import { ClaimAddressDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

interface ClaimExtraFields {
  providerAddress?: ClaimAddressDto;
  hpiOrganisationNumber?: string;
  practiceName?: string;
}

export class ClaimExtraFieldsValidator extends Validator<ClaimExtraFields> {
  constructor() {
    super();

    this.forField("providerAddress", required());
    this.forField("hpiOrganisationNumber", required());
    this.forField("practiceName", required());
  }
}
