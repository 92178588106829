import { DateTime } from "@bps/utils";
import {
  Frequency,
  OrgUnitAvailabilityDto,
  OrgUnitOpeningHoursDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  OrgUnitOpeningHoursForm,
  OrgUnitOpeningHoursValues
} from "./OrgUnitOpeningHoursForm.tsx";
import { OrgUnitOpeningHoursFormValues } from "./OrgUnitOpeningHoursForm.types.ts";

const toOrgUnitOpeningHoursFormValues = (
  orgUnitOpeningHours: OrgUnitOpeningHoursDto[]
): OrgUnitOpeningHoursFormValues[] => {
  return (
    orgUnitOpeningHours &&
    orgUnitOpeningHours.map(o => ({
      eTag: o.eTag,
      id: o.id,
      startTime: DateTime.fromISO(o.startTime).toTimeInputFormat(),
      endTime: DateTime.fromISO(o.endTime).toTimeInputFormat(),
      count: o.recurrenceRule.count,
      frequency: o.recurrenceRule.frequency,
      interval: o.recurrenceRule.interval,
      dayRecur:
        o.recurrenceRule.frequency === Frequency.Week
          ? o.recurrenceRule.dayRecur || []
          : undefined,
      monthRecur:
        o.recurrenceRule.frequency !== Frequency.Week
          ? o.recurrenceRule.monthRecur || []
          : undefined,
      until: DateTime.fromISO(o.recurrenceRule.until)?.startOf("day").toJSDate()
    }))
  );
};

interface OrgUnitOpeningHoursDetailsProps {
  orgUnitAvailability?: OrgUnitAvailabilityDto;
}

export const OrgUnitOpeningHoursDetails: React.FC<
  OrgUnitOpeningHoursDetailsProps
> = ({ orgUnitAvailability }) => {
  const { core, booking, notification } = useStores();

  const toOrgUnitOpeningHours = (
    orgUnitOpeningHours: OrgUnitOpeningHoursFormValues[],
    orgUnitId: string
  ): OrgUnitOpeningHoursDto[] => {
    return orgUnitOpeningHours?.map<OrgUnitOpeningHoursDto>(s => {
      const frequency = s.frequency === undefined ? 5 : Number(s.frequency);
      const monthRecur = s.monthRecur || [];

      return {
        orgUnitId,
        id: s.id!,
        eTag: s.eTag!,
        startTime: DateTime.fromISO(s.startTime)?.toTimeInputFormat()!,
        endTime: DateTime.fromISO(s.endTime)?.toTimeInputFormat()!,
        recurrenceRule: {
          dayRecur: frequency === Frequency.Week ? s.dayRecur || [] : undefined,
          frequency,
          interval: s.interval === undefined ? 1 : s.interval,
          monthRecur:
            frequency === Frequency.Month || frequency === Frequency.Year
              ? monthRecur
              : undefined,
          until: s.until && DateTime.fromJSDate(s.until).startOf("day").toISO()
        }
      };
    });
  };

  const initialValues: OrgUnitOpeningHoursValues = {
    openingHours: orgUnitAvailability
      ? toOrgUnitOpeningHoursFormValues(orgUnitAvailability.orgUnitOpeningHours)
      : []
  };

  return (
    <OrgUnitOpeningHoursForm
      readonly={!core.hasPermissions(Permission.OrgUnitSettingWrite)}
      initialValues={initialValues}
      onSubmit={async values => {
        const orgUnitOpeningHours = toOrgUnitOpeningHours(
          values.openingHours,
          core.locationId
        );

        await booking.updateOrgUnitAvailability({
          orgUnitId: core.locationId,
          orgUnitOpeningHours
        });
      }}
      onSubmitSucceeded={() => {
        notification.success("Opening hours have been updated.");
      }}
    />
  );
};
