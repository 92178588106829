import React from "react";
import { useForm } from "react-final-form";

import {
  FontIcon,
  FontSizes,
  Heading,
  Link,
  Separator,
  Stack,
  StandardRangesOptionsKeys,
  useTheme
} from "@bps/fluent-ui";
import {
  clinicalInformationValues,
  clinicalOptions,
  demographicDataValues,
  demographicOptions,
  exportPatientRecordFormNameOf,
  formatOptions
} from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecordFormFields.types.ts";
import { ExportPatientRecordFormValues } from "@shared-types/practice/export-patient-record-form-values.types.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { DatesRangePickerField } from "@ui-components/form/DatesRangePickerField/DatesRangePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";

const SELECT_ALL = "Select all";
export const ExportPatientRecordModalFormFields = () => {
  const form = useForm<ExportPatientRecordFormValues>();
  const theme = useTheme();

  const onDemographicDataSelectAllClick = () => {
    form.change(
      exportPatientRecordFormNameOf("demographicData"),
      demographicDataValues
    );
  };

  const onClinicalInformationSelectAllClick = () => {
    form.change(
      exportPatientRecordFormNameOf("clinicalInformation"),
      clinicalInformationValues
    );
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        tokens={{ childrenGap: 16 }}
        styles={{
          root: {
            padding: "8px 16px",
            borderRadius: 2,
            backgroundColor: theme.palette.neutralLighterAlt
          }
        }}
      >
        <FontIcon
          styles={{ root: { fontSize: FontSizes.size16, cursor: "pointer" } }}
          iconName="Filter"
        />
        <Stack.Item grow={1} basis={0}>
          <DatesRangePickerField
            startDateName={exportPatientRecordFormNameOf("startDate")}
            endDateName={exportPatientRecordFormNameOf("endDate")}
            standardRanges={[
              StandardRangesOptionsKeys.today,
              StandardRangesOptionsKeys.yesterday,
              StandardRangesOptionsKeys.lastWeek,
              StandardRangesOptionsKeys.lastMonth,
              StandardRangesOptionsKeys.lastYear
            ]}
            placeholder="All dates"
            label="Dates"
            hidePasteStartButton
            styles={{ root: { width: "100%" }, fieldGroup: { width: "100%" } }}
          />
        </Stack.Item>
        <Stack.Item grow={1} basis={0} verticalFill>
          <Heading labelPaddings>Confidential records</Heading>
          <CheckboxField
            label="Include confidential records"
            name={exportPatientRecordFormNameOf("confidentialRecords")}
            styles={{ root: { height: 32, alignItems: "center" } }}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Heading variant="section-sub-heading">Demographic data</Heading>
        <Link onClick={onDemographicDataSelectAllClick}>{SELECT_ALL}</Link>
      </Stack>
      <CheckboxGroupField
        name={exportPatientRecordFormNameOf("demographicData")}
        horizontal
        wrap
        checkboxWidth={265}
        options={demographicOptions}
      />
      <Separator />
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Heading variant="section-sub-heading">Clinical information</Heading>
        <Link onClick={onClinicalInformationSelectAllClick}>{SELECT_ALL}</Link>
      </Stack>
      <CheckboxGroupField
        name={exportPatientRecordFormNameOf("clinicalInformation")}
        horizontal
        wrap
        checkboxWidth={265}
        options={clinicalOptions}
      />
      <Separator />
      <Heading variant="section-sub-heading">Export details</Heading>
      <Stack
        tokens={{ childrenGap: 8 }}
        horizontal
        horizontalAlign="space-between"
      >
        <Stack.Item grow={1} basis={0}>
          <DropdownField
            name={exportPatientRecordFormNameOf("format")}
            placeholder="Format"
            label="Format"
            options={formatOptions}
          />
        </Stack.Item>
        <Stack.Item grow={2} basis={0}>
          <DropdownField
            name={exportPatientRecordFormNameOf("method")}
            placeholder="Method"
            label="Method"
            options={[]}
          />
        </Stack.Item>
        <Stack.Item grow={2} basis={0}>
          <DropdownField
            name={exportPatientRecordFormNameOf("contactDetails")}
            placeholder="Contact details"
            label="Contact details"
            options={[]}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
