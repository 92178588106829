import { useField } from "react-final-form";

import { AllocationOptions } from "@modules/billing/screens/shared-components/allocation-form/AllocationOptions.ts";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { useAllocationFormContext } from "@modules/billing/screens/shared-components/allocation-form/context/AllocationFormContext.ts";

/**
 * @returns the selected AllocationOption
 **/
export const useAllocationOption = () => {
  const { allocationOptions } = useAllocationFormContext();

  const {
    input: { value: allocationOption }
  } = useField<AllocationOptions>(allocationNameOf("allocationOption"), {
    subscription: { value: true }
  });

  const selectedPaymentOption = allocationOptions.find(
    x => x.key === allocationOption
  );

  return selectedPaymentOption;
};
