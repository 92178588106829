import { runInAction } from "mobx";
import { FC } from "react";

import {
  CorrespondenceType,
  DocumentWriterTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import {
  TemplatePickerFormContext,
  useTemplatePickerFormContext
} from "@modules/clinical/screens/correspondence/components/context/TemplatePickerFormContext.tsx";
import { TemplatePickerFormModel } from "@modules/clinical/screens/correspondence/components/context/TemplatePickerFormModel.ts";
import { CreateBlankDocumentDialog } from "@modules/clinical/screens/correspondence/components/CreateBlankDocumentDialog.tsx";
import { DocumentWriterMergeFormDialog } from "@modules/clinical/screens/document-writer/components/merge-fields/DocumentWriterMergeFormDialog.tsx";
import {
  DocumentMergeFieldsFormValues,
  DocumentWriterMergeFormDialogProps
} from "@modules/clinical/screens/document-writer/components/merge-fields/DocumentWriterMergeFormDialog.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { useQuickAccessContext } from "./context/QuickAccessContext.tsx";

interface QuickAccessDocumentWriterDialogBaseProps {
  documentSettings: OrgUnitDocumentSettingsDto | undefined;
  createDocument: (
    templateType: CorrespondenceType,
    values: DocumentMergeFieldsFormValues,
    toContactId?: string
  ) => Promise<void>;
}

const QuickAccessDocumentWriterDialogBase: FC<
  QuickAccessDocumentWriterDialogBaseProps
> = ({ documentSettings, createDocument }) => {
  const model = useTemplatePickerFormContext();
  const { clinicalRecord, patientId } = usePatientRecordScreenContext();

  const appointmentClaimId = clinicalRecord.calendarEvent?.claim?.id;
  const { setDocumentWriterMergeFormDialog } = useQuickAccessContext();

  const onMergeFieldFormSubmit: DocumentWriterMergeFormDialogProps["onSubmit"] =
    async values => {
      await createDocument(
        values.selectedTemplate!,
        values,
        values.toContactId
      );
    };

  return (
    <DocumentWriterMergeFormDialog
      onCancel={() => {
        setDocumentWriterMergeFormDialog(false);
        runInAction(() => {
          model.selectedTemplate = undefined;
        });
      }}
      onSubmit={onMergeFieldFormSubmit}
      activeClaimId={appointmentClaimId}
      launchFrom={LaunchFrom.Clinical}
      patientId={patientId}
      documentSettings={documentSettings}
    />
  );
};

export const QuickAccessDocumentWriterDialog = () => {
  const { document, correspondence, clinical, core, practice } = useStores();

  const model = new TemplatePickerFormModel({
    document,
    correspondence,
    clinical,
    core
  });

  const { clinicalRecord, patientId } = usePatientRecordScreenContext();
  const {
    showDocumentWriterMergeFormDialog,
    setDocumentWriterMergeFormDialog
  } = useQuickAccessContext();

  const encounterId = clinicalRecord.currentEncounterId;

  const claimId = clinicalRecord.calendarEvent?.claimId;
  const { selectedTemplate } = useQuickAccessContext();

  const loadPracticeDocumentSettings = async (): Promise<
    OrgUnitDocumentSettingsDto | undefined
  > => {
    const orgUnitId = core.location.parentOrgUnit?.id;

    if (orgUnitId) {
      const orgUnit = await practice.getOrgUnit(orgUnitId);
      return orgUnit?.orgUnitDocumentSettings;
    }

    return undefined;
  };

  const createDocument = async (
    templateType: CorrespondenceType,
    values: DocumentMergeFieldsFormValues,
    toContactId?: string
  ) => {
    await model.createDocument(templateType, values, {
      toContactId,
      encounterId,
      onSuccess: correspondence.ui.navigateToDocumentWriter,
      onDismiss: () => {
        correspondence.ui.currentTemplatePickerProps?.onDismiss();
        setDocumentWriterMergeFormDialog(false);
      }
    });
  };
  return (
    <TemplatePickerFormContext.Provider value={model}>
      <DataFetcher
        fetch={async () => {
          const template = selectedTemplate
            ? await document.getTemplate(selectedTemplate, {
                ignoreCache: false
              })
            : undefined;

          runInAction(() => {
            model.selectedTemplate = template;
          });

          correspondence.ui.setShowTemplatePicker(true, {
            hidden: true,
            userId: core.userId,
            onDismiss: () => correspondence.ui.setShowTemplatePicker(false),
            onSuccess: (documentTab: DocumentWriterTab) => {
              correspondence.activeDocumentTab = documentTab;
            },
            launchFrom: LaunchFrom.Clinical,
            patientId,
            encounterId,
            claimId
          });

          return await loadPracticeDocumentSettings();
        }}
        refetchId={selectedTemplate}
      >
        {documentSettings => {
          if (
            model.selectedTemplate?.documentType === CorrespondenceType.Autofill
          ) {
            return (
              <DataFetcher
                fetch={async () => {
                  if (patientId) {
                    const patient = await practice.getContact(patientId);
                    return patient.nameWithTitle;
                  }
                  return undefined;
                }}
              >
                {patientNameWithTitle => (
                  <CreateBlankDocumentDialog
                    createDocument={createDocument}
                    isDialogHidden={!showDocumentWriterMergeFormDialog}
                    onDismiss={() => setDocumentWriterMergeFormDialog(false)}
                    launchFrom={LaunchFrom.Clinical}
                    patientId={patientId}
                    heading={
                      patientNameWithTitle
                        ? `Create document for ${patientNameWithTitle}`
                        : "Create document"
                    }
                    documentSettings={documentSettings}
                  />
                )}
              </DataFetcher>
            );
          }
          return (
            <QuickAccessDocumentWriterDialogBase
              documentSettings={documentSettings}
              createDocument={createDocument}
            />
          );
        }}
      </DataFetcher>
    </TemplatePickerFormContext.Provider>
  );
};
