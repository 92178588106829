import { useState } from "react";

import { Link, Stack, Text, TooltipHost } from "@bps/fluent-ui";
import {
  ClinicalDocumentLite,
  DocumentDestinationType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { InboxDocumentDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { UserHelper } from "@modules/settings/screens/users/components/context/UserHelper.tsx";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalImagesDialog } from "../clinical-images/ClinicalImagesDialog.tsx";
import { CorrespondenceDialog } from "../correspondence/CorrespondenceDialog.tsx";
import { AddEditInvestigationDialog } from "../investigations/AddEditInvestigationDialog.tsx";
import { EditInboxDocumentDialog } from "./EditInboxDocumentDialog.tsx";
import { RecentIncomingDocContentHelper } from "./RecentIncomingDocContentHelper.tsx";

export interface IRenderIncomingDocReceivedDateProps {
  item: ClinicalDocumentLite;
  clinicalRecord: ClinicalRecord;
}

export const RenderIncomingDocReceivedDate: React.FunctionComponent<
  IRenderIncomingDocReceivedDateProps
> = ({ item, clinicalRecord }) => {
  const dateReceived = item?.dateReceived;
  const root = useStores();
  const {
    fetchClinicalDocument,
    createSelectedInBoxDoc,
    fetchInboxDocument,
    createNewClinDocument
  } = new RecentIncomingDocContentHelper(clinicalRecord, root);

  const { hasClinViewSecurityRole, hasPracWorkflowOrInfoContributorRole } =
    new UserHelper(root);

  const [isCorrespondence, setIsCorrespondence] = useState<boolean>(false);
  const [isInvestigation, setIsInvestigation] = useState<boolean>(false);
  const [isClinicalImage, setIsClinicalImage] = useState<boolean>(false);
  const [selectedClinicalImageDocument, setClinicalImageDocument] = useState<
    ClinicalDocument | undefined
  >(undefined);

  const [selectedCorrespondenceDocument, setCorrespondenceDocument] = useState<
    ClinicalDocument | undefined
  >(undefined);

  const [selectedInvestigationDocument, setInvestigationDocument] = useState<
    ClinicalDocument | undefined
  >(undefined);

  const [selectedInboxDocument, setInboxDocument] = useState<
    InboxDocumentDto | undefined
  >(undefined);

  const [isInbox, setIsInbox] = useState<boolean>(false);

  const handleSelectedDocumentClick = async (item: ClinicalDocumentLite) => {
    switch (item.storeIn) {
      case DocumentDestinationType.ClinicalImages:
        setClinicalImageDocument(await fetchClinicalDocument(item.id));
        setIsClinicalImage(true);
        break;
      case DocumentDestinationType.Investigations:
        setInvestigationDocument(await fetchClinicalDocument(item.id));
        setIsInvestigation(true);
        break;
      case DocumentDestinationType.Correspondence:
        setCorrespondenceDocument(await fetchClinicalDocument(item.id));
        setIsCorrespondence(true);
        break;
      case "Inbox":
        const inboxDocument = await fetchInboxDocument(item);
        if (inboxDocument) {
          const selectedInBoxDoc = createSelectedInBoxDoc(inboxDocument);
          const newClinDocument = createNewClinDocument(inboxDocument);
          setInboxDocument(selectedInBoxDoc);
          setInvestigationDocument(newClinDocument);
          setIsInbox(true);
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 4 }}>
        <Text variant="small">{item.storeIn}</Text>
        <TooltipHost
          content="Edit details"
          styles={{ root: { verticalAlign: "middle" } }}
        >
          {dateReceived &&
            (hasClinViewSecurityRole &&
            !hasPracWorkflowOrInfoContributorRole ? (
              <Text>{dateReceived.toDayDefaultFormat()}</Text>
            ) : (
              <Link onClick={() => handleSelectedDocumentClick(item)}>
                {dateReceived.toDayDefaultFormat()}
              </Link>
            ))}
        </TooltipHost>
      </Stack>

      {selectedClinicalImageDocument && !isInbox && isClinicalImage && (
        <ClinicalImagesDialog
          clinicalImage={selectedClinicalImageDocument}
          onDismiss={() => setClinicalImageDocument(undefined)}
          onConflictError={() => {}}
        />
      )}

      {selectedCorrespondenceDocument && !isInbox && isCorrespondence && (
        <CorrespondenceDialog
          correspondence={selectedCorrespondenceDocument}
          onDismiss={() => setCorrespondenceDocument(undefined)}
          onConflictError={() => {}}
        />
      )}

      {selectedInvestigationDocument && !isInbox && isInvestigation && (
        <AddEditInvestigationDialog
          clinicalRecord={clinicalRecord}
          editInvestigation={selectedInvestigationDocument}
          onDismiss={() => setInvestigationDocument(undefined)}
        />
      )}

      {selectedInboxDocument && isInbox && (
        <EditInboxDocumentDialog
          clinicalRecord={clinicalRecord}
          onDismiss={() => {
            setInboxDocument(undefined);
            setIsInbox(false);
            setInvestigationDocument(undefined);
            setInboxDocument(undefined);
          }}
          selectedDocument={selectedInvestigationDocument}
          selectedInboxDocument={selectedInboxDocument}
        />
      )}
    </>
  );
};
