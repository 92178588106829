import React from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";

interface GeneralHighLowWarningMessageBarProps {
  min?: number;
  max?: number;
  value: number;
  minMessage?: string;
  maxMessage?: string;
}
export const GeneralHighLowSevereWarningMessageBar: React.FC<
  GeneralHighLowWarningMessageBarProps
> = ({ min, max, value, minMessage, maxMessage }) => {
  if (min && value < min) {
    return (
      <MessageBar messageBarType={MessageBarType.severeWarning}>
        {minMessage}
      </MessageBar>
    );
  } else if (max && value > max) {
    return (
      <MessageBar messageBarType={MessageBarType.severeWarning}>
        {maxMessage}
      </MessageBar>
    );
  }
  return null;
};
