import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";

export const StatementBadge: React.FC = () => {
  return (
    <TextBadge
      hasBorder={true}
      badgeColor={TextBadgeColor.lightGrey}
      badgeSize={TextBadgeSize.medium}
      styles={{ root: { width: 108 } }}
    >
      PDF
    </TextBadge>
  );
};
