import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DeleteMedicationDialog } from "../DeleteMedicationDialog.tsx";
import {
  MedicationsContext,
  useMedicationsContext
} from "./helpers/MedicationsContext.tsx";
import { MedicationsHelper } from "./helpers/MedicationsHelper.ts";
import { MedicationsList } from "./MedicationsList.tsx";
import { PrescribingWizard } from "./PrescribingWizard.tsx";

const MedicationsComponentBase: React.FunctionComponent = observer(() => {
  const helper = useMedicationsContext();

  const { setDeleteDialogVisible, onDeleteMedication } = helper;

  return (
    <>
      <MedicationsList />
      <PrescribingWizard />
      <DeleteMedicationDialog
        hidden={!(helper.isDeleteDialogVisible && helper.selectedMedicationId)}
        onConfirm={async (reasonForDelete, reasonForDeleteComment) => {
          if (helper.selectedMedicationId) {
            await onDeleteMedication(
              helper.selectedMedicationId,
              reasonForDelete,
              reasonForDeleteComment
            );
          }
        }}
        onCancel={() => setDeleteDialogVisible(false)}
      />
    </>
  );
});

const MedicationsWithContext: FunctionComponent = () => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const root = useStores();
  const helper = new MedicationsHelper(clinicalRecord, root);
  return (
    <MedicationsContext.Provider value={helper}>
      <MedicationsComponentBase />
    </MedicationsContext.Provider>
  );
};

export const Medications = withFetch(
  x => [
    x.clinical.ref.dosingFrequencies.load(),
    x.clinical.ref.dosingAdministrationUnits.load()
  ],
  MedicationsWithContext
);
