import { useState } from "react";
import { useForm } from "react-final-form";

import {
  CollapsibleCard,
  FontIcon,
  FontSizes,
  OptionsSelect,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { MeridianData } from "@shared-types/clinical/meridian-data.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TagsSelectField } from "@ui-components/form/TagsSelectField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { TreatmentFormValues } from "../treatmentAndManagement/treatment/TreatmentForm.types.ts";
import { SotapMskLabel } from "./SOTAP.constants.ts";

interface MeridiansCardProps {
  isPrimaryMeridians?: boolean;
}

export const MeridiansCard: React.FC<MeridiansCardProps> = ({
  isPrimaryMeridians
}) => {
  const theme = useTheme();
  const { clinical } = useStores();

  const meridiansOptions = clinical.ref.meridians.values
    .filter(x => !!x.isPrimary === !!isPrimaryMeridians)
    .map(option => ({
      key: option.code,
      text: `${option.code}-${option.pinyin}`
    }));

  const getAcupoints = (meridian: string) => {
    const result = clinical.ref.acupoints.values
      .filter(acupoint => acupoint.meridian === meridian)
      .map(({ code }) => ({ key: code, text: code }));
    return result;
  };

  const form = useForm<TreatmentFormValues>();
  const nameOf = nameOfFactory<TreatmentFormValues>();

  const name = isPrimaryMeridians
    ? "primaryMeridians"
    : "extraordinaryMeridians";

  const { fields: meridians } = useFieldArray(nameOf(name));

  const selectedMeridianKeys =
    meridians.value && meridians.value.length > 0
      ? meridians.value.map(x => x.meridian)
      : [];

  const [selectedGenericKeys, setSelectedGenericKeys] =
    useState<string[]>(selectedMeridianKeys);

  const getAcupointField = (name: string, meridian: string) => {
    return (
      <Stack
        tokens={{ childrenGap: 8 }}
        key={`${name}.meridians`}
        styles={{
          root: {
            marginTop: 8,
            padding: "8px 0",
            borderTop: `1px solid ${theme.palette.neutralLight}`
          }
        }}
      >
        <CheckboxField
          name={`${name}.check`}
          label={meridiansOptions.find(x => x.key === meridian)?.text}
          styles={{
            label: {
              fontWeight: 600,
              fontSize: 14
            }
          }}
        />
        <Stack
          styles={{ root: { marginLeft: 28 } }}
          tokens={{ childrenGap: 8 }}
        >
          {getAcupoints(meridian).length > 0 && (
            <TagsSelectField
              name={`${name}.acupoints`}
              options={getAcupoints(meridian)}
            />
          )}

          <TextInputField name={`${name}.comment`} placeholder="Comment" />
        </Stack>
      </Stack>
    );
  };

  const meridiansModified = meridians?.value?.some(
    x =>
      (x.acupoints !== undefined && x.acupoints.length > 0) ||
      x.comment !== undefined ||
      !!x.check !== false
  );

  const renderTitle = (title: string, showModified: boolean) => {
    return (
      <Text variant="large" bold>
        {title}

        {showModified && (
          <FontIcon
            iconName="PageEdit"
            styles={{
              root: { fontSize: FontSizes.size16, paddingLeft: "8px" }
            }}
          />
        )}
      </Text>
    );
  };

  return (
    <CollapsibleCard
      heading={renderTitle(
        isPrimaryMeridians
          ? SotapMskLabel.PrimaryMeridians
          : SotapMskLabel.ExtraordinaryMeridians,
        meridiansModified
      )}
      headingLevel="section-heading"
      iconName={isPrimaryMeridians ? "BpPrimMerids" : "BpExtraMerids"}
    >
      <OptionsSelect
        label="Meridians"
        options={meridiansOptions}
        placeholder="Select meridian(s)"
        onChangeSelectedKeys={(values: string[]) => {
          if (values) {
            const meridianList: MeridianData[] = values.map(meridian => {
              const primaryMeridian: MeridianData = meridians.value
                ? meridians.value.filter(x => x.meridian === meridian)[0]
                : null;

              return {
                meridian,
                check: true,
                acupoints: primaryMeridian
                  ? primaryMeridian.acupoints
                  : undefined,
                comment: primaryMeridian ? primaryMeridian.comment : undefined
              };
            });
            form.change(name, meridianList);
            setSelectedGenericKeys(values);
          }
        }}
        selectedKeys={selectedGenericKeys}
        multiSelect
        showAllSelected
      />

      {meridians.map((name, index) => {
        const meridian = meridians.value[index].meridian;
        return getAcupointField(name, meridian);
      })}
    </CollapsibleCard>
  );
};
