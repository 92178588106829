import { getIn } from "final-form";

import {
  OPTOUT,
  OutboundCommChannelMapping
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  ContactEditFormValues,
  PatientEditFormValues
} from "../PatientEditFormValues.tsx";

type FormValues = PatientEditFormValues | ContactEditFormValues;

export class PreferencesAndConsentsValidator extends Validator<FormValues> {
  constructor() {
    super();

    const validatePreferenceMethodValues =
      (): FieldValidator =>
      (value: string | undefined | null, { communications }: FormValues) => {
        if (!value) {
          return undefined;
        }

        if (value === OPTOUT) return undefined;

        const data = communications.find(
          i => i.type === OutboundCommChannelMapping[value] && i.value
        );

        if (!data) return ValidationMessages.invalid;

        return undefined;
      };

    const validatePreferenceValues =
      (method: string): FieldValidator =>
      (value: number | undefined | null, values: FormValues) => {
        if (!values) {
          return undefined;
        }

        const commsMethod = getIn(values, method);
        if (!commsMethod || commsMethod === OPTOUT) return undefined;
        if (typeof value !== "number") return ValidationMessages.required;

        const data = values.communications.filter(
          i => i.type === OutboundCommChannelMapping[commsMethod]
        );
        if (!data || !data.length) return ValidationMessages.invalid;
        if (value < 0 || value >= data.length)
          return ValidationMessages.invalid;
        return undefined;
      };

    this.forField("appointmentReminderType", validatePreferenceMethodValues());
    this.forField(
      "appointmentReminderValue",
      validatePreferenceValues("appointmentReminderType")
    );
    this.forField(
      "appointmentConfirmationType",
      validatePreferenceMethodValues()
    );
    this.forField(
      "appointmentConfirmationValue",
      validatePreferenceValues("appointmentConfirmationType")
    );

    this.forField("invoiceCommunicationType", validatePreferenceMethodValues());
    this.forField(
      "invoiceCommunicationValue",
      validatePreferenceValues("invoiceCommunicationType")
    );

    this.forField("formNotifyType", validatePreferenceMethodValues());
    this.forField(
      "formNotifyValue",
      validatePreferenceValues("formNotifyType")
    );
  }
}
