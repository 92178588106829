import { observer, Observer } from "mobx-react-lite";

import {
  DetailsRow,
  DirectionalHint,
  FontIcon,
  FontSizes,
  FontWeights,
  IDetailsRowProps,
  NoDataTile,
  noWrap,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { ActivityDescriptionText } from "../clinical-activity/ActivityDescriptionText.tsx";
import { ClinicalActivityTypeIcon } from "../clinical-activity/ClinicalActivityTypeIcon.tsx";
import { ClinicalActivityTableLabels } from "../clinical-activity/types/clinical-activity-table-labels.enum.ts";
import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";

export interface ClinicalActivitySummaryContentProps {
  clinicalRecord: ClinicalRecord;
  handleDialogVisbleChanged: (isVisible: boolean) => void;
}

export const ClinicalActivitySummaryContent: React.FC<ClinicalActivitySummaryContentProps> =
  observer(({ clinicalRecord, handleDialogVisbleChanged }) => {
    const theme = useTheme();

    const { core, clinical } = useStores();

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    const items = clinicalRecord.clinicalActivities.filter(
      x => !x.isCompleted && !x.isDeleted
    );

    const sortPriority = [
      ClinicalActivityStatusText.Overdue,
      ClinicalActivityStatusText.Today,
      ClinicalActivityStatusText.Upcoming
    ];

    items.sort((a, b) => {
      if (!a.activityStatus || !b.activityStatus) {
        return 0;
      }

      return (
        sortPriority.indexOf(a.activityStatus) -
        sortPriority.indexOf(b.activityStatus)
      );
    });

    const renderVisitsNumber = (item: ClinicalActivity) => {
      if (item.remainingVisits === undefined) {
        return undefined;
      }

      const remainingVisits = item.remainingVisits;

      const visitsText = remainingVisits === 1 ? "consult" : "consults";

      return `${remainingVisits} ${visitsText}`;
    };

    const toSimpleDateString = (dueDate: string | undefined) => {
      if (!dueDate) return "";

      return DateTime.fromISO(dueDate).toDayDefaultFormat();
    };

    const checkConfidentailAccess = (
      item: ClinicalActivity,
      renderColumn: JSX.Element
    ) => {
      return core.hasAccessToSecGroup(item.secGroupId) && renderColumn;
    };

    const renderStatusBadgeAndIcon = (item: ClinicalActivity) => {
      const activityStatus = item.activityStatus;
      let badgeColor = TextBadgeColor.lightGrey;
      if (activityStatus === ClinicalActivityStatusText.Upcoming) {
        badgeColor = TextBadgeColor.blue;
      }
      if (activityStatus === ClinicalActivityStatusText.Today) {
        badgeColor = TextBadgeColor.yellow;
      }
      if (activityStatus === ClinicalActivityStatusText.Overdue) {
        badgeColor = TextBadgeColor.red;
      }

      const showWarningIcon =
        activityStatus === ClinicalActivityStatusText.Overdue;

      return (
        <Observer>
          {() => (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <TextBadge
                badgeColor={badgeColor}
                badgeSize={TextBadgeSize.medium}
                hasBorder={activityStatus === ClinicalActivityStatusText.Today}
                styles={{
                  root: {
                    fontSize: FontSizes.size12,
                    width: 100
                  }
                }}
              >
                <Stack horizontal horizontalAlign="center">
                  {showWarningIcon && (
                    <FontIcon
                      iconName="AlertSolid"
                      styles={{ root: { marginRight: 4 } }}
                    />
                  )}
                  {activityStatus}
                </Stack>
              </TextBadge>
              {item.activityStatus === ClinicalActivityStatusText.Completed && (
                <TooltipHost
                  styles={{ root: { alignSelf: "center" } }}
                  calloutProps={{
                    directionalHint: DirectionalHint.bottomLeftEdge
                  }}
                  content={
                    <DataFetcher<User | undefined>
                      fetch={async ({ core }) => {
                        if (item.completedBy) {
                          return await core.getUser(item.completedBy);
                        } else {
                          if (item.changeLog?.updatedBy) {
                            return await core.getUserByUserName(
                              item.changeLog?.updatedBy
                            );
                          }
                        }
                        return undefined;
                      }}
                    >
                      {user => (
                        <Stack
                          tokens={{ childrenGap: 8 }}
                          styles={{ root: { margin: "10px 12px" } }}
                        >
                          <Stack>
                            <Text bold>Completed</Text>
                            <Text>{user?.fullName}</Text>
                            <Text>{item.completedDate}</Text>
                          </Stack>
                          {item.completionNotes && (
                            <Stack>
                              <Text bold>Notes</Text>
                              <Text>{item.completionNotes}</Text>
                            </Stack>
                          )}
                        </Stack>
                      )}
                    </DataFetcher>
                  }
                >
                  <FontIcon
                    styles={{
                      root: {
                        fontSize: FontSizes.size16,
                        color: theme.palette.themePrimary
                      }
                    }}
                    iconName={item.completionNotes ? "CannedChat" : "Info"}
                  />
                </TooltipHost>
              )}
            </Stack>
          )}
        </Observer>
      );
    };

    const columns = [
      {
        key: ClinicalActivityTableLabels.Status,
        isRowHeader: true,
        minWidth: 100,
        maxWidth: 100,
        name: ClinicalActivityTableLabels.Status,
        isMultiline: true,
        onRender: renderStatusBadgeAndIcon
      },
      {
        key: ClinicalActivityTableLabels.Due,
        minWidth: 80,
        maxWidth: 85,
        name: ClinicalActivityTableLabels.Due,
        isMultiline: true,
        onRender: (item: ClinicalActivity) =>
          checkConfidentailAccess(
            item,
            <Observer>
              {() => {
                let fontColour = theme.palette.neutralPrimary;

                if (
                  item.activityStatus === ClinicalActivityStatusText.Overdue
                ) {
                  fontColour = theme.semanticColors.errorText;
                }

                if (
                  item.activityStatus === ClinicalActivityStatusText.Completed
                ) {
                  fontColour = theme.palette.neutralSecondaryAlt;
                }

                return (
                  <Text
                    styles={{
                      root: { color: fontColour, fontWeight: FontWeights.bold }
                    }}
                  >
                    {item.dueDate
                      ? toSimpleDateString(item.dueDate)
                      : renderVisitsNumber(item)}
                  </Text>
                );
              }}
            </Observer>
          )
      },
      {
        key: ClinicalActivityTableLabels.Details,
        minWidth: 150,
        maxWidth: 300,
        name: ClinicalActivityTableLabels.Details,
        isMultiline: true,
        onRender: (item: ClinicalActivity) => {
          const providerName = core.getUserByPrivateSecGroupId(item.secGroupId)
            ?.fullName;

          return (
            <Stack
              tokens={{ childrenGap: 4 }}
              horizontal
              verticalAlign="center"
            >
              <ClinicalActivityTypeIcon activityType={item.activityType} />
              {core.hasAccessToSecGroup(item.secGroupId) ? (
                <Observer>
                  {() => (
                    <Stack horizontal>
                      <ActivityDescriptionText
                        descriptionId={item.descriptionId}
                        freeTextFallback={item.freeText}
                      />
                      <ConfidentialToolTipFontIcon
                        isShowConfidentialIcon={item.secGroupId !== undefined}
                      />
                    </Stack>
                  )}
                </Observer>
              ) : (
                <Text nowrap>{`Confidential (by ${providerName} )`}</Text>
              )}
            </Stack>
          );
        }
      }
    ];

    const renderRow = (props: IDetailsRowProps): JSX.Element | null => {
      const renderRowProps = { ...props };

      return (
        <DetailsRow
          {...renderRowProps}
          styles={{
            root: {
              "&.is-selected:hover": {
                backgroundColor: theme.semanticColors.listItemBackgroundChecked
              }
            },
            fields: {
              display: "inline-flex",
              alignItems: "center"
            },

            isMultiline: {
              ...noWrap
            }
          }}
        />
      );
    };

    return (
      <>
        {!items.length && (
          <NoDataTile
            textProps={{ text: "No information to display" }}
            linkProps={
              !isViewOnlyOrDischarged &&
              !clinical.ui.clinicalActivityActionDisabled
                ? {
                    text: "Add clinical notification",
                    onClick: () => {
                      handleDialogVisbleChanged(true);
                    }
                  }
                : { hidden: true }
            }
            showBoxShadow={false}
            styles={{
              root: {
                padding: 0,
                minHeight: 120,
                width: "100%",
                height: "100%"
              }
            }}
          />
        )}

        {items.length > 0 && (
          <ShimmeredDetailsList
            items={items}
            onRenderRow={renderRow}
            columns={columns}
          />
        )}
      </>
    );
  });
