export enum AddressFieldsLabels {
  BuildingName = "Building / facility",
  Address1 = "Address line 1",
  Address2 = "Address line 2",
  Country = "Country",
  State = "Select state",
  Postcode = "Post code",
  Street = "Street name & number",
  Suburb = "Suburb",
  City = "Town / city"
}
