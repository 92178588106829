import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { getSeriesOccurrences } from "../../utils.tsx";
import { CancelAppointmentFormFields } from "./CancelAppointmentFormFields.tsx";
import { CancelAppointmentRecurrenceFormValues } from "./CancelAppointmentRecurrenceForm.types.ts";
import { CalendarEventRecurrenceCancelFormValidator } from "./CancelAppointmentRecurrenceFormValidator.ts";

export interface CancelAppointmentRecurrenceFormProps {
  onSubmitted?: (values: CancelAppointmentRecurrenceFormValues) => void;
  onCancel?: () => void;
  calendarEvent: CalendarEvent;
}

export const CancelAppointmentRecurrenceForm: FunctionComponent<CancelAppointmentRecurrenceFormProps> =
  observer(({ onSubmitted, calendarEvent, onCancel }) => {
    const { booking, userExperience } = useStores();

    const captureApptCancellationReason =
      userExperience.orgUnitSettingForLocation?.preferenceSetting
        .captureApptCancellationReason;

    const handleSubmitAction = async (
      values: CancelAppointmentRecurrenceFormValues
    ) => {
      await booking.cancelCalendarSeries(lastRecurrenceSeries?.seriesId!, {
        cancelDateTime: calendarEvent.startDateTime.toISO(),
        cancellationReasonId: values.cancellationReasonId || undefined
      });
    };

    const calendarEventRecurrence = booking.recurrenceMap.get(
      calendarEvent.calendarEventRecurrenceId!
    );

    const recurrenceSeries =
      calendarEventRecurrence && calendarEventRecurrence.seriesId
        ? booking.getRecurrencesForSeries(calendarEventRecurrence.seriesId)
        : undefined;

    const lastRecurrenceSeries =
      recurrenceSeries &&
      recurrenceSeries.reduce((a, b) => {
        return a.createdDate &&
          b.createdDate &&
          DateTime.fromISO(a.createdDate) > DateTime.fromISO(b.createdDate)
          ? a
          : b;
      });

    const isNever =
      lastRecurrenceSeries !== undefined &&
      lastRecurrenceSeries.count === undefined &&
      lastRecurrenceSeries.until === undefined;

    const recurrenceOccurredCount = (): number | undefined => {
      const totalOccurrences =
        recurrenceSeries && getSeriesOccurrences({ recurrenceSeries })?.length;

      const remaindingOccurrences =
        recurrenceSeries &&
        getSeriesOccurrences({
          recurrenceSeries,
          defaultEndDate: calendarEvent.startDateTime
        })?.length;

      return (
        totalOccurrences &&
        remaindingOccurrences &&
        totalOccurrences - remaindingOccurrences + 1
      );
    };

    const otherCancellationReasonId = Array.from(
      booking.appointmentCancellationReasonsMap.values()
    ).find(x => x.text === "Other")?.id;

    return (
      <SubmissionForm<CancelAppointmentRecurrenceFormValues>
        formName="cancel-appointment-recurrence"
        styles={{ root: { minHeight: 250 }, fields: { flexGrow: 1 } }}
        initialValues={{ cancellationReasonId: "" }}
        validate={(values: CancelAppointmentRecurrenceFormValues) =>
          new CalendarEventRecurrenceCancelFormValidator(
            otherCancellationReasonId,
            captureApptCancellationReason
          ).validate(values)
        }
        buttonsProps={{
          submitButtonProps: {
            text: "Cancel series"
          },

          onCancel,
          disableSubmitOnPristine: captureApptCancellationReason
        }}
        disableRoutePrompt
        onSubmit={handleSubmitAction}
        onSubmitSucceeded={onSubmitted}
      >
        {() => {
          return (
            <Fieldset>
              <Stack.Item>
                Are you sure you want to cancel the{" "}
                {!isNever ? `${recurrenceOccurredCount()} remaining ` : null}
                {calendarEvent.isGroupAppointment
                  ? `recurring group appointments with ${calendarEvent.user?.fullName} `
                  : `recurring appointments for ${calendarEvent.contact?.name} with ${calendarEvent.user?.fullName} `}
                that occurs on or after{" "}
                {calendarEvent.startDateTime.toDayDefaultFormat()}?
              </Stack.Item>
              <CancelAppointmentFormFields />
            </Fieldset>
          );
        }}
      </SubmissionForm>
    );
  });
