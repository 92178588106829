import { DateTime } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isDateAfterDate,
  lessThan,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { TreatmentDetailsFormValues } from "@shared-types/acc/treatement-detais-values.type.ts";

export class TreatmentDetailsDraftValidator extends Validator<TreatmentDetailsFormValues> {
  constructor() {
    super();

    const dateLessThanOneWeek = DateTime.now().minus({ days: 7 });

    this.forField(nameof("followUpTreatmentsNeeded"), [maxLength(2)]);
    this.forField(nameof("treatmentsRequired"), [maxLength(2)]);
    this.forField(nameof("nailRemovalResectionTreatmentsNeeded"), [
      lessThan(11)
    ]);
    this.forField(nameof("nailSimpleRemovalTreatmentsNeeded"), [lessThan(11)]);
    this.forField(nameof("abscessHematomaTreatmentsNeeded"), [lessThan(11)]);
    this.forField(nameof("treatmentStartDate"), [
      isDateAfterDate(
        dateLessThanOneWeek,
        "This date is more than a week ago. Please enter a valid date"
      )
    ]);
  }
}
