import { useField } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { AnimatedListWithKeys, Stack } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { ContactPickerFieldWithAdd } from "../../../shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import {
  ExternalProviderFieldValue,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

export const ExternalProviders: React.FunctionComponent = () => {
  const {
    input: { value: externalProvider }
  } = useField<ExternalProviderFieldValue[]>(
    patientFormNameOf("externalProvider"),
    { subscription: { value: true } }
  );

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <FieldArray name={patientFormNameOf("externalProvider")}>
        {(
          fieldProps: FieldArrayRenderProps<
            ExternalProviderFieldValue,
            HTMLElement
          >
        ) => {
          if (fieldProps.fields.value.length === 0) {
            return null;
          }

          return (
            <AnimatedListWithKeys
              items={fieldProps.fields.value}
              onItemIdRemoved={id => {
                const index = fieldProps.fields.value.findIndex(
                  v => v.id === id
                );
                fieldProps.fields.remove(index);
              }}
            >
              {(id, onRemoveItem) => {
                const value = fieldProps.fields.value.find(p => p.id === id);
                const index = fieldProps.fields.value.findIndex(
                  p => p.id === id
                );

                if (value && index >= 0) {
                  return (
                    <Fieldset frame>
                      <ContactPickerFieldWithAdd
                        autoFocus={value.isNew}
                        label={Labels.externalProvider}
                        actionButton={{
                          linkProps: {
                            children: "Remove",
                            onClick: onRemoveItem
                          }
                        }}
                        includeInactive
                        name={`${patientFormNameOf(
                          "externalProvider"
                        )}[${index}].contactId`}
                        quickAddProps={{
                          addButtonLabel: Labels.newProvider
                        }}
                        filter={{
                          types: [
                            ContactType.Individual,
                            ContactType.Organisation
                          ]
                        }}
                        tagInUseKeys={externalProvider?.map(v => v.contactId!)}
                        placeholder="Search for provider"
                      />
                    </Fieldset>
                  );
                }
                return null;
              }}
            </AnimatedListWithKeys>
          );
        }}
      </FieldArray>
    </Stack>
  );
};
