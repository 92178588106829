import { FC } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { useStores } from "@stores/hooks/useStores.ts";

type ClinicalTaskActionMenuProps = {
  item: ClinicalTask;
};

export const ClinicalTaskActionMenu: FC<ClinicalTaskActionMenuProps> = ({
  item
}) => {
  const { routing, core } = useStores();

  const { patientId } = item;

  const menuItems: IContextualMenuItem[] = [];

  menuItems.push(
    {
      key: "recordUpdate",
      className: "record-update-clinical-menu-item",
      text: "Record update",
      onClick: () => {
        routing.push(
          routes.records.recordUpdate.path({ id: patientId }),
          routing.getStateWithFromQuery()
        );
      },
      disabled: !core.hasPermissions(Permission.ClinicalWrite)
    },
    {
      key: "viewClinicalRecord",
      className: "view-clinical-menu-item",
      text: "View clinical record",
      onClick: () => {
        routing.push(
          routes.records.recordView.path({ id: patientId }),
          routing.getStateWithFromQuery()
        );
      },
      disabled: !core.hasPermissions(Permission.ClinicalRead)
    }
  );

  return (
    <Stack horizontalAlign="center" verticalAlign="center">
      <TooltipHost content="More">
        <IconButton
          {...dataAttribute(
            DataAttributes.Element,
            "clinicaltask-list-actions-button"
          )}
          menuIconProps={{ iconName: "More" }}
          menuProps={{
            items: menuItems.map(i => ({
              ...dataAttribute(
                DataAttributes.Element,
                "clinicaltask-list-actions-list-item"
              ),
              ...i
            })),
            calloutProps: {
              ...dataAttribute(
                DataAttributes.Element,
                "clinicaltask-list-actions-list"
              )
            }
          }}
          styles={{
            root: { width: "32px", height: "36px", padding: 0 },
            flexContainer: { width: "32px", height: "36px" }
          }}
        />
      </TooltipHost>
    </Stack>
  );
};
