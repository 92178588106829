import { DATE_FORMATS } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isNotFutureDate,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { PatientDeclarationFormValues } from "@shared-types/acc/patient-declaration-values.type.ts";

export class PatientDeclarationDraftValidator extends Validator<PatientDeclarationFormValues> {
  constructor() {
    super();
    this.forField(nameof("patientDeclaration"), [
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isNotFutureDate()
    ]);
  }
}
