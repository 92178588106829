import { nameof } from "@libs/utils/name-of.utils.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimAdjustmentDocumentUploadFormValue } from "@shared-types/acc/claim-adjustment-document-upload-value.types.ts";
import { ClaimAdjustmentDocumentFormValue } from "@shared-types/acc/claim-adjustment-document-value.type.ts";

import { errorStatus } from "./DocumentFormValidator.tsx";

export class DocumentDraftValidator extends Validator<
  ClaimAdjustmentDocumentFormValue | ClaimAdjustmentDocumentUploadFormValue
> {
  constructor() {
    super();
    this.forField(nameof("status"), errorStatus());
  }
}
