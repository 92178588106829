import { observable } from "mobx";

import { flatten, FontWeights, IColumn } from "@bps/fluent-ui";
import { DateTime, isDefined, unique } from "@bps/utils";
import {
  ClinicalDataType,
  DASHModuleTypes,
  MeasurementDto,
  MeasurementType,
  PSFSMultipleQuestionnaireResponseDto,
  PSFSQuestionnaireResponseDto,
  QuestionnaireDto,
  QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalDataToMeasurementConverter } from "@modules/clinical/screens/patient-record/components/clinical-tools/utils/ClinicalDataToMeasurementConverter.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { generateDashMeasurements } from "../../clinical-tools/clinical-tools.utils.ts";
import { SeriesQuestionAnswers } from "../../clinical-tools/psfs/PSFSTrendDialog.types.ts";
import {
  createDynamicPsfsAnswerObject,
  getAverages
} from "../../clinical-tools/psfs/utils.ts";

export const SERIES_PREFIX = "series";
export class OutcomeMeasuresCardModel {
  private converter = new ClinicalDataToMeasurementConverter();
  constructor(private clinicalRecord: ClinicalRecord) {}

  @observable
  filledTools: ClinicalDataType[] = [];
  getComponentData = async (type: ClinicalDataType) => {
    const encounterId = this.clinicalRecord.openEncounter?.id;
    let questionnaire: QuestionnaireDto | undefined;
    let measurement: MeasurementDto | undefined;
    let data: QuestionnaireResponseDto | undefined;

    if (encounterId) {
      if (type === ClinicalDataType.PSFS || type === ClinicalDataType.NPRS) {
        questionnaire = await this.clinicalRecord.clinical.getQuestionnaires(
          this.converter.clinicalToQuestionnaire(type)
        );
      }

      const result = await this.clinicalRecord.clinical.getMeasurements({
        patientId: this.clinicalRecord.patient?.id,
        encounterId,
        types: [this.converter.clinicalToMeasurement(type)]
      });

      const measurements = result.results;

      if (measurements && measurements.length > 0) {
        switch (type) {
          case ClinicalDataType.PSFS:
            const psfsData = this.clinicalRecord.clinicalData?.psfs;

            if (psfsData && psfsData.responses.length > 0) {
              measurement = measurements.find(
                m => m.contextId === psfsData.responses[0].contextId
              );
              data = psfsData.responses[0];
            }
            break;
          case ClinicalDataType.NPRS:
            measurement = measurements[0];
            data = this.clinicalRecord.clinicalData
              ? this.clinicalRecord.clinicalData[
                  this.converter.clinicalToMeasurement(type).toLocaleLowerCase()
                ]
              : undefined;
            break;
          case ClinicalDataType.DASH:
            const modifiedMeasurements = generateDashMeasurements(measurements);

            if (modifiedMeasurements && modifiedMeasurements.length > 0) {
              measurement = modifiedMeasurements[0];
            }

            break;
          default:
            measurement = measurements[0];
            break;
        }
      }
    }

    return { questionnaire, measurement, data };
  };

  predicateDates = (a: MeasurementDto, b: MeasurementDto) =>
    a.changeLog?.createdDate &&
    b.changeLog?.createdDate &&
    a.changeLog?.createdDate < b.changeLog?.createdDate
      ? 1
      : -1;

  // need this to return the columns and answers array for the table
  getTrendData = async (type: ClinicalDataType, episodeOfCareId?: string) => {
    let questionnaire: QuestionnaireDto | undefined;
    let responses: PSFSQuestionnaireResponseDto[] = [];
    const encounterId =
      this.clinicalRecord.openEncounter?.episodeOfCareId === episodeOfCareId
        ? this.clinicalRecord.openEncounter?.id
        : undefined;

    const encounterResponse =
      (await this.clinicalRecord.clinical.getEncounters({
        episodeOfCareIds: episodeOfCareId ? [episodeOfCareId] : undefined,
        patientId: this.clinicalRecord.id
      })) ?? [];

    const eocEncounterIds = this.clinicalRecord
      .filterEncountersOnCurrentRole(encounterResponse)
      .map(e => e.id);

    let answersArray: SeriesQuestionAnswers[] = [];
    let arrayLength: number = 0;
    const columns: IColumn[] = [];

    if (type === ClinicalDataType.PSFS || type === ClinicalDataType.NPRS) {
      questionnaire = await this.clinicalRecord.clinical.getQuestionnaires(
        this.converter.clinicalToQuestionnaire(type)
      );
    }

    const result = await this.clinicalRecord.clinical.getMeasurements({
      patientId: this.clinicalRecord.patient?.id,
      types: [this.converter.clinicalToMeasurement(type)]
    });

    const allMeasurements = result.results;

    const measurement = allMeasurements.find(
      x => x.encounterId === encounterId
    );

    const linkedMeasurements = allMeasurements.filter(x =>
      eocEncounterIds.includes(x.encounterId)
    );

    const contextId = linkedMeasurements
      ? linkedMeasurements[0]?.contextId
      : undefined;

    arrayLength = linkedMeasurements.length;

    const linkedEncounterIds = unique(
      linkedMeasurements.map(x => x.encounterId)
    );

    if (allMeasurements && allMeasurements.length > 0) {
      switch (type) {
        case ClinicalDataType.PSFS:
          linkedMeasurements.sort(this.predicateDates);

          // last element is the oldest measurement in the series we always want to show the original series even if a new PSFS is created
          const lastMeasurement = linkedMeasurements.pop();
          if (lastMeasurement) {
            const contextId = lastMeasurement.contextId;
            const psfsSeriesContext =
              this.clinicalRecord.clinicalData?.psfsContext?.contexts.find(
                c => c.contextId === contextId
              );

            if (
              contextId &&
              linkedEncounterIds &&
              psfsSeriesContext &&
              questionnaire
            ) {
              const promises = linkedEncounterIds.map(x =>
                this.getEncounterPSFSData(x, contextId)
              );

              const respond = await Promise.all(promises);
              responses = flatten(respond.filter(isDefined));

              responses.sort((a, b) => {
                return a.createLog?.createdDateTime! <
                  b.createLog?.createdDateTime!
                  ? 1
                  : -1;
              });

              const questionIds = psfsSeriesContext.activities.map(
                x => x.questionnaireItemId
              );
              answersArray = createDynamicPsfsAnswerObject({
                questionnaire,
                responses,
                questionIds,
                psfsSeriesContext
              });

              const summaryRow = getAverages(responses, questionIds.length);
              answersArray.push(summaryRow);
              arrayLength = responses.length;
            }
          }
          break;

        case ClinicalDataType.DASH:
          [answersArray, arrayLength] = this.createDashDynamicAnswerObject(
            linkedMeasurements,
            linkedEncounterIds
          );
          break;

        default:
          answersArray = this.createDynamicAnswerObject(linkedMeasurements);
          break;
      }

      for (let x = 0; x < arrayLength; x++) {
        const name = this.recordDateString(
          x,
          {
            responses,
            linkedMeasurements
          },
          encounterId
        );

        const column = this.getColumn(x, arrayLength, name);
        columns.push(column);
      }

      const activitiesColumn: IColumn = this.getActiveColumn(arrayLength);
      columns.unshift(activitiesColumn);
    }

    return { questionnaire, answersArray, columns, measurement, contextId };
  };

  recordDateString = (
    key: number,
    arrays: {
      responses: PSFSQuestionnaireResponseDto[];
      linkedMeasurements: MeasurementDto[];
    },
    encounterId?: string
  ) => {
    const { responses, linkedMeasurements } = arrays;

    const stringDate =
      responses && responses.length > 0
        ? responses[key].createLog?.createdDateTime
        : linkedMeasurements && linkedMeasurements[key].changeLog?.createdDate;

    const actualDate = DateTime.fromISO(stringDate)?.toDayDefaultFormat();

    const thisEncounterId =
      responses && responses.length > 0
        ? responses[key].createLog?.createdEncounterId
        : linkedMeasurements && linkedMeasurements[key].encounterId;

    const isCurrentEncounter = encounterId && thisEncounterId === encounterId;
    const today = DateTime.now().toDayDefaultFormat();

    if (actualDate) {
      const isToday = actualDate === today;
      return isToday && isCurrentEncounter ? "Today" : actualDate;
    }
    return "";
  };

  getActiveColumn = (arrayLength: number): IColumn => {
    const SMALLEST_DESC_WIDTH = 128;
    const descColWidth = this.descriptionColumnWidth(
      arrayLength,
      95,
      SMALLEST_DESC_WIDTH
    );
    return {
      key: "Description",
      isRowHeader: true,
      minWidth: SMALLEST_DESC_WIDTH,
      maxWidth: descColWidth,
      name: "Description",
      onRender: (item: SeriesQuestionAnswers) => {
        return `${item.questionNumberText ?? ""} ${item.questionText}`;
      },
      isMultiline: true
    };
  };

  getColumn = (key: number, arrayLength: number, name: string): IColumn => {
    const fontWeight =
      key === arrayLength - 1 ? FontWeights.bold : FontWeights.regular;

    return {
      key: String(key),
      isRowHeader: true,
      minWidth: 75,
      maxWidth: 75,
      name,
      styles: { root: { fontWeight } },
      onRender: (item: SeriesQuestionAnswers) => {
        const answerKey = `${SERIES_PREFIX}${key}`;
        return item[answerKey] ?? "";
      },
      isMultiline: false
    };
  };

  descriptionColumnWidth = (
    arrayLength: number,
    columnWidth: number,
    smallestDescriptionWidth: number
  ) => {
    if (arrayLength >= 4) return smallestDescriptionWidth;
    if (arrayLength === 3) return smallestDescriptionWidth + columnWidth;
    if (arrayLength === 2) return smallestDescriptionWidth + columnWidth * 2;
    return smallestDescriptionWidth + columnWidth * 3;
  };

  getEncounterPSFSData = async (encounterId: string, contextId: string) => {
    const data = (
      await this.clinicalRecord.clinical.getEncounterClinicalData(
        {
          encounterId
        },
        { ignoreCache: true }
      )
    )[
      MeasurementType.PSFS.toLocaleLowerCase()
    ] as PSFSMultipleQuestionnaireResponseDto;

    if (data && data.responses) {
      return data.responses.filter(r => r.contextId === contextId);
    }
    return undefined;
  };

  createDynamicAnswerObject = (
    linkedMeasurements: MeasurementDto[] | undefined
  ) => {
    if (linkedMeasurements && linkedMeasurements.length > 0) {
      const measurements = linkedMeasurements.slice().sort(this.predicateDates);

      const questionAnswers: SeriesQuestionAnswers = {
        questionId: "q1",
        questionText: "Score"
      };

      return this.getAnswersArray(measurements, questionAnswers);
    }
    return [];
  };

  getAnswersArray = (
    measurements: MeasurementDto[] | MeasurementDto[][],
    questionAnswers: SeriesQuestionAnswers,
    predicate?: (dto: MeasurementDto) => boolean
  ) => {
    const answersArray: SeriesQuestionAnswers[] = [];
    let containValue: boolean = false;

    measurements.forEach(
      (item: MeasurementDto | MeasurementDto[], idx: number) => {
        const answerKey = `${SERIES_PREFIX}${idx}`;

        const searchedItem =
          Array.isArray(item) && predicate
            ? (item as MeasurementDto[]).find(predicate)
            : (item as MeasurementDto);

        const answer = searchedItem && (searchedItem?.value ?? 0);

        if (answer !== undefined) {
          questionAnswers[answerKey] = answer;
          containValue = true;
        }
      }
    );
    if (containValue) {
      answersArray.push(questionAnswers);
    }

    return answersArray;
  };

  createDashDynamicAnswerObject = (
    linkedMeasurements: MeasurementDto[] | undefined,
    linkedEncounterIds: string[]
  ): [SeriesQuestionAnswers[], number] => {
    // need to create special array object of linked measurements get all measurements for each encounter and add to an array then add all those ar
    const dashType = [
      DASHModuleTypes.Disability,
      DASHModuleTypes.Work,
      DASHModuleTypes.Sport
    ];

    if (linkedMeasurements && linkedMeasurements.length > 0) {
      let answersArray: SeriesQuestionAnswers[] = [];

      const dashMeasurementsGrouped: Array<Array<MeasurementDto>> =
        linkedEncounterIds
          .map(encounterId => {
            return linkedMeasurements.filter(
              x => x.encounterId === encounterId
            );
          })
          .sort((a, b) => this.predicateDates(a[0], b[0]));

      dashType.forEach((questionText, idx) => {
        const questionAnswers: SeriesQuestionAnswers = {
          questionId: `q${idx}`,
          questionText
        };

        answersArray = [
          ...answersArray,
          ...this.getAnswersArray(
            dashMeasurementsGrouped,
            questionAnswers,
            y => y.summary === questionText
          )
        ];
      });

      return [answersArray, dashMeasurementsGrouped.length];
    }

    return [[], 0];
  };
}
