import { dataAttribute, DataAttributes, FontIcon, Stack } from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";

import {
  BigCalendarEvent,
  invoiceStatusIcon
} from "./BookingCalendarEvent.types.ts";

interface BookingCalendarEventIconProps {
  event: BigCalendarEvent;
}

export const BookingCalendarEventIcons: React.FC<
  BookingCalendarEventIconProps
> = ({ event }) => {
  const { invoiceStatus } = event;
  const showRecurrenceIcon =
    event.calendarEventRecurrenceId !== undefined &&
    (event.type === CalendarEventType.Unavailable ||
      event.type === CalendarEventType.Appointment);

  if (event.model.noChargeComment && !showRecurrenceIcon) {
    return null;
  }
  return (
    <Stack.Item shrink={false}>
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        styles={{ root: { paddingTop: 3 } }}
      >
        {invoiceStatus && (
          <FontIcon
            {...dataAttribute(DataAttributes.Element, `${invoiceStatus}-icon`)}
            iconName={invoiceStatusIcon[invoiceStatus]}
            styles={{ root: { fontSize: 12 } }}
          />
        )}
        {showRecurrenceIcon && (
          <FontIcon
            {...dataAttribute(
              DataAttributes.Element,
              event.isPartOfRecurrence
                ? "is-part-of-recurrence-icon"
                : "is-not-part-of-recurrence-icon"
            )}
            iconName={
              event.isPartOfRecurrence ? "SyncOccurence" : "UnsyncOccurence"
            }
          />
        )}
      </Stack>
    </Stack.Item>
  );
};
