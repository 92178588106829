import { Grid, Stack, Text } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { Outcome } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { OutcomeDataItemDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export interface OutcomeLogProps {
  outcomeLog: OutcomeDataItemDto[];
}

export const OutcomeLog: React.FC<OutcomeLogProps> = ({ outcomeLog }) => {
  const { inbox, core } = useStores();
  const outcomeLabels = inbox.ref.outcomes.keyTextValues;
  const getContactAttemptNumber = (
    outcomeLog: OutcomeDataItemDto[],
    currentIndex: number
  ) => {
    const otherOutcome = outcomeLog.slice(0, currentIndex);
    const prevAttempts = otherOutcome.filter(
      x => x.outcomeStatus === Outcome.AttemptedContact
    ).length;

    const allattempts = outcomeLog.filter(
      x => x.outcomeStatus === Outcome.AttemptedContact
    ).length;

    return allattempts - prevAttempts;
  };

  if (outcomeLog) {
    return (
      <Stack tokens={{ childrenGap: 8 }}>
        {outcomeLog.map((value, index) => {
          const outcomeDateTime = value.outcomeDateTime;
          const outcomeDate = outcomeDateTime
            ? DateTime.fromISO(outcomeDateTime).toDayDefaultFormat()
            : undefined;

          const outcomeTime = outcomeDateTime
            ? DateTime.fromISO(outcomeDateTime).toFormat(
                TIME_FORMATS.TIME_FORMAT_WITH_MIDDAY_FULL
              )
            : undefined;

          const outcomeStatus = value.outcomeStatus
            ? outcomeLabels.find(y => y.key === value.outcomeStatus)?.text
            : undefined;

          const attemptNum =
            value.outcomeStatus === Outcome.AttemptedContact
              ? getContactAttemptNumber(outcomeLog, index)
              : undefined;

          const createdByUser = value.createdByUser;

          return (
            <Grid
              key={`${value.id} ${outcomeDateTime}`}
              templateColumns="1fr 1fr"
              childrenTokens={{ gap: 8 }}
            >
              <Stack tokens={{ childrenGap: 4 }}>
                <Text variant="small">
                  {`${outcomeStatus}${attemptNum ? ` (${attemptNum})` : ""}`}
                </Text>
                <Text>{outcomeDate}</Text>
                <Text>{outcomeTime}</Text>
              </Stack>
              <Stack tokens={{ childrenGap: 4 }}>
                <DataFetcher<User | undefined>
                  fetch={async () => {
                    if (createdByUser) {
                      return await core.getUserByUserName(createdByUser);
                    }
                    return undefined;
                  }}
                >
                  {user => user && <Text variant="small">{user.fullName}</Text>}
                </DataFetcher>

                <Text
                  styles={{
                    root: {
                      maxWidth: 140,
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }
                  }}
                >
                  {value.comment}
                </Text>
              </Stack>
            </Grid>
          );
        })}
      </Stack>
    );
  }
  return null;
};
