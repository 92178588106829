import { FormApi } from "final-form";

import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { PresentingComplaintContent } from "./PresentingComplaintContent.tsx";
import { PresentingComplaintFormValues } from "./PresentingComplaintForm.types.ts";
import { PresentingComplaintFormHelper } from "./PresentingComplaintFormHelper.tsx";
import { PresentingComplaintFormValidator } from "./PresentingComplaintFormValidator.ts";

interface PresentingComplaintFormProps {
  clinicalRecord: ClinicalRecord;
}

const PresentingComplaintFormBase: React.FunctionComponent<
  PresentingComplaintFormProps
> = ({ clinicalRecord }) => {
  const { clinical, notification } = useStores();
  const validator = new PresentingComplaintFormValidator();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const { getInitialValues, convertFormValuesToData } =
    new PresentingComplaintFormHelper(clinicalRecord);

  const submitData = async (values: PresentingComplaintFormValues) => {
    const data = convertFormValuesToData(values);

    const confirmedETag = clinicalRecord.clinicalData?.hofpcConfirmed?.eTag;

    data.hofpcConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };

    await clinicalRecord.saveClinicalData(data);
  };

  const onDismiss = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.CurrentInjury,
      ClinicalDataType.PhysioBodyDiagram
    );
  };

  const onReset = () => {
    onDismiss();
    clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
      ClinicalDataType.CurrentInjury,
      true
    );
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "physioBody",
      "currentInjury",
      "injury"
    ]);
  };

  const onSubmitSucceeded = (
    values: PresentingComplaintFormValues,
    form: FormApi<PresentingComplaintFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.CurrentInjury
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onReset();
    }
  };

  return (
    <ClinicalSubmissionForm<PresentingComplaintFormValues>
      formName="presentingComplaint"
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={getInitialValues()}
      readOnly={isViewOnlyOrDischarged}
      validate={validator.validate}
      disableRoutePrompt
      noGap
      heading="History of presenting complaint"
      onCancel={onReset}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyCurrentInjuryForm ||
        !!clinicalRecord.stashedClinicalData?.dirtyPhysioBodyForm ||
        !!clinicalRecord.stashedClinicalData?.dirtyInjuryForm ||
        !!clinicalRecord.stashedClinicalData?.haveBeenResetForms.get(
          ClinicalDataType.CurrentInjury
        )
      }
      disableButtonCondition={stashedData =>
        (!stashedData?.dirtyCurrentInjuryForm &&
          !stashedData?.dirtyPhysioBodyForm &&
          !stashedData?.dirtyInjuryForm) ||
        !!stashedData.haveBeenResetForms.get(ClinicalDataType.CurrentInjury)
      }
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.hofpcConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.hofpcConfirmed
      )}
    >
      <>
        <StashedClinicalDataFormSpy<PresentingComplaintFormValues>
          clinicalRecord={clinicalRecord}
          getData={convertFormValuesToData}
          areasToObserve={{
            currentInjury: [ClinicalDataType.CurrentInjury],
            injury: [ClinicalDataType.CurrentInjury],
            physioBody: [ClinicalDataType.PhysioBodyDiagram]
          }}
          withHasBeenResetFormCheck={ClinicalDataType.CurrentInjury}
        />
        <PresentingComplaintContent />
      </>
    </ClinicalSubmissionForm>
  );
};

export const PresentingComplaintForm = withFetch(
  x => [
    x.clinical.ref.progressionTypes.load(),
    x.clinical.ref.swellingTypes.load(),
    x.clinical.ref.stages.load(),
    x.clinical.ref.reactionSeverities.load(),
    x.clinical.ref.irritabilityLevels.load(),
    x.clinical.ref.natures.load(),
    x.clinical.ref.dailyPatterns.load(),
    x.clinical.ref.sleepPatterns.load(),
    x.clinical.ref.aggravatingFactors.load(),
    x.clinical.ref.easingFactors.load(),
    x.clinical.ref.investigations.load(),
    x.clinical.ref.onsets.load(),
    x.clinical.ref.durations.load(),
    x.clinical.ref.frequencyOccurrences.load(),
    x.clinical.ref.frequencies.load(),
    x.clinical.ref.impacts.load(),
    x.clinical.ref.hofPCAttributes.load(),
    x.clinical.ref.patientAppliedTreatments.load()
  ],
  PresentingComplaintFormBase
);
