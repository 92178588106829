import { observer } from "mobx-react-lite";

import {
  DetailsList,
  NoDataTile,
  RESET_CELLS_PADDING_CLASSNAME,
  Text
} from "@bps/fluent-ui";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { useIntegrationScreenContext } from "../context/IntegrationScreenContext.ts";
import { PhysitrackProviderMenu } from "./PhysitrackProviderContextualMenu.tsx";

export interface PhysitrackProviderItem {
  id: string;
  name: string | undefined;
  apiKey: string | undefined;
  providerId: string;
}

export const PhysitrackProviderList: React.FC = observer(() => {
  const { core, customIntegrations } = useStores();
  const { setNumberOfActivatedProviders } = useIntegrationScreenContext();
  const nameOf = nameOfFactory<PhysitrackProviderItem>();

  const fetch = async (): Promise<PhysitrackProviderItem[]> => {
    const physitrackDto = await customIntegrations.getPhysitrackApiKeys();
    const users = await core.fetchUsers({
      showOnCalendar: true,
      statusCodes: [UserStatus.Active],
      businessRoleClasses: [BusinessRoleClasses.Provider]
    });

    const hasApiKey = physitrackDto.length;
    setNumberOfActivatedProviders(physitrackDto.length);

    if (hasApiKey) {
      return physitrackDto.map(p => ({
        id: p.id,
        name: users.find(u => u.id === p.providerId)?.name,
        apiKey: p.apiKey,
        providerId: p.providerId
      }));
    }
    return [];
  };

  return (
    <DataFetcher
      fetch={fetch}
      refetchId={customIntegrations.ui.recentlyUpdatedPhysitrackApiKey}
    >
      {items =>
        items.length ? (
          <DetailsList
            items={items}
            columns={[
              {
                name: "",
                className: RESET_CELLS_PADDING_CLASSNAME,
                key: "action",
                minWidth: 50,
                maxWidth: 50,
                onRender: (item: PhysitrackProviderItem) => (
                  <PhysitrackProviderMenu item={item} />
                )
              },
              {
                key: nameOf("name"),
                name: "Name",
                minWidth: 244,
                maxWidth: 244,
                onRender: (item: PhysitrackProviderItem) => (
                  <Navigate
                    to={routes.settings.users.user.path({
                      id: item.providerId
                    })}
                  >
                    {item.name}
                  </Navigate>
                )
              },
              {
                key: nameOf("apiKey"),
                name: "API Key",
                minWidth: 244,
                onRender: (item: PhysitrackProviderItem) => (
                  <Text>{item.apiKey}</Text>
                )
              }
            ]}
          />
        ) : (
          <NoDataTile
            textProps={{ text: "No providers configured" }}
            linkProps={{ hidden: true }}
          />
        )
      }
    </DataFetcher>
  );
});
