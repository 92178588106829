import { FunctionComponent } from "react";

import { Heading, PersonaSize, Stack, Text, useTheme } from "@bps/fluent-ui";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { User } from "@stores/core/models/User.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { SmallText } from "../SmallText.tsx";
import { personaCard } from "./ImagingRequest.styles.ts";

export interface RequestUserCardProps {
  user?: User;
  location?: OrgUnit;
}

export const ImagingRequestUserCard: FunctionComponent<
  RequestUserCardProps
> = ({ user, location }) => {
  const theme = useTheme();

  if (!user || !location) return null;

  return (
    <>
      <Heading>From</Heading>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={personaCard(theme)}
      >
        <Persona
          id={user.id}
          size={PersonaSize.size48}
          imageInitials={user.initials}
          text={user.fullName}
          onRenderSecondaryText={() => (
            <>
              <Stack>
                <SmallText>Contact phone: {user.workPhone} </SmallText>
              </Stack>
              <Stack>
                <SmallText>Email: {user.email} </SmallText>
              </Stack>
            </>
          )}
        />
      </Stack>
      <Stack>
        <Text variant="xSmall" styles={{ root: { color: "inherit" } }}>
          Location
        </Text>
        <Text>{location.name}</Text>
        <Text>{location.address}</Text>
      </Stack>
    </>
  );
};
