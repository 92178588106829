// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { useField, useFormState } from "react-final-form";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { DrawingCanvas } from "../../notes/draw/DrawingCanvas.tsx";
import { InjuryImagesHelper } from "../context/InjuryImagesHelper.tsx";
import { sotapNameOf } from "../SOTAP.utils.ts";

enum ShoulderImageType {
  leftShldrSideImage = "0",
  leftShldrFrontImage = "1",
  leftShldrBackImage = "2",
  rightShldrSideImage = "3",
  rightShldrFrontImage = "4",
  rightShldrBackImage = "5"
}

export const ShoulderInjuryImageAssessment: React.FC = () => {
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const sex = clinicalRecord.patient?.sex;

  const {
    input: { onChange: onLeftFrontShoulderImage }
  } = useField(sotapNameOf("leftFrontShoulderImage"));

  const {
    input: { onChange: onLeftBackShoulderImage }
  } = useField(sotapNameOf("leftBackShoulderImage"));

  const {
    input: { onChange: onLeftSideShoulderImage }
  } = useField(sotapNameOf("leftSideShoulderImage"));

  const {
    input: { onChange: onRightFrontShoulderImage }
  } = useField(sotapNameOf("rightFrontShoulderImage"));

  const {
    input: { onChange: onRightBackShoulderImage }
  } = useField(sotapNameOf("rightBackShoulderImage"));

  const {
    input: { onChange: onRightSideShoulderImage }
  } = useField(sotapNameOf("rightSideShoulderImage"));

  const shoulderProps = InjuryImagesHelper.getShoulderProperties(sex);

  const images = InjuryImagesHelper.mapImagesOnPart(
    shoulderProps,
    initialValues
  );

  const handleOnChange = (
    canvas: Canvas,
    event: any,
    onChangeFunc: (x: any) => void
  ) => {
    if (event.target.dirty) {
      onChangeFunc(canvas?.toSVG());
    } else {
      onChangeFunc(undefined);
    }
  };
  return (
    <DrawingCanvas
      isViewOnly={
        isViewOnlyOrDischarged ||
        clinicalRecord.dischargeInProgressOrCompleted()
      }
      showQuickColourPicker={!isViewOnlyOrDischarged}
      initialSelectedImage={0}
      caption="Diagram"
      images={images}
      onChange={(canvas: Canvas, event: any) => {
        switch (canvas["lowerCanvasEl"].id as ShoulderImageType) {
          case ShoulderImageType.leftShldrFrontImage:
            handleOnChange(canvas, event, onLeftFrontShoulderImage);
            break;
          case ShoulderImageType.leftShldrBackImage:
            handleOnChange(canvas, event, onLeftBackShoulderImage);
            break;

          case ShoulderImageType.leftShldrSideImage:
            handleOnChange(canvas, event, onLeftSideShoulderImage);
            break;
          case ShoulderImageType.rightShldrFrontImage:
            handleOnChange(canvas, event, onRightFrontShoulderImage);
            break;

          case ShoulderImageType.rightShldrBackImage:
            handleOnChange(canvas, event, onRightBackShoulderImage);
            break;
          case ShoulderImageType.rightShldrSideImage:
            handleOnChange(canvas, event, onRightSideShoulderImage);
            break;

          default:
            return;
        }
      }}
    />
  );
};
