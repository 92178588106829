import { DateTime, getUniqueObjectsByKeys } from "@bps/utils";
import { ConvertToRefData } from "@libs/api/ref-data/RefData.ts";
import {
  CodedFieldClinicalDataItemDto,
  CustomClinicalToolClinicalDataDto,
  CustomClinicalToolContextDto,
  DiagnosesClinicalDataDto,
  DiagnosisDataItemDto,
  GoalDataItemDto,
  MedicalCertainty,
  MedicalHistoryClinicalDataDto,
  MedicalHistoryClinicalDataItemDto,
  ReasonForVisitClinicalDataDto,
  RefMotionTypeDto,
  TreatmentDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { CustomToolTrendAnswers } from "@shared-types/clinical/custom-tool-trend-answers.interface.ts";
import { DiagnosisSideData } from "@shared-types/clinical/diagnosis-side-data.interface.ts";
import { GoalDataItem } from "@shared-types/clinical/goal-data-item.interface.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { MotionTypeItem } from "@shared-types/clinical/motion-type-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { TreatmentData } from "@shared-types/clinical/treatment-data.interface.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { DEGREE_SYMBOL } from "./SOTAP.constants.ts";
import { CustomToolFieldData } from "./SOTAP.types.ts";

export const createCustomToolDynamicObject = (
  linkedContexts: CustomClinicalToolContextDto[],
  allCustomToolData: CustomClinicalToolClinicalDataDto[],
  thisEncounterResults: CustomToolFieldData[]
) => {
  const answersArray: CustomToolTrendAnswers[] = [];
  for (let i = 0; i < linkedContexts.length; i++) {
    const questionAnswers: CustomToolTrendAnswers = {
      contextId: linkedContexts[i].contextId,
      questionId: `q${i}`,
      name: linkedContexts[i].name,
      isReadOnly: false
    };

    for (let x = 0; x < allCustomToolData.length; x++) {
      const answerKey = `series${x}`;
      const answer = allCustomToolData[x].tools.find(
        x => x.contextId === linkedContexts[i].contextId
      )?.result;

      if (answer && answerKey) {
        questionAnswers[answerKey] = answer;
      }
    }

    if (thisEncounterResults && thisEncounterResults.length > 0) {
      const thisContextResult = thisEncounterResults.find(
        x => x.contextId === linkedContexts[i].contextId
      );
      questionAnswers.thisEncounterResult = thisContextResult?.result;
    } else {
      questionAnswers.thisEncounterResult = undefined;
    }
    answersArray.push(questionAnswers);
  }

  const columns: { key: string; name: string }[] = allCustomToolData.map(
    (i, idx) => {
      const recordDate = DateTime.fromISO(
        i.createLog?.createdDateTime
      )?.toDayDefaultFormat();

      return {
        key: `${idx}`,
        name: recordDate || ""
      };
    }
  );

  const linkedContextIds = linkedContexts.map(x => x.contextId);
  const newToolsThisEncounter = thisEncounterResults.filter(
    x => !linkedContextIds.includes(x.contextId)
  );
  if (newToolsThisEncounter) {
    newToolsThisEncounter.forEach(tool => {
      const toolResult: CustomToolTrendAnswers = {
        contextId: tool.contextId,
        name: tool.name,
        thisEncounterResult: tool.result,
        isReadOnly: true
      };

      answersArray.push(toolResult);
    });
  }

  return { answersArray, columns };
};

export const convertGoals = (
  goals: GoalDataItemDto[] | undefined
): GoalDataItem[] | undefined => {
  return goals?.map(x => ({
    goal: x.goal,
    startDate: DateTime.jsDateFromISO(x.startDate),
    endDate: DateTime.jsDateFromISO(x.endDate),
    isAchieved: x.isAchieved,
    achievedDate: DateTime.jsDateFromISO(x.achievedDate),
    notAchievedReason: x.notAchievedReason
  }));
};

export const checkGoalsHasValues = (
  goals: GoalDataItem[]
): GoalDataItemDto[] => {
  const stripedGoals = goals.filter(x => checkGoalHasValues(x));

  return stripedGoals.map(x => {
    return {
      goal: x.goal,
      startDate: x.startDate
        ? DateTime.jsDateToISODate(x.startDate)
        : undefined,
      endDate: x.endDate ? DateTime.jsDateToISODate(x.endDate) : undefined,
      isAchieved: x.isAchieved,
      achievedDate: x.achievedDate
        ? DateTime.jsDateToISODate(x.achievedDate)
        : undefined,
      notAchievedReason: x.notAchievedReason ?? undefined
    };
  });
};

const checkGoalHasValues = (goal: GoalDataItem) => {
  return goal.goal;
};

export const getMappedDiagnoses = (
  primaryDiagnosis: DiagnosisSideData[],
  additionalDiagnoses: DiagnosisSideData[]
) => {
  const diagnoses: DiagnosisDataItemDto[] = [];
  if (
    primaryDiagnosis &&
    primaryDiagnosis.length > 0 &&
    primaryDiagnosis[0].diagnosisKey
  ) {
    const primary: DiagnosisDataItemDto = {
      diagnosisCode: {
        code: primaryDiagnosis[0].diagnosis ?? "",
        originalText: primaryDiagnosis[0].originalText ?? "",
        codeSystem: primaryDiagnosis[0].codeSystem,
        version: primaryDiagnosis[0].version
      },
      diagnosisSide: primaryDiagnosis[0].side,
      isPrimaryDiagnosis: true
    };
    diagnoses.push(primary);
  }

  if (additionalDiagnoses && additionalDiagnoses.length > 0) {
    additionalDiagnoses.forEach(additionalDiagnosis => {
      const diagnosis: DiagnosisDataItemDto = {
        diagnosisCode: {
          code: additionalDiagnosis.diagnosis ?? "",
          originalText: additionalDiagnosis.originalText ?? "",
          codeSystem: additionalDiagnosis.codeSystem,
          version: additionalDiagnosis.version
        },
        diagnosisSide: additionalDiagnosis.side,
        isPrimaryDiagnosis: false
      };

      diagnoses.push(diagnosis);
    });
  }

  return diagnoses;
};

export const getDiagnoses = (
  clinicalRecord: ClinicalRecord,
  diagnosesClinicalData: DiagnosesClinicalDataDto | undefined,
  excludeUndiagnosed?: boolean
) => {
  const episodeOfCare = clinicalRecord.episodeOfCare;
  let primaryDiagnoses: DiagnosisSideData[] = [];
  let additionalDiagnoses: DiagnosisSideData[] = [];
  if (!diagnosesClinicalData) {
    if (episodeOfCare) {
      const diagnoses = episodeOfCare.diagnoses;
      if (diagnoses) {
        primaryDiagnoses = diagnoses
          .filter(
            x =>
              x.isPrimaryDiagnosis &&
              x.diagnosisCode?.originalText &&
              x.diagnosisSide
          )
          .map(x => {
            const sideData: DiagnosisSideData = {
              diagnosis: x.diagnosisCode?.code,
              originalText: x.diagnosisCode?.originalText,
              diagnosisKey: `${x.diagnosisCode?.code}${x.diagnosisCode?.originalText}`,
              codeSystem: x.diagnosisCode?.codeSystem,
              version: x.diagnosisCode?.version,
              side: x.diagnosisSide,
              isPrimaryDiagnosis: x.isPrimaryDiagnosis
            };

            return sideData;
          });
        additionalDiagnoses = diagnoses
          .filter(
            x =>
              !x.isPrimaryDiagnosis &&
              x.diagnosisCode?.originalText &&
              x.diagnosisSide
          )
          .map(x => {
            const sideData: DiagnosisSideData = {
              diagnosis: x.diagnosisCode?.code,
              originalText: x.diagnosisCode?.originalText,
              diagnosisKey: `${x.diagnosisCode?.code}${x.diagnosisCode?.originalText}`,
              codeSystem: x.diagnosisCode?.codeSystem,
              version: x.diagnosisCode?.version,
              side: x.diagnosisSide,
              isPrimaryDiagnosis: x.isPrimaryDiagnosis
            };

            return sideData;
          });
      }
    }
  }

  if (diagnosesClinicalData && diagnosesClinicalData.diagnoses) {
    primaryDiagnoses = diagnosesClinicalData.diagnoses
      .filter(
        x =>
          x.isPrimaryDiagnosis &&
          x.diagnosisCode?.originalText &&
          x.diagnosisSide
      )
      .map(x => {
        const sideData: DiagnosisSideData = {
          diagnosis: x.diagnosisCode?.code,
          originalText: x.diagnosisCode?.originalText,
          diagnosisKey: `${x.diagnosisCode?.code}${x.diagnosisCode?.originalText}`,
          side: x.diagnosisSide,
          codeSystem: x.diagnosisCode?.codeSystem,
          version: x.diagnosisCode?.version,
          isPrimaryDiagnosis: x.isPrimaryDiagnosis
        };

        return sideData;
      });

    additionalDiagnoses = diagnosesClinicalData.diagnoses
      .filter(
        x =>
          !x.isPrimaryDiagnosis &&
          x.diagnosisCode?.originalText &&
          x.diagnosisSide
      )
      .map(x => {
        const sideData: DiagnosisSideData = {
          diagnosis: x.diagnosisCode?.code,
          originalText: x.diagnosisCode?.originalText,
          diagnosisKey: `${x.diagnosisCode?.code}${x.diagnosisCode?.originalText}`,
          side: x.diagnosisSide,
          codeSystem: x.diagnosisCode?.codeSystem,
          version: x.diagnosisCode?.version,
          isPrimaryDiagnosis: x.isPrimaryDiagnosis
        };

        return sideData;
      });
  }

  if (primaryDiagnoses.length === 0 && !excludeUndiagnosed)
    primaryDiagnoses.push({
      diagnosisKey: undefined,
      diagnosis: undefined,
      originalText: undefined,
      side: undefined,
      isPrimaryDiagnosis: true,
      readCodes: [""],
      codeSystem: undefined,
      version: undefined
    });

  return {
    primaryDiagnoses,
    additionalDiagnoses,
    multipleDiagnoses: additionalDiagnoses.length > 0
  };
};

export const getTreatmentData = (
  treatments: TreatmentDataItemDto[] | undefined,
  isTreatAndEdu: boolean
): TreatmentData[] => {
  if (!treatments) {
    return [];
  }

  return treatments.map((treatment: TreatmentDataItemDto, index: number) => {
    return {
      key: index,
      isTreatAndEdu,
      treatment: treatment.treatment,
      comment: treatment.comment,
      check: true
    };
  });
};

export const getReasonForVisitsFromDiagnosis = (
  diagnosisSideData: DiagnosisSideData[]
) => {
  const rfvFromDiagnosis: CodedFieldClinicalDataItemDto[] = [];
  diagnosisSideData.forEach(dsd => {
    const { diagnosis: code, originalText, version, codeSystem } = dsd;
    if (code && originalText)
      rfvFromDiagnosis.push({
        code,
        originalText,
        codeSystem,
        version
      });
  });
  return rfvFromDiagnosis;
};

export const createDefaultInjuryAreaMotionAssessment = (props: {
  injuryArea: string;
  injuryAreaGroup: string;
  refData: ConvertToRefData<RefMotionTypeDto>[];
  injurySide?: string;
}) => {
  const filterBy = props.injuryAreaGroup;

  const motionTypesRef = props.refData.map(
    ({ code, text, injuryAreaMotionTypeGroups }) => ({
      key: code,
      name: text,
      injuryAreaMotionTypeGroups
    })
  );

  const motionTypes = motionTypesRef
    .filter(x => x.injuryAreaMotionTypeGroups.includes(filterBy))
    .map(x => {
      return {
        text: x.name,
        code: x.key,
        active: `0${DEGREE_SYMBOL}`,
        passive: `0${DEGREE_SYMBOL}`,
        difference: 0
      } as MotionTypeItem;
    });

  const assessment: InjuryAreaMotionAssessmentItem = {
    injuryArea: props.injuryArea,
    injuryAreaGroup: props.injuryAreaGroup,
    motionTypes,
    injurySide: props.injurySide
  };
  return assessment;
};

export const getUpdatedMedicalHistory = (
  diagnoses: DiagnosisDataItemDto[],
  medicalHistory?: MedicalHistoryClinicalDataDto,
  options?: {
    episodeOfCareId?: string;
    diagnosisDate?: string;
    isAddItem?: boolean;
    isFirstMultiRoleConsult: boolean;
  }
) => {
  const medicalHistories = medicalHistory?.medicalHistories ?? [];
  const filtedMedicalHistories = medicalHistories?.filter(
    item => item.episodeOfCareId !== options?.episodeOfCareId
  );

  const newItems: Omit<MedicalHistoryClinicalDataItemDto, "id">[] =
    diagnoses.map(item => {
      return {
        active: true,
        diagnosis: item?.diagnosisCode?.code,
        diagnosisCode: item?.diagnosisCode,
        diagnosisSide: item?.diagnosisSide,
        certainty: MedicalCertainty.Confirmed,
        episodeOfCareId: options?.episodeOfCareId,
        diagnosisDate: DateTime.fromISO(options?.diagnosisDate)?.toISODate()
      };
    });

  const updatedMedicalHistories = filtedMedicalHistories?.concat(newItems);

  const updatedMedicalHistory: MedicalHistoryClinicalDataDto = {
    eTag: medicalHistory?.eTag,
    medicalHistories: options?.isFirstMultiRoleConsult
      ? medicalHistories
      : updatedMedicalHistories,
    noSignificantHistory: medicalHistory?.noSignificantHistory ?? false
  };
  return updatedMedicalHistory;
};

export const getReasonForVisit = (
  primaryDiagnosis: DiagnosisSideData[],
  existingReasonForVisits?: ReasonForVisitClinicalDataDto
): ReasonForVisitClinicalDataDto => {
  const mergedReasonsForVisit = [
    ...(existingReasonForVisits?.reasonForVisits || []),
    ...(getReasonForVisitsFromDiagnosis(primaryDiagnosis) || [])
  ];

  const reasonForVisits = getUniqueObjectsByKeys({
    array: mergedReasonsForVisit,
    keys: ["code"]
  });

  return {
    ...existingReasonForVisits,
    reasonForVisits
  };
};

export const getTimeUntilGoalCompletionText = (props: {
  goals: GoalDataItem[];
  goalIndex: number;
  dateToCompareTo?: DateTime;
  achievedDate?: boolean;
}) => {
  const goal: GoalDataItem = props.goals[props.goalIndex];
  // If the achieved value has not been set, don't show.
  if (!props.achievedDate && (goal.isAchieved === false || goal.isAchieved)) {
    return undefined;
  }

  let comparisonDate = DateTime.today();

  if (props.dateToCompareTo) {
    comparisonDate = props.dateToCompareTo;
  }

  if (goal.startDate && goal.endDate) {
    const endDate = DateTime.fromJSDate(goal.endDate).startOf("day");

    const diff = endDate.diff(comparisonDate, ["weeks", "days", "hours"]);

    if (diff.weeks === 0 && diff.days === 0) {
      return props.achievedDate ? "(in time)" : "Due today";
    }

    if (diff.weeks === 0) {
      if (diff.days < 0) {
        const text = `${Math.abs(diff.days)} days late`;

        return props.achievedDate ? `(${text})` : text;
      }

      return props.achievedDate
        ? `(${diff.days} days early)`
        : `Due in ${diff.days} days`;
    }

    if (diff.weeks < 0) {
      const text = `${Math.abs(diff.weeks)} weeks late`;
      return props.achievedDate ? `(${text})` : text;
    }

    return props.achievedDate
      ? `(${diff.weeks} weeks early)`
      : `Due in ${diff.weeks} weeks`;
  }

  return undefined;
};

export const getGoalAchievedDateText = (
  goals: GoalDataItem[],
  goalIndex: number
) => {
  const goal: GoalDataItem = goals[goalIndex];
  if (
    !goal.isAchieved ||
    !goal.startDate ||
    !goal.endDate ||
    !goal.achievedDate
  ) {
    return undefined;
  }

  const achievedDate = DateTime.fromJSDate(goal.achievedDate).startOf("day");

  return getTimeUntilGoalCompletionText({
    goals,
    goalIndex,
    dateToCompareTo: achievedDate,
    achievedDate: true
  });
};
export const sotapNameOf = nameOfFactory<SOTAPFormValues>();
