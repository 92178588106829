import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

/**
 * We need to map our model into a different object because
 * React Big Calendar directly modifies it in a way that won't
 * work with our model.
 */
export type BigCalendarEvent = Pick<
  CalendarEvent,
  | "id"
  | "user"
  | "startDateTime"
  | "endDateTime"
  | "typeRef"
  | "type"
  | "contact"
  | "contactId"
  | "userId"
  | "priority"
  | "isPatientCalendarEvent"
  | "appointmentStatus"
  | "calendarEventRecurrenceId"
  | "purpose"
  | "isPartOfRecurrence"
  | "bookedByUser"
  | "bookedByType"
  | "hasMultipleLocks"
  | "invoiceStatus"
  | "calendarEventExtension"
  | "hasOpenEncounters"
  | "changeLog"
  | "orgUnitId"
  | "isGroupAppointment"
  | "groupAppointmentName"
  | "attendanceStatus"
> & {
  model: CalendarEvent;
  // pseudo event = an event the user can't interact with such as temporary events created while
  //  a user is creating an appointment, or an event to show that a time range is unavailable
  isPseudoEvent?: boolean;
  // These dates should be pre-calculated so the calendar doesn't have to convert them from DateTimes on every render
  startDate: Date;
  endDate: Date;
};

export interface BookingCalendarEventProps {
  event: BigCalendarEvent;
}
const { Paid, Unpaid, Owing, Settled, Part } = InvoiceStatus;
export const invoiceStatusIcon = {
  [Paid]: "Completed",
  [Settled]: "Completed",
  [Unpaid]: "BpOwing",
  [Owing]: "BpOwing",
  [Part]: "BpOwing"
};
