import { observer } from "mobx-react-lite";

import { SubmitActionCode } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import { TemplateFilter } from "./context/TemplatePickerFormModel.ts";

type TemplateFilterProps = Pick<FilterBarProps<TemplateFilter>, "children">;

export enum AuthorTypes {
  Me = "Me",
  Practice = "Practice",
  System = "System"
}

export const TemplatePickerFilter: React.FC<TemplateFilterProps> = observer(
  ({ children }) => {
    const { correspondence } = useStores();

    const correspondenceTypes =
      correspondence.ref.correspondenceTypes.keyTextValues;

    return (
      <FilterBar<TemplateFilter>
        initialValues={{}}
        items={[
          {
            type: "searchBox",
            name: "searchText",
            stickItem: true,
            props: {
              placeholder: "Filter by name",
              styles: { root: { maxWidth: "180px", flexGrow: 1 } }
            }
          },
          {
            type: "optionsSelect",
            name: "documentAuthor",
            props: {
              placeholder: "Author",
              label: "Author",
              options: [
                { key: AuthorTypes.Me, text: "Me" },
                { key: AuthorTypes.Practice, text: "My practice" },
                { key: AuthorTypes.System, text: "System" }
              ],
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "optionsSelect",
            name: "documentTypes",
            props: {
              placeholder: "Doc. type",
              label: "Doc. Type",
              options: correspondenceTypes,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "optionsSelect",
            name: "documentStatus",
            props: {
              placeholder: "Status",
              label: "Status",
              options: [
                { key: SubmitActionCode.DraftCode, text: "Draft" },
                { key: SubmitActionCode.PublishToSelfCode, text: "Private" },
                { key: SubmitActionCode.PublishToEveryoneCode, text: "Shared" }
              ],
              hideSearchOption: true,
              calloutWidth: 200
            }
          }
        ]}
        styles={{
          calloutItem: {
            ":nth-child(1)": { width: "100%" },
            ":nth-child(2)": { width: "100%" },
            ":nth-child(3)": { width: "100%" }
          },
          callout: {
            width: 288
          }
        }}
        children={children}
      />
    );
  }
);
