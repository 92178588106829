import { FunctionComponent } from "react";
import { useField } from "react-final-form";

import { dataAttribute, DataAttributes, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { medHistoryNameOf } from "./MedicalHistoryDateFields.tsx";
import { getYearFromAge } from "./utils.ts";

interface MedicalHistoryAgePickerProps {
  birthDate?: Date;
  disabled?: boolean;
}
export const MedicalHistoryAgePicker: FunctionComponent<
  MedicalHistoryAgePickerProps
> = ({ birthDate, disabled }) => {
  const {
    input: { value: age },
    meta: { error }
  } = useField("age", {
    subscription: { value: true, error: true }
  });

  const {
    input: { value: year }
  } = useField("year", {
    subscription: { value: true }
  });

  const renderPatientYearText = () => {
    const displayYear: string | undefined =
      birthDate && age
        ? String(getYearFromAge(DateTime.fromJSDate(birthDate), age))
        : year;

    return `Year (approx.) ${Number(displayYear) > 0 ? displayYear : ""}`;
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <SpinNumberInputField
        label="Patient age"
        name={medHistoryNameOf("age")}
        step={1}
        min={1}
        disabled={!birthDate || disabled}
        placeholder={!birthDate ? "Birth date not set" : undefined}
        styles={{ root: { width: 160 } }}
      />
      {!error && (
        <Text
          {...dataAttribute(
            DataAttributes.Element,
            "medical-history-patient-year"
          )}
          variant="small"
        >
          {renderPatientYearText()}
        </Text>
      )}
    </Stack>
  );
};
