import { observer } from "mobx-react-lite";

import { TemplateListItem } from "@modules/clinical/screens/correspondence/components/TemplatePivot.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteDialog } from "@ui-components/DeleteDialog.tsx";

import { TemplatePickerState } from "../../correspondence/components/TemplatePickerForm.types.ts";
import { useTemplateManagerFormContext } from "../context/TemplateManagerFormContext.ts";

type DeleteReactionDialogProps = {
  templatePickerState?: TemplatePickerState;
  setTemplatePickerState?: React.Dispatch<
    React.SetStateAction<TemplatePickerState>
  >;
  lauchFrom?: string;
  items?: TemplateListItem[];
};
const DeleteReactionDialogBase: React.FC<DeleteReactionDialogProps> = observer(
  () => {
    const { document } = useStores();
    const { templateMap, setSelectedTemplateId, selectedTemplateId } = document;

    const selectedTemplate = templateMap.get(selectedTemplateId ?? "");
    const model = useTemplateManagerFormContext();
    const { showDeleteTemplateDialog, setShowDeleteTemplateDialog } = model;
    const onConfirm = model.deleteTemplate
      ? model.deleteTemplate
      : document.deleteTemplate;
    return (
      <DeleteDialog
        onConfirm={onConfirm}
        title="Confirm template deletion"
        subText={`Do you want to permanently delete ${selectedTemplate?.name}`}
        options={document.ref.reasonsForDeleteTemplate.keyTextValues}
        hidden={!(showDeleteTemplateDialog && selectedTemplateId)}
        onDeleteSucceeded={() => setShowDeleteTemplateDialog(false)}
        onCancel={() => {
          setSelectedTemplateId(undefined);
          setShowDeleteTemplateDialog(false);
        }}
        formName="deleteTemplateDialog"
      />
    );
  }
);

export const DeleteTemplateDialog = withFetch(
  x => [x.document.ref.reasonsForDeleteTemplate.load()],
  DeleteReactionDialogBase
);
