import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Heading, Stack, useTheme } from "@bps/fluent-ui";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";

import { invoiceDetailsRowHeadingStyles } from "./InvoiceDetailsRow.styles.ts";

interface InvoiceDetailsRowNotesProps {
  reference: InvoiceFormValues["reference"];
}

export const InvoiceDetailsRowNotes: FunctionComponent<InvoiceDetailsRowNotesProps> =
  observer(props => {
    const { reference } = props;
    const theme = useTheme();

    return (
      <Stack grow styles={{ root: { width: 160 } }}>
        <Heading styles={invoiceDetailsRowHeadingStyles}>Notes</Heading>
        <Stack
          styles={{
            root: {
              backgroundColor: theme.palette.neutralLighterAlt,
              padding: 8,
              height: !!reference?.length ? 60 : 30,
              overflowX: "auto"
            }
          }}
          grow
        >
          {reference || ""}
        </Stack>
      </Stack>
    );
  });
