import { FC, PropsWithChildren } from "react";

import { Link, Text, TooltipHost } from "@bps/fluent-ui";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface OptedOutTooltipProps extends PropsWithChildren {
  contact: Contact | undefined;
}

export const OptedOutTooltip: FC<OptedOutTooltipProps> = ({
  children,
  contact
}) => {
  const { practice } = useStores();

  return (
    <TooltipHost
      content={
        contact?.billingOptedOut ? (
          <Text block styles={{ root: { textAlign: "center" } }}>
            The recipient opted out of receiving billing correspondence.&nbsp;
            <Link
              id="edit-contact-link"
              onClick={() =>
                practice.ui.showEditContact(PatientCardIds.prefCons, contact.id)
              }
            >
              Update preferences
            </Link>
          </Text>
        ) : undefined
      }
    >
      {children}
    </TooltipHost>
  );
};
