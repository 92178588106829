import { isEqual } from "lodash";

import {
  InjuryArea,
  InjuryAreaGroups,
  InjuryAreaSides
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";

export const EDITED_ICON_NAME = "PageEdit";
export const getPivotIcon =
  (
    motionAssessments: InjuryAreaMotionAssessmentItem[] | undefined = [],
    initialMotionAssessments: InjuryAreaMotionAssessmentItem[] | undefined = []
  ) =>
  (props: {
    injuryArea: InjuryArea;
    injuryAreaGroup: InjuryAreaGroups | InjuryArea;
    injuryAreaSide?: InjuryAreaSides;
  }) => {
    const { injuryArea, injuryAreaSide, injuryAreaGroup } = props;

    const injuryAreaMotionAssessments = motionAssessments?.filter(
      i => i.injuryArea === injuryArea
    );

    const initialInjuryAreaMotionAssessments = initialMotionAssessments?.filter(
      i => i.injuryArea === injuryArea
    );

    const isFilled = !isEqual(
      injuryAreaMotionAssessments.filter(
        i =>
          i.injuryAreaGroup === injuryAreaGroup &&
          i.injurySide === injuryAreaSide
      ),
      initialInjuryAreaMotionAssessments.filter(
        i =>
          i.injuryAreaGroup === injuryAreaGroup &&
          i.injurySide === injuryAreaSide
      )
    )
      ? EDITED_ICON_NAME
      : undefined;

    return isFilled ? EDITED_ICON_NAME : undefined;
  };
