import {
  FontIcon,
  FontSizes,
  noWrap,
  Text,
  Theme,
  TooltipHost,
  TooltipOverflowMode
} from "@bps/fluent-ui";
import { compareDatesOrUndefinedPredicate } from "@bps/utils";
import { routes } from "@libs/routing/routes.ts";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { EM_DASH_SYMBOL } from "@modules/acc/screens/claims/components/ClaimNumberCell.tsx";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const sortByNextAppointmentDatePredicate = (
  a: Condition,
  b: Condition
) => {
  if (a.nextCalendarEventDate || b.nextCalendarEventDate) {
    return compareDatesOrUndefinedPredicate(
      a.nextCalendarEventDate,
      b.nextCalendarEventDate,
      true
    );
  }
  return 0;
};

export const sortByDiagnosisTextPredicate = (a: Condition, b: Condition) => {
  const aDiagnosisText =
    a.primaryDiagnosis !== ClaimFormLabels.undiagnosed
      ? a.primaryDiagnosis
      : undefined;

  const bDiagnosisText =
    b.primaryDiagnosis !== ClaimFormLabels.undiagnosed
      ? b.primaryDiagnosis
      : undefined;

  //compare diagnosisText
  if (aDiagnosisText && bDiagnosisText) {
    //continue sorting if strings are the same.
    return aDiagnosisText
      .trim()
      .toLowerCase()
      .localeCompare(bDiagnosisText.trim().toLowerCase());
  } else if (aDiagnosisText) {
    return -1;
  } else if (bDiagnosisText) {
    return 1;
  }
  return 0;
};

export const dischargePredicate = (a: Condition, b: Condition) => {
  //finally sort by dischared
  if (a.discharged && !b.discharged) {
    return 1;
  } else if (!a.discharged && b.discharged) {
    return -1;
  }
  return 0;
};

export const sortConditions = (conditionListItems: Condition[]) =>
  conditionListItems.sort(
    (a, b) =>
      dischargePredicate(a, b) ||
      sortByNextAppointmentDatePredicate(a, b) ||
      sortByDiagnosisTextPredicate(a, b)
  );

export const renderDiagnosisCell = (item: Condition, theme: Theme) => {
  const longDashSymbol = String.fromCodePoint(EM_DASH_SYMBOL);
  const diagnosisText =
    item.primaryDiagnosis !== ClaimFormLabels.undiagnosed
      ? item.primaryDiagnosis
      : longDashSymbol;

  let toolTipContent;
  if (item.isReferral) {
    toolTipContent = `Referred: ${diagnosisText}`;
  } else if (item.discharged) {
    toolTipContent = `Discharged: ${diagnosisText}`;
  } else {
    toolTipContent = diagnosisText;
  }

  const fontIcon =
    item.isReferral || item.discharged ? (
      <FontIcon
        iconName={item.isReferral ? "FollowUser" : "Leave"}
        styles={{
          root: {
            fontSize: FontSizes.size16,
            padding: 4,
            borderRadius: 4,
            marginRight: 4,
            display: "inline-block",
            backgroundColor: item.discharged
              ? theme.semanticColors.disabledBackground
              : undefined,
            position: "relative",
            verticalAlign: "middle",
            bottom: 1
          }
        }}
      />
    ) : undefined;

  const tooltip = (
    <TooltipHost
      content={toolTipContent}
      overflowMode={
        item.isReferral || item.discharged
          ? undefined
          : TooltipOverflowMode.Self
      }
      styles={{ root: { display: "block", ...noWrap } }}
    >
      {fontIcon} <Text>{diagnosisText}</Text>
    </TooltipHost>
  );

  return (
    <>
      {item.isPrivate ? (
        <Navigate
          to={routes.conditions.summary.path({
            id: item.id
          })}
        >
          {tooltip}
        </Navigate>
      ) : (
        tooltip
      )}
    </>
  );
};
