import { DateTime, isToday } from "@bps/utils";
import {
  AddDocumentDto,
  CodedFieldClinicalDataItemDto,
  CorrespondenceDirection,
  CorrespondenceType,
  DocumentEnum,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { FileFieldValue } from "@ui-components/form/FilePickerField/FilePickerField.types.ts";

export const getAddDocumentDto = async (options: {
  files: Array<
    FileFieldValue & {
      date: Date;
      type?: CorrespondenceType;
      subject?: string;
    }
  >;
  patientId: string;
  encounterId?: string;
  claimAdjustmentId?: string;
  direction?: CorrespondenceDirection;
  to?: string;
  from?: string;
  store: StoreType;
  showOnTimeline?: boolean;
}) => {
  const {
    files,
    direction,
    patientId,
    to,
    from,
    encounterId,
    claimAdjustmentId,
    store,
    showOnTimeline
  } = options;
  return files.map<AddDocumentDto>(file => {
    const metadata = [
      {
        key: DocumentEnum.Date,
        value: DateTime.jsDateToISODate(file.date)
      },
      {
        key: DocumentEnum.Extension,
        value: file.extension
      },
      { key: DocumentEnum.Name, value: file.subject || file.name || "" }
    ];

    if (encounterId) {
      metadata.push({
        key: DocumentEnum.EncounterId,
        value: encounterId
      });
    }

    if (claimAdjustmentId) {
      metadata.push({
        key: DocumentEnum.claimAdjustmentId,
        value: claimAdjustmentId
      });
    }

    if (showOnTimeline) {
      metadata.push({
        key: DocumentEnum.ShowOnTimeline,
        value: true.toString()
      });
    }

    const document: AddDocumentDto = {
      id: file.blobName,
      patientId,
      type: file.type,
      direction,
      from,
      to,
      store,
      metadata,
      showOnTimeline
    };

    return document;
  });
};

export const getReasonsText = (
  reasonForVisits: CodedFieldClinicalDataItemDto[]
) => {
  const reasonForVisitValue: string[] | undefined = reasonForVisits
    ?.filter(x => !x.isDeleted)
    .map(rfv => `${rfv.code}.${rfv.originalText}`);

  if (reasonForVisitValue === undefined || reasonForVisitValue.length < 1)
    return "Unspecified";

  const reasonForVisitTextList = reasonForVisitValue.map(x => x.split(".")[1]);

  return reasonForVisitTextList.reduce((p, d, i) => {
    return p + (i === reasonForVisitValue.length - 1 ? " and " : ", ") + d;
  });
};

export const getTimePickerProps = (startDateInput: string) => {
  const startDate = DateTime.fromISO(startDateInput);
  const isStartDateToday = isToday(startDate);
  const isStartDateAfterToday =
    startDate.startOf("day") > DateTime.now().plus({ days: 1 });

  const maxTime = isStartDateToday
    ? DateTime.now().toTimeInputFormat()
    : undefined;
  return {
    isStartDateToday,
    isStartDateAfterToday,
    maxTime
  };
};

export const getEncounterDayString = (encounterDateTime: DateTime) => {
  let dayString = encounterDateTime.toDayDefaultFormat();
  if (DateTime.today().equals(encounterDateTime.startOf("day"))) {
    dayString = "today";
  } else if (
    DateTime.now()
      .startOf("day")
      .minus({ days: 1 })
      .equals(encounterDateTime.startOf("day"))
  ) {
    dayString = "yesterday";
  }
  return dayString;
};
