import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useForm } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PatientDeclarationFormValidator } from "@modules/acc/screens/claim/validators/PatientDeclarationFormValidator.tsx";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { PatientDeclarationFormValues } from "@shared-types/acc/patient-declaration-values.type.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { claimFormNameOf } from "../claim.utils.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";

const patientFields: (keyof PatientDeclarationFormValues)[] = [
  "patientAccContactAuthorised",
  "patientClaimAuthorised",
  "patientInfoAuthorised",
  "patientDeclaration",
  "patientDeclared"
];

export const PatientDeclarationFormSection: FC = observer(() => {
  const form = useForm<ClaimFormValues>();
  const { selectedMenuItemId } = useCardFormLayoutContext();

  return (
    <ClaimCard
      id={ClaimsCardIds.patientDeclaration}
      openOnRender={selectedMenuItemId === ClaimsCardIds.patientDeclaration}
      heading={ClaimSectionHeadings.patientDeclaration}
      iconName="TextDocumentShared"
      statusValidator={new PatientDeclarationFormValidator()}
      errorProps={{ fields: patientFields }}
    >
      <FieldSpy
        name={claimFormNameOf("patientId")}
        onChange={id => {
          form.batch(() => {
            if (!id) {
              form.change(claimFormNameOf("patientDeclared"), undefined);
              form.change(claimFormNameOf("patientClaimAuthorised"), undefined);
              form.change(claimFormNameOf("patientInfoAuthorised"), undefined);
              form.change(
                claimFormNameOf("patientAccContactAuthorised"),
                undefined
              );
              form.change(claimFormNameOf("patientDeclaration"), undefined);
            }
          });
        }}
      />
      <Stack tokens={{ childrenGap: 8 }}>
        <Heading variant="section-sub-heading" hasAsterisk>
          {ClaimFormLabels.declaration}
        </Heading>
        <CheckboxField
          label={ClaimFormLabels.patientDeclared}
          name={claimFormNameOf("patientDeclared")}
        />
        <Heading variant="section-sub-heading" hasAsterisk>
          {ClaimFormLabels.authorisation}
        </Heading>
        <CheckboxField
          label={ClaimFormLabels.patientClaimAuthorised}
          name={claimFormNameOf("patientClaimAuthorised")}
        />
        <CheckboxField
          label={ClaimFormLabels.patientInfoAuthorised}
          name={claimFormNameOf("patientInfoAuthorised")}
        />
        <CheckboxField
          label={ClaimFormLabels.patientAccContactAuthorised}
          name={claimFormNameOf("patientAccContactAuthorised")}
        />
        <DatePickerField
          styles={{ root: { marginTop: 24 } }}
          name={claimFormNameOf("patientDeclaration")}
          label={ClaimFormLabels.patientDeclaration}
          required
          maxDate={DateTime.jsDateNow()}
        />
      </Stack>
    </ClaimCard>
  );
});
