import { observer } from "mobx-react-lite";

import { Heading, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { AddressField } from "@modules/acc/screens/shared-components/AddressField.tsx";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import {
  claimAdjustmentFormNameOf,
  patientAddressFieldNames
} from "../claim-adjustment.utils.ts";
import { PatientDetailsFormValidator } from "../validators/PatientDetailsFormValidator.tsx";
import {
  ClaimAdjustmentFormLabels,
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";
import { RelatedSurgeryFields } from "./RelatedSurgeryFields.tsx";

export const PatientDetailsFormSection = observer(() => {
  const { claim, claimAdjustment } = useClaimAdjustmentContext();
  const { selectedMenuItemId } = useCardFormLayoutContext();

  const patientLabel = usePatientLabel(true);

  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.patientDetails}
      statusValidator={new PatientDetailsFormValidator()}
      openOnRender={
        selectedMenuItemId === ClaimsAdjustmentCardIds.patientDetails
      }
      heading={`${patientLabel} ${ClaimAdjustmentSectionHeading.patientDetails}`}
      iconName="contact"
      visible={!claimAdjustment.verballyApproved}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            styles={{ root: { width: 182 } }}
            name={claimAdjustmentFormNameOf("claimNumber")}
            required
            label={ClaimAdjustmentFormLabels.claimNumber}
          />
          <ReadOnlyTextField
            label={ClaimAdjustmentFormLabels.injuryDate}
            value={claim.accidentDate?.toDayDefaultFormat()}
            alignAsField
          />
        </Stack>
        <RelatedSurgeryFields />
        <Heading
          variant="section-heading-light"
          styles={{ root: { "&&": { marginTop: 24 } } }}
        >
          Client details
        </Heading>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            styles={{ root: { flex: 1 } }}
            name={claimAdjustmentFormNameOf("patientFirstName")}
            required
            label={ClaimAdjustmentFormLabels.firstName}
          />
          <TextInputField
            styles={{ root: { flex: 1 } }}
            name={claimAdjustmentFormNameOf("patientLastName")}
            required
            label={ClaimAdjustmentFormLabels.lastName}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            styles={{ root: { width: 175 } }}
            name={claimAdjustmentFormNameOf("patientNhiNumber")}
            disabled
            label={ClaimAdjustmentFormLabels.nhi}
          />
          <DatePickerField
            name={claimAdjustmentFormNameOf("patientDateOfBirth")}
            required
            maxDate={DateTime.jsDateNow()}
            label={ClaimAdjustmentFormLabels.dateOfBirth}
          />
        </Stack>
        <AddressField
          required
          fieldNames={{
            street1: patientAddressFieldNames("street1"),
            street2: patientAddressFieldNames("street2"),
            addressType: patientAddressFieldNames("type"),
            suburb: patientAddressFieldNames("suburb"),
            city: patientAddressFieldNames("city"),
            postCode: patientAddressFieldNames("postCode"),
            country: patientAddressFieldNames("country")
          }}
        />
      </Stack>
    </ClaimCard>
  );
});
