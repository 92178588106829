import { FormApi } from "final-form";

import { IButtonProps } from "@bps/fluent-ui";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";

export interface UploadDocFormValues {
  id: string;
  docName?: string;
  patient?: string;
  storeIn?: string;
  assignTo?: string;
  destination: string;
  docExtension?: string;
  fromContactId?: string;
  documentDetailId: string;
  correspondenceType?: string;
  documentDate?: Date;
  checkedByAnotherFlag?: boolean;
  checkedBy?: string;
}

export interface UploadDocFormProps {
  document: InboxDocument;
  isDisabled?: boolean;
  onRemove?: (id: string, documentDetailId: string) => void;
  onClose?: () => void;
  onSubmitSucceeded?: (
    values: UploadDocFormValues,
    form: FormApi<UploadDocFormValues>
  ) => void;
  submitButtonProps?: IButtonProps;
  cancelButtonProps?: IButtonProps;
  confirmOnNewAssignee?: boolean;
  isDismissOnly?: boolean;
}

export enum UploadDocFormLabels {
  AssignedFileFeedback = "Document has been assigned to",
  DeleteFileFeedback = "Document has been deleted",
  SavedFileFeedback = "Document has been saved to",
  DialogDeleteConfirmationTitle = "Confirm file deletion",
  CorrespondenceType = "Document type",
  Destination = "Destination",
  Description = "Description",
  Subject = "Subject",
  Inbox = "Inbox",
  ClinicalRecord = "Clinical record",
  Save = "Save",
  Update = "Update",
  Delete = "Delete",
  From = "From",
  Edit = "Edit",
  DocumentDate = "Document date",
  AddressedTo = "Addressed to",
  AddressedToAndCheckedBy = "Addressed to & checked by",
  AssigneeChangedConfirmationSubText = "This action will reassign the document to a different inbox",
  AssigneeChangedConfirmText = "Reassign",
  CheckedByAnotherOrUnchecked = "Checked by another / unchecked",
  CheckedBy = "Checked by"
}

export type HL7HeaderResponse = {
  PatientSurname: string;
  PatientFirstName: string;
  PatientDob: string;
  PatientSex: string;
  PatientStreetAddress?: string | null;
  PatientCity?: string | null;
  PatientPostcode?: string | null;
  LabReference?: string;
  LabName?: string;
  Addressee?: string;
  ReferredBy?: string;
  CopyTo?: string;
  TestName?: string;
  CollectionDate?: string;
  ReportDate?: string;
  RequestedDate?: string;
  PatientAddress?: string;
  PatientName?: string;
};

export const hl7KeyMappings = {
  LabReference: "Lab reference",
  LabName: "Lab name",
  Addressee: "Addressee",
  ReferredBy: "Referred by",
  CopyTo: "Copy to",
  TestName: "Test name",
  CollectionDate: "Collection date",
  ReportDate: "Report date",
  RequestedDate: "Requested",
  PatientAddress: "Address",
  PatientName: "Patient",
  PatientDob: "DOB",
  PatientSex: "Sex"
};
