import {
  PSFSContextClinicalDataItemDto,
  PSFSReason
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { getActiveDiagnosisSideExist } from "../PSFSBaselineForm.tsx";
import { PSFSFormValues } from "../PSFSForm.types.ts";
import { PSFSFormItemsValidator } from "./PSFSFormItemsValidator.ts";

interface PSFSFormValidatorProps {
  contextClinicalData: PSFSContextClinicalDataItemDto[] | undefined;
  contextId: string | undefined;
}
export class PSFSFormValidator extends Validator<PSFSFormValues> {
  constructor(options: PSFSFormValidatorProps) {
    super();
    this.forField(
      "diagnosisKey",
      [
        predicate(
          () =>
            getActiveDiagnosisSideExist(
              options.contextClinicalData,
              options.contextId
            ),
          () => " "
        ),
        required()
      ],
      {
        when: (_value, parent) => {
          return parent["reason"] === PSFSReason.Baseline;
        }
      }
    );
    this.forField(
      "side",
      [
        predicate(
          () =>
            getActiveDiagnosisSideExist(
              options.contextClinicalData,
              options.contextId
            ),
          () => " "
        ),
        required()
      ],
      {
        when: (_value, parent) => {
          return parent["reason"] === PSFSReason.Baseline;
        }
      }
    );

    this.forField("reason", required());
    this.forField("items", (items, allValues) =>
      new PSFSFormItemsValidator(allValues.reason).validate(items)
    );
  }
}
