import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { Link, noWrap, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { NoticeBadge } from "./NoticeBadge.tsx";

interface Props {
  onClickDescription: () => void;
}

export const GroupAppointmentMenuItem: FunctionComponent<Props> = observer(
  ({ onClickDescription }) => {
    const { calendarEvent, appointmentNotices } = useContext(
      BookingCalendarEventContext
    );

    const { core } = useStores();

    const text =
      calendarEvent.groupDescription ||
      `${calendarEvent.activeAttendees.length} participants (max ${calendarEvent.maxParticipants})`;

    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        grow
        verticalAlign="center"
        styles={{
          root: {
            marginLeft: 4,
            width: 200,
            ...noWrap
          }
        }}
      >
        {core.hasPermissions(Permission.CalendarEventWrite) ? (
          <Link
            onClick={onClickDescription}
            styles={{ root: { width: "100%" } }}
          >
            {text}
          </Link>
        ) : (
          text
        )}
        <NoticeBadge
          notices={appointmentNotices}
          dataAtrributeValue="group-appt-notices-count"
        />
      </Stack>
    );
  }
);
