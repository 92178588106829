import { mergeStyles, Theme } from "@bps/fluent-ui";

const commonStyles = {
  padding: 8,
  height: 38
};

const getTotalOwingStyles = (owingTotal: number, theme: Theme) =>
  mergeStyles(
    {
      backgroundColor:
        owingTotal > 0
          ? theme.semanticColors.errorBackground
          : theme.palette.neutralLighterAlt
    },
    commonStyles
  );

const getCreditStyles = (unallocatedTotal: number, theme: Theme) =>
  mergeStyles(
    {
      backgroundColor:
        unallocatedTotal > 0
          ? theme.semanticColors.successBackground
          : theme.palette.neutralLighterAlt
    },
    commonStyles
  );

export { getTotalOwingStyles, getCreditStyles };
