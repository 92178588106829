import React from "react";

import { Text } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

interface RolesProps {
  userRoles: string[];
}

export const Roles: React.FC<RolesProps> = ({ userRoles }) => {
  const { core } = useStores();
  const roles: { code: string; text: string }[] =
    core.catalogBusinessRoles ?? [];

  return (
    <Text>
      {userRoles
        .map(x => roles.find((y: { code: string }) => x === y.code)?.text)
        .join(", ")}
    </Text>
  );
};
