import { Stack, useTheme } from "@bps/fluent-ui";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactCategoryText } from "@ui-components/RefText.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { ContactGenderInformation } from "./ContactGenderInformation.tsx";

export const ContactPersonaSecondaryText: React.FC<{ contact: Contact }> = ({
  contact
}) => {
  const theme = useTheme();
  const { breakLongText } = getPeopleScreenStylesSet(theme);

  return (
    <Stack verticalAlign="center" verticalFill styles={{ root: breakLongText }}>
      <Stack.Item>
        <ContactGenderInformation contact={contact} />
      </Stack.Item>
      {contact.categories.length ? (
        <ContactCategoryText code={contact.categories} />
      ) : (
        Labels.noCategoryRecorded
      )}
    </Stack>
  );
};
