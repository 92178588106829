import { observer } from "mobx-react-lite";
import React from "react";

import { FontSizes, Stack, Text, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { PreferenceSettingFormValues } from "./PreferenceSettingForm.types.tsx";

const nameOf = nameOfFactory<PreferenceSettingFormValues>();

export const PreferenceSettingForm: React.FC = observer(() => {
  const { notification, core, userExperience } = useStores();
  const { autoLinkCondition, captureApptCancellationReason } =
    userExperience.orgUnitSettingForLocation?.preferenceSetting || {};

  const theme = useTheme();

  const { formFooter, formFields } = getUserStylesSet(theme);

  const onSubmitPreferenceSetting = async (
    values: PreferenceSettingFormValues
  ) => {
    const { autoLinkCondition, captureApptCancellationReason } = values;

    await userExperience.updateOrgUnitSetting({
      orgUnitId: core.locationId,
      preferenceSetting: {
        autoLinkCondition,
        captureApptCancellationReason
      },
      eTag: userExperience.orgUnitSettingForLocation?.eTag || ""
    });
  };

  return (
    <SubmissionForm<PreferenceSettingFormValues>
      formName="preference-setting"
      onSubmitSucceeded={() => {
        notification.success("Preference settings have been updated.");
      }}
      autoFocus={false}
      onSubmit={onSubmitPreferenceSetting}
      readOnly={!core.hasPermissions(Permission.TenantSettingWrite)}
      initialValues={{
        autoLinkCondition:
          autoLinkCondition !== undefined ? autoLinkCondition : true,
        captureApptCancellationReason:
          captureApptCancellationReason !== undefined
            ? captureApptCancellationReason
            : true
      }}
      buttonsProps={{
        disableCancelOnPristine: true,

        styles: {
          root: formFooter
        }
      }}
    >
      {() => (
        <Stack
          styles={{
            root: { ...formFields }
          }}
          tokens={{ childrenGap: 8 }}
        >
          <Stack>
            <Text bold>Capture appointment cancellation reason</Text>
            <Text
              styles={{
                root: {
                  fontSize: FontSizes.size10,
                  color: theme.palette.neutralSecondary
                }
              }}
            >
              If disabled, cancellation reason will be optional
            </Text>
          </Stack>
          <ToggleField
            onText="Enabled"
            offText="Disabled"
            name={nameOf("captureApptCancellationReason")}
            styles={{ root: { marginTop: 8 } }}
          />
          <When permission={[Permission.ConditionsAllowed]}>
            <Stack>
              <Text bold>Auto-link to condition</Text>
              <Text
                styles={{
                  root: {
                    fontSize: FontSizes.size10,
                    color: theme.palette.neutralSecondary
                  }
                }}
              >
                If only one open condition exists, automatically link to new
                appointment
              </Text>
            </Stack>
            <ToggleField
              onText="Enabled"
              offText="Disabled"
              name={nameOf("autoLinkCondition")}
              styles={{ root: { marginTop: 8 } }}
            />
          </When>
        </Stack>
      )}
    </SubmissionForm>
  );
});
