import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  DetailsList,
  FontIcon,
  FontSizes,
  Heading,
  IColumn,
  Link,
  Separator,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ActivityDescriptionDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalActivityType } from "@modules/clinical/screens/patient-record/components/clinical-activity/types/clinical-activity.type.ts";
import { ActivityDescription } from "@stores/clinical/models/ActivityDescription.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";

import { ClinicalReminderPreferenceDialog } from "./ClinicalReminderPreferenceDialog.tsx";
import { nameOfCommPreferences } from "./CommunicationPreferencesForm.tsx";

export interface ClinicalReminderPreferenceFilterBarProps {
  searchText?: string;
  isActive: string[];
}

export const ClinicalReminderPreferenceFields: React.FC = observer(() => {
  const { clinical, core } = useStores();

  const [
    showClinicalReminderPreferenceDialog,
    setShowClinicalReminderPreferenceDialog
  ] = useState<boolean>(false);

  const [selectedClinicalReminder, setSelectedClinicalReminder] = useState<
    ActivityDescription | undefined
  >(undefined);

  const hasActivityDescriptionWritePermission = core.hasPermissions(
    Permission.ActivityDescriptionWrite
  );

  const columns: IColumn[] = [
    {
      name: "Reason",
      key: "reminder-reason",
      minWidth: 100,
      onRender: (activityDescription: ActivityDescription) => {
        return (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            {hasActivityDescriptionWritePermission ? (
              <Link
                onClick={() => {
                  setShowClinicalReminderPreferenceDialog(true);
                  setSelectedClinicalReminder(activityDescription);
                }}
              >
                {activityDescription.reasonText}
              </Link>
            ) : (
              <Text>{`${activityDescription.reasonText} `}</Text>
            )}

            {activityDescription.clinicallySignificant && (
              <TextBadge
                badgeColor={TextBadgeColor.blue}
                badgeSize={TextBadgeSize.small}
              >
                <FontIcon
                  iconName="UserOptional"
                  styles={{
                    root: {
                      fontSize: FontSizes.size16,

                      verticalAlign: "middle"
                    }
                  }}
                />
              </TextBadge>
            )}
          </Stack>
        );
      }
    },
    {
      name: "Default Interval",
      key: "defaultInterval",
      minWidth: 120,
      onRender: activityDescription => {
        if (!activityDescription.interval && !activityDescription.frequency) {
          return "";
        }

        return (
          <Text>{`${activityDescription.interval ?? ""} ${
            activityDescription.frequency ?? ""
          }`}</Text>
        );
      }
    },
    {
      name: "Status",
      key: "status",
      minWidth: 120,
      onRender: activityDescription => {
        return (
          <Text>{activityDescription.isInactive ? "Inactive" : "Active"}</Text>
        );
      }
    }
  ];

  // Filter for items
  const filterItems = (
    filterProps: ClinicalReminderPreferenceFilterBarProps
  ) => {
    let items = Array.from(clinical.activityDescriptionMapValues ?? []);

    items = items.filter(
      x =>
        x.reasonText !== "Other" &&
        x.activityType === ClinicalActivityType.Reminder
    );

    items.sort((a: ActivityDescriptionDto, b: ActivityDescriptionDto) => {
      if (!a.isInactive && b.isInactive) {
        return -1;
      } else if (a.isInactive && !b.isInactive) {
        return 1;
      } else {
        return 0;
      }
    });

    const searchText = filterProps.searchText;

    if (searchText) {
      items = items.filter(x =>
        x.reasonText
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      );
    }

    if (filterProps.isActive && filterProps.isActive.length > 0) {
      items = items.filter(x =>
        filterProps.isActive.includes(x.isInactive ? "Inactive" : "Active")
      );
    }

    return items;
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 8 }}>
        <Separator styles={{ root: { marginBottom: 24 } }} />
        <Heading variant="section-heading-light">Clinical reminders</Heading>
        <CheckboxField
          label="Allow free-text reminders"
          name={nameOfCommPreferences("allowReminderFreeText")}
        />

        <FilterBar<ClinicalReminderPreferenceFilterBarProps>
          initialValues={{ isActive: ["Active"], searchText: "" }}
          items={[
            {
              name: "searchText",
              stickItem: true,
              type: "searchBox",
              props: {
                ...dataAttribute(DataAttributes.Element, "search-box"),
                placeholder: "Search reason",
                styles: {
                  root: {
                    width: 400
                  }
                }
              }
            },
            {
              type: "optionsSelect",
              name: "isActive",
              props: {
                placeholder: "Status",
                label: "Status",
                options: [
                  { key: "Active", text: "Active" },
                  { key: "Inactive", text: "Inactive" }
                ],
                hideSearchOption: true,
                calloutWidth: 130,
                multiSelect: true
              }
            }
          ]}
        >
          {filtered => {
            return (
              <DetailsList
                styles={{
                  contentWrapper: {
                    maxHeight: "40vh",
                    overflowX: "hidden"
                  }
                }}
                items={filterItems(filtered.values)}
                columns={columns}
                cellStyleProps={{
                  cellLeftPadding: 0,
                  cellRightPadding: 7,
                  cellExtraRightPadding: 0
                }}
              />
            );
          }}
        </FilterBar>
        {hasActivityDescriptionWritePermission && (
          <ActionButton
            iconProps={{ iconName: "Add" }}
            title="Add another"
            onClick={() => {
              setShowClinicalReminderPreferenceDialog(true);
            }}
            styles={{ root: { width: 130 } }}
          >
            Add another
          </ActionButton>
        )}
      </Stack>
      {showClinicalReminderPreferenceDialog && (
        <ClinicalReminderPreferenceDialog
          selectedActivityDescription={selectedClinicalReminder}
          onDismiss={() => {
            setShowClinicalReminderPreferenceDialog(false);
            setSelectedClinicalReminder(undefined);
          }}
        />
      )}
    </>
  );
});
