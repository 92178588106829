import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  Heading,
  IBasePickerStyleProps,
  IBasePickerStyles,
  Stack,
  styled
} from "@bps/fluent-ui";
import { MedicationDurationUnit } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DrugMedicationSummaryDto } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import {
  StaticPickerField,
  StaticPickerFieldProps
} from "@ui-components/form/StaticPickerField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";
import { nameOfPrescribingDoseForm } from "./PrescribingDoseForm.tsx";
import { PrescribingDoseFormValues } from "./PrescribingWizard.types.tsx";
import { getDurationText } from "./utils.ts";

export const StyledStaticPickerField = styled<
  StaticPickerFieldProps,
  IBasePickerStyleProps,
  IBasePickerStyles
>(StaticPickerField, {
  text: { minWidth: 0 }
});

interface PrescribingDoseFormRightFieldsProps {
  formulation?: DrugMedicationSummaryDto;
  isPrescribed: boolean;
}

export const PrescribingDoseFormRightFields: FunctionComponent<PrescribingDoseFormRightFieldsProps> =
  observer(({ formulation, isPrescribed }) => {
    const { clinical } = useStores();
    const form = useForm<PrescribingDoseFormValues>();
    const { values } = useFormState<PrescribingDoseFormValues>();
    const helper = usePrescribingWizardContext();
    return (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: 16,
            rowGap: 8,
            flexGrow: 1
          }}
        >
          <Heading labelPaddings>Food</Heading>
          <StyledStaticPickerField
            name={nameOfPrescribingDoseForm("food")}
            resolveDelay={0}
            fetchDataSource={clinical.ref.dosingFood.fetchAsKeyNameValues}
          />
          <Heading labelPaddings>Other</Heading>
          <StyledStaticPickerField
            name={nameOfPrescribingDoseForm("other")}
            resolveDelay={0}
            fetchDataSource={
              clinical.ref.dosingOtherInstructions.fetchAsKeyNameValues
            }
          />
          <Heading labelPaddings>Route</Heading>
          <StyledStaticPickerField
            name={nameOfPrescribingDoseForm("route")}
            resolveDelay={0}
            fetchDataSource={clinical.ref.dosingRoutes.fetchAsKeyNameValues}
          />
          <Heading labelPaddings>Long Term</Heading>
          <ToggleField
            inlineLabel
            label=" "
            name={nameOfPrescribingDoseForm("longTerm")}
            styles={{ root: { marginBottom: 0 } }}
          />
          <FieldSpy
            name={nameOfPrescribingDoseForm("longTerm")}
            onChange={checked => {
              if (checked) {
                form.batch(() => {
                  form.change(nameOfPrescribingDoseForm("duration"), undefined);
                  form.change(
                    nameOfPrescribingDoseForm("durationUnit"),
                    undefined
                  );
                });
              }
            }}
          />
          <Heading labelPaddings hasAsterisk>
            Duration
          </Heading>
          <Stack horizontal>
            <SpinNumberInputField
              name={nameOfPrescribingDoseForm("duration")}
              min={1}
              max={99}
              disabled={
                !!values?.longTerm ||
                values?.durationUnit === MedicationDurationUnit.UntilFinished
              }
              styles={{ root: { width: 60, marginRight: 16 } }}
            />
            <StyledStaticPickerField
              name={nameOfPrescribingDoseForm("durationUnit")}
              resolveDelay={0}
              disabled={!!values?.longTerm}
              withTagItemProps={item => ({
                children: getDurationText(item, values.duration)
              })}
              fieldItemStyles={{ root: { flexGrow: 1 } }}
              withTagItemSuggestionProps={item => ({
                children: getDurationText(item, values.duration)
              })}
              fetchDataSource={
                clinical.ref.dosingDurationPeriods.fetchAsKeyNameValues
              }
              onChange={(e, val) => {
                if (val?.key === MedicationDurationUnit.UntilFinished) {
                  form.change(nameOfPrescribingDoseForm("duration"), undefined);
                }
              }}
            />
          </Stack>
          <Heading labelPaddings>Qty</Heading>
          <SpinNumberInputField
            name={nameOfPrescribingDoseForm("quantity")}
            min={1}
            max={999}
            styles={{ root: { maxWidth: 60 } }}
            fieldItemStyles={{ item: { flexGrow: 0, width: "auto" } }}
            suffix={
              formulation?.productPack?.productUnit ??
              helper.selectedFormulation?.productPack.productUnit
            }
          />
          <Heading labelPaddings>Rpts</Heading>
          <SpinNumberInputField
            name={nameOfPrescribingDoseForm("rpts")}
            min={0}
            max={99}
            styles={{ root: { maxWidth: 60 } }}
          />
          <Heading labelPaddings>Reg 49</Heading>

          <ToggleField
            inlineLabel
            label=" "
            name={nameOfPrescribingDoseForm("regulation49")}
            styles={{ root: { marginBottom: 0 } }}
          />
          <Heading labelPaddings>Script type</Heading>
          <StyledStaticPickerField
            name={nameOfPrescribingDoseForm("rxType")}
            resolveDelay={0}
            fetchDataSource={clinical.ref.rxTypes.fetchAsKeyNameValues}
          />
        </div>
        {!isPrescribed && (
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            styles={{ root: { marginTop: 8 } }}
          >
            <ButtonsGroupSingleChoiceField
              label="Medication started"
              options={clinical.ref.medicationStartedByTypes.keyTextValues}
              name={nameOfPrescribingDoseForm("medicationStartedBy")}
            />
            <DatePickerField
              label="on"
              name={nameOfPrescribingDoseForm("medicationStarted")}
              validateOnInitialize
              styles={{
                errorMessage: {
                  whiteSpace: "normal",
                  wordBreak: "break-word"
                }
              }}
            />
          </Stack>
        )}
      </div>
    );
  });
