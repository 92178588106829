import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";

import { ManualAllocationStatusLabel } from "./AllocationStatusLabel.tsx";
import { ManualAllocationField } from "./ManualAllocationField.tsx";
import { manualAllocationFormNameOf } from "./ManualAllocationForm.types.tsx";

interface ManualAllocationFormLayoutProps {
  allocatabaleAmount: number;
}

export const ManualAllocationFormLayout: FunctionComponent<
  ManualAllocationFormLayoutProps
> = ({ allocatabaleAmount }) => {
  return (
    <Stack tokens={{ childrenGap: 6 }}>
      <ManualAllocationStatusLabel allocatabaleAmount={allocatabaleAmount} />

      <FieldArray name={manualAllocationFormNameOf("allocations")}>
        {() => <ManualAllocationField allocatableAmount={allocatabaleAmount} />}
      </FieldArray>
    </Stack>
  );
};
