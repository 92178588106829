import { useForm } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { AnimatedListWithKeys } from "@bps/fluent-ui";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";

import { CommunicationField } from "./CommunicationField.tsx";
import { PatientEditFormValues } from "./PatientEditFormValues.tsx";
import { defaultCommunication } from "./utils.ts";

export interface ContactMethodCommunicationsProps {
  disablePreferredChoice?: boolean;
  disableSendForm?: boolean;
  disableRemoveLink?: boolean;
  required?: boolean;
  name: string;
  noDefaultCommunication?: boolean;
  onRemove?: (index: number, values: PatientEditFormValues) => void;
}
export const ContactMethodCommunications: React.FC<
  ContactMethodCommunicationsProps
> = ({
  name,
  required,
  disableRemoveLink,
  disablePreferredChoice,
  disableSendForm,
  noDefaultCommunication,
  onRemove
}) => {
  const { change, getState } = useForm<PatientEditFormValues>();

  return (
    <FieldArray name={name}>
      {(
        fieldProps: FieldArrayRenderProps<CommunicationFieldValue, HTMLElement>
      ) => (
        <FormItemField data-name={name} name={name}>
          <AnimatedListWithKeys
            items={fieldProps.fields.value}
            onItemIdRemoved={id => {
              const index = fieldProps.fields.value.findIndex(v => v.id === id);
              if (fieldProps.fields.value.length === 1) {
                if (noDefaultCommunication) {
                  fieldProps.fields.remove(index);
                } else {
                  fieldProps.fields.update(
                    index,
                    defaultCommunication(
                      CommunicationType.Mobile,
                      fieldProps.fields.value[0].id
                    )
                  );
                }
              } else {
                fieldProps.fields.remove(index);
              }
            }}
          >
            {(id, onRemoveItem) => {
              const index = fieldProps.fields.value.findIndex(v => v.id === id);
              const value = fieldProps.fields.value[index];
              const onRemoveAtIndex = (index: number) => {
                const values = getState().values;
                onRemove && onRemove(index, values);
                onRemoveItem();
              };

              if (index >= 0 && value) {
                return (
                  <CommunicationField
                    value={value}
                    name={name}
                    index={index}
                    required={required}
                    onRemove={() => onRemoveAtIndex(index)}
                    disableRemoveLink={disableRemoveLink}
                    disablePreferredChoice={disablePreferredChoice}
                    hideSendForm={disableSendForm}
                    onChangePreferred={(val: boolean) =>
                      val &&
                      fieldProps.fields.forEach(
                        (fieldName: string, idx: number) => {
                          change(
                            `${fieldName}.preferred` as any,
                            index === idx
                          );
                        }
                      )
                    }
                  />
                );
              }
              return null;
            }}
          </AnimatedListWithKeys>
        </FormItemField>
      )}
    </FieldArray>
  );
};
