import {
  ClinicalDataElementCreateLog,
  ClinicalDataElementUpdateLog,
  NatureOfReactionSeverity,
  ReactionClinicalDataItemDto,
  ReactionSeverity
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export interface ReactionFormTypes {
  reaction?: string;
  createLog?: ClinicalDataElementCreateLog;
  updateLog?: ClinicalDataElementUpdateLog;
  naturesOfReaction: NatureOfReactionFields[];
  comment?: string;
  deletedReactions: DeletedNatureOfReactions;
  doomedReaction?: NatureOfReactionFields;
  certainty: string;
  otherText?: string;
}

export type NatureOfReactionFields =
  RecursivePartial<ReactionClinicalDataItemDto>;

export interface DeletedNatureOfReactions {
  [key: string]: NatureOfReactionFields;
}

export type AgentReaction = ReactionClinicalDataItemDto & {
  key: string;
  name: string;
  maxSeverity: ReactionSeverity;
  naturesOfReaction: NatureOfReactionSeverity[];
};

export enum ReactionsListLabels {
  Recorded = "Recorded",
  Certainty = "Certainty",
  Reaction = "Reaction",
  Agent = "Agent",
  Severity = "Severity"
}
