import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  GroupedList,
  Heading,
  IconButton,
  IGroupHeaderProps,
  IRenderFunction,
  NoDataTile,
  Pivot,
  PivotItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import {
  ClinicalDataType,
  MedicationClinicalDataItemDto,
  MedicationsTestElements,
  PrescriptionClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { MedicationsListCell } from "./MedicationListCell.tsx";
import { MedicationsListHeader } from "./MedicationsListHeader.tsx";
import { PrescriptionListCell } from "./PrescriptionListCell.tsx";
import { PrescriptionsListHeader } from "./PrescriptionsListHeader.tsx";

export const MedicationsList: FunctionComponent = observer(() => {
  const { clinical } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const helper = useMedicationsContext();
  const {
    setMedicationDialogVisibleType,
    getActiveMedicationData,
    getPrescriptionData
  } = helper;

  const { groups, sortedMedications } = getActiveMedicationData();

  const { groups: prescriptionGroups, prescriptions } = getPrescriptionData();

  const onRenderMedicationsCell = (
    depth?: number,
    item?: MedicationClinicalDataItemDto
  ): React.ReactNode => {
    if (item) {
      return <MedicationsListCell medication={item} />;
    }

    return null;
  };

  const renderMedicationsHeader: IRenderFunction<IGroupHeaderProps> = props => {
    if (props && props.group && props.group.startIndex >= 0) {
      const medication = sortedMedications[props.group.startIndex];

      return <MedicationsListHeader medication={medication} props={props} />;
    }
    return null;
  };

  const onRenderPrescriptionsCell = (
    depth?: number,
    item?: PrescriptionClinicalDataItemDto
  ): React.ReactNode => {
    if (item) {
      return (
        <Stack styles={{ root: { paddingLeft: 32 } }}>
          <PrescriptionListCell prescription={item} />
        </Stack>
      );
    }

    return null;
  };

  const renderPrescriptionsHeader: IRenderFunction<
    IGroupHeaderProps
  > = props => {
    if (props && props.group && props.group.startIndex >= 0) {
      const prescription = prescriptions[props.group.startIndex];

      return (
        <PrescriptionsListHeader prescription={prescription} props={props} />
      );
    }
    return null;
  };

  return (
    <>
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
          <Heading variant="section-heading-light">Medications</Heading>

          <TooltipHost content="Show as table">
            <IconButton
              iconProps={{ iconName: "GUID" }}
              onClick={() => {
                clinical.ui.setPatientClinicalContent({
                  type: ClinicalDataType.Medication
                });
              }}
              styles={{ root: { marginTop: 3 } }}
            />
          </TooltipHost>
        </Stack>
        {!isViewOnlyOrDischarged && (
          <Stack horizontal verticalAlign="center">
            <ActionButton
              {...dataAttribute(
                DataAttributes.Element,
                MedicationsTestElements.PrescribeButton
              )}
              text="Prescribe"
              iconProps={{
                iconName: "BpRX"
              }}
              onClick={() => setMedicationDialogVisibleType("prescribe")}
            />
            <When permission={Permission.RxRecordAllowed}>
              <ActionButton
                {...dataAttribute(
                  DataAttributes.Element,
                  MedicationsTestElements.RecordButton
                )}
                text="Record"
                iconProps={{
                  iconName: "PenWorkspace"
                }}
                onClick={() => setMedicationDialogVisibleType("record")}
              />
            </When>
          </Stack>
        )}
      </Stack>

      <Pivot>
        <PivotItem headerText="Current">
          {groups.length === 0 ? (
            <NoDataTile
              textProps={{ text: "No medications recorded" }}
              linkProps={{
                hidden: isViewOnlyOrDischarged,
                text: "Add a medication",
                onClick: () => {
                  setMedicationDialogVisibleType("record");
                }
              }}
              showBoxShadow={false}
              greyView
            />
          ) : (
            <GroupedList
              groups={groups}
              items={sortedMedications}
              onRenderCell={onRenderMedicationsCell}
              groupProps={{
                onRenderHeader: renderMedicationsHeader
              }}
              styles={{
                root: {
                  overflow: "auto",
                  overflowX: "hidden",
                  maxHeight: "calc(100vh - 240px)"
                }
              }}
            />
          )}
        </PivotItem>
        <PivotItem headerText="Scripts">
          {prescriptionGroups.length === 0 ? (
            <NoDataTile
              textProps={{ text: "No scripts created" }}
              linkProps={{
                hidden: false
              }}
              showBoxShadow={false}
              greyView
            />
          ) : (
            <GroupedList
              groups={prescriptionGroups}
              items={prescriptions}
              onRenderCell={onRenderPrescriptionsCell}
              groupProps={{
                onRenderHeader: renderPrescriptionsHeader
              }}
              styles={{
                root: {
                  overflow: "auto",
                  overflowX: "hidden",
                  maxHeight: "calc(100vh - 240px)"
                }
              }}
            />
          )}
        </PivotItem>
      </Pivot>
    </>
  );
});
