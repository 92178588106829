import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";
import { useForm } from "react-final-form";

import {
  FontIcon,
  MessageBar,
  MessageBarType,
  OptionsSelect,
  SplitButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";

export enum InvoiceItemListOptionsValue {
  purchaseOrder = "PO",
  lineItemComment = "LIC"
}

export interface InvoiceItemListOptionsDropdownProps {
  claimHasPurchaseOrders?: boolean;
  value: InvoiceItemListOptionsValue[];
  onChange: (value: InvoiceItemListOptionsValue[]) => void;
  invoiceItems: InvoiceItemFormValue[];
  checked?: boolean;
  disabled?: boolean;
}

export const InvoiceItemListOptionsDropdown: FunctionComponent<InvoiceItemListOptionsDropdownProps> =
  observer(({ claimHasPurchaseOrders, value, onChange, invoiceItems }) => {
    const form = useForm<InvoiceFormValues>();
    const theme = useTheme();

    const {
      adjustInvoice,
      addEmptyServiceRowToForm,
      setIsAddItemDialogVisible
    } = useContext(InvoiceFormContext);

    const onChangeSelectedKeys = (newValue: string | string[] | undefined) => {
      const newValueString = (
        Array.isArray(newValue) ? newValue : undefined
      ) as InvoiceItemListOptionsDropdownProps["value"];
      onChange(newValueString);

      if (newValueString.includes(InvoiceItemListOptionsValue.purchaseOrder)) {
        form.batch(() => {
          invoiceItems.forEach((ii, index) => {
            const { initialValues } = form.getState();
            const newValue =
              initialValues.invoiceItems?.find(
                initialItem => initialItem.serviceId === ii.serviceId
              )?.purchaseOrderNumber || "";
            form.change(
              `invoiceItems[${index}].purchaseOrder` as keyof InvoiceFormValues,
              newValue
            );
          });
        });
      } else if (value.includes(InvoiceItemListOptionsValue.purchaseOrder)) {
        form.batch(() => {
          invoiceItems.forEach((ii, index) => {
            form.change(
              `invoiceItems[${index}].purchaseOrder` as keyof InvoiceFormValues,
              ""
            );
          });
        });
      }
    };

    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { width: "100%" } }}
      >
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          styles={{ root: { justifyContent: "flex-end" } }}
        >
          <OptionsSelect
            onRenderFieldContent={() => (
              <Stack
                horizontal
                tokens={{ childrenGap: 8 }}
                horizontalAlign="center"
                styles={{ root: { marginTop: 2 } }}
              >
                <FontIcon
                  iconName="Settings"
                  styles={{ root: { color: theme.palette.themePrimary } }}
                />
                <Stack.Item>Display custom columns</Stack.Item>
              </Stack>
            )}
            selectedKeys={value}
            multiSelect={true}
            onChangeSelectedKeys={onChangeSelectedKeys}
            styles={{
              root: { width: 230 },
              field: { borderColor: "transparent" }
            }}
            hideSearchOption
            hideSelectAllButton
            hideCount
            options={[
              {
                key: InvoiceItemListOptionsValue.lineItemComment,
                text: "Line item comments",
                disabled: adjustInvoice
                  ? adjustInvoice.items.some(invoiceItem => invoiceItem.comment)
                  : false
              },

              {
                key: InvoiceItemListOptionsValue.purchaseOrder,
                text: "Purchase order number"
              }
            ]}
          />

          {claimHasPurchaseOrders && (
            <MessageBar
              styles={{ root: { width: 280, height: 32 } }}
              messageBarType={MessageBarType.warning}
            >
              This claim has accepted Purchase orders.
            </MessageBar>
          )}
        </Stack>
        <Stack styles={{ root: { marginBottom: 4 } }}>
          <SplitButton
            onClick={() => addEmptyServiceRowToForm(form)}
            iconProps={{ iconName: "Add" }}
            primary
            items={[
              {
                key: "add",
                text: "Add single line item",
                onClick: () => addEmptyServiceRowToForm(form)
              },
              {
                key: "add2",
                text: "Search for / Add multiple items",
                onClick: () => setIsAddItemDialogVisible(true)
              }
            ]}
          >
            Add
          </SplitButton>
        </Stack>
      </Stack>
    );
  });
