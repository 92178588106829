import {
  integer,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { InvoiceSettingsFormValues } from "../InvoiceSettingsForm.types.tsx";

export class InvoiceSettingsAuBankDetailsValidator extends Validator<
  InvoiceSettingsFormValues["bankDetails"]
> {
  constructor() {
    super();

    const partiallyCompleted = (
      value: string,
      values: InvoiceSettingsFormValues["bankDetails"]
    ) => {
      return (
        !!values.accountName ||
        !!values.bankName ||
        !!values.bsb ||
        !!values.accountNumber
      );
    };

    this.forField("accountName", [
      maxLength(100),
      predicate(partiallyCompleted, required())
    ]);
    this.forField("bankName", [
      maxLength(100),
      predicate(partiallyCompleted, required())
    ]);
    this.forField("bsb", [
      maxLength(6),
      integer(),
      predicate(partiallyCompleted, required())
    ]);
    this.forField("accountNumber", [
      maxLength(9),
      integer(),
      predicate(partiallyCompleted, required())
    ]);
  }
}
