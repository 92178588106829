import { useContext } from "react";

import { Separator, Stack } from "@bps/fluent-ui";
import { groupBy } from "@bps/utils";
import { sum } from "@libs/utils/utils.ts";
import { AllocationViewContext } from "@modules/billing/screens/allocation/context/AllocationViewContext.tsx";
import { PaymentMethodDetails } from "@modules/billing/screens/shared-components/PaymentMethodText.tsx";
import { TotalsDetails } from "@modules/billing/screens/shared-components/TotalsDetails.tsx";
import { Allocation } from "@stores/billing/models/Allocation.ts";
import { getCreditPath } from "@stores/billing/utils/billing.utils.ts";
import { refTextFor } from "@ui-components/RefText.tsx";

export const AllocationViewTotalDetails = () => {
  const { oldPaymentId, payment, allocation } = useContext(
    AllocationViewContext
  );

  const creditDetailsItemsGrouped = (allocation: Allocation) => {
    return groupBy(allocation.items, x => x.creditId).map(creditGroup => {
      const creditItems = creditGroup[1];
      const creditItem = creditItems[0];
      const creditAmountTotal = sum("amount", creditItems);

      const path = getCreditPath(creditItem.credit.itemType);

      const creditText = refTextFor(x => x.billing.ref.itemTypes)({
        code: creditItem.credit.itemType
      });

      return {
        id: creditItem.creditId,
        amount: creditAmountTotal,
        text: creditItem.credit.number,
        itemType: creditText,
        path: path?.path({ id: creditItem.creditId })
      };
    });
  };

  return (
    <>
      {oldPaymentId && (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <PaymentMethodDetails
            heading="Payment methods"
            methods={payment?.items || []}
          />
          <Separator styles={{ root: { height: "100%" } }} vertical />
        </Stack>
      )}
      <TotalsDetails
        heading="Amounts used"
        rowOptions={creditDetailsItemsGrouped(allocation).map(credit => ({
          ...credit,
          text: (
            <>
              {credit.itemType} {credit.text}
            </>
          )
        }))}
      />
    </>
  );
};
