import { required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";

export class AddToGroupApptDialogValidator extends Validator<AppointmentFormValues> {
  constructor() {
    super();

    this.forField("startTime", [required()]);
    this.forField("startDate", [required()]);
    this.forField("patientId", [required()]);
    this.forField("appointmentTypeId", [required()]);
    this.forField("providerId", [required()]);

    this.forArrayField(
      "groupAttendeeIds",
      (value: string, _, allValues: AppointmentFormValues) => {
        // Should ignore undefined / empty string caused by patient picker with no patient selected

        if (value) {
          const occurrences = allValues.groupAttendeeIds?.filter(
            x => x === value
          );
          if (occurrences && occurrences.length > 1) {
            return ValidationMessages.appointmentClientDuplicate;
          }
        }
        return false;
      }
    );
  }
}
