import { FontSizes, ITextProps, ITextStyles, ITheme } from "@bps/fluent-ui";

export const getSubheadingStyles = (
  _props: ITextProps,
  theme: ITheme
): Partial<ITextStyles> => ({
  root: {
    fontSize: FontSizes.mini,
    color: theme.palette.neutralSecondary
  }
});
