import { isEqual } from "lodash";
import { useContext } from "react";
import { useField, useForm, useFormState } from "react-final-form";

import {
  ActionButtonWithMaxedReached,
  AnimatedListWithKeys,
  Stack
} from "@bps/fluent-ui";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { DiagnosisField } from "@modules/acc/screens/shared-components/DiagnosisField.tsx";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import {
  getEmptyDiagnosis,
  sideFilter,
  terminologyFilter
} from "../../utils.ts";
import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { ChangeDiagnosisDropDown } from "./ChangeDiagnosisPicker.tsx";
import { ClaimAdjustmentFormLabels } from "./ClaimAdjustmentEnums.ts";

export const ChangeDiagnosesField: React.FC = () => {
  const { values: formValues } = useFormState<ClaimAdjustmentFormValues>();
  const { change } = useForm();
  const { claim, getSelectedFormDiagnoses } = useContext(
    ClaimAdjustmentContext
  );

  const {
    input: { value: diagnosisChanges }
  } = useField(claimAdjustmentFormNameOf("diagnosisChanges"), {
    isEqual,
    subscription: { value: true }
  });

  const allSelectedDiagnoses = getSelectedFormDiagnoses(
    claim.currentDiagnoses,
    formValues
  );

  return (
    <FieldArray name={claimAdjustmentFormNameOf("diagnosisChanges")}>
      {({ fields }) => {
        return (
          <>
            <AnimatedListWithKeys
              items={fields.value}
              onItemIdRemoved={id => {
                const index = fields.value.findIndex(v => v.id === id);
                fields.remove(index);
              }}
            >
              {(id, onRemoveItem) => {
                const index = fields.value.findIndex(v => v.id === id);
                if (index < 0) return null;

                const notACC32Acceptable =
                  fields.value[index].aCC32Acceptable === false;

                return (
                  <Stack tokens={{ childrenGap: 8 }}>
                    <Stack
                      tokens={{ childrenGap: 8 }}
                      styles={(props, theme) => ({
                        root: {
                          border: `1px solid ${theme.palette.neutralLight}`,
                          borderRadius: 2,
                          padding: "0px 8px 8px",
                          width: "100%"
                        }
                      })}
                    >
                      <FieldSpy
                        name={`${claimAdjustmentFormNameOf(
                          "diagnosisChanges"
                        )}[${index}].oldDiagnosisKey`}
                        onChange={val => {
                          if (!val) {
                            change(
                              `${claimAdjustmentFormNameOf(
                                "diagnosisChanges"
                              )}[${index}]`,
                              {
                                id: fields.value[index].id
                              }
                            );
                          }
                        }}
                      />
                      <Stack
                        grow
                        horizontal
                        tokens={{ childrenGap: 8 }}
                        styles={{
                          root: {
                            width: "100%"
                          }
                        }}
                      >
                        <ChangeDiagnosisDropDown
                          name={`${claimAdjustmentFormNameOf(
                            "diagnosisChanges"
                          )}[${index}]`}
                          field={fields.value[index]}
                          label={ClaimAdjustmentFormLabels.diagnosisToChange}
                          required
                        />

                        <DeleteButton
                          styles={{
                            root: {
                              height: "auto",
                              padding: "7px 3px",
                              alignSelf: "flex-end"
                            }
                          }}
                          onClick={() => {
                            if (diagnosisChanges.length !== 1) onRemoveItem();
                            else {
                              // when there is only 1 item, reset the values, don't remove the item.
                              change(
                                `${claimAdjustmentFormNameOf(
                                  "diagnosisChanges"
                                )}[0]` as any,
                                {
                                  id: diagnosisChanges[0].id
                                }
                              );
                            }
                          }}
                        />
                      </Stack>
                      {fields.value[index].oldDiagnosisKey && (
                        <DiagnosisField
                          badgeStyles={{ root: { marginTop: 28 } }}
                          key={`${claimAdjustmentFormNameOf(
                            "diagnosisChanges"
                          )}[${index}].newDiagnosis`}
                          name={`${claimAdjustmentFormNameOf(
                            "diagnosisChanges"
                          )}[${index}]`}
                          showStatusCol={false}
                          showNonStandardBadge={notACC32Acceptable}
                          filters={{
                            terminologyFilter: terminologyFilter(
                              allSelectedDiagnoses,
                              Array.from(fields.value)[index]
                            ),
                            sideFilter: sideFilter(
                              allSelectedDiagnoses,
                              Array.from(fields.value)[index]
                            )
                          }}
                          diagnosisLabel={
                            ClaimAdjustmentFormLabels.newDiagnosis
                          }
                          sideLabel={ClaimAdjustmentFormLabels.side}
                        />
                      )}
                    </Stack>
                  </Stack>
                );
              }}
            </AnimatedListWithKeys>
            <ActionButtonWithMaxedReached
              styles={{ root: { alignSelf: "baseline" } }}
              text="Add another diagnosis"
              onClick={() => {
                const newDiagnosisChange = getEmptyDiagnosis();
                diagnosisChanges
                  ? change(claimAdjustmentFormNameOf("diagnosisChanges"), [
                      ...diagnosisChanges,
                      newDiagnosisChange
                    ])
                  : change(claimAdjustmentFormNameOf("diagnosisChanges"), [
                      newDiagnosisChange
                    ]);
              }}
              maxCountReached={fields.length === 3}
            />
          </>
        );
      }}
    </FieldArray>
  );
};
