import { FunctionComponent } from "react";

import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DeleteDialog,
  DeleteDialogProps
} from "@ui-components/DeleteDialog.tsx";

export interface DeleteMedicationDialogProps {
  hidden: boolean;
  onConfirm: DeleteDialogProps["onConfirm"];
  onCancel?: () => void;
}

const DeleteMedicationDialogBase: FunctionComponent<
  DeleteMedicationDialogProps
> = props => {
  const { clinical } = useStores();
  return (
    <DeleteDialog
      formName="delete-medication"
      {...props}
      title="Delete rx"
      subText="Are you sure you want to delete this rx?"
      options={clinical.ref.reasonsForDeleteMedication.keyTextValues}
    />
  );
};

export const DeleteMedicationDialog = withFetch(
  x => [x.clinical.ref.reasonsForDeleteMedication.load()],
  DeleteMedicationDialogBase
);
