import { DateTime } from "@bps/utils";
import { UserActionType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  UserInboxActionFormLabels,
  UserInboxActionFormValues
} from "../user-inbox/components/user-inbox-form/UserInboxActionForm.types.ts";

interface UserInboxActionFieldsProps {
  disabled?: boolean;
}

export const UserInboxActionFields: React.FC<UserInboxActionFieldsProps> = ({
  disabled
}) => {
  const { inbox } = useStores();

  const nameOf = nameOfFactory<UserInboxActionFormValues>();

  return (
    <>
      <ChoiceGroupField
        name={nameOf("instructionCode")}
        label={UserInboxActionFormLabels.followUp}
        disabled={disabled}
        options={[
          ...inbox.ref.instructionsForReception.keyTextValues,
          { key: UserActionType.NoAction, text: "No action" }
        ]}
        required
      />
      <DatePickerField
        name={nameOf("dueDate")}
        disabled={disabled}
        minDate={DateTime.today().toJSDate()}
        label={UserInboxActionFormLabels.followUpBy}
      />
      <TextInputField
        disabled={disabled}
        name={nameOf("extraInfo")}
        label={UserInboxActionFormLabels.ExtraInfo}
        multiline
      />
    </>
  );
};
