import { useContext } from "react";

import {
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { hl7KeyMappings } from "./upload-doc-form/UploadDocForm.types.ts";
import {
  displayHL7RelatedDates,
  displayPatientName,
  dobAndSex,
  formatHL7Response
} from "./utils.ts";

export const HL7HeaderDataPreview = () => {
  const { selectedInboxDocument } = useContext(InboxScreenContext);

  if (
    !selectedInboxDocument?.dto.headerDocumentDetailId &&
    !selectedInboxDocument?.dto.headerEntityId
  )
    return null;

  const noData = (
    <MessageBar messageBarType={MessageBarType.warning}>
      No header data
    </MessageBar>
  );

  return (
    <DataFetcher
      refetchId={selectedInboxDocument.id}
      fetch={async root => {
        const res = await root.inbox.getInboxDocument({
          id: selectedInboxDocument?.dto.headerEntityId!,
          documentDetailId: selectedInboxDocument?.dto.headerDocumentDetailId! // get document header
        });

        const hdr = await root.inbox.getDocumentFileContents(res.fileUri); // get contents from header
        return hdr;
      }}
      fallback={<Spinner />}
    >
      {headerData => {
        if (headerData) {
          const response = JSON.parse(headerData); // raw hl7 data
          const HL7Dates = displayHL7RelatedDates(response);
          const formattedResponse = Object.entries(formatHL7Response(response));
          return (
            <Stack
              tokens={{ childrenGap: 8 }}
              styles={{ root: { padding: 8 } }}
            >
              <div>
                <Text bold>Patient </Text>
                <Text block> {displayPatientName(response)}</Text>
              </div>

              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <Text bold>DOB</Text>
                  <Text>{dobAndSex(response).dob}</Text>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <Text bold>Sex</Text>

                  <Text>{dobAndSex(response).sex}</Text>
                </Stack>
              </Stack>
              {formattedResponse.map(([key, value]) => (
                <div key={key}>
                  <Text bold>{hl7KeyMappings[key] || key}</Text>&nbsp;
                  <Text block>{value}</Text>
                </div>
              ))}
              <Stack
                tokens={{
                  childrenGap: HL7Dates.length > 0 ? 8 : undefined
                }}
              >
                <Text block bold>
                  Test
                </Text>
                {HL7Dates.length > 0 ? (
                  HL7Dates.map(x => <Text key={x}>{x}</Text>)
                ) : (
                  <Text>Not provided</Text>
                )}
              </Stack>
            </Stack>
          );
        } else return noData;
      }}
    </DataFetcher>
  );
};
