import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Heading, Stack } from "@bps/fluent-ui";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { invoiceDetailsRowHeadingStyles } from "./InvoiceDetailsRow.styles.ts";
import { InvoiceDetailsRowPatientPersona } from "./InvoiceDetailsRowPatientPersona.tsx";

interface InvoiceDetailsRowPatientProps {
  accountContactId: InvoiceFormValues["accountContactId"];
  patientId: InvoiceFormValues["patientId"];
}

export const InvoiceDetailsRowPatient: FunctionComponent<InvoiceDetailsRowPatientProps> =
  observer(props => {
    const {
      userExperience: { localisedConfig }
    } = useStores();

    const { accountContactId, patientId } = props;

    if (accountContactId === patientId) {
      return null;
    }

    const patientLabel = localisedConfig("patientDisplay", {
      capitalizeFirst: true
    });

    return (
      <Stack grow>
        <Heading styles={invoiceDetailsRowHeadingStyles}>
          {patientLabel}
        </Heading>
        <InvoiceDetailsRowPatientPersona patientId={patientId} />
      </Stack>
    );
  });
