import {
  InvoiceContact,
  PaymentMethod
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import { AllocationFieldValues } from "../../allocation-field/types.ts";

export const ACCOUNT_CREDIT_METHOD = "ACCOUNTCREDIT";

export interface PaymentFormValues {
  id: string;
  method: PaymentMethod | string;
  amount: number;
}

export interface AllocationFormValues {
  accountContactId?: string;
  comment?: string;
  allocations?: AllocationFieldValues[];
  allocationOption?: string;
  amount?: number;
  payments?: PaymentFormValues[];
  accountContact?: InvoiceContact | undefined;
  paymentDate?: Date;
  locationId: string;
}

export const allocationNameOf = nameOfFactory<AllocationFormValues>();
