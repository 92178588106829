import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { CancelInvoiceFormValues } from "./CancelTransactionDialog.tsx";

export class CancelTransactionValidator extends Validator<CancelInvoiceFormValues> {
  constructor() {
    super();
    this.forField("reason", [required()]);
    this.forField(
      "otherReason",
      predicate((val, values) => values?.reason === "other", required())
    );
  }
}
