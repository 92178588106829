import { action, computed, observable, runInAction } from "mobx";

import {
  CorrespondenceVisibility,
  DocumentCreateOptions,
  DocumentDto,
  DocumentSource
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { CorrespondenceStore } from "@stores/clinical/CorrespondenceStore.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";

import { DocumentWriterValues } from "../components/DocumentWriterValues.ts";

export class DocumentWriterHelper {
  constructor(
    private store: CorrespondenceStore,
    private clinicalDoc: ClinicalDocument
  ) {
    runInAction(() => {
      this.document = this.clinicalDoc;
    });
  }

  @observable
  public document: ClinicalDocument;

  @observable
  public isDirty: boolean = false;

  @action
  setIsDirty = (value: boolean) => {
    this.isDirty = value;
  };

  @computed
  get initialValues(): DocumentWriterValues {
    return {
      documentTitle:
        this.document.name || this.getDocumentTypeText(this.document.type!),
      documentContent: this.document.content!,
      recipientId: this.document.to,
      documentType: this.document.type!,
      providerId: this.document.from,
      visibility: !!this.document.secGroupId
        ? CorrespondenceVisibility.Confidential
        : undefined
    };
  }

  @computed
  get id(): string {
    return this.document.id;
  }

  @computed
  get type(): string | undefined {
    return this.document.type;
  }

  @computed
  get templateId(): string {
    return this.document.templateId;
  }

  @computed
  get patientId(): string {
    return this.document.patientId;
  }

  @computed
  get dto(): DocumentDto {
    return this.document.dto;
  }

  @computed
  get isDefinedTemplate(): boolean {
    return this.document.templateId !== undefined;
  }

  @computed
  get documentTitle() {
    return this.document.metadata.find(x => x.key === "Name")?.value;
  }

  public destinationEmail: string | undefined;

  getDocumentTypeText = (type: string): string => {
    const documentTypeFound =
      this.store.ref.correspondenceTypes.keyTextValues.find(
        keyVal => keyVal.key === type
      );

    return documentTypeFound ? documentTypeFound.text : "Document";
  };

  saveValues = async (dto: DocumentDto, encounterId: string) => {
    if (!!dto.eTag) {
      const updatedDocument = await this.store.updateCorrespondence(
        encounterId,
        this.document.id,
        dto
      );

      runInAction(() => {
        this.document = updatedDocument;
      });
    } else {
      const options: DocumentCreateOptions = {
        source: DocumentSource.Content,
        documents: [dto]
      };

      await this.store.addDocuments(encounterId, options);

      this.store.updateDocumentTab(this.document.id);

      runInAction(() => {
        this.document = new ClinicalDocument(dto, this.store);
      });
    }
  };
}
