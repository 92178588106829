import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Pivot, PivotItem } from "@bps/fluent-ui";

import { DermatomeAccordian } from "./DermatomeAccordian.tsx";
import { DermatomeTest, Labels } from "./DermatomesAndMyotomesForm.types.ts";

export const Dermatomes: React.FC = observer(() => {
  const [selectedKey, setSelectedKey] = useState<DermatomeTest>(
    DermatomeTest.LightTouch
  );
  return (
    <>
      <Pivot
        selectedKey={selectedKey}
        onLinkClick={item => {
          setSelectedKey(item?.props.itemKey as DermatomeTest);
        }}
        headersOnly
      >
        <PivotItem
          headerText={Labels.LightTouch}
          itemKey={DermatomeTest.LightTouch}
        />
        <PivotItem
          headerText={Labels.Pinprick}
          itemKey={DermatomeTest.Pinprick}
        />
      </Pivot>

      <DermatomeAccordian testType={selectedKey} />
    </>
  );
});
