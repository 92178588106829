import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { FC, useContext, useRef } from "react";
import { FormRenderProps } from "react-final-form";

import {
  DetailsList,
  FontIcon,
  FontSizes,
  FontWeights,
  Heading,
  IChoiceGroupOptionStyles,
  IColumn,
  IDetailsColumnProps,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import {
  BusinessRoleClasses,
  Permission,
  SecurityAreaKeyword,
  SecurityPermission,
  SecurityRoleCode,
  SecurityTypeKeyword
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { UserContext } from "@modules/settings/screens/users/components/context/UserContext.tsx";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ChoiceGroupOptionField } from "@ui-components/form/ChoiceGroupOptionField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { FieldsSpy } from "@ui-components/form/FieldsSpy.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { getUserStylesSet } from "../UserScreens.styles.tsx";
import { LicenceMessageBar } from "./LicenceMessageBar.tsx";
import { ResetSecurityPermissionsButton } from "./ResetSecurityPermissionsButton.tsx";
import { SecurityRolesFormValue } from "./UserSecurityRolesForm.types.ts";
import { UserSecurityRolesFormValidator } from "./UserSecurityRolesFormValidator.tsx";
import {
  getDefaultSecurityPermissions,
  getDefaultSecurityRoleCodes,
  getInitialSecurityPermissionsValue,
  getSecurityPermissionMatrix,
  getSecurityRoleCode,
  transformToSecurityRoles
} from "./utils.ts";

type UserSecurityFormProps = {
  user: User;
  lastUserAdmin?: boolean;
};

enum SecurityPermissionsValueType {
  Appointment = "BOOK",
  Clinical = "CLIN",
  Claiming = "CLAIM",
  Account = "BILL",
  Practice = "PRAC",
  User = "USER",
  Work = "WORK",
  BHB = "BHB",
  Licence = "LIC"
}

const validator = new UserSecurityRolesFormValidator();
export const UserSecurityRolesFormBase: FC<UserSecurityFormProps> = observer(
  ({ user, lastUserAdmin }) => {
    const { semanticColors } = useTheme();
    const { Administrator, Contributor, Read, None, Reset } =
      SecurityTypeKeyword;

    const { core } = useStores();
    const userHelper = useContext(UserContext);

    const hasSecurityWritePermission = core.hasPermissions(
      Permission.SecurityWrite
    );

    const hasClinicalAdminPrerelPermission = core.hasPermissions(
      Permission.ClinicalAdminPrerel
    );

    const hasBhbConfigAllowedPermission = core.hasPermissions(
      Permission.BhbConfigAllowed
    );

    const licencingAllowed = core.hasPermissions(Permission.LicencingAllowed);

    const isProvider = useRef(user.isProviderClass);

    const securityRoleCodes = Object.values(SecurityRoleCode);
    const securityPermissions = getDefaultSecurityPermissions(
      core.isNZTenant,
      hasBhbConfigAllowedPermission,
      licencingAllowed
    );

    const theme = useTheme();
    const { formFooter, formFields } = getUserStylesSet(theme);

    const isDataListItemEditable = (
      area: SecurityAreaKeyword,
      type: SecurityTypeKeyword
    ) => {
      const securityRoleCode = getSecurityRoleCode(area, type);

      const isClinAdmin =
        securityRoleCode === SecurityRoleCode.ClinicalAdmin &&
        !hasClinicalAdminPrerelPermission;

      if (
        (area === SecurityAreaKeyword.Licencing &&
          (!userHelper.hasActiveLicence(user.id) || !isProvider.current)) ||
        isClinAdmin
      ) {
        return false;
      }

      if (
        !core.hasPermissions(Permission.ClaimListDischargeAllowed) &&
        securityRoleCode === SecurityRoleCode.ClaimingAdmin
      ) {
        return false;
      }

      return (
        securityRoleCodes.includes(securityRoleCode) &&
        hasSecurityWritePermission
      );
    };

    const choiceGroupStyles: IChoiceGroupOptionStyles = {
      root: {
        width: "inherit",
        justifyContent: "center",
        margin: 0
      },
      field: { width: 20 }
    };

    const columnStyles: IDetailsColumnProps["styles"] = {
      cellTitle: { justifyContent: "center" }
    };

    const getColumns = (
      formApi: FormRenderProps<
        SecurityRolesFormValue,
        Partial<SecurityRolesFormValue>
      >
    ) => {
      const columns: IColumn[] = [
        {
          key: "security-permission",
          minWidth: 180,
          maxWidth: 180,
          name: "Security permissions",
          onRender: (item: SecurityPermission) => {
            const getTooltipMessage = () => {
              // Is the user not a provider?
              if (!isProvider.current) return undefined;

              // The tooltip is only for the licenced workflows permission
              if (
                !licencingAllowed ||
                item.area !== SecurityAreaKeyword.Licencing
              )
                return undefined;

              // No licence?
              if (!userHelper.hasActiveLicence(user.id))
                return "Requires a licence for full access";

              // Has None for licenced workflows?
              if (
                formApi.values.securityPermissionsValue[
                  SecurityPermissionsValueType.Licence
                ] === SecurityTypeKeyword.None
              )
                return "Requires the licensed workflows contributor role for full access";

              return undefined;
            };

            const tooltipMessage = getTooltipMessage();
            return (
              <Stack horizontal tokens={{ childrenGap: 4 }}>
                <Heading labelPaddings>{item.areaLabel}</Heading>
                {tooltipMessage && (
                  <TooltipHost content={tooltipMessage}>
                    <FontIcon
                      iconName="Warning12"
                      styles={{
                        root: {
                          fontSize: FontSizes.size16,
                          verticalAlign: "middle",
                          color: semanticColors.severeWarningIcon,
                          marginTop: 3
                        }
                      }}
                    />
                  </TooltipHost>
                )}
              </Stack>
            );
          }
        },
        {
          key: Administrator,
          minWidth: 100,
          maxWidth: 100,
          name: "Administrator",
          styles: columnStyles,
          onRender: (item: SecurityPermission) => {
            return (
              <ChoiceGroupOptionField
                name={`securityPermissionsValue.${item.area}`}
                itemKey={Administrator}
                readOnly={!isDataListItemEditable(item.area, Administrator)}
                styles={choiceGroupStyles}
                size="large"
              />
            );
          }
        },
        {
          key: Contributor,
          minWidth: 100,
          maxWidth: 100,
          name: "Contributor",
          styles: columnStyles,
          onRender: (item: SecurityPermission) => {
            return (
              <ChoiceGroupOptionField
                name={`securityPermissionsValue.${item.area}`}
                itemKey={Contributor}
                readOnly={!isDataListItemEditable(item.area, Contributor)}
                styles={choiceGroupStyles}
                size="large"
              />
            );
          }
        },
        {
          key: Read,
          minWidth: 100,
          maxWidth: 100,
          name: "View Only",
          styles: columnStyles,
          onRender: (item: SecurityPermission) => {
            const readOnly =
              !isDataListItemEditable(item.area, Read) ||
              (item.area === SecurityAreaKeyword.Accounts &&
                formApi.values.securityPermissionsValue[
                  SecurityPermissionsValueType.Clinical
                ] === SecurityTypeKeyword.Contributor);
            return (
              <ChoiceGroupOptionField
                name={`securityPermissionsValue.${item.area}`}
                itemKey={Read}
                readOnly={readOnly}
                styles={choiceGroupStyles}
                size="large"
              />
            );
          }
        },
        {
          key: None,
          minWidth: 100,
          maxWidth: 100,
          name: "None",
          styles: columnStyles,
          onRender: (item: SecurityPermission) => {
            const readOnly =
              !hasSecurityWritePermission ||
              (item.area === SecurityAreaKeyword.UserManagement &&
                lastUserAdmin) ||
              (item.area === SecurityAreaKeyword.Accounts &&
                formApi.values.securityPermissionsValue[
                  SecurityPermissionsValueType.Clinical
                ] === SecurityTypeKeyword.Contributor) ||
              (item.area === SecurityAreaKeyword.Appointments &&
                (formApi.values.securityPermissionsValue[
                  SecurityPermissionsValueType.Claiming
                ] === SecurityTypeKeyword.Contributor ||
                  formApi.values.securityPermissionsValue[
                    SecurityPermissionsValueType.Claiming
                  ] === SecurityTypeKeyword.Read ||
                  formApi.values.securityPermissionsValue[
                    SecurityPermissionsValueType.Claiming
                  ] === SecurityTypeKeyword.Administrator));

            return (
              <ChoiceGroupOptionField
                name={`securityPermissionsValue.${item.area}`}
                itemKey={None}
                readOnly={readOnly}
                styles={choiceGroupStyles}
                size="large"
              />
            );
          }
        },
        {
          key: "desc",
          name: "",
          minWidth: 112,
          onRender: (item: SecurityPermission) => {
            const securityRoleCode = `TI.${item.area}-${
              formApi.values.securityPermissionsValue[item.area]
            }`;

            const securityRole = core.catalogSecurityRoles.find(
              role => role.code === securityRoleCode
            );

            const fallbackText = `No access to ${item.areaLabel.toLowerCase()}`;

            return securityRole?.description ?? fallbackText;
          }
        }
      ];
      if (!hasSecurityWritePermission) {
        return columns.filter(x => x.key !== Reset);
      }
      return columns;
    };

    const onSubmit = async (values: SecurityRolesFormValue) => {
      const securityRoles = transformToSecurityRoles(
        values.securityPermissionsValue
      );

      await core.updateUser({
        id: user.id,
        securityRoles,
        businessRoles: values.businessRoles
      });

      // Is the user not a provider with an active licence?
      // e.g. if the user is a provider with an assigned licence, the assigned licence to the user should be removed when removeing the provider roles
      if (!isProvider.current && userHelper.hasActiveLicence(user.id)) {
        // The user is not a provider any more, so remove the licence
        await userHelper.updateLicence(user.id);
      }
    };

    const extraActionsBefore = () => {
      return (
        <Stack.Item align="center" styles={{ root: { marginRight: "auto" } }}>
          <ResetSecurityPermissionsButton user={user} />
        </Stack.Item>
      );
    };

    return (
      <SubmissionForm<SecurityRolesFormValue>
        formName="security-roles"
        autoFocus={false}
        onSubmit={onSubmit}
        initialValues={{
          securityPermissionsValue: getInitialSecurityPermissionsValue(
            [...securityPermissions],
            user.securityRoles
          ),
          businessRoles: user.businessRoles
        }}
        buttonsProps={{
          disableCancelOnPristine: true,
          disableSubmitOnPristine: true,
          disableSubmitOnFormInvalid: true,
          styles: {
            root: formFooter
          },
          extraActionsBefore: hasSecurityWritePermission
            ? extraActionsBefore
            : undefined
        }}
        validate={(values: SecurityRolesFormValue) =>
          validator.validate(values)
        }
      >
        {formApi => (
          <Fieldset
            styles={{
              root: { ...formFields, width: "auto" }
            }}
          >
            {formApi.submitSucceeded && !formApi.hasSubmitErrors && (
              <MessageBar
                styles={{
                  root: { flex: 1, width: "auto" },
                  iconContainer: { margin: "auto 8px auto 16px" }
                }}
                messageBarType={MessageBarType.success}
              >
                The changes have been successfully updated
              </MessageBar>
            )}
            <Heading hasAsterisk>Business roles</Heading>
            <Text
              styles={{
                root: {
                  fontSize: theme.fonts.tiny.fontSize,
                  color: theme.palette.neutralSecondary,
                  fontWeight: FontWeights.regular
                }
              }}
            >
              Each business role comes with default security permissions and
              changing the business roles may override current settings
            </Text>
            <StaticTagPickerField
              required
              fetchDataSource={() =>
                core.catalogBusinessRoles.map(({ code, text }) => ({
                  key: code,
                  name: text
                }))
              }
              name={nameof("businessRoles")}
              styles={{ root: { width: 630 } }}
              disabled={!hasSecurityWritePermission}
            />

            {isProvider.current && licencingAllowed && (
              <LicenceMessageBar userId={user.id} />
            )}

            <FieldSpy
              name={nameof("businessRoles")}
              onChange={(currentValue, values, previousValue) => {
                if (isEqual(previousValue, currentValue)) {
                  return;
                }

                isProvider.current = core.catalogBusinessRoles.some(
                  catalogBr =>
                    values?.businessRoles.some(
                      (br: string) => br === catalogBr.code
                    ) && catalogBr.classCode === BusinessRoleClasses.Provider
                );

                const defaultSRCodes: SecurityRoleCode[] =
                  getDefaultSecurityRoleCodes(
                    core.catalogBusinessRoles || [],
                    values?.businessRoles || [],
                    core.tenantDetails?.country
                  );

                const hasActiveLicence = userHelper.hasActiveLicence(user.id);
                const securityPermissionsValue = getSecurityPermissionMatrix(
                  defaultSRCodes,
                  getDefaultSecurityPermissions(
                    core.isNZTenant,
                    hasBhbConfigAllowedPermission,
                    licencingAllowed
                  ),
                  hasActiveLicence
                );

                if (!isProvider.current || !hasActiveLicence)
                  securityPermissionsValue.LIC = SecurityTypeKeyword.None;

                formApi.form.change(
                  "securityPermissionsValue",
                  securityPermissionsValue
                );
              }}
            />
            <FieldsSpy
              fieldNames={[
                "securityPermissionsValue.CLIN",
                "securityPermissionsValue.CLAIM"
              ]}
              onChange={(fieldNames, value) => {
                if (fieldNames === "securityPermissionsValue.CLIN") {
                  switch (value as unknown as SecurityTypeKeyword) {
                    case SecurityTypeKeyword.Contributor: {
                      if (
                        formApi.values.securityPermissionsValue[
                          SecurityPermissionsValueType.Account
                        ] !== SecurityTypeKeyword.Administrator
                      ) {
                        const updatedBillValue = {
                          ...formApi.values.securityPermissionsValue,
                          BILL: SecurityTypeKeyword.Contributor
                        };
                        formApi.form.change(
                          "securityPermissionsValue",
                          updatedBillValue
                        );
                      }
                      break;
                    }
                  }
                } else if (fieldNames === "securityPermissionsValue.CLAIM") {
                  switch (value as unknown as SecurityTypeKeyword) {
                    case SecurityTypeKeyword.Administrator:
                    case SecurityTypeKeyword.Contributor:
                    case SecurityTypeKeyword.Read: {
                      if (
                        formApi.values.securityPermissionsValue[
                          SecurityPermissionsValueType.Appointment
                        ] === SecurityTypeKeyword.None
                      ) {
                        const updatedBookingValue = {
                          ...formApi.values.securityPermissionsValue,
                          BOOK: SecurityTypeKeyword.Read
                        };
                        formApi.form.change(
                          "securityPermissionsValue",
                          updatedBookingValue
                        );
                      }
                      break;
                    }
                  }
                }
              }}
            />
            <DetailsList
              items={[...securityPermissions]}
              columns={getColumns(formApi)}
              styles={{ root: { padding: "40px 0" } }}
              onRenderRow={(props, defaultRender) => {
                if (!defaultRender || !props) return null;
                return defaultRender({
                  ...props,
                  styles: { cell: { whiteSpace: "normal" } }
                });
              }}
            />
          </Fieldset>
        )}
      </SubmissionForm>
    );
  }
);

export const UserSecurityRolesForm = withFetch(
  x => [x.core.getCatalogSecurityRoles()],
  UserSecurityRolesFormBase
);
