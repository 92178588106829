import { FeeType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  isDateBeforeField,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { NewScheduleFormValues } from "../ScheduleForm.types.tsx";
import { isFeeFormDirty } from "../utils.ts";

export class NewScheduleFormValidator extends Validator<NewScheduleFormValues> {
  private nameOf = nameOfFactory<NewScheduleFormValues>();

  constructor() {
    super();
    this.forField(this.nameOf("scheduleName"), [required(), maxLength(300)]);
    this.forField(this.nameOf("scheduleStartDate"), required());

    this.forField(
      this.nameOf("scheduleEndDate"),
      predicate(
        (value, values) => !!values && !values.scheduleIsOngoing && !value,
        required()
      )
    );
    this.forField(
      this.nameOf("scheduleEndDate"),
      isDateBeforeField(
        ValidationMessages.endDateBeforeStartDate,
        this.nameOf("scheduleStartDate")
      )
    );

    this.forField(this.nameOf("feeCode"), [
      predicate((value, values) => isFeeFormDirty(values!), required()),
      predicate((value, values) => isFeeFormDirty(values!), maxLength(30))
    ]);
    this.forField(this.nameOf("feeName"), [
      predicate((value, values) => isFeeFormDirty(values!), required())
    ]);
    this.forField(
      this.nameOf("feeEffectiveDate"),
      predicate((value, values) => isFeeFormDirty(values!), required())
    );
    this.forField(this.nameOf("feeFee"), [
      predicate((value, values) => isFeeFormDirty(values!), required()),
      predicate((value, values) => isFeeFormDirty(values!), greaterThan(0))
    ]);

    const validFeeTypesForMaterials = [FeeType.FlatRate, FeeType.PerUnit];

    this.forField(
      this.nameOf("feeType"),
      predicate(
        (value, values) => !values?.feeIsService,
        value =>
          !validFeeTypesForMaterials.includes(value)
            ? ValidationMessages.invalidFeeTypeForMaterial
            : false
      )
    );
  }
}
