import { memo } from "react";
import { useField } from "react-final-form";

import { Heading } from "@bps/fluent-ui";
import { AllocationFormLabels } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { PatientAccountBalance } from "@modules/billing/screens/allocation/components/patient-account-balance/PatientAccountBalance.tsx";
import { useAllocationField } from "@modules/billing/screens/shared-components/allocation-field/hooks/useAllocationField.ts";
import { useAllocationsTotal } from "@modules/billing/screens/shared-components/allocation-field/hooks/useAllocationsTotal.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { AllocationOptions } from "../AllocationOptions.ts";
import { useAllocationFormContext } from "../context/AllocationFormContext.ts";
import {
  ACCOUNT_CREDIT_METHOD,
  allocationNameOf,
  PaymentFormValues
} from "./AllocationForm.types.ts";

export const AllocationFormAccountState: React.FC = memo(() => {
  const { balance, accountStateHeading } = useAllocationFormContext();

  const { allocationOption, allocatableAmount } = useAllocationField();

  const { allocatedTotal } = useAllocationsTotal();

  const payments: PaymentFormValues[] | undefined = useFieldArray(
    allocationNameOf("payments"),
    {
      subscription: { value: true }
    }
  ).fields.value;

  const isMoreThanTwoMethods = (payments?.length || 0) > 2;
  const isPayTotalAction =
    allocationOption?.key !== AllocationOptions.setAmount;

  const isLockedPaymentMethods = !isMoreThanTwoMethods && isPayTotalAction;

  const allocatable = isLockedPaymentMethods
    ? allocatedTotal
    : allocatableAmount;

  const {
    input: { value: accountContactId }
  } = useField(allocationNameOf("accountContactId"), {
    subscription: { value: true }
  });

  if (!accountContactId || !balance) {
    return null;
  }

  const accountCreditSelectedTotal =
    payments?.find?.(x => x.method === ACCOUNT_CREDIT_METHOD)?.amount || 0;

  const paymentSelectedTotal = allocatable - accountCreditSelectedTotal;

  const totalUnallocated = payments
    ? balance.unallocatedCredit + paymentSelectedTotal - allocatedTotal
    : balance.unallocatedCredit;

  const payInvoiceAmountsOwing = payments
    ? balance.totalOwing - allocatedTotal
    : balance.totalOwing;

  return (
    <>
      <Heading labelPaddings>
        {accountStateHeading
          ? accountStateHeading
          : AllocationFormLabels.accountState}
      </Heading>
      <PatientAccountBalance
        owingTotal={
          payments
            ? payInvoiceAmountsOwing
            : payInvoiceAmountsOwing - allocatedTotal
        }
        unallocatedTotal={Math.max(totalUnallocated, 0)}
      />
    </>
  );
});
