import { decode } from "base64-arraybuffer";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  Card,
  FontWeights,
  Heading,
  IconButton,
  Link,
  Stack,
  TextBadge,
  TextBadgeColor,
  useTheme
} from "@bps/fluent-ui";
import {
  CorrespondenceType,
  DocumentContentType,
  DocumentTabStatus
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { NonCustomTemplateName } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { DOCUMENT_LABEL } from "@modules/clinical/screens/quick-links/components/TemplatePill.tsx";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { DocumentSettingsForm } from "./DocumentSettingsForm.tsx";

interface DocumentSettingsCardProps {
  pracOrgUnit: PracOrgUnit;
}

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

export const DocumentSettingsCard: React.FC<DocumentSettingsCardProps> =
  observer(({ pracOrgUnit }) => {
    const theme = useTheme();
    const { formFields } = getUserStylesSet(theme);
    const { document, billing, userExperience, core } = useStores();

    const getTemplate = async () => {
      const templates = await document.getTemplates({
        name: NonCustomTemplateName.PracticeLetterhead,
        isLetterhead: true
      });

      const practiceLetterHeadTemplate = templates.find(
        template => !template.isCustom
      );

      return practiceLetterHeadTemplate;
    };

    const [previewDocument, setPreviewDocument] = useState<
      Document | undefined
    >(undefined);

    const getFilteredTabs = async () => {
      const filteredTabs = await userExperience.getUserStorage(
        UserStorageKeys.TemplateFilterTabs
      );
      if (filteredTabs && filteredTabs.jsonData) {
        return filteredTabs.dto.jsonData as CorrespondenceType[];
      }

      // We can't find these options, so we default them!
      return [
        CorrespondenceType.Letter,
        CorrespondenceType.Referral,
        CorrespondenceType.Report
      ];
    };

    const getInvoiceSettings = async () => {
      const settings = await billing.getInvoiceSettings();
      // for now, as in the current pbi we have one setting only
      const setting = settings.length > 0 ? settings[0] : undefined;

      return setting;
    };

    return (
      <When
        permission={[Permission.TemplateManageAllowed]}
        permissionOperator="and"
      >
        <DataFetcher
          fetch={() =>
            Promise.all([
              getTemplate(),
              getInvoiceSettings(),
              getFilteredTabs()
            ])
          }
        >
          {data => {
            const [practiceLetterHeadTemplate, invoiceSettings, filteredTabs] =
              data;

            const isTemplateUpdated =
              practiceLetterHeadTemplate &&
              !!practiceLetterHeadTemplate.updatedDate;
            return (
              <Stack tokens={{ childrenGap: 32 }}>
                <Card
                  iconName="Source"
                  headingLevel="section-heading"
                  heading={SettingsLabels.documents}
                  styles={cardStyles}
                >
                  <Stack
                    styles={{
                      root: {
                        ...formFields,
                        paddingBottom: "0px"
                      }
                    }}
                    tokens={{ childrenGap: 8 }}
                  >
                    <Heading variant="section-heading-light">
                      {DOCUMENT_LABEL.PracticeLetterhead}
                    </Heading>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: isTemplateUpdated ? 0 : 10 }}
                    >
                      <TextBadge
                        badgeColor={
                          isTemplateUpdated
                            ? TextBadgeColor.green
                            : TextBadgeColor.lightGrey
                        }
                        styles={{
                          root: {
                            flexDirection: "row",
                            padding: isTemplateUpdated
                              ? "0px 16px"
                              : "5px 16px",
                            border: isTemplateUpdated ? "none" : "bold",
                            borderColor: isTemplateUpdated
                              ? ""
                              : theme.palette.black
                          }
                        }}
                      >
                        {isTemplateUpdated && (
                          <IconButton
                            iconProps={{ iconName: "Completed" }}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: theme.palette.greenDark,
                                pointerEvents: "none"
                              }
                            }}
                          />
                        )}
                        <Stack verticalAlign="center">
                          {isTemplateUpdated ? "Practice custom" : "Default"}
                        </Stack>
                      </TextBadge>

                      {practiceLetterHeadTemplate &&
                        practiceLetterHeadTemplate?.updatedDate && (
                          <TextBadge
                            styles={{
                              root: {
                                color: theme.palette.neutralTertiary,
                                fontWeight: FontWeights.regular
                              }
                            }}
                          >
                            {`Updated ${practiceLetterHeadTemplate.updatedDate.toDayDefaultFormat()}`}
                          </TextBadge>
                        )}

                      <Link
                        styles={{ root: { marginRight: "auto" } }}
                        onClick={async () => {
                          if (
                            practiceLetterHeadTemplate &&
                            practiceLetterHeadTemplate.id
                          ) {
                            const dto = practiceLetterHeadTemplate.dto;

                            const template = await document.renderTemplate(
                              practiceLetterHeadTemplate.id,
                              {
                                context: {
                                  UserId: core?.userId ?? "",
                                  PracticeOrgUnitId:
                                    core.location.parentOrgUnit?.id ?? ""
                                },
                                contentType: DocumentContentType.Pdf
                              }
                            );

                            const previewDocument: Document = {
                              entityId: "",
                              documentId: practiceLetterHeadTemplate.id,
                              entityType: DocumentEntityType.DocumentProcessing,
                              name: dto ? dto.name : "",
                              extension: DocumentContentType.Pdf,
                              previewUri: new Uint8Array(
                                decode(template.content)
                              )
                            };
                            setPreviewDocument(previewDocument);
                          }
                        }}
                      >
                        Preview
                      </Link>
                      {previewDocument && (
                        <DocumentViewerDialog
                          getDocument={() => previewDocument}
                          closeDocumentViewer={() =>
                            setPreviewDocument(undefined)
                          }
                          hideDownloadButton={true}
                        />
                      )}
                      <IconButton
                        iconProps={{ iconName: "Edit" }}
                        onClick={async () => {
                          if (practiceLetterHeadTemplate) {
                            document.setActiveTemplateTab({
                              documentId: practiceLetterHeadTemplate.id,
                              documentTabStatus: DocumentTabStatus.Edit
                            });
                          }
                        }}
                      />
                    </Stack>
                  </Stack>

                  <div style={{ marginTop: 8 }}>
                    <DocumentSettingsForm
                      documentSettings={pracOrgUnit.orgUnitDocumentSettings}
                      orgUnitId={pracOrgUnit.id}
                      invoiceSettings={invoiceSettings}
                      initialTabs={filteredTabs}
                    />
                  </div>
                </Card>
              </Stack>
            );
          }}
        </DataFetcher>
      </When>
    );
  });
