import { useState } from "react";

import { IContextualMenuItem } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { FormTemplateDTO } from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { ContextMenuItemsEnum } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";
import { canReceiveForm } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import {
  GetSubMenuItemsOptions,
  UseFormMenuOptions
} from "./form-context-menu.types.ts";
import { getSubMenuItems } from "./form-context-menu.utils.tsx";

export const useFormMenu = (
  contactOrId?: Contact | string,
  options?: UseFormMenuOptions
): IContextualMenuItem[] => {
  const { forms } = useStores();

  // The templates are stored in state so that they will trigger a rerender when they load
  const [clinicalTemplates, setClinicalTemplates] = useState<
    FormTemplateDTO[] | undefined
  >();

  const [nonClinicalTemplates, setNonClinicalTemplates] = useState<
    FormTemplateDTO[] | undefined
  >();

  const contact =
    typeof contactOrId === "string"
      ? forms.ui.practice.contactsMap.get(contactOrId)
      : contactOrId;

  if (
    !contact?.isPatient ||
    !forms.ui.core.hasPermissions(Permission.SendForms)
  )
    return [];

  const subMenuOptions: GetSubMenuItemsOptions = {
    patientId: contact.id,
    claimId: options?.claimId,
    addContextBeforeDeployAction: options?.addContextBeforeDeployAction,
    formUi: forms.ui,
    clinicalTemplates: options?.filterForms
      ? clinicalTemplates?.filter(options.filterForms)
      : clinicalTemplates,
    nonClinicalTemplates: options?.filterForms
      ? nonClinicalTemplates?.filter(options.filterForms)
      : nonClinicalTemplates,
    setTemplates: (data: {
      clinicalTemplates: FormTemplateDTO[];
      nonClinicalTemplates: FormTemplateDTO[];
    }) => {
      setClinicalTemplates(data.clinicalTemplates);
      setNonClinicalTemplates(data.nonClinicalTemplates);
    }
  };

  // These styles are there to fix a bug where the forms picker would
  // have an ellipsis and not show the full name of some of the forms, but
  // only when there are enough forms to have a scrollbar. This issue may be
  // fixed on the component level in future, in which case this can be removed.
  const menuStyles = {
    root: { width: "fit-content" },
    subComponentStyles: {
      menuItem: { root: { paddingRight: 16 } }
    }
  };

  const sendForm: IContextualMenuItem = {
    key: ContextMenuItemsEnum.SendFormKey,
    text: ContextMenuItemsEnum.SendForm,
    disabled: !canReceiveForm(contact.communications),
    subMenuProps: {
      items: getSubMenuItems(subMenuOptions),
      styles: menuStyles
    }
  };

  const showQR: IContextualMenuItem = {
    key: ContextMenuItemsEnum.QRCodeKey,
    text: ContextMenuItemsEnum.QRCode,
    qrCode: true,
    subMenuProps: {
      items: getSubMenuItems({
        ...subMenuOptions,
        qrcode: true
      }),
      styles: menuStyles
    }
  };

  const items = [sendForm, showQR];

  if (options?.asSubmenu) {
    return [
      {
        key: "forms",
        text: ContextMenuItemsEnum.Forms,
        subMenuProps: { items }
      }
    ];
  }

  return items;
};
