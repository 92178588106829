import { observer } from "mobx-react-lite";
import { useField, useForm } from "react-final-form";

import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";

export const InPaidEmploymentField = observer(() => {
  const form = useForm<ClaimFormValues>();

  const {
    input: { value: workInjury }
  } = useField("workInjury", {
    subscription: { value: true }
  });

  const paidEmploymentOptions = [
    { key: true, text: "Yes", disabled: false },
    { key: false, text: "No", disabled: !!workInjury }
  ];

  return (
    <>
      <BoolButtonGroupField
        label={ClaimFormLabels.inPaidEmployment}
        name={claimFormNameOf("inPaidEmployment")}
        required
        options={paidEmploymentOptions}
      />
      <FieldSpy
        name={claimFormNameOf("inPaidEmployment")}
        onChange={inPaidEmployment => {
          if (!inPaidEmployment) {
            form.batch(() => {
              form.change(claimFormNameOf("employmentStatus"), undefined);
              form.change(claimFormNameOf("otherEmployment"), undefined);
              form.change(claimFormNameOf("workType"), undefined);
              form.change(claimFormNameOf("employerAddress"), undefined);
              form.change(claimFormNameOf("employerId"), undefined);
              form.change(claimFormNameOf("employerName"), undefined);
              form.change(claimFormNameOf("isQuickAddEmployer"), undefined);
            });
          }
        }}
      />
    </>
  );
});
