import { runInAction } from "mobx";
import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontSizes,
  FontWeights,
  IconButton,
  IGroupHeaderProps,
  Link,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { PrescriptionType } from "./MedicationList.types.ts";
import { iconButtonStyles } from "./MedicationsList.styles.ts";
import { getDoseFullInstructions } from "./utils.ts";

interface MedicationsListHeaderProps {
  medication: MedicationClinicalDataItemDto;
  props: IGroupHeaderProps;
}

export const MedicationsListHeader: FunctionComponent<
  MedicationsListHeaderProps
> = ({ medication, props }) => {
  const theme = useTheme();
  const { clinical } = useStores();

  const helper = useMedicationsContext();
  const {
    setDoseFormDialogVisibleWithViewType,
    setMedicationDialogVisibleType
  } = helper;

  const isFinal = props?.groups?.length
    ? props?.groupIndex === props?.groups?.length - 1
    : false;

  const isCollapsed = props.group?.isCollapsed || false;

  const getBadgeColour = (rxType: string | undefined) => {
    if (rxType) {
      switch (rxType) {
        case PrescriptionType.GeneralPBS:
        case PrescriptionType.GeneralRPBS:
          return TextBadgeColor.green;
        default:
          return TextBadgeColor.blue;
      }
    }
    return TextBadgeColor.blue;
  };

  const getDoseDetails = (
    medication: MedicationClinicalDataItemDto
  ): string => {
    const {
      prn,
      complexInstructions,
      food,
      dose,
      doseUnit,
      frequency,
      route,
      duration,
      durationUnit,
      otherInstructions
    } = medication;

    const complexInstructionsText = complexInstructions
      ? complexInstructions
      : "";

    const foodText = food ? clinical.ref.dosingFood.get(food)?.text : "";

    const frequencyText = frequency
      ? clinical.ref.dosingFrequencies.get(frequency)?.text
      : "";

    const routeText = route ? clinical.ref.dosingRoutes.get(route)?.text : "";

    const durationUnitText = durationUnit
      ? clinical.ref.dosingDurationPeriods.get(durationUnit)?.text
      : "";

    const otherInstructionsText = otherInstructions
      ? clinical.ref.dosingOtherInstructions.get(otherInstructions)?.text
      : "";

    const doseFullInstructions = getDoseFullInstructions({
      dose,
      doseUnit,
      frequencyText,
      prn,
      routeText,
      complexInstructionsText,
      foodText,
      otherInstructionsText,
      duration,
      durationUnitText
    });

    return doseFullInstructions;
  };

  return (
    <Stack
      horizontal
      onClick={() => {
        if (props?.onToggleCollapse && props?.group) {
          props.onToggleCollapse(props.group);
        }
      }}
      styles={{
        root: {
          height: "fit-content",
          borderTop: `1px ${theme.palette.neutralLight} solid`,
          borderBottom:
            isFinal && isCollapsed
              ? `1px ${theme.palette.neutralLight} solid`
              : undefined
        }
      }}
    >
      <>
        <IconButton
          {...dataAttribute(
            DataAttributes.Element,
            `${medication.productName}-collapse`
          )}
          iconProps={{
            iconName: isCollapsed ? "ChevronRight" : "ChevronDown"
          }}
          styles={iconButtonStyles}
        />
        <Stack
          grow
          tokens={{ childrenGap: 4 }}
          styles={{
            root: {
              paddingTop: 8,
              paddingBottom: isCollapsed ? 8 : 0,
              paddingRight: 16
            }
          }}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            tokens={{ childrenGap: 4 }}
          >
            <Link
              styles={{
                root: {
                  color: theme.palette.themeDarker,
                  fontWeight: FontWeights.semibold,
                  fontSize: FontSizes.size14
                }
              }}
              onClick={() => {
                runInAction(() => {
                  helper.selectedMedicationId = medication.id;
                  setDoseFormDialogVisibleWithViewType("edit");
                  setMedicationDialogVisibleType(
                    medication.isPrescribed ? "prescribe" : "record"
                  );
                });
              }}
            >
              {medication.productName}
            </Link>
            <TextBadge
              badgeSize={TextBadgeSize.small}
              badgeColor={getBadgeColour(medication.rxType)}
            >
              {medication.rxType}
            </TextBadge>
          </Stack>
          <Stack
            verticalAlign="center"
            horizontal
            tokens={{ childrenGap: 4 }}
            styles={{
              root: {
                width: "100%",
                overflow: "hidden"
              }
            }}
          >
            <Text
              variant="medium"
              styles={{
                root: {
                  width: isCollapsed ? "250px" : "auto",
                  minWidth: 0,
                  overflow: isCollapsed ? "hidden" : "visible",
                  textOverflow: isCollapsed ? "ellipsis" : "clip",
                  whiteSpace: isCollapsed ? "nowrap" : "normal",
                  wordBreak: "break-word",
                  flexGrow: 1,
                  paddingBottom: 4
                }
              }}
            >
              <span
                style={{
                  color: theme.palette.neutralSecondary,
                  fontSize: FontSizes.size12
                }}
              >
                Dose details
              </span>{" "}
              {getDoseDetails(medication)}
            </Text>
          </Stack>
        </Stack>
      </>
    </Stack>
  );
};
