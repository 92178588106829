import React from "react";

import {
  DefaultButton,
  TextBadge,
  TopBarDefaultHeader,
  useTheme
} from "@bps/fluent-ui";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ConditionHeaderProps {
  condition: Condition;
}

export const ConditionHeader: React.FC<ConditionHeaderProps> = ({
  condition
}) => {
  const theme = useTheme();
  const { routing } = useStores();

  const rightContainer = <DefaultButton text="Close" onClick={routing.back} />;

  return (
    <TopBarDefaultHeader
      backButtonOnClick={routing.back}
      heading={`${condition.patient?.name} - ${condition.primaryDiagnosis}`}
      leftContainer={
        <TextBadge
          customColors={{ backgroundColor: theme.palette.neutralLighter }}
          styles={{
            root: { padding: "12px 16px", borderRadius: 2 }
          }}
          children="Summary"
        />
      }
      rightContainer={rightContainer}
    />
  );
};
