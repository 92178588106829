import { useField, useForm } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { AnimatedListWithKeys, Stack } from "@bps/fluent-ui";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { InternalProviderField } from "./InternalProviderField.tsx";
import {
  InternalProviderFieldValue,
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

export const InternalProviders: React.FunctionComponent = () => {
  const reactFinalForm = useForm<PatientEditFormValues>();

  const {
    input: { value: internalProvider }
  } = useField<InternalProviderFieldValue[]>(
    patientFormNameOf("internalProvider"),
    {
      subscription: { value: true }
    }
  );

  const handleResetLeads = (value: boolean, index: number) => {
    reactFinalForm.batch(() => {
      internalProvider.forEach((p: InternalProviderFieldValue, idx: number) => {
        reactFinalForm.change(
          `${patientFormNameOf("internalProvider")}[${idx}].lead` as any,
          value && index === idx
        );
      });
    });
  };

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <FieldArray name={patientFormNameOf("internalProvider")}>
        {(
          fieldProps: FieldArrayRenderProps<
            InternalProviderFieldValue,
            HTMLElement
          >
        ) => {
          if (fieldProps.fields.value.length === 0) {
            return null;
          }

          return (
            <AnimatedListWithKeys
              items={fieldProps.fields.value}
              onItemIdRemoved={id => {
                const index = fieldProps.fields.value.findIndex(
                  v => v.id === id
                );
                fieldProps.fields.remove(index);
              }}
            >
              {(id, onRemoveItem) => {
                const value = fieldProps.fields.value.find(p => p.id === id);
                const index = fieldProps.fields.value.findIndex(
                  p => p.id === id
                );

                if (value && index >= 0) {
                  return (
                    <Fieldset frame>
                      <InternalProviderField
                        index={index}
                        isNew={value.isNew}
                        fieldProps={fieldProps}
                        onRemove={onRemoveItem}
                        resetLeads={handleResetLeads}
                      />
                    </Fieldset>
                  );
                }
                return null;
              }}
            </AnimatedListWithKeys>
          );
        }}
      </FieldArray>
    </Stack>
  );
};
