import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { CenteredLargeSpinner, Stack } from "@bps/fluent-ui";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { AttendeeRow } from "./AttendeeRow.tsx";

export const AttendeeRows: React.FC = observer(() => {
  const { calendarEvent } = useContext(BookingCalendarEventContext);

  if (!calendarEvent.isGroupAppointment) return null;

  const maxParticipants = calendarEvent.maxParticipants ?? 0;
  const spacesRemaining =
    maxParticipants && maxParticipants - calendarEvent.activeAttendees.length;

  return (
    <DataFetcher
      fetch={({ practice }) =>
        practice.getContactsById(
          calendarEvent.activeAttendees.map(a => a.attendeeId)
        )
      }
      fallback={<CenteredLargeSpinner />}
    >
      {() => (
        <Stack tokens={{ childrenGap: 8 }}>
          <div style={{ textAlign: "right" }}>
            Spaces remaining: <strong>{spacesRemaining}</strong>
          </div>
          {calendarEvent.sortedAttendees.map(a => (
            <AttendeeRow attendee={a} key={a.attendeeId} />
          ))}
        </Stack>
      )}
    </DataFetcher>
  );
});
