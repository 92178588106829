import { FC, useContext } from "react";

import { Heading, Stack, TextBadge, TextBadgeColor } from "@bps/fluent-ui";
import { ProviderTypeCode } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { VisitsLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

type VisitsFormFieldsProps = {
  name: string;
  index: number;
  onRemoveItem: () => void;
};

export const VisitsFormFields: FC<VisitsFormFieldsProps> = ({
  name,
  index,
  onRemoveItem
}) => {
  const { claim } = useContext(ClaimContext);
  const { acc } = useStores();
  const spinBtnArr = [
    {
      label: VisitsLabels.allocated,
      name: `${name}.allocated`
    },
    {
      label: VisitsLabels.extensions,
      name: `${name}.extensions`
    }
  ];

  const previousVisitsBtnArr = [
    {
      label: VisitsLabels.previousInitials,
      name: `${name}.previousInitialConsultVisits`
    },
    {
      label: VisitsLabels.previousFollowUps,
      name: `${name}.previousFollowUpVisits`
    }
  ];

  if (!claim.appointmentVisits) return null;

  const appointmentVisit = claim.appointmentVisits[index];

  const providerContractType = acc.ref.providerContractTypes.values.find(
    x =>
      x.contractType === appointmentVisit.contractType &&
      x.providerTypeCode === appointmentVisit.providerType
  );

  let headingText: string | undefined;

  if (appointmentVisit.contractType === ProviderTypeCode.alliedHealthService)
    headingText = "Allied health contract";
  else if (appointmentVisit.contractType === ProviderTypeCode.orthotistService)
    headingText = acc.ref.providerContractTypes.values.find(
      x => x.contractType === appointmentVisit.contractType
    )?.text;
  else if (appointmentVisit.providerType)
    headingText = providerContractType?.text;
  else
    headingText = acc.ref.practiceContractTypes.get(
      appointmentVisit.contractType ?? ""
    )?.text;

  const getBilledAndBooked = (): boolean => {
    if (claim.appointmentVisits) {
      const { billed, booked } = claim.appointmentVisits[index];
      return !!(billed || booked);
    }
    return false;
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack
        horizontal
        styles={{
          root: {
            alignItems: "center",
            justifyContent: "space-between"
          }
        }}
      >
        <Heading variant="section-heading-light">{headingText}</Heading>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 4 }}>
          <FormItemField name={`${name}.totalRemaining`} />
          <TextBadge badgeColor={TextBadgeColor.lightGrey}>
            {claim.appointmentVisits[index].totalRemaining
              ? `${claim.appointmentVisits[index].totalRemaining} consults remaining`
              : "No consults remaining"}
          </TextBadge>
          <DeleteButton
            disabled={getBilledAndBooked()}
            onClick={() => onRemoveItem()}
          />
        </Stack>
      </Stack>
      <Fieldset frame styles={{ root: { padding: 12 } }}>
        <Stack tokens={{ childrenGap: 8 }}>
          {!claim.private && (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              {spinBtnArr.map(x => (
                <SpinNumberInputField
                  key={`spinBtnArr${x.name}`}
                  label={x.label}
                  name={x.name}
                  fieldItemStyles={{ root: { flexGrow: 1 } }}
                  parse={value => value ?? ""}
                />
              ))}
            </Stack>
          )}
          {((claim.referralIn && !claim.private) || claim.isImported) && (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              {previousVisitsBtnArr.map(x => (
                <SpinNumberInputField
                  key={`previousVisitsBtnArr${x.name}`}
                  label={x.label}
                  name={x.name}
                  fieldItemStyles={{ root: { flexGrow: 1 } }}
                  parse={value => value ?? ""}
                />
              ))}
            </Stack>
          )}
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <TextInputField
              label={VisitsLabels.totalBooked}
              name={`${name}.booked`}
              disabled
            />
            <TextInputField
              label={VisitsLabels.totalBilled}
              name={`${name}.billed`}
              disabled
            />
          </Stack>
        </Stack>
      </Fieldset>
    </Stack>
  );
};
