import { maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

export class AnalysisAndPlanCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();

    this.forField(sotapNameOf("analysis"), [maxLength(2000)]);
    this.forField(sotapNameOf("plan"), [maxLength(2000)]);
  }
}
