// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { runInAction } from "mobx";
import { FunctionComponent } from "react";
import { useField, useFormState } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { DrawingCanvas } from "../notes/draw/DrawingCanvas.tsx";
import { CanvasImage } from "../notes/draw/DrawingCanvas.types.ts";
import { InjuryImagesHelper } from "../SOTAP/context/InjuryImagesHelper.tsx";
import { ImageType } from "../SOTAP/context/InjuryImagesHelper.types.ts";
import { FullBodyFormValues } from "./FullBodyClinicalDataForm.types.ts";

const nameOf = nameOfFactory<FullBodyFormValues>();
export const FullBodyClinicalDataFields: FunctionComponent = () => {
  const { clinicalRecord, isViewOnlyOrDischarged } =
    usePatientRecordScreenContext();

  const {
    input: { onChange: onFrontBodyImage }
  } = useField(nameOf("frontImage"));

  const {
    input: { onChange: onBackBodyImage }
  } = useField(nameOf("backImage"));

  const {
    input: { onChange: onLeftSideBodyImage }
  } = useField(nameOf("leftSideImage"));

  const {
    input: { onChange: onRightSideBodyImage }
  } = useField(nameOf("rightSideImage"));

  const { initialValues } = useFormState();

  const images: CanvasImage[] = InjuryImagesHelper.getCanvasBodyImages(
    {
      initialFrontImage: initialValues.frontImage,
      initialBackImage: initialValues.backImage,
      initialLeftSideImage: initialValues.leftSideImage,
      initialRightSideImage: initialValues.rightSideImage
    },
    clinicalRecord.patient
  );

  const onCanvasSelected = (imageIndex: number) => {
    runInAction(() => {
      clinicalRecord.selectedFullBodyImageIndex = imageIndex;
    });
  };

  return (
    <Stack tokens={{ childrenGap: "8px" }}>
      <Heading variant="section-heading">Full body</Heading>
      <Heading variant="section-sub-heading">Examination</Heading>
      <DrawingCanvas
        isViewOnly={isViewOnlyOrDischarged}
        caption="Diagram"
        images={images}
        initialSelectedImage={clinicalRecord.selectedFullBodyImageIndex}
        onCanvasSelected={onCanvasSelected}
        onChange={(canvas: Canvas, event: any) => {
          const canvasImageValue =
            event.target.dirty && canvas._objects.length > 0
              ? canvas?.toSVG()
              : undefined;
          if (canvas["lowerCanvasEl"].id === ImageType.frontImage.toString()) {
            onFrontBodyImage(canvasImageValue);
          }

          if (canvas["lowerCanvasEl"].id === ImageType.backImage.toString()) {
            onBackBodyImage(canvasImageValue);
          }

          if (canvas["lowerCanvasEl"].id === ImageType.leftImage.toString()) {
            onLeftSideBodyImage(canvasImageValue);
          }

          if (canvas["lowerCanvasEl"].id === ImageType.rightImage.toString()) {
            onRightSideBodyImage(canvasImageValue);
          }
        }}
      />
      <Stack.Item>
        <TextInputField
          name={nameOf("examinationComment")}
          className="examinationComment"
          placeholder="Comments"
          multiline
          rows={6}
          label="Comments"
          styles={{ wrapper: { paddingTop: 13, paddingRight: 16 } }}
        />
      </Stack.Item>
    </Stack>
  );
};
