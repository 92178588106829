import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useFormState } from "react-final-form";

import { Heading, OptionsSelect } from "@bps/fluent-ui";
import { BodyArea } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BodyExaminationFormValues } from "../BodyExaminationForm.types.ts";
import { useBodyExaminationContext } from "../context/BodyExaminationContext.ts";

const DiagramPickerSelectComponent: FunctionComponent = observer(() => {
  const {
    bodyParts,
    setBodyParts,
    setImages,
    setShowDiagramEditorArea,
    setSelectedBodyParts
  } = useBodyExaminationContext();

  const { clinical } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { values } = useFormState<BodyExaminationFormValues>();

  if (bodyParts.length < 1) {
    setShowDiagramEditorArea(false);
  }
  return (
    <OptionsSelect
      selectedKeys={bodyParts.map(part => part)}
      multiSelect
      options={clinical.ref.bodyAreas.keyTextValues}
      placeholder="Diagrams"
      hideSearchOption
      styles={{
        field: {
          border: 0,
          selectors: { "&::after": { border: 0 } },
          paddingLeft: 0
        },
        root: { width: 100, marginBottom: 8, marginLeft: 0 },
        list: { maxHeight: 300 }
      }}
      showAllSelected={false}
      hideCount
      calloutWidth={220}
      onRenderFieldContent={() => {
        return (
          <Heading
            variant="section-sub-heading"
            styles={{
              root: {
                margin: "auto 0"
              }
            }}
          >
            Diagrams
          </Heading>
        );
      }}
      onChangeSelectedKeys={(bodyParts: BodyArea[]) => {
        setSelectedBodyParts(bodyParts);
        setBodyParts(bodyParts);
        if (values.imageValue) {
          setImages(values.imageValue, clinicalRecord.patient?.sex);
        }
      }}
    />
  );
});

export const DiagramPickerSelect = withFetch(
  x => [x.clinical.ref.bodyAreas.load()],
  DiagramPickerSelectComponent
);
