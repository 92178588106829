import { observer } from "mobx-react-lite";
import React from "react";

import { Heading, Separator, Stack, Text, useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { UpdateInvoiceSettingDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  OrgUnitCompanyDataType,
  OrgUnitDocumentSettingsDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { pluralizeString } from "@libs/utils/utils.ts";
import { InvoiceSettings } from "@stores/billing/models/InvoiceSettings.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { getUserStylesSet } from "../../../users/components/UserScreens.styles.tsx";
import {
  SettingsFormHeadings,
  SettingsFormLabels,
  SettingsFormPlaceholders
} from "./DocumentSettingsForm.types.tsx";
import { DocumentSettingsValidator } from "./validators/DocumentSettingsValidator.ts";

interface DocumentSettingsFormValues {
  usePracticeLetterhead: boolean;
  footer: string;
  statementFooter: string;
  bankDetailsUnderStatementFooter: boolean;
  bankDetailsUnderInvoiceFooter: boolean;
  templateFilterTabs: string[];
}

interface DocumentSettingsFormProps {
  documentSettings: OrgUnitDocumentSettingsDto | undefined;
  invoiceSettings: InvoiceSettings | undefined;
  orgUnitId: string;
  initialTabs: CorrespondenceType[];
}

export const DocumentSettingsFormBase: React.FC<DocumentSettingsFormProps> =
  observer(({ documentSettings, invoiceSettings, orgUnitId, initialTabs }) => {
    const { practice, billing, core, correspondence, userExperience } =
      useStores();

    const MAX_OPTIONS = 3;

    const nameOf = nameOfFactory<DocumentSettingsFormValues>();
    const theme = useTheme();
    const { formFooter, formFields, settingsSeparator } =
      getUserStylesSet(theme);

    const documentFormValidator = new DocumentSettingsValidator();

    const onSubmitPractice = async (values: DocumentSettingsFormValues) => {
      const dto: OrgUnitDocumentSettingsDto = {
        orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.DocumentSettings,
        usePracticeLetterhead: values.usePracticeLetterhead
      };
      await practice.updateOrgUnit({
        id: orgUnitId,
        orgUnitCompanyData: [dto]
      });
    };

    const getUpdatedInvoiceSettings = (
      setting: InvoiceSettings,
      values: DocumentSettingsFormValues
    ): UpdateInvoiceSettingDto => {
      return {
        ...values,
        business: {
          businessType:
            core.tenantDetails?.country === Country.NewZealand
              ? OrgUnitCompanyDataType.NzOrgUnitBusiness
              : OrgUnitCompanyDataType.AuOrgUnitBusiness,
          gstNumber: setting.gstNumber,
          bankDetails: setting.bankDetails && {
            accountName: setting.bankDetails?.accountName,
            bankName: setting.bankDetails?.bankName,
            bsb: setting.bankDetails?.bsb,
            accountNumber: setting.bankDetails?.accountNumber,
            locationId: core.locationId
          }
        },
        alwaysApplyGst: setting.alwaysApplyGst,
        eTag: setting.eTag,
        gstPercent: setting.gstPercent,
        id: setting.id
      };
    };

    const onSubmitBilling = async (values: DocumentSettingsFormValues) => {
      // get billing data
      const settings = await billing.getInvoiceSettings();
      // for now, as in the current pbi we have one setting only
      const setting = settings.length > 0 ? settings[0] : undefined;

      const upd = setting && getUpdatedInvoiceSettings(setting, values);
      if (upd) {
        await billing.updateInvoiceSettings(upd);
      }
    };

    const onSubmitTabs = async (values: DocumentSettingsFormValues) => {
      const userStorage = await userExperience.getUserStorage(
        UserStorageKeys.TemplateFilterTabs
      );

      if (!userStorage) {
        await userExperience.addUserStorage(
          UserStorageKeys.TemplateFilterTabs,
          {
            key: UserStorageKeys.TemplateFilterTabs,
            userId: core.userId,
            jsonData: values.templateFilterTabs
          }
        );
      } else {
        await userExperience.updateUserStorage(
          UserStorageKeys.TemplateFilterTabs,
          {
            key: userStorage.key,
            userId: userStorage.userId,
            jsonData: values.templateFilterTabs,
            eTag: userStorage.eTag,
            id: userStorage.id
          }
        );
      }
    };

    const onSubmit = async (values: DocumentSettingsFormValues) => {
      await Promise.all([
        onSubmitTabs(values),
        onSubmitPractice(values),
        onSubmitBilling(values)
      ]);
    };

    const correspondenceTypes =
      correspondence.ref.correspondenceTypes.keyTextValues;

    const buildOptions = (selectedValue: string[]) => {
      // Sort so that the selectedValues appear first in the correspondence list.
      const correspondenceSelected = [...correspondenceTypes].sort((a, b) => {
        const selectedA = selectedValue.find(x => x === a.key);
        const selectedB = selectedValue.find(x => x === b.key);

        if (selectedA && selectedB) {
          return a.text.localeCompare(b.text);
        }

        if (selectedA) {
          return -1;
        }
        if (selectedB) {
          return 1;
        }

        return 0;
      });

      const options = correspondenceSelected.map(data => ({
        key: data.key,
        text: pluralizeString(data.text),
        disabled:
          selectedValue.length >= MAX_OPTIONS &&
          !selectedValue.find(x => x === data.key)
      }));

      return options;
    };

    return (
      <SubmissionForm<DocumentSettingsFormValues>
        onSubmit={onSubmit}
        formName="practice-document-settings"
        validate={documentFormValidator.validate}
        autoFocus={false}
        initialValues={{
          usePracticeLetterhead:
            documentSettings?.usePracticeLetterhead ?? false,
          footer: invoiceSettings?.footer ?? "",
          statementFooter: invoiceSettings?.statementFooter ?? "",
          bankDetailsUnderStatementFooter:
            invoiceSettings?.bankDetailsUnderStatementFooter ?? false,
          bankDetailsUnderInvoiceFooter:
            invoiceSettings?.bankDetailsUnderInvoiceFooter ?? false,
          templateFilterTabs: initialTabs
        }}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: { root: formFooter }
        }}
      >
        {() => (
          <Stack
            styles={{
              root: {
                ...formFields,
                paddingTop: "0px"
              }
            }}
            tokens={{ childrenGap: 8 }}
          >
            <Stack tokens={{ childrenGap: 8, maxWidth: 628 }}>
              <Text
                styles={{
                  root: {
                    fontWeight: 600,
                    marginTop: 8,
                    marginBottom: 0
                  }
                }}
              >
                {SettingsFormLabels.usingTheLetterhead}
              </Text>

              <CheckboxField
                label={SettingsFormLabels.usePracticeLetterhead}
                name={nameOf("usePracticeLetterhead")}
              />
              <Separator styles={settingsSeparator} />
              <Heading variant="section-heading-light">
                {SettingsFormLabels.templateDialogue}
              </Heading>

              <FieldSpy
                name={nameOf("templateFilterTabs")}
                subscription={{ value: true }}
                children={(_state, value) => {
                  return (
                    <OptionsSelectField
                      label={SettingsFormLabels.templateAvailableTabs}
                      name={nameOf("templateFilterTabs")}
                      options={buildOptions(value)}
                      hideSearchOption
                      multiSelect
                      hideClearButton
                      showAllSelected
                      styles={{ root: { maxWidth: "400px" } }}
                    />
                  );
                }}
              />

              <Separator styles={settingsSeparator} />

              <Heading variant="section-heading-light">
                {SettingsFormHeadings.financials}
              </Heading>
              <Stack tokens={{ childrenGap: 8, maxWidth: 628 }}>
                <TextInputField
                  parse={(value: string) => value ?? ""}
                  label={SettingsFormLabels.footer}
                  name={nameOf("footer")}
                  placeholder={SettingsFormPlaceholders.footer}
                />
                <CheckboxField
                  label={SettingsFormLabels.bankDetailsUnderInvoiceFooter}
                  name={nameOf("bankDetailsUnderInvoiceFooter")}
                />
                <When permission={[Permission.StatementWrite]}>
                  <TextInputField
                    parse={(value: string) => value ?? ""}
                    label={SettingsFormLabels.statementFooter}
                    name={nameOf("statementFooter")}
                  />
                  <CheckboxField
                    label={SettingsFormLabels.bankDetailsUnderStatementFooter}
                    name={nameOf("bankDetailsUnderStatementFooter")}
                  />
                </When>
              </Stack>
            </Stack>
          </Stack>
        )}
      </SubmissionForm>
    );
  });

export const DocumentSettingsForm = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  DocumentSettingsFormBase
);
