import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { TemplatePickerWrapper } from "./components/TemplatePickerWrapper.tsx";

const CorrespondenceBase: FunctionComponent = observer(() => {
  const {
    correspondence: {
      ui: { currentTemplatePickerProps }
    }
  } = useStores();

  if (!currentTemplatePickerProps) {
    return null;
  }

  return <TemplatePickerWrapper {...currentTemplatePickerProps} />;
});

const CorrespondenceDialog = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  CorrespondenceBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default CorrespondenceDialog;
