export enum ClinicalTasksTableLabels {
  Status = "Status",
  Patient = "Patient",
  Description = "Description",
  Due = "Due",
  DueDate = "Due date",
  DueIn = "Due in",
  DueConsults = "Due consults",
  Priority = "Priority",
  CreatedBy = "Created By",
  Comment = "Comment",
  SearchPatient = "Search patient",
  SearchDescription = "Search description",
  Start = "Start",
  End = "End",
  Claim = "Claim",
  Actions = "Actions"
}
