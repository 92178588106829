import { Heading, Stack, Text } from "@bps/fluent-ui";

export const UserClinicalFormLabel: React.FC<{
  label: string;
  description?: string;
}> = ({ label, description }) => (
  <Stack
    styles={{
      root: {
        marginBottom: 8
      }
    }}
  >
    <Heading
      variant="section-sub-heading"
      styles={{ root: { marginBottom: 5 } }}
    >
      {label}
    </Heading>
    <Text>{description}</Text>
  </Stack>
);
