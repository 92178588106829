import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { ScrollablePane } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { TransactionActionCell } from "@modules/billing/screens/account/components/transaction-list/TransactionActionCell.tsx";
import { TransactionDetailsCell } from "@modules/billing/screens/account/components/transaction-list/TransactionDetailsCell.tsx";
import { Statement } from "@stores/billing/models/Statement.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { StatementContactCell } from "./components/StatementContactCell.tsx";
import { StatementItemsContext } from "./context/StatementItemsContext.ts";

export const StatementsList: React.FC = observer(() => {
  const { billing, core } = useStores();

  const { getStatements } = useContext(StatementItemsContext);

  const columnArr = [
    {
      key: "ContextMenu",
      name: "",
      minWidth: 36,
      maxWidth: 36,
      onRender: () => {
        return null;
      }
    },
    {
      name: "Generated",
      key: "date",
      minWidth: 79,
      maxWidth: 79,
      onRender: (props: Statement) => {
        if (props.date) {
          return props.date.toDayDefaultFormat();
        }

        return "";
      }
    },
    {
      name: "Details",
      key: "status",
      minWidth: 367,
      maxWidth: 367,
      onRender: (props: Statement) => {
        return <TransactionDetailsCell statement={props} />;
      }
    },
    {
      name: "Action",
      key: "action",
      minWidth: 80,
      maxWidth: 80,
      onRender: (props: Statement) => {
        return <TransactionActionCell statement={props} />;
      }
    },
    {
      name: "Contact",
      key: "contact",
      minWidth: 80,
      maxWidth: 80,
      onRender: (props: Statement) => {
        return <StatementContactCell statement={props} />;
      }
    }
  ];

  return (
    <div style={{ position: "relative", flexGrow: 1 }}>
      <ScrollablePane>
        <InfiniteScrollList<Statement>
          setKey="statements-list"
          getItems={getStatements}
          refreshKey={billing.ui.lastUpdatedStatementETag}
          detailsListStyles={{
            root: {
              ".ms-DetailsRow-cell": { alignSelf: "center" }
            }
          }}
          columns={
            !core.hasPermissions(Permission.StatementRead)
              ? columnArr.filter(column => column.key !== "action")
              : columnArr
          }
        />
      </ScrollablePane>
    </div>
  );
});
