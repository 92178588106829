import { Observer } from "mobx-react-lite";
import React, { useState } from "react";

import { Link, MessageBar, MessageBarType } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  FormInstanceDTO,
  FormInstanceStatus,
  FormTemplateTypeCode
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DocumentDownloadLink } from "@ui-components/document-viewer/DocumentDownloadLink.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { FormResponseDocument } from "./FormResponseDocument.tsx";
import { findLatestForm } from "./utils.ts";

interface FormWarningPartiallyCompleteProps {
  patientId: string;
  formTemplateTypes: FormTemplateTypeCode[];
  context?: Record<string, string>;
}

export const FormWarningPartiallyComplete: React.FunctionComponent<
  FormWarningPartiallyCompleteProps
> = ({ patientId, formTemplateTypes, context }) => {
  const [viewDocument, setViewDocument] = useState<boolean>(false);

  return (
    <When permission={Permission.SendForms}>
      <DataFetcher<{ patientForms: FormInstanceDTO[]; patient: Contact }>
        fetch={async ({ forms, practice }) => {
          const patientForms = await forms.getFormInstances(patientId, {
            ignoreCache: false
          });

          const patient = await practice.getContact(patientId);
          return { patientForms, patient };
        }}
      >
        {data => {
          const respondedForm = findLatestForm(data.patientForms, {
            formTemplateTypes,
            context,
            status: FormInstanceStatus.Responded
          });
          return (
            <Observer>
              {() => {
                return !respondedForm ? null : (
                  <MessageBar messageBarType={MessageBarType.success}>
                    This claim details have been partially completed via online
                    form.
                    {respondedForm.documentId && (
                      <>
                        <Link
                          onClick={() => setViewDocument(true)}
                          styles={{ root: { paddingLeft: 4 } }}
                        >
                          Review the full response
                        </Link>
                        |
                        <DocumentDownloadLink
                          patientId={data.patient.id}
                          documentId={respondedForm.documentId}
                        >
                          Download as PDF
                        </DocumentDownloadLink>
                      </>
                    )}
                    {viewDocument && respondedForm.documentId && (
                      <FormResponseDocument
                        formDocumentId={respondedForm.documentId}
                        patientName={data.patient.name}
                        formTemplateName={respondedForm.formTemplateName}
                        patientId={data.patient.id}
                        responseDate={respondedForm.responseDate}
                        closeDocumentViewer={() => setViewDocument(false)}
                      />
                    )}
                  </MessageBar>
                );
              }}
            </Observer>
          );
        }}
      </DataFetcher>
    </When>
  );
};
