import { FormApi } from "final-form";
import { useContext } from "react";

import { TopBarDefaultHeader } from "@bps/fluent-ui";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { CommsScheduleContext } from "../context/CommsScheduleContext.ts";
import { CommsScheduleFormValues } from "./CommsScheduleForm.types.ts";

export const CommsScheduleFormHeader = () => {
  const { goBackToBasePath } = useContext(CommsScheduleContext);

  const onCancel = (form: FormApi<any, Partial<CommsScheduleFormValues>>) => {
    form.reset();
    goBackToBasePath();
  };

  return (
    <TopBarDefaultHeader
      heading="Schedules"
      backButtonOnClick={goBackToBasePath}
      rightContainer={
        <FormSubmitButtons
          styles={{
            root: {
              marginTop: 0,
              borderTopColor: "none",
              backgroundColor: "inherit",
              paddingTop: 0
            }
          }}
          hideButtonsSeparator
          submitButtonProps={{
            iconProps: { hidden: true }
          }}
          disableSubmitOnFormInvalid
          disableSubmitOnPristine
          disableCancelOnPristine
          promptOnCancel
          onCancel={onCancel}
        />
      }
    />
  );
};
