import { ITheme } from "@bps/fluent-ui";
import { DateTime, to2dp } from "@bps/utils";
import {
  AddInvoiceDto,
  AllocationTransactionReferenceDto,
  BillingStatuses,
  BillType,
  FeeType,
  GstMethod,
  InvoiceItemDto,
  ItemType,
  ServiceRuleType,
  ServiceSearchDto,
  TransactionPatientDto,
  TransactionReferenceDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { sum } from "@libs/utils/utils.ts";
import { mapContactToTransactionContact } from "@modules/billing/billing.utils.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import {
  getAddInvoiceItemNewDto,
  getInvoiceItemFormValues,
  getServiceSearchFromService
} from "@modules/billing/screens/shared-components/utils/invoice.utils.ts";
import { getAddressFormValueFromContact } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { InvoiceAllocationStatuses } from "@shared-types/billing/invoice-allocation-statuses.enum.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { Service } from "@stores/billing/models/Service.ts";
import { getInvoiceItem } from "@stores/billing/utils/billing.utils.ts";
import { getStatusesItem } from "@stores/billing/utils/invoice.utils.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { MethodType, RouterStore } from "@stores/router/RouterStore.ts";

import { InvoiceListItem, ReferenceLink } from "./Invoice.types.ts";

export const getAdjustInvoiceDto = (
  formValues: InvoiceFormValues,
  core: CoreStore
) => {
  const dto = { ...getAddInvoiceDto(formValues, core) };
  dto.items = dto.items.map(item => ({ ...item, id: undefined }));
  return dto;
};

export const getAddInvoiceDto = (
  formValues: InvoiceFormValues,
  core: CoreStore
): AddInvoiceDto => {
  const {
    invoiceNumber,
    invoiceDate,
    reference,
    locationId,
    accountContactId,
    accountAddress,
    accountContactType,
    accountName,
    accountLastName,
    accountFirstName,
    accountPhone,
    invoiceItems,
    claimId,
    userId,
    patientId,
    patientAddress,
    patientLastName,
    patientFirstName,
    patientPhone,
    userFirstName,
    userLastName,
    userTitle,
    calendarEventId
  } = formValues;

  const patient: TransactionPatientDto = {
    contactType: ContactType.Patient,
    firstName: patientFirstName,
    lastName: patientLastName,
    phone: patientPhone,
    address: patientAddress ? JSON.parse(patientAddress) : ""
  };

  const itemBaseProps = {
    userId: userId!,
    user: {
      firstName: userFirstName,
      lastName: userLastName,
      title: userTitle
    },
    patientId,
    patient,
    itemType: ItemType.Invoice,
    calendarEventId,
    claimId,
    accountId: accountContactId
  };

  const accountContact = mapContactToTransactionContact({
    type: accountContactType,
    name: accountName,
    firstName: accountFirstName,
    lastName: accountLastName,
    phone: accountPhone,
    address: accountAddress ? JSON.parse(accountAddress) : ""
  });

  const location = core.getLocationName(locationId) ?? "";

  return {
    number: invoiceNumber,
    transactionDate: DateTime.jsDateToISODate(invoiceDate),
    accountId: accountContactId!,
    accountContact,
    status: BillingStatuses.current,
    reference: reference!,
    location,
    itemType: ItemType.Invoice,
    items: invoiceItems.map(x =>
      getAddInvoiceItemNewDto({
        item: x,
        baseProps: itemBaseProps,
        locationId
      })
    )
  };
};

export const getOwingFormValue = (
  invoiceItemFormValues: InvoiceItemFormValue[]
) =>
  invoiceItemFormValues
    .reduce(
      (sum: number, item) =>
        sum + Number(item.total) - Number(item.allocatedAmount),
      0
    )
    .toFixed(2);

export const getTotalFormValue = (
  invoiceItemFormValues: InvoiceItemFormValue[]
) => sum("total", invoiceItemFormValues).toFixed(2);

export const getInvoiceFormValuesFromDraftItems = (options: {
  draftItems: InvoiceItemDto[];
  gstPercent: number;
  invoiceNumber: string;
  claimId?: string;
  locationId: string;
  accountContact: Contact;
  services: Service[];
}): InvoiceFormValues => {
  const {
    draftItems,
    gstPercent,
    claimId,
    locationId,
    invoiceNumber,
    accountContact,
    services
  } = options;

  const { calendarEventId, patient, patientId, user, userId } = draftItems[0];
  const patientFirstName = patient.firstName;
  const patientLastName = patient.lastName;
  const patientAddress = patient.address ? JSON.stringify(patient.address) : "";

  const patientPhone = patient.phone;
  const userFirstName = user.firstName;
  const userLastName = user.lastName;
  const userTitle = user?.title;
  const invoiceItemsFormValues = getInvoiceItemFormValues({
    invoiceItems: draftItems,
    gstPercent,
    services
  });

  return {
    userId,
    billType: BillType.Patient,
    patientId,
    patientFirstName,
    patientLastName,
    patientAddress,
    patientPhone,
    userFirstName,
    userLastName,
    userTitle,
    owing: getOwingFormValue(invoiceItemsFormValues),
    total: getTotalFormValue(invoiceItemsFormValues),
    invoiceItems: invoiceItemsFormValues,
    calendarEventId,
    claimId,
    locationId,
    invoiceDate: DateTime.today().toJSDate(),
    invoiceNumber,
    accountAddress: getAddressFormValueFromContact(accountContact),
    accountContactId: accountContact.id,
    accountContactType: accountContact.type,
    accountName: accountContact.name,
    accountFirstName: accountContact.firstName,
    accountLastName: accountContact?.lastName
  };
};

export const getInvoiceFormValuesFromInvoice = (options: {
  invoice: Invoice;
  gstPercent: number;
  isAdjust: boolean;
}): InvoiceFormValues => {
  const {
    id,
    number,
    transactionDate,
    accountId,
    reference,
    accountContactType,
    accountName,
    accountFirstName,
    accountLastName,
    accountAddress,
    accountPhone,
    patientId,
    patientFirstName,
    patientLastName,
    patientAddress,
    patientPhone,
    userId,
    userFirstName,
    userLastName,
    userTitle,
    calendarEventId,
    items,
    services
  } = options.invoice;

  const invoiceItemFormValues = getInvoiceItemFormValues({
    invoiceItems: items,
    gstPercent: options.gstPercent,
    services
  });

  return {
    id,
    invoiceNumber: number,
    invoiceDate: options.isAdjust ? transactionDate : DateTime.jsDateNow(),
    userId,
    reference,
    locationId: items.length > 0 ? items[0].locationId : "",
    billType: BillType.Patient,
    accountContactId: accountId,
    accountContactType,
    accountName,
    accountLastName,
    accountFirstName,
    accountAddress: JSON.stringify(accountAddress),
    accountPhone,
    patientId,
    patientFirstName,
    patientLastName,
    patientAddress: JSON.stringify(patientAddress),
    patientPhone,
    userFirstName,
    userLastName,
    userTitle,
    owing: getOwingFormValue(invoiceItemFormValues),
    total: getTotalFormValue(invoiceItemFormValues),
    invoiceItems: invoiceItemFormValues,
    calendarEventId,
    claimId: options.invoice.claimId
  };
};

export const getFormValuesFromInvoiceItem = (
  invoiceItem: InvoiceItemDto,
  service?: Service
): InvoiceItemFormValue => ({
  id: invoiceItem.id,
  serviceId: invoiceItem.serviceId,
  scheduleId: invoiceItem.scheduleId,
  description: invoiceItem.description,
  name: invoiceItem.name,
  code: invoiceItem.code,
  quantity: invoiceItem.quantity.toString(),
  serviceDate: DateTime.jsDateFromISO(invoiceItem.serviceDate),
  fee: String(invoiceItem.fee),
  gst: String(invoiceItem.gst),
  total: String(invoiceItem.amount),
  allocatedAmount: to2dp(
    (invoiceItem.paid || 0) + (invoiceItem.writtenOff || 0)
  ),
  feeType: invoiceItem.feeType,
  isService: invoiceItem.isService,
  purchaseOrderNumber: invoiceItem.purchaseOrderNumber,
  comment: invoiceItem.comment,
  serviceSearch: {
    // required to be provided but should not be used as data will be wrong.
    // we should look to refactor InvoiceItemList if we don't actually need servicesearch.
    serviceId: "",
    scheduleId: "",
    code: "",
    feeType: FeeType.FlatRate,
    isService: true,
    gstMethod: GstMethod.notApplicable,
    rules: service ? getServiceSearchFromService(service).rules : []
  }
});

const getReferenceLink = (
  reference: TransactionReferenceDto | AllocationTransactionReferenceDto
): ReferenceLink => ({
  status:
    reference.itemType === ItemType.Allocation
      ? InvoiceAllocationStatuses.paid
      : InvoiceAllocationStatuses.writtenOff,
  number: reference.transaction.number,
  id: reference.transaction.id,
  path: getViewPath(reference.itemType).path({ id: reference.transaction.id }),
  transaction: reference.transaction
});

export const getReferencesLinks = (
  invoiceItem: InvoiceItemDto
): ReferenceLink[] => {
  const allocations = invoiceItem.references
    ? invoiceItem.references
        .filter(x => x.itemType === ItemType.Allocation)
        .map(getReferenceLink)
    : [];

  const writeOffs = invoiceItem.references
    ? invoiceItem.references
        .filter(x => x.itemType === ItemType.WriteOff)
        .map(getReferenceLink)
    : [];

  const creditNotes = invoiceItem.references
    ? invoiceItem.references
        .filter(x => x.itemType === ItemType.CreditNote)
        .map(getReferenceLink)
    : [];

  return [...allocations, ...writeOffs, ...creditNotes];
};

export const getInvoiceAllocationStatusColors = (
  status: InvoiceAllocationStatuses,
  theme: ITheme
) => {
  switch (status) {
    case InvoiceAllocationStatuses.owing:
      return theme.palette.redDark;
    case InvoiceAllocationStatuses.paid:
      return theme.semanticColors.successIcon;
    case InvoiceAllocationStatuses.writtenOff:
      return theme.palette.neutralDark;
    case InvoiceAllocationStatuses.cancelled:
      return theme.palette.neutralDark;
    case InvoiceAllocationStatuses.credited:
      return theme.palette.neutralDark;
  }
};

export const allocationStatusesHierarchy = (status: string): number => {
  switch (status) {
    case InvoiceAllocationStatuses.owing:
      return 1;
    case InvoiceAllocationStatuses.writtenOff:
      return 2;
    case InvoiceAllocationStatuses.cancelled:
      return 3;
    case InvoiceAllocationStatuses.credited:
      return 4;
    case InvoiceAllocationStatuses.paid:
      return 5;
    default:
      return 6;
  }
};

export const getViewPath = (itemType: ItemType) => {
  switch (itemType) {
    case ItemType.Allocation:
      return routes.accounts.allocations.allocation;
    case ItemType.WriteOff:
      return routes.accounts.invoices.writeOff.viewPath;
    case ItemType.CreditNote:
      return routes.accounts.creditNotes.viewPath;
    default:
      throw new Error(`View not available for ItemType: ${itemType}`);
  }
};

export const closeInvoiceOrPaymentPage = (
  routing: RouterStore,
  method: MethodType = "push"
) => {
  const pathname =
    routing.location.state?.from || routes.accounts.basePath.pattern;

  routing.goToFromState(routes.accounts.basePath.pattern, {
    method,
    retainState: !!routes.accounts.basePath.match(pathname, true)
  });
};

export const mapServicesToInvoiceItemFormValues = (
  items: ServiceSearchDto[],
  options: { serviceDate: Date; gstPercent: number }
): InvoiceItemFormValue[] =>
  items.map(item =>
    getInvoiceItem({
      service: item,
      serviceDate: options.serviceDate,
      gstPercent: options.gstPercent
    })
  );

export const getEmptyInvoiceItemFormValue = (
  serviceDate: Date
): InvoiceItemFormValue => ({
  id: "",
  serviceId: "",
  scheduleId: "",
  serviceSearch: undefined,
  code: "",
  name: "",
  description: "",
  quantity: "",
  fee: "",
  gst: "",
  total: "",
  feeType: FeeType.FlatRate,
  isService: true,
  serviceDate
});

export const getIsSubsidyInvoice = (
  invoice: Invoice,
  gstPercent: number
): boolean => {
  const itemsWithServices = getInvoiceItemFormValues({
    invoiceItems: invoice.items,
    gstPercent,
    services: invoice.services
  });
  return itemsWithServices.some(getIsItemASubsidy);
};

export const getIsItemASubsidy = (item: InvoiceItemFormValue): boolean => {
  return !!item.serviceSearch?.rules?.some(
    rule => rule.ruleType === ServiceRuleType.ACC
  );
};
export const getInvoiceListItems = (
  invoiceItems: Array<InvoiceItemDto>,
  services: Service[]
): InvoiceListItem[] => {
  return invoiceItems.map(item => ({
    ...getFormValuesFromInvoiceItem(
      item,
      services.find(x => x.id === item.serviceId)
    ),
    references: getReferencesLinks(item),
    statuses: getStatusesItem(item)
  }));
};
