import React from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PrefAndConsentPivotTabTypes } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { ViewPreferencesConsents } from "./ViewPreferencesConsents.tsx";

interface PatientPreferencesConsentsPivotTabsProps {
  contactPreferences?: ContactPreferences;
  selectedTab: PrefAndConsentPivotTabTypes;
}

export const PatientPreferencesConsentsPivotTabs: React.FC<
  PatientPreferencesConsentsPivotTabsProps
> = ({ contactPreferences, selectedTab }) => {
  const { core } = useStores();
  const theme = useTheme();

  const withWrapper = (children: React.ReactNode) => (
    <Stack tokens={{ childrenGap: 8 }} styles={{ root: { height: "100%" } }}>
      <Stack
        styles={{
          root: {
            padding: 5,
            marginTop: 8,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.palette.neutralLight
          }
        }}
      >
        {children}
      </Stack>
    </Stack>
  );

  if (selectedTab === PrefAndConsentPivotTabTypes.Appointments) {
    return withWrapper(
      <>
        <ViewPreferencesConsents
          contactPreference={contactPreferences?.apptRemPreferences}
        />

        {core.hasPermissions(Permission.ApptConfirmationsAllowed) && (
          <ViewPreferencesConsents
            contactPreference={contactPreferences?.apptConfirmPreferences}
          />
        )}
      </>
    );
  }

  if (selectedTab === PrefAndConsentPivotTabTypes.Accounts) {
    return withWrapper(
      <ViewPreferencesConsents
        contactPreference={contactPreferences?.invoiceCommunicationPreferences}
      />
    );
  }

  if (selectedTab === PrefAndConsentPivotTabTypes.Forms) {
    return withWrapper(
      <ViewPreferencesConsents
        contactPreference={contactPreferences?.formNotifyPreferences}
      />
    );
  }

  return null;
};
