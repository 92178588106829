import { DateTime } from "@bps/utils";

export interface TemplateListItem {
  key: string;
  details: string;
  updated: DateTime | undefined;
  isCustom?: boolean;
  documentStatus: string | undefined;
  documentType: string | undefined;
  documentAuthor: string | undefined;
  isLetterhead?: boolean;
}

export enum TemplatePivotItemKeys {
  Favourites = "favourites",
  Custom = "custom",
  Autofills = "autofills",
  All = "all"
}
