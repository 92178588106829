import { User } from "@stores/core/models/User.ts";

export interface AppointmentInfo {
  provider: User | undefined;
  startTime: string;
  type: string;
  id: string;
}

export enum NextAndLastAppointmentsLabels {
  UpcomingEmptyMessage = "No upcoming appointments",
  PatEmptyMessage = "No past appointments",
  Upcoming = "Upcoming",
  Past = "Past"
}
export enum RelationshipStatus {
  MarriedOrCivilUnion = "M",
  Unknown = "UNK",
  Separated = "L",
  Divorced = "D",
  Widowed = "W",
  Defacto = "T",
  Single = "U"
}
