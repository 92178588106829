import React, { FunctionComponent } from "react";

import {
  CodedFieldClinicalDataItemDto,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ReadCodesHintProps {
  data: CodedFieldClinicalDataItemDto;
}
export const ReadCodesHint: FunctionComponent<ReadCodesHintProps> = ({
  data
}) => {
  const { clinical } = useStores();

  return (
    <DataFetcher<QueryResult<TerminologyDto>>
      fetch={async () =>
        await clinical.getTerminologiesSearch({
          search: data?.code
        })
      }
      refetchId={data.code + data?.originalText}
    >
      {terminologies => {
        const codes = terminologies?.results[0]?.readCodes;
        return !!codes?.length && !!codes[0] && <>Read code: {codes[0]}</>;
      }}
    </DataFetcher>
  );
};
