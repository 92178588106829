import { createContext, useContext } from "react";

import { SyncFusionAutofillModel } from "./SyncFusionAutofillsModel.ts";

export const SyncFusionAutofillContext = createContext<SyncFusionAutofillModel>(
  {} as SyncFusionAutofillModel
);

export const useSyncFusionAutofillContext = () =>
  useContext(SyncFusionAutofillContext);
