import { DateTime, newGuid } from "@bps/utils";
import {
  AddInvoiceItemReferenceDto,
  AddWriteOffDto,
  ItemType,
  PaymentMethod,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { AllocationFormLabels } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { getAllocationFormValues } from "@modules/billing/screens/allocation/utils.ts";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import { ItemSelectionMode } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { AllocationOptions } from "@modules/billing/screens/shared-components/allocation-form/AllocationOptions.ts";
import { AllocationFormValues } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { AllocationOption } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationOptionsChoiceFields.tsx";
import { IAllocationFormHelper } from "@modules/billing/screens/shared-components/allocation-form/context/AllocationFormHelper.types.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";

export interface WriteOffScreenHelperOptions {
  invoice: Invoice;
  writeOffNumber: string;
}
export class WriteOffFormHelper implements IAllocationFormHelper {
  constructor(
    private root: IRootStore,
    private options: WriteOffScreenHelperOptions
  ) {
    this.invoice = this.options.invoice;
    this.writeOffNumber = this.options.writeOffNumber;
  }

  public balance?: AccountBalance;
  public invoice;
  public writeOffNumber;

  public initialValues: AllocationFormValues;

  accountStateHeading = AllocationFormLabels.accountStateAfterWriteOff;

  public getColumnOptions = () => {
    return {
      filtersToShow: {
        invoice: true,
        providerName: true,
        patientName: true,
        total: true,
        owing: false
      },
      headingOptions: {
        allocationName: "Written-off ($)"
      },
      statusText: {
        partAllocated: "Part-written-off",
        fullyAllocated: "Written-off"
      }
    };
  };

  public get disableAccountPicker() {
    return true;
  }

  public get hideAmountSelection() {
    return true;
  }

  public get allocationOptions(): AllocationOption[] {
    return [
      {
        key: AllocationOptions.paySetItems,
        itemSelectionMode: ItemSelectionMode.none,
        text: `Invoice ($${currencyFormat(Number(this.invoice.owing || 0), {
          currency: ""
        })})`
      },
      {
        key: AllocationOptions.setAmount,
        text: "Selected items"
      }
    ];
  }

  public onAllocationOptionsChanged = (allocationOption: string) => {
    if (allocationOption === AllocationOptions.setAmount) {
      return {
        payments: [
          {
            id: newGuid(),
            amount: this.invoice.owing || 0,
            method: PaymentMethod.Eftpos
          }
        ]
      };
    }
    return;
  };

  public getInitialValues = () => {
    const allocations = getAllocationFormValues(
      this.invoice
    ).invoiceAllocations.map(x => ({
      ...x,
      total: x.owing || 0,
      status: PaymentStatuses.paid,
      checked: true
    }));

    const values: AllocationFormValues = {
      comment: "",
      allocations,
      accountContactId: this.invoice.accountId,
      allocationOption: AllocationOptions.paySetItems,
      locationId: this.invoice.items.length
        ? this.invoice.items[0].locationId
        : "",
      payments: [
        {
          id: newGuid(),
          amount: this.invoice.owing || 0,
          method: PaymentMethod.Eftpos
        }
      ]
    };

    this.initialValues = values;
    return values;
  };

  private getAddWriteOffDto = (values: AllocationFormValues) => {
    const writeOffItems: AddInvoiceItemReferenceDto[] = [];

    values.allocations?.forEach(item => {
      if (!!item.total) {
        writeOffItems.push({
          itemType: ItemType.WriteOff,
          comment: item.comment,
          amount: item.total,
          invoiceItemId: item.invoiceItemId,
          locationId: values.locationId
        });
      }
    });

    const location = this.root.core.getLocationName(values.locationId) ?? "";

    const writeOff: AddWriteOffDto = {
      accountId: this.invoice.accountId,
      location,
      accountContact: this.invoice.accountContact,
      transactionDate: DateTime.now().toISODate(),
      itemType: ItemType.WriteOff,
      items: writeOffItems,
      number: this.writeOffNumber,
      comment: values.comment
    };
    return writeOff;
  };

  public onSubmit = async (values: AllocationFormValues) => {
    await this.root.billing.addWriteOff(this.getAddWriteOffDto(values));
  };

  public onSubmitSucceeded = async () => {
    this.close();
  };

  public close = () => {
    closeInvoiceOrPaymentPage(this.root.routing, "replace");
  };

  public reset = async () => {
    this.getInitialValues();

    const balance = this.invoice.accountId
      ? await this.root.billing.getAccountBalanceForAccountId(
          this.invoice.accountId
        )
      : undefined;

    this.balance = balance;
  };
}
