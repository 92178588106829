import { Observer, observer } from "mobx-react-lite";
import { FC, useCallback } from "react";

import {
  CenteredBox,
  IColumn,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import {
  Permission,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { UsersFilter } from "@shared-types/core/users-filter.interface.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { LicenceTypes } from "./LicenceTypes.tsx";
import { Products } from "./Products.tsx";
import { Roles } from "./Roles.tsx";
import { UserListActionMenu } from "./UserListActionMenu.tsx";
import { UsersFilterItems } from "./UserListFilters.tsx";

type UserListItem = {
  id: User["id"];
  name: User["fullName"];
  status: string | undefined;
  businessRoles: string[];
  isProvider: boolean;
  products: string[];
};

const nameOf = nameOfFactory<UserListItem>();

interface UserListProps {
  filter: UsersFilterItems;
}

export const UserList: FC<UserListProps> = observer(
  ({ filter }: UserListProps) => {
    const { core } = useStores();
    const { lastUpdatedUserETag } = core;

    const search = useCallback(
      (query: UsersFilter) => {
        return core.fetchUsers({
          ...query,
          statusCodes: [UserStatus.Active, UserStatus.Pending],
          ...filter
        });
      },
      [core, filter]
    );

    const getUsers = async (query: UsersFilter) => {
      const filteredUsers = await search(query);

      const getUserListItem = (user: User): UserListItem => {
        return {
          id: user.id,
          name: user.name,
          status: user.status,
          businessRoles: user.businessRoles,
          isProvider: user.isProviderClass,
          products: ["Omni"] // Add the default product
        };
      };

      return filteredUsers
        ?.slice()
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        ?.map(getUserListItem);
    };

    const getStatus = (item: UserListItem) => {
      const PENDING_STATUS_TEXT = "Pending sign up";
      return item.status && item.status !== UserStatus.Pending
        ? capitalizeSentence(item.status)
        : PENDING_STATUS_TEXT;
    };

    const renderBadge = (item: UserListItem) => {
      if (item.status === UserStatus.Active) {
        return undefined;
      }

      let badgeColor = TextBadgeColor.grey;
      if (item.status === UserStatus.Pending) {
        badgeColor = TextBadgeColor.yellow;
      }

      return (
        <TextBadge
          badgeColor={badgeColor}
          badgeSize={TextBadgeSize.small}
          styles={{ root: { width: "93px" } }}
        >
          {getStatus(item)}
        </TextBadge>
      );
    };

    const columns: IColumn[] = [
      {
        name: "User name",
        key: nameOf("name"),
        minWidth: 100,
        maxWidth: 400,
        onRender: (item: UserListItem) => (
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 8 }}>
            <Navigate
              styles={{
                root: {
                  overflow: "hidden"
                }
              }}
              to={routes.settings.users.user.path({ id: item.id })}
            >
              {item.name}
            </Navigate>
            {renderBadge(item)}
          </Stack>
        )
      },
      {
        name: "Business roles",
        onRender: (item: UserListItem) => {
          return <Roles userRoles={item.businessRoles} />;
        },
        key: nameOf("businessRoles"),
        minWidth: 200,
        maxWidth: 300
      }
    ];

    // has the Licencing permission
    if (core.hasPermissions([Permission.LicencingAllowed])) {
      // Add Actions column to the first column
      columns.unshift({
        name: "Actions",
        className: RESET_CELLS_PADDING_CLASSNAME,
        key: "actions",
        minWidth: 50,
        maxWidth: 50,
        onRender: ({ id, isProvider }: UserListItem) =>
          isProvider && <UserListActionMenu userId={id} />
      });

      // Add Licence type and Bp products columns
      columns.push(
        {
          name: "Licence type",
          onRender: ({ id, isProvider }: UserListItem) =>
            isProvider ? <LicenceTypes userId={id} /> : <Text>N/A</Text>,
          key: "licences",
          minWidth: 200,
          maxWidth: 300
        },
        {
          name: "Bp products",
          onRender: ({ products }: UserListItem) => (
            <Products products={products} />
          ),
          key: nameOf("products"),
          minWidth: 200,
          maxWidth: 300
        }
      );
    }

    return (
      <div style={{ position: "relative", flexGrow: 1 }}>
        <Observer>
          {() => (
            <DataFetcher
              fetch={() => getUsers(filter)}
              refetchId={`${JSON.stringify(filter)}-${lastUpdatedUserETag}`}
            >
              {userList => {
                if (!userList.length) {
                  return (
                    <CenteredBox>
                      <DefaultNoResultsTile defaultText="No records found" />
                    </CenteredBox>
                  );
                }
                return (
                  <Stack
                    grow
                    styles={{ root: { position: "relative", height: "100%" } }}
                  >
                    <ScrollablePane>
                      <ShimmeredDetailsList
                        items={userList}
                        columns={columns}
                      />
                    </ScrollablePane>
                  </Stack>
                );
              }}
            </DataFetcher>
          )}
        </Observer>
      </div>
    );
  }
);
