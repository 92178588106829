import { FunctionComponent } from "react";
import { useField } from "react-final-form";

import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AppointmentNoticesColumn } from "./appointment-notices/AppointmentNoticesColumn.tsx";
import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { ConditionDetails } from "./condition-modal/ConditionDetails.tsx";
import { GroupAttendeesDetails } from "./group-attendees-details/GroupAttendeesDetails.tsx";
import { SelectedGroupAttendee } from "./group-attendees-details/SelectedGroupAttendee.tsx";
import { NextAvailableFields } from "./next-available-fields/NextAvailableFields.tsx";
import { NextGroupAppt } from "./next-group-appt/NextGroupAppts.tsx";
import { RepeatTwoColumnLayout } from "./RepeatTwoColumnLayout.tsx";

export const SecondColumnFieldsSwitcher: FunctionComponent = () => {
  const { booking } = useStores();

  const {
    input: { value: patientId }
  } = useField(appointmentFormNameOf("patientId"));

  switch (booking.ui.currentAppointment?.secondColumnContent) {
    case SecondColumnContent.nextAvailable:
      return <NextAvailableFields />;
    case SecondColumnContent.repeat:
      return <RepeatTwoColumnLayout />;
    case SecondColumnContent.conditionDetails:
      return <ConditionDetails />;
    case SecondColumnContent.patientNotices:
      if (patientId) return <AppointmentNoticesColumn />;
      return null;
    case SecondColumnContent.groupAttendees:
      return <GroupAttendeesDetails />;
    case SecondColumnContent.selectedGroupAttendee:
      return <SelectedGroupAttendee />;
    case SecondColumnContent.nextGroupAppt:
      return <NextGroupAppt />;
    default:
      return null;
  }
};
