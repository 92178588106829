import { observer } from "mobx-react-lite";
import { useForm, useFormState } from "react-final-form";

import {
  Heading,
  ITextFieldStyles,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  AccProviderContractTypeCodes,
  ProviderTypeCode
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { TreatmentDetailsFormValues } from "@shared-types/acc/treatement-detais-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContractType } from "@stores/practice/models/Provider.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { TreatmentDetailsFormValidator } from "../validators/TreatmentDetailsFormValidator.tsx";
import {
  ClaimAdjustmentFormLabels,
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";
import { OtherTreatmentField } from "./OtherTreatmentField.tsx";

const detailsFields: (keyof TreatmentDetailsFormValues)[] = [
  "treatmentStartDate"
];

export const TreatmentDetailsFormSection: React.FC = observer(() => {
  const { practice } = useStores();
  const { values } = useFormState<ClaimAdjustmentFormValues>();
  const { claimAdjustment } = useClaimAdjustmentContext();
  const form = useForm<ClaimAdjustmentFormValues>();
  const { selectedMenuItemId } = useCardFormLayoutContext();

  const providerContractType =
    practice.ref.accProviderContractTypes.values.filter(
      x => values.providerContractType === x.code
    )[0];

  const isAlliedHealth =
    providerContractType &&
    providerContractType.accPracticeContractTypeCode ===
      ProviderTypeCode.alliedHealthService;

  const isSpecifiedTreatment =
    providerContractType &&
    providerContractType.accPracticeContractTypeCode ===
      ProviderTypeCode.specifiedTreatmentProvider;

  const isPodaitrist = (providerContractType?: string) =>
    providerContractType === AccProviderContractTypeCodes.PodiatristAHS ||
    providerContractType === AccProviderContractTypeCodes.PodiatristSTP;

  const isProviderTypeSelected =
    isAlliedHealth || isSpecifiedTreatment || isPodaitrist();

  const MESSAGE_TEXT =
    "Select an ACC32 approved provider type to enable number of treatment requests required.";

  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.treatmentDetails}
      openOnRender={
        selectedMenuItemId === ClaimsAdjustmentCardIds.treatmentDetails
      }
      heading={ClaimAdjustmentSectionHeading.treatmentDetails}
      iconName="BpMedCare"
      errorProps={{ fields: detailsFields }}
      visible={claimAdjustment.hasTreatmentDetails}
      statusValidator={
        new TreatmentDetailsFormValidator(claimAdjustment, practice)
      }
    >
      {!isProviderTypeSelected && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          styles={{ root: { marginBottom: 10 } }}
        >
          {MESSAGE_TEXT}
        </MessageBar>
      )}
      <Stack tokens={{ childrenGap: 8 }}>
        <Heading variant="section-heading-light">
          {ClaimAdjustmentFormLabels.treatmentRequestDetails}
        </Heading>
        <Stack tokens={{ childrenGap: 8 }}>
          <BoolButtonGroupField
            label={ClaimAdjustmentFormLabels.firstAdditionalTreatmentRequested}
            name={claimAdjustmentFormNameOf(
              "firstAdditionalTreatmentRequested"
            )}
            required
            options={[
              { key: true, text: "Yes" },
              { key: false, text: "No" }
            ]}
          />
        </Stack>
        {isProviderTypeSelected && (
          <Heading
            variant="section-heading-light"
            styles={{ root: { "&&": { marginTop: 24 } } }}
          >
            {ClaimAdjustmentFormLabels.howManyTreatmentsNeed}
          </Heading>
        )}
        <Stack tokens={{ childrenGap: 8 }}>
          <FieldSpy
            name={claimAdjustmentFormNameOf("providerContractType")}
            onChange={(value: ContractType) => {
              form.batch(() => {
                if (
                  value === ContractType.HandTherapistAlliedHealth ||
                  value === ContractType.OccupationalTherapistAlliedHealth
                ) {
                  form.change("treatmentsRequired", undefined);
                }
                if (value === ContractType.PhysiotherapistAlliedHealth) {
                  form.change("treatmentsRequired", undefined);
                  form.change("handSplintingCost", "0");
                }
                if (value === ContractType.PhysiotherapistSpecifiedTreatment) {
                  form.change("initialAssessmentsNeeded", 0);
                  form.change("handSplintingCost", "0");
                  form.change("followUpTreatmentsNeeded", undefined);
                }
                if (!isPodaitrist(value)) {
                  form.batch(() => {
                    form.change(
                      claimAdjustmentFormNameOf(
                        "abscessHematomaTreatmentsNeeded"
                      ),
                      undefined
                    );
                    form.change(
                      claimAdjustmentFormNameOf(
                        "nailSimpleRemovalTreatmentsNeeded"
                      ),
                      undefined
                    );
                    form.change(
                      claimAdjustmentFormNameOf(
                        "nailRemovalResectionTreatmentsNeeded"
                      ),
                      undefined
                    );
                  });
                }
              });
            }}
          />
          {isAlliedHealth && (
            <>
              <ButtonsGroupSingleChoiceField
                label={ClaimAdjustmentFormLabels.initialAssessment}
                name={claimAdjustmentFormNameOf("initialAssessmentsNeeded")}
                required
                options={[
                  { key: 0, text: "0" },
                  { key: 1, text: "1" }
                ]}
              />
              <SpinNumberInputField
                required
                label={ClaimAdjustmentFormLabels.followUp}
                name={claimAdjustmentFormNameOf("followUpTreatmentsNeeded")}
                max={99}
                min={0}
                styles={{ root: { width: 120 } }}
                parse={value => Number(value)}
              />
            </>
          )}
          {isSpecifiedTreatment && (
            <SpinNumberInputField
              required
              label={ClaimAdjustmentFormLabels.treatmentsRequired}
              name={claimAdjustmentFormNameOf("treatmentsRequired")}
              max={99}
              min={0}
              styles={{ root: { width: 120 } }}
              parse={value => Number(value)}
            />
          )}
          {isPodaitrist(values.providerContractType) && (
            <Stack>
              <Heading labelPaddings>Other treatments</Heading>
              <Stack tokens={{ childrenGap: 8 }}>
                <OtherTreatmentField
                  name={claimAdjustmentFormNameOf(
                    "abscessHematomaTreatmentsNeeded"
                  )}
                  label="Abscess or haematoma: drainage with incision"
                />
                <OtherTreatmentField
                  name={claimAdjustmentFormNameOf(
                    "nailSimpleRemovalTreatmentsNeeded"
                  )}
                  label="Nails, simple removal of"
                />
                <OtherTreatmentField
                  name={claimAdjustmentFormNameOf(
                    "nailRemovalResectionTreatmentsNeeded"
                  )}
                  label="Nail removal with wedge resection"
                />
              </Stack>
            </Stack>
          )}
          <FieldCondition
            when={claimAdjustmentFormNameOf("providerContractType")}
            is={(value: ContractType) =>
              value === ContractType.HandTherapistAlliedHealth ||
              value === ContractType.OccupationalTherapistAlliedHealth
            }
          >
            <SpinNumberInputField
              required
              label={ClaimAdjustmentFormLabels.splintingHandCost}
              styles={{ root: { root: 120 } }}
              name={claimAdjustmentFormNameOf("handSplintingCost")}
              prefix="$"
              step={0.01}
              precision={2}
            />
          </FieldCondition>

          <DatePickerField
            styles={{ field: { minWidth: 115 } } as Partial<ITextFieldStyles>}
            name={claimAdjustmentFormNameOf("treatmentStartDate")}
            label={ClaimAdjustmentFormLabels.treatmentStartDate}
            required
            minDate={DateTime.now().minus({ days: 6 }).toJSDate()}
            validateOnInitialize={true}
          />
        </Stack>
      </Stack>
    </ClaimCard>
  );
});
