import { observer } from "mobx-react-lite";
import React from "react";

import {
  confirm,
  PillButton,
  Stack,
  Text,
  Tile,
  TooltipHost
} from "@bps/fluent-ui";
import {
  GAEvents,
  useGoogleAnalyticsEventTracking
} from "@libs/analytics/google/index.tsx";
import {
  AccClinicalTab,
  ClinicalDataType,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalTabItem } from "@stores/clinical/models/clinical-tab/ClinicalTabItem.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FORM_PROMPT_DEFAULT_MESSAGE } from "@ui-components/form/FinalForm.tsx";
import { defaultButtonsText } from "@ui-components/form/prompt/Prompt.tsx";

import { useNewClinicalTheme } from "../../../hooks/useNewClinicalTheme.tsx";
import { resetStashedClinicalData } from "./utils.ts";

export const EncounterDock: React.FC = observer(() => {
  const { clinical, userExperience } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const patientTabs = clinical.ui.tabs.currentPatientRecordTab;
  const notifyGoogleAnalytics = useGoogleAnalyticsEventTracking(
    GAEvents.clinicalDockSelected
  );

  const theme = useNewClinicalTheme();
  const tabsToTrack = [
    PermanentClinicalTab.TodaysNotes,
    PermanentClinicalTab.PatientSummary,
    PermanentClinicalTab.SOTAP
  ];

  const onClickTab = (tab: ClinicalTabItem) => () => {
    const tabName: undefined | string = tabsToTrack.includes(
      tab.type as PermanentClinicalTab
    )
      ? tab.title
      : tab.type;

    tabName && notifyGoogleAnalytics({ tabName });
    patientTabs &&
      patientTabs.setActive(tab.type, tab.encounterId, tab.contextId);
  };

  const patientLabel = userExperience.localisedConfig("patientDisplay", {
    capitalizeFirst: true
  });

  const handleClose = async (
    e: React.MouseEvent<HTMLElement>,
    tab: ClinicalTabItem
  ) => {
    e.stopPropagation();
    if (tab.isDirty) {
      const isConfirmed = await confirm({
        dialogContentProps: {
          subText: FORM_PROMPT_DEFAULT_MESSAGE
        },
        confirmButtonProps: {
          text: defaultButtonsText.confirm
        },
        cancelButtonProps: {
          text: defaultButtonsText.cancel
        }
      });

      if (!isConfirmed) {
        return;
      }
    }

    clinical.ui.closePatientRecordContentForm(clinicalRecord.id, tab.type);
    resetStashedClinicalData(tab.type as ClinicalDataType, clinicalRecord);
  };

  return (
    <Tile
      styles={{
        root: {
          padding: theme.spacing.s1,
          overflowY: "hidden",
          scrollbarWidth: "thin",
          minHeight: 45,
          width: "100%"
        }
      }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.s1 }}
        styles={{ root: { width: 500 } }}
      >
        {patientTabs?.allTabs?.map(tab => {
          return (
            <TooltipHost content={tab.toolTipText} key={tab.id}>
              <PillButton
                key={tab.id}
                active={tab.isActive}
                onClick={onClickTab(tab)}
                styles={{ root: { maxWidth: 150 } }}
                onClose={
                  tab.type === PermanentClinicalTab.PatientSummary ||
                  tab.type === PermanentClinicalTab.TodaysNotes ||
                  tab.type === PermanentClinicalTab.SOTAP ||
                  tab.type === AccClinicalTab.Injury
                    ? undefined
                    : ev => handleClose(ev, tab)
                }
                isDirty={tab.isDirty}
              >
                <Text nowrap>
                  {tab.type === PermanentClinicalTab.PatientSummary
                    ? `${patientLabel} summary`
                    : tab.title}{" "}
                </Text>
              </PillButton>
            </TooltipHost>
          );
        })}
      </Stack>
    </Tile>
  );
});
