import { FunctionComponent, ReactNode, useState } from "react";

import { IconButton, Stack, Text, TooltipHost, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { When } from "@ui-components/withPerm.tsx";

interface FreeTextNoteWrapperProps {
  heading?: string;
  boldHeading?: boolean;
  children: (toolBarHidden: boolean, autoFillBarHidden: boolean) => ReactNode;
}

export const FreeTextNoteWrapper: FunctionComponent<
  FreeTextNoteWrapperProps
> = ({ heading, boldHeading, children }) => {
  const [toolBarHidden, setToolBarHidden] = useState<boolean>(true);
  const [autoFillBarHidden, setAutoBarHidden] = useState<boolean>(true);

  const theme = useTheme();

  return (
    <div className="bp-FreeTextNoteWrapper">
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
        {heading && <Text bold={boldHeading}>{heading}</Text>}
        <TooltipHost content="Edit style">
          <IconButton
            iconProps={{ iconName: "EditStyle" }}
            styles={{
              root: {
                background: !toolBarHidden
                  ? theme.palette.neutralLighter
                  : "initial"
              }
            }}
            onClick={() => {
              setAutoBarHidden(true);
              setToolBarHidden(prev => !prev);
            }}
          />
        </TooltipHost>
        <When permission={Permission.AutofillAllowed}>
          <TooltipHost content="Autofill">
            <IconButton
              iconProps={{ iconName: "NumberSequence" }}
              onClick={() => {
                setToolBarHidden(true);
                setAutoBarHidden(prev => !prev);
              }}
              styles={{
                root: {
                  background: !autoFillBarHidden
                    ? theme.palette.neutralLighter
                    : "initial"
                }
              }}
            />
          </TooltipHost>
        </When>
      </Stack>
      {children(toolBarHidden, autoFillBarHidden)}
    </div>
  );
};
