import { DateTime, TIME_FORMATS } from "@bps/utils";
import {
  CalendarEventStatus,
  CalendarEventStatusText,
  RecurrenceLinkingActionType,
  RecurrenceRuleDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import {
  AvailabilitySlot,
  SlotGroup
} from "@stores/booking/models/AvailabilitySlots.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

import { AppointmentStatusTextValues } from "../../../shared-components/appointment-status/utils.ts";
import {
  getCount,
  getDayRecur,
  getMonthDayRecur,
  getMonthRecur,
  getRecurrenceWeekPosition,
  getUntil
} from "../../../utils.tsx";

export const recurrenceRuleDto = (
  values: AppointmentFormValues,
  overrideCount: number | undefined = undefined
): RecurrenceRuleDto => {
  const frequency = Number(values.frequency);
  const startDate = DateTime.fromJSDate(values.startDate!);

  const recurrenceRule = {
    count: overrideCount ?? getCount(values.endScheduleType, values.count),
    frequency,
    weekPosition: getRecurrenceWeekPosition(
      startDate,
      values.frequency,
      values.monthYearRecurrence
    ),
    dayRecur: getDayRecur({
      startDate,
      frequency: values.frequency,
      dayRecur: values.dayRecur,
      monthYearRecurrence: values.monthYearRecurrence
    }),
    monthDayRecur: getMonthDayRecur(
      startDate,
      values.frequency,
      values.monthYearRecurrence
    ),
    interval: values.interval!,
    monthRecur: getMonthRecur(frequency, startDate),
    until: getUntil(values.endScheduleType, values.until)
  };

  return recurrenceRule;
};

export const getGroupByCountSlices = (
  availabilitySlot: AvailabilitySlot[],
  slotSize: number
): SlotGroup[] => {
  const slotGroup: SlotGroup[] = [];
  const arrayLength = availabilitySlot.length;
  let arrayIndex = 0;
  while (arrayIndex < arrayLength) {
    const output: SlotGroup = {
      time: []
    };
    availabilitySlot
      .slice(arrayIndex, (arrayIndex += slotSize))
      .forEach(value => {
        output.time.push(value);
      });
    slotGroup.push(output);
  }
  return slotGroup;
};

export const getTimeSpan = (minutes: number): string => {
  const hourInMinutes = Number(60);
  return `00.${Math.floor(minutes / hourInMinutes)}:${
    minutes % hourInMinutes
  }:00`;
};

export const getRecurrenceRule = (values: AppointmentFormValues) => {
  const item = recurrenceRuleDto(values);
  if (
    !values.repeat ||
    (!item.dayRecur && !item.monthDayRecur) ||
    !values.endScheduleType ||
    (values.endScheduleType === EndScheduleType.OnDate && !item.until) ||
    (values.endScheduleType === EndScheduleType.After && !item.count)
  ) {
    return undefined;
  }

  const recurrenceRule = {
    frequency: item.frequency,
    until: item.until ? DateTime.fromISO(item.until) : undefined,
    count:
      item.until === undefined && item.count === undefined ? 1 : item.count,
    dayRecur: item.dayRecur,
    interval: item.interval,
    monthDayRecur: item.monthDayRecur,
    monthRecur: item.monthRecur,
    start:
      values.startDate &&
      DateTime.fromMillis(
        values.startDate.setHours(
          values.startDate && (values.startDate.getTimezoneOffset() / 60) * -1
        )
      ),
    weekPosition: item.weekPosition
  };

  return recurrenceRule;
};

export const formatTimeSpanWithDate = (
  time: string,
  formatOptions: {
    date?: Date | undefined;
    duration?: number;
  }
) => {
  let parsedTime = DateTime.fromParsedTimeString(time);
  if (!parsedTime) return undefined;

  const parsedDate = DateTime.fromJSDate(formatOptions.date);
  if (parsedDate) {
    parsedTime = parsedTime.set({
      year: parsedDate.year,
      month: parsedDate.month,
      day: parsedDate.day
    });
  }

  parsedTime = parsedTime.plus({ minutes: formatOptions.duration ?? 0 });

  return parsedTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);
};

export const getLinkedOccurrenceCount = (
  calendarEvents: CalendarEvent[] | undefined
): number => {
  if (!calendarEvents) {
    return 0;
  }
  return calendarEvents.reduce((count, calendarEvent) => {
    if (calendarEvent.reason) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);
};

export const getRecurrenceLinkText = (
  displayedCondition: string,
  occurrencesOptions: {
    totalOccurrences: number;
    recurrenceOccurred: number;
  },

  linkingAction?: RecurrenceLinkingActionType
): string => {
  let message: string = "";

  const { totalOccurrences, recurrenceOccurred } = occurrencesOptions;

  if (totalOccurrences > 1) {
    if (linkingAction === RecurrenceLinkingActionType.LinkAll) {
      message = `${totalOccurrences} appointments linked to ${displayedCondition}`;
    } else if (linkingAction === RecurrenceLinkingActionType.UnlinkAll) {
      message = `0 appointments linked to ${displayedCondition}`;
    } else {
      message = `${recurrenceOccurred} of ${totalOccurrences} appointments linked to ${displayedCondition}`;
    }
  }

  return message;
};

export const getAppointmentStatusFromCode = (code: string, type: string) => {
  return type === CalendarEventStatus.Confirmed
    ? AppointmentStatusTextValues[code]
    : CalendarEventStatusText[type];
};
