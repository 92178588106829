import { FunctionComponent } from "react";

import {
  Badge,
  BadgeProps,
  mergeStyleSets,
  Theme,
  useTheme
} from "@bps/fluent-ui";
import { ReactionSeverity } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

interface ReactionBadgeProps extends BadgeProps {
  severity?: ReactionSeverity;
}

export const getSeverityStylingOptions = (
  theme: Theme,
  severity?: ReactionSeverity
) => {
  switch (severity) {
    default:
      return undefined;
    case ReactionSeverity.Mild:
      return {
        iconName: "Warning",
        iconColor: theme.palette.neutralSecondary,
        badgeColor: theme.palette.neutralLighterAlt
      };
    case ReactionSeverity.Moderate:
      return {
        iconName: "WarningSolid",
        iconColor: theme.palette.yellowDark,
        badgeColor: theme.semanticColors.warningBackground
      };
    case ReactionSeverity.Severe:
      return {
        iconName: "AlertSolid",
        iconColor: theme.semanticColors.errorText,
        badgeColor: theme.semanticColors.errorBackground
      };
  }
};

const ReactionBadge: FunctionComponent<ReactionBadgeProps> = ({
  severity,
  styles,
  ...rest
}) => {
  const theme = useTheme();
  const allStyles = mergeStyleSets(
    {
      root: {
        backgroundColor: getSeverityStylingOptions(theme, severity)?.badgeColor
      }
    },
    styles
  );
  return <Badge styles={allStyles} {...rest} />;
};

export { ReactionBadge };
