import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";

export const SetClaimOnCalendarEventChangeSpy: FunctionComponent = observer(
  () => {
    const { acc } = useStores();

    const form = useForm<InvoiceFormValues>();

    const onChange = async (
      calendarEventId: InvoiceFormValues["calendarEventId"]
    ) => {
      if (calendarEventId) {
        const ca = await acc.getClaimAppointmentDtos({ calendarEventId });

        const { values } = form.getState();
        if (ca.length && ca[0].claimId !== values.claimId) {
          form.change("claimId", ca[0].claimId || "");
        }
      }
    };

    return (
      <FieldSpy
        name={invoiceFormNameOf("calendarEventId")}
        onChange={onChange}
      />
    );
  }
);
