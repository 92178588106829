import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import {
  CenteredLargeSpinner,
  CommandBarButton,
  dataAttribute,
  DataAttributes,
  DirectionalHint,
  IconButton,
  IIconProps,
  IOverflowSetItemProps,
  ResizableOverflowBar,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { AutofillContext } from "@ui-components/text-editors/editor-autofills/editor-autofill.types.ts";

import { SyncFusionAutofills } from "./autofills/SyncFusionAutofills.tsx";
import { SyncFusionDocumentEditorContext } from "./context/SyncFusionDocumentEditorContext.tsx";
import { SyncFusionMergeFields } from "./SyncFusionMergeFields.tsx";

enum SidePanelContent {
  Merge = "Merge",
  Autofill = "Autofill"
}

interface SyncFusionDocumentEditorSidePanelProps {
  autofillContext: AutofillContext;
  docEditor: DocumentEditor | undefined;
}
export const SyncFusionDocumentEditorSidePanel: React.FC<SyncFusionDocumentEditorSidePanelProps> =
  observer(({ autofillContext, docEditor }) => {
    const theme = useTheme();
    const { core } = useStores();
    const {
      sidePanelSize,
      toggleSidePanel,
      loadTemplateDataModelAndData,
      contextOption,
      loadAutofills,
      isTemplateEditor
    } = useContext(SyncFusionDocumentEditorContext);

    const onToggleExpandOrCollapse = (isExpanding: boolean) => {
      toggleSidePanel(isExpanding);
    };

    const [sidePanelContent, setSidePanelContent] = useState<SidePanelContent>(
      SidePanelContent.Merge
    );

    const baseIconProps: IIconProps = {
      styles: () => ({
        root: {
          color: theme.palette.neutralPrimary,
          selectors: {
            "&:hover": {
              color: theme.palette.neutralPrimary
            }
          }
        }
      })
    };

    const sidePanelLinks: IOverflowSetItemProps[] = [
      {
        iconProps: {
          ...baseIconProps,
          iconName: "TextDocumentShared"
        },
        name: "Merge fields",
        key: SidePanelContent.Merge,
        onClick: () => {
          setSidePanelContent(SidePanelContent.Merge);
        }
      }
    ];

    if (core.hasPermissions(Permission.AutofillAllowed)) {
      sidePanelLinks.push({
        iconProps: {
          ...baseIconProps,
          iconName: "NumberSequence"
        },
        name: "Autofill",
        key: SidePanelContent.Autofill,
        onClick: () => {
          setSidePanelContent(SidePanelContent.Autofill);
        }
      });
    }

    const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
      return (
        <TooltipHost
          content={item.name}
          directionalHint={DirectionalHint.leftCenter}
        >
          <CommandBarButton
            {...dataAttribute(
              DataAttributes.Element,
              `doc-side-nav-button-${item.key}`
            )}
            {...item}
            styles={{
              root: {
                height: 44,
                padding: "0 7px",
                backgroundColor:
                  item.key === sidePanelContent
                    ? theme.palette.neutralLight
                    : "transparent"
              }
            }}
            onClick={item.onClick}
          />
        </TooltipHost>
      );
    };

    const onRenderOverflowButton = (
      overflowItems: any[] | undefined
    ): JSX.Element => {
      return (
        <CommandBarButton
          role="menuitem"
          title="More items"
          menuIconProps={{ iconName: "More" }}
          menuProps={{
            items: overflowItems!
          }}
        />
      );
    };

    return (
      <Stack verticalFill horizontal>
        <Stack
          styles={{
            root: {
              backgroundColor: theme.palette.themeLighterAlt
            }
          }}
        >
          <ResizableOverflowBar
            itemSize={45}
            itemsGap={0}
            items={sidePanelLinks}
            onRenderOverflow={onRenderOverflowButton}
            onRenderItem={onRenderItem}
            vertical
          />

          <IconButton
            styles={{
              root: {
                width: "100%",
                borderRadius: 0
              }
            }}
            iconProps={{
              iconName: "DoubleChevronRight12",
              styles: props => ({
                root: {
                  fontSize: props.theme!.fonts.small.fontSize
                }
              })
            }}
            onClick={() => onToggleExpandOrCollapse(false)}
            title="Collapse"
            disabled={sidePanelSize === TreeViewSize.IconsOnly}
          />
          <IconButton
            styles={{
              root: {
                width: "100%",
                borderRadius: 0
              }
            }}
            iconProps={{
              iconName: "DoubleChevronLeft12",
              styles: props => ({
                root: {
                  fontSize: props.theme!.fonts.small.fontSize
                }
              })
            }}
            onClick={() => onToggleExpandOrCollapse(true)}
            title="Expand"
            disabled={sidePanelSize === TreeViewSize.Default}
          />
        </Stack>

        <Stack
          styles={{
            root: {
              width: "100%",
              display: sidePanelSize === TreeViewSize.Default ? "flex" : "none"
            }
          }}
        >
          <Stack
            tokens={{ childrenGap: 8 }}
            verticalFill
            styles={{
              root: {
                padding: 16,
                maxHeight: "100%",
                position: "relative"
              }
            }}
          >
            {sidePanelContent === SidePanelContent.Merge && (
              <DataFetcher
                fetch={loadTemplateDataModelAndData}
                fallback={<CenteredLargeSpinner />}
                refetchId={contextOption.contactId}
              >
                {() => <SyncFusionMergeFields />}
              </DataFetcher>
            )}
            {sidePanelContent === SidePanelContent.Autofill && (
              <DataFetcher
                fetch={loadAutofills}
                fallback={<CenteredLargeSpinner />}
              >
                {autofills => (
                  <SyncFusionAutofills
                    docEditor={docEditor}
                    autofillContext={autofillContext}
                    autofills={autofills}
                    isTemplate={isTemplateEditor ?? false}
                  />
                )}
              </DataFetcher>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  });
