import { observer } from "mobx-react-lite";

import { Dialog, Heading } from "@bps/fluent-ui";
import { useDialogOpenedAnalytics } from "@libs/analytics/hooks/useDialogOpenedAnalytics.ts";
import { AppointmentInformationModalContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModalContext.ts";
import { AppointmentInformationModalHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModalHelper.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

import { AppointmentInformationModalInner } from "./AppointmentInformationModalInner.tsx";
import { AppointmentInformationModalPrintWrapper } from "./AppointmentInformationModalPrintWrapper.tsx";

type AppointmentInformationModalProps = {
  contactId: string;
  patientName: string;
  isHidden?: boolean;
  selectedId?: string;
  onDismissInfoModal: () => void;
};
export const AppointmentInformationModal: React.FunctionComponent<AppointmentInformationModalProps> =
  observer(
    ({ contactId, isHidden, selectedId, onDismissInfoModal, patientName }) => {
      const root = useStores();
      const helper = usePersistedHelper(
        () => new AppointmentInformationModalHelper(root)
      );
      useDialogOpenedAnalytics(
        "Appointment information dialog",
        root.core.user?.fullName,
        isHidden
      );

      const title = (
        <Heading variant="modal-heading">
          Appointment information for {patientName}
        </Heading>
      );

      return (
        <Dialog
          hidden={isHidden}
          onDismiss={onDismissInfoModal}
          dialogContentProps={{
            title,
            showCloseButton: true,
            styles: {
              content: { height: "100%" },
              inner: {
                paddingLeft: 24,
                paddingRight: 24
              }
            }
          }}
          minWidth={900}
        >
          <AppointmentInformationModalContext.Provider value={helper}>
            <AppointmentInformationModalPrintWrapper title={title}>
              <AppointmentInformationModalInner
                contactId={contactId}
                selectedId={selectedId}
                onDismissInfoModal={onDismissInfoModal}
              />
            </AppointmentInformationModalPrintWrapper>
          </AppointmentInformationModalContext.Provider>
        </Dialog>
      );
    }
  );
