import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

import { ClinicalActivityDialog } from "../clinical-activity/ClinicalActivityDialog.tsx";
import { PatientClinicalActivityFormModel } from "../clinical-activity/PatientClinicalActivityFormModel.ts";
import { ClinicalActivitySummaryContent } from "./ClinicalActivitySummaryContent.tsx";

export interface ClinicalActivitySummaryWrapperProps {
  clinicalRecord: ClinicalRecord;
  isDialogVisible: boolean;
  handleDialogVisbleChanged: (isVisible: boolean) => void;
}

export const ClinicalActivitySummaryWrapper: React.FC<
  ClinicalActivitySummaryWrapperProps
> = ({ clinicalRecord, isDialogVisible, handleDialogVisbleChanged }) => {
  const root = useStores();
  const clinicalActivityFormModel =
    usePersistedHelper<PatientClinicalActivityFormModel>(
      () => new PatientClinicalActivityFormModel(clinicalRecord, root)
    );

  return (
    <>
      <ClinicalActivitySummaryContent
        clinicalRecord={clinicalRecord}
        handleDialogVisbleChanged={handleDialogVisbleChanged}
      />
      <ClinicalActivityDialog
        hidden={!isDialogVisible}
        clinicalRecordId={clinicalRecord.id}
        clinicalActivities={clinicalRecord?.clinicalActivities}
        onSubmit={clinicalActivityFormModel.onSubmit}
        onDismiss={() => {
          handleDialogVisbleChanged(false);
        }}
        initialValues={clinicalActivityFormModel.initialValues}
      />
    </>
  );
};
