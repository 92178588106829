import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { Heading, Stack, ToolTipButton, useTheme } from "@bps/fluent-ui";

interface ObservationsResponsiveHeaderProps {
  heading?: string;
  onClose: () => void;
  pivotTab?: ReactNode;
  hasSeparator?: boolean;
}

export const ObservationsResponsiveHeader: React.FC<ObservationsResponsiveHeaderProps> =
  observer(({ heading, onClose, pivotTab, hasSeparator }) => {
    const { palette } = useTheme();
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{
          root: {
            padding: "0 16px",
            width: "100%",
            borderBottom: hasSeparator
              ? `1px solid ${palette.neutralLighter}`
              : "none"
          }
        }}
      >
        <Stack horizontal verticalAlign="center">
          <Heading variant="section-heading">{heading}</Heading>
          <Stack
            horizontal
            styles={{
              root: { marginTop: 10, paddingBottom: 8, paddingLeft: 8 }
            }}
          >
            {pivotTab}
          </Stack>
        </Stack>

        <Stack horizontal verticalAlign="center">
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: "8" }}
          >
            <ToolTipButton
              toolTipContent="Close"
              buttonProps={{
                text: "Close",
                onClick: onClose
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  });
