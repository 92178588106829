import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { BillingScreenWrapper } from "@modules/billing/screens/shared-components/BillingScreenWrapper.tsx";

import { TransactionFilterBar } from "../shared-components/AllocationList/transaction-filter/TransactionFilterBar.tsx";
import { TransactionList } from "../shared-components/AllocationList/transaction-list/TransactionList.tsx";
import { BillingButtons } from "./components/BillingButtons.tsx";

export const BillingScreen: FunctionComponent = observer(() => {
  return (
    <BillingScreenWrapper headerButtons={<BillingButtons hideAccountCredit />}>
      <TransactionFilterBar>
        <TransactionList />
      </TransactionFilterBar>
    </BillingScreenWrapper>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default BillingScreen;
