import React, { useState } from "react";
import { useForm } from "react-final-form";

import {
  ActionButton,
  CollapsibleCard,
  defaultStandardWeekRangesOptionsKeys,
  MessageBar,
  MessageBarType,
  NoDataTile,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { GoalDataItem } from "@shared-types/clinical/goal-data-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DatesRangePickerField } from "@ui-components/form/DatesRangePickerField/DatesRangePickerField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import {
  getGoalAchievedDateText,
  getTimeUntilGoalCompletionText,
  sotapNameOf
} from "./SOTAP.utils.ts";

interface IGoalsCardProps {
  isViewOnly?: boolean;
  linkedCondition?: boolean;
  inDischarge?: boolean;
}

export const GoalsCard: React.FC<IGoalsCardProps> = props => {
  const { isViewOnly, linkedCondition, inDischarge } = props;
  const form = useForm<SOTAPFormValues>();
  const psfsGoalsAdded = form.getState().values.psfsGoalsAdded;
  const { fields: goals } = useFieldArray<GoalDataItem>("goals");

  const theme = useTheme();

  const [showMessageBar, setShowMessageBar] = useState<boolean>(
    psfsGoalsAdded ?? false
  );

  const removeGoalRow = (index: number) => {
    goals.remove(index);
  };

  const name = sotapNameOf("goals");

  const isNoGoals = goals.length === 0;

  return (
    <div id={SotapMskId.Goals}>
      <CollapsibleCard
        heading={SotapMskLabel.Goals}
        headingLevel="section-heading"
        iconName="Checklist"
        openOnRender={linkedCondition}
        styles={{ collapseContent: { paddingRight: 32, paddingLeft: 32 } }}
        disabled={!linkedCondition}
      >
        <>
          {isNoGoals ? (
            <NoDataTile
              textProps={{ text: "No goals to display" }}
              linkProps={{
                text: "Add a goal",
                onClick: () => goals.push({ startDate: DateTime.jsDateNow() }),
                hidden: isViewOnly
              }}
              showBoxShadow={false}
              greyView={true}
            />
          ) : (
            <Stack>
              {showMessageBar && (
                <MessageBar
                  messageBarType={MessageBarType.success}
                  onDismiss={() => setShowMessageBar(false)}
                >
                  Autofilled from PSFS
                </MessageBar>
              )}
              <FieldArray name={name}>
                {({ fields }) => {
                  return (
                    <Stack tokens={{ childrenGap: 8 }}>
                      {fields.map((item, index) => {
                        return (
                          <Fieldset
                            key={item}
                            tokens={{ childrenGap: 8 }}
                            frame
                          >
                            <Stack
                              horizontal
                              verticalAlign="center"
                              tokens={{ childrenGap: 8 }}
                            >
                              <TextInputField
                                placeholder="Goal"
                                name={`${name}[${index}].goal`}
                                rows={1}
                                multiline
                                autoAdjustHeight
                                resizable={false}
                                styles={{
                                  fieldGroup: { minHeight: 30 },
                                  root: { flexGrow: 1 }
                                }}
                              />

                              <DatesRangePickerField
                                styles={{
                                  fieldGroup: { width: 200 },
                                  root: { width: "50%" }
                                }}
                                placeholder="Date(s)"
                                weekRanges={[
                                  ...defaultStandardWeekRangesOptionsKeys
                                ]}
                                endDateName={`${name}[${index}].endDate`}
                                startDateName={`${name}[${index}].startDate`}
                                closeCalloutOnSelection
                                renderCloseBtn
                                hidePasteStartButton
                                label="Timeframe"
                                horizontalLabel={true}
                                disabled={isViewOnly}
                              />

                              {!isViewOnly && (
                                <DeleteButton
                                  disabled={fields.length === 1 || inDischarge}
                                  onClick={() => removeGoalRow(index)}
                                />
                              )}
                            </Stack>
                            <Stack
                              horizontal
                              tokens={{ childrenGap: 8 }}
                              verticalAlign="center"
                            >
                              <ButtonsGroupSingleChoiceField
                                name={`${name}[${index}].isAchieved`}
                                options={[
                                  { key: true, text: "Achieved" },
                                  { key: false, text: "Not achieved" }
                                ]}
                                styles={{ root: { flex: "1" } }}
                              />

                              <Text
                                styles={{
                                  root: {
                                    color: theme.palette.neutralSecondary
                                  }
                                }}
                              >
                                {getTimeUntilGoalCompletionText({
                                  goals: goals.value,
                                  goalIndex: index
                                })}
                              </Text>

                              <FieldCondition
                                when={`${name}[${index}].isAchieved`}
                                is={true}
                              >
                                <DatePickerField
                                  name={`${name}[${index}].achievedDate`}
                                  validateOnInitialize
                                  styles={{
                                    errorMessage: {
                                      whiteSpace: "normal",
                                      wordBreak: "break-word"
                                    }
                                  }}
                                />
                              </FieldCondition>

                              <Text
                                styles={{
                                  root: {
                                    color: theme.palette.neutralSecondary
                                  }
                                }}
                              >
                                {getGoalAchievedDateText(goals.value, index)}
                              </Text>

                              <FieldSpy
                                name={`${name}[${index}].isAchieved`}
                                onChange={() => {
                                  const goal = goals.value[index];
                                  goal.notAchievedReason = undefined;
                                  goal.achievedDate = undefined;
                                  form.mutators.update(name, index, goal);
                                }}
                              />

                              <FieldCondition
                                when={`${name}[${index}].isAchieved`}
                                is={false}
                              >
                                <TextInputField
                                  placeholder="Reason"
                                  name={`${name}[${index}].notAchievedReason`}
                                  rows={1}
                                  multiline
                                  autoAdjustHeight
                                  resizable={false}
                                  styles={{
                                    fieldGroup: { minHeight: 30 },
                                    root: {
                                      flex: "1"
                                    }
                                  }}
                                />
                              </FieldCondition>
                            </Stack>
                          </Fieldset>
                        );
                      })}
                    </Stack>
                  );
                }}
              </FieldArray>
              {!isViewOnly && (
                <ActionButton
                  iconProps={{ iconName: "Add" }}
                  title="Add more"
                  disabled={inDischarge}
                  onClick={() =>
                    goals.push({
                      startDate: DateTime.jsDateNow(),
                      achievedDate: undefined
                    })
                  }
                  styles={{ root: { width: 400 } }}
                >
                  Add another
                </ActionButton>
              )}
            </Stack>
          )}
        </>
      </CollapsibleCard>
    </div>
  );
};
