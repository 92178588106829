import {
  EmployerDto,
  PrivateInsurerDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { email } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

export class OrganisationTypeValidator extends Validator<
  PrivateInsurerDto | EmployerDto
> {
  constructor() {
    super();
    this.forField("claimsAdminEmail", email());
    this.forField("invoicingEmail", email());
  }
}
