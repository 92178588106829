import { HL7HeaderResponse } from "./upload-doc-form/UploadDocForm.types.ts";

export const formatHL7Response = (response: HL7HeaderResponse) => {
  const displayAddress = () => {
    if (
      response.PatientStreetAddress &&
      response.PatientCity &&
      response.PatientPostcode
    )
      return `${response.PatientStreetAddress}, ${response.PatientCity}, ${response.PatientPostcode}`;

    return "Not provided";
  };

  return {
    PatientAddress: displayAddress(),
    LabReference: response.LabReference || "Not provided",
    LabName: response.LabName || "Not provided",
    ReferredBy: response.ReferredBy?.trim() || "Not provided",
    Addressee: response.Addressee?.trim() || "Not provided",
    CopyTo: response.CopyTo || "Not provided"
  };
};

export const displayPatientName = (response: HL7HeaderResponse) => {
  return `${response.PatientFirstName} ${response.PatientSurname}`;
};

export const dobAndSex = (response: HL7HeaderResponse) => {
  return {
    dob: response.PatientDob,
    sex: response.PatientSex
  };
};

export const displayHL7RelatedDates = (response: HL7HeaderResponse) => {
  const result: string[] = [];

  if (response.TestName) {
    result.push(response.TestName);
  }

  if (response.CollectionDate) {
    result.push(`Collected ${response.CollectionDate}`);
  }

  if (response.ReportDate) {
    result.push(`Reported ${response.ReportDate}`);
  }

  if (response.RequestedDate) {
    result.push(`Requested ${response.RequestedDate}`);
  }

  return result;
};
