import { useForm } from "react-final-form";

import { DetailsList, IColumn } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { MotionTypeItem } from "@shared-types/clinical/motion-type-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { MotionTypeText } from "@ui-components/RefText.tsx";

interface MotionAssessmentTableProps {
  name: string;
  injuryArea: string;
  injuryAreaGroup: string;
  injurySide?: string;
}

// @TODO to be remove after new motion assessment layouts implementation. Ilya S.
export const MotionAssessmentTable: React.FC<MotionAssessmentTableProps> = ({
  name,
  injuryArea,
  injuryAreaGroup,
  injurySide
}) => {
  const form = useForm<SOTAPFormValues>();

  // The Date column can only appear if there are values to display, therefor we have to append it if required.
  const getColumns = () => {
    const { values } = form.getState();
    // Find if there is any prior injuries for this item.
    const itemAndArea = values.priorInjuryAreaMotionAssesments?.filter(
      x =>
        x.injuryArea === injuryArea &&
        x.injuryAreaGroup === injuryAreaGroup &&
        x.injurySide === injurySide
    );

    const shouldAppendPrevious = !!itemAndArea?.length;

    const columns: IColumn[] = [
      {
        key: "typeOfMotion",
        onRender: (value: MotionTypeItem) => (
          <MotionTypeText code={value.code} />
        ),
        name: "Type of motion",
        minWidth: shouldAppendPrevious ? 210 : 325,
        maxWidth: shouldAppendPrevious ? 210 : 325,
        styles: {}
      },
      {
        key: "active",
        onRender: (item: MotionTypeItem, index: number) => (
          <SpinNumberInputField
            name={`${name}[${index}].active`}
            maxDigitsNumber={3}
            min={0}
            max={180}
            step={1}
            valueSuffix={"\u00B0"}
            styles={{
              root: {
                verticalAlign: "center"
              }
            }}
          />
        ),
        name: "Active",
        minWidth: shouldAppendPrevious ? 100 : 110,
        maxWidth: shouldAppendPrevious ? 100 : 110
      },
      {
        key: "passive",
        onRender: (value: MotionTypeItem, index: number) => (
          <SpinNumberInputField
            name={`${name}[${index}].passive`}
            maxDigitsNumber={3}
            min={0}
            max={180}
            step={1}
            styles={{ root: { verticalAlign: "center" } }}
            valueSuffix={"\u00B0"}
          />
        ),
        name: "Passive",
        minWidth: shouldAppendPrevious ? 100 : 110,
        maxWidth: shouldAppendPrevious ? 100 : 110
      }
    ];

    if (shouldAppendPrevious) {
      columns.push({
        key: "previousValues",
        onRender: (value: MotionTypeItem, index: number) => (
          <>{getDisplayForItem(index)}</>
        ),
        name: `${DateTime.fromJSDate(
          values.priorInjuryRecordedDate
        )?.toDayDefaultFormat()}`,
        minWidth: 110,
        maxWidth: 110
      });
    }

    return columns;
  };

  const getDisplayForItem = (index: number) => {
    const { values } = form.getState();
    const itemAndArea = values.priorInjuryAreaMotionAssesments?.filter(
      x =>
        x.injuryArea === injuryArea &&
        x.injuryAreaGroup === injuryAreaGroup &&
        x.injurySide === injurySide
    );

    if (itemAndArea && itemAndArea.length > 0) {
      if (itemAndArea[0].motionTypes) {
        const item = itemAndArea[0].motionTypes[index];
        if (item) return `${item.active} / ${item.passive}`;
      }
    }

    return "";
  };

  return (
    <FieldArray<MotionTypeItem> name={name}>
      {({ fields: { value } }) => (
        <DetailsList
          items={value ?? []}
          columns={getColumns()}
          styles={{
            root: {
              overflow: "visible",
              ".ms-DetailsRow-cell": {
                alignSelf: "center"
              },
              ".ms-DetailsRow-fields": {
                width: "calc(100% - 2px)"
              },
              ".ms-DetailsHeader": { display: "flex" }
            }
          }}
        />
      )}
    </FieldArray>
  );
};
