import {
  ContactStatus,
  EmployerDto,
  OrganisationContactDto,
  OrganisationRoleTypeCode,
  PrivateInsurerDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";

export interface EditOrganisationFormValues
  extends Partial<Pick<OrganisationContactDto, "id" | "organisationName">> {
  relationships: OrgRelationshipFieldValue[];
  status?: ContactStatus;
  communications: CommunicationFieldValue[];
  addresses: AddressFieldValue[];
  organisationCategories?: string[];
  linkOrganizationId?: string;
  profilePictureUrl?: string;
  organisationRoles: OrganisationRoleTypeCode[];
  privateInsurer?: PrivateInsurerDto;
  employer?: EmployerDto;
  usingPrivateInsurer?: boolean;
  draftItemsEnabled: boolean;
  disciplines?: string[];
  isHealthProvider: boolean;
}

export type OrgRelationshipFieldValue = {
  relatedContactId?: string;
  professional: RelationshipType[];
  org: RelationshipType[];
  autoFocus: boolean;
  id: string;
  currentJob: boolean;
};

export const orgEditFormNameOf = nameOfFactory<EditOrganisationFormValues>();
