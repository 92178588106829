import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { AllocationFormBillToField } from "@modules/billing/screens/allocation/components/AllocationFormBillToField.tsx";
import {
  BillingDetailsRow,
  BillingDetailsRowItem
} from "@modules/billing/screens/shared-components/BIllingDetailsRow.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { useAllocationFormContext } from "../context/AllocationFormContext.ts";
import { allocationNameOf } from "./AllocationForm.types.ts";
import { AllocationFormAccountState } from "./AllocationFormAccountState.tsx";
import { AllocationOptionsChoiceField } from "./AllocationOptionsChoiceFields.tsx";
import { PaymentsField } from "./payment-field/PaymentsField.tsx";

interface AllocationFormDetailsProps {
  heading?: string;
}

export const AllocationFormDetails: React.FC<AllocationFormDetailsProps> = ({
  heading
}) => {
  const { disableAccountPicker, hideAmountSelection } =
    useAllocationFormContext();

  const {
    input: { value: accountContactId }
  } = useField(allocationNameOf("accountContactId"), {
    subscription: { value: true }
  });

  const items: BillingDetailsRowItem[] = [
    {
      id: "1",
      width: 348,
      content: <AllocationFormBillToField showPicker={!disableAccountPicker} />
    }
  ];

  if (!!accountContactId) {
    items.push(
      {
        id: "2",
        width: 521,
        content: (
          <Stack tokens={{ childrenGap: 16 }}>
            <AllocationOptionsChoiceField heading={heading} />
            {!hideAmountSelection && <PaymentsField />}
          </Stack>
        )
      },
      {
        id: "3",
        grow: true,
        content: (
          <Stack tokens={{ childrenGap: 8 }}>
            <AllocationFormAccountState />
            <TextInputField
              label="Comment"
              name={allocationNameOf("comment")}
              multiline
              resizable={false}
              rows={4}
              maxLength={500}
            />
          </Stack>
        )
      }
    );
  }

  return <BillingDetailsRow items={items} />;
};
