import { NativeList } from "@bps/fluent-ui";
import { ClaimDiagnosisDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface InjuryDiagnosisListProps {
  claimDiagnosis: ClaimDiagnosisDto[];
}

export const InjuryDiagnosisList: React.FC<InjuryDiagnosisListProps> = ({
  claimDiagnosis
}) => {
  const { acc } = useStores();

  const getListItems = () => {
    return claimDiagnosis?.map(x => (
      <li key={`${x.terminology?.code}${x.diagnosisSide}`}>
        {x.terminology?.text} -{" "}
        {
          acc.ref.diagnosisSides.keyTextValues.find(
            side => side.key === x.diagnosisSide
          )?.text
        }
      </li>
    ));
  };

  return <NativeList>{getListItems()}</NativeList>;
};
