import { useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  Stack
} from "@bps/fluent-ui";
import {
  NatureOfReaction,
  ReactionClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";

import { DeleteReactionDialog } from "./DeleteReactionDialog.tsx";
import { AgentReaction } from "./ReactionForm.types.ts";
import { ReactionFormDialog } from "./ReactionFormDialog.tsx";
import { removeReactionFromClinicalData } from "./utils.ts";

interface IRenderReactionActionsProps {
  reaction: AgentReaction;
  clinicalRecord: ClinicalRecord;
}

export const RenderReactionActions: React.FunctionComponent<
  IRenderReactionActionsProps
> = ({ reaction, clinicalRecord }) => {
  const { notification } = useStores();

  type SelectedItem = {
    agentId?: string;
    natureOfReaction?: NatureOfReaction | string;
    key?: string;
  };

  const [isReactionDialogVisible, setIsReactionDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [selectedReactionItem, setSelectedReactionItem] = useState<
    AgentReaction | undefined
  >();

  const [deletingItem, setDeletingItem] = useState<SelectedItem | undefined>(
    undefined
  );

  const onReactionDialogDismiss = () => {
    setIsReactionDialogVisible(false);
    setSelectedReactionItem(undefined);
    setIsDeleteDialogVisible(false);
  };

  const onEditReaction = (reactionItem: AgentReaction) => {
    setSelectedReactionItem(reactionItem);
    setIsReactionDialogVisible(true);
  };

  const onDeleteNatureOfReaction = async (
    { agent, key, createLog }: AgentReaction,
    natureOfReaction: NatureOfReaction | string
  ) => {
    const agentId: string | undefined = agent.code;
    if (
      createLog?.createdEncounterId &&
      createLog?.createdEncounterId === clinicalRecord.openEncounter?.id
    ) {
      await onDeleteConfirm({
        deletingItem: { key, agentId, natureOfReaction }
      });
    } else {
      setDeletingItem({ key, agentId, natureOfReaction });
      setIsDeleteDialogVisible(true);
    }
  };

  const onDeleteConfirmDialog = async (
    reasonForDelete: string,
    reasonForDeleteComment?: string
  ) => {
    if (deletingItem) {
      await onDeleteConfirm({
        reasonForDelete,
        reasonForDeleteComment,
        deletingItem
      });
    }
  };

  const updateClinicalData = async (reaction: ReactionClinicalDataDto) => {
    reaction.eTag = clinicalRecord.clinicalData?.reaction?.eTag;

    await clinicalRecord.saveClinicalData({
      reaction
    });
  };

  const onDeleteConfirm = async (options: {
    reasonForDelete?: string;
    reasonForDeleteComment?: string;
    deletingItem: SelectedItem;
  }) => {
    const { reasonForDelete, reasonForDeleteComment, deletingItem } = options;

    const { agentId: reactionAgentId, natureOfReaction, key } = deletingItem;
    const { reactions, agents } = removeReactionFromClinicalData({
      reaction: clinicalRecord.clinicalData?.reaction,
      reactionAgentId,
      natureOfReaction,
      reasonForDelete,
      reasonForDeleteComment
    });

    const promise = updateClinicalData({
      reactions,
      agents
    });

    try {
      if (key && natureOfReaction) {
        setDeletingItem({
          key,
          natureOfReaction
        });
      }

      await promise;
    } catch (error) {
      notification.error(error?.message);
    } finally {
      setDeletingItem(undefined);
    }

    onDeleteCancel();
  };

  const onDeleteCancel = () => {
    setDeletingItem(undefined);
    setIsDeleteDialogVisible(false);
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 4 }} horizontal>
        <IconButton
          iconProps={{ iconName: "Edit" }}
          onClick={() => onEditReaction(reaction)}
          {...dataAttribute(
            DataAttributes.Data,
            `${reaction.key}-${reaction.natureOfReaction}`
          )}
          {...dataAttribute(DataAttributes.Element, "edit-button")}
        />
        <DeleteButton
          onClick={() =>
            onDeleteNatureOfReaction(reaction, reaction.natureOfReaction)
          }
          {...dataAttribute(
            DataAttributes.Data,
            `${reaction.key}-${reaction.natureOfReaction}`
          )}
          {...dataAttribute(DataAttributes.Element, "delete-button")}
        />
      </Stack>
      {isReactionDialogVisible && (
        <ReactionFormDialog
          clinicalRecord={clinicalRecord}
          onDismiss={onReactionDialogDismiss}
          reaction={selectedReactionItem}
        />
      )}
      {isDeleteDialogVisible && (
        <DeleteReactionDialog
          hidden={!isDeleteDialogVisible}
          onConfirm={onDeleteConfirmDialog}
          onCancel={onDeleteCancel}
        />
      )}
    </>
  );
};
