import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

export const physicalActivityFormConfirmed = (
  clinicalRecord: ClinicalRecord
) => {
  const physicalActivityConfirmed =
    clinicalRecord.clinicalData?.physicalActivityConfirmed;

  const { openEncounter } = clinicalRecord;

  return (
    openEncounter &&
    physicalActivityConfirmed?.createLog?.createdEncounterId ===
      openEncounter?.id
  );
};
