// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { useState } from "react";
import { useField, useFormState } from "react-final-form";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { DrawingCanvas } from "../../notes/draw/DrawingCanvas.tsx";
import { InjuryImagesHelper } from "../context/InjuryImagesHelper.tsx";
import { sotapNameOf } from "../SOTAP.utils.ts";

enum SpinalCordInjuryImageType {
  spinalCordFrontImage = "0",
  spinalCordSideImage = "1"
}

export const SpinalCordInjuryImageAssessment: React.FC = () => {
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const [selectedSpineImageIndex, setSelectedSpineImageIndex] =
    useState<number>(0);

  const {
    input: { onChange: onSpinalCordFrontImage }
  } = useField(sotapNameOf("spinalCordFrontImage"));

  const {
    input: { onChange: onSpinalCordSideImage }
  } = useField(sotapNameOf("spinalCordSideImage"));

  const spineProps = InjuryImagesHelper.spinalCordProperties();
  const images = InjuryImagesHelper.mapImagesOnPart(spineProps, initialValues);

  return (
    <DrawingCanvas
      isViewOnly={
        isViewOnlyOrDischarged ||
        clinicalRecord.dischargeInProgressOrCompleted()
      }
      showQuickColourPicker={!isViewOnlyOrDischarged}
      onCanvasSelected={setSelectedSpineImageIndex}
      initialSelectedImage={selectedSpineImageIndex}
      caption="Diagram"
      images={images}
      onChange={(canvas: Canvas, event: any) => {
        if (
          canvas["lowerCanvasEl"].id ===
          SpinalCordInjuryImageType.spinalCordFrontImage
        ) {
          if (event.target.dirty) {
            onSpinalCordFrontImage(canvas?.toSVG());
          } else {
            onSpinalCordFrontImage(undefined);
          }
        }

        if (
          canvas["lowerCanvasEl"].id ===
          SpinalCordInjuryImageType.spinalCordSideImage
        ) {
          if (event.target.dirty) {
            onSpinalCordSideImage(canvas?.toSVG());
          } else {
            onSpinalCordSideImage(undefined);
          }
        }
      }}
    />
  );
};
