import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { AdjustInvoiceFormValues } from "./AdjustInvoiceDialog.tsx";

export class AdjustInvoiceDialogValidator extends Validator<AdjustInvoiceFormValues> {
  constructor() {
    super();
    this.forField("otherReason", required());
  }
}
