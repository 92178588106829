import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";

export interface InvoiceFormDetailsModalProps {
  initialValues?: Partial<InvoiceDetailsModalFormValues>;
  isAdjustMode?: boolean;
  hidden: boolean;
  onDismiss: () => void;
  onSubmit?: (values: Partial<InvoiceDetailsModalFormValues>) => void;
}

export const invoiceDetailsModalFormNameOf =
  nameOfFactory<InvoiceDetailsModalFormValues>();
