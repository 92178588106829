import { useForm, useFormState } from "react-final-form";

import {
  CheckboxGroupOption,
  FontSizes,
  FontWeights,
  ICheckboxProps,
  IRenderFunction,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { SystemReviewLabel } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { SOTAPFormWrapper } from "../../SOTAP/SOTAPFormWrapper.tsx";
import { CollapsibleFlagsCard } from "./CollapsibleFlagCard.tsx";
import { GynaeAdditionalFields } from "./GynaeAdditionalFields.tsx";
import {
  SystemsReviewFormValues,
  systemsReviewNameOf
} from "./SystemsReviewForm.types.ts";
import { UrinaryFlagsCard } from "./UrinaryFlagsCard.tsx";

export const SystemsReviewContent: React.FunctionComponent = () => {
  const theme = useTheme();
  const { clinical } = useStores();
  const form = useForm<SystemsReviewFormValues>();
  const { values } = useFormState<SystemsReviewFormValues>({
    subscription: { values: true }
  });

  const onRenderLabel: IRenderFunction<ICheckboxProps> = (
    props: ICheckboxProps
  ): JSX.Element => {
    const splitLabel = props.label?.split("_");

    const styles = {
      marginLeft: 4,
      color: props.disabled
        ? theme.semanticColors.disabledText
        : theme.semanticColors.bodyText
    };

    if (splitLabel && splitLabel[0].localeCompare(splitLabel[1]) !== 0) {
      return (
        <span style={styles}>
          {splitLabel[0]} <i>{splitLabel[1]}</i>
        </span>
      );
    }

    const text = splitLabel ? splitLabel[0] : "";
    return <span style={styles}>{text}</span>;
  };

  const systemicFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isSystemic)
    .map(({ code, clinicalFlagText }) => ({
      value: code,
      label: clinicalFlagText
    }));

  const NeuroFlagOptions: CheckboxGroupOption<string>[] =
    clinical.ref.clinicalFlags.values
      .filter(x => x.isNeuro)
      .map(({ code, text, clinicalFlagText }) => ({
        value: code,
        label: `${text}_${clinicalFlagText}`,
        checkboxProps: {
          onRenderLabel: (props: ICheckboxProps) => onRenderLabel(props)
        }
      }));

  const entFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isENT)
    .map(({ code, text }) => ({
      value: code,
      label: text
    }));

  const cardioFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isCardio)
    .map(({ code, text, clinicalFlagText }) => ({
      value: code,
      label: `${text}_${clinicalFlagText}`,
      checkboxProps: {
        onRenderLabel: (props: ICheckboxProps) => onRenderLabel(props)
      }
    }));

  const gastroFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isGastro)
    .map(({ code, text }) => ({
      value: code,
      label: text
    }));

  const gynaeFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isGynae)
    .map(({ code, text }) => ({
      value: code,
      label: text
    }));

  const musculoFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isMusculo)
    .map(({ code, text }) => ({
      value: code,
      label: text
    }));

  const haematoFlagOptions: CheckboxGroupOption<string>[] =
    clinical.ref.clinicalFlags.values
      .filter(x => x.isHaemato)
      .map(({ code, text, clinicalFlagText }) => ({
        value: code,
        label: `${text}_${clinicalFlagText}`,
        checkboxProps: {
          onRenderLabel: (props: ICheckboxProps) => onRenderLabel(props)
        }
      }));

  const renderHeadingText = (
    allClearFlag: boolean | undefined,
    flags: string[],
    heading: string
  ) => {
    let content = "";
    if (allClearFlag) {
      content = "(NAD)";
    } else if (flags) {
      content = flags.length > 0 ? `(${flags.length} detected)` : "";
    }

    return (
      <Stack horizontal>
        <Text
          styles={{
            root: {
              fontWeight: FontWeights.semibold,
              fontSize: FontSizes.size20
            }
          }}
        >
          {heading}
        </Text>
        <Text
          styles={{
            root: {
              paddingLeft: 8,
              paddingTop: 5,
              color: theme.palette.neutralPrimary,
              fontWeight: FontWeights.regular
            }
          }}
        >
          {content}
        </Text>
      </Stack>
    );
  };

  return (
    <SOTAPFormWrapper>
      <CollapsibleFlagsCard
        options={systemicFlagOptions}
        heading={renderHeadingText(
          values.systemicFlagsAllClear,
          values.systemicFlags ?? [],
          SystemReviewLabel.SYS
        )}
        id={SystemReviewLabel.SYS}
        icon="SearchNearby"
        allClearName={systemsReviewNameOf("systemicFlagsAllClear")}
        flagName={systemsReviewNameOf("systemicFlags")}
        disabled={!!values.systemicFlagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("systemicFlags"), undefined);
            }
          }
        }}
      />
      <CollapsibleFlagsCard
        options={NeuroFlagOptions}
        heading={renderHeadingText(
          values.neuroFlagsAllClear,
          values.neuroFlags ?? [],
          SystemReviewLabel.NEURO
        )}
        id={SystemReviewLabel.NEURO}
        icon="SplitObject"
        allClearName={systemsReviewNameOf("neuroFlagsAllClear")}
        flagName={systemsReviewNameOf("neuroFlags")}
        disabled={!!values.neuroFlagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("neuroFlags"), undefined);
            }
          }
        }}
      />
      <CollapsibleFlagsCard
        options={entFlagOptions}
        heading={renderHeadingText(
          values.eNTFlagsAllClear,
          values.eNTFlags ?? [],
          SystemReviewLabel.ENT
        )}
        id={SystemReviewLabel.ENT}
        icon="Emoji2"
        allClearName={systemsReviewNameOf("eNTFlagsAllClear")}
        flagName={systemsReviewNameOf("eNTFlags")}
        disabled={!!values.eNTFlagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("eNTFlags"), undefined);
            }
          }
        }}
      />
      <CollapsibleFlagsCard
        options={cardioFlagOptions}
        heading={renderHeadingText(
          values.cardioFlagsAllClear,
          values.cardioFlags ?? [],
          SystemReviewLabel.CARDIO
        )}
        id={SystemReviewLabel.CARDIO}
        icon="Health"
        allClearName={systemsReviewNameOf("cardioFlagsAllClear")}
        flagName={systemsReviewNameOf("cardioFlags")}
        disabled={!!values.cardioFlagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("cardioFlags"), undefined);
            }
          }
        }}
      />
      <CollapsibleFlagsCard
        options={gastroFlagOptions}
        heading={renderHeadingText(
          values.gastroFlagsAllClear,
          values.gastroFlags ?? [],
          SystemReviewLabel.GASTRO
        )}
        id={SystemReviewLabel.GASTRO}
        icon="EatDrink"
        allClearName={systemsReviewNameOf("gastroFlagsAllClear")}
        flagName={systemsReviewNameOf("gastroFlags")}
        disabled={!!values.gastroFlagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("gastroFlags"), undefined);
            }
          }
        }}
      />
      <UrinaryFlagsCard
        heading={renderHeadingText(
          values.urinaryFlagsAllClear,
          [...(values.urinaryFlags ?? []), ...(values.maleUrinaryFlags ?? [])],
          SystemReviewLabel.GENURN
        )}
      />
      <CollapsibleFlagsCard
        options={gynaeFlagOptions}
        heading={renderHeadingText(
          values.gynae?.flagsAllClear,
          values.gynae?.flags ?? [],
          SystemReviewLabel.GYN
        )}
        id={SystemReviewLabel.GYN}
        icon="plugDisconnected"
        allClearName="gynae.flagsAllClear"
        flagName="gynae.flags"
        disabled={!!values.gynae?.flagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("gynae"), {
                ...form.getState().values.gynae,
                flags: undefined
              });
            }
          }
        }}
      >
        <GynaeAdditionalFields disabled={!!values.gynae?.flagsAllClear} />
      </CollapsibleFlagsCard>

      <CollapsibleFlagsCard
        options={musculoFlagOptions}
        heading={renderHeadingText(
          values.musculoFlagsAllClear,
          values.musculoFlags ?? [],
          SystemReviewLabel.MUS
        )}
        id={SystemReviewLabel.MUS}
        icon="BpMSP24"
        allClearName={systemsReviewNameOf("musculoFlagsAllClear")}
        flagName={systemsReviewNameOf("musculoFlags")}
        disabled={!!values.musculoFlagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("musculoFlags"), undefined);
            }
          }
        }}
      />
      <CollapsibleFlagsCard
        options={haematoFlagOptions}
        heading={renderHeadingText(
          values.haematoFlagsAllClear,
          values.haematoFlags ?? [],
          SystemReviewLabel.HAEM
        )}
        id={SystemReviewLabel.HAEM}
        icon="Drop"
        allClearName={systemsReviewNameOf("haematoFlagsAllClear")}
        flagName={systemsReviewNameOf("haematoFlags")}
        disabled={!!values.haematoFlagsAllClear}
        onChange={(value: boolean) => {
          if (value) {
            if (form) {
              form.change(systemsReviewNameOf("haematoFlags"), undefined);
            }
          }
        }}
      />
    </SOTAPFormWrapper>
  );
};
