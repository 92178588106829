import { useForm, useFormState } from "react-final-form";

import { CollapsibleCard, Stack } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";

export const PhysioMedicalHistoryCard: React.FC = () => {
  const { clinical } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const MED_HISTORY_LABEL = "Diagnosis & procedures";
  const getGeneralHealthConditions = () =>
    clinical.ref.generalHealthConditions.keyNameValues;

  const form = useForm<SOTAPFormValues>();
  const { values } = useFormState<SOTAPFormValues>({
    subscription: { values: true }
  });

  const redFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isRed && x.isSOTAP)
    .map(({ code, text }) => ({ value: code, label: text }));

  const phychologicalFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isYellow && x.isSOTAP)
    .map(({ code, text }) => ({ value: code, label: text }));

  const isDischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();
  return (
    <div id={SotapMskId.PhysioMedicalHistory}>
      <CollapsibleCard
        heading={SotapMskLabel.PhysioMedicalHistory}
        headingLevel="section-heading"
        iconName="DietPlanNotebook"
        openOnRender
      >
        <Stack tokens={{ childrenGap: 8 }}>
          <StaticPickerField
            name={sotapNameOf("generalHealth")}
            label="General health"
            fetchDataSource={getGeneralHealthConditions}
            fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            inputProps={{
              placeholder: "e.g. Excellent"
            }}
          />
          <TextInputField
            label={MED_HISTORY_LABEL}
            name={sotapNameOf("physioMedicalHistory")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: { minHeight: 60 }
            }}
          />
          <TextInputField
            label="Medications"
            name={sotapNameOf("medications")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: { minHeight: 60 }
            }}
          />
          <TextInputField
            label="Reactions"
            name={sotapNameOf("reactions")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: { minHeight: 60 }
            }}
          />
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 16 }}
              styles={{ root: { height: 487 } }}
            >
              <Fieldset
                frame
                styles={{
                  root: {
                    flexGrow: 1,
                    width: "50%",
                    paddingLeft: 10,
                    paddingRight: 10
                  }
                }}
              >
                <CheckboxGroupField
                  name={sotapNameOf("redFlags")}
                  options={redFlagOptions}
                  label="Red flags"
                  disabled={
                    !!values.redFlagsAllClear ||
                    isDischargeInProgressOrCompleted
                  }
                  actionButton={{
                    onRender: () => (
                      <ToggleField
                        name={sotapNameOf("redFlagsAllClear")}
                        label="All clear"
                        inlineLabel
                        styles={{
                          root: {
                            margin: 0
                          }
                        }}
                      />
                    )
                  }}
                />
                <FieldSpy
                  name={sotapNameOf("redFlagsAllClear")}
                  onChange={(value: boolean) => {
                    if (value) {
                      if (form) {
                        form.change(sotapNameOf("redFlags"), undefined);
                      }
                    }
                  }}
                />
              </Fieldset>
              <Fieldset
                frame
                styles={{
                  root: {
                    flexGrow: 1,
                    width: "50%",
                    paddingLeft: 10,
                    paddingRight: 10
                  }
                }}
              >
                <CheckboxGroupField
                  name={sotapNameOf("specialQuestions")}
                  options={clinical.ref.specialQuestions.keyLabelValues}
                  label="Special questions"
                  disabled={
                    !!values.specialQuestionsAllClear ||
                    isDischargeInProgressOrCompleted
                  }
                  actionButton={{
                    onRender: () => (
                      <ToggleField
                        name={sotapNameOf("specialQuestionsAllClear")}
                        label="All clear"
                        inlineLabel
                        styles={{
                          root: {
                            margin: 0
                          }
                        }}
                      />
                    )
                  }}
                />
                <FieldSpy
                  name={sotapNameOf("specialQuestionsAllClear")}
                  onChange={(value: boolean) => {
                    if (value) {
                      if (form) {
                        form.change(sotapNameOf("specialQuestions"), undefined);
                      }
                    }
                  }}
                />
              </Fieldset>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <Fieldset
                frame
                styles={{
                  root: {
                    flexGrow: 1,
                    width: "50%",
                    paddingLeft: 10,
                    paddingRight: 10
                  }
                }}
              >
                <CheckboxGroupField
                  name={sotapNameOf("psychosocialFlags")}
                  options={phychologicalFlagOptions}
                  label="Psychosocial flags"
                  disabled={
                    !!values.psychosocialFlagsAllClear ||
                    isDischargeInProgressOrCompleted
                  }
                  fieldItemStyles={{ root: { paddingRight: 10 } }}
                  actionButton={{
                    onRender: () => (
                      <ToggleField
                        name={sotapNameOf("psychosocialFlagsAllClear")}
                        label="All clear"
                        inlineLabel
                        styles={{
                          root: {
                            margin: 0
                          }
                        }}
                      />
                    )
                  }}
                />
                <FieldSpy
                  name={sotapNameOf("psychosocialFlagsAllClear")}
                  onChange={(value: boolean) => {
                    if (value) {
                      if (form) {
                        form.change(
                          sotapNameOf("psychosocialFlags"),
                          undefined
                        );
                      }
                    }
                  }}
                />
              </Fieldset>
              <Fieldset
                frame
                styles={{
                  root: {
                    flexGrow: 1,
                    width: "50%",
                    paddingLeft: 10,
                    paddingRight: 10
                  }
                }}
              >
                <CheckboxGroupField
                  name={sotapNameOf("vBIs")}
                  options={
                    clinical.ref.vertebrobasilarInsufficiencies.keyLabelValues
                  }
                  label="VBI (Spine only)"
                  disabled={
                    !!values.vBIsAllClear || isDischargeInProgressOrCompleted
                  }
                  actionButton={{
                    onRender: () => (
                      <ToggleField
                        name={sotapNameOf("vBIsAllClear")}
                        label="All clear"
                        inlineLabel
                        styles={{
                          root: {
                            margin: 0
                          }
                        }}
                      />
                    )
                  }}
                />
                <FieldSpy
                  name={sotapNameOf("vBIsAllClear")}
                  onChange={(value: boolean) => {
                    if (value) {
                      if (form) {
                        form.change(sotapNameOf("vBIs"), undefined);
                        form.change(sotapNameOf("neuroSigns"), undefined);
                      }
                    }
                  }}
                />
                <TextInputField
                  label="Neurological signs"
                  name={sotapNameOf("neuroSigns")}
                  multiline={true}
                  rows={3}
                  autoAdjustHeight
                  resizable={false}
                  styles={{
                    fieldGroup: { minHeight: 60 }
                  }}
                  disabled={!!values.vBIsAllClear}
                />
              </Fieldset>
            </Stack>
          </Stack>
          <TextInputField
            label="Precautions"
            name={sotapNameOf("precautions")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: { minHeight: 60 }
            }}
          />
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
