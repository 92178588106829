import { Dropdown } from "@bps/fluent-ui";
import { CessationAdvice } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { CessationFieldPlaceholder } from "./IntakeCessationField.tsx";
import { getDropdownStyles } from "./IntakeForm.styles.tsx";

export interface IntakeCessationViewProps {
  types?: string[];
}

const options = [
  {
    key: CessationAdvice.BriefSmokingCessAdvGiven,
    text: "Brief cessation advice given",
    disabled: true
  },
  {
    key: CessationAdvice.SmokingCessBehaviourSupport,
    text: "Cessation behaviour support provided",
    disabled: true
  },
  {
    key: CessationAdvice.PrescribedSmokingCessMedication,
    text: "Prescribed cessation medication",
    disabled: true
  },
  {
    key: CessationAdvice.RefToSmokingCessSupport,
    text: "Referred to cessation support",
    disabled: true
  },
  {
    key: CessationAdvice.RefusedSmokingCessSupport,
    text: "Refused cessation support",
    disabled: true
  },
  {
    key: CessationAdvice.RiskManagement,
    text: "Risk management",
    disabled: true
  }
];

export const IntakeCessationView: React.FunctionComponent<
  IntakeCessationViewProps
> = ({ types }) => {
  const dropdownOptions = options.filter(x => types?.includes(x.key));
  const placeholder =
    dropdownOptions.length === 0
      ? CessationFieldPlaceholder.NotGiven
      : CessationFieldPlaceholder.Given;

  return (
    <Dropdown
      name="intake-cessation"
      options={dropdownOptions}
      placeholder={placeholder}
      styles={getDropdownStyles(false)}
    />
  );
};
