import { DateTime } from "@bps/utils";
import {
  MeasurementType,
  QuestionnaireDto,
  QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";

import { ClinicalDataToMeasurementConverter } from "../utils/ClinicalDataToMeasurementConverter.ts";
import {
  ClinicalToolAnswer,
  findClinicalToolAnswerText
} from "../utils/ClinicalToolAnswerFinder.ts";

export interface ClincialToolViewData {
  measurements?: Measurement[];
  questionnaire: QuestionnaireDto;
  answers: ClinicalToolAnswer[];
  summary: string;
  isConfidential: boolean;
  lastUpdatedDate?: DateTime;
  lastUpdatedUserId?: string;
}

export class ClinicalToolViewDataFetcher {
  getData = async (
    clinical: ClinicalStore,
    encounterId: string,
    measurementType: MeasurementType
  ): Promise<ClincialToolViewData> => {
    const converter = new ClinicalDataToMeasurementConverter();

    const savedData = (
      await clinical.getEncounterClinicalData({ encounterId })
    )[
      converter.measurementToClinical(measurementType).toLocaleLowerCase()
    ] as QuestionnaireResponseDto;

    const questionnaire = await clinical.getQuestionnaires(
      converter.measurementToQuestionnaire(measurementType)
    );

    const answers = savedData
      ? savedData.items.map(i => ({
          questionId: `q${i.questionnaireItemId}`,
          answerValue: i.answer,
          answerText: findClinicalToolAnswerText(
            i.questionnaireItemId,
            i.answer!,
            questionnaire
          )
        }))
      : [];

    const result = await clinical.getMeasurements({
      patientId: clinical.activeRecordPatientId,
      encounterId,
      types: [measurementType]
    });

    const measurements = result.results;

    if (measurements.length === 0) {
      throw new Error(`Unable to retrieve ${measurementType} measurement data`);
    }

    const summary = measurements[0].summary ?? measurements[0].value.toString();
    const isConfidential = !!savedData.secGroupId;
    const lastUpdatedDate = getClinicalDataLastUpdatedDate(savedData);
    const lastUpdatedUserId = getClinicalDataLastUpdatedUserId(savedData);

    return {
      measurements,
      answers,
      summary,
      questionnaire,
      isConfidential,
      lastUpdatedDate,
      lastUpdatedUserId
    };
  };
}
