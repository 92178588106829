import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";

export interface AccountBadgeProps {
  badgeSize?: TextBadgeSize;
  balance: AccountBalance;
  hideAccountText?: boolean;
}
export const AccountBadge: React.FC<AccountBadgeProps> = ({
  badgeSize,
  hideAccountText,
  balance
}) => {
  if (!balance) {
    return null;
  }

  if (balance.totalOwing > 0) {
    return (
      <TextBadge badgeSize={badgeSize} badgeColor={TextBadgeColor.red}>
        Payment due
      </TextBadge>
    );
  }

  if (balance.unallocatedCredit > 0) {
    return (
      <TextBadge
        badgeSize={badgeSize}
        badgeColor={TextBadgeColor.green}
        hasBorder
      >
        Account in credit
      </TextBadge>
    );
  }

  return (
    <TextBadge badgeSize={badgeSize} badgeColor={TextBadgeColor.green}>
      {hideAccountText ? "Up to date" : "Account up to date"}
    </TextBadge>
  );
};
