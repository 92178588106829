import { useState } from "react";

import {
  Heading,
  LabeledText,
  LabeledTextGroup,
  Link,
  Stack
} from "@bps/fluent-ui";
import {
  ClaimAdjustmentStatus,
  responseStatusesArray
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

export const ResponseDetails = () => {
  const {
    claimAdjustment,
    claimAdjustment: { status },
    claim
  } = useClaimAdjustmentContext();

  const { responseDate, purchaseOrderNumber } = claimAdjustment!;
  const [showDialog, setShowDialog] = useState(false);
  const { correspondence } = useStores();

  const handleLinkClicked = async () => {
    setShowDialog(true);
  };

  const requestFormDocument = claimAdjustment?.documents?.find(
    d =>
      d.fileName ===
      `ACC32-${claim.claimNumber}-${claimAdjustment.claimAdjustmentNumber}`
  );

  const getDocument = (): Promise<Document> => {
    if (requestFormDocument?.documentId) {
      const args = {
        patientId: claim.patientId!,
        documentId: requestFormDocument.documentId,
        excludeContentDisposition: false
      };
      return correspondence.getDocumentUrl(args).then(result => {
        const doc: Document = {
          entityId: claim.patientId!,
          entityType: DocumentEntityType.Patient,
          name: requestFormDocument.fileName!,
          extension: requestFormDocument.extension ?? "",
          documentId: requestFormDocument.documentId!,
          previewUri: result.url,
          downloadUri: result.url
        };
        return doc;
      });
    }

    return Promise.reject("Invalid document id or file not found.");
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Heading>
        {status?.code && responseStatusesArray.includes(status?.code)
          ? "Response"
          : "Details"}
      </Heading>
      <LabeledTextGroup
        styles={{ root: { marginRight: "auto" } }}
        horizontalColumnCount={4}
        gap={24}
      >
        {purchaseOrderNumber && (
          <LabeledText noSemicolon label="Purchase order">
            {purchaseOrderNumber}
          </LabeledText>
        )}
        {responseDate && (
          <LabeledText noSemicolon label={status?.text}>
            {claimAdjustment?.responseDate?.toDayDefaultFormat()}
          </LabeledText>
        )}
        {status?.code && !responseStatusesArray.includes(status?.code) && (
          <LabeledText noSemicolon label="Created">
            {claimAdjustment?.createdDate?.toDayDefaultFormat()}
          </LabeledText>
        )}
        {status?.code === ClaimAdjustmentStatus.Pending && (
          <LabeledText noSemicolon label="Lodged">
            {claimAdjustment?.updatedDate?.toDayDefaultFormat()}
          </LabeledText>
        )}
        {(status?.code === ClaimAdjustmentStatus.Pending ||
          (status?.code && responseStatusesArray.includes(status?.code))) && (
          <LabeledText
            noSemicolon
            label=" "
            styles={{ text: { paddingTop: 3 } }}
          >
            <Link onClick={handleLinkClicked}>View ACC32</Link>
          </LabeledText>
        )}
      </LabeledTextGroup>
      {showDialog && (
        <DocumentViewerDialog
          getDocument={getDocument}
          closeDocumentViewer={() => {
            setShowDialog(false);
          }}
        />
      )}
    </Stack>
  );
};
