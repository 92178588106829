import { FunctionComponent, memo } from "react";

import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DeleteDialog,
  DeleteDialogProps
} from "@ui-components/DeleteDialog.tsx";

export interface ClinicalTaskDeleteDialogProps {
  hidden: boolean;
  selectedCount: number;
  onConfirm: DeleteDialogProps["onConfirm"];
  onCancel?: () => void;
}

const ClinicalTaskDeleteDialogBase: FunctionComponent<ClinicalTaskDeleteDialogProps> =
  memo(props => {
    const { clinical } = useStores();
    const title =
      props.selectedCount > 1
        ? `Delete (${props.selectedCount}) clinical tasks`
        : "Delete clinical task";

    const subText =
      props.selectedCount > 1
        ? "Are you sure you want to remove these clinical tasks?"
        : "Are you sure you want to remove this clinical task?";

    const okButtonText =
      props.selectedCount > 1 ? `Delete (${props.selectedCount})` : "Delete";
    return (
      <DeleteDialog
        formName="remove-clinical-task"
        {...props}
        title={title}
        subText={subText}
        options={clinical.ref.clinicalTaskDeleteReasons.keyTextValues}
        okButtonText={okButtonText}
      />
    );
  });

export const ClinicalTaskDeleteDialog = withFetch(
  x => [x.clinical.ref.clinicalTaskDeleteReasons.load()],
  ClinicalTaskDeleteDialogBase
);
