import React from "react";
import { useField, useForm } from "react-final-form";

import {
  ActionButton,
  HideStack,
  NoDataTile,
  Separator,
  Stack,
  Text
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  FamilyMember,
  SignificantFamilyHistoryDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AdditionalFamilyHistoryField } from "./AdditionalFamilyHistoryField.tsx";
import { AliveStatus } from "./AliveStatus.tsx";
import {
  FamilyHistoryFormValues,
  familyHistoryNameOf,
  placeHolderParentHistoryValues
} from "./FamilyHistoryForm.types.ts";
import { SignificantConditionCheckBoxGroupField } from "./SignificantConditionCheckBoxGroupField.tsx";

export const FamilyHistoryFields: React.FC = () => {
  const form = useForm<FamilyHistoryFormValues>();
  const { fields: parentHistories } =
    useFieldArray<SignificantFamilyHistoryDataItemDto>(
      familyHistoryNameOf("parentHistories")
    );

  const { fields: additionalFamilyHistories } =
    useFieldArray<SignificantFamilyHistoryDataItemDto>(
      familyHistoryNameOf("additionalFamilyHistories")
    );

  const {
    input: { value: unknownFamilyHistory }
  } = useField(familyHistoryNameOf("unknownFamilyHistory"), {
    subscription: { value: true }
  });

  const {
    input: { value: noSignificantFamilyHistory }
  } = useField(familyHistoryNameOf("noSignificantFamilyHistory"), {
    subscription: { value: true }
  });

  const showSignificantHistory =
    !unknownFamilyHistory && !noSignificantFamilyHistory;

  const additionalFamilyHistoriesValues = additionalFamilyHistories.value;
  const showAdditionalFamilyHistory =
    additionalFamilyHistoriesValues &&
    additionalFamilyHistoriesValues.length > 0;

  const addAdditionalFamilyHistory = () => {
    additionalFamilyHistories.push({
      id: newGuid(),
      relationship: "",
      conditions: [{ id: newGuid(), condition: { code: "", originalText: "" } }]
    });
  };

  const { isViewOnly } = usePatientRecordScreenContext();

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Text variant="large">General</Text>

      <Stack horizontal tokens={{ childrenGap: 24 }}>
        <CheckboxField
          name={familyHistoryNameOf("unknownFamilyHistory")}
          label="Unknown (e.g. adopted)"
        />

        <CheckboxField
          name={familyHistoryNameOf("noSignificantFamilyHistory")}
          label="No significant family history"
        />
        <FieldSpy
          name={familyHistoryNameOf("unknownFamilyHistory")}
          onChange={value => {
            if (value) {
              form.change(
                familyHistoryNameOf("noSignificantFamilyHistory"),
                false
              );
            }
          }}
        />

        <FieldSpy
          name={familyHistoryNameOf("noSignificantFamilyHistory")}
          onChange={value => {
            if (value) {
              form.change(familyHistoryNameOf("unknownFamilyHistory"), false);
            }
          }}
        />
      </Stack>

      <HideStack when={showSignificantHistory}>
        <TextInputField
          name={familyHistoryNameOf("generalFamilyHistoryComment")}
          label="Additional comments"
          multiline={true}
          rows={3}
          autoAdjustHeight
          resizable={false}
          styles={{ fieldGroup: { minHeight: 60 } }}
        />
      </HideStack>

      <HideStack when={!showSignificantHistory} tokens={{ childrenGap: 14 }}>
        <AliveStatus familyMember={FamilyMember.MOTHER} />
        <AliveStatus familyMember={FamilyMember.FATHER} />
        <Separator styles={{ root: { height: 0, padding: 0 } }} />
        <Text variant="large">Significant family history</Text>
        {placeHolderParentHistoryValues.map((value, index) => {
          return (
            <Stack key={value.id} tokens={{ childrenGap: 8 }}>
              <Text variant="large">
                {capitalizeSentence(value.relationship.toLocaleLowerCase())}
              </Text>
              <SignificantConditionCheckBoxGroupField
                familyHistoryIndex={index}
                significantValues={parentHistories.value}
              />
            </Stack>
          );
        })}

        <Text variant="large">Additional significant family history</Text>
        <HideStack
          when={!showAdditionalFamilyHistory}
          tokens={{ childrenGap: 10 }}
        >
          {additionalFamilyHistoriesValues &&
            additionalFamilyHistoriesValues.map((value, index) => {
              return (
                <Stack horizontal key={value.id} tokens={{ childrenGap: 8 }}>
                  <AdditionalFamilyHistoryField
                    additionalFamilyHistoryIndex={index}
                    additionalFamilyHistoriesValues={
                      additionalFamilyHistoriesValues
                    }
                  />
                  <DeleteButton
                    styles={{
                      root: {
                        width: 20
                      }
                    }}
                    onClick={() => {
                      additionalFamilyHistories.remove(index);
                    }}
                  />
                </Stack>
              );
            })}
          <ActionButton
            iconProps={{ iconName: "Add" }}
            title="Add another"
            onClick={addAdditionalFamilyHistory}
            styles={{ root: { width: 130 } }}
          >
            Add another
          </ActionButton>
        </HideStack>

        <HideStack
          when={showAdditionalFamilyHistory}
          tokens={{ childrenGap: 10 }}
        >
          {!isViewOnly && (
            <NoDataTile
              textProps={{ text: "No additional significant family history" }}
              linkProps={{
                text: "Add additional significant family history",
                onClick: addAdditionalFamilyHistory
              }}
              showBoxShadow={true}
              styles={{
                root: {
                  maxWidth: 500
                }
              }}
            />
          )}
        </HideStack>
        <TextInputField
          name={familyHistoryNameOf("otherComments")}
          label="Other comments"
          multiline={true}
          rows={3}
          autoAdjustHeight
          resizable={false}
          styles={{ fieldGroup: { minHeight: 60 } }}
        />
      </HideStack>
    </Stack>
  );
};
