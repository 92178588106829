import { isEqual } from "lodash";
import { FunctionComponent, useState } from "react";

import { ITag, Stack } from "@bps/fluent-ui";
import {
  CodedText,
  RFV_CD_TYPE_CODE_OTHER,
  RFV_CD_TYPE_CODE_OTHER_TEXT
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  TagPickerField,
  TagPickerFieldProps
} from "@ui-components/form/TagPickerField.tsx";

export interface TerminologyTagPickerFieldProps
  extends Omit<TagPickerFieldProps, "onSearchItems" | "onFetchItems"> {
  take?: number;
  isAlliedHealth?: boolean;
  isGeneralPractitioner?: boolean;
  isReasonForVisit?: boolean;
  isDiagnosis?: boolean;
  isProcedure?: boolean;
  isCauseOfDeath?: boolean;
  codedFields?: CodedText[];
  onOtherSelected?: () => void;
  onOtherDeleted?: () => void;
  clearOnBlur?: boolean;
  hideLabel?: boolean;
}

export const TerminologyTagPickerField: FunctionComponent<
  TerminologyTagPickerFieldProps
> = props => {
  const {
    name,
    label,
    onOtherSelected,
    onOtherDeleted,
    isReasonForVisit,
    isDiagnosis,
    isProcedure,
    isCauseOfDeath,
    codedFields,
    componentRef,
    onChange,
    clearOnBlur,
    hideLabel
  } = props;

  const { clinical } = useStores();

  const [otherId, setOtherId] = useState<string | number | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onItemSelected = (suggestionItem?: ITag | undefined) => {
    if (suggestionItem?.name === "Other" && onOtherSelected) {
      setOtherId(suggestionItem.key);
      onOtherSelected();
    }

    return suggestionItem ?? null;
  };

  const _onChange = (ids: string[], items: ITag[]) => {
    if (onChange) {
      onChange(ids, items);
    }

    if (onOtherDeleted && otherId && !ids?.some(key => key === otherId)) {
      setOtherId(undefined);
      onOtherDeleted();
    }
  };

  return (
    <Stack
      data-list-finished-loading={isLoading}
      horizontal
      styles={{ root: { width: "100%" } }}
      tokens={{ childrenGap: 8 }}
    >
      <TagPickerField
        {...props}
        componentRef={ref => {
          if (componentRef) {
            componentRef(ref);
          }
        }}
        isEqual={isEqual}
        minimumCharactersToSearch={2}
        label={!hideLabel ? label || "Terminology" : undefined}
        name={name}
        onFetchItems={async (keys: string[]) => {
          if (!keys?.length) {
            return [];
          }

          const terminologies = keys?.map(
            key => codedFields?.find(i => i.code === key)
          );

          return terminologies?.map(
            terminology =>
              ({
                key: terminology?.code,
                name:
                  terminology?.code === RFV_CD_TYPE_CODE_OTHER
                    ? RFV_CD_TYPE_CODE_OTHER_TEXT
                    : terminology?.text
              }) as ITag
          );
        }}
        onSearchItems={async (filter, previousResult) => {
          setIsLoading(false);
          const result = await clinical.getTerminologiesSearch({
            search: filter,
            take: 50,
            skip: previousResult?.take ?? 0,
            total: true,
            isReasonForVisit,
            isDiagnosis,
            isProcedure,
            isCauseOfDeath
          });
          setIsLoading(true);
          return {
            ...result,
            results: result.results.map(x => ({
              key: `${x.code}.${x.text}`,
              name: x.text
            }))
          };
        }}
        onChange={_onChange}
        onItemSelected={onItemSelected}
        clearInputOnBlur={clearOnBlur ?? false}
        formItemFieldStyles={{
          root: { flex: 1 },
          item: { width: "100%", backgroundColor: "white" }
        }}
        styles={{ itemsWrapper: { height: "auto" } }}
      />
    </Stack>
  );
};
