import { DateTime } from "@bps/utils";
import { OrgUnitOpeningHoursOverrideDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export interface OrgUnitOpeningHoursOverridesValues {
  orgUnitOpeningHoursOverrides: OrgUnitOpeningHoursOverridesFormValues[];
}

export enum Operation {
  Open = "Open",
  Closed = "Closed"
}

export interface OrgUnitOpeningHoursOverridesFormValues
  extends Omit<
    OrgUnitOpeningHoursOverrideDto,
    "startDate" | "endDate" | "orgUnitId" | "isClosed"
  > {
  startDate?: Date;
  endDate?: Date;
  operation?: Operation;
}

export function toOrgUnitOpeningHoursOverridesFormValues(
  overrides: OrgUnitOpeningHoursOverrideDto[]
) {
  const before: OrgUnitOpeningHoursOverridesFormValues[] = [];
  const after: OrgUnitOpeningHoursOverridesFormValues[] = [];

  if (overrides) {
    overrides.map(o => {
      const exception: OrgUnitOpeningHoursOverridesFormValues = {
        id: o.id,
        eTag: o.eTag,
        startDate: DateTime.jsDateFromISO(o.startDate),
        endDate: DateTime.jsDateFromISO(o.endDate),
        operation: o.isClosed ? Operation.Closed : Operation.Open,
        startTime: DateTime.fromISO(o.startTime)?.toTimeInputFormat(),
        endTime: DateTime.fromISO(o.endTime)?.toTimeInputFormat(),
        reason: o.reason
      };

      const ed = exception.endDate
        ? DateTime.fromJSDate(exception.endDate)
        : null;

      const now = DateTime.today();
      if (!ed || ed < now) {
        before.push(exception);
      } else {
        after.push(exception);
      }
      return o;
    });
    return { before, after };
  }

  return { before, after };
}
