import { maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DocumentSettingsFormValues } from "../DocumentSettingsForm.types.tsx";

export class DocumentSettingsValidator extends Validator<DocumentSettingsFormValues> {
  constructor() {
    super();

    this.forField("footer", maxLength(300));
    this.forField("statementFooter", maxLength(300));
  }
}
