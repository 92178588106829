import { DateTime } from "@bps/utils";
import {
  ClinicalDocumentLite,
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  DocumentDto,
  DocumentEnum,
  DocumentMetadataItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  InboxDocumentDto,
  InboxStoreInDestinationType
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export class RecentIncomingDocContentHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root: RootStore
  ) {}

  private get correspondence() {
    return this.root.correspondence;
  }

  private get inbox() {
    return this.root.inbox;
  }

  fetchCorrespondenceDetails = async (itemId: string) => {
    const [corr, documentUrl] = await Promise.all([
      this.correspondence.getCorrespondenceByDocumentId(
        this.clinicalRecord.id,
        itemId
      ),
      this.correspondence.getDocumentUrl({
        patientId: this.clinicalRecord.id,
        documentId: itemId
      })
    ]);
    return { corr, documentUrl };
  };

  fetchClinicalDocument = async (documentId: string) => {
    return await this.correspondence.getCorrespondenceByDocumentId(
      this.clinicalRecord.id,
      documentId
    );
  };

  fetchInboxDocument = async (item: ClinicalDocumentLite) => {
    return await this.inbox.getInboxDocument(
      {
        id: item.id,
        documentDetailId: item.documentDetailId
      },
      { ignoreCache: true }
    );
  };

  // Forces the document date to never exceed
  clampDocumentDate = (
    receivedDate: Date | undefined,
    documentDate: Date | undefined
  ) => {
    if (documentDate && receivedDate && documentDate > receivedDate) {
      return DateTime.jsDateToISODate(receivedDate);
    }

    return (
      DateTime.jsDateToISODate(documentDate) ??
      DateTime.jsDateToISODate(receivedDate) ??
      DateTime.now().toISODate()
    );
  };

  createSelectedInBoxDoc = (
    inboxDocument: InboxDocument
  ): InboxDocumentDto => ({
    id: inboxDocument.id,
    documentDetailId: inboxDocument.documentDetailId,
    name: inboxDocument.name,
    assignedToUserId: inboxDocument.assignedToUserId,
    patientId: inboxDocument.patientId,
    extension: inboxDocument.docExtension ?? "",
    fromContactId: inboxDocument.fromContactId,
    documentDate: DateTime.jsDateToISODate(inboxDocument.documentDate),
    receivedDate: DateTime.jsDateToISODate(inboxDocument.receivedDate),
    correspondenceType: inboxDocument.correspondenceType,
    blobFileUri: inboxDocument.fileUri,
    blobSasUri: inboxDocument.previewUri,
    changeLog: inboxDocument.changeLog,
    eTag: inboxDocument.eTag,
    patientFirstName: inboxDocument.patientFirstName,
    patientLastName: inboxDocument.patientLastName,
    extraInfo: inboxDocument.extraInfo,
    showOnTimeline: inboxDocument.showOnTimeline
  });

  createNewInBoxClinDoc = (inboxDocument: InboxDocument): DocumentDto => ({
    id: inboxDocument.documentDetailId,
    patientId: inboxDocument.patientId,
    to: inboxDocument.assignedToUserId,
    from: inboxDocument.fromContactId,
    type: inboxDocument.correspondenceType as CorrespondenceType,
    direction: CorrespondenceDirection.In,
    status: CorrespondenceStatus.Uploaded,
    store: InboxStoreInDestinationType.Investigations,
    metadata: this.createMetaData(inboxDocument, this.clinicalRecord),
    eTag: inboxDocument.eTag,
    secGroupId: inboxDocument.secGroupId
  });

  createMetaData = (
    inboxDocument: InboxDocument,
    clinicalRecord: ClinicalRecord
  ): DocumentMetadataItem[] => [
    {
      key: DocumentEnum.Date,
      value: this.clampDocumentDate(
        inboxDocument.receivedDate,
        inboxDocument.documentDate
      )
    },
    {
      key: DocumentEnum.Extension,
      value: inboxDocument.docExtension
    },
    {
      key: DocumentEnum.Name,
      value: inboxDocument.name
    },
    {
      key: DocumentEnum.EncounterId,
      value: clinicalRecord.openEncounter?.id
    },
    {
      key: DocumentEnum.SecGroupId,
      value: inboxDocument.secGroupId
    },
    {
      key: DocumentEnum.ShowOnTimeline,
      value: inboxDocument.showOnTimeline
    },
    {
      key: DocumentEnum.ExtraInfo,
      value: inboxDocument.extraInfo
    },
    {
      key: DocumentEnum.CheckedBy,
      value: inboxDocument.checkedBy ?? this.root.core.userId
    },
    {
      key: DocumentEnum.DateChecked,
      value: inboxDocument.checkedDate
    }
  ];

  createNewClinDocument = (inboxDocument: InboxDocument): ClinicalDocument => {
    const newInBoxClinDoc: DocumentDto =
      this.createNewInBoxClinDoc(inboxDocument);

    return new ClinicalDocument(newInBoxClinDoc, this.correspondence);
  };
}
