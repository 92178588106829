import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Card } from "@bps/fluent-ui";

import { RecentPatientsList } from "./components/RecentPatientsList.tsx";

const RecentPatientsScreen: FC = observer(() => {
  return (
    <Card
      headingLevel="section-heading"
      heading="My recent patients"
      iconName="TemporaryUser"
      styles={{
        header: { padding: "8px 0 12px 8px" },
        subComponentStyles: {
          tile: {
            content: {
              padding: 0,
              position: "relative",
              display: "flex"
            },
            header: { border: "none" },
            root: {
              height: "100%",
              padding: 16,
              maxWidth: 1500,
              margin: "0 auto"
            }
          }
        }
      }}
    >
      <RecentPatientsList />
    </Card>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default RecentPatientsScreen;
