import { lazy } from "react";

export const ClinicalSidePanel = lazy(() => import("./ClinicalSidePanel.tsx"));

export const EncounterForm = lazy(
  () => import("./clinical-form/EncounterForm.tsx")
);

export const PatientSummary = lazy(
  () => import("./patient-summary/PatientSummary.tsx")
);

export const ClinicalClaimEditScreen = lazy(
  () => import("./claims/ClinicalClaimEditScreen.tsx")
);
