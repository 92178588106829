import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

import { useStores } from "@stores/hooks/useStores.ts";

import { TemplateManagementContext } from "./context/TemplateManagementContext.tsx";
import { TemplateManagementHelper } from "./context/TemplateManagementHelper.ts";
import { TemplateManagementList } from "./TemplateManagementList.tsx";

const TemplateListModalWrapper: FunctionComponent = observer(() => {
  const { templateManagement } = useStores();

  const root = useStores();

  const helper = useRef(new TemplateManagementHelper(root));

  if (!templateManagement.ui.showTemplateListModal) return null;

  return (
    <TemplateManagementContext.Provider value={helper.current}>
      <TemplateManagementList />
    </TemplateManagementContext.Provider>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default TemplateListModalWrapper;
