import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { Heading, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { SystemNotification as SystemNotificationModel } from "@stores/notifications/models/SystemNotification.ts";

import { NotificationContext } from "../context/NotificationContext.ts";
import { NotificationActions } from "./NotificationActions.tsx";
import { NotificationsPivotKeys } from "./SystemNotifications.types.ts";
import { SystemNotificationStatus } from "./SystemNotificationStatus.tsx";

interface SystemNotificationProps {
  notification: SystemNotificationModel;
}

export const SystemNotification: FunctionComponent<SystemNotificationProps> =
  observer(({ notification }) => {
    const { selectedPivot } = useContext(NotificationContext);

    const theme = useTheme();
    if (
      selectedPivot === NotificationsPivotKeys.Unread &&
      notification.isRead
    ) {
      return null;
    }

    const getTimestamp = (createdDate: DateTime): string => {
      const time = createdDate.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

      const date = createdDate.toDayDefaultFormat();

      if (createdDate.isToday) {
        return time;
      } else if (createdDate.isYesterday) {
        return `Yesterday at ${time}`;
      } else {
        return `${date} at ${time}`;
      }
    };

    return (
      <Stack
        key={notification.id}
        styles={{
          root: {
            "&:hover": {
              backgroundColor: theme.palette.neutralLighter
            }
          }
        }}
      >
        <Stack
          styles={{
            root: {
              margin: "0 8px",
              padding: 16,
              minHeight: "158px",
              borderBottom: "solid",
              borderBottomColor: theme.palette.neutralLight,
              borderBottomWidth: 1
            }
          }}
        >
          <div>
            <SystemNotificationStatus notification={notification} />
            <Heading
              styles={{
                root: {
                  marginTop: 8,
                  "&::first-letter": {
                    textTransform: "uppercase"
                  }
                }
              }}
            >
              {notification.title}
            </Heading>
            <Text>{notification.description}</Text>
          </div>

          <NotificationActions notification={notification} />

          {notification.createdDate && (
            <Text
              styles={{
                root: {
                  marginTop: "auto",
                  paddingTop: 16
                }
              }}
              block
              variant="small"
            >
              {getTimestamp(notification.createdDate)}
            </Text>
          )}
        </Stack>
      </Stack>
    );
  });
