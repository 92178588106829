import { FunctionComponent } from "react";

import {
  IPickerItemProps,
  MultiTagPickerItem,
  Text,
  TextBadge,
  TextBadgeSize
} from "@bps/fluent-ui";

import { ServicePickerTag } from "./ServicePicker.types.ts";

interface ServiceTagItemProps extends IPickerItemProps<ServicePickerTag> {
  onRemoveItem: () => void;
}

export const ServiceTagItem: FunctionComponent<ServiceTagItemProps> = ({
  item,
  index,
  onRemoveItem
}) => {
  return (
    <MultiTagPickerItem
      styles={{
        text: {
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        },
        root: {
          width: "100%",
          background: "transparent",
          maxWidth: "100%",
          "&:hover": { background: "transparent" }
        }
      }}
      item={item}
      index={index}
      onRemoveItem={onRemoveItem}
      disableFocus
    >
      <Text nowrap>{item.name}</Text>
      <TextBadge
        badgeColor={2}
        badgeSize={TextBadgeSize.small}
        styles={{ root: { marginLeft: 5 } }}
      >
        {item.scheduleName}
      </TextBadge>
    </MultiTagPickerItem>
  );
};
