import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { Stack, StandardRangesOptionsKeys } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { StatementItemsContext } from "@modules/billing/screens/statement/context/StatementItemsContext.ts";
import { DatesRangePickerField } from "@ui-components/form/DatesRangePickerField/DatesRangePickerField.tsx";

export const StatementGenerateModalFields: FunctionComponent = observer(() => {
  const { nameOf } = useContext(StatementItemsContext);
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <DatesRangePickerField
        styles={{ root: { width: "50%" }, fieldGroup: { width: 200 } }}
        placeholder="Date(s)"
        required
        standardRanges={[
          StandardRangesOptionsKeys.previousMonth,
          StandardRangesOptionsKeys.thisMonth
        ]}
        label="Date range"
        endDateName={nameOf("endDate")}
        startDateName={nameOf("startDate")}
        endDateProps={{ maxDate: DateTime.jsDateNow() }}
        closeCalloutOnSelection
        renderCloseBtn
        hidePasteStartButton
      />
    </Stack>
  );
});
