import { fabric } from "fabric";

/**
 * Works around the fact that Fabric uses a hand rolled class concept
 * where the Typescript typings is quite average.
 */
interface EllipseBrushOwnProps {
  startPosition?: { x: number; y: number };
  fill?: string;
  canvas?: fabric.Canvas;
  ellipse?: fabric.Ellipse;
  initialize: (this: EllipseBrushProps, canvas: fabric.Canvas) => void;
  onMouseDown: (
    this: EllipseBrushProps,
    pointer: { x: number; y: number }
  ) => void;
  onMouseMove: (
    this: EllipseBrushProps,
    pointer: { x: number; y: number }
  ) => void;
  onMouseUp: (this: EllipseBrushProps) => void;
  render: (this: EllipseBrushProps) => void;
}

interface EllipseBrushProps extends EllipseBrushOwnProps, fabric.BaseBrush {}

const properties: EllipseBrushOwnProps = {
  initialize(
    canvas,
    options: {
      fill?: string;
    } = {}
  ) {
    this.canvas = canvas;
    this.fill = options && options.fill;
  },

  onMouseDown(pointer) {
    this.startPosition = pointer;
    this.ellipse = new fabric.Ellipse({
      strokeWidth: this.width,
      stroke: this.color,
      fill: this.fill,
      originX: "left",
      originY: "top",
      top: pointer.y,
      left: pointer.x
    });
  },

  onMouseMove({ x, y }) {
    if (!this.ellipse || !this.startPosition) {
      return;
    }

    const { x: x0, y: y0 } = this.startPosition;

    const rx = Math.abs(x - x0) / 2;
    const ry = Math.abs(y - y0) / 2;

    let changes: Partial<fabric.IEllipseOptions> = {};

    if (x < x0) {
      changes.left = x;
    }

    if (y < y0) {
      changes.top = y;
    }

    changes = { ...changes, rx, ry };
    this.ellipse.set(changes);

    this.render();
  },

  onMouseUp() {
    if (!this.canvas || !this.ellipse) {
      return;
    }

    const context = this.canvas.getSelectionContext();
    this.canvas.clearContext(context);

    if (this.ellipse.width || this.ellipse.height) {
      this.canvas.add(this.ellipse);
    }

    this.ellipse = undefined;
    this.startPosition = undefined;
  },

  render() {
    if (!this.canvas || !this.ellipse) {
      return;
    }

    const context = this.canvas.getSelectionContext();
    this.canvas.clearContext(context);
    this.ellipse.render(context);
  }
};

export const EllipseBrush: EllipseBrushProps & {
  new (canvas: fabric.Canvas): EllipseBrushProps;
} = fabric.util.createClass(fabric.BaseBrush, properties);
