import { FC } from "react";
import { useField, useForm } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { AccInsurerPickerField } from "@modules/acc/screens/claim-management/components/InsurerPickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { OrganisationLabels } from "../organisation.types.ts";
import { BillingAdminEmailFields } from "./BillingAdminEmailFields.tsx";
import {
  EditOrganisationFormValues,
  orgEditFormNameOf
} from "./EditOrganisationFormValues.tsx";

type EmployerFieldsProps = {
  name: string;
};

export const EmployerFields: FC<EmployerFieldsProps> = ({ name }) => {
  const form = useForm();
  const { values } = useForm<EditOrganisationFormValues>().getState();

  const {
    input: { value: visitsPerClaim }
  } = useField(`${name}.visitsPerClaim`, {
    subscription: { value: true }
  });

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <YesNoToggleField
        label={OrganisationLabels.AccAccreditedEmployer}
        name={`${name}.accreditedEmployer`}
        styles={{ root: { marginBottom: 0 } }}
      />
      <FieldSpy
        name={`${name}.accreditedEmployer`}
        onChange={(value: boolean) => {
          form.batch(() => {
            if (value) {
              form.change(`${name}.visitsPerClaim`, "16");
              form.change(orgEditFormNameOf("usingPrivateInsurer"), false);
              form.change(`${name}.differentInvoicingEmail`, false);
            } else {
              form.change(`${name}.accreditedEmployer`, undefined);
              form.change(`${name}.visitsPerClaim`, "16");
              form.change(orgEditFormNameOf("usingPrivateInsurer"), false);
              form.change(`${name}.claimsAdminEmail`, undefined);
              form.change(`${name}.privateInsurerContactId`, undefined);
              form.change(`${name}.differentInvoicingEmail`, false);
              form.change(`${name}.invoicingEmail`, undefined);
            }
          });
        }}
      />
      <FieldCondition when={`${name}.accreditedEmployer`} is={true}>
        {visitsPerClaim && (
          <SpinNumberInputField
            required
            label={OrganisationLabels.ConsultsPerClaim}
            name={`${name}.visitsPerClaim`}
            min={0}
            step={1}
            fieldItemStyles={{
              itemWrapper: { alignItems: "baseline" },
              item: { flexGrow: 0 }
            }}
            parse={value => value ?? ""}
            // SpinNumberInput component has a min width of 86
            styles={{ root: { width: 86 } }}
          />
        )}
        <YesNoToggleField
          styles={{ root: { marginBottom: 0 } }}
          label={OrganisationLabels.UsingPrivateInsurer}
          name={orgEditFormNameOf("usingPrivateInsurer")}
        />
        <FieldSpy
          name={orgEditFormNameOf("usingPrivateInsurer")}
          onChange={(value: boolean) => {
            form.batch(() => {
              if (value) {
                form.change(`${name}.claimsAdminEmail`, undefined);
                form.change(`${name}.differentInvoicingEmail`, false);
                form.change(`${name}.invoicingEmail`, undefined);
              } else {
                form.change(`${name}.privateInsurerContactId`, undefined);
              }
            });
          }}
        />
        {values.usingPrivateInsurer ? (
          <AccInsurerPickerField
            label={OrganisationLabels.PrivateInsurer}
            required
            name={`${name}.privateInsurerContactId`}
          />
        ) : (
          <BillingAdminEmailFields name={orgEditFormNameOf("employer")} />
        )}
      </FieldCondition>
    </Stack>
  );
};
