import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { AddServicesModal } from "@modules/billing/screens/shared-components/add-service-modal/AddServicesModal.tsx";
import { ServiceableItem } from "@modules/billing/screens/shared-components/add-service-modal/AddServicesModal.types.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";

import { invoiceFormNameOf } from "./InvoiceForm.types.tsx";

export const InvoiceAddServicesModal: FunctionComponent = observer(() => {
  const { batch, change, mutators } = useForm();
  const { isAddItemDialogVisible, setIsAddItemDialogVisible, adjustInvoice } =
    useContext(InvoiceFormContext);

  const { values } = useFormState<InvoiceFormValues>({
    subscription: { values: true }
  });

  const { invoiceItems } = values;

  const closeDialog = () => {
    setIsAddItemDialogVisible(false);
  };

  const setInvoiceItems = async (services: InvoiceItemFormValue[]) => {
    if (services.length > 0) {
      change(invoiceFormNameOf("invoiceItems"), services);
    } else {
      batch(() => {
        for (let i = invoiceItems.length; i >= 0; i--) {
          mutators.remove(invoiceFormNameOf("invoiceItems"), i);
        }
      });
    }

    closeDialog();
  };

  return (
    <AddServicesModal
      closeDialog={closeDialog}
      hidden={!isAddItemDialogVisible}
      selectedServices={invoiceItems}
      kind={ServiceableItem.Invoice}
      invoice={{
        ...values,
        invoiceItems: adjustInvoice?.items
      }}
      onServicesSelected={setInvoiceItems}
    />
  );
});
