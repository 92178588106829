import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Heading } from "@bps/fluent-ui";
import {
  AddAmendmentDto,
  AmendmentClinicalDataDto,
  AmendmentDataItem,
  EncounterClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AddAmendmentFormValues } from "./AmendmentForm.types.ts";
import { AmendmentFormValidator } from "./AmendmentFormValidator.ts";

export interface AmendmentFormDialogProps {
  encounterId: string;
  isHidden: boolean;
  setIsHidden: (value: boolean) => void;
  clinicalRecord: ClinicalRecord;
}

const nameOf = nameOfFactory<AddAmendmentFormValues>();
export const AmendmentFormDialog: FunctionComponent<AmendmentFormDialogProps> =
  observer(({ encounterId, setIsHidden, isHidden, clinicalRecord }) => {
    const { clinical } = useStores();

    if (isHidden) return null;

    const TITLE = "Append note";

    const onSubmit = async (values: AddAmendmentFormValues) => {
      const request: AddAmendmentDto = {
        text: values.text,
        encounterId
      };

      const amendmentData = clinicalRecord.clinicalData?.amendments;

      const amendments: AmendmentDataItem[] = amendmentData?.amendments
        ? [...amendmentData?.amendments]
        : [];
      amendments.push({ encounterId });

      const amendmentClinicalData: AmendmentClinicalDataDto = {
        eTag: amendmentData?.eTag,
        amendments
      };

      const clinicalData: EncounterClinicalDataDto = {};

      clinicalData.amendment = amendmentClinicalData;

      await clinical.addAmendment(request);
      await clinicalRecord.saveClinicalData(clinicalData);
    };

    const onDismiss = () => setIsHidden(true);
    return (
      <SubmissionFormDialog<AddAmendmentFormValues>
        dialogName={`${TITLE} dialog`}
        onSubmit={onSubmit}
        validate={values => new AmendmentFormValidator().validate(values)}
        dialogProps={{
          onDismiss,
          minWidth: 800,
          dialogContentProps: {
            title: <Heading variant="modal-heading">{TITLE}</Heading>
          }
        }}
        onSubmitSucceeded={onDismiss}
        buttonsProps={{
          submitButtonProps: {
            text: "Save",
            iconProps: { iconName: "Save" }
          },
          hideButtonsSeparator: true
        }}
      >
        {() => (
          <TextInputField
            multiline={true}
            placeholder="Enter note here"
            rows={4}
            name={nameOf("text")}
            styles={{ root: { width: "100%" } }}
          />
        )}
      </SubmissionFormDialog>
    );
  });
