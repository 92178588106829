import { observer } from "mobx-react-lite";

import {
  dataAttribute,
  DataAttributes,
  MessageBar,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";

export interface RecurrenceRuleDisplayProps {
  recurrenceDates: {
    nextAppointmentDate: string | undefined;
    lastAppointmentDate: string | undefined;
  };
  isValidRRule: boolean | undefined;
  hideInitialState?: boolean;
}

export const RecurrenceRuleDisplay: React.FunctionComponent<RecurrenceRuleDisplayProps> =
  observer(({ recurrenceDates, isValidRRule, hideInitialState = false }) => {
    const next = DateTime.fromISO(
      recurrenceDates.nextAppointmentDate
    )?.toDayDefaultFormat();

    const last = DateTime.fromISO(
      recurrenceDates.lastAppointmentDate
    )?.toDayDefaultFormat();

    return (
      <FieldCondition when={appointmentFormNameOf("repeat")} is={true}>
        {next || last ? (
          <MessageBar>
            {next && (
              <Text
                block
                {...dataAttribute(DataAttributes.Element, "next-date-text")}
              >
                Next {next === last ? " and final " : ""}appointment will be{" "}
                {next}
              </Text>
            )}
            {last && (!next || (next && next !== last)) && (
              <Text
                block
                {...dataAttribute(DataAttributes.Element, "last-date-text")}
              >
                Final appointment will be on the {last}
              </Text>
            )}
          </MessageBar>
        ) : (
          isValidRRule !== undefined &&
          !isValidRRule &&
          !hideInitialState && (
            <MessageBar>
              <Text
                block
                {...dataAttribute(DataAttributes.Element, "default-date-text")}
              >
                Set up series to view appointment information
              </Text>
            </MessageBar>
          )
        )}
      </FieldCondition>
    );
  });
