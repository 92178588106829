import { Validator } from "@libs/validation/Validator.ts";
import { ClaimDiagnosisValidator } from "@modules/acc/screens/claim-adjustment/validators/ClaimDiagnosisValidator.tsx";

import { ClaimFormManagementValues } from "../../claim/types/claim-form-management-values.types.ts";

export class ClaimManagementFormValidator extends Validator<ClaimFormManagementValues> {
  constructor() {
    super();

    const primaryDiagnosisValidator = new ClaimDiagnosisValidator();

    this.forField("primaryDiagnosis", primaryDiagnosisValidator.validate);
  }
}
