import React from "react";

import {
  CorrespondenceDirection,
  CorrespondenceStatus
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

import { usePatientRecordScreenContext } from "../../context/PatientRecordScreenContext.ts";

interface SidePanelDocumentPreviewerProps {
  record: ClinicalDocument;
  setViewerVisible: (value: boolean) => void;
  renderDownloadButton?: (handleDownload: () => void) => JSX.Element;
}

export const SidePanelDocumentPreviewer: React.FC<
  SidePanelDocumentPreviewerProps
> = ({ record, setViewerVisible, renderDownloadButton }) => {
  const { correspondence } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();
  return (
    <DocumentViewerDialog
      closeDocumentViewer={() => {
        setViewerVisible(false);
      }}
      getDocument={async () => {
        const response = await correspondence.getDocumentUrl({
          patientId: clinicalRecord.id,
          documentId: record.id
        });

        return {
          entityId: record.patientId,
          documentId: record.id,
          entityType: DocumentEntityType.Patient,
          name: record.name,
          extension: record.extension,
          previewUri: response.url
        };
      }}
      getDownloadUri={async () => {
        const documentUrlResponse = await correspondence.getDocumentUrl({
          patientId: clinicalRecord.id,
          documentId: record.id
        });
        return documentUrlResponse.url;
      }}
      renderDownloadButton={
        record.status === CorrespondenceStatus.Done &&
        record.direction === CorrespondenceDirection.Out
          ? renderDownloadButton
          : undefined
      }
    />
  );
};
