export enum ContextMenuItemsEnum {
  MakeAvailable = "Make available",
  OpenPatientRecord = "Clinical",
  StartConsult = "Start consult",
  CompleteConsult = "Complete notes",
  ViewRecord = "View record",
  SendApptReminder = "SMS",
  Forms = "Forms",
  SendForms = "Send Forms",
  SendForm = "Send a form",
  SendFormKey = "sendForm",
  QRCode = "Display a QR code",
  QRCodeKey = "QRCODE",
  UnArrive = "Not yet arrived",
  Invoice = "Invoice",
  Cancel = "Cancel",
  Booked = "Booked",
  Arrive = "Arrive",
  Claim = "Claim",
  View = "View",
  Edit = "Edit",
  Open = "Open",
  RecordUpdate = "Record update",
  NoCharge = "No charge for this appointment",
  ReviewUnmatchedPatient = "Review unmatched"
}
