import { useContext, useRef } from "react";

import { ClaimsScreenContext } from "@modules/acc/screens/claims/context/ClaimsScreenContext.ts";
import { ClaimsScreenHelper } from "@modules/acc/screens/claims/context/ClaimsScreenHelper.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import "../styles/accForm.css";

export enum ClaimFormPrintType {
  Summary = "summary",
  Patient = "patient"
}

interface ClaimFormPrintBaseProps {
  values: ClaimFormValues;
  printType: ClaimFormPrintType.Patient | ClaimFormPrintType.Summary;
}

export const ClaimFormPrintBase: React.FC<ClaimFormPrintBaseProps> = ({
  values,
  printType
}) => {
  const {
    acc: { templates, renderClaimForm }
  } = useStores();

  const { previewAcc45PatientClaim } = useContext(ClaimsScreenContext);

  const printFormData = {
    summary: {
      label: "Print completed ACC45",
      template: templates.summaryForm,
      previewFunction: renderClaimForm
    },
    patient: {
      template: templates.patientForm,
      label: "Print claim form",
      previewFunction: previewAcc45PatientClaim
    }
  };

  const renderFile = () => {
    const { id: claimId } = values;
    if (claimId) {
      printFormData[printType].previewFunction(claimId);
    }
  };

  return (
    <div onClick={renderFile}>
      <div style={{ margin: "0px 4px" }}>{printFormData[printType].label}</div>
    </div>
  );
};

export const ClaimFormPrint: React.FC<ClaimFormPrintBaseProps> = ({
  values,
  printType
}) => {
  const root = useStores();
  const claimsScreenHelper = useRef(new ClaimsScreenHelper(root));

  return (
    <ClaimsScreenContext.Provider value={claimsScreenHelper.current}>
      <ClaimFormPrintBase values={values} printType={printType} />
    </ClaimsScreenContext.Provider>
  );
};
