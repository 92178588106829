import { AppointmentReminderJobScheduleBehavior } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export const DEFAULT_SEND_BEFORE_DAYS = 1;

export type DayOfWeekConfigValue = {
  checked: boolean;
  jobDayOfWeek: number;
};

export interface CommsScheduleFormValues {
  jobName?: string;
  sendBefore?: number;
  scheduleBehaviour?: AppointmentReminderJobScheduleBehavior;
  sendTime?: string;
  everyday?: boolean;
  daysOfWeek?: DayOfWeekConfigValue[];
  appointmentTypes?: string[];
  templateId?: string;
}
