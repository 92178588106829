import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";

export const SportingInjuryFieldsBase = observer(() => {
  const { acc } = useStores();

  const form = useForm<ClaimFormValues>();

  return (
    <>
      <BoolButtonGroupField
        label={ClaimFormLabels.sportingInjury}
        name={claimFormNameOf("sportingInjury")}
        required
      />
      <FieldCondition when={claimFormNameOf("sportingInjury")} is={true}>
        <OptionsSelectField
          name={claimFormNameOf("sport")}
          label={ClaimFormLabels.sport}
          options={acc.ref.sports.keyTextValues}
          required
          placeholder={ClaimFormLabels.sportingInjuryPlaceholder}
        />
      </FieldCondition>
      <FieldSpy
        name={claimFormNameOf("sportingInjury")}
        onChange={sportingInjury => {
          if (!sportingInjury) {
            form.change(claimFormNameOf("sport"), undefined);
          }
        }}
      />
    </>
  );
});

export const SportingInjuryFields = withFetch(
  x => [x.acc.ref.sports.load()],
  SportingInjuryFieldsBase
);
