import { Field } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { employerRelationships } from "@shared-types/practice/employer.relationship.constant.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { ContactPickerFieldWithAdd } from "../../../shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { professionalRelationships } from "../../constants/professional-relationships.constant.ts";
import { toggleFieldStyle } from "../../shared-components/edit/employers/Employers.styles.ts";
import { excludeItemsFilter } from "../../shared-components/edit/relationships/RelationshipStaticTagPicker.tsx";
import { OrganisationLabels } from "../organisation.types.ts";
import { OrgRelationshipFieldValue } from "./EditOrganisationFormValues.tsx";

type OrgRelationshipFieldsProps = {
  showToggle: boolean;
  name: string;
  onRemove: () => void;
};

export const OrgRelationshipFields: React.FC<OrgRelationshipFieldsProps> = ({
  name,
  showToggle,
  onRemove
}) => {
  const { practice } = useStores();
  const fieldName = (key: keyof OrgRelationshipFieldValue) => `${name}.${key}`;

  return (
    <Stack>
      <Field<string | undefined>
        name={fieldName("relatedContactId")}
        subscription={{ value: true }}
      >
        {({ input: { value: relatedContactId } }) => (
          <Stack tokens={{ childrenGap: 8 }} horizontal={!!relatedContactId}>
            <ContactPickerFieldWithAdd
              label={Labels.relationships}
              name={fieldName("relatedContactId")}
              autoFocus={!relatedContactId}
              actionButton={{
                linkProps: {
                  onClick: onRemove,
                  children: !relatedContactId && Labels.remove
                }
              }}
              filter={{
                types: [ContactType.Individual, ContactType.Patient]
              }}
            />
            {relatedContactId && (
              <StaticTagPickerField
                actionButton={{
                  linkProps: { onClick: onRemove, children: Labels.remove }
                }}
                formItemFieldStyles={{ root: { width: "100%" } }}
                fetchDataSource={() =>
                  practice.ref.relationshipTypes.keyNameValues.filter(
                    x =>
                      professionalRelationships.includes(x.key) ||
                      employerRelationships.includes(x.key)
                  )
                }
                label={OrganisationLabels.RelationshipType}
                name={fieldName("professional")}
                excludeItemsFilter={excludeItemsFilter}
                excludeSelectedItems={true}
              />
            )}
          </Stack>
        )}
      </Field>

      {showToggle && (
        <ToggleField
          label="Do they currently work here?"
          onText="Yes"
          offText="No"
          name={fieldName("currentJob")}
          styles={toggleFieldStyle}
        />
      )}
    </Stack>
  );
};
