import { DateTime } from "@bps/utils";
import { FeeType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  greaterThan,
  isDateAfterDate,
  isDateBeforeDate,
  isDateBeforeField,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { Service } from "@stores/billing/models/Service.ts";

import { feeFormNameOf, FeeFormValues } from "../../ScheduleForm.types.tsx";

export class FeeFormEditFeeValidator extends Validator<FeeFormValues> {
  constructor(fee: Service) {
    super();

    const lockedInstances = fee?.lockedInstances || [];

    this.forField(feeFormNameOf("feeName"), required());
    this.forField(feeFormNameOf("feeEffectiveDate"), [
      predicate(
        () => !!fee.currentInstance,
        required(),
        predicate(
          () => lockedInstances.length > 0,
          isDateAfterDate(
            DateTime.fromISOOrNow(
              lockedInstances[lockedInstances.length - 1]?.effectiveDate
            ),
            ValidationMessages.startsBeforePreviousInstance
          )
        ),
        predicate(
          (value, values) => !!values?.feeNextEffectiveDate,
          isDateBeforeDate(
            DateTime.now(),
            ValidationMessages.startsBeforeTodayIfNextInstanceExists
          )
        )
      )
    ]);
    this.forField(
      feeFormNameOf("feeFee"),
      predicate(
        (value, values) =>
          !!fee.currentInstance &&
          !!values?.feeIsActive &&
          !values.feeIsEditable,
        required(),
        greaterThan(0)
      )
    );

    this.forField(
      feeFormNameOf("feeNextEffectiveDate"),
      predicate((value, values) => !!values?.feeNextFee, required())
    );

    this.forField(
      feeFormNameOf("feeNextEffectiveDate"),
      predicate(
        (value, values) =>
          (!!values?.feeNextFee || !values?.feeNextIsActive) &&
          !fee?.nextInstance,
        isDateBeforeField(
          ValidationMessages.nextChangeSameOrAfterCurrentChange,
          "feeEffectiveDate"
        )
      )
    );

    this.forField(
      feeFormNameOf("feeNextEffectiveDate"),
      predicate(
        (value, values) =>
          (!!values?.feeNextFee || !values?.feeNextIsActive) &&
          !!fee?.nextInstance,
        isDateBeforeField(
          ValidationMessages.nextChangeAfterCurrentChange,
          "feeEffectiveDate",
          true
        )
      )
    );

    this.forField(
      feeFormNameOf("feeNextFee"),
      predicate(
        (_, values) =>
          !!values?.feeNextEffectiveDate &&
          !!values.feeNextIsActive &&
          !values.feeNextIsEditable,
        required(),
        greaterThan(0)
      )
    );

    const validFeeTypesForMaterials = [FeeType.FlatRate, FeeType.PerUnit];

    this.forField(
      feeFormNameOf("feeType"),
      predicate(
        (value, values) => !values?.feeIsService,
        value =>
          !validFeeTypesForMaterials.includes(value)
            ? ValidationMessages.invalidFeeTypeForMaterial
            : false
      )
    );
  }
}
