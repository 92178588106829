import { action, computed } from "mobx";

import { newGuid, to2dp } from "@bps/utils";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { PaymentMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { sum } from "@libs/utils/utils.ts";
import { AllocationFormButtons } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import {
  getAllocationFormValuesFromInvoice,
  getFilteredAllocations
} from "@modules/billing/screens/allocation/utils.ts";
import { ADD_ACCOUNT_CREDIT_BTN } from "@modules/billing/screens/billing-history/components/BillingButtons.tsx";
import {
  AllocationFieldValues,
  ItemSelectionMode
} from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { getAllocationsTotal } from "@modules/billing/screens/shared-components/allocation-field/utils.tsx";
import { AllocationOptions } from "@modules/billing/screens/shared-components/allocation-form/AllocationOptions.ts";
import {
  ACCOUNT_CREDIT_METHOD,
  AllocationFormValues,
  allocationNameOf,
  PaymentFormValues
} from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { AllocationOption } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationOptionsChoiceFields.tsx";
import { IAllocationFormHelper } from "@modules/billing/screens/shared-components/allocation-form/context/AllocationFormHelper.types.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { FormApiWithMutators } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

import { AllocationFormHelperBase } from "./AllocationFormHelperBase.ts";

interface AllocationFormScreenHelperOptions {
  invoice?: Invoice;
  invoiceIds?: string[];
  accountId?: string;
}

export class AllocateToInvoiceFormHelper
  extends AllocationFormHelperBase
  implements IAllocationFormHelper
{
  balance?: AccountBalance;
  invoice?: Invoice;
  invoiceIds?: string[];
  accountId?: string;
  initialValues: AllocationFormValues;
  initialAllocationValues: AllocationFormValues["allocations"];

  constructor(
    private root: IRootStore,
    options: AllocationFormScreenHelperOptions
  ) {
    super(root);
    this.invoice = options.invoice;
    this.invoiceIds = options.invoiceIds;
    this.accountId = options.accountId;
  }

  private get allocationOptionValue() {
    if (this.isFromInvoice) {
      return AllocationOptions.paySetItems;
    } else if (this.invoiceIds) {
      return AllocationOptions.setAmount;
    } else if (!this.owingTotal || this.isCreateAccountCreditOnly) {
      return AllocationOptions.accountCredit;
    } else {
      return AllocationOptions.payAccount;
    }
  }

  get isCreateAccountCreditOnly() {
    return (
      this.root.routing.getRouteState()?.buttonClicked ===
      ADD_ACCOUNT_CREDIT_BTN
    );
  }

  public get disableAccountPicker() {
    return this.isFromAccount || this.isFromInvoice;
  }

  public get owingTotal() {
    return this.invoice?.owing ?? this.balance?.totalOwing ?? 0;
  }

  public get dateRequired() {
    return true;
  }

  public shouldSendEmail: boolean;

  public allocationEmail: string | undefined;

  public setAllocationEmail = (email: string) => {
    this.allocationEmail = email;
  };

  @computed public get isNewAllocationMode() {
    return !!this.root.routing.match(routes.accounts.allocations.new);
  }

  @computed public get isFromInvoice() {
    return this.root.routing.hasQueryStringParam(
      routes.accounts.queryKeys.invoiceId
    );
  }

  @computed public get isFromAccount() {
    return !!this.accountId;
  }

  public shouldOpenPdf: boolean;

  @action
  public setShouldOpenPdf = (open: boolean) => {
    this.shouldOpenPdf = open;
  };

  public setShouldSendEmail = (sendEmail: boolean) => {
    this.shouldSendEmail = sendEmail;
  };

  public onAllocationOptionsChanged = (allocationOption: string) => {
    return { allocations: this.getAllocations(allocationOption) };
  };

  public get allocationOptions(): AllocationOption[] {
    const hasOutstandingItems = this.initialAllocationValues?.some(
      a => a.invoiceNumber !== this.invoice?.number
    );

    return [
      {
        key: AllocationOptions.paySetItems,
        text: `${AllocationFormButtons.payInvoice} (${currencyFormat(
          this.invoice?.owing || 0
        )})`,
        itemSelectionMode: ItemSelectionMode.none,
        filter: this.isFromInvoice
      },
      {
        key: AllocationOptions.payAccount,
        text: `${AllocationFormButtons.payAccount} (${currencyFormat(
          Number(this.balance ? this.balance.totalOwing : 0)
        )})`,
        itemSelectionMode: ItemSelectionMode.none,
        filter: hasOutstandingItems
      },
      {
        key: AllocationOptions.setAmount,
        text: AllocationFormButtons.payAmount,
        filter: this.balance?.totalOwing !== 0
      },
      {
        key: AllocationOptions.accountCredit,
        text: AllocationFormButtons.accountCredit,
        itemSelectionMode: ItemSelectionMode.hidden,
        filter: !this.isFromInvoice
      }
    ]
      .filter(x => x.filter === undefined || !!x.filter)
      .map(x => ({
        key: x.key,
        text: x.text,
        itemSelectionMode: x.itemSelectionMode
      }));
  }

  public getColumnOptions = (allocationOption: string) => ({
    filtersToShow: {
      invoice: allocationOption !== AllocationOptions.paySetItems,
      providerName: allocationOption !== AllocationOptions.paySetItems,
      patientName: allocationOption !== AllocationOptions.paySetItems,
      total:
        allocationOption === AllocationOptions.setAmount ||
        this.isNewAllocationMode,
      owing:
        allocationOption === AllocationOptions.setAmount &&
        this.isNewAllocationMode
    }
  });

  public setAmountBasedOnOption = (
    allocationOption: string,
    form: FormApiWithMutators<AllocationFormValues>,
    paymentMethod?: string
  ) => {
    let payment: PaymentFormValues;
    if (allocationOption === AllocationOptions.paySetItems) {
      payment = {
        id: newGuid(),
        amount: this.invoice?.owing || 0,
        method: paymentMethod || PaymentMethod.Eftpos
      };
    } else if (allocationOption === AllocationOptions.payAccount) {
      payment = {
        id: newGuid(),
        amount: this.balance?.totalOwing || 0,
        method: paymentMethod || PaymentMethod.Eftpos
      };
    } else {
      payment = {
        id: newGuid(),
        amount: 0,
        method: paymentMethod || PaymentMethod.Eftpos
      };
    }
    form.change(allocationNameOf("payments"), [
      {
        ...payment,
        amount:
          paymentMethod === ACCOUNT_CREDIT_METHOD
            ? Math.min(this.accountCreditTotal, payment.amount)
            : payment.amount
      }
    ]);
  };

  public onPaymentLengthChange = (options: {
    index: number;
    values: AllocationFormValues;
    form: FormApiWithMutators<AllocationFormValues>;
    removed?: boolean;
  }) => {
    const { index, values, form, removed = false } = options;
    const { payments, allocationOption } = values;

    const isPayTotalAction =
      allocationOption === AllocationOptions.paySetItems ||
      allocationOption === AllocationOptions.payAccount;

    if (
      payments?.length === 2 &&
      index < 2 &&
      allocationOption &&
      isPayTotalAction
    ) {
      if (removed) {
        this.setAmountBasedOnOption(
          allocationOption,
          form,
          payments[1 - index].method
        );
      } else if (index === 1 && payments[0].method === ACCOUNT_CREDIT_METHOD) {
        this.onAmountChange({
          value: payments[0].amount,
          values,
          index: 0,
          form
        });
      }
    }
  };

  public onAmountChange = (options: {
    value: any;
    values: AllocationFormValues;
    index: number;
    form: FormApiWithMutators<AllocationFormValues>;
  }) => {
    const {
      value,
      values: { payments, allocationOption },
      index,
      form
    } = options;

    const isPayTotalAction =
      allocationOption === AllocationOptions.paySetItems ||
      allocationOption === AllocationOptions.payAccount;

    // auto-calculates amounts if the user is paying using exactly two actions
    // this function can cause itself to run - checking if valueString equals "0" is how we prevent loops when one number is too large
    if (value !== "0" && payments?.length === 2 && isPayTotalAction) {
      const amount = Number(value || 0);
      const total =
        allocationOption === AllocationOptions.payAccount
          ? this.balance?.totalOwing
          : this.invoice?.owing;

      const otherValueRaw = (total || 0) - amount;
      const otherValueRounded = to2dp(otherValueRaw); // stringified to fix rounding issues
      const otherValue = Math.max(otherValueRounded, 0); // to avoid negative numbers
      const existingOtherValue =
        index === 0 ? Number(payments[1].amount) : Number(payments[0].amount);
      // to prevent unnecessary changes and loops
      if (otherValue !== existingOtherValue) {
        form.change("payments", [
          {
            ...payments[0],
            amount: index === 0 ? amount : otherValue
          },
          {
            ...payments[1],
            amount: index === 1 ? amount : otherValue
          }
        ]);
      }
    }
  };

  public initialise = async (accountContactId?: string): Promise<void> => {
    await this.reset(accountContactId);
  };

  public reset = async (accountContactId?: string) => {
    await this._reset(accountContactId);

    const balance = accountContactId
      ? await this.root.billing.getAccountBalanceForAccountId(accountContactId)
      : undefined;

    this.balance = balance;

    this.getInitialValues(accountContactId);
  };

  public getAllocations = (allocationOption: string) => {
    if (!!this.initialAllocationValues?.length) {
      const filteredAllocations: AllocationFieldValues[] =
        getFilteredAllocations({
          allocations: this.initialAllocationValues,
          invoiceNumber: this.invoice?.number,
          allocationAction: allocationOption
        }).map(x => ({ ...x, checked: false, total: 0 }));
      return filteredAllocations;
    }
    return undefined;
  };

  public = async (accountId?: string) => {
    await this.reset(accountId);
  };

  public getInitialValues = (
    accountContactId?: string
  ): AllocationFormValues => {
    const initialValues = this._getInitialValues(
      accountContactId,
      this.invoice
    );

    const allocationFormValues = {
      ...initialValues,
      allocationOption: this.allocationOptionValue,
      ...getAllocationFormValuesFromInvoice(
        {
          amountOverride: this.isCreateAccountCreditOnly ? 0 : undefined,
          number: this.number,
          invoice: this.invoice,
          accountId: accountContactId || this.accountId,
          invoiceIds: this.invoiceIds,
          outstandingInvoices: this.outstandingInvoices,
          paymentMethod: this.invoiceIds
            ? PaymentMethod.DirectCredit
            : PaymentMethod.Eftpos
        },
        this.root.core
      )
    };
    this.initialAllocationValues = allocationFormValues.allocations!;

    allocationFormValues.allocations = this.isCreateAccountCreditOnly
      ? []
      : getFilteredAllocations({
          allocations: allocationFormValues.allocations || [],
          allocationAction: this.allocationOptionValue,
          invoiceNumber: this.invoice?.number
        });

    this.initialValues = allocationFormValues;

    return this.initialValues;
  };

  public onSubmit = async (values: AllocationFormValues) => {
    const allocationsTotal: number = getAllocationsTotal(
      values.allocations || []
    );
    if (allocationsTotal > sum("amount", values.payments || [])) {
      this.root.notification.error(
        notificationMessages.allocationMoreThanUnallocatedPaymentAmount
      );
    } else {
      try {
        const newAllocation = await this._onSubmit({
          ...values
        });

        if (this.shouldOpenPdf && newAllocation) {
          await this.root.billing.openAllocationReceiptPdf(newAllocation.id);
        }
        if (this.shouldSendEmail && newAllocation) {
          await this.root.billing.sendAllocationEmail(
            newAllocation.id,
            this.allocationEmail
          );
        }
      } catch (e) {
        this.root.notification.error(e.message);
        throw new Error(e.message);
      }
    }
  };

  public onSubmitFailed = () => {
    this.setShouldOpenPdf(false);
  };
}
