import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";

import {
  NoDataTile,
  ScrollablePane,
  SelectionMode,
  Stack,
  Tile,
  useFormContext
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { RunQueryOptions } from "@libs/utils/promise-observable/promise-observable.types.ts";
import {
  ClinicalTaskManagementFilter,
  ClinicalTaskManagementFilterType
} from "@modules/clinical/screens/patient-record/components/clinical-task/ClinicalTaskManagementFilter.tsx";
import { ClinicalTaskTable } from "@modules/clinical/screens/patient-record/components/clinical-task/ClinicalTaskTable.tsx";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

export interface TasksClinicalProps {
  setSelected?: React.Dispatch<React.SetStateAction<ClinicalTask[]>>;
}

export const TasksClinicalListBase: FC<TasksClinicalProps> = observer(props => {
  const {
    state: { values: filter }
  } = useFormContext<ClinicalTaskManagementFilterType>();

  const { clinical, core } = useStores();
  const { setSelected } = props;

  const getItems = useCallback(
    async (options?: RunQueryOptions) => {
      const clinicalTasks = await clinical.getClinicalTasks({
        ...filter,
        ...options,
        excludeConfidential: !filter.confidential,
        startDateTime: DateTime.jsDateToISODate(filter.startDateTime),
        endDateTime: DateTime.jsDateToISODate(filter.endDateTime),
        total: true
      });
      if (!filter.confidential) {
        clinicalTasks.results = clinicalTasks.results.filter(x =>
          core.hasAccessToSecGroup(x.secGroupId)
        );
      }

      return clinicalTasks;
    },
    [clinical, filter, core]
  );

  return (
    <ScrollablePane
      styles={{
        root: {
          height: "100%",
          padding: 8,
          position: "relative"
        }
      }}
    >
      <ClinicalTaskTable
        showLockIcon={true}
        compact={false}
        showPtColumn={true}
        showCreatedByColumn={true}
        isMultiline={false}
        setSelected={setSelected}
        verticalView={false}
      >
        {({ selection, columns, renderRow, renderDetailsHeader }) => (
          <InfiniteScrollList
            setKey="clinical-task-list"
            selectionMode={SelectionMode.multiple}
            stickyHeader
            getItems={getItems}
            columns={columns}
            onRenderRow={renderRow}
            onRenderDetailsHeader={renderDetailsHeader}
            selection={selection.current}
            selectionPreservedOnEmptyClick={true}
            refreshKey={clinical.lastUpdatedClinicalTasks}
            onRenderNoResults={() => (
              <NoDataTile
                textProps={{ text: "No information to display" }}
                linkProps={{ hidden: true }}
                showBoxShadow={false}
              />
            )}
          />
        )}
      </ClinicalTaskTable>
    </ScrollablePane>
  );
});

export const TasksClinicalList: FC<TasksClinicalProps> = props => (
  <Tile
    styles={{
      root: {
        flexGrow: 1,
        height: "100%",
        position: "relative",
        pending: 8
      }
    }}
  >
    <Stack
      styles={{
        root: { height: "100%", overflowX: "auto" }
      }}
    >
      <ClinicalTaskManagementFilter>
        <TasksClinicalListBase {...props} />
      </ClinicalTaskManagementFilter>
    </Stack>
  </Tile>
);
