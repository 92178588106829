import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef } from "react";
import { useField, useForm } from "react-final-form";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  Heading,
  NoDataTile,
  Pivot,
  PivotItem,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { appointmentFormNameOf } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/AppointmentForm.types.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { SecondColumnWrapper } from "../../SecondColumnWrapper.tsx";
import { ActiveConditionList } from "../active-condition-list/ActiveConditionList.tsx";
import { ConditionContext } from "../context/ConditionContext.ts";
import { ConditionHelper } from "../context/ConditionHelper.ts";
import {
  ConditionDetailLabels,
  ConditionModalFormValues,
  ConditionPivotHeader
} from "./Condition.types.ts";
import { ConditionModal } from "./ConditionModal.tsx";

export const ConditionDetails: React.FC = observer(() => {
  const {
    condition,
    setInitialConditions,
    setEpisodeOfCareId,
    setCreatedConditions,
    conditionAppointmentFormHelper,
    selectedProviders
  } = useContext(AppointmentFormContext);

  const root = useStores();

  const patientConditions =
    conditionAppointmentFormHelper.getSortedConditions();

  const conditionsDischarged = patientConditions.filter(
    x => x.discharged ?? false
  );

  const conditionsInProgress = root.core.hasPermissions(
    Permission.OnHoldClaimAllowed
  )
    ? patientConditions.filter(x => !(x.discharged || x.claim?.isOnHold))
    : patientConditions.filter(x => !x.discharged);

  const conditionsOnHold = patientConditions.filter(
    x => x.claim?.isOnHold ?? false
  );

  const showOnHoldCondition = root.core.hasPermissions([
    Permission.OnHoldClaimAllowed,
    Permission.ClaimRead
  ]);

  setInitialConditions(patientConditions);

  const form = useForm<AppointmentFormValues>();
  const {
    input: { value: patientId }
  } = useField(appointmentFormNameOf("patientId"), {
    subscription: { value: true }
  });

  const {
    input: { value: sendDemographicForm }
  } = useField(appointmentFormNameOf("sendDemographicForm"), {
    subscription: { value: true }
  });

  const updateForm = (condition: ICondition) => {
    form.batch(() => {
      form.change(
        appointmentFormNameOf("episodeOfCareId"),
        condition.episodeOfCareId
      );
      form.change(appointmentFormNameOf("sendAccForm"), condition.sendAccForm);
      if (sendDemographicForm && sendDemographicForm !== condition.sendAccForm)
        form.change(
          appointmentFormNameOf("sendDemographicForm"),
          condition.sendAccForm
        );
    });
  };

  const conditionHelper = useRef<ConditionHelper>(
    new ConditionHelper(root, patientId, {
      onSubmit: async (
        values: ConditionModalFormValues,
        helper: ConditionHelper
      ) => {
        await helper.createCondition(values, selectedProviders[0]);
      },
      onSubmitSucceeded: async condition => {
        const conditions = conditionAppointmentFormHelper.conditions;
        runInAction(() => {
          conditionAppointmentFormHelper.conditionsPromise.set(
            Promise.resolve([condition, ...conditions])
          );
        });

        setEpisodeOfCareId(condition.episodeOfCareId);
        //storing new created Conditions
        setCreatedConditions(condition);
        //This is updating the AppointmentFormFields with values form the ClaimManagementModalForm once submitted
        updateForm(condition);
      }
    })
  );

  useEffect(() => {
    if (
      !patientId &&
      root.booking.ui.currentAppointment?.secondColumnContent ===
        SecondColumnContent.conditionDetails
    ) {
      root.booking.ui.setSecondColumnContent(
        root.booking.ui.currentAppointment?.secondColumnContent !==
          SecondColumnContent.conditionDetails
          ? SecondColumnContent.conditionDetails
          : undefined
      );
    }
  }, [patientId, root.booking.ui]);

  const header = (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      grow={1}
    >
      <Heading
        variant="section-heading-light"
        {...dataAttribute(DataAttributes.Element, "second-column-heading")}
      >
        {ConditionDetailLabels.CurrentConditions}
      </Heading>
      <ActionButton
        iconProps={{ iconName: "add" }}
        onClick={() => conditionHelper.current.setHiddenConditionModal(false)}
        text={ConditionDetailLabels.New}
        disabled={selectedProviders.length < 1}
      />
    </Stack>
  );

  const defaultSelectedKey = (() => {
    if (condition?.discharged) return ConditionPivotHeader.Discharged;
    if (condition?.claim?.isOnHold) return ConditionPivotHeader.OnHold;
    return undefined;
  })();

  return (
    <ConditionContext.Provider value={conditionHelper.current}>
      <SecondColumnWrapper heading={header}>
        {patientConditions.length > 0 && patientId ? (
          <Stack styles={{ root: { width: "calc(100% - 2px)" } }}>
            <Pivot defaultSelectedKey={defaultSelectedKey}>
              <PivotItem
                headerText={`${ConditionPivotHeader.InProgress} (${conditionsInProgress.length})`}
                itemKey={ConditionPivotHeader.InProgress}
              >
                <ActiveConditionList conditions={conditionsInProgress} />
              </PivotItem>
              <PivotItem
                headerText={`${ConditionPivotHeader.Discharged} (${conditionsDischarged.length})`}
                itemKey={ConditionPivotHeader.Discharged}
              >
                <ActiveConditionList conditions={conditionsDischarged} />
              </PivotItem>
              {showOnHoldCondition && (
                <PivotItem
                  headerText={`${ConditionPivotHeader.OnHold} (${conditionsOnHold.length})`}
                  itemKey={ConditionPivotHeader.OnHold}
                >
                  <ActiveConditionList conditions={conditionsOnHold} />
                </PivotItem>
              )}
            </Pivot>
          </Stack>
        ) : (
          <NoDataTile
            styles={{ root: { width: "100%", justifyContent: "center" } }}
            textProps={{
              text: ConditionDetailLabels.NoConditionsToDisplay
            }}
            greyView
            showBoxShadow={false}
            linkProps={{
              text: ConditionDetailLabels.CreateNewCondition,
              onClick: () => {
                conditionHelper.current.setHiddenConditionModal(false);
              }
            }}
          />
        )}
      </SecondColumnWrapper>
      <ConditionModal />
    </ConditionContext.Provider>
  );
});
