import { FunctionComponent } from "react";

import { Dropdown, IDropdownOption } from "@bps/fluent-ui";
import { CalendarEventReminderStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { EventReminderStatesEnumText } from "@modules/booking/screens/event-reminders/EventReminderScreen.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AttendanceStatusDropDownOption } from "./AttendanceStatusDropDownOption.tsx";

interface AttendanceStatusDropdownProps {
  onStatusChange: (status: CalendarEventReminderStatus) => void;
  attendanceStatus?: CalendarEventReminderStatus;
}

const AttendanceStatusDropdownBase: FunctionComponent<
  AttendanceStatusDropdownProps
> = ({ onStatusChange, attendanceStatus }) => {
  const { booking, core } = useStores();

  const disabled = !core.hasPermissions(Permission.CalendarEventWrite);

  const onRenderTitle = (options: IDropdownOption[]) => {
    const option = options[0];
    return (
      <AttendanceStatusDropDownOption
        code={option?.key as CalendarEventReminderStatus}
        text={option?.text}
        currentAttendenceStatus={attendanceStatus}
      />
    );
  };

  const options = booking.ref.calendarEventReminderStatuses.values.map(
    ({ code }) => ({
      key: code,
      text: EventReminderStatesEnumText[code]
    })
  );

  return (
    <Dropdown
      options={options}
      name="attendance-status"
      onRenderOption={option => (
        <AttendanceStatusDropDownOption
          code={option?.key as CalendarEventReminderStatus}
          text={option?.text}
          currentAttendenceStatus={attendanceStatus}
        />
      )}
      onRenderTitle={onRenderTitle}
      placeholder="Unconfirmed"
      onChange={(_, option) => {
        const code = option?.key as CalendarEventReminderStatus;
        if (code === attendanceStatus) return;
        onStatusChange(code);
      }}
      disabled={disabled}
      selectedKey={attendanceStatus}
      styles={{
        dropdownItem: {
          padding: 0
        },
        dropdownItemSelected: {
          padding: 0,
          "&:hover": {
            backgroundColor: "unset"
          },
          borderWidth: 0
        },
        title: {
          padding: 0,
          borderWidth: "0 0 1px 0",
          paddingLeft: !!attendanceStatus ? 0 : 8
        },
        dropdown: { minWidth: 160 }
      }}
    />
  );
};

export const AttendanceStatusDropdown = withFetch(
  x => x.booking.ref.calendarEventReminderStatuses.load(),
  AttendanceStatusDropdownBase
);
