import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontSizes,
  FontWeights,
  IconButton,
  IGroupHeaderProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PrescriptionClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { iconButtonStyles } from "./MedicationsList.styles.ts";

interface PrescriptionsListHeaderProps {
  prescription: PrescriptionClinicalDataItemDto;
  props: IGroupHeaderProps;
}

export const PrescriptionsListHeader: FunctionComponent<
  PrescriptionsListHeaderProps
> = ({ props, prescription }) => {
  const theme = useTheme();

  const isFinal = props?.groups?.length
    ? props?.groupIndex === props?.groups?.length - 1
    : false;

  const isCollapsed = props.group?.isCollapsed || false;

  const getTitle = (prescription: PrescriptionClinicalDataItemDto) => {
    if (prescription.createLog && prescription.createLog.createdDateTime) {
      const date = DateTime.fromISO(prescription.createLog.createdDateTime);
      return `${date.toDayDefaultFormat()} - ID: ${prescription.scriptId}`;
    }

    return `ID: ${prescription.scriptId}`;
  };

  return (
    <Stack
      horizontal
      onClick={() => {
        if (props?.onToggleCollapse && props?.group) {
          props.onToggleCollapse(props.group);
        }
      }}
      styles={{
        root: {
          height: "fit-content",
          borderTop: `1px ${theme.palette.neutralLight} solid`,
          borderBottom:
            isFinal && isCollapsed
              ? `1px ${theme.palette.neutralLight} solid`
              : undefined
        }
      }}
    >
      <>
        <IconButton
          {...dataAttribute(
            DataAttributes.Element,
            `${prescription.scriptId}-collapse`
          )}
          iconProps={{
            iconName: isCollapsed ? "ChevronRight" : "ChevronDown"
          }}
          styles={iconButtonStyles}
        />
        <Stack
          grow
          styles={{
            root: {
              paddingTop: 8,
              paddingBottom: isCollapsed ? 8 : 0,
              paddingRight: 16
            }
          }}
        >
          <Text
            styles={{
              root: {
                color: theme.palette.themeDark,
                fontWeight: FontWeights.semibold,
                fontSize: FontSizes.size14
              }
            }}
          >
            {getTitle(prescription)}
          </Text>
          {isCollapsed && (
            <Stack>
              {prescription.medications.map(x => (
                <Text key={x.productName}>{x.productName}</Text>
              ))}
            </Stack>
          )}
        </Stack>
      </>
    </Stack>
  );
};
