import { useForm } from "react-final-form";

import { CollapsibleCard, Heading, HideStack, Stack } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { DiagnosisSideFields } from "./DiagnosisSideFields.tsx";
import { DiagnosisList } from "./discharge-form/DiagnosisList.tsx";
import {
  DiagnosisCardLabels,
  SotapMskId,
  SotapMskLabel
} from "./SOTAP.constants.ts";
import { getDiagnoses, sotapNameOf } from "./SOTAP.utils.ts";

export const DiagnosisCard: React.FC = () => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const form = useForm();
  const { fields } = useFieldArray(sotapNameOf("additionalDiagnoses"));

  const isFollowOn = clinicalRecord.isFollowOnEncounter;

  const condition = clinicalRecord.condition;

  const claim = condition?.claim;
  const viewOnly =
    (isFollowOn ||
      clinicalRecord.is1stMultiRoleConsult() ||
      claim?.isViewMode ||
      claim?.referralIn) &&
    claim !== undefined;

  const { primaryDiagnoses, additionalDiagnoses } = getDiagnoses(
    clinicalRecord,
    clinicalRecord.clinicalData?.diagnoses,
    viewOnly
  );

  return (
    <div id={SotapMskId.Diagnosis}>
      <CollapsibleCard
        heading={SotapMskLabel.Diagnosis}
        headingLevel="section-heading"
        iconName="Medical"
        openOnRender={!isFollowOn}
      >
        {viewOnly ? (
          <DiagnosisList
            primaryDiagnoses={primaryDiagnoses}
            additionalDiagnoses={additionalDiagnoses}
          />
        ) : (
          <>
            <Stack tokens={{ childrenGap: 8 }}>
              <Heading variant="section-heading-light">
                {DiagnosisCardLabels.PrimaryDiagnosis}
              </Heading>

              <DiagnosisSideFields
                name={sotapNameOf("primaryDiagnosis")}
                addMoreButton={false}
              />
            </Stack>
            <Stack tokens={{ childrenGap: 8 }}>
              <Heading
                variant="section-heading-light"
                styles={{
                  root: {
                    "&&": {
                      marginTop: 24
                    }
                  }
                }}
              >
                {DiagnosisCardLabels.AdditionalDiagnoses}
              </Heading>
              <ToggleField
                styles={{ root: { marginBottom: 0 } }}
                onText="Yes"
                offText="No"
                name={sotapNameOf("multipleDiagnoses")}
              />
              <FieldSpy
                name={sotapNameOf("multipleDiagnoses")}
                onChange={checked => {
                  if (checked) {
                    if (
                      !fields.value ||
                      (fields?.value && (fields?.value?.length ?? 0) === 0)
                    )
                      fields.push({});
                  } else form.change(sotapNameOf("additionalDiagnoses"), []);
                }}
              />
            </Stack>

            <FieldSpy name={sotapNameOf("multipleDiagnoses")}>
              {(_fieldState, value) => (
                <HideStack when={!value} styles={{ root: { marginTop: 16 } }}>
                  <DiagnosisSideFields
                    name={sotapNameOf("additionalDiagnoses")}
                    addMoreButton={true}
                    maxDiagnosisCount={9}
                  />
                  <FieldSpy
                    name={sotapNameOf("additionalDiagnoses")}
                    onChange={value => {
                      if (!value?.length) {
                        form.change(sotapNameOf("multipleDiagnoses"), false);
                      }
                    }}
                  />
                </HideStack>
              )}
            </FieldSpy>
          </>
        )}
      </CollapsibleCard>
    </div>
  );
};
