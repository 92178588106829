import { observer } from "mobx-react-lite";
import { parse, stringify } from "query-string";
import { To } from "react-router-dom";

import {
  FontSizes,
  IconButton,
  PersonaSize,
  Spinner,
  Stack,
  StateBlock,
  Text,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  AllocationStatuses,
  DraftItemsTotalsDto,
  GetTransactionsArgs,
  ItemType,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { useAccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { AccountBadge } from "@modules/billing/screens/shared-components/AccountBadge.tsx";
import { AccountStatementDate } from "@modules/billing/screens/shared-components/AccountStatementDate.tsx";
import { TransactionFilterQuery } from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/TransactionFilter.types.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";

export const getMostRecentTransactionType = (
  transaction: TransactionBase | undefined,
  ref: KeyTextValue<string>[]
): string => {
  if (!transaction) {
    return "None";
  } else {
    const dateString = DateTime.fromJSDate(
      transaction.transactionDate
    ).toDayDefaultFormat();
    return `${dateString} - ${
      ref.find(x => x.key === transaction.itemType)?.text || ""
    }`;
  }
};

export interface AccountHeaderProps {
  balance: AccountBalance;
}

interface AccountHeaderLink {
  text: string;
  to: To;
}

export const AccountHeader: React.FC<AccountHeaderProps> = observer(
  ({ balance }) => {
    const {
      practice: {
        ui: { showContactDetails }
      },
      routing,
      billing
    } = useStores();

    const theme = useTheme();

    const accountId: string | undefined = routing.match(routes.accounts.account)
      ?.params.id!;

    if (!accountId) {
      return null;
    }

    const handleOnClick = () => {
      showContactDetails(accountId);
    };

    const clearFilterLink = {
      text: "Clear filter",
      to: routes.accounts.account.path({ id: accountId })
    };

    const path = routes.accounts.account.path({ id: accountId });

    const filter: TransactionFilterQuery = parse(routing.location.search);

    let viewInvoicesLink: AccountHeaderLink | undefined;
    let viewCreditsLink: AccountHeaderLink | undefined;

    if (balance.totalOwing > 0) {
      if (
        filter.itemTypes === ItemType.Invoice ||
        (Array.isArray(filter.itemTypes) &&
          filter.itemTypes?.some(type => type === ItemType.Invoice))
      ) {
        viewInvoicesLink = clearFilterLink;
      } else {
        const filter: GetTransactionsArgs = {
          itemTypes: [ItemType.Invoice],
          paymentStatuses: [PaymentStatuses.unpaid, PaymentStatuses.part]
        };
        viewInvoicesLink = {
          text: "View invoices",
          to: `${path}?${stringify(filter)}`
        };
      }
    }

    if (balance.unallocatedCredit > 0) {
      const creditItemTypes = [ItemType.CreditNote, ItemType.Payment];

      if (
        creditItemTypes.every(
          itemType =>
            Array.isArray(filter.itemTypes) &&
            filter.itemTypes?.some(
              filterItemType => filterItemType === itemType
            )
        )
      ) {
        viewCreditsLink = clearFilterLink;
      } else {
        const filter: GetTransactionsArgs = {
          itemTypes: creditItemTypes,
          allocationStatuses: [
            AllocationStatuses.Unallocated,
            AllocationStatuses.PartAllocated
          ]
        };
        viewCreditsLink = {
          text: "View credits",
          to: `${path}?${stringify(filter)}`
        };
      }
    }

    return (
      <Tile
        styles={{
          root: {
            padding: 16,
            minHeight: 240,
            border: `1px solid ${theme.palette.neutralLight}`,
            boxShadow: "none"
          },
          content: { display: "flex", flexDirection: "column" }
        }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{
            root: {
              padding: 24,
              borderBottom: `1px solid ${theme.palette.neutralLight}`
            }
          }}
        >
          <DataFetcher<Contact | undefined>
            fetch={async ({ practice }) =>
              accountId ? practice.getContact(accountId) : undefined
            }
            fallback={<Spinner />}
          >
            {accountContact =>
              accountContact && (
                <Persona
                  id={accountContact.id}
                  imageUrl={accountContact.profilePictureUrl}
                  contactType={accountContact.type}
                  size={PersonaSize.size48}
                  imageInitials={accountContact.initials}
                  onRenderPrimaryText={() => (
                    <Stack
                      horizontal
                      verticalAlign="center"
                      tokens={{ childrenGap: 16 }}
                    >
                      <Text styles={{ root: { minWidth: 100 } }}>
                        {accountContact.name}
                      </Text>
                      <IconButton
                        iconProps={{ iconName: "Share" }}
                        ariaLabel="Move to"
                        onClick={handleOnClick}
                      />
                    </Stack>
                  )}
                  onRenderSecondaryText={() => (
                    <Stack>
                      {accountContact.billingEmail && (
                        <Text styles={{ root: { fontSize: FontSizes.small } }}>
                          {accountContact.billingEmail}
                        </Text>
                      )}
                      {accountContact.primaryCommunication && (
                        <Text styles={{ root: { fontSize: FontSizes.small } }}>
                          {accountContact.primaryCommunication?.value}
                        </Text>
                      )}
                    </Stack>
                  )}
                />
              )
            }
          </DataFetcher>

          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <AccountStatementDate accountContactId={accountId} />
            <AccountBadge balance={balance} />
          </Stack>
        </Stack>
        <Stack
          styles={{ root: { padding: 24 } }}
          horizontal
          tokens={{ childrenGap: 12 }}
          horizontalAlign="stretch"
        >
          <StateBlock
            centerAlign
            id="account-header-total-owing"
            labelText="Total owing"
            descText={currencyFormat(balance.totalOwing)}
            linkText={viewInvoicesLink?.text}
            onLickClick={() =>
              viewInvoicesLink && routing.push(viewInvoicesLink?.to)
            }
          />
          <DataFetcher<DraftItemsTotalsDto | undefined>
            fetch={async ({ billing, practice }) => {
              const contact = await practice.getContact(accountId);
              if (contact.draftItemsEnabled)
                return billing.getAccountDraftItemsTotals(accountId);
              else return undefined;
            }}
          >
            {draftItemsTotals =>
              draftItemsTotals && (
                <StateBlock
                  centerAlign
                  id="account-header-draft-items-total"
                  labelText="Draft items"
                  descText={currencyFormat(draftItemsTotals?.total ?? 0)}
                  linkText="Go to draft items"
                  onLickClick={() =>
                    routing.push({
                      pathname: routes.accounts.draftItems.basePath.path({}),
                      search: `?accountIds=${accountId}`
                    })
                  }
                />
              )
            }
          </DataFetcher>

          <StateBlock
            centerAlign
            id="account-header-unallocated-credit"
            labelText="Unallocated credit"
            descText={currencyFormat(balance.unallocatedCredit)}
            linkText={viewCreditsLink?.text}
            onLickClick={() =>
              viewCreditsLink && routing.push(viewCreditsLink?.to)
            }
          />

          <AccountLatestTransactionFetcher>
            {mostRecentTransaction => (
              <StateBlock
                centerAlign
                id="account-header-most-recent-transaction"
                labelText="Most recent activity"
                descText={getMostRecentTransactionType(
                  mostRecentTransaction,
                  billing.ref.itemTypes.keyTextValues
                )}
              />
            )}
          </AccountLatestTransactionFetcher>
        </Stack>
      </Tile>
    );
  }
);

export type AccountLatestTransactionFetcherProps = {
  children: (
    mostRecentTransaction: TransactionBase | undefined
  ) => React.ReactNode;
};

const AccountLatestTransactionFetcher: React.FC<
  AccountLatestTransactionFetcherProps
> = ({ children }) => {
  const { accountContact } = useAccountScreenContext();
  return (
    <DataFetcher<TransactionBase | undefined>
      fetch={async x => {
        const result = await x.billing.fetchTransactionsNew({
          accountIds: [accountContact.id],
          sortColumn: "TRANSACTIONCHANGEDDATE",
          sortDescending: true,
          take: 1
        });

        const transactions = result.results;
        return transactions[0];
      }}
      fallback={
        <Stack grow verticalAlign="center" horizontalAlign="center">
          <Spinner />
        </Stack>
      }
    >
      {mostRecentTransaction => children(mostRecentTransaction)}
    </DataFetcher>
  );
};
