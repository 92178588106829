import { to2dp } from "@bps/utils";
import { sum } from "@libs/utils/utils.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ManualAllocationFormValues } from "../shared-components/allocation-field/manual-allocation-modal/ManualAllocationForm.types.tsx";
import {
  allocationFieldNameOf,
  AllocationFieldValues
} from "../shared-components/allocation-field/types.ts";
import { AllocationOption } from "../shared-components/allocation-form/components/AllocationOptionsChoiceFields.tsx";

export class ManualAllocationListValidator extends Validator<AllocationFieldValues> {
  constructor(
    value: AllocationFieldValues,
    values: ManualAllocationFormValues,
    maxTotalKey: AllocationOption["itemMaxTotalKey"] = "owing"
  ) {
    super();

    this.forField(allocationFieldNameOf("total"), [
      (total: number) => {
        const allocated = sum(
          "total",
          values.allocations.filter(
            i => i.invoiceItemId !== value.invoiceItemId
          )
        );

        const overallocated = to2dp(
          values.allocatableAmount - allocated - value.total
        );
        // no value
        if (total > (value[maxTotalKey] || 0)) {
          return messageWithData(
            ValidationMessages.owing,
            value[maxTotalKey] ?? 0
          );
        } else if (!!value.total && overallocated && overallocated < 0) {
          return messageWithData(
            ValidationMessages.overallocated,
            Math.abs(overallocated)
          );
        }

        return undefined;
      }
    ]);
  }
}
