import React, { useContext, useEffect } from "react";

import {
  AnimatedList,
  ButtonsGroupOption,
  dataAttribute,
  DataAttributes,
  Heading,
  NoDataTile,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  NzPracticeAccContractDto,
  ProviderAccContractTypeDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { ProviderAccContractContext } from "./context/ProviderAccContractContext.tsx";
import { getProviderAccContractStylesSet } from "./ProviderAccContracts.styles.ts";
import {
  NO_ACC_CONTRACT,
  ProviderAccContractsFormLabels
} from "./ProviderAccContracts.types.ts";

interface ProviderAccContractsProps {
  practiceContracts: NzPracticeAccContractDto[];
}

const ProviderAccContractsBase: React.FC<ProviderAccContractsProps> = ({
  practiceContracts
}) => {
  const { core } = useStores();
  const accContractHelper = useContext(ProviderAccContractContext);

  useEffect(() => {
    accContractHelper.setAvailableAccContracts(practiceContracts);
  }, [accContractHelper, practiceContracts]);

  const accProviderTypes = accContractHelper.availableAccProviderTypes;

  const contractFields = useFieldArray("contractTypes").fields;

  const groupByProviderType =
    accContractHelper.availableAccProviderContractTypes.reduce(
      (groups, item) => {
        const groupByValue = item.providerTypeText;
        groups[groupByValue] = groups[groupByValue] || [];
        groups[groupByValue].push(item);
        return groups;
      },
      {}
    );

  const orderedKeys = accProviderTypes.map(i => i.text);

  const findFieldsIndex = (key: string) =>
    contractFields.value.findIndex(v => v.providerTypeText === key);

  const getButtonOptions = (
    providerTypeText: string,
    disabled: boolean
  ): ButtonsGroupOption<string>[] => {
    const index = findFieldsIndex(providerTypeText);

    let values: ButtonsGroupOption<string>[] = [
      {
        key: `${providerTypeText}${NO_ACC_CONTRACT}`,
        text: "No ACC Contract",
        disabled
      }
    ];

    if (index >= 0) {
      const value = contractFields.value[index].providerContractTypeCode;

      values = [
        ...groupByProviderType[providerTypeText]
          .filter(
            (g: ProviderAccContractTypeDto) =>
              g.isActive || (!g.isActive && g.code === value)
          )
          .map((g: ProviderAccContractTypeDto) => ({
            key: g.code,
            text: g.text,
            disabled
          })),
        ...values
      ];
    }

    return values;
  };

  const theme = useTheme();
  const providerAccContractStyles = getProviderAccContractStylesSet(theme);

  const hasUserWritePermission = core.hasPermissions(Permission.UserWrite);

  return (
    <>
      <AnimatedList
        animationKeys={orderedKeys}
        onAnimationKeyRemoved={() => {}}
      >
        {animationKey => {
          const index = findFieldsIndex(animationKey);
          const providerTypeText = accProviderTypes.filter(
            i => i.text === animationKey
          )[0].text;

          const savedValue =
            index >= 0
              ? contractFields.value[index].providerContractTypeCode
              : undefined;

          const removedContract =
            accContractHelper.availableAccProviderContractTypes.find(
              c => !c.isActive && c.code === savedValue
            );

          return (
            <Stack
              key={animationKey}
              tokens={{ childrenGap: 0 }}
              styles={
                !removedContract
                  ? providerAccContractStyles.lineStackRoot
                  : providerAccContractStyles.lineStackRootError
              }
            >
              <Heading
                variant="section-heading-light"
                styles={providerAccContractStyles.providerTypeLineStackItem}
              >
                {providerTypeText}
              </Heading>
              <Stack
                key={animationKey}
                horizontal
                tokens={{ childrenGap: 10 }}
                styles={
                  !removedContract
                    ? providerAccContractStyles.lineStackItem
                    : providerAccContractStyles.lineStackItemError
                }
              >
                <ButtonsGroupSingleChoiceField
                  disabled={!!removedContract || !hasUserWritePermission}
                  options={getButtonOptions(
                    animationKey,
                    !!removedContract || !hasUserWritePermission
                  )}
                  required
                  name={`contractTypes[${index}].providerContractTypeCode`}
                  label="Contract"
                />
              </Stack>
              {removedContract && (
                <Text
                  styles={providerAccContractStyles.lineStackError}
                  {...dataAttribute(
                    DataAttributes.Element,
                    "removedContractMessage"
                  )}
                >{`The ${removedContract.text.toLowerCase()} contract cannot be used, it has been removed from the practice`}</Text>
              )}
            </Stack>
          );
        }}
      </AnimatedList>

      {!contractFields.value.length && (
        <NoDataTile
          textProps={{ text: ProviderAccContractsFormLabels.noProviderType }}
          linkProps={{ hidden: true }}
        />
      )}
    </>
  );
};

const ProviderAccContractDataFetcher: React.FC = () => (
  <DataFetcher
    fetch={x => x.practice.getOrgUnit(x.core.location.parentOrgUnit!.id)}
  >
    {(data: PracOrgUnit) => (
      <ProviderAccContractsBase
        practiceContracts={data?.nzAccContracts?.contracts ?? []}
      />
    )}
  </DataFetcher>
);

export const ProviderAccContracts = withFetch(
  x => [x.practice.ref.accProviderContractTypes.load()],
  ProviderAccContractDataFetcher
);
