import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";

export enum InvoiceFormSubmitButtonClicked {
  PayNow = 1,
  OpenPdf = 2,
  SendEmail = 3,
  Save = 4
}

export interface InvoiceFormProps {
  initialValues: InvoiceFormValues;
}

export enum InvoiceFormButtons {
  pay = "Pay",
  payInvoice = "Pay Invoice",
  paid = "Paid",
  pdf = "PDF",
  getPdf = "Get PDF",
  submit = "Save",
  submitInvoice = "Save Invoice",
  close = "Close",
  adjust = "Adjust",
  cancelInvoice = "Cancel invoice",
  writeOff = "Write off",
  credit = "Credit / refund",
  cancel = "Cancel",
  addItem = "Add items to invoice",
  sendEmail = "Send email",
  saveDraft = "Save draft"
}

export enum InvoiceItemsColumns {
  serviceDate = "service-date",
  itemNumber = "item-number",
  state = "state",
  receiptNumber = "receipt-number",
  description = "description",
  lineItemComment = "comment",
  chargedTo = "charged-to",
  purchaseOrderNumber = "purchase-order-number",
  quantity = "quantity",
  gst = "gst",
  fee = "fee",
  owing = "owing",
  changeType = "change-type",
  actions = "actions"
}

export enum InvoiceItemsColumnNames {
  serviceDate = "Service date",
  itemNumber = "Item #",
  state = "State",
  receiptNumber = "Receipt",
  description = "Description",
  lineItemComment = "Comment",
  chargedTo = "Charged to",
  purchaseOrderNumber = "PO #",
  quantity = "QTY",
  gst = "GST",
  fee = "Fee ($)",
  owing = "Owing",
  changeType = "Change type",
  actions = ""
}

export enum InvoiceItemsTotal {
  totalIncGst = "Total incl. GST ($)",
  owing = "Balance owing ($)"
}

export const invoiceFormNameOf = nameOfFactory<InvoiceFormValues>();
export const invoiceItemFormNameOf = nameOfFactory<InvoiceItemFormValue>();
