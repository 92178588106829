import { DateTime } from "@bps/utils";
import {
  FormInstanceDTO,
  FormInstanceStatus,
  FormTemplateTypeCode
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { CommunicationDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { canReceiveForm } from "@stores/core/models/Communication.ts";

export const sendFormHint = (
  communications: CommunicationDto[] | undefined,
  qrCode: boolean
) => {
  return !qrCode && !canReceiveForm(communications)
    ? "A mobile or email is required to send out forms"
    : undefined;
};

export const getFormInstanceStatus = (
  formInstance: FormInstanceDTO
): FormInstanceStatus => {
  let formInstanceStatus: FormInstanceStatus;
  if (formInstance.responseDate) {
    formInstanceStatus = FormInstanceStatus.Responded;
  } else if (formInstance.cancelled) {
    formInstanceStatus = FormInstanceStatus.Cancelled;
  } else if (DateTime.fromISO(formInstance.expiryDate) < DateTime.now()) {
    formInstanceStatus = FormInstanceStatus.Expired;
  } else {
    formInstanceStatus = FormInstanceStatus.Pending;
  }
  return formInstanceStatus;
};

export interface FindFormOptions {
  formTemplateTypes: FormTemplateTypeCode[];
  context?: Record<string, string>;
  status?: FormInstanceStatus;
}

export const findLatestForm = (
  patientForms: FormInstanceDTO[],
  findOptions: FindFormOptions
): FormInstanceDTO | undefined => {
  const { formTemplateTypes, context, status } = findOptions;

  let matchingForms = patientForms.filter(
    f =>
      formTemplateTypes.includes(f.formTemplateTypeCode) &&
      status === getFormInstanceStatus(f)
  );

  //match contexts
  if (context && matchingForms.length > 0) {
    const contextKeys = Object.keys(context);
    matchingForms = matchingForms.filter(f => {
      let allContextMatched = true;
      for (const key of contextKeys) {
        if (context[key] !== f.context[key]) {
          allContextMatched = false;
          break;
        }
      }
      return allContextMatched;
    });
  }

  let latestForm: FormInstanceDTO | undefined;

  if (matchingForms.length > 0) {
    const sortedForms = matchingForms.sort(sortByDateDesc);
    latestForm = sortedForms[0];
  }

  return latestForm;
};

export const sortByDateDesc = (
  formInstance1: FormInstanceDTO,
  formInstance2: FormInstanceDTO
): number => {
  const date1 = DateTime.fromISO(formInstance1.sentDate);

  const date2 = DateTime.fromISO(formInstance2.sentDate);
  if (date1 > date2) return -1;
  if (date2 < date1) return 1;
  return 0;
};
