import { observer } from "mobx-react-lite";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo
} from "react";
import { useField, useForm } from "react-final-form";

import { useFormContext } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { appointmentFormNameOf } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/AppointmentForm.types.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

import { useWaitingListScreenContext } from "../../context/WaitingListScreenContext.tsx";
import { WaitingListFilterInternal } from "../WaitingListDetailFilter.tsx";
import { WaitingListDialogTable } from "./WaitingListDialogTable.tsx";

interface WaitingListDetailsTableWithContextProps {
  selection: React.MutableRefObject<Selection>;
}

export const WaitingListDetailsTableWithContext: FunctionComponent<WaitingListDetailsTableWithContextProps> =
  observer(({ selection }) => {
    const { isGroupAppointment } = useContext(AppointmentFormContext);
    const form = useForm();
    form.change(appointmentFormNameOf("selectedCount"), 0);
    const {
      input: { value: startDate }
    } = useField<AppointmentFormValues["startDate"]>("startDate", {
      subscription: { value: true }
    });

    const {
      input: { value: orgUnitId }
    } = useField<AppointmentFormValues["orgUnitId"]>("orgUnitId", {
      subscription: { value: true }
    });

    const {
      input: { value: providerId }
    } = useField<AppointmentFormValues["providerId"]>("providerId", {
      subscription: { value: true }
    });

    const {
      input: { value: appointmentTypeId }
    } = useField<AppointmentFormValues["appointmentTypeId"]>(
      "appointmentTypeId",
      { subscription: { value: true } }
    );

    const {
      input: { value: duration }
    } = useField<AppointmentFormValues["duration"]>("duration", {
      subscription: { value: true }
    });

    const initialFilter = useMemo(
      () => ({
        expiryDate: DateTime.jsDateToISODate(startDate),
        providerId,
        orgUnitId,
        appointmentTypeId:
          isGroupAppointment && appointmentTypeId
            ? [appointmentTypeId]
            : undefined,
        duration:
          isGroupAppointment && duration ? [duration.toString()] : undefined
      }),
      [
        startDate,
        providerId,
        appointmentTypeId,
        isGroupAppointment,
        duration,
        orgUnitId
      ]
    );

    const { fetchWaitingListItems, waitingListResults, items } =
      useWaitingListScreenContext();

    const {
      state: { values: filter }
    } = useFormContext<WaitingListFilterInternal>();

    const { booking } = useStores();

    useEffect(() => {
      fetchWaitingListItems({ ...filter, ...initialFilter });
      return () => {
        form.change(appointmentFormNameOf("selectedCount"), 0);
        waitingListResults.clear();
      };
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, booking.recentlyUpdatedWaitingListItemEtag]);

    return (
      <WaitingListDialogTable
        items={items}
        selection={selection}
        loading={waitingListResults.pending}
        error={waitingListResults.error}
      />
    );
  });
