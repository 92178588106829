export interface DocumentSettingsFormValues {
  id?: string;
  footer: string;
  statementFooter?: string;
  bankDetailsUnderStatementFooter: boolean;
}

export enum SettingsFormLabels {
  usingTheLetterhead = "Using the letterhead",
  footer = "Invoice footer",
  templateDialogue = "Template dialogue",
  statementFooter = "Statement footer",
  templateAvailableTabs = "Available tabs (max. 3)",
  bankDetailsUnderStatementFooter = "Include practice bank account in the statement footer",
  bankDetailsUnderInvoiceFooter = "Include practice bank account in the invoice footer",
  usePracticeLetterhead = "Add automatically to all documents from blank or custom templates"
}

export enum SettingsFormPlaceholders {
  footer = "Standard footer"
}

export enum SettingsFormHeadings {
  financials = "Financials"
}
