import { action, computed, observable } from "mobx";

import { newGuid } from "@bps/utils";
import {
  CorrespondenceType,
  DocumentContentType,
  DocumentTabStatus,
  TemplateTypeCode,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  TemplateDto,
  TemplateRenderDto,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { DocumentStore } from "@stores/documents/DocumentStore.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { UserStorage } from "@stores/user-experience/models/UserStorage.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { TemplatePickerFormValues } from "../../correspondence/components/TemplatePickerForm.types.ts";
import {
  TemplateListItem,
  TemplatePivotItemKeys
} from "../../correspondence/components/TemplatePivot.types.ts";
import { sortItems } from "../../correspondence/correspondence.utils.ts";

export class TemplateManagementFormModel {
  constructor(
    private document: DocumentStore,
    private userExperience: UserExperienceStore
  ) {}

  @observable
  favourites: string[];
  @observable
  items: TemplateListItem[];
  @observable
  selectedKey: string;
  @observable
  isEditAsCopy: boolean;

  @action
  setIsEditAsCopy = (value: boolean) => {
    this.isEditAsCopy = value;
  };

  @computed
  get isCreateTemplate(): boolean {
    if (this.selectedTemplate) {
      return !this.document.templateMap.get(this.selectedTemplate.id);
    }
    return true;
  }

  @observable
  showDeleteTemplateDialog: boolean | undefined;

  @action
  setShowDeleteTemplateDialog = (value: boolean | undefined) => {
    this.showDeleteTemplateDialog = value;
  };

  get isNew(): boolean | undefined {
    return this.selectedTemplate?.isNew;
  }

  get isFromAutofillMgmt(): boolean {
    return this.document.isFromAutofillMgmt;
  }

  @action
  setFavourites(favourites: string[]) {
    this.favourites = favourites;
  }

  @action
  setItems(items: TemplateListItem[]) {
    this.items = items;
  }

  @action
  setSelectedKey(selectedKey: string) {
    this.selectedKey = selectedKey;
  }

  setUpInitialState(favourites: string[], items: TemplateListItem[]) {
    this.setFavourites(favourites);

    // Is it from AutoFill management
    if (this.isFromAutofillMgmt) {
      this.setItems(
        sortItems(
          items.filter(
            item => item.documentType === CorrespondenceType.Autofill
          )
        )
      );
      this.setSelectedKey(TemplatePivotItemKeys.Autofills);
      return;
    }

    this.setItems(
      favourites.length
        ? sortItems(items.filter(item => favourites.includes(item.key)))
        : sortItems(items)
    );

    this.setSelectedKey(
      favourites.length
        ? TemplatePivotItemKeys.Favourites
        : TemplatePivotItemKeys.All
    );
  }

  @computed
  get selectedTemplate() {
    const { templateMap, selectedTemplateId } = this.document;
    return selectedTemplateId ? templateMap.get(selectedTemplateId) : undefined;
  }

  @computed
  get submitButtonProps() {
    return this.isCreateTemplate || this.isEditAsCopy
      ? {
          text: "New template",
          iconProps: { iconName: "EditNote" }
        }
      : {
          text: "Update",
          iconProps: { hidden: true }
        };
  }

  generateTemplateDto(props: {
    documentType: CorrespondenceType;
    name?: string;
    documentStatus?: string;
    isAdmin: boolean;
    isClinical: boolean;
    shortcut?: string;
    content?: string;
  }) {
    const dto: TemplateDto = {
      id: newGuid(),
      name: props.name ?? props.documentType,
      documentType: props.documentType,
      content: props.content ?? "",
      templateTypeCode: TemplateTypeCode.Clinical,
      templateFormat: DocumentContentType.Sfdt,
      isCustom: true,
      isSystem: false,
      eTag: "",
      isNew: true,
      isLetterhead: false,
      documentStatus: props.documentStatus,
      isAdmin: props.isAdmin,
      isClinical: props.isClinical,
      shortcut: props.shortcut
    };

    this.document.mergeTemplate(dto);
    return dto;
  }

  onTemplateSelected = async (values: TemplatePickerFormValues) => {
    if (!values.templateId) {
      await this.document.createWriterTemplate(
        CorrespondenceType.Letter,
        {},
        {
          visibility: TemplateVisibility.Both,
          writerTemplate: this.selectedTemplate
        }
      );
    } else {
      if (values.templateId) {
        const template = await this.document.getTemplate(values.templateId);
        this.document.setSelectedTemplateId(template.id);
        await this.editTemplate(template);
      }
    }
  };

  editTemplate = async (template: Template) => {
    let renderedContent: TemplateRenderDto | undefined;
    if (this.selectedTemplate) {
      const renderOptions: TemplateRenderOptions = {
        skipMerge: true
      };
      renderedContent = await this.document.renderTemplate(
        template.id,
        renderOptions
      );
    }

    template.setRenderedContent(renderedContent?.content ?? "");
    this.document.setActiveTemplateTab({
      documentId: template.id,
      documentTabStatus: DocumentTabStatus.Edit
    });
    this.document.setShowTemplateManagementModal(false);
  };

  getUpdatedFavouriteItems = (favouriteTemplates: UserStorage | undefined) => {
    const favouriteTemplate = (favouriteTemplates?.jsonData || []) as string[];
    const favouriteTemplateList = favouriteTemplate.filter(x =>
      this.document.templateMap.has(x)
    );
    return favouriteTemplateList.filter(id => id !== this.selectedTemplate?.id);
  };

  deleteTemplate = async (
    reasonForDelete?: string | undefined,
    reasonForDeleteComment?: string | undefined
  ) => {
    // delete template should call frist
    await this.document.deleteTemplate(reasonForDelete, reasonForDeleteComment);

    const result = await this.userExperience.getUserStorage(
      UserStorageKeys.FavouriteTemplates
    );

    const favouriteTemplates = result;
    const updatedFavouriteTemplate =
      this.getUpdatedFavouriteItems(favouriteTemplates);
    if (favouriteTemplates) {
      this.setFavourites(updatedFavouriteTemplate);
      this.setItems(
        this.selectedKey === TemplatePivotItemKeys.Favourites
          ? this.items.filter(item =>
              updatedFavouriteTemplate.includes(item.key)
            )
          : this.items.filter(item => item.key !== this.selectedTemplate?.id)
      );
      await this.userExperience.updateUserStorage(
        UserStorageKeys.FavouriteTemplates,
        {
          key: favouriteTemplates.key,
          userId: favouriteTemplates.userId,
          jsonData: updatedFavouriteTemplate,
          eTag: favouriteTemplates.eTag,
          id: favouriteTemplates.id
        }
      );
    }
  };
}
