import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { Heading } from "@bps/fluent-ui";
import { GetStagingInfoDto } from "@libs/api/dtos/index.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { CorrespondenceForm } from "../clinical-form/CorrespondenceForm.tsx";
import { CorrespondenceFormValue } from "../clinical-form/DocumentBatchFormValues.ts";
import { CorrespondenceDialogHelper } from "./CorrespondenceDialogHelper.ts";
import { CorrespondenceFormValidator } from "./CorrespondenceFormValidator.ts";
import { handleConflictError } from "./utils.tsx";

interface CorrespondenceDialogProps {
  correspondence?: ClinicalDocument;
  onDismiss: () => void;
  onConflictError: (confirmed: boolean) => void;
}

export const CorrespondenceDialog: React.FC<CorrespondenceDialogProps> =
  observer(({ correspondence, onDismiss, onConflictError }) => {
    const root = useStores();
    const { notification } = root;

    const { clinicalRecord } = usePatientRecordScreenContext();
    const correspondeceDialogHelper = useRef(
      new CorrespondenceDialogHelper(clinicalRecord, root)
    ).current;

    const isEdit = !!correspondence;
    const validateForm = async (values: CorrespondenceFormValue) => {
      return new CorrespondenceFormValidator(isEdit).validate(values);
    };

    const initialValues =
      correspondeceDialogHelper.getInitialValues(correspondence);

    const formName = isEdit ? "Edit correspondence" : "New correspondence";

    return (
      <DataFetcher<GetStagingInfoDto>
        fetch={async root => {
          const stagingPath = await root.clinical.getStagingInfo();
          return stagingPath;
        }}
      >
        {stagingPath => (
          <SubmissionFormDialog<CorrespondenceFormValue>
            dialogName="Correspondence dialog"
            onSubmit={async formValues => {
              try {
                if (isEdit && correspondence) {
                  await correspondeceDialogHelper.updateCorrespondence(
                    formValues,
                    correspondence
                  );
                } else {
                  await correspondeceDialogHelper.submitNewCorrespondence(
                    formValues
                  );
                }
              } catch (error) {
                const isConflict = await handleConflictError(
                  error,
                  confirmed => {
                    onConflictError(confirmed);
                    if (!confirmed) throw new Error(error.message);
                  }
                );
                if (!isConflict) {
                  notification.error(
                    "An error occurred when updating the correspondence."
                  );
                  throw new Error(error.message);
                }
              }
            }}
            onSubmitSucceeded={onDismiss}
            validate={validateForm}
            styles={{ fields: { overflowY: "inherit" } }}
            buttonsProps={() => {
              return {
                submitButtonProps: {
                  text: "Save",
                  iconProps: { hidden: true }
                },
                disableSubmitOnPristine: true,
                disableSubmitOnFormInvalid: true,
                hideButtonsSeparator: true
              };
            }}
            initialValues={
              isEdit ? initialValues : { ...initialValues, stagingPath }
            }
            dialogProps={{
              onDismiss,
              modalProps: {
                layerProps: {
                  eventBubblingEnabled: true
                }
              },

              minWidth: 650,
              dialogContentProps: {
                title: <Heading variant="modal-heading">{formName}</Heading>
              }
            }}
            render={() => <CorrespondenceForm isEdit={isEdit} />}
          />
        )}
      </DataFetcher>
    );
  });
