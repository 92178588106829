import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  DefaultButton,
  IContextualMenuItem,
  SplitButton
} from "@bps/fluent-ui";
import { BillingStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import { WriteOff } from "@stores/billing/models/WriteOff.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { CancelWriteOffDialog } from "./CancelWriteOffDialog.tsx";

export interface WriteOffHeaderButtonsProps {
  writeOff: WriteOff;
}

export const WriteOffHeaderButtons: React.FC<WriteOffHeaderButtonsProps> =
  observer(({ writeOff }) => {
    const { routing, core } = useStores();

    const [showDialog, setShowDialog] = useState(false);

    const isCancelled = writeOff.status === BillingStatuses.cancelled;

    const close = () => {
      closeInvoiceOrPaymentPage(routing, "replace");
    };

    const showCancel =
      !isCancelled && core.hasPermissions(Permission.WriteOffCancel);

    const menuItems: IContextualMenuItem[] = showCancel
      ? [
          {
            key: "cancel",
            text: "Cancel write-off",
            onClick: () => {
              setShowDialog(true);
            }
          }
        ]
      : [];

    return (
      <div>
        {!isCancelled ? (
          <SplitButton items={menuItems} text="Close" onClick={close} />
        ) : (
          <DefaultButton text="Close" onClick={close} />
        )}

        <CancelWriteOffDialog
          writeOff={writeOff}
          hidden={!showDialog}
          onDismiss={() => setShowDialog(false)}
          onSubmitSucceeded={() =>
            closeInvoiceOrPaymentPage(routing, "replace")
          }
        />
      </div>
    );
  });
