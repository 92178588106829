import { FunctionComponent } from "react";

import { dataAttribute, DataAttributes, Stack } from "@bps/fluent-ui";
import { EmploymentType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import {
  EmployerFieldValue,
  patientFormNameOf
} from "../PatientEditFormValues.tsx";
import { EditEmployersViewModel } from "./EditEmployersViewModel.ts";
import { toggleFieldStyle } from "./Employers.styles.ts";

interface EmployerMetadataProps {
  model: EditEmployersViewModel;
  employers: EmployerFieldValue[];
  index: number;
}

export const EmployerMetadata: FunctionComponent<EmployerMetadataProps> = ({
  model,
  employers,
  index
}) => {
  const currentEmployer = employers[index];
  const metadata = currentEmployer.metadata;
  return (
    <>
      {metadata?.employmentType === EmploymentType.Other && (
        <Stack
          styles={() => ({
            root: {
              marginBottom: 13
            }
          })}
        >
          <TextInputField
            required={true}
            name={`${patientFormNameOf(
              "employers"
            )}[${index}].metadata.otherInformation`}
            label="Details of other employment type"
            placeholder="Details of other employment type"
          />
        </Stack>
      )}
      {currentEmployer.relatedContactId && (
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          <ToggleField
            {...dataAttribute(
              DataAttributes.Element,
              "do-you-currently-work-toggle"
            )}
            label="Do you currently work here?"
            onText="Yes"
            offText="No"
            name={`${patientFormNameOf(
              "employers"
            )}[${index}].metadata.currentJob`}
            styles={toggleFieldStyle}
          />
          <ToggleField
            {...dataAttribute(
              DataAttributes.Element,
              "is-this-primary-job-toggle"
            )}
            label="Is this your primary job?"
            onText="Yes"
            offText="No"
            name={`${patientFormNameOf(
              "employers"
            )}[${index}].metadata.primaryJob`}
            disabled={!metadata?.currentJob}
            styles={toggleFieldStyle}
          />
          <FieldSpy
            name={`${patientFormNameOf(
              "employers"
            )}[${index}].metadata.primaryJob`}
            onChange={(isPrimaryJob: boolean) => {
              model.onPrimaryJobChange(isPrimaryJob, index, employers);
            }}
          />
          <FieldSpy
            name={`${patientFormNameOf(
              "employers"
            )}[${index}].metadata.currentJob`}
            onChange={(isCurrentJob: boolean) => {
              model.onCurrentJobChange(isCurrentJob, index, employers);
            }}
          />
        </Stack>
      )}
    </>
  );
};
