import React, { useState } from "react";

import {
  Card,
  Heading,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { LocationStatusButtons } from "./LocationStatusButtons.tsx";
import { NewLocationDetailsForm } from "./NewLocationDetailsForm.tsx";
import { fetchLocationById } from "./utils.ts";

interface FetchResult {
  pracOrgUnit?: PracOrgUnit;
  locationOrgUnit?: OrgUnit;
  hasAppointments?: boolean;
  hasFutureWaitingListEntries?: boolean;
}

const LocationDetailsScreen: React.FC = () => {
  const { routing } = useStores();
  const [
    showNeedToConfigWorkingHoursMessageBar,
    setShowNeedToConfigWorkingHoursMessageBar
  ] = useState(false);

  const locationId = routing.match(routes.settings.practices.locations.edit)
    ?.params.id;

  return (
    <Stack styles={{ root: { paddingTop: 24 } }} tokens={{ childrenGap: 24 }}>
      <Heading variant="section-heading">{SettingsLabels.locations}</Heading>
      <DataFetcher<FetchResult>
        fetch={root =>
          locationId ? fetchLocationById(root, locationId) : Promise.resolve({})
        }
        fallback={<Spinner />}
      >
        {({
          pracOrgUnit,
          locationOrgUnit,
          hasAppointments,
          hasFutureWaitingListEntries
        }) => (
          <Card
            id="new-location-details-card"
            iconName="Nav2DMapView"
            headingLevel="section-heading"
            heading={SettingsLabels.locationDetails}
            button={
              <LocationStatusButtons
                locationId={locationId}
                locationOrgUnit={locationOrgUnit}
                hasAppointments={hasAppointments}
                hasFutureWaitingListEntries={hasFutureWaitingListEntries}
                pracOrgUnit={pracOrgUnit}
                onReactivate={() =>
                  setShowNeedToConfigWorkingHoursMessageBar(true)
                }
              />
            }
            styles={{
              subComponentStyles: {
                tile: {
                  content: {
                    padding: 0
                  }
                }
              }
            }}
          >
            {showNeedToConfigWorkingHoursMessageBar && (
              <MessageBar
                messageBarType={MessageBarType.warning}
                onDismiss={() =>
                  setShowNeedToConfigWorkingHoursMessageBar(false)
                }
              >
                Please configure new working hours for providers working at this
                location
              </MessageBar>
            )}
            <NewLocationDetailsForm
              pracOrgUnit={pracOrgUnit}
              locationOrgUnit={locationOrgUnit}
            />
          </Card>
        )}
      </DataFetcher>
    </Stack>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default LocationDetailsScreen;
