import { DateTime, ordinalNumber } from "@bps/utils";
import {
  MeasurementDto,
  MeasurementType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

export class PSFSHelper {
  private psfsMeasurements?: MeasurementDto[];

  get isInitialAssessment() {
    return (
      !this.psfsMeasurements ||
      this.psfsMeasurements.length === 0 ||
      (this.psfsMeasurements &&
        this.psfsMeasurements[this.psfsMeasurements.length - 1].encounterId ===
          this.encounterId)
    );
  }

  private numberInSeries() {
    let value = 0;
    let index = -1;

    if (this.psfsMeasurements) {
      index = this.psfsMeasurements.findIndex(
        m => m.encounterId === this.encounterId
      );
      value =
        index !== -1
          ? this.psfsMeasurements.length - index
          : this.psfsMeasurements.length + 1;
    }

    return value;
  }

  generateSubheadingText(date: DateTime) {
    return `${
      this.isInitialAssessment
        ? "Baseline"
        : ordinalNumber(this.numberInSeries())
    } Assessment ${date.toDayDefaultFormat()}`;
  }

  constructor(
    clinicalRecord: ClinicalRecord,
    private contextId?: string,
    private encounterId?: string
  ) {
    if (clinicalRecord.measurements) {
      this.psfsMeasurements = clinicalRecord.measurements.filter(
        m => m.type === MeasurementType.PSFS && m.contextId === contextId
      );
    }
  }
}
