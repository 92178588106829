import { PropsWithChildren, ReactNode } from "react";

import { Stack, Tile, TopBarWrapper } from "@bps/fluent-ui";

import { ObservationsResponsiveHeader } from "./ObservationsResponsiveHeader.tsx";

interface ObservationHeaderWrapperProps
  extends PropsWithChildren<{
    heading?: string;
    noGap?: boolean;
    pivotTab?: ReactNode;
    onClose: () => void;
    hasSeparator?: boolean;
  }> {}

export const ObservationsHeaderWrapper: React.FC<
  ObservationHeaderWrapperProps
> = props => {
  return (
    <TopBarWrapper
      styles={{
        root: {
          gap: props.noGap ? 0 : 16,
          margin: -16
        },
        content: {
          display: "flex"
        },
        subComponentStyles: {
          topBarStyles: {
            root: {
              height: "auto",
              padding: 0
            }
          }
        }
      }}
      header={
        <ObservationsResponsiveHeader
          heading={props.heading}
          pivotTab={props.pivotTab}
          onClose={props.onClose}
          hasSeparator={props.hasSeparator}
        />
      }
      hasShadow={!props.hasSeparator}
    >
      <Tile
        styles={{
          root: {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            padding: props.noGap && !props.hasSeparator ? 0 : 16
          }
        }}
      >
        <Stack styles={{ root: { padding: 16 } }}>{props.children}</Stack>
      </Tile>
    </TopBarWrapper>
  );
};
