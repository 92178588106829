import { FunctionComponent } from "react";

import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DeleteDialog,
  DeleteDialogProps
} from "@ui-components/DeleteDialog.tsx";

export interface IntakeDeleteDialogProps {
  hidden: boolean;
  onConfirm: DeleteDialogProps["onConfirm"];
  onCancel?: () => void;
}

const IntakeDeleteDialogBase: FunctionComponent<
  IntakeDeleteDialogProps
> = props => {
  const {
    clinical: { ref }
  } = useStores();

  return (
    <DeleteDialog
      formName="delete-intake"
      {...props}
      title="Delete Intake"
      subText="Are you sure you want to delete this intake?"
      options={ref.reasonsForDelete.keyTextValues}
    />
  );
};

export const IntakeDeleteDialog = withFetch(
  x => [x.clinical.ref.reasonsForDelete.load()],
  IntakeDeleteDialogBase
);
