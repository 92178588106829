import { memo, useEffect, useState } from "react";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IExtendedBasePicker,
  IPersonaProps,
  Link,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import {
  InternalProviderFieldValue,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

export interface InternalProviderFieldProps {
  index: number;
  fieldProps: FieldArrayRenderProps<InternalProviderFieldValue, HTMLElement>;
  onRemove: () => void;
  resetLeads: (value: boolean, currentIndex: number) => void;
  isNew?: boolean;
}

export const InternalProviderField: React.FC<InternalProviderFieldProps> = memo(
  ({ index, fieldProps, onRemove, resetLeads, isNew }) => {
    const [providerRef, setProviderRef] =
      useState<IExtendedBasePicker<IPersonaProps> | null>(null);
    useEffect(() => {
      if (providerRef && isNew) {
        providerRef.focusInput();
      }
    }, [providerRef, isNew]);

    const theme = useTheme();

    const { removeLinkStyle } = getPeopleScreenStylesSet(theme);
    return (
      <Stack
        key={index}
        horizontalAlign="space-between"
        horizontal
        tokens={{ childrenGap: 16 }}
      >
        <BookableUserPickerField
          componentRef={ref => {
            if (!providerRef) {
              setProviderRef(ref);
            }
          }}
          label={Labels.internalProvider}
          name={`${patientFormNameOf("internalProvider")}[${index}].userId`}
          filter={{
            statusCodes: [UserStatus.Active]
          }}
          inputProps={{
            placeholder: "Search for provider",
            name: "provider-filter-box"
          }}
          required
          iconName="Search"
          useBusinessRoleClass
          styles={{ root: { width: 400 } }}
          tagInUseKeys={fieldProps.fields?.value?.map(v => v.userId!)}
        />
        <Stack>
          <Heading labelPaddings styles={{ root: { textAlign: "right" } }}>
            <Link
              id={`remove-int-prov-${index}`}
              onClick={onRemove}
              styles={removeLinkStyle}
            >
              {Labels.remove}
            </Link>
          </Heading>
          <ToggleField
            {...dataAttribute(
              DataAttributes.Element,
              `toggle-lead-int-prov-${index}`
            )}
            name={`${patientFormNameOf("internalProvider")}[${index}].lead`}
            onText={Labels.leadProvider}
            offText={Labels.leadProvider}
            alignWithInputs
            styles={{
              root: { marginBottom: 0, minWidth: 150 }
            }}
          />
        </Stack>
        <FieldSpy
          name={`${patientFormNameOf("internalProvider")}[${index}].lead`}
          onChange={(value: boolean) => {
            resetLeads(value, index);
          }}
        />
      </Stack>
    );
  }
);
