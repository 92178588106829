import { observer } from "mobx-react-lite";
import React, { ReactNode, useContext, useRef } from "react";

import { ClaimAdjustmentStatus } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentHelper } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.ts";
import { getClaimFormManagementValues } from "@modules/acc/screens/claim-management/components/utils.tsx";
import { formStyles } from "@modules/acc/screens/claim/components/ClaimForm.styles.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { ClaimFormManagementValues } from "../../claim/types/claim-form-management-values.types.ts";
import { ClaimManagementFormValidator } from "../validators/ClaimManagementFormValidator.tsx";
import { ClaimManagementFormLayout } from "./ClaimManagementFormLayout.tsx";

export interface SubmitOptions {
  redirect: boolean;
  claimStatus?: ClaimAdjustmentStatus;
  lodge?: boolean;
  create?: boolean;
}

const validator = new ClaimManagementFormValidator();

interface ClaimManagementFormProps {
  header: ReactNode;
}

const ClaimManagementFormBase: React.FC<ClaimManagementFormProps> = observer(
  ({ header }) => {
    const { handleSubmitSucceeded, handleSubmitClaimManagement, claim } =
      useContext(ClaimContext);

    const root = useStores();

    const claimAdjustmentHelper = useRef(
      new ClaimAdjustmentHelper(root, claim)
    );

    const initialValues = getClaimFormManagementValues(claim);

    return (
      <SubmissionForm<ClaimFormManagementValues>
        formName="claim-management"
        readOnly={!root.core.hasPermissions(Permission.ClaimWrite)}
        onSubmitSucceeded={values => {
          handleSubmitSucceeded(values);
        }}
        onSubmit={handleSubmitClaimManagement}
        styles={formStyles}
        hideButtons
        initialValues={initialValues}
        validate={validator.validate}
      >
        {() => (
          <ClaimAdjustmentContext.Provider
            value={claimAdjustmentHelper.current}
          >
            <ClaimManagementFormLayout header={header} />
          </ClaimAdjustmentContext.Provider>
        )}
      </SubmissionForm>
    );
  }
);
export const ClaimManagementForm = withFetch(
  x => [x.acc.ref.claimStatuses.load(), x.acc.ref.providerContractTypes.load()],
  ClaimManagementFormBase
);
