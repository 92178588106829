import { ReactNode, useState } from "react";

import {
  FontIcon,
  Heading,
  IconButton,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { LinkButton } from "@ui-components/navigation/LinkButton.tsx";

export interface ClinicalToolInformationPanelProps {
  title: string;
  expandButtonText?: string;
  canExpand?: boolean;
  onRenderContent: (isExpanded: boolean) => ReactNode;
  onRenderOpenTooltip: () => ReactNode;
  onRenderCloseTooltip: () => ReactNode;
  onRenderSummaryMessageBar?: () => ReactNode;
}

export const ClinicalToolInformationPanel: React.FC<
  ClinicalToolInformationPanelProps
> = ({
  title,
  expandButtonText,
  canExpand,
  onRenderContent,
  onRenderOpenTooltip,
  onRenderCloseTooltip,
  onRenderSummaryMessageBar
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const theme = useTheme();

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal verticalAlign="center">
        <Heading variant="section-heading-light">{title}</Heading>
        <TooltipHost content={<>{onRenderOpenTooltip()}</>}>
          <IconButton
            iconProps={{ iconName: "Info", color: theme.palette.accent }}
            onClick={() => {
              setVisible(!visible);
            }}
          />
        </TooltipHost>
      </Stack>

      {onRenderSummaryMessageBar ? onRenderSummaryMessageBar() : null}

      {visible && (
        <Stack
          styles={{
            root: {
              width: "100%",
              padding: 8,
              margin: "8px 0px",
              backgroundColor: theme.palette.neutralLighterAlt
            }
          }}
          tokens={{ childrenGap: 8 }}
        >
          <Stack horizontal horizontalAlign="space-between">
            <Stack styles={{ root: { margin: 8, flex: 1 } }}>
              {onRenderContent(expanded)}
            </Stack>

            <Stack>
              <TooltipHost content={<>{onRenderCloseTooltip()}</>}>
                <IconButton
                  iconProps={{
                    iconName: "Clear"
                  }}
                  styles={{
                    root: { color: theme.palette.black },
                    icon: { fontSize: 10 }
                  }}
                  onClick={() => {
                    setVisible(false);
                  }}
                />
              </TooltipHost>
            </Stack>
          </Stack>

          {canExpand && (
            <Stack styles={{ root: { alignSelf: "end" } }}>
              <LinkButton
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <Text
                  variant="medium"
                  styles={{ root: { color: theme.palette.accent } }}
                >
                  {expanded ? "Collapse" : expandButtonText ?? "Expand"}{" "}
                </Text>

                <FontIcon
                  iconName={
                    expanded ? "DoubleChevronUp12" : "DoubleChevronDown12"
                  }
                  styles={{
                    root: {
                      paddingLeft: 8,
                      color: theme.palette.accent
                    }
                  }}
                />
              </LinkButton>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
