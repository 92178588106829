import React from "react";

import { Grid, Heading, Text } from "@bps/fluent-ui";
import {
  PSFSContextClinicalDataItemDto,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  ClinicalToolAnswer,
  getSliderConstraints
} from "../utils/ClinicalToolAnswerFinder.ts";
import { MAX_INPUT } from "../utils/clinicalToolsUtils.ts";

interface PSFSQuestionProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  context?: PSFSContextClinicalDataItemDto;
  newItemInSeries?: boolean;
}

const textStyles = { root: { paddingTop: 6 } };

export const PSFSQuestions: React.FunctionComponent<PSFSQuestionProps> = ({
  questionnaire,
  answers,
  context,
  newItemInSeries
}) => {
  const parseInputAsString = (value: number) => {
    let returnValue = "0";

    if (value > 0 && value < MAX_INPUT) {
      returnValue = value.toString();
    } else if (value >= MAX_INPUT) {
      returnValue = MAX_INPUT.toString();
    }

    return returnValue;
  };

  return (
    <Grid
      templateColumns="15px auto 90px"
      childrenTokens={{ gap: 8 }}
      styles={{
        root: {
          overflowY: "auto",
          paddingRight: 8
        }
      }}
    >
      <Heading
        hasAsterisk
        labelPaddings
        styles={{ root: { gridColumnStart: "1", gridColumnEnd: "3" } }}
      >
        Activity
      </Heading>
      <Heading hasAsterisk labelPaddings>
        Score
      </Heading>
      {newItemInSeries &&
        !answers &&
        context?.activities.map(activity => {
          const item = questionnaire.items.find(
            q => q.id === activity.questionnaireItemId
          )!;

          const elementName = `q${item.id}`;
          const constraints = getSliderConstraints(item);
          const answer1 = activity.text;

          return (
            <React.Fragment key={item.id}>
              <Text styles={textStyles}>{item.text}</Text>
              <Text styles={textStyles}>{answer1}</Text>
              <SpinNumberInputField
                name={`items.${elementName}a2`}
                {...constraints}
                styles={{ root: { maxWidth: "75px" } }}
                parse={value => parseInputAsString(Number(value))}
              />
            </React.Fragment>
          );
        })}

      {!newItemInSeries &&
        !answers &&
        questionnaire.items.map(item => {
          const elementName = `q${item.id}`;
          const constraints = getSliderConstraints(item);

          return (
            <React.Fragment key={item.id}>
              <Text styles={textStyles}>{item.text}</Text>
              {item.id <= 1 ? (
                <TextInputField
                  name={`items.${elementName}a1`}
                  parse={value => value ?? ""}
                />
              ) : (
                <TextInputField
                  name={`items.${elementName}a1`}
                  placeholder="Optional"
                  parse={value => value ?? ""}
                />
              )}
              <SpinNumberInputField
                name={`items.${elementName}a2`}
                {...constraints}
                styles={{ root: { maxWidth: "75px" } }}
                parse={value =>
                  value === "" ? value : parseInputAsString(Number(value))
                }
              />
            </React.Fragment>
          );
        })}
      {!!answers &&
        answers.map(questionAnswers => {
          const question = questionnaire.items.find(
            q => `q${q.id}` === questionAnswers.questionId
          )!;

          const answer1 = context?.activities.find(
            a => a.questionnaireItemId === question.id
          )?.text;

          const answer2 = questionAnswers.answerValue;

          return (
            <React.Fragment key={question.id}>
              <Text styles={textStyles}>{question.text}</Text>
              <Text styles={textStyles}>{answer1}</Text>
              <Text styles={textStyles}>{answer2}</Text>
            </React.Fragment>
          );
        })}
    </Grid>
  );
};
