import { useForm } from "react-final-form";

import {
  IComboBoxOption,
  SelectableOptionMenuItemType,
  Stack
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { parseAsDigitsOnly } from "../../../shared-components/utils.ts";
import { patientFormNameOf } from "./PatientEditFormValues.tsx";
import { languageImportance } from "./utils.ts";

interface InterpreterNeededFieldProps {
  interpreterNeeded: boolean;
}

export const InterpreterNeededField: React.FC<
  InterpreterNeededFieldProps
> = props => {
  const { practice } = useStores();
  const form = useForm();
  const commonLanguages = practice.ref.languages.values
    .slice()
    .sort((a, b) => languageImportance(a) - languageImportance(b))
    .slice(0, 9)
    .map(m => ({
      key: `${m.code}-common`,
      text: m.text
    }));

  const combinedOptions: IComboBoxOption[] = [
    ...commonLanguages,
    {
      key: "divider",
      text: "-",
      itemType: SelectableOptionMenuItemType.Divider
    },
    {
      key: "HeaderMore",
      text: "More languages",
      itemType: SelectableOptionMenuItemType.Header
    },
    ...practice.ref.languages.keyTextValues
  ];
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 12 }}
      styles={{ root: { height: 62 } }}
    >
      <ToggleField
        alignWithInputs
        name={patientFormNameOf("interpreterNeeded")}
        placeholder="Select language"
        label={Labels.interpreter}
        onText="Needed"
        offText="Not needed"
        styles={{
          root: { marginBottom: 0 }
        }}
      />
      <FieldSpy
        name={patientFormNameOf("interpreterNeeded")}
        onChange={(value: boolean) => {
          if (!value) {
            form &&
              form.change(patientFormNameOf("interpreterLanguage"), undefined);
          }
        }}
      />
      {props.interpreterNeeded && (
        <ComboBoxField
          placeholder="Select language"
          parse={value =>
            value ? parseAsDigitsOnly(value.toString()) : undefined
          }
          label={Labels.language}
          calloutProps={{
            calloutMaxHeight: 500
          }}
          name={patientFormNameOf("interpreterLanguage")}
          options={combinedOptions}
        />
      )}
    </Stack>
  );
};
