import { DATE_FORMATS, DateTime } from "@bps/utils";
import { ServiceRuleType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  greaterThan,
  isNotFutureDate,
  predicate,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { filterInsurers } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { isOrganisation } from "@stores/practice/models/Contact.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export class InvoiceItemListValidator extends Validator<
  InvoiceItemFormValue,
  InvoiceFormValues
> {
  constructor(root: RootStore, isAdjust: boolean) {
    super();

    this.forField("description", [
      predicate((value, values) => !values?.serviceId, required()),
      predicate(
        () => isAdjust,
        (
          value,
          itemValues: InvoiceItemFormValue,
          formValues: InvoiceFormValues
        ) => {
          const invoiceHasAccItems =
            !!formValues.invoiceItems[0].serviceSearch?.rules?.find(
              rule => rule.ruleType === ServiceRuleType.ACC
            );

          const item = formValues.invoiceItems.find(item => {
            const itemIsAcc = !!item.serviceSearch?.rules?.find(
              rule => rule.ruleType === ServiceRuleType.ACC
            );
            return itemIsAcc !== invoiceHasAccItems;
          });

          if (item?.serviceId === itemValues.serviceId) {
            return "An invoice cannot be adjusted to include both subsidy and surcharge items";
          }

          return undefined;
        },
        (
          value,
          itemValues: InvoiceItemFormValue,
          formValues: InvoiceFormValues
        ) => {
          const itemIsAcc = !!itemValues.serviceSearch?.rules?.find(
            rule => rule.ruleType === ServiceRuleType.ACC
          );

          if (!itemIsAcc || !formValues.accountContactId) {
            return undefined;
          }

          const accountContact = root.practice.contactsMap.get(
            formValues.accountContactId
          );

          if (!accountContact) {
            return undefined;
          }

          const isInsurer =
            !!isOrganisation(accountContact.dto) &&
            filterInsurers(accountContact.dto, false);

          return isInsurer
            ? undefined
            : "Bill to contact is not a billable insurer";
        }
      )
    ]);
    this.forField("serviceDate", [
      required(),
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isNotFutureDate()
    ]);
    this.forField("quantity", [required(), greaterThan(0)]);
    this.forField("fee", [
      required(),
      greaterThan(0),
      (value: string, values: InvoiceItemFormValue) => {
        const max = values.serviceSearch?.rules?.find(
          r => r.ruleType === ServiceRuleType.UserDefinedAmount
        )?.max;

        return max && Number(value) > max
          ? ValidationMessages.exceedsMaximumFeeAmount
          : undefined;
      }
    ]);
    this.forField(
      "purchaseOrderNumber",
      predicate(
        (val, values) =>
          !!values?.serviceSearch?.rules?.some(
            r => r.ruleType === ServiceRuleType.PORequired
          ),
        required()
      )
    );

    const hasDuplicateCodes = (
      invoiceItems: InvoiceItemFormValue[],
      serviceId: string
    ) => {
      const matchingInvoiceItems = invoiceItems.filter(
        item => item.serviceId === serviceId
      );
      return matchingInvoiceItems.some((itemA, indexA) => {
        return matchingInvoiceItems.slice(indexA + 1).some(itemB => {
          return DateTime.fromJSDate(itemA.serviceDate).hasSame(
            DateTime.fromJSDate(itemB.serviceDate),
            "day"
          );
        });
      });
    };

    this.forField(
      "code",
      (
        code: string,
        _itemValue: InvoiceItemFormValue,
        formValues: InvoiceFormValues
      ) =>
        hasDuplicateCodes(formValues.invoiceItems, _itemValue.serviceId)
          ? ValidationMessages.invoiceItemsHasMoreTheOnceSameService
          : undefined
    );
  }
}
