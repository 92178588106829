import {
  SignificantConditionDataItemDto,
  SignificantFamilyHistoryDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  Length,
  maxLength,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  FamilyHistoryFormValues,
  familyHistoryNameOf,
  significantConditionNameOf,
  significantFamilyHistoryNameOf
} from "./FamilyHistoryForm.types.ts";

export class FamilyHistoryFormValidator extends Validator<FamilyHistoryFormValues> {
  constructor() {
    const checkAdditionalConditions = (
      val: any,
      values: SignificantFamilyHistoryDataItemDto[]
    ) => {
      if (values) {
        const familyHistoryValidator = new SignificantFamilyHistoryValidator(
          values?.indexOf(val) ?? -1
        );
        return familyHistoryValidator.validate(val);
      }
      return false;
    };

    super();

    this.forField("otherComments", [maxLength(Length.comments)]);
    this.forField("generalFamilyHistoryComment", [maxLength(Length.comments)]);
    this.forArrayField(
      familyHistoryNameOf("additionalParentHistories"),
      (
        val: SignificantFamilyHistoryDataItemDto,
        values: FamilyHistoryFormValues
      ) =>
        checkAdditionalConditions(val, values.additionalParentHistories ?? [])
    );
    this.forArrayField(
      familyHistoryNameOf("additionalFamilyHistories"),
      (
        val: SignificantFamilyHistoryDataItemDto,
        values: FamilyHistoryFormValues
      ) =>
        checkAdditionalConditions(val, values.additionalFamilyHistories ?? [])
    );
  }
}
class SignificantFamilyHistoryValidator extends Validator<SignificantFamilyHistoryDataItemDto> {
  constructor(index: number) {
    super();
    index !== -1 &&
      this.forField(significantFamilyHistoryNameOf("relationship"), required());
    this.forArrayField(
      significantFamilyHistoryNameOf("conditions"),
      (val, values: SignificantFamilyHistoryDataItemDto) => {
        if (values.conditions) {
          const significantConditionValidator =
            new SignificantConditionValidator(
              values.conditions?.indexOf(val) ?? -1
            );
          return significantConditionValidator.validate(val);
        }
        return false;
      }
    );
  }
}
class SignificantConditionValidator extends Validator<SignificantConditionDataItemDto> {
  constructor(index: number) {
    super();
    index !== -1 &&
      this.forField(significantConditionNameOf("diagnosisKey"), required());
    this.forField(significantConditionNameOf("comment"), [
      maxLength(Length.long)
    ]);
  }
}
