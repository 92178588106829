import { DateTime, unique } from "@bps/utils";
import {
  CodedText,
  ConsultEncounterTypes,
  EncounterType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import type { ClosedEncountersFilter } from "@stores/clinical/models/ClinicalRecord.ts";

import { PastVisitsFilterStateInternal } from "./PastVisitsFilter.types.ts";

export const NO_MATCHING_REASON = "9999999999";

export const getReasonForVisit = (
  value: string,
  reasonsForVisit: CodedText[]
) => {
  if (reasonsForVisit.length) {
    let reasons = reasonsForVisit
      .filter(reason =>
        reason.text.toLowerCase().includes(value.toLocaleLowerCase())
      )
      .map(reason => reason.code);

    if (reasons.length === 0) {
      reasons = [NO_MATCHING_REASON];
    }

    return unique(reasons);
  }
  return [];
};

export const mapEncounterFilterArgs = (
  filter: PastVisitsFilterStateInternal,
  reasonsForVisit: CodedText[]
): ClosedEncountersFilter => {
  let encounterTypeCodes = ConsultEncounterTypes;

  if (!filter.showConsultOnly) {
    encounterTypeCodes = Object.values(EncounterType);
  }

  return {
    reasons: !filter.showConsultOnly
      ? []
      : getReasonForVisit(filter.searchReason, reasonsForVisit),
    encounterTypeCodes,
    userIds: filter.providers,
    dateFrom: DateTime.jsDateToISODate(filter.dateFrom),
    dateTo: DateTime.jsDateToISODate(filter.dateTo)
  };
};
