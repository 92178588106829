import { Validator } from "@libs/validation/Validator.ts";

import {
  manualAllocationFormNameOf,
  ManualAllocationFormValues
} from "../shared-components/allocation-field/manual-allocation-modal/ManualAllocationForm.types.tsx";
import { AllocationOption } from "../shared-components/allocation-form/components/AllocationOptionsChoiceFields.tsx";
import { ManualAllocationListValidator } from "./ManualAllocationListValidator.ts";

export class ManualAllocationFormValidator extends Validator<ManualAllocationFormValues> {
  constructor(maxTotalKey: AllocationOption["itemMaxTotalKey"]) {
    super();

    this.forArrayField(
      manualAllocationFormNameOf("allocations"),
      (value, values) => {
        const allocationListValidator = new ManualAllocationListValidator(
          value,
          values,
          maxTotalKey
        );
        return allocationListValidator.validate(value);
      }
    );
  }
}
