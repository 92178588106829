import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { OrgRelationshipFieldValue } from "../EditOrganisationFormValues.tsx";

export class OrgRelationshipValidator extends Validator<OrgRelationshipFieldValue> {
  constructor() {
    super();

    const empty = (x: OrgRelationshipFieldValue) =>
      !x.relatedContactId && !x.professional.length;

    const onlyProf = (x: OrgRelationshipFieldValue) =>
      !empty(x) && !x.org.length;

    this.forField("professional", [
      predicate(
        (val, rels: OrgRelationshipFieldValue) => onlyProf(rels),
        required()
      )
    ]);
  }
}
