import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { IContextualMenuListProps } from "@bps/fluent-ui";
import {
  AppointmentStatusCode,
  CalendarEventPriority
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { enableAppointmentStatusItems } from "@modules/booking/screens/booking-calendar/components/utils.tsx";

import { getAppointmentStatusText } from "../../shared-components/appointment-status/utils.ts";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

interface AppointmentStatusItemProps {
  listProps: IContextualMenuListProps;
  defaultRender:
    | ((props: IContextualMenuListProps) => JSX.Element | null)
    | undefined;
  onDismiss?: () => void;
  changeAppointmentStatus: (
    appointmentStatus: AppointmentStatusCode,
    currentAppointmentStatus?: AppointmentStatusCode | undefined
  ) => Promise<void>;
  currentAppointmentStatus: AppointmentStatusCode;
}

export const AppointmentStatusItem: FunctionComponent<AppointmentStatusItemProps> =
  observer(
    ({
      listProps,
      defaultRender,
      onDismiss,
      changeAppointmentStatus,
      currentAppointmentStatus
    }) => {
      const {
        calendarEvent,
        isStatusUpdating,
        todayAppointment,
        canStatusChange,
        startPatientMatchWorkflow
      } = useContext(BookingCalendarEventContext);

      const statusItems = listProps.items.map(item => ({
        key: item.key,
        text: getAppointmentStatusText(
          item.key,
          item.text!,
          calendarEvent.priority === CalendarEventPriority.Urgent
        ),
        disabled:
          (!isStatusUpdating.get() &&
            enableAppointmentStatusItems(
              currentAppointmentStatus,
              item.key as AppointmentStatusCode,
              todayAppointment && !calendarEvent.hasEncounter
            )) ||
          !canStatusChange,
        onClick: () => {
          changeAppointmentStatus(item.key as AppointmentStatusCode).then(
            () => {
              onDismiss && onDismiss();
              if (calendarEvent.canShowPatientMatchReview)
                startPatientMatchWorkflow();
            }
          );
        }
      }));
      return defaultRender!({ ...listProps, items: statusItems });
    }
  );
