import { sortByKey } from "@libs/utils/utils.ts";
import { AppointmentReminderJob } from "@stores/booking/models/AppointmentReminderJob.ts";

interface SchedulesFilterInternal {
  scheduleType?: string[];
  scheduleName?: string;
}

export type AppointmentReminderJobSorts = Pick<
  AppointmentReminderJob,
  "jobName" | "type" | "status" | "jobSchedule" | "time"
>;

export type SortingStateKeys = keyof AppointmentReminderJobSorts;

export class CommsScheduleScreenHelper {
  public getFilteredSchedules = (
    items: AppointmentReminderJob[],
    filter: SchedulesFilterInternal
  ) => {
    return items?.filter(i => {
      const hasType =
        filter.scheduleType && filter.scheduleType.length
          ? filter.scheduleType.includes(i.type)
          : true;

      const hasName =
        filter.scheduleName && !!filter.scheduleName.trim().length
          ? i.jobName
              .toLowerCase()
              .includes(filter.scheduleName.trim().toLowerCase())
          : true;
      return hasType && hasName;
    });
  };

  public getSortedSchedules = (args: {
    items: AppointmentReminderJob[];
    sortingState: SortingStateKeys | undefined;
    isDescending: boolean | undefined;
  }) => {
    const { isDescending, sortingState, items } = args;

    const clonedItems = Array.from(items);
    if (!sortingState) {
      return items;
    }
    return sortByKey<AppointmentReminderJob>(
      clonedItems,
      sortingState,
      isDescending
    );
  };
}
