import { useField, useForm } from "react-final-form";

import { ActionButtonWithMaxedReached, Stack } from "@bps/fluent-ui";
import {
  SideOfBody,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DiagnosisHeader } from "@modules/acc/screens/shared-components/DiagnosisHeader.tsx";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DiagnosisSideData } from "@shared-types/clinical/diagnosis-side-data.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { TerminologyPickerField } from "../clinical-form/TerminologyPickerField.tsx";
import { ReadCodesHint } from "./ReadCodesHint.tsx";

interface DiagnosisSideFieldsBaseProps {
  name: string;
  maxDiagnosisCount?: number;
  addMoreButton: boolean;
  disabled?: boolean;
}

export const DiagnosisSideFields: React.FC<DiagnosisSideFieldsBaseProps> = ({
  name,
  maxDiagnosisCount,
  addMoreButton,
  disabled
}) => {
  const { meta: formMeta } = useField(name);

  const { clinical } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();

  const form = useForm<SOTAPFormValues>();

  const currentValues = form.getState().values;
  const currentDiagnoses = [
    ...currentValues.primaryDiagnosis,
    ...currentValues.additionalDiagnoses
  ];

  const disableFields =
    disabled || clinicalRecord.dischargeInProgressOrCompleted();

  const isPrimaryDiagnosis = name === "primaryDiagnosis";

  const getSideOptions = (currentValue: DiagnosisSideData) => {
    const sideItems = clinical.ref.sidesOfBody.keyTextValues.map(
      ({ key, text }) => {
        if (key === SideOfBody.Neither) {
          return { key, text: "Not applicable" };
        } else {
          return { key, text };
        }
      }
    );

    let filteredSideItems = [...sideItems];

    if (currentValue.diagnosis) {
      const matchingDiagnosis = currentDiagnoses.filter(
        d => d.diagnosis === currentValue.diagnosis
      );

      if (matchingDiagnosis && matchingDiagnosis.length > 0) {
        matchingDiagnosis.forEach(diagnosis => {
          filteredSideItems = filteredSideItems.filter(i => {
            if (currentValue.side === i.key) {
              return true; // return true if option matches currently selected value
            } else if (i.key !== diagnosis.side) {
              return true; // return true if the option doesn't match a side that has already been selected by another matching diagnosis
            }

            return false;
          });
        });

        if (matchingDiagnosis && matchingDiagnosis.length > 1) {
          filteredSideItems = filteredSideItems.filter(
            i => i.key !== SideOfBody.Neither
          ); // If a side has been picked (L or R), exclude the option to then select Neither
        }
      }
    }

    return filteredSideItems;
  };

  const filterTerminology = (items: TerminologyDto[]) => {
    const filteredItems = items.filter(i => {
      // I'm sure this could be simplified, but left it spelt out like this to make it easier to debug if required
      const hasLeft = currentDiagnoses.some(
        d => d.diagnosis === i.code && d.side === SideOfBody.Left
      );

      const hasRight = currentDiagnoses.some(
        d => d.diagnosis === i.code && d.side === SideOfBody.Right
      );

      const hasBothLeftAndRight = hasLeft && hasRight;
      const hasNA = currentDiagnoses.some(
        d => d.diagnosis === i.code && d.side === SideOfBody.Neither
      );

      const hasNoSide = currentDiagnoses.some(
        d => d.diagnosis === i.code && !d.side
      );

      return !(hasBothLeftAndRight || hasNA || hasNoSide);
    });

    return filteredItems;
  };

  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <Stack tokens={{ childrenGap: 8 }}>
          <Fieldset
            disable={disableFields}
            frame
            styles={{ root: { padding: "0px 0px 8px" } }}
          >
            <DiagnosisHeader
              styles={{
                root: {
                  paddingRight: isPrimaryDiagnosis ? "0px" : "48px"
                }
              }}
              showStatusCol={false}
            />
            {fields.map((itemName, index) => {
              const value = fields.value[index];

              const sideOptions = getSideOptions(value);

              return (
                <Stack
                  key={itemName}
                  styles={{
                    root: {
                      padding: "0px 8px 0px 8px"
                    }
                  }}
                >
                  <Stack
                    tokens={{ childrenGap: formMeta?.data?.readOnly ? 0 : 4 }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                      <Stack
                        grow
                        styles={{
                          root: { width: 0 }
                        }}
                      >
                        <TerminologyPickerField
                          disabled={disableFields}
                          name={`${itemName}.diagnosisKey`}
                          take={50}
                          isDiagnosis={true}
                          terminology={{
                            code: value.diagnosis,
                            originalText: value.originalText,
                            codeSystem: value.codeSystem,
                            version: value.version
                          }}
                          onChange={(id, item) => {
                            if (item) {
                              form.mutators.update(name, index, {
                                ...value,
                                originalText: item?.text,
                                diagnosisKey: clinical.getTerminologyKey(
                                  item?.code,
                                  item?.text
                                ),
                                diagnosis: item?.code,
                                codeSystem: item?.codeSystem,
                                version: item?.version,
                                readCodes: item?.readCodes
                              });
                            } else {
                              if (
                                value.side === undefined ||
                                value.side === ""
                              ) {
                                form.mutators.update(name, index, {});
                              } else {
                                form.mutators.update(name, index, {
                                  side: value.side
                                });
                              }
                            }
                          }}
                          excludeItemsFilter={filterTerminology}
                          validateOnInitialize
                          hint={
                            !!value.diagnosis ? (
                              <ReadCodesHint data={value} />
                            ) : undefined
                          }
                        />
                      </Stack>
                      <Stack
                        styles={{
                          root: { flexBasis: 132, marginLeft: 0 }
                        }}
                      >
                        <DropdownField
                          disabled={disableFields}
                          name={`${itemName}.side`}
                          options={sideOptions}
                          validateOnInitialize
                        />
                        <FieldSpy
                          name={`${itemName}.side`}
                          onChange={side => {
                            if (!!side) {
                              form.mutators.update(name, index, {
                                ...value,
                                side
                              });
                            } else {
                              if (value.diagnosis === undefined) {
                                form.mutators.update(name, index, {});
                              } else {
                                form.mutators.update(name, index, {
                                  ...value,
                                  side: undefined
                                });
                              }
                            }
                          }}
                        />
                      </Stack>
                      {!isPrimaryDiagnosis && (
                        <Stack
                          styles={{
                            root: {
                              flexBasis: 34
                            }
                          }}
                        >
                          <DeleteButton
                            onClick={() => {
                              fields.remove(index);
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </Fieldset>
          {addMoreButton && !disableFields && (
            <ActionButtonWithMaxedReached
              styles={{ root: { alignSelf: "baseline" } }}
              text="Add another diagnosis"
              onClick={() => fields.push({})}
              maxCountReached={
                !!maxDiagnosisCount && fields.length === maxDiagnosisCount
              }
            />
          )}
        </Stack>
      )}
    </FieldArray>
  );
};
