import {
  PurposeType,
  SubmitActionCode
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { DocumentStore } from "@stores/documents/DocumentStore.ts";

import { TemplateWriterValues } from "./TemplateWriterValues.ts";

export class TemplateWriterValidator extends Validator<TemplateWriterValues> {
  constructor(
    document: DocumentStore,
    initValues: TemplateWriterValues,
    options: {
      isEditAsCopy?: boolean;
      userName?: string;
    }
  ) {
    super();
    this.forField("name", required());
    this.forField(
      "documentType",
      predicate(
        (val, values) => values?.purpose === PurposeType.DOC,
        required()
      )
    );
    this.forField("shortcut", [
      predicate(
        (val, values) => values?.purpose === PurposeType.AUTO,
        required(),
        maxLength(20),
        (val: string) => {
          // Allow characters, numbers, _ and - only
          const regex = new RegExp("^[a-zA-Z0-9_-]+$");
          return regex.test(val) ? undefined : ValidationMessages.allowShortcut;
        },
        (val: string) => {
          const { isEditAsCopy, userName } = options;
          const upperCaseShortcut = val.toLocaleUpperCase();
          // Has not changed the shortcut?
          if (initValues.shortcut === val && !isEditAsCopy) return undefined;

          if (isEditAsCopy) {
            const isDuplicateShortCutWithSameUser = Array.from(
              document.templateMap.values()
            ).some(
              x =>
                x.shortcut?.toLocaleUpperCase() === upperCaseShortcut &&
                x.documentStatus === SubmitActionCode.PublishToSelfCode &&
                x.createdBy === userName
            );

            return isDuplicateShortCutWithSameUser
              ? messageWithData(
                  ValidationMessages.duplicatedShortcut,
                  upperCaseShortcut
                )
              : undefined;
          }
          // Check duplicate shortcut
          return Array.from(document.templateMap.values()).some(
            x => x.shortcut?.toLocaleUpperCase() === upperCaseShortcut
          )
            ? messageWithData(
                ValidationMessages.duplicatedShortcut,
                upperCaseShortcut
              )
            : undefined;
        }
      )
    ]);

    this.forField(
      "templateState",
      predicate(
        (val, values) => values?.purpose === PurposeType.DOC,
        required()
      )
    );
    this.forField(
      "availability",
      predicate(
        (val, values) => values?.purpose === PurposeType.AUTO,
        required()
      )
    );
    this.forField("visibility", required());
    this.forField(
      "publicOption",
      predicate(
        (val, values) =>
          values?.purpose === PurposeType.DOC &&
          values?.templateState !== SubmitActionCode.DraftCode,
        required()
      )
    );
  }
}
