import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  FontSizes,
  Heading,
  Link,
  NoDataTile,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { SideNavContent } from "@modules/clinical/screens/patient-record/components/SideNavContent.tsx";

import { SyncFusionDocumentEditorContext } from "./context/SyncFusionDocumentEditorContext.tsx";
import { TemplateNode } from "./context/SyncFusionDocumentEditorHelper.ts";
import { MergeFieldPreviewCallout } from "./MergeFieldPreviewCallout.tsx";
import { SyncFusionMergeFieldsFilter } from "./SyncFusionMergeFieldsFilter.tsx";

export const SyncFusionMergeFields: React.FC = observer(() => {
  const {
    handleMergeFieldOnClick,
    filteredGroups,
    filteredItems,
    onSearch,
    filterText,
    isTemplateEditor
  } = useContext(SyncFusionDocumentEditorContext);

  const columns = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 190,
      maxWidth: 190,
      isResizable: true,
      onRender: (node: TemplateNode) => {
        return node.value ? (
          <Link
            styles={{
              root: {
                fontSize: FontSizes.size14
              }
            }}
            onClick={() =>
              node && handleMergeFieldOnClick(node, !!isTemplateEditor)
            }
          >
            {node.text}
          </Link>
        ) : (
          <Text>{node.text}</Text>
        );
      }
    },
    {
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      maxWidth: 100,
      onRender: (node: TemplateNode, index: number) => {
        if (isTemplateEditor) {
          return <MergeFieldPreviewCallout index={index} node={node} />;
        }

        return node.value ? (
          <MergeFieldPreviewCallout index={index} node={node} />
        ) : (
          <TextBadge
            badgeColor={TextBadgeColor.lightGrey}
            badgeSize={TextBadgeSize.small}
            styles={{
              root: {
                width: 55
              }
            }}
          >
            No data
          </TextBadge>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <Stack>
        <Heading variant="section-heading-light">Merge fields</Heading>
      </Stack>

      <Stack>
        <SideNavContent>
          <SyncFusionMergeFieldsFilter onSearch={onSearch} />
          {filteredGroups?.length > 0 && filteredItems.length > 0 ? (
            <DetailsList
              layoutMode={DetailsListLayoutMode.fixedColumns}
              compact={true}
              groups={filteredGroups}
              items={filteredItems}
              columns={columns}
              groupProps={{}}
              styles={{
                root: {
                  overflow: "auto",
                  overflowX: "hidden",
                  maxHeight: "calc(100vh - 240px)"
                }
              }}
            />
          ) : (
            <NoDataTile
              textProps={{
                text: filterText
                  ? `No matches found for '${filterText}'.`
                  : "No matches found."
              }}
              linkProps={{ hidden: true }}
            />
          )}
        </SideNavContent>
      </Stack>
    </React.Fragment>
  );
});
