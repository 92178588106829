import { action, observable } from "mobx";

import {
  GetStatementArgs,
  ItemType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Statement } from "@stores/billing/models/Statement.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export enum ViewKeys {
  accounts = "accounts",
  statements = "statements"
}
export class AccountScreenHelper {
  constructor(
    private root: IRootStore,
    public accountContact: Contact
  ) {}

  private get routing() {
    return this.root.routing;
  }

  getStatements = async (query: GetStatementArgs) => {
    const result = await this.root.billing.getStatements({
      ...query,
      total: true
    });

    return result;
  };

  @observable modalInvoiceId: string | undefined;

  @action
  setModalId = (value: string | undefined, itemType: ItemType) => {
    switch (itemType) {
      case ItemType.Invoice:
        return this.setModalInvoiceId(value);
      case ItemType.Allocation:
        return this.setModalAllocationId(value);
      case ItemType.WriteOff:
        // We will create a write modal in a separate story. Navigating to the write-off page is a temporary solution for now.
        return (
          value &&
          this.routing.push({
            pathname: routes.accounts.invoices.writeOff.viewPath.path({
              id: value
            })
          })
        );
      default:
        throw Error(`Unsupported transaction type for view: ${itemType}`);
    }
  };

  @action setModalInvoiceId = (value: string | undefined) => {
    this.modalInvoiceId = value;
  };

  onDismissModalInvoice = () => {
    this.setModalInvoiceId(undefined);
  };
  onSubmitModalInvoice = () => {
    this.setModalInvoiceId(undefined);
  };

  @observable modalAllocationId: string | undefined;

  @action setModalAllocationId = (value: string | undefined) => {
    this.modalAllocationId = value;
  };

  onDismissModalAllocation = () => {
    this.setModalAllocationId(undefined);
  };

  onSubmitModalAllocation = () => {
    this.setModalAllocationId(undefined);
  };

  @observable
  isStatementPrinting: boolean;

  @action
  setIsStatementPrinting = (value: boolean) => {
    this.isStatementPrinting = value;
  };

  @observable
  selectedStatement: Statement | undefined;

  @action
  setSelectedStatement = (value: Statement | undefined) => {
    this.selectedStatement = value;
  };

  @observable
  selectedViewKey: ViewKeys | undefined = ViewKeys.accounts;

  @action
  setSelectedView = (value: ViewKeys | undefined) => {
    this.selectedViewKey = value;
  };
}
