import { observer } from "mobx-react-lite";
import { FunctionComponent, ReactNode, useEffect, useRef } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  FontWeights,
  Heading,
  ITextField,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  CorrespondenceType,
  PurposeType,
  SubmitActionCode,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { TemplateManagementFormModel } from "../context/TemplateManagementFormModel.ts";
import { TemplateWriterHelper } from "../context/TemplateWriterHelper.ts";
import { getDuplicateAutoFillInitialValues } from "../utils.ts";
import { TemplateWriterValidator } from "./TemplateWriterValidator.ts";
import { TemplateWriterValues } from "./TemplateWriterValues.ts";

interface TemplateWriterDialogProps {
  isDialogHidden: boolean;
  onDismiss: () => void;
  model: TemplateManagementFormModel | TemplateWriterHelper;
}

const nameOf = nameOfFactory<TemplateWriterValues>();

export const TemplateWriterDialog: FunctionComponent<TemplateWriterDialogProps> =
  observer((props: TemplateWriterDialogProps) => {
    const { correspondence, document, core } = useStores();
    const { isDialogHidden, onDismiss, model } = props;
    const isCreateTemplate = model.isCreateTemplate;
    const form = useForm<TemplateWriterValues>();
    const { values } = useFormState<TemplateWriterValues>();

    const theme = useTheme();
    const nameTextField = useRef<ITextField | null>(null);

    useEffect(() => {
      if (isDialogHidden) return;

      const timeout = window.setTimeout(() => {
        nameTextField.current?.focus();
      }, 100);

      return () => window.clearTimeout(timeout);
    }, [isDialogHidden]);

    let currentTemplate: Template;
    let isEditAsCopy: boolean = (model as TemplateManagementFormModel)
      .isEditAsCopy;

    let selectedTempalte = (model as TemplateManagementFormModel)
      .selectedTemplate;

    let isFromTemplateWriter: boolean = false;

    if (model instanceof TemplateWriterHelper) {
      currentTemplate = model.currentTemplate;
      isEditAsCopy = model.isEditAsCopy;
      selectedTempalte = model.currentTemplate;
      isFromTemplateWriter = true;
    }

    const editAsInitialValues = selectedTempalte
      ? getDuplicateAutoFillInitialValues(selectedTempalte)
      : undefined;

    const validator = new TemplateWriterValidator(document, values, {
      isEditAsCopy,
      userName: core.user?.username
    });

    if (isDialogHidden) return null;

    const getHeading = () => {
      if (isCreateTemplate) {
        return "New template";
      } else if (isEditAsCopy && !isFromTemplateWriter) {
        return `New template - ${editAsInitialValues?.name}`;
      } else {
        return "Edit template details";
      }
    };

    const defaultTemplateState = model.isCreateTemplate
      ? SubmitActionCode.DraftCode
      : values.templateState;

    const initialValues = isEditAsCopy
      ? editAsInitialValues
      : {
          ...values,
          templateState: defaultTemplateState,
          purpose: model.isFromAutofillMgmt ? PurposeType.AUTO : PurposeType.DOC
        };

    const submitButtonProps = model.submitButtonProps;

    const onSubmit = (val: TemplateWriterValues) => {
      const isAutofill = val.purpose === PurposeType.AUTO;
      const getDocumentStatus = (val: TemplateWriterValues) => {
        // Is it Autofill?
        if (isAutofill) return val.availability;

        // Is it new template?
        if (isCreateTemplate) return SubmitActionCode.DraftCode;

        // Get the template state if the document is draft, otherwise the public option.
        return val.templateState === SubmitActionCode.DraftCode
          ? val.templateState
          : val.publicOption;
      };

      const documentStatus = getDocumentStatus(val);
      if (isCreateTemplate || isEditAsCopy) {
        document.createWriterTemplate(
          isAutofill ? CorrespondenceType.Autofill : val.documentType,
          {
            documentTitle: val.name,
            documentStatus,
            shortcut: val.shortcut,
            documentContent: values.documentContent
          },
          {
            visibility: val.visibility,
            writerTemplate: selectedTempalte,
            isSaveAsCopy: model.isEditAsCopy,
            isFromTemplateWriter
          }
        );
      } else {
        if (documentStatus) val.documentStatus = documentStatus;

        form.batch(() => {
          Object.entries(val).forEach(formValuesEntry => {
            const [key, value] = formValuesEntry;
            form.change(key as keyof TemplateWriterValues, value);
          });
        });
      }
    };

    const getIsDraftDisable = () => {
      if (currentTemplate) {
        return currentTemplate.documentStatus !== SubmitActionCode.DraftCode;
      }
      return false;
    };

    const renderForm = (): ReactNode => {
      return (
        <Stack
          styles={{
            root: {
              minWidth: 600
            }
          }}
        >
          <When permission={Permission.AutofillAllowed}>
            <Fieldset styles={{ root: { marginBottom: 8 } }}>
              <ButtonsGroupSingleChoiceField
                label="Purpose"
                name={nameOf("purpose")}
                notUnselectable
                options={[
                  { key: PurposeType.DOC, text: "Document" },
                  { key: PurposeType.AUTO, text: "Autofill" }
                ]}
              />
            </Fieldset>
          </When>
          <Fieldset horizontal>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <TextInputField
                name={nameOf("name")}
                label="Name"
                required={true}
                componentRef={ref => {
                  nameTextField.current = ref;
                }}
                validateOnInitialize={isEditAsCopy}
              />
            </Stack.Item>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <FieldCondition when={nameOf("purpose")} is={PurposeType.DOC}>
                <StaticPickerField
                  name={nameOf("documentType")}
                  label="Document type"
                  fetchDataSource={() =>
                    correspondence.ref.correspondenceTypes.fetchAsKeyNameValues()
                  }
                  required={true}
                />
              </FieldCondition>
              <FieldCondition when={nameOf("purpose")} is={PurposeType.AUTO}>
                <TextInputField
                  name={nameOf("shortcut")}
                  label="Shortcut"
                  required={true}
                  validateOnInitialize={isEditAsCopy}
                />
              </FieldCondition>
            </Stack.Item>
          </Fieldset>
          <FieldCondition
            when={nameOf("shortcut")}
            is={selectedTempalte?.shortcut?.toLocaleUpperCase()}
          >
            <MessageBar
              styles={{
                root: {
                  marginTop: 8
                }
              }}
              messageBarType={MessageBarType.warning}
            >
              {`Existing shortcut: ${selectedTempalte?.name} — ${selectedTempalte?.shortcut}. Choose another combination
              or proceed to override usage priority (the latest saved autofill
              under this shortcut will be used)`}
            </MessageBar>
          </FieldCondition>

          <Fieldset horizontal styles={{ root: { marginTop: 8 } }}>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <FieldCondition when={nameOf("purpose")} is={PurposeType.DOC}>
                <ButtonsGroupSingleChoiceField
                  disabled={isCreateTemplate}
                  label="State"
                  name={nameOf("templateState")}
                  required
                  options={[
                    {
                      key: SubmitActionCode.DraftCode,
                      text: "Draft",
                      disabled: getIsDraftDisable()
                    },
                    { key: "Published", text: "Published" }
                  ]}
                />
                <FieldCondition when={nameOf("templateState")} is="Published">
                  <ButtonsGroupSingleChoiceField
                    styles={{ root: { marginTop: "8px" } }}
                    name={nameOf("publicOption")}
                    options={[
                      {
                        key: SubmitActionCode.PublishToSelfCode,
                        text: "Only to me"
                      },
                      {
                        key: SubmitActionCode.PublishToEveryoneCode,
                        text: "Publish to practice"
                      }
                    ]}
                  />
                </FieldCondition>
                <FieldCondition
                  when={nameOf("templateState")}
                  is={SubmitActionCode.DraftCode}
                >
                  <Text
                    styles={{
                      root: {
                        fontSize: theme.fonts.smallPlus.fontSize,
                        color: theme.palette.neutralSecondary,
                        fontWeight: FontWeights.regular,
                        paddingTop: 5
                      }
                    }}
                  >
                    Draft templates cannot be used to create documents or
                    letters
                  </Text>
                </FieldCondition>
              </FieldCondition>
              <FieldCondition when={nameOf("purpose")} is={PurposeType.AUTO}>
                <ButtonsGroupSingleChoiceField
                  label="Availability"
                  name={nameOf("availability")}
                  validateOnInitialize={isEditAsCopy}
                  required
                  options={[
                    {
                      key: SubmitActionCode.PublishToSelfCode,
                      text: "Private"
                    },
                    {
                      key: SubmitActionCode.PublishToEveryoneCode,
                      text: "Shared"
                    }
                  ]}
                />
              </FieldCondition>
            </Stack.Item>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <ButtonsGroupSingleChoiceField
                name={nameOf("visibility")}
                label="Visibility"
                required
                validateOnInitialize={isEditAsCopy}
                options={[
                  {
                    key: TemplateVisibility.Clinical,
                    text: "Clinical"
                  },
                  {
                    key: TemplateVisibility.Admin,
                    text: "Admin"
                  },
                  {
                    key: TemplateVisibility.Both,
                    text: "Both"
                  }
                ]}
              />
            </Stack.Item>
          </Fieldset>
        </Stack>
      );
    };

    return (
      <SubmissionFormDialog
        onSubmit={(formValues: TemplateWriterValues) => {
          onSubmit(formValues);
        }}
        dialogName="Write template dialog"
        onSubmitSucceeded={onDismiss}
        initialValues={initialValues}
        dialogProps={{
          maxWidth: 650,
          styles: { root: { width: "100%" } },
          onDismiss,
          dialogContentProps: {
            title: <Heading variant="modal-heading">{getHeading()}</Heading>,
            showCloseButton: true
          }
        }}
        buttonsProps={{
          submitButtonProps,
          disableSubmitOnFormInvalid: true,
          disableSubmitOnPristine: false
        }}
        validate={validator.validate}
        autoFocus={false}
      >
        {renderForm}
      </SubmissionFormDialog>
    );
  });
