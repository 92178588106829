import { ReactNode } from "react";

import { Spinner } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ConditionModalFormValues } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/Condition.types.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClaimFormLabels } from "../claim/components/ClaimFormEnums.ts";

interface ConditionHelperDataFetcherProps {
  claim: Claim;
  showModal?: boolean;
  children: (helper: ConditionHelper) => ReactNode;
}

export const ClaimConditionHelperDataFetcher: React.FC<
  ConditionHelperDataFetcherProps
> = ({ claim, showModal, children }) => {
  const root = useStores();
  const { acc } = root;

  const getCondition = async (): Promise<ConditionHelper> => {
    const claimEpisodesOfCare = await acc.getClaimEpisodesOfCare({
      claimId: claim.id
    });

    let condition: ICondition | undefined;

    if (claimEpisodesOfCare.length) {
      condition = {
        episodeOfCareId: claimEpisodesOfCare[0].episodeOfCareId,
        patientId: claim.patientId,
        providerId: claim.providerId,
        primaryDiagnosis:
          claim.primaryDiagnosis?.terminology?.text ??
          ClaimFormLabels.undiagnosed,
        injuryDate: claim.accidentDate,
        discharged: claim.isDischarged,
        isPrivate: claim.private ?? false,
        createdDate: DateTime.fromISO(claim.dto.changeLog?.createdDate)!,
        sendAccForm: claim.sendAccForm,
        isReferral: claim.referralIn,
        referralDate: claim.referralDate?.startOf("day").toJSDate(),
        referralNumber: claim.claimNumber,
        referralProvider: claim.referralProvider,
        claim
      };
    }

    await claim.loadClaimSchedules();

    const conditionHelper = new ConditionHelper(root, claim.patientId!, {
      onSubmit: async (
        values: ConditionModalFormValues,
        helper: ConditionHelper
      ) => {
        await helper.updateClaimCondition(
          values,
          values.referralIn ? claim.providerId : undefined
        );
      },
      initialCondition: condition
    });

    if (showModal) {
      conditionHelper.setHiddenConditionModal(false);
    }

    return conditionHelper;
  };

  return (
    <DataFetcher<ConditionHelper> fetch={getCondition} fallback={<Spinner />}>
      {helper => children(helper)}
    </DataFetcher>
  );
};
