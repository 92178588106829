import { DateTime } from "@bps/utils";
import { ProviderTypeCode } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isDateAfterDate,
  lessThan,
  predicate,
  required,
  requiredBoolean
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { ContractType } from "@stores/practice/models/Provider.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";

export class TreatmentDetailsFormValidator extends Validator<ClaimAdjustmentFormValues> {
  constructor(claimAdjustment: ClaimAdjustment, practice?: PracticeStore) {
    super();

    const dateLessThanOneWeek = DateTime.now().minus({ days: 7 });

    if (claimAdjustment.hasTreatmentDetails) {
      this.forField("followUpTreatmentsNeeded", [
        predicate(
          (val, values) =>
            values?.providerContractType ===
              ContractType.HandTherapistAlliedHealth ||
            values?.providerContractType ===
              ContractType.PhysiotherapistAlliedHealth ||
            values?.providerContractType ===
              ContractType.OccupationalTherapistAlliedHealth,
          (value: number | undefined) => {
            if (typeof value === "number") {
              return value > 99
                ? messageWithData(ValidationMessages.lessThan, 99)
                : undefined;
            }
            return ValidationMessages.required;
          }
        )
      ]);

      this.forField(nameof("treatmentStartDate"), [
        required(),
        isDateAfterDate(
          dateLessThanOneWeek,
          "This date is more than a week ago. Please enter a valid date"
        )
      ]);
      this.forField("initialAssessmentsNeeded", [
        predicate(
          (val, values) =>
            values?.providerContractType ===
              ContractType.HandTherapistAlliedHealth ||
            values?.providerContractType ===
              ContractType.PhysiotherapistAlliedHealth ||
            values?.providerContractType ===
              ContractType.OccupationalTherapistAlliedHealth,
          (value: number) => {
            if (!value) {
              return !Number.isInteger(value)
                ? ValidationMessages.required
                : undefined;
            } else {
              return value > 1
                ? messageWithData(ValidationMessages.lessThan, "1")
                : undefined;
            }
          }
        )
      ]);
      this.forField(nameof("firstAdditionalTreatmentRequested"), [
        requiredBoolean()
      ]);

      this.forField("treatmentsRequired", [
        predicate(
          (val, values) => {
            const providerContractType =
              practice?.ref.accProviderContractTypes.values.find(
                x => values?.providerContractType === x.code
              );
            return (
              providerContractType?.accPracticeContractTypeCode ===
              ProviderTypeCode.specifiedTreatmentProvider
            );
          },
          (value: number) => {
            if (!value) {
              return !Number.isInteger(value)
                ? ValidationMessages.required
                : undefined;
            } else {
              return value > 99
                ? messageWithData(ValidationMessages.lessThan, 99)
                : undefined;
            }
          }
        )
      ]);

      this.forField("handSplintingCost", [
        predicate(
          (val, values) =>
            values?.providerContractType ===
              ContractType.HandTherapistAlliedHealth ||
            values?.providerContractType ===
              ContractType.OccupationalTherapistAlliedHealth,
          predicate(x => x !== 0, required()) // treat zero as value entered
        )
      ]);

      this.forField(nameof("nailRemovalResectionTreatmentsNeeded"), [
        lessThan(11)
      ]);
      this.forField(nameof("nailSimpleRemovalTreatmentsNeeded"), [
        lessThan(11)
      ]);
      this.forField(nameof("abscessHematomaTreatmentsNeeded"), [lessThan(11)]);
    }
  }
}
