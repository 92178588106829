import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface InteractionTimeLineFilterState {
  search: string;
  types: string[];
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
}

export interface IInteractionTimeLineFilterProps {
  onSearch: (filter: InteractionTimeLineFilterState) => void;
}

export const interactionTimeFilterNameOf =
  nameOfFactory<InteractionTimeLineFilterState>();
