export enum PhysicalActivityFormLabels {
  namePlaceHolder = "Search for an activity",
  name = "Name",
  level = "Level",
  sessionsPerWeek = "Sessions per week",
  sessionLength = "Session length(mins)"
}

export enum PhysicalActivity {
  other = "OTH",
  outdoorCricker = "OUTDOORCRICKET",
  boating = "BOATING"
}
