import { DATE_FORMATS } from "@bps/utils";
import {
  predicate,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  AttachCorrespondenceFileValue,
  CorrespondenceFormValue
} from "../clinical-form/DocumentBatchFormValues.ts";

export class CorrespondenceFormValidator extends Validator<CorrespondenceFormValue> {
  constructor(isEdit: boolean) {
    super();
    const fileMetadataValidator = new FileMetaDataValidator(isEdit);
    this.forField("type", [required()]);
    this.forField("file", fileMetadataValidator.validate);
    this.forField("direction", [required()]);
  }
}

export class FileMetaDataValidator extends Validator<AttachCorrespondenceFileValue> {
  constructor(isEdit: boolean) {
    super();

    this.forField("date", [
      required(),
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT)
    ]);

    this.forField("subject", [required()]);

    this.forField(
      "blobName",
      predicate(() => !isEdit, required())
    );
  }
}
