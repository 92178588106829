import { DATE_FORMATS } from "@bps/utils";
import {
  predicate,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { InvoiceItemListValidator } from "./InvoiceItemListValidator.ts";

export class InvoiceFormValidator extends Validator<InvoiceFormValues> {
  constructor(root: RootStore, isAdjust: boolean) {
    super();
    const itemListValidator = new InvoiceItemListValidator(root, isAdjust);
    this.forField("invoiceNumber", required());
    this.forField("invoiceDate", [
      required(),
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT)
    ]);

    this.forField("billType", required());
    this.forField(
      "invoiceItems",
      predicate(
        (items: InvoiceItemFormValue[]) => items && items.length === 0,
        required(ValidationMessages.invoiceItemsRequired)
      )
    );

    this.forArrayField("invoiceItems", itemListValidator.validate);
  }
}
