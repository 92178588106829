// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { useField, useFormState } from "react-final-form";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { DrawingCanvas } from "../../notes/draw/DrawingCanvas.tsx";
import { InjuryImagesHelper } from "../context/InjuryImagesHelper.tsx";
import { sotapNameOf } from "../SOTAP.utils.ts";

enum KneeImageType {
  frontKneeImage = "0",
  backKneeImage = "1",
  leftSideKneeImage = "2",
  rightSideKneeImage = "3"
}

export const KneeInjuryImageAssessment: React.FC = () => {
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const sex = clinicalRecord.patient?.sex;

  const {
    input: { onChange: onFrontKneeImage }
  } = useField(sotapNameOf("frontKneeImage"));

  const {
    input: { onChange: onBackKneeImage }
  } = useField(sotapNameOf("backKneeImage"));

  const {
    input: { onChange: onLeftSideKneeImage }
  } = useField(sotapNameOf("leftSideKneeImage"));

  const {
    input: { onChange: onRightSideKneeImage }
  } = useField(sotapNameOf("rightSideKneeImage"));

  const kneeProps = InjuryImagesHelper.getKneeProperties(sex);
  const images = InjuryImagesHelper.mapImagesOnPart(kneeProps, initialValues);

  const handleOnChange = (
    canvas: Canvas,
    event: any,
    onChangeFunc: (x: any) => void
  ) => {
    if (event.target.dirty) {
      onChangeFunc(canvas?.toSVG());
    } else {
      onChangeFunc(undefined);
    }
  };
  return (
    <DrawingCanvas
      isViewOnly={
        isViewOnlyOrDischarged ||
        clinicalRecord.dischargeInProgressOrCompleted()
      }
      showQuickColourPicker={!isViewOnlyOrDischarged}
      initialSelectedImage={0}
      caption="Diagram"
      images={images}
      onChange={(canvas: Canvas, event: any) => {
        switch (canvas["lowerCanvasEl"].id as KneeImageType) {
          case KneeImageType.frontKneeImage:
            handleOnChange(canvas, event, onFrontKneeImage);
            break;
          case KneeImageType.backKneeImage:
            handleOnChange(canvas, event, onBackKneeImage);
            break;
          case KneeImageType.leftSideKneeImage:
            handleOnChange(canvas, event, onLeftSideKneeImage);
            break;
          case KneeImageType.rightSideKneeImage:
            handleOnChange(canvas, event, onRightSideKneeImage);
            break;

          default:
            return;
        }
      }}
    />
  );
};
