import { FunctionComponent } from "react";
import { useField, useForm } from "react-final-form";

import { Pivot, PivotItem } from "@bps/fluent-ui";
import {
  InjuryArea,
  InjuryAreaSides
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { PivotLabels } from "../SOTAP.constants.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";
import { InjuryAreaMotionAssessmentFields } from "./InjuryAreaMotionAssessment.tsx";
import { getPivotIcon } from "./utils.ts";

interface SidePivotsProps {
  injuryArea: InjuryArea;
}
export const SidePivots: FunctionComponent<SidePivotsProps> = ({
  injuryArea
}) => {
  const {
    input: { value: injuryAreaMotionAssessments }
  } = useField<InjuryAreaMotionAssessmentItem[] | undefined>(
    sotapNameOf("injuryAreaMotionAssessments"),
    {
      subscription: { value: true }
    }
  );

  const { getState } = useForm<SOTAPFormValues>();
  const initialInjuryAreaMotionAssessments =
    getState().initialValues.injuryAreaMotionAssessments;

  const icon = getPivotIcon(
    injuryAreaMotionAssessments,
    initialInjuryAreaMotionAssessments
  );

  return (
    <Pivot styles={{ itemContainer: { marginTop: 16 } }}>
      <PivotItem
        alwaysRender
        headerText={PivotLabels.Left}
        key={InjuryAreaSides.Left}
        itemIcon={icon({
          injuryArea,
          injuryAreaGroup: injuryArea,
          injuryAreaSide: InjuryAreaSides.Left
        })}
      >
        <InjuryAreaMotionAssessmentFields
          name={sotapNameOf("injuryAreaMotionAssessments")}
          injuryArea={injuryArea}
          injuryAreaGroup={injuryArea}
          injurySide={InjuryAreaSides.Left}
        />
      </PivotItem>

      <PivotItem
        alwaysRender
        headerText={PivotLabels.Right}
        key={InjuryAreaSides.Right}
        itemIcon={icon({
          injuryArea,
          injuryAreaGroup: injuryArea,
          injuryAreaSide: InjuryAreaSides.Right
        })}
      >
        <InjuryAreaMotionAssessmentFields
          name={sotapNameOf("injuryAreaMotionAssessments")}
          injuryArea={injuryArea}
          injuryAreaGroup={injuryArea}
          injurySide={InjuryAreaSides.Right}
        />
      </PivotItem>
    </Pivot>
  );
};
