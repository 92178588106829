import { DateTime } from "@bps/utils";
import {
  AddressDto,
  AddressType,
  OrganisationContactDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { getValueWithUnits } from "@libs/utils/utils.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import {
  Contact,
  orgRolesToEmployer,
  orgRolesToPrivateInsurer,
  orgRolesToPublicInsurer
} from "@stores/practice/models/Contact.ts";

export const filterInsurers = (
  organisation: OrganisationContactDto,
  accOnly: boolean
) => {
  if (
    !organisation.organisationRoles ||
    organisation.organisationRoles.length === 0
  )
    return false;

  const employer = orgRolesToEmployer(organisation.organisationRoles!);
  const privateInsurer = orgRolesToPrivateInsurer(
    organisation.organisationRoles!,
    accOnly
  );

  const publicInsurer = orgRolesToPublicInsurer(
    organisation.organisationRoles!
  );

  const accreditedEmployerNotPrivate: boolean =
    !!employer &&
    !!employer.accreditedEmployer &&
    !employer.privateInsurerContactId;

  if (accOnly) {
    return !!privateInsurer || !!publicInsurer;
  } else {
    return accreditedEmployerNotPrivate || !!privateInsurer || !!publicInsurer;
  }
};
export const getAgeString = (
  date: DateTime,
  dateOfDeath: DateTime | undefined
): string => {
  const endDate = dateOfDeath ? dateOfDeath : DateTime.now();
  const diffYears = endDate.diff(date, "years");
  const diffMonths = endDate.diff(date, "months");
  const diffWeeks = endDate.diff(date, "weeks");
  const diffDays = endDate.diff(date, "days");
  const years = Math.trunc(diffYears.years);
  const weeks = Math.trunc(diffWeeks.weeks);
  const days = Math.trunc(diffDays.days);
  const months = Math.trunc(diffMonths.months);

  if (years >= 2) {
    return `${years} years`;
  } else {
    if (days <= 14) {
      return `${getValueWithUnits(days, "day")} `;
    } else if (weeks <= 13) {
      return `${getValueWithUnits(weeks, "week")} `;
    } else {
      return `${getValueWithUnits(months, "month")}`;
    }
  }
};
export const getAddressType = (value: AddressFieldValue): AddressType => {
  if (value.types && value.types.length > 0) {
    if (
      value.types?.includes(AddressType.Physical) &&
      value.types?.includes(AddressType.Postal)
    ) {
      return AddressType.Both;
    } else if (
      value.types?.includes(AddressType.Physical) &&
      !value.types?.includes(AddressType.Postal)
    ) {
      return AddressType.Physical;
    } else if (
      !value.types?.includes(AddressType.Physical) &&
      value.types?.includes(AddressType.Postal)
    ) {
      return AddressType.Postal;
    } else {
      // just in case return Physical by default however the type is required and cannot be undefined
      return AddressType.Physical;
    }
  } else {
    return value.type!;
  }
};
export const toAddressDto = (
  addresses: AddressFieldValue[] | undefined
): AddressDto[] => {
  if (!addresses) {
    return [];
  }
  return addresses
    .filter(a => a.street1)
    .map<AddressDto>(add => ({
      city: add.city,
      type: getAddressType(add),
      street1: add.street1,
      street2: add.street2,
      suburb: add.suburb,
      state: add.state,
      postCode: add.postCode,
      country: add.country
    }));
};
export const getAddressFormValueFromContact = (contact?: Contact): string => {
  const address = contact && contact.defaultAddress;
  return address ? JSON.stringify(address) : "";
};
export const extractName = (value?: string) => {
  if (!value) {
    return {};
  }

  const names = value.trim().split(" ");
  const isMultiName = names.length > 1;
  const firstName = isMultiName ? names.pop() : "";
  const lastName = names.join(" ");
  return {
    firstName,
    lastName,
    organisationName: value
  };
};
