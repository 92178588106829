import { observer } from "mobx-react-lite";

import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

import {
  ConditionConsultsExportDialog,
  ConditionConsultsExportDialogProps
} from "../condition-consults-export/ConditionConsultsExportDialog.tsx";
import { UnlinkConditionDialog } from "../unlink-condition-dialog/UnlinkConditionDialog.tsx";

export const ConditionConsultsWrapper: React.FC<ConditionConsultsExportDialogProps> =
  observer(
    ({
      diagnosis,
      patientName,
      patientId,
      claimId,
      claimNumber,
      episodeOfCareId,
      claimBadgeText
    }) => {
      const { correspondence, clinical } = useStores();
      const {
        isConditionConsultsExportDialogVisible,
        conditionConsultsDocumentId,
        setConditionConsultsDocumentId
      } = clinical.ui;

      const { getDocumentNameForExport } = usePatientRecordScreenContext();

      const documentName = getDocumentNameForExport(claimNumber, diagnosis);

      return (
        <>
          <UnlinkConditionDialog />
          {isConditionConsultsExportDialogVisible && (
            <ConditionConsultsExportDialog
              claimId={claimId}
              patientName={patientName}
              claimBadgeText={claimBadgeText}
              claimNumber={claimNumber}
              patientId={patientId}
              episodeOfCareId={episodeOfCareId}
              diagnosis={diagnosis}
            />
          )}
          {conditionConsultsDocumentId && (
            <DocumentViewerDialog
              getDocument={async () => {
                const response = await correspondence.getDocumentUrl({
                  patientId,
                  documentId: conditionConsultsDocumentId
                });

                return {
                  entityId: patientId,
                  documentId: conditionConsultsDocumentId,
                  previewUri: response.url,
                  name: documentName,
                  extension: "pdf",
                  entityType: DocumentEntityType.Patient
                };
              }}
              getDownloadUri={async () => {
                const documentUrlResponse = await correspondence.getDocumentUrl(
                  {
                    patientId,
                    documentId: conditionConsultsDocumentId
                  }
                );
                return documentUrlResponse.url;
              }}
              closeDocumentViewer={() =>
                setConditionConsultsDocumentId(undefined)
              }
            />
          )}
        </>
      );
    }
  );
