import { EncounterType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  predicate,
  required,
  validTime
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";

export class EncounterFormValidator extends Validator<EncounterFormValues> {
  constructor() {
    super();
    this.forField("startTime", [required(), validTime()]);
    this.forField("startDate", [required()]);
    this.forField("type", required());
    this.forField("location", [
      predicate(
        (val, encounter: EncounterFormValues) =>
          encounter.type === EncounterType.Consultation,
        required()
      )
    ]);
  }
}
