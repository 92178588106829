import { ClaimAddressDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ChangeSideValuesType } from "@shared-types/acc/change-side-values.type.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";

import { ClaimAdjustmentModalValues } from "./types/claim-adjustment-modal-values.interface.ts";

export const claimAdjustmentModalFormNameOf =
  nameOfFactory<ClaimAdjustmentModalValues>();
export const claimAdjustmentFormNameOf =
  nameOfFactory<ClaimAdjustmentFormValues>();
export const changeSideFormNameOf = nameOfFactory<ChangeSideValuesType>();
const patientAddress = claimAdjustmentFormNameOf("patientAddress");
export const patientAddressFieldNames = (key: keyof ClaimAddressDto) =>
  patientAddress ? `${patientAddress}.${String(key)}` : key;
