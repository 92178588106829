export enum RelationshipGroup {
  Priority = "priority",
  Family = "family",
  Professional = "professional",
  Other = "other",
  CurrentEmployee = "currentEmployee",
  PreviousEmployee = "previousEmployee"
}

//move
