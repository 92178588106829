import {
  ClinicalDataType,
  IntakeTypesFormTitle,
  SubstanceUseClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { IntakeForm } from "../intake/IntakeForm.tsx";
import { IntakeFormValues } from "../intake/IntakeForm.types.ts";
import { getCombinedIntakes, getInitialValues } from "../intake/utils.ts";
import { SubstanceUseFormValidator } from "./validators/SubstanceUseFormValidator.tsx";

interface SubstanceUseFormProps {
  clinicalRecord: ClinicalRecord;
}

export const SubstanceUseForm: React.FunctionComponent<
  SubstanceUseFormProps
> = ({ clinicalRecord }) => {
  const { core } = useStores();
  const intakeFormValidator = new SubstanceUseFormValidator(
    {
      dateOfBirth: clinicalRecord.patient?.birthDate?.toJSDate()
    },
    core
  );

  const getClinicalData = (values: IntakeFormValues) => {
    const {
      deletedIntakes,
      currentIntakes,
      historicalIntakes,
      status,
      comment,
      intakeConfirmed
    } = values;

    const initialValues = getInitialValues({
      intakeConfirmed,
      intake: clinicalRecord.clinicalData?.substanceUse,
      domain: ClinicalDataType.SubstanceUse,
      dateOfBirth: clinicalRecord.patient?.birthDate
    });

    const intakes = getCombinedIntakes({
      deletedIntakes,
      currentIntakes,
      historicalIntakes,
      initialCurrentIntakes: initialValues.currentIntakes
    });

    const eTag = clinicalRecord.clinicalData?.substanceUse?.eTag;
    const confirmedETag =
      clinicalRecord.clinicalData?.substanceUseConfirmed?.eTag;

    const substanceUse: SubstanceUseClinicalDataDto = {
      eTag,
      status,
      comment,
      intakes
    };

    return {
      substanceUse,
      substanceUseConfirmed: {
        eTag: confirmedETag,
        confirmed: true
      }
    };
  };

  const submitData = async (values: IntakeFormValues) => {
    const clinicalData = getClinicalData(values);
    await clinicalRecord.saveClinicalData(clinicalData);
  };

  return (
    <IntakeForm
      formTitle={IntakeTypesFormTitle.SubstanceUseTitle}
      onSubmit={submitData}
      maxIntakes={41}
      getData={getClinicalData}
      validate={intakeFormValidator.validate}
      domain={ClinicalDataType.SubstanceUse}
      clinicalRecord={clinicalRecord}
    />
  );
};
