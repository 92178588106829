import { FontSizes } from "@bps/fluent-ui";

export const iconButtonStyles = {
  root: { height: "36px", marginTop: 6 },
  flexContainer: { height: "36px" },
  menuIcon: { fontSize: FontSizes.size16 },
  rootDisabled: {
    backgroundColor: "transparent"
  }
};
