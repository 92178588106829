import { routes } from "@libs/routing/routes.ts";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { CreditNoteView } from "./CreditNoteView.tsx";

export const CreditNoteViewScreen = () => {
  const { routing } = useStores();
  const id = routes.accounts.creditNotes.viewPath.match(
    routing.location.pathname
  )?.params.id;

  if (!id) {
    return null;
  }

  return (
    <DataFetcher<CreditNote> fetch={({ billing }) => billing.getCreditNote(id)}>
      {(creditNote: CreditNote) => <CreditNoteView creditNote={creditNote} />}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default CreditNoteViewScreen;
