import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  Accordion,
  AccordionItem,
  FontWeights,
  mergeStyleSets,
  Stack,
  Text,
  useResizeElementObserver,
  useTheme
} from "@bps/fluent-ui";
import { BodyArea } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import { DiagramEditor } from "../../full-body-clinical-data/examination/components/DiagramEditor.tsx";
import { ExaminationComments } from "../../full-body-clinical-data/examination/components/ExaminationComments.tsx";
import { useBodyExaminationContext } from "../../full-body-clinical-data/examination/context/BodyExaminationContext.ts";
import { DiagramWrapper } from "../../full-body-clinical-data/examination/DiagramWrapper.tsx";
import { Dermatomes } from "./Dermatomes.tsx";
import {
  DermatomeFieldValues,
  DermatomesMyotomesAndReflexesFormValues
} from "./DermatomesAndMyotomesForm.types.ts";
import { Myotomes } from "./Myotomes.tsx";
import { Reflexes } from "./Reflexes.tsx";

export const DermatomesAndMyotomesFields: React.FC = observer(() => {
  const { setImages, setBodyParts, currentBodyArea } =
    useBodyExaminationContext();

  const { clinicalRecord, isViewOnly } = usePatientRecordScreenContext();
  const { values } = useFormState<DermatomesMyotomesAndReflexesFormValues>();

  const { mutators } = useForm<DermatomesMyotomesAndReflexesFormValues>();

  const [compactedCanvas, setCanvasCompacted] = useState<boolean>(false);

  useEffect(() => {
    setBodyParts([BodyArea.DermatomesAndMyotomes]);
    setImages(values.imageValue);
  }, [setBodyParts, setImages, values.imageValue]);

  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const theme = useTheme();

  const accordionStyle = {
    content: {
      backgroundColor: theme.semanticColors.bodyBackground
    }
  };

  const reflexAccordionStyle = mergeStyleSets(accordionStyle, {
    content: {
      padding: 0
    }
  });

  const commentsModified = useMemo(() => {
    if (currentBodyArea) {
      const comments = values.examinationComments[currentBodyArea];

      if (
        comments &&
        (comments.length > 1 || comments[0].title || comments[0].comment)
      ) {
        return true;
      }
    }

    return false;
  }, [currentBodyArea, values.examinationComments]);

  const nerveModified = (dermatomes: DermatomeFieldValues[] | undefined) => {
    return (
      !!dermatomes &&
      dermatomes.some(x => (!!x.nerves && x.nerves.length > 0) || x.estimation)
    );
  };

  const myotomesModified =
    !!values.myotomeFields && values.myotomeFields.length >= 1;

  const reflexesModified =
    values.reflexFields &&
    values.reflexFields.some(x => x.strength !== undefined);

  const dermatomesModified =
    nerveModified(values.trigeminalDermatomes) ||
    nerveModified(values.cervicalDermatomes) ||
    nerveModified(values.thoracicDermatomes) ||
    nerveModified(values.lumbosacralDermatomes);

  return (
    <DiagramWrapper
      compactedCanvas={compactedCanvas}
      showDiagramEditorArea={true}
      panelWidth={panelWidth}
      setElement={setElement}
      element={element}
    >
      <DiagramEditor
        onCompactButtonClicked={() => {
          setCanvasCompacted(!compactedCanvas);
        }}
        isCompacted={compactedCanvas}
        isViewOnly={
          isViewOnly || clinicalRecord.dischargeInProgressOrCompleted()
        }
        panelWidth={panelWidth}
        isDermAndMyotomes={true}
      />

      <Stack styles={{ root: { height: "100%" } }}>
        <Text
          styles={{
            root: {
              marginTop: 6,
              marginBottom: 8,
              fontWeight: FontWeights.semibold
            }
          }}
        >
          Tests & comments
        </Text>

        <Accordion multiple toggleIconType="folder" disableAnimation>
          <AccordionItem
            title="Dermatomes"
            styles={accordionStyle}
            showTitleIcon={dermatomesModified}
            titleIconName="PageEdit"
          >
            <Dermatomes />
          </AccordionItem>
          <AccordionItem
            title="Myotomes"
            styles={accordionStyle}
            showTitleIcon={myotomesModified}
            titleIconName="PageEdit"
          >
            <Myotomes />
          </AccordionItem>
          <AccordionItem
            title="Reflexes"
            styles={reflexAccordionStyle}
            showTitleIcon={reflexesModified}
            titleIconName="PageEdit"
          >
            <Reflexes />
          </AccordionItem>
          <AccordionItem
            title="Comments"
            extendedByDefault
            styles={accordionStyle}
            showTitleIcon={commentsModified}
            titleIconName="PageEdit"
          >
            <ExaminationComments mutators={mutators} isDermAndMyotomes={true} />
          </AccordionItem>
        </Accordion>
      </Stack>
    </DiagramWrapper>
  );
});
