import { action, computed, observable } from "mobx";

import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { ConvertToRefData } from "@libs/api/ref-data/RefData.ts";
import { BusinessRole } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AccProviderTypeText,
  NzPracticeAccContractDto,
  ProviderAccContractTypeDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  AccContractFormValues,
  ContractTypeFormValues
} from "../AccContractForm.types.ts";
import { NO_ACC_CONTRACT } from "../ProviderAccContracts.types.ts";

export class ProviderAccContractHelper {
  private practice: PracticeStore;

  constructor(
    private root: RootStore,
    private user: User
  ) {
    this.practice = this.root.practice;
  }

  @observable
  private orginalPracticeAccContractCompanyData: NzPracticeAccContractDto[] =
    [];

  @action
  public setAvailableAccContracts(data: NzPracticeAccContractDto[]) {
    this.orginalPracticeAccContractCompanyData = data;
  }

  public onFormReset() {
    this.setAvailableAccContracts(this.orginalPracticeAccContractCompanyData);
  }

  public onSubmit = async (values: AccContractFormValues) => {
    const filteredValues = values.contractTypes.filter(
      x => !x.providerContractTypeCode.includes(NO_ACC_CONTRACT)
    );

    const provider = await this.practice.getProvider(this.user.id);

    await this.practice.updateProvider({
      id: this.user.id,
      ahpraNumber: provider.ahpraNumber,
      medicareProviderNumber: provider.medicareProviderNumber,
      discipline: provider.discipline,
      cpn: provider.cpn,
      accProviderContractTypeCodes: filteredValues.map(
        c => c.providerContractTypeCode
      )
    });
  };

  public getAccContractTypeFormValues(
    values?: string[]
  ): ContractTypeFormValues[] {
    let result =
      values?.map(value => {
        const providerCC =
          this.practice.ref.accProviderContractTypes.values.find(
            r => r.code === value
          );
        return {
          providerTypeCode: providerCC?.providerTypeCode,
          providerTypeText: providerCC?.providerTypeText,
          providerContractTypeCode: value
        } as ContractTypeFormValues;
      }) ?? [];

    const possibleAccContracts =
      this.practice.ref.accProviderContractTypes.values.filter(x =>
        this.user.businessRoles.includes(x.businessRoleCode)
      );

    possibleAccContracts.forEach(contractCode => {
      if (
        !result.some(x => x.providerTypeText === contractCode.providerTypeText)
      ) {
        result?.push({
          providerTypeCode: contractCode.providerTypeCode,
          providerTypeText: contractCode.providerTypeText,
          providerContractTypeCode: `${contractCode.providerTypeText}${NO_ACC_CONTRACT}`
        });
      }
    });

    if (this.user.businessRoles.includes(BusinessRole.HandTherapist)) {
      result = result.filter(
        x =>
          x.providerTypeText !==
          (this.user.businessRoles.includes(BusinessRole.Physiotherapist)
            ? AccProviderTypeText.HandTherapistOT
            : AccProviderTypeText.HandTherapistPhysio)
      );
    }

    return result;
  }

  @computed
  get availableAccProviderContractTypes() {
    return this.practice.ref.accProviderContractTypes.values
      .filter(p => this.user.businessRoles.some(x => x === p.businessRoleCode))
      .map(value => ({
        ...value,
        isActive: this.orginalPracticeAccContractCompanyData.some(
          p =>
            p.accPracticeContractTypeCode === value.accPracticeContractTypeCode
        )
      })) as ProviderAccContractTypeDto[];
  }

  @computed
  get availableAccProviderTypes() {
    return this.availableAccProviderContractTypes
      .filter((value, index, array) => {
        return (
          index ===
          array.findIndex(
            y =>
              y.providerTypeCode === value.providerTypeCode &&
              y.providerTypeText === value.providerTypeText
          )
        );
      })
      .map(x => {
        const providerType: ConvertToRefData<RefDataDto> = {
          code: x.providerTypeCode,
          text: x.providerTypeText
        };

        return providerType;
      })
      .filter(x => {
        if (this.user.businessRoles.includes(BusinessRole.HandTherapist)) {
          return (
            x.text !==
            (this.user.businessRoles.includes(BusinessRole.Physiotherapist)
              ? AccProviderTypeText.HandTherapistOT
              : AccProviderTypeText.HandTherapistPhysio)
          );
        }
        return true;
      });
  }
}
