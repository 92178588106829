import { PatientNotePriority } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";

import { Visibility } from "./PatientNoticeForm.types.tsx";

export const getHighestNoticePriority = (patientNotices: PatientNotice[]) => {
  const sortedByPriority = patientNotices
    .map(notice => notice.priority)
    .sort((a, b) => {
      return getPriorityOrder(b) - getPriorityOrder(a);
    });
  return sortedByPriority[0] || "";
};

export const getPriorityOrder = (severity?: string): number => {
  switch (severity) {
    default:
      return 0;
    case PatientNotePriority.Low:
      return 0;
    case PatientNotePriority.Medium:
      return 1;
    case PatientNotePriority.High:
      return 2;
  }
};

export const getVisibility = (level: {
  isAdmin: boolean | undefined;
  isClinical: boolean | undefined;
}): Visibility[] => {
  const options: Visibility[] = ["isClinical", "isAdmin"];
  return options.filter(
    i =>
      (i === "isClinical" && level.isClinical) ||
      (i === "isAdmin" && level.isAdmin)
  );
};
