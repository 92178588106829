import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

import {
  Card,
  confirm,
  ConfirmDialog,
  Dropdown,
  IDropdownOption,
  Link,
  mergeStyles,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  TopBarWrapper
} from "@bps/fluent-ui";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameof, nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { formStyles } from "@modules/settings/screens/comms-templates/components/template-form/AddTemplateForm.styles.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AddTemplateInsertTagCallout } from "../AddTemplateInsertTagCallout.tsx";
import { AddTemplatePreview } from "../AddTemplatePreview.tsx";
import { LastUpdatedTemplate } from "../LastUpdatedTemplate.tsx";
import { TemplateEditorCharacterCount } from "../TemplateEditorCharacterCount.tsx";
import { AddTemplateFormHeader } from "./AddTemplateFormHeader.tsx";
import { AddTemplateFormValidator } from "./AddTemplateFormValidator.ts";
import { AddTemplateFormValues } from "./AddTemplateFormValues.ts";
import { useTemplateFormContext } from "./context/TemplateFormContext.tsx";

const nameOf = nameOfFactory<AddTemplateFormValues>();

const AddTemplateFormComponent: React.FunctionComponent = observer(() => {
  const textFieldRef = useRef<any>();

  const { comms, core, routing, notification } = useStores();
  const {
    addEditTemplate,
    templateList,
    templatesMasterList,
    getInitialValues
  } = useTemplateFormContext();

  const [selectedPreLoadedTemplate, setSelectedPreLoadedTemplate] = useState<
    string | undefined
  >(undefined);

  const selectedPreLoadedTemplateContent = useRef<string>("");

  const [hideDefaultTemplateDialog, setHideDefaultTemplateDialog] =
    useState<boolean>(false);

  const templateId = routing.match(routes.settings.communications.template.edit)
    ? routing.match(routes.settings.communications.template.edit)?.params.id
    : null;

  const validator = new AddTemplateFormValidator(templateList, templateId!);

  const templateTypeOptions: IDropdownOption[] =
    comms.ref.outboundCommTypes.keyTextValues.filter(
      x =>
        x.key !== OutboundCommType.FormNotify &&
        x.key !== OutboundCommType.ApptFree &&
        x.key !== OutboundCommType.Invoice &&
        x.key !== OutboundCommType.Receipt
    );

  const templateMasterOptions: IDropdownOption[] = templatesMasterList.map(
    t => {
      return { key: t.id, text: t.name };
    }
  );

  const handleInsert = (item: string, form: FormApi<AddTemplateFormValues>) => {
    if (item !== null) {
      if (textFieldRef.current) {
        const ref = textFieldRef.current;

        if (typeof ref.selectionStart === "number") {
          const { value = "" } = ref;
          const completedValue = `${value.slice(
            0,
            ref.selectionStart
          )} {{${item}}} ${value.slice(ref.selectionStart)}`;

          form.change("templateEditor", completedValue);
          textFieldRef.current.focus();
        }
      }
    }
  };

  const onSubmitSucceeded = () => {
    notification.success("Template has been successfully saved");
  };

  const initialValues = getInitialValues();

  return (
    <SubmissionForm<AddTemplateFormValues>
      formName="add-template"
      initialValues={initialValues}
      disableRoutePrompt
      onSubmit={addEditTemplate}
      styles={formStyles}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      render={(renderProps: { form: FormApi<AddTemplateFormValues> }) => (
        <TopBarWrapper
          header={<AddTemplateFormHeader form={renderProps.form} />}
        >
          <div className={mergeStyles({ padding: 16, height: "100%" })}>
            <Card
              headingLevel="section-heading"
              header={<LastUpdatedTemplate />}
              styles={{
                subComponentStyles: {
                  tile: {
                    content: {
                      padding: 16,
                      height: "100%"
                    },
                    header: {
                      padding: 24,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    },
                    root: {
                      height: "100%",
                      minWidth: 750,
                      minHeight: 750,
                      maxWidth: 1500,
                      margin: "0 auto"
                    }
                  }
                }
              }}
            >
              <Stack
                tokens={{ childrenGap: 100 }}
                horizontalAlign="start"
                horizontal
                styles={{
                  root: {
                    height: "100%",
                    paddingBottom: 25,
                    paddingRight: 100
                  }
                }}
              >
                <Stack
                  grow
                  verticalAlign="start"
                  tokens={{ childrenGap: 8 }}
                  styles={{
                    root: {
                      height: "100%"
                    }
                  }}
                  verticalFill
                >
                  <TextInputField
                    placeholder="Name your template"
                    name={nameOf("templateName")}
                    label="Name"
                    required
                  />
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <DropdownField
                      styles={{ root: { flexGrow: 1 } }}
                      label="Type"
                      placeholder="Select template type"
                      required
                      name={nameOf("templateType")}
                      options={templateTypeOptions}
                    />
                    <Dropdown
                      name="preloaded-template"
                      withNoEmptyOption={false}
                      selectedKey={
                        selectedPreLoadedTemplate
                          ? selectedPreLoadedTemplate
                          : undefined
                      }
                      placeholder="Select pre-loaded templates"
                      styles={{ root: { flexGrow: 1 } }}
                      label="Pre-loaded templates"
                      options={templateMasterOptions}
                      onChange={(
                        _event: React.FormEvent<HTMLDivElement>,
                        _item: IDropdownOption
                      ) => {
                        const form = renderProps.form;
                        const template = templatesMasterList.find(
                          t => t.id === _item.key
                        );
                        if (template) {
                          setSelectedPreLoadedTemplate(template.id);
                          selectedPreLoadedTemplateContent.current =
                            template.template;
                          form.change(
                            nameOf("templateEditor"),
                            template.template
                          );
                        } else {
                          setSelectedPreLoadedTemplate(undefined);
                          form.change(
                            nameOf("templateEditor"),
                            initialValues.defaultTemplateName
                          );
                        }
                      }}
                    />
                  </Stack>
                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    styles={{ root: { paddingTop: 10 } }}
                  >
                    <AddTemplateInsertTagCallout
                      handleInsert={(item: string) => {
                        handleInsert(item, renderProps.form);
                      }}
                    />
                    {core.hasPermissions(Permission.TenantSettingWrite) && (
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <FieldSpy
                          name={nameOf("isDefaultTemplate")}
                          children={(_state, value) => {
                            if (
                              value &&
                              !initialValues.isDefaultTemplate &&
                              initialValues.defaultTemplateName
                            ) {
                              return (
                                <ConfirmDialog
                                  hidden={hideDefaultTemplateDialog}
                                  minWidth={480}
                                  dialogContentProps={{
                                    title: "Change default template",
                                    subText:
                                      "A default template already exists. Would you like to replace the existing template?",
                                    showCloseButton: true,
                                    onDismiss: () => {
                                      setHideDefaultTemplateDialog(true);
                                      renderProps.form.change(
                                        "isDefaultTemplate",
                                        false
                                      );
                                    }
                                  }}
                                  confirmButtonProps={{ text: "Continue" }}
                                  onConfirm={() =>
                                    setHideDefaultTemplateDialog(true)
                                  }
                                  cancelButtonProps={{ text: "No" }}
                                  onCancel={() => {
                                    setHideDefaultTemplateDialog(true);
                                    renderProps.form.change(
                                      "isDefaultTemplate",
                                      false
                                    );
                                  }}
                                />
                              );
                            } else {
                              setHideDefaultTemplateDialog(false);
                            }
                            if (!value && initialValues.isDefaultTemplate) {
                              return (
                                <MessageBar
                                  styles={{ root: { width: 210 } }}
                                  messageBarType={MessageBarType.info}
                                >
                                  No default template selected
                                </MessageBar>
                              );
                            }
                            return null;
                          }}
                        />
                        <CheckboxField
                          name={nameOf("isDefaultTemplate")}
                          label="Set as default template for this template type"
                        />
                      </Stack>
                    )}
                  </Stack>

                  <TextInputField
                    multiline
                    name={nameOf("templateEditor")}
                    rows={20}
                    componentRef={ref => {
                      if (!textFieldRef.current) {
                        textFieldRef.current = ref;
                      }
                    }}
                  />
                  <Stack horizontal horizontalAlign="space-between">
                    <TemplateEditorCharacterCount />
                    <Link
                      styles={{
                        root: {
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end"
                        }
                      }}
                      disabled={
                        selectedPreLoadedTemplate === undefined ||
                        renderProps.form.getState().values.templateEditor ===
                          selectedPreLoadedTemplateContent.current
                      }
                      onClick={async () => {
                        const isConfirmed = await confirm({
                          confirmButtonProps: { text: "Ok" },
                          cancelButtonProps: {
                            text: "Cancel"
                          },
                          dialogContentProps: {
                            subText:
                              "All changes made on this Template will be lost"
                          }
                        });
                        if (isConfirmed) {
                          const form = renderProps.form;
                          const template = templatesMasterList.find(
                            t => t.id === selectedPreLoadedTemplate
                          );
                          form.change(
                            nameof("templateEditor"),
                            template && template.template
                          );
                        }
                      }}
                    >
                      Reset to Pre-loaded template
                    </Link>
                  </Stack>
                </Stack>

                <AddTemplatePreview />
              </Stack>
            </Card>
          </div>
        </TopBarWrapper>
      )}
      validate={validator.validate}
    />
  );
});

export const AddTemplateForm = withFetch(
  x => [x.comms.ref.outboundCommTypes.load()],
  observer(() => {
    const { getTemplateList, getTemplatesMasterList } =
      useTemplateFormContext();

    const fetch = async () => {
      await Promise.all([getTemplatesMasterList(), getTemplateList()]);
    };

    return (
      <DataFetcher fetch={fetch} fallback={<Spinner />}>
        {() => <AddTemplateFormComponent />}
      </DataFetcher>
    );
  })
);
