import { createContext, FC, useContext } from "react";

import { useStores } from "@stores/hooks/useStores.ts";

import { PatientSummaryHelper } from "./PatientSummaryHelper.ts";

interface PatientSummaryContextProviderInterface {
  children: JSX.Element;
}

const PatientSummaryContext = createContext<PatientSummaryHelper>(
  {} as PatientSummaryHelper
);

export const usePatientSummaryContext = (): PatientSummaryHelper => {
  return useContext<PatientSummaryHelper>(PatientSummaryContext);
};

export const PatientSummaryContextProvider: FC<
  PatientSummaryContextProviderInterface
> = ({ children }) => {
  const root = useStores();

  return (
    <PatientSummaryContext.Provider value={new PatientSummaryHelper(root)}>
      {children}
    </PatientSummaryContext.Provider>
  );
};
