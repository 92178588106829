import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";

import { Link } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ContactDetailsLinkProps {
  modelName: string | undefined;
  modelId: string | undefined;
}
export const ContactDetailsLink: FunctionComponent<ContactDetailsLinkProps> =
  observer(({ modelName, modelId }) => {
    const { practice, core } = useStores();
    const {
      ui: { showEditContact, showContactDetails }
    } = practice;

    const hasPatientWritePermission = core.hasPermissions([
      Permission.PatientWrite
    ]);

    if (!modelId) return null;
    return (
      <Link
        onClick={() =>
          hasPatientWritePermission
            ? showEditContact(PatientCardIds.patientHeader, modelId)
            : showContactDetails(modelId)
        }
      >
        {modelName}
      </Link>
    );
  });
