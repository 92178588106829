import { FC } from "react";
import { useField } from "react-final-form";

import {
  ButtonsGroupOption,
  dataAttribute,
  DataAttributes
} from "@bps/fluent-ui";
import { FeeType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";

import { FeeFormLabels, feeFormNameOf } from "../ScheduleForm.types.tsx";

const FeeFormFeeTypeFieldComponent: FC = () => {
  const { billing } = useStores();
  let feeTypeOptions: ButtonsGroupOption<any>[] =
    billing.ref.feeTypes.keyTextValues;

  const {
    input: { value: feeIsService }
  } = useField(feeFormNameOf("feeIsService"), {
    subscription: { value: true }
  });

  if (!feeIsService) {
    feeTypeOptions = feeTypeOptions.filter(
      x => x.key === FeeType.FlatRate || x.key === FeeType.PerUnit
    );
  }

  return (
    <ButtonsGroupSingleChoiceField
      {...dataAttribute(DataAttributes.Element, "fee-form-feeType-buttons")}
      label={FeeFormLabels.feeType}
      name={feeFormNameOf("feeType")}
      options={feeTypeOptions}
    />
  );
};

export const FeeFormFeeTypeField = withFetch(
  x => [x.billing.ref.feeTypes.load()],
  FeeFormFeeTypeFieldComponent
);
