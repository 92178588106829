import {
  FontSizes,
  FontWeights,
  IButtonStyles,
  ILabelStyles,
  IStyle,
  ITextProps,
  ITheme,
  mergeStyles,
  mergeStyleSets
} from "@bps/fluent-ui";

export const getPeopleScreenStylesSet = (theme: ITheme) => {
  const breakLongText = {
    whiteSpace: "normal",
    wordBreak: "break-word"
  };

  const personaText = {
    primaryText: {
      div: { overflow: "hidden", textOverflow: "ellipsis" }
    }
  };

  const iconButtonStyles: IButtonStyles = {
    root: {
      padding: 0,
      width: 24,
      height: 24,
      marginTop: 2
    },
    icon: {
      margin: 0,
      color: theme.palette.neutralPrimary,
      fontSize: theme.fonts.medium.fontSize
    }
  };

  const halfWidthField = { root: { width: "50%" } };

  const itemFullyCentered = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  const labelSmall: Partial<ILabelStyles> = {
    root: {
      fontSize: theme.fonts.small.fontSize,
      color: theme.palette.neutralSecondary,
      fontWeight: FontWeights.regular
    }
  };

  const personaStyle = mergeStyleSets({
    root: {
      margin: "0 16px",
      height: "auto"
    },
    primaryText: {
      ...breakLongText,
      fontSize: theme.fonts.xxLarge.fontSize,
      fontWeight: FontWeights.semibold,
      marginBottom: "4px"
    }
  });

  const providerTitle = mergeStyles({
    fontSize: theme.fonts.medium.fontSize,
    fontWeight: FontWeights.semibold,
    marginBottom: 5
  });

  const personaTitleStyle = {
    root: {
      fontSize: FontSizes.xSmall,
      fontWeight: FontWeights.regular,
      color: theme.palette.neutralSecondary
    }
  };

  const removeLinkStyle = {
    root: {
      fontSize: FontSizes.small,
      fontWeight: FontWeights.regular
    }
  };

  const noRelationships = {
    ...itemFullyCentered,
    minHeight: 83
  };

  return {
    personaText,
    personaTitleStyle,
    itemFullyCentered,
    iconButtonStyles,
    noRelationships,
    removeLinkStyle,
    halfWidthField,
    breakLongText,
    providerTitle,
    personaStyle,
    labelSmall
  };
};

export const getOptionalTextStyleSecondary = (
  props: ITextProps,
  theme: ITheme
) => ({
  root: {
    fontSize: theme.fonts.medium.fontSize,
    color: theme.palette.neutralSecondary
  } as IStyle
});
