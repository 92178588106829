import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontSizes,
  GroupedList,
  Heading,
  IconButton,
  IContextualMenuItem,
  IGroupHeaderProps,
  Link,
  noWrap,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  CodedText,
  MeasurementDto,
  MeasurementType,
  PSFSCloseReason
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalTabs } from "@stores/clinical/models/clinical-tab/ClinicalTabs.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteDialog } from "@ui-components/DeleteDialog.tsx";

import { ConfidentialToolTipFontIcon } from "../../ConfidentialToolTipFontIcon.tsx";
import { ConfidentialByProviderText } from "../../investigations/side-panel/ConfidentialByProviderText.tsx";
import { ClinicalToolEditOptions } from "../ClinicalTool.types.ts";
import { PSFSTrendDialog } from "../psfs/PSFSTrendDialog.tsx";
import { Rand36ClinicalToolListItem } from "../rand36/Rand36ClinicalToolListItem.tsx";
import { ClinicalDataToMeasurementConverter } from "../utils/ClinicalDataToMeasurementConverter.ts";
import { ClinicalToolResultOpener } from "./ClinicalToolResultOpener.ts";
import { ClinicalToolsListModel } from "./ClinicalToolsListModel.ts";

export interface ClinicalToolsListProps {
  clinicalRecord: ClinicalRecord;
  clinicalTabs: ClinicalTabs;
  codedTexts?: CodedText[];
  clinicalToolResultOpener: ClinicalToolResultOpener;
}

const marginStyle = {
  root: { height: "36px" },
  flexContainer: { height: "36px" },
  menuIcon: { fontSize: FontSizes.size16 },
  rootDisabled: {
    backgroundColor: "transparent"
  }
};

const subMarginStyle = {
  root: {
    marginTop: 8,
    marginBottom: 4,
    paddingLeft: 40,
    paddingRight: 14
  }
};

const converter = new ClinicalDataToMeasurementConverter();

const DEPTH_1 = 1;
const DEPTH_2 = 2;
const DEPTH_3 = 3;

export const ClinicalToolsListBase: React.FC<ClinicalToolsListProps> = observer(
  ({ clinicalRecord, clinicalTabs, codedTexts, clinicalToolResultOpener }) => {
    const { core, clinical } = useStores();
    if (!clinicalTabs.currentPatientRecordTab) {
      return null;
    }

    const sidebarIsShort =
      clinical.ui.clinicalTabs.currentPatientRecordTab?.sidePanelSize === 1;

    const [showTrendDialog, setShowTrendDialog] = useState<boolean>(false);
    const [deleteDialogHidden, setDeleteDialogHidden] = useState<boolean>(true);
    const [currentContextId, setCurrentContextId] = useState<
      string | undefined
    >(undefined);

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    const model = useRef(
      new ClinicalToolsListModel(clinicalRecord, codedTexts)
    );

    const groupedItems = model.current.generateGroups(
      clinicalTabs.currentPatientRecordTab,
      clinicalRecord.measurements
    );

    const adjustMeasurementConfidentiality =
      model.current.adjustMeasurementConfidentiality;

    const editOptions = (
      header: IGroupHeaderProps | undefined,
      disableOptions: {
        contextItemsDisabled: boolean;
        trendingDisabled: boolean;
      }
    ) => {
      const group = header?.group;
      const currentToolItems = groupedItems.items.filter(
        x => group && x.type === group.key
      );

      const measurementSubItems = currentToolItems.map(measurement => {
        const item: IContextualMenuItem = {
          key: measurement.id,
          name: DateTime.fromISO(
            measurement.changeLog?.createdDate
          )?.toDayDefaultFormat(),
          onClick: () => {
            adjustMeasurementConfidentiality(measurement);
          },
          iconProps: {
            iconName: measurement.secGroupId ? "ShieldAlert" : ""
          },
          disabled: !core.hasAccessToSecGroup(measurement.secGroupId)
        };
        return item;
      });

      const currentSelectedPSFSItem = groupedItems.items.find(
        x =>
          x.type === MeasurementType.PSFS &&
          group &&
          group.data &&
          x.id === group.data.measurementId
      );

      let items: IContextualMenuItem[] =
        group && group.level === DEPTH_1
          ? [
              {
                key: "addNew",
                className: "add-new-clinical-tool",
                text: ClinicalToolEditOptions.addNew,
                onClick: () => {
                  const type = converter.measurementToClinical(
                    header?.group?.key as MeasurementType
                  );
                  clinicalToolResultOpener.openClinicalToolFromDropDown(type);
                }
              },
              {
                key: "makeConfidential",
                className: "make-clinical-tool-confidential",
                text: "Manage confidentiality",
                subMenuProps: {
                  items: measurementSubItems
                },
                data: {
                  permission: [Permission.ConfidentialDataAllowed],
                  filter: group.key === "PSFS"
                }
              }
            ]
          : [
              {
                key: "addSeries",
                className: "add-new-series-clinical-tool",
                text: "Record",
                onClick: () => {
                  const type = converter.measurementToClinical(
                    header?.group?.data.type as MeasurementType
                  );
                  clinicalToolResultOpener.addNewSeriesItemFromLink(
                    type,
                    header?.group?.key!
                  );
                },
                disabled: disableOptions.contextItemsDisabled
              },
              {
                key: "viewTrending",
                className: "view-series-trending",
                text: "View trending",
                onClick: () => {
                  setCurrentContextId(header?.group?.key!);
                  setShowTrendDialog(true);
                },
                disabled: disableOptions.trendingDisabled
              },
              {
                key: "closeSeries",
                className: "close-series-clinical-tool",
                text: "Close Series",
                itemProps: {},
                onClick: () => {
                  setCurrentContextId(header?.group?.key!);
                  setDeleteDialogHidden(false);
                },
                disabled: disableOptions.contextItemsDisabled
              },
              {
                key: "makeConfidential",
                className: "make-clinical-tool-confidential",
                text:
                  currentSelectedPSFSItem && currentSelectedPSFSItem.secGroupId
                    ? ClinicalToolEditOptions.removeConfidentiality
                    : ClinicalToolEditOptions.makeConfidential,
                data: { permission: [Permission.ConfidentialDataAllowed] },
                onClick: () => {
                  if (currentSelectedPSFSItem) {
                    adjustMeasurementConfidentiality(currentSelectedPSFSItem);
                  }
                }
              }
            ];

      items = items.filter(x => {
        if (x.data) {
          if (
            (x.data.permission && !core.hasPermissions(x.data.permission)) ||
            x.data.filter
          ) {
            return false;
          }
        }
        return true;
      });

      return items;
    };

    return (
      <React.Fragment>
        <GroupedList
          items={groupedItems.items}
          groups={groupedItems.groups}
          compact={true}
          styles={{
            root: {
              selectors: {
                ".ms-GroupHeader:hover": {
                  background: "inherit"
                },
                ".ms-GroupHeader-title": {
                  cursor: "default"
                },
                ".ms-List-cell": {
                  minHeight: 8
                }
              }
            },
            group: {
              selectors: {}
            }
          }}
          groupProps={{
            onRenderHeader: header => {
              const name = header?.group?.name || "";
              const count =
                (name === MeasurementType.PSFS
                  ? header?.group?.children?.length
                  : header?.group?.count) || 0;

              const dateHeader = model.current.headerDate(header?.group?.key);
              const group = header?.group!;

              const isGroupLevel2Confidential =
                group.level === DEPTH_2 && group.data.secGroupId;

              const isGroupLevel2ConfidentialByOther =
                group.level === DEPTH_2 &&
                !core.hasAccessToSecGroup(group.data.secGroupId);

              const isCollapsed =
                header?.group?.isCollapsed ||
                isGroupLevel2ConfidentialByOther ||
                false;

              let contextItemsDisabled = false;
              const trendingDisabled = !(
                header?.group?.count && header?.group?.count >= 2
              );
              if (header?.group?.level !== DEPTH_1) {
                contextItemsDisabled = model.current.checkIsSeriesClosed(
                  header?.group?.key!
                );
              }

              return (
                <>
                  <Stack
                    horizontal
                    onClick={() => {
                      if (
                        header?.onToggleCollapse &&
                        header?.group &&
                        !isGroupLevel2ConfidentialByOther
                      ) {
                        header.onToggleCollapse(header.group);
                        clinicalTabs.currentPatientRecordTab!.toggleClinicalTools(
                          name
                        );
                      }
                    }}
                    styles={{
                      root: {
                        height: "fit-content",
                        borderBottom: isCollapsed ? "1px #eee solid" : undefined
                      }
                    }}
                  >
                    {!isViewOnlyOrDischarged && (
                      <IconButton
                        {...dataAttribute(
                          DataAttributes.Element,
                          `more-button-${name}`
                        )}
                        menuIconProps={{
                          iconName: isGroupLevel2ConfidentialByOther
                            ? "ShieldAlert"
                            : "More"
                        }}
                        menuProps={{
                          items: editOptions(header, {
                            trendingDisabled,
                            contextItemsDisabled
                          })
                        }}
                        styles={marginStyle}
                        disabled={isGroupLevel2ConfidentialByOther}
                      />
                    )}
                    {!isGroupLevel2ConfidentialByOther && (
                      <IconButton
                        {...dataAttribute(
                          DataAttributes.Element,
                          `${name}-collapse`
                        )}
                        iconProps={{
                          iconName: isCollapsed ? "ChevronRight" : "ChevronDown"
                        }}
                        styles={
                          group.level === DEPTH_1 ? marginStyle : subMarginStyle
                        }
                      />
                    )}

                    {!isGroupLevel2ConfidentialByOther ? (
                      <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{ childrenGap: 4 }}
                        styles={{
                          root: {
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingRight: 16,
                            width: "100%"
                          }
                        }}
                      >
                        <Heading
                          {...dataAttribute(
                            DataAttributes.Element,
                            `${name}-heading`
                          )}
                          labelPaddings
                        >
                          {name} ({count})
                        </Heading>
                        {group.level === DEPTH_1 && (
                          <Text
                            styles={{
                              root: {
                                marginTop: 5
                              }
                            }}
                          >
                            Latest:
                            {` ${dateHeader}`}
                          </Text>
                        )}
                        {isGroupLevel2Confidential && (
                          <ConfidentialToolTipFontIcon
                            isShowConfidentialIcon={true}
                          />
                        )}
                      </Stack>
                    ) : (
                      <ConfidentialByProviderText
                        secGroupId={group.data.secGroupId}
                        styles={{
                          root: {
                            ...noWrap,
                            marginTop: 8,
                            marginLeft: 30,
                            marginBottom: 20
                          }
                        }}
                      />
                    )}
                  </Stack>
                  {!isCollapsed &&
                    ((group.level === DEPTH_1 &&
                      group.children?.length === 0) ||
                      (group.level === DEPTH_2 &&
                        !isGroupLevel2ConfidentialByOther)) && (
                      <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{ childrenGap: 4 }}
                        styles={{
                          root: {
                            padding: "8px 16px",
                            borderBottom: "1px #eee solid"
                          }
                        }}
                      >
                        <Text
                          styles={{
                            root: {
                              marginLeft:
                                group.level === DEPTH_1 ? "45px" : "65px"
                            }
                          }}
                        >
                          Summary
                        </Text>
                        <Text>Recorded</Text>
                      </Stack>
                    )}
                </>
              );
            }
          }}
          onRenderCell={(depth, item: MeasurementDto) => {
            const isConfidentialByOthers = !core.hasAccessToSecGroup(
              item.secGroupId
            );

            const isConfidentialByOthersLevel3 =
              depth === DEPTH_3 && isConfidentialByOthers;

            if (isConfidentialByOthersLevel3) {
              return;
            }
            return (
              <Stack
                {...dataAttribute(DataAttributes.Element, `${item.type}-item`)}
                grow
                horizontal
                horizontalAlign="space-between"
                tokens={{ childrenGap: 16 }}
                styles={{
                  root: {
                    borderBottom: "1px #eee solid",
                    padding: "11px 8px 11px 12px"
                  }
                }}
              >
                <Stack
                  horizontal
                  tokens={{ childrenGap: 5 }}
                  horizontalAlign="start"
                  verticalAlign="end"
                  styles={{
                    root: {
                      marginLeft: depth === DEPTH_1 ? "45px" : "65px",
                      minWidth: 200
                    }
                  }}
                >
                  <Link
                    {...dataAttribute(
                      DataAttributes.Element,
                      "open-result-link"
                    )}
                    onClick={() => {
                      clinicalToolResultOpener.openResultFromLink(item);
                    }}
                  >
                    {!isConfidentialByOthers &&
                      (item.encounterId === clinicalRecord.openEncounter?.id
                        ? "Edit:"
                        : "Result:")}
                  </Link>
                  <Text
                    nowrap
                    styles={{
                      root: {
                        paddingRight: "4px"
                      }
                    }}
                  >
                    {item.type === MeasurementType.RAND36 ? (
                      <Rand36ClinicalToolListItem
                        measurement={item}
                        clinicalRecord={clinicalRecord}
                      />
                    ) : (
                      <>
                        {sidebarIsShort ? (
                          <TooltipHost content={item.summary}>
                            <Text styles={{ root: { fontStyle: "italic" } }}>
                              {item.summary}
                            </Text>
                          </TooltipHost>
                        ) : (
                          <Text styles={{ root: { fontStyle: "italic" } }}>
                            {item.summary}
                          </Text>
                        )}
                      </>
                    )}
                  </Text>
                  <ConfidentialToolTipFontIcon
                    isShowConfidentialIcon={
                      !!item.secGroupId && item.type !== MeasurementType.PSFS
                    }
                    summaryStyling={isConfidentialByOthers}
                    content={
                      isConfidentialByOthers
                        ? "Confidential"
                        : "Confidential. Use action menu to release"
                    }
                  />
                  {isConfidentialByOthers && (
                    <ConfidentialByProviderText
                      secGroupId={item.secGroupId ?? ""}
                      styles={{
                        root: {
                          ...noWrap
                        }
                      }}
                    />
                  )}
                </Stack>
                {!isConfidentialByOthers && (
                  <Text>
                    {DateTime.fromISO(
                      item.changeLog?.createdDate
                    )?.toDayDefaultFormat()}
                  </Text>
                )}
              </Stack>
            );
          }}
        />
        {showTrendDialog && (
          <PSFSTrendDialog
            clinicalRecord={clinicalRecord}
            contextId={currentContextId!}
            onClose={() => {
              setShowTrendDialog(false);
              setCurrentContextId(undefined);
            }}
          />
        )}
        <DeleteDialog
          formName="close-psfs"
          title="Close PSFS"
          subText="Once you close a PSFS series you will no longer be able to make further records against it. Are you sure you would like to proceed?"
          options={clinical.ref.psfsCloseReasons.keyTextValues.filter(
            x => x.key !== PSFSCloseReason.Discharged
          )}
          okButtonText="Close PSFS Series"
          hidden={deleteDialogHidden}
          onConfirm={(reason, comment) => {
            return model.current
              .closePSFSSeries(currentContextId!, reason, comment)
              .then(() => {
                setDeleteDialogHidden(true);
              });
          }}
          onCancel={() => {
            setDeleteDialogHidden(true);
          }}
          reasonLabel="Reason for closing"
        />
      </React.Fragment>
    );
  }
);

export const ClinicalToolsList = withFetch(
  x => x.clinical.ref.psfsCloseReasons.load(),
  ClinicalToolsListBase
);
