import { getAllocation } from "@modules/billing/billing.utils.ts";
import { PaymentTotals } from "@modules/billing/screens/shared-components/AllocationList/AllocationListBase.types.tsx";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";

import { TransactionView } from "../shared-components/TransactionView.tsx";
import { CreditNoteViewButtons } from "./components/CreditNoteViewButtons.tsx";
import { CreditNoteDetails } from "./CreditNoteDetails.tsx";

interface CreditNoteProps {
  creditNote: CreditNote;
}

export const CreditNoteView: React.FC<CreditNoteProps> = ({ creditNote }) => {
  const creditNoteItems = creditNote.items.map(x => ({
    ...getAllocation(x),
    paymentNumber: creditNote.number,
    paymentId: creditNote.id
  }));

  const allocations =
    creditNote.allocationSpenders?.map(x => ({
      ...getAllocation(x),
      paymentNumber: x.transaction.number,
      paymentId: x.transaction.id
    })) || [];

  const allocationProps = [
    {
      allocations: creditNoteItems,
      unallocatedAmount: 0,
      columnOptions: {
        filtersToShow: { total: true },
        headingOptions: { allocationName: "Credited ($)" }
      },
      headerOptions: {
        fields: [
          {
            key: PaymentTotals.creditOriginated,
            title: "Credit originated",
            hideHeaderValue: true,
            boldHeading: true
          },
          {
            key: PaymentTotals.itemCount,
            title: "Items"
          },
          {
            key: PaymentTotals.total,
            title: "Total ($)"
          }
        ]
      }
    },
    {
      allocations,
      unallocatedAmount: creditNote.unallocated,
      columnOptions: {
        filtersToShow: { total: true, createdDate: true },
        headingOptions: { allocationName: "Allocated ($)" }
      },
      headerOptions: {
        fields: [
          {
            key: PaymentTotals.creditAllocated,
            title: "Credit allocated",
            hideHeaderValue: true,
            boldHeading: true
          },
          {
            key: PaymentTotals.total,
            title: "Total ($)"
          },
          {
            key: PaymentTotals.allocated,
            title: "Allocated ($)"
          },
          {
            key: PaymentTotals.unallocated,
            title: "Available ($)"
          }
        ]
      }
    }
  ];

  return (
    <TransactionView
      extraDetailsContent={<CreditNoteDetails credit={creditNote} />}
      transaction={creditNote}
      allocationOptions={{ allocationProps }}
      buttons={<CreditNoteViewButtons creditNote={creditNote} />}
    />
  );
};
