import {
  BodyArea,
  ExaminationCommentDto,
  ExaminationImageDto,
  ImageTypeCode,
  SpecialTestAnswerSpecialCondition
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { centralNervousSystemNameOf } from "../../history-examinations/central-nervous-system/CentralNervousSystemForm.types.ts";
import { dermMyotomesAndReflexesNameOf } from "../../history-examinations/dermatomes-and-myotomes/DermatomesAndMyotomesForm.types.ts";
import {
  bodyExamNameOf,
  ExaminationCommentsType,
  ImageValue,
  SpecialTestResponseType
} from "./BodyExaminationForm.types.ts";

export const getImagesDTO = (imageValue: ImageValue = {}) => {
  const images: ExaminationImageDto[] = [];

  Object.entries(imageValue).forEach((fieldValue: [ImageTypeCode, string]) => {
    const [key, value] = fieldValue;
    if (value) {
      images.push({
        type: key,
        content: btoa(value)
      });
    }
  });

  return images;
};

export const getFlattenedImageValues = (
  images: ExaminationImageDto[]
): ImageValue => {
  const imageValue: ImageValue = {};

  images.forEach(image => {
    imageValue[image.type] = image.content ? atob(image.content) : undefined;
  });
  return imageValue;
};

export const cleanExaminationComments = (
  examinationComments: ExaminationCommentsType
) => {
  const commentKeys = Object.keys(examinationComments);
  const cleanComments: ExaminationCommentsType = {};
  commentKeys.forEach(key => {
    const filledComments: ExaminationCommentDto[] = [];
    const currentComments: ExaminationCommentDto[] = examinationComments[key];
    currentComments.forEach(comment => {
      if (comment.title || comment.comment) {
        filledComments.push(comment);
      }
    });
    if (filledComments.length > 0) {
      cleanComments[key] = filledComments;
    }
  });
  return cleanComments;
};

export const hasQuestionConditionBeenMet = (
  valueList: string[] | undefined,
  answerValue: string,
  shouldFindValue: boolean
): boolean => {
  // If there's nothing to compare to, the answer passes.
  if (!valueList || valueList.length === 0) {
    return true;
  }

  const foundMatchingValue =
    valueList.find(
      x => x.toLowerCase() === answerValue.toString().toLowerCase()
    ) || valueList.find(x => x === SpecialTestAnswerSpecialCondition.Any);

  if (shouldFindValue) {
    return foundMatchingValue !== undefined;
  }

  if (!shouldFindValue) {
    return foundMatchingValue === undefined;
  }

  return false;
};

export const getNameOf = (
  isDermAndMyotomes?: boolean,
  isCentralNervousSystem?: boolean
) => {
  if (isDermAndMyotomes) {
    return dermMyotomesAndReflexesNameOf;
  }

  if (isCentralNervousSystem) {
    return centralNervousSystemNameOf;
  }

  return bodyExamNameOf;
};

export const SMALL_BREAKPOINT = 622;

export const MEDIUM_BREAKPOINT = 913;
export const LARGE_BREAKPOINT = 1200;

export const REFLEX_JAW_JERK_NERVE = "Trigeminal";

export const testResponseItemsModified = (
  currentBodyArea?: BodyArea,
  specialResponseItems?: SpecialTestResponseType,
  hasSide?: boolean
) => {
  if (!currentBodyArea || !specialResponseItems) {
    return false;
  }

  let currentResponseItems = specialResponseItems[currentBodyArea] || [];

  if (hasSide) {
    const leftResponseItems = specialResponseItems[`L${currentBodyArea}`] || [];

    const rightResponseItems =
      specialResponseItems[`R${currentBodyArea}`] || [];

    currentResponseItems = [
      ...currentResponseItems,
      ...leftResponseItems,
      ...rightResponseItems
    ];
  }

  return currentResponseItems.some(item => item !== undefined);
};
