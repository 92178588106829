import { DEGREE_SYMBOL } from "../../SOTAP/SOTAP.constants.ts";

export interface GeneralExaminationFormValues {
  skinDiscolouration?: string;
  skinCyanosisPattern?: string;
  pulseRhythm?: string;
  pulseCharacteristic?: string;
  bloodPressures: BloodPressure[];
  temperature?: string;
  temperatureMethod?: string;
  respiratoryRate?: string;
  o2SaturationRate?: string;
  weight?: string;
  height?: string;
  bmi?: string;
  pulses: Pulse[];
  hydration?: string;
  hydrationRate?: string;
  bGLLevel?: string;
  bGLFasting?: string;
  bodyHabitus?: string;
  neck?: string;
  hip?: string;
  waist?: string;
  nails?: string[];
  hands?: string[];
  comment?: string;
}

export const SQUARED_SYMBOL = "\xB2";

export const degreeCSymbol = `${DEGREE_SYMBOL}C`;

export interface Pulse {
  heartRate?: string;
  position?: string;
  method?: string;
  timeStamp?: string;
}
export interface BloodPressure {
  systolic?: string;
  diastolic?: string;
  arm?: string;
  bPPosition?: string;
  timeStamp?: string;
}
