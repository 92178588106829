import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { TemplateWriterDetails } from "./components/TemplateWriterDetails.tsx";
import { TemplateWriterContext } from "./context/TemplateWriterContext.ts";
import { TemplateWriterHelper } from "./context/TemplateWriterHelper.ts";

interface TemplateData {
  template: Template;
  documentSettings?: OrgUnitDocumentSettingsDto;
}

const TemplateWriterScreen: FunctionComponent = observer(() => {
  const root = useStores();
  const activeTemplateTab = root.document.activeTemplateTab;

  if (!activeTemplateTab) {
    return null;
  }

  return (
    <DataFetcher<TemplateData>
      refetchId={activeTemplateTab?.documentId}
      fetch={async () => {
        let documentSettings: OrgUnitDocumentSettingsDto | undefined;
        const orgUnitId = root.core.location.parentOrgUnit?.id;

        if (orgUnitId) {
          const orgUnit = await root.practice.getOrgUnit(orgUnitId);
          documentSettings = orgUnit?.orgUnitDocumentSettings;
        }

        const template = await root.document.getTemplate(
          activeTemplateTab.documentId
        );

        if (template.isPracticeLetterhead) {
          const renderedContent = await root.document.renderTemplate(
            template.id,
            {
              includeDefaultBody: true,
              skipMerge: true
            }
          );
          template.setRenderedContent(renderedContent.content ?? "");
        }

        if (template.content !== "" && template.renderedContent === "") {
          const renderedContent = await root.document.renderTemplate(
            template.id,
            {
              skipMerge: true
            }
          );
          template.setRenderedContent(renderedContent.content ?? "");
        }
        return { template, documentSettings };
      }}
    >
      {data => {
        if (!data.template) {
          return;
        }

        const model = new TemplateWriterHelper(root, data.template);
        return (
          <TemplateWriterContext.Provider value={model}>
            <TemplateWriterDetails documentSettings={data.documentSettings} />
          </TemplateWriterContext.Provider>
        );
      }}
    </DataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default TemplateWriterScreen;
