import { Stack } from "@bps/fluent-ui";
import { StatementBadge } from "@modules/billing/screens/shared-components/StatementBadge.tsx";
import { Statement } from "@stores/billing/models/Statement.ts";

import { StatementLinkCell } from "./StatementLinkCell.tsx";

export const TransactionDetailsCell: React.FC<{
  statement: Statement;
}> = ({ statement }) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
      <StatementBadge />
      <StatementLinkCell statement={statement} />
    </Stack>
  );
};
