import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import {
  ConfirmDialog,
  MessageBar,
  MessageBarType,
  NoDataTile,
  Stack,
  Text
} from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { sum } from "@libs/utils/utils.ts";
import { AllocationFieldValues } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { CreditNoteHelper } from "../context/CreditNoteHelper.ts";
import { getMessageBarText } from "./utils.ts";

interface WriteOffStatusLabelProps {
  helper: CreditNoteHelper;
}

export const CreditNoteConfirmDialog: React.FC<WriteOffStatusLabelProps> =
  observer(({ helper }) => {
    const form = useForm();
    const {
      submitDialogType,
      showModal,
      getOwingAllocationItems,
      getPaidRefundItems
    } = helper;

    const {
      fields: { value: allocations }
    } = useFieldArray<AllocationFieldValues>(allocationNameOf("allocations"));

    if (!showModal) {
      return null;
    }

    const paidItems = getPaidRefundItems(allocations);
    const owingItems = getOwingAllocationItems(allocations);

    const noSelectedItems = !paidItems.length && !owingItems.length;

    const refundedTotal = sum("amount", paidItems);

    const getInfoJSX = () => {
      if (refundedTotal > 0) {
        return (
          <Text>{`$${currencyFormat(refundedTotal, {
            currency: ""
          })} credited to ${helper.invoice.accountContactName}`}</Text>
        );
      }
      return null;
    };

    return (
      <ConfirmDialog
        hidden={!showModal}
        confirmButtonProps={{
          disabled: noSelectedItems,
          text: "Credit invoice",
          iconProps: { hidden: true }
        }}
        maxWidth={480}
        minWidth={480}
        cancelButtonProps={{ text: "Close" }}
        dialogContentProps={{
          title: `Crediting invoice ${helper.invoice.number}`,

          styles: { subText: { marginBottom: 16 } }
        }}
        onConfirm={() => form.submit()}
        onCancel={() => submitDialogType(undefined)}
      >
        <Stack>
          {noSelectedItems && (
            <NoDataTile
              textProps={{
                text: "Please select an allocation to refund"
              }}
              showBoxShadow={false}
              linkProps={{ hidden: true }}
            />
          )}
          {getInfoJSX()}
          {owingItems.length > 0 && (
            <MessageBar messageBarType={MessageBarType.warning}>
              <Stack>
                {getMessageBarText(owingItems, paidItems).map(x => (
                  <Text key={x.key} variant="small">
                    {x.text}
                  </Text>
                ))}
              </Stack>
            </MessageBar>
          )}
        </Stack>
      </ConfirmDialog>
    );
  });
