import { FormApi } from "final-form";

import { DateTime } from "@bps/utils";
import {
  BloodPressureDataItemDto,
  ClinicalDataType,
  EncounterClinicalDataDto,
  HandDataItemDto,
  NailDataItemDto,
  PulseDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { GeneralExaminationFields } from "./GeneralExaminationFields.tsx";
import {
  BloodPressure,
  GeneralExaminationFormValues,
  Pulse
} from "./GeneralExaminationFormValues.ts";
import { calculateBMI } from "./utils.ts";
import { GeneralExamFormValidator } from "./validator/GeneralExaminationFormValidator.ts";

interface GeneralExaminationFormProps {
  clinicalRecord: ClinicalRecord;
}

const validator = new GeneralExamFormValidator();

export const GeneralExaminationForm: React.FunctionComponent<
  GeneralExaminationFormProps
> = ({ clinicalRecord }) => {
  const { clinical, notification } = useStores();
  const generalValues = clinicalRecord.stashedClinicalData?.generalExamination;
  const initialValues: GeneralExaminationFormValues = {
    ...generalValues,
    nails: generalValues?.nails?.map(x => x.nail),
    hands: generalValues?.hands?.map(x => x.hand),
    temperature: generalValues?.temperature?.toString(),
    respiratoryRate: generalValues?.respiratoryRate?.toString(),
    o2SaturationRate: generalValues?.o2SaturationRate?.toString(),
    hydrationRate: generalValues?.hydrationRate?.toString(),
    weight: generalValues?.weight?.toString(),
    height: generalValues?.height?.toString(),
    neck: generalValues?.neck?.toString(),
    waist: generalValues?.waist?.toString(),
    hip: generalValues?.hip?.toString(),
    bGLLevel: generalValues?.bGLLevel?.toString(),
    pulses: !generalValues?.pulses?.length
      ? [
          {
            heartRate: undefined,
            position: undefined,
            method: undefined,
            timeStamp: DateTime.now().toISO()
          }
        ]
      : generalValues.pulses.map(x => {
          const pulse: Pulse = {
            heartRate: x.heartRate?.toString(),
            position: x.position,
            method: x.method,
            timeStamp: x.timeStamp
          };
          return pulse;
        }),
    bloodPressures: !generalValues?.bloodPressures?.length
      ? [
          {
            systolic: undefined,
            diastolic: undefined,
            arm: undefined,
            bPPosition: undefined,
            timeStamp: DateTime.now().toISO()
          }
        ]
      : generalValues.bloodPressures.map(x => {
          const bp: BloodPressure = {
            systolic: x.systolic?.toString(),
            diastolic: x.diastolic?.toString(),
            arm: x.arm,
            bPPosition: x.bPPosition,
            timeStamp: x.timeStamp
          };
          return bp;
        }),
    bmi:
      !!generalValues?.weight && !!generalValues?.height
        ? calculateBMI(generalValues.weight, generalValues.height).toFixed(2)
        : undefined
  };

  const onCancel = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.GeneralExamination
    );
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "generalExamination",
      "generalExamConfirmed"
    ]);
  };

  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const cleanBloodPressure = (bloodPressures: BloodPressure[]) => {
    return bloodPressures.filter(p => !(!p.systolic && !p.diastolic));
  };

  const cleanPulse = (pulses: Pulse[]) => {
    return pulses.filter(p => !!p.heartRate);
  };

  const getClinicalData = (values: GeneralExaminationFormValues) => {
    const { nails, hands, pulses } = values;
    const eTag = clinicalRecord.clinicalData?.generalExamination?.eTag;

    const confirmedETag =
      clinicalRecord.clinicalData?.generalExamConfirmed?.eTag;

    const clinicalData: EncounterClinicalDataDto = {};
    clinicalData.generalExamination = {
      skinDiscolouration: values.skinDiscolouration,
      skinCyanosisPattern: values.skinCyanosisPattern,
      pulseRhythm: values.pulseRhythm,
      pulseCharacteristic: values.pulseCharacteristic,
      o2SaturationRate: values.o2SaturationRate
        ? parseFloat(values.o2SaturationRate)
        : undefined,
      hydration: values.hydration,
      hydrationRate: values.hydrationRate
        ? parseFloat(values.hydrationRate)
        : undefined,
      nails: nails
        ? nails.map(x => {
            const nail: NailDataItemDto = {
              nail: x
            };
            return nail;
          })
        : undefined,
      hands: hands
        ? hands.map(x => {
            const hand: HandDataItemDto = {
              hand: x
            };
            return hand;
          })
        : undefined,
      pulses: pulses.map(x => {
        const pulse: PulseDataItemDto = {
          heartRate: x.heartRate ? parseFloat(x.heartRate) : undefined,
          position: x.position,
          method: x.method,
          timeStamp: x.timeStamp
        };
        return pulse;
      }),
      temperature: values.temperature
        ? parseFloat(values.temperature)
        : undefined,
      temperatureMethod: values.temperatureMethod,

      respiratoryRate: values.respiratoryRate
        ? parseFloat(values.respiratoryRate)
        : undefined,
      weight: values.weight ? parseFloat(values.weight) : undefined,
      height: values.height ? parseFloat(values.height) : undefined,
      bloodPressures: values.bloodPressures.map(x => {
        const bp: BloodPressureDataItemDto = {
          systolic: x.systolic ? parseFloat(x.systolic) : undefined,
          diastolic: x.diastolic ? parseFloat(x.diastolic) : undefined,
          arm: x.arm,
          bPPosition: x.bPPosition,
          timeStamp: x.timeStamp
        };
        return bp;
      }),
      bGLFasting: values.bGLFasting,
      bGLLevel: values.bGLLevel ? parseFloat(values.bGLLevel) : undefined,
      bodyHabitus: values.bodyHabitus,
      neck: values.neck ? parseFloat(values.neck) : undefined,
      hip: values.hip ? parseFloat(values.hip) : undefined,
      waist: values.waist ? parseFloat(values.waist) : undefined,
      comment: values.comment,
      eTag
    };

    clinicalData.generalExamConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };

    return clinicalData;
  };

  const submitData = async (values: GeneralExaminationFormValues) => {
    const bloodPressures = cleanBloodPressure(values.bloodPressures);
    const pulses = cleanPulse(values.pulses);
    const clinicalData: EncounterClinicalDataDto = getClinicalData({
      ...values,
      bloodPressures,
      pulses
    });

    await clinicalRecord.saveClinicalData(clinicalData);
  };

  const onSubmitSucceeded = (
    values: GeneralExaminationFormValues,
    form: FormApi<GeneralExaminationFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.GeneralExamination
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onCancel();
    }
  };

  const getValues = (stashedData?: StashedEncounterClinicalData) => {
    const dirty = stashedData?.dirtyGeneralExaminationForm;

    const generalExamniationFormAlreadyConfirmed =
      (stashedData?.originalDto?.generalExamConfirmed &&
        stashedData.originalDto?.generalExamConfirmed.createLog
          ?.createdEncounterId === clinicalRecord.openEncounter?.id) ??
      false;

    const isNewGeneralExamination =
      !clinicalRecord?.clinicalData?.generalExamConfirmed?.createLog;

    return {
      dirty,
      generalExamniationFormAlreadyConfirmed,
      isNewGeneralExamination
    };
  };

  const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
    const {
      dirty,
      generalExamniationFormAlreadyConfirmed,
      isNewGeneralExamination
    } = getValues(stashedData);

    return (
      (isNewGeneralExamination || generalExamniationFormAlreadyConfirmed) &&
      !dirty
    );
  };
  return (
    <ClinicalSubmissionForm<GeneralExaminationFormValues>
      formName="general"
      autoFocus={false}
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues}
      validate={validator.validate}
      readOnly={isViewOnlyOrDischarged}
      disableRoutePrompt
      heading="General"
      onCancel={onCancel}
      disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.generalExamConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.generalExamConfirmed
      )}
      noGap
      hasSeparator
    >
      <>
        <GeneralExaminationFields />
        <StashedClinicalDataFormSpy<GeneralExaminationFormValues>
          clinicalRecord={clinicalRecord}
          getData={getClinicalData}
          areasToObserve={{
            generalExamination: [ClinicalDataType.GeneralExamination]
          }}
        />
      </>
    </ClinicalSubmissionForm>
  );
};
