import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  IFontWeight,
  Text
} from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { AllocationFieldValues } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";

import { getCalculation } from "../utils.ts";

type AllocationTotalValueLabelProps<K> = {
  items: K[];
  iterator: (
    item:
      | AllocationFieldValues
      | InvoiceItemFormValue
      | {
          [key: string]: string;
        }
  ) => number;
  dataAttr?: string;
  fontWeight?: IFontWeight;
};

export const AllocationTotalValueLabel = <K extends {}>({
  items,
  iterator,
  fontWeight,
  dataAttr
}: AllocationTotalValueLabelProps<K>) => {
  return (
    <Text
      {...dataAttribute(DataAttributes.Element, dataAttr)}
      styles={{
        root: {
          fontWeight: fontWeight ? fontWeight : FontWeights.regular,
          width: "100%"
        }
      }}
    >
      {currencyFormat(
        getCalculation({
          items,
          iterator
        }),
        { currency: "" }
      )}
    </Text>
  );
};
