import { IDropdownOption } from "@bps/fluent-ui";
import { DateTime, ordinalNumber } from "@bps/utils";
import { NonCustomTemplateName } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { isJson } from "@libs/utils/file.utils.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { DocumentStore } from "@stores/documents/DocumentStore.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import {
  DocumentEditor,
  DocumentEditorKeyDownEventArgs
} from "@syncfusion/ej2-react-documenteditor";

export type VisitData = {
  encounterId: string;
  date: DateTime;
};
export const getOptions = (visits: VisitData[] | undefined) => {
  const data: IDropdownOption[] = [];
  if (visits && visits.length > 0) {
    return visits.map((x, index) => ({
      key: x.encounterId.toString(),
      text: `${ordinalNumber(
        index + 1
      )} Consult - ${x.date.toDayDefaultFormat()}`
    }));
  }
  return data;
};

export const getCheckedItems = (
  visits: VisitData[] | undefined,
  checkedItems: string[]
) => {
  return visits && visits.length > 0
    ? visits
        .filter((x, index) => checkedItems?.includes((index + 1).toString()))
        .map(x => x.encounterId)
    : [];
};

export const onSyncFusionEditorKeyDown = (
  args: DocumentEditorKeyDownEventArgs
) => {
  const { event } = args;
  const keyCode = event.which || event.keyCode;
  const { key } = event;
  const CTRL_KEY_CODE = 17;
  const S_KEY_CODE = 83;

  const isCtrlKey =
    event.ctrlKey || event.metaKey ? true : keyCode === CTRL_KEY_CODE;

  if (isCtrlKey && (keyCode === S_KEY_CODE || key.toLowerCase() === "s")) {
    args.isHandled = true;
  }
};

export const initialiseDocEditor = (
  content: string,
  docEditor: React.MutableRefObject<DocumentEditor | undefined>,
  additionalOptions: { goToHeader?: boolean } = {}
) => {
  const timer = setTimeout(() => {
    if (docEditor.current && content) {
      if (isJson(content)) docEditor.current.open(content);
    }
    if (docEditor.current) {
      docEditor.current.keyDown = onSyncFusionEditorKeyDown;
      if (additionalOptions.goToHeader) {
        docEditor.current.selection.goToHeader();
      }
    }
  }, 20);
  return () => clearTimeout(timer);
};

export const getDefaultLetterheadId = async (
  core: CoreStore,
  practice: PracticeStore,
  document: DocumentStore
) => {
  let documentSettings: OrgUnitDocumentSettingsDto | undefined;
  const orgUnitId = core.location.parentOrgUnit?.id;

  if (orgUnitId) {
    const orgUnit = await practice.getOrgUnit(orgUnitId);
    documentSettings = orgUnit?.orgUnitDocumentSettings;
  }

  let letterheadId: string | undefined;
  if (documentSettings) {
    const templates = await document.getTemplates({
      name: NonCustomTemplateName.PracticeLetterhead,
      isLetterhead: true
    });

    const practiceLetterhead = templates.find(template => !template.isCustom);
    letterheadId = practiceLetterhead?.id;
  }
  return letterheadId;
};

/**
 * min-max normalization: rescaling the value to specific range
 * Equation: (value - min) / (max - min) * range (in our case, the min value will be always 0)
 * (value - min) / (max - min): this will compress the value to ( 0 ~ 1) range
 * then we coulde scale up the value based on range given
 * @param size
 * @param maxValue
 * @param range
 * @returns normalized value
 */
export const normalizedImageSize = (
  size: number,
  maxValue: number,
  range: number
) => {
  return (size / maxValue) * range;
};
