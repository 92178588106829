import { action, computed, observable } from "mobx";

import { IColumn } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  CalendarEventReminderStatus,
  CalendarEventStatus,
  CalendarEventStatusText,
  DateType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { OutboundCommStatuses } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import {
  IMaybePromiseObservable,
  maybePromiseObservable
} from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { CalendarEventReminderSearchModel } from "@stores/booking/models/CalendarEventReminderSearchModel.ts";

import { AppointmentStatusTextValues } from "../../booking-calendar/components/shared-components/appointment-status/utils.ts";
import { EventReminderFilterInternal } from "../components/EventReminderFilters.tsx";
import {
  EventReminderDateFilterTypes,
  EventReminderDateFilterValues,
  EventReminderTreeViewSize
} from "../components/utils.tsx";
import {
  EventReminderCurrentData,
  EventReminderFilters,
  EventReminderState,
  EventReminderStateDisplayType,
  EventReminderStateItem,
  EventReminderStatesText
} from "../EventReminderScreen.types.ts";

export class EventReminderScreenModel {
  constructor(private booking: BookingStore) {}

  eventReminderSearchResults: IMaybePromiseObservable<
    CalendarEventReminderSearchModel[]
  > = maybePromiseObservable();

  @observable
  activeReminderState: EventReminderStateItem =
    EventReminderScreenModel.baseStateItems()[0];

  private static baseStateItems() {
    const items: EventReminderStateItem[] = [
      {
        title: EventReminderStatesText.Sent,
        state: OutboundCommStatuses.Sent,
        count: 0,
        displayType: EventReminderStateDisplayType.Pill,
        statusFromApi: false
      },
      {
        title: EventReminderStatesText.Confirmed,
        state: CalendarEventReminderStatus.CONFIRMED,
        count: 0,
        displayType: EventReminderStateDisplayType.Pill,
        statusFromApi: true
      },
      {
        title: EventReminderStatesText.Declined,
        state: CalendarEventReminderStatus.DECLINED,
        count: 0,
        displayType: EventReminderStateDisplayType.Pill,
        statusFromApi: true
      },
      {
        title: EventReminderStatesText.RequiresReview,
        state: CalendarEventReminderStatus.REQUIRESREVIEW,
        count: 0,
        displayType: EventReminderStateDisplayType.Pill,
        statusFromApi: true
      },
      {
        title: EventReminderStatesText.NoReply,
        state: CalendarEventReminderStatus.NOREPLY,
        count: 0,
        displayType: EventReminderStateDisplayType.Pill,
        statusFromApi: true
      },
      {
        title: EventReminderStatesText.All,
        state: EventReminderFilters.All,
        count: 0,
        displayType: EventReminderStateDisplayType.Link,
        statusFromApi: false
      },
      {
        title: EventReminderStatesText.NotSent,
        state: OutboundCommStatuses.NotSent,
        count: 0,
        displayType: EventReminderStateDisplayType.Link,
        statusFromApi: false
      }
    ];
    return items;
  }

  @computed
  get totalItems() {
    return (this.eventReminderSearchResults.value ?? []).length;
  }

  @computed
  get remindersNotSent() {
    return this.notSentFilter(this.eventReminderSearchResults.value ?? []);
  }

  @observable
  eventReminder: CalendarEventReminderSearchModel | undefined;

  @observable
  currentEventData: EventReminderCurrentData | undefined;

  get core() {
    return this.booking.core;
  }

  get today() {
    return DateTime.today().toJSDate();
  }

  get tomorrow() {
    const _today = this.today;
    _today.setDate(this.today.getDate() + 1);
    return _today;
  }

  get yesterday() {
    const _today = this.today;
    _today.setDate(this.today.getDate() - 1);
    return _today;
  }

  public load = async (
    startDate?: Date,
    endDate?: Date,
    dateType?: DateType
  ) => {
    if (!this.eventReminderSearchResults.pending) {
      const startDateTime = DateTime.fromJSDate(
        startDate ?? this.today
      ).toISO();

      const endDateTime = // for initial data fetching with no start and endDate, we are setting end of date for end data time
        !startDate && !endDate
          ? DateTime.fromJSDate(endDate ?? this.today)
              .endOf("day")
              .toISO()
          : DateTime.fromJSDate(endDate ?? this.today).toISO();

      this.eventReminderSearchResults.set(
        this.booking.loadCalendarEventRemindersBySearchArgs(
          {
            dateType: dateType ?? DateType.CalendarEvent,
            orgUnitId: this.core.orgUnitsMap.get(this.core.locationId)?.id,
            startDateTime,
            endDateTime
          },
          { loadCalendarEventReminderContacts: true }
        )
      );
    }
  };

  @computed
  get eventReminderStatesMap() {
    const newMap = new Map<EventReminderState, EventReminderStateItem>();

    const results = this.eventReminderSearchResults.value;
    if (results === undefined) {
    } else {
      const totalRemindersSent = this.allSentFilter(results);
      const items = EventReminderScreenModel.baseStateItems();

      items.forEach(stateMenu => {
        const menu = { ...stateMenu };

        // These 3 menu items are manually calculated
        if (stateMenu.state === EventReminderFilters.All) {
          menu.count = results.length;
          newMap.set(menu.state, menu);
        }

        if (stateMenu.state === OutboundCommStatuses.NotSent) {
          menu.count = this.remindersNotSent.length;
          newMap.set(menu.state, menu);
        }

        if (stateMenu.state === OutboundCommStatuses.Sent) {
          menu.count = totalRemindersSent.length;
          newMap.set(menu.state, menu);
        }

        // These menu items have reply status codes that come from the API, so we calculate these using their codes as a key
        if (stateMenu.statusFromApi) {
          const collection = results.filter(
            x =>
              x.attendanceStatus ===
              CalendarEventReminderStatus[stateMenu.state]
          );
          menu.count = collection.length;
          newMap.set(menu.state, menu);
        }
      });
    }

    return newMap;
  }

  // front end filtering of searched items
  public getFilteredEventReminders = (
    filter?: Omit<
      EventReminderFilterInternal,
      "endDate" | "startDate" | "dateType"
    >,
    items?: CalendarEventReminderSearchModel[]
  ) => {
    let result = items;

    if (!filter || !result) return [];

    if (filter.appointmentType && filter.appointmentType.length) {
      result = result.filter(
        x =>
          filter.appointmentType &&
          filter.appointmentType.includes(x.appointmentType)
      );
    }
    if (filter.appointmentStatus && filter.appointmentStatus.length) {
      result = result.filter(x => {
        let appointmentStatus = x.attendeeStatus
          ? x.attendeeStatus
          : x.appointmentStatus;
        if (x.attendeeEncounterStatus === CalendarEventStatus.Cancelled) {
          appointmentStatus = x.attendeeEncounterStatus;
        }
        return (
          filter.appointmentStatus &&
          filter.appointmentStatus.includes(appointmentStatus)
        );
      });
    }
    if (filter.location && filter.location.length) {
      result = result.filter(x => filter.location?.includes(x.locationName));
    }
    if (filter.deliveryStatus && filter.deliveryStatus.length) {
      result = result.filter(
        x => filter.deliveryStatus?.includes(x.deliveryStatus)
      );
    }
    if (filter.appointmentTime && filter.appointmentTime.length) {
      const selectedTimeRanges = filter.appointmentTime?.map(x => {
        const startTime = Number(x);
        return {
          startTime,
          endTime: startTime + 1
        };
      });
      result = result.filter(x => {
        const startHour = x.calendarEvent.startDateTime.hour;
        return (
          selectedTimeRanges.find(
            y => y.startTime <= startHour && y.endTime > startHour
          ) !== undefined
        );
      });
    }
    if (filter.providerName && filter.providerName.length) {
      result = result.filter(x => filter.providerName?.includes(x.providerId));
    }
    if (
      filter.patientName &&
      filter.patientName !== "" &&
      filter.patientName.trim() !== ""
    ) {
      result = result.filter(x =>
        !x.patientName || x.patientName === "" || x.patientName.trim() === ""
          ? false
          : filter.patientName &&
            x.patientName
              .toLowerCase()
              .includes(filter.patientName.trim().toLowerCase())
      );
    }
    if (
      filter.phoneNumber &&
      filter.phoneNumber !== "" &&
      filter.phoneNumber.trim() !== ""
    ) {
      result = result.filter(x =>
        !x.phoneNumber || x.phoneNumber === "" || x.phoneNumber.trim() === ""
          ? false
          : filter.phoneNumber &&
            x.phoneNumber.includes(filter.phoneNumber.trim())
      );
    }

    const { state } = this.activeReminderState;

    if (
      state === CalendarEventReminderStatus.CONFIRMED ||
      state === CalendarEventReminderStatus.DECLINED ||
      state === CalendarEventReminderStatus.REQUIRESREVIEW ||
      state === CalendarEventReminderStatus.NOREPLY
    ) {
      result = result.filter(x => x.attendanceStatus === state);
    }

    if (state === OutboundCommStatuses.NotSent) {
      result = this.notSentFilter(result);
    }

    if (state === OutboundCommStatuses.Sent) {
      result = this.allSentFilter(result);
    }

    return result;
  };

  private sorting = (currColumn: IColumn, a: string, b: string) =>
    currColumn.isSortedDescending
      ? b.trim().toLowerCase().localeCompare(a.trim().toLowerCase())
      : a.trim().toLowerCase().localeCompare(b.trim().toLowerCase());

  private getAppointmentStatusFromCode = (
    item: CalendarEventReminderSearchModel
  ) => {
    if (item.appointmentStatus) {
      return item.calendarEvent.status === CalendarEventStatus.Confirmed
        ? AppointmentStatusTextValues[item.appointmentStatus]
        : CalendarEventStatusText[item.appointmentStatus];
    } else return "";
  };

  public getSortedAppointmentReminders = action((column: IColumn) => {
    const newColumns: IColumn[] = this.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      currCol => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === column.key) {
        currColumn.isSorted = true;
        currColumn.isSortedDescending = !column.isSortedDescending;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    let newItems: CalendarEventReminderSearchModel[] = [];

    const items = this.eventReminderSearchResults.value ?? [];
    switch (currColumn.key) {
      case "appointmentType":
        newItems = items
          .slice(0)
          .sort((a, b) =>
            this.sorting(currColumn, a.appointmentType, b.appointmentType)
          );
        break;
      case "appointmentStatus":
        newItems = items
          .slice(0)
          .sort((a, b) =>
            this.sorting(
              currColumn,
              this.getAppointmentStatusFromCode(a),
              this.getAppointmentStatusFromCode(b)
            )
          );
        break;
      case "patientName":
        newItems = items
          .slice(0)
          .sort((a, b) =>
            this.sorting(currColumn, a.patientName, b.patientName)
          );
        break;
      case "providerName":
        newItems = items
          .slice(0)
          .sort((a, b) =>
            this.sorting(currColumn, a.providerName, b.providerName)
          );
        break;
      case "phoneNumber":
        newItems = items
          .slice(0)
          .sort((a, b) =>
            this.sorting(currColumn, a.phoneNumber, b.phoneNumber)
          );
        break;
      case "location":
        newItems = items
          .slice(0)
          .sort((a, b) =>
            this.sorting(currColumn, a.locationName, b.locationName)
          );
        break;
      case "deliveryStatus":
        newItems = items
          .slice(0)
          .sort((a, b) =>
            this.sorting(currColumn, a.deliveryStatus, b.deliveryStatus)
          );
        break;
      default:
        newItems = this.sortReminders(
          items,
          currColumn.key,
          currColumn.isSortedDescending
        );
        break;
    }
    this.setColumns(newColumns);
    this.eventReminderSearchResults.set(
      new Promise(resolve => resolve(newItems))
    );
  });

  private allSentFilter(result: CalendarEventReminderSearchModel[]) {
    return result.filter(
      x =>
        x.message?.deliveryStatusCode === OutboundCommStatuses.Sent ||
        x.message?.deliveryStatusCode === OutboundCommStatuses.Delivered
    );
  }

  private notSentFilter(result: CalendarEventReminderSearchModel[]) {
    return result.filter(
      x =>
        x.reminder === undefined ||
        x.message?.deliveryStatusCode === OutboundCommStatuses.NotSent
    );
  }

  private sortReminders<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }

  public handleFilterDateChange = async (options: {
    startDate: Date | undefined;
    endDate: Date | undefined;
    dateType: DateType;
  }) => {
    const { startDate, endDate, dateType } = options;
    if (!startDate || !endDate || !dateType) return;

    await this.load(startDate, endDate, dateType);
  };

  @observable
  eventReminderSidePanelSize: EventReminderTreeViewSize =
    EventReminderTreeViewSize.Expanded;

  @action
  toggleSidePanel = (isExpanding: boolean) => {
    if (isExpanding) {
      this.eventReminderSidePanelSize = EventReminderTreeViewSize.Expanded;
    } else {
      this.eventReminderSidePanelSize = EventReminderTreeViewSize.IconsOnly;
    }
  };

  @computed
  get eventReminderStates(): EventReminderStateItem[] {
    return Array.from(this.eventReminderStatesMap.values());
  }

  @action
  setEventReminderState = (item: EventReminderStateItem) => {
    this.activeReminderState = item;
  };

  @action
  updateEventReminderStateCount = (stateMenu: EventReminderStateItem) => {
    this.eventReminderStatesMap.set(stateMenu.state, stateMenu);
  };

  @action
  setEventReminder = (data: CalendarEventReminderSearchModel | undefined) => {
    this.eventReminder = data;
  };

  @action
  setCurrentEventData = (data: EventReminderCurrentData | undefined) => {
    this.currentEventData = data;
  };

  @action
  setColumns = (data: IColumn[]) => {
    this.columns = data;
  };

  @observable
  public columns: IColumn[] = [];

  public getDatesRangePickerFieldText = (options: {
    startDate: Date | undefined;
    endDate: Date | undefined;
    dateType: DateType;
  }) => {
    const { startDate, endDate, dateType } = options;

    const todayDateTime = DateTime.jsDateToISODate(this.today);
    const yesterdayDateTime = DateTime.jsDateToISODate(this.yesterday);

    const tomorrowDateTime = DateTime.jsDateToISODate(this.tomorrow);

    const startDateTime = DateTime.jsDateToISODate(startDate ?? this.today);

    const endDateTime = DateTime.jsDateToISODate(endDate ?? this.today);

    if (todayDateTime === startDateTime && todayDateTime === endDateTime) {
      return `${EventReminderDateFilterTypes[dateType]} | ${EventReminderDateFilterValues.Today}`;
    }

    if (
      startDateTime === tomorrowDateTime &&
      endDateTime === tomorrowDateTime
    ) {
      return `${EventReminderDateFilterTypes[dateType]} | ${EventReminderDateFilterValues.Tomorrow}`;
    }

    if (
      startDateTime === yesterdayDateTime &&
      endDateTime === yesterdayDateTime
    ) {
      return `${EventReminderDateFilterTypes[dateType]} | ${EventReminderDateFilterValues.Yesterday}`;
    }

    return `${EventReminderDateFilterTypes[dateType]} | ${EventReminderDateFilterValues.Custom}`;
  };
}
