import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { CenteredLargeSpinner, Heading, PrimaryButton } from "@bps/fluent-ui";
import { clone } from "@bps/utils";
import {
  CodedFieldClinicalDataItemDto,
  EncounterClinicalDataDto,
  MedicalHistoryClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { MedicalHistoryDialogLabels } from "./MedicalHistory.types.ts";
import { MedicalHistoryFormFields } from "./MedicalHistoryFormFields.tsx";
import { MedicalHistoryFormValidator } from "./MedicalHistoryFormValidator.ts";
import {
  MedicalHistoryFormValues,
  TerminologyData
} from "./MedicalHistoryFormValues.ts";
import {
  addMedicalHistoryItemFromForm,
  editMedicalHistoryItemFromForm,
  getInitialDateValues,
  getSelectedDiagnosisSides
} from "./utils.ts";

export interface MedicalHistoryFormDialogProps {
  hidden: boolean;
  isPrescribed?: boolean;
  selectedDiagnosis?: MedicalHistoryClinicalDataItemDto;
  medicalHistories?: MedicalHistoryClinicalDataItemDto[];
  onDismiss: () => void;
}

export const MedicalHistoryFormDialog: React.FunctionComponent<MedicalHistoryFormDialogProps> =
  observer(
    ({
      hidden,
      selectedDiagnosis,
      onDismiss,
      medicalHistories,
      isPrescribed
    }) => {
      const addNewAfterSubmit = useRef<boolean>(false);
      const { clinicalRecord, getUpdatedDiagnosis } =
        usePatientRecordScreenContext();

      const medicalHistory = clinicalRecord?.medicalHistories.find(
        medicalHistory => medicalHistory.id === selectedDiagnosis?.id
      );

      const { clinical, core } = useStores();
      if (hidden) {
        return null;
      }

      const isEditForm = medicalHistory !== undefined;
      const title = isEditForm
        ? MedicalHistoryDialogLabels.EditMedicalHistory
        : MedicalHistoryDialogLabels.AddToMedicalHistory;

      const getInitialValues = (): MedicalHistoryFormValues | undefined => {
        const diagnoses = clinicalRecord.medicalHistories.filter(
          x =>
            x.episodeOfCareId === clinicalRecord.openEncounter?.episodeOfCareId
        );

        const diagnosis = diagnoses.find(x => x.isPrimary === true);
        const isPrimary =
          !diagnoses.length ||
          !diagnosis ||
          diagnosis.id === medicalHistory?.id;

        if (!medicalHistory) {
          return {
            active: true,
            saveAsReasonForVisit: false,
            clinicallySignificant: false,
            confidential: false,
            diagnosisSideSelected: [],
            isPrimary
          };
        }

        const diagnosisCode = medicalHistory.diagnosisCode;

        const diagnosisKey =
          diagnosisCode &&
          clinical.getTerminologyKey(
            diagnosisCode.code,
            diagnosisCode.originalText
          );

        return {
          diagnosisKey,
          active: !!medicalHistory.active,
          certainty: medicalHistory.certainty,
          details: medicalHistory.details,
          severity: medicalHistory.severity,
          chronicity: medicalHistory.chronicity,
          fracture: medicalHistory.fracture,
          fractureTypes: medicalHistory.fractureTypes,
          clinicallySignificant: medicalHistory.clinicallySignificant,
          confidential: !!medicalHistory.secGroupId,
          diagnosisSideSelected: getSelectedDiagnosisSides(
            medicalHistory.diagnosisSide
          ),
          linkToCondition:
            medicalHistory.episodeOfCareId === clinicalRecord.episodeOfCare?.id,
          isPrimary,
          primaryDiagnosis: !core.hasPermissions(
            Permission.MedicalHistoryDiagnosisSyncAllowed
          )
            ? medicalHistory.episodeOfCareId ===
              clinicalRecord.episodeOfCare?.id
            : false,
          ...getInitialDateValues(medicalHistory)
        };
      };

      const fetch = async (root: RootStore) => {
        const { clinical } = root;

        const promises = Promise.all([
          clinicalRecord.loadPatient(),
          clinical.ref.medicalCertainties.load()
        ]);

        const [contact] = await promises;

        return { contact };
      };

      const getExtraButtonBetween = (
        form: FormApi<MedicalHistoryFormValues>,
        isDisabled: boolean
      ): JSX.Element | null => {
        const hasMDH = medicalHistory;
        if (!hasMDH) {
          return (
            <PrimaryButton
              text="Save & add another"
              onClick={onSaveNewButtonClick(form)}
              disabled={isDisabled}
            />
          );
        }
        return null;
      };

      const getSubmitButtonProps = (form: FormApi<MedicalHistoryFormValues>) =>
        !medicalHistory
          ? {
              onClick: () => form.submit(),
              text: "Save",
              iconProps: { hidden: true }
            }
          : undefined;

      const onSaveNewButtonClick =
        (form: FormApi<MedicalHistoryFormValues>) => () => {
          addNewAfterSubmit.current = true;
          form.submit();
        };

      const addEpisodeOfCare = (
        medicalHistoryValues: MedicalHistoryFormValues
      ) => {
        if (
          core.hasPermissions(Permission.MedicalHistoryDiagnosisSyncAllowed)
        ) {
          return medicalHistoryValues.linkToCondition
            ? clinicalRecord.episodeOfCare?.id
            : undefined;
        } else {
          return medicalHistoryValues.primaryDiagnosis
            ? clinicalRecord.episodeOfCare?.id
            : undefined;
        }
      };

      const onSubmit = async (
        medicalHistoryValues: MedicalHistoryFormValues
      ) => {
        let clinicalData: EncounterClinicalDataDto | undefined;
        let isDiagnosis, isProcedure: boolean | undefined;
        let updatedDiagnosisCode: CodedFieldClinicalDataItemDto | undefined =
          medicalHistory && medicalHistory.diagnosisCode
            ? clone(medicalHistory.diagnosisCode)
            : undefined;
        const episodeOfCareId = addEpisodeOfCare(medicalHistoryValues);

        const secGroupId =
          medicalHistory?.secGroupId ?? core.user?.privateSecGroupId;

        // Edit med history
        if (medicalHistory?.id) {
          clinicalData = await editMedicalHistoryItemFromForm({
            id: medicalHistory.id,
            clinicalRecord,
            medicalHistoryValues,
            clinicalStore: clinical,
            episodeOfCareId,
            confidential: medicalHistoryValues.confidential,
            secGroupId
          });
        } else {
          // Add med history
          const { saveAsReasonForVisit, diagnosisKey } = medicalHistoryValues;

          let diagnosisText: string | undefined,
            diagnosisCode: string | undefined,
            diagnosisVersion: string | undefined;

          if (diagnosisKey) {
            const diagnosisTerminology =
              clinical.getTerminologyFromMap(diagnosisKey);

            diagnosisText = diagnosisTerminology && diagnosisTerminology.text;
            diagnosisCode = diagnosisTerminology && diagnosisTerminology.code;
            diagnosisVersion =
              diagnosisTerminology && diagnosisTerminology.version;

            isDiagnosis = diagnosisTerminology?.isDiagnosis;
            isProcedure = diagnosisTerminology?.isProcedure;
          }

          clinicalData = await addMedicalHistoryItemFromForm({
            clinicalRecord,
            medicalHistoryValues,
            clinicalStore: clinical,
            isDiagnosis,
            isProcedure,
            episodeOfCareId,
            confidential: medicalHistoryValues.confidential,
            secGroupId: core.user?.privateSecGroupId
          });
          clinicalData.reasonForVisit =
            saveAsReasonForVisit && diagnosisText && diagnosisCode
              ? {
                  eTag: clinicalRecord.clinicalData?.reasonForVisit?.eTag,
                  reasonForVisits: [
                    ...(clinicalRecord.clinicalData?.reasonForVisit
                      ?.reasonForVisits || []),
                    {
                      code: diagnosisCode!,
                      originalText: diagnosisText,
                      version: diagnosisVersion
                    }
                  ]
                }
              : undefined;

          if (diagnosisText && diagnosisCode) {
            updatedDiagnosisCode = {
              code: diagnosisCode,
              originalText: diagnosisText,
              version: diagnosisVersion
            };
          }
        }

        if (
          !core.hasPermissions(Permission.MedicalHistoryDiagnosisSyncAllowed)
        ) {
          if (updatedDiagnosisCode && medicalHistoryValues.primaryDiagnosis) {
            const updatedDiagnosis = await getUpdatedDiagnosis(
              updatedDiagnosisCode,
              medicalHistoryValues.primaryDiagnosis,
              medicalHistoryValues.diagnosisSideSelected
            );
            if (updatedDiagnosis) {
              clinicalData.diagnoses = {
                eTag: clinicalRecord.clinicalData?.diagnoses?.eTag,
                diagnoses: updatedDiagnosis
              };
            }
          }

          if (episodeOfCareId && clinicalData.diagnoses) {
            const episodeOfCare =
              await clinical.getEpisodeOfCare(episodeOfCareId);

            const newEpisodeOfCareData = {
              ...episodeOfCare,
              diagnoses: clinicalData.diagnoses.diagnoses,
              injuryDate:
                medicalHistoryValues.date?.toDateString() ??
                episodeOfCare.injuryDate
            };

            await clinical.updateEpisodeOfCare(
              newEpisodeOfCareData,
              episodeOfCareId
            );
          }
        }
        clinicalData && (await clinicalRecord.saveClinicalData(clinicalData));
      };

      const onSubmitSucceeded = (
        _v: MedicalHistoryFormValues,
        form: FormApi<MedicalHistoryFormValues>
      ) => {
        if (addNewAfterSubmit.current) {
          form.restart();
          addNewAfterSubmit.current = false;
        } else {
          onDismiss();
        }
      };

      let terminology: TerminologyData | undefined;

      if (medicalHistory) {
        terminology = {
          terminologyText: medicalHistory?.diagnosisCode?.originalText ?? "",
          terminologyCode: medicalHistory?.diagnosisCode?.code ?? "",
          isDiagnosis: medicalHistory?.isDiagnosis,
          isProcedure: medicalHistory?.isProcedure
        };
      }

      return (
        <DataFetcher fetch={fetch} noExceptionsHandlers>
          {(data, loading, error) => {
            const birthDate = data?.contact?.birthDate
              ? data.contact.birthDate
              : undefined;
            return (
              <SubmissionFormDialog<MedicalHistoryFormValues>
                initialValues={getInitialValues()}
                Fallback={<CenteredLargeSpinner />}
                validate={
                  new MedicalHistoryFormValidator({
                    birthDate: birthDate?.toJSDate(),
                    terminologyMap: clinical.terminologyMap,
                    medicalHistories,
                    selectedId: selectedDiagnosis?.id
                  }).validate
                }
                dialogName={title}
                onSubmit={onSubmit}
                onSubmitSucceeded={onSubmitSucceeded}
                loadingData={loading}
                buttonsProps={form => ({
                  submitButtonProps: getSubmitButtonProps(form),
                  extraActionsBetween: !isPrescribed
                    ? getExtraButtonBetween
                    : undefined,
                  disableSubmitOnFormInvalid: true
                })}
                dataLoadingError={error?.message}
                dialogProps={{
                  minWidth: 680,
                  onDismiss,
                  dialogContentProps: {
                    title: <Heading variant="modal-heading">{title}</Heading>
                  }
                }}
              >
                {() => (
                  <MedicalHistoryFormFields
                    terminologyData={terminology}
                    patient={data?.contact}
                    medicalHistories={medicalHistories}
                    medicalHistory={medicalHistory}
                  />
                )}
              </SubmissionFormDialog>
            );
          }}
        </DataFetcher>
      );
    }
  );
