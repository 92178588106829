import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";

import {
  Callout,
  DirectionalHint,
  FontIcon,
  FontSizes,
  Link,
  Text,
  TextBadge,
  TextBadgeSize,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";
import { TreeView } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { EncounterFormContext } from "./models/EncounterFormContext.ts";

export const ConditionLinkedIndicatorBase: React.FC = observer(() => {
  const { acc, clinical } = useStores();

  const { clinicalRecord } = useContext(EncounterFormContext);

  const condition = clinicalRecord?.condition;

  const theme = useTheme();

  const [showCallout, setShowCallout] = useState(false);

  const { width } = useScreenSize();

  const getCalloutContent = () => {
    const claimNumberText = condition?.claim?.claimNumber
      ? ` | ${condition?.claim?.claimNumber}`
      : "";

    const statusText = acc.ref.claimStatuses.keyTextValues.find(
      x => x.key === condition?.claim?.claimStatus
    )?.text;

    const claimStatusText = statusText ? ` | ${statusText}` : "";

    return (
      <Text styles={{ root: { fontSize: FontSizes.size12 } }}>
        {!!condition
          ? `${condition.primaryDiagnosis}${claimNumberText}${claimStatusText}. `
          : "No linked condition. "}
        <Link
          onClick={() => {
            if (
              clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize ===
              TreeViewSize.IconsOnly
            ) {
              clinical.ui.tabs.currentPatientRecordTab.toggleSidePanel(
                true,
                width
              );
            }

            if (clinical.ui.tabs.currentPatientRecordTab) {
              clinical.ui.tabs.currentPatientRecordTab.sidePanelTab =
                TreeView.Conditions;
            }
          }}
        >
          {!!condition ? "More >>" : "Link"}
        </Link>
      </Text>
    );
  };

  const fontSize = FontSizes.size16;

  const fontIconProps = !!condition
    ? {
        iconName: "BpLinkedCondition",
        styles: {
          root: {
            color: theme.palette.themePrimary,
            fontSize
          }
        }
      }
    : {
        iconName: "BpNoCondition",
        styles: {
          root: {
            color: theme.palette.redDark,
            fontSize
          }
        }
      };

  return (
    <>
      <Callout
        hidden={!showCallout}
        target="#condition-text-badge"
        directionalHint={DirectionalHint.topCenter}
        onDismiss={() => {
          setShowCallout(false);
        }}
        styles={{ root: { padding: 8 } }}
      >
        {getCalloutContent()}
      </Callout>
      <TextBadge
        id="condition-text-badge"
        badgeSize={TextBadgeSize.small}
        onMouseOver={() => {
          setShowCallout(true);
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setShowCallout(false);
          }, 700);
        }}
        styles={{
          root: {
            padding: 4,
            borderRadius: 2,
            background: !!condition
              ? theme.palette.themeLighterAlt
              : theme.semanticColors.errorBackground
          }
        }}
      >
        <FontIcon {...fontIconProps} />
      </TextBadge>
    </>
  );
});

export const ConditionLinkedIndicator = withFetch(
  x => [x.acc.ref.claimStatuses.load()],
  ConditionLinkedIndicatorBase
);
