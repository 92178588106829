import { SubmissionFormStyles } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

export const submissionFromStyles: Partial<SubmissionFormStyles> = {
  root: {
    display: "flex",
    flex: 1,
    height: "100%"
  },
  fields: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  main: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  }
};
