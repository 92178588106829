import { DefaultButton } from "@bps/fluent-ui";
import { ReportListViewModel } from "@modules/reports/screens/context/ReportScreenHelper.ts";
import { csvExports } from "@modules/reports/screens/csv/xero.ts";

export interface ReportExportButtonsProps {
  selectedReport?: ReportListViewModel;
  requestAllData: (tag: string) => void;
  disabled: boolean;
}

export const ReportExportButtons: React.FC<ReportExportButtonsProps> = ({
  selectedReport,
  requestAllData,
  disabled
}) => {
  const validExports = csvExports.filter(
    exp =>
      selectedReport?.baseReport?.metadata &&
      selectedReport?.baseReport?.metadata[exp.matchesMetadata]
  );
  return (
    <>
      {validExports.map(exp => (
        <DefaultButton
          key={exp.matchesMetadata}
          onClick={() => requestAllData(exp.matchesMetadata)}
          disabled={disabled}
        >
          {exp.name}
        </DefaultButton>
      ))}
    </>
  );
};
