import { useEffect, useRef, useState } from "react";

import {
  FieldItemProps,
  FieldItemStyles,
  IExtendedBasePicker,
  IPersonaProps,
  IStackStyles,
  mergeStyleSets,
  Stack
} from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  ContactPickerField,
  ContactPickerFieldProps
} from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { onResolveSuggestionItem } from "@ui-components/pickers/contact-picker/PatientPicker.tsx";

import {
  ContactPickerFieldQuickAdd,
  ContactPickerFieldQuickAddProps
} from "./ContactPickerQuickAdd.tsx";

export type ContactPickerFieldWithAddProps = ContactPickerFieldProps & {
  quickAddProps?: ContactPickerFieldQuickAddProps;
  tagInUseKeys?: string[];
  wrapperStyles?: IStackStyles;
  isPatient?: boolean;
  pickerFormItemStyles?: Partial<Pick<FieldItemStyles, "item" | "root">>;
} & Pick<FieldItemProps, "actionButton">;

/**
 * ContactPickerField component with add new contact functionality. It can be used for both Contact and Patient.
 * The default type is Contact. ⚠️ Use filter prop from ContactPickerFieldProps to specify another type in addition to
 * typeToAdd.
 *
 * @param actionButton - optional feature to render action link;
 * @param typeToAdd - type for adding a contact in QuickAddContactDialog, the default type is Individual
 * @param placeholder - field placeholder, the default value is "Search for person"
 * @param tagInUseKeys - array of ids to prevent selections of already selected contacts if the field is an array item
 * @param wrapperStyles - styles for the root element
 * @param autoFocus - set autofocus flag
 * @param pickerFormItemStyles
 * @param contactPickerFieldProps - ContactPickerFieldProps
 */
export const ContactPickerFieldWithAdd: React.FunctionComponent<
  ContactPickerFieldWithAddProps
> = ({
  placeholder,
  tagInUseKeys,
  wrapperStyles,
  autoFocus,
  actionButton,
  pickerFormItemStyles,
  quickAddProps,
  ...contactPickerFieldProps
}) => {
  const types: (ContactType | string)[] = contactPickerFieldProps.filter
    ?.types ?? [ContactType.Individual];

  const addButtonClicked = useRef<boolean>(false); // used to prevent input autoselecting on blur
  const relatedContactText = useRef<string | undefined>();

  const [pickerRef, setPickerRef] =
    useState<IExtendedBasePicker<IPersonaProps> | null>(null);
  useEffect(() => {
    if (pickerRef && autoFocus) {
      setTimeout(() => {
        pickerRef.focusInput();
      }, 1);
    }
  }, [pickerRef, autoFocus]);

  return (
    <Stack
      horizontalAlign="space-between"
      horizontal
      verticalAlign="start"
      tokens={{ childrenGap: 8 }}
      styles={mergeStyleSets(wrapperStyles, {
        root: {
          position: "relative",
          paddingBottom: 0,
          marginTop: 0
        }
      })}
    >
      <ContactPickerField
        {...contactPickerFieldProps}
        actionButton={
          actionButton
            ? {
                linkProps: {
                  ...actionButton.linkProps,
                  styles: { root: { position: "absolute", right: 0 } }
                }
              }
            : undefined
        }
        filter={{ types }}
        componentRef={ref => {
          if (!pickerRef) {
            setPickerRef(ref);
          }
        }}
        pickerCalloutProps={{
          calloutWidth: 300
        }}
        onInputChange={(value: string) => {
          if (value) {
            relatedContactText.current = value;
          }
          return value;
        }}
        iconName="Search"
        placeholder={placeholder ?? "Search for person"}
        tagInUseKeys={tagInUseKeys?.filter(a => !!a)}
        fieldItemStyle={mergeStyleSets(
          { root: { flex: 1 } },
          pickerFormItemStyles
        )}
        onResolveSuggestionItem={
          types.includes(ContactType.Patient)
            ? onResolveSuggestionItem
            : undefined
        }
        onDismiss={() => !addButtonClicked.current}
      />
      <ContactPickerFieldQuickAdd
        {...quickAddProps}
        {...contactPickerFieldProps}
        addButtonClicked={addButtonClicked}
        relatedContactText={relatedContactText}
      />
    </Stack>
  );
};
