import { DateTime } from "@bps/utils";
import {
  isNotFutureDate,
  isNotFutureTime,
  Length,
  maxLength,
  predicate,
  required,
  validDate,
  validTime
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { currentOutcomeFormValues } from "../Tasks.types.ts";

export class OutcomeValidator extends Validator<currentOutcomeFormValues> {
  constructor() {
    super();
    this.forField("outcomeStatus", [required()]);
    this.forField("date", [required(), isNotFutureDate(), validDate()]);
    this.forField("time", [
      required(),
      validTime(),
      predicate(
        (value, values: currentOutcomeFormValues) =>
          !!value &&
          !!values.date &&
          DateTime.fromJSDate(values.date).toISODate() ===
            DateTime.fromJSDate(DateTime.jsDateNow()).toISODate(),
        isNotFutureTime()
      )
    ]);
    this.forField("comment", maxLength(Length.long));
  }
}
