import {
  FontSizes,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  StackItem,
  Text
} from "@bps/fluent-ui";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { KNOWLEDGEBASE_INFO_LINK } from "./PatientMergeFieldValues.ts";

export const PatientMergeInfo: React.FC = () => {
  const patientLabel = usePatientLabel(false);

  const getText = (text: string, isBold?: boolean) => (
    <Text
      variant="medium"
      styles={{ root: { fontSize: FontSizes.size12 } }}
      bold={isBold}
    >
      {text}
    </Text>
  );
  return (
    <Stack tokens={{ childrenGap: 12 }} styles={{ root: { marginBottom: 16 } }}>
      <MessageBar messageBarType={MessageBarType.warning}>
        <Stack tokens={{ childrenGap: 12 }}>
          {getText(`Check and be sure before you proceed to merge! Merging
            ${usePatientLabel(
              false
            )} records is a permanent change and cannot be
            reversed`)}
          <Link href={KNOWLEDGEBASE_INFO_LINK} target="_blank">
            Read the Knowledge Base article
          </Link>
        </Stack>
      </MessageBar>
      <Text bold styles={{ root: { fontSize: FontSizes.size14 } }}>
        How {patientLabel} merge works
      </Text>
      <StackItem>
        {getText(
          "Demographic data of the master record will be retained. ",
          true
        )}
        {getText(
          "The same applies to: contact method, carer, enrolment, and consent data. Ensure the master record has all necessary data such as phone number and address"
        )}
      </StackItem>
      <StackItem>
        {getText(
          " Other data which cannot co-exist between master and duplicate: ",
          true
        )}
        {getText(
          "the merge retains the most recent populated value from master or duplicate such as occupational, social, smoking, alcohol history"
        )}
      </StackItem>
      <StackItem>
        {getText(
          `For all other administrative, clinical, and billing ${patientLabel} data: `,
          true
        )}
        {getText(
          `the merge appends the duplicate's records to the master ${patientLabel}`
        )}
      </StackItem>
    </Stack>
  );
};
