import { FunctionComponent, memo } from "react";

import { DateTime } from "@bps/utils";
import { User } from "@stores/core/models/User.ts";

interface BookedProps {
  bookedByUser: User | undefined;
  bookedDate: DateTime | undefined;
}
export const Booked: FunctionComponent<BookedProps> = memo(
  ({ bookedByUser, bookedDate }) => {
    return bookedByUser && bookedDate ? (
      <div>
        {`Booked by ${bookedByUser.name} on ${bookedDate.toDayDefaultFormat()}`}
      </div>
    ) : null;
  }
);
