import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useFormState } from "react-final-form";

import { SideRailMenuItem, Stack } from "@bps/fluent-ui";
import { SOTAPSectionText } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { AnalysisAndPlanCard } from "../AnalysisAndPlanCard.tsx";
import { ConsentAndWarningsCard } from "../ConsentAndWarningsCard.tsx";
import { useSOTAPFollowOnFormContext } from "../context/SOTAPFollowOnFormContext.tsx";
import { DiagnosisCard } from "../DiagnosisCard.tsx";
import { GoalsCard } from "../GoalsCard.tsx";
import { SotapMskId, SotapMskLabel } from "../SOTAP.constants.ts";
import { SOTAPFormWrapper } from "../SOTAPFormWrapper.tsx";
import { TreatmentAndEducationCard } from "../TreatmentAndEducationCard.tsx";
import { TreatmentPlanCard } from "../TreatmentPlanCard.tsx";

export const SOTAPFollowOnTapContent: FC = observer(() => {
  const model = useSOTAPFollowOnFormContext();
  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const [isFilledFromTreatments, setIsFilledFromTreatments] = useState<boolean>(
    !!(
      (initialValues.planTreatments &&
        initialValues.planTreatments.length > 0 &&
        initialValues.planTreatments[0].treatment !== undefined) ||
      (initialValues.planEducationOptions &&
        initialValues.planEducationOptions.length > 0) ||
      initialValues.planReview
    )
  );

  const { isViewOnlyOrDischarged: _isViewOnly, clinicalRecord } =
    usePatientRecordScreenContext();

  const dischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();

  const isViewOnly = dischargeInProgressOrCompleted || _isViewOnly;

  const objectiveMenuItems: SideRailMenuItem[] = [
    {
      text: SotapMskLabel.Diagnosis,
      id: SotapMskId.Diagnosis
    },
    {
      text: SotapMskLabel.TodaysTreatment,
      id: SotapMskId.TodaysTreatment
    },
    {
      text: SotapMskLabel.Analysis,
      id: SotapMskId.AnalysisAndPlan
    },
    {
      text: SotapMskLabel.Goals,
      id: SotapMskId.Goals
    },
    {
      text: SotapMskLabel.TreatmentPlan,
      id: SotapMskId.TreatmentPlan
    },
    {
      text: SotapMskLabel.ConsentAndWarning,
      id: SotapMskId.ConsentAndWarning
    }
  ];
  return (
    <Stack
      styles={{
        root: {
          height: "100%",
          display:
            model.currentSection !== SOTAPSectionText.TAP ? "none" : "flex"
        }
      }}
    >
      <SOTAPFormWrapper sideRailMenuItems={objectiveMenuItems}>
        <DiagnosisCard />
        <TreatmentAndEducationCard
          setIsFilledFromTreatments={setIsFilledFromTreatments}
        />
        <AnalysisAndPlanCard />
        <GoalsCard isViewOnly={isViewOnly} linkedCondition />
        <TreatmentPlanCard
          isFilledFromTreatments={isFilledFromTreatments}
          setIsFilledFromTreatments={setIsFilledFromTreatments}
          linkedCondition
        />
        <ConsentAndWarningsCard linkedCondition />
      </SOTAPFormWrapper>
    </Stack>
  );
});
