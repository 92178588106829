import React, { useContext } from "react";

import { AccBenefitCountDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  NzPracticeAccContractDto,
  OrgUnitCompanyDataDto,
  OrgUnitCompanyDataType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AccContractLink } from "./AccContractLink.tsx";
import { AccContractList } from "./AccContractList.tsx";
import { AccContractToggles } from "./AccContractToggles.tsx";

interface AccScheduleContractInfoData {
  orgUnit: PracOrgUnit | undefined;
  accBenefitCounts: AccBenefitCountDto[];
}

const AccScheduleContractInfoBase: React.FC = () => {
  const { isScheduleViewVisible } = useContext(ScheduleScreenContext);
  const { practice } = useStores();

  return (
    <DataFetcher<AccScheduleContractInfoData>
      fetch={async ({ acc, practice, core }) => {
        const orgUnit = core.location.parentOrgUnit?.id
          ? await practice.getOrgUnit(core.location.parentOrgUnit.id)
          : undefined;

        let accBenefitCounts: AccBenefitCountDto[] = [];
        const contracts = orgUnit?.nzAccContracts?.contracts;
        if (contracts) {
          accBenefitCounts = await acc.getAccBenefitCounts({
            contractClasses: contracts.map(c => c.accPracticeContractTypeCode)
          });
        }

        return { orgUnit, accBenefitCounts };
      }}
    >
      {({ orgUnit, accBenefitCounts }) => {
        if (!orgUnit) return undefined;

        const contracts = orgUnit.nzAccContracts?.contracts;
        const orgUnitCompanyData = orgUnit.orgUnitCompanyData;

        const handleToggle = async (contract: NzPracticeAccContractDto) => {
          if (contracts) {
            const indexOfContract = contracts.findIndex(
              c =>
                c.accPracticeContractTypeCode ===
                contract.accPracticeContractTypeCode
            );

            const updatedContracts = [
              ...contracts.slice(0, indexOfContract),
              { ...contract, isSubscribed: !contract.isSubscribed },
              ...contracts.slice(indexOfContract + 1)
            ];

            const updatedOrgUnitCompanyData = orgUnitCompanyData
              .filter(
                d =>
                  d.orgUnitCompanyDataTypeCode !==
                  OrgUnitCompanyDataType.NzAccContracts
              )
              .concat([
                {
                  orgUnitCompanyDataTypeCode:
                    OrgUnitCompanyDataType.NzAccContracts,
                  contracts: updatedContracts
                } as OrgUnitCompanyDataDto
              ]);

            await practice.updateOrgUnit({
              id: orgUnit.id,
              orgUnitCompanyData: updatedOrgUnitCompanyData
            });
          }
        };

        if (isScheduleViewVisible) {
          return contracts?.length ? (
            <AccContractToggles
              orgUnitCompanyData={orgUnitCompanyData}
              accBenefitCounts={accBenefitCounts}
              handleToggle={handleToggle}
            />
          ) : (
            <AccContractLink />
          );
        }

        return (
          <AccContractList
            contracts={contracts ?? []}
            accBenefitCounts={accBenefitCounts}
          />
        );
      }}
    </DataFetcher>
  );
};

export const AccScheduleContractInfo = withFetch(
  x => [x.practice.ref.accPracticeContractTypes.load()],
  AccScheduleContractInfoBase
);
