import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { IconButton, LabeledText, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BillingDetailsRow } from "@modules/billing/screens/shared-components/BIllingDetailsRow.tsx";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InvoiceDetailsRowAccountContact } from "./InvoiceDetailsRowAccountContact.tsx";
import { InvoiceDetailsRowClaim } from "./InvoiceDetailsRowClaim.tsx";
import { InvoiceDetailsRowNotes } from "./InvoiceDetailsRowNotes.tsx";
import { InvoiceDetailsRowPatient } from "./InvoiceDetailsRowPatient.tsx";
import { InvoiceDetailsRowProvider } from "./InvoiceDetailsRowProvider.tsx";
import { InvoiceDetailsRowTotals } from "./InvoiceDetailsRowTotals.tsx";

export interface InvoiceDetailsRowProps {
  invoice: Pick<
    InvoiceFormValues,
    | "reference"
    | "userId"
    | "locationId"
    | "accountContactId"
    | "patientId"
    | "accountAddress"
    | "claimId"
    | "calendarEventId"
  >;
  onEditClick?: () => void;
}

export const InvoiceDetailsRow: FunctionComponent<InvoiceDetailsRowProps> =
  observer(props => {
    const { acc, booking, core } = useStores();
    const { invoice, onEditClick } = props;

    let calendarEvent: CalendarEvent | undefined;
    let claim: Claim | undefined;

    if (core.hasPermissions(Permission.AppointmentTypeRead)) {
      calendarEvent = invoice.calendarEventId
        ? booking.calendarEventsMap.get(invoice.calendarEventId)
        : undefined;
    }

    if (core.hasPermissions(Permission.ClaimRead)) {
      claim = invoice.claimId ? acc.claimsMap.get(invoice.claimId) : undefined;
    }

    const items = [
      {
        id: "1",
        content: (
          <Stack grow tokens={{ childrenGap: 16 }}>
            <Stack grow horizontal tokens={{ childrenGap: 24 }}>
              <InvoiceDetailsRowAccountContact
                accountContactId={invoice.accountContactId}
                patientId={invoice.patientId}
              />
              <InvoiceDetailsRowPatient
                accountContactId={invoice.accountContactId}
                patientId={invoice.patientId}
              />
            </Stack>
            <LabeledText noSemicolon label="Billing address">
              {invoice.accountAddress
                ? addressText(JSON.parse(invoice.accountAddress))
                : "Address not recorded"}
            </LabeledText>
          </Stack>
        ),
        grow: true,
        basis: "100%"
      },
      {
        id: "2",
        content: (
          <InvoiceDetailsRowClaim calendarEvent={calendarEvent} claim={claim} />
        ),
        filter:
          core.tenantDetails!.country === Country.NewZealand &&
          core.hasPermissions(Permission.ClaimWrite) &&
          (!!calendarEvent || !!claim),
        grow: true,
        basis: "100%"
      },
      {
        id: "3",
        content: (
          <InvoiceDetailsRowProvider
            editMode={!!onEditClick}
            userId={invoice.userId}
            locationId={invoice.locationId}
          />
        ),
        grow: true,
        basis: "100%"
      },
      {
        id: "4",
        content: (
          <Stack tokens={{ childrenGap: 24 }}>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <InvoiceDetailsRowNotes reference={invoice.reference} />
              {onEditClick && (
                <Stack.Item>
                  <IconButton
                    iconProps={{ iconName: "Edit" }}
                    ariaLabel="Edit"
                    onClick={onEditClick}
                  />
                </Stack.Item>
              )}
            </Stack>
            <InvoiceDetailsRowTotals />
          </Stack>
        ),
        grow: true,
        basis: "100%"
      }
    ];

    const billingDetailItems = items
      .filter(x => x.filter === undefined || x.filter)
      .map(x => ({
        content: x.content,
        grow: x.grow,
        id: x.id,
        basis: x.basis
      }));

    return <BillingDetailsRow items={billingDetailItems} />;
  });
