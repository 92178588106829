import { observer } from "mobx-react-lite";

import { Spinner } from "@bps/fluent-ui";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { CommsScheduleForm } from "./components/CommsScheduleForm.tsx";
import { CommsScheduleFormValues } from "./components/CommsScheduleForm.types.ts";
import { CommsScheduleContext } from "./context/CommsScheduleContext.ts";
import { CommsScheduleHelper } from "./context/CommsScheduleHelper.ts";

const CommsScheduleScreen = observer(() => {
  const root = useStores();
  const helper = new CommsScheduleHelper(root);
  return (
    <CommsScheduleContext.Provider value={helper}>
      <DataFetcher<
        [AppointmentType[], CommsScheduleFormValues, OutboundCommTemplate[]]
      >
        fetch={async ({ booking, comms }) => {
          const commTemplates = await comms.getAllOutboundCommTemplates();
          const apptRemTemplates = commTemplates.filter(
            template =>
              template.outboundCommTypeCode === OutboundCommType.ApptReminder
          );
          return [
            ...(await Promise.all([
              booking.fetchAppointmentTypes(),
              helper.getInitialValuesCommsSchedule(apptRemTemplates)
            ])),
            apptRemTemplates
          ];
        }}
        fallback={<Spinner />}
      >
        {([appointmentTypes, initialValues, templates]) => (
          <CommsScheduleForm
            appointmentTypes={appointmentTypes}
            templates={templates}
            initialValues={initialValues}
          />
        )}
      </DataFetcher>
    </CommsScheduleContext.Provider>
  );
});

// eslint-disable-next-line import/no-default-export
export default CommsScheduleScreen;
