import { observer } from "mobx-react-lite";

import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { MedicationsTable } from "../prescribing/MedicationsTable.tsx";
import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";

export interface CurrentRxCardComponentProps {
  clinicalRecord: ClinicalRecord;
}

export const CurrentRxCardComponent: React.FunctionComponent<CurrentRxCardComponentProps> =
  observer(({ clinicalRecord }) => {
    const data = clinicalRecord?.clinicalData?.medication?.medications?.filter(
      x => x.isLongTermMedication === true
    );

    return data && data.length > 0 ? (
      <MedicationsTable medications={data} />
    ) : (
      <PatientSummaryEmptyState />
    );
  });
