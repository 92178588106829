import { PropsWithChildren } from "react";

import {
  ScrollablePane,
  SideRailMenu,
  SideRailMenuItem,
  Stack
} from "@bps/fluent-ui";

type ContactLayoutWrapperProps = PropsWithChildren<{
  menuItems: SideRailMenuItem[];
}>;

export const ContactLayoutWrapper: React.FunctionComponent<
  ContactLayoutWrapperProps
> = ({ menuItems, children }) => {
  return (
    <Stack
      horizontal
      horizontalAlign="center"
      styles={{ root: { height: "100%" } }}
    >
      <SideRailMenu
        items={menuItems}
        styles={{
          containerWrapper: { width: 230, justifySelf: "end" },
          root: {
            justifyContent: "center",
            display: "grid",
            gridTemplateColumns: "1fr 2fr"
          }
        }}
      >
        <div style={{ position: "relative" }}>
          <ScrollablePane>
            <Stack
              tokens={{ childrenGap: 16 }}
              verticalFill
              styles={{
                root: {
                  height: "100%",
                  width: 587
                }
              }}
            >
              {children}
            </Stack>
          </ScrollablePane>
        </div>
      </SideRailMenu>
    </Stack>
  );
};
