import React from "react";

import { dataAttribute, DataAttributes, Stack } from "@bps/fluent-ui";
import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  ClaimFormDataAttributes,
  ClaimFormLabels
} from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CountryPickerField } from "@ui-components/CountryPickerField.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

interface AddressFieldProps {
  fieldNames: {
    street1: string;
    street2?: string;
    addressType: string;
    suburb: string;
    city: string;
    postCode: string;
    country: string;
  };
  required?: boolean;
  validateOnInitialize?: boolean;
}

export const AddressFieldBase: React.FC<AddressFieldProps> = ({
  fieldNames,
  required,
  validateOnInitialize
}) => {
  const { practice } = useStores();
  const addressTypes = practice.ref.addressTypes.keyTextValues
    .filter(
      addressType =>
        addressType.key === AddressType.Physical ||
        addressType.key === AddressType.Postal
    )
    .map(addressType => ({
      text: addressType.text.substring(0, addressType.text.indexOf(" ")),
      key: addressType.key
    }));
  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <div
          {...dataAttribute(
            DataAttributes.Element,
            ClaimFormDataAttributes.addressType
          )}
        >
          <ButtonsGroupSingleChoiceField
            label={ClaimFormLabels.addressType}
            name={fieldNames.addressType}
            options={addressTypes}
            required={required}
            notUnselectable={required || undefined}
          />
        </div>
        <TextInputField
          {...dataAttribute(
            DataAttributes.Element,
            ClaimFormDataAttributes.patientAddressStreet1
          )}
          name={fieldNames.street1}
          label={ClaimFormLabels.patientAddressStreet1}
          required={required}
          validateOnInitialize={validateOnInitialize}
        />
      </Stack>
      {fieldNames.street2 && (
        <TextInputField
          name={fieldNames.street2}
          label={ClaimFormLabels.patientAddressStreet2}
        />
      )}
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <TextInputField
          {...dataAttribute(
            DataAttributes.Element,
            ClaimFormDataAttributes.patientAddressSuburb
          )}
          name={fieldNames.suburb}
          label={ClaimFormLabels.patientAddressSuburb}
          validateOnInitialize={validateOnInitialize}
        />
        <TextInputField
          {...dataAttribute(
            DataAttributes.Element,
            ClaimFormDataAttributes.patientAddressCity
          )}
          name={fieldNames.city}
          label={ClaimFormLabels.patientAddressCity}
          required={required}
          validateOnInitialize={validateOnInitialize}
        />
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <TextInputField
          {...dataAttribute(
            DataAttributes.Element,
            ClaimFormDataAttributes.patientAddressPostCode
          )}
          name={fieldNames.postCode}
          label={ClaimFormLabels.patientAddressPostCode}
          required={required}
          styles={{ root: { flexGrow: 1, flexBasis: 0 } }}
          validateOnInitialize={validateOnInitialize}
        />
        <CountryPickerField
          {...dataAttribute(
            DataAttributes.Element,
            ClaimFormDataAttributes.patientAddressCountry
          )}
          label={ClaimFormLabels.patientAddressCountry}
          name={fieldNames.country}
          calloutProps={{
            calloutMaxHeight: 500
          }}
          required={required}
          fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
          validateOnInitialize={validateOnInitialize}
        />
      </Stack>
    </>
  );
};

export const AddressField = withFetch(
  x => [x.practice.ref.addressTypes.load(), x.core.ref.countries.load()],
  AddressFieldBase
);
