import { FC } from "react";

import { Stack, Text } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  Address,
  addressText,
  nzAddressText
} from "@stores/core/models/Address.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { AddressTypeText } from "@ui-components/RefText.tsx";

import { sortedAddresses } from "./utils.ts";

type ContactMethodListAddressesProps = {
  patient: Contact;
};

export const ContactMethodListAddresses: FC<
  ContactMethodListAddressesProps
> = ({ patient }) => {
  return (
    <>
      {sortedAddresses(patient.addresses).map(address => {
        const showAddress = (address: Address): string | JSX.Element => {
          // street2 to use as Building/Facility name for NZ addresses;
          // nzAddress contains the rest of address fields(without street2);
          const { street2, ...nzAddress } = address;

          if (address.country === Country.NewZealand && address.street2) {
            return (
              <>
                {/* street2 to use as Building/Facility name for NZ addresses goes on the first line*/}
                <div>{street2},</div>
                {/* the rest of address fields(without street2) goes on the second ilne*/}
                <div>{nzAddressText(nzAddress)}</div>
              </>
            );
          }
          return addressText(address);
        };

        return (
          <Stack
            key={JSON.stringify(address)}
            styles={{ root: { padding: "0 10px" } }}
          >
            <Text variant="xSmall">
              {address.type === AddressType.Both ? (
                "Physical and Postal address"
              ) : (
                <AddressTypeText code={address.type} />
              )}
            </Text>
            <Text> {showAddress(address)}</Text>
          </Stack>
        );
      })}
    </>
  );
};
