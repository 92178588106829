import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

export interface UserWorkingHoursOverridesValues {
  overrides: UserWorkingHoursOverridesFormValues[];
}

export interface UserWorkingHoursOverridesScreenValues {
  before: UserWorkingHoursOverridesValues;
  after: UserWorkingHoursOverridesValues;
}

export interface UserWorkingHoursOverridesFormValues {
  id?: string;
  eTag?: string;
  atWork: string;
  reason?: string;
  startDate?: Date;
  endDate?: Date;
  startTime?: string;
  endTime?: string;
}

export interface UserWorkingHoursOverridesFormProps {
  containerId: string;
  onSubmit: (values: UserWorkingHoursOverridesValues) => void;
  initialValues: UserWorkingHoursOverridesValues;
  title: string;
  onSubmitSucceeded: SubmissionFormProps<UserWorkingHoursOverridesValues>["onSubmitSucceeded"];
  iconName: string;
  emptyStateText: string;
  emptyStateSubText: string;
  allowAdd?: boolean;
}

export interface UserWorkingHoursOverridesFieldsProps {
  initialValues: UserWorkingHoursOverridesValues;
}

export enum UserWorkingHoursTestElements {
  AddButton = "addbutton",
  AddBox = "addbox",
  FromDate = "fromdate",
  UntilDate = "untildate",
  CurrentCard = "currentcard",
  PreviousCard = "previouscard",
  FieldsWrapper = "fieldswrapper",
  FieldsRow = "fieldsrow",
  SubmitButtons = "submitbuttons",
  RemoveButton = "removebutton",
  NoPreviousOverridesBox = "nopreviousoverridesbox"
}
