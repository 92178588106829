import { useContext } from "react";

import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { CommsCard } from "../../../comms-schedules/components/CommsCard.tsx";
import { CommsScheduleCardIds } from "../../../comms-schedules/components/CommsScheduleFormEnums.ts";
import { CommsScheduleContext } from "../../../comms-schedules/context/CommsScheduleContext.ts";
import { CommsConfirmationCampaignFormValues } from "./CommsConfirmationCampaignFormValues.ts";

const nameOf = nameOfFactory<CommsConfirmationCampaignFormValues>();

export const AppointmentConfirmationCampaignSection = () => {
  const { selectedSectionId } = useContext(CommsScheduleContext);

  return (
    <CommsCard
      id={CommsScheduleCardIds.apptConfirmationReminders}
      heading="Appointment Confirmation"
      openOnRender={
        !selectedSectionId ||
        selectedSectionId === CommsScheduleCardIds.apptConfirmationReminders
      }
    >
      <TextInputField
        placeholder="Name your confirmation campaign"
        name={nameOf("name")}
        label="Name"
        required
      />
    </CommsCard>
  );
};
