import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { FontIcon, Stack, TooltipHost, useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  NzOrgUnitIdentifierDto,
  OrgUnitCompanyDataType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { Provider } from "@stores/practice/models/Provider.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { getUserStylesSet } from "../UserScreens.styles.tsx";
import { UserProviderFormValues } from "./UserProviderForm.types.ts";
import { UserProviderFormValidator } from "./UserProviderFormValidator.tsx";

const nameOf = nameOfFactory<UserProviderFormValues>();
const validator = new UserProviderFormValidator();

type UserProviderFormProps = {
  provider: Provider;
  pracOrgUnit: PracOrgUnit | undefined;
};

export const UserProviderForm: FunctionComponent<UserProviderFormProps> =
  observer(props => {
    const theme = useTheme();
    const { practice, notification, core } = useStores();
    const { formFooter, formFields } = getUserStylesSet(theme);

    const defaultCpn = props.pracOrgUnit?.nzOrgUnitIdentifier?.defaultHpiCpn;

    const initialValues: UserProviderFormValues = {
      medicareProviderNumber: props.provider.medicareProviderNumber,
      ahpraNumber: props.provider.ahpraNumber,
      discipline: props.provider.discipline,
      cpn: props.provider.cpn,
      prescriberNumber: props.provider.prescriberNumber,
      defaultCpn: !!defaultCpn && defaultCpn === props.provider.cpn
    };

    const onSubmit = async (values: UserProviderFormValues) => {
      const { defaultCpn, ...providerValues } = values;
      const {
        provider: { id, contractTypes }
      } = props;

      const promises: Promise<any>[] = [];

      promises.push(
        practice.updateProvider({
          id,
          ...providerValues,
          accProviderContractTypeCodes: contractTypes
        })
      );

      if (
        core.isNZTenant &&
        props.pracOrgUnit &&
        defaultCpn !== initialValues.defaultCpn
      ) {
        const dto: NzOrgUnitIdentifierDto = {
          ...props.pracOrgUnit.nzOrgUnitIdentifier,
          orgUnitCompanyDataTypeCode:
            OrgUnitCompanyDataType.NzOrgUnitIdentifier,
          defaultHpiCpn: defaultCpn ? providerValues.cpn : "",
          defaultHpiCpnUserId: defaultCpn ? id : ""
        };

        promises.push(
          practice.updateOrgUnit({
            id: props.pracOrgUnit.id,
            orgUnitCompanyData: [dto]
          })
        );
      }

      await Promise.all(promises);

      return false;
    };

    const resetForm = (form?: FormApi<UserProviderFormValues>) => {
      form?.reset();
    };

    return (
      <SubmissionForm<UserProviderFormValues>
        formName="user-provider"
        styles={{ main: { borderRadius: 4 } }}
        autoFocus={false}
        validate={(values: UserProviderFormValues) =>
          validator.validate(values)
        }
        readOnly={!core.hasPermissions(Permission.UserWrite)}
        initialValues={initialValues}
        onSubmitSucceeded={() => {
          resetForm();
          notification.success("Professional credentials have been updated.");
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          },
          onCancel: form => resetForm(form)
        }}
      >
        {() => (
          <Fieldset
            styles={{
              root: formFields
            }}
          >
            <WhenCountry is={Country.Australia}>
              <TextInputField
                label="Registration number"
                name={nameOf("ahpraNumber")}
                hint="APHRA number"
              />
              <TextInputField
                label="Provider number"
                name={nameOf("medicareProviderNumber")}
                hint="Medicare number"
                format={val => (val ? val.toString().toUpperCase() : "")}
              />
              <TextInputField
                label="Prescriber number"
                name={nameOf("prescriberNumber")}
                hint="Prescriber number"
                format={val => (val ? val.toString().toUpperCase() : "")}
              />
            </WhenCountry>
            <WhenCountry is={Country.NewZealand}>
              <Stack tokens={{ childrenGap: 8 }}>
                <TextInputField
                  label="HPI CPN"
                  name={nameOf("cpn")}
                  format={val => (val ? val.toString().toUpperCase() : "")}
                />
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <CheckboxField
                    label="Use as default for ACC queries"
                    name={nameOf("defaultCpn")}
                    disabled={!!defaultCpn && !initialValues.defaultCpn}
                  />
                  <TooltipHost
                    content="This CPN will be used when a status check or not verified claim is queued for checking the ACC claim status"
                    styles={{ root: { display: "flex", alignItems: "center" } }}
                  >
                    <FontIcon
                      iconName="Info"
                      styles={{ root: { fontSize: 16 } }}
                    />
                  </TooltipHost>
                </Stack>
              </Stack>
            </WhenCountry>
            <TextInputField label="Qualification" name={nameOf("discipline")} />
          </Fieldset>
        )}
      </SubmissionForm>
    );
  });
