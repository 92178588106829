import { Fragment } from "react";

import {
  dataAttribute,
  DataAttributes,
  DetailsHeader,
  DetailsListLayoutMode,
  DetailsRow,
  ICellStyleProps,
  IColumn,
  IDetailsFooterProps,
  IDetailsHeaderProps,
  mergeStyleSets,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { InvoiceItemsColumns } from "@modules/billing/screens/invoice/components/InvoiceForm.types.tsx";
import {
  InvoiceItemListOptionsDropdown,
  InvoiceItemListOptionsDropdownProps
} from "@modules/billing/screens/invoice/components/InvoiceItemListOptionsDropdown.tsx";
import {
  getIsItemASubsidy,
  getIsSubsidyInvoice
} from "@modules/billing/screens/invoice/components/utils.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import {
  getDetailsHeader,
  getItemListRowStyles,
  headerFooterRowStyles
} from "../AllocationList/AllocationListBase.styles.tsx";
import {
  FooterOptions,
  HeaderOptions,
  InvalidItemsIndexes,
  InvoiceTotals,
  TotalField
} from "../AllocationList/AllocationListBase.types.tsx";
import {
  automationElementStyles,
  getDetailsListStyles,
  getFooterRowStyles,
  totalLabelStyles,
  totalsStyles
} from "./InvoiceItemListBase.styles.ts";

export type InvoiceItemListBaseProps = {
  name: string & keyof InvoiceFormValues;
  columns: IColumn[];
  invoice?: Invoice;
  items: InvoiceItemFormValue[];
  options: {
    footer: FooterOptions;
    header: HeaderOptions;
    dropdownProps?: InvoiceItemListOptionsDropdownProps;
    warningItemsIndexes?: InvalidItemsIndexes;
    invalidItemsIndexes?: InvalidItemsIndexes;
  };
};

const InvoiceItemListBaseNoInvSettings = (props: InvoiceItemListBaseProps) => {
  const { core, billing } = useStores();
  const theme = useTheme();

  const { name, columns, items, options } = props;

  /**
   * Function returns totals calculations (value or currency string) depending on
   * an iterator
   */
  const getCalculation = (options: {
    items: InvoiceItemFormValue[];
    iterator: (item: { [key: string]: any }) => number;
  }): number => {
    let total: number = 0;
    const { items, iterator } = options;
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        total += iterator(items[i]);
      }
    }

    if (isNaN(total) || total < 0) {
      total = 0;
    }

    return total;
  };

  const totalIncGst = (item: { [key: string]: string }): number => {
    return Number(item.total || 0);
  };

  const owing = (item: InvoiceItemFormValue): number => {
    return Number(item.total) - Number(item.allocatedAmount);
  };

  const gst = (item: InvoiceItemFormValue): number => {
    return Number(item.gst || 0);
  };

  const getFieldValue = (field: TotalField): number => {
    switch (field.key) {
      case InvoiceTotals.totalIncGst:
        return getCalculation({ items, iterator: totalIncGst });
      case InvoiceTotals.owing:
        return getCalculation({ items, iterator: owing });

      default:
        return 0;
    }
  };

  const totalsColumnIndex = columns.findIndex(
    c => c.key === InvoiceItemsColumns.fee
  );

  const actionsColumnIndex = columns.findIndex(
    c => c.key === InvoiceItemsColumns.actions
  );

  const getCellsBeforeColumnWidth = (
    columns: IColumn[],
    cellStyle: ICellStyleProps,
    columnIndex: number
  ): number => {
    const cellsWidth: number = columns.reduce(
      (acc: number, item: IColumn, index: number) => {
        return index < columnIndex + 1
          ? acc + Number(item.calculatedWidth)
          : acc;
      },
      0
    );

    const calculatedPadding =
      columnIndex * (cellStyle.cellLeftPadding + cellStyle.cellRightPadding);

    return cellsWidth + calculatedPadding;
  };

  const renderTotals = () => {
    return (
      <Stack styles={totalsStyles}>
        {options.footer.fields.map((field: TotalField) => (
          <Fragment key={field.key}>
            <Text styles={totalLabelStyles}>{field.title}</Text>
            <Text
              {...dataAttribute(
                DataAttributes.Element,
                `${field.dataAttr}-footer`
              )}
              styles={(_prop, theme) => ({
                root: {
                  textAlign: "right",
                  padding: 8,
                  color:
                    field.showState && getFieldValue(field) > 0
                      ? theme!.semanticColors.errorText
                      : undefined
                }
              })}
            >
              {currencyFormat(getFieldValue(field), { currency: "" })}
            </Text>
          </Fragment>
        ))}
      </Stack>
    );
  };

  let surchargeItems: InvoiceItemFormValue[] = [];
  let subsidyItems: InvoiceItemFormValue[] = [];

  if (props.invoice?.accountId) {
    const isSubsidyInvoice = getIsSubsidyInvoice(
      props.invoice,
      billing.gstPercent
    );
    if (isSubsidyInvoice) {
      subsidyItems = items;
    } else {
      surchargeItems = items;
    }
  } else {
    surchargeItems = items.filter(item => !getIsItemASubsidy(item));
    subsidyItems = items.filter(getIsItemASubsidy);
  }

  const renderSubtotals = () => {
    return (
      <Stack styles={totalsStyles}>
        <Text
          styles={mergeStyleSets(totalLabelStyles, {
            root: { backgroundColor: theme.palette.themeLighter }
          })}
        >
          Surcharge ($)
        </Text>
        <Text
          {...dataAttribute(
            DataAttributes.Element,
            "billing-subtotal-surcharge"
          )}
          styles={{
            root: {
              textAlign: "right",
              padding: 8,
              backgroundColor: theme.palette.themeLighter
            }
          }}
        >
          {currencyFormat(
            getCalculation({ items: surchargeItems, iterator: totalIncGst }),
            { currency: "" }
          )}
        </Text>
        <Text styles={totalLabelStyles}>Subsidised ($)</Text>
        <Text
          {...dataAttribute(DataAttributes.Element, "billing-subtotal-subsidy")}
          styles={{ root: { textAlign: "right", padding: 8 } }}
        >
          {currencyFormat(
            getCalculation({ items: subsidyItems, iterator: totalIncGst }),
            { currency: "" }
          )}
        </Text>
      </Stack>
    );
  };

  const getFirstFooterRow = (
    detailsFooterProps: IDetailsFooterProps
  ): IColumn[] => {
    if (!detailsFooterProps.cellStyleProps) {
      return [];
    }

    const calculatedWidth = getCellsBeforeColumnWidth(
      detailsFooterProps.columns,
      detailsFooterProps.cellStyleProps,
      totalsColumnIndex
    );

    const noInvoiceForNZTenant = core.isNZTenant && !props.invoice;

    const returnedColumns: IColumn[] = [
      {
        key: "totals",
        name: "totals",
        calculatedWidth,
        onRender: () => (
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={{ root: { width: "100%" } }}
          >
            {props.options.footer.onRenderFooterButton?.()}
            {noInvoiceForNZTenant ? renderSubtotals() : renderTotals()}
          </Stack>
        ),
        minWidth: 250
      }
    ];

    const gstColumn = detailsFooterProps.columns.find(
      c => c.key === InvoiceItemsColumns.gst
    );

    if (gstColumn) {
      const gstItems = noInvoiceForNZTenant ? surchargeItems : items;

      returnedColumns.push({
        key: "gst",
        name: "gst",
        calculatedWidth: gstColumn.calculatedWidth,
        onRender: () => (
          <Stack
            styles={mergeStyleSets(totalsStyles, {
              root: { display: "block" }
            })}
          >
            <Text
              block
              styles={{
                root: {
                  textAlign: "center",
                  padding: 8,
                  backgroundColor: noInvoiceForNZTenant
                    ? theme.palette.themeLighter
                    : undefined
                }
              }}
            >
              {currencyFormat(
                getCalculation({ items: gstItems, iterator: gst }),
                { currency: "" }
              )}
            </Text>
          </Stack>
        ),
        minWidth: 100
      });
    }

    return returnedColumns;
  };

  const getSecondFooterRow = (
    detailsFooterProps: IDetailsFooterProps
  ): IColumn[] | undefined => {
    if (
      !core.isNZTenant ||
      props.invoice ||
      !detailsFooterProps.cellStyleProps
    ) {
      return undefined;
    }

    const calculatedWidth = getCellsBeforeColumnWidth(
      detailsFooterProps.columns,
      detailsFooterProps.cellStyleProps,
      totalsColumnIndex
    );

    return [
      {
        key: "totals",
        name: "totals",
        calculatedWidth,
        onRender: renderTotals,
        minWidth: 250
      }
    ];
  };

  const getHeaderRow = (options: {
    columns: IColumn[];
    dropdownProps?: InvoiceItemListOptionsDropdownProps;
    fields: TotalField[];
    cellStyle: ICellStyleProps;
  }): IColumn[] => {
    const { columns, dropdownProps, cellStyle } = options;

    const paddingWidth = getCellsBeforeColumnWidth(
      columns,
      cellStyle,
      actionsColumnIndex
    );

    return [
      {
        key: "padding",
        name: "padding",
        calculatedWidth: paddingWidth,
        onRender: () => (
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={{ root: { width: "100%", paddingLeft: 160 } }}
          >
            {dropdownProps && (
              <InvoiceItemListOptionsDropdown {...dropdownProps} />
            )}
          </Stack>
        ),
        minWidth: paddingWidth
      }
    ];
  };

  const getExtraHeaderRows = (
    columns: IColumn[],
    cellStyle: ICellStyleProps
  ): IColumn[] => {
    const { fields } = options.header;
    return getHeaderRow({
      columns,
      dropdownProps: options.dropdownProps,
      fields,
      cellStyle
    });
  };

  const onRenderDetailsHeader = (detailsHeaderProps: IDetailsHeaderProps) => (
    <Stack>
      <DetailsRow
        {...detailsHeaderProps}
        columns={getExtraHeaderRows(
          detailsHeaderProps.columns,
          detailsHeaderProps.cellStyleProps!
        )}
        item={{}}
        itemIndex={-1}
        styles={headerFooterRowStyles}
      />

      <DetailsHeader
        {...detailsHeaderProps}
        styles={getDetailsHeader(true, options.invalidItemsIndexes)}
      />
    </Stack>
  );

  const onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
    const footerFirstColumns = getFirstFooterRow(detailsFooterProps);
    const footerSecondColumns = getSecondFooterRow(detailsFooterProps);
    return (
      <Stack>
        <DetailsRow
          {...detailsFooterProps}
          columns={footerFirstColumns}
          item={{}}
          itemIndex={-1}
          styles={getFooterRowStyles(options.invalidItemsIndexes)}
        />
        {footerSecondColumns && (
          <DetailsRow
            {...detailsFooterProps}
            columns={footerSecondColumns}
            item={{}}
            itemIndex={-1}
            styles={mergeStyleSets(getFooterRowStyles(), {
              root: { borderBottom: "none", padding: "0px 8px" }
            })}
          />
        )}
      </Stack>
    );
  };

  return (
    <ShimmeredDetailsList
      setKey={name}
      detailsListStyles={getDetailsListStyles(options.invalidItemsIndexes)}
      items={items}
      columns={columns.map(c => ({
        ...c,
        onRender: (item, index) => (
          <div
            {...dataAttribute(DataAttributes.Element, c.key)}
            className={automationElementStyles}
          >
            {c.onRender ? c.onRender(item, index) : c.name}
          </div>
        )
      }))}
      layoutMode={DetailsListLayoutMode.justified}
      bordersVariant="bottomHeader"
      onRenderRow={(props, defaultRender) => {
        if (!props || !defaultRender) {
          return null;
        }
        return defaultRender({
          ...props,
          styles: getItemListRowStyles(props, options)
        });
      }}
      onRenderDetailsHeader={onRenderDetailsHeader}
      onRenderDetailsFooter={onRenderDetailsFooter}
    />
  );
};
export const InvoiceItemListBase = withFetch(
  x => [x.billing.getInvoiceSettings()],
  InvoiceItemListBaseNoInvSettings,
  { fallback: <Spinner /> }
);
