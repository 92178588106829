import { observer } from "mobx-react-lite";
import { FC, useRef, useState } from "react";

import {
  FontIcon,
  FontSizes,
  Link,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ConditionCallout } from "./ConditionCallout.tsx";

interface SOTAPConditionHeadingProps {}

export const SOTAPConditionHeading: FC<SOTAPConditionHeadingProps> = observer(
  () => {
    const root = useStores();
    const { clinicalRecord } = usePatientRecordScreenContext();

    const { routing } = root;

    const condition = clinicalRecord.condition;

    const diagnoses = clinicalRecord.clinicalData?.diagnoses
      ? clinicalRecord.clinicalData?.diagnoses.diagnoses
      : clinicalRecord.episodeOfCare?.diagnoses;

    const diagnosesLength = diagnoses?.length ?? 0;

    const theme = useTheme();

    const primaryDiagnosis = diagnoses
      ? diagnoses?.find(x => x.isPrimaryDiagnosis)?.diagnosisCode
          ?.originalText ?? "Undiagnosed"
      : condition?.primaryDiagnosis;

    const additionalDiagnosesLength = diagnosesLength - 1;

    const [showConditionCallout, setShowConditionCallout] = useState(false);

    const divRef = useRef<HTMLElement | null>(null);

    return (
      <>
        {clinicalRecord.isFollowOnEncounter && (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Text
              nowrap
              styles={{
                root: {
                  fontSize: 18,
                  maxWidth: 420
                }
              }}
            >
              {primaryDiagnosis}
            </Text>
            {diagnosesLength > 1 ? (
              <Link
                ref={divRef}
                styles={{
                  root: {
                    marginTop: 6,
                    fontSize: FontSizes.size14,
                    lineHeight: 14,
                    alignSelf: "baseline"
                  }
                }}
                onClick={evt => {
                  evt.stopPropagation();
                  setShowConditionCallout(state => !state);
                }}
              >
                {additionalDiagnosesLength > 1
                  ? `${additionalDiagnosesLength} more`
                  : "More"}
              </Link>
            ) : (
              <>
                {condition?.claim && (
                  <FontIcon
                    styles={{
                      root: {
                        cursor: "pointer",
                        alignSelf: "center",
                        fontSize: 18,
                        color: theme.palette.themePrimary
                      }
                    }}
                    iconName="Share"
                    onClick={() => {
                      if (condition?.claim)
                        routing.push(
                          {
                            pathname: routes.claims.management.edit.path({
                              id: condition.claim.id
                            })
                          },
                          { ...routing.getStateWithFromQuery() }
                        );
                    }}
                  />
                )}
              </>
            )}
            <div>
              {primaryDiagnosis && diagnoses && showConditionCallout && (
                <ConditionCallout
                  primaryDiagnosisText={primaryDiagnosis}
                  additionalDiagnoses={diagnoses?.filter(
                    x => !x.isPrimaryDiagnosis
                  )}
                  condition={condition}
                  target={divRef}
                  onDismiss={() => setShowConditionCallout(false)}
                />
              )}
            </div>
          </Stack>
        )}
      </>
    );
  }
);
