import { nameof } from "@libs/utils/name-of.utils.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";

import { DocumentDraftValidator } from "./DocumentDraftValidator.tsx";

export class DocumentSectionDraftValidator extends Validator<ClaimAdjustmentFormValues> {
  constructor() {
    const documentDraftValidator = new DocumentDraftValidator();

    super();
    this.forArrayField(nameof("documents"), documentDraftValidator.validate);
    this.forArrayField(
      nameof("uploadDocuments"),
      documentDraftValidator.validate
    );
  }
}
