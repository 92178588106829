import { DateTime } from "@bps/utils";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { CeaseCurrentMedicationFormFields } from "./CeaseCurrentMedicationFormFields.tsx";
import { CeaseCurrentMedicationFormValidator } from "./CeaseCurrentMedicationFormValidator.tsx";

export interface CeaseCurrentMedicationDialogProps {
  medication: MedicationClinicalDataItemDto;
  onCancel: () => void;
  onSubmit: (values: CeaseCurrentMedicationFormValues) => void;
}

export interface CeaseCurrentMedicationFormValues {
  date: Date | undefined;
  reason?: string;
  comment?: string;
  openReactions?: boolean;
}

export const CeaseCurrentMedicationDialog: React.FC<
  CeaseCurrentMedicationDialogProps
> = ({ medication, onCancel, onSubmit }) => {
  const validator = new CeaseCurrentMedicationFormValidator();
  return (
    <SubmissionFormDialog<CeaseCurrentMedicationFormValues>
      initialValues={{
        date: DateTime.jsDateNow()
      }}
      onSubmit={onSubmit}
      validate={validator.validate}
      dialogProps={{
        minWidth: 600,
        onDismiss: () => {
          onCancel();
        },
        title: `Cease ${medication.productName}?`
      }}
      dialogName={`Cease ${medication.productName}`}
      buttonsProps={{
        submitButtonProps: { text: "Cease" }
      }}
    >
      {() => {
        return <CeaseCurrentMedicationFormFields />;
      }}
    </SubmissionFormDialog>
  );
};
