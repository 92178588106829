import { useForm } from "react-final-form";

import { CollapsibleCard, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ClaimAdjustmentFormLabels } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentEnums.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { SotapMskId } from "../SOTAP.constants.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";

export const FollowUpNotesCard: React.FC = () => {
  const form = useForm<SOTAPFormValues>();
  const { clinical } = useStores();

  return (
    <div id={SotapMskId.FollowUpNotes}>
      <CollapsibleCard
        heading="Injury details"
        headingLevel="section-heading"
        iconName="FormLibraryMirrored"
        openOnRender
      >
        <Stack horizontal tokens={{ childrenGap: 8, maxWidth: 630 }}>
          <Fieldset horizontal styles={{ root: { marginBottom: "8px" } }}>
            <DatePickerField
              disabled={true}
              name={sotapNameOf("dateOfInjury")}
              maxDate={DateTime.jsDateNow()}
              label={ClaimAdjustmentFormLabels.injuryDate}
            />

            <ToggleField
              styles={{
                root: { marginBottom: 0 },
                container: { marginTop: 5 }
              }}
              onText="Yes"
              offText="None"
              name={sotapNameOf("addSurgeryDate")}
              label="Surgery"
            />
            <FieldSpy
              name={sotapNameOf("addSurgeryDate")}
              onChange={checked => {
                checked
                  ? form.change(
                      sotapNameOf("dateOfSurgery"),
                      DateTime.jsDateNow()
                    )
                  : form.change(sotapNameOf("dateOfSurgery"), undefined);
              }}
            />
            <FieldCondition when={sotapNameOf("addSurgeryDate")} is={true}>
              <Stack.Item align="end">
                <DatePickerField name={sotapNameOf("dateOfSurgery")} />
              </Stack.Item>
            </FieldCondition>
          </Fieldset>
        </Stack>
        <Stack tokens={{ childrenGap: 8, maxWidth: 630 }}>
          <ButtonsGroupSingleChoiceField
            label="Progression"
            name={sotapNameOf("progression")}
            options={clinical.ref.progressionTypes.keyTextValues}
          />
          <TextInputField
            label="Comments"
            name={sotapNameOf("followUpNotesComment")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{ fieldGroup: { minHeight: 60 } }}
          />
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
