import { FunctionComponent, ReactNode } from "react";

import {
  Tile,
  TileStyles,
  TopBarWrapper,
  TopBarWrapperProps
} from "@bps/fluent-ui";

export interface BillingLayoutProps {
  children: ReactNode;
  header: TopBarWrapperProps["header"];
  messageBar?: TopBarWrapperProps["messageBar"];
}

const tileStyles: Partial<TileStyles> = {
  root: {
    position: "relative",
    padding: "30px 24px 16px 24px",
    margin: "0 auto",
    maxWidth: 1500,
    minHeight: "100%",
    minWidth: 992, // 1024px minus 2 x 16px margin on parent
    width: "100%"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  }
};

export const BillingLayout: FunctionComponent<BillingLayoutProps> = ({
  children,
  header,
  messageBar
}) => {
  return (
    <TopBarWrapper
      styles={{ content: { padding: 16 } }}
      header={header}
      messageBar={messageBar}
    >
      <Tile styles={tileStyles}>{children}</Tile>
    </TopBarWrapper>
  );
};
