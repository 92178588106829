import {
  FontWeights,
  Stack,
  StackItem,
  Text,
  TooltipHost
} from "@bps/fluent-ui";

export const InvoiceItemDescriptionCell: React.FC<{
  invoiceItem: { code?: string; name?: string };
}> = ({ invoiceItem }) => {
  const InvoiceItemDescription = invoiceItem.name;
  return (
    <Stack
      styles={{ root: { alignSelf: "center", overflowX: "hidden" } }}
      horizontal
      tokens={{ childrenGap: 8 }}
    >
      <StackItem>
        <Text nowrap styles={{ root: { fontWeight: FontWeights.semibold } }}>
          {invoiceItem.code}:
        </Text>
      </StackItem>

      <TooltipHost
        content={InvoiceItemDescription}
        styles={{
          root: {
            overflow: "hidden"
          }
        }}
      >
        <Text nowrap>{InvoiceItemDescription}</Text>
      </TooltipHost>
    </Stack>
  );
};
