import { stringify } from "query-string";

import { appContext } from "@libs/config/app-context.utils.ts";
import { Country } from "@libs/enums/country.enum.ts";
import { PhysitrackApiKeyDto } from "@libs/gateways/custom-inegration/CustomIntegrationGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export const redirectToPhysitrack = (
  physitrackApiKeyDto: PhysitrackApiKeyDto | undefined,
  contact: Contact,
  url: string
): string => {
  return `/physitrack.html?${stringify({
    token: physitrackApiKeyDto?.apiKey,
    id: contact.id,
    firstName: contact.firstName,
    lastName: contact.lastName,
    birthYear: contact.birthDate?.year,
    url
  })}`;
};

export const fetchContactAndPhysitrackKey = async (
  root: RootStore,
  patientId: string
) => {
  const [contact, physitrackApiKeyDto] = await Promise.all([
    root.practice.getContact(patientId),
    root.customIntegrations.getPhysitrackApiKeyByProviderId(root.core.userId)
  ]);

  return { contact, physitrackApiKeyDto };
};

export const getPhysitrackUrl = (country: Country) => {
  switch (country) {
    case Country.NewZealand:
      return appContext.config.physitrackNzUrl;
    case Country.Australia:
      return appContext.config.physitrackAuUrl;
    default:
      return "";
  }
};
