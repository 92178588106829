import { observer } from "mobx-react-lite";
import React from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { usePatientMatchContext } from "../context/PatientMatchContext.ts";

export const PatientMatchActionButtons: React.FC = observer(() => {
  const patientLabel = usePatientLabel(false);
  const {
    setShowPatientMatchingDiscardDialog,
    selectedPatient,
    setShowPatientMatchingProgressDialog
  } = usePatientMatchContext();

  return (
    <Stack horizontal tokens={{ childrenGap: 4 }} horizontalAlign="end">
      <DefaultButton
        id="patientMatch-primary-button"
        onClick={() => setShowPatientMatchingProgressDialog(true)}
        text={`Match to ${patientLabel}`}
        disabled={!selectedPatient?.id}
        primary
      />
      <DefaultButton
        id="cancel-patient-match"
        onClick={() => setShowPatientMatchingDiscardDialog(true)}
        text="Cancel"
      />
    </Stack>
  );
});
