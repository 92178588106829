import { observer } from "mobx-react-lite";
import { ReactNode, useRef } from "react";

import { fullScreenForm } from "@modules/billing/screens/shared-components/billing.styles.ts";
import {
  BillingLayout,
  BillingLayoutProps
} from "@modules/billing/screens/shared-components/BillingLayout.tsx";
import { AllocationFormValidator } from "@modules/billing/screens/validators/AllocationFormValidator.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import {
  AllocationFormContext,
  useAllocationFormContext
} from "../context/AllocationFormContext.ts";
import { IAllocationFormHelper } from "../context/AllocationFormHelper.types.ts";
import { AllocationFormValues } from "./AllocationForm.types.ts";
import { AllocationFormDataFetcher } from "./AllocationFormDataFetcher.tsx";
import { AllocationFormFields } from "./AllocationFormFields.tsx";

export interface AllocationFormBaseProps {
  statusLabel?: () => ReactNode;
  header?: BillingLayoutProps["header"];
  children?: () => ReactNode;
  heading?: string;
}

export const AllocationFormBase = observer((props: AllocationFormBaseProps) => {
  const {
    initialValues,
    onSubmit,
    onSubmitSucceeded,
    onSubmitFailed,
    dateRequired,
    accountCreditPaymentOption
  } = useAllocationFormContext();

  const { statusLabel, header, children, heading } = props;

  const validator = new AllocationFormValidator(
    dateRequired,
    accountCreditPaymentOption?.max
  );

  return (
    <SubmissionForm<AllocationFormValues>
      formName="allocation-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitSucceeded={values => {
        onSubmitSucceeded(values);
      }}
      onSubmitFailed={() => onSubmitFailed && onSubmitFailed()}
      hideButtons
      styles={fullScreenForm}
      validate={validator.validate}
    >
      {() => (
        <BillingLayout header={header}>
          <AllocationFormDataFetcher>
            <>
              <AllocationFormFields
                statusLabel={statusLabel && statusLabel()}
                heading={heading}
              />
              {children && children()}
            </>
          </AllocationFormDataFetcher>
        </BillingLayout>
      )}
    </SubmissionForm>
  );
});

interface AllocationFormProps extends AllocationFormBaseProps {
  helper: IAllocationFormHelper;
}

export const AllocationForm: React.FC<AllocationFormProps> = ({
  helper,
  ...baseProps
}) => {
  const helperRef = useRef(helper);
  return (
    <AllocationFormContext.Provider value={helperRef.current}>
      <AllocationFormBase {...baseProps} />
    </AllocationFormContext.Provider>
  );
};
