import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  useTheme
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

interface AccountCardContactDetailsButtonProps {
  contactId: string;
}

export const AccountCardContactDetailsButton: FunctionComponent<
  AccountCardContactDetailsButtonProps
> = ({ contactId }) => {
  const theme = useTheme();
  const {
    practice: {
      ui: { showContactDetails }
    }
  } = useStores();

  const handleOnClick = () => {
    showContactDetails(contactId);
  };

  return (
    <IconButton
      {...dataAttribute(
        DataAttributes.Element,
        "account-card-contact-details-button"
      )}
      iconProps={{ iconName: "Share" }}
      styles={{
        root: {
          padding: 0,
          width: 24,
          height: 24,
          marginTop: 2
        },
        icon: {
          margin: 0,
          color: theme.palette.neutralPrimary,
          fontSize: theme.fonts.medium.fontSize
        }
      }}
      ariaLabel="Move to"
      onClick={handleOnClick}
    />
  );
};
