import { ValidationErrors } from "final-form";
import { FormSpy } from "react-final-form";

import { calendarStrings, MessageBar, MessageBarType } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import {
  MedicalHistoryClinicalDataItemDto,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  MedicalHistoryDateKey,
  MedicalHistoryFormValues
} from "./MedicalHistoryFormValues.ts";
import {
  getDiagnosisSideString,
  getPotentialSimilarDiagnosisDates,
  getYearFromAge
} from "./utils.ts";

export interface MedicalHistoryDateErrorMessageProps {
  birthDate?: DateTime;
  medicalHistories?: MedicalHistoryClinicalDataItemDto[];
  editingMedicalHistory?: MedicalHistoryClinicalDataItemDto;
}

export const MedicalHistoryDateErrorMessage: React.FC<
  MedicalHistoryDateErrorMessageProps
> = ({ birthDate, medicalHistories, editingMedicalHistory }) => {
  const { clinical } = useStores();
  const getMessageBarText = (params: {
    values: MedicalHistoryFormValues;
    errors?: ValidationErrors;
    terminology?: TerminologyDto;
    birthDate?: DateTime;
  }) => {
    const { values, terminology, birthDate, errors } = params;
    const { year, month, age, dateKey, date, diagnosisSideSelected } = values;

    const exactDate = date ? DateTime.fromJSDate(date).toISODate() : "";
    const yearMonth = `${year}-${month}`;
    const terminologyText = terminology?.text;
    const diagnosisSideText =
      diagnosisSideSelected && diagnosisSideSelected.length
        ? `( ${getDiagnosisSideString(diagnosisSideSelected)} )`
        : "";

    const errorMsg =
      errors?.date && exactDate && terminologyText
        ? `A duplicate of an existing record for ${terminologyText} ${diagnosisSideText} on ${DateTime.fromISO(
            exactDate
          ).toDayDefaultFormat()}`
        : undefined;

    const potentialSimilarDiagnosisDateTimes =
      terminology && medicalHistories
        ? getPotentialSimilarDiagnosisDates(terminology, medicalHistories, {
            selectedId: editingMedicalHistory?.id,
            diagnosisSideSelected
          }).map(x => {
            return DateTime.fromISO(x);
          })
        : [];

    const potentialSimilarYears = potentialSimilarDiagnosisDateTimes.map(
      x => x?.year
    );

    const potentialSimilarYearMonths = potentialSimilarDiagnosisDateTimes.map(
      x => {
        const yearMonthString = x?.toFormat(DATE_FORMATS.YEAR_MONTH_FORMAT);
        return yearMonthString;
      }
    );
    //check if it is an error message
    if (errorMsg) {
      return errorMsg;
    }
    //check duplications based on Age
    else if (
      dateKey === MedicalHistoryDateKey.Age &&
      age &&
      birthDate &&
      potentialSimilarYears.includes(getYearFromAge(birthDate, age))
    ) {
      return `Possible duplicate of an existing record for ${terminologyText} ${diagnosisSideText} at age of ${age}`;
    }
    //check duplications based on YearMonth
    else if (
      dateKey === MedicalHistoryDateKey.YearMonth &&
      year &&
      month &&
      (potentialSimilarYearMonths.includes(yearMonth) ||
        potentialSimilarYears.includes(Number(year)))
    ) {
      return `Possible duplicate of an existing record for ${terminologyText} ${diagnosisSideText} in ${
        calendarStrings.months[Number(month) - 1]
      } ${year}`;
    }
    //check duplications based on Year
    else if (
      dateKey === MedicalHistoryDateKey.Year &&
      year &&
      potentialSimilarYears.includes(Number(year))
    ) {
      return `Possible duplicate of an existing record for ${terminologyText} ${diagnosisSideText} in ${year}`;
    }
    return undefined;
  };
  return (
    <FormSpy<MedicalHistoryFormValues>
      subscription={{ errors: true, values: true }}
    >
      {({ errors, values }) => {
        const { diagnosisKey, date } = values;
        const terminology = diagnosisKey
          ? clinical.getTerminologyFromMap(diagnosisKey)
          : undefined;

        const messageBarText = getMessageBarText({
          values,
          terminology,
          birthDate,
          errors
        });

        return messageBarText ? (
          <MessageBar
            messageBarType={
              errors?.date && date
                ? MessageBarType.error
                : MessageBarType.warning
            }
          >
            {messageBarText}
          </MessageBar>
        ) : null;
      }}
    </FormSpy>
  );
};
