import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useContext, useRef } from "react";

import { dataAttribute, DataAttributes, Heading } from "@bps/fluent-ui";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { NewScheduleFields } from "./NewScheduleFields.tsx";
import { NewScheduleFormValues } from "./ScheduleForm.types.tsx";
import { getNewScheduleFormValues } from "./utils.ts";
import { NewScheduleFormValidator } from "./validators/NewScheduleFormValidator.tsx";

const validator = new NewScheduleFormValidator();

export const NewScheduleFormDialog: React.FC = observer(() => {
  const { closeNewScheduleDialog, isNewScheduleDialogVisible } = useContext(
    ScheduleScreenContext
  );

  const { submitNewScheduleValues, invoiceSettings } = useContext(
    ScheduleScreenContext
  );

  const initialValues = useRef(getNewScheduleFormValues(invoiceSettings));

  const newFeeAfterSubmit = useRef<boolean>(false);
  if (!isNewScheduleDialogVisible) return null;

  const submitValues = async (values: NewScheduleFormValues) => {
    return submitNewScheduleValues(values, newFeeAfterSubmit.current);
  };

  const handleSubmit = (form: FormApi<NewScheduleFormValues>) => {
    form.submit();
  };

  const handleSubmitAndNewFee = (form: FormApi<NewScheduleFormValues>) => {
    newFeeAfterSubmit.current = true;
    handleSubmit(form);
  };

  return (
    <SubmissionFormDialog
      initialValues={initialValues.current}
      dialogName="Combined schedule and fee dialog"
      onSubmit={submitValues}
      validate={validator.validate}
      buttonsProps={form => ({
        hideButtonsSeparator: false,
        onSubmit: handleSubmit,
        submitButtonProps: {
          id: "schedule-form-submit-btn",
          iconProps: {},
          items: [
            {
              key: "schedule-form-submit-and-new-btn",
              text: "Save & add another fee",
              onClick: () => handleSubmitAndNewFee(form)
            }
          ],
          text: "Save schedule"
        },
        cancelButtonProps: { id: "schedule-form-cancel-btn" }
      })}
      dialogProps={{
        onDismiss: closeNewScheduleDialog,
        showTitleSeparator: true,
        minWidth: 600,
        maxWidth: 600,
        dialogContentProps: {
          showCloseButton: true,
          title: (
            <Heading
              variant="modal-heading"
              {...dataAttribute(
                DataAttributes.Element,
                "combined-form-dialog-heading"
              )}
            >
              New schedule
            </Heading>
          )
        }
      }}
      component={NewScheduleFields}
    />
  );
});
