import {
  FontIcon,
  FontSizes,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { CorrespondenceDirection } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export const CorrespondenceDirectionBadge: React.FC<{
  direction: CorrespondenceDirection;
}> = ({ direction }) => {
  let iconName = "People";
  let directionText = "Prac.";
  let badgeColor: TextBadgeColor = TextBadgeColor.lightGrey;

  switch (direction) {
    case CorrespondenceDirection.Out:
      iconName = "Up";
      directionText = "Out";
      break;
    case CorrespondenceDirection.In:
      iconName = "Down";
      directionText = "In";
      badgeColor = TextBadgeColor.yellow;
      break;
  }

  return (
    <TextBadge
      badgeColor={badgeColor}
      badgeSize={TextBadgeSize.small}
      hasBorder={false}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 4 }}
        styles={{ root: { width: 38 } }}
      >
        <FontIcon
          iconName={iconName}
          styles={{
            root: {
              fontSize: FontSizes.size12
            }
          }}
        />
        <Text
          styles={{
            root: {
              fontSize: FontSizes.size12
            }
          }}
        >
          {directionText}
        </Text>
      </Stack>
    </TextBadge>
  );
};
