import { CollapsibleCard } from "@bps/fluent-ui";
import { DisplayOutcome } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DischargeFormValues } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeForm.types.ts";
import { DisplayOutcomePartiallyResolved } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DisplayOutcomePartiallyResolved.tsx";
import { DisplayOutcomeResolved } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DisplayOutcomeResolved.tsx";
import { DisplayOutcomeUnresolved } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DisplayOutcomeUnresolved.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { DischargeId, DischargeLabel } from "../SOTAP.constants.ts";

interface DisachargeOutcomeCardProps {
  isViewOnly: boolean;
}
export const DischargeOutcomeCard: React.FunctionComponent<
  DisachargeOutcomeCardProps
> = ({ isViewOnly }) => {
  const { clinical } = useStores();
  const nameOf = nameOfFactory<DischargeFormValues>();

  return (
    <div id={DischargeId.Outcome}>
      <CollapsibleCard
        heading={DischargeLabel.Outcome}
        headingLevel="section-heading"
        iconName="ComplianceAudit"
        openOnRender
      >
        <Fieldset
          tokens={{
            childrenGap: 8
          }}
        >
          <DatePickerField
            disabled={isViewOnly}
            name={nameOf("dischargeDate")}
            label="Discharge date"
            required
          />
          <ButtonsGroupSingleChoiceField
            disabled={isViewOnly}
            label="Discharger"
            name={nameOf("discharger")}
            options={clinical.ref.dischargers.keyTextValues}
            required
          />
          <ButtonsGroupSingleChoiceField
            disabled={isViewOnly}
            label="Outcome"
            name={nameOf("dischargeOutcome")}
            options={clinical.ref.dischargerOutcomes.keyTextValues}
            required
          />
          <FieldCondition
            when={nameOf("dischargeOutcome")}
            is={DisplayOutcome.Resolved}
          >
            <DisplayOutcomeResolved />
          </FieldCondition>
          <FieldCondition
            when={nameOf("dischargeOutcome")}
            is={DisplayOutcome.PartiallyResolved}
          >
            <DisplayOutcomePartiallyResolved />
          </FieldCondition>
          <FieldCondition
            when={nameOf("dischargeOutcome")}
            is={DisplayOutcome.Unresolved}
          >
            <DisplayOutcomeUnresolved />
          </FieldCondition>
        </Fieldset>
      </CollapsibleCard>
    </div>
  );
};
