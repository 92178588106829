import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { BadgeSize, Stack } from "@bps/fluent-ui";
import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { getAgeString } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { GenderText, SexText } from "@ui-components/RefText.tsx";

import { Reaction } from "../reactions/Reaction.tsx";
import { PrescribingPatientBadge } from "./PrescribingPatientBadge.tsx";

interface PrescribingPatientHeaderComponentProps {
  clinicalRecord: ClinicalRecord;
}

export const PrescribingPatientHeader: FunctionComponent<PrescribingPatientHeaderComponentProps> =
  observer(({ clinicalRecord }) => {
    return (
      <ContactFetcher contactId={clinicalRecord.id}>
        {patient => {
          const renderSecondaryText = () => {
            const patientAge = patient.birthDate
              ? getAgeString(patient.birthDate, patient.dateOfDeath)
              : "No DOB recorded";

            const patientGender =
              patient.sex &&
              patient.gender &&
              String(patient.sex) !== String(patient.gender) ? (
                <>
                  /<GenderText code={patient.gender} />
                </>
              ) : (
                ""
              );

            const patientSex = patient?.sex ? (
              <>
                , <SexText code={patient.sex} />
              </>
            ) : (
              ""
            );
            return (
              <>
                {patientAge}
                {patientSex}
                {patientGender}
              </>
            );
          };

          const isEliteAthlete =
            clinicalRecord.clinicalData?.physicalActivity?.isEliteAthlete
              ?.observed === true;

          const isPregnant =
            clinicalRecord.clinicalData?.social?.isPregnant?.observed === true;

          const isBreastfeeding =
            clinicalRecord.clinicalData?.social?.isBreastfeeding?.observed ===
            true;
          return (
            <Stack
              horizontal
              tokens={{ childrenGap: 8 }}
              styles={{ root: { maxHeight: 120 } }}
              grow
            >
              <Stack
                tokens={{ childrenGap: 16 }}
                styles={{ root: { width: "50%" } }}
              >
                <Persona
                  id={patient.id}
                  contactType={patient.type}
                  imageInitials={patient.initials}
                  imageUrl={patient.profilePictureUrl}
                  text={patient.name}
                  onRenderSecondaryText={renderSecondaryText}
                />
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <Stack styles={{ root: { width: "50%" } }}>
                    <PrescribingPatientBadge
                      size={BadgeSize.Small}
                      active={isEliteAthlete}
                    >{`${
                      isEliteAthlete ? "" : "Not"
                    } Elite Athlete`}</PrescribingPatientBadge>
                  </Stack>
                  <Stack
                    styles={{ root: { width: "50%" } }}
                    tokens={{ childrenGap: 4 }}
                  >
                    {patient?.sex === Sex.Female && (
                      <>
                        <PrescribingPatientBadge
                          size={BadgeSize.Small}
                          active={isPregnant}
                        >{`${
                          isPregnant ? "" : "Not"
                        } Pregnant`}</PrescribingPatientBadge>
                        <PrescribingPatientBadge
                          size={BadgeSize.Small}
                          active={isBreastfeeding}
                        >{`${
                          isBreastfeeding ? "" : "Not"
                        } Breastfeeding`}</PrescribingPatientBadge>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Stack styles={{ root: { width: "50%" } }}>
                <Reaction />
              </Stack>
            </Stack>
          );
        }}
      </ContactFetcher>
    );
  });
