import { Separator, Stack } from "@bps/fluent-ui";
import { ItemType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { PaymentMethodDetails } from "@modules/billing/screens/shared-components/PaymentMethodText.tsx";
import { TotalsDetails } from "@modules/billing/screens/shared-components/TotalsDetails.tsx";
import { Refund } from "@stores/billing/models/Refund.ts";

interface RefundDetailsProps {
  refund: Refund;
}

export const RefundDetails: React.FC<RefundDetailsProps> = ({ refund }) => (
  <Stack horizontal tokens={{ childrenGap: 8 }}>
    <PaymentMethodDetails heading="Refund methods" methods={refund.items} />
    <Separator vertical />
    <TotalsDetails
      heading="Refunded transactions"
      rowOptions={refund.items.map(items => {
        const { itemType, number, total, id } = items.credit;
        return {
          id: number,
          text: number,
          amount: total,
          path:
            itemType === ItemType.CreditNote
              ? routes.accounts.creditNotes.viewPath.path({
                  id
                })
              : routes.accounts.payments.viewPath.path({
                  id
                }),
          permission: Permission.CreditAllowed
        };
      })}
    />
  </Stack>
);
