import { useEffect, useState } from "react";

import {
  ChoiceGroup,
  getTheme,
  IChoiceGroupOption,
  Stack
} from "@bps/fluent-ui";

import { getDrawingThumbnailListStyles } from "./DrawingCanvas.styles.ts";
import { CanvasImage } from "./DrawingCanvas.types.ts";

export interface DrawingThumbnailProps {
  images: CanvasImage[];
  imageSelected: (imageIndex: number) => void;
  initialSelectedImage: number;
}

export const DrawingThumbnailList: React.FC<DrawingThumbnailProps> = ({
  images,
  imageSelected,
  initialSelectedImage
}) => {
  const theme = getTheme();
  const [selectedKey, setSelectedKey] = useState<string>(
    initialSelectedImage.toString()
  );

  const [thumbnails, setThumbnails] = useState<IChoiceGroupOption[]>([]);

  const styles = getDrawingThumbnailListStyles(theme);

  useEffect(() => {
    const thumbnails: IChoiceGroupOption[] = images.map((image, index) => ({
      key: index.toString(),
      selectedImageSrc: image.backgroundImageUrl,
      imageSrc: image.backgroundImageUrl,
      text: "",
      imageSize: { width: 96, height: 96 },
      styles: styles.choiceGroupOption
    }));
    setThumbnails(thumbnails);
    imageSelected(initialSelectedImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionSelected = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option) {
      setSelectedKey(option.key as string);
      imageSelected(parseInt(option.key as string));
    }
  };

  return (
    <Stack styles={styles.wrapper}>
      <ChoiceGroup
        options={thumbnails}
        onChange={optionSelected}
        selectedKey={selectedKey}
        styles={styles.choiceGroup}
      />
    </Stack>
  );
};
