import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  Accordion,
  AccordionItem,
  Heading,
  Spinner,
  Stack,
  useResizeElementObserver,
  useTheme
} from "@bps/fluent-ui";
import {
  BodyArea,
  CentralNervousTest
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DiagramEditor } from "../../full-body-clinical-data/examination/components/DiagramEditor.tsx";
import { ExaminationComments } from "../../full-body-clinical-data/examination/components/ExaminationComments.tsx";
import { ExaminationSpecialTests } from "../../full-body-clinical-data/examination/components/ExaminationSpecialTests.tsx";
import { useBodyExaminationContext } from "../../full-body-clinical-data/examination/context/BodyExaminationContext.ts";
import { DiagramWrapper } from "../../full-body-clinical-data/examination/DiagramWrapper.tsx";
import { testResponseItemsModified } from "../../full-body-clinical-data/examination/utils.ts";
import { CentralNervousSystemFormValues } from "./CentralNervousSystemForm.types.ts";

export const CentralNervousSystemFields: React.FC = observer(() => {
  const { values } = useFormState<CentralNervousSystemFormValues>();

  const { setImages, setBodyParts, currentBodyArea } =
    useBodyExaminationContext();

  const { clinical } = useStores();
  const theme = useTheme();

  const { clinicalRecord, isViewOnly } = usePatientRecordScreenContext();

  const { mutators } = useForm<CentralNervousSystemFormValues>();

  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const [compactedCanvas, setCanvasCompacted] = useState<boolean>(false);

  useEffect(() => {
    setBodyParts([BodyArea.CentralNervousSystemCranial]);
    setImages(values.imageValue);
  }, [setBodyParts, setImages, values.imageValue]);

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const accordionStyle = {
    content: {
      backgroundColor: theme.semanticColors.bodyBackground
    }
  };

  const commentsModified = useMemo(() => {
    if (currentBodyArea) {
      const comments = values.examinationComments[currentBodyArea];

      if (
        comments &&
        (comments.length > 1 || comments[0].title || comments[0].comment)
      ) {
        return true;
      }
    }

    return false;
  }, [currentBodyArea, values.examinationComments]);

  return (
    <DiagramWrapper
      compactedCanvas={compactedCanvas}
      showDiagramEditorArea={true}
      panelWidth={panelWidth}
      setElement={setElement}
      element={element}
    >
      <DiagramEditor
        onCompactButtonClicked={() => {
          setCanvasCompacted(!compactedCanvas);
        }}
        isCompacted={compactedCanvas}
        isViewOnly={
          isViewOnly || clinicalRecord.dischargeInProgressOrCompleted()
        }
        panelWidth={panelWidth}
        isCentralNervousSystem
      />

      <Stack styles={{ root: { height: "100%" } }}>
        <Heading
          styles={{
            root: {
              marginTop: 6,
              marginBottom: 8
            }
          }}
        >
          Tests & comments
        </Heading>
        <DataFetcher
          fetch={async () => {
            const result = await clinical.getSpecialTests(
              CentralNervousTest.CRANER,
              { ignoreCache: true }
            );
            return result;
          }}
          fallback={<Spinner />}
        >
          {centralNervousTest => {
            const cranialNerveTestResponseItemsModified =
              centralNervousTest.items &&
              testResponseItemsModified(
                currentBodyArea,
                values.cranialNerveTestResponseItems,
                centralNervousTest.hasSide
              );

            return (
              <Accordion multiple toggleIconType="folder">
                <AccordionItem
                  title="Cranial nerves"
                  showTitleIcon={cranialNerveTestResponseItemsModified}
                  titleIconName="PageEdit"
                  styles={{
                    content: {
                      padding: 0
                    }
                  }}
                >
                  <ExaminationSpecialTests
                    specialTest={centralNervousTest}
                    panelWidth={panelWidth}
                  />
                </AccordionItem>
              </Accordion>
            );
          }}
        </DataFetcher>

        <Accordion multiple toggleIconType="folder" disableAnimation>
          <AccordionItem
            title="Comments"
            extendedByDefault
            styles={accordionStyle}
            showTitleIcon={commentsModified}
            titleIconName="PageEdit"
          >
            <ExaminationComments mutators={mutators} isCentralNervousSystem />
          </AccordionItem>
        </Accordion>
      </Stack>
    </DiagramWrapper>
  );
});
