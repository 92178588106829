import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useField } from "react-final-form";

import { ITextFieldStyles, Separator, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ClaimDiagnosisDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ServiceItemsFormFields } from "@modules/acc/screens/claim-management/components/ServiceItemsFormFields.tsx";
import {
  ClaimManagementCardIds,
  ClaimSectionHeadings,
  ReferralInformationLabels
} from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { DiagnosisFormFields } from "@modules/acc/screens/shared-components/DiagnosisFormFields.tsx";
import { OtherDiagnosesDataFetcher } from "@modules/acc/screens/shared-components/OtherDiagnosesDataFetcher.tsx";
import { PrimaryDiagnosisFormFields } from "@modules/acc/screens/shared-components/PrimaryDiagnosisFormFields.tsx";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { claimManagementFormNameOf } from "../../claim/claim.utils.ts";
import { DiagnosisToggleHeader } from "../../claim/components/DiagnosisToggleHeader.tsx";
import { getEmptyDiagnosis } from "../../utils.ts";

export const ReferralInformationFormSection: React.FC<{
  isPrivate: boolean;
}> = observer(({ isPrivate }) => {
  const { claim } = useContext(ClaimContext);
  const { selectedMenuItemId } = useCardFormLayoutContext();

  const {
    input: { value: primaryDiagnosis }
  } = useField(claimManagementFormNameOf("primaryDiagnosis"), {
    subscription: { value: true }
  });

  const {
    input: { value: referralDiagnosis }
  } = useField(claimManagementFormNameOf("referralDiagnosis"), {
    subscription: { value: true }
  });

  return (
    <ClaimCard
      id={ClaimManagementCardIds.referralInformation}
      openOnRender={
        selectedMenuItemId === ClaimManagementCardIds.referralInformation
      }
      heading={ClaimSectionHeadings.referralInformation}
      iconName="SendMirrored"
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            label={ReferralInformationLabels.referralProvider}
            name={claimManagementFormNameOf("referralProvider")}
          />
          <DatePickerField
            styles={{ field: { minWidth: 105 } } as Partial<ITextFieldStyles>}
            label={ReferralInformationLabels.referralDate}
            name={claimManagementFormNameOf("referralDate")}
            maxDate={DateTime.jsDateNow()}
          />
          <DatePickerField
            styles={{ field: { minWidth: 105 } } as Partial<ITextFieldStyles>}
            name={claimManagementFormNameOf("accidentDate")}
            label={ReferralInformationLabels.injuryDate}
            maxDate={DateTime.jsDateNow()}
          />
        </Stack>
        <Separator />
        <OtherDiagnosesDataFetcher
          claim={claim}
          currentDiagnoses={referralDiagnosis}
          primaryDiagnosis={primaryDiagnosis}
        >
          {(filteredDiagnoses: ClaimDiagnosisDto[]) => (
            <>
              <PrimaryDiagnosisFormFields
                showStatusCol={true}
                diagnoses={[...referralDiagnosis, ...filteredDiagnoses]}
                name={claimManagementFormNameOf("primaryDiagnosis")}
              />
              <>
                <DiagnosisToggleHeader
                  fieldName={claimManagementFormNameOf("referralDiagnosis")}
                  toggleName={claimManagementFormNameOf(
                    "hasAdditionalDiagnoses"
                  )}
                  emptyDiagnosis={getEmptyDiagnosis()}
                />
                <FieldCondition
                  when={claimManagementFormNameOf("hasAdditionalDiagnoses")}
                  is={true}
                >
                  <DiagnosisFormFields
                    diagnoses={[primaryDiagnosis, ...filteredDiagnoses]}
                    maxDiagnosisCount={9}
                    showStatusCol={true}
                    arrayFieldName={claimManagementFormNameOf(
                      "referralDiagnosis"
                    )}
                    emptyDiagnosis={getEmptyDiagnosis}
                  />
                </FieldCondition>
              </>
            </>
          )}
        </OtherDiagnosesDataFetcher>
        {!isPrivate && (
          <>
            <Separator />
            <ServiceItemsFormFields />
          </>
        )}
      </Stack>
    </ClaimCard>
  );
});
