import { observer } from "mobx-react-lite";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { CenteredLargeSpinner, Overlay } from "@bps/fluent-ui";

import { useBookingCalendarScreenContext } from "../context/BookingCalendarScreenContext.tsx";

// The loading overlay is its own component so that the calendar component doesn't have to rerender when the loading boolean changes
export const CalendarLoadingOverlay = observer(() => {
  const { isLoading } = useBookingCalendarScreenContext();

  if (!isLoading) return null;

  return (
    <Overlay styles={{ root: { zIndex: 100 } }}>
      <CenteredLargeSpinner label="Loading appointments..." />
    </Overlay>
  );
});
