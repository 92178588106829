import { ITheme } from "@bps/fluent-ui";

export const patientWarningDotStyles = (theme: ITheme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    selectors: {
      "&:before": {
        content: "' '",
        marginRight: 5,
        backgroundColor: theme.semanticColors.severeWarningBackground,
        width: 10,
        height: 10,
        display: "inline-block",
        borderRadius: 100
      }
    }
  }
});
