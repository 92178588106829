import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { FontWeights, MessageBar, MessageBarType, Text } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

export const LicenceMessage: FunctionComponent = observer(() => {
  const { core } = useStores();

  const currentUser = core.user;

  // Check the current logged user
  if (!currentUser) return null;

  const getWarningMessage = () => {
    // Is the user not a provider?
    if (!currentUser.isProviderClass) return undefined;

    // get the valid licence
    const validLicence = core.licences.find(
      x => x.userId === currentUser.id && x.isValid
    );

    // Is the user unlicenced?
    if (!validLicence)
      return "please contact your administrator to allocate you a paid licence for full access";

    // Reach this far, the user has valid licence and check the licence is the grace period.
    if (validLicence.isLicenceInGracePeriod)
      return `your licence expired on ${validLicence.expiryDate.toDayDefaultFormat()}, you require a licence from ${validLicence.gracePeriodEndDate.toDayDefaultFormat()} for full access. Please contact your administrator`;

    return undefined;
  };

  const warningMessage = getWarningMessage();

  return warningMessage ? (
    <MessageBar messageBarType={MessageBarType.warning}>
      <Text styles={{ root: { fontWeight: FontWeights.bold } }}>
        Limited access:
      </Text>
      <Text>{` ${warningMessage}`}</Text>
    </MessageBar>
  ) : null;
});
