import React from "react";

import { Separator, Stack, Text } from "@bps/fluent-ui";
import {
  CodedFieldClinicalDataItemDto,
  QuestionnaireDto,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { TerminologyPickerField } from "@modules/clinical/screens/patient-record/components/clinical-form/TerminologyPickerField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { ReadOnlyTextField } from "@ui-components/form/read-only-fields/ReadOnlyTextField/ReadOnlyTextField.tsx";

import { ReadCodesHint } from "../shared-components/ReadCodesHint.tsx";
import { GRCSFormValues } from "./GRCSForm.types.ts";
import { GRCSQuestions } from "./GRCSQuestions.tsx";

interface GRCSBaselineFormProps {
  questionnaire: QuestionnaireDto;
  diagnosis?: CodedFieldClinicalDataItemDto;
  viewData?: {
    side?: string;
    answer?: string;
  };
}

const GRCSBaselineFormBase: React.FunctionComponent<GRCSBaselineFormProps> = ({
  questionnaire,
  viewData,
  diagnosis
}) => {
  const { clinical } = useStores();
  const nameOf = nameOfFactory<GRCSFormValues>();
  return (
    <Stack>
      <Text variant="large" block>
        Setup
      </Text>
      <Text block>
        The GRCS provides a means of measuring self-perceived change in health
        status with the main purpose to quantify the extent to which a patient
        has improved or deteriorated over time.
      </Text>
      <Stack.Item styles={{ root: { paddingTop: "8px" } }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <Stack.Item styles={{ root: { width: "calc(50% - 8px)" } }}>
            {!!viewData && diagnosis ? (
              <ReadOnlyTextField
                label="Diagnosis"
                value={diagnosis.originalText}
              />
            ) : (
              <TerminologyPickerField
                name={nameOf("diagnosisKey")}
                label="Diagnosis"
                take={50}
                isDiagnosis={true}
                terminology={diagnosis}
                required
                hint={
                  diagnosis?.code ? (
                    <ReadCodesHint data={diagnosis} />
                  ) : undefined
                }
              />
            )}
          </Stack.Item>
          <Stack.Item styles={{ root: { width: "calc(50% - 8px)" } }}>
            {viewData ? (
              <ReadOnlyTextField label="Side" value={viewData.side} />
            ) : (
              <DropdownField
                name={nameOf("side")}
                options={clinical.ref.sidesOfBody.keyTextValues.filter(
                  s => s.key !== SideOfBody.Both
                )}
                placeholder="Left, right or neither"
                label="Side"
                required
              />
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Separator
        styles={{ root: { paddingTop: "8px", paddingBottom: "8px" } }}
      />
      <Text variant="large" block>
        Question
      </Text>
      <GRCSQuestions questionnaire={questionnaire} answer={viewData?.answer} />
    </Stack>
  );
};

export const GRCSBaselineForm = withFetch(
  x => x.clinical.ref.sidesOfBody.load(),
  GRCSBaselineFormBase
);
