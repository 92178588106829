import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import React, { memo, useContext, useEffect } from "react";

import { Dialog, MessageBar, MessageBarType } from "@bps/fluent-ui";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { PermanentClinicalTab } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ReasonForVisitContext } from "@modules/clinical/screens/context/ReasonForVisitContext.ts";
import { ReasonForVisitHelper } from "@modules/clinical/screens/context/ReasonForVisitHelper.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { NoChargeCommentDialog } from "@ui-components/NoChargeCommentDialog.tsx";

import { ClinicalFormValuesSpy } from "../clinical-record-forms-values-spy/ClinicalRecordFormsValuesSpy.tsx";
import { EncounterAddServicesDialog } from "./EncounterAddServicesDialog.tsx";
import { submissionFromStyles } from "./EncounterForm.styles.ts";
import { EncounterFormFields } from "./EncounterFormFields.tsx";
import { EncounterFormValidator } from "./EncounterFormValidator.ts";
import { InvoicedServicesDialog } from "./InvoicedServicesDialog.tsx";
import { EncounterFormContext } from "./models/EncounterFormContext.ts";
import { EncounterFormHelper } from "./models/EncounterFormHelper.ts";
import { ProviderCommentDialog } from "./ProviderCommentDialog.tsx";
import { UnsavedFormsDialog } from "./UnsavedFormsDialog.tsx";

const validator = new EncounterFormValidator();

export const EncounterFormBase: React.FC = observer(() => {
  const helper = useContext(EncounterFormContext);

  const {
    clinicalRecord,
    encounterToInitialValues,
    onSubmit,
    hasEncounterInProgressWarning,
    dismissEncounterInProgressWarning,
    isNoChargeCommentDialogVisible,
    setNoChargeDialogVisibility,
    noChargeComment,
    setNoChargeComment,
    subscribeToCalendarEventChanges,
    unsubscribeFromCalendarEventChanges
  } = helper;

  const patientRecordScreenHelper = usePatientRecordScreenContext();

  const trackEvent = useAppTrackEvent(AppInsightsEventNames.encounterCompleted);

  useEffect(() => {
    subscribeToCalendarEventChanges();

    return () => {
      unsubscribeFromCalendarEventChanges();
      helper.dispose();
    };
  }, [
    helper,
    subscribeToCalendarEventChanges,
    unsubscribeFromCalendarEventChanges
  ]);

  return (
    <>
      <SubmissionForm<EncounterFormValues>
        formName="encounter"
        onSubmit={async (
          values: EncounterFormValues,
          form:
            | FormApi<EncounterFormValues>
            | FormApi<EncounterFormValues, Partial<EncounterFormValues>>
        ) => {
          patientRecordScreenHelper.setEncounterFormApi(form);
          await onSubmit({
            values,
            form,
            trackEvent
          });
        }}
        autoFocus={false}
        initialValues={encounterToInitialValues}
        validate={validator.validate}
        disableRoutePrompt
        styles={submissionFromStyles}
        hideButtons
        render={() => (
          <>
            <EncounterFormFields />
            <ClinicalFormValuesSpy
              clinicalRecord={clinicalRecord}
              tab={PermanentClinicalTab.TodaysNotes}
              originalInitialValues={encounterToInitialValues}
              skipTabHighlighting
            />
          </>
        )}
      />

      {isNoChargeCommentDialogVisible && (
        <NoChargeCommentDialog
          setDialogVisibility={setNoChargeDialogVisibility}
          noChargeComment={noChargeComment || ""}
          onSave={setNoChargeComment}
        />
      )}
      <ProviderCommentDialog />
      <EncounterAddServicesDialog />
      <UnsavedFormsDialog />
      <InvoicedServicesDialog />
      <Dialog
        hidden={!hasEncounterInProgressWarning}
        onDismiss={() => dismissEncounterInProgressWarning()}
        dialogContentProps={{
          title: "Encounter in progress",
          showCloseButton: true
        }}
        maxWidth={600}
      >
        <MessageBar messageBarType={MessageBarType.warning}>
          Add a reason for consult to close the record
        </MessageBar>
      </Dialog>
    </>
  );
});

export const EncounterFormContextProvider: React.FC = memo(props => {
  const root = useStores();
  const patientRecordScreenHelper = usePatientRecordScreenContext();
  const reasonForVisitHelper = new ReasonForVisitHelper(root);
  const helper = new EncounterFormHelper(root, {
    parentHelper: patientRecordScreenHelper,
    openReasonForVisitDialog: reasonForVisitHelper.openReasonForVisitDialog
  });

  return (
    <ReasonForVisitContext.Provider value={reasonForVisitHelper}>
      <EncounterFormContext.Provider value={helper}>
        <EncounterFormBase {...props} />
      </EncounterFormContext.Provider>
    </ReasonForVisitContext.Provider>
  );
});

export const EncounterForm = withFetch(
  root => [
    root.clinical.ref.encounterTypes.load(),
    root.clinical.ref.encounterLocations.load()
  ],
  EncounterFormContextProvider
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default EncounterForm;
