import { FormApi } from "final-form";
import { FunctionComponent } from "react";

import { ActionButton, Heading, MessageBar } from "@bps/fluent-ui";
import { QuickAccessSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { TemplateListItem } from "@modules/clinical/screens/correspondence/components/TemplatePivot.types.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { useQuickAccessContext } from "./context/QuickAccessContext.tsx";
import { EditQuickAccessDialogValidator } from "./EditQuickAccessDialogValidator.tsx";
import { QuickAccessDetailsList } from "./QuickAccessDetailsList.tsx";
import { OVERFLOW_LENGTH } from "./QuickAccessLinks.Types.ts";

export interface EditQuickAccessDialogValues {
  quickAccessSettings: QuickAccessSettingDto[];
}

interface EditQuickAccessDialogBaseProps {
  loading?: boolean;
  error: Error | undefined;
  data?: {
    templateItems: TemplateListItem[];
    favourites: string[];
    templateFilterTabs: string[];
  };
}

const EditQuickAccessDialogBase: FunctionComponent<
  EditQuickAccessDialogBaseProps
> = ({ loading, error, data }) => {
  const { getInitialValues, onSubmit, setShowEditQuickAccessDialog } =
    useQuickAccessContext();

  const initialValues = getInitialValues();

  const addQuickAccessSetting = (
    form: FormApi<EditQuickAccessDialogValues>
  ) => {
    form.mutators.push("quickAccessSettings", {
      code: "",
      icon: "",
      title: "",
      isSystem: false
    });
  };

  const getExtraActionsBefore = (
    form: FormApi<EditQuickAccessDialogValues>
  ) => {
    return (
      <ActionButton
        iconProps={{ iconName: "Add" }}
        title="Add another"
        onClick={() => addQuickAccessSetting(form)}
        styles={{ root: { width: 130, marginRight: "auto" } }}
      >
        Add another
      </ActionButton>
    );
  };

  return (
    <SubmissionFormDialog<EditQuickAccessDialogValues>
      loadingData={loading}
      dataLoadingError={error?.message}
      dialogName="Add / edit quick access bar shortcuts"
      onSubmit={onSubmit}
      validate={values => {
        const validator = new EditQuickAccessDialogValidator(values);
        return validator.validate(values);
      }}
      onSubmitSucceeded={() => setShowEditQuickAccessDialog(false)}
      initialValues={initialValues}
      buttonsProps={{
        disableSubmitOnFormInvalid: true,
        extraActionsBefore: getExtraActionsBefore,
        styles: {
          root: {
            width: 600
          }
        }
      }}
      dialogProps={{
        minWidth: 600,
        onDismiss: () => setShowEditQuickAccessDialog(false),
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">
              Add / edit quick access bar shortcuts
            </Heading>
          )
        }
      }}
    >
      {({ values }) => {
        const showMessage = values.quickAccessSettings.length;
        return (
          <>
            {showMessage > OVERFLOW_LENGTH && (
              <MessageBar>
                Only top 5 shortcuts will be visible in the bar, any additional
                shortcuts will be accessible from overflow menu
              </MessageBar>
            )}
            {data && (
              <QuickAccessDetailsList
                favourites={data?.favourites}
                templateFilterTabs={data.templateFilterTabs}
                templateListItems={data.templateItems}
              />
            )}
          </>
        );
      }}
    </SubmissionFormDialog>
  );
};

export const EditQuickAccessDialog: FunctionComponent = () => {
  const { getTemplatesList } = useQuickAccessContext();

  return (
    <DataFetcher<{
      templateItems: TemplateListItem[];
      favourites: string[];
      templateFilterTabs: string[];
    }>
      noExceptionsHandlers
      fetch={async ({ document }) => {
        await document.getAllTemplates();
        return await getTemplatesList();
      }}
    >
      {(data, loading, error) => (
        <EditQuickAccessDialogBase
          error={error}
          loading={loading}
          data={data}
        />
      )}
    </DataFetcher>
  );
};
