import {
  FontIcon,
  IColumn,
  RESET_CELLS_PADDING_CLASSNAME,
  Text,
  Theme,
  TooltipHost
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { AddressColumn } from "@modules/practice/screens/address-book/components/contacts-lists/AddressColumn.tsx";
import { ContactCommunicationColumn } from "@modules/practice/screens/address-book/components/contacts-lists/ContactCommunicationColumn.tsx";
import { ContactListActionMenu } from "@modules/practice/screens/address-book/components/contacts-lists/ContactListActionMenu.tsx";
import { ContactNameColumn } from "@modules/practice/screens/address-book/components/contacts-lists/ContactNameColumn.tsx";
import { EmailColumn } from "@modules/practice/screens/address-book/components/contacts-lists/EmailColumn.tsx";
import { NameHeader } from "@modules/practice/screens/address-book/components/contacts-lists/NameHeader.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  ContactCategoryText,
  ContactStatusText,
  ContactTypeText
} from "@ui-components/RefText.tsx";

export const nameColumn = (): IColumn => {
  return {
    name: "Name",
    onRenderHeader: () => (
      <NameHeader
        headerName="Name"
        toolTipText="Last name, (First name) Preferred name"
      />
    ),
    onRender: (contact: Contact) => <ContactNameColumn contact={contact} />,
    key: nameof<Contact>("name"),
    minWidth: 100,
    maxWidth: 200
  };
};

export const dobColumn = (): IColumn => {
  return {
    name: "Date of birth",
    onRender: (contact: Contact) => contact.birthDate?.toDayDefaultFormat(),
    fieldName: nameof("birthDate"),
    key: nameof<Contact>("birthDate"),
    minWidth: 80,
    maxWidth: 100
  };
};

export const prePhoneColumn = (): IColumn => {
  return {
    name: "Preferred Phone",
    onRender: (contact: Contact) => (
      <ContactCommunicationColumn contact={contact} />
    ),
    key: nameof<Contact>("communications"),
    minWidth: 80,
    maxWidth: 150
  };
};

export const emailColumn = (): IColumn => {
  return {
    name: "Email",
    onRender: (contact: Contact) => <EmailColumn contact={contact} />,

    key: nameof<Contact>("email"),
    minWidth: 200,
    maxWidth: 200
  };
};

export const phyAddressColumn = (): IColumn => {
  return {
    name: "Physical Address",
    onRender: (contact: Contact) => <AddressColumn contact={contact} />,

    key: nameof<Contact>("addresses"),
    minWidth: 400,
    maxWidth: 400
  };
};

export const orgCategoryColumn = (theme?: Theme): IColumn => {
  return {
    name: "Categories & disciplines",
    onRender: (contact: Contact) => {
      const combinedArr = [
        ...contact.organisationCategories,
        ...contact.disciplines
      ];

      const combinedArrLength = combinedArr.slice(1).length;
      const excessText =
        combinedArrLength > 0 ? ` ... (+${combinedArrLength})` : "";

      return combinedArr.length ? (
        <>
          {contact.isHealthProvider ? (
            <FontIcon
              iconName="Hospital"
              styles={{
                root: {
                  marginRight: 8,
                  color: theme?.palette.neutralTertiary
                }
              }}
            />
          ) : null}
          <TooltipHost content={<ContactCategoryText code={combinedArr} />}>
            <Text>
              <ContactCategoryText code={combinedArr[0]} />
              {excessText}
            </Text>
          </TooltipHost>
        </>
      ) : null;
    },
    key: nameof<Contact>("categories"),
    minWidth: 100,
    maxWidth: 300
  };
};

export const categoryColumn = (): IColumn => {
  return {
    name: "Category",
    onRender: (contact: Contact) => (
      <ContactCategoryText code={contact.categories} />
    ),
    key: nameof<Contact>("categories"),
    minWidth: 50,
    maxWidth: 50
  };
};

export const typeColumn = (): IColumn => {
  return {
    name: "Type",
    onRender: (contact: Contact) => <ContactTypeText code={contact.type} />,
    fieldName: nameof("type"),
    key: nameof<Contact>("type"),
    minWidth: 80,
    maxWidth: 100
  };
};

export const statusColumn = (): IColumn => {
  return {
    name: "Status",
    fieldName: nameof("status"),
    onRender: (contact: Contact) => <ContactStatusText code={contact.status} />,
    key: nameof<Contact>("status"),
    minWidth: 80,
    maxWidth: 100
  };
};

export const useActionColumn = (): IColumn | null => {
  const { core, practice } = useStores();

  const {
    ui: { showEditContact }
  } = practice;

  const onShowEdit = (cardId: string, id: string) => {
    showEditContact(cardId, id);
  };

  const canOpenClinical = core.hasPermissions([
    Permission.EncounterRead,
    Permission.ClinicalRead
  ]);

  const canOpenPatient = core.hasPermissions([
    Permission.PatientRead,
    Permission.PatientWrite,
    Permission.PatientRead,
    Permission.PatientWrite
  ]);

  const canOpenIndividualOrg = core.hasPermissions([
    Permission.ContactRead,
    Permission.ContactWrite,
    Permission.PatientRead,
    Permission.PatientWrite
  ]);

  const displayActionsColumn =
    canOpenClinical || canOpenPatient || canOpenIndividualOrg;

  if (!displayActionsColumn) {
    return null;
  }

  return {
    name: "Actions",
    onRender: (contact: Contact) => (
      <ContactListActionMenu
        contact={contact}
        onShowEdit={onShowEdit}
        canOpenClinical={canOpenClinical}
      />
    ),
    className: RESET_CELLS_PADDING_CLASSNAME,
    key: "actions",
    minWidth: 50,
    maxWidth: 50
  };
};
