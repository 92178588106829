import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  LabeledText,
  PersonaSize,
  Pivot,
  PivotItem,
  Stack,
  StackItem,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { groupBy } from "@bps/utils";
import {
  EmployerGroup,
  EmployerType,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { OccupationText } from "@ui-components/RefText.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { Relationship } from "../../constants/relationship.constant.ts";
import { CompoundButtonWithHoveredIcon } from "../../shared-components/view/CompoundButtonWithHoveredIcon.tsx";
import { NoDetailsButton } from "../../shared-components/view/NoDetailsButton.tsx";

interface EmployersCardProps {
  showEditIcon: boolean;
  patient: Contact;
  onHandleEditModal: (id?: string) => void;
}

export const EmployersCard: FC<EmployersCardProps> = observer(
  ({ showEditIcon, onHandleEditModal, patient }) => {
    const { practice } = useStores();
    const theme = useTheme();
    const { noRelationships, labelSmall, personaText } =
      getPeopleScreenStylesSet(theme);

    const {
      ui: { showContactDetails }
    } = practice;

    const renderEmployers = (relationships: Relationship[]): JSX.Element => {
      return (
        <Stack
          styles={{ root: { padding: "0 10px" } }}
          tokens={{ childrenGap: 8 }}
        >
          {relationships.map((re, idx) => (
            <CompoundButtonWithHoveredIcon
              key={`${re.contact.id}-${idx.toString()}`}
              onClick={() => showContactDetails(re.contact.id)}
            >
              <Stack horizontal>
                <Persona
                  styles={personaText}
                  id={re.contact.id}
                  contactType={re.contact.type}
                  size={PersonaSize.size48}
                  imageInitials={re.contact.initials}
                  imageUrl={re.contact.profilePictureUrl}
                  text={re.contact.name}
                  status={re.contact.status}
                  onRenderSecondaryText={
                    re ? renderSecondaryText(re) : undefined
                  }
                />

                {patient.relationships.some(
                  x =>
                    x.relationship === RelationshipType.Employer &&
                    re.contact.id === x.relatedContactId &&
                    x.metadata?.primaryJob
                ) && (
                  <TextBadge
                    badgeSize={TextBadgeSize.small}
                    badgeColor={TextBadgeColor.blue}
                  >
                    Primary job
                  </TextBadge>
                )}
              </Stack>
            </CompoundButtonWithHoveredIcon>
          ))}
        </Stack>
      );
    };

    const renderSecondaryText =
      (relationship: Relationship) => (): JSX.Element => {
        return (
          <Stack
            verticalAlign="center"
            verticalFill
            styles={{
              root: {
                whiteSpace: "normal",
                wordBreak: "break-word"
              }
            }}
          >
            <StackItem styles={labelSmall}>
              {relationship.contact.workPhone}
            </StackItem>
            <StackItem styles={labelSmall}>
              {relationship.contact.email}
            </StackItem>
          </Stack>
        );
      };

    const filterEmployerBy = (
      type: EmployerType,
      sortByPrimaryJob: boolean = false
    ) => {
      const isCurrent = type === EmployerType.Current;
      const filteredRelationships = patient.relationships.filter(
        rel =>
          rel.relationship === RelationshipType.Employer &&
          rel.metadata?.currentJob === isCurrent
      );

      const result = groupBy(
        filteredRelationships || [],
        x => x.relatedContactId
      )
        .filter(([contactId]) => practice.contactsMap.has(contactId!))
        .map(([contactId, relationships]) => ({
          contact: practice.contactsMap.get(contactId!)!,
          relationshipTypes: relationships.map(x => x.relationship)
        }))
        .sort((a, b) => {
          if (a.contact.name! > b.contact.name!) return 1;
          if (b.contact.name! > a.contact.name!) return -1;
          return 0;
        });
      if (sortByPrimaryJob) {
        const primaryJobIndex = result.findIndex(re =>
          patient.relationships.some(
            x =>
              x.relationship === RelationshipType.Employer &&
              re.contact.id === x.relatedContactId &&
              x.metadata?.primaryJob
          )
        );
        if (primaryJobIndex > 0) {
          const primaryJobContact = result[primaryJobIndex];
          result.splice(primaryJobIndex, 1);
          result.splice(0, 0, primaryJobContact);
        }
      }
      return result;
    };

    const pivotItemArray = [
      {
        itemKey: EmployerGroup.Current,
        headerText: "Current",
        employers: filterEmployerBy(EmployerType.Current, true),
        text: EmployerGroup.Current
      },
      {
        itemKey: EmployerGroup.Previous,
        headerText: "Previous",
        employers: filterEmployerBy(EmployerType.Previous),
        text: EmployerGroup.Previous
      }
    ];

    return (
      <>
        {patient.isPatient && (
          <Stack styles={{ root: { paddingLeft: 10, paddingBottom: 8 } }}>
            <LabeledText noSemicolon label="Occupation">
              {patient.occupation === "UNK" ||
              patient.occupation === undefined ? (
                "None recorded"
              ) : (
                <OccupationText code={patient.occupation} />
              )}
            </LabeledText>
          </Stack>
        )}
        <Stack tokens={{ childrenGap: 8 }}>
          {showEditIcon ? (
            <Pivot
              styles={{ linkIsSelected: { paddingLeft: 10 } }}
              defaultSelectedKey={
                filterEmployerBy(EmployerType.Current).length
                  ? EmployerGroup.Current
                  : EmployerGroup.Previous
              }
            >
              {pivotItemArray.map(pivotItem => (
                <PivotItem
                  key={pivotItem.itemKey + pivotItem.text}
                  headerText={`${pivotItem.headerText} (${pivotItem.employers.length})`}
                  itemKey={pivotItem.itemKey}
                  style={
                    !pivotItem.employers.length
                      ? {
                          ...noRelationships
                        }
                      : undefined
                  }
                >
                  <div style={{ marginTop: 11 }}>
                    {pivotItem.employers.length > 0 &&
                      renderEmployers(pivotItem.employers)}
                    {!pivotItem.employers.length &&
                      `No ${pivotItem.text} employers`}
                  </div>
                </PivotItem>
              ))}
            </Pivot>
          ) : (
            <NoDetailsButton
              sectionId={PatientCardIds.employers}
              actionTitle="Add an employer"
              noPermissionText="No employers"
              secondaryText="Organisation"
              onHandleEditModal={onHandleEditModal}
            />
          )}
        </Stack>
      </>
    );
  }
);
