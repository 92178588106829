import { DateTime, newGuid } from "@bps/utils";
import {
  AddDocumentDto,
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  CorrespondenceVisibility,
  DocumentContentType,
  DocumentCreateOptions,
  DocumentDto,
  DocumentEnum,
  DocumentExtensionType,
  DocumentMetadataItem,
  DocumentSource,
  DocumentStagingInfo,
  EncounterClinicalDataDto,
  ImagingReportsDataItemDto,
  ImagingReportViewParameter,
  StoreType,
  XrayParameters
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  TemplateArgs,
  TemplateNameDescription,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import {
  AddUserAction,
  InboxStoreInDestinationType,
  Instructions,
  MoveToClinicalRecordArgsDto,
  NoActionUserActionContext,
  UserActionType,
  UserTaskDto,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { TemplateOptionKeys } from "../../types/template-option-keys.interface.ts";
import { InvestigationFormValues } from "./AddInvestigationDialog.types.ts";
import {
  AddXrayParametersFormValues,
  ImagingReportViewParameterCode
} from "./AddXrayParameterDialog.types.ts";

export class AddEditInvestigationDialogHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root: RootStore,
    private options: {
      editInvestigation?: ClinicalDocument;
      userTask?: UserTask;
    }
  ) {}

  get editInvestigation(): ClinicalDocument | undefined {
    return this.options.editInvestigation;
  }

  set editInvestigation(newInvestigation: ClinicalDocument | undefined) {
    this.options.editInvestigation = newInvestigation;
  }

  private get correspondence() {
    return this.root.correspondence;
  }

  private get inbox() {
    return this.root.inbox;
  }

  private converter = (values: AddXrayParametersFormValues) => {
    if (!values.views) return;

    const xRayParametersValue: XrayParameters = {
      region: values?.region,
      side: values?.side?.toString(),
      views: []
    };

    values.views.forEach(item => {
      const parameters: ImagingReportViewParameter[] = [
        {
          code: ImagingReportViewParameterCode.AreaSize,
          value: item["areaSize"]
        },
        {
          code: ImagingReportViewParameterCode.Voltage,
          value: item["voltage"]
        },
        {
          code: ImagingReportViewParameterCode.Current,
          value: item["current"]
        },
        {
          code: ImagingReportViewParameterCode.Duration,
          value: item["duration"]
        },
        {
          code: ImagingReportViewParameterCode.SID,
          value: item["sid"]
        }
      ];

      xRayParametersValue.views.push({
        view: item.view ? item.view.toString() : "",
        parameters
      });
    });
    return xRayParametersValue;
  };

  public reverter = (values: XrayParameters) => {
    if (!values.views) return;

    const addXrayParametersValue: AddXrayParametersFormValues = {
      region: values?.region,
      side: values?.side,
      views: []
    };

    addXrayParametersValue.views = values.views.map(item => {
      const findParameterValue = (
        arr: ImagingReportViewParameter[],
        key: string
      ) => {
        return arr.find(x => x.code === key)?.value;
      };

      const parameters = item.parameters;
      const view = {
        view: item.view ? item.view.toString() : "",
        areaSize: findParameterValue(
          parameters,
          ImagingReportViewParameterCode.AreaSize
        ),
        voltage: findParameterValue(
          parameters,
          ImagingReportViewParameterCode.Voltage
        ),
        current: findParameterValue(
          parameters,
          ImagingReportViewParameterCode.Current
        ),
        duration: findParameterValue(
          parameters,
          ImagingReportViewParameterCode.Duration
        ),
        sid: findParameterValue(parameters, ImagingReportViewParameterCode.SID)
      };
      return view;
    });

    return addXrayParametersValue;
  };

  private updateReportClinicalDataWithFormValues = async (
    documentId: string,
    values: InvestigationFormValues
  ) => {
    const addXrayParametersFormValues = values.xrayParameters;
    const editInvestigation = this.options.editInvestigation;

    const reportText = values.reportText;
    const newImagingReport: Omit<ImagingReportsDataItemDto, "id"> = {
      documentId,
      reportText
    };

    if (addXrayParametersFormValues && addXrayParametersFormValues.region) {
      const xrayParametersValue = this.converter(addXrayParametersFormValues);
      newImagingReport.xrayParameters = xrayParametersValue;
    }

    const updatedImagingReports = [...this.clinicalRecord.imagingReports];
    const updateIndex = updatedImagingReports.findIndex(
      x => x.documentId === documentId
    );
    updatedImagingReports[updateIndex] = newImagingReport;

    const encounterClinicalData: EncounterClinicalDataDto = {
      imagingReports: {
        eTag: this.clinicalRecord.clinicalData?.imagingReports?.eTag,
        imagingReports: editInvestigation
          ? updatedImagingReports
          : [...this.clinicalRecord.imagingReports, newImagingReport]
      }
    };
    if (reportText || addXrayParametersFormValues) {
      await this.clinicalRecord.saveClinicalData(encounterClinicalData);
    }
  };

  submitCorrespondence = async (
    values: InvestigationFormValues,
    stagingPath?: DocumentStagingInfo
  ) => {
    const editInvestigation = this.options.editInvestigation;

    const doucmentId = editInvestigation ? editInvestigation.id : newGuid();
    if (values.reportText) {
      await this.updateReportClinicalDataWithFormValues(doucmentId, values);
    }

    if (editInvestigation) {
      await this.updateInvestigation(values);
    } else if (values.inboxDocument) {
      await this.addInvestigationFromInbox(values);
    } else {
      await this.addInvestigation(values, doucmentId, stagingPath);
    }
  };

  private addInvestigationFromInbox = async (
    values: InvestigationFormValues
  ) => {
    if (!values.inboxDocument) return;

    // Move to investigations
    const doc = values.inboxDocument;

    const showOnTimeline =
      values.visibility === CorrespondenceVisibility.DisplayOnTimeline;

    const storeIn: MoveToClinicalRecordArgsDto = {
      inboxDocument: {
        assignedToUserId: doc.assignedToUserId,
        documentDetailId: doc.documentDetailId,
        ...(doc.documentDate && {
          documentDate: DateTime.jsDateToISODate(doc.documentDate)
        }),
        correspondenceType: doc.correspondenceType,
        extension: doc.docExtension ?? "",
        patientId: doc.patientId,
        name: doc.name,
        patientFirstName: doc.patientFirstName,
        patientLastName: doc.patientLastName,
        eTag: doc.eTag,
        changeLog: doc.changeLog,
        fromContactId: doc.fromContactId,
        id: doc.id,
        checkedBy: this.root.core.userId,
        receivedDate: DateTime.fromJSDate(doc.receivedDate)?.toISODate(),
        checkedDateTime: DateTime.now().toISO(),
        showOnTimeline
      },
      storeInDestination: InboxStoreInDestinationType.Investigations
    };

    const context: NoActionUserActionContext = {
      storeIn,
      ...((values.outcome || values.extraInfo) && {
        userTask: {
          instructionCode: Instructions.NoAction,
          status: UserTaskStatus.Completed,
          outcome: values.outcome
        } as UserTaskDto
      })
    };

    const userAction: AddUserAction = {
      userActionType: UserActionType.NoAction,
      context
    };

    await this.inbox.addUserAction(userAction);

    const investigation =
      await this.correspondence.getInvestigationByDocumentId(
        this.clinicalRecord.id,
        doc.documentDetailId
      );

    if (!investigation) throw new Error("Error updating document");

    investigation.dto.metadata.push(
      this.createMeta(DocumentEnum.CheckedBy, this.root.core.userId)
    );

    investigation.dto.metadata.push(
      this.createMeta(DocumentEnum.DateChecked, DateTime.now().toISO())
    );

    investigation.dto.metadata.push(
      this.createMeta(
        DocumentEnum.EncounterId,
        this.clinicalRecord.openEncounter!.id
      )
    );

    this.options.editInvestigation = investigation;

    await this.updateInvestigation(values);
  };

  public updateInvestigation = async (values: InvestigationFormValues) => {
    if (!this.options.editInvestigation) return;

    const encounterId = this.clinicalRecord.openEncounter!.id;
    const documentDto = this.options.editInvestigation.dto;
    const dateAsString = DateTime.jsDateToISODate(values.reportDate);
    const userSecGroupId = this.clinicalRecord.core.user?.privateSecGroupId;

    // Prevents error for docs created without ReceivedDate see #26483
    documentDto.metadata = documentDto.metadata.filter(
      metadata => !!metadata.value
    );
    documentDto.store = StoreType.Investigations;

    const confidential =
      values.visibility === CorrespondenceVisibility.Confidential;

    this.updateMetadata(DocumentEnum.ExternalLink, documentDto, values.link);
    this.updateMetadata(DocumentEnum.ExtraInfo, documentDto, values.extraInfo);
    this.updateMetadata(DocumentEnum.Date, documentDto, dateAsString);
    this.updateMetadata(DocumentEnum.Name, documentDto, values.subject);
    this.updateMetadata(DocumentEnum.Outcome, documentDto, values.outcome);

    this.updateMetadata(
      DocumentEnum.SecGroupId,
      documentDto,
      confidential ? userSecGroupId : undefined
    );

    if (values.reportType) {
      this.updateMetadata(
        DocumentEnum.ReportType,
        documentDto,
        values.reportType
      );

      this.updateMetadata(
        DocumentEnum.CheckedBy,
        documentDto,
        this.clinicalRecord.openEncounter?.userId
      );
      this.updateMetadata(
        DocumentEnum.DateChecked,
        documentDto,
        DateTime.now().toISO()
      );
    }

    if (values.isReportTextDocument) {
      const investigationReportTemplate = await this.getDefaultReportTemplate();
      if (investigationReportTemplate) {
        const content = await this.getReportDocumentContent(
          values,
          documentDto.id,
          investigationReportTemplate
        );
        documentDto.content = content ?? documentDto.content;
      }
    }

    documentDto.from = values.from;
    //update document dto on confidential.
    documentDto.secGroupId = confidential ? userSecGroupId : undefined;

    await this.correspondence.updateCorrespondence(
      encounterId,
      documentDto.id,
      documentDto
    );

    // Avoid creating duplicate task in addInvestigationFromInbox
    if ((values.outcome || values.extraInfo) && !values.inboxDocument) {
      if (this.options.userTask) {
        await this.inbox.updateUserTask({
          ...this.options.userTask.dto,
          outcome: values.outcome
        });
      } else {
        const context: NoActionUserActionContext = {
          userTask: {
            instructionCode: Instructions.NoAction,
            status: UserTaskStatus.Completed,
            outcome: values.outcome
          } as UserTaskDto,
          patientKey: {
            patientId: this.clinicalRecord.id,
            documentId: this.options.editInvestigation.id
          }
        };

        const userAction: AddUserAction = {
          userActionType: UserActionType.NoAction,
          context
        };

        await this.inbox.addUserAction(userAction);
      }
    }
  };

  public addInvestigation = async (
    values: InvestigationFormValues,
    documentId: string,
    stagingPath?: DocumentStagingInfo
  ) => {
    if (!stagingPath) throw new Error("stagingPath not set");
    if (!this.clinicalRecord.openEncounter)
      throw new Error("An open encounter is required.");

    const encounterId = this.clinicalRecord.openEncounter.id;
    const metadata: DocumentMetadataItem[] = [];
    const patientId = this.clinicalRecord.id;
    const userSecGroupId = this.clinicalRecord.core.user?.privateSecGroupId;
    const createdDate = DateTime.jsDateToISODate(values.reportDate);
    const reportText = values.reportText;
    const subject = values.subject;
    const fromId = values.from;

    subject && metadata.push(this.createMeta(DocumentEnum.Name, subject));

    createdDate &&
      metadata.push(this.createMeta(DocumentEnum.Date, createdDate));

    encounterId &&
      metadata.push(this.createMeta(DocumentEnum.EncounterId, encounterId));

    fromId && metadata.push(this.createMeta(DocumentEnum.From, fromId));

    values.link &&
      metadata.push(this.createMeta(DocumentEnum.ExternalLink, values.link));

    // The "Comment" metadata holds "extraInfo" which on the backend is retrieved and stored as Notes when task is created.
    values.extraInfo &&
      metadata.push(this.createMeta(DocumentEnum.Comment, values.extraInfo));

    values.reportType &&
      metadata.push(
        this.createMeta(DocumentEnum.ReportType, values.reportType)
      );

    // Creates a UserAction/Task via event
    values.outcome &&
      metadata.push(this.createMeta(DocumentEnum.Outcome, values.outcome));

    const confidential =
      values.visibility === CorrespondenceVisibility.Confidential;

    //add confidential
    confidential &&
      userSecGroupId &&
      metadata.push(this.createMeta(DocumentEnum.SecGroupId, userSecGroupId));

    metadata.push(
      this.createMeta(
        DocumentEnum.CheckedBy,
        this.clinicalRecord.openEncounter.userId
      )
    );
    metadata.push(
      this.createMeta(DocumentEnum.DateChecked, DateTime.now().toISO())
    );

    const showOnTimeline =
      values.visibility === CorrespondenceVisibility.DisplayOnTimeline;

    if (showOnTimeline) {
      metadata.push(
        this.createMeta(DocumentEnum.ShowOnTimeline, true.toString())
      );
    }

    const document: AddDocumentDto = {
      patientId,
      type: CorrespondenceType.Report,
      direction: CorrespondenceDirection.In,
      from: fromId,
      status: CorrespondenceStatus.Uploaded,
      store: StoreType.Investigations,
      metadata,
      showOnTimeline,
      secGroupId: confidential ? userSecGroupId : undefined
    };

    if (reportText) {
      document.id = documentId;

      const investigationReportTemplate = await this.getDefaultReportTemplate();

      if (investigationReportTemplate) {
        const content = await this.getReportDocumentContent(
          values,
          documentId,
          investigationReportTemplate
        );
        document.content = content ?? "";

        metadata.push(
          this.createMeta(DocumentEnum.Extension, DocumentExtensionType.Docx)
        );
        metadata.push(
          this.createMeta(DocumentEnum.ContentType, DocumentContentType.Sfdt)
        );

        metadata.push(
          this.createMeta(
            DocumentEnum.TemplateId,
            investigationReportTemplate.id
          )
        );

        const request: DocumentCreateOptions = {
          source: DocumentSource.Content,
          documents: [document]
        };

        await this.correspondence.addDocuments(encounterId, request);
      }
    } else if (values.files?.length > 0) {
      const file = values.files[0];

      file.extension &&
        metadata.push(this.createMeta(DocumentEnum.Extension, file.extension));

      document.id = file.blobName;

      const request: DocumentCreateOptions = {
        stagingId: stagingPath.stagingId,
        source: DocumentSource.Staging,
        documents: [document]
      };

      await this.correspondence.addDocuments(encounterId, request);
    }
  };

  private getDefaultReportTemplate = async () => {
    const templateArgs: TemplateArgs = {
      name: TemplateNameDescription.investigationReport
    };

    const templates = await this.root.document.getTemplates(templateArgs);

    const investigationReportTemplate = templates.find(
      x => x.name === TemplateNameDescription.investigationReport
    );

    return investigationReportTemplate;
  };

  private getReportDocumentContent = async (
    values: InvestigationFormValues,
    documentId: string,
    defaultTemplate: Template
  ) => {
    const nameOf = nameOfFactory<TemplateOptionKeys>();

    const core = this.clinicalRecord.core;
    const subject = values.subject;
    const userId = this.clinicalRecord.core.userId;
    const encounterId = this.clinicalRecord.openEncounter?.id;
    const patientId = this.clinicalRecord.id;
    const createdDate = DateTime.jsDateToISODate(values.reportDate);

    if (defaultTemplate) {
      //generate render options
      const context: { [id: string]: string } = {
        //contexts for letterhead
        UserId: core?.userId ?? "",
        PracticeOrgUnitId: core.location.parentOrgUnit?.id ?? ""
      };

      if (encounterId) {
        context[nameOf("EncounterId")] = encounterId;
      }
      context[nameOf("PatientId")] = patientId;
      if (userId) {
        context["UserId"] = userId;
      }
      context[nameOf("InvestigationReportDocumentId")] = documentId;
      const parameters: { [id: string]: string } = {};

      if (subject) {
        parameters[nameOf("Subject")] = subject;
      }
      if (createdDate) {
        parameters[nameOf("CreatedDate")] =
          DateTime.fromISO(createdDate).toDayDefaultFormat();
      }

      const renderOptions: TemplateRenderOptions = {
        context,
        contentType: DocumentContentType.Sfdt,
        parameters
      };

      const practiceLetterheadTemplate =
        await this.correspondence.root.document.getDefaultPracticeLetterhead();
      if (practiceLetterheadTemplate) {
        renderOptions.letterheadId = practiceLetterheadTemplate.id;
      }

      const renderedDocument = await this.root.document.renderTemplate(
        defaultTemplate.id,
        renderOptions
      );

      return renderedDocument.content ?? "";
    }
    return;
  };

  private updateMetadata = (
    metadataName: DocumentEnum,
    documentDto: DocumentDto,
    formValue?: string
  ) => {
    const metadata = documentDto?.metadata.find(m => m.key === metadataName);

    if (!formValue) {
      //remove metadata if no formvalue
      documentDto.metadata = documentDto.metadata.filter(
        m => m.key !== metadataName
      );
    } else {
      if (metadata) {
        if (!formValue) {
        }
        //update existing metadata
        metadata.value = formValue;
      } else {
        if (formValue) {
          //add new metadata
          documentDto.metadata.push(this.createMeta(metadataName, formValue));
        }
      }
    }
  };

  private createMeta = (
    key: DocumentEnum,
    value: string
  ): DocumentMetadataItem => {
    return {
      key,
      value
    };
  };

  public getInitialValues = () => {
    let initialValues: InvestigationFormValues;
    const editInvestigation = this.options.editInvestigation;
    const initialXrayParameter = {
      region: undefined,
      side: undefined,
      views: [{}]
    };
    if (editInvestigation) {
      const link = this.options.editInvestigation?.metadata.find(
        m => m.key === DocumentEnum.ExternalLink
      );

      const extraInfo = this.options.editInvestigation?.metadata.find(
        m => m.key === DocumentEnum.ExtraInfo
      );

      const reports =
        this.clinicalRecord.clinicalData?.imagingReports?.imagingReports?.map(
          a => a
        );

      const reportDto =
        reports && editInvestigation.id
          ? reports.find(x => x.documentId === editInvestigation.id)
          : undefined;

      const xrayParameters = reportDto ? reportDto.xrayParameters : undefined;

      initialValues = {
        subject: editInvestigation.name,
        reportDate: DateTime.jsDateFromISO(editInvestigation.dateString),
        files: [],
        link: link?.value,
        from: editInvestigation.from,
        reportType: editInvestigation.reportType,
        outcome: this.options.userTask?.outcome,
        extraInfo: extraInfo?.value,
        visibility: !!editInvestigation.secGroupId
          ? CorrespondenceVisibility.Confidential
          : undefined,
        xrayParameters: xrayParameters
          ? this.reverter(xrayParameters)
          : initialXrayParameter,
        reportText: reportDto?.reportText,
        isReportTextDocument: !!reportDto?.reportText
      };
    } else {
      initialValues = {
        subject: "",
        reportDate: DateTime.jsDateNow(),
        files: [],
        xrayParameters: initialXrayParameter
      };
    }

    return initialValues;
  };
}
