import { useForm } from "react-final-form";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { TreatmentData } from "@shared-types/clinical/treatment-data.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { sotapNameOf } from "./SOTAP.utils.ts";

export interface TreatmentFieldProps {
  treatmentArrayName: "treatments" | "planTreatments";
  treatmentBaseArrayName: "treatmentsBase" | "planTreatmentsBase";
  setIsFilledFromTreatments?: (value: boolean) => void;
  updateTreatmentPlan: boolean;
}

export const TreatmentFieldsBase: React.FC<TreatmentFieldProps> = ({
  treatmentArrayName,
  updateTreatmentPlan,
  treatmentBaseArrayName
}) => {
  const { clinical, core, userExperience } = useStores();

  const form = useForm<SOTAPFormValues>();
  const { fields: treatments } = useFieldArray(treatmentArrayName);

  const PLACEHOLDER_TEXT = "Select treatment(s)";

  const labelText =
    treatmentArrayName === "treatments" ? "Today's treatment" : "Treatment";

  const getTreatmentOptions = () => {
    let treatmentOptionsKeyValues = [];
    // Convert the roles from the string.
    if (core.tenantDetails!.country === Country.NewZealand) {
      // In NZ tenants we display treatment options based on business role
      const businessRoles = core.user?.businessRoles;
      const abbr = userExperience.ref.businessRoleAbbreviations.values;
      const roles = abbr.filter(
        x => businessRoles?.find(y => y.includes(x.code))
      );

      const results = clinical.ref.treatmentOptions.values
        .filter(option => {
          if (option.code === "OTH") return true;
          return roles.find(x => option.businessRoles?.includes(x.text));
        })
        .map(option => ({ key: option.code, text: option.text }));

      treatmentOptionsKeyValues = results;
    } else {
      // AU tenants just get the whole list
      treatmentOptionsKeyValues = clinical.ref.treatmentOptions.keyTextValues;
    }
    return treatmentOptionsKeyValues;
  };

  const theme = useTheme();

  const onRenderField = (options: { key: string; text: string }[]) => {
    let selectedText;
    if (options.length === 1) {
      selectedText = `${options.length} treatment selected`;
    } else if (options.length > 1) {
      selectedText = `${options.length} treatments selected`;
    } else {
      selectedText = PLACEHOLDER_TEXT;
    }

    return (
      <Text
        styles={{
          root: {
            color: theme.palette.neutralPrimary
          }
        }}
      >
        {selectedText}
      </Text>
    );
  };

  const updateTreatments = (values: string[]) => {
    if (values) {
      const treatmentList: TreatmentData[] = values.map(treatment => {
        const treatmentData: TreatmentData = treatments.value
          ? treatments.value.find(x => x.treatment === treatment)
          : null;

        return {
          treatment,
          check: true,
          comment: treatmentData?.comment,
          key: treatmentData?.key,
          isTreatAndEdu: updateTreatmentPlan
        };
      });

      const otherTreatmentsKey =
        treatmentArrayName === "treatments"
          ? "otherTreatments"
          : "planOtherTreatments";

      form.change(treatmentArrayName, treatmentList);

      const otherTreatments = form.getState().values[otherTreatmentsKey];
      if (otherTreatments && !values.includes("OTH")) {
        form.change(sotapNameOf(otherTreatmentsKey), undefined);
      }
    }
  };

  const updateTreatmentsBase = (
    value: boolean | undefined,
    treatment: string
  ) => {
    if (!value) {
      const values = form.getState().values;
      const treatments: TreatmentData[] = values[treatmentArrayName] ?? [];
      const treatmentsBase: string[] = values[treatmentBaseArrayName] ?? [];

      const filteredOutTreatments = treatments.filter(
        x => x.treatment !== treatment
      );

      const filteredOutTreatmentsBase = treatmentsBase.filter(
        x => x !== treatment
      );
      form.batch(() => {
        form.change(treatmentArrayName, filteredOutTreatments);
        form.change(treatmentBaseArrayName, filteredOutTreatmentsBase);
      });
    }
  };

  const addCheckBoxAndCommentFields = (
    name: string,
    treatment: string,
    isChecked: boolean
  ) => {
    return (
      treatment && (
        <Stack tokens={{ childrenGap: 8 }} key={`${name}.treatment`}>
          <Stack horizontal>
            <CheckboxField
              name={`${name}.check`}
              onChange={(ev, checked) =>
                updateTreatmentsBase(checked, treatment)
              }
              label={getTreatmentOptions().find(x => x.key === treatment)?.text}
              checked={isChecked}
              styles={{
                label: {
                  fontWeight: 600,
                  fontSize: 14,
                  alignSelf: "center"
                }
              }}
            />

            {treatment === "OTH" && treatmentArrayName === "treatments" && (
              <TextInputField
                name={sotapNameOf("otherTreatments")}
                validateOnInitialize={true}
                styles={{ root: { paddingLeft: "8px" } }}
              />
            )}

            {treatment === "OTH" && treatmentArrayName === "planTreatments" && (
              <TextInputField
                name={sotapNameOf("planOtherTreatments")}
                validateOnInitialize={true}
                styles={{ root: { paddingLeft: "8px" } }}
              />
            )}
          </Stack>
          <Stack
            styles={{ root: { marginLeft: 28 } }}
            tokens={{ childrenGap: 8 }}
          >
            <TextInputField
              styles={{ root: { paddingBottom: "8px" } }}
              name={`${name}.comment`}
              placeholder="Comment"
            />
          </Stack>
        </Stack>
      )
    );
  };

  return (
    <Stack>
      <OptionsSelectField
        name={treatmentBaseArrayName}
        options={getTreatmentOptions()}
        placeholder={PLACEHOLDER_TEXT}
        label={labelText}
        hideSearchOption
        multiSelect
        showAllSelected
        onRenderFieldContent={onRenderField}
      />
      <FieldSpy name={treatmentBaseArrayName} onChange={updateTreatments} />
      {treatments && treatments.value.length > 0 && (
        <Stack
          styles={{
            root: {
              border: `1px solid ${theme.palette.neutralLight}`,
              padding: "8px",
              marginTop: 8
            }
          }}
        >
          {treatments.map((name, index) => {
            const treatment = treatments.value[index].treatment;
            const isChecked = treatments.value[index].check;
            return addCheckBoxAndCommentFields(name, treatment, isChecked);
          })}
        </Stack>
      )}
    </Stack>
  );
};

export const TreatmentFields = withFetch(
  x => [x.userExperience.ref.businessRoleAbbreviations.load()],
  TreatmentFieldsBase
);
