import { FC } from "react";

import { FontIcon, Stack, Text, useTheme } from "@bps/fluent-ui";

interface ProductsProps {
  products: string[];
}

export const Products: FC<ProductsProps> = ({ products }: ProductsProps) => {
  const { palette } = useTheme();
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      {products.map(x => (
        <Stack
          key={x}
          verticalAlign="center"
          horizontal
          tokens={{ childrenGap: 4 }}
        >
          <FontIcon
            iconName="BpOmniLogoBG"
            styles={{
              root: {
                paddingTop: 2,
                color: palette.themeDarker
              }
            }}
          />
          <Text>{x}</Text>
        </Stack>
      ))}
    </Stack>
  );
};
