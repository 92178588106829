import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";

import {
  DefaultButton,
  getScreenSelector,
  mergeStyles,
  ScreenWidthMinXXLarge,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { DrugMedicationSummaryDto } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { FormSubmitButtonsProps } from "@ui-components/form/submission-form/FormSubmitButtons.types.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { useQuickAccessContext } from "../quickAccess/context/QuickAccessContext.tsx";
import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";
import { PrescribingDoseFormLeftFields } from "./PrescribingDoseFormLeftFields.tsx";
import { PrescribingDoseFormRightFields } from "./PrescribingDoseFormRightFields.tsx";
import { PrescribingDoseFormValidator } from "./PrescribingDoseFormValidator.tsx";
import { PrescribingHighlightStack } from "./PrescribingHighlightStack.tsx";
import { PrescribingPatientHeader } from "./PrescribingPatientHeader.tsx";
import { PrescribingProductCard } from "./PrescribingProductCard.tsx";
import { PrescribingDoseFormValues } from "./PrescribingWizard.types.tsx";
import { PrescribingWizardPage } from "./PrescribingWizardPage.tsx";
import { ReasonForPrescriptionField } from "./ReasonForPrescriptionField.tsx";

export const nameOfPrescribingDoseForm =
  nameOfFactory<PrescribingDoseFormValues>();

const fieldsContainerStyles = mergeStyles({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "16px",

  [getScreenSelector(0, ScreenWidthMinXXLarge)]: {
    gridTemplateColumns: "auto"
  }
});

export const PrescribingDoseForm: React.FunctionComponent = observer(() => {
  const helper = usePrescribingWizardContext();
  const { restorePrescribingWizardHelper, onSaveMedication } = helper;
  const { core } = useStores();
  const hasPrescribePermission = core.hasPermissions(
    Permission.RxPrescribeAllowed
  );

  const {
    selectedMedication,
    setDoseFormDialogVisibleWithViewType,
    setMedicationDialogVisibleType,
    doseFormDialogVisibleWithViewType,
    medicationDialogVisibleWithType
  } = useMedicationsContext();

  const isPrescribed = medicationDialogVisibleWithType === "prescribe";

  const validator = new PrescribingDoseFormValidator({
    hasPrescribePermission
  });

  const { clinicalRecord } = usePatientRecordScreenContext();
  const { setQuickAccessPrescribingRx } = useQuickAccessContext();
  const isActionView = doseFormDialogVisibleWithViewType === "view";

  const getButtonsProps = (
    form: FormApi<PrescribingDoseFormValues>
  ): FormSubmitButtonsProps<PrescribingDoseFormValues> => ({
    cancelButtonProps: {
      text: isActionView ? "Close" : "Cancel"
    },
    hideSubmit: isActionView,
    submitButtonProps: {
      text: "Save & Close",
      iconProps: { iconName: "Save" },
      items: !!medicationDialogVisibleWithType
        ? [
            {
              key: "prescribe",
              text: "Save & New",
              iconProps: {
                iconName: "SaveAndClose"
              },
              onClick: () => {
                helper.saveAndNew = true;
                form.submit();
              }
            }
          ]
        : undefined
    },
    extraActionsBefore: () => {
      if (!isActionView && !!medicationDialogVisibleWithType) {
        return (
          <DefaultButton
            onClick={() => {
              setDoseFormDialogVisibleWithViewType(undefined);
            }}
          >
            Back
          </DefaultButton>
        );
      }
      return null;
    }
  });

  const TITLE = "Record Rx";

  const onDismiss = () => {
    setMedicationDialogVisibleType(undefined);
    setDoseFormDialogVisibleWithViewType(undefined);
    restorePrescribingWizardHelper();
  };

  return (
    <DataFetcher<DrugMedicationSummaryDto | undefined>
      fetch={async ({ drugs }) => {
        if (!selectedMedication) return undefined;

        const formulations = await drugs.searchMedicationSummary({
          packId: `${selectedMedication.packId}`
        });

        return formulations[0];
      }}
      noExceptionsHandlers
    >
      {(formulation, loading, error) => {
        const abbreviatedProductInformationUrl =
          formulation?.productPack.abbreviatedProductInformationUrl ??
          helper.selectedFormulation?.productPack
            .abbreviatedProductInformationUrl;

        return (
          <SubmissionFormDialog<PrescribingDoseFormValues>
            dialogName={`${TITLE} dialog`}
            disabled={doseFormDialogVisibleWithViewType === "view"}
            initialValues={helper.getInitialDoseFormValues(selectedMedication)}
            validate={validator.validate}
            onSubmit={values =>
              onSaveMedication(values, isPrescribed, selectedMedication?.id)
            }
            onSubmitSucceeded={() => {
              if (helper.saveAndNew) {
                setDoseFormDialogVisibleWithViewType(undefined);
                restorePrescribingWizardHelper();
              } else {
                onDismiss();
              }
              setQuickAccessPrescribingRx(false);
            }}
            buttonsProps={getButtonsProps}
            dialogProps={{
              minWidth: "90vw",
              maxWidth: "90vw",
              onDismiss,
              dialogContentProps: { title: TITLE }
            }}
          >
            {() => {
              return (
                <PrescribingWizardPage>
                  <Stack
                    styles={{
                      root: {
                        width: "50%",
                        paddingRight: 16,
                        maxHeight: "100%",
                        overflowY: "auto"
                      }
                    }}
                    tokens={{ childrenGap: 8 }}
                  >
                    <Stack tokens={{ childrenGap: 8 }}>
                      <PrescribingPatientHeader
                        clinicalRecord={clinicalRecord}
                      />

                      <PrescribingProductCard
                        loading={helper.formulationsLoading || !!loading}
                        error={error}
                        drug={formulation ?? helper.selectedFormulation}
                      />
                    </Stack>

                    <div className={fieldsContainerStyles}>
                      <PrescribingDoseFormLeftFields
                        isPrescribed={isPrescribed}
                      />
                      <PrescribingDoseFormRightFields
                        formulation={formulation}
                        isPrescribed={isPrescribed}
                      />
                    </div>
                    {isPrescribed && (
                      <>
                        <TextInputField
                          label="Complex"
                          placeholder="More complex instructions"
                          name={nameOfPrescribingDoseForm("complex")}
                          multiline
                          resizable={false}
                        />
                        <ReasonForPrescriptionField />
                      </>
                    )}
                  </Stack>

                  <Stack styles={{ root: { width: "50%" } }}>
                    <PrescribingHighlightStack>
                      {abbreviatedProductInformationUrl ? (
                        <iframe
                          title="Mims Abbreviated"
                          frameBorder="0"
                          height="100%"
                          src={abbreviatedProductInformationUrl}
                        />
                      ) : (
                        <PrescribingHighlightStack
                          horizontalAlign="center"
                          verticalAlign="center"
                        >
                          MIMS Abbreviated Information does not exist for this
                          product.
                        </PrescribingHighlightStack>
                      )}
                    </PrescribingHighlightStack>
                  </Stack>
                </PrescribingWizardPage>
              );
            }}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
});
