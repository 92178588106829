import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { ScrollablePane, Stack } from "@bps/fluent-ui";
import {
  AppointmentTypeScreenContext,
  useAppointmentTypeScreenContext
} from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";
import { AppointmentTypeScreenModel } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ScreenLayout } from "../shared-components/ScreenLayout.tsx";
import { AppointmentTypeDialog } from "./components/AppointmentTypeDialog.tsx";
import { AppointmentTypeFilter } from "./components/AppointmentTypeFilter.tsx";
import { AppointmentTypeHeader } from "./components/AppointmentTypeHeader.tsx";
import { AppointmentTypeList } from "./components/AppointmentTypeList.tsx";
import { OnlineAppointmentTypeDialog } from "./components/OnlineSettings/OnlineAppointmentTypeDialog.tsx";
import { OnlineSettingsDialog } from "./components/OnlineSettings/OnlineSettingsDialog.tsx";

const AppointmentTypesScreenBase: FunctionComponent = observer(() => {
  const { appointmentType, setAppointmentType, showOnlineSettingModal } =
    useAppointmentTypeScreenContext();

  return (
    <ScreenLayout
      tileStyles={{
        root: { height: "100%" },
        content: { height: "inherit", display: "flex", flexDirection: "column" }
      }}
      onRenderHeader={() => <AppointmentTypeHeader />}
    >
      <AppointmentTypeFilter>
        {() => (
          <Stack
            grow
            styles={{
              root: {
                position: "relative",
                height: "100%",
                overflow: "hidden"
              }
            }}
          >
            <ScrollablePane>
              <AppointmentTypeList />
            </ScrollablePane>

            {appointmentType !== null && (
              <AppointmentTypeDialog
                appointmentType={appointmentType}
                closeModal={() => setAppointmentType(null)}
              />
            )}
            {showOnlineSettingModal && <OnlineSettingsDialog />}
            <OnlineAppointmentTypeDialog />
          </Stack>
        )}
      </AppointmentTypeFilter>
    </ScreenLayout>
  );
});

const AppointmentTypesScreen: FunctionComponent = () => {
  const root = useStores();
  return (
    <AppointmentTypeScreenContext.Provider
      value={new AppointmentTypeScreenModel(root)}
    >
      <AppointmentTypesScreenBase />
    </AppointmentTypeScreenContext.Provider>
  );
};

// eslint-disable-next-line import/no-default-export
export default AppointmentTypesScreen;
