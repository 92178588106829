import { decode } from "base64-arraybuffer";
import { observer } from "mobx-react-lite";
// eslint-disable-next-line import/extensions
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";

import {
  FontSizes,
  IStackItemStyles,
  NoDataTile,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { getDefaultLetterheadId } from "@modules/clinical/screens/document-writer/components/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  getCanvasKey,
  getDocumentPdfPages
} from "@ui-components/document-viewer/pdf-utils.tsx";
import { PdfCanvas } from "@ui-components/document-viewer/PdfCanvas.tsx";

interface TemplatePreviewProps {
  templateId?: string;
}

export const TemplatePreview: React.FC<TemplatePreviewProps> = observer(
  ({ templateId }) => {
    const { document, core, practice } = useStores();
    const theme = useTheme();
    const getPreview = async () => {
      if (templateId) {
        const letterheadId = await getDefaultLetterheadId(
          core,
          practice,
          document
        );

        const selectedTemplate = await document.getTemplate(templateId);

        const template = await document.renderTemplate(templateId, {
          isPreview: true,
          letterheadId:
            selectedTemplate.documentType !== CorrespondenceType.Autofill
              ? letterheadId
              : undefined
        });

        const templateUrl = new Uint8Array(decode(template.content));
        return await getDocumentPdfPages(templateUrl);
      }
      return undefined;
    };

    const pdfWrapper: IStackItemStyles = {
      root: {
        height: "100%",
        width: "283px",
        padding: 4,
        justifyContent: "center",
        color: theme.palette.neutralTertiary,
        backgroundColor: theme.palette.neutralLighterAlt
      }
    };

    return (
      <Stack
        styles={{
          root: {
            backgroundColor: theme.palette.neutralLighterAlt,
            width: 283
          }
        }}
      >
        {templateId ? (
          <DataFetcher<PDFDocumentProxy | undefined>
            fetch={getPreview}
            key={templateId}
            fallback={
              <Stack
                verticalAlign="center"
                styles={{
                  root: {
                    width: "100%",
                    height: "100%"
                  }
                }}
              >
                <Spinner />
              </Stack>
            }
          >
            {pdf => {
              return (
                <>
                  {pdf && (
                    <Stack styles={pdfWrapper}>
                      <Stack
                        styles={{
                          root: {
                            width: "100%",
                            height: "100%",
                            backgroundColor: "white"
                          }
                        }}
                      >
                        <PdfCanvas
                          pdf={pdf}
                          key={getCanvasKey(pdf, 0)}
                          pageNumber={0}
                          scale={45}
                        />
                      </Stack>
                    </Stack>
                  )}
                </>
              );
            }}
          </DataFetcher>
        ) : (
          <Stack
            grow
            verticalFill
            horizontalAlign="center"
            verticalAlign="center"
            styles={{
              root: {
                padding: 8,
                height: 300
              }
            }}
          >
            <NoDataTile
              styles={{
                root: {
                  backgroundColor: theme.palette.neutralLighterAlt
                }
              }}
              textProps={{
                text: (
                  <Stack
                    styles={{
                      root: {
                        backgroundColor: theme.palette.neutralLighterAlt,
                        fontSize: FontSizes.size14,
                        fontStyle: "normal"
                      }
                    }}
                  >
                    <Text
                      styles={{
                        root: { color: theme.palette.neutralSecondary }
                      }}
                    >
                      Click on a template
                    </Text>
                    <Text
                      styles={{
                        root: { color: theme.palette.neutralSecondary }
                      }}
                    >
                      to preview it here
                    </Text>
                  </Stack>
                )
              }}
              linkProps={{
                hidden: true
              }}
              showBoxShadow={false}
            />
          </Stack>
        )}
      </Stack>
    );
  }
);
