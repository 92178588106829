import { useForm } from "react-final-form";

import {
  AnimatedListWithKeys,
  DefaultButton,
  Heading,
  NoDataTile,
  Stack
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";
import { RelationshipFields } from "./relationships/RelationshipFields.tsx";
import { RelationshipFieldValue } from "./relationships/utils.ts";

interface EditRelationshipsProps {
  fullName: string | undefined;
}

export const EditRelationships: React.FC<EditRelationshipsProps> = ({
  fullName
}) => {
  const reactFinalForm = useForm<PatientEditFormValues>();

  const addRelationship =
    (type: ContactType = ContactType.Patient) =>
    () => {
      const relationships = reactFinalForm
        .getState()
        .values.relationships.map(p => ({ ...p, isNew: false }));

      const newRelationship: RelationshipFieldValue = {
        family: [],
        special: [],
        professional: [],
        other: [],
        account: [],
        type,
        isNew: true,
        id: newGuid()
      };
      reactFinalForm.change(patientFormNameOf("relationships"), [
        ...relationships,
        newRelationship
      ]);
    };

  return (
    <div
      id={`${PatientCardIds.contactRelationships}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack
        styles={(_props, theme) => ({
          root: {
            borderBottom: `1px solid ${theme.palette.neutralLighterAlt}`
          }
        })}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { lineHeight: "22px", marginBottom: 6 } }}
        >
          <Heading variant="section-heading-light">
            {Labels.relationships}
          </Heading>
          <DefaultButton
            iconProps={{ iconName: "Add" }}
            text="Add more"
            menuProps={{
              styles: { container: { width: 152 } },
              shouldFocusOnMount: true,
              items: [
                {
                  key: "relatedPerson",
                  text: Labels.relatedPerson,
                  onClick: addRelationship()
                },
                {
                  key: "relatedOrganisation",
                  text: Labels.relatedOrganisation,
                  onClick: addRelationship(ContactType.Organisation)
                }
              ]
            }}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 16 }}>
          <FieldArray<RelationshipFieldValue>
            name={patientFormNameOf("relationships")}
          >
            {fieldProps => {
              return (
                <>
                  {fieldProps.fields.value.filter(x => x.account.length < 1)
                    .length === 0 && (
                    <NoDataTile
                      textProps={{ text: "No relationships" }}
                      linkProps={{
                        text: "Add a relationship to this person",
                        onClick: addRelationship()
                      }}
                    />
                  )}
                  <AnimatedListWithKeys
                    items={fieldProps.fields.value}
                    onItemIdRemoved={id => {
                      const index = fieldProps.fields.value.findIndex(
                        v => v.id === id
                      );
                      fieldProps.fields.remove(index);
                    }}
                  >
                    {(id, onRemoveItem) => {
                      const index = fieldProps.fields.value.findIndex(
                        v => v.id === id
                      );
                      if (index >= 0) {
                        return (
                          <Fieldset frame>
                            <RelationshipFields
                              fullName={fullName}
                              onRemove={onRemoveItem}
                              name={`${patientFormNameOf(
                                "relationships"
                              )}[${index}]`}
                            />
                          </Fieldset>
                        );
                      }
                      return null;
                    }}
                  </AnimatedListWithKeys>
                </>
              );
            }}
          </FieldArray>
        </Stack>
      </Stack>
    </div>
  );
};
