import { IStackProps, mergeStyles, Stack } from "@bps/fluent-ui";

export const SideNavContent: React.FC<IStackProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Stack
      data-is-scrollable={true}
      className={mergeStyles(className, {
        flex: 1,
        overflow: "auto"
      })}
      {...props}
    >
      {children}
    </Stack>
  );
};
