import { DeepPartial } from "@fluentui/merge-styles";
import { SubmissionFormStyles } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

export const fullScreen = {
  flexGrow: 1,
  margin: -16,
  height: "calc(100% + 32px)"
};

export const fullScreenForm: DeepPartial<SubmissionFormStyles> = {
  root: fullScreen,
  main: { overflowY: "visible", background: "transparent" },
  fields: { flexGrow: 1, overflowY: "visible" }
};
