import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { Contact } from "@stores/practice/models/Contact.ts";

import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { NoDetailsButton } from "../../shared-components/view/NoDetailsButton.tsx";
import { PatientProvidersExternal } from "./HealthProviderExternal.tsx";
import { PatientProvidersInternal } from "./HealthProvidersInternal.tsx";

interface PatientFieldProps {
  showEditIcon: boolean;
  patient: Contact;
  onHandleEditModal: (id?: string) => void;
}

export const PatientProviders: FunctionComponent<PatientFieldProps> = observer(
  ({ onHandleEditModal, patient, showEditIcon }) => {
    return (
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={{ root: { padding: "0 10px" } }}
      >
        {showEditIcon ? (
          <>
            <PatientProvidersInternal patient={patient} />
            <PatientProvidersExternal patient={patient} />
          </>
        ) : (
          <NoDetailsButton
            sectionId={PatientCardIds.healthProviders}
            actionTitle="Add a health provider"
            noPermissionText="No health providers"
            secondaryText="Internal provider, External provider"
            onHandleEditModal={onHandleEditModal}
          />
        )}
      </Stack>
    );
  }
);
