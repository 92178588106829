import { observer } from "mobx-react-lite";

import {
  Callout,
  dataAttribute,
  DataAttributes,
  DirectionalHint,
  FontIcon,
  FontSizes,
  Heading,
  ICalloutProps,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { AppointmentVisitDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ClaimStatusText } from "@ui-components/RefText.tsx";

type ClaimCalloutProps = Pick<ICalloutProps, "target" | "onDismiss"> & {
  claim: Claim;
  iconOptions: {
    iconColour: string;
    iconName?: string;
  };
};

interface ClaimCalloutDataFetcherProps
  extends Omit<ClaimCalloutProps, "claim"> {
  claimId: string;
}

const ClaimCalloutBase: React.FC<ClaimCalloutProps> = observer(
  ({ target, onDismiss, claim, iconOptions }) => {
    const { iconColour, iconName } = iconOptions;

    const { acc } = useStores();

    const getProviderTypeText = (visit: AppointmentVisitDto) => {
      const providerTypeText = visit.providerType
        ? acc.ref.providerTypes.get(visit.providerType)?.text
        : undefined;
      return providerTypeText
        ? `${visit.contractType} (${providerTypeText})`
        : visit.contractType;
    };

    return claim ? (
      <Callout
        target={target}
        onDismiss={onDismiss}
        directionalHint={DirectionalHint.leftCenter}
        calloutWidth={400}
      >
        <Stack
          styles={{ root: { padding: "20px 24px" } }}
          tokens={{ childrenGap: 24 }}
        >
          <Stack
            horizontal
            styles={{
              root: { alignItems: "center", justifyContent: "space-between" }
            }}
          >
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Heading variant="section-heading-light">
                {claim.claimNumber || "Not lodged"}
              </Heading>
              {claim.isOnHold && (
                <TextBadge
                  badgeSize={TextBadgeSize.small}
                  badgeColor={TextBadgeColor.lightGrey}
                  styles={{ root: { fontSize: FontSizes.size12 } }}
                  {...dataAttribute(DataAttributes.Element, "on-hold-badge")}
                >
                  On hold
                </TextBadge>
              )}
            </Stack>
            <Stack horizontal>
              <FontIcon
                iconName={iconName}
                styles={{
                  root: {
                    color: iconColour,
                    marginRight: 8,
                    fontSize: FontSizes.size18
                  }
                }}
              />
              <ClaimStatusText code={claim?.claimStatus} />
            </Stack>
          </Stack>
          {claim.primaryDiagnosis && (
            <Stack>
              <Heading labelPaddings>Primary diagnosis</Heading>
              <Text>{`${claim.primaryDiagnosis.terminology?.code} — ${claim.primaryDiagnosis.terminology?.text}`}</Text>
            </Stack>
          )}
          {claim.appointmentVisits &&
            claim.appointmentVisits.some(x => x.contractType) && (
              <Stack>
                <Heading labelPaddings>Consults provided of allocated</Heading>
                {claim.appointmentVisits?.map(visit => {
                  if (visit.contractType) {
                    return (
                      <Text
                        key={`${visit.contractType} ${visit.providerType}`}
                      >{`${getProviderTypeText(visit)} - ${visit.booked} of ${
                        visit.allocated
                      }`}</Text>
                    );
                  }
                  return null;
                })}
              </Stack>
            )}
          {claim.accidentDate && (
            <Stack>
              <Heading labelPaddings>InjuryDate</Heading>
              <Text>{claim.accidentDate.toDayDefaultFormat()}</Text>
            </Stack>
          )}
          <Navigate to={routes.claims.management.edit.path({ id: claim.id })}>
            Go to claim
          </Navigate>
        </Stack>
      </Callout>
    ) : null;
  }
);

export const ClaimCalloutDataFetcher: React.FC<
  ClaimCalloutDataFetcherProps
> = ({ claimId, ...props }) => {
  return (
    <DataFetcher
      fetch={({ acc }) => acc.getClaim(claimId)}
      fallback={<Spinner />}
    >
      {(claim: Claim) => <ClaimCalloutBase claim={claim} {...props} />}
    </DataFetcher>
  );
};

export const ClaimCallout = withFetch(
  x => x.practice.ref.accProviderContractTypes.load(),
  ClaimCalloutDataFetcher
);
