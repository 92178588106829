import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  Card,
  DefaultButton,
  DetailsList,
  FontIcon,
  Heading,
  IBreadcrumbItem,
  mergeStyles,
  mergeStyleSets,
  NoDataTile,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { FeeInstanceListDataItem } from "@modules/settings/screens/schedules/components/fees/fee-view/fee-view.types.ts";
import { getInstanceListData } from "@modules/settings/screens/schedules/components/fees/fee-view/utils.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import { getFeeIncludingGst } from "../../../../shared-components/fees.utils.ts";
import { FeeFormLabels, FeeTileLabels } from "../../ScheduleForm.types.tsx";
import { getFeeTypeText } from "../../utils.ts";
import { getFeesStylesSet } from "../Fees.styles.ts";

const FeeView: React.FC<{}> = observer(() => {
  const { core, billing } = useStores();
  const theme = useTheme();
  const { feeViewSubheadingStyles } = getFeesStylesSet(theme);

  const {
    viewSchedule: schedule,
    viewFee: fee,
    openScheduleView,
    openScheduleList,
    openFeeDialog
  } = useContext(ScheduleScreenContext);
  // if somehow this is mounted but either of these are falsy then render nothing
  if (!schedule || !fee) {
    return null;
  }

  const canEdit =
    core.hasPermissions(Permission.ServiceWrite) && !fee.benefitId;

  const { listCellRightAlignHeaderClassName, listRowStyles } =
    getFeesStylesSet(theme);

  const breadcrumbs: IBreadcrumbItem[] = [
    {
      key: "schedules",
      text: "Schedules",
      onClick: openScheduleList
    },
    {
      key: "view-schedule",
      text: schedule.name,
      onClick: () => openScheduleView(schedule)
    },
    {
      key: "view-fee",
      text: fee.code,
      isCurrentItem: true
    }
  ];

  const viewCardButtons = (
    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
      <TextBadge
        styles={{ root: { height: 32 } }}
        badgeSize={TextBadgeSize.large}
        badgeColor={fee?.isActive ? TextBadgeColor.green : TextBadgeColor.grey}
      >
        Active
      </TextBadge>
      {canEdit && (
        <DefaultButton onClick={() => openFeeDialog(schedule, fee)}>
          Edit
        </DefaultButton>
      )}
    </Stack>
  );

  const feeAmount: string = fee.currentNextOrMostRecentActiveInstance?.fee
    ? `${currencyFormat(
        getFeeIncludingGst(
          fee.currentNextOrMostRecentActiveInstance?.fee,
          fee.gstMethod,
          billing.gstPercent
        )
      )} (${getFeeTypeText(fee.feeType)})`
    : "";

  let startDateText = DateTime.fromISO(fee.startDate).toDayDefaultFormat();
  if (fee.nextInstance?.isActive === false) {
    const toDate = DateTime.fromISO(fee.nextInstance.effectiveDate);
    startDateText += ` to ${toDate.toDayDefaultFormat()}`;
  } else if (!fee.nextInstance && fee.currentInstance?.isActive === false) {
    const toDate = DateTime.fromISO(fee.currentInstance.effectiveDate);
    startDateText += ` to ${toDate.toDayDefaultFormat()}`;
  } else {
    startDateText += " Ongoing";
  }

  return (
    <Stack id="fee-view" tokens={{ childrenGap: 16 }} grow>
      <BreadCrumb routes={breadcrumbs} />
      <Card
        headingLevel="section-heading"
        heading={`Fee ${fee.code}`}
        button={viewCardButtons}
      >
        <Stack tokens={{ childrenGap: 12 }}>
          <Stack horizontal tokens={{ childrenGap: 32 }}>
            <Stack>
              <Heading
                variant="section-sub-heading"
                styles={feeViewSubheadingStyles}
              >
                {FeeFormLabels.itemNumber}
              </Heading>
              <Text>{fee.code}</Text>
            </Stack>
            <Stack>
              <Heading
                variant="section-sub-heading"
                styles={feeViewSubheadingStyles}
              >
                {FeeFormLabels.feeFor}
              </Heading>
              <Text>
                {fee.isService ? FeeTileLabels.service : FeeTileLabels.material}
              </Text>
            </Stack>
            <Stack>
              <Heading
                variant="section-sub-heading"
                styles={feeViewSubheadingStyles}
              >
                {FeeFormLabels.fee}
              </Heading>
              <Text>{feeAmount}</Text>
            </Stack>
            <Stack verticalAlign="end">
              {fee.gstIncluded ? (
                <>
                  <Heading
                    variant="section-sub-heading"
                    styles={feeViewSubheadingStyles}
                  >
                    {FeeFormLabels.gst}
                  </Heading>
                  <FontIcon
                    iconName="Completed"
                    styles={{ root: { fontSize: 18 } }}
                  />
                </>
              ) : (
                <Text as="em">{FeeFormLabels.gstExcluded}</Text>
              )}
            </Stack>
            <Stack verticalAlign="end">
              <Heading
                variant="section-sub-heading"
                styles={feeViewSubheadingStyles}
              >
                {FeeFormLabels.editableFee}
              </Heading>
              {fee.feeIsEditable ? (
                <FontIcon
                  iconName="Completed"
                  styles={{ root: { fontSize: 18 } }}
                />
              ) : (
                <Text>No</Text>
              )}
            </Stack>
          </Stack>
          {fee.name && (
            <Stack>
              <Heading
                variant="section-sub-heading"
                styles={feeViewSubheadingStyles}
              >
                {FeeFormLabels.name}
              </Heading>
              <Text>{fee.name}</Text>
            </Stack>
          )}
          {fee.description && (
            <Stack>
              <Heading
                variant="section-sub-heading"
                styles={feeViewSubheadingStyles}
              >
                {FeeFormLabels.description}
              </Heading>
              <Text>{fee.description}</Text>
            </Stack>
          )}
          <Stack>
            <Heading
              variant="section-sub-heading"
              styles={feeViewSubheadingStyles}
            >
              {FeeFormLabels.startDate}
            </Heading>
            <Text>{startDateText}</Text>
          </Stack>
        </Stack>
      </Card>
      <Card headingLevel="section-heading" heading="History">
        {fee.instances?.length > 1 ? (
          <DetailsList
            items={getInstanceListData(
              fee.instances,
              fee.gstMethod,
              billing.gstPercent
            )}
            bordersVariant="bottomHeader"
            onRenderRow={(props, defaultRender) => {
              if (!props || !defaultRender) {
                return null;
              }

              const styles = {
                cell: {
                  paddingTop: 4,
                  paddingBottom: 4
                }
              };
              return defaultRender({
                ...props,
                styles: mergeStyleSets(listRowStyles, styles)
              });
            }}
            columns={[
              {
                name: "",
                key: "badge",
                minWidth: 90,
                maxWidth: 90,
                fieldName: "badge",
                onRender: (item: FeeInstanceListDataItem) => (
                  <TextBadge {...item.badge} />
                )
              },
              {
                key: "date",
                name: "Date range",
                minWidth: 200,
                maxWidth: 200,
                fieldName: "date"
              },
              {
                key: "fee",
                name: "Fee",
                className: mergeStyles({ justifyContent: "flex-end" }),
                headerClassName: listCellRightAlignHeaderClassName,
                maxWidth: 80,
                minWidth: 80,
                fieldName: "fee"
              },
              {
                key: "state",
                name: "State",
                maxWidth: 150,
                minWidth: 150,
                fieldName: "state"
              }
            ]}
          />
        ) : (
          <NoDataTile
            textProps={{
              text: "This fee has not changed since it was created"
            }}
            linkProps={{ hidden: true }}
            showBoxShadow={false}
            styles={{ root: { minHeight: 68 } }}
          />
        )}
      </Card>
    </Stack>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default FeeView;
