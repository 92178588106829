import { observer } from "mobx-react-lite";

import { useStores } from "@stores/hooks/useStores.ts";

import { PatientMatchContext } from "./context/PatientMatchContext.ts";
import { PatientMatchHelper } from "./context/PatientMatchHelper.ts";
import { PatientMatchForm } from "./PatientMatchForm.tsx";

const PatientMatchDialogBase: React.FC = () => {
  const root = useStores();
  return (
    <PatientMatchContext.Provider value={new PatientMatchHelper(root)}>
      <PatientMatchForm />
    </PatientMatchContext.Provider>
  );
};

const PatientMatchDialog: React.FC = observer(() => {
  return <PatientMatchDialogBase />;
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PatientMatchDialog;
