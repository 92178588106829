import { ValidationErrors } from "final-form";
import { observer } from "mobx-react-lite";
import { Fragment, FunctionComponent, useContext, useState } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  FieldItemError,
  FontIcon,
  FontSizes,
  IColumn,
  Link,
  mergeStyles,
  mergeStyleSets,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PurchaseOrderDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  FeeType,
  ServiceRuleType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { convertQuantity } from "@modules/billing/screens/allocation/utils.ts";
import { getInvalidItemsIndexes } from "@modules/billing/screens/billing-history/utils.ts";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { ServiceWarningsHelper } from "@modules/billing/screens/shared-components/add-service-modal/context/ServiceWarningsHelper.ts";
import {
  FooterOptions,
  HeaderOptions,
  InvoiceTotals,
  TotalField
} from "@modules/billing/screens/shared-components/AllocationList/AllocationListBase.types.tsx";
import { InvoiceItemListBase } from "@modules/billing/screens/shared-components/invoice-list-base/InvoiceItemListBase.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { UnitInputField } from "@ui-components/form/UnitInputField.tsx";

import {
  invoiceFormNameOf,
  invoiceItemFormNameOf,
  InvoiceItemsColumnNames,
  InvoiceItemsColumns,
  InvoiceItemsTotal
} from "./InvoiceForm.types.tsx";
import { InvoiceFormChargeToCell } from "./InvoiceFormChargeToCell.tsx";
import { InvoiceFormFeeInputField } from "./InvoiceFormFeeInputField.tsx";
import { InvoiceItemListAddComment } from "./InvoiceItemListAddComment.tsx";
import { InvoiceItemListFeeSpy } from "./InvoiceItemListFeeSpy.tsx";
import { InvoiceItemListOptionsValue } from "./InvoiceItemListOptionsDropdown.tsx";
import { InvoiceItemListPurchaseOrderSpy } from "./InvoiceItemListPurchaseOrderSpy.tsx";
import { InvoiceItemListQuantitySpy } from "./InvoiceItemListQuantitySpy.tsx";
import { PurchaseOrderField } from "./PurchaseOrderField.tsx";
import { ServiceSelectField } from "./service-select-field/ServiceSelectField.tsx";

export type FormOptionsValidationErrors =
  | undefined
  | string
  | ValidationErrors[];

export interface InvoiceFormItemListProps {
  invoiceItems: InvoiceItemFormValue[];
  claim?: Claim;
  onRemove: (index: number) => void;
  serviceWarningsHelper: ServiceWarningsHelper;
  errors: FormOptionsValidationErrors;
  submitFailed: boolean | undefined;
  onAddNewRow: () => void;
  onOpenItemDialog: () => void;
  purchaseOrders: PurchaseOrderDto[];
}

export const InvoiceFormItemList: FunctionComponent<InvoiceFormItemListProps> =
  observer(
    ({
      invoiceItems,
      claim,
      onRemove,
      serviceWarningsHelper,
      errors,
      submitFailed,
      purchaseOrders,
      onAddNewRow
    }) => {
      const theme = useTheme();
      const { core } = useStores();

      const { adjustInvoice } = useContext(InvoiceFormContext);

      const claimHasPurchaseOrders = !!purchaseOrders?.length;

      const startingDropdownValue: InvoiceItemListOptionsValue[] = [];

      if (
        claimHasPurchaseOrders ||
        invoiceItems.some(ii => ii.purchaseOrderNumber)
      ) {
        startingDropdownValue.push(InvoiceItemListOptionsValue.purchaseOrder);
      }
      if (invoiceItems.some(li => li.comment)) {
        startingDropdownValue.push(InvoiceItemListOptionsValue.lineItemComment);
      }

      const [optionsDropdownValue, setOptionsDropdownValue] = useState<
        InvoiceItemListOptionsValue[]
      >(startingDropdownValue);

      const renderActions = (index: number | undefined) =>
        (index || index === 0) && (
          <Stack
            className={mergeStyles({ height: "100%" })}
            horizontal
            horizontalAlign="end"
            verticalAlign="start"
          >
            <Link
              {...dataAttribute(DataAttributes.Element, "remove-invoice-link")}
              onClick={() => onRemove(index)}
            >
              Remove
            </Link>
          </Stack>
        );

      const getColumns = (): IColumn[] => {
        return [
          {
            name: InvoiceItemsColumnNames.serviceDate,
            key: InvoiceItemsColumns.serviceDate,
            minWidth: 110,
            maxWidth: 120,
            onRender: (item: InvoiceItemFormValue, index: number) => (
              <DatePickerField
                name={`${invoiceFormNameOf(
                  "invoiceItems"
                )}[${index}].${invoiceItemFormNameOf("serviceDate")}`}
                maxDate={DateTime.jsDateNow()}
              />
            )
          },
          {
            name: InvoiceItemsColumnNames.itemNumber,
            key: InvoiceItemsColumns.itemNumber,
            minWidth: 100,
            maxWidth: 120,
            onRender: (item: InvoiceItemFormValue, index: number) => (
              <FormItemField
                name={`invoiceItems[${index}].code`}
                styles={{ root: { flexGrow: 1 } }}
              >
                <ServiceSelectField
                  name={`invoiceItems[${index}]`}
                  placeholder="Item #"
                />
              </FormItemField>
            )
          },
          {
            name: InvoiceItemsColumnNames.description,
            key: InvoiceItemsColumns.description,
            minWidth: 150,
            maxWidth: 700,
            onRender: (item: InvoiceItemFormValue, index: number) => {
              const warnings = serviceWarningsHelper.getServiceWarnings(item, {
                claimId: claim?.id
              });

              const errors = serviceWarningsHelper.getServiceErrors(item, {
                claimId: claim?.id
              });
              return (
                <>
                  <ServiceSelectField
                    name={`invoiceItems[${index}]`}
                    placeholder="Description"
                    useDescription
                    styles={{ root: { width: "100%" } }}
                  />
                  <div>
                    {errors?.messages?.map(message => (
                      <FieldItemError
                        name={`${InvoiceItemsColumnNames.description}-error`}
                        key={message}
                        errorMessage={message}
                      />
                    ))}
                    {!errors?.messages?.length &&
                      warnings?.messages?.map(message => (
                        <FieldItemError
                          name={`${InvoiceItemsColumnNames.description}-warning`}
                          key={message}
                          errorMessage={message}
                          styles={{
                            errorMessage: { color: theme.palette.yellowDark }
                          }}
                        />
                      ))}
                  </div>
                  <FormItemField name={`invoiceItems[${index}].description`} />
                </>
              );
            }
          },
          {
            name: InvoiceItemsColumnNames.lineItemComment,
            key: InvoiceItemsColumns.lineItemComment,
            iconName: "Comment",
            isIconOnly: true,
            minWidth: 32,
            maxWidth: 32,
            styles: mergeStyleSets({
              cellTitle: {
                justifyContent: "center"
              }
            }),
            onRender: (_item: InvoiceItemFormValue, index: number) => (
              <InvoiceItemListAddComment index={index} />
            )
          },
          {
            name: InvoiceItemsColumnNames.purchaseOrderNumber,
            key: InvoiceItemsColumns.purchaseOrderNumber,
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: InvoiceItemFormValue, index: number) => (
              <>
                <PurchaseOrderField
                  name={`invoiceItems[${index}].purchaseOrderNumber`}
                  claim={claim}
                  purchaseOrders={purchaseOrders}
                />
                <InvoiceItemListPurchaseOrderSpy rowIndex={index} />
              </>
            )
          },
          {
            name: InvoiceItemsColumnNames.chargedTo,
            key: InvoiceItemsColumns.chargedTo,
            minWidth: 180,
            maxWidth: 180,
            onRender: (item: InvoiceItemFormValue) => (
              <InvoiceFormChargeToCell item={item} />
            )
          },

          {
            name: InvoiceItemsColumnNames.quantity,
            key: InvoiceItemsColumns.quantity,
            minWidth: 120,
            maxWidth: 300,
            styles: mergeStyleSets({
              cellTitle: {
                justifyContent: "flex-end"
              }
            }),
            onRender: (item: InvoiceItemFormValue, index: number) =>
              item.feeType === FeeType.FlatRate ? (
                <Text block styles={{ root: { textAlign: "right" } }}>
                  {item.quantity
                    ? convertQuantity(Number(item.quantity), item.feeType)
                    : ""}
                </Text>
              ) : (
                <>
                  <UnitInputField
                    name={`invoiceItems[${index}].quantity`}
                    unitType={item.feeType!}
                  />
                  <InvoiceItemListQuantitySpy rowIndex={index} />
                </>
              )
          },
          {
            name: InvoiceItemsColumnNames.fee,
            key: InvoiceItemsColumns.fee,
            minWidth: 115,
            maxWidth: 170,
            onRender: (item: InvoiceItemFormValue, index: number) => {
              if (
                !item.serviceSearch?.rules?.some(
                  r => r.ruleType === ServiceRuleType.UserDefinedAmount
                )
              ) {
                return (
                  <Text block styles={{ root: { textAlign: "right" } }}>
                    {item.total
                      ? currencyFormat(Number(item.total), { currency: "" })
                      : ""}
                  </Text>
                );
              }

              if (item.feeType === FeeType.FlatRate) {
                return (
                  <Fragment key={item.serviceId}>
                    <SpinNumberInputField
                      required
                      name={`invoiceItems[${index}].fee`}
                      parse={value => value ?? ""}
                      step={1}
                      precision={2}
                      styles={{ input: { textAlign: "right" } }}
                    />
                    <InvoiceItemListFeeSpy rowIndex={index} />
                  </Fragment>
                );
              }

              return (
                <Fragment key={item.serviceId}>
                  <InvoiceFormFeeInputField
                    index={index}
                    feeType={item.feeType!}
                  />
                  <InvoiceItemListFeeSpy rowIndex={index} />
                </Fragment>
              );
            },
            styles: mergeStyleSets({
              cellTitle: {
                justifyContent: "flex-end"
              }
            })
          },

          {
            name: InvoiceItemsColumnNames.gst,
            key: InvoiceItemsColumns.gst,
            minWidth: 50,
            maxWidth: 50,
            styles: mergeStyleSets({
              cellTitle: {
                justifyContent: "center"
              }
            }),
            onRender: (item: InvoiceItemFormValue) =>
              Number(item.gst) > 0 && (
                <Stack
                  horizontal
                  horizontalAlign="center"
                  verticalAlign="center"
                  tokens={{ childrenGap: 8 }}
                >
                  <FontIcon
                    iconName="Completed"
                    styles={{
                      root: {
                        fontSize: FontSizes.large
                      }
                    }}
                  />
                </Stack>
              )
          },
          {
            name: InvoiceItemsColumnNames.actions,
            key: InvoiceItemsColumns.actions,
            minWidth: 60,
            maxWidth: 60,
            isIconOnly: true,
            iconClassName: mergeStyles({ paddingLeft: "8px" }),
            styles: mergeStyleSets({
              cellTitle: {
                justifyContent: "flex-end"
              }
            }),
            onRender: (item: InvoiceItemFormValue, index: number) =>
              renderActions(index)
          }
        ].filter(col => {
          if (col.key === InvoiceItemsColumns.purchaseOrderNumber) {
            return optionsDropdownValue.includes(
              InvoiceItemListOptionsValue.purchaseOrder
            );
          }
          if (col.key === InvoiceItemsColumns.lineItemComment) {
            return optionsDropdownValue.includes(
              InvoiceItemListOptionsValue.lineItemComment
            );
          }

          // hide charged to column on Aus tenant
          return !(
            col.key === InvoiceItemsColumns.chargedTo &&
            (!core.isNZTenant || !claim)
          );
        });
      };

      const totalFields: TotalField[] = [
        {
          key: InvoiceTotals.totalIncGst,
          title: InvoiceItemsTotal.totalIncGst,
          dataAttr: "billing-totals-total-inc-gst"
        }
      ];

      const START_INDEX = 5;

      const footerOptions: FooterOptions = {
        startIndex: START_INDEX,
        fields: totalFields,
        onRenderFooterButton: () => (
          <ActionButton iconProps={{ iconName: "Add" }} onClick={onAddNewRow}>
            Add item
          </ActionButton>
        )
      };

      const headerOptions: HeaderOptions = {
        startIndex: START_INDEX,
        fields: totalFields
      };

      return (
        <InvoiceItemListBase
          name={invoiceFormNameOf("invoiceItems")}
          items={invoiceItems}
          columns={getColumns()}
          invoice={adjustInvoice}
          options={{
            footer: footerOptions,
            dropdownProps: {
              claimHasPurchaseOrders,
              value: optionsDropdownValue,
              onChange: setOptionsDropdownValue,
              invoiceItems
            },
            header: headerOptions,
            invalidItemsIndexes: [
              ...(getInvalidItemsIndexes({ errors, submitFailed }) || []),
              ...(serviceWarningsHelper.getErrorIndexes(invoiceItems, {
                claimId: claim?.id
              }) || [])
            ],
            warningItemsIndexes: serviceWarningsHelper.getWarningIndexes(
              invoiceItems,
              { claimId: claim?.id }
            )
          }}
        />
      );
    }
  );
