import { FormApi } from "final-form";
import React, { FunctionComponent, useContext } from "react";

import { useTheme } from "@bps/fluent-ui";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { CancelCalendarEventComponent } from "./CancelCalendarEventComponent.tsx";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";

interface AppointmentFormSubmitButtonsProps {
  onCancel: () => void;
}
export const AppointmentFormSubmitButtons: FunctionComponent<
  AppointmentFormSubmitButtonsProps
> = ({ onCancel }) => {
  const { spacing } = useTheme();
  const trackFormEvent = useAppTrackEvent(AppInsightsEventNames.formDeployed);

  const {
    initialValues,
    calendarEvent,
    onSubmit,
    showCancelEvent,
    isSettingOutsideWorkHoursWithNoPermission
  } = useContext(AppointmentFormContext);

  const onSubmitWithTracking = async (form: FormApi<AppointmentFormValues>) => {
    await onSubmit(form, (formName, page) =>
      trackFormEvent({ form: formName, page })
    );
  };

  const extraActionsBefore = () => {
    return calendarEvent ? (
      <CancelCalendarEventComponent
        showCancelEvent={showCancelEvent}
        disabled={calendarEvent.hasEncounter}
      />
    ) : undefined;
  };

  return (
    <FormSubmitButtons
      promptOnCancel
      hideButtonsSeparator
      onCancel={onCancel}
      onSubmit={onSubmitWithTracking}
      disableSubmitOnPristine={
        !initialValues?.patientAddedFromWL ||
        isSettingOutsideWorkHoursWithNoPermission()
      }
      submitButtonProps={{
        id: "submit-appointment-form",
        text: "Save",
        iconProps: undefined
      }}
      extraActionsBefore={extraActionsBefore}
      styles={{ root: { padding: spacing.l1 } }}
      cancelButtonProps={{ text: "Cancel" }}
    />
  );
};
