import { FC, ReactNode } from "react";

import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export const EmailColumnWrapper: FC<{
  contact: Contact;
  children: ReactNode;
}> = ({ contact, children }) => {
  const email = contact.communications.filter(
    x => x.type === CommunicationType.Email
  );
  if (email.length === 0) return null;
  if (email.length === 1) return <>{email[0].value}</>;

  return <>{children}</>;
};
