import { observer } from "mobx-react-lite";
import React from "react";

import { FontIcon, Persona, PersonaSize, Stack } from "@bps/fluent-ui";
import {
  BillingStatuses,
  InvoiceStatus
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { openNewInvoiceFromCalendar } from "@modules/booking/utils/booking.utils.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { invoiceStatusIcon } from "../BookingCalendarEvent.types.ts";

interface InvoiceAttendeeItemProps {
  attendee?: Contact;
  calendarEvent: CalendarEvent;
}

export const InvoiceAttendeeItem: React.FC<InvoiceAttendeeItemProps> = observer(
  ({ attendee, calendarEvent }) => {
    const root = useStores();
    const { routing } = root;
    const { attendeeInvoices } = useBookingCalendarScreenContext();

    const attendeeInvoice = attendeeInvoices.find(x => {
      return x?.attendeeId === attendee?.id;
    });

    const invoiceStatus = attendee?.id
      ? calendarEvent.getCalendarEventExtensionAttendee(attendee.id)
          ?.invoiceStatus
      : undefined;

    const isInvoiced =
      attendeeInvoice &&
      attendeeInvoice.billingStatus !== BillingStatuses.cancelled;
    if (!attendee) return null;

    const onClick = () => {
      // Has patient been invoiced for this calendar event
      const invoiceId = calendarEvent.getInvoiceIdByAttendee(attendee.id);

      if (invoiceId && isInvoiced) {
        routing.push(
          routes.accounts.invoices.invoice.path({
            id: invoiceId
          })
        );
      } else {
        openNewInvoiceFromCalendar(root, calendarEvent.id, attendee.id);
      }
    };
    return (
      <Stack
        horizontal
        verticalAlign="center"
        styles={{ root: { maxWidth: 160, minWidth: 160 } }}
      >
        <Persona
          text={attendee.name}
          id={attendee.id}
          size={PersonaSize.size24}
          imageInitials={attendee.initials}
          styles={{ root: { padding: 8 } }}
          onClick={onClick}
        />
        {isInvoiced && (
          <FontIcon
            iconName={invoiceStatusIcon[invoiceStatus || InvoiceStatus.Settled]}
          />
        )}
      </Stack>
    );
  }
);
