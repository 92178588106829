import { FunctionComponent } from "react";

import { Spinner } from "@bps/fluent-ui";
import { InvoiceForm } from "@modules/billing/screens/invoice/components/InvoiceForm.tsx";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { InvoiceFormHelper } from "@modules/billing/screens/invoice/context/InvoiceFormHelper.ts";
import { ServiceWarningsContext } from "@modules/billing/screens/shared-components/add-service-modal/context/ServiceWarningsContext.tsx";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

const InvoiceEditScreen: FunctionComponent = () => {
  const fetch = async (
    root: RootStore
  ): Promise<{
    helper: InvoiceFormHelper;
    initialValues: InvoiceFormValues;
  }> => {
    const helper = new InvoiceFormHelper(root);

    const initialValues = await helper.getFormInitialValues();
    const { calendarEventId, claimId } = initialValues;

    await Promise.all([
      calendarEventId
        ? root.booking.getCalendarEvent(calendarEventId)
        : undefined,
      claimId ? root.acc.getClaim(claimId) : undefined
    ]);

    helper.loadServiceWarningsHelper();

    return {
      helper,
      initialValues
    };
  };

  return (
    <DataFetcher key="invoice-form" fetch={fetch} fallback={<Spinner />}>
      {({ helper, initialValues }) => (
        <InvoiceFormContext.Provider value={helper}>
          <ServiceWarningsContext.Provider value={helper.serviceWarningsHelper}>
            <InvoiceForm initialValues={initialValues} />
          </ServiceWarningsContext.Provider>
        </InvoiceFormContext.Provider>
      )}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default InvoiceEditScreen;
