import { ScrollablePane } from "@bps/fluent-ui";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { InfiniteScrollListProps } from "@ui-components/InfiniteScrollList/InfiniteScrollList.types.ts";

export const SideNavDetailsList = <Item extends { id: string }>(
  props: InfiniteScrollListProps<Item>
) => (
  <div style={{ position: "relative", flexGrow: 1 }}>
    <ScrollablePane
      styles={{
        root: {
          height: "100%"
        }
      }}
    >
      <InfiniteScrollList
        detailsListStyles={{
          root: {
            overflow: "visible"
          }
        }}
        {...props}
      />
    </ScrollablePane>
  </div>
);
