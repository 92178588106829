import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { ConstrainMode, IColumn, SelectionMode, Stack } from "@bps/fluent-ui";
import { DrugProductNameDto } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";

export const PrescribingProductsList: FunctionComponent = observer(() => {
  const { drugs } = useStores();
  const helper = usePrescribingWizardContext();

  const onSelectOption = (drug: DrugProductNameDto) => {
    if (helper.formulationsLoading) {
      return;
    }
    runInAction(() => {
      helper.selectedProduct = drug;
      helper.formulationsLoading = true;
    });

    drugs
      .searchMedicationSummary({ productNameId: `${drug.id}` })
      .then(summaries => {
        const selectedFormulation = summaries[0];

        runInAction(() => {
          helper.selectedFormulation = selectedFormulation;
          helper.formulationsLoading = false;
          helper.formulations = summaries;
        });
      });
  };

  const columns: IColumn[] = [
    {
      key: "productName",
      name: "Product Name",
      fieldName: "name",
      minWidth: 100,
      className: "bp-prescribing-item"
    }
  ];
  return (
    <Stack
      data-is-scrollable="true"
      styles={{
        root: {
          overflowY: "auto"
        }
      }}
    >
      <ShimmeredDetailsList
        enableShimmer={helper.formulationsLoading || helper.productsLoading}
        compact
        columns={columns}
        items={helper.products || []}
        isHeaderVisible={false}
        constrainMode={ConstrainMode.unconstrained}
        selectionMode={SelectionMode.single}
        onActiveItemChanged={onSelectOption}
      />
    </Stack>
  );
});
