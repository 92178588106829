import {
  PresetReportDefinition,
  ReportSlicerValues
} from "@libs/gateways/reports/ReportsGateway.dtos.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

export class ReportSlicerSaveValidator extends Validator<ReportSlicerValues> {
  constructor(
    private options: {
      userDefinedReports: PresetReportDefinition[];
      presetReports: PresetReportDefinition[];
      reportId: string;
      rename: boolean;
    }
  ) {
    super();
    this.forField("reportName", required());
    this.forField("reportName", this.notDuplicateName());
  }

  notDuplicateName =
    (): FieldValidator =>
    (
      value: string | string | string | undefined | null,
      allValues: ReportSlicerValues
    ) => {
      if (!value) {
        return undefined;
      }

      const { presetReports, userDefinedReports, rename, reportId } =
        this.options;

      const reportsList = allValues.isPublic
        ? presetReports
        : userDefinedReports;

      const reportHasSameName = reportsList.some(
        x => x.name === value && (!rename || x.id !== reportId)
      );

      return reportHasSameName ? "Duplicate name" : undefined;
    };
}
