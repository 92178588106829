import {
  hasValidNameData,
  hasValidPhoneNumberData
} from "@ui-components/pickers/contact-picker/contact-picker.utils.ts";

export const MIN_SEARCH_CHAR_PATIENT_NAME = 3;
export const MIN_SEARCH_CHAR_PHONE_NUMBER = 5;
export const MIN_SEARCH_CHAR_DATE_OF_BIRTH = 10;

export const getMinimumCharactersToSearch = (value: string | undefined) => {
  const isValidPhoneNumber = hasValidPhoneNumberData(value);
  const isValidName = hasValidNameData(value);
  if (isValidName && !isValidPhoneNumber) {
    return MIN_SEARCH_CHAR_PATIENT_NAME;
  } else if (isValidPhoneNumber) {
    return MIN_SEARCH_CHAR_PHONE_NUMBER;
  } else {
    return MIN_SEARCH_CHAR_DATE_OF_BIRTH;
  }
};
