import { observer } from "mobx-react-lite";

import { confirm, Link, Spinner, Stack, Text } from "@bps/fluent-ui";
import { MessageCreditBalanceDto } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

export const CommsCreditsBalanceHeader: React.FC = observer(() => {
  const { comms } = useStores();
  return (
    <When permission={Permission.CommsCreditsRead}>
      <DataFetcher
        fetch={comms.getMessageCreditBalance}
        fallback={<Spinner />}
        renderError={() => "Credit balance unavailable"}
      >
        {(balance: MessageCreditBalanceDto | undefined) => (
          <Stack>
            <Text>
              Credits remaining: {balance?.units?.toString() ?? "not set"}
            </Text>
            <Link
              onClick={async () => {
                await confirm({
                  confirmButtonProps: {
                    styles: { root: { display: "none" } }
                  },
                  cancelButtonProps: {
                    text: "Close"
                  },
                  dialogContentProps: {
                    subText: "Coming soon please contact support"
                  }
                });
              }}
            >
              Update your settings
            </Link>
            {/* Add badge and or action here e.g. launch modal or redirect to self service portal */}
          </Stack>
        )}
      </DataFetcher>
    </When>
  );
});
