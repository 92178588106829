import { isEqual } from "lodash";
import { computed, observable, runInAction, toJS } from "mobx";

import { SideRailMenuItem } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  CustomClinicalToolClinicalDataDto,
  CustomClinicalToolContextClinicalDataDto,
  CustomClinicalToolContextDto,
  DiagnosisDataItemDto,
  DisplayOutcome,
  DisplayOutcomeDetail,
  EncounterClinicalDataDto,
  EpisodeOfCareDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { DiagnosisSideData } from "@shared-types/clinical/diagnosis-side-data.interface.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { GoalDataItem } from "@shared-types/clinical/goal-data-item.interface.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { SOTAPFormCommonModel } from "../context/SOTAPFormCommonModel.ts";
import { SOTAPMultiProviderClinicalDataHelper } from "../context/SOTAPMultiProviderClinicalDataHelper .ts";
import { DischargeId, DischargeLabel } from "../SOTAP.constants.ts";
import { DischargeSummary } from "../SOTAP.types.ts";
import { checkGoalsHasValues, convertGoals } from "../SOTAP.utils.ts";
import { DischargeFormValues } from "./DischargeForm.types.ts";

export class DischargeFormModel extends SOTAPFormCommonModel {
  customClinicalToolData: CustomClinicalToolClinicalDataDto[] = [];

  public episodeOfCare: EpisodeOfCareDto;
  public contextId: string;

  private businessRoleCode: string | undefined;
  private encounterClinicalData: EncounterClinicalDataDto;

  constructor(
    protected clinicalRecord: ClinicalRecord,
    data: {
      episodeOfCare: EpisodeOfCareDto;
      businessRoleCode: string | undefined;
      encounterClinicalData: EncounterClinicalDataDto;
    }
  ) {
    super(clinicalRecord);

    this.episodeOfCare = data.episodeOfCare;
    this.businessRoleCode = data.businessRoleCode;
    this.encounterClinicalData = data.encounterClinicalData;

    this.multiProviderHelper = new SOTAPMultiProviderClinicalDataHelper(
      this.clinicalRecord,
      this.businessRoleCode
    );

    this.contextId = `${this.episodeOfCare.id}::${this.businessRoleCode}`;
  }

  get acc() {
    return this.clinicalRecord.acc;
  }

  get clinical() {
    return this.clinicalRecord.clinical;
  }

  get core() {
    return this.clinicalRecord.core;
  }

  get stashedDischargeClinicalData() {
    return this.clinicalRecord.stashedClinicalData?.discharge;
  }

  get isViewOnly() {
    return this.clinicalRecord.episodeOfCare?.id === this.episodeOfCare?.id
      ? this.clinicalRecord.getDischargeStatus(this.businessRoleCode) ===
          DischargeStatus.Completed
      : true;
  }

  get dischargeSideMenu(): SideRailMenuItem[] {
    return [
      {
        text: DischargeLabel.Summary,
        id: DischargeId.Summary
      },
      {
        text: DischargeLabel.Outcome,
        id: DischargeId.Outcome
      },
      ...(this.episodeOfCare?.referralNumber
        ? [
            {
              text: DischargeLabel.ReferralFollowUp,
              id: DischargeId.ReferralFollowUp
            }
          ]
        : []),
      {
        text: DischargeLabel.Goals,
        id: DischargeId.Goals
      },
      {
        text: DischargeLabel.OutcomeMeasure,
        id: DischargeId.OutcomeMeasure
      }
    ];
  }

  public closeDischargeTab = (episodeOfCareId: string) => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.Discharge,
      `${episodeOfCareId}::${this.businessRoleCode}`
    );

    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "discharge"
    ]);
  };

  getDiagnoses = () => {
    const diagnosesClinicalData = this.encounterClinicalData?.diagnoses;

    // Have Diagnoses in clinical data?
    if (diagnosesClinicalData && diagnosesClinicalData.diagnoses) {
      // Get Diagnoses from ClinicalData
      const primaryDiagnoses = diagnosesClinicalData.diagnoses
        .filter(x => x.isPrimaryDiagnosis)
        .map(x => this.createDiagnosisSideData(x));

      const additionalDiagnoses = diagnosesClinicalData.diagnoses
        .filter(x => !x.isPrimaryDiagnosis)
        .map(x => this.createDiagnosisSideData(x));
      return { primaryDiagnoses, additionalDiagnoses };
    }

    // Have Diagnoses in EpisodeOfCare
    if (this.episodeOfCare && this.episodeOfCare.diagnoses) {
      // Get Diagnoses from EpisdoeOfCare
      const primaryDiagnoses = this.episodeOfCare.diagnoses
        .filter(x => x.isPrimaryDiagnosis)
        .map(x => this.createDiagnosisSideData(x));

      const additionalDiagnoses = this.episodeOfCare.diagnoses
        .filter(x => !x.isPrimaryDiagnosis)
        .map(x => this.createDiagnosisSideData(x));
      return { primaryDiagnoses, additionalDiagnoses };
    }

    // Reach this for, There are no diagnoses
    return {
      primaryDiagnoses: [{ originalText: "Undiagnosed" }],
      additionalDiagnoses: []
    };
  };

  getDischargeSummary = async (): Promise<DischargeSummary> => {
    const claim = await this.getClaim();
    const injuryDate = DateTime.jsDateFromISO(
      this.clinicalRecord?.episodeOfCare?.injuryDate
    );

    // Has Claim?
    if (claim) {
      const { totalVisitCount, initialConsultDate } =
        await this.getInitialConsultDateAndTotalVisitCount(claim.id);

      return {
        claim,
        totalVisitCount,
        initialConsultDate,
        injuryDate
      } as DischargeSummary;
    }

    // No claim
    return {
      claim,
      totalVisitCount: 0,
      injuryDate
    } as DischargeSummary;
  };

  @observable
  private defaultGoalDataItem: GoalDataItem[] | undefined;

  @computed
  get initialValues(): DischargeFormValues {
    const initialDischargeDataId =
      this.multiProviderHelper.initialDischargeData?.id;

    const persistedDischargeData =
      this.stashedDischargeClinicalData?.dataItems?.find(
        d => d.id === initialDischargeDataId
      );

    const dischargeData =
      persistedDischargeData ?? this.multiProviderHelper.initialDischargeData;

    let goals: GoalDataItem[] | undefined;
    if (this.core.hasPermissions(Permission.PatientTreatmentPlanAllowed)) {
      const treatmentPlan =
        this.multiProviderHelper.getInitialValueTreatmentPlanData();
      goals =
        treatmentPlan && treatmentPlan.goals
          ? convertGoals(treatmentPlan.goals)
          : undefined;
    } else {
      const goalsData = this.multiProviderHelper.getInitialValueGoalsData();

      goals =
        goalsData && goalsData.claimGoals
          ? convertGoals(goalsData.claimGoals[0].goals)
          : undefined;
    }

    if (!goals || goals.length < 1) {
      goals = this.clinicalRecord.stashedPSFSGoals;
    }

    const { answersArray, columns } = this.getCustomClinicalTools(
      this.clinicalRecord,
      this.customClinicalToolData
    );

    return {
      followUpNotes: dischargeData?.referralFollowUp?.followUpNotes,
      followUpDueDate: DateTime.jsDateFromISO(
        dischargeData?.referralFollowUp?.followUpDueDate
      ),
      goals,
      dischargeDate:
        DateTime.jsDateFromISO(dischargeData?.dischargeDate) ||
        DateTime.jsDateNow(),
      discharger: dischargeData?.discharger,
      dischargeOutcome: dischargeData?.outcomeDetail?.outcome,
      dischargeOutcomeResolved:
        dischargeData?.outcomeDetail?.outcome &&
        dischargeData?.outcomeDetail?.outcome === DisplayOutcome.Resolved
          ? dischargeData?.outcomeDetail?.detail?.[0]
          : undefined,
      dischargeOutcomePartiallyResolved:
        dischargeData?.outcomeDetail?.outcome &&
        dischargeData?.outcomeDetail?.outcome ===
          DisplayOutcome.PartiallyResolved
          ? dischargeData?.outcomeDetail?.detail ?? []
          : [],
      specialistInformationPartiallyResolved:
        dischargeData?.outcomeDetail?.outcome &&
        dischargeData?.outcomeDetail?.outcome ===
          DisplayOutcome.PartiallyResolved &&
        dischargeData?.outcomeDetail.detail?.find(
          x => x === DisplayOutcomeDetail.SpecialistReferral
        )
          ? dischargeData?.outcomeDetail?.comment
          : undefined,
      dischargeOutcomeUnresolved:
        dischargeData?.outcomeDetail?.outcome &&
        dischargeData?.outcomeDetail?.outcome === DisplayOutcome.Unresolved
          ? dischargeData?.outcomeDetail?.detail?.[0]
          : undefined,
      specialistInformationUnresolved:
        dischargeData?.outcomeDetail?.outcome &&
        dischargeData?.outcomeDetail?.outcome === DisplayOutcome.Unresolved &&
        dischargeData?.outcomeDetail.detail?.find(
          x => x === DisplayOutcomeDetail.WillBenefitFromSpecialistReferral
        )
          ? dischargeData?.outcomeDetail?.comment
          : undefined,
      customClinicalTools: answersArray,
      customToolBaseColumns: columns,
      filledClinicalDataType: this.filledClinicalDataType
    };
  }

  public getDischargeStashedClinicalData = (values: DischargeFormValues) => {
    const clinicalData: EncounterClinicalDataDto = {};

    if (values.goals) {
      const cleanedGoals = checkGoalsHasValues(values.goals);
      if (cleanedGoals.length > 0) {
        clinicalData.patientTreatmentPlan =
          this.multiProviderHelper.getTreatmentPlanNewGoalsData(cleanedGoals);
        if (!this.core.hasPermissions(Permission.PatientTreatmentPlanAllowed)) {
          clinicalData.goals =
            this.multiProviderHelper.getNewGoalsData(cleanedGoals);
        }
      }
    }

    clinicalData.discharge =
      this.multiProviderHelper.getNewDischargeData(values);

    return clinicalData;
  };

  public submitData = async (values: DischargeFormValues) => {
    if (this.isViewOnly) return;

    const clinicalData: EncounterClinicalDataDto =
      this.getDischargeStashedClinicalData(values);

    await this.updateCustomClinicalToolData(values, clinicalData);
    const submitData = toJS(clinicalData);

    await this.clinicalRecord.updateCurrentConditionDischargeData(
      submitData?.discharge?.dataItems
    );

    await this.clinicalRecord.saveClinicalData(submitData);

    await this.clinicalRecord.updateDischargeStatus(
      DischargeStatus.ReadyToFinalise,
      this.businessRoleCode
    );
  };

  async updateCustomClinicalToolData(
    values: DischargeFormValues,
    clinicalData: EncounterClinicalDataDto
  ) {
    if (
      !isEqual(
        values.customClinicalTools,
        this.initialValues.customClinicalTools
      )
    ) {
      const customData =
        this.clinicalRecord.stashedClinicalData?.customClinicalTool;

      const contextData =
        this.clinicalRecord.stashedClinicalData?.customClinicalToolContext;

      const existingContexts = contextData?.contexts ?? [];
      const addedContexts: CustomClinicalToolContextDto[] = [];

      // save context first
      if (values.customClinicalTools) {
        values.customClinicalTools.forEach(customTool => {
          if (
            existingContexts.findIndex(
              c => c.contextId === customTool.contextId
            ) === -1
          ) {
            addedContexts.push({
              contextId: customTool.contextId,
              name: customTool.name!
            });
          }

          if (addedContexts.length > 0) {
            const newCustomContextData: CustomClinicalToolContextClinicalDataDto =
              {
                ...contextData,
                contexts: [...addedContexts, ...existingContexts]
              };

            runInAction(() => {
              clinicalData.customClinicalToolContext = newCustomContextData;
            });
          }
        });

        // save encounter-scoped data next

        const cleanedCustomTools = values.customClinicalTools.filter(
          x => x.thisEncounterResult !== undefined
        );

        const newCustomData: CustomClinicalToolClinicalDataDto = {
          ...customData,
          tools: cleanedCustomTools.map(c => {
            return {
              contextId: c.contextId,
              result: String(c.thisEncounterResult)
            };
          })
        };

        runInAction(() => {
          clinicalData.customClinicalTool = newCustomData;
        });
      }
    }
  }

  private createDiagnosisSideData(
    item: DiagnosisDataItemDto
  ): DiagnosisSideData {
    return {
      originalText: item.diagnosisCode?.originalText,
      side: item.diagnosisSide
    };
  }

  isLinkedCondition = (episodeOfCareId: string | undefined) => {
    return (
      this.clinicalRecord.openEncounter?.episodeOfCareId === episodeOfCareId ||
      this.clinicalRecord?.episodeOfCare?.id === episodeOfCareId
    );
  };

  private async getClaim() {
    const linkedCondition = this.clinicalRecord.conditions.find(x =>
      this.isLinkedCondition(x.episodeOfCareId)
    );

    //Get a claim through linked condition
    if (linkedCondition) {
      return linkedCondition.claim;
    }

    // Get a claim by claim Id
    if (this.clinicalRecord.calendarEvent?.claimId)
      return await this.clinicalRecord.calendarEvent.loadClaim();

    // No claim
    return undefined;
  }

  private async getInitialConsultDateAndTotalVisitCount(claimId: string) {
    const claimEpisodesOfCare = await this.acc.getClaimEpisodesOfCare({
      claimId
    });

    const episodeOfCareId = claimEpisodesOfCare[0].episodeOfCareId;

    if (
      !claimEpisodesOfCare ||
      claimEpisodesOfCare.length === 0 ||
      !this.clinicalRecord.openEncounter
    )
      return { totalVisitCount: 0, initialConsultDate: undefined };

    const options = {
      episodeOfCareIds: [episodeOfCareId],
      patientId: this.clinicalRecord.id,
      openEncounter: this.clinicalRecord.openEncounter
    };

    const initialConsultDateAndVisitsCounts =
      await this.clinical.getInitialConsultDateAndVisitsCounts(options);

    return initialConsultDateAndVisitsCounts[0];
  }

  getOrderedDischargeRoles = async () => {
    const encounters = this.clinicalRecord.linkedEocEncounters;
    const orderedList: string[] = [];

    if (encounters) {
      // Map the encounters down to their business role and their encounter start time.
      const roleDateMapping = encounters.map(encounter => {
        return {
          role: encounter.businessRole,
          date: encounter.startDateTime
        };
      });

      // Sort by date asc
      const sorted = Array.from(roleDateMapping).sort(
        (x, y) => x.date.toMillis() - y.date.toMillis()
      );

      sorted.forEach(x => {
        if (!orderedList.find(y => y === x.role) && x.role) {
          orderedList.push(x.role);
        }
      });
    }

    return orderedList;
  };

  getCompletedDischarges = async () => {
    const clinicalData = await this.clinical.getEpisodeOfCareScopedClinicalData(
      {
        patientId: this.episodeOfCare.patientId,
        episodeOfCareId: this.episodeOfCare.id
      }
    );

    if (clinicalData?.discharge?.dataItems) {
      return clinicalData.discharge.dataItems.filter(
        x => x.dischargeStatus === DischargeStatus.Completed
      );
    }

    return undefined;
  };

  getDischargeRanking = async () => {
    const completedDischarges = await this.getCompletedDischarges();

    const orderedList = await this.getOrderedDischargeRoles();

    if (orderedList && orderedList.length > 1) {
      if (completedDischarges && completedDischarges.length > 0) {
        if (completedDischarges.length + 1 < orderedList.length) {
          return `${completedDischarges.length + 1} of ${orderedList.length}`;
        }
      } else {
        return `1 of ${orderedList.length}`; // No discharges have been completed yet, so this is the first.
      }
    }
    return "Final discharge";
  };

  get isUserProviderEquivalent() {
    return this.core.primaryBusinessRole?.code === this.businessRoleCode;
  }
}
