import React, { FC, useContext } from "react";

import { isClaimAdjustmentAllowed } from "@modules/acc/screens/claim-adjustment/components/utils.ts";
import { OnHoldMessageBar } from "@modules/acc/screens/claim/components/OnHoldMessageBar.tsx";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";

import { ClaimAdjustmentFormSection } from "./ClaimAdjustmentFormSection.tsx";
import { OverviewFormSection } from "./OverviewFormSection.tsx";
import { PurchaseOrdersFormSection } from "./purchase-order-section/PurchaseOrdersFormSection.tsx";
import { ReferralInformationFormSection } from "./ReferralInformationFormSection.tsx";
import { getMenuItems } from "./utils.tsx";
import { VisitsFormSection } from "./VisitsFormSection.tsx";

type ClaimManagementFormLayoutProps = {
  header: React.ReactNode;
};

export const ClaimManagementFormLayout: FC<ClaimManagementFormLayoutProps> = ({
  header
}) => {
  const { claimPurchaseOrders, claim } = useContext(ClaimContext);

  return (
    <CardFormLayout
      header={header}
      sideRailMenuItems={getMenuItems(claimPurchaseOrders?.length ?? 0, claim)}
      messageBar={claim.isOnHold ? <OnHoldMessageBar /> : undefined}
    >
      <OverviewFormSection errorMessageList={claim.errorMessageList} />
      <VisitsFormSection />
      {claim.referralIn && (
        <ReferralInformationFormSection isPrivate={claim.private!} />
      )}
      {isClaimAdjustmentAllowed(claim.claimStatus!) && (
        <ClaimAdjustmentFormSection />
      )}
      <PurchaseOrdersFormSection />
    </CardFormLayout>
  );
};
