import { DateTime } from "@bps/utils";
import { ClinicalTaskDescription } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  maxLength,
  predicate,
  required,
  todayOrLater
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClinicalTaskFormValues } from "@shared-types/clinical/clinical-task-values.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";

import { ClinicalTaskDue } from "./types/clinical-task-due.enum.ts";
import { ClinicalTaskType } from "./types/clinical-task.type.ts";

const nameOf = nameOfFactory<ClinicalTaskFormValues>();

export class ClinicalTaskValidator extends Validator<ClinicalTaskFormValues> {
  constructor(
    clinicalTask?: ClinicalTask,
    root?: IRootStore,
    ptClinicalTasks?: ClinicalTask[]
  ) {
    super();

    this.forField(nameOf("taskType"), [
      required(),
      (val, values) => {
        if (
          this.duplicateCheck(root?.acc, {
            tasks: ptClinicalTasks,
            values,
            id: clinicalTask?.id
          })
        ) {
          return ValidationMessages.noDuplicateClinicalTasksAllowed;
        } else return undefined;
      }
    ]);
    this.forField(nameOf("dueChoice"), [required()]);

    this.forField(nameOf("claimNumber"), [
      (val, values) => {
        if (
          this.duplicateCheck(root?.acc, {
            tasks: ptClinicalTasks,
            values,
            id: clinicalTask?.id
          })
        ) {
          return ValidationMessages.noDuplicateClinicalTasksAllowed;
        } else return undefined;
      }
    ]);
    this.forField(
      nameOf("dueDate"),
      predicate(
        (val, values) => values?.dueChoice === ClinicalTaskDue.Date,
        required()
      )
    );
    this.forField(nameOf("comment"), [required(), maxLength(250)], {
      when: (_value, parent) => parent.taskType === ClinicalTaskType.Other
    });

    const initialDate = DateTime.jsDateFromISO(clinicalTask?.dueDate);

    this.forField(nameOf("dueDate"), [
      predicate(
        (val, values) => values?.dueDate?.getDate() !== initialDate?.getDate(),
        todayOrLater
      ),
      (val, values) => {
        if (
          this.duplicateCheck(root?.acc, {
            tasks: ptClinicalTasks,
            values,
            id: clinicalTask?.id
          })
        ) {
          return ValidationMessages.noDuplicateClinicalTasksAllowed;
        } else return undefined;
      }
    ]);

    this.forField(nameOf("dueInVisits"), [
      predicate(
        (val, values) => values?.dueChoice === ClinicalTaskDue.Consult,
        required(),
        greaterThan(0)
      ),
      (val, values) => {
        if (
          this.duplicateCheck(root?.acc, {
            tasks: ptClinicalTasks,
            values,
            id: clinicalTask?.id
          })
        ) {
          return ValidationMessages.noDuplicateClinicalTasksAllowed;
        } else return undefined;
      },

      (value: number | undefined) => {
        if (!value || (value ?? 0) === 0) return undefined;

        if (value <= 0) {
          // Can't use greaterThan(0) to allow ZERO value.

          return messageWithData(ValidationMessages.greaterThan, 0);
        }

        if (value >= 100) {
          // Can't use lessThan(100) to allow ZERO value.

          return messageWithData(ValidationMessages.lessThan, 100);
        }

        return undefined;
      }
    ]);

    this.forField(nameOf("priority"), [required()]);
    this.forField(nameOf("comment"), [maxLength(250)]);
    this.forField(nameOf("isLocked"), [
      (val, values) => {
        if (
          val &&
          values?.isCompleted &&
          !root?.core?.hasPermissions(Permission.ClinTaskUnlock)
        ) {
          return ValidationMessages.notAbleToCompleteLockedTasks;
        }

        return undefined;
      }
    ]);

    this.forField(nameOf("claimNumber"), [
      (val, values) => {
        const taskType = values?.taskType;
        if (
          !val &&
          (taskType === ClinicalTaskDescription.ACC32 ||
            taskType === ClinicalTaskDescription.RecordOutcomeMeasure ||
            taskType === ClinicalTaskDescription.ClaimReview)
        ) {
          return ValidationMessages.required;
        }

        return undefined;
      }
    ]);
  }

  duplicateCheck(
    acc?: AccStore,
    options?: {
      tasks?: ClinicalTask[];
      values?: ClinicalTaskFormValues;
      id?: string;
    }
  ) {
    const { tasks, values, id } = options!;
    if (tasks && (values?.dueDate || values?.dueInVisits)) {
      const match = tasks.find(
        t =>
          t.id !== id &&
          !t.isCompleted &&
          !t.isDeleted &&
          values.taskType === t.taskType &&
          ((values.taskType !== ClinicalTaskDescription.ACC32 &&
            values.taskType !== ClinicalTaskDescription.RecordOutcomeMeasure) ||
            (values?.claimNumber && t.claimNumber === values?.claimNumber)) &&
          ((values.dueInVisits && values.dueInVisits === t.remainingVisits) ||
            (values?.dueDate &&
              t?.dueDate &&
              values.dueDate.toDateString() ===
                DateTime.jsDateFromISO(t.dueDate).toDateString()))
      );
      return match !== undefined;
    }

    return false;
  }
}
