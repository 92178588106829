import { flatten } from "@bps/fluent-ui";
import { DateTime, groupBy, newGuid, to2dp } from "@bps/utils";
import {
  FeeType,
  PaymentMethod,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { compareNumberStrings, sum } from "@libs/utils/utils.ts";
import { getAllocation } from "@modules/billing/billing.utils.ts";
import { AllocationActions } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { Allocation } from "@stores/billing/models/Allocation.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { getInvoiceItemOwing } from "@stores/billing/utils/billing.utils.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { AllocationFieldValues } from "../shared-components/allocation-field/types.ts";
import { AllocationOptions } from "../shared-components/allocation-form/AllocationOptions.ts";
import { AllocationFormValues } from "../shared-components/allocation-form/components/AllocationForm.types.ts";

export const getAllocations = (allocation: Allocation) => {
  const allocationItemsGroupedByInvoiceId = groupBy(
    allocation.dto.items,
    x => x.invoiceItemId
  ).map(invoiceItemGroup => {
    const items = invoiceItemGroup[1];
    const itemAmountTotal = sum("amount", items);
    return { ...items[0], amount: itemAmountTotal };
  });

  return allocationItemsGroupedByInvoiceId.map(allocationItem => ({
    ...getAllocation(allocationItem),
    comment: allocationItem.invoiceItem.accScheduleItem?.paymentReason,
    creditNumber: allocationItem.credit.number,
    creditType: allocationItem.credit.itemType
  }));
};

export const getAllocationsFromInvoice = (options: {
  invoice: Invoice;
  checked: boolean;
  filterOwing?: boolean;
}): AllocationFieldValues[] => {
  const { invoice, checked, filterOwing = true } = options;
  return invoice.items
    .filter(item => !filterOwing || getInvoiceItemOwing(item) > 0)
    .map(item => {
      const owing = getInvoiceItemOwing(item);
      return {
        checked,
        invoiceDate: invoice.transactionDate,
        code: item.code || "",
        invoiceNumber: invoice.number,
        invoiceItemId: item.id,
        invoiceId: invoice.id,
        description: item.description!,
        name: item.name,
        quantity: item.quantity,
        fee: item.fee,
        gst: item.gst,
        feeType: item.feeType!,
        total: checked ? getInvoiceItemOwing(item) : 0,
        itemTotal: item.amount,
        owing: owing ?? 0,
        paymentStatus: item.paymentStatus,
        locationId: item.locationId
      };
    });
};

export const getAllocationFormValues = (
  invoice?: Invoice,
  outstandingInvoices?: Invoice[],
  checked?: boolean
) => {
  const invoiceAllocations = invoice
    ? getAllocationsFromInvoice({
        invoice,
        checked: checked !== undefined ? checked : true
      })
    : [];

  const outstandingAllocations = outstandingInvoices
    ? flatten(
        // !invoice means check items if created from "New payment"
        outstandingInvoices.map(i =>
          getAllocationsFromInvoice({
            invoice: i,
            checked: checked !== undefined ? checked : !invoice
          })
        )
      ).filter(o => {
        if (!invoice) return true;
        return invoiceAllocations.some(
          a => a.invoiceNumber !== o.invoiceNumber
        );
      })
    : [];
  return { invoiceAllocations, outstandingAllocations };
};

export const getAllocationFormValuesFromInvoice = (
  options: {
    number: string;
    invoice?: Invoice;
    accountId?: string;
    outstandingInvoices?: Invoice[];
    invoiceIds?: string[];
    paymentMethod?: PaymentMethod;
    amountOverride?: number | undefined;
  },
  core: CoreStore
): AllocationFormValues => {
  const {
    invoice,
    outstandingInvoices,
    accountId,
    invoiceIds,
    paymentMethod = PaymentMethod.Eftpos
  } = options;

  const { invoiceAllocations, outstandingAllocations } =
    getAllocationFormValues(invoice, outstandingInvoices);

  let accountContactId: string | undefined;
  if (invoice) {
    accountContactId = invoice.accountId;
  } else if (accountId) {
    accountContactId = accountId;
  } else {
    accountContactId = "";
  }

  let amount: number = 0;
  if (invoice) {
    amount = invoice.owing ?? 0;
  } else if (outstandingAllocations.length > 0) {
    amount = to2dp(
      outstandingAllocations.reduce((total, allocation) => {
        if (!invoiceIds || invoiceIds.some(id => id === allocation.invoiceId)) {
          return total + Number(allocation.owing);
        }
        allocation.checked = false;
        allocation.total = 0;
        return total;
      }, 0)
    );
  }

  let locationId = invoice?.items.length ? invoice.items[0].locationId : "";
  if (!locationId && !core.hasMultipleActiveLocations) {
    locationId = core.locationId;
  }

  return {
    locationId,
    accountContactId,
    paymentDate: DateTime.today().toJSDate(),
    comment: undefined,
    payments: [
      {
        id: newGuid(),
        amount: options.amountOverride ?? amount,
        method: paymentMethod
      }
    ],
    allocations: [...invoiceAllocations, ...outstandingAllocations]
  };
};

export const getFilteredAllocations = (options: {
  allocations: AllocationFieldValues[];
  allocationAction: string;
  invoiceNumber?: string;
}): AllocationFieldValues[] => {
  const { allocations, invoiceNumber, allocationAction } = options;
  return allocations
    .filter(
      a =>
        !(
          a.invoiceNumber !== invoiceNumber &&
          (allocationAction === AllocationActions.payInvoice ||
            allocationAction === AllocationOptions.paySetItems)
        )
    )
    .sort((a: AllocationFieldValues, b: AllocationFieldValues) => {
      if (a.checked !== b.checked) {
        return Number(b.checked) - Number(a.checked);
      }

      return compareNumberStrings(a.invoiceNumber, b.invoiceNumber);
    });
};

export const convertQuantity = (quantity: number, feeType: FeeType): string => {
  if (feeType === FeeType.Hourly) {
    return `${to2dp(quantity / 60)} hr`;
  }
  return `${to2dp(quantity)}${feeType === FeeType.Km ? " km" : ""}`;
};

/**
 * Function returns totals calculations (value or currency string) depending on
 * an iterator
 */
export const getCalculation = <K extends { [key: string]: any }>(options: {
  items: K[];
  iterator: (item: { [key: string]: any }) => number;
}): number => {
  const { items, iterator } = options;

  return items.reduce((acc: number, item: K) => {
    const total = acc + iterator(item);
    if (isNaN(total) || total < 0) {
      return 0;
    }
    return total;
  }, 0);
};

const paymentStatusImportance = (status: string | undefined): number => {
  switch (status) {
    case PaymentStatuses.paid:
      return 1;
    case PaymentStatuses.part:
      return 2;
    case PaymentStatuses.unpaid:
      return 3;
    default:
      //to make sure that it is never the case (like we use z-index: 100)
      return 100;
  }
};

export const sortAllocationByInvoiceNumberAndStatus = (
  a: { invoiceNumber: string; status?: string },
  b: { invoiceNumber: string; status?: string }
) => {
  if (a.invoiceNumber < b.invoiceNumber) return 1;
  if (a.invoiceNumber === b.invoiceNumber)
    return (
      paymentStatusImportance(a.status) - paymentStatusImportance(b.status)
    );
  return -1;
};
