import { useContext } from "react";

import { dataAttribute, DataAttributes, Link, Stack } from "@bps/fluent-ui";
import { AccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { Statement } from "@stores/billing/models/Statement.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const TransactionActionCell: React.FC<{
  statement: Statement;
}> = ({ statement }) => {
  const { billing } = useStores();
  const { accountContact } = useContext(AccountScreenContext);

  const viewStatementLink = (
    <Link
      onClick={() => {
        billing.openStatementPdf(statement.id);
        billing.ui.setStatementDocumentPreviewData({
          contactId: accountContact.id,
          statementId: statement.id,
          statement
        });
      }}
      {...dataAttribute(DataAttributes.Element, "statement-link")}
    >
      View
    </Link>
  );

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
      {viewStatementLink}
    </Stack>
  );
};
