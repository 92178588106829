import {
  isBeforeTimeField,
  isDateBeforeField,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  UserWorkingHoursOverridesFormValues,
  UserWorkingHoursOverridesValues
} from "../UserWorkingHoursOverridesScreen.types.ts";

export class UserWorkingHoursOverridesFormValidator extends Validator<UserWorkingHoursOverridesValues> {
  constructor() {
    super();
    const userWorkingHoursFormValidator =
      new UserWorkingHoursOverridesValidator();
    this.forArrayField("overrides", userWorkingHoursFormValidator.validate);
  }
}

export class UserWorkingHoursOverridesValidator extends Validator<UserWorkingHoursOverridesFormValues> {
  constructor() {
    super();
    const isAtWork = (
      val: any,
      exception: UserWorkingHoursOverridesFormValues
    ) => exception.atWork === "1";

    this.forField("startDate", [required()]);
    this.forField("endDate", [
      required(),
      predicate(
        (val: Date, exception: UserWorkingHoursOverridesFormValues) =>
          !!val && !!exception.startDate,
        isDateBeforeField(
          ValidationMessages.endDateBeforeStartDate,
          "startDate"
        )
      )
    ]);
    this.forField("atWork", [required()]);
    this.forField("startTime", [predicate(isAtWork, required())]);
    this.forField("endTime", [
      predicate(isAtWork, required()),
      predicate(
        isAtWork,
        isBeforeTimeField(
          ValidationMessages.endTimeLaterStartTime,
          "startTime",
          true
        )
      )
    ]);
    this.forField("reason", maxLength(50));
  }
}
