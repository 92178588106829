import { observer } from "mobx-react-lite";
import { FC } from "react";

import { SideRailMenuItem, Stack } from "@bps/fluent-ui";
import { SOTAPSectionText } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { useSOTAPFollowOnFormContext } from "../context/SOTAPFollowOnFormContext.tsx";
import { InjuryAreasCard } from "../injury-area/InjuryAreasCard.tsx";
import { OtherMovementsCard } from "../OtherMovementsCard.tsx";
import { PostureOrObservationsCard } from "../PostureOrObservationsCard.tsx";
import { SotapMskId, SotapMskLabel } from "../SOTAP.constants.ts";
import { SOTAPFormWrapper } from "../SOTAPFormWrapper.tsx";

export const SOTAPFollowOnObjectiveContent: FC = observer(() => {
  const { currentSection } = useSOTAPFollowOnFormContext();
  const objectiveMenuItems: SideRailMenuItem[] = [
    {
      text: SotapMskLabel.PostureOrObservations,
      id: SotapMskId.PostureOrObservations
    },
    {
      text: SotapMskLabel.InjuryAreas,
      id: SotapMskId.InjuryAreas
    },
    {
      text: SotapMskLabel.OtherMovements,
      id: SotapMskId.OtherMovements
    }
  ];
  return (
    <Stack
      styles={{
        root: {
          height: "100%",
          display:
            currentSection !== SOTAPSectionText.Objective ? "none" : "flex"
        }
      }}
    >
      <SOTAPFormWrapper sideRailMenuItems={objectiveMenuItems}>
        <PostureOrObservationsCard />
        <InjuryAreasCard />
        <OtherMovementsCard />
      </SOTAPFormWrapper>
    </Stack>
  );
});
