import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ContactDetails } from "./individual/view/ContactDetails.tsx";
import { OrganisationDetails } from "./organisation/view/OrganisationDetails.tsx";
import { PatientDetails } from "./patient/view/PatientDetails.tsx";

const ContactScreenBase: FunctionComponent = observer(() => {
  const { routing } = useStores();

  const contactId = routes.contacts.contact.match(routing.location.pathname)
    ?.params.id;

  if (!contactId) {
    return null;
  }

  const fetch = async (root: RootStore) => {
    const contact = await root.practice.getContact(contactId, {
      includeRelationships: true,
      includeContactPreferences: true
    });

    return { contact, contactPreferences: contact.contactPreferences };
  };

  return (
    <DataFetcher<{
      contact: Contact;
      contactPreferences: ContactPreferences | undefined;
    }>
      fetch={fetch}
      refetchId={contactId}
    >
      {({ contact, contactPreferences }) => {
        switch (contact?.type) {
          case ContactType.Patient:
            return (
              <PatientDetails
                patient={contact}
                contactPreferences={contactPreferences}
              />
            );
          case ContactType.Organisation:
            return <OrganisationDetails organisation={contact} />;
          default:
            return (
              <ContactDetails
                contact={contact}
                contactPreferences={contactPreferences}
              />
            );
        }
      }}
    </DataFetcher>
  );
});

const ContactScreen = withFetch(
  x => [
    x.core.ref.countries.load(),
    x.core.ref.australianStates.load(),
    x.booking.ref.appointmentStatuses.load()
  ],
  ContactScreenBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ContactScreen;
