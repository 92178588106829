import {
  IStyleFunctionOrObject,
  Persona,
  PersonaSize,
  Spinner,
  StateBlock,
  StateBlockStyleProps,
  StateBlockStyles
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { useDischargeContext } from "./DischargeContext.tsx";

export const DischargeSummaryProvider: React.FunctionComponent = () => {
  const { core } = useStores();

  const { getDischargeRanking } = useDischargeContext();
  const { clinicalRecord } = usePatientRecordScreenContext();

  const getPersonaBadge = (person: Contact | User) => {
    const isContact = (contactOrUser: any): contactOrUser is Contact => {
      return !!(contactOrUser as Contact).type;
    };

    const profilePictureUrl = isContact(person)
      ? person.profilePictureUrl
      : undefined;

    const businessRoleCatalogText = core.catalogBusinessRoles.find(
      x => x.code === clinicalRecord.openEncounter?.businessRole
    )?.text;
    return (
      <Persona
        text={person.name}
        id={person.id}
        imageUrl={profilePictureUrl}
        size={PersonaSize.size48}
        imageInitials={person.initials}
        styles={{
          details: {
            paddingRight: 0
          },
          root: {
            width: "100%"
          }
        }}
        onRenderPrimaryText={() => {
          let text = "";
          if (person) {
            text = isContact(person) ? person.nameWithTitle : person.fullName;
          }

          return <span>{text}</span>;
        }}
        onRenderSecondaryText={() => {
          return <>{businessRoleCatalogText}</>;
        }}
      />
    );
  };

  const stateBlockStyles: IStyleFunctionOrObject<
    StateBlockStyleProps,
    StateBlockStyles
  > = {
    root: {
      padding: "8px 16px"
    }
  };

  return (
    <>
      {core.user && (
        <StateBlock
          styles={stateBlockStyles}
          labelText=""
          descText={
            <UserFetcher
              refetchId={core.user.id}
              userId={core.user.id}
              fallback={<Spinner />}
            >
              {(user: User) => getPersonaBadge(user)}
            </UserFetcher>
          }
        />
      )}

      <DataFetcher fallback={<Spinner />} fetch={getDischargeRanking}>
        {ranking => {
          return (
            <StateBlock
              styles={stateBlockStyles}
              labelText="Discharge"
              descText={ranking}
            />
          );
        }}
      </DataFetcher>
    </>
  );
};
