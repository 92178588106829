import React from "react";

import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";

import { usePatientMatchContext } from "../context/PatientMatchContext.ts";
import { PatientMatchFilter } from "./PatientMatchListItem.types.ts";

type PatientMatchFilterBarProps = Pick<
  FilterBarProps<PatientMatchFilter>,
  "children"
>;

const PatientMatchFilterBarBase: React.FC<
  PatientMatchFilterBarProps
> = props => {
  const { practice } = useStores();
  const { filterCriteria } = usePatientMatchContext();

  const items: Item<PatientMatchFilter>[] = [
    {
      type: "searchBox",
      name: nameof<PatientMatchFilter>("search"),
      stickItem: true,
      props: {
        removeSpecialCharacters: false,
        autoFocus: true,
        placeholder: "Search name"
      }
    },
    {
      type: "optionsSelect",
      name: nameof<PatientMatchFilter>("statuses"),
      props: {
        id: "contacts-filter-statuses",
        multiSelect: true,
        placeholder: "Show all statuses",
        options: practice.ref.contactStatuses.keyTextValues.filter(
          val =>
            val.key === ContactStatus.Active ||
            val.key === ContactStatus.Inactive
        ),
        hideSearchOption: true,
        calloutWidth: 170,
        listHeight: "initial"
      }
    }
  ];

  return (
    <FilterBar<PatientMatchFilter>
      items={items}
      children={props.children}
      initialValues={filterCriteria}
    />
  );
};

export const PatientMatchFilterBar = withFetch(
  x => [x.practice.ref.contactStatuses.load()],
  PatientMatchFilterBarBase
);
