import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Dialog,
  Heading,
  ScrollablePane,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useDialogOpenedAnalytics } from "@libs/analytics/hooks/useDialogOpenedAnalytics.ts";
import { ServiceSearchDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { sortSchedules } from "@modules/settings/screens/shared-components/fees.utils.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";
import { getInvoiceItem } from "@stores/billing/utils/billing.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { PromiseObservableButton } from "@ui-components/PromiseObservableButton/PromiseObservableButton.tsx";

import { AddServicesFilter } from "./AddServicesFilter.tsx";
import { getOptionalTextStyle } from "./AddServicesModal.styles.tsx";
import {
  AddServicesModalProps,
  SearchFilter,
  ServiceableItem
} from "./AddServicesModal.types.ts";
import { PreviouslyUsedServices } from "./PreviouslyUsedServices.tsx";
import { SearchedServicesList } from "./SearchedServicesList.tsx";
import { SelectedServicesList } from "./SelectedServicesList.tsx";
import { ServiceableItemSummary } from "./ServiceableItemSummary.tsx";
import { getIsPrimaryButtonDisabled, getIsValid } from "./utils.ts";

const AddServicesModalBase: React.FC<
  AddServicesModalProps & {
    schedules: Schedule[];
  }
> = observer(props => {
  const { billing, core } = useStores();
  const modalTitle =
    props.kind === ServiceableItem.Invoice
      ? "Add an item to invoice"
      : "Add items to this appointment";
  useDialogOpenedAnalytics(modalTitle, core.user?.fullName, props.hidden);

  const {
    closeDialog,
    hidden,
    selectedServices,
    onServicesSelected,
    schedules,
    kind,
    invoice,
    extraButton
  } = props;

  const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    searchText: "",
    scheduleIds: []
  });

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<ServiceSearchDto[] | null>(
    null
  );

  const [addedServices, setAddedServices] = useState<InvoiceItemFormValue[]>(
    selectedServices.filter(service => service.serviceId)
  );

  useEffect(() => {
    if (!hidden) {
      setAddedServices(selectedServices.filter(service => service.serviceId));
    }
  }, [selectedServices, hidden]);

  useEffect(() => {
    if (!hidden) {
      setSearchResults(null);
    }
  }, [hidden, modalTitle, core.user?.fullName]);

  const validAddedServices = addedServices.filter(service =>
    getIsValid(service, invoice.claimId)
  );

  const originalServices =
    kind === ServiceableItem.Invoice ? selectedServices : [];

  const { patientId, userId, invoiceItems } = invoice;

  // NOTE at time of writting the PBI was focused solely on creating a new service
  // order for now. Thus the current time is used, as opposed to the date of any
  // existing draft item for now
  const date = invoice.invoiceDate || DateTime.today().toJSDate();

  const showPreviouslyUsedItemsIfPatient = () => {
    if (patientId === undefined) {
      return null;
    }

    return (
      <PreviouslyUsedServices
        invoiceDate={date}
        patientId={patientId}
        onItemsChecked={toggleService}
        checkedItemsIds={addedServices.map(service => service.serviceId)}
        serviceableItem={kind}
      />
    );
  };

  const updateService = (item: InvoiceItemFormValue) => {
    const services = addedServices.map(service => {
      if (service.serviceId === item.serviceId) {
        return item;
      }

      return service;
    });

    setAddedServices(services);
  };

  const removeServiceById = (idToRemove: string): void => {
    setAddedServices(
      addedServices.filter(service => service.serviceId !== idToRemove)
    );
  };

  const toggleService = (serviceToToggle: ServiceSearchDto) => {
    const existingService = addedServices.find(
      service => service.serviceId === serviceToToggle.serviceId
    );

    if (existingService) {
      removeServiceById(serviceToToggle.serviceId);
    } else {
      const possiblePreExistingService = props.selectedServices.find(
        service => {
          return service.serviceId === serviceToToggle.serviceId;
        }
      );

      // If toggling an item that existed at the start of the modal interaction
      // it should be restored as opposed to added
      if (possiblePreExistingService) {
        setAddedServices(addedServices.concat(possiblePreExistingService));
      } else {
        const service = getInvoiceItem({
          service: serviceToToggle,
          serviceDate: DateTime.jsDateNow(),
          gstPercent: billing.gstPercent
        });

        setAddedServices(addedServices.concat(service));
      }
    }
  };

  const showSearchResultsIfAny = () => {
    if (isSearching || !searchResults || searchResults.length === 0) {
      return null;
    } else {
      return (
        <ScrollablePane>
          <SearchedServicesList
            onItemsChecked={toggleService}
            filter={searchFilter.searchText?.toLowerCase() || ""}
            services={searchResults}
            checkedItemsIds={addedServices.map(service => service.serviceId)}
          />
        </ScrollablePane>
      );
    }
  };

  const addItemsToServiceableItem = async (): Promise<void> => {
    return onServicesSelected(validAddedServices);
  };

  const handleSearch = async (filters: SearchFilter): Promise<void> => {
    setSearchFilter(filters);
    setSearchResults(null);

    if (!filters.searchText) {
      return;
    }

    setIsSearching(true);

    const services = await billing.getServiceSearch({
      ...filters,
      effectiveDate: DateTime.fromJSDate(date).toISODate()
    });

    const formattedAndSortedResults = services.sort(
      (a, b) => Number(a.code) - Number(b.code)
    );

    setSearchResults(formattedAndSortedResults);
    setIsSearching(false);
  };

  const getScheduleName = (schedules: Schedule[]): string => {
    if (searchFilter.scheduleIds?.length) {
      return searchFilter.scheduleIds
        .map(id => schedules.find(s => s.id === id)?.name || "")
        .join(", ");
    }

    return "All schedules";
  };

  const showSpinnerWhenSearchLoading = (schedules: Schedule[]) => {
    if (!isSearching) {
      return null;
    }

    const scheduleName = getScheduleName(schedules);
    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
        <Spinner />

        <Text styles={getOptionalTextStyle}>
          Searching for "{searchFilter.searchText}" in {scheduleName}
        </Text>
      </Stack>
    );
  };

  const showMessageIfSearchEmpty = (schedules: Schedule[]) => {
    if (
      !searchFilter.searchText ||
      isSearching ||
      !searchResults ||
      searchResults.length > 0
    ) {
      return null;
    }

    return (
      <ScrollablePane>
        <Stack
          verticalAlign="start"
          horizontalAlign="center"
          grow
          styles={{ root: { marginTop: 32 } }}
          tokens={{ childrenGap: 48 }}
        >
          <Text styles={getOptionalTextStyle}>
            0 matches found for "{searchFilter.searchText}"
          </Text>

          <Text>
            Unable to find "{searchFilter.searchText}" in item codes and
            descriptions in the following schedule(s){" "}
            {getScheduleName(schedules)}
          </Text>
        </Stack>
      </ScrollablePane>
    );
  };

  const serviceableItemSummary = () => {
    if (kind === ServiceableItem.Invoice) {
      return (
        <ServiceableItemSummary
          summaryProps={{
            patientId,
            userId,
            accountContactId: patientId,
            billType: invoice.billType,
            invoiceNumber: invoice.invoiceNumber || "",
            invoiceDate: date,
            kind: ServiceableItem.Invoice
          }}
        />
      );
    }

    return (
      <ServiceableItemSummary
        summaryProps={{
          patientId,
          userId,
          kind: ServiceableItem.DraftItem,
          locationId: invoice.locationId
        }}
      />
    );
  };

  const addItemsButtonText =
    kind === ServiceableItem.Invoice
      ? "Add items to invoice"
      : `Add items to appointment (${validAddedServices.length})`;

  return (
    <Dialog
      hidden={hidden}
      onDismiss={closeDialog}
      minWidth="90vw"
      dialogContentProps={{
        title: <Heading variant="modal-heading">{modalTitle}</Heading>,
        showCloseButton: true,
        styles: {
          inner: { flexGrow: 1 },
          innerContent: { height: "100%" },
          content: {
            display: "flex",
            flexDirection: "column",
            height: "90vh"
          }
        }
      }}
    >
      <Stack
        styles={{ root: { height: "inherit" } }}
        tokens={{ childrenGap: 40 }}
      >
        <Stack grow horizontal tokens={{ childrenGap: 16 }}>
          <Stack.Item grow styles={{ root: { width: "50%" } }}>
            <Stack
              tokens={{ childrenGap: 10 }}
              styles={{ root: { height: "100%" } }}
              grow
            >
              {serviceableItemSummary()}
              <Stack tokens={{ childrenGap: 16 }} grow>
                <AddServicesFilter
                  schedules={schedules}
                  onSearch={handleSearch}
                />
                <Stack
                  grow
                  verticalAlign="center"
                  horizontalAlign="center"
                  styles={{ root: { position: "relative" } }}
                  {...dataAttribute(
                    DataAttributes.Element,
                    "add-services-modal-search-results"
                  )}
                  {...dataAttribute(DataAttributes.Loading, isSearching)}
                  {...dataAttribute(
                    DataAttributes.Data,
                    `has-items-${searchResults && searchResults.length > 0}`
                  )}
                >
                  {showSpinnerWhenSearchLoading(schedules)}
                  {showMessageIfSearchEmpty(schedules)}
                  {showSearchResultsIfAny()}
                </Stack>

                {showPreviouslyUsedItemsIfPatient()}
              </Stack>
            </Stack>
          </Stack.Item>

          <SelectedServicesList
            onChange={updateService}
            onRemove={removeServiceById}
            services={addedServices}
            schedules={schedules}
            existingInvoiceItems={invoiceItems}
            claimId={invoice.claimId}
          />
        </Stack>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 20 }}>
          <Stack.Item styles={{ root: { flexGrow: 1 } }}>
            {extraButton}
          </Stack.Item>
          <PromiseObservableButton
            primary
            {...dataAttribute(
              DataAttributes.Element,
              "add-services-modal-submit-button"
            )}
            disabled={getIsPrimaryButtonDisabled(
              originalServices,
              validAddedServices
            )}
            iconProps={{ iconName: "Add" }}
            text={addItemsButtonText}
            onClick={addItemsToServiceableItem}
          />
          <DefaultButton
            {...dataAttribute(
              DataAttributes.Element,
              "add-services-modal-cancel-button"
            )}
            onClick={closeDialog}
            text="Cancel"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
});

export const AddServicesModal: React.FC<AddServicesModalProps> = props => {
  const getSchedules = async (
    root: RootStore
  ): Promise<{
    schedules: Schedule[];
  }> => {
    const [schedules] = await Promise.all([
      root.billing.getSchedules({ mustHaveActiveFee: true }),

      root.billing.getInvoiceSettings(), // used by SelectedServicesList
      root.core.isNZTenant
        ? root.practice.ref.accProviderContractTypes.load()
        : undefined
    ]);

    // TODO - remove this when backend is filtering properly
    const activeSchedules = schedules.filter(schedule => !schedule.isInactive);

    return {
      schedules: activeSchedules.sort(sortSchedules)
    };
  };

  return (
    <DataFetcher fallback={<Spinner />} fetch={getSchedules}>
      {({ schedules }) => (
        <AddServicesModalBase {...props} schedules={schedules} />
      )}
    </DataFetcher>
  );
};
