import { FunctionComponent } from "react";

import { AppointmentTypesFilter } from "@shared-types/booking/appointment-type-filter.type.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

type AppointmentTypeFilterProps = Pick<
  FilterBarProps<AppointmentTypesFilter>,
  "children"
>;
export const AppointmentTypeFilter: FunctionComponent<
  AppointmentTypeFilterProps
> = ({ children }) => (
  <FilterBar<AppointmentTypesFilter>
    initialValues={{ search: "", includeInactive: false }}
    items={[
      {
        name: "search",
        type: "searchBox",
        stickItem: true,
        props: {
          placeholder: "Search appointment type",
          styles: { root: { width: 300 } },
          removeSpecialCharacters: false
        }
      },
      {
        type: "toggle",
        name: "includeInactive",
        props: {
          label: "Show inactive types",
          onText: "On",
          offText: "Off"
        }
      }
    ]}
    children={children}
  />
);
