import {
  isDateBeforeField,
  isNotFutureDate,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../../SOTAP.utils.ts";

export class FollowUpNotesCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();

    this.forField(sotapNameOf("dateOfInjury"), [
      isNotFutureDate(ValidationMessages.futureDateEqualOrLess)
    ]);
    this.forField(sotapNameOf("dateOfSurgery"), [
      isDateBeforeField(
        ValidationMessages.dateLaterThanDateOfInjury,
        "dateOfInjury"
      )
    ]);
    this.forField(sotapNameOf("followUpNotesComment"), [maxLength(2000)]);
  }
}
