import { ReactNode } from "react";

import {
  BillType,
  InvoiceItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";

export enum ServiceableItem {
  Invoice = 1,
  DraftItem = 2
}

export interface SearchFilter {
  searchText: string | undefined;
  scheduleIds: string[];
}

export type AddServicesModalProps = {
  selectedServices: InvoiceItemFormValue[];
  closeDialog: () => void;
  onServicesSelected: (services: InvoiceItemFormValue[]) => Promise<void>;
  hidden: boolean;
  kind: ServiceableItem;
  invoice: {
    patientId?: string;
    userId?: string;
    locationId?: string;
    invoiceDate?: Date;
    billType?: BillType;
    invoiceNumber?: string;
    calendarEventId?: string;
    claimId?: string;
    invoiceItems?: InvoiceItemDto[];
  };
  extraButton?: ReactNode;
};
