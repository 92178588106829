export enum ClinicalActivityTableLabels {
  Type = "Type",
  Status = "Status",
  Details = "Details",
  Due = "Due",
  Created = "Created",
  Comments = "Comments",
  ClaimId = "Claim",
  Priority = "Priority",
  Patient = "Patient",
  Actions = "Actions"
}
