import { IGroup } from "@bps/fluent-ui";
import { GoalDataItem } from "@shared-types/clinical/goal-data-item.interface.ts";
import { TreatmentData } from "@shared-types/clinical/treatment-data.interface.ts";

export interface ManagementFormValues {
  linkId?: string;
  treatmentPlanLinkType?: string;
  planTreatmentsBase?: string[];
  planTreatments?: TreatmentData[];
  planHasOtherTreatments?: boolean;
  planOtherTreatments?: string;
  planEducationOptions?: string[];
  planEducationComment?: string;
  planOtherEducationComment?: string;
  planReview?: string;
  goals?: GoalDataItem[];
  treatmentPlanDiscussed?: boolean;
  warningExplained?: boolean;
  consentObtained?: boolean;
  culturalNeedsIdentified?: boolean;
  hasAdditionalDiscussions?: boolean;
  additionalDiscussions?: string;
}

export type ConditionOption = {
  key: string;
  text: string;
  date: string;
};

export type ConditionSubGroup = {
  key: string;
  heading: string;
  items: ConditionItem[];
};

export type ConditionItem = {
  title: string;
  comment?: string;
};

export type MedicalHistoryOption = {
  key: string;
  text: string;
  date: string;
};

export type ManagementGroups = {
  groups: IGroup[];
  items: ConditionItem[];
};

export enum TreatmentPlanLinkType {
  episodeOfCare = "EOC",
  medicalHistory = "MH"
}
