import { useForm } from "react-final-form";

import {
  AnonymousCredential,
  ContainerClient,
  newPipeline
} from "@azure/storage-blob";
import { FontWeights, IButtonProps, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DocumentStagingInfo } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FileFieldValue } from "@ui-components/form/FilePickerField/FilePickerField.types.ts";
import { FileUploaderField } from "@ui-components/form/FileUploaderField/FileUploaderField.tsx";
import { getDefaultFileFields } from "@ui-components/form/FileUploaderField/utils.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AttachCorrespondenceFileValue } from "./DocumentBatchFormValues.ts";

interface CorrespondenceFileUploaderFieldProps {
  stagingPath?: DocumentStagingInfo;
  maxFiles?: number;
  hideDate?: boolean;
  hideSubject: boolean;
  setExternalFields?: (files: AttachCorrespondenceFileValue[]) => void;
}
const fileDateField = (fileFieldName: string) => `${fileFieldName}.date`;
const fileSubjectField = (fileFieldName: string) => `${fileFieldName}.subject`;

export const CorrespondenceFileUploaderField: React.FC<
  CorrespondenceFileUploaderFieldProps
> = ({ stagingPath, maxFiles, hideDate, hideSubject, setExternalFields }) => {
  const form = useForm();

  const renderUploadedFile = ({
    fileFieldValue,
    fileFieldName,
    onRemoveFile
  }: {
    fileFieldValue: FileFieldValue;
    fileFieldName: string;
    onRemoveFile: IButtonProps["onClick"];
  }) => {
    const today = DateTime.jsDateNow();
    fileFieldValue.blobName = form.getFieldState(
      `${fileFieldName}.name`
    )!.data!.blobName;

    const fileName = `${fileFieldValue.name}${
      fileFieldValue.extension ? `.${fileFieldValue.extension}` : undefined
    }`;

    return (
      <Stack
        verticalFill
        tokens={{ childrenGap: 4 }}
        styles={{ root: { margin: "4px 0" } }}
      >
        <Stack horizontal verticalAlign="center">
          <Fieldset
            horizontal
            horizontalAlign="space-between"
            styles={{ root: { width: "100%" } }}
          >
            <Stack.Item align="center">
              <Text
                variant="medium"
                styles={{
                  root: {
                    fontWeight: FontWeights.semibold,
                    marginRight: "8px"
                  }
                }}
              >
                {maxFiles && maxFiles > 1 ? "Files" : "File"}
              </Text>
              <Text>{fileName}</Text>
            </Stack.Item>

            <Stack horizontal>
              {!hideDate && (
                <DatePickerField
                  maxDate={today}
                  name={fileDateField(fileFieldName)}
                  styles={{ root: { width: 120 } }}
                />
              )}
              <DeleteButton
                styles={{ root: { flexShrink: 0 } }}
                onClick={e => {
                  setExternalFields &&
                    setExternalFields([
                      {
                        ...fileFieldValue,
                        subject: undefined,
                        date: undefined
                      }
                    ]);

                  onRemoveFile && onRemoveFile(e);
                }}
              />
            </Stack>
          </Fieldset>
        </Stack>
        {!hideSubject && (
          <TextInputField
            name={fileSubjectField(fileFieldName)}
            prefix="Subject"
          />
        )}
      </Stack>
    );
  };

  const getFileFields = (files: File[]) => {
    const defaultFileFields = getDefaultFileFields(files);

    if (setExternalFields) {
      setExternalFields(defaultFileFields);
    }
    return defaultFileFields;
  };

  return (
    <FileUploaderField<AttachCorrespondenceFileValue>
      getFileFields={getFileFields}
      name="files"
      renderUploadedFile={renderUploadedFile}
      maxFiles={maxFiles}
      getContainerClient={async () =>
        new ContainerClient(
          stagingPath!.stagingUrl,
          newPipeline(new AnonymousCredential())
        )
      }
    />
  );
};
