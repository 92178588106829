import { Slider, Stack, Text, useTheme } from "@bps/fluent-ui";
import { QuestionnaireDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { SliderField } from "@ui-components/form/SliderField.tsx";

import { getSliderConstraints } from "../utils/ClinicalToolAnswerFinder.ts";
import { grcsFormNameOf } from "./GRCSForm.types.ts";

interface GRCSQuestionProps {
  questionnaire: QuestionnaireDto;
  answer?: string;
}

export const GRCSQuestions: React.FunctionComponent<GRCSQuestionProps> = ({
  questionnaire,
  answer
}) => {
  const theme = useTheme();

  return (
    <Stack
      tokens={{ childrenGap: 24 }}
      styles={{ root: { overflowY: "auto", paddingRight: 8 } }}
    >
      <Text block styles={{ root: { fontStyle: "italic" } }}>
        {`"${questionnaire.items[0].text}"`}
      </Text>
      {questionnaire.items.map(item => {
        return (
          <Stack key={`${item.id}Item`}>
            <Text
              block
              styles={{
                root: {
                  padding: 24,
                  border: "1px solid",
                  borderColor: theme.palette.neutralLight,
                  borderRadius: "4px"
                }
              }}
            >
              <Stack.Item>
                {answer ? (
                  <Slider
                    titles={{
                      min: "Very much worse",
                      mid: "Unchanged",
                      max: "Completely recovered"
                    }}
                    scaled
                    originFromZero
                    value={Number(answer)}
                    disabled
                    {...getSliderConstraints(item)}
                  />
                ) : (
                  <SliderField
                    name={grcsFormNameOf("answer")}
                    titles={{
                      min: "Very much worse",
                      mid: "Unchanged",
                      max: "Completely recovered"
                    }}
                    scaled={true}
                    originFromZero={true}
                    snapToStep
                    parse={v => (v ? v.toString() : undefined)}
                    {...getSliderConstraints(item, -5, 5)}
                  />
                )}
              </Stack.Item>
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};
