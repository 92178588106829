import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { SubmissionFormDialogProps } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.types.ts";

interface ContactSubmissionFormDialogProps<FormValues extends object = object>
  extends Omit<
    SubmissionFormDialogProps<FormValues>,
    | "dialogProps"
    | "dialogName"
    | "autoFocus"
    | "subscription"
    | "styles"
    | "onSubmitSucceeded"
  > {}

export const ContactSubmissionFormDialog = <FormValues extends object = object>(
  props: ContactSubmissionFormDialogProps<FormValues>
) => {
  const {
    practice: {
      ui: { hideModal }
    }
  } = useStores();
  return (
    <SubmissionFormDialog<FormValues>
      {...props}
      dialogName="Patient demographic dialog"
      onSubmitSucceeded={hideModal}
      dialogProps={{
        minWidth: 862,
        onDismiss: () => hideModal(),
        modalProps: {
          layerProps: { eventBubblingEnabled: true }
        },
        dialogContentProps: {
          showCloseButton: false,
          styles: { inner: { padding: 0 }, title: { display: "none" } }
        }
      }}
      autoFocus={false}
      subscription={{
        values: true,
        dirty: true,
        submitting: true,
        submitSucceeded: true,
        dirtySinceLastSubmit: true
      }}
      styles={{
        root: {
          position: "relative"
        },
        main: { height: "80vh" },
        fields: {
          padding: 0
        }
      }}
    />
  );
};
