import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useForm } from "react-final-form";

import {
  ButtonsGroupOption,
  Heading,
  Stack,
  StackItem,
  TextBadge,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import {
  BodyArea,
  QuestionnaireAnswerOptionDto,
  QuestionnaireItemType,
  SpecialTestItemDto,
  SpecialTestTypeCode
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldsSpy } from "@ui-components/form/FieldsSpy.tsx";
import { SliderField } from "@ui-components/form/SliderField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { BodyExaminationFormValues } from "../BodyExaminationForm.types.ts";
import { useBodyExaminationContext } from "../context/BodyExaminationContext.ts";
import { useSideAccordionContext } from "./side-accordion/context/SideAccordionContext.tsx";

export interface SpecialTestItemProps {
  allSpecialTests: SpecialTestItemDto[];
  specialTestItem: SpecialTestItemDto;
  bodyArea: string;
  index: number;
  hideLabel?: boolean;
  isVertical?: boolean;
  renderLabelOnly?: boolean; // Some questions may only appear based on conditions, so we need the same logic for a label.
  hasSide?: boolean;
  specialTestType?: string;
}

export const BodySpecialTestItem: React.FC<SpecialTestItemProps> = observer(
  ({
    allSpecialTests,
    specialTestItem,
    bodyArea,
    index,
    hideLabel,
    isVertical,
    renderLabelOnly,
    hasSide,
    specialTestType
  }) => {
    const theme = useTheme();

    const hasHeading = !!specialTestItem.heading;

    const { getState } = useForm<BodyExaminationFormValues>();

    const [showItem, setItemVisible] = useState<boolean>(false);

    const helper = useSideAccordionContext();
    const { hasSpecialTestConditionBeenMet } = useBodyExaminationContext();

    const fieldName = (index: number) => {
      let name = "specialTestResponseItems";

      if (specialTestType === SpecialTestTypeCode.Strength) {
        name = "strengthTestResponseItems";
      } else if (specialTestType === SpecialTestTypeCode.CentralNervous) {
        name = "cranialNerveTestResponseItems";
      }

      if (hasSide) {
        return `${name}[${helper.specialTestSide}${bodyArea}][${index}]`;
      }
      return `${name}[${bodyArea}][${index}]`;
    };

    const combinedBodyArea = hasSide
      ? `${helper.specialTestSide}${bodyArea}`
      : bodyArea;

    const getIconName = (key: string, text: string) => {
      // If the actual option should be "Normal", don't render an icon.

      if (text === "Normal") {
        if (key === "NORM") {
          return undefined;
        } else if (key === "NDOT") {
          return "LocationFill";
        } else if (key === "NCHECK") {
          return "Completed";
        } else if (key === "N") {
          return "CalculatorEqualTo";
        }
      }

      switch (text) {
        case "High":
          return "Market";
        case "Low":
          return "MarketDown";
        case "Neutral":
          return "Pause";
        case "Positive":
          return "Add";
        case "Negative":
          return "Remove";
        case "Increased":
          return "Up";
        case "Reduced":
          return "Down";
        case "PinPoint":
          return "LocationDot";
        case "Normal":
          return "LocationFill";
        case "Dilated":
          return "FullCircleMask";
        case "Decreased":
          return "Down";
        case "Absent":
          return "ErrorBadge";
        case "Increased Left":
          return "GoMirrored";
        case "Equal":
          return "CalculatorEqualTo";
        case "Increased Right":
          return "Go";
        default:
          return undefined;
      }
    };

    const renderLabel = (label: string) => (
      <Heading variant="section-sub-heading">{label}</Heading>
    );

    const getOptions = (
      answerOptions: QuestionnaireAnswerOptionDto[]
    ): ButtonsGroupOption<string>[] => {
      return answerOptions.map(x => {
        return getIconName(x.value, x.text)
          ? {
              key: x.value,
              text: x.text,
              iconName: getIconName(x.value, x.text),
              tooltipContent: x.text
            }
          : {
              key: x.value,
              text: x.text
            };
      });
    };

    const renderMultichoice = () => {
      const renderAsDropdown =
        bodyArea !== BodyArea.CentralNervousSystemCranial &&
        (bodyArea === BodyArea.AnkleFoot &&
        (specialTestItem.id === 5 || specialTestItem.id === 6)
          ? true
          : specialTestItem.answerOptions &&
            specialTestItem.answerOptions.length > 3);

      if (renderAsDropdown) {
        return (
          <DropdownField
            name={fieldName(index)}
            options={getOptions(specialTestItem.answerOptions || [])}
            placeholder={specialTestItem.placeholder}
            calloutProps={{
              calloutMaxHeight: 250
            }}
            withNoEmptyOption
            prefix={specialTestItem.prefix}
            multiSelect={bodyArea === BodyArea.Spine}
          />
        );
      } else {
        return (
          <Stack horizontal verticalAlign="center" horizontalAlign="start">
            {specialTestItem.prefix && (
              <Stack styles={{ root: { paddingRight: 8 } }}>
                <TextBadge
                  badgeSize={TextBadgeSize.large}
                  hasBorder
                  customColors={{ borderColor: theme.palette.themeLight }}
                >
                  {specialTestItem.prefix}
                </TextBadge>
              </Stack>
            )}
            <ButtonsGroupSingleChoiceField
              name={fieldName(index)}
              styles={{
                label: {
                  maxHeight: 32,
                  width: "auto"
                },
                root: { minWidth: 75 },
                text: {
                  color: theme.semanticColors.inputText,
                  justifyContent: "center",
                  display: "flex"
                }
              }}
              options={getOptions(specialTestItem.answerOptions || [])}
              showSelectedTextValue={
                specialTestItem.answerOptions &&
                specialTestItem.answerOptions.length > 2
              }
            />
          </Stack>
        );
      }
    };

    const renderInteger = () => {
      const range = specialTestItem.range;
      const prefix = specialTestItem.answerOptions
        ? specialTestItem.answerOptions[0].value
        : undefined;

      const sliderFormat = (value: number) =>
        `${value}/${specialTestItem.range}`;
      return (
        <Stack
          horizontal={!isVertical}
          verticalAlign="start"
          tokens={{ childrenGap: 8 }}
        >
          <StackItem>
            <SpinNumberInputField
              min={0}
              max={range}
              name={fieldName(index)}
              styles={{
                input: { width: 0, minWidth: "none" },
                root: { width: "65%", maxWidth: 86 }
              }}
              prefix={prefix}
            />
          </StackItem>
          <StackItem grow>
            <SliderField
              styles={{
                slideBox: {
                  padding: isVertical && !hasHeading ? 0 : 8
                },
                valueLabel: {
                  width: 13
                }
              }}
              min={0}
              max={range}
              valueFormat={sliderFormat}
              showValue={sliderFormat !== undefined}
              name={fieldName(index)}
            />
          </StackItem>
        </Stack>
      );
    };

    const renderItem = () => {
      switch (specialTestItem.type) {
        case QuestionnaireItemType.MultiChoice:
          return renderMultichoice();
        case QuestionnaireItemType.Integer:
          return renderInteger();
        case QuestionnaireItemType.String:
          return (
            <TextInputField
              name={fieldName(index)}
              placeholder={specialTestItem.placeholder}
              prefix={specialTestItem.prefix}
            />
          );
        default:
          return null;
      }
    };

    const onConditionalChanged = () => {
      const formValues = getState().values;

      // Find all form results;
      const conditionals = specialTestItem.requiredConditions;

      if (!conditionals) {
        setItemVisible(true);
        return;
      }

      // All conditions have to be met in order for the question to appear. If one of the conditions fail, the question is not allowed to show.
      const conditionsMet = conditionals.every(conditional => {
        const indexOfQuestion = allSpecialTests.findIndex(
          y => y.id === conditional.questionId
        );

        if (
          indexOfQuestion !== -1 &&
          formValues.specialTestResponseItems &&
          formValues.specialTestResponseItems[combinedBodyArea] &&
          formValues.specialTestResponseItems[combinedBodyArea].length >=
            indexOfQuestion
        ) {
          const question: string =
            formValues.specialTestResponseItems[combinedBodyArea][
              indexOfQuestion
            ];

          return hasSpecialTestConditionBeenMet(conditional, question);
        }

        return false;
      });

      setItemVisible(conditionsMet);
    };

    const conditionalRender =
      specialTestItem.requiredConditions &&
      specialTestItem.requiredConditions.length > 0;

    const getFieldNames = () => {
      const requiredConditions = specialTestItem.requiredConditions;
      const fieldNames: string[] = [];
      if (requiredConditions) {
        requiredConditions.forEach(x => {
          const indexOfQuestion = allSpecialTests.findIndex(
            y => y.id === x.questionId
          );

          fieldNames.push(fieldName(indexOfQuestion));
        });
      }

      return fieldNames;
    };

    const fullWidthItem =
      specialTestItem.type === QuestionnaireItemType.String ||
      specialTestItem.type === QuestionnaireItemType.Integer ||
      (specialTestItem.type === QuestionnaireItemType.MultiChoice &&
        specialTestItem.answerOptions &&
        specialTestItem.answerOptions.length >= 3);

    const shouldShowItem = conditionalRender ? showItem : true;

    return (
      <>
        {conditionalRender && (
          <FieldsSpy
            fieldNames={getFieldNames()}
            onChange={() => {
              onConditionalChanged();
            }}
          />
        )}
        {shouldShowItem && (
          <Stack
            verticalAlign="end"
            tokens={{ childrenGap: 4 }}
            styles={{
              root: {
                width: fullWidthItem ? "100%" : "fit-content"
              }
            }}
          >
            {!hideLabel && <Stack>{renderLabel(specialTestItem.text)}</Stack>}
            {!renderLabelOnly && <Stack>{renderItem()}</Stack>}
          </Stack>
        )}
      </>
    );
  }
);
