import { FormApi } from "final-form";
import React from "react";

import { Spinner } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ClinicalDataTypeLabel
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import {
  FamilyHistoryFormContext,
  useFamilyHistoryFormContext
} from "./context/FamilyHistoryFormContext.ts";
import { FamilyHistoryFormHelper } from "./context/FamilyHistoryFormHelper.ts";
import { FamilyHistoryFields } from "./FamilyHistoryFields.tsx";
import { FamilyHistoryFormValues } from "./FamilyHistoryForm.types.ts";
import { FamilyHistoryFormValidator } from "./FamilyHistoryFormValidator.ts";

export interface FamilyHistoryFormProps {
  clinicalRecord: ClinicalRecord;
}

const FamilyHistoryFormComponent: React.FC<FamilyHistoryFormProps> = ({
  clinicalRecord
}) => {
  const root = useStores();
  const validator = new FamilyHistoryFormValidator();
  const { isViewOnly } = usePatientRecordScreenContext();

  const helper = useFamilyHistoryFormContext();

  const { submitData, onCancel, getClinicalData, getInitialValues } = helper;

  const onSubmitSucceeded = (
    values: FamilyHistoryFormValues,
    form: FormApi<FamilyHistoryFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      root.clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.FamilyHistory
      });
      form.restart(values);
      root.notification.success("Saved successfully");
    } else {
      onCancel();
    }
  };

  const getValues = (stashedData?: StashedEncounterClinicalData) => {
    const dirty = stashedData?.dirtyFamilyHistoryForm;

    const familyHistoryFormAlreadyConfirmed =
      (stashedData?.originalDto?.familyHistoryConfirmed &&
        stashedData.originalDto?.familyHistoryConfirmed.createLog
          ?.createdEncounterId === clinicalRecord.openEncounter?.id) ??
      false;

    const isNewFamilyHistory =
      !clinicalRecord?.clinicalData?.familyHistoryConfirmed?.createLog;

    return { dirty, familyHistoryFormAlreadyConfirmed, isNewFamilyHistory };
  };

  const getButtonText = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, familyHistoryFormAlreadyConfirmed, isNewFamilyHistory } =
      getValues(stashedData);

    const submissionButtonText =
      isNewFamilyHistory ||
      familyHistoryFormAlreadyConfirmed ||
      (!familyHistoryFormAlreadyConfirmed && dirty)
        ? "Save"
        : "Confirm";

    return submissionButtonText;
  };

  const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, familyHistoryFormAlreadyConfirmed, isNewFamilyHistory } =
      getValues(stashedData);
    return (isNewFamilyHistory || familyHistoryFormAlreadyConfirmed) && !dirty;
  };

  return (
    <ClinicalSubmissionForm<FamilyHistoryFormValues>
      formName="familyHistory"
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={getInitialValues()}
      readOnly={isViewOnly}
      validate={validator.validate}
      disableRoutePrompt
      heading={ClinicalDataTypeLabel.FamilyHistory}
      onCancel={() => {
        onCancel();
      }}
      extraPromptConditionOnCancel={() =>
        clinicalRecord.stashedClinicalData?.dirtyAreas
          ? !!clinicalRecord.stashedClinicalData?.dirtyAreas.get(
              "familyHistory"
            )
          : false
      }
      disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
      submitButtonTextCondition={stashedData => getButtonText(stashedData)}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.familyHistoryConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.familyHistoryConfirmed
      )}
      hideSubmit={isViewOnly}
      noGap
      hasSeparator
    >
      <>
        <FamilyHistoryFields />
        <StashedClinicalDataFormSpy<FamilyHistoryFormValues>
          getData={getClinicalData}
          clinicalRecord={clinicalRecord}
          areasToObserve={{
            familyHistory: [ClinicalDataType.FamilyHistory]
          }}
        />
      </>
    </ClinicalSubmissionForm>
  );
};

export const FamilyHistoryFormWithContext = ({
  clinicalRecord
}: {
  clinicalRecord: ClinicalRecord;
}) => {
  const root = useStores();
  const helper = new FamilyHistoryFormHelper(root, clinicalRecord);
  return (
    <FamilyHistoryFormContext.Provider value={helper}>
      <DataFetcher
        fetch={() => helper.lookupGeneralTerminologies()}
        fallback={<Spinner />}
      >
        {() => <FamilyHistoryFormComponent clinicalRecord={clinicalRecord} />}
      </DataFetcher>
    </FamilyHistoryFormContext.Provider>
  );
};

export const FamilyHistoryForm = withFetch(
  x => [x.clinical.ref.relationships.load()],
  FamilyHistoryFormWithContext
);
