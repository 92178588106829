import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import { PatientNoticesAddButton } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesAddButton.tsx";
import { PatientNoticesContextProvider } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesContext.tsx";
import {
  NoticeType,
  PatientNoticesModel
} from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { PatientNoticesTable } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTable.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { BookingEventCalloutSeparator } from "./BookingEventCalloutSeparator.tsx";

export const BookingEventCalloutPatientNotices = observer(() => {
  const root = useStores();
  const { practice } = root;

  const { calendarEvent, isPatientCalendarEvent, setPreventDismiss } =
    useContext(BookingCalendarEventContext);

  const { contactId } = calendarEvent;

  if (!isPatientCalendarEvent || !contactId) return null;

  return (
    <PatientNoticesContextProvider
      value={
        new PatientNoticesModel(practice, {
          patientId: contactId,
          withSystemNotices: true
        })
      }
    >
      <BookingEventCalloutSeparator />
      <Stack tokens={{ childrenGap: 8 }}>
        <PatientNoticesAddButton
          filter={NoticeType.Admin}
          styles={{ icon: { root: { margin: -8 } } }}
        />

        <PatientNoticesTable
          showActions
          setPreventDismissOnDelete={setPreventDismiss}
          childrenGap={0}
          padding={0}
          filter={NoticeType.Admin}
        />
      </Stack>
    </PatientNoticesContextProvider>
  );
});
