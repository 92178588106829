import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useForm } from "react-final-form";

import { ActionButton, Stack } from "@bps/fluent-ui";
import { ReferralsOutFormValidator } from "@modules/acc/screens/claim/validators/ReferralsOutFormValidator.tsx";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { ReferralsOutFormValues } from "@shared-types/acc/referrals-out-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { claimFormNameOf } from "../claim.utils.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";

const referralFields: (keyof ReferralsOutFormValues)[] = [
  "enableReferralsOut",
  "referralDetails"
];

const MAX_REFERRAL_LENGTH = 5;

const ReferralsOutFormSectionBase: FC = observer(() => {
  const { acc } = useStores();
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const form = useForm<ClaimFormValues>();

  const emptyReferralDetails = {
    referralReason: "",
    providerTypeCode: undefined
  };

  const handleDeleteItem = (
    index: number,
    fields: {
      remove: (index: number) => void;
      length?: number;
    }
  ) => {
    fields.remove(index);
    if (fields.length === 1) {
      form.change(claimFormNameOf("enableReferralsOut"), false);
    }
  };
  return (
    <ClaimCard
      id={ClaimsCardIds.referralsOut}
      errorProps={{ fields: referralFields }}
      openOnRender={selectedMenuItemId === ClaimsCardIds.referralsOut}
      heading={ClaimSectionHeadings.referralsOut}
      iconName="Send"
      statusValidator={new ReferralsOutFormValidator()}
    >
      <FieldArray name={claimFormNameOf("referralDetails")}>
        {({ fields }) => {
          return (
            <Stack tokens={{ childrenGap: 8 }}>
              <ToggleField
                onText="Yes"
                offText="No"
                label={ClaimFormLabels.enableReferralsOut}
                name={claimFormNameOf("enableReferralsOut")}
              />
              <FieldSpy
                name={claimFormNameOf("enableReferralsOut")}
                onChange={checked => {
                  if (checked) {
                    if ((fields?.value?.length ?? 0) === 0)
                      fields.push(emptyReferralDetails);
                  } else
                    for (let ii = 0; ii < (fields?.value?.length ?? 0); ii++)
                      fields.remove(0);
                }}
              />
              <FieldCondition
                when={claimFormNameOf("enableReferralsOut")}
                is={true}
              >
                {fields.map((name, index) => {
                  return (
                    <Stack key={name} horizontal tokens={{ childrenGap: 8 }}>
                      <Stack.Item grow>
                        <Stack
                          styles={(_props, theme) => ({
                            root: {
                              borderRadius: 2,
                              border: `1px solid ${theme.palette.neutralLight}`,
                              padding: 15,
                              paddingTop: 5
                            }
                          })}
                          tokens={{ childrenGap: 8 }}
                        >
                          <StaticPickerField
                            name={`${name}.providerTypeCode`}
                            label={ClaimFormLabels.providerType}
                            inputProps={{
                              placeholder: ClaimFormLabels.searchProvider
                            }}
                            fetchDataSource={() =>
                              acc.ref.providerTypes.keyNameValues
                            }
                            required
                            fieldItemStyles={{
                              root: { flexGrow: 2.8, flexBasis: 0 }
                            }}
                          />
                          <TextInputField
                            required
                            label={ClaimFormLabels.referralReason}
                            name={`${name}.referralReason`}
                            placeholder={ClaimFormLabels.betweenCharacter}
                            resizable={false}
                            multiline
                            rows={4}
                            maxLength={255}
                          />
                        </Stack>
                      </Stack.Item>
                      <Stack.Item
                        styles={{
                          root: {
                            flexBasis: 16,
                            display: "flex",
                            justifyContent: "flex-start"
                          }
                        }}
                      >
                        <DeleteButton
                          styles={{
                            root: { height: "auto" },
                            icon: {
                              alignSelf: "flex-start",
                              paddingTop: 5
                            }
                          }}
                          onClick={() => handleDeleteItem(index, fields)}
                        />
                      </Stack.Item>
                    </Stack>
                  );
                })}
                <Stack horizontal>
                  <ActionButton
                    iconProps={{ iconName: "Add" }}
                    text="Add another referral"
                    onClick={() => fields.push(emptyReferralDetails)}
                    disabled={fields.length === MAX_REFERRAL_LENGTH}
                    styles={{
                      root: { paddingLeft: "9px 5px" }
                    }}
                  />
                </Stack>
              </FieldCondition>
            </Stack>
          );
        }}
      </FieldArray>
    </ClaimCard>
  );
});

export const ReferralsOutFormSection = withFetch(
  x => [x.acc.ref.providerTypes.load()],
  ReferralsOutFormSectionBase
);
