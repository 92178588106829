import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { Booked } from "../../shared-components/Booked.tsx";
import { CreateTemporaryReservation } from "./CreateTemporaryReservation.tsx";
import { nameOf } from "./MeetingFormValues.ts";

export const MeetingFormFields: FunctionComponent = () => {
  const { booking } = useStores();
  const calendarEventId: string | undefined = booking.ui.currentAppointment?.id;

  const calendarEvent: CalendarEvent | undefined = calendarEventId
    ? booking.calendarEventsMap.get(calendarEventId)
    : undefined;

  const bookedByUser = calendarEvent && calendarEvent.bookedByUser;
  const bookedDate = calendarEvent && calendarEvent.startDateTime;
  return (
    <>
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack.Item>
            <ContactPickerField
              name={nameOf("contactId")}
              label="Contact"
              getTextFromItem={item => item.text!}
              iconName="Search"
            />
          </Stack.Item>

          <Stack.Item>
            <BookableUserPickerField
              name={nameOf("providerId")}
              label="Provider"
              filter={{
                statusCodes: [UserStatus.Active],
                showOnCalendar: true
              }}
              required
              getTextFromItem={item => item.text!}
              iconName="Search"
            />
          </Stack.Item>
        </Stack>

        <Fieldset horizontal horizontalAlign="start">
          <DatePickerField label="Date" name={nameOf("startDate")} />
          <TimePickerField
            label="Time"
            suggestionInterval={15}
            name={nameOf("startTime")}
          />
          <SpinNumberInputField
            required
            label="Duration"
            name={nameOf("duration")}
            min={0}
            step={15}
            suffix="mins"
            fieldItemStyles={{
              itemWrapper: { alignItems: "baseline" },
              item: { flexGrow: 0 }
            }}
            // SpinNumberInput component has a min width of 86
            styles={{ root: { width: 90 } }}
          />
        </Fieldset>

        <TextInputField
          multiline={true}
          label="Comments"
          name={nameOf("comments")}
        />
      </Stack>
      <Booked bookedByUser={bookedByUser} bookedDate={bookedDate} />
      <CreateTemporaryReservation />
    </>
  );
};
