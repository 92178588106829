import React from "react";

import {
  dataAttribute,
  DataAttributes,
  ITheme,
  Link,
  NoDataTile,
  Stack,
  Text
} from "@bps/fluent-ui";

import {
  AccPracticeContractType,
  PracticeDetailsFormLabels
} from "../practice-details/PracticeDetailsForm.types.tsx";

const neutralTertiaryColorStyle = (_: any, theme: ITheme) => ({
  root: {
    color: theme.palette.neutralTertiary
  }
});

interface PracticeAccContractsNoTileProps {
  onTileItemClicked: (key: string) => void;
  show: boolean;
}

export const PracticeAccContractsNoDataTile: React.FC<
  PracticeAccContractsNoTileProps
> = ({ onTileItemClicked, show }) => {
  return show ? (
    <NoDataTile
      textProps={{ text: "No contracts" }}
      linkProps={{
        onRender: () => {
          return (
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <Text styles={neutralTertiaryColorStyle}>
                {PracticeDetailsFormLabels.addAn}
              </Text>
              <Link
                {...dataAttribute(
                  DataAttributes.Element,
                  "no-data-tile-link-allied"
                )}
                onClick={() =>
                  onTileItemClicked(AccPracticeContractType.AlliedHeathServices)
                }
              >
                {PracticeDetailsFormLabels.alliedHealth}
              </Link>
              <Text styles={neutralTertiaryColorStyle}>
                {PracticeDetailsFormLabels.orAn}
              </Text>
              <Link
                {...dataAttribute(
                  DataAttributes.Element,
                  "no-data-tile-link-specified"
                )}
                onClick={() =>
                  onTileItemClicked(
                    AccPracticeContractType.SpecifiedTreatmentProvider
                  )
                }
              >
                {PracticeDetailsFormLabels.specifiedTreament}
              </Link>
              <Text styles={neutralTertiaryColorStyle}>
                {PracticeDetailsFormLabels.contract}
              </Text>
            </Stack>
          );
        }
      }}
    />
  ) : null;
};
