import { OutcomeDataItemDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";

export enum TasksScreenLabels {
  Edit = "Edit",
  Tasks = "Tasks",
  Print = "Print",
  Submit = "Submit",
  Search = "Search",
  Action = "Action",
  Status = "Status",
  NoData = "No data",
  Comment = "Comment",
  Outcome = "Outcome",
  OutcomeLog = "Outcome log",
  NoTasks = "No tasks",
  NoMatch = "No match",
  Assignee = "Assignee",
  Outcomes = "Outcomes",
  Comments = "Comments",
  Priority = "Priority",
  AssignTo = "Assign to",
  PrintedBy = "Printed by",
  Unassigned = "Unassigned",
  SelectTask = "Select task",
  DatePrinted = "Date printed",
  TaskDetails = "Task details",
  followUpDate = "Follow up date",
  ImageAttached = "Image attached",
  SearchPatient = "Search for %pat%",
  NoFilteredResult = "0 matches found",
  ActionToBeTaken = "Action to be taken",
  AssignedTo = "Assigned to",
  FollowUp = "Follow-up",
  Checked = "Checked",
  ExtraInfo = "Extra info",
  UrgentAppointment = "Urgent appointment",
  NonUrgentAppointment = "Non-urgent appointment",
  NoAction = "No action",
  NonUrgentBadgeLabel = "Non-urgent appt.",
  UrgentBadgeLabel = "Urgent appt.",
  Save = "Save"
}

export interface TaskActionFormValues {
  closingComments?: string;
  userTaskId: string;
  outcome?: string;
  outcomeLog?: OutcomeDataItemDto[];
  currentOutcome?: currentOutcomeFormValues;
}

export interface currentOutcomeFormValues {
  id: string;
  outcomeStatus?: string;
  date?: Date;
  time?: string;
  comment?: string;
  createdByUser?: string;
}

export interface TaskDetailsFormValues {
  instructionCode: string;
  extraInfo?: string;
  assignTo?: string;
  dueDate?: Date;
  id: string;
  checkedDate?: Date;
}
