import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";

import {
  FontIcon,
  FontSizes,
  Link,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { HighlightedText } from "@ui-components/highlighted-text/HighlightedText.tsx";
import { ClaimStatusText } from "@ui-components/RefText.tsx";

import { IInvoiceRow } from "./AccInvoiceTransactionsList.tsx";
import { ClaimCallout } from "./ClaimCallout.tsx";

export const TransactionClaimCell: FunctionComponent<IInvoiceRow> = observer(
  props => {
    const theme = useTheme();

    const { invoice } = props;

    if (!invoice.accSchedule) {
      return null;
    }

    const { claimStatus, claimId, claimNumber } = invoice;
    const { routing } = useStores();

    const searchString = routing.queryStringParam("search") || "";

    let iconColour = theme.palette.neutralSecondary;
    let iconName: string | undefined;

    switch (claimStatus) {
      case ClaimStatuses.Incomplete:
      case ClaimStatuses.Ready:
        iconColour = theme.palette.yellowDark;
        iconName = "Warning";
        break;
      case ClaimStatuses.Queued:
        iconName = "Documentation";
        break;
      case ClaimStatuses.Accepted:
        iconColour = theme.semanticColors.successIcon;
        iconName = "Accept";
        break;
      case ClaimStatuses.Held:
      case ClaimStatuses.Pending:
        iconName = "HourGlass";
        break;
      case ClaimStatuses.NotVerified:
      case ClaimStatuses.NotAvailable:
        iconName = "Warning";
        break;
      case ClaimStatuses.Error:
      case ClaimStatuses.Declined:
        iconColour = theme.semanticColors.errorIcon;
        iconName = "Warning";
        break;
      case ClaimStatuses.Accredited:
        iconName = "ContactList";
        break;
      case ClaimStatuses.Discharged:
        iconColour = theme.semanticColors.errorIcon;
        iconName = "SignOut";
    }

    const [claimMouseEvent, setClaimMouseEvent] = useState<
      MouseEvent | undefined
    >(undefined);

    return (
      <Stack horizontal>
        <TooltipHost content={<ClaimStatusText code={claimStatus} />}>
          <FontIcon
            iconName={iconName}
            styles={{
              root: {
                color: iconColour,
                marginRight: 8,
                fontSize: FontSizes.size16
              }
            }}
          />
        </TooltipHost>
        <Link
          onMouseDown={evt => evt.stopPropagation()}
          onClick={evt => setClaimMouseEvent(evt.nativeEvent)}
        >
          {claimNumber ? (
            <HighlightedText
              text={claimNumber}
              filter={searchString}
              startOnly
            />
          ) : (
            "Not lodged"
          )}
        </Link>
        {claimMouseEvent && claimId && (
          <ClaimCallout
            target={claimMouseEvent}
            onDismiss={() => setClaimMouseEvent(undefined)}
            claimId={claimId}
            iconOptions={{ iconName, iconColour }}
          />
        )}
      </Stack>
    );
  }
);
