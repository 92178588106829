import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Dialog, Stack } from "@bps/fluent-ui";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { ModalViewHeader } from "@modules/billing/screens/shared-components/ModalViewHeader.tsx";
import { TransactionBadgeNew } from "@modules/billing/screens/shared-components/TransactionBadgeNew.tsx";

import { InvoiceView } from "./InvoiceView.tsx";
import { InvoiceViewButtons } from "./InvoiceViewButtons.tsx";
import { InvoiceViewDataFetcher } from "./InvoiceViewDataFetcher.tsx";

interface InvoiceViewModalProps {
  invoiceId: string | undefined;
  onDismiss: () => void;
  onSubmit: () => void;
}

const InvoiceViewModalBase: React.FC<InvoiceViewModalProps> = observer(
  ({ onDismiss, onSubmit }) => {
    const { invoice } = useContext(InvoiceViewContext);

    return (
      <Dialog
        hidden={false}
        onDismiss={onDismiss}
        dialogContentProps={{
          title: (
            <ModalViewHeader
              badge={<TransactionBadgeNew transaction={invoice} />}
              amount={invoice.total}
              heading={`Invoice ${invoice.number}`}
              date={invoice.transactionDate}
            />
          ),
          showCloseButton: true,
          styles: { content: { width: "auto" }, inner: { padding: 0 } }
        }}
        maxWidth="auto"
        minWidth="auto"
      >
        <Stack
          styles={{
            root: {
              maxHeight: "calc(90vh - 75px)"
            }
          }}
        >
          <Stack
            styles={{
              root: {
                flexShrink: 1,
                overflow: "auto",
                padding: "0 24px 16px 24px"
              }
            }}
          >
            <InvoiceView />
          </Stack>
          <Stack styles={{ root: { padding: 24 } }}>
            <InvoiceViewButtons onClose={onDismiss} onSubmit={onSubmit} />
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);

export const InvoiceViewModal: React.FC<InvoiceViewModalProps> = observer(
  props => {
    if (!props.invoiceId) {
      return null;
    }

    return (
      <InvoiceViewDataFetcher invoiceId={props.invoiceId}>
        <InvoiceViewModalBase {...props} />
      </InvoiceViewDataFetcher>
    );
  }
);
