import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { HideStack, mergeStyles, MessageBar, Text } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { PatientDemographicContext } from "../../context/PatientDemographicContext.ts";

export const listStyle = mergeStyles({
  listStyle: "inherit",
  marginLeft: 24,
  marginTop: 0
});

interface EditPatientHeaderGenderDiverseFieldProps {
  displayNoticeMessage: boolean | undefined;
  displayGenderMessageBar: boolean | undefined;
  patientId: string | undefined;
}

export const EditPatientHeaderGenderDiverseField: FC<EditPatientHeaderGenderDiverseFieldProps> =
  observer(({ displayNoticeMessage, displayGenderMessageBar, patientId }) => {
    const { userExperience } = useStores();

    const { setdisplayGenderMessageBar } = useContext(
      PatientDemographicContext
    );
    return (
      <HideStack when={!displayGenderMessageBar}>
        <MessageBar
          onDismiss={() => {
            setdisplayGenderMessageBar(false);
            patientId &&
              userExperience.upsertPatientSetting({
                patientId,
                pronounMessage: false
              });
          }}
        >
          <HideStack when={displayNoticeMessage!}>
            <Text variant="small">
              <b>Gender diverse profile:</b>
              <ul className={listStyle}>
                <li>Clinical notice will be created</li>
                <li>
                  Consider updating <b>'Title'</b> and <b>'Preferred name'</b>{" "}
                  for this patient
                </li>
              </ul>
            </Text>
          </HideStack>
          <HideStack when={!displayNoticeMessage!}>
            <Text variant="small">
              <b>Gender diverse profile:</b>Consider updating <b>'Title'</b> and{" "}
              <b>'Preferred name'</b> for this patient
            </Text>
          </HideStack>
        </MessageBar>
      </HideStack>
    );
  });
