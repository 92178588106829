import { FC } from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { OnHoldMessageBar } from "@modules/acc/screens/claim/components/OnHoldMessageBar.tsx";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { InvoiceItemFormValue } from "../../shared-components/types/invoice-item-form-value.interface.ts";
import { getIsItemASubsidy } from "./utils.ts";

interface InvoiceFormHeaderMessageBarProps {
  accountContactId?: string;
  claimId?: string;
  invoiceItems?: InvoiceItemFormValue[];
}

export const InvoiceFormHeaderMessageBar: FC<
  InvoiceFormHeaderMessageBarProps
> = ({ claimId, accountContactId, invoiceItems }) => {
  if (claimId) {
    return (
      <DataFetcher<Claim> fetch={({ acc }) => acc.getClaim(claimId)}>
        {claim => {
          const { insurerContactId, isOnHold } = claim;
          return insurerContactId || isOnHold ? (
            <>
              {insurerContactId && (
                <ContactFetcher contactId={insurerContactId}>
                  {contact =>
                    invoiceItems?.some(getIsItemASubsidy) &&
                    contact.draftItemsEnabled && (
                      <MessageBar messageBarType={MessageBarType.warning}>
                        Organisation has draft items enabled, subsidy items will
                        be saved to the draft items list
                      </MessageBar>
                    )
                  }
                </ContactFetcher>
              )}
              {isOnHold && <OnHoldMessageBar />}
            </>
          ) : null;
        }}
      </DataFetcher>
    );
  }

  if (accountContactId) {
    return (
      <ContactFetcher contactId={accountContactId}>
        {contact =>
          contact.draftItemsEnabled &&
          !invoiceItems?.every(getIsItemASubsidy) && (
            <MessageBar messageBarType={MessageBarType.warning}>
              This organisation has draft items enabled, selecting save will
              create a draft item for the organisation
            </MessageBar>
          )
        }
      </ContactFetcher>
    );
  }

  return null;
};
