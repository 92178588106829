import { FunctionComponent } from "react";

import { Spinner, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { User } from "@stores/core/models/User.ts";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { AgentReaction } from "./ReactionForm.types.ts";

interface ReactionRecordedDetailsProps {
  reaction: AgentReaction;
}

export const ReactionRecordedDetails: FunctionComponent<
  ReactionRecordedDetailsProps
> = ({ reaction }) => {
  const theme = useTheme();
  const userId = reaction?.createLog?.createdById;
  const createdDateTime = reaction?.createLog?.createdDateTime;

  const dateFormat = (dateString: string) => {
    if (!dateString) return "";

    const date = DateTime.fromISO(dateString);
    return `${date
      .toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)
      .toLocaleLowerCase()}`;
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 4 }} wrap>
      {createdDateTime && (
        <Text
          variant="small"
          styles={{ root: { color: theme.palette.neutralPrimary } }}
        >
          {dateFormat(createdDateTime)}
        </Text>
      )}

      {userId && (
        <UserFetcher userId={userId} fallback={<Spinner />}>
          {(user: User) => (
            <Text
              variant="xSmall"
              styles={{ root: { color: theme.palette.neutralSecondary } }}
            >
              by {user.fullName}
            </Text>
          )}
        </UserFetcher>
      )}
    </Stack>
  );
};
