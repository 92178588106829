import { observer } from "mobx-react-lite";
import { FC } from "react";

import { confirm, IconButton, TooltipHost } from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { PurchaseOrderListItem } from "./PurchaseOrderList.types.ts";

type Props = {
  item: PurchaseOrderListItem;
};

export const DeletePurchaseOrderCell: FC<Props> = observer(({ item }) => {
  const { acc, notification } = useStores();

  const deletePurchaseOrder = async () => {
    const isConfirmed = await confirm({
      maxWidth: 560,
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: "Delete purchase order"
      },
      dialogContentProps: {
        title: "Confirm purchase order deletion",
        subText: `Do you want to permanently delete ${item.purchaseOrderNumber}?`
      }
    });
    if (isConfirmed) {
      try {
        await acc.deletePurchaseOrder(item.id);
        notification.success(
          notificationMessages.purchaseOrderDeleted(
            item.purchaseOrderNumber || ""
          )
        );
      } catch (e) {
        notification.error(e.message);
      }
    }
  };

  return (
    <TooltipHost content={!item.isRemovable ? "Used in invoice" : undefined}>
      <IconButton
        disabled={!item.isRemovable}
        onClick={deletePurchaseOrder}
        iconProps={{ iconName: "Delete" }}
      />
    </TooltipHost>
  );
});
