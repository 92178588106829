import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { CenteredBox, Spinner, SpinnerSize } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { SystemNotification } from "@stores/notifications/models/SystemNotification.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { NotificationContext } from "../context/NotificationContext.ts";
import { NotificationHelper } from "../context/NotificationHelper.ts";
import { SystemNotificationPanelDetails } from "./SystemNotificationPanelDetails.tsx";

export const SystemNotificationPanel: FunctionComponent = observer(() => {
  const root = useStores();
  const { notification } = root;

  return (
    <DataFetcher<SystemNotification[]>
      fetch={async ({ notification, core }) => {
        const notifications = await notification.getNotifications({
          userId: core.userId
        });
        notification.getUnreadNotificationsCount(core.userId);
        return notifications;
      }}
      renderError={error => {
        notification.error(error.message);
        return undefined;
      }}
      fallback={
        <CenteredBox styles={{ root: { width: 36 } }}>
          <Spinner size={SpinnerSize.small} />
        </CenteredBox>
      }
    >
      {(notifications: SystemNotification[]) => {
        if (!notifications) {
          return;
        }

        const model = new NotificationHelper(root);
        return (
          <NotificationContext.Provider value={model}>
            <SystemNotificationPanelDetails />
          </NotificationContext.Provider>
        );
      }}
    </DataFetcher>
  );
});
