import { observer } from "mobx-react-lite";

import {
  TerminologyTagPickerField,
  TerminologyTagPickerFieldProps
} from "./TerminologyTagPickerField.tsx";

interface ReasonForVisitPickerFieldProps
  extends Omit<
    TerminologyTagPickerFieldProps,
    "isReasonForVisit" | "isDiagnosis" | "isProcedure" | "isCauseOfDeath"
  > {
  label?: string;
  hideLabel?: boolean;
}

const ReasonForVisitPickerFieldComponent: React.FC<
  ReasonForVisitPickerFieldProps
> = props => (
  <TerminologyTagPickerField
    {...props}
    isReasonForVisit
    label={props.label ?? "Reason for consult"}
  />
);

export const ReasonForVisitPickerField = observer(
  ReasonForVisitPickerFieldComponent
);
