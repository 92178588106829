import { FunctionComponent } from "react";

import { IStackProps, Stack } from "@bps/fluent-ui";

export const PrescribingHighlightStack: FunctionComponent<
  IStackProps
> = props => {
  return (
    <Stack
      grow
      verticalFill
      styles={(props, theme) => ({
        root: {
          overflowY: "auto",
          background: theme.palette.neutralLighterAlt,
          padding: 8
        }
      })}
      {...props}
    />
  );
};
