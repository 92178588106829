import { TransactionView } from "@modules/billing/screens/shared-components/TransactionView.tsx";
import { Refund } from "@stores/billing/models/Refund.ts";

import { RefundDetails } from "./RefundDetails.tsx";
import { RefundViewButtons } from "./RefundViewButtons.tsx";

interface RefundViewProps {
  refund: Refund;
}

export const RefundView: React.FC<RefundViewProps> = ({ refund }) => {
  return (
    <TransactionView
      extraDetailsContent={<RefundDetails refund={refund} />}
      buttons={<RefundViewButtons refund={refund} />}
      allocationOptions={{ hide: true }}
      transaction={refund}
    />
  );
};
