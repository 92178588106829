import { Length, maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { FullBodyFormValues } from "../FullBodyClinicalDataForm.types.ts";

export class FullBodyClinicalDataValidator extends Validator<FullBodyFormValues> {
  constructor() {
    super();
    this.forField("examinationComment", [maxLength(Length.comments)]);
  }
}
