import {
  DisplayOutcome,
  DisplayOutcomeDetail
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DischargeFormValues } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeForm.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";

import { useDischargeContext } from "./DischargeContext.tsx";

export const DisplayOutcomeResolved: React.FC = () => {
  const nameOf = nameOfFactory<DischargeFormValues>();
  const { clinical } = useStores();
  const outcomesDetails = clinical.ref.outcomesDetails.keyTextValues;

  const resolvedDischargerOutcomes = outcomesDetails.filter(
    x =>
      x.key === DisplayOutcomeDetail.FullRecovery ||
      x.key === DisplayOutcomeDetail.SelfManageToFullRecovery
  );

  const { isViewOnly } = useDischargeContext();

  return (
    <div id={DisplayOutcome.Resolved}>
      <ChoiceGroupField
        name={nameOf("dischargeOutcomeResolved")}
        options={resolvedDischargerOutcomes}
        label="Additional comments"
        disabled={isViewOnly}
      />
    </div>
  );
};
