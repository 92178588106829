import { observer } from "mobx-react-lite";

import { Header } from "@bps/fluent-ui";
import { QRCodeDialog } from "@modules/forms/components/QRCodeDialog.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { IFrameDialog } from "@ui-components/IFrameDialog.tsx";

export const FormDesignPreview = observer(() => {
  const { formDesign } = useStores();

  const preview = formDesign.ui.showFormDesignPreview;
  const qr = formDesign.ui.showFormDesignPreviewQR;

  const onClose = () => {
    formDesign.ui.setFormDesignPreview(undefined);
  };

  const title = `Form preview - ${preview?.name ?? ""}`;

  if (!preview || !preview.previewURL) return null;

  if (qr) {
    return (
      <QRCodeDialog
        hostingUrl={preview.previewURL.replace("preview/", "preview")}
        onClose={onClose}
        title={title}
      />
    );
  }

  return (
    <IFrameDialog
      onClose={onClose}
      url={preview.previewURL}
      title={<Header>{title}</Header>}
      styles={{ root: { iframe: { height: "80vh", width: "80vw" } } }}
    />
  );
});
