import { useField } from "react-final-form";

import {
  DisplayOutcome,
  DisplayOutcomeDetail
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DischargeFormValues } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeForm.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { useDischargeContext } from "./DischargeContext.tsx";

export const DisplayOutcomePartiallyResolved: React.FC = () => {
  const nameOf = nameOfFactory<DischargeFormValues>();
  const { clinical } = useStores();
  const outcomesDetails = clinical.ref.outcomesDetails.keyTextValues;

  const partiallyResolvedDischargerOutcomes = outcomesDetails
    .filter(
      x =>
        x.key === DisplayOutcomeDetail.HappyToSelfManage ||
        x.key === DisplayOutcomeDetail.NecessaryOngoingManagementPlans ||
        x.key === DisplayOutcomeDetail.SpecialistReferral
    )
    .map(outcomeDetail => ({
      label: outcomeDetail.text,
      value: outcomeDetail.key
    }));

  const { isViewOnly } = useDischargeContext();

  const {
    input: { value: dischargeOutcomePartiallyResolved }
  } = useField<DischargeFormValues["dischargeOutcomePartiallyResolved"]>(
    "dischargeOutcomePartiallyResolved"
  );

  return (
    <div id={DisplayOutcome.PartiallyResolved}>
      <Fieldset tokens={{ childrenGap: 8 }}>
        <CheckboxGroupField
          name={nameOf("dischargeOutcomePartiallyResolved")}
          options={partiallyResolvedDischargerOutcomes}
          label="Additional comments"
          disabled={isViewOnly}
        />
        {(dischargeOutcomePartiallyResolved || []).some(
          value => value === DisplayOutcomeDetail.SpecialistReferral
        ) && (
          <TextInputField
            disabled={isViewOnly}
            label="Further specialist information to be returned to referrer"
            name={nameOf("specialistInformationPartiallyResolved")}
          />
        )}
      </Fieldset>
    </div>
  );
};
