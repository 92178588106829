import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";
import { Field, useField, useForm } from "react-final-form";

import { Stack, Toggle } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  Permission,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { PatientActivation } from "@modules/practice/screens/shared-components/PatientActivation.tsx";
import { getAddressFormValueFromContact } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { UserPickerField } from "@ui-components/pickers/user-picker/UserPickerField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { InvoiceFormClaimFields } from "../invoice-form-claim-fields/InvoiceFormClaimFields.tsx";
import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";
import { InvoiceAccountContactSelectField } from "./InvoiceAccountContactSelectField.tsx";
import { invoiceDetailsModalFormNameOf } from "./InvoiceDetailsModal.types";
import { InvoiceDetailsModalNewAddressFields } from "./InvoiceDetailsModalNewAddressFields.tsx";
import { InvoiceDetailsModalNotesField } from "./InvoiceDetailsModalNotesField.tsx";
import { InvoiceLicenceWarning } from "./InvoiceLicenceWarning.tsx";
import { InvoiceLocationSelectField } from "./InvoiceLocationSelectField.tsx";

interface InvoiceDetailsModalFormFieldsProps {
  isOrganisationInvoice?: boolean;
}

export const InvoiceDetailsModalFormFields: FunctionComponent<InvoiceDetailsModalFormFieldsProps> =
  observer(({ isOrganisationInvoice }) => {
    const { core, practice, booking, routing } = useStores();
    const form = useForm<InvoiceFormValues>();
    const { batch, change } = useForm<InvoiceDetailsModalFormValues>();
    const {
      input: { value: accountContactId }
    } = useField<string | undefined>(
      invoiceDetailsModalFormNameOf("accountContactId"),
      {
        subscription: { value: true }
      }
    );

    const {
      input: { value: patientId }
    } = useField<string | undefined>(
      invoiceDetailsModalFormNameOf("patientId"),
      {
        subscription: { value: true }
      }
    );

    const currentContactId = routing.match(routes.accounts.account)?.params.id;

    const { isAdjustMode, isFromCalendarEvent } =
      useContext(InvoiceFormContext);

    const toggleBillThePatient = (event: any, checked: boolean) => {
      if (checked) {
        change("accountContactId", patientId);
      } else if (patientId) {
        practice.getContact(patientId).then(patient => {
          const accountHolderId =
            patient.primaryAccountHolder?.relatedContactId || "";
          batch(() => {
            change("accountContactId", accountHolderId);
            if (!accountHolderId) change("newAddress", undefined);
          });
        });
      }
    };

    const onPatientChange = (
      patientId: string,
      isOrganisationInvoice?: boolean
    ) => {
      if (patientId) {
        practice.getContact(patientId).then((patient: Contact) => {
          batch(() => {
            change("patientFirstName", patient.firstName);
            change("patientLastName", patient.lastName);
            change("patientAddress", getAddressFormValueFromContact(patient));
            const accountHolderId =
              patient.primaryAccountHolder?.relatedContactId;
            if (!isOrganisationInvoice) {
              change("accountContactId", accountHolderId || patientId);
            }
          });
        });
      } else {
        batch(() => {
          change("patientFirstName", "");
          change("patientLastName", "");
          change("patientAddress", "");
          change(
            "accountContactId",
            isOrganisationInvoice ? currentContactId : undefined
          );
          change("newAddress", undefined);
        });
      }
    };

    const onAccountContactChange = (contactId: string | undefined) => {
      if (contactId) {
        practice.getContact(contactId).then((contact: Contact) => {
          batch(() => {
            change("accountAddress", getAddressFormValueFromContact(contact));
            change("accountContactType", contact.type);
            if (contact.isPerson) {
              change("accountFirstName", contact.firstName);
              change("accountLastName", contact.lastName);
              change("accountName", "");
            } else {
              change("accountFirstName", "");
              change("accountLastName", "");
              change("accountName", contact.name);
            }
          });
        });
      } else {
        batch(() => {
          change("accountName", "");
          change("accountFirstName", "");
          change("accountLastName", "");
          change("accountAddress", "");
          change("accountContactType", undefined);
        });
      }
    };

    const onProviderChange = (userId: string | undefined) => {
      const formState = form.getState();
      if (userId) {
        core.getUser(userId).then(user => {
          batch(() => {
            change("userLastName", user.lastName);
            change("userFirstName", user.firstName);
            change("userTitle", user.titleRef?.text || "");
            if (formState.values.calendarEventId) {
              change("locationId", formState.values.locationId);
            } else {
              change(
                "locationId",
                user.availableOrgUnitIds.length === 1
                  ? user.availableOrgUnitIds[0]
                  : ""
              );
            }
          });
        });
      } else {
        batch(() => {
          change("userLastName", "");
          change("userFirstName", "");
          change("userTitle", "");
          change("locationId", "");
        });
      }
    };

    const onCalendarEventChange = (calendarEventId: string | undefined) => {
      if (calendarEventId) {
        booking.getCalendarEvent(calendarEventId).then(calendarEvent => {
          batch(() => {
            change("userId", calendarEvent?.userId);
            change("locationId", calendarEvent?.orgUnitId);
          });
        });
      }
    };

    const billThePatient = accountContactId === patientId;

    const patientLabel = usePatientLabel();

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack horizontal tokens={{ childrenGap: 8 }} horizontalAlign="start">
          <ContactPickerFieldWithAdd
            wrapperStyles={{ root: { flexGrow: 1 } }}
            label={capitalizeSentence(patientLabel)}
            name={invoiceFormNameOf("patientId")}
            quickAddProps={{
              addButtonLabel: `New ${patientLabel}`,
              typeToAdd: ContactType.Patient,
              permission: [
                Permission.ContactRead,
                Permission.ContactWrite,
                Permission.PatientRead,
                Permission.PatientWrite
              ]
            }}
            filter={{ types: [ContactType.Patient] }}
            actionButton={{
              linkProps: {
                children: (
                  <Field<string | undefined>
                    name={invoiceFormNameOf("patientId")}
                  >
                    {props => {
                      if (!props.input.value) {
                        return null;
                      }
                      return (
                        <PatientActivation patientId={props.input.value} />
                      );
                    }}
                  </Field>
                )
              }
            }}
            required
            includeInactive
            disabled={isAdjustMode || isFromCalendarEvent}
          />
          {patientId && (
            <Toggle
              automationAttribute="bill-patient-toggle"
              onChange={toggleBillThePatient}
              label="Bill the patient"
              onText="Yes"
              offText="No"
              checked={billThePatient}
              disabled={!patientId || isAdjustMode}
              styles={{
                root: { marginTop: 4, width: 100 }
              }}
            />
          )}
        </Stack>

        {patientId && (
          <Fieldset frame>
            <InvoiceAccountContactSelectField />
            <InvoiceDetailsModalNewAddressFields />
          </Fieldset>
        )}
        <WhenCountry is={Country.NewZealand}>
          <When
            permission={[Permission.ClaimRead, Permission.AppointmentTypeRead]}
            permissionOperator="and"
          >
            <InvoiceFormClaimFields />
          </When>
        </WhenCountry>

        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <UserPickerField
            currentUserOnTop
            required
            filter={{
              statusCodes: [UserStatus.Active, UserStatus.Inactive]
            }}
            label="Provider"
            name={invoiceFormNameOf("userId")}
            onResolveSuggestionItem={user => ({
              text: user.fullName
            })}
            disabled={isAdjustMode}
            fieldItemStyle={{
              root: { flexGrow: 1, flexBasis: 0, minWidth: 0 }
            }}
          />

          {core.hasMultipleActiveLocations && <InvoiceLocationSelectField />}
        </Stack>
        <InvoiceLicenceWarning />
        <InvoiceDetailsModalNotesField />
        <FieldSpy
          name={invoiceFormNameOf("patientId")}
          onChange={patientId =>
            onPatientChange(patientId, isOrganisationInvoice)
          }
        />
        <FieldSpy
          name={invoiceFormNameOf("accountContactId")}
          onChange={onAccountContactChange}
        />
        <FieldSpy
          name={invoiceFormNameOf("userId")}
          onChange={onProviderChange}
        />
        <FieldSpy
          name={invoiceFormNameOf("calendarEventId")}
          onChange={onCalendarEventChange}
        />
      </Stack>
    );
  });
