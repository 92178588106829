import {
  DirectionalHint,
  FontIcon,
  FontSizes,
  IStyleFunctionOrObject,
  Stack,
  StackItem,
  StateBlock,
  StateBlockStyleProps,
  StateBlockStyles,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ClaimManagementModalFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionDetailsModel } from "./ConditionsSidePanelHelper.ts";
import { DischargeOverview } from "./DischargeOverview.tsx";

interface ConditionOverviewProps {
  model: ConditionDetailsModel;
}

export const ConditionOverview: React.FC<ConditionOverviewProps> = ({
  model
}) => {
  const stateBlockStyles: IStyleFunctionOrObject<
    StateBlockStyleProps,
    StateBlockStyles
  > = {
    root: { padding: "6px 16px" }
  };

  const referraledBlockStyles: IStyleFunctionOrObject<
    StateBlockStyleProps,
    StateBlockStyles
  > = {
    root: {
      border: "none",
      padding: 0
    }
  };

  const getReferralMessage = (model: ConditionDetailsModel): string => {
    let message = "";
    if (
      model.condition?.referralProvider === undefined &&
      model.condition?.referralDate === undefined &&
      model.condition?.referralNumber === undefined
    ) {
      return "Referred in, no further info";
    }

    if (model.condition?.referralProvider) {
      message += model.condition?.referralProvider;
    }
    if (model.condition?.referralDate) {
      const dateString = DateTime.fromJSDate(
        model.condition.referralDate
      ).toDayDefaultFormat();
      message += ` on ${dateString}`;
    }
    if (model.condition?.referralNumber) {
      if (message.length !== 0) {
        message += `, ${model.condition?.referralNumber}`;
      } else {
        message += model.condition?.referralNumber;
      }
    }

    return message;
  };

  const theme = useTheme();

  return (
    <Stack>
      <DischargeOverview condition={model.condition!} />
      <Stack tokens={{ childrenGap: 8 }}>
        {model.referralIn ? (
          <Stack
            styles={{
              root: {
                fontSize: FontSizes.size14,
                border: "1px solid",
                borderColor: theme.palette.neutralLight,
                padding: "12px 16px"
              }
            }}
          >
            <StateBlock
              styles={referraledBlockStyles}
              labelText={ClaimManagementModalFormLabels.referralFrom}
              descText={
                <Stack horizontal>
                  <TooltipHost content="Referral">
                    <FontIcon
                      iconName="FollowUser"
                      styles={{
                        root: {
                          color: theme.palette.black,
                          fontSize: FontSizes.size16,
                          marginRight: 8
                        }
                      }}
                    />
                  </TooltipHost>
                  {getReferralMessage(model)}
                </Stack>
              }
            />
            <Stack horizontal>
              <StackItem align="start" styles={{ root: { paddingRight: 32 } }}>
                <StateBlock
                  styles={referraledBlockStyles}
                  labelText={ClaimManagementModalFormLabels.initialConsult}
                  descText={model?.initialConsultDate ?? "–"}
                />
              </StackItem>

              <StackItem align="start">
                {model?.condition?.claim?.insurerContactId && (
                  <ContactFetcher
                    contactId={model?.condition?.claim?.insurerContactId}
                  >
                    {contact => (
                      <StateBlock
                        styles={referraledBlockStyles}
                        labelText={ClaimManagementModalFormLabels.insurer}
                        descText={
                          <TooltipHost
                            directionalHint={DirectionalHint.leftCenter}
                            content={contact.name}
                          >
                            {contact.name.split("-")[0]}
                          </TooltipHost>
                        }
                      />
                    )}
                  </ContactFetcher>
                )}
              </StackItem>
            </Stack>
          </Stack>
        ) : (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <StateBlock
              styles={stateBlockStyles}
              labelText={ClaimManagementModalFormLabels.referral}
              descText={ClaimManagementModalFormLabels.notReferred}
            />
            {model?.condition?.claim &&
              (model?.condition?.claim?.insurerContactId ? (
                <DataFetcher
                  refetchId={model?.condition?.claim?.insurerContactId}
                  fetch={({ practice }) =>
                    practice.getContact(
                      model?.condition?.claim?.insurerContactId!
                    )
                  }
                >
                  {(contact: Contact) => (
                    <StateBlock
                      styles={{
                        root: { padding: "6px 16px", width: 0 },
                        wrapper: { width: "100%" },
                        description: {
                          fontSize: 14,
                          width: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis"
                        }
                      }}
                      labelText={ClaimManagementModalFormLabels.insurer}
                      descText={
                        <TooltipHost
                          directionalHint={DirectionalHint.leftCenter}
                          content={contact.name}
                        >
                          {contact.name}
                        </TooltipHost>
                      }
                    />
                  )}
                </DataFetcher>
              ) : (
                <StateBlock
                  styles={stateBlockStyles}
                  labelText={ClaimManagementModalFormLabels.insurer}
                  descText="–"
                />
              ))}
            <StateBlock
              styles={stateBlockStyles}
              labelText={ClaimManagementModalFormLabels.initialConsult}
              descText={model?.initialConsultDate ?? "–"}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
