import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  DataFetcherProps
} from "@ui-components/data-fetcher/DataFetcher.tsx";

type Props = Omit<
  DataFetcherProps<{ accountTotals: AccountBalance }>,
  "fetch"
> & {
  accountId: string;
};

export const AccountTotalDataFetcher = (props: Props) => {
  const fetch = async ({ core, billing }: RootStore) => {
    const accountTotals = core.hasPermissions(Permission.AccountHistoryAllowed)
      ? await billing.getAccountBalanceForAccountId(props.accountId)
      : undefined;

    return { accountTotals };
  };

  return (
    <DataFetcher<{ accountTotals?: AccountBalance }> fetch={fetch} {...props} />
  );
};
