import { AccScheduleTransactionStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  TransactionFilterBase,
  TransactionFilterQueryBase
} from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/TransactionFilterBase.types.ts";

export interface AccInvoicesFilterQuery
  extends Pick<
    TransactionFilterQueryBase,
    "claimStatuses" | "search" | "userIds" | "endTime"
  > {
  transactionStatuses?:
    | AccScheduleTransactionStatuses[]
    | AccScheduleTransactionStatuses;
  serviceStartTime?: string;
  serviceEndTime?: string;
  actionRequired?: true;
  isOwing?: boolean;
  hasStatusReason?: boolean;
}

export interface AccInvoicesFilter
  extends Pick<
    TransactionFilterBase,
    "claimStatuses" | "search" | "userIds" | "endTime"
  > {
  transactionStatuses?: AccScheduleTransactionStatuses[];
  serviceStartTime?: Date | undefined;
  serviceEndTime?: Date | undefined;
  actionRequired?: boolean | undefined;
  isOwing: boolean | undefined;
  hasStatusReason?: boolean | undefined;
}

export enum ApplyToDateKeys {
  transactionDate = "TD",
  serviceDate = "SD"
}

export const accInvoicesFilterNameOf = nameOfFactory<AccInvoicesFilter>();
export const defaultAccInvoicesFilter = Object.freeze({
  search: undefined,
  transactionStatuses: [],
  claimStatuses: [],
  serviceStartTime: undefined,
  serviceEndTime: undefined,
  userIds: [],
  actionRequired: undefined,
  isOwing: undefined,
  hasStatusReason: undefined
});
