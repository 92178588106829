import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { PracticeDetailsValues } from "./PracticeDetailsForm.types.tsx";

// Removes the underscores that form part of value in a masked textbox
/* eslint no-template-curly-in-string: "off" */
const requiredNumLengthOnMaskedField =
  (length: number): FieldValidator =>
  value =>
    value &&
    value.replace(/_/gi, "") &&
    parseFloat(value).toString().length !== length
      ? messageWithData(ValidationMessages.requiredNumLength, length.toString())
      : undefined;

export class PracticeDetailsValidator extends Validator<PracticeDetailsValues> {
  constructor() {
    super();
    this.forField("businessName", [required(), maxLength(100)]);
    this.forField("hpiOrganisationId", maxLength(8));
    this.forField("abn", [requiredNumLengthOnMaskedField(11)]);
    this.forField("nzbn", [requiredNumLengthOnMaskedField(13)]);
    this.forField("tagLine", maxLength(150));
    this.forField("hpiOrganisationName", maxLength(40));
  }
}
