import React from "react";

import { useTheme } from "@bps/fluent-ui";
import { useAccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { getPDFStyles } from "@ui-components/document-viewer/pdf.styles.ts";
import { DocumentPrint } from "@ui-components/printing/DocumentPrint.tsx";

export const StatementPrint: React.FC = () => {
  const { notification, billing } = useStores();
  const theme = useTheme();
  const { noMarginsLandscapePdfstyles } = getPDFStyles(theme);

  const { setIsStatementPrinting, selectedStatement, accountContact } =
    useAccountScreenContext();

  const completePrinting = () => {
    setIsStatementPrinting(false);
  };

  if (!selectedStatement?.id) return null;

  const getDocumentData = async () => {
    notification.warn("Preparing to print");
    return (
      await billing.getBillingStoredDocumentUrl({
        contactId: accountContact.id,
        statementId: selectedStatement.id
      })
    ).url;
  };

  return (
    <DocumentPrint
      pageStyle={noMarginsLandscapePdfstyles}
      completePrinting={completePrinting}
      documentTitle={selectedStatement.id}
      getDocumentData={getDocumentData}
    />
  );
};
