import { useRef, useState } from "react";

import {
  Callout,
  DirectionalHint,
  GroupedList,
  Heading,
  IGroupHeaderProps,
  Link,
  Stack
} from "@bps/fluent-ui";

import { useTemplateFormContext } from "./template-form/context/TemplateFormContext.tsx";

export interface AddTemplateInsertTagCalloutProps {
  handleInsert: (item: string) => void;
}

export const AddTemplateInsertTagCallout: React.FC<
  AddTemplateInsertTagCalloutProps
> = ({ handleInsert }) => {
  const [showInsertTagCallout, setInsertTagShowCallout] = useState(false);
  const { tags } = useTemplateFormContext();
  const divRef = useRef<HTMLElement | null>(null);

  const renderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
    if (props) {
      return (
        <Heading
          styles={{ root: { margin: "0px 0px 8px 0px" } }}
          variant="section-sub-heading"
        >
          {props.group!.name}
        </Heading>
      );
    }

    return null;
  };

  const renderCell = (nestingDepth?: number, item?: any): React.ReactNode => {
    return item ? (
      <Link
        styles={{ root: { textAlign: "left" } }}
        onClick={() => {
          handleInsert(item.name);
        }}
      >
        {item.text}
      </Link>
    ) : null;
  };

  return (
    <>
      <Link
        ref={divRef}
        styles={{
          root: { align: "flex-start", width: 85 }
        }}
        onClick={() => {
          setInsertTagShowCallout(state => !state);
        }}
        as="button"
      >
        # Insert field
      </Link>

      <Callout
        hidden={!showInsertTagCallout}
        directionalHint={DirectionalHint.bottomLeftEdge}
        target={divRef}
        onDismiss={() => {
          setInsertTagShowCallout(state => !state);
        }}
        calloutMaxHeight={420}
      >
        <Stack verticalFill styles={{ root: { padding: "20px 24px" } }}>
          <GroupedList
            styles={{
              root: {
                selectors: {
                  ".ms-GroupHeader": {
                    marginTop: 15
                  }
                }
              }
            }}
            compact={true}
            groups={[
              {
                key: "Patient",
                name: "Patient",
                count: 4,
                startIndex: 0
              },
              {
                key: "Appointments",
                name: "Appointments",
                count: 4,
                startIndex: 4
              },
              {
                key: "Provider",
                name: "Provider",
                count: 4,
                startIndex: 8
              },
              {
                key: "Practice",
                name: "Practice",
                count: 4,
                startIndex: 12
              }
            ]}
            items={tags}
            onRenderCell={renderCell}
            groupProps={{
              showEmptyGroups: true,
              onRenderHeader: renderHeader
            }}
          />
        </Stack>
      </Callout>
    </>
  );
};
