import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { FieldItemError, ScrollablePane, Spinner } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import {
  ClaimAdjustmentDocumentStatuses,
  DOCUMENT_FILE_SIZE_LIMIT
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentDocumentFormValue } from "@shared-types/acc/claim-adjustment-document-value.type.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { useInfiniteScroll } from "@ui-components/InfiniteScrollList/useInfiniteScroll.ts";
import { ScrollListener } from "@ui-components/ScrollListener.tsx";

export interface AttachCorrespondeceFormValues {
  ids: string[];
}

interface AttachCorrespondenceFormFieldsProps {
  previouslySelectedDocuments?: ClaimAdjustmentDocumentFormValue[];
}

export const getDisableReason = (
  document: ClinicalDocument,
  previouslySelectedDocuments?: ClaimAdjustmentDocumentFormValue[]
) => {
  const disabledReasons = [];

  if (document.size > DOCUMENT_FILE_SIZE_LIMIT) {
    disabledReasons.push("over 20MB");
  }
  if (document.extension !== "pdf") disabledReasons.push("not PDF");
  if (
    !!previouslySelectedDocuments?.find(
      prevDoc => prevDoc.documentId === document.id
    )
  )
    disabledReasons.push("already selected");

  const reason = disabledReasons.join(", and ");

  return !!reason.length
    ? ` (${reason[0].toUpperCase()}${reason.substring(1)})`
    : "";
};

const attachCorrespondeceFormValuesFormNameOf =
  nameOfFactory<AttachCorrespondeceFormValues>();

export const getClaimDocumentFormValues = (
  documents: ClinicalDocument[]
): ClaimAdjustmentDocumentFormValue[] => {
  return documents.map(x => ({
    id: newGuid(),
    documentId: x.id,
    extension: x.extension,
    fileName: x.name,
    status: ClaimAdjustmentDocumentStatuses.AttachedFromCorrespondence
  }));
};

export const getDocumentDropdownItems = (
  clinicalDocuments: ClinicalDocument[],
  previouslySelectedDocuments?: ClaimAdjustmentDocumentFormValue[]
) => {
  return clinicalDocuments.map(x => {
    const disableReason = getDisableReason(x, previouslySelectedDocuments);
    return {
      label: `${x.name} - ${x.date?.toDayDefaultFormat()}${disableReason}`,
      value: x.id,
      checkboxProps: { id: x.id },
      disabled: disableReason !== ""
    };
  });
};

export const AttachCorrespondenceFormFields: React.FC<AttachCorrespondenceFormFieldsProps> =
  observer(({ previouslySelectedDocuments }) => {
    const { correspondence } = useStores();

    const { claim } = useClaimAdjustmentContext();

    const getItems = useCallback(
      (query: PagingOptions) => {
        return correspondence.fetchCorrespondence({
          ...query,
          patientId: claim.patientId ?? ""
        });
      },
      [claim.patientId, correspondence]
    );

    const { handleScrolledToBottom, isLoading, searchResults } =
      useInfiniteScroll({
        getItems
      });

    return (
      <>
        <div style={{ position: "relative", height: 216 }}>
          <ScrollablePane
            styles={{
              root: {
                height: "auto"
              }
            }}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <CheckboxGroupField
                name={attachCorrespondeceFormValuesFormNameOf("ids")}
                options={
                  searchResults.value
                    ? getDocumentDropdownItems(
                        searchResults.value.results,
                        previouslySelectedDocuments
                      )
                    : []
                }
              />
            )}

            <ScrollListener
              searchResults={searchResults}
              onScrolledToBottom={handleScrolledToBottom}
            />
          </ScrollablePane>
        </div>
        <FieldSpy
          name={attachCorrespondeceFormValuesFormNameOf("ids")}
          subscription={{ invalid: true }}
        >
          {props => (
            <>
              {props.invalid && (
                <FieldItemError
                  name="too-many-documents"
                  errorMessage="A total of six documents can be selected."
                />
              )}
            </>
          )}
        </FieldSpy>
      </>
    );
  });
