import { IChoiceGroupOption, Stack } from "@bps/fluent-ui";
import { WriteOffReason } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { WriteOff } from "@stores/billing/models/WriteOff.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

export interface CancelDialogProps {
  hidden: boolean;
  writeOff: WriteOff;
  onDismiss: () => void;
  onSubmitSucceeded?: () => void;
}

interface CanceWriteOffFormValues {
  reason: string;
}

export const CancelWriteOffDialog: React.FC<CancelDialogProps> = ({
  writeOff,
  hidden,
  onDismiss,
  onSubmitSucceeded
}) => {
  const { billing } = useStores();

  if (hidden) {
    return null;
  }

  const onSubmit = async (values: CanceWriteOffFormValues) => {
    await billing.cancelWriteOff({ id: writeOff.id, reason: values.reason });
  };

  const initialValues = { reason: WriteOffReason.EnteredInError };

  const options: IChoiceGroupOption[] = [
    {
      key: WriteOffReason.EnteredInError,
      text: WriteOffReason.EnteredInError
    },
    { key: WriteOffReason.InvoicePaid, text: WriteOffReason.InvoicePaid }
  ];

  return (
    <SubmissionFormDialog<CanceWriteOffFormValues>
      dialogName="Cancel write-off"
      onSubmit={onSubmit}
      onSubmitSucceeded={() => onSubmitSucceeded && onSubmitSucceeded()}
      initialValues={initialValues}
      dialogProps={{
        minWidth: 430,
        maxWidth: 430,
        onDismiss,
        dialogContentProps: {
          title: `Cancelling write-off ${writeOff.number}`
        }
      }}
      buttonsProps={{
        disableSubmitOnPristine: false,
        submitButtonProps: {
          text: "Cancel write-off",
          iconProps: {
            hidden: true
          }
        },
        cancelButtonProps: {
          onClick: onDismiss
        }
      }}
    >
      {() => (
        <Stack tokens={{ childrenGap: 16 }}>
          <ChoiceGroupField
            name="reason"
            options={options}
            label="Reason"
            required
          />
        </Stack>
      )}
    </SubmissionFormDialog>
  );
};
