import { isEqual } from "lodash";
import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  AnalysisAndPlanClinicalDataDto,
  ClinicalDataType,
  CurrentInjuryClinicalDataDto,
  CustomClinicalToolClinicalDataDto,
  CustomClinicalToolContextClinicalDataDto,
  CustomClinicalToolContextDto,
  CustomClinicalToolDto,
  DiagnosesClinicalDataDto,
  EncounterClinicalDataDto,
  EncounterStatus,
  FollowUpNotesClinicalDataDto,
  InjuryArea,
  InjuryAreaClinicalDataDto,
  InjuryClinicalDataDto,
  InjuryImageType,
  MeasurementDto,
  MeasurementType,
  OtherMovementsDto,
  PatientTreatmentPlanClinicalDataDto,
  PhysicalActivityClinicalDataDto,
  PostureOrObservationsDto,
  PSFSActivityItemDto,
  PSFSContextClinicalDataItemDto,
  TreatmentAndEducationClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { GoalDataItem } from "@shared-types/clinical/goal-data-item.interface.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import {
  getClinicalDataLastUpdatedDate,
  removeUndefinedProperties
} from "@stores/clinical/utils/clinical.utils.ts";

import { OutcomeMeasuresCardModel } from "../outcome-measure/OutcomeMeasuresCardModel.ts";
import {
  convertGoals,
  getDiagnoses,
  getMappedDiagnoses,
  getReasonForVisit,
  getTreatmentData
} from "../SOTAP.utils.ts";
import { InjuryImagesHelper } from "./InjuryImagesHelper.tsx";
import {
  ElbowImageContent,
  FootImageContent,
  HipImageContent,
  KneeImageContent,
  ShoulderImageContent
} from "./InjuryImagesHelper.types.ts";
import { SOTAPFormCommonModel } from "./SOTAPFormCommonModel.ts";

export interface EncounterItem {
  id: string;
  date: Date;
}

export class SOTAPFollowOnFormModel extends SOTAPFormCommonModel {
  injuryAreaData: InjuryAreaClinicalDataDto[] = [];
  injuryArea?: InjuryAreaClinicalDataDto;
  priorInjuryAreaMotionAssesments?: InjuryAreaMotionAssessmentItem[];
  latestEncounterDate: Date;
  psfsQuestions: string[] = [];
  psfsGoalsAdded: boolean | undefined = false;
  linkedMeasurements: MeasurementDto[];
  loadPsfsQuestions = async () => {
    const model = new OutcomeMeasuresCardModel(this.clinicalRecord);
    let context: PSFSContextClinicalDataItemDto | undefined;
    const { questionnaire, data } = await model.getComponentData(
      ClinicalDataType.PSFS
    );

    if (data) {
      const contextData = this.clinicalRecord.clinicalData?.psfsContext;
      context = contextData?.contexts.find(c => c.contextId === data.contextId);
      if (questionnaire) {
        questionnaire.items.forEach(item => {
          let question: string | undefined;
          if (context) {
            const activity = context.activities.find(
              a => a.questionnaireItemId === item.id
            );
            if (activity) {
              question = activity.text;
              this.psfsQuestions.push(question);
            }
          }
        });
      }
    }
  };

  loadInjuryAreaData = async () => {
    await this.clinicalRecord.loadlinkedEncounters();
    const linkedEncounters = this.clinicalRecord.linkedEocEncounters;
    let content: EncounterItem[] | undefined;

    if (linkedEncounters && linkedEncounters.length > 0) {
      const sortedEncounters = linkedEncounters.sort(
        (a: Encounter, b: Encounter) => {
          return 0 - (a.startDateTime > b.startDateTime ? 1 : -1);
        }
      );

      if (sortedEncounters.length > 0) {
        const closedEncounters = sortedEncounters.filter(
          x => x.dto.status === EncounterStatus.Closed
        );

        if (closedEncounters && closedEncounters.length > 0) {
          const latestClosed = closedEncounters[0];
          const latestClinicalData =
            await this.clinicalRecord.clinical.getEncounterClinicalData({
              encounterId: latestClosed.id,
              types: [ClinicalDataType.InjuryArea]
            });

          this.latestEncounterDate = DateTime.jsDateFromISO(
            latestClosed.dto.visitDateTime
          );

          if (latestClinicalData.injuryArea) {
            const injuryAreaData = latestClinicalData.injuryArea;

            const { injuryAreaMotionAssessmentItems } =
              InjuryImagesHelper.getMotionAndImageAssesments(injuryAreaData);

            this.priorInjuryAreaMotionAssesments =
              injuryAreaMotionAssessmentItems;
          }

          const topEncounters = sortedEncounters.slice(0, 8);
          content = topEncounters.map(item => {
            return {
              id: item.id,
              date: item.startDateTime.toJSDate()
            } as EncounterItem;
          });
        }
      }
    }

    if (content && content.length > 0) {
      const promises = content.map(encounterItem =>
        this.clinicalRecord.clinical
          .getEncounterClinicalData(
            { encounterId: encounterItem.id },
            { ignoreCache: true }
          )
          .then(encounter => {
            if (encounter?.injuryArea) {
              this.injuryAreaData!.push(encounter.injuryArea);
            }
          })
      );
      await Promise.all(promises);
    }

    this.injuryArea =
      this.injuryAreaData && this.injuryAreaData.length > 0
        ? this.injuryAreaData[0]
        : undefined;
  };

  loadCurrentMeasurement = async () => {
    const allMeasurementsPromise = this.clinicalRecord.clinical.getMeasurements(
      {
        patientId: this.clinicalRecord.patient?.id,
        types: [MeasurementType.PSFS]
      }
    );

    const openEncEoCId = this.clinicalRecord.openEncounter?.episodeOfCareId;
    const encounterResponsePromise = this.clinicalRecord.clinical.getEncounters(
      {
        episodeOfCareIds: openEncEoCId ? [openEncEoCId] : undefined,
        patientId: this.clinicalRecord.id
      }
    );

    const [allMeasurements, encounterResponse] = await Promise.all([
      allMeasurementsPromise,
      encounterResponsePromise
    ]);

    const eocEncounterIds = this.clinicalRecord
      .filterEncountersOnCurrentRole(encounterResponse)
      .map(e => e.id);

    this.linkedMeasurements = allMeasurements.results.filter(x =>
      eocEncounterIds.includes(x.encounterId)
    );
  };

  private getPSFSGoals = (
    activities: PSFSActivityItemDto[]
  ): GoalDataItem[] => {
    const goals: GoalDataItem[] = [];
    activities.map(question =>
      goals.push({
        goal: question.text,
        isAchieved: false,
        startDate: DateTime.today().toJSDate()
      })
    );

    return goals;
  };

  @computed
  get initialSOTAPFollowOnFormValues(): SOTAPFormValues {
    const episodeOfCare = this.clinicalRecord.episodeOfCare;
    const followUpNotesData = this.clinicalRecord.clinicalData?.followUpNotes;

    const analysisAndPlanData =
      this.clinicalRecord.clinicalData?.analysisAndPlan;

    const postureOrObservations =
      this.clinicalRecord.clinicalData?.postureOrObservations
        ?.postureOrObservations;

    const otherMovements =
      this.clinicalRecord.clinicalData?.otherMovements?.otherMovements;

    const injuryAreaData = this.clinicalRecord.clinicalData?.injuryArea;
    const { injuryAreaMotionAssessmentItems, injuryAreaImageItems } =
      InjuryImagesHelper.getInjuryAreaData(
        injuryAreaData,
        this.clinicalRecord.clinical.ref
      );

    const currentInjuryData = this.clinicalRecord.clinicalData?.currentInjury;
    const treatmentAndEducationData =
      this.clinicalRecord.clinicalData?.treatmentAndEducation;

    const treatmentPlanData =
      this.multiProviderHelper.getInitialValueTreatmentPlanData();

    const patient = this.clinicalRecord.patient;

    let dateOfInjury = DateTime.jsDateFromISO(currentInjuryData?.dateOfInjury);

    if (!dateOfInjury)
      dateOfInjury = DateTime.jsDateFromISO(episodeOfCare?.injuryDate);

    const goals: GoalDataItem[] =
      convertGoals(treatmentPlanData?.goals ?? []) || [];

    const planTreatments = getTreatmentData(
      treatmentPlanData?.treatments,
      false
    );

    const planTreatmentsBase = planTreatments.map(x => x.treatment);

    const linkedContextId =
      this.linkedMeasurements.length > 0
        ? this.linkedMeasurements[0]?.contextId
        : undefined;

    const psfsContext =
      this.clinicalRecord.clinicalData?.psfsContext?.contexts.find(
        context => context.contextId === linkedContextId
      );

    const lastestPsfsContextActivities = psfsContext?.activities;

    if (
      !goals.length &&
      !treatmentPlanData?.psfsGoalsAdded &&
      lastestPsfsContextActivities?.length
    ) {
      this.psfsGoalsAdded = true;
      goals.push(...this.getPSFSGoals(lastestPsfsContextActivities));
    }

    const { answersArray, columns } = this.getCustomClinicalTools(
      this.clinicalRecord,
      this.customClinicalToolData
    );

    const { injuryAreas } = InjuryImagesHelper.getInjuryAreaData(
      this.injuryArea,
      this.clinicalRecord.clinical.ref
    );

    const injuryData = this.clinicalRecord.clinicalData?.injury;

    let spinalCordFrontImage: string | undefined;
    let spinalCordSideImage: string | undefined;
    let handsWristsImage: string | undefined;

    let kneeImageContent: KneeImageContent | undefined;
    let hipImageContent: HipImageContent | undefined;

    let footImageContent: FootImageContent | undefined;
    let shoulderImageContent: ShoulderImageContent | undefined;
    let elbowImageContent: ElbowImageContent | undefined;

    if (injuryAreaImageItems && injuryAreaImageItems.length > 0) {
      const spinalCordFront = injuryAreaImageItems.find(
        x =>
          x.injuryArea === InjuryArea.Spine &&
          x.type === InjuryImageType.SpinalCordFront
      );
      if (spinalCordFront) {
        spinalCordFrontImage = atob(spinalCordFront.content);
      }

      const spinalCordSide = injuryAreaImageItems.find(
        x =>
          x.injuryArea === InjuryArea.Spine &&
          x.type === InjuryImageType.SpinalCordSide
      );
      if (spinalCordSide) {
        spinalCordSideImage = atob(spinalCordSide.content);
      }

      const handsWrists = injuryAreaImageItems.find(
        x =>
          x.injuryArea === InjuryArea.HandAndWrist &&
          x.type === InjuryImageType.HandsWrists
      );
      if (handsWrists) {
        handsWristsImage = atob(handsWrists.content);
      }

      if (injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Knee)) {
        kneeImageContent = InjuryImagesHelper.getKneeImageContents(
          injuryAreaImageItems,
          patient?.sex
        );
      }

      if (
        injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.AnkleFoot)
      ) {
        footImageContent =
          InjuryImagesHelper.getFootImageContents(injuryAreaImageItems);
      }

      if (injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Hip)) {
        hipImageContent = InjuryImagesHelper.getHipImageContents(
          injuryAreaImageItems,
          patient?.sex
        );
      }

      if (
        injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Shoulder)
      ) {
        shoulderImageContent = InjuryImagesHelper.getShoulderImageContent(
          injuryAreaImageItems,
          patient?.sex
        );
      }

      if (injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Elbow)) {
        elbowImageContent = InjuryImagesHelper.getElbowImageContent(
          injuryAreaImageItems,
          patient?.sex
        );
      }
    }

    const treatments = getTreatmentData(
      treatmentAndEducationData?.treatments,
      true
    );

    const treatmentsBase = treatments.map(x => x.treatment);

    const diagnosisClinicalData = this.clinicalRecord.clinicalData?.diagnoses;

    const physicalActivity =
      this.clinicalRecord.stashedClinicalData?.physicalActivity;

    const { primaryDiagnoses, additionalDiagnoses, multipleDiagnoses } =
      getDiagnoses(this.clinicalRecord, diagnosisClinicalData);

    const treatmentDate = getClinicalDataLastUpdatedDate(
      this.clinicalRecord.clinicalData?.patientTreatmentPlan
    )?.toDayDefaultFormat();

    return {
      activities: [],
      dominantHand: physicalActivity?.dominantHand,
      specialQuestionsAllClear: false,
      vBIsAllClear: false,
      psychosocialFlagsAllClear: false,
      primaryDiagnosis: primaryDiagnoses,
      additionalDiagnoses,
      multipleDiagnoses,
      diagnosisComment: diagnosisClinicalData?.diagnosisComment,
      progression: followUpNotesData?.progression,
      followUpNotesComment: followUpNotesData?.followUpNotesComment,
      postureOrObservations,
      otherMovements,
      injuryAreas,
      injuryAreaMotionAssessments: injuryAreaMotionAssessmentItems,
      injuryAreaImages: injuryAreaImageItems,
      priorInjuryAreaMotionAssesments: this.priorInjuryAreaMotionAssesments,
      priorInjuryRecordedDate: this.latestEncounterDate,
      spinalCordFrontImage,
      spinalCordSideImage,
      handsWristsImage,
      rxAnalysis: analysisAndPlanData?.rxAnalysis,
      analysis: analysisAndPlanData?.analysis,
      treatments:
        treatmentAndEducationData?.treatments &&
        treatmentAndEducationData?.treatments.length > 0
          ? treatments
          : planTreatments,
      treatmentsBase:
        treatmentAndEducationData?.treatments &&
        treatmentAndEducationData?.treatments.length > 0
          ? treatmentsBase
          : planTreatmentsBase,
      otherTreatments:
        treatmentAndEducationData?.otherTreatments ??
        treatmentPlanData?.otherTreatments,
      hasOtherTreatments:
        (treatmentAndEducationData?.otherTreatments ??
          treatmentPlanData?.otherTreatments) !== undefined,
      educationComment:
        treatmentAndEducationData?.educationComment ??
        treatmentPlanData?.educationComment,
      educationOptions:
        treatmentAndEducationData?.educationOptions &&
        treatmentAndEducationData?.educationOptions.length > 0
          ? treatmentAndEducationData?.educationOptions
          : treatmentPlanData?.educationOptions,
      treatmentDate,
      analysisOfCurrentTreatment:
        treatmentAndEducationData?.analysisOfCurrentTreatment,
      analysisOfCurrentTreatmentNote:
        treatmentAndEducationData?.analysisOfCurrentTreatmentNote,
      plan: analysisAndPlanData?.plan,
      goals,
      psfsGoalsAdded: this.psfsGoalsAdded,
      treatmentPlanDiscussed: treatmentPlanData?.treatmentPlanDiscussed,
      warningExplained: treatmentPlanData?.warningExplained,
      consentObtained: treatmentPlanData?.consentObtained,
      culturalNeedsIdentified: treatmentPlanData?.culturalNeedsIdentified,
      hasAdditionalDiscussions: !!treatmentPlanData?.additionalDiscussions,
      additionalDiscussions: treatmentPlanData?.additionalDiscussions,
      customClinicalTools: answersArray,
      customToolBaseColumns: columns,
      dateOfInjury,
      dateOfSurgery: DateTime.jsDateFromISO(injuryData?.dateOfSurgery),
      addSurgeryDate: !!injuryData?.dateOfSurgery,
      filledClinicalDataType: this.filledClinicalDataType,
      planTreatmentsBase,
      planTreatments,
      planHasOtherTreatments: treatmentPlanData?.otherTreatments !== undefined,
      planOtherTreatments: treatmentPlanData?.otherTreatments,
      planEducationOptions: treatmentPlanData?.educationOptions,
      planOtherEducationComment: treatmentPlanData?.otherEducationComment,
      planEducationComment: treatmentPlanData?.educationComment,
      planReview: treatmentPlanData?.plan,

      frontKneeImage: kneeImageContent?.frontKneeImage,
      backKneeImage: kneeImageContent?.backKneeImage,
      leftSideKneeImage: kneeImageContent?.leftSideKneeImage,
      rightSideKneeImage: kneeImageContent?.rightSideKneeImage,

      frontFootImage: footImageContent?.frontFootImage,
      backFootImage: footImageContent?.backFootImage,
      bottomFootImage: footImageContent?.bottomFootImage,
      topFootImage: footImageContent?.topFootImage,
      leftInsideFootImage: footImageContent?.leftInsideFootImage,
      rightInsideFootImage: footImageContent?.rightInsideFootImage,
      leftOutsideFootImage: footImageContent?.leftOutsideFootImage,
      rightOutsideFootImage: footImageContent?.rightOutsideFootImage,

      frontHipImage: hipImageContent?.frontHipImage,
      backHipImage: hipImageContent?.backHipImage,
      leftSideHipImage: hipImageContent?.leftSideHipImage,
      rightSideHipImage: hipImageContent?.rightSideHipImage,

      leftSideShoulderImage: shoulderImageContent?.leftSideShoulderImage,
      leftFrontShoulderImage: shoulderImageContent?.leftFrontShoulderImage,
      leftBackShoulderImage: shoulderImageContent?.leftBackShoulderImage,
      rightSideShoulderImage: shoulderImageContent?.rightSideShoulderImage,
      rightBackShoulderImage: shoulderImageContent?.rightBackShoulderImage,
      rightFrontShoulderImage: shoulderImageContent?.rightSideShoulderImage,

      leftElbowImage: elbowImageContent?.leftElbowImage,
      rightElbowImage: elbowImageContent?.rightElbowImage
    };
  }

  private getFollowUpNotesData = (values: SOTAPFormValues) => {
    if (
      !isEqual(
        values.progression,
        this.initialSOTAPFollowOnFormValues.progression
      ) ||
      !isEqual(
        values.followUpNotesComment,
        this.initialSOTAPFollowOnFormValues.followUpNotesComment
      )
    ) {
      const followUpNotesData = this.clinicalRecord.clinicalData?.followUpNotes;

      const newfollowUpNotesData: FollowUpNotesClinicalDataDto = {
        ...followUpNotesData,
        progression: values.progression,
        followUpNotesComment: values.followUpNotesComment
      };

      return newfollowUpNotesData;
    }

    return undefined;
  };

  private getAnalysisAndPlanData = (values: SOTAPFormValues) => {
    const analysisAndPlanData =
      this.clinicalRecord.clinicalData?.analysisAndPlan;

    const newAnalysisAndPlanData: AnalysisAndPlanClinicalDataDto | undefined = {
      ...analysisAndPlanData,
      rxAnalysis: values.rxAnalysis,
      analysis: values.analysis,
      plan: values.planReview
    };

    const cleanedAnalysisAndPlanData = removeUndefinedProperties(
      newAnalysisAndPlanData
    ) as AnalysisAndPlanClinicalDataDto | undefined;

    if (!isEqual(analysisAndPlanData, cleanedAnalysisAndPlanData)) {
      return cleanedAnalysisAndPlanData;
    }

    return undefined;
  };

  private getTreatmentAndEducationData = (values: SOTAPFormValues) => {
    const filledTreatments = values.treatments
      ?.filter(x => !!x.treatment)
      .map(x => {
        return {
          treatment: x.treatment,
          comment: x.comment
        };
      });

    const treatmentAndEducationData =
      this.clinicalRecord.clinicalData?.treatmentAndEducation;

    const newTreatmentAndEducationData:
      | TreatmentAndEducationClinicalDataDto
      | undefined = {
      ...treatmentAndEducationData,
      treatments: filledTreatments,
      educationOptions: values.educationOptions,
      educationComment: values.educationComment,
      otherComment: values.otherComment,
      analysisOfCurrentTreatment: values.analysisOfCurrentTreatment,
      analysisOfCurrentTreatmentNote: values.analysisOfCurrentTreatmentNote
    };

    const cleanedTreatmentAndEducationData = removeUndefinedProperties(
      newTreatmentAndEducationData
    ) as TreatmentAndEducationClinicalDataDto | undefined;

    if (!isEqual(treatmentAndEducationData, cleanedTreatmentAndEducationData)) {
      return cleanedTreatmentAndEducationData;
    }

    return undefined;
  };

  private getCustomClinicalToolData = (values: SOTAPFormValues) => {
    let newCustomContextData:
      | CustomClinicalToolContextClinicalDataDto
      | undefined;

    let newCustomData: CustomClinicalToolClinicalDataDto | undefined;

    if (
      !isEqual(
        values.customClinicalTools,
        this.initialSOTAPFollowOnFormValues.customClinicalTools
      )
    ) {
      const customData = this.clinicalRecord.clinicalData?.customClinicalTool;

      const contextData =
        this.clinicalRecord.clinicalData?.customClinicalToolContext;

      const existingContexts = contextData?.contexts ?? [];

      const addedContexts: CustomClinicalToolContextDto[] = [];

      // save context first
      if (values.customClinicalTools) {
        values.customClinicalTools.forEach(customTool => {
          if (
            existingContexts.findIndex(
              c => c.contextId === customTool.contextId
            ) === -1
          ) {
            addedContexts.push({
              contextId: customTool.contextId,
              name: customTool.name!
            });
          }

          if (addedContexts.length > 0) {
            newCustomContextData = {
              ...contextData,
              contexts: [...addedContexts, ...existingContexts]
            };
          }
        });

        const cleanedCustomTools = values.customClinicalTools.filter(
          x => x.thisEncounterResult !== undefined
        );

        // save encounter-scoped data next
        newCustomData = {
          ...customData,
          tools: cleanedCustomTools.map(c => {
            return {
              contextId: c.contextId,
              result: c.thisEncounterResult,
              name: c.name
            } as CustomClinicalToolDto;
          })
        };
      }
    }

    return { newCustomContextData, newCustomData };
  };

  private getPostureOrObservationsData = (values: SOTAPFormValues) => {
    const postureOrObservations =
      this.clinicalRecord.clinicalData?.postureOrObservations;

    const newPostureOrObservations: PostureOrObservationsDto | undefined = {
      ...postureOrObservations,
      postureOrObservations: values.postureOrObservations
    };

    const cleanedPostureOrObservations = removeUndefinedProperties(
      newPostureOrObservations
    ) as PostureOrObservationsDto | undefined;

    if (!isEqual(postureOrObservations, cleanedPostureOrObservations)) {
      return cleanedPostureOrObservations;
    }

    return undefined;
  };

  private getOtherMovementsData = (values: SOTAPFormValues) => {
    const otherMovements = this.clinicalRecord.clinicalData?.otherMovements;

    const newOtherMovements: OtherMovementsDto | undefined = {
      ...otherMovements,
      otherMovements: values.otherMovements
    };

    const cleanedOtherMovements = removeUndefinedProperties(
      newOtherMovements
    ) as OtherMovementsDto | undefined;

    if (!isEqual(otherMovements, cleanedOtherMovements)) {
      return cleanedOtherMovements;
    }

    return undefined;
  };

  private getInjuryAreaData = (values: SOTAPFormValues) => {
    const injuryArea = this.clinicalRecord.clinicalData?.injuryArea;
    const patient = this.clinicalRecord.patient;

    //check if the values have changed since component renedered so only sections(clinicalDataTypes) where the data has changed are saved
    if (
      !isEqual(injuryArea, values.injuryAreas) ||
      !isEqual(
        values.injuryAreaMotionAssessments,
        this.initialSOTAPFollowOnFormValues.injuryAreaMotionAssessments
      )
    ) {
      let newInjuryAreasData: InjuryAreaClinicalDataDto = {
        ...injuryArea,
        assessments: InjuryImagesHelper.getMappedInjuryAreaAssessments(
          values.injuryAreas,
          values.injuryAreaMotionAssessments
        )
      };

      if (newInjuryAreasData?.assessments) {
        if (values.spinalCordFrontImage) {
          newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
            injuryAreaData: newInjuryAreasData,
            injuryImageType: InjuryImageType.SpinalCordFront,
            imageContent: values.spinalCordFrontImage,
            injuryArea: InjuryArea.Spine
          });
        }

        if (values.spinalCordSideImage) {
          newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
            injuryAreaData: newInjuryAreasData,
            injuryImageType: InjuryImageType.SpinalCordSide,
            imageContent: values.spinalCordSideImage,
            injuryArea: InjuryArea.Spine
          });
        }

        if (values.handsWristsImage) {
          newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
            injuryAreaData: newInjuryAreasData,
            injuryImageType: InjuryImageType.HandsWrists,
            imageContent: values.handsWristsImage,
            injuryArea: InjuryArea.HandAndWrist
          });
        }

        if (InjuryImagesHelper.hasKneeData(values)) {
          newInjuryAreasData = InjuryImagesHelper.updateKneeInjuryImage(
            values,
            newInjuryAreasData,
            patient?.sex
          );
        }

        if (InjuryImagesHelper.hasFootData(values)) {
          newInjuryAreasData = InjuryImagesHelper.updateFootInjuryImage(
            values,
            newInjuryAreasData
          );
        }

        if (InjuryImagesHelper.hasHipData(values)) {
          newInjuryAreasData = InjuryImagesHelper.updateHipInjuryImage(
            values,
            newInjuryAreasData,
            patient?.sex
          );
        }

        if (InjuryImagesHelper.hasShoulderData(values)) {
          newInjuryAreasData = InjuryImagesHelper.updateShoulderInjuryImage(
            values,
            newInjuryAreasData,
            patient?.sex
          );
        }

        if (InjuryImagesHelper.hasElbowData(values)) {
          newInjuryAreasData = InjuryImagesHelper.updateElbowInjuryImage(
            values,
            newInjuryAreasData,
            patient?.sex
          );
        }
      }

      return newInjuryAreasData;
    }

    return undefined;
  };

  private getInjuryData = (values: SOTAPFormValues) => {
    if (
      !isEqual(
        values.dateOfSurgery,
        this.initialSOTAPFollowOnFormValues.dateOfSurgery
      )
    ) {
      const injuryData = this.clinicalRecord.clinicalData?.injury;

      const newInjuryData: InjuryClinicalDataDto = {
        ...injuryData,
        dateOfSurgery: values.dateOfSurgery
          ? DateTime.jsDateToISODate(values.dateOfSurgery)
          : undefined
      };

      return newInjuryData;
    }
    return undefined;
  };

  private getCurrentInjuryData = (values: SOTAPFormValues) => {
    if (
      !isEqual(
        values.dateOfInjury,
        this.initialSOTAPFollowOnFormValues.dateOfInjury
      )
    ) {
      const currentInjuryData = this.clinicalRecord.clinicalData?.currentInjury;

      const newCurrentInjuryData: CurrentInjuryClinicalDataDto | undefined = {
        ...currentInjuryData,
        dateOfInjury: values.dateOfInjury
          ? DateTime.jsDateToISODate(values.dateOfInjury)
          : undefined
      };

      const cleanedCurrentInjuryData = removeUndefinedProperties(
        newCurrentInjuryData
      ) as CurrentInjuryClinicalDataDto | undefined;

      if (cleanedCurrentInjuryData) {
        return cleanedCurrentInjuryData;
      }
    }

    return undefined;
  };

  private getDiagnosisData = (values: SOTAPFormValues) => {
    if (
      !isEqual(
        values.primaryDiagnosis,
        this.initialSOTAPFollowOnFormValues.primaryDiagnosis
      ) ||
      !isEqual(
        values.additionalDiagnoses,
        this.initialSOTAPFollowOnFormValues.additionalDiagnoses
      ) ||
      !isEqual(
        values.diagnosisComment,
        this.initialSOTAPFollowOnFormValues.diagnosisComment
      )
    ) {
      const diagnosesData = this.clinicalRecord.clinicalData?.diagnoses;
      const newDiagnosesData: DiagnosesClinicalDataDto = {
        ...diagnosesData,
        diagnoses: getMappedDiagnoses(
          values.primaryDiagnosis ?? [],
          values.additionalDiagnoses ?? []
        ),
        diagnosisComment: values.diagnosisComment
      };

      return newDiagnosesData;
    }

    return undefined;
  };

  private getPatientTreatmentPlanData = (values: SOTAPFormValues) => {
    const treatmentPlanData =
      this.clinicalRecord.clinicalData?.patientTreatmentPlan;

    const newTreatmentPlanData =
      this.multiProviderHelper.getNewTreatmentPlanData(values);

    const cleanedTreatmentPlanData = removeUndefinedProperties(
      newTreatmentPlanData
    ) as PatientTreatmentPlanClinicalDataDto | undefined;

    if (!isEqual(treatmentPlanData, cleanedTreatmentPlanData)) {
      return cleanedTreatmentPlanData;
    }

    return undefined;
  };

  private getPhysicalActivityData = (values: SOTAPFormValues) => {
    const physicalActivityData =
      this.clinicalRecord.stashedClinicalData?.physicalActivity;

    const newPhysicalActivityData: PhysicalActivityClinicalDataDto = {
      ...physicalActivityData,
      eTag: physicalActivityData?.eTag,
      dominantHand: values.dominantHand
    };

    const cleanedPAD = removeUndefinedProperties(newPhysicalActivityData) as
      | PhysicalActivityClinicalDataDto
      | undefined;

    if (!isEqual(physicalActivityData, cleanedPAD)) {
      return newPhysicalActivityData;
    }

    return undefined;
  };

  public submitData = async (values: SOTAPFormValues) => {
    const clinicalData: EncounterClinicalDataDto = {};

    const followUpNotesData = this.getFollowUpNotesData(values);
    if (followUpNotesData) clinicalData.followUpNotes = followUpNotesData;

    const analysisAndPlanData = this.getAnalysisAndPlanData(values);
    if (analysisAndPlanData) clinicalData.analysisAndPlan = analysisAndPlanData;

    const treatmentAndEducationData = this.getTreatmentAndEducationData(values);
    if (treatmentAndEducationData)
      clinicalData.treatmentAndEducation = treatmentAndEducationData;

    const patientTreatmentPlanData = this.getPatientTreatmentPlanData(values);
    if (patientTreatmentPlanData)
      clinicalData.patientTreatmentPlan = patientTreatmentPlanData;

    const { newCustomContextData, newCustomData } =
      this.getCustomClinicalToolData(values);

    if (newCustomContextData)
      clinicalData.customClinicalToolContext = newCustomContextData;

    if (newCustomData) clinicalData.customClinicalTool = newCustomData;

    const postureOrObservationsData = this.getPostureOrObservationsData(values);
    if (postureOrObservationsData)
      clinicalData.postureOrObservations = postureOrObservationsData;

    const otherMovementsData = this.getOtherMovementsData(values);
    if (otherMovementsData) clinicalData.otherMovements = otherMovementsData;

    const injuryAreaData = this.getInjuryAreaData(values);
    if (injuryAreaData) clinicalData.injuryArea = injuryAreaData;

    const injuryData = this.getInjuryData(values);
    if (injuryData) clinicalData.injury = injuryData;

    const currentInjuryData = this.getCurrentInjuryData(values);
    if (currentInjuryData) clinicalData.currentInjury = currentInjuryData;

    const diagnosisClinicalData = this.getDiagnosisData(values);
    if (diagnosisClinicalData) clinicalData.diagnoses = diagnosisClinicalData;

    const physicalActivityData = this.getPhysicalActivityData(values);
    if (physicalActivityData)
      clinicalData.physicalActivity = physicalActivityData;

    clinicalData.reasonForVisit = getReasonForVisit(
      values.primaryDiagnosis,
      this.clinicalRecord.clinicalData?.reasonForVisit
    );

    await this.clinicalRecord.saveClinicalData(clinicalData);
  };
}
