import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";
import { Field } from "react-final-form";

import { ButtonsGroupOption, Stack, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  CommunicationDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PatientDisplayCode } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { EditPronounHeader } from "@modules/practice/screens/contact-details/shared-components/edit/EditPronounHeader.tsx";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { settings } from "@shared-types/user-experience/localisation-settings.constant.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { PhoneTextField } from "@ui-components/form/PhoneTextField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TitlePickerField } from "@ui-components/form/TitlePickerField.tsx";

import { UserInfoFormLabels } from "../../../shared-components/SettingsLabels.ts";
import { ProviderSignatureField } from "./ProviderSignatureField.tsx";
import {
  userInfoFormNameOf as nameOf,
  UserInfoFormValues
} from "./UserInfoForm.types.ts";
import { UserInfoFormValidator } from "./UserInfoFormValidator.tsx";

const validator = new UserInfoFormValidator();

type UserInfoFormProps = {
  user: User;
  bhbProvider?: BhbProvider;
};

export const UserInfoForm: FunctionComponent<UserInfoFormProps> = observer(
  ({ user }) => {
    const { core, userExperience, notification, practice } = useStores();

    const theme = useTheme();
    const { formFooter, formFields } = getUserStylesSet(theme);

    const [signatureStagingId, setSignatureStagingId] = useState<string>();
    const [clearedSignature, setClearedSignature] = useState<boolean>();

    const customersCalledOptions: ButtonsGroupOption<string>[] = [
      {
        key: PatientDisplayCode.Patient,
        text: capitalizeSentence(`${settings.PAT.patientDisplay}s`)
      },
      {
        key: PatientDisplayCode.Client,
        text: capitalizeSentence(`${settings.CLI.patientDisplay}s`)
      }
    ];

    const initialValues = (): UserInfoFormValues => {
      return {
        id: user.id,
        title: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        username: user.username,
        workPhone: user.workPhone,
        pronounObjective: user.pronounObjective,
        pronounSubjective: user.pronounSubjective,
        pronounPossessive: user.pronounPossessive,
        patientDisplayCode: userExperience.userSettingMap.get(user.id)
          ?.patientLabel,
        signatureUrl: practice.providersMap.get(user.id)?.signatureUrl,
        gender: user.gender,
        preferredName: user.preferredName
      };
    };

    const onSubmit = async (values: UserInfoFormValues) => {
      const { workPhone } = values;

      const communications: CommunicationDto[] = mergeWithCommunications(
        workPhone,
        user
      );

      await core.updateUser({
        communications,
        id: values.id,
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        username: values.username,
        pronounObjective: values.pronounObjective,
        pronounSubjective: values.pronounSubjective,
        pronounPossessive: values.pronounPossessive,
        gender: values.gender,
        preferredName: values.preferredName
      });

      const userSetting = await userExperience.getUserSetting(user.id);
      if (userSetting) {
        await userExperience.upsertUserSetting({
          patientLabel: values.patientDisplayCode,
          userId: user.id
        });
      }

      // ** SIGNATURES **

      // Uploading
      if (signatureStagingId) {
        await practice.updateSignature({
          fileStagingId: signatureStagingId,
          entityId: user.id
        });
      }

      // Clearing/Deleting
      if (clearedSignature) {
        await practice.deleteSignature(user.id);
        setClearedSignature(false);
      }

      setSignatureStagingId(undefined);
      setClearedSignature(false);
    };

    return (
      <SubmissionForm<UserInfoFormValues>
        formName="user-info"
        styles={{ main: { borderRadius: 4 } }}
        validate={(values: UserInfoFormValues) => validator.validate(values)}
        readOnly={!core.hasPermissions(Permission.UserWrite)}
        initialValues={initialValues()}
        onSubmitSucceeded={() => {
          notification.success(`${user.fullName} has been updated.`);
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {({ values, form }) => {
          return (
            <Fieldset
              styles={{
                root: formFields
              }}
            >
              <Stack
                tokens={{ childrenGap: 8 }}
                horizontal
                horizontalAlign="space-between"
              >
                <TitlePickerField
                  fieldItemStyles={{
                    root: { flexGrow: 1, flexBasis: 0 }
                  }}
                  label={UserInfoFormLabels.title}
                  name={nameOf("title")}
                  styles={{
                    text: {
                      minWidth: "auto"
                    }
                  }}
                />
                <TextInputField
                  name={nameOf("firstName")}
                  label={UserInfoFormLabels.firstName}
                  required={true}
                  autoFocus
                  styles={{
                    root: { width: "auto", flexBasis: 0, flexGrow: 3 }
                  }}
                />
              </Stack>
              <Stack
                tokens={{ childrenGap: 8 }}
                horizontal
                horizontalAlign="space-between"
              >
                <Stack.Item styles={{ root: { flexGrow: 3, flexBasis: 0 } }}>
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <TextInputField
                      name={nameOf("preferredName")}
                      label={UserInfoFormLabels.preferredName}
                      placeholder="Same as the 'First name' if not specified"
                    />
                    <TextInputField
                      label={UserInfoFormLabels.middleName}
                      name={nameOf("middleName")}
                    />
                  </Stack>
                </Stack.Item>
              </Stack>
              <TextInputField
                required
                label={UserInfoFormLabels.lastName}
                name={nameOf("lastName")}
              />
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Stack.Item styles={{ root: { flexGrow: 1, flexBasis: 0 } }}>
                  <PhoneTextField
                    label={UserInfoFormLabels.phone}
                    name={nameOf("workPhone")}
                  />
                </Stack.Item>
                <Stack.Item styles={{ root: { flexGrow: 3, flexBasis: 0 } }}>
                  <TextInputField
                    required
                    disabled
                    label={UserInfoFormLabels.email}
                    name={nameOf("username")}
                    type="email"
                  />
                </Stack.Item>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Stack.Item styles={{ root: { flexGrow: 1, flexBasis: 0 } }}>
                  <DropdownField
                    name={nameOf("gender")}
                    label="Gender"
                    placeholder="Select gender identity"
                    options={practice.ref.genders.keyTextValues}
                    styles={{
                      root: {
                        flexGrow: 1
                      }
                    }}
                    withNoEmptyOption={false}
                  />
                </Stack.Item>
                <Stack.Item>
                  <EditPronounHeader
                    pronounSubjectiveName={nameOf("pronounPossessive")}
                    pronounObjectiveName={nameOf("pronounObjective")}
                    pronounPossessiveName={nameOf("pronounPossessive")}
                  />
                </Stack.Item>
              </Stack>
              <ProviderSignatureField
                label="Signature"
                name={nameOf("signatureFile")}
                signatureUrl={values.signatureUrl}
                onSignatureCleared={() => {
                  form.batch(() => {
                    form.change(nameOf("signatureFile"), undefined);
                    form.change(nameOf("signatureUrl"), undefined);
                  });

                  setSignatureStagingId(undefined);
                  setClearedSignature(true);
                }}
                onFileAccepted={stagingId => {
                  form.batch(() => {
                    form.change(nameOf("signatureUrl"), stagingId);
                  });

                  setSignatureStagingId(stagingId);
                  setClearedSignature(false);
                }}
              />

              <Field name={nameOf("signatureUrl")} render={() => null} />
              {(user.id === core.userId ||
                core.permissions.includes(Permission.UserSettingWrite)) && (
                <ButtonsGroupSingleChoiceField
                  label={UserInfoFormLabels.customersCalled}
                  name={nameOf("patientDisplayCode")}
                  options={customersCalledOptions}
                />
              )}
            </Fieldset>
          );
        }}
      </SubmissionForm>
    );
  }
);

/**
 *
 * @param workPhone the new work phone or falsy if the work phone must be removed
 * @param user the user whoose communications will be used to merge the work phone.
 *
 * It doesn't mutate the user communications but returns a new array.
 */
function mergeWithCommunications(workPhone: string | undefined, user: User) {
  let communications: CommunicationDto[] = [];
  if (workPhone && (!user || user.workPhone !== workPhone)) {
    communications = (
      user
        ? user.communications.filter(
            x => x.type !== CommunicationType.WorkPhone
          )
        : []
    ).concat([
      {
        type: CommunicationType.WorkPhone,
        value: workPhone,
        preferred: false
      }
    ]);
  } else if (!workPhone && user && user.workPhone) {
    communications = user.communications.filter(
      x => x.type !== CommunicationType.WorkPhone
    );
  } else if (workPhone && user && user.workPhone === workPhone) {
    communications = user.communications.filter(
      x => x.type === CommunicationType.WorkPhone
    );
  }
  return communications;
}
