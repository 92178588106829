import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { TemplateWriterValues } from "./TemplateWriterValues.ts";

export class TemplateWriterDetailsValidator extends Validator<TemplateWriterValues> {
  constructor() {
    super();
    this.forField(
      "documentContent",
      predicate(
        (val, values) =>
          values?.documentContent !== undefined ||
          values?.documentContent !== "",
        required()
      )
    );
  }
}
