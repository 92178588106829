import React from "react";

import { CommandBarButton, Persona, PersonaSize } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface ReminderAttendeeItemProps {
  attendee?: Contact;
  calendarEvent: CalendarEvent;
}

export const ReminderAttendeeItem: React.FC<ReminderAttendeeItemProps> = ({
  attendee,
  calendarEvent
}) => {
  const { setReminderArgs } = useBookingCalendarScreenContext();

  const onClick = () => {
    setReminderArgs({
      calendarEventId: calendarEvent.id,
      contactId: attendee?.id
    });
  };

  // !attendee = "All" option
  if (!attendee) {
    return (
      <CommandBarButton onClick={onClick}>
        Multiple participants
      </CommandBarButton>
    );
  }

  return (
    <Persona
      text={attendee.name}
      id={attendee.id}
      size={PersonaSize.size24}
      imageInitials={attendee.initials}
      styles={{ root: { padding: 8 } }}
      onClick={onClick}
    />
  );
};
