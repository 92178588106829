import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  getTheme,
  Heading,
  IButtonStyles,
  IconButton,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PatientNotePriority } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { When } from "@ui-components/withPerm.tsx";

import { AddPatientNoticeFormDialog } from "./AddPatientNoticeFormDialog.tsx";
import { usePatientNoticesContext } from "./PatientNoticesContext.tsx";
import { NoticeType } from "./PatientNoticesModel.ts";
import { getPriorityColor } from "./PatientNoticesTableRow.tsx";
import { getHighestNoticePriority } from "./utils.ts";

interface PatientNoticesAddButtonNoContextProps {
  filter: NoticeType;
  styles?: { icon?: IButtonStyles };
}

export const PatientNoticesAddButton: React.FC<PatientNoticesAddButtonNoContextProps> =
  observer(({ filter, styles }) => {
    const { getFilteredPatientNotices, systemNotices } =
      usePatientNoticesContext();

    const theme = getTheme();
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const patientNotices = getFilteredPatientNotices(filter);

    const priority =
      systemNotices.length > 0
        ? PatientNotePriority.High
        : getHighestNoticePriority(patientNotices);

    const noticeCount = patientNotices.length + systemNotices.length;
    return (
      <>
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          styles={{ root: { alignItems: "center" } }}
        >
          <When permission={Permission.ContactWrite}>
            <IconButton
              {...dataAttribute(
                DataAttributes.Element,
                "patient-notices-add-button"
              )}
              styles={styles?.icon}
              iconProps={{ iconName: "Add" }}
              onClick={() => setShowDialog(true)}
            />
          </When>

          <Heading
            styles={{ root: { paddingBottom: 6, paddingTop: 6 } }}
            variant="section-sub-heading"
          >
            Notices
          </Heading>
          <ActionButton
            {...dataAttribute(
              DataAttributes.Element,
              "patient-notices-count-button"
            )}
            text={noticeCount.toString()}
            styles={{
              root: [
                {
                  cursor: "default",
                  borderRadius: 5,
                  height: 26,
                  backgroundColor: getPriorityColor(theme.palette, priority)
                },
                theme.fonts.small
              ]
            }}
          />
        </Stack>
        {showDialog && (
          <AddPatientNoticeFormDialog onDismiss={() => setShowDialog(false)} />
        )}
      </>
    );
  });
