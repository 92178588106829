import { FC } from "react";
import { useForm } from "react-final-form";

import {
  DefaultButton,
  Heading,
  IContextualMenuItem,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  AddressType,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { communicationComparer } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AddressTypeCheckboxFields } from "../../../shared-components/AddressTypeCheckboxFields.tsx";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { ContactMethodAddress } from "../../shared-components/edit/ContactMethodAddress.tsx";
import { ContactMethodCommunications } from "../../shared-components/edit/ContactMethodCommunications.tsx";
import { OrganisationLabels } from "../organisation.types.ts";
import {
  EditOrganisationFormValues,
  orgEditFormNameOf
} from "./EditOrganisationFormValues.tsx";

export const EditOrganisationalLocation: FC = () => {
  const { core } = useStores();
  const form = useForm<EditOrganisationFormValues>();
  const { values } = useForm<EditOrganisationFormValues>().getState();
  const { country } = core.tenantDetails!;
  const locationIsRequired =
    values.privateInsurer?.accPrivateInsurer ||
    values.employer?.accreditedEmployer;

  const addContactMethod = (type: CommunicationType) => () => {
    const communications = form.getState().values.communications;
    return form.change(
      orgEditFormNameOf("communications"),
      [
        ...communications.map(comms => ({
          ...comms,
          isNew: false
        })),
        { type, isNew: true, id: newGuid() }
      ].sort(communicationComparer)
    );
  };

  const addAddress = (types: AddressType[], locationCountry: string) => () => {
    return form.mutators.push(orgEditFormNameOf("addresses"), {
      street1: "",
      country: locationCountry,
      types,
      isNew: true,
      id: newGuid()
    } as AddressFieldValue);
  };

  const getItems = (): IContextualMenuItem[] => [
    {
      key: "mobile",
      text: "Mobile",
      onClick: addContactMethod(CommunicationType.Mobile)
    },
    {
      key: "phone",
      text: "Phone",
      onClick: addContactMethod(CommunicationType.Phone)
    },
    {
      key: "afterHoursPhone",
      text: "After hours phone",
      onClick: addContactMethod(CommunicationType.AfterHours)
    },
    {
      key: "email",
      text: "Email",
      onClick: addContactMethod(CommunicationType.Email)
    },
    {
      key: "fax",
      text: "Fax",
      onClick: addContactMethod(CommunicationType.Fax)
    },
    {
      key: "physicalAddress",
      text: "Physical address",
      onClick: addAddress([AddressType.Physical], country ?? "")
    },
    {
      key: "postalAddress",
      text: "Postal address",
      onClick: addAddress([AddressType.Postal], country ?? "")
    },
    {
      key: "websiteAddress",
      text: "Website address",
      onClick: addContactMethod(CommunicationType.Website)
    }
  ];

  return (
    <div
      id={`${OrganisationCardIds.OrganisationLocation}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack
        styles={(props, theme) => {
          return {
            root: {
              borderBottom: "1px solid",
              borderColor: theme.palette.neutralLighterAlt
            }
          };
        }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { lineHeight: "22px", marginBottom: 8 } }}
        >
          <Heading
            variant="section-heading-light"
            styles={{ root: { padding: "5px 0" } }}
          >
            {Labels.location}
          </Heading>
          <DefaultButton
            iconProps={{ iconName: "Add" }}
            text="Add more"
            menuProps={{
              styles: { container: { width: 152 } },
              shouldFocusOnMount: true,
              items: getItems()
            }}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 16 }}>
          {locationIsRequired && (
            <MessageBar
              styles={{
                root: {
                  marginTop: 16
                }
              }}
              messageBarType={MessageBarType.warning}
            >
              {OrganisationLabels.AddressDetailsRequired}
            </MessageBar>
          )}
          <ContactMethodAddress
            name={orgEditFormNameOf("addresses")}
            country={country}
            defaultAddressType={AddressType.Both}
            onRenderFieldsetActions={(parentName, index) => (
              <AddressTypeCheckboxFields
                parentName={parentName}
                index={index}
              />
            )}
          />
          <ContactMethodCommunications
            disablePreferredChoice
            disableSendForm
            noDefaultCommunication={true}
            name={orgEditFormNameOf("communications")}
          />
        </Stack>
      </Stack>
    </div>
  );
};
