import { Observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { useFormState } from "react-final-form";

import { ActionButton, TopBarDefaultHeader } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { AllClaimFormValues } from "@modules/acc/screens/claim/types/claim.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

export const ClinicalClaimFormHeader: FC = () => {
  const { routing, clinical } = useStores();
  const { values } = useFormState<AllClaimFormValues>({
    subscription: { values: true }
  });

  const claimHelper = useContext(ClaimContext);
  const { primaryDiagnosis, claimNumber, id } = values;

  const primaryDiagnosisDescription = primaryDiagnosis?.diagnosisDescription;

  const heading = `${claimNumber ?? ""} ${primaryDiagnosisDescription ?? ""}`;

  const injuryButton = (
    <ActionButton
      styles={{ root: { padding: "5px 9px" } }}
      iconProps={{ iconName: "Share" }}
      text="Claim summary"
      onClick={() =>
        id &&
        routing.push(
          routes.claims.management.edit.path({ id }),
          routing.getStateWithFromQuery()
        )
      }
    />
  );

  return (
    <TopBarDefaultHeader
      hideBackButton
      heading={!claimNumber && primaryDiagnosisDescription ? "ACC45" : heading}
      rightContainer={
        <Observer>
          {() => {
            const disabled =
              !clinical.ui.clinicalTabs.currentPatientRecordTab?.activeTab
                ?.isDirty;

            return (
              <FormSubmitButtons
                hideButtonsSeparator
                hideCancel
                hideSubmit={claimHelper.claim.isViewMode}
                submitButtonProps={{
                  disabled,
                  onClick: () => {
                    claimHelper.submitOptions = {
                      redirect: false,
                      claimStatus: claimHelper.getDraftStatus(values),
                      isLodge: false
                    };
                  }
                }}
                styles={{ root: { padding: 0, margin: 0 } }}
                extraActionsBefore={() => injuryButton}
              />
            );
          }}
        </Observer>
      }
    />
  );
};
