import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useField, useForm } from "react-final-form";

import { Heading, mergeStyles, Stack, Text, useTheme } from "@bps/fluent-ui";
import { ClaimAdjustmentFormLabels } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentEnums.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ReviewFormValues } from "@shared-types/acc/claim-review-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { reviewFormNameOf } from "./claim-review.utils.ts";
import { CardHeadings, CardIds } from "./ClaimReviewEnums.ts";
import { ClaimReviewContext } from "./models/ClaimReviewContext.ts";

const ReviewCardBase: React.FC = observer(() => {
  const { acc } = useStores();
  const { claim } = useContext(ClaimReviewContext);
  const form = useForm<ReviewFormValues>();

  const { semanticColors } = useTheme();

  const patientLabel = usePatientLabel(false);

  const {
    input: { value: mechanismOfInjury }
  } = useField("mechanismOfInjury", {
    subscription: { value: true }
  });

  const copyCauseFromACC45 = () => {
    const text = mechanismOfInjury ? `${mechanismOfInjury}\n` : "";
    if (claim.causeOfAccident) {
      form.change("mechanismOfInjury", `${text}${claim.causeOfAccident}\n`);
    }
  };

  return (
    <ClaimCard
      id={CardIds.review}
      openOnRender={true}
      heading={CardHeadings.review}
      iconName="ComplianceAudit"
    >
      <Fieldset>
        <BookableUserPickerField
          required
          label="Clinical director"
          name={reviewFormNameOf("clinicalDirectorId")}
        />
        <TextInputField
          label="Mechanism of injury"
          placeholder={`Please explain how the reported accident event has caused the ${patientLabel} injury`}
          name={reviewFormNameOf("mechanismOfInjury")}
          multiline
          autoAdjustHeight
          resizable={false}
          rows={4}
          maxLength={2001}
          actionButton={{
            linkProps: {
              onClick: () => copyCauseFromACC45(),
              children: ClaimAdjustmentFormLabels.copyFromACC45
            }
          }}
        />
        <TextInputField
          label="Review injury and diagnosis"
          placeholder={`Please confirm the ${patientLabel}’s current injury diagnosis. If the ${patientLabel}’s diagnosis has changed, ensure an ACC32 has been completed explaining the rationale for the change of diagnosis`}
          name={reviewFormNameOf("reviewInjuryAndDiagnosis")}
          multiline
          autoAdjustHeight
          resizable={false}
          rows={4}
          maxLength={2001}
        />
        <ButtonsGroupSingleChoiceField
          required
          onRenderLabel={(props, styles) => (
            <Stack
              className={mergeStyles({
                marginBottom: 3,
                color: props.disabled ? semanticColors.disabledText : undefined
              })}
            >
              <Heading disabled={props.disabled} styles={styles}>
                Statement on causation
              </Heading>
              <Text styles={{ root: { color: "inherit" } }}>
                “In my opinion, the reported accident has caused the injury
                being treated.”
              </Text>
            </Stack>
          )}
          options={acc.ref.statementOnCausation.keyTextValues}
          name={reviewFormNameOf("statementOnCausation")}
        />
        <TextInputField
          placeholder="Please explain how the accident event caused the current injury diagnosis and how this is linked to the treatment provided"
          name={reviewFormNameOf("statementOnCausationDetails")}
          multiline
          autoAdjustHeight
          resizable={false}
          rows={4}
          maxLength={2001}
        />
        <TextInputField
          label="Review of current treatment plan"
          placeholder="Please summarise the treatment which has been provided to date and make detailed comment on whether this was necessary and appropriate to treat the covered injury"
          name={reviewFormNameOf("reviewOfCurrentTreatment")}
          multiline
          autoAdjustHeight
          resizable={false}
          rows={4}
          maxLength={2001}
        />
        <TextInputField
          label="Documentation of any recommendations and actions"
          placeholder="Please explain why further treatment is required and what your recommendations are to treat the covered injury"
          name={reviewFormNameOf("documentationOfAnyRecommendationsAndActions")}
          multiline
          autoAdjustHeight
          resizable={false}
          rows={4}
          maxLength={2001}
        />
        <ButtonsGroupSingleChoiceField
          label="Liaison with provider undertaken"
          options={acc.ref.liaisonWithProviderUndertaken.keyTextValues}
          name={reviewFormNameOf("liaisonWithProviderUndertaken")}
        />
        <TextInputField
          name={reviewFormNameOf("liaisonWithProviderUndertakenDetails")}
          placeholder="Please detail your discussion with the treating provider if applicable"
          multiline
          autoAdjustHeight
          resizable={false}
          rows={4}
          maxLength={2001}
        />
      </Fieldset>
    </ClaimCard>
  );
});

export const ReviewCard = withFetch(
  x => [
    x.acc.ref.liaisonWithProviderUndertaken.load(),
    x.acc.ref.statementOnCausation.load()
  ],
  ReviewCardBase
);
