import { AddBankAccountsDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";

export interface InvoiceSettingsFormValues {
  id?: string;
  footer: string;
  bankDetails: {
    accountName: string;
    bankName: string;
    bsb: string;
    accountNumber: string;
    accountNumber2: string;
    accountNumber3: string;
    accountNumber4: string;
  };
  alwaysApplyGst: boolean;
  statementFooter?: string;
  gstPercent: number;
  gstNumber?: string;
  bankDetailsUnderStatementFooter: boolean;
}

export interface BankAccount extends AddBankAccountsDto {}

export enum SettingsFormLabels {
  account = "Bank accounts",
  financialOptions = "Financial options",
  feesAreGstInclusive = "All fees are GST inclusive",
  gstPercent = "GST value",
  gstNumber = "GST number"
}

export enum SettingsFormPlaceholders {
  footer = "Standard footer",
  accountName = "Account name",
  bankName = "Bank name",
  bsb = "BSB",
  accountNumber = "Account number"
}

export enum SettingsFormHeadings {
  gstSettings = "GST settings",
  bankDetails = "Bank details"
}
