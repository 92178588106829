import { observer } from "mobx-react-lite";

import { ToolTipButton } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface PracticeLocationAddButtonProps {
  locations: OrgUnit[] | undefined;
  loading?: boolean;
  error?: Error;
}

export const MAX_ACTIVE_LOCATIONS = 10;

export const PracticeLocationAddButton = observer(
  (props: PracticeLocationAddButtonProps) => {
    const { core, routing } = useStores();

    if (!core.user?.hasPracInfoAdminSecurityRole) return null;

    const { loading, error, locations } = props;

    const activeLocations = locations?.reduce(
      (sum, location) => (location.isInactive ? sum : sum + 1),
      0
    );

    const maxLocations =
      activeLocations !== undefined && activeLocations >= MAX_ACTIVE_LOCATIONS;

    const tooltipMessage = maxLocations
      ? "You must deactivate an active location to be able to add another"
      : undefined;

    const onClick = () =>
      routing.push(routes.settings.practices.locations.new.pattern);

    return (
      <ToolTipButton
        toolTipContent={tooltipMessage}
        buttonProps={{
          disabled: !!loading || !!error || maxLocations,
          iconProps: { iconName: "Add" },
          text: "Add another",
          onClick
        }}
      />
    );
  }
);
