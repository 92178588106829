import { HeadingProps, IPersonaProps, IStackProps } from "@bps/fluent-ui";

export const invoiceDetailsRowHeadingStyles: HeadingProps["styles"] = {
  root: { height: 32, width: 250 }
};

export const invoiceDetailsRowPersonaContainerStyles: IStackProps["styles"] = (
  props,
  theme
) => ({
  root: {
    flexDirection: "row",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.neutralLight}`,
    padding: 10,
    height: "auto",
    minHeight: 77
  }
});

export const invoiceDetailsRowPersonaStyles: IPersonaProps["styles"] = {
  root: {
    height: "auto",
    alignItems: "flex-start"
  },
  primaryText: {
    whiteSpace: "normal",
    wordBreak: "break-word"
  }
};
