import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { HighlightedText } from "@ui-components/highlighted-text/HighlightedText.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

interface ClaimNumberCellProps {
  claim: Claim;
  hideReferalToolTip?: boolean;
  filter?: string;
}

export const EM_DASH_SYMBOL = 0x2014;

export const claimNumberDisplay = (claimStatus: string) => {
  const longDashSymbol = String.fromCodePoint(EM_DASH_SYMBOL);
  const NOT_LODGED_TEXT = "Not lodged";
  const isNotLodged =
    claimStatus === ClaimStatuses.Incomplete ||
    claimStatus === ClaimStatuses.Ready;

  if (isNotLodged) {
    return NOT_LODGED_TEXT;
  }
  return longDashSymbol;
};

export const ClaimNumberCell: React.FC<ClaimNumberCellProps> = ({
  claim,
  hideReferalToolTip,
  filter
}) => {
  const { routing } = useStores();

  if (!claim.claimStatus) {
    return null;
  }
  return (
    <Navigate
      {...dataAttribute(DataAttributes.Element, "claim-Number")}
      state={{ claimsFilter: routing.location.search }}
      to={
        claim.referralIn
          ? routes.claims.management.edit.path({
              id: claim.id
            })
          : routes.claims.edit.path({
              id: claim.id
            })
      }
    >
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        {claim.isOnHold && (
          <TooltipHost content="On hold" styles={{ root: { display: "flex" } }}>
            <FontIcon
              iconName="Clock"
              styles={{
                root: {
                  fontSize: FontSizes.size16
                }
              }}
            />
          </TooltipHost>
        )}
        {claim.referralIn && !hideReferalToolTip && (
          <TooltipHost
            content="Referred claim"
            styles={{ root: { display: "flex" } }}
          >
            <FontIcon
              iconName="FollowUser"
              styles={{
                root: {
                  fontSize: FontSizes.size16
                }
              }}
            />
          </TooltipHost>
        )}
        {claim.private && (
          <TooltipHost
            content="Private claim"
            styles={{ root: { display: "flex" } }}
          >
            <FontIcon
              iconName="Lock"
              styles={{
                root: {
                  fontSize: FontSizes.size16
                }
              }}
            />
          </TooltipHost>
        )}
        <Stack.Item>
          {claim.claimNumber && filter && (
            <HighlightedText text={claim.claimNumber} filter={filter} />
          )}
          {claim.claimNumber && !filter && claim.claimNumber}
          {!claim.claimNumber &&
            !filter &&
            claimNumberDisplay(claim.claimStatus)}
        </Stack.Item>
      </Stack>
    </Navigate>
  );
};
