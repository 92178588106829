import { useForm } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { ActionButton, confirm, Stack } from "@bps/fluent-ui";
import {
  CareGiverDto,
  NO,
  YES
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import {
  CARE_CONTACTS_MAX_WIDTH,
  CARE_CONTACTS_MIN_WIDTH,
  CARE_TYPES_MAX_WIDTH,
  CARE_TYPES_MIN_WIDTH,
  SocialFormValues
} from "./SocialFormValues.ts";

interface SocialCareGiverListProps {
  options: { key: string; text: string }[];
}

export const SocialCareGiverList: React.FunctionComponent<
  SocialCareGiverListProps
> = props => {
  const socialForm = useForm<SocialFormValues>();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const changeHasACareGiver = async () => {
    const { getState, change } = socialForm;
    const careGiversList = getState().values.patientDemographic.careGivers?.map(
      p => ({
        ...p
      })
    );
    if (getState().values?.patientDemographic.hasACareGiver === YES) {
      if (!careGiversList || (careGiversList && careGiversList.length === 0)) {
        addNewCareGiver();
      }
    } else {
      if (careGiversList && careGiversList.length > 0) {
        if (careGiversList.filter(x => x.id)?.length > 0) {
          if (
            await confirm({
              dialogContentProps: {
                title: "Remove care giver record/s",
                subText:
                  "This action will remove all related records. Are you sure?"
              },
              confirmButtonProps: {
                text: "Confirm"
              },
              cancelButtonProps: {
                text: "Cancel"
              },
              minWidth: 500
            })
          ) {
            change(
              "patientDemographic.careGivers" as keyof SocialFormValues,
              [] as any
            );
          } else {
            change(
              "patientDemographic.hasACareGiver" as keyof SocialFormValues,
              YES as any
            );
          }
        } else {
          change(
            "patientDemographic.careGivers" as keyof SocialFormValues,
            [] as any
          );
        }
      }
    }
  };

  const addNewCareGiver = () => {
    const { mutators } = socialForm;
    mutators.push("patientDemographic.careGivers", {
      id: "",
      careGiverTypes: []
    } as CareGiverDto);
  };

  const removeCareGiverRow = (careGiverRowNumber: number) => {
    const { getState, mutators, change } = socialForm;
    mutators.remove("patientDemographic.careGivers", careGiverRowNumber);
    const careGiversList = getState().values.patientDemographic.careGivers?.map(
      p => ({
        ...p
      })
    );
    if (!careGiversList || (careGiversList && careGiversList.length <= 0)) {
      change(
        "patientDemographic.hasACareGiver" as keyof SocialFormValues,
        NO as any
      );
    }
  };

  return (
    <Fieldset>
      <FieldArray name="patientDemographic.careGivers">
        {(fieldProps: FieldArrayRenderProps<CareGiverDto, HTMLElement>) => {
          const careGivers = fieldProps.fields.value;
          return (
            careGivers &&
            careGivers.length > 0 && (
              <Stack tokens={{ childrenGap: 8 }}>
                {careGivers.map((item: CareGiverDto, index: number) => {
                  return (
                    <Stack
                      key={`careGiver${item.id}`}
                      horizontal
                      verticalAlign="start"
                    >
                      <ContactPickerFieldWithAdd
                        label={index === 0 ? "Carer" : undefined}
                        name={`patientDemographic.careGivers[${index}].id`}
                        filter={{
                          types: [
                            ContactType.Individual,
                            ContactType.Patient,
                            ContactType.Organisation
                          ]
                        }}
                        placeholder="Person or organisation"
                        wrapperStyles={{
                          root: {
                            marginRight: 8,
                            flexGrow: 1,
                            minWidth: CARE_CONTACTS_MIN_WIDTH,
                            maxWidth: CARE_CONTACTS_MAX_WIDTH
                          }
                        }}
                        tagInUseKeys={careGivers?.map(v => v.id)}
                        quickAddProps={{ showAddByDropdown: true }}
                      />
                      <Stack
                        horizontal
                        verticalAlign="end"
                        styles={{
                          root: {
                            minWidth: CARE_TYPES_MIN_WIDTH,
                            maxWidth: CARE_TYPES_MAX_WIDTH,
                            width: "100%",
                            marginRight: 8
                          }
                        }}
                      >
                        <DropdownField
                          label={index === 0 ? "Type of care" : undefined}
                          multiSelect
                          fieldItemStyles={{
                            root: { width: "100%", marginRight: 8 }
                          }}
                          options={props.options}
                          name={`patientDemographic.careGivers[${index}].careGiverTypes`}
                        />
                        {!isViewOnlyOrDischarged && (
                          <DeleteButton
                            onClick={() => removeCareGiverRow(index)}
                            styles={{
                              icon: {
                                paddingBottom: 8
                              }
                            }}
                          />
                        )}
                      </Stack>
                    </Stack>
                  );
                })}
                {!isViewOnlyOrDischarged && (
                  <ActionButton
                    iconProps={{ iconName: "Add" }}
                    title="Add more"
                    onClick={addNewCareGiver}
                    styles={{ root: { minWidth: 200 } }}
                  >
                    Add another care giver
                  </ActionButton>
                )}
              </Stack>
            )
          );
        }}
      </FieldArray>
      <FieldSpy
        name="patientDemographic.hasACareGiver"
        onChange={changeHasACareGiver}
      />
    </Fieldset>
  );
};
