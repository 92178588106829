import { useState } from "react";

import { DetailsList } from "@bps/fluent-ui";
import { ClinicalDocumentLite } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";
import { RenderIncomingDocCheckedDate } from "./RenderIncomingDocCheckedDate.tsx";
import { RenderIncomingDocDetails } from "./RenderIncomingDocDetails.tsx";
import { RenderIncomingDocReceivedDate } from "./RenderIncomingDocReceivedDate.tsx";

interface IRenderIncomingDocListProps {
  documents?: ClinicalDocumentLite[];
  clinicalRecord: ClinicalRecord;
}

export const RenderIncomingDocList: React.FunctionComponent<
  IRenderIncomingDocListProps
> = ({ documents, clinicalRecord }) => {
  const [previewDocument, setPreviewDocument] = useState<Document | undefined>(
    undefined
  );

  return (
    <>
      {documents && documents?.length > 0 ? (
        <DetailsList
          setKey="recent incoming docs"
          items={documents}
          compact
          styles={() => ({
            root: {
              ".ms-DetailsRow-cell": { whiteSpace: "initial" }
            }
          })}
          columns={[
            {
              name: "Received",
              key: "received",
              minWidth: 130,
              maxWidth: 130,
              isMultiline: true,
              onRender: (item: ClinicalDocumentLite) => (
                <RenderIncomingDocReceivedDate
                  item={item}
                  clinicalRecord={clinicalRecord}
                />
              )
            },
            {
              name: "Checked",
              key: "checked",
              minWidth: 160,
              maxWidth: 160,
              isMultiline: true,
              onRender: (item: ClinicalDocumentLite) => (
                <RenderIncomingDocCheckedDate item={item} />
              )
            },
            {
              name: "Details",
              key: "details",
              minWidth: 0,
              flexGrow: 1,
              isMultiline: true,
              onRender: (item: ClinicalDocumentLite) => (
                <RenderIncomingDocDetails
                  item={item}
                  clinicalRecord={clinicalRecord}
                />
              )
            }
          ]}
          cellStyleProps={{
            cellExtraRightPadding: 24,
            cellRightPadding: 8,
            cellLeftPadding: 2
          }}
        />
      ) : (
        <PatientSummaryEmptyState displayText="No new documents" />
      )}

      {previewDocument && (
        <DocumentViewerDialog
          getDocument={() => previewDocument}
          closeDocumentViewer={() => setPreviewDocument(undefined)}
        />
      )}
    </>
  );
};
