import {
  QuickAccessSettingDto,
  QuickAccessType
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { EditQuickAccessDialogValues } from "./EditQuickAccessDialog.tsx";

const checkDuplicateQuickAccess = (
  values: EditQuickAccessDialogValues,
  quickAccessSetting: QuickAccessSettingDto
) => {
  const duplicateSettings = values.quickAccessSettings.filter(x =>
    x.documentId
      ? x.documentId === quickAccessSetting.documentId
      : x.title === quickAccessSetting.title
  );
  return duplicateSettings.length > 1
    ? ValidationMessages.duplicateLinks
    : undefined;
};

const checkSourceDeleted = (
  values: EditQuickAccessDialogValues,
  quickAccessSetting: QuickAccessSettingDto
) => {
  const quickAccessWithSourceCheck = values.quickAccessSettings.find(
    x =>
      x.code === quickAccessSetting.code &&
      x.icon === quickAccessSetting.icon &&
      x.documentId === quickAccessSetting.documentId &&
      x.title === quickAccessSetting.title
  );

  return !!quickAccessWithSourceCheck &&
    quickAccessWithSourceCheck?.isSourceTemplateDeleted &&
    quickAccessWithSourceCheck.code === QuickAccessType.Document
    ? ValidationMessages.missingSource
    : undefined;
};

export class EditQuickAccessSettingValidator extends Validator<QuickAccessSettingDto> {
  constructor(values: EditQuickAccessDialogValues) {
    super();
    this.forField("title", [
      required(),
      (title, quickAccessSetting) =>
        checkDuplicateQuickAccess(values, quickAccessSetting),
      (title, quickAccessSetting) =>
        checkSourceDeleted(values, quickAccessSetting)
    ]);
    this.forField("icon", [required()]);
  }
}

export class EditQuickAccessDialogValidator extends Validator<EditQuickAccessDialogValues> {
  constructor(values: EditQuickAccessDialogValues) {
    super();
    const editQuickAccessSettingValidator = new EditQuickAccessSettingValidator(
      values
    );
    this.forArrayField("quickAccessSettings", values => {
      return editQuickAccessSettingValidator.validate(values);
    });
  }
}
