import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ReviewFormValues } from "@shared-types/acc/claim-review-values.interface.ts";

import { reviewFormNameOf } from "./claim-review.utils.ts";

export class ClaimReviewValidator extends Validator<ReviewFormValues> {
  constructor() {
    super();
    this.forField(reviewFormNameOf("clinicalDirectorId"), required());

    this.forField(reviewFormNameOf("mechanismOfInjury"), maxLength(2000));
    this.forField(
      reviewFormNameOf("reviewInjuryAndDiagnosis"),
      maxLength(2000)
    );
    this.forField(
      reviewFormNameOf("statementOnCausationDetails"),
      maxLength(2000)
    );
    this.forField(
      reviewFormNameOf("reviewOfCurrentTreatment"),
      maxLength(2000)
    );
    this.forField(
      reviewFormNameOf("documentationOfAnyRecommendationsAndActions"),
      maxLength(2000)
    );
    this.forField(
      reviewFormNameOf("liaisonWithProviderUndertakenDetails"),
      maxLength(2000)
    );
  }
}
