import { observer } from "mobx-react-lite";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  Link,
  Stack,
  Text
} from "@bps/fluent-ui";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import {
  AppointmentStatusText,
  InvoiceStatusText
} from "@ui-components/RefText.tsx";

import { invoiceStatusIcon } from "../booking-calendar-event/BookingCalendarEvent.types.ts";
import { BillingStatus } from "./AppointmentFilter.types.ts";
import { getLinkText, getSelectedFilterStyles } from "./utils.ts";

interface AppointmentBillingStatusFilterProps {
  status: InvoiceStatus | BillingStatus;
  handleClick: () => void;
  events: CalendarEvent[];
}

export const AppointmentBillingStatusFilter: React.FC<AppointmentBillingStatusFilterProps> =
  observer(({ status, handleClick, events }) => {
    const { appointmentsFilter } = useBookingCalendarScreenContext();

    const getIconForInvoiceBillingStatus = (
      status: BillingStatus | InvoiceStatus
    ) => {
      const { Paid, Unpaid } = InvoiceStatus;
      const lookup = new Map<BillingStatus | InvoiceStatus, string>([
        [BillingStatus.Unbilled, "M365InvoicingLogo"],
        [Unpaid, invoiceStatusIcon[Unpaid]],
        [Paid, invoiceStatusIcon[Paid]]
      ]);

      const iconName = lookup.get(status);

      if (iconName) {
        return (
          <FontIcon
            iconName={iconName}
            styles={{
              root: {
                fontSize: FontSizes.large,
                verticalAlign: "middle"
              }
            }}
          />
        );
      }
      return;
    };

    const getTextForInvoiceBillingStatus = (
      status: BillingStatus | InvoiceStatus
    ) => {
      const lookup = new Map<BillingStatus | InvoiceStatus, string>([
        [BillingStatus.Unbilled, "Unbilled"],
        [InvoiceStatus.Unpaid, "Owing"]
      ]);

      const textForStatus = lookup.get(status);

      return textForStatus ? (
        <Text>{textForStatus}</Text>
      ) : (
        <InvoiceStatusText code={status} />
      );
    };

    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        {...dataAttribute(DataAttributes.Element, `today-appts-${status}`)}
        styles={getSelectedFilterStyles(status, appointmentsFilter)}
      >
        <Stack.Item>{getIconForInvoiceBillingStatus(status)}</Stack.Item>
        <Stack.Item>
          {getTextForInvoiceBillingStatus(status)}
          <AppointmentStatusText code={status} />:
        </Stack.Item>
        {events.length > 0 ? (
          <Link
            onClick={handleClick}
            {...dataAttribute(
              DataAttributes.Element,
              `today-appts-${status}-button`
            )}
          >
            {getLinkText(events.length, status, appointmentsFilter)}
          </Link>
        ) : (
          <Text>0</Text>
        )}
      </Stack>
    );
  });
