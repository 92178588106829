import React from "react";

import { AccordionItem, Separator } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";

import {
  MEDIUM_BREAKPOINT,
  SMALL_BREAKPOINT
} from "../../full-body-clinical-data/examination/utils.ts";
import { nameOfGeneralExaminationForm } from "./GeneralExaminationFields.tsx";
import { GeneralExaminationFormValues } from "./GeneralExaminationFormValues.ts";
import { GeneralExamWeightHeightBMIField } from "./GeneralExamWeightHeightBMIField.tsx";
import {
  getButtonGroupBreakLineStyles,
  getFieldSetStyles,
  getGeneralExamAccordionItemStyles,
  getSeparatorProps,
  getSpinButtonStyles
} from "./utils.ts";

interface AccordionItemBodyMeasurementProps {
  values: GeneralExaminationFormValues;
  width: number;
}
export const AccordionItemBodyMeasurement: React.FC<
  AccordionItemBodyMeasurementProps
> = ({ values, width }) => {
  const { clinical } = useStores();
  const spinButtonStyles = getSpinButtonStyles();
  const breakLines = width <= SMALL_BREAKPOINT;
  const wrapValues = width <= MEDIUM_BREAKPOINT;

  const { weight, height, bodyHabitus, neck, hip, waist, nails, hands } =
    values;

  const isEdited =
    !!weight ||
    !!height ||
    !!bodyHabitus ||
    !!neck ||
    !!hip ||
    !!waist ||
    (!!nails && nails.length > 0) ||
    (!!hands && hands.length > 0);

  const fieldItemStyles = {
    subComponentStyles: {
      label: {
        root: {
          minWidth: 80
        }
      }
    }
  };

  return (
    <AccordionItem
      title="Body measurements"
      showTitleIcon={isEdited}
      titleIconName="PageEdit"
      styles={getGeneralExamAccordionItemStyles()}
    >
      <Fieldset tokens={{ childrenGap: 8 }} frame styles={getFieldSetStyles()}>
        {/* Weight / Height / BMI / Habitus */}

        <GeneralExamWeightHeightBMIField wrapValues={wrapValues} />

        {/* Body habitus*/}
        <ButtonsGroupSingleChoiceField
          label="Body habitus"
          options={clinical.ref.bodyHabitus.keyTextValues}
          name={nameOfGeneralExaminationForm("bodyHabitus")}
          styles={getButtonGroupBreakLineStyles(breakLines)}
          vertical={breakLines}
        />

        {/* Circumference */}
        <Separator {...getSeparatorProps()} />
        <Fieldset
          tokens={{ childrenGap: 8 }}
          legend="Circumference"
          legendLevel="section-heading-light"
        >
          <SpinNumberInputField
            name={nameOfGeneralExaminationForm("neck")}
            min={0.1}
            max={99.9}
            step={0.1}
            precision={1}
            maxDigitsNumber={3}
            label="Neck (cm)"
            horizontalLabel
            styles={spinButtonStyles}
            fieldItemStyles={fieldItemStyles}
          />
          <SpinNumberInputField
            name={nameOfGeneralExaminationForm("hip")}
            min={0.1}
            max={99.9}
            step={0.1}
            precision={1}
            maxDigitsNumber={3}
            label="Hip (cm)"
            horizontalLabel
            styles={spinButtonStyles}
            fieldItemStyles={fieldItemStyles}
          />
          <SpinNumberInputField
            name={nameOfGeneralExaminationForm("waist")}
            min={0.1}
            max={99.9}
            step={0.1}
            precision={1}
            maxDigitsNumber={3}
            label="Waist (cm)"
            horizontalLabel
            styles={spinButtonStyles}
            fieldItemStyles={fieldItemStyles}
          />
        </Fieldset>

        {/* Extremities */}
        <Separator {...getSeparatorProps()} />
        <Fieldset legend="Extremities" legendLevel="section-heading-light">
          <StaticTagPickerField
            fetchDataSource={async () => {
              return clinical.ref.nails.keyNameValues;
            }}
            name={nameOfGeneralExaminationForm("nails")}
            label="Nails"
            formItemFieldStyles={{ item: { width: "100%" } }}
          />
          <StaticTagPickerField
            fetchDataSource={async () => {
              return clinical.ref.hands.keyNameValues;
            }}
            name={nameOfGeneralExaminationForm("hands")}
            label="Hands"
            formItemFieldStyles={{ item: { width: "100%" } }}
          />
        </Fieldset>
      </Fieldset>
    </AccordionItem>
  );
};
