import { useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  AllocationTransactionReferenceDto,
  InvoiceItemReferenceDto,
  ItemType,
  TransactionDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  RowOptions,
  TotalsDetails
} from "@modules/billing/screens/shared-components/TotalsDetails.tsx";
import { ItemTypeText } from "@ui-components/RefText.tsx";

import { InvoiceViewContext } from "../../context/InvoiceViewContext.tsx";
import { getViewPath } from "../utils.ts";

export const InvoiceDetailsRowTotals = () => {
  const { invoice } = useContext(InvoiceViewContext);

  const mapToRowOptions = (
    param: Omit<TransactionDto<InvoiceItemReferenceDto>, "items">
  ): RowOptions => {
    return {
      amount: param.total,
      noLinkText: `(${DateTime.fromISO(
        param.transactionDate
      )?.toDayDefaultFormat()})`,
      text: (
        <>
          <ItemTypeText code={param.itemType} />
          &nbsp;
          {param.number}
        </>
      ),
      id: param.id,
      path: getViewPath(param.itemType).path({ id: param.id })
    };
  };

  const transactionTypeItems = invoice?.transactionReferences
    .filter(
      x =>
        x.itemType === ItemType.WriteOff || x.itemType === ItemType.CreditNote
    )
    .map(mapToRowOptions);

  const paymentTypeItems = invoice?.allocationReferences.reduce(
    (accum: Array<RowOptions>, current: AllocationTransactionReferenceDto) => {
      const transactionItem = mapToRowOptions(current.transaction);

      const newAccum = [...accum, transactionItem];

      if (current.credit.itemType === ItemType.CreditNote) {
        const creditItem = mapToRowOptions(current.credit);

        return [...newAccum, creditItem];
      }

      return newAccum;
    },
    []
  );
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {paymentTypeItems?.length > 0 && (
        <TotalsDetails
          heading="Payments"
          rowOptions={paymentTypeItems}
          width="100%"
        />
      )}
      {transactionTypeItems?.length > 0 && (
        <TotalsDetails
          heading="Transactions"
          rowOptions={transactionTypeItems}
          width="100%"
        />
      )}
    </Stack>
  );
};
