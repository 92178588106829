import { FC } from "react";

import { LabeledText, Stack, Text, useTheme } from "@bps/fluent-ui";
import { PrivateInsurerDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { OrganisationRoleTypeText } from "@ui-components/RefText.tsx";

import { OrganisationLabels } from "../organisation.types.ts";

interface ViewPrivateInsurerFieldsProps {
  privateInsurer: PrivateInsurerDto;
  organisationRoleTypeCode: string;
}

export const ViewPrivateInsurerFields: FC<ViewPrivateInsurerFieldsProps> = ({
  privateInsurer,
  organisationRoleTypeCode
}) => {
  const theme = useTheme();
  return (
    <>
      {privateInsurer.accPrivateInsurer ? (
        <>
          <Stack horizontal>
            <Stack styles={{ root: { width: "25%" } }}>
              <LabeledText noSemicolon label={OrganisationLabels.Type} wrap>
                <OrganisationRoleTypeText code={organisationRoleTypeCode} />
              </LabeledText>
            </Stack>
            <Stack>
              <LabeledText
                noSemicolon
                label={OrganisationLabels.PrivateInsurerForAcc}
                wrap
              >
                Yes
              </LabeledText>
            </Stack>
          </Stack>
          <Stack horizontal styles={{ root: { paddingTop: 8 } }}>
            <Stack styles={{ root: { width: "50%" } }}>
              <LabeledText
                label={
                  privateInsurer?.differentInvoicingEmail
                    ? OrganisationLabels.Claims
                    : OrganisationLabels.ClaimsAndInvoicing
                }
                wrap
              >
                {privateInsurer.claimsAdminEmail ? (
                  <Stack.Item>{privateInsurer?.claimsAdminEmail}</Stack.Item>
                ) : (
                  <Text
                    styles={{
                      root: {
                        fontStyle: "italic",
                        color: theme.semanticColors.disabledBodyText
                      }
                    }}
                  >
                    {OrganisationLabels.EmailNotProvided}
                  </Text>
                )}
              </LabeledText>
            </Stack>
            {privateInsurer?.differentInvoicingEmail && (
              <Stack styles={{ root: { width: "50%" } }}>
                <LabeledText label={OrganisationLabels.Invoicing} wrap>
                  {privateInsurer.invoicingEmail ? (
                    <Stack.Item>{privateInsurer.invoicingEmail}</Stack.Item>
                  ) : (
                    <Text
                      styles={{
                        root: {
                          fontStyle: "italic",
                          color: theme.semanticColors.disabledBodyText
                        }
                      }}
                    >
                      {OrganisationLabels.EmailNotProvided}
                    </Text>
                  )}
                </LabeledText>
              </Stack>
            )}
          </Stack>
        </>
      ) : (
        <Stack horizontal>
          <Stack styles={{ root: { width: "25%" } }}>
            <LabeledText noSemicolon label={OrganisationLabels.Type} wrap>
              <OrganisationRoleTypeText code={organisationRoleTypeCode} />
            </LabeledText>
          </Stack>
          <Stack>
            <LabeledText
              noSemicolon
              label={OrganisationLabels.PrivateInsurerForAcc}
              wrap
            >
              No
            </LabeledText>
          </Stack>
        </Stack>
      )}
    </>
  );
};
