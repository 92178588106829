import { observer } from "mobx-react-lite";
import { FC } from "react";

import { ActionButton, TopBarDefaultHeader } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

export const ClaimAdjustmentFormHeader: FC = observer(() => {
  const { routing, acc, clinical } = useStores();
  const { claimAdjustment, claim } = useClaimAdjustmentContext();

  const primaryDiagnosisDescription = Array.from(
    acc.accTerminologyMap.values()
  ).find(x => x.code === claim.primaryDiagnosis?.terminology?.code)?.text;

  const handleOnBackClicked = () => {
    routing.goToFromState(routes.claims.basePath.pattern);
  };

  const disabled =
    !clinical.ui.clinicalTabs.currentPatientRecordTab?.activeTab?.isDirty;

  const adjustmentButton = (
    <ActionButton
      styles={{ root: { padding: "5px 9px" } }}
      iconProps={{ iconName: "DocumentSet" }}
      text="ACC32 page"
      onClick={() =>
        routing.push(
          routes.claimAdjustment.edit.path({
            claimId: claim.id,
            id: claimAdjustment!.id!
          }),
          routing.getStateWithFromQuery()
        )
      }
    />
  );

  return (
    <TopBarDefaultHeader
      backButtonOnClick={handleOnBackClicked}
      hideBackButton
      heading={`${claim.claimNumber} ${primaryDiagnosisDescription ?? ""}`}
      rightContainer={
        <FormSubmitButtons
          hideButtonsSeparator
          hideCancel
          styles={{ root: { padding: 0, margin: 0 } }}
          extraActionsBefore={() => adjustmentButton}
          submitButtonProps={{ disabled }}
        />
      }
    />
  );
});
