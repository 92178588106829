import { parse } from "query-string";

import {
  MIN_SEARCH_CHAR_DATE_OF_BIRTH,
  MIN_SEARCH_CHAR_PATIENT_NAME,
  MIN_SEARCH_CHAR_PHONE_NUMBER
} from "@modules/practice/utils.ts";
import {
  IndividualAndOrgContactFilter,
  PatientContactFilter
} from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  hasValidBirthdayData,
  hasValidNameData,
  hasValidPhoneNumberData
} from "@ui-components/pickers/contact-picker/contact-picker.utils.ts";

export const useParseContactFilters = (filterKeys: Record<string, string>) => {
  const { routing } = useStores();

  const parseArrayFilter = () => {
    const filter = {};

    if (routing.location.search) {
      const parsedFilter = parse(routing.location.search, {
        parseBooleans: true
      });

      // convert in array is one only selected, but not search filter
      Object.entries(parsedFilter).forEach(([key, value]) => {
        if (
          key !== filterKeys.search &&
          key !== filterKeys.isHealthProvider &&
          value &&
          !Array.isArray(value)
        ) {
          filter[key] = Array(value);
        } else {
          filter[key] = value;
        }
      });
    }
    return filter;
  };

  const setLocationFromFilter = (
    field: keyof PatientContactFilter | keyof IndividualAndOrgContactFilter,
    value: string | string[] | boolean | undefined
  ) => {
    if (typeof value === "boolean") {
      routing.replaceQueryStringParam(field, value.toString());
    } else if (field === "search") {
      if (value && !Array.isArray(value)) {
        const isValidName = hasValidNameData(value);
        const isValidBirthdayDate = hasValidBirthdayData(value);
        const isValidPhoneNumber = hasValidPhoneNumberData(value);
        if (
          isValidName &&
          !isValidPhoneNumber &&
          value.length >= MIN_SEARCH_CHAR_PATIENT_NAME
        ) {
          routing.replaceQueryStringParam(field, value);
        } else if (
          isValidPhoneNumber &&
          value.length >= MIN_SEARCH_CHAR_PHONE_NUMBER
        ) {
          routing.replaceQueryStringParam(field, value);
        } else if (
          isValidBirthdayDate &&
          value.length >= MIN_SEARCH_CHAR_DATE_OF_BIRTH
        ) {
          routing.replaceQueryStringParam(field, value);
        }
      } else {
        routing.replaceQueryStringParam(field, value);
      }
    } else {
      routing.replaceQueryStringParam(field, value);
    }
  };

  return { parseArrayFilter, setLocationFromFilter };
};
