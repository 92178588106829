import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { FontSizes, mergeStyles, NativeList } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { Amendment } from "@stores/clinical/models/Amendments.ts";

interface PastVisitAmendmentsProps {
  amendments: Amendment[];
}

export const PastVisitAmendments: FunctionComponent<PastVisitAmendmentsProps> =
  observer(({ amendments }) => {
    if (!amendments.length) return null;

    return (
      <NativeList
        orderedList
        styles={{ root: { listStyle: "decimal", paddingLeft: 20 } }}
      >
        {amendments.map(amendment => {
          const dateTime = DateTime.fromISO(amendment.createdDate);

          const dateString = dateTime?.toDayDefaultFormat();

          const timeString = dateTime?.toFormat(
            TIME_FORMATS.DEFAULT_TIME_FORMAT
          );

          return (
            <li
              key={amendment.id}
              className={mergeStyles({
                paddingLeft: 8,
                paddingBottom: 8,
                fontStyle: "italic",
                fontSize: FontSizes.size12
              })}
            >
              {dateTime && (
                <strong>
                  {`Consult notes appended on ${dateString} at ${timeString}`}
                </strong>
              )}
              <p>{amendment.text}</p>
            </li>
          );
        })}
      </NativeList>
    );
  });
