import debounce from "lodash.debounce";
import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { toAttendees } from "@stores/booking/models/CalendarEvent.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { UnavailableFormValues } from "./UnavailableForm.types.ts";

export class UnavailableFormHelper {
  constructor(private booking: BookingStore) {}

  private get core(): CoreStore {
    return this.booking.core;
  }

  @computed
  get calendarEventId() {
    return this.booking.ui.currentAppointment?.id;
  }

  @computed
  get calendarEvent() {
    return this.calendarEventId
      ? this.booking.calendarEventsMap.get(this.calendarEventId)
      : undefined;
  }

  public onSubmitted = () => this.booking.ui.hideCalendarEventDialog();

  public getUnavailableDefaultValues = (
    _startDate: DateTime
  ): UnavailableFormValues | undefined => {
    // hours only formatted time sting
    const startTime: string = DateTime.now()
      .startOf("hour")
      .toTimeInputFormat();

    // hours only + 30 minutes formatted time sting
    const endTime: string = DateTime.now()
      .startOf("hour")
      .plus({ minutes: 30 })
      .toTimeInputFormat();

    const startDate = _startDate.startOf("day").toJSDate();

    return {
      startDate,
      endDate: startDate,
      endTime,
      startTime
    };
  };

  public showCancelEvent = () => {
    this.booking.ui.setShowCancelCalendarEventDialog(
      true,
      this.calendarEventId
    );
  };

  public getInitialValues = (
    startDate: DateTime
  ): UnavailableFormValues | undefined => {
    if (this.calendarEvent) {
      return {
        providerId: this.calendarEvent.userId,
        startDate: this.calendarEvent.startDateTime.toJSDate(),
        startTime: this.calendarEvent.startDateTime.toTimeInputFormat(),
        endDate: this.calendarEvent.endDateTime.toJSDate(),
        endTime: this.calendarEvent?.endDateTime.toTimeInputFormat(),
        comments: this.calendarEvent.content
      };
    }
    return this.getUnavailableDefaultValues(startDate);
  };

  public handleSubmit = async (values: UnavailableFormValues) => {
    if (values.startDate && values.startTime && values.endDate) {
      const startTime = DateTime.fromJSDateAndTime(
        values.startDate,
        values.startTime
      );

      const endTime = DateTime.fromJSDateAndTime(
        values.endDate,
        values.endTime
      );

      const baseRequest = {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        content: values.comments,
        attendees: toAttendees({
          userId: values.providerId
        })
      };

      if (this.calendarEvent) {
        await this.booking.updateCalendarEvent({
          id: this.calendarEvent.id,
          ...baseRequest
        });
      } else {
        await this.booking.addCalendarEvent({
          type: CalendarEventType.Unavailable,
          orgUnitId: this.core.locationId,
          ...baseRequest
        });
      }
    }
  };

  @computed
  get bookedByUser() {
    return this.calendarEvent && this.calendarEvent.bookedByUser;
  }

  @computed
  get bookedDate() {
    return this.calendarEvent && this.calendarEvent?.startDateTime;
  }

  getBaseRequest = (values: UnavailableFormValues) => {
    if (values.startDate && values.startTime && values.endDate) {
      const startTime = DateTime.fromJSDateAndTime(
        values.startDate,
        values.startTime
      );

      const endTime = DateTime.fromJSDateAndTime(
        values.endDate,
        values.endTime
      );
      return {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        bookedBy: this.core.userId,
        content: values.comments,
        attendees: toAttendees({
          userId: values.providerId
        }),
        orgUnitId: this.core.locationId
      };
    }

    return undefined;
  };

  temporaryReservationDebounce = debounce(
    async (formValues: UnavailableFormValues) => {
      if (!formValues) return;
      if (
        formValues.providerId &&
        formValues.endDate &&
        formValues.endTime &&
        formValues.startDate &&
        formValues.startTime &&
        DateTime.fromJSDateAndTime(formValues.endDate, formValues.endTime) >
          DateTime.fromJSDateAndTime(formValues.startDate, formValues.startTime)
      ) {
        const request = this.getBaseRequest(formValues);
        if (request) {
          await this.booking.addTemporaryReservation({
            ...request,
            type: CalendarEventType.TemporaryReservation
          });
        }
      }
      return;
    },
    500
  );

  // asyncCreateTemporaryReservation returns promise when debounce action is completed
  // it help to close sidePanel after temporary reservation is created
  asyncCreateTemporaryReservation = (formValues: UnavailableFormValues) => {
    return new Promise(resolve => {
      resolve(this.temporaryReservationDebounce(formValues));
    });
  };
}
