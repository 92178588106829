import { observer } from "mobx-react-lite";

import { Stack, useTheme } from "@bps/fluent-ui";
import { useTransactionListContext } from "@modules/billing/screens/shared-components/AllocationList/transaction-list/context/TransactionListContext.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";

import { TransactionRow } from "./TransactionList.tsx";

const itemRowStyles = {
  root: {
    ".ms-DetailsRow": { borderBottom: "none" }
  }
};

export const CollapsibleRow: React.FC<{
  item: TransactionBase;
  renderRow: (row: TransactionRow) => JSX.Element | null;
}> = observer(({ item, renderRow }) => {
  const theme = useTheme();

  const { rowsToggledOpen } = useTransactionListContext();

  return (
    <Stack
      styles={{
        root: {
          overflow: "hidden",
          borderBottom: `1px solid ${theme.palette.neutralLight}`
        }
      }}
    >
      <Stack>
        <Stack styles={itemRowStyles}>{renderRow({ item })}</Stack>
      </Stack>
      {rowsToggledOpen.has(item.id) && (
        <Stack
          styles={{
            root: {
              backgroundColor: theme.palette.neutralLighterAlt,
              ".ms-DetailsRow": {
                backgroundColor: theme.palette.neutralLighterAlt,
                transition: "background-color 0.3s"
              }
            }
          }}
        >
          {item.items?.map(subItem => (
            <Stack key={item.id} styles={itemRowStyles}>
              {renderRow({ item, subItem })}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
});
