import { createContext, useContext } from "react";

import { TransactionListHelper } from "./TransactionListHelper.ts";

export const TransactionListContext = createContext<TransactionListHelper>(
  {} as TransactionListHelper
);

export const useTransactionListContext = () =>
  useContext(TransactionListContext);
