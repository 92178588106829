import { FC } from "react";

import { Heading } from "@bps/fluent-ui";
import { IFrameDialog } from "@ui-components/IFrameDialog.tsx";

export const QRCodeDialog: FC<{
  hostingUrl: string;
  onClose: () => void;
  title?: string;
}> = ({ hostingUrl, title, ...props }) => (
  <IFrameDialog
    title={<Heading variant="modal-heading">{title || "QR Code"}</Heading>}
    url={hostingUrl.replace("?id=", "/qrcode/?id=")}
    styles={{
      root: {
        iframe: { height: "60vh", width: "47.5vw" }
      }
    }}
    minWidth="50vw"
    maxWidth="300px"
    {...props}
  />
);
