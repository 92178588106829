import { unique } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export interface OptionsParameters {
  showBusinessRole?: boolean;
  displayDiagnosis?: boolean;
  pickByClaimNumber?: boolean;
}

export const getOptions = (
  claims: Claim[],
  root: RootStore,
  props: OptionsParameters
) => {
  const options: { key: string; text: string }[] = [];
  claims.forEach(claim => {
    if (
      props.showBusinessRole &&
      root.core.hasPermissions(Permission.MultiProviderClaimsAllowed)
    ) {
      let text: string = claim.claimNumber || "Undefined";

      if (props.displayDiagnosis) {
        const diagnosisDescription = claim.primaryDiagnosis?.terminology?.text;

        if (diagnosisDescription) {
          const MAXDIAGNOSISTEXTLENGTH = 35;
          const disagnosisText =
            diagnosisDescription.length >= MAXDIAGNOSISTEXTLENGTH
              ? `${diagnosisDescription.slice(0, MAXDIAGNOSISTEXTLENGTH)}...`
              : diagnosisDescription;
          text = `${text} | ${disagnosisText}`;
        }
      }

      // checks to see if any claim appointments have different providerTypes - makes it a multiProvider claim
      const multiBusinessRoles = claim.claimAppointments.some(x =>
        claim.claimAppointments.some(
          y => y.id !== x.id && y.providerType !== x.providerType
        )
      );

      if (multiBusinessRoles) {
        const businessRoles = claim.claimAppointments.map(
          x =>
            root.practice.ref.accProviderContractTypes.values.find(
              ref =>
                ref.providerTypeCode === x.providerType &&
                ref.accPracticeContractTypeCode === x.contractType
            )?.businessRoleCode
        );
        if (businessRoles && businessRoles.length > 0) {
          const uniqueBusinessRoles = unique(businessRoles);

          // we want an option for each claim/providerType
          uniqueBusinessRoles.forEach(role => {
            let businessRoleText = text;
            const businessRole = root.core.catalogBusinessRoles.find(
              x => x.code === role
            );

            let key = props.pickByClaimNumber
              ? claim.claimNumber || ""
              : claim.id;

            if (businessRole) {
              businessRoleText = `${businessRoleText} | ${businessRole.text}`;
              key = `${key}/${role}`;
            }

            const option = {
              key,
              text: businessRoleText
            };

            options.push(option);
          });
        }
      } else {
        const option = {
          key: `${
            props.pickByClaimNumber ? claim.claimNumber || "" : claim.id
          }`,
          text
        };

        options.push(option);
      }
    } else {
      let text: string = claim.claimNumber || "Not lodged";

      const providerCode =
        claim.providerContractType?.accPracticeContractTypeCode;

      if (providerCode) {
        text += ` | ${providerCode}`;
      }

      options.push({
        key: props.pickByClaimNumber ? claim.claimNumber || "" : claim.id,
        text
      });
    }
  });
  return options;
};
export const splitClaimKeyString = (claimKey: string | undefined) => {
  const claimInfo = claimKey ? claimKey.split("/") : undefined;
  let keyId: string | undefined;
  let keyBusinessCode: string | undefined;

  if (claimInfo && claimInfo.length === 2) {
    keyId = claimInfo[0];
    keyBusinessCode = claimInfo[1];
  } else {
    keyId = claimKey;
  }

  return {
    keyId,
    keyBusinessCode
  };
};
