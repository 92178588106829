import { ConfirmDialog, Heading, Stack } from "@bps/fluent-ui";

export interface DeleteActivityDialogProps {
  hidden: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DeleteActivityDialog: React.FC<DeleteActivityDialogProps> = ({
  hidden,
  onCancel,
  onConfirm
}) => {
  return (
    <ConfirmDialog
      hidden={hidden}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonProps={{
        text: "Delete"
      }}
      dialogContentProps={{
        title: (
          <Heading variant="modal-heading">Delete selected reminders</Heading>
        ),
        showCloseButton: true
      }}
    >
      <Stack>Are you sure you want to delete selected reminders?</Stack>
    </ConfirmDialog>
  );
};
