import { memo } from "react";
import { useForm } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { MotionAssessmentLabels } from "../SOTAP.constants.ts";
import { MotionAssessmentTable } from "./MotionAssessmentTable.tsx";

export interface InjuryAreaMotionAssessmentFieldsProps {
  name: string;
  injuryAreaGroup: string;
  injuryArea: string;
  injurySide?: string;
}

export const InjuryAreaMotionAssessmentFields: React.FC<InjuryAreaMotionAssessmentFieldsProps> =
  memo(({ name, injuryAreaGroup, injuryArea, injurySide }) => {
    const form = useForm<SOTAPFormValues>();
    const getItemIndex = (
      itemInjuryArea: string,
      itemInjuryAreaGroup: string,
      itemInjurySide?: string
    ) => {
      const { values } = form.getState();
      if (!values.injuryAreaMotionAssessments) return undefined;
      return values.injuryAreaMotionAssessments.findIndex(
        x =>
          x.injuryArea === itemInjuryArea &&
          x.injuryAreaGroup === itemInjuryAreaGroup &&
          x.injurySide === itemInjurySide
      );
    };

    const index = getItemIndex(injuryArea, injuryAreaGroup, injurySide);

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        <MotionAssessmentTable
          name={`${name}[${index}].motionTypes`}
          injuryArea={injuryArea}
          injuryAreaGroup={injuryAreaGroup}
          injurySide={injurySide}
        />
        <TextInputField
          label={MotionAssessmentLabels.Strength}
          name={`${name}[${index}].strength`}
          multiline={true}
          rows={3}
          autoAdjustHeight
          resizable={false}
          styles={{ fieldGroup: { minHeight: 60 } }}
        />
        <TextInputField
          label={MotionAssessmentLabels.Palpation}
          name={`${name}[${index}].palpation`}
          multiline={true}
          rows={3}
          autoAdjustHeight
          resizable={false}
          styles={{ fieldGroup: { minHeight: 60 } }}
        />
        <TextInputField
          label={MotionAssessmentLabels.SpecialTests}
          name={`${name}[${index}].specialTests`}
          multiline={true}
          rows={3}
          autoAdjustHeight
          resizable={false}
          styles={{ fieldGroup: { minHeight: 60 } }}
        />
      </Stack>
    );
  });
