import { observer } from "mobx-react-lite";
import React from "react";

import { Heading, Stack, Text, useTheme } from "@bps/fluent-ui";
import {
  AddBookingTenantSettingsDto,
  PatchBookingTenantSettingsDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { getUserStylesSet } from "../../../../users/components/UserScreens.styles.tsx";
import { ClinicalReminderPreferenceFields } from "./ClinicalReminderPreferenceFields.tsx";
import { BookingTenantSettingValues } from "./CommunicationPreferences.types.ts";
import {
  tenantSettingFormDropdown,
  TenantSettingsFormsDropDown
} from "./TenantSettingsFormDropDown.tsx";

const nameOf = nameOfFactory<BookingTenantSettingValues>();

const BookingTenantSettingsFormBase: React.FC = observer(() => {
  const { booking, clinical, formDesign, core, notification } = useStores();

  const theme = useTheme();
  const { formFooter, formFields } = getUserStylesSet(theme);

  const getFormIdFromTypeCode = (code: string) => {
    const design = formDesign.formDesigns?.find(x => x.formTypeCode === code);
    return design?.id;
  };

  const tenantSettings = booking.tenantSettings;
  const initialValues: Partial<BookingTenantSettingValues> = {
    apptConfirmationEnabled:
      tenantSettings?.appointmentConfirmationSettings?.isActive ?? false,
    demographics: getFormIdFromTypeCode(
      tenantSettingFormDropdown.demographics.typeCode
    ),
    acc: getFormIdFromTypeCode(tenantSettingFormDropdown.acc.typeCode),
    accDemographics: getFormIdFromTypeCode(
      tenantSettingFormDropdown.accDemographics.typeCode
    ),
    allowReminderFreeText:
      !!clinical.clinicalActivitiesPreference?.allowReminderFreeText
  };

  const clinicalActivityPreference = clinical.clinicalActivitiesPreference?.dto;

  const onSubmit = async (values: BookingTenantSettingValues) => {
    if (tenantSettings) {
      const dataPatch: PatchBookingTenantSettingsDto = {
        appointmentConfirmationSettings: {
          isActive: values.apptConfirmationEnabled
        },
        eTag: tenantSettings.eTag!
      };
      await booking.updateTenantSettings(dataPatch);
    } else {
      const dataAdd: AddBookingTenantSettingsDto = {
        appointmentConfirmationSettings: {
          isActive: values.apptConfirmationEnabled
        }
      };
      await booking.addTenantSettings(dataAdd);
    }
    if (
      values.demographics &&
      values.demographics !==
        getFormIdFromTypeCode(tenantSettingFormDropdown.demographics.typeCode)
    ) {
      await formDesign.setFormTypeCode(
        values.demographics,
        tenantSettingFormDropdown.demographics.typeCode
      );
    }
    if (
      values.acc &&
      values.acc !==
        getFormIdFromTypeCode(tenantSettingFormDropdown.acc.typeCode)
    ) {
      await formDesign.setFormTypeCode(
        values.acc,
        tenantSettingFormDropdown.acc.typeCode
      );
    }
    if (
      values.accDemographics &&
      values.accDemographics !==
        getFormIdFromTypeCode(
          tenantSettingFormDropdown.accDemographics.typeCode
        )
    ) {
      await formDesign.setFormTypeCode(
        values.accDemographics,
        tenantSettingFormDropdown.accDemographics.typeCode
      );
    }
    if (
      clinicalActivityPreference &&
      core.hasPermissions(Permission.ClinicalReminderPreferenceAllowed)
    ) {
      const updatedClinReminderPreferences = {
        id: clinicalActivityPreference.id,
        allowReminderFreeText: !!values.allowReminderFreeText,
        eTag: clinicalActivityPreference.eTag
      };
      await clinical.patchClinicalActivitiesPreference(
        updatedClinReminderPreferences
      );
    }
  };

  return (
    <SubmissionForm<BookingTenantSettingValues>
      formName="communications-preferences"
      onSubmitSucceeded={() => {
        notification.success("Communications preference has been updated.");
      }}
      readOnly={!core.hasPermissions(Permission.OrgUnitSettingWrite)}
      autoFocus={false}
      onSubmit={onSubmit}
      initialValues={initialValues}
      buttonsProps={{
        disableCancelOnPristine: true,
        styles: {
          root: formFooter
        }
      }}
    >
      {() => {
        return (
          <Stack
            styles={{
              root: formFields
            }}
            tokens={{ childrenGap: 24 }}
          >
            <Stack
              tokens={{ childrenGap: 16 }}
              styles={{ root: { paddingBottom: 10 } }}
            >
              <Heading variant="section-heading-light">Appointments</Heading>
              <When permission={Permission.ApptConfirmationsAllowed}>
                <Stack
                  tokens={{ childrenGap: 5 }}
                  styles={{ root: { width: 250 } }}
                >
                  <Heading variant="section-sub-heading">Confirmation</Heading>
                  <Text>Send confirmation of appt</Text>
                  <ToggleField
                    styles={{ root: { marginBottom: 0 } }}
                    onText="Yes"
                    offText="No"
                    name={nameOf("apptConfirmationEnabled")}
                  />
                </Stack>
              </When>
            </Stack>

            <When permission={Permission.FormDesignWrite}>
              <Stack tokens={{ childrenGap: 8 }}>
                <Heading variant="section-heading-light">
                  Online forms - appointment screen
                </Heading>
                <TenantSettingsFormsDropDown formKey="demographics" />
                <TenantSettingsFormsDropDown formKey="acc" />
                <TenantSettingsFormsDropDown formKey="accDemographics" />
              </Stack>
            </When>

            <When permission={Permission.ClinicalReminderPreferenceAllowed}>
              <ClinicalReminderPreferenceFields />
            </When>
          </Stack>
        );
      }}
    </SubmissionForm>
  );
});

export const BookingTenantSettingsForm = withFetch(async ({ formDesign }) => {
  return {
    formDesigns: await formDesign.getFormDesigns()
  };
}, BookingTenantSettingsFormBase);
