import { observer } from "mobx-react-lite";

import { Spinner } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ClinicalDocumentLite } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { usePatientSummaryContext } from "./helpers/PatientSummaryContext.tsx";
import { RenderIncomingDocList } from "./RenderIncomingDocList.tsx";

interface ClinicalToolComponentProps {
  clinicalRecord: ClinicalRecord;
  lastConsultDate: DateTime;
}

export const RecentIncomingDocComponent: React.FC<ClinicalToolComponentProps> =
  observer(({ clinicalRecord, lastConsultDate }) => {
    const { correspondence } = useStores();
    const { searchIncomingDocs } = usePatientSummaryContext();

    const getContent = (documents?: ClinicalDocumentLite[]) => {
      return (
        <RenderIncomingDocList
          clinicalRecord={clinicalRecord}
          documents={documents}
        />
      );
    };

    return (
      <DataFetcher
        fetch={async () => {
          return await searchIncomingDocs(clinicalRecord.id, lastConsultDate);
        }}
        fallback={<Spinner />}
        refetchId={correspondence.editInboxRefreshKey}
      >
        {documents => getContent(documents)}
      </DataFetcher>
    );
  });
