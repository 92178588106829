import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { Card, Stack } from "@bps/fluent-ui";
import { ClaimsScreenContext } from "@modules/acc/screens/claims/context/ClaimsScreenContext.ts";
import { ClaimsScreenHelper } from "@modules/acc/screens/claims/context/ClaimsScreenHelper.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClaimsFilter } from "./components/claims-filter/ClaimsFilter.tsx";
import { ClaimsList } from "./components/ClaimsList.tsx";
import { NewClaimButtonsGroup } from "./components/NewClaimButtonsGroup.tsx";

const ClaimsScreenBase: React.FC = observer(() => {
  return (
    <Card
      heading="Claims"
      headingLevel="section-heading-light"
      button={<NewClaimButtonsGroup />}
      styles={{
        header: { padding: "8px 0 12px 8px" },
        subComponentStyles: {
          tile: {
            content: {
              padding: 0,
              position: "relative"
            },
            header: { border: "none" },
            root: {
              height: "100%",
              padding: 16,
              maxWidth: 1500,
              margin: "0 auto"
            }
          }
        }
      }}
    >
      <Stack styles={{ root: { height: "100%" } }}>
        <ClaimsFilter>
          <ClaimsList />
        </ClaimsFilter>
      </Stack>
    </Card>
  );
});

const ClaimsScreen: React.FC = () => {
  const root = useStores();
  const claimsScreenHelper = useRef(new ClaimsScreenHelper(root));

  return (
    <ClaimsScreenContext.Provider value={claimsScreenHelper.current}>
      <ClaimsScreenBase />
    </ClaimsScreenContext.Provider>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClaimsScreen;
