import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import {
  FontIcon,
  FontSizes,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { UserContext } from "@modules/settings/screens/users/components/context/UserContext.tsx";

interface LicenceTypesProps {
  userId: string;
}

export const LicenceTypes: FC<LicenceTypesProps> = observer(
  ({ userId }: LicenceTypesProps) => {
    const { palette } = useTheme();
    const helper = useContext(UserContext);

    const getTooltipMessage = (): string | undefined => {
      // Get the user licences with descending order by the expiry date
      const licences = helper.core.licences
        .filter(x => x.userId === userId)
        .sort((a, b) => b.expiryDate.diff(a.expiryDate, "days").days);

      // Don't have any licences
      if (licences.length === 0)
        return "This user requires a licence for full access";

      // Have any vailid licences?
      const validLicence = licences.find(x => x.isValid);
      if (validLicence) {
        // Is the licence in the grace period?
        return validLicence.isLicenceInGracePeriod
          ? `The licence expired on ${validLicence.expiryDate.toDayDefaultFormat()}, this user requires a licence from ${validLicence.gracePeriodEndDate.toDayDefaultFormat()}  for full access`
          : undefined;
      }

      // Reach this far, all licences have been expired
      // Get the last expired licence
      const expiredLicence = licences.filter(x => x.isExpired).find(() => true);
      return expiredLicence
        ? `The licence expired on ${expiredLicence.expiryDate.toDayDefaultFormat()}`
        : "The licence expired";
    };

    // Get a tooltip message
    const tooltipMessage = getTooltipMessage();
    return (
      <Stack horizontal horizontalAlign="space-between" grow>
        <Text>Allied</Text>
        {tooltipMessage && (
          <TooltipHost content={tooltipMessage}>
            <FontIcon
              iconName="Info"
              styles={{
                root: {
                  fontSize: FontSizes.size20,
                  verticalAlign: "middle",
                  color: palette.orange
                }
              }}
            />
          </TooltipHost>
        )}
      </Stack>
    );
  }
);
