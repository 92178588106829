import { FunctionComponent, useRef } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import { getId, mergeFuncStyles, useTheme } from "@bps/fluent-ui";
import { identity } from "@libs/utils/utils.ts";
import {
  ExposedFieldProps,
  getFieldErrorMessage
} from "@ui-components/form/FinalForm.tsx";
import {
  FormItemField,
  FormItemFieldProps
} from "@ui-components/form/FormItemField.tsx";

import { ServicePicker } from "./ServicePicker.tsx";
import { ServicePickerProps } from "./ServicePicker.types.ts";

type HtmlElementType = any;

export type ServicePickerAdapterProps = Pick<ServicePickerProps, "styles"> &
  Pick<
    FormItemFieldProps,
    "required" | "label" | "validateOnInitialize" | "placeholder" | "disabled"
  > & {
    fieldItemStyles?: FormItemFieldProps["styles"];
  };

export type ServicePickerFieldProps = ExposedFieldProps<
  ServicePickerProps["selectedKey"],
  HtmlElementType
> &
  ServicePickerAdapterProps;

type InternalServicePickerAdapterProps = FieldRenderProps<
  ServicePickerProps["selectedKey"],
  HtmlElementType
> &
  ServicePickerAdapterProps;

const ServicePickerAdapter: FunctionComponent<
  InternalServicePickerAdapterProps
> = ({
  input,
  onChange,
  meta,
  validateOnInitialize,
  styles,
  fieldItemStyles,
  required,
  label,
  inputProps,
  placeholder,
  ...servicePickerProps
}) => {
  const id = useRef(getId(input.name));
  const theme = useTheme();

  const hasErrorMessage = !!getFieldErrorMessage(meta, validateOnInitialize);

  const newStyles = mergeFuncStyles(
    {
      text: [
        { backgroundColor: theme.palette.white },
        hasErrorMessage && {
          borderColor: theme.semanticColors.errorText,
          selectors: {
            "&:after": {
              borderColor: theme.semanticColors.errorText
            }
          }
        }
      ]
    },
    styles
  );

  const onChangeHandle = (value: string | undefined) => {
    input.onChange(value);
    onChange && onChange(value);
    input.onFocus();
  };

  return (
    <FormItemField
      name={input.name}
      required={required}
      label={label}
      styles={fieldItemStyles}
      htmlFor={id.current}
      validateOnInitialize={validateOnInitialize}
    >
      <ServicePicker
        {...servicePickerProps}
        disabled={servicePickerProps.disabled}
        onChange={onChangeHandle}
        selectedKey={input.value}
        inputProps={{
          placeholder,
          onBlur: input.onBlur,
          onFocus: input.onFocus,
          id: id.current
        }}
        styles={newStyles}
      />
    </FormItemField>
  );
};

/**
 * Use ServicePickerField to use ServicePicker with final-form.
 * It also wraps the ServicePicker in a FormItemField to support label and error message.
 * @param props
 */
export const ServicePickerField = (props: ServicePickerFieldProps) => (
  <Field
    format={identity}
    parse={identity}
    {...props}
    component={ServicePickerAdapter}
  />
);
