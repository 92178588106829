import { useForm } from "react-final-form";

import {
  AnimatedListWithKeys,
  DefaultButton,
  FontSizes,
  Heading,
  IContextualMenuItem,
  Link,
  NoDataTile,
  Stack
} from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  OrganisationRoleDto,
  OrganisationRoleType,
  OrganisationRoleTypeCode
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { OrgRoleTypeText } from "@ui-components/RefText.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { OrganisationLabels } from "../organisation.types.ts";
import {
  EditOrganisationFormValues,
  orgEditFormNameOf
} from "./EditOrganisationFormValues.tsx";
import { EmployerFields } from "./EmployerFields.tsx";
import { PrivateInsurerFields } from "./PrivateInsurerFields.tsx";
import { defaultEmployer, defaultPrivateInsurer } from "./utils.ts";

export const isPrivateInsurer = (code: OrganisationRoleTypeCode) =>
  code.organisationRoleTypeCode === OrganisationRoleType.NzPrivateInsurer ||
  code.organisationRoleTypeCode === OrganisationRoleType.AuPrivateInsurer;

const EditOrganisationTypeBase: React.FC = () => {
  const { mutators, change } = useForm<EditOrganisationFormValues>();
  const { fields } = useFieldArray<OrganisationRoleTypeCode | undefined>(
    nameof("organisationRoles")
  );

  const { core } = useStores();

  const isPublicInsurer = (code: OrganisationRoleTypeCode) =>
    code.organisationRoleTypeCode === OrganisationRoleType.NzPublicInsurer ||
    code.organisationRoleTypeCode === OrganisationRoleType.AuPublicInsurer;

  const isEmployer = (code: OrganisationRoleTypeCode) =>
    code.organisationRoleTypeCode === OrganisationRoleType.NzEmployer ||
    code.organisationRoleTypeCode === OrganisationRoleType.AuEmployer;

  const privateInsurerExists =
    fields.value && fields.value.filter(isPrivateInsurer).length;

  const employerExists = fields.value && fields.value.filter(isEmployer).length;
  const publicInsurerExists =
    fields.value && fields.value.filter(isPublicInsurer);

  const hideAddButton: boolean =
    !!(privateInsurerExists && employerExists && publicInsurerExists) ||
    (fields.value && fields.value.length > 3);

  const country = core.tenantDetails!.country;
  const addEmployer = () => {
    mutators.push(
      orgEditFormNameOf("organisationRoles"),
      defaultEmployer(country)
    );
  };

  const addPrivateInsurer = () => {
    mutators.push(
      orgEditFormNameOf("organisationRoles"),
      defaultPrivateInsurer(country)
    );
  };

  const removeOrgRole = (indexToRemove: number) => {
    mutators.remove(orgEditFormNameOf("organisationRoles"), indexToRemove);
    const organisationRoleTypeCode =
      fields.value && fields.value[indexToRemove]?.organisationRoleTypeCode;
    if (
      organisationRoleTypeCode &&
      (organisationRoleTypeCode === OrganisationRoleType.NzPrivateInsurer ||
        organisationRoleTypeCode === OrganisationRoleType.AuPrivateInsurer)
    ) {
      change("privateInsurer", undefined);
    }
    if (
      organisationRoleTypeCode &&
      (organisationRoleTypeCode === OrganisationRoleType.NzEmployer ||
        organisationRoleTypeCode === OrganisationRoleType.AuEmployer)
    ) {
      change("employer", undefined);
      change("usingPrivateInsurer", undefined);
    }
  };

  const employerMenuItem: IContextualMenuItem[] = !employerExists
    ? [
        {
          key: "employer",
          text: OrganisationLabels.Employer,
          onClick: addEmployer
        }
      ]
    : [];

  const privateInsurerMenuItem: IContextualMenuItem[] = !privateInsurerExists
    ? [
        {
          key: "privateInsurer",
          text: OrganisationLabels.PrivateInsurer,
          onClick: addPrivateInsurer
        }
      ]
    : [];

  return (
    <div
      id={`${OrganisationCardIds.OrganisationType}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack
        styles={(_props, theme) => ({
          root: {
            borderBottom: "1px solid",
            borderColor: theme.palette.neutralLighterAlt
          }
        })}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { marginBottom: 8 } }}
        >
          <Heading variant="section-heading-light">
            {Labels.organisationType}
          </Heading>
          {!hideAddButton && (
            <DefaultButton
              id="add-organisation-types-btn"
              iconProps={{ iconName: "Add" }}
              text={Labels.addMore}
              menuProps={{
                styles: { container: { width: 152 } },
                shouldFocusOnMount: true,
                items: [...employerMenuItem, ...privateInsurerMenuItem]
              }}
            />
          )}
        </Stack>
        <Stack tokens={{ childrenGap: 16 }}>
          {(!fields.value || fields.value?.length === 0) && (
            <NoDataTile
              textProps={{
                text: OrganisationLabels.NoOrganisationTypes
              }}
              linkProps={{
                text: OrganisationLabels.SetAsAnEmployer,
                onClick: addEmployer
              }}
            />
          )}
          <FieldArray<OrganisationRoleDto>
            name={orgEditFormNameOf("organisationRoles")}
          >
            {({ fields }) => (
              <AnimatedListWithKeys
                items={fields.value}
                itemIdField="organisationRoleTypeCode"
                onItemIdRemoved={id => {
                  const index = fields.value.findIndex(
                    v => v.organisationRoleTypeCode === id
                  );
                  removeOrgRole(index);
                }}
              >
                {(id, onRemoveItem) => {
                  const index = fields.value.findIndex(
                    v => v.organisationRoleTypeCode === id
                  );

                  const value = fields.value.find(
                    v => v.organisationRoleTypeCode === id
                  );
                  if (value && index >= 0) {
                    return (
                      <Fieldset frame tokens={{ childrenGap: 8 }}>
                        <Stack
                          horizontal
                          horizontalAlign="space-between"
                          styles={{ root: { width: "100%" } }}
                        >
                          <Heading labelPaddings>
                            {OrganisationLabels.Type}
                          </Heading>
                          <Link
                            id={`remove-organisation-types-btn-${index}`}
                            styles={{ root: { fontSize: FontSizes.size12 } }}
                            onClick={onRemoveItem}
                            as="button"
                            disabled={
                              fields.value[index].organisationRoleTypeCode ===
                                OrganisationRoleType.NzPublicInsurer ||
                              fields.value[index].organisationRoleTypeCode ===
                                OrganisationRoleType.AuPublicInsurer
                            }
                          >
                            {OrganisationLabels.Remove}
                          </Link>
                        </Stack>
                        <OrgRoleTypeText
                          code={fields.value[index].organisationRoleTypeCode}
                        />
                        <WhenCountry is={Country.NewZealand}>
                          <FieldCondition
                            when={`${orgEditFormNameOf(
                              "organisationRoles"
                            )}[${index}].organisationRoleTypeCode`}
                            is={OrganisationRoleType.NzPrivateInsurer}
                          >
                            <PrivateInsurerFields
                              name={orgEditFormNameOf("privateInsurer")}
                            />
                          </FieldCondition>
                          <FieldCondition
                            when={`${orgEditFormNameOf(
                              "organisationRoles"
                            )}[${index}].organisationRoleTypeCode`}
                            is={OrganisationRoleType.NzEmployer}
                          >
                            <EmployerFields
                              name={orgEditFormNameOf("employer")}
                            />
                          </FieldCondition>
                        </WhenCountry>
                      </Fieldset>
                    );
                  }
                  return null;
                }}
              </AnimatedListWithKeys>
            )}
          </FieldArray>
        </Stack>
      </Stack>
    </div>
  );
};

export const EditOrganisationType = withFetch(
  x => [x.practice.ref.organisationRoleType.load()],
  EditOrganisationTypeBase
);
