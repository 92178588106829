import { observer } from "mobx-react-lite";

import { Heading, IDialogContentProps } from "@bps/fluent-ui";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { BookableUsersSelectField } from "@ui-components/form/selects/BookableUsersSelectField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ApiKeyModalValidator } from "./ApiKeyModalValidator.tsx";

interface ApiKeyModalProps {
  onDismiss: () => void;
}

export interface ApiKeyModalFormValues {
  providerIds: string[];
}

const validator = new ApiKeyModalValidator();

export const ApiKeyModal: React.FC<ApiKeyModalProps> = observer(
  ({ onDismiss }) => {
    const { customIntegrations } = useStores();

    const onApiKeyModalSubmitted = async (values: ApiKeyModalFormValues) => {
      const selectedIds = values.providerIds;

      const updatedProvidersPromises = selectedIds.map(id =>
        customIntegrations.addPhysitrackApiKey(id)
      );

      await Promise.all(updatedProvidersPromises);
    };

    const dialogContentProps: IDialogContentProps = {
      title: <Heading variant="modal-heading">Generate API Key</Heading>
    };

    return (
      <SubmissionFormDialog<ApiKeyModalFormValues>
        dialogName="Generate API Key dialog"
        onSubmit={onApiKeyModalSubmitted}
        onSubmitSucceeded={onDismiss}
        validate={validator.validate}
        dialogProps={{
          dialogContentProps,
          onDismiss,
          minWidth: 600,
          styles: {
            root: {
              ".ms-Dialog-action": { width: "100%" }
            }
          }
        }}
        buttonsProps={{
          disableSubmitOnPristine: false,
          submitButtonProps: {
            text: "Generate key",
            iconProps: undefined
          }
        }}
      >
        {() => (
          <BookableUsersSelectField
            name="providerIds"
            label="Provider"
            placeholder="Select provider"
            required
            multiSelect
            selectAllButtonText="All providers"
            usersFilter={{
              showOnCalendar: true,
              statusCodes: [UserStatus.Active],
              businessRoleClasses: [BusinessRoleClasses.Provider]
            }}
            onFilter={async users => {
              const physitrackDto =
                await customIntegrations.getPhysitrackApiKeys();

              return users.filter(u => {
                return !physitrackDto.find(data => u.id === data.providerId);
              });
            }}
          />
        )}
      </SubmissionFormDialog>
    );
  }
);
