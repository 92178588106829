import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { PresetButtonType } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { AppointmentTypesFilter } from "@shared-types/booking/appointment-type-filter.type.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

type OnlineAppointmentTypeFilterProps = Pick<
  FilterBarProps<AppointmentTypesFilter>,
  "children"
> & { types?: string[] };

export const OnlineAppointmentTypeFilter: FunctionComponent<OnlineAppointmentTypeFilterProps> =
  observer(({ children }) => {
    const nameOf = nameOfFactory<AppointmentTypesFilter>();

    const durationOptions = [
      { key: "0", text: "0 - 15 mins" },
      { key: "15", text: "15 - 30 mins" },
      { key: "30", text: "30 - 45 mins" },
      { key: "45", text: "45 - 60 mins" },
      { key: "60", text: "60+ mins" }
    ];

    const availabilityOptions = [
      {
        key: "All",
        text: "All patients"
      },
      {
        key: "EPOnly",
        text: "Existing patient only"
      },
      {
        key: "NPOnly",
        text: "New patient only"
      },
      {
        key: "Unavailable",
        text: "Unavailable online"
      }
    ];

    const availabilityPresetValues = ["All", "EPOnly", "NPOnly"];

    const presets: PresetButtonType<AppointmentTypesFilter>[] = [
      {
        name: nameOf("availabilitiesPreset"),
        text: "Available",
        iconName: "Globe",
        tooltip: "Show appointment types available online",
        tooltipPresses:
          "Showing appointment types available online only. Release to show all",
        valuesToBeSetOnToggleOn: {
          availabilities: availabilityPresetValues
        },
        valuesToBeSetOnToggleOff: {
          availabilities: undefined
        }
      }
    ];

    return (
      <FilterBar<AppointmentTypesFilter>
        initialValues={{
          search: "",
          availabilities: availabilityPresetValues,
          availabilitiesPreset: true
        }}
        items={[
          {
            name: nameOf("search"),
            type: "searchBox",
            stickItem: true,
            props: {
              placeholder: "Search by name",
              styles: { root: { width: 300 } },
              removeSpecialCharacters: false
            }
          },
          {
            type: "optionsSelect",
            name: nameOf("availabilities"),
            props: {
              placeholder: "Availability",
              options: availabilityOptions,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "optionsSelect",
            name: nameOf("durations"),
            props: {
              id: "waitinglist-filter-duration",
              placeholder: "Duration",
              options: durationOptions,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 200
            }
          }
        ]}
        presets={presets}
      >
        {children}
      </FilterBar>
    );
  });
