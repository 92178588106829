import { observer } from "mobx-react-lite";
import React from "react";

import { PresetButtonType } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { ClinicalTaskFilterValues } from "@shared-types/clinical/clinical-task-filter-values.type.ts";
import { ClinicalTaskStatus } from "@shared-types/clinical/clinical-task-status.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import { ClinicalTaskManagementFilterType } from "./ClinicalTaskManagementFilter.tsx";
import { ClinicalTasksTableLabels } from "./types/clinical-task-table-labels.enum.ts";

export interface ClinicalTaskFilterProps {
  onSearch: (filter: ClinicalTaskFilterValues) => void;
  filter: ClinicalTaskFilterValues;
  clinicalRecord: ClinicalRecord;
}

export const getConfidentialFilterPreset = (
  hasPermission: boolean,
  hidden: boolean
): PresetButtonType<
  ClinicalTaskFilterValues | ClinicalTaskManagementFilterType
>[] => {
  return hasPermission && !hidden
    ? [
        {
          name: "confidential",
          iconName: "ReportHacked",
          tooltip: "Show confidential",
          tooltipPresses: "Hide confidential",
          tooltipOnDisabled: "No confidential items"
        }
      ]
    : [];
};

const ClinicalTaskFilterBase: React.FC<ClinicalTaskFilterProps> = observer(
  ({ onSearch, filter, clinicalRecord }) => {
    const { clinical, core } = useStores();

    const statusOptions = [
      {
        key: ClinicalTaskStatus.Overdue,
        text: capitalizeSentence(ClinicalTaskStatus.Overdue)
      },
      {
        key: ClinicalTaskStatus.Today,
        text: capitalizeSentence(ClinicalTaskStatus.Today)
      },
      {
        key: ClinicalTaskStatus.Upcoming,
        text: capitalizeSentence(ClinicalTaskStatus.Upcoming)
      },
      {
        key: ClinicalTaskStatus.Completed,
        text: capitalizeSentence(ClinicalTaskStatus.Completed)
      }
    ];

    const dueVisitOptions = [
      { key: "0,5", text: "0 - 5" },
      { key: "5,10", text: "5 - 10" },
      { key: "10,20", text: "10 - 20" },
      { key: "20,30", text: "20 - 30" },
      { key: "30,50", text: "30 - 50" },
      { key: "50,99", text: "50+" }
    ];

    const priorityOptions = clinical.ref.clinicalTaskPriorities.keyTextValues;
    const items: Item<ClinicalTaskFilterValues>[] = [
      {
        type: "searchBox",
        name: "description",
        stickItem: true,
        props: {
          placeholder: ClinicalTasksTableLabels.SearchDescription,
          styles: {
            root: {
              flexGrow: 1
            }
          }
        }
      },
      {
        type: "optionsSelect",
        name: "statuses",
        props: {
          id: "clinical-task-status-dropdown",
          placeholder: ClinicalTasksTableLabels.Status,
          options: statusOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 256
        }
      },
      {
        type: "datesRangePicker",
        name: "datesRangePicker",
        props: {
          id: "clinical-tasks-filter-dates-range",
          startDateProps: {
            id: "clinical-tasks-filter-start-date"
          },
          endDateProps: { id: "clinical-tasks-filter-end-date" },
          startDateName: "startDateTime",
          endDateName: "endDateTime",
          placeholder: "Date(s)"
        }
      },
      {
        type: "optionsSelect",
        name: "dueVisits",
        props: {
          id: "clinical-task-duevisit-dropdown",
          placeholder: ClinicalTasksTableLabels.DueConsults,
          options: dueVisitOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 256
        }
      },
      {
        type: "optionsSelect",
        name: "priorities",
        props: {
          id: "clinical-task-priority-dropdown",
          placeholder: ClinicalTasksTableLabels.Priority,
          options: priorityOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 256
        }
      }
    ];
    return (
      <FilterBar<ClinicalTaskFilterValues>
        initialValues={filter}
        onChange={({ values }) => {
          onSearch(values);
        }}
        items={items}
        presets={getConfidentialFilterPreset(
          core.hasPermissions(Permission.ConfidentialDataAllowed),
          !clinicalRecord.isExistConfidentialClinicalTasksWithOtherUsers
        )}
      />
    );
  }
);

export const ClinicalTaskFilter = withFetch(
  x => [x.clinical.ref.clinicalTaskPriorities.load()],
  ClinicalTaskFilterBase
);
