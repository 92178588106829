import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import { Heading, HideStack, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";
import { OccupationPicker } from "../OccupationPicker.tsx";
import { EmployerFields } from "./EmployerFields.tsx";
import { EmploymentStatusField } from "./EmploymentStatusField.tsx";
import { InPaidEmploymentField } from "./InPaidEmploymentField.tsx";

export const EmploymentFields = observer(() => {
  const { acc } = useStores();

  const {
    input: { value: inPaidEmployment }
  } = useField(claimFormNameOf("inPaidEmployment"));

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Heading variant="section-heading-light">
        {ClaimFormLabels.employment}
      </Heading>
      <OccupationPicker
        label={ClaimFormLabels.occupation}
        name={claimFormNameOf("occupation")}
      />
      <InPaidEmploymentField />
      <HideStack when={!inPaidEmployment} tokens={{ childrenGap: 8 }}>
        <EmploymentStatusField />
        <ChoiceGroupField
          required
          options={acc.ref.workTypes.keyTextValues}
          label={ClaimFormLabels.workType}
          name={claimFormNameOf("workType")}
        />
        <EmployerFields />
      </HideStack>
    </Stack>
  );
});
