import { Spinner } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  GRCSQuestionnaireResponseDto,
  MeasurementType,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import { findClinicalToolAnswerText } from "../utils/ClinicalToolAnswerFinder.ts";
import { GRCSBaselineForm } from "./GRCSBaselineForm.tsx";
import { CLINICAL_TOOLS_FORM_HEADING } from "./GRCSForm.types.ts";
import { GRCSFormViewModel } from "./GRCSFormViewModel.ts";

export interface GRCSQuestionnaireViewProps {
  encounterId: string;
}

export const GRCSFormView: React.FunctionComponent<
  GRCSQuestionnaireViewProps
> = ({ encounterId }) => {
  const getGRCSViewData =
    () =>
    async ({ clinical }: RootStore): Promise<GRCSFormViewModel> => {
      const questionnaire = await clinical.getQuestionnaires(
        QuestionnaireType.GRCSV1
      );

      const savedData = (
        await clinical.getEncounterClinicalData({
          encounterId,
          types: [ClinicalDataType.GRCS]
        })
      )[
        ClinicalDataType.GRCS.toLocaleLowerCase()
      ] as GRCSQuestionnaireResponseDto;

      const answers = savedData
        ? savedData.items.map(i => ({
            questionId: `${i.questionnaireItemId}`,
            answerValue: i.answer,
            answerText: findClinicalToolAnswerText(
              i.questionnaireItemId,
              i.answer!,
              questionnaire
            )
          }))
        : [];

      const result = await clinical.getMeasurements({
        patientId: clinical.activeRecordPatientId,
        encounterId,
        types: [MeasurementType.GRCS]
      });

      const measurements = result.results;

      if (measurements.length !== 1)
        throw new Error(
          `Unable to retrieve ${MeasurementType.GRCS} measurement data`
        );

      const summary =
        measurements[0].summary ?? measurements[0].value.toString();

      const isConfidential = !!savedData.secGroupId;

      const lastUpdatedDate = getClinicalDataLastUpdatedDate(savedData);

      const lastUpdatedUserId = getClinicalDataLastUpdatedUserId(savedData);

      return {
        measurements,
        answers,
        summary,
        questionnaire,
        side: savedData.side,
        diagnosis: savedData.diagnosis,
        encounterDate: savedData.createLog?.createdDateTime,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      };
    };

  const title = (encounterDate: string | undefined) =>
    `${CLINICAL_TOOLS_FORM_HEADING} - ${DateTime.fromISOOrNow(
      encounterDate
    ).toDayDefaultFormat()}`;

  return (
    <DataFetcher<GRCSFormViewModel>
      fetch={getGRCSViewData()}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        summary,
        questionnaire,
        side,
        diagnosis,
        encounterDate,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <ClinicalToolView
          title={title(encounterDate)}
          summary={summary}
          isConfidential={isConfidential}
          lastUpdatedDate={lastUpdatedDate}
          lastUpdatedUserId={lastUpdatedUserId}
        >
          <GRCSBaselineForm
            questionnaire={questionnaire}
            diagnosis={diagnosis}
            viewData={{
              answer: answers[0].answerValue,
              side
            }}
          />
        </ClinicalToolView>
      )}
    </DataFetcher>
  );
};
