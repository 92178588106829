import { observer } from "mobx-react-lite";

import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { PatientSetting } from "@stores/user-experience/models/PatientSetting.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ContactFormDialog } from "../../individual/edit/ContactFormDialog.tsx";
import { OrganisationFormDialog } from "../../organisation/edit/OrganisationFormDialog.tsx";
import { PatientFormDialog } from "../../patient/edit/PatientFormDialog.tsx";

export const ContactDialogsSwitch: React.FC = observer(() => {
  const {
    practice: {
      ui: { currentContactId, newContactType }
    }
  } = useStores();

  const fetch = async (root: RootStore) => {
    const [contact, patientNotices, patientSetting] = await Promise.all([
      currentContactId
        ? root.practice.getContact(currentContactId, {
            includeRelationships: true,
            includeContactPreferences: true
          })
        : undefined,
      currentContactId
        ? root.practice.getPatientNotices(currentContactId)
        : undefined,
      currentContactId
        ? root.userExperience.getPatientSetting(currentContactId)
        : undefined
    ]);

    return {
      contact,
      contactPreferences: contact?.contactPreferences,
      patientNotices,
      patientSetting
    };
  };

  return (
    <DataFetcher<{
      contact: Contact | undefined;
      contactPreferences: ContactPreferences | undefined;
      patientNotices: PatientNotice[] | undefined;
      patientSetting: PatientSetting | undefined;
    }>
      fetch={fetch}
      refetchId={currentContactId}
    >
      {({ contact, contactPreferences, patientNotices, patientSetting }) => {
        switch (contact?.type ?? newContactType) {
          case ContactType.Patient: {
            return (
              <PatientFormDialog
                contact={contact}
                contactPreferences={contactPreferences}
                patientNotices={patientNotices}
                patientSetting={patientSetting}
              />
            );
          }
          case ContactType.Organisation: {
            return <OrganisationFormDialog organisation={contact} />;
          }
          case ContactType.Individual: {
            return (
              <ContactFormDialog
                contact={contact}
                contactPreferences={contactPreferences}
              />
            );
          }
          default: {
            return null as any;
          }
        }
      }}
    </DataFetcher>
  );
});
