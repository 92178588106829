import { useForm } from "react-final-form";

import { Card, LabeledText, LabeledTextGroup, Stack } from "@bps/fluent-ui";
import { getReferralOutViewValues } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ReferralOutViewValues } from "../types/claim.types.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";

export const ReferralsOutViewSectionBase: React.FC = () => {
  const values = useForm<ClaimFormValues>().getState().values;

  return (
    <DataFetcher fetch={root => getReferralOutViewValues({ values, root })}>
      {(referralOut: ReferralOutViewValues) => (
        <Card
          heading={ClaimSectionHeadings.referralsOut}
          headingLevel="section-heading"
          iconName="Send"
          id={ClaimsCardIds.referralsOut}
        >
          <Stack tokens={{ childrenGap: 8 }}>
            {!!referralOut.referralDetails?.length &&
              referralOut.referralDetails.map(x => (
                <LabeledTextGroup
                  styles={({ theme }) => ({
                    root: {
                      border: `1px solid ${theme.palette.neutralLight}`,
                      padding: 16,
                      borderRadius: 4
                    }
                  })}
                  key={`referral-out-${x.providerTypeCode}`}
                >
                  <LabeledText noSemicolon label={ClaimFormLabels.providerType}>
                    {x.providerTypeText}
                  </LabeledText>
                  <LabeledText
                    noSemicolon
                    label={ClaimFormLabels.referralReason}
                    styles={{ text: { whiteSpace: "normal" } }}
                  >
                    {x.referralReason}
                  </LabeledText>
                </LabeledTextGroup>
              ))}
          </Stack>
        </Card>
      )}
    </DataFetcher>
  );
};

export const ReferralsOutViewSection = withFetch(
  x => [x.acc.ref.providerTypes.load()],
  ReferralsOutViewSectionBase
);
