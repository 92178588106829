import { useField, useForm } from "react-final-form";

import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  InvestigationFormValues,
  Labels
} from "../investigations/AddInvestigationDialog.types.ts";

export interface InvestigationReportFreeTextFieldProps {
  reportTextFocused: boolean;
  setReportTextFocused: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
}

export const InvestigationReportFreeTextField: React.FC<
  InvestigationReportFreeTextFieldProps
> = ({ reportTextFocused, setReportTextFocused, isEdit }) => {
  const form = useForm<InvestigationFormValues>();
  const nameOf = nameOfFactory<InvestigationFormValues>();
  const {
    input: { value: files }
  } = useField(nameOf("files"), {
    subscription: { value: true }
  });

  const onReportTextClicked = () => {
    setReportTextFocused(true);
  };

  const onDiscardClicked = () => {
    setReportTextFocused(false);
    form.change(nameOf("reportText"), undefined);
  };

  return (
    <TextInputField
      name={nameOf("reportText")}
      label={Labels.ReportText}
      maxLength={2000}
      autoFocus={reportTextFocused}
      actionButton={{
        linkProps: {
          onClick: onDiscardClicked,
          styles: {
            root: {
              visibility: reportTextFocused && !isEdit ? "visible" : "hidden",
              fontSize: 14
            }
          },
          children: "Discard"
        }
      }}
      required={reportTextFocused || files === undefined || files?.length === 0}
      multiline
      onClick={onReportTextClicked}
    />
  );
};
