import { createContext, useContext } from "react";

import { TransactionFilterHelper } from "./TransactionFilterHelper.ts";

export const TransactionFilterContext = createContext<
  TransactionFilterHelper<any>
>({} as TransactionFilterHelper<any>);

export const useTransactionFilterContext = () =>
  useContext(TransactionFilterContext);
