import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import { DefaultButton, NoDataTile } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import {
  ClaimManagementCardIds,
  ClaimSectionHeadings
} from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { defaultConfirm } from "@ui-components/form/submission-form-dialog/defaultConfirm.util.ts";

import { ClaimAdjustmentDialogForm } from "../../claim-adjustment/components/ClaimAdjustmentDialogForm.tsx";
import { ClaimAdjustmentFormLabels } from "../../claim-adjustment/components/ClaimAdjustmentEnums.ts";
import { ClaimAdjustmentList } from "../../claim-adjustment/components/ClaimAdjustmentList.tsx";
import { ClaimAdjustmentResponseDialogForm } from "../../claim-adjustment/components/ClaimAdjustmentResponseDialogForm.tsx";
import { ModalKeys } from "../../claim-adjustment/context/ClaimAdjustmentHelper.types.ts";

export const ClaimAdjustmentFormSection: React.FC = observer(() => {
  const {
    claim,
    claim: { claimAdjustments }
  } = useContext(ClaimContext);

  const root = useStores();

  const { selectedMenuItemId } = useCardFormLayoutContext();
  const { setOpenModal } = useClaimAdjustmentContext();

  const onConfirm = () => {
    form.reset();
    setOpenModal(ModalKeys.AdjustmentModal);
  };

  const onClean = () => {
    setOpenModal(ModalKeys.AdjustmentModal);
  };

  const form = useForm();
  const { dirty } = useFormState();

  return (
    <>
      <ClaimCard
        id={ClaimManagementCardIds.claimAdjustment}
        heading={`${ClaimSectionHeadings.claimAdjustment} ${
          claimAdjustments.length > 0 ? `(${claimAdjustments.length})` : ""
        }`}
        openOnRender={
          selectedMenuItemId === ClaimManagementCardIds.claimAdjustment ||
          root.routing.location.state?.openACC32Card
        }
        iconName="DocumentSet"
        button={
          root.core.hasPermissions(Permission.ClaimWrite) &&
          claim.claimNumber ? (
            <DefaultButton
              iconProps={{ iconName: "Add" }}
              text="New ACC32"
              onClick={e => {
                e.stopPropagation();
                defaultConfirm(dirty, onConfirm, onClean);
              }}
              styles={{
                root: { paddingLeft: "9px 5px" }
              }}
            />
          ) : undefined
        }
      >
        {claimAdjustments && claimAdjustments.length > 0 ? (
          <>
            <ClaimAdjustmentList claimAdjustments={claimAdjustments} />
            <ClaimAdjustmentResponseDialogForm />
          </>
        ) : (
          <NoDataTile
            textProps={{
              text: ClaimAdjustmentFormLabels.noAcc32
            }}
            showBoxShadow={false}
            linkProps={
              root.core.hasPermissions(Permission.ClaimWrite) &&
              claim.claimNumber
                ? {
                    text: ClaimAdjustmentFormLabels.addACC32,
                    onClick: () => {
                      defaultConfirm(dirty, onConfirm, onClean);
                    }
                  }
                : { hidden: true }
            }
          />
        )}
      </ClaimCard>

      <ClaimAdjustmentDialogForm claim={claim} />
    </>
  );
});
