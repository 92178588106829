import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";
import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";

import { invoiceFormNameOf } from "./InvoiceForm.types.tsx";
import { InvoiceItemListChargeToCell } from "./InvoiceItemListChargeToCell.tsx";
import { getIsItemASubsidy } from "./utils.ts";

interface InvoiceFormChargeToCellProps {
  item: InvoiceItemFormValue;
}

export const InvoiceFormChargeToCell: FunctionComponent<InvoiceFormChargeToCellProps> =
  observer(props => {
    const { item } = props;

    const {
      input: { value: claimId }
    } = useField<string | undefined>(invoiceFormNameOf("claimId"), {
      subscription: { value: true }
    });

    const {
      input: { value: accountContactId }
    } = useField<string | undefined>(invoiceFormNameOf("accountContactId"), {
      subscription: { value: true }
    });

    const { getClaim } = useContext(InvoiceFormContext);

    if (!item.serviceId) {
      return null;
    }

    const isSubsidy = getIsItemASubsidy(item);

    let chargeToId: string | undefined;
    if (isSubsidy) {
      if (claimId) {
        chargeToId = getClaim(claimId)?.insurerContactId;
      }
    } else {
      chargeToId = accountContactId;
    }

    return (
      <Stack styles={{ root: { paddingTop: 4 } }}>
        <InvoiceItemListChargeToCell
          contactId={chargeToId}
          isSubsidy={isSubsidy}
        />
      </Stack>
    );
  });
