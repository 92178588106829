import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  DirectionalHint,
  FontIcon,
  FontSizes,
  IStyle,
  mergeStyleSets,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

interface ConfidentialToggleFieldProps {
  isInline?: boolean;
  isDocument?: boolean;
  name: string;
  styles?: IStyle;
}

export const ConfidentialToggleField: FunctionComponent<
  ConfidentialToggleFieldProps
> = ({ isInline, isDocument, name, styles }) => {
  const theme = useTheme();
  const renderInfoIcon = () => (
    <TooltipHost
      content="The document won't be available for other users"
      directionalHint={DirectionalHint.bottomCenter}
      styles={{
        root: {
          paddingTop: 3
        }
      }}
    >
      <FontIcon
        iconName="Info"
        styles={{
          root: {
            color: theme.palette.neutralPrimary,
            fontSize: FontSizes.size16,
            paddingLeft: 4,
            paddingBottom: 2
          }
        }}
      />
    </TooltipHost>
  );
  return (
    <When permission={Permission.ConfidentialDataAllowed}>
      <YesNoToggleField
        styles={mergeStyleSets(
          {
            root: {
              marginBottom: 0
            },
            label: {
              marginRight: 8,
              display: "flex"
            }
          },
          styles
        )}
        {...dataAttribute(DataAttributes.Element, "confidential")}
        label={
          <>
            {isDocument ? "Internally confidential" : "Confidential"}
            {renderInfoIcon()}
          </>
        }
        inlineLabel={isInline}
        name={name}
      />
    </When>
  );
};
