import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { formDateInputStyles } from "../SchedulesScreen.styles.ts";
import {
  ScheduleFormLabels,
  ScheduleFormValues
} from "./ScheduleForm.types.tsx";

export const nameOf = nameOfFactory<ScheduleFormValues>();

export const ScheduleFormFields: React.FC = observer(() => {
  const { input: endDateInput } = useField(nameOf("scheduleEndDate"), {
    subscription: { value: true }
  });

  const { input: isOngoingInput } = useField(nameOf("scheduleIsOngoing"), {
    subscription: { value: true }
  });

  return (
    <Stack tokens={{ childrenGap: 8, maxWidth: 600 }}>
      <TextInputField
        required
        label={ScheduleFormLabels.name}
        name={nameOf("scheduleName")}
      />
      <TextInputField
        label={ScheduleFormLabels.description}
        name={nameOf("scheduleDescription")}
        multiline={true}
      />
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <DatePickerField
          required
          name={nameOf("scheduleStartDate")}
          label={ScheduleFormLabels.startDate}
          styles={formDateInputStyles}
        />
        <DatePickerField
          placeholder="-"
          name={nameOf("scheduleEndDate")}
          label={ScheduleFormLabels.endDate}
          disabled={isOngoingInput.value}
          styles={formDateInputStyles}
        />
        <ToggleField
          label="Ongoing"
          inlineLabel={true}
          name={nameOf("scheduleIsOngoing")}
          styles={{
            root: { margin: 0, paddingTop: 30, alignSelf: "flex-start" },
            label: { cursor: "pointer", fontWeight: 400 }
          }}
        />
        <FieldSpy
          name={nameOf("scheduleIsOngoing")}
          onChange={(isOngoing: boolean) => {
            if (isOngoing) {
              endDateInput.onChange(undefined);
            }
          }}
        />
      </Stack>
    </Stack>
  );
});
