import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { useField, useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IconButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";
import { InvalidEmployerAddressMessageBar } from "../InvalidEmployerAddressMessageBar.tsx";
import { AddEmployerButton } from "./AddEmployerButton.tsx";
import { BusinessAddressFields } from "./BusinessAddressFields.tsx";
import { useIsEmployerRequired } from "./hooks/useIsEmployerRequired.tsx";
import { NoEmployerTile } from "./NoEmployerTile.tsx";
import { SavedEmployerField } from "./SavedEmployerField.tsx";

export const EmployerFields = observer(() => {
  const theme = useTheme();
  const form = useForm<ClaimFormValues>();
  const { claim, getEmployerAddress, getIsClaimAddressValid } =
    useContext(ClaimContext);

  const {
    input: { value: isQuickAddEmployer }
  } = useField(claimFormNameOf("isQuickAddEmployer"), {
    subscription: { value: true }
  });

  const {
    input: { value: inPaidEmployment }
  } = useField(claimFormNameOf("inPaidEmployment"), {
    subscription: { value: true }
  });

  const {
    input: { value: employerId }
  } = useField(claimFormNameOf("employerId"), {
    subscription: { value: true }
  });

  const {
    input: { value: hasInvalidEmployerAddress }
  } = useField(claimFormNameOf("hasInvalidEmployerAddress"), {
    subscription: { value: true }
  });

  const isEmployerDetailsRequired = useIsEmployerRequired();

  useEffect(() => {
    claim.patient?.loadRelatedContacts();
  }, [claim.patient, claim.patient?.relationships]);

  useEffect(() => {
    const employerAddress = claim.patient?.employerContacts[0]?.defaultAddress;

    const address = getEmployerAddress(employerAddress);

    if (
      claim.patient?.employerContacts.length === 1 &&
      inPaidEmployment &&
      !isQuickAddEmployer
    ) {
      form.change(
        claimFormNameOf("employerId"),
        claim.patient.employerContacts[0].id
      );
      form.change(claimFormNameOf("employerAddress"), address);

      form.change(
        claimFormNameOf("hasInvalidEmployerAddress"),
        !getIsClaimAddressValid(address)
      );
    }
  }, [
    form,
    claim.patient?.employerContacts,
    inPaidEmployment,
    isQuickAddEmployer,
    getEmployerAddress,
    getIsClaimAddressValid
  ]);

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Heading
        variant="section-heading-light"
        styles={{ root: { marginTop: 16 } }}
      >
        {ClaimFormLabels.businessAddress}
      </Heading>

      {hasInvalidEmployerAddress && <InvalidEmployerAddressMessageBar />}

      {!isQuickAddEmployer && !!claim.patient?.employerContacts.length ? (
        <>
          <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="end">
            <SavedEmployerField
              employerContacts={claim.patient?.employerContacts || []}
            />
            <AddEmployerButton />
          </Stack>
          {employerId && <BusinessAddressFields />}
        </>
      ) : undefined}

      {isQuickAddEmployer && (
        <Stack
          tokens={{ childrenGap: 8 }}
          styles={{
            root: {
              padding: 8,
              borderRadius: 4,
              border: `1px solid ${theme.palette.neutralLight}`
            }
          }}
        >
          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
            <TextInputField
              name={claimFormNameOf("employerName")}
              label="Quick employer"
              required={isEmployerDetailsRequired}
            />
            <IconButton
              {...dataAttribute(DataAttributes.Element, "hide-quick-add")}
              iconProps={{ iconName: "Delete" }}
              onClick={() => {
                form.batch(() => {
                  form.change("isQuickAddEmployer", false);
                  if (claim.patient?.employerContacts.length === 1) {
                  }
                });
              }}
            />
          </Stack>
          <BusinessAddressFields />
        </Stack>
      )}

      {!claim.patient?.employerContacts.length && !isQuickAddEmployer && (
        <NoEmployerTile />
      )}
    </Stack>
  );
});
