import { decode } from "base64-arraybuffer";
import { action, computed, observable, runInAction } from "mobx";

import {
  CorrespondenceType,
  DocumentContentType,
  PurposeType,
  SubmitActionCode,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TemplateDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { getDefaultLetterheadId } from "@modules/clinical/screens/document-writer/components/utils.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { Document } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

import { SubmitAction } from "../../document-writer/components/DocumentWriterDetails.types.ts";
import { TemplateWriterValues } from "../components/TemplateWriterValues.ts";

export class TemplateWriterHelper {
  constructor(
    private root: RootStore,
    private template: Template
  ) {
    runInAction(() => {
      this.currentTemplate = this.template;
    });
  }

  private get document() {
    return this.root.document;
  }

  private get correspondence() {
    return this.root.correspondence;
  }

  private get core() {
    return this.root.core;
  }

  private get practice() {
    return this.root.practice;
  }

  @observable
  currentTemplate: Template;

  @observable
  public isDirty: boolean = false;

  @action
  setIsDirty = (value: boolean) => {
    this.isDirty = value;
  };

  originalTemplateId: string | undefined;

  @computed
  get isCreateTemplate(): boolean {
    if (this.currentTemplate) {
      return !this.document.getTemplate(this.currentTemplate.id);
    }
    return true;
  }

  @computed
  get initialValues(): TemplateWriterValues {
    const initialValues: TemplateWriterValues = {
      name:
        this.currentTemplate.name ||
        this.getDocumentTypeText(this.currentTemplate.documentType),
      documentContent:
        this.currentTemplate.renderedContent &&
        this.currentTemplate.renderedContent.length > 0
          ? this.currentTemplate.renderedContent
          : this.currentTemplate.content!,
      documentType: this.currentTemplate.documentType,
      documentStatus: this.currentTemplate.documentStatus!,
      visibility: this.currentTemplate.visibility,
      purpose:
        this.currentTemplate.documentType === CorrespondenceType.Autofill
          ? PurposeType.AUTO
          : PurposeType.DOC,
      shortcut: this.currentTemplate.shortcut,
      availability: this.currentTemplate.documentStatus
    };

    // Is it autofill
    if (this.currentTemplate.documentType === CorrespondenceType.Autofill)
      return initialValues;

    // Reach this far, it is a document
    if (this.template.documentStatus) {
      const templateState =
        this.template.documentStatus === SubmitActionCode.DraftCode
          ? SubmitActionCode.DraftCode
          : "Published";

      const getPublicOptions = (): string | undefined => {
        if (
          this.template.documentStatus ===
          SubmitActionCode.PublishToEveryoneCode
        ) {
          return SubmitActionCode.PublishToEveryoneCode;
        } else if (
          this.template.documentStatus === SubmitActionCode.PublishToSelfCode
        ) {
          return SubmitActionCode.PublishToSelfCode;
        } else {
          return undefined;
        }
      };
      initialValues.templateState = templateState;
      initialValues.publicOption = getPublicOptions();
    }
    return initialValues;
  }

  @observable
  isEditAsCopy: boolean;

  @action
  setIsEditAsCopy = (value: boolean) => {
    this.isEditAsCopy = value;
  };

  @computed
  get submitButtonProps() {
    return this.isCreateTemplate || this.isEditAsCopy
      ? {
          text: "Save",
          iconProps: { iconName: "" }
        }
      : {
          text: "Update",
          iconProps: { hidden: true }
        };
  }

  get id(): string {
    return this.originalTemplateId ?? this.currentTemplate.id;
  }

  get type(): string {
    return this.currentTemplate.documentType;
  }

  get documentTitle() {
    return this.currentTemplate.name;
  }

  get dto(): TemplateDto {
    return this.currentTemplate.dto;
  }

  get createdBy(): string | undefined {
    return this.currentTemplate.createdBy;
  }

  get isNew(): boolean | undefined {
    return this.currentTemplate.isNew;
  }

  get isFromAutofillMgmt(): boolean {
    return this.document.isFromAutofillMgmt;
  }

  @computed
  get isEditMode(): boolean {
    return !!this.initialValues?.documentContent;
  }

  getDocumentTypeText = (type: string): string => {
    const documentTypeFound =
      this.correspondence.ref.correspondenceTypes.keyTextValues.find(
        keyVal => keyVal.key === type
      );

    return documentTypeFound ? documentTypeFound.text : "Template";
  };

  saveValues = async (dto: TemplateDto) => {
    let template: Template;
    if (!!dto.eTag) {
      template = await this.document.editTemplate(dto);
    } else {
      template = await this.document.createTemplate(dto);
      this.document.updateTemplateTab(
        this.currentTemplate.id,
        template.id,
        true
      );
    }

    // if we edit a supplied template a new template is created, we need to
    //keep track of the original ID so the tab will still close on 'save and exit' and close
    if (template.id !== this.currentTemplate.id) {
      this.originalTemplateId = this.currentTemplate.id;
    }

    runInAction(() => {
      this.currentTemplate = template;
    });
    return template;
  };

  getTemplateDto = (values: TemplateWriterValues) => {
    const updatedDto = { ...this.dto };
    updatedDto.content = values.documentContent;
    updatedDto.documentType = values.documentType;
    updatedDto.name = values.name;
    updatedDto.documentStatus = values.documentStatus;
    updatedDto.isAdmin =
      values.visibility === TemplateVisibility.Both ||
      values.visibility === TemplateVisibility.Admin;
    updatedDto.isClinical =
      values.visibility === TemplateVisibility.Both ||
      values.visibility === TemplateVisibility.Clinical;

    return updatedDto;
  };

  @observable
  previewDocument: Document | undefined;

  @action
  setPreviewDocument = (previewDocument: Document | undefined) => {
    this.previewDocument = previewDocument;
  };

  onSubmit = async (
    values: TemplateWriterValues,
    submitType: SubmitAction
  ): Promise<void> => {
    const dto = this.getTemplateDto(values);
    if (dto && dto.id) {
      if (submitType === SubmitAction.PublishToEveryone) {
        dto.documentStatus = SubmitActionCode.PublishToEveryoneCode;
      } else if (submitType === SubmitAction.PublishToSelf) {
        dto.documentStatus = SubmitActionCode.PublishToSelfCode;
      } else if (
        (submitType === SubmitAction.Draft ||
          submitType === SubmitAction.SaveAndClose ||
          submitType === SubmitAction.Save) &&
        (dto.documentStatus === SubmitActionCode.DraftCode ||
          dto.documentStatus === undefined)
      ) {
        dto.documentStatus = SubmitActionCode.DraftCode;
      }
      await this.saveValues(dto);

      const templateId = this.originalTemplateId ?? this.currentTemplate.id;

      if (
        submitType === SubmitAction.SaveAndClose ||
        submitType === SubmitAction.PublishToEveryone ||
        submitType === SubmitAction.PublishToSelf
      ) {
        this.document.closeTemplate(templateId);
      } else if (submitType === SubmitAction.Delete) {
        this.document.setSelectedTemplateId(dto.id);
      } else if (submitType === SubmitAction.Preview) {
        const letterheadId = await getDefaultLetterheadId(
          this.core,
          this.practice,
          this.document
        );

        // Avoid rendering the letterhead twice
        const isLetterHeadTemplate = dto.id === letterheadId;

        const renderedContent = await this.document.renderTemplate(dto.id, {
          isPreview: !isLetterHeadTemplate,
          letterheadId:
            !isLetterHeadTemplate &&
            dto.documentType !== CorrespondenceType.Autofill
              ? letterheadId
              : undefined,
          context: {
            UserId: this.core?.userId ?? "",
            PracticeOrgUnitId: this.core.location.parentOrgUnit?.id ?? ""
          },
          contentType: isLetterHeadTemplate
            ? DocumentContentType.Pdf
            : DocumentContentType.Sfdt
        });
        this.setPreviewDocument({
          entityId: "",
          documentId: dto.id,
          entityType: DocumentEntityType.DocumentProcessing,
          name: renderedContent.name,
          extension: DocumentContentType.Pdf,
          previewUri: new Uint8Array(decode(renderedContent.content))
        });
      }
    }
  };
}
