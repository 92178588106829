export enum CardIds {
  summary = "summary",
  review = "review"
}

export enum CardHeadings {
  summary = "Summary",
  review = "Review"
}

export enum ClaimReviewStatus {
  draft = "DRAFT",
  completed = "COMPLETED"
}

export enum DocumentStatus {
  pending = "PENDING",
  created = "CREATED",
  deleted = "DELETED"
}
