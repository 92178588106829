export enum SubmitAction {
  Save = "Save",
  SaveAndClose = "SaveAndClose",
  SaveAs = "Save as",
  Email = "Email",
  Finish = "Finish",
  Delete = "Delete",
  PublishToEveryone = "PublishToEveryone",
  PublishToSelf = "PublishToSelf",
  Draft = "Draft",
  Preview = "Preview"
}
