import { action, computed, observable, runInAction } from "mobx";
import { stringifyUrl } from "query-string";

import { CalendarEventReminderStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  Permission,
  SecGroupAccessRequestStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  NotificationSubtype,
  NotificationType
} from "@libs/gateways/notification/NotificationGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { SystemNotification as SystemNotificationModel } from "@stores/notifications/models/SystemNotification.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  IActionSet,
  NotificationBehaviour,
  NotificationTypeBehaviourCollection,
  RenderDirection
} from "../../notification.types.ts";
import { NotificationsPivotKeys } from "../system-notification/SystemNotifications.types.ts";

export class NotificationHelper {
  constructor(private root: RootStore) {}

  get routing() {
    return this.root.routing;
  }

  get notification() {
    return this.root.notification;
  }

  get core() {
    return this.root.core;
  }

  @observable
  selectedPivot: NotificationsPivotKeys = NotificationsPivotKeys.All;

  @action
  setPivotSelection = (value: NotificationsPivotKeys) => {
    this.selectedPivot = value;
  };

  @computed
  get unreadCount() {
    return this.notification.unreadCount;
  }

  @computed
  get systemNotifications() {
    return Array.from(this.notification.systemNotificationsMap.values());
  }

  @computed
  get unreadNotifications() {
    return this.systemNotifications.filter(n => !n.isRead);
  }

  updateReadStatus = async (notificationId: string, isRead: boolean) => {
    await this.notification.updateNotification(
      notificationId,
      this.root.core.userId,
      { isRead }
    );
  };

  clearNotificationsCache = () => {
    runInAction(() => {
      this.notification.systemNotificationsMap.clear();
    });
  };

  readAll = async () => {
    await this.notification.markAllRead(this.root.core.userId);
  };

  updateSecGroupAccessRequest = async (
    status: SecGroupAccessRequestStatus,
    context?: { [key: string]: string }
  ) => {
    if (!!context) {
      const data = {
        id: context["SecGroupAccessRequestId"],
        status
      };

      await this.core.updateSecGroupAccessRequestStatus(data);
    } else {
      this.notification.error(
        "Unable to update request - Notification is missing context."
      );
    }
  };

  getNotificationActionSet = (
    notification: SystemNotificationModel
  ): IActionSet | undefined => {
    const typeActionDataMap: NotificationTypeBehaviourCollection = {
      [NotificationType.Booking]: {
        renderDirection: RenderDirection.Horizontal,
        behaviours: [
          {
            key: "BookingDefaultNotificationAction",
            text: "Go to calendar",
            url: routes.calendarEvents.basePath.pattern,
            permissions: [Permission.CalendarEventRead]
          }
        ],
        subtypes: {
          [NotificationSubtype.AppointmentReminderFailed]: {
            renderDirection: RenderDirection.Horizontal,
            behaviours: [
              {
                key: "AppointmentReminderFailedAction",
                text: "Go to reminders",
                url: routes.calendarEvents.basePath.pattern,
                query: {
                  [routes.calendarEvents.queryKeys.calendarPage]:
                    "eventReminders",
                  [routes.calendarEvents.queryKeys.reminderTab]:
                    CalendarEventReminderStatus.NOREPLY
                },
                permissions: [Permission.CalendarEventRead]
              }
            ]
          }
        }
      },
      [NotificationType.Security]: {
        subtypes: {
          [NotificationSubtype.SecGroupAccessRequest]: {
            renderDirection: RenderDirection.Horizontal,
            behaviours: [
              {
                key: "ApproveRequest",
                text: "Approve",
                onClick: async (context?: { [key: string]: string }) =>
                  this.updateSecGroupAccessRequest(
                    SecGroupAccessRequestStatus.Approved,
                    context
                  )
              },
              {
                key: "DenyRequest",
                text: "Deny",
                onClick: async (context?: { [key: string]: string }) =>
                  this.updateSecGroupAccessRequest(
                    SecGroupAccessRequestStatus.Denied,
                    context
                  )
              }
            ]
          }
        }
      }
    };

    const typeBaseBehaviour = typeActionDataMap[notification.type];

    const notificationType = notification.subtype
      ? typeBaseBehaviour?.subtypes?.[notification.subtype]
      : typeBaseBehaviour;

    const actions: NotificationBehaviour[] | undefined =
      notificationType?.behaviours;

    const renderDirection = notificationType?.renderDirection;

    if (!actions) return;

    const routing = this.routing;

    return {
      renderDirection,
      actions: actions.map(action => {
        return {
          key: action.key,
          text: action.text,
          permissions: action.permissions,
          permissionsOperator: action.permissionsOperator,
          onClick: () => {
            if (action.onClick) {
              action.onClick(notification.context);
            } else if (action.url) {
              const url = stringifyUrl({
                url: action.url,
                query: {
                  ...action.query
                }
              });

              routing.push(url, { ...notification.context });
            }
          }
        };
      })
    };
  };
}
