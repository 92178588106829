import {
  IButtonStyles,
  IStackStyles,
  ITextFieldStyles,
  ITheme,
  IToggleStyles
} from "@bps/fluent-ui";

export const formDateInputStyles: Partial<ITextFieldStyles> = {
  root: { width: 150 },
  field: { width: "100%" },
  fieldGroup: { width: "100%" }
};

export const FEE_FORM_CHANGES_BADGE_WIDTH = 80;
export const feeFormChangesBadgeStyles: Partial<IStackStyles> = {
  root: {
    width: FEE_FORM_CHANGES_BADGE_WIDTH,
    flexShrink: 0,
    alignSelf: "flex-start",
    marginTop: 4
  }
};
export const feeFormChangesFeeStyles: Partial<IStackStyles> = {
  root: { width: 130 }
};
export const feeFormChangesOngoingToggleStyles: Partial<IToggleStyles> = {
  root: { marginTop: 4, width: 80 },
  label: { cursor: "pointer", fontWeight: 400, marginLeft: 8 }
};

export const getIconButtonStyles = (theme: ITheme): IButtonStyles => {
  return {
    root: {
      padding: 0,
      width: 24,
      height: 24,
      marginTop: 2
    },
    icon: {
      margin: 0,
      color: theme.palette.neutralPrimary,
      fontSize: theme.fonts.medium.fontSize
    }
  };
};
