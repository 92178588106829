import React, { ReactNode } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Dialog,
  Heading,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  MeasurementDto,
  QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

interface ClinicalToolScoreModalProps {
  title: string;
  onClose: () => void;
  sentenceText?: string;
  diagnosis: string;
  boldDiagnosis?: boolean;
  measurements?: MeasurementDto[];
  questionnaireResponse?: QuestionnaireResponseDto;
  onRenderModalContent?: (
    diagnosis: string,
    measurements?: MeasurementDto[],
    questionnaireResponse?: QuestionnaireResponseDto
  ) => ReactNode;
}

export const ClinicalToolScoreModal: React.FunctionComponent<
  ClinicalToolScoreModalProps
> = ({
  title,
  sentenceText,
  diagnosis,
  boldDiagnosis,
  measurements,
  questionnaireResponse,
  onClose,
  onRenderModalContent
}) => {
  const formatSummary = () => {
    if (boldDiagnosis) {
      return (
        <>
          <Text>{sentenceText}</Text>
          <Text styles={{ root: { fontWeight: "bold" } }}>{diagnosis}.</Text>
        </>
      );
    } else {
      return <Text>{diagnosis}</Text>;
    }
  };

  return (
    <Dialog
      minWidth={600}
      hidden={false}
      onDismiss={() => {
        onClose();
      }}
      dialogContentProps={{
        showCloseButton: true,
        title: <Heading variant="modal-heading">{title}</Heading>
      }}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        {onRenderModalContent ? (
          onRenderModalContent(diagnosis, measurements, questionnaireResponse)
        ) : (
          <MessageBar
            {...dataAttribute(DataAttributes.Element, "score-modal")}
            messageBarType={MessageBarType.info}
          >
            {formatSummary()}
          </MessageBar>
        )}

        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { paddingTop: 16 } }}
          tokens={{ childrenGap: 8 }}
        >
          <DefaultButton
            onClick={() => {
              onClose();
            }}
          >
            Close
          </DefaultButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
