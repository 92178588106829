import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { MoveCorrespondenceFormValues } from "./MoveCorrespondenceFormValues.ts";

export class MoveCorrespondenceFormValidator extends Validator<MoveCorrespondenceFormValues> {
  constructor() {
    super();

    this.forField("sourcePatientId", [required()]);
    this.forField("sourceFileId", [required()]);
    this.forField("destinationPatientId", [required()]);
  }
}
