import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  mergeStyles,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";

export type PreferredArgs = {
  userId?: string;
  preferredOptions?: { value: string; type: CommunicationType };
};

export type PreferredCheckBoxProps = {
  preferred: boolean;
  tooltipContent: string;
  userId?: string;
  contMethodsArgs?: { value: string; type: CommunicationType };
  onPreferredClick: (preferredArgs: PreferredArgs) => void;
};

export const PreferredCheckBox: React.FC<PreferredCheckBoxProps> = ({
  preferred,
  tooltipContent,
  onPreferredClick,
  contMethodsArgs,
  userId
}) => {
  const { core } = useStores();

  const theme = useTheme();
  const getPreferredIconStyles = {
    margin: 4
  };

  const preferredGoldColor = { color: theme.palette.yellow };
  const { itemFullyCentered } = getPeopleScreenStylesSet(theme);

  const canTogglePreferred = core.hasPermissions([
    Permission.ContactRead,
    Permission.ContactWrite,
    Permission.PatientRead,
    Permission.PatientWrite
  ]);

  const argsOnPreferredClick: PreferredArgs = {
    userId,
    preferredOptions: {
      type: contMethodsArgs?.type!,
      value: contMethodsArgs?.value!
    }
  };

  const preferredClick = (preferredArgs: PreferredArgs) => () => {
    onPreferredClick(preferredArgs);
  };

  if (userId && !contMethodsArgs) {
    delete argsOnPreferredClick.preferredOptions;
  } else {
    delete argsOnPreferredClick.userId;
  }

  return (
    <TooltipHost content={canTogglePreferred ? tooltipContent : undefined}>
      <FontIcon
        {...dataAttribute(
          DataAttributes.Element,
          `preferred-item-${preferred ? "favorite-star-fill" : "favorite-star"}`
        )}
        iconName={preferred ? "FavoriteStarFill" : "FavoriteStar"}
        className={mergeStyles({
          cursor: canTogglePreferred ? "pointer" : "default",
          itemFullyCentered,
          ...getPreferredIconStyles,
          ...(preferred ? preferredGoldColor : undefined)
        })}
        onClick={
          canTogglePreferred ? preferredClick(argsOnPreferredClick) : undefined
        }
      />
    </TooltipHost>
  );
};
