import { DateTime } from "@bps/utils";
import {
  MedicalHistoryClinicalDataItemDto,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  greaterThan,
  isNotFutureDate,
  predicate,
  required,
  requiredCharactersLength,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  AddMedicalHistoryFormValues,
  MedicalHistoryDateKey,
  MedicalHistoryFormValues
} from "./MedicalHistoryFormValues.ts";
import { getPotentialSimilarDiagnosisDates, getYearFromAge } from "./utils.ts";

const currentYear = DateTime.now().year;
const currentMonth = DateTime.now().month;

const isYearAfterBirthDate =
  (birthDate: Date | undefined, messageFieldName: string): FieldValidator =>
  value =>
    birthDate && value < birthDate.getFullYear()
      ? `${messageFieldName} must be after patient's Date of Birth.`
      : undefined;

const isSameOrAfterBirthDate =
  (birthDate: Date | undefined, messageFieldName: string): FieldValidator =>
  (val: string, values: MedicalHistoryFormValues) => {
    return birthDate &&
      values.year &&
      Number(values.year) === birthDate.getFullYear() &&
      Number(val) < birthDate.getMonth() + 1
      ? `${messageFieldName} must be after patient's Date of Birth.`
      : undefined;
  };

const isDateSameOrAfterDate =
  (date: Date | undefined, message: string): FieldValidator =>
  value => {
    return !date ||
      (date && value
        ? DateTime.fromJSDate(value) >= DateTime.fromJSDate(date)
        : undefined)
      ? undefined
      : message;
  };

const isYearValid: FieldValidator = (value: string | number) =>
  Number(value) > currentYear ? ValidationMessages.futureDate : undefined;

const isMonthValid: FieldValidator = (
  value: string | number,
  values: MedicalHistoryFormValues
) =>
  values.year &&
  Number(values.year) === currentYear &&
  Number(value) > currentMonth
    ? ValidationMessages.futureDate
    : false;

const isDuplicatedDiagnosisTermExistedOnDate = (
  values: MedicalHistoryFormValues,
  params: {
    terminology: TerminologyDto | undefined;
    medicalHistories: MedicalHistoryClinicalDataItemDto[] | undefined;
    selectedId: string | undefined;
  }
) => {
  const { terminology, medicalHistories, selectedId } = params;
  const { date, dateKey, diagnosisSideSelected } = values;

  const exactDate = DateTime.fromJSDate(date)?.toISODate();

  //get all potential duplications' datas
  const potentialSimilarDiagnosistDates =
    terminology && medicalHistories
      ? getPotentialSimilarDiagnosisDates(terminology, medicalHistories, {
          selectedId,
          diagnosisSideSelected
        })
      : [];

  return (
    dateKey === MedicalHistoryDateKey.Exact &&
    exactDate &&
    potentialSimilarDiagnosistDates &&
    potentialSimilarDiagnosistDates.includes(exactDate)
  );
};

//age field validations
const isAgeValid =
  (birthDate: Date | undefined): FieldValidator =>
  value => {
    if (!birthDate) {
      return "Patient's Date of birth must be set";
    }

    const year = getYearFromAge(DateTime.fromJSDate(birthDate), value);
    if (year > currentYear) {
      return "Age entered cannot be greater than patients age";
    }
    if (value < 0) {
      return "Invalid age";
    }
    return;
  };

const isDateKey =
  (dateKey: MedicalHistoryDateKey | MedicalHistoryDateKey[]) =>
  (val: string, values: MedicalHistoryFormValues) => {
    if (!values.dateKey) return false;
    if (Array.isArray(dateKey)) return dateKey.includes(values.dateKey);
    return values.dateKey === dateKey;
  };

export class MedicalHistoryFormValidator extends Validator<
  RecursivePartial<MedicalHistoryFormValues>
> {
  constructor({
    birthDate,
    terminologyMap,
    medicalHistories,
    selectedId
  }: {
    birthDate: Date | undefined;
    terminologyMap: Map<string, TerminologyDto>;
    medicalHistories?: MedicalHistoryClinicalDataItemDto[];
    selectedId?: string | undefined;
  }) {
    super();
    this.forField("diagnosisKey", required());
    this.forField(
      "diagnosisSideSelected",
      predicate((value, values) => {
        const hasSide =
          !!values?.diagnosisKey &&
          !!terminologyMap.get(values?.diagnosisKey)?.isSide;
        return hasSide && (!value || Array.isArray(value));
      }, required())
    );

    this.forField(
      "age",
      predicate(
        isDateKey(MedicalHistoryDateKey.Age),
        greaterThan(0),
        required(),
        isAgeValid(birthDate)
      )
    );

    this.forField(
      "month",
      predicate(
        isDateKey(MedicalHistoryDateKey.YearMonth),
        required(),
        isMonthValid,
        isSameOrAfterBirthDate(birthDate, "Month")
      )
    );
    this.forField(
      "year",
      predicate(
        isDateKey([
          MedicalHistoryDateKey.Year,
          MedicalHistoryDateKey.YearMonth
        ]),
        required(),
        requiredCharactersLength(4),
        isYearValid,
        isYearAfterBirthDate(birthDate, "Year")
      )
    );

    this.forField("dateKey", [
      predicate(
        (val, appt: AddMedicalHistoryFormValues) =>
          appt.clinicallySignificant === true,
        required()
      )
    ]);

    this.forField(
      "date",
      predicate(
        isDateKey(MedicalHistoryDateKey.Exact),
        validDate(),
        required(),
        isNotFutureDate(ValidationMessages.futureDateEqualOrLess),
        isDateSameOrAfterDate(birthDate, ValidationMessages.afterBirthdate),
        (value, values) => {
          const { diagnosisKey } = values;
          const terminology = diagnosisKey
            ? terminologyMap.get(diagnosisKey)
            : undefined;
          return isDuplicatedDiagnosisTermExistedOnDate(values, {
            terminology,
            medicalHistories,
            selectedId
          });
        }
      )
    );
  }
}
