import { observer } from "mobx-react-lite";
import { useRef } from "react";

import { Card, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserHelper } from "@modules/settings/screens/users/components/context/UserHelper.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { SettingsLabels } from "../shared-components/SettingsLabels.ts";
import { UserContext } from "./components/context/UserContext.tsx";
import { CreateUserFormDialogWithToggle } from "./components/create-user-form-dialog/CreateUserFormDialogWithToggle.tsx";
import { UserList } from "./components/UserList.tsx";
import { UserListFilter } from "./components/UserListFilters.tsx";
import { LicenceSummary } from "./LicenceSummary.tsx";

const UsersScreen: React.FC = observer(() => {
  const root = useStores();

  const helper = useRef<UserHelper>(new UserHelper(root)).current;

  return (
    <Stack styles={{ root: { height: "100%" } }}>
      <UserContext.Provider value={helper}>
        <When permission={Permission.LicencingAllowed}>
          <LicenceSummary />
        </When>
        <Card
          styles={{
            subComponentStyles: {
              tile: {
                content: {
                  padding: "16px",
                  position: "relative"
                },
                root: {
                  maxWidth: 1500,
                  flexGrow: 1
                }
              }
            }
          }}
          headingLevel="section-heading"
          heading={SettingsLabels.usersList}
          iconName="contact"
          button={
            <When permission={Permission.SecurityWrite}>
              <CreateUserFormDialogWithToggle />
            </When>
          }
        >
          <Stack
            styles={{ root: { height: "100%" } }}
            tokens={{ childrenGap: 8 }}
          >
            <UserListFilter>
              {state => <UserList filter={state.values} />}
            </UserListFilter>
          </Stack>
        </Card>
      </UserContext.Provider>
    </Stack>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default UsersScreen;
