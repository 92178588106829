import React from "react";
import { useForm } from "react-final-form";

import { AccordionItem, FontWeights, Separator, Stack } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import {
  MEDIUM_BREAKPOINT,
  SMALL_BREAKPOINT
} from "../../full-body-clinical-data/examination/utils.ts";
import { ObservationsLabels } from "../../observations/utils.ts";
import { BloodPressuresList } from "./BloodPressuresList.tsx";
import { GeneralExamFieldConditionKeys } from "./GeneralExamination.types.ts";
import { nameOfGeneralExaminationForm } from "./GeneralExaminationFields.tsx";
import {
  BloodPressure,
  GeneralExaminationFormValues
} from "./GeneralExaminationFormValues.ts";
import { GeneralExamRespiratoryField } from "./GeneralExamRespiratoryField.tsx";
import { GeneralExamTemperatureField } from "./GeneralExamTemperatureField.tsx";
import { GeneralHighLowSevereWarningMessageBar } from "./GeneralHighLowSevereWarningMessageBar.tsx";
import { PulseList } from "./PulseList.tsx";
import {
  getButtonGroupBreakLineStyles,
  getGeneralExamAccordionItemStyles,
  getSeparatorProps
} from "./utils.ts";

interface AccordionItemVitalSignsProps {
  values: GeneralExaminationFormValues;
  width: number;
}
export const AccordionItemVitalSigns: React.FC<
  AccordionItemVitalSignsProps
> = ({ values, width }) => {
  const { clinical } = useStores();
  const form = useForm<GeneralExaminationFormValues>();
  const breakLines = width <= SMALL_BREAKPOINT;
  const wrapValues = width <= MEDIUM_BREAKPOINT;
  const separatorStyles = getSeparatorProps();
  const nameOfBloodPressure = nameOfFactory<BloodPressure>();
  const {
    hydration,
    hydrationRate,
    pulses,
    bloodPressures,
    pulseRhythm,
    pulseCharacteristic,
    temperature,
    temperatureMethod,
    respiratoryRate,
    o2SaturationRate,
    bGLFasting,
    bGLLevel
  } = values;

  const checkIsPlaceholderObject = (obj: any, ignoreProps?: string[]) => {
    for (const prop in obj) {
      if (
        obj.hasOwnProperty(prop) &&
        !!obj[prop] &&
        !ignoreProps?.includes(prop)
      ) {
        return false;
      }
    }
    return true;
  };

  const isEdited =
    !!hydration ||
    !!hydrationRate ||
    (!!pulses &&
      pulses.length > 0 &&
      pulses.some(x => !checkIsPlaceholderObject(x))) ||
    (!!bloodPressures &&
      bloodPressures.length > 0 &&
      bloodPressures.some(
        x => !checkIsPlaceholderObject(x, [nameOfBloodPressure("timeStamp")])
      )) ||
    !!pulseRhythm ||
    !!pulseCharacteristic ||
    !!temperature ||
    !!temperatureMethod ||
    !!respiratoryRate ||
    !!o2SaturationRate ||
    !!bGLFasting ||
    !!bGLLevel;

  const isHypovolemic = hydration
    ? hydration === GeneralExamFieldConditionKeys.Hypovolemic
    : false;

  return (
    <AccordionItem
      title="Vital signs"
      showTitleIcon={isEdited}
      titleIconName="PageEdit"
      styles={getGeneralExamAccordionItemStyles()}
      extendedByDefault={true}
    >
      <Stack tokens={{ childrenGap: 8, padding: 0 }}>
        {/* Pulse */}
        <PulseList width={width} />

        {/* Blood pressure */}
        <Separator {...separatorStyles} />
        <BloodPressuresList wrapValues={wrapValues} />

        {/* Temperature */}
        <Separator {...separatorStyles} />
        <GeneralExamTemperatureField width={width} />

        {/* Respiratory rate */}
        <Separator {...separatorStyles} />
        <GeneralExamRespiratoryField />

        {/* Blood glucose level (BGL) */}
        <Separator {...separatorStyles} />
        <Fieldset
          horizontal
          tokens={{ childrenGap: 8 }}
          legend={ObservationsLabels.BGL}
          legendLevel="section-heading-light"
        >
          <SpinNumberInputField
            name={nameOfGeneralExaminationForm("bGLLevel")}
            min={0.1}
            max={99.9}
            step={0.1}
            precision={2}
            maxDigitsNumber={3}
            styles={{ root: { width: 100 } }}
          />
          <ButtonsGroupSingleChoiceField
            options={clinical.ref.bslQualifiers.keyTextValues}
            name={nameOfGeneralExaminationForm("bGLFasting")}
          />
        </Fieldset>

        {bGLLevel && (
          <GeneralHighLowSevereWarningMessageBar
            min={4}
            max={15}
            minMessage="Low BGL"
            maxMessage="High BGL"
            value={parseFloat(bGLLevel)}
          />
        )}

        {/* Hydration*/}
        <Separator {...separatorStyles} />
        <Fieldset
          horizontal={!wrapValues}
          tokens={{ childrenGap: 8 }}
          legend="Hydration"
          legendLevel="section-heading-light"
          onChange={() => {
            if (!isHypovolemic) {
              form.change(
                nameOfGeneralExaminationForm("hydrationRate"),
                undefined
              );
            }
          }}
        >
          <ButtonsGroupSingleChoiceField
            options={clinical.ref.hydrations.keyTextValues}
            name={nameOfGeneralExaminationForm("hydration")}
            vertical={breakLines}
            styles={getButtonGroupBreakLineStyles(breakLines)}
          />
          {isHypovolemic && (
            <Stack
              horizontal
              styles={{ root: { alignItems: "center" } }}
              tokens={{ childrenGap: 8 }}
            >
              <SpinNumberInputField
                name={nameOfGeneralExaminationForm("hydrationRate")}
                min={1}
                max={999}
                maxDigitsNumber={3}
                styles={{ root: { width: 100 } }}
                fieldItemStyles={{
                  suffix: { fontWeight: FontWeights.semibold }
                }}
                suffix="%"
              />
            </Stack>
          )}
        </Fieldset>
      </Stack>
    </AccordionItem>
  );
};
