import { useState } from "react";

import {
  ActionButton,
  Callout,
  FontWeights,
  Heading,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

interface ClaimAdjustmentResponseCalloutProps {
  id: string;
  number: string;
  purchaseOrder?: string;
  responseNotes?: string;
}

export const ClaimAdjustmentResponseCallout: React.FC<
  ClaimAdjustmentResponseCalloutProps
> = ({ id, number, purchaseOrder, responseNotes }) => {
  const [showCallout, setShowCallout] = useState(false);
  const claimAdjustmentId = `claim-adjustment-response-callout-${id}`;
  const theme = useTheme();

  return (
    <>
      <ActionButton
        id={claimAdjustmentId}
        onClick={() => setShowCallout(prev => !prev)}
        iconProps={{ iconName: "TextDocument" }}
        styles={{
          root: { height: "auto" },
          label: { color: theme.semanticColors.link }
        }}
      >
        PO details
      </ActionButton>
      <Callout
        target={`#${claimAdjustmentId}`}
        calloutWidth={570}
        hidden={!showCallout}
        onDismiss={() => setShowCallout(prev => !prev)}
      >
        <Stack
          styles={{ root: { padding: "20px 24px" } }}
          tokens={{ childrenGap: 8 }}
        >
          <Heading
            styles={{ root: { marginBottom: 16 } }}
            variant="section-heading-light"
          >
            ACC32 {number} details
          </Heading>
          {purchaseOrder && (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Text styles={{ root: { fontWeight: FontWeights.bold } }}>
                Purchase order
              </Text>
              <Text>{purchaseOrder}</Text>
            </Stack>
          )}
          {responseNotes && (
            <Stack>
              <Text styles={{ root: { fontWeight: FontWeights.bold } }}>
                Notes
              </Text>
              <Text> {responseNotes} </Text>
            </Stack>
          )}
        </Stack>
      </Callout>
    </>
  );
};
