import { compareDatesPredicate, DateTime } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import {
  OrgUnitHierarchyType,
  PatchOrgUnitDto
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AddressType,
  CommunicationDto,
  CommunicationType,
  LocationDataDto,
  NzOrgUnitIdentifierDto,
  OrgUnitCompanyDataType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { Address } from "@stores/core/models/Address.ts";
import { formatPhone, parsePhone } from "@stores/core/models/Communication.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { NotificationsStore } from "@stores/notifications/NotificationsStore.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { LocationDetailsValues } from "./LocationDetails.types.ts";

export const onFormSubmit = async (
  values: LocationDetailsValues,
  options: {
    core: CoreStore;
    practice: PracticeStore;
    notification: NotificationsStore;
  },
  parentOrgUnitId: string | undefined
) => {
  const { phone, email } = values;
  const { core, practice, notification } = options;

  const workPhone = parsePhone(phone);
  const communications: CommunicationDto[] = [];

  let city: string | undefined;
  if (values.city) {
    if (values.country === Country.Australia) {
      city = "";
    } else {
      city = values.city;
    }
  }

  const addresses: Address[] = [
    {
      type: AddressType.Physical,
      street1: values.address1!,
      suburb: values.suburb,
      city,
      state: values.state,
      postCode: values.postCode,
      country: values.country ?? Country.NewZealand
    }
  ];

  if (email) {
    communications.push({
      type: CommunicationType.Email,
      value: email,
      preferred: false
    });
  }
  if (workPhone) {
    communications.push({
      type: CommunicationType.WorkPhone,
      value: workPhone,
      preferred: false
    });
  }

  const locationData: LocationDataDto = {
    orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.LocationData,

    appointmentBookMarkerCode: values?.appointmentBookMarker
      ? values?.appointmentBookMarker
      : "",
    nickName: values?.locationNickname ? values?.locationNickname : "",
    defaultLocation: values?.defaultLocation
      ? values?.defaultLocation
      : undefined
  };

  const nzOrgUnitData: NzOrgUnitIdentifierDto = {
    hpiFacilityId: values.hpiFacilityId,
    orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.NzOrgUnitIdentifier
  };

  let locationId: string | undefined;

  if (values.id) {
    const patchOrgUnitDto: Omit<PatchOrgUnitDto, "eTag"> = {
      id: values.id,
      addresses,
      parentOrgUnitId,
      contact: communications,
      name: values.practiceName,
      hierarchyType: OrgUnitHierarchyType.Location,
      timeZone: values.timezone
    };

    await core.updateOrgUnit(patchOrgUnitDto);
    locationId = values.id;
    notification.success(`${values.practiceName} has been updated`);
  } else {
    const response = await core.addOrgUnit({
      parentOrgUnitId,
      name: values.practiceName,
      timeZone: values.timezone,
      contact: communications,
      addresses,
      hierarchyType: OrgUnitHierarchyType.Location
    });
    locationId = response.id;

    notification.success(`${values.practiceName} has been added`);
  }
  await practice.updateOrgUnit({
    id: locationId,
    orgUnitCompanyData: [locationData, nzOrgUnitData]
  });
};

export const fetchAllLocations = async (
  core: CoreStore,
  practice: PracticeStore
): Promise<OrgUnit[]> => {
  const { results: locations } = await core.loadOrgUnits();
  const locationData = await practice.getAllOrgUnitsLocationData();

  if (locations.length) {
    const orgUnits = locations
      .filter(l => locationData.find(data => data.id === l.id))
      .sort((a, b) =>
        compareDatesPredicate(b.createdLocationDate, a.createdLocationDate)
      );
    await Promise.all(
      orgUnits.map(unit => unit.preloadIsDefaultLocation(unit.id))
    );
    return orgUnits;
  }
  return [];
};

export const fetchLocationById = async (
  root: RootStore,
  locationId: string
) => {
  const [pracOrgUnit, locationOrgUnit] = await Promise.all([
    root.practice.getOrgUnit(locationId),
    root.core.getOrgUnit(locationId)
  ]);

  // No need to check for appointments or waiting list entries if location is default

  const hasAppointmentsPromise = pracOrgUnit?.isDefault
    ? false
    : root.booking.hasFutureCalendarEvents({
        orgUnitIds: [locationId]
      });

  const hasWaitingListEntriesPromise = pracOrgUnit?.isDefault
    ? false
    : root.booking
        .getLocationWaitingListItems(locationId)
        .then(result =>
          result.some(
            x => DateTime.fromISO(x.until) > DateTime.today().minus({ days: 1 })
          )
        );

  const [hasAppointments, hasFutureWaitingListEntries] = await Promise.all([
    hasAppointmentsPromise,
    hasWaitingListEntriesPromise
  ]);

  return {
    pracOrgUnit,
    locationOrgUnit,
    hasAppointments,
    hasFutureWaitingListEntries
  };
};

export const getInitialValues = (
  pracOrgUnit: PracOrgUnit | undefined,
  locationOrgUnit: OrgUnit | undefined
): LocationDetailsValues => {
  const country = locationOrgUnit?.firstAddress?.country;

  const address = locationOrgUnit?.firstAddress;

  const workPhone: CommunicationDto = {
    type: CommunicationType.WorkPhone,
    value:
      locationOrgUnit && locationOrgUnit.workPhone
        ? locationOrgUnit.workPhone
        : "",
    preferred: false
  };
  return {
    id: pracOrgUnit?.id,
    practiceName: locationOrgUnit ? locationOrgUnit?.name : "",
    isInactiveLocation: locationOrgUnit?.isInactive,
    locationNickname: pracOrgUnit?.orgUnitLocationData?.nickName,
    appointmentBookMarker:
      pracOrgUnit?.orgUnitLocationData?.appointmentBookMarkerCode,
    address1: address?.street1,
    address2: address?.street2,
    suburb: address?.suburb,
    country: address?.country,
    postCode: address?.postCode,
    city: address?.city,
    state: address?.state,
    phone: formatPhone(workPhone.value, country),
    timezone: locationOrgUnit?.timezone,
    email: locationOrgUnit?.email,
    hpiFacilityId: pracOrgUnit?.nzOrgUnitIdentifier?.hpiFacilityId,
    defaultLocation: pracOrgUnit?.orgUnitLocationData?.defaultLocation
  };
};
