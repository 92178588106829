import { action, observable } from "mobx";

import { DateTime } from "@bps/utils";
import { GetStatementArgs } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { StatementGenerateFormValues } from "@modules/billing/screens/statement/components/StatementGenerateModal.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Statement } from "@stores/billing/models/Statement.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export interface StatementItem {
  statement: Statement;
  contact: Contact;
}

export class StatementItemsHelper {
  constructor(private root: IRootStore) {}

  getStatements = async (query: GetStatementArgs) => {
    return this.root.billing.getStatements({
      ...query,
      accountContactId: undefined
    });
  };

  handleSubmitAction = async (values: StatementGenerateFormValues) => {
    return this.root.billing.addStatementBulk({
      date: DateTime.now().toISODate(),
      startDate: DateTime.jsDateToISODate(values.startDate),
      endDate: DateTime.jsDateToISODate(values.endDate)
    });
  };

  nameOf = nameOfFactory<StatementGenerateFormValues>();

  @observable
  isStatementNewModalVisible = false;
  @action
  setIsStatementNewModalVisible = (value: boolean) => {
    this.isStatementNewModalVisible = value;
  };
}
