import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { IButtonProps, PrimaryButton } from "@bps/fluent-ui";
import { CommsSectionViews } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface CommsScreenCommandsProps {
  selectedKey: CommsSectionViews;
}

export const CommsScreenCommands: FunctionComponent<CommsScreenCommandsProps> =
  observer(({ selectedKey }) => {
    const { routing } = useStores();

    let buttonProps: IButtonProps | undefined;

    if (selectedKey === CommsSectionViews.templates) {
      buttonProps = {
        text: "Template",
        iconProps: { iconName: "Add" },
        onClick: () => {
          routing.push(routes.settings.communications.template.new.pattern);
        }
      };
    }

    if (selectedKey === CommsSectionViews.schedule) {
      buttonProps = {
        text: "Schedule",
        iconProps: { iconName: "Add" },
        onClick: () => {
          routing.push(routes.settings.communications.schedule.new.pattern);
        }
      };
    }

    return <PrimaryButton styles={{ root: { height: 32 } }} {...buttonProps} />;
  });
