import {
  CareGiverDto,
  CareRecipientDto,
  EnduringPowerOfAttorneyDto,
  PhysicalActivityDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export const CARE_CONTACTS_MIN_WIDTH = 340;
export const CARE_CONTACTS_MAX_WIDTH = 416;
export const CARE_TYPES_MIN_WIDTH = 88;
export const CARE_TYPES_MAX_WIDTH = 300;

export interface SocialFormValues {
  social: SocialValues;
  physicalActivity: PhysicalActivityValues;
  patientDemographic: PatientDemographicValues;
  socialConfirmed: SocialConfirmedValues;
}

interface SocialValues {
  sexuality?: string;
  isPregnant?: string;
  isBreastfeeding?: string;
  isAdvancedCareDirective?: string;
  accommodation?: string;
  livesWith?: string;
  safetyInHome?: string;
}

interface PhysicalActivityValues {
  isEliteAthlete?: string;
  activities?: PhysicalActivityDto[];
}

export interface PatientDemographicValues {
  relationshipStatus?: string;
  hasACareGiver?: string;
  isACarer?: string;
  isEnduringPowerOfAttorney?: string;
  careGivers?: CareGiverDto[];
  careRecipients?: CareRecipientDto[];
  powerOfAttorneys?: EnduringPowerOfAttorneyDto[];
}

interface SocialConfirmedValues {
  confirmed: boolean | undefined;
}
