import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import {
  getServiceTax,
  getServiceTotal
} from "@stores/billing/utils/billing.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

interface InvoiceItemListQuantitySpyProps {
  rowIndex: number;
}

export const InvoiceItemListQuantitySpy: FunctionComponent<InvoiceItemListQuantitySpyProps> =
  observer(props => {
    const { billing } = useStores();
    const form = useForm();

    return (
      <FieldSpy
        name={
          `invoiceItems[${props.rowIndex}].quantity` as keyof InvoiceFormValues
        }
        onChange={(
          value: InvoiceItemFormValue["quantity"],
          values: InvoiceFormValues
        ) => {
          const item = values.invoiceItems[props.rowIndex];
          const gst = getServiceTax(
            { ...item, quantity: value },
            billing.gstPercent
          );

          const total = getServiceTotal(
            { ...item, quantity: value },
            billing.gstPercent
          );
          form.batch(() => {
            form.change(`invoiceItems[${props.rowIndex}].gst`, gst);
            form.change(`invoiceItems[${props.rowIndex}].total`, total);
          });
        }}
      />
    );
  });
