import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { ClinicalActivityDialog } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalActivityDialog.tsx";
import { ClinicalActivityFormModel } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalActivityFormModel.ts";
import { ClinicalActivityType } from "@modules/clinical/screens/patient-record/components/clinical-activity/types/clinical-activity.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { DeleteActivityDialog } from "../todo/components/DeleteActivityDialog.tsx";
import { RemindersClinicalList } from "../todo/components/RemindersClinicalList.tsx";

export const RemindersScreen: FC = observer(() => {
  const { clinical, core } = useStores();

  const {
    setSelectedClinicalActivities,
    selectedClinicalActivities,
    patientClinicalActivities,
    activityDialogVisible,
    setActivityDialogVisible
  } = useContext(InboxScreenContext);

  const { initialValues, onSubmit } = new ClinicalActivityFormModel(
    clinical,
    core,
    undefined
  );

  const reminderInitialValues = {
    ...initialValues,
    activityType: ClinicalActivityType.Reminder
  };

  const handleDeleteConfirmed = async () => {
    const promises = selectedClinicalActivities.map(activity => {
      const item = {
        ...activity.dto,
        isDeleted: true
      };
      return clinical.updateClinicalActivity(activity.patientId, [item]);
    });
    await Promise.all(promises);
    setActivityDialogVisible({
      ...activityDialogVisible,
      delete: false
    });
    setSelectedClinicalActivities([]);
  };

  return (
    <>
      <RemindersClinicalList setSelected={setSelectedClinicalActivities} />
      <ClinicalActivityDialog
        title="New clinical reminder"
        hideActivityTypeOption
        allowProviderSelection
        allowPatientSelection
        clinicalActivities={patientClinicalActivities}
        hidden={!activityDialogVisible.add}
        onDismiss={() => {
          setActivityDialogVisible({
            ...activityDialogVisible,
            add: false
          });
        }}
        initialValues={reminderInitialValues}
        onSubmit={onSubmit}
      />

      <DeleteActivityDialog
        hidden={!activityDialogVisible.delete}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => {
          setActivityDialogVisible({
            ...activityDialogVisible,
            delete: false
          });
        }}
      />
    </>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default RemindersScreen;
