import { routes } from "@libs/routing/routes.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionCards } from "./components/ConditionCards.tsx";
import { ConditionHeader } from "./components/ConditionHeader.tsx";

export enum ConditionCardIds {
  overview = "Overview"
}

interface ConditionScreenBaseProps {
  condition: Condition;
}

const ConditionScreenBase: React.FC<ConditionScreenBaseProps> = ({
  condition
}) => {
  return (
    <CardFormLayout
      styles={{
        subComponentStyles: {
          topBarWrapper: { root: { margin: "-16px" } }
        }
      }}
      sideRailMenuItems={[
        {
          id: ConditionCardIds.overview,
          text: "Overview"
        }
      ]}
      header={<ConditionHeader condition={condition} />}
    >
      <ConditionCards condition={condition} />
    </CardFormLayout>
  );
};

const ConditionScreen = () => {
  const root = useStores();
  const conditionId = routes.conditions.summary.match(
    root.routing.location.pathname
  )?.params.id;

  if (!conditionId) {
    return null;
  }

  const fetch = async (clinical: ClinicalStore) => {
    const condition = await clinical.getCondition(conditionId);
    await Promise.all([
      condition.loadPatient(),
      condition.loadClaim(),
      condition.loadCalendarEvent()
    ]);

    return condition;
  };

  return (
    <DataFetcher<Condition> fetch={({ clinical }) => fetch(clinical)}>
      {condition => <ConditionScreenBase condition={condition} />}
    </DataFetcher>
  );
};
// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ConditionScreen;
