import { FormApi } from "final-form";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Heading } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AccreditedBillingEmailDialogValues } from "./AccreditedBillingEmailDialog.types.ts";
import { AccreditedBillingEmailDialogValidator } from "./AccreditedBillingEmailDialogValidator.tsx";
import { onSubmitAccreditedBillingEmailDialog } from "./utils.ts";

const validator = new AccreditedBillingEmailDialogValidator();

export const AccreditedBillingEmailDialog: React.FC = observer(() => {
  const { invoiceEmailQueue } = useContext(AccInvoicesListContext);
  const root = useStores();

  if (invoiceEmailQueue.length === 0) {
    return null;
  }

  const invoice = invoiceEmailQueue[0];
  const invoiceContactId = invoice.accountId;
  const nameOf = nameOfFactory<AccreditedBillingEmailDialogValues>();

  const dequeueInvoice = action(() => {
    invoiceEmailQueue.remove(invoice);
  });

  const dequeueRelatedInvoices = action(() => {
    const unrelatedInvoices = invoiceEmailQueue.filter(
      invoice => invoice.accountId !== invoiceContactId
    );
    invoiceEmailQueue.replace(unrelatedInvoices);
  });

  const onSubmit = async (values: AccreditedBillingEmailDialogValues) => {
    await onSubmitAccreditedBillingEmailDialog({
      root,
      values,
      invoice,
      invoiceEmailQueue
    });
  };

  const onDismiss = () => {
    dequeueInvoice();
  };

  const onSubmitSucceeded = (
    values: AccreditedBillingEmailDialogValues,
    form: FormApi<AccreditedBillingEmailDialogValues>
  ) => {
    form.reset();
    if (values.addAsInvoicingEmail) {
      dequeueRelatedInvoices();
    } else {
      dequeueInvoice();
    }
  };

  return (
    <DataFetcher<Contact>
      fetch={({ practice }) => practice.getContact(invoiceContactId)}
      noExceptionsHandlers
      refetchId={invoiceContactId}
    >
      {(contact, loading, error) => {
        return (
          <SubmissionFormDialog<AccreditedBillingEmailDialogValues>
            dialogName="Accredited billing - Email confirmation dialog"
            loadingData={loading}
            dataLoadingError={error?.message}
            onSubmit={onSubmit}
            onSubmitSucceeded={onSubmitSucceeded}
            validate={validator.validate}
            buttonsProps={{
              submitButtonProps: {
                text: "Send now",
                iconProps: { hidden: true }
              }
            }}
            dialogProps={{
              onDismiss,
              minWidth: 600,
              dialogContentProps: {
                title: (
                  <Heading variant="modal-heading">
                    Send invoice #{invoice.number} to {contact?.name}
                  </Heading>
                )
              }
            }}
          >
            {() => (
              <Fieldset>
                <TextInputField name={nameOf("email")} />
                <CheckboxField
                  label="Add as invoicing email"
                  name={nameOf("addAsInvoicingEmail")}
                />
              </Fieldset>
            )}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
});
