import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef } from "react";

import { ISearchBox, SearchBox } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";

export const PrescribingProductsSearch: FunctionComponent = observer(() => {
  const { drugs } = useStores();
  const helper = usePrescribingWizardContext();

  useEffect(() => {
    setTimeout(() => {
      searchBoxInstance.current?.focus();
    }, 100);
  }, []);

  const searchBoxInstance = useRef<ISearchBox | null>(null);

  const onProductsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    runInAction(() => {
      helper.productSearch = value;
      helper.productsLoading = value.length > 1;
    });
  };

  const onProductsSearch = (value: string) => {
    if (value) {
      drugs
        .searchProductNames(value)
        .then(results => {
          runInAction(() => {
            helper.productsLoading = false;
            helper.products = results;
          });
        })
        .catch(() => {});
    }
  };

  const onProductsClear = () => {
    runInAction(() => {
      helper.products = [];
      helper.productSearch = "";
      helper.productsLoading = false;
    });
  };

  return (
    <SearchBox
      autoFocus
      placeholder="Search product"
      value={helper.productSearch}
      onSearch={onProductsSearch}
      onChange={onProductsChange}
      onClear={onProductsClear}
      componentRef={searchBoxInstance}
    />
  );
});
