import { runInAction } from "mobx";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { MedicationsTestElements } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { PrescriptionItem } from "./PrescriptionsList.tsx";

interface PrescriptionListContextMenuProps {
  prescription: PrescriptionItem;
}

export const PrescriptionListContextMenu: React.FC<
  PrescriptionListContextMenuProps
> = ({ prescription }) => {
  const helper = useMedicationsContext();
  const { setMedicationDialogVisibleType } = helper;
  const { clinical } = useStores();

  const getSubMenuItems = () => {
    if (prescription.medications && prescription.medications.length > 1) {
      const subMenuItems: IContextualMenuItem[] = prescription.medications.map(
        x =>
          ({
            key: x.id,
            text: x.productName,
            onClick: () => {
              runInAction(() => {
                helper.setRePrescribeMed(x);
                setMedicationDialogVisibleType("prescribe");
              });
            }
          }) as IContextualMenuItem
      );
      return subMenuItems;
    }
    return [];
  };

  const items: IContextualMenuItem[] = [
    {
      key: "view",
      text: "View",
      onClick: () => {
        if (prescription) {
          helper.previewPrescription(prescription);
        }
      },
      disabled: !prescription.documentId
    }
  ];

  if (prescription.medications && prescription.medications.length > 1) {
    items.push({
      key: "represcripe",
      text: "Represcribe",
      subMenuProps: {
        items: getSubMenuItems()
      }
    });
  } else {
    items.push({
      key: "represcripe",
      text: "Represcribe",
      onClick: () => {
        if (prescription.medications) {
          const med = prescription?.medications[0];
          runInAction(() => {
            helper.setRePrescribeMed(med);
            setMedicationDialogVisibleType("prescribe");
          });
        }
      }
    });
  }

  if (prescription.prescriptionCreatedDate) {
    const { years } = DateTime.now().diff(
      prescription.prescriptionCreatedDate,
      ["years"]
    );

    // Hide if older than a year
    if (years < 1) {
      items.push({
        key: "reprint",
        text: "Reprint",
        onClick: () => {
          if (prescription) {
            helper.reprintScript(prescription);
          }
        }
      });
    }
  }

  if (prescription.createdEncounterId === clinical.activeRecordEncounterId) {
    items.push({
      key: "delete",
      text: "Delete",
      onClick: () => {
        if (prescription) {
          helper.deletePrescription(prescription);
        }
      }
    });
  }

  return (
    <IconButton
      {...dataAttribute(
        DataAttributes.Element,
        MedicationsTestElements.ContextualMenu
      )}
      menuIconProps={{
        iconName: "More"
      }}
      menuProps={{
        items
      }}
    />
  );
};
