import { Observer, observer } from "mobx-react-lite";

import {
  AddBox,
  CenteredBox,
  confirm,
  ContextualMenuItemType,
  FontIcon,
  FontSizes,
  FontWeights,
  Heading,
  IColumn,
  IconButton,
  IContextualMenuItem,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Stack,
  Text,
  TooltipHost,
  useDetailsListSorting,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { AppointmentReminderJobRunSummaryDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  AppointmentReminderJobStatus,
  CommsSectionViews
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { CommsScreenCommands } from "@modules/settings/screens/comms-schedules/components/CommsScreenCommands.tsx";
import { useCommsSchedulesScreenContext } from "@modules/settings/screens/comms-schedules/context/CommsScheduleScreenContext.tsx";
import { ScreenLayout } from "@modules/settings/screens/shared-components/ScreenLayout.tsx";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { AppointmentReminderJob } from "@stores/booking/models/AppointmentReminderJob.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { AppointmentReminderJobStatusText } from "@ui-components/RefText.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import {
  AppointmentReminderJobSorts,
  SortingStateKeys
} from "../context/CommsScheduleScreenHelper.ts";
import { CommsCreditsBalanceHeader } from "./CommsCreditsBalanceHeader.tsx";
import { SchedulesFilter } from "./SchedulesFilter.tsx";

enum ScheduleListLabel {
  Actions = "Actions",
  Name = "Name",
  Type = "Type",
  Frequency = "Frequency",
  Time = "Time",
  Status = "Status",
  NextRun = "Next run",
  LastRun = "Last run",
  LastRunResult = "Last result"
}

interface AppointmentReminderScheduleListItem {
  job: AppointmentReminderJob;
  runSummary: AppointmentReminderJobRunSummaryDto | undefined;
}

const TITLE = "Create a new schedule";
const nameOf = nameOfFactory<AppointmentReminderJobSorts>();
const SchedulesListBase: React.FC = observer(() => {
  const { booking, routing, notification, core } = useStores();
  const { setSort, getSortedColumns, descendingMap, currentSortKey } =
    useDetailsListSorting<AppointmentReminderJobSorts>(true);

  const onColumnClick: IColumn["onColumnClick"] = (_evt, col) => {
    setSort(col.key as SortingStateKeys);
  };

  const theme = useTheme();

  const { getFilteredSchedules, getSortedSchedules } =
    useCommsSchedulesScreenContext();

  const deleteSchedule = async (item: AppointmentReminderJob) => {
    if (
      await confirm({
        confirmButtonProps: {
          text: "Confirm"
        },
        cancelButtonProps: {
          text: "Cancel"
        },
        dialogContentProps: {
          title: "Delete Schedule",
          subText: `Are you sure you want to delete the ${item.jobName} schedule?`
        }
      })
    ) {
      try {
        await booking.deleteAppointmentReminderJob(item.id);
        notification.success(notificationMessages.commScheduleDeleted);
      } catch (e) {
        notification.error(e.message);
      }
    }
  };

  const changeScheduleStatus = async (
    item: AppointmentReminderJob,
    newStatus: string
  ) => {
    await booking.updateAppointmentReminderJob({
      ...item.dto,
      status: newStatus
    });
  };

  const runNow = async (
    item: AppointmentReminderJob,
    jobRunId: string | undefined = undefined
  ) => {
    if (
      await confirm({
        confirmButtonProps: {
          text: "Confirm"
        },
        cancelButtonProps: {
          text: "Cancel"
        },
        dialogContentProps: {
          title: "Run Schedule",
          subText: `Are you sure you want to run the ${item.jobName} schedule?`
        }
      })
    ) {
      try {
        await booking.runAppointmentReminderJob(item.id, jobRunId);
        notification.success(notificationMessages.commScheduleQueued);
      } catch (e) {
        notification.error(e.message);
      }
    }
  };

  const getItemsWithSummaries = (
    schedulesListResults: AppointmentReminderJob[],
    runSummaryResults: AppointmentReminderJobRunSummaryDto[] | undefined
  ): AppointmentReminderScheduleListItem[] => {
    return schedulesListResults.map(job => ({
      job,
      runSummary: runSummaryResults?.find(
        summary => summary.appointmentReminderJobId === job.id
      )
    }));
  };

  const renderActions = (item: AppointmentReminderScheduleListItem) => {
    const isPaused = item.job.status === AppointmentReminderJobStatus.Paused;
    const showRun = core.hasPermissions(Permission.PreRelease);
    const menuItems: IContextualMenuItem[] = [
      {
        key: item.job.status,
        text: isPaused ? "Schedule" : "Pause",
        onClick: () => {
          changeScheduleStatus(
            item.job,
            isPaused
              ? AppointmentReminderJobStatus.Scheduled
              : AppointmentReminderJobStatus.Paused
          );
        }
      },
      {
        key: "delete",
        text: "Delete",
        onClick: () => {
          deleteSchedule(item.job);
        }
      },
      {
        key: "edit",
        text: "Edit",
        onClick: () =>
          routing.push(
            routes.settings.communications.schedule.edit.path({
              id: item.job.id
            })
          )
      },
      ...(showRun
        ? [
            {
              key: "divider",
              itemType: ContextualMenuItemType.Divider
            },
            {
              key: "runActions",
              text: "Run",
              disabled: isPaused,
              items: [
                {
                  key: "runNow",
                  text: "Now",
                  onClick: () => {
                    runNow(item.job);
                  }
                },
                {
                  key: "retry",
                  text: "Retry last",
                  disabled: !item.runSummary,
                  onClick: () => {
                    runNow(item.job, item.runSummary?.id);
                  }
                }
              ]
            }
          ]
        : [])
    ].filter(i => i.key !== AppointmentReminderJobStatus.Expired);

    return (
      <TooltipHost content="More">
        <IconButton
          menuIconProps={{ iconName: "More" }}
          menuProps={{
            items: menuItems
          }}
          styles={{
            root: { height: "36px" },
            flexContainer: { height: "36px" }
          }}
        />
      </TooltipHost>
    );
  };

  const renderName = (item: AppointmentReminderScheduleListItem) => {
    return (
      <Navigate
        to={routes.settings.communications.schedule.edit.path({
          id: item.job.id
        })}
      >
        {item.job.jobName}
      </Navigate>
    );
  };

  const renderType = (item: AppointmentReminderScheduleListItem) => {
    return item.job.type;
  };

  const renderFrequency = (item: AppointmentReminderScheduleListItem) => {
    return item.job.jobSchedule;
  };

  const renderTime = (item: AppointmentReminderScheduleListItem) => {
    return item.job.time;
  };

  const renderStatus = (item: AppointmentReminderScheduleListItem) => {
    return (
      <Observer>
        {() => (
          <Text
            styles={{
              root: {
                color:
                  item.job.status === AppointmentReminderJobStatus.Scheduled
                    ? theme.palette.green
                    : theme.palette.red
              }
            }}
          >
            <AppointmentReminderJobStatusText code={item.job.status} />
          </Text>
        )}
      </Observer>
    );
  };

  const renderLastRun = (item: AppointmentReminderScheduleListItem) => {
    if (item.job.status !== AppointmentReminderJobStatus.Scheduled) return "";

    const updatedDate = DateTime.fromISO(
      item?.runSummary?.changeLog?.updatedDate
    )?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT);

    const createdDate = DateTime.fromISO(
      item?.runSummary?.changeLog?.createdDate
    )?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT);

    return updatedDate || createdDate || "--";
  };

  const renderNextRun = (item: AppointmentReminderScheduleListItem) => {
    if (item.job.status !== AppointmentReminderJobStatus.Scheduled) return "";

    const nextScheduledRunDate =
      item?.runSummary?.nextScheduledRun &&
      DateTime.fromISO(item?.runSummary?.nextScheduledRun).toFormat(
        DATE_FORMATS.LONG_DATE_TIME_FORMAT
      );

    return nextScheduledRunDate || "--";
  };

  const renderLastRunErrors = (item: AppointmentReminderScheduleListItem) => {
    if (item.job.status !== AppointmentReminderJobStatus.Scheduled) return "";

    if (!item?.runSummary?.errorMessages) return "Success";

    //const errorMessages = item?.runSummary?.errorMessages;
    const errorMessages = JSON.stringify(item?.runSummary?.errorMessages);

    //This is an indicative display of what messages could be.  A more componentised version is warranted as/when design evolves.
    return (
      <TooltipHost
        styles={{
          root: { alignContent: "center", verticalAlign: "center" }
        }}
        content={errorMessages}
      >
        <Stack horizontal horizontalAlign="center">
          <Stack
            horizontal
            styles={{
              root: {
                width: 102,
                justifyContent: "center",
                color: theme.palette.neutralSecondary,
                fontSize: FontSizes.size14,
                fontWeight: FontWeights.regular
              }
            }}
          >
            <FontIcon
              iconName="warning"
              styles={{
                root: {
                  marginRight: 8,
                  fontSize: FontSizes.size18
                }
              }}
            />
            Warning(s)
          </Stack>
        </Stack>
      </TooltipHost>
    );
  };

  const defaultColumns: IColumn[] = [
    {
      key: "actions",
      className: RESET_CELLS_PADDING_CLASSNAME,
      minWidth: 56,
      maxWidth: 56,
      name: ScheduleListLabel.Actions,
      onRender: (item: AppointmentReminderScheduleListItem) => (
        <Observer>{() => renderActions(item)}</Observer>
      )
    },
    {
      key: nameOf("jobName"),
      minWidth: 100,
      maxWidth: 310,
      name: ScheduleListLabel.Name,
      onColumnClick,
      onRender: renderName
    },
    {
      key: nameOf("type"),
      minWidth: 70,
      maxWidth: 224,
      name: ScheduleListLabel.Type,
      onColumnClick,
      onRender: renderType
    },
    {
      key: nameOf("jobSchedule"),
      minWidth: 130,
      maxWidth: 130,
      name: ScheduleListLabel.Frequency,
      onColumnClick,
      onRender: renderFrequency
    },
    {
      key: nameOf("time"),
      minWidth: 80,
      maxWidth: 80,
      name: ScheduleListLabel.Time,
      onColumnClick,
      onRender: renderTime
    },
    {
      key: nameOf("status"),
      minWidth: 80,
      maxWidth: 80,
      name: ScheduleListLabel.Status,
      onColumnClick,
      onRender: renderStatus
    },
    {
      key: "lastRun",
      minWidth: 150,
      maxWidth: 150,
      name: ScheduleListLabel.LastRun,
      onColumnClick,
      onRender: renderLastRun
    },
    {
      key: "lastRunResult",
      minWidth: 80,
      maxWidth: 80,
      name: ScheduleListLabel.LastRunResult,
      onColumnClick,
      onRender: renderLastRunErrors
    },
    {
      key: "nextRun",
      minWidth: 150,
      maxWidth: 150,
      name: ScheduleListLabel.NextRun,
      onColumnClick,
      onRender: renderNextRun
    }
  ];

  const handleClick = () => {
    routing.push(routes.settings.communications.schedule.new.pattern);
  };

  return (
    <DataFetcher
      fetch={() =>
        Promise.all([
          booking.getAppointmentReminderJobs(),
          booking.getAllAppointmentReminderJobRunSummaries()
        ])
      }
      refetchId={booking.recentlyDeletedScheduleListItemId}
    >
      {([schedulesListResults, runSummaryResults]) => {
        return (
          <ScreenLayout
            tileStyles={{
              root: { height: "100%" },
              content: {
                height: "inherit",
                display: "flex",
                flexDirection: "column"
              }
            }}
            onRenderHeader={() => (
              <Stack
                horizontal
                horizontalAlign="space-between"
                grow
                styles={{ root: { marginBottom: 16 } }}
              >
                <Heading variant="section-heading">
                  {SettingsLabels.communicationsSchedules}
                </Heading>
                <CommsCreditsBalanceHeader />
                <CommsScreenCommands selectedKey={CommsSectionViews.schedule} />
              </Stack>
            )}
          >
            <SchedulesFilter types={schedulesListResults.map(i => i.type)}>
              {state => {
                const filteredItems = getFilteredSchedules(
                  schedulesListResults,
                  state.values
                );

                const sortedItems = getSortedSchedules({
                  items: filteredItems,
                  isDescending: currentSortKey.current
                    ? descendingMap.get(currentSortKey.current)
                    : undefined,
                  sortingState: currentSortKey.current
                });

                const itemsWithSummaries = getItemsWithSummaries(
                  sortedItems,
                  runSummaryResults
                );

                return (
                  <Stack
                    grow
                    styles={{
                      root: {
                        position: "relative",
                        height: "100%",
                        overflow: "hidden"
                      }
                    }}
                  >
                    <ScrollablePane>
                      <ShimmeredDetailsList
                        stickyHeader
                        items={itemsWithSummaries}
                        columns={getSortedColumns(defaultColumns, ["actions"])}
                      />
                      {schedulesListResults &&
                        schedulesListResults.length === 0 && (
                          <CenteredBox>
                            <AddBox
                              onClick={handleClick}
                              subText="No new schedule created"
                              buttonText={TITLE}
                              text=""
                            />
                          </CenteredBox>
                        )}
                    </ScrollablePane>
                  </Stack>
                );
              }}
            </SchedulesFilter>
          </ScreenLayout>
        );
      }}
    </DataFetcher>
  );
});

export const SchedulesList = withFetch(
  x => [x.booking.ref.appointmentReminderJobStatuses.load()],
  SchedulesListBase
);
