import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";
import { useFormState } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";

import { DraftInvoiceGenerateContext } from "./context/DraftInvoiceGenerateContext.ts";
import { DraftDropdown } from "./DraftDropDown.tsx";
import { DraftInvoiceGenerateFormValues } from "./DraftInvoiceGenerateModal.type.ts";

export const DraftInvoiceGenerateModalFields: FunctionComponent = observer(
  () => {
    const { nameOf, getItems, optionsForPatients, optionsForProvider } =
      useContext(DraftInvoiceGenerateContext);

    const { values } = useFormState<DraftInvoiceGenerateFormValues>();

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        <ContactPickerField
          name={nameOf("accountId")}
          required={true}
          placeholder="Search bill to"
          iconName="search"
          label="Generate for"
        />
        <FieldSpy
          name={nameOf("accountId")}
          onChange={value => {
            getItems(value, values.cutOffDate);
          }}
        />
        <DraftDropdown
          options={optionsForProvider}
          fieldName={nameOf("selectedProvider")}
          label="Providers"
        />
        <DraftDropdown
          options={optionsForPatients}
          fieldName={nameOf("selectedPatient")}
          label="Patients"
        />
        <DatePickerField
          name={nameOf("cutOffDate")}
          label="Cut-off date"
          required={true}
        />
        <FieldSpy
          name={nameOf("cutOffDate")}
          onChange={(value: Date) => {
            getItems(values.accountId, value);
          }}
        />
      </Stack>
    );
  }
);
