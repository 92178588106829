import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { SideRailMenuItem } from "@bps/fluent-ui";
import { ALL_DAYS_OF_WEEK } from "@bps/utils";
import { AddAppointmentReminderJobDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentReminderJobStatus } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { CommsScheduleContext } from "../context/CommsScheduleContext.ts";
import { CommsScheduleEditFormFields } from "./CommsScheduleEditFormFields.tsx";
import { formStyles } from "./CommsScheduleForm.styles.ts";
import {
  CommsScheduleFormValues,
  DEFAULT_SEND_BEFORE_DAYS
} from "./CommsScheduleForm.types.ts";
import {
  CommsScheduleCardIds,
  CommsScheduleFormSideRailLabels
} from "./CommsScheduleFormEnums.ts";
import { CommsScheduleFormHeader } from "./CommsScheduleFormHeader.tsx";
import { CommsScheduleFormValidator } from "./CommsScheduleFormValidator.ts";

const validator = new CommsScheduleFormValidator();

interface CommsScheduleFormProps {
  appointmentTypes: AppointmentType[];
  templates: OutboundCommTemplate[];
  initialValues: CommsScheduleFormValues;
}

export const CommsScheduleForm: React.FC<CommsScheduleFormProps> = observer(
  (props: CommsScheduleFormProps) => {
    const { core, notification } = useStores();
    const { goBackToBasePath, addEditSchedule, transformToDaysOfWeek } =
      useContext(CommsScheduleContext);

    const menuItems: SideRailMenuItem[] = [
      {
        text: CommsScheduleFormSideRailLabels.appointmentReminder,
        id: CommsScheduleCardIds.appointmentReminder
      },
      {
        text: CommsScheduleFormSideRailLabels.whenToNotify,
        id: CommsScheduleCardIds.whenToNotify
      },
      {
        text: CommsScheduleFormSideRailLabels.typeOfAppointments,
        id: CommsScheduleCardIds.typeOfAppointments
      },
      {
        text: CommsScheduleFormSideRailLabels.messageContent,
        id: CommsScheduleCardIds.messageContent
      }
    ];

    const header = <CommsScheduleFormHeader />;

    const handleSubmit = async (values: CommsScheduleFormValues) => {
      await addEditSchedule(getUpdatedScheduleJobValues(values));
    };

    const getUpdatedScheduleJobValues = (
      values: CommsScheduleFormValues
    ): AddAppointmentReminderJobDto => {
      const timeVals = values.sendTime!.split(":");
      const hours = parseInt(timeVals[0]);
      const mins = parseInt(timeVals[1]);

      const advancedValuesDayOfWeek = values.daysOfWeek
        ? transformToDaysOfWeek(values.daysOfWeek)
        : [];

      const valueDaysOfWeek = values.everyday
        ? ALL_DAYS_OF_WEEK
        : advancedValuesDayOfWeek;

      return {
        jobName: values.jobName ?? "",
        appointmentReminderOptions: {
          appointmentTypes: values.appointmentTypes ?? [],
          sendBefore: values.sendBefore ?? DEFAULT_SEND_BEFORE_DAYS,
          templateId: values.templateId ?? ""
        },
        jobSchedule: {
          daysOfWeek: valueDaysOfWeek,
          hour: hours,
          minute: mins
        },
        orgUnitId: core.locationId,
        status: AppointmentReminderJobStatus.Scheduled
      };
    };

    const onSubmitSucceeded = () => {
      notification.success("Schedule Job has been successfully saved");
      goBackToBasePath();
    };

    return (
      <SubmissionForm<CommsScheduleFormValues>
        formName="comms-schedule"
        onSubmitSucceeded={onSubmitSucceeded}
        onSubmit={handleSubmit}
        hideButtons
        validate={validator.validate}
        styles={formStyles}
        initialValues={props.initialValues}
      >
        {() => (
          <CardFormLayout sideRailMenuItems={menuItems} header={header}>
            <CommsScheduleEditFormFields {...props} />
          </CardFormLayout>
        )}
      </SubmissionForm>
    );
  }
);
