import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

export interface MedicalHistoryFilterType {
  name?: string;
  status: string;
  type: string;
  clinicallySignificant?: boolean;
  confidential?: boolean;
}

export const defaultFilters: MedicalHistoryFilterType = {
  name: "",
  status: "active",
  type: "",
  clinicallySignificant: false,
  confidential: false
};

export interface MedicalHistoryFilterProps
  extends Pick<FilterBarProps<MedicalHistoryFilterType>, "children"> {
  isExistConfidentialHx: boolean;
}
