import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";

import { InvestigationFormValues } from "./AddInvestigationDialog.types.ts";

export class AddInvestigationDialogValidator extends Validator<InvestigationFormValues> {
  constructor(editInvestigation?: ClinicalDocument) {
    super();

    this.forField("subject", required());
    this.forField("reportDate", required());

    this.forField(
      "reportText",
      predicate((reportText, values) => {
        const hasFilesOrDocument =
          (values?.files?.length ?? 0) > 0 || !!values?.inboxDocument;

        const isInvalidCreation = !editInvestigation && hasFilesOrDocument;
        const isInvalidEditWithText =
          editInvestigation && values?.isReportTextDocument && reportText;

        const isInvalidEditWithoutText =
          editInvestigation && !values?.isReportTextDocument;

        return !(
          isInvalidCreation ||
          isInvalidEditWithText ||
          isInvalidEditWithoutText
        );
      }, required())
    );
  }
}
