import { observer } from "mobx-react-lite";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useField } from "react-final-form";

import {
  ButtonsGroupOption,
  FontSizes,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  ProviderOnlineStatus,
  ProviderOnlineStatusText
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import {
  BusinessRoleClasses,
  Permission,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { useScrollToViewById } from "@ui-components/hooks/useScrollToViewById.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UserScreenCardKeys } from "../../UserScreen.types.ts";
import { UserOnlineBookingProfileFormValues } from "./UserOnlineBookingProfileForm.types.ts";
import { UserOnlineBookingProfileFormContext } from "./UserOnlineBookingProfileFormContext.ts";

type UserOnlineDisplayFieldProps = {
  user: User;
  bhbProvider?: BhbProvider;
  userAvailability?: UserAvailabilityModel;
};

export const UserOnlineDisplayField: FunctionComponent<UserOnlineDisplayFieldProps> =
  observer(({ user, bhbProvider, userAvailability }) => {
    const { core } = useStores();

    const { saveUserSetting } = useContext(UserOnlineBookingProfileFormContext);

    const nameOf = nameOfFactory<UserOnlineBookingProfileFormValues>();

    const scroll = useScrollToViewById();
    const showOnCalendarDisabled = !(
      (user.userSetting?.showOnCalendar ?? false) &&
      user.status === UserStatus.Active
    );

    const [displayMessageBar, setDisplayMessageBar] = useState(
      showOnCalendarDisabled &&
        (bhbProvider?.providerOnlineStatus === ProviderOnlineStatus.NO ||
          bhbProvider?.providerOnlineStatus === ProviderOnlineStatus.CALL)
    );

    const {
      input: { value: onlineStatusFieldValue }
    } = useField(nameOf("providerOnlineStatusCode"), {
      subscription: { value: true }
    });

    useEffect(() => {
      setDisplayMessageBar(
        !(user.userSetting?.showOnCalendar ?? false) &&
          (onlineStatusFieldValue === ProviderOnlineStatus.NO ||
            onlineStatusFieldValue === ProviderOnlineStatus.CALL)
      );
    }, [user.userSetting?.showOnCalendar, onlineStatusFieldValue]);

    const onlineBookingStatusOptions: ButtonsGroupOption<string>[] = [
      {
        key: ProviderOnlineStatus.YES,
        text: ProviderOnlineStatusText.ShowAndBook,
        disabled: showOnCalendarDisabled
      },
      {
        key: ProviderOnlineStatus.CALL,
        text: ProviderOnlineStatusText.ShowAndCall
      },
      {
        key: ProviderOnlineStatus.NO,
        text: ProviderOnlineStatusText.DontShow
      }
    ];

    return (
      <When permission={[Permission.BhbConfigAllowed, Permission.BhbRead]}>
        <Stack tokens={{ childrenGap: 8 }}>
          {user.businessRoleClasses.some(
            x => x === BusinessRoleClasses.Provider
          ) && (
            <Stack tokens={{ childrenGap: 8 }}>
              <ButtonsGroupSingleChoiceField
                disabled={!core.hasPermissions(Permission.BhbWrite)}
                label="Online booking & visibility preferences"
                name={nameOf("providerOnlineStatusCode")}
                options={onlineBookingStatusOptions}
              />
              {displayMessageBar && (
                <MessageBar
                  onDismiss={() => {
                    setDisplayMessageBar(false);
                  }}
                  messageBarType={MessageBarType.warning}
                  styles={{ root: { width: 330 } }}
                >
                  <Stack tokens={{ childrenGap: 4 }}>
                    <Text
                      styles={{
                        root: { fontSize: FontSizes.size12 }
                      }}
                    >
                      Online profile display won't take effect until appointment
                      book display is activated
                    </Text>
                    <Link
                      onClick={() => {
                        if (
                          userAvailability?.schedules &&
                          userAvailability?.schedules?.length > 0
                        ) {
                          user.userSetting && saveUserSetting(user.userSetting);
                        } else {
                          scroll(UserScreenCardKeys.AppointmentSettings);
                        }
                      }}
                    >
                      Show user on appointment book
                    </Link>
                  </Stack>
                </MessageBar>
              )}
            </Stack>
          )}
        </Stack>
      </When>
    );
  });
