import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import { DefaultButton, Spinner, Stack } from "@bps/fluent-ui";
import {
  AccScheduleTransactionStatuses,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ResubmitInvoiceDialog } from "./ResubmitInvoiceDialog.tsx";
import { SelectedInvoicesMessageBar } from "./SelectedInvoicesMessageBar.tsx";

const SelectedInvoicesGroupBase: React.FC = observer(() => {
  const { routing } = useStores();

  const {
    defaultNzPublicInsurerId,
    selection,
    selectedInvoices,
    disableBulkSubmit,
    bulkSubmitHandler
  } = useContext(AccInvoicesListContext);

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [showResubmitConfirmDialog, setShowResubmitConfirmDialog] =
    useState<boolean>(false);

  const onClickSubmit = () => {
    setShowConfirmDialog(true);
  };

  const onClickConfirm = async () => {
    await bulkSubmitHandler();
    setShowConfirmDialog(false);
    selection.setAllSelected(false);
  };

  const onCancelConfirm = () => {
    setShowConfirmDialog(false);
  };

  const onNewPaymentClick = () => {
    routing.push(
      {
        pathname: routes.accounts.allocations.new.path({}),
        search: `?accountId=${defaultNzPublicInsurerId}`
      },
      {
        ...routing.getStateWithFromQuery(),
        invoiceIds: selectedInvoices.map(invoice => invoice.id).join(",")
      }
    );
  };

  const isResubmitDisplayed =
    selectedInvoices.length &&
    selectedInvoices.length === 1 &&
    selectedInvoices.every(x => x.hasACCComment) &&
    selectedInvoices.every(x => x.paymentStatus === PaymentStatuses.unpaid) &&
    selectedInvoices.every(
      x =>
        x.accSchedule?.transactionStatus ===
          AccScheduleTransactionStatuses.AwaitingAuthorisation ||
        x.accSchedule?.transactionStatus ===
          AccScheduleTransactionStatuses.PartiallyPaid
    );

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
      <SelectedInvoicesMessageBar />
      <Stack.Item>
        <DefaultButton
          disabled={!selectedInvoices.length}
          onClick={onNewPaymentClick}
        >
          New payment
        </DefaultButton>
      </Stack.Item>
      {isResubmitDisplayed ? (
        <DefaultButton onClick={() => setShowResubmitConfirmDialog(true)}>
          Resubmit
        </DefaultButton>
      ) : (
        <Stack.Item>
          <DefaultButton disabled={disableBulkSubmit} onClick={onClickSubmit}>
            Submit
          </DefaultButton>
          {showConfirmDialog && (
            <SubmissionFormDialog
              dialogName="Selected Invoices"
              onSubmit={onClickConfirm}
              dialogProps={{
                onDismiss: onCancelConfirm,
                dialogContentProps: { title: "Submit?" }
              }}
              buttonsProps={{
                submitButtonProps: {
                  text: "Submit",
                  iconProps: { hidden: true }
                },
                disableSubmitOnPristine: false
              }}
            >
              {() => (
                <>
                  <Stack.Item>
                    The following invoice(s) will be sent to ACC:
                  </Stack.Item>
                  <Stack
                    styles={{ root: { maxHeight: 140, overflowY: "auto" } }}
                  >
                    {selectedInvoices.map(invoice => (
                      <Stack.Item key={invoice.id}>{invoice.number}</Stack.Item>
                    ))}
                  </Stack>
                </>
              )}
            </SubmissionFormDialog>
          )}
        </Stack.Item>
      )}
      {showResubmitConfirmDialog && (
        <ResubmitInvoiceDialog
          selectedInvoice={selectedInvoices[0]}
          onDismiss={() => setShowResubmitConfirmDialog(false)}
          showResubmitInvoiceDialog={showResubmitConfirmDialog}
        />
      )}
    </Stack>
  );
});

export const SelectedInvoicesGroup = withFetch(
  x => [x.billing.getInvoiceSettings()],
  SelectedInvoicesGroupBase,
  { fallback: <Spinner /> }
);
