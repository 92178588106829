import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { SideRailMenuItem } from "@bps/fluent-ui";
import { AddAppointmentConfirmationCampaignDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { formStyles } from "../../../comms-schedules/components/CommsScheduleForm.styles.ts";
import {
  CommsScheduleCardIds,
  CommsScheduleFormSideRailLabels
} from "../../../comms-schedules/components/CommsScheduleFormEnums.ts";
import { CommsScheduleFormHeader } from "../../../comms-schedules/components/CommsScheduleFormHeader.tsx";
import { CommsScheduleContext } from "../../../comms-schedules/context/CommsScheduleContext.ts";
import { CommsConfirmationCampaignEditFormFields } from "./CommsConfirmationCampaignFormFields.tsx";
import { CommsConfirmationCampaignFormValidator } from "./CommsConfirmationCampaignFormValidator.ts";
import { CommsConfirmationCampaignFormValues } from "./CommsConfirmationCampaignFormValues.ts";

interface CommsConfirmationCampaignProps {
  templates: OutboundCommTemplate[];
  initialValues: CommsConfirmationCampaignFormValues | undefined;
}
const validator = new CommsConfirmationCampaignFormValidator();

export const CommsConfirmationCampaignForm: React.FC<CommsConfirmationCampaignProps> =
  observer((props: CommsConfirmationCampaignProps) => {
    const { notification } = useStores();
    const { goBackToBasePath, addEditConfirmationCampaign } =
      useContext(CommsScheduleContext);

    const menuItems: SideRailMenuItem[] = [
      {
        text: CommsScheduleFormSideRailLabels.apptConfirmationReminders,
        id: CommsScheduleCardIds.apptConfirmationReminders
      },
      {
        text: CommsScheduleFormSideRailLabels.messageContent,
        id: CommsScheduleCardIds.messageContent
      }
    ];

    const header = <CommsScheduleFormHeader />;

    const handleSubmit = async (
      values: CommsConfirmationCampaignFormValues
    ) => {
      addEditConfirmationCampaign(getUpdatedScheduleJobValues(values));
    };

    const getUpdatedScheduleJobValues = (
      values: CommsConfirmationCampaignFormValues
    ): AddAppointmentConfirmationCampaignDto => {
      return {
        name: values.name,
        newAppointmentCampaignId: values.newAppointmentCampaignId,
        rescheduleAppointmentCampaignId: values.rescheduleAppointmentCampaignId,
        cancelAppointmentCampaignId: values.cancelAppointmentCampaignId
      };
    };

    const onSubmitSucceeded = () => {
      notification.success(
        "Confirmation Campaign details has been successfully saved"
      );
      goBackToBasePath();
    };

    return (
      <SubmissionForm<CommsConfirmationCampaignFormValues>
        formName="comms-confirmation-campaign"
        onSubmitSucceeded={onSubmitSucceeded}
        onSubmit={handleSubmit}
        validate={validator.validate}
        hideButtons
        styles={formStyles}
        initialValues={props.initialValues}
      >
        {() => (
          <CardFormLayout sideRailMenuItems={menuItems} header={header}>
            <CommsConfirmationCampaignEditFormFields {...props} />
          </CardFormLayout>
        )}
      </SubmissionForm>
    );
  });
