import { DateTime, isDefined } from "@bps/utils";
import {
  ClaimAdjustmentRequests,
  ClaimDiagnosisChangeDto,
  ClaimDiagnosisDto,
  ClaimSideChangeDto,
  TreatmentRequestDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  getEmptyDiagnosis,
  isDiagnosisChangeNotEmpty,
  isDiagnosisNotEmpty
} from "@modules/acc/screens/utils.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";

import {
  getEmptyDiagnosisAdditionRequestDto,
  getEmptyDiagnosisChangeRequestDto,
  getEmptySideChangeRequestDto
} from "../utils.ts";

export const getTreatmentsHeading = (
  treatmentStartDate: DateTime | undefined
) => {
  return treatmentStartDate
    ? `Additional treatments (from  ${treatmentStartDate.toDayDefaultFormat()}`
    : "Additional treatments";
};

export const getOldDiagnosis = (claimDiagnosis: ClaimDiagnosisDto) =>
  `${claimDiagnosis?.terminology?.code}${claimDiagnosis?.diagnosisSide}`;

export const toDiagnosisChangesDto = (
  diagnosisChanges: ClaimDiagnosisChangeDto,
  currentDiagnoses: ClaimDiagnosisDto[]
): ClaimDiagnosisChangeDto => {
  const oldDiagnosis = currentDiagnoses.find(
    x => getOldDiagnosis(x) === getOldDiagnosis(diagnosisChanges.oldDiagnosis)
  );
  return {
    oldDiagnosis: oldDiagnosis || getEmptyDiagnosis(),
    newDiagnosis: diagnosisChanges.newDiagnosis,
    requestApproved: diagnosisChanges.requestApproved
  };
};

export const toSideChangesDto = (
  sideChanges: ClaimSideChangeDto,
  currentDiagnoses: ClaimDiagnosisDto[]
): ClaimSideChangeDto => {
  const oldDiagnosis = currentDiagnoses.find(
    x => getOldDiagnosis(x) === getOldDiagnosis(sideChanges.oldDiagnosis)
  );

  return {
    oldDiagnosis: oldDiagnosis || getEmptyDiagnosis(),
    newSide: sideChanges.newSide,
    requestApproved: sideChanges.requestApproved
  };
};

export const toClaimAdjustmentRequestsDto = (args: {
  additions: ClaimDiagnosisFormValues[];
  diagnosisChanges: ClaimDiagnosisChangeDto[];
  sideChanges: ClaimSideChangeDto[];
  treatmentRequest: TreatmentRequestDto;
  currentDiagnoses: ClaimDiagnosisDto[];
}): ClaimAdjustmentRequests[] => {
  const additionRequest = getEmptyDiagnosisAdditionRequestDto();
  additionRequest.diagnosisAdditions =
    args.additions.filter(isDiagnosisNotEmpty);

  additionRequest.requestApproved = additionRequest.diagnosisAdditions.every(
    diagnosisAddition => diagnosisAddition.requestApproved
  );

  const diagnosisChangeRequest = getEmptyDiagnosisChangeRequestDto();
  diagnosisChangeRequest.diagnosisChanges =
    args.diagnosisChanges
      ?.map(x => toDiagnosisChangesDto(x, args.currentDiagnoses))
      ?.filter(isDiagnosisChangeNotEmpty) ?? [];

  diagnosisChangeRequest.requestApproved =
    diagnosisChangeRequest.diagnosisChanges.every(
      diagnosisChange => diagnosisChange.requestApproved
    );

  const sideChangeRequest = getEmptySideChangeRequestDto();
  sideChangeRequest.sideChanges = args.sideChanges
    .map(x => toSideChangesDto(x, args.currentDiagnoses))
    .filter(
      sideChange =>
        isDiagnosisNotEmpty(sideChange.oldDiagnosis) || sideChange.newSide
    );

  sideChangeRequest.requestApproved = sideChangeRequest.sideChanges.every(
    sideChange => sideChange.requestApproved
  );

  const requests: ClaimAdjustmentRequests[] = [];
  if (
    additionRequest.diagnosisAdditions.length > 0 ||
    args.additions.length === 1
  )
    requests.push(additionRequest);

  if (
    diagnosisChangeRequest.diagnosisChanges.length > 0 ||
    args.diagnosisChanges.length === 1
  )
    requests.push(diagnosisChangeRequest);

  if (sideChangeRequest.sideChanges.length > 0 || args.sideChanges.length === 1)
    requests.push(sideChangeRequest);

  if (
    [
      args.treatmentRequest.firstAdditionalTreatmentRequested,
      args.treatmentRequest.followUpTreatmentsNeeded,
      args.treatmentRequest.handSplintingCost,
      args.treatmentRequest.initialAssessmentsNeeded,
      args.treatmentRequest.treatmentStartDate,
      args.treatmentRequest.treatmentsApproved,
      args.treatmentRequest.treatmentsRequested
    ].filter(isDefined).length > 0
  )
    requests.push(args.treatmentRequest);

  return requests;
};

export const toClaimAdjustmentRequestsDeclinedDto = (args: {
  additions: ClaimDiagnosisFormValues[];
  diagnosisChanges: ClaimDiagnosisChangeDto[];
  sideChanges: ClaimSideChangeDto[];
  treatmentRequest: TreatmentRequestDto;
  currentDiagnoses: ClaimDiagnosisDto[];
}): ClaimAdjustmentRequests[] => {
  const requests: ClaimAdjustmentRequests[] = [];
  if (args.additions.length > 0) {
    const additionRequest = getEmptyDiagnosisAdditionRequestDto();
    additionRequest.diagnosisAdditions =
      args.additions.filter(isDiagnosisNotEmpty);
    additionRequest.diagnosisAdditions.forEach(x => {
      x.requestApproved = false;
    });
    additionRequest.requestApproved = false;
    requests.push(additionRequest);
  }

  if (args.diagnosisChanges.length > 0) {
    const diagnosisChangeRequest = getEmptyDiagnosisChangeRequestDto();
    diagnosisChangeRequest.diagnosisChanges =
      args.diagnosisChanges
        ?.map(x => toDiagnosisChangesDto(x, args.currentDiagnoses))
        ?.filter(isDiagnosisChangeNotEmpty) ?? [];
    diagnosisChangeRequest.diagnosisChanges.forEach(x => {
      x.requestApproved = false;
    });
    diagnosisChangeRequest.requestApproved = false;
    requests.push(diagnosisChangeRequest);
  }

  if (args.sideChanges.length > 0) {
    const sideChangeRequest = getEmptySideChangeRequestDto();
    sideChangeRequest.sideChanges = args.sideChanges
      .map(x => toSideChangesDto(x, args.currentDiagnoses))
      .filter(
        sideChange =>
          isDiagnosisNotEmpty(sideChange.oldDiagnosis) || sideChange.newSide
      );
    sideChangeRequest.sideChanges.forEach(x => {
      x.requestApproved = false;
    });
    sideChangeRequest.requestApproved = false;
    requests.push(sideChangeRequest);
  }

  if (
    [
      args.treatmentRequest.firstAdditionalTreatmentRequested,
      args.treatmentRequest.followUpTreatmentsNeeded,
      args.treatmentRequest.handSplintingCost,
      args.treatmentRequest.initialAssessmentsNeeded,
      args.treatmentRequest.treatmentStartDate,
      args.treatmentRequest.treatmentsApproved,
      args.treatmentRequest.treatmentsRequested
    ].filter(isDefined).length > 0
  ) {
    const treatmentRequest: TreatmentRequestDto = {
      ...args.treatmentRequest,
      treatmentsApproved: undefined,
      handSplintingCostApproved: undefined,
      initialAssessmentsApproved: undefined
    };
    requests.push(treatmentRequest);
  }
  return requests;
};
