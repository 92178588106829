import { Shimmer, ShimmerElementType } from "@bps/fluent-ui";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

export const WorkingHoursFormFallback = (
  <Fieldset styles={{ root: { padding: 24, minHeight: 383 } }}>
    <Shimmer width={50} />
    <Shimmer styles={{ shimmerWrapper: { height: 30 } }} width="25%" />
    <Shimmer styles={{ shimmerWrapper: { height: 30 } }} width="90%" />
    <Shimmer
      shimmerElements={[
        { type: ShimmerElementType.circle },
        { type: ShimmerElementType.gap },
        { type: ShimmerElementType.line }
      ]}
      width="50%"
    />
    <Shimmer
      shimmerElements={[
        { type: ShimmerElementType.circle },
        { type: ShimmerElementType.gap },
        { type: ShimmerElementType.line }
      ]}
      width="25%"
    />
    <Shimmer width={50} />
    <Shimmer styles={{ shimmerWrapper: { height: 60 } }} width="100%" />
  </Fieldset>
);
