import { useContext, useState } from "react";

import {
  DefaultButton,
  FontIcon,
  FontSizes,
  Link,
  mergeStyles,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { EncounterStatus } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { convertClinicalSideToDiagnosisSide } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/active-condition-list/active-condition-list.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ClaimReviewStatus } from "../claim-review/ClaimReviewEnums.ts";
import { ClaimAppointmentContext } from "./ClaimAppointmentContext.tsx";
import { getConditionsButtonStyles } from "./ConditionsSidePanel.styles.ts";
import { ConditionDetailsModel } from "./ConditionsSidePanelHelper.ts";
import { ConsultsRemainingBadges } from "./ConsultsRemainingBadges.tsx";
import { LinkedConditionText } from "./LinkedConditionText.tsx";

export interface ConditionListItemProps {
  model: ConditionDetailsModel;
  onSelectedCondition(condition: ConditionDetailsModel): void;
  isViewOnly: boolean;
}

const ConditionListItemBase: React.FC<ConditionListItemProps> = ({
  model,
  onSelectedCondition,
  isViewOnly
}) => {
  const { acc, core } = useStores();
  const theme = useTheme();
  const {
    hasLinkedCondition,
    loadConditionEncounters,
    isAlliedHealthService,
    isLinkedCondition,
    linkConditionToEncounter
  } = useContext(ClaimAppointmentContext);

  const { setClaimReviewTab, clinicalRecord } = usePatientRecordScreenContext();

  const styles = getConditionsButtonStyles(theme);

  const [isLinked, setIsLinked] = useState<boolean>(
    isLinkedCondition(model.condition?.episodeOfCareId)
  );

  const handleLinkCondition = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!model.condition?.episodeOfCareId) return;

    await linkConditionToEncounter(model.condition?.episodeOfCareId);
    setIsLinked(true);
  };

  return (
    <DataFetcher
      fetch={() => loadConditionEncounters(model.condition?.episodeOfCareId)}
      fallback={<Spinner />}
    >
      {(encounters: Encounter[]) => {
        const linkedToAnotherOpenConsult = encounters.some(
          x =>
            x.id !== clinicalRecord.openEncounter?.id &&
            x.userId === core.user?.id &&
            x.status !== EncounterStatus.Closed
        );

        return (
          <DefaultButton
            onClick={() => onSelectedCondition(model)}
            styles={styles.defaultButton}
          >
            <Stack grow>
              {isLinked ? (
                <LinkedConditionText />
              ) : (
                <Stack
                  horizontalAlign="start"
                  styles={{
                    root: {
                      padding: !hasLinkedCondition
                        ? "12px 16px 4px"
                        : "12px 16px 0px"
                    }
                  }}
                >
                  {!hasLinkedCondition && !isViewOnly && (
                    <TooltipHost
                      content={
                        linkedToAnotherOpenConsult
                          ? "You already have another open consult linked to this condition"
                          : undefined
                      }
                    >
                      <Link
                        as="a" // To avoid nest button from DefaultButton component
                        styles={{
                          root: {
                            fontSize: FontSizes.size12
                          }
                        }}
                        disabled={linkedToAnotherOpenConsult}
                        onClick={handleLinkCondition}
                      >
                        Link to consult
                      </Link>
                    </TooltipHost>
                  )}
                </Stack>
              )}
              <Stack styles={{ root: { padding: "0px 16px 12px" } }}>
                <Stack horizontal horizontalAlign="space-between">
                  <Stack>
                    <div className={mergeStyles(styles.diagnosis)}>
                      <Stack horizontal tokens={{ childrenGap: 8 }}>
                        {model.referralIn && (
                          <TooltipHost content="Referral">
                            <FontIcon
                              iconName="FollowUser"
                              styles={{
                                root: {
                                  color: theme.palette.black,
                                  fontSize: FontSizes.size16
                                }
                              }}
                            />
                          </TooltipHost>
                        )}
                        <Text>
                          {model.diagnosis}
                          {convertClinicalSideToDiagnosisSide(model.side)}
                        </Text>
                      </Stack>
                    </div>
                    <Stack horizontal>
                      <Text styles={styles.subText}>
                        {model.condition?.initialConsultDate &&
                          `Initial: ${model.initialConsultDate}`}
                      </Text>

                      <Text styles={styles.subText}>
                        {model.condition?.injuryDate &&
                          `Onset: ${model.injuryDate}`}
                      </Text>

                      <Text styles={styles.subText}>
                        {model.condition?.claim
                          ? model.condition?.claim?.claimNumber ?? "Not lodged"
                          : model.condition?.referralNumber}
                      </Text>
                    </Stack>
                    <When permission={Permission.ClaimRead}>
                      <Stack
                        horizontal
                        tokens={{ childrenGap: 8 }}
                        wrap
                        styles={{ root: { marginTop: 4 } }}
                      >
                        {model.condition?.claim &&
                          acc.ui.shouldDisplayClaimReviewAlert(
                            encounters.length
                          ) &&
                          isAlliedHealthService(model.condition?.claim) &&
                          model.condition?.claim.claimReview?.statusCode !==
                            ClaimReviewStatus.completed && (
                            <TextBadge
                              badgeColor={TextBadgeColor.yellow}
                              styles={styles.visitsRemaining}
                              badgeSize={TextBadgeSize.medium}
                              asLink
                              onClick={() =>
                                setClaimReviewTab(model.condition?.claim)
                              }
                            >
                              Claim review
                            </TextBadge>
                          )}

                        {!model.condition?.claim && (
                          <TextBadge
                            badgeColor={TextBadgeColor.lightGrey}
                            styles={styles.visitsRemaining}
                            badgeSize={TextBadgeSize.medium}
                          >
                            Private
                          </TextBadge>
                        )}
                        {model.appointmentVisits && (
                          <ConsultsRemainingBadges
                            appointmentVisits={model.appointmentVisits}
                          />
                        )}
                      </Stack>
                    </When>
                  </Stack>
                  <Stack horizontalAlign="center" verticalAlign="center">
                    <FontIcon
                      iconName="ChevronRight"
                      styles={{
                        root: {
                          color: theme.palette.themePrimary
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </DefaultButton>
        );
      }}
    </DataFetcher>
  );
};

export const ConditionListItem = withFetch(
  root => [
    root.practice.ref.accProviderTypes.load(),
    root.practice.ref.accProviderContractTypes.load()
  ],
  ConditionListItemBase
);
