import { observer } from "mobx-react-lite";

import { PatientDemographicContext } from "@modules/practice/screens/contact-details/context/PatientDemographicContext.ts";
import { PatientDemographicHelper } from "@modules/practice/screens/contact-details/context/PatientDemographicHelper.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ContactDialogsSwitch } from "./shared-components/edit/ContactDialogsSwitch.tsx";

const PatientDemographicDialogBase: React.FunctionComponent = () => {
  return (
    <PatientDemographicContext.Provider value={new PatientDemographicHelper()}>
      <ContactDialogsSwitch />
    </PatientDemographicContext.Provider>
  );
};

const PatientDemographicDialog: React.FunctionComponent = observer(() => {
  const { practice } = useStores();
  const {
    ui: { currentContactId, newContactType }
  } = practice;

  if (currentContactId || newContactType) {
    return <PatientDemographicDialogBase />;
  }
  return null;
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PatientDemographicDialog;
