import React, { PropsWithChildren } from "react";

import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { GetServiceDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { StateFilter } from "@shared-types/core/state-filter.enum.ts";
import { TypeFilter } from "@shared-types/core/type-filter.enum.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";

import { FeeActiveLabel } from "../../labels.ts";
import { FilterState } from "./FeesList.types.ts";
import { FeesListFilterValidator } from "./validators/FeesListFilterValidator.ts";

interface FeesListFilterProps
  extends PropsWithChildren<
    Partial<Pick<FilterBarProps, "hidden" | "onReset" | "initialValues">>
  > {
  setFilterSearch: (options: GetServiceDto) => void;
}

const defaultFilterState: GetServiceDto = {
  scheduleId: undefined,
  effectiveDate: undefined,
  code: undefined,
  searchText: undefined,
  isActive: undefined,
  isService: undefined,
  startDate: undefined,
  endDate: undefined
};

const validator = new FeesListFilterValidator();

export const FeesListFilter: React.FC<FeesListFilterProps> = ({
  hidden,
  onReset,
  setFilterSearch,
  initialValues,
  children,
  ...rest
}) => {
  const filterBarItems: Item<FilterState>[] = [
    {
      name: "searchText",
      type: "searchBox",
      stickItem: true,
      props: {
        ...dataAttribute(DataAttributes.Element, "fee-text-filter"),
        autoFocus: true,
        placeholder: "Search for a fee"
      }
    },
    {
      name: "isActive",
      type: "optionsSelect",
      props: {
        ...dataAttribute(DataAttributes.Element, "fee-state-filter"),
        hideSearchOption: true,
        placeholder: "State",
        calloutWidth: 120,
        options: [
          { key: StateFilter.active, text: FeeActiveLabel.active },
          { key: StateFilter.inactive, text: FeeActiveLabel.inactive }
        ]
      }
    },
    {
      name: "isService",
      type: "optionsSelect",
      props: {
        ...dataAttribute(DataAttributes.Element, "fee-type-filter"),
        placeholder: "Type",
        hideSearchOption: true,
        calloutWidth: 120,
        options: [
          { key: TypeFilter.service, text: "Service" },
          { key: TypeFilter.material, text: "Material" }
        ]
      }
    },
    {
      type: "datesRangePicker",
      name: "datesRangePicker",
      props: {
        id: "fees-filter-dates-range",
        startDateProps: {
          id: "fees-filter-start-date",
          maxDate: DateTime.jsDateNow()
        },
        endDateProps: { id: "fees-filter-end-date" },
        startDateName: "startDate",
        endDateName: "endDate",
        placeholder: "Date(s)"
      }
    }
  ];

  return (
    <FilterBar<FilterState>
      {...rest}
      onChange={({ values }) => {
        const filter = {
          ...values,
          startDate: DateTime.jsDateToISODate(values.startDate),
          endDate: DateTime.jsDateToISODate(values.endDate)
        };
        setFilterSearch(filter);
      }}
      validate={validator.validate}
      hidden={!hidden}
      initialValues={{
        ...initialValues,
        startDate: DateTime.jsDateFromISO(initialValues?.startDate),
        endDate: DateTime.jsDateFromISO(initialValues?.endDate)
      }}
      {...dataAttribute(DataAttributes.Element, "fee-filter")}
      items={filterBarItems}
      styles={{ searchBox: { minWidth: 300 } }}
      customResetButtonCondition={() => false}
      onReset={(actions, values) => {
        onReset && onReset(actions, values);
        actions.reset({
          ...defaultFilterState,
          startDate: DateTime.jsDateFromISO(defaultFilterState?.startDate),
          endDate: DateTime.jsDateFromISO(defaultFilterState?.endDate)
        });
        setFilterSearch(defaultFilterState);
      }}
    >
      {() => children}
    </FilterBar>
  );
};
