import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { routes } from "@libs/routing/routes.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { fullScreen } from "../shared-components/billing.styles.ts";
import { BillingLayout } from "../shared-components/BillingLayout.tsx";
import { InvoiceView } from "./components/InvoiceView.tsx";
import { InvoiceViewDataFetcher } from "./components/InvoiceViewDataFetcher.tsx";
import { InvoiceViewHeader } from "./components/InvoiceViewHeader.tsx";
import { InvoiceViewInfoBar } from "./components/InvoiceViewInfoBar.tsx";

const InvoiceViewScreenBase: React.FC = observer(() => {
  const { isStatusCancelledOrAdjusted } = useContext(InvoiceViewContext);

  const messageBar = isStatusCancelledOrAdjusted && <InvoiceViewInfoBar />;

  return (
    <div style={fullScreen}>
      <BillingLayout header={<InvoiceViewHeader />} messageBar={messageBar}>
        <InvoiceView hideInfoBar={true} />
      </BillingLayout>
    </div>
  );
});

const InvoiceViewScreen: React.FC = () => {
  const { routing } = useStores();

  const invoiceId = routing.match(routes.accounts.invoices.invoice)?.params.id;

  if (!invoiceId) {
    return null;
  }

  return (
    <InvoiceViewDataFetcher invoiceId={invoiceId}>
      <InvoiceViewScreenBase />
    </InvoiceViewDataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default InvoiceViewScreen;
