import { IDropdownProps, noWrap } from "@bps/fluent-ui";
import { nameOfFieldArray } from "@libs/utils/name-of.utils.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimDiagnosisChangeFormValues } from "@shared-types/acc/claim-diagnosis-change-values.type.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import {
  getCurrentDiagnosisPickerData,
  getOldDiagnosisKey,
  getOriginalCurrentDiagnoses
} from "../../utils.ts";
import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";

interface ChangeDiagnosisPickerProps {
  name: string;
  field: ClaimDiagnosisChangeFormValues;
  label?: string;
  required?: boolean;
  dropdownWidth?: IDropdownProps["dropdownWidth"];
}

export const ChangeDiagnosisDropDown: React.FC<ChangeDiagnosisPickerProps> = ({
  name,
  field,
  label,
  required,
  dropdownWidth
}) => {
  const { claim, claimAdjustment } = useClaimAdjustmentContext();
  const { acc } = useStores();
  const fieldNameOf = nameOfFieldArray<ClaimDiagnosisChangeFormValues>(name);

  const diagnosisChangesFields =
    useFieldArray<ClaimDiagnosisChangeFormValues>(
      claimAdjustmentFormNameOf("diagnosisChanges")
    ).fields.value || [];

  const sideChangesFields =
    useFieldArray<ClaimDiagnosisChangeFormValues>(
      claimAdjustmentFormNameOf("sideChanges")
    ).fields.value || [];

  const diagnosisAdditions =
    useFieldArray<ClaimDiagnosisFormValues>(
      claimAdjustmentFormNameOf("diagnosisAdditions")
    ).fields.value?.map(v => ({
      oldDiagnosisKey: getOldDiagnosisKey(v),
      ...v
    })) || [];

  const currentlySelectedKey = field.oldDiagnosisKey;
  const allSelectedKeys = [
    ...diagnosisChangesFields,
    ...sideChangesFields,
    ...diagnosisAdditions
  ]
    .map(x => x.oldDiagnosisKey)
    .filter(x => !!x);

  const currentDiagnosesPickerData = getOriginalCurrentDiagnoses(
    claim,
    claimAdjustment!
  ).map(x =>
    getCurrentDiagnosisPickerData(x, acc.ref.diagnosisSides.keyTextValues)
  );

  const currentDiagnosesPickerDataFiltered = currentDiagnosesPickerData.filter(
    currentDiagnosis =>
      !allSelectedKeys.find(
        key =>
          currentDiagnosis.key === key &&
          currentDiagnosis.key !== currentlySelectedKey
      )
  );

  return (
    <DropdownField
      fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0, ...noWrap } }}
      label={label}
      required={required}
      name={fieldNameOf("oldDiagnosisKey")}
      options={currentDiagnosesPickerDataFiltered}
      dropdownWidth={dropdownWidth}
    />
  );
};
