import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext } from "react";

import {
  ContextualMenuItemType,
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  TooltipHost
} from "@bps/fluent-ui";
import {
  CorrespondencesTestElements,
  RequestStatus
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { isPreviewAndPrintSupported } from "@libs/utils/file.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { CorrespondencesLabels } from "@modules/clinical/screens/patient-record/components/correspondence/Correspondences.types.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InvestigationSidePanelContext } from "./InvestigationsSidePanelContext.ts";

export interface RequestsListItemContextMenuItemsProps {
  record: ClinicalDocument;
}

export const RequestsListItemContextMenu: FunctionComponent<RequestsListItemContextMenuItemsProps> =
  observer(({ record }) => {
    const {
      onOpenInvestigationClick,
      onPrintClick,
      onMarkAsReturnedClick,
      onCancelRequestClick,
      onEditConfidential,
      updateDocumentSecGroupId
    } = useContext(InvestigationSidePanelContext);

    const { clinicalRecord } = usePatientRecordScreenContext();
    const { core } = useStores();

    const imagingRequest = clinicalRecord.imagingRequests.find(
      x => x.documentId === record.id
    );

    const isConfidential = !!record?.secGroupId;
    const isConfidentOfOtherUser = !core.hasAccessToSecGroup(record.secGroupId);

    let menuItems: IContextualMenuItem[] = [
      {
        key: CorrespondencesLabels.MarkAsReturned,
        name: CorrespondencesLabels.MarkAsReturned,
        onClick: () => {
          onMarkAsReturnedClick(record);
        },
        disabled: imagingRequest?.status === RequestStatus.Returned
      },
      {
        key: CorrespondencesLabels.CancelRequest,
        name: CorrespondencesLabels.CancelRequest,
        onClick: () => {
          onCancelRequestClick(record);
        },
        disabled: imagingRequest?.status === RequestStatus.Cancelled
      },
      {
        key: isConfidential
          ? CorrespondencesLabels.RemoveConfidentiality
          : CorrespondencesLabels.MakeConfidential,
        name: isConfidential
          ? CorrespondencesLabels.RemoveConfidentiality
          : CorrespondencesLabels.MakeConfidential,

        onClick: async () => {
          //update document for backend confidential masking - security
          updateDocumentSecGroupId(record);

          onEditConfidential(record);
        },
        disabled: imagingRequest?.status === RequestStatus.Cancelled
      },
      { key: "divder1", itemType: ContextualMenuItemType.Divider },
      {
        key: CorrespondencesLabels.ContextMenuOpenDocument,
        name: CorrespondencesLabels.ContextMenuOpenDocument,
        onClick: () => {
          onOpenInvestigationClick(record);
        }
      },
      {
        key: CorrespondencesLabels.ContextMenuPrint,
        name: CorrespondencesLabels.ContextMenuPrint,
        onClick: () => {
          onPrintClick(record);
        }
      }
    ];

    if (!core.hasPermissions(Permission.ConfidentialDataAllowed)) {
      menuItems = menuItems.filter(
        x =>
          x.key !== CorrespondencesLabels.RemoveConfidentiality &&
          x.key !== CorrespondencesLabels.MakeConfidential
      );
    }

    const items: IContextualMenuItem[] =
      record.extension && isPreviewAndPrintSupported(record.extension)
        ? menuItems
        : [];

    return (
      <TooltipHost content={isConfidentOfOtherUser ? "Confidential" : ""}>
        <IconButton
          {...dataAttribute(
            DataAttributes.Element,
            CorrespondencesTestElements.MoreButton
          )}
          menuIconProps={{
            iconName: isConfidentOfOtherUser ? "ShieldAlert" : "More"
          }}
          menuProps={{
            items
          }}
          styles={{
            root: {
              height: "36px"
            },
            rootDisabled: {
              backgroundColor: "transparent"
            }
          }}
          disabled={isConfidentOfOtherUser}
        />
      </TooltipHost>
    );
  });
