import { fabric } from "fabric";

export class DrawingCanvasHistory {
  public allHistory = new Map<string, DrawingCanvasHistoryItems>();
  private isHistoryAction: boolean = false;

  public undo = (currentCanvas: fabric.Canvas) => {
    const currentCanvasId = currentCanvas?.getElement().id;

    const historyItems = this.allHistory.get(currentCanvasId);
    if (
      historyItems &&
      historyItems.items.length &&
      // we are skipping initial values from the history
      historyItems.currentIndex >= historyItems.skip
    ) {
      this.isHistoryAction = true;
      const object = historyItems.items[historyItems.currentIndex];
      if (object) {
        historyItems.currentIndex--;

        return object && currentCanvas.remove(object);
      }
    }
    return;
  };

  public redo = (currentCanvas: fabric.Canvas) => {
    const currentCanvasId = currentCanvas?.getElement().id;

    const historyItems = this.allHistory.get(currentCanvasId);
    if (
      historyItems &&
      historyItems.items.length &&
      historyItems.currentIndex < historyItems.items.length - 1
    ) {
      historyItems.currentIndex++;
      this.isHistoryAction = true;
      const object = historyItems.items[historyItems.currentIndex];
      return object && currentCanvas.add(object);
    }
    return;
  };

  public initialize = (canvasKey: string, currentIndex: number) => {
    this.allHistory.set(canvasKey, {
      items: [],
      currentIndex,
      skip: currentIndex
    });
  };

  public add = (canvasKey: string, item: fabric.Canvas) => {
    const historyItems = this.allHistory.get(canvasKey);

    if (!historyItems) {
    } else {
      // skip adding in a case or undo/redo actions to avoid duplicated items in history
      if (!this.isHistoryAction) {
        historyItems.items = item.getObjects();
        historyItems.currentIndex = item.getObjects().length - 1;
      }
    }
    this.isHistoryAction = false;
  };
}

export interface DrawingCanvasHistoryItems {
  currentIndex: number;
  skip: number;
  items: fabric.Object[];
}
