import { Stack } from "@bps/fluent-ui";
import {
  DiagnosisList,
  DiagnosisListItem
} from "@modules/clinical/screens/shared-components/DiagnosisList.tsx";

interface ClaimDiagnosisOverviewProps {
  diagnoses: DiagnosisListItem[];
}

export const ClaimDiagnosisOverview: React.FC<ClaimDiagnosisOverviewProps> = ({
  diagnoses
}) => {
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      {!!diagnoses.length && (
        <DiagnosisList
          descriptionHeaderText="Primary diagnosis"
          diagnoses={[diagnoses[0]]}
        />
      )}
      {diagnoses.length > 1 && (
        <DiagnosisList
          descriptionHeaderText="Additional diagnoses"
          diagnoses={diagnoses.slice(1)}
        />
      )}
    </Stack>
  );
};
