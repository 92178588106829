import { Stack } from "@bps/fluent-ui";
import { groupBy } from "@bps/utils";
import { isAllocationItemDto } from "@libs/gateways/billing/billing-gateway.utils.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { sum } from "@libs/utils/utils.ts";
import { TotalsDetails } from "@modules/billing/screens/shared-components/TotalsDetails.tsx";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { ItemTypeText } from "@ui-components/RefText.tsx";

interface CreditNoteDetailsProps {
  credit: CreditNote;
}

export const CreditNoteDetails: React.FC<CreditNoteDetailsProps> = ({
  credit
}) => {
  const allocationWithItems = credit.spenders
    ? groupBy(credit.spenders, x => x.transaction.number)
    : [];

  return allocationWithItems.length > 0 ? (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <TotalsDetails
        heading="Credit allocated to"
        rowOptions={allocationWithItems.map(x => {
          const [number, items] = x;
          const paymentRoute = routes.accounts.allocations.allocation.path({
            id: items[0].transaction.id
          });

          const refundRoute = routes.accounts.refund.path({
            id: items[0].transaction.id
          });
          return {
            id: number,
            text: (
              <>
                <ItemTypeText code={items[0].transaction.itemType} />
                &nbsp;
                {number}
              </>
            ),
            amount: sum("amount", items),
            path: isAllocationItemDto(items[0]) ? paymentRoute : refundRoute,
            permission: isAllocationItemDto(items[0])
              ? Permission.AccountHistoryAllowed
              : Permission.RefundAllowed
          };
        })}
      />
    </Stack>
  ) : null;
};
