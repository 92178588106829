import { createContext, FC, PropsWithChildren, useContext } from "react";

import { TemplateScreenHelper } from "./TemplateScreenHelper.ts";

const TemplateScreenContext = createContext<TemplateScreenHelper>(
  {} as TemplateScreenHelper
);

export const useTemplateScreenContext = (): TemplateScreenHelper => {
  return useContext<TemplateScreenHelper>(TemplateScreenContext);
};

export const TemplateScreenContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <TemplateScreenContext.Provider value={new TemplateScreenHelper()}>
      {children}
    </TemplateScreenContext.Provider>
  );
};
