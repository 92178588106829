import { IButtonStyles, IconButton, TooltipHost } from "@bps/fluent-ui";

interface TodaysNotesConfidentialToolTipIconProps {
  isConfidential: boolean;
  updateConfidential: () => void;
  styles?: IButtonStyles;
}
export const TodaysNotesConfidentialToolTipIcon: React.FunctionComponent<
  TodaysNotesConfidentialToolTipIconProps
> = ({ isConfidential, updateConfidential, styles }) => {
  const toolTipContent = isConfidential
    ? "Confidential. Click to release"
    : "Make confidential";

  return (
    <TooltipHost content={toolTipContent}>
      <IconButton
        iconProps={{ iconName: isConfidential ? "ShieldAlert" : "Shield" }}
        styles={{
          ...styles
        }}
        onClick={updateConfidential}
      />
    </TooltipHost>
  );
};
