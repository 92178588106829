import { DATE_FORMATS } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  isNotFutureDate,
  predicate,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ProviderDeclarationValuesType } from "@shared-types/acc/provider-declaration-values.type.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { checkIfProviderCanDiagnose } from "../components/utils.ts";

export class ProviderDeclarationFormValidator extends Validator<ProviderDeclarationValuesType> {
  constructor(
    private core: CoreStore,
    private contractTypes: string[] | undefined
  ) {
    super();
    this.forField("hpiCpn", [required()]);
    this.forField("providerId", [required()]);
    this.forField("providerTypeCode", [required()]);
    this.forField("providerTypeCode", [
      checkIfProviderCanDiagnose(contractTypes)
    ]);
    this.forField("providerDeclaration", [
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isNotFutureDate(),
      required()
    ]);
    this.forField("providerDeclared", [required()]);

    if (core.hasPermissions(Permission.LicencingAllowed)) {
      this.forField("claimLodgementAllowed", [
        val => {
          if (val === false) return "Invalid permissions.";
          else return undefined;
        }
      ]);
    }

    this.forField(
      "telehealthDeclared",
      predicate((val, values) => !!values?.telehealthConsultation, required())
    );
    this.forField(
      "telehealthAuthorisedHealthProfessional",
      predicate((val, values) => !!values?.telehealthConsultation, required())
    );
    this.forField(
      "telehealthAuthorisedRecords",
      predicate((val, values) => !!values?.telehealthConsultation, required())
    );
  }
}
