import { FormApi } from "final-form";
import { PropsWithChildren, ReactNode } from "react";

import { Stack, Tile, TopBarWrapper } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";

import { ClinicalFormResponsiveHeader } from "./ClinicalFormResponsiveHeader.tsx";

export interface FormHeaderProps
  extends PropsWithChildren<{
    heading?: string;
    onCancel?: (form: FormApi<any, Partial<any>>) => void;
    hideSubmit?: boolean;
    canSubmit?: (form: FormApi<any, Partial<any>>) => Promise<boolean>;
    clinicalToolAfterSubmit?: (value: boolean) => void;
    extraPromptConditionOnCancel?: (
      form: FormApi<any, Partial<any>>
    ) => boolean;
    disableButtonCondition?: (
      stashedData?: StashedEncounterClinicalData
    ) => boolean;
    submitButtonTextCondition?: (
      stashedData?: StashedEncounterClinicalData
    ) => string;
    noGap?: boolean;
    lastUpdatedDate?: DateTime;
    lastUpdatedUserId?: string;
    promptOnCancel?: boolean;
    confidentialShield?: ReactNode;
    onClose?: () => void;
    saveButtons?: ReactNode;
    saveAndCloseOnClick?: (form: FormApi<any, Partial<any>>) => void;
    additionalDisableCheck?: (values: Partial<any>) => boolean;
    hasSeparator?: boolean;
  }> {}

export const FormHeaderWrapper: React.FC<FormHeaderProps> = props => {
  return (
    <TopBarWrapper
      styles={{
        root: {
          gap: props.noGap ? 0 : 16,
          margin: props.noGap || !props.onClose ? 0 : -16
        },
        content: {
          display: "flex"
        },
        subComponentStyles: {
          topBarStyles: {
            root: {
              height: "auto",
              padding: 0
            }
          }
        }
      }}
      header={
        <ClinicalFormResponsiveHeader
          heading={props.heading}
          onCancel={props.onCancel}
          clinicalToolAfterSubmit={props.clinicalToolAfterSubmit}
          extraPromptConditionOnCancel={props.extraPromptConditionOnCancel}
          disableButtonCondition={props.disableButtonCondition}
          submitButtonTextCondition={props.submitButtonTextCondition}
          lastUpdatedDate={props.lastUpdatedDate}
          lastUpdatedUserId={props.lastUpdatedUserId}
          confidentialShield={props.confidentialShield}
          canSubmit={props.canSubmit}
          onClose={props.onClose}
          saveButtons={props.saveButtons}
          saveAndCloseOnClick={props.saveAndCloseOnClick}
          additionalDisableCheck={props.additionalDisableCheck}
          hideSubmit={props.hideSubmit}
          hasSeparator={props.hasSeparator}
        />
      }
      hasShadow={!props.hasSeparator}
    >
      <Tile
        styles={{
          root: {
            padding: props.noGap && !props.hasSeparator ? 0 : 16,
            width: "100%",
            height: "100%",
            overflowY: "auto"
          }
        }}
      >
        <Stack
          styles={{
            root: {
              width: "100%",
              height: "100%",
              padding: props.hasSeparator ? 16 : 0
            }
          }}
        >
          {props.children}
        </Stack>
      </Tile>
    </TopBarWrapper>
  );
};
