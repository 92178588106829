import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

type UnavailableFormValues = {
  providerId?: string | undefined;
  startDate?: Date | undefined;
  startTime?: string | undefined;
  endDate?: Date | undefined;
  endTime?: string | undefined;
  comments?: string | undefined;
};

export type { UnavailableFormValues };
export const nameOf = nameOfFactory<UnavailableFormValues>();
