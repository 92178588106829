import { TextBadgeColor, useTheme } from "@bps/fluent-ui";
import {
  ClaimStatuses,
  ClaimStatusText
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  ClaimStatusBadgeBase,
  ClaimStatusBadgeBaseProps
} from "./ClaimStatusBadgeBase.tsx";

interface ClaimStatusBadgeProps {
  claimStatus?: string | undefined;
  badgeSize?: ClaimStatusBadgeBaseProps["badgeSize"];
  styles?: ClaimStatusBadgeBaseProps["styles"];
}

export const StatusBadge: React.FC<ClaimStatusBadgeProps> = ({
  badgeSize,
  claimStatus,
  styles
}) => {
  const { acc } = useStores();
  const theme = useTheme();

  let displayIcon = false;
  let hasBorder = false;
  let badgeColor = TextBadgeColor.red;
  let iconName = "Message";
  let fontColor;
  let statusText = acc.ref.claimStatuses.keyTextValues.find(
    x => x.key === claimStatus
  )?.text;

  switch (claimStatus) {
    case ClaimStatuses.Ready:
      badgeColor = TextBadgeColor.green;
      break;
    case ClaimStatuses.Discharged:
      displayIcon = true;
      iconName = "Leave";
      break;
    case ClaimStatuses.Queued:
      displayIcon = true;
      iconName = "Documentation";
      break;
    case ClaimStatuses.Accepted:
      displayIcon = true;
      iconName = "Accept";
      fontColor = theme.palette.green;
      break;
    case ClaimStatuses.Pending:
      displayIcon = true;
      iconName = "HourGlass";
      break;
    case ClaimStatuses.NotAvailable:
      displayIcon = true;
      iconName = "Warning";
      statusText = ClaimStatusText.NotAvailable;
      break;
    case ClaimStatuses.Accredited:
      displayIcon = true;
      iconName = "ContactList";
      break;
    case ClaimStatuses.Held:
    case ClaimStatuses.NotVerified:
      badgeColor = TextBadgeColor.lightGrey;
      hasBorder = true;
      break;
    case ClaimStatuses.Private:
      badgeColor = TextBadgeColor.lightGrey;
      break;
    case "Incomplete":
    case ClaimStatuses.Incomplete:
      badgeColor = TextBadgeColor.yellow;
      statusText = "Incomplete";
      break;
    case undefined:
    case "":
      badgeColor = TextBadgeColor.lightGrey;
      statusText = "Private";
      break;
    default:
      iconName = "CannedChat";
      badgeColor = TextBadgeColor.red;
      break;
  }
  return (
    <ClaimStatusBadgeBase
      displayIcon={displayIcon}
      badgeColor={badgeColor}
      hasBorder={hasBorder}
      icon={iconName}
      textColor={fontColor}
      text={statusText}
      styles={styles}
      badgeSize={badgeSize}
    />
  );
};

export const ClaimStatusBadge = withFetch(
  x => [x.acc.ref.claimStatuses.load()],
  StatusBadge
);
