import { FunctionComponent, memo } from "react";

import { ClinicalNotification } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DeleteDialog,
  DeleteDialogProps
} from "@ui-components/DeleteDialog.tsx";

import { ClinicalNotificationList } from "./ClinicalNotificationList.tsx";

interface ClinicalNotificationDeleteDialogProps {
  title?: {
    single: string;
    multiple: string;
  };
  subText?: {
    single: string;
    multiple: string;
  };
  hidden: boolean;
  selectedCount: number;
  onConfirm: DeleteDialogProps["onConfirm"];
  onCancel?: () => void;
  selectedNotifications?: ClinicalActivity[];
}

const ClinicalNotificationDeleteDialogBase: FunctionComponent<ClinicalNotificationDeleteDialogProps> =
  memo(props => {
    const { clinical } = useStores();

    const getTitle = () => {
      if (props.selectedCount > 1) {
        return props.title
          ? props.title.multiple
          : ClinicalNotification.DeleteModalTitleWithMultiple;
      } else {
        return props.title
          ? props.title.single
          : ClinicalNotification.DeleteModalTitleWithSingle;
      }
    };

    const getSubtext = () => {
      if (props.selectedCount > 1) {
        return props.subText
          ? props.subText.multiple
          : ClinicalNotification.DeleteMultiple;
      } else {
        return props.subText
          ? props.subText.single
          : ClinicalNotification.DeleteSingle;
      }
    };

    return (
      <DeleteDialog
        formName="remove-clinical-notification"
        {...props}
        title={getTitle()}
        subText={getSubtext()}
        children={
          <ClinicalNotificationList
            notifications={props.selectedNotifications}
          />
        }
        options={clinical.ref.clinicalTaskDeleteReasons.keyTextValues}
        okButtonText={ClinicalNotification.Delete}
      />
    );
  });

export const ClinicalNotificationDeleteDialog = withFetch(
  x => [
    x.clinical.ref.clinicalTaskDeleteReasons.load(),
    x.clinical.ref.clinicalActivityTypes.load(),
    x.clinical.ref.clinicalActivityDescriptions.load()
  ],
  ClinicalNotificationDeleteDialogBase
);
