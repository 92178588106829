import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { PaymentViewDataFetcher } from "./components/PaymentViewDataFetcher.tsx";
import { PaymentView } from "./PaymentView.tsx";

export const PaymentViewScreen = () => {
  const { routing } = useStores();
  const id = routes.accounts.payments.viewPath.match(routing.location.pathname)
    ?.params.id;

  if (!id) {
    return null;
  }

  return (
    <PaymentViewDataFetcher paymentId={id}>
      <PaymentView />
    </PaymentViewDataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PaymentViewScreen;
