import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  applyOpacityToHexColor,
  FontSizes,
  Link,
  MessageBar,
  PrimaryButton,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClaimsScreenContext } from "@modules/acc/screens/claims/context/ClaimsScreenContext.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";
import { When } from "@ui-components/withPerm.tsx";

const lodgeBulkClaimsHandler = async (acc: AccStore, request: Selection) => {
  const selectedItems = request.getSelection() as Claim[];

  await acc.lodgeBulkAccClaims(selectedItems.map(claim => claim.id));
};

export const NewClaimButtonsGroup: React.FC = observer(() => {
  const theme = useTheme();
  const { acc } = useStores();

  const { selection, selectionCount, disableBulkLodge } =
    useContext(ClaimsScreenContext);

  const borderColor = applyOpacityToHexColor(theme.palette.neutralTertiary, 50);

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
      {selectionCount > 1 && (
        <MessageBar
          styles={({ theme }) => ({
            root: {
              width: "auto",
              height: 32,
              backgroundColor: theme.palette.white,
              border: `1px solid ${borderColor}`
            },
            content: { height: 32 }
          })}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: 24 }}
            styles={{ root: { height: "100%" } }}
          >
            <Text styles={{ root: { fontSize: FontSizes.size12 } }}>
              {selectionCount} claims selected
            </Text>
            <Link
              styles={{
                root: { fontSize: FontSizes.size14, lineHeight: 14 }
              }}
              onClick={() => {
                selection.setAllSelected(false);
              }}
            >
              Clear selection
            </Link>
          </Stack>
        </MessageBar>
      )}

      <When permission={Permission.ClaimWrite}>
        <PrimaryButton
          disabled={disableBulkLodge}
          onClick={() => lodgeBulkClaimsHandler(acc, selection)}
        >
          Lodge
        </PrimaryButton>
      </When>
    </Stack>
  );
});
