export enum PatientCardIds {
  conditions = "conditions",
  patientHeader = "patientHeader",
  extraInformation = "extraInformation",
  contactMethods = "contactMethods",
  patientAccount = "patientAccount",
  contactRelationships = "contactRelationships",
  patientEntitlements = "patientEntitlements",
  prefCons = "prefCons",
  healthProviders = "healthProviders",
  patientProfile = "patientProfile",
  appointments = "appointments",
  employers = "employers",
  forms = "forms"
}
