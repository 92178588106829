import { observer } from "mobx-react-lite";

import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { unique } from "@bps/utils";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

export interface SchedulesFilterInternal {
  scheduleType?: string[];
  scheduleName?: string;
}

interface SchedulesListFilterProps
  extends Pick<FilterBarProps<SchedulesFilterInternal>, "children"> {
  types: string[];
}

export const SchedulesFilter: React.FC<SchedulesListFilterProps> = observer(
  ({ types, ...rest }) => {
    const uniqueTypes = Array.from(unique(types)).sort((a, b) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : -1
    );

    const typesOptions = uniqueTypes?.map(x => ({
      key: x,
      text: x
    }));

    return (
      <FilterBar<SchedulesFilterInternal>
        {...rest}
        initialValues={undefined}
        {...dataAttribute(DataAttributes.Element, "schedulesList-filter")}
        items={[
          {
            type: "searchBox",
            name: "scheduleName",
            stickItem: true,
            props: {
              ...dataAttribute(
                DataAttributes.Element,
                "schedulesList-filter-ScheduleName"
              ),
              autoFocus: true,
              placeholder: "Search by name"
            }
          },
          {
            type: "optionsSelect",
            name: "scheduleType",
            props: {
              id: "schedulesList-filter-scheduleType",
              placeholder: "Type",
              options: typesOptions,
              multiSelect: true,
              calloutWidth: 250
            }
          }
        ]}
        styles={{
          searchBox: { minWidth: 220 }
        }}
      />
    );
  }
);
