import { routes } from "@libs/routing/routes.ts";
import { WriteOff } from "@stores/billing/models/WriteOff.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { WriteOffView } from "./components/WriteOffView.tsx";

export const WriteOffViewScreen = () => {
  const { routing } = useStores();
  const id = routing.match(routes.accounts.invoices.writeOff.viewPath)?.params
    .id!;

  if (!id) {
    return null;
  }

  return (
    <DataFetcher<WriteOff> fetch={({ billing }) => billing.getWriteOff(id)}>
      {(writeOff: WriteOff) => <WriteOffView writeOff={writeOff} />}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default WriteOffViewScreen;
