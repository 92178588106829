import { memo, useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";

import {
  CollapsibleCard,
  CommandButton,
  DefaultButton,
  Heading,
  IContextualMenuItem,
  Separator,
  Stack,
  Toggle,
  useTheme
} from "@bps/fluent-ui";
import { InjuryArea } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { InjuryAreasText } from "@ui-components/RefText.tsx";

import { DominantHandSingleChoice } from "../../family-social-history/physical-activity/DominantHandSingleChoice.tsx";
import { SotapMskId, SotapMskLabel } from "../SOTAP.constants.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";
import { ElbowInjuryImageAssessment } from "./ElbowInjuryImageAssessment.tsx";
import { FootInjuryImageAssessment } from "./FootInjuryImageAssessment.tsx";
import { HandsWristsInjuryImageAssessment } from "./HandsWristsInjuryImageAssessment.tsx";
import { HipInjuryImageAssessment } from "./HipInjuryImageAssessment.tsx";
import { KneeInjuryImageAssessment } from "./KneeInjuryImageAssessment.tsx";
import { ShoulderInjuryImageAssessment } from "./ShoulderInjuryImageAssessment.tsx";
import { SidePivots } from "./SidePivots.tsx";
import { SpinalCordInjuryImageAssessment } from "./SpinalCordInjuryImageAssessment.tsx";
import { SpinePivots } from "./SpinePivots.tsx";

export const InjuryAreasCard: React.FC = memo(() => {
  const theme = useTheme();
  const { clinical } = useStores();
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const {
    input: { value: injuryAreas }
  } = useField<InjuryArea[]>(sotapNameOf("injuryAreas"), {
    subscription: { value: true }
  });

  const form = useForm<SOTAPFormValues>();
  const initInjuryAreas = initialValues?.injuryAreas ?? [];

  const [showInjuryAreas, setShowInjuryAreas] = useState<boolean>(
    !!initInjuryAreas.length
  );

  const onContinue = () => {
    const { values } = form.getState();
    if (values.injuryAreas && !!values.injuryAreas.length) {
      setShowInjuryAreas(!!values.injuryAreas);
    }
  };

  const renderImages = (injuryArea: string) => {
    switch (injuryArea) {
      case InjuryArea.Spine:
        return <SpinalCordInjuryImageAssessment />;
      case InjuryArea.HandAndWrist:
        return <HandsWristsInjuryImageAssessment />;
      case InjuryArea.Knee:
        return <KneeInjuryImageAssessment />;
      case InjuryArea.AnkleFoot:
        return <FootInjuryImageAssessment />;
      case InjuryArea.Hip:
        return <HipInjuryImageAssessment />;
      case InjuryArea.Shoulder:
        return <ShoulderInjuryImageAssessment />;
      case InjuryArea.Elbow:
        return <ElbowInjuryImageAssessment />;
      default:
        return undefined;
    }
  };

  const toggleInjuryArea = (type: string, checked?: boolean) => {
    // need to add or remove the injuryAreaMotionAssessment also
    const items = getInjuryAreaMenuItems(injuryAreas);
    const index = items.findIndex(i => i.key === type);
    if (index !== -1) {
      items[index].checked = !!checked;
    }

    const { values, initialValues } = form.getState();

    const updatedList = items
      .filter(i => i.checked)
      .map(i => i.key) as InjuryArea[];

    form.batch(() => {
      if (!checked) {
        if (values.injuryAreaMotionAssessments) {
          form.change(
            sotapNameOf("injuryAreaMotionAssessments"),
            initialValues.injuryAreaMotionAssessments
          );
        }

        if (type === InjuryArea.Spine && values.spinalCordFrontImage) {
          form.mutators.remove(sotapNameOf("spinalCordFrontImage"), 0);
          form.mutators.remove(sotapNameOf("spinalCordSideImage"), 0);
        }
        if (type === InjuryArea.HandAndWrist && values.handsWristsImage) {
          form.mutators.remove(sotapNameOf("handsWristsImage"), 0);
        }
        if (type === InjuryArea.Knee && values.frontKneeImage) {
          form.mutators.remove(sotapNameOf("frontKneeImage"), 0);
          form.mutators.remove(sotapNameOf("backKneeImage"), 0);
          form.mutators.remove(sotapNameOf("leftSideKneeImage"), 0);
          form.mutators.remove(sotapNameOf("rightSideKneeImage"), 0);
        }
        if (type === InjuryArea.AnkleFoot && values.backFootImage) {
          form.mutators.remove(sotapNameOf("backFootImage"), 0);
          form.mutators.remove(sotapNameOf("frontFootImage"), 0);
          form.mutators.remove(sotapNameOf("bottomFootImage"), 0);
          form.mutators.remove(sotapNameOf("topFootImage"), 0);

          form.mutators.remove(sotapNameOf("leftInsideFootImage"), 0);
          form.mutators.remove(sotapNameOf("rightInsideFootImage"), 0);
          form.mutators.remove(sotapNameOf("leftOutsideFootImage"), 0);
          form.mutators.remove(sotapNameOf("rightOutsideFootImage"), 0);
        }
        if (type === InjuryArea.Hip && values.frontHipImage) {
          form.mutators.remove(sotapNameOf("frontHipImage"), 0);
          form.mutators.remove(sotapNameOf("backHipImage"), 0);
          form.mutators.remove(sotapNameOf("leftSideHipImage"), 0);
          form.mutators.remove(sotapNameOf("rightSideHipImage"), 0);
        }
        if (type === InjuryArea.Shoulder && values.leftFrontShoulderImage) {
          form.mutators.remove(sotapNameOf("leftFrontShoulderImage"), 0);
          form.mutators.remove(sotapNameOf("leftBackShoulderImage"), 0);
          form.mutators.remove(sotapNameOf("leftSideShoulderImage"), 0);
          form.mutators.remove(sotapNameOf("rightFrontShoulderImage"), 0);
          form.mutators.remove(sotapNameOf("rightBackShoulderImage"), 0);
          form.mutators.remove(sotapNameOf("rightSideShoulderImage"), 0);
        }
        if (type === InjuryArea.Elbow && values.leftElbowImage) {
          form.mutators.remove(sotapNameOf("leftElbowImage"), 0);
          form.mutators.remove(sotapNameOf("rightElbowImage"), 0);
        }
      }

      form.change(sotapNameOf("injuryAreas"), updatedList);
    });

    setShowInjuryAreas(!!updatedList.length);
  };

  const getInjuryAreaMenuItems = (
    areas: InjuryArea[]
  ): IContextualMenuItem[] => {
    const refData = clinical.ref.injuryAreas.keyTextValues;
    return refData.map(x => {
      return {
        ...x,
        key: x.key,
        onRenderContent: prop => (
          <Toggle
            automationAttribute={x.key}
            disabled={
              clinicalRecord.isFollowOnEncounter
                ? initInjuryAreas.includes(x.key)
                : false
            }
            label={prop.item.text}
            inlineLabel
            checked={areas.includes(x.key)}
            styles={{
              root: { margin: 0 },
              label: { cursor: "pointer" }
            }}
          />
        ),
        checked: areas.includes(x.key),
        onClick: ev => {
          ev?.preventDefault();
          if (
            clinicalRecord.isFollowOnEncounter &&
            initInjuryAreas.includes(x.key)
          )
            return;

          toggleInjuryArea(x.key, !areas.includes(x.key));
        }
      };
    });
  };

  const injuryAreasButton =
    showInjuryAreas && !isViewOnlyOrDischarged ? (
      <CommandButton
        iconProps={{ iconName: "Add" }}
        text="Add more injury areas"
        menuProps={{
          items: getInjuryAreaMenuItems(injuryAreas),
          hidden: false,
          calloutProps: { hideOverflow: true }
        }}
      />
    ) : undefined;

  return (
    <div id={SotapMskId.InjuryAreas}>
      <CollapsibleCard
        heading={SotapMskLabel.InjuryAreas}
        headingLevel="section-heading"
        iconName="ChartYAngle"
        openOnRender
        button={injuryAreasButton}
      >
        {!showInjuryAreas ? (
          <Stack
            tokens={{ childrenGap: 24 }}
            horizontalAlign="center"
            styles={{
              root: {
                background: theme.palette.neutralLighterAlt,
                padding: "37px 90px 30px 90px"
              }
            }}
          >
            <Heading variant="section-sub-heading">
              Please select injury areas that apply
            </Heading>
            <Stack tokens={{ childrenGap: 16 }}>
              <CheckboxGroupField
                name={sotapNameOf("injuryAreas")}
                options={clinical.ref.injuryAreas.keyLabelValues}
                horizontal
                wrap
                checkboxWidth={100}
                styles={{
                  itemsWrapper: {
                    display: "flex",
                    justifyContent: "space-between"
                  }
                }}
              />
            </Stack>
            <FieldSpy
              name={sotapNameOf("injuryAreas")}
              subscription={{ value: true }}
            >
              {(_state, value) => {
                return (
                  <DefaultButton
                    text="Continue"
                    onClick={onContinue}
                    disabled={value?.length === 0}
                  />
                );
              }}
            </FieldSpy>
          </Stack>
        ) : (
          <>
            {injuryAreas.map(x => {
              const isSpineInjuryArea = x === InjuryArea.Spine;
              const isHandArea = x === InjuryArea.HandAndWrist;
              return (
                <Stack key={x}>
                  <Heading variant="section-heading">
                    <InjuryAreasText code={x} />
                  </Heading>

                  {isHandArea && (
                    <DominantHandSingleChoice
                      name={sotapNameOf("dominantHand")}
                      styles={{
                        root: { paddingTop: "8px", paddingBottom: "16px" }
                      }}
                    />
                  )}

                  {renderImages(x)}

                  {isSpineInjuryArea && <SpinePivots injuryArea={x} />}

                  {!isSpineInjuryArea && <SidePivots injuryArea={x} />}

                  {injuryAreas.length > 0 && <Separator />}
                </Stack>
              );
            })}
          </>
        )}
      </CollapsibleCard>
    </div>
  );
});
