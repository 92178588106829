import { FunctionComponent } from "react";

import { Heading, Text } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, TIME_FORMATS } from "@bps/utils";
import { User } from "@stores/core/models/User.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";

import { useTemplateFormContext } from "./template-form/context/TemplateFormContext.tsx";

export const LastUpdatedTemplate: FunctionComponent = () => {
  const { template } = useTemplateFormContext();
  if (
    !template ||
    (template.updatedDate === "" && template.createdDate === "")
  ) {
    return (
      <Heading variant="section-heading">
        Patient Communication template
      </Heading>
    );
  } else {
    const lastUpdatedDate = DateTime.fromISO(
      template.updatedDate || template.createdDate
    );

    const lastUpdatedDateText = `${lastUpdatedDate?.toFormat(
      DATE_FORMATS.DAY_DEFAULT_FORMAT
    )} at ${lastUpdatedDate?.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)}`;

    return (
      <>
        <Heading variant="section-heading">
          Patient Communication template
        </Heading>
        {template && (
          <FieldCondition when="templateId " is={(v: string) => !!v}>
            <DataFetcher<User | undefined>
              fetch={async ({ core }) => {
                return await core.getUserByUserName(
                  (template.updated && template.updated !== ""
                    ? template.updated
                    : template.created)!
                );
              }}
            >
              {provider =>
                provider && (
                  <Text styles={{ root: { fontSize: 12 } }}>
                    <strong>Last updated by</strong> {provider.name} |{" "}
                    {lastUpdatedDateText}
                  </Text>
                )
              }
            </DataFetcher>
          </FieldCondition>
        )}
      </>
    );
  }
};
