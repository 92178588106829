import { PropsWithChildren } from "react";
import { useField, useForm } from "react-final-form";

import { Spinner } from "@bps/fluent-ui";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { useAllocationFormContext } from "../context/AllocationFormContext.ts";

export const AllocationFormDataFetcher: React.FC<PropsWithChildren> = props => {
  const { initialValues, reset, getInitialValues } = useAllocationFormContext();
  const form = useForm();
  const {
    input: { value: accountContactId }
  } = useField("accountContactId", {
    subscription: { value: true }
  });

  return (
    <DataFetcher
      fallback={<Spinner />}
      refetchId={accountContactId}
      fetch={async () => {
        if (initialValues.accountContactId !== accountContactId) {
          await reset(accountContactId);
          form.restart(getInitialValues(accountContactId));
        }
        return Promise.resolve(undefined);
      }}
    >
      {() => props.children}
    </DataFetcher>
  );
};
