import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { CommsScheduleFormValues } from "./CommsScheduleForm.types.ts";

export class CommsScheduleFormValidator extends Validator<CommsScheduleFormValues> {
  constructor() {
    super();

    this.forField("jobName", [required()]);
    this.forField("appointmentTypes", [required()]);
    this.forField("sendTime", [required()]);
    this.forField("templateId", [required()]);
  }
}
