import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Heading, Stack, useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { nameOfPrescribingDoseForm } from "./PrescribingDoseForm.tsx";

const labelContainerStyles = {
  root: { textAlign: "left", marginRight: 16 }
};

const labelStyles = {
  root: { width: 80 }
};

interface PrescribingDoseFormLeftFieldsProps {
  isPrescribed: boolean;
}
export const PrescribingDoseFormLeftFields: FunctionComponent<PrescribingDoseFormLeftFieldsProps> =
  observer(({ isPrescribed }) => {
    const { clinical } = useStores();
    const theme = useTheme();

    return (
      <div>
        <Stack tokens={{ childrenGap: 16 }}>
          <Stack horizontal>
            <SpinNumberInputField
              name={nameOfPrescribingDoseForm("dose")}
              label="Dose"
              horizontalLabel
              min={0.01}
              max={999.99}
              step={1}
              precision={2}
              styles={{
                root: { width: 70, marginRight: 16 }
              }}
            />
            <Stack grow>
              <StaticPickerField
                name={nameOfPrescribingDoseForm("doseUnit")}
                resolveDelay={0}
                fetchDataSource={
                  clinical.ref.dosingAdministrationUnits.fetchAsKeyNameValues
                }
              />
            </Stack>
          </Stack>

          <Stack horizontal>
            <Stack styles={labelContainerStyles}>
              <Heading labelPaddings styles={labelStyles}>
                Frequency
              </Heading>
            </Stack>
            <ChoiceGroupField
              fieldItemStyles={{
                root: {
                  flexGrow: 1,
                  border: `1px ${theme.semanticColors.inputBorder} solid`,
                  borderRadius: 3,
                  padding: 8,
                  maxHeight: 212,
                  overflowY: "auto"
                }
              }}
              name={nameOfPrescribingDoseForm("frequency")}
              options={clinical.ref.dosingFrequencies.keyTextValues}
            />
          </Stack>

          <Stack horizontal>
            <Stack styles={labelContainerStyles}>
              <Heading labelPaddings styles={labelStyles}>
                prn
              </Heading>
            </Stack>
            <Stack grow>
              <ToggleField
                inlineLabel
                name={nameOfPrescribingDoseForm("prn")}
                label=" "
                styles={{ root: { marginBottom: 0 } }}
              />
            </Stack>
          </Stack>
        </Stack>
        {!isPrescribed && (
          <TextInputField
            label="Complex"
            placeholder="More complex instructions"
            name={nameOfPrescribingDoseForm("complex")}
            multiline
            rows={3}
            resizable={false}
            styles={{ root: { marginTop: 8 } }}
          />
        )}
      </div>
    );
  });
