import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { PersonaSize, Spinner, Stack } from "@bps/fluent-ui";
import { AccountCardContactDetailsButton } from "@modules/billing/screens/account/components/AccountCardContactDetailsButton.tsx";
import { BillToSecondaryText } from "@modules/billing/screens/allocation/components/BillToSecondaryText.tsx";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";

import {
  invoiceDetailsRowPersonaContainerStyles,
  invoiceDetailsRowPersonaStyles
} from "./InvoiceDetailsRow.styles.ts";

interface InvoiceDetailsRowPatientPersonaProps {
  patientId: InvoiceFormValues["patientId"];
}

export const InvoiceDetailsRowPatientPersona: FunctionComponent<InvoiceDetailsRowPatientPersonaProps> =
  observer(props => {
    const { patientId } = props;

    if (!patientId) return null;

    return (
      <ContactFetcher
        contactId={patientId}
        options={{ includeContactPreferences: true }}
        fallback={<Spinner />}
      >
        {patient => (
          <Stack styles={invoiceDetailsRowPersonaContainerStyles}>
            <Persona
              styles={invoiceDetailsRowPersonaStyles}
              text={patient.name}
              id={patient.id}
              size={PersonaSize.size48}
              imageInitials={patient.initials}
              onRenderSecondaryText={() => (
                <BillToSecondaryText contact={patient} />
              )}
            />
            <AccountCardContactDetailsButton contactId={patient.id} />
          </Stack>
        )}
      </ContactFetcher>
    );
  });
