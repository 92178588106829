import { DateTime } from "@bps/utils";
import { StoreType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { InboxDocumentDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  ClinicalImageFormValues,
  CorrespondenceFormValue
} from "../clinical-form/DocumentBatchFormValues.ts";
import { ClinicalImageDialogHelper } from "../clinical-images/ClinicalImageDialogHelper.tsx";
import { CorrespondenceDialogHelper } from "../correspondence/CorrespondenceDialogHelper.ts";
import { AddEditInvestigationDialogHelper } from "../investigations/AddEditInvestigationDialogHelper.ts";
import { InvestigationFormValues } from "../investigations/AddInvestigationDialog.types.ts";
import { EditPAHFormValues } from "./helpers/PatientSummaryHelper.ts";

export class EditInboxDocumentDialogHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root: RootStore
  ) {}

  private get correspondence() {
    return this.root.correspondence;
  }

  private get inbox() {
    return this.root.inbox;
  }

  private createBaseRequest(
    selectedInboxDocument: InboxDocumentDto
  ): Partial<InboxDocumentDto> {
    return {
      id: selectedInboxDocument.id,
      documentDetailId: selectedInboxDocument.documentDetailId,
      extension: selectedInboxDocument.extension,
      patientId: selectedInboxDocument.patientId ?? "",
      fromContactId: selectedInboxDocument.fromContactId ?? "",
      documentDate: selectedInboxDocument.documentDate,
      receivedDate: selectedInboxDocument.receivedDate,
      containerUri: selectedInboxDocument.containerUri,
      containerToken: selectedInboxDocument.containerToken,
      changeLog: selectedInboxDocument.changeLog,
      assignedToUserId: selectedInboxDocument.assignedToUserId ?? "",
      eTag: selectedInboxDocument.eTag,
      correspondenceType: selectedInboxDocument.correspondenceType
    };
  }

  private async handleCorrespondenceSave(
    formValue: CorrespondenceFormValue,
    selectedInboxDocument: InboxDocumentDto,
    baseRequest: Partial<InboxDocumentDto>
  ) {
    const { file, to, from, type, checkedBy } = formValue;

    const updatePayload: Partial<InboxDocumentDto> = {
      ...baseRequest,
      name: file?.subject ?? selectedInboxDocument.name,
      assignedToUserId: to ?? "",
      fromContactId: from ?? "",
      correspondenceType: type ?? selectedInboxDocument.correspondenceType,
      checkedBy: checkedBy ?? selectedInboxDocument.checkedBy,
      checkedDateTime: DateTime.now().toISO()
    };

    await this.inbox.updateStoreInboxDocument({
      inboxDocument: updatePayload as InboxDocumentDto,
      storeInDestination: StoreType.Correspondence
    });

    const movedCorrespondence =
      await this.correspondence.getCorrespondenceByDocumentId(
        this.clinicalRecord.id,
        selectedInboxDocument.documentDetailId
      );

    if (!movedCorrespondence) throw new Error("Error updating document");

    const correspondenceHelper = new CorrespondenceDialogHelper(
      this.clinicalRecord,
      this.root
    );
    correspondenceHelper.updateCorrespondence(formValue, movedCorrespondence);
  }

  handleSubmitAction = async (
    storedIn: StoreType,
    formValue: EditPAHFormValues,
    selectedInboxDocument: InboxDocumentDto
  ) => {
    if (!selectedInboxDocument) {
      throw new Error(
        "No selected inbox document available. Please select a document and try again."
      );
    }

    const baseRequest = this.createBaseRequest(selectedInboxDocument);

    try {
      switch (storedIn) {
        case StoreType.Correspondence:
          await this.handleCorrespondenceSave(
            formValue as CorrespondenceFormValue,
            selectedInboxDocument,
            baseRequest
          );
          break;
        case StoreType.ClinicalImages:
          await this.handleClinicalImagesSave(
            formValue as ClinicalImageFormValues,
            selectedInboxDocument,
            baseRequest
          );
          break;
        case StoreType.Investigations:
          await this.handleInvestigationsSave(
            formValue as InvestigationFormValues,
            selectedInboxDocument,
            baseRequest
          );
          break;
        default:
          throw new Error("Invalid storage option");
      }
    } catch (error) {
      throw new Error("Error saving document");
    }
  };

  private async handleClinicalImagesSave(
    formValue: ClinicalImageFormValues,
    selectedInboxDocument: InboxDocumentDto,
    baseRequest: Partial<InboxDocumentDto>
  ) {
    const { file } = formValue;

    const updateCIPayload: Partial<InboxDocumentDto> = {
      ...baseRequest,
      name: file?.subject ?? selectedInboxDocument.name
    };

    await this.inbox.updateStoreInboxDocument({
      inboxDocument: updateCIPayload as InboxDocumentDto,
      storeInDestination: StoreType.ClinicalImages
    });

    const movedClinicalImage =
      await this.correspondence.getCorrespondenceByDocumentId(
        this.clinicalRecord.id,
        selectedInboxDocument.documentDetailId
      );

    if (!movedClinicalImage) throw new Error("Error updating document");

    const clinicalImageHelper = new ClinicalImageDialogHelper(
      this.clinicalRecord,
      this.root
    );
    clinicalImageHelper.updateClinicalImage(formValue, movedClinicalImage);
  }

  private async handleInvestigationsSave(
    formValue: InvestigationFormValues,
    selectedInboxDocument: InboxDocumentDto,
    baseRequest: Partial<InboxDocumentDto>
  ) {
    const { subject } = formValue;

    const updateInvPayload: Partial<InboxDocumentDto> = {
      ...baseRequest,
      name: subject ?? selectedInboxDocument.name
    };

    await this.inbox.updateStoreInboxDocument({
      inboxDocument: updateInvPayload as InboxDocumentDto,
      storeInDestination: StoreType.Investigations
    });

    const movedInvestigation =
      await this.correspondence.getInvestigationByDocumentId(
        this.clinicalRecord.id,
        selectedInboxDocument.documentDetailId
      );

    if (!movedInvestigation) throw new Error("Error updating document");

    const invHelper = new AddEditInvestigationDialogHelper(
      this.clinicalRecord,
      this.root,
      { editInvestigation: movedInvestigation }
    );

    invHelper.updateInvestigation(formValue);
  }
}
