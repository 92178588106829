import { CollapsibleCard, Heading, Stack } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PhysicalActivitiesList } from "@modules/clinical/screens/patient-record/components/family-social-history/physical-activity/PhysicalActivitiesList.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";

export const FamilyAndSocialHistoryCard: React.FC = () => {
  const { clinical } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const getOccupations = () => clinical.ref.occupations.keyNameValues;
  const isDischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();
  return (
    <div id={SotapMskId.FamilyAndSocial}>
      <CollapsibleCard
        heading={SotapMskLabel.FamilyAndSocial}
        headingLevel="section-heading"
        iconName="Family"
        openOnRender
      >
        <Heading variant="section-heading-light">Work </Heading>
        <Stack tokens={{ childrenGap: 24, maxWidth: 630 }}>
          <Stack tokens={{ childrenGap: 8 }}>
            <Fieldset>
              <StaticPickerField
                name={sotapNameOf("occupation")}
                label="Occupation"
                fetchDataSource={getOccupations}
                fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
                inputProps={{
                  placeholder: "Search for an occupation"
                }}
              />
            </Fieldset>
            <ChoiceGroupField
              disabled={isDischargeInProgressOrCompleted}
              name={sotapNameOf("workType")}
              options={clinical.ref.workTypes.keyTextValues}
              label="Type of work"
            />
          </Stack>
          <Stack tokens={{ childrenGap: 8 }}>
            <Heading variant="section-heading-light">
              Physical activities
            </Heading>
            <PhysicalActivitiesList clinicalRecord={clinicalRecord} />
          </Stack>
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
