import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { getAllocation } from "@modules/billing/billing.utils.ts";
import { PaymentViewContext } from "@modules/billing/screens/payment/context/PaymentViewContext.tsx";

import { AllocationItemsTotal } from "../allocation/components/AllocationForm.types.tsx";
import { PaymentTotals } from "../shared-components/AllocationList/AllocationListBase.types.tsx";
import { TransactionView } from "../shared-components/TransactionView.tsx";
import { PaymentDetails } from "./components/PaymentDetails.tsx";
import { PaymentViewButtons } from "./PaymentViewButtons.tsx";

export const PaymentView: React.FC = observer(() => {
  const { payment } = useContext(PaymentViewContext);

  const allocations =
    payment.allocationSpenders?.map(x => ({
      ...getAllocation(x),
      paymentNumber: x.transaction.number,
      paymentId: x.transaction.id
    })) || [];

  const headerOptions = {
    fields: [
      {
        key: PaymentTotals.total,
        title: "Tendered ($)"
      },
      {
        key: PaymentTotals.allocated,
        title: AllocationItemsTotal.allocatedPrefixed
      },
      {
        key: PaymentTotals.unallocated,
        title: AllocationItemsTotal.availablePrefixed
      }
    ]
  };

  const columnOptions = {
    filtersToShow: {
      paymentNumber: true,
      createdDate: true
    }
  };

  const allocationProps = [
    {
      allocations,
      unallocatedAmount: payment.unallocated,
      total: payment.total,
      allocatedAmount: payment.allocated,
      columnOptions,
      headerOptions,
      accountId: payment.accountId
    }
  ];

  return (
    <TransactionView
      extraDetailsContent={<PaymentDetails payment={payment} />}
      transaction={payment}
      allocationOptions={{ allocationProps }}
      buttons={<PaymentViewButtons payment={payment} />}
    />
  );
});
