import { useState } from "react";

import {
  Dropdown,
  FontIcon,
  IDropdownOption,
  IOverflowSetItemProps,
  mergeStyles,
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
  Stack
} from "@bps/fluent-ui";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

interface OverflowButtonProps {
  items: IOverflowSetItemProps[];
  onDocumentClick?: (
    id: string,
    patientId: string,
    encounterId?: string
  ) => void;
}

interface OverFlowDropDownOptions extends IDropdownOption {
  key: string;
  active?: boolean;
  record?: ClinicalRecord;
  loading?: boolean;
  type?: "patient" | "document";
  patientId?: string;
  encounterId?: string;
}

const PatientPillOverflowButtonBase: React.FunctionComponent<
  OverflowButtonProps
> = ({ items, onDocumentClick }) => {
  const { clinical, correspondence } = useStores();
  let overflowOptions: OverFlowDropDownOptions[] = [];
  const overflowLength: number = items.length;
  if (items) {
    overflowOptions = items?.map(item => ({
      key: item.key,
      text: item.type,
      active: item.active ?? undefined,
      record: item.record ?? undefined,
      patientId: item.patientId ?? undefined,
      encounterId: item.encounterId ?? undefined
    }));
  }

  const [selectedItem, setSelectedItem] = useState<OverFlowDropDownOptions>();

  const handlePatientClick = (id: string, encounterId?: string) => {
    clinical.ui.changeRecordPosition(id, encounterId);
  };

  const handleDocumentClick = (
    doc: ClinicalDocument | undefined,
    encounterId?: string
  ) => {
    if (!onDocumentClick || !doc) {
      return;
    }
    onDocumentClick(doc.id, doc.patientId, encounterId);
  };

  const renderOverflowDropdownTitle = () => {
    return <span>{`${overflowLength} more`}</span>;
  };

  const renderOverflowOption = (item: OverFlowDropDownOptions) => {
    if (item) {
      if (item.text === "patient") {
        return (
          <DataFetcher<Contact>
            key={`${item.key}-patientPill`}
            fetch={({ practice }) => practice.getContact(item.key)}
          >
            {(patient: Contact) => {
              return (
                <Persona
                  onClick={() => handlePatientClick(item.key, item.encounterId)}
                  text={patient.name}
                  id={patient.id}
                  imageUrl={patient.profilePictureUrl}
                  size={PersonaSize.size24}
                  imageInitials={patient.initials}
                  styles={{
                    details: {
                      paddingRight: 0
                    },
                    root: {
                      width: "100%"
                    }
                  }}
                  onRenderPrimaryText={() => (
                    <span>
                      {item.loading && <Spinner size={SpinnerSize.small} />}
                      {patient && `${patient.firstName} ${patient.lastName}`}
                    </span>
                  )}
                />
              );
            }}
          </DataFetcher>
        );
      } else {
        return (
          <DataFetcher<ClinicalDocument | undefined>
            key={`${item.key}-documentPill`}
            fetch={({ correspondence }) =>
              correspondence.getCorrespondenceByDocumentId(
                item.patientId!,
                item.key
              )
            }
          >
            {(doc: ClinicalDocument) => {
              if (doc) {
                return (
                  <DataFetcher<Contact>
                    key={`${item.key}-contactdocumentPill`}
                    fetch={({ practice }) => practice.getContact(doc.patientId)}
                  >
                    {(patient: Contact) => {
                      return (
                        <Stack
                          horizontal
                          onClick={() =>
                            handleDocumentClick(doc, item.encounterId)
                          }
                        >
                          <FontIcon iconName="TextDocument" />
                          <span
                            className={mergeStyles({
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              paddingLeft: 8,
                              paddingRight: 8
                            })}
                          >
                            {item.loading && (
                              <Spinner size={SpinnerSize.small} />
                            )}
                            {patient && doc && getDocumentText(doc, patient)}
                          </span>
                        </Stack>
                      );
                    }}
                  </DataFetcher>
                );
              }
              return undefined;
            }}
          </DataFetcher>
        );
      }
    }
    return null;
  };

  const getDocumentText = (doc: ClinicalDocument, patient: Contact) => {
    const docType = correspondence.ref.correspondenceTypes.get(doc.type!);
    return `${docType?.altText} - ${patient!.firstName} ${patient!.lastName}`;
  };

  const onPillChanged = (
    event: React.FormEvent<HTMLDivElement>,
    item: OverFlowDropDownOptions
  ): void => {
    if (item.text === "document") {
      setSelectedItem(item);
    } else {
      setSelectedItem(undefined);
    }
  };

  return (
    <Dropdown
      name="pill-dropdown"
      placeholder={`${overflowLength} more`}
      onRenderTitle={renderOverflowDropdownTitle}
      onRenderOption={renderOverflowOption}
      selectedKey={selectedItem ? selectedItem.key : null}
      options={overflowOptions}
      dropdownWidth={200}
      withNoEmptyOption={true}
      onChange={onPillChanged}
      styles={({ theme }) => ({
        title: {
          width: 87,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme!.semanticColors.menuDivider,
          borderRadius: 4,
          padding: "0px 8px",
          textAlign: "left"
        },
        dropdown: {
          selectors: {
            "&:active": {
              backgroundColor: theme!.semanticColors.buttonBackgroundPressed,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: theme!.semanticColors.menuDivider,
              borderRadius: 4
            },
            "&:focus::after": {
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme!.semanticColors.menuDivider,
              borderRadius: 4
            }
          }
        }
      })}
    />
  );
};

export const PatientPillOverflowButton = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  PatientPillOverflowButtonBase
);
