import { observer } from "mobx-react-lite";

import {
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";
import { RequestStatus } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface RequestBadgeProps {
  document: ClinicalDocument;
}

const RequestBadgeBase: React.FC<RequestBadgeProps> = observer(
  ({ document }) => {
    const { clinicalRecord } = usePatientRecordScreenContext();
    const imagingRequest = clinicalRecord.imagingRequests.find(
      x => x.documentId === document.id
    );

    let TextBadgeColorNumber;
    if (imagingRequest?.status === RequestStatus.Returned) {
      TextBadgeColorNumber = TextBadgeColor.green;
    } else if (imagingRequest?.status === RequestStatus.Cancelled) {
      TextBadgeColorNumber = TextBadgeColor.lightGrey;
    } else if (imagingRequest?.urgent && imagingRequest?.urgent === true) {
      TextBadgeColorNumber = TextBadgeColor.red;
    } else {
      TextBadgeColorNumber = TextBadgeColor.yellow;
    }

    return (
      <TooltipHost
        content={
          imagingRequest?.urgent &&
          imagingRequest?.status === RequestStatus.Requested
            ? RequestStatus.urgentRequest
            : ""
        }
      >
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={TextBadgeColorNumber}
          styles={{
            root: { minWidth: "85px" }
          }}
        >
          {imagingRequest?.status ?? RequestStatus.Requested}
        </TextBadge>
      </TooltipHost>
    );
  }
);

export const RequestBadge = withFetch(
  x => [x.inbox.ref.outcomes.load()],
  RequestBadgeBase
);
