import { useStores } from "@stores/hooks/useStores.ts";

export interface ActivityDescriptionTextProps {
  descriptionId: string;
  freeTextFallback?: string;
}

export const ActivityDescriptionText: React.FC<
  ActivityDescriptionTextProps
> = ({ descriptionId, freeTextFallback }) => {
  const { clinical } = useStores();

  const activityDescriptions = clinical.activityDescriptionMapValues;

  const activityDescription = activityDescriptions.find(
    x => x.id === descriptionId
  );

  if (!activityDescription) {
    return <>Other</>;
  }

  const primaryText = activityDescription.isOther
    ? undefined
    : activityDescription.reasonText;

  const alternativeText = freeTextFallback ?? "Other";
  const activityDescriptionText = primaryText ?? alternativeText;

  return <>{activityDescriptionText}</>;
};
