import { CheckboxGroupOption } from "@bps/fluent-ui";
import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ExportPatientRecordFormValues } from "@shared-types/practice/export-patient-record-form-values.types.ts";

export const exportPatientRecordFormNameOf =
  nameOfFactory<ExportPatientRecordFormValues>();

enum DemographicData {
  relationships = "Relationships",
  contactMethods = "Contact methods",
  healthProviders = "Health providers",
  employers = "Employers"
}

enum ClinicalInformation {
  pastConsults = "Past consults",
  reactions = "Reactions",
  medicalHistory = "Medical history",
  correspondence = "Correspondence",
  investigations = "Investigations",
  clinicalTools = "Clinical tools",
  historyAndExaminations = "History & examinations",
  managementAndPlan = "Management & plan",
  familyAndSocialHistory = "Family and social history"
}

export enum DemographicDataTemplateName {
  general = "Patient Export-Demographic-General",
  relationships = "Patient Export-Demographic-Relationships",
  contactMethods = "Patient Export-Demographic-ContactMethods",
  healthProviders = "Patient Export-Demographic-HealthProviders",
  employers = "Patient Export-Demographic-Employers"
}

export enum ClinicalDataTemplateName {
  header = "Patient Export-Clinical-Header",
  pastConsults = "Patient Export-Clinical-Past-Consults",
  reactions = "Patient Export-Clinical-Reactions",
  medicalHistory = "Patient Export-Clinical-MedicalHistory",
  correspondence = "Patient Export-Clinical-Correspondence",
  investigations = "Patient Export-Clinical-Investigations",
  clinicalTools = "Patient Export-Clinical-ClinicalTools",
  historyAndExaminations = "Patient Export-Clinical-HistoryAndExaminations",
  managementAndPlan = "Patient Export-Clinical-ManagementAndPlan",
  familyAndSocialHistory = "Patient Export-Clinical-FamilyAndSocialHistory"
}

export const demographicOptions: CheckboxGroupOption<string>[] = [
  {
    label: DemographicData.relationships,
    value: DemographicDataTemplateName.relationships
  },
  {
    label: DemographicData.contactMethods,
    value: DemographicDataTemplateName.contactMethods
  },
  {
    label: DemographicData.healthProviders,
    value: DemographicDataTemplateName.healthProviders
  },
  {
    label: DemographicData.employers,
    value: DemographicDataTemplateName.employers
  }
];

export const clinicalOptions: CheckboxGroupOption<string>[] = [
  {
    label: ClinicalInformation.pastConsults,
    value: ClinicalDataTemplateName.pastConsults
  },
  {
    label: ClinicalInformation.reactions,
    value: ClinicalDataTemplateName.reactions
  },
  {
    label: ClinicalInformation.medicalHistory,
    value: ClinicalDataTemplateName.medicalHistory
  },
  {
    label: ClinicalInformation.correspondence,
    value: ClinicalDataTemplateName.correspondence
  },
  {
    label: ClinicalInformation.investigations,
    value: ClinicalDataTemplateName.investigations
  },
  {
    label: ClinicalInformation.clinicalTools,
    value: ClinicalDataTemplateName.clinicalTools
  },
  {
    label: ClinicalInformation.historyAndExaminations,
    value: ClinicalDataTemplateName.historyAndExaminations
  },
  {
    label: ClinicalInformation.managementAndPlan,
    value: ClinicalDataTemplateName.managementAndPlan
  },
  {
    label: ClinicalInformation.familyAndSocialHistory,
    value: ClinicalDataTemplateName.familyAndSocialHistory
  }
];

export const formatOptions = [
  {
    key: DocumentContentType.Pdf,
    text: DocumentContentType.Pdf.toUpperCase()
  },
  {
    key: DocumentContentType.Html,
    text: DocumentContentType.Html.toUpperCase()
  }
];

export const demographicDataValues = demographicOptions.map(
  option => option.value
);
export const clinicalInformationValues = clinicalOptions.map(
  option => option.value
);
