import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

import {
  ContextualMenuItemType,
  IconButton,
  IContextualMenuItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { isClaimStatusDeletable } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimDeleteConfirmDialog } from "@modules/acc/screens/claims/components/claim-delete/ClaimDeleteConfirmDialog.tsx";
import { Claim } from "@stores/acc/models/Claim.ts";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { PrivateConditionModal } from "./PrivateConditionModal.tsx";

type ConditionsContextualMenuProps = {
  condition: Condition;
};

export const ConditionsContextualMenu: FC<ConditionsContextualMenuProps> =
  observer(({ condition }) => {
    const { routing, booking } = useStores();
    const [deletingClaim, setDeletingClaim] = useState<Claim | undefined>();
    const [showConditionModal, setShowConditionModal] = useState(false);

    const widthHeight = {
      width: "32px",
      height: "36px"
    };

    const getMenuItems = () => {
      const items: IContextualMenuItem[] = [
        {
          key: "setup",
          text: "Condition setup",
          onClick: () => setShowConditionModal(true)
        },
        {
          key: "summary",
          text: "Summary",
          onClick: () => {
            if (condition.claim && condition.claim.id) {
              routing.push({
                pathname: routes.claims.management.edit.path({
                  id: condition.claim.id
                })
              });
            } else if (condition.isPrivate) {
              routing.push({
                pathname: routes.conditions.summary.path({
                  id: condition.id
                })
              });
            }
          }
        },
        {
          key: "dividerSummary",
          itemType: ContextualMenuItemType.Divider
        },
        {
          key: "newLinked",
          text: "New linked appointment",
          onClick: () => {
            booking.ui.showCalendarEventDialog({
              type: CalendarEventType.Appointment,
              initialValues: {
                patientId: condition.patientId,
                episodeOfCareId: condition.id
              }
            });
          }
        },
        {
          key: "dividerAppt",
          itemType: ContextualMenuItemType.Divider
        }
      ];
      if (
        !condition.isPrivate &&
        condition.claim &&
        !condition.claim.referralIn
      ) {
        items.push({
          key: "acc45",
          text: "ACC45",
          onClick: () => {
            routing.push({
              pathname: routes.claims.edit.path({
                id: condition.claim!.id
              })
            });
          }
        });
      }
      items.push({
        key: "delete",
        text: "Delete",
        disabled:
          condition.isPrivate ||
          !condition.claim?.claimStatus ||
          (!!condition.claim?.claimStatus &&
            !isClaimStatusDeletable(condition.claim.claimStatus!)),
        onClick: () => setDeletingClaim(condition.claim)
      });

      return items;
    };

    return (
      <Stack horizontalAlign="center" verticalAlign="center" horizontal>
        <TooltipHost content="More">
          <IconButton
            onMouseDown={e => e.stopPropagation()}
            menuIconProps={{ iconName: "More" }}
            menuProps={{
              items: getMenuItems()
            }}
            styles={{
              root: widthHeight,
              flexContainer: widthHeight
            }}
          />
        </TooltipHost>
        {deletingClaim && (
          <ClaimDeleteConfirmDialog
            deletingClaim={deletingClaim}
            setDeletingClaim={setDeletingClaim}
          />
        )}
        {showConditionModal && condition.isPrivate && (
          <PrivateConditionModal
            onDismiss={() => setShowConditionModal(false)}
            condition={condition}
          />
        )}
      </Stack>
    );
  });
