import { observer } from "mobx-react-lite";
import { SortDirection } from "powerbi-models";

import {
  DetailsListLayoutMode,
  DetailsRow,
  IDetailsHeaderProps,
  IDetailsRowProps,
  Link,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { compareDatesPredicate, DateTime } from "@bps/utils";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";

interface PreviousMedicationsListProps {
  clinicalRecord: ClinicalRecord;
}

export enum MedicationSortableColumnIds {
  Added = "Added"
}

export const PreviousMedicationsList: React.FC<PreviousMedicationsListProps> =
  observer(({ clinicalRecord }) => {
    const theme = useTheme();

    const { correspondence } = useStores();

    const MAX_NUM_OF_MEDS = 3;
    const medications =
      clinicalRecord.clinicalData?.medication?.medications.filter(
        x => x.isCeased
      ) ?? [];

    const sortedMedications = Array.from(medications).sort((a, b) =>
      a.productName.localeCompare(b.productName)
    );

    const helper = useMedicationsContext();

    const renderDetailsHeader = (
      props: IDetailsHeaderProps,
      defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
    ) => {
      if (!defaultRender || !props) return null;

      return defaultRender(props);
    };

    const renderRow = (props: IDetailsRowProps): JSX.Element | null => {
      const renderRowProps = { ...props };

      const selectedPackIds = helper.selectedCurrentMeds.map(x => x.packId);

      const disabled =
        !selectedPackIds.includes(
          (props.item as MedicationClinicalDataItemDto).packId
        ) && helper.selectedCurrentMeds.length === MAX_NUM_OF_MEDS;

      return (
        <DetailsRow
          {...renderRowProps}
          styles={{
            root: {
              "&.is-selected:hover": {
                backgroundColor: theme.semanticColors.listItemBackgroundChecked
              }
            },
            fields: {
              display: "inline-flex",
              alignItems: "center"
            }
          }}
          disabled={disabled}
        />
      );
    };

    const getColumns = () => {
      return [
        {
          fieldName: "addedDate",
          key: "addedDate",
          minWidth: 96,
          maxWidth: 96,
          isMultiline: true,
          name: "Added",
          isResizable: true,
          isSortable: true,
          sortDefaultDirection: SortDirection.Descending,

          onRender: (record: MedicationClinicalDataItemDto) => {
            return (
              <Stack horizontalAlign="center">
                <Text>
                  {DateTime.fromISO(record.lastRx)?.toDayDefaultFormat()}
                </Text>
                <TextBadge
                  badgeColor={TextBadgeColor.yellow}
                  badgeSize={TextBadgeSize.small}
                >
                  {record.isCeased ? "Ceased" : "Completed"}
                </TextBadge>
              </Stack>
            );
          }
        },
        {
          fieldName: "productName",
          key: "productName",
          minWidth: 100,
          maxWidth: 400,
          isMultiline: true,
          name: "Name",
          isResizable: true,
          isSortable: true,
          sortDefaultDirection: SortDirection.Descending,
          onRender: (record: MedicationClinicalDataItemDto) =>
            record.productName
        },
        {
          fieldName: "dose",
          key: "dose",
          minWidth: 100,
          maxWidth: 500,
          isMultiline: true,
          name: "Dose details",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) => {
            return (
              <Stack verticalAlign="center">
                {helper.getDoseDetails(record)}
              </Stack>
            );
          }
        },
        {
          fieldName: "quantity",
          key: "quantity",
          minWidth: 84,
          maxWidth: 84,
          name: "Qty",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) =>
            record.quantity ?? "-"
        },
        {
          fieldName: "repeats",
          key: "repeats",
          minWidth: 60,
          maxWidth: 60,
          name: "Rpts",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) =>
            record.repeats ?? "-"
        },
        {
          fieldName: "source",
          key: "source",
          minWidth: 100,
          maxWidth: 100,
          name: "Source",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) => {
            if (record.createLog?.createdById) {
              const associated = getAssociatedPrescription(record);

              return (
                <Stack>
                  {associated && (
                    <Stack horizontal tokens={{ childrenGap: 4 }}>
                      <Text>Script ID:</Text>
                      <Link
                        onClick={async () => {
                          if (
                            associated.documentId &&
                            clinicalRecord.patient?.id
                          ) {
                            const document =
                              await correspondence.getCorrespondenceByDocumentId(
                                clinicalRecord.patient?.id,
                                associated.documentId
                              );

                            if (document) {
                              helper.setPrintedDocumentId(document.id);
                              helper.setLastPrintedDocument(document);
                              helper.setPrintDialogVisible(true);
                            }
                          }
                        }}
                      >
                        {associated.scriptId}
                      </Link>
                    </Stack>
                  )}

                  <UserFetcher
                    userId={record.createLog?.createdById}
                    fallback={<Spinner />}
                  >
                    {(user: User) => (
                      <Text
                        variant="xSmall"
                        styles={{
                          root: { color: theme.palette.neutralSecondary }
                        }}
                      >
                        by {user.fullName}
                      </Text>
                    )}
                  </UserFetcher>
                </Stack>
              );
            }

            return undefined;
          }
        }
      ];
    };

    const getAssociatedPrescription = (
      medicationRecord: MedicationClinicalDataItemDto
    ) => {
      // Find the item.
      const prescriptions =
        clinicalRecord.clinicalData?.prescriptions?.prescriptions ?? [];

      if (prescriptions.length === 0) {
        return undefined;
      }

      const prespectionsWithMedication = prescriptions.filter(x =>
        x.medications.find(y => y.id === medicationRecord.id)
      );

      if (prespectionsWithMedication.length > 0) {
        const sorted = prespectionsWithMedication.sort((a, b) => {
          if (a.createLog && b.createLog) {
            return compareDatesPredicate(
              DateTime.fromISO(a.createLog?.createdDateTime),
              DateTime.fromISO(b.createLog?.createdDateTime),
              true
            );
          } else return 0;
        });

        return sorted[0];
      }

      return undefined;
    };

    return (
      <ShimmeredDetailsList
        items={sortedMedications}
        setKey="set"
        onRenderDetailsHeader={renderDetailsHeader}
        stickyHeader
        layoutMode={DetailsListLayoutMode.fixedColumns}
        columns={getColumns()}
        onRenderRow={renderRow}
        selectionPreservedOnEmptyClick={true}
      />
    );
  });
