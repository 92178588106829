import React from "react";

import {
  FontIcon,
  FontWeights,
  getColumnClampLinesSelector,
  IconButton,
  mergeStyleSets,
  PersonaSize,
  Stack,
  StackItem,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { getAgeString } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import {
  formatCommunication,
  isPhone
} from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { PatientHeaderTags } from "./PatientHeaderTags.tsx";

export const PersonaDetailsBase: React.FC = () => {
  const {
    practice: {
      ui: { showEditContact }
    },
    clinical,
    core
  } = useStores();

  const theme = useTheme();
  const onHandleEditModal = (cardId: string, id: string) => () => {
    showEditContact(cardId, id);
  };

  const { clinicalRecord } = usePatientRecordScreenContext();

  const onClinicalDataLinkClick =
    (clinicalDataType: ClinicalDataType) => () => {
      clinical.ui.setPatientClinicalContent({ type: clinicalDataType });
    };

  return (
    <ContactFetcher contactId={clinicalRecord.id}>
      {patient => {
        const {
          birthDate,
          dateOfDeath,
          primaryCommunication,
          id,
          nameWithTitle,
          firstName,
          lastName,
          titleRef,
          nickName
        } = patient;

        const ageDescription =
          birthDate &&
          `${getAgeString(
            birthDate,
            dateOfDeath
          )} (${birthDate.toDayDefaultFormat()})`;

        const hasPreferredComms = isPhone(core, primaryCommunication);

        const primaryComms =
          primaryCommunication &&
          formatCommunication(primaryCommunication, core).value;

        const intakeOptions = clinical.ref.intakeStatuses?.keyTextValues;

        const patientNameWithTitle = () => {
          if (!nickName) return nameWithTitle;
          return (
            <>
              {titleRef?.text && <>{titleRef?.text} </>}
              {nickName}{" "}
              <span style={{ fontWeight: FontWeights.regular }}>
                ({firstName})
              </span>{" "}
              {lastName}
            </>
          );
        };

        return (
          <Stack
            styles={{ root: { minHeight: 100 } }}
            tokens={{ childrenGap: 8 }}
          >
            <Persona
              id={id}
              contactType={patient.type}
              imageInitials={patient.initials}
              imageUrl={patient.profilePictureUrl}
              size={PersonaSize.size48}
              styles={{
                details: { paddingRight: 0, display: "block" },
                root: {
                  height: "auto",
                  width: "100%",
                  paddingLeft: 4,
                  paddingTop: 4,
                  alignItems: "top",
                  flex: "auto"
                }
              }}
              onRenderPrimaryText={() => (
                <Stack>
                  <Stack horizontal>
                    <StackItem
                      styles={mergeStyleSets(
                        { root: { fontWeight: FontWeights.semibold } },
                        { root: getColumnClampLinesSelector(1) }
                      )}
                    >
                      {patientNameWithTitle()}
                    </StackItem>
                    <When
                      permission={[
                        Permission.ContactRead,
                        Permission.ContactWrite,
                        Permission.PatientRead,
                        Permission.PatientWrite
                      ]}
                      permissionOperator="and"
                    >
                      <Stack.Item>
                        <TooltipHost
                          content="Edit"
                          styles={{ root: { marginLeft: 8 } }}
                        >
                          <IconButton
                            iconProps={{ iconName: "Edit" }}
                            styles={{
                              root: {
                                padding: 0,
                                width: 20,
                                height: 20,
                                cursor: "pointer"
                              },
                              icon: {
                                margin: 0,
                                color: theme.palette.neutralPrimary,
                                fontSize: theme.fonts.medium.fontSize
                              }
                            }}
                            onClick={onHandleEditModal(
                              PatientCardIds.patientHeader,
                              id
                            )}
                          />
                        </TooltipHost>
                      </Stack.Item>
                    </When>
                  </Stack>
                  <Stack>
                    <StackItem>
                      <FontIcon
                        iconName="CalendarMirrored"
                        styles={{ root: { paddingRight: 4 } }}
                      />

                      <Navigate
                        onClick={onHandleEditModal(
                          PatientCardIds.patientHeader,
                          id
                        )}
                      >
                        {ageDescription || "No DOB recorded"}
                      </Navigate>
                    </StackItem>
                    <StackItem>
                      <FontIcon
                        iconName="Phone"
                        styles={{ root: { paddingRight: 4 } }}
                      />
                      <Navigate
                        onClick={onHandleEditModal(
                          PatientCardIds.contactMethods,
                          id
                        )}
                      >
                        {hasPreferredComms
                          ? primaryComms
                          : "Phone not recorded"}
                      </Navigate>
                    </StackItem>
                  </Stack>
                </Stack>
              )}
            />
            <PatientHeaderTags
              onClinicalDataLinkClick={onClinicalDataLinkClick}
              intakeOptions={intakeOptions}
              clinicalRecord={clinicalRecord}
              onHandleEditModal={onHandleEditModal}
              patient={patient}
            />
          </Stack>
        );
      }}
    </ContactFetcher>
  );
};

export const PersonaDetails = withFetch(
  x => x.clinical.ref.intakeStatuses.load(),
  PersonaDetailsBase
);
