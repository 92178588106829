import { CollapsibleCard } from "@bps/fluent-ui";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";

export const OtherMovementsCard: React.FC = () => {
  return (
    <div id={SotapMskId.OtherMovements}>
      <CollapsibleCard
        heading={SotapMskLabel.OtherMovements}
        headingLevel="section-heading"
        iconName="Rotate90Clockwise"
        openOnRender
      >
        <TextInputField
          name={sotapNameOf("otherMovements")}
          multiline
          rows={3}
          autoAdjustHeight
          resizable={false}
          styles={{
            fieldGroup: { minHeight: 60 }
          }}
        />
      </CollapsibleCard>
    </div>
  );
};
