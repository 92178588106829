import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton, Dialog, NativeList, Stack } from "@bps/fluent-ui";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { EncounterType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { EncounterFormContext } from "./models/EncounterFormContext.ts";

export const UnsavedFormsDialog: React.FC = observer(() => {
  const {
    closeUnsavedFormsDialog,
    isUnsavedFormsDialogVisible,
    clinicalRecord,
    notesCompleted
  } = useContext(EncounterFormContext);

  const { clinical } = useStores();
  const unsavedForms = clinical.ui.tabs.currentPatientRecordTab?.dirtyTabs;

  const appointmentState =
    clinicalRecord.openEncounter?.calendarEvent?.appointmentStatus;

  let unsavedNotesText: string = "";
  let unsavedNotesTitle: string = "";
  if (
    appointmentState &&
    (appointmentState === AppointmentStatusCode.Finalised ||
      appointmentState === AppointmentStatusCode.Completed)
  ) {
    unsavedNotesText = "being saved";
    unsavedNotesTitle = "save";
  } else {
    unsavedNotesText = "ending";
    unsavedNotesTitle = "end";
  }

  let titleText = notesCompleted ? "finalise" : unsavedNotesTitle;
  let encounterType = "consult";
  let closeText = notesCompleted ? "being finalised" : unsavedNotesText;

  if (clinicalRecord.openEncounter?.type === EncounterType.RecordUpdate) {
    titleText = "save";
    encounterType = "record update";
    closeText = "saved";
  }

  const getListItems = () => {
    if (unsavedForms) {
      return unsavedForms.map(x => <li key={x.title}>{x.title}</li>);
    }
    return undefined;
  };

  return (
    <Dialog
      minWidth={600}
      hidden={!isUnsavedFormsDialogVisible}
      dialogContentProps={{
        title: `Unable to ${titleText} the ${encounterType}`
      }}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        <Stack.Item>
          {`There are forms with unsaved content preventing the ${encounterType} ${closeText}`}
        </Stack.Item>
        <NativeList>{getListItems()}</NativeList>
        <Stack horizontalAlign="end">
          <DefaultButton onClick={closeUnsavedFormsDialog}>Close</DefaultButton>
        </Stack>
      </Stack>
    </Dialog>
  );
});
