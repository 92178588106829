import { memo } from "react";

import {
  dataAttribute,
  DataAttributes,
  HideStack,
  Stack
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { RenderItemProps } from "../AllocationList/AllocationListBase.types.tsx";
import { useAllocationField } from "./hooks/useAllocationField.ts";
import {
  AllocationDataAttributes,
  allocationFieldNameOf,
  AllocationFieldValues,
  ItemSelectionMode
} from "./types.ts";

interface InvoiceNumberCellProps {
  item: AllocationFieldValues;
  itemIndex: number;
  renderItem: RenderItemProps;
}

export const InvoiceNumberCell: React.FC<InvoiceNumberCellProps> = memo(
  ({ item, itemIndex, renderItem }) => {
    const {
      allocateItem,
      allocationOption,
      allocatableAmount,
      allocatedTotal,
      allocations
    } = useAllocationField();

    const { routing } = useStores();
    const nothingToAllocate =
      allocatableAmount !== undefined
        ? allocatedTotal >= allocatableAmount
        : false;

    const filteredAllocations = allocations.filter(
      a => a.invoiceNumber === item.invoiceNumber
    );

    const matchIndex = filteredAllocations.findIndex(
      a => a.invoiceItemId === item.invoiceItemId
    );

    const Link = renderItem.link({
      path: {
        pathname: routes.accounts.invoices.invoice.path({
          id: item.invoiceId
        }),
        state: routing.mergeRouteStates(routing.getStateWithFromQuery())
      },
      value:
        filteredAllocations.length === 1 || matchIndex === 0
          ? item.invoiceNumber
          : "",
      styles: () => ({ textAlign: "right" })
    });

    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        {...dataAttribute(
          DataAttributes.Element,
          `${AllocationDataAttributes.invoiceNumberCell}-${itemIndex}`
        )}
      >
        <HideStack
          when={allocationOption?.itemSelectionMode === ItemSelectionMode.none}
        >
          {renderItem.checkbox({
            index: itemIndex,
            name: allocationFieldNameOf("checked"),
            disabled:
              // disabled checkboxes if nothing to allocate
              Number(item.total) === 0 && nothingToAllocate,
            onChange: (checked: boolean, _, index: number) => {
              allocateItem({
                item,
                checked,
                index
              });
            }
          })}
        </HideStack>

        {Link}
      </Stack>
    );
  }
);
