import { DragEventHandler } from "react";

import {
  ITextProps,
  mergeStyleSets,
  Stack,
  Text,
  Tile,
  TileProps,
  TileStyles,
  useTheme
} from "@bps/fluent-ui";

interface TextProps {
  text: string | JSX.Element;
  styles?: ITextProps["styles"];
}

interface DropZoneProps extends TileProps {
  styles?: Partial<TileStyles>;
  textProps: TextProps;
  invalid?: boolean;
  onDrop: () => void;
  id?: string;
}

const doDragOver: DragEventHandler<HTMLDivElement> = event => {
  event.preventDefault();
};
export const DropZone: React.FunctionComponent<DropZoneProps> = ({
  invalid,
  textProps,
  styles,
  onDrop
}) => {
  const theme = useTheme();
  const tileStyles = {
    root: {
      justifyContent: "center",
      padding: 16,
      minHeight: 90,
      borderWidth: 2,
      borderStyle: "dashed",
      borderColor: invalid
        ? theme.semanticColors.errorText
        : theme.palette.themeLight
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  };

  const textStyles = {
    root: {
      fontSize: theme.fonts.medium.fontSize,
      fontStyle: "italic",
      textAlign: "center",
      color: invalid
        ? theme.semanticColors.errorText
        : theme.palette.neutralTertiary
    }
  };
  return (
    <div onDragOver={doDragOver} onDrop={onDrop} style={{ width: "100%" }}>
      <Tile styles={mergeStyleSets(tileStyles, styles)}>
        <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
          <Text styles={mergeStyleSets(textStyles, textProps.styles)}>
            {textProps.text}
          </Text>
        </Stack>
      </Tile>
    </div>
  );
};
