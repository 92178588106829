import { Accordion, AccordionItem } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DermatomeFields } from "./DermatomeFields.tsx";
import {
  DermatomeTest,
  dermMyotomesAndReflexesNameOf,
  Labels
} from "./DermatomesAndMyotomesForm.types.ts";

interface DermatomeAccordianProps {
  testType: DermatomeTest;
}
const DermatomeAccordianBase: React.FC<DermatomeAccordianProps> = ({
  testType
}) => {
  const { clinical } = useStores();

  const trigeminalOptions = clinical.ref.nerves.values
    .filter(x => x.trigeminal)
    .map(x => x.code);

  const cervicalOptions = clinical.ref.nerves.values
    .filter(x => x.cervical)
    .map(x => x.code);

  const thoracicOptions = clinical.ref.nerves.values
    .filter(x => x.thoracic)
    .map(x => x.code);

  const lumbosacralOptions = clinical.ref.nerves.values
    .filter(x => x.lumbosacral)
    .map(x => x.code);
  return (
    <Accordion
      disableAnimation
      noBorders
      multiple
      styles={{ root: { marginTop: 8 } }}
    >
      <AccordionItem extendedByDefault={true} title={Labels.Trigeminal}>
        <DermatomeFields
          name={dermMyotomesAndReflexesNameOf("trigeminalDermatomes")}
          testType={testType}
          options={trigeminalOptions}
        />
      </AccordionItem>
      <AccordionItem extendedByDefault={true} title={Labels.Cervical}>
        <DermatomeFields
          name={dermMyotomesAndReflexesNameOf("cervicalDermatomes")}
          testType={testType}
          options={cervicalOptions}
        />
      </AccordionItem>
      <AccordionItem extendedByDefault={true} title={Labels.Thoracic}>
        <DermatomeFields
          name={dermMyotomesAndReflexesNameOf("thoracicDermatomes")}
          testType={testType}
          options={thoracicOptions}
        />
      </AccordionItem>
      <AccordionItem extendedByDefault={true} title={Labels.Lumbosacral}>
        <DermatomeFields
          name={dermMyotomesAndReflexesNameOf("lumbosacralDermatomes")}
          testType={testType}
          options={lumbosacralOptions}
        />
      </AccordionItem>
    </Accordion>
  );
};

export const DermatomeAccordian = withFetch(
  x => [x.clinical.ref.nerves.load()],
  DermatomeAccordianBase
);
