import { ReactNode } from "react";

import { AccTerminologyDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  getClaimAdjustmentTerminologies,
  getClaimTerminologies
} from "../utils.ts";

type ClaimDataFetcherProps = {
  claimId?: string;
  claimAdjustmentId?: string;
  fetchPatient?: boolean;
  fetchProvider?: boolean;
  fetchOrgUnits?: boolean;
  fallback?: ReactNode;
  refetchId?: string;
  children: (claim: Claim) => React.ReactNode;
};

export const ClaimDataFetcher: React.FC<ClaimDataFetcherProps> = props => {
  const { children, refetchId } = props;
  const getData = async (root: RootStore): Promise<Claim> => {
    let claimAdjustment: ClaimAdjustment | undefined;

    if (props.claimAdjustmentId) {
      claimAdjustment = await root.acc.getClaimAdjustment(
        props.claimAdjustmentId
      );
    }

    const claimId = props.claimId ?? claimAdjustment?.claimId;

    if (!claimId) {
      throw new Error("Unable to load a claim");
    }

    //Temporary solution until accLiteDto is introduced

    const claim = await root.acc.getClaim(claimId, {
      ignoreCache: true
    });

    const claimTerminologies = getClaimTerminologies(claim);

    const claimAdjustmentTerminologies = Array.from(
      claim.claimAdjustmentMap.values()
    )
      .map(getClaimAdjustmentTerminologies)
      .flat();

    const terminologies: AccTerminologyDto[] = [
      ...claimTerminologies,
      ...claimAdjustmentTerminologies
    ];

    terminologies.map(root.acc.mergeAccTerminologyMap);

    const promises = [];

    if (props.fetchPatient) {
      promises.push(claim.loadPatient());
    }
    if (props.fetchProvider) {
      promises.push(claim.loadProvider());
      promises.push(claim.loadUser());
      if (props.claimAdjustmentId) {
        const ca = claim.claimAdjustments.find(
          x => x.id === props.claimAdjustmentId
        );
        if (ca) {
          promises.push(ca.loadProvider());
        }
      }
    }

    if (props.fetchOrgUnits) {
      // this data gets used in Claim.getOrgUnitData
      promises.push(
        root.core.location.parentOrgUnitId
          ? root.practice.getOrgUnit(root.core.location.parentOrgUnitId)
          : undefined,
        claim.loadCalendarEvent().then(async () => {
          const calendarEvent = claim.initialCalendarEvent;
          if (!calendarEvent) return;

          await Promise.all([
            root.core.getOrgUnit(calendarEvent.orgUnitId),
            root.practice.getOrgUnit(calendarEvent.orgUnitId)
          ]);
        })
      );
    }

    await Promise.all(promises);
    return claim;
  };

  return (
    <DataFetcher<Claim>
      fallback={props.fallback}
      fetch={getData}
      refetchId={refetchId}
    >
      {claim => children(claim)}
    </DataFetcher>
  );
};
