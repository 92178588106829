import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { CenteredLargeSpinner } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { ScheduleScreenHelper } from "@modules/settings/screens/schedules/context/ScheduleScreenHelper.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { FeeFormDialog } from "./components/fees/FeeFormDialog.tsx";
import { NewScheduleFormDialog } from "./components/NewScheduleFormDialog.tsx";
import { ScheduleFormDialog } from "./components/ScheduleFormDialog.tsx";

export const SchedulesScreenBase: React.FC = observer(() => {
  return (
    <>
      <Outlet />
      <NewScheduleFormDialog />
      <ScheduleFormDialog />
      <FeeFormDialog />
    </>
  );
});

export const SchedulesScreenDataFetcher: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const loadRequiredData = async (root: RootStore) => {
      const scheduleScreenHelper = new ScheduleScreenHelper(root);

      const scheduleId =
        root.routing.match(routes.settings.schedules.viewPath)?.params
          .scheduleId ??
        root.routing.match(routes.settings.schedules.feePath)?.params
          .scheduleId;

      const feeId = root.routing.match(routes.settings.schedules.feePath)
        ?.params.feeId;

      const [schedule, fee] = await Promise.all([
        scheduleId ? root.billing.getSchedule(scheduleId) : undefined,
        feeId ? root.billing.getService(feeId) : undefined,
        scheduleScreenHelper.loadInvoiceSettings(),
        // left refData prefetch here since we have DataFetcher already in this wrapper component to preload data
        // withFetch does the same
        root.practice.ref.contactStatuses.load()
      ]);

      runInAction(() => {
        scheduleScreenHelper.viewSchedule = schedule;
        scheduleScreenHelper.viewFee = fee;
      });

      return scheduleScreenHelper;
    };

    return (
      <DataFetcher<ScheduleScreenHelper>
        fetch={loadRequiredData}
        fallback={<CenteredLargeSpinner />}
      >
        {(helper: ScheduleScreenHelper) => (
          <ScheduleScreenContext.Provider value={helper}>
            {children}
          </ScheduleScreenContext.Provider>
        )}
      </DataFetcher>
    );
  }
);

const SchedulesScreen: React.FC = observer(() => {
  return (
    <SchedulesScreenDataFetcher>
      <SchedulesScreenBase />
    </SchedulesScreenDataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default SchedulesScreen;
