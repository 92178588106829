import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Link } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { RecentPatientsDetailsProps } from "./RecentPatientsList.types.ts";

export const RecentApptLink: FC<RecentPatientsDetailsProps> = observer(
  ({ recentPatient }) => {
    const { booking } = useStores();

    const handleOnClick = () => {
      booking.ui.showCalendarEventDialog({
        type: CalendarEventType.Appointment,
        id: recentPatient.typeId
      });
    };

    return (
      <DataFetcher
        fetch={() =>
          booking
            .getCalendarEvent(recentPatient.typeId)
            .catch(catchNotFoundError)
        }
      >
        {appointment => {
          if (!appointment) return <i>Unknown appointment</i>;

          return (
            <Link onClick={handleOnClick}>
              {`${appointment.startDateTime.toFormat(
                DATE_FORMATS.LONG_DATE_TIME_FORMAT
              )}, ${appointment.user?.name || ""}`}
            </Link>
          );
        }}
      </DataFetcher>
    );
  }
);
