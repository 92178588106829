import { DateTime } from "@bps/utils";
import {
  AddDocumentDto,
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  CorrespondenceVisibility,
  DocumentCreateOptions,
  DocumentDto,
  DocumentEnum,
  DocumentMetadataItem,
  DocumentSource,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AddUserAction,
  Instructions,
  NoActionUserActionContext,
  ReportTypeKeys,
  UserActionType,
  UserTaskDto,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { getNewMetadata } from "@modules/clinical/utils/clinical.utils.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { ClinicalImageFormValues } from "../clinical-form/DocumentBatchFormValues.ts";

const {
  Name,
  EncounterId,
  Extension,
  SecGroupId,
  ReportType,
  Outcome,
  CheckedBy,
  DateChecked
} = DocumentEnum;

export class ClinicalImageDialogHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root: RootStore
  ) {}

  getInitialValues = (clinicalImage?: ClinicalDocument) => {
    const checkedBy = this.clinicalRecord.openEncounter?.userId;
    const dateChecked = DateTime.now().toISO();

    if (clinicalImage) {
      const userTask = this.clinicalRecord.patientUserTasks.find(
        task => task.documentId === clinicalImage?.id
      );

      return {
        file: {
          subject: clinicalImage.name,
          date: clinicalImage.date?.toJSDate(),
          file: new File([], clinicalImage.name),
          name: clinicalImage.name
        },
        visibility: !!clinicalImage.secGroupId
          ? CorrespondenceVisibility.Confidential
          : undefined,
        outcome: userTask?.outcome,
        reportType: clinicalImage.reportType,
        checkedBy: clinicalImage.checkedBy ?? checkedBy,
        dateChecked: clinicalImage.dateChecked ?? dateChecked
      };
    } else return {};
  };

  submitNewClinicalImage = async (formValues: ClinicalImageFormValues) => {
    const { stagingPath, file, visibility, reportType, outcome } = formValues;

    const { id: encounterId, userId } = this.clinicalRecord.openEncounter || {};
    const { id: patientId } = this.clinicalRecord;
    if (!stagingPath) throw new Error("stagingPath not set");
    if (!encounterId) throw new Error("encounterId not set");

    const includeCheckedBy =
      reportType === ReportTypeKeys.acceptable ||
      reportType === ReportTypeKeys.unacceptable;

    const documentDateString = DateTime.jsDateToISODate(file?.date);

    const conf = visibility === CorrespondenceVisibility.Confidential;

    const metadataRecord: Record<string, string | undefined> = {
      [Name]: file?.subject,
      [DocumentEnum.Date]: documentDateString,
      [EncounterId]: encounterId,
      [Extension]: file?.extension,
      [ReportType]: reportType,
      [Outcome]: outcome
    };

    if (includeCheckedBy) {
      metadataRecord[CheckedBy] = userId;
      metadataRecord[DateChecked] = DateTime.now().toISODate();
    }

    const metadata: DocumentMetadataItem[] = getNewMetadata([], metadataRecord);

    const document: AddDocumentDto = {
      patientId,
      store: StoreType.ClinicalImages,
      metadata,
      id: file?.blobName,
      secGroupId: conf ? this.root.core.user?.privateSecGroupId : undefined,
      status: CorrespondenceStatus.Uploaded,
      direction: CorrespondenceDirection.In,
      type: CorrespondenceType.ClinicalPhotograph
    };

    const request: DocumentCreateOptions = {
      stagingId: stagingPath.stagingId,
      source: DocumentSource.Staging,
      documents: [document]
    };

    await this.root.correspondence.addDocuments(encounterId, request);
  };

  updateClinicalImage = async (
    formValue: ClinicalImageFormValues,
    clinicalImage: ClinicalDocument
  ) => {
    const { id: encounterId, userId } = this.clinicalRecord.openEncounter || {};
    const { id: patientId } = this.clinicalRecord;

    if (!encounterId) throw new Error("encounterId not set");
    if (!clinicalImage)
      throw new Error("You do not have any correspondece data to edit");

    const { file, visibility, reportType, outcome } = formValue;

    const includeCheckedBy =
      reportType !== ReportTypeKeys.unchecked &&
      this.getInitialValues(clinicalImage)?.reportType !== reportType;

    const confidential = visibility === CorrespondenceVisibility.Confidential;

    const documentDateString = DateTime.fromJSDate(file?.date)?.toISODate();

    const metadataRecord: Record<string, string | undefined> = {
      [Name]: file?.subject,
      [DocumentEnum.Date]: documentDateString,
      [EncounterId]: encounterId,
      [Extension]: file?.extension,
      [ReportType]: reportType,
      [Outcome]: outcome
    };

    if (includeCheckedBy) {
      metadataRecord[CheckedBy] = userId;
      metadataRecord[DateChecked] = DateTime.now().toISODate();
    } else {
      metadataRecord[CheckedBy] = clinicalImage.checkedBy;
      metadataRecord[DateChecked] = clinicalImage.dateChecked;
    }

    if (confidential) {
      metadataRecord[SecGroupId] = this.root.core.user?.privateSecGroupId;
    }

    const metadata: DocumentMetadataItem[] = getNewMetadata([], metadataRecord);

    const updateDocumentDto: DocumentDto = {
      id: clinicalImage.id,
      eTag: clinicalImage.eTag,
      patientId,
      status: clinicalImage.status,
      store: clinicalImage.store,
      direction: clinicalImage.direction,
      type: clinicalImage.type,
      metadata,
      secGroupId: confidential
        ? this.root.core.user?.privateSecGroupId
        : undefined
    };

    await this.root.correspondence.updateCorrespondence(
      encounterId,
      clinicalImage.id,
      updateDocumentDto
    );

    await this.upsertUserTask(clinicalImage, outcome);
  };

  upsertUserTask = async (
    correspondence: ClinicalDocument,
    outcome?: string
  ) => {
    if (!correspondence) {
      return;
    }

    const { id: patientId, patientUserTasks } = this.clinicalRecord;
    const userTask = patientUserTasks.find(
      task => task.documentId === correspondence.id
    );
    if (userTask) {
      await this.root.inbox.updateUserTask({
        ...userTask.dto,
        outcome
      });
    } else if (outcome) {
      const context: NoActionUserActionContext = {
        userTask: {
          instructionCode: Instructions.NoAction,
          status: UserTaskStatus.Completed,
          outcome
        } as UserTaskDto,
        patientKey: {
          patientId,
          documentId: correspondence.id
        }
      };

      const userAction: AddUserAction = {
        userActionType: UserActionType.NoAction,
        context
      };

      await this.root.inbox.addUserAction(userAction);
    }
  };
}
