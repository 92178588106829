import { observer } from "mobx-react-lite";
import { useRef } from "react";

import { IBreadcrumbItem, Spinner, SpinnerSize, Stack } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { AccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { AccountScreenHelper } from "@modules/billing/screens/account/context/AccountScreenHelper.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import { BillingBreadcrumbsKeys } from "../shared-components/BillingBreadcrumbs.tsx";
import { AccountHeader } from "./components/AccountHeader.tsx";
import { AccountsList } from "./components/AccountsList.tsx";

interface AccountScreenBaseProps {
  contact: Contact;
  balance: AccountBalance;
}

const AccountScreenBase: React.FC<AccountScreenBaseProps> = observer(
  ({ balance, contact }) => {
    const root = useStores();
    const breadcrumbRoutes: IBreadcrumbItem[] = [
      {
        onClick: () =>
          root.routing.push({
            pathname: routes.accounts.basePath.pattern,
            search: root.routing.location.state?.accountsFilter || ""
          }),
        text: "Accounts",
        key: BillingBreadcrumbsKeys.accounts.toString()
      },
      {
        text: contact.name || "",
        key: BillingBreadcrumbsKeys.accountHistory.toString(),
        isCurrentItem: true
      }
    ];

    const accountScreenHelper = useRef(new AccountScreenHelper(root, contact));

    return (
      <Stack
        tokens={{ childrenGap: 16 }}
        styles={{
          root: {
            height: "100%",
            maxWidth: 1500,
            margin: "0 auto"
          }
        }}
      >
        <AccountScreenContext.Provider value={accountScreenHelper.current}>
          <BreadCrumb routes={breadcrumbRoutes} />
          <AccountHeader balance={balance} />
          <AccountsList />
        </AccountScreenContext.Provider>
      </Stack>
    );
  }
);

const AccountScreenDataFetcher: React.FC = () => {
  const { routing } = useStores();
  const accountId: string = routing.match(routes.accounts.account)?.params.id!;

  const fetch = async (root: RootStore) => {
    const [contact, balance] = await Promise.all([
      root.practice.getContact(accountId, { includeContactPreferences: true }),
      root.billing.getAccountBalanceForAccountId(accountId)
    ]);
    return { contact, balance };
  };

  return (
    <DataFetcher<{ contact: Contact; balance: AccountBalance }>
      key={accountId}
      fetch={fetch}
      fallback={<Spinner size={SpinnerSize.large} />}
    >
      {({ contact, balance }) => (
        <AccountScreenBase balance={balance} contact={contact} />
      )}
    </DataFetcher>
  );
};

const AccountScreen = withFetch(
  x => [x.practice.ref.invoiceStatuses.load()],
  AccountScreenDataFetcher
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default AccountScreen;
