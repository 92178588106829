import { FunctionComponent } from "react";

import { DateSettings, DateTime, Duration, TIME_FORMATS } from "@bps/utils";

interface BookingEventTimeLocalProps {
  startTime: DateTime;
  endTime: DateTime;
}

export const BookingEventTimeLocal: FunctionComponent<
  BookingEventTimeLocalProps
> = ({ startTime, endTime }) => {
  const localStartTime = startTime
    .setZone(DateSettings.systemTimeZone)
    .toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

  const localEndTime = endTime
    .setZone(DateSettings.systemTimeZone)
    .toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

  const offsetInMinutes = -DateTime.jsDateNow().getTimezoneOffset();
  const sign = offsetInMinutes > 0 ? "+" : "-";
  const offset = Duration.fromObject({ minutes: offsetInMinutes }).toFormat(
    "hh:mm"
  );

  const utcOffset = `UTC${sign}${offset}`;

  return (
    <span>
      {`${localStartTime} - ${localEndTime}`} {`(${utcOffset})`}
    </span>
  );
};
