import { observer } from "mobx-react-lite";

import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DocumentPillComponentBase } from "./DocumentPillComponentBase.tsx";

interface DocumentPillProps {
  loading?: boolean;
  active?: boolean;
  clinDocument?: ClinicalDocument;
  patient?: Contact;
  onClick?: () => void;
  onClose?: () => void;
}

const DocumentPillBase: React.FunctionComponent<DocumentPillProps> = observer(
  ({ loading, active, clinDocument, patient, onClick, onClose }) => {
    const handleClick = () => {
      if (onClick && clinDocument) {
        onClick();
      }
    };

    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (onClose && clinDocument) {
        return onClose();
      }
    };

    const documentType = clinDocument ? clinDocument?.type : undefined;

    return (
      <DocumentPillComponentBase
        loading={loading}
        active={active}
        patient={patient}
        handleClick={handleClick}
        handleClose={handleClose}
        documentType={documentType as CorrespondenceType}
        title={clinDocument?.name}
      />
    );
  }
);

export const DocumentPill = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  DocumentPillBase
);
