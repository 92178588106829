import { memo } from "react";

import { dataAttribute, DataAttributes, Stack } from "@bps/fluent-ui";
import { BillType } from "@libs/gateways/billing/BillingGateway.dtos.ts";

import { ServiceableItem } from "./AddServicesModal.types.ts";
import { InvoiceDetails } from "./InvoiceDetails.tsx";
import { PatientDetails } from "./PatientDetails.tsx";
import { ProviderDetails } from "./ProviderDetails.tsx";

interface InvoiceSummaryProps {
  patientId?: string;
  userId?: string;
  accountContactId?: string;
  billType?: BillType;
  invoiceNumber: string;
  invoiceDate: Date;
  kind: ServiceableItem.Invoice;
  locationId?: string;
}

interface DraftItemsSummaryProps {
  patientId?: string;
  userId?: string;
  kind: ServiceableItem.DraftItem;
  locationId?: string;
}

// NOTE: These props are split up to all the union types to passed through the system
// correctly. Including core in the union type currently causes
// issues with MobXs inject functionality
export type ServiceableItemSummaryProps = {
  summaryProps: InvoiceSummaryProps | DraftItemsSummaryProps;
};

export const ServiceableItemSummary: React.FunctionComponent<ServiceableItemSummaryProps> =
  memo(props => {
    const summaryProps = props.summaryProps;
    const patientPersonaWidth =
      summaryProps.kind === ServiceableItem.Invoice ? "30%" : "50%";

    const providerPersonaWidth =
      summaryProps.kind === ServiceableItem.Invoice ? "30%" : "50%";

    const invoiceDetailsIfInvoice = () => {
      if (summaryProps.kind === ServiceableItem.DraftItem) {
        return null;
      } else {
        return (
          <InvoiceDetails
            accountContactId={summaryProps.accountContactId}
            billType={summaryProps.billType}
            invoiceNumber={summaryProps.invoiceNumber}
            invoiceDate={summaryProps.invoiceDate}
            wrapperStyles={{ width: "34%" }}
          />
        );
      }
    };

    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        {...dataAttribute(DataAttributes.Element, "serviceable-item-summary")}
        styles={{ root: { marginTop: 10 } }}
      >
        <PatientDetails
          patientId={summaryProps.patientId}
          additionalStyles={{ width: patientPersonaWidth }}
        />

        <ProviderDetails
          providerId={summaryProps.userId}
          locationId={summaryProps.locationId}
          additionalStyles={{ width: providerPersonaWidth }}
        />

        {invoiceDetailsIfInvoice()}
      </Stack>
    );
  });
