import {
  FontIcon,
  FontSizes,
  IStackStyles,
  Stack,
  TextBadge,
  TextBadgeColor,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

enum ClaimsNonStandardBadgeLabels {
  badgeText = "Non-standard",
  tooltipContent = "Non-standard codes require extra information in the ACC32 request"
}
interface ClaimsNonStandardBadgeProps {
  styles?: IStackStyles;
}
/**
 * ClaimsNonStandardBadge renders a badge followed by a tooltip.
 */
export const ClaimsNonStandardBadge: React.FC<ClaimsNonStandardBadgeProps> = ({
  styles
}) => {
  const {
    palette: { themePrimary }
  } = useTheme();
  return (
    <Stack horizontal styles={styles} tokens={{ childrenGap: 8 }}>
      <TextBadge noTextWrap badgeColor={TextBadgeColor.yellow}>
        {ClaimsNonStandardBadgeLabels.badgeText}
      </TextBadge>
      <Stack horizontalAlign="center" verticalAlign="center">
        <TooltipHost content={ClaimsNonStandardBadgeLabels.tooltipContent}>
          <FontIcon
            styles={{
              root: {
                fontSize: FontSizes.size14,
                borderRadius: "50%",
                border: `1px solid ${themePrimary}`,
                color: themePrimary
              }
            }}
            iconName="StatusCircleQuestionMark"
          />
        </TooltipHost>
      </Stack>
    </Stack>
  );
};
