import { Grid, Stack } from "@bps/fluent-ui";

import { LARGE_BREAKPOINT, MEDIUM_BREAKPOINT } from "./utils.ts";

interface DiagramWrapperProps {
  children?: React.ReactNode;
  compactedCanvas: boolean;
  showDiagramEditorArea: boolean;
  panelWidth: number;
  setElement: React.Dispatch<
    React.SetStateAction<HTMLElement | null | undefined>
  >;
  element: HTMLElement | null | undefined;
}

export const DiagramWrapper: React.FC<DiagramWrapperProps> = ({
  children,
  compactedCanvas,
  showDiagramEditorArea,
  panelWidth,
  setElement,
  element
}) => {
  const gridTemplateColumns = () => {
    if (!showDiagramEditorArea) {
      return "1fr";
    }
    let gridLayout;
    switch (true) {
      case panelWidth <= MEDIUM_BREAKPOINT:
        gridLayout = "1fr";
        break;

      case compactedCanvas &&
        panelWidth > MEDIUM_BREAKPOINT &&
        panelWidth < LARGE_BREAKPOINT:
        gridLayout = "1.5fr 5fr";
        break;

      case panelWidth > MEDIUM_BREAKPOINT &&
        panelWidth < LARGE_BREAKPOINT &&
        !compactedCanvas:
        gridLayout = "5fr 2fr";
        break;

      case compactedCanvas && panelWidth >= LARGE_BREAKPOINT:
        gridLayout = "2fr 5fr";
        break;

      case panelWidth >= LARGE_BREAKPOINT:
        gridLayout = "4fr 3fr";
        break;

      default:
        gridLayout = "1fr";
        break;
    }

    return gridLayout;
  };

  return (
    <div
      ref={r => {
        if (r && !element) {
          setElement(r);
        }
      }}
      style={{ height: "100%" }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 16 }}
        styles={{ root: { height: "100%" } }}
      >
        <Grid
          childrenTokens={{ gap: 16, minWidth: 0 }}
          styles={{
            root: {
              gridTemplateColumns: gridTemplateColumns(),
              height: "100%",
              width: "100%",
              transition: "300ms"
            }
          }}
        >
          {children}
        </Grid>
      </Stack>
    </div>
  );
};
