import {
  isDateBeforeField,
  isNotFutureDate,
  Length,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

export class CurrentInjuryCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();

    this.forField(sotapNameOf("dateOfInjury"), [
      isNotFutureDate(ValidationMessages.futureDateEqualOrLess)
    ]);
    this.forField(sotapNameOf("dateOfSurgery"), [
      isDateBeforeField(
        ValidationMessages.dateLaterThanDateOfInjury,
        "dateOfInjury"
      )
    ]);
    this.forField(sotapNameOf("mechanismOfInjury"), [
      maxLength(Length.comments)
    ]);
    this.forField(sotapNameOf("currentHistory"), [maxLength(Length.comments)]);
    this.forField(sotapNameOf("investigationsComment"), [
      maxLength(Length.comments)
    ]);
    this.forField(sotapNameOf("naturesComment"), [maxLength(Length.comments)]);
    this.forField(sotapNameOf("aggravatingFactorsComment"), [
      maxLength(Length.comments)
    ]);
    this.forField(sotapNameOf("easingFactorsComment"), [
      maxLength(Length.comments)
    ]);
  }
}
