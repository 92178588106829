import { action, observable, runInAction } from "mobx";

import { clone } from "@bps/utils";
import { getBlobInBase64 } from "@libs/api/utils/blob-service.utils.ts";
import {
  TemplateDataModelDataNodeDto,
  TemplateDataModelDto,
  TemplateDataModelNodeDto,
  TemplateDataModelNodeType,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";

import { normalizedImageSize } from "../../utils.ts";
import { SyncFusionMergeFieldsFilterState } from "../SyncFusionMergeFieldsFilter.tsx";

export interface TemplateNode {
  text?: string;
  value?: string;
  path: string;
  nodeType?: TemplateDataModelNodeType;
}

export interface Group {
  key: string;
  name: string;
  startIndex: number;
  count: number;
  isCollapsed: boolean;
}

export interface GroupItem {
  items: TemplateNode[];
  group: Group;
}

export interface ContextOption {
  patientId?: string;
  userId?: string;
  encounterId?: string;
  contactId?: string;
  orgUnitId?: string;
}

export class SyncFusionDocumentEditorHelper {
  constructor(
    private root: RootStore,
    public isTemplateEditor?: boolean
  ) {}

  @observable
  sidePanelSize: TreeViewSize = TreeViewSize.Default;

  @observable
  filteredItems: TemplateNode[] = [];

  @observable
  filteredGroups: Group[] = [];

  @observable
  filterText: string | undefined;

  @observable
  contextOption: ContextOption;
  documentEditor: DocumentEditor | undefined;
  groups: Group[];

  private templateDataModel: TemplateDataModelDto;
  private mergeFieldData: TemplateNode[] = [];
  private groupItems: GroupItem[];

  @action
  setContextOption = (newContextOption: ContextOption) => {
    this.contextOption = newContextOption;
  };

  @action
  toggleSidePanel = (isExpanding: boolean) => {
    this.sidePanelSize = isExpanding
      ? TreeViewSize.Default
      : TreeViewSize.IconsOnly;
  };

  loadTemplateDataModelAndData = async () => {
    const { document, core } = this.root;
    const TEMPLATE_TYPE = "CLIN";
    const context: { [id: string]: string } = {};

    if (this.contextOption.userId) {
      context["UserId"] = this.contextOption.userId;
    }
    if (this.contextOption.patientId) {
      context["PatientId"] = this.contextOption.patientId;
    }
    if (this.contextOption.encounterId)
      context["EncounterId"] = this.contextOption.encounterId;

    if (this.contextOption.contactId)
      context["ContactId"] = this.contextOption.contactId;

    if (this.contextOption.orgUnitId)
      context["PracticeOrgUnitId"] = this.contextOption.orgUnitId;

    const options: TemplateRenderOptions = {
      context,
      isPreview: this.isTemplateEditor
    };

    // propertyPath has not been implemented in the back-end at the time of writing and it can be any string.
    const [templateDataModel, templateDataModelData] = await Promise.all([
      document.getTemplateDataModel(TEMPLATE_TYPE, options),
      document.getTemplateDataModelData(TEMPLATE_TYPE, "name", options)
    ]);

    // Temporary(?) removal of clinical merge fields category for beta, Filter out Contact merge fileds when contactId is not provided and is not in TemplateEditor
    // (Temporary) Filter out unavailable merge fields (MiddleName, Title) in Contact Node and will remove this filter once they become available.

    const filteredNodes = templateDataModel.nodes
      .filter(
        node =>
          node.property !== "Clinical" &&
          (!this.isTemplateEditor && !this.contextOption.contactId
            ? node.property !== "Contact"
            : true)
      )
      .map(item => {
        if (item.property === "Contact") {
          item.children?.map(item => {
            item.children = item.children?.filter(
              node =>
                !(
                  node.property &&
                  ["MiddleName", "Title"].includes(node.property)
                )
            );
            return item;
          });
        } else if (item.property === "User") {
          const { isNZTenant } = core;
          const newUserItem = { ...item };
          const children = item.children?.filter(
            c => !(c.property && isNZTenant && c.property === "ProviderNumber")
          );
          newUserItem.children = children;
          return newUserItem;
        }
        return item;
      });

    // sort merge fields alphabetically
    const sortedNodes = filteredNodes.sort((a, b) => {
      if (a.text && b.text) {
        return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
      }
      return 0;
    });

    this.templateDataModel = { ...templateDataModel, nodes: sortedNodes };

    this.mergeFieldData = templateDataModelData.nodes.reduce(
      (nodes: TemplateNode[], item: TemplateDataModelDataNodeDto) => {
        return [...nodes, ...this.getMergeFieldData(item, item.property)];
      },
      []
    );

    this.loadGroupsAndItems();
    this.onSearch();
  };

  onSearch = (values?: SyncFusionMergeFieldsFilterState) => {
    const groupItems = clone(this.groupItems);

    let filteredGroupItems = groupItems.filter(x => {
      if (!values) return true;

      const searchMatch =
        !values.search ||
        x.items.some(
          y => y.text?.toLowerCase().includes(values.search!.toLowerCase())
        ) ||
        x.group.name.toLowerCase().includes(values.search.toLowerCase());

      const categoriesMatch =
        !values.categories?.length || values.categories.includes(x.group?.key);

      if (searchMatch && categoriesMatch) return true;

      return false;
    });

    let startIndex = 0;

    filteredGroupItems.forEach(x => {
      const items = x.items.filter(y => {
        const searchResult =
          !values?.search ||
          x.group.name.toLowerCase().includes(values.search.toLowerCase()) ||
          y.text?.toLowerCase().includes(values.search.toLowerCase());

        const hideFieldsResult =
          !values?.hideFieldsWithNoData ||
          (values.hideFieldsWithNoData && y.value);

        if (searchResult && hideFieldsResult) return true;

        return false;
      });

      const group = this.filteredGroups.find(y => y.key === x.group.key);
      x.group.isCollapsed = group?.isCollapsed ?? false;

      x.items = items;
      x.group.startIndex = startIndex;
      x.group.count = items.length;
      startIndex += items.length;

      filteredGroupItems = filteredGroupItems.filter(x => x.group.count > 0);
    });

    const filteredItems = filteredGroupItems
      .map(m => m.items)
      .reduce((nodes: TemplateNode[], item: TemplateNode[]) => {
        return [...nodes, ...item];
      }, []);

    const filteredGroups = filteredGroupItems.map(x => x.group);

    runInAction(() => {
      this.filterText = values?.search;
      this.filteredItems = filteredItems;
      this.filteredGroups = filteredGroups;
    });
  };

  private loadGroupsAndItems = () => {
    let startIndex = 0;
    this.groupItems = this.templateDataModel.nodes.map(m => {
      const nodes = this.getMergeFieldData(m, m.property);

      const group: Group = {
        key: m.property ?? "",
        name: m.text ?? "",
        startIndex,
        count: nodes.length,
        isCollapsed: false
      };

      startIndex += nodes.length;

      const groupItem: GroupItem = {
        items: nodes,
        group
      };

      return groupItem;
    });

    this.groups = this.groupItems.map(x => x.group);
  };

  private getMergeFieldData = <
    T extends TemplateDataModelNodeDto & TemplateDataModelDataNodeDto
  >(
    node?: T,
    path: string = ""
  ): TemplateNode[] => {
    if (!node) return [];

    let nodes: TemplateNode[] = [];
    if (node.children && node.children.length > 0) {
      node.children?.map(m => {
        const delimiter = path.length ? "." : "";
        const result = this.getMergeFieldData(
          m,
          `${path}${delimiter}${m.property}`
        );
        if (result) nodes = [...nodes, ...result];
        return { nodes };
      });
    } else {
      nodes.push({
        text: node.text,
        value: node.text
          ? this.mergeFieldData.find(x => x.path === path)?.value
          : node.value,
        path,
        nodeType: node.nodeType
      });
    }
    return nodes;
  };

  handleMergeFieldOnClick = async (
    node: TemplateNode,
    isTemplateEditor: boolean
  ) => {
    if (isTemplateEditor) {
      this.handleTemplateMergeField(node.path);
    } else {
      await this.handleDocumentMergeField(node.nodeType, node.value);
    }
  };

  handleDocumentMergeField = async (
    nodeType?: TemplateDataModelNodeType,
    value?: string
  ) => {
    if (!value) return;
    if (nodeType === TemplateDataModelNodeType.Image) {
      try {
        const response = await getBlobInBase64(value);

        const image = new Image();
        if (response) {
          image.src = response;

          const maxValue =
            image.width > image.height ? image.width : image.height;

          const normalizedWidth = normalizedImageSize(
            image.width,
            maxValue,
            150
          );

          const normalizedHeight = normalizedImageSize(
            image.height,
            maxValue,
            150
          );

          this.documentEditor?.editor.insertImage(
            response,
            normalizedWidth,
            normalizedHeight
          );
        }
      } catch (error) {
        this.root.document.notification.error(error.message);
      }
    } else {
      this.documentEditor?.editor.insertText(value);
    }
  };

  handleTemplateMergeField = (path?: string) => {
    if (!path) return;

    const imageFieldCodes = [
      "PracticeOrgUnit.CompanyLogo",
      "User.SignatureUrl"
    ];

    const fieldCode: string = imageFieldCodes.includes(path)
      ? `MERGEFIELD Image:${path}  \\* MERGEFORMAT `
      : `MERGEFIELD  ${path}  \\* MERGEFORMAT `;

    const fieldResult: string = `«${path}»`;

    this.documentEditor?.editor.insertField(fieldCode, fieldResult);
  };

  loadAutofills = async () => {
    const autofills = await this.root.document.getAutofills();
    return autofills;
  };
}
