import { useForm } from "react-final-form";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { SideOfBody } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";

import {
  DermatomesMyotomesAndReflexesFormValues,
  dermMyotomesAndReflexesNameOf
} from "./DermatomesAndMyotomesForm.types.ts";
import { MyotomeFields } from "./MyotomeFields.tsx";

export const Myotomes: React.FC = () => {
  const { clinical } = useStores();
  const options = clinical.ref.nerves.values
    .filter(x => x.myotome)
    .map(x => {
      return { key: x.code, text: `${x.code} - ${x.text}` };
    });

  const form = useForm<DermatomesMyotomesAndReflexesFormValues>();

  const theme = useTheme();

  const updateMyotomeFields = (values: string[]) => {
    if (values && values.length > 0) {
      const myotomeFields = form.getState().values.myotomeFields;
      const currentMyotomeKeys = myotomeFields?.map(x => x.nerve);
      //add new nerves that have been added
      values.forEach(value => {
        if (!currentMyotomeKeys?.includes(value)) {
          form.mutators.push("myotomeFields", {
            nerve: value,
            strength: undefined,
            side: SideOfBody.Left,
            checked: true
          });
          form.mutators.push("myotomeFields", {
            nerve: value,
            strength: undefined,
            side: SideOfBody.Right,
            checked: true
          });
        }
      });
      //remove removed nerves
      currentMyotomeKeys?.forEach(key => {
        if (key) {
          if (!values.includes(key)) {
            const index = myotomeFields?.findIndex(x => x.nerve === key);
            if (index || index === 0) {
              form.mutators.remove("myotomeFields", index);
            }
          }
        }
      });
    } else {
      form.change("myotomeFields", []);
    }
  };

  const onRenderField = (options: { key: string; text: string }[]) => {
    if (options.length > 0) {
      const optionsFieldText = options.map(x => x.key).join(", ");
      return <Text>{optionsFieldText}</Text>;
    } else {
      return (
        <Text
          styles={{
            root: { color: theme.palette.neutralSecondary }
          }}
        >
          Select myotome(s)
        </Text>
      );
    }
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack grow>
        <OptionsSelectField
          name={dermMyotomesAndReflexesNameOf("myotomes")}
          options={options}
          placeholder="Select myotome(s)"
          label="Nerves"
          horizontalLabel
          hideSearchOption
          multiSelect
          showAllSelected
          onRenderFieldContent={onRenderField}
          fieldItemStyles={{ itemWrapper: { flexGrow: 1 } }}
        />
        <FieldSpy
          name={dermMyotomesAndReflexesNameOf("myotomes")}
          onChange={updateMyotomeFields}
        />
      </Stack>
      <MyotomeFields />
    </Stack>
  );
};
