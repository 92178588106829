import { observer } from "mobx-react-lite";
import { FC, MouseEventHandler } from "react";
import { Field } from "react-final-form";

import { Heading, Stack, Text, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserPickerField } from "@ui-components/pickers/user-picker/UserPickerField.tsx";

import { TasksScreenLabels } from "./Tasks.types.ts";

type AssignToPickerProps = {
  name: string;
  disabled: boolean;
  onEditClick: MouseEventHandler<HTMLElement>;
};

export const AssignToPickerField: FC<AssignToPickerProps> = observer(props => {
  const { onEditClick, disabled, name } = props;
  const theme = useTheme();

  const handleOnRenderLabel = () => {
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { width: "100%" } }}
      >
        <Heading
          labelPaddings
          styles={{
            root: {
              color: disabled ? theme.palette.neutralTertiary : undefined
            }
          }}
        >
          {TasksScreenLabels.AssignTo}
        </Heading>
        {disabled && (
          <Text
            onClick={onEditClick}
            styles={(_props, { palette }) => ({
              root: {
                color: palette.themePrimary,
                padding: "5px 0",
                cursor: "pointer"
              }
            })}
          >
            {TasksScreenLabels.Edit}
          </Text>
        )}
      </Stack>
    );
  };

  return (
    <Field name="user" subscription={{ value: true }}>
      {() => (
        <UserPickerField
          name={name}
          onRenderLabel={() => handleOnRenderLabel()}
          required={true}
          filter={{
            requiredPermissions: [Permission.FollowUpTasks]
          }}
          iconName="Search"
          disabled={disabled}
          inputProps={{ placeholder: TasksScreenLabels.Search }}
        />
      )}
    </Field>
  );
});
