import { Fragment } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IStyle,
  mergeStyles,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { BillType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";

export interface InvoiceDetailsProps {
  accountContactId?: string;
  billType?: BillType;
  invoiceNumber: string;
  invoiceDate: Date;
  wrapperStyles?: IStyle;
}

const invoiceDetailsStyles = mergeStyles({
  display: "grid",
  gridTemplateColumns: "max-content auto",
  columnGap: "8px",
  alignItems: "baseline"
});

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = (
  props
): JSX.Element => {
  const billToContactDescription = () => {
    if (props.accountContactId) {
      return props.billType === BillType.Patient
        ? BillType.Patient
        : "not yet implemented";
    } else {
      return <>&ndash;</>;
    }
  };

  const details: { label: string; text: string | JSX.Element }[] = [
    { label: "Bill to", text: billToContactDescription() },
    { label: "Invoice #", text: props.invoiceNumber },
    {
      label: "Invoice date",
      text: DateTime.fromJSDate(props.invoiceDate).toDayDefaultFormat()
    }
  ];

  return (
    <Stack.Item
      className={mergeStyles(props.wrapperStyles)}
      {...dataAttribute(DataAttributes.Element, "invoice-details")}
    >
      <Heading>{InvoiceFormLabels.invoiceDetails}</Heading>
      <div className={invoiceDetailsStyles}>
        {details.map(i => (
          <Fragment key={i.label}>
            <Text variant="small">{i.label}:</Text>
            <Text>{i.text}</Text>
          </Fragment>
        ))}
      </div>
    </Stack.Item>
  );
};
