import { createContext, useContext } from "react";

import { ClaimAdjustmentHelper } from "./ClaimAdjustmentHelper.ts";

export const ClaimAdjustmentContext = createContext<ClaimAdjustmentHelper>(
  {} as ClaimAdjustmentHelper
);

export const useClaimAdjustmentContext = () =>
  useContext(ClaimAdjustmentContext);
