import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useForm } from "react-final-form";

import { SplitButton } from "@bps/fluent-ui";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const AddEmployerButton = observer(() => {
  const { practice } = useStores();
  const {
    ui: { showEditContact }
  } = practice;

  const form = useForm<ClaimFormValues>();

  const { claim, onQuickAddSelected } = useContext(ClaimContext);

  const onUpdateEmployerSelected = () => {
    showEditContact(PatientCardIds.employers, claim.patient?.id || "");
  };

  return (
    <SplitButton
      iconProps={{ iconName: "Add" }}
      onClick={() => onQuickAddSelected(form)}
      items={[
        {
          key: "saved-employer",
          text: "Add a saved employer",
          onClick: onUpdateEmployerSelected
        },
        {
          key: "edit-employer",
          text: "Edit a saved employer",
          onClick: onUpdateEmployerSelected
        }
      ]}
    >
      Add quick employer
    </SplitButton>
  );
});
