import { newGuid } from "@bps/utils";
import {
  ContactType,
  RelationshipDto,
  RelationshipMetadataDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";

import { accountRelationships } from "../../../constants/account-relationships.constant.ts";
import { familyRelationships } from "../../../constants/family-relationships.constant.ts";
import { otherRelationships } from "../../../constants/other-relationships.constant.ts";
import { priorityRelationships } from "../../../constants/priority-relationships.constant.ts";
import { professionalRelationships } from "../../../constants/professional-relationships.constant.ts";

export type RelationshipFieldValue = {
  metadata?: RelationshipMetadataDto;
  relationship?: RelationshipType;
  professional: RelationshipType[];
  special: RelationshipType[];
  account: RelationshipType[];
  family: RelationshipType[];
  other: RelationshipType[];
  relatedContactId?: string;
  hasRelationship?: boolean;
  contactName?: string;
  type?: ContactType;
  isNew?: boolean;
  id: string;
};

export const toSingleRelationshipFieldValue = (
  relationship: RelationshipDto,
  types?: RelationshipType[],
  contactType?: ContactType
): RelationshipFieldValue => {
  return {
    metadata: relationship.metadata,
    relationship: relationship.relationship,
    professional:
      types?.filter(x => professionalRelationships.includes(x)) ?? [],
    special: types?.filter(x => priorityRelationships.includes(x)) ?? [],
    account: types?.filter(x => accountRelationships.includes(x)) ?? [],
    family: types?.filter(x => familyRelationships.includes(x)) ?? [],
    other: types?.filter(x => otherRelationships.includes(x)) ?? [],
    relatedContactId: relationship.relatedContactId,
    hasRelationship: relationship.hasRelationship,
    type: contactType,
    isNew: false,
    id: newGuid()
  };
};

export const toSingleRelationshipDto = (
  value: RelationshipFieldValue
): RelationshipDto => {
  return {
    relatedContactId: value.relatedContactId,
    relationship: value.relationship
      ? value.relationship
      : RelationshipType.General,
    hasRelationship: value.hasRelationship,
    metadata: value.metadata
  };
};

export const relationshipFromContactId = (params: {
  contactId: string | undefined;
  type: RelationshipType;
  metadata: RelationshipMetadataDto;
  contactType?: ContactType;
  types?: RelationshipType[];
}): RelationshipFieldValue => {
  const { contactId, type, metadata, types, contactType } = params;
  const dto: RelationshipDto = {
    relatedContactId: contactId,
    relationship: type,
    hasRelationship: true,
    metadata
  };
  return toSingleRelationshipFieldValue(dto, types, contactType);
};
