import { ValidationErrors } from "final-form";
import { useMemo } from "react";

import { IColumn, IStyle, mergeStyleSets } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { AllocationItemsColumns } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { getInvalidItemsIndexes } from "@modules/billing/screens/billing-history/utils.ts";
import { maxZero } from "@stores/billing/utils/billing.utils.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { AllocationListBase } from "../../AllocationList/AllocationListBase.tsx";
import { RenderItemProps } from "../../AllocationList/AllocationListBase.types.tsx";
import { allocationFieldNameOf, AllocationFieldValues } from "../types.ts";
import { getAllocationsTotal } from "../utils.tsx";
import { manualAllocationFormNameOf } from "./ManualAllocationForm.types.tsx";

interface ManualAllocationFieldProps {
  allocatableAmount: number;
}

export const ManualAllocationField: React.FunctionComponent<
  ManualAllocationFieldProps
> = ({ allocatableAmount }) => {
  const arrayApi = useFieldArray<AllocationFieldValues>("allocations");

  const { value: allocations } = arrayApi.fields;

  const getOwing = (item: AllocationFieldValues): string => {
    return currencyFormat(maxZero((item.owing || 0) - item.total), {
      currency: ""
    });
  };

  const isTotalDisabled = useMemo(() => {
    return getAllocationsTotal(allocations) >= Number(allocatableAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocations]);

  const getColumns = (renderItem: RenderItemProps): IColumn[] => {
    return [
      {
        name: AllocationItemsColumns.invoiceDate,
        key: allocationFieldNameOf("invoiceDate"),
        minWidth: 85,
        maxWidth: 85,
        onRender: (item: AllocationFieldValues) =>
          renderItem.text({
            value: DateTime.fromJSDate(item.invoiceDate).toDayDefaultFormat()
          })
      },
      {
        name: AllocationItemsColumns.invoiceNumber,
        key: allocationFieldNameOf("invoiceNumber"),
        minWidth: 60,
        maxWidth: 60,
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        }),
        onRender: (item: AllocationFieldValues) =>
          renderItem.text({
            value: item.invoiceNumber,
            styles: () => ({ textAlign: "right" }) as IStyle
          })
      },
      {
        name: AllocationItemsColumns.itemNumber,
        key: allocationFieldNameOf("code"),
        minWidth: 90,
        maxWidth: 90,
        onRender: (item: AllocationFieldValues) =>
          renderItem.text({
            value: item.code,
            styles: () => ({ textAlign: "right" }) as IStyle
          }),
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        })
      },
      {
        name: AllocationItemsColumns.owing,
        key: allocationFieldNameOf("owing"),
        minWidth: 70,
        maxWidth: 70,
        onRender: (item: AllocationFieldValues) =>
          renderItem.text({
            value: getOwing(item),
            styles: () => ({ textAlign: "right" }) as IStyle
          }),
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        })
      },
      {
        name: AllocationItemsColumns.allocation,
        key: allocationFieldNameOf("total"),
        minWidth: 40,
        maxWidth: 40,
        onRender: (item: AllocationFieldValues, index: number) => {
          return renderItem.input({
            index,
            name: allocationFieldNameOf("total"),
            precision: 2,
            // disabled text fields if nothing to allocate
            disabled: Number(item.total) === 0 && isTotalDisabled
          });
        },
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        })
      }
    ];
  };

  return (
    <AllocationListBase
      name={manualAllocationFormNameOf("allocations")}
      getColumns={getColumns}
      items={allocations}
      options={{
        invalidItemsIndexes: getInvalidItemsIndexes({
          errors: arrayApi.meta.error as undefined | ValidationErrors[],
          submitFailed: arrayApi.meta.submitFailed
        })
      }}
      onShouldVirtualize={() => false} // see Bug 27597 for explanation
    />
  );
};
