import { IContextualMenuItem, Spinner } from "@bps/fluent-ui";
import {
  FormsChannelType,
  FormTemplateDTO
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { SendFormConfirmationDialogProps } from "@shared-types/forms/send-form-confirmation-dialog-props.type.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  GetSubMenuItemsOptions,
  OnSendFormsClickOptions
} from "./form-context-menu.types.ts";

export const getClinicalFormContext: (options: {
  patientId: string | undefined;
}) => Record<string, string> | undefined = ({ patientId }) => {
  if (!patientId) {
    return undefined;
  }

  return { ClinicalPatientId: patientId };
};

export const getFormContext: (options: {
  claimId: string | undefined;
  patientId: string | undefined;
}) => Record<string, string> | undefined = ({ patientId, claimId }) => {
  if (!patientId) {
    return undefined;
  }

  const context: Record<string, string> = { PatientId: patientId };
  if (claimId) {
    context["ClaimId"] = claimId;
  }

  return context;
};

export const onSendFormsClick = async (options: OnSendFormsClickOptions) => {
  const {
    formTemplate,
    patientId,
    claimId,
    qrcode,
    addContextBeforeDeployAction,
    isClinical,
    formUi
  } = options;

  const context = isClinical
    ? getClinicalFormContext({ patientId })
    : getFormContext({ patientId, claimId });

  if (context) {
    if (!qrcode) {
      const props: SendFormConfirmationDialogProps = {
        context,
        formTemplate,
        addContextBeforeDeployAction: (selectedTemplate, currentContext) => {
          return addContextBeforeDeployAction
            ? addContextBeforeDeployAction(selectedTemplate, currentContext)
            : Promise.resolve(currentContext);
        }
      };
      formUi.setSendFormDialog(props);
    } else {
      const deployed = await formUi.forms.deployForm({
        context: addContextBeforeDeployAction
          ? await addContextBeforeDeployAction(formTemplate, context)
          : context,
        formTemplate,
        channelCode: FormsChannelType.QrCode
      });
      formUi.setSendFormDialog({
        addContextBeforeDeployAction,
        qrcode,
        formTemplate,
        context,
        hostingUrl: deployed?.hostingUrl
      });
    }
  }
};

export const getSubMenuItems = (
  options: GetSubMenuItemsOptions
): IContextualMenuItem[] => {
  const {
    patientId,
    claimId,
    addContextBeforeDeployAction,
    qrcode,
    formUi,
    clinicalTemplates,
    nonClinicalTemplates,
    setTemplates
  } = options;

  // if the templates have loaded, then display them in a list
  if (clinicalTemplates && nonClinicalTemplates) {
    const getMapTemplateToMenuItem =
      (isClinical: boolean) =>
      (template: FormTemplateDTO): IContextualMenuItem => {
        return {
          key: template.id,
          text: template.name,
          iconProps: {
            iconName: "ClipboardList",
            styles: ({ theme }) => ({
              root: {
                color: theme?.semanticColors.menuItemText
              }
            })
          },
          onClick: () => {
            onSendFormsClick({
              formTemplate: template,
              patientId,
              claimId,
              addContextBeforeDeployAction,
              isClinical,
              qrcode,
              formUi
            });
          }
        };
      };

    return [
      ...nonClinicalTemplates.map(getMapTemplateToMenuItem(false)),
      ...clinicalTemplates.map(getMapTemplateToMenuItem(true))
    ];
  }

  // if the templates haven't loaded, render a spinner and load them
  return [
    {
      key: "loading",
      text: "",
      onRenderContent: () => (
        <DataFetcher
          fetch={async ({ forms }) => {
            const clinicalFormContextStrings = Object.keys(
              getClinicalFormContext({ patientId }) ?? {}
            );

            const clinicalTemplatesPromise = forms.getTemplates({
              context: clinicalFormContextStrings
            });

            const nonClinicalFormContextStrings = Object.keys(
              getFormContext({ claimId, patientId }) ?? {}
            );

            const nonClinicalTemplatesPromise = forms.getTemplates({
              context: nonClinicalFormContextStrings
            });

            const [nonClinicalTemplates, clinicalTemplates] = await Promise.all(
              [nonClinicalTemplatesPromise, clinicalTemplatesPromise]
            );

            setTemplates({
              nonClinicalTemplates,
              clinicalTemplates
            });

            return;
          }}
          fallback={<Spinner styles={{ root: { margin: "0 auto" } }} />}
        >
          {() => null}
        </DataFetcher>
      )
    }
  ];
};
