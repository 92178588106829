import { FC } from "react";

import { getUniqueObjectsByKeys } from "@bps/utils";
import {
  InboxTasksFilterValues,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import { TasksScreenLabels } from "./Tasks.types.ts";

export const defaultTaskFilter: InboxTasksFilterValues = {
  statuses: [UserTaskStatus.InProgress, UserTaskStatus.New]
};

export interface TasksFilterProps
  extends Pick<FilterBarProps<InboxTasksFilterValues>, "children"> {}

export const FollowUpTaskFilter: FC<TasksFilterProps> = ({ children }) => {
  const { inbox, userExperience } = useStores();
  const { instructions, userTaskStatus, outcomes } = inbox.ref;
  const instructionsOptions = instructions.keyTextValues.map(x => ({
    key: x.key,
    text: x.text
  }));

  const statusesOptions = userTaskStatus.keyTextValues;
  const outcomeOptions = outcomes.keyTextValues;

  //removes duplicated text and keeps unique only
  const uniqueInstructionsOptions = getUniqueObjectsByKeys({
    array: instructionsOptions,
    keys: ["text"]
  });

  const getPatientPlaceholder = () => {
    const patientLabel = userExperience.localisedConfig("patientDisplay", {
      capitalizeFirst: false
    });

    return TasksScreenLabels.SearchPatient.replace("%pat%", patientLabel);
  };

  return (
    <FilterBar<InboxTasksFilterValues>
      initialValues={defaultTaskFilter}
      items={[
        {
          type: "contactPicker",
          name: "patientId",
          stickItem: true,
          props: {
            inputProps: {
              placeholder: getPatientPlaceholder(),
              name: "tasks-patient-filter"
            },
            iconName: "Filter",
            pickerCalloutProps: {
              calloutWidth: 300
            },
            pickerSuggestionsProps: {
              noResultsFoundText: TasksScreenLabels.NoMatch
            },
            filter: {
              types: [ContactType.Patient]
            },
            onResolveSuggestionItem: (contact: Contact) => ({
              text: contact.name
            })
          }
        },
        {
          type: "usersSelect",
          name: "assignedToUserIds",
          props: {
            placeholder: TasksScreenLabels.Assignee,
            id: "tasks-users-filter",
            styles: { root: { maxWidth: 90, minWidth: 90 } },
            calloutWidth: 250,
            multiSelect: true
          }
        },
        {
          type: "optionsSelect",
          name: "instructionCodes",
          props: {
            id: "tasks-filter-actions",
            placeholder: TasksScreenLabels.FollowUp,
            options: uniqueInstructionsOptions,
            styles: { root: { maxWidth: 97, minWidth: 97 } },
            multiSelect: true,
            hideSearchOption: true,
            calloutWidth: 220
          }
        },
        {
          type: "datePicker",
          name: "checkedDateTime",
          props: {
            placeholder: TasksScreenLabels.Checked,
            styles: { root: { maxWidth: 90, minWidth: 90 } }
          }
        },
        {
          type: "optionsSelect",
          name: "outcomeCodes",
          props: {
            id: "tasks-filter-outcomeCodes",
            placeholder: TasksScreenLabels.Outcome,
            options: outcomeOptions,
            styles: { root: { maxWidth: 93, minWidth: 93 } },
            multiSelect: true,
            calloutWidth: 150
          }
        },
        {
          type: "optionsSelect",
          name: "statuses",
          props: {
            id: "tasks-filter-status",
            placeholder: TasksScreenLabels.Status,
            options: statusesOptions,
            styles: { root: { maxWidth: 130, minWidth: 130 } },
            multiSelect: true,
            calloutWidth: 150
          }
        }
      ]}
      styles={{
        peoplePicker: { minWidth: 200 }
      }}
      children={children}
    />
  );
};
