import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { getSubmissionFormStyles } from "../appointment-dialog/components/appointment-form/AppointmentForm.styles.tsx";
import { AppointmentFormHelper } from "../appointment-dialog/components/appointment-form/context/AppointmentFormHelper.ts";
import { SecondColumnFieldsSwitcher } from "../appointment-dialog/components/appointment-form/SecondColumnFieldsSwitcher.tsx";
import { AddToGroupApptFormFields } from "./components/AddToGroupApptFields.tsx";
import { AddToGroupApptDialogValidator } from "./validators/AddToGroupApptDialogValidator.ts";

const AddToGroupApptDialogBase: React.FunctionComponent = observer(() => {
  const { addToGroupApptSubmit, initialValues } = useContext(
    AppointmentFormContext
  );

  const { booking } = useStores();

  const validator = new AddToGroupApptDialogValidator();

  const onDismiss = () => {
    booking.ui.showCalendarEventDialog({
      showAddToGroupApptDialog: false
    });
  };

  return (
    <SubmissionFormDialog<AppointmentFormValues>
      dialogName="Add patient to group appointment"
      dialogProps={{
        onDismiss,
        maxWidth: "auto",
        minWidth: "auto",
        dialogContentProps: {
          styles: {
            title: { display: "none" },
            inner: { padding: 0, height: "100%" },
            innerContent: { padding: 0 }
          }
        }
      }}
      onSubmit={addToGroupApptSubmit}
      onSubmitSucceeded={onDismiss}
      initialValues={initialValues}
      validate={validator.validate}
      styles={getSubmissionFormStyles(
        booking.ui.currentAppointment?.secondColumnContent
      )}
      sidePanel={<SecondColumnFieldsSwitcher />}
      hideButtons
    >
      {() => <AddToGroupApptFormFields onDismiss={onDismiss} />}
    </SubmissionFormDialog>
  );
});

const AddToGroupApptDialogWithContext = observer(() => {
  const root = useStores();

  const hidden =
    !root.booking.ui.currentAppointment ||
    !root.booking.ui.currentAppointment.showAddToGroupApptDialog;

  const groupApptTypes = root.booking.activeAppointmentTypes.filter(
    x => x.isGroupAppointmentType
  );

  const helper = new AppointmentFormHelper(root, groupApptTypes[0], undefined);

  if (hidden) return null;
  return (
    <AppointmentFormContext.Provider value={helper}>
      <AddToGroupApptDialogBase />
    </AppointmentFormContext.Provider>
  );
});

export const AddToGroupApptDialog = withFetch(
  x => [x.booking.loadAppointmentTypes()],
  AddToGroupApptDialogWithContext
);
