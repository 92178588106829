import { observer } from "mobx-react-lite";
import { FunctionComponent, ReactNode } from "react";
import { useForm, useFormState } from "react-final-form";

import { Heading, IPersonaProps, Stack } from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { UserPickerField } from "@ui-components/pickers/user-picker/UserPickerField.tsx";

import { useDocumentWriterContext } from "../context/DocumentWriterContext.ts";
import { DocumentWriterHeaderFormValidator } from "./DocumentWriterHeaderFormValidator.ts";
import { DocumentWriterValues } from "./DocumentWriterValues.ts";
import { VisibilityAndConfidentialityField } from "./VisibilityAndConfidentialityField.tsx";

interface DocumentWriterHeaderDialogProps {
  isDialogHidden: boolean;
  patientName: string;
  onDismiss: () => void;
}

const nameOf = nameOfFactory<DocumentWriterValues>();
const validator = new DocumentWriterHeaderFormValidator();
const getTextFromItem = (persona: IPersonaProps) => persona.text as string;

export const DocumentWriterHeaderDialog: FunctionComponent<DocumentWriterHeaderDialogProps> =
  observer((props: DocumentWriterHeaderDialogProps) => {
    const { isDialogHidden, patientName, onDismiss } = props;
    const form = useForm<DocumentWriterValues>();
    const { correspondence, core } = useStores();
    const { values } = useFormState<DocumentWriterValues>();
    const model = useDocumentWriterContext();

    if (isDialogHidden) return null;

    const { type, isDefinedTemplate, getDocumentTypeText } = model;
    const heading = `Edit ${getDocumentTypeText(
      values.documentType
    )} for ${patientName}`;

    const renderForm = (): ReactNode => {
      return (
        <Stack
          styles={{
            root: {
              minWidth: 600
            }
          }}
          tokens={{ childrenGap: 8 }}
        >
          <Fieldset horizontal grow>
            {type !== CorrespondenceType.MedicalCertificate && (
              <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
                <ContactPickerField
                  name={nameOf("recipientId")}
                  label="Recipient"
                  iconName="Search"
                  placeholder="Search contacts"
                  getTextFromItem={getTextFromItem}
                  disabled={
                    isDefinedTemplate && type === CorrespondenceType.Referral
                  }
                />
              </Stack.Item>
            )}
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <UserPickerField
                name={nameOf("providerId")}
                label="Provider"
                onResolveSuggestionItem={user => ({
                  text: user.fullName
                })}
                placeholder="Select provider"
                required
              />
            </Stack.Item>
          </Fieldset>
          <Fieldset horizontal>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <TextInputField
                name={nameOf("documentTitle")}
                label="Subject"
                required={true}
                autoFocus={true}
                placeholder="Add subject"
              />
            </Stack.Item>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <DropdownField
                name={nameOf("documentType")}
                placeholder="Select document type"
                label="Document type"
                required
                disabled={isDefinedTemplate}
                options={correspondence.ref.correspondenceTypes.keyTextValues}
              />
            </Stack.Item>
          </Fieldset>
          <VisibilityAndConfidentialityField
            name={nameOf("visibility")}
            isEdit
          />
        </Stack>
      );
    };

    return (
      <SubmissionFormDialog
        onSubmit={(formValues: DocumentWriterValues) => {
          form.batch(() => {
            Object.entries(formValues).forEach(formValuesEntry => {
              const [key, value] = formValuesEntry;
              form.change(key as keyof DocumentWriterValues, value);
            });
          });
        }}
        dialogName="Document write dialog"
        onSubmitSucceeded={onDismiss}
        initialValues={{
          ...values,
          providerId: values.providerId ?? core.userId
        }}
        validate={validator.validate}
        dialogProps={{
          maxWidth: 650,
          styles: { root: { width: "100%" } },
          onDismiss,
          dialogContentProps: {
            title: <Heading variant="modal-heading">{heading}</Heading>,
            showCloseButton: true
          }
        }}
        buttonsProps={{
          submitButtonProps: { iconProps: { hidden: true }, text: "Update" }
        }}
      >
        {renderForm}
      </SubmissionFormDialog>
    );
  });
