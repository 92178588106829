import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ApplyRefundFormValues } from "../shared-components/RefundConfirmationDialog.tsx";

export class ApplyRefundValidator extends Validator<ApplyRefundFormValues> {
  constructor() {
    super();
    this.forField("method", required());
  }
}
