import { parse } from "query-string";

import {
  BillingStatuses,
  GetTransactionsArgs,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { ApplyToDateKeys } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsListFilter.types.ts";
import { SetFilter } from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/TransactionFilterBase.types.ts";

import {
  AccreditedBillingFilter,
  AccreditedBillingFilterQuery
} from "./AccreditedBillingList.types.ts";

export const getTransactionStatusesFromFilterQuery = (
  filterQuery: AccreditedBillingFilterQuery
) => {
  const transactionStatuses: AccreditedBillingFilter["transactionStatuses"] =
    [];
  if (filterQuery.billingStatuses) {
    if (typeof filterQuery.billingStatuses === "string") {
      transactionStatuses.push(filterQuery.billingStatuses);
    } else {
      transactionStatuses.push(...filterQuery.billingStatuses);
    }
  }
  if (filterQuery.paymentStatuses) {
    if (typeof filterQuery.paymentStatuses === "string") {
      transactionStatuses.push(filterQuery.paymentStatuses);
    } else {
      transactionStatuses.push(...filterQuery.paymentStatuses);
    }
  }

  return transactionStatuses;
};

export const getAccreditedBillingFilterInitialValues = (
  filterQuery: AccreditedBillingFilterQuery
): AccreditedBillingFilter => {
  const transactionStatuses =
    getTransactionStatusesFromFilterQuery(filterQuery);
  return {
    applyToDate: filterQuery.applyToDate,
    accountIds:
      typeof filterQuery.accountIds === "string"
        ? [filterQuery.accountIds]
        : filterQuery.accountIds,
    transactionStatuses: transactionStatuses.length
      ? transactionStatuses
      : undefined
  };
};

export const onFilterChange = (
  options: {
    field: keyof AccreditedBillingFilter;
    values: AccreditedBillingFilter;
  },
  setFilter: SetFilter
) => {
  const { field, values } = options;

  if (field === "transactionStatuses") {
    const billingStatuses = values[field]?.find(
      value => value === BillingStatuses.cancelled
    );

    const paymentStatuses = values[field]?.filter(
      value =>
        value === PaymentStatuses.paid ||
        value === PaymentStatuses.part ||
        value === PaymentStatuses.unpaid
    );
    return setFilter({
      billingStatuses,
      paymentStatuses
    });
  }

  const shouldNotSearch =
    field === "applyToDate" ? !values.startTime && !values.endTime : undefined;

  if (shouldNotSearch) return;

  return setFilter({ [field]: values[field] });
};

export const parseToGetByArgs = (
  searchQuery: string,
  accountIds: string[]
): GetTransactionsArgs => {
  const filter: AccreditedBillingFilterQuery = searchQuery
    ? parse(searchQuery)
    : {};

  const {
    startTime: startTimeBasic,
    endTime: endTimeBasic,
    applyToDate,
    ...restFilter
  } = filter;

  const serviceStartTime =
    applyToDate === ApplyToDateKeys.serviceDate ? startTimeBasic : undefined;

  const serviceEndTime =
    applyToDate === ApplyToDateKeys.serviceDate ? endTimeBasic : undefined;

  const startTime =
    applyToDate === ApplyToDateKeys.transactionDate
      ? startTimeBasic
      : undefined;

  const endTime =
    applyToDate === ApplyToDateKeys.transactionDate ? endTimeBasic : undefined;

  return {
    accountIds,
    billingStatuses: filter.paymentStatuses
      ? undefined
      : [BillingStatuses.current, BillingStatuses.cancelled],
    serviceStartTime,
    serviceEndTime,
    startTime,
    endTime,
    ...restFilter
  };
};
