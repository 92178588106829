import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DocumentMergeFieldsFormValues } from "./DocumentWriterMergeFormDialog.types.ts";

export class DocumentWriterMergeFieldsDischargeFormValidator extends Validator<DocumentMergeFieldsFormValues> {
  constructor() {
    super();

    this.forField("toContactId", [required()]);
    this.forField("episodeOfCareId", [required()]);
  }
}
