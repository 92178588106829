import { KB_URL_BASE } from "app-shell/NavBar/HelpNavButton.tsx";
import { observer } from "mobx-react-lite";

import {
  BestPracticeSvgLogo,
  CenteredBox,
  DialogType,
  Heading,
  IDialogContentProps,
  Link,
  PrimaryButton,
  Stack
} from "@bps/fluent-ui";
import { HotkeyCheatSheet } from "@modules/user-experience/components/HotkeyCheatsheet.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { SearchBoxField } from "@ui-components/form/SearchBoxField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

interface SearchValues {
  search?: string;
}

const helpDialogProps: IDialogContentProps = {
  titleProps: { hidden: true },
  type: DialogType.close,
  styles: {
    topButton: {
      position: "static",
      padding: 0,
      display: "block",
      textAlign: "right"
    },
    content: { padding: 24 }
  }
};

const HelpDialog = observer(() => {
  const {
    userExperience: { ui }
  } = useStores();

  const onDismiss = () => ui.toggleHelpDialogVisibility();

  return (
    <SubmissionFormDialog<SearchValues>
      initialValues={{ search: undefined }}
      disablePrompt
      dialogProps={{
        minWidth: 500,
        dialogContentProps: { ...helpDialogProps },
        onDismiss
      }}
      onSubmit={(values: SearchValues) => {
        if (!values.search) {
          return;
        }
        window.open(
          `${KB_URL_BASE}/bpomni/Resources/MasterPages/SearchResultsPage.htm?q=${values.search}`,
          "_blank"
        );
      }}
      hideButtons
      dialogName="Help dialog"
    >
      {() => (
        <>
          <CenteredBox styles={{ root: { height: "auto" } }}>
            <BestPracticeSvgLogo width={100} height={100} isBlue />
            <Heading styles={{ root: { marginTop: 4 } }} variant="heading">
              How can we help?
            </Heading>
          </CenteredBox>
          <Stack tokens={{ childrenGap: 48 }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 16 }}
              styles={{ root: { marginTop: 32, padding: "0, 14px" } }}
            >
              <SearchBoxField
                debounceSearchOnChange={false}
                styles={{ root: { flexGrow: 1 } }}
                iconProps={{ iconName: "Search" }}
                showIcon
                underlined
                placeholder="Search the Omni knowledge base"
                name="search"
              />
              <PrimaryButton
                type="submit"
                text="Go"
                styles={{ root: { minWidth: 60, maxWidth: 60 } }}
              />
            </Stack>
            <Heading
              variant="section-sub-heading"
              styles={{ root: { display: "inline", marginTop: 48 } }}
            >
              Omni keyboard shortcuts{" "}
            </Heading>
            <Link
              href={`${KB_URL_BASE}/bpomni/GettingStarted/KeyboardShortcuts.htm`}
              target="_blank"
            >
              What are keyboard shortcuts?
            </Link>
            <div style={{ marginTop: 16 }}>
              <HotkeyCheatSheet />
            </div>
            <CenteredBox styles={{ root: { marginTop: 48 } }}>
              <Link
                styles={{ root: { alignSelf: "center" } }}
                onClick={onDismiss}
              >
                Close
              </Link>
            </CenteredBox>
          </Stack>
        </>
      )}
    </SubmissionFormDialog>
  );
});

// eslint-disable-next-line import/no-default-export
export default HelpDialog;
