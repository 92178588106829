import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef } from "react";

import {
  Heading,
  IContextualMenuItem,
  IDialogContentProps,
  Spinner,
  SplitButton,
  Stack,
  StandardRangesOptionsKeys,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DocumentUrlDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { getPDFStyles } from "@ui-components/document-viewer/pdf.styles.ts";
import { DatesRangePickerField } from "@ui-components/form/DatesRangePickerField/DatesRangePickerField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";

import { EmailStatementModal } from "./EmailStatementModal.tsx";
import { StatementModalFormValues } from "./StatementModalForm.types.ts";
import { getStatementName } from "./statementModalUtils.ts";
import { StatementModalValidator } from "./StatementModalValidator.tsx";

interface StatementModalProps {
  setCurrentAccountId: (id: string) => void;
}

const validator = new StatementModalValidator();
const nameOf = nameOfFactory<StatementModalFormValues>();
const STATEMENT_DIALOG_NAME = "Create statement";
export const STATEMENT_DIALOG_TITLE = "New account statement";

const StatementModal: React.FC<StatementModalProps> = observer(
  ({ setCurrentAccountId }) => {
    const {
      billing: {
        addStatement,
        ui: { onToggleStatementModal }
      },
      routing
    } = useStores();

    const accountId: string | undefined = routing.match(routes.accounts.account)
      ?.params.id;

    const dialogContentProps: IDialogContentProps = {
      title: <Heading variant="modal-heading">{STATEMENT_DIALOG_TITLE}</Heading>
    };

    // set current account ID for DocumentViewerDialog in StatementModal mount
    useEffect(() => {
      if (accountId) setCurrentAccountId(accountId);
    }, [accountId, setCurrentAccountId]);

    const nowDateTimeStartDay = DateTime.today();

    const initialValues = {
      accountContactId: accountId,
      startDate: nowDateTimeStartDay
        .minus({ months: 1 })
        .startOf("month")
        .toJSDate(),
      endDate: nowDateTimeStartDay
        .minus({ months: 1 })
        .endOf("month")
        .toJSDate()
    };

    const onStatementModalSubmitted = async (
      values: StatementModalFormValues
    ) => {
      const { accountContactId, endDate, startDate } = values;
      await addStatement({
        accountContactId,
        date: DateTime.now().toISODate(),
        endDate: DateTime.fromJSDate(endDate).toISODate(),
        startDate: DateTime.fromJSDate(startDate).toISODate()
      });
    };

    const onDismiss = () => onToggleStatementModal(false);
    return (
      <SubmissionFormDialog<StatementModalFormValues>
        dialogName={STATEMENT_DIALOG_NAME}
        initialValues={initialValues}
        onSubmit={values => onStatementModalSubmitted(values)}
        onSubmitSucceeded={onDismiss}
        validate={validator.validate}
        dialogProps={{
          dialogContentProps,
          onDismiss,
          minWidth: 600,
          styles: {
            root: {
              ".ms-Dialog-action": { width: "100%" }
            }
          }
        }}
        buttonsProps={{
          disableSubmitOnPristine: false,
          submitButtonProps: {
            text: "Generate",
            iconProps: undefined
          }
        }}
      >
        {() => (
          <Stack tokens={{ childrenGap: 8 }}>
            <ContactPickerField
              name={nameOf("accountContactId")}
              iconName="Search"
              label="For"
              placeholder="Search for account holders"
              required
            />
            <FieldSpy
              name={nameOf("accountContactId")}
              // reset current account ID
              onChange={setCurrentAccountId}
            />

            <DatesRangePickerField
              styles={{ root: { width: "50%" }, fieldGroup: { width: 200 } }}
              placeholder="Date(s)"
              required
              standardRanges={[
                StandardRangesOptionsKeys.previousMonth,
                StandardRangesOptionsKeys.thisMonth
              ]}
              label="Date range"
              endDateName={nameOf("endDate")}
              startDateName={nameOf("startDate")}
              endDateProps={{ maxDate: DateTime.jsDateNow() }}
              closeCalloutOnSelection
              renderCloseBtn
              hidePasteStartButton
            />
          </Stack>
        )}
      </SubmissionFormDialog>
    );
  }
);

const StatementModalWrapper: FunctionComponent = observer(() => {
  const { billing } = useStores();

  const {
    ui: {
      isStatementModalVisible,
      statementDocumentPreviewData,
      setStatementDocumentPreviewData,
      setStatementEmailDialogVisible,
      isStatementEmailDialogVisible
    },
    getBillingStoredDocumentUrl
  } = billing;

  const currentAccountId = useRef<string | undefined>(undefined);

  const theme = useTheme();

  const { noMarginsLandscapePdfstyles } = getPDFStyles(theme);

  const renderDownloadButton = (handleDownload: () => void) => {
    const contactId = statementDocumentPreviewData?.contactId;
    const statementId = statementDocumentPreviewData?.statementId;
    if (!contactId) {
      return null;
    }

    return (
      <ContactFetcher
        contactId={contactId}
        options={{ includeContactPreferences: true }}
        fallback={<Spinner />}
      >
        {contact => {
          if (contact.billingOptedOut) {
            const menuItems: IContextualMenuItem[] = [
              {
                key: "emailOptedOut",
                text: "Email (opted out)",
                iconProps: { iconName: "Mail" },
                disabled: true
              }
            ];

            return (
              <SplitButton
                onClick={handleDownload}
                text="Download"
                items={menuItems}
              />
            );
          }

          const onDefaultButtonClick = async () => {
            if (contactId && statementId) {
              setStatementEmailDialogVisible(true);
            }
          };

          const menuItems: IContextualMenuItem[] = [
            {
              key: "download",
              text: "Download",
              onClick: handleDownload
            }
          ];

          return (
            <SplitButton
              onClick={onDefaultButtonClick}
              text="Email"
              iconProps={{ iconName: "Mail" }}
              items={menuItems}
            />
          );
        }}
      </ContactFetcher>
    );
  };

  const closeDocumentViewer = () => {
    currentAccountId.current = undefined;
    setStatementDocumentPreviewData(undefined);
    setStatementEmailDialogVisible(false);
  };

  return (
    <>
      {isStatementModalVisible && (
        <StatementModal
          setCurrentAccountId={contactId => {
            currentAccountId.current = contactId;
          }}
        />
      )}
      {statementDocumentPreviewData && (
        <DataFetcher<DocumentUrlDto | undefined>
          fetch={async () => {
            const { contactId, documentId, statementId } =
              statementDocumentPreviewData;
            try {
              // call the method with contactId and documentId when first creating Account statement PDF
              if (contactId && documentId) {
                return await getBillingStoredDocumentUrl({
                  contactId,
                  documentId
                });
                // call the method with contactId and statementId when the statement is created and we click on view statement link
              } else if (contactId && statementId) {
                return await getBillingStoredDocumentUrl({
                  contactId,
                  statementId
                });
              } else if (currentAccountId.current && documentId) {
                return await getBillingStoredDocumentUrl({
                  contactId: currentAccountId.current,
                  documentId
                });
              }
              return undefined;
            } catch (err) {
              closeDocumentViewer();
              return undefined;
            }
          }}
        >
          {data => {
            if (data) {
              return (
                <DocumentViewerDialog
                  pageStyle={noMarginsLandscapePdfstyles}
                  renderDownloadButton={renderDownloadButton}
                  getDocument={async () => {
                    const documentId =
                      statementDocumentPreviewData.documentId ??
                      statementDocumentPreviewData.statementId;

                    const entityId =
                      currentAccountId.current ??
                      statementDocumentPreviewData.contactId;
                    if (documentId && entityId) {
                      return {
                        documentId,
                        previewUri: data?.url,
                        extension: "pdf",
                        entityId,
                        entityType: DocumentEntityType.Patient,
                        downloadUri: data?.url,
                        name: getStatementName(
                          statementDocumentPreviewData.statement
                        )
                      };
                    }
                    throw Error(
                      "documentId or contactId have not been specified!"
                    );
                  }}
                  downloadInNewTab
                  closeDocumentViewer={closeDocumentViewer}
                />
              );
            }
            return null;
          }}
        </DataFetcher>
      )}
      {statementDocumentPreviewData && isStatementEmailDialogVisible && (
        <EmailStatementModal
          statementDocumentPreviewData={statementDocumentPreviewData}
        />
      )}
    </>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default StatementModalWrapper;
