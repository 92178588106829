import { useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  IconButton,
  Stack
} from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { QuickAddContactFormValues } from "./QuickAddContactFormValues.tsx";

const nameOf = nameOfFactory<QuickAddContactFormValues>();

interface QuickAddContactBasicFormFieldsProps {
  detailedView?: boolean;
}

export const QuickAddContactBasicFormFields: React.FunctionComponent<
  QuickAddContactBasicFormFieldsProps
> = ({ detailedView }) => {
  const form = useForm<QuickAddContactFormValues>();
  const { values } = form.getState();

  return (
    <Fieldset>
      <Stack
        horizontal={detailedView}
        verticalAlign={!detailedView ? "end" : undefined}
        horizontalAlign={!detailedView ? "space-between" : undefined}
        tokens={{ childrenGap: 8 }}
      >
        <Stack horizontal={detailedView} tokens={{ childrenGap: 8 }} grow>
          <Stack
            horizontal
            verticalAlign="end"
            tokens={{ childrenGap: 8 }}
            grow
          >
            <TextInputField
              autoFocus
              required={true}
              name={nameOf("lastName")}
              label="Last name"
            />
            <IconButton
              {...dataAttribute(
                DataAttributes.Element,
                "quick-add-modal-switcher"
              )}
              iconProps={{
                iconName: detailedView ? "Switch" : "SwitcherStartEnd",
                styles: {
                  root: {
                    margin: 0,
                    fontWeight: FontWeights.semibold
                  }
                }
              }}
              onClick={() => {
                form.change(nameOf("firstName"), values.lastName);
                form.change(nameOf("lastName"), values.firstName);
              }}
            />
          </Stack>

          <TextInputField
            required={true}
            name={nameOf("firstName")}
            label="First name"
            styles={{
              root: {
                width: detailedView ? 196 : "100%"
              }
            }}
          />
        </Stack>
      </Stack>
      <TextInputField name={nameOf("mobile")} label="Mobile" />
      <TextInputField name={nameOf("email")} label="Email" />
    </Fieldset>
  );
};
