import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { DefaultButton, Heading } from "@bps/fluent-ui";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ImagingRequestContext } from "./ImagingRequestContext.ts";
import {
  getEmptyImagingRequest,
  ImagingRequestFormValues,
  Labels
} from "./ImagingRequestDialog.types.ts";
import { ImagingRequestDialogFields } from "./ImagingRequestDialogFields.tsx";
import { ImagingRequestHelper } from "./ImagingRequestHelper.ts";
import { ImagingRequestValidator } from "./ImagingRequestValidator.tsx";

export interface AddRequestDialogProps {
  onDismiss: () => void;
  clinicalRecord: ClinicalRecord;
  printImagingRequest: (document: ClinicalDocument) => void;
}

const ImagingRequestDialogBase: React.FC<AddRequestDialogProps> = observer(
  ({ onDismiss, clinicalRecord, printImagingRequest }) => {
    const helper = useContext(ImagingRequestContext);
    let print = false;
    const saveAndPrint = async (form: FormApi<ImagingRequestFormValues>) => {
      print = true;
      form.submit();
    };

    const submitForm = async (values: ImagingRequestFormValues) => {
      await helper.submitImagingRequest(values);

      if (print) {
        const clinDoc = await helper.getClinicalDocument();
        if (clinDoc) {
          printImagingRequest(clinDoc);
        }
      }
    };

    return (
      <SubmissionFormDialog<ImagingRequestFormValues>
        dialogName={Labels.NewRequest}
        dialogProps={{
          onDismiss,
          modalProps: {
            layerProps: {
              eventBubblingEnabled: true
            }
          },
          minWidth: 1200,
          dialogContentProps: {
            title: (
              <Heading variant="modal-heading">{Labels.NewRequest}</Heading>
            ),
            showCloseButton: true
          }
        }}
        buttonsProps={{
          extraActionsBefore: (form: FormApi<ImagingRequestFormValues>) => {
            const { pristine } = form.getState();
            return (
              <DefaultButton
                text="Save & print"
                primary={true}
                onClick={() => saveAndPrint(form)}
                disabled={pristine}
              />
            );
          }
        }}
        onSubmit={submitForm}
        initialValues={getEmptyImagingRequest(clinicalRecord)}
        onSubmitSucceeded={onDismiss}
        validate={(values: ImagingRequestFormValues) => {
          const validator = new ImagingRequestValidator(
            clinicalRecord.clinicalTasks,
            values.createClinicalTask
          );
          return validator.validate(values);
        }}
        render={() => (
          <ImagingRequestDialogFields clinicalRecord={clinicalRecord} />
        )}
      />
    );
  }
);

export const ImagingRequestDialog: React.FC<AddRequestDialogProps> = ({
  ...props
}) => {
  const root = useStores();
  const helper = new ImagingRequestHelper(props.clinicalRecord, root);

  return (
    <ImagingRequestContext.Provider value={helper}>
      <ImagingRequestDialogBase {...props} />
    </ImagingRequestContext.Provider>
  );
};
