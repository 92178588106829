import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Heading } from "@bps/fluent-ui";
import { ClinicalTaskFormValues } from "@shared-types/clinical/clinical-task-values.type.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ClinicalTaskFormFields } from "./ClinicalTaskFormFields.tsx";
import { ClinicalTaskValidator } from "./ClinicalTaskValidator.tsx";

export interface ClinicalTaskDialogProps {
  clinicalTasks: ClinicalTask[];
  clinicalTask?: ClinicalTask;
  hidden?: boolean | undefined;
  onDismiss: () => void;
  initialValues: ClinicalTaskFormValues;
  onSubmit: (values: ClinicalTaskFormValues) => void;
  clinicalRecordId?: string;
}

const NEW_DIALOG_TITLE = "New clinical task";
const EDIT_DIALOG_TITLE = "Edit clinical task";

export const ClinicalTaskDialog: FunctionComponent<ClinicalTaskDialogProps> =
  observer(
    ({
      clinicalTasks,
      clinicalTask,
      hidden,
      onDismiss,
      initialValues,
      onSubmit,
      clinicalRecordId
    }) => {
      const root = useStores();
      if (hidden) return null;

      const validator = new ClinicalTaskValidator(
        clinicalTask,
        root,
        clinicalTasks
      );

      const onSubmitSucceeded = () => {
        root.notification.success("Clinical task has been recorded.");
        onDismiss();
      };

      const dialogName = clinicalTask ? EDIT_DIALOG_TITLE : NEW_DIALOG_TITLE;

      const getDialogTitle = () => {
        if (clinicalTask) {
          if (!clinicalRecordId && clinicalTask?.patientId) {
            const contact = root.practice.contactsMap.get(
              clinicalTask?.patientId
            );

            return contact
              ? `${EDIT_DIALOG_TITLE} - ${contact.fullName}`
              : EDIT_DIALOG_TITLE;
          }
          return EDIT_DIALOG_TITLE;
        }

        return NEW_DIALOG_TITLE;
      };

      return (
        <SubmissionFormDialog
          dialogName={dialogName}
          autoFocus={!clinicalTask}
          onSubmit={onSubmit}
          onSubmitSucceeded={onSubmitSucceeded}
          buttonsProps={{
            disableSubmitOnPristine: true,
            disableSubmitOnFormInvalid: true,
            submitButtonProps: { text: "Save" },
            hideButtonsSeparator: true
          }}
          validate={validator.validate}
          initialValues={initialValues}
          dialogProps={{
            onDismiss,
            minWidth: 480,
            dialogContentProps: {
              title: (
                <Heading
                  styles={{
                    root: {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }
                  }}
                  variant="modal-heading"
                >
                  {getDialogTitle()}
                </Heading>
              )
            }
          }}
          render={() => (
            <ClinicalTaskFormFields
              clinicalTask={clinicalTask}
              clinicalRecordId={clinicalRecordId}
            />
          )}
        />
      );
    }
  );
