import { FC } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  IStackItemStyles,
  mergeStyleSets,
  Stack
} from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { patientWarningDotStyles } from "@modules/practice/screens/contact-details/patient/view/patient-warnings/PatientWarnings.styles.ts";

interface PatientAccountBalanceValueProps {
  value: number;
  text: string;
  showDotIfPositive?: boolean;
  containerStyles?: IStackItemStyles;
}
export const PatientAccountBalanceValue: FC<
  PatientAccountBalanceValueProps
> = ({ value, text, showDotIfPositive, containerStyles }) => (
  <Stack
    horizontal
    horizontalAlign="space-between"
    tokens={{ childrenGap: 16 }}
  >
    <Stack.Item
      styles={(props, theme) =>
        mergeStyleSets(
          {
            root: {
              fontWeight: FontWeights.semibold,
              "&:before": showDotIfPositive && { width: 14, height: 14 }
            }
          },
          value > 0 && showDotIfPositive && patientWarningDotStyles(theme),
          containerStyles
        )
      }
    >
      {text}
    </Stack.Item>
    <div
      {...dataAttribute(
        DataAttributes.Element,
        "patient-account-balance-value"
      )}
    >
      {value >= 0 && currencyFormat(value)}
    </div>
  </Stack>
);
