import { observer } from "mobx-react-lite";

import { DefaultButton, Dialog, Stack } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const EncounterDeletedElsewhereDialog: React.FC = observer(() => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { clinical } = useStores();

  const removedEncounterId =
    clinicalRecord.openEncounter &&
    clinical.encountersDeletedElsewhere.find(
      x => x === clinicalRecord?.openEncounter?.id
    );

  let removedAwhileAgoEncounterId: string | undefined;
  const removedAwhileAgo =
    !clinicalRecord.openEncounter &&
    !clinical.activeRecordIsView &&
    !clinicalRecord.encounterDiscardedByUser &&
    !clinicalRecord.finalisedByClient;
  if (removedAwhileAgo) {
    removedAwhileAgoEncounterId = clinicalRecord.currentEncounterId;
  }

  const elsewhere = removedEncounterId || removedAwhileAgoEncounterId;

  const closeUnsavedFormsDialog = () => {
    if (removedEncounterId) {
      clinical.removeDeletedElsewhereEncounter(
        removedEncounterId,
        clinicalRecord
      );
    }

    if (removedAwhileAgoEncounterId) {
      clinical.closeRecord(clinicalRecord.id, removedAwhileAgoEncounterId);
    }
  };
  return (
    <Dialog minWidth={600} title="Deleted Elsewhere" hidden={!elsewhere}>
      <Stack tokens={{ childrenGap: 16 }}>
        <Stack.Item> This Encounter has been deleted elsewhere. </Stack.Item>
        <Stack horizontalAlign="end">
          <DefaultButton onClick={() => closeUnsavedFormsDialog()}>
            Close
          </DefaultButton>
        </Stack>
      </Stack>
    </Dialog>
  );
});
