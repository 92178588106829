import { FontIcon, FontSizes, TooltipHost, useTheme } from "@bps/fluent-ui";

interface ConfidentialToolTipFontIconProps {
  content?: string;
  summaryStyling?: boolean;
  isShowConfidentialIcon: boolean;
}
export const ConfidentialToolTipFontIcon: React.FunctionComponent<
  ConfidentialToolTipFontIconProps
> = ({ content, summaryStyling, isShowConfidentialIcon }) => {
  const theme = useTheme();
  if (!isShowConfidentialIcon) {
    return null;
  }

  const styles = summaryStyling
    ? {
        root: {
          margin: "auto 4px",
          fontSize: FontSizes.size16,
          color: theme.palette.neutralSecondaryAlt
        }
      }
    : {
        root: {
          margin: "auto 10px",
          color: theme.palette.themePrimary,
          fontSize: FontSizes.size16,
          paddingTop: 3
        }
      };
  return (
    <TooltipHost content={content ?? "Confidential"}>
      <FontIcon
        iconName="ShieldAlert"
        styles={{
          ...styles
        }}
      />
    </TooltipHost>
  );
};
