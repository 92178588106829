import { FunctionComponent } from "react";

import { BadgeSize, Stack } from "@bps/fluent-ui";

import { ReactionBadge } from "./ReactionBadge.tsx";
import { ReactionTooltip, ReactionTooltipProps } from "./ReactionTooltip.tsx";

export interface ReactionListItemProps extends ReactionTooltipProps {
  onRenderActions?: () => React.ReactNode;
  size?: BadgeSize;
}

export const ReactionListItem: FunctionComponent<ReactionListItemProps> = ({
  reaction,
  calloutProps,
  onRenderActions,
  size,
  natureOfReaction,
  agent
}) => {
  return (
    <ReactionBadge
      size={size}
      key={reaction.key}
      severity={
        reaction.naturesOfReaction.find(
          x => x.natureOfReaction === natureOfReaction
        )?.severity
      }
      styles={{
        root: {
          display: "flex",
          flex: 1
        },
        content: {
          flex: 1
        }
      }}
    >
      <Stack
        grow
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 16 }}
        styles={{ root: { justifyContent: "space-between" } }}
      >
        <Stack.Item grow>
          <ReactionTooltip
            reaction={reaction}
            agent={agent}
            calloutProps={calloutProps}
            natureOfReaction={natureOfReaction}
          />
        </Stack.Item>
        {onRenderActions && <Stack.Item>{onRenderActions()}</Stack.Item>}
      </Stack>
    </ReactionBadge>
  );
};
