import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Heading, PersonaSize, Spinner, Stack, Text } from "@bps/fluent-ui";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AccountCardContactDetailsButton } from "@modules/billing/screens/account/components/AccountCardContactDetailsButton.tsx";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { RelationshipTypeText } from "@ui-components/RefText.tsx";

import {
  invoiceDetailsRowHeadingStyles,
  invoiceDetailsRowPersonaContainerStyles,
  invoiceDetailsRowPersonaStyles
} from "./InvoiceDetailsRow.styles.ts";
import { InvoiceDetailsRowPatientPersona } from "./InvoiceDetailsRowPatientPersona.tsx";

interface InvoiceDetailsRowAccountContactProps {
  accountContactId: InvoiceFormValues["accountContactId"];
  patientId: InvoiceFormValues["patientId"];
}

export const InvoiceDetailsRowAccountContact: FunctionComponent<InvoiceDetailsRowAccountContactProps> =
  observer(props => {
    const {
      practice,
      userExperience: { localisedConfig }
    } = useStores();

    const { accountContactId, patientId } = props;

    const accountIsPatient = accountContactId === patientId;

    const patientLabel = localisedConfig("patientDisplay", {
      capitalizeFirst: true
    });

    const fetchContacts = async () => {
      const [accountContact, patient] = await Promise.all([
        accountContactId
          ? practice.getContact(accountContactId, {
              includeContactPreferences: true
            })
          : undefined,
        patientId
          ? practice.getContact(patientId, { includeContactPreferences: true })
          : undefined
      ]);

      return { accountContact, patient };
    };

    return (
      <Stack grow>
        <DataFetcher
          fetch={fetchContacts}
          fallback={<Spinner />}
          refetchId={accountContactId}
        >
          {({ accountContact, patient }) => {
            if (!accountContact) {
              return null;
            }

            const isAccountHolder = !!patient?.accountHolders.some(
              relationship => relationship.relatedContactId === accountContactId
            );

            const otherRelationship = patient?.relationships.find(
              relationship => relationship.relatedContactId === accountContactId
            )?.relationship;

            let accountContactType: string | JSX.Element;

            if (accountIsPatient) {
              accountContactType = patientLabel;
            } else if (isAccountHolder) {
              accountContactType = (
                <RelationshipTypeText code={RelationshipType.AccountHolder} />
              );
            } else if (otherRelationship) {
              accountContactType = "Other";
            } else {
              accountContactType = "No relation";
            }

            return (
              <>
                <Heading
                  variant="section-heading-light"
                  styles={invoiceDetailsRowHeadingStyles}
                >
                  Bill to ({accountContactType})
                </Heading>
                {accountIsPatient ? (
                  <InvoiceDetailsRowPatientPersona patientId={patientId} />
                ) : (
                  <Stack styles={invoiceDetailsRowPersonaContainerStyles}>
                    <Persona
                      styles={invoiceDetailsRowPersonaStyles}
                      text={accountContact.name}
                      id={accountContact.id}
                      size={PersonaSize.size48}
                      imageInitials={accountContact.initials}
                      onRenderSecondaryText={() => {
                        return (
                          <Stack>
                            <Text variant="small">{accountContact.phone}</Text>
                            <Text variant="small">
                              {accountContact.billingEmail}
                            </Text>
                          </Stack>
                        );
                      }}
                    />
                    <AccountCardContactDetailsButton
                      contactId={accountContact.id}
                    />
                  </Stack>
                )}
              </>
            );
          }}
        </DataFetcher>
      </Stack>
    );
  });
