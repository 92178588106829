import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useField } from "react-final-form";

import {
  IStackStyles,
  Persona,
  PersonaSize,
  Spinner,
  Stack,
  StateBlock
} from "@bps/fluent-ui";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { DiagnosisList } from "@modules/clinical/screens/shared-components/DiagnosisList.tsx";
import { User } from "@stores/core/models/User.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { CardHeadings, CardIds } from "./ClaimReviewEnums.ts";
import { ClaimReviewContext } from "./models/ClaimReviewContext.ts";

export const SummaryCard: React.FC = observer(() => {
  const { getSummaryViewValues } = useContext(ClaimReviewContext);
  const patientLabel = usePatientLabel(true);

  const {
    input: { value: clinicalDirectorId }
  } = useField("clinicalDirectorId", {
    subscription: { value: true }
  });

  const summaryViewValues = getSummaryViewValues();

  const stateBoxSpacing: IStackStyles = {
    root: {
      justifyContent: "flex-start",
      padding: "8px 16px"
    }
  };

  const getPersonaBadge = (person: Contact | User) => {
    const isContact = (contactOrUser: any): contactOrUser is Contact => {
      return !!(contactOrUser as Contact).type;
    };

    const profilePictureUrl = isContact(person)
      ? person.profilePictureUrl
      : undefined;

    return (
      <Persona
        text={person.name}
        id={person.id}
        imageUrl={profilePictureUrl}
        size={PersonaSize.size24}
        imageInitials={person.initials}
        styles={{
          details: {
            paddingRight: 0
          },
          root: {
            width: "100%"
          }
        }}
        onRenderPrimaryText={() => (
          <span>{person && `${person.firstName} ${person.lastName}`}</span>
        )}
      />
    );
  };

  return (
    <ClaimCard
      id={CardIds.summary}
      openOnRender={true}
      heading={CardHeadings.summary}
      iconName="FormLibraryMirrored"
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <StateBlock
          styles={stateBoxSpacing}
          labelText={patientLabel}
          descText={
            <ContactFetcher
              contactId={summaryViewValues.patientId}
              fallback={<Spinner />}
            >
              {(patient: Contact) => getPersonaBadge(patient)}
            </ContactFetcher>
          }
        />
        <StateBlock
          styles={stateBoxSpacing}
          labelText="Clinical director"
          descText={
            <UserFetcher
              refetchId={clinicalDirectorId}
              userId={clinicalDirectorId}
              fallback={<Spinner />}
            >
              {(user: User) => getPersonaBadge(user)}
            </UserFetcher>
          }
        />
        <Stack
          styles={{
            root: {
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: "8px"
            }
          }}
        >
          <StateBlock
            styles={stateBoxSpacing}
            labelText="Providers"
            descText={
              <Stack as="ul" tokens={{ childrenGap: 8 }}>
                <DataFetcher
                  fetch={({ core }) =>
                    core.getUsersByIds(summaryViewValues.providers)
                  }
                  fallback={<Spinner />}
                >
                  {(users: User[]) => {
                    return users.map(user => (
                      <Stack key={user.id} as="li">
                        {getPersonaBadge(user)}
                      </Stack>
                    ));
                  }}
                </DataFetcher>
              </Stack>
            }
          />
          <StateBlock
            styles={stateBoxSpacing}
            labelText="Provider type"
            descText={summaryViewValues.providerType}
          />
        </Stack>
        <Stack
          styles={{
            root: {
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: "8px"
            }
          }}
        >
          <StateBlock
            styles={stateBoxSpacing}
            labelText="Claim number"
            descText={summaryViewValues.claimNumber}
          />
          <StateBlock
            styles={stateBoxSpacing}
            labelText="Total consults"
            descText={summaryViewValues.totalVisits}
          />
          <StateBlock
            styles={stateBoxSpacing}
            labelText="Date of injury"
            descText={
              summaryViewValues.dateOfInjury
                ? summaryViewValues.dateOfInjury?.toDayDefaultFormat()
                : "Nil"
            }
          />
          <StateBlock
            styles={stateBoxSpacing}
            labelText="Date of surgery"
            descText={
              summaryViewValues.dateOfSurgery
                ? summaryViewValues.dateOfSurgery?.toDayDefaultFormat()
                : "Nil"
            }
          />
        </Stack>
        <DiagnosisList
          descriptionHeaderText="Initial diagnoses"
          diagnoses={summaryViewValues.initialDiagnoses.map(x => ({
            description: x.terminology?.text,
            side: x.diagnosisSide
          }))}
        />
        <DiagnosisList
          descriptionHeaderText="Current diagnoses"
          diagnoses={summaryViewValues.currentDiagnoses.map(x => ({
            description: x.terminology?.text,
            side: x.diagnosisSide
          }))}
        />
      </Stack>
    </ClaimCard>
  );
});
