import { createContext, FC, PropsWithChildren, useContext } from "react";

import { useStores } from "@stores/hooks/useStores.ts";

import { ReportScreenHelper } from "./ReportScreenHelper.ts";

const ReportScreenContext = createContext<ReportScreenHelper>(
  {} as ReportScreenHelper
);

export const useReportScreenContext = (): ReportScreenHelper => {
  return useContext<ReportScreenHelper>(ReportScreenContext);
};

export const ReportsScreenContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const root = useStores();
  return (
    <ReportScreenContext.Provider value={new ReportScreenHelper(root)}>
      {children}
    </ReportScreenContext.Provider>
  );
};
