import { useField } from "react-final-form";

import { Stack, useTheme } from "@bps/fluent-ui";
import {
  ClinicalNoteFormat,
  ClinicalWorkflowFormat,
  DockView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UserLabels } from "../../labels.ts";
import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import { UserClinicalFormLabel } from "./UserClinicalFormLabel.tsx";

interface ConsultationNotesSettingsFromProps {}

export const ConsultationNotesSettingsFormFields: React.FC<
  ConsultationNotesSettingsFromProps
> = () => {
  const { userExperience, core } = useStores();

  const theme = useTheme();
  const { formFields } = getUserStylesSet(theme);

  const {
    input: { value: clinicalWorkflow }
  } = useField("clinicalWorkflow", {
    subscription: { value: true }
  });

  const defaultDockViewOptions = () => {
    if (clinicalWorkflow !== ClinicalWorkflowFormat.SOTAP) {
      return userExperience.ref.dockViews.keyTextValues.filter(
        x => x.key !== DockView.Sotap
      );
    } else {
      return userExperience.ref.dockViews.keyTextValues;
    }
  };

  return (
    <Fieldset
      styles={{
        root: formFields
      }}
    >
      <Stack>
        {/* the workflow containing clinicalWorkflowDropdown has been removed as part of this ticket: 
                https://dev.azure.com/bpcloud-dev/BpCloud/_workitems/edit/40499/ */}

        <UserClinicalFormLabel
          label={UserLabels.notesFormat}
          description={UserLabels.notesFormatDescription}
        />
        {core.isNZTenant ? (
          <DropdownField
            className="notesFormatDropdown"
            options={[
              {
                key: ClinicalNoteFormat.Default,
                text: "Default"
              },
              {
                key: ClinicalNoteFormat.SOTAP,
                text: ClinicalNoteFormat.SOTAP
              },
              {
                key: ClinicalNoteFormat.SOAP,
                text: ClinicalNoteFormat.SOAP
              }
            ]}
            name={nameof("clinicalNotesFormat")}
            styles={{
              root: {
                width: "100%",
                marginBottom: 40
              }
            }}
          />
        ) : (
          <DropdownField
            className="notesFormatDropdown"
            options={[
              {
                key: ClinicalNoteFormat.Default,
                text: "Default"
              },
              {
                key: ClinicalNoteFormat.SOAP,
                text: ClinicalNoteFormat.SOAP
              }
            ]}
            name={nameof("clinicalNotesFormat")}
            styles={{
              root: {
                width: "100%",
                marginBottom: 40
              }
            }}
          />
        )}

        <UserClinicalFormLabel
          label={UserLabels.defaultDockedTab}
          description={UserLabels.dockViewControlInfo}
        />
        <DropdownField
          className="defaultDockView"
          options={defaultDockViewOptions()}
          name={nameof("defaultDockView")}
          styles={{
            root: {
              width: "100%",
              marginBottom: 40
            }
          }}
        />
        <Stack horizontal>
          <UserClinicalFormLabel
            label={UserLabels.captureDiscardConsultReason}
          />
          <ToggleField
            name={nameof("enforceDiscardConsultReason")}
            onText="required"
            offText="optional"
            styles={{
              root: { paddingLeft: "10px" },
              text: { selectors: { "&.ms-Label": { fontWeight: 600 } } } // This selector is because the existing font-weight cannot be overridden without using 2+ selectors.  Please update this if a better method is found.
            }}
          />
        </Stack>

        <When permission={Permission.EncounterTimerAllowed}>
          <Stack horizontal>
            <UserClinicalFormLabel label={UserLabels.timerEnabled} />
            <ToggleField
              name={nameof("timerEnabled")}
              onText="automatically when consultations start"
              offText="manually"
              styles={{
                root: { paddingLeft: "10px" },
                text: { selectors: { "&.ms-Label": { fontWeight: 600 } } } // This selector is because the existing font-weight cannot be overridden without using 2+ selectors.  Please update this if a better method is found.
              }}
            />
          </Stack>
        </When>
      </Stack>
    </Fieldset>
  );
};
