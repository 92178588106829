import { observer } from "mobx-react-lite";

import { CenteredLargeSpinner, FontSizes, Heading, Text } from "@bps/fluent-ui";
import { ModalLabels } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentEnums.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { useClaimAdjustmentContext } from "../context/ClaimAdjustmentContext.ts";
import { ModalKeys } from "../context/ClaimAdjustmentHelper.types.ts";
import { ClaimAdjustmentModalValues } from "../types/claim-adjustment-modal-values.interface.ts";
import { ClaimAdjustmentModalValidator } from "../validators/ClaimAdjustmentModalValidator.tsx";
import { ClaimAdjustmentModalFields } from "./ClaimAdjustmentModalFields.tsx";
import { InjuryDiagnosisList } from "./InjuryDiagnosisList.tsx";
import { getClaimAdjustmentModalValues } from "./utils.ts";

interface ClaimManagementModalProps {
  claim: Claim;
  onSubmitSucceeded?: (claimAdjustment: ClaimAdjustment) => void;
  onSubmitOverride?: (values: ClaimAdjustmentModalValues) => Promise<void>; // on submit logic goes here
}

const validator = new ClaimAdjustmentModalValidator();

export const ClaimAdjustmentDialogForm: React.FC<ClaimManagementModalProps> =
  observer(({ claim, onSubmitSucceeded, onSubmitOverride }) => {
    const {
      saveClaimAdjustment,
      currentOpenModal,
      claimAdjustment,
      setOpenModal,
      setSubmitOptions,
      handleSubmitSucceeded
    } = useClaimAdjustmentContext();

    if (currentOpenModal !== ModalKeys.AdjustmentModal) {
      return null;
    }

    const initialValues = getClaimAdjustmentModalValues(claimAdjustment);

    const modalHeading = `${ModalLabels.heading} ${claim.claimNumber ?? ""}`;

    return (
      <DataFetcher
        fetch={async () => {
          await Promise.all([claim.loadPatient(), claim.loadProvider()]);
        }}
        noExceptionsHandlers
      >
        {(_data, loading, error) => (
          <SubmissionFormDialog
            loadingData={loading}
            dataLoadingError={error?.message}
            Fallback={<CenteredLargeSpinner />}
            onSubmitSucceeded={() => {
              if (!claimAdjustment?.id) {
                setSubmitOptions({
                  redirect: true,
                  lodge: false,
                  create: true
                });
              }
              handleSubmitSucceeded(onSubmitSucceeded);
            }}
            onSubmit={async values => {
              if (onSubmitOverride) {
                await onSubmitOverride(values);
                return;
              } else {
                await saveClaimAdjustment(values);
              }
            }}
            dialogName={ModalLabels.heading}
            dialogProps={{
              onDismiss: setOpenModal,
              minWidth: 480,
              maxWidth: 480,
              dialogContentProps: {
                title: <Heading variant="modal-heading">{modalHeading}</Heading>
              }
            }}
            initialValues={initialValues}
            buttonsProps={{
              hideButtonsSeparator: true,
              disableSubmitOnPristine: false,
              submitButtonProps: {
                text: claimAdjustment?.id
                  ? "Amend current ACC32"
                  : "Create new ACC32",
                iconProps: { iconName: "Add" }
              }
            }}
            validate={validator.validate}
          >
            {() => (
              <>
                <Text styles={{ root: { fontSize: FontSizes.size12 } }}>
                  Current diagnosis is
                </Text>
                {claim.claimDiagnosis && (
                  <InjuryDiagnosisList
                    claimDiagnosis={claim.currentDiagnoses}
                  />
                )}
                <ClaimAdjustmentModalFields />
              </>
            )}
          </SubmissionFormDialog>
        )}
      </DataFetcher>
    );
  });
