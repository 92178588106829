import { ReactNode } from "react";

import { FontWeights, MessageBar, MessageBarType } from "@bps/fluent-ui";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

interface TransactionAdjustedBarBaseProps {
  userName: string;
  transactionName: string;
  adjustmentName: string;
  updatedDate?: string;
  reason: ReactNode;
}

export enum AdjustmentName {
  adjustedBy = "adjusted",
  cancelledBy = "cancelled"
}

export const getViewInfoText = (args: {
  userName: string;
  actionText: string;
  updatedDate: string | undefined;
  transaction: string;
}) => {
  const { userName, actionText, updatedDate, transaction } = args;
  let text = `This ${transaction} has been ${actionText} `;
  if (updatedDate) {
    text += `on ${updatedDate} `;
  }
  text += `by ${userName}`;
  return text;
};

export const TransactionAdjustedBarBase: React.FC<
  TransactionAdjustedBarBaseProps
> = ({ updatedDate, userName, transactionName, adjustmentName, reason }) => {
  return (
    <UserFetcher username={userName}>
      {user => (
        <MessageBar messageBarType={MessageBarType.warning}>
          {getViewInfoText({
            userName: user.name,
            updatedDate,
            actionText: adjustmentName,
            transaction: transactionName
          })}
          <span
            style={{
              fontWeight: FontWeights.bold,
              marginLeft: 8
            }}
          >
            Reason:
          </span>
          <span> {reason}</span>
        </MessageBar>
      )}
    </UserFetcher>
  );
};
