import {
  greaterThan,
  lessThanOrSame,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  ACCOUNT_CREDIT_METHOD,
  PaymentFormValues
} from "../shared-components/allocation-form/components/AllocationForm.types.ts";

export class PaymentsFormValidator extends Validator<PaymentFormValues> {
  constructor(accountCreditTotal?: number) {
    super();
    this.forField("method", [required()]);
    this.forField(
      "amount",
      predicate(
        (value, parent: PaymentFormValues) =>
          parent.method === ACCOUNT_CREDIT_METHOD && !!accountCreditTotal,
        lessThanOrSame(accountCreditTotal || 0)
      )
    );
    this.forField("amount", [required(), greaterThan(0)]);
  }
}
