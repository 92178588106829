import { FunctionComponent } from "react";

import {
  ButtonsGroupOption,
  DirectionalHint,
  FontIcon,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { CorrespondenceVisibility } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";

export interface VisibilityAndConfidentialityFieldProps {
  isEdit?: boolean;
  name: string;
}

export const VisibilityAndConfidentialityField: FunctionComponent<
  VisibilityAndConfidentialityFieldProps
> = props => {
  const theme = useTheme();

  const { core } = useStores();

  const renderConfidentialText = (text: ButtonsGroupOption<string>) => {
    return (
      <TooltipHost
        content="The document won't be available for other users"
        directionalHint={DirectionalHint.bottomCenter}
        styles={{
          root: {
            paddingTop: 3
          }
        }}
      >
        <FontIcon
          iconName="ShieldAlert"
          styles={{
            root: {
              color: theme.palette.neutralPrimary,
              paddingRight: 5
            }
          }}
        />
        <Text>{text.text}</Text>
      </TooltipHost>
    );
  };

  const renderTimelineText = (text: ButtonsGroupOption<string>) => {
    return (
      <>
        <FontIcon
          iconName="Clock"
          styles={{
            root: {
              color: theme.palette.neutralPrimary,
              paddingRight: 5
            }
          }}
        />
        <Text>{text.text}</Text>
      </>
    );
  };

  const options = () => {
    const optionList: ButtonsGroupOption<CorrespondenceVisibility>[] = [];

    if (core.hasPermissions(Permission.ConfidentialDataAllowed)) {
      optionList.push({
        key: CorrespondenceVisibility.Confidential,
        text: "Internally confidential",
        onRenderText: x => renderConfidentialText(x)
      });
    }

    if (!props.isEdit) {
      optionList.push({
        key: CorrespondenceVisibility.DisplayOnTimeline,
        text: "Display on timeline",
        onRenderText: x => renderTimelineText(x)
      });
    }

    return optionList;
  };

  return (
    <ButtonsGroupSingleChoiceField
      name={props.name}
      options={options()}
      styles={{ root: { marginRight: "8px" } }}
      label="Visibility & confidentiality"
    />
  );
};
