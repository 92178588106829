import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useField } from "react-final-form";

import { mergeStyleSets } from "@bps/fluent-ui";
import {
  compareDatesPredicate,
  DateTime,
  isDefined,
  TIME_FORMATS
} from "@bps/utils";
import { Claim } from "@stores/acc/models/Claim.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  OptionsSelectField,
  OptionsSelectFieldProps
} from "@ui-components/form/selects/OptionsSelectField.tsx";

const INITIAL_NUM_OF_OPTIONS = 3;
interface PatientAppointmentPickerFieldProps
  extends Omit<
    OptionsSelectFieldProps,
    "options" | "loading" | "errorMessage"
  > {
  options: { claim?: Claim; patientId?: string };
}
export const PatientCalendarEventPickerField: FunctionComponent<PatientAppointmentPickerFieldProps> =
  observer(props => {
    const { options, styles: propsStyles, ...pickerProps } = props;
    const { booking } = useStores();

    // Try get calendarEvents from calendarEventMap so that it can appear in the input
    //  while the rest of the calendarEvents are loaded
    const {
      input: { value: calendarEventId }
    } = useField(pickerProps.name, { subscription: { value: true } });

    const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>(
      () => {
        const selectedCalendarEvent = calendarEventId
          ? booking.calendarEventsMap.get(calendarEventId)
          : undefined;

        return selectedCalendarEvent ? [selectedCalendarEvent] : [];
      }
    );

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
      undefined
    );

    const isMounted = useRef<boolean>(true);

    useEffect(() => {
      let fetchCalendarEvents: (() => Promise<CalendarEvent[]>) | undefined;

      const twelveMonthsAgo = DateTime.now().minus({ years: 1 });

      if (options.claim) {
        fetchCalendarEvents = async () => {
          const claimAppointments =
            await options.claim?.loadClaimAppointments();
          if (!claimAppointments || claimAppointments.length === 0) {
            return [];
          }

          const calendarEventIds = claimAppointments
            .map(claimAppointment => claimAppointment.calendarEventId)
            .filter(isDefined);

          const calendarEvents = await booking.getCalendarEvents({
            calendarEventIds,
            startTime: twelveMonthsAgo.toISODate()
          });

          return calendarEvents.results;
        };
      } else if (options.patientId) {
        fetchCalendarEvents = () =>
          booking
            .getCalendarEvents({
              attendees: options.patientId ? [options.patientId] : undefined,
              startTime: twelveMonthsAgo.toISODate()
            })
            .then(calendarEvents => calendarEvents.results);
      }
      if (!fetchCalendarEvents) {
        setCalendarEvents([]);
        return;
      }

      fetchCalendarEvents()
        .then(newCalendarEvents => {
          if (isMounted.current) {
            const sortedCalendarEvents = Array.from(newCalendarEvents).sort(
              (caA, caB) =>
                compareDatesPredicate(
                  caA.startDateTime,
                  caB.startDateTime,
                  true
                )
            );

            setCalendarEvents(sortedCalendarEvents);
          }
        })
        .catch(e => {
          if (isMounted.current) {
            setErrorMessage(e.message);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false);
          }
        });
    }, [options, booking]);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    const selectOptions = calendarEvents.map(ca => {
      const date = ca.startDateTime?.toDayDefaultFormat();

      const time = ca.startDateTime?.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

      const text = `${date} — ${time}`;

      return {
        key: ca.id,
        text
      };
    });

    const styles = mergeStyleSets(
      { fieldContent: { justifyContent: "flex-start" } },
      propsStyles
    );

    // Select component internal state needs to reset when claim or patient is changed, so key is used
    const optionsSelectKey = options.claim?.id || options.patientId;

    return (
      <OptionsSelectField
        key={optionsSelectKey}
        disabled={!options.patientId && !options.claim}
        hideSearchOption={true}
        multiSelect={false}
        styles={styles}
        {...pickerProps}
        options={selectOptions}
        loading={isLoading}
        errorMessage={errorMessage}
        showMoreOptionProps={{
          initialNumOfOptions: INITIAL_NUM_OF_OPTIONS,
          onRenderShowMore: (num: number) => `Reveal ${num} previous visits`
        }}
      />
    );
  });
