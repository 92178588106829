import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useField } from "react-final-form";

import { DefaultButton, Heading, Stack, useTheme } from "@bps/fluent-ui";
import { MedicalHistoryClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";

import { MedicalHistoryFormDialog } from "../medical-history/MedicalHistoryFormDialog.tsx";
import { nameOfPrescribingDoseForm } from "./PrescribingDoseForm.tsx";

export const ReasonForPrescriptionField: React.FC = observer(() => {
  const theme = useTheme();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const {
    input: { value: reasonForPrescription }
  } = useField(nameOfPrescribingDoseForm("reasonForPrescription"));

  const [isMedicalHistoryDialogVisible, setIsMedicalHistoryDialogVisible] =
    useState<boolean>(false);

  const [selectedDiagnosis, setSelectedDiagnosis] = useState<
    MedicalHistoryClinicalDataItemDto | undefined
  >();

  const reasonForVisit =
    clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits ?? [];

  const medicalHistories =
    clinicalRecord.clinicalData?.medicalHistory?.medicalHistories ?? [];

  const activeMedHxItems = medicalHistories
    .filter(x => x.active && !!x.id)
    .map(x => {
      const originalText = x.diagnosisCode?.originalText ?? "";
      return {
        key: x.id!,
        text: originalText
      };
    });

  const chosenMedHx = medicalHistories.find(
    x => x.id === reasonForPrescription
  );

  const isVisitReasonsIncludeRFP =
    !!chosenMedHx &&
    !!reasonForVisit.some(
      x => x.originalText === chosenMedHx.diagnosisCode?.originalText
    );

  const isDisplayRFCcheckBox = isVisitReasonsIncludeRFP || !chosenMedHx;

  const openMedicalHistoryDialog = (
    item?: MedicalHistoryClinicalDataItemDto
  ) => {
    setSelectedDiagnosis(item);
    setIsMedicalHistoryDialogVisible(true);
  };

  const closeMedicalHistoryDialog = () => {
    setSelectedDiagnosis(undefined);
    setIsMedicalHistoryDialogVisible(false);
  };
  return (
    <Stack
      styles={{
        root: {
          border: `1px ${theme.semanticColors.variantBorder} solid`,
          padding: 8
        }
      }}
      tokens={{ childrenGap: 8 }}
      horizontal
    >
      <Stack
        styles={{
          root: {
            flex: 1
          }
        }}
        tokens={{ childrenGap: 4 }}
      >
        <Stack
          horizontal
          styles={{
            root: {
              flex: 1,
              justifyContent: "space-between"
            }
          }}
        >
          <Heading>Reason for prescription</Heading>
          {!isDisplayRFCcheckBox && (
            <CheckboxField
              name={nameOfPrescribingDoseForm("saveRFPAsRFC")}
              automationAttribute=""
              label="Reason for consult"
            />
          )}
        </Stack>

        <OptionsSelectField
          placeholder="Select a reason"
          name={nameOfPrescribingDoseForm("reasonForPrescription")}
          options={activeMedHxItems}
        />
      </Stack>
      <Stack verticalAlign="end">
        <DefaultButton
          text="Add new"
          iconProps={{
            iconName: "Add"
          }}
          styles={{ root: { minWidth: 125 } }}
          onClick={() => openMedicalHistoryDialog()}
        />
      </Stack>
      <MedicalHistoryFormDialog
        isPrescribed
        hidden={!isMedicalHistoryDialogVisible}
        selectedDiagnosis={selectedDiagnosis}
        onDismiss={closeMedicalHistoryDialog}
        medicalHistories={medicalHistories}
      />
    </Stack>
  );
});
