import React, { FunctionComponent } from "react";

import {
  FontIcon,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";

interface OnlineBookingBadgeProps {
  isAvailableExistingPatients?: boolean;
  isAvailableNewPatients?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const OnlineBookingBadge: FunctionComponent<OnlineBookingBadgeProps> = ({
  isAvailableExistingPatients,
  isAvailableNewPatients,
  onClick
}) => {
  let badgeLabel: string = "Unavailable";
  let badgeColor: TextBadgeColor = TextBadgeColor.lightGrey;

  if (isAvailableExistingPatients && isAvailableNewPatients) {
    badgeLabel = "All patients";
    badgeColor = TextBadgeColor.green;
  } else if (isAvailableExistingPatients) {
    badgeLabel = "Existing only";
    badgeColor = TextBadgeColor.yellow;
  } else if (isAvailableNewPatients) {
    badgeLabel = "New only";
    badgeColor = TextBadgeColor.yellow;
  }

  return (
    <TextBadge
      horizontal
      badgeColor={badgeColor}
      badgeSize={TextBadgeSize.medium}
      onClick={onClick}
    >
      <FontIcon iconName="Globe" styles={{ root: { marginRight: 4 } }} />
      {badgeLabel}
    </TextBadge>
  );
};
