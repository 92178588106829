// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { useField, useFormState } from "react-final-form";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { DrawingCanvas } from "../../notes/draw/DrawingCanvas.tsx";
import { InjuryImagesHelper } from "../context/InjuryImagesHelper.tsx";
import { sotapNameOf } from "../SOTAP.utils.ts";

enum HipImageType {
  frontHipImage = "0",
  backHipImage = "1",
  leftSideHipImage = "2",
  rightSideHipImage = "3"
}

export const HipInjuryImageAssessment: React.FC = () => {
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const sex = clinicalRecord.patient?.sex;

  const {
    input: { onChange: onFrontHipImage }
  } = useField(sotapNameOf("frontHipImage"));

  const {
    input: { onChange: onBackHipImage }
  } = useField(sotapNameOf("backHipImage"));

  const {
    input: { onChange: onLeftSideHipImage }
  } = useField(sotapNameOf("leftSideHipImage"));

  const {
    input: { onChange: onRightSideHipImage }
  } = useField(sotapNameOf("rightSideHipImage"));

  const hipProps = InjuryImagesHelper.getHipProperties(sex);
  const images = InjuryImagesHelper.mapImagesOnPart(hipProps, initialValues);

  const handleOnChange = (
    canvas: Canvas,
    event: any,
    onChangeFunc: (x: any) => void
  ) => {
    if (event.target.dirty) {
      onChangeFunc(canvas?.toSVG());
    } else {
      onChangeFunc(undefined);
    }
  };
  return (
    <DrawingCanvas
      isViewOnly={
        isViewOnlyOrDischarged ||
        clinicalRecord.dischargeInProgressOrCompleted()
      }
      showQuickColourPicker={!isViewOnlyOrDischarged}
      initialSelectedImage={0}
      caption="Diagram"
      images={images}
      onChange={(canvas: Canvas, event: any) => {
        switch (canvas["lowerCanvasEl"].id as HipImageType) {
          case HipImageType.frontHipImage:
            handleOnChange(canvas, event, onFrontHipImage);
            break;
          case HipImageType.backHipImage:
            handleOnChange(canvas, event, onBackHipImage);
            break;
          case HipImageType.leftSideHipImage:
            handleOnChange(canvas, event, onLeftSideHipImage);
            break;
          case HipImageType.rightSideHipImage:
            handleOnChange(canvas, event, onRightSideHipImage);
            break;
          default:
            return;
        }
      }}
    />
  );
};
