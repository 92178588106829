import { useContext } from "react";

import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";

import { AccidentAndEmploymentDetailsViewSection } from "./AccidentAndEmploymentDetailsViewSection.tsx";
import { InjuryDiagnosisViewSection } from "./InjuryDiagnosisAndAssistanceViewSection.tsx";
import { PatientDeclarationViewSection } from "./PatientDeclarationViewSection.tsx";
import { PersonalDetailsViewSection } from "./PersonalDetailViewSection.tsx";
import { ProviderDeclarationViewSection } from "./ProviderDeclarationViewSection.tsx";
import { ReferralsOutViewSection } from "./ReferralsOutViewSection.tsx";

export const ClaimViewFormBody: React.FC = () => {
  const { claim } = useContext(ClaimContext);
  return (
    <>
      <PersonalDetailsViewSection />
      <AccidentAndEmploymentDetailsViewSection />
      <PatientDeclarationViewSection />
      <InjuryDiagnosisViewSection />
      <ProviderDeclarationViewSection />
      {!!claim.referralDetails?.length && <ReferralsOutViewSection />}
    </>
  );
};
