import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { PersonaSize, Stack } from "@bps/fluent-ui";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { AppointmentFormContext } from "../context/AppointmentFormContext.ts";
import { MoreLessToggleTimeSlots } from "./MoreLessToggleTimeSlots.tsx";

interface ProviderTimeSlotsHeaderProps {
  moreText: string;
  isExpanded: boolean;
  toggleIsExpanded: () => void;
  providerId: string;
}

export const ProviderTimeSlotsHeader: FunctionComponent<ProviderTimeSlotsHeaderProps> =
  observer(({ moreText, isExpanded, providerId, toggleIsExpanded }) => {
    const { selectedProviders, core } = useContext(AppointmentFormContext);

    const user = core.userMap.get(providerId);
    const isSingleProvider = selectedProviders.length === 1;

    return (
      <Stack
        horizontal
        verticalAlign="center"
        styles={{ root: { alignItems: "baseline" } }}
        tokens={{ childrenGap: 8 }}
      >
        <Persona
          text={user?.name}
          id={user?.id}
          size={PersonaSize.size24}
          imageInitials={user?.initials}
          styles={{ root: { flexGrow: 1 } }}
        />

        {!isSingleProvider && (
          <MoreLessToggleTimeSlots
            text={moreText}
            onChange={() => toggleIsExpanded()}
            isExpanded={isExpanded}
          />
        )}
      </Stack>
    );
  });
