import {
  DvaCardColour,
  type DvaDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

const isValidDvaNumber = (val: string) => {
  const trimmedVal = val.trim();

  let returnStr;
  if (!(trimmedVal.length === 8 || trimmedVal.length === 9)) {
    returnStr = "Dva Number must be 8 or 9 characters.";
  } else {
    if (!new RegExp("[NVQSTW]").test(val.trim())) {
      returnStr = "1st character must be a one of NVQSTW";
    }
    if (!new RegExp("[0-9A-Z]{7}", "i").test(trimmedVal.trim().substr(1))) {
      returnStr = `${
        returnStr === undefined ? "" : `${returnStr}, `
      }Characters 2 - 8 must be alphanumeric`;
    }
    if (
      trimmedVal.length > 8 &&
      !new RegExp("[A-Z]", "i").test(trimmedVal.substr(8))
    ) {
      returnStr = `${
        returnStr === undefined ? "" : `${returnStr}, `
      }9th character must be alpha`;
    }
    if (returnStr) {
      returnStr = returnStr.trim();
    }
  }
  return returnStr;
};

const dvaNumber: FieldValidator = (val: string | undefined) => {
  return !val || isValidDvaNumber(val);
};

export class DvaValidator extends Validator<DvaDto> {
  constructor() {
    super();
    const empty = (x?: DvaDto) => (x ? !x.number && !x.cardColor : true);
    const dvaWhite = (x?: DvaDto) =>
      x ? !!x.cardColor && x.cardColor === DvaCardColour.White : true;

    this.forField("number", [
      predicate((val: string, dva?: DvaDto) => !empty(dva), required()),
      dvaNumber
    ]);

    this.forField("cardColor", [
      predicate((val: string, dva?: DvaDto) => !empty(dva), required())
    ]);

    this.forField("disability", [
      predicate((val: string, dva?: DvaDto) => dvaWhite(dva), required()),
      maxLength(100)
    ]);
  }
}
