import React, { useContext } from "react";
import { useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FontWeights,
  SplitButton,
  Stack,
  TopBarDefaultHeader
} from "@bps/fluent-ui";
import { ClaimAdjustmentStatus } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimPivotTabs } from "@modules/acc/screens/shared-components/ClaimPivotTabs.tsx";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { ClaimFormDataAttributes } from "../../claim/components/ClaimFormEnums.ts";
import { CLAIM_ADJUSTMENT_FORM_ID } from "./ClaimAdjustmentForm.tsx";

export const ClaimAdjustmentFormHeader: React.FC = () => {
  const { routing, practice } = useStores();

  const {
    claim,
    claimAdjustment,
    isLodgeDisabled,
    setSubmitOptions,
    isFinaliseDisabled
  } = useContext(ClaimAdjustmentContext);

  const accountContact = claim.insurerContactId
    ? practice.contactsMap.get(claim.insurerContactId)
    : undefined;

  const isNotNzPublicInsurer =
    !accountContact?.organisationRoles ||
    !practice.isNzPublicInsurer(accountContact.organisationRoles);

  const { values } = useFormState<ClaimAdjustmentFormValues>();

  const handleLodgeOnClick = async () => {
    setSubmitOptions({
      redirect: true,
      claimStatus: ClaimAdjustmentStatus.Ready,
      lodge: true
    });
  };

  const submitButtonProps = claimAdjustment.verballyApproved
    ? {
        text: "Finalise",
        onClick: () => handleLodgeOnClick(),
        iconProps: { hidden: true },
        disabled: isFinaliseDisabled(values)
      }
    : {
        text: isNotNzPublicInsurer ? "Send email" : "Lodge",
        onClick: () => handleLodgeOnClick(),
        iconProps: { hidden: true },
        disabled: isLodgeDisabled(values)
      };

  const defaultButtons = (
    <FormSubmitButtons
      styles={{
        root: {
          marginTop: 0,
          borderTopColor: "none",
          backgroundColor: "inherit",
          paddingTop: 0
        }
      }}
      hideButtonsSeparator
      submitButtonProps={submitButtonProps}
      disableSubmitOnPristine
      extraActionsBetween={(form, isPrimaryDisabled) => (
        <Stack.Item styles={{ root: { flexShrink: 0 } }}>
          <SplitButton
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.saveDraft
            )}
            text="Save"
            form={CLAIM_ADJUSTMENT_FORM_ID}
            disabled={isPrimaryDisabled}
            onClick={() =>
              setSubmitOptions({
                redirect: false,
                lodge: false
              })
            }
            type="submit"
            items={[
              {
                key: "saveClose",
                onRender: () => (
                  <DefaultButton
                    onClick={() =>
                      setSubmitOptions({
                        redirect: true,
                        lodge: false
                      })
                    }
                    type="submit"
                    form={CLAIM_ADJUSTMENT_FORM_ID}
                    disabled={isPrimaryDisabled}
                    styles={{
                      root: {
                        border: "none",
                        width: "100%",
                        textAlign: "left",
                        padding: "0px 8px 0px 4px",
                        height: "36px"
                      },
                      label: {
                        fontWeight: FontWeights.regular
                      }
                    }}
                  >
                    Save & close
                  </DefaultButton>
                )
              }
            ]}
          />
        </Stack.Item>
      )}
      onCancel={() => {
        routing.push(
          {
            pathname: routes.claims.management.edit.path({
              id: claim.id!
            })
          },
          { openACC32Card: true }
        );
      }}
    />
  );

  return (
    <TopBarDefaultHeader
      hideBackButton
      leftContainer={<ClaimPivotTabs />}
      leftContainerStyles={{ root: { paddingLeft: 0 } }}
      rightContainer={defaultButtons}
    />
  );
};
