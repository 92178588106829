import React from "react";

import {
  dataAttribute,
  DataAttributes,
  ChoiceGroup as FluentChoiceGroup,
  FontWeights,
  Grid,
  Slider,
  SpinButton,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  ClinicalDataTypeLabel,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { SliderField } from "@ui-components/form/SliderField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { ClinicalToolReferences } from "../ClinicalTool.types.ts";
import { ClinicalToolInformation } from "../shared-components/ClinicalToolInformation.tsx";
import {
  ClinicalToolAnswer,
  findClinicalToolAnswer,
  getSliderConstraints
} from "../utils/ClinicalToolAnswerFinder.ts";
import { MAX_INPUT } from "../utils/clinicalToolsUtils.ts";

interface OREBROQuestionsProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
}

export const OREBROQuestions: React.FunctionComponent<OREBROQuestionsProps> = ({
  questionnaire,
  answers
}) => {
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const findAnswer = (questionId: string): string => {
    let answerValue = "";
    if (answers) {
      const answer = findClinicalToolAnswer(questionId, answers);
      if (answer) answerValue = answer.answerValue!;
    }
    return answerValue;
  };
  return (
    <Stack
      tokens={{ childrenGap: 25 }}
      styles={{ root: { overflowY: "auto", paddingRight: 8 } }}
    >
      <ClinicalToolInformation
        clinicalToolType={ClinicalDataTypeLabel.OREBRO}
        paragraphs={ClinicalToolReferences.OREBRO}
      />
      {questionnaire.items.map(item => {
        const constraints = getSliderConstraints(item);
        const elementName = `q${item.id}`;
        const readyAnswer = findClinicalToolAnswer(elementName, answers);
        if (item.id === 1) {
          return (
            <React.Fragment key={item.id}>
              <Stack tokens={{ childrenGap: 8 }} horizontal>
                <Text>{item.id}.</Text>
                <Text
                  styles={(props, theme) => ({
                    root: {
                      fontWeight: FontWeights.semibold,
                      color: answers && theme.semanticColors.disabledText
                    }
                  })}
                >
                  {item.text}
                </Text>
              </Stack>

              <Grid
                templateColumns="repeat(2, 150px)"
                templateRows="repeat(5, 20px)"
                styles={{ root: { gridAutoFlow: "column" } }}
                childrenTokens={{ rowGap: 8 }}
              >
                {item.answerOptions?.map(option => (
                  <div key={`${item.id}_${option.value}`}>
                    {!answers ? (
                      <ChoiceGroupField
                        name={`q${item.id}`}
                        options={[
                          {
                            key: `${option.value}`,
                            value: option.value.toString(),
                            text: option.text
                          }
                        ]}
                      />
                    ) : (
                      <FluentChoiceGroup
                        name={`q${item.id}`}
                        options={[
                          {
                            key: `${option.value}`,
                            value: option.value.toString(),
                            text: option.text
                          }
                        ]}
                        disabled={true}
                        styles={{
                          root: {
                            selectors: {
                              "& .ms-ChoiceField": { margin: 0, height: 34 }
                            }
                          }
                        }}
                        defaultSelectedKey={findAnswer(`q${item.id}`)}
                      />
                    )}
                  </div>
                ))}
              </Grid>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={item.id}>
              <Stack
                className="orebro-row"
                styles={(props, theme) => ({
                  root: item.id % 2 === 0 && {
                    selectors: {
                      "&.orebro-row": {
                        backgroundColor: theme.palette.neutralLighterAlt
                      }
                    }
                  }
                })}
              >
                <Stack horizontal tokens={{ childrenGap: 12, padding: 8 }}>
                  <Stack.Item>
                    <Text>{item.id}.</Text>
                  </Stack.Item>
                  {!isViewOnlyOrDischarged && (
                    <Stack.Item
                      styles={{
                        root: {
                          width: "100%"
                        }
                      }}
                    >
                      {answers && readyAnswer ? (
                        <Slider
                          label={item.text}
                          disabled={true}
                          value={Number(readyAnswer.answerValue)}
                          titles={{
                            min: item.minTitle ?? "",
                            max: item.maxTitle ?? ""
                          }}
                        />
                      ) : (
                        <SliderField
                          name={elementName}
                          {...constraints}
                          label={item.text}
                          snapToStep
                          titles={{
                            min: item.minTitle ?? "",
                            max: item.maxTitle ?? ""
                          }}
                        />
                      )}
                    </Stack.Item>
                  )}
                  <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    tokens={{ childrenGap: 8 }}
                  >
                    <Stack.Item>
                      {answers && readyAnswer ? (
                        <SpinButton
                          disabled={true}
                          value={readyAnswer.answerValue}
                          styles={{ root: { maxWidth: 82 } }}
                          {...dataAttribute(
                            DataAttributes.Element,
                            elementName
                          )}
                        />
                      ) : (
                        <SpinNumberInputField
                          name={elementName}
                          styles={{ root: { maxWidth: 82 } }}
                          {...constraints}
                          {...dataAttribute(
                            DataAttributes.Element,
                            elementName
                          )}
                          parse={value =>
                            Number(value) > MAX_INPUT ? MAX_INPUT : value
                          }
                        />
                      )}
                    </Stack.Item>
                  </Stack>
                </Stack>
              </Stack>
            </React.Fragment>
          );
        }
      })}
    </Stack>
  );
};
