import { Stack } from "@bps/fluent-ui";
import { Statement } from "@stores/billing/models/Statement.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

export const StatementContactCell: React.FC<{ statement: Statement }> = ({
  statement
}) => {
  return (
    <ContactFetcher contactId={statement.accountContactId}>
      {contact => (
        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
          {contact.name}
        </Stack>
      )}
    </ContactFetcher>
  );
};
