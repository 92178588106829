import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";
import { useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FieldItem,
  Heading,
  HideStack,
  MessageBar,
  MessageBarType,
  noWrap,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import {
  MedicalCertainty,
  MedicalHistoryClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { ButtonsChoiceGroupField } from "@ui-components/form/ButtonsGroupChoiceField.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { TerminologyPickerField } from "../clinical-form/TerminologyPickerField.tsx";
import { MedicalHistoryDialogLabels } from "./MedicalHistory.types.ts";
import { MedicalHistoryDateErrorMessage } from "./MedicalHistoryDateErrorMessage.tsx";
import { MedicalHistoryDateFields } from "./MedicalHistoryDateFields.tsx";
import {
  MedicalHistoryFormValues,
  TerminologyData
} from "./MedicalHistoryFormValues.ts";
import { getClaimStatusText } from "./utils.ts";

export const medicalHistoryFormNameOf =
  nameOfFactory<MedicalHistoryFormValues>();

export interface EditMedicalHistoryFormProps {
  terminologyData: TerminologyData | undefined;
  patient?: Contact;
  medicalHistory?: MedicalHistoryClinicalDataItemDto;
  medicalHistories?: MedicalHistoryClinicalDataItemDto[];
}
const DEFAULT_TAKE = 50;

export const MedicalHistoryFormFieldsBase: FunctionComponent<EditMedicalHistoryFormProps> =
  observer(({ terminologyData, patient, medicalHistory, medicalHistories }) => {
    const form = useForm<MedicalHistoryFormValues>();

    const {
      clinicalRecord,
      showPrimaryDiagnosis,
      canLinkPrimaryDiagnosis,
      checkDiagnoseEditability
    } = usePatientRecordScreenContext();

    const { clinical, core } = useStores();

    const isDiagnoseEditable = checkDiagnoseEditability(
      medicalHistory?.episodeOfCareId
    );

    const procCertaintyOptions =
      clinical.ref.medicalCertainties.keyTextValues.filter(
        k =>
          k.key === MedicalCertainty.Confirmed ||
          k.key === MedicalCertainty.PatientReported
      );

    const [isProcedure, setIsProcedure] = useState(
      terminologyData?.isProcedure
    );

    const birthDate = patient?.birthDate;

    const primaryDiagnosisText = clinicalRecord.medicalHistories.find(
      x => x.episodeOfCareId === clinicalRecord.episodeOfCare?.id
    )?.diagnosisCode?.originalText;

    const primaryDiagnosisDisabled =
      clinicalRecord.medicalHistories.filter(
        x =>
          x.episodeOfCareId === clinicalRecord.episodeOfCare?.id &&
          x.id !== medicalHistory?.id
      ).length === 0;

    const getCertaintyItems = (value: string | undefined) => {
      if (value) {
        const term = clinical.terminologyMap.get(value);
        if (term && term.isProcedure) {
          return procCertaintyOptions;
        }
      }

      return clinical.ref.medicalCertainties.keyTextValues;
    };

    const canDiagnosisBeReasonForVisit = (key: string) =>
      clinical.terminologyMap.get(key) &&
      !!clinical.terminologyMap.get(key)?.isReasonForVisit &&
      !clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits.some(
        x => x.code === clinical.terminologyMap.get(key)?.code
      );

    const isDiagnosisSided = (key: string) =>
      clinical.terminologyMap.get(key)?.isSide ?? false;

    const getSideText = () => {
      if (medicalHistory && medicalHistory.diagnosisSide) {
        const sideOfBody = clinical.ref.sidesOfBody.keyTextValues.find(
          x => x.key === medicalHistory.diagnosisSide
        )?.text;

        if (sideOfBody) {
          return `(${sideOfBody})`;
        }
      }

      return "";
    };

    const hasChronicity = (key: string) =>
      !!clinical.terminologyMap.get(key)?.isChronicity;

    const hasSeverity = (key: string) =>
      !!clinical.terminologyMap.get(key)?.isSeverity;

    const hasFracture = (key: string) =>
      !!clinical.terminologyMap.get(key)?.isFracture;

    const claim = clinicalRecord.condition?.claim;
    const claimStatusText = getClaimStatusText(claim?.claimStatus);

    return (
      <Fieldset
        {...dataAttribute(
          DataAttributes.Element,
          `${!terminologyData ? "add" : "edit"}"-medical-history-form"`
        )}
      >
        {/* Edit mode if terminologyData */}
        {terminologyData ? (
          <FieldSpy name={medicalHistoryFormNameOf("diagnosisKey")}>
            {(_fieldProp, value) => {
              const showSide = isDiagnosisSided(value);
              return (
                <Fieldset>
                  <Stack horizontal>
                    <Stack.Item>
                      <FieldItem
                        name={medicalHistoryFormNameOf("diagnosisKey")}
                        label={MedicalHistoryDialogLabels.DiagnosisProcedure}
                      >
                        {terminologyData.terminologyText} {getSideText()}
                      </FieldItem>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { paddingLeft: "10px" } }}>
                      <HideStack when={!showSide}>
                        <ButtonsChoiceGroupField
                          label={MedicalHistoryDialogLabels.Side}
                          name={medicalHistoryFormNameOf(
                            "diagnosisSideSelected"
                          )}
                          options={[
                            {
                              key: "L",
                              text: "L",
                              disabled: !isDiagnoseEditable
                            },
                            {
                              key: "R",
                              text: "R",
                              disabled: !isDiagnoseEditable
                            }
                          ]}
                          required
                        />
                      </HideStack>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { paddingLeft: "10px" } }}>
                      <Fieldset horizontal tokens={{ childrenGap: 16 }}>
                        <BoolButtonGroupField
                          label="Active"
                          disabled={!isDiagnoseEditable}
                          options={[
                            { key: true, text: "Yes" },
                            { key: false, text: "No" }
                          ]}
                          name={medicalHistoryFormNameOf("active")}
                        />
                      </Fieldset>
                    </Stack.Item>
                  </Stack>
                </Fieldset>
              );
            }}
          </FieldSpy>
        ) : (
          <DataFetcher
            fetch={() => showPrimaryDiagnosis(isProcedure)}
            refetchId={`${isProcedure}`}
          >
            {() => (
              <FieldSpy name={medicalHistoryFormNameOf("diagnosisKey")}>
                {(_fieldProp, value) => {
                  const showSide = isDiagnosisSided(value);
                  return (
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                      <Stack.Item
                        styles={{
                          root: {
                            flex: 1,
                            ...noWrap
                          }
                        }}
                      >
                        <TerminologyPickerField
                          name={medicalHistoryFormNameOf("diagnosisKey")}
                          label={MedicalHistoryDialogLabels.DiagnosisProcedure}
                          take={DEFAULT_TAKE}
                          isDiagnosis
                          isProcedure
                          required
                        />
                      </Stack.Item>
                      <HideStack when={!showSide}>
                        <ButtonsChoiceGroupField
                          label={MedicalHistoryDialogLabels.Side}
                          name={medicalHistoryFormNameOf(
                            "diagnosisSideSelected"
                          )}
                          options={[
                            {
                              key: "L",
                              text: "L"
                            },
                            {
                              key: "R",
                              text: "R"
                            }
                          ]}
                          required
                        />
                      </HideStack>

                      <Fieldset horizontal tokens={{ childrenGap: 16 }}>
                        <BoolButtonGroupField
                          label="Active"
                          options={[
                            { key: true, text: "Yes" },
                            { key: false, text: "No" }
                          ]}
                          name={medicalHistoryFormNameOf("active")}
                        />
                      </Fieldset>
                    </Stack>
                  );
                }}
              </FieldSpy>
            )}
          </DataFetcher>
        )}

        <MedicalHistoryDateFields
          label="When"
          birthDate={birthDate?.toJSDate()}
          disabled={!isDiagnoseEditable && !!terminologyData}
        />
        <MedicalHistoryDateErrorMessage
          birthDate={birthDate}
          medicalHistories={medicalHistories}
          editingMedicalHistory={medicalHistory}
        />

        <FieldSpy name={medicalHistoryFormNameOf("diagnosisKey")}>
          {(_fieldProp, value) => (
            <Stack>
              {(() => {
                const showChronicity = hasChronicity(value);
                const showSeverity = hasSeverity(value);
                const showFracture = hasFracture(value);

                return (
                  <Stack>
                    <ButtonsGroupSingleChoiceField
                      label={MedicalHistoryDialogLabels.Certainty}
                      name={medicalHistoryFormNameOf("certainty")}
                      options={getCertaintyItems(value)}
                    />

                    <Stack horizontal styles={{ root: { paddingTop: "8px" } }}>
                      <HideStack when={!showChronicity}>
                        <ButtonsGroupSingleChoiceField
                          label={MedicalHistoryDialogLabels.Course}
                          name={medicalHistoryFormNameOf("chronicity")}
                          options={clinical.ref.chronicity.keyTextValues}
                          styles={{ root: { paddingRight: "8px" } }}
                        />
                      </HideStack>
                      <HideStack when={!showSeverity}>
                        <ButtonsGroupSingleChoiceField
                          label={MedicalHistoryDialogLabels.Severity}
                          name={medicalHistoryFormNameOf("severity")}
                          options={clinical.ref.severity.keyTextValues}
                        />
                      </HideStack>
                    </Stack>

                    <Stack horizontal>
                      <HideStack when={!showFracture}>
                        <ButtonsGroupSingleChoiceField
                          label={MedicalHistoryDialogLabels.Fracture}
                          name={medicalHistoryFormNameOf("fracture")}
                          options={clinical.ref.fracture.keyTextValues}
                          styles={{ root: { paddingRight: "8px" } }}
                        />
                      </HideStack>
                      <HideStack when={!showFracture}>
                        <CheckboxGroupField
                          horizontal
                          name={medicalHistoryFormNameOf("fractureTypes")}
                          styles={{ root: { marginTop: "35px" } }}
                          options={clinical.ref.fractureTypes.keyLabelValues}
                        />
                      </HideStack>
                    </Stack>
                  </Stack>
                );
              })()}
            </Stack>
          )}
        </FieldSpy>
        {core.hasPermissions(Permission.MedicalHistoryDiagnosisSyncAllowed) &&
          !!clinicalRecord.openEncounter?.episodeOfCareId &&
          !isProcedure && (
            <Stack tokens={{ childrenGap: 8 }}>
              <Heading>Add as a diagnosis to the current condition</Heading>
              <Stack horizontal>
                <TooltipHost
                  content={
                    canLinkPrimaryDiagnosis &&
                    !claim?.claimStatus &&
                    !claimStatusText
                      ? undefined
                      : `Current claim is in ${claimStatusText} state, must submit the change via ACC32`
                  }
                >
                  <ToggleField
                    styles={{ root: { marginBottom: 0 } }}
                    onText="Yes"
                    offText="No"
                    name={medicalHistoryFormNameOf("linkToCondition")}
                    disabled={!canLinkPrimaryDiagnosis}
                  />
                </TooltipHost>
                <FieldCondition
                  when={medicalHistoryFormNameOf("linkToCondition")}
                  is={true}
                >
                  <CheckboxField
                    label="Primary diagnosis"
                    name={medicalHistoryFormNameOf("isPrimary")}
                    disabled={primaryDiagnosisDisabled}
                  />
                </FieldCondition>
              </Stack>
              <FieldSpy name={medicalHistoryFormNameOf("isPrimary")}>
                {(_fieldProp, value) => {
                  if (value) {
                    const changingPrimaryDiagnosis =
                      clinicalRecord.medicalHistories.find(
                        x =>
                          x.episodeOfCareId ===
                            clinicalRecord.episodeOfCare?.id &&
                          x.isPrimary &&
                          x.id !== medicalHistory?.id
                      );
                    if (changingPrimaryDiagnosis) {
                      const diagnosisText =
                        changingPrimaryDiagnosis.diagnosisCode?.originalText ??
                        "";
                      return (
                        <MessageBar
                          messageBarType={MessageBarType.warning}
                          styles={{ root: { marginBottom: 10 } }}
                        >
                          {`This diagnosis will replace already assigned ${diagnosisText}`}
                        </MessageBar>
                      );
                    }
                  }
                  return null;
                }}
              </FieldSpy>
            </Stack>
          )}

        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            resizable={false}
            multiline={true}
            label={MedicalHistoryDialogLabels.Details}
            name={medicalHistoryFormNameOf("details")}
            styles={{
              root: {
                flexGrow: 1,
                width: "100%",
                margin: "0px",
                flex: "1"
              },
              wrapper: {
                height: "100%",
                display: "flex",
                flexDirection: "column"
              },
              fieldGroup: {
                flexGrow: 1
              }
            }}
          />
          <DataFetcher
            fetch={() => showPrimaryDiagnosis(isProcedure)}
            refetchId={`${isProcedure}`}
          >
            {(showPrimaryDiagnosis: boolean) => (
              <Stack.Item>
                <Fieldset>
                  <legend
                    style={{
                      paddingTop: "5px",
                      fontWeight: "600",
                      paddingBottom: "1px"
                    }}
                  >
                    This diagnosis / procedure is:
                  </legend>
                  {!terminologyData && (
                    <FieldSpy name={medicalHistoryFormNameOf("diagnosisKey")}>
                      {(_fieldProp, value) =>
                        canDiagnosisBeReasonForVisit(value) && (
                          <CheckboxField
                            label={MedicalHistoryDialogLabels.ReasonForConsult}
                            name={medicalHistoryFormNameOf(
                              "saveAsReasonForVisit"
                            )}
                          />
                        )
                      }
                    </FieldSpy>
                  )}
                  <CheckboxField
                    {...dataAttribute(
                      DataAttributes.Element,
                      "clinicallySignificant"
                    )}
                    label={MedicalHistoryDialogLabels.ClinicallySignificant}
                    name={medicalHistoryFormNameOf("clinicallySignificant")}
                  />
                  {showPrimaryDiagnosis &&
                    core.isNZTenant &&
                    !core.hasPermissions(
                      Permission.MedicalHistoryDiagnosisSyncAllowed
                    ) && (
                      <FieldSpy name={medicalHistoryFormNameOf("diagnosisKey")}>
                        {(_fieldProp, value) =>
                          value && (
                            <CheckboxField
                              label={
                                MedicalHistoryDialogLabels.PrimaryDiagnosis
                              }
                              name={medicalHistoryFormNameOf(
                                "primaryDiagnosis"
                              )}
                            />
                          )
                        }
                      </FieldSpy>
                    )}
                  <FieldSpy
                    name={medicalHistoryFormNameOf("diagnosisKey")}
                    onChange={diagnosisKey => {
                      const diagnosisTerminology =
                        clinical.getTerminologyFromMap(diagnosisKey);
                      setIsProcedure(diagnosisTerminology?.isProcedure);
                      if (!diagnosisKey) {
                        form.change(
                          medicalHistoryFormNameOf("primaryDiagnosis"),
                          undefined
                        );
                      }
                    }}
                  />
                </Fieldset>
                <When
                  permission={
                    Permission.ConfidentialDataAllowed && Permission.PreRelease
                  }
                >
                  <CheckboxField
                    {...dataAttribute(DataAttributes.Element, "confidential")}
                    label={MedicalHistoryDialogLabels.Confidential}
                    name={medicalHistoryFormNameOf("confidential")}
                    styles={{ root: { paddingTop: "10px" } }}
                  />
                </When>
              </Stack.Item>
            )}
          </DataFetcher>
        </Stack>

        {!core.hasPermissions(
          Permission.MedicalHistoryDiagnosisSyncAllowed
        ) && (
          <DataFetcher
            fetch={() => showPrimaryDiagnosis(isProcedure)}
            refetchId={`${isProcedure}`}
          >
            {(showPrimaryDiagnosis: boolean) =>
              showPrimaryDiagnosis &&
              primaryDiagnosisText && (
                <FieldCondition
                  when={medicalHistoryFormNameOf("primaryDiagnosis")}
                  is={true}
                >
                  <FieldSpy name={medicalHistoryFormNameOf("diagnosisKey")}>
                    {(_fieldProp, value) =>
                      value && (
                        <MessageBar
                          messageBarType={MessageBarType.warning}
                        >{`This diagnosis will replace ${primaryDiagnosisText} as primary diagnosis for the current condition`}</MessageBar>
                      )
                    }
                  </FieldSpy>
                </FieldCondition>
              )
            }
          </DataFetcher>
        )}

        <RecordUpdateCheckedLog
          createdBy={medicalHistory?.createLog?.createdById}
          createdDate={medicalHistory?.createLog?.createdDateTime}
          updatedBy={medicalHistory?.updateLog?.updatedById}
          updatedDate={medicalHistory?.updateLog?.updatedDateTime}
          styles={{
            root: {
              justifyContent: "flex-start",
              paddingTop: 8
            }
          }}
        />
      </Fieldset>
    );
  });

export const MedicalHistoryFormFields = withFetch(
  x => [
    x.clinical.ref.sidesOfBody.load(),
    x.clinical.ref.severity.load(),
    x.clinical.ref.chronicity.load(),
    x.clinical.ref.fracture.load(),
    x.clinical.ref.fractureTypes.load()
  ],
  MedicalHistoryFormFieldsBase
);
