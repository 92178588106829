import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  DetailsList,
  DtoDetailsRow,
  IDetailsRowProps,
  Link,
  noWrap,
  RESET_CELLS_PADDING_CLASSNAME,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { PurchaseOrder } from "@stores/acc/models/PurchaseOrder.ts";

import { ClaimAdjustamentCell } from "./ClaimAdjustamentCell.tsx";
import { DeletePurchaseOrderCell } from "./DeletePurchaseOrderCell.tsx";
import { PurchaseOrderListItem } from "./PurchaseOrderList.types.ts";
import { mapToPurchaseOrderListItem } from "./utils.ts";

type PurchaseOrderListProps = {
  sortedPurchaseOrders: PurchaseOrder[];
  setShowModal: (value: boolean) => void;
};

export const PurchaseOrderList: FC<PurchaseOrderListProps> = observer(
  ({ sortedPurchaseOrders, setShowModal }) => {
    const nameOf = nameOfFactory<PurchaseOrderListItem>();

    const { setResponseModal, setPurchaseOrderId } =
      useClaimAdjustmentContext();

    return (
      <DetailsList
        bordersVariant="bottomHeader"
        items={sortedPurchaseOrders.map(mapToPurchaseOrderListItem)}
        onRenderRow={(x: IDetailsRowProps) => <DtoDetailsRow {...x} />}
        columns={[
          {
            name: "Number",
            key: nameOf("purchaseOrderNumber"),

            minWidth: 100,
            maxWidth: 100,
            onRender: (item: PurchaseOrderListItem) => (
              <Link
                onClick={() => {
                  item.claimAdjustment
                    ? setResponseModal(item.claimAdjustment!.id)
                    : setShowModal(true);
                  setPurchaseOrderId(item.id);
                }}
              >
                {item.purchaseOrderNumber}
              </Link>
            )
          },
          {
            name: "Created",
            key: nameOf("createdDate"),
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: PurchaseOrderListItem) => (
              <Stack>
                {item.createdDate
                  ? DateTime.fromISO(item.createdDate).toDayDefaultFormat()
                  : ""}
              </Stack>
            )
          },
          {
            name: "ACC32",
            key: nameOf("claimAdjustmentId"),

            minWidth: 120,
            maxWidth: 120,
            onRender: (item: PurchaseOrderListItem) => (
              <ClaimAdjustamentCell claimAdjustment={item.claimAdjustment} />
            )
          },
          {
            name: "Notes",
            key: nameOf("purchaseOrderNotes"),

            minWidth: 100,
            maxWidth: 150,
            onRender: (item: PurchaseOrderListItem) => (
              <TooltipHost
                content={item.purchaseOrderNotes}
                styles={{
                  root: { ...noWrap, display: "block" }
                }}
              >
                <Text nowrap>{item.purchaseOrderNotes ?? ""}</Text>
              </TooltipHost>
            )
          },
          {
            name: "",
            key: "",
            className: RESET_CELLS_PADDING_CLASSNAME,
            minWidth: 32,
            maxWidth: 32,
            onRender: (item: PurchaseOrderListItem) => (
              <DeletePurchaseOrderCell item={item} />
            )
          }
        ]}
      />
    );
  }
);
