import { action, computed, observable } from "mobx";
import { stringify } from "query-string";

import { BillingStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { RootStore } from "@stores/root/RootStore.ts";

export class InvoiceViewHelper {
  constructor(
    private root: RootStore,
    public options: {
      invoice: Invoice;
      adjustedInvoice: Invoice | undefined;
      contactPreference: ContactPreferences | undefined;
    }
  ) {
    this.invoice = this.options?.invoice;

    this.adjustedInvoice = this.options?.adjustedInvoice;

    this.contactPreference = this.options?.contactPreference;
  }

  public invoice: Invoice;

  public adjustedInvoice;

  public contactPreference;

  @observable isInvoiceEmailDialogVisible: boolean = false;

  @observable isConfirmationDialogVisible: boolean = false;

  @computed get isSendEmailDisabled() {
    return (
      this.isStatusCancelledOrAdjusted ||
      this.contactPreference?.invoiceCommunicationPreferences
        ?.contactHasOptedOut
    );
  }

  @computed get isStatusCancelledOrAdjusted() {
    return (
      this.invoice.status !== BillingStatuses.current &&
      !!this.invoice.adjustmentReason &&
      this.invoice.changeLog &&
      !!this.invoice.changeLog.updatedDate &&
      !!this.invoice.changeLog.updatedBy
    );
  }

  @action
  setConfirmationDialogVisible = (value: boolean) => {
    this.isConfirmationDialogVisible = value;
  };

  @action setInvoiceEmailDialogVisible = (value: boolean) => {
    this.isInvoiceEmailDialogVisible = value;
  };

  @computed get accountId() {
    return (
      this.root.routing.queryStringParam(routes.accounts.queryKeys.accountId) ||
      undefined
    );
  }

  handleOnAdjustClick = () => {
    this.root.routing.replaceRetainingState({
      pathname: routes.accounts.invoices.adjust.path({
        id: this.invoice.id
      }),
      search: stringify({ accountId: this.accountId })
    });
  };

  handleOnCancelClick = (value: boolean) => {
    this.setConfirmationDialogVisible(value);
  };

  handleWriteOffClick = () => {
    this.root.routing.replaceRetainingState({
      pathname: routes.accounts.invoices.writeOff.new.path({
        id: this.invoice.id
      })
    });
  };

  credit = async () => {
    this.root.routing.replaceRetainingState({
      pathname: routes.accounts.creditNotes.new.path({
        id: this.invoice.id
      })
    });
  };

  onOpenInvoicePdf = async () => {
    try {
      await this.root.billing.openInvoicePdf(this.invoice.id);
    } catch (e) {
      this.root.notification.error(e.message);
    }
  };
}
