import React from "react";

import { ActionButton, Stack, useTheme } from "@bps/fluent-ui";
import { last } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import {
  addModalityButton,
  deleteModalityButton,
  eightGap,
  modalityDropDown,
  modalityWrapper
} from "./ImagingRequest.styles.ts";
import { emptyRegion, Labels, Region } from "./ImagingRequestDialog.types.ts";
import { ImagingRequestRegionField } from "./ImagingRequestRegionField.tsx";

interface RequestModalityFieldProps {
  addRegion: () => void;
  deleteModality: () => void;
  modalityIndex: number;
  fieldName: string;
}

const ImagingRequestModalityFieldBase: React.FC<RequestModalityFieldProps> = ({
  deleteModality,
  modalityIndex,
  fieldName
}) => {
  const theme = useTheme();

  const { fields: regionFields } = useFieldArray<Region>(
    `${fieldName}.regions`
  );

  const checkHasSelectedRegion = () => {
    if (regionFields.value) {
      const lastRegionField = last(regionFields.value);
      if (lastRegionField) {
        return !!lastRegionField.region;
      }
    }
    return false;
  };

  const { clinical } = useStores();

  return (
    <Stack tokens={eightGap} styles={modalityWrapper(theme)}>
      <Stack horizontal>
        <DropdownField
          name={`${fieldName}.modality`}
          options={clinical.ref.imagingModalities.keyTextValues.sort(
            (imgModality1, imgModality2) =>
              imgModality1.text.localeCompare(imgModality2.text)
          )}
          label={`${Labels.Modality} ${modalityIndex + 1}`}
          fieldItemStyles={modalityDropDown}
          withNoEmptyOption
        />
        {modalityIndex !== 0 && (
          <DeleteButton
            styles={deleteModalityButton}
            onClick={deleteModality}
          />
        )}
      </Stack>
      {regionFields.map((name, index) => {
        return (
          <ImagingRequestRegionField
            key={name}
            deleteRegion={() => {
              regionFields.remove(index);
            }}
            regionIndex={index}
            modalityIndex={modalityIndex}
            fieldName={`${fieldName}.regions`}
          />
        );
      })}

      <ActionButton
        iconProps={{ iconName: "Add" }}
        onClick={() => regionFields.push(emptyRegion)}
        styles={addModalityButton}
        disabled={!checkHasSelectedRegion()}
      >
        Add region
      </ActionButton>
    </Stack>
  );
};

export const ImagingRequestModalityField = withFetch(
  x => [
    x.clinical.ref.imagingModalities.load(),
    x.clinical.ref.imagingRegions.load(),
    x.clinical.ref.sidesOfBody.load()
  ],
  ImagingRequestModalityFieldBase
);
