import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { PurchaseOrderFormValues } from "../components/purchase-order-section/PurchaseOrderDialog.types.ts";

export class PurchaseOrderValidator extends Validator<PurchaseOrderFormValues> {
  constructor() {
    super();

    this.forField("purchaseOrderNumber", [required(), maxLength(10)]);
    this.forField("date", required());
  }
}
