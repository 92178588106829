import { useField } from "react-final-form";

import { EmploymentStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { claimFormNameOf } from "@modules/acc/screens/claim/claim.utils.ts";

export const useIsEmployerRequired = () => {
  const {
    input: { value: employmentStatusField }
  } = useField(claimFormNameOf("employmentStatus"), {
    subscription: { value: true }
  });

  const {
    input: { value: workInjury }
  } = useField(claimFormNameOf("workInjury"), {
    subscription: { value: true }
  });

  return (
    !!workInjury && employmentStatusField === EmploymentStatuses.IAmAnEmployee
  );
};
