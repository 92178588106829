import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { isDeepEmptyUtils } from "@libs/utils/isDeepEmpty.utils.ts";
import { type ValidationResult } from "@libs/validation/validation.types.ts";
import { ScheduleServicesValidator } from "@modules/billing/screens/shared-components/ScheduleServicesValidator.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";

export const getIsBilled = (
  item: InvoiceItemFormValue,
  existingItems?: InvoiceItemDto[]
): boolean => {
  return !!existingItems?.some(
    billedItem => billedItem.serviceId === item.serviceId
  );
};

export const getValidationResult = (
  item: InvoiceItemFormValue,
  claimId?: string
): ValidationResult => {
  return new ScheduleServicesValidator(claimId).validate(item);
};

export const getIsValid = (
  item: InvoiceItemFormValue,
  claimId?: string
): boolean => {
  return isDeepEmptyUtils(getValidationResult(item, claimId));
};
export const getIsPrimaryButtonDisabled = (
  originalServices: InvoiceItemFormValue[],
  currentServices: InvoiceItemFormValue[]
): boolean => {
  if (originalServices.length !== currentServices.length) {
    return false;
  }

  const isArraysIdentical = originalServices.every(originalService => {
    const currentService = currentServices.find(
      service => service.code === originalService.code
    );
    return (
      !!currentService && originalService.quantity === currentService.quantity
    );
  });

  return isArraysIdentical;
};
