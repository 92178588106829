import React from "react";

import {
  FontIcon,
  mergeStyles,
  mergeStyleSets,
  useTheme
} from "@bps/fluent-ui";
import {
  CompoundButtonWithError,
  CompoundButtonWithErrorProps
} from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/CompoundButtonWithError.tsx";

interface ExpanderButtonProps<T> extends CompoundButtonWithErrorProps<T> {
  isOpened: boolean;
}
export const ExpanderButton = <T extends object>({
  isOpened,
  children,
  ...props
}: ExpanderButtonProps<T>) => {
  const theme = useTheme();
  return (
    <CompoundButtonWithError<T>
      {...props}
      styles={mergeStyleSets(
        {
          root: {
            backgroundColor: isOpened ? theme.palette.neutralLight : undefined
          }
        },
        props.styles
      )}
    >
      <FontIcon
        iconName={isOpened ? "ChevronLeft" : "ChevronRight"}
        className={mergeStyles({
          color: props.disabled
            ? theme.semanticColors.disabledText
            : theme.palette.themePrimary
        })}
      />
      {children}
    </CompoundButtonWithError>
  );
};
