import { FC } from "react";

import { routes } from "@libs/routing/routes.ts";
import { ClaimDataFetcher } from "@modules/acc/screens/shared-components/ClaimDataFetcher.tsx";
import { ErrorBlock } from "@ui-components/ErrorBlock.tsx";

interface Props {
  claimId: string;
  hideLink?: boolean;
}

export const ClaimAdjustmentLodgeError: FC<Props> = ({ claimId, hideLink }) => {
  const getErrorBlock = (errorText: string, referralIn?: boolean) => {
    const text = referralIn
      ? "Enter into referral information in summary card."
      : "Change status to incomplete in setup and update.";
    return (
      <ErrorBlock
        errorText={`${errorText} ${text}`}
        linkText={!hideLink ? "Update claim" : undefined}
        to={
          !hideLink
            ? routes.claims.management.edit.path({
                id: claimId
              })
            : undefined
        }
      />
    );
  };

  return (
    <ClaimDataFetcher claimId={claimId}>
      {claim => {
        if (!claim || (claim.accidentDate && claim.primaryDiagnosis)) {
          return null;
        }

        const accidentDateError =
          claim.isAcc32Allowed && !claim.accidentDate
            ? getErrorBlock(
                "Claim must have an injury date to lodge an ACC32.",
                claim.referralIn
              )
            : null;

        const primaryDiagnosisError =
          claim.isAcc32Allowed && !claim.primaryDiagnosis
            ? getErrorBlock(
                "Claim must have a primary diagnosis to lodge an ACC32.",
                claim.referralIn
              )
            : null;
        return (
          <>
            {accidentDateError}
            {primaryDiagnosisError}
          </>
        );
      }}
    </ClaimDataFetcher>
  );
};
