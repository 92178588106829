import { observer } from "mobx-react-lite";
import React from "react";

import {
  CenteredBox,
  dataAttribute,
  DataAttributes,
  DetailsRow,
  DetailsRowFields,
  FontIcon,
  FontSizes,
  IColumn,
  IDetailsRowFieldsProps,
  IDetailsRowProps,
  MessageBar,
  MessageBarType,
  ScrollablePane,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { WaitingListItemModel } from "@stores/booking/models/WaitingListModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { ContactDetailsLink } from "../ContactDetailsLink.tsx";
import {
  getInterpreterLanguage,
  renderAnyProvider,
  renderAppointmentDateTime,
  renderAppointmentType,
  renderDuration,
  renderListExpiry,
  renderPatientPrimaryContact,
  renderPriority,
  renderProvider
} from "../utils.ts";

interface WaitingListDialogTableProps {
  loading?: boolean;
  error?: Error;
  items: WaitingListItemModel[];
  selection: React.MutableRefObject<Selection>;
}

export type WaitingListItemModelWithKey = Pick<
  WaitingListItemModel,
  | "appointmentTypeId"
  | "appointmentType"
  | "anyProvider"
  | "anyLocation"
  | "startTime"
  | "attendees"
  | "orgUnitId"
  | "priority"
  | "duration"
  | "endTime"
  | "contact"
  | "content"
  | "until"
  | "user"
  | "id"
>;

enum CommentIconNames {
  CannedChat = "CannedChat",
  CommentSolid = "CommentSolid"
}

export const WaitingListDialogTable: React.FunctionComponent<WaitingListDialogTableProps> =
  observer(({ loading, items, error, selection }) => {
    const theme = useTheme();
    const { core } = useStores();
    const renderLanguage = (props: IDetailsRowFieldsProps) => {
      const record: WaitingListItemModel = props.item;
      const interpreterLanguage = getInterpreterLanguage(record);
      return interpreterLanguage ? (
        <MessageBar
          styles={{
            root: {
              marginBottom: 8,
              marginLeft: 11,
              width: "calc(99% - 11px)"
            }
          }}
          messageBarType={MessageBarType.warning}
        >
          Interpreter needed - {interpreterLanguage}
        </MessageBar>
      ) : null;
    };

    const columns: IColumn[] = [
      {
        key: "name",
        isRowHeader: true,
        minWidth: 90,
        maxWidth: 90,
        name: "Name",
        onRender: (record: WaitingListItemModelWithKey) => (
          <ContactDetailsLink
            modelId={record?.contact?.model?.id}
            modelName={record?.contact?.model?.name}
          />
        ),
        isMultiline: true
      },
      {
        iconName: "comment",
        key: "comment",
        minWidth: 25,
        maxWidth: 25,
        name: "",
        isIconOnly: true,
        onRender: (record: WaitingListItemModelWithKey) =>
          record.content ? (
            <TooltipHost content={record.content}>
              <FontIcon
                iconName={
                  selection.current
                    .getSelection()
                    .find(x => x.key === record.id)?.key === record.id
                    ? CommentIconNames.CommentSolid
                    : CommentIconNames.CannedChat
                }
                styles={{
                  root: {
                    fontSize: FontSizes.medium,
                    marginTop: 4,
                    maxWidth: 270,
                    color: theme.palette.themePrimary
                  }
                }}
              />
            </TooltipHost>
          ) : null
      },
      {
        key: "priority",
        minWidth: 60,
        maxWidth: 60,
        name: "Priority",
        isMultiline: true,
        onRender: renderPriority
      },
      {
        key: "provider",
        minWidth: 50,
        maxWidth: 80,
        name: "Provider",
        isMultiline: true,
        onRender: renderProvider
      },
      {
        key: "any",
        minWidth: 30,
        maxWidth: 40,
        name: "Any",
        isMultiline: true,
        onRender: renderAnyProvider
      },
      {
        key: "length",
        minWidth: 50,
        maxWidth: 50,
        name: "Length",
        isMultiline: true,
        onRender: renderDuration
      },
      {
        key: "type",
        minWidth: 70,
        maxWidth: 70,
        name: "Type",
        isMultiline: true,
        onRender: renderAppointmentType
      },
      {
        key: "primary",
        minWidth: 50,
        maxWidth: 70,
        name: "Primary",
        isMultiline: true,
        onRender: renderPatientPrimaryContact
      },
      {
        key: "listexpiry",
        minWidth: 30,
        maxWidth: 70,
        name: "List Expiry",
        isMultiline: true,
        onRender: renderListExpiry
      },
      {
        key: "appointmentDateTime",
        minWidth: 50,
        maxWidth: 50,
        name: "Appointment",
        isMultiline: true,
        onRender: renderAppointmentDateTime
      }
    ];

    if (core.hasMultipleActiveLocations) {
      const colIndex = columns.findIndex(x => x.key === "length");

      columns.splice(colIndex, 0, {
        fieldName: "location",
        key: "location",
        minWidth: 150,
        maxWidth: 200,
        name: "Location",
        onRender: (record: WaitingListItemModel) => (
          <Text>{core.getLocationName(record.orgUnitId)}</Text>
        ),
        isMultiline: true
      });
    }

    const renderRow = (props: IDetailsRowProps): JSX.Element => {
      return (
        <Stack verticalAlign="center">
          <DetailsRow
            {...props}
            rowFieldsAs={renderRowfields}
            styles={{
              checkCell: {
                display: "inline-flex",
                alignItems: "center"
              }
            }}
          />
        </Stack>
      );
    };

    const renderRowfields = (props: IDetailsRowFieldsProps) => {
      return (
        <Stack verticalAlign="center">
          <DetailsRowFields {...props} />
          {renderLanguage(props)}
        </Stack>
      );
    };

    const mappedItems: WaitingListItemModelWithKey[] = items.map(item => ({
      id: item.id,
      startTime: item.startTime,
      endTime: item.endTime,
      appointmentTypeId: item.appointmentTypeId,
      until: item.until,
      priority: item.priority,
      anyProvider: item.anyProvider,
      anyLocation: item.anyLocation,
      attendees: item.attendees,
      duration: item.duration,
      orgUnitId: item.orgUnitId,
      user: item.user,
      contact: item.contact,
      appointmentType: item.appointmentType,
      content: item.content
    }));

    return mappedItems.length || !!loading || error ? (
      <div
        style={{
          overflowY: "auto",
          position: "relative",
          flexGrow: 1,
          height: 300
        }}
        {...dataAttribute(DataAttributes.Loading, loading)}
      >
        <ScrollablePane>
          <ShimmeredDetailsList
            enableShimmer={loading}
            stickyHeader
            errorMessage={error?.message}
            items={mappedItems}
            selectionMode={SelectionMode.single}
            selection={selection.current}
            selectionPreservedOnEmptyClick={true}
            onRenderRow={renderRow}
            columns={columns}
          />
        </ScrollablePane>
      </div>
    ) : (
      <CenteredBox styles={{ root: { height: 300 } }}>
        <DefaultNoResultsTile />
      </CenteredBox>
    );
  });
