import { IColumn } from "@bps/fluent-ui";
import {
  IMaybePromiseObservable,
  maybePromiseObservable
} from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { sortByKey } from "@libs/utils/utils.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";

interface TemplatesFilterInternal {
  templateType?: string[];
  templateName?: string;
}

export class TemplateScreenHelper {
  templateListResults: IMaybePromiseObservable<OutboundCommTemplate[]>;

  constructor() {
    this.templateListResults = maybePromiseObservable();
  }

  public getFilteredTemplates = (
    filter?: TemplatesFilterInternal,
    items?: OutboundCommTemplate[]
  ) => {
    let result = items;
    if (!filter || !result) return [];

    if (filter.templateType && filter.templateType.length) {
      result = result.filter(
        x => filter.templateType && filter.templateType.includes(x.templateType)
      );
    }
    if (
      filter.templateName &&
      filter.templateName !== "" &&
      filter.templateName.trim() !== ""
    ) {
      result = result.filter(x =>
        !x.name || x.name === "" || x.name.trim() === ""
          ? false
          : filter.templateName &&
            x.name
              .toLowerCase()
              .includes(filter.templateName.trim().toLowerCase())
      );
    }
    return result;
  };

  public getSortedTemplates = (
    column: IColumn,
    columns: IColumn[],
    items?: OutboundCommTemplate[]
  ) => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      currCol => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol.key === column.key) {
        currColumn.isSorted = true;
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    let newItems: OutboundCommTemplate[] = [];
    if (items) {
      newItems = sortByKey<OutboundCommTemplate>(
        items,
        currColumn.key as keyof OutboundCommTemplate,
        currColumn.isSortedDescending
      );
    }

    return { newColumns, newItems };
  };
}
