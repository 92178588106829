import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { AddAmendmentFormValues } from "./AmendmentForm.types.ts";

export class AmendmentFormValidator extends Validator<
  RecursivePartial<AddAmendmentFormValues>
> {
  constructor() {
    super();
    this.forField("text", [required()]);
  }
}
