import { useMemo } from "react";
import { useField } from "react-final-form";

import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";

const AccidentLocationSelectFieldBase = () => {
  const { acc } = useStores();

  const {
    input: { value }
  } = useField<ClaimFormValues["accidentLocationCode"]>(
    claimFormNameOf("accidentLocationCode")
  );

  const options = useMemo(
    () =>
      acc.ref.accidentLocations.values
        .filter(data => !data.disabled || data.code === value)
        .map(({ code, text, disabled }) => ({ key: code, text, disabled })),
    [value, acc.ref.accidentLocations.values]
  );

  return (
    <OptionsSelectField
      name={claimFormNameOf("accidentLocationCode")}
      label={ClaimFormLabels.accidentLocation}
      options={options}
      required
      placeholder={ClaimFormLabels.accidentLocationPlaceholder}
    />
  );
};

export const AccidentLocationSelectField = withFetch(
  x => [x.acc.ref.accidentLocations.load()],
  AccidentLocationSelectFieldBase
);
