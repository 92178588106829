import { observer } from "mobx-react-lite";
import React from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ActivitiesClinical } from "./ActivitiesClinical.tsx";
import TasksClinical from "./TasksClinical.tsx";

export const ActivityOrTaskListBase: React.FC = observer(() => {
  const { core } = useStores();

  if (core.hasPermissions(Permission.ClinActivityAllowed)) {
    return <ActivitiesClinical />;
  }

  return <TasksClinical />;
});

export const ActivityOrTaskList = withFetch(
  x => [x.clinical.loadActivityDescriptions()],
  ActivityOrTaskListBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ActivityOrTaskList;
