import { observer } from "mobx-react-lite";

import { Stack } from "@bps/fluent-ui";

import { ReactionListHeader } from "./ReactionListHeader.tsx";

export const Reaction = observer(() => {
  return (
    <Stack
      tokens={{ childrenGap: 4 }}
      styles={{
        root: {
          overflowY: "hidden"
        }
      }}
    >
      <ReactionListHeader />
    </Stack>
  );
});
