import { useForm } from "react-final-form";

import {
  calendarStrings,
  DateRangeType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SystemsReviewFormValues } from "./SystemsReviewForm.types.ts";

export interface GynaeAdditionalFieldsProps {
  disabled?: boolean;
}

export const GynaeAdditionalFieldsBase: React.FC<
  GynaeAdditionalFieldsProps
> = ({ disabled }) => {
  const theme = useTheme();

  const form = useForm<SystemsReviewFormValues>();

  const { clinical } = useStores();

  const monthsMapped = calendarStrings.months.map((x, index) => ({
    key: index + 1,
    text: x
  }));

  const innerBoxStyles = {
    root: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.neutralLighter,
      padding: 8
    }
  };

  const onContraceptionChanged = (value: boolean) => {
    if (!value) {
      const values = form.getState().values;
      form.change("gynae", { ...values.gynae, contraceptionType: undefined });
    }
  };

  const onHasMenopausalChanged = (value: boolean) => {
    if (!value) {
      const values = form.getState().values;
      form.change("gynae", {
        ...values.gynae,
        tiredness: undefined,
        sleepDisturbances: undefined,
        moodDisturbances: undefined,
        hotFlushes: undefined
      });
    }
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <CheckboxField
        name="gynae.couldBePregnant"
        label="Could be pregnant"
        disabled={disabled}
      />
      <DatePickerField
        name="gynae.lastMenstrualPeriodDate"
        label="Last menstrual period date"
        placeholder="Date"
        dateRangeType={DateRangeType.Day}
        maxDate={DateTime.jsDateNow()}
        disabled={disabled}
      />
      <SpinNumberInputField
        name="gynae.pregnancyCount"
        label="Number of pregnancies"
        disabled={disabled}
        maxDigitsNumber={2}
        parse={value => (value ? Number(value) : undefined)}
      />

      <Stack tokens={{ childrenGap: 8 }} verticalAlign="center">
        <FieldSpy
          name="gynae.usesContraception"
          onChange={onContraceptionChanged}
        />
        <CheckboxField
          name="gynae.usesContraception"
          label="Uses contraception"
          disabled={disabled}
        />
        <FieldCondition when="gynae.usesContraception" is={true}>
          <Stack tokens={{ childrenGap: 8 }} styles={innerBoxStyles}>
            <ChoiceGroupField
              name="gynae.contraceptionType"
              options={clinical.ref.contraceptionTypes.keyTextValues}
              styles={{ root: { width: 200 } }}
              disabled={disabled}
            />
          </Stack>
        </FieldCondition>
      </Stack>
      <Stack tokens={{ childrenGap: 8 }} verticalAlign="center">
        <FieldSpy
          name="gynae.hasMenopausalProblems"
          onChange={onHasMenopausalChanged}
        />

        <CheckboxField
          name="gynae.hasMenopausalProblems"
          label="Has menopausal symptoms"
          disabled={disabled}
        />

        <FieldCondition when="gynae.hasMenopausalProblems" is={true}>
          <Stack tokens={{ childrenGap: 8 }} styles={innerBoxStyles}>
            <CheckboxField
              name="gynae.sleepDisturbances"
              label="Sleep disturbances"
              disabled={disabled}
            />
            <CheckboxField
              name="gynae.hotFlushes"
              label="Hot flushes"
              disabled={disabled}
            />
            <CheckboxField
              name="gynae.moodDisturbances"
              label="Mood disturbances"
              disabled={disabled}
            />
            <CheckboxField
              name="gynae.tiredness"
              label="Tiredness"
              disabled={disabled}
            />
          </Stack>
        </FieldCondition>
      </Stack>
      <CheckboxField name="gynae.hRT" label="HRT" disabled={disabled} />

      <Stack>
        <Text bold>Last breast exam</Text>
        <Stack horizontal tokens={{ childrenGap: 8 }} styles={innerBoxStyles}>
          <SpinNumberInputField
            name="gynae.lastBreastScreenDateYear"
            label="Year"
            placeholder="Year"
            maxDigitsNumber={4}
            disabled={disabled}
            parse={value => (value ? Number(value) : undefined)}
          />
          <DropdownField
            label="Month"
            name="gynae.lastBreastScreenDateMonth"
            options={monthsMapped}
            placeholder="Month"
            styles={{ root: { width: 200 } }}
            disabled={disabled}
          />
        </Stack>
      </Stack>
      <Stack>
        <Text bold>Last cervical smear</Text>
        <Stack horizontal tokens={{ childrenGap: 8 }} styles={innerBoxStyles}>
          <SpinNumberInputField
            name="gynae.lastCervicalScreenDateYear"
            label="Year"
            placeholder="Year"
            maxDigitsNumber={4}
            disabled={disabled}
            parse={value => (value ? Number(value) : undefined)}
          />
          <DropdownField
            label="Month"
            name="gynae.lastCervicalScreenDateMonth"
            options={monthsMapped}
            placeholder="Month"
            styles={{ root: { width: 200 } }}
            disabled={disabled}
          />
        </Stack>
      </Stack>

      <TextInputField
        name="gynae.comments"
        label="Comments"
        disabled={disabled}
      />
    </Stack>
  );
};

export const GynaeAdditionalFields = withFetch(
  x => [x.clinical.ref.contraceptionTypes.load()],
  GynaeAdditionalFieldsBase
);
