import {
  InfiniteScrollListColumn,
  SortDirection
} from "@ui-components/InfiniteScrollList/InfiniteScrollList.types.ts";

export enum SortableColumnIds {
  invoiceNumber = "NUMBERSORT",
  serviceDate = "SERVICEDATE",
  transactionStatus = "TRANSACTIONSTATUS",
  accChangedDate = "ACCCHANGEDDATE",
  transactionChangedDate = "TRANSACTIONCHANGEDDATE",
  insurer = "SEARCHACCOUNTNAME",
  sent = "SENTDATETIME"
}

enum InvoiceColumn {
  payment = "payment",
  comment = "comment",
  claim = "claim",
  appointment = "appointment",
  patient = "patient",
  value = "value",
  serviceDate = "serviceDate",
  description = "description",
  provider = "provider"
}

export const invoiceColumn: InfiniteScrollListColumn = {
  name: "Invoice",
  key: SortableColumnIds.invoiceNumber,
  minWidth: 70,
  maxWidth: 70,
  isSortable: true,
  sortDefaultDirection: SortDirection.Descending,
  styles: {
    cellTitle: { justifyContent: "flex-end" }
  }
};

export const insurerColumn: InfiniteScrollListColumn = {
  name: "Insurer",
  key: SortableColumnIds.insurer,
  minWidth: 120,
  maxWidth: 160,
  isSortable: true
};

export const stateColumn: InfiniteScrollListColumn = {
  name: "State",
  key: SortableColumnIds.transactionStatus,
  minWidth: 90,
  maxWidth: 90
};
export const updatedColumn: InfiniteScrollListColumn = {
  name: "Updated",
  key: SortableColumnIds.accChangedDate,
  minWidth: 80,
  maxWidth: 80,
  isSortable: true,
  sortDefaultDirection: SortDirection.Descending
};

export const sentColumn: InfiniteScrollListColumn = {
  name: "Sent",
  key: SortableColumnIds.sent,
  minWidth: 85,
  maxWidth: 85,
  isSortable: true,
  sortDefaultDirection: SortDirection.Descending,
  styles: {
    cellTitle: { justifyContent: "flex-start" }
  }
};

export const paymentColumn: InfiniteScrollListColumn = {
  name: "Payment",
  key: InvoiceColumn.payment,
  minWidth: 65,
  maxWidth: 65,
  styles: {
    cellTitle: { justifyContent: "flex-end" }
  }
};

export const commentColumn: InfiniteScrollListColumn = {
  iconName: "comment",
  name: "",
  key: InvoiceColumn.comment,
  minWidth: 17,
  maxWidth: 17
};

export const valueColumn: InfiniteScrollListColumn = {
  name: "Value ($)",
  key: InvoiceColumn.value,
  minWidth: 60,
  maxWidth: 60,
  styles: { cellTitle: { justifyContent: "flex-end" } }
};

export const descriptionColumn: InfiniteScrollListColumn = {
  name: "Invoiced items",
  key: InvoiceColumn.description,
  minWidth: 180,
  maxWidth: 300
};

export const servicedColumn: InfiniteScrollListColumn = {
  name: "Serviced",
  key: SortableColumnIds.serviceDate,
  minWidth: 80,
  maxWidth: 80,
  isSortable: true,
  sortDefaultDirection: SortDirection.Descending
};

export const patientColumn: InfiniteScrollListColumn = {
  name: "Patient",
  key: InvoiceColumn.patient,
  minWidth: 150,
  maxWidth: 200
};

export const claimColumn: InfiniteScrollListColumn = {
  name: "Claim",
  key: InvoiceColumn.claim,
  minWidth: 90,
  maxWidth: 110
};

export const appointmentColumn: InfiniteScrollListColumn = {
  name: "Appointment",
  key: InvoiceColumn.appointment,
  minWidth: 100,
  maxWidth: 180
};

export const providerColumn: InfiniteScrollListColumn = {
  name: "Provider",
  key: InvoiceColumn.provider,
  minWidth: 112,
  maxWidth: 112
};
