import { ITag } from "@bps/fluent-ui";
import {
  MedicationClinicalDataItemDto,
  MedicationDurationUnit
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  DrugMedicationSummaryDto,
  PbsListing,
  ScriptType
} from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { DvaCardColour } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { boolToYesNo } from "@libs/utils/utils.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";

import { PrescribingDoseFormValues } from "./PrescribingWizard.types.tsx";

export const getDefaultFormValues = (
  formulation: DrugMedicationSummaryDto,
  dvaCardColor: string | undefined
): PrescribingDoseFormValues => {
  const { adminUnit, quantity, rpts, routeId, pbsListing } =
    formulation.productPack;
  return {
    doseUnit: adminUnit,
    quantity,
    rpts,
    route: `${routeId}`,
    rxType: getRxType(pbsListing, dvaCardColor)
  };
};

const getRxType = (pbsListingId: string, dvaCardColor: string | undefined) => {
  let selectedRxType = "";

  switch (pbsListingId) {
    case PbsListing.NonPBS: {
      if (
        dvaCardColor === DvaCardColour.Gold ||
        dvaCardColor === DvaCardColour.Orange ||
        dvaCardColor === DvaCardColour.White ||
        dvaCardColor === undefined
      ) {
        selectedRxType = ScriptType.PRIVATE;
      }
      break;
    }
    case PbsListing.RPBS:
    case PbsListing.RPBSRB:
    case PbsListing.RPBSAuth:
    case PbsListing.RPBSAuthStreamlined: {
      if (
        dvaCardColor === DvaCardColour.Gold ||
        dvaCardColor === DvaCardColour.Orange
      ) {
        selectedRxType = ScriptType.RPBS;
        break;
      } else if (dvaCardColor === DvaCardColour.White) {
        selectedRxType = ScriptType.PRIVATE;
        break;
      } else {
        if (dvaCardColor === undefined) selectedRxType = ScriptType.PRIVATE;
        break;
      }
    }

    case PbsListing.PBSORRPBS:
    case PbsListing.PBSORRPBSRB:
    case PbsListing.PBSRPBSAuth:
    case PbsListing.PBSRPBSAuthStreamlined: {
      if (
        dvaCardColor === DvaCardColour.Gold ||
        dvaCardColor === DvaCardColour.Orange
      ) {
        selectedRxType = ScriptType.RPBS;
        break;
      } else if (dvaCardColor === DvaCardColour.White) {
        selectedRxType = ScriptType.PBS;
        break;
      } else {
        if (dvaCardColor === undefined) selectedRxType = ScriptType.PBS;
        break;
      }
    }

    case PbsListing.S100:
    case PbsListing.S100Private:
    case PbsListing.S100Public:
    case PbsListing.S100CommunityAccess: {
      if (
        dvaCardColor === DvaCardColour.Gold ||
        dvaCardColor === DvaCardColour.Orange ||
        dvaCardColor === DvaCardColour.White ||
        dvaCardColor === undefined
      ) {
        selectedRxType = ScriptType.PBS;
        break;
      }
    }
  }
  return selectedRxType;
};

export const getDurationText = (durationUnit: ITag, duration?: number) => {
  if (
    durationUnit.key === MedicationDurationUnit.UntilFinished ||
    duration === 1
  ) {
    return durationUnit.name;
  } else {
    return `${durationUnit.name}s`;
  }
};

export const getMedicationsTableColumns = (clinical: ClinicalStore) => {
  return [
    {
      fieldName: "productName",
      key: "productName",
      minWidth: 200,
      maxWidth: 200,
      name: "Product name",
      onRender: (record: MedicationClinicalDataItemDto) => {
        return record.productName;
      },
      isResizable: true
    },
    {
      fieldName: "dose",
      key: "dose",
      minWidth: 150,
      maxWidth: 150,
      name: "Dose",
      onRender: (record: MedicationClinicalDataItemDto) =>
        `${record.dose ?? ""}
             ${
               (record.doseUnit &&
                 clinical.ref.dosingAdministrationUnits.map.get(record.doseUnit)
                   ?.text) ||
               ""
             }

         ${
           (record.frequency &&
             clinical.ref.dosingFrequencies.map.get(record.frequency)?.text) ||
           ""
         }`,
      isResizable: true
    },
    {
      fieldName: "quantity",
      key: "quantity",
      minWidth: 80,
      maxWidth: 80,
      name: "Qty",
      onRender: (record: MedicationClinicalDataItemDto) => record.quantity,
      isResizable: true
    },
    {
      fieldName: "repeats",
      key: "repeats",
      minWidth: 80,
      maxWidth: 80,
      name: "Rpts",
      onRender: (record: MedicationClinicalDataItemDto) => record.repeats,
      isResizable: true
    },
    {
      fieldName: "rxType",
      key: "type",
      minWidth: 80,
      maxWidth: 80,
      name: "Type",
      onRender: (record: MedicationClinicalDataItemDto) => record.rxType,
      isResizable: true
    },
    {
      fieldName: "isLongTermMedication",
      key: "isLongTermMedication",
      minWidth: 80,
      name: "Long term",
      onRender: (record: MedicationClinicalDataItemDto) =>
        boolToYesNo(record.isLongTermMedication),
      isResizable: true
    },
    {
      fieldName: "isPrescribed",
      key: "isPrescribed",
      minWidth: 80,
      name: "Prescribed",
      onRender: (record: MedicationClinicalDataItemDto) =>
        boolToYesNo(record.isPrescribed),
      isResizable: true
    }
  ];
};

type DoseInstructions = {
  dose?: number | undefined;
  doseUnit?: string | undefined;
  frequencyText?: string | undefined;
  prn?: boolean;
  routeText?: string | undefined;
  isLongTerm?: boolean | undefined;
  complexInstructionsText?: string | undefined;
  foodText?: string | undefined;
  otherInstructionsText?: string | undefined;
  duration?: number | undefined;
  durationUnitText?: string | undefined;
};

export const getDoseFullInstructions = ({
  dose,
  doseUnit = "",
  frequencyText = "",
  prn,
  routeText = "",
  complexInstructionsText = "",
  foodText = "",
  otherInstructionsText = "",
  duration,
  durationUnitText = ""
}: DoseInstructions): string => {
  const doseString = dose ? `${dose} ${doseUnit}`.trim() : "";

  const durationText =
    duration || durationUnitText
      ? `for ${duration ?? ""} ${durationUnitText}`.trim()
      : "";

  const fullInstructions = [
    doseString,
    frequencyText,
    prn ? "prn" : "",
    complexInstructionsText,
    routeText,
    durationText,
    foodText,
    otherInstructionsText
  ]
    .filter(Boolean)
    .join(", ");

  return fullInstructions;
};
