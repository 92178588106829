import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { AddressValidator } from "@modules/practice/screens/shared-components/validation/AddressValidator.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { RefCountry } from "@stores/core/models/Address.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface InvoiceDetailsModalFormValidatorOptions {
  isAdjustMode: boolean;
  countries: readonly RefCountry[];
  country: Country;
  accountContact?: Contact;
}

export class InvoiceDetailsModalFormValidator extends Validator<InvoiceDetailsModalFormValues> {
  constructor(
    private core: CoreStore,
    options: InvoiceDetailsModalFormValidatorOptions
  ) {
    super();
    const addressValidator = new AddressValidator({
      countries: options.countries,
      country: options.country
    });

    this.forField(
      "userId",
      predicate(() => !options.isAdjustMode, required())
    );

    if (core.hasPermissions(Permission.LicencingAllowed)) {
      this.forField("billingAllowed", [
        val => {
          if (val === false) return "Invalid permissions.";
          else return undefined;
        }
      ]);
    }

    this.forField(
      "accountContactId",
      predicate(() => !options.isAdjustMode, required())
    );
    this.forField(
      "patientId",
      predicate(() => !options.isAdjustMode, required())
    );
    this.forField("reference", maxLength(250));

    // validate only if account contact has any addresses recorded
    this.forField(
      "accountAddress",
      predicate(
        () =>
          !!options.accountContact?.addresses.length && !options.isAdjustMode,
        required()
      )
    );

    this.forField(
      "newAddress",
      predicate(
        value => value !== undefined && !options.isAdjustMode,
        addressValidator.validate
      )
    );
  }
}
