import { dataAttribute, DataAttributes, IconButton } from "@bps/fluent-ui";
import {
  MedicationClinicalDataItemDto,
  MedicationsTestElements
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";

interface MedicationListContextMenuProps {
  medication: MedicationClinicalDataItemDto;
}

export const MedicationListContextMenu: React.FC<
  MedicationListContextMenuProps
> = ({ medication }) => {
  const { clinical } = useStores();

  const helper = useMedicationsContext();

  const onCeaseClick = () => {
    helper.setCeaseDialogVisible(true);
  };

  const onDeleteClick = () => {
    helper.setSelectedCurrentMeds([medication]);
    helper.setDeleteDialogVisible(true);
  };

  const isFromThisEncounter =
    clinical.activeRecordEncounterId ===
    medication.createLog?.createdEncounterId;

  // An item is recorded if it's not prescribed.
  const isRecorded = !medication.isPrescribed;

  const showDelete = isRecorded || isFromThisEncounter;

  const showCeased = !isFromThisEncounter;

  const menuItems = [];

  if (showCeased) {
    menuItems.push({ key: "cease", text: "Cease", onClick: onCeaseClick });
  }

  if (showDelete) {
    menuItems.push({ key: "delete", text: "Delete", onClick: onDeleteClick });
  }

  return (
    <IconButton
      {...dataAttribute(
        DataAttributes.Element,
        MedicationsTestElements.ContextualMenu
      )}
      menuIconProps={{
        iconName: "More"
      }}
      menuProps={{
        items: menuItems
      }}
    />
  );
};
