import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useField } from "react-final-form";

import { Stack, Text, TooltipHost, useTheme } from "@bps/fluent-ui";
import { ClaimAddressDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  AddressDto,
  AddressType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { patientFieldNames } from "../claim.utils.ts";
import { ClaimContext } from "../context/ClaimContext.ts";

export const isAddressTheSame = (
  patientAddress: AddressDto,
  formAddress: ClaimAddressDto
): boolean => {
  return (
    patientAddress.city?.toLowerCase() === formAddress.city?.toLowerCase() &&
    patientAddress.country?.toLowerCase() ===
      formAddress.country?.toLowerCase() &&
    patientAddress.postCode?.toLowerCase() ===
      formAddress.postCode?.toLowerCase() &&
    patientAddress.street1?.toLowerCase() ===
      formAddress.street1?.toLowerCase() &&
    patientAddress.street2?.toLowerCase() ===
      formAddress.street2?.toLowerCase() &&
    patientAddress.suburb?.toLowerCase() === formAddress.suburb?.toLowerCase()
  );
};

export const PersonalDetailsAddressOverrideCheckbox = observer(() => {
  const patientLabel = usePatientLabel();
  const theme = useTheme();
  const { claim } = useContext(ClaimContext);

  const {
    input: { value: addressValue }
  } = useField<ClaimAddressDto>(patientFieldNames("patientAddress"));

  if (!claim.patient) return null;

  if (!addressValue) return null;

  const address =
    addressValue.type === AddressType.Physical
      ? claim.patient.physicalAddress
      : claim.patient.postalAddress;

  if (!address) return null;

  if (isAddressTheSame(address, addressValue)) return null;

  return (
    <CheckboxField
      name={patientFieldNames("patientAddressOverride")}
      onRenderLabel={() => (
        <Stack horizontal>
          <Text>Override address in {patientLabel} demographics&nbsp;</Text>
          <TooltipHost
            content={
              <Stack>
                <Stack>{address.street1}</Stack>
                <Stack>{address.street2}</Stack>
                <Stack>{address.suburb}</Stack>
                <Stack>
                  {address.city} {address.postCode}
                </Stack>
              </Stack>
            }
          >
            <Text styles={{ root: { color: theme.semanticColors.link } }}>
              (existing address)
            </Text>
          </TooltipHost>
        </Stack>
      )}
    />
  );
});
