import { Heading, Text } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { formDesignDomainNames } from "@libs/gateways/form-design/FormDesignGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";

export const tenantSettingFormDropdown = {
  demographics: {
    typeCode: "PATDEMO",
    heading: "Patient details",
    description: "Send this form for patient details:",
    domains: [formDesignDomainNames.practiceManagement]
  },
  acc: {
    typeCode: "ACC",
    heading: "ACC claim details",
    description: "Send this form for ACC claim details:",
    domains: [formDesignDomainNames.acc],
    nzTenant: true
  },
  accDemographics: {
    typeCode: "ACCPATDEMO",
    heading: "ACC claim, and patient details",
    description:
      "Send this form for combined patient details and ACC claim details:",
    domains: [
      formDesignDomainNames.practiceManagement,
      formDesignDomainNames.acc
    ],
    nzTenant: true
  }
};

export const TenantSettingsFormsDropDown: React.FC<{ formKey: string }> = ({
  formKey
}) => {
  const { core, formDesign } = useStores();
  const result = tenantSettingFormDropdown[formKey];
  if (core.tenantDetails?.country !== Country.NewZealand && result.nzTenant) {
    return null;
  }

  const validFormDesigns =
    result &&
    formDesign.formDesigns
      .filter(a => {
        const domains = a.domains?.filter(x => x !== "Forms");
        return (
          a &&
          a?.isPublished &&
          domains &&
          domains.length === result.domains.length &&
          domains.every(x => result.domains.includes(x))
        );
      })
      .map(x => {
        return { key: x.id, text: x.name + (x.domainCode ? " (Default)" : "") };
      });

  return result ? (
    <>
      <Heading variant="section-sub-heading">{result.heading}</Heading>
      <Text>{result.description}</Text>
      <DropdownField
        withNoEmptyOption
        name={formKey}
        styles={{ root: { marginBottom: 16, width: 350 } }}
        options={validFormDesigns}
      />
    </>
  ) : null;
};
