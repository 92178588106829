import { DateTime } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isDateAfterDate,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CurrentStatusAndPrognosisFormValues } from "@shared-types/acc/current-status-prognosis-values.type.ts";

export class CurrentStatusAndPrognosisDraftValidator extends Validator<CurrentStatusAndPrognosisFormValues> {
  constructor() {
    super();

    const dateLessThanOneWeek = DateTime.now().minus({ days: 7 });

    this.forField("originalAccidentEvent", maxLength(2000));
    this.forField("currentConditionOrDiagnosis", maxLength(2000));
    this.forField("causeOfCurrentCondition", maxLength(2000));
    this.forField("causeOfNotResolvedCondition", maxLength(2000));
    this.forField("signAndSymptom", maxLength(2000));
    this.forField("proposedTreatment", maxLength(2000));
    this.forField("managementPlan", maxLength(2000));
    this.forField(
      nameof("expectedTreatmentCompletionDate"),
      isDateAfterDate(
        dateLessThanOneWeek,
        "This date is more than a week ago. Please enter a valid date"
      )
    );
  }
}
