import { FunctionComponent, useRef } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  CalloutContent,
  FontIcon,
  IColumn,
  IconButton,
  IDragDropEvents,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { QuickColoursSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { ColourSelectField } from "@ui-components/form/selects/ColourSelectField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { QuickColoursDialogValues } from "./QuickColoursDialog.tsx";

export const QuickColoursDetailsList: FunctionComponent = () => {
  const { userExperience } = useStores();
  const dragStartIndex = useRef<number>(0);

  const formState = useFormState<QuickColoursDialogValues>();
  const dirty = formState.dirty;

  const fieldName = (index: number) => `quickColoursSettings[${index}]`;

  const quickColourList = userExperience.ref.quickColoursType.values.map(x => ({
    key: x.text,
    text: x.text
  }));

  const { mutators } = useForm();

  const { fields: _quickColoursSettingsField } =
    useFieldArray<QuickColoursSettingDto>("quickColoursSettings", {
      subscription: { value: true }
    });

  const quickColoursSettingsField = _quickColoursSettingsField.value;

  const iconOnClick = (index: number) => {
    mutators.remove("quickColoursSettings", index);
  };

  const handleDropped = (insertIndex: number) => {
    if (dragStartIndex.current !== insertIndex) {
      _quickColoursSettingsField.move(dragStartIndex.current, insertIndex);
    }
  };

  const dragDropEvents: IDragDropEvents = {
    canDrop: () => true,
    canDrag: () => true,
    onDrop: droppedAtItem => {
      const index = quickColoursSettingsField.indexOf(droppedAtItem);
      handleDropped(index);
    },
    onDragStart: (item, index) => {
      dragStartIndex.current = index ?? 0;
    }
  };

  const columns: IColumn[] = [
    {
      name: "",
      key: "drag",
      minWidth: 30,
      maxWidth: 30,
      onRender: (item: QuickColoursSettingDto, index: number) => (
        <Stack horizontal styles={{ root: { alignItems: "flex-end" } }}>
          {index + 1}
          <FontIcon
            iconName="Move"
            styles={{ root: { fontSize: 16, cursor: "grab", paddingLeft: 4 } }}
          />
        </Stack>
      )
    },
    {
      name: "Name",
      key: "Name",
      minWidth: 240,
      maxWidth: 240,
      onRender: (item: QuickColoursSettingDto, index: number) => {
        return (
          <ComboBoxField
            styles={{ root: { minWidth: 240 } }}
            name={`${fieldName(index)}.colourName`}
            options={quickColourList}
            useComboBoxAsMenuWidth
            allowFreeform
            required
            calloutProps={{
              calloutMaxHeight: 500
            }}
            dynamicOptions
          />
        );
      }
    },
    {
      name: "Colour",
      key: "Colour",
      minWidth: 240,
      maxWidth: 240,
      onRender: (item: QuickColoursSettingDto, index: number) => {
        return (
          <ColourSelectField
            name={`${fieldName(index)}.colourCode`}
            styles={{ root: { minWidth: 240 }, field: { marginTop: 0 } }}
            colourPickerProps={{ CalloutContent }}
          />
        );
      }
    },
    {
      name: "",
      key: "iconBtn",
      minWidth: 24,
      maxWidth: 24,
      onRender: (item: QuickColoursSettingDto, index: number) => (
        <IconButton
          iconProps={{
            iconName: "Delete"
          }}
          styles={{ root: { marginRight: "auto" } }}
          onClick={() => {
            iconOnClick(index);
          }}
        />
      )
    }
  ];

  return (
    <>
      {dirty && (
        <MessageBar messageBarType={MessageBarType.warning}>
          Any earlier created diagrams will preserve the colours used on the
          moment of creation
        </MessageBar>
      )}
      <ShimmeredDetailsList
        columns={columns}
        items={quickColoursSettingsField}
        dragDropEvents={dragDropEvents}
        onRenderRow={(props, defaultRender) => {
          if (!defaultRender || !props) return null;
          return defaultRender({
            ...props,
            styles: {
              cell: {
                justifyContent: "flex-end"
              }
            }
          });
        }}
      />
    </>
  );
};
