import { toJS } from "mobx";

import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  CurrentInjuryClinicalDataDto,
  EncounterClinicalDataDto,
  FullBodyCodes,
  InjuryClinicalDataDto,
  PhysioInjuryAreaClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { PresentingComplaintFormValues } from "./PresentingComplaintForm.types.ts";

export class PresentingComplaintFormHelper {
  constructor(private clinicalRecord: ClinicalRecord) {}

  public getInitialValues = (): PresentingComplaintFormValues => {
    const hasBeenReset =
      !!this.clinicalRecord.stashedClinicalData?.haveBeenResetForms.get(
        ClinicalDataType.CurrentInjury
      );

    const currentInjuryData = hasBeenReset
      ? this.clinicalRecord.clinicalData?.currentInjury
      : this.clinicalRecord.stashedClinicalData?.currentInjury;

    const eoc = this.clinicalRecord.episodeOfCare;

    let injuryDate: Date | undefined;
    const currentInjuryDate = currentInjuryData?.dateOfInjury;

    if (currentInjuryDate) {
      injuryDate = DateTime.jsDateFromISO(currentInjuryDate);
    } else {
      injuryDate = DateTime.jsDateFromISO(eoc?.injuryDate);
    }

    const stashedInjuryData = hasBeenReset
      ? this.clinicalRecord.clinicalData?.injury
      : this.clinicalRecord.stashedClinicalData?.injury;

    const surgeryDate = DateTime.jsDateFromISO(
      stashedInjuryData?.dateOfSurgery
    );

    const bodyData = this.clinicalRecord.stashedClinicalData?.physioBody ?? {
      images: []
    };

    const patient = this.clinicalRecord.patient;

    const {
      frontBodyImage,
      backBodyImage,
      leftBodyImage,
      rightBodyImage
    }: {
      frontBodyImage: string | undefined;
      backBodyImage: string | undefined;
      leftBodyImage: string | undefined;
      rightBodyImage: string | undefined;
    } = this.getInitialBodyData(patient, bodyData);

    return {
      ...currentInjuryData,
      duration: currentInjuryData?.duration?.toString(),
      dateOfInjury: injuryDate,
      addSurgeryDate: !!surgeryDate,
      dateOfSurgery: surgeryDate,
      naturesComment: currentInjuryData?.natureComment,
      frontBodyImage,
      backBodyImage,
      leftBodyImage,
      rightBodyImage
    };
  };

  public getInitialBodyData = (
    patient: Contact | undefined,
    bodyData: PhysioInjuryAreaClinicalDataDto
  ) => {
    let frontBodyImage: string | undefined;
    let backBodyImage: string | undefined;
    let leftBodyImage: string | undefined;
    let rightBodyImage: string | undefined;

    const { frontCode, backCode, leftSideCode, rightSideCode } =
      this.getPhysioBodyCodes(patient);

    const frontIndex = bodyData.images.findIndex(i => i.type === frontCode);

    const backIndex = bodyData.images.findIndex(i => i.type === backCode);

    const leftSideIndex = bodyData.images.findIndex(
      i => i.type === leftSideCode
    );

    const rightSideIndex = bodyData.images.findIndex(
      i => i.type === rightSideCode
    );

    if (frontIndex !== -1) {
      frontBodyImage = bodyData.images[frontIndex].content
        ? atob(bodyData.images[frontIndex].content!)
        : undefined;
    }

    if (backIndex !== -1) {
      backBodyImage = bodyData.images[backIndex].content
        ? atob(bodyData.images[backIndex].content!)
        : undefined;
    }

    if (leftSideIndex !== -1) {
      leftBodyImage = bodyData.images[leftSideIndex].content
        ? atob(bodyData.images[leftSideIndex].content!)
        : undefined;
    }

    if (rightSideIndex !== -1) {
      rightBodyImage = bodyData.images[rightSideIndex].content
        ? atob(bodyData.images[rightSideIndex].content!)
        : undefined;
    }
    return { frontBodyImage, backBodyImage, leftBodyImage, rightBodyImage };
  };

  public convertFormValuesToData = (values: PresentingComplaintFormValues) => {
    const stashedCurrentInjuryData =
      this.clinicalRecord.stashedClinicalData?.originalDto?.currentInjury;

    const eTag = stashedCurrentInjuryData?.eTag;

    const injuryETag =
      this.clinicalRecord.stashedClinicalData?.originalDto?.injury?.eTag;

    const injuryData: InjuryClinicalDataDto = {
      eTag: injuryETag,
      dateOfSurgery: values.dateOfSurgery
        ? DateTime.jsDateToISODate(values.dateOfSurgery)
        : undefined
    };

    const currentInjuryData: CurrentInjuryClinicalDataDto = {
      ...stashedCurrentInjuryData,
      eTag,
      dateOfInjury: values.dateOfInjury
        ? DateTime.jsDateToISODate(values.dateOfInjury)
        : undefined,
      natureComment: values.naturesComment,
      duration: values.duration ? parseInt(values.duration) : undefined,
      mechanismOfInjury: values.mechanismOfInjury,
      currentHistory: values.currentHistory,
      severity: values.severity,
      natures: values.natures,
      aggravatingFactors: values.aggravatingFactors,
      aggravatingFactorsComment: values.aggravatingFactorsComment,
      easingFactors: values.easingFactors,
      easingFactorsComment: values.easingFactorsComment,
      onset: values.onset,
      durationPeriod: values.durationPeriod,
      frequencyOccurrences: values.frequencyOccurrences,
      frequencyPeriod: values.frequencyPeriod,
      radiation: values.radiation,
      associatedSymptoms: values.associatedSymptoms,
      concomitantFactors: values.concomitantFactors,
      impacts: values.impacts,
      attributes: values.attributes,
      attributeComment: values.attributeComment,
      appliedTreatments: values.appliedTreatments,
      appliedTreatmentComment: values.appliedTreatmentComment
    };

    const bodyData = toJS(this.clinicalRecord.clinicalData?.physioBody, {
      recurseEverything: true
    });

    const physioBodyData = bodyData ?? { images: [] };

    const patient = this.clinicalRecord.patient;

    this.convertBodyFormValuesToData(patient, physioBodyData, values);

    const data: EncounterClinicalDataDto = {
      injury: injuryData,
      currentInjury: currentInjuryData,
      physioBody: physioBodyData
    };

    return data;
  };

  private getPhysioBodyCodes = (patient: Contact | undefined) => {
    const frontCode =
      patient?.sex === Sex.Female
        ? FullBodyCodes.FemaleFront
        : FullBodyCodes.MaleFront;

    const backCode =
      patient?.sex === Sex.Female
        ? FullBodyCodes.FemaleBack
        : FullBodyCodes.MaleBack;

    const leftSideCode =
      patient?.sex === Sex.Female
        ? FullBodyCodes.FemaleLeftSide
        : FullBodyCodes.MaleLeftSide;

    const rightSideCode =
      patient?.sex === Sex.Female
        ? FullBodyCodes.FemaleRightSide
        : FullBodyCodes.MaleRightSide;
    return { frontCode, backCode, leftSideCode, rightSideCode };
  };

  private convertBodyFormValuesToData = (
    patient: Contact | undefined,
    physioBodyData: PhysioInjuryAreaClinicalDataDto,
    values: PresentingComplaintFormValues
  ) => {
    const { frontCode, backCode, leftSideCode, rightSideCode } =
      this.getPhysioBodyCodes(patient);

    const frontIndex = physioBodyData.images.findIndex(
      i => i.type === frontCode
    );

    const backIndex = physioBodyData.images.findIndex(i => i.type === backCode);
    const leftSideIndex = physioBodyData.images.findIndex(
      i => i.type === leftSideCode
    );

    const rightSideIndex = physioBodyData.images.findIndex(
      i => i.type === rightSideCode
    );

    if (frontIndex !== -1) {
      physioBodyData.images[frontIndex].content = values.frontBodyImage
        ? btoa(values.frontBodyImage)
        : undefined;
    } else if (values.frontBodyImage) {
      physioBodyData.images.push({
        type: frontCode,
        content: btoa(values.frontBodyImage)
      });
    }

    if (backIndex !== -1) {
      physioBodyData.images[backIndex].content = values.backBodyImage
        ? btoa(values.backBodyImage)
        : undefined;
    } else if (values.backBodyImage) {
      physioBodyData.images.push({
        type: backCode,
        content: btoa(values.backBodyImage)
      });
    }

    if (leftSideIndex !== -1) {
      physioBodyData.images[leftSideIndex].content = values.leftBodyImage
        ? btoa(values.leftBodyImage)
        : undefined;
    } else if (values.leftBodyImage) {
      physioBodyData.images.push({
        type: leftSideCode,
        content: btoa(values.leftBodyImage)
      });
    }

    if (rightSideIndex !== -1) {
      physioBodyData.images[rightSideIndex].content = values.rightBodyImage
        ? btoa(values.rightBodyImage)
        : undefined;
    } else if (values.rightBodyImage) {
      physioBodyData.images.push({
        type: rightSideCode,
        content: btoa(values.rightBodyImage)
      });
    }
  };
}
