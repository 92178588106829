import { Length, maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

export class PhysioMedicalHistoryCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();

    this.forField(sotapNameOf("physioMedicalHistory"), [
      maxLength(Length.comments)
    ]);
    this.forField(sotapNameOf("medications"), [maxLength(Length.comments)]);
    this.forField(sotapNameOf("reactions"), [maxLength(Length.comments)]);
    this.forField(sotapNameOf("neuroSigns"), [maxLength(Length.comments)]);
    this.forField(sotapNameOf("precautions"), [maxLength(Length.comments)]);
  }
}
