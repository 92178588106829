export enum SotapMskId {
  FamilyAndSocial = "FamilyAndSocial",
  BodyDiagram = "BodyDiagram",
  CurrentInjury = "CurrentInjury",
  OutcomeMeasure = "OutcomeMeasure",
  PhysioMedicalHistory = "PhysioMedicalHistory",
  PostureOrObservations = "PostureOrObservations",
  OtherMovements = "OtherMovements",
  AnalysisAndPlan = "AnalysisAndPlan",
  TodaysTreatment = "TodaysTreatment",
  InjuryAreas = "InjuryAreas",
  Goals = "Goals",
  ConsentAndWarning = "ConsentAndWarning",
  Diagnosis = "Diagnosis",
  FollowUpNotes = "FollowUpNotes",
  TreatmentPlan = "TreatmentPlan"
}

export enum SotapMskLabel {
  FamilyAndSocial = "Family & social history",
  BodyDiagram = "Body diagram",
  ProblemDetails = "Problem details",
  OutcomeMeasure = "Outcome measures",
  PhysioMedicalHistory = "Medical history",
  PostureOrObservations = "Posture/observations",
  OtherMovements = "Other movements/joints",
  AnalysisAndPlan = "Analysis and plan",
  Analysis = "Analysis",

  TodaysTreatment = "Today's treatment & education",
  PrimaryMeridians = "Primary meridians",
  ExtraordinaryMeridians = "Extraordinary meridians",
  InjuryAreas = "Injury areas",
  Goals = "Goals",
  ConsentAndWarning = "Consent & warnings",
  TreatmentPlanDiscussedText = "Treatment plan discussed and understood",
  WarningExplainedText = "Warnings explained",
  ConsentObtainedText = "Consent for treatment obtained",
  CulturalNeedsIdentifiedText = "All relevant cultural needs have been identified & discussed",
  AdditionalDiscussions = "Additional discussions & comments",
  Diagnosis = "Diagnosis",
  FollowUpNotes = "Injury details",
  TreatmentPlan = "Planning",
  Treatment = "Treatment"
}

export enum DiagnosisCardLabels {
  PrimaryDiagnosis = "Primary",
  AdditionalDiagnoses = "Additional / differential",
  ClinicalImpression = "Clinical impression"
}

export enum PivotLabels {
  Spine = "Spine",
  Cervical = "Cervical",
  Thoracic = "Thoracic",
  Lumbosacral = "Lumbosacral",
  Left = "Left",
  Right = "Right",
  Active = "Active",
  Passive = "Passive"
}

export enum MotionAssessmentLabels {
  Strength = "Strength",
  Palpation = "Palpation",
  SpecialTests = "Special tests"
}

export enum DischargeId {
  Summary = "Summary",
  Outcome = "Outcome",
  ReferralFollowUp = "ReferralFollowUp",
  Goals = "Goals",
  OutcomeMeasure = "OutcomeMeasure"
}

export enum DischargeLabel {
  Summary = "Summary",
  Outcome = "Outcome",
  ReferralFollowUp = "Referral follow-up",
  Goals = "Goals",
  OutcomeMeasure = "Outcome measures"
}

export const DEGREE_SYMBOL: string = "\u00B0";
