import { FC, useContext, useRef } from "react";
import { useField } from "react-final-form";

import {
  ButtonsGroupOption,
  dataAttribute,
  DataAttributes,
  FontIcon,
  Heading,
  Stack,
  Text
} from "@bps/fluent-ui";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  FeeFormLabels,
  feeFormNameOf,
  FeeTileLabels
} from "../ScheduleForm.types.tsx";

export const FeeFormFields: FC = () => {
  const { editFee: fee, invoiceSettings } = useContext(ScheduleScreenContext);

  const { input: feeIncludesGstInput } = useField(
    feeFormNameOf("feeIncludesGst"),
    {
      subscription: { value: true }
    }
  );

  // prevent GST from being changed if true and financial settings are set to add GST by default
  const lockGST = useRef<boolean>(
    feeIncludesGstInput.value && invoiceSettings[0].alwaysApplyGst
  );

  const options: ButtonsGroupOption<boolean>[] = [
    { key: true, text: FeeTileLabels.service },
    { key: false, text: FeeTileLabels.material }
  ];

  const codeField = fee ? (
    <Stack styles={{ root: { paddingRight: 16 } }} tokens={{ childrenGap: 8 }}>
      <Heading labelPaddings>{FeeFormLabels.itemNumber}</Heading>
      <Text>{fee.code}</Text>
    </Stack>
  ) : (
    <TextInputField
      required
      styles={{ root: { width: "auto" } }}
      label={FeeFormLabels.itemNumber}
      name={feeFormNameOf("feeCode")}
    />
  );

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        {codeField}
        <ButtonsGroupSingleChoiceField
          {...dataAttribute(
            DataAttributes.Element,
            "fee-form-isService-buttons"
          )}
          label={FeeFormLabels.itemIs}
          name={feeFormNameOf("feeIsService")}
          options={options}
        />
        <Stack
          styles={{ root: { paddingLeft: 8 } }}
          tokens={{ childrenGap: 6 }}
        >
          <Heading labelPaddings>{FeeFormLabels.gst}</Heading>
          {lockGST.current ? (
            <FontIcon
              iconName="Completed"
              styles={{ root: { fontSize: 18 } }}
            />
          ) : (
            <CheckboxField
              name={feeFormNameOf("feeIncludesGst")}
              label={FeeFormLabels.includesGst}
              automationAttribute="fee-includes-gst"
            />
          )}
        </Stack>
        <Stack>
          <Heading labelPaddings>Editable when billing</Heading>
          <FieldSpy name={feeFormNameOf("feeNextIsActive")}>
            {feeNextIsActive =>
              feeNextIsActive ? (
                <CheckboxField
                  styles={{ root: { marginTop: 4 } }}
                  name={feeFormNameOf("feeIsEditable")}
                />
              ) : (
                <EmptyEditableField />
              )
            }
          </FieldSpy>
        </Stack>
      </Stack>
      <TextInputField
        required
        label={FeeFormLabels.name}
        name={feeFormNameOf("feeName")}
      />
      <TextInputField
        label={FeeFormLabels.description}
        name={feeFormNameOf("feeDescription")}
        multiline
      />
    </Stack>
  );
};
const EmptyEditableField: FC = () => (
  <Stack.Item styles={{ root: { width: 70, padding: "4px 0px" } }}>
    —
  </Stack.Item>
);
