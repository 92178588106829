import {
  CollapsibleCard,
  CollapsibleCardProps,
  dataAttribute,
  DataAttributes,
  HideStack,
  Variant
} from "@bps/fluent-ui";
import { Validator } from "@libs/validation/Validator.ts";
import {
  ErrorWrapper,
  ErrorWrapperProps
} from "@ui-components/form/ErrorWrapper.tsx";
import { FormStatusIndicator } from "@ui-components/form/FormStatusIndicator/FormStatusIndicator.tsx";

interface errorProps extends Omit<ErrorWrapperProps, "errorMessage"> {
  errorMessage?: string;
}

interface ClaimCardCardProps<T extends object>
  extends Omit<CollapsibleCardProps, "headingLevel"> {
  id: string;
  children?: React.ReactNode;
  statusValidator?: Validator<T>;
  headingLevel?: Variant;
  visible?: boolean;
  errorProps?: Omit<errorProps, "name">;
}

export const ClaimCard = <T extends object>(props: ClaimCardCardProps<T>) => {
  const { id, children, statusValidator, visible = true, ...rest } = props;
  const formStatusIndicator = statusValidator ? (
    <FormStatusIndicator validator={statusValidator} />
  ) : undefined;

  return (
    <HideStack when={!visible}>
      <ErrorWrapper
        name="claim"
        fields={[]}
        errorMessage="Invalid fields remain in this section"
        {...props.errorProps}
      >
        <CollapsibleCard
          {...dataAttribute(DataAttributes.Element, id)}
          id={id}
          headingLevel="section-heading"
          button={formStatusIndicator}
          {...rest}
        >
          {children}
        </CollapsibleCard>
      </ErrorWrapper>
    </HideStack>
  );
};
