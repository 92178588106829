import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { Shimmer, Stack } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useEventReminderScreenContext } from "@modules/booking/screens/event-reminders/context/EventReminderScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { EventReminderStateDisplayType } from "../EventReminderScreen.types.ts";
import { EventReminderDatesRangePicker } from "./EventReminderDatesRangePicker.tsx";
import { EventReminderStatusPill } from "./EventReminderStatusPill.tsx";
import { EventRemindersTotalsBar } from "./EventRemindersTotalsBar.tsx";

export const EventReminderDashboard: React.FC = observer(() => {
  const { routing } = useStores();
  const {
    eventReminderStates,
    eventReminderSearchResults,
    setEventReminderState
  } = useEventReminderScreenContext();

  useEffect(() => {
    const queryTab = routing.queryStringParam(
      routes.calendarEvents.queryKeys.reminderTab
    );

    if (queryTab) {
      const tabOption = eventReminderStates.find(item => {
        return item.state === queryTab;
      });
      if (tabOption) {
        setEventReminderState(tabOption);
      }
    }
  }, [eventReminderStates, routing, setEventReminderState]);

  return (
    <Stack
      horizontal
      styles={{ root: { marginBottom: 16 } }}
      tokens={{ childrenGap: 8 }}
    >
      <EventReminderDatesRangePicker />
      <Stack horizontal tokens={{ childrenGap: 8 }} grow>
        {eventReminderSearchResults.pending && (
          <Shimmer
            styles={{ root: { flexGrow: 1 }, shimmerWrapper: { height: 36 } }}
          />
        )}

        {!eventReminderSearchResults.pending &&
          eventReminderStates
            .filter(x => x.displayType === EventReminderStateDisplayType.Pill)
            .map(tab => <EventReminderStatusPill key={tab.state} tab={tab} />)}

        {!eventReminderSearchResults.pending && <EventRemindersTotalsBar />}
      </Stack>
    </Stack>
  );
});
