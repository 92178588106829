import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

export const familyRelationships = [
  RelationshipType.Child,
  RelationshipType.Parent,
  RelationshipType.Sibling,
  RelationshipType.Stepparent,
  RelationshipType.Stepchild,
  RelationshipType.Grandparent,
  RelationshipType.Spouse,
  RelationshipType.Partner,
  RelationshipType.Friend,
  RelationshipType.ExtendedFamily
];
