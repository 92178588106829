import { DetailsList, Spinner } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

export interface DiagnosisListItem {
  description?: string;
  side?: string;
  readCode?: string;
}

interface DiagnosisListProps {
  descriptionHeaderText: string;
  diagnoses: DiagnosisListItem[];
}

const DiagnosisListBase: React.FC<DiagnosisListProps> = ({
  descriptionHeaderText,
  diagnoses
}) => {
  const { acc } = useStores();

  const mapDiagnosisToText = (diagnosis: DiagnosisListItem) => ({
    description: diagnosis.readCode
      ? `${diagnosis.description} (${diagnosis.readCode})`
      : diagnosis.description,
    side: acc.ref.diagnosisSides.keyTextValues.find(
      side => side.key === diagnosis.side
    )?.text
  });

  return (
    <DetailsList
      bordersVariant="frameWithHeader"
      columns={[
        {
          name: descriptionHeaderText,
          key: "description",
          fieldName: "description",
          minWidth: 0,
          flexGrow: 1,
          isMultiline: true
        },
        {
          name: "Side",
          key: "side",
          fieldName: "side",
          minWidth: 164,
          maxWidth: 164
        }
      ]}
      items={diagnoses.map(mapDiagnosisToText)}
    />
  );
};

export const DiagnosisList = withFetch(
  x => [x.acc.ref.diagnosisSides.load()],
  DiagnosisListBase,
  { fallback: <Spinner /> }
);
