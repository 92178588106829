import { Fragment } from "react";
import { useForm } from "react-final-form";

import {
  Heading,
  IconButton,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { FormDesignFieldGroup } from "@shared-types/form-design/form-design-field-group.interface.ts";
import { FormDesignValues } from "@shared-types/form-design/form-design-form-values.interface.ts";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { HiddenButton } from "./HiddenButton.tsx";
import { RequiredButton } from "./RequiredButton.tsx";

export interface GroupChildProps {
  field: FormDesignFieldGroup;
  groupIndex: number;
  fieldIndex: number;
}
export const FormDesignGroupFields: React.FC<GroupChildProps> = ({
  field,
  groupIndex,
  fieldIndex
}) => {
  const theme = useTheme();
  const { change, batch, mutators } = useForm<FormDesignValues>();

  const groupPath = `usedGroups[${groupIndex}]`;
  const fieldstr = `${groupPath}.fieldOptions[${fieldIndex}]`;
  const thisField = field.fieldOptions[fieldIndex];
  const onClickHidden = () => {
    batch(() => {
      if (thisField.groupDependency) {
        field.fieldOptions.forEach((x, i) => {
          if (x.groupDependency === thisField.groupDependency) {
            const fieldstr = `${groupPath}.fieldOptions[${i}]`;
            change(
              `${fieldstr}.hidden` as keyof FormDesignValues,
              !thisField.hidden
            );
          }
        });
      } else {
        const fieldstr = `${groupPath}.fieldOptions[${fieldIndex}]`;
        change(
          `${fieldstr}.hidden` as keyof FormDesignValues,
          !thisField.hidden
        );
      }
    });
  };

  const onClickRequired = () => {
    batch(() => {
      if (thisField.groupDependency) {
        field.fieldOptions?.forEach((x, i) => {
          if (x.groupDependency === thisField.groupDependency) {
            const fieldstr = `${groupPath}.fieldOptions[${i}]`;
            change(
              `${fieldstr}.required` as keyof FormDesignValues,
              !thisField.required
            );
          }
        });
      } else {
        const fieldstr = `${groupPath}.fieldOptions[${fieldIndex}]`;
        change(
          `${fieldstr}.required` as keyof FormDesignValues,
          !thisField.required
        );
      }
    });
  };

  const labelElement = (text: string) => (
    <Text
      styles={{
        root: {
          textAlign: "right",
          gridColumn: "2 / 3",
          marginTop: 6,
          marginRight: 6
        }
      }}
    >
      {text}
    </Text>
  );

  const isMulti = thisField.maxChoices !== 1;

  return field ? (
    <>
      {thisField.userCanChangeLabel ? (
        <>
          {labelElement(thisField.noDataEntry ? "Text" : "Question")}
          <TextInputField
            multiline
            resizable={false}
            name={`${fieldstr}.label`}
            placeholder={
              thisField.noDataEntry
                ? "Insert display text"
                : "Insert question text"
            }
            styles={{
              fieldGroup: {
                height: 66
              },
              root: {
                gridColumn:
                  thisField.userCanToggleShow || thisField.userCanToggleRequired
                    ? "span 1"
                    : "span 2"
              }
            }}
          />
          {thisField.userCanSetMaxChoices && (
            <TooltipHost
              content={
                isMulti
                  ? "Multi-select. Unpress to switch to single"
                  : "Single-select. Press to switch to multiple"
              }
            >
              <IconButton
                iconProps={{
                  iconName: isMulti ? "CheckList" : "CheckboxComposite"
                }}
                onClick={() =>
                  change(
                    `${fieldstr}.maxChoices` as keyof FormDesignValues,
                    (isMulti ? 1 : 0) as unknown as string
                  )
                }
                styles={{
                  root: {
                    background: isMulti
                      ? theme.semanticColors.buttonBackgroundPressed
                      : undefined,
                    padding: 16
                  }
                }}
              />
            </TooltipHost>
          )}
        </>
      ) : (
        <Stack
          styles={{
            root: {
              width: "100%",
              borderWidth: 1,
              borderColor: theme.palette.neutralLight,
              borderStyle: "solid",
              padding: "0px 8px",
              background: thisField.hidden
                ? theme.semanticColors.buttonBackgroundDisabled
                : undefined,
              gridColumn: "3 / 4"
            }
          }}
        >
          <TooltipHost
            content={`${thisField.label}${thisField.required ? " *" : ""}`}
          >
            <Heading
              labelPaddings={true}
              hasAsterisk={thisField.required}
              nowrap
              variant="section-heading-light"
              styles={{
                root: {
                  fontSize: 14,
                  color: thisField.hidden
                    ? theme.palette.neutralSecondary
                    : undefined
                }
              }}
            >
              {thisField.label}
            </Heading>
          </TooltipHost>
        </Stack>
      )}
      {!!field.fieldOptions?.length && field.fieldOptions?.length !== 1 && (
        <HiddenButton
          hidden={!thisField.hidden}
          alwaysShown={
            !thisField.userCanToggleShow || !thisField.userCanToggleRequired
          }
          disabled={
            !thisField.userCanToggleShow ||
            thisField.required ||
            !thisField.userCanToggleRequired
          }
          onClick={() => onClickHidden()}
        />
      )}
      {field.field?.children?.[0]?.nodeType !== "None" && (
        <RequiredButton
          disabled={!thisField.userCanToggleRequired || !!thisField.hidden}
          alwaysRequired={!thisField.userCanToggleRequired}
          required={!!thisField.required}
          onClick={() => onClickRequired()}
        />
      )}
      {thisField.userCanSetMinMax && (
        <>
          {labelElement("Min")}
          <SpinNumberInputField
            name={`${fieldstr}.min`}
            min={Number.MIN_SAFE_INTEGER}
          />
          {labelElement("Max")}
          <SpinNumberInputField name={`${fieldstr}.max`} />
          {labelElement("Step")}
          <SpinNumberInputField name={`${fieldstr}.step`} min={1} />
        </>
      )}
      {thisField.userCanSetMinMaxText && (
        <>
          {labelElement("Low Text")}
          <TextInputField
            name={`${fieldstr}.minText`}
            placeholder="Insert display text"
          />
          {labelElement("Middle Text")}
          <TextInputField
            name={`${fieldstr}.middleText`}
            placeholder="Insert display text"
          />
          {labelElement("High Text")}
          <TextInputField
            name={`${fieldstr}.maxText`}
            placeholder="Insert display text"
          />
        </>
      )}
      {thisField.userCanAddChoices &&
        thisField.formChoices?.map((item, itemIndex) => {
          return (
            <Fragment key={item.key}>
              {labelElement(`Answer ${itemIndex + 1}`)}
              <TextInputField
                name={`${fieldstr}.formChoices[${itemIndex}].text`}
                placeholder="Insert answer text"
              />
              {item && (
                <>
                  <IconButton
                    iconProps={{ iconName: "Add" }}
                    onClick={() => {
                      mutators.insert(
                        `${fieldstr}.formChoices`,
                        itemIndex + 1,
                        {
                          key: newGuid(),
                          text: ""
                        }
                      );
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Remove" }}
                    disabled={(thisField.formChoices?.length || 0) <= 2}
                    onClick={() => {
                      item &&
                        mutators.remove(`${fieldstr}.formChoices`, itemIndex);
                    }}
                  />
                </>
              )}
            </Fragment>
          );
        })}
    </>
  ) : null;
};
