import { fabric } from "fabric";

/**
 * Works around the fact that Fabric uses a hand rolled class concept
 * where the Typescript typings is quite average.
 */
interface LineBrushOwnProps {
  canvas?: fabric.Canvas;
  line?: fabric.Line;
  initialize: (this: LineBrushProps, canvas: fabric.Canvas) => void;
  onMouseDown: (
    this: LineBrushProps,
    pointer: { x: number; y: number }
  ) => void;
  onMouseMove: (
    this: LineBrushProps,
    pointer: { x: number; y: number }
  ) => void;
  onMouseUp: (this: LineBrushProps) => void;
  render: (this: LineBrushProps) => void;
}

interface LineBrushProps extends LineBrushOwnProps, fabric.BaseBrush {}

const properties: LineBrushOwnProps = {
  initialize(canvas) {
    this.canvas = canvas;
  },

  onMouseDown(pointer) {
    this.line = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      strokeWidth: this.width,
      stroke: this.color,
      // without originX and originY set to center
      // the line tends to always draw from the top left
      // my guess it that the pointer position defaults to an origin X,Y being the center
      // of the canvas
      originX: "center",
      originY: "center"
    });
  },

  onMouseMove(pointer) {
    if (!this.line) {
      return;
    }

    this.line.set({
      x2: pointer.x,
      y2: pointer.y
    });

    this.render();
  },

  onMouseUp() {
    if (!this.canvas || !this.line) {
      return;
    }

    const context = this.canvas.getSelectionContext();
    this.canvas.clearContext(context);

    // avoid adding a line if the 2 edges correspond to the same
    // coordinates
    if (this.line.width || this.line.height) {
      this.canvas.add(this.line);
    }

    this.line = undefined;
  },

  render() {
    if (!this.canvas || !this.line) {
      return;
    }

    const context = this.canvas.getSelectionContext();
    this.canvas.clearContext(context);
    this.line.render(context);
  }
};

export const LineBrush: LineBrushProps & {
  new (canvas: fabric.Canvas): LineBrushProps;
} = fabric.util.createClass(fabric.BaseBrush, properties);
