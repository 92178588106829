import { observer } from "mobx-react-lite";

import { IButtonProps } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  BillingHeading,
  BillingHeadingProps
} from "@modules/billing/screens/shared-components/BillingHeading.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

interface AllocationDefaultFormHeaderProps
  extends Pick<BillingHeadingProps, "breadcrumbProps"> {
  number: string;
  close: () => void;
  resetting?: boolean;
  onClick?: IButtonProps["onClick"];
  dateOverride?: React.ReactNode;
}

export const AllocationDefaultFormHeader: React.FC<AllocationDefaultFormHeaderProps> =
  observer(({ close, resetting, breadcrumbProps, onClick, dateOverride }) => {
    return (
      <BillingHeading
        breadcrumbProps={breadcrumbProps}
        date={DateTime.jsDateNow()}
        dateOverride={dateOverride}
        buttons={
          <FormSubmitButtons
            hideButtonsSeparator
            submitButtonProps={{ disabled: resetting, onClick }}
            cancelButtonProps={{
              name: "Close",
              onClick: close
            }}
            styles={{ root: { paddingTop: 0, margin: 0 } }}
          />
        }
      />
    );
  });
