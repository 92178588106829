import { observer } from "mobx-react-lite";

import { DataTimeFormats, Link, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Statement } from "@stores/billing/models/Statement.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface AccountStatementDateProps {
  accountContactId?: string | undefined;
  extraLinkText?: boolean;
}

const STATEMENT_LINK_TEXT = "Statement - ";
export const AccountStatementDate: React.FC<AccountStatementDateProps> =
  observer(({ accountContactId, extraLinkText }) => {
    const { billing } = useStores();
    const getLastStatementDate = (statement: Statement) =>
      (statement.date || DateTime.now()).toFormat(
        DataTimeFormats.DAY_DEFAULT_FORMAT
      );

    return (
      <DataFetcher<Statement | undefined>
        refetchId={billing.ui.lastUpdatedStatementETag}
        fetch={({ billing }) =>
          billing.getLatestAccountStatement({ accountContactId })
        }
      >
        {accountStatement => {
          if (!accountStatement) {
            return null;
          }

          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 16 }}
              styles={{ root: { marginLeft: "auto" } }}
            >
              {!extraLinkText && <Text bold>Latest statement</Text>}
              <Link
                onClick={() => {
                  if (accountStatement.id) {
                    billing.openStatementPdf(accountStatement.id);
                    billing.ui.setStatementDocumentPreviewData({
                      contactId: accountContactId,
                      statementId: accountStatement.id,
                      statement: accountStatement
                    });
                  }
                }}
              >
                {extraLinkText
                  ? `${STATEMENT_LINK_TEXT}${getLastStatementDate(
                      accountStatement
                    )}`
                  : getLastStatementDate(accountStatement)}
              </Link>
            </Stack>
          );
        }}
      </DataFetcher>
    );
  });
