import { Length, maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

export class PostureOrObservationsCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();
    this.forField(sotapNameOf("postureOrObservations"), [
      maxLength(Length.comments)
    ]);
  }
}
