import {
  QuestionnaireDto,
  QuestionnaireItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export interface ClinicalToolAnswer {
  questionId: string;
  answerValue: string;
  answerText?: string;
}

export const findClinicalToolAnswer = (
  questionId: string,
  answers?: ClinicalToolAnswer[]
): ClinicalToolAnswer | undefined => {
  if (answers) {
    const answer = answers.find(a => a.questionId === questionId);
    if (answer) return answer;
  }
  return undefined;
};

export const findClinicalToolAnswerText = (
  questionId: number,
  answerValue: string,
  questionnaire: QuestionnaireDto
) => {
  const question = questionnaire.items.find(q => q.id === questionId);
  if (question) {
    const optionText = question.answerOptions?.find(o => {
      return o.value === answerValue;
    });
    if (optionText) return optionText.text;
  }
  return "";
};

export const getSliderConstraints = (
  questionnaireItem: QuestionnaireItemDto,
  initMin: number = 0,
  initMax: number = 0
) => {
  let min = initMin;
  let max = initMax;

  questionnaireItem.answerOptions?.forEach(o => {
    const value = parseInt(o.value);
    if (value < min) min = value;
    if (value > max) max = value;
  });
  return { min, max };
};
