import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontIcon,
  FontSizes,
  NoDataTile,
  NoDataTileProps,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ActivityDescriptionText } from "./ActivityDescriptionText.tsx";
import { ClinicalActivityTypeIcon } from "./ClinicalActivityTypeIcon.tsx";

export interface ClinicalActivitySideTableProps {
  clinicalRecord: ClinicalRecord;
  noDataTileStyles?: NoDataTileProps["styles"];
  greyBackground?: boolean;
  noDataMessage?: string;
  showDialogue: () => void;
}

export const ClinicalActivitySideTableBase: React.FC<ClinicalActivitySideTableProps> =
  observer(
    ({
      clinicalRecord,
      noDataTileStyles,
      noDataMessage,
      greyBackground,
      showDialogue
    }) => {
      const { isViewOnly } = usePatientRecordScreenContext();
      const theme = useTheme();
      const { clinical, core } = useStores();

      const activities = clinicalRecord.clinicalActivities.filter(
        x => !x.isCompleted && !x.isDeleted && !x.secGroupId
      );

      const sortPriority = [
        ClinicalActivityStatusText.Overdue,
        ClinicalActivityStatusText.Today,
        ClinicalActivityStatusText.Upcoming
      ];

      activities.sort((a, b) => {
        if (!a.activityStatus || !b.activityStatus) {
          return 0;
        }

        return (
          sortPriority.indexOf(a.activityStatus) -
          sortPriority.indexOf(b.activityStatus)
        );
      });

      const activitiesCount = activities.length;

      const renderStatusBadge = (item: ClinicalActivity) => {
        const activityStatus = item.activityStatus;
        let badgeColor = TextBadgeColor.lightGrey;
        if (activityStatus === ClinicalActivityStatusText.Upcoming) {
          badgeColor = TextBadgeColor.blue;
        }
        if (activityStatus === ClinicalActivityStatusText.Today) {
          badgeColor = TextBadgeColor.yellow;
        }
        if (activityStatus === ClinicalActivityStatusText.Overdue) {
          badgeColor = TextBadgeColor.red;
        }

        const showWarningIcon =
          activityStatus === ClinicalActivityStatusText.Overdue;

        return (
          <TextBadge
            badgeColor={badgeColor}
            badgeSize={TextBadgeSize.small}
            hasBorder={activityStatus === ClinicalActivityStatusText.Today}
            horizontal
          >
            {showWarningIcon && (
              <FontIcon
                iconName="AlertSolid"
                styles={{ root: { marginRight: 4 } }}
              />
            )}

            {item.dueDate
              ? DateTime.fromISO(item.dueDate).toDayDefaultFormat()
              : `${item.remainingVisits} consults`}
          </TextBadge>
        );
      };

      const renderItem = () => {
        return activities.map(activity => {
          if (!activity.descriptionId) {
            return undefined;
          }

          const activityDescription =
            clinical.activityDescriptionMapValues?.find(
              x => x.id === activity.descriptionId
            );

          if (!activityDescription) {
            return undefined;
          }

          return (
            <Stack
              key={activity.id}
              styles={{
                root: {
                  borderBottom: `1px solid ${theme.palette.themeLighter}`,
                  padding: 8
                }
              }}
              tokens={{ childrenGap: 4 }}
              horizontal
              verticalAlign="center"
            >
              <Stack>
                <ClinicalActivityTypeIcon
                  activityType={activity.activityType}
                />
              </Stack>

              <Stack styles={{ root: { flex: 1 } }}>
                <ActivityDescriptionText
                  descriptionId={activity.descriptionId}
                  freeTextFallback={activity.freeText}
                />
                {activityDescription?.clinicallySignificant && (
                  <TextBadge
                    badgeColor={TextBadgeColor.blue}
                    badgeSize={TextBadgeSize.small}
                  >
                    <FontIcon
                      iconName="UserOptional"
                      styles={{
                        root: {
                          fontSize: FontSizes.size16,
                          verticalAlign: "middle"
                        }
                      }}
                    />
                  </TextBadge>
                )}
              </Stack>

              <Stack>{renderStatusBadge(activity)}</Stack>
            </Stack>
          );
        });
      };

      if (activitiesCount === 0) {
        return (
          <NoDataTile
            styles={{
              root: {
                padding: 0,
                minHeight: 120,
                width: "100%",
                height: "100%"
              },
              ...noDataTileStyles
            }}
            greyView={greyBackground}
            textProps={{ text: noDataMessage ?? "" }}
            linkProps={{
              text: "Add notification",
              hidden:
                isViewOnly ||
                !core.hasPermissions(Permission.ClinActivityWrite) ||
                clinical.ui.clinicalActivityActionDisabled,
              onClick: () => {
                showDialogue();
              }
            }}
            showBoxShadow={false}
          />
        );
      }

      return <>{renderItem()}</>;
    }
  );

export const ClinicalActivitySideTable = withFetch(
  x => [
    x.clinical.ref.clinicalActivityTypes.load(),
    x.clinical.ref.clinicalActivityDescriptions.load(),
    x.clinical.getClinicalActivitiesPreferenceByTenant(),
    x.clinical.loadActivityDescriptions()
  ],
  ClinicalActivitySideTableBase
);
