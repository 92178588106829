import React from "react";

import { Stack } from "@bps/fluent-ui";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { gridRowStyles } from "./InvoiceSettingsForm.styles.ts";
import { SettingsFormPlaceholders } from "./InvoiceSettingsForm.types.tsx";

export const InvoiceSettingsFormAuBankAccountField: React.FC = () => (
  <Stack tokens={{ childrenGap: 8 }}>
    <TextInputField
      label="Account name"
      parse={(value: string) => value ?? ""}
      name="bankDetails.accountName"
      placeholder={SettingsFormPlaceholders.accountName}
    />

    <TextInputField
      label="Bank name"
      parse={(value: string) => value ?? ""}
      name="bankDetails.bankName"
      placeholder={SettingsFormPlaceholders.bankName}
    />

    <Stack horizontal styles={gridRowStyles}>
      <TextInputField
        label="Account number"
        parse={(value: string) => value ?? ""}
        name="bankDetails.accountNumber"
        placeholder={SettingsFormPlaceholders.accountNumber}
        styles={{ root: { gridColumn: "span 3" } }}
      />
      <TextInputField
        label="BSB"
        parse={(value: string) => value ?? ""}
        name="bankDetails.bsb"
        placeholder={SettingsFormPlaceholders.bsb}
        styles={{ root: { gridColumn: "span 2" } }}
      />
    </Stack>
  </Stack>
);
