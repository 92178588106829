export enum TemplateConstants {
  SMS_ONEMSG_CHARACTER_COUNT = 160,
  SMS_TWOMSG_CHARACTER_COUNT = 306,
  SMS_THREEMSG_CHARACTER_COUNT = 459,
  SMS_FOURMSG_CHARACTER_COUNT = 612,
  SMS_FIVEMSG_CHARACTER_COUNT = 765,
  SMS_SIXMSG_CHARACTER_COUNT = 918,
  SMS_SEVENMSG_CHARACTER_COUNT = 1071,
  SMS_MAX_CHARACTER_COUNT = 1224
}
