import { observer } from "mobx-react-lite";

import {
  FontWeights,
  Persona,
  PersonaSize,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { formatPhone } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { getAgeString } from "../../../shared-components/utils/contact.utils.ts";

interface PatientInfoCardProps {
  patient: Contact;
}

export const PatientInfoCard: React.FC<PatientInfoCardProps> = observer(
  ({ patient }) => {
    const { core } = useStores();
    const theme = useTheme();
    const getPatientInfoRow = (title: string, value?: string) => (
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Text bold styles={{ root: { flexShrink: 0 } }}>
          {title}
        </Text>
        <Text
          styles={{
            root: {
              color: theme.palette.neutralSecondary
            }
          }}
        >
          {value || ""}
        </Text>
      </Stack>
    );

    return (
      <Stack styles={{ root: { padding: 0 } }}>
        <Persona
          text={patient.nameWithTitle}
          id={patient.id}
          size={PersonaSize.size40}
          imageInitials={patient.initials}
          styles={{
            root: { margin: "16px 4px" },
            primaryText: {
              fontWeight: FontWeights.semibold,
              maxWidth: 300
            }
          }}
        />
        <Stack tokens={{ childrenGap: 8 }} styles={{ root: { padding: 8 } }}>
          {getPatientInfoRow("First name", patient.firstName)}
          {getPatientInfoRow("Last name", patient.lastName)}
          {getPatientInfoRow(
            "DOB",
            patient.birthDate &&
              `${patient.birthDate.toDayDefaultFormat()} (${getAgeString(
                patient.birthDate,
                patient.dateOfDeath
              )})`
          )}
          {getPatientInfoRow("Sex", patient.sexExpandedForm)}
          {getPatientInfoRow(
            "Phone",
            patient.phone &&
              formatPhone(patient.phone, core.tenantDetails?.country)
          )}
          {getPatientInfoRow("Email", patient.email)}
          {getPatientInfoRow(
            "Physical address",
            patient.physicalAddress && addressText(patient.physicalAddress)
          )}
          {getPatientInfoRow(
            "Status",
            capitalizeSentence(patient.status?.toLowerCase() || "")
          )}
        </Stack>
      </Stack>
    );
  }
);
