import { useForm } from "react-final-form";

import {
  ActionButton,
  ButtonsGroupOption,
  flatten,
  Stack,
  Text,
  useResizeElementObserver,
  useTheme
} from "@bps/fluent-ui";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  DermatomeFieldValues,
  DermatomeOption,
  DermatomesMyotomesAndReflexesFormValues,
  DermatomeTest
} from "./DermatomesAndMyotomesForm.types.ts";
import { NerveSelect } from "./NerveSelect.tsx";

interface DermatomeFieldsProps {
  name: string;
  testType: DermatomeTest;
  options: string[];
}

enum Estimations {
  Absent = "ABS",
  Decreased = "DEC",
  Increased = "INC",
  Same = "SAM"
}
export const DermatomeFields: React.FC<DermatomeFieldsProps> = ({
  testType,
  name,
  options
}) => {
  const theme = useTheme();
  const deleteButtonStyles = { root: { marginLeft: 0 } };

  const form = useForm<DermatomesMyotomesAndReflexesFormValues>();

  const getNerveOptions = (
    dermatome: DermatomeFieldValues,
    usedNerves: string[]
  ) => {
    const nerveOptions: DermatomeOption[] = options.map(x => {
      return {
        nerve: x,
        disabled: !dermatome.nerves?.includes(x) && usedNerves.includes(x),
        leftDisabled:
          !dermatome.nerves?.includes(`${x}-L`) &&
          usedNerves.includes(`${x}-L`),
        rightDisabled:
          !dermatome.nerves?.includes(`${x}-R`) && usedNerves.includes(`${x}-R`)
      };
    });

    return nerveOptions;
  };

  const isDisabled = (
    dermatome: DermatomeFieldValues,
    estimation: Estimations,
    usedEstimations: string[]
  ) => {
    return (
      !dermatome.nerves ||
      dermatome.nerves.length === 0 ||
      ((!dermatome.estimation || dermatome.estimation[0] !== estimation) &&
        usedEstimations.includes(estimation))
    );
  };

  const getEstimationOptions = (
    dermatome: DermatomeFieldValues,
    usedEstimations: string[]
  ): ButtonsGroupOption<Estimations>[] => {
    const absDisabled = isDisabled(
      dermatome,
      Estimations.Absent,
      usedEstimations
    );

    const decDisabled = isDisabled(
      dermatome,
      Estimations.Decreased,
      usedEstimations
    );

    const samDisabled = isDisabled(
      dermatome,
      Estimations.Same,
      usedEstimations
    );

    const incDisabled = isDisabled(
      dermatome,
      Estimations.Increased,
      usedEstimations
    );

    return [
      {
        key: Estimations.Absent,
        text: "A",
        disabled: absDisabled,
        iconName: "ErrorBadge",
        tooltipContent: "Absent"
      },
      {
        key: Estimations.Decreased,
        text: "D",
        disabled: decDisabled,
        iconName: "Down",
        tooltipContent: "Decreased"
      },
      {
        key: Estimations.Same,
        text: "S",
        disabled: samDisabled,
        iconName: "CalculatorEqualTo",
        tooltipContent: "Same"
      },
      {
        key: Estimations.Increased,
        text: "I",
        disabled: incDisabled,
        iconName: "Up",
        tooltipContent: "Increased"
      }
    ];
  };

  const { setElement, resizeObserverEntry, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  return (
    <div
      ref={r => {
        if (r && !element) {
          setElement(r);
        }
      }}
      style={{ height: "100%" }}
    >
      <FieldArray name={name}>
        {({ fields }) => {
          const usedEstimations = fields.value
            .filter(x => x.estimation && x.testType === testType)
            .map(x => x.estimation[0]);

          const usedNervesArrays = fields.value
            .filter(x => x.testType === testType && x.nerves)
            .map(x => x.nerves);

          const usedNerves = flatten(usedNervesArrays);
          return (
            <Stack tokens={{ childrenGap: 8 }}>
              {fields.map((dermName, index) => {
                const dermatome = fields.value[index];
                if (dermatome.testType !== testType) return null;
                return (
                  <Stack
                    key={dermName}
                    tokens={{ childrenGap: 8 }}
                    styles={{
                      root: {
                        paddingTop: 8,
                        borderTop: "1px solid",
                        borderTopColor: theme.palette.neutralLight
                      }
                    }}
                  >
                    <Stack
                      horizontal
                      verticalAlign="center"
                      tokens={{ childrenGap: 8 }}
                    >
                      <Text styles={{ root: { fontWeight: 600 } }}>Nerves</Text>

                      <Stack grow>
                        <NerveSelect
                          options={getNerveOptions(dermatome, usedNerves)}
                          name={name}
                          index={index}
                          dermName={dermName}
                          dermatome={dermatome}
                          panelWidth={panelWidth}
                        />
                      </Stack>

                      {((dermatome.testType === DermatomeTest.LightTouch &&
                        index > 0) ||
                        (dermatome.testType === DermatomeTest.Pinprick &&
                          index > 1)) && (
                        <DeleteButton
                          styles={deleteButtonStyles}
                          onClick={() => fields.remove(index)}
                        />
                      )}
                    </Stack>
                    <Stack
                      horizontal
                      wrap
                      verticalAlign="center"
                      tokens={{ childrenGap: 8 }}
                    >
                      <Stack styles={{ root: { width: 176 } }}>
                        <ButtonsGroupSingleChoiceField
                          name={`${dermName}.estimation`}
                          options={getEstimationOptions(
                            dermatome,
                            usedEstimations
                          )}
                        />
                      </Stack>
                      <Stack grow styles={{ root: { minWidth: 144 } }}>
                        {dermatome.hasComment ? (
                          <TextInputField
                            name={`${dermName}.comment`}
                            rows={1}
                            multiline
                            maxLength={250}
                            autoAdjustHeight
                            resizable={false}
                            styles={{
                              fieldGroup: { minHeight: 30 }
                            }}
                            placeholder="Comment"
                          />
                        ) : (
                          <ActionButton
                            iconProps={{ iconName: "CommentAdd" }}
                            disabled={
                              !dermatome.nerves || dermatome.nerves.length === 0
                            }
                            onClick={() =>
                              form.mutators.update(name, index, {
                                nerves: dermatome.nerves,
                                estimation: dermatome.estimation,
                                hasComment: true,
                                comment: dermatome.comment,
                                testType: dermatome.testType
                              })
                            }
                          >
                            Add comment
                          </ActionButton>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
              <ActionButton
                iconProps={{ iconName: "Add" }}
                onClick={() => fields.push({ testType })}
              >
                Add another
              </ActionButton>
            </Stack>
          );
        }}
      </FieldArray>
    </div>
  );
};
