import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";

export const ClearFieldsOnPatientChangeSpy: FunctionComponent = observer(() => {
  const form = useForm<InvoiceFormValues>();

  const onChange = async (
    patientId: InvoiceFormValues["patientId"],
    values: InvoiceFormValues
  ) => {
    form.batch(() => {
      if (values.claimId) {
        form.change("claimId", "");
      }
      if (values.calendarEventId) {
        form.change("calendarEventId", "");
      }
    });
  };

  return <FieldSpy name={invoiceFormNameOf("patientId")} onChange={onChange} />;
});
