import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import {
  dataAttribute,
  DataAttributes,
  FieldItemError,
  NoDataTile,
  Shimmer,
  Stack
} from "@bps/fluent-ui";
import { PurchaseOrderDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";

import { InvoiceDetailsRow } from "./invoice-details-row/InvoiceDetailsRow.tsx";
import { invoiceFormNameOf } from "./InvoiceForm.types.tsx";
import { InvoiceFormItemList } from "./InvoiceFormItemList.tsx";

export const InvoiceFormFields: FunctionComponent = observer(() => {
  const {
    addEmptyServiceRowToForm,
    getClaim,
    serviceWarningsHelper,
    setIsAddItemDialogVisible,
    setIsInvoiceDetailsModalVisible
  } = useContext(InvoiceFormContext);

  const form = useForm<InvoiceFormValues>();
  const { values } = useFormState<InvoiceFormValues>({
    subscription: { values: true }
  });

  const { claimId } = values;

  const claim = getClaim(claimId);

  const fetch = async ({ acc }: IRootStore): Promise<PurchaseOrderDto[]> => {
    if (claim?.id) {
      return await acc.getPurchaseOrdersByClaimId(claim.id);
    }
    return [];
  };

  return (
    <Stack tokens={{ childrenGap: 70 }}>
      <InvoiceDetailsRow
        invoice={values}
        onEditClick={() => setIsInvoiceDetailsModalVisible(true)}
      />

      {/* These Fields ensure that the form is correctly clean or dirty when these details are updated from the modal */}
      <Field name={invoiceFormNameOf("accountContactId")} render={() => null} />
      <Field name={invoiceFormNameOf("accountAddress")} render={() => null} />
      <Field name={invoiceFormNameOf("patientId")} render={() => null} />
      <Field name={invoiceFormNameOf("reference")} render={() => null} />

      <FieldArray name={invoiceFormNameOf("invoiceItems")}>
        {(
          fieldArrayProps: FieldArrayRenderProps<
            InvoiceItemFormValue,
            HTMLElement
          >
        ) => (
          <FormItemField
            name={invoiceFormNameOf("invoiceItems")}
            data-name={invoiceFormNameOf("invoiceItems")}
            label={`${InvoiceFormLabels.invoicedItems} (${values.invoiceItems.length})`}
            styles={({ theme }) => ({
              root: { position: "relative" },
              headerWrapper: { padding: 0 },
              subComponentStyles: {
                button: { root: { top: 0, fontSize: 39 } },
                label: {
                  root: {
                    // extra validation of invoiceItems state, but is an item is invalid
                    color:
                      fieldArrayProps.meta.submitFailed &&
                      fieldArrayProps.meta.invalid
                        ? theme.semanticColors.errorText
                        : undefined,
                    fontSize: theme.fonts.large.fontSize,
                    position: "absolute",
                    top: 4,
                    left: 0,
                    zIndex: 1,
                    margin: 0
                  }
                }
              }
            })}
            {...dataAttribute(DataAttributes.Element, "invoice-items")}
          >
            {fieldArrayProps.fields &&
            fieldArrayProps.fields.value.length > 0 ? (
              <>
                <DataFetcher<PurchaseOrderDto[]>
                  fetch={fetch}
                  refetchId={claim?.id}
                  fallback={<Shimmer width={120} />}
                >
                  {purchaseOrders => (
                    <InvoiceFormItemList
                      invoiceItems={fieldArrayProps.fields.value}
                      claim={claim}
                      purchaseOrders={purchaseOrders}
                      errors={fieldArrayProps.meta.error}
                      serviceWarningsHelper={serviceWarningsHelper}
                      submitFailed={fieldArrayProps.meta.submitFailed}
                      onRemove={fieldArrayProps.fields.remove}
                      onAddNewRow={() => addEmptyServiceRowToForm(form)}
                      onOpenItemDialog={() => setIsAddItemDialogVisible(true)}
                    />
                  )}
                </DataFetcher>

                {
                  // extra validation of invoiceItems state, but is an item is invalid
                  fieldArrayProps.meta.submitFailed &&
                    fieldArrayProps.meta.invalid && (
                      <FieldItemError
                        name="invoice-item"
                        errorMessage={ValidationMessages.errorsInInvoiceItems}
                      />
                    )
                }
              </>
            ) : (
              <FieldSpy name={invoiceFormNameOf("invoiceItems")}>
                {meta => {
                  return (
                    <NoDataTile
                      styles={{ root: { boxShadow: "none", marginTop: 30 } }}
                      textProps={{ text: "No items" }}
                      linkProps={{
                        text: "Add items to this invoice",
                        onClick: () => setIsAddItemDialogVisible(true)
                      }}
                      invalid={meta.submitFailed && meta.error}
                    />
                  );
                }}
              </FieldSpy>
            )}
          </FormItemField>
        )}
      </FieldArray>
    </Stack>
  );
});
