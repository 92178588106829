import { getAllocationsOwing, getAllocationsTotal } from "../utils.tsx";
import { useAllocationsValues } from "./useAllocationsValues.ts";

/**
 *  @returns allocatedTotal:The total amount that has been allocated to the items.
 *  @returns owingRemianing:The amount that is still owing an can be allocated to.
 **/
export const useAllocationsTotal = () => {
  const allocations = useAllocationsValues();
  const allocatedTotal = getAllocationsTotal(allocations);
  const owingRemaining = getAllocationsOwing(allocations);

  return { allocatedTotal, owingRemaining };
};
