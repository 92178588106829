import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  FontWeights,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

export type LabelData = {
  data: {
    label: string;
    value: string;
    tooltipText?: string;
  };
  id: string;
};

export interface StatusLabelProps {
  labelData: LabelData[];
  childrenGap?: number;
  isManualAllocationModal?: boolean;
}

export const StatusLabel: React.FunctionComponent<StatusLabelProps> = ({
  labelData,
  childrenGap
}) => {
  const theme = useTheme();
  return (
    <Stack horizontal tokens={{ childrenGap }} id="status-label">
      {labelData.map((data: LabelData) => (
        <Stack
          {...dataAttribute(DataAttributes.Element, data.id)}
          horizontal
          tokens={{ childrenGap: 10 }}
          key={data.id}
        >
          <Text variant="large">{data.data.label}</Text>
          <Text
            variant="large"
            styles={{ root: { fontWeight: FontWeights.semibold } }}
          >
            {data.data.value}
          </Text>
          {data.data.tooltipText ? (
            <TooltipHost content={data.data.tooltipText}>
              <FontIcon
                iconName="Info"
                styles={{
                  root: {
                    fontSize: FontSizes.size16,
                    color: theme.palette.themePrimary,
                    marginTop: 4,
                    verticalAlign: "bottom"
                  }
                }}
              />
            </TooltipHost>
          ) : null}
        </Stack>
      ))}
    </Stack>
  );
};
