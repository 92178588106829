import { observer } from "mobx-react-lite";

import { DefaultButton, Link, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { KNOWLEDGEBASE_INFO_LINK } from "./PatientMergeFieldValues.ts";

interface PatientMergeConfirmationProps {
  master: Contact;
  duplicate: Contact;
}
export const PatientMergeConfirmationDialog: React.FC<PatientMergeConfirmationProps> =
  observer(({ master, duplicate }) => {
    const {
      practice: {
        ui: {
          setShowPatientMergeModal,
          setShowPatMergeConfirmation,
          setMasterPatient
        },
        mergePatients
      },
      notification
    } = useStores();

    const patientLabel = usePatientLabel(false);
    const patientLabelUpperCase = usePatientLabel(true);

    const onDismiss = () => {
      setShowPatMergeConfirmation(false);
      setShowPatientMergeModal(false);
      setMasterPatient(undefined);
    };

    const getCommaSeparatedString = (label: string, value?: string) =>
      !!value?.trim() ? `, ${label} ${value}` : "";

    const getInformationString = (
      masterPatient: Contact,
      duplicatePatient: Contact
    ) => {
      const dobMaster = getDateString(masterPatient?.birthDate);
      const dobDuplicate = getDateString(duplicatePatient?.birthDate);

      return `Master ${patientLabel} ${masterPatient?.nameWithTitle}${getCommaSeparatedString(
        "DOB",
        dobMaster
      )}${getCommaSeparatedString(
        "postcode",
        masterPatient?.physicalAddress?.postCode
      )} will be merged with duplicate ${patientLabel} ${duplicatePatient?.nameWithTitle}${getCommaSeparatedString(
        "DOB",
        dobDuplicate
      )}${getCommaSeparatedString(
        "postcode",
        duplicatePatient?.physicalAddress?.postCode
      )}.`;
    };

    const getDateString = (value?: DateTime) =>
      value ? value.toDayDefaultFormat() : "";

    return (
      <SubmissionFormDialog
        dialogName="Patient merge confirmation dialog"
        dialogProps={{
          minWidth: 600,
          styles: {
            root: {
              ".ms-Dialog-action": { width: "100%" }
            }
          },
          dialogContentProps: {
            title: `Confirm ${patientLabel} merge`
          },
          onDismiss
        }}
        buttonsProps={{
          cancelButtonProps: {
            id: "close-patient-merge-confirmation-form-dialog"
          },
          submitButtonProps: {
            text: "Confirm and merge",
            iconProps: { hidden: true },
            disabled: false
          },
          extraPromptConditionOnCancel: () => true,
          promptOnCancel: true,
          extraActionsBefore: () => {
            return (
              <DefaultButton
                text="Back"
                onClick={() => setShowPatMergeConfirmation(false)}
                styles={{ root: { marginRight: "auto" } }}
              />
            );
          },
          hideButtonsSeparator: true,
          styles: { root: { marginTop: 0 } }
        }}
        onSubmit={async () => {
          await mergePatients(master.id, duplicate.id);
          notification.success(`${patientLabelUpperCase} merge in progress`);
          onDismiss();
        }}
      >
        {() => (
          <Stack tokens={{ childrenGap: 30 }}>
            <Text>{getInformationString(master, duplicate)}</Text>
            <Text>
              Check and be sure before confirming. Review the{" "}
              <Link href={KNOWLEDGEBASE_INFO_LINK} target="_blank">
                Knowledge Base
              </Link>{" "}
              for full merge rules
            </Text>
          </Stack>
        )}
      </SubmissionFormDialog>
    );
  });
