import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { EncounterLocation } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ConsultDetailDialogFields } from "./ConsultDetailDialogFields.tsx";
import { EncounterFormContext } from "./models/EncounterFormContext.ts";
import { ReasonForConsultValidator } from "./ReasonForConsultValidator.ts";

export interface ConsultDetailDialogValues {
  startDate?: Date;
  startTime?: string;
  type?: string;
  location?: EncounterLocation;
  confidential?: boolean;
  reasonForVisit?: string[];
  otherText?: string;
  practiceLocationId?: string;
}

// exported to allow for easier testing
export const CANCEL_BTN_ID = "todays-note-dt-dialog-cancel-button";
export const DELETE_BTN_ID = "todays-note-dt-dialog-delete-button";
const SUBMIT_BTN_ID = "todays-note-dt-dialog-submit-button";

interface ConsultDetailDialogProps {
  initialValues: ConsultDetailDialogValues;
  onSubmit: (values: ConsultDetailDialogValues) => void;
}

export const ConsultDetailDialog: React.FC<ConsultDetailDialogProps> = observer(
  ({ initialValues, onSubmit }) => {
    const { core } = useStores();
    const {
      closeConsultDetailDialog,
      consultDetailDialogVisibleWithFocus,
      isEnding,
      isFinalising
    } = useContext(EncounterFormContext);

    const helper = useContext(EncounterFormContext);

    if (!consultDetailDialogVisibleWithFocus) return null;

    const validator = new ReasonForConsultValidator(core, {
      isReasonForVisitRequired: isEnding || isFinalising
    });

    const dialogContentProps = {
      title: "Edit consult details"
    };

    const submitButtonProps = {
      id: SUBMIT_BTN_ID,
      iconProps: {}
    };

    const cancelButtonProps = {
      id: CANCEL_BTN_ID,
      text: "Cancel",
      iconProps: {}
    };

    return (
      <SubmissionFormDialog<ConsultDetailDialogValues>
        autoFocus={false}
        dialogName="Todays Notes details dialog"
        dialogProps={{
          onDismiss: () => {
            helper.isFinalising = false;
            closeConsultDetailDialog();
          },
          dialogContentProps,
          minWidth: 500,
          maxWidth: 725
        }}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSucceeded={closeConsultDetailDialog}
        render={() => <ConsultDetailDialogFields />}
        validate={validator.validate}
        buttonsProps={{
          submitButtonProps,
          cancelButtonProps
        }}
      />
    );
  }
);
