import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useMemo } from "react";

import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { BookingCalendarEventModel } from "../../BookingCalendarEventModel.ts";
import { BookingCalendarEventContext } from "./BookingCalendarEventContext.tsx";
import { BookingEventContextualMenu } from "./contextual-menu/BookingEventContextualMenu.tsx";
import { BookingEventCallout } from "./popup/BookingEventCallout.tsx";

/**
 * Component is responsible for BookingEventCallout and BookingCalendarEventContext displaying in
 * BookingCalendarEvent, AgendaList and WaitingRoom
 * @param children
 * @constructor
 */
export const BookingCalendarEventController: FunctionComponent = observer(
  () => {
    const root = useStores();
    const {
      practice: {
        ui: { currentContactId }
      }
    } = root;

    const { setCurrentEventData, currentEventData } =
      useBookingCalendarScreenContext();

    const model = useMemo(
      () =>
        currentEventData.event
          ? new BookingCalendarEventModel(root, currentEventData.event)
          : undefined,
      [root, currentEventData.event]
    );

    const onDismiss = () => setCurrentEventData({});

    const onDismissCallout = () => {
      // do not close event modal if patient form has been opened
      if (!currentContactId) {
        onDismiss();
      }
    };

    useEffect(() => {
      return () => {
        setCurrentEventData({});
      };
    }, [setCurrentEventData]);

    return currentEventData.event && model?.calendarEvent ? (
      <BookingCalendarEventContext.Provider value={model}>
        {currentEventData.calloutPosition && (
          <BookingEventCallout
            target={currentEventData.calloutPosition}
            onDismiss={onDismissCallout}
          />
        )}

        {currentEventData.contextualMenuPosition && (
          <BookingEventContextualMenu
            target={currentEventData.contextualMenuPosition}
            onDismiss={onDismiss}
          />
        )}
      </BookingCalendarEventContext.Provider>
    ) : null;
  }
);
