import { FunctionComponent, memo } from "react";
import { useField, useForm } from "react-final-form";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ErrorBlock } from "@ui-components/ErrorBlock.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { invoiceDetailsModalFormNameOf } from "./InvoiceDetailsModal.types";

export const InvoiceLicenceWarning: FunctionComponent = memo(() => {
  const { core } = useStores();

  const { change } = useForm<InvoiceDetailsModalFormValues>();

  const {
    input: { value: userId }
  } = useField<string | undefined>(invoiceDetailsModalFormNameOf("userId"), {
    subscription: { value: true }
  });

  const fetchUser = async () => {
    let billingAllowed = false;

    if (!!userId) {
      const user = await core.getUser(userId);

      billingAllowed = await core.checkUserPermissionsFromAPI(user.username, [
        Permission.BillingAllowed
      ]);

      change("billingAllowed", billingAllowed);
    } else {
      change("billingAllowed", undefined);
    }

    return {
      billingAllowed
    };
  };

  return (
    <DataFetcher<{
      billingAllowed: boolean;
    }>
      refetchId={userId}
      fetch={fetchUser}
    >
      {({ billingAllowed }) => (
        <When permission={Permission.LicencingAllowed}>
          {userId && !billingAllowed && (
            <ErrorBlock
              errorText="You can only create invoices for licensed providers."
              linkText="Assign a licence"
              to={routes.settings.users.user.path({
                id: userId
              })}
            />
          )}
        </When>
      )}
    </DataFetcher>
  );
});
