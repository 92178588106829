import {
  FontWeights,
  IDropdownOption,
  SelectableOptionMenuItemType
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PurchaseOrderDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

export interface PurchaseOrderFieldProps {
  name: string;
  claim?: Claim;
  purchaseOrders: PurchaseOrderDto[];
}

const getDropdownOptions = (
  claim: Claim,
  purchaseOrders: PurchaseOrderDto[]
): IDropdownOption[] => {
  const options: IDropdownOption[] = [
    {
      key: "header",
      text: `Claim ${claim.claimNumber || ""}`,
      itemType: SelectableOptionMenuItemType.Header
    },
    {
      key: "divider",
      text: "",
      itemType: SelectableOptionMenuItemType.Divider
    }
  ];

  if (purchaseOrders.length) {
    return options.concat(
      purchaseOrders
        .filter(x => x.purchaseOrderNumber && x.responseDate)
        .map(purchaseOrder => ({
          key: purchaseOrder.purchaseOrderNumber!,
          text: `${purchaseOrder.purchaseOrderNumber} — ${DateTime.fromISO(
            purchaseOrder.responseDate!
          ).toDayDefaultFormat()}`
        }))
    );
  } else
    return options.concat([
      { key: "noPOAvailable", text: "No PO available", disabled: true }
    ]);
};

export const PurchaseOrderField: React.FunctionComponent<
  PurchaseOrderFieldProps
> = ({ name, claim, purchaseOrders }: PurchaseOrderFieldProps): JSX.Element => {
  return claim ? (
    <DropdownField
      onRenderTitle={(options: IDropdownOption[]) => {
        const title = options[0]?.text.split("—")[0];
        return <span>{title}</span>;
      }}
      styles={{
        dropdownItemHeader: { fontWeight: FontWeights.regular },
        dropdownItemDisabled: { fontStyle: "italic" }
      }}
      name={name}
      options={getDropdownOptions(claim, purchaseOrders)}
      dropdownWidth="auto"
    />
  ) : (
    <TextInputField name={name} maxLength={15} placeholder="PO #" />
  );
};
