import { observer } from "mobx-react-lite";

import { DefaultButton, Dialog, PrimaryButton, Stack } from "@bps/fluent-ui";

type TemplateUsingElseWhereDialogProps = {
  onDismiss: () => void;
  onDelete: () => void;
  hidden: boolean;
  templateName: string;
};
export const TemplateUsingElseWhereDialog: React.FC<TemplateUsingElseWhereDialogProps> =
  observer(({ onDismiss, onDelete, hidden, templateName }) => {
    return (
      <Dialog
        hidden={hidden}
        dialogContentProps={{
          title: `Delete ${templateName}`,
          showCloseButton: true,
          onDismiss
        }}
        minWidth={470}
      >
        <Stack tokens={{ childrenGap: 16 }}>
          <Stack.Item>
            This template is referenced in other part of the application. Are
            you sure you want to proceed?
          </Stack.Item>
          <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 8 }}>
            <PrimaryButton onClick={onDelete}>Delete</PrimaryButton>
            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </Stack>
        </Stack>
      </Dialog>
    );
  });
