import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { IContextualMenuListProps, Separator } from "@bps/fluent-ui";

interface SubMenuSeparatorProps {
  listProps: IContextualMenuListProps | undefined;
  defaultRender?: (props: IContextualMenuListProps) => JSX.Element | null;
  key: string;
}

export const SubMenuSeparator: FunctionComponent<SubMenuSeparatorProps> =
  observer(({ listProps, defaultRender, key }) => {
    if (defaultRender && listProps) {
      return defaultRender({
        ...listProps,
        items: [
          {
            key,
            onRender: () => <Separator styles={{ root: { padding: 0 } }} />
          }
        ]
      });
    }

    return null;
  });
