import { action, observable, runInAction } from "mobx";

import { DateTime, newGuid } from "@bps/utils";
import {
  CorrespondenceDirection,
  CorrespondenceStatus,
  DocumentContentType,
  DocumentDto,
  DocumentEnum,
  DocumentExtensionType,
  DocumentMetadataItem,
  DocumentTabStatus,
  DocumentWriterTab,
  Outcome,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AddUserAction,
  Instructions,
  NoActionUserActionContext,
  UserActionType,
  UserTaskDto,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import {
  ClaimReviewStatus,
  DocumentStatus
} from "@modules/clinical/screens/patient-record/components/claim-review/ClaimReviewEnums.ts";
import { getNewMetadata } from "@modules/clinical/utils/clinical.utils.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { MoveCorrespondenceFormValues } from "../../clinical-form/MoveCorrespondenceFormValues.ts";
import { handleConflictError } from "../utils.tsx";

interface DeleteCorrespondenceFromFormArgs {
  item: ClinicalDocument;
  reasonForDelete: string;
  reasonForDeleteComment?: string;
}

const { Name, Extension, ContentType, OriginatingDocumentId } = DocumentEnum;
export class CorrespondencesHelper {
  constructor(
    private root: RootStore,
    public clinicalRecord: ClinicalRecord
  ) {}

  private get correspondence() {
    return this.root.correspondence;
  }

  private get notification() {
    return this.root.notification;
  }

  private get inbox() {
    return this.root.inbox;
  }

  private get core() {
    return this.root.core;
  }

  @observable
  isPrinting: boolean = false;

  @action
  setIsPrinting = (value: boolean) => {
    this.isPrinting = value;
  };

  @observable
  documentViewerVisible: boolean = false;

  @action
  setDocumentViewerVisible = (value: boolean) => {
    this.documentViewerVisible = value;
  };

  @observable
  moveDialogVisible: boolean = false;

  @action
  setMoveDialogVisible = (value: boolean) => {
    this.moveDialogVisible = value;
  };

  @observable
  editDialogVisible: boolean = false;

  @action
  setEditDialogVisible = (value: boolean) => {
    this.editDialogVisible = value;
  };

  @observable
  selectedClinicalDocument: ClinicalDocument | undefined;

  @action
  setSelectedClinicalDocument = (value: ClinicalDocument | undefined) => {
    this.selectedClinicalDocument = value;
  };

  @observable
  isDeleteDialogVisible: boolean = false;

  @action
  setIsDeleteDialogVisible = (value: boolean) => {
    this.isDeleteDialogVisible = value;
  };

  @observable
  uploadDialogVisible: boolean = false;

  @action
  setUploadDialogVisible = (value: boolean) => {
    this.uploadDialogVisible = value;
  };

  @observable
  sendDialogVisible: boolean;

  @action
  setSendDialogVisible = (value: boolean) => {
    this.sendDialogVisible = value;
  };

  onDeleteConfirm = async (
    reasonForDelete: string,
    reasonForDeleteComment?: string
  ) => {
    if (this.selectedClinicalDocument?.id) {
      await this.deleteCorrespondence({
        item: this.selectedClinicalDocument,
        reasonForDelete,
        reasonForDeleteComment
      });

      this.setIsDeleteDialogVisible(false);
    }
  };

  deleteCorrespondence = async (args: DeleteCorrespondenceFromFormArgs) => {
    const patientId = this.clinicalRecord.id;

    try {
      const itemParam = args.item;
      //remove correspondence from backend database (API used)
      //require: PatientID, Etag, document ID, encounter id.
      await this.root.correspondence.deleteDocument(
        this.clinicalRecord.openEncounter?.id!,
        itemParam.id,
        {
          patientId,
          eTag: itemParam.eTag,
          reasonForDelete: args.reasonForDelete,
          reasonForDeleteComment: args.reasonForDeleteComment
        }
      );

      //remove on screen (render purpose)
      runInAction(() => {
        this.root.correspondence.correspondenceListRefreshKey = `${args.item.id}-deleted`;
      });

      //patch claim review to database (API used)
      const claimReviewId = itemParam.metadata.find(
        x => x.key === DocumentEnum.claimReviewId
      )?.value;
      if (claimReviewId) {
        const claimReview = await this.root.acc.getClaimReview(claimReviewId);
        if (claimReview) {
          await this.root.acc.patchClaimReview({
            id: claimReviewId,
            eTag: claimReview.eTag,
            documentStatus: DocumentStatus.deleted,
            statusCode: ClaimReviewStatus.draft
          });
        }
      }
    } catch (error) {
      const isConflict = await handleConflictError(error, confirmed => {
        this.handleOnConflictError(confirmed);
      });
      if (!isConflict) {
        this.root.notification.error(
          "An error occurred when deleting the correspondence."
        );
      }
    }
  };

  handleOnConflictError = async (confirmed: boolean) => {
    if (confirmed) {
      await this.handleDismissMoveDialog();
      this.handleDismissDetails(true);
      runInAction(() => {
        this.root.correspondence.correspondenceListRefreshKey =
          "conflict-error";
      });
    }
  };

  handleDismissMoveDialog = async () => {
    this.setSelectedClinicalDocument(undefined);
    this.setMoveDialogVisible(false);
  };

  handleDismissDetails = (clearSelected?: boolean) => {
    this.correspondence.ui.setShowTemplatePicker(false);
    this.setUploadDialogVisible(false);
    this.setEditDialogVisible(false);
    this.setMoveDialogVisible(false);
    if (clearSelected) {
      this.setSelectedClinicalDocument(undefined);
    }
  };

  handleDismissSendDialog = () => {
    // Close the send dialog without closing the document viewer
    if (!this.documentViewerVisible)
      this.setSelectedClinicalDocument(undefined);
    this.setSendDialogVisible(false);
  };

  setActiveDocumentTab = (record: ClinicalDocument) => {
    this.correspondence.activeDocumentTab = {
      documentId: record.id,
      patientId: record.patientId,
      encounterId: this.clinicalRecord.openEncounter?.id,
      documentTabStatus: DocumentTabStatus.Edit
    };
  };

  onOpenClinicalDocumentClick = async (item: ClinicalDocument) => {
    if (item.status === CorrespondenceStatus.Draft) {
      this.setActiveDocumentTab(item);
    } else {
      // this call interacts with document API to get the SasUri, required for viewing documents
      await this.previewCorrespondence(item);
    }
  };

  previewCorrespondence = async (item: ClinicalDocument) => {
    const corr = await this.correspondence.getCorrespondenceByDocumentId(
      this.clinicalRecord.id,
      item.id
    );

    this.setSelectedClinicalDocument(corr);
    this.setDocumentViewerVisible(true);
  };

  onMoveTo = async (item: ClinicalDocument, storeType: string) => {
    if (this.clinicalRecord.openEncounter?.id) {
      const document = {
        ...item.dto,
        store: storeType
      };

      if (storeType === StoreType.Correspondence) {
        document.direction = CorrespondenceDirection.In;
      }

      await this.correspondence.updateCorrespondence(
        this.clinicalRecord.openEncounter.id,
        document.id,
        document
      );

      runInAction(() => {
        this.root.correspondence.correspondenceListRefreshKey = `${item.id}-moved`;
      });
    } else {
      this.notification.error(
        "Failed to move document to investigations - no active encounter."
      );
    }
  };

  onOutcomeClick = async (document: ClinicalDocument, outcome: string) => {
    const task = this.clinicalRecord.patientUserTasks.find(
      task => task.documentId === document.id
    );
    if (task) {
      await this.inbox.updateUserTask({
        ...task.dto,
        outcome
      });
    } else {
      const context: NoActionUserActionContext = {
        userTask: {
          instructionCode: Instructions.NoAction,
          status:
            outcome === Outcome.AttemptedContact
              ? UserTaskStatus.InProgress
              : UserTaskStatus.Completed,
          outcome
        } as UserTaskDto,
        patientKey: {
          patientId: this.clinicalRecord.id,
          documentId: document.id
        }
      };

      const userAction: AddUserAction = {
        userActionType: UserActionType.NoAction,
        context
      };

      await this.inbox.addUserAction(userAction);
    }

    await this.clinicalRecord.loadPatientUserTasks();
  };

  updateCorrespondenceConfidentiality = async (item: ClinicalDocument) => {
    if (this.clinicalRecord.openEncounter?.id) {
      const document = {
        ...item.dto,
        secGroupId: !item.secGroupId
          ? this.core.user?.privateSecGroupId
          : undefined
      };

      await this.correspondence.updateCorrespondence(
        this.clinicalRecord.openEncounter.id,
        document.id,
        document
      );
    }
  };
  navigateToDocumentWriter = (documentTab: DocumentWriterTab) => {
    this.correspondence.activeDocumentTab = documentTab;
  };

  getMetaValue = (key: string, metaData: DocumentMetadataItem[]) => {
    return metaData.find(x => x.key === key)?.value;
  };

  duplicateOriginalMetaData = (
    originalMetaData: DocumentMetadataItem[],
    originalDocId: string
  ) => {
    const name = this.getMetaValue(Name, originalMetaData);
    const extensionType = this.getMetaValue(Extension, originalMetaData);

    const duplicatedMetaData: Record<string, string | undefined> = {};

    originalMetaData
      .filter(x => x.key !== ContentType)
      .forEach(x => {
        duplicatedMetaData[x.key] = x.value;
      });

    duplicatedMetaData[Name] = `${name} - Duplicate`;
    duplicatedMetaData[OriginatingDocumentId] = originalDocId;
    duplicatedMetaData[DocumentEnum.Date] = DateTime.now().toISODate();

    duplicatedMetaData[Extension] = extensionType ?? DocumentExtensionType.Docx;
    const metadata: DocumentMetadataItem[] = getNewMetadata(
      [],
      duplicatedMetaData
    );

    return metadata;
  };

  onDuplicateDocument = async (record: ClinicalDocument) => {
    const { id: encounterId } = this.clinicalRecord.openEncounter || {};
    if (!encounterId) throw new Error("encounterId not set");

    const originalMetaData = record.dto.metadata;

    const metadata = this.duplicateOriginalMetaData(
      originalMetaData,
      record.dto.id
    );

    const content = await this.correspondence.getDocumentContent(
      record.patientId,
      record.id,
      DocumentContentType.Sfdt
    );

    const duplicatedDto: DocumentDto = {
      ...record.dto,
      id: newGuid(),
      metadata,
      eTag: "",
      status: CorrespondenceStatus.Draft,
      content: content.content
    };

    this.correspondence.mergeCorrespondence(duplicatedDto);

    this.navigateToDocumentWriter({
      documentId: duplicatedDto.id,
      patientId: duplicatedDto.patientId,
      encounterId
    });
  };

  onMoveToAnotherPatientSucceeded = (values: MoveCorrespondenceFormValues) => {
    // setTimeout is a temporary solution as an immediate request to the API doesn't
    //  appear to update straight away
    setTimeout(
      action(() => {
        this.correspondence.correspondenceListRefreshKey = `${values.sourceFileId}-moved`;
      }),
      2000
    );
  };

  onMoveToAnotherPatient = async (item: ClinicalDocument) => {
    this.setSelectedClinicalDocument(item);
    this.setMoveDialogVisible(true);
  };
}
