import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Heading, Stack, Tile } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { AddWaitingListItemButton } from "./components/AddWaitingListItemButton.tsx";
import { WaitingListItemDialog } from "./components/waiting-list-item-form-dialog/WaitingListItemDialog.tsx";
import { WaitingListDetailFilter } from "./components/WaitingListDetailFilter.tsx";
import { WaitingListDetails } from "./components/WaitingListDetails.tsx";
import {
  useWaitingListScreenContext,
  WaitingListScreenContext
} from "./context/WaitingListScreenContext.tsx";
import { WaitingListScreenHelper } from "./context/WaitingListScreenHelper.ts";

const WaitingListScreenBase: FunctionComponent = observer(() => {
  const TITLE = "Add to waiting list";

  const { closeDialog, setCloseDialog, itemToEdit } =
    useWaitingListScreenContext();

  return (
    <Tile styles={{ root: { flexGrow: 1 } }}>
      <Stack verticalFill tokens={{ childrenGap: 10 }}>
        <Stack
          horizontal
          tokens={{ childrenGap: 24 }}
          styles={{ root: { paddingTop: 16, paddingBottom: 16 } }}
        >
          <Heading variant="section-heading-light">Waiting list</Heading>
          <AddWaitingListItemButton />
        </Stack>

        <Stack
          grow
          styles={{
            root: {
              height: 0
            }
          }}
        >
          <WaitingListDetailFilter>
            {() => <WaitingListDetails />}
          </WaitingListDetailFilter>
        </Stack>

        {!closeDialog && (
          <WaitingListItemDialog
            title={itemToEdit ? "Edit waiting list" : TITLE}
            closeDialog={setCloseDialog}
            item={itemToEdit}
          />
        )}
      </Stack>
    </Tile>
  );
});

export const WaitingListScreen = observer(() => {
  const { booking } = useStores();
  const helper = new WaitingListScreenHelper(booking);

  return (
    <WaitingListScreenContext.Provider value={helper}>
      <WaitingListScreenBase />
    </WaitingListScreenContext.Provider>
  );
});
