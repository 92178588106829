import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useFormState } from "react-final-form";

import {
  CheckboxGroup,
  DefaultButton,
  Heading,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { BodyArea } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BodyExaminationFormValues } from "../BodyExaminationForm.types.ts";
import { useBodyExaminationContext } from "../context/BodyExaminationContext.ts";

const DiagramPickerCardComponent: FunctionComponent = observer(() => {
  const { clinical } = useStores();
  const { values } = useFormState<BodyExaminationFormValues>();
  const {
    setShowDiagramEditorArea,
    bodyParts,
    setBodyParts,
    setImages,
    setSelectedBodyParts
  } = useBodyExaminationContext();

  const { clinicalRecord } = usePatientRecordScreenContext();
  const theme = useTheme();
  const optionsTextValue = clinical.ref.bodyAreas.keyTextValues.map(o => {
    return { label: o.text, value: o.key };
  });
  return (
    <Stack
      styles={{
        root: {
          background: theme.palette.neutralLighterAlt,
          width: "inherit",
          height: "inherit"
        }
      }}
    >
      <Stack
        tokens={{ childrenGap: 28 }}
        styles={{
          root: {
            margin: "auto",
            background: theme.palette.neutralLighterAlt
          }
        }}
      >
        <Heading variant="section-sub-heading">
          Select diagrams to display
        </Heading>
        <CheckboxGroup
          options={optionsTextValue}
          horizontal
          wrap
          checkboxWidth={100}
          styles={{
            root: { width: 250 },
            itemsWrapper: {
              display: "flex",
              justifyContent: "space-between"
            }
          }}
          onChange={(bodyParts: BodyArea[]) => {
            setSelectedBodyParts(bodyParts);
            setBodyParts(bodyParts);
            if (values.imageValue) {
              setImages(values.imageValue, clinicalRecord.patient?.sex);
            }
          }}
          value={bodyParts.map(part => part)}
        />
        <DefaultButton
          text="Show"
          styles={{ root: { width: 100 } }}
          disabled={bodyParts?.length === 0}
          onClick={() => {
            setShowDiagramEditorArea(true);
          }}
        />
      </Stack>
    </Stack>
  );
});

export const DiagramPickerCard = withFetch(
  x => [x.clinical.ref.bodyAreas.load()],
  DiagramPickerCardComponent
);
