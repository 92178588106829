import { FunctionComponent, useCallback, useState } from "react";

import { ButtonsGroupChoice, Heading, Stack } from "@bps/fluent-ui";
import { GetStagingInfoDto } from "@libs/api/dtos/index.ts";
import {
  DocumentDestinationType,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { InboxDocumentDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ClinicalImageFormFields } from "../clinical-form/ClinicalImageFormFields.tsx";
import { CorrespondenceForm } from "../clinical-form/CorrespondenceForm.tsx";
import {
  ClinicalImageFormValues,
  CorrespondenceFormValue
} from "../clinical-form/DocumentBatchFormValues.ts";
import { ClinicalImageDialogHelper } from "../clinical-images/ClinicalImageDialogHelper.tsx";
import { ClinicalImagesDialogValidator } from "../clinical-images/ClinicalImagesDialogValidator.tsx";
import { CorrespondenceDialogHelper } from "../correspondence/CorrespondenceDialogHelper.ts";
import { CorrespondenceFormValidator } from "../correspondence/CorrespondenceFormValidator.ts";
import { AddEditInvestigationDialogFields } from "../investigations/AddEditInvestigationDialogFields.tsx";
import { AddEditInvestigationDialogHelper } from "../investigations/AddEditInvestigationDialogHelper.ts";
import { InvestigationFormValues } from "../investigations/AddInvestigationDialog.types.ts";
import { AddInvestigationDialogValidator } from "../investigations/AddInvestigationDialogValidator.tsx";
import { EditInboxDocumentDialogHelper } from "./EditInboxDocumentDialogHelper.tsx";
import { EditPAHFormValues } from "./helpers/PatientSummaryHelper.ts";

interface EditInboxDocumentDialogProps {
  selectedDocument?: ClinicalDocument;
  selectedInboxDocument?: InboxDocumentDto;
  clinicalRecord: ClinicalRecord;
  onDismiss: () => void;
}

export const EditInboxDocumentDialog: FunctionComponent<
  EditInboxDocumentDialogProps
> = ({
  selectedDocument,
  onDismiss,
  selectedInboxDocument,
  clinicalRecord
}) => {
  const root = useStores();

  const [storedIn, setStoredIn] = useState<StoreType>(StoreType.Investigations);

  const handleStoredInChange = (selectedValue: StoreType | undefined) => {
    if (selectedValue) {
      setStoredIn(selectedValue);
    } else {
      setStoredIn(StoreType.Investigations); // Reset to default value
    }
  };

  const editInboxDocumentDialogHelper = new EditInboxDocumentDialogHelper(
    clinicalRecord,
    root
  );

  const getInitialValues = (): EditPAHFormValues => {
    const investigationHelper = new AddEditInvestigationDialogHelper(
      clinicalRecord,
      root,
      { editInvestigation: selectedDocument }
    );
    switch (storedIn) {
      case StoreType.Investigations:
        return investigationHelper.getInitialValues();
      case StoreType.ClinicalImages:
        const clinicalImageHelper = new ClinicalImageDialogHelper(
          clinicalRecord,
          root
        );
        return clinicalImageHelper.getInitialValues(selectedDocument);
      case StoreType.Correspondence:
        const correspondenceHelper = new CorrespondenceDialogHelper(
          clinicalRecord,
          root
        );
        return correspondenceHelper.getInitialValues(selectedDocument);
      default:
        return investigationHelper.getInitialValues();
    }
  };

  const initialValues = getInitialValues();

  const validate = useCallback(
    (values: EditPAHFormValues): Record<string, any> => {
      switch (storedIn) {
        case StoreType.Investigations:
          return new AddInvestigationDialogValidator(selectedDocument).validate(
            values as InvestigationFormValues
          );
        case StoreType.ClinicalImages:
          return new ClinicalImagesDialogValidator().validate(
            values as ClinicalImageFormValues
          );
        case StoreType.Correspondence:
          return new CorrespondenceFormValidator(!!selectedDocument).validate(
            values as CorrespondenceFormValue
          );
        default:
          return new AddInvestigationDialogValidator(selectedDocument).validate(
            values as InvestigationFormValues
          );
      }
    },
    [storedIn, selectedDocument]
  );

  const handleSubmitAction = async (formValue: EditPAHFormValues) => {
    editInboxDocumentDialogHelper.handleSubmitAction(
      storedIn,
      formValue,
      selectedInboxDocument!
    );
  };

  return (
    <DataFetcher<GetStagingInfoDto>
      fetch={async root => {
        const stagingPath = await root.clinical.getStagingInfo();
        return stagingPath;
      }}
    >
      {stagingPath => (
        <SubmissionFormDialog<EditPAHFormValues>
          dialogName="Edit Inbox"
          dialogProps={{
            onDismiss,
            modalProps: {
              layerProps: {
                eventBubblingEnabled: true
              }
            },
            minWidth: 600,
            dialogContentProps: {
              title: (
                <Heading variant="modal-heading">
                  {selectedInboxDocument?.name}
                </Heading>
              ),
              showCloseButton: true
            }
          }}
          onSubmit={handleSubmitAction}
          validate={validate}
          initialValues={initialValues}
          onSubmitSucceeded={onDismiss}
          buttonsProps={() => {
            return {
              disableSubmitOnPristine: true,
              disableSubmitOnFormInvalid: true
            };
          }}
          render={() => (
            <Stack>
              <Heading variant="section-sub-heading">Store in</Heading>
              <ButtonsGroupChoice
                name="Store in"
                options={[
                  {
                    key: StoreType.Correspondence,
                    text: DocumentDestinationType.Correspondence
                  },
                  {
                    key: StoreType.Investigations,
                    text: DocumentDestinationType.Investigations
                  },
                  {
                    key: StoreType.ClinicalImages,
                    text: DocumentDestinationType.ClinicalImages
                  }
                ]}
                value={storedIn}
                onChange={handleStoredInChange}
              />

              {storedIn === StoreType.Investigations && (
                <Stack>
                  <AddEditInvestigationDialogFields
                    stagingPath={stagingPath}
                    editInvestigation={selectedDocument}
                  />
                </Stack>
              )}

              {storedIn === StoreType.Correspondence && (
                <Stack>
                  <CorrespondenceForm
                    isEdit={!!selectedDocument}
                    isEditInbox={true}
                  />
                </Stack>
              )}

              {storedIn === StoreType.ClinicalImages && (
                <Stack>
                  <ClinicalImageFormFields
                    isEdit={!!selectedDocument}
                    clinicalImage={selectedDocument}
                  />
                </Stack>
              )}
            </Stack>
          )}
        />
      )}
    </DataFetcher>
  );
};
