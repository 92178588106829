import { DateTime } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isDateAfterDate,
  maxLength,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CurrentStatusAndPrognosisFormValues } from "@shared-types/acc/current-status-prognosis-values.type.ts";

export class CurrentStatusAndPrognosisFormValidator extends Validator<CurrentStatusAndPrognosisFormValues> {
  constructor() {
    super();

    const dateLessThanOneWeek = DateTime.now().minus({ days: 7 });

    this.forField(nameof("originalAccidentEvent"), [
      required(),
      maxLength(2000)
    ]);
    this.forField(nameof("currentConditionOrDiagnosis"), [
      required(),
      maxLength(2000)
    ]);
    this.forField(nameof("causeOfCurrentCondition"), [
      required(),
      maxLength(2000)
    ]);
    this.forField(nameof("causeOfNotResolvedCondition"), [
      required(),
      maxLength(2000)
    ]);
    this.forField(nameof("signAndSymptom"), [required(), maxLength(2000)]);
    this.forField(nameof("proposedTreatment"), [required(), maxLength(2000)]);
    this.forField(nameof("expectedTreatmentCompletionDate"), [
      required(),
      isDateAfterDate(
        dateLessThanOneWeek,
        "This date is more than a week ago. Please enter a valid date"
      )
    ]);
    this.forField(nameof("managementPlan"), [required(), maxLength(2000)]);
  }
}
