import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { StatementModalFormValues } from "./StatementModalForm.types.ts";

export class StatementModalValidator extends Validator<StatementModalFormValues> {
  constructor() {
    super();
    this.forField("accountContactId", [required()]);
    this.forField("startDate", [required()]);
    this.forField("endDate", [required()]);
  }
}
