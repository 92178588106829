import { FunctionComponent } from "react";

import { ActionButton, confirm, Stack, Text } from "@bps/fluent-ui";
import { Length } from "@libs/validation/fieldValidators.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { AllMutators } from "@ui-components/form/customMutators.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { useBodyExaminationContext } from "../context/BodyExaminationContext.ts";
import { getNameOf } from "../utils.ts";

interface ExaminationCommentsProps {
  isDermAndMyotomes?: boolean;
  isCentralNervousSystem?: boolean;
  mutators: AllMutators;
}

export const ExaminationComments: FunctionComponent<
  ExaminationCommentsProps
> = ({ isDermAndMyotomes, mutators, isCentralNervousSystem }) => {
  const { currentBodyArea } = useBodyExaminationContext();

  if (!currentBodyArea) return null;

  const nameOf = getNameOf(isDermAndMyotomes, isCentralNervousSystem);

  return (
    <FieldArray name={`${nameOf("examinationComments")}[${currentBodyArea}]`}>
      {({ fields }) => {
        return (
          <Stack tokens={{ childrenGap: 8 }}>
            {fields.map((name, index) => (
              <Stack key={name} tokens={{ childrenGap: 8 }}>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 8 }}
                >
                  <Text styles={{ root: { fontWeight: 600 } }}>Title</Text>
                  <TextInputField
                    name={`${name}.title`}
                    placeholder="Comment title"
                  />
                  <DeleteButton
                    onClick={async () => {
                      if (
                        fields.value[index].title ||
                        fields.value[index].comment
                      ) {
                        const result = await confirm({
                          cancelButtonProps: {
                            text: "Back to editing"
                          },
                          confirmButtonProps: {
                            text: "Discard"
                          },
                          dialogContentProps: {
                            title: "Discard the comment?",
                            subText:
                              "Are you sure you want to discard this comment?"
                          }
                        });
                        if (result) {
                          mutators.remove(
                            `${nameOf(
                              "examinationComments"
                            )}[${currentBodyArea}]`,
                            index
                          );
                        }
                      } else {
                        mutators.remove(
                          `${nameOf(
                            "examinationComments"
                          )}[${currentBodyArea}]`,
                          index
                        );
                      }
                    }}
                    disabled={fields.length === 1}
                  />
                </Stack>
                <TextInputField
                  name={`${name}.comment`}
                  className="examinationComment"
                  placeholder="Comment"
                  multiline
                  maxLength={Length.comments}
                  resizable={false}
                  styles={{
                    fieldGroup: { marginTop: 0, height: "100%" },
                    description: { padding: 0 },
                    wrapper: { height: "inherit" },
                    root: {
                      height: "inherit",
                      maxHeight: 583
                    }
                  }}
                />
              </Stack>
            ))}
            <ActionButton
              iconProps={{ iconName: "Add" }}
              title="Add more"
              onClick={() => {
                mutators.push(
                  `${nameOf("examinationComments")}[${currentBodyArea}]`,
                  { title: undefined, comment: undefined }
                );
              }}
            >
              Add another
            </ActionButton>
          </Stack>
        );
      }}
    </FieldArray>
  );
};
