import { ClaimAddressDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  maxLength,
  minLength,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

export class ClaimAddressValidator extends Validator<ClaimAddressDto> {
  constructor() {
    super();

    this.forField("street1", [required(), maxLength(35)]);

    this.forField("street2", [maxLength(35)]);

    this.forField("suburb", [maxLength(60)]);

    this.forField("postCode", [required(), minLength(4), maxLength(10)]);

    this.forField("city", [required(), maxLength(60)]);

    this.forField("type", required());

    this.forField("country", required());
  }
}
