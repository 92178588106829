import { FontIcon, Text, TooltipHost, useTheme } from "@bps/fluent-ui";
import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { getStatusesItem } from "@stores/billing/utils/invoice.utils.ts";

interface Props {
  invoice?: Invoice;
  invoiceItem?: InvoiceItemDto;
}

export const InvoiceStatusTooltip: React.FC<Props> = ({
  invoice,
  invoiceItem
}) => {
  const theme = useTheme();
  const statuses = invoiceItem
    ? getStatusesItem(invoiceItem)
    : invoice?.allocationStatuses;

  if (!statuses?.length || statuses.length < 2) {
    return null;
  }

  return (
    <TooltipHost content={<Text>{statuses.map(x => x).join(", ")}</Text>}>
      <FontIcon
        iconName="info"
        styles={{
          root: {
            fontSize: 16,
            display: "block",
            color: theme.palette.themePrimary,
            cursor: "pointer"
          }
        }}
      />
    </TooltipHost>
  );
};
