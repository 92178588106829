import React from "react";

import {
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import { AppointmentVisitDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getConditionsButtonStyles } from "./ConditionsSidePanel.styles.ts";

interface ConsultsRemainingBadgesProps {
  appointmentVisits: AppointmentVisitDto[];
}

export const ConsultsRemainingBadgesBase: React.FC<
  ConsultsRemainingBadgesProps
> = ({ appointmentVisits }) => {
  const { practice, userExperience } = useStores();
  const theme = useTheme();

  const styles = getConditionsButtonStyles(theme);

  const getConsultsRemainingText = (
    appointmentVisitDto: AppointmentVisitDto,
    withProviderInfo: boolean
  ): string => {
    if (!appointmentVisitDto.contractType) {
      return "";
    }

    let businessRoleAbbreviation: string | undefined;

    if (appointmentVisitDto.providerType) {
      const accProviderContractType =
        practice.ref.accProviderContractTypes.values.find(
          x =>
            appointmentVisitDto.contractType ===
              x.accPracticeContractTypeCode &&
            appointmentVisitDto.providerType === x.providerTypeCode
        );

      businessRoleAbbreviation =
        userExperience.ref.businessRoleAbbreviations.values.find(
          x => x.businessRoleCode === accProviderContractType?.businessRoleCode
        )?.text;
    }

    const visitsRemaining = isDefined(appointmentVisitDto.totalRemaining)
      ? `${appointmentVisitDto.totalRemaining} consults remaining`
      : "No consults remaining";

    const providerInfo = businessRoleAbbreviation
      ? `(${appointmentVisitDto.contractType} ${businessRoleAbbreviation})`
      : appointmentVisitDto.contractType;

    if (!withProviderInfo) return visitsRemaining;
    return `${visitsRemaining} ${providerInfo}`;
  };

  return (
    <React.Fragment>
      {appointmentVisits.map(av => {
        const text = getConsultsRemainingText(av, appointmentVisits.length > 1);
        return (
          <TextBadge
            badgeColor={TextBadgeColor.lightGrey}
            styles={styles.visitsRemaining}
            badgeSize={TextBadgeSize.medium}
            key={text}
          >
            {text}
          </TextBadge>
        );
      })}
    </React.Fragment>
  );
};

export const ConsultsRemainingBadges = withFetch(
  x => x.practice.ref.accProviderContractTypes.load(),
  ConsultsRemainingBadgesBase
);
