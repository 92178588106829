import { ITheme, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

/**
 * Temporary hook to override some theme properties used in clinical area.
 * ⚠️ Should be removed later. Ilya S.
 */
export const useNewClinicalTheme = () => {
  const theme = useTheme();
  const { core } = useStores();
  const isNewDesign = core.hasPermissions(Permission.UIRedesignAllowed);

  const updatedTheme: Pick<ITheme, "spacing"> = {
    spacing: {
      ...theme.spacing,
      s1: isNewDesign ? "8px" : "16px",
      s2: isNewDesign ? "8px 16px" : "16px"
    }
  };

  return updatedTheme;
};
