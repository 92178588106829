import {
  CompoundButton,
  FontIcon,
  FontWeights,
  IButtonProps,
  mergeStyles,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

type CompoundButtonWithHoveredIconProps = IButtonProps & {
  contactType?: ContactType;
  tooltipText?: string;
  iconName?: string;
};

export const CompoundButtonWithHoveredIcon = ({
  iconName = "Share",
  tooltipText,
  contactType,
  ...compoundButtonprops
}: CompoundButtonWithHoveredIconProps) => {
  const { userExperience } = useStores();

  const theme = useTheme();

  const { localisedConfig } = userExperience;
  const patientLabel = localisedConfig("patientDisplay", {
    capitalizeFirst: true
  });

  let tooltipHostContent: string;
  if (tooltipText) {
    tooltipHostContent = tooltipText;
  } else if (contactType === ContactType.Patient) {
    tooltipHostContent = `${patientLabel} record`;
  } else {
    tooltipHostContent = "Contact card";
  }

  return (
    <CompoundButton
      {...compoundButtonprops}
      styles={{
        root: { maxWidth: "100%", padding: 0 },
        flexContainer: { display: "block" }
      }}
      onClick={compoundButtonprops.onClick}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{
          root: {
            padding: 10,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: FontWeights.regular,
            fontSize: theme.fonts.mediumPlus.fontSize,
            selectors: {
              "&:hover .ms-Icon": {
                visibility: "visible"
              }
            }
          }
        }}
      >
        {compoundButtonprops.children}
        <Stack
          verticalAlign="center"
          styles={{
            root: {
              visibility: "hidden",
              fontSize: theme.fonts.mediumPlus.fontSize
            }
          }}
        >
          <TooltipHost content={tooltipHostContent}>
            <FontIcon
              iconName={iconName}
              className={mergeStyles({
                fontSize: theme.fonts.medium.fontSize,
                color: theme.palette.neutralPrimary
              })}
            />
          </TooltipHost>
        </Stack>
      </Stack>
    </CompoundButton>
  );
};
