import { memo } from "react";
import { Field, useForm } from "react-final-form";

import { dataAttribute, DataAttributes, MessageBar } from "@bps/fluent-ui";
import {
  ContactType,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { RelationshipStaticTagPicker } from "@modules/practice/screens/contact-details/shared-components/edit/relationships/RelationshipStaticTagPicker.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsChoiceGroupField } from "@ui-components/form/ButtonsGroupChoiceField.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";

import { ContactPickerFieldWithAdd } from "../../../../shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { Labels } from "../../../../shared-components/types/labels.enums.types.ts";
import { familyRelationships } from "../../../constants/family-relationships.constant.ts";
import { RelationshipTypeDescription } from "./RelationshipTypeDescription.tsx";
import { RelationshipFieldValue } from "./utils.ts";

export type RelationshipFieldsContactProps = {
  name: string;
  onRemove: () => void;
  autoFocus?: boolean;
  fullName: string | undefined;
};

const RelationshipFieldsContactBase: React.FunctionComponent<RelationshipFieldsContactProps> =
  memo(({ fullName, name, onRemove }) => {
    const {
      practice: { ref }
    } = useStores();

    const fieldName = `${name}.relatedContactId`;

    const { change } = useForm();

    return (
      <Field<RelationshipFieldValue> name={name} subscription={{ value: true }}>
        {({
          input: {
            value: {
              id,
              relatedContactId,
              family,
              other,
              professional,
              special,
              account,
              isNew
            }
          }
        }) => {
          const relationships: RelationshipType[] = [
            ...account,
            ...special,
            ...professional,
            ...family,
            ...other
          ];
          return (
            <>
              <ContactPickerFieldWithAdd
                autoFocus={isNew}
                label={Labels.relatedPerson}
                actionButton={{
                  linkProps: {
                    children: "Remove",
                    onClick: onRemove
                  }
                }}
                includeInactive
                includeDeceased
                name={fieldName}
                filter={{
                  types: [ContactType.Individual, ContactType.Patient]
                }}
                onChanged={relatedContactId => {
                  // if contact is clear, also clear the existing relationships
                  // we do not use FieldSpy since when we remove a relationship
                  // this hook reacts on this action like relatedContactId has been removed
                  // it is more reliable to use a callback onChanged
                  // but for most other cases it is fine to use the hook.
                  if (!relatedContactId) {
                    change(name, {
                      id,
                      relatedContactId,
                      family: [],
                      special: [],
                      professional: [],
                      other: [],
                      account: []
                    });
                  }
                }}
              />
              {relatedContactId && (
                <MessageBar
                  {...dataAttribute(
                    DataAttributes.Element,
                    "related-contact-message-bar"
                  )}
                  styles={({ theme }) => ({
                    root: {
                      backgroundColor: theme.palette.themeLighter
                    }
                  })}
                >
                  <ContactFetcher contactId={relatedContactId}>
                    {contact => contact.name}
                  </ContactFetcher>
                  &nbsp;
                  <span style={{ textTransform: "lowercase" }}>
                    <RelationshipTypeDescription
                      codes={
                        relationships.length > 0
                          ? relationships
                          : [RelationshipType.General]
                      }
                    />
                  </span>
                  &nbsp;
                  {fullName}
                </MessageBar>
              )}

              <>
                {relatedContactId && (
                  <>
                    <ButtonsChoiceGroupField
                      label="Special relationships"
                      name={`${name}.special`}
                      options={[
                        { key: "EC", text: "Emergency" },
                        { key: "NK", text: "Next of kin" },
                        { key: "PA", text: "Enduring power of attorney" }
                      ]}
                    />
                    <StaticTagPickerField
                      itemLimit={1}
                      fetchDataSource={() =>
                        ref.relationshipTypes.keyNameValues.filter((x: any) =>
                          familyRelationships.includes(x.key)
                        )
                      }
                      label="Is family"
                      name={`${name}.family`}
                      styles={{
                        text: { flexGrow: 0 }
                      }}
                    />
                    <RelationshipStaticTagPicker
                      label="Has a legal or professional relationship"
                      excludeSelectedItems={true}
                      styles={{
                        text: { flexGrow: 0 }
                      }}
                      name={`${name}.professional`}
                    />
                  </>
                )}
              </>
            </>
          );
        }}
      </Field>
    );
  });

export const RelationshipFieldsContact = withFetch(
  x => x.practice.ref.relationshipStatuses.load(),
  RelationshipFieldsContactBase
);
