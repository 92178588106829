import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  maxLength,
  phone,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { isValidHpiOrganisationId } from "@modules/settings/screens/shared-components/utils.ts";
import { ProviderDetailsFormValues } from "@shared-types/acc/provider-details-values.type.ts";

import { checkIfProviderCanSubmit } from "../components/utils.ts";

export class ProviderDetailsFormValidator extends Validator<ProviderDetailsFormValues> {
  constructor() {
    super();
    this.forField(nameof("providerContactNumber"), [
      phone,
      maxLength(20),
      required()
    ]);
    this.forField(nameof("hpiOrganisationNumber"), [
      maxLength(8),
      isValidHpiOrganisationId,
      required()
    ]);
    this.forField(nameof("providerContractType"), required());
    this.forField(nameof("providerContractType"), [checkIfProviderCanSubmit()]);
  }
}
