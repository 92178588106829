import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";

import { compareDatesPredicate } from "@bps/utils";
import {
  CalendarEventStatus,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useInfiniteScroll } from "@ui-components/InfiniteScrollList/useInfiniteScroll.ts";

import { AppointmentInformationTable } from "./AppointmentInformationTable.tsx";

type AppointmentInformationModalInnerProps = {
  contactId: string;
  selectedId?: string;
  onDismissInfoModal: () => void;
};
export const AppointmentInformationModalInner: React.FC<AppointmentInformationModalInnerProps> =
  observer(({ contactId, selectedId, onDismissInfoModal }) => {
    const { booking } = useStores();

    const refreshKey = useMemo(() => {
      const calendarEventId = booking.ui.lastUpdatedCalendarEventData?.id;
      if (calendarEventId) {
        const calendarEvent = booking.calendarEventsMap.get(calendarEventId);
        if (
          calendarEvent?.attendees.some(
            attendee => attendee.attendeeId === contactId
          )
        ) {
          return calendarEvent.eTag;
        }
      }

      return "";
    }, [
      booking.calendarEventsMap,
      booking.ui.lastUpdatedCalendarEventData?.id,
      contactId
    ]);

    const getItems = useCallback(async () => {
      const result = await booking.getCalendarEvents({
        statuses: [
          CalendarEventStatus.Confirmed,
          CalendarEventStatus.Cancelled
        ],
        attendees: [contactId]
      });

      return {
        skip: 0,
        take: result.results.length,
        results: result.results
          .filter(
            calendarEvent =>
              calendarEvent.type !== CalendarEventType.TemporaryReservation
          )
          .sort((a, b) =>
            compareDatesPredicate(a.startDateTime, b.startDateTime, true)
          )
      };
    }, [booking, contactId]);

    const { searchResults, isLoading } = useInfiniteScroll({
      getItems,
      refreshKey
    });

    return (
      <AppointmentInformationTable
        selectedId={selectedId}
        onDismiss={onDismissInfoModal}
        items={searchResults.value?.results ?? []}
        loading={isLoading}
      />
    );
  });
