import { FC, useState } from "react";
import { useForm } from "react-final-form";

import { ActionButton, Heading } from "@bps/fluent-ui";
import { AllocationFormButtons } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { ManualAllocationFormValidator } from "@modules/billing/screens/validators/ManualAllocationFormValidator.ts";
import { maxZero } from "@stores/billing/utils/billing.utils.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { useAllocationFormContext } from "../../allocation-form/context/AllocationFormContext.ts";
import { useAllocationField } from "../hooks/useAllocationField.ts";
import { ManualAllocationFormValues } from "./ManualAllocationForm.types.tsx";
import { ManualAllocationFormLayout } from "./ManualAllocationFormLayout.tsx";
import { UnallocatedAmountWarning } from "./UnallocatedAmountWarning.tsx";

interface ManualAllocationModalProps {
  onDismiss: () => void;
}

export const ManualAllocationModalBase: FC<ManualAllocationModalProps> = ({
  onDismiss
}) => {
  const { allocatableAmount, allocationOption, allocations } =
    useAllocationField();

  const form = useForm();

  const onHandleSubmit = (values: ManualAllocationFormValues) => {
    form.change(
      allocationNameOf("allocations"),
      values.allocations.map(a => ({
        ...a,
        checked: !!a.total,
        total: a.total || 0
      }))
    );
  };

  const initialValues: ManualAllocationFormValues = {
    allocations: allocations.map(a => ({
      ...a
    })),
    allocatableAmount
  };

  return (
    <SubmissionFormDialog<ManualAllocationFormValues>
      dialogName="Manual allocation"
      onSubmit={onHandleSubmit}
      onSubmitSucceeded={onDismiss}
      initialValues={initialValues}
      validate={values =>
        new ManualAllocationFormValidator(
          allocationOption?.itemMaxTotalKey
        ).validate(values)
      }
      buttonsProps={({ getState }) => {
        const { pristine } = getState();
        return {
          submitButtonProps: {
            text: AllocationFormButtons.saveAllocation,
            iconProps: {}
          },
          cancelButtonProps: {
            text: `${pristine ? "Close" : "Cancel"}`
          }
        };
      }}
      warning={({ getState }) => {
        const allocated = getState().values.allocatableAmount;
        const unallocated = maxZero(allocatableAmount - allocated);
        return unallocated && getState().pristine ? (
          <UnallocatedAmountWarning
            allocated={getState().values.allocatableAmount || 0}
            unallocated={unallocated}
            noWrapper
          />
        ) : undefined;
      }}
      dialogProps={{
        onDismiss,
        minWidth: 600,
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">Manually allocate payment</Heading>
          )
        }
      }}
      component={() => (
        <ManualAllocationFormLayout allocatabaleAmount={allocatableAmount} />
      )}
    />
  );
};

export const ManualAllocationModal = () => {
  const [showAllocationModal, setShowAllocationModal] = useState(false);
  const { allocatableAmount } = useAllocationField();

  const { hideManuallyAllocate } = useAllocationFormContext();

  const showModalLink = allocatableAmount > 0;

  if (!showModalLink || hideManuallyAllocate) {
    return null;
  }

  return (
    <div>
      <ActionButton
        iconProps={{ iconName: "ChangeEntitlements" }}
        onClick={() => {
          setShowAllocationModal(true);
        }}
        text="Manually allocate"
      />
      {showAllocationModal && (
        <ManualAllocationModalBase
          onDismiss={() => setShowAllocationModal(false)}
        />
      )}
    </div>
  );
};
