import {
  FieldItemStyles,
  IDropdownStyles,
  ISpinButtonStyles,
  IStackStyles,
  ITheme,
  IToggleStyles
} from "@bps/fluent-ui";

export const getBhbOnlineSettingsFormStyles = (theme: ITheme) => {
  const separatorStyles: IStackStyles = {
    root: {
      border: `1px solid ${theme.palette.neutralLight}`,
      padding: 8
    }
  };

  const inlineLabelStyles: Partial<FieldItemStyles> = {
    wrapper: { display: "flex" },
    headerWrapper: { marginRight: 8 },
    itemWrapper: { marginRight: 8 }
  };

  const reverseInlineLabelStyles: Partial<FieldItemStyles> = {
    wrapper: { display: "flex", flexDirection: "row-reverse" },
    itemWrapper: { marginRight: 8 }
  };

  const spinNumberStyles: Partial<ISpinButtonStyles> = {
    root: { width: 80 }
  };

  const dropdownStyles: Partial<IDropdownStyles> = {
    root: { width: 80 }
  };

  const toggleStyles: Partial<IToggleStyles> = {
    root: { margin: 0 }
  };

  return {
    separatorStyles,
    inlineLabelStyles,
    reverseInlineLabelStyles,
    spinNumberStyles,
    dropdownStyles,
    toggleStyles
  };
};
