import { useForm } from "react-final-form";

import { Card, LabeledTextGroupProps, Stack } from "@bps/fluent-ui";
import { getPatientDetailsViewValues } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  LabeledTextFields,
  PatientDetailsViewValues
} from "../types/claim.types.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";
import { ViewField } from "./ViewField.tsx";

export const descProps: Omit<LabeledTextGroupProps, "children"> = {
  gap: 32,
  styles: { root: { justifyContent: "start" } }
};

const PersonalDetailsViewSectionBase: React.FC<{
  patientDetailsViewValues: PatientDetailsViewValues;
}> = ({ patientDetailsViewValues }) => {
  const fields: LabeledTextFields[] = [
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.name,
          children: patientDetailsViewValues.patientFullName
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.dateOfBirth,
          children: patientDetailsViewValues.patientDateOfBirthFormatted
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.sex,
          children: patientDetailsViewValues.patientSexText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.nhi,
          children: patientDetailsViewValues.patientNhiNumber
        }
      ]
    },
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.patientMobileNumber,
          children: patientDetailsViewValues.patientMobileNumber
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.patientHomePhoneNumber,
          children: patientDetailsViewValues.patientHomePhoneNumber
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.patientWorkPhoneNumber,
          children: patientDetailsViewValues.patientWorkPhoneNumber
        }
      ]
    },
    {
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.patientEmail,
          children: patientDetailsViewValues.patientEmail
        }
      ]
    },
    {
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.patientEthnicityCode,
          children: patientDetailsViewValues.patientEthnicityText
        }
      ]
    },
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.address,
          children: patientDetailsViewValues.patientAddressFormatted
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.addressType,
          children: patientDetailsViewValues.patientAddressTypeText
        }
      ]
    }
  ];

  return (
    <Card
      heading={ClaimSectionHeadings.personalDetails}
      headingLevel="section-heading"
      iconName="contact"
      id={ClaimsCardIds.personalDetails}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        {fields.map(x => (
          <ViewField key={x.labeledTexts[0].label?.toString()} {...x} />
        ))}
      </Stack>
    </Card>
  );
};

const PatientDetailViewSectionDataFetcher = () => {
  const values = useForm<ClaimFormValues>().getState().values;

  return (
    <DataFetcher fetch={root => getPatientDetailsViewValues({ values, root })}>
      {(patientDetails: PatientDetailsViewValues) => (
        <PersonalDetailsViewSectionBase
          patientDetailsViewValues={patientDetails}
        />
      )}
    </DataFetcher>
  );
};

export const PersonalDetailsViewSection = withFetch(
  x => [
    x.practice.ref.sexes.load(),
    x.acc.ref.levelTwoEthnicities.load(),
    x.acc.ref.accSexes.load()
  ],
  PatientDetailViewSectionDataFetcher
);
