import { computed } from "mobx";

import {
  EncounterStartingPoints,
  NoteStatus
} from "@libs/analytics/app-insights/app-insights.enums.ts";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class AppInsightsClinicalRecordHelper {
  constructor(protected root: IRootStore) {}

  get routing() {
    return this.root.routing;
  }

  get core() {
    return this.root.core;
  }

  get clinical() {
    return this.root.clinical;
  }

  @computed
  get clinicalRecord() {
    if (this.clinical.activeRecord) {
      return this.clinical.multipleEncountersRecordsMap.get(
        this.clinical.activeRecord
      );
    }
    return undefined;
  }

  @computed
  get calendarEventId() {
    return this.routing.match(routes.records.appointment)?.params
      .calendarEventId;
  }

  private getReadableBusinessRoles() {
    const businessRoles = this.core.user?.businessRoles ?? [];

    const userRoles = businessRoles.map(
      role =>
        this.core.catalogBusinessRoles.find(
          catalogRole => catalogRole.code === role
        )?.text ?? "unknown"
    );

    return userRoles;
  }

  public trackEncounterStartedEvent = async (
    trackEvent: (eventData: Object) => void
  ) => {
    const UIOption = this.calendarEventId
      ? EncounterStartingPoints.appointmentAddEncounter
      : EncounterStartingPoints.peopleListAddEncounter;

    const calendarEvent = this.calendarEventId
      ? await this.root.booking.getCalendarEvent(this.calendarEventId)
      : undefined;

    const eocLinked = calendarEvent?.reason?.episodeOfCareId;
    const claimLinked = calendarEvent?.claimId;

    trackEvent({
      UIOption,
      UserRole: this.getReadableBusinessRoles().join(", "),
      EoCLinked: eocLinked ? "Yes" : "No",
      ClaimLinked: claimLinked ? "Yes" : "No"
    });
  };

  private trackEventEncounter = async (
    trackEvent: (eventData: Object) => void,
    completedStatus: NoteStatus
  ) => {
    const eocLinked =
      this.clinicalRecord?.calendarEvent?.reason?.episodeOfCareId;

    const claimLinked = await this.clinicalRecord?.calendarEvent?.loadClaim();

    trackEvent({
      UserRole: this.getReadableBusinessRoles().join(", "),
      EoCLinked: eocLinked ? "Yes" : "No",
      ClaimLinked: claimLinked ? "Yes" : "No",
      NoteStatus: completedStatus
    });
  };

  public trackEventEncounterCompleted = async (
    trackEvent: (eventData: Object) => void
  ) => {
    await this.trackEventEncounter(trackEvent, NoteStatus.Completed);
  };

  public trackEventEncounterUpdated = async (
    trackEvent: (eventData: Object) => void
  ) => {
    await this.trackEventEncounter(trackEvent, NoteStatus.Incompleted);
  };
}
