import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { formStyles } from "../../claim/components/ClaimForm.styles.ts";
import { AddDiagnosisDraftValidator } from "../validators/AddDiagnosisDraftValidator.tsx";
import { CurrentStatusAndPrognosisDraftValidator } from "../validators/CurrentStatusAndPrognosisDraftValidator.tsx";
import { DocumentSectionDraftValidator } from "../validators/DocumentSectionDraftValidator.tsx";
import { PatientDetailsDraftValidator } from "../validators/PatientDetailsDraftValidator.tsx";
import { ProviderDetailsDraftValidator } from "../validators/ProviderDetailsDraftValidator.tsx";
import { TreatmentDetailsDraftValidator } from "../validators/TreatmentDetailsDraftValidator.tsx";
import { ClaimAdjustmentFormEdit } from "./ClaimAdjustmentFormEdit.tsx";
import { ClaimAdjustmentFormView } from "./ClaimAdjustmentFormView.tsx";

export const CLAIM_ADJUSTMENT_FORM_ID = "claimAdjustmentForm";
interface ClaimAdjustmentFormProps {
  header: ReactNode;
  initialValues?: ClaimAdjustmentFormValues;
  onSubmitSucceeded?: (
    values: ClaimAdjustmentFormValues,
    form: FormApi<ClaimAdjustmentFormValues>
  ) => void;
  children?: (form: FormApi<ClaimAdjustmentFormValues>) => ReactNode;
}

const patientDetailsDraftValidator = new PatientDetailsDraftValidator();
const providerDetailsDraftValidator = new ProviderDetailsDraftValidator();
const addDiagnosisDraftValidator = new AddDiagnosisDraftValidator();
const treatmentDetailsDraftValidator = new TreatmentDetailsDraftValidator();
const currentStatusAndPrognosisDraftValidator =
  new CurrentStatusAndPrognosisDraftValidator();

const documentSectionDraftValidator = new DocumentSectionDraftValidator();

const ClaimAdjustmentFormBase: React.FC<ClaimAdjustmentFormProps> = observer(
  ({
    header,
    initialValues: initialValuesOverride,
    onSubmitSucceeded,
    children
  }) => {
    const context = useClaimAdjustmentContext();
    const {
      patchClaimAdjustment,
      claimAdjustment,
      getInitialValues,
      handleSubmitSucceeded,
      loadErrorMessages
    } = context;

    const initialValues = initialValuesOverride || getInitialValues();

    useEffect(() => {
      loadErrorMessages();
    }, [loadErrorMessages]);

    const { core } = useStores();

    return (
      <SubmissionForm<ClaimAdjustmentFormValues>
        formName="claim-adjustment"
        styles={formStyles}
        formId={CLAIM_ADJUSTMENT_FORM_ID}
        hideButtons
        initialValues={initialValues}
        onSubmitSucceeded={(values, form) => {
          handleSubmitSucceeded();
          onSubmitSucceeded && onSubmitSucceeded(values, form);
        }}
        onSubmit={patchClaimAdjustment}
        validate={values => {
          return {
            ...patientDetailsDraftValidator.validate(values),
            ...providerDetailsDraftValidator.validate(values),
            ...addDiagnosisDraftValidator.validate(values),
            ...treatmentDetailsDraftValidator.validate(values),
            ...currentStatusAndPrognosisDraftValidator.validate(values),
            ...documentSectionDraftValidator.validate(values)
          };
        }}
      >
        {({ form }) => {
          context.formChange = form.change;
          return (
            <>
              {core.hasPermissions(Permission.ClaimWrite) &&
              claimAdjustment.status?.allowUpdate ? (
                <ClaimAdjustmentFormEdit header={header} />
              ) : (
                <ClaimAdjustmentFormView header={header} />
              )}
              {children && children(form)}
            </>
          );
        }}
      </SubmissionForm>
    );
  }
);

export const ClaimAdjustmentForm = withFetch(
  x => [
    x.acc.ref.diagnosisSides.load(),
    x.acc.ref.claimAdjustmentStatuses.load()
  ],
  ClaimAdjustmentFormBase
);
