import {
  greaterThan,
  integer,
  lessThan,
  maxLength,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { AppointmentTypeFormValues } from "./AppointmentTypeForm.types.ts";

export class AppointmentTypeFormValidator extends Validator<AppointmentTypeFormValues> {
  constructor(groupAppsAllowed: boolean) {
    super();
    this.forField("name", [required(), maxLength(255)]);
    this.forField("duration", [
      required(),
      integer(),
      greaterThan(0),
      lessThan(1440)
    ]);

    if (groupAppsAllowed) {
      this.forField("maxParticipants", [
        required(),
        integer(),
        greaterThan(0),
        lessThan(1001)
      ]);
    }
  }
}
