import { FC } from "react";

import { FontIcon, Stack, Text, useTheme } from "@bps/fluent-ui";

import { ClinicalToolInformationPanel } from "./ClinicalToolInformationPanel.tsx";

interface ClinicalToolInformationProps {
  clinicalToolType: string;
  paragraphs: string;
}
export const ClinicalToolInformation: FC<ClinicalToolInformationProps> = ({
  clinicalToolType,
  paragraphs
}) => {
  const theme = useTheme();
  return (
    <ClinicalToolInformationPanel
      title={clinicalToolType}
      onRenderContent={() => (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <FontIcon
            iconName="info"
            styles={{
              root: {
                fontSize: 16,
                display: "block"
              }
            }}
          />
          <Stack tokens={{ childrenGap: 8 }}>
            <Text variant="small">{paragraphs}</Text>
          </Stack>
        </Stack>
      )}
      onRenderOpenTooltip={() => (
        <>Click to show information about {clinicalToolType}</>
      )}
      onRenderCloseTooltip={() => (
        <Stack horizontal tokens={{ childrenGap: 4 }} verticalAlign="center">
          <Text>Close. Click the</Text>
          <FontIcon
            iconName="info"
            styles={{
              root: {
                display: "block",
                color: theme.palette.themePrimary
              }
            }}
          />
          <Text> icon to show again</Text>
        </Stack>
      )}
    />
  );
};
