import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  Card,
  confirm,
  Image,
  Spinner,
  Stack,
  Text,
  Toggle
} from "@bps/fluent-ui";
import {
  OrgUnitCompanyDataType,
  OrgUnitEnabledPhysitrackDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import physitrackLogo from "../assets/physitrack_logo.svg";
import { useIntegrationScreenContext } from "../context/IntegrationScreenContext.ts";

const getPhysitrackLogo = () => {
  return (
    <Image
      width="154.54"
      height="27"
      src={physitrackLogo}
      alt="Physitrack logo"
    />
  );
};

export const PhysitrackCard: React.FC = observer(() => {
  const { practice, core, notification } = useStores();
  const [isProviderListLoading, setIsProviderListLoading] = useState(false);
  const {
    numberOfActivatedProviders,
    setShowPhysitrackProviderList,
    showPhysitrackProviderList
  } = useIntegrationScreenContext();

  const getOrgUnit = async () => {
    const orgUnit = await practice.getOrgUnit(core.location?.id);
    const isEnabled = orgUnit?.orgUnitEnabledPhysitrack?.enabled;
    setShowPhysitrackProviderList(!!isEnabled);
  };

  const onChangeHandle = async (checked: boolean | undefined) => {
    if (checked) {
      try {
        setIsProviderListLoading(true);
        await practice.updateOrgUnit({
          id: core.location?.id,
          orgUnitCompanyData: [
            {
              orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.Physitrack,
              enabled: checked
            } as OrgUnitEnabledPhysitrackDto
          ]
        });
        setShowPhysitrackProviderList(!!checked);
      } catch (error) {
        notification.error(error.message);
      } finally {
        setIsProviderListLoading(false);
      }
    } else {
      const isConfirmed = await confirm({
        children: (
          <>
            <Text
              block
              variant="medium"
              styles={{ root: { paddingBottom: 16 } }}
            >
              Do you want to disable the Physitrack integration?
            </Text>

            <Text block variant="medium">
              Any configured providers will be disabled as part of this process.
            </Text>
          </>
        ),
        minWidth: 560,
        dialogContentProps: {
          title: "Disabling Integration"
        },
        confirmButtonProps: {
          text: "Disable"
        },
        cancelButtonProps: {
          text: "Cancel"
        }
      });
      if (isConfirmed) {
        try {
          await practice.updateOrgUnit({
            id: core.location?.id,
            orgUnitCompanyData: [
              {
                orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.Physitrack,
                enabled: checked
              } as OrgUnitEnabledPhysitrackDto
            ]
          });
          setShowPhysitrackProviderList(!!checked);
        } catch (error) {
          notification.error(error.message);
        }
      }
    }
  };

  return (
    <Card heading={getPhysitrackLogo()}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 16 }}
      >
        <Stack verticalAlign="start" tokens={{ childrenGap: 4 }}>
          <Text bold variant="medium">
            Generate provider Physitrack integration API keys
          </Text>
          {showPhysitrackProviderList && (
            <Text variant="small">
              {`Number of providers activated: ${numberOfActivatedProviders}`}
            </Text>
          )}
        </Stack>
        {!isProviderListLoading ? (
          <DataFetcher
            fetch={getOrgUnit}
            renderError={error => notification.error(error.message)}
            refetchId={practice.ui.recentlyUpdatedOrgUnitEtag}
            fallback={<Spinner />}
          >
            {() => (
              <Toggle
                onText="Enabled"
                offText="Disabled"
                automationAttribute="enable-activated-providers"
                checked={showPhysitrackProviderList}
                onChange={(_evt, checked) => onChangeHandle(checked)}
              />
            )}
          </DataFetcher>
        ) : (
          <Spinner />
        )}
      </Stack>
    </Card>
  );
});
