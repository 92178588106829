import React, { useContext, useState } from "react";
import { useForm } from "react-final-form";

import {
  ButtonsGroupChoice,
  ButtonsGroupOption,
  mergeStyleSets,
  useTheme
} from "@bps/fluent-ui";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";

enum ChoiceButtonsValue {
  Urgent = "Urgent",
  WaitingList = "WaitingList",
  Repeat = "Repeat"
}

export const ChoiceButtons: React.FunctionComponent = () => {
  const { booking } = useStores();
  const theme = useTheme();

  const form = useForm<AppointmentFormValues>();

  const isEdit = !!booking.ui.currentAppointment?.id;
  const { repeat, urgent, waitingListIds, providerId, startDate, startTime } =
    form.getState().values;

  const iconButtonStyle = isEdit &&
    repeat && {
      label: {
        selectors: {
          "&[data-name='Repeat'][data-disabled]": {
            backgroundColor: theme.palette.neutralTertiary
          }
        }
      }
    };

  const { calendarEvent } = useContext(AppointmentFormContext);
  const getInitialValue = (): ChoiceButtonsValue | undefined => {
    if (isEdit) {
      if (urgent) return ChoiceButtonsValue.Urgent;
      else if (repeat) return ChoiceButtonsValue.Repeat;
      else if (!!waitingListIds?.length) return ChoiceButtonsValue.WaitingList;
      else return undefined;
    } else return undefined;
  };

  const checkProviderDateTime = startDate && startTime && providerId;
  const showHideRepeatSidePanel = () => {
    booking.ui.setSecondColumnContent(
      checkProviderDateTime &&
        booking.ui.currentAppointment?.secondColumnContent !==
          SecondColumnContent.repeat
        ? SecondColumnContent.repeat
        : undefined
    );
  };

  const hideSidePanel = () => {
    booking.ui.setSecondColumnContent(undefined);
  };

  const [choicevalue, setChoiceValue] = useState<
    ChoiceButtonsValue | undefined
  >(getInitialValue());

  const disabledValue = (value: ChoiceButtonsValue): boolean => {
    let disabled: boolean = false;
    if (isEdit) {
      if (
        value === ChoiceButtonsValue.WaitingList &&
        (booking.ui.isEditSingleEvent || repeat)
      ) {
        disabled = true;
      } else if (
        value === ChoiceButtonsValue.Repeat &&
        (booking.ui.isEditSingleEvent ||
          !!waitingListIds?.length ||
          !calendarEvent?.calendarEventRecurrenceId)
      ) {
        disabled = true;
      }
    }
    return disabled;
  };

  const options: ButtonsGroupOption<any>[] = [
    {
      key: ChoiceButtonsValue.Urgent,
      text: ChoiceButtonsValue.Urgent,
      disabled: disabledValue(ChoiceButtonsValue.Urgent)
    },
    {
      key: ChoiceButtonsValue.Repeat,
      text: ChoiceButtonsValue.Repeat,
      disabled: disabledValue(ChoiceButtonsValue.Repeat)
    },
    {
      key: ChoiceButtonsValue.WaitingList,
      text: "Waiting list",
      disabled: disabledValue(ChoiceButtonsValue.WaitingList)
    }
  ];

  const handleClickButton = (value: ChoiceButtonsValue) => {
    if (form) {
      const { urgent, waitingFieldToggle, repeat } = form.getState().values;

      switch (value) {
        case ChoiceButtonsValue.Urgent: {
          if (isEdit && booking.ui.isEditSingleEvent) {
            form.change(appointmentFormNameOf("repeat"), repeat);
          } else {
            form.change(appointmentFormNameOf("repeat"), false);
          }
          form.change(appointmentFormNameOf("urgent"), !urgent);
          form.change(appointmentFormNameOf("waitingFieldToggle"), false);
          setChoiceValue(ChoiceButtonsValue.Urgent);
          hideSidePanel();
          break;
        }
        case ChoiceButtonsValue.WaitingList: {
          form.change(appointmentFormNameOf("urgent"), false);
          form.change(
            appointmentFormNameOf("waitingFieldToggle"),
            !waitingFieldToggle
          );
          form.change(appointmentFormNameOf("repeat"), false);
          setChoiceValue(ChoiceButtonsValue.WaitingList);
          hideSidePanel();
          break;
        }
        case ChoiceButtonsValue.Repeat: {
          form.change(appointmentFormNameOf("repeat"), !repeat);
          form.change(appointmentFormNameOf("waitingFieldToggle"), false);
          form.change(appointmentFormNameOf("urgent"), false);
          setChoiceValue(ChoiceButtonsValue.Repeat);
          showHideRepeatSidePanel();
          break;
        }
        default: {
          form.change(appointmentFormNameOf("urgent"), false);
          form.change(appointmentFormNameOf("waitingFieldToggle"), false);
          if (isEdit && repeat && booking.ui.isEditSingleEvent) {
            form.change(appointmentFormNameOf("repeat"), repeat);
          } else {
            form.change(appointmentFormNameOf("repeat"), false);
          }
          setChoiceValue(undefined);
          hideSidePanel();
        }
      }
    }
  };

  return (
    <ButtonsGroupChoice
      onChange={handleClickButton}
      value={choicevalue}
      options={options}
      equalWidth={true}
      styles={mergeStyleSets(iconButtonStyle, {
        root: { display: "flex" },
        label: {
          flex: 1
        }
      })}
    />
  );
};
