import { ReactNode } from "react";
import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { AllocationStatusLabel } from "@modules/billing/screens/allocation/components/AllocationStatusLabel.tsx";
import { AllocationField } from "@modules/billing/screens/shared-components/allocation-field/AllocationField.tsx";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";

import { useAllocationFormContext } from "../context/AllocationFormContext.ts";
import { allocationNameOf } from "./AllocationForm.types.ts";
import { AllocationFormDetails } from "./AllocationFormDetails.tsx";

interface AllocationFieldProps {
  statusLabel?: ReactNode;
  heading?: string;
}

export const AllocationFormFields: React.FC<AllocationFieldProps> = props => {
  const { getColumnOptions } = useAllocationFormContext();
  const { statusLabel, heading } = props;

  const {
    input: { value: allocationOption }
  } = useField(allocationNameOf("allocationOption"), {
    subscription: { value: true }
  });

  const defaultcolumnOptions = {
    filtersToShow: {
      invoice: true,
      providerName: true,
      patientName: true,
      total: true,
      owing: true
    }
  };

  const columnOptions = getColumnOptions
    ? getColumnOptions(allocationOption)
    : defaultcolumnOptions;

  return (
    <Stack tokens={{ childrenGap: 40 }}>
      <AllocationFormDetails heading={heading} />
      <FieldCondition
        when={allocationNameOf("accountContactId")}
        is={(value: string) => {
          return !!value;
        }}
      >
        <AllocationField
          columnOptions={columnOptions}
          statusLabel={statusLabel || <AllocationStatusLabel />}
          defaultHeadingText={
            statusLabel ? undefined : InvoiceFormLabels.invoicedItems
          }
        />
      </FieldCondition>
    </Stack>
  );
};
