import { observer } from "mobx-react-lite";
import { FC, ReactNode } from "react";

import { Heading, Text } from "@bps/fluent-ui";
import { PaymentMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { PaymentMethodField } from "@modules/billing/screens/shared-components/allocation-form/components/payment-field/PaymentMethodField.tsx";
import { ApplyRefundValidator } from "@modules/billing/screens/validators/RefundSubmissionDialogValidator.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

export interface ApplyRefundFormValues {
  method?: PaymentMethod;
}

export const applyCreditNameOf = nameOfFactory<ApplyRefundFormValues>();

interface RefundConfirmationDialogProps {
  onDismiss: () => void;
  onSucceeded: (method: PaymentMethod) => void;
  refundTotal: number;
  refundItemNumber: string;
  refundItemType?: string;
  refundDialogVisible?: boolean;
  messageBar?: ReactNode;
}

export const RefundConfirmationDialog: FC<RefundConfirmationDialogProps> =
  observer(
    ({
      onDismiss,
      onSucceeded,
      messageBar,
      refundTotal,
      refundDialogVisible,
      refundItemNumber,
      refundItemType = "payment"
    }) => {
      if (!refundDialogVisible) {
        return null;
      }

      return (
        <SubmissionFormDialog<ApplyRefundFormValues>
          validate={values => {
            return new ApplyRefundValidator().validate(values);
          }}
          buttonsProps={form => {
            const formState = form.getState();
            return {
              submitButtonProps: {
                disabled: !formState.values.method,
                text: `Refund ${refundItemType}`,
                iconProps: { hidden: true }
              }
            };
          }}
          dialogName="Create refund"
          onSubmit={({ method }) => method && onSucceeded(method)}
          dialogProps={{
            minWidth: 480,
            maxWidth: 480,
            onDismiss,
            dialogContentProps: {
              title: (
                <Heading variant="modal-heading">
                  {`Refunding ${refundItemType} ${refundItemNumber}`}
                </Heading>
              )
            }
          }}
        >
          {() => (
            <Fieldset>
              <Text>
                {`${currencyFormat(refundTotal)} refunded to the payer`}
              </Text>
              <PaymentMethodField
                required={true}
                label="Payment method for the refund"
                name={applyCreditNameOf("method")}
              />

              {messageBar}
            </Fieldset>
          )}
        </SubmissionFormDialog>
      );
    }
  );
