import { FunctionComponent, memo } from "react";

import {
  CompleteDialog,
  CompleteDialogProps
} from "@ui-components/CompleteDialog.tsx";

export interface ReverseDischargeDialogProps {
  hidden: boolean;
  onConfirm: CompleteDialogProps["onConfirm"];
  onCancel?: CompleteDialogProps["onCancel"];
}
export const ReverseDischargeDialog: FunctionComponent<ReverseDischargeDialogProps> =
  memo(props => {
    return (
      <CompleteDialog
        formName="reverse-discharge"
        {...props}
        title="Reverse discharge"
        subText="Are you sure you want to reverse this discharge?"
        maxWidth={480}
        minWidth={480}
        textInputLabel="Comments"
        okButtonText="Reverse discharge"
      />
    );
  });
