import React from "react";

import { AddBox, Card, DefaultButton, Stack, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

import { getUserStylesSet } from "../../../users/components/UserScreens.styles.tsx";
import { OrgUnitOpeningHoursOverrideFields } from "./OrgUnitOpeningHoursOverridesFields.tsx";
import {
  Operation,
  OrgUnitOpeningHoursOverridesValues
} from "./OrgUnitOpeningHoursOverridesForm.types.ts";
import { OrgUnitOpeningHoursOverridesFormValidator } from "./OrgUnitOpeningHoursOverridesFormValidator.ts";

type OrgUnitOpeningHoursOverridesFormProps = Pick<
  SubmissionFormProps<OrgUnitOpeningHoursOverridesValues>,
  "onSubmit" | "onSubmitSucceeded"
> & {
  readonly?: boolean;
  initialValues: Partial<OrgUnitOpeningHoursOverridesValues>;
  iconName: string;
  title: string;
  emptyStateText: string;
  emptyStateSubText: string;
  allowAdd?: boolean;
};

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

export const OrgUnitOpeningHoursOverridesForm: React.FC<
  OrgUnitOpeningHoursOverridesFormProps
> = props => {
  const {
    initialValues,
    iconName,
    title,
    emptyStateText,
    emptyStateSubText,
    allowAdd,
    onSubmit,
    readonly,
    onSubmitSucceeded
  } = props;

  const theme = useTheme();

  const validator = new OrgUnitOpeningHoursOverridesFormValidator();
  const validateForm = (values: OrgUnitOpeningHoursOverridesValues) => {
    return (
      validator ?? new OrgUnitOpeningHoursOverridesFormValidator()
    ).validate(values);
  };

  const { formFooter } = getUserStylesSet(theme);
  return (
    <SubmissionForm
      formName="opening-hours-override"
      readOnly={readonly}
      validate={validateForm}
      initialValues={initialValues}
      hideButtons
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      styles={{
        main: { overflowY: "visible" },
        fields: { overflowY: "visible" }
      }}
    >
      {({ form, values, pristine }) => {
        const { reset } = form;
        const resetForm = () => {
          reset(initialValues);
        };

        const onAddOverride = () => {
          form.change("orgUnitOpeningHoursOverrides", [
            ...(values.orgUnitOpeningHoursOverrides || []),
            {
              startDate: DateTime.jsDateNow(),
              endDate: DateTime.jsDateNow(),
              operation: Operation.Open,
              startTime: DateTime.today().set({ hour: 8 }).toTimeInputFormat(),
              endTime: DateTime.today().set({ hour: 17 }).toTimeInputFormat()
            }
          ]);
        };

        return (
          <Card
            iconName={iconName}
            headingLevel="section-heading"
            heading={title}
            styles={cardStyles}
            button={
              allowAdd && values.orgUnitOpeningHoursOverrides.length > 0 ? (
                <DefaultButton
                  onClick={onAddOverride}
                  iconProps={{ iconName: "Add" }}
                >
                  Add an exception
                </DefaultButton>
              ) : null
            }
          >
            {values.orgUnitOpeningHoursOverrides.length === 0 && (
              <AddBox
                text={emptyStateText}
                subText={emptyStateSubText}
                buttonText="Add an exception"
                onClick={allowAdd ? onAddOverride : undefined}
                styles={{ root: { padding: "32px 64px 32px 64px" } }}
              />
            )}
            <Stack styles={{ root: { overflowY: "auto" } }}>
              <OrgUnitOpeningHoursOverrideFields />
            </Stack>
            {!readonly &&
            (!pristine ||
              allowAdd ||
              values.orgUnitOpeningHoursOverrides.length !== 0) ? (
              <FormSubmitButtons
                disableCancelOnPristine
                onCancel={resetForm}
                promptOnCancel
                styles={{ root: formFooter }}
              />
            ) : null}
          </Card>
        );
      }}
    </SubmissionForm>
  );
};
