import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IButtonProps,
  IDialogContentProps,
  Spinner
} from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { convertInvoiceDetailsToRouteState } from "@modules/billing/screens/shared-components/utils/invoice.utils.ts";
import { InvoiceDetailsModalFormValidator } from "@modules/billing/screens/validators/InvoiceDetailsModalFormValidator.ts";
import { toAddressDto } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { InvoiceFormDetailsModalProps } from "./InvoiceDetailsModal.types.ts";
import { InvoiceDetailsModalFormFields } from "./InvoiceDetailsModalFormFields.tsx";

export const InvoiceDetailsModal: FunctionComponent<
  InvoiceFormDetailsModalProps
> = props => {
  const { hidden, initialValues, onDismiss, isAdjustMode } = props;
  const { practice, core, routing } = useStores();

  const currentContactId = routing.match(routes.accounts.account)?.params.id;

  if (hidden) {
    return null;
  }

  const onSubmit = async (values: InvoiceDetailsModalFormValues) => {
    if (values.newAddress && values.accountContactId) {
      const contact = practice.contactsMap.get(values.accountContactId);
      const contactPatch = {
        id: values.accountContactId,
        addresses: (contact?.addresses ?? []).concat(
          toAddressDto([values.newAddress])
        )
      };

      switch (contact?.type) {
        case ContactType.Patient:
          await practice.updatePatient(contactPatch);
          break;
        case ContactType.Individual:
          await practice.updateIndividual(contactPatch);
          break;
        case ContactType.Organisation:
          await practice.updateOrganisation(contactPatch);
          break;
      }
    }

    if (props.onSubmit) {
      props.onSubmit({ ...values });
    } else {
      await setTimeout(() => {
        // navigate to invoice form, saving values in state as strings
        routing.pushWithFromQuery(
          {
            pathname: routes.accounts.invoices.new.path({})
          },
          convertInvoiceDetailsToRouteState({
            ...values
          })
        );
      });
    }
  };

  const dialogContentProps: IDialogContentProps = {
    title: (
      <Heading variant="modal-heading">
        {isAdjustMode ? "Edit invoice billing details" : "New invoice details"}
      </Heading>
    )
  };

  const submitButtonProps: IButtonProps = {
    ...dataAttribute(DataAttributes.Element, "submit-button"),
    text: "Save & continue",
    iconProps: { iconName: "Save" }
  };

  return (
    <SubmissionFormDialog
      dialogName="Invoice details"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitSucceeded={onDismiss}
      validate={async (values: InvoiceDetailsModalFormValues) => {
        const accountContact = values.accountContactId
          ? await practice.getContact(values.accountContactId)
          : undefined;

        const validator = new InvoiceDetailsModalFormValidator(core, {
          accountContact,
          countries: core.ref.countries.values,
          isAdjustMode: !!isAdjustMode,
          country: core.tenantDetails!.country
        });

        return validator.validate(values);
      }}
      dialogProps={{
        showTitleSeparator: true,
        onDismiss,
        minWidth: 600,
        dialogContentProps,
        styles: {
          root: {
            ".ms-Dialog-action": { width: "100%" }
          }
        }
      }}
      buttonsProps={{
        submitButtonProps,
        hideButtonsSeparator: false,
        disableSubmitOnPristine: !!isAdjustMode,
        disableSubmitOnFormInvalid: true
      }}
      children={() => (
        <DataFetcher<Contact | undefined>
          fetch={async ({ practice }) =>
            currentContactId ? practice.getContact(currentContactId) : undefined
          }
          fallback={<Spinner />}
        >
          {currentContact => (
            <InvoiceDetailsModalFormFields
              isOrganisationInvoice={
                currentContact?.type === ContactType.Organisation
              }
            />
          )}
        </DataFetcher>
      )}
    />
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default InvoiceDetailsModal;
