import {
  ScreenWidthMinXXLarge,
  Stack,
  Text,
  useScreenSize
} from "@bps/fluent-ui";
import {
  NO,
  SafetyInHome,
  YES
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Labels } from "@modules/practice/screens/shared-components/types/labels.enums.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { PowerOfAttorneyList } from "./PowerOfAttorneyList.tsx";
import { SocialCareGiverList } from "./SocialCareGiverList.tsx";
import { SocialCareRecipientList } from "./SocialCareRecipientList.tsx";
import { sectionHeader } from "./SocialForm.tsx";

interface SocialFormContentProps {
  patient: Contact | undefined;
}
export const SocialFormContent: React.FunctionComponent<
  SocialFormContentProps
> = ({ patient }) => {
  const { clinical } = useStores();
  const { width } = useScreenSize();

  const getButtonOptions = (withNoDefaultOption: boolean) => {
    const options = [
      { key: YES, text: "Yes" },
      { key: NO, text: "No" },
      { key: undefined, text: "Have not asked" }
    ];

    return withNoDefaultOption ? options.filter(val => !!val.key) : options;
  };

  const safetyInHomeOptions = () => {
    return [
      { key: SafetyInHome.FeelsSafeAtHome, text: "Feels safe at home" },
      { key: SafetyInHome.DoesNotFeelSafe, text: "Does not feel safe" }
    ];
  };
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack tokens={{ childrenGap: 8, maxWidth: 235 }}>
        <Text styles={sectionHeader}>Personal</Text>
        <Fieldset>
          <DropdownField
            name="patientDemographic.relationshipStatus"
            label={Labels.relationshipStatus}
            options={clinical.ref.relationshipStatuses.keyTextValues.filter(
              x => x.key !== "UNK"
            )}
          />

          <DropdownField
            name="social.sexuality"
            label="Sexuality"
            options={clinical.ref.sexuality.keyTextValues}
          />
        </Fieldset>
        {patient?.sex === Sex.Female && (
          <>
            <ButtonsGroupSingleChoiceField
              label="Is pregnant"
              options={getButtonOptions(true)}
              name="social.isPregnant"
            />
            <ButtonsGroupSingleChoiceField
              label="Is breastfeeding"
              options={getButtonOptions(true)}
              name="social.isBreastfeeding"
            />
          </>
        )}

        <Fieldset>
          <ButtonsGroupSingleChoiceField
            label="Is elite athlete"
            name="physicalActivity.isEliteAthlete"
            options={getButtonOptions(true)}
          />
        </Fieldset>
      </Stack>
      <Stack tokens={{ childrenGap: 8 }}>
        <Text styles={sectionHeader}>Living situation</Text>
        <Fieldset horizontal={width >= ScreenWidthMinXXLarge}>
          <Stack
            tokens={{ childrenGap: 8, maxWidth: 235 }}
            styles={{ root: { minWidth: 235 } }}
          >
            <DropdownField
              name="social.accommodation"
              label="Accommodation"
              options={clinical.ref.accommodations.keyTextValues}
            />

            <DropdownField
              name="social.livesWith"
              label="Lives with"
              options={clinical.ref.livesWith.keyTextValues}
            />
          </Stack>

          <ButtonsGroupSingleChoiceField
            label="Safety in home"
            name="social.safetyInHome"
            options={safetyInHomeOptions()}
          />
        </Fieldset>
      </Stack>
      <Stack tokens={{ childrenGap: 8 }}>
        <Text styles={sectionHeader}>Care arrangement</Text>
        <Fieldset styles={{ root: { minWidth: 500 } }}>
          <ButtonsGroupSingleChoiceField
            label="Has a care giver "
            name="patientDemographic.hasACareGiver"
            options={getButtonOptions(true)}
          />
          <SocialCareGiverList options={clinical.ref.careTypes.keyTextValues} />
          <ButtonsGroupSingleChoiceField
            label="Is a care giver "
            name="patientDemographic.isACarer"
            options={getButtonOptions(true)}
          />
          <SocialCareRecipientList
            options={clinical.ref.careTypes.keyTextValues}
          />
        </Fieldset>
      </Stack>
      <Stack tokens={{ childrenGap: 8 }}>
        <Text styles={sectionHeader}>Legal</Text>
        <Fieldset styles={{ root: { maxWidth: 450 } }}>
          <ButtonsGroupSingleChoiceField
            label="Advanced care directive"
            name="social.isAdvancedCareDirective"
            options={getButtonOptions(true)}
          />

          <ButtonsGroupSingleChoiceField
            label="Enduring power of attorney"
            name="patientDemographic.isEnduringPowerOfAttorney"
            options={getButtonOptions(true)}
          />
          <PowerOfAttorneyList />
        </Fieldset>
      </Stack>
    </Stack>
  );
};
