import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import {
  Card,
  dataAttribute,
  DataAttributes,
  IconButton,
  mergeStyleSets,
  Pivot,
  PivotItem,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";

import { getIconButtonStyles } from "../SchedulesScreen.styles.ts";
import { FeesList } from "./fees/FeesList.tsx";
import { FeesListFilter } from "./fees/FeesListFilter.tsx";
import { useSearch } from "./fees/FeesTile.tsx";

enum FeesKeys {
  StandardFees = "Standard fees",
  NonStandardFees = " Non-standard fees"
}

export const AccFeesTile = observer(() => {
  const { viewSchedule } = useContext(ScheduleScreenContext);

  const { search, queryOptions, setQueryOptions } = useSearch(
    viewSchedule?.id,
    true
  );

  const {
    search: nonStandardSearch,
    queryOptions: nonStandardQueryOptions,
    setQueryOptions: setNonStandardQueryOptions
  } = useSearch(viewSchedule?.id, false);

  const [feeKey, setSelectedFeeKey] = useState<string>(FeesKeys.StandardFees);

  const handlePivot = (item: PivotItem) => {
    const key = item.props.itemKey!;
    setSelectedFeeKey(key);
  };

  const pivot = (
    <Pivot
      selectedKey={feeKey}
      onLinkClick={handlePivot}
      styles={{
        link: { backgroundColor: "inherit" }
      }}
    >
      <PivotItem
        headerText={`${FeesKeys.StandardFees} (${viewSchedule?.activeBenefitFees})`}
        itemKey={FeesKeys.StandardFees}
      />
      <PivotItem
        headerText={`${FeesKeys.NonStandardFees} (${viewSchedule?.activeNonBenefitFees})`}
        itemKey={FeesKeys.NonStandardFees}
      />
    </Pivot>
  );

  const theme = useTheme();

  const iconButtonStyles = getIconButtonStyles(theme);
  const [isFilter, setIsFilter] = useState<boolean>(false);

  const tileHeading = (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
      {pivot}
      <IconButton
        id="schedule-header-filter-button"
        iconProps={{ iconName: "Filter" }}
        onClick={() => setIsFilter(state => !state)}
        styles={mergeStyleSets(iconButtonStyles, {
          icon: { fontSize: theme.fonts.mediumPlus.fontSize }
        })}
      />
    </Stack>
  );

  return (
    <Card
      {...dataAttribute(DataAttributes.Element, "schedule-acc-fees-table")}
      headingLevel="section-heading"
      heading={tileHeading}
      styles={{
        subComponentStyles: {
          tile: {
            root: { flexGrow: 1 },
            content: {
              display: "flex",
              flexDirection: "column",
              padding: 16
            }
          }
        }
      }}
    >
      {feeKey === FeesKeys.StandardFees ? (
        <FeesListFilter
          key="stdFilter"
          setFilterSearch={setQueryOptions}
          initialValues={queryOptions}
          hidden={isFilter}
          onReset={() => setIsFilter(state => !state)}
        >
          <FeesList
            key="stdList"
            search={search}
            notFoundText="in standard fees"
            queryOptions={queryOptions}
            hideNotFoundLink
          />
        </FeesListFilter>
      ) : (
        <FeesListFilter
          key="nonStndFilter"
          initialValues={nonStandardQueryOptions}
          setFilterSearch={setNonStandardQueryOptions}
          hidden={isFilter}
          onReset={() => setIsFilter(state => !state)}
        >
          <FeesList
            key="nonStndList"
            search={nonStandardSearch}
            queryOptions={nonStandardQueryOptions}
            notFoundText="in non-standard fees"
            noFeeText="non-standard"
          />
        </FeesListFilter>
      )}
    </Card>
  );
});
