import { FormApi } from "final-form";
import React, { useState } from "react";

import { Spinner } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ClinicalDataTypeLabel,
  MeasurementDto,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { ClinicalToolScoreModal } from "../clinical-tool-form/ClinicalToolScoreModal.tsx";
import { ClinicalToolReferences } from "../ClinicalTool.types.ts";
import { ClinicalToolInformation } from "../shared-components/ClinicalToolInformation.tsx";
import { ConfidentialShield } from "../shared-components/ConfidentialShield.tsx";
import { GRCSBaselineForm } from "./GRCSBaselineForm.tsx";
import {
  CLINICAL_TOOLS_FORM_HEADING,
  GRCSFormProps,
  GRCSFormValues
} from "./GRCSForm.types.ts";
import { GRCSFormModel } from "./GRCSFormModel.ts";
import { GRCSFormValidator } from "./GRCSFormValidator.ts";

const validator = new GRCSFormValidator();

const GRCSFormBase: React.FunctionComponent<GRCSFormProps> = ({
  clinicalRecord,
  questionnaire
}) => {
  const { clinical, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const [summary, setSummary] = useState<string>("");
  const [closeOnSubmit, setCloseOnSubmit] = useState<boolean>(false);
  const onMeasurementSaved = (measurement: MeasurementDto) => {
    setSummary(measurement.summary ?? measurement[0].value.toString());
  };

  const nameOf = nameOfFactory<GRCSFormValues>();

  const model = new GRCSFormModel(clinicalRecord, clinical, onMeasurementSaved);

  const diagnoses = clinicalRecord.episodeOfCare?.diagnoses
    ? clinicalRecord.episodeOfCare?.diagnoses?.find(d => d.isPrimaryDiagnosis)
    : clinicalRecord.clinicalData?.diagnoses?.diagnoses?.find(
        d => d.isPrimaryDiagnosis
      );

  const getTerminology = () => {
    if (
      diagnoses &&
      diagnoses.diagnosisCode?.code &&
      diagnoses.diagnosisCode?.originalText
    ) {
      const terminology = {
        code: diagnoses.diagnosisCode?.code,
        originalText: diagnoses.diagnosisCode?.originalText,
        codeSystem: diagnoses.diagnosisCode?.codeSystem,
        version: diagnoses.diagnosisCode?.version
      };
      return terminology;
    }
    return undefined;
  };

  const onSubmitSucceeded = (
    values: GRCSFormValues,
    form: FormApi<GRCSFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.GRCS
      });
      setCloseOnSubmit(false);
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      setCloseOnSubmit(true);
    }
  };

  return (
    <>
      <ClinicalSubmissionForm<GRCSFormValues>
        formName="grcs"
        onSubmit={async values => {
          await model.onSubmit(values, questionnaire);
        }}
        onSubmitSucceeded={onSubmitSucceeded}
        hideButtons
        validate={validator.validate}
        initialValues={model.initialValues}
        readOnly={isViewOnlyOrDischarged}
        disableRoutePrompt
        heading={CLINICAL_TOOLS_FORM_HEADING}
        onCancel={model.onCancel}
        promptOnCancel={true}
        disableButtonCondition={stashedData =>
          !stashedData?.dirtyAreas?.get("grcs")
        }
        lastUpdatedDate={getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.grcs
        )}
        lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
          clinicalRecord.clinicalData?.grcs
        )}
        confidentialShield={
          <ConfidentialShield name={nameOf("confidential")} />
        }
        noGap
        hasSeparator
      >
        <>
          <ClinicalToolInformation
            clinicalToolType={ClinicalDataTypeLabel.GRCS}
            paragraphs={ClinicalToolReferences.GRCS}
          />
          <StashedClinicalDataFormSpy<GRCSFormValues>
            clinicalRecord={clinicalRecord}
            getData={model.getStashedClinicalData(questionnaire)}
            areasToObserve={{
              grcs: [ClinicalDataType.GRCS]
            }}
          />
          <GRCSBaselineForm
            questionnaire={questionnaire}
            diagnosis={model.gprsClinicalData?.diagnosis ?? getTerminology()}
          />
        </>
      </ClinicalSubmissionForm>
      {summary && (
        <ClinicalToolScoreModal
          title={CLINICAL_TOOLS_FORM_HEADING}
          onClose={() => {
            if (closeOnSubmit) {
              model.onCancel();
            } else {
              setSummary("");
            }
          }}
          diagnosis={summary}
        />
      )}
    </>
  );
};
export const GRCSForm: React.FunctionComponent<
  Omit<GRCSFormProps, "questionnaire">
> = ({ clinicalRecord }) => {
  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.GRCSV1)}
      fallback={<Spinner />}
    >
      {questionnaire => {
        return (
          <GRCSFormBase
            clinicalRecord={clinicalRecord}
            questionnaire={questionnaire}
          />
        );
      }}
    </DataFetcher>
  );
};
