import React from "react";

import { Dropdown, Stack, Text } from "@bps/fluent-ui";
import {
  PSFSContextClinicalDataItemDto,
  PSFSQuestionnaireResponseDto,
  PSFSReason,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { getSideOfBodyText } from "@modules/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";

import { ClinicalToolAnswer } from "../utils/ClinicalToolAnswerFinder.ts";
import { psfsNameOf } from "./PSFSForm.types.ts";
import { PSFSQuestions } from "./PSFSQuestions.tsx";

interface PSFSSeriesFormProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  context?: PSFSContextClinicalDataItemDto;
  response?: PSFSQuestionnaireResponseDto;
}

const PSFSSeriesFormBase: React.FunctionComponent<PSFSSeriesFormProps> = ({
  questionnaire,
  answers,
  context,
  response
}) => {
  const { clinical } = useStores();

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Text variant="large" block>
        {context?.diagnosis.originalText} - {getSideOfBodyText(context?.side!)}
      </Text>

      <Text block>
        This questionnaire can be used to quantify activity limitation and
        measure functional outcome for patients with an orthopaedic diagnosis.
        Clinician to read and fill in.
      </Text>
      <Text block>
        Rate these same activities again so we can see if there is any
        improvement. Rate each activity from 0 to 10 where 0 means you are
        unable to perform the activity and 10 means you are able to perform the
        activity at the same level as before the injury or problem.
      </Text>
      {!!answers ? (
        <Dropdown
          name="psfs-reason"
          options={clinical.ref.psfsReasons.keyTextValues.filter(
            x => x.key !== PSFSReason.Baseline
          )}
          label="Reason"
          required
          disabled
          selectedKey={response?.reason}
          styles={{ root: { paddingRight: "8px" } }}
        />
      ) : (
        <DropdownField
          name={psfsNameOf("reason")}
          options={clinical.ref.psfsReasons.keyTextValues.filter(
            x => x.key !== PSFSReason.Baseline
          )}
          label="Reason"
          required
          styles={{ root: { paddingRight: "8px" } }}
        />
      )}
      <Stack.Item>
        <PSFSQuestions
          questionnaire={questionnaire}
          answers={answers}
          context={context}
          newItemInSeries={true}
        />
      </Stack.Item>
    </Stack>
  );
};

export const PSFSSeriesForm = withFetch(
  x => [x.clinical.ref.sidesOfBody.load(), x.clinical.ref.psfsReasons.load()],
  PSFSSeriesFormBase
);
