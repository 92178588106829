import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useField } from "react-final-form";

import { mergeStyleSets } from "@bps/fluent-ui";
import { compareDatesOrUndefinedPredicate } from "@bps/utils";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  OptionsSelectField,
  OptionsSelectFieldProps
} from "@ui-components/form/selects/OptionsSelectField.tsx";

import { getOptions, splitClaimKeyString } from "./utils.ts";

interface ClaimPickerFieldProps
  extends Omit<
    OptionsSelectFieldProps,
    | "options"
    | "loading"
    | "errorMessage"
    | "onRenderField"
    | "onRenderOption"
    | "onChangeSelectedKeys"
  > {
  patientId: string | undefined;
  onRenderOption?: (claim: Claim) => JSX.Element;
  pickByClaimNumber?: boolean;
  onRenderField?: (claims: Claim[]) => string | JSX.Element | undefined;
  showBusinessRole?: boolean;
  displayDiagnosis?: boolean;
  isPrivateClaim?: boolean;
}

const ClaimPickerFieldBase: FunctionComponent<ClaimPickerFieldProps> = observer(
  props => {
    const {
      patientId,
      onRenderOption: propsOnRenderOption,
      pickByClaimNumber,
      onRenderField: propsOnRenderField,
      styles: propsStyles,
      showBusinessRole,
      displayDiagnosis,
      isPrivateClaim,
      ...pickerProps
    } = props;

    const root = useStores();

    // Try get claim from claimsMap so that it can appear in the input
    //  while the rest of the claims are loaded
    const {
      input: { value: claimId }
    } = useField(pickerProps.name);

    const selectedClaim = claimId ? root.acc.claimsMap.get(claimId) : undefined;

    const [claims, setClaims] = useState<Claim[]>(
      selectedClaim ? [selectedClaim] : []
    );

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
      undefined
    );

    const isMounted = useRef<boolean>(true);

    useEffect(() => {
      if (!patientId) {
        setClaims([]);
        return;
      }

      if (isPrivateClaim) {
        setClaims([
          new Claim(root, { id: "Private", claimNumber: "Private", eTag: "" })
        ]);
        return;
      }

      setIsLoading(true);

      root.acc
        .fetchClaims({ patients: [patientId] })
        .then(async result => {
          await Promise.all(
            result.results.map(newClaim =>
              Promise.all([
                newClaim.loadProvider(),
                newClaim.loadClaimAppointments()
              ])
            )
          );

          const sortedClaims = Array.from(result.results).sort(
            (claimA, claimB) =>
              compareDatesOrUndefinedPredicate(
                claimB.initialConsultDate,
                claimA.initialConsultDate
              )
          );

          if (isMounted.current) {
            if (pickByClaimNumber) {
              setClaims(sortedClaims.filter(claim => claim.claimNumber));
            } else {
              setClaims(sortedClaims);
            }
          }
        })
        .catch(e => {
          if (isMounted.current) {
            setErrorMessage(e.message);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false);
          }
        });
    }, [patientId, root, pickByClaimNumber, isPrivateClaim]);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    const onRenderOption:
      | OptionsSelectFieldProps["onRenderOption"]
      | undefined = propsOnRenderOption
      ? option => {
          const keyId = splitClaimKeyString(option.key).keyId;

          const claim = claims.find(claim => claim.id === keyId);
          if (!claim) {
            // should never occur as claim should always be found
            return <div />;
          }
          return propsOnRenderOption(claim);
        }
      : undefined;

    const onRenderField:
      | OptionsSelectFieldProps["onRenderFieldContent"]
      | undefined = propsOnRenderField
      ? options => {
          const selectedClaims = options
            .map(option => {
              const keyId = splitClaimKeyString(option.key).keyId;
              return claims.find(claim => claim.id === keyId);
            })
            .filter(claim => claim) as Claim[];
          // note - field won't render text if claims aren't loaded or a matching claim can't be found

          return propsOnRenderField(selectedClaims);
        }
      : undefined;

    const styles = mergeStyleSets(
      { fieldContent: { justifyContent: "flex-start" } },
      propsStyles
    );

    return (
      <OptionsSelectField
        onRenderOption={onRenderOption}
        onRenderFieldContent={onRenderField}
        hideSearchOption={true}
        multiSelect={false}
        styles={styles}
        disabled={isPrivateClaim ?? pickerProps.disabled}
        {...pickerProps}
        options={getOptions(claims, root, {
          showBusinessRole,
          displayDiagnosis,
          pickByClaimNumber
        })}
        loading={isLoading}
        errorMessage={errorMessage}
      />
    );
  }
);

export const ClaimPickerField = withFetch(
  root => [
    root.practice.ref.accProviderContractTypes.load(),
    root.practice.ref.accProviderTypes.load()
  ],
  ClaimPickerFieldBase
);
