import { fabric } from "fabric";
// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef } from "react";
import { useField } from "react-final-form";

import { Overlay } from "@bps/fluent-ui";
import { getBlobInBase64 } from "@libs/api/utils/blob-service.utils.ts";

import {
  CanvasImage,
  Options
} from "../../../notes/draw/DrawingCanvas.types.ts";
import { DrawingCanvasHistory } from "../../../notes/draw/DrawingCanvasHistory.ts";
import { FabricDrawing } from "../../../notes/draw/fabric-drawing/FabricDrawing.tsx";
import { BackgroundDisplayOption } from "../../../notes/draw/fabric-drawing/FabricDrawing.types.ts";

interface Props {
  image: CanvasImage;
  isViewOnly?: boolean;
  history: DrawingCanvasHistory;
  options: Options;
  fieldName: string;
  showCurrentCanvas: boolean;
  isDrawingMode: boolean;
  handleKeyDown: (event: React.KeyboardEvent<HTMLCanvasElement>) => void;
  handleSelectionChange: (activeObjects: fabric.Object[] | null) => void;
  handleCanvasFocused: (canvas: Canvas) => void;
  setCanvas: (canvas: Canvas) => void;
}

const MARGIN = 8;
export const CanvasImageEditor: FunctionComponent<Props> = observer(
  ({
    image,
    isViewOnly,
    history,
    options,
    fieldName,
    handleKeyDown,
    handleCanvasFocused,
    showCurrentCanvas,
    setCanvas,
    isDrawingMode
  }) => {
    const isInitializing = useRef<boolean>(false);
    useEffect(() => {
      isInitializing.current = true;
      if (image.id) {
        if (image.initialValue) {
          fabric.loadSVGFromString(image.initialValue, res => {
            const skipInitial = res.length - 1;
            image.id && history.initialize(image.id, skipInitial);
          });
        } else {
          image.id && history.initialize(image.id, 0);
        }
      }
      setTimeout(() => {
        isInitializing.current = false;
      }, 500);
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
      input: { onChange: onFieldChange }
    } = useField(fieldName || "");

    const onChange = (canvas: Canvas, event: any) => {
      if (!event || isViewOnly || isInitializing.current) return;
      history.add(canvas.getElement().id, canvas);

      const canvasImageValue =
        event.target.dirty && canvas._objects.length > 0
          ? canvas?.toSVG()
          : undefined;
      onFieldChange(canvasImageValue);
    };

    const handleCanvasRef = async (canvas: Canvas) => {
      if (image.canvasRef) {
        image.canvasRef(canvas);
      }
      setCanvas(canvas);

      // Set background image with offset
      if (image.backgroundImageUrl) {
        const base64Image = await getBlobInBase64(image.backgroundImageUrl);
        if (base64Image) {
          fabric.Image.fromURL(base64Image, img => {
            img &&
              img.width &&
              img.height &&
              img.set({
                left: MARGIN,
                top: MARGIN,
                scaleX: (image.width - 2 * MARGIN) / img.width,
                scaleY: (image.height - 2 * MARGIN) / img.height
              });
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
          });
        }
      }
    };

    return (
      <div
        key={image.id}
        style={{
          display: showCurrentCanvas ? "block" : "none",
          position: "relative"
        }}
      >
        <FabricDrawing
          id={image.id}
          options={options}
          embedImages={true}
          initialValue={image.initialValue}
          onChange={onChange}
          canvasRef={handleCanvasRef}
          isDrawingMode={isDrawingMode}
          width={image.width}
          height={image.height}
          onKeyDown={handleKeyDown}
          handleCanvasFocused={handleCanvasFocused}
          backgroundDisplayOption={BackgroundDisplayOption.Center}
        />

        {isViewOnly && (
          <Overlay styles={{ root: { backgroundColor: "none" } }} />
        )}
      </div>
    );
  }
);
