import React, { useState } from "react";
import { useField } from "react-final-form";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { claimFormNameOf } from "@modules/acc/screens/claim/claim.utils.ts";

export const InvalidEmployerAddressMessageBar: React.FC = () => {
  const [hiddenMessageBar, setHiddenMessageBar] = useState(false);

  const {
    input: { value: employerId }
  } = useField(claimFormNameOf("employerId"), {
    subscription: { value: true }
  });

  return !hiddenMessageBar && employerId ? (
    <MessageBar
      onDismiss={() => setHiddenMessageBar(true)}
      dismissButtonAriaLabel="Close"
      messageBarType={MessageBarType.warning}
    >
      Not all address data could be imported due to conflicts with ACC data
      capture requirements.
    </MessageBar>
  ) : null;
};
