import { ACCEPTED_DAY_FORMATS, DateTime } from "@bps/utils";
import { CscDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  predicate,
  required,
  requiredCharactersLength,
  todayOrLater,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

export class CscValidator extends Validator<CscDto> {
  nameOf = nameOfFactory<CscDto>();

  constructor(oldCscExpiry: Date | undefined) {
    super();
    this.forField(this.nameOf("number"), [
      required(),
      requiredCharactersLength(16)
    ]);
    this.forField(this.nameOf("startDate"), [
      required(),
      validDate(...ACCEPTED_DAY_FORMATS)
    ]);

    this.forField(this.nameOf("expiry"), [
      required(),
      validDate(...ACCEPTED_DAY_FORMATS),
      predicate(
        value =>
          !!value &&
          oldCscExpiry?.getTime() !== DateTime.jsDateFromISO(value).getTime(),
        todayOrLater
      )
    ]);
  }
}
