import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";

import { DefaultButton } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ReviewFormValues } from "@shared-types/acc/claim-review-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { DocumentStatus } from "./ClaimReviewEnums.ts";
import { useClaimReviewContext } from "./models/ClaimReviewContext.ts";

export const ClaimReviewFormButtons = observer(() => {
  const { clinical } = useStores();
  const { setClaimReviewTab } = usePatientRecordScreenContext();
  const claimReviewHelper = useClaimReviewContext();

  const { deleteClaimReview, formIsDisabled } = claimReviewHelper;

  const disabled =
    formIsDisabled ||
    !clinical.ui.clinicalTabs.currentPatientRecordTab?.activeTab?.isDirty;

  return (
    <FormSubmitButtons
      hideSubmit={true}
      extraActionsBefore={(form: FormApi<ReviewFormValues>) => {
        return (
          <>
            <DefaultButton
              text="Save & create PDF"
              type="submit"
              primary
              split
              menuProps={{
                items: [
                  {
                    key: "delete",
                    text: "Delete",
                    onClick: () => {
                      deleteClaimReview(setClaimReviewTab);
                    }
                  }
                ]
              }}
              disabled={
                formIsDisabled ||
                form.getState().submitting ||
                claimReviewHelper.claimReview?.documentStatus ===
                  DocumentStatus.pending
              }
              onClick={() => {
                claimReviewHelper.renderPdf = true;
              }}
            />

            <DefaultButton
              text="Save draft"
              type="submit"
              disabled={disabled}
            />
          </>
        );
      }}
      hideButtonsSeparator={true}
      styles={{
        root: {
          padding: 24,
          marginTop: 0
        }
      }}
      onCancel={() => {
        setClaimReviewTab();
      }}
      promptOnCancel={true}
    />
  );
});
