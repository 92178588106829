import { createContext, useContext } from "react";

import { UserWorkingHoursOverridesScreenHelper } from "./UserWorkingHoursOverridesScreenHelper.ts";

export const UserWorkingHoursOverridesScreenContext =
  createContext<UserWorkingHoursOverridesScreenHelper>(
    {} as UserWorkingHoursOverridesScreenHelper
  );

export const useUserWorkingHoursOverridesScreen = () =>
  useContext(UserWorkingHoursOverridesScreenContext);
