import { Spinner } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";

import { AllocationFormLabels } from "../allocation/components/AllocationForm.types.tsx";
import { AllocationForm } from "../shared-components/allocation-form/components/AllocationForm.tsx";
import { allocationNameOf } from "../shared-components/allocation-form/components/AllocationForm.types.ts";
import { CreditNoteConfirmDialog } from "./components/CreditNoteConfirmDialog.tsx";
import { CreditNoteStatusLabel } from "./components/CreditNoteStatusLabel.tsx";
import { CreditNoteHelper } from "./context/CreditNoteHelper.ts";
import { CreditNoteAllocationFormHeader } from "./CreditNoteAllocationFormHeader.tsx";

export const CreditNoteScreen = () => {
  const root = useStores();
  const invoiceId = root.routing.match(routes.accounts.creditNotes.new)?.params
    .id;

  if (!invoiceId) {
    return null;
  }

  const fetch = async (root: IRootStore) => {
    const invoice = await root.billing.getInvoice(invoiceId);

    const helper = new CreditNoteHelper(root, { invoice });
    await helper.initialise();
    return helper;
  };

  return (
    <DataFetcher<CreditNoteHelper> fallback={<Spinner />} fetch={fetch}>
      {helper => (
        <AllocationForm
          heading={AllocationFormLabels.allocationActionCreditPage}
          helper={helper}
          header={
            <CreditNoteAllocationFormHeader
              helper={helper}
              number={helper.number}
              close={helper.close}
              dateOverride={
                <DatePickerField
                  name={allocationNameOf("paymentDate")}
                  styles={{ fieldGroup: { borderColor: "transparent" } }}
                  maxDate={DateTime.jsDateNow()}
                />
              }
              breadcrumbProps={{
                transactionRefNumber: helper.invoice.number,
                transactionNumber: helper.number
              }}
            />
          }
          statusLabel={() => <CreditNoteStatusLabel invoice={helper.invoice} />}
        >
          {() => <CreditNoteConfirmDialog helper={helper} />}
        </AllocationForm>
      )}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default CreditNoteScreen;
