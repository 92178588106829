import { AddPatientNoticeDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { SystemNotice } from "@shared-types/practice/system-notice.interface.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";

import { AddPatientNoticeFormTypes } from "./PatientNoticeForm.types.tsx";
import { getPriorityOrder, getVisibility } from "./utils.ts";

export interface PatientNoticesModelOptions {
  patientId: string;
  withSystemNotices: boolean;
}

export enum NoticeType {
  Clinical = "clinical",
  Admin = "admin"
}

export class PatientNoticesModel {
  constructor(
    public practice: PracticeStore,
    public options: PatientNoticesModelOptions
  ) {}

  public get patientId() {
    return this.options.patientId;
  }

  private get withSystemNotices() {
    return this.options.withSystemNotices;
  }

  get patientNotices(): PatientNotice[] {
    return Array.from(this.practice.patientNoticesMap.values()).filter(
      pn => pn.patientId === this.patientId
    );
  }

  get clinicalNotices(): PatientNotice[] {
    return this.getFilteredPatientNotices(NoticeType.Clinical);
  }

  get adminNotices(): PatientNotice[] {
    return this.getFilteredPatientNotices(NoticeType.Admin);
  }

  get systemNotices(): SystemNotice[] {
    if (!this.withSystemNotices) return [];
    return this.practice.systemNotices.filter(
      sn => sn.patientId === this.patientId
    );
  }

  public getFilteredPatientNotices = (filter: NoticeType): PatientNotice[] => {
    if (filter === NoticeType.Admin)
      return this.patientNotices.filter(pn => pn.isAdmin);
    if (filter === NoticeType.Clinical)
      return this.patientNotices.filter(pn => pn.isClinical);
    return this.patientNotices;
  };

  public onSubmit = async (
    values: AddPatientNoticeFormTypes,
    editNotice?: PatientNotice
  ) => {
    const { priority, type, comment, visibility } = values;

    const notice: AddPatientNoticeDto = {
      patientId: this.patientId,
      priority,
      type,
      comment,
      isAdmin: visibility.includes("isAdmin"),
      isClinical: visibility.includes("isClinical")
    };

    if (editNotice) {
      await this.practice.updatePatientNotice({
        ...notice,
        id: editNotice.id,
        eTag: editNotice.eTag
      });
    } else {
      await this.practice.addPatientNotice(notice);
    }
  };

  public getInitialValues = (editNotice?: PatientNotice) => {
    if (editNotice) {
      return {
        patientId: editNotice.patientId,
        comment: editNotice.comment ?? "",
        type: editNotice.type ?? "",
        priority: editNotice.priority ?? "",
        visibility: getVisibility({
          isClinical: editNotice.isClinical,
          isAdmin: editNotice.isAdmin
        })
      };
    }
    return undefined;
  };

  public patientNoticeSort = (x: PatientNotice, y: PatientNotice) => {
    const yPriority = getPriorityOrder(y.priority);
    const xPriority = getPriorityOrder(x.priority);
    const yText = this.getTextOrder(y.type);
    const xText = this.getTextOrder(x.type);

    if (yPriority < xPriority) return -1;
    if (yPriority > xPriority) return 1;
    if (yText < xText) return 1;
    if (yText > xText) return -1;
    return 0;
  };

  private getTextOrder = (type?: string): number => {
    const typeArray: { key: string; name: string }[] =
      this.practice.ref.patientNoticeType.keyNameValues;

    const currentType = typeArray.find(x => x.key === type);
    return currentType ? typeArray.indexOf(currentType) : 0;
  };
}
