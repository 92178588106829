import { ProviderOnlineStatus } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface BhbProvidersOnlineProfileFormValues {
  id: string;
  displayName?: string;
  genderIdentity?: string | undefined;
  language?: string[] | undefined;
  providerOnlineStatus?: ProviderOnlineStatus | undefined;
  areaOfInterest?: string[] | undefined;
  title: string;
  firstName: string;
  lastName: string;
  profileDetail?: string | undefined;
  photoUrl?: string | undefined;
}

export const onlineProfileNameOf =
  nameOfFactory<BhbProvidersOnlineProfileFormValues>();
