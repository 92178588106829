import { FontIcon, TooltipHost, useTheme } from "@bps/fluent-ui";

import { ClinicalActivityType } from "./types/clinical-activity.type.ts";

export interface ClinicalActivityTypeIconProps {
  activityType: string;
}

export const ClinicalActivityTypeIcon: React.FC<
  ClinicalActivityTypeIconProps
> = ({ activityType }) => {
  const theme = useTheme();

  let iconName = "BpPulseBoard10";

  let onHoverText = "Clinical task";
  let iconColor = theme.palette.themePrimary;
  let iconBackgroundColor = theme.appointmentStatusesColors.bookedSecondary;

  if (activityType === ClinicalActivityType.Reminder) {
    iconName = "BpReminder";
    iconColor = theme.palette.red;
    iconBackgroundColor = theme.semanticColors.errorBackground;
    onHoverText = "Clinical reminder";
  }

  return (
    <TooltipHost content={onHoverText}>
      <FontIcon
        iconName={iconName}
        styles={{
          root: {
            color: iconColor,
            backgroundColor: iconBackgroundColor,
            padding: 4,
            marginRight: 4
          }
        }}
      />
    </TooltipHost>
  );
};
