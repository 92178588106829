import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import {
  ExtraCurrentItemValidator,
  ExtraHistoricalItemValidator,
  IntakeFormValidator
} from "../../intake/IntakeFormValidator.tsx";

interface TobaccoFormValidatorProps {
  dateOfBirth: Date | undefined;
}

export class TobaccoFormValidator extends IntakeFormValidator {
  constructor(options: TobaccoFormValidatorProps, core: CoreStore) {
    super(options, core);
    if (core.hasPermissions(Permission.CommonIntakeDetailAllowed)) {
      const tobaccoCurrentItemValidator = new ExtraCurrentItemValidator(
        options
      );

      const tobaccoHistoricalItemValidator = new ExtraHistoricalItemValidator(
        options
      );
      this.forArrayField(
        "currentIntakes",
        tobaccoCurrentItemValidator.validate
      );
      this.forArrayField(
        "historicalIntakes",
        tobaccoHistoricalItemValidator.validate
      );
    }
  }
}
