import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import {
  FontWeights,
  Stack,
  Text,
  useResizeElementObserver,
  useTheme
} from "@bps/fluent-ui";
import { unique } from "@bps/utils";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { ReflexDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { SliderField } from "@ui-components/form/SliderField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import {
  DermatomesMyotomesAndReflexesFormValues,
  dermMyotomesAndReflexesNameOf,
  ReflexFieldValues
} from "./DermatomesAndMyotomesForm.types.ts";

export const ReflexesBase: React.FunctionComponent = observer(() => {
  const theme = useTheme();
  const { clinical } = useStores();

  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const form = useForm<DermatomesMyotomesAndReflexesFormValues>();

  const getFactorText = (reflex: ReflexDataItemDto) => {
    if (reflex.strength || reflex.strength === 0) {
      return `${reflex.strength}+`;
    }
    return "-";
  };

  const formatReflex = (
    reflex: ReflexDataItemDto,
    reflexNerves: KeyTextValue[]
  ) =>
    `${reflex.nerve} - ${reflexNerves.find(x => x.key === reflex.nerve)?.text}`;

  const fieldName = (
    fields: ReflexFieldValues[],
    reflex: ReflexFieldValues
  ) => {
    const indexOf = fields.findIndex(
      (x: ReflexFieldValues) =>
        x.nerve === reflex.nerve && x.side === reflex.side
    );

    return `reflexFields[${indexOf}]`;
  };

  const onCheckboxChanged = (
    checked: boolean | undefined,
    reflexes: ReflexFieldValues[]
  ) => {
    const values = form.getState().values;
    const reflexFields = values.reflexFields;

    if (!reflexFields) {
      return;
    }

    reflexes.forEach(x => {
      const index = reflexFields.indexOf(x);
      if (index !== undefined && index !== -1) {
        const updatedField = x;
        updatedField.checked = checked ?? false;

        if (!checked) {
          updatedField.strength = undefined;
        }

        form.mutators.update("reflexFields", index, updatedField);
      }
    });
  };

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const renderVertical = panelWidth < 400;

  return (
    <>
      <div
        ref={r => {
          if (r && !element) {
            setElement(r);
          }
        }}
      />

      <FieldArray name={dermMyotomesAndReflexesNameOf("reflexFields")}>
        {({ fields }) => {
          // Get a distinct list of nerves to build our fields from.
          const uniqueNerves = unique(fields.value.map(x => x.nerve));

          return (
            <Stack
              tokens={{ childrenGap: 8 }}
              styles={{ root: { padding: 8 } }}
            >
              {uniqueNerves.map((nerve, index) => {
                const reflexes = fields.value.filter(x => x.nerve === nerve);

                const firstReflexName = fieldName(fields.value, reflexes[0]);
                const firstReflex = reflexes[0];
                const firstElement = index === 0;

                return (
                  <Stack
                    key={firstReflex.nerve}
                    tokens={{ childrenGap: 8 }}
                    wrap
                    styles={{
                      root: {
                        padding: 8,
                        paddingTop: firstElement ? 0 : 8,
                        paddingBottom: 0,
                        borderTop: firstElement ? "none" : "1px solid",
                        borderTopColor: theme.palette.neutralLight
                      }
                    }}
                  >
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 8 }}
                      styles={{
                        root: { minWidth: 160 }
                      }}
                    >
                      <CheckboxField
                        name={`${firstReflexName}.checked`}
                        automationAttribute="reflexnerve-selected-checkbox"
                        onChange={(ev, checked) => {
                          onCheckboxChanged(checked, reflexes);
                        }}
                        checked={firstReflex.checked}
                      />
                      <Text
                        styles={{
                          root: {
                            fontWeight: firstReflex.checked
                              ? FontWeights.bold
                              : FontWeights.regular
                          }
                        }}
                      >
                        {formatReflex(
                          firstReflex,
                          clinical.ref.reflexNerves.keyTextValues
                        )}
                      </Text>
                    </Stack>
                    {reflexes.map(reflex => {
                      const name = fieldName(fields.value, reflex);

                      return (
                        <FieldCondition
                          key={reflex}
                          when={`${name}.checked`}
                          is={true}
                        >
                          <Stack
                            horizontal={!renderVertical}
                            verticalAlign="center"
                            tokens={{ childrenGap: 8 }}
                          >
                            <Stack
                              verticalAlign="center"
                              styles={{
                                root: { maxWidth: 112 }
                              }}
                            >
                              <SpinNumberInputField
                                parse={value => Number(value)}
                                max={4}
                                min={0}
                                maxDigitsNumber={1}
                                name={`${name}.strength`}
                                fieldItemStyles={{
                                  root: { maxWidth: 112 }
                                }}
                                styles={{ input: { width: 0 } }}
                                prefix={reflex.side}
                              />
                            </Stack>
                            <Stack horizontal verticalAlign="center" grow>
                              <SliderField
                                name={`${name}.strength`}
                                min={0}
                                max={4}
                                snapToStep
                                styles={{ wrapper: { flexGrow: 1 } }}
                              />
                              <Text
                                styles={{
                                  root: {
                                    fontWeight: FontWeights.semibold,
                                    fontSize: 14,
                                    width: 30
                                  }
                                }}
                              >
                                {getFactorText(reflex)}
                              </Text>
                            </Stack>
                          </Stack>
                        </FieldCondition>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          );
        }}
      </FieldArray>
    </>
  );
});

export const Reflexes = withFetch(
  x => [x.clinical.ref.reflexNerves.load()],
  ReflexesBase
);
