import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useContext, useRef } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  Separator,
  Stack,
  TextBadge,
  TextBadgeColor
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { FeeActiveLabel } from "../../labels.ts";
import { FeeFormValues } from "../ScheduleForm.types.tsx";
import { getFeeFormValuesFromDto } from "../utils.ts";
import { FeeFormChangesFields } from "./FeeFormChangesFields.tsx";
import { FeeFormFeeTypeField } from "./FeeFormFeeTypeField.tsx";
import { FeeFormFields } from "./FeeFormFields.tsx";
import { FeeFormNewFeeDetailFields } from "./FeeFormNewFeeDetailFields.tsx";
import { FeeFormEditFeeValidator } from "./validators/FeeFormEditFeeValidator.tsx";
import { FeeFormNewFeeValidator } from "./validators/FeeFormNewFeeValidator.ts";

export const FeeFormDialog: React.FC = observer(() => {
  const {
    feeSchedule: schedule,
    editFee: fee,
    isFeeDialogVisible,
    closeFeeDialog,
    invoiceSettings,
    submitEditFeeValues,
    submitNewFeeValues,
    canEditSchedule
  } = useContext(ScheduleScreenContext);

  const closeAfterSubmit = useRef<boolean>(false);

  const isEditForm = !!fee;

  if (!isFeeDialogVisible) return null;

  const initialValues = getFeeFormValuesFromDto({
    fee,
    settings: invoiceSettings
  });

  const submitValues = (values: FeeFormValues) => {
    if (isEditForm) {
      return submitEditFeeValues(values);
    }

    // if "to" date entered, adjust it by 1 so next instance will start on correct day
    const updatedValues: FeeFormValues = {
      ...values,
      feeNextEffectiveDate: values.feeNextEffectiveDate
        ? DateTime.fromJSDate(values.feeNextEffectiveDate)
            .plus({ days: 1 })
            .toJSDate()
        : undefined
    };
    return submitNewFeeValues(updatedValues, closeAfterSubmit.current);
  };

  const handleSubmit = async (form: FormApi<FeeFormValues>) => {
    closeAfterSubmit.current = true;
    form.submit();
  };

  const handleSubmitAndNewFee = (form: FormApi<FeeFormValues>) => {
    closeAfterSubmit.current = false;
    form.submit()?.then(() => {
      form.restart(getFeeFormValuesFromDto({ settings: invoiceSettings }));
    });
  };

  const buttonsProps = (form: FormApi<FeeFormValues>) => ({
    onSubmit: handleSubmit,
    submitButtonProps: {
      ...dataAttribute(DataAttributes.Element, "fee-form-submit-btn"),
      iconProps: {},
      items: isEditForm
        ? undefined
        : [
            {
              key: "fee-form-submit-and-new-btn",
              text: "Save & add another fee",
              onClick: () => handleSubmitAndNewFee(form)
            }
          ],
      text: "Save fee",
      splitButtonMenuProps: {
        ...dataAttribute(DataAttributes.Element, "fee-form-submit-split-btn")
      }
    },
    cancelButtonProps: {
      ...dataAttribute(DataAttributes.Element, "fee-form-cancel-btn")
    },
    styles: () => ({ root: { padding: 24 } }),
    hideButtonsSeparator: !isEditForm
  });

  const dialogHeading = isEditForm
    ? `Edit fee #${fee?.code}`
    : `New fee in ${schedule?.name}`;

  const isActive = fee?.isActive;

  const badge = fee ? (
    <TextBadge
      {...dataAttribute(DataAttributes.Element, "fee-form-dialog-badge")}
      badgeColor={isActive ? TextBadgeColor.green : TextBadgeColor.grey}
    >
      {isActive ? FeeActiveLabel.active : FeeActiveLabel.inactive}
    </TextBadge>
  ) : null;

  const heading = (
    <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
      <Heading
        variant="modal-heading"
        {...dataAttribute(DataAttributes.Element, "fee-form-dialog-heading")}
      >
        {dialogHeading}
      </Heading>
      {badge}
    </Stack>
  );

  return (
    <DataFetcher fetch={async () => await schedule?.loadServices()}>
      {() => (
        <SubmissionFormDialog
          readOnly={!canEditSchedule}
          dialogName="Fee form dialog"
          onSubmit={submitValues}
          initialValues={initialValues}
          buttonsProps={buttonsProps}
          dialogProps={{
            onDismiss: closeFeeDialog,
            maxWidth: 630,
            minWidth: 630,
            dialogContentProps: {
              showCloseButton: false,
              title: heading,
              styles: { inner: { padding: 0 }, title: { paddingRight: 24 } }
            },
            showTitleSeparator: isEditForm
          }}
          validate={(values: FeeFormValues) => {
            const validator = isEditForm
              ? new FeeFormEditFeeValidator(fee!)
              : new FeeFormNewFeeValidator(schedule);
            return validator.validate(values);
          }}
        >
          {() => (
            <Stack tokens={{ childrenGap: 16 }}>
              <FeeFormFields />
              {isEditForm ? (
                <>
                  <FeeFormFeeTypeField />
                  <Separator />
                  <FeeFormChangesFields />
                </>
              ) : (
                <>
                  <FeeFormNewFeeDetailFields />
                  <Stack
                    horizontal
                    styles={{ root: { justifyContent: "space-between" } }}
                  >
                    <FeeFormFeeTypeField />
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </SubmissionFormDialog>
      )}
    </DataFetcher>
  );
});
