import { FC } from "react";

import { EmailColumnWrapper } from "@modules/practice/screens/address-book/components/contacts-lists/EmailColumnWrapper.tsx";
import { useShowContactDetails } from "@modules/practice/screens/address-book/components/hooks/useShowContactDetails.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const EmailColumn: FC<{ contact: Contact }> = ({ contact }) => {
  const showContactDetails = useShowContactDetails(contact);
  return (
    <EmailColumnWrapper contact={contact}>
      <Navigate onClick={() => showContactDetails()}>Multiple emails</Navigate>
    </EmailColumnWrapper>
  );
};
