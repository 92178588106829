import { FunctionComponent } from "react";

import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DeleteDialog,
  DeleteDialogProps
} from "@ui-components/DeleteDialog.tsx";

export interface DeleteCorrespondenceDialogProps {
  hidden: boolean;
  onConfirm: DeleteDialogProps["onConfirm"];
  onCancel?: () => void;
  item: ClinicalDocument | undefined;
}

const DeleteCorrespondenceDialogBase: FunctionComponent<
  DeleteCorrespondenceDialogProps
> = ({ item, ...rest }) => {
  const { clinical } = useStores();

  return (
    <DeleteDialog
      formName="delete-correspondence"
      {...rest}
      title="Delete correspondence"
      subText={`Are you sure you want to delete ${item?.name}?`}
      options={clinical.ref.reasonForDeleteCorrespondence.keyTextValues}
    />
  );
};

export const DeleteCorrespondenceDialog = withFetch(
  x => [x.clinical.ref.reasonForDeleteCorrespondence.load()],
  DeleteCorrespondenceDialogBase
);
