import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IconButton,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import { Duration, formatCalendarDate } from "@bps/utils";
import {
  EncounterTimerDto,
  TodaysNotes
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Print } from "@ui-components/printing/Print.tsx";
import { PrintContentWrapper } from "@ui-components/printing/PrintContentWrapper.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ClaimConsultBadges } from "../claims/ClaimConsultBadges.tsx";
import { EncounterReasonForVisitText } from "../clinical-form/EncounterReasonForVisitText.tsx";
import { SideNavContent } from "../SideNavContent.tsx";
import { PastVisitAmendments } from "./PastVisitAmendments.tsx";
import { PastVisitCommandBar } from "./PastVisitCommandBar.tsx";
import { PastVisitDetailsStructuredNotes } from "./PastVisitDetailsStructuredNotes.tsx";

interface PastVisitDetailsProps {
  encounter: Encounter;
  todaysNotes: TodaysNotes | undefined;
  clinicalRecord: ClinicalRecord;
}

const MILLISECONDS_IN_MINUTE = 60000;

const getUser = (encounter: Encounter): string => {
  return `${encounter.user ? `${encounter.user.fullName}` : "System"}`;
};

const getCalendarTime = (encounter: Encounter): string => {
  return `${formatCalendarDate(encounter.startDateTime)}`;
};

const getDurationTime = (encounterTimer?: EncounterTimerDto): string => {
  if (!encounterTimer || (encounterTimer && !encounterTimer.elapsedTime)) {
    return "";
  }

  const duration = Duration.fromMillis(encounterTimer.elapsedTime);

  if (duration === 0) {
    return "";
  }

  const lessThanAnHour =
    encounterTimer.elapsedTime < MILLISECONDS_IN_MINUTE * 60;

  const lessThan10Minutes =
    encounterTimer.elapsedTime < MILLISECONDS_IN_MINUTE * 10;

  const lessThanAMinute = encounterTimer.elapsedTime <= MILLISECONDS_IN_MINUTE;

  if (lessThan10Minutes) {
    return ` (${duration.toFormat(lessThanAMinute ? "s 's'" : "m 'mins'")})`;
  }

  return ` (${duration.toFormat(
    lessThanAnHour ? "mm 'mins'" : "hh 'hrs' mm 'mins'"
  )})`;
};

const PastVisitDetailsComponent: FunctionComponent<PastVisitDetailsProps> =
  observer(({ encounter, todaysNotes, clinicalRecord }) => {
    const { clinical, core } = useStores();

    const ToolTipWidth =
      clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize ===
      TreeViewSize.Expanded
        ? "75%"
        : "65%";

    const confidential = !core.hasAccessToSecGroup(encounter.secGroupId);

    const eoc = encounter.episodeOfCareId;

    const condition = clinicalRecord.getConditionByEocId(eoc);

    const amendments = clinical.amendments.filter(
      i => i.encounterId === encounter.id
    );

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    return (
      <Print>
        {print => (
          <>
            {/* Header */}
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <IconButton
                iconProps={{ iconName: "ChevronLeft" }}
                onClick={() => {
                  if (clinical.ui.tabs.currentPatientRecordTab) {
                    clinical.ui.tabs.currentPatientRecordTab!.sidePanelTabId =
                      undefined;
                    clinical.ui.tabs.currentPatientRecordTab!.sidePanelTab =
                      clinical.ui.tabs.currentPatientRecordTab!.previousNavigatedTab;
                  }
                }}
              />

              <TooltipHost
                tooltipProps={{
                  onRenderContent: () => {
                    return (
                      <EncounterReasonForVisitText
                        encounter={encounter}
                        skipOtherText={true}
                      />
                    );
                  }
                }}
                styles={{
                  root: {
                    maxWidth:
                      !isViewOnlyOrDischarged && !confidential
                        ? ToolTipWidth
                        : "100%"
                  }
                }}
              >
                <Heading
                  variant="section-heading"
                  styles={{
                    root: { padding: "5px 0" }
                  }}
                >
                  <EncounterReasonForVisitText
                    encounter={encounter}
                    skipOtherText={true}
                    variant="large"
                    nowrap
                    block
                  />
                </Heading>
              </TooltipHost>

              {!isViewOnlyOrDischarged && !confidential && (
                <Stack grow horizontalAlign="end">
                  <PastVisitCommandBar
                    {...dataAttribute(
                      DataAttributes.Element,
                      "past-visit-command-bar"
                    )}
                    printClick={() => print.print && print.print()}
                    encounter={encounter}
                    clinicalRecord={clinicalRecord}
                  />
                </Stack>
              )}
            </Stack>
            {/* Date, Duration and Provider */}
            <Stack styles={{ root: { paddingLeft: "40px" } }}>
              <Text
                styles={(props, theme) => ({
                  root: {
                    color: theme.palette.neutralSecondary
                  }
                })}
              >
                {getCalendarTime(encounter)}
                <When permission={Permission.EncounterTimerAllowed}>
                  <DataFetcher
                    fetch={() => clinical.getEncounterTimer(encounter.id)}
                    noExceptionsHandlers
                  >
                    {encounterTimer => {
                      return <i>{getDurationTime(encounterTimer)}</i>;
                    }}
                  </DataFetcher>
                </When>
                , {getUser(encounter)}
              </Text>

              {condition && (
                <Stack styles={{ root: { paddingTop: 8 } }}>
                  <ClaimConsultBadges
                    condition={condition}
                    clinicalRecord={clinicalRecord}
                    encounter={encounter}
                    hideConsultOnNoClaim
                    showConsultIndex
                    claimBadgeText={ConditionHelper.getClaimBadgeText(
                      condition
                    )}
                  />
                </Stack>
              )}
            </Stack>
            <SideNavContent>
              <PrintContentWrapper
                hidePrintContent={false}
                contentStyle={{ root: { margin: "0 16px" } }}
              >
                <Stack styles={{ root: { margin: "16px 0" } }}>
                  {/*/ main doctor note /*/}
                  <PastVisitDetailsStructuredNotes todaysNotes={todaysNotes} />
                </Stack>

                <PastVisitAmendments amendments={amendments} />
              </PrintContentWrapper>
            </SideNavContent>
          </>
        )}
      </Print>
    );
  });

export const PastVisitDetails = withFetch(
  ({ clinical }) =>
    clinical.getAmendments(
      clinical.ui.tabs.currentPatientRecordTab?.sidePanelTabId!
    ),
  PastVisitDetailsComponent
);
