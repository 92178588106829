import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export const addXrayParametersFormValuesNameOf =
  nameOfFactory<AddXrayParametersFormValues>();

export const addXrayParametersViewNameOf =
  nameOfFactory<AddXrayParametersView>();
export interface AddXrayParametersFormValues {
  region?: string;
  side?: string;
  views?: AddXrayParametersView[];
}

export interface AddXrayParametersView {
  view?: string;
  areaSize?: string;
  voltage?: string;
  current?: string;
  duration?: string;
  sid?: string;
}

export interface ImagingReportView {
  view?: string;
  parameters: ImagingReportViewParameter[];
}

export interface ImagingReportViewParameter {
  code?: string;
  value?: string;
}

export interface AddXrayParametersDialogProps {
  onDismiss: () => void;
  initialValues?: AddXrayParametersFormValues;
}

export enum ImagingReportViewParameterCode {
  AreaSize = "AREA",
  Voltage = "VOLTAGE",
  Current = "CURRENT",
  Duration = "DURATION",
  SID = "SID"
}

export enum ImagingReportViewParameterName {
  AreaSize = "Area size",
  Voltage = "Voltage",
  Current = "Current",
  Duration = "Duration",
  SID = "S.I.D"
}

export const imagingReportViewParameters = [
  {
    parameter: ImagingReportViewParameterName.AreaSize,
    unit: "cm",
    fieldName: "areaSize"
  },
  {
    parameter: ImagingReportViewParameterName.Voltage,
    unit: "kVp",
    fieldName: "voltage"
  },
  {
    parameter: ImagingReportViewParameterName.Current,
    unit: "mA",
    fieldName: "current"
  },
  {
    parameter: ImagingReportViewParameterName.Duration,
    unit: "sec",
    fieldName: "duration"
  },
  {
    parameter: ImagingReportViewParameterName.SID,
    unit: "cm",
    fieldName: "sid"
  }
];
