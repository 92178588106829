import { observer } from "mobx-react-lite";
import React from "react";

import {
  dataAttribute,
  DataAttributes,
  PresetButtonType,
  Stack
} from "@bps/fluent-ui";
import { MedicalHistoryTestElements } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

import { MedicalHistoryStatus } from "./MedicalHistory.types.ts";
import {
  defaultFilters,
  MedicalHistoryFilterProps,
  MedicalHistoryFilterType
} from "./MedicalHistoryFilter.types.ts";

export const MedicalHistoryFilter: React.FC<MedicalHistoryFilterProps> =
  observer(({ children, isExistConfidentialHx }) => {
    const { clinical, core } = useStores();

    const statusOptions = [
      {
        key: MedicalHistoryStatus.Active,
        text: capitalizeSentence(MedicalHistoryStatus.Active)
      },
      {
        key: MedicalHistoryStatus.Inactive,
        text: capitalizeSentence(MedicalHistoryStatus.Inactive)
      }
    ];

    const typeOptions = [
      { key: "diagnosis", text: "Diagnosis" },
      { key: "procedure", text: "Procedure" }
    ];

    const sidePanelSize =
      clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize;

    const presets: PresetButtonType<MedicalHistoryFilterType>[] = [
      {
        text: "Clinically significant",
        name: "clinicallySignificant",
        iconName: "UserOptional",
        tooltip: "Show clinically significant entries only",
        tooltipPresses: "Showing clinically significant entries only"
      }
    ];

    if (
      core.hasPermissions(Permission.ConfidentialDataAllowed) &&
      isExistConfidentialHx
    ) {
      presets.push({
        name: "confidential",
        iconName: "ReportHacked",
        tooltip: "Show confidential",
        tooltipPresses: "Hide confidential",
        tooltipOnDisabled: "No confidential items"
      });
    }

    return (
      <Stack grow>
        <FilterBar<MedicalHistoryFilterType>
          children={children}
          initialValues={defaultFilters}
          items={[
            {
              name: "name",
              type: "searchBox",
              stickItem: true,
              props: {
                ...dataAttribute(
                  DataAttributes.Element,
                  MedicalHistoryTestElements.NameFilter
                ),
                autoFocus: true,
                placeholder: "Search details",
                styles: { root: { maxWidth: "150px", flexGrow: 1 } }
              }
            },
            {
              type: "optionsSelect",
              name: "status",
              props: {
                ...dataAttribute(
                  DataAttributes.Element,
                  MedicalHistoryTestElements.StatusDropdown
                ),
                placeholder: "Status",
                label: "Status",
                options: statusOptions,
                multiSelect: false,
                hideSearchOption: true,
                calloutWidth: 96
              }
            },
            {
              type: "optionsSelect",
              name: "type",
              props: {
                ...dataAttribute(
                  DataAttributes.Element,
                  MedicalHistoryTestElements.TypeDropdown
                ),
                placeholder: "Type",
                label: "Type",
                options: typeOptions,
                hideSearchOption: true,
                calloutWidth: 96
              }
            }
          ]}
          presets={presets}
          styles={{
            calloutItem: {
              ":nth-child(1)": { width: "100%" },
              ":nth-child(2)": { width: "100%" },
              ":nth-child(3)": { width: "100%" }
            },
            callout: {
              width: 288
            }
          }}
          shrinkVersion={sidePanelSize === TreeViewSize.Default}
        />
      </Stack>
    );
  });
