import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { FormSpy } from "react-final-form";

import { useDebounce } from "@bps/utils";

import { ClinicalRecordFormsValuesSpyProps } from "./ClinicalRecordFormsValuesSpy.types.ts";

export const ClinicalFormValuesSpy = observer(
  ({
    tab,
    originalInitialValues,
    clinicalRecord,
    skipTabHighlighting
  }: ClinicalRecordFormsValuesSpyProps) => {
    useEffect(() => {
      if (originalInitialValues) {
        clinicalRecord.clinicalRecordFormsValuesStash.init(
          tab,
          originalInitialValues
        );
      }
    }, [
      clinicalRecord.clinicalRecordFormsValuesStash,
      originalInitialValues,
      tab
    ]);

    const persistFormValues = (values: Record<string, any>) => {
      if (clinicalRecord.clinicalRecordFormsValuesStash) {
        clinicalRecord.clinicalRecordFormsValuesStash.setPersistedValues(
          {
            [tab]: values
          },
          skipTabHighlighting
        );
      }
    };

    const persistFormData = useDebounce(persistFormValues);

    return (
      <FormSpy
        subscription={{
          dirty: true,
          values: true,
          modified: true
        }}
        onChange={({ dirty, values, modified }) => {
          const hasBeenModified =
            modified && Object.values(modified).some(i => i);

          if (dirty || hasBeenModified) {
            persistFormData(values);
          }
        }}
      />
    );
  }
);
