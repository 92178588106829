import { useContext } from "react";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { DropzoneSectionAzureBlob } from "../../shared-components/DropzoneSectionAzureBlob.tsx";

export const UploadButtons = () => {
  const {
    setSelectedInboxDocument,
    setDropzoneEventInProgress,
    setUploadFileError
  } = useContext(InboxScreenContext);

  return (
    <DropzoneSectionAzureBlob
      isButton
      onDropRejected={setUploadFileError}
      onDropStarted={() => {
        setSelectedInboxDocument(undefined);
        setDropzoneEventInProgress(true);
      }}
      onDropFinished={() => {
        setDropzoneEventInProgress(false);
      }}
    />
  );
};
