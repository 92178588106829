import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

import {
  IDetailsRowProps,
  ScrollablePane,
  SelectionMode,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { InvoiceItemDescriptionCell } from "@modules/billing/screens/account/components/transaction-list/InvoiceItemDescriptionCell.tsx";
import { InvoiceItemValueCell } from "@modules/billing/screens/account/components/transaction-list/InvoiceItemValueCell.tsx";
import { TransactionProvider } from "@modules/billing/screens/account/components/TransactionProvider.tsx";
import { BillingListNoDataTile } from "@modules/billing/screens/billing-history/components/BillingListNoDataTile.tsx";
import { useTransactionFilterContext } from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/context/TransactionFilterContext.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import {
  InfiniteScrollListColumn,
  InfiniteScrollListSortProps
} from "@ui-components/InfiniteScrollList/InfiniteScrollList.types.ts";

import { AccInvoiceCalendarEventCell } from "./AccInvoiceCalendarEventCell.tsx";
import { AccInvoicesTransactionAllocationCell } from "./AccInvoiceTransactionAllocationCell.tsx";
import { AccInvoicesFilterQuery } from "./AccInvoiceTransactionsListFilter.types.ts";
import { InvoiceRow } from "./InvoiceRow.tsx";
import {
  appointmentColumn,
  claimColumn,
  commentColumn,
  descriptionColumn,
  invoiceColumn,
  patientColumn,
  paymentColumn,
  providerColumn,
  servicedColumn,
  SortableColumnIds,
  stateColumn,
  updatedColumn,
  valueColumn
} from "./InvoiceRowColumns.ts";
import { ReasonCell } from "./ReasonCell.tsx";
import { TransactionClaimCell } from "./TransactionClaimCell.tsx";
import { TransactionInvoiceCell } from "./TransactionInvoiceCell.tsx";
import { TransactionPatientCell } from "./TransactionPatientCell.tsx";
import { TransactionTransmissionStatusCell } from "./TransactionTransmissionStatusCell.tsx";

export interface IInvoiceRow {
  id: string;
  invoice: Invoice;
  invoiceItem: InvoiceItemDto;
  isFirstItem: boolean;
}

const defaultSort: InfiniteScrollListSortProps = {
  sortColumn: SortableColumnIds.invoiceNumber,
  sortDescending: true
};

const changedDateSort: InfiniteScrollListSortProps = {
  sortColumn: SortableColumnIds.accChangedDate,
  sortDescending: true
};

export const AccInvoiceTransactionsList: FunctionComponent = observer(() => {
  const { billing, routing, core } = useStores();

  const {
    selection,
    setTotalInvoices,
    search,
    subscribeToAccScheduleChanges,
    unsubscribeToAccScheduleChanges
  } = useContext(AccInvoicesListContext);

  const { getTransactionArgs } = useTransactionFilterContext();

  // Sometimes canSelectItem gets called with an Invoice, sometimes this function
  //  gets called with an IInvoiceRow (created in onRenderRow).  In order for
  //  the checkbox to work this function has to return the correct result in
  //  both scenarios.  Not happy with this solution but seems good enough for now.
  useEffect(() => {
    selection.canSelectItem = (item: any) =>
      !!item?.owing || !!item?.invoice?.owing;
  }, [selection]);

  useEffect(() => {
    subscribeToAccScheduleChanges();
    return unsubscribeToAccScheduleChanges;
  }, [subscribeToAccScheduleChanges, unsubscribeToAccScheduleChanges]);

  const searchQuery = routing.location.search;

  const { actionRequired, hasStatusReason }: AccInvoicesFilterQuery =
    searchQuery ? parse(routing.location.search) : {};

  const initialSort =
    actionRequired || hasStatusReason ? changedDateSort : defaultSort;

  const [sort, setSort] = useState<InfiniteScrollListSortProps>(initialSort);

  useEffect(() => {
    if (actionRequired || hasStatusReason) {
      setSort(changedDateSort);
    } else {
      setSort(defaultSort);
    }
  }, [actionRequired, hasStatusReason]);

  const onSearch = useCallback(
    async (query: PagingOptions) => {
      const result = await search(getTransactionArgs(query));
      setTotalInvoices(result);
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, setTotalInvoices, searchQuery]
  );

  const onRenderRow = (
    props: IDetailsRowProps | undefined,
    defaultRender:
      | ((props?: IDetailsRowProps | undefined) => JSX.Element | null)
      | undefined
  ) => {
    if (!props || !defaultRender) {
      return null;
    }

    const invoice = props.item as Invoice;

    return (
      <InvoiceRow
        props={props}
        defaultRender={defaultRender}
        invoice={invoice}
      />
    );
  };

  const columnArr: InfiniteScrollListColumn[] = [
    {
      ...invoiceColumn,
      onRender: (props: IInvoiceRow) => <TransactionInvoiceCell {...props} />
    },
    {
      ...stateColumn,
      minWidth: 110,
      maxWidth: 110,
      onRender: (props: IInvoiceRow) => {
        if (props.isFirstItem) {
          if (props.invoice.accSchedule) {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                styles={{ root: { marginTop: -4, marginBottom: -6 } }}
              >
                {/* 110 is the max width of the badge */}
                <Text nowrap styles={{ root: { width: 110 } }}>
                  <TransactionTransmissionStatusCell
                    accSchedule={props.invoice.accSchedule}
                    showPartPaidIcon={
                      !!props.invoice.paid && props.invoice.hasACCComment
                    }
                  />
                </Text>
              </Stack>
            );
          }
        }

        return "";
      }
    },
    {
      ...updatedColumn,
      onRender: (props: IInvoiceRow) => {
        if (props.invoice.accSchedule?.accChangedDate) {
          return (
            <>
              {DateTime.fromISO(
                props.invoice.accSchedule?.accChangedDate
              ).toDayDefaultFormat()}
            </>
          );
        }
        return "";
      }
    },
    {
      ...paymentColumn,
      minWidth: 70,
      maxWidth: 70,
      onRender: (props: IInvoiceRow) => (
        <AccInvoicesTransactionAllocationCell {...props} />
      )
    },
    {
      ...commentColumn,
      onRender: (props: IInvoiceRow) => <ReasonCell {...props} />
    },
    {
      ...valueColumn,
      onRender: (props: IInvoiceRow) => (
        <InvoiceItemValueCell amount={props.invoiceItem.amount} />
      )
    },
    {
      ...descriptionColumn,
      onRender: (props: IInvoiceRow) => (
        <InvoiceItemDescriptionCell invoiceItem={props.invoiceItem} />
      )
    },
    {
      ...servicedColumn,
      onRender: (props: IInvoiceRow) => (
        <>
          {DateTime.fromISO(props.invoiceItem.serviceDate).toDayDefaultFormat()}
        </>
      )
    },
    {
      ...patientColumn,
      minWidth: 167,
      maxWidth: 212,
      onRender: (props: IInvoiceRow) => <TransactionPatientCell {...props} />
    },
    {
      ...claimColumn,
      onRender: (props: IInvoiceRow) =>
        props.isFirstItem ? <TransactionClaimCell {...props} /> : null
    },
    {
      ...appointmentColumn,
      onRender: (props: IInvoiceRow) =>
        props.isFirstItem ? <AccInvoiceCalendarEventCell {...props} /> : null
    },
    {
      ...providerColumn,
      minWidth: 167,
      maxWidth: 212,
      onRender: (props: IInvoiceRow) => {
        if (props.isFirstItem) {
          return <TransactionProvider {...props} />;
        }
        return null;
      }
    }
  ];

  return (
    <div style={{ position: "relative", flexGrow: 1 }}>
      <ScrollablePane>
        <InfiniteScrollList
          setKey="acc-invoices-list"
          getItems={onSearch}
          onRenderNoResults={() => <BillingListNoDataTile />}
          refreshKey={billing.ui.lastUpdatedInvoiceETag}
          onRenderRow={onRenderRow}
          selectionMode={SelectionMode.multiple}
          selection={selection}
          displayDefaultCheckBox
          checkboxAutomationAttribute="acc-invoice-transaction-item-checkbox"
          sort={sort}
          onSort={setSort}
          columns={
            core.hasPermissions(Permission.AppointmentTypeRead)
              ? columnArr
              : columnArr.filter(column => column.key !== "appointment")
          }
        />
      </ScrollablePane>
    </div>
  );
});
