import { FormEvent, useEffect, useRef } from "react";
import { useField, useForm } from "react-final-form";

import {
  ActionButton,
  Dropdown,
  FontIcon,
  Grid,
  IColumn,
  IconButton,
  IDragDropEvents,
  IDropdownOption,
  Separator,
  Stack,
  Text
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { FormDesignFieldGroup } from "@shared-types/form-design/form-design-field-group.interface.ts";
import { FormDesignValues } from "@shared-types/form-design/form-design-form-values.interface.ts";
import { FormDesignGroupEntityValues } from "@shared-types/form-design/form-design-group-entity-values.interface.ts";
import { FormSubmitWarningMessage } from "@ui-components/form/submission-form/FormSubmitWarningMessage.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { useScrollToViewById } from "@ui-components/hooks/useScrollToViewById.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { getUxDomainName } from "../FormDesign.utils.ts";
import { FormDesignGroupFields } from "./FormDesignGroupFields.tsx";

const ADD_FIELD_GROUP_ID = "add-field-group-action-button";

export const FormDesignGroupList = () => {
  const nameOf = nameOfFactory<FormDesignValues>();
  const { mutators, getFieldState } = useForm<FormDesignValues>();

  const dragStartIndex = useRef<number>(0);

  const {
    input: { value: domainGroups }
  } = useField<Record<string, FormDesignGroupEntityValues>>(
    nameOf("domainGroups"),
    {
      subscription: { value: true }
    }
  );

  const { fields: _usedGroups } = useFieldArray<FormDesignFieldGroup>(
    nameOf("usedGroups")
  );

  // TODO - we're supposed to use useFieldArray instead of useField for arrays in forms
  //  please replace the useField below when suitable

  const {
    input: { value: usedGroups }
  } = useField<FormDesignFieldGroup[]>(nameOf("usedGroups"), {
    subscription: { value: true }
  });

  const lastGroup = usedGroups[usedGroups.length - 1];

  const scrollTo = useScrollToViewById({ behavior: "instant" }, 0);

  useEffect(() => {
    scrollTo(ADD_FIELD_GROUP_ID);
  }, [lastGroup, scrollTo]);

  const handleDropped = (insertIndex: number) => {
    if (dragStartIndex.current !== insertIndex) {
      _usedGroups.move(dragStartIndex.current, insertIndex);
    }
  };

  const columns: IColumn[] = [
    {
      name: "",
      key: "field",
      minWidth: 500,
      maxWidth: 710,
      onRender: (item: FormDesignFieldGroup | undefined, index: number) => {
        if (!item) {
          return null;
        }

        const domainOptions: IDropdownOption<FormDesignGroupEntityValues>[] =
          Object.keys(domainGroups)?.map(x => {
            const g = domainGroups[x];
            return { key: g.domainEntity, text: g.domainEntity, data: g };
          });

        const filteredDomainOptions = domainOptions
          .filter(({ data }) => {
            const available = data?.groups.filter(
              x =>
                x.field?.allowMultipleOnSameForm ||
                !usedGroups.find(
                  (used, i) =>
                    i !== index &&
                    used.field?.groupPropertyPath === x.field?.groupPropertyPath
                )
            );
            return !!available && available.length > 0;
          })
          .map(option => ({
            ...option,
            text: getUxDomainName(option.text)
          }));

        const groupOptions: IDropdownOption<FormDesignFieldGroup>[] =
          item?.domain?.groups?.map(x => ({
            key: x?.field?.groupPropertyPath ?? "",
            text: x?.field?.groupTitle ?? "",
            data: x
          })) ?? [];

        const filteredGroupOptions = groupOptions.filter(
          x =>
            x.data?.field?.allowMultipleOnSameForm ||
            !usedGroups.find(
              (used, i) =>
                i !== index && used.field?.groupPropertyPath === x.key
            )
        );

        const onGroupSelect = (
          _e: FormEvent<HTMLDivElement>,
          field: IDropdownOption<FormDesignFieldGroup>
        ) => {
          const newField: FormDesignFieldGroup = {
            ...usedGroups[index],
            field: field.data?.field,
            collapsed: false,
            fieldOptions: field.data?.fieldOptions
              ? [...field.data?.fieldOptions]
              : []
          };

          const fieldOptions = newField.fieldOptions[0];
          if (fieldOptions) {
            if (fieldOptions.userCanChangeLabel) {
              fieldOptions.label = "";
            }
            fieldOptions.formChoices = field.data?.fieldOptions[0]
              .userCanAddChoices
              ? [
                  { key: newGuid(), text: "" },
                  { key: newGuid(), text: "" }
                ]
              : undefined;
            if (field.data?.fieldOptions[0].userCanSetMinMax) {
              fieldOptions.min = "0";
              fieldOptions.max = "10";
              fieldOptions.step = "1";
            }
            if (field.data?.fieldOptions[0].userCanSetMaxChoices) {
              fieldOptions.maxChoices = 0;
            }
          }
          field && mutators.update("usedGroups", index, newField);
        };

        return (
          <Grid
            templateColumns="24px 210px 240px 32px 32px 32px"
            styles={{ root: { columnGap: "4px", rowGap: "8px" } }}
          >
            <Stack
              horizontal
              tokens={{ childrenGap: 8 }}
              styles={{ root: { marginTop: 8 } }}
            >
              <FontIcon iconName="Move" styles={{ root: { fontSize: 16 } }} />
            </Stack>
            <Dropdown
              name={`usedGroups[${index}].domainName`}
              options={filteredDomainOptions}
              placeholder="Please select..."
              onChange={(
                e,
                option: IDropdownOption<FormDesignGroupEntityValues>
              ) => {
                const domain = domainGroups[option.data?.domainEntity || ""];
                mutators.update(nameOf("usedGroups"), index, {
                  ...usedGroups[index],
                  domain,
                  field: null,
                  fieldOptions: []
                });
              }}
              selectedKey={item.domain?.domainEntity}
            />
            <Stack styles={{ root: { gridColumn: "span 2" } }}>
              <Dropdown
                name={`usedGroups[${index}].groupPropertyPath`}
                selectedKey={item.field?.groupPropertyPath}
                options={filteredGroupOptions}
                onChange={onGroupSelect}
                disabled={!item.domain || !item.domain.domain.length}
                placeholder="Please select..."
              />
            </Stack>
            <IconButton
              disabled={!item.field}
              onClick={() => {
                mutators.update(nameOf("usedGroups"), index, {
                  ...item,
                  collapsed: !item.collapsed
                });
              }}
              iconProps={{
                iconName: item.collapsed
                  ? "DoubleChevronUp"
                  : "DoubleChevronDown"
              }}
            />
            <IconButton
              iconProps={{ iconName: "Delete" }}
              disabled={usedGroups.length <= 1}
              onClick={() => {
                mutators.remove(nameOf("usedGroups"), index);
              }}
            />
            {!item.collapsed && item.field && item.fieldOptions
              ? item.fieldOptions.map((x, i) => (
                  <FormDesignGroupFields
                    key={`${item.domain?.domainEntity}.${x.name || x.label}`}
                    field={item}
                    groupIndex={index}
                    fieldIndex={i}
                  />
                ))
              : !item.collapsed &&
                item.field && (
                  <FormDesignGroupFields
                    field={item}
                    groupIndex={index}
                    fieldIndex={0}
                  />
                )}
            <FormSubmitWarningMessage
              warning={getFieldState("usedGroups")?.error?.[index]?.field}
              warningMessageBarProps={{
                messageBarIconProps: { iconName: "Warning" },
                styles: { root: { marginTop: 8, gridColumn: "span 6" } }
              }}
            />
          </Grid>
        );
      }
    }
  ];

  const dragDropEvents: IDragDropEvents = {
    canDrop: () => true,
    canDrag: () => true,
    onDrop: droppedAtItem => {
      const index = usedGroups.indexOf(droppedAtItem);
      handleDropped(index);
    },
    onDragStart: (_item, index) => {
      dragStartIndex.current = index ?? 0;
    },
    onDragEnd: () => {}
  };

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Stack
        styles={{
          root: {
            height: "100%",
            width: "100%"
          }
        }}
      >
        <Stack
          styles={{
            root: {
              padding: "8px",
              overflowX: "hidden",
              height: "100%"
            }
          }}
        >
          <Grid
            templateColumns="234px 1fr"
            styles={{ root: { columnGap: "4px", margin: "8px 0 8px 0" } }}
          >
            <Text bold styles={{ root: { marginLeft: 34 } }}>
              Subject
            </Text>
            <Text bold styles={{ root: { marginLeft: 8 } }}>
              Field
            </Text>
          </Grid>
          <Separator styles={{ root: { height: 2, padding: 0 } }} />
          <ShimmeredDetailsList
            dragDropEvents={dragDropEvents}
            columns={columns}
            items={usedGroups}
            detailsListStyles={{
              root: {
                overflow: "hidden"
              },
              headerWrapper: { display: "none" }
            }}
            styles={{
              root: {
                contentWrapper: { overflow: "hidden" }
              }
            }}
            cellStyleProps={{
              cellLeftPadding: 4,
              cellRightPadding: 4,
              cellExtraRightPadding: 0
            }}
          />
        </Stack>
      </Stack>
      <ActionButton
        id={ADD_FIELD_GROUP_ID}
        onClick={() => {
          mutators.push(nameOf("usedGroups"), {
            domain: {
              domain: "",
              domainEntity: "",
              groups: [],
              context: []
            },
            domainName: "",
            fieldOptions: []
          });
        }}
        text="Add another"
        iconProps={{ iconName: "Add" }}
        styles={{ root: { width: "fit-content" } }}
      />
    </Stack>
  );
};
