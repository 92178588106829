import { observer } from "mobx-react-lite";

import { useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import { ConsultationNotesSettingsFormFields } from "./ConsultationNotesSettingsFormFields.tsx";
import { UserClinicalViewFormValues } from "./UserClinicalViewForm.types.ts";

interface ConsultationNotesSettingsFromProps {
  initialValues: Partial<UserClinicalViewFormValues>;
  onSubmit: (values: UserClinicalViewFormValues) => void;
}

const ConsultationNotesSettingsFormBase: React.FC<ConsultationNotesSettingsFromProps> =
  observer(({ initialValues, onSubmit }) => {
    const { notification } = useStores();

    const theme = useTheme();
    const { formFooter } = getUserStylesSet(theme);

    return (
      <SubmissionForm
        formName="consultation-notes-settings"
        styles={{ main: { borderRadius: 4 } }}
        initialValues={initialValues}
        onSubmitSucceeded={() => {
          notification.success(
            "Clinical settings consultation notes has been updated"
          );
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {() => <ConsultationNotesSettingsFormFields />}
      </SubmissionForm>
    );
  });

export const ConsultationNotesSettingsForm = withFetch(
  x => [x.userExperience.ref.dockViews.load()],
  ConsultationNotesSettingsFormBase
);
