import { useEffect } from "react";

import { DateTime } from "@bps/utils";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";

export const useNextAvailableAppointment = () => {
  const trackEvent = useAppTrackEvent(
    AppInsightsEventNames.nextAvailableApptCount
  );

  useEffect(() => {
    trackEvent({
      date: DateTime.now().toISODate()
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
