import { FunctionComponent } from "react";

import { ITag } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  StaticTagPickerField,
  StaticTagPickerFieldProps
} from "@ui-components/form/StaticTagPickerField.tsx";

import { professionalRelationships } from "../../../constants/professional-relationships.constant.ts";

interface RelationshipStaticTagPickerProps
  extends Omit<StaticTagPickerFieldProps, "fetchDataSource"> {}

export const excludeItemsFilter = (
  items: ITag[],
  selectedItemKeys: string[]
): ITag[] =>
  items.filter(
    (item: ITag & { reverseRelationshipTypeCode: string }) =>
      !(
        selectedItemKeys.includes(item.key as string) ||
        selectedItemKeys.includes(item.reverseRelationshipTypeCode)
      )
  );
const RelationshipStaticTagPickerBase: FunctionComponent<
  RelationshipStaticTagPickerProps
> = ({ ...props }): JSX.Element => {
  const {
    practice: { ref }
  } = useStores();

  const fetchDataSource = () => {
    return ref.relationshipTypes.keyNameValuesWithReverseRelation.filter(rt =>
      professionalRelationships.includes(rt.key)
    );
  };

  return (
    <StaticTagPickerField
      {...props}
      fetchDataSource={fetchDataSource}
      excludeItemsFilter={excludeItemsFilter}
    />
  );
};

export const RelationshipStaticTagPicker = withFetch(
  x => x.practice.ref.relationshipTypes.load(),
  RelationshipStaticTagPickerBase
);
