import { newGuid } from "@bps/utils";
import { CustomToolTrendAnswers } from "@shared-types/clinical/custom-tool-trend-answers.interface.ts";

export const getEmptyTool = (currentNumber: number): CustomToolTrendAnswers => {
  const newToolIndex = currentNumber === 0 ? 0 : currentNumber - 1;

  return {
    contextId: newGuid(),
    questionId: `q${newToolIndex}`,
    name: "",
    isReadOnly: true,
    thisEncounterResult: undefined
  };
};
