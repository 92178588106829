import { FunctionComponent } from "react";

import { ScrollablePane } from "@bps/fluent-ui";

import { AppointmentTypeList } from "./components/AppointmentTypeList.tsx";
import { OnlineAppointmentTypeFilter } from "./components/OnlineAppointmentTypeFilter.tsx";

export const OnlineAppointmentTypeFields: FunctionComponent = () => {
  return (
    <OnlineAppointmentTypeFilter>
      {() => (
        <ScrollablePane
          styles={{
            root: {
              height: "100%",
              padding: "25%",
              position: "relative"
            }
          }}
        >
          <AppointmentTypeList isOnlineAppointments={true} />
        </ScrollablePane>
      )}
    </OnlineAppointmentTypeFilter>
  );
};
