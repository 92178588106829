import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { DefaultButton, Dialog, Stack } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const EncounterFinalisedElsewhereDialog: FunctionComponent = observer(
  () => {
    const { clinical } = useStores();

    const { recentlyFinalisedEncounterId, encounterMap } = clinical;

    const { clinicalRecord, isViewOnly } = usePatientRecordScreenContext();

    const onClick = () => {
      clinicalRecord.close(encounter?.id);
      runInAction(() => {
        clinical.recentlyFinalisedEncounterId = undefined;
      });
    };

    const encounter = recentlyFinalisedEncounterId
      ? encounterMap.get(recentlyFinalisedEncounterId)
      : undefined;

    const visible =
      recentlyFinalisedEncounterId === clinicalRecord.currentEncounterId &&
      !isViewOnly &&
      !clinicalRecord.finalisedByClient;

    return (
      <Dialog
        minWidth={600}
        hidden={!visible}
        dialogContentProps={{ title: "Finalised elsewhere" }}
      >
        <Stack tokens={{ childrenGap: 16 }} horizontalAlign="space-between">
          <Stack.Item>This encounter has been finalised elsewhere.</Stack.Item>
          <DefaultButton onClick={onClick}>Close</DefaultButton>
        </Stack>
      </Dialog>
    );
  }
);
