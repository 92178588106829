import { useState } from "react";

import { FontIcon, FontSizes, mergeStyles, useTheme } from "@bps/fluent-ui";

interface FavouriteStarProps {
  filled: boolean;
  onClick: (isFilled: boolean) => void;
}

export const FavouriteStar: React.FC<FavouriteStarProps> = ({
  filled,
  onClick
}) => {
  const theme = useTheme();
  const [isFilled, setFilled] = useState<boolean>(filled);
  return (
    <FontIcon
      iconName={isFilled ? "FavoriteStarFill" : "FavoriteStar"}
      className={mergeStyles({
        fontSize: FontSizes.size20,
        color: isFilled ? theme.palette.yellow : undefined
      })}
      onClick={() => {
        onClick(isFilled);
        setFilled(!isFilled);
      }}
    />
  );
};
