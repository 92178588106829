import { routes } from "@libs/routing/routes.ts";
import { Refund } from "@stores/billing/models/Refund.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { RefundView } from "./components/RefundView.tsx";

export const RefundViewScreen = () => {
  const { routing } = useStores();
  const id = routes.accounts.refund.match(routing.location.pathname)?.params.id;

  if (!id) {
    return null;
  }

  return (
    <DataFetcher<Refund> fetch={({ billing }) => billing.getRefund(id)}>
      {(refund: Refund) => <RefundView refund={refund} />}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default RefundViewScreen;
