export enum ProviderAccContractsFormLabels {
  handTherapy = "hand therapy",
  accProviderTypeAndContract = "ACC provider type & contract",
  noProviderType = "No applicable provider business roles available",
  occupationalTherapy = "occupational therapy",
  providerType = "provider type",
  addMore = "Add more",
  physio = "physio",
  addA = "Add a",
  orA = "or a",
  add = "Add"
}

export const NO_ACC_CONTRACT = "-NoAccContract";
