import {
  maxLength,
  required,
  todayOrLater
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DischargeFormValues } from "../../discharge-form/DischargeForm.types.ts";

export class DischargeReferralFollowUpValidator extends Validator<DischargeFormValues> {
  constructor() {
    super();
    this.forField("followUpNotes", [maxLength(2000)]);
    this.forField("followUpDueDate", todayOrLater);
    this.forField("dischargeDate", required());
    this.forField("discharger", required());
    this.forField("dischargeOutcome", required());
  }
}
