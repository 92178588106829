import React, { FunctionComponent, memo } from "react";

import {
  IFontStyles,
  IStackStyles,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, isGUID } from "@bps/utils";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface RecordUpdateCheckedLogProps {
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  checkedBy?: string;
  checkedDate?: string;
  styles?: IStackStyles;
  fontSize?: keyof IFontStyles;
  detailedDateTime?: boolean;
  showCreatedBy?: boolean;
  showUpdatedBy?: boolean;
  showNameOnly?: boolean;
}

export const RecordUpdateCheckedLog: FunctionComponent<RecordUpdateCheckedLogProps> =
  memo(
    ({
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      checkedBy,
      checkedDate,
      styles,
      fontSize = "medium",
      showCreatedBy = true,
      showUpdatedBy = true,
      showNameOnly = false
    }) => {
      const { core } = useStores();
      const theme = useTheme();
      const dateFormat = (dateString: string) => {
        if (!dateString) return "";

        const date = DateTime.fromISO(dateString);
        return `${date
          .toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)
          .toLocaleLowerCase()}`;
      };

      const getUser = async (user: string) => {
        if (!user) return undefined;
        if (user === User.System) return user;
        return isGUID(user)
          ? (await core.getUser(user)).fullName
          : (await core.getUserByUserName(user)).fullName;
      };

      const displayName = (
        user: string | undefined,
        label: string,
        date: string
      ) => {
        if (showNameOnly) {
          return <Text variant={fontSize}>{user}</Text>;
        }
        return (
          <Stack horizontal tokens={{ childrenGap: 4 }} wrap>
            <Text
              variant={fontSize}
              styles={{ root: { color: theme.palette.neutralPrimary } }}
            >
              {label} <strong>{dateFormat(date)}</strong>
            </Text>
            <Text
              variant={fontSize}
              styles={{ root: { color: theme.palette.neutralPrimary } }}
            >
              by <strong>{user}</strong>
            </Text>
          </Stack>
        );
      };

      return (
        <DataFetcher<{
          createdByUser: string | undefined;
          updatedByUser: string | undefined;
          checkedByUser: string | undefined;
        }>
          fetch={async () => {
            const [createdByUser, updatedByUser, checkedByUser] =
              await Promise.all([
                createdBy ? getUser(createdBy) : undefined,
                updatedBy ? getUser(updatedBy) : undefined,
                checkedBy ? getUser(checkedBy) : undefined
              ]);
            return { createdByUser, updatedByUser, checkedByUser };
          }}
          fallback={<Spinner />}
        >
          {({ createdByUser, updatedByUser, checkedByUser }) => (
            <Stack styles={styles} tokens={{ childrenGap: 4 }}>
              {createdByUser &&
                showCreatedBy &&
                displayName(createdByUser, "Recorded", createdDate || "")}
              {updatedByUser &&
                showUpdatedBy &&
                displayName(updatedByUser, "Updated", updatedDate || "")}
              {checkedByUser &&
                displayName(checkedByUser, "Checked", checkedDate || "")}
            </Stack>
          )}
        </DataFetcher>
      );
    }
  );
