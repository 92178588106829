import {
  Length,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { GoalValidator } from "../../../SOTAP/validators/GoalCardValidator.tsx";
import { TreatmentValidator } from "../../../SOTAP/validators/TreatmentAndEducationCardValidator.tsx";
import { ManagementFormValues } from "../ManagementForm.Types.ts";

const OTHER = "OTH";
export class ManagementFormValidator extends Validator<ManagementFormValues> {
  constructor() {
    super();
    const goalValidator = new GoalValidator();
    const treatmentValidator = new TreatmentValidator();
    this.forArrayField("planTreatments", treatmentValidator.validate);
    this.forArrayField("goals", goalValidator.validate);
    this.forField("planEducationComment", [
      predicate(
        (
          value,
          parent: ManagementFormValues,
          allValues?: ManagementFormValues
        ) => {
          if (!allValues || !allValues.planEducationOptions) return false;

          return allValues.planEducationOptions.includes(OTHER);
        },
        required()
      ),
      maxLength(Length.long)
    ]);
    this.forField("planOtherEducationComment", maxLength(Length.long));
    this.forField("planReview", maxLength(Length.long));
    this.forField("planOtherTreatments", maxLength(Length.long));
  }
}
