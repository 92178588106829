import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { dataAttribute, DataAttributes, Heading } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ExportPatientRecordModalFormFields } from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecordModalFormFields.tsx";
import { ExportPatientRecordFormValues } from "@shared-types/practice/export-patient-record-form-values.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ExportPatientRecordContext } from "./context/ExportPatientRecordContext.ts";

export const ExportPatientRecordModal = observer(() => {
  const { practice, userExperience, clinical } = useStores();

  const {
    masterPatient,
    setShowExportPatientRecordModal,
    setShowExportPatientRecordViewerDialog,
    setExportRecordValues,
    setExportRecordEncounters
  } = practice.ui;

  const { getExportPatientDocument } = useContext(ExportPatientRecordContext);
  const { localisedConfig } = userExperience;
  const patientLabel = localisedConfig("patientDisplay");

  const patientId = masterPatient?.id;
  const getEncounters = async (values: ExportPatientRecordFormValues) => {
    if (patientId) {
      if (values.startDate && values.endDate) {
        return await clinical.getEncounters({
          patientId,
          dateTo: DateTime.jsDateToISODate(values.endDate),
          dateFrom: DateTime.jsDateToISODate(values.startDate)
        });
      } else {
        return await clinical.getEncounters({ patientId });
      }
    }
    return [];
  };

  const closeModal = () => setShowExportPatientRecordModal(false);
  const onSubmit = async (values: ExportPatientRecordFormValues) => {
    setExportRecordValues(values);

    const encounters = await getEncounters(values);
    setExportRecordEncounters(encounters);

    if (values.format === DocumentContentType.Pdf) {
      setShowExportPatientRecordViewerDialog(true);
    } else {
      await getExportPatientDocument(DocumentContentType.Html, patientId);
    }
    closeModal();
  };

  const initialValues: ExportPatientRecordFormValues = {
    format: DocumentContentType.Pdf
  };

  return (
    <SubmissionFormDialog<ExportPatientRecordFormValues>
      dialogName={`Export ${patientLabel} ${masterPatient?.fullName}`}
      dialogProps={{
        onDismiss: closeModal,
        minWidth: 600,
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">
              {`Export ${patientLabel} ${masterPatient?.fullName}`}
            </Heading>
          )
        }
      }}
      initialValues={initialValues}
      onSubmitSucceeded={closeModal}
      onSubmit={onSubmit}
      buttonsProps={{
        submitButtonProps: {
          text: "Export",
          iconProps: { hidden: true }
        },
        cancelButtonProps: {
          ...dataAttribute(DataAttributes.Element, "export-cancel-button")
        },
        disableSubmitOnPristine: false
      }}
    >
      {() => <ExportPatientRecordModalFormFields />}
    </SubmissionFormDialog>
  );
});
