import { FunctionComponent, useContext } from "react";

import { MessageBar, MessageBarType, PersonaSize, Stack } from "@bps/fluent-ui";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { AppointmentFormContext } from "../context/AppointmentFormContext.ts";

interface ProviderTimeSlotsErrorProps {
  providerId: string;
  error: Error;
}

export const ProviderTimeSlotsError: FunctionComponent<
  ProviderTimeSlotsErrorProps
> = ({ providerId, error }) => {
  const { core } = useContext(AppointmentFormContext);

  const user = core.userMap.get(providerId);
  return (
    <Stack
      tokens={{ childrenGap: 16 }}
      styles={(_props, theme) => ({
        root: {
          borderBottomStyle: "solid",
          borderBottomColor: theme.palette.neutralLighter,
          borderBottomWidth: 1,
          paddingBottom: 16
        }
      })}
    >
      <Persona
        text={user?.name}
        id={user?.id}
        size={PersonaSize.size24}
        imageInitials={user?.initials}
        styles={{ root: { flexGrow: 1 } }}
      />
      <MessageBar messageBarType={MessageBarType.error}>
        {error.message}
      </MessageBar>
    </Stack>
  );
};
