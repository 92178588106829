import { compareDatesPredicate, DateTime } from "@bps/utils";
import { ScheduleOverrideDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";

import {
  UserWorkingHoursOverridesFormValues,
  UserWorkingHoursOverridesScreenValues
} from "../UserWorkingHoursOverridesScreen.types.ts";

const fromTime = (data: string): DateTime => {
  return data.length === 8
    ? DateTime.fromFormat(data, "HH:mm:ss")
    : DateTime.fromFormat(data, "HH:mm");
};
export function convertOverrideToFormValue(
  override: ScheduleOverrideDto
): UserWorkingHoursOverridesFormValues {
  return {
    id: override.id,
    eTag: override.eTag,
    startDate: DateTime.jsDateFromISO(override.startDate),
    endDate: DateTime.jsDateFromISO(override.endDate),
    atWork: (+!!override.isAvailable).toString(),
    startTime: override.startTime
      ? fromTime(override.startTime).toTimeInputFormat()
      : undefined,
    endTime: override.endTime
      ? fromTime(override.endTime).toTimeInputFormat()
      : undefined,
    reason: override.reason
  };
}

export function convertFormValueToOverride(
  override: UserWorkingHoursOverridesFormValues,
  locationId: string
): ScheduleOverrideDto {
  const isAtWork = override.atWork === "1";
  return {
    orgUnitId: locationId,
    id: override.id,
    eTag: override.eTag,
    startDate: override.startDate
      ? DateTime.jsDateToISODate(override.startDate)
      : "",
    endDate: override.endDate ? DateTime.jsDateToISODate(override.endDate) : "",
    startTime: isAtWork ? override.startTime : undefined,
    endTime: isAtWork ? override.endTime : undefined,
    isAvailable: isAtWork,
    reason: override.reason
  };
}

export function convertOverridesToFormValues(
  overrides: ScheduleOverrideDto[]
): UserWorkingHoursOverridesScreenValues {
  const before: UserWorkingHoursOverridesFormValues[] = [];
  const after: UserWorkingHoursOverridesFormValues[] = [];
  overrides
    .sort((x, y) =>
      compareDatesPredicate(
        DateTime.fromISOOrNow(x.endDate),
        DateTime.fromISOOrNow(y.endDate),
        true
      )
    )
    .forEach(overrideDto => {
      const res = convertOverrideToFormValue(overrideDto);
      if (
        res.endDate &&
        DateTime.fromJSDate(res.endDate).startOf("day") < DateTime.today()
      ) {
        before.push(res);
      } else {
        after.push(res);
      }
      return null;
    });
  return { before: { overrides: before }, after: { overrides: after } };
}
