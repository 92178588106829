import { FunctionComponent } from "react";

import { FontIcon, Stack, useTheme } from "@bps/fluent-ui";
import { CalendarEventReminderStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { getEventReminderStatusColours } from "@modules/booking/screens/event-reminders/components/utils.tsx";

import {
  getReminderStatusBackgroundColor,
  reminderStatusIconName
} from "../appointment-status/utils.ts";

interface OptionProps {
  code: CalendarEventReminderStatus;
  text?: string;
  currentAttendenceStatus?: CalendarEventReminderStatus;
}
export const AttendanceStatusDropDownOption: FunctionComponent<OptionProps> = ({
  code,
  text,
  currentAttendenceStatus
}) => {
  const theme = useTheme();

  return (
    <Stack
      horizontal
      verticalAlign="center"
      styles={{
        root: {
          backgroundColor:
            code === currentAttendenceStatus
              ? getReminderStatusBackgroundColor(theme, currentAttendenceStatus)
              : undefined,
          width: "100%",
          height: "100%",
          padding: 8
        }
      }}
    >
      <FontIcon
        iconName={reminderStatusIconName[code]}
        styles={{
          root: {
            color:
              code !== CalendarEventReminderStatus.REQUIRESREVIEW
                ? getEventReminderStatusColours(theme)(code)
                : theme.palette.yellowDark,
            marginRight: 8
          }
        }}
      />
      <span>{text}</span>
    </Stack>
  );
};
