// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { useField, useFormState } from "react-final-form";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { DrawingCanvas } from "../../notes/draw/DrawingCanvas.tsx";
import { InjuryImagesHelper } from "../context/InjuryImagesHelper.tsx";
import { sotapNameOf } from "../SOTAP.utils.ts";

enum FootImageType {
  frontFootImage = "0",
  backFootImage = "1",
  bottomFootImage = "2",
  topFootImage = "3",
  leftInsideFootImage = "4",
  rightInsideFootImage = "5",
  leftOutsideFootImage = "6",
  rightOutsideFootImage = "7"
}

export const FootInjuryImageAssessment: React.FC = () => {
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const {
    input: { onChange: onBackFootImage }
  } = useField(sotapNameOf("backFootImage"));

  const {
    input: { onChange: onFrontFootImage }
  } = useField(sotapNameOf("frontFootImage"));

  const {
    input: { onChange: onBottomFootImage }
  } = useField(sotapNameOf("bottomFootImage"));

  const {
    input: { onChange: onTopFootImage }
  } = useField(sotapNameOf("topFootImage"));

  const {
    input: { onChange: onLeftInsideFootImage }
  } = useField(sotapNameOf("leftInsideFootImage"));

  const {
    input: { onChange: onRightInsideFootImage }
  } = useField(sotapNameOf("rightInsideFootImage"));

  const {
    input: { onChange: onLeftOutsideFootImage }
  } = useField(sotapNameOf("leftOutsideFootImage"));

  const {
    input: { onChange: onRightOutsideFootImage }
  } = useField(sotapNameOf("rightOutsideFootImage"));

  const footProps = InjuryImagesHelper.footProperties();
  const images = InjuryImagesHelper.mapImagesOnPart(footProps, initialValues);

  const handleOnChange = (
    canvas: Canvas,
    event: any,
    onChangeFunc: (x: any) => void
  ) => {
    if (event.target.dirty) {
      onChangeFunc(canvas?.toSVG());
    } else {
      onChangeFunc(undefined);
    }
  };
  return (
    <DrawingCanvas
      isViewOnly={
        isViewOnlyOrDischarged ||
        clinicalRecord.dischargeInProgressOrCompleted()
      }
      showQuickColourPicker={!isViewOnlyOrDischarged}
      initialSelectedImage={0}
      caption="Diagram"
      images={images}
      onChange={(canvas: Canvas, event: any) => {
        switch (canvas["lowerCanvasEl"].id as FootImageType) {
          case FootImageType.backFootImage:
            handleOnChange(canvas, event, onBackFootImage);
            break;

          case FootImageType.bottomFootImage:
            handleOnChange(canvas, event, onBottomFootImage);
            break;

          case FootImageType.frontFootImage:
            handleOnChange(canvas, event, onFrontFootImage);
            break;

          case FootImageType.topFootImage:
            handleOnChange(canvas, event, onTopFootImage);
            break;

          case FootImageType.leftInsideFootImage:
            handleOnChange(canvas, event, onLeftInsideFootImage);
            break;

          case FootImageType.rightInsideFootImage:
            handleOnChange(canvas, event, onRightInsideFootImage);
            break;

          case FootImageType.leftOutsideFootImage:
            handleOnChange(canvas, event, onLeftOutsideFootImage);
            break;

          case FootImageType.rightOutsideFootImage:
            handleOnChange(canvas, event, onRightOutsideFootImage);
            break;

          default:
            return;
        }
      }}
    />
  );
};
