import { observer } from "mobx-react-lite";

import { BusinessRoleClasses } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { getStartingInvoiceValues } from "@modules/billing/screens/shared-components/utils/invoice.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import InvoiceDetailsModal from "./InvoiceDetailsModal.tsx";

const CurrentInvoiceDetailsModal: React.FC = observer(() => {
  const {
    core,
    booking,
    billing: {
      ui: { currentInvoiceDetails, hideInvoiceDetailsDialog }
    }
  } = useStores();

  return (
    <DataFetcher<Partial<InvoiceDetailsModalFormValues> | undefined>
      fetch={async root => {
        const values =
          !!currentInvoiceDetails && currentInvoiceDetails.contactId
            ? await getStartingInvoiceValues(root, {
                patientId: currentInvoiceDetails.contactId
              })
            : undefined;
        core.user?.id &&
          (await booking.getUserAvailability(values?.userId ?? core.user?.id));
        return values;
      }}
    >
      {values => (
        <InvoiceDetailsModal
          initialValues={{
            ...values,
            userId: core.user?.businessRoleClasses.includes(
              BusinessRoleClasses.Provider
            )
              ? core.userId
              : undefined,
            locationId:
              core.user?.availableOrgUnitIds?.length === 1
                ? core.user.availableOrgUnitIds[0]
                : undefined
          }}
          hidden={false}
          onDismiss={() => hideInvoiceDetailsDialog()}
        />
      )}
    </DataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default CurrentInvoiceDetailsModal;
