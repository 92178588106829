import { observer } from "mobx-react-lite";
import { FC, PropsWithChildren } from "react";

import { Separator, Stack, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  NzPracticeAccContractCompanyData,
  NzPracticeAccContractDto,
  OrgUnitCompanyDataDto,
  OrgUnitCompanyDataType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { PracticeAccContracts } from "../practice-acc-contracts/PracticeAccContracts.tsx";
import { OrgUnits } from "../practice-details/PracticeDetailsForm.tsx";
import { AccSetupFormValuesValidator } from "./AccSetupFormValuesValidator.tsx";
import { DefaultCpnLink } from "./DefaultCpnLink.tsx";

const validator = new AccSetupFormValuesValidator();
export interface AccSetupFormValues {
  accContracts: NzPracticeAccContractDto[];
}

interface AccSetupFormProps extends PropsWithChildren {
  orgUnits: OrgUnits | undefined;
}

const AccSetupFormBase: FC<AccSetupFormProps> = observer(
  ({ orgUnits, children }) => {
    const { core, practice, notification } = useStores();
    const theme = useTheme();
    const { formFooter, formFields } = getUserStylesSet(theme);
    const orgUnit = core.location;
    const getInitialValues = () => {
      return {
        accContracts:
          orgUnits?.pracOrgUnit?.nzAccContracts?.contracts.sort((a, b) => {
            const sortOrderA =
              practice.ref.accPracticeContractTypes.values.find(
                v => v.code === a.accPracticeContractTypeCode
              )?.sortOrder ?? 0;

            const sortOrderB =
              practice.ref.accPracticeContractTypes.values.find(
                v => v.code === b.accPracticeContractTypeCode
              )?.sortOrder ?? 0;
            return sortOrderB - sortOrderA;
          }) ?? []
      };
    };

    const getOrgUnitCompanyData = (values: AccSetupFormValues) => {
      const dtos: OrgUnitCompanyDataDto[] = [];
      dtos.push({
        orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.NzAccContracts,
        contracts:
          values.accContracts?.map(c => ({
            accPracticeContractTypeCode: c.accPracticeContractTypeCode,
            contractId: c.contractId ?? "",
            isSubscribed: c.isSubscribed
          })) ?? []
      } as NzPracticeAccContractCompanyData);
      return dtos;
    };

    const updatePracticeOrgUnit = (values: AccSetupFormValues) => {
      const updateOrgUnitCompanyDataDto = getOrgUnitCompanyData(values);

      return practice.updateOrgUnit({
        id: orgUnit.parentOrgUnit!.id,
        orgUnitCompanyData: updateOrgUnitCompanyDataDto
      });
    };

    const onSubmit = async (values: AccSetupFormValues) => {
      await updatePracticeOrgUnit(values);
    };
    return (
      <SubmissionForm<AccSetupFormValues>
        formName="acc-contracts"
        onSubmitSucceeded={() => {
          notification.success(
            `${orgUnit.parentOrgUnit?.name || "Practice"} has been updated.`
          );
        }}
        autoFocus={false}
        onSubmit={onSubmit}
        readOnly={!core.hasPermissions(Permission.OrgUnitWrite)}
        initialValues={getInitialValues()}
        validate={(values: AccSetupFormValues) => validator.validate(values)}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {() => {
          return (
            <Stack
              styles={{
                root: formFields
              }}
              tokens={{ childrenGap: 16 }}
            >
              <PracticeAccContracts />
              <DefaultCpnLink pracOrgUnit={orgUnits?.pracOrgUnit} />
              <Separator />
              {children}
            </Stack>
          );
        }}
      </SubmissionForm>
    );
  }
);

export const AccSetupForm = withFetch(
  x => [x.practice.ref.accPracticeContractTypes.load()],
  AccSetupFormBase
);
