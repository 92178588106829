import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import {
  FontWeights,
  Heading,
  Shimmer,
  ShimmerElementType,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import { compareDatesOrUndefinedPredicate, DateTime } from "@bps/utils";
import { TreeView } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { InteractionType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { Interaction } from "@stores/clinical/models/Interaction.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { EventList } from "@ui-components/EventList.tsx";

import { scrollToPositionFromClickedEvent } from "../ClickedEventDetail.ts";
import { InteractionTimelineProps } from "./InteractionTimeLine.types.ts";
import { InteractionTimeLineFilter } from "./InteractionTimeLineFilter.tsx";
import { InteractionTimeLineFilterState } from "./InteractionTimeLineFilter.types.ts";

export const loadingMoreShimmer = (
  <>
    {Array.from(Array(3).keys()).map(i => (
      <Stack tokens={{ childrenGap: 6 }} key={i}>
        <Shimmer
          styles={{ root: { paddingLeft: 4, marginTop: 7 } }}
          shimmerElements={[
            { type: ShimmerElementType.circle },
            { type: ShimmerElementType.gap },
            { type: ShimmerElementType.line }
          ]}
          width={120}
        />
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.gap, width: 36 },
            { type: ShimmerElementType.line }
          ]}
          width={200}
        />
      </Stack>
    ))}
  </>
);
const InteractionTimelineComponent: React.FC<InteractionTimelineProps> =
  observer(({ clinicalRecord, clickedEventDetail, onInteractionRowClick }) => {
    const { clinical, correspondence } = useStores();
    const [documentSelected, setdocumentSelected] = useState<
      ClinicalDocument | undefined
    >(undefined);

    useEffect(() => {
      if (clickedEventDetail) {
        scrollToPositionFromClickedEvent(clickedEventDetail);
      }
    }, [clickedEventDetail]);

    const loadMore = () => {
      if (clinicalRecord.hasMoreInteractions) {
        clinicalRecord.loadMoreInteractions();
      }
    };

    const onCorrespondenceClick = async (sourceId: string) => {
      // this call interacts with document API to get the SasUri, required for viewing documents
      const corr = await correspondence.getCorrespondenceByDocumentId(
        clinicalRecord.id,
        sourceId
      );
      setdocumentSelected(corr);
    };

    const renderDetails = (interaction: Interaction): React.ReactNode => {
      return (
        <>
          <Text
            block
            styles={(_props, theme) => ({
              root: {
                color: theme?.palette.themeDarker,
                fontWeight: FontWeights.semibold
              }
            })}
          >
            {(interaction.type === InteractionType.DocumentCreated ||
              interaction.type === InteractionType.DocumentUploaded) &&
            interaction.SourceTypeCode
              ? `${clinical.ref.interactionTypes.map.get(interaction.type)
                  ?.text} - ${correspondence.ref.correspondenceTypes.map.get(
                  interaction.SourceTypeCode
                )?.text}`
              : clinical.ref.interactionTypes.map.get(interaction.type)?.text}
          </Text>
          <Text block>
            {interaction.type === InteractionType.Diagnosis
              ? interaction?.title.substring(0, interaction?.title.indexOf(","))
              : interaction?.title}
          </Text>
        </>
      );
    };

    const mapInteractions = (interactions?: Interaction[]) => {
      return (interactions ?? [])
        .sort((a, b) =>
          compareDatesOrUndefinedPredicate(
            b.interactionDateTime,
            a.interactionDateTime
          )
        )
        .map(x => {
          return {
            id: x.sourceId,
            title: renderDetails(x),
            iconName: "Drop",
            key: x.interactionAt
          };
        });
    };

    const filterValues = (filter: InteractionTimeLineFilterState) => {
      const { dateTo, dateFrom, search, types } = filter;
      const dateToCopy = DateTime.fromJSDate(dateTo)?.plus({ days: 1 });
      clinicalRecord.loadInteractions({
        dateFrom: DateTime.jsDateToISODate(dateFrom) ?? "",
        dateTo: dateToCopy?.toISODate() ?? "",
        search: search ?? "",
        types: !!types && types.length > 0 ? types : []
      });
    };

    const closeDocumentViewer = () => {
      setdocumentSelected(undefined);
    };

    return (
      <DataFetcher
        fetch={() => clinicalRecord.loadInteractions()}
        fallback={<Spinner />}
      >
        {() => (
          <Stack verticalFill>
            <Heading
              variant="section-heading-light"
              styles={{ root: { padding: "5px 0" } }}
            >
              Timeline
            </Heading>
            <InteractionTimeLineFilter onSearch={filterValues} />
            <EventList
              items={mapInteractions(clinicalRecord.interactions)}
              onLoadMore={loadMore}
              hasMore={clinicalRecord.hasMoreInteractions}
              loading={clinicalRecord.nextInteractionsPromise.pending}
              loadingShimmer={loadingMoreShimmer}
              emptyListContent={<div>No past items found.</div>}
              groupByKey={true}
              elementId="timeline"
              onRowClick={(
                sourceId: string,
                listElementId: string | undefined,
                capturedScrollPosition: number | undefined
              ) => {
                const interaction = clinicalRecord.interactions.find(
                  i => i.sourceId === sourceId
                );

                if (interaction) {
                  if (
                    interaction.type !== InteractionType.DocumentCreated &&
                    interaction.type !== InteractionType.DocumentUploaded &&
                    interaction.type !== InteractionType.RequestCreated
                  ) {
                    runInAction(() => {
                      clinical.ui.tabs.currentPatientRecordTab!.sidePanelTab =
                        TreeView.PastConsults;
                      clinical.ui.tabs.currentPatientRecordTab!.sidePanelTabId =
                        sourceId;
                    });
                  } else {
                    onCorrespondenceClick(sourceId);
                  }
                }

                if (listElementId) {
                  const clickedEventDetail = {
                    eventListElementId: listElementId,
                    capturedScrollPosition
                  };
                  onInteractionRowClick(clickedEventDetail);
                }
              }}
            />
            {documentSelected && documentSelected.id && (
              <DocumentViewerDialog
                getDocument={async () => {
                  const response = await correspondence.getDocumentUrl({
                    patientId: clinicalRecord.id,
                    documentId: documentSelected!.id
                  });
                  return {
                    entityId: documentSelected.patientId,
                    documentId: documentSelected.id,
                    entityType: DocumentEntityType.Patient,
                    name: documentSelected.name,
                    extension: documentSelected.extension,
                    previewUri: response.url
                  };
                }}
                closeDocumentViewer={closeDocumentViewer}
                getDownloadUri={async () => {
                  const documentUrlResponse =
                    await correspondence.getDocumentUrl({
                      patientId: clinicalRecord.id,
                      documentId: documentSelected.id
                    });
                  return documentUrlResponse.url;
                }}
              />
            )}
          </Stack>
        )}
      </DataFetcher>
    );
  });

export const InteractionTimeline = withFetch(
  x => [
    x.clinical.ref.interactionTypes.load(),
    x.correspondence.ref.correspondenceTypes.load()
  ],
  InteractionTimelineComponent
);
