import { FormApi } from "final-form";
import { useEffect, useRef } from "react";

import { Spinner } from "@bps/fluent-ui";
import { AccClinicalTab } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClaimAdjustmentForm } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentForm.tsx";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentHelper } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.ts";
import { ClaimDataFetcher } from "@modules/acc/screens/shared-components/ClaimDataFetcher.tsx";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalFormValuesSpy } from "../clinical-record-forms-values-spy/ClinicalRecordFormsValuesSpy.tsx";
import { ClaimAdjustmentFormHeader } from "./ClaimAdjustmentFormHeader.tsx";

interface ClaimAdjustmentEditScreenProps {
  claim: Claim;
}

const ClinicalClaimAdjustmentEditScreenBase: React.FC<
  ClaimAdjustmentEditScreenProps
> = ({ claim }) => {
  const root = useStores();
  const { claimAdjustment, clinicalRecord } = usePatientRecordScreenContext();

  const claimHelper = useRef<ClaimAdjustmentHelper>(
    new ClaimAdjustmentHelper(root, claim, {
      claimAdjustmentId: claimAdjustment?.id,
      subscribeToClaimAdjustmentEvent: true
    })
  );

  const initialValues = claimHelper.current.getInitialValues();
  const persistedValues =
    clinicalRecord.clinicalRecordFormsValuesStash.persistedValues[
      AccClinicalTab.Adjustment
    ];

  const { destroy } = claimHelper.current;

  useEffect(() => {
    return () => {
      destroy();
    };
  }, [destroy]);

  const restartForm = (
    values: ClaimAdjustmentFormValues,
    form: FormApi<ClaimAdjustmentFormValues>
  ) => {
    clinicalRecord.clinicalRecordFormsValuesStash.resetPersistedValues(
      AccClinicalTab.Adjustment,
      values
    );
    form.restart(values);
  };

  return (
    <ClaimAdjustmentContext.Provider value={claimHelper.current}>
      <ClaimAdjustmentForm
        initialValues={{ ...initialValues, ...persistedValues }}
        header={<ClaimAdjustmentFormHeader />}
        onSubmitSucceeded={restartForm}
      >
        {() => (
          <ClinicalFormValuesSpy
            clinicalRecord={clinicalRecord}
            originalInitialValues={initialValues}
            tab={AccClinicalTab.Adjustment}
          />
        )}
      </ClaimAdjustmentForm>
    </ClaimAdjustmentContext.Provider>
  );
};

export const ClinicalClaimAdjustmentEditScreen: React.FC = props => {
  const { claimAdjustment } = usePatientRecordScreenContext();
  if (!claimAdjustment?.id) return null;

  return (
    <ClaimDataFetcher
      refetchId={claimAdjustment.id}
      fallback={<Spinner />}
      fetchProvider
      claimAdjustmentId={claimAdjustment.id}
    >
      {(claim: Claim) => (
        <ClinicalClaimAdjustmentEditScreenBase claim={claim} {...props} />
      )}
    </ClaimDataFetcher>
  );
};
