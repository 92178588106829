import { useForm } from "react-final-form";

import { Card, LabeledText } from "@bps/fluent-ui";
import { getPatientDeclarationViewValues } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { PatientDeclarationViewValues } from "../types/claim.types.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";

export const PatientDeclarationViewSection: React.FC = () => {
  const values = useForm<ClaimFormValues>().getState().values;

  return (
    <DataFetcher
      fetch={root => getPatientDeclarationViewValues({ values, root })}
    >
      {(patientDeclaration: PatientDeclarationViewValues) => (
        <Card
          heading={ClaimSectionHeadings.patientDeclaration}
          headingLevel="section-heading"
          iconName="TextDocumentShared"
          id={ClaimsCardIds.patientDeclaration}
        >
          {patientDeclaration.declarationDateFormatted && (
            <LabeledText noSemicolon label={ClaimFormLabels.signed}>
              {patientDeclaration.declarationDateFormatted}
            </LabeledText>
          )}
        </Card>
      )}
    </DataFetcher>
  );
};
