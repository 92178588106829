import { useRef, useState } from "react";
import { useField } from "react-final-form";

import {
  FontSizes,
  Heading,
  IconButton,
  ITooltipHost,
  Link,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import { InvoiceItemListAddCommentValidator } from "@modules/billing/screens/validators/InvoiceItemListAddCommentValidator.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { CommentField } from "./CommentField.tsx";

export interface InvoiceItemCommentFormValues {
  comment: string;
}
const validator = new InvoiceItemListAddCommentValidator();

export const InvoiceItemListAddComment: React.FC<{ index: number }> = ({
  index
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const tooltipRef = useRef<ITooltipHost>(null);

  const onLinkClicked = async () => {
    if (tooltipRef.current) {
      tooltipRef.current.dismiss();
    }

    setIsDialogVisible(true);
  };

  const fieldName = `invoiceItems[${index}].comment`;

  const {
    input: { value: comment, onChange }
  } = useField(fieldName, { subscription: { value: true } });

  const renderAddCommentButton = () => {
    return (
      <Stack horizontal>
        <TooltipHost
          componentRef={tooltipRef}
          content={
            <Stack tokens={{ childrenGap: 8 }}>
              <Text>{comment || "Add a comment"}</Text>
              {comment ? (
                <Link onClick={onLinkClicked}>Edit comment</Link>
              ) : null}
            </Stack>
          }
        >
          <IconButton
            iconProps={{
              iconName: comment ? "CommentSolid" : "CommentAdd"
            }}
            onClick={() => setIsDialogVisible(true)}
            styles={{
              icon: {
                fontSize: FontSizes.medium,
                marginTop: 2
              }
            }}
          />
        </TooltipHost>
      </Stack>
    );
  };

  const onDismiss = () => setIsDialogVisible(false);
  const onSubmit = (values: InvoiceItemCommentFormValues) => {
    onChange(values.comment);
    setIsDialogVisible(false);
  };

  return (
    <>
      {renderAddCommentButton()}
      {isDialogVisible && (
        <SubmissionFormDialog<InvoiceItemCommentFormValues>
          dialogName="Invoice item comment dialog"
          initialValues={{ comment }}
          validate={validator.validate}
          buttonsProps={{
            disableSubmitOnFormInvalid: true,
            submitButtonProps: {
              text: comment ? "Save" : "New comment",
              iconProps: { hidden: true }
            }
          }}
          dialogProps={{
            onDismiss,
            dialogContentProps: {
              title: (
                <Heading variant="modal-heading">
                  {comment ? "Edit line item comment" : "New line item comment"}
                </Heading>
              ),
              showCloseButton: false
            },
            minWidth: 520
          }}
          onSubmit={onSubmit}
        >
          {() => <CommentField />}
        </SubmissionFormDialog>
      )}
    </>
  );
};
