import { ActivityDescription } from "@stores/clinical/models/ActivityDescription.ts";

import { ClinicalActivityDescriptionCode } from "./types/clinical-activity.type.ts";

export const checkIsDescriptionClaimRelated = (
  activityDescription?: ActivityDescription
) =>
  activityDescription &&
  (activityDescription.convertedDescriptionCode ===
    ClinicalActivityDescriptionCode.ACC32 ||
    activityDescription.convertedDescriptionCode ===
      ClinicalActivityDescriptionCode.ClaimReview ||
    activityDescription.convertedDescriptionCode ===
      ClinicalActivityDescriptionCode.RecordOutcomeMeasures);
