import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  mergeStyleSets,
  TextBadge,
  TextBadgeColor,
  TextBadgeProps,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import {
  BillingStatuses,
  TransactionItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import {
  isCreditNote,
  isInvoice,
  isPayment
} from "@stores/billing/utils/transaction.utils.ts";
import { refTextFor } from "@ui-components/RefText.tsx";

export interface TransactionBadgeNewProps {
  transaction: TransactionBase;
  item?: TransactionItemDto;
  displayAsIconBadge?: boolean;
}

export const TransactionBadgeNew: React.FC<TransactionBadgeNewProps> = ({
  transaction,
  item,
  displayAsIconBadge
}) => {
  const theme = useTheme();
  let badgeColour: TextBadgeColor;
  let code: string;
  let key: string;
  let styles: TextBadgeProps["styles"];

  if (item) {
    badgeColour = transaction.getItemStatusColour(item.id);
    const statusText = transaction.getItemStatusText(item.id);
    code = statusText.code || "";
    key = statusText.key;
    styles = {
      root: {
        backgroundColor: "transparent",
        color:
          item.status === BillingStatuses.cancelled
            ? theme.palette.neutralSecondary
            : undefined
      }
    };
  } else {
    badgeColour =
      displayAsIconBadge &&
      isInvoice(transaction) &&
      transaction.isFullyCredited
        ? TextBadgeColor.lightGrey
        : transaction.statusColour;
    code = transaction.statusText.code;
    key = transaction.statusText.key;
  }

  const getText = () => {
    return (isPayment(transaction) && !transaction.isCancelled) ||
      isCreditNote(transaction)
      ? transaction.customReceiptText
      : refTextFor(x => x.billing.ref[key])({
          code
        });
  };
  return (
    <TextBadge
      styles={mergeStyleSets(
        {
          root: displayAsIconBadge
            ? { paddingRight: 8, paddingLeft: 8 }
            : { width: 108 }
        },
        styles
      )}
      badgeColor={
        (isCreditNote(transaction) && transaction.creditNoteBadgeColour) ||
        badgeColour
      }
      badgeSize={TextBadgeSize.medium}
      {...dataAttribute(DataAttributes.Element, "transaction-badge")}
    >
      {displayAsIconBadge && !item ? (
        <TooltipHost
          content={
            isInvoice(transaction) ? transaction.getStatusTooltip() : undefined
          }
        >
          <FontIcon
            iconName={transaction.getStatusIcon()}
            styles={{
              root: {
                fontSize: FontSizes.large,
                verticalAlign: "middle"
              }
            }}
            {...dataAttribute(DataAttributes.Element, `${code}-status`)}
          />
        </TooltipHost>
      ) : (
        getText()
      )}
    </TextBadge>
  );
};
