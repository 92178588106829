import { ServiceRuleType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  greaterThan,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";

export class ScheduleServicesValidator extends Validator<InvoiceItemFormValue> {
  constructor(claimId?: string) {
    super();
    this.forField("fee", [
      greaterThan(
        0,
        messageWithData(ValidationMessages.feeInvoiceItems, "$0.00")
      ),
      required(messageWithData(ValidationMessages.feeInvoiceItems, "$0.00")),
      (value: string, values: InvoiceItemFormValue) => {
        const max = values.serviceSearch?.rules?.find(
          r => r.ruleType === ServiceRuleType.UserDefinedAmount
        )?.max;

        return max && Number(value) > max
          ? ValidationMessages.exceedsMaximumFeeAmount
          : undefined;
      }
    ]);
    this.forField("serviceSearch", [
      predicate(
        () => !claimId,
        (servcieSearch: InvoiceItemFormValue["serviceSearch"]) =>
          !!servcieSearch?.rules?.find(r => r.ruleType === ServiceRuleType.ACC)
            ? ValidationMessages.accFeesRequireClaim
            : undefined
      )
    ]);

    this.forField("quantity", [
      (quantity, rest) => {
        const increment = rest.serviceSearch?.rules?.find(
          r => r.ruleType === ServiceRuleType.Increment
        )?.amount;
        return increment && Number(quantity) % increment !== 0
          ? messageWithData(
              ValidationMessages.quantityMustBeMultipleOfIncrement,
              increment
            )
          : undefined;
      }
    ]);
  }
}
