import { FunctionComponent } from "react";

import { ConfirmDialog, Dialog, Stack } from "@bps/fluent-ui";
import { DATE_FORMATS, TIME_FORMATS } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CancelAppointmentFormValues } from "@modules/booking/screens/booking-calendar/components/cancel-calendar-event-dialog/components/CancelAppointmentForm.types.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { CancelAppointmentForm } from "./components/CancelAppointmentForm.tsx";
import { CancelAppointmentRecurrenceForm } from "./components/CancelAppointmentRecurrenceForm.tsx";
import { cancelAppointmentRecurrenceFormFallback } from "./components/CancelAppointmentRecurrenceFormFallback.tsx";

export interface CancelCalendarEventDialogProps {
  setIsClosing?: (value: boolean) => void;
}

export const CancelCalendarEventDialog: FunctionComponent<
  CancelCalendarEventDialogProps
> = ({ setIsClosing }) => {
  const { booking } = useStores();

  const calendarEvent = booking.calendarEventsMap.get(
    booking.ui.cancelCalendarEventId!
  )!;

  const {
    cancelCalendarEventRecurrenceId,
    setCancelCalendarEventRecurrenceId
  } = booking.ui;

  const onDismiss = () => {
    booking.ui.setCancelCalendarEventId();
    setCancelCalendarEventRecurrenceId();
    if (setIsClosing !== undefined) setIsClosing(false);
  };

  const onCancelled = (values?: CancelAppointmentFormValues) => {
    booking.ui.setCancelCalendarEventId();
    setCancelCalendarEventRecurrenceId();
    if (setIsClosing !== undefined) setIsClosing(false);
    if (values?.rescheduleAppointment) {
      booking?.ui.onRebookOrRescheduleButtonClick(calendarEvent, 1);
    }
  };

  const handleCancelEvent = async () => {
    await booking.cancelCalendarEvent({
      id: booking.ui.cancelCalendarEventId!
    });
    onCancelled();
  };

  const handleCancelCalendarEventRecurrenceSeries = async () => {
    await booking.cancelRecurrence(cancelCalendarEventRecurrenceId!);
    onCancelled();
  };

  const isUnavailableSeries =
    calendarEvent &&
    calendarEvent.type === CalendarEventType.Unavailable &&
    calendarEvent.calendarEventRecurrenceId !== undefined;

  const cancelOccurrenceOnly =
    isUnavailableSeries && cancelCalendarEventRecurrenceId === undefined;

  const cancelAppointmentOccurrence: boolean =
    calendarEvent.type === CalendarEventType.Appointment &&
    cancelCalendarEventRecurrenceId !== undefined;

  if (isUnavailableSeries) {
    const onConfirm = cancelOccurrenceOnly
      ? handleCancelEvent
      : handleCancelCalendarEventRecurrenceSeries;

    return (
      <ConfirmDialog
        hidden={false}
        confirmButtonProps={{ text: "Yes" }}
        cancelButtonProps={{ text: "No" }}
        onConfirm={onConfirm}
        onCancel={onDismiss}
      >
        {cancelOccurrenceOnly &&
          `Are you sure you want to delete this event for ${calendarEvent.user
            ?.fullName}
          ${calendarEvent.purpose} at ${calendarEvent.startDateTime.toFormat(
            TIME_FORMATS.DEFAULT_TIME_FORMAT
          )} on ${calendarEvent.startDateTime.toFormat(
            DATE_FORMATS.MONTH_DAY
          )}?`}

        {!cancelOccurrenceOnly && (
          <Stack>
            <span>
              Are you sure want to cancel the series for{" "}
              {calendarEvent.user?.fullName}?
            </span>
            <span>
              {calendarEvent.purpose} at{" "}
              {calendarEvent.startDateTime.toFormat(
                TIME_FORMATS.TIME_FORMAT_WITH_MIDDAY_FULL
              )}
            </span>
          </Stack>
        )}
      </ConfirmDialog>
    );
  }

  const patientName = calendarEvent.contact?.fullName;
  const providerName = calendarEvent.user?.fullName;

  const dialogTitle = `Cancel appt for ${patientName}`;
  const dialogSubtext = `at ${calendarEvent.startDateTime.toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  )} on ${calendarEvent.startDateTime.toDayDefaultFormat()} with ${providerName}`;

  return (
    <DataFetcher<CalendarEvent>
      refetchId={booking.ui.cancelCalendarEventId}
      fallback={cancelAppointmentRecurrenceFormFallback}
      fetch={({ booking }) =>
        // Note: For cancelAppointmentOccurrence ignoreCache to get the latest recurance and series information.
        booking.getCalendarEvent(booking.ui.cancelCalendarEventId!, {
          ignoreCache: cancelAppointmentOccurrence
        })
      }
    >
      {event => {
        if (!event.user) {
          return null;
        }

        switch (event.type) {
          case CalendarEventType.Appointment: {
            return (
              <Dialog
                hidden={false}
                onDismiss={onDismiss}
                maxWidth="480px"
                minWidth="480px"
                dialogContentProps={{
                  title: cancelAppointmentOccurrence
                    ? "Cancel series"
                    : dialogTitle,
                  subText: dialogSubtext
                }}
              >
                {cancelAppointmentOccurrence ? (
                  <CancelAppointmentRecurrenceForm
                    onSubmitted={onCancelled}
                    onCancel={onDismiss}
                    calendarEvent={event}
                  />
                ) : (
                  <CancelAppointmentForm
                    onSubmitted={onCancelled}
                    onCancel={onDismiss}
                    calendarEvent={event}
                  />
                )}
              </Dialog>
            );
          }
          case CalendarEventType.Meeting: {
            return (
              <ConfirmDialog
                hidden={false}
                confirmButtonProps={{ text: "Yes" }}
                cancelButtonProps={{ text: "No" }}
                onConfirm={handleCancelEvent}
                onCancel={onDismiss}
              >
                {`Are you sure you want to cancel the meeting at ${calendarEvent.startDateTime.toFormat(
                  TIME_FORMATS.DEFAULT_TIME_FORMAT
                )} on ${calendarEvent.startDateTime.toFormat(
                  DATE_FORMATS.MONTH_DAY
                )} for ${event.user.fullName}?`}
              </ConfirmDialog>
            );
          }
          case CalendarEventType.Unavailable: {
            return (
              <ConfirmDialog
                hidden={false}
                confirmButtonProps={{ text: "Yes" }}
                cancelButtonProps={{ text: "No" }}
                onConfirm={handleCancelEvent}
                onCancel={onDismiss}
              >
                {`Are you sure you want to make ${calendarEvent.user
                  ?.fullName} available at ${calendarEvent.startDateTime.toFormat(
                  TIME_FORMATS.DEFAULT_TIME_FORMAT
                )} on ${calendarEvent.startDateTime.toFormat(
                  DATE_FORMATS.MONTH_DAY
                )}?`}
              </ConfirmDialog>
            );
          }
          default: {
            return null;
          }
        }
      }}
    </DataFetcher>
  );
};
