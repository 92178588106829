import React from "react";
import { useForm } from "react-final-form";

import { ActionButton, Heading, Stack } from "@bps/fluent-ui";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { emptyServiceItem } from "@modules/acc/screens/claim/components/utils.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { claimManagementFormNameOf } from "../../claim/claim.utils.ts";
import { ClaimFormManagementValues } from "../../claim/types/claim-form-management-values.types.ts";
import { ServiceItemField } from "./ServiceItemField.tsx";

const ServiceItemsFormFieldsBase: React.FC = () => {
  const MAX_SERVICE_ITEMS_LENGTH = 6;
  const form = useForm<ClaimFormManagementValues>();
  return (
    <FieldArray name={claimManagementFormNameOf("serviceItems")}>
      {({ fields }) => {
        return (
          <>
            <Heading
              variant="section-heading-light"
              styles={{
                root: {
                  "&&": {
                    marginTop: 24
                  }
                }
              }}
            >
              {ClaimFormLabels.equipmentAndOrthotics}
            </Heading>
            <ToggleField
              styles={{ root: { marginBottom: 0 } }}
              onText="Yes"
              offText="No"
              name={claimManagementFormNameOf("addServiceItem")}
            />
            <FieldSpy
              name={claimManagementFormNameOf("addServiceItem")}
              onChange={checked => {
                if (checked) {
                  if (!fields?.value?.length) fields.push(emptyServiceItem);
                } else {
                  for (let i = 0; i < fields?.value?.length; i++)
                    fields.remove(0);
                }
              }}
            />
            <FieldSpy
              name={claimManagementFormNameOf("serviceItems")}
              onChange={values => {
                if (values?.length === 0)
                  form.change(
                    claimManagementFormNameOf("addServiceItem"),
                    false
                  );
              }}
            />
            <FieldCondition
              when={claimManagementFormNameOf("addServiceItem")}
              is={true}
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <Stack
                  styles={(prop, theme) => ({
                    root: {
                      borderRadius: 2,
                      border: `1px solid ${theme.palette.neutralLight}`
                    }
                  })}
                >
                  <Stack
                    horizontal
                    styles={(prop, theme) => ({
                      root: {
                        padding: "8px 56px 8px 8px",
                        borderBottom: `1px solid ${theme.palette.neutralLight}`
                      }
                    })}
                    tokens={{ childrenGap: 8 }}
                  >
                    <Heading
                      hasAsterisk
                      variant="section-sub-heading"
                      styles={{ root: { flex: 1, padding: "5px 0px" } }}
                    >
                      {ClaimFormLabels.serviceItem}
                    </Heading>
                    <Heading
                      hasAsterisk
                      variant="section-sub-heading"
                      styles={{ root: { flexBasis: 114, padding: "5px 0px" } }}
                    >
                      {ClaimFormLabels.spent}
                    </Heading>
                  </Stack>
                  {fields.map((name, index) => {
                    return (
                      <ServiceItemField
                        key={name}
                        name={name}
                        button={
                          <Stack.Item
                            styles={{
                              root: {
                                flexBasis: 16,
                                display: "flex",
                                justifyContent: "center"
                              }
                            }}
                          >
                            <DeleteButton
                              onClick={() => {
                                fields.remove(index);
                              }}
                            />
                          </Stack.Item>
                        }
                      />
                    );
                  })}
                </Stack>
              </Stack>

              <Stack horizontal>
                <ActionButton
                  iconProps={{ iconName: "Add" }}
                  text="Add another service item"
                  onClick={() => fields.push(emptyServiceItem)}
                  disabled={fields.length === MAX_SERVICE_ITEMS_LENGTH}
                  styles={{
                    root: { paddingLeft: "9px 5px" }
                  }}
                />
              </Stack>
            </FieldCondition>
          </>
        );
      }}
    </FieldArray>
  );
};

export const ServiceItemsFormFields = withFetch(
  x => [x.acc.ref.serviceItems.load()],
  ServiceItemsFormFieldsBase
);
