import { observer } from "mobx-react-lite";

import { Heading } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ModalKeys } from "../context/ClaimAdjustmentHelper.types.ts";
import { ClaimAdjustmentResponseModalFormValidator } from "../validators/ClaimAdjustmentResponseModalFormValidator.tsx";
import { ClaimAdjustmentResponseFields } from "./ClaimAdjustmentResponseFields.tsx";
import { getClaimAdjustmentResponseInitialValues } from "./utils.ts";

const claimAdjustmentResponseModalFormValidator =
  new ClaimAdjustmentResponseModalFormValidator();

export const ClaimAdjustmentResponseDialogForm: React.FC = observer(() => {
  const {
    currentOpenModal,
    setOpenModal,
    claimAdjustment,
    updateClaimAdjustmentWithResponseValues,
    setClaimAdjustmentId
  } = useClaimAdjustmentContext();

  if (currentOpenModal !== ModalKeys.AdjustmentResponseModal) {
    return null;
  }

  const initialValues =
    getClaimAdjustmentResponseInitialValues(claimAdjustment);

  const acc32num = claimAdjustment?.claimAdjustmentNumber
    ? `ACC32 response - ${claimAdjustment.claimNumber}/${claimAdjustment.claimAdjustmentNumber}`
    : "ACC32 response";
  return (
    <SubmissionFormDialog
      initialValues={initialValues}
      dialogName="ACC32 response"
      onSubmit={updateClaimAdjustmentWithResponseValues}
      onSubmitSucceeded={() => {
        setClaimAdjustmentId();
      }}
      buttonsProps={{
        submitButtonProps: {
          text: "Save",
          iconProps: {}
        },
        hideButtonsSeparator: true
      }}
      dialogProps={{
        onDismiss: () => {
          setOpenModal();
          setClaimAdjustmentId();
        },
        minWidth: 480,
        maxWidth: 480,
        dialogContentProps: {
          title: <Heading variant="modal-heading">{acc32num}</Heading>,
          showCloseButton: true
        }
      }}
      validate={claimAdjustmentResponseModalFormValidator.validate}
    >
      {() => <ClaimAdjustmentResponseFields />}
    </SubmissionFormDialog>
  );
});
