import { observer } from "mobx-react-lite";

import { Heading, Stack } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import {
  UserWorkingHoursOverridesScreenContext,
  useUserWorkingHoursOverridesScreen
} from "@modules/settings/screens/users/components/user-working-hours-overrides/context/UserWorkingHoursOverridesScreenContext.tsx";
import { UserWorkingHoursOverridesScreenHelper } from "@modules/settings/screens/users/components/user-working-hours-overrides/context/UserWorkingHoursOverridesScreenHelper.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { UserCardHeadings, UserPageHeadings } from "../labels.ts";
import { UserWorkingHoursOverridesForm } from "./components/UserWorkingHoursOverridesForm.tsx";
import {
  convertFormValueToOverride,
  convertOverridesToFormValues
} from "./components/utils.ts";
import {
  UserWorkingHoursOverridesFormValues,
  UserWorkingHoursTestElements
} from "./UserWorkingHoursOverridesScreen.types.ts";

const UserWorkingHoursOverridesScreenBase: React.FC = observer(() => {
  const { booking, core, notification } = useStores();
  const { userAvailability, setUserAvailability } =
    useUserWorkingHoursOverridesScreen();

  const { before, after } = convertOverridesToFormValues(
    userAvailability.scheduleOverrides
  );

  const onSubmitOverrides = async (
    newValues: UserWorkingHoursOverridesFormValues[],
    otherValues: UserWorkingHoursOverridesFormValues[]
  ) => {
    const newOverrides = [...newValues, ...otherValues];
    const bookingScheduleOverrides = newOverrides.map(x =>
      convertFormValueToOverride(x, core.locationId)
    );

    const userAvailabilityModel = await booking.updateUserAvailability({
      userId: userAvailability.userId,
      bookingScheduleOverrides
    });
    setUserAvailability(userAvailabilityModel);
    notification.success("Exceptions have been updated.");
  };

  return (
    <Stack styles={{ root: { paddingTop: 26 } }} tokens={{ childrenGap: 24 }}>
      <Heading
        styles={{ root: { paddingBottom: 2 } }}
        variant="section-heading"
      >
        {UserPageHeadings.exceptions}
      </Heading>
      <UserWorkingHoursOverridesForm
        containerId={UserWorkingHoursTestElements.CurrentCard}
        initialValues={after}
        onSubmit={values =>
          onSubmitOverrides(values.overrides, before.overrides)
        }
        onSubmitSucceeded={(values, form) => {
          const { after: after1 } = convertOverridesToFormValues(
            userAvailability.scheduleOverrides
          );
          form.reset(after1);
        }}
        title={UserCardHeadings.exceptions}
        iconName="awayStatus"
        emptyStateText="No current exceptions"
        emptyStateSubText="Use the button below to begin adding records to this area"
        allowAdd
      />
      <UserWorkingHoursOverridesForm
        containerId={UserWorkingHoursTestElements.PreviousCard}
        initialValues={before}
        onSubmit={values =>
          onSubmitOverrides(values.overrides, after.overrides)
        }
        onSubmitSucceeded={(values, form) => {
          const { before } = convertOverridesToFormValues(
            userAvailability.scheduleOverrides
          );
          form.reset(before);
        }}
        title={UserCardHeadings.exceptionsPrevious}
        iconName="History"
        emptyStateText="Expired exceptions populate here"
        emptyStateSubText="This section will begin to populate as records expire"
      />
    </Stack>
  );
});

const UserWorkingHoursOverridesScreen: React.FC = () => {
  const fetch = async (root: RootStore) => {
    const userId = root.routing.match(
      routes.settings.users.userWorkingHoursOverrides
    )?.params.id;

    const userAvailability = await root.booking.getUserAvailability(userId!);
    return new UserWorkingHoursOverridesScreenHelper(root, userAvailability);
  };

  return (
    <DataFetcher fetch={fetch}>
      {helper => (
        <UserWorkingHoursOverridesScreenContext.Provider value={helper}>
          <UserWorkingHoursOverridesScreenBase />
        </UserWorkingHoursOverridesScreenContext.Provider>
      )}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default UserWorkingHoursOverridesScreen;
