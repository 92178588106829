import { FormApi } from "final-form";

import {
  ClinicalDataType,
  EncounterClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { MedicationsAndSupplementsFormValues } from "./MedicationsAndSupplementsFormValues.ts";
import { MedicationsAndSupplementsList } from "./MedicationsAndSupplementsList.tsx";

interface MedicationsAndSupplementsFormProps {
  clinicalRecord: ClinicalRecord;
}

export const MedicationsAndSupplementsForm: React.FunctionComponent<
  MedicationsAndSupplementsFormProps
> = ({ clinicalRecord }) => {
  const { clinical, notification } = useStores();
  const medicationsAndSupplementsValues =
    clinicalRecord.stashedClinicalData?.medicationsAndSupplements;

  const initialValues: MedicationsAndSupplementsFormValues = {
    medicationsAndSupplements:
      medicationsAndSupplementsValues?.medicationsAndSupplements ?? [{}]
  };

  const onCancel = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.MedicationsAndSupplements
    );
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "medicationsAndSupplements",
      "medicationsAndSupplementsConfirmed"
    ]);
  };

  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const getClinicalData = (values: MedicationsAndSupplementsFormValues) => {
    const eTag = clinicalRecord.clinicalData?.medicationsAndSupplements?.eTag;

    const confirmedETag =
      clinicalRecord.clinicalData?.medicationsAndSupplementsConfirmed?.eTag;

    const clinicalData: EncounterClinicalDataDto = {};
    clinicalData.medicationsAndSupplements = {
      eTag,
      medicationsAndSupplements: values.medicationsAndSupplements ?? []
    };
    clinicalData.medicationsAndSupplementsConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };

    return clinicalData;
  };

  const submitData = async (values: MedicationsAndSupplementsFormValues) => {
    const clinicalData: EncounterClinicalDataDto = getClinicalData({
      ...values
    });

    await clinicalRecord.saveClinicalData(clinicalData);
  };

  const onSubmitSucceeded = (
    values: MedicationsAndSupplementsFormValues,
    form: FormApi<MedicationsAndSupplementsFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.MedicationsAndSupplements
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onCancel();
    }
  };

  const getValues = (stashedData?: StashedEncounterClinicalData) => {
    const dirty = !!stashedData?.dirtyAreas?.get("medicationsAndSupplements");

    const medicationsAndSupplementsFormAlreadyConfirmed =
      (stashedData?.originalDto?.medicationsAndSupplementsConfirmed &&
        stashedData.originalDto?.medicationsAndSupplementsConfirmed.createLog
          ?.createdEncounterId === clinicalRecord.openEncounter?.id) ??
      false;

    const isNewMedicationsAndSupplements =
      !clinicalRecord?.clinicalData?.medicationsAndSupplementsConfirmed
        ?.createLog;

    return {
      dirty,
      medicationsAndSupplementsFormAlreadyConfirmed,
      isNewMedicationsAndSupplements
    };
  };

  const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
    const {
      dirty,
      medicationsAndSupplementsFormAlreadyConfirmed,
      isNewMedicationsAndSupplements
    } = getValues(stashedData);

    return (
      (isNewMedicationsAndSupplements ||
        medicationsAndSupplementsFormAlreadyConfirmed) &&
      !dirty
    );
  };
  return (
    <ClinicalSubmissionForm<MedicationsAndSupplementsFormValues>
      formName="general"
      autoFocus={false}
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues}
      readOnly={isViewOnlyOrDischarged}
      disableRoutePrompt
      heading="Medications & supplements"
      onCancel={onCancel}
      disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.medicationsAndSupplementsConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.medicationsAndSupplementsConfirmed
      )}
      hideSubmit={isViewOnlyOrDischarged}
      noGap
      hasSeparator
    >
      <>
        <MedicationsAndSupplementsList />
        <StashedClinicalDataFormSpy<MedicationsAndSupplementsFormValues>
          clinicalRecord={clinicalRecord}
          getData={getClinicalData}
          areasToObserve={{
            medicationsAndSupplements: [
              ClinicalDataType.MedicationsAndSupplements
            ]
          }}
        />
      </>
    </ClinicalSubmissionForm>
  );
};
