import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClinicalActivityType } from "@modules/clinical/screens/patient-record/components/clinical-activity/types/clinical-activity.type.ts";
import { ActivityDescription } from "@stores/clinical/models/ActivityDescription.ts";

import { ClinicalReminderPreferenceFormValues } from "../components/ClinicalReminderPreferenceDialog.tsx";

const nameOf = nameOfFactory<ClinicalReminderPreferenceFormValues>();
export class ClinicalActivitiesPreferenceValidator extends Validator<ClinicalReminderPreferenceFormValues> {
  constructor(
    activitiyDescriptions: ActivityDescription[],
    initialValues?: ActivityDescription
  ) {
    super();

    this.forField(nameOf("reasonText"), [
      required(),
      (val: string) => {
        if (initialValues?.reasonText.toLowerCase() === val.toLowerCase())
          return false;
        return activitiyDescriptions.some(
          x =>
            x.reasonText.toLowerCase() === val.toLocaleLowerCase() &&
            x.activityType === ClinicalActivityType.Reminder
        )
          ? ValidationMessages.noDuplicateClinicalReminderPreferenceAllowed
          : false;
      }
    ]);
  }
}
