import React, { useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  CommandBarButton,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FontWeights,
  IContextualMenuItem,
  IContextualMenuProps,
  SplitButton,
  Stack,
  TopBarDefaultHeader
} from "@bps/fluent-ui";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { CLAIM_FORM_ID } from "@modules/acc/screens/claim/components/ClaimForm.types.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimPivotTabs } from "@modules/acc/screens/shared-components/ClaimPivotTabs.tsx";
import { SendACC45EmailModal } from "@modules/acc/screens/shared-components/SendACC45EmailModal.tsx";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { patientFieldNames } from "../claim.utils.ts";
import { ClaimFormDataAttributes } from "./ClaimFormEnums.ts";
import { ClaimFormPrint, ClaimFormPrintType } from "./ClaimFormPrint.tsx";

export const DefaultFormHeader: React.FC = () => {
  const { routing, acc, core } = useStores();
  const { values, submitting } = useFormState<ClaimFormValues>({
    subscription: { values: true, submitting: true }
  });

  const claimHelper = useContext(ClaimContext);
  const form = useForm();

  const { claim, getDraftStatus, getIsLodgeEnabled } = claimHelper;
  const patientId = claim.patientId;

  const formMenu = useFormMenu(claim.patient, {
    claimId: claim.isClaimStatusesAllowToSendOnlineForm ? claim.id : undefined,
    filterForms: template => template.contextJson.includes("ClaimId")
  });

  const getPrintMenuButtonItems = (): IContextualMenuItem[] => {
    const printMenuButtonMenu: IContextualMenuItem[] = [
      {
        key: "patientForm",
        disabled: !claim.isClaimStatusesAllowToPrintOnlineForm,
        onRenderContent: () => (
          <ClaimFormPrint
            values={values}
            printType={ClaimFormPrintType.Patient}
          />
        )
      },
      {
        key: "summaryForm",
        disabled: claim.isClaimStatusesAllowToPrintOnlineForm,
        onRenderContent: () => (
          <ClaimFormPrint
            values={values}
            printType={ClaimFormPrintType.Summary}
          />
        )
      },
      {
        key: "emailSummary",
        disabled: claim.isClaimStatusesAllowToPrintOnlineForm,
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          acc.ui.setCurrentClaimToEmail(claim);
          acc.ui.setIsEmailACC45DialogVisible(true);
        },
        onRenderContent: () =>
          patientId && (
            <SendACC45EmailModal
              patientId={patientId}
              patientName={claim.patient?.fullName}
              claimDto={claim.dto}
              onSubmitSucceeded={values =>
                values.isUpdatedEmail &&
                form.change(patientFieldNames("patientEmail"), values.email)
              }
            />
          ),
        data: {
          filter: core.hasPermissions(Permission.ClaimWrite)
        }
      },
      ...formMenu
    ];
    printMenuButtonMenu.filter(
      x => x.data?.filter === undefined || x.data?.filter
    );
    return printMenuButtonMenu;
  };

  const getSubmitButtonMenuProps = (
    isPrimaryDisabled: boolean | undefined
  ): IContextualMenuProps["items"] => [
    {
      key: "saveDraftClose",
      onRender: () => (
        <DefaultButton
          onClick={() => {
            claimHelper.submitOptions = {
              redirect: true,
              claimStatus: getDraftStatus(values),
              isLodge: false
            };
          }}
          type="submit"
          form={CLAIM_FORM_ID}
          disabled={isPrimaryDisabled}
          styles={{
            root: {
              border: "none",
              width: "100%",
              textAlign: "left",
              padding: "0px 8px 0px 4px",
              height: "36px"
            },
            label: {
              fontWeight: FontWeights.regular
            }
          }}
        >
          Save draft & close
        </DefaultButton>
      )
    }
  ];

  const printButtons = (
    <CommandBarButton
      styles={{
        root: {
          marginRight: 8,
          height: 32
        }
      }}
      text="Print"
      split
      // ⚠️ to prevent warning in unit tests, onClick is required is split is true. Ilya S.
      onClick={undefined}
      menuProps={{ items: getPrintMenuButtonItems() }}
      iconProps={{ iconName: "print" }}
    />
  );

  const defaultButtons = (
    <>
      {printButtons}
      {!claim.isViewMode ? (
        <FormSubmitButtons
          styles={{
            root: {
              marginTop: 0,
              borderTopColor: "none",
              backgroundColor: "inherit",
              paddingTop: 0
            }
          }}
          hideButtonsSeparator
          submitButtonProps={{
            text: "Lodge",
            onClick: () => {
              claimHelper.submitOptions = {
                redirect: true,
                claimStatus: ClaimStatuses.Ready,
                isLodge: true
              };
            },
            disabled: !getIsLodgeEnabled(values, submitting),
            iconProps: { hidden: true }
          }}
          disableSubmitOnPristine
          extraActionsBetween={(form, isPrimaryDisabled) => (
            <Stack.Item styles={{ root: { flexShrink: 0 } }}>
              <SplitButton
                {...dataAttribute(
                  DataAttributes.Element,
                  ClaimFormDataAttributes.saveDraft
                )}
                text="Save draft"
                onClick={() => {
                  claimHelper.submitOptions = {
                    redirect: false,
                    claimStatus: getDraftStatus(values),
                    isLodge: false
                  };
                }}
                type="submit"
                disabled={isPrimaryDisabled || claim.isViewMode}
                items={getSubmitButtonMenuProps(isPrimaryDisabled)}
              />
            </Stack.Item>
          )}
          onCancel={() => {
            routing.goToFromState(routes.claims.basePath.pattern);
          }}
        />
      ) : undefined}
    </>
  );

  return (
    <TopBarDefaultHeader
      leftContainerStyles={{ root: { paddingLeft: 0 } }}
      hideBackButton
      leftContainer={<ClaimPivotTabs />}
      rightContainer={defaultButtons}
    />
  );
};
