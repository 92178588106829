import {
  BodyArea,
  InjuryArea,
  InjuryAreaAssessment,
  InjuryAreaClinicalDataDto,
  InjuryAreaGroups,
  InjuryAreaImageDto,
  InjuryAreaImageItem,
  InjuryAreaMotionAssessment,
  InjuryAreaSides,
  InjuryImageType,
  MotionAssessment,
  UpdateImageRequest
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { MotionTypeItem } from "@shared-types/clinical/motion-type-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { ClinicalRef } from "@stores/clinical/ref/ClinicalRef.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import {
  backgroundImages,
  BackgroundImageTypes
} from "../../notes/draw/constants.ts";
import { CanvasImage } from "../../notes/draw/DrawingCanvas.types.ts";
import { DEGREE_SYMBOL } from "../SOTAP.constants.ts";
import { BodyImageBackgrounds } from "../SOTAP.types.ts";
import { createDefaultInjuryAreaMotionAssessment } from "../SOTAP.utils.ts";
import {
  ElbowImageContent,
  FieldNameMap,
  FootImageContent,
  HipImageContent,
  ImagePartProp,
  ImageType,
  KneeImageContent,
  ShoulderImageContent,
  SidesCode
} from "./InjuryImagesHelper.types.ts";

export class InjuryImagesHelper {
  static mapImagesOnPart = (
    partProps: ImagePartProp[],
    initialValues: Partial<SOTAPFormValues>
  ): CanvasImage[] => {
    return partProps.map(i => {
      const indexProp = backgroundImages.filter(
        x => x.type === i.backGroundImageType
      )[0];
      return {
        canvasRef: () => {},
        backgroundImageUrl: indexProp.src,
        width: indexProp.width,
        height: indexProp.height,
        initialValue: initialValues[i.formValue],
        id: indexProp.displayOrder.toString()
      };
    });
  };

  static filterImageTypeSex = (
    injuryArea: InjuryArea,
    sex: Sex | undefined
  ) => {
    const sideCodes = new SidesCode();

    if (injuryArea === InjuryArea.Hip) {
      sideCodes.frontCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleFrontHip
          : InjuryImageType.MaleFrontHip;

      sideCodes.backCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleBackHip
          : InjuryImageType.MaleBackHip;

      sideCodes.leftSideCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleLeftSideHip
          : InjuryImageType.MaleLeftSideHip;

      sideCodes.rightSideCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleRightSideHip
          : InjuryImageType.MaleRightSideHip;
    }

    if (injuryArea === InjuryArea.Knee) {
      sideCodes.frontCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleFrontKnee
          : InjuryImageType.MaleFrontKnee;

      sideCodes.backCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleBackKnee
          : InjuryImageType.MaleBackKnee;

      sideCodes.leftSideCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleLeftSideKnee
          : InjuryImageType.MaleLeftSideKnee;

      sideCodes.rightSideCode =
        sex === Sex.Female
          ? InjuryImageType.FemaleRightSideKnee
          : InjuryImageType.MaleRightSideKnee;
    }

    return sideCodes;
  };

  private static filterShoulderImageTypeSex = (sex: Sex | undefined) => {
    const sidesCode = new SidesCode();

    switch (sex) {
      case Sex.Female:
        sidesCode.leftFrontCode = InjuryImageType.FemaleLeftShldrFront;
        sidesCode.leftSideCode = InjuryImageType.FemaleLeftShldrSide;
        sidesCode.leftBackCode = InjuryImageType.FemaleLeftShldrBack;
        sidesCode.rightFrontCode = InjuryImageType.FemaleRightShldrFront;
        sidesCode.rightSideCode = InjuryImageType.FemaleRightShldrSide;
        sidesCode.rightBackCode = InjuryImageType.FemaleRightShldrBack;
        break;
      case Sex.Other:
        sidesCode.leftFrontCode = InjuryImageType.NBLeftShldrFront;
        sidesCode.leftSideCode = InjuryImageType.NBLeftShldrSide;
        sidesCode.leftBackCode = InjuryImageType.NBLeftShldrBack;
        sidesCode.rightFrontCode = InjuryImageType.NBRightShldrFront;
        sidesCode.rightSideCode = InjuryImageType.NBRightShldrSide;
        sidesCode.rightBackCode = InjuryImageType.NBRightShldrBack;
        break;
      default:
        sidesCode.leftFrontCode = InjuryImageType.MaleLeftShldrFront;
        sidesCode.leftSideCode = InjuryImageType.MaleLeftShldrSide;
        sidesCode.leftBackCode = InjuryImageType.MaleLeftShldrBack;
        sidesCode.rightFrontCode = InjuryImageType.MaleRightShldrFront;
        sidesCode.rightSideCode = InjuryImageType.MaleRightShldrSide;
        sidesCode.rightBackCode = InjuryImageType.MaleRightShldrBack;
        break;
    }

    return sidesCode;
  };

  private static filterElbowImageTypeSex = (sex: Sex | undefined) => {
    const sidesCode = new SidesCode();
    switch (sex) {
      case Sex.Female:
        sidesCode.leftSideCode = InjuryImageType.FemaleLeftElbow;
        sidesCode.rightSideCode = InjuryImageType.FemaleRightElbow;
        break;
      case Sex.Other:
        sidesCode.leftSideCode = InjuryImageType.NBLeftElbow;
        sidesCode.rightSideCode = InjuryImageType.NBRightElbow;
        break;
      default:
        sidesCode.leftSideCode = InjuryImageType.MaleLeftElbow;
        sidesCode.rightSideCode = InjuryImageType.MaleRightElbow;
        break;
    }

    return sidesCode;
  };

  static filterBackGroundImageTypeSex = (
    injuryArea: InjuryArea,
    sex: Sex | undefined
  ) => {
    const sideCodes = new SidesCode();

    if (injuryArea === InjuryArea.Hip) {
      sideCodes.frontCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleFrontHip
          : BackgroundImageTypes.MaleFrontHip;

      sideCodes.backCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleBackHip
          : BackgroundImageTypes.MaleBackHip;

      sideCodes.leftSideCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleLeftSideHip
          : BackgroundImageTypes.MaleLeftSideHip;

      sideCodes.rightSideCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleRightSideHip
          : BackgroundImageTypes.MaleRightSideHip;
    }

    if (injuryArea === InjuryArea.Knee) {
      sideCodes.frontCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleFrontKnee
          : BackgroundImageTypes.MaleFrontKnee;

      sideCodes.backCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleBackKnee
          : BackgroundImageTypes.MaleBackKnee;

      sideCodes.leftSideCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleLeftSideKnee
          : BackgroundImageTypes.MaleLeftSideKnee;

      sideCodes.rightSideCode =
        sex === Sex.Female
          ? BackgroundImageTypes.FemaleRightSideKnee
          : BackgroundImageTypes.MaleRightSideKnee;
    }
    return sideCodes;
  };

  private static filterElbowBackImageTypeSex = (sex: Sex | undefined) => {
    const sidesCode = new SidesCode();

    switch (sex) {
      case Sex.Female:
        sidesCode.leftSideCode = BackgroundImageTypes.FemaleLeftElbow;
        sidesCode.rightSideCode = BackgroundImageTypes.FemaleRightElbow;
        break;
      case Sex.Other:
        sidesCode.leftSideCode = BackgroundImageTypes.NBLeftElbow;
        sidesCode.rightSideCode = BackgroundImageTypes.NBRightElbow;
        break;
      default:
        sidesCode.leftSideCode = BackgroundImageTypes.MaleLeftElbow;
        sidesCode.rightSideCode = BackgroundImageTypes.MaleRightElbow;
        break;
    }

    return sidesCode;
  };

  private static filterShoulderBackImageTypeSex = (sex: Sex | undefined) => {
    const sidesCode = new SidesCode();

    switch (sex) {
      case Sex.Female:
        sidesCode.leftFrontCode = BackgroundImageTypes.FemaleLeftShldrFront;
        sidesCode.leftSideCode = BackgroundImageTypes.FemaleLeftShldrSide;
        sidesCode.leftBackCode = BackgroundImageTypes.FemaleLeftShldrBack;
        sidesCode.rightFrontCode = BackgroundImageTypes.FemaleRightShldrFront;
        sidesCode.rightSideCode = BackgroundImageTypes.FemaleRightShldrSide;
        sidesCode.rightBackCode = BackgroundImageTypes.FemaleRightShldrBack;
        break;
      case Sex.Other:
        sidesCode.leftFrontCode = BackgroundImageTypes.NBLeftShldrFront;
        sidesCode.leftSideCode = BackgroundImageTypes.NBLeftShldrSide;
        sidesCode.leftBackCode = BackgroundImageTypes.NBLeftShldrBack;
        sidesCode.rightFrontCode = BackgroundImageTypes.NBRightShldrFront;
        sidesCode.rightSideCode = BackgroundImageTypes.NBRightShldrSide;
        sidesCode.rightBackCode = BackgroundImageTypes.NBRightShldrBack;
        break;
      default:
        sidesCode.leftFrontCode = BackgroundImageTypes.MaleLeftShldrFront;
        sidesCode.leftSideCode = BackgroundImageTypes.MaleLeftShldrSide;
        sidesCode.leftBackCode = BackgroundImageTypes.MaleLeftShldrBack;
        sidesCode.rightFrontCode = BackgroundImageTypes.MaleRightShldrFront;
        sidesCode.rightSideCode = BackgroundImageTypes.MaleRightShldrSide;
        sidesCode.rightBackCode = BackgroundImageTypes.MaleRightShldrBack;
        break;
    }
    return sidesCode;
  };

  static spinalCordProperties = (fieldNameMap?: FieldNameMap) => {
    const { front, side, back } = fieldNameMap || {};
    return [
      {
        formValue: front ?? "spinalCordFrontImage",
        imageType: InjuryImageType.SpinalCordFront,
        backGroundImageType: BackgroundImageTypes.SpinalCordFront,
        bodyArea: BodyArea.Spine
      },
      {
        formValue: side ?? "spinalCordSideImage",
        imageType: InjuryImageType.SpinalCordSide,
        backGroundImageType: BackgroundImageTypes.SpinalCordSide,
        bodyArea: BodyArea.Spine
      },
      {
        formValue: back ?? "spinalCordBackImage",
        imageType: InjuryImageType.SpinalCordBack,
        backGroundImageType: BackgroundImageTypes.SpinalCordBack,
        bodyArea: BodyArea.Spine
      }
    ];
  };

  static footProperties = (fieldNameMap?: FieldNameMap) => {
    const {
      front,
      back,
      top,
      bottom,
      leftInside,
      rightInside,
      leftOutside,
      rightOutside
    } = fieldNameMap || {};
    return [
      {
        formValue: front ?? "frontFootImage",
        imageType: InjuryImageType.FootFront,
        backGroundImageType: BackgroundImageTypes.FootFront,
        bodyArea: BodyArea.AnkleFoot
      },
      {
        formValue: back ?? "backFootImage",
        imageType: InjuryImageType.FootBack,
        backGroundImageType: BackgroundImageTypes.FootBack,
        bodyArea: BodyArea.AnkleFoot
      },
      {
        formValue: bottom ?? "bottomFootImage",
        imageType: InjuryImageType.FootBottom,
        backGroundImageType: BackgroundImageTypes.FootBottom,
        bodyArea: BodyArea.AnkleFoot
      },
      {
        formValue: top ?? "topFootImage",
        imageType: InjuryImageType.FootTop,
        backGroundImageType: BackgroundImageTypes.FootTop,
        bodyArea: BodyArea.AnkleFoot
      },
      {
        formValue: leftInside ?? "leftInsideFootImage",
        imageType: InjuryImageType.FootInsideLeft,
        backGroundImageType: BackgroundImageTypes.FootInsideLeft,
        bodyArea: BodyArea.AnkleFoot
      },
      {
        formValue: rightInside ?? "rightInsideFootImage",
        imageType: InjuryImageType.FootInsideRight,
        backGroundImageType: BackgroundImageTypes.FootInsideRight,
        bodyArea: BodyArea.AnkleFoot
      },
      {
        formValue: leftOutside ?? "leftOutsideFootImage",
        imageType: InjuryImageType.FootOutsideLeft,
        backGroundImageType: BackgroundImageTypes.FootOutsideLeft,
        bodyArea: BodyArea.AnkleFoot
      },
      {
        formValue: rightOutside ?? "rightOutsideFootImage",
        imageType: InjuryImageType.FootOutsideRight,
        backGroundImageType: BackgroundImageTypes.FootOutsideRight,
        bodyArea: BodyArea.AnkleFoot
      }
    ];
  };

  static getHipProperties = (
    sex: Sex | undefined,
    fieldNameMap?: FieldNameMap
  ) => {
    const sideCodes = InjuryImagesHelper.filterImageTypeSex(
      InjuryArea.Hip,
      sex
    );

    const backGroundCodes = InjuryImagesHelper.filterBackGroundImageTypeSex(
      InjuryArea.Hip,
      sex
    );

    const { front, back, leftSide, rightSide } = fieldNameMap || {};
    return [
      {
        formValue: front ?? "frontHipImage",
        imageType: sideCodes.frontCode,
        backGroundImageType: backGroundCodes.frontCode,
        bodyArea: BodyArea.Hip
      },
      {
        formValue: back ?? "backHipImage",
        imageType: sideCodes.backCode,
        backGroundImageType: backGroundCodes.backCode,
        bodyArea: BodyArea.Hip
      },
      {
        formValue: leftSide ?? "leftSideHipImage",
        imageType: sideCodes.leftSideCode,
        backGroundImageType: backGroundCodes.leftSideCode,
        bodyArea: BodyArea.Hip
      },
      {
        formValue: rightSide ?? "rightSideHipImage",
        imageType: sideCodes.rightSideCode,
        backGroundImageType: backGroundCodes.rightSideCode,
        bodyArea: BodyArea.Hip
      }
    ];
  };

  static getFullBodyProperties = (sex?: Sex, fieldNameMap?: FieldNameMap) => {
    const { front, back, leftSide, rightSide } = fieldNameMap || {};
    const imageProps: ImagePartProp[] = [
      {
        formValue: front ?? "frontImage",
        imageType: "",
        backGroundImageType:
          sex === Sex.Female
            ? BackgroundImageTypes.FemaleFront
            : BackgroundImageTypes.MaleFront,
        bodyArea: BodyArea.Body
      },
      {
        formValue: back ?? "backImage",
        imageType: "",
        backGroundImageType:
          sex === Sex.Female
            ? BackgroundImageTypes.FemaleBack
            : BackgroundImageTypes.MaleBack,
        bodyArea: BodyArea.Body
      },
      {
        formValue: leftSide ?? "leftImage",
        imageType: "",
        backGroundImageType:
          sex === Sex.Female
            ? BackgroundImageTypes.FemaleLeftSide
            : BackgroundImageTypes.MaleLeftSide,
        bodyArea: BodyArea.Body
      },
      {
        formValue: rightSide ?? "rightImage",
        imageType: "",
        backGroundImageType:
          sex === Sex.Female
            ? BackgroundImageTypes.FemaleRightSide
            : BackgroundImageTypes.MaleRightSide,
        bodyArea: BodyArea.Body
      }
    ];

    return imageProps;
  };

  static getKneeProperties = (
    sex: Sex | undefined,
    fieldNameMap?: FieldNameMap
  ) => {
    const sideCodes = InjuryImagesHelper.filterImageTypeSex(
      InjuryArea.Knee,
      sex
    );

    const backGroundCodes = InjuryImagesHelper.filterBackGroundImageTypeSex(
      InjuryArea.Knee,
      sex
    );

    const { front, back, leftSide, rightSide } = fieldNameMap || {};
    return [
      {
        formValue: front ?? "frontKneeImage",
        imageType: sideCodes.frontCode,
        backGroundImageType: backGroundCodes.frontCode,
        bodyArea: BodyArea.Knee
      },
      {
        formValue: back ?? "backKneeImage",
        imageType: sideCodes.backCode,
        backGroundImageType: backGroundCodes.backCode,
        bodyArea: BodyArea.Knee
      },
      {
        formValue: leftSide ?? "leftSideKneeImage",
        imageType: sideCodes.leftSideCode,
        backGroundImageType: backGroundCodes.leftSideCode,
        bodyArea: BodyArea.Knee
      },
      {
        formValue: rightSide ?? "rightSideKneeImage",
        imageType: sideCodes.rightSideCode,
        backGroundImageType: backGroundCodes.rightSideCode,
        bodyArea: BodyArea.Knee
      }
    ];
  };

  static getShoulderProperties = (
    sex: Sex | undefined,
    fieldNameMap?: FieldNameMap
  ) => {
    const sidesCode = InjuryImagesHelper.filterShoulderImageTypeSex(sex);
    const backGroundSidesCode =
      InjuryImagesHelper.filterShoulderBackImageTypeSex(sex);

    const { leftFront, rightFront, leftBack, rightBack, leftSide, rightSide } =
      fieldNameMap || {};
    return [
      {
        formValue: leftFront ?? "leftFrontShoulderImage",
        imageType: sidesCode.leftFrontCode,
        backGroundImageType: backGroundSidesCode.leftFrontCode,
        bodyArea: BodyArea.Shoulder
      },
      {
        formValue: leftBack ?? "leftBackShoulderImage",
        imageType: sidesCode.leftBackCode,
        backGroundImageType: backGroundSidesCode.leftBackCode,
        bodyArea: BodyArea.Shoulder
      },
      {
        formValue: leftSide ?? "leftSideShoulderImage",
        imageType: sidesCode.leftSideCode,
        backGroundImageType: backGroundSidesCode.leftSideCode,
        bodyArea: BodyArea.Shoulder
      },
      {
        formValue: rightFront ?? "rightFrontShoulderImage",
        imageType: sidesCode.rightFrontCode,
        backGroundImageType: backGroundSidesCode.rightFrontCode,
        bodyArea: BodyArea.Shoulder
      },
      {
        formValue: rightBack ?? "rightBackShoulderImage",
        imageType: sidesCode.rightBackCode,
        backGroundImageType: backGroundSidesCode.rightBackCode,
        bodyArea: BodyArea.Shoulder
      },
      {
        formValue: rightSide ?? "rightSideShoulderImage",
        imageType: sidesCode.rightSideCode,
        backGroundImageType: backGroundSidesCode.rightSideCode,
        bodyArea: BodyArea.Shoulder
      }
    ];
  };

  static getElbowProperties = (
    sex: Sex | undefined,
    fieldNameMap?: FieldNameMap
  ) => {
    const sidesCode = InjuryImagesHelper.filterElbowImageTypeSex(sex);
    const backGroundSidesCode =
      InjuryImagesHelper.filterElbowBackImageTypeSex(sex);

    const { leftSide, rightSide } = fieldNameMap || {};

    return [
      {
        formValue: leftSide ?? "leftElbowImage",
        imageType: sidesCode.leftSideCode,
        backGroundImageType: backGroundSidesCode.leftSideCode,
        bodyArea: BodyArea.Elbow
      },
      {
        formValue: rightSide ?? "rightElbowImage",
        imageType: sidesCode.rightSideCode,
        backGroundImageType: backGroundSidesCode.rightSideCode,
        bodyArea: BodyArea.Elbow
      }
    ];
  };

  static getAbdomenProperties = (sex?: Sex, fieldNameMap?: FieldNameMap) => {
    const { front } = fieldNameMap || {};
    const imageProps: ImagePartProp[] = [
      {
        formValue: front ?? "frontImage",
        imageType: "",
        backGroundImageType:
          sex === Sex.Female
            ? BackgroundImageTypes.FemaleAbdomen
            : BackgroundImageTypes.MaleAbdomen,
        bodyArea: BodyArea.Abdomen
      }
    ];

    return imageProps;
  };

  static getCrainialProperties = (fieldNameMap?: FieldNameMap) => {
    const { front } = fieldNameMap || {};
    const imageProps: ImagePartProp[] = [
      {
        formValue: front ?? "frontImage",
        imageType: InjuryImageType.CentralNervousSystemCranial,
        backGroundImageType: BackgroundImageTypes.CentralNervousSystemCranial,
        bodyArea: BodyArea.CentralNervousSystemCranial
      }
    ];

    return imageProps;
  };

  static getDermatomesProperties = (fieldNameMap?: FieldNameMap) => {
    const { front, back, top, bottom, side } = fieldNameMap || {};
    const imageProps: ImagePartProp[] = [
      {
        formValue: front ?? "dermatomesFront",
        imageType: InjuryImageType.DermatomesFront,
        backGroundImageType: BackgroundImageTypes.DermatomesFront,
        bodyArea: BodyArea.DermatomesAndMyotomes
      },
      {
        formValue: back ?? "dermatomesBack",
        imageType: InjuryImageType.DermatomesBack,
        backGroundImageType: BackgroundImageTypes.DermatomesBack,
        bodyArea: BodyArea.DermatomesAndMyotomes
      },
      {
        formValue: top ?? "dermatomesSpine",
        imageType: InjuryImageType.DermatomesSpine,
        backGroundImageType: BackgroundImageTypes.DermatomesSpine,
        bodyArea: BodyArea.DermatomesAndMyotomes
      },
      {
        formValue: bottom ?? "dermatomesFace",
        imageType: InjuryImageType.DermatomesFace,
        backGroundImageType: BackgroundImageTypes.DermatomesFace,
        bodyArea: BodyArea.DermatomesAndMyotomes
      },
      {
        formValue: side ?? "dermatomesSide",
        imageType: InjuryImageType.DermatomesSide,
        backGroundImageType: BackgroundImageTypes.DermatomesSide,
        bodyArea: BodyArea.DermatomesAndMyotomes
      }
    ];

    return imageProps;
  };

  public static hasKneeData(values: SOTAPFormValues) {
    return (
      !!values.frontKneeImage ||
      !!values.backKneeImage ||
      !!values.leftSideKneeImage ||
      !!values.rightSideKneeImage
    );
  }
  public static hasHipData(values: SOTAPFormValues) {
    return (
      !!values.frontHipImage ||
      !!values.backHipImage ||
      !!values.leftSideHipImage ||
      !!values.rightSideHipImage
    );
  }
  public static hasFootData(values: SOTAPFormValues) {
    return (
      !!values.frontFootImage ||
      !!values.backFootImage ||
      !!values.topFootImage ||
      !!values.bottomFootImage ||
      !!values.rightInsideFootImage ||
      !!values.leftInsideFootImage ||
      !!values.rightOutsideFootImage ||
      !!values.leftOutsideFootImage
    );
  }

  public static hasShoulderData(values: SOTAPFormValues) {
    return (
      !!values.leftFrontShoulderImage ||
      !!values.leftBackShoulderImage ||
      !!values.leftSideShoulderImage ||
      !!values.rightFrontShoulderImage ||
      !!values.rightBackShoulderImage ||
      !!values.rightSideShoulderImage
    );
  }

  public static hasElbowData(values: SOTAPFormValues) {
    return !!values.leftElbowImage || !!values.rightElbowImage;
  }

  public static getKneeImageContents = (
    injuryAreaImageItems: InjuryAreaImageItem[],
    sex: Sex | undefined
  ) => {
    const sideCodes = InjuryImagesHelper.filterImageTypeSex(
      InjuryArea.Knee,
      sex
    );

    const frontContent = injuryAreaImageItems.find(
      x => x.injuryArea === InjuryArea.Knee && x.type === sideCodes.frontCode
    )?.content;

    const backContent = injuryAreaImageItems.find(
      x => x.injuryArea === InjuryArea.Knee && x.type === sideCodes.backCode
    )?.content;

    const leftSideContent = injuryAreaImageItems.find(
      x => x.injuryArea === InjuryArea.Knee && x.type === sideCodes.leftSideCode
    )?.content;

    const rightSideContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Knee && x.type === sideCodes.rightSideCode
    )?.content;

    const kneeImageContent: KneeImageContent = {
      frontKneeImage:
        frontContent !== undefined ? atob(frontContent) : undefined,
      backKneeImage: backContent !== undefined ? atob(backContent) : undefined,
      leftSideKneeImage:
        leftSideContent !== undefined ? atob(leftSideContent) : undefined,
      rightSideKneeImage:
        rightSideContent !== undefined ? atob(rightSideContent) : undefined
    };

    return kneeImageContent;
  };

  public static getHipImageContents = (
    injuryAreaImageItems: InjuryAreaImageItem[],
    sex: Sex | undefined
  ) => {
    const sideCodes = InjuryImagesHelper.filterImageTypeSex(
      InjuryArea.Hip,
      sex
    );

    const frontContent = injuryAreaImageItems.find(
      x => x.injuryArea === InjuryArea.Hip && x.type === sideCodes.frontCode
    )?.content;

    const backContent = injuryAreaImageItems.find(
      x => x.injuryArea === InjuryArea.Hip && x.type === sideCodes.backCode
    )?.content;

    const leftSideContent = injuryAreaImageItems.find(
      x => x.injuryArea === InjuryArea.Hip && x.type === sideCodes.leftSideCode
    )?.content;

    const rightSideContent = injuryAreaImageItems.find(
      x => x.injuryArea === InjuryArea.Hip && x.type === sideCodes.rightSideCode
    )?.content;

    const hipImageContent: HipImageContent = {
      frontHipImage:
        frontContent !== undefined ? atob(frontContent) : undefined,
      backHipImage: backContent !== undefined ? atob(backContent) : undefined,
      leftSideHipImage:
        leftSideContent !== undefined ? atob(leftSideContent) : undefined,
      rightSideHipImage:
        rightSideContent !== undefined ? atob(rightSideContent) : undefined
    };

    return hipImageContent;
  };

  public static getFootImageContents = (
    injuryAreaImageItems: InjuryAreaImageItem[]
  ) => {
    const frontContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootFront
    )?.content;

    const backContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootBack
    )?.content;

    const topContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootTop
    )?.content;

    const bottomContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootBottom
    )?.content;

    const leftInnerContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootInsideLeft
    )?.content;

    const rightInnerContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootInsideRight
    )?.content;

    const leftOuterContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootOutsideLeft
    )?.content;

    const rightOutsideContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.AnkleFoot &&
        x.type === InjuryImageType.FootOutsideRight
    )?.content;

    const footImageContent: FootImageContent = {
      frontFootImage:
        frontContent !== undefined ? atob(frontContent) : undefined,
      backFootImage: backContent !== undefined ? atob(backContent) : undefined,
      topFootImage: topContent !== undefined ? atob(topContent) : undefined,
      bottomFootImage:
        bottomContent !== undefined ? atob(bottomContent) : undefined,
      leftInsideFootImage:
        leftInnerContent !== undefined ? atob(leftInnerContent) : undefined,
      rightInsideFootImage:
        rightInnerContent !== undefined ? atob(rightInnerContent) : undefined,
      leftOutsideFootImage:
        leftOuterContent !== undefined ? atob(leftOuterContent) : undefined,
      rightOutsideFootImage:
        rightOutsideContent !== undefined
          ? atob(rightOutsideContent)
          : undefined
    };
    return footImageContent;
  };

  public static getShoulderImageContent = (
    injuryAreaImageItems: InjuryAreaImageItem[],
    sex: Sex | undefined
  ) => {
    const sidesCode = InjuryImagesHelper.filterShoulderImageTypeSex(sex);

    const leftFrontShoulderContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Shoulder &&
        x.type === sidesCode.leftFrontCode
    )?.content;

    const leftBackShoulderContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Shoulder &&
        x.type === sidesCode.leftBackCode
    )?.content;

    const leftSideShoulderContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Shoulder &&
        x.type === sidesCode.leftSideCode
    )?.content;

    const rightFrontShoulderContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Shoulder &&
        x.type === sidesCode.rightFrontCode
    )?.content;

    const rightBackShoulderContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Shoulder &&
        x.type === sidesCode.rightBackCode
    )?.content;

    const rightSideShoulderContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Shoulder &&
        x.type === sidesCode.rightSideCode
    )?.content;

    const shoulderImageContent: ShoulderImageContent = {
      leftFrontShoulderImage:
        leftFrontShoulderContent !== undefined
          ? atob(leftFrontShoulderContent)
          : undefined,
      leftBackShoulderImage:
        leftBackShoulderContent !== undefined
          ? atob(leftBackShoulderContent)
          : undefined,
      leftSideShoulderImage:
        leftSideShoulderContent !== undefined
          ? atob(leftSideShoulderContent)
          : undefined,
      rightFrontShoulderImage:
        rightFrontShoulderContent !== undefined
          ? atob(rightFrontShoulderContent)
          : undefined,
      rightBackShoulderImage:
        rightBackShoulderContent !== undefined
          ? atob(rightBackShoulderContent)
          : undefined,
      rightSideShoulderImage:
        rightSideShoulderContent !== undefined
          ? atob(rightSideShoulderContent)
          : undefined
    };
    return shoulderImageContent;
  };

  public static getElbowImageContent = (
    injuryAreaImageItems: InjuryAreaImageItem[],
    sex: Sex | undefined
  ) => {
    const sideCodes = InjuryImagesHelper.filterElbowImageTypeSex(sex);

    const leftSideContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Elbow && x.type === sideCodes.leftSideCode
    )?.content;

    const rightSideContent = injuryAreaImageItems.find(
      x =>
        x.injuryArea === InjuryArea.Elbow && x.type === sideCodes.rightSideCode
    )?.content;

    const elbowImageContent: ElbowImageContent = {
      leftElbowImage:
        leftSideContent !== undefined ? atob(leftSideContent) : undefined,
      rightElbowImage:
        rightSideContent !== undefined ? atob(rightSideContent) : undefined
    };

    return elbowImageContent;
  };

  public static updateKneeInjuryImage = (
    values: SOTAPFormValues,
    currentInjuryArea: InjuryAreaClinicalDataDto,
    sex: Sex | undefined
  ) => {
    let newInjuryAreasData: InjuryAreaClinicalDataDto | undefined;
    const hipProps = InjuryImagesHelper.getKneeProperties(sex);

    hipProps.forEach(value => {
      if (values[value.formValue]) {
        newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: currentInjuryArea,
          injuryImageType: value.imageType,
          imageContent: values[value.formValue],
          injuryArea: InjuryArea.Knee
        });
      }
    });

    return newInjuryAreasData ?? currentInjuryArea;
  };

  public static updateHipInjuryImage = (
    values: SOTAPFormValues,
    currentInjuryArea: InjuryAreaClinicalDataDto,
    sex: Sex | undefined
  ) => {
    let newInjuryAreasData: InjuryAreaClinicalDataDto | undefined;

    const hipProps = InjuryImagesHelper.getHipProperties(sex);

    hipProps.forEach(value => {
      if (values[value.formValue]) {
        newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: currentInjuryArea,
          injuryImageType: value.imageType,
          imageContent: values[value.formValue],
          injuryArea: InjuryArea.Hip
        });
      }
    });

    return newInjuryAreasData ?? currentInjuryArea;
  };

  public static updateFootInjuryImage = (
    values: SOTAPFormValues,
    currentInjuryArea: InjuryAreaClinicalDataDto
  ) => {
    let newInjuryAreasData: InjuryAreaClinicalDataDto | undefined;

    InjuryImagesHelper.footProperties().forEach(value => {
      if (values[value.formValue]) {
        newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: currentInjuryArea,
          injuryImageType: value.imageType,
          imageContent: values[value.formValue],
          injuryArea: InjuryArea.AnkleFoot
        });
      }
    });

    return newInjuryAreasData ?? currentInjuryArea;
  };

  public static updateShoulderInjuryImage = (
    values: SOTAPFormValues,
    currentInjuryArea: InjuryAreaClinicalDataDto,
    sex: Sex | undefined
  ) => {
    let newInjuryAreasData: InjuryAreaClinicalDataDto | undefined;
    const shoulderValues = InjuryImagesHelper.getShoulderProperties(sex);
    shoulderValues.forEach(value => {
      if (values[value.formValue]) {
        newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: currentInjuryArea,
          injuryImageType: value.imageType,
          imageContent: values[value.formValue],
          injuryArea: InjuryArea.Shoulder
        });
      }
    });
    return newInjuryAreasData ?? currentInjuryArea;
  };

  public static updateElbowInjuryImage = (
    values: SOTAPFormValues,
    currentInjuryArea: InjuryAreaClinicalDataDto,
    sex: Sex | undefined
  ) => {
    let newInjuryAreasData: InjuryAreaClinicalDataDto | undefined;
    const shoulderValues = InjuryImagesHelper.getElbowProperties(sex);
    shoulderValues.forEach(value => {
      if (values[value.formValue]) {
        newInjuryAreasData = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: currentInjuryArea,
          injuryImageType: value.imageType,
          imageContent: values[value.formValue],
          injuryArea: InjuryArea.Elbow
        });
      }
    });
    return newInjuryAreasData ?? currentInjuryArea;
  };

  static getCanvasBodyImages = (
    initialImages: BodyImageBackgrounds,
    patient: Contact | undefined
  ): CanvasImage[] => {
    const frontImageType =
      patient?.sex === Sex.Female
        ? BackgroundImageTypes.FemaleFront
        : BackgroundImageTypes.MaleFront;

    const backImageType =
      patient?.sex === Sex.Female
        ? BackgroundImageTypes.FemaleBack
        : BackgroundImageTypes.MaleBack;

    const leftSideImageType =
      patient?.sex === Sex.Female
        ? BackgroundImageTypes.FemaleLeftSide
        : BackgroundImageTypes.MaleLeftSide;

    const rightSideImageType =
      patient?.sex === Sex.Female
        ? BackgroundImageTypes.FemaleRightSide
        : BackgroundImageTypes.MaleRightSide;

    const frontImage = backgroundImages.find(x => x.type === frontImageType);

    const backImage = backgroundImages.find(x => x.type === backImageType);

    const leftSideImage = backgroundImages.find(
      x => x.type === leftSideImageType
    );

    const rightSideImage = backgroundImages.find(
      x => x.type === rightSideImageType
    );

    return [
      {
        canvasRef: () => {},
        backgroundImageUrl: frontImage?.src,
        width: frontImage?.width ?? 0,
        height: frontImage?.height ?? 0,
        initialValue: initialImages.initialFrontImage,
        id: ImageType.frontImage.toString()
      },
      {
        canvasRef: () => {},
        backgroundImageUrl: backImage?.src,
        width: backImage?.width ?? 0,
        height: backImage?.height ?? 0,
        initialValue: initialImages.initialBackImage,
        id: ImageType.backImage.toString()
      },
      {
        canvasRef: () => {},
        backgroundImageUrl: leftSideImage?.src,
        width: leftSideImage?.width ?? 0,
        height: leftSideImage?.height ?? 0,
        initialValue: initialImages.initialLeftSideImage,
        id: ImageType.leftImage.toString()
      },
      {
        canvasRef: () => {},
        backgroundImageUrl: rightSideImage?.src,
        width: rightSideImage?.width ?? 0,
        height: rightSideImage?.height ?? 0,
        initialValue: initialImages.initialRightSideImage,
        id: ImageType.rightImage.toString()
      }
    ];
  };

  public static getInjuryAreaIndex = (
    assessments: InjuryAreaAssessment[],
    injuryArea: string
  ) => {
    return assessments.findIndex(x => x.injuryArea === injuryArea);
  };

  public static updateInjuryImage = (
    request: UpdateImageRequest
  ): InjuryAreaClinicalDataDto => {
    if (!request.injuryAreaData.assessments) {
      request.injuryAreaData.assessments = [];
    }

    // No image content?
    if (request.imageContent.length === 0) return request.injuryAreaData;

    let injuryAreaIndex = InjuryImagesHelper.getInjuryAreaIndex(
      request.injuryAreaData?.assessments,
      request.injuryArea
    );

    if (injuryAreaIndex === -1) {
      const injuryAreaAssessment: InjuryAreaAssessment = {
        injuryArea: request.injuryArea
      };
      request.injuryAreaData.assessments.push(injuryAreaAssessment);
      injuryAreaIndex = request.injuryAreaData.assessments.length - 1;
    }

    const imageIndex = InjuryImagesHelper.getInjuryImageIndex(
      request.injuryAreaData?.assessments,
      request.injuryImageType,
      injuryAreaIndex
    );

    if (imageIndex !== -1) {
      const updatedImage: InjuryAreaImageDto = {
        type: request.injuryImageType,
        content: btoa(request.imageContent)
      };
      request.injuryAreaData?.assessments &&
        request.injuryAreaData?.assessments[
          injuryAreaIndex
        ]?.injuryAreaImages?.splice(imageIndex, 1, updatedImage);
    } else {
      const image = {
        type: request.injuryImageType,
        content: btoa(request.imageContent)
      };

      if (request.injuryAreaData?.assessments) {
        if (
          !request.injuryAreaData.assessments[injuryAreaIndex].injuryAreaImages
        ) {
          request.injuryAreaData.assessments[injuryAreaIndex].injuryAreaImages =
            [];
        }

        request.injuryAreaData.assessments[
          injuryAreaIndex
        ].injuryAreaImages?.push(image);
      }
    }

    return request.injuryAreaData;
  };

  public static getInjuryImageIndex = (
    assessments: InjuryAreaAssessment[],
    injuryImageType: string,
    injuryAreaIndex: number
  ) => {
    const injuryAreaImages = (assessments ?? [])[injuryAreaIndex]
      ?.injuryAreaImages;
    if (injuryAreaImages) {
      return injuryAreaImages.findIndex(i => i.type === injuryImageType);
    }
    return -1;
  };

  public static getInjuryAreas = (assessments?: InjuryAreaAssessment[]) => {
    const injuryAreas: InjuryArea[] = [];
    assessments?.forEach(assesment => {
      const { injuryArea } = assesment;
      if (assesment && injuryArea) {
        injuryAreas.push(injuryArea);
      }
    });
    return injuryAreas;
  };

  private static getHandInjuryAreaData = (
    injuryAreaMotionAssessmentItems: InjuryAreaMotionAssessmentItem[],
    clinicalRef: ClinicalRef
  ) => {
    const newInjuryParts: InjuryAreaMotionAssessmentItem[] = [];
    const areas = [
      InjuryAreaGroups.Hand,
      InjuryAreaGroups.Thumb,
      InjuryAreaGroups.Finger
    ];

    // Loop between each area, check if they have a value and if not populate them.
    areas.forEach(areaGroup => {
      const handAreaAssesmentItem = injuryAreaMotionAssessmentItems.find(
        x =>
          x.injuryArea === InjuryArea.HandAndWrist &&
          x.injuryAreaGroup === areaGroup &&
          x.injurySide === InjuryAreaSides.Left
      );

      if (!handAreaAssesmentItem) {
        newInjuryParts.push(
          createDefaultInjuryAreaMotionAssessment({
            injuryArea: InjuryArea.HandAndWrist,
            injuryAreaGroup: areaGroup,
            refData: [...clinicalRef.motionTypes.values],
            injurySide: InjuryAreaSides.Left
          })
        );

        newInjuryParts.push(
          createDefaultInjuryAreaMotionAssessment({
            injuryArea: InjuryArea.HandAndWrist,
            injuryAreaGroup: areaGroup,
            refData: [...clinicalRef.motionTypes.values],
            injurySide: InjuryAreaSides.Right
          })
        );
      }
    });
    return newInjuryParts;
  };

  public static getInjuryAreaData = (
    data: InjuryAreaClinicalDataDto | undefined,
    clinicalRef: ClinicalRef
  ) => {
    const injuryAreas = data
      ? InjuryImagesHelper.getInjuryAreas(data?.assessments)
      : [];

    const { injuryAreaMotionAssessmentItems, injuryAreaImageItems } =
      InjuryImagesHelper.getMotionAndImageAssesments(data);

    /**
     * If you don't have assessment, you need to add a default one in order to render the datalist.
     * In the initial SOTAP form, the bug was reporting for the injury areas where their non imagery data was not entered. In the follow-up however the whole assessment goes missing/lost when you start a consult.
     * It checks if there is no prior assessment, fill it with default values that the respective component needs.
     */
    const injuryAreasRefValues = [...clinicalRef.injuryAreas.keyTextValues];

    injuryAreasRefValues.forEach(injuryAreaRef => {
      const injuryArea = injuryAreaRef.key;

      // The hand has several groups that exist at the one time, meaning we need to check if each group has a value and populate accordingly
      if (injuryArea === InjuryArea.HandAndWrist) {
        const handAssesmentItems = InjuryImagesHelper.getHandInjuryAreaData(
          injuryAreaMotionAssessmentItems,
          clinicalRef
        );

        handAssesmentItems.forEach(x =>
          injuryAreaMotionAssessmentItems.push(x)
        );
      } else {
        const isAssementDataPresent =
          injuryAreaMotionAssessmentItems.findIndex(
            item => item.injuryArea === injuryArea
          ) >= 0;

        if (!isAssementDataPresent) {
          if (injuryArea === InjuryArea.Spine) {
            const injuryAreaGroups = [
              InjuryAreaGroups.Cervical,
              InjuryAreaGroups.Lumbosacral,
              InjuryAreaGroups.Thoracic
            ];
            injuryAreaGroups.forEach(injuryAreaGroup => {
              injuryAreaMotionAssessmentItems.push(
                createDefaultInjuryAreaMotionAssessment({
                  injuryArea,
                  injuryAreaGroup,
                  refData: [...clinicalRef.motionTypes.values],
                  injurySide: undefined
                })
              );
            });
          } else {
            injuryAreaMotionAssessmentItems.push(
              createDefaultInjuryAreaMotionAssessment({
                injuryArea,
                injuryAreaGroup: injuryArea,
                refData: [...clinicalRef.motionTypes.values],
                injurySide: InjuryAreaSides.Left
              })
            );

            injuryAreaMotionAssessmentItems.push(
              createDefaultInjuryAreaMotionAssessment({
                injuryArea,
                injuryAreaGroup: injuryArea,
                refData: [...clinicalRef.motionTypes.values],
                injurySide: InjuryAreaSides.Right
              })
            );
          }
        }
      }
    });
    return {
      injuryAreas,
      injuryAreaMotionAssessmentItems,
      injuryAreaImageItems
    };
  };

  public static getMotionAndImageAssesments = (
    data: InjuryAreaClinicalDataDto | undefined
  ) => {
    let injuryAreaMotionAssessmentItems: InjuryAreaMotionAssessmentItem[] = [];
    const injuryAreaImageItems: InjuryAreaImageItem[] = [];
    const injuryAreas = data
      ? InjuryImagesHelper.getInjuryAreas(data?.assessments)
      : [];

    if (data) {
      injuryAreas.forEach(area => {
        const injuryAreaMotionAssessments = data.assessments?.find(
          assessment => assessment.injuryArea === area
        );
        if (
          injuryAreaMotionAssessments &&
          injuryAreaMotionAssessments.injuryAreaMotionAssessments
        ) {
          const currentInjuryAreaMotionAssessmentItems: InjuryAreaMotionAssessmentItem[] =
            injuryAreaMotionAssessments.injuryAreaMotionAssessments.map(
              injuryAreaMotionAssessment => {
                return {
                  injuryArea: area,
                  injurySide: injuryAreaMotionAssessment.side,
                  injuryAreaGroup:
                    injuryAreaMotionAssessment.injuryAreaMotionTypeGroup,
                  palpation: injuryAreaMotionAssessment.palpation,
                  strength: injuryAreaMotionAssessment.strength,
                  specialTests: injuryAreaMotionAssessment.specialTests,
                  motionTypes:
                    injuryAreaMotionAssessment.motionAssessments?.map(x => {
                      return {
                        code: x.motionType,
                        active: `${x.active}${DEGREE_SYMBOL}`,
                        passive: `${x.passive}${DEGREE_SYMBOL}`
                      } as MotionTypeItem;
                    })
                } as InjuryAreaMotionAssessmentItem;
              }
            );

          injuryAreaMotionAssessmentItems =
            injuryAreaMotionAssessmentItems.concat(
              currentInjuryAreaMotionAssessmentItems
            );
        }

        const injuryAreaAssessment = data.assessments?.find(
          x => x.injuryArea === area
        );
        injuryAreaAssessment &&
          injuryAreaAssessment.injuryAreaImages &&
          injuryAreaAssessment.injuryAreaImages.length > 0 &&
          injuryAreaAssessment.injuryAreaImages.map(x =>
            injuryAreaImageItems.push({
              injuryArea: injuryAreaAssessment.injuryArea,
              type: x.type,
              content: x.content
            })
          );
      });
    }

    return { injuryAreaMotionAssessmentItems, injuryAreaImageItems };
  };

  public static checkInjuryAreaMotionAssessmentHasValues = (
    assessment: InjuryAreaMotionAssessmentItem
  ) => {
    const motionTypesHasValue = assessment.motionTypes?.filter(
      x => x.active !== `0${DEGREE_SYMBOL}` || x.passive !== `0${DEGREE_SYMBOL}`
    );

    const textFieldHasValue =
      assessment.palpation || assessment.specialTests || assessment.strength;
    return (
      (motionTypesHasValue && motionTypesHasValue.length > 0) ||
      !!textFieldHasValue
    );
  };

  public static getMappedInjuryAreaAssessments = (
    injuryAreas: InjuryArea[] | undefined,
    injuryAreaMotionAssessmentsItems:
      | InjuryAreaMotionAssessmentItem[]
      | undefined
  ) => {
    const injuryAreaAssessments: InjuryAreaAssessment[] = [];
    if (injuryAreas && injuryAreaMotionAssessmentsItems) {
      injuryAreas.forEach(injuryArea => {
        const injuryAreaMotionAssessmentItems =
          injuryAreaMotionAssessmentsItems.filter(
            item => item.injuryArea === injuryArea
          );

        const injuryAreaMotionAssessments: InjuryAreaMotionAssessment[] = [];
        injuryAreaMotionAssessmentItems.forEach(assessment => {
          if (
            InjuryImagesHelper.checkInjuryAreaMotionAssessmentHasValues(
              assessment
            )
          ) {
            const motionTypes = assessment.motionTypes?.map(x => {
              const active = x.active.replace(DEGREE_SYMBOL, "");
              const passive = x.passive.replace(DEGREE_SYMBOL, "");
              return {
                motionType: x.code,
                active: Number(active),
                passive: Number(passive)
              } as MotionAssessment;
            });

            const injuryAreaMotionTypeAssessment: InjuryAreaMotionAssessment = {
              motionAssessments: motionTypes,
              palpation: assessment.palpation,
              specialTests: assessment.specialTests,
              strength: assessment.strength,
              injuryAreaMotionTypeGroup: assessment.injuryAreaGroup,
              side:
                injuryArea !== InjuryArea.Spine
                  ? assessment.injurySide
                  : undefined
            };

            injuryAreaMotionAssessments.push(injuryAreaMotionTypeAssessment);
          }
        });
        if (injuryAreaMotionAssessments.length > 0) {
          const injuryAreaAssessment: InjuryAreaAssessment = {
            injuryArea,
            injuryAreaMotionAssessments
          };
          injuryAreaAssessments.push(injuryAreaAssessment);
        }
      });
    }
    return injuryAreaAssessments;
  };
}
