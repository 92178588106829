import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useRef, useState } from "react";

import {
  CenteredLargeSpinner,
  CheckboxVisibility,
  DetailsRow,
  GroupedList,
  IColumn,
  IGroup,
  ISelection,
  SelectionMode,
  SelectionZone,
  Shimmer,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { hasMore } from "@libs/api/utils/paging.utils.ts";
import {
  IMaybePromiseObservable,
  QueryResult
} from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { NoFileDataTile } from "@modules/inbox/screens/user-inbox/components/UserInboxScreenLeftSideList.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";
import { ScrollListener } from "@ui-components/ScrollListener.tsx";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { GroupedListItem } from "../inbox/Inbox.types.ts";
import { getInboxScreenStylesSet } from "../inbox/InboxScreen.styles.ts";

type InboxGroupedListProps = {
  groups: IGroup[];
  files: InboxDocument[];
  selection: Selection<InboxDocument>;
  searchResults: IMaybePromiseObservable<QueryResult<InboxDocument>>;
  isLoading: boolean;
  isLoadingMore: boolean;
  onScrollToBottom: () => void;
};

export const useInboxSelection = () => {
  const { inbox } = useStores();
  const { setSelectedInboxDocument, selectedInboxDocument, setActiveForm } =
    useContext(InboxScreenContext);

  const selection: Selection<InboxDocument> = usePersistedHelper(
    () =>
      new Selection<InboxDocument>({
        getKey: item => item.id,
        selectionMode: SelectionMode.single,
        onSelectionChanged: async () => {
          const indexes = selection.getSelectedIndices();
          if (indexes.length === 0) {
            setSelectedInboxDocument(undefined);
            setActiveForm(false);
            return;
          }

          const itemKey = indexes[0];
          const item = selection.getItems()[itemKey];
          if (item) {
            setSelectedInboxDocument(item);
            setActiveForm(false);
          }
        },
        onItemsChanged: async () => {
          // onItemsChanged gets called when a new item is uploaded, the list refreshes
          //  and when the user triggers the infinite scroll
          if (inbox.uploadSessionDocsIds.size) {
            const indexes = selection.getSelectedIndices();
            if (!indexes.length) {
              selection.setIndexSelected(0, true, true);
            }
          }
        }
      })
  );
  useEffect(() => {
    if (!selectedInboxDocument) {
      selection.setAllSelected(false);
    }
  }, [selection, selectedInboxDocument]);

  return selection;
};

export const InboxGroupedList: FC<InboxGroupedListProps> = observer(
  ({
    searchResults,
    onScrollToBottom,
    files,
    groups,
    selection,
    isLoading,
    isLoadingMore
  }) => {
    const theme = useTheme();

    useEffect(() => {
      selection.setItems(files, false);
    }, [selection, files]);

    const [toggleCollapsed, setToggleCollapsed] = useState(true);

    const parentRef = useRef<HTMLDivElement>(null);

    const otherDocCount = groups[0]?.count;

    //avoid fetching next results when documents are added or removed
    const scrollToTop = () => parentRef.current?.scroll(0, 0);
    useEffect(() => {
      scrollToTop();
    }, [otherDocCount]);

    const { groupedListHeaderStyles } = getInboxScreenStylesSet(theme);

    const columns: IColumn[] = [
      {
        key: "pdfPage",
        name: "pdfPage",
        minWidth: 300,
        maxWidth: 300,
        onRender: (item: GroupedListItem) => (
          <Text
            styles={{
              root: {
                whiteSpace: "normal",
                wordBreak: "break-all"
              }
            }}
          >
            {item.name}
          </Text>
        )
      }
    ];

    const onRenderCell = (
      _nestingDepth?: number,
      item?: GroupedListItem,
      itemIndex?: number
    ): React.ReactNode => (
      <DetailsRow
        columns={columns}
        styles={{ root: { width: "100%" } }}
        item={item}
        itemIndex={itemIndex!}
        selection={selection as ISelection}
        checkboxVisibility={CheckboxVisibility.always}
        selectionMode={SelectionMode.single}
      />
    );

    return (
      <div
        ref={parentRef}
        style={{
          overflowY: "auto",
          height: "100%"
        }}
      >
        {isLoading && <CenteredLargeSpinner />}
        <Stack styles={{ root: { minHeight: "100%" } }}>
          <SelectionZone
            selection={selection as ISelection}
            selectionMode={SelectionMode.single}
          >
            <GroupedList
              groups={groups}
              onShouldVirtualize={() => false}
              items={files}
              selectionMode={SelectionMode.single}
              selection={selection as ISelection}
              styles={{
                root: {
                  selectors: groupedListHeaderStyles,
                  ".ms-DetailsRow.is-selected:hover": {
                    backgroundColor:
                      theme.semanticColors.listItemBackgroundChecked
                  }
                }
              }}
              groupProps={{
                onRenderHeader: (props, defaultRender) => {
                  if (!props || !defaultRender) {
                    return null;
                  }
                  return defaultRender!({
                    ...props,
                    onToggleCollapse: () => {
                      if (props.group?.key === "otherUpload") {
                        setToggleCollapsed(!!props.group?.isCollapsed);
                      }
                      if (props.group) {
                        props.onToggleCollapse!(props.group);
                      }
                      scrollToTop();
                    },
                    onGroupHeaderClick: () => {
                      // Prevent selecting all items when the group header is clicked
                      if (props.group && props.group.isCollapsed) {
                        props.onToggleCollapse!(props.group);
                      } else {
                        scrollToTop();
                      }
                    }
                  });
                }
              }}
              onRenderCell={onRenderCell}
            />
            {toggleCollapsed &&
              searchResults.value &&
              hasMore(searchResults.value) && (
                <Stack tokens={{ childrenGap: 32 }}>
                  <Shimmer />
                  <Shimmer />
                  <Shimmer />
                </Stack>
              )}
          </SelectionZone>
        </Stack>
        <div style={{ marginTop: 10 }}>
          <ScrollListener
            root={parentRef.current as Element}
            searchResults={searchResults}
            onScrolledToBottom={onScrollToBottom}
          />
        </div>
        {!searchResults.value?.results.length &&
          !isLoading &&
          !isLoadingMore && <NoFileDataTile />}
      </div>
    );
  }
);
