import React from "react";

import {
  FontIcon,
  TextBadge,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { SubmitActionCode } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

interface DocumentWriterDocStatusBadgeProps {
  isDraftTemplate: boolean;
  docStatusCode: string;
}

export const DocumentWriterDocStatusBadge: React.FC<
  DocumentWriterDocStatusBadgeProps
> = ({ isDraftTemplate, docStatusCode }) => {
  const theme = useTheme();

  const getDocStatusToolTip = () => {
    if (isDraftTemplate) {
      return "Draft templates cannot be used to create documents or letters";
    } else
      return docStatusCode === SubmitActionCode.PublishToEveryoneCode
        ? "This template is published and visible to others in the practice"
        : "This template is published and visible only to me";
  };
  return (
    <TooltipHost content={getDocStatusToolTip()}>
      <TextBadge
        hasBorder={isDraftTemplate}
        badgeSize={TextBadgeSize.large}
        noTextWrap
        customColors={{
          backgroundColor: isDraftTemplate
            ? theme.palette.themeLighterAlt
            : theme.palette.themeLighter,
          color: isDraftTemplate
            ? theme.palette.neutralPrimaryAlt
            : theme.palette.themeDarkAlt
        }}
        styles={{
          root: {
            marginRight: 24
          }
        }}
      >
        {!isDraftTemplate && (
          <FontIcon
            iconName="Completed"
            styles={{
              root: {
                paddingRight: 5,
                fontWeight: "bold",
                verticalAlign: "bottom"
              }
            }}
          />
        )}
        {isDraftTemplate ? "Draft" : "Published"}
      </TextBadge>
    </TooltipHost>
  );
};
