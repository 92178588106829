import { action, observable } from "mobx";

import { PaymentMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Payment } from "@stores/billing/models/Payment.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface PaymentViewHelperOptions {
  payment: Payment;
  contact: Contact;
}

export class PaymentViewHelper {
  payment: Payment;
  contact: Contact;

  constructor(
    private root: IRootStore,
    options: PaymentViewHelperOptions
  ) {
    this.payment = options.payment;
    this.contact = options.contact;
  }

  handlePaymentViewOpenPdf = async (paymentId: string) => {
    await this.root.billing.openPaymentReceiptPdf(paymentId);
  };

  @observable emailDialogVisible: boolean = false;

  @observable refundPaymentMethod: PaymentMethod | undefined;

  @action setRefundPaymentMethod = (value: PaymentMethod | undefined) => {
    this.refundPaymentMethod = value;
  };

  @action setEmailDialogVisible = (value: boolean) => {
    this.emailDialogVisible = value;
  };
}
