import { Country } from "@libs/enums/country.enum.ts";
import { formatByPattern } from "@libs/utils/format-by-pattern.utils.ts";
import { InvoiceSettings } from "@stores/billing/models/InvoiceSettings.ts";

import { InvoiceSettingsFormValues } from "./InvoiceSettingsForm.types.tsx";

export const getFormValuesFromInvoiceSettings = (
  settings: InvoiceSettings | undefined,
  country: Country
): InvoiceSettingsFormValues => {
  if (settings) {
    const {
      footer,
      alwaysApplyGst,
      gstPercent,
      gstNumber,
      bankDetails,
      bankDetailsUnderStatementFooter,
      statementFooter,
      id
    } = settings;

    const accountNumbers =
      country === Country.NewZealand
        ? (bankDetails?.accountNumber || "").split("-")
        : [bankDetails?.accountNumber || ""];

    return {
      id,
      footer: footer ?? "",
      alwaysApplyGst,
      gstPercent,
      gstNumber,
      bankDetails: {
        accountName: bankDetails?.accountName || "",
        accountNumber: accountNumbers[0] || "",
        accountNumber2: accountNumbers[1] || "",
        accountNumber3: accountNumbers[2] || "",
        accountNumber4: accountNumbers[3] || "",
        bankName: bankDetails?.bankName || "",
        bsb: bankDetails?.bsb || ""
      },
      bankDetailsUnderStatementFooter,
      statementFooter
    };
  } else {
    return {
      footer: "",
      alwaysApplyGst: false,
      gstPercent: 10,
      bankDetails: {
        accountName: "",
        accountNumber: "",
        accountNumber2: "",
        accountNumber3: "",
        accountNumber4: "",
        bankName: "",
        bsb: ""
      },
      bankDetailsUnderStatementFooter: true,
      statementFooter: ""
    };
  }
};

export const parseGstNumber = (str: string = ""): string => {
  const strLength = str.replace(/-/gi, "").length;
  if (strLength === 8) {
    return formatByPattern("00-000-000", str);
  }
  if (strLength === 9) {
    return formatByPattern("000-000-000", str);
  }
  return str;
};
