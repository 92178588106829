import { observer } from "mobx-react-lite";
import React from "react";

import { Pivot, PivotItem, Stack, useTheme } from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { pluralizeString } from "@libs/utils/utils.ts";
import {
  TemplateListItem,
  TemplatePivotItemKeys
} from "@modules/clinical/screens/correspondence/components/TemplatePivot.types.ts";
import { useTemplateManagerFormContext } from "@modules/clinical/screens/template-writer/context/TemplateManagerFormContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { useTemplatePickerFormContext } from "./context/TemplatePickerFormContext.tsx";

type TemplatePivotProps = {
  items: TemplateListItem[];
  isPickerFrom?: boolean;
};
export const TemplatePivotBase: React.FC<TemplatePivotProps> = observer(
  props => {
    const { userExperience, correspondence, core } = useStores();
    const { items, isPickerFrom } = props;
    const theme = useTheme();
    const model = isPickerFrom
      ? useTemplatePickerFormContext()
      : useTemplateManagerFormContext();

    const favouriteItems = items.filter(item =>
      model.favourites.includes(item.key)
    );

    const handleLinkClick = (item: PivotItem) => {
      switch (item.props.itemKey) {
        case TemplatePivotItemKeys.All:
          model.setSelectedKey(TemplatePivotItemKeys.All);

          break;
        case TemplatePivotItemKeys.Favourites:
          model.setSelectedKey(TemplatePivotItemKeys.Favourites);

          break;
        case TemplatePivotItemKeys.Custom:
          model.setSelectedKey(TemplatePivotItemKeys.Custom);

          break;
        case TemplatePivotItemKeys.Autofills:
          model.setSelectedKey(TemplatePivotItemKeys.Autofills);

          break;
        default:
          if (item.props.itemKey) {
            model.setSelectedKey(item.props.itemKey);
          }
      }
    };

    const getFilteredTabs = () => {
      const userStorage = userExperience.userStorageMap.get(
        UserStorageKeys.TemplateFilterTabs
      );

      let storedTabs: string[] = [];

      // If there's not anything recorded, default to these tabs.
      if (userStorage) {
        storedTabs = userStorage.jsonData as string[];
      } else {
        storedTabs = [
          CorrespondenceType.Letter,
          CorrespondenceType.Referral,
          CorrespondenceType.Report
        ];
      }

      const keyTextValues: KeyTextValue[] = [];

      storedTabs.forEach(x => {
        const corType =
          correspondence.ref.correspondenceTypes.keyTextValues.find(
            y => y.key === x
          );
        if (corType) {
          keyTextValues.push(corType);
        }
      });

      const sorted = keyTextValues.sort((a, b) => a.text.localeCompare(b.text));

      return sorted.map(data => {
        return (
          <PivotItem
            key={data.key}
            itemKey={data.key}
            headerText={pluralizeString(data.text)}
            itemCount={items.filter(y => y.documentType === data.key).length}
          />
        );
      });
    };

    return (
      <Stack horizontal horizontalAlign="center">
        <Pivot
          styles={{
            icon: {
              color: theme.palette.yellow
            }
          }}
          selectedKey={model.selectedKey}
          onLinkClick={handleLinkClick}
        >
          <PivotItem
            key="Favourites"
            itemKey={TemplatePivotItemKeys.Favourites}
            headerText="Favourites"
            itemIcon="FavoriteStarFill"
            itemCount={favouriteItems.length}
          />
          {getFilteredTabs()}
          <PivotItem
            key="Custom"
            itemKey={TemplatePivotItemKeys.Custom}
            headerText="Custom"
            itemCount={items.filter(x => x.isCustom === true).length}
          />
          {core.hasPermissions(Permission.AutofillAllowed) && (
            <PivotItem
              key="Autofills"
              itemKey={TemplatePivotItemKeys.Autofills}
              headerText="Autofills"
              itemCount={
                items.filter(
                  x => x.documentType === CorrespondenceType.Autofill
                ).length
              }
            />
          )}
          <PivotItem
            key="All"
            itemKey={TemplatePivotItemKeys.All}
            headerText="All"
            itemCount={items.length}
          />
        </Pivot>
      </Stack>
    );
  }
);

export const TemplatePivot = withFetch(
  x => [
    x.userExperience.getUserStorage(UserStorageKeys.TemplateFilterTabs),
    x.correspondence.ref.correspondenceTypes.load()
  ],
  TemplatePivotBase
);
