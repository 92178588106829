import { createContext, useContext } from "react";

import { BodyExaminationHelper } from "./BodyExaminationHelper.ts";

export const BodyExaminationContext = createContext<BodyExaminationHelper>(
  {} as BodyExaminationHelper
);

export const useBodyExaminationContext = () =>
  useContext(BodyExaminationContext);
