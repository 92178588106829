import { action, computed, observable } from "mobx";

import { confirm } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  AttendeeTypeEnum,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { WaitingListItemModel } from "@stores/booking/models/WaitingListModel.ts";

import { WaitingListFilterInternal } from "../components/WaitingListDetailFilter.tsx";
import { WaitingListItem } from "../WaitingListScreen.types.ts";

export class WaitingListScreenHelper {
  constructor(private booking: BookingStore) {}

  public waitingListResults = maybePromiseObservable<WaitingListItemModel[]>();

  @observable
  public closeDialog: boolean = true;

  @observable
  public itemToEdit: WaitingListItem | undefined = undefined;

  @computed
  public get items() {
    return (
      this.waitingListResults.value?.filter(
        x => DateTime.fromISO(x.until) >= DateTime.today()
      ) ?? []
    );
  }

  @action
  setCloseDialog = (value: boolean) => {
    this.closeDialog = value;
  };

  @action
  setItemToEdit = (value: WaitingListItem | undefined) => {
    this.itemToEdit = value;
  };

  public editWaitingList = async (item: WaitingListItem) => {
    if (!!item?.calenderEventId) {
      if (!this.booking.calendarEventsMap.has(item.calenderEventId)) {
        await this.booking.getCalendarEvent(item.calenderEventId, {
          ignoreCache: true
        });
      }
      this.booking.ui.showCalendarEventDialog({
        id: item.calenderEventId,
        type: CalendarEventType.Appointment
      });
    } else {
      this.setCloseDialog(false);
      this.setItemToEdit(item);
    }
  };

  public removeWaitingList = async (item: WaitingListItem) => {
    const contactId = item.attendees?.find(
      x => x.type === AttendeeTypeEnum.contact
    )?.attendeeId;

    const contact =
      contactId && this.booking.practice.contactsMap.get(contactId);

    if (
      contact &&
      (await confirm({
        confirmButtonProps: {
          text: "Confirm"
        },
        cancelButtonProps: {
          text: "Cancel"
        },
        dialogContentProps: {
          title: "Remove from waiting list",
          subText: `Are you sure you want to remove ${contact.name} from the waiting list?`
        }
      }))
    ) {
      await this.booking.deleteWaitingListRecord(item.id);
    }
  };

  public filterItemsWithAnyProvider = (
    items: WaitingListItemModel[],
    providerId: string | undefined
  ) => {
    if (!providerId) return items;
    return items.filter(item => {
      return (
        item.attendees.some(i => i.attendeeId === providerId) ||
        item.anyProvider
      );
    });
  };

  public filterItemsWithAnyLocation = (
    items: WaitingListItemModel[],
    orgUnitId?: string
  ) => {
    if (!orgUnitId) return items;
    return items.filter(
      item => item.orgUnitId === orgUnitId || item.anyLocation
    );
  };

  public fetchWaitingListItems = (
    filter: WaitingListFilterInternal & { orgUnitId?: string }
  ) => {
    this.waitingListResults.set(
      this.booking
        .getWaitingListRecordsFilteredValues(filter)
        .then(result =>
          this.filterItemsWithAnyProvider(result, filter.providerId)
        )
        .then(result =>
          this.filterItemsWithAnyLocation(result, filter.orgUnitId)
        )
    );
  };
}
