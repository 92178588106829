import { observer } from "mobx-react-lite";

import {
  noWrap,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";

import { ConditionListItem } from "./Condition.types.ts";

interface ConditionConsultBadgeProps {
  conditionListItem: ConditionListItem;
}

export const ConditionConsultBadge: React.FC<ConditionConsultBadgeProps> =
  observer(({ conditionListItem }) => {
    const claimAppointmentVisits = conditionListItem.claim?.appointmentVisits;

    if (
      !conditionListItem.isPrivate &&
      !conditionListItem.discharged &&
      conditionListItem.claim &&
      claimAppointmentVisits &&
      claimAppointmentVisits.length > 0
    ) {
      return (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          horizontalAlign="center"
          badgeColor={TextBadgeColor.lightGrey}
          styles={{
            root: {
              paddingLeft: "8px",
              paddingRight: "8px",
              ...noWrap
            }
          }}
        >
          {claimAppointmentVisits && claimAppointmentVisits.length > 1
            ? "Multiprovider"
            : claimAppointmentVisits.length === 1 &&
              `${claimAppointmentVisits[0].totalRemaining} consults remaining`}
        </TextBadge>
      );
    }

    return null;
  });
