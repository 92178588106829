import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  GroupedList,
  Heading,
  IconButton,
  IContextualMenuItem,
  IGroupHeaderProps,
  IRenderFunction,
  NoDataTile,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  PatientTreatmentPlanClinicalDataDto,
  PatientTreatmentPlanDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { deleteItemFromClinicalDataArray } from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteDialog } from "@ui-components/DeleteDialog.tsx";

import {
  ConditionItem,
  ManagementGroups
} from "./management/ManagementForm.Types.ts";
import { TreatmentAndManagementSidePanelContext } from "./TreatmentAndManagementSidePanelContext.ts";
import {
  contextMenuStyles,
  subMarginStyle,
  textBoldStyles
} from "./TreatmentList.styles.ts";

interface ManagementListProps {
  clinicalRecord: ClinicalRecord;
}

const ManagementListBase: FunctionComponent<ManagementListProps> = observer(
  ({ clinicalRecord }) => {
    const { core } = useStores();
    const { getManagementData } = useContext(
      TreatmentAndManagementSidePanelContext
    );

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
    const { clinical } = useStores();

    const DEPTH_1 = 1;

    const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
    const [planToDelete, setPlanToDelete] =
      useState<PatientTreatmentPlanDataItemDto>();

    const handlePlanDelete = (planId: string) => {
      const treatmentPlans = Array.from(
        clinicalRecord.clinicalData?.patientTreatmentPlan?.treatmentPlans ?? []
      );

      const selectedTreatmentPlan = treatmentPlans?.find(x => x.id === planId);

      if (selectedTreatmentPlan) {
        if (
          selectedTreatmentPlan.createLog?.createdEncounterId ===
          clinicalRecord.openEncounter?.id
        ) {
          const filteredTreatmentPlans = treatmentPlans.filter(
            x => x.id !== planId
          );

          const treatmentPlanData: PatientTreatmentPlanClinicalDataDto = {
            eTag: clinicalRecord.clinicalData?.patientTreatmentPlan?.eTag,
            treatmentPlans: filteredTreatmentPlans
          };

          const data: EncounterClinicalDataDto = {
            patientTreatmentPlan: treatmentPlanData
          };

          clinicalRecord.saveClinicalData(data);
          clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
            ClinicalDataType.PatientTreatmentPlan,
            true
          );
          clinical.ui.closePatientRecordContentForm(
            clinicalRecord.id,
            ClinicalDataType.PatientTreatmentPlan
          );
        } else {
          setPlanToDelete(selectedTreatmentPlan);
          setHideDeleteDialog(false);

          if (
            selectedTreatmentPlan.linkId ===
            clinicalRecord.openEncounter?.episodeOfCareId
          ) {
            clinical.ui.closePatientRecordContentForm(
              clinicalRecord.id,
              ClinicalDataType.PatientTreatmentPlan
            );
          }
        }
      }
    };

    const onDeleteConfirm = async (
      reasonForDelete: string,
      reasonForDeleteComment?: string
    ) => {
      if (planToDelete) {
        const treatmentPlans = deleteItemFromClinicalDataArray({
          array:
            clinicalRecord.clinicalData?.patientTreatmentPlan?.treatmentPlans ??
            [],
          id: planToDelete.id!,
          deletedComment: reasonForDeleteComment,
          reasonForDelete
        });

        const encounterClinicalData: EncounterClinicalDataDto = {
          patientTreatmentPlan: {
            eTag: clinicalRecord.clinicalData?.patientTreatmentPlan?.eTag,
            treatmentPlans
          }
        };

        await clinicalRecord.saveClinicalData(encounterClinicalData);
        onDeleteCancel();
      }
    };

    const onDeleteCancel = () => {
      setHideDeleteDialog(true);
    };

    const onRenderCell = (
      depth?: number,
      item?: ConditionItem
    ): React.ReactNode => {
      return (
        <Stack
          tokens={{ childrenGap: 4 }}
          styles={{ root: { padding: "8px 0px", marginLeft: "90px" } }}
        >
          <Text styles={textBoldStyles}>{item?.title}</Text>
          <Text>{item?.comment}</Text>
        </Stack>
      );
    };

    const getContextMenuItem = (planId: string, disabled: boolean) => {
      const contextMenuItems: IContextualMenuItem[] = [
        {
          key: "edit",
          text: "Edit",
          disabled,
          onClick: () => {
            clinical.ui.setPatientClinicalContent({
              type: ClinicalDataType.PatientTreatmentPlan
            });
          }
        },
        {
          key: "delete",
          text: "Delete",
          disabled: !core.hasPermissions(
            Permission.PatientTreatmentPlanAllowed
          ),
          onClick: () => {
            handlePlanDelete(planId);
          }
        }
      ];
      return contextMenuItems;
    };

    const renderHeader: IRenderFunction<IGroupHeaderProps> = props => {
      const group = props?.group!;
      const name = group.name || "";
      const isFinal = props?.groups?.length
        ? props?.groupIndex === props?.groups?.length - 1
        : false;

      const isCollapsed = group.isCollapsed || false;
      const thisPlan =
        clinicalRecord.clinicalData?.patientTreatmentPlan?.treatmentPlans?.find(
          x => x.id === group.key
        );

      const disableEdit = core.hasPermissions(
        Permission.PatientTreatmentPlanAllowed
      )
        ? thisPlan?.linkId !== clinicalRecord.openEncounter?.episodeOfCareId
        : false;

      const disableButton = () => {
        if (core.hasPermissions(Permission.PatientTreatmentPlanAllowed)) {
          return isViewOnlyOrDischarged;
        } else {
          return (
            props?.group?.key !==
              clinicalRecord.openEncounter?.episodeOfCareId ||
            isViewOnlyOrDischarged
          );
        }
      };

      return (
        <Stack
          horizontal
          onClick={() => {
            if (props?.onToggleCollapse && props?.group) {
              props.onToggleCollapse(props.group);
            }
          }}
          styles={{
            root: {
              height: "fit-content",
              borderTop: "1px #eee solid",
              borderBottom:
                isFinal && isCollapsed ? "1px #eee solid" : undefined
            }
          }}
        >
          <>
            {group.level === DEPTH_1 && (
              <IconButton
                {...dataAttribute(
                  DataAttributes.Element,
                  `more-button-${name}`
                )}
                menuIconProps={{
                  iconName: "More"
                }}
                menuProps={{
                  items: getContextMenuItem(group.key, disableEdit)
                }}
                styles={contextMenuStyles}
                disabled={disableButton()}
              />
            )}

            <IconButton
              {...dataAttribute(DataAttributes.Element, `${name}-collapse`)}
              iconProps={{
                iconName: isCollapsed ? "ChevronRight" : "ChevronDown"
              }}
              styles={
                group.level === DEPTH_1 ? contextMenuStyles : subMarginStyle
              }
            />
            <Stack
              tokens={{ childrenGap: 4 }}
              styles={{
                root: {
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingRight: 16,
                  width: "100%"
                }
              }}
            >
              <Heading
                {...dataAttribute(DataAttributes.Element, `${name}-heading`)}
                labelPaddings
              >
                {name}
              </Heading>
            </Stack>
          </>
        </Stack>
      );
    };

    return (
      <>
        <DataFetcher<ManagementGroups>
          fetch={getManagementData}
          fallback={<Spinner />}
          refetchId={`${clinicalRecord.clinicalData?.patientTreatmentPlan?.eTag}${clinicalRecord.clinicalData?.treatmentPlan?.eTag}${clinicalRecord.clinicalData?.consentAndWarnings?.eTag}${clinicalRecord.clinicalData?.goals?.eTag}`}
        >
          {({ groups, items }) => (
            <>
              {groups.length === 0 ? (
                <NoDataTile
                  textProps={{ text: "No plans recorded" }}
                  linkProps={{
                    hidden: isViewOnlyOrDischarged,
                    text: "Add a plan",
                    onClick: () => {
                      clinical.ui.setPatientClinicalContent({
                        type: ClinicalDataType.PatientTreatmentPlan
                      });
                    }
                  }}
                  showBoxShadow={false}
                  greyView
                />
              ) : (
                <GroupedList
                  groups={groups}
                  items={items}
                  onRenderCell={onRenderCell}
                  groupProps={{
                    onRenderHeader: renderHeader
                  }}
                  styles={{
                    root: {
                      overflow: "auto",
                      overflowX: "hidden",
                      maxHeight: "calc(100vh - 240px)"
                    }
                  }}
                />
              )}
            </>
          )}
        </DataFetcher>
        <DeleteDialog
          formName="delete-treatment-plan"
          hidden={hideDeleteDialog}
          onConfirm={onDeleteConfirm}
          onCancel={onDeleteCancel}
          title="Delete plan"
          subText="Are you sure you want to delete this plan?"
          options={clinical.ref.reasonsForDiscard.keyTextValues.filter(
            x => x.key !== "WRP"
          )}
        />
      </>
    );
  }
);

export const ManagementList = withFetch(
  x => [
    x.clinical.ref.treatmentOptions.load(),
    x.clinical.ref.educationOptions.load()
  ],
  ManagementListBase
);
