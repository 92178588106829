import { useState } from "react";
import { useForm } from "react-final-form";

import {
  ConfirmDialog,
  dataAttribute,
  DataAttributes,
  Heading,
  IColumn,
  IconButton,
  IContextualMenuItem,
  Link,
  RESET_CELLS_PADDING_CLASSNAME,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  IntakeFlagRefDataDto,
  IntakeFrequency,
  IntakeUnit
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import {
  IntakeFrequencyText,
  IntakeUnitText
} from "@ui-components/RefText.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { IntakeCessationField } from "./IntakeCessationField.tsx";
import { IntakeCessationView } from "./IntakeCessationView.tsx";
import {
  IntakeDomain,
  IntakeFormItem,
  IntakeFormValues
} from "./IntakeForm.types.ts";
import { IntakeTableHeader } from "./IntakeTableHeader.tsx";
import { IntakeTypeField } from "./IntakeTypeField.tsx";
import {
  ceaseCurrentIntake,
  getDefaultIntake,
  getFilteredOptions
} from "./utils.ts";

export interface IntakeTableProps {
  name: string;
  domain: IntakeDomain;
  tableTitle: string;
  maxIntakes?: number;
  clinicalRecord: ClinicalRecord;
  initialValuesFromDto: IntakeFormItem[];
}

interface IntakeTableState {
  editDialogHidden: boolean;
  deleteDialogHidden: boolean;
  currentItem: IntakeFormItem | undefined;
}

export const IntakeTable: React.FunctionComponent<IntakeTableProps> = ({
  name,
  domain,
  tableTitle,
  maxIntakes,
  clinicalRecord,
  initialValuesFromDto
}) => {
  const { clinical } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const [intakeFormState, setIntakeFormState] = useState<IntakeTableState>({
    editDialogHidden: true,
    deleteDialogHidden: true,
    currentItem: undefined
  });

  const theme = useTheme();

  const nameOf = nameOfFactory<IntakeFormValues>();
  const { getState, mutators, change } = useForm<IntakeFormValues>();
  const ceaseIntake = (item: IntakeFormItem) => () => {
    const { currentIntakes } = getState().values;
    const ceaseIndex = currentIntakes?.findIndex(i => i.id === item.id);
    ceaseCurrentIntake(item, ceaseIndex, mutators as any);
  };

  const deleteIntake = (item: IntakeFormItem) => () => {
    if (item.isLocked) {
      setIntakeFormState(state => ({
        ...state,
        deleteDialogHidden: false,
        currentItem: item
      }));
    } else {
      change(nameOf("deleteIntake"), item);
    }
  };

  const deleteFromModal = () => {
    if (intakeFormState.currentItem) {
      change(nameOf("deleteIntake"), intakeFormState.currentItem);
    }

    setIntakeFormState(state => ({ ...state, deleteDialogHidden: true }));
  };

  const getCessationAdviceStatus = (item: IntakeFormItem): boolean => {
    if (item.cessation) {
      if (item.cessation.types) {
        return item.cessation.types.length > 0;
      }
    }
    return false;
  };

  const iconButtonItems = (item: IntakeFormItem): IContextualMenuItem[] =>
    [
      { key: "cease", text: "Cease", onClick: ceaseIntake(item) },
      { key: "delete", text: "Delete", onClick: deleteIntake(item) }
    ].filter(i => !(name === "historicalIntakes" && i.key === "cease"));

  const columns: IColumn[] = [
    {
      key: "button",
      className: RESET_CELLS_PADDING_CLASSNAME,
      onRender: (item: IntakeFormItem) =>
        !item.isEdit &&
        !isViewOnlyOrDischarged && (
          <Stack horizontalAlign="center" verticalAlign="center">
            <IconButton
              {...dataAttribute(DataAttributes.Element, "more-options-button")}
              iconProps={{ iconName: "More" }}
              onRenderMenuIcon={() => null}
              menuProps={{ items: iconButtonItems(item) }}
              styles={{
                root: { width: "32px", height: "36px", padding: 0 },
                flexContainer: { width: "32px", height: "36px" }
              }}
            />
          </Stack>
        ),
      name: "",
      minWidth: 50,
      maxWidth: 55
    },
    {
      key: "type",
      onRender: (value: IntakeFormItem, index: number) => (
        <IntakeTypeField
          domain={domain}
          name={name}
          index={index}
          value={value}
        />
      ),
      name: "Type",
      minWidth: 180,
      maxWidth: 180
    },
    {
      key: "amount",
      onRender: (value: IntakeFormItem, index: number) =>
        value.isEdit ? (
          <SpinNumberInputField
            name={`${name}[${index}].amount`}
            maxDigitsNumber={3}
            styles={{
              root: { minWidth: 60, maxWidth: 65 },
              input: { width: 85 }
            }}
          />
        ) : (
          <Text>{value.amount}</Text>
        ),
      name: "Amount",
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: "unit",
      onRender: (value: IntakeFormItem, index: number) =>
        domain === ClinicalDataType.Alcohol || !value.isEdit ? ( // use ref text if it is Alcohol form or view mode
          <IntakeUnitText code={value.unit} />
        ) : (
          <DropdownField
            name={`${name}[${index}].unit`}
            fieldItemStyles={{ root: { flex: 1 } }}
            options={getFilteredOptions(
              clinical.ref.intakeUnits
                .values as IntakeFlagRefDataDto<IntakeUnit>[],
              domain
            )}
          />
        ),
      name: "Unit",
      minWidth: 130,
      maxWidth: 130
    },
    {
      key: "occurrence",
      onRender: (value: IntakeFormItem, index: number) => {
        const suffix: string =
          Number(value.occurrence) === 1 ? "time" : "times";

        if (
          value.isEdit &&
          value.frequency === IntakeFrequency.Daily &&
          value.occurrence
        ) {
          mutators.update(name, index, { ...value, occurrence: undefined });
        }

        if (value.isEdit) {
          if (value.frequency === IntakeFrequency.Daily) {
            return null;
          }

          return (
            <SpinNumberInputField
              name={`${name}[${index}].occurrence`}
              maxDigitsNumber={3}
              suffix={suffix}
              styles={{
                input: { width: 60 },
                spinButtonWrapper: { width: 70 }
              }}
              fieldItemStyles={{
                suffix: { display: "flex", alignItems: "center" }
              }}
            />
          );
        }

        if (!value.occurrence) {
          return null;
        }

        return (
          <Stack>
            <Text>
              {value.occurrence} {suffix}
            </Text>
          </Stack>
        );
      },
      name: "Occurrences",
      minWidth: 130,
      maxWidth: 135
    },
    {
      key: "frequency",
      onRender: (value: IntakeFormItem, index: number) =>
        value.isEdit ? (
          <DropdownField
            name={`${name}[${index}].frequency`}
            withNoEmptyOption
            options={clinical.ref.intakeFrequencies.keyTextValues}
            fieldItemStyles={{ root: { flex: 1 } }}
          />
        ) : (
          <IntakeFrequencyText code={value.frequency} />
        ),
      name: "Unit of time",
      minWidth: 85,
      maxWidth: 92
    },
    {
      key: "from",
      onRender: (value: IntakeFormItem, index: number) =>
        value.isEdit ? (
          <SpinNumberInputField
            name={`${name}[${index}].yearStarted`}
            max={DateTime.now().year}
            min={clinicalRecord.patient?.birthDate?.year ?? 1900}
            validateOnInitialize={!!value.yearStarted}
            maxDigitsNumber={4}
            styles={{ spinButtonWrapper: { width: 86 } }}
          />
        ) : (
          value.yearStarted
        ),
      name: "From",
      minWidth: 86,
      maxWidth: 90
    },
    {
      key: "to",
      onRender: (value: IntakeFormItem, index: number) =>
        value.isEdit ? (
          <SpinNumberInputField
            name={`${name}[${index}].yearStopped`}
            min={
              value.yearStarted
                ? value.yearStarted
                : clinicalRecord.patient?.birthDate?.year ?? 1900
            }
            max={DateTime.now().year}
            validateOnInitialize={!!value.yearStarted}
            maxDigitsNumber={4}
            styles={{ spinButtonWrapper: { width: 86 } }}
          />
        ) : (
          value.yearStopped
        ),
      name: "To",
      minWidth: 86,
      maxWidth: 90
    },
    {
      key: "cessation-advice",
      onRender: (value: IntakeFormItem, index: number) => {
        if (name === nameOf("currentIntakes") || value.isEdit) {
          return (
            <IntakeCessationField
              name={name}
              index={index}
              isGiven={getCessationAdviceStatus(value)}
              isEdit={value.isEdit}
            />
          );
        }

        if (value.cessation?.types?.length) {
          return <IntakeCessationView types={value.cessation.types} />;
        }

        return "Not given";
      },
      name: "Cessation advice",
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: "action",
      onRender: (value: IntakeFormItem, index: number) =>
        !isViewOnlyOrDischarged && (
          <Stack styles={{ root: { width: "100%" } }}>
            <Link
              {...dataAttribute(
                DataAttributes.Element,
                `action-button-${value.isEdit ? "cancel" : "edit"}`
              )}
              styles={{ root: { textAlign: "end" } }}
              onClick={() => {
                if (value.id) {
                  if (!value.isEdit && value.isLocked) {
                    setIntakeFormState(state => ({
                      ...state,
                      editDialogHidden: false
                    }));
                  } else {
                    /* toggle edit/view mode if an existing intake
                  if Cancel, return the intial values */
                    if (value.isEdit) {
                      mutators.update(name, index, {
                        ...initialValuesFromDto[index],
                        isEdit: !value.isEdit
                      });
                    } else {
                      mutators.update(name, index, {
                        ...value,
                        isEdit: !value.isEdit
                      });
                    }
                  }
                } else {
                  // remove a new intake id cancel
                  mutators.remove(name, index);
                }
              }}
            >
              {value.isEdit ? "Cancel" : "Edit"}
            </Link>
          </Stack>
        ),
      name: "",
      minWidth: 50
    }
  ].filter(
    i =>
      !(
        (domain === ClinicalDataType.Tobacco ||
          domain === ClinicalDataType.SubstanceUse) &&
        i.key === "standard-drink"
      ) && // filter standard-drink for Tobacco data
      !(domain === ClinicalDataType.Tobacco && i.key === "unit") && // filter unit for Tobacco
      !(name === nameOf("currentIntakes") && i.key === "to") // dont show "to" field for current intakes
  );

  return (
    <>
      <FieldArray<IntakeFormItem> name={name}>
        {({ fields: { value, push } }) => (
          <Stack tokens={{ childrenGap: 8 }}>
            <IntakeTableHeader
              length={value.length}
              onClick={() => push(getDefaultIntake(domain, name))}
              tableTitle={tableTitle}
              maxIntakes={maxIntakes}
            />
            <ShimmeredDetailsList
              items={value}
              columns={columns}
              onRenderDetailsHeader={(props, defaultRender) => {
                return defaultRender!({
                  ...props!,
                  styles: {
                    root: {
                      selectors: {
                        "& > div:hover": {
                          backgroundColor: "inherit"
                        }
                      }
                    }
                  }
                });
              }}
              onRenderRow={(props, defaultRender) => {
                if (!props || !defaultRender) {
                  return null;
                }
                return defaultRender!({
                  ...props,
                  styles: {
                    root: {
                      "&.is-selected:hover": {
                        backgroundColor:
                          theme.semanticColors.listItemBackgroundChecked
                      }
                    }
                  }
                });
              }}
            />
          </Stack>
        )}
      </FieldArray>
      <ConfirmDialog
        hidden={intakeFormState.editDialogHidden}
        confirmButtonProps={{ text: "OK" }}
        cancelButtonProps={undefined}
        dialogContentProps={{
          title: (
            <Heading variant="modal-heading">Edit historical intake</Heading>
          )
        }}
        onConfirm={() => {
          setIntakeFormState(state => ({ ...state, editDialogHidden: true }));
        }}
      >
        <Text>
          This historical record was added by ceasing a current intake record
          and cannot be edited.
        </Text>
      </ConfirmDialog>
      <ConfirmDialog
        confirmButtonProps={{ text: "Delete" }}
        cancelButtonProps={{ text: "Cancel " }}
        hidden={intakeFormState.deleteDialogHidden}
        dialogContentProps={{
          title: (
            <Heading variant="modal-heading">Delete historical intake</Heading>
          )
        }}
        onConfirm={deleteFromModal}
        onCancel={() => {
          setIntakeFormState(state => ({ ...state, deleteDialogHidden: true }));
        }}
      >
        <Text>
          This historical record was added by ceasing a current intake record.
          Are you sure you wish to delete this?
        </Text>
      </ConfirmDialog>
    </>
  );
};
