import { observer } from "mobx-react-lite";
import { useState } from "react";

import { DefaultButton, IButtonProps, useTheme } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { QuickAddContactDialog } from "@modules/practice/screens/shared-components/quick-add-contact-dialog/QuickAddContactDialog.tsx";
import { Labels } from "@modules/practice/screens/shared-components/types/labels.enums.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { WithPermissionProps } from "@ui-components/withPerm.tsx";

export type QuickAddContactProps = {
  addButtonLabel?: string;
  addModalTitle?: string;
  onContactAdded?: (contactId: string) => void;
  showAddByDropdown?: boolean;
  typeToAdd?: ContactType;
} & Pick<WithPermissionProps, "permission"> &
  Pick<IButtonProps, "disabled">;

export const QuickAddContact: React.FC<QuickAddContactProps> = observer(
  ({ addButtonLabel, typeToAdd, onContactAdded, permission, disabled }) => {
    const theme = useTheme();
    const { core } = useStores();
    if (permission && !core.hasPermissions(permission)) {
      return null;
    }

    const [quickAddOpened, setQuickAddOpened] = useState(false);

    const onDismiss = () => {
      setQuickAddOpened(false);
    };

    const handleOnContactAdded = (contact: Contact) => {
      setQuickAddOpened(false);
      if (onContactAdded) {
        onContactAdded(contact.id);
      }
    };

    return (
      <>
        <DefaultButton
          text={addButtonLabel ?? Labels.newPerson}
          styles={{
            root: {
              borderColor: theme.palette.neutralSecondary,
              marginTop: 0,
              width: "100%"
            }
          }}
          onClick={() => {
            setQuickAddOpened(true);
          }}
          disabled={disabled}
        />
        {quickAddOpened && (
          <QuickAddContactDialog
            type={typeToAdd ?? ContactType.Individual}
            onDismiss={onDismiss}
            onContactAdded={handleOnContactAdded}
          />
        )}
      </>
    );
  }
);
