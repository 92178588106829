import { decode } from "base64-arraybuffer";
import { observer } from "mobx-react-lite";

import {
  ClaimPreviewDocumentData,
  ClaimPreviewDocumentRequired
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

const hasUriContent = (
  data: ClaimPreviewDocumentData
): data is ClaimPreviewDocumentRequired & { url: string } => {
  return nameof<ClaimPreviewDocumentData>("url") in data;
};

export const ClaimDocumentViewerDialog = observer(() => {
  const { acc } = useStores();
  const closeDocumentViewer = () =>
    acc.ui.setClaimPreviewDocumentData(undefined);

  const getDocument = (data: ClaimPreviewDocumentData): Document => ({
    documentId: data.documentId,
    extension: DocumentContentType.Pdf,
    entityId: data.patientId,
    entityType: DocumentEntityType.Patient,
    downloadUri: hasUriContent(data) ? data.url : undefined,
    previewUri: !hasUriContent(data)
      ? new Uint8Array(decode(data.templateUriContent))
      : undefined,
    name: hasUriContent(data) ? "Claim form" : "Patient claim form"
  });

  const data = acc.ui.claimPreviewDocumentData;

  if (data) {
    return (
      <DocumentViewerDialog
        getDocument={(): Document => getDocument(data)}
        downloadInNewTab
        closeDocumentViewer={closeDocumentViewer}
      />
    );
  }
  return null;
});
