import { useField, useForm } from "react-final-form";

import { FontWeights } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { AddressFields } from "../AddressFields.tsx";
import {
  QuickAddContactFormValues,
  quickAffContactFormNameOf
} from "./QuickAddContactFormValues.tsx";

type QuickAddAddressFieldsProps = {
  country: Country;
};

export const QuickAddAddressFields: React.FunctionComponent<
  QuickAddAddressFieldsProps
> = ({ country }) => {
  const form = useForm<QuickAddContactFormValues>();
  const {
    input: { value: hasPostal }
  } = useField<boolean>("hasPostal", { subscription: { value: true } });

  const {
    input: { value: addresses }
  } = useField<AddressFieldValue[] | undefined>("addresses", {
    subscription: { value: true }
  });

  const addAddress = (type: AddressType) => {
    form.mutators.push(quickAffContactFormNameOf("addresses"), {
      street1: undefined,
      country,
      type,
      isNew: true,
      id: newGuid()
    } as AddressFieldValue);
  };

  const togglePostal = (value: boolean) => {
    const postalIndex =
      addresses?.findIndex(p => p.type === AddressType.Postal) ?? -1;

    if (postalIndex === -1 && value) {
      addAddress(AddressType.Postal);
    }

    if (postalIndex > -1 && !value) {
      form.mutators.remove(quickAffContactFormNameOf("addresses"), postalIndex);
    }
  };

  return (
    <>
      {addresses?.length && (
        <AddressFields
          focusFirstField={false}
          name="addresses[0]"
          value={addresses[0]}
          disableRemoveLink={true}
        />
      )}

      <ToggleField
        name="hasPostal"
        styles={{
          root: { marginBottom: 0 },
          label: { fontWeight: FontWeights.regular, marginLeft: 8 }
        }}
        label="Add a different postal address"
        inlineLabel
      />
      <FieldSpy name="hasPostal" onChange={togglePostal} />
      {hasPostal && addresses && addresses?.length > 1 && (
        <AddressFields
          frame
          name="addresses[1]"
          value={addresses[1]}
          disableRemoveLink={true}
        />
      )}
    </>
  );
};
