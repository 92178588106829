import React, { FunctionComponent } from "react";

import { useTheme } from "@bps/fluent-ui";
import { TIME_FORMATS } from "@bps/utils";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { CancelAppointmentFormValues } from "./components/CancelAppointmentForm.types.ts";
import { CancelAppointmentFormFields } from "./components/CancelAppointmentFormFields.tsx";
import { CancelAppointmentFormValidator } from "./components/CancelAppointmentFormValidator.ts";

export interface CancelAttendeeAppointmentDialogProps {
  handleCancellation: (values: CancelAppointmentFormValues) => void;
  closeDialog: () => void;
  calendarEvent: CalendarEvent;
  patientName: string;
  providerName: string;
}

export const CancelAttendeeAppointmentDialog: FunctionComponent<
  CancelAttendeeAppointmentDialogProps
> = ({
  handleCancellation,
  closeDialog,
  calendarEvent,
  patientName,
  providerName
}) => {
  const { booking, userExperience } = useStores();
  const captureApptCancellationReason =
    userExperience.orgUnitSettingForLocation?.preferenceSetting
      .captureApptCancellationReason;

  const theme = useTheme();

  if (!calendarEvent) return null;

  const otherCancellationReasonId = Array.from(
    booking.appointmentCancellationReasonsMap.values()
  ).find(x => x.text === "Other")?.id;

  const apptDateTime = `${calendarEvent.startDateTime.toDayDefaultFormat()} at ${calendarEvent.startDateTime.toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  )}`;

  return (
    <SubmissionFormDialog<CancelAppointmentFormValues>
      styles={{ fields: { overflowY: "visible" } }}
      initialValues={{ cancellationReasonId: "" }}
      validate={(values: CancelAppointmentFormValues) =>
        new CancelAppointmentFormValidator(
          otherCancellationReasonId,
          captureApptCancellationReason
        ).validate(values)
      }
      dialogProps={{
        onDismiss: closeDialog,
        maxWidth: "480px",
        minWidth: "480px",
        title: "Remove patient from group appt",
        dialogContentProps: {
          subText: `Do you want to remove ${patientName} from group appt with ${providerName} on ${apptDateTime}`,
          styles: { subText: { color: theme.palette.black } }
        }
      }}
      buttonsProps={{
        submitButtonProps: {
          text: "Remove patient",
          iconProps: { hidden: true }
        },
        onCancel: closeDialog,
        hideButtonsSeparator: true,
        disableSubmitOnPristine: captureApptCancellationReason
      }}
      onSubmit={values => {
        handleCancellation(values);
        closeDialog();
      }}
      dialogName="Cancel attendee appointment dialog"
    >
      {() => {
        return (
          <Fieldset>
            <CancelAppointmentFormFields reasonLabel="Reason" />
          </Fieldset>
        );
      }}
    </SubmissionFormDialog>
  );
};
