import { useState } from "react";
import { useForm } from "react-final-form";

import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { ConsentAndWarningsCard } from "../../SOTAP/ConsentAndWarningsCard.tsx";
import { GoalsCard } from "../../SOTAP/GoalsCard.tsx";
import { SOTAPFormWrapper } from "../../SOTAP/SOTAPFormWrapper.tsx";
import { TreatmentPlanCard } from "../../SOTAP/TreatmentPlanCard.tsx";
import { ConditionCard } from "./ConditionCard.tsx";
import { ManagementFormValues } from "./ManagementForm.Types.ts";

interface ManagementFormContentProps {
  clinicalRecord: ClinicalRecord;
}
export const ManagementFormContent: React.FunctionComponent<
  ManagementFormContentProps
> = ({ clinicalRecord }) => {
  const hasLinkedCondition = clinicalRecord.episodeOfCare?.id;
  const form = useForm<ManagementFormValues>();
  const showCards =
    hasLinkedCondition !== undefined || !!form.getState().values.linkId;

  const [linkedItem, setLinkedItem] = useState<boolean>(showCards);
  return (
    <SOTAPFormWrapper>
      <ConditionCard
        clinicalRecord={clinicalRecord}
        setLinkedItem={setLinkedItem}
      />
      <GoalsCard linkedCondition={linkedItem} />
      <TreatmentPlanCard
        setIsFilledFromTreatments={() => undefined}
        isFilledFromTreatments={false}
        linkedCondition={linkedItem}
      />
      <ConsentAndWarningsCard linkedCondition={linkedItem} />
    </SOTAPFormWrapper>
  );
};
