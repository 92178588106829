import { observer } from "mobx-react-lite";
import React, { ReactNode, useCallback, useContext, useState } from "react";

import {
  Card,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Heading,
  IconButton,
  mergeStyleSets,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  GetServiceDto,
  ServiceFilterQuery
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ErrorAlert } from "@ui-components/Alert.tsx";

import { SettingsLabels } from "../../../shared-components/SettingsLabels.ts";
import { getIconButtonStyles } from "../../SchedulesScreen.styles.ts";
import { FeesList } from "./FeesList.tsx";
import { FeesListFilter } from "./FeesListFilter.tsx";

interface FeesTileProps {
  hideAddButton?: boolean;
  feeButtons?: ReactNode;
}

export const useSearch = (scheduleId?: string, nonStandardFees?: boolean) => {
  const {
    billing: { getServices }
  } = useStores();

  const [queryOptions, setQueryOptions] = useState<ServiceFilterQuery>({});

  const search = useCallback(
    async (query: GetServiceDto) => {
      const result = await getServices({
        ...query,
        hasBenefitId: nonStandardFees,
        scheduleId
      });
      return result;
    },
    [scheduleId, getServices, nonStandardFees]
  );

  return { search, queryOptions, setQueryOptions };
};

export const FeesTile: React.FC<FeesTileProps> = observer(
  ({ hideAddButton, feeButtons }) => {
    const {
      openFeeDialog,
      viewSchedule,
      searchResults,
      canEditSchedule,
      isFeesSearchRejected
    } = useContext(ScheduleScreenContext);

    const { search, queryOptions, setQueryOptions } = useSearch(
      viewSchedule?.id
    );

    const theme = useTheme();
    const iconButtonStyles = getIconButtonStyles(theme);
    const [isFilter, setIsFilter] = useState<boolean>(false);

    const tileHeading = (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        {feeButtons ? (
          feeButtons
        ) : (
          <Heading variant="section-heading">{SettingsLabels.fees}</Heading>
        )}
        <IconButton
          id="schedule-header-filter-button"
          iconProps={{ iconName: "Filter" }}
          onClick={() => setIsFilter(state => !state)}
          styles={mergeStyleSets(iconButtonStyles, {
            icon: { fontSize: theme.fonts.mediumPlus.fontSize }
          })}
        />
      </Stack>
    );

    const addButton =
      canEditSchedule && !hideAddButton ? (
        <DefaultButton
          id="add-service-btn"
          onClick={() => openFeeDialog(viewSchedule!)}
        >
          {SettingsLabels.newFee}
        </DefaultButton>
      ) : undefined;

    if (isFeesSearchRejected) {
      return <ErrorAlert error={searchResults.error} />;
    }

    return (
      <Card
        {...dataAttribute(DataAttributes.Element, "schedule-fees-table")}
        headingLevel="section-heading"
        heading={tileHeading}
        button={addButton}
        styles={{
          subComponentStyles: {
            tile: {
              root: { flexGrow: 1 },
              content: {
                display: "flex",
                flexDirection: "column",
                padding: 16
              }
            }
          }
        }}
      >
        <FeesListFilter
          setFilterSearch={setQueryOptions}
          hidden={isFilter}
          onReset={() => setIsFilter(state => !state)}
        >
          <FeesList search={search} queryOptions={queryOptions} />
        </FeesListFilter>
      </Card>
    );
  }
);
