import { observer } from "mobx-react-lite";

import { PhoneCommunicationText } from "@modules/practice/screens/address-book/components/contacts-lists/PhoneCommunicationText.tsx";
import { useShowContactDetails } from "@modules/practice/screens/address-book/components/hooks/useShowContactDetails.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const ContactCommunicationColumn = observer(
  ({ contact }: { contact: Contact }) => {
    const showContactDetails = useShowContactDetails(contact);

    return (
      <PhoneCommunicationText contact={contact}>
        <Navigate onClick={() => showContactDetails}>Multiple numbers</Navigate>
      </PhoneCommunicationText>
    );
  }
);
