import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";

import {
  CommandBar,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Dialog,
  Heading,
  ICommandBarItemProps,
  Stack
} from "@bps/fluent-ui";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AgentReaction } from "./ReactionForm.types.ts";
import { ReactionFormDialog } from "./ReactionFormDialog.tsx";
import { ReactionsList } from "./ReactionsList.tsx";
import { RenderNilKnownReactionState } from "./RenderNilKnownReactionState.tsx";
import { RenderReactionEmptyState } from "./RenderReactionEmptyState.tsx";
import { fetchReactionsDetails } from "./utils.ts";

export interface ReactionListDialogProps {
  clinicalRecord: ClinicalRecord;
  onDismiss: () => void;
}

export const ReactionListDialog: FunctionComponent<ReactionListDialogProps> =
  observer(({ clinicalRecord, onDismiss }) => {
    useStores();

    const [isDialogVisible, setIsDialogVisible] = useState(false);

    const reactionClinicalData = clinicalRecord.clinicalData?.reaction;

    const onReactionDialogDismiss = () => {
      setIsDialogVisible(false);
    };

    const onAddReaction = () => {
      setIsDialogVisible(true);
    };

    const renderList = () => {
      const key = JSON.stringify(reactionClinicalData?.reactions);
      const nilKnown = reactionClinicalData?.nilKnown;

      return (
        <>
          <DataFetcher<AgentReaction[]>
            key={key}
            fetch={({ drugs }) =>
              fetchReactionsDetails(reactionClinicalData?.reactions, { drugs })
            }
          >
            {reactions => (
              <Stack
                {...dataAttribute(
                  DataAttributes.Element,
                  "reaction-list-dialog-stack"
                )}
                tokens={{ childrenGap: 20 }}
              >
                {reactions.length === 0 && (
                  <>
                    {nilKnown ? (
                      <RenderNilKnownReactionState
                        clinicalRecord={clinicalRecord}
                        onDismiss={onDismiss}
                      />
                    ) : (
                      <RenderReactionEmptyState
                        clinicalRecord={clinicalRecord}
                      />
                    )}
                  </>
                )}

                <ReactionsList
                  reactions={reactions}
                  clinicalRecord={clinicalRecord}
                />
              </Stack>
            )}
          </DataFetcher>
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={{ root: { paddingTop: 23 } }}
          >
            <Stack horizontal horizontalAlign="start">
              {renderCommandbar()}
            </Stack>
            <Stack horizontal horizontalAlign="end">
              <DefaultButton onClick={onDismiss}>Close</DefaultButton>
            </Stack>
          </Stack>
        </>
      );
    };

    const renderCommandbar = () => {
      const items: ICommandBarItemProps[] = [
        {
          key: "add",
          text: "Add reaction",
          iconProps: { iconName: "Add" },
          onClick: onAddReaction
        }
      ];

      return <CommandBar items={items} styles={{ root: { padding: 0 } }} />;
    };

    return (
      <>
        <Dialog
          hidden={false}
          onDismiss={onDismiss}
          dialogContentProps={{
            title: <Heading variant="modal-heading">Reactions</Heading>,
            showCloseButton: true
          }}
          minWidth={780}
        >
          {renderList()}
        </Dialog>

        {isDialogVisible && (
          <ReactionFormDialog
            clinicalRecord={clinicalRecord}
            onDismiss={onReactionDialogDismiss}
            reaction={undefined}
          />
        )}
      </>
    );
  });
