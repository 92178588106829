import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  Heading,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ErrorBlock } from "@ui-components/ErrorBlock.tsx";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { claimFormNameOf } from "../claim.utils.ts";
import { ProviderDeclarationFormValidator } from "../validators/ProviderDeclarationFormValidator.tsx";
import {
  ClaimFormDataAttributes,
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";
import { ClaimLicenceWarning } from "./ClaimLicenceWarning.tsx";

const providerFields: (keyof ClaimFormValues)[] = [
  "hpiCpn",
  "providerId",
  "providerTypeCode",
  "providerDeclared",
  "telehealthDeclared",
  "providerDeclaration",
  "telehealthConsultation",
  "telehealthAuthorisedRecords",
  "telehealthAuthorisedHealthProfessional"
];

const ProviderDeclarationFormSectionBase: React.FC = observer(() => {
  const { practice, acc, core } = useStores();
  const form = useForm<ClaimFormValues>();
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const claimContext = useContext(ClaimContext);

  const onProviderSelectionChanged = async (id: string) => {
    await claimContext.setProviderById(id);
    form.batch(() => {
      form.change(
        claimFormNameOf("providerTypeCode"),
        claimContext.providerTypeCode
      );
      form.change(claimFormNameOf("hpiCpn"), claimContext.provider?.cpn);
    });
  };

  const providerTypes = acc.getAvailableProviderTypes(
    claimContext.provider?.contractTypes
  );

  const onProviderSelectionCleared = async () => {
    form.batch(() => {
      form.change(claimFormNameOf("providerTypeCode"), undefined);
      form.change(
        claimFormNameOf("providerDeclaration"),
        DateTime.today().toJSDate()
      );
      form.change(claimFormNameOf("providerDeclared"), undefined);
      form.change(claimFormNameOf("hpiCpn"), undefined);
    });
    await claimContext.setProviderById(undefined);
  };

  return (
    <ClaimCard
      id={ClaimsCardIds.providerDeclaration}
      errorProps={{ fields: providerFields }}
      openOnRender={selectedMenuItemId === ClaimsCardIds.providerDeclaration}
      heading={ClaimSectionHeadings.providerDeclaration}
      iconName="PenWorkspace"
      statusValidator={
        new ProviderDeclarationFormValidator(
          core,
          claimContext.provider?.contractTypes
        )
      }
    >
      <FieldSpy
        name={claimFormNameOf("providerId")}
        onChange={async id => {
          if (!id) await onProviderSelectionCleared();
          else await onProviderSelectionChanged(id);
        }}
      />
      <Stack tokens={{ childrenGap: 24 }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <div
            style={{ width: 229 }}
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.providerPicker
            )}
          >
            <BookableUserPickerField
              name={claimFormNameOf("providerId")}
              label={ClaimFormLabels.provider}
              filter={{
                statusCodes: [UserStatus.Active]
              }}
              inputProps={{
                placeholder: ClaimFormLabels.searchForProvider,
                name: "provider-filter-box"
              }}
              required
              iconName="Search"
            />
          </div>
          <Stack.Item grow>
            <DropdownField
              {...dataAttribute(
                DataAttributes.Element,
                ClaimFormDataAttributes.providerType
              )}
              required
              name={claimFormNameOf("providerTypeCode")}
              label={ClaimFormLabels.providerType}
              options={providerTypes}
              validateOnInitialize
              disabled={!providerTypes || providerTypes.length < 1}
            />
            <FieldSpy
              name={claimFormNameOf("providerTypeCode")}
              onChange={providerTypeCode => {
                if (providerTypeCode) {
                  const providerTypeAllowAcc45 =
                    practice.ref.accProviderTypes.values.find(
                      p => p.code === providerTypeCode
                    )?.allowAcc45 ?? undefined;

                  form.change(
                    claimFormNameOf("providerTypeAllowAcc45"),
                    providerTypeAllowAcc45
                  );
                } else {
                  form.change(
                    claimFormNameOf("providerTypeAllowAcc45"),
                    undefined
                  );
                }
              }}
            />
          </Stack.Item>
          <DatePickerField
            name={claimFormNameOf("providerDeclaration")}
            label={ClaimFormLabels.providerDeclaration}
            required
            maxDate={DateTime.jsDateNow()}
          />
        </Stack>
        <ClaimLicenceWarning />
        {claimContext.provider && !claimContext.provider?.cpn && (
          <ErrorBlock
            errorText="HPI CPN missing for this provider's user settings. This information is required to lodge an ACC45"
            linkText="Update user settings"
            to={routes.settings.users.user.path({
              id: claimContext.provider.id
            })}
          />
        )}
        {claimContext.provider?.contractTypes?.length === 0 && (
          <ErrorBlock
            errorText="ACC provider types missing for this provider's user settings. This information is required to lodge an ACC45"
            linkText="Update user settings"
            to={routes.settings.users.user.path({
              id: claimContext.provider.id
            })}
          />
        )}
        <Stack tokens={{ childrenGap: 8 }}>
          <Heading variant="section-sub-heading" hasAsterisk>
            Declaration
          </Heading>
          <CheckboxField
            label={ClaimFormLabels.providerDeclared}
            name={claimFormNameOf("providerDeclared")}
          />
        </Stack>
        <BoolButtonGroupField
          label={ClaimFormLabels.telehealthConsultation}
          name={claimFormNameOf("telehealthConsultation")}
        />
      </Stack>
      <FieldCondition
        when={claimFormNameOf("telehealthConsultation")}
        is={true}
      >
        <Stack styles={{ root: { marginTop: 24 } }} tokens={{ childrenGap: 5 }}>
          <Heading
            hasAsterisk
            styles={{
              root: { paddingTop: 5, fontWeight: FontWeights.semibold }
            }}
          >
            {ClaimFormLabels.patientConsentForTelehealth}
          </Heading>
          <Text>{ClaimFormLabels.patientConsentForTelehealthDescription}</Text>
        </Stack>
        <Stack styles={{ root: { marginTop: 16 } }} tokens={{ childrenGap: 8 }}>
          <CheckboxField
            label={ClaimFormLabels.telehealthDeclared}
            name={claimFormNameOf("telehealthDeclared")}
          />
          <CheckboxField
            label={ClaimFormLabels.telehealthAuthorisedHealthProfessional}
            name={claimFormNameOf("telehealthAuthorisedHealthProfessional")}
          />
          <CheckboxField
            label={ClaimFormLabels.telehealthAuthorisedRecords}
            name={claimFormNameOf("telehealthAuthorisedRecords")}
          />
        </Stack>
      </FieldCondition>
    </ClaimCard>
  );
});

export const ProviderDeclarationFormSection = withFetch(
  x => [
    x.practice.ref.accProviderTypes.load(),
    x.practice.ref.accProviderContractTypes.load()
  ],
  ProviderDeclarationFormSectionBase
);
