import { useForm, useFormState } from "react-final-form";

import { confirm } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  IntakeFlagRefDataDto,
  IntakeStatus,
  IntakeStatuses
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { IntakeDomain, IntakeFormValues } from "./IntakeForm.types.ts";
import {
  ceaseAllIntakes,
  getDefaultIntake,
  getFilteredOptions,
  isIntakeStatusCurrent,
  isIntakeStatusEx,
  isIntakeStatusNever,
  removeNewIntakes
} from "./utils.ts";

interface IntakeStatusFieldProps {
  domain: IntakeDomain;
}

export const IntakeStatusField: React.FunctionComponent<
  IntakeStatusFieldProps
> = ({ domain }) => {
  const { clinical } = useStores();
  const nameOf = nameOfFactory<IntakeFormValues>();

  const { mutators, change } = useForm<IntakeFormValues>();
  const { values } = useFormState<IntakeFormValues>();
  const { currentIntakes, exSmokerLessThan12Months } = values;

  const onChangeBefore = async (selectedKey?: string) => {
    const hasCurrentIntake = !!currentIntakes.filter(x => x.id).length;

    if (hasCurrentIntake && isIntakeStatusEx(selectedKey)) {
      return await confirm({
        dialogContentProps: {
          subText: "Current intake will be ceased"
        },
        confirmButtonProps: {
          text: "Ok"
        },
        cancelButtonProps: {
          text: "Cancel"
        }
      });
    }
    return true;
  };

  const onChange = (selectedKey?: string) => {
    // reset exSmokerLessThan12Months for Tobacco form if status has been changed from ExSmoker
    if (
      domain === ClinicalDataType.Tobacco &&
      selectedKey !== IntakeStatuses.ExSmoker
    ) {
      if (exSmokerLessThan12Months) {
        change("exSmokerLessThan12Months", false);
      }
    }
    if (currentIntakes.length) {
      if (isIntakeStatusEx(selectedKey)) {
        return ceaseAllIntakes(currentIntakes, mutators);
      }

      if (isIntakeStatusNever(selectedKey)) {
        return removeNewIntakes(currentIntakes, mutators);
      }
    }

    // add a default intake record is set to Active status and there are no new
    // records yet
    if (isIntakeStatusCurrent(selectedKey) && currentIntakes.every(i => i.id)) {
      return mutators.push(
        nameOf("currentIntakes"),
        getDefaultIntake(domain, nameOf("currentIntakes"))
      );
    }
  };

  const options = getFilteredOptions(
    clinical.ref.intakeStatuses.values as IntakeFlagRefDataDto<IntakeStatus>[],
    domain
  );

  return (
    <>
      <ButtonsGroupSingleChoiceField
        equalWidth
        required
        label="Current state"
        name={nameOf("status")}
        options={options}
        styles={{
          root: { maxWidth: 600, minWidth: 300 },
          label: { flex: 1 }
        }}
        onConfirmBefore={onChangeBefore}
      />
      <FieldSpy name={nameOf("status")} onChange={onChange} />
    </>
  );
};
