import { Text } from "@bps/fluent-ui";
import { FeeType } from "@libs/gateways/billing/BillingGateway.dtos.ts";

export const SelectedServiceTitleQuantity: React.FC<{
  feeType: FeeType;
  quantity: string;
  isExtended: boolean;
}> = ({ isExtended, feeType, quantity }) => {
  if (isExtended) {
    return null;
  }

  let label = "Qty";
  if (feeType === FeeType.Km) {
    label = "Km";
  } else if (feeType === FeeType.Hourly) {
    label = "Hr";
  }

  const quantityLabel =
    feeType === FeeType.Hourly
      ? Math.round((Number(quantity) / 60) * 100) / 100
      : quantity;

  return <Text>{`(${label} ${quantityLabel})`}</Text>;
};
