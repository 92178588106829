import { isEqual } from "lodash";
import { parse } from "query-string";

import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { GetTransactionsArgs } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { TransactionFilterBaseProps } from "../TransactionFilterBarBase.tsx";
import {
  SetFilter,
  TransactionFilterBase,
  transactionFilterBaseNameOf,
  TransactionFilterQueryBase
} from "../TransactionFilterBase.types.ts";

export class TransactionFilterHelper<TFilter extends TransactionFilterBase> {
  constructor(
    private store: IRootStore,
    private options: Pick<
      TransactionFilterBaseProps<TFilter>,
      "items" | "parseToGetByArgs"
    >
  ) {}

  private get items() {
    return this.options.items;
  }

  private get parseToGetByArgs() {
    return this.options.parseToGetByArgs;
  }

  public parseToGetByArgsWithBase = (
    searchQuery: string
  ): GetTransactionsArgs => {
    const queryFilter: TransactionFilterQueryBase = parse(searchQuery);
    const baseFilter: TransactionFilterBase = searchQuery
      ? this.getBaseInitialValues(queryFilter)
      : {};

    const filter = this.parseToGetByArgs
      ? this.parseToGetByArgs(searchQuery)
      : {};
    return {
      ...baseFilter,
      startTime: queryFilter.startTime,
      endTime: queryFilter.endTime,
      ...filter
    };
  };

  public getTransactionArgs = (query: PagingOptions): GetTransactionsArgs => {
    const searchQuery = this.store.routing.location.search;
    return {
      ...query,
      ...this.parseToGetByArgsWithBase(searchQuery)
    };
  };

  public getBaseInitialValues = (
    query: TransactionFilterQueryBase
  ): TransactionFilterBase => {
    const values: TransactionFilterBase = {};
    this.items.forEach(item => {
      switch (item.name) {
        case transactionFilterBaseNameOf("search"):
          values.search = query.search;
          break;
        case transactionFilterBaseNameOf("patientId"):
          values.patientId = query.patientId;
          break;
        case transactionFilterBaseNameOf("numberSearch"):
          values.numberSearch = query.numberSearch;
          break;
        case transactionFilterBaseNameOf("accountIds"):
          values.accountIds =
            typeof query.accountIds === "string"
              ? [query.accountIds]
              : query.accountIds;
          break;
        case transactionFilterBaseNameOf("claimStatuses"):
          values.claimStatuses =
            typeof query.claimStatuses === "string"
              ? [query.claimStatuses]
              : query.claimStatuses;
          break;
        case transactionFilterBaseNameOf("userIds"):
          values.userIds =
            typeof query.userIds === "string" ? [query.userIds] : query.userIds;
          break;
        case "datesRangePicker":
          values.startTime = DateTime.jsDateFromISO(query.startTime);
          values.endTime = DateTime.jsDateFromISO(query.endTime);
          break;
      }
    });

    return { ...values };
  };

  public setFilter: SetFilter = (newQuery: { [key: string]: any }) => {
    const existingFilter = parse(this.store.routing.location.search);
    const query = {
      ...existingFilter,
      ...newQuery
    };
    if (!isEqual(query, existingFilter)) {
      this.store.routing.pushQueryStringParams(query);
    }
  };

  public onBaseFilterChange = (options: {
    field: string | number | symbol;
    values: TransactionFilterBase;
  }) => {
    const { field, values } = options;

    if (field === "startTime" || field === "endTime") {
      const date = values[field];

      const oppositeTimeField: keyof Pick<
        TransactionFilterBase,
        "startTime" | "endTime"
      > = field === "startTime" ? "endTime" : "startTime";

      const oppositeTimeValue = values[oppositeTimeField];

      return this.setFilter({
        [field]: !!date ? DateTime.jsDateToISODate(date) : undefined,
        [oppositeTimeField]: oppositeTimeValue
          ? DateTime.jsDateToISODate(oppositeTimeValue)
          : undefined
      });
    }
  };
}
