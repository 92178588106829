import {
  isAfterTimeField,
  maxLength,
  predicate,
  required,
  todayOrLater
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  ClosedChoice,
  EditPracticeDayWorkingHoursFormValues,
  nameOf
} from "../EditPracticeDayWorkingHoursForm.types.ts";

const isPresentCondition = (
  val: string,
  values: EditPracticeDayWorkingHoursFormValues
) => values.closedChoice === ClosedChoice.open;

export class EditPracticeDayWorkingHoursFormValidator extends Validator<EditPracticeDayWorkingHoursFormValues> {
  constructor() {
    super();

    this.forField(
      "startTime",
      predicate(
        isPresentCondition,
        required(),
        isAfterTimeField(
          "Start time must be earlier than end time.",
          nameOf("endTime"),
          true
        )
      )
    );
    this.forField("endTime", predicate(isPresentCondition, required()));
    this.forField("date", [required(), todayOrLater]);
    this.forField("reason", maxLength(250));
  }
}
