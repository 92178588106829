import {
  AddUserDto,
  CatalogBusinessRoleProfileDto
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface CreateUserFormValues
  extends Omit<AddUserDto, "isInactive" | "communications"> {
  phone: string | undefined;
  medicareProviderNumber: string | undefined;
  ahpraNumber: string | undefined;
}

export interface BusinessRoleProfile {
  code: string;
  profile: CatalogBusinessRoleProfileDto | undefined;
}

export const nameOf = nameOfFactory<CreateUserFormValues>();
