import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { CheckboxVisibility, SelectionMode, useTheme } from "@bps/fluent-ui";
import { appointmentFormNameOf } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/AppointmentForm.types.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { DetailsListField } from "@ui-components/form/DetailsListField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { ConditionListItem } from "../condition-modal/Condition.types.ts";
import { ActiveConditionListItem } from "./ActiveConditionListItem.tsx";

interface ActiveClaimListProps {
  conditions: ICondition[];
}

export const ActiveConditionList: React.FC<ActiveClaimListProps> = observer(
  ({ conditions }) => {
    const theme = useTheme();
    const { setEpisodeOfCareId } = useContext(AppointmentFormContext);
    const conditionList: ConditionListItem[] = conditions.map(m => ({
      ...m,
      key: m.episodeOfCareId
    }));

    return (
      <>
        <FieldSpy
          name={appointmentFormNameOf("episodeOfCareId")}
          onChange={val => {
            setEpisodeOfCareId(val);
          }}
        />
        <DetailsListField
          setKey="condition-list"
          isHeaderVisible={false}
          selectionMode={SelectionMode.single}
          name={appointmentFormNameOf("episodeOfCareId")}
          checkboxVisibility={CheckboxVisibility.always}
          items={conditionList}
          columns={[
            {
              name: "episodeOfCare",
              key: "episodeOfCare",
              flexGrow: 1,
              minWidth: 1,
              isMultiline: true,
              onRender: (item: ConditionListItem) => (
                <ActiveConditionListItem conditionListItem={item} />
              )
            }
          ]}
          extraRowStyles={{
            root: {
              marginBottom: 8,
              borderRadius: 2,
              border: `1px solid ${theme.palette.neutralLighter}`
            }
          }}
          styles={{
            root: {
              overflowX: "visible"
            }
          }}
          cellStyleProps={{
            cellExtraRightPadding: 16,
            cellRightPadding: 4,
            cellLeftPadding: 4
          }}
          fullRowSelection
        />
      </>
    );
  }
);
