import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext, useRef } from "react";
import { FormRenderProps } from "react-final-form";

import { CenteredLargeSpinner, IMaskedTextField, Stack } from "@bps/fluent-ui";
import { unique } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { TemplateManagementContext } from "./context/TemplateManagementContext.tsx";
import { TemplateFieldsList } from "./TemplateFieldsList.tsx";
import {
  TemplateFieldSelection,
  TemplateManagementFormValues
} from "./TemplateManagementTypes.ts";

export const nameOf = nameOfFactory<TemplateManagementFormValues>();

export const TemplateManagementModal: FunctionComponent = observer(() => {
  const { templateManagement } = useStores();

  const {
    fields,
    saveTemplate,
    hideTemplateManagementModal,
    templateManagementInitialValues,
    typeToFormatMap
  } = useContext(TemplateManagementContext);

  const templateFieldRef = useRef<IMaskedTextField | null>(null);
  const contextRef = useRef<string[]>(
    templateManagementInitialValues?.context ?? []
  );

  const handleDismiss = () => {
    hideTemplateManagementModal();
  };

  const addTemplateField = (
    templateField: TemplateFieldSelection,
    formApi: FormRenderProps<TemplateManagementFormValues>
  ) => {
    const { form, values } = formApi;

    const currentValue = values.content === undefined ? "" : values.content;

    let cursorPosition = 0;
    if (
      templateFieldRef.current !== null &&
      templateFieldRef.current.selectionStart
    ) {
      cursorPosition = templateFieldRef.current.selectionStart;
    }

    const newValue = `${
      currentValue.slice(0, cursorPosition) + templateField.path
    }${currentValue.slice(cursorPosition)}`;

    form.change(nameOf("content"), newValue);

    const newContexts = [...contextRef.current, templateField.context];
    contextRef.current = unique(newContexts);
  };

  return (
    <SubmissionFormDialog<TemplateManagementFormValues>
      dialogProps={{
        maxWidth: 800,
        minWidth: 800,
        onDismiss: handleDismiss
      }}
      onSubmit={(values: TemplateManagementFormValues) => {
        saveTemplate({ ...values, context: contextRef.current });
      }}
      dialogName="Template edit"
      initialValues={templateManagementInitialValues}
    >
      {formApi => {
        return (
          <Stack tokens={{ childrenGap: 16 }}>
            <TextInputField
              name={nameOf("name")}
              label="Name"
              maxLength={50}
              required
            />
            <TextInputField
              name={nameOf("description")}
              label="Description"
              maxLength={250}
              rows={2}
              multiline
              required
            />
            <DropdownField
              label="Template type"
              name={nameOf("templateTypeCode")}
              options={templateManagement.ref.templateTypes.keyTextValues}
              required
            />
            <DropdownField
              label="Template format"
              name={nameOf("templateFormatCode")}
              options={templateManagement.ref.templateFormats.keyTextValues}
              required
              disabled
            />
            <FieldSpy
              name={nameOf("templateTypeCode")}
              onChange={(value: string) => {
                formApi.form.change(
                  nameOf("templateFormatCode"),
                  typeToFormatMap.get(value)
                );
              }}
            />
            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <TextInputField
                label="Template"
                multiline
                rows={30}
                name={nameOf("content")}
                styles={{
                  root: { maxWidth: 500, minWidth: 500 }
                }}
                componentRef={r => {
                  if (!templateFieldRef.current) templateFieldRef.current = r;
                }}
              />
              <DataFetcher
                fetch={() => fields.loadTemplateFields()}
                fallback={<CenteredLargeSpinner />}
              >
                {() => (
                  <TemplateFieldsList
                    addTemplateField={templateField =>
                      addTemplateField(templateField, formApi)
                    }
                  />
                )}
              </DataFetcher>
            </Stack>
          </Stack>
        );
      }}
    </SubmissionFormDialog>
  );
});
