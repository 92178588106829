import {
  AddressDto,
  CommunicationDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";

const communicationRank = (type: CommunicationType) => {
  switch (type) {
    case CommunicationType.Email:
      return 1;
    case CommunicationType.Phone:
      return 2;
    case CommunicationType.Mobile:
      return 3;
    case CommunicationType.AfterHours:
      return 4;
    case CommunicationType.Website:
      return 5;
    case CommunicationType.Fax:
      return 6;
    default:
      return 7;
  }
};

export const sortedCommunications = (communications: CommunicationDto[]) =>
  communications
    .slice()
    .sort((a, b) => communicationRank(a.type) - communicationRank(b.type));

export const sortedAddresses = (addresses: AddressDto[]) =>
  addresses.slice().sort((a, b) => {
    if (a.type > b.type) {
      return 1;
    }
    if (a.type < b.type) {
      return -1;
    }
    return 0;
  });
