import { action, observable } from "mobx";

import { DateTime, newGuid } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Country } from "@libs/enums/country.enum.ts";
import { AttendeeTypeEnum } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  AddressType,
  CommunicationType,
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";
import { DemographicInitialValues } from "@shared-types/practice/demographic-form-values.types.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  convertSearchValueToBirthdayString,
  hasValidBirthdayData,
  hasValidNameData,
  hasValidPhoneNumberData
} from "@ui-components/pickers/contact-picker/contact-picker.utils.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

import {
  PatientMatchFilter,
  PatientMatchListItem
} from "../components/PatientMatchListItem.types.ts";

type addressInfo = {
  street1?: string;
  suburb?: string;
  postCode?: string;
  country?: Country;
  state?: string;
  city?: string;
};

export class PatientMatchHelper {
  constructor(private root: RootStore) {}

  @observable
  showPatientMatchingDiscardDialog: boolean = false;

  @observable
  totalMatched: number | undefined;

  @observable
  showPatientMatchingProgressDialog: boolean = false;

  @observable
  selectedPatient: Contact | undefined;

  filterCriteria: PatientMatchFilter = {
    statuses: [ContactStatus.Active]
  };

  @action
  setTotalMatched = (value: number) => {
    this.totalMatched = value;
  };

  @action
  setShowPatientMatchingDiscardDialog = (value: boolean) => {
    this.showPatientMatchingDiscardDialog = value;
  };

  @action
  setShowPatientMatchingProgressDialog = (value: boolean) => {
    this.showPatientMatchingProgressDialog = value;
  };

  @action
  setSelectedPatient = (value: Contact) => {
    this.selectedPatient = value;
  };

  get booking() {
    return this.root.booking;
  }

  get practice() {
    return this.root.practice;
  }

  get core() {
    return this.root.core;
  }

  selection: Selection = new Selection({
    onSelectionChanged: () => {
      const [firstSelection] = this.selection.getSelection() as Contact[];
      this.setSelectedPatient(firstSelection);
    }
  });

  get calendarEvent() {
    const calendarEventId = this.booking.ui.patientMatchInfo?.calendarEventId;
    if (!calendarEventId) return undefined;
    return this.booking.calendarEventsMap.get(calendarEventId);
  }

  get externalPatient() {
    return this.calendarEvent?.externalPatient;
  }

  addAttendeeToCalendarEvent = async (attendeeId: string) => {
    if (this.calendarEvent) {
      const attendees = [...this.calendarEvent.dto.attendees];

      attendees.push({ type: AttendeeTypeEnum.contact, attendeeId });

      await this.booking.updateCalendarEvent({
        id: this.calendarEvent.id,
        attendees
      });
    }
  };

  getMatchedResult = async (
    query: PagingOptions
  ): Promise<QueryResult<PatientMatchListItem>> => {
    const noResult = { results: [], skip: 0, total: 0, take: 0 };
    if (!this.externalPatient) return noResult;

    const { lastName, dateOfBirth, mobile } = this.externalPatient;

    const dob =
      dateOfBirth && DateTime.fromISO(dateOfBirth).toDayDefaultFormat();

    const isValidBirthdayDate = hasValidBirthdayData(dob);

    const isValidPhoneNumber = hasValidPhoneNumberData(
      mobile?.replaceAll(" ", "")
    );

    const isValidName = hasValidNameData(lastName);

    if (!(isValidName || isValidBirthdayDate || isValidPhoneNumber))
      return noResult;

    const matchedContacts = await this.practice.fetchMatchedContacts({
      filter: {
        ...query,
        types: [ContactType.Patient],
        statuses: [ContactStatus.Active],
        lastName: isValidName ? lastName : undefined,
        phoneNumber: isValidPhoneNumber ? mobile : undefined,
        birthday: isValidBirthdayDate
          ? convertSearchValueToBirthdayString(dob)
          : undefined
      }
    });

    const items: PatientMatchListItem[] = matchedContacts.results.map(
      patient => {
        const onRecordNumber = patient.phoneCommunicationsOnly.find(
          comm => comm.value === mobile
        );
        return {
          contact: patient,
          id: patient.id,
          name: patient.name,
          birthDate: patient.birthDate?.toDayDefaultFormat(),
          preferredPhone: onRecordNumber ? onRecordNumber.value : patient.phone,
          email: patient.email,
          address: patient.defaultAddress
            ? addressText(patient.defaultAddress)
            : undefined
        };
      }
    );

    this.setTotalMatched(matchedContacts.total || 0);
    return { ...matchedContacts, results: items };
  };

  patientSearch = async (
    query: PagingOptions,
    filter: PatientMatchFilter
  ): Promise<QueryResult<PatientMatchListItem>> => {
    const isValidBirthdayDate = hasValidBirthdayData(filter.search);
    const isValidPhoneNumber = hasValidPhoneNumberData(filter.search);
    const isValidName = hasValidNameData(filter.search);

    const results = await this.practice.fetchContacts({
      filter: {
        ...query,
        search: isValidName && !isValidPhoneNumber ? filter.search : undefined,
        birthday: isValidBirthdayDate
          ? convertSearchValueToBirthdayString(filter.search)
          : undefined,
        phoneNumber: isValidPhoneNumber ? filter.search : undefined,
        types: [ContactType.Patient],
        statuses:
          (filter.statuses?.length ?? 0) === 0
            ? [ContactStatus.Active, ContactStatus.Inactive]
            : filter.statuses
      }
    });

    return {
      ...results,
      results: results.results.map(patient => ({
        contact: patient,
        id: patient.id,
        name: patient.name,
        birthDate: patient.birthDate?.toDayDefaultFormat(),
        preferredPhone: patient.phone,
        email: patient.email,
        address: patient.defaultAddress
          ? addressText(patient.defaultAddress)
          : undefined
      }))
    };
  };

  onCreateNewPatientClick = () => {
    if (this.booking.ui.patientMatchInfo) {
      this.booking.ui.patientMatchInfo.onCreateContact =
        this.onCreateNewContact;
    }
    this.showAddContactModal();
  };

  private showAddContactModal = () => {
    let initialValues: DemographicInitialValues | undefined;

    if (this.externalPatient) {
      this.practice.ui.showAddContact(ContactType.Patient);

      const {
        firstName,
        lastName,
        mobile,
        address: street1,
        postcode,
        suburb,
        email,
        dateOfBirth,
        state,
        city,
        country
      } = this.externalPatient;

      initialValues = {
        firstName,
        lastName,
        addresses: this.getDefaultAddress({
          street1,
          suburb,
          postCode: postcode,
          state,
          city,
          country
        }),
        communications: this.getDefaultCommunication(mobile, email),
        birthDate: dateOfBirth
          ? DateTime.fromISO(dateOfBirth).toJSDate()
          : undefined
      };
    }

    this.practice.ui.showAddContact(ContactType.Patient, initialValues);
  };

  private onCreateNewContact = async (patientId: string) => {
    await this.addAttendeeToCalendarEvent(patientId);
    this.onClosePatientMatch();
  };

  private getDefaultAddress = ({
    street1,
    suburb,
    postCode,
    country,
    state,
    city
  }: addressInfo) => {
    const address: AddressFieldValue = {
      type: AddressType.Physical,
      postCode,
      street1,
      id: newGuid(),
      suburb,
      state,
      city,
      country
    };
    return [address];
  };

  private getDefaultCommunication = (mobile?: string, email?: string) => {
    const communications: CommunicationFieldValue[] = [];
    mobile &&
      communications.push({
        type: CommunicationType.Mobile,
        value: mobile,
        preferred: false,
        id: newGuid()
      });

    email &&
      communications.push({
        type: CommunicationType.Email,
        value: email,
        preferred: false,
        id: newGuid()
      });
    return communications.length > 0 ? communications : undefined;
  };

  onClosePatientMatch = () => {
    this.booking.ui.setPatientMatchInfo(undefined);
    this.booking.ui.setShowPatientMatchingDialog(false);
  };
}
