import { useContext } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";

export const CreateTemporaryReservation: React.FunctionComponent = () => {
  const { asyncCreateTemporaryReservation } = useContext(
    AppointmentFormContext
  );

  return (
    <When permission={Permission.PreRelease}>
      <FieldSpy
        name={appointmentFormNameOf("duration")}
        onChange={(value, values) => asyncCreateTemporaryReservation(values!)}
      />
      <FieldSpy
        name={appointmentFormNameOf("providerId")}
        onChange={(value, values) => asyncCreateTemporaryReservation(values!)}
      />
      <FieldSpy
        name={appointmentFormNameOf("startDate")}
        onChange={(value, values) => asyncCreateTemporaryReservation(values!)}
      />
    </When>
  );
};
