import {
  newInvoiceNavLink,
  newPaymentNavLink
} from "app-shell/NavBar/FinanceNavButton.tsx";
import { observer } from "mobx-react-lite";
import { stringify } from "query-string";
import { FunctionComponent } from "react";

import { useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

export const ADD_ACCOUNT_CREDIT_BTN = "addAccountCredit";

interface BillingButtonsProps {
  contactId?: string;
  hideAccountCredit?: boolean;
}

export const BillingButtons: FunctionComponent<BillingButtonsProps> = observer(
  ({ contactId, hideAccountCredit }) => {
    const { routing, billing } = useStores();

    const theme = useTheme();

    return (
      <Nav
        renderAsButton
        buttonProps={{
          hideChevron: true,
          iconProps: { iconName: "add" },
          gapSpace: 12,
          buttonStyles: {
            root: {
              height: 32,
              width: 32,
              minWidth: 32,
              alignSelf: "center",
              border: `1px solid ${theme.palette.neutralSecondaryAlt}`,
              borderRadius: 4,
              background: theme.semanticColors.buttonBackground,
              color: theme.palette.neutralSecondary,
              selectors: {
                "&:hover": {
                  background: theme.semanticColors.buttonBackgroundHovered
                },
                "& .ms-Button-icon": {
                  color: theme.palette.neutralSecondary
                },
                "&:active .ms-Button-icon": {
                  color: theme.palette.neutralSecondary
                }
              }
            },
            rootExpanded: {
              selectors: {
                "& .ms-Button-icon": {
                  color: theme.palette.neutralSecondary
                }
              }
            },
            iconHovered: { color: theme.palette.neutralSecondary },
            menuIcon: { display: "none" }
          }
        }}
        links={[
          {
            ...newInvoiceNavLink(billing),
            key: "new-invoice",
            onClick: () => billing.ui.showInvoiceDetailsDialog(contactId)
          },
          {
            ...newPaymentNavLink,
            onClick: () =>
              routing.pushWithFromQuery({
                pathname: routes.accounts.allocations.new.pattern,
                search: stringify({ accountId: contactId })
              }),
            showBorder: !contactId
          },
          {
            name: "Add account credit",
            key: "add-account-credit",
            path: routes.accounts.allocations.new,
            onClick: () => {
              routing.pushWithFromQuery(
                {
                  pathname: routes.accounts.allocations.new.pattern,
                  search: stringify({ accountId: contactId })
                },
                { buttonClicked: ADD_ACCOUNT_CREDIT_BTN }
              );
            },
            showBorder: true,
            filter: !hideAccountCredit
          },
          {
            key: "new-statement",
            permissions: [Permission.StatementWrite],
            onClick: () => {
              billing.ui.onToggleStatementModal(true);
            },
            name: "New statement",
            showBorder: true
          }
        ].filter(x => x.filter === undefined || !!x.filter)}
      />
    );
  }
);
