import { nameof } from "@libs/utils/name-of.utils.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { TaskActionFormValues } from "../Tasks.types.ts";
import { OutcomeValidator } from "./OutcomeValidator.tsx";

export class TaskActionFormValidator extends Validator<TaskActionFormValues> {
  constructor() {
    super();
    this.forField(nameof("currentOutcome"), new OutcomeValidator());
  }
}
