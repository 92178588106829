import { FC } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";

import {
  getCreditStyles,
  getTotalOwingStyles
} from "./PatientAccountBalance.styles.tsx";

type PatientBalanceType = {
  unallocatedTotal: number;
  accountBalance: number;
  owingTotal: number;
};

type PatientAccountBalanceProps = Pick<
  PatientBalanceType,
  "unallocatedTotal" | "owingTotal"
>;

export const PatientAccountBalance: FC<PatientAccountBalanceProps> = ({
  owingTotal,
  unallocatedTotal
}) => {
  const theme = useTheme();

  const getBalanceJSX = (options: {
    text: string;
    className: string;
    id: string;
    value: number;
  }) => {
    const { text, className, id, value } = options;
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        className={className}
        styles={{ root: { width: "50%" } }}
      >
        <Text variant="small">{text}</Text>
        <Text
          variant="mediumPlus"
          styles={{ root: { fontWeight: FontWeights.semibold } }}
          id={id}
          {...dataAttribute(DataAttributes.Element, id)}
        >
          {value >= 0 && currencyFormat(value)}
        </Text>
      </Stack>
    );
  };

  return (
    <Stack styles={{ root: { minWidth: 300 } }} horizontal>
      {getBalanceJSX({
        text: "Total owing",
        className: getTotalOwingStyles(owingTotal, theme),
        id: "account-balance-owing-total",
        value: owingTotal
      })}
      {getBalanceJSX({
        text: "Credit",
        className: getCreditStyles(unallocatedTotal, theme),
        id: "account-balance-unallocated-total",
        value: unallocatedTotal
      })}
    </Stack>
  );
};
