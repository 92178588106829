import { FunctionComponent } from "react";

import { TerminologyConceptDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DiagnosisSideData } from "@shared-types/clinical/diagnosis-side-data.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ReadCodesHintProps {
  data: DiagnosisSideData;
}
export const ReadCodesHint: FunctionComponent<ReadCodesHintProps> = ({
  data
}) => {
  const { clinical } = useStores();
  return (
    <DataFetcher<TerminologyConceptDto[]>
      fetch={async () =>
        await clinical.getTerminologiesLookup([
          {
            code: data.diagnosis,
            codeSystem: data.codeSystem,
            version: data.version
          }
        ])
      }
    >
      {terminologies => {
        return (
          terminologies[0]?.readCodes &&
          terminologies[0]?.readCodes[0] && (
            <>Read code: {terminologies[0]?.readCodes[0]}</>
          )
        );
      }}
    </DataFetcher>
  );
};
