import { observer } from "mobx-react-lite";

import {
  FontSizes,
  IStyle,
  LabeledText,
  LabeledTextGroup,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ExternalPatient } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { usePatientMatchContext } from "../context/PatientMatchContext.ts";

export const ExternalPatientInfo: React.FC = observer(() => {
  const theme = useTheme();
  const { externalPatient } = usePatientMatchContext();
  const patientLabel = usePatientLabel(false);

  const getContent = (data: ExternalPatient) => {
    const {
      firstName = "",
      lastName = "",
      dateOfBirth = "",
      email = "",
      address = "",
      mobile = "",
      postcode,
      suburb,
      country,
      state,
      city
    } = data;

    const externalPatAddress = addressText({
      type: AddressType.Physical,
      postCode: postcode,
      street1: address,
      suburb,
      state,
      city,
      country
    });

    const textStyle: { text: IStyle } = { text: { marginTop: 4 } };
    return (
      <>
        <LabeledText noSemicolon label=" First name" styles={textStyle}>
          {firstName}
        </LabeledText>
        <LabeledText noSemicolon label="Last name" styles={textStyle}>
          {lastName}
        </LabeledText>
        <LabeledText noSemicolon label="DOB" styles={textStyle}>
          {dateOfBirth
            ? DateTime.fromISO(dateOfBirth).toDayDefaultFormat()
            : ""}
        </LabeledText>
        <LabeledText noSemicolon label="Phone" styles={textStyle}>
          {mobile}
        </LabeledText>
        <LabeledText noSemicolon label="Email" styles={textStyle}>
          {email}
        </LabeledText>
        <LabeledText noSemicolon label="Address" styles={textStyle}>
          <TooltipHost content={externalPatAddress}>
            {externalPatAddress}
          </TooltipHost>
        </LabeledText>
      </>
    );
  };
  return (
    <Stack>
      <Text bold styles={{ root: { fontSize: FontSizes.size14 } }}>
        Select a {patientLabel} from the matched results or search for a{" "}
        {patientLabel} record
      </Text>
      <LabeledTextGroup
        gap={20}
        horizontalColumnCount={6}
        styles={{
          root: {
            margin: "12px 0",
            padding: 8,
            border: `1px ${theme.palette.neutralLight} solid`,
            borderRadius: 4,
            gridRowGap: "8px"
          }
        }}
      >
        {externalPatient && getContent(externalPatient)}
      </LabeledTextGroup>
    </Stack>
  );
});
