import { observer } from "mobx-react-lite";

import { Modal } from "@bps/fluent-ui";
import { DeleteCheckDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimDeleteRequest } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClaimCannotDeleteDialog } from "./ClaimCannotDeleteDialog.tsx";
import { ClaimDeleteAppointmentDialog } from "./ClaimDeleteAppointmentDialog.tsx";
import { ClaimDeleteDialog } from "./ClaimDeleteDialog.tsx";
import { ClaimDeleteInvoiceDialog } from "./ClaimDeleteInvoiceDialog.tsx";

interface ClaimDeleteConfirmDialogBaseProps {
  deleteCheck: DeleteCheckDto;
  deletingClaim: Claim | undefined;
  setDeletingClaim: (claim: Claim | undefined) => void;
}

interface ClaimDeleteConfirmDialogProps {
  deletingClaim: Claim | undefined;
  setDeletingClaim: (claim: Claim | undefined) => void;
}

export interface DeleteClaimDialogProps {
  text: string;
  onDismiss: () => void;
  makePrivate: () => Promise<void>;
}

const ClaimDeleteConfirmDialogBase: React.FC<ClaimDeleteConfirmDialogBaseProps> =
  observer(({ deleteCheck, deletingClaim, setDeletingClaim }) => {
    const { acc } = useStores();

    if (!deletingClaim) {
      return null;
    }

    const deleteClaim = async () => {
      await acc.deleteBulkClaims([deletingClaim.id]);
      setDeletingClaim(undefined);
    };

    const { claimDeleteCheckResponseText, claimDeleteRequestResponseCode } =
      deleteCheck;

    const onDismiss = () => {
      setDeletingClaim(undefined);
    };

    const makePrivate = async () => {
      const claimEpisodesOfCare = await acc.getClaimEpisodesOfCare({
        claimId: deletingClaim.id
      });
      await Promise.all([
        acc.transitionClaimEpisodeOfCareToPrivate(
          deletingClaim.id,
          claimEpisodesOfCare[0].episodeOfCareId
        ),
        acc.patchClaim({
          id: deletingClaim.id,
          private: true
        })
      ]);
    };

    switch (claimDeleteRequestResponseCode) {
      case ClaimDeleteRequest.noBecauseAppointment:
        return (
          <ClaimDeleteAppointmentDialog
            onDismiss={onDismiss}
            makePrivate={makePrivate}
            text={claimDeleteCheckResponseText}
            deleteClaim={deleteClaim}
          />
        );
      case ClaimDeleteRequest.noBecauseEncounterInProgress:
      case ClaimDeleteRequest.noBecauseEncounterInFinalised:
        return (
          <ClaimCannotDeleteDialog
            onDismiss={onDismiss}
            makePrivate={makePrivate}
            text={claimDeleteCheckResponseText}
          />
        );
      case ClaimDeleteRequest.noBecauseInvoice:
        return (
          <ClaimDeleteInvoiceDialog
            text={claimDeleteCheckResponseText}
            onDismiss={onDismiss}
          />
        );
      default:
        return (
          <ClaimDeleteDialog
            onDismiss={onDismiss}
            text={claimDeleteCheckResponseText}
            deleteClaim={deleteClaim}
          />
        );
    }
  });

export const ClaimDeleteConfirmDialog: React.FC<
  ClaimDeleteConfirmDialogProps
> = ({ deletingClaim, ...props }) => {
  return (
    <DataFetcher<DeleteCheckDto>
      fetch={({ acc }) => acc.deleteCheck(deletingClaim!.id)}
      fallback={
        <Modal
          styles={{
            main: {
              minWidth: 450,
              minHeight: 120,
              display: "grid",
              alignItems: "center",
              justifyContent: "center"
            }
          }}
          isOpen={true}
        >
          Checking claim can be deleted...
        </Modal>
      }
    >
      {deleteCheckDto => (
        <ClaimDeleteConfirmDialogBase
          deletingClaim={deletingClaim}
          {...props}
          deleteCheck={deleteCheckDto}
        />
      )}
    </DataFetcher>
  );
};
