import { ACCEPTED_DAY_FORMATS } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  integer,
  maxLength,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { HealthInsuranceFields } from "../PatientEditFormValues.tsx";

export class HealthInsuranceValidator extends Validator<HealthInsuranceFields> {
  nameOf = nameOfFactory<HealthInsuranceFields>();

  constructor() {
    super();
    this.forField(this.nameOf("fund"), [required(), maxLength(50)]);
    this.forField(this.nameOf("number"), [required(), maxLength(20)]);
    this.forField(this.nameOf("familyNumber"), [
      maxLength(2),
      integer(),
      greaterThan(0)
    ]);
    this.forField(this.nameOf("expiry"), [validDate(...ACCEPTED_DAY_FORMATS)]);
  }
}
