import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { Link, Stack, Text } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ConfidentialToolTipFontIcon } from "../../ConfidentialToolTipFontIcon.tsx";
import { RequestBadge } from "../RequestBadge.tsx";
import { ConfidentialByProviderText } from "./ConfidentialByProviderText.tsx";
import { InvestigationSidePanelContext } from "./InvestigationsSidePanelContext.ts";

export interface RequestsListItemProps {
  record: ClinicalDocument;
}

export const RequestListItem: FC<RequestsListItemProps> = observer(
  ({ record }) => {
    const { onOpenInvestigationClick } = useContext(
      InvestigationSidePanelContext
    );

    const { core } = useStores();

    const isConfidentOfOtherUser = !core.hasAccessToSecGroup(record.secGroupId);

    const isShowConfidentialIcon =
      !!record.secGroupId &&
      !isConfidentOfOtherUser &&
      core.hasPermissions(Permission.ConfidentialDataAllowed);

    return (
      <Stack wrap tokens={{ childrenGap: 8 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{
            root: {
              whiteSpace: "normal",
              wordBreak: "break-word"
            }
          }}
        >
          {!isConfidentOfOtherUser ? (
            <Link
              onClick={() => {
                onOpenInvestigationClick(record);
              }}
            >
              {record.name}
            </Link>
          ) : (
            <ConfidentialByProviderText secGroupId={record.secGroupId ?? ""} />
          )}
          <ConfidentialToolTipFontIcon
            isShowConfidentialIcon={isShowConfidentialIcon}
          />
        </Stack>

        <Stack horizontal horizontalAlign="space-between">
          <Text>{record.date?.toDayDefaultFormat()}</Text>

          <RequestBadge document={record} />
        </Stack>
      </Stack>
    );
  }
);
