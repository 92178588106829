export type Rand36FormValues = {
  confidential?: boolean;
  items: Record<string, string>;
};

export enum Rand36ReponseTypes {
  PhysicalFunctioning = "Physical functioning",
  LimitationsPhysicalHealth = "Role limitations due to physical health",
  LimitationsEmotionalProblems = "Role limitations due to emotional problems",
  EnergyFatigue = "Energy/fatigue",
  EmotionalWellBeing = "Emotional well-being",
  SocialFunctioning = "Social functioning",
  Pain = "Pain",
  GeneralHealth = "General health"
}
