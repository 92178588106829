import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

interface InvoiceItemListChargeToCellProps {
  contactId?: string;
  isSubsidy: boolean;
}

export const InvoiceItemListChargeToCell: FunctionComponent<InvoiceItemListChargeToCellProps> =
  observer(props => {
    const { contactId, isSubsidy } = props;

    const textBadgeColor = isSubsidy
      ? TextBadgeColor.green
      : TextBadgeColor.blue;

    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={textBadgeColor}
          hasBorder
          styles={{ root: { minWidth: 67 } }} // width of widest badge
        >
          {isSubsidy ? "Subsidy" : "Surcharge"}
        </TextBadge>
        {contactId && (
          <ContactFetcher contactId={contactId}>
            {contact => <Text nowrap>{contact.name}</Text>}
          </ContactFetcher>
        )}
      </Stack>
    );
  });
