import React, { createContext, PropsWithChildren, useContext } from "react";

import { PatientNoticesModel } from "./PatientNoticesModel.ts";

const PatientNoticesContext = createContext<PatientNoticesModel>(
  {} as PatientNoticesModel
);

export const usePatientNoticesContext = () => {
  return useContext<PatientNoticesModel>(PatientNoticesContext);
};

export const PatientNoticesContextProvider: React.FC<
  PropsWithChildren<{
    value: PatientNoticesModel;
  }>
> = ({ value, children }) => (
  <PatientNoticesContext.Provider value={value}>
    {children}
  </PatientNoticesContext.Provider>
);
