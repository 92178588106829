import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import {
  CommandBarButton,
  DirectionalHint,
  FontSizes,
  Heading,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  IOverflowSetItemProps,
  NoDataTile,
  ResizableOverflowBar,
  Separator,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { compareDatesOrUndefinedPredicate, DateTime } from "@bps/utils";
import {
  CalendarEventReminderMessageDto,
  CalendarEventReminderReplyDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { useEventReminderScreenContext } from "../context/EventReminderScreenContext.tsx";
import { EventReminderStatesEnumText } from "../EventReminderScreen.types.ts";
import {
  EventReminderMessage,
  EventReminderMessageTypes
} from "./EventReminderMessage.tsx";
import {
  EventReminderTreeViewSize,
  getAlternativeStates,
  getBulletIconProps,
  sidePanelMenuActionItems
} from "./utils.tsx";

const EventReminderSidePanelContent: React.FC = () => {
  const { eventReminderSidePanelSize, toggleSidePanel, eventReminder } =
    useEventReminderScreenContext();

  const theme = useTheme();
  const root = useStores();
  const { booking } = root;
  const { setReminderArgs } = useBookingCalendarScreenContext();

  const eventData = booking.calendarEventRemindersMap;

  const sidePanelSize = eventReminderSidePanelSize;

  const [messages, setMessages] = useState<
    CalendarEventReminderMessageDto[] | null
  >(null);

  const [replies, setReplies] = useState<
    CalendarEventReminderReplyDto[] | null
  >(null);

  useEffect(() => {
    if (eventReminder && eventReminder.calendarEvent) {
      setMessages(null);
      setReplies(null);

      if (eventReminder.message) {
        Promise.all([
          booking.getCalendarEventReminderMessages({
            calendarEventId: eventReminder.calendarEvent.id,
            reminderId: eventReminder.reminder?.id ?? ""
          }),
          booking.getCalendarEventReminderReplies({
            calendarEventId: eventReminder.calendarEvent.id,
            reminderId: eventReminder.reminder?.id ?? ""
          })
        ]).then(([messages, replies]) => {
          const sortedMessages = messages.sort((a, b) =>
            compareDatesOrUndefinedPredicate(
              DateTime.fromISO(b.deliveryStatusUpdatedDateTime),
              DateTime.fromISO(a.deliveryStatusUpdatedDateTime)
            )
          );

          const sortedReplies = replies.sort((a, b) =>
            compareDatesOrUndefinedPredicate(
              DateTime.fromISO(b.replyDateTime),
              DateTime.fromISO(a.replyDateTime)
            )
          );

          setMessages(sortedMessages);
          setReplies(sortedReplies);
        });
      }
    }
  }, [
    eventData,
    booking,
    eventReminder,
    booking.ui.recentlyCreatedReminderReplyId
  ]);

  const baseIconProps: IIconProps = {
    styles: ({ theme }) => ({
      root: {
        color: theme!.palette.neutralPrimary,
        selectors: {
          "&:hover": {
            color: theme!.palette.neutralPrimary
          }
        }
      }
    })
  };

  const icons = [{ key: "chat", iconName: "Chat" }];

  const sidePanelLinks: IOverflowSetItemProps[] = [
    {
      iconProps: {
        ...baseIconProps,
        iconName: icons.find(y => y.key === "chat")?.iconName
      },
      name: "Chat",
      key: "chat"
    } as IOverflowSetItemProps
  ];

  const menuProps: IContextualMenuProps = {
    items: eventReminder
      ? sidePanelMenuActionItems(eventReminder, root, setReminderArgs)
      : []
  };

  const renderPanelContent = () => {
    return (
      <Stack grow>
        <Stack horizontal horizontalAlign="space-between">
          <Heading
            variant="section-heading"
            styles={{ root: { padding: "5px 0", fontSize: FontSizes.size14 } }}
          >
            SMS
          </Heading>
          {eventData && (
            <Stack
              horizontal
              horizontalAlign="center"
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              {eventReminder?.reminder &&
                eventReminder.attendanceStatus &&
                root.core.hasPermissions(
                  Permission.SendApptRemindersAllowed
                ) && (
                  <CommandBarButton
                    iconProps={getBulletIconProps(
                      eventReminder.attendanceStatus,
                      theme
                    )}
                    disabled={eventReminder.disabled}
                    text={
                      EventReminderStatesEnumText[
                        eventReminder.attendanceStatus
                      ]
                    }
                    menuProps={{
                      items: getAlternativeStates(eventReminder, root, theme)
                    }}
                    styles={{
                      root: { height: 36 },
                      icon: { marginRight: 0 },
                      label: { marginLeft: 0 }
                    }}
                  />
                )}
              {menuProps.items && menuProps.items.length > 0 && (
                <IconButton
                  iconProps={{ iconName: "More" }}
                  disabled={eventReminder?.disabled}
                  onRenderMenuIcon={() => null}
                  menuProps={menuProps}
                  styles={{
                    root: { width: "32px", height: "36px", padding: 0 },
                    flexContainer: { width: "32px", height: "36px" },
                    rootDisabled: { backgroundColor: "transparent" }
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>
        {eventData && (
          <>
            {replies || messages ? (
              <Stack
                verticalAlign="start"
                styles={{
                  root: {
                    width: 255
                  }
                }}
              >
                {replies && replies.length > 0 ? (
                  replies.map(reply => (
                    <EventReminderMessage
                      key={reply.id}
                      replyAction={reply.replyAction}
                      type={EventReminderMessageTypes.Reply}
                      dateTime={reply.replyDateTime!}
                      message={reply.content!}
                    />
                  ))
                ) : (
                  <Stack>
                    <Separator />
                    <Text
                      styles={{
                        root: {
                          padding: "4px 0px",
                          color: theme.palette.neutralSecondaryAlt,
                          fontStyle: "italic"
                        }
                      }}
                    >
                      Waiting for reply
                    </Text>
                  </Stack>
                )}
                {messages &&
                  messages.map(message => (
                    <EventReminderMessage
                      key={message.id}
                      type={EventReminderMessageTypes.Sent}
                      dateTime={message.deliveryStatusUpdatedDateTime!}
                      message={message.content!}
                    />
                  ))}
              </Stack>
            ) : (
              <Stack grow verticalAlign="center">
                <NoDataTile
                  showBoxShadow={false}
                  textProps={{
                    text: "Patient wasn't sent an appointment reminder"
                  }}
                  linkProps={{
                    text: "",
                    hidden: true
                  }}
                />
              </Stack>
            )}
          </>
        )}
        {!eventData && (
          <Stack grow verticalAlign="center">
            <NoDataTile
              showBoxShadow={false}
              textProps={{
                text: "Select a record to load SMS history"
              }}
              linkProps={{
                text: "",
                hidden: true
              }}
            />
          </Stack>
        )}
      </Stack>
    );
  };

  const onRenderOverflowButton = (
    overflowItems: any[] | undefined
  ): JSX.Element => {
    return (
      <CommandBarButton
        role="menuitem"
        title="More items"
        menuIconProps={{ iconName: "More" }}
        menuProps={{
          items: overflowItems!
        }}
      />
    );
  };

  const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    return (
      <TooltipHost
        content={item.name}
        directionalHint={DirectionalHint.leftCenter}
      >
        <CommandBarButton
          {...item}
          styles={{
            root: {
              height: 44,
              padding: "0 7px",
              backgroundColor: theme.palette.neutralLight
            }
          }}
          onClick={item.onClick}
        />
      </TooltipHost>
    );
  };

  return (
    <Stack verticalFill horizontal>
      <Stack
        styles={{
          root: {
            backgroundColor: theme.palette.themeLighterAlt
          }
        }}
      >
        <ResizableOverflowBar
          itemSize={45}
          itemsGap={0}
          items={sidePanelLinks}
          onRenderOverflow={onRenderOverflowButton}
          onRenderItem={onRenderItem}
          vertical
        />
        <IconButton
          styles={{
            root: {
              width: "100%",
              borderRadius: 0
            }
          }}
          iconProps={{
            iconName: "DoubleChevronRight12",
            styles: props => ({
              root: {
                fontSize: props.theme!.fonts.small.fontSize
              }
            })
          }}
          onClick={() => toggleSidePanel(false)}
          title="Collapse"
          disabled={sidePanelSize === EventReminderTreeViewSize.IconsOnly}
        />
        <IconButton
          styles={{
            root: {
              width: "100%",
              borderRadius: 0
            }
          }}
          iconProps={{
            iconName: "DoubleChevronLeft12",
            styles: props => ({
              root: {
                fontSize: props.theme!.fonts.small.fontSize
              }
            })
          }}
          onClick={() => toggleSidePanel(true)}
          title="Expand"
          disabled={sidePanelSize === EventReminderTreeViewSize.Expanded}
        />
      </Stack>

      {sidePanelSize !== EventReminderTreeViewSize.IconsOnly && (
        <Stack
          styles={{
            root: { overflow: "hidden", width: "100%", padding: 16 }
          }}
        >
          <Stack tokens={{ childrenGap: 8 }} grow verticalFill>
            {renderPanelContent()}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export const EventReminderSidePanel = observer(EventReminderSidePanelContent);
