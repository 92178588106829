import { FunctionComponent } from "react";

import { Heading, IPersonaProps, mergeStyles } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { PatientPickerField } from "@ui-components/pickers/contact-picker/PatientPickerField.tsx";

import { MoveCorrespondenceFormValidator } from "./clinical-form/MoveCorrespondenceFormValidator.ts";
import { MoveCorrespondenceFormValues } from "./clinical-form/MoveCorrespondenceFormValues.ts";
import { CorrespondencesLabels } from "./correspondence/Correspondences.types.ts";
import { handleConflictError } from "./correspondence/utils.tsx";

interface MoveDocumentDialogProps {
  hidden: boolean;
  onDismiss: () => void;
  onSubmitSucceeded?: (values: MoveCorrespondenceFormValues) => void;
  handleOnConflictError: (confirmed: boolean) => void;
  clinicalRecord: ClinicalRecord;
  document: ClinicalDocument;
}

const moveNameof = nameOfFactory<MoveCorrespondenceFormValues>();
const moveValidator = new MoveCorrespondenceFormValidator();
const fieldStyles = mergeStyles({
  width: "100%"
});

export const MoveDocumentDialog: FunctionComponent<
  MoveDocumentDialogProps
> = props => {
  const {
    hidden,
    onDismiss,
    onSubmitSucceeded,
    handleOnConflictError,
    clinicalRecord,
    document
  } = props;

  const { correspondence, notification } = useStores();

  if (hidden) return null;

  const renderMoveForm = () => {
    return (
      <Fieldset>
        <PatientPickerField
          name={moveNameof("destinationPatientId")}
          label={CorrespondencesLabels.MovePatientLabel}
          getTextFromItem={getTextFromItem}
          iconName="Search"
          placeholder={CorrespondencesLabels.MovePatientPlaceholder}
          className={fieldStyles}
        />
      </Fieldset>
    );
  };

  const submitMoveDocument = async (values: MoveCorrespondenceFormValues) => {
    try {
      await correspondence.moveDocument(
        clinicalRecord.openEncounter!.id,
        values.sourceFileId,
        {
          sourcePatientId: values.sourcePatientId,
          eTag: document?.eTag!,
          destinationPatientId: values.destinationPatientId
        }
      );
    } catch (error) {
      const isConflict = await handleConflictError(error, confirmed => {
        handleOnConflictError(confirmed);
        if (!confirmed) throw new Error(error.message);
      });
      if (!isConflict) {
        notification.error(
          "An error occurred while moving the investigation report."
        );
        throw new Error(error.message);
      }
    }
  };

  const getTextFromItem = (persona: IPersonaProps) => persona.text as string;

  const fullname = clinicalRecord.patient?.nameWithTitle ?? "";

  const initialMoveValues: RecursivePartial<MoveCorrespondenceFormValues> = {
    sourcePatientId: clinicalRecord.id,
    sourceFileId: document?.id
  };

  return (
    <SubmissionFormDialog
      onSubmit={submitMoveDocument}
      onSubmitSucceeded={values => {
        if (onSubmitSucceeded) onSubmitSucceeded(values);
        onDismiss();
      }}
      validate={(values: MoveCorrespondenceFormValues) =>
        moveValidator.validate(values)
      }
      render={renderMoveForm}
      buttonsProps={{
        submitButtonProps: {
          iconProps: {}
        },
        hideButtonsSeparator: true
      }}
      initialValues={initialMoveValues}
      styles={{
        fields: { padding: 10 },
        subComponentStyles: {
          submitErrorMessage: {
            root: { marginLeft: 8, marginRight: 8 }
          }
        }
      }}
      dialogName="Move document dialog"
      dialogProps={{
        onDismiss,
        minWidth: 600,
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">{`Move file from ${fullname}`}</Heading>
          ),
          showCloseButton: true
        }
      }}
    />
  );
};
