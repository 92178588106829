import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";

import { InvoiceDetailsRow } from "./invoice-details-row/InvoiceDetailsRow.tsx";
import { InvoiceViewAdjustedItemsList } from "./InvoiceViewAdjustedItemsList.tsx";
import { InvoiceViewInfoBar } from "./InvoiceViewInfoBar.tsx";
import { InvoiceViewItemsList } from "./InvoiceViewItemsList.tsx";
import { getInvoiceFormValuesFromInvoice } from "./utils.ts";

interface InvoiceViewProps {
  hideInfoBar?: boolean;
}

export const InvoiceView: React.FC<InvoiceViewProps> = observer(
  ({ hideInfoBar }) => {
    const { invoice } = useContext(InvoiceViewContext);
    //Services and gstPercent are deliberately empty / zero as these shouldn't
    // need to be loaded to view an invoice.
    const formValues = getInvoiceFormValuesFromInvoice({
      invoice,
      gstPercent: 0,
      isAdjust: true
    });

    return (
      <Stack tokens={{ childrenGap: 16 }}>
        {!hideInfoBar && <InvoiceViewInfoBar />}
        <InvoiceDetailsRow invoice={formValues} />
        <InvoiceViewAdjustedItemsList />
        <InvoiceViewItemsList />
      </Stack>
    );
  }
);
