import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { UserScheduleConfigured } from "@libs/utils/calendar/calendar.constants.ts";
import { isScheduleConfigured } from "@libs/utils/calendar/calendar.utils.ts";
import { UsersLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { NestedPageLink } from "@ui-components/PageLink/NestedPageLink.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UserOnApptBook } from "./UserOnApptBook.tsx";
import {
  getExceptionsLinkMessage,
  getWorkingHoursLinkMessage
} from "./utils.ts";

type ShowUserOnCalendarFormProps = {
  user: User;
  userAvailabilityModel: UserAvailabilityModel;
  bhbProvider: BhbProvider | undefined;
};

export const ShowUserOnCalendarForm: FC<ShowUserOnCalendarFormProps> = observer(
  ({ user, userAvailabilityModel, bhbProvider }) => {
    const { routing } = useStores();
    const disabled = !user.userSetting?.showOnCalendar;
    const scheduleConfigured = isScheduleConfigured(
      userAvailabilityModel.schedules,
      DateTime.now()
    );

    const noWorkingHours =
      scheduleConfigured !== UserScheduleConfigured.Configured;

    return (
      <Stack
        styles={{
          root: {
            margin: "32px 64px"
          }
        }}
        tokens={{ childrenGap: 16 }}
      >
        <When permission={Permission.CalendarEventRead}>
          <UserOnApptBook user={user} bhbProvider={bhbProvider} />
        </When>
        <NestedPageLink
          isWarningType={noWorkingHours}
          disabled={disabled}
          messageBarText={getWorkingHoursLinkMessage(
            scheduleConfigured,
            !!user.userSetting?.showOnCalendar
          )}
          onClick={() =>
            routing.push(
              routes.settings.users.userWorkingHours.path({
                id: user.id
              })
            )
          }
          headingText={UsersLabels.workingHours}
          iconName="calendar"
        />
        <NestedPageLink
          isWarningType={noWorkingHours}
          disabled={disabled || noWorkingHours}
          messageBarText={getExceptionsLinkMessage(
            scheduleConfigured,
            !!user.userSetting?.showOnCalendar
          )}
          onClick={() =>
            routing.push(
              routes.settings.users.userWorkingHoursOverrides.path({
                id: user.id
              })
            )
          }
          headingText={UsersLabels.exceptions}
          iconName="AwayStatus"
        />
        <NestedPageLink
          disabled={disabled}
          onClick={() =>
            routing.push(
              routes.settings.users.reserves.path({
                id: user.id
              })
            )
          }
          headingText={UsersLabels.reserves}
          iconName="DateTime"
        />
      </Stack>
    );
  }
);
