import { FunctionComponent, useRef, useState } from "react";

import { DefaultButton, Heading } from "@bps/fluent-ui";
import {
  ReactionCertainty,
  ReactionClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { defaultButtonsText } from "@ui-components/form/prompt/Prompt.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { DeleteReactionDialog } from "./DeleteReactionDialog.tsx";
import { ReactionFormTypes } from "./ReactionForm.types.ts";
import { ReactionFormFields } from "./ReactionFormFields.tsx";
import { ReactionFormModel } from "./ReactionFormModel.ts";
import { ReactionFormValidator } from "./ReactionFormValidator.ts";
import {
  getNaturesOfReaction,
  getReactionAgent,
  removeReactionFromClinicalData,
  toReactionAgentType
} from "./utils.ts";

export interface ReactionFormComponentProps {
  clinicalRecord: ClinicalRecord;
  onCancel: () => void;
  onSubmitted: (dismiss?: boolean) => void;
  reaction?: ReactionClinicalDataItemDto;
}

const ReactionFormComponent: FunctionComponent<ReactionFormComponentProps> = ({
  clinicalRecord,
  onCancel,
  onSubmitted,
  reaction
}) => {
  const { drugs } = useStores();
  const saveNew = useRef<boolean>(false);

  const model = new ReactionFormModel(clinicalRecord, drugs);

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] =
    useState<boolean>(false);

  const onDeleteCancel = () => {
    setIsDeleteDialogVisible(false);
  };

  const onSubmitSucceeded = () => {
    onSubmitted(!saveNew.current);
    saveNew.current = false;
  };

  const onDeleteConfirm = async (
    reasonForDelete?: string,
    reasonForDeleteComment?: string
  ) => {
    const { reactions, agents } = removeReactionFromClinicalData({
      reaction: clinicalRecord.clinicalData?.reaction,
      reactionAgentId: reaction?.agent.code,
      natureOfReaction: undefined,
      reasonForDelete,
      reasonForDeleteComment
    });

    await clinicalRecord.saveClinicalData({
      reaction: {
        eTag: clinicalRecord.clinicalData?.reaction?.eTag,
        agents,
        reactions,
        // remove explicit nil known when backend infers it
        nilKnown: false
      }
    });

    if (onCancel) {
      onCancel();
    }
  };

  const onDelete = async (values: ReactionFormTypes) => {
    const { naturesOfReaction } = values;

    if (
      naturesOfReaction &&
      naturesOfReaction.filter(
        z =>
          !z.id ||
          z.createLog?.createdEncounterId !== clinicalRecord.openEncounter?.id
      ).length
    ) {
      // if the above tests are true, we have some reactions that were recorded in earlier encounters.
      // So we need to show the dialog.
      setIsDeleteDialogVisible(true);
    } else {
      await onDeleteConfirm();
    }
  };

  const existingReactions = clinicalRecord.clinicalData?.reaction;
  const relatedAgent = reaction
    ? getReactionAgent(reaction.agent.code, existingReactions?.agents)
    : undefined;

  const validator = useRef(
    new ReactionFormValidator({
      existingReactions,
      isAdd: !reaction
    })
  );

  const initialValues: ReactionFormTypes = reaction
    ? {
        reaction: `${reaction.agent.code}.${toReactionAgentType(
          reaction.type
        )}`,
        naturesOfReaction: getNaturesOfReaction(
          reaction.agent.code,
          existingReactions?.reactions,
          reaction.otherText
        ),
        comment: relatedAgent?.comment,
        deletedReactions: {},
        certainty: reaction.certainty,
        otherText: reaction?.otherText
      }
    : {
        naturesOfReaction: [{}],
        deletedReactions: {},
        certainty: ReactionCertainty.Confirmed
      };

  const dialogTitle = `${!!reaction ? "Edit" : "Add"} reaction`;

  return (
    <>
      <SubmissionFormDialog<ReactionFormTypes>
        autoFocus={!reaction}
        onSubmit={model.onSubmit}
        onSubmitSucceeded={onSubmitSucceeded}
        buttonsProps={form => ({
          disableSubmitOnFormInvalid: true,
          onCancel,
          submitButtonProps: {
            items: !reaction
              ? [
                  {
                    key: "saveAndNew",
                    text: "Save & New",
                    iconProps: { iconName: "Save" },
                    onClick: () => {
                      saveNew.current = true;
                      form.submit();
                    }
                  }
                ]
              : undefined,
            text: "Save",
            iconProps: { iconName: "Save" }
          },
          extraActionsBefore: form =>
            reaction && (
              <DefaultButton
                key="deleteReaction"
                text="Delete"
                iconProps={{ iconName: "Delete" }}
                onClick={() => onDelete(form.getState().values)}
              />
            ),
          hideButtonsSeparator: true
        })}
        validate={(values: ReactionFormTypes) =>
          validator.current.validate(values)
        }
        initialValues={initialValues}
        dialogName={`${dialogTitle} dialog`}
        dialogProps={{
          onDismiss: onCancel,
          minWidth: 480,
          dialogContentProps: {
            showCloseButton: true,
            title: <Heading variant="modal-heading">{dialogTitle}</Heading>
          }
        }}
        promptDialogProps={{
          confirmButtonProps: { text: defaultButtonsText.confirm },
          cancelButtonProps: {
            text: defaultButtonsText.cancel
          }
        }}
      >
        {() => (
          <ReactionFormFields
            createLog={
              relatedAgent ? relatedAgent.createLog : reaction?.createLog
            }
            updateLog={relatedAgent?.updateLog}
          />
        )}
      </SubmissionFormDialog>
      <DeleteReactionDialog
        hidden={!isDeleteDialogVisible}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
    </>
  );
};

export const ReactionForm = withFetch(
  x => [
    x.clinical.ref.reactionSeverities.load(),
    x.clinical.ref.reactionTypes.load()
  ],
  ReactionFormComponent
);
