import debounce from "lodash.debounce";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-final-form";

import { MessageBar, MessageBarType, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { RecurringAppointmentHelperTextDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";
import { RecurrenceRuleDisplay } from "./RecurrenceRuleDisplay.tsx";
import { getRecurrenceRule } from "./utils.ts";

interface RecurringAppointmentConflictProps {
  isDetailedConflictVisible?: boolean;
  hideInitialStateRrule?: boolean;
}

export const RecurringAppointmentConflicts: React.FunctionComponent<
  RecurringAppointmentConflictProps
> = ({ isDetailedConflictVisible = true, hideInitialStateRrule = false }) => {
  const { booking, core } = useStores();
  const { calendarEventRecurrence } = useContext(AppointmentFormContext);

  const [helperTextInfo, setHelperTextInfo] = useState<
    RecurringAppointmentHelperTextDto | undefined
  >();

  const [isValidRRule, setIsValidRRule] = useState<boolean>();
  const [noticeDates, setNoticeDates] = useState<{
    lastOccurrenceDate: string | undefined;
    nextOccurrenceDate: string | undefined;
  }>();

  const getAppointmentHelperText = async (values: AppointmentFormValues) => {
    if (
      !values.repeat ||
      !values.startDate ||
      !values.startTime ||
      !values.duration ||
      !values.providerId
    ) {
      setIsValidRRule(false);
      return undefined;
    }

    const startTime = DateTime.fromJSDateAndTime(
      values.startDate ?? DateTime.jsDateNow(),
      values.startTime
    );

    const duration =
      typeof values.duration === "string"
        ? Number(values.duration)
        : values.duration;

    const endTime = startTime.plus({ minutes: duration });

    const rRuleProps = getRecurrenceRule(values);
    if (!rRuleProps || !startTime || !endTime || !values.providerId) {
      setIsValidRRule(false);
      return undefined;
    }
    rRuleProps.count =
      values.endScheduleType === EndScheduleType.Never
        ? undefined
        : rRuleProps.count;
    setIsValidRRule(true);

    return values?.providerId
      ? await booking.getRecurringAppointmentHelperText(values.providerId, {
          orgUnitId: core.locationId,
          startTime: startTime.toISO(),
          endTime: endTime.toISO(),
          interval: rRuleProps.interval,
          frequency: rRuleProps.frequency,
          count: rRuleProps.count,
          until: rRuleProps.until
            ? rRuleProps.until
                .plus({ days: 1 }) //to cover 24 hrs
                .plus({ minutes: rRuleProps.until._dateTime.offset }) // to cover utc time difference
                .toISO()
            : undefined,
          dayRecur: rRuleProps.dayRecur,
          monthRecur: rRuleProps.monthRecur,
          monthDayRecur: rRuleProps.monthDayRecur,
          weekPosition: rRuleProps.weekPosition,
          seriesId: calendarEventRecurrence?.seriesId
        })
      : undefined;
  };

  const conflictsText = () => {
    let text = "";
    helperTextInfo?.recurringAppointmentConflicts?.forEach(conflict => {
      const dateFormatted = DateTime.fromISO(
        conflict.conflictDateTime
      ).toDayDefaultFormat();
      text += `${dateFormatted}, `;
    });

    return text.substring(0, text.length - 2);
  };

  const getRecurrenceDates = async (
    helperText: RecurringAppointmentHelperTextDto | undefined,
    occurrenceDate: Date | undefined
  ) => {
    let lastAppointmentDate = helperText?.lastAppointmentDate;
    let nextAppointmentDate = helperText?.nextAppointmentDate;
    if (calendarEventRecurrence && calendarEventRecurrence.seriesId) {
      await booking.getRecurrenceEvents(calendarEventRecurrence.seriesId, true);

      const recurrenceSeries = Array.from(
        booking.calendarEventsMap.values()
      ).filter(
        x => x.calendarEventRecurrenceId === calendarEventRecurrence.seriesId
      );

      if (recurrenceSeries && recurrenceSeries.length > 0) {
        const sortedRecurrences = recurrenceSeries.sort(
          (x, y) => x.startDateTime.valueOf() - y.startDateTime.valueOf()
        );
        lastAppointmentDate =
          sortedRecurrences![recurrenceSeries.length - 1].startDateTime.toISO();

        const futureAppointments = sortedRecurrences!.filter(
          x => x.startDateTime.startOf("day").toJSDate() > occurrenceDate!
        );

        nextAppointmentDate =
          futureAppointments.length > 0
            ? futureAppointments[0].startDateTime.toISO()
            : undefined;
      }
    }

    setNoticeDates({
      lastOccurrenceDate: lastAppointmentDate,
      nextOccurrenceDate: nextAppointmentDate
    });
    setHelperTextInfo(helperText);
  };

  //debounce call to check recurring appointment conflicts
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setAppointmentConflictsDebounced = useCallback(
    debounce(async (values: AppointmentFormValues) => {
      if (values.repeat) {
        const text = await getAppointmentHelperText(values);
        await getRecurrenceDates(text, values.startDate);
      }
    }, 500),
    [helperTextInfo]
  );

  const form = useForm<AppointmentFormValues>();
  useEffect(() => {
    setAppointmentConflictsDebounced(form.getState().values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fieldset styles={{ root: { marginTop: 8 } }}>
      <FieldCondition when={appointmentFormNameOf("repeat")} is={true}>
        {!booking.ui.isEditSingleEvent &&
          helperTextInfo?.recurringAppointmentConflicts &&
          helperTextInfo?.recurringAppointmentConflicts?.length > 0 && (
            <Stack>
              <MessageBar
                messageBarType={MessageBarType.warning}
                styles={{
                  root: {
                    maxHeight: 450,
                    overflowY: "auto"
                  }
                }}
              >
                {isDetailedConflictVisible &&
                  helperTextInfo?.recurringAppointmentConflicts.length ===
                    1 && (
                    <Text>{`There is a conflict on the ${DateTime.fromISO(
                      helperTextInfo.recurringAppointmentConflicts[0]
                        .conflictDateTime
                    ).toDayDefaultFormat()}`}</Text>
                  )}

                {isDetailedConflictVisible &&
                  helperTextInfo?.recurringAppointmentConflicts.length !==
                    1 && (
                    <Stack.Item>
                      <Text>There are conflicts on multiple dates:</Text>
                      <Stack.Item>
                        <Text>{conflictsText()}</Text>
                      </Stack.Item>
                    </Stack.Item>
                  )}

                {!isDetailedConflictVisible && (
                  <Text>There are conflicts in this series</Text>
                )}
              </MessageBar>
            </Stack>
          )}

        <FieldSpy
          name={appointmentFormNameOf("duration")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("providerId")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("startDate")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("startTime")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("repeat")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("interval")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("dayRecur")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("monthDayRecur")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("endScheduleType")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("frequency")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("until")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />
        <FieldSpy
          name={appointmentFormNameOf("count")}
          onChange={(value: any, values: AppointmentFormValues) =>
            setAppointmentConflictsDebounced(values)
          }
        />

        <RecurrenceRuleDisplay
          hideInitialState={hideInitialStateRrule}
          recurrenceDates={{
            lastAppointmentDate: noticeDates?.lastOccurrenceDate,
            nextAppointmentDate: noticeDates?.nextOccurrenceDate
          }}
          isValidRRule={isValidRRule}
        />
      </FieldCondition>
    </Fieldset>
  );
};
