import { action } from "mobx";

import { IGroup } from "@bps/fluent-ui";
import { DateTime, groupBy } from "@bps/utils";
import {
  CodedText,
  MeasurementDto,
  MeasurementType,
  PSFSContextClinicalDataItemDto,
  PSFSMultipleQuestionnaireResponseDto,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nullifyAnyUndefined } from "@libs/utils/utils.ts";
import { getSideOfBodyText } from "@modules/clinical/utils/clinical.utils.ts";
import { PatientClinicalRecordTab } from "@stores/clinical/models/clinical-tab/PatientClinicalRecordTab.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";

import { generateDashMeasurements } from "../clinical-tools.utils.ts";

export class ClinicalToolsListModel {
  private _clinicalItemHeaders: ClinicalItemHeader[] = [];
  private contexts: PSFSContextClinicalDataItemDto[] | undefined;

  constructor(
    private clinicalRecord: ClinicalRecord,
    private codedTexts?: CodedText[]
  ) {
    this.clinicalRecord = clinicalRecord;
    this.codedTexts = codedTexts;
    this.contexts = this.clinicalRecord.clinicalData?.psfsContext?.contexts;
  }

  headerDate = (key: string | undefined): string => {
    if (key) {
      const header = this._clinicalItemHeaders.find(h => h.key === key);
      if (header) return header.latestDate;
    }
    return "";
  };

  getHeaderText = (code: string) => {
    if (MeasurementType[code] === MeasurementType.DASH) {
      return "DASH 11";
    }

    if (MeasurementType[code] === MeasurementType.RAND36) {
      return "RAND 36";
    }

    if (MeasurementType[code] === MeasurementType.DASS21) {
      return "DASS 21";
    }

    return MeasurementType[code];
  };

  generateGroups = (
    currentPatientRecordTab: PatientClinicalRecordTab,
    measurements: Measurement[]
  ) => {
    let startIndex = 0;
    const groups: IGroup[] = [];
    const measurementCode = Object.keys(MeasurementType);
    const orderedMeasurements: Measurement[] = [];
    const psfsContexts =
      this.clinicalRecord.clinicalData?.psfsContext?.contexts;

    const modifiedMeasurements = generateDashMeasurements(measurements);

    for (const code of measurementCode) {
      const items = modifiedMeasurements?.filter(x => x.type === code) || [];
      const itemDates = items.map(i =>
        DateTime.fromISOOrNow(i.changeLog?.createdDate)
      );

      const latestDate =
        itemDates.length > 0
          ? itemDates.reduce((p, v) => (p > v ? p : v))
          : undefined;

      // if the items have contextIds it means there is an additional
      // layer of subgrouping we can do
      const subGroups: IGroup[] = [];
      let subGroupStartIndex = startIndex;
      if (
        code !== MeasurementType.DASH &&
        code !== MeasurementType.RAND36 &&
        code !== MeasurementType.DASS21
      ) {
        const contextGroups = groupBy(items, item => item.contextId);

        // for each contextGroup we need to make a new IGroup object with
        // all the items related to that context
        contextGroups.forEach(group => {
          const [contextId, groupItems] = group;
          if (contextId && groupItems.length > 0) {
            const context = psfsContexts?.find(c => c.contextId === contextId);
            const side =
              context?.side! === SideOfBody.Neither
                ? ""
                : `, ${getSideOfBodyText(context?.side!)}`;

            const terminology = this.codedTexts?.find(
              t => t.code === context?.diagnosis.code
            );

            const termname = terminology ? terminology.text : "";
            const subGroupName = `${termname}${side}`;

            // add this new context group to the list of subgroups
            subGroups.push({
              key: contextId,
              name: subGroupName,
              count: groupItems.length,
              isCollapsed:
                currentPatientRecordTab.state.collapseClinicalTools.hasOwnProperty(
                  subGroupName
                )
                  ? !currentPatientRecordTab.state.collapseClinicalTools[
                      subGroupName
                    ]
                  : true,
              startIndex: subGroupStartIndex,
              level: 2,
              data: {
                type: groupItems[0].type,
                measurementId: groupItems[0].id,
                secGroupId: groupItems[0].secGroupId
              } // add measurement's props as extra data to reuse elsewhere
            });
            orderedMeasurements.push(...groupItems);
            subGroupStartIndex += groupItems.length;
          }
        });
      }

      const subGroupOperation = subGroupStartIndex > startIndex;

      this.addClinicalItemHeader(code, latestDate);

      // Creates a new IGroup
      // With all the context subgroups as children of this group

      if (items.length > 0) {
        groups.push({
          key: code,
          name: this.getHeaderText(code),
          count: items.length,
          isCollapsed:
            currentPatientRecordTab.state.collapseClinicalTools.hasOwnProperty(
              code
            )
              ? !currentPatientRecordTab.state.collapseClinicalTools[code]
              : true,
          startIndex,
          children: subGroups,
          level: 1
        });
      }

      if (!subGroupOperation) {
        orderedMeasurements.push(...items);
      }

      startIndex += items.length;
    }
    return {
      groups,
      items: orderedMeasurements
    };
  };

  private addClinicalItemHeader(code: string, latestDate?: DateTime) {
    this._clinicalItemHeaders.push({
      key: code,
      latestDate: latestDate?.toDayDefaultFormat() || ""
    });
  }

  checkIsSeriesClosed = (contextId: string) => {
    let contextItemsDisabled = false;

    if (this.contexts) {
      const index = this.contexts.findIndex(c => c.contextId === contextId);

      if (index !== -1) {
        const existingContext = this.contexts[index];
        contextItemsDisabled = existingContext.isClosed;
      }
    }

    return contextItemsDisabled;
  };

  @action
  closePSFSSeries = async (
    contextId: string,
    reason: string,
    comment?: string | undefined
  ) => {
    const contextData = this.clinicalRecord.clinicalData?.psfsContext;

    if (contextData) {
      const existingContexts = contextData.contexts;

      if (existingContexts) {
        const index = existingContexts.findIndex(
          c => c.contextId === contextId
        );

        if (index !== -1) {
          const existingContext = existingContexts[index];
          existingContext.isClosed = true;
          existingContext.reasonForClose = reason;
          existingContext.closedComment = comment;
          existingContexts[index] = existingContext;
        }

        contextData.contexts = existingContexts;

        await this.clinicalRecord.saveClinicalData({
          psfsContext: contextData
        });
      }
    }
  };

  private assignSecGroupIdToPSFSresponses = (
    measurement: MeasurementDto,
    psfsResponses: PSFSMultipleQuestionnaireResponseDto
  ) => {
    const responses = psfsResponses.responses;
    const userSecGroupId = this.clinicalRecord.core.user?.privateSecGroupId;

    return responses.map(r => {
      if (r.contextId === measurement.contextId) {
        return { ...r, secGroupId: r.secGroupId ? undefined : userSecGroupId };
      } else {
        return r;
      }
    });
  };

  adjustMeasurementConfidentiality = async (measurement: MeasurementDto) => {
    const clinicalRecord = this.clinicalRecord;
    const core = clinicalRecord.core;
    const userSecGroupId = core.user?.privateSecGroupId;
    const clinical = clinicalRecord.clinical;

    if (core.hasAccessToSecGroup(measurement.secGroupId)) {
      const chosenEncounter = await clinical.getEncounter(
        measurement.encounterId
      );

      const currentResponses =
        clinicalRecord.clinicalData?.[measurement.type.toLowerCase()];

      //update through clinicalData for currently opened encounter
      if (chosenEncounter === clinicalRecord.openEncounter) {
        let updatedResponse: any;
        //handle PSFS's extra layer data structure
        if (measurement.type === MeasurementType.PSFS) {
          const responses = this.assignSecGroupIdToPSFSresponses(
            measurement,
            currentResponses
          );

          updatedResponse = {
            ...currentResponses,
            responses
          };
        } else {
          updatedResponse = {
            ...currentResponses,
            secGroupId:
              currentResponses && currentResponses.secGroupId
                ? undefined
                : userSecGroupId
          };
        }

        const payload = { [measurement.type.toLowerCase()]: updatedResponse };
        await clinicalRecord.saveClinicalData(payload);
        clinicalRecord.stashedClinicalData?.updateFromPatch(
          nullifyAnyUndefined(payload)
        );

        //check for update then update cache
        const updatedMeasurement = await clinical.getMeasurement(
          measurement.id
        );

        if (updatedMeasurement) {
          clinicalRecord.updateMeasurements([updatedMeasurement]);
        }
      } else {
        //update directly to measurement for closed/other user's encounter
        const updatedMeasurement = {
          ...measurement,
          secGroupId: !!measurement.secGroupId ? undefined : userSecGroupId
        };

        const dto = await clinical.updateMeasurement(updatedMeasurement);
        //update cache
        if (dto) {
          clinicalRecord.updateMeasurements([dto]);
        }
      }
    }
  };
}

export interface ClinicalItemHeader {
  key: string;
  latestDate: string;
}
