import { PaymentMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { PaymentMethodText } from "@ui-components/RefText.tsx";

import { TotalsDetails } from "./TotalsDetails.tsx";

interface PaymentMethodProps {
  heading: string;
  methods: {
    id?: string;
    paymentMethod: PaymentMethod;
    amount: number;
  }[];
  fontColor?: string;
}

export const PaymentMethodDetails: React.FC<PaymentMethodProps> = ({
  heading,
  methods,
  fontColor
}) => (
  <TotalsDetails
    heading={heading}
    fontColor={fontColor}
    rowOptions={methods.map(x => ({
      ...x,
      text: <PaymentMethodText code={x.paymentMethod} />
    }))}
  />
);
