import { Stack } from "@bps/fluent-ui";

import { BhbProvidersCard } from "../providers/BhbProvidersCard.tsx";
import { BhbOnlineSettingsCard } from "./BhbOnlineSettingsCard.tsx";

const BhbOnlineSettingsScreen: React.FC = () => {
  return (
    <Stack tokens={{ childrenGap: 24 }}>
      <BhbOnlineSettingsCard />
      <BhbProvidersCard />
    </Stack>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default BhbOnlineSettingsScreen;
