import {
  ContactType,
  OrganisationContactDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { filterInsurers } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import {
  ContactPickerField,
  ContactPickerFieldProps
} from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";

export type InsurerPickerFieldProps = ContactPickerFieldProps;

/**
 * Use InsurerPickerField when requiring a InsurerPicker within react final form.
 * @param props
 */
export const InsurerPickerField = (props: InsurerPickerFieldProps) => (
  <ContactPickerField
    {...props}
    filter={{
      types: [ContactType.Organisation]
    }}
    resultFilterPredicate={contact => {
      const organisation = contact.value as OrganisationContactDto;
      return filterInsurers(organisation, false);
    }}
  />
);

export const AccInsurerPickerField = (props: InsurerPickerFieldProps) => (
  <ContactPickerField
    {...props}
    filter={{
      types: [ContactType.Organisation]
    }}
    resultFilterPredicate={contact => {
      const organisation = contact.value as OrganisationContactDto;
      return filterInsurers(organisation, true);
    }}
  />
);
