import { NoDataTile, Stack, useTheme } from "@bps/fluent-ui";
import { ClaimAdjustmentDialogForm } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentDialogForm.tsx";
import { ClaimAdjustmentFormLabels } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentEnums.ts";
import { ClaimAdjustmentList } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentList.tsx";
import { ClaimAdjustmentResponseDialogForm } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentResponseDialogForm.tsx";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentHelper } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.ts";
import { ModalKeys } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.types.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ClaimAdjustmentProps {
  claim: Claim;
}

export const ClaimAdjustment: React.FC<ClaimAdjustmentProps> = ({ claim }) => {
  const theme = useTheme();
  const root = useStores();

  const { setClaimAdjustment } = usePatientRecordScreenContext();

  const claimAdjustmentHelper = new ClaimAdjustmentHelper(root, claim);
  return (
    <Stack>
      <ClaimAdjustmentContext.Provider value={claimAdjustmentHelper}>
        {claim.claimAdjustments.length > 0 ? (
          <>
            <ClaimAdjustmentList
              onClaimNumberClicked={id => {
                const claimAdjustment = claim.claimAdjustments.find(
                  x => x.id === id
                );
                setClaimAdjustment(claimAdjustment);
              }}
              claimAdjustments={claim.claimAdjustments}
            />
            <ClaimAdjustmentResponseDialogForm />
          </>
        ) : (
          <>
            <NoDataTile
              styles={{
                root: { backgroundColor: theme.palette.neutralLighterAlt }
              }}
              textProps={{
                text: "No ACC32s for this claim"
              }}
              showBoxShadow={false}
              linkProps={{
                text: ClaimAdjustmentFormLabels.addACC32,
                onClick: () => {
                  claimAdjustmentHelper.setOpenModal(ModalKeys.AdjustmentModal);
                }
              }}
            />
            <ClaimAdjustmentDialogForm claim={claim} />
          </>
        )}
      </ClaimAdjustmentContext.Provider>
    </Stack>
  );
};
