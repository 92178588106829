import { observer } from "mobx-react-lite";

import {
  dataAttribute,
  DataAttributes,
  PersonaSize,
  PresetButtonType
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

import {
  pastVisitsFilterNameOf,
  PastVisitsFilterProps,
  PastVisitsFilterStateInternal
} from "./PastVisitsFilter.types.ts";
import { PastVisitsFilterValidator } from "./PastVisitsFilterValidator.ts";

interface Option {
  key: string;
  text: string;
}

interface ProviderOption extends Option {
  initials: string;
}

const validator = new PastVisitsFilterValidator();

export const PastVisitsFilter: React.FunctionComponent<PastVisitsFilterProps> =
  observer(
    ({
      setPastVisitsFilter,
      providers,
      initialFilterValues,
      clinicalRecord
    }) => {
      const { clinical, core } = useStores();
      const sidePanelSize =
        clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize;

      const mapProviders = (
        providers: User[] | undefined
      ): ProviderOption[] => {
        if (providers) {
          return providers.map(u => ({
            key: u.id,
            text: u.name,
            initials: u.initials
          }));
        }

        return [];
      };

      const items: Item<PastVisitsFilterStateInternal>[] = [
        {
          name: pastVisitsFilterNameOf("searchReason"),
          type: "searchBox",
          stickItem: true,
          props: {
            placeholder: "Search reason",
            styles: { root: { maxWidth: "180px" } }
          }
        },
        {
          type: "datesRangePicker",
          name: "datesRangePicker",
          props: {
            id: "timeline-filter-dates-range",
            startDateProps: {
              id: "timeline-filter-start-date",
              label: "Start date",
              maxDate: DateTime.jsDateNow()
            },
            endDateProps: { id: "timeline-filter-end-date", label: "End date" },
            startDateName: "dateFrom",
            endDateName: "dateTo",
            placeholder: "Date(s)",
            label: "Date(s)",
            styles: { fieldGroup: { width: "100%" } }
          }
        },
        {
          name: pastVisitsFilterNameOf("providers"),
          type: "optionsSelect",
          props: {
            ...dataAttribute(
              DataAttributes.Element,
              "past-visit-filter-providers"
            ),
            placeholder: "Providers",
            label: "Providers",
            multiSelect: true,
            options: mapProviders(providers),
            calloutWidth: 240,
            styles: { root: { width: "auto" } },
            onRenderOption: (option: ProviderOption) => (
              <Persona
                id={option.key}
                size={PersonaSize.size24}
                text={option.text}
                imageInitials={option.initials}
              />
            )
          }
        }
      ];

      const presets: PresetButtonType<PastVisitsFilterStateInternal>[] = [
        {
          text: "Consults",
          name: pastVisitsFilterNameOf("showConsultOnly"),
          dataAttr: "past-visit-filter-consult-only",
          iconName: "SchoolDataSyncLogo",
          tooltip: "Show consults only",
          tooltipPresses: "Showing consults only"
        }
      ];

      if (
        core.hasPermissions(Permission.ConfidentialDataAllowed) &&
        clinicalRecord?.isExistConfidentialClosedEncountersWithOtherUsers
      ) {
        presets.push({
          name: "confidential",
          iconName: "ReportHacked",
          tooltip: "Show confidential",
          tooltipPresses: "Hide confidential",
          tooltipOnDisabled: "No confidential items"
        });
      }

      return (
        <FilterBar<PastVisitsFilterStateInternal>
          onChange={({ values }) => {
            setPastVisitsFilter(values);
          }}
          validate={validator.validate}
          initialValues={initialFilterValues}
          items={items}
          presets={presets}
          shrinkVersion={sidePanelSize === TreeViewSize.Default}
          styles={{
            calloutItem: {
              width: "100%"
            },
            callout: {
              width: 288
            }
          }}
        />
      );
    }
  );
