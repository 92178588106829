import { IconButton, TooltipHost, useTheme } from "@bps/fluent-ui";

export const RequiredButton: React.FC<{
  required: boolean;
  disabled: boolean;
  alwaysRequired: boolean;
  onClick: () => void;
}> = ({ required, disabled, alwaysRequired, onClick }) => {
  const theme = useTheme();

  const background = required
    ? theme.semanticColors.errorBackground
    : undefined;

  const color = required ? theme.semanticColors.errorIcon : undefined;

  const toolTipIfDisabled = alwaysRequired ? "Always required" : "Not required";
  const toolTipIfEnabled = required ? "Required" : "Not required";

  return (
    <TooltipHost content={disabled ? toolTipIfDisabled : toolTipIfEnabled}>
      <IconButton
        iconProps={{ iconName: "Warning12" }}
        disabled={disabled}
        styles={{
          root: {
            color,
            background,
            padding: 16
          },
          rootHovered: {
            color,
            background
          }
        }}
        onClick={() => onClick()}
      />
    </TooltipHost>
  );
};
