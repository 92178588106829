import { DateTime, getDayOfWeek, getWeekPosition } from "@bps/utils";
import { MonthYearRecurrence } from "@libs/constants/month-year-recurrence.enum.ts";
import {
  CalendarEventRecurrenceDto,
  CalendarEventType,
  Frequency
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { toMonthYearRecurrence } from "@libs/utils/calendar/calendar.utils.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import { toAttendees } from "@stores/booking/models/CalendarEvent.ts";

import {
  UserReservesFormValues,
  UserReservesValues
} from "./UserReserves.types.ts";

export const prepareFormValues = (
  values: UserReservesValues,
  ids: {
    userId: string;
    locationId: string;
    loggedInUserId: string;
  }
) => {
  const { userId, locationId, loggedInUserId } = ids;
  const frequency = Number(values.frequency);
  const startDate = DateTime.fromJSDate(values.startDate);

  const isWeekSetDay =
    (values.frequency === Frequency.Month ||
      values.frequency === Frequency.Year) &&
    (values.monthYearRecurrence === MonthYearRecurrence.LastDayOfWeek ||
      values.monthYearRecurrence === MonthYearRecurrence.DayOfWeek);

  let dayRecur: number[] | undefined;
  if (values.frequency === Frequency.Week) {
    dayRecur = values.dayRecur;
  } else if (isWeekSetDay) {
    dayRecur = [getDayOfWeek(startDate)];
  }

  return {
    id: values.id,
    orgUnitId: locationId,
    bookedBy: values.bookedBy ?? loggedInUserId,
    type: values.calendarEventType,
    attendees: toAttendees({
      userId
    }),
    startTime: DateTime.fromISO(values.startTime)?.toTimeInputFormat()!,
    endTime: DateTime.fromISO(values.endTime)?.toTimeInputFormat()!,
    purpose: values.purpose,
    startDate: DateTime.jsDateToISODate(values.startDate),
    endDate:
      values.endScheduleType === EndScheduleType.OnDate
        ? values.until && DateTime.jsDateToISODate(values.until)
        : undefined,
    recurrenceRule: {
      count:
        values.endScheduleType === EndScheduleType.After
          ? values.count
          : undefined,
      frequency,
      weekPosition: isWeekSetDay
        ? getWeekPosition(startDate, values.monthYearRecurrence)
        : undefined,
      dayRecur,
      monthDayRecur:
        (values.frequency === Frequency.Month ||
          values.frequency === Frequency.Year) &&
        values.monthYearRecurrence === MonthYearRecurrence.MonthDay
          ? [startDate.day]
          : undefined,
      monthRecur:
        frequency === Frequency.Year ? [startDate.month] || [] : undefined,
      interval: values.interval,
      until:
        values.endScheduleType === EndScheduleType.OnDate
          ? values.until &&
            DateTime.fromJSDate(values.until).startOf("day").toISODate()
          : undefined
    }
  };
};

export const getUserReserves = (
  values: CalendarEventRecurrenceDto[]
): UserReservesValues[] => {
  return values.map(x => {
    let endScheduleType: EndScheduleType;
    if (x.recurrenceRule.count) {
      endScheduleType = EndScheduleType.After;
    } else if (x.recurrenceRule.until !== undefined) {
      endScheduleType = EndScheduleType.OnDate;
    } else {
      endScheduleType = EndScheduleType.Never;
    }

    return {
      id: x.id,
      bookedBy: x.bookedBy,
      orgUnitId: x.orgUnitId,
      calendarEventType: x.type,
      startDate: DateTime.jsDateFromISO(x.startDate),
      count: x.recurrenceRule.count,
      endScheduleType,
      startTime: DateTime.fromISO(x.startTime).toTimeInputFormat(),
      endTime: DateTime.fromISO(x.endTime).toTimeInputFormat(),
      purpose: x.purpose,
      interval: x.recurrenceRule.interval,
      frequency: x.recurrenceRule.frequency,
      until: DateTime.jsDateFromISO(x.recurrenceRule.until),
      dayRecur: x.recurrenceRule.dayRecur,
      monthRecur: x.recurrenceRule.monthRecur,
      monthDayRecur: x.recurrenceRule.monthDayRecur,
      weekPosition: x.recurrenceRule.weekPosition,
      monthYearRecurrence:
        x.recurrenceRule.frequency !== Frequency.Week
          ? toMonthYearRecurrence(
              x.recurrenceRule.weekPosition,
              x.recurrenceRule.monthDayRecur
            )
          : undefined
    };
  });
};

export const toUserReservesFormValues = (
  values: CalendarEventRecurrenceDto[]
) => {
  const userReserves = getUserReserves(values);
  return sortUserReservesFormValues(userReserves);
};

export const sortUserReservesFormValues = (
  userReserves: UserReservesValues[]
) => {
  const userReservesSorted: UserReservesFormValues = {
    currentUserReserves:
      userReserves.filter(
        x =>
          x.until === undefined ||
          (x.until && DateTime.fromJSDate(x.until) >= DateTime.today())
      ) ?? [],
    previousUserReserves:
      userReserves.filter(
        x => x.until && DateTime.fromJSDate(x.until) < DateTime.today()
      ) ?? []
  };
  return userReservesSorted;
};

export const getEmptyUserReserveValues = (
  userId: string,
  locationId: string
) => {
  const formValues: UserReservesValues = {
    calendarEventType: CalendarEventType.Unavailable,
    endScheduleType: EndScheduleType.Never,
    startTime: DateTime.now().startOf("hour").toTimeInputFormat(),
    endTime: DateTime.now()
      .startOf("hour")
      .plus({ hours: 1 })
      .toTimeInputFormat(),
    startDate: DateTime.today().toJSDate(),
    orgUnitId: locationId,
    bookedBy: userId,
    frequency: Frequency.Week,
    interval: 1
  };
  return formValues;
};
