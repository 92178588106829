import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import {
  ExtraCurrentItemValidator,
  ExtraHistoricalItemValidator,
  IntakeFormValidator
} from "../../intake/IntakeFormValidator.tsx";

interface AlcoholFormValidatorProps {
  dateOfBirth: Date | undefined;
}

export class AlcoholFormValidator extends IntakeFormValidator {
  constructor(options: AlcoholFormValidatorProps, core: CoreStore) {
    super(options, core);
    if (core.hasPermissions(Permission.CommonIntakeDetailAllowed)) {
      const alcoholItemCurrentValidator = new AlcoholItemCurrentValidator(
        options
      );

      const alcoholItemHistoricalValidator = new AlcoholItemHistoricalValidator(
        options
      );
      this.forArrayField(
        "historicalIntakes",
        alcoholItemHistoricalValidator.validate
      );
      this.forArrayField(
        "currentIntakes",
        alcoholItemCurrentValidator.validate
      );
    }
  }
}

export class AlcoholItemCurrentValidator extends ExtraCurrentItemValidator {
  constructor(options: AlcoholFormValidatorProps) {
    super(options);

    this.forField("unit", [required()]);
  }
}

export class AlcoholItemHistoricalValidator extends ExtraHistoricalItemValidator {
  constructor(options: AlcoholFormValidatorProps) {
    super(options);

    this.forField("unit", [required()]);
  }
}
