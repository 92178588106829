import { FormApi } from "final-form";
import { useRef } from "react";

import { routes } from "@libs/routing/routes.ts";
import { onSubmitAccForm } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimHelper } from "@modules/acc/screens/claim/context/ClaimHelper.ts";
import { ClaimDataFetcher } from "@modules/acc/screens/shared-components/ClaimDataFetcher.tsx";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClaimForm } from "./components/ClaimFormLazy.tsx";
import { DefaultFormHeader } from "./components/DefaultFormHeader.tsx";
import { ClaimScreenBaseProps, SubmitOptions } from "./types/claim.types.ts";

export const ClaimEditScreenBase: React.FC<ClaimScreenBaseProps> = ({
  claim
}) => {
  const root = useStores();
  const { routing } = root;

  const onSubmitSucceeded = (claim: Claim, submitOptions: SubmitOptions) => {
    if (submitOptions.redirect) {
      routing.push(routes.claims.basePath.pattern);
    }
  };

  const header = <DefaultFormHeader />;

  const claimHelper = useRef<ClaimHelper>(
    new ClaimHelper(root, claim, {
      onSubmit: onSubmitAccForm,
      onSubmitSucceeded
    })
  );

  const originalInitialValues = claimHelper.current.getInitialFormValues();
  const restartForm = (
    values: ClaimFormValues,
    form: FormApi<ClaimFormValues>
  ) => {
    form.restart(values);
  };

  return (
    <ClaimContext.Provider value={claimHelper.current}>
      <ClaimForm
        header={header}
        initialValues={originalInitialValues}
        onSubmitSucceeded={restartForm}
      />
    </ClaimContext.Provider>
  );
};

const ClaimEditScreenDataFetcher: React.FC = () => {
  const root = useStores();
  const claimId = routes.claims.edit.match(root.routing.location.pathname)
    ?.params.id;

  return (
    <ClaimDataFetcher
      fetchPatient
      fetchProvider
      fetchOrgUnits
      claimId={claimId}
    >
      {claim => <ClaimEditScreenBase claim={claim!} />}
    </ClaimDataFetcher>
  );
};

const ClaimEditScreen = withFetch(
  x => [x.acc.ref.claimStatuses.load()],
  ClaimEditScreenDataFetcher
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClaimEditScreen;
