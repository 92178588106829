import { AccScheduleProcessingStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface TransactionFilterQueryBase {
  search?: string;
  numberSearch?: string;
  accountIds?: string[] | string;
  excludedAccountIds?: string[] | string;
  claimStatuses?:
    | AccScheduleProcessingStatuses[]
    | AccScheduleProcessingStatuses;
  userIds?: string[] | string;
  startTime?: string;
  patientId?: string;
  endTime?: string;
}

export interface TransactionFilterBase {
  search?: string | undefined;
  numberSearch?: string;
  patientId?: string | undefined;
  accountIds?: string[] | undefined;
  excludedAccountIds?: string[] | undefined;
  claimStatuses?: AccScheduleProcessingStatuses[] | undefined;
  userIds?: string[] | undefined;
  startTime?: Date | undefined;
  endTime?: Date | undefined;
}

export const transactionFilterBaseNameOf =
  nameOfFactory<Required<TransactionFilterBase>>();

export type SetFilter = (newQuery: { [key: string]: any }) => void;
