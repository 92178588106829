import { ItemType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import {
  TransactionFilterBase,
  TransactionFilterQueryBase
} from "./TransactionFilterBase.types.ts";

export interface TransactionFilterQuery
  extends Pick<
    TransactionFilterQueryBase,
    | "search"
    | "userIds"
    | "startTime"
    | "endTime"
    | "accountIds"
    | "patientId"
    | "numberSearch"
  > {
  itemTypes?: ItemType[] | ItemType;
  invoiceOnly?: string;
  accExcluded?: string;
}

export interface TransactionFilter
  extends Pick<
    TransactionFilterBase,
    | "search"
    | "userIds"
    | "startTime"
    | "endTime"
    | "accountIds"
    | "patientId"
    | "numberSearch"
  > {
  itemTypes?: ItemType[] | undefined;
  invoiceOnly?: boolean | undefined;
  accExcluded?: boolean | undefined;
}

export const defaultTransactionFilter = {
  search: undefined,
  userIds: [],
  numberSearch: undefined,
  accountIds: [],
  patientId: undefined,
  startTime: undefined,
  endTime: undefined,
  itemType: undefined,
  accExcluded: undefined,
  invoiceOnly: undefined
};

export const transactionFilterNameOf = nameOfFactory<TransactionFilter>();
