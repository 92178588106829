import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useFormState } from "react-final-form";

import { PrimaryButton, Stack, TopBarDefaultHeader } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { AddTemplateFormValues } from "./AddTemplateFormValues.ts";
import { useTemplateFormContext } from "./context/TemplateFormContext.tsx";

export interface AddTemplateFormHeaderProps {
  form: FormApi<AddTemplateFormValues>;
}

export const AddTemplateFormHeader: React.FC<AddTemplateFormHeaderProps> =
  observer(({ form }) => {
    const { routing } = useStores();
    const { handleClose, isTemplateLengthValid } = useTemplateFormContext();
    const { invalid, dirty } = useFormState<AddTemplateFormValues>({
      subscription: { invalid: true, dirty: true }
    });

    const isFormValid = !invalid && dirty && isTemplateLengthValid;

    const handleOnBackClicked = () => {
      routing.push(routes.settings.communications.templates.pattern);
    };

    return (
      <TopBarDefaultHeader
        heading="Templates"
        backButtonOnClick={handleOnBackClicked}
        rightContainer={
          <FormSubmitButtons
            styles={{
              root: {
                marginTop: 0,
                borderTopColor: "none",
                backgroundColor: "inherit",
                paddingTop: 0
              }
            }}
            onCancel={() => {
              routing.push(routes.settings.communications.templates.pattern);
            }}
            hideButtonsSeparator
            promptOnCancel
            submitButtonProps={{
              iconProps: { hidden: true },
              id: "submit-template",
              disabled: !isFormValid
            }}
            cancelButtonProps={{
              id: "cancel-template"
            }}
            disableSubmitOnFormInvalid
            disableSubmitOnPristine
            disableCancelOnPristine
            extraActionsBetween={() => (
              <Stack.Item styles={{ root: { flexShrink: 0 } }}>
                <PrimaryButton
                  disabled={!isFormValid}
                  text="Save & Close"
                  onClick={() => {
                    handleClose();
                    form.submit();
                  }}
                />
              </Stack.Item>
            )}
          />
        }
      />
    );
  });
