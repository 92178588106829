import { memo } from "react";

import { CollapsibleCard } from "@bps/fluent-ui";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";

export const PostureOrObservationsCard: React.FC = memo(() => {
  return (
    <div id={SotapMskId.PostureOrObservations}>
      <CollapsibleCard
        heading={SotapMskLabel.PostureOrObservations}
        headingLevel="section-heading"
        iconName="SearchAndApps"
        openOnRender
      >
        <TextInputField
          name={sotapNameOf("postureOrObservations")}
          multiline
          rows={3}
          autoAdjustHeight
          resizable={false}
          styles={{
            fieldGroup: { minHeight: 60 }
          }}
        />
      </CollapsibleCard>
    </div>
  );
});
