import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";

export const ScheduleWarningBanner: React.FC = observer(() => {
  const { viewSchedule } = useContext(ScheduleScreenContext);

  if (!viewSchedule!.benefitSchedule) {
    return null;
  }

  return (
    <MessageBar messageBarType={MessageBarType.warning}>
      This schedule & the fees within are controlled by ACC, and editing is
      limited
    </MessageBar>
  );
});
