import { memo, PropsWithChildren } from "react";

import {
  DefaultButton,
  FontIcon,
  Stack,
  Text,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { CommunicationDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContextMenuItemsEnum } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { Contact } from "@stores/practice/models/Contact.ts";

interface CardWrapperProps extends PropsWithChildren {
  cardId: string;
  marginBottom?: number;
  communications: CommunicationDto[] | undefined;
  patient: Contact;
}

export const FormsCardWrapper: React.FC<CardWrapperProps> = memo(
  ({ cardId, children, marginBottom, patient }) => {
    const theme = useTheme();

    const formMenuItems = useFormMenu(patient);

    return (
      <div id={cardId}>
        <Stack
          horizontal
          styles={{
            root: marginBottom ? { marginBottom } : undefined
          }}
        >
          <Tile
            header={
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="baseline"
                styles={{ root: { paddingLeft: 10 } }}
              >
                <Stack>Forms</Stack>
                <DefaultButton
                  styles={{ root: { border: 0, minWidth: 0 } }}
                  className="open-clinical-header"
                  title="Forms"
                  menuProps={{ items: formMenuItems }}
                >
                  <FontIcon
                    iconName="ClipboardList"
                    styles={{
                      root: {
                        width: 16,
                        height: 16,
                        marginRight: 4,
                        marginTop: 4,
                        color: theme.palette.themePrimary
                      }
                    }}
                  />
                  <Text
                    styles={{
                      root: {
                        marginRight: 4
                      }
                    }}
                  >
                    {ContextMenuItemsEnum.SendForm}
                  </Text>
                </DefaultButton>
              </Stack>
            }
            styles={{
              root: { padding: "16px 14px", width: "100%" },
              header: {
                fontSize: theme.fonts.xLarge.fontSize
              }
            }}
          >
            {children}
          </Tile>
        </Stack>
      </div>
    );
  }
);
