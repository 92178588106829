import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

import { ScrollablePane, Stack } from "@bps/fluent-ui";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { AccInvoicesListHelper } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListHelper.ts";
import { AccreditedBillingList } from "@modules/billing/screens/accredited-billing/components/AccreditedBillingList.tsx";
import { AccreditedBillingListFilter } from "@modules/billing/screens/accredited-billing/components/AccreditedBillingListFilter.tsx";
import { AccreditedBillingSelectionButtons } from "@modules/billing/screens/accredited-billing/components/AccreditedBillingSelectionButtons.tsx";
import { BillingScreenWrapper } from "@modules/billing/screens/shared-components/BillingScreenWrapper.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export const AccreditedBillingScreen: FunctionComponent = observer(() => {
  const root = useStores();
  const { acc, practice } = root;

  const helper = useRef(new AccInvoicesListHelper(root, ""));

  const fetch = async () => {
    const billableOrgs = await acc.getBillableOrganisations();
    const ids = billableOrgs.map(org => org.contactId);
    await practice.getContactsById(ids);

    ids.sort((idA, idB) => {
      const contactA = practice.contactsMap.get(idA);
      const contactB = practice.contactsMap.get(idB);
      return (contactA?.name.toLowerCase() || "") >
        (contactB?.name.toLowerCase() || "")
        ? 1
        : -1;
    });

    return ids;
  };

  return (
    <DataFetcher<string[]> fetch={fetch} fallback={<BillingScreenWrapper />}>
      {insurerIds => (
        <AccInvoicesListContext.Provider value={helper.current}>
          <BillingScreenWrapper
            headerButtons={<AccreditedBillingSelectionButtons />}
          >
            <AccreditedBillingListFilter insurerIds={insurerIds}>
              <Stack grow styles={{ root: { position: "relative" } }}>
                <ScrollablePane>
                  <AccreditedBillingList insurerIds={insurerIds} />
                </ScrollablePane>
              </Stack>
            </AccreditedBillingListFilter>
          </BillingScreenWrapper>
        </AccInvoicesListContext.Provider>
      )}
    </DataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default AccreditedBillingScreen;
