import { AddPatientNoticeDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export type Visibility = keyof Pick<
  AddPatientNoticeDto,
  "isAdmin" | "isClinical"
>;

export interface AddPatientNoticeFormTypes
  extends Omit<AddPatientNoticeDto, "isClinical" | "isAdmin"> {
  visibility: Visibility[];
  textMandatory?: boolean;
}

export const addPatientNoticesFormNameOf =
  nameOfFactory<AddPatientNoticeFormTypes>();
