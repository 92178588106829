import { observer } from "mobx-react-lite";
import React from "react";

import { DefaultButton, PrimaryButton } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

interface WaitingListReBookAppointmentButtonsProps {
  confirmWLItem: () => void;
  deleteWLItem: () => void;
}

export const WaitingListRebookAppointmentButtons: React.FC<WaitingListReBookAppointmentButtonsProps> =
  observer(({ confirmWLItem, deleteWLItem }) => {
    const { booking } = useStores();
    if (
      booking.ui.cancelledAppointmentDetails &&
      booking.ui.cancelledAppointmentDetails.selectedCount > 0
    ) {
      return (
        <>
          <PrimaryButton
            id="addWlItemToAppt"
            onClick={confirmWLItem}
            text="Save"
          />
          <DefaultButton
            id="deleteWLItem"
            onClick={deleteWLItem}
            text="Remove from list"
          />
        </>
      );
    }

    return null;
  });
