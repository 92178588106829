import { Field } from "react-final-form";

import {
  hiddenContentStyle,
  IColumn,
  IStyle,
  mergeStyles,
  mergeStyleSets,
  Spinner,
  Text
} from "@bps/fluent-ui";
import { DateTime, to2dp } from "@bps/utils";
import { PaymentStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { sum } from "@libs/utils/utils.ts";
import { AllocationItemsColumns } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { RenderItemProps } from "../AllocationList/AllocationListBase.types.tsx";
import { AllocationFieldValues } from "./types.ts";

export const getAllocationsOwing = (allocations: AllocationFieldValues[]) => {
  return sum("owing", allocations);
};

export const getAllocationsTotal = (allocations: { total: number }[]) =>
  sum("total", allocations);

export const getTotalIncGst = (item: { [key: string]: string }): number =>
  Number(item.total || 0);

export const getTotalFee = (item: AllocationFieldValues): number =>
  Number(item.itemTotal || 0);

export const getOwing = (item: InvoiceItemFormValue): number =>
  Number(item.total) - Number(item.allocatedAmount);

//footer credited column total
export const getCreditedAmount = (item: AllocationFieldValues): number => {
  return Math.min(item.total, item.itemTotal - (item.owing || 0));
};

//footer voided column total
export const getVoidedAmount = (item: AllocationFieldValues): number => {
  return Math.min(item.total, item.owing || 0);
};

export const getAllocatedAmount = (item: AllocationFieldValues): number =>
  Number(item.total);

export const getBalanceOwing = (item: AllocationFieldValues): number =>
  -(Number(item.total) - Number(item.owing));

export const getTotalExcGst = (item: { [key: string]: string }): number => {
  return to2dp(Number(item.total || 0) - Number(item.gst || 0));
};

export const getOwingExcGst = (item: AllocationFieldValues): number => {
  return to2dp(Number(item.owing) - Number(item.gst || 0));
};

export const getGst = (item: InvoiceItemFormValue): number => {
  return Number(item.gst || 0);
};

export const getFirstItemIndex = <T extends {}>(
  key: keyof T,
  value: T,
  allocations: T[]
) => allocations.findIndex(a => a[key] === value[key]);

const getName = (
  options: {
    item: AllocationFieldValues;
    index: number;
  },
  allocations: AllocationFieldValues[],
  nameType: "patientName" | "userName"
) => {
  const { index, item } = options;
  const invoiceIdIndex = getFirstItemIndex<AllocationFieldValues>(
    "invoiceId",
    item,
    allocations
  );
  if (index === invoiceIdIndex) {
    return (
      <DataFetcher
        fallback={<Spinner />}
        fetch={root => root.billing.getInvoice(item.invoiceId)}
      >
        {(invoice: Invoice) => <Text>{invoice[nameType]} </Text>}
      </DataFetcher>
    );
  }
  return null;
};

export const invoiceNumberColumn = (
  onRender: (item: AllocationFieldValues, index: number) => JSX.Element,
  //visually hide the column so that checkbox fields are still in sync.
  hideColumn: boolean
): IColumn => {
  const hiddenCellStyle: IStyle = {
    "&&": {
      ...hiddenContentStyle,
      visibility: "hidden"
    }
  };

  const defaultColumnProps = {
    styles: mergeStyleSets({
      cellTitle: {
        justifyContent: "flex-end"
      }
    })
  };

  const hiddenColumnProps = {
    styles: {
      root: hiddenContentStyle
    },
    className: mergeStyles(hiddenCellStyle)
  };

  return {
    name: AllocationItemsColumns.invoiceNumber,
    key: "invoiceNumber",
    minWidth: 60,
    maxWidth: 100,
    ...(hideColumn ? hiddenColumnProps : defaultColumnProps),
    onRender
  };
};

export const patientNameColumn = (
  allocations: AllocationFieldValues[]
): IColumn => ({
  name: AllocationItemsColumns.patient,
  key: "patientName",
  minWidth: 100,
  maxWidth: 140,
  onRender: (item: AllocationFieldValues, index: number) =>
    getName({ item, index }, allocations, "patientName")
});

export const providerNameColumn = (
  allocations: AllocationFieldValues[]
): IColumn => ({
  name: "Provider",
  key: "providerName",
  minWidth: 100,
  maxWidth: 140,
  onRender: (item: AllocationFieldValues, index: number) =>
    getName({ item, index }, allocations, "userName")
});

export const serviceDateColumn = (): IColumn => ({
  name: AllocationItemsColumns.serviceDate,
  key: "invoiceDate",
  minWidth: 50,
  maxWidth: 100,
  onRender: (item: AllocationFieldValues) => (
    <DataFetcher
      fallback={<Spinner />}
      fetch={root => root.billing.getInvoice(item.invoiceId)}
    >
      {(invoice: Invoice) => (
        <Text>
          {DateTime.fromISO(
            invoice.getInvoiceItem(item.invoiceItemId)?.serviceDate
          )?.toDayDefaultFormat() || ""}
        </Text>
      )}
    </DataFetcher>
  )
});

export const getVoidedColumnContent = (item: {
  total: number;
  paymentStatus: PaymentStatuses;
  owing: number;
  checked: boolean;
}) => {
  const { checked, paymentStatus, owing, total } = item;
  switch (paymentStatus) {
    case PaymentStatuses.paid:
      return owing;
    case PaymentStatuses.unpaid:
      return total;
    case PaymentStatuses.part:
      return owing && checked ? owing : total;
    default:
      return total;
  }
};

export const owingColumn = (
  options: {
    renderItem: RenderItemProps;
    name?: string;
    hide?: boolean;
  },
  overrideOptions: {
    nameOverride?: string;
    calculationOverride?: (item: {
      total: number;
      paymentStatus: PaymentStatuses;
      owing: number;
      checked: boolean;
    }) => number;
  }
): IColumn => {
  const { renderItem, name, hide } = options;
  const { nameOverride, calculationOverride } = overrideOptions;
  return {
    name: hide ? "" : nameOverride || AllocationItemsColumns.owingPrefixed,
    key: hide ? "" : "owing",
    minWidth: 100,
    maxWidth: 100,
    onRender: (
      item: {
        owing: number;
        total: number;
        paymentStatus: PaymentStatuses;
        checked: boolean;
      },
      index: number
    ) => {
      const owing = Math.max(
        to2dp(
          calculationOverride
            ? calculationOverride(item)
            : Number(item.owing || 0) - Number(item.total || 0)
        ),
        0
      );
      return hide ? null : (
        <>
          {renderItem.text({
            value: owing.toLocaleString(undefined, {
              minimumFractionDigits: 2
            }),
            styles: () => ({ textAlign: "right" }) as IStyle
          })}
          {/* ⚠️ Hidden component aimed at registering of "total" fields, to detect changes after manual allocation */}
          {name && (
            <Field name={`${name}[${index}].total}`} render={() => null} />
          )}
        </>
      );
    },
    styles: mergeStyleSets({
      cellTitle: {
        justifyContent: "flex-end"
      }
    })
  };
};
