import { action, observable } from "mobx";

import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import { isPayment } from "@stores/billing/utils/transaction.utils.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export class TransactionListHelper {
  constructor(public accountContact?: Contact) {}

  @observable
  rowsToggledOpen = new Set<string>();

  @observable
  allParentIds: string[];

  @action setAllParentIdsNew = (items: TransactionBase[]) => {
    this.allParentIds = items
      .filter(x => x.items.length > 0 && !isPayment(x))
      .map(item => item.id);
  };

  @action
  toggleAll = () => {
    if (this.rowsToggledOpen.size === this.allParentIds?.length) {
      this.rowsToggledOpen.clear();
      this.rowsToggledOpen = new Set(this.rowsToggledOpen);
    } else {
      this.rowsToggledOpen = new Set(this.allParentIds);
    }
  };

  @action
  toggleRow = (id: string) => {
    if (this.rowsToggledOpen.has(id)) {
      this.rowsToggledOpen.delete(id);
    } else {
      this.rowsToggledOpen.add(id);
    }
    this.rowsToggledOpen = new Set(this.rowsToggledOpen);
  };

  @action resetRowsToggledOpen = () => {
    this.rowsToggledOpen = new Set();
  };
}
