import { createContext, useContext } from "react";

import { UnavailableFormHelper } from "./UnavailableFormHelper.ts";

export const UnavailableFormContext = createContext<UnavailableFormHelper>(
  {} as UnavailableFormHelper
);

export const useUnavailableFormContext = () =>
  useContext(UnavailableFormContext);
