import { DateTime } from "@bps/utils";
import { CorrespondenceVisibility } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { ImagingReportView } from "../AddXrayParameterDialog.types.ts";

export enum Labels {
  NewRequest = "New imaging request",
  Date = "Date",
  Urgent = "Urgent",
  InternallyConfidential = "Internally confidential",
  To = "To",
  Modality = "Modality",
  Region = "Region",
  CustomViews = "Custom views",
  WeightBearing = "Weight bearing",
  Other = "Other",
  For = "For",
  AdditionalInfo = "Additional service information",
  ClinicalHistory = "Clinical history",
  CurrentMeds = "Current medications",
  ProvisionalDiag = "Provisional diagnosis",
  CreateClinicalTask = "Create clinical task",
  ShowOnTimeline = "Display on timeline"
}

export const EMPTY_SECGROUPID = "EMPTY";

export interface ImagingRequestFormValues {
  requestDate: Date;
  urgent: boolean;
  to: string;
  modalities: Modality[];
  additionalServiceInformation: string;
  clinicalHistory: string;
  provisionalDiagnosis: string[] | undefined;
  pregnant: boolean;
  currentMedications: string;
  createClinicalTask: boolean;
  clinicalTaskDueDate?: Date;
  visibility?: CorrespondenceVisibility;
  claimId?: string;
  isPrivateClaim?: boolean;
  episodeOfCareId?: string;
}

export interface Modality {
  modality: string;
  regions: Region[];
}

export interface Region {
  region: string;
  side: string[];
  selectedRegionDetails: string[];
  customViews: boolean;
  customViewsDetails: string[];
  weightBearing: boolean;
  other: boolean;
  otherDetails: string;
}

export const requestFormNameOf = nameOfFactory<ImagingRequestFormValues>();
export const regionNameOf = nameOfFactory<Region>();
export const nameOfView = nameOfFactory<ImagingReportView>();
export const regionTypes = [
  {
    key: regionNameOf("customViews"),
    text: Labels.CustomViews
  },
  { key: regionNameOf("weightBearing"), text: Labels.WeightBearing },
  { key: regionNameOf("other"), text: Labels.Other }
];

export enum Modalities {
  Xray = "XR",
  Ultrasound = "US"
}

// key same as text as the key is saved and used to populate data for the template
export const customViews = [
  {
    key: "Antero-posterior",
    text: "Antero-posterior"
  },
  {
    key: "Postero-anterior",
    text: "Postero-anterior"
  },
  { key: "Lateral", text: "Lateral" },
  { key: "Oblique", text: "Oblique" },
  {
    key: "Anterior oblique",
    text: "Anterior oblique"
  },
  {
    key: "Posterior oblique",
    text: "Posterior oblique"
  },
  { key: "Lateral decubitus", text: "Lateral decubitus" },
  { key: "Open-mouth odontoid", text: "Open-mouth odontoid" }
];

export const emptyRegion: Region = {
  region: "",
  side: [],
  selectedRegionDetails: [],
  customViews: false,
  customViewsDetails: [],
  weightBearing: false,
  other: false,
  otherDetails: ""
};

export const emptyModality: Modality = {
  modality: Modalities.Xray,
  regions: [emptyRegion]
};

export const getEmptyImagingRequest = (
  _clinicalRecord: ClinicalRecord
): ImagingRequestFormValues => {
  const isPrivateClaim =
    _clinicalRecord.condition?.episodeOfCareId !== undefined &&
    _clinicalRecord.condition?.claim === undefined;
  return {
    requestDate: DateTime.jsDateNow(),
    urgent: false,
    to: "",
    modalities: [emptyModality],
    additionalServiceInformation: "",
    clinicalHistory: "",
    provisionalDiagnosis: [],
    pregnant: !!_clinicalRecord.clinicalData?.social?.isPregnant?.observed,
    currentMedications: "",
    createClinicalTask: false,
    clinicalTaskDueDate: undefined,
    claimId: isPrivateClaim ? "Private" : _clinicalRecord.condition?.claim?.id,
    isPrivateClaim,
    episodeOfCareId: _clinicalRecord.episodeOfCare?.id
  };
};
