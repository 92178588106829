import { FunctionComponent, memo } from "react";

import {
  CompleteDialog,
  CompleteDialogProps
} from "@ui-components/CompleteDialog.tsx";

export interface ClinicalTaskCompleteDialogProps {
  hidden: boolean;
  selectedCount: number;
  onConfirm: CompleteDialogProps["onConfirm"];
  onCancel?: () => void;
}
export const ClinicalTaskCompleteDialog: FunctionComponent<ClinicalTaskCompleteDialogProps> =
  memo(props => {
    const title =
      props.selectedCount > 1
        ? `Complete (${props.selectedCount}) clinical tasks`
        : "Complete clinical task";

    const subText =
      props.selectedCount > 1
        ? "Are you sure you want to complete these clinical tasks?"
        : "Are you sure you want to complete this clinical task?";
    return (
      <CompleteDialog
        formName="clinical-task-complete"
        {...props}
        title={title}
        subText={subText}
        maxWidth={480}
        okButtonText="Complete"
      />
    );
  });
