import React, { useState } from "react";

import {
  DefaultButton,
  Heading,
  IButtonStyles,
  IconButton,
  mergeStyles,
  mergeStyleSets,
  NoDataTile,
  Spinner,
  Stack
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { QuickColoursDialog } from "./QuickColoursDialog.tsx";

export interface QuickColoursProps {
  onColourSelected: (colour: string) => void;
  buttonStyles?: IButtonStyles;
  orientVertically?: boolean;
}

export const QuickColoursBase: React.FC<QuickColoursProps> = ({
  onColourSelected,
  buttonStyles,
  orientVertically
}) => {
  const { userExperience } = useStores();

  const quickColours = userExperience.quickColours;

  const [showQuickColoursDialog, setShowQuickColoursDialog] = useState(false);

  const quickColorsStyles = mergeStyles({
    flexWrap: orientVertically ? "noWrap" : "wrap",
    maxHeight: "calc(100vh - 600px)",
    overflowY: "auto",
    "@media screen and (max-height: 1040px)": {
      maxHeight: 160
    }
  });

  const renderButtonContent = (name: string, colour: string) => {
    return (
      <Stack
        key={name}
        horizontal
        horizontalAlign="start"
        styles={{ root: { whiteSpace: "nowrap", width: "100%" } }}
        onClick={() => {
          onColourSelected(colour);
        }}
      >
        <div
          style={{
            width: 16,
            height: 16,
            backgroundColor: colour,
            marginRight: 4
          }}
        />
        <Stack.Item>{name}</Stack.Item>
      </Stack>
    );
  };

  return (
    <>
      <Stack>
        <Stack
          horizontal
          styles={{ root: { alignItems: "center" } }}
          tokens={{ childrenGap: 4 }}
        >
          <Heading styles={{ root: { marginTop: 13, marginBottom: 14.5 } }}>
            Quick colours
          </Heading>
          <IconButton
            iconProps={{ iconName: "Settings" }}
            onClick={() => setShowQuickColoursDialog(true)}
            styles={{ icon: { fontSize: "15px" } }}
          />
        </Stack>
        <Stack
          verticalFill={orientVertically}
          horizontal={orientVertically !== true}
          className={quickColorsStyles}
        >
          {quickColours?.quickColoursSettings.length === 0 ? (
            <NoDataTile
              textProps={{ text: "No quick colours selected" }}
              linkProps={{
                text: "Select",
                onClick: () => setShowQuickColoursDialog(true)
              }}
              showBoxShadow={false}
              greyView
            />
          ) : (
            quickColours?.quickColoursSettings.map(colourSetting => (
              <DefaultButton
                key={colourSetting.colourName}
                onRenderText={() =>
                  renderButtonContent(
                    colourSetting.colourName,
                    colourSetting.colourCode
                  )
                }
                styles={mergeStyleSets(
                  {
                    root: {
                      border: 0,
                      minWidth: 0,
                      paddingRight: 8,
                      paddingLeft: 8,
                      justifyContent: "start"
                    }
                  },
                  { ...buttonStyles }
                )}
              />
            ))
          )}
        </Stack>
      </Stack>
      {showQuickColoursDialog && (
        <QuickColoursDialog
          onDismiss={() => setShowQuickColoursDialog(false)}
        />
      )}
    </>
  );
};

export const QuickColours = withFetch(
  x => [
    x.userExperience.getCurrentUserQuickColours(),
    x.userExperience.ref.quickColoursType.load()
  ],
  (props: QuickColoursProps) => {
    return <QuickColoursBase {...props} />;
  },
  { fallback: <Spinner /> }
);
