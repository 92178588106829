import {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";
import { useField } from "react-final-form";

import { ITextFieldProps, mergeStyleSets, TextField } from "@bps/fluent-ui";
import { FeeType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { TextInputFieldProps } from "@ui-components/form/TextInputField.tsx";

const ALL_CHARS_BUT_NUMS_AND_PERIOD = /[^\d.]/g;

const MAX_NUMERIC_DECIMAL_PLACES = 2;

export const formatString = (value: string): string => {
  const str = value.replace(ALL_CHARS_BUT_NUMS_AND_PERIOD, "");
  const maxDigits =
    str.indexOf(".") !== -1
      ? str.indexOf(".") + MAX_NUMERIC_DECIMAL_PLACES + 1
      : str.length;
  return str.slice(0, maxDigits);
};

export const convertString = (value: string): number | "" => {
  if (!value) {
    return 0;
  }

  if (Number(value)) {
    return Number(value);
  }

  return "";
};

export const convertNumber = (value: number | ""): string => {
  return value === "" ? value : String(value);
};

interface InvoiceFormFeeInputFieldProps
  extends Omit<TextInputFieldProps, "name"> {
  index: number;
  feeType: FeeType;
}

export const InvoiceFormFeeInputField: FunctionComponent<
  InvoiceFormFeeInputFieldProps
> = ({ data, ...props }) => {
  const name = `invoiceItems[${props.index}].fee`;
  const { input } = useField(name, { subscription: { value: true } });

  return (
    <InvoiceFormFeeInput
      {...props}
      value={input.value}
      onChange={input.onChange}
    />
  );
};

interface InvoiceFormFeeInputProps
  extends Omit<ITextFieldProps, "value" | "onChange"> {
  index: number;
  feeType: FeeType;
  value: number | "";
  onChange: (value: number | "") => void;
}

const InvoiceFormFeeInput: React.FC<InvoiceFormFeeInputProps> = ({
  index,
  feeType,
  onChange: parentOnChange,
  onBlur: parentOnBlur,
  value,
  styles,
  ...props
}) => {
  const quantityFieldName = `invoiceItems[${index}].quantity`;
  const quantityField = useField(quantityFieldName, {
    subscription: { value: true }
  });

  const rawQuantity = Number(quantityField.input.value);
  const quantity = feeType === FeeType.Hourly ? rawQuantity / 60 : rawQuantity;

  const [valueString, setValueString] = useState<string>(convertNumber(value));

  const previousValue = useRef<number | "">(value);

  useEffect(() => {
    // if value has been changed by parent, update string
    if (value !== previousValue.current) {
      previousValue.current = value;
      setValueString(convertNumber(value));
    }
  }, [value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const str = formatString(event.target.value);

    setValueString(str);
    const newValue = convertString(str);
    if (newValue !== "") {
      // if new value is valid, call parent onChange
      previousValue.current = newValue;
      parentOnChange(newValue);
    }
  };

  const onBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = convertString(valueString);
    setValueString(convertNumber(newValue));

    // if current value is not valid, call parent onChange
    // as otherwise, form will still have the previous valid value
    if (newValue === "") {
      previousValue.current = newValue;
      parentOnChange(newValue);
    }
    if (parentOnBlur) {
      parentOnBlur(event);
    }
  };

  const suffix =
    value && quantity
      ? (quantity * value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : "";

  return (
    <TextField
      styles={mergeStyleSets(
        {
          suffix: { justifyContent: "center", minWidth: 50 },
          field: { textAlign: "right" }
        },
        styles
      )}
      suffix={suffix}
      {...props}
      value={valueString}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
