import { FC, PropsWithChildren, ReactNode } from "react";

import { Stack } from "@bps/fluent-ui";
import { hideUnlessPrintingStyles } from "@ui-components/printing/Print.styles.ts";
import { Print } from "@ui-components/printing/Print.tsx";

interface AppointmentInformationModalPrintWrapperProps
  extends PropsWithChildren {
  title: ReactNode;
}

export const AppointmentInformationModalPrintWrapper: FC<
  AppointmentInformationModalPrintWrapperProps
> = ({ title, children }) => {
  // 40px pageStyle margin is necessary otherwise it print preview has no margin
  return (
    <Print pageStyle="@page { margin: 60px 40px; }">
      {print => (
        <div ref={print.ref}>
          <Stack
            tokens={{ childrenGap: 8 }}
            styles={{
              root: {
                height: 631,
                display: "flex",
                flexDirection: "column",
                "@media print": {
                  "[data-bp-test-element='list-wrapper']": {
                    overflowY: "visible" // stops the element from scrolling
                  },
                  ".ms-SelectionZone > .ms-FocusZone": {
                    display: "block" // so the page doesn't break unusually (display: inline-block doesn't work well for some reason)
                  },
                  ".ms-DetailsHeader-cellIsCheck, .ms-DetailsRow-cellCheck": {
                    display: "none" // hide the checkbox column
                  },
                  ".ms-DetailsHeader-cell[data-item-key='duration'], .ms-DetailsRow-cell[data-automation-key='duration']":
                    {
                      display: "none" // hide the "duration" column
                    }
                }
              }
            }}
          >
            <Stack styles={hideUnlessPrintingStyles}>{title}</Stack>
            {children}
          </Stack>
        </div>
      )}
    </Print>
  );
};
