import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { Stack, Text } from "@bps/fluent-ui";
import { DateTime, newGuid } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { TaskActionFormValues, TasksScreenLabels } from "./Tasks.types.ts";
import { TaskActionFormValidator } from "./validators/TaskActionFormValidator.tsx";

export type TaskActionFormProps = {
  task: UserTask;
};

export const TaskActionForm: FC<TaskActionFormProps> = observer(({ task }) => {
  const { inbox, core } = useStores();
  const nameOf = nameOfFactory<TaskActionFormValues>();

  const { handleTaskActionSubmit } = useContext(InboxScreenContext);

  const todayDate = DateTime.jsDateNow();

  const todayTime = DateTime.now().toTimeInputFormat();

  const initialValues: TaskActionFormValues = {
    outcomeLog: task.outcomeLog ?? [],
    currentOutcome: {
      id: newGuid(),
      date: todayDate,
      time: todayTime,
      outcomeStatus: "",
      createdByUser: core.accountInfo?.username
    },
    userTaskId: task.id
  };

  return (
    <SubmissionForm<TaskActionFormValues>
      formName="task-action"
      validate={values => new TaskActionFormValidator().validate(values)}
      initialValues={initialValues}
      styles={{ fields: { overflow: "hidden" } }}
      onSubmit={values => handleTaskActionSubmit(values, task)}
      onSubmitSucceeded={(values, form) => {
        form.restart(values);
      }}
      buttonsProps={{
        submitButtonProps: {
          text: "Save",
          iconProps: undefined
        }
      }}
    >
      {() => {
        return (
          <Stack
            tokens={{ childrenGap: 8 }}
            styles={{
              root: {
                height: "100%"
              }
            }}
          >
            <DropdownField
              name={`${nameOf("currentOutcome")}.outcomeStatus`}
              label={TasksScreenLabels.Outcome}
              options={inbox.ref.outcomes.keyTextValues}
              required
            />
            <Text styles={{ root: { fontWeight: 600 } }}>
              {TasksScreenLabels.OutcomeLog}
            </Text>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <DatePickerField
                name={`${nameOf("currentOutcome")}.date`}
                maxDate={todayDate}
              />
              <TimePickerField
                name={`${nameOf("currentOutcome")}.time`}
                max={todayTime}
              />
            </Stack>

            <TextInputField
              name={`${nameOf("currentOutcome")}.comment`}
              resizable={false}
              multiline
            />
          </Stack>
        );
      }}
    </SubmissionForm>
  );
});
