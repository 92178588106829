import { Country } from "@libs/enums/country.enum.ts";
import {
  greaterThan,
  lessThan,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { InvoiceSettingsFormValues } from "../InvoiceSettingsForm.types.tsx";
import { InvoiceSettingsAuBankDetailsValidator } from "./InvoiceSettingsAuBankDetailsValidator.ts";
import { InvoiceSettingsNzBankDetailsValidator } from "./InvoiceSettingsNzBankDetailsValidator.ts";

export class InvoiceSettingsValidator extends Validator<InvoiceSettingsFormValues> {
  constructor(country: Country) {
    super();

    this.forField("gstPercent", [required(), lessThan(100), greaterThan(0)]);

    this.forField("bankDetails", [
      predicate(
        () => country === Country.Australia,
        value => {
          const accountsValidator = new InvoiceSettingsAuBankDetailsValidator();
          return accountsValidator.validate(value);
        }
      ),
      predicate(
        () => country === Country.NewZealand,
        value => {
          const accountsValidator = new InvoiceSettingsNzBankDetailsValidator();
          return accountsValidator.validate(value);
        }
      )
    ]);
  }
}
