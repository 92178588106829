import { toJS } from "mobx";
import { FunctionComponent } from "react";

import { CenteredLargeSpinner, TopBarWrapper, useTheme } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { ClinicalFormValuesSpy } from "../../clinical-record-forms-values-spy/ClinicalRecordFormsValuesSpy.tsx";
import {
  SOTAPFollowOnFormContext,
  useSOTAPFollowOnFormContext
} from "../context/SOTAPFollowOnFormContext.tsx";
import { SOTAPFollowOnFormModel } from "../context/SOTAPFollowOnFormModel.ts";
import { SOTAPFormHeader } from "../SOTAPFormHeader.tsx";
import { GoalCardValidator } from "../validators/GoalCardValidator.tsx";
import { OutcomeMeasuresCardValidator } from "../validators/OutcomeMeasuresCardValidator.tsx";
import { withFetchSOTAPRefData } from "../withFetchSOTAPRefData.tsx";
import { SOTAPFollowOnObjectiveContent } from "./SOTAPFollowOnObjectiveContent.tsx";
import { SOTAPFollowOnSubjectiveContent } from "./SOTAPFollowOnSubjectiveContent.tsx";
import { SOTAPFollowOnTapContent } from "./SOTAPFollowOnTapContent.tsx";
import { FollowUpNotesCardValidator } from "./validators/FollowUpNotesCardValidator.ts";

const followUpNotesCardValidator = new FollowUpNotesCardValidator();
const goalCardValidator = new GoalCardValidator();
const outcomeMeasuresCardValidator = new OutcomeMeasuresCardValidator();

export interface ISOTAPFollowOnFormProps {
  defaultTools?: ClinicalDataType[] | undefined;
}

interface SOTAPFollowOnFormBaseProps {
  claim?: Claim;
}

const SOTAPFollowOnFormBase: React.FC<SOTAPFollowOnFormBaseProps> = () => {
  const theme = useTheme();
  const model = useSOTAPFollowOnFormContext();
  const { notification } = useStores();
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const formInitialValues = model.initialSOTAPFollowOnFormValues;
  const persistedValues =
    clinicalRecord.clinicalRecordFormsValuesStash.persistedValues[
      PermanentClinicalTab.SOTAP
    ];

  const mergedValues: SOTAPFormValues = {
    ...formInitialValues,
    ...persistedValues
  };

  const initialValues = toJS(mergedValues, {
    recurseEverything: true
  });

  const onSubmit = async (values: SOTAPFormValues) => {
    await model.submitData(values);
    clinicalRecord.clinicalRecordFormsValuesStash.resetPersistedValues(
      PermanentClinicalTab.SOTAP,
      values
    );
    notification.success("SOTAP MSK has been saved successfully.");
  };

  const dischargeInProgress = clinicalRecord.dischargeInProgress();

  const dischargeInProgressOrCompleted =
    dischargeInProgress ||
    clinicalRecord.getDischargeStatus(
      clinicalRecord.openEncounter?.businessRole
    ) === DischargeStatus.Completed;

  let warning: JSX.Element | string | undefined;
  if (dischargeInProgress) {
    warning =
      "Unable to edit SOTAP once discharge has begun. Delete the discharge to enable editing.";
  }
  return (
    <SubmissionForm<SOTAPFormValues>
      formName="sotap-follow-on"
      disabled={dischargeInProgressOrCompleted}
      delayForSettingFieldsData={500}
      styles={{
        root: {
          margin: -16
        }
      }}
      subscription={{}}
      disableRoutePrompt
      hideButtons
      warning={warning ? () => warning : undefined}
      warningMessageBarProps={{
        styles: {
          text: { fontSize: theme.fonts.small.fontSize }
        }
      }}
      onSubmit={onSubmit}
      readOnly={isViewOnlyOrDischarged}
      initialValues={initialValues}
      validate={values => {
        return {
          ...followUpNotesCardValidator.validate(values),
          ...goalCardValidator.validate(values),
          ...outcomeMeasuresCardValidator.validate(values)
        };
      }}
      render={() => (
        <TopBarWrapper header={<SOTAPFormHeader model={model} />}>
          <ClinicalFormValuesSpy
            clinicalRecord={clinicalRecord}
            tab={PermanentClinicalTab.SOTAP}
            originalInitialValues={model.initialSOTAPFollowOnFormValues}
          />
          <SOTAPFollowOnSubjectiveContent />
          <SOTAPFollowOnObjectiveContent />
          <SOTAPFollowOnTapContent />
        </TopBarWrapper>
      )}
    />
  );
};

const SOTAPFollowOnFormComponent: FunctionComponent<
  ISOTAPFollowOnFormProps
> = ({ defaultTools }) => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  // Needs to be here to preload persisted values before SOTAPFormBase rendering.
  // Get claim
  const getClaim = async () => {
    return clinicalRecord.calendarEvent?.loadClaim();
  };

  const fetch = async () => {
    const model = new SOTAPFollowOnFormModel(clinicalRecord);
    const [claim] = await Promise.all([
      getClaim(),
      model.loadCurrentMeasurement(),
      model.loadInjuryAreaData(),
      model.loadCurrentEncounterClinicalToolsData(
        clinicalRecord.openEncounter?.businessRole,
        defaultTools
      ),
      model.loadPsfsQuestions()
    ]);
    return { model, claim };
  };

  return (
    <DataFetcher fetch={fetch} fallback={<CenteredLargeSpinner />}>
      {data => (
        <SOTAPFollowOnFormContext.Provider value={data.model}>
          <SOTAPFollowOnFormBase claim={data.claim} />
        </SOTAPFollowOnFormContext.Provider>
      )}
    </DataFetcher>
  );
};

export const SOTAPFollowOnForm = withFetchSOTAPRefData(
  SOTAPFollowOnFormComponent
);
