import React, { FC } from "react";

import { TodaysNotesHeading } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

type StructuredNoteTextProps = {
  mainFreeText: string | undefined;
  notesHeadings: TodaysNotesHeading[];
};

export const StructuredNoteText: FC<StructuredNoteTextProps> = ({
  mainFreeText,
  notesHeadings
}) => (
  <>
    {mainFreeText && (
      <div
        dangerouslySetInnerHTML={{
          __html: mainFreeText
        }}
      />
    )}
    {notesHeadings.map(heading => (
      <React.Fragment key={heading.code}>
        {heading.structuredNotes?.map(structuredNote => (
          <div
            key={structuredNote.order}
            dangerouslySetInnerHTML={{ __html: structuredNote.note }}
          />
        ))}
        {heading.freeText && (
          <div dangerouslySetInnerHTML={{ __html: heading.freeText }} />
        )}
      </React.Fragment>
    ))}
  </>
);
