import { memo } from "react";

import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";

import { MessageContentFormSection } from "../../../comms-schedules/components/MessageContentFormSection.tsx";
import { AppointmentConfirmationCampaignSection } from "./AppointmentConfirmationCampaignFormSection.tsx";
import { CommsConfirmationCampaignFormValues } from "./CommsConfirmationCampaignFormValues.ts";

interface CommsConfirmationCampaignEditFormFieldsProps {
  templates: OutboundCommTemplate[];
  initialValues: CommsConfirmationCampaignFormValues | undefined;
}

export const CommsConfirmationCampaignEditFormFields: React.FC<CommsConfirmationCampaignEditFormFieldsProps> =
  memo(props => {
    const subHeadingArray = [
      { key: 1, value: "New appointment confitmaion" },
      { key: 2, value: "Appointment change" },
      { key: 3, value: "Appointment cancellation" }
    ];
    return (
      <>
        <AppointmentConfirmationCampaignSection />
        {subHeadingArray.map(x => (
          <MessageContentFormSection
            key={x.key}
            templates={props.templates}
            subHeading={x.value}
          />
        ))}
      </>
    );
  });
