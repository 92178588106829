export enum CorrespondencesLabels {
  SearchTitlePlaceholder = "Search title or content",
  SearchDirectionPlaceholder = "Show In and Out",
  SearchTypePlaceholder = "Show all types",
  SearchStatusPlaceholder = "Show all Statuses",
  Upload = "Upload new file",
  Create = "Create document",
  RowCheckbox = "Row checkbox",
  MovePatientLabel = "Move to patient",
  MovePatientPlaceholder = "Search patient",
  ContextMenuOpenDraft = "Edit",
  ContextMenuOpenDocument = "Preview",
  ContextMenuEdit = "Edit details",
  ContextMenuMoveToAnotherPatient = "Another patient",
  ContextMenuAnotherPatient = "Another patient",
  ContextMenuInvestigations = "Investigations",
  ContextMenuCorrespondence = "Correspondence",
  ContextMenuClinicalImages = "Clinical images",
  ContextMenuMoveTo = "Move to",
  ContextMenuPrint = "Print",
  ContextMenuDelete = "Delete",
  ContextMenuEmail = "Email",
  ContextMenuOutcome = "Task outcome",
  Outcome = "Outcome",
  ContextMenuAttemptedContact = "Attempted contact",
  ContextMenuInformed = "Informed",
  ContextMenuBooked = "Booked",
  ContextMenuGiven = "Given",
  ContextMenuNone = "None",
  ContextMenuNormal = "Normal",
  ContextMenuAcceptable = "Acceptable",
  ContextMenuUnacceptable = "Unacceptable",
  ContextMenuMoveToInvestigations = "Investigations",
  ContextMenuMoveToClinicalImages = "Clinical images",
  DialogDeleteConfirmationTitle = "Delete correspondence",
  DialogDeleteConfirmationSubText = "Are you sure you want to delete this correspondence?",
  DialogConflictErrorSubText = "This file has been changed by someone else since you opened it. The up-to-date list will load on refresh and discard your changes.",
  DialogConflictErrorTitle = "File already edited",
  MarkAsReturned = "Mark as returned",
  RemoveConfidentiality = "Remove confidentiality",
  MakeConfidential = "Make confidential",
  CancelRequest = "Cancel request",
  CreateClinicalTask = "Create clinical task"
}
