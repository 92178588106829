import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  Badge,
  FontWeights,
  IStackProps,
  mergeStyleSets,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DrugMedicationSummaryDto } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";

export const PrescribingFormulationsList: FunctionComponent = observer(() => {
  const { drugs } = useStores();
  const helper = usePrescribingWizardContext();
  const {
    formulationsSearch,
    formulations,
    formulationsRestriction,
    selectedFormulation
  } = helper;

  const theme = useTheme();

  const getFilteredFormulations = () => {
    if (!formulations) {
      return [];
    }

    const hasRestrictionFilter = Object.values(
      formulationsRestriction
    ).includes(true);

    return formulations.filter(
      formulation =>
        (!formulationsSearch ||
          formulation.productPack.medication
            .toLowerCase()
            .indexOf(formulationsSearch.toLowerCase()) > -1) &&
        (!hasRestrictionFilter ||
          formulationsRestriction[formulation.productPack.pbsListing])
    );
  };

  const updateSelectedFormulation = (
    selectedFormulation: DrugMedicationSummaryDto
  ) => {
    runInAction(() => {
      helper.selectedFormulation = selectedFormulation;
      helper.warnings = [];
    });
  };

  const renderFormulationsList = () => {
    const filteredFormulations = getFilteredFormulations();
    const textStyles = {
      root: { flex: 0.2 }
    };

    const textHeadingStyles = {
      root: {
        fontSize: 12,
        color: theme.palette.neutralSecondaryAlt
      }
    };

    const rowStyles: IStackProps["styles"] = {
      root: {
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 8,
        selectors: {
          "&:not(:last-child)": {
            borderBottom: `1px ${theme.semanticColors.bodyFrameDivider} solid`
          },
          "&:hover": {
            backgroundColor: theme.semanticColors.buttonBackgroundHovered
          },
          "&:active": {
            backgroundColor: theme.semanticColors.buttonBackgroundPressed
          }
        }
      }
    };

    const selectedRowStyles: IStackProps["styles"] = {
      root: {
        backgroundColor: theme.semanticColors.buttonBackgroundPressed
      }
    };
    return (
      <Stack grow styles={{ root: { overflowY: "auto" } }}>
        {filteredFormulations.map(formulation => {
          const { productPack } = formulation;
          const {
            packId,
            medication,
            quantity,
            rpts,
            bpp,
            tgp,
            pbsListing,
            productUnit
          } = productPack;

          const isSelected = selectedFormulation?.productPack.packId === packId;

          const currentRowStyles = mergeStyleSets(
            rowStyles,
            isSelected && selectedRowStyles
          );
          return (
            <Stack
              key={formulation.productPack.packId}
              horizontal
              onClick={() => {
                updateSelectedFormulation(formulation);
              }}
              styles={currentRowStyles}
              className={`bp-prescribing-formulation-item ${
                isSelected ? "bp-prescribing-formulation-item--selected" : ""
              }`}
            >
              <Stack
                grow
                styles={{
                  root: {
                    maxWidth: "80%"
                  }
                }}
              >
                <Stack
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold,
                      textOverflow: "inherit",
                      whiteSpace: "pre-wrap"
                    }
                  }}
                >
                  {medication}
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      paddingTop: 4
                    }
                  }}
                  tokens={{ childrenGap: 16 }}
                >
                  <Text styles={textStyles}>
                    <Text styles={textHeadingStyles}>QTY</Text>{" "}
                    {`${quantity}${
                      productUnit === undefined ? "" : productUnit
                    }`}
                  </Text>
                  <Text styles={textStyles}>
                    <Text styles={textHeadingStyles}>RPTS</Text> {rpts}
                  </Text>
                  {(bpp || tgp) && (
                    <Text
                      styles={{
                        root: {
                          ...textStyles.root,
                          color: theme.palette.red
                        }
                      }}
                    >
                      <Text
                        styles={{
                          root: {
                            ...textHeadingStyles.root,
                            color: theme.palette.redDark
                          }
                        }}
                      >
                        {tgp ? "TGP/SP" : "BPP"}
                      </Text>{" "}
                      ${tgp ? Number(tgp).toFixed(2) : Number(bpp).toFixed(2)}
                    </Text>
                  )}
                </Stack>
              </Stack>
              <Badge
                styles={{
                  root: {
                    padding: "4px 8px",
                    background: theme.semanticColors.errorBackground,
                    borderRadius: 2
                  }
                }}
              >
                {drugs.ref.pbsListings.get(pbsListing)?.text}
              </Badge>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return (
    <Stack grow styles={{ root: { overflow: "hidden" } }}>
      {renderFormulationsList()}
    </Stack>
  );
});
