import {
  isBeforeTimeField,
  isDateBeforeField,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  Operation,
  OrgUnitOpeningHoursOverridesFormValues,
  OrgUnitOpeningHoursOverridesValues
} from "./OrgUnitOpeningHoursOverridesForm.types.ts";

export class OrgUnitOpeningHoursOverridesFormValidator extends Validator<OrgUnitOpeningHoursOverridesValues> {
  constructor() {
    super();
    const orgUnitOpeningHoursFormValidator =
      new OrgUnitOpeningHoursOverridesValidator();
    this.forArrayField(
      "orgUnitOpeningHoursOverrides",
      orgUnitOpeningHoursFormValidator.validate
    );
  }
}

export class OrgUnitOpeningHoursOverridesValidator extends Validator<OrgUnitOpeningHoursOverridesFormValues> {
  constructor() {
    super();

    this.forField("startDate", [required()]);
    this.forField("endDate", [required()]);

    this.forField("endDate", [
      predicate(
        (val: Date, exception: OrgUnitOpeningHoursOverridesFormValues) =>
          !!val && !!exception.startDate,
        isDateBeforeField(
          ValidationMessages.endDateBeforeStartDate,
          "startDate"
        )
      )
    ]);

    this.forField("operation", [required()]);

    this.forField("startTime", [
      predicate(
        (val: string, exception: OrgUnitOpeningHoursOverridesFormValues) =>
          exception.operation !== Operation.Closed,
        required()
      )
    ]);
    this.forField("endTime", [
      predicate(
        (val: string, exception: OrgUnitOpeningHoursOverridesFormValues) =>
          exception.operation !== Operation.Closed,
        required()
      )
    ]);
    this.forField("endTime", [
      predicate(
        (val: string, exception: OrgUnitOpeningHoursOverridesFormValues) =>
          exception.operation !== Operation.Closed,
        required(),
        isBeforeTimeField(
          ValidationMessages.endTimeLaterStartTime,
          "startTime",
          true
        )
      )
    ]);
    this.forField("reason", maxLength(50));
  }
}
