import { unique } from "@bps/utils";
import {
  DASHModuleTypes,
  MeasurementType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";

import { Rand36ReponseTypes } from "./rand36/Rand36Form.types.ts";

export function generateDashMeasurements(
  measurements: Measurement[]
): Measurement[] {
  const otherMeasurements = measurements.filter(
    x =>
      x.type !== MeasurementType.DASH &&
      x.type !== MeasurementType.RAND36 &&
      x.type !== MeasurementType.DASS21
  );

  const dashMeasurements = measurements.filter(
    x => x.type === MeasurementType.DASH
  );

  const randMeasurements = measurements.filter(
    x => x.type === MeasurementType.RAND36
  );

  const dass21Measurements = measurements.filter(
    x => x.type === MeasurementType.DASS21
  );

  // Merge Dash together.
  if (dashMeasurements.length !== 0) {
    const dashEncounters = dashMeasurements.map(x => x.encounterId);
    const uniqueEncounterIds = dashEncounters.filter(
      (v, i, a) => a.indexOf(v) === i
    );

    uniqueEncounterIds.forEach(encounterId => {
      const encounterMeasurements = dashMeasurements.filter(
        x => x.encounterId === encounterId
      );

      const disabilityMeasurement = encounterMeasurements.find(
        x => x.summary === DASHModuleTypes.Disability
      );

      const workMeasurement = encounterMeasurements.find(
        x => x.summary === DASHModuleTypes.Work
      );

      const sportMeasurement = encounterMeasurements.find(
        x => x.summary === DASHModuleTypes.Sport
      );

      const alreadyUpdatedSummary = encounterMeasurements.find(
        x =>
          x.summary !== DASHModuleTypes.Disability &&
          x.summary !== DASHModuleTypes.Work &&
          x.summary !== DASHModuleTypes.Sport
      );

      const summary: string[] = [];
      if (disabilityMeasurement) {
        summary.push(`Disability = ${disabilityMeasurement!.value ?? 0}`);
      }

      if (workMeasurement) {
        summary.push(`Work = ${workMeasurement.value ?? 0}`);
      }

      if (sportMeasurement) {
        summary.push(`Sport/Music = ${sportMeasurement.value ?? 0}`);
      }

      if (alreadyUpdatedSummary) {
        otherMeasurements.push(alreadyUpdatedSummary);
      } else if (disabilityMeasurement) {
        const newMeasurement = new Measurement({
          ...disabilityMeasurement.dto,
          summary: summary.join(", ")
        });

        otherMeasurements.push(newMeasurement);
      }
    });
  }

  // Merge Dass together.
  if (dass21Measurements.length !== 0) {
    const dassEncounters = dass21Measurements.map(x => x.encounterId);
    const uniqueEncounterIds = dassEncounters.filter(
      (v, i, a) => a.indexOf(v) === i
    );

    uniqueEncounterIds.forEach(encounterId => {
      const encounterMeasurements = dass21Measurements.filter(
        x => x.encounterId === encounterId
      );

      const depressionMeasurement = encounterMeasurements.find(
        x => x.summary && x.summary.includes("Depression")
      );

      const alreadyUpdatedSummary = encounterMeasurements.find(
        x =>
          x.summary &&
          !x.summary.includes("Depression") &&
          !x.summary.includes("Anxiety") &&
          !x.summary.includes("Stress")
      );

      const summaries = [];

      const depression = encounterMeasurements.find(
        x => x.summary && x.summary.includes("Depression")
      );

      const anxiety = encounterMeasurements.find(
        x => x.summary && x.summary.includes("Anxiety")
      );

      const stress = encounterMeasurements.find(
        x => x.summary && x.summary.includes("Stress")
      );

      if (depression) summaries.push(depression);
      if (anxiety) summaries.push(anxiety);
      if (stress) summaries.push(stress);

      if (alreadyUpdatedSummary) {
        otherMeasurements.push(alreadyUpdatedSummary);
      } else if (depressionMeasurement) {
        const summary = summaries
          .map(x => {
            return `${x.summary} (${x.value})`;
          })
          .join("; ");

        const newMeasurement = new Measurement({
          ...depressionMeasurement.dto,
          summary
        });

        otherMeasurements.push(newMeasurement);
      }
    });
  }

  // Merge Rand together
  if (randMeasurements && randMeasurements.length !== 0) {
    const randEncounters = randMeasurements.map(x => x.encounterId);
    const uniqueEncounterIds = unique(randEncounters);

    uniqueEncounterIds.forEach(encounterId => {
      const encounterMeasurements = randMeasurements.filter(
        x => x.encounterId === encounterId
      );

      const physicalFunctioning = encounterMeasurements.find(
        x => x.summary === Rand36ReponseTypes.PhysicalFunctioning
      );

      // All entries have a recorded physical functioning.
      if (physicalFunctioning) {
        const newMeasurement = new Measurement({
          ...physicalFunctioning.dto,
          summary: "Outcome"
        });

        otherMeasurements.push(newMeasurement);
      }
    });
  }

  return otherMeasurements;
}
