import { observer } from "mobx-react-lite";

import {
  Callout,
  DirectionalHint,
  ICalloutProps,
  Link,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DiagnosisDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

type ConditionCalloutProps = Pick<ICalloutProps, "target" | "onDismiss"> & {
  primaryDiagnosisText: string;
  additionalDiagnoses: DiagnosisDataItemDto[];
  condition: ICondition | undefined;
};

export const ConditionCallout: React.FC<ConditionCalloutProps> = observer(
  ({
    target,
    onDismiss,
    primaryDiagnosisText,
    additionalDiagnoses,
    condition
  }) => {
    const listItems = additionalDiagnoses.map(d => (
      <li key={d.diagnosisCode?.code}>{d.diagnosisCode?.originalText}</li>
    ));

    const root = useStores();
    const { routing } = root;

    return (
      <Callout
        target={target}
        onDismiss={onDismiss}
        directionalHint={DirectionalHint.bottomLeftEdge}
        calloutWidth={343}
      >
        <Stack
          styles={{ root: { padding: "20px 24px" } }}
          tokens={{ childrenGap: 14 }}
        >
          <Text styles={{ root: { fontSize: 18 } }}>
            {primaryDiagnosisText}
          </Text>
          {listItems}
          {condition?.claim && (
            <Navigate
              styles={{ root: { fontSize: 12 } }}
              to={{
                pathname: routes.claims.management.edit.path({
                  id: condition?.claim.id
                })
              }}
              state={routing.getStateWithFromQuery()}
            >
              <Link>Go to claim summary page</Link>
            </Navigate>
          )}
        </Stack>
      </Callout>
    );
  }
);
