import React, { FC } from "react";

import { FontWeights, Stack, Text, useTheme } from "@bps/fluent-ui";

import { FormattedMeasurements } from "./generateMeasurementColumns.tsx";
import { EN_DASH, formatTimeWithoutSeconds } from "./utils.ts";

interface BloodPressureDetailsProps {
  formattedMeasurements: FormattedMeasurements;
}

export const BloodPressureDetails: FC<BloodPressureDetailsProps> = ({
  formattedMeasurements
}) => {
  const theme = useTheme();

  return (
    <Stack styles={{ root: { alignSelf: "flex-start" } }}>
      {Object.keys(formattedMeasurements).map(timestamp => {
        const measurement = formattedMeasurements[timestamp];

        return (
          <React.Fragment key={timestamp}>
            <Stack horizontal>
              <Stack>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold,
                      paddingRight: "5px",
                      color: theme.palette.neutralPrimary
                    }
                  }}
                >
                  {measurement.systolic}/{measurement.diastolic}
                </Text>
              </Stack>
              <Stack>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      color: theme.palette.neutralPrimaryAlt
                    }
                  }}
                >
                  {EN_DASH}
                  {formatTimeWithoutSeconds(timestamp)}
                </Text>
              </Stack>
            </Stack>
            {measurement.label && (
              <Stack>
                <Text
                  variant="xSmall"
                  styles={{
                    root: {
                      color: theme.palette.neutralPrimaryAlt,
                      paddingBottom: "10px"
                    }
                  }}
                >
                  {measurement.label}
                </Text>
              </Stack>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
