import { FC, PropsWithChildren, ReactNode } from "react";

import { CollapsibleCard, CollapsibleCardStyles } from "@bps/fluent-ui";

type BhbConfigCardProps = {
  heading: string;
  subHeading?: ReactNode | string;
  button?: ReactNode;
  styles?: Partial<CollapsibleCardStyles>;
  openOnRender?: boolean;
  onClick?: () => void;
} & PropsWithChildren;

export const BhbConfigCard: FC<BhbConfigCardProps> = ({
  children,
  heading,
  subHeading,
  button,
  styles,
  openOnRender,
  onClick
}) => {
  return (
    <CollapsibleCard
      openOnRender={openOnRender ?? true}
      headingLevel="section-heading"
      heading={heading}
      subHeading={subHeading}
      button={button}
      styles={styles}
      onClick={() => onClick && onClick()}
    >
      {children}
    </CollapsibleCard>
  );
};
