import { FunctionComponent, memo } from "react";

import { AccInvoicesList } from "@modules/billing/screens/acc-invoices/components/AccInvoicesList.tsx";
import { BillingScreenWrapper } from "@modules/billing/screens/shared-components/BillingScreenWrapper.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AccInvoicesListContext } from "./context/AccInvoicesListContext.ts";
import { AccInvoicesListHelper } from "./context/AccInvoicesListHelper.ts";

export const AccInvoicesScreen: FunctionComponent = memo(() => {
  const root = useStores();

  const fetch = async () => {
    const { defaultNzPublicInsurerId } =
      await root.practice.getDefaultInsurers();

    return defaultNzPublicInsurerId;
  };

  return (
    <DataFetcher<string> fetch={fetch} fallback={<BillingScreenWrapper />}>
      {defaultNzPublicInsurerId => (
        <AccInvoicesListContext.Provider
          value={new AccInvoicesListHelper(root, defaultNzPublicInsurerId)}
        >
          <AccInvoicesList />
        </AccInvoicesListContext.Provider>
      )}
    </DataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default AccInvoicesScreen;
