import { memo } from "react";

import { IColumn } from "@bps/fluent-ui";
import { InvoiceItemsTotal } from "@modules/billing/screens/invoice/components/InvoiceForm.types.tsx";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { AllocationListBase } from "../AllocationList/AllocationListBase.tsx";
import {
  FooterOptions,
  PaymentTotals,
  RenderItemProps,
  TotalField
} from "../AllocationList/AllocationListBase.types.tsx";
import {
  allocatedColumn,
  codeColumn,
  creditedColumn,
  descriptionColumn,
  feeColumn
} from "../AllocationList/utils.tsx";
import { AllocationFieldProps } from "./AllocationField.types.ts";
import { InvoiceNumberCell } from "./InvoiceNumberCell.tsx";
import { allocationFieldNameOf, AllocationFieldValues } from "./types.ts";
import {
  invoiceNumberColumn,
  owingColumn,
  patientNameColumn,
  providerNameColumn,
  serviceDateColumn
} from "./utils.tsx";

export interface AllocationFieldBaseProps
  extends Pick<AllocationFieldProps, "columnOptions"> {}

export const AllocationFieldBase: React.FC<AllocationFieldBaseProps> = memo(
  ({ columnOptions }): JSX.Element => {
    const {
      fields: { value: allocations }
    } = useFieldArray<AllocationFieldValues>(allocationNameOf("allocations"), {
      subscription: { value: true }
    });

    const getColumns = (renderItem: RenderItemProps): IColumn[] => {
      return [
        invoiceNumberColumn(
          (item: AllocationFieldValues, index: number) => (
            <InvoiceNumberCell
              item={item}
              itemIndex={index}
              renderItem={renderItem}
            />
          ),
          !columnOptions.filtersToShow?.invoice
        ),
        patientNameColumn(allocations),
        providerNameColumn(allocations),
        serviceDateColumn(),
        codeColumn(renderItem),
        descriptionColumn(renderItem),
        feeColumn(renderItem),
        allocatedColumn(
          renderItem,
          columnOptions.headingOptions?.allocationName
        ),
        creditedColumn(renderItem),
        owingColumn(
          { renderItem, name: allocationNameOf("allocations") },
          {
            nameOverride: columnOptions.headingOptions?.owingName,
            calculationOverride: columnOptions.calculationOptions?.owingCalc
          }
        )
      ].filter(
        c =>
          !(
            c.key === "providerName" &&
            !columnOptions.filtersToShow?.providerName
          ) &&
          !(
            c.key === "patientName" && !columnOptions.filtersToShow?.patientName
          ) &&
          !(
            c.key === allocationFieldNameOf("total") &&
            !columnOptions.filtersToShow?.total
          ) &&
          !(
            c.key === allocationFieldNameOf("owing") &&
            !columnOptions.filtersToShow?.owing
          ) &&
          !(c.key === "credit" && !columnOptions.filtersToShow?.credit)
      );
    };

    const getFooterTotalFields = (): TotalField[] => {
      return [
        {
          key: PaymentTotals.totalIncGst,
          title: InvoiceItemsTotal.totalIncGst,
          dataAttr: "billing-totals-footer-total-inc-gst"
        },
        {
          key: PaymentTotals.totalIncGst,
          title: "",
          dataAttr: "billing-totals-footer-total-inc-gst"
        },
        {
          key: PaymentTotals.owing,
          title: "",
          dataAttr: "billing-totals-header-total-inc-gst"
        }
      ];
    };

    const footer: FooterOptions = {
      fields: getFooterTotalFields()
    };

    return (
      <AllocationListBase
        name={allocationNameOf("allocations")}
        getColumns={getColumns}
        items={allocations}
        columnOptions={columnOptions}
        options={{
          footer
        }}
      />
    );
  }
);
