import { FeeType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  greaterThan,
  isDateBeforeField,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";

import { feeFormNameOf, FeeFormValues } from "../../ScheduleForm.types.tsx";

export class FeeFormNewFeeValidator extends Validator<FeeFormValues> {
  constructor(schedule?: Schedule) {
    super();

    const isNotDuplicateCode = (): FieldValidator => code => {
      return schedule?.services.some(
        x => x.code.toLocaleLowerCase() === code.toLocaleLowerCase()
      )
        ? ValidationMessages.duplicatedServiceCode
        : false;
    };

    const validFeeTypesForMaterials = [FeeType.FlatRate, FeeType.PerUnit];

    this.forField(feeFormNameOf("feeCode"), [
      required(),
      maxLength(30),
      isNotDuplicateCode()
    ]);

    this.forField(feeFormNameOf("feeName"), required());
    this.forField(feeFormNameOf("feeEffectiveDate"), [required()]);
    this.forField(
      feeFormNameOf("feeFee"),
      predicate(
        (_, values) => !values?.feeIsEditable,
        required(),
        greaterThan(0)
      )
    );

    this.forField(
      feeFormNameOf("feeNextEffectiveDate"),
      predicate(
        (value, values) => !values?.feeNextIsActive,
        isDateBeforeField(
          ValidationMessages.nextChangeSameOrAfterCurrentChange,
          "feeEffectiveDate",
          true
        )
      )
    );
    this.forField(
      feeFormNameOf("feeType"),
      predicate(
        (value, values) => !values?.feeIsService,
        value =>
          !validFeeTypesForMaterials.includes(value)
            ? ValidationMessages.invalidFeeTypeForMaterial
            : false
      )
    );
  }
}
