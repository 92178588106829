import { action, computed, observable } from "mobx";

import { IChoiceGroupOption } from "@bps/fluent-ui";
import { groupBy } from "@bps/utils";
import {
  BodyArea,
  BodyAreaClinicalDataItemDto,
  CentralNervousSystemClinicalDataDto,
  CentralNervousTest,
  ClinicalDataType,
  DermatomeDataItemDto,
  EncounterClinicalDataDto,
  ExaminationImageDto,
  ImageTypeCode,
  InjuryArea,
  InjuryAreaMotionAssessment,
  InjuryAreaSides,
  MyotomeDataItemDto,
  QuestionnaireItemType,
  ReflexDataItemDto,
  SideOfBody,
  SpecialTestAnswerConditionDto,
  SpecialTestDto,
  SpecialTestItemDto,
  SpecialTestResponseDto,
  SpecialTestResponseItem,
  SpecialTestTypeCode,
  StrengthSpecialTest
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { BodyExaminationState } from "@stores/clinical/models/clinical-tab/ClinicalTabState.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { CentralNervousSystemFormValues } from "../../../history-examinations/central-nervous-system/CentralNervousSystemForm.types.ts";
import {
  DermatomeFieldValues,
  DermatomesMyotomesAndReflexesFormValues,
  DermatomeTest,
  MyotomeFieldValues,
  ReflexFieldValues
} from "../../../history-examinations/dermatomes-and-myotomes/DermatomesAndMyotomesForm.types.ts";
import {
  backgroundImages,
  BackgroundImageTypes,
  BLACK,
  WHITE
} from "../../../notes/draw/constants.ts";
import {
  BrushKindEnum,
  CanvasImage,
  Options
} from "../../../notes/draw/DrawingCanvas.types.ts";
import { InjuryImagesHelper } from "../../../SOTAP/context/InjuryImagesHelper.tsx";
import { ImagePartProp } from "../../../SOTAP/context/InjuryImagesHelper.types.ts";
import { DEGREE_SYMBOL } from "../../../SOTAP/SOTAP.constants.ts";
import {
  BodyAreaCanvasImage,
  BodyExaminationFormValues,
  BodyPartImageProp,
  ExaminationCommentsType,
  ImageValue,
  SpecialTestResponseType
} from "../BodyExaminationForm.types.ts";
import {
  cleanExaminationComments,
  getFlattenedImageValues,
  getImagesDTO,
  hasQuestionConditionBeenMet,
  REFLEX_JAW_JERK_NERVE
} from "../utils.ts";

const {
  KneeFrontSide,
  KneeBackSide,
  KneeLeftSide,
  KneeRightSide,
  HandWrist,
  HipBackSide,
  HipFrontSide,
  HipLeftSide,
  HipRightSide,
  FullBodyBackSide,
  FullBodyFrontSide,
  FullBodyLeftSide,
  FullBodyRightSide,
  FootBack,
  FootBottom,
  FootFront,
  FootLeftInside,
  FootLeftOutside,
  FootRightInside,
  FootRightOutside,
  FootTop,
  ShoulderLeftBackSide,
  ShoulderLeftFrontSide,
  ShoulderLeftSide,
  ShoulderRightBackSide,
  ShoulderRightFrontSide,
  ShoulderRightSide,
  ElbowLeftSide,
  ElbowRightSide,
  SPIFrontSide,
  SPIBackSide,
  SPISide,
  DERMFront,
  DERMBack,
  DERMSpine,
  DERMFace,
  DERMSide,
  CentralNervousSystemCranial
} = ImageTypeCode;

const {
  Knee,
  AnkleFoot,
  Elbow,
  Hip,
  Shoulder,
  Spine,
  HandAndWrist,
  Body,
  Abdomen
} = BodyArea;

export class BodyExaminationHelper {
  constructor(
    private clinical: ClinicalStore,
    private clinicalRecord: ClinicalRecord
  ) {}

  canvases: fabric.Canvas[] = [];

  get core() {
    return this.clinical.root.core;
  }

  get notification() {
    return this.clinical.root.notification;
  }

  @computed
  private get bodyAreas() {
    return this.clinicalRecord.clinicalData?.bodyArea;
  }

  @computed
  get currentPatientRecordTab() {
    return this.clinical.ui.tabs.currentPatientRecordTab || { bodyExam: {} };
  }

  @computed
  get bodyExamSessionState(): BodyExaminationState {
    const { bodyExam } = this.currentPatientRecordTab;
    return bodyExam;
  }
  setBodyExamSessionState(bodyExamState: BodyExaminationState) {
    const { bodyExam = {} } = this.currentPatientRecordTab;
    this.currentPatientRecordTab.bodyExam = {
      ...bodyExam,
      ...bodyExamState
    };
  }

  @observable
  currentBodyArea?: BodyArea =
    this.bodyExamSessionState.currentBodyArea || undefined;

  @observable bodyParts: BodyArea[] = this.bodyExamSessionState.bodyParts || [];

  @action
  setBodyParts = (values: BodyArea[]) => {
    this.setBodyExamSessionState({ bodyParts: [...values] });
    this.bodyParts = [...values];
    if (
      !this.currentBodyArea ||
      (this.currentBodyArea && !values.includes(this.currentBodyArea))
    ) {
      if (values.length > 0) {
        this.setCurrentBodyArea(values[0]);
      } else {
        this.setCurrentBodyArea(undefined);
      }
    }
  };

  @action
  setSelectedBodyParts = (values: BodyArea[]) => {
    this.setBodyExamSessionState({ selectedBodyParts: [...values] });
  };

  @action
  setCurrentBodyArea = (value?: BodyArea) => {
    this.setBodyExamSessionState({ currentBodyArea: value });
    this.currentBodyArea = value;
  };

  @observable
  showDiagramEditorArea: boolean =
    this.bodyExamSessionState.showDiagramEditorArea || false;

  @observable
  brush: Options = this.bodyExamSessionState.brush || {
    kind: BrushKindEnum.Pencil,
    width: 2,
    color: BLACK,
    fill: WHITE
  };

  @observable
  currentCanvas?: fabric.Canvas = undefined;

  @observable
  isDrawingMode: boolean = true;

  @observable
  activeObjects: fabric.Object[] | null = null;

  @observable
  images: BodyAreaCanvasImage[] = [];

  @action
  setActiveObjects = (value: fabric.Object[] | null) => {
    this.activeObjects = value;
  };

  @action
  setIsDrawingMode = (value: boolean) => {
    this.isDrawingMode = value;
  };

  @action
  switchCanvas = (value?: fabric.Canvas) => {
    this.currentCanvas = value;
  };

  @action
  setShowDiagramEditorArea = (value: boolean) => {
    this.setBodyExamSessionState({ showDiagramEditorArea: value });
    this.showDiagramEditorArea = value;
  };

  @action
  setBrush = (option: Options) => {
    this.setBodyExamSessionState({ brush: { ...option } });
    this.brush = { ...option };
  };

  @action
  setImages = (imageValue: ImageValue, sex?: Sex) => {
    let imageProps: ImagePartProp[] = [];

    const imageProperties: BodyPartImageProp = {
      KNE: InjuryImagesHelper.getKneeProperties(sex, {
        front: KneeFrontSide,
        back: KneeBackSide,
        leftSide: KneeLeftSide,
        rightSide: KneeRightSide
      }),
      HAN: [
        {
          formValue: HandWrist,
          imageType: "",
          backGroundImageType: BackgroundImageTypes.HandsWrists,
          bodyArea: BodyArea.HandAndWrist
        }
      ],
      HIP: InjuryImagesHelper.getHipProperties(sex, {
        front: HipFrontSide,
        back: HipBackSide,
        leftSide: HipLeftSide,
        rightSide: HipRightSide
      }),
      FB: InjuryImagesHelper.getFullBodyProperties(sex, {
        front: FullBodyFrontSide,
        back: FullBodyBackSide,
        leftSide: FullBodyLeftSide,
        rightSide: FullBodyRightSide
      }),
      FOO: InjuryImagesHelper.footProperties({
        front: FootFront,
        back: FootBack,
        top: FootTop,
        bottom: FootBottom,
        leftInside: FootLeftInside,
        leftOutside: FootLeftOutside,
        rightInside: FootRightInside,
        rightOutside: FootRightOutside
      }),
      SHO: InjuryImagesHelper.getShoulderProperties(sex, {
        leftBack: ShoulderLeftBackSide,
        leftFront: ShoulderLeftFrontSide,
        leftSide: ShoulderLeftSide,
        rightBack: ShoulderRightBackSide,
        rightFront: ShoulderRightFrontSide,
        rightSide: ShoulderRightSide
      }),
      ELB: InjuryImagesHelper.getElbowProperties(sex, {
        leftSide: ElbowLeftSide,
        rightSide: ElbowRightSide
      }),
      SPI: InjuryImagesHelper.spinalCordProperties({
        side: SPISide,
        front: SPIFrontSide,
        back: SPIBackSide
      }),
      ABD: InjuryImagesHelper.getAbdomenProperties(sex, {
        front: Abdomen
      }),
      DAM: InjuryImagesHelper.getDermatomesProperties({
        front: DERMFront,
        back: DERMBack,
        top: DERMSpine,
        bottom: DERMFace,
        side: DERMSide
      }),
      CNSC: InjuryImagesHelper.getCrainialProperties({
        front: CentralNervousSystemCranial
      })
    };

    this.bodyParts.forEach(bodyPart => {
      imageProps = imageProps.concat([...(imageProperties[bodyPart] || [])]);
    });

    this.images = this.getImages(imageProps, imageValue);
  };

  getSpecialTest = (code: string) => {
    return this.clinical.specialTestDataMap.get(ClinicalDataType[code]);
  };

  getStrengthTest = (code: string) => {
    return this.clinical.specialTestDataMap.get(StrengthSpecialTest[code]);
  };

  getCentralNervousTest = (code: string) => {
    return this.clinical.specialTestDataMap.get(CentralNervousTest[code]);
  };

  setTestResponseItems = (
    specialTestResponse: SpecialTestResponseDto,
    specialTestResponseItems: SpecialTestResponseType,
    bodyArea: string
  ) => {
    if (specialTestResponse && specialTestResponse.items.length > 0) {
      if (specialTestResponse.items.find(x => x.side)) {
        const leftResponseValues: string[] = [];
        const rightResponseValues: string[] = [];

        specialTestResponse?.items.forEach(item => {
          if (item.side === "L") {
            leftResponseValues[item.specialTestItemId - 1] = item.value;
          } else {
            rightResponseValues[item.specialTestItemId - 1] = item.value;
          }
        });
        specialTestResponseItems[`${SideOfBody.Left}${bodyArea}`] =
          leftResponseValues;
        specialTestResponseItems[`${SideOfBody.Right}${bodyArea}`] =
          rightResponseValues;
      } else {
        const responseValues: string[] | string[][] = [];
        specialTestResponse?.items.forEach(item => {
          if (item.specialTestItemType === QuestionnaireItemType.MultiChoice) {
            if (item.value.includes(",")) {
              const splitValues = item.value.split(",");
              responseValues[item.specialTestItemId - 1] = splitValues;
            } else {
              const isMultiSelect =
                bodyArea === BodyArea.Spine &&
                specialTestResponse.specialTestId === "1";

              responseValues[item.specialTestItemId - 1] = isMultiSelect
                ? [item.value]
                : item.value;
            }
          } else {
            responseValues[item.specialTestItemId - 1] = item.value;
          }
        });
        specialTestResponseItems[bodyArea] = responseValues;
      }
    }
  };

  get initialValues(): BodyExaminationFormValues {
    const stashedData = this.clinicalRecord.stashedClinicalData?.bodyArea;

    const images: ExaminationImageDto[] = [];
    const examinationComments: ExaminationCommentsType = {};
    const specialTestResponseItems: SpecialTestResponseType = {};
    const strengthTestResponseItems: SpecialTestResponseType = {};
    const existingBodyAreas: string[] = [];

    if (
      stashedData &&
      stashedData.bodyAreasData &&
      stashedData.bodyAreasData.length > 0
    ) {
      stashedData.bodyAreasData.forEach(area => {
        existingBodyAreas.push(area.bodyArea);
        images.push(...area.images);
        if (area.comments && area.comments.length > 0) {
          examinationComments[area.bodyArea] = area.comments;
        } else {
          examinationComments[area.bodyArea] = [
            { title: undefined, comment: undefined }
          ];
        }

        if (
          area.specialTestResponse &&
          area.specialTestResponse.items.length > 0
        ) {
          this.setTestResponseItems(
            area.specialTestResponse,
            specialTestResponseItems,
            area.bodyArea
          );
        }

        if (
          area.strengthTestResponse &&
          area.strengthTestResponse.items.length > 0
        ) {
          this.setTestResponseItems(
            area.strengthTestResponse,
            strengthTestResponseItems,
            area.bodyArea
          );
        }
      });
    }

    const bodyParts = Object.values(BodyArea);

    bodyParts.forEach(bodyPart => {
      if (!existingBodyAreas.includes(bodyPart)) {
        examinationComments[bodyPart] = [
          { title: undefined, comment: undefined }
        ];
      }
    });

    const flattenedImageValue = getFlattenedImageValues(images);
    const bodyAreasData = stashedData?.bodyAreasData ?? [];
    const injuryAreaMotionAssessments =
      this.getInjuryAreaMotionAssessmentItems(bodyAreasData);

    return {
      imageValue: { ...flattenedImageValue },
      examinationComments,
      injuryAreaMotionAssessments,
      specialTestResponseItems,
      strengthTestResponseItems
    };
  }

  public getDefaultMotionTypes = (group: string) => {
    const allMotionTypes = Array.from(this.clinical.ref.motionTypes.values);
    return allMotionTypes
      .filter(type => type.injuryAreaMotionTypeGroups.includes(group))
      .map(type => ({
        code: type.code,
        active: `0${DEGREE_SYMBOL}`,
        passive: `0${DEGREE_SYMBOL}`
      }));
  };

  public getDefaultInjuryAreaMotionAssessmentItem = (
    bodyArea: string,
    group: string
  ) => {
    return {
      injuryArea: bodyArea,
      injuryAreaGroup: group,
      motionTypes: this.getDefaultMotionTypes(group)
    };
  };

  public getInjuryAreaMotionAssessmentItems = (
    bodyAreas: BodyAreaClinicalDataItemDto[]
  ): InjuryAreaMotionAssessmentItem[] => {
    const allBodyAreasGroups = Array.from(
      this.clinical.ref.injuryAreaMotionTypeGroups.values
    );

    return allBodyAreasGroups.reduce(
      (assessments: InjuryAreaMotionAssessmentItem[], group) => {
        const newInjuryAreaMotionAssessmentItem =
          this.getDefaultInjuryAreaMotionAssessmentItem(
            group.injuryAreas,
            group.code
          );

        const dtoBodyArea = bodyAreas.find(
          area =>
            area.bodyArea === group.injuryAreas &&
            area.injuryAreaMotionAssessments?.some(
              i => i.injuryAreaMotionTypeGroup === group.code
            )
        );

        if (!dtoBodyArea?.injuryAreaMotionAssessments?.length) {
          if (group.injuryAreas === BodyArea.Spine) {
            return [...assessments, newInjuryAreaMotionAssessmentItem];
          } else {
            return [
              ...assessments,
              ...[
                {
                  ...newInjuryAreaMotionAssessmentItem,
                  injurySide: InjuryAreaSides.Left
                },
                {
                  ...newInjuryAreaMotionAssessmentItem,
                  injurySide: InjuryAreaSides.Right
                }
              ]
            ];
          }
        }

        const groupAssessments: InjuryAreaMotionAssessmentItem[] =
          dtoBodyArea.injuryAreaMotionAssessments?.map(
            (motion: InjuryAreaMotionAssessment) => {
              return {
                injuryArea: group.injuryAreas,
                injuryAreaGroup: motion.injuryAreaMotionTypeGroup,
                motionTypes: motion.motionAssessments?.map(m => ({
                  code: m.motionType ?? "",
                  active: `${m.active}${DEGREE_SYMBOL}`,
                  passive: `${m.passive}${DEGREE_SYMBOL}`
                })),
                injurySide: motion.side,
                palpation: motion.palpation,
                strength: motion.strength,
                specialTests: motion.specialTests
              };
            }
          );

        // Check if injuryAreaMotionAssessments has missing Left or Right sides. Not for Spine.
        if (
          (dtoBodyArea.bodyArea !== BodyArea.Spine &&
            dtoBodyArea.injuryAreaMotionAssessments.length === 1) ||
          (dtoBodyArea.bodyArea === BodyArea.HandAndWrist &&
            dtoBodyArea.injuryAreaMotionAssessments.length === 3)
        ) {
          const injurySide =
            dtoBodyArea.injuryAreaMotionAssessments[0].side ===
            InjuryAreaSides.Right
              ? InjuryAreaSides.Left
              : InjuryAreaSides.Right;

          groupAssessments.push({
            injuryArea: group.injuryAreas,
            injuryAreaGroup: group.code,
            motionTypes: this.getDefaultMotionTypes(group.code),
            injurySide
          });
        }

        const currentGroupAssessment = groupAssessments.filter(
          g =>
            g.injuryArea === group.injuryAreas &&
            g.injuryAreaGroup === group.code
        );

        return [...assessments, ...currentGroupAssessment];
      },
      []
    );
  };

  getBodyParts(imageValue: ImageValue): BodyArea[] {
    const {
      FBFS,
      FBBS,
      FBLS,
      FBRS,
      KNBS,
      KNFS,
      KNLS,
      KNRS,
      FOOTBOT,
      FOOTBS,
      FOOTFS,
      FOOTLI,
      FOOTLO,
      FOOTRI,
      FOOTRO,
      FOOTTOP,
      ELBLS,
      ELBRS,
      HIPBS,
      HIPFS,
      HIPLS,
      HIPRS,
      SHOULLBS,
      SHOULLFS,
      SHOULLS,
      SHOULRBS,
      SHOULRFS,
      SHOULRS,
      SPIFS,
      SPIS,
      SPIBS,
      HANW,
      ABD
    } = imageValue;

    const bodyGroup: { [key in BodyArea]?: boolean } = {
      [Body]: !!FBFS || !!FBBS || !!FBLS || !!FBRS,
      [Knee]: !!KNFS || !!KNBS || !!KNLS || !!KNRS,
      [AnkleFoot]:
        !!FOOTTOP ||
        !!FOOTBOT ||
        !!FOOTBS ||
        !!FOOTFS ||
        !!FOOTLI ||
        !!FOOTLO ||
        !!FOOTRI ||
        !!FOOTRO,
      [Elbow]: !!ELBLS || !!ELBRS,
      [Hip]: !!HIPBS || !!HIPFS || !!HIPLS || !!HIPRS,
      [Shoulder]:
        !!SHOULLBS ||
        !!SHOULLFS ||
        !!SHOULLS ||
        !!SHOULRBS ||
        !!SHOULRFS ||
        !!SHOULRS,
      [Spine]: !!SPIFS || !!SPIS || !!SPIBS,
      [HandAndWrist]: !!HANW,
      [Abdomen]: !!ABD
    };

    return Object.entries(bodyGroup)
      .filter((entry: [BodyArea, boolean]) => entry[1])
      .map((entry: [BodyArea, boolean]) => entry[0]);
  }

  private getImages<T>(
    imageProps: ImagePartProp[],
    formValues: T
  ): CanvasImage[] {
    const images: BodyAreaCanvasImage[] = [];
    imageProps.forEach(imageProp => {
      const image = backgroundImages.find(
        backgroundImage =>
          backgroundImage.type === imageProp.backGroundImageType
      );
      if (image) {
        const { formValue: fieldName } = imageProp;
        images.push({
          canvasRef: () => {},
          backgroundImageUrl: image?.src,
          width: image?.width,
          height: 535,
          text: image.commonName,
          initialValue: formValues && formValues[fieldName],
          id: fieldName,
          bodyArea: imageProp.bodyArea
        });
      }
    });
    return images;
  }

  getThumbnails = (backgroundColor: string) => {
    const thumbnails: IChoiceGroupOption[] = this.images.map(
      (image, index) => ({
        key: image.id?.toString() || index.toString(),
        id: image.id,
        selectedImageSrc: image.backgroundImageUrl,
        imageSrc: image.backgroundImageUrl,
        text: image.text || "",
        imageSize: { width: 96, height: 96 },
        bodyArea: image.bodyArea,
        styles: {
          root: { backgroundColor },
          innerField: { padding: 2 },
          field: {
            padding: 2,
            "&:before": { zIndex: 100 },
            "&:after": { zIndex: 100 }
          },
          labelWrapper: { maxWidth: 70, height: 0 }
        }
      })
    );
    return thumbnails;
  };

  isCanvasExist = (id: string) => {
    return !!this.canvases.find(c => c.getElement().id === id);
  };

  public getStashedClinicalData = (
    values: BodyExaminationFormValues
  ): Partial<EncounterClinicalDataDto> => {
    const bodyAreaClinicalData = this.clinicalRecord.clinicalData?.bodyArea;

    const bodyAreaClinicalDataConfirmed =
      this.clinicalRecord?.clinicalData?.bodyAreaConfirmed;

    const bodyAreasData: BodyAreaClinicalDataItemDto[] = [];
    let bodyAreas: BodyArea[] = [];
    let cleanComments: ExaminationCommentsType = {};

    if (values.imageValue) {
      // get the bodyAreas from the images
      bodyAreas = this.getBodyParts(values.imageValue);
    }

    if (values.examinationComments) {
      //removes all empty comments
      cleanComments = cleanExaminationComments(values.examinationComments);
      const examinationCommentKeys = Object.keys(cleanComments);
      examinationCommentKeys.forEach(key => {
        if (!bodyAreas.includes(key as BodyArea)) {
          bodyAreas.push(key as BodyArea);
        }
      });
    }

    if (values.injuryAreaMotionAssessments?.length) {
      values.injuryAreaMotionAssessments.forEach(assessment => {
        const bodyArea = assessment.injuryArea as BodyArea;
        if (!bodyAreas.includes(bodyArea)) {
          bodyAreas.push(bodyArea);
        }
      });
    }

    if (values.specialTestResponseItems) {
      const specialTestResponseKeys = Object.keys(
        values.specialTestResponseItems
      );
      specialTestResponseKeys.forEach(key => {
        if (!bodyAreas.includes(key as BodyArea)) {
          bodyAreas.push(key as BodyArea);
        }
      });
    }

    if (values.strengthTestResponseItems) {
      const strengthTestResponseKeys = Object.keys(
        values.strengthTestResponseItems
      );
      strengthTestResponseKeys.forEach(key => {
        if (!bodyAreas.includes(key as BodyArea)) {
          bodyAreas.push(key as BodyArea);
        }
      });
    }

    bodyAreas.forEach(bodyArea => {
      const bodyAreaImages: ExaminationImageDto[] = [];

      if (values.imageValue) {
        Object.entries(values.imageValue).forEach(
          (fieldValue: [ImageTypeCode, string]) => {
            const [key, value] = fieldValue;
            const imageValue: ImageValue = {};
            imageValue[key] = value;
            const currentBodyArea = this.getBodyParts(imageValue);
            if (bodyArea === currentBodyArea[0]) {
              bodyAreaImages.push(...getImagesDTO(imageValue));
            }
          }
        );
      }

      const currentBodyArea = bodyArea.includes(BodyArea.HandAndWrist)
        ? BodyArea.HandAndWrist
        : bodyArea;

      //create a clinicalDataItem per body area, we now have this grouping
      // as we have a lot of new things coming up that are linked to the group of images.
      const bodyAreaExamination: BodyAreaClinicalDataItemDto = {
        images: bodyAreaImages,
        comments: cleanComments[bodyArea],
        bodyArea: currentBodyArea
      };

      const specialTest = this.getSpecialTest(currentBodyArea);
      const strengthTest = this.getStrengthTest(currentBodyArea);

      if (specialTest) {
        const specialTestResponseItems = this.getTestResponseDto(
          specialTest,
          values,
          bodyArea
        );
        if (specialTestResponseItems && specialTestResponseItems.length > 0) {
          const specialTestResponse: SpecialTestResponseDto = {
            specialTestId: specialTest.id,
            specialTestCode: specialTest.code,
            items: specialTestResponseItems
          };
          bodyAreaExamination.specialTestResponse = specialTestResponse;
        }
      }

      if (strengthTest) {
        const strengthTestResponseItems = this.getTestResponseDto(
          strengthTest,
          values,
          bodyArea
        );

        if (strengthTestResponseItems && strengthTestResponseItems.length > 0) {
          const strengthTestResponse: SpecialTestResponseDto = {
            specialTestId: strengthTest.id,
            specialTestCode: strengthTest.code,
            items: strengthTestResponseItems
          };
          bodyAreaExamination.strengthTestResponse = strengthTestResponse;
        }
      }

      // injuryAreaMotionAssessments is under feature toggle
      const injuryAreaAssessments =
        InjuryImagesHelper.getMappedInjuryAreaAssessments(
          bodyAreas as unknown as InjuryArea[],
          values.injuryAreaMotionAssessments
        ).find(
          i =>
            i.injuryArea && i.injuryArea === (bodyArea as unknown as InjuryArea)
        );
      bodyAreaExamination.injuryAreaMotionAssessments =
        injuryAreaAssessments?.injuryAreaMotionAssessments;
      if (Object.values(BodyArea).includes(bodyArea as BodyArea))
        bodyAreasData.push(bodyAreaExamination);
    });

    return {
      bodyArea: {
        eTag: bodyAreaClinicalData?.eTag,
        bodyAreasData
      },
      bodyAreaConfirmed: {
        eTag: bodyAreaClinicalDataConfirmed?.eTag,
        confirmed: true
      }
    };
  };

  private getTestResponseDto = (
    specialTest: SpecialTestDto,
    values: BodyExaminationFormValues,
    bodyArea: BodyArea
  ) => {
    const specialTestResponseItems: SpecialTestResponseItem[] = [];
    const isStrength = specialTest.typeCode === SpecialTestTypeCode.Strength;
    const testResponseItems = isStrength
      ? values.strengthTestResponseItems
      : values.specialTestResponseItems;

    if (!testResponseItems) {
      return;
    }

    if (specialTest.hasSide) {
      [SideOfBody.Left, SideOfBody.Right].forEach(side => {
        testResponseItems[`${side}${bodyArea}`]?.forEach(
          (responseItem: string[] | string, index: number) => {
            const responseItemValue = responseItem?.toString();
            if (responseItemValue && responseItemValue.length > 0) {
              specialTestResponseItems.push({
                specialTestItemId: specialTest.items[index].id,
                specialTestItemType: specialTest.items[index].type,
                side,
                value: responseItemValue
              });
            }
          }
        );
      });
    } else {
      testResponseItems[bodyArea]?.forEach(
        (responseItem: string[] | string, index: number) => {
          const responseItemValue = responseItem?.toString();
          if (responseItemValue && responseItemValue.length > 0) {
            specialTestResponseItems.push({
              specialTestItemId: specialTest.items[index].id,
              specialTestItemType: specialTest.items[index].type,
              value: responseItemValue
            });
          }
        }
      );
    }

    return specialTestResponseItems;
  };

  public submitData = async (values: BodyExaminationFormValues) => {
    const clinicalData = this.getStashedClinicalData(values);
    await this.clinicalRecord.saveClinicalData(clinicalData);
  };

  public onCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.BodyExam
    );
    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "bodyArea",
      "bodyAreaConfirmed"
    ]);
  };

  getEstimationSortedDermatomes = (
    dermatomes: DermatomeDataItemDto[],
    testType: DermatomeTest
  ) => {
    const estimations = this.clinical.ref.dermatomeEstimations.keyTextValues;
    const sortedDermatomes: DermatomeFieldValues[] = [];
    estimations.forEach(estimation => {
      const estimationDermatomes = dermatomes.filter(
        x => x.estimation === estimation.key
      );
      if (estimationDermatomes && estimationDermatomes.length > 0) {
        const nerves: string[] = [];
        estimationDermatomes.forEach(x => {
          nerves.push(x.nerve);
          if (x.side) {
            nerves.push(`${x.nerve}-${x.side}`);
          }
        });

        const distinctNerves = [...Array.from(new Set(nerves))];

        const dermatome: DermatomeFieldValues = {
          nerves: distinctNerves,
          hasComment: !!estimationDermatomes[0].comment,
          comment: estimationDermatomes[0].comment,
          estimation: estimation.key,
          testType
        };

        sortedDermatomes.push(dermatome);
      }
    });

    return sortedDermatomes;
  };

  getTestSortedDermatomes = (dermatomes: DermatomeDataItemDto[]) => {
    let sortedDermatomes: DermatomeFieldValues[] = [];
    Object.keys(DermatomeTest).forEach(key => {
      const testDerms = dermatomes.filter(
        x => x.testType === DermatomeTest[key]
      );

      const testDermatomes = this.getEstimationSortedDermatomes(
        testDerms,
        DermatomeTest[key]
      );
      if (testDermatomes && testDermatomes.length > 0) {
        sortedDermatomes = sortedDermatomes.concat(testDermatomes);
      } else {
        sortedDermatomes.push({
          testType: DermatomeTest[key]
        });
      }
    });
    return sortedDermatomes;
  };

  get dmrInitialValues(): DermatomesMyotomesAndReflexesFormValues {
    const stashedData =
      this.clinicalRecord.stashedClinicalData?.dermatomesAndMyotomes;

    const images: ExaminationImageDto[] = [];
    const examinationComments: ExaminationCommentsType = {};

    let myotomes: string[] = [];
    const myotomeFields: MyotomeFieldValues[] = [];
    let trigeminalDermatomes: DermatomeFieldValues[] = [
      { testType: DermatomeTest.LightTouch },
      { testType: DermatomeTest.Pinprick }
    ];
    let cervicalDermatomes: DermatomeFieldValues[] = [
      { testType: DermatomeTest.LightTouch },
      { testType: DermatomeTest.Pinprick }
    ];
    let thoracicDermatomes: DermatomeFieldValues[] = [
      { testType: DermatomeTest.LightTouch },
      { testType: DermatomeTest.Pinprick }
    ];
    let lumbosacralDermatomes: DermatomeFieldValues[] = [
      { testType: DermatomeTest.LightTouch },
      { testType: DermatomeTest.Pinprick }
    ];

    if (stashedData && stashedData.images && stashedData.images.length > 0) {
      images.push(...stashedData.images);
    }

    if (
      stashedData &&
      stashedData.comments &&
      stashedData.comments.length > 0
    ) {
      examinationComments[BodyArea.DermatomesAndMyotomes] =
        stashedData.comments;
    } else {
      examinationComments[BodyArea.DermatomesAndMyotomes] = [
        { title: undefined, comment: undefined }
      ];
    }

    if (
      stashedData &&
      stashedData.myotomes &&
      stashedData.myotomes.length > 0
    ) {
      myotomes = stashedData.myotomes.map(x => x.nerve);

      stashedData.myotomes.forEach(x => {
        if (
          stashedData.myotomes &&
          stashedData.myotomes.filter(y => y.nerve === x.nerve).length === 2
        ) {
          // Add the item like normally, we've got both sides.
          myotomeFields.push({
            nerve: x.nerve,
            strength: x.strength,
            side: x.side,
            checked: true
          });
        } else {
          // It appears we've only got one entry of a saved item!.

          const leftSided = x.side === SideOfBody.Left;

          // Push both sides, ensuring we're placing them in the right order.
          myotomeFields.push({
            nerve: x.nerve,
            strength: leftSided ? x.strength : undefined,
            side: SideOfBody.Left,
            checked: true
          });

          myotomeFields.push({
            nerve: x.nerve,
            strength: leftSided ? undefined : x.strength,
            side: SideOfBody.Right,
            checked: true
          });
        }
      });
    }

    // Add fields for both left and right based on the nerve keytextValues.
    let reflexFields: ReflexFieldValues[] = [];
    this.clinical.ref.reflexNerves.keyTextValues.forEach(x => {
      // Trigeminal isn't sided.
      if (x.key === REFLEX_JAW_JERK_NERVE) {
        reflexFields.push({
          nerve: x.key,
          strength: undefined,
          checked: false
        });
      } else {
        reflexFields.push({
          nerve: x.key,
          strength: undefined,
          side: "L",
          checked: false
        });

        reflexFields.push({
          nerve: x.key,
          strength: undefined,
          side: "R",
          checked: false
        });
      }
    });

    if (
      stashedData &&
      stashedData.reflexes &&
      stashedData.reflexes.length > 0
    ) {
      reflexFields = reflexFields.map(defaultField => {
        const stashedField = stashedData.reflexes!.find(
          x => x.nerve === defaultField.nerve && x.side === defaultField.side
        );

        if (stashedField) {
          return {
            nerve: stashedField.nerve,
            strength: stashedField.strength,
            side: stashedField.side,
            checked: stashedField.strength !== undefined
          };
        }
        return defaultField;
      });
    }

    if (
      stashedData &&
      stashedData.dermatomes &&
      stashedData.dermatomes.length > 0
    ) {
      const trigeminal = this.clinical.ref.nerves.values
        .filter(x => x.trigeminal)
        .map(x => x.code);

      trigeminalDermatomes = this.getTestSortedDermatomes(
        stashedData.dermatomes.filter(x => trigeminal.includes(x.nerve))
      );

      const cervical = this.clinical.ref.nerves.values
        .filter(x => x.cervical)
        .map(x => x.code);

      cervicalDermatomes = this.getTestSortedDermatomes(
        stashedData.dermatomes.filter(x => cervical.includes(x.nerve))
      );

      const thoracic = this.clinical.ref.nerves.values
        .filter(x => x.thoracic)
        .map(x => x.code);

      thoracicDermatomes = this.getTestSortedDermatomes(
        stashedData.dermatomes.filter(x => thoracic.includes(x.nerve))
      );

      const lumbosacral = this.clinical.ref.nerves.values
        .filter(x => x.lumbosacral)
        .map(x => x.code);

      lumbosacralDermatomes = this.getTestSortedDermatomes(
        stashedData.dermatomes.filter(x => lumbosacral.includes(x.nerve))
      );
    }

    const flattenedImageValue = getFlattenedImageValues(images);
    return {
      imageValue: { ...flattenedImageValue },
      examinationComments,
      myotomes,
      myotomeFields,
      reflexFields,
      trigeminalDermatomes,
      cervicalDermatomes,
      thoracicDermatomes,
      lumbosacralDermatomes
    };
  }
  dmrOnCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.DermatomesAndMyotomes
    );

    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "dermatomesAndMyotomes"
    ]);
  };

  dmrSubmitData = async (values: DermatomesMyotomesAndReflexesFormValues) => {
    const bodyArea = this.getDMRStashedClinicalData(values);

    await this.clinicalRecord.saveClinicalData(bodyArea);
  };

  cleanReflexes = (reflexes: ReflexDataItemDto[]) => {
    return reflexes.filter(
      p => p.strength !== null && p.strength !== undefined
    );
  };

  cleanDermatomeValues = (values: DermatomeFieldValues[]) => {
    let dermatomes: DermatomeDataItemDto[] = [];
    const onlyfilled = values.filter(v => v.nerves && v.estimation);
    onlyfilled.forEach(estimation => {
      if (estimation.nerves) {
        let nerveList = estimation.nerves;
        //removes the double up of nerves and nerve-side
        estimation.nerves.forEach(x => {
          const splitKey = x.split("-");
          if (splitKey.length === 2) {
            nerveList = nerveList.filter(x => x !== splitKey[0]);
          }
        });

        const newDermatomes = nerveList.map(x => {
          const splitKey = x.split("-");
          if (splitKey.length === 2) {
            return {
              nerve: splitKey[0],
              side: splitKey[1],
              estimation: estimation && estimation.estimation,
              testType: estimation.testType,
              comment: estimation.comment
            };
          } else {
            return {
              nerve: x,
              side: undefined,
              estimation: estimation && estimation.estimation,
              testType: estimation.testType,
              comment: estimation.comment
            };
          }
        });

        dermatomes = dermatomes.concat(newDermatomes);
      }
    });
    return dermatomes;
  };

  cleanMyotomesValues = (values: MyotomeDataItemDto[]) => {
    return values.filter(value => value.strength || value.strength === 0);
  };

  getDMRStashedClinicalData = (
    values: DermatomesMyotomesAndReflexesFormValues
  ): Partial<EncounterClinicalDataDto> => {
    const dermatomesAndMyotomes =
      this.clinicalRecord.clinicalData?.dermatomesAndMyotomes;

    const dermatomesAndMyotomesConfirmed =
      this.clinicalRecord.clinicalData?.dermatomesAndMyotomesConfirmed;

    let cleanMyotomes: MyotomeDataItemDto[] = [];
    let cleanComments: ExaminationCommentsType = {};
    const images: ExaminationImageDto[] = [];
    let dermatomes: DermatomeDataItemDto[] = [];

    if (values.examinationComments) {
      //removes all empty comments
      cleanComments = cleanExaminationComments(values.examinationComments);
    }

    if (values.myotomeFields) {
      cleanMyotomes = this.cleanMyotomesValues(values.myotomeFields);
    }

    if (
      values.trigeminalDermatomes ||
      values.cervicalDermatomes ||
      values.thoracicDermatomes ||
      values.lumbosacralDermatomes
    ) {
      if (values.trigeminalDermatomes) {
        const trigeminalDermatomes = this.cleanDermatomeValues(
          values.trigeminalDermatomes
        );

        if (trigeminalDermatomes && trigeminalDermatomes.length > 0) {
          dermatomes = dermatomes.concat(trigeminalDermatomes);
        }
      }

      if (values.cervicalDermatomes) {
        const cervicalDermatomes = this.cleanDermatomeValues(
          values.cervicalDermatomes
        );

        if (cervicalDermatomes && cervicalDermatomes.length > 0) {
          dermatomes = dermatomes.concat(cervicalDermatomes);
        }
      }

      if (values.thoracicDermatomes) {
        const thoracicDermatomes = this.cleanDermatomeValues(
          values.thoracicDermatomes
        );

        if (thoracicDermatomes && thoracicDermatomes.length > 0) {
          dermatomes = dermatomes.concat(thoracicDermatomes);
        }
      }

      if (values.lumbosacralDermatomes) {
        const lumbosacralDermatomes = this.cleanDermatomeValues(
          values.lumbosacralDermatomes
        );

        if (lumbosacralDermatomes && lumbosacralDermatomes.length > 0) {
          dermatomes = dermatomes.concat(lumbosacralDermatomes);
        }
      }
    }

    if (values.imageValue) {
      Object.entries(values.imageValue).forEach(
        (fieldValue: [ImageTypeCode, string]) => {
          const [key, value] = fieldValue;
          const imageValue: ImageValue = {};
          imageValue[key] = value;

          images.push(...getImagesDTO(imageValue));
        }
      );
    }

    const cleanedReflexes = this.cleanReflexes(values.reflexFields || []);
    return {
      dermatomesAndMyotomes: {
        eTag: dermatomesAndMyotomes?.eTag,
        images,
        comments: cleanComments[BodyArea.DermatomesAndMyotomes],
        myotomes: cleanMyotomes,
        reflexes: cleanedReflexes,
        dermatomes
      },
      dermatomesAndMyotomesConfirmed: {
        eTag: dermatomesAndMyotomesConfirmed?.eTag,
        confirmed: true
      }
    };
  };

  sortSpecialTestItemsByHeading = (specialTestItems: SpecialTestItemDto[]) => {
    const groupedItems = groupBy(specialTestItems, item => item.heading).map(
      item => item[1]
    );
    return groupedItems;
  };

  hasSpecialTestConditionBeenMet = (
    condition: SpecialTestAnswerConditionDto,
    value: string
  ): boolean => {
    return (
      hasQuestionConditionBeenMet(condition.is, value, true) &&
      hasQuestionConditionBeenMet(condition.not, value, false)
    );
  };

  getSpecialTests = async (currentBodyArea: BodyArea) => {
    const [specialTest, strengthTest] = await Promise.all([
      this.clinical
        .getSpecialTests(ClinicalDataType[currentBodyArea])
        .catch(catchNotFoundError),
      this.clinical
        .getSpecialTests(StrengthSpecialTest[currentBodyArea])
        .catch(catchNotFoundError)
    ]);
    return { specialTest, strengthTest };
  };

  get cnsInitialValues(): CentralNervousSystemFormValues {
    const stashedData =
      this.clinicalRecord.stashedClinicalData?.centralNervousSystem;

    const cranialNerveTestResponseItems: SpecialTestResponseType = {};
    const images: ExaminationImageDto[] = [];
    const examinationComments: ExaminationCommentsType = {};

    if (stashedData && stashedData.images && stashedData.images.length > 0) {
      images.push(...stashedData.images);
    }
    if (
      stashedData &&
      stashedData?.cranialNerveTestResponse &&
      stashedData.cranialNerveTestResponse.items.length > 0
    ) {
      this.setTestResponseItems(
        stashedData?.cranialNerveTestResponse,
        cranialNerveTestResponseItems,
        BodyArea.CentralNervousSystemCranial
      );
    }

    if (
      stashedData &&
      stashedData.comments &&
      stashedData.comments.length > 0
    ) {
      examinationComments[BodyArea.CentralNervousSystemCranial] =
        stashedData.comments;
    } else {
      examinationComments[BodyArea.CentralNervousSystemCranial] = [
        { title: undefined, comment: undefined }
      ];
    }

    const flattenedImageValue = getFlattenedImageValues(images);

    return {
      imageValue: { ...flattenedImageValue },
      cranialNerveTestResponseItems,
      examinationComments
    };
  }

  getCNSStashedClinicalData = (
    values: CentralNervousSystemFormValues
  ): Partial<EncounterClinicalDataDto> => {
    const centralNervousSystemClinicalData =
      this.clinicalRecord.clinicalData?.centralNervousSystem;

    const centralNervousSystemConfirmed =
      this.clinicalRecord.clinicalData?.centralNervousSystemConfirmed;

    let cleanComments: ExaminationCommentsType = {};

    if (values.examinationComments) {
      //removes all empty comments
      cleanComments = cleanExaminationComments(values.examinationComments);
    }

    const images: ExaminationImageDto[] = [];

    if (values.imageValue) {
      Object.entries(values.imageValue).forEach(
        (fieldValue: [ImageTypeCode, string]) => {
          const [key, value] = fieldValue;
          const imageValue: ImageValue = {};
          imageValue[key] = value;

          images.push(...getImagesDTO(imageValue));
        }
      );
    }

    const centralNervousSystem: CentralNervousSystemClinicalDataDto = {
      eTag: centralNervousSystemClinicalData?.eTag,
      images,
      comments: cleanComments[BodyArea.CentralNervousSystemCranial]
    };

    if (values.cranialNerveTestResponseItems) {
      const cranialNerveTest = this.getCentralNervousTest(
        CentralNervousTest.CRANER
      );

      const cranialNerveTestResponseItems: SpecialTestResponseItem[] = [];
      if (cranialNerveTest) {
        values.cranialNerveTestResponseItems[
          BodyArea.CentralNervousSystemCranial
        ]?.forEach((responseItem: string[] | string, index: number) => {
          const responseItemValue = responseItem?.toString();
          if (responseItemValue && responseItemValue.length > 0) {
            cranialNerveTestResponseItems.push({
              specialTestItemId: cranialNerveTest.items[index].id,
              specialTestItemType: cranialNerveTest.items[index].type,
              value: responseItemValue
            });
          }
        });

        const cranialNerveTestResponse: SpecialTestResponseDto = {
          specialTestId: cranialNerveTest.id,
          specialTestCode: cranialNerveTest.code,
          items: cranialNerveTestResponseItems
        };

        centralNervousSystem.cranialNerveTestResponse =
          cranialNerveTestResponseItems.length > 0
            ? cranialNerveTestResponse
            : undefined;
      }
    }

    return {
      centralNervousSystem,
      centralNervousSystemConfirmed: {
        eTag: centralNervousSystemConfirmed?.eTag,
        confirmed: true
      }
    };
  };

  cnsOnSubmit = async (values: CentralNervousSystemFormValues) => {
    const bodyArea = this.getCNSStashedClinicalData(values);
    await this.clinicalRecord.saveClinicalData(bodyArea);
  };

  cnsOnCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.CentralNervousSystem
    );

    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "centralNervousSystem"
    ]);
  };
}
