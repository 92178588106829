import React from "react";

import { dataAttribute, DataAttributes, Spinner } from "@bps/fluent-ui";
import {
  CodedFieldClinicalDataItemDto,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  GenericPickerField,
  GenericPickerFieldProps
} from "@ui-components/form/GenericPickerField.tsx";

export interface TerminologyPickerFieldProps
  extends Omit<
    GenericPickerFieldProps<TerminologyDto>,
    | "keyAccessor"
    | "selectedKey"
    | "onFetchItem"
    | "onSearchItems"
    | "onResolveSuggestionItem"
  > {
  take?: number;
  isDiagnosis?: boolean;
  isProcedure?: boolean;
  isCauseOfDeath?: boolean;
  terminology?: CodedFieldClinicalDataItemDto;
  resultsFilter?: (
    results: QueryResult<TerminologyDto>
  ) => QueryResult<TerminologyDto>;
}

export const TerminologyPickerField: React.FC<TerminologyPickerFieldProps> = ({
  isDiagnosis,
  isProcedure,
  take,
  name,
  label,
  terminology,
  validateOnInitialize,
  disabled,
  required,
  onChange,
  excludeItemsFilter,
  resultsFilter,
  hint,
  isCauseOfDeath,
  inputProps
}) => {
  const { clinical } = useStores();

  const fetchInitialSelectedTerminology = async () => {
    if (terminology && terminology.originalText) {
      if (
        !clinical.terminologyMap.has(
          clinical.getTerminologyKey(terminology.code, terminology.originalText)
        )
      ) {
        await clinical.getTerminologiesSearch({
          search: terminology.originalText,
          total: true,
          isDiagnosis,
          isProcedure,
          isCauseOfDeath,
          take
        });
      }
    }
  };
  return (
    <DataFetcher fetch={fetchInitialSelectedTerminology} fallback={<Spinner />}>
      {() => (
        <GenericPickerField<TerminologyDto>
          name={name}
          hint={hint}
          {...dataAttribute(DataAttributes.Element, name)}
          label={label}
          required={required}
          keyAccessor={dto => clinical.getTerminologyKey(dto.code, dto.text)}
          onChange={onChange}
          minimumCharactersToSearch={2}
          onResolveSuggestionItem={dto => ({
            key: dto.code,
            name: dto.text
          })}
          onSearchItems={async (filter, previousResult) => {
            const fetchedDatas = await clinical.getTerminologiesSearch({
              search: filter,
              take,
              skip: previousResult?.take ?? 0,
              total: true,
              isDiagnosis,
              isProcedure,
              isCauseOfDeath
            });

            if (resultsFilter) {
              return resultsFilter(fetchedDatas);
            }

            return fetchedDatas;
          }}
          onFetchItem={key => {
            const TerminologyFromMap: TerminologyDto =
              clinical.getTerminologyFromMap(key) ?? { code: "", text: "" };
            return terminology &&
              key ===
                clinical.getTerminologyKey(
                  terminology.code,
                  terminology.originalText
                )
              ? {
                  code: terminology.code,
                  text: terminology.originalText,
                  codeSystem: terminology.codeSystem,
                  version: terminology.version
                }
              : TerminologyFromMap;
          }}
          selectedKey={
            terminology &&
            clinical.getTerminologyKey(
              terminology.code,
              terminology.originalText
            )
          }
          validateOnInitialize={validateOnInitialize}
          disabled={disabled}
          excludeItemsFilter={excludeItemsFilter}
          inputProps={inputProps}
        />
      )}
    </DataFetcher>
  );
};
