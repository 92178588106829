import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { FormDesignListModal } from "./FormDesignListModal.tsx";

//NOTE!!: This is just a 'walking skeleton' at this stage, we do not have proper designs yet :)

const FormDesignListModalWrapper: FunctionComponent = observer(() => {
  const { formDesign } = useStores();

  const {
    ui: { showFormDesignListModal }
  } = formDesign;
  return (
    <When permission={[Permission.FormDesignRead, Permission.FormDesignWrite]}>
      {showFormDesignListModal && <FormDesignListModal />}
    </When>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default FormDesignListModalWrapper;
