import { FunctionComponent } from "react";
import { Field, useField, useForm } from "react-final-form";

import {
  ITagItemSuggestionProps,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataElementCreateLog,
  ClinicalDataElementUpdateLog,
  DescriptionTag
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  ReactionAgentKind,
  ReactionAgentTypeDto
} from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { GenericPickerField } from "@ui-components/form/GenericPickerField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { DeleteReactionDialog } from "./DeleteReactionDialog.tsx";
import { NatureOfReactionsFields } from "./NatureOfReactionFields.tsx";
import {
  DeletedNatureOfReactions,
  NatureOfReactionFields,
  ReactionFormTypes
} from "./ReactionForm.types.ts";
import {
  getNaturesOfReaction,
  getReactionAgent,
  removeReactionFromClinicalData,
  toReactionType
} from "./utils.ts";

interface UpdateInfo {
  updatedDateTime: string;
  updatedById: string;
}
interface ReactionFormFieldsProps {
  createLog?: ClinicalDataElementCreateLog;
  updateLog?: ClinicalDataElementUpdateLog;
}

export const OTHER_CODE = "56";

export const nameOfReactionForm = nameOfFactory<ReactionFormTypes>();

export const ReactionFormFieldsBase: FunctionComponent<
  ReactionFormFieldsProps
> = ({ createLog, updateLog }) => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { drugs } = useStores();
  const form = useForm<ReactionFormTypes>();

  const existingReactions = clinicalRecord.clinicalData?.reaction;
  const mostRecentUpdate = (updateLog?: ClinicalDataElementUpdateLog) => {
    if (!updateLog) return undefined;

    const reactions = getNaturesOfReaction(
      reaction ?? "",
      existingReactions?.reactions
    );

    const mostRecentDates: UpdateInfo[] = reactions
      .filter(x => x.id !== reaction)
      .map(reaction => {
        if (reaction.updateLog) {
          return {
            updatedDateTime: reaction.updateLog.updatedDateTime,
            updatedById: reaction.updateLog.updatedById
          };
        }

        return {
          updatedDateTime: reaction.createLog!.createdDateTime,
          updatedById: reaction.createLog!.createdById
        };
      });

    if (updateLog) {
      mostRecentDates.push({
        updatedDateTime: updateLog.updatedDateTime,
        updatedById: updateLog.updatedById
      });
    }

    const commentUpdate = getReactionAgent(
      reaction ?? "",
      existingReactions?.agents
    )?.updateLog;

    if (commentUpdate) {
      mostRecentDates.push({
        updatedDateTime: commentUpdate.updatedDateTime,
        updatedById: commentUpdate.updatedById
      });
    }

    mostRecentDates.sort((a: UpdateInfo, b: UpdateInfo) => {
      return DateTime.fromISO(b.updatedDateTime).diff(
        DateTime.fromISO(a.updatedDateTime)
      ).milliseconds;
    });

    return mostRecentDates[0];
  };

  const {
    input: { value: reaction }
  } = useField<ReactionFormTypes["reaction"]>(nameOfReactionForm("reaction"));

  const { fields: naturesOfReactions } =
    useFieldArray<NatureOfReactionFields>("naturesOfReaction");

  const { fields: deletedReactions } =
    useFieldArray<NatureOfReactionFields>("deletedReactions");

  const {
    input: { value: doomedReaction }
  } = useField(nameOfReactionForm("doomedReaction"));

  const onDeleteConfirmed = async (
    dooomedNatureOfReaction: NatureOfReactionFields,
    reasonForDelete?: string,
    reasonForDeleteComment?: string
  ) => {
    if (dooomedNatureOfReaction) {
      const newDeletedReaction: DeletedNatureOfReactions = {};
      if (dooomedNatureOfReaction.id) {
        newDeletedReaction[dooomedNatureOfReaction.id] = {
          ...dooomedNatureOfReaction,
          isDeleted: true,
          deletedComment: reasonForDeleteComment,
          reasonForDelete
        };
      }

      const doomed = naturesOfReactions.value.findIndex(
        x => x.natureOfReaction === dooomedNatureOfReaction.natureOfReaction
      );

      form.batch(() => {
        doomed >= 0 && naturesOfReactions.remove(doomed);
        form.change(nameOfReactionForm("doomedReaction"), undefined);
        form.change(nameOfReactionForm("deletedReactions"), {
          ...deletedReactions.value,
          ...newDeletedReaction
        });
      });
      return;
    }

    const { reactions, agents } = removeReactionFromClinicalData({
      reaction: existingReactions,
      reactionAgentId: reaction,
      natureOfReaction: undefined,
      reasonForDelete,
      reasonForDeleteComment
    });

    await clinicalRecord.saveClinicalData({
      reaction: {
        eTag: existingReactions?.eTag,
        agents,
        reactions,
        // remove explicit nil known when backend infers it
        nilKnown: false
      }
    });
  };

  const onDeleteConfirm = async (
    reasonForDelete?: string,
    reasonForDeleteComment?: string
  ) => {
    onDeleteConfirmed(doomedReaction, reasonForDelete, reasonForDeleteComment);
  };

  const onDeleteNatureOfReaction = (nor: NatureOfReactionFields) => {
    if (
      !nor.createLog ||
      nor.createLog?.createdEncounterId === clinicalRecord.openEncounter?.id
    ) {
      onDeleteConfirmed(nor);
    } else {
      form.change(nameOfReactionForm("doomedReaction"), nor);
    }
  };

  const reactionTypeDescription = (type?: ReactionAgentKind) => {
    switch (type) {
      case ReactionAgentKind.DrugClass:
        return `   ${DescriptionTag.Class}`;
      case ReactionAgentKind.NonDrug:
        return `   ${DescriptionTag.NonDrug}`;
      default:
        return undefined;
    }
  };

  const isEdit = (() => {
    if (!reaction) return false;

    const [typeId] = reaction.split(".");
    if (!typeId || typeId === OTHER_CODE) return false;
    return (
      existingReactions?.agents?.some(x => x.agent?.code === typeId) ?? false
    );
  })();

  return (
    <>
      <DeleteReactionDialog
        hidden={!doomedReaction}
        onConfirm={onDeleteConfirm}
        onCancel={() =>
          form.change(nameOfReactionForm("doomedReaction"), undefined)
        }
      />
      <Stack tokens={{ childrenGap: 8 }}>
        <GenericPickerField<ReactionAgentTypeDto>
          name={nameOfReactionForm("reaction")}
          label="Reaction agent"
          required={true}
          disabled={isEdit}
          withTagItemSuggestionProps={(item: ReactionAgentTypeDto) => {
            const itemTypeDescription = reactionTypeDescription(item.type);
            const props: ITagItemSuggestionProps = {
              children: (
                <>
                  {item.description}
                  {itemTypeDescription &&
                    item.description !== ReactionAgentKind.Other && (
                      <span style={{ fontWeight: "bold" }}>
                        {itemTypeDescription}
                      </span>
                    )}
                </>
              )
            };

            return props;
          }}
          onSearchItems={async (filter, previousSearchResult) => {
            const items = drugs.searchReactionAgentTypes({
              search: filter,
              take: 50,
              skip: previousSearchResult?.results?.length ?? 0,
              total: true
            });

            return items;
          }}
          keyAccessor={item => `${item.id}.${item.type}`}
          onResolveSuggestionItem={item => ({
            key: `${item.id}.${item.type}`,
            name: item.description
          })}
          onFetchItem={async (key: string) => {
            const [typeId, type] = key.split(".");
            switch (type) {
              case ReactionAgentKind.DrugClass: {
                const { id, description } = await drugs.getDrugClass(typeId);
                return {
                  id,
                  description,
                  type: ReactionAgentKind.DrugClass
                };
              }
              case ReactionAgentKind.Ingredient: {
                const { id, ingredientName } =
                  await drugs.getIngredient(typeId);
                return {
                  id,
                  description: ingredientName,
                  type: ReactionAgentKind.Ingredient
                };
              }
              case ReactionAgentKind.NonDrug: {
                const { code, preferredTerm } = await drugs.getNonDrug(typeId);
                return {
                  id: code,
                  description: preferredTerm,
                  type: ReactionAgentKind.NonDrug
                };
              }
              default:
                return Promise.reject(new Error(`Unknown reaction key ${key}`));
            }
          }}
        />

        <FieldSpy
          name={nameOfReactionForm("reaction")}
          onChange={val => {
            const [typeId] = val.split(".");
            if (typeId !== OTHER_CODE) {
              form.change(nameOfReactionForm("otherText"), undefined);
            }
          }}
        />

        <FieldCondition
          when={nameOfReactionForm("reaction")}
          is={(val: string) => {
            const [typeId, type] = val.split(".");
            return !(!typeId || type !== ReactionAgentKind.NonDrug);
          }}
        >
          {val => {
            const [typeId] = val.split(".");
            return typeId === OTHER_CODE ? (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 4 }}
              >
                <TextInputField
                  required
                  name={nameOfReactionForm("otherText")}
                  maxLength={100}
                />
              </Stack>
            ) : undefined;
          }}
        </FieldCondition>

        <DropdownField
          name={nameOfReactionForm("certainty")}
          options={
            clinicalRecord.clinical.ref.reactionCertainties.keyTextValues
          }
          label="Certainty"
          required
        />

        <NatureOfReactionsFields onDeleteReaction={onDeleteNatureOfReaction} />

        <TextInputField
          name={nameOfReactionForm("comment")}
          label="Comment"
          multiline={true}
        />

        <RecordUpdateCheckedLog
          createdBy={createLog?.createdById}
          createdDate={createLog?.createdDateTime}
          updatedBy={mostRecentUpdate(updateLog)?.updatedById}
          updatedDate={mostRecentUpdate(updateLog)?.updatedDateTime}
          fontSize="small"
        />

        <Field<string | undefined>
          name={nameOfReactionForm("reaction")}
          subscription={{ value: true }}
        >
          {({ input: { value: reactionKey } }) => {
            if (!reactionKey) {
              return null;
            }

            const [, type] = reactionKey.split(".");
            const warningText =
              clinicalRecord.clinical.ref.reactionTypes.map.get(
                toReactionType(type as ReactionAgentKind)
              )?.warningText;

            return warningText ? (
              <MessageBar messageBarType={MessageBarType.warning}>
                {warningText}
              </MessageBar>
            ) : null;
          }}
        </Field>
      </Stack>
    </>
  );
};

export const ReactionFormFields = withFetch(
  x => [x.clinical.ref.reactionCertainties.load()],
  ReactionFormFieldsBase
);
