import { DateTime } from "@bps/utils";
import { PurchaseOrder } from "@stores/acc/models/PurchaseOrder.ts";

import { PurchaseOrderFormValues } from "./PurchaseOrderDialog.types.ts";
import { PurchaseOrderListItem } from "./PurchaseOrderList.types.ts";

export const mapToPurchaseOrderListItem = (
  item: PurchaseOrder
): PurchaseOrderListItem => {
  return {
    id: item.id,
    createdDate: item.responseDate,
    purchaseOrderNumber: item.purchaseOrderNumber,
    claimAdjustmentId: item.claimAdjustmentId,
    purchaseOrderNotes: item.purchaseOrderNotes,
    claimAdjustment: item.claimAdjustment,
    isRemovable: item.isRemovable
  };
};

export const getInitialValues = (
  purchaseOrder?: PurchaseOrder
): PurchaseOrderFormValues => {
  return {
    date: DateTime.jsDateFromISO(purchaseOrder?.responseDate),
    purchaseOrderNotes: purchaseOrder?.purchaseOrderNotes,
    purchaseOrderNumber: purchaseOrder?.purchaseOrderNumber
  };
};
