import { action, computed, observable } from "mobx";

import { TenantSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import {
  RECOMMENDED_INACTIVITY_TIMEOUT_THRESHOLD,
  SecurityFormValues
} from "./SecurityForm.types.tsx";

export class SecurityFormModel {
  constructor(private userExperience: UserExperienceStore) {}

  @observable
  public showTimeoutEnabledRiskMessage = false;

  @action
  setShowTimeoutEnabledRiskMessage = (value: boolean) => {
    this.showTimeoutEnabledRiskMessage = value;
  };

  @observable
  public showTimeoutThresholdRiskMessage = false;

  @action
  setShowTimeoutThresholdRiskMessage = (value: boolean) => {
    this.showTimeoutThresholdRiskMessage = value;
  };

  @observable
  public inactivityThresholdControlEnabled = false;

  @action
  setInactivityThresholdControlEnabled = (value: boolean) => {
    this.inactivityThresholdControlEnabled = value;
  };

  @computed
  get initialValues(): SecurityFormValues {
    const inactivityTimeoutEnabled =
      this.userExperience.tenantSecuritySettings?.inactivityTimeoutEnabled ??
      true;

    const inactivityTimeoutThresholdMinutes =
      this.userExperience.tenantSecuritySettings?.inactivityTimeoutThresholdMinutes?.toString() ??
      "15";

    return {
      inactivityTimeoutEnabled,
      inactivityTimeoutThresholdMinutes
    };
  }

  public onSubmit = async (values: SecurityFormValues) => {
    const tenantSettingDto: TenantSettingDto = {
      ...this.userExperience.tenantSetting?.dto,
      securitySettings: {
        inactivityTimeoutEnabled: values.inactivityTimeoutEnabled,
        inactivityTimeoutThresholdMinutes: Number(
          values.inactivityTimeoutEnabled
            ? values.inactivityTimeoutThresholdMinutes
            : RECOMMENDED_INACTIVITY_TIMEOUT_THRESHOLD
        )
      },
      eTag: this.userExperience.tenantSetting?.eTag ?? ""
    };

    return this.userExperience.updateTenantSetting(tenantSettingDto);
  };
}
