import { observer } from "mobx-react-lite";

import { Card, Spinner } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AddInvoiceSettingsDto,
  UpdateInvoiceSettingDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { OrgUnitCompanyDataType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { InvoiceSettings } from "@stores/billing/models/InvoiceSettings.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { InvoiceSettingsForm } from "./InvoiceSettingsForm.tsx";
import { InvoiceSettingsFormValues } from "./InvoiceSettingsForm.types.tsx";

export const InvoiceSettingsCard: React.FC = observer(() => {
  const { billing, core, routing } = useStores();

  const canEdit = core.hasPermissions(Permission.FinancialSettingsWrite);

  const cardStyles = {
    subComponentStyles: {
      tile: {
        content: {
          padding: 0
        }
      }
    }
  };

  return (
    <When permission={Permission.FinancialSettingsRead}>
      <Card
        iconName="Bank"
        headingLevel="section-heading"
        heading={SettingsLabels.financialInformation}
        styles={cardStyles}
      >
        <DataFetcher<InvoiceSettings[]>
          fetch={billing.getInvoiceSettings}
          fallback={<Spinner />}
        >
          {(settings: InvoiceSettings[]) => {
            // for now, as in the current pbi we have one setting only
            const setting = settings.length > 0 ? settings[0] : undefined;

            const getUpdatedSettings = (
              values: InvoiceSettingsFormValues
            ): AddInvoiceSettingsDto | UpdateInvoiceSettingDto => {
              const { gstNumber, bankDetails, ...otherValues } = values;

              return {
                ...otherValues,
                business: {
                  businessType:
                    core.tenantDetails?.country === Country.NewZealand
                      ? OrgUnitCompanyDataType.NzOrgUnitBusiness
                      : OrgUnitCompanyDataType.AuOrgUnitBusiness,
                  gstNumber,
                  bankDetails: {
                    accountName: values.bankDetails.accountName,
                    bankName: values.bankDetails.bankName,
                    bsb: values.bankDetails.bsb,
                    accountNumber: [
                      values.bankDetails.accountNumber,
                      values.bankDetails.accountNumber2,
                      values.bankDetails.accountNumber3,
                      values.bankDetails.accountNumber4
                    ]
                      .filter(x => x)
                      .join("-"),
                    locationId: core.locationId
                  }
                }
              };
            };

            const addInvoiceSettings = (values: InvoiceSettingsFormValues) => {
              return billing.addInvoiceSettings(getUpdatedSettings(values));
            };

            const updateInvoiceSettings = (
              values: InvoiceSettingsFormValues
            ) => {
              const dto = getUpdatedSettings(values);

              return billing.updateInvoiceSettings({
                ...setting!.dto,
                ...dto,
                business: {
                  ...setting!.business,
                  ...dto.business
                }
              });
            };

            const onSubmitForm = async (values: InvoiceSettingsFormValues) => {
              const isUpdate = !!setting;
              if (isUpdate) {
                await updateInvoiceSettings(values);
              } else {
                await addInvoiceSettings(values);
              }
            };

            return (
              <InvoiceSettingsForm
                settings={setting}
                goBack={routing.back}
                onSubmitForm={onSubmitForm}
                readOnly={
                  !canEdit ||
                  !core.hasPermissions(Permission.OrgUnitSettingWrite)
                }
              />
            );
          }}
        </DataFetcher>
      </Card>
    </When>
  );
});
