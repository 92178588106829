import {
  PSFSContextClinicalDataItemDto,
  PSFSQuestionnaireResponseDto,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { SeriesQuestionAnswers } from "./PSFSTrendDialog.types.ts";

export const createDynamicPsfsAnswerObject = (params: {
  questionnaire: QuestionnaireDto;
  responses: PSFSQuestionnaireResponseDto[];
  questionIds: number[];
  psfsSeriesContext: PSFSContextClinicalDataItemDto;
}) => {
  const { questionIds, questionnaire, psfsSeriesContext, responses } = params;
  const answersArray: SeriesQuestionAnswers[] = [];
  for (let i = 0; i < questionIds.length; i++) {
    const questionId = questionIds[i];
    const question = questionnaire.items.find(q => q.id === questionId)!;
    const questionAnswers: SeriesQuestionAnswers = {
      questionId: `q${questionId}`,
      questionNumberText: question.text,
      questionText: psfsSeriesContext?.activities.find(
        a => a.questionnaireItemId === question.id
      )?.text
    };
    for (let x = 0; x < responses.length; x++) {
      const answerKey = `series${x}`;
      const answer = responses[x].items.find(
        x => x.questionnaireItemId === questionId
      )?.answer;

      if (answer && answerKey) {
        questionAnswers[answerKey] = answer;
      }
    }
    answersArray.push(questionAnswers);
  }

  return answersArray;
};

export const getAverages = (
  responses: PSFSQuestionnaireResponseDto[],
  numberofQuestions: number
) => {
  const summaryRow: SeriesQuestionAnswers = {};
  for (let x = 0; x < responses.length; x++) {
    const answers = responses[x].items.map(x => Number(x.answer));
    const seriesTotal = answers.reduce((a, b) => a + b, 0);
    const average = seriesTotal / numberofQuestions;
    const averageRounded = Math.round(average * 10) / 10;
    const key = `series${x}`;
    summaryRow[key] = averageRounded;
  }

  summaryRow.questionText = "Average scores";
  summaryRow.questionNumberText = "";

  return summaryRow;
};
