import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { AccStore } from "@stores/acc/AccStore.ts";

export const getClaimStatusesOptions = (acc: AccStore) => {
  const statusesRefData = acc.ref.claimStatuses;

  const filteredOptions = statusesRefData.keyTextValues.filter(
    x =>
      x.key !== ClaimStatuses.Ready &&
      x.key !== ClaimStatuses.Error &&
      x.key !== ClaimStatuses.GetStatusError
  );

  const options = [
    {
      key: ClaimStatuses.Ready,
      text: statusesRefData.get(ClaimStatuses.Ready)?.text ?? "Ready",
      isDivider: true
    },
    ...filteredOptions
      .sort((a, b) => a.text.localeCompare(b.text))
      .map((x, i) => {
        if (i + 1 === filteredOptions.length) {
          return { ...x, isDivider: true };
        }
        return x;
      }),

    {
      key: ClaimStatuses.Error,
      text: statusesRefData.get(ClaimStatuses.Error)?.text ?? "Lodge error"
    },
    {
      key: ClaimStatuses.GetStatusError,
      text:
        statusesRefData.get(ClaimStatuses.GetStatusError)?.text ?? "Query error"
    }
  ];

  return options;
};
