import { observer } from "mobx-react-lite";
import { useFormState } from "react-final-form";

import { Modal, Spinner, Stack, Text } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";

export const DocumentUploadStatusModal: React.FC = observer(() => {
  const { isSavingDocuments, claim } = useClaimAdjustmentContext();

  const { uploadDocuments, documents } =
    useFormState<ClaimAdjustmentFormValues>().values;

  const uploadDocumentsWithoutIds = uploadDocuments?.filter(x => !x.documentId);
  const documentsWithoutIds = documents.filter(x => !x.documentId);

  const isUploadingDocuments =
    !!uploadDocumentsWithoutIds?.length || !!documentsWithoutIds?.length;

  return (
    <Modal
      isOpen={isSavingDocuments}
      styles={{
        main: { minWidth: 450, minHeight: 120, padding: 40 },
        scrollableContent: { overflow: "hidden" }
      }}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        {!!isUploadingDocuments ? (
          <div>
            <Text styles={{ root: { marginBottom: 8 } }}>
              Uploading documents to {claim.patient?.name}’s correspondence...
            </Text>
            <Stack as="ul">
              {uploadDocumentsWithoutIds &&
                uploadDocumentsWithoutIds
                  .filter(ud => !ud.documentId)
                  .map(pendingDoc => (
                    <Stack key={pendingDoc.stagingId} as="li">
                      {pendingDoc.fileName} uploading...
                    </Stack>
                  ))}
              {documentsWithoutIds &&
                documentsWithoutIds
                  .filter(ud => !ud.documentId)
                  .map(pendingDoc => (
                    <Stack key={pendingDoc.claimAdjustmentDocumentId} as="li">
                      {pendingDoc.fileName} uploading...
                    </Stack>
                  ))}
            </Stack>
          </div>
        ) : (
          <Text>
            Finished uploading documents to {claim.patient?.name}’s
            correspondence now lodging ACC32...
          </Text>
        )}
        <Spinner />
      </Stack>
    </Modal>
  );
});
