import { FC } from "react";
import { useField, useForm } from "react-final-form";

import { DefaultButton, Heading, NoDataTile, Stack } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { ExternalProviders } from "./ExternalProviders.tsx";
import { InternalProviders } from "./InternalProviders.tsx";
import {
  ExternalProviderFieldValue,
  InternalProviderFieldValue,
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

export const EditHealthProviders: FC<{}> = () => {
  const form = useForm<PatientEditFormValues>();

  const {
    input: { value: externalProvider }
  } = useField<ExternalProviderFieldValue[]>(
    patientFormNameOf("externalProvider"),
    { subscription: { value: true } }
  );

  const {
    input: { value: internalProvider }
  } = useField<InternalProviderFieldValue[]>(
    patientFormNameOf("internalProvider"),
    { subscription: { value: true } }
  );

  const addInternalProvider = () => {
    const internalProviders = internalProvider.map(p => ({
      ...p,
      isNew: false
    }));

    const newProvider: InternalProviderFieldValue = {
      userId: "",
      isNew: true,
      id: newGuid()
    };
    form.change(patientFormNameOf("internalProvider"), [
      ...internalProviders,
      newProvider
    ]);
  };

  const addExternalProvider = () => {
    const externalProviders = externalProvider.map(p => ({
      ...p,
      isNew: false
    }));

    const newProvider: ExternalProviderFieldValue = {
      contactId: "",
      isNew: true,
      id: newGuid()
    };
    form.change(patientFormNameOf("externalProvider"), [
      ...externalProviders,
      newProvider
    ]);
  };

  return (
    <div
      id={`${PatientCardIds.healthProviders}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{
            root: {
              lineHeight: "22px"
            }
          }}
        >
          <Heading variant="section-heading-light">
            {Labels.healthProviders}
          </Heading>
          <DefaultButton
            iconProps={{ iconName: "Add" }}
            text="Add more"
            menuProps={{
              styles: { container: { width: 152 } },
              shouldFocusOnMount: true,
              items: [
                {
                  key: "internal",
                  text: Labels.internalProvider,
                  onClick: addInternalProvider
                },
                {
                  key: "external",
                  text: Labels.externalProvider,
                  onClick: addExternalProvider
                }
              ]
            }}
          />
        </Stack>
        {internalProvider.length === 0 && externalProvider.length === 0 ? (
          <NoDataTile
            textProps={{ text: "No health providers" }}
            linkProps={{
              text: "Add an internal health provider to this person",
              onClick: () => addInternalProvider()
            }}
          />
        ) : (
          <Stack tokens={{ childrenGap: 16 }}>
            {internalProvider.length > 0 && <InternalProviders />}
            {externalProvider.length > 0 && <ExternalProviders />}
          </Stack>
        )}
      </Stack>
    </div>
  );
};
