import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { AddServicesModal } from "@modules/billing/screens/shared-components/add-service-modal/AddServicesModal.tsx";
import { ServiceableItem } from "@modules/billing/screens/shared-components/add-service-modal/AddServicesModal.types.ts";
import { ServiceWarningsContext } from "@modules/billing/screens/shared-components/add-service-modal/context/ServiceWarningsContext.tsx";
import { ServiceWarningsHelper } from "@modules/billing/screens/shared-components/add-service-modal/context/ServiceWarningsHelper.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { EncounterFormContext } from "./models/EncounterFormContext.ts";
import { getProviderCommentDialogTitle } from "./ProviderCommentDialog.tsx";

export const EncounterAddServicesDialogBase: React.FC<{
  servicesSelected: InvoiceItemFormValue[];
}> = observer(({ servicesSelected: initialServicesSelected }) => {
  const { core } = useStores();
  const helper = useContext(EncounterFormContext);

  const {
    isAddServicesDialogVisible,
    calendarEvent,
    onSubmitServices,
    clinicalRecord,
    cancelAddServicesDialog,
    helpers
  } = helper;

  const patientRecordScreenHelper = usePatientRecordScreenContext();

  const condition = patientRecordScreenHelper.clinicalRecord.condition;

  const claimId = condition && condition.claim && condition.claim.id;

  const [servicesSelected, setServicesSelected] = useState(
    initialServicesSelected
  );

  const onServicesSelected = async (items: InvoiceItemFormValue[]) => {
    setServicesSelected(items);
    await onSubmitServices(items);

    if (
      (helper.isFinalising || helper.isEnding) &&
      patientRecordScreenHelper.encounterFormApi
    ) {
      patientRecordScreenHelper.encounterFormApi?.submit();
    }
  };

  const extraButton = calendarEvent && (
    <DefaultButton
      onClick={helper.openProviderCommentDialog}
      text={getProviderCommentDialogTitle(calendarEvent.providerComment)}
    />
  );

  const encounterFormValues =
    helpers.parentHelper.encounterFormApi?.getState().values;

  return (
    <AddServicesModal
      extraButton={extraButton}
      closeDialog={cancelAddServicesDialog}
      hidden={!isAddServicesDialogVisible}
      selectedServices={servicesSelected}
      onServicesSelected={onServicesSelected}
      invoice={{
        patientId: clinicalRecord.patient?.id,
        userId: core.userId,
        locationId: encounterFormValues?.practiceLocationId,
        calendarEventId: calendarEvent?.id,
        claimId
      }}
      kind={ServiceableItem.DraftItem}
    />
  );
});

export const EncounterAddServicesDialog: React.FC = observer(() => {
  const root = useStores();
  const encounterFormHelper = useContext(EncounterFormContext);
  const serviceWarningsHelper = new ServiceWarningsHelper(root);
  if (!encounterFormHelper.isAddServicesDialogVisible) return null;
  return (
    <DataFetcher fetch={encounterFormHelper.getInitialServicesSelected}>
      {servicesSelected => (
        <ServiceWarningsContext.Provider value={serviceWarningsHelper}>
          <EncounterAddServicesDialogBase servicesSelected={servicesSelected} />
        </ServiceWarningsContext.Provider>
      )}
    </DataFetcher>
  );
});
