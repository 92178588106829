import { FC } from "react";

import {
  DefaultButton,
  DirectionalHint,
  Image,
  Spinner,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { PhysitrackApiKeyDto } from "@libs/gateways/custom-inegration/CustomIntegrationGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import physitrack from "./physitrack.png";
import {
  fetchContactAndPhysitrackKey,
  getPhysitrackUrl,
  redirectToPhysitrack
} from "./utils.ts";

export const PhysitrackButton: FC = () => {
  const { patientId } = usePatientRecordScreenContext();
  const { core } = useStores();

  const url = getPhysitrackUrl(core.tenantDetails!.country);

  return (
    <DataFetcher<{
      contact: Contact;
      physitrackApiKeyDto: PhysitrackApiKeyDto | undefined;
    }>
      fallback={<Spinner />}
      fetch={async root => await fetchContactAndPhysitrackKey(root, patientId)}
    >
      {({ contact, physitrackApiKeyDto }) =>
        physitrackApiKeyDto !== undefined && (
          <Stack styles={{ root: { width: "fit-content" } }}>
            <TooltipHost
              content="Will redirect to Physitrack"
              directionalHint={DirectionalHint.leftTopEdge}
            >
              <DefaultButton
                href={redirectToPhysitrack(physitrackApiKeyDto, contact, url)}
                target="_blank"
              >
                <Image styles={{ root: { width: 20 } }} src={physitrack} />
                &nbsp; Open patient in Physitrack
              </DefaultButton>
            </TooltipHost>
          </Stack>
        )
      }
    </DataFetcher>
  );
};
