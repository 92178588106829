import { Fragment, ReactNode } from "react";

import { IStackItemProps, Separator, Stack } from "@bps/fluent-ui";

export interface BillingDetailsRowItem {
  grow?: IStackItemProps["grow"];
  basis?: IStackItemProps["basis"];
  width?: number | string;
  content: ReactNode;
  id: string;
}

export interface BillingDetailsRowProps {
  items: BillingDetailsRowItem[];
}

export const BillingDetailsRow: React.FC<BillingDetailsRowProps> = ({
  items
}) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 24 }}>
      {items.map(({ width, content, grow, id, basis }, index) => (
        <Fragment key={id}>
          <Stack.Item
            grow={grow}
            basis={basis}
            styles={{ root: { width: width || undefined } }}
          >
            {content}
          </Stack.Item>
          {index + 1 !== items.length && <Separator vertical />}
        </Fragment>
      ))}
    </Stack>
  );
};
