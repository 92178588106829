import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { IContextualMenuListProps } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import {
  ClaimFormPrint,
  ClaimFormPrintType
} from "@modules/acc/screens/claim/components/ClaimFormPrint.tsx";
import { getClaimFormValues } from "@modules/acc/screens/claim/components/utils.ts";
import { SendACC45EmailModal } from "@modules/acc/screens/shared-components/SendACC45EmailModal.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

interface ContextualMenuClaimSubMenuProps {
  listProps: IContextualMenuListProps | undefined;
  defaultRender:
    | ((props: IContextualMenuListProps) => JSX.Element | null)
    | undefined;
}

export enum ClaimSubMenuKeys {
  openClaim = "openClaim",
  print = "print",
  printSummary = "printSummary",
  emailSummary = "emailSummary"
}

export const ContextualMenuClaimSubMenu: FunctionComponent<ContextualMenuClaimSubMenuProps> =
  observer(({ listProps, defaultRender }) => {
    const { routing, acc } = useStores();
    const { claim } = useContext(BookingCalendarEventContext);

    const getSubMenu = () => {
      if (claim && defaultRender && listProps) {
        const items = listProps.items.map(i => {
          switch (i.key) {
            case ClaimSubMenuKeys.openClaim:
              return {
                ...i,
                onClick: () =>
                  routing.push(
                    routes.claims.management.edit.path({
                      id: claim.id!
                    }),
                    routing.getStateWithFromQuery()
                  )
              };
            case ClaimSubMenuKeys.print:
              return {
                ...i,
                onRenderContent: () => (
                  <ClaimFormPrint
                    values={getClaimFormValues(claim)}
                    printType={ClaimFormPrintType.Patient}
                  />
                )
              };
            case ClaimSubMenuKeys.printSummary:
              return {
                ...i,
                onRenderContent: () => (
                  <ClaimFormPrint
                    values={getClaimFormValues(claim)}
                    printType={ClaimFormPrintType.Summary}
                  />
                )
              };

            case ClaimSubMenuKeys.emailSummary:
              return {
                ...i,
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  acc.ui.setCurrentClaimToEmail(claim);
                  acc.ui.setIsEmailACC45DialogVisible(true);
                },
                onRenderContent: () => (
                  <SendACC45EmailModal
                    patientId={claim.patientId!}
                    claimDto={claim.dto}
                  />
                )
              };
            default:
              return i;
          }
        });
        return defaultRender({ ...listProps, items });
      }

      return null;
    };

    return <>{getSubMenu()}</>;
  });
