import { routes } from "@libs/routing/routes.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { UserWorkingHoursForm } from "./components/UserWorkingHoursForm.tsx";

const WorkingHoursScreen: React.FC = () => {
  const { routing } = useStores();

  const userId = routing.match(routes.settings.users.userWorkingHours)?.params
    .id;

  if (!userId) return null;

  return (
    <DataFetcher<UserAvailabilityModel>
      key={userId}
      fetch={({ booking }) => booking.getUserAvailability(userId)}
    >
      {userAvailability => (
        <UserWorkingHoursForm userAvailability={userAvailability} />
      )}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default WorkingHoursScreen;
