import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  email,
  maxLength,
  phone,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimPatientFormValues } from "@shared-types/acc/claim-patient-values.type.ts";
import { PersonalDetailsValuesType } from "@shared-types/acc/personal-details-values.type.ts";

import { ClaimAddressValidator } from "../../claim-adjustment/validators/ClaimAddressValidator.ts";

class PatientValidator extends Validator<ClaimPatientFormValues> {
  constructor() {
    const claimAddressValidator = new ClaimAddressValidator();
    super();
    this.forField(nameof("patientFirstName"), [required()]);
    this.forField(nameof("patientLastName"), [required()]);
    this.forField(nameof("patientDateOfBirth"), [required()]);
    this.forField(nameof("patientSex"), [required()]);
    this.forField(nameof("patientEthnicityCode"), [required()]);
    this.forField(nameof("patientEmail"), [email()]);
    this.forField(nameof("patientWorkPhoneNumber"), [phone, maxLength(20)]);
    this.forField(nameof("patientMobileNumber"), [phone, maxLength(20)]);
    this.forField(nameof("patientHomePhoneNumber"), [phone, maxLength(20)]);

    this.forField("patientAddress", claimAddressValidator.validate);
  }
}
export class PersonalDetailsFormValidator extends Validator<PersonalDetailsValuesType> {
  constructor() {
    const validator = new PatientValidator();
    super();

    this.forField("patient", validator.validate);
  }
}
