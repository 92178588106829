import { observer } from "mobx-react-lite";
import { useContext, useMemo } from "react";

import { NoDataTile, Pivot, PivotItem, Stack } from "@bps/fluent-ui";
import { InboxScreenContext } from "@modules/inbox/screens/context/InboxScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  InboxGroupedList,
  useInboxSelection
} from "../../shared-components/InboxGroupedList.tsx";
import { useInboxSearch } from "./useInboxResults.tsx";

export const NoFileDataTile = () => (
  <NoDataTile
    styles={{ root: { marginTop: 30 } }}
    textProps={{ text: "No files" }}
    linkProps={{ hidden: true }}
  />
);

export interface UserInboxScreenLeftSideListProps {
  onTabItemSelected?: (selectedHeaderText: string) => void;
}

export const UserInboxScreenLeftSideList: React.FC<UserInboxScreenLeftSideListProps> =
  observer(({ onTabItemSelected }) => {
    const { inbox } = useStores();
    const selection = useInboxSelection();

    const { searchResults, isLoading, isLoadingMore, handleScrolledToBottom } =
      useInboxSearch({ refreshKey: inbox.ui.documentRefreshKey });

    const { getPatientGroups } = useContext(InboxScreenContext);

    const groups = useMemo(
      () => getPatientGroups(searchResults.value?.results ?? []),
      [searchResults.value?.results, getPatientGroups]
    );

    const files = searchResults.value?.results ?? [];

    return (
      <Stack
        styles={{
          root: {
            overflowY: "auto"
          }
        }}
      >
        <Pivot
          styles={{
            root: { paddingLeft: 40 },
            linkIsSelected: { paddingLeft: 10 }
          }}
          defaultSelectedKey="files"
          onLinkClick={(item?: PivotItem) =>
            onTabItemSelected && onTabItemSelected(item?.props.headerText ?? "")
          }
        >
          <PivotItem
            key="files"
            headerText="Files"
            itemCount={searchResults.value?.total ?? undefined}
          >
            <InboxGroupedList
              selection={selection}
              groups={groups}
              files={files}
              searchResults={searchResults}
              onScrollToBottom={handleScrolledToBottom}
              isLoadingMore={isLoadingMore}
              isLoading={isLoading}
            />
          </PivotItem>
        </Pivot>
      </Stack>
    );
  });
