import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  DefaultButton,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClaimReviewStatus } from "@modules/clinical/screens/patient-record/components/claim-review/ClaimReviewEnums.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ClaimReviewStatusText } from "@ui-components/RefText.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { ClaimReviewDocumentDialog } from "./ClaimReviewDocumentDialog.tsx";

interface ClaimReviewCellProps {
  claim: Claim;
  visitCount: number;
}

const ClaimReviewBase: React.FC<ClaimReviewCellProps> = observer(
  ({ claim, visitCount }) => {
    const { setClaimReviewTab } = usePatientRecordScreenContext();
    const theme = useTheme();
    const { acc, correspondence } = useStores();
    const [deleting, setDeleting] = useState(false);
    const [showDocumentViewer, setShowDocumentViewer] = useState(false);

    const claimReview = claim.claimReview;
    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    return (
      <>
        {!claimReview ? (
          <MessageBar
            styles={{
              root: {
                backgroundColor: theme.semanticColors.infoBackground,
                flexDirection: "row"
              },
              actions: {
                margin: "4px 12px"
              }
            }}
            messageBarType={MessageBarType.warning}
            actions={
              acc.ui.shouldDisplayClaimReviewAlert(visitCount) ? (
                <DefaultButton
                  onClick={() => setClaimReviewTab(claim)}
                  styles={{ root: { height: 24 } }}
                  text="Start"
                  disabled={isViewOnlyOrDischarged}
                />
              ) : undefined
            }
          >
            Required by 16th consult
          </MessageBar>
        ) : (
          <Stack
            styles={{ root: { height: 32 } }}
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <Text
                styles={{ root: { color: theme.palette.neutralSecondary } }}
              >
                <ClaimReviewStatusText code={claimReview?.statusCode} />
              </Text>
              <UserFetcher
                refetchId={claimReview?.clinicalDirectorId}
                userId={claimReview?.clinicalDirectorId}
                fallback={<Spinner />}
              >
                {(user: User) => <Text>{user.fullName}</Text>}
              </UserFetcher>
            </Stack>
            {claimReview.statusCode === ClaimReviewStatus.draft ? (
              <>
                {deleting ? (
                  <Spinner />
                ) : (
                  <Stack horizontal verticalAlign="center">
                    <IconButton
                      iconProps={{ iconName: "Edit" }}
                      onClick={() => setClaimReviewTab(claim)}
                    />
                    <DeleteButton
                      onClick={() => {
                        setDeleting(true);
                        acc.deleteClaimReview(claimReview!.id).then(() => {
                          setDeleting(false);
                          claim.setClaimReview(undefined);
                          setClaimReviewTab();
                        });
                      }}
                    />
                  </Stack>
                )}
              </>
            ) : (
              <Link
                onClick={() => {
                  setShowDocumentViewer(true);
                }}
              >
                View
              </Link>
            )}
          </Stack>
        )}
        {showDocumentViewer && claimReview?.clinicalDocumentId && (
          <ClaimReviewDocumentDialog
            claim={claim}
            documentId={claimReview.clinicalDocumentId}
            closeDocumentViewer={() => {
              setShowDocumentViewer(false);
            }}
            getDocument={async () => {
              const response = await correspondence.getDocumentUrl({
                patientId: claim.patientId!,
                documentId: claimReview.clinicalDocumentId!
              });
              return {
                entityId: claim.patientId!,
                documentId: claimReview.clinicalDocumentId!,
                entityType: DocumentEntityType.Patient,
                name: `ClaimReview_${claim.claimNumber}`,
                extension: "pdf",
                previewUri: response.url
              };
            }}
            getDownloadUri={async () => {
              const documentUrlResponse = await correspondence.getDocumentUrl({
                patientId: claim.patientId!,
                documentId: claimReview.clinicalDocumentId!
              });
              return documentUrlResponse.url;
            }}
          />
        )}
      </>
    );
  }
);

const ClaimReviewCellDataFetcher: React.FC<ClaimReviewCellProps> = props => {
  return (
    <DataFetcher
      fetch={async () =>
        await Promise.all([
          props.claim.loadClaimReview(),
          props.claim.loadPatient()
        ])
      }
    >
      {() => <ClaimReviewBase {...props} />}
    </DataFetcher>
  );
};

export const ClaimReviewCell = withFetch(
  ({ acc }) => [acc.ref.claimReviewStatuses.load()],
  ClaimReviewCellDataFetcher
);
