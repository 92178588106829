import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export enum ClosedChoice {
  closed = "closed",
  open = "open"
}

export type EditPracticeDayWorkingHoursFormValues = {
  date?: Date | undefined;
  startTime?: string | undefined;
  endTime?: string | undefined;
  reason?: string | undefined;
  closedChoice: ClosedChoice;
};

export const nameOf = nameOfFactory<EditPracticeDayWorkingHoursFormValues>();
