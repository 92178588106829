import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BillingScreenWrapper } from "@modules/billing/screens/shared-components/BillingScreenWrapper.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { AccInvoiceTransactionsList } from "./AccInvoiceTransactionsList.tsx";
import { AccInvoiceTransactionsListFilter } from "./AccInvoiceTransactionsListFilter.tsx";
import { SelectedInvoicesGroup } from "./SelectedInvoicesGroup.tsx";

export const AccInvoicesList: FunctionComponent = observer(() => {
  return (
    <BillingScreenWrapper
      headerButtons={
        <When permission={Permission.ClaimWrite}>
          <SelectedInvoicesGroup />
        </When>
      }
    >
      <AccInvoiceTransactionsListFilter>
        <AccInvoiceTransactionsList />
      </AccInvoiceTransactionsListFilter>
    </BillingScreenWrapper>
  );
});
