import { FunctionComponent } from "react";

import { Accordion, AccordionItem, Stack, useTheme } from "@bps/fluent-ui";
import {
  SpecialTestDto,
  SpecialTestItemDto,
  SpecialTestTypeCode
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { useBodyExaminationContext } from "../context/BodyExaminationContext.ts";
import { LARGE_BREAKPOINT } from "../utils.ts";
import { BodySpecialTestItem } from "./BodySpecialTestItem.tsx";
import { SpecialTestItemGroup } from "./SpecialTestItemGroup.tsx";

interface ExaminationSpecialTestsProps {
  specialTest: SpecialTestDto;
  panelWidth?: number;
}

export const ExaminationSpecialTestsBase: FunctionComponent<
  ExaminationSpecialTestsProps
> = ({ specialTest, panelWidth }) => {
  const { currentBodyArea, sortSpecialTestItemsByHeading } =
    useBodyExaminationContext();

  const { clinical } = useStores();
  const theme = useTheme();

  const isPanelVertical =
    panelWidth !== undefined && panelWidth < LARGE_BREAKPOINT;

  if (!currentBodyArea) return null;

  const specialTestItems: SpecialTestItemDto[] = specialTest.items.sort(
    x => x.id
  );

  const borderStyles = {
    root: {
      borderTop: `1px solid ${theme.palette.neutralLight}`,
      padding: "8px 4px"
    }
  };

  const renderSpecialTestItemCollection = (
    allTestItems: SpecialTestItemDto[],
    specialTestItemGroup: SpecialTestItemDto[]
  ) => {
    return (
      <Stack
        styles={borderStyles}
        key={`itemGroup-${specialTestItemGroup[0].text}`}
      >
        <SpecialTestItemGroup
          allSpecialTestItems={allTestItems}
          groupedSpecialTestItems={specialTestItemGroup}
          bodyArea={currentBodyArea}
          isVertical={isPanelVertical}
          hasSide={specialTest.hasSide}
          specialTestType={specialTest.typeCode}
        />
      </Stack>
    );
  };

  const renderSpecialTestItem = (options: {
    specialTestItems: SpecialTestItemDto[];
    specialTestItem: SpecialTestItemDto;
    index: number;
    hideLabel?: boolean;
    renderLabelOnly?: boolean;
  }) => {
    return (
      <BodySpecialTestItem
        bodyArea={currentBodyArea}
        index={options.index}
        allSpecialTests={options.specialTestItems}
        specialTestItem={options.specialTestItem}
        hideLabel={options.hideLabel}
        isVertical={isPanelVertical === true}
        renderLabelOnly={options.renderLabelOnly}
        hasSide={specialTest.hasSide}
        specialTestType={specialTest.typeCode}
      />
    );
  };

  const renderSpecialTestItems = (
    totalSpecialTestItems: SpecialTestItemDto[],
    specialTestItems: SpecialTestItemDto[]
  ) => {
    const itemList: JSX.Element[] = [];
    return specialTestItems.reduce((specialTestList, specialTestItem) => {
      // Index number.
      const indexNumber = totalSpecialTestItems.findIndex(
        x => x.id === specialTestItem.id
      );

      const mergeSpecialTest = specialTestItems.filter(
        item => item.text === specialTestItem.text
      );

      if (mergeSpecialTest.length === 1) {
        const specialTestField = renderSpecialTestItem({
          specialTestItems: totalSpecialTestItems,
          specialTestItem,
          index: indexNumber,
          hideLabel: false
        });

        return [
          ...specialTestList,
          <Stack
            key={`${specialTest.typeCode}-${currentBodyArea}-${indexNumber}`}
            horizontal={!isPanelVertical}
            tokens={{ childrenGap: 8 }}
            styles={borderStyles}
          >
            {specialTestField}
          </Stack>
        ];
      }

      if (mergeSpecialTest.length > 0 && specialTestItem.index === 0) {
        const mergeSpecialTestField = renderSpecialTestItemCollection(
          totalSpecialTestItems,
          mergeSpecialTest
        );
        return [...specialTestList, mergeSpecialTestField];
      }

      return specialTestList;
    }, itemList);
  };

  // Splitting up the items into digestible lists for an accordion to work with.
  const separateSpecialTestByHeading = (
    specialTestItems: SpecialTestItemDto[]
  ) => {
    const headingSortedItems = sortSpecialTestItemsByHeading(specialTestItems);

    const result = headingSortedItems.map(x => {
      const sortedById = x.sort(y => y.id); // There's a chance that these items won't be sorted by their ID. This ensures that they are, for index purposes.
      const heading = x[0].heading;

      const title = clinical.ref.specialTestHeadings.keyTextValues.find(
        x => x.key === heading
      );

      return (
        <AccordionItem
          key={specialTest.typeCode + (heading ?? "other")}
          title={title ? title.text : "Other"}
        >
          <Stack tokens={{ childrenGap: 4 }}>
            {renderSpecialTestItems(specialTestItems, sortedById)}
          </Stack>
        </AccordionItem>
      );
    });

    return result;
  };

  const nonHeadingItems = specialTestItems.filter(x => !x.heading);
  const headingItems = specialTestItems.filter(x => x.heading);

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Accordion
        withActions={
          specialTest.typeCode === SpecialTestTypeCode.CentralNervous
        }
        multiple
        noBorders
      >
        {separateSpecialTestByHeading(headingItems)}
      </Accordion>

      {nonHeadingItems && nonHeadingItems.length > 0 && (
        <Stack tokens={{ childrenGap: 4 }}>
          {renderSpecialTestItems(specialTestItems, nonHeadingItems)}
        </Stack>
      )}
    </Stack>
  );
};

export const ExaminationSpecialTests = withFetch(
  x => [x.clinical.ref.specialTestHeadings.load()],
  ExaminationSpecialTestsBase
);
