import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontWeights,
  Heading,
  Link,
  PrimaryButton,
  Stack,
  Text
} from "@bps/fluent-ui";
import { groupBy } from "@bps/utils";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ReactionWarningTypeText } from "@ui-components/RefText.tsx";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";

const ReactionWarningsBase: FunctionComponent = observer(() => {
  const helper = usePrescribingWizardContext();
  const { setDoseFormDialogVisibleWithViewType } = useMedicationsContext();
  const { selectedFormulation, warnings } = helper;

  const handleSubmitForm = async () => {
    runInAction(() => {
      helper.acceptedWarnings = true;
    });
    setDoseFormDialogVisibleWithViewType("edit");
  };

  if (!warnings) return null;

  const groupedWarnings = groupBy(warnings, x => x.type);
  const productInformationUrl =
    selectedFormulation?.productPack?.productInformationUrl;

  const consumerInformationUrl =
    selectedFormulation?.productPack?.consumerInformationUrl;
  return (
    <Stack verticalFill tokens={{ childrenGap: 8 }}>
      {selectedFormulation?.productPack ? (
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 4 }}>
          <Link
            disabled={!productInformationUrl}
            href={productInformationUrl}
            target="_blank"
          >
            PI
          </Link>
          <Link
            disabled={!consumerInformationUrl}
            href={consumerInformationUrl}
            target="_blank"
          >
            CMI
          </Link>
        </Stack>
      ) : null}
      <Stack grow tokens={{ padding: 2 }} horizontalAlign="center">
        <Text
          variant="large"
          styles={(props, theme) => ({
            root: {
              fontWeight: FontWeights.semibold,
              color: theme.semanticColors.errorText,
              textTransform: "uppercase"
            }
          })}
        >
          Warnings
        </Text>
      </Stack>
      <Stack
        verticalFill
        styles={{ root: { overflowY: "auto" } }}
        tokens={{ childrenGap: 16 }}
      >
        {groupedWarnings.map(([type, warnings]) => (
          <Stack horizontal key={type}>
            <Text
              styles={{
                root: { fontWeight: FontWeights.semibold, minWidth: 150 }
              }}
            >
              <ReactionWarningTypeText code={type} />
            </Text>
            <Stack
              verticalFill
              styles={{ root: { overflowY: "auto" } }}
              tokens={{ childrenGap: 16 }}
            >
              {warnings.map(item => (
                <Text key={item.warning}>{item.warning}</Text>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
        <Heading labelPaddings>
          I acknowledge the warnings related to prescribing this product
        </Heading>

        <PrimaryButton onClick={handleSubmitForm}>Continue</PrimaryButton>
      </Stack>
    </Stack>
  );
});

export const ReactionWarnings = withFetch(
  x => x.clinical.ref.reactionWarningTypes.load(),
  ReactionWarningsBase
);
