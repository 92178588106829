import { observer } from "mobx-react-lite";

import { InvoiceDocumentPreviewData } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

const BillingDocumentViewer = observer(() => {
  const {
    billing: { ui }
  } = useStores();

  const closeDocumentViewer = () => ui.setPreviewDocumentData(undefined);

  const getDocument = (data: InvoiceDocumentPreviewData): Document => ({
    documentId: data.documentId,
    extension: DocumentContentType.Pdf,
    entityId: data.patientId,
    entityType: DocumentEntityType.Patient,
    downloadUri: data.url,
    previewUri: data.url,
    name: `Invoice ${data.invoiceNumber}`
  });

  const data = ui.previewDocumentData;

  if (data) {
    return (
      <DocumentViewerDialog
        getDocument={(): Document => getDocument(data)}
        downloadInNewTab
        closeDocumentViewer={closeDocumentViewer}
      />
    );
  }
  return null;
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default BillingDocumentViewer;
