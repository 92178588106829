import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useFormState } from "react-final-form";

import { FontIcon, ILinkProps, Link, Stack, TooltipHost } from "@bps/fluent-ui";
import { useDialogOpenedAnalytics } from "@libs/analytics/hooks/useDialogOpenedAnalytics.ts";
import { CalendarEventStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface AddFromWaitingListButtonProps {
  linkStyles?: ILinkProps["styles"];
}

export const AddFromWaitingListButton: React.FC<AddFromWaitingListButtonProps> =
  observer(({ linkStyles }) => {
    const { core } = useStores();

    const {
      showWaitingListDialog,
      setShowWaitingListDialog,
      isGroupAppointment,
      isAppointmentValidForEdit,
      patientLabel
    } = useContext(AppointmentFormContext);

    useDialogOpenedAnalytics(
      "Add patient from waiting list",
      core.user?.fullName,
      !showWaitingListDialog
    );

    const {
      providerId,
      startDate,
      startTime,
      patientId,
      groupAttendees,
      maxParticipants
    } = useFormState<AppointmentFormValues>({
      subscription: { values: true }
    }).values;

    const isDisabled =
      providerId === undefined ||
      startDate === undefined ||
      startTime === undefined ||
      (isGroupAppointment
        ? (groupAttendees?.filter(
            a => a.status !== CalendarEventStatus.Cancelled
          ).length ?? 0) >= (maxParticipants ?? 0) || isAppointmentValidForEdit
        : !!patientId);

    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <Link
          id="add-patient-toWL-toggle"
          label="Add to waiting list"
          disabled={isDisabled}
          onClick={() => {
            setShowWaitingListDialog(true);
          }}
          styles={linkStyles}
        >
          {`Add ${patientLabel} from waiting list`}
        </Link>
        {isDisabled && !isGroupAppointment && (
          <TooltipHost content="Select provider, date, and time to be able to add from the waiting list">
            <FontIcon iconName="Error" />
          </TooltipHost>
        )}
      </Stack>
    );
  });
