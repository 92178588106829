import { Frequency } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";

const isValidMedicareProviderNumber = (val: string) => {
  if (val.charAt(0).match(/[a-z]/i)) {
    return checkAncillaryProviderNo(val);
  }
  return checkProviderNo(val);
};

const checkAncillaryProviderNo = (providerNo: string) => {
  const values = [3, 5, 8, 4, 2, 1];
  const stateCodes = new Map([
    ["A", 1],
    ["N", 2],
    ["V", 3],
    ["Q", 4],
    ["S", 5],
    ["W", 6],
    ["T", 7],
    ["Z", 8],
    ["C", 9],
    ["D", 9],
    ["E", 9]
  ]);

  const checkDigits = new Map([
    ["Y", 0],
    ["X", 1],
    ["W", 2],
    ["T", 3],
    ["L", 4],
    ["K", 5],
    ["J", 6],
    ["H", 7],
    ["F", 8],
    ["B", 9],
    ["A", 10]
  ]);

  const parseProviderNo = providerNo.toUpperCase().replace(" ", "");

  const stem = parseProviderNo.substring(0, 6);
  const stateCode = parseProviderNo.substring(6, 7);
  const check = parseProviderNo.substring(7, 8);
  let checkValue;
  const DIVISOR = 11;

  if (checkDigits.has(check)) {
    checkValue = checkDigits.get(check);
  } else {
    return false;
  }

  if (checkValue && checkValue > 0) {
    let checkCalc = 0;

    if (stateCodes.has(stateCode)) {
      checkCalc = stateCodes.get(stateCode) || 0;
    } else {
      return false;
    }

    if (checkCalc > 0) {
      checkCalc = checkCalc * 3;
    }

    for (let i = 0; i < 6; i++) {
      checkCalc += parseInt(stem[i]) * values[i];
    }

    if (checkCalc % DIVISOR === checkValue) {
      return true;
    }
  }
  return false;
};

const checkProviderNo = (providerNo: string) => {
  const values = [3, 5, 8, 4, 2, 1];

  const plvValues = new Map([
    ["0", 0],
    ["1", 1],
    ["2", 2],
    ["3", 3],
    ["4", 4],
    ["5", 5],
    ["6", 6],
    ["7", 7],
    ["8", 8],
    ["9", 9],
    ["A", 10],
    ["B", 11],
    ["C", 12],
    ["D", 13],
    ["E", 14],
    ["F", 15],
    ["G", 16],
    ["H", 17],
    ["J", 18],
    ["K", 19],
    ["L", 20],
    ["M", 21],
    ["N", 22],
    ["P", 23],
    ["Q", 24],
    ["R", 25],
    ["T", 26],
    ["U", 27],
    ["V", 28],
    ["W", 29],
    ["X", 30],
    ["Y", 31]
  ]);

  const checkDigits = new Map([
    ["Y", 0],
    ["X", 1],
    ["W", 2],
    ["T", 3],
    ["L", 4],
    ["K", 5],
    ["J", 6],
    ["H", 7],
    ["F", 8],
    ["B", 9],
    ["A", 10]
  ]);

  let parseProviderNo = providerNo.toUpperCase().replace(" ", "");

  if (parseProviderNo.length < 8) {
    parseProviderNo = parseProviderNo.padStart(8, "0");
  }

  const stem = parseProviderNo.substring(0, 6);
  const plv = parseProviderNo.substring(6, 7);
  const check = parseProviderNo.substring(7, 8);
  const DIVISOR = 11;
  let checkValue;
  let plvValue;

  if (checkDigits.has(check)) {
    checkValue = checkDigits.get(check);
  } else {
    return false;
  }

  if (checkValue && checkValue > 0) {
    let checkCalc = 0;

    for (let i = 0; i < 6; i++) {
      checkCalc += parseInt(stem[i]) * values[i];
    }

    if (plvValues.has(plv)) {
      plvValue = plvValues.get(plv);
    } else {
      return false;
    }

    if (plvValue && plvValue > 0) {
      checkCalc += plvValue * 6;
    }

    if (checkCalc % DIVISOR === checkValue) {
      return true;
    }
  }
  return false;
};

export const medicareProviderNumberValidator: FieldValidator = (
  val: string | undefined
) => {
  return !val || isValidMedicareProviderNumber(val)
    ? undefined
    : ValidationMessages.invalid;
};

export const getFrequencyKeyTextOptions = (
  interval: number
): Array<{ key: Frequency; text: string }> => [
  {
    key: Frequency.Week,
    text: interval > 1 ? "Weeks" : "Week"
  },
  {
    key: Frequency.Month,
    text: interval > 1 ? "Months" : "Month"
  },
  {
    key: Frequency.Year,
    text: interval > 1 ? "Years" : "Year"
  }
];
