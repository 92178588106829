import { createContext } from "react";

import { AccInvoicesListHelper } from "./AccInvoicesListHelper.ts";

// Empty object is provided as default context as unable to create a class without a RootStore
// This will result in the app or tests breaking if a context value isn't provided

export const AccInvoicesListContext = createContext<AccInvoicesListHelper>(
  {} as AccInvoicesListHelper
);
