import { Country } from "@libs/enums/country.enum.ts";
import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { RefCountry } from "@stores/core/models/Address.ts";

import { AddressValidator } from "../../../../shared-components/validation/AddressValidator.ts";
import { ContactEditFormValues } from "../PatientEditFormValues.tsx";
import { CommunicationValidator } from "./CommunicationValidator.ts";

export class ContactFormValidator extends Validator<ContactEditFormValues> {
  constructor(countries: readonly RefCountry[], country: Country) {
    super();
    const addressValidator = new AddressValidator({ countries, country });
    const commsValidator = new CommunicationValidator(country);

    this.forField("firstName", [required(), maxLength(40)]);
    this.forField("lastName", [required(), maxLength(40)]);

    this.forArrayField("communications", commsValidator.validate);
    this.forArrayField("addresses", addressValidator.validate);
  }
}
