import { useField } from "react-final-form";

import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { getCalculation } from "@modules/billing/screens/allocation/utils.ts";
import { AllocationFieldValues } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import {
  getCreditedAmount,
  getVoidedAmount
} from "@modules/billing/screens/shared-components/allocation-field/utils.tsx";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import {
  LabelData,
  StatusLabel
} from "@modules/billing/screens/shared-components/StatusLabel.tsx";
import { Invoice } from "@stores/billing/models/Invoice.ts";

interface CreditNoteStatusLabelProps {
  invoice: Invoice;
}

export const CreditNoteStatusLabel: React.FC<CreditNoteStatusLabelProps> = ({
  invoice
}) => {
  const {
    input: { value: allocations }
  } = useField<AllocationFieldValues[]>(allocationNameOf("allocations"), {
    subscription: { value: true }
  });

  const labelsData: LabelData[] = [
    {
      id: "invoice",
      data: {
        label: "Invoice ($)",
        value: currencyFormat(invoice.total || 0, {
          currency: ""
        })
      }
    },
    {
      id: "Credit-total",
      data: {
        label: "Credit ($)",
        value: currencyFormat(
          getCalculation({
            items: allocations,
            iterator: getCreditedAmount
          }),
          {
            currency: ""
          }
        )
      }
    },
    {
      id: "Void",
      data: {
        label: "Void ($)",
        tooltipText: "In the invoice voided items have no owing",
        value: currencyFormat(
          getCalculation({
            items: allocations,
            iterator: getVoidedAmount
          }),
          {
            currency: ""
          }
        )
      }
    }
  ];
  return <StatusLabel childrenGap={80} labelData={labelsData} />;
};
