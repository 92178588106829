import {
  ClinicalDataType,
  DASHQuestionnaireResponseDto,
  MeasurementType,
  QuestionnaireDto,
  QuestionnaireItem,
  QuestionnaireItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";

import { ClinicalToolFormModel } from "../clinical-tool-form/ClinicalToolFormModel.ts";
import { DashFormValues } from "./DASHForm.types.ts";

export class DASHFormModel {
  private clinicalRecord: ClinicalRecord;
  private clinical: ClinicalStore;
  private savedResponse?: DASHQuestionnaireResponseDto;
  private readonly onMeasurementSaved: (measurement: Measurement[]) => void;

  constructor(
    clinicalRecord: ClinicalRecord,
    clinicalStore: ClinicalStore,
    onMeasurementSaved: (measurement: Measurement[]) => void
  ) {
    this.clinicalRecord = clinicalRecord;
    this.clinical = clinicalStore;
    this.onMeasurementSaved = onMeasurementSaved;
    this.savedResponse = this.clinicalRecord.clinicalData
      ? this.clinicalRecord.clinicalData["dash"]
      : undefined;
  }

  get initialValues(): DashFormValues | undefined {
    return this.stashedDashClinicalData
      ? {
          confidential: !!this.stashedDashClinicalData.secGroupId,
          items: this.stashedDashClinicalData?.items?.reduce(
            (
              acc: Record<string, string>,
              i: QuestionnaireItem,
              idx: number
            ) => {
              return {
                ...acc,
                [ClinicalToolFormModel.getKey(idx)]: i.answer
              };
            },
            {}
          )
        }
      : undefined;
  }

  get workModuleOpen() {
    const values = this.initialValues;

    if (values) {
      const q12Answer = values.items["q12"];
      const q13Answer = values.items["q13"];
      const q14Answer = values.items["q14"];
      const q15Answer = values.items["q15"];
      const q16Answer = values.items["q16"];
      return !!(q12Answer || q13Answer || q14Answer || q15Answer || q16Answer);
    }
    return false;
  }

  get sportModuleOpen() {
    const values = this.initialValues;
    if (values) {
      const q17Answer = values.items["q17"];
      const q18Answer = values.items["q18"];
      const q19Answer = values.items["q19"];
      const q20Answer = values.items["q20"];
      const q21Answer = values.items["q21"];
      return !!(q20Answer || q17Answer || q18Answer || q19Answer || q21Answer);
    }

    return false;
  }

  get stashedDashClinicalData() {
    return this.clinicalRecord.stashedClinicalData?.dash;
  }

  get dashClinicalData() {
    return this.clinicalRecord.clinicalData?.dash;
  }

  public getStashedClinicalData =
    (questionnaire: QuestionnaireDto) =>
    (values: DashFormValues): Pick<EncounterClinicalData, "dash"> => {
      const secGroupId = !!values.confidential
        ? this.clinicalRecord.core.user?.privateSecGroupId
        : undefined;
      if (this.dashClinicalData) {
        const items = this.stashedDashClinicalData?.items ?? [];

        return {
          dash: {
            ...this.stashedDashClinicalData,
            questionnaireId: questionnaire.id,
            questionnaireCode: questionnaire.code,
            items: items?.map((item: QuestionnaireItem, idx: number) => ({
              ...item,
              answer: values.items[ClinicalToolFormModel.getKey(idx)]
            })),
            secGroupId
          }
        };
      } else {
        return {
          dash: {
            items: questionnaire.items?.map(
              (_item: QuestionnaireItemDto, idx: number) => {
                return {
                  answer: values.items[ClinicalToolFormModel.getKey(idx)]
                };
              }
            ),
            secGroupId
          }
        } as Pick<EncounterClinicalData, "dash">;
      }
    };

  onSubmit =
    (questionnaire: QuestionnaireDto) => async (values: DashFormValues) => {
      const stashedClinicalData =
        this.getStashedClinicalData(questionnaire)(values);

      const eTag = this.dashClinicalData?.eTag;

      const items = stashedClinicalData.dash?.items ?? [];

      const payload: Partial<EncounterClinicalData> = {
        dash: {
          ...stashedClinicalData.dash,
          eTag,
          questionnaireId: questionnaire.id,
          questionnaireCode: questionnaire.code,
          items: items.map((i: QuestionnaireItem, idx: number) => ({
            answer: i.answer,
            id: i.id,
            questionnaireItemId: questionnaire.items[idx].id
          }))
        }
      };

      await this.clinicalRecord.saveClinicalData(payload);

      const result = await this.clinical.getMeasurements({
        patientId: this.clinicalRecord.patient?.id,
        encounterId: this.clinicalRecord.openEncounter?.id,
        types: [MeasurementType.DASH]
      });

      const measurements = result.results;

      if (measurements && measurements.length > 0) {
        this.clinicalRecord.updateMeasurements(measurements);
        this.onMeasurementSaved(measurements);
      } else {
        this.clinical.root.notification.error(
          "Unable to retrieve the total clinical tool scores."
        );
      }
    };

  onCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.DASH
    );
    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["dash"]);
  };
}
