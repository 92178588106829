import { action, observable } from "mobx";

import { BhbAppointmentTypeSortOrderDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import {
  IMaybePromiseObservable,
  maybePromiseObservable
} from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { AppointmentTypesFilter } from "@shared-types/booking/appointment-type-filter.type.ts";
import { BhbAppointmentType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { AppointmentTypeScreenItem } from "../AppointmentTypeScreen.types.ts";

export class AppointmentTypeScreenModel {
  constructor(private root: RootStore) {
    this.appointmentTypes = maybePromiseObservable();
  }

  @observable
  public appointmentType: AppointmentType | null | undefined = null;

  appointmentTypes: IMaybePromiseObservable<AppointmentType[]>;

  @action
  public setAppointmentType = (value: AppointmentType | null | undefined) => {
    this.appointmentType = value;
  };

  @observable
  public bhbAppointmentType?: BhbAppointmentType;

  @action
  public setBHBAppointmentType = (value?: BhbAppointmentType) => {
    this.bhbAppointmentType = value;
  };

  @observable
  public showOnlineSettingModal: boolean = false;

  @action
  public setShowOnlineSettingModal = (value: boolean) => {
    this.showOnlineSettingModal = value;
  };

  @observable
  public showOnlineAppointmentTypeSettingModal: boolean = false;

  @observable
  public bhbAppointmentTypes: BhbAppointmentType[] = [];

  @action
  public setBhbAppointmentTypes = (value: BhbAppointmentType[] | undefined) => {
    this.bhbAppointmentTypes = value ?? [];
  };

  @action
  public setShowOnlineAppointmentTypeSettingModal = (value: boolean) => {
    this.showOnlineAppointmentTypeSettingModal = value;
  };

  @observable
  public omniAppointmentType?: AppointmentType;

  @action
  public setOmniAppointmentType = (value?: AppointmentType) => {
    this.omniAppointmentType = value;
  };

  closeOnlineSettingModal = () => {
    this.setShowOnlineSettingModal(false);
    this.setOmniAppointmentType(undefined);
    this.setBHBAppointmentType(undefined);
  };

  closeOnlineAppointmentTypeSettingModal = () => {
    this.setShowOnlineAppointmentTypeSettingModal(false);
    this.setOmniAppointmentType(undefined);
    this.setBHBAppointmentType(undefined);
  };

  getItems = (
    appointmentTypes: AppointmentType[],
    bhbAppointmentTypes: BhbAppointmentType[] | undefined
  ): AppointmentTypeScreenItem[] => {
    return appointmentTypes.map(appointmentType => ({
      appointmentType,
      bhbAppointmentType: bhbAppointmentTypes?.find(
        bat => bat.id === appointmentType.id
      )
    }));
  };

  getItemsForOnlineFilter = (
    appointmentTypes: AppointmentType[],
    bhbAppointmentTypes: BhbAppointmentType[] | undefined,
    appointmentTypeFilterValues: AppointmentTypesFilter | undefined
  ): AppointmentTypeScreenItem[] => {
    const bhbAppointmentTypesModified = bhbAppointmentTypes?.filter(
      bhbAppointmentType =>
        this.filterData(bhbAppointmentType, appointmentTypeFilterValues)
    );

    const bhbAppointmentTypesSorted = this.getSortedAppointmentTypes(
      bhbAppointmentTypesModified!
    );

    const appointmentTypesModified = appointmentTypes.filter(
      appointmentType =>
        bhbAppointmentTypesModified?.find(
          bhbAppointmentType => bhbAppointmentType.id === appointmentType.id
        ) && !appointmentType.isGroupAppointmentType
    );

    const checkout: {
      bhbAppointmentType: BhbAppointmentType;
      appointmentType?: AppointmentType;
    }[] = bhbAppointmentTypesSorted.map(bhbAppointmentType => ({
      bhbAppointmentType,
      appointmentType: appointmentTypesModified.find(
        appointType => appointType.id === bhbAppointmentType.id
      )
    }));

    const filtered = checkout.filter(
      ({ appointmentType }) => appointmentType !== undefined
    );

    return filtered;
  };

  getSortedAppointmentTypes = (appointmentTypes: BhbAppointmentType[]) => {
    return (
      Array.from(appointmentTypes).sort((left, right) => {
        if (
          (left.isAvailableExistingPatients &&
            right.isAvailableExistingPatients) ||
          (left.isAvailableNewPatients && right.isAvailableNewPatients) ||
          (left.isAvailableExistingPatients && right.isAvailableNewPatients) ||
          (left.isAvailableNewPatients && right.isAvailableExistingPatients)
        ) {
          if (
            typeof left.sortOrder === "number" &&
            typeof right.sortOrder === "number"
          ) {
            const sortOrderResult = left.sortOrder - right.sortOrder;
            if (sortOrderResult !== 0) return sortOrderResult;
          }
          return left.name.localeCompare(right.name);
        }

        if (left.isAvailableExistingPatients || left.isAvailableNewPatients)
          return -1;

        return 1;
      }),
      appointmentTypes
    );
  };

  updateSorting = (
    targetItem: AppointmentTypeScreenItem,
    droppedItem: AppointmentTypeScreenItem
  ) =>
    this.updateAppointmentTypeSorting(
      targetItem.bhbAppointmentType,
      droppedItem.bhbAppointmentType
    );

  updateAppointmentTypeSorting = (
    targetItem: BhbAppointmentType | undefined,
    droppedItem: BhbAppointmentType | undefined
  ) => {
    if (
      (typeof targetItem?.sortOrder !== "number" ||
        typeof droppedItem?.sortOrder !== "number") &&
      !this.bhbAppointmentTypes.find(
        x =>
          x.id === targetItem?.id &&
          (targetItem.isAvailableNewPatients ||
            targetItem.isAvailableExistingPatients)
      )
    )
      return;

    let newSortOrder = 0;
    const reOrdered: BhbAppointmentTypeSortOrderDto[] =
      this.bhbAppointmentTypes.reduce(
        (acc: BhbAppointmentTypeSortOrderDto[], item: BhbAppointmentType) => {
          if (item.id === targetItem?.id) {
            if (
              droppedItem?.sortOrder &&
              targetItem.sortOrder &&
              droppedItem.sortOrder > targetItem.sortOrder
            ) {
              return [
                ...acc,
                {
                  id: droppedItem?.id,
                  sortOrder: ++newSortOrder
                },
                {
                  id: targetItem?.id,
                  sortOrder: ++newSortOrder
                }
              ];
            } else {
              return [
                ...acc,
                {
                  id: targetItem.id,
                  sortOrder: ++newSortOrder
                },
                {
                  id: droppedItem?.id,
                  sortOrder: ++newSortOrder
                }
              ];
            }
          } else if (item.id !== droppedItem?.id) {
            return [...acc, { id: item.id, sortOrder: ++newSortOrder }];
          }
          return acc;
        },
        []
      );
    return reOrdered;
  };

  updateBhbAppointmentTypeSorting = async (
    appTypes: BhbAppointmentType[],
    bhbAppointmentTypeId: string
  ) => {
    if (appTypes !== undefined) {
      const max = appTypes.reduce((maxSort, appType) => {
        return Math.max(maxSort, appType.sortOrder || 0);
      }, 0);

      const bhbAppointmentType = appTypes.find(x => x.sortOrder === max);

      const targetItem = appTypes.find(x => x.id === bhbAppointmentType?.id);
      const droppedItem = appTypes.find(x => x.id === bhbAppointmentTypeId);

      const reorder = this.updateAppointmentTypeSorting(
        targetItem,
        droppedItem
      );
      if (reorder !== undefined) {
        const updatedBhbAppointmentTypes =
          await this.root.bhb.updateAppointmentTypeSortOrderForLocation(
            reorder
          );
        this.setBhbAppointmentTypes(updatedBhbAppointmentTypes);
      } else {
        this.setBhbAppointmentTypes(appTypes);
      }
    }
  };

  @action
  filterData = (
    x: BhbAppointmentType,
    filters: AppointmentTypesFilter | undefined
  ) => {
    if (!filters) {
      return true;
    }

    const filtersEnabled = filters.availabilities;

    if (!filtersEnabled || !filtersEnabled.length) {
      return true;
    }

    const isNPOnly = filters.availabilities?.includes("NPOnly");
    const isEPOnly = filters.availabilities?.includes("EPOnly");
    const isAll = filters.availabilities?.includes("All");
    const isUnavailable = filters.availabilities?.includes("Unavailable");

    if (isAll) {
      if (x.isAvailableExistingPatients && x.isAvailableNewPatients)
        return true;
    }

    if (isUnavailable) {
      if (
        x.isAvailableExistingPatients === null &&
        x.isAvailableNewPatients === null
      )
        return true;
    }

    if (isNPOnly) {
      if (!x.isAvailableExistingPatients && x.isAvailableNewPatients)
        return true;
    }

    if (isEPOnly) {
      if (x.isAvailableExistingPatients && !x.isAvailableNewPatients)
        return true;
    }

    return false;
  };
}
