import { useCallback, useEffect, useState } from "react";

import { PagingOptions } from "@libs/api/dtos/index.ts";
import { ContactFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  convertSearchValueToBirthdayString,
  hasValidBirthdayData,
  hasValidNameData,
  hasValidPhoneNumberData
} from "@ui-components/pickers/contact-picker/contact-picker.utils.ts";

export const useContactListSearch = (filter: ContactFilter) => {
  const { practice } = useStores();

  const [recentlyAddedContact, setRecentlyAddedContact] = useState<Contact>();

  useEffect(() => {
    // This list has special behaviour where if a contact was recently added then it appears at the top
    //  practice.ui.recentlyAddedContactId gets cleared after a few seconds so we have to save its value
    if (practice.ui.recentlyAddedContactId) {
      const contact = practice.contactsMap.get(
        practice.ui.recentlyAddedContactId
      );
      setRecentlyAddedContact(contact);
    }
  }, [practice.ui.recentlyAddedContactId, practice.contactsMap]);

  return useCallback(
    (query: PagingOptions) => {
      const searchValue = filter.search ?? "";

      const isValidBirthdayDate = hasValidBirthdayData(searchValue);
      const isValidPhoneNumber = hasValidPhoneNumberData(searchValue);
      const isValidName = hasValidNameData(searchValue);
      const birthdayString = convertSearchValueToBirthdayString(searchValue);

      return practice
        .fetchContacts({
          filter: {
            ...query,
            ...filter,
            search:
              isValidName && !isValidPhoneNumber ? searchValue : undefined,
            birthday: isValidBirthdayDate ? birthdayString : undefined,
            phoneNumber: isValidPhoneNumber ? searchValue : undefined
          }
        })
        .then(result => {
          if (!recentlyAddedContact) return result;
          return {
            ...result,
            results: [
              recentlyAddedContact,
              ...result.results.filter(
                contact => contact.id !== recentlyAddedContact.id
              )
            ]
          };
        });
    },
    [filter, practice, recentlyAddedContact]
  );
};
