import { action, computed, observable, runInAction } from "mobx";

import { IDropdownOption } from "@bps/fluent-ui";
import { DateTime, getUniqueObjectsByKeys, groupBy } from "@bps/utils";
import {
  AddInvoiceDto,
  BillingStatuses,
  InvoiceItemDto,
  ItemType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { mapContactToTransactionContact } from "@modules/billing/billing.utils.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { DraftInvoiceGenerateFormValues } from "../DraftInvoiceGenerateModal.type.ts";
import { DraftInvoiceGenerateValidator } from "../DraftInvoiceGenerateValidator.tsx";

export class DraftInvoiceGenerateHelper {
  constructor(private root: RootStore) {}

  @observable invoiceItems: InvoiceItemDto[] = [];

  nameOf = nameOfFactory<DraftInvoiceGenerateFormValues>();

  public isDraftInvoiceFormReady = (values: DraftInvoiceGenerateFormValues) => {
    const validator = new DraftInvoiceGenerateValidator(this.invoiceItems);
    return validator.validate(values);
  };

  @computed
  get optionsForPatients(): IDropdownOption[] {
    const uniquePatients = getUniqueObjectsByKeys({
      array: this.invoiceItems,
      keys: ["patientId"]
    });

    const dropdownOptions: IDropdownOption[] =
      uniquePatients.length > 0
        ? uniquePatients?.map(x => {
            return {
              key: x.patientId,
              text: `${x.patient?.lastName ?? ""}, ${
                x.patient?.firstName ?? ""
              }`
            };
          })
        : [{ key: "", text: "" }];

    return dropdownOptions;
  }

  @computed
  get optionsForProvider(): IDropdownOption[] {
    const uniqueProviders = getUniqueObjectsByKeys({
      array: this.invoiceItems,
      keys: ["userId"]
    });

    const dropdownOptions: IDropdownOption[] =
      uniqueProviders.length > 0
        ? uniqueProviders?.map(x => {
            return {
              key: x.userId,
              text: `${x.user?.lastName ?? ""}, ${x.user?.firstName ?? ""}`,
              selected: x.userId === this.root.core.userId
            };
          })
        : [{ key: "", text: "" }];

    return dropdownOptions;
  }

  @action
  getItems = async (
    billToAccountId: string | undefined,
    cutOffDate: Date | undefined
  ) => {
    if (billToAccountId === undefined || billToAccountId.length === 0) {
      runInAction(() => {
        this.invoiceItems = [];
      });
      return;
    }

    const items = await this.root.billing
      .fetchInvoiceItems({
        accountIds: billToAccountId ? [billToAccountId] : undefined,
        draftOnly: true
      })
      .then(x =>
        x.results.filter(
          i =>
            DateTime.fromISO(i.serviceDate) <=
            (cutOffDate ? cutOffDate : DateTime.now())
        )
      );

    runInAction(() => {
      this.invoiceItems = items;
    });
  };

  handleSubmitAction = async (values: DraftInvoiceGenerateFormValues) => {
    const finalInvoiceItems = this.invoiceItems.filter(
      x =>
        values.selectedPatient === x.patientId &&
        values.selectedProvider === x.userId
    );

    const groupedInvoiceItems = groupBy(
      finalInvoiceItems,
      item => item.claimId
    );

    const accountContact = await this.root.practice.getContact(
      values.accountId
    );

    const invoices: Invoice[] = await Promise.all(
      groupedInvoiceItems.map(async item => {
        const invoiceNumber = await this.root.billing.generateInvoiceNumber();
        const addInvoiceDto = this.getAddInvoiceDto(
          item[1],
          invoiceNumber,
          accountContact
        );

        return await this.root.billing.addInvoice(addInvoiceDto);
      })
    );

    return invoices;
  };

  getAddInvoiceDto = (
    invoiceItems: InvoiceItemDto[],
    invoiceNumber: string,
    contact: Contact
  ): AddInvoiceDto => {
    const accountContact = mapContactToTransactionContact(contact);

    return {
      number: invoiceNumber,
      transactionDate: DateTime.now().toISODate(),
      accountId: contact.id,
      accountContact,
      location: this.root.core.location.name,
      status: BillingStatuses.current,
      itemType: ItemType.Invoice,
      items: invoiceItems
    };
  };
}
