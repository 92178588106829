import { observer } from "mobx-react-lite";

import { FontIcon, Stack, Text, useTheme } from "@bps/fluent-ui";
import { ClaimStatusBadge } from "@modules/acc/screens/shared-components/claim-status-badge/ClaimStatusBadge.tsx";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";

import { getDischargeStyles } from "./ConditionsSidePanel.styles.ts";

interface DischargeOverviewProps {
  condition: ICondition;
}

export const DischargeOverview: React.FC<DischargeOverviewProps> = observer(
  ({ condition }) => {
    const theme = useTheme();
    const { clinicalRecord } = usePatientRecordScreenContext();

    const dischargeStyles = getDischargeStyles(theme);
    const dischargeStatus = clinicalRecord.getDischargeStatus(
      clinicalRecord.openEncounter?.businessRole
    );

    const claimStatus = condition?.claim?.claimStatus;
    const prevClaimStatus = condition?.claim?.previousClaimStatus;

    return (
      <Stack tokens={{ childrenGap: 8 }} styles={dischargeStyles.dischargeBox}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          {claimStatus &&
            (!condition?.discharged ||
              (condition?.discharged &&
                dischargeStatus === DischargeStatus.Reversed)) && (
              <ClaimStatusBadge
                claimStatus={
                  condition?.discharged ? prevClaimStatus : claimStatus
                }
              />
            )}
          {condition?.discharged &&
            dischargeStatus !== DischargeStatus.Reversed && (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 8 }}
              >
                <FontIcon iconName="Leave" />
                <Text>Discharged</Text>
              </Stack>
            )}
        </Stack>
      </Stack>
    );
  }
);
