export enum ServiceItemLabels {
  fee = "Fee",
  tax = "GST",
  type = "Type",
  state = "State",
  active = "Active",
  newFee = "New fee",
  service = "Service",
  details = "Details",
  inactive = "Inactive",
  material = "Material",
  itemNumber = "Item #"
}
