import React from "react";

import {
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { NotificationPriority } from "@libs/gateways/notification/NotificationGateway.dtos.ts";
import { SystemNotification } from "@stores/notifications/models/SystemNotification.ts";

interface SystemNotificationBadgesProps {
  notification: SystemNotification;
}

export const SystemNotificationBadges: React.FC<
  SystemNotificationBadgesProps
> = ({ notification }) => {
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 8 }}
      styles={{ root: { marginRight: "auto", alignItems: "center" } }}
    >
      {notification.priority === NotificationPriority.High && (
        <TextBadge
          badgeColor={TextBadgeColor.red}
          badgeSize={TextBadgeSize.small}
        >
          {notification.priority}
        </TextBadge>
      )}
      <Text variant="xSmall">{notification.type.toUpperCase()}</Text>
    </Stack>
  );
};
