import { useEffect } from "react";
import { useForm } from "react-final-form";

import { IDropdownOption, Stack } from "@bps/fluent-ui";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";

import { DraftInvoiceGenerateFormValues } from "./DraftInvoiceGenerateModal.type.ts";

interface DropDownProps {
  options: IDropdownOption<any>[];
  fieldName: keyof DraftInvoiceGenerateFormValues;
  label: string;
}

export const DraftDropdown: React.FC<DropDownProps> = ({
  options,
  fieldName,
  label
}) => {
  const form = useForm<DraftInvoiceGenerateFormValues>();

  useEffect(() => {
    if (options.find(x => x.selected)) {
      form.change(fieldName, options.find(x => x.selected)?.key.toString());
    } else {
      form.change(fieldName, "");
    }
  }, [form, options, fieldName]);

  return (
    <Stack>
      <DropdownField
        options={options}
        name={fieldName}
        label={label}
        required={true}
        withNoEmptyOption={true}
      />
    </Stack>
  );
};
