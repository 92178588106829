import { FontIcon, Stack, Text } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";

import { OverviewSectionListIcon } from "./OverviewSectionListIcon.tsx";

export const Treatments = () => {
  const { claimAdjustment } = useClaimAdjustmentContext();
  const { approvedVisits, requestedTreatments } = claimAdjustment!;
  return (
    <Stack
      as="li"
      verticalAlign="center"
      horizontal
      tokens={{ childrenGap: 4 }}
    >
      {typeof approvedVisits === "undefined" && (
        <FontIcon iconName="LocationDot" />
      )}
      {typeof approvedVisits !== "undefined" ? (
        <>
          <OverviewSectionListIcon approved={approvedVisits > 0} />
          {approvedVisits < requestedTreatments ? (
            <Text
              block
            >{`${approvedVisits} (requested ${requestedTreatments})`}</Text>
          ) : (
            <Text>{approvedVisits}</Text>
          )}
        </>
      ) : (
        <Text>{requestedTreatments}</Text>
      )}
    </Stack>
  );
};
