import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { Field } from "react-final-form";

import {
  ButtonsGroupOption,
  Heading,
  Separator,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  PatchOrgUnitDto,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  NzOrgUnitBusinessDto,
  NzOrgUnitIdentifierDto,
  OrgUnitCompanyDataDto,
  OrgUnitCompanyDataType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  PatientDisplayCode,
  TenantSettingDto
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { nameof, nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { getFileFromUrl } from "@modules/settings/screens/bhb/utils.ts";
import { settings } from "@shared-types/user-experience/localisation-settings.constant.ts";
import { InvoiceSettings } from "@stores/billing/models/InvoiceSettings.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { OrgUnitSetting } from "@stores/user-experience/models/OrgUnitSetting.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { TenantFormLabels } from "../../../shared-components/SettingsLabels.ts";
import { getUserStylesSet } from "../../../users/components/UserScreens.styles.tsx";
import { getInitialsText } from "../../utils.tsx";
import { PracticeDetailsLocations } from "../practice-locations/PracticeDetailsLocations.tsx";
import { ResetUsersControlField } from "../ResetUsersControlField.tsx";
import {
  PracticeDetailsFormLabels,
  PracticeDetailsValues
} from "./PracticeDetailsForm.types.tsx";
import { PracticeDetailsValidator } from "./PracticeDetailsValidator.tsx";
import {
  PracticeProfilePicture,
  PraticeDetailsabel
} from "./PracticeProfilePicture.tsx";

export interface OrgUnits {
  pracOrgUnit?: PracOrgUnit;
  orgUnitSetting?: OrgUnitSetting;
  invoiceSetting?: InvoiceSettings;
}
interface PracticeDetailsFormProps {
  orgUnits?: OrgUnits;
}
const nameOf = nameOfFactory<PracticeDetailsValues>();

const PracticeDetailsFormBase: React.FC<PracticeDetailsFormProps> = observer(
  ({ orgUnits }) => {
    const { billing, core, notification, userExperience, practice, bhb } =
      useStores();

    const profilePictureStagingId = useRef<string | undefined>();

    const theme = useTheme();
    const { formFooter, formFields, settingsSeparator } =
      getUserStylesSet(theme);

    const buttonsOptions: ButtonsGroupOption<string>[] = [
      {
        key: PatientDisplayCode.Patient,
        text: capitalizeSentence(`${settings.PAT.patientDisplay}s`)
      },
      {
        key: PatientDisplayCode.Client,
        text: capitalizeSentence(`${settings.CLI.patientDisplay}s`)
      }
    ];

    const orgUnit = core.location;
    const validator = new PracticeDetailsValidator();

    const initialValues = {
      abn: orgUnits?.invoiceSetting?.abn,
      nzbn: orgUnits?.pracOrgUnit?.nzOrgUnitBusiness?.nzbn,
      businessName: orgUnit.parentOrgUnit?.name,
      tagLine: orgUnit.parentOrgUnit?.tagLine,
      patientDisplayCode: userExperience.tenantPatientLabel,
      userSettingsToReset: undefined,
      hpiOrganisationId:
        orgUnits?.pracOrgUnit?.nzOrgUnitIdentifier?.hpiOrganisationId,
      hpiOrganisationName:
        orgUnits?.pracOrgUnit?.nzOrgUnitIdentifier?.hpiOrganisationName,
      withProvider: orgUnits?.orgUnitSetting?.waitingRoom?.withProvider ?? true,
      finalised: orgUnits?.orgUnitSetting?.waitingRoom?.finalised ?? true,
      profilePictureUrl: orgUnits?.pracOrgUnit?.dto.profilePictureUrl
    };

    const getOrgUnitCompanyData = (
      initialValues: Partial<PracticeDetailsValues>,
      values: PracticeDetailsValues
    ) => {
      const dtos: OrgUnitCompanyDataDto[] = [];

      //save nzbn value to orgUnitCompanyData
      if (
        initialValues.nzbn !== values.nzbn &&
        core.tenantDetails?.country === Country.NewZealand
      ) {
        dtos.push({
          orgUnitCompanyDataTypeCode: OrgUnitCompanyDataType.NzOrgUnitBusiness,
          nzbn: values!.nzbn?.replace(/_/gi, "")
        } as NzOrgUnitBusinessDto);
      }

      //save hpiOrganisation value to orgUnitCompanyData
      if (
        (initialValues.hpiOrganisationId !== values.hpiOrganisationId ||
          initialValues.hpiOrganisationName !== values.hpiOrganisationName) &&
        core.tenantDetails?.country === Country.NewZealand
      ) {
        dtos.push({
          orgUnitCompanyDataTypeCode:
            OrgUnitCompanyDataType.NzOrgUnitIdentifier,
          hpiOrganisationId: values!.hpiOrganisationId,
          hpiOrganisationName: values!.hpiOrganisationName
        } as NzOrgUnitIdentifierDto);
      }

      return dtos;
    };

    const updatePracticeOrgUnit = (
      values: PracticeDetailsValues,
      initialValues: Partial<PracticeDetailsValues>
    ) => {
      const updateOrgUnitCompanyDataDto = getOrgUnitCompanyData(
        initialValues,
        values
      );

      return practice.updateOrgUnit({
        id: orgUnit.parentOrgUnit!.id,
        orgUnitCompanyData: updateOrgUnitCompanyDataDto
      });
    };

    const updateCoreOrgUnit = (
      values: PracticeDetailsValues,
      initialValues: Partial<PracticeDetailsValues>
    ) => {
      //save orgunit data if changed
      if (
        initialValues.businessName !== values.businessName ||
        initialValues.tagLine !== values.tagLine
      ) {
        const patchOrgUnitDto: Omit<PatchOrgUnitDto, "eTag"> = {
          id: core.location.parentOrgUnit!.id,
          name: values.businessName,
          tagLine: values.tagLine
        };

        return core.updateOrgUnit(patchOrgUnitDto);
      }

      return Promise.resolve();
    };

    const updateUXOrgUnitSetting = (
      values: PracticeDetailsValues,
      initialValues: Partial<PracticeDetailsValues>
    ) => {
      // Save waiting room settings if changed
      if (
        initialValues.withProvider !== values.withProvider ||
        initialValues.finalised !== values.finalised
      ) {
        const dto = {
          orgUnitId: core.location.parentOrgUnit!.id,
          eTag: orgUnits?.orgUnitSetting?.eTag ?? "",
          waitingRoom: {
            withProvider: values.withProvider,
            finalised: values.finalised
          }
        };
        return userExperience.updateOrgUnitSetting(dto);
      }

      return Promise.resolve();
    };

    const updateUXTenantSetting = (
      values: PracticeDetailsValues,
      initialValues: Partial<PracticeDetailsValues>
    ) => {
      //save tenant settings if changed
      if (initialValues.patientDisplayCode !== values.patientDisplayCode) {
        const tenantSettingDto: TenantSettingDto = {
          ...userExperience.tenantSetting?.dto,
          patientLabel: values.patientDisplayCode,
          eTag: userExperience.tenantSetting?.eTag ?? ""
        };
        return userExperience.updateTenantSetting(tenantSettingDto);
      }

      return Promise.resolve();
    };

    const resetUXUsersSetting = (values: PracticeDetailsValues) => {
      //save users to reset.
      if (
        values.userSettingsToReset &&
        values.userSettingsToReset?.length > 0
      ) {
        return values.userSettingsToReset.map(us =>
          userExperience.patchUserSetting({
            userId: us.userId,
            patientLabel: undefined
          })
        );
      }

      return [Promise.resolve()];
    };

    const updateBillingInvoiceSettings = (
      values: PracticeDetailsValues,
      initialValues: Partial<PracticeDetailsValues>
    ) => {
      //save invoice setttings if changed
      if (initialValues.abn !== values.abn && orgUnits?.invoiceSetting) {
        billing.updateInvoiceSettings({
          ...orgUnits.invoiceSetting.dto,
          business: {
            ...orgUnits.invoiceSetting.dto.business,
            businessType: OrgUnitCompanyDataType.AuOrgUnitBusiness,
            abn: values.abn
          }
        });
      }

      return Promise.resolve();
    };

    const updateBHBCompanyLogo = async () => {
      const practiceOrgId = orgUnits?.pracOrgUnit?.dto.id;
      if (!practiceOrgId) return;

      await bhb.getLocation();
      const pracOrgUnit = await practice.getOrgUnit(practiceOrgId, {
        ignoreCache: true
      });

      const practiceLogoFileBHB = pracOrgUnit?.profilePictureUrl
        ? await getFileFromUrl("practiceLogo", pracOrgUnit.profilePictureUrl)
        : undefined;

      const logoUrl =
        practiceLogoFileBHB && (await bhb.uploadLogo(practiceLogoFileBHB));

      await bhb.patchLocation({
        id: core.locationId,
        logoUrl
      });
    };

    const updateComponyLogoPicture = (values: PracticeDetailsValues) => {
      const fileStagingId = profilePictureStagingId.current;

      if (orgUnit.parentOrgUnit?.id) {
        if (values.profilePictureUrl) {
          fileStagingId &&
            practice.updateComponyLogoPicture({
              entityId: orgUnit.parentOrgUnit.id,
              fileStagingId
            });
        } else {
          practice.deleteComponyLogoPicture(orgUnit.parentOrgUnit.id);
        }
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Unable to update practice logo"));
      }
    };

    const onSubmit = async (
      values: PracticeDetailsValues,
      form: FormApi<PracticeDetailsValues, Partial<PracticeDetailsValues>>
    ) => {
      const initialValues = form.getState().initialValues;
      const promises: Promise<any>[] = [];

      const isProfilePictureUrlChanged =
        form.getFieldState("profilePictureUrl")?.dirty;

      promises.push(updateComponyLogoPicture(values));
      promises.push(updatePracticeOrgUnit(values, initialValues));
      promises.push(updateCoreOrgUnit(values, initialValues));
      promises.push(updateUXOrgUnitSetting(values, initialValues));
      promises.push(updateUXTenantSetting(values, initialValues));
      promises.concat(resetUXUsersSetting(values));
      promises.push(updateBillingInvoiceSettings(values, initialValues));

      await Promise.all(promises);
      if (
        isProfilePictureUrlChanged &&
        core.hasPermissions(Permission.BhbWrite)
      ) {
        await updateBHBCompanyLogo();
      }
    };

    return (
      <SubmissionForm<PracticeDetailsValues>
        formName="practice-details"
        onSubmitSucceeded={() => {
          notification.success(
            `${orgUnit.parentOrgUnit?.name || "Practice"} has been updated.`
          );
        }}
        autoFocus={false}
        onSubmit={onSubmit}
        readOnly={!core.hasPermissions(Permission.OrgUnitWrite)}
        validate={values => validator.validate(values)}
        initialValues={initialValues}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {renderProps => {
          return (
            <Stack
              styles={{
                root: formFields
              }}
              tokens={{ childrenGap: 8 }}
            >
              <Stack horizontal>
                <Field name={nameof("profilePictureUrl")}>
                  {({ input: { value } }) => (
                    <PracticeProfilePicture
                      onChange={url =>
                        renderProps.form.change(
                          nameof("profilePictureUrl"),
                          url
                        )
                      }
                      profilePictureUrl={value}
                      imageInitials={getInitialsText(
                        initialValues.businessName
                      )}
                      contact={undefined}
                      addPhotoLinkText={PraticeDetailsabel.newLogo}
                      removePhotoLinkText={PraticeDetailsabel.removeLogo}
                      profilePictureStagingId={profilePictureStagingId}
                    />
                  )}
                </Field>

                <Stack styles={{ root: { paddingLeft: 8, flexGrow: 1 } }}>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 8 }}
                    styles={{ root: { marginBottom: 8 } }}
                  >
                    <TextInputField
                      placeholder={PracticeDetailsFormLabels.businessName}
                      name={nameOf("businessName")}
                      label={PracticeDetailsFormLabels.businessName}
                    />
                    <WhenCountry is={Country.Australia}>
                      <TextInputField
                        placeholder={PracticeDetailsFormLabels.abn}
                        name={nameOf("abn")}
                        label={PracticeDetailsFormLabels.abn}
                        mask="99999999999"
                      />
                    </WhenCountry>
                    <WhenCountry is={Country.NewZealand}>
                      <TextInputField
                        placeholder={PracticeDetailsFormLabels.nzbn}
                        name={nameOf("nzbn")}
                        label={PracticeDetailsFormLabels.nzbn}
                        mask="9999999999999"
                      />
                    </WhenCountry>
                  </Stack>

                  <TextInputField
                    placeholder={PracticeDetailsFormLabels.practiceInformation}
                    name={nameOf("tagLine")}
                    label={PracticeDetailsFormLabels.practiceInformation}
                  />
                </Stack>
              </Stack>
              <WhenCountry is={Country.NewZealand}>
                <Separator styles={settingsSeparator} />
                <Heading variant="section-heading-light">
                  {PracticeDetailsFormLabels.hpi}
                </Heading>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <TextInputField
                    placeholder={PracticeDetailsFormLabels.organisationName}
                    name={nameOf("hpiOrganisationName")}
                    label={PracticeDetailsFormLabels.organisationNameAbbr}
                  />
                  <TextInputField
                    placeholder={PracticeDetailsFormLabels.organisationID}
                    name={nameOf("hpiOrganisationId")}
                    label={PracticeDetailsFormLabels.organisationIDAbbr}
                  />
                </Stack>
              </WhenCountry>
              <Separator styles={settingsSeparator} />
              <Heading variant="section-heading-light">
                {PracticeDetailsFormLabels.ourCustomers}
              </Heading>
              <Stack
                tokens={{ childrenGap: 12 }}
                styles={{
                  root: {
                    paddingTop: 0
                  }
                }}
              >
                <ButtonsGroupSingleChoiceField
                  label={TenantFormLabels.customersCalled}
                  name={nameof("patientDisplayCode")}
                  options={buttonsOptions}
                />
                <FieldSpy
                  name={nameof("patientDisplayCode")}
                  onChange={() => {
                    renderProps.form.change(
                      nameOf("userSettingsToReset"),
                      undefined
                    );
                  }}
                />
              </Stack>
              <When permission={Permission.UserSettingRead}>
                <ResetUsersControlField />
              </When>
              <Stack>
                <Text
                  styles={{
                    root: {
                      fontWeight: 600,
                      marginTop: 15,
                      marginBottom: 5
                    }
                  }}
                >
                  When should a patient appear in the waiting room?
                </Text>
                <Stack horizontal tokens={{ childrenGap: 18 }}>
                  <CheckboxField
                    label={PracticeDetailsFormLabels.withProvider}
                    name={nameOf("withProvider")}
                  />
                  <CheckboxField label="Finalised" name={nameOf("finalised")} />
                </Stack>
              </Stack>
              <PracticeDetailsLocations />
            </Stack>
          );
        }}
      </SubmissionForm>
    );
  }
);

export const PracticeDetailsForm = withFetch(
  x => [x.practice.ref.accPracticeContractTypes.load()],
  PracticeDetailsFormBase
);
