export enum IncomingLabels {
  Date = "Date",
  Test = "Test",
  NoMatch = "No match",
  Received = "Received",
  PrintedBy = "Printed by",
  Unassigned = "Unassigned",
  DatePrinted = "Date printed",
  PatientName = "Patient name",
  AddressedTo = "Addressed to",
  NotAddressed = "Not addressed",
  SearchPatient = "Search for patient",
  SearchFileName = "Search for file name",
  Subject = "Subject"
}
