import { DATE_FORMATS } from "@bps/utils";
import {
  isDateSameOrBeforeDate,
  maxLength,
  predicate,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  UploadDocFormLabels,
  UploadDocFormValues
} from "./UploadDocForm.types.ts";

interface UploadDocFormValidatorProps {
  destination: string;
  assignTo?: string;
  initialAssignedToUserId?: string;
  documentDate?: Date;
}

export class UploadDocFormValidator extends Validator<UploadDocFormValues> {
  constructor(options: UploadDocFormValidatorProps) {
    super();

    this.forField("docName", [required(), maxLength(50)]);
    this.forField("patient", [required()]);

    // validates only if a destination is inbox
    options.destination &&
      this.forField(
        "assignTo",
        predicate(
          () => options.destination === UploadDocFormLabels.Inbox,
          required()
        )
      );

    this.forField("correspondenceType", required());

    // validates only if a destination is clinical record
    options.destination &&
      this.forField(
        "storeIn",
        predicate(
          () => options.destination === UploadDocFormLabels.ClinicalRecord,
          required()
        )
      );

    // validates only if a documentDate is not empty
    options.documentDate &&
      this.forField("documentDate", [
        validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
        isDateSameOrBeforeDate(
          options.documentDate,
          ValidationMessages.uploadedOrEarlier
        )
      ]);
  }
}
