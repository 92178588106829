import {
  action,
  computed,
  IObservableArray,
  IReactionDisposer,
  observable,
  reaction,
  runInAction
} from "mobx";

import { DateTime } from "@bps/utils";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import {
  AppointmentStatusCode,
  AttendeeTypeEnum
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export class WaitingRoomModel {
  constructor(private root: RootStore) {
    this.interval = setInterval(() => {
      runInAction(() => {
        this.timestamp.set(Date.now());
      });
    }, 30000);

    this.calendarEventReaction = reaction(
      () => root.booking.ui.lastUpdatedCalendarEventData,
      () =>
        root.booking.ui.lastUpdatedCalendarEventData &&
        this.onCalendarEventMap(root.booking.ui.lastUpdatedCalendarEventData)
    );
  }

  private calendarEventReaction: IReactionDisposer;

  public allowedStatusCodes: string[];
  public calendarEventsPromise =
    maybePromiseObservable<IObservableArray<CalendarEvent>>();
  public timestamp = observable.box<number>(Date.now());

  @computed
  get calendarEvents() {
    return this.calendarEventsPromise.value ?? observable.array([]);
  }

  private readonly interval: NodeJS.Timeout;

  public checkAttendeeStatus(
    statusCodes: string[],
    calendarEvent: CalendarEvent
  ) {
    const attendees = calendarEvent.attendees ?? [];
    return attendees.some(attendee => {
      return (
        attendee.type !== AttendeeTypeEnum.user &&
        attendee.attendeeStatus &&
        statusCodes.includes(attendee.attendeeStatus)
      );
    });
  }

  public setAppointments = async () => {
    const { booking } = this.root;

    const statusCodes = await this.getAppointmentStatusCodes();
    this.allowedStatusCodes = statusCodes;

    if (statusCodes.length > 0) {
      const date = DateTime.today().toISO();
      const endDate = DateTime.now().endOf("day").toISO();
      const calendarEventsPromise = booking
        .getCalendarEvents({ startTime: date, endTime: endDate })
        .then(results =>
          observable.array<CalendarEvent>(
            results.results.filter(
              e =>
                (e.appointmentStatus &&
                  statusCodes.includes(e.appointmentStatus)) ||
                this.checkAttendeeStatus(statusCodes, e)
            )
          )
        );

      this.calendarEventsPromise.set(calendarEventsPromise);
    } else {
      this.calendarEventsPromise.set(undefined);
    }
  };

  public destroy = () => {
    this.calendarEventReaction();
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  @action
  private addToCurrentResults = (calendarEvent: CalendarEvent) => {
    const results = this.calendarEventsPromise.value;
    if (results && !results.find(ce => ce.id === calendarEvent.id)) {
      results.push(calendarEvent);
    }
  };

  @action
  public removeFromCurrentResults = (id: string) => {
    const results = this.calendarEventsPromise.value;
    if (results) {
      const ceToBeRemoved = results.find(ce => ce.id === id);
      if (ceToBeRemoved) {
        results.remove(ceToBeRemoved);
      }
    }
  };

  private onCalendarEventMap = (
    event: Pick<EntityEventData, "etag" | "action" | "id">
  ) => {
    if (
      event.id != null &&
      (event.action === EventAction.Create ||
        event.action === EventAction.Update)
    ) {
      const ce = this.root.booking.calendarEventsMap.get(event.id);
      if (ce) {
        this.addToCurrentResults(ce);
      }
    }
    if (event.action === EventAction.Delete) {
      this.removeFromCurrentResults(event.id);
    }
  };

  public getAppointmentStatusCodes = async () => {
    const { core, userExperience } = this.root;
    // Get Appointment status by the specified waiting room settings
    const orgUnitSetting = core.location.parentOrgUnit
      ? await userExperience.getOrgUnitSetting(core.location.parentOrgUnit.id)
      : undefined;

    if (!orgUnitSetting || !orgUnitSetting.waitingRoom) {
      // Set to default
      return [
        AppointmentStatusCode.Waiting,
        AppointmentStatusCode.WithProvider,
        AppointmentStatusCode.Finalised
      ];
    }

    const statusCodes: string[] = [AppointmentStatusCode.Waiting];
    const { waitingRoom } = orgUnitSetting;

    if (waitingRoom.withProvider) {
      statusCodes.push(AppointmentStatusCode.WithProvider);
    }

    if (waitingRoom.finalised) {
      statusCodes.push(AppointmentStatusCode.Finalised);
    }

    return statusCodes;
  };
}
