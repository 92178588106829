import {
  GenericSelectStyles,
  ICheckboxStyleProps,
  ICheckboxStyles,
  IStackProps,
  IStackStyles,
  IStyleFunctionOrObject,
  ITheme,
  Theme
} from "@bps/fluent-ui";

const border = (theme: Theme) => {
  return `1px solid ${theme.palette.neutralLight}`;
};

export const fieldWrapper = { root: { height: 811 } };

export const leftPane = { root: { width: 530 } };
export const imagingRequestToggle = {
  label: { paddingBottom: 11 }
};

export const rightPane = { root: { width: 580, marginLeft: 24 } };
export const rightPaneContainer = (
  props: IStackProps,
  theme: ITheme
): IStackStyles => ({
  root: {
    position: "relative",
    width: "100%",
    padding: 24,
    backgroundColor: theme.palette.neutralLighterAlt
  }
});
export const rightScrollablePane = {
  root: { marginLeft: 24, marginTop: 24, marginBottom: 24 }
};

export const rightPaneHeading = { root: { marginBottom: 4 } };

export const personaCard = (theme: Theme) => {
  return {
    root: {
      border: border(theme),
      borderRadius: 2,
      padding: 8
    }
  };
};

export const personaCardIconButton = {
  root: {
    padding: 0,
    width: 16,
    height: 16,
    cursor: "pointer"
  }
};

export const modalityWrapper = (theme: Theme) => {
  return {
    root: {
      marginTop: 8,
      border: border(theme),
      padding: 8,
      backgroundColor: theme.palette.white,
      borderRadius: 2
    }
  };
};

export const modalityDropDown = { root: { width: "100%" } };

export const deleteModalityButton = {
  root: { marginTop: 28, padding: 0, marginRight: 8, marginLeft: 8 }
};

export const addModalityButton = { root: { paddingLeft: 0 } };

export const clinicalDetailsHeading = { root: { marginTop: 12 } };

export const regionWrapper = (theme: Theme) => {
  return {
    root: {
      marginTop: 8,
      border: border(theme),
      padding: 8,
      backgroundColor: theme.palette.white,
      borderRadius: 2
    }
  };
};

export const regionItemCheckBox: IStyleFunctionOrObject<
  ICheckboxStyleProps,
  ICheckboxStyles
> = {
  root: { marginTop: 4, whiteSpace: "nowrap" },
  label: { fontWeight: 600, marginRight: 12 }
};

export const regionItemDropdown = { root: { width: "100%" } };

export const deleteRegionButton = { root: { marginLeft: 0 } };

export const regionLeftRightButtons = { root: { marginLeft: 8 } };

export const regionPicker = {
  text: { maxWidth: 270, minWidth: "100%" }
};

export const regionDetails = (theme: Theme): Partial<GenericSelectStyles> => {
  return {
    root: {
      "> div": { display: "flex" },
      marginLeft: 8
    },
    caretDown: { color: theme.palette.themePrimary },
    field: {
      borderColor: "transparent",
      paddingLeft: 0
    },
    fieldContent: { marginTop: 4 }
  };
};

export const eightGap = { childrenGap: 8 };
