import {
  FontIcon,
  FontSizes,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ReactionSeverity } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { AgentReaction } from "./ReactionForm.types.ts";

interface IRenderStatusBadgeAndIconProps {
  reaction: AgentReaction;
}

export const RenderStatusBadgeAndIcon: React.FunctionComponent<
  IRenderStatusBadgeAndIconProps
> = ({ reaction }) => {
  const severity = reaction.severity;
  let badgeColor: TextBadgeColor | undefined,
    label: string | undefined,
    iconName: string | undefined;

  if (severity === ReactionSeverity.Severe) {
    badgeColor = TextBadgeColor.red;
    label = "Severe";
    iconName = "AlertSolid";
  }
  if (severity === ReactionSeverity.Moderate) {
    badgeColor = TextBadgeColor.yellow;
    label = "Moderate";
    iconName = "WarningSolid";
  }
  if (severity === ReactionSeverity.Mild) {
    badgeColor = TextBadgeColor.lightGrey;
    label = "Mild";
    iconName = "Warning";
  }

  return (
    <Stack>
      <TextBadge
        badgeColor={badgeColor}
        badgeSize={TextBadgeSize.medium}
        noTextWrap
        styles={{
          root: {
            fontSize: FontSizes.size12,
            width: 100
          }
        }}
      >
        <Stack horizontal horizontalAlign="center">
          {iconName && (
            <FontIcon
              iconName={iconName}
              styles={{ root: { marginRight: 4, fontSize: 14 } }}
            />
          )}
          {label}
        </Stack>
      </TextBadge>
    </Stack>
  );
};
