import { ITheme, mergeStyles } from "@bps/fluent-ui";

export const getFreeTextStyles = (
  theme: ITheme,
  options: {
    hideToolbar?: boolean;
    stickyToolbar?: boolean;
  }
) => {
  const { hideToolbar, stickyToolbar } = options;

  return mergeStyles({
    "& .ql-container.ql-snow": { borderColor: theme.palette.neutralLighter },
    "& .ql-toolbar.ql-snow": {
      position: stickyToolbar ? "sticky" : "relative",
      display: hideToolbar ? "none" : "block",
      borderBottom: "none",
      borderColor: theme.palette.neutralLighter,
      background: theme.palette.neutralLighter,
      top: 0,
      zIndex: 1
    },

    "& .ql-toolbar.ql-snow + .ql-container.ql-snow": {
      borderTop: "1px solid",
      borderColor: theme.palette.neutralLighter
    }
  });
};
