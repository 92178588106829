import { FunctionComponent } from "react";

import { IStackProps, Stack } from "@bps/fluent-ui";

export const PrescribingWizardPage: FunctionComponent<IStackProps> = props => {
  return (
    <Stack
      grow
      horizontal
      verticalFill
      styles={{
        root: {
          height: "calc(90vh - 152px)"
        }
      }}
      {...props}
    />
  );
};
