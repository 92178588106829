import React from "react";

import { ITag } from "@bps/fluent-ui";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { minimumSearchLengthFilter } from "@ui-components/form/utils.ts";

interface OccupationPickerBaseProps {
  name: string;
  label?: string;
}

export const OccupationPicker: React.FC<OccupationPickerBaseProps> = ({
  name,
  label
}) => {
  const { acc } = useStores();

  const allOccupations = acc.ref.occupations.values.map<ITag>(item => ({
    key: item.code,
    name: `${item.text}`
  }));

  const handleOnFilterItems = (searchText: string) => {
    return minimumSearchLengthFilter(searchText, 2, allOccupations);
  };
  return (
    <StaticPickerField
      label={label}
      name={name}
      required
      resolveDelay={300}
      fetchDataSource={() => allOccupations}
      onEmptySearchItems={() => []}
      onFilterItems={handleOnFilterItems}
      styles={{ itemsWrapper: { display: "block" } }}
      fieldItemStyles={{ root: { flexGrow: 2.8, flexBasis: 0 } }}
      inputProps={{
        placeholder: ClaimFormLabels.searchCodeOrDescription
      }}
    />
  );
};
