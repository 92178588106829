import { FunctionComponent, useRef } from "react";
import { useForm } from "react-final-form";

import {
  DefaultButton,
  FontIcon,
  IColumn,
  IconButton,
  IContextualMenuItem,
  IDragDropEvents,
  IDropdownOption,
  Separator,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  QuickAccessSettingDto,
  QuickAccessType
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { TemplateListItem } from "@modules/clinical/screens/correspondence/components/TemplatePivot.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";
import { IconSelectField } from "@ui-components/form/IconSelectField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import {
  AvailableLinkType,
  defulatDocumentSubMenuIcon,
  DocumentSubMenuIcon,
  OVERFLOW_INDEX,
  OVERFLOW_LENGTH,
  QuickAccessSection
} from "./QuickAccessLinks.Types.ts";

export interface QuickAccessDetailsListProps {
  templateListItems: TemplateListItem[];
  favourites: string[];
  templateFilterTabs: string[];
}

export const QuickAccessDetailsList: FunctionComponent<
  QuickAccessDetailsListProps
> = ({ templateListItems, favourites, templateFilterTabs }) => {
  const documentTabs = [
    AvailableLinkType.Favourites,
    ...templateFilterTabs,
    AvailableLinkType.Custom
  ];

  const { userExperience, correspondence, core } = useStores();

  const dragStartIndex = useRef<number>(0);

  const quickAccessIcon = userExperience.ref.quickAccessIcon.keyTextValues;
  const { palette } = useTheme();
  const convertIcon = quickAccessIcon.map(x => {
    return {
      key: x.text,
      text: x.key
    };
  });

  const theme = useTheme();

  const { change, mutators, batch } = useForm();

  const { fields: _quickAccessSettingsField } =
    useFieldArray<QuickAccessSettingDto>("quickAccessSettings", {
      subscription: { value: true }
    });

  const quickAccessSettingsField = _quickAccessSettingsField.value;

  const onRenderQuickAccessOption = (
    option: IDropdownOption,
    isSystem?: boolean
  ) => {
    return (
      <FontIcon
        iconName={option.key}
        styles={{
          root: {
            fontSize: 16,
            color: isSystem
              ? theme.palette.neutralTertiary
              : theme.palette.themePrimary
          }
        }}
      />
    );
  };

  const handleDropped = (insertIndex: number) => {
    if (dragStartIndex.current !== insertIndex) {
      _quickAccessSettingsField.move(dragStartIndex.current, insertIndex);
    }
  };

  const hiddenIcon = (isHidden: boolean | undefined) =>
    isHidden ? "Hide3" : "View";

  const dragDropEvents: IDragDropEvents = {
    canDrop: () => true,
    canDrag: () => true,
    onDrop: droppedAtItem => {
      const index = quickAccessSettingsField.indexOf(droppedAtItem);
      handleDropped(index);
    },
    onDragStart: (item, index) => {
      dragStartIndex.current = index ?? 0;
    }
  };

  const fieldName = (index: number) => `quickAccessSettings[${index}]`;

  const iconOnClick = (index: number) => {
    quickAccessSettingsField[index].isSystem
      ? change(
          `${fieldName(index)}.isHidden`,
          !quickAccessSettingsField[index].isHidden
        )
      : mutators.remove("quickAccessSettings", index);
  };

  const subMenuItems = (
    documentType: string,
    index: number,
    isFavourites?: boolean
  ): IContextualMenuItem[] => {
    const getCorrespondenceTypeKey = (documentType?: string) =>
      Object.keys(CorrespondenceType)[
        Object.values(CorrespondenceType).indexOf(
          documentType as CorrespondenceType
        )
      ];

    const templateListItem = isFavourites
      ? templateListItems.filter(item => favourites.includes(item.key))
      : templateListItems.filter(x =>
          documentType !== AvailableLinkType.Custom
            ? x.documentType === documentType
            : x.isCustom === true
        );

    return templateListItem.map(x => ({
      key: x.key,
      text: x.details,
      title: x.details,
      onClick: () => {
        const title = `New document / ${getCorrespondenceTypeKey(
          x.documentType
        )} / ${x.details}`;
        batch(() => {
          change(`${fieldName(index)}.code`, QuickAccessType.Document);
          change(`${fieldName(index)}.title`, title);
          change(`${fieldName(index)}.documentId`, x.key);
          change(`${fieldName(index)}.isSourceTemplateDeleted`, undefined);
        });
      }
    }));
  };

  const columns: IColumn[] = [
    {
      name: "",
      key: "drag",
      minWidth: 5,
      maxWidth: 24,
      onRender: () => (
        <FontIcon
          iconName="Move"
          styles={{ root: { fontSize: 16, cursor: "grab" } }}
        />
      )
    },
    {
      name: "Available links",
      key: "availableLinks",
      minWidth: 350,
      maxWidth: 350,
      onRender: (item: QuickAccessSettingDto, index: number) => {
        const documentSubMenuItems: IContextualMenuItem[] = documentTabs.map(
          (value, tabIndex) => {
            const doucmentType =
              correspondence.ref.correspondenceTypes.get(value)?.text ?? value;

            return {
              key: doucmentType,
              text: doucmentType,
              subMenuProps: {
                items: subMenuItems(
                  value,
                  index,
                  value === AvailableLinkType.Favourites
                )
              },
              iconProps: {
                iconName:
                  DocumentSubMenuIcon[value] ??
                  defulatDocumentSubMenuIcon[tabIndex - 1]
              }
            };
          }
        );

        const menuItems: IContextualMenuItem[] = [
          {
            key: "newDocument",
            text: QuickAccessSection.Document,
            subMenuProps: { items: documentSubMenuItems },
            iconProps: { iconName: DocumentSubMenuIcon.Document }
          }
        ];

        if (core.hasPermissions(Permission.ClinicalFormsAllowed)) {
          menuItems.push({
            key: "onlineForms",
            text: QuickAccessSection.Forms,
            iconProps: { iconName: DocumentSubMenuIcon.OnlineForm },
            onClick: () => {
              batch(() => {
                change(`${fieldName(index)}.code`, QuickAccessType.OnlineForm);
                change(`${fieldName(index)}.title`, "Online form");
                change(
                  `${fieldName(index)}.isSourceTemplateDeleted`,
                  undefined
                );
                change(`${fieldName(index)}.documentId`, undefined);
              });
            }
          });
        }

        return (
          <FormItemField
            name={`${fieldName(index)}.title`}
            validateOnInitialize={item.isSourceTemplateDeleted}
            styles={{ root: { width: "100%" } }}
          >
            <DefaultButton
              styles={{
                root: { width: "100%", paddingLeft: 8 },
                flexContainer: {
                  justifyContent: item.title ? "space-between" : "flex-end"
                },
                textContainer: {
                  flexGrow: 0,
                  overflow: "hidden"
                },
                label: {
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }
              }}
              text={item.title}
              disabled={item.isSystem}
              menuProps={{
                shouldFocusOnMount: true,
                subMenuHoverDelay: 100,
                items: menuItems
              }}
            />
          </FormItemField>
        );
      }
    },
    {
      name: "Icon",
      key: "icon",
      minWidth: 94,
      maxWidth: 94,
      onRender: (item: QuickAccessSettingDto, index: number) => {
        return (
          <IconSelectField
            name={`${fieldName(index)}.icon`}
            disabled={item.isSystem}
            options={convertIcon}
            styles={{
              singleOption: {
                color: palette.themePrimary
              }
            }}
            fieldItemStyles={{ root: { width: "100%" } }}
            onRenderFieldContent={(options: IDropdownOption[]) => {
              return onRenderQuickAccessOption(options[0], item.isSystem);
            }}
            hideClearButton
            hideSearchOption
          />
        );
      }
    },
    {
      name: "",
      key: "iconBtn",
      minWidth: 5,
      maxWidth: 24,
      onRender: (item: QuickAccessSettingDto, index: number) => (
        <IconButton
          iconProps={{
            iconName: item.isSystem ? hiddenIcon(item.isHidden) : "Delete"
          }}
          onClick={() => {
            iconOnClick(index);
          }}
        />
      )
    }
  ];

  return (
    <ShimmeredDetailsList
      dragDropEvents={dragDropEvents}
      columns={columns}
      items={quickAccessSettingsField}
      onRenderRow={(props, defaultRender) => {
        if (!defaultRender || !props) return null;
        if (
          quickAccessSettingsField.length > OVERFLOW_LENGTH &&
          props?.itemIndex === OVERFLOW_INDEX
        ) {
          return (
            <Stack>
              {defaultRender(props)}
              <Separator styles={{ root: { fontSize: 14 } }}>
                overflow
              </Separator>
            </Stack>
          );
        }
        return defaultRender({
          ...props,
          styles: {
            fields: {
              alignItems: "flex-start"
            },
            cell: {
              minHeight: 54
            }
          }
        });
      }}
      styles={{
        root: {
          contentWrapper: { overflow: "hidden" }
        }
      }}
      cellStyleProps={{
        cellLeftPadding: 4,
        cellRightPadding: 4,
        cellExtraRightPadding: 0
      }}
    />
  );
};
