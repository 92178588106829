import { FunctionComponent } from "react";

import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteDialog } from "@ui-components/DeleteDialog.tsx";

export interface DeleteInvestigationDialogProps {
  hidden: boolean;
  documentName: string;
  onCancel: () => void;
  onConfirm: (
    reasonForDelete: string,
    reasonForDeleteComment?: string | undefined
  ) => Promise<void>;
}

const DeleteInvestigationDialogBase: FunctionComponent<
  DeleteInvestigationDialogProps
> = props => {
  const { hidden, documentName, onCancel, onConfirm } = props;
  const { clinical } = useStores();
  return (
    <DeleteDialog
      formName="delete-investigation"
      title="Delete investigation report"
      subText={`Are you sure you want to delete ${documentName}?`}
      reasonLabel="Reason"
      options={clinical.ref.reasonForDeleteInvestigationReport.keyTextValues}
      hidden={!hidden}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export const DeleteInvestigationDialog = withFetch(
  x => [x.clinical.ref.reasonForDeleteInvestigationReport.load()],
  DeleteInvestigationDialogBase
);
