import { action, computed, observable, toJS } from "mobx";
import * as pbi from "powerbi-client";
import { PageSizeType } from "powerbi-models";

import { IGroup, ScreenSizeState } from "@bps/fluent-ui";
import {
  getSlicerTextValues,
  initialReportDefinition,
  ReportDefinition,
  SlicerState
} from "@bps/titanium-powerbi-helper";
import { DATE_FORMATS, DateTime, isDefined } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ReportEmbeddingConfiguration } from "@libs/gateways/reports/ReportGateway.interface.ts";
import {
  PresetReportDefinition,
  ReportDto,
  ReportPrintDocument,
  ReportType,
  SaveType
} from "@libs/gateways/reports/ReportsGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { RootStore } from "@stores/root/RootStore.ts";

const LOCALE_SETTINGS = "&formatLocale=en-AU&language=en";
const NO_REPORT_NAME = "No reports";

export type ReportListViewModel = {
  id: string;
  name: string;
  baseReport?: ReportDto;
  definition?: PresetReportDefinition;
};

export class ReportScreenHelper {
  @computed get selectedReport() {
    const thisReport = this.reportList.items.find(
      report => report.id === this.urlReportId
    );
    return thisReport;
  }

  @computed get urlReportId() {
    return routes.reports.view.match(this.routing.location.pathname)?.params.id;
  }

  @observable searchString: string | undefined = undefined;

  @action
  setSearchString = (value: string | undefined) => {
    this.searchString = value;
  };

  constructor(private root: RootStore) {}

  private get core() {
    return this.root.core;
  }

  private get routing() {
    return this.root.routing;
  }

  private get reports() {
    return this.root.reports;
  }

  private noReportsView: ReportListViewModel = {
    id: "",
    name: NO_REPORT_NAME,
    definition: {
      ...initialReportDefinition,
      type: ReportType.None,
      baseReportName: "",
      rowVisibility: "All"
    }
  };

  getReportViewModels(items: PresetReportDefinition[], isAllowed: boolean) {
    const models = items
      .map(definition => {
        const baseReport = this.reports.baseReports.find(
          x => x.name === definition.baseReportName
        );
        if (baseReport) {
          return this.getReportViewModel({
            baseReport,
            definition,
            reportType: definition.type
          });
        }

        return undefined;
      })
      .filter(isDefined);

    if (isAllowed && models && models.length === 0)
      models.push(this.noReportsView);
    return models ?? [];
  }

  private getAllModels() {
    const baseReports = this.reports.baseReports.map(baseReport =>
      this.getReportViewModel({
        baseReport,
        reportType: ReportType.Base
      })
    );

    const allModels = [
      {
        key: "userDefinedReports",
        name: "My reports",
        reports: this.getReportViewModels(
          this.reports.userDefinedReportDefinitions,
          true
        )
      },
      {
        key: "publishedReports",
        name: "Public reports",
        reports: this.getReportViewModels(
          this.reports.publishedReportDefinitions,
          this.core.hasPermissions(Permission.ReportPublishedRead)
        )
      },
      {
        key: "presetReports",
        name: "Preset reports",
        reports: this.getReportViewModels(
          this.reports.presetReportDefinitions,
          this.core.hasPermissions(Permission.ReportPresetRead)
        )
      }
    ];

    const otherGroups: { [key: string]: ReportListViewModel[] } = {};
    for (const report of baseReports) {
      const displayInGroup = report?.baseReport?.metadata?.[
        "DisplayInGroup"
      ] as string;
      if (displayInGroup) {
        if (otherGroups[displayInGroup]) {
          otherGroups[displayInGroup].push(report);
        } else {
          otherGroups[displayInGroup] = [report];
        }
      }
    }

    for (const groupName in otherGroups) {
      allModels.push({
        key: groupName.replace(" ", ""),
        name: groupName,
        reports: otherGroups[groupName]
      });
    }

    this.core.hasPermissions(Permission.ReportBaseRead) &&
      allModels.push({
        key: "baseReports",
        name: "Base reports",
        reports: baseReports.filter(
          report => !report?.baseReport?.metadata?.["DisplayInGroup"]
        )
      });

    return allModels;
  }

  private generateReportGroupList(
    groups: {
      key: string;
      name: string;
      reports: ReportListViewModel[];
    }[]
  ): { groups: IGroup[]; items: ReportListViewModel[] } {
    return groups.reduce(
      (accumulator, model) => {
        const { reports, ...group } = model;
        return {
          items: [...accumulator.items, ...model.reports],
          groups: [
            ...accumulator.groups,
            {
              ...group,
              startIndex: accumulator.items.length,
              count: model.reports.length
            }
          ]
        };
      },
      { items: [], groups: [] }
    );
  }

  private getReportList(): { groups: IGroup[]; items: ReportListViewModel[] } {
    return this.generateReportGroupList(this.getAllModels());
  }

  private getReportDisplayList(): {
    groups: IGroup[];
    items: ReportListViewModel[];
  } {
    const allModels = this.getAllModels();

    if (this.searchString) {
      const search = this.searchString.toLocaleLowerCase();
      allModels.forEach(model => {
        model.reports = model.reports.filter(
          report =>
            report.id && report.name.toLocaleLowerCase().includes(search)
        );
      });
    }

    return this.generateReportGroupList(
      allModels.filter(model => model.reports.length > 0)
    );
  }

  @computed get reportList() {
    return this.getReportList();
  }

  @computed get reportDisplayList() {
    return this.getReportDisplayList();
  }

  @computed get hasReports() {
    return this.getReportList().items.some(x => x.name !== NO_REPORT_NAME);
  }

  @computed get isAdmin() {
    return this.core.hasPermissions([Permission.ReportPublishedWrite]);
  }

  printReport = async (
    data: ReportDefinition,
    report: pbi.Report,
    landscape: boolean
  ) => {
    const pageWidth = landscape ? 770 : 540;
    const columnWidth =
      (pageWidth - 16 * data.viewColumns.length) /
      (data.viewColumns.length ?? 1);

    const reportSlicers = await getSlicerTextValues(report);
    const pageOrientation = landscape ? "landscape" : undefined;
    const reportData = {
      pageOrientation,
      ...data,
      reportSlicers,
      dateNow: DateTime.now().toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT),
      startDate:
        data.reportDates &&
        DateTime.fromJSDate(data.reportDates.startDate).toDayDefaultFormat(),

      endDate:
        data.reportDates &&
        DateTime.fromJSDate(data.reportDates.endDate).toDayDefaultFormat(),
      columnWidth
    };

    const printReport: ReportPrintDocument = {
      reportName: data.name ?? data.title ?? "Report",
      data: JSON.stringify(reportData)
    };

    await this.reports.openReportPdf(printReport);
  };

  getEmbedConfiguration = async (options: {
    reportId: string;
    screenSize: ScreenSizeState;
    reportParameters?: string;
    type: ReportType;
  }): Promise<ReportEmbeddingConfiguration> => {
    const { reportId, type, screenSize, reportParameters } = options;
    const embedConfig = await this.reports.getEmbedConfiguration({
      reportId,
      type
    });
    embedConfig.report.embedUrl += LOCALE_SETTINGS;
    embedConfig.report.settings = {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: pbi.models.LayoutType.Custom,
      customLayout: {
        pageSize: {
          type: pbi.models.PageSizeType.Custom,
          width: screenSize.width - 400,
          height: screenSize.height - 200
        } as pbi.models.ICustomPageSize,
        displayOption: pbi.models.DisplayOption.ActualSize
      }
    };
    if (reportParameters) {
      embedConfig.report.embedUrl += `&${reportParameters}`;
    }
    return embedConfig;
  };

  getReportViewModel(options: {
    baseReport: ReportDto;
    definition?: PresetReportDefinition;
    reportType: ReportType;
  }): ReportListViewModel {
    const { baseReport, definition, reportType } = options;
    const baseReportName = baseReport.name;
    const name = definition ? definition.name : baseReportName;

    let reportTypeToUse = reportType;
    if (!reportTypeToUse) {
      if (definition) {
        reportTypeToUse = ReportType.UserDefined;
      } else {
        reportTypeToUse = ReportType.Base;
      }
    }

    const rowVisibilityMetadata = baseReport.metadata?.RowVisibilityTypes ?? [];

    const rowVisibilityTypes = rowVisibilityMetadata.length
      ? rowVisibilityMetadata.map(x => {
          return {
            role: x["Role"] as string,
            name: x["Name"] as string
          };
        })
      : [];
    return {
      name,
      id: definition ? definition.id : baseReport.id,
      baseReport,
      definition: definition
        ? {
            ...definition,
            baseReportName,
            rowVisibilityTypes
          }
        : {
            ...initialReportDefinition,
            dateRange: undefined,
            name,
            rowVisibility: "All",
            baseReportName,
            type: reportTypeToUse,
            rowVisibilityTypes
          }
    };
  }

  deletePublishedReport = async (report: PresetReportDefinition) => {
    await this.reports.deletePublishedReport(report);
    this.routing.push(routes.reports.basePath.pattern);
  };

  deleteUserDefinedReport = async (report: PresetReportDefinition) => {
    await this.reports.deleteUserDefinedReport(report);
    this.routing.push(routes.reports.basePath.pattern);
  };

  saveMyReport = async (
    saveReport: PresetReportDefinition,
    saveType: SaveType,
    report?: pbi.Report
  ) => {
    const save = await this.getReportSaveSettings(saveReport, report);
    const savedReport = await this.reports.saveUserDefinedReport(
      save,
      saveType
    );

    const baseReport = this.reports.baseReports.find(
      x => x.name === savedReport?.baseReportName
    );

    const model =
      baseReport &&
      savedReport &&
      this.getReportViewModel({
        baseReport,
        definition: {
          ...savedReport.definition,
          id: savedReport.id,
          title: savedReport.name,
          name: savedReport.name
        },
        reportType: ReportType.UserDefined
      });
    await this.reports.getMyReports();
    model && this.routing.push(routes.reports.view.path({ id: model.id }));
    return savedReport;
  };

  savePublished = async (
    saveReport: PresetReportDefinition,
    saveType: SaveType,
    report?: pbi.Report
  ) => {
    const save = await this.getReportSaveSettings(saveReport, report);
    const savedReport = await this.reports.savePublishedReport(save, saveType);
    const baseReport = this.reports.baseReports.find(
      x => x.name === savedReport?.baseReportName
    );

    const model =
      baseReport &&
      savedReport &&
      this.getReportViewModel({
        baseReport,
        definition: {
          ...savedReport.definition,
          id: savedReport.id,
          title: savedReport.name,
          name: savedReport.name
        },
        reportType: ReportType.Published
      });
    await this.reports.getPublishedReports();
    model && this.routing.push(routes.reports.view.path({ id: model.id }));
    return savedReport;
  };

  async getReportSaveSettings(
    tableData: PresetReportDefinition,
    report?: pbi.Report
  ) {
    const slicerState = report && (await SlicerState.getSlicerState(report));

    const {
      id,
      name,
      viewColumns,
      dateRange,
      availableViewColumns,
      reportDates,
      sorts,
      baseReportName,
      rowVisibility,
      rowVisibilityTypes,
      type
    } = toJS(tableData);

    const newReport: PresetReportDefinition = {
      id,
      reportId: id,
      name,
      title: name,
      slicers: slicerState ?? [],
      viewColumns,
      availableViewColumns,
      sorts,
      dateRange,
      reportDates,
      baseReportName,
      rowVisibility,
      rowVisibilityTypes,
      type
    };
    return newReport;
  }

  private readonly reportLayoutParams = {
    leftMargin: 10,
    slicerLeftMargin: 8,
    topMargin: 20,
    bottomMargin: 20,
    slicerHorizontalSpacing: 5,
    verticalSpacing: 25,
    slicerHeight: 43,
    graphLeft: 20,
    graphPadding: 40,
    dialogMargin: 60,
    dialogContentPadding: 20
  };

  private calculateElementPositions = (
    elements: pbi.VisualDescriptor[],
    {
      startingPosition,
      size,
      resizeElements
    }: {
      startingPosition: { left: number; top: number; bottom: number };
      size: DOMRect;
      resizeElements?: boolean;
    }
  ) => {
    // Only used when resizing
    const maximumElementWidth =
      elements.length &&
      (size.width -
        (this.reportLayoutParams.slicerHorizontalSpacing * elements.length -
          1) -
        this.reportLayoutParams.leftMargin) /
        elements.length;

    const maximumElementHeight =
      size.height / 2 -
      this.reportLayoutParams.verticalSpacing -
      startingPosition.top;

    return elements
      .sort((a, b) =>
        a.layout && b.layout && a.layout.x && b.layout.x
          ? a.layout.x - b.layout.x
          : 0
      )
      .reduce(
        (
          pos: { left: number; top: number; bottom: number },
          element: pbi.VisualDescriptor
        ) => {
          if (element.layout && element.layout.height && element.layout.width) {
            if (resizeElements) {
              element.resizeVisual(maximumElementWidth, maximumElementHeight);
            }

            // These need to be used because element.layout.width/height does updates asynchronously. Awaiting it doesn't seem to help.
            const width = resizeElements
              ? maximumElementWidth
              : element.layout.width;

            const height = resizeElements
              ? maximumElementHeight
              : element.layout.height;

            const right = pos.left + width;

            if (
              pos.left === this.reportLayoutParams.leftMargin ||
              right < size.width
            ) {
              element.moveVisual(pos.left, pos.top);
              const elementBottom = pos.top + height;
              return {
                ...pos,
                left: right + this.reportLayoutParams.slicerHorizontalSpacing,
                bottom: Math.max(elementBottom, pos.bottom)
              };
            } else {
              const newPos = {
                left: this.reportLayoutParams.leftMargin + width,
                top: pos.bottom,
                bottom: pos.bottom + height
              };
              element.moveVisual(
                this.reportLayoutParams.leftMargin,
                pos.bottom
              );
              return newPos;
            }
          }
          return pos;
        },
        startingPosition
      );
  };

  positionGraphs(
    graphs: pbi.VisualDescriptor[],
    size: { height: number; width: number },
    positionAfterSlicers: { bottom: number }
  ) {
    for (let i = 0; i < graphs.length; i++) {
      const graph = graphs[i];

      const graphHeight = (size.height - 200) / 2;
      const graphWidth = (size.width - 200) / 2;

      const left =
        i % 2 === 0
          ? this.reportLayoutParams.graphLeft
          : graphWidth + this.reportLayoutParams.graphPadding;

      const top =
        Math.floor(i / 2) *
          (graphHeight + this.reportLayoutParams.graphPadding) +
        positionAfterSlicers.bottom +
        this.reportLayoutParams.graphPadding * 2;

      graph.resizeVisual(graphWidth, graphHeight);

      graph.moveVisual(left, top);
    }
  }

  resizeAndMovePopups(
    popup: pbi.VisualDescriptor[],
    size: { width: number; height: number }
  ) {
    if (popup && popup.length > 0) {
      popup.forEach(p => {
        if (p.title.includes("background")) {
          p.resizeVisual(size.width, size.height);
        }
        if (p.title.includes("dialog")) {
          p.moveVisual(
            this.reportLayoutParams.dialogMargin,
            this.reportLayoutParams.dialogMargin - 10
          );
          p.resizeVisual(
            size.width - this.reportLayoutParams.dialogMargin * 2,
            size.height - this.reportLayoutParams.dialogMargin * 2
          );
        }
        if (p.title.includes("table")) {
          p.moveVisual(
            this.reportLayoutParams.dialogMargin +
              this.reportLayoutParams.dialogContentPadding,
            this.reportLayoutParams.dialogMargin +
              this.reportLayoutParams.dialogContentPadding
          );
          p.resizeVisual(
            size.width -
              this.reportLayoutParams.dialogMargin * 2 -
              this.reportLayoutParams.dialogContentPadding * 2,
            size.height -
              this.reportLayoutParams.dialogMargin * 2 -
              this.reportLayoutParams.dialogContentPadding * 2
          );
        }
        if (p.title.includes("close")) {
          const x = size.width - this.reportLayoutParams.dialogMargin - 30;
          p.moveVisual(x, this.reportLayoutParams.dialogMargin);
        }
      });
    }
  }

  updateReportVisualsPosition = async (
    size: DOMRect | null | undefined,
    report?: pbi.Report
  ) => {
    if (report && size && size.width && size.height) {
      const page = (await report.getActivePage())!;
      const allVisuals = await page.getVisuals();
      size && page.resizePage(PageSizeType.Custom, size.width, size.height);

      const visuals = allVisuals.reduce(
        (
          fields: {
            labels: pbi.VisualDescriptor[];
            slicers: pbi.VisualDescriptor[];
            graphs: pbi.VisualDescriptor[];
            table?: pbi.VisualDescriptor;
            date?: pbi.VisualDescriptor;
            popup: pbi.VisualDescriptor[];
          },
          x
        ) => {
          if (x?.title?.startsWith("popup_")) {
            return { ...fields, popup: [...fields.popup, x] };
          }
          if (
            x.type.startsWith("bpTable") ||
            x.type.startsWith("pivotTable") ||
            x.type.startsWith("tableEx")
          )
            return { ...fields, table: x };
          if (x.type.startsWith("bpText"))
            return { ...fields, labels: [...fields.labels, x] };
          if (x.type === "slicer" || x.type === "card") {
            if (x.title?.toLowerCase().includes("date")) {
              return { ...fields, date: x };
            }
            return { ...fields, slicers: [...fields.slicers, x] };
          }
          if (
            x.type === "donutChart" ||
            x.type === "clusteredColumnChart" ||
            x.type === "barChart"
          ) {
            return { ...fields, graphs: [...fields.graphs, x] };
          }
          if (x.type === "qnaVisual") {
            return { ...fields, qna: x };
          }
          return fields;
        },
        { slicers: [], labels: [], graphs: [], popup: [] }
      );

      const { table, slicers, labels, date, graphs, popup } = visuals;

      const positionAfterSlicers = this.calculateElementPositions(slicers, {
        startingPosition: {
          left: this.reportLayoutParams.slicerLeftMargin,
          top: this.reportLayoutParams.topMargin,
          bottom: this.reportLayoutParams.topMargin
        },
        size,
        resizeElements: false
      });

      this.positionGraphs(graphs, size, positionAfterSlicers);

      const dateTop = date ? positionAfterSlicers.bottom : undefined;
      const dateBottom =
        dateTop && date?.layout?.height
          ? positionAfterSlicers.bottom + date.layout.height
          : positionAfterSlicers.bottom +
            (graphs.length && this.reportLayoutParams.verticalSpacing);
      if (date && dateTop) {
        date.moveVisual(this.reportLayoutParams.slicerLeftMargin, dateTop);
      }
      class LabelMove {
        left: number;
        top: number;
        label: pbi.VisualDescriptor;
      }
      class LabelPositionTracker {
        left: number;
        height: number;
        top: number;
        moves: LabelMove[];
      }

      const labelResult = labels.reduce(
        (pos: LabelPositionTracker, label: pbi.VisualDescriptor) => {
          if (label.layout.width && label.layout.height && label.layout.width) {
            const right =
              pos.left +
              this.reportLayoutParams.slicerHorizontalSpacing +
              label.layout.width;

            if (
              pos.left === this.reportLayoutParams.slicerLeftMargin ||
              right < size.width
            ) {
              return {
                left:
                  pos.left +
                  this.reportLayoutParams.slicerHorizontalSpacing +
                  label.layout.width,
                top: pos.height,
                height: Math.max(label.layout.height, pos.height),
                moves: [...pos.moves, { left: pos.left, top: pos.top, label }]
              };
            } else {
              const newPos = {
                left: this.reportLayoutParams.slicerLeftMargin,
                top: pos.top + pos.height,
                height: label.layout.height,
                moves: [
                  ...pos.moves,
                  {
                    left: pos.left,
                    top: pos.height + label.layout.height,
                    label
                  }
                ]
              };
              return newPos;
            }
          }
          return pos;
        },
        {
          left: this.reportLayoutParams.slicerLeftMargin,
          height: 0,
          top: 0,
          moves: []
        }
      );

      const labelHeight =
        size.height -
        (labelResult.height + this.reportLayoutParams.bottomMargin);
      labelResult.moves.forEach(move => {
        move.label.moveVisual(move.left, move.top + labelHeight);
      });

      if (table && size) {
        const top = dateBottom;
        table.moveVisual(this.reportLayoutParams.leftMargin, top);
        table.resizeVisual(
          size.width - this.reportLayoutParams.leftMargin * 2,
          labelHeight - top
        );
      }

      this.resizeAndMovePopups(popup, size);
    }
  };
}
