import debounce from "lodash.debounce";
import { action, computed, observable, runInAction } from "mobx";

import { confirm, flatten, IDialogContentProps } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { AppointmentStartingPoints } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { BillingStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AddCalendarEventDto,
  AttendeeTypeEnum,
  CalendarEventAttendeeDto,
  CalendarEventDto,
  CalendarEventPosition,
  CalendarEventStatus,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  Permission,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { FormsChannelType } from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { UsersOrgUnitTimeRanges } from "@shared-types/booking/users-org-unit-time-ranges.type.ts";
import { SendFormConfirmationDialogProps } from "@shared-types/forms/send-form-confirmation-dialog-props.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AppointmentDialogOptions } from "@stores/booking/BookingUi.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { OrgUnitAvailability } from "@stores/booking/models/OrgUnitAvailability.ts";
import { TimeRange, TimeRanges } from "@stores/booking/models/TimeRanges.ts";
import {
  TimeRangeOrgUnit,
  TimeRangesOrgUnit
} from "@stores/booking/models/TimeRangesOrgUnit.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";

import {
  BookingCalendarSlotInfo,
  CalendarPage
} from "../BookingCalendarScreen.types.ts";
import { DragAndDropInfo } from "../calendar/Calendar.types.tsx";
import { AppointmentFilterStatus } from "../components/appointments-filter/AppointmentFilter.types.ts";
import { BigCalendarEvent } from "../components/booking-calendar-event/BookingCalendarEvent.types.ts";
import {
  calculateWorkWeekRange,
  filterEventsForWorkWeek,
  getClosedExceptionDates
} from "../components/calendar/bookingCalendarWorkWeekUtils.ts";
import { convertToBigCalendarEvent } from "../components/calendar/utils.ts";
import { WorkingHoursAvailabilityMessages } from "../components/calendar/WorkingHoursAvailabilityMessages.tsx";
import {
  getCalendarAvailability,
  isBefore12hours
} from "../components/utils.tsx";
import { CalendarEventView } from "../types/CalendarEventView.types.ts";
import {
  AttendeeInvoice,
  CalendarEventAttendeeArgs,
  CalendarEventAttendeeCancellationArgs
} from "./BookingCalendarScreenModel.types.ts";

type Subscription = {
  event: Entity;
  handler: (arg?: any) => any;
};

export const defaultCalendarView = CalendarEventView.WorkWeek;
export const STEP = "15";

export class BookingCalendarScreenModel {
  constructor(private root: IRootStore) {
    const { hub } = root.booking;
    // set subscriptions in a public property to unsubscribe in BookingCalendarScreen
    this.subscriptions = [
      {
        event: Entity.OrgUnitAvailability,
        handler: async () => {
          const locationHours = await this.loadLocationOpeningHours(
            this.startDate
          );
          runInAction(() => {
            this.locationHours = locationHours;
          });
        }
      },
      {
        event: Entity.UserAvailability,
        handler: async () => {
          const usersWorkingHours = await this.loadUsersWorkingHours(
            this.resourceIds,
            this.startDate
          );
          runInAction(() => {
            this.usersWorkingHours = usersWorkingHours;
          });
        }
      },
      {
        event: Entity.CalendarEventRecurrence,
        handler: this.onCalendarEventRecurrenceProjected
      }
    ];

    // subscribe SignaslR events
    this.subscriptions.map(s => hub.onEntityEvent(s.event, s.handler));
  }

  public subscriptions: Subscription[] = [];

  private get routing() {
    return this.root.routing;
  }

  private get booking() {
    return this.root.booking;
  }

  private get core() {
    return this.root.core;
  }

  private get userExperience() {
    return this.root.userExperience;
  }
  private get forms() {
    return this.root.forms;
  }

  currentEventData = observable.object<{
    event?: CalendarEvent;
    calloutPosition?: MouseEvent;
    contextualMenuPosition?: MouseEvent;
  }>({});

  @action
  setCurrentEventData = (data: {
    event?: CalendarEvent;
    calloutPosition?: MouseEvent;
    contextualMenuPosition?: MouseEvent;
  }) => {
    this.currentEventData.event = data.event;
    this.currentEventData.calloutPosition = data.calloutPosition;
    this.currentEventData.contextualMenuPosition = data.contextualMenuPosition;
  };

  @observable
  newRecurrenceUserId: string | undefined;

  @observable
  currentRecurrenceId: string | undefined;

  @observable
  currentOccurrenceId: string | undefined;

  @observable
  reminderArgs: CalendarEventAttendeeArgs | undefined;

  @observable
  isRemindersDialogVisible: boolean = false;

  @observable
  selectionCount: number;

  @observable
  appointmentsFilter: AppointmentFilterStatus | undefined = undefined;

  @observable
  public attendeeInvoices: AttendeeInvoice[] = [];

  @observable
  public isGeneralInvoiceCompleted: boolean = false;

  @observable locationHours: TimeRanges | undefined;
  @observable calendarEvents: CalendarEvent[] = [];
  @observable bookableUsers: User[] = [];
  @observable userAvailabilities: UserAvailabilityModel[] = [];
  @observable orgUnitAvailability: OrgUnitAvailability | undefined;
  @observable usersWorkingHours: UsersOrgUnitTimeRanges[] | undefined;
  @observable private _resourceIds: string[] = [];
  @observable orgUnitIds: string[] = [this.core.locationId];

  // These loading indicators stop the calendar from rerendering before all the data is fully loaded
  // There is separate reloading variables for providers, orgUnits, and calendarView so that the user inputs that
  //  use those can render while the rest of the calendar is waiting for the rest of the data.
  @observable isInitialLoading = true;
  @observable isInitialLoadingProviders = true;
  @observable isInitialLoadingOrgUnits = true;
  @observable isInitialLoadingCalendarView = true;
  @observable isReloading = false;
  @computed get isLoading() {
    return this.isInitialLoading || this.isReloading;
  }

  @action
  setOrgUnitIds = (orgUnitIds: string[]) => {
    this.orgUnitIds = orgUnitIds;
    this.saveOrgUnitIdsToUserStorage(orgUnitIds);
  };

  @action
  pushToOrgUnitIds = (id: string) => {
    if (!this.orgUnitIds.includes(id)) {
      this.orgUnitIds = [...this.orgUnitIds, id];
      this.saveOrgUnitIdsToUserStorage(this.orgUnitIds);
    }
  };

  @computed get resourceIds() {
    return this._resourceIds.filter(resourceId => {
      return this.pickableUsers.some(user => user.id === resourceId);
    });
  }

  @observable
  private _startDate = DateTime.today();

  @observable
  calendarView: CalendarEventView = defaultCalendarView;

  get dayOrWeekView(): CalendarEventView.Day | CalendarEventView.Week {
    if (this.calendarView === CalendarEventView.Day) {
      return CalendarEventView.Day;
    }

    return CalendarEventView.Week;
  }

  @computed
  get pickableUsers(): User[] {
    if (this.orgUnitIds.filter(x => x).length > 0) {
      return this.bookableUsers.filter(
        user =>
          user.availableOrgUnitIds?.some(orgId =>
            this.orgUnitIds.includes(orgId)
          )
      );
    }

    return this.bookableUsers;
  }

  @computed
  get startDate() {
    return this._startDate;
  }

  @observable
  today = DateTime.jsDateNow();

  @observable
  hasLoadingError = false;

  get eventRemindersDisplay() {
    return this.calendarPage === "eventReminders";
  }

  get didNotArriveDisplay() {
    return this.calendarPage === "didNotArrive";
  }

  get hideLeftSide() {
    return this.didNotArriveDisplay || this.eventRemindersDisplay;
  }

  get hideLegend() {
    return !!this.calendarPage;
  }

  @observable
  sendFormConfirmationDialogProps: SendFormConfirmationDialogProps | undefined;

  @action
  setAttendeeInvoices = (attendeeInvoices?: AttendeeInvoice[]) => {
    if (attendeeInvoices) {
      runInAction(() => {
        this.attendeeInvoices = attendeeInvoices;
      });
      this.setIsGeneralInvoiceStatus(attendeeInvoices);
    }
  };

  @action
  setIsGeneralInvoiceStatus = (attendeeInvoices: AttendeeInvoice[]) => {
    runInAction(() => {
      if (attendeeInvoices) {
        this.isGeneralInvoiceCompleted = !attendeeInvoices.find(
          x =>
            (x && x.billingStatus === BillingStatuses.cancelled) ||
            !x.billingStatus ||
            !x
        );
      }
    });
  };
  @action
  setFormConfirmationProps = async (vals?: SendFormConfirmationDialogProps) => {
    if (!vals || (vals && !vals.qrcode)) {
      runInAction(() => {
        this.sendFormConfirmationDialogProps = vals;
      });
    } else {
      if (vals?.formTemplate && vals?.context) {
        const deployOptions = {
          context: vals.addContextBeforeDeployAction
            ? await vals.addContextBeforeDeployAction(
                vals.formTemplate,
                vals.context
              )
            : vals.context,
          formTemplate: vals.formTemplate,
          channelCode: FormsChannelType.QrCode
        };

        const deployed = await this.forms.deployForm(deployOptions);
        runInAction(() => {
          this.sendFormConfirmationDialogProps = {
            ...vals,
            hostingUrl: deployed?.hostingUrl
          };
        });
      }
    }
  };

  @computed
  get providerAndLocationHours(): TimeRange[] | undefined {
    const locationHours = this.locationHours?.timeRanges;

    let userHours: TimeRange[][] | undefined;

    if (this.usersWorkingHours) {
      userHours = this.usersWorkingHours.map(x => x.orgUnitRanges.timeRanges);
    }

    if (locationHours && userHours) {
      return [...locationHours, ...flatten(userHours)];
    }

    return undefined;
  }

  @computed
  get filteredCalendarEvents(): CalendarEvent[] {
    let filteredCalendarEvents = this.calendarEvents.filter(
      x =>
        this.resourceIds.includes(x.userId) &&
        x.status === CalendarEventStatus.Confirmed &&
        this.orgUnitIds.includes(x.orgUnitId)
    );

    if (this.calendarView === CalendarEventView.WorkWeek) {
      filteredCalendarEvents = filterEventsForWorkWeek(
        filteredCalendarEvents,
        this.providerAndLocationHours
      );
    }

    if (this.calendarPage === "agenda") {
      if (this.dayOrWeekView === CalendarEventView.Week) {
        return filteredCalendarEvents;
      }

      return filteredCalendarEvents.filter(x =>
        x.startDateTime.isBetweenInclusive(
          this.startDate,
          this.startDate.plus({ days: 1 })
        )
      );
    }

    return filteredCalendarEvents;
  }

  @action
  setToday = () => {
    this.today = DateTime.jsDateNow();
  };

  @action
  setAppointmentsFilter = (filter?: AppointmentFilterStatus) => {
    this.appointmentsFilter = filter;
  };

  @action
  setSelectionCount = (value: number) => {
    this.selectionCount = value;
  };

  @action
  setNewRecurrenceUserId = (value?: string) => {
    this.newRecurrenceUserId = value;
  };

  @action
  setCurrentRecurrenceId = (value?: string) => {
    this.currentRecurrenceId = value;
  };

  @action
  setCurrentOccurrenceId = (value?: string) => {
    this.currentOccurrenceId = value;
  };

  @action
  setReminderArgs = (value?: CalendarEventAttendeeArgs) => {
    this.reminderArgs = value;
  };

  @action
  toggleIsRemindersDialogVisible = () => {
    this.isRemindersDialogVisible = !this.isRemindersDialogVisible;
  };

  @computed
  get calendarPage(): CalendarPage | undefined {
    return this.routing.queryStringParam(
      routes.calendarEvents.queryKeys.calendarPage
    ) as CalendarPage;
  }

  @action
  setCalendarPage = (value: CalendarPage | undefined) => {
    if (!value)
      return this.routing.replaceQueryStringParam(
        routes.calendarEvents.queryKeys.calendarPage
      );

    this.routing.pushQueryStringParam(
      routes.calendarEvents.queryKeys.calendarPage,
      value
    );
  };

  @action
  setCalendarView = (value: CalendarEventView) => {
    if (value === this.calendarView) return;
    this.calendarView = value;
    this.saveCalendarViewToUserStorage(value);
  };

  saveCalendarViewToUserStorage = debounce((value: CalendarEventView) => {
    return this.saveToUserStorage(UserStorageKeys.CalendarView, value);
  }, 1000);

  @action
  setStartDate = (value: DateTime) => {
    if (this.dayOrWeekView === CalendarEventView.Week) {
      if (this._startDate.startOf("week").equals(value.startOf("week"))) return;
    } else {
      if (this._startDate.startOf("day").equals(value.startOf("day"))) return;
    }

    this.onStartDateChanged(value);
  };

  saveResourceIdsToUserStorage = debounce(async (ids: string[]) => {
    return this.saveToUserStorage(UserStorageKeys.SelectedProviders, ids);
  }, 1000);

  @action
  onChangeLocationOrgUnitIds = (ids: string[]) => {
    this.setOrgUnitIds(ids);

    const selected = this.bookableUsers
      .filter(
        user => user.availableOrgUnitIds?.some(orgId => ids.includes(orgId))
      )
      .map(x => x.id);

    const newSelected = selected.filter(item =>
      this.resourceIds.includes(item)
    );

    this.onChangeResourceIds(newSelected);
  };

  saveToUserStorage = async (
    key: UserStorageKeys,
    value: string | string[]
  ) => {
    try {
      const userStorage = await this.userExperience.getUserStorage(key);
      if (userStorage) {
        await this.userExperience.updateUserStorage(key, {
          key: userStorage.key,
          userId: userStorage.userId,
          jsonData: value,
          eTag: userStorage.eTag,
          id: userStorage.id
        });
      } else {
        await this.userExperience.addUserStorage(key, {
          key,
          userId: this.core.userId,
          jsonData: value
        });
      }
    } catch (error) {
      // Since this happens in the background, it would just confuse the user if we informed them about it
      // reload user storage as it is common for it to become out of sync with the remove verison
      await this.userExperience.getUserStorage(key);
    }
  };

  saveOrgUnitIdsToUserStorage = debounce((ids: string[]) => {
    return this.saveToUserStorage(UserStorageKeys.OrgUnitIds, ids);
  }, 1000);

  getResourceIds = async (bookableUsers: User[]) => {
    const response = await this.userExperience.getUserStorage(
      UserStorageKeys.SelectedProviders
    );

    if (response?.jsonData) {
      return response.jsonData as string[];
    } else if (bookableUsers.some(user => user.id === this.core.userId)) {
      return [this.core.userId];
    }

    return [];
  };

  setResourceIds(newIds: string[]) {
    runInAction(() => {
      this._resourceIds = newIds;
    });

    this.saveResourceIdsToUserStorage(newIds);
  }

  public search = async () => {
    await this.loadCalendarEvents({
      startTime: this.startDate,
      resourceIds: this.resourceIds,
      dateRange: this.dayOrWeekView
    });

    runInAction(() => {
      this.isReloading = false;
    });
  };

  private loadCalendarEvents = async (options: {
    startTime: DateTime;
    resourceIds: string[];
    dateRange: CalendarEventView.Day | CalendarEventView.Week;
  }) => {
    return this.booking.fetchCalendarEvents({
      statuses: [CalendarEventStatus.Confirmed],
      ...options
    });
  };

  loadUsersWorkingHours = async (
    resourceIds: string[],
    startDate: DateTime
  ): Promise<UsersOrgUnitTimeRanges[]> => {
    if (resourceIds.length === 0) {
      return [];
    }

    const from = startDate.startOf("week");
    const to = from.plus({ weeks: 1 });

    const results = await this.booking.getUserWorkingHoursAllLocations({
      from,
      to,
      userIds: resourceIds,
      isStandardHours: false
    });
    return resourceIds.map(userId => {
      return (
        results.find(result => result.userId === userId) ?? {
          userId,
          orgUnitRanges: new TimeRangesOrgUnit([])
        }
      );
    });
  };

  loadLocationOpeningHours = async (startDate: DateTime) => {
    const from = startDate.startOf("week");

    return this.booking.getOrgUnitWorkingHours({
      from,
      to: from.plus({ weeks: 1 }),
      orgUnitId: this.core.locationId
    });
  };

  /**
   * onCalendarEventRecurrenceProjected is called when a CalendarEventRecurrence event (with action 'Projected') is received from the SignalR hub
   */
  private onCalendarEventRecurrenceProjected = async (
    message: EntityEventData
  ) => {
    const isProjected =
      message.action === EventAction.CalendarEventRecurrenceProjected;
    if (isProjected || message.action === EventAction.Delete) {
      try {
        const calendarEventRecurrenceId = message.id;
        //const calendar events and update calendar events results
        const calendarEvents = await this.booking.getCalendarEvents({
          statuses: [CalendarEventStatus.Confirmed],
          calendarEventRecurrenceId
        });

        const updatedCalendarEvents =
          message.action === EventAction.Delete
            ? []
            : calendarEvents && calendarEvents.results;

        const results = this.calendarEvents;

        if (isProjected) {
          runInAction(() => {
            this.booking.recurrenceMap.delete(calendarEventRecurrenceId);
          });

          const calendarEventRecurrence = await this.booking.getRecurrence(
            calendarEventRecurrenceId
          );
          runInAction(() => {
            this.booking.recurrenceMap.delete(calendarEventRecurrenceId);
          });

          calendarEventRecurrence &&
            (await this.booking.getUserUnavailability(
              calendarEventRecurrence.userId
            ));
        } else if (message.action === EventAction.Delete) {
          runInAction(() => {
            this.booking.recurrenceMap.delete(calendarEventRecurrenceId);
          });
        }

        this.updateCalendarEventsResults([
          //Delete the existing events with calendarEventRecurrenceId
          ...results.filter(
            calendarEvent =>
              calendarEvent.calendarEventRecurrenceId !==
              calendarEventRecurrenceId
          ),
          //Add the updated events with calendarEventRecurrenceId
          ...updatedCalendarEvents
        ]);
      } catch (error) {
        this.root.notification.error(error.message);
      }
    }
  };

  @action
  private updateCalendarEventsResults = (results: CalendarEvent[]) => {
    this.calendarEvents = results;
  };

  /**
   * Add the calendar to the current calendar event results or
   * if it is cancelled, remove it from the results.
   *
   * If latency of calendar events search is low, we can simplify the logic
   * by refetching the calendar events instead.
   */
  @action
  public mergeWithCurrentResults = (calendarEvent: CalendarEvent) => {
    const results = this.calendarEvents;

    if (results.find(ce => ce.id === calendarEvent.id)) {
      if (calendarEvent.status === CalendarEventStatus.Cancelled) {
        this.removeFromCurrentResults(calendarEvent.id);
      }
    } else {
      results.push(calendarEvent);
    }
  };

  @action
  public removeFromCurrentResults = (id: string) => {
    const results = this.calendarEvents;
    const ceToBeRemoved = results.find(ce => ce.id === id);
    if (ceToBeRemoved) {
      this.calendarEvents = this.calendarEvents.filter(ce => ce.id !== id);
    }
  };

  /**
   * onBookingUpdateEvent is called when a Booking update event is received from the SignalR hub
   */
  public onCalendarEventUpdate = async (
    message: Pick<EntityEventData, "etag" | "action" | "id">
  ) => {
    //because BookingCalendarScreenModel uses a local map of ce  we need to subscribe to create and deletes.
    //we might not need to keep a local map and just filter the map in get calendarEvents
    if (message.action === EventAction.Create) {
      const calendarEventId = message.id;
      const existingCalendarEvent =
        this.booking.calendarEventsMap.get(calendarEventId);
      if (existingCalendarEvent)
        this.mergeWithCurrentResults(existingCalendarEvent);
    }
    if (message.action === EventAction.Delete) {
      this.removeFromCurrentResults(message.id);
    }
  };

  getUsersWorkingHours = (
    userId: string | undefined
  ): UsersOrgUnitTimeRanges | undefined => {
    return this.usersWorkingHours?.find(u => u.userId === userId);
  };

  /**
   * sort by user last name but with current user first
   */
  private selfThenLastNameComparer = (a: User, b: User): number => {
    if (a.id === this.core.user?.id) return -1;
    if (b.id === this.core.user?.id) return 1;

    return a.lastName.localeCompare(b.lastName);
  };

  onCalendarEventAttendeeCancellation = async (
    value: CalendarEventAttendeeCancellationArgs
  ) => {
    const { calendarEvent, cancellationReasonId, cancellationText } = value;
    const groupAttendees = value.calendarEvent.attendeesPatientAndContact;
    const prevAttendee = calendarEvent.attendeesPatientAndContact.find(
      attendee =>
        attendee.attendeeId === this.booking.ui.cancelCalendarEventAttendeeId
    );

    if (prevAttendee) {
      const newGroupAttendees = groupAttendees?.map(groupAttendee => {
        if (groupAttendee.attendeeId === prevAttendee.attendeeId) {
          const attendee: CalendarEventAttendeeDto = {
            ...groupAttendee,
            cancellationReasonId: cancellationReasonId || undefined,
            cancellationText,
            status: CalendarEventStatus.Cancelled
          };
          return attendee;
        }
        return groupAttendee;
      });

      if (calendarEvent.user) {
        const provider: CalendarEventAttendeeDto = {
          type: AttendeeTypeEnum.user,
          attendeeId: calendarEvent.user.id
        };
        newGroupAttendees.push(provider);
        const baseRequest = {
          attendees: newGroupAttendees
        };
        await this.booking.updateCalendarEvent({
          ...baseRequest,
          id: calendarEvent.id
        });
      } else {
        throw new Error("Provider required");
      }
    }
  };

  get isCalendarReadOnly() {
    return !this.core.hasPermissions(Permission.CalendarEventWrite);
  }

  draggableAccessor = (event: BigCalendarEvent) =>
    !this.isCalendarReadOnly && !event.isPseudoEvent;

  resizableAccessor = (event: BigCalendarEvent) =>
    !this.isCalendarReadOnly && !event.isPseudoEvent;

  get hasBookOutsideWorkingHoursPermissions() {
    return this.core.hasPermissions([Permission.BookingScheduleWrite]);
  }

  @computed
  get events() {
    return [
      ...convertToBigCalendarEvent(this.filteredCalendarEvents),
      ...this.unavailableTimeslots,
      ...this.differentLocationAppointments
    ];
  }

  @computed
  get unavailableTimeslots(): BigCalendarEvent[] {
    const unavailableTimeslots: CalendarEvent[] = [];

    this.bookableUsers.forEach(user => {
      const userAvailability = this.booking.userAvailabilityMap.get(user.id);
      const orgUnitAvailability = this.orgUnitAvailability;
      const userTimeRange = this.usersWorkingHours?.find(
        range => range.userId === user.id
      )?.orgUnitRanges.timeRanges;

      if (userAvailability && orgUnitAvailability && userTimeRange) {
        const timeslots = this.calculateUnavailableTimeslots({
          userId: user.id,
          startDate: this.startDate,
          userAvailability,
          userTimeRange,
          orgUnitAvailability
        });

        timeslots.forEach(timeslot => {
          const calendarEventDto: CalendarEventDto = {
            startTime: timeslot.start.toISO(),
            endTime: timeslot.end.toISO(),
            orgUnitId: this.core.locationId,
            type: CalendarEventType.ClosedException,
            content:
              timeslot.reason ||
              `${timeslot.isProvider ? "Provider" : "Practice"} unavailable`,
            attendees: [
              {
                attendeeId: user.id,
                type: AttendeeTypeEnum.user
              }
            ],
            eTag: "",
            id: ""
          };

          unavailableTimeslots.push(
            new CalendarEvent(this.root, calendarEventDto)
          );
        });
      }
    });

    return convertToBigCalendarEvent(unavailableTimeslots);
  }

  @computed
  get differentLocationAppointments(): BigCalendarEvent[] {
    // These are events orphaned appointments that are for locations not currently selected in the filter
    //  They display as a grey, mostly-empty event until clicked

    if (!this.core.hasMultipleActiveLocations || !this.usersWorkingHours) {
      return [];
    }

    const orphanedAppointments: CalendarEvent[] = [];

    this.calendarEvents.forEach(event => {
      if (
        !this.orgUnitIds.includes(event.orgUnitId) &&
        this.getIsOrphaned(event)
      ) {
        const calendarEventDto: CalendarEventDto = {
          startTime: event.startDateTime.toISO(),
          endTime: event.endDateTime.toISO(),
          orgUnitId: event.orgUnitId,
          type: CalendarEventType.AnotherLocation,
          attendees: [
            {
              attendeeId: event.userId,
              type: AttendeeTypeEnum.user
            }
          ],
          eTag: "",
          id: event.id
        };

        orphanedAppointments.push(
          new CalendarEvent(this.root, calendarEventDto)
        );
      }
    });

    return convertToBigCalendarEvent(orphanedAppointments);
  }

  @computed
  get differentLocationTimeslots(): BigCalendarEvent[] {
    // These are provider working hours for locations not currently selected in the filter
    //  They display as a grey, mostly-empty background event until clicked

    if (!this.core.hasMultipleActiveLocations || !this.usersWorkingHours) {
      return [];
    }

    const differentLocationTimeslots: CalendarEvent[] = [];

    this.usersWorkingHours.forEach(orgUnitTimeRange => {
      if (!this.resourceIds.includes(orgUnitTimeRange.userId)) return;

      orgUnitTimeRange.orgUnitRanges.timeRanges.forEach(timeRange => {
        if (this.orgUnitIds.includes(timeRange.orgUnitId)) return;

        const calendarEventDto: CalendarEventDto = {
          startTime: timeRange.from.toISO(),
          endTime: timeRange.to.toISO(),
          orgUnitId: timeRange.orgUnitId,
          type: CalendarEventType.AnotherLocation,
          attendees: [
            {
              attendeeId: orgUnitTimeRange.userId,
              type: AttendeeTypeEnum.user
            }
          ],
          eTag: "",
          id: ""
        };

        differentLocationTimeslots.push(
          new CalendarEvent(this.root, calendarEventDto)
        );
      });
    });

    return convertToBigCalendarEvent(differentLocationTimeslots);
  }

  cachedSlotProps = new Map();

  getAvailability = (
    start: DateTime,
    end: DateTime,
    userId: string | undefined
  ) => {
    return getCalendarAvailability({
      start,
      end,
      providerTimeRange: this.getUsersWorkingHours(userId)?.orgUnitRanges,
      timeRange: this.locationHours,
      hasPermissions: this.hasBookOutsideWorkingHoursPermissions
    });
  };

  updateCalendarEventWithoutSaving = (
    dragDropEvent: DragAndDropInfo<BigCalendarEvent>
  ) => {
    const {
      event: { id, model }
    } = dragDropEvent;

    const calendarEvent = this.root.booking.calendarEventsMap.get(id)!;

    const startTime = this.getEventDateTime(dragDropEvent.start);
    const orgUnitId = this.getOrgUnitIdBySlotTime(
      startTime,
      dragDropEvent.resourceId
    );

    calendarEvent.updateFromPatch({
      startTime: startTime.toISO(),
      endTime: this.getEventDateTime(dragDropEvent.end).toISO(),
      orgUnitId,
      attendees: [
        {
          attendeeId: dragDropEvent.resourceId,
          type: AttendeeTypeEnum.user
        },
        ...model.attendeesPatientAndContact
      ]
    });

    this.pushToOrgUnitIds(calendarEvent.orgUnitId);
  };

  validationError = (title: string, validationMessage: string) => {
    confirm({
      confirmButtonProps: {
        text: "OK"
      },
      cancelButtonProps: { style: { display: "none" } },
      dialogContentProps: {
        subText: validationMessage,
        title
      },
      modalProps: {
        styles: { root: { whiteSpace: "pre-wrap" } }
      }
    });
  };

  showCannotEditPastAppointmentDialog = () => {
    confirm({
      confirmButtonProps: {
        styles: { root: { display: "none" } }
      },
      cancelButtonProps: {
        text: "OK"
      },
      dialogContentProps: {
        subText: "Appointments more than 12 hours old cannot be edited."
      }
    });
  };

  moveEvent = async (dragDropEvent: DragAndDropInfo<BigCalendarEvent>) => {
    if (!dragDropEvent) {
      return;
    }

    const { event, start, end, resourceId } = dragDropEvent;

    const startd = this.getEventDateTime(start);
    const endd = this.getEventDateTime(end);

    if (event.startDateTime.equals(startd) && event.userId === resourceId) {
      return;
    }

    if (isBefore12hours(event.startDateTime)) {
      this.showCannotEditPastAppointmentDialog();
      return;
    }

    const eventType = event.typeRef && event.typeRef.text;
    const isUnavailableSeries =
      event.type === CalendarEventType.Unavailable &&
      event.calendarEventRecurrenceId;

    const slotAvailability = this.getAvailability(
      startd,
      endd,
      dragDropEvent.resourceId
    );

    const user = await this.core.getUser(resourceId);
    let dialogContentProps: IDialogContentProps | undefined;

    switch (slotAvailability) {
      case CalendarEventPosition.NoPermission: {
        this.root.notification.warn(
          WorkingHoursAvailabilityMessages.moveNoPermissionMessage(eventType)
        );
        return;
      }
      case CalendarEventPosition.PracticeWorkingHours: {
        dialogContentProps = {
          subText:
            WorkingHoursAvailabilityMessages.practiceOutsideWorkingHoursMessage,
          title:
            WorkingHoursAvailabilityMessages.practiceOutsideWorkingHoursTitle
        };
        break;
      }
      case CalendarEventPosition.ProviderWorkingHours: {
        dialogContentProps = {
          subText:
            WorkingHoursAvailabilityMessages.providerOutsideWorkingHoursMessage(
              user.name
            ),
          title:
            WorkingHoursAvailabilityMessages.providerOutsideWorkingHoursTitle
        };
        break;
      }
      case CalendarEventPosition.Available: {
        const contactName = event.contact && event.contact.name;

        const type = event.isGroupAppointment
          ? event.model.groupAppointmentName
          : eventType;

        const content = `Are you sure you want to move ${
          !event.isGroupAppointment ? "this" : ""
        } ${isUnavailableSeries ? "ocurrence" : type} ${
          contactName && !event.isGroupAppointment ? `with ${contactName}` : ""
        } to ${startd.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)} on
                ${startd.toDayDefaultFormat()} with ${user.name}?`;

        dialogContentProps = {
          subText: content
        };
      }
    }

    this.updateCalendarEventWithoutSaving(dragDropEvent);

    if (
      isUnavailableSeries &&
      (resourceId !== event.userId ||
        startd.startOf("day").toMillis() ===
          event.startDateTime.startOf("day").toMillis())
    ) {
      this.booking.undoPendingChanges(event.id);
      this.root.notification.warn("This occurrence cannot be moved.");
      return;
    }

    const value = await event.model.validate();
    if (value && value.hasError) {
      this.root.booking.undoPendingChanges(event.id);
      const message = `Unable to move appointment.\n${value.errors[0]}`;
      this.validationError("Series Conflict", message);
    } else {
      const isConfirmed = await confirm({
        cancelButtonProps: {
          text: "No"
        },
        confirmButtonProps: {
          text: "Yes"
        },
        dialogContentProps
      });

      if (isConfirmed) {
        const timeSlotOrgUnitId = this.getOrgUnitIdBySlotTime(
          this.getEventDateTime(dragDropEvent.start),
          dragDropEvent.resourceId
        );
        if (timeSlotOrgUnitId) {
          await event.model.save();
        } else {
          this.booking.ui.showCalendarEventDialog({
            type: dragDropEvent.event.type,
            id: dragDropEvent.event.id,
            initialValues: { orgUnitId: undefined },
            onCancel: () => {
              this.booking.undoPendingChanges(event.id);
            }
          });
        }
      } else {
        this.booking.undoPendingChanges(event.id);
      }
    }
  };

  resizeEvent = async (dragDropEvent: DragAndDropInfo<BigCalendarEvent>) => {
    const { event, start, end } = dragDropEvent;
    const startDate = this.getEventDateTime(start);
    const endDate = this.getEventDateTime(end);
    if (
      event.startDateTime.toTimeInputFormat() ===
        startDate.toTimeInputFormat() &&
      event.endDateTime.toTimeInputFormat() === endDate.toTimeInputFormat()
    ) {
      return;
    }

    if (isBefore12hours(event.startDateTime)) {
      this.showCannotEditPastAppointmentDialog();
      return;
    }

    const eventType = event.typeRef && event.typeRef.text;

    const slotAvailability = this.getAvailability(
      startDate,
      endDate,
      dragDropEvent.resourceId
    );

    if (slotAvailability === CalendarEventPosition.NoPermission) {
      if (!this.hasBookOutsideWorkingHoursPermissions) {
        this.root.notification.warn(
          `${eventType} cannot be moved outside working hours.`
        );
        return;
      }
    }

    // Check to ensure appointment is not shrunk to 0 minutes duration before saving draft appointment changes
    if (endDate.toTimeInputFormat() <= startDate.toTimeInputFormat()) {
      dragDropEvent.end = startDate.plus({ minutes: 1 }).toJSDate();
    }

    this.updateCalendarEventWithoutSaving(dragDropEvent);
    const duration = endDate
      .startOf("minute")
      .diff(startDate.startOf("minute"), "minutes").minutes;

    const user = await this.core.getUser(dragDropEvent.resourceId);
    const contactName = event.contact && event.contact.name;

    const singleApptContent = contactName
      ? `Are you sure you want to change ${contactName}'s appointment duration to ${duration} minutes with ${user.fullName}?`
      : `Are you sure you want to change the ${eventType}'s duration to ${duration} minutes with ${user.fullName}?`;

    const groupApptContent = `Are you sure you want to change the ${event.groupAppointmentName}'s appointment duration to ${duration} minutes with ${user.fullName}?`;

    const value = await event.model.validate();
    if (value && value.hasError) {
      this.booking.undoPendingChanges(event.id);
      const message = `Unable to move appointment.\n${value.errors[0]}`;
      this.validationError("Series Conflict", message);
    } else {
      const isConfirmed = await confirm({
        cancelButtonProps: {
          text: "No"
        },
        confirmButtonProps: {
          text: "Yes"
        },
        dialogContentProps: {
          subText: event.isGroupAppointment
            ? groupApptContent
            : singleApptContent
        }
      });

      if (isConfirmed) {
        await event.model.save();
      } else {
        this.booking.undoPendingChanges(event.id);
      }
    }
  };

  confirmIfOutsideWorkingHours = async (
    slotAvailability: CalendarEventPosition,
    resourceId: string
  ) => {
    const user = await this.core.getUser(resourceId);

    let dialogContentProps: IDialogContentProps | undefined;

    if (slotAvailability === CalendarEventPosition.PracticeWorkingHours) {
      dialogContentProps = {
        subText:
          WorkingHoursAvailabilityMessages.practiceOutsideWorkingHoursMessage,
        title: WorkingHoursAvailabilityMessages.practiceOutsideWorkingHoursTitle
      };
    }

    if (slotAvailability === CalendarEventPosition.ProviderWorkingHours) {
      dialogContentProps = {
        subText:
          WorkingHoursAvailabilityMessages.providerOutsideWorkingHoursMessage(
            user.name
          ),
        title: WorkingHoursAvailabilityMessages.providerOutsideWorkingHoursTitle
      };
    }

    if (!dialogContentProps) {
      return true;
    }

    return confirm({
      cancelButtonProps: {
        text: "No"
      },
      confirmButtonProps: {
        text: "Yes"
      },
      dialogContentProps
    });
  };

  onSelectSlot = async (slotInfo: BookingCalendarSlotInfo) => {
    const { activeAppointmentTypes } = this.root.booking;

    if (slotInfo.action !== "click") {
      // new tenant with no providers to book
      if (!slotInfo.resourceId) {
        this.showCalendarEventDialog(
          {
            type: CalendarEventType.Appointment
          },
          AppointmentStartingPoints.CalendarDoubleClick
        );
        return;
      }

      const startTime = this.getEventDateTime(slotInfo.start);

      const appointmentTypeDuration =
        activeAppointmentTypes.length > 0
          ? activeAppointmentTypes[0] && activeAppointmentTypes[0].duration
          : undefined;

      const endTime =
        slotInfo.action === "select"
          ? this.getEventDateTime(slotInfo.end)
          : startTime.plus({ minutes: appointmentTypeDuration });

      const slotAvailability = this.getAvailability(
        startTime,
        endTime,
        slotInfo.resourceId
      );

      if (slotAvailability === CalendarEventPosition.NoPermission) {
        this.root.notification.warn(
          WorkingHoursAvailabilityMessages.createPermissionMessage
        );
        return;
      }

      const isConfirmed = await this.confirmIfOutsideWorkingHours(
        slotAvailability,
        slotInfo.resourceId
      );

      if (!isConfirmed) {
        this.booking.ui.hideCalendarEventDialog();
        return;
      }

      const duration = endTime.diff(startTime, ["minutes"]).toObject().minutes;

      const orgUnitId = this.getOrgUnitIdBySlotTime(
        startTime,
        slotInfo.resourceId
      );

      const initialValues: Partial<AppointmentFormValues> = {
        providerId: slotInfo.resourceId,
        startDate: startTime.startOf("day").toJSDate(),
        expiryDate: startTime.startOf("day").toJSDate(),
        startTime: startTime.toTimeInputFormat(),
        orgUnitId,
        duration
      };

      this.showCalendarEventDialog(
        {
          type: CalendarEventType.Appointment,
          initialValues
        },
        AppointmentStartingPoints.CalendarDoubleClick
      );

      if (orgUnitId) {
        const temporaryReservationRequest: AddCalendarEventDto = {
          startTime: startTime.toISO(),
          endTime: endTime.toISO(),
          bookedBy: this.core.userId,
          attendees: [{ attendeeId: slotInfo.resourceId!, type: "USER" }],
          type: CalendarEventType.TemporaryReservation,
          orgUnitId
        };

        await this.booking.addTemporaryReservation(temporaryReservationRequest);
      }
    }
  };

  loadBookableUsers = async () => {
    const results = await this.core.fetchUsers({ showOnCalendar: true });

    const filteredResults = results
      .filter(
        user =>
          user.status === UserStatus.Active &&
          !!user.userSetting?.showOnCalendar
      )
      .sort(this.selfThenLastNameComparer);

    runInAction(() => {
      this.bookableUsers = filteredResults;
    });

    return filteredResults;
  };

  loadUserAvailability = async (bookableUsers: User[]) => {
    const userIds = bookableUsers.map(u => u.id);
    return this.booking.getUserAvailabilities(userIds);
  };

  loadOrgUnitAvailability = async () => {
    return this.booking.getOrgUnitAvailability(this.core.locationId);
  };

  loadOrgUnitIds = async () => {
    const response = await this.userExperience.getUserStorage(
      UserStorageKeys.OrgUnitIds
    );

    if (response?.jsonData) {
      return response.jsonData as string[];
    }

    if (this.core.user) {
      await this.booking.getUserAvailability(this.core.user.id);
      if (this.core.user.availableOrgUnitIds.length) {
        return this.core.user.availableOrgUnitIds;
      }
    }

    return [this.core.locationId];
  };

  loadDefaultOrgUnitId = async () => {};

  loadCalendarView = async () => {
    const response = await this.userExperience.getUserStorage(
      UserStorageKeys.CalendarView
    );

    if (response?.jsonData) {
      return response.jsonData as CalendarEventView;
    }

    return defaultCalendarView;
  };

  calculateUnavailableTimeslots = (options: {
    userId: string;
    startDate: DateTime;
    userAvailability: UserAvailabilityModel;
    userTimeRange: TimeRangeOrgUnit[];
    orgUnitAvailability: OrgUnitAvailability;
  }): Array<{
    start: DateTime;
    end: DateTime;
    isProvider: boolean;
    reason?: string;
  }> => {
    const { startDate, userAvailability, userTimeRange, orgUnitAvailability } =
      options;

    const endDate = startDate.plus({ days: 6 }).endOf("day");

    const isBetweenDates = (overrideStart: DateTime) => {
      return (
        overrideStart < endDate &&
        (overrideStart > startDate || overrideStart.equals(startDate))
      );
    };

    const userOverrideDateTimes = userAvailability.scheduleOverrides.reduce(
      (
        dateTimes: Array<{
          start: DateTime;
          end: DateTime;
          isProvider: boolean;
          reason?: string;
        }>,
        override
      ) => {
        if (!override.isAvailable) {
          const start = DateTime.fromISO(override.startDate).startOf("day");

          if (isBetweenDates(start)) {
            const end = start.endOf("day");
            dateTimes.push({
              start,
              end,
              isProvider: true,
              reason: override.reason
            });
          }
        }

        return dateTimes;
      },
      []
    );

    const timeslots = [...userOverrideDateTimes];

    orgUnitAvailability.openingHoursOverrides.forEach(override => {
      if (override.isClosed) {
        const start = DateTime.fromISODateAndTime(
          override.startDate,
          override.startTime
        );

        if (isBetweenDates(start)) {
          if (
            userTimeRange.every(
              timeRange => !start.hasSame(timeRange.from, "day")
            )
          ) {
            if (
              userOverrideDateTimes.every(
                userOverrideStart =>
                  !start.hasSame(userOverrideStart.start, "day")
              )
            ) {
              const end = start.endOf("day");

              timeslots.push({
                start,
                end,
                isProvider: false,
                reason: override.reason
              });
            }
          }
        }
      }
    });

    return timeslots;
  };

  getWorkWeekRange = (day: DateTime) => {
    const closedExceptionDates = getClosedExceptionDates(
      this.resourceIds,
      this.booking.userAvailabilityMap,
      this.orgUnitAvailability
    );

    return calculateWorkWeekRange(
      day.toJSDate(),
      this.providerAndLocationHours,
      closedExceptionDates
    );
  };

  getEventDateTime = (eventDate: Date | string): DateTime => {
    return typeof eventDate === "string"
      ? DateTime.fromISO(eventDate)
      : DateTime.fromJSDate(eventDate);
  };

  getUserIsAvailable = (
    slotTime: DateTime,
    resourceId: string | undefined
  ): boolean => {
    const userWorkingHours = this.getUsersWorkingHours(resourceId);
    const timeRanges = userWorkingHours?.orgUnitRanges.timeRanges ?? [];

    return timeRanges.some(
      timeRange => slotTime >= timeRange.from && slotTime < timeRange.to
    );
  };

  getOrgUnitIdBySlotTime = (
    slotTime: DateTime,
    resourceId: string | undefined
  ): string | undefined => {
    if (!this.core.hasMultipleActiveLocations) return this.core.locationId;

    const userWorkingHours = this.getUsersWorkingHours(resourceId);
    const timeRanges = userWorkingHours?.orgUnitRanges.timeRanges ?? [];

    return timeRanges.find(
      timeRange => slotTime >= timeRange.from && slotTime < timeRange.to
    )?.orgUnitId;
  };

  getLocationColourByOrgUnitId = (orgUnitId: string): string | undefined => {
    const locData =
      this.root.practice.allOrgUnitsLocationDataMap.get(orgUnitId);
    return locData?.orgUnitLocationData?.appointmentBookMarkerCode;
  };

  getLocationColourBySlotTime = (
    slotTime: DateTime,
    resourceId: string
  ): string | undefined => {
    const foundOrgUnitId = this.getOrgUnitIdBySlotTime(slotTime, resourceId);

    if (!foundOrgUnitId) return undefined;

    return this.getLocationColourByOrgUnitId(foundOrgUnitId);
  };

  // An orphaned appointment is an appointment that is outside regular working hours for the provider and location
  getIsOrphaned = (
    event: Pick<
      BigCalendarEvent,
      "userId" | "orgUnitId" | "startDateTime" | "type"
    >
  ): boolean => {
    if (event.type === CalendarEventType.AnotherLocation) return false;

    const timeslotOrgUnit = this.getOrgUnitIdBySlotTime(
      event.startDateTime,
      event.userId
    );

    return timeslotOrgUnit !== event.orgUnitId;
  };

  // This method should be used rather than calling booking.ui.showCalendarEventDialog directly to ensure
  //  that the calendar gets updated appropriately after submit
  showCalendarEventDialog = (
    options: Omit<AppointmentDialogOptions, "onSubmitted">,
    startingPoint?: AppointmentStartingPoints
  ) => {
    return this.booking.ui.showCalendarEventDialog(
      {
        ...options,
        onSubmitted: (values: AppointmentFormValues) => {
          this.pushToOrgUnitIds(values.orgUnitId);
        }
      },
      startingPoint
    );
  };

  bookableUsersInitialSort = (
    bookableUsers: User[],
    resourceIds: string[]
  ): User[] => {
    return Array.from(bookableUsers).sort((userA, userB) => {
      const userASelected = resourceIds.includes(userA.id);
      const userBSelected = resourceIds.includes(userB.id);

      if (userASelected && !userBSelected) {
        return -1;
      } else if (userBSelected && !userASelected) {
        return 1;
      } else {
        return this.selfThenLastNameComparer(userA, userB);
      }
    });
  };

  loadInitialData = async () => {
    const [
      { userAvailabilities, usersWorkingHours },
      locationHours,
      orgUnitAvailability
    ] = await Promise.all([
      this.loadBookableUsers().then(async bookableUsers => {
        const [resourceIds, userAvailabilities] = await Promise.all([
          this.getResourceIds(bookableUsers),
          this.loadUserAvailability(bookableUsers)
        ]);

        runInAction(() => {
          this.bookableUsers = this.bookableUsersInitialSort(
            bookableUsers,
            resourceIds
          );
          this._resourceIds = resourceIds;
          this.isInitialLoadingProviders = false;
        });

        const usersWorkingHours = await this.loadUsersWorkingHours(
          resourceIds,
          this.startDate
        );
        return {
          userAvailabilities,
          usersWorkingHours
        };
      }),
      this.loadLocationOpeningHours(this.startDate),
      this.loadOrgUnitAvailability(),
      this.loadOrgUnitIds().then(orgUnitIds => {
        runInAction(() => {
          this.orgUnitIds = orgUnitIds;
          this.isInitialLoadingOrgUnits = false;
        });
      }),
      this.loadCalendarView().then(calendarView => {
        runInAction(() => {
          this.calendarView = calendarView;
          this.isInitialLoadingCalendarView = false;
        });
      })
    ]);

    const calendarEvents = await this.loadCalendarEvents({
      startTime: this.startDate,
      resourceIds: this.resourceIds,
      dateRange: this.dayOrWeekView
    });

    runInAction(() => {
      this.userAvailabilities = userAvailabilities;
      this.usersWorkingHours = usersWorkingHours;
      this.locationHours = locationHours;
      this.orgUnitAvailability = orgUnitAvailability;
      this.calendarEvents = calendarEvents.results;
      this.isInitialLoading = false;
    });
  };

  onStartDateChanged = async (startDate: DateTime) => {
    runInAction(() => {
      this.isReloading = true;
    });

    const [usersWorkingHours, locationHours, calendarEvents] =
      await Promise.all([
        this.loadUsersWorkingHours(this.resourceIds, startDate),
        this.loadLocationOpeningHours(startDate),
        this.loadCalendarEvents({
          startTime: startDate,
          resourceIds: this.resourceIds,
          dateRange: this.dayOrWeekView
        })
      ]);

    // All the data gets set at once so the calendar only needs to rerender once
    runInAction(() => {
      this._startDate = startDate;
      this.usersWorkingHours = usersWorkingHours;
      this.locationHours = locationHours;
      this.calendarEvents = calendarEvents.results;
      this.isReloading = false;
    });
  };

  onChangeResourceIds = async (resourceIds: string[]) => {
    const resourceIdsToFetch = resourceIds.filter(id => {
      if (this.resourceIds.includes(id)) return false;
      return !this.usersWorkingHours?.some(({ userId }) => userId === id);
    });

    if (!resourceIdsToFetch.length) {
      this.setResourceIds(resourceIds);
      return;
    }

    runInAction(() => {
      this.isReloading = true;
    });

    const [usersWorkingHours, calendarEvents] = await Promise.all([
      this.loadUsersWorkingHours(resourceIdsToFetch, this.startDate),
      this.loadCalendarEvents({
        startTime: this.startDate,
        resourceIds: this.resourceIds,
        dateRange: this.dayOrWeekView
      })
    ]);

    // All the data gets set at once so the calendar only needs to rerender once
    runInAction(() => {
      this.setResourceIds(resourceIds);
      this.usersWorkingHours = [
        ...(this.usersWorkingHours ?? []),
        ...(usersWorkingHours ?? [])
      ];
      this.calendarEvents = calendarEvents.results;
      this.isReloading = false;
    });
  };
}
