import { BlockBlobClient } from "@azure/storage-blob";
import { DurationUnit } from "@bps/utils";
import { ProviderOnlineStatus } from "@libs/gateways/bhb/bhbGateway.dtos.ts";

import { DurationValue } from "./online-settings/BhbOnlineSettings.types.ts";

export const getValueInMinutes = (unitValue: number, unit: DurationUnit) => {
  switch (unit) {
    case "days":
      return unitValue * 24 * 60;
    case "hours":
      return unitValue * 60;
    case "minutes":
    default:
      return unitValue;
  }
};

export const getDurationValue = (valueInMinutes?: number): DurationValue => {
  if (!valueInMinutes) {
    return { unitValue: 0, unit: "minutes" };
  }

  const valueInHours = valueInMinutes / 60;
  if (valueInHours % 1 > 0) {
    return { unitValue: valueInMinutes, unit: "minutes" };
  }

  const valueInDays = valueInHours / 24;

  return valueInDays % 1 === 0
    ? { unitValue: valueInDays, unit: "days" }
    : { unitValue: valueInHours, unit: "hours" };
};

export const providerOnlineStatusTooltip = (
  onlineStatus?: ProviderOnlineStatus
) => {
  switch (onlineStatus) {
    case ProviderOnlineStatus.NO:
      return "Provider will not display and cannot be booked online.";
    case ProviderOnlineStatus.YES:
      return "Provider will display and can be booked online.";
    default:
      return "Provider will display but cannot be booked online.";
  }
};

export const bhbDefaultImgDimensions = 1024 * 1024;

export const getFileFromUrl = async (name: string, practiceLogoUrl: string) => {
  const blobClient = new BlockBlobClient(practiceLogoUrl);
  const blobBody = await (await blobClient.download()).blobBody;
  if (blobBody) {
    const type = blobBody.type;
    const [, extension] = type.split("/");
    const file = new File([blobBody], `${name}.${extension}`, {
      type
    });
    return file;
  }
  return;
};
