import { observer } from "mobx-react-lite";
import { FC } from "react";

import { CSSTransition, getScreenSelector, Stack } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AppointmentsFilter } from "./appointments-filter/AppointmentsFilter.tsx";
import { BookingResourceFilter } from "./appointments-filter/BookingResourceFilter.tsx";
import {
  BASIC_PADDING,
  FILTERS_MIN_WIDTH
} from "./BookingCalendarFilters.types.ts";
import {
  LARGE_SCREEN_BREAKPOINT,
  NAV_CONTAINER_HEIGHT
} from "./BookingCalendarNavigation.tsx";
import { BookingCalendarPicker } from "./BookingCalendarPicker.tsx";

export const BookingCalendarFiltersBase: FC = observer(() => {
  const { hideLeftSide } = useBookingCalendarScreenContext();

  return (
    <CSSTransition
      in={!hideLeftSide}
      timeout={{ appear: 100, enter: 250, exit: 100 }}
      classNames={{
        enter: "animate__animated",
        enterActive: "animate__fadeInLeft",
        exit: "animate__animated",
        exitActive: "animate__fadeOutLeft"
      }}
      unmountOnExit
    >
      <Stack
        tokens={{ childrenGap: BASIC_PADDING }}
        styles={{
          root: {
            minWidth: FILTERS_MIN_WIDTH,
            selectors: {
              [getScreenSelector(0, LARGE_SCREEN_BREAKPOINT)]: {
                paddingTop: NAV_CONTAINER_HEIGHT + BASIC_PADDING
              }
            }
          }
        }}
      >
        <BookingCalendarPicker />
        <BookingResourceFilter />
        <AppointmentsFilter />
      </Stack>
    </CSSTransition>
  );
});

export const BookingCalendarFilters = withFetch(
  x => [
    x.booking.ref.appointmentStatuses.load(),
    x.practice.ref.invoiceStatuses.load()
  ],
  BookingCalendarFiltersBase
);
