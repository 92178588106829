import { reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Field, useField, useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { ExpanderButton } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/ExpanderButton.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { AppointmentFormContext } from "../context/AppointmentFormContext.ts";
import { EpisodeOfCareBadges } from "../EpisodeOfCareBadges.tsx";

enum ClaimDetailsButtonsLabels {
  LinkCondition = "Link or create a condition"
}

interface ConditionDetailsButtonProps {
  disabled?: boolean;
}

export const ConditionDetailsButton: React.FC<ConditionDetailsButtonProps> =
  observer(({ disabled }) => {
    const { palette } = useTheme();

    const { booking } = useStores();

    const {
      conditionAppointmentFormHelper,
      setConditionButtonClicked,
      calendarEvent
    } = useContext(AppointmentFormContext);

    const isConditionDetailsSecondColum =
      booking.ui.currentAppointment?.secondColumnContent ===
      SecondColumnContent.conditionDetails;

    const onClick = () => {
      booking.ui.setSecondColumnContent(
        booking.ui.currentAppointment?.secondColumnContent !==
          SecondColumnContent.conditionDetails
          ? SecondColumnContent.conditionDetails
          : undefined
      );
      setConditionButtonClicked(true);
    };

    const form = useForm<AppointmentFormValues>();

    const {
      input: { value: patientId }
    } = useField(appointmentFormNameOf("patientId"), {
      subscription: { value: true }
    });

    const loadConditionDetails = async () => {
      setConditionButtonClicked(false);

      if (conditionAppointmentFormHelper) {
        conditionAppointmentFormHelper.setPatientId(patientId);

        runInAction(() => {
          conditionAppointmentFormHelper.conditionsPromise.set(
            conditionAppointmentFormHelper.getConditions()
          );
        });
      }
    };

    const autoLinkConditionReaction = reaction(
      () => conditionAppointmentFormHelper.conditionsPromise.value,
      async value => {
        if (value && !calendarEvent) {
          await conditionAppointmentFormHelper.autoLinkCondition();

          form.change(
            appointmentFormNameOf("episodeOfCareId"),
            conditionAppointmentFormHelper.episodeOfCareId
          );
        }
      }
    );

    useEffect(
      () => {
        return () => {
          //dispose reactions
          autoLinkConditionReaction();
        };
      },
      //eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <DataFetcher fetch={loadConditionDetails} refetchId={patientId}>
        {() => (
          <>
            <Field name={appointmentFormNameOf("episodeOfCareId")}>
              {() => null}
            </Field>
            <ExpanderButton<AppointmentFormValues>
              {...dataAttribute(
                DataAttributes.Element,
                "appt-form-claim-injury-button"
              )}
              hasBorder={false}
              fields={["episodeOfCareId"]}
              disabled={
                disabled ||
                conditionAppointmentFormHelper.conditionsPromise.pending
              }
              errorMessage="Required fields remain in this section"
              onClick={onClick}
              isOpened={isConditionDetailsSecondColum}
              styles={{
                root: {
                  maxWidth: "100%",
                  margin: 0,
                  height: 24,
                  marginTop: 8
                },
                flexContainer: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  alignItems: "center"
                }
              }}
              iconProps={{
                styles: {
                  root: {
                    margin: 0,
                    fontSize: FontSizes.size14
                  }
                }
              }}
            >
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 8 }}
              >
                {conditionAppointmentFormHelper.conditionsPromise.pending ? (
                  <Spinner />
                ) : (
                  <>
                    <FontIcon
                      iconName="documentSet"
                      styles={{
                        root: {
                          color: disabled
                            ? palette.neutralTertiary
                            : palette.themePrimary,
                          fontSize: FontSizes.size16
                        }
                      }}
                    />

                    <Stack
                      horizontal
                      verticalAlign="center"
                      tokens={{ childrenGap: 8 }}
                    >
                      {conditionAppointmentFormHelper.condition ? (
                        <EpisodeOfCareBadges
                          condition={conditionAppointmentFormHelper.condition}
                        />
                      ) : (
                        <Text> {ClaimDetailsButtonsLabels.LinkCondition}</Text>
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
            </ExpanderButton>
          </>
        )}
      </DataFetcher>
    );
  });
