import { observer } from "mobx-react-lite";
import { FC } from "react";

import { routes } from "@libs/routing/routes.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { RecentPatientsDetailsProps } from "./RecentPatientsList.types.ts";

export const RecentPatientLink: FC<RecentPatientsDetailsProps> = observer(
  ({ recentPatient }) => (
    <Navigate
      to={routes.contacts.contact.path({ id: recentPatient.patientId })}
    >
      Go to patient
    </Navigate>
  )
);
