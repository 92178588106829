import React, { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  FontWeights,
  IconButton,
  IContextualMenuItem,
  Spinner,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import {
  ClaimFormPrint,
  ClaimFormPrintType
} from "@modules/acc/screens/claim/components/ClaimFormPrint.tsx";
import { getClaimFormValues } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimDocumentViewerDialog } from "@modules/acc/screens/claims/components/ClaimDocumentViewerDialog.tsx";
import { SendACC45EmailModal } from "@modules/acc/screens/shared-components/SendACC45EmailModal.tsx";
import { EpisodeOfCareBadges } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/EpisodeOfCareBadges.tsx";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { BookingEventCalloutSeparator } from "./BookingEventCalloutSeparator.tsx";

const ConditionRowBase: React.FC<{ condition: ICondition }> = ({
  condition
}) => {
  const theme = useTheme();
  const { routing, acc, core, practice } = useStores();

  const contact = condition.patientId
    ? practice.contactsMap.get(condition.patientId)
    : undefined;

  const formMenuItems = useFormMenu(contact, { claimId: condition.claim?.id });

  const items: IContextualMenuItem[] = [
    {
      key: "open",
      text: "Open",
      onClick: () => {
        routing.push(
          routes.claims.management.edit.path({
            id: condition.claim!.id
          }),
          routing.getStateWithFromQuery()
        );
      }
    },
    {
      key: "print",
      text: "Print patient claim form",
      disabled: !condition?.claim?.isClaimStatusesAllowToPrintOnlineForm,
      onRenderContent: () => (
        <ClaimFormPrint
          values={getClaimFormValues(condition.claim)}
          printType={ClaimFormPrintType.Patient}
        />
      ),
      data: { filter: !condition.claim?.isNotVerifiedOrNotAvailable }
    },
    {
      key: "printSummary",
      text: "Print summary form",
      disabled: condition?.claim?.isClaimStatusesAllowToPrintOnlineForm,
      onRenderContent: () => (
        <ClaimFormPrint
          values={getClaimFormValues(condition.claim)}
          printType={ClaimFormPrintType.Summary}
        />
      ),
      data: { filter: !condition.claim?.isNotVerifiedOrNotAvailable }
    },
    {
      key: "emailSummary",
      disabled: condition?.claim?.isClaimStatusesAllowToPrintOnlineForm,
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        acc.ui.setCurrentClaimToEmail(condition.claim);
        acc.ui.setIsEmailACC45DialogVisible(true);
      },
      onRenderContent: () => (
        <SendACC45EmailModal
          patientId={condition.patientId!}
          patientName={condition.claim?.patientFullName}
          claimDto={condition.claim?.dto!}
        />
      ),
      data: {
        filter:
          !condition.claim?.isNotVerifiedOrNotAvailable &&
          core.hasPermissions(Permission.ClaimWrite)
      }
    },
    ...formMenuItems
  ].filter(x => x.data?.filter === undefined || x.data?.filter);

  return (
    <Stack horizontal horizontalAlign="space-between">
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <FontIcon
          iconName="documentSet"
          styles={{
            root: {
              color: theme.palette.themePrimary,
              fontSize: FontSizes.size16
            }
          }}
        />
        <Text
          {...dataAttribute(DataAttributes.Element, "condition-text")}
          styles={{ root: { fontWeight: FontWeights.semibold } }}
        >
          Condition
        </Text>

        <EpisodeOfCareBadges condition={condition} />
      </Stack>

      {!condition.isPrivate && (
        <Stack horizontalAlign="center" verticalAlign="center">
          <TooltipHost content="More">
            <IconButton
              onMouseDown={e => e.stopPropagation()}
              menuIconProps={{ iconName: "More" }}
              menuProps={{
                items
              }}
              styles={{
                root: { width: "32px", height: "36px", padding: 0 },
                flexContainer: { width: "32px", height: "36px" }
              }}
            />
          </TooltipHost>
        </Stack>
      )}
      <ClaimDocumentViewerDialog />
    </Stack>
  );
};

const ConditionRowDataFetcher = () => {
  const { getCondition } = useContext(BookingCalendarEventContext);

  return (
    <DataFetcher
      fetch={getCondition}
      fallback={<Spinner styles={{ root: { margin: "0 auto" } }} />}
    >
      {condition =>
        condition ? (
          <ConditionRowBase condition={condition} />
        ) : (
          <Text
            as="i"
            styles={(_prop, theme) => ({
              root: { color: theme.palette.neutralTertiary }
            })}
          >
            No conditions linked
          </Text>
        )
      }
    </DataFetcher>
  );
};

// TODO: Some or all of these may not be required anymore
export const ConditionRowWithFetch = withFetch(
  x => [
    x.acc.ref.accidentLocations.load(),
    x.acc.ref.accidentScenes.load(),
    x.acc.ref.occupations.load(),
    x.acc.ref.sports.load()
  ],
  ConditionRowDataFetcher,
  { fallback: null }
);

export const ConditionRow = () => {
  const { isPatientCalendarEvent } = useContext(BookingCalendarEventContext);

  if (!isPatientCalendarEvent) return null;

  return (
    <WhenCountry is={Country.NewZealand}>
      <BookingEventCalloutSeparator />
      <ConditionRowWithFetch />
    </WhenCountry>
  );
};
