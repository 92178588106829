import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import {
  AnonymousCredential,
  ContainerClient,
  newPipeline
} from "@azure/storage-blob";
import { IButtonProps, Stack } from "@bps/fluent-ui";
import { GetStagingInfoDto } from "@libs/api/dtos/index.ts";
import {
  ClaimAdjustmentDocumentTypes,
  DOCUMENT_FILE_SIZE_LIMIT
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { useAttachedDocumentCount } from "@modules/acc/screens/claim-adjustment/hooks/useAttachedDocumentCount.tsx";
import { ClaimAdjustmentDocumentUploadFormValue } from "@shared-types/acc/claim-adjustment-document-upload-value.types.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { ErrorWrapper } from "@ui-components/form/ErrorWrapper.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FileUploaderField } from "@ui-components/form/FileUploaderField/FileUploaderField.tsx";
import { getDefaultFileFields } from "@ui-components/form/FileUploaderField/utils.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { DOCUMENT_ATTACHMENT_LIMIT } from "../context/ClaimAdjustmentHelper.types.ts";

export const ClaimAdjustmentCorrespondenceFileUploaderField = observer(() => {
  const form = useForm();
  const { acc } = useStores();

  const { setStagingInfo } = useClaimAdjustmentContext();

  const attachedDocumentCount = useAttachedDocumentCount();

  const renderUploadedFile = ({
    fileFieldValue,
    fileFieldName,
    onRemoveFile
  }: {
    fileFieldValue: ClaimAdjustmentDocumentUploadFormValue;
    fileFieldName: string;
    onRemoveFile: IButtonProps["onClick"];
  }) => {
    if (!fileFieldValue.blobName) {
      fileFieldValue.blobName = form.getFieldState(
        `${fileFieldName}.name`
      )!.data!.blobName;
    }
    return (
      <Stack tokens={{ childrenGap: 8 }} styles={{ root: { marginTop: 8 } }}>
        <ErrorWrapper
          name="claim-adjustment-correspondence-file"
          fields={[
            `${fileFieldName}.fileName`,
            `${fileFieldName}.contentType`,
            `${fileFieldName}.assessmentType`,
            `${fileFieldName}.bodySite`,
            `${fileFieldName}.status`
          ]}
          errorMessage="Invalid fields remain in this section"
          messageOverride={{
            message: "ACC returned an error with this document.",
            field: `${fileFieldName}.status`,
            submitFailedOverride: true
          }}
        >
          <Fieldset frame styles={{ root: { padding: "8px 0 8px 8px" } }}>
            <Stack horizontal>
              <Stack grow tokens={{ childrenGap: 8 }}>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      display: "grid",
                      gap: 8,
                      gridAutoFlow: "column",
                      gridAutoColumns: "minmax(0, 1fr)"
                    }
                  }}
                >
                  <TextInputField
                    name={`${fileFieldName}.fileName`}
                    placeholder="File name"
                    suffix={
                      fileFieldValue.extension
                        ? fileFieldValue.extension
                        : undefined
                    }
                  />
                  <DropdownField
                    name={`${fileFieldName}.contentType`}
                    placeholder="Choose type ..."
                    dropdownWidth="auto"
                    options={acc.ref.documentContentTypes.keyTextValues}
                  />
                </Stack>
                <FieldCondition
                  when={`${fileFieldName}.contentType`}
                  is={ClaimAdjustmentDocumentTypes.RadiologyReports}
                >
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <TextInputField
                      name={`${fileFieldName}.assessmentType`}
                      placeholder="Assessment type"
                    />
                    <TextInputField
                      name={`${fileFieldName}.bodySite`}
                      placeholder="Body site of assessment"
                    />
                  </Stack>
                </FieldCondition>
              </Stack>
              <DeleteButton
                styles={{
                  root: {
                    height: 30,
                    width: 30,
                    display: "flex",
                    justifyContent: "center"
                  }
                }}
                onClick={onRemoveFile}
              />
            </Stack>
          </Fieldset>
        </ErrorWrapper>
      </Stack>
    );
  };

  const getData = async ({
    clinical
  }: IRootStore): Promise<GetStagingInfoDto> => {
    const stagingInfo = await clinical.getStagingInfo();
    setStagingInfo(stagingInfo);
    return stagingInfo;
  };

  return (
    <DataFetcher<GetStagingInfoDto> fetch={getData}>
      {stagingInfo => {
        return (
          <FileUploaderField<ClaimAdjustmentDocumentUploadFormValue>
            maxSize={DOCUMENT_FILE_SIZE_LIMIT}
            fileTooLargeText="is greater than 20MB limit"
            incorrectFileTypeText="is an incorrect type, only PDF allowed"
            maxFiles={DOCUMENT_ATTACHMENT_LIMIT - attachedDocumentCount}
            multiple={true}
            maxFileText={`Only ${DOCUMENT_ATTACHMENT_LIMIT} documents can be attached to an ACC32`}
            accept={{ "application/pdf": [] }}
            getFileFields={getDefaultFileFields}
            name={claimAdjustmentFormNameOf("uploadDocuments")}
            renderUploadedFile={renderUploadedFile}
            getContainerClient={async () =>
              new ContainerClient(
                stagingInfo.stagingUrl,
                newPipeline(new AnonymousCredential())
              )
            }
          />
        );
      }}
    </DataFetcher>
  );
});
