import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export type AppointmentFilterStatus =
  | AppointmentStatusCode
  | InvoiceStatus
  | ClinicalStatus
  | BillingStatus;

export enum BillingStatus {
  Unbilled = "UNBILLED"
}

export enum ClinicalStatus {
  incompleteNotes = "DN"
}

const completedTypes = [
  AppointmentStatusCode.Finalised,
  AppointmentStatusCode.Completed
];
export const apptTypes = [
  AppointmentStatusCode.Booked,
  AppointmentStatusCode.Waiting,
  AppointmentStatusCode.WithProvider,
  AppointmentStatusCode.Finalised,
  AppointmentStatusCode.Completed
];
export const billingTypes = [
  BillingStatus.Unbilled,
  InvoiceStatus.Unpaid,
  InvoiceStatus.Part,
  InvoiceStatus.Paid
];
export const combinedTypes = {
  appointementStatuses: [...apptTypes, ...completedTypes],
  invoiceStatuses: [
    InvoiceStatus.Unpaid,
    InvoiceStatus.Part,
    InvoiceStatus.Paid
  ]
};
