import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  ActionButton,
  Card,
  dataAttribute,
  DataAttributes,
  Heading,
  PivotItem,
  PivotTabs,
  Stack
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { usePatientSummaryContext } from "@modules/clinical/screens/patient-record/components/patient-summary/helpers/PatientSummaryContext.tsx";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

import { AddPatientNoticeFormDialog } from "./AddPatientNoticeFormDialog.tsx";
import { usePatientNoticesContext } from "./PatientNoticesContext.tsx";
import { NoticeType } from "./PatientNoticesModel.ts";
import { PatientNoticesTable } from "./PatientNoticesTable.tsx";

export enum ClinicalNoticesTestElements {
  Callout = "notices-callout"
}

export const PatientNoticeCallout: React.FC = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [filter, setFilter] = useState<NoticeType>(NoticeType.Clinical);

  const { patientId } = usePatientRecordScreenContext();

  const { clinicalNotices, adminNotices } = usePatientNoticesContext();

  const { setPreventDismissOnLostFocus } = usePatientSummaryContext();

  const noDataMessage =
    filter === NoticeType.Clinical ? "No clinical notices" : "No admin notices";

  const handlePivotClicked = (item: PivotItem): void => {
    const selectedValue = item.props && item.props.itemKey;
    if (selectedValue === filter) {
      return;
    }

    switch (selectedValue) {
      case NoticeType.Clinical:
        setFilter(NoticeType.Clinical);
        break;
      case NoticeType.Admin:
        setFilter(NoticeType.Admin);
        break;
    }
  };

  const calloutHeader = (
    <Stack
      horizontal
      tokens={{ childrenGap: 8 }}
      verticalAlign="center"
      horizontalAlign="space-between"
      styles={{
        root: {
          padding: "16px 24px"
        }
      }}
    >
      <ContactFetcher contactId={patientId}>
        {patient => (
          <Heading variant="section-heading-light">
            {patient?.name || ""}
          </Heading>
        )}
      </ContactFetcher>

      <PivotTabs selectedKey={filter} onLinkClick={handlePivotClicked}>
        <PivotItem
          headerText={`Clinical (${clinicalNotices.length})`}
          itemKey={NoticeType.Clinical}
        />
        <PivotItem
          headerText={`Admin (${adminNotices.length})`}
          itemKey={NoticeType.Admin}
        />
      </PivotTabs>

      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 24 }}>
        <ActionButton
          text="Add notice"
          iconProps={{
            iconName: "Add"
          }}
          onClick={() => setShowDialog(true)}
        />
      </Stack>
    </Stack>
  );

  return (
    <Card
      styles={{
        subComponentStyles: {
          tile: {
            root: { height: "100%" },
            header: {
              padding: 0
            },
            content: {
              flexGrow: 1,
              overflowY: "auto",
              padding: "16px 24px"
            }
          }
        },
        header: {
          padding: "16px 24px"
        }
      }}
      {...dataAttribute(
        DataAttributes.Element,
        ClinicalNoticesTestElements.Callout
      )}
      header={calloutHeader}
      headingLevel="section-heading-light"
    >
      <PatientNoticesTable
        filter={filter}
        showActions
        showNoDataTile
        noDataMessage={noDataMessage}
        setPreventDismissOnDelete={setPreventDismissOnLostFocus}
      />
      {showDialog && (
        <AddPatientNoticeFormDialog onDismiss={() => setShowDialog(false)} />
      )}
    </Card>
  );
});
