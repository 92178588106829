import { Heading, IStyle, mergeStyleSets, Stack } from "@bps/fluent-ui";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";

interface DiagnosisHeaderProps {
  showStatusCol: boolean;
  styles?: IStyle;
  diagnosisColumnLabel?: string;
  sideColumnLabel?: string;
}

export const DiagnosisHeader: React.FC<DiagnosisHeaderProps> = ({
  showStatusCol,
  styles,
  diagnosisColumnLabel,
  sideColumnLabel
}) => {
  return (
    <Stack
      horizontal
      styles={(prop, theme) =>
        mergeStyleSets(
          {
            root: {
              padding: 8,
              borderBottom: `1px solid ${theme.palette.neutralLight}`
            }
          },
          styles
        )
      }
      tokens={{ childrenGap: 8 }}
    >
      <Heading
        variant="section-sub-heading"
        styles={{ root: { flex: 1, padding: "5px 0px" } }}
      >
        {diagnosisColumnLabel}
      </Heading>
      <Heading
        variant="section-sub-heading"
        styles={{ root: { flexBasis: 130, padding: "5px 0px" } }}
      >
        {sideColumnLabel}
      </Heading>
      {showStatusCol && (
        <Heading
          variant="section-sub-heading"
          styles={{ root: { flexBasis: 122, padding: "5px 0px" } }}
        >
          {ClaimFormLabels.status}
        </Heading>
      )}
    </Stack>
  );
};
DiagnosisHeader.defaultProps = {
  diagnosisColumnLabel: ClaimFormLabels.description,
  sideColumnLabel: ClaimFormLabels.side
};
