import { observer } from "mobx-react-lite";

import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DocumentPillComponentBase } from "./DocumentPillComponentBase.tsx";

interface DocumentPillProps {
  loading?: boolean;
  active?: boolean;
  clinDocument?: ClinicalDocument;
  template?: Template;
  patient?: Contact;
  onClick?: () => void;
  onClose?: () => void;
}
export enum DOCUMENT_LABEL {
  PracticeLetterhead = "Practice letterhead",
  Financials = "Financials"
}

const TemplatePillBase: React.FunctionComponent<DocumentPillProps> = observer(
  ({ loading, active, patient, onClick, onClose, template }) => {
    const handleClick = () => {
      if (onClick && template) {
        onClick();
      }
    };

    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (onClose && template) {
        return onClose();
      }
    };

    const documentType = template?.documentType;
    const templateName = template?.name;

    return (
      <DocumentPillComponentBase
        loading={loading}
        active={active}
        patient={patient}
        handleClick={handleClick}
        handleClose={handleClose}
        documentType={documentType}
        title={templateName}
      />
    );
  }
);

export const TemplatePill = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  TemplatePillBase
);
