import { observer } from "mobx-react-lite";

import { Dialog, Heading, Stack } from "@bps/fluent-ui";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { CreateNewPatientLink } from "./components/CreateNewPatientLink.tsx";
import { ExternalPatientInfo } from "./components/ExternalPatientInfo.tsx";
import { PatientMatchActionButtons } from "./components/PatientMatchActionButtons.tsx";
import { PatientMatchConfirmDialogs } from "./components/PatientMatchConfirmDialogs.tsx";
import { usePatientMatchContext } from "./context/PatientMatchContext.ts";
import { PatientMatchPivot } from "./PatientMatchPivot.tsx";

export const PatientMatchForm: React.FC = observer(() => {
  const patientLabel = usePatientLabel(true);

  const { setShowPatientMatchingDiscardDialog } = usePatientMatchContext();

  return (
    <Dialog
      styles={{ root: { overflowY: "none" } }}
      hidden={false}
      onDismiss={() => setShowPatientMatchingDiscardDialog(true)}
      dialogContentProps={{
        title: (
          <Heading variant="modal-heading">{patientLabel} matching</Heading>
        ),
        showCloseButton: true,
        styles: {
          content: {
            display: "flex",
            flexDirection: "column"
          }
        }
      }}
      minWidth={950}
    >
      <>
        <Stack grow tokens={{ childrenGap: 8 }}>
          <ExternalPatientInfo />
          <PatientMatchPivot />
          <CreateNewPatientLink />
          <PatientMatchActionButtons />
        </Stack>
        <PatientMatchConfirmDialogs />
      </>
    </Dialog>
  );
});
