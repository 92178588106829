import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  IconButton,
  IContextualMenuItem,
  Stack,
  ToolTipButton
} from "@bps/fluent-ui";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AmendmentFormDialog } from "./amendment/AmendmentFormDialog.tsx";

interface PastVisitCommandBarProps {
  encounter: Encounter;
  printClick: () => void;
  clinicalRecord: ClinicalRecord;
  iconOnly?: boolean;
}
export const PastVisitCommandBar: React.FC<PastVisitCommandBarProps> = observer(
  ({ printClick, encounter, clinicalRecord }) => {
    const [isHidden, setIsHidden] = useState<boolean>(true);

    const { core, clinical } = useStores();

    const isAppendCompacted =
      clinical.ui.tabs.currentPatientRecordTab?.sidePanelSize !==
      TreeViewSize.Expanded;

    const items: IContextualMenuItem[] = [
      {
        key: "print",
        name: "Print",
        onClick: printClick,
        iconProps: {
          iconName: "Print"
        }
      }
    ];

    return (
      <Stack horizontal>
        <ToolTipButton
          compactModeProps={{
            showCompactMode: isAppendCompacted,
            toolTipContentOverride: "Append"
          }}
          buttonProps={{
            text: "Append",
            iconProps: { iconName: "EditNote" },
            styles: isAppendCompacted
              ? {
                  root: { width: "36px", height: "36px", border: 0, padding: 0 }
                }
              : { root: { border: 0, padding: 0 } },
            disabled: core.user?.id !== encounter.userId,
            onClick: () => {
              setIsHidden(false);
            }
          }}
        />
        <IconButton
          menuIconProps={{
            iconName: "More"
          }}
          menuProps={{ items }}
          styles={{
            root: { height: "36px" },
            flexContainer: { height: "36px" },
            rootDisabled: {
              backgroundColor: "transparent"
            }
          }}
        />
        <AmendmentFormDialog
          encounterId={encounter.id}
          isHidden={isHidden}
          setIsHidden={setIsHidden}
          clinicalRecord={clinicalRecord}
        />
      </Stack>
    );
  }
);
