import React from "react";
import { useForm } from "react-final-form";

import { AccordionItem, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { MEDIUM_BREAKPOINT } from "../../full-body-clinical-data/examination/utils.ts";
import { GeneralExamFieldConditionKeys } from "./GeneralExamination.types.ts";
import { nameOfGeneralExaminationForm } from "./GeneralExaminationFields.tsx";
import { GeneralExaminationFormValues } from "./GeneralExaminationFormValues.ts";
import {
  getFieldSetStyles,
  getGeneralExamAccordionItemStyles
} from "./utils.ts";

interface AccordionItemGeneralObservationsProps {
  values: GeneralExaminationFormValues;
  width: number;
}
export const AccordionItemGeneralObservations: React.FC<
  AccordionItemGeneralObservationsProps
> = ({ values, width }) => {
  const { clinical } = useStores();
  const form = useForm<GeneralExaminationFormValues>();
  const wrapValues = width <= MEDIUM_BREAKPOINT;

  const { skinDiscolouration, skinCyanosisPattern } = values;

  const isCyanosisSkinDiscolouration = skinDiscolouration
    ? skinDiscolouration === GeneralExamFieldConditionKeys.Cyanosis
    : false;

  const isEdited = !!skinDiscolouration || !!skinCyanosisPattern;

  return (
    <AccordionItem
      title="General observations"
      showTitleIcon={isEdited}
      titleIconName="PageEdit"
      styles={getGeneralExamAccordionItemStyles()}
      extendedByDefault={true}
    >
      <Fieldset
        tokens={{ childrenGap: 8 }}
        horizontal={!wrapValues}
        legendLevel="section-heading-light"
        legend="Skin"
        onChange={() => {
          if (!isCyanosisSkinDiscolouration) {
            form.change(
              nameOfGeneralExaminationForm("skinCyanosisPattern"),
              undefined
            );
          }
        }}
        frame
        styles={getFieldSetStyles()}
      >
        <Stack tokens={{ childrenGap: 4 }}>
          <ButtonsGroupSingleChoiceField
            label="Discolouration"
            options={clinical.ref.discolourations.keyTextValues}
            name={nameOfGeneralExaminationForm("skinDiscolouration")}
          />
        </Stack>
        {isCyanosisSkinDiscolouration && (
          <Stack tokens={{ childrenGap: 4 }}>
            <ButtonsGroupSingleChoiceField
              label="Cyanosis pattern"
              options={clinical.ref.cyanosisPatterns.keyTextValues}
              name={nameOfGeneralExaminationForm("skinCyanosisPattern")}
            />
          </Stack>
        )}
      </Fieldset>
    </AccordionItem>
  );
};
