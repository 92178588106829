import { Observer, observer } from "mobx-react-lite";
import { stringify } from "query-string";
import { useEffect } from "react";

import {
  DateRangeType,
  FontIcon,
  FontSizes,
  Heading,
  IColumn,
  IconButton,
  IContextualMenuItem,
  NoDataTile,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Shimmer,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  Tile,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { customFormatDuration, DateTime, TIME_FORMATS } from "@bps/utils";
import {
  CalendarEventStatus,
  DidNotArriveLabel,
  GetCalendarEventsDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { openNewInvoiceFromCalendar } from "@modules/booking/utils/booking.utils.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { AppointmentCancellationReason } from "@stores/booking/models/AppointmentCancellationReason.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { invoiceStatusIcon } from "../booking-calendar/components/booking-calendar-event/BookingCalendarEvent.types.ts";
import { renderCalendarEventWrapper } from "../booking-calendar/components/utils.tsx";
import {
  DidNotArriveDetailFilter,
  DidNotArriveListFilter
} from "./components/DidNotArriveDetailFilter.tsx";
import { DidNotArrive, getDidNotArriveListItems } from "./utils.ts";

interface DidNotArriveListProps {
  selectedUsers: string[];
  loading?: boolean;
  error?: Error;
  loadingMore?: boolean;
}

const didNotArriveListResults = maybePromiseObservable<DidNotArrive[]>();
const cancellationReasons =
  maybePromiseObservable<AppointmentCancellationReason[]>();

export const DidNotArriveListScreen: React.FC<DidNotArriveListProps> = observer(
  () => {
    const root = useStores();
    const { booking, practice, core, routing } = root;
    const {
      ui: { showEditContact, showContactDetails }
    } = practice;

    const { startDate } = useBookingCalendarScreenContext();
    const theme = useTheme();

    const defaultDidNotArriveListFilter: DidNotArriveListFilter = {
      dateRange: DateRangeType.Day.toString(),
      apptDate: startDate.startOf("day").toJSDate(),
      patientName: undefined,
      providers: [],
      locations: [],
      status: undefined
    };

    const loadDidNotArriveListItems = async (
      filterSearch: DidNotArriveListFilter
    ) => {
      const calendarEventsFilter: GetCalendarEventsDto = {
        startTime:
          filterSearch.dateRange === DateRangeType.Week.toString()
            ? DateTime.fromJSDate(filterSearch.apptDate).startOf("week").toISO()
            : DateTime.fromJSDate(filterSearch.apptDate).startOf("day").toISO(),
        endTime:
          filterSearch.dateRange === DateRangeType.Week.toString()
            ? DateTime.fromJSDate(filterSearch.apptDate)
                .startOf("week")
                .plus({ days: 7 })
                .toISO()
            : DateTime.fromJSDate(filterSearch.apptDate)
                .startOf("day")
                .plus({ days: 1 })
                .toISO(),
        statuses: [
          CalendarEventStatus.Cancelled,
          CalendarEventStatus.Confirmed
        ],
        name: filterSearch.patientName,
        attendees: filterSearch.providers,
        orgUnitIds: filterSearch.locations
      };

      const events = await booking.getCalendarEvents(calendarEventsFilter, {
        loadCalendarEventExtensions: true,
        loadCalendarEventUsers: true,
        loadCalendarEventContacts: true
      });

      const results = events.results;
      const statuses = filterSearch.status ?? [];
      const items = getDidNotArriveListItems(results, statuses, root);
      didNotArriveListResults.set(new Promise(resolve => resolve(items)));
    };

    const noResults = didNotArriveListResults.value?.length === 0;

    useEffect(() => {
      cancellationReasons.set(booking.getAppointmentCancellationReasons({}));

      loadDidNotArriveListItems(defaultDidNotArriveListFilter);
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderActions = (item: DidNotArrive) => {
      return (
        <Observer>
          {() => (
            <Stack horizontalAlign="center" verticalAlign="center">
              <IconButton
                iconProps={{ iconName: "More" }}
                onRenderMenuIcon={() => null}
                menuProps={{
                  items: actionItems(item)
                }}
                styles={{
                  root: { width: "32px", height: "36px", padding: 0 },
                  flexContainer: { width: "32px", height: "36px" }
                }}
              />
            </Stack>
          )}
        </Observer>
      );
    };

    const actionItems = (item: DidNotArrive): IContextualMenuItem[] => {
      const { patientId, calendarEvent } = item;
      const { invoiceId, invoiceStatus } = calendarEvent;
      const invoiceIconProp = invoiceStatus
        ? { iconName: invoiceStatusIcon[invoiceStatus] }
        : undefined;

      const items = [];
      if (core.hasPermissions(Permission.InvoiceCreate)) {
        items.push({
          key: DidNotArriveLabel.NewInvoice,
          text: invoiceId
            ? DidNotArriveLabel.Invoice
            : DidNotArriveLabel.NewInvoice,
          iconProps: invoiceIconProp,
          onClick: () => {
            if (!invoiceId)
              openNewInvoiceFromCalendar(root, calendarEvent.id, patientId);
            else {
              routing.push(
                routes.accounts.invoices.invoice.path({ id: invoiceId }),
                routing.getStateWithFromQuery()
              );
            }
          }
        });
      }
      if (core.hasPermissions(Permission.PaymentCreate)) {
        items.push({
          key: DidNotArriveLabel.NewPayment,
          text: DidNotArriveLabel.NewPayment,
          onClick: () => {
            routing.pushWithFromQuery({
              pathname: routes.accounts.allocations.new.pattern,
              search: stringify({ accountId: patientId })
            });
          }
        });
      }
      return items;
    };

    const handleNavigateClicked = (id: string) => {
      const hasPatientWritePermission = core.hasPermissions([
        Permission.PatientWrite
      ]);

      if (hasPatientWritePermission)
        showEditContact(PatientCardIds.patientHeader, id);
      else showContactDetails(id);
    };

    const renderPatient = (item: DidNotArrive) => {
      return item.patient ? (
        <Navigate
          onClick={() => handleNavigateClicked(item.patient?.id!)}
        >{`${item.patient.firstName} ${item.patient.lastName}`}</Navigate>
      ) : (
        <Shimmer />
      );
    };

    const renderScheduleDate = (item: DidNotArrive) => {
      return <Text>{item.startDateTime.toDayDefaultFormat()}</Text>;
    };

    const renderScheduleTime = (item: DidNotArrive) => {
      return (
        <Stack styles={{ root: { textAlign: "end" } }}>
          <Text>
            {item.startDateTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)}
          </Text>
        </Stack>
      );
    };

    const renderStatusBadge = (item: DidNotArrive) => {
      let badgeColor = TextBadgeColor.red;
      let badgeLabel = "DNA";
      if (item.cancelled) {
        badgeColor = TextBadgeColor.lightGrey;
        badgeLabel = "Cancelled";
      }
      return (
        <TextBadge
          badgeColor={badgeColor}
          badgeSize={TextBadgeSize.medium}
          hasBorder={true}
          styles={{ root: { width: "120px" } }}
        >
          {badgeLabel}
        </TextBadge>
      );
    };

    const renderRescheduled = (item: DidNotArrive) => {
      const ICON_NAME = "Completed";
      const LABEL = "Rescheduled";

      const patientName = item.patient?.fullName;
      const providerName = item.provider?.fullName;
      const date = item.startDateTime.toDayDefaultFormat();
      const tooltipContent = `Rescheduled to ${date}  with ${patientName} by ${providerName}`;
      const itemCanceled = item.cancelled;

      if (itemCanceled) {
        return null;
      }

      return (
        <TooltipHost content={tooltipContent}>
          <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
            <FontIcon
              iconName={ICON_NAME}
              styles={{
                root: {
                  color: theme.semanticColors.successIcon
                }
              }}
            />
            <span>{LABEL}</span>
          </Stack>
        </TooltipHost>
      );
    };

    const renderNoticeBy = (item: DidNotArrive) => {
      let noticeBy: string | undefined = "N/A";
      if (item.cancellationReasonId && item.cancellationDateTime) {
        const duration = item.startDateTime.diff(item.cancellationDateTime);
        if (duration.milliseconds >= 0) {
          noticeBy = `${customFormatDuration(
            item.startDateTime.diff(item.cancellationDateTime),
            true
          )}`;
        } else {
          noticeBy = ` + ${customFormatDuration(
            item.cancellationDateTime.diff(item.startDateTime),
            true
          )}`;
        }
      }
      return (
        <Stack styles={{ root: { textAlign: "end" } }}>
          <Text>{noticeBy}</Text>
        </Stack>
      );
    };

    const renderReason = (item: DidNotArrive) => {
      let reasonText: string | undefined = "N/A";
      if (item.cancellationReasonId) {
        const cancellationReason = cancellationReasons.value?.find(
          x => x.id === item.cancellationReasonId
        );

        if (cancellationReason) {
          if (cancellationReason.text === "Other")
            reasonText = item.cancellationText;
          else reasonText = cancellationReason.text;
        }
      }
      return <Text>{reasonText}</Text>;
    };

    const renderProvider = (item: DidNotArrive) => {
      return item.provider ? (
        <Text>{item.provider.fullName}</Text>
      ) : (
        <Shimmer />
      );
    };

    const renderLocation = (item: DidNotArrive) => {
      return item.location ? <Text>{item.location}</Text> : <Shimmer />;
    };

    const getColumns = () => {
      const columns: IColumn[] = [
        {
          key: DidNotArriveLabel.Date,
          minWidth: 79,
          maxWidth: 79,
          name: DidNotArriveLabel.Date,
          onRender: renderScheduleDate
        },
        {
          key: DidNotArriveLabel.Scheduled,
          minWidth: 74,
          maxWidth: 74,
          name: DidNotArriveLabel.Scheduled,
          styles: {
            root: { textAlign: "end" },
            cellTitle: { justifyContent: "flex-end" }
          },
          onRender: renderScheduleTime
        },
        {
          key: DidNotArriveLabel.Patient,
          minWidth: 160,
          maxWidth: 160,
          name: DidNotArriveLabel.Patient,
          onRender: renderPatient
        },
        {
          key: DidNotArriveLabel.Status,
          minWidth: 121,
          maxWidth: 121,
          className: RESET_CELLS_PADDING_CLASSNAME,
          name: DidNotArriveLabel.Status,
          onRender: renderStatusBadge
        },
        {
          key: DidNotArriveLabel.Rescheduled,
          minWidth: 125,
          maxWidth: 125,
          className: RESET_CELLS_PADDING_CLASSNAME,
          name: "",
          onRender: renderRescheduled
        },
        {
          key: DidNotArriveLabel.NoticeBy,
          minWidth: 94,
          maxWidth: 155,
          name: DidNotArriveLabel.NoticeBy,
          styles: {
            root: { textAlign: "end" },
            cellTitle: { justifyContent: "flex-end" }
          },
          onRender: renderNoticeBy
        },
        {
          key: DidNotArriveLabel.Provider,
          minWidth: 160,
          maxWidth: 200,
          name: DidNotArriveLabel.Provider,
          onRender: renderProvider
        }
      ];

      if (core.hasMultipleActiveLocations) {
        columns.push({
          key: DidNotArriveLabel.Location,
          minWidth: 160,
          maxWidth: 200,
          name: DidNotArriveLabel.Location,
          onRender: renderLocation
        });
      }

      columns.push({
        key: DidNotArriveLabel.Reason,
        minWidth: 150,
        maxWidth: 583,
        name: DidNotArriveLabel.Reason,
        onRender: renderReason
      });

      if (
        core.hasPermissions(
          [Permission.PaymentCreate, Permission.InvoiceCreate],
          "or"
        )
      ) {
        return [
          {
            key: DidNotArriveLabel.Actions,
            className: RESET_CELLS_PADDING_CLASSNAME,
            minWidth: 56,
            maxWidth: 56,
            name: DidNotArriveLabel.Actions,
            onRender: renderActions
          },
          ...columns
        ];
      }
      return columns;
    };

    const content = (
      <Stack verticalFill tokens={{ childrenGap: 24 }}>
        <Heading variant="section-heading-light">Incomplete</Heading>
        <DidNotArriveDetailFilter onSearch={loadDidNotArriveListItems}>
          {(state, actions) => (
            <>
              {!noResults && (
                <Stack
                  grow
                  styles={{
                    root: {
                      position: "relative",
                      height: "100%"
                    }
                  }}
                >
                  <ScrollablePane styles={{ root: { height: "100%" } }}>
                    <ShimmeredDetailsList
                      setKey="didNotArriveList"
                      enableShimmer={
                        didNotArriveListResults.pending ||
                        cancellationReasons.pending
                      }
                      errorMessage={
                        didNotArriveListResults.error?.message ||
                        cancellationReasons.error?.message
                      }
                      onRenderRow={props => renderCalendarEventWrapper(props)}
                      items={didNotArriveListResults.value ?? []}
                      columns={getColumns()}
                    />
                  </ScrollablePane>
                </Stack>
              )}
              {noResults && state.dirty && (
                <NoDataTile
                  textProps={{ text: "0 matches found" }}
                  linkProps={{
                    text: "Clear filters",
                    onClick: () => actions.reset()
                  }}
                  styles={{
                    root: {
                      padding: 8,
                      height: "100%",
                      fontSize: FontSizes.size14,
                      fontStyle: "italic"
                    }
                  }}
                  showBoxShadow={false}
                />
              )}
              {noResults && !state.dirty && (
                <NoDataTile
                  styles={{
                    root: {
                      height: "100%"
                    }
                  }}
                  textProps={{ text: "No entries for incomplete appointments" }}
                  linkProps={{
                    hidden: true
                  }}
                  showBoxShadow={false}
                  greyView={true}
                />
              )}
            </>
          )}
        </DidNotArriveDetailFilter>
      </Stack>
    );

    return <Tile styles={{ root: { flexGrow: 1 } }}>{content}</Tile>;
  }
);
