import { memo } from "react";

import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";

import { AppointmentReminderFormSection } from "./AppointmentReminderFormSection.tsx";
import { MessageContentFormSection } from "./MessageContentFormSection.tsx";
import { NotifyFormSection } from "./NotifyFormSection.tsx";
import { TypeOfAppointmentsFormSection } from "./TypeOfAppointmentsFormSection.tsx";

interface CommsScheduleEditFormBodyProps {
  appointmentTypes: AppointmentType[];
  templates: OutboundCommTemplate[];
}

export const CommsScheduleEditFormFields: React.FC<CommsScheduleEditFormBodyProps> =
  memo((props: CommsScheduleEditFormBodyProps) => {
    return (
      <>
        <AppointmentReminderFormSection />
        <NotifyFormSection />
        <TypeOfAppointmentsFormSection
          appointmentTypes={props.appointmentTypes}
        />
        <MessageContentFormSection templates={props.templates} />
      </>
    );
  });
