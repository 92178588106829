import React from "react";

import { Stack, TextField } from "@bps/fluent-ui";
import {
  Relationship,
  SignificantFamilyHistoryDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { ConditionAndCommentGroupField } from "./ConditionAndCommentGroupField.tsx";
import {
  familyHistoryNameOf,
  significantFamilyHistoryNameOf
} from "./FamilyHistoryForm.types.ts";

interface AdditionalFamilyHistoryFieldProps {
  additionalFamilyHistoryIndex: number;
  additionalFamilyHistoriesValues: SignificantFamilyHistoryDataItemDto[];
}

export const AdditionalFamilyHistoryField: React.FC<
  AdditionalFamilyHistoryFieldProps
> = ({ additionalFamilyHistoryIndex, additionalFamilyHistoriesValues }) => {
  const { clinical } = useStores();
  const additionalFamilyHistoryName = `${familyHistoryNameOf(
    "additionalFamilyHistories"
  )}[${additionalFamilyHistoryIndex}]`;

  const relationshipFieldName = `${additionalFamilyHistoryName}.${significantFamilyHistoryNameOf(
    "relationship"
  )}`;

  const additionalConditionsName = `${additionalFamilyHistoryName}.${significantFamilyHistoryNameOf(
    "conditions"
  )}`;

  const getRelationshipNumber = (
    relationship: string,
    parentHistories: SignificantFamilyHistoryDataItemDto[],
    currentIndex: number
  ) => {
    const prevRelationships = parentHistories.slice(0, currentIndex);
    const numberOfSameRelationship = prevRelationships.filter(
      x => x.relationship === relationship
    ).length;

    if (numberOfSameRelationship === undefined || relationship === "") {
      return undefined;
    } else if (numberOfSameRelationship === 0) {
      return 1;
    } else {
      return 1 + numberOfSameRelationship;
    }
  };

  const interactingField =
    additionalFamilyHistoriesValues[additionalFamilyHistoryIndex];

  const number = getRelationshipNumber(
    interactingField.relationship,
    additionalFamilyHistoriesValues,
    additionalFamilyHistoryIndex
  );

  const relationshipOptions = clinical.ref.relationships.keyTextValues.filter(
    x => x.key !== Relationship.Mother && x.key !== Relationship.Father
  );

  return (
    <Fieldset frame styles={{ root: { width: "100%" } }}>
      <Stack tokens={{ childrenGap: 8 }} horizontal>
        <DropdownField
          name={relationshipFieldName}
          options={relationshipOptions}
          required
          label="Relationship"
          styles={{
            root: {
              width: 365
            }
          }}
        />
        <TextField
          disabled={true}
          label="Number"
          styles={{
            root: {
              maxWidth: 60
            }
          }}
          placeholder={number ? number.toString() : ""}
        />
      </Stack>
      <Stack>
        <ConditionAndCommentGroupField
          conditionsName={additionalConditionsName}
        />
      </Stack>
    </Fieldset>
  );
};
