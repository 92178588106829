import { observer } from "mobx-react-lite";

import { Heading, IDialogProps } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  AddOrganisationContactDto,
  AddressType,
  CommunicationDto,
  CommunicationType,
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DialogWithSubmitButtonsProps } from "@ui-components/form/submission-form-dialog/DialogWithSubmitButtons.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { Labels } from "../types/labels.enums.types.ts";
import { toAddressDto } from "../utils/contact.utils.ts";
import { QuickAddContactFormFields } from "./QuickAddContactFormFields.tsx";
import { QuickAddContactFormValidator } from "./QuickAddContactFormValidator.tsx";
import { QuickAddContactFormValues } from "./QuickAddContactFormValues.tsx";

type QuickAddContactDialogProps = Pick<IDialogProps, "hidden"> &
  Pick<DialogWithSubmitButtonsProps["dialogProps"], "onDismiss"> & {
    type: ContactType;
    initialValues?: QuickAddContactFormValues;
    onContactAdded: (contact: Contact) => void;
    detailedView?: boolean;
    title?: string;
  };

export const QuickAddContactDialog: React.FunctionComponent<QuickAddContactDialogProps> =
  observer(
    ({
      type,
      initialValues: defaultValues,
      onContactAdded,
      hidden,
      onDismiss,
      detailedView,
      title
    }) => {
      const { core, userExperience, practice } = useStores();

      const getInitialValues = () => {
        if (
          detailedView &&
          type === ContactType.Individual &&
          core.tenantDetails?.country
        ) {
          return {
            ...defaultValues,
            addresses: [
              {
                street1: undefined,
                country: core.tenantDetails?.country,
                type: AddressType.Physical,
                isNew: true,
                id: newGuid()
              }
            ]
          };
        }
        return defaultValues;
      };

      let newContact: Contact;

      const { localisedConfig } = userExperience;

      if (hidden) return null;

      const patientLabel = localisedConfig("patientDisplay");
      const validator = new QuickAddContactFormValidator(
        {
          contactType: type
        },
        core.ref.countries.values,
        core.tenantDetails?.country!
      );

      const getTitle = () => {
        if (title) {
          return title;
        } else {
          switch (type) {
            case ContactType.Individual:
              return Labels.newIndividual;
            case ContactType.Patient:
              return `New ${patientLabel}`;
            case ContactType.Organisation:
              return Labels.newOrganisation;
          }
        }
      };

      const addContactByType = async (values: QuickAddContactFormValues) => {
        const { mobile, firstName, lastName, email, addresses, hasPostal } =
          values;

        const communications: CommunicationDto[] = [];

        if (mobile) {
          communications.push({
            type: CommunicationType.Mobile,
            preferred: true,
            value: mobile
          });
        }

        if (email) {
          communications.push({
            type: CommunicationType.Email,
            value: email
          });
        }

        let filteredAddress = addresses;

        if (!hasPostal && addresses) {
          filteredAddress = addresses?.filter(
            a => a.type !== AddressType.Postal
          );
        }

        const data = {
          fullName: { firstName, lastName },
          addresses: toAddressDto(filteredAddress),
          communications
        };

        switch (type) {
          case ContactType.Patient:
            return await practice.addPatient(data);
          case ContactType.Individual:
            return await practice.addIndividual({
              ...data,
              isHealthProvider: false
            });
          case ContactType.Organisation:
            const orgData: AddOrganisationContactDto = {
              organisationName: values.organisationName,
              organisationCategories: values.organisationCategories,
              communications: values.phone
                ? [
                    {
                      type: CommunicationType.WorkPhone,
                      preferred: true,
                      value: values.phone
                    }
                  ]
                : [],
              contactStatus: ContactStatus.Active,
              draftItemsEnabled: false,
              isHealthProvider: false
            };

            if (
              values.suburb ||
              values.state ||
              values.city ||
              values.postCode
            ) {
              orgData.addresses = [
                {
                  type: AddressType.Physical,
                  suburb: values.suburb,
                  state: values.state,
                  postCode: values.postCode,
                  city: values.city,
                  country: core.tenantDetails?.country
                }
              ];
            }
            return await practice.addOrganisation(orgData);
        }
      };

      return (
        <SubmissionFormDialog<QuickAddContactFormValues>
          dialogName="Quick add contact dialog"
          initialValues={getInitialValues()}
          validate={values => validator.validate(values)}
          onSubmit={async (values: QuickAddContactFormValues) => {
            newContact = await addContactByType(values);
          }}
          buttonsProps={{
            hideButtonsSeparator: true,
            disableSubmitOnPristine:
              !defaultValues?.firstName?.trim() &&
              !defaultValues?.lastName?.trim()
          }}
          onSubmitSucceeded={() => {
            if (!newContact) {
              return;
            }
            onContactAdded(newContact);
          }}
          subscription={{ values: true }}
          dialogProps={{
            maxWidth: 480,
            minWidth: 480,
            onDismiss,
            modalProps: {
              containerClassName: "quick-add-modal-container",
              layerProps: { eventBubblingEnabled: true },
              className: "quick-add-modal"
            },
            dialogContentProps: {
              title: <Heading variant="modal-heading">{getTitle()}</Heading>,
              showCloseButton: true
            }
          }}
        >
          {() => (
            <QuickAddContactFormFields
              contactType={type}
              detailedView={detailedView}
              country={core.tenantDetails?.country!}
            />
          )}
        </SubmissionFormDialog>
      );
    }
  );
