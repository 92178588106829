import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { HealthCertificateValues } from "./HealthCertificate.types.tsx";

export class HealthCertificateValidator extends Validator<HealthCertificateValues> {
  constructor() {
    super();
    this.forField("certificateFile", [required()]);
    this.forField("certificatePassword", [required()]);
    this.forField("certificateName", [required()]);
  }
}
