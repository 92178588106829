import { observer } from "mobx-react-lite";

import {
  BadgeSize,
  DirectionalHint,
  FontIcon,
  FontWeights,
  HoverCard,
  HoverCardType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { LinkButton } from "@ui-components/navigation/LinkButton.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { ReactionBadge } from "./ReactionBadge.tsx";
import { AgentReaction } from "./ReactionForm.types.ts";
import { ReactionListItem } from "./ReactionListItem.tsx";
import {
  fetchReactionsDetails,
  getReactionAgent,
  sortReactions
} from "./utils.ts";

interface ReactionTableProps {
  onAddReaction: () => void;
}
export const ReactionTable: React.FC<ReactionTableProps> = observer(
  ({ onAddReaction }) => {
    const { clinicalRecord, isViewOnly } = usePatientRecordScreenContext();
    const theme = useTheme();
    const reactionData = clinicalRecord.clinicalData?.reaction;
    const reactionsCheckedData =
      clinicalRecord.clinicalData?.reactionsChecked?.reactionsCheckedAndUpdated;

    const listBadgeSize = BadgeSize.Small;

    const renderData = () => {
      if (reactionData?.nilKnown && !reactionData?.reactions?.length) {
        return renderNilKnown();
      }
      if (reactionData?.reactions?.length) {
        return renderList();
      }

      return renderNotRecorded();
    };

    const { createdById: updatedById, createdDateTime: updatedDate } =
      reactionsCheckedData?.createLog || {};

    const renderNilKnown = () => {
      return (
        <ReactionBadge
          size={listBadgeSize}
          styles={{
            root: {
              backgroundColor: theme.semanticColors.successBackground,
              display: "flex",
              flex: 1
            },
            content: {
              flex: 1
            }
          }}
        >
          <HoverCard
            type={HoverCardType.plain}
            plainCardProps={{
              directionalHint: DirectionalHint.rightCenter,
              directionalHintFixed: true,
              calloutProps: {
                isBeakVisible: true,
                gapSpace: 5
              },
              onRenderPlainCard: () => (
                <Stack
                  tokens={{ childrenGap: 8 }}
                  styles={{
                    root: {
                      padding: 16
                    }
                  }}
                >
                  <Text
                    block
                    variant="mediumPlus"
                    styles={{
                      root: { fontWeight: FontWeights.bold }
                    }}
                  >
                    Nil known
                  </Text>

                  <RecordUpdateCheckedLog
                    fontSize="tiny"
                    createdBy={reactionData?.createLog?.createdById}
                    createdDate={reactionData?.createLog?.createdDateTime}
                    updatedBy={updatedById}
                    updatedDate={updatedDate}
                  />
                </Stack>
              )
            }}
          >
            <Stack
              verticalAlign="center"
              horizontalAlign="center"
              styles={{
                root: {
                  fontWeight: FontWeights.semibold,
                  color: theme.palette.green
                }
              }}
            >
              Nil known
            </Stack>
          </HoverCard>
        </ReactionBadge>
      );
    };

    const renderNotRecorded = () => {
      return (
        <Stack
          grow
          horizontalAlign="center"
          verticalAlign="center"
          horizontal
          styles={{
            root: {
              background: theme.palette.neutralLighterAlt,
              justifyItems: "center",
              display: "flex"
            }
          }}
        >
          <FontIcon
            iconName="AlertSolid"
            styles={{
              root: {
                color: theme.semanticColors.errorText,
                padding: 6
              }
            }}
          />
          <Text
            styles={{
              root: {
                color: theme.semanticColors.errorText,
                fontSize: 12
              }
            }}
          >
            No reactions recorded.
          </Text>
          {!isViewOnly && (
            <LinkButton
              styles={{
                root: {
                  color: theme.palette.themePrimary,
                  fontSize: 12,
                  padding: 0,
                  justifyContent: "center"
                }
              }}
              onClick={onAddReaction}
            >
              Record
            </LinkButton>
          )}
        </Stack>
      );
    };

    const renderList = () => {
      const dtos = reactionData?.reactions;
      const key = JSON.stringify(dtos);

      return (
        <DataFetcher<AgentReaction[]>
          refetchId={key}
          fetch={({ drugs }) => fetchReactionsDetails(dtos, { drugs })}
        >
          {reactions => (
            <Stack grow tokens={{ childrenGap: 4 }}>
              {sortReactions(reactions).map(reaction => (
                <ReactionListItem
                  key={reaction.id}
                  natureOfReaction={reaction.natureOfReaction}
                  size={listBadgeSize}
                  reaction={reaction}
                  agent={getReactionAgent(
                    reaction.agent.code,
                    reactionData?.agents
                  )}
                />
              ))}
            </Stack>
          )}
        </DataFetcher>
      );
    };

    return <>{renderData()}</>;
  }
);
