import { useEffect, useRef } from "react";

import { DateTime } from "@bps/utils";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

const ONE_MINUTE = 60000;

export const useBookingFilterTrackEvent = () => {
  const { calendarView, resourceIds } = useBookingCalendarScreenContext();
  const {
    core: { userId, user }
  } = useStores();

  const trackEvent = useAppTrackEvent(
    AppInsightsEventNames.bookingFilterToggling
  );

  const timer = useRef<NodeJS.Timeout | undefined>(undefined);

  const previousCalendarView = useRef(calendarView);

  let scheduleForView: string | undefined;

  if (resourceIds.length === 1) {
    if (resourceIds[0] === userId) {
      scheduleForView = "own";
    } else {
      scheduleForView = "single";
    }
  } else if (resourceIds.length > 1) {
    scheduleForView = "mutliple";
  } else {
    scheduleForView = undefined;
  }

  const previousScheduleForView = useRef(scheduleForView);

  useEffect(
    () => {
      const calendarViewInitialised =
        !previousCalendarView.current && calendarView;

      const scheduleForViewInitialised =
        !previousScheduleForView.current && scheduleForView;

      const calendarViewValueChanged =
        previousCalendarView.current &&
        previousCalendarView.current !== calendarView;

      const scheduleForViewChanged =
        previousScheduleForView.current &&
        previousScheduleForView.current !== scheduleForView;

      const handleInterval = () => {
        trackEvent({
          calendarView,
          scheduleForView,
          dateTime: DateTime.now().toISODate()
        });
        timer.current && clearInterval(timer.current);
      };

      if (calendarViewInitialised) {
        previousCalendarView.current = calendarView;
      }

      if (scheduleForViewInitialised) {
        previousScheduleForView.current = calendarView;
      }

      if (
        scheduleForView &&
        calendarView &&
        (calendarViewValueChanged || scheduleForViewChanged)
      ) {
        previousCalendarView.current = calendarView;
        previousScheduleForView.current = scheduleForView;
        timer.current = setInterval(handleInterval, ONE_MINUTE);
      }

      return () => {
        timer.current && clearInterval(timer.current);
      };
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [calendarView, scheduleForView, user]
  );
};
