import { stringify } from "query-string";

import { dataAttribute, DataAttributes, Stack, Text } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import { isInvoice } from "@stores/billing/utils/transaction.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { refTextFor } from "@ui-components/RefText.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { InvoiceStatusTooltip } from "../invoice/components/InvoiceStatusTooltip.tsx";

export interface TransactionLinkCellProps {
  transaction: TransactionBase;
}

export const TransactionLinkCell: React.FC<TransactionLinkCellProps> = ({
  transaction
}) => {
  const { routing } = useStores();

  const accountId = routing.match(routes.accounts.account)?.params.id;

  const innerText = (
    <>
      {refTextFor(x => x.billing.ref[transaction.itemTypeText.key])({
        code: transaction.itemTypeText.code
      })}{" "}
      {transaction.number}
    </>
  );

  const text = <Text>{innerText}</Text>;

  let content = text;
  if (transaction.viewLink) {
    content = (
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
        <Navigate
          to={{
            pathname: transaction.viewLink.path({ id: transaction.id }),
            search: accountId ? stringify({ accountId }) : undefined
          }}
          state={{
            ...routing.getStateWithFromQuery(),
            accountsFilter: routing.location.search
          }}
          styles={{ root: { alignSelf: "center" } }}
          {...dataAttribute(
            DataAttributes.Element,
            `${transaction.itemType}-link`
          )}
          {...dataAttribute(DataAttributes.Id, transaction.id)}
          {...dataAttribute(DataAttributes.Data, transaction.number)}
        >
          {innerText}
        </Navigate>

        {isInvoice(transaction) && (
          <InvoiceStatusTooltip invoice={transaction} />
        )}
      </Stack>
    );
  }

  return (
    <When permission={transaction.viewPermission} else={text}>
      {content}
    </When>
  );
};
