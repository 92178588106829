import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef, useState } from "react";

import {
  ButtonsGroupChoice,
  DatesRangeCallout,
  DefaultButton,
  StackItem,
  StandardRangesOptionsKeys
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DateType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useEventReminderScreenContext } from "@modules/booking/screens/event-reminders/context/EventReminderScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

export const EventReminderDatesRangePicker: FunctionComponent = observer(() => {
  const {
    getDatesRangePickerFieldText,
    handleFilterDateChange,
    eventReminderSearchResults,
    today
  } = useEventReminderScreenContext();

  const { routing } = useStores();

  const notificationContext = routing.getRouteState();

  const createdDate = notificationContext?.CreatedDate;

  const startDateTime = createdDate
    ? DateTime.fromISO(createdDate)
    : DateTime.fromJSDate(today);

  const defaultStartDate = startDateTime.startOf("day").toJSDate();

  const defaultEndDate = DateTime.fromJSDate(defaultStartDate)
    .endOf("day")
    .toJSDate();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [hiddenCalendar, setHiddenCalendar] = useState<boolean>(true);
  const [dateType, setDateType] = useState<DateType>(
    createdDate ? DateType.Sent : DateType.CalendarEvent
  );

  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultStartDate
  );

  const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate);

  useEffect(() => {
    handleFilterDateChange({ startDate, endDate, dateType });
  }, [dateType, startDate, endDate, handleFilterDateChange]);

  const renderSelection = () => (
    <ButtonsGroupChoice
      value={dateType}
      onChange={setDateType}
      options={[
        { key: DateType.CalendarEvent, text: "Appointment date" },
        { key: DateType.Sent, text: "Reminder sent" },
        { key: DateType.Reply, text: "Reply received" }
      ]}
    />
  );

  const toggleHideCalendar = () => setHiddenCalendar(preState => !preState);

  return (
    <>
      <DefaultButton
        componentRef={ref => {
          if (!buttonRef.current && ref && ref["_buttonElement"]) {
            buttonRef.current = ref["_buttonElement"];
          }
        }}
        iconProps={{
          iconName: "Search"
        }}
        checked={true}
        disabled={eventReminderSearchResults.pending}
        onClick={toggleHideCalendar}
        text={getDatesRangePickerFieldText({ startDate, endDate, dateType })}
        styles={{
          root: {
            height: 36,
            width: 230
          }
        }}
      />
      <StackItem>
        <DatesRangeCallout
          target={buttonRef.current}
          hiddenCalendar={hiddenCalendar}
          toggleHideCalendar={toggleHideCalendar}
          renderSelection={renderSelection}
          startDateProps={{
            name: "startDate",
            value: startDate,
            onChange: setStartDate
          }}
          endDateProps={{
            name: "endDate",
            value: endDate,
            onChange: setEndDate
          }}
          standardRanges={[
            StandardRangesOptionsKeys.today,
            StandardRangesOptionsKeys.yesterday,
            StandardRangesOptionsKeys.tomorrow,
            StandardRangesOptionsKeys.thisWeek,
            StandardRangesOptionsKeys.lastWeek
          ]}
        />
      </StackItem>
    </>
  );
});
