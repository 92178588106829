import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { ClinicalTasksUnlockArgs } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalTaskButtonLabels } from "@modules/clinical/screens/patient-record/components/clinical-task/types/clinical-task-button-labels.enum.ts";
import { findRelatedClinicalActivity } from "@modules/clinical/screens/patient-record/components/clinical-task/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";

export const TaskClinicalButtons = observer(() => {
  const {
    selectedClinicalTasks,
    setTaskDialogVisible,
    taskDialogVisible,
    setSelectedClinicalTasks
  } = useContext(InboxScreenContext);

  const { clinical, core } = useStores();

  const isListNonEmpty =
    selectedClinicalTasks && selectedClinicalTasks.length > 0;

  const isEveryTaskCompleted = isListNonEmpty
    ? selectedClinicalTasks.every(x => x.isCompleted)
    : true;

  const isEveryTaskUnlocked = isListNonEmpty
    ? selectedClinicalTasks.every(x => x && !x.isLocked)
    : true;

  const isMoreThanOneTaskSelected = isListNonEmpty
    ? selectedClinicalTasks.length !== 1
    : true;

  const unlockTasks = async () => {
    const args: ClinicalTasksUnlockArgs = {
      taskIds: selectedClinicalTasks.map(task => task.id)
    };

    if (
      core.hasPermissions([
        Permission.ClinActivityRead,
        Permission.ClinActivityUnlock
      ])
    ) {
      selectedClinicalTasks.forEach(async task => {
        const activities = await clinical.getPatientClinicalActivities(
          task.patientId
        );

        if (activities) {
          const related = findRelatedClinicalActivity(
            task,
            clinical.activityDescriptionMapValues,
            activities
          );
          if (related) {
            await clinical.unlockClinicalActivities({
              activityIds: [related.id]
            });
          }
        }
      });
    }

    await clinical.unlockClinicalTasks(args);
    setSelectedClinicalTasks([]);
  };

  return (
    <>
      <When permission={Permission.ClinTaskUnlock}>
        <Stack.Item>
          <DefaultButton
            disabled={isEveryTaskUnlocked}
            onClick={async () => {
              await unlockTasks();
            }}
            text={ClinicalTaskButtonLabels.Unlock}
          />
        </Stack.Item>
      </When>
      <When permission={Permission.ClinTaskWrite}>
        <Stack.Item>
          <DefaultButton
            disabled={
              isEveryTaskCompleted || clinical.ui.clinicalTaskActionDisabled
            }
            onClick={() =>
              setTaskDialogVisible({
                ...taskDialogVisible,
                complete: true
              })
            }
            text={ClinicalTaskButtonLabels.Completed}
          />
        </Stack.Item>
      </When>
      <When permission={Permission.ClinTaskWrite}>
        <Stack.Item>
          <DefaultButton
            disabled={
              isMoreThanOneTaskSelected ||
              selectedClinicalTasks[0].isCompleted ||
              clinical.ui.clinicalTaskActionDisabled
            }
            onClick={() =>
              setTaskDialogVisible({
                ...taskDialogVisible,
                edit: true
              })
            }
            text={ClinicalTaskButtonLabels.Edit}
          />
        </Stack.Item>
      </When>
      <When permission={Permission.ClinTaskDelete}>
        <Stack.Item>
          <DefaultButton
            disabled={
              isEveryTaskCompleted || clinical.ui.clinicalTaskActionDisabled
            }
            onClick={() =>
              setTaskDialogVisible({
                ...taskDialogVisible,
                delete: true
              })
            }
            text={ClinicalTaskButtonLabels.Delete}
          />
        </Stack.Item>
      </When>
    </>
  );
});
