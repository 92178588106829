import { MutableRefObject, PropsWithChildren, ReactNode } from "react";

import { Stack, Tile, TopBarWrapper } from "@bps/fluent-ui";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

import { MedicationFormResponsiveHeader } from "./MedicationFormResponsiveHeader.tsx";

export interface MedicationHeaderWrapperProps
  extends PropsWithChildren<{
    heading?: string;
    noGap?: boolean;
    pivotTab?: ReactNode;
    onClose: () => void;
  }> {
  selection: MutableRefObject<
    Selection<
      MedicationClinicalDataItemDto & {
        key: string;
      }
    >
  >;
}

export const MedicationFormHeaderWrapper: React.FC<
  MedicationHeaderWrapperProps
> = props => {
  return (
    <TopBarWrapper
      styles={{
        root: {
          gap: props.noGap ? 0 : 16,
          margin: -16
        },
        content: {
          display: "flex"
        },
        subComponentStyles: {
          topBarStyles: {
            root: {
              height: "auto",
              padding: 0
            }
          }
        }
      }}
      header={
        <MedicationFormResponsiveHeader
          heading={props.heading}
          pivotTab={props.pivotTab}
          onClose={props.onClose}
          selection={props.selection}
          hasSeparator
        />
      }
      hasShadow={false}
    >
      <Tile
        styles={{
          root: {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            padding: 0
          }
        }}
      >
        <Stack
          styles={{
            root: {
              width: "100%",
              height: "100%",
              padding: 16
            }
          }}
        >
          {props.children}
        </Stack>
      </Tile>
    </TopBarWrapper>
  );
};
