import { useForm } from "react-final-form";

import { Card, Stack } from "@bps/fluent-ui";
import { getProviderDeclarationViewValues } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  LabeledTextFields,
  ProviderDeclarationViewValues
} from "../types/claim.types.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";
import { ViewField } from "./ViewField.tsx";

export const ProviderDeclarationViewSectionBase: React.FC<{
  providerDeclarationValues: ProviderDeclarationViewValues;
}> = ({ providerDeclarationValues }) => {
  const fields: LabeledTextFields[] = [
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.provider,
          styles: { text: { whiteSpace: "initial" } },
          children: providerDeclarationValues.providerName
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.providerType,
          children: providerDeclarationValues.providerTypeText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.providerDeclaration,
          children: providerDeclarationValues.providerDeclarationDateFormatted
        }
      ]
    },
    {
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.patientConsentForTelehealth,
          children: providerDeclarationValues.patientConsentForTelehealth
        }
      ]
    }
  ];

  return (
    <Card
      heading={ClaimSectionHeadings.providerDeclaration}
      headingLevel="section-heading"
      iconName="PenWorkspace"
      id={ClaimsCardIds.providerDeclaration}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        {fields.map(x => (
          <ViewField key={x.labeledTexts[0].label?.toString()} {...x} />
        ))}
      </Stack>
    </Card>
  );
};

export const ProviderDeclarationViewSectionDataFetcher = () => {
  const values = useForm<ClaimFormValues>().getState().values;

  return (
    <DataFetcher
      fetch={root => getProviderDeclarationViewValues({ values, root })}
    >
      {(providerDeclaration: ProviderDeclarationViewValues) => (
        <ProviderDeclarationViewSectionBase
          providerDeclarationValues={providerDeclaration}
        />
      )}
    </DataFetcher>
  );
};

export const ProviderDeclarationViewSection = withFetch(
  x => [x.practice.ref.accProviderTypes.load()],
  ProviderDeclarationViewSectionDataFetcher
);
