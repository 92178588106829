import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";

import {
  Heading,
  IconButton,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PatchOrgUnitAvailabilityDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { OrgUnitAvailability } from "@stores/booking/models/OrgUnitAvailability.ts";
import { TimeRanges } from "@stores/booking/models/TimeRanges.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";

import { WorkingHoursFormFallback } from "../shared-components/WorkingHoursFormFallback.tsx";
import {
  ClosedChoice,
  EditPracticeDayWorkingHoursFormValues,
  nameOf
} from "./EditPracticeDayWorkingHoursForm.types.ts";
import { getReason, openingHoursInfoMessage } from "./utils.ts";
import { EditPracticeDayWorkingHoursFormValidator } from "./validators/EditPracticeDayWorkingHoursValidator.tsx";

type EditPracticeDayWorkingHoursProps = {
  initialDate: DateTime;
  onDismiss: () => void;
};

const validator = new EditPracticeDayWorkingHoursFormValidator();

type EditPracticeDayWorkingHoursData = {
  availability: OrgUnitAvailability;
  openingHours: TimeRanges;
};

const SUGGESTION_INTERVAL = 30;

const EditPracticeDayWorkingHoursDialogBase: FunctionComponent<EditPracticeDayWorkingHoursProps> =
  observer(({ initialDate, onDismiss }) => {
    const { core, booking } = useStores();
    const handleSubmitForm = async (
      values: EditPracticeDayWorkingHoursFormValues
    ) => {
      if (!core.locationId || !values.date) {
        return;
      }

      const orgUnitAvailability = await booking.getOrgUnitAvailability(
        core.locationId
      );

      const isClosed = values.closedChoice === ClosedChoice.closed;

      const date = DateTime.fromJSDate(values.date);

      // try to match an existing override before attempting to append a new one
      const existingOverride = orgUnitAvailability.openingHoursOverrides?.find(
        x =>
          DateTime.fromISO(x.startDate)
            .startOf("day")
            .equals(date.startOf("day")) &&
          DateTime.fromISO(x.endDate).startOf("day").equals(date.startOf("day"))
      );

      const orgUnitOpeningHoursOverrides: PatchOrgUnitAvailabilityDto["orgUnitOpeningHoursOverrides"] =
        [
          ...orgUnitAvailability.openingHoursOverrides.filter(
            x => !existingOverride || x.id !== existingOverride.id
          ),
          {
            ...existingOverride,
            orgUnitId: core.locationId,
            startDate: date.toISODate(),
            endDate: date.toISODate(),
            startTime: !isClosed ? values.startTime : undefined,
            endTime: !isClosed ? values.endTime : undefined,
            reason: values.reason,
            isClosed
          }
        ];

      await booking.updateOrgUnitAvailability({
        orgUnitId: core.locationId,
        orgUnitOpeningHoursOverrides,
        // pass in orgUnitOpeningHours because the server fails with 500 otherwise
        // TODO: remove line below when API properly supports patch behaviour
        orgUnitOpeningHours: orgUnitAvailability.openingHours
      });
    };

    const fetch = async ({ core, booking }: RootStore) => {
      const availability = await booking.getOrgUnitAvailability(
        core.locationId,
        { ignoreCache: true }
      );

      const openingHours = await booking.getOrgUnitWorkingHours({
        from: initialDate,
        to: initialDate.plus({ days: 1 }),
        orgUnitId: core.locationId
      });
      return {
        openingHours,
        availability
      };
    };

    const getInitialValues = (
      data: EditPracticeDayWorkingHoursData
    ): EditPracticeDayWorkingHoursFormValues => {
      const { startTime: workingHoursStart, endTime: workingHoursEnd } =
        data.openingHours.startEndTime(initialDate);

      const isClosed = !workingHoursStart && !workingHoursEnd;
      const startTime = workingHoursStart?.toTimeInputFormat();

      const endTime = workingHoursEnd?.toTimeInputFormat();

      const reason = getReason(data.availability, initialDate);

      return {
        date: initialDate.toJSDate(),
        closedChoice: isClosed ? ClosedChoice.closed : ClosedChoice.open,
        startTime,
        endTime,
        reason
      };
    };
    return (
      <DataFetcher<EditPracticeDayWorkingHoursData>
        refetchId={`${initialDate.toISO()}${core.locationId}`}
        fetch={fetch}
        noExceptionsHandlers
      >
        {(data, loading, error) => {
          return (
            <SubmissionFormDialog<EditPracticeDayWorkingHoursFormValues>
              dialogName="Practice working hours dialog"
              loadingData={loading}
              Fallback={WorkingHoursFormFallback}
              dataLoadingError={error?.message}
              dialogProps={{
                minWidth: 600,
                onDismiss,
                dialogContentProps: {
                  title: (
                    <Heading variant="modal-heading">
                      {`${core.location?.name} opening hours`}
                    </Heading>
                  )
                }
              }}
              initialValues={data ? getInitialValues(data) : undefined}
              onSubmit={handleSubmitForm}
              onSubmitSucceeded={onDismiss}
              validate={values => validator.validate(values)}
            >
              {() => (
                <Fieldset>
                  <DatePickerField
                    label="Date"
                    name={nameOf("date")}
                    minDate={DateTime.jsDateNow()}
                  />

                  {data && (
                    <MessageBar messageBarType={MessageBarType.info}>
                      {openingHoursInfoMessage(initialDate, data?.availability)}
                    </MessageBar>
                  )}

                  <ChoiceGroupField
                    name={nameOf("closedChoice")}
                    options={[
                      {
                        key: ClosedChoice.open,
                        text: "Open from",
                        onRenderField: (props, render) => {
                          const disabled = !props || !props.checked;
                          return (
                            <Stack
                              horizontal
                              verticalAlign="center"
                              tokens={{ childrenGap: 16 }}
                            >
                              {render && render(props)}
                              <TimePickerField
                                disabled={disabled}
                                name={nameOf("startTime")}
                                suggestionInterval={SUGGESTION_INTERVAL}
                              />
                              <span> to </span>
                              <TimePickerField
                                disabled={disabled}
                                name={nameOf("endTime")}
                                suggestionInterval={SUGGESTION_INTERVAL}
                              />
                            </Stack>
                          );
                        }
                      },
                      {
                        key: ClosedChoice.closed,
                        text: "Closed all day"
                      }
                    ]}
                  />
                  <TextInputField
                    label="Reason"
                    name={nameOf("reason")}
                    multiline
                  />
                </Fieldset>
              )}
            </SubmissionFormDialog>
          );
        }}
      </DataFetcher>
    );
  });

export const EditPracticeDayWorkingHoursDialog: FunctionComponent<
  Omit<EditPracticeDayWorkingHoursProps, "onDismiss">
> = props => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <IconButton
        styles={{ root: { height: 24, width: 24 } }}
        iconProps={{ iconName: "Repair" }}
        onClick={() => setShowDialog(true)}
      />
      {showDialog && (
        <EditPracticeDayWorkingHoursDialogBase
          {...props}
          onDismiss={() => setShowDialog(false)}
        />
      )}
    </>
  );
};
