import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { mergeStyleSets, noWrap } from "@bps/fluent-ui";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  Navigate,
  NavigateProps
} from "@ui-components/navigation/Navigate.tsx";

interface ContactNavigateProps extends PropsWithChildren, NavigateProps {
  contact: Contact;
}

export const IndividualAndOrganisationNavigate: React.FC<ContactNavigateProps> =
  observer(({ contact, children, styles, ...navigateProps }) => {
    const getStyles = () => {
      if (contact.status === ContactStatus.Inactive) {
        return { root: { fontStyle: "italic", ...noWrap } };
      }
      return {};
    };

    return (
      <Navigate styles={mergeStyleSets(styles, getStyles())} {...navigateProps}>
        {children}
      </Navigate>
    );
  });
