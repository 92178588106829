import { Option } from "@bps/fluent-ui";
import { Contact } from "@stores/practice/models/Contact.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";

interface getAccountContactSelectOptionsParams {
  contacts: Contact[];
  patient?: Contact;
  practice: PracticeStore;
  searchString: string | undefined;
}

export const getAccountContactSelectOptions = (
  params: getAccountContactSelectOptionsParams
): Option<Contact>[] => {
  const { contacts, patient, practice, searchString } = params;
  let options: Option[] = [];

  if ((!searchString || !contacts.length) && patient?.relationships.length) {
    const accountHolders: Contact[] = [];

    patient.accountHolders.forEach(relationship => {
      const relatedContact = relationship.relatedContactId
        ? practice.contactsMap.get(relationship.relatedContactId)
        : undefined;

      if (relatedContact) {
        if (relationship.metadata?.isPrimary) {
          accountHolders.unshift(relatedContact);
        } else {
          accountHolders.push(relatedContact);
        }
      }
    });

    if (accountHolders.length > 0) {
      options.push({
        key: "account-holders",
        text: "Account holders",
        isHeading: true
      });

      accountHolders.forEach(accountHolder => {
        options.push({
          key: accountHolder.id,
          text: accountHolder.name,
          data: accountHolder
        });
      });
    }

    const relatedContacts: Contact[] = [];

    patient.relationships.forEach(relationship => {
      const relatedContact = relationship.relatedContactId
        ? practice.contactsMap.get(relationship.relatedContactId)
        : undefined;

      if (
        relatedContact &&
        accountHolders.every(
          accountHolder =>
            accountHolder.id !== relatedContact.id &&
            relatedContacts.every(
              otherRelatedContact =>
                otherRelatedContact.id !== relatedContact.id
            )
        )
      ) {
        relatedContacts.push(relatedContact);
      }
    });

    if (relatedContacts.length > 0) {
      options.push({
        key: "other-relationships",
        text: "Other relationships",
        isHeading: true
      });

      relatedContacts.forEach(relatedContact => {
        options.push({
          key: relatedContact.id,
          text: relatedContact.name,
          data: relatedContact
        });
      });
    }
  } else {
    options = contacts.map(contact => ({
      key: contact.id,
      text: contact.name,
      data: contact
    }));
  }

  return options;
};
