import { observer } from "mobx-react-lite";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useField } from "react-final-form";

import { GenericSelect, Option, Stack, useTheme } from "@bps/fluent-ui";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { ContactPickerFieldQuickAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerQuickAdd.tsx";
import { Labels } from "@modules/practice/screens/shared-components/types/labels.enums.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactPickerSuggestionItem } from "@ui-components/pickers/contact-picker/ContactPickerSuggestionItem.tsx";
import { getContactSuggestionItem } from "@ui-components/pickers/contact-picker/getContactSuggestionItem.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";
import { InvoiceAccountContactSelectSuggestionItem } from "./InvoiceAccountContactSelectSuggestionItem.tsx";
import { getAccountContactSelectOptions } from "./invoiceDetailsModalUtils.ts";

export const InvoiceAccountContactSelectField: FunctionComponent = observer(
  () => {
    const name = invoiceFormNameOf("accountContactId");
    const theme = useTheme();
    const { practice } = useStores();

    const [searchString, setSearchString] = useState<string>("");
    const [contacts, setContacts] = useState<Contact[]>([]);

    const { isAdjustMode } = useContext(InvoiceFormContext);
    // useField for accountContactId field
    const {
      input: { value: accountContactId, onChange: onAccountContactIdChange }
    } = useField<string | undefined>(name, { subscription: { value: true } });

    const {
      input: { value: patientId }
    } = useField<string | undefined>(invoiceFormNameOf("patientId"), {
      subscription: { value: true }
    });

    const addButtonClicked = useRef<boolean>(false); // used to prevent input autoselecting on blur
    const relatedContactText = useRef<string | undefined>();

    const billThePatient = accountContactId === patientId;

    const contact = accountContactId
      ? practice.contactsMap.get(accountContactId)
      : undefined;

    const patient = patientId ? practice.contactsMap.get(patientId) : undefined;

    useEffect(() => {
      patient?.loadRelatedContacts();
    }, [patient]);

    useEffect(() => {
      if (!accountContactId) {
        setSearchString("");
      }
    }, [accountContactId]);

    if (billThePatient) {
      return null;
    }

    const options = getAccountContactSelectOptions({
      contacts,
      patient,
      practice,
      searchString
    });

    const onSearchItem = async (search: string) => {
      relatedContactText.current = search;
      setSearchString(search);

      if (search) {
        const result = await practice.fetchContacts({
          filter: {
            types: Object.values(ContactType),
            statuses: [ContactStatus.Active],
            search
          }
        });

        setContacts(result.results);
      } else {
        setContacts([]);
      }
    };

    return (
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Stack.Item grow>
          <GenericSelect
            searchBoxProps={{ onSearch: onSearchItem }}
            options={options}
            label="Bill to"
            required
            disabled={isAdjustMode}
            selectedKeys={accountContactId}
            onChangeSelectedKeys={onAccountContactIdChange}
            onRenderFieldContent={() => contact?.name || "Search by name"}
            onRenderOption={(option: Option<Contact>) => {
              const contact = option.data;
              if (!contact) return null;
              return (
                <ContactPickerSuggestionItem
                  contact={contact}
                  isSelected={option.selected}
                  personaProps={getContactSuggestionItem(contact, theme, {
                    isSelected: Boolean(option.selected),
                    overrides: {
                      showSecondaryText: true,
                      onRenderSecondaryText: () => (
                        <InvoiceAccountContactSelectSuggestionItem
                          contact={contact}
                          patient={patient}
                        />
                      )
                    }
                  })}
                />
              );
            }}
            styles={{
              singleOption: { padding: 0 },
              fieldContent: { justifyContent: "flex-start" }
            }}
          />
        </Stack.Item>
        <ContactPickerFieldQuickAdd
          buttonWrapperStyles={{ root: { marginTop: 29 } }}
          name={name}
          disabled={isAdjustMode}
          addButtonLabel={Labels.newIndividual}
          relatedContactText={relatedContactText}
          addButtonClicked={addButtonClicked}
        />
      </Stack>
    );
  }
);
