import { maxArrayLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DocumentMergeFieldsFormValues } from "./merge-fields/DocumentWriterMergeFormDialog.types.ts";

export class DocumentWriterReportFormValidator extends Validator<DocumentMergeFieldsFormValues> {
  constructor() {
    super();

    this.forField("claimId", [required()]);
    this.forField("visits", [
      required(),
      maxArrayLength(4, "Maximum of 4 consults")
    ]);
  }
}
