import { action, observable, runInAction } from "mobx";

import {
  ClinicalDataType,
  EncounterStatus
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AppInsightsClinicalRecordHelper } from "@modules/clinical/screens/context/AppInsightsClinicalRecordHelper.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";

export class EncounterExistsPromptHelper extends AppInsightsClinicalRecordHelper {
  constructor(
    protected root: IRootStore,
    public openedEncounterId: string | undefined
  ) {
    super(root);
    runInAction(() => {
      this.selectedEncounterId = this.openedEncounterId;
    });
  }
  get clinical() {
    return this.root.clinical;
  }

  get core() {
    return this.root.core;
  }

  get booking() {
    return this.root.booking;
  }

  get userId() {
    return this.core?.user?.id;
  }

  get patientId() {
    return this.clinical.activeRecordPatientId!;
  }

  @observable selectedEncounterId: string | undefined;

  @action setSelectedEncounterId = (
    selectedEncounterId: string | undefined
  ) => {
    this.selectedEncounterId = selectedEncounterId;
  };

  fetchOpenEncounterDetail = async (openEncounter: Encounter) => {
    const encounterId = openEncounter.id;

    return Promise.all([
      this.clinical.getEncounterClinicalNote(encounterId),
      this.clinical.getEncounterClinicalData({
        encounterId,
        types: [ClinicalDataType.ReasonForVisit]
      })
    ]);
  };

  loadOpenedEncounters = async () => {
    let linkedEocId: string | undefined;

    // have to check for the condition linked to the calendarEvent we clicked "Start consult" as if no liked encounter it will just load the most recent encounter
    if (this.clinical?.activeCalendarEvent) {
      const openedFromAppointment = await this.booking.getCalendarEvent(
        this.clinical?.activeCalendarEvent
      );
      if (openedFromAppointment) {
        linkedEocId =
          openedFromAppointment.reason?.episodeOfCareId ?? undefined;
      }
    }

    // this will return the correct number of encounters for all scenarios
    //- if appointment is linked to a condition Only one as you can only have one open encounter per condition
    //- if not linked to a condition all open encounters for this patient/user
    //- if not have multiEncounter permission only one as you can only have one open encounter anyways
    const openEncounters = await this.clinical.getEncounters({
      patientId: this.patientId,
      userIds: this.userId ? [this.userId] : undefined,
      episodeOfCareIds: linkedEocId ? [linkedEocId] : undefined,
      statuses: [EncounterStatus.Open, EncounterStatus.Committed]
    });

    return openEncounters;
  };
}
