import {
  isAfterTimeField,
  maxLength,
  predicate,
  required,
  todayOrLater
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  EditUserDayWorkingHoursFormValues,
  nameOf
} from "../components/EditUserDayWorkingHours.types.ts";

const isPresentCondition = (
  val: string,
  values: EditUserDayWorkingHoursFormValues
) => values.awayChoice === "present";

export class EditUserDayWorkingHoursFormValidator extends Validator<EditUserDayWorkingHoursFormValues> {
  constructor() {
    super();

    this.forField(nameOf("startTime"), [
      predicate(
        isPresentCondition,
        required(),
        isAfterTimeField(
          "Start time must be earlier than end time.",
          nameOf("endTime"),
          true
        )
      )
    ]);

    this.forField(nameOf("endTime"), [
      predicate(isPresentCondition, required())
    ]);

    this.forField(nameOf("date"), [required(), todayOrLater]);
    this.forField(nameOf("reason"), [maxLength(250)]);
  }
}
