import { FontWeights, Text } from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";

export const TransactionOwingCell: React.FC<{
  owing: number;
  isCancelled?: boolean;
}> = ({ owing, isCancelled }) => {
  return (
    <Text
      styles={{
        root: {
          fontWeight: owing > 0 ? FontWeights.semibold : FontWeights.regular,
          textAlign: "right",
          display: "block",
          width: "100%"
        }
      }}
    >
      {owing === 0 || isCancelled
        ? ""
        : currencyFormat(owing, { currency: "" })}
    </Text>
  );
};
