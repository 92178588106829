import { useField } from "react-final-form";

export const useAttachedDocumentCount = (): number => {
  const {
    input: { value: documents }
  } = useField("documents", {
    subscription: { value: true }
  });

  const {
    input: { value: uploadDocuments }
  } = useField("uploadDocuments", {
    subscription: { value: true }
  });

  return (documents.length || 0) + (uploadDocuments.length || 0);
};
