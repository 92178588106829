import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { TimeRanges } from "@stores/booking/models/TimeRanges.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";

export enum AwayChoice {
  away = "away",
  present = "present"
}
export type EditUserDayWorkingHoursFormValues = {
  date?: Date | undefined;
  startTime?: string | undefined;
  endTime?: string | undefined;
  awayChoice: AwayChoice;
  reason?: string | undefined;
};

export type EditUserDayWorkingHoursProps = {
  user: User;
  onClose: () => void;
};

export type WorkingHoursInfo = {
  start: DateTime | undefined;
  end: DateTime | undefined;
  date: DateTime;
};

export type EditUserDayWorkingHoursData = {
  availability: UserAvailabilityModel;
  user: User;
  workingHours?: TimeRanges;
  orgUnitHours?: TimeRanges;
};

export const nameOf = nameOfFactory<EditUserDayWorkingHoursFormValues>();
