import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface PatientMergeFieldValues {
  masterId?: string;
  duplicateId: string;
  masterStatusFilter?: ContactStatus;
  duplicateStatusFilter: ContactStatus;
}

export const patientMergeNameOf = nameOfFactory<PatientMergeFieldValues>();

export const KNOWLEDGEBASE_INFO_LINK =
  "https://kb.bpsoftware.net/bpcloud/Management/Patientmerge.htm";
