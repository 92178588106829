import { FormApi } from "final-form";

import {
  ClinicalDataType,
  EncounterClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { ClinicalFlagsFormValues } from "./ClinicalFlagForm.types.ts";
import { ClinicalFlagsContent } from "./ClinicalFlagsContent.tsx";

interface ClinicalFlagsFormProps {
  clinicalRecord: ClinicalRecord;
}

const ClinicalFlagsFormBase: React.FunctionComponent<
  ClinicalFlagsFormProps
> = ({ clinicalRecord }) => {
  const { clinical, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const clinicalFlagsData = clinicalRecord.stashedClinicalData?.clinicalFlags;

  const initialValues = {
    redFlags: clinicalFlagsData?.redFlags,
    redFlagsAllClear: clinicalFlagsData?.redFlagsAllClear,
    yellowFlags: clinicalFlagsData?.yellowFlags,
    yellowFlagsAllClear: clinicalFlagsData?.yellowFlagsAllClear
  };

  const convertFormValuesToData = (values: ClinicalFlagsFormValues) => {
    const eTag = clinicalRecord.clinicalData?.clinicalFlags?.eTag;

    const confirmedETag =
      clinicalRecord.clinicalData?.clinicalFlagsConfirmed?.eTag;

    const clinicalData: EncounterClinicalDataDto = {};

    clinicalData.clinicalFlags = {
      eTag,
      redFlags: values.redFlags,
      redFlagsAllClear: values.redFlagsAllClear,
      yellowFlags: values.yellowFlags,
      yellowFlagsAllClear: values.yellowFlagsAllClear
    };

    clinicalData.clinicalFlagsConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };

    return clinicalData;
  };

  const submitData = async (values: ClinicalFlagsFormValues) => {
    const clinicalData: EncounterClinicalDataDto = convertFormValuesToData({
      ...values
    });

    await clinicalRecord.saveClinicalData(clinicalData);
  };

  const onDismiss = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.ClinicalFlags
    );
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "clinicalFlags",
      "clinicalFlagsConfirmed"
    ]);
  };

  const onSubmitSucceeded = (
    values: ClinicalFlagsFormValues,
    form: FormApi<ClinicalFlagsFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.ClinicalFlags
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onDismiss();
    }
  };

  return (
    <ClinicalSubmissionForm<ClinicalFlagsFormValues>
      formName="clinicalFlags"
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues}
      readOnly={isViewOnlyOrDischarged}
      disableRoutePrompt
      heading="Clinical flags"
      noGap
      onCancel={onDismiss}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyClinicalFlagsForm
      }
      disableButtonCondition={stashedData =>
        !stashedData?.dirtyClinicalFlagsForm
      }
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.clinicalFlagsConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.clinicalFlagsConfirmed
      )}
    >
      <>
        <StashedClinicalDataFormSpy<ClinicalFlagsFormValues>
          clinicalRecord={clinicalRecord}
          getData={convertFormValuesToData}
          areasToObserve={{
            clinicalFlags: [ClinicalDataType.ClinicalFlags]
          }}
        />
        <ClinicalFlagsContent />
      </>
    </ClinicalSubmissionForm>
  );
};

export const ClinicalFlagsForm = withFetch(
  x => [x.clinical.ref.clinicalFlags.load()],
  ClinicalFlagsFormBase
);
