import { FeeType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export enum ScheduleFormLabels {
  name = "Name",
  description = "Description",
  startDate = "Active from",
  endDate = "To"
}

export enum FeeTileLabels {
  active = "Active",
  inactive = "Inactive",
  fee = "Fee",
  feeFor = "Fee for",
  service = "Service",
  material = "Material",
  activeDates = "Active dates"
}

export enum FeeFormLabels {
  ongoing = "Ongoing",
  itemNumber = "Item number",
  itemIs = "Item is a",
  description = "Description",
  feeType = "Fee type",
  name = "Name",
  fee = "Fee",
  gst = "GST",
  editableFee = "Editable when billing",
  startDate = "Active from",
  newFee = "New fee",
  gstIncluded = " GST included",
  gstExcluded = "GST excluded",
  includesGst = "Includes GST",
  feeFor = "Fee for"
}

export interface ScheduleFormValues {
  scheduleName: string;
  scheduleDescription?: string;
  scheduleIsOngoing: boolean;
  scheduleStartDate: Date;
  scheduleEndDate?: Date;
}

export interface FeeFormValues {
  feeCode?: string;
  feeIsService: boolean;
  feeName?: string;
  feeDescription?: string;
  feeIncludesGst: boolean;
  feeEffectiveDate?: Date;
  feeFee?: string;
  feeIsActive?: boolean;
  feeIsEditable?: boolean;
  feeNextEffectiveDate?: Date;
  feeNextFee?: string;
  feeNextIsActive?: boolean;
  feeNextIsEditable?: boolean;
  feeType: FeeType;
  // feeShowCurrent determines whether to show the "current" row on the edit fee form.
  // It is only relevant when first service instance has an effective date in the future
  feeShowCurrent: boolean;
}

export type NewScheduleFormValues = ScheduleFormValues & FeeFormValues;
export const newScheduleFormNameOf = nameOfFactory<NewScheduleFormValues>();
export const feeFormNameOf = nameOfFactory<FeeFormValues>();
