import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  maxArrayLength,
  maxLength,
  predicate,
  requiredArrayLength
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { InjuryDiagnosisAndAssistanceFormValues } from "@shared-types/acc/injury-diagnosis-and-assistance-values.type.ts";

import { ClaimDiagnosisValidator } from "../../claim-adjustment/validators/ClaimDiagnosisValidator.tsx";
import { ClaimPrimaryDiagnosisValidator } from "./ClaimPrimaryDiagnosisValidator.tsx";

export class InjuryDiagnosisAndAssistanceFormValidator extends Validator<InjuryDiagnosisAndAssistanceFormValues> {
  constructor() {
    super();

    const claimDiagnosisValidator = new ClaimDiagnosisValidator();
    const primaryDiagnosisValidator = new ClaimPrimaryDiagnosisValidator();

    this.forArrayField(
      nameof("claimDiagnosis"),
      claimDiagnosisValidator.validate
    );

    this.forField("primaryDiagnosis", primaryDiagnosisValidator.validate);

    const singleItemAndHasAdditionalDiagnosesToggleOn = (
      items: ClaimDiagnosisFormValues[],
      parent: InjuryDiagnosisAndAssistanceFormValues
    ) => items?.length <= 0 && parent.hasAdditionalDiagnoses;

    this.forField(
      nameof("claimDiagnosis"),
      predicate(
        singleItemAndHasAdditionalDiagnosesToggleOn,
        requiredArrayLength(1)
      )
    );

    this.forField(nameof("claimDiagnosis"), [
      maxArrayLength(10, "Must not exceed 10 diagnosis.")
    ]);

    this.forField("injuryComments", [maxLength(255)]);
  }
}
