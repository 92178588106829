import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface MeetingFormValues {
  status?: string;
  bookedBy?: string;
  duration?: string;
  comments?: string;
  contactId?: string;
  providerId?: string;
  startDate?: Date;
  startTime?: string;
  title?: string;
}

export const nameOf = nameOfFactory<MeetingFormValues>();
