import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { PropsWithChildren, useRef } from "react";

import { GetInvoiceItemsArgs } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePersistDraftFilter } from "@modules/billing/screens/draft-items/hooks/usePersistDraftFilter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

const pickerWidth = { root: { maxWidth: 250, minWidth: 150 } };

const convertStringToArray = (filterQuery: string[] | undefined | string) => {
  if (!filterQuery) {
    return [];
  }
  if (Array.isArray(filterQuery)) {
    return filterQuery;
  }
  return [filterQuery];
};

const defaultDraftItemsFilter: DraftItemsState = {
  accountIds: [],
  userIds: [],
  patientIds: undefined
};

export interface DraftItemsFilterQuery
  extends Omit<
    GetInvoiceItemsArgs,
    "calendarEventIds" | "itemIds" | "draftOnly"
  > {}

interface DraftItemsState
  extends Pick<
    DraftItemsFilterQuery,
    "accountIds" | "userIds" | "patientIds"
  > {}

export const DraftItemsFilterBar: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const { routing } = useStores();
    const patientLabel = usePatientLabel(true);

    const nameOf = nameOfFactory<DraftItemsState>();

    usePersistDraftFilter();

    const setFilter = (newFilter: Partial<DraftItemsState>) => {
      const existingFilter: DraftItemsState = parse(routing.location.search, {
        parseBooleans: true
      });

      const query: DraftItemsState = {
        ...existingFilter,
        ...newFilter
      };

      if (!isEqual(query, existingFilter)) {
        routing.pushQueryStringParams(query);
      }
    };

    const filterQuery: DraftItemsFilterQuery = parse(routing.location.search, {
      parseBooleans: true
    });

    const onFilterChange = (
      field: keyof DraftItemsState,
      values: DraftItemsState
    ) => {
      const filterValue = values[field];
      return setFilter({ [field]: filterValue });
    };

    const initialValues = useRef<DraftItemsState>({
      accountIds: convertStringToArray(filterQuery.accountIds),
      userIds: convertStringToArray(filterQuery.userIds),
      patientIds: convertStringToArray(filterQuery.patientIds)
    });

    const isFilterPristine = isEqual(filterQuery, defaultDraftItemsFilter);
    return (
      <FilterBar<DraftItemsState>
        initialValues={initialValues.current}
        onChange={({ field, values }) => onFilterChange(field, values)}
        customResetButtonCondition={() => isFilterPristine}
        items={[
          {
            type: "patientsSelect",
            stickItem: true,
            name: nameOf("patientIds"),
            props: {
              styles: {
                root: {
                  maxWidth: 250,
                  minWidth: 250
                }
              },
              calloutWidth: 230,
              placeholder: `${patientLabel} search`,
              multiSelect: true
            }
          },
          {
            type: "contactsSelect",
            name: nameOf("accountIds"),
            props: {
              id: "transaction-bill-to-select",
              multiSelect: true,
              placeholder: "Bill to",
              styles: pickerWidth,
              calloutWidth: 230
            }
          },
          {
            type: "usersSelect",
            name: nameOf("userIds"),
            props: {
              id: "darft-filter-user",
              placeholder: "Providers",
              styles: pickerWidth,
              calloutWidth: 230,
              multiSelect: true
            }
          }
        ]}
        styles={{
          peoplePicker: { minWidth: 400 }
        }}
        onReset={(actions, values) => {
          if (!isEqual(defaultDraftItemsFilter, values)) {
            actions.reset(defaultDraftItemsFilter);
            routing.pushQueryStringParams(defaultDraftItemsFilter);
          }
        }}
      >
        {() => children}
      </FilterBar>
    );
  }
);
