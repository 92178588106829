import { computed, toJS } from "mobx";

import { DateTime } from "@bps/utils";
import {
  AnalysisAndPlanClinicalDataDto,
  ClinicalDataType,
  CurrentInjuryClinicalDataDto,
  CustomClinicalToolClinicalDataDto,
  CustomClinicalToolContextClinicalDataDto,
  CustomClinicalToolContextDto,
  CustomClinicalToolDto,
  EncounterClinicalDataDto,
  FullBodyCodes,
  InjuryArea,
  InjuryAreaClinicalDataDto,
  InjuryClinicalDataDto,
  InjuryImageType,
  OtherMovementsDto,
  PatientDemographicUpdateClinicalDataDto,
  PatientTreatmentPlanClinicalDataDto,
  PermanentClinicalTab,
  PhysicalActivityClinicalDataDto,
  PhysicalActivityFormDto,
  PhysioMedicalHistoryClinicalDataDto,
  PostureOrObservationsDto,
  TreatmentAndEducationClinicalDataDto,
  WorkHistoryClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AreasToObserveKeys } from "@shared-types/clinical/areas-to-observe-keys.type.ts";
import { GoalDataItem } from "@shared-types/clinical/goal-data-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { TreatmentData } from "@shared-types/clinical/treatment-data.interface.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { removeUndefinedProperties } from "@stores/clinical/utils/clinical.utils.ts";

import { OutcomeMeasuresCardModel } from "../outcome-measure/OutcomeMeasuresCardModel.ts";
import {
  convertGoals,
  getDiagnoses,
  getMappedDiagnoses,
  getReasonForVisit,
  getTreatmentData,
  getUpdatedMedicalHistory
} from "../SOTAP.utils.ts";
import { InjuryImagesHelper } from "./InjuryImagesHelper.tsx";
import {
  ElbowImageContent,
  FootImageContent,
  HipImageContent,
  KneeImageContent,
  ShoulderImageContent
} from "./InjuryImagesHelper.types.ts";
import { SOTAPFormCommonModel } from "./SOTAPFormCommonModel.ts";

export class SOTAPFormModel extends SOTAPFormCommonModel {
  constructor(
    private clinical: ClinicalStore,
    protected clinicalRecord: ClinicalRecord
  ) {
    super(clinicalRecord);
  }
  psfsQuestions: string[] = [];
  psfsGoalsAdded: boolean | undefined = false;
  private outcomeMeasuresCardModel = new OutcomeMeasuresCardModel(
    this.clinicalRecord
  );

  public readonly areasToObserve: Partial<
    Record<AreasToObserveKeys, (ClinicalDataType | PermanentClinicalTab)[]>
  > = {
    physicalActivity: [PermanentClinicalTab.SOTAP],
    workHistory: [PermanentClinicalTab.SOTAP],
    physioMedicalHistory: [PermanentClinicalTab.SOTAP],
    injury: [PermanentClinicalTab.SOTAP],
    physioBody: [PermanentClinicalTab.SOTAP],
    customClinicalTool: [PermanentClinicalTab.SOTAP],
    diagnoses: [PermanentClinicalTab.SOTAP],
    injuryArea: [PermanentClinicalTab.SOTAP],
    patientDemographicUpdate: [PermanentClinicalTab.SOTAP],
    reasonForVisit: [PermanentClinicalTab.SOTAP],
    customClinicalToolContext: [PermanentClinicalTab.SOTAP],
    currentInjury: [PermanentClinicalTab.SOTAP],
    postureOrObservations: [PermanentClinicalTab.SOTAP],
    otherMovements: [PermanentClinicalTab.SOTAP],
    analysisAndPlan: [PermanentClinicalTab.SOTAP],
    treatmentAndEducation: [PermanentClinicalTab.SOTAP],
    patientTreatmentPlan: [PermanentClinicalTab.SOTAP]
  };

  get patient() {
    return this.clinicalRecord.patient;
  }

  get sex() {
    return this.clinicalRecord.patient?.sex;
  }

  loadPsfsQuestions = async () => {
    const { questionnaire, data } =
      await this.outcomeMeasuresCardModel.getComponentData(
        ClinicalDataType.PSFS
      );

    if (data) {
      const contextData = this.clinicalRecord.clinicalData?.psfsContext;
      const context = contextData!.contexts.find(
        c => c.contextId === data.contextId
      );

      if (questionnaire) {
        questionnaire.items.forEach(item => {
          let question: string | undefined;
          if (context) {
            const activity = context.activities.find(
              a => a.questionnaireItemId === item.id
            );
            if (activity) {
              question = activity.text;
              this.psfsQuestions.push(question);
            }
          }
        });
      }
    }
  };

  @computed
  get initialSOTAPFormValues(): SOTAPFormValues {
    const episodeOfCare = this.clinicalRecord.episodeOfCare;
    const { answersArray, columns } = this.getCustomClinicalTools(
      this.clinicalRecord,
      this.customClinicalToolData
    );

    const physicalData =
      this.clinicalRecord.stashedClinicalData?.physicalActivity;

    const workData = this.clinicalRecord.stashedClinicalData?.workHistory;
    const pduData =
      this.clinicalRecord.stashedClinicalData?.patientDemographicUpdate;

    const bodyData = this.clinicalRecord.stashedClinicalData?.physioBody;
    const postureOrObservations =
      this.clinicalRecord.stashedClinicalData?.postureOrObservations
        ?.postureOrObservations;

    const otherMovements =
      this.clinicalRecord.stashedClinicalData?.otherMovements?.otherMovements;

    const analysisAndPlan =
      this.clinicalRecord.stashedClinicalData?.analysisAndPlan;

    const patient = this.clinicalRecord.patient;
    const currentInjuryData =
      this.clinicalRecord.stashedClinicalData?.currentInjury;
    let dateOfInjury = currentInjuryData?.dateOfInjury
      ? DateTime.fromISO(currentInjuryData?.dateOfInjury).toJSDate()
      : undefined;

    if (!dateOfInjury)
      dateOfInjury = DateTime.jsDateFromISO(episodeOfCare?.injuryDate);

    const physioMedicalHistoryData =
      this.clinicalRecord.stashedClinicalData?.physioMedicalHistory;

    const treatmentAndEducationData =
      this.clinicalRecord.stashedClinicalData?.treatmentAndEducation;

    const treatments = getTreatmentData(
      treatmentAndEducationData?.treatments,
      true
    );

    const treatmentsBase = treatments.map(x => x.treatment);

    const injuryAreaData = this.clinicalRecord.stashedClinicalData?.injuryArea;

    const patientTreatmentPlanData =
      this.multiProviderHelper.getInitialValueTreatmentPlanData();

    const diagnosisClinicalData =
      this.clinicalRecord.stashedClinicalData?.diagnoses;

    const injuryData = this.clinicalRecord.stashedClinicalData?.injury;

    let frontBodyImage: string | undefined;
    let backBodyImage: string | undefined;
    let leftBodyImage: string | undefined;
    let rightBodyImage: string | undefined;

    if (bodyData) {
      const frontCode =
        patient?.sex === Sex.Female
          ? FullBodyCodes.FemaleFront
          : FullBodyCodes.MaleFront;

      const backCode =
        patient?.sex === Sex.Female
          ? FullBodyCodes.FemaleBack
          : FullBodyCodes.MaleBack;

      const leftSideCode =
        patient?.sex === Sex.Female
          ? FullBodyCodes.FemaleLeftSide
          : FullBodyCodes.MaleLeftSide;

      const rightSideCode =
        patient?.sex === Sex.Female
          ? FullBodyCodes.FemaleRightSide
          : FullBodyCodes.MaleRightSide;

      const frontIndex = bodyData.images.findIndex(i => i.type === frontCode);

      const backIndex = bodyData.images.findIndex(i => i.type === backCode);

      const leftSideIndex = bodyData.images.findIndex(
        i => i.type === leftSideCode
      );

      const rightSideIndex = bodyData.images.findIndex(
        i => i.type === rightSideCode
      );

      if (frontIndex !== -1) {
        frontBodyImage = bodyData.images[frontIndex].content
          ? atob(bodyData.images[frontIndex].content!)
          : undefined;
      }

      if (backIndex !== -1) {
        backBodyImage = bodyData.images[backIndex].content
          ? atob(bodyData.images[backIndex].content!)
          : undefined;
      }

      if (leftSideIndex !== -1) {
        leftBodyImage = bodyData.images[leftSideIndex].content
          ? atob(bodyData.images[leftSideIndex].content!)
          : undefined;
      }

      if (rightSideIndex !== -1) {
        rightBodyImage = bodyData.images[rightSideIndex].content
          ? atob(bodyData.images[rightSideIndex].content!)
          : undefined;
      }
    }

    const {
      injuryAreas,
      injuryAreaMotionAssessmentItems,
      injuryAreaImageItems
    } = InjuryImagesHelper.getInjuryAreaData(injuryAreaData, this.clinical.ref);

    let goals: GoalDataItem[] =
      (patientTreatmentPlanData && patientTreatmentPlanData.goals
        ? convertGoals(patientTreatmentPlanData.goals)
        : undefined) ?? [];

    this.psfsGoalsAdded =
      patientTreatmentPlanData && patientTreatmentPlanData.goals
        ? patientTreatmentPlanData.psfsGoalsAdded
        : false;

    if (
      !patientTreatmentPlanData?.goals ||
      (patientTreatmentPlanData.goals &&
        !patientTreatmentPlanData.psfsGoalsAdded)
    ) {
      if (this.psfsQuestions?.length > 0) {
        this.psfsGoalsAdded = true;
        const goalsList: GoalDataItem[] = [];
        this.psfsQuestions.map(question =>
          goalsList.push({
            goal: question,
            isAchieved: false,
            startDate: DateTime.today().toJSDate()
          })
        );
        goals = goalsList;
      }
    }

    const planTreatments = getTreatmentData(
      patientTreatmentPlanData?.treatments,
      false
    );

    const planTreatmentsBase = planTreatments.map(x => x.treatment);

    let spinalCordFrontImage: string | undefined;
    let spinalCordSideImage: string | undefined;
    let handsWristsImage: string | undefined;

    let kneeImageContent: KneeImageContent | undefined;
    let hipImageContent: HipImageContent | undefined;

    let footImageContent: FootImageContent | undefined;
    let shoulderImageContent: ShoulderImageContent | undefined;
    let elbowImageContent: ElbowImageContent | undefined;

    if (injuryAreaImageItems && injuryAreaImageItems.length > 0) {
      const spinalCordFront = injuryAreaImageItems.find(
        x =>
          x.injuryArea === InjuryArea.Spine &&
          x.type === InjuryImageType.SpinalCordFront
      );
      if (spinalCordFront) {
        spinalCordFrontImage = atob(spinalCordFront.content);
      }

      const spinalCordSide = injuryAreaImageItems.find(
        x =>
          x.injuryArea === InjuryArea.Spine &&
          x.type === InjuryImageType.SpinalCordSide
      );
      if (spinalCordSide) {
        spinalCordSideImage = atob(spinalCordSide.content);
      }

      const handsWrists = injuryAreaImageItems.find(
        x =>
          x.injuryArea === InjuryArea.HandAndWrist &&
          x.type === InjuryImageType.HandsWrists
      );
      if (handsWrists) {
        handsWristsImage = atob(handsWrists.content);
      }

      if (injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Knee)) {
        kneeImageContent = InjuryImagesHelper.getKneeImageContents(
          injuryAreaImageItems,
          patient?.sex
        );
      }

      if (
        injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.AnkleFoot)
      ) {
        footImageContent =
          InjuryImagesHelper.getFootImageContents(injuryAreaImageItems);
      }

      if (injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Hip)) {
        hipImageContent = InjuryImagesHelper.getHipImageContents(
          injuryAreaImageItems,
          patient?.sex
        );
      }

      if (
        injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Shoulder)
      ) {
        shoulderImageContent = InjuryImagesHelper.getShoulderImageContent(
          injuryAreaImageItems,
          patient?.sex
        );
      }

      if (injuryAreaImageItems.some(i => i.injuryArea === InjuryArea.Elbow)) {
        elbowImageContent = InjuryImagesHelper.getElbowImageContent(
          injuryAreaImageItems,
          patient?.sex
        );
      }
    }

    const { primaryDiagnoses, additionalDiagnoses, multipleDiagnoses } =
      getDiagnoses(this.clinicalRecord, diagnosisClinicalData);

    const filledClinicalDataType = this.filledClinicalDataType;

    return {
      workType: workData?.workType,
      occupation:
        !!this.clinicalRecord.clinicalData?.patientDemographicUpdate
          ?.occupation || !!pduData?.occupation
          ? pduData?.occupation
          : patient?.occupation,
      activities:
        physicalData?.activities?.map(x => {
          return {
            ...x,
            sessionsPerWeek: x.sessionsPerWeek.toString(),
            sessionLength: x.sessionLength.toString()
          };
        }) ?? [],
      dominantHand: physicalData?.dominantHand,
      frontBodyImage,
      backBodyImage,
      leftBodyImage,
      rightBodyImage,
      postureOrObservations,
      otherMovements,
      analysis: analysisAndPlan?.analysis,
      plan: analysisAndPlan?.plan,
      mechanismOfInjury: currentInjuryData?.mechanismOfInjury,
      currentHistory: currentInjuryData?.currentHistory,
      investigations: currentInjuryData?.investigations,
      investigationsComment: currentInjuryData?.investigationComment,
      progression: currentInjuryData?.progression,
      swelling: currentInjuryData?.swelling,
      stage: currentInjuryData?.stage,
      severity: currentInjuryData?.severity,
      irritability: currentInjuryData?.irritability,
      natures: currentInjuryData?.natures,
      naturesComment: currentInjuryData?.natureComment,
      dailyPattern: currentInjuryData?.dailyPattern,
      sleepPattern: currentInjuryData?.sleepPattern,
      aggravatingFactors: currentInjuryData?.aggravatingFactors,
      aggravatingFactorsComment: currentInjuryData?.aggravatingFactorsComment,
      easingFactors: currentInjuryData?.easingFactors,
      easingFactorsComment: currentInjuryData?.easingFactorsComment,
      generalHealth: physioMedicalHistoryData?.generalHealth,
      physioMedicalHistory: physioMedicalHistoryData?.physioMedicalHistory,
      medications: physioMedicalHistoryData?.medications,
      reactions: physioMedicalHistoryData?.reactions,
      redFlags: physioMedicalHistoryData?.redFlags,
      redFlagsAllClear: physioMedicalHistoryData?.redFlagsAllClear ?? false,
      specialQuestions: physioMedicalHistoryData?.specialQuestions,
      specialQuestionsAllClear:
        physioMedicalHistoryData?.specialQuestionsAllClear ?? false,
      psychosocialFlags: physioMedicalHistoryData?.psychosocialFlags,
      psychosocialFlagsAllClear:
        physioMedicalHistoryData?.psychosocialFlagsAllClear ?? false,
      vBIs: physioMedicalHistoryData?.vBIs,
      vBIsAllClear: physioMedicalHistoryData?.vBIsAllClear ?? false,
      neuroSigns: physioMedicalHistoryData?.neuroSigns,
      precautions: physioMedicalHistoryData?.precautions,
      customClinicalTools: answersArray,
      customToolBaseColumns: columns,
      treatments,
      treatmentsBase,
      otherTreatments: treatmentAndEducationData?.otherTreatments,
      hasOtherTreatments:
        treatmentAndEducationData?.otherTreatments !== undefined,
      educationComment: treatmentAndEducationData?.educationComment,
      educationOptions: treatmentAndEducationData?.educationOptions,
      injuryAreas,
      injuryAreaMotionAssessments: injuryAreaMotionAssessmentItems,
      goals,
      psfsGoalsAdded: this.psfsGoalsAdded,
      treatmentPlanDiscussed: patientTreatmentPlanData?.treatmentPlanDiscussed,
      warningExplained: patientTreatmentPlanData?.warningExplained,
      consentObtained: patientTreatmentPlanData?.consentObtained,
      culturalNeedsIdentified:
        patientTreatmentPlanData?.culturalNeedsIdentified,
      hasAdditionalDiscussions:
        !!patientTreatmentPlanData?.additionalDiscussions,
      additionalDiscussions: patientTreatmentPlanData?.additionalDiscussions,
      injuryAreaImages: injuryAreaImageItems,
      spinalCordFrontImage,
      spinalCordSideImage,
      handsWristsImage,
      primaryDiagnosis: primaryDiagnoses,
      additionalDiagnoses,
      multipleDiagnoses,
      diagnosisComment: diagnosisClinicalData?.diagnosisComment,
      dateOfInjury,
      dateOfSurgery: DateTime.jsDateFromISO(injuryData?.dateOfSurgery),
      addSurgeryDate: !!injuryData?.dateOfSurgery,
      filledClinicalDataType,
      planTreatmentsBase,
      planTreatments,
      planHasOtherTreatments:
        patientTreatmentPlanData?.otherTreatments !== undefined,
      planOtherTreatments: patientTreatmentPlanData?.otherTreatments,
      planEducationOptions: patientTreatmentPlanData?.educationOptions,
      planOtherEducationComment:
        patientTreatmentPlanData?.otherEducationComment,
      planEducationComment: patientTreatmentPlanData?.educationComment,
      planReview: patientTreatmentPlanData?.plan,

      investigationsFlag:
        (currentInjuryData?.investigations &&
          currentInjuryData?.investigations.length > 0) ||
        undefined,

      frontKneeImage: kneeImageContent?.frontKneeImage,
      backKneeImage: kneeImageContent?.backKneeImage,
      leftSideKneeImage: kneeImageContent?.leftSideKneeImage,
      rightSideKneeImage: kneeImageContent?.rightSideKneeImage,

      frontFootImage: footImageContent?.frontFootImage,
      backFootImage: footImageContent?.backFootImage,
      bottomFootImage: footImageContent?.bottomFootImage,
      topFootImage: footImageContent?.topFootImage,
      leftInsideFootImage: footImageContent?.leftInsideFootImage,
      rightInsideFootImage: footImageContent?.rightInsideFootImage,
      leftOutsideFootImage: footImageContent?.leftOutsideFootImage,
      rightOutsideFootImage: footImageContent?.rightOutsideFootImage,

      frontHipImage: hipImageContent?.frontHipImage,
      backHipImage: hipImageContent?.backHipImage,
      leftSideHipImage: hipImageContent?.leftSideHipImage,
      rightSideHipImage: hipImageContent?.rightSideHipImage,

      leftSideShoulderImage: shoulderImageContent?.leftSideShoulderImage,
      leftFrontShoulderImage: shoulderImageContent?.leftFrontShoulderImage,
      leftBackShoulderImage: shoulderImageContent?.leftBackShoulderImage,
      rightSideShoulderImage: shoulderImageContent?.rightSideShoulderImage,
      rightBackShoulderImage: shoulderImageContent?.rightBackShoulderImage,
      rightFrontShoulderImage: shoulderImageContent?.rightFrontShoulderImage,

      leftElbowImage: elbowImageContent?.leftElbowImage,
      rightElbowImage: elbowImageContent?.rightElbowImage
    };
  }

  private getTreatmentData = (treatments?: TreatmentData[]) => {
    return treatments
      ?.filter(x => !!x.treatment)
      .map(x => {
        return {
          treatment: x.treatment,
          comment: x.comment
        };
      });
  };

  private getCurrentInjuryStashedData = (values: SOTAPFormValues) => {
    const currentInjuryData = toJS(
      this.clinicalRecord.clinicalData?.currentInjury,
      { recurseEverything: true }
    );

    const newCurrentInjuryData: CurrentInjuryClinicalDataDto | undefined = {
      ...currentInjuryData,
      dateOfInjury: values.dateOfInjury
        ? DateTime.jsDateToISODate(values.dateOfInjury)
        : undefined,
      mechanismOfInjury: values.mechanismOfInjury,
      currentHistory: values.currentHistory,
      investigations:
        values.investigations && values.investigations.length > 0
          ? values.investigations
          : undefined,
      investigationComment: values.investigationsComment,
      progression: values.progression,
      swelling: values.swelling,
      stage: values.stage,
      severity: values.severity,
      irritability: values.irritability,
      natures:
        values.natures && values.natures.length > 0
          ? values.natures
          : undefined,
      natureComment: values.naturesComment,
      dailyPattern: values.dailyPattern,
      sleepPattern: values.sleepPattern,
      aggravatingFactors:
        values.aggravatingFactors && values.aggravatingFactors.length > 0
          ? values.aggravatingFactors
          : undefined,
      aggravatingFactorsComment: values.aggravatingFactorsComment,
      easingFactors:
        values.easingFactors && values.easingFactors.length > 0
          ? values.easingFactors
          : undefined,
      easingFactorsComment: values.easingFactorsComment
    };

    return removeUndefinedProperties(newCurrentInjuryData) as
      | CurrentInjuryClinicalDataDto
      | undefined;
  };

  private getCustomClinicalToolsStashedData = (values: SOTAPFormValues) => {
    const customClinicalTools = values.customClinicalTools ?? [];
    const customData = toJS(
      this.clinicalRecord.clinicalData?.customClinicalTool,
      { recurseEverything: true }
    );
    let customClinicalToolContext:
      | CustomClinicalToolContextClinicalDataDto
      | undefined;

    const contextData =
      this.clinicalRecord.clinicalData?.customClinicalToolContext;

    const existingContexts = contextData?.contexts ?? [];
    const addedContexts: CustomClinicalToolContextDto[] = [];
    // save context first
    customClinicalTools.forEach(customTool => {
      if (
        existingContexts.findIndex(
          c => c.contextId === customTool.contextId
        ) === -1
      ) {
        addedContexts.push({
          contextId: customTool.contextId,
          name: customTool.name!
        });
      }

      if (addedContexts.length > 0) {
        customClinicalToolContext = {
          ...contextData,
          contexts: [...addedContexts, ...existingContexts]
        };
      }
    });

    // save encounter-scoped data next

    const cleanedCustomTools = customClinicalTools.filter(
      x => x.thisEncounterResult !== undefined
    );

    const customClinicalTool: CustomClinicalToolClinicalDataDto = {
      ...customData,
      tools: cleanedCustomTools.map(c => {
        return {
          contextId: c.contextId,
          result: c.thisEncounterResult,
          name: c.name
        } as CustomClinicalToolDto;
      })
    };

    return { customClinicalToolContext, customClinicalTool };
  };

  private getDiagnosesStashedData = (values: SOTAPFormValues) => {
    const diagnosesDataItems = getMappedDiagnoses(
      values.primaryDiagnosis ?? [],
      values.additionalDiagnoses ?? []
    );

    const diagnosesData = toJS(this.clinicalRecord.clinicalData?.diagnoses, {
      recurseEverything: true
    });

    const diagnoses = {
      ...diagnosesData,
      diagnoses: diagnosesDataItems,
      diagnosisComment: values.diagnosisComment
    };

    return { diagnoses };
  };

  private getInjuryAreaStashedData = (values: SOTAPFormValues) => {
    const injuryAreaData = toJS(this.clinicalRecord.clinicalData?.injuryArea, {
      recurseEverything: true
    });
    let injuryArea: InjuryAreaClinicalDataDto = {
      ...injuryAreaData,
      assessments: InjuryImagesHelper.getMappedInjuryAreaAssessments(
        values.injuryAreas,
        values.injuryAreaMotionAssessments
      )
    };

    if (injuryArea?.assessments) {
      if (values.spinalCordFrontImage) {
        injuryArea = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: injuryArea,
          injuryImageType: InjuryImageType.SpinalCordFront,
          imageContent: values.spinalCordFrontImage,
          injuryArea: InjuryArea.Spine
        });
      }

      if (values.spinalCordSideImage) {
        injuryArea = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: injuryArea,
          injuryImageType: InjuryImageType.SpinalCordSide,
          imageContent: values.spinalCordSideImage,
          injuryArea: InjuryArea.Spine
        });
      }

      if (values.handsWristsImage) {
        injuryArea = InjuryImagesHelper.updateInjuryImage({
          injuryAreaData: injuryArea,
          injuryImageType: InjuryImageType.HandsWrists,
          imageContent: values.handsWristsImage,
          injuryArea: InjuryArea.HandAndWrist
        });
      }

      if (InjuryImagesHelper.hasKneeData(values)) {
        injuryArea = InjuryImagesHelper.updateKneeInjuryImage(
          values,
          injuryArea,
          this.sex
        );
      }

      if (InjuryImagesHelper.hasFootData(values)) {
        injuryArea = InjuryImagesHelper.updateFootInjuryImage(
          values,
          injuryArea
        );
      }

      if (InjuryImagesHelper.hasHipData(values)) {
        injuryArea = InjuryImagesHelper.updateHipInjuryImage(
          values,
          injuryArea,
          this.sex
        );
      }
      if (InjuryImagesHelper.hasShoulderData(values)) {
        injuryArea = InjuryImagesHelper.updateShoulderInjuryImage(
          values,
          injuryArea,
          this.sex
        );
      }
      if (InjuryImagesHelper.hasElbowData(values)) {
        injuryArea = InjuryImagesHelper.updateElbowInjuryImage(
          values,
          injuryArea,
          this.sex
        );
      }
    }

    return injuryArea;
  };

  private getPhysioBodyStashedData = (values: SOTAPFormValues) => {
    const bodyData = toJS(this.clinicalRecord.clinicalData?.physioBody, {
      recurseEverything: true
    });

    const physioBody = bodyData ?? { images: [] };

    const frontCode =
      this.sex === Sex.Female
        ? FullBodyCodes.FemaleFront
        : FullBodyCodes.MaleFront;

    const backCode =
      this.sex === Sex.Female
        ? FullBodyCodes.FemaleBack
        : FullBodyCodes.MaleBack;

    const leftSideCode =
      this.sex === Sex.Female
        ? FullBodyCodes.FemaleLeftSide
        : FullBodyCodes.MaleLeftSide;

    const rightSideCode =
      this.sex === Sex.Female
        ? FullBodyCodes.FemaleRightSide
        : FullBodyCodes.MaleRightSide;

    const frontIndex = physioBody.images.findIndex(i => i.type === frontCode);
    const backIndex = physioBody.images.findIndex(i => i.type === backCode);
    const leftSideIndex = physioBody.images.findIndex(
      i => i.type === leftSideCode
    );

    const rightSideIndex = physioBody.images.findIndex(
      i => i.type === rightSideCode
    );

    if (frontIndex !== -1) {
      physioBody.images[frontIndex].content = values.frontBodyImage
        ? btoa(values.frontBodyImage)
        : undefined;
    } else if (values.frontBodyImage) {
      physioBody.images.push({
        type: frontCode,
        content: btoa(values.frontBodyImage)
      });
    }

    if (backIndex !== -1) {
      physioBody.images[backIndex].content = values.backBodyImage
        ? btoa(values.backBodyImage)
        : undefined;
    } else if (values.backBodyImage) {
      physioBody.images.push({
        type: backCode,
        content: btoa(values.backBodyImage)
      });
    }

    if (leftSideIndex !== -1) {
      physioBody.images[leftSideIndex].content = values.leftBodyImage
        ? btoa(values.leftBodyImage)
        : undefined;
    } else if (values.leftBodyImage) {
      physioBody.images.push({
        type: leftSideCode,
        content: btoa(values.leftBodyImage)
      });
    }

    if (rightSideIndex !== -1) {
      physioBody.images[rightSideIndex].content = values.rightBodyImage
        ? btoa(values.rightBodyImage)
        : undefined;
    } else if (values.rightBodyImage) {
      physioBody.images.push({
        type: rightSideCode,
        content: btoa(values.rightBodyImage)
      });
    }

    return physioBody;
  };

  public getStashedEncounterClinicalData = (
    values: SOTAPFormValues
  ): Partial<EncounterClinicalDataDto> => {
    // PHYSICAL ACTIVITIES
    const injuryDataOriginal = toJS(
      this.clinicalRecord.clinicalData?.physicalActivity,
      { recurseEverything: true }
    );

    const physicalActivity: PhysicalActivityClinicalDataDto = {
      ...injuryDataOriginal,
      activities: values.activities.map((x: PhysicalActivityFormDto) => {
        return {
          ...x,
          sessionsPerWeek: Number(x.sessionsPerWeek),
          sessionLength: Number(x.sessionLength)
        };
      })
    };

    // INJURY
    const injuryData = toJS(this.clinicalRecord.clinicalData?.injury, {
      recurseEverything: true
    });

    const injury: InjuryClinicalDataDto = {
      ...injuryData,
      dateOfSurgery: values.dateOfSurgery
        ? DateTime.jsDateToISODate(values.dateOfSurgery)
        : undefined
    };

    // TREATMENT PLAN
    const newTreatmentPlanData =
      this.multiProviderHelper.getNewTreatmentPlanData(values);

    const patientTreatmentPlan:
      | PatientTreatmentPlanClinicalDataDto
      | undefined = removeUndefinedProperties(newTreatmentPlanData);

    // INJURY AREA
    const injuryArea: InjuryAreaClinicalDataDto =
      this.getInjuryAreaStashedData(values);

    // TREATMENT AND EDUCATION
    const treatmentAndEducationData = toJS(
      this.clinicalRecord.clinicalData?.treatmentAndEducation,
      { recurseEverything: true }
    );

    const treatment = this.getTreatmentData(values.treatments);
    const tempTreatmentAndEducation:
      | TreatmentAndEducationClinicalDataDto
      | undefined = {
      ...treatmentAndEducationData,
      treatments:
        treatment !== undefined && treatment.length > 0 ? treatment : undefined,
      educationOptions: values.educationOptions,
      educationComment: values.educationComment,
      otherComment: values.otherComment,
      otherTreatments: values.hasOtherTreatments
        ? values.otherTreatments
        : undefined,
      primaryMeridians: values.primaryMeridians
    };

    const treatmentAndEducation = removeUndefinedProperties(
      tempTreatmentAndEducation
    );

    // PHYSIO MEDICATION HISTORY
    const physioMedicalHistoryData = toJS(
      this.clinicalRecord.clinicalData?.physioMedicalHistory,
      { recurseEverything: true }
    );

    const newPhysioMedicalHistoryData:
      | PhysioMedicalHistoryClinicalDataDto
      | undefined = {
      ...physioMedicalHistoryData,
      generalHealth: values.generalHealth,
      physioMedicalHistory: values.physioMedicalHistory,
      medications: values.medications,
      reactions: values.reactions,
      redFlags:
        values.redFlags && values.redFlags.length > 0
          ? values.redFlags
          : undefined,
      redFlagsAllClear:
        values.redFlagsAllClear !== undefined
          ? values.redFlagsAllClear
          : undefined,
      specialQuestions:
        values.specialQuestions && values.specialQuestions.length > 0
          ? values.specialQuestions
          : undefined,
      specialQuestionsAllClear:
        values.specialQuestionsAllClear !== undefined
          ? values.specialQuestionsAllClear
          : undefined,
      psychosocialFlags:
        values.psychosocialFlags && values.psychosocialFlags.length > 0
          ? values.psychosocialFlags
          : undefined,
      psychosocialFlagsAllClear:
        values.psychosocialFlagsAllClear !== undefined
          ? values.psychosocialFlagsAllClear
          : undefined,
      vBIs: values.vBIs && values.vBIs.length > 0 ? values.vBIs : undefined,
      vBIsAllClear:
        values.vBIsAllClear !== undefined ? values.vBIsAllClear : undefined,
      neuroSigns: values.neuroSigns,
      precautions: values.precautions
    };

    // ANALYSIS AND PLAN
    const physioMedicalHistory = removeUndefinedProperties(
      newPhysioMedicalHistoryData
    ) as PhysioMedicalHistoryClinicalDataDto | undefined;

    const analysisAndPlanData = toJS(
      this.clinicalRecord.clinicalData?.analysisAndPlan,
      { recurseEverything: true }
    );

    const newAnalysisAndPlanData: AnalysisAndPlanClinicalDataDto | undefined = {
      ...analysisAndPlanData,
      analysis: values.analysis,
      plan: values.planReview
    };

    const analysisAndPlan = removeUndefinedProperties(
      newAnalysisAndPlanData
    ) as AnalysisAndPlanClinicalDataDto | undefined;

    // OTHER MOVEMENTS
    const otherMovementsTemp = toJS(
      this.clinicalRecord.clinicalData?.otherMovements,
      { recurseEverything: true }
    );

    const newOtherMovements: OtherMovementsDto | undefined = {
      ...otherMovementsTemp,
      otherMovements: values.otherMovements
    };

    const otherMovements = removeUndefinedProperties(newOtherMovements) as
      | OtherMovementsDto
      | undefined;

    // POSTURE OR OBSERVATIONS
    const postureOrObservationsData = toJS(
      this.clinicalRecord.clinicalData?.postureOrObservations,
      { recurseEverything: true }
    );

    const newPostureOrObservations: PostureOrObservationsDto | undefined = {
      ...postureOrObservationsData,
      postureOrObservations: values.postureOrObservations
    };

    const postureOrObservations = removeUndefinedProperties(
      newPostureOrObservations
    ) as PostureOrObservationsDto | undefined;

    // PHYSIO BODY
    const physioBody = this.getPhysioBodyStashedData(values);

    // WORK HISTORY
    const workData = toJS(this.clinicalRecord.clinicalData?.workHistory, {
      recurseEverything: true
    });

    const workHistory: WorkHistoryClinicalDataDto = {
      ...workData,
      workType: values.workType
    };

    // PATIENT DEMOGRAPHIC UPDATE
    const pduData = toJS(
      this.clinicalRecord.clinicalData?.patientDemographicUpdate,
      { recurseEverything: true }
    );

    const patientDemographicUpdate: PatientDemographicUpdateClinicalDataDto = {
      ...pduData,
      occupation: values.occupation
    };

    // CURRENT INJURY
    const currentInjury = this.getCurrentInjuryStashedData(values);

    // CUSTOM TOOLS CLINICAL DATA
    const { customClinicalToolContext, customClinicalTool } =
      this.getCustomClinicalToolsStashedData(values);

    // REASON FOR VISIT
    const reasonForVisit = getReasonForVisit(
      values.primaryDiagnosis,
      this.clinicalRecord.clinicalData?.reasonForVisit
    );

    // DIAGNOSES
    const { diagnoses } = this.getDiagnosesStashedData(values);

    return {
      reasonForVisit,
      customClinicalToolContext,
      customClinicalTool,
      currentInjury,
      physioBody,
      postureOrObservations,
      otherMovements,
      analysisAndPlan,
      physioMedicalHistory,
      treatmentAndEducation,
      injuryArea,
      injury,
      patientTreatmentPlan,
      diagnoses,
      workHistory,
      patientDemographicUpdate,
      physicalActivity
    };
  };

  public submitData = async (values: SOTAPFormValues) => {
    const clinicalData: EncounterClinicalDataDto =
      this.getStashedEncounterClinicalData(values);

    // MERGE MEDICAL HISTORY DATA
    const medicalHistoryOriginal = toJS(
      this.clinicalRecord.clinicalData?.medicalHistory,
      { recurseEverything: true }
    );

    const isEditMedicalHistory = medicalHistoryOriginal?.medicalHistories?.find(
      item => item.episodeOfCareId === this.clinicalRecord.episodeOfCare?.id
    );

    const medicalHistory = getUpdatedMedicalHistory(
      clinicalData.diagnoses?.diagnoses ?? [],
      medicalHistoryOriginal,
      {
        episodeOfCareId: this.clinicalRecord.episodeOfCare?.id,
        diagnosisDate:
          this.clinicalRecord?.clinicalData?.currentInjury?.dateOfInjury,
        isAddItem: !isEditMedicalHistory,
        isFirstMultiRoleConsult: this.clinicalRecord.is1stMultiRoleConsult()
      }
    );

    const submitData = toJS({ ...clinicalData, medicalHistory });
    await this.clinicalRecord.saveClinicalData(submitData);
    this.clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
      type: PermanentClinicalTab.SOTAP
    });
  };
}
