import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  Accordion,
  AccordionItem,
  FontWeights,
  Spinner,
  Stack,
  Text,
  useResizeElementObserver
} from "@bps/fluent-ui";
import {
  BodyArea,
  InjuryAreaGroups
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import {
  BodyExaminationFormValues,
  bodyExamNameOf
} from "./BodyExaminationForm.types.ts";
import { DiagramEditor } from "./components/DiagramEditor.tsx";
import { DiagramPickerCard } from "./components/DiagramPickerCard.tsx";
import { ExaminationComments } from "./components/ExaminationComments.tsx";
import { SideAccordion } from "./components/side-accordion/SideAccordion.tsx";
import { useBodyExaminationContext } from "./context/BodyExaminationContext.ts";
import { DiagramWrapper } from "./DiagramWrapper.tsx";

export const BodyExaminationFields: React.FC = observer(() => {
  const { clinical } = useStores();

  const {
    showDiagramEditorArea,
    setImages,
    setBodyParts,
    bodyExamSessionState,
    currentBodyArea,
    setShowDiagramEditorArea,
    getBodyParts,
    getSpecialTests
  } = useBodyExaminationContext();

  const { clinicalRecord, isViewOnly } = usePatientRecordScreenContext();
  const { values } = useFormState<BodyExaminationFormValues>({
    subscription: { values: true }
  });

  // ⚠⚠⚠️ Needs to be here. Since by switching  Right/Left Tabs we unmount and mount motion fields,
  // for FinalForm it means we register and un-register them, but all fields MUST be register
  // to track their state changes, e.g. dirty state. Ilya S.
  useFieldArray(bodyExamNameOf("injuryAreaMotionAssessments"));

  const { mutators } = useForm<BodyExaminationFormValues>();

  const [compactedCanvas, setCanvasCompacted] = useState<boolean>(false);

  useEffect(() => {
    setBodyParts(
      bodyExamSessionState.selectedBodyParts || [
        ...getBodyParts({ ...values.imageValue })
      ]
    );
    if (values.imageValue) {
      setImages(values.imageValue, clinicalRecord.patient?.sex);
    }
    setShowDiagramEditorArea(
      !(
        bodyExamSessionState.bodyParts &&
        bodyExamSessionState.bodyParts.length === 0
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setElement, resizeObserverEntry, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const bodyAreaText = clinical.ref.bodyAreas.keyTextValues.find(
    x => x.key === currentBodyArea
  )?.text;

  const commentsModified = useMemo(() => {
    if (currentBodyArea) {
      const comments = values.examinationComments[currentBodyArea];

      if (
        comments &&
        (comments.length > 1 || comments[0].title || comments[0].comment)
      ) {
        return true;
      }
    }

    return false;
  }, [currentBodyArea, values.examinationComments]);

  const romModified = useMemo(() => {
    if (currentBodyArea) {
      const romItems = values.injuryAreaMotionAssessments.find(
        item => item.injuryArea === currentBodyArea
      );
      if (romItems && romItems.motionTypes && romItems.motionTypes.length > 0) {
        return romItems.motionTypes.some(
          motionType =>
            motionType.active !== "0°" || motionType.passive !== "0°"
        );
      }
    }
    return false;
  }, [currentBodyArea, values.injuryAreaMotionAssessments]);

  function renderDefaultCommentsAccordion() {
    return (
      <Accordion toggleIconType="folder">
        <AccordionItem
          title="Comments"
          extendedByDefault
          showTitleIcon={commentsModified}
          titleIconName="PageEdit"
        >
          <ExaminationComments mutators={mutators} isDermAndMyotomes={false} />
        </AccordionItem>
      </Accordion>
    );
  }

  const injuryAreaGroups = useMemo(
    () =>
      Array.from(clinical.ref.injuryAreaMotionTypeGroups.values)
        .filter(i => i.injuryAreas === currentBodyArea)
        .map(i => i.code as InjuryAreaGroups),
    [clinical.ref.injuryAreaMotionTypeGroups.values, currentBodyArea]
  );

  const shouldRenderSideAccordion =
    currentBodyArea !== BodyArea.Body && currentBodyArea !== BodyArea.Abdomen;

  return (
    <DiagramWrapper
      compactedCanvas={compactedCanvas}
      showDiagramEditorArea={showDiagramEditorArea}
      panelWidth={panelWidth}
      setElement={setElement}
      element={element}
    >
      {showDiagramEditorArea ? (
        <DiagramEditor
          onCompactButtonClicked={() => {
            setCanvasCompacted(!compactedCanvas);
          }}
          isCompacted={compactedCanvas}
          isViewOnly={
            isViewOnly || clinicalRecord.dischargeInProgressOrCompleted()
          }
          panelWidth={panelWidth}
          showQuickColours
        />
      ) : (
        <DiagramPickerCard />
      )}
      {showDiagramEditorArea && (
        <Stack styles={{ root: { height: "100%" } }}>
          <Text
            styles={{
              root: {
                marginTop: 6,
                marginBottom: 8,
                fontWeight: FontWeights.semibold
              }
            }}
          >
            {`${bodyAreaText} examination`}
          </Text>

          <Stack.Item basis={0} grow={1}>
            {shouldRenderSideAccordion && currentBodyArea && (
              <DataFetcher
                fetch={async () => {
                  const result = await getSpecialTests(currentBodyArea);

                  return result;
                }}
                fallback={<Spinner />}
                refetchId={currentBodyArea}
              >
                {({ specialTest, strengthTest }) => {
                  return (
                    <SideAccordion
                      area={currentBodyArea}
                      injuryAreaGroups={injuryAreaGroups}
                      commentsModified={commentsModified}
                      romModified={romModified}
                      specialTest={specialTest}
                      strengthTest={strengthTest}
                      panelWidth={panelWidth}
                      specialResponseItems={values.specialTestResponseItems}
                      strengthResponseItems={values.strengthTestResponseItems}
                    />
                  );
                }}
              </DataFetcher>
            )}

            {!shouldRenderSideAccordion && renderDefaultCommentsAccordion()}
          </Stack.Item>
        </Stack>
      )}
    </DiagramWrapper>
  );
});
