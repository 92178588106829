import { FC } from "react";

import {
  applyOpacityToHexColor,
  FontWeights,
  ICalloutProps,
  IconButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";

interface BookingEventCalloutHeaderProps
  extends Pick<ICalloutProps, "onDismiss"> {
  eventTypeText: string;
}

export const BookingEventCalloutHeader: FC<BookingEventCalloutHeaderProps> = ({
  onDismiss,
  eventTypeText
}) => {
  const theme = useTheme();

  const hoverColor = applyOpacityToHexColor(theme.palette.neutralLight, 25);

  const hoverActiveColor = {
    color: theme.palette.neutralLighterAlt,
    background: hoverColor
  };
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={(prop, theme) => ({
        root: {
          padding: "8px 8px 8px 16px",
          background: theme.semanticColors.mainHeaderBackground,
          color: theme.palette.white,
          fontWeight: FontWeights.semibold
        }
      })}
    >
      {eventTypeText === "Unavailable"
        ? eventTypeText
        : `${eventTypeText} information`}

      <IconButton
        iconProps={{
          iconName: "Cancel"
        }}
        styles={{
          root: {
            color: "inherit"
          },
          rootHovered: hoverActiveColor,
          rootPressed: hoverActiveColor
        }}
        onClick={() => onDismiss && onDismiss()}
      />
    </Stack>
  );
};
