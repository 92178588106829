import { Country } from "@libs/enums/country.enum.ts";
import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { RefCountry } from "@stores/core/models/Address.ts";

export class AddressValidator extends Validator<Omit<AddressFieldValue, "id">> {
  isRequired: boolean;
  constructor(options: {
    countries: readonly RefCountry[];
    country: Country;
    isRequired?: boolean;
    emptyOverride?: (addr?: AddressFieldValue) => boolean;
  }) {
    super();
    const { countries, isRequired, emptyOverride, country } = options;
    this.isRequired = !!isRequired;

    const isPostCodeRequired = country !== Country.NewZealand;

    const empty = (x?: AddressFieldValue) => {
      if (emptyOverride) {
        return emptyOverride(x);
      }
      return x
        ? !x.street1 &&
            !x.street2 &&
            !x.suburb &&
            !x.postCode &&
            !x.state &&
            !x.city
        : true;
    };

    const requiresStateCondition = (val: any, addr: AddressFieldValue) =>
      !empty(addr) &&
      !!countries.find(c => c.code === addr.country && c.isStateRequired);

    const requiresCityCondition = (val: any, addr: AddressFieldValue) => {
      return (
        !empty(addr) &&
        !!countries.find(c => c.code === addr.country && !c.isStateRequired)
      );
    };

    this.forField("street1", [
      predicate(
        (val: string, addr?: AddressFieldValue) =>
          !empty(addr) || this.isRequired,
        required()
      ),
      maxLength(100)
    ]);
    this.forField("street2", maxLength(100));
    this.forField("suburb", [maxLength(60)]);
    this.forField("postCode", [
      predicate(
        (val: string, addr?: AddressFieldValue) =>
          isPostCodeRequired && (!empty(addr) || this.isRequired),
        required()
      ),
      maxLength(10)
    ]);
    this.forField(
      "country",
      predicate(
        (val, addr?: AddressFieldValue) => !empty(addr) || this.isRequired,
        required()
      )
    );
    this.forField("city", [
      predicate(requiresCityCondition, required()),
      maxLength(60)
    ]);
    this.forField("state", predicate(requiresStateCondition, required()));
  }
}
