import {
  CorrespondenceType,
  CorrespondenceVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

export enum MessageBarLabel {
  noVisitsFound = "No consults for the selected claim"
}

export type DocumentWriterMergeFormDialogProps = Pick<
  SubmissionFormProps<DocumentMergeFieldsFormValues>,
  "onSubmit"
> & {
  onCancel: any;
  activeClaimId?: string;
  launchFrom: LaunchFrom;
  patientId?: string;
  documentSettings?: OrgUnitDocumentSettingsDto;
};

export interface DocumentMergeFieldsFormValues {
  toContactId?: string;
  fromDate?: Date;
  toDate?: Date;
  selectedTemplate?: CorrespondenceType;
  claimId?: string;
  visits?: string[];
  clinicalTools?: string[];
  episodeOfCareId?: string;
  documentTitle?: string;
  providerId?: string;
  patientId?: string;
  usePracticeLetterhead?: boolean;
  documentStatus?: string;
  dischargeBusinessCode?: string;
  visibility?: CorrespondenceVisibility;
  shortcut?: string;
  content?: string;
}
