import {
  InvoiceItemReferenceDto,
  PaymentStatuses,
  WriteOffStatus
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { getAllocation } from "@modules/billing/billing.utils.ts";
import { AllocationsListItem } from "@modules/billing/screens/shared-components/AllocationList/types.ts";

const getStatus = (status?: string): PaymentStatuses | undefined => {
  switch (status) {
    case WriteOffStatus.fully:
      return PaymentStatuses.paid;
    case WriteOffStatus.part:
      return PaymentStatuses.part;
    default:
      return undefined;
  }
};

export const getWriteOffAllocations = (
  item: InvoiceItemReferenceDto
): AllocationsListItem => ({
  ...getAllocation(item),
  status: getStatus(item.status)
});
