import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Dialog,
  FontSizes,
  FontWeights,
  Heading,
  LabeledText,
  NoDataTile,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { CodedFieldClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import {
  PatientRecordScreenContext,
  usePatientRecordScreenContext
} from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { encounterExistsWrapperStyles } from "./EncounterExistsPrompt.styles.ts";
import {
  EncounterExistsPromptContext,
  useEncounterExistsPromptContext
} from "./models/EncounterExistsPromptContext.ts";
import { EncounterExistsPromptHelper } from "./models/EncounterExistsPromptHelper.ts";
import { OpenEncounterDetailList } from "./OpenEncounterDetailList.tsx";
import { StructuredNoteText } from "./StructureNoteText.tsx";
import { getEncounterDayString } from "./utils.ts";

const EncounterExistsPromptBase: React.FC<{
  reasons: CodedFieldClinicalDataItemDto[];
  openEncounters: Encounter[];
}> = observer(({ reasons, openEncounters }) => {
  const { routing, clinical } = useStores();
  const helper = usePatientRecordScreenContext();

  const { clinicalRecord, patientId } = helper;

  const { palette } = useTheme();
  const { selectedEncounterId } = useEncounterExistsPromptContext();

  const encounter = clinicalRecord.openEncounter;

  if (!encounter) {
    return null;
  }

  const heading = (
    <Heading variant="modal-heading">
      {`There is already an open consult with ${
        clinicalRecord.patient?.name || ""
      }`}
    </Heading>
  );

  const encounterDateTime = encounter.startDateTime;

  const dayString = getEncounterDayString(encounterDateTime);

  const timeString = encounterDateTime.toFormat("hh:mma");

  const showMultipleConsults = openEncounters.length > 1;

  const onCancel = () => {
    return clinical.closeRecord(clinicalRecord.id, encounter.id, true);
  };

  const handleConfirm = () => {
    clinicalRecord.setShowEncounterExistsPrompt(false);
    helper.setShowEncounterExistsPrompt(false);

    routing.replaceRetainingState(
      routes.records.encounter.path({
        id: patientId,
        encounterId: selectedEncounterId || encounter.id
      })
    );
  };

  const hasClinicalNotes =
    clinicalRecord.mainFreeText !== "" ||
    clinicalRecord.notesHeadings.some(x => x.freeText !== "") ||
    clinicalRecord.notesHeadings.some(
      x => x.structuredNotes && x.structuredNotes?.length > 0
    );

  const clinicalNotes = !hasClinicalNotes ? (
    <NoDataTile
      textProps={{ text: "None recorded" }}
      styles={{ root: { minHeight: 28, marginTop: 8 } }}
      linkProps={{ hidden: true }}
      showBoxShadow={false}
      greyView={true}
    />
  ) : (
    <Stack
      styles={{
        root: {
          ...encounterExistsWrapperStyles,
          border: `1px solid ${palette.neutralTertiary}`,
          borderRadius: 2,
          padding: 5,
          marginTop: 4,
          maxHeight: 350
        }
      }}
    >
      <StructuredNoteText
        mainFreeText={clinicalRecord.mainFreeText}
        notesHeadings={clinicalRecord.notesHeadings}
      />
    </Stack>
  );

  return (
    <Dialog
      {...dataAttribute(DataAttributes.Element, "encounter-exists-prompt")}
      hidden={false}
      dialogContentProps={{
        title: heading,
        showCloseButton: false
      }}
      minWidth={480}
      modalProps={{
        styles: {
          scrollableContent: {
            "> div": { ...encounterExistsWrapperStyles, height: "100%" }
          }
        }
      }}
      styles={{
        root: {
          ".ms-Dialog-inner": encounterExistsWrapperStyles,
          ".ms-Dialog-content": encounterExistsWrapperStyles
        }
      }}
    >
      {showMultipleConsults ? (
        <LabeledText
          noSemicolon
          label="Currently open consults"
          styles={{
            label: {
              fontSize: FontSizes.size14,
              fontWeight: FontWeights.bold,
              paddingBottom: 4,
              color: palette.black
            }
          }}
        >
          <Stack
            tokens={{ childrenGap: 8 }}
            styles={{
              root: {
                padding: "4px 0",
                maxHeight: 300,
                overflowY: "auto",
                border: `1px solid ${palette.neutralTertiary}`
              }
            }}
          >
            <OpenEncounterDetailList openEncounters={openEncounters} />
          </Stack>
        </LabeledText>
      ) : (
        <Stack
          tokens={{ childrenGap: 16 }}
          styles={{ root: encounterExistsWrapperStyles }}
        >
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Text bold>Date</Text>
            <Text>{`${dayString} at ${timeString}`}</Text>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Text bold>Reason</Text>
            <Text>
              {reasons?.map(reason => reason.originalText).join(", ")}
            </Text>
          </Stack>
          <Stack.Item styles={{ root: encounterExistsWrapperStyles }}>
            <Text bold>Notes</Text>
            {clinicalNotes}
          </Stack.Item>
        </Stack>
      )}
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: 12 }}
        styles={{
          root: { marginTop: 40, alignSelf: "center", width: "100%" }
        }}
      >
        <Stack horizontal tokens={{ childrenGap: 12 }}>
          <DefaultButton
            primary
            {...dataAttribute(
              DataAttributes.Element,
              "close-encounter-exists-prompt-button"
            )}
            disabled={showMultipleConsults && !selectedEncounterId}
            onClick={handleConfirm}
          >
            Continue consult
          </DefaultButton>
          <DefaultButton
            {...dataAttribute(
              DataAttributes.Element,
              "cancel-encounter-exists-prompt-button"
            )}
            onClick={onCancel}
          >
            Cancel
          </DefaultButton>
        </Stack>
      </Stack>
    </Dialog>
  );
});

export const EncounterExistsPrompt: React.FC = observer(props => {
  const { clinicalRecord, showEncounterExistsPrompt } = useContext(
    PatientRecordScreenContext
  );

  const root = useStores();
  if (!showEncounterExistsPrompt) {
    return null;
  }

  const reasons = clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits;
  const helper = new EncounterExistsPromptHelper(
    root,
    clinicalRecord.openEncounter?.id
  );

  return (
    <DataFetcher
      refetchId={clinicalRecord.currentEncounterId}
      fetch={helper.loadOpenedEncounters}
    >
      {openEncounters => {
        return (
          <EncounterExistsPromptContext.Provider value={helper}>
            <EncounterExistsPromptBase
              {...props}
              reasons={!reasons || reasons.length === 0 ? [] : reasons}
              openEncounters={openEncounters}
            />
          </EncounterExistsPromptContext.Provider>
        );
      }}
    </DataFetcher>
  );
});
