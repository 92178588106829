import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext, useRef } from "react";

import {
  FontIcon,
  Heading,
  IPivotItemProps,
  Pivot,
  PivotItem,
  useTheme
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { SideNavContent } from "../SideNavContent.tsx";
import { ManagementList } from "./ManagementList.tsx";
import {
  TandMSectionKeys,
  TandMSectionText
} from "./TreatmentAndManagement.Types.ts";
import { TreatmentAndManagementSidePanelContext } from "./TreatmentAndManagementSidePanelContext.ts";
import { TreatmentAndManagementSidePanelHelper } from "./TreatmentAndManagementSidePanelHelper.ts";
import { TreatmentList } from "./TreatmentList.tsx";

interface TreatmentAndManagementSidePanelProps {
  clinicalRecord: ClinicalRecord;
}

export const TreatmentAndManagementSidePanelBase: FunctionComponent<TreatmentAndManagementSidePanelProps> =
  observer(({ clinicalRecord }) => {
    const theme = useTheme();
    const {
      pageSelected,
      handleViewClick,
      onCreateTreatmentClick,
      onCreateManagementClick
    } = useContext(TreatmentAndManagementSidePanelContext);

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    const renderItemLink = (
      onLinkClick: () => void,
      link?: IPivotItemProps,
      defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
    ) => {
      if (defaultRenderer)
        return (
          <>
            {!isViewOnlyOrDischarged && (
              <FontIcon
                iconName="Add"
                onClick={onLinkClick}
                styles={{
                  root: {
                    marginRight: 8,
                    fontSize: 16,
                    color: theme.palette.themePrimary
                  }
                }}
              />
            )}
            {defaultRenderer({ ...link, itemIcon: undefined })}
          </>
        );
      else return null;
    };
    return (
      <SideNavContent>
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          Management & Plan
        </Heading>
        <Pivot
          onLinkClick={handleViewClick}
          selectedKey={pageSelected}
          styles={{ root: { marginBottom: 4 } }}
        >
          <PivotItem
            headerText={TandMSectionText.Management}
            itemKey={TandMSectionKeys.Management}
            onRenderItemLink={(link, defaultRenderer) =>
              renderItemLink(onCreateTreatmentClick, link, defaultRenderer)
            }
          />
          <PivotItem
            headerText={TandMSectionText.Plan}
            itemKey={TandMSectionKeys.Plan}
            itemIcon="Add"
            onRenderItemLink={(link, defaultRenderer) =>
              renderItemLink(onCreateManagementClick, link, defaultRenderer)
            }
          />
        </Pivot>

        {pageSelected === TandMSectionKeys.Management && (
          <TreatmentList clinicalRecord={clinicalRecord} />
        )}
        {pageSelected === TandMSectionKeys.Plan && (
          <ManagementList clinicalRecord={clinicalRecord} />
        )}
      </SideNavContent>
    );
  });

export const TreatmentAndManagementSidePanel: FunctionComponent<
  TreatmentAndManagementSidePanelProps
> = ({ clinicalRecord }) => {
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
  const helper = useRef(
    new TreatmentAndManagementSidePanelHelper(
      clinicalRecord,
      useStores(),
      isViewOnlyOrDischarged
    )
  );

  return (
    <TreatmentAndManagementSidePanelContext.Provider value={helper.current}>
      <TreatmentAndManagementSidePanelBase clinicalRecord={clinicalRecord} />
    </TreatmentAndManagementSidePanelContext.Provider>
  );
};
