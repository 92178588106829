import { FC } from "react";
import { useForm } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";

const toggleLabel = (
  <Heading hasAsterisk>
    Has the client had ACC related surgery on this claim?
  </Heading>
);

export const RelatedSurgeryFields: FC = () => {
  const form = useForm<ClaimAdjustmentFormValues>();
  return (
    <Stack>
      <YesNoToggleField
        label={toggleLabel}
        name={claimAdjustmentFormNameOf("accRelatedSurgery")}
      />
      <FieldSpy
        name={claimAdjustmentFormNameOf("accRelatedSurgery")}
        onChange={(_value, values) => {
          if (values && values.mostRecentSurgeryDate) {
            form.change(
              claimAdjustmentFormNameOf("mostRecentSurgeryDate"),
              undefined
            );
          }
        }}
      />
      <FieldCondition
        when={claimAdjustmentFormNameOf("accRelatedSurgery")}
        is={true}
      >
        <DatePickerField
          name={claimAdjustmentFormNameOf("mostRecentSurgeryDate")}
          required
          maxDate={DateTime.jsDateNow()}
          label="Date of most recent surgery related to this claim"
        />
      </FieldCondition>
    </Stack>
  );
};
