import React, { FunctionComponent } from "react";
import { Field, useField, useForm } from "react-final-form";

import {
  CollapsibleCard,
  CommandButton,
  DetailsList,
  FontSizes,
  FontWeights,
  Heading,
  IColumn,
  IContextualMenuItem,
  IToggleStyles,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  Toggle
} from "@bps/fluent-ui";
import {
  ClinicalDataType,
  MeasurementDto,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalToolResultOpener } from "@modules/clinical/screens/patient-record/components/clinical-tools/clinical-tool-list/ClinicalToolResultOpener.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { SeriesQuestionAnswers } from "../../clinical-tools/psfs/PSFSTrendDialog.types.ts";
import { SotapMskId, SotapMskLabel } from "../SOTAP.constants.ts";
import { CustomToolFieldData } from "../SOTAP.types.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";
import { CustomClinicalToolList } from "./CustomClinicalToolList.tsx";
import { OutcomeMeasuresCardModel } from "./OutcomeMeasuresCardModel.ts";
import { getEmptyTool } from "./utils.ts";

const OutcomeMeasuresCardBase: React.FC<{
  model: OutcomeMeasuresCardModel;
  hideAddToolsButton: boolean;
  isOutcomeMeasureViewOnly: boolean;
  episodeOfCareId?: string;
}> = ({
  model,
  hideAddToolsButton,
  isOutcomeMeasureViewOnly,
  episodeOfCareId
}) => {
  const { clinical } = useStores();
  const { clinicalRecord, isViewOnlyOrDischarged: isContextBasedViewOnly } =
    usePatientRecordScreenContext();

  const isViewOnly = isContextBasedViewOnly || isOutcomeMeasureViewOnly;
  const encounterId = clinicalRecord.openEncounter?.id;
  const {
    input: { value: filledClinicalDataType = [] }
  } = useField<ClinicalDataType[]>(sotapNameOf("filledClinicalDataType"), {
    subscription: { value: true }
  });

  const tab = clinical.ui.tabs.currentPatientRecordTab;
  const form = useForm<SOTAPFormValues>();

  const clinicalToolResultOpener = new ClinicalToolResultOpener(
    clinical,
    clinicalRecord
  );

  const toggleClinicalTool = (type: ClinicalDataType, checked?: boolean) => {
    if (type === ClinicalDataType.CustomClinicalTool) {
      const customTools = form.getState().values.customClinicalTools;

      if (!customTools || customTools.length === 0) {
        const customClinicalTools: CustomToolFieldData[] = [];
        customClinicalTools.push(getEmptyTool(customClinicalTools.length ?? 0));

        form.change(sotapNameOf("customClinicalTools"), customClinicalTools);
      } else {
        if (!checked) {
          const validTools = customTools.filter(
            customTool =>
              customTool.thisEncounterResult !== undefined &&
              customTool.name &&
              customTool.name?.length > 0
          );
          form.change(sotapNameOf("customClinicalTools"), validTools);
        }
      }
    }

    const index = addToolsMenuItems.findIndex(i => i.key === type);
    if (index !== -1) {
      addToolsMenuItems[index].checked = checked ?? false;
    }

    const updatedList: ClinicalDataType[] = addToolsMenuItems
      .filter(i => i.checked)
      .map(i => i.key as ClinicalDataType);

    form.change(sotapNameOf("filledClinicalDataType"), updatedList);

    tab?.setCheckedOutcomeMeasures(updatedList);
  };

  const toggleStyles: Partial<IToggleStyles> = {
    root: { margin: 0 },
    label: { cursor: "pointer" }
  };

  const addToolsMenuItems: IContextualMenuItem[] = [
    {
      key: ClinicalDataType.PSFS,
      text: "PSFS",
      onRenderContent: prop => (
        <Toggle
          automationAttribute="psfs"
          label={<Text>{prop.item.text}</Text>}
          inlineLabel
          disabled
          defaultChecked
          styles={toggleStyles}
        />
      ),
      checked: filledClinicalDataType?.includes(ClinicalDataType.PSFS),
      onClick: ev => {
        ev?.preventDefault();
      }
    },
    {
      key: ClinicalDataType.NPRS,
      text: "NPRS",
      onRenderContent: prop => (
        <Toggle
          automationAttribute="nprs"
          label={<Text>{prop.item.text}</Text>}
          inlineLabel
          disabled
          defaultChecked
          styles={toggleStyles}
        />
      ),
      checked: filledClinicalDataType?.includes(ClinicalDataType.NPRS),
      onClick: ev => {
        ev?.preventDefault();
      }
    },
    {
      key: ClinicalDataType.DASH,
      text: "DASH 11",
      onClick: ev => {
        ev?.preventDefault();
        toggleClinicalTool(
          ClinicalDataType.DASH,
          !filledClinicalDataType?.includes(ClinicalDataType.DASH)
        );
      },
      onRenderContent: prop => (
        <Toggle
          automationAttribute="dash-eleven"
          label={<Text>{prop.item.text}</Text>}
          inlineLabel
          checked={filledClinicalDataType?.includes(ClinicalDataType.DASH)}
          styles={toggleStyles}
          disabled={model.filledTools.includes(ClinicalDataType.DASH)}
        />
      ),
      checked: filledClinicalDataType?.includes(ClinicalDataType.DASH)
    },
    {
      key: ClinicalDataType.GRCS,
      text: "GRCS",
      onClick: ev => {
        ev?.preventDefault();
        toggleClinicalTool(
          ClinicalDataType.GRCS,
          !filledClinicalDataType?.includes(ClinicalDataType.GRCS)
        );
      },
      onRenderContent: prop => (
        <Toggle
          automationAttribute="grcs"
          label={<Text>{prop.item.text}</Text>}
          inlineLabel
          checked={filledClinicalDataType?.includes(ClinicalDataType.GRCS)}
          styles={toggleStyles}
          disabled={model.filledTools.includes(ClinicalDataType.GRCS)}
        />
      ),
      checked: filledClinicalDataType?.includes(ClinicalDataType.GRCS)
    },
    {
      key: ClinicalDataType.OREBRO,
      text: "OREBRO",
      onClick: ev => {
        ev?.preventDefault();
        toggleClinicalTool(
          ClinicalDataType.OREBRO,
          !filledClinicalDataType?.includes(ClinicalDataType.OREBRO)
        );
      },
      onRenderContent: prop => (
        <Toggle
          automationAttribute="orebro"
          label={<Text>{prop.item.text}</Text>}
          inlineLabel
          checked={filledClinicalDataType?.includes(ClinicalDataType.OREBRO)}
          styles={toggleStyles}
          disabled={model.filledTools.includes(ClinicalDataType.OREBRO)}
        />
      ),
      checked: filledClinicalDataType?.includes(ClinicalDataType.OREBRO)
    },
    {
      key: ClinicalDataType.CustomClinicalTool,
      text: "Custom tool",
      onClick: ev => {
        ev?.preventDefault();
        toggleClinicalTool(
          ClinicalDataType.CustomClinicalTool,
          !filledClinicalDataType?.includes(ClinicalDataType.CustomClinicalTool)
        );
      },
      onRenderContent: prop => (
        <Toggle
          automationAttribute="custom-tool"
          label={<Text>{prop.item.text}</Text>}
          inlineLabel
          checked={filledClinicalDataType?.includes(
            ClinicalDataType.CustomClinicalTool
          )}
          disabled={model.filledTools.includes(
            ClinicalDataType.CustomClinicalTool
          )}
          styles={toggleStyles}
        />
      ),
      checked: filledClinicalDataType?.includes(
        ClinicalDataType.CustomClinicalTool
      )
    }
  ];

  const addToolsButton =
    !hideAddToolsButton && !isViewOnly ? (
      <CommandButton
        iconProps={{ iconName: "Add" }}
        text="Add more tools"
        menuProps={{
          items: addToolsMenuItems,
          hidden: false,
          calloutProps: { hideOverflow: true }
        }}
      />
    ) : null;

  return (
    <div id={SotapMskId.OutcomeMeasure}>
      <CollapsibleCard
        heading={SotapMskLabel.OutcomeMeasure}
        headingLevel="section-heading"
        iconName="Toolbox"
        openOnRender
        button={addToolsButton}
      >
        <Field name="filledClinicalDataType" render={() => null} />
        <Stack tokens={{ childrenGap: 8 }}>
          {filledClinicalDataType &&
            filledClinicalDataType
              .filter(t => t !== ClinicalDataType.CustomClinicalTool)
              .map(tool => {
                const item = addToolsMenuItems.find(i => i.key === tool);

                if (!item) return null;

                return (
                  <DataFetcher<{
                    questionnaire: QuestionnaireDto | undefined;
                    answersArray: SeriesQuestionAnswers[];
                    columns: IColumn[];
                    measurement: MeasurementDto | undefined;
                    contextId: string | undefined;
                  }>
                    key={item.key}
                    fetch={async () =>
                      await model.getTrendData(
                        item.key as ClinicalDataType,
                        episodeOfCareId
                      )
                    }
                    fallback={<Spinner />}
                  >
                    {({ answersArray, columns, measurement, contextId }) => {
                      if (isViewOnly && !answersArray && !columns) {
                        return null;
                      }
                      if (answersArray && answersArray.length < 0) {
                      }
                      return (
                        <Fieldset
                          frame
                          styles={{
                            root: {
                              flexGrow: 1,
                              paddingLeft: 10,
                              paddingRight: 10
                            }
                          }}
                        >
                          <Stack
                            styles={{
                              root: { margin: "16px 24px", width: "inherit" }
                            }}
                          >
                            <Stack horizontal horizontalAlign="space-between">
                              <Heading variant="section-heading-light">
                                {item.text}
                              </Heading>
                              <Navigate
                                disabled={isViewOnly}
                                onClick={() => {
                                  if (measurement) {
                                    clinicalToolResultOpener.openResultFromLink(
                                      measurement
                                    );
                                  } else if (contextId) {
                                    clinicalToolResultOpener.addNewSeriesItemFromLink(
                                      item.key as ClinicalDataType,
                                      contextId
                                    );
                                  } else {
                                    clinicalToolResultOpener.openClinicalToolFromDropDown(
                                      item.key as ClinicalDataType
                                    );
                                  }
                                }}
                              >
                                {measurement &&
                                measurement.encounterId === encounterId
                                  ? "Edit today's entry"
                                  : "Record entry"}
                              </Navigate>
                            </Stack>

                            {answersArray &&
                              answersArray.length > 0 &&
                              columns && (
                                <Stack
                                  styles={{
                                    root: {
                                      overflowX: "auto",
                                      flexGrow: 1
                                    }
                                  }}
                                  data-is-scrollable="true"
                                >
                                  <DetailsList
                                    items={answersArray}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    onRenderRow={(props, defaultRender) => {
                                      if (!props || !defaultRender) {
                                        return null;
                                      }

                                      const averageBold =
                                        props.item.questionText ===
                                        "Average scores"
                                          ? FontWeights.bold
                                          : FontWeights.regular;
                                      return (
                                        <>
                                          {defaultRender!({
                                            ...props,
                                            styles: {
                                              root: [
                                                {
                                                  lineHeight: "16px",
                                                  fontSize: FontSizes.small,
                                                  fontWeight: averageBold,
                                                  borderBottom: "none"
                                                }
                                              ]
                                            }
                                          })}
                                        </>
                                      );
                                    }}
                                  />
                                </Stack>
                              )}
                          </Stack>
                        </Fieldset>
                      );
                    }}
                  </DataFetcher>
                );
              })}{" "}
          {filledClinicalDataType?.includes(
            ClinicalDataType.CustomClinicalTool
          ) && <CustomClinicalToolList />}
        </Stack>
      </CollapsibleCard>
    </div>
  );
};

export interface IOutcomeMeasuresCardProps {
  hideAddToolsButton?: boolean | undefined;
  isViewOnly?: boolean;
  episodeOfCareId?: string;
}

export const OutcomeMeasuresCard: FunctionComponent<
  IOutcomeMeasuresCardProps
> = ({ hideAddToolsButton, isViewOnly, episodeOfCareId }) => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const fetch = async (): Promise<OutcomeMeasuresCardModel> => {
    const model = new OutcomeMeasuresCardModel(clinicalRecord);
    await clinicalRecord.loadMeasurementData();
    return model;
  };

  return (
    <DataFetcher fetch={fetch}>
      {model => (
        <OutcomeMeasuresCardBase
          model={model}
          hideAddToolsButton={Boolean(hideAddToolsButton)}
          isOutcomeMeasureViewOnly={isViewOnly || false}
          episodeOfCareId={episodeOfCareId}
        />
      )}
    </DataFetcher>
  );
};
