import { action, observable } from "mobx";

import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class IntegrationScreenHelper {
  constructor(private root: IRootStore) {}
  @observable
  public showPhysitrackProviderList: boolean = false;
  @observable
  public numberOfActivatedProviders: number = 0;
  @action
  public setNumberOfActivatedProviders = (value: number) => {
    this.numberOfActivatedProviders = value;
  };
  @action
  public setShowPhysitrackProviderList = (value: boolean) => {
    this.showPhysitrackProviderList = value;
  };
}
