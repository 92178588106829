import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export const useShowContactDetails = (contact: Contact) => {
  const {
    practice: {
      ui: { showContactDetails }
    }
  } = useStores();

  const { id } = contact;

  return () => {
    showContactDetails(id);
  };
};
