import { DateTime } from "@bps/utils";
import { Frequency } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  greaterThan,
  integer,
  isDateBeforeField,
  Length,
  lessThan,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";

import {
  UserReservesFormValues,
  UserReservesValues
} from "./UserReserves.types.ts";

export class UserReservesFormValidator extends Validator<UserReservesFormValues> {
  constructor() {
    super();
    const userReservesFormValidator = new UserReservesValidator();
    this.forArrayField(
      "currentUserReserves",
      userReservesFormValidator.validate
    );
    this.forArrayField(
      "previousUserReserves",
      userReservesFormValidator.validate
    );
  }
}

const isStartAndEndTimeNonEmpty = (val: string, values: UserReservesValues) =>
  !!(values.endTime && values.startTime);

export class UserReservesValidator extends Validator<UserReservesValues> {
  constructor() {
    super();
    this.forField("startTime", required());
    this.forField("endScheduleType", [required()]);
    this.forField("startDate", [required()]);
    this.forField("purpose", maxLength(Length.long));
    this.forField("frequency", required());

    this.forField("endTime", [
      required(),
      predicate(
        isStartAndEndTimeNonEmpty,
        (val, values: UserReservesValues) => {
          const v1 = DateTime.fromParsedTimeString(val);
          const v2 = DateTime.fromParsedTimeString(values.startTime);

          return v1 && v2 && v1 < v2
            ? "End time must not be before start time."
            : undefined;
        },
        (val, values: UserReservesValues) =>
          val === values.startTime
            ? "End time must not be equal to start time."
            : undefined
      )
    ]);

    this.forField("interval", [
      predicate(x => x !== 0, required()), // treat zero as value entered
      integer(),
      greaterThan(0),
      lessThan(100)
    ]);

    this.forField("count", [
      predicate(
        (val: string, schedule: UserReservesValues) =>
          schedule.endScheduleType === EndScheduleType.After &&
          schedule.count !== 0,
        required()
      ),
      integer(),
      greaterThan(0),
      lessThan(100)
    ]);

    this.forField("until", [
      predicate(
        (val, schedule: UserReservesValues) =>
          schedule.endScheduleType === EndScheduleType.OnDate,
        required()
      )
    ]);

    this.forField("until", [
      predicate(
        (_val, schedule: UserReservesValues) =>
          schedule.endScheduleType === EndScheduleType.OnDate,
        isDateBeforeField(
          ValidationMessages.endDateBeforeStartDate,
          "startDate"
        )
      )
    ]);

    this.forField("endTime", [
      predicate(
        (val: string, schedule: UserReservesValues) =>
          schedule.endTime && schedule.startTime
            ? schedule.endTime === schedule.startTime
            : false,
        () => "End time must not be equal to start time."
      )
    ]);

    this.forField("dayRecur", [
      predicate(
        (val, schedule: UserReservesValues) =>
          schedule.frequency === Frequency.Week,
        required(ValidationMessages.dayRecurrences)
      )
    ]);

    this.forField("monthYearRecurrence", [
      predicate(
        (val, schedule: UserReservesValues) =>
          schedule.frequency === Frequency.Month,
        required(ValidationMessages.monthRecurrences)
      )
    ]);

    this.forField("monthYearRecurrence", [
      predicate(
        (val, schedule: UserReservesValues) =>
          schedule.frequency === Frequency.Year,
        required(ValidationMessages.yearRecurrences)
      )
    ]);
  }
}
