import { QuickColoursSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { QuickColoursDialogValues } from "./QuickColoursDialog.tsx";

export class QuickColoursSettingValidator extends Validator<QuickColoursSettingDto> {
  constructor() {
    super();
    this.forField("colourName", [required()]);
    this.forField("colourCode", [required()]);
  }
}

export class QuickColoursDialogValidator extends Validator<QuickColoursDialogValues> {
  constructor() {
    super();
    const editQuickAccessSettingValidator = new QuickColoursSettingValidator();
    this.forArrayField("quickColoursSettings", values => {
      return editQuickAccessSettingValidator.validate(values);
    });
  }
}
