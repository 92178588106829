import { reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import { Stack } from "@bps/fluent-ui";
import {
  CorrespondenceStatus,
  CorrespondenceVisibility,
  DocumentContentType,
  DocumentCreateOptions,
  DocumentEnum,
  DocumentSource,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { getNewMetadata } from "@modules/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { useDocumentWriterContext } from "../context/DocumentWriterContext.ts";
import { SubmitAction } from "./DocumentWriterDetails.types.ts";
import { DocumentWriterDetailsFields } from "./DocumentWriterDetailsFields.tsx";
import { DocumentWriterValues } from "./DocumentWriterValues.ts";
import { initialiseDocEditor } from "./utils.ts";

interface DocumentWriterDetailsProps {
  encounterId?: string;
}

const DocumentWriterDetailsComponent: React.FunctionComponent<DocumentWriterDetailsProps> =
  observer(({ encounterId }) => {
    const { correspondence, clinical, core } = useStores();

    const model = useDocumentWriterContext();

    const documentWriterEncounterId = useRef<string | undefined>(encounterId);
    const type = useRef<string>(SubmitAction.Save);

    const docEditor = useRef<DocumentEditor>();

    const beforeUnloadHandler = (evt: BeforeUnloadEvent) => {
      if (model.isDirty) {
        evt.preventDefault();
      }
    };

    useEffect(() => {
      window.addEventListener("beforeunload", beforeUnloadHandler, {
        capture: true
      });
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler, {
          capture: true
        });
      };
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      initialiseDocEditor(model.initialValues?.documentContent, docEditor);
    }, [model.initialValues?.documentContent]);

    const correspondenceListRefreshKeyReaction = reaction(
      () => correspondence.correspondenceListRefreshKey,
      () => {
        const dto = correspondence.correspondenceMap.get(model.id);

        documentWriterEncounterId.current = dto?.encounterId;

        if (dto) {
          runInAction(() => {
            model.document = dto;
          });
        }
      }
    );

    useEffect(() => {
      return () => correspondenceListRefreshKeyReaction();
    });

    const getDocumentDto = (
      values: DocumentWriterValues,
      submitType: SubmitAction
    ) => {
      const { dto } = model;
      const updatedDto = { ...dto };

      if (
        submitType === SubmitAction.Finish ||
        submitType === SubmitAction.Email
      ) {
        updatedDto.status = CorrespondenceStatus.Done;
      }

      updatedDto.content = values.documentContent;

      updatedDto.metadata = getNewMetadata(updatedDto.metadata, {
        [DocumentEnum.Name]: values.documentTitle
      });

      updatedDto.type = values.documentType;
      updatedDto.to = values.recipientId;
      updatedDto.from = values.providerId;
      updatedDto.store = StoreType.Correspondence;

      const { Extension, ContentType } = DocumentEnum;
      updatedDto.metadata = getNewMetadata(updatedDto.metadata, {
        [Extension]: "docx",
        [ContentType]: DocumentContentType.Sfdt
      });

      if (values.visibility === CorrespondenceVisibility.DisplayOnTimeline) {
        updatedDto.metadata = getNewMetadata(updatedDto.metadata, {
          [DocumentEnum.ShowOnTimeline]: true.toString()
        });
      }
      updatedDto.secGroupId =
        values.visibility === CorrespondenceVisibility.Confidential
          ? core.user?.privateSecGroupId
          : undefined;
      return updatedDto;
    };

    const onSubmit: (
      values: DocumentWriterValues,
      submitType: SubmitAction
    ) => Promise<void> = async (values, submitType) => {
      const { patientId } = model;

      const documentDTO = getDocumentDto(values, submitType);

      if (documentWriterEncounterId.current) {
        await model.saveValues(documentDTO, documentWriterEncounterId.current);
      } else {
        const createRequest: DocumentCreateOptions = {
          documents: [documentDTO],
          source: DocumentSource.Content
        };

        await correspondence.addDocumentsByPatientId(patientId, createRequest);
        correspondence.updateDocumentTab(model.document.id);
      }

      const { id } = model;

      if (submitType === SubmitAction.Email && model.destinationEmail) {
        await clinical.sendClinicalDocument({
          documentId: id,
          patientId,
          email: model.destinationEmail
        });
      }

      if (submitType !== SubmitAction.Save) {
        await correspondence.closeDocument(id);
      }
    };

    return (
      <Stack tokens={{ childrenGap: 16 }}>
        <SubmissionForm<DocumentWriterValues>
          formName="document-writer"
          onSubmit={val => onSubmit(val, SubmitAction[type.current])}
          initialValues={model.initialValues}
          styles={{
            fields: {
              display: "flex",
              flexDirection: "row",
              height: "100%",
              overflowY: "inherit"
            },
            main: {
              backgroundColor: "inherit",
              overflowY: "inherit"
            }
          }}
          hideButtons
          render={() => {
            return (
              <DocumentWriterDetailsFields
                type={type}
                docEditor={docEditor}
                encounterId={encounterId}
              />
            );
          }}
        />
      </Stack>
    );
  });

export const DocumentWriterDetails = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  DocumentWriterDetailsComponent
);
