import { action, observable } from "mobx";

import { ALL_DAYS_OF_WEEK } from "@bps/utils";
import {
  AddAppointmentConfirmationCampaignDto,
  AddAppointmentReminderJobDto,
  AppointmentReminderJobScheduleBehavior
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import {
  IMaybePromiseObservable,
  maybePromiseObservable
} from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { AppointmentReminderJob } from "@stores/booking/models/AppointmentReminderJob.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { CommsConfirmationCampaignFormValues } from "../../comms-templates/components/confirmation/CommsConfirmationCampaignFormValues.ts";
import {
  CommsScheduleFormValues,
  DayOfWeekConfigValue
} from "../components/CommsScheduleForm.types.ts";

enum TemplateParameters {
  PracticeLocation = "PracticeLocation",
  LocationWorkPhone = "LocationWorkPhone"
}

export class CommsScheduleHelper {
  @observable
  selectedSectionId: string | undefined;

  schedulesListResults: IMaybePromiseObservable<AppointmentReminderJob[]>;

  constructor(private root: RootStore) {
    this.schedulesListResults = maybePromiseObservable();
  }

  get bookingStore() {
    return this.root.booking;
  }

  get routing() {
    return this.root.routing;
  }

  get core() {
    return this.root.core;
  }

  templateHasMissingValues = (template: OutboundCommTemplate): boolean => {
    const orgUnit = this.core.orgUnitsMap.get(this.core.location.id);

    const parameters: { key: string; value: any }[] = [
      {
        key: TemplateParameters.PracticeLocation,
        value: orgUnit?.name
      },
      {
        key: TemplateParameters.LocationWorkPhone,
        value: orgUnit?.workPhone
      }
    ];

    return parameters.some(parameter => {
      return template.channelTemplates.some(
        template =>
          template.template.includes(parameter.key) && !parameter.value
      );
    });
  };

  transformToDaysOfWeek = (values: DayOfWeekConfigValue[]): number[] => {
    const daysOfWeek: number[] = [];
    values.forEach(row => {
      if (row.checked) {
        daysOfWeek.push(row.jobDayOfWeek ?? 0);
      }
    });

    return daysOfWeek;
  };

  transformFromDaysOfWeek = (daysOfWeek: number[]): DayOfWeekConfigValue[] => {
    const result: DayOfWeekConfigValue[] = ALL_DAYS_OF_WEEK.map(
      weekDayNumber => {
        return {
          jobDayOfWeek: weekDayNumber,
          checked: daysOfWeek.includes(weekDayNumber),
          apptDaysOfWeek: []
        };
      }
    );

    return result;
  };

  getInitialValuesCommsSchedule = async (
    templates: OutboundCommTemplate[]
  ): Promise<CommsScheduleFormValues> => {
    const DEFAULT_SEND_BEFORE_DAYS = 1;
    const scheduleId = this.routing.match(
      routes.settings.communications.schedule.edit
    )
      ? this.routing.match(routes.settings.communications.schedule.edit)?.params
          .id
      : undefined;

    const schedule = !scheduleId
      ? undefined
      : await this.bookingStore.getAppointmentReminderJob(scheduleId);

    const sendBefore = !schedule
      ? DEFAULT_SEND_BEFORE_DAYS
      : schedule.appointmentReminderOptions.sendBefore;

    //at some point in the future we may allow the user to pick an alternate behaviour as is present in the API, but not yet exposed
    const scheduleBehaviour: AppointmentReminderJobScheduleBehavior =
      AppointmentReminderJobScheduleBehavior.SpanUntilScheduledRunDate;

    const everyday = !schedule
      ? true
      : schedule.dto?.jobSchedule.daysOfWeek.length === ALL_DAYS_OF_WEEK.length;

    const daysOfWeek = !schedule
      ? ALL_DAYS_OF_WEEK
      : schedule.dto?.jobSchedule.daysOfWeek;

    const daysOfWeekConfigValues = this.transformFromDaysOfWeek(daysOfWeek);

    const defaultTemplateKey = (): string | undefined => {
      const value = templates.find(x => x.isDefault);
      return value?.id;
    };

    return !schedule
      ? {
          templateId: defaultTemplateKey(),
          sendBefore,
          scheduleBehaviour,
          everyday,
          daysOfWeek: daysOfWeekConfigValues,
          sendTime: "10:00 AM"
        }
      : {
          jobName: schedule.jobName,
          sendBefore,
          scheduleBehaviour,
          sendTime: schedule.time,
          everyday,
          daysOfWeek: daysOfWeekConfigValues,
          appointmentTypes:
            schedule.appointmentReminderOptions.appointmentTypes,
          templateId: schedule.appointmentReminderOptions.templateId
        };
  };

  getInitialValuesCommsConfirmationCampaign = async (
    templates: OutboundCommTemplate[]
  ): Promise<CommsConfirmationCampaignFormValues> => {
    const confirmationCampaignId = this.routing.match(
      routes.settings.communications.confirmationCampaign.edit
    )
      ? this.routing.match(
          routes.settings.communications.confirmationCampaign.edit
        )?.params.id
      : undefined;

    const confirmationCampaign = confirmationCampaignId
      ? await this.bookingStore.getAppointmentConfirmationCampaign(
          confirmationCampaignId
        )
      : undefined;

    return !confirmationCampaign
      ? {
          name: "",
          newAppointmentCampaignId: templates[0].id ?? "",
          rescheduleAppointmentCampaignId: templates[0].id ?? "",
          cancelAppointmentCampaignId: templates[0].id ?? ""
        }
      : {
          name: confirmationCampaign.name ?? "",
          newAppointmentCampaignId:
            confirmationCampaign.newAppointmentCampaignId ?? "",
          rescheduleAppointmentCampaignId:
            confirmationCampaign.rescheduleAppointmentCampaignId ?? "",
          cancelAppointmentCampaignId:
            confirmationCampaign.cancelAppointmentCampaignId ?? ""
        };
  };

  addEditSchedule = async (values: AddAppointmentReminderJobDto) => {
    try {
      if (this.routing.match(routes.settings.communications.schedule.new)) {
        await this.bookingStore.addAppointmentReminderJob(values);
      } else {
        const scheduleId = this.routing.match(
          routes.settings.communications.schedule.edit
        )?.params.id!;

        const appointmentReminderJob =
          this.bookingStore.appointmentReminderJobsMap.get(scheduleId)!;

        await this.bookingStore.updateAppointmentReminderJob({
          ...values,
          id: scheduleId,
          eTag: appointmentReminderJob.eTag
        });
      }
    } catch (e) {
      this.bookingStore.notification.error(e.message);
    }
  };

  addEditConfirmationCampaign = async (
    values: AddAppointmentConfirmationCampaignDto
  ) => {
    try {
      if (
        this.routing.match(
          routes.settings.communications.confirmationCampaign.new
        )
      ) {
        await this.bookingStore.addAppointmentConfirmationCampaign(values);
      } else {
        const confirmationCampaignId = this.routing.match(
          routes.settings.communications.confirmationCampaign.edit
        )?.params.id!;

        const appointmentConfirmationCampaign =
          this.bookingStore.appointmentConfirmationCampaignsMap.get(
            confirmationCampaignId
          )!;

        await this.bookingStore.updateAppointmentConfirmationCampaign({
          ...values,
          id: confirmationCampaignId,
          eTag: appointmentConfirmationCampaign.eTag
        });
      }
    } catch (e) {
      this.bookingStore.notification.error(e.message);
    }
  };

  goBackToBasePath = () => {
    this.routing.push(routes.settings.communications.schedules.pattern);
  };

  @action
  setSelectedSectionId = (sectionId: string | undefined) => {
    this.selectedSectionId = sectionId;
  };
}
