import { IStyle } from "@bps/fluent-ui";

export const getContainerStyles = (motionsLength: number): IStyle => {
  return {
    containerType: "inline-size",
    "@container (width < 355px)": {
      ".motions-grid": {
        gridTemplateColumns: "1fr"
      }
    },
    "@container (width >= 355px) and (width < 800px)": {
      ".motions-grid": {
        gridTemplateColumns: "1fr 1fr"
      },
      ".motions-grid-item:last-child": {
        gridColumn: motionsLength % 2 > 0 ? "span 2" : undefined
      }
    },
    "@container (width > 800px)": {
      ".motions-grid": {
        gridTemplateColumns: `repeat(${motionsLength}, 1fr)`
      }
    }
  };
};
