import { createContext, useContext } from "react";

import { CorrespondencesHelper } from "./CorrespondencesHelper.ts";

export const CorrespondencesContext = createContext<CorrespondencesHelper>(
  {} as CorrespondencesHelper
);

export const useCorrespondencesHelper = () =>
  useContext(CorrespondencesContext);
