import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export const goToDrafts = (root: RootStore) => {
  root.userExperience
    .getUserStorage(UserStorageKeys.DraftItemsFilters)
    .then(draftItemsFilter =>
      root.routing.push({
        pathname: routes.accounts.draftItems.basePath.pattern,
        search: draftItemsFilter
          ? (draftItemsFilter.jsonData as string)
          : undefined
      })
    );
};

export const useDraftRoute = () => {
  const root = useStores();

  return () => goToDrafts(root);
};
