import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontWeights,
  IconButton,
  IContextualMenuItem,
  IPalette,
  mergeStyles,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { PatientNotePriority } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { PatientNoticeTypeText } from "@ui-components/RefText.tsx";

interface PatientNoticesTableRowProps {
  showActions: undefined | boolean;
  items: IContextualMenuItem[];
  patientNotice: PatientNotice;
}

const colorDotPrefixStyles = (backgroundColor: string) => ({
  borderRadius: "50%",
  backgroundColor,
  width: 10,
  height: 10
});

export const getPriorityColor = (
  palette: IPalette,
  severity?: string
): string => {
  switch (severity) {
    case PatientNotePriority.Low:
      return palette.themeLighter;
    case PatientNotePriority.Medium:
      return "#FFE0B3";
    case PatientNotePriority.High:
      return "#FFB3B5";
    default:
      return "#F0F0F0";
  }
};

export const PatientNoticesTableRow: FunctionComponent<PatientNoticesTableRowProps> =
  observer(({ showActions, items, patientNotice }) => {
    const { palette } = useTheme();

    return (
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 4 }}
        styles={{
          root: {
            width: "100%",
            position: "relative" // Fix for issue where tooltip hover causes scrollbar
          }
        }}
      >
        <Stack.Item styles={{ root: { marginRight: "4px" } }}>
          <div
            className={mergeStyles(
              colorDotPrefixStyles(
                getPriorityColor(palette, patientNotice.priority)
              )
            )}
          />
        </Stack.Item>
        <Stack.Item
          styles={{
            root: {
              marginRight: "auto",
              color: palette.themeDarker,
              fontWeight: FontWeights.semibold
            }
          }}
        >
          <PatientNoticeTypeText code={patientNotice.type} separator=" " />
          <Text styles={{ root: { wordBreak: "break-word" } }}>
            {patientNotice.comment && ` - ${patientNotice.comment}`}
          </Text>
        </Stack.Item>

        {showActions && (
          <TooltipHost content="More">
            <IconButton
              menuIconProps={{ iconName: "More" }}
              menuProps={{ items }}
            />
          </TooltipHost>
        )}
      </Stack>
    );
  });
