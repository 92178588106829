import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  FontIcon,
  IContextualMenuItemProps,
  mergeStyles,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { Claim } from "@stores/acc/models/Claim.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { ContextMenuItemsEnum } from "./ContextMenuItemsEnum.ts";

const ContextualMenuClaimItemBase: FunctionComponent<{
  itemProp: IContextualMenuItemProps;
}> = observer(({ itemProp }) => {
  const theme = useTheme();
  const { getClaim } = useContext(BookingCalendarEventContext);

  const getClaimNumber = (claim: Claim | undefined) => {
    if (claim) {
      const claimNumberDisplayed = claim.claimNumber
        ? `#${claim.claimNumber}`
        : "";

      return (
        <Stack horizontal tokens={{ childrenGap: 4 }}>
          <FontIcon
            iconName={itemProp.item.iconProps?.iconName}
            className={mergeStyles({
              marginRight: 4,
              marginLeft: 4,
              color: theme.palette.themePrimary
            })}
          />
          <Stack.Item>{`${ContextMenuItemsEnum.Claim}  ${claimNumberDisplayed}`}</Stack.Item>
        </Stack>
      );
    }

    return (
      <Text
        as="i"
        styles={(_prop, theme) => ({
          root: { color: theme.palette.neutralTertiary, marginLeft: 8 }
        })}
      >
        No claims recorded
      </Text>
    );
  };

  return (
    <DataFetcher<Claim | undefined>
      fetch={getClaim}
      fallback={<Spinner styles={{ root: { margin: "0 auto" } }} />}
    >
      {getClaimNumber}
    </DataFetcher>
  );
});

export const ContextualMenuClaimItem = withFetch(
  x => [
    x.acc.ref.accidentLocations.load(),
    x.acc.ref.accidentScenes.load(),
    x.acc.ref.occupations.load(),
    x.acc.ref.sports.load()
  ],
  ContextualMenuClaimItemBase
);
