import { observer } from "mobx-react-lite";
import { FC } from "react";

import { PresetButtonType } from "@bps/fluent-ui";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  categoriesItem,
  disciplinesItem,
  searchFilterItem,
  statusFilterItem
} from "@modules/practice/screens/address-book/components/contacts-filters/contact-filter-items.ts";
import { useParseContactFilters } from "@modules/practice/screens/address-book/components/contacts-filters/useParseContactFilters.ts";
import { IndividualAndOrgContactFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

export const defaultIndividualAndOrganisationFilter: IndividualAndOrgContactFilter =
  {
    categories: [],
    search: "",
    statuses: [ContactStatus.Active],
    organisationRoleTypeCodes: [],
    disciplines: []
  };

const filterKeys = {
  organisationRoleTypeCodes: "organisationRoleTypeCodes",
  isHealthProvider: "isHealthProvider",
  disciplines: "disciplines",
  categories: "categories",
  statuses: "statuses",
  search: "search",
  types: "types"
};

const IndividualAndOrganisationListFilterBase: FC<
  Pick<FilterBarProps<IndividualAndOrgContactFilter>, "children"> & {
    contactType: ContactType;
    presets?: PresetButtonType<IndividualAndOrgContactFilter>[] | undefined;
  }
> = observer(({ contactType, presets, ...props }) => {
  const { practice } = useStores();

  const { parseArrayFilter, setLocationFromFilter } =
    useParseContactFilters(filterKeys);

  const initialState = usePersistedHelper(() => {
    const defaultFilter: IndividualAndOrgContactFilter = {
      ...defaultIndividualAndOrganisationFilter
    };

    const filterQuery = { ...defaultFilter, ...parseArrayFilter() };

    const types: ContactType[] = [contactType];

    return {
      types,
      search: filterQuery?.search,
      categories: filterQuery?.categories ?? [],
      disciplines: filterQuery?.disciplines ?? [],
      statuses: filterQuery?.statuses ?? [],
      isHealthProvider: filterQuery?.isHealthProvider
    };
  });

  const filterItems: Item<IndividualAndOrgContactFilter>[] = [
    searchFilterItem(),
    categoriesItem(practice.ref.organisationCategories.keyTextValues),

    statusFilterItem(
      practice.ref.contactStatuses.keyTextValues.filter(
        x => x.key !== ContactStatus.Deceased && x.key !== ContactStatus.Merged
      )
    )
  ];

  if (contactType === ContactType.Organisation) {
    filterItems.splice(
      filterItems.length - 1,
      0,
      disciplinesItem(practice.ref.contactDisciplines.keyTextValues)
    );
  }

  return (
    <FilterBar<IndividualAndOrgContactFilter>
      {...props}
      onChange={({ field, value }) => setLocationFromFilter(field, value)}
      items={filterItems}
      initialValues={initialState}
      presets={
        contactType === ContactType.Organisation
          ? [
              {
                text: "Healthcare",
                name: "isHealthProvider",
                id: "isHealthProvider",
                iconName: "Hospital",
                tooltip: "Only show healthcare organisations",
                tooltipPresses: "Only showing healthcare organisations",
                valuesToBeSetOnToggleOn: {
                  isHealthProvider: true
                },
                valuesToBeSetOnToggleOff: {
                  isHealthProvider: false
                }
              }
            ]
          : undefined
      }
    />
  );
});

export const IndividualAndOrganisationListFilter = withFetch(
  x => [
    x.practice.ref.contactDisciplines.load(),
    x.practice.ref.organisationCategories.load()
  ],
  IndividualAndOrganisationListFilterBase
);
