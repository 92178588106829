import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { BillingScreenWrapper } from "@modules/billing/screens/shared-components/BillingScreenWrapper.tsx";

import { DraftInvoiceGenerateModal } from "../draft-invoices/components/new-invoice-modal/DraftInvoiceGenerateModal.tsx";
import { DraftItemListContext } from "./context/DraftItemListContext.ts";
import { DraftItemsListHelper } from "./context/DraftItemsListHelper.ts";
import { DraftItemsFilterBar } from "./DraftItemsFilterBar.tsx";
import { DraftItemsList } from "./DraftItemsList.tsx";

const DraftItemsScreen = () => {
  const helper = new DraftItemsListHelper();
  return (
    <DraftItemListContext.Provider value={helper}>
      <DraftItemsScreenBase />
    </DraftItemListContext.Provider>
  );
};

const DraftItemsScreenBase = observer(() => {
  const { setIsInvoiceNewModalVisible, isInvoiceNewModalVisible } =
    useContext(DraftItemListContext);

  return (
    <BillingScreenWrapper
      headerButtons={
        <DefaultButton onClick={() => setIsInvoiceNewModalVisible(true)}>
          Create invoice
        </DefaultButton>
      }
    >
      {isInvoiceNewModalVisible && <DraftInvoiceGenerateModal />}
      <DraftItemsFilterBar>
        <DraftItemsList />
      </DraftItemsFilterBar>
    </BillingScreenWrapper>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default DraftItemsScreen;
