import { observer } from "mobx-react-lite";
import { useContext, useMemo } from "react";
import { FormRenderProps } from "react-final-form";

import { Heading, NoDataTile, SideRailMenuItem, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactStatus,
  ContactType,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PatientDemographicContext } from "@modules/practice/screens/contact-details/context/PatientDemographicContext.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { When } from "@ui-components/withPerm.tsx";

import { ContactCardIdsEnum } from "../../../shared-components/types/contact-card-ids.enum.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { toAddressDto } from "../../../shared-components/utils/contact.utils.ts";
import { ContactSubmissionFormDialog } from "../../shared-components/edit/ContactSubmissionFormDialog.tsx";
import { EditContactHeader } from "../../shared-components/edit/EditContactHeader.tsx";
import { EditContactMethods } from "../../shared-components/edit/EditContactMethods.tsx";
import { EditFormLayout } from "../../shared-components/edit/EditFormLayout.tsx";
import { EditPreferencesConsents } from "../../shared-components/edit/EditPreferencesConsents.tsx";
import { EditProfile } from "../../shared-components/edit/EditProfile.tsx";
import { EditRelationships } from "../../shared-components/edit/EditRelationships.tsx";
import { EditEmployers } from "../../shared-components/edit/employers/EditEmployers.tsx";
import { ContactEditFormValues } from "../../shared-components/edit/PatientEditFormValues.tsx";
import {
  getCommTypePreferences,
  getContactFormValues,
  toCommunicationDto,
  toRelationshipDto,
  toRelationshipDtoFromEmployerField
} from "../../shared-components/edit/utils.ts";
import { ContactFormValidator } from "../../shared-components/edit/validators/ContactFormValidator.tsx";
import { PreferencesAndConsentsValidator } from "../../shared-components/edit/validators/PreferencesAndConsentsValidator.ts";
import { menuItems } from "../view/ContactDetails.tsx";

type ContactFormDialogProps = {
  contact?: Contact;
  contactPreferences: ContactPreferences | undefined;
};

const preferencesConsentsValidator = new PreferencesAndConsentsValidator();

export const ContactFormDialog: React.FC<ContactFormDialogProps> = observer(
  ({ contact, contactPreferences }) => {
    const { core, practice, notification, comms } = useStores();
    const {
      ui: { newContactType, demographicInitialValues }
    } = practice;

    const isNewContactVisible = !!newContactType;

    const submitPicture = async (contact: Contact, fileStagingId: string) => {
      await practice.updateProfilePicture({
        entityId: contact.id,
        fileStagingId
      });
    };

    const initialValues = useMemo(
      () =>
        getContactFormValues(
          {
            contact,
            contactPreferences,
            isAddPatient: isNewContactVisible
          },
          isNewContactVisible,
          {
            core,
            practice
          }
        ),
      [contact, core, isNewContactVisible, practice, contactPreferences]
    );

    const permittedMenuItems = menuItems.filter(
      item => !item.permissions || core.hasPermissions(item.permissions)
    );

    const permittedMenuItemsEdit: SideRailMenuItem[] = [
      ...permittedMenuItems,
      {
        text: Labels.profile,
        id: ContactCardIdsEnum.contactProfile
      }
    ];

    const helper = useContext(PatientDemographicContext);

    const onSubmit = async (values: ContactEditFormValues) => {
      const {
        relationships: relationshipsValues,
        firstName,
        lastName,
        title,
        status,
        profilePictureUrl,
        appointmentReminderType,
        appointmentReminderValue,
        appointmentConfirmationType,
        appointmentConfirmationValue,
        invoiceCommunicationType,
        invoiceCommunicationValue,
        employers,
        ...restValues
      } = values;

      const baseRequest = {
        ...restValues,
        fullName: {
          firstName,
          lastName,
          title
        },
        contactStatus: status,
        communications: toCommunicationDto(values.communications),
        addresses: toAddressDto(values.addresses),
        relationships: toRelationshipDto(relationshipsValues)
      };

      if (employers && employers.length > 0) {
        baseRequest.relationships.push(
          ...toRelationshipDtoFromEmployerField(employers)
        );
      }

      let updatedIndividual: Contact;

      if (!!initialValues.profilePictureUrl && !values.profilePictureUrl) {
        try {
          await practice.deleteProfilePicture(contact?.id!);
        } catch (error) {
          notification.error("Error occurred while removing profile photo");
        }
      }

      if (!values.id) {
        updatedIndividual = await practice.addIndividual({
          ...baseRequest,
          fullName: baseRequest.fullName,
          addresses: baseRequest.addresses,
          contactStatus: ContactStatus.Active
        });
      } else {
        updatedIndividual = await practice.updateIndividual({
          id: values.id,
          ...baseRequest
        });
      }
      if (updatedIndividual)
        await comms.addOrUpdateContactPreferences(
          updatedIndividual.id,
          getCommTypePreferences(values)
        );
      if (helper.profilePictureStagingId && updatedIndividual) {
        // stores picture and fetches contact with new picture url
        await submitPicture(updatedIndividual, helper.profilePictureStagingId);
      }
    };

    return (
      <Stack grow tokens={{ childrenGap: 8 }}>
        <ContactSubmissionFormDialog<ContactEditFormValues>
          initialValues={initialValues}
          validate={(values: ContactEditFormValues) => {
            const validator = new ContactFormValidator(
              core.ref.countries.values,
              core.tenantDetails!.country
            );
            return {
              ...validator.validate(values),
              ...preferencesConsentsValidator.validate(values)
            };
          }}
          onSubmit={onSubmit}
          buttonsProps={{
            disableSubmitOnPristine: !demographicInitialValues,
            cancelButtonProps: {
              id: "close-individual-form-dialog"
            },
            hideButtonsSeparator: true,
            styles: {
              root: {
                marginTop: 0
              }
            }
          }}
        >
          {(props: FormRenderProps<ContactEditFormValues>) => {
            const { firstName, lastName } = props.values;
            const accountContentText = contact?.relationships?.some(
              x =>
                x.relationship === RelationshipType.AccountHolder ||
                x.relationship === RelationshipType.AccountHolderFor
            )
              ? "Invoices are billed to this individual"
              : "Not applicable";
            return (
              <EditFormLayout
                menuItems={
                  isNewContactVisible
                    ? permittedMenuItems
                    : permittedMenuItemsEdit
                }
                contact={contact}
              >
                <Stack tokens={{ childrenGap: 48 }}>
                  <EditContactHeader contact={contact} />
                  <EditContactMethods country={core.tenantDetails?.country!} />
                  <EditPreferencesConsents />
                  <When permission={[Permission.AccountHistoryAllowed]}>
                    {contact?.type === ContactType.Individual && (
                      <div
                        id={`${PatientCardIds.patientAccount}-edit`}
                        style={{ paddingRight: 16 }}
                      >
                        <Heading
                          variant="section-heading-light"
                          styles={{ root: { padding: "5px 0" } }}
                        >
                          {Labels.account}
                        </Heading>
                        <NoDataTile
                          textProps={{ text: accountContentText }}
                          linkProps={{ hidden: true }}
                          showBoxShadow={false}
                          styles={{ root: { minHeight: 85 } }}
                          greyView={true}
                        />
                      </div>
                    )}
                  </When>
                  <EditRelationships
                    fullName={
                      firstName && lastName ? `${firstName} ${lastName}` : ""
                    }
                  />
                  <EditEmployers contact={contact} />
                  {!isNewContactVisible && (
                    <EditProfile
                      contact={contact}
                      sectionId={ContactCardIdsEnum.contactProfile}
                    />
                  )}
                </Stack>
              </EditFormLayout>
            );
          }}
        </ContactSubmissionFormDialog>
      </Stack>
    );
  }
);
