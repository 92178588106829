import React, { FC, useState } from "react";

import { DefaultButton } from "@bps/fluent-ui";

import { CreateUserFormDialog } from "./CreateUserFormDialog.tsx";

export const CreateUserFormDialogWithToggle: FC = () => {
  const [isDialogHidden, setIsDialogHidden] = useState(true);

  return (
    <>
      <DefaultButton
        onClick={() => setIsDialogHidden(false)}
        text="Create user"
      />

      {!isDialogHidden && (
        <CreateUserFormDialog onDismiss={() => setIsDialogHidden(true)} />
      )}
    </>
  );
};
