import {
  BodyArea,
  ExaminationCommentDto,
  ImageTypeCode
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { CanvasImage } from "../../notes/draw/DrawingCanvas.types.ts";
import { ImagePartProp } from "../../SOTAP/context/InjuryImagesHelper.types.ts";

export type BodyPartImageProp = { [key in BodyArea]?: ImagePartProp[] };

export type ImageValue = {
  [key in ImageTypeCode]?: string;
};

export class BodyExaminationFormValues {
  imageValue?: ImageValue;
  examinationComments: ExaminationCommentsType;
  specialTestResponseItems?: SpecialTestResponseType;
  strengthTestResponseItems?: SpecialTestResponseType;
  injuryAreaMotionAssessments: InjuryAreaMotionAssessmentItem[];
}

export type ExaminationCommentsType = {
  [key in BodyArea]?: ExaminationCommentDto[];
};

export type SpecialTestResponseType = {
  [key in BodyArea]?: string[];
};

export interface BodyAreaCanvasImage extends CanvasImage {
  bodyArea?: BodyArea;
}

export const bodyExamNameOf = nameOfFactory<BodyExaminationFormValues>();

export interface BodyExaminationFormProps {
  clinicalRecord: ClinicalRecord;
}
