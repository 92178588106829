import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useRef, useState } from "react";

import { ActionButton, Heading, Pivot, PivotItem, Stack } from "@bps/fluent-ui";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { SideNavContent } from "@modules/clinical/screens/patient-record/components/SideNavContent.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { AutofillQuickAddEditDialog } from "@ui-components/text-editors/editor-autofills/autofillQuickAddEdit/AutofillQuickAddEditDialog.tsx";
import { AutofillContext } from "@ui-components/text-editors/editor-autofills/editor-autofill.types.ts";

import {
  SyncFusionAutofillContext,
  useSyncFusionAutofillContext
} from "./SyncFusionAutofillContext.ts";
import { SyncFusionAutofillFilter } from "./SyncFusionAutofillFilter.tsx";
import { SyncFusionAutofillList } from "./SyncFusionAutofillList.tsx";
import {
  AutofillPivotKey,
  SyncFusionAutofillModel
} from "./SyncFusionAutofillsModel.ts";

interface SyncFusionAutofillsProps {
  docEditor: DocumentEditor | undefined;
  autofillContext: AutofillContext;
  autofills: AutofillDto[];
  isTemplate: boolean;
}

const SyncFusionAutofillsBase: React.FC = observer(() => {
  const {
    setAutofills,
    setSelectedKey,
    autofills,
    selectedPivotKey,
    previewDocument,
    documentViewerVisible,
    setDocumentViewerVisible,
    onSearch
  } = useSyncFusionAutofillContext();

  const [hideCreateAutofullDialog, sethideCreateAutofillDialog] =
    useState<boolean>(true);

  const handleLinkClick = (item: PivotItem) => {
    switch (item.props.itemKey) {
      case AutofillPivotKey.All:
        setSelectedKey(AutofillPivotKey.All);
        setAutofills(autofills);
        break;
      default:
        setSelectedKey(AutofillPivotKey.All);
        setAutofills(autofills);
        break;
    }
  };
  return (
    <>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <Heading variant="section-heading-light">Autofills</Heading>

        <ActionButton
          iconProps={{ iconName: "BpAddUpdate" }}
          text="Add / update"
          onClick={() => sethideCreateAutofillDialog(false)}
        />
      </Stack>
      <AutofillQuickAddEditDialog
        isDialogHidden={hideCreateAutofullDialog}
        onDismiss={() => sethideCreateAutofillDialog(true)}
      />

      <SideNavContent>
        <Pivot
          onLinkClick={handleLinkClick}
          selectedKey={selectedPivotKey}
          styles={{ root: { marginBottom: 4 } }}
        >
          <PivotItem headerText="All" itemKey={AutofillPivotKey.All} />
        </Pivot>
        <SyncFusionAutofillFilter onSearch={onSearch} />
        <SyncFusionAutofillList />
      </SideNavContent>
      {documentViewerVisible && previewDocument && (
        <DocumentViewerDialog
          getDocument={() => previewDocument}
          closeDocumentViewer={() => setDocumentViewerVisible(false)}
          hideDownloadButton={true}
        />
      )}
    </>
  );
});

export const SyncFusionAutofills: FunctionComponent<
  SyncFusionAutofillsProps
> = ({ docEditor, autofillContext, autofills, isTemplate }) => {
  const { document } = useStores();
  const model = useRef(
    new SyncFusionAutofillModel(document, {
      docEditor,
      autofillContext,
      autofills,
      isTemplate
    })
  );
  return (
    <SyncFusionAutofillContext.Provider value={model.current}>
      <SyncFusionAutofillsBase />
    </SyncFusionAutofillContext.Provider>
  );
};
