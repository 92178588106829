import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  DirectionalHint,
  FontSizes,
  Heading,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaSize,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { TIME_FORMATS } from "@bps/utils";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  OutboundCommChannel,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  SendReminderFormValues,
  SendReminderValidator
} from "./validators/SendReminderValidator.tsx";

const validator = new SendReminderValidator();
const TITLE = "Send SMS reminder";

const nameOf = nameOfFactory<SendReminderFormValues>();
export const SendReminderDialog: FunctionComponent = observer(() => {
  const { booking, notification, userExperience } = useStores();
  const theme = useTheme();
  const { reminderArgs, setReminderArgs } = useBookingCalendarScreenContext();

  if (!reminderArgs?.calendarEventId) {
    return null;
  }

  return (
    <DataFetcher<[CalendarEvent, OutboundCommTemplate[], Contact | undefined]>
      key={reminderArgs.calendarEventId}
      fetch={async ({ booking, comms, practice }) => {
        return Promise.all([
          booking.getCalendarEvent(reminderArgs.calendarEventId),
          comms.getAllOutboundCommTemplates()
        ]).then(async ([calendarEvent, templates]) => {
          const lookupId = !!calendarEvent.isGroupAppointment
            ? reminderArgs.contactId
            : calendarEvent.contactId;

          const contact = !!lookupId
            ? await practice.getContact(lookupId, {
                includeContactPreferences: true
              })
            : undefined;

          return [calendarEvent, templates, contact];
        });
      }}
    >
      {([calendarEvent, templates, contact]) => {
        const { core } = useStores();

        const defaultTemplate = templates
          .filter(x => x.outboundCommTypeCode === OutboundCommType.ApptReminder)
          .find(x => x.isDefault);

        const isMultipleAttendeeReminder =
          calendarEvent.isGroupAppointment && !reminderArgs.contactId;

        const noApptReminderConsent =
          contact?.contactPreferences?.apptRemPreferences
            ?.preferredCommChannelTypeCode === undefined;

        const isOptOut =
          contact?.contactPreferences?.apptRemPreferences?.contactHasOptedOut ??
          false;

        const noSMSReminder =
          !isMultipleAttendeeReminder && (noApptReminderConsent || isOptOut);

        const initialValues = {
          templateId: noSMSReminder ? "" : defaultTemplate?.id ?? ""
        };

        const templateValues = (): KeyTextValue[] => {
          return templates
            .filter(
              x => x.outboundCommTypeCode === OutboundCommType.ApptReminder
            )
            .map(({ id, name, isDefault }) => ({
              key: id,
              text: isDefault ? `${name} (default)` : name,
              data: { isDefault, name }
            }));
        };

        const onRenderOption = (option: IDropdownOption): JSX.Element => {
          return (
            <span>
              {option.data.name}
              {option.data.isDefault && (
                <Text
                  styles={{
                    root: { color: theme.palette.blackTranslucent40 }
                  }}
                >
                  {" (default)"}
                </Text>
              )}
            </span>
          );
        };

        const onSubmit = (values: SendReminderFormValues) =>
          booking.sendCalendarEventReminder({
            calendarEventId: calendarEvent.id,
            contactId: reminderArgs.contactId,
            content: values.reminderMessage!,
            campaignId:
              values.templateId === "" ? undefined : values.templateId,
            commType:
              values.templateId === ""
                ? OutboundCommType.ApptFree
                : OutboundCommType.ApptReminder
          });

        const onSubmitSucceeded = () => {
          notification.success(
            contact
              ? `Your message to ${contact.name} has been queued for sending`
              : `Your messages for ${
                  calendarEvent.groupDescription ??
                  `${calendarEvent.startDateTime.toFormat(
                    TIME_FORMATS.DEFAULT_TIME_FORMAT
                  )} ${calendarEvent.appointmentType?.name}`
                } participants have been queued for sending`
          );

          setReminderArgs(undefined);
        };
        return (
          <SubmissionFormDialog
            dialogName={TITLE}
            onSubmit={onSubmit}
            onSubmitSucceeded={onSubmitSucceeded}
            keepDirtyOnReinitialize
            buttonsProps={{
              submitButtonProps: {
                text: "Send",
                iconProps: {}
              },
              hideButtonsSeparator: true
            }}
            validate={values => validator.validate(values)}
            initialValues={initialValues}
            dialogProps={{
              minWidth: 480,
              onDismiss: () => setReminderArgs(undefined),
              dialogContentProps: {
                title: <Heading variant="modal-heading">{TITLE}</Heading>,
                showCloseButton: true
              }
            }}
          >
            {renderProps => (
              <Stack tokens={{ childrenGap: 16 }}>
                <Stack tokens={{ childrenGap: 8 }}>
                  <Persona
                    text={contact?.name ?? "Multiple participants"}
                    id={contact?.id}
                    size={PersonaSize.size24}
                    imageInitials={
                      contact?.initials ??
                      calendarEvent.activeAttendees.length.toString()
                    }
                    styles={{ root: { padding: 8 } }}
                  />
                  <Text>
                    {`${calendarEvent.startDateTime.toFormat(
                      TIME_FORMATS.DEFAULT_TIME_FORMAT
                    )} - ${calendarEvent.endDateTime.toFormat(
                      TIME_FORMATS.DEFAULT_TIME_FORMAT
                    )}`}
                  </Text>
                </Stack>
                <DropdownField
                  name={nameOf("templateId")}
                  placeholder="Select a template"
                  label="Template"
                  options={templateValues()}
                  calloutProps={{
                    calloutMaxHeight: 400,
                    directionalHint: DirectionalHint.bottomCenter
                  }}
                  onRenderOption={onRenderOption}
                  disabled={noSMSReminder}
                />
                {isMultipleAttendeeReminder && (
                  <MessageBar messageBarType={MessageBarType.warning}>
                    SMS consent may not be configured for all participants.
                  </MessageBar>
                )}
                {noSMSReminder && !isMultipleAttendeeReminder && (
                  <MessageBar messageBarType={MessageBarType.warning}>
                    {isOptOut
                      ? "Patient opted out of appointment reminders."
                      : "SMS consent has not been configured for this patient."}
                  </MessageBar>
                )}
                <FieldCondition
                  when={nameOf("templateId")}
                  is={value => !!value}
                >
                  <div
                    style={{
                      borderWidth: 1,
                      height: "153px",
                      maxHeight: "153px",
                      borderRadius: 2,
                      borderStyle: "solid",
                      borderColor: theme.palette.neutralDark,
                      padding: 4
                    }}
                  >
                    <DataFetcher
                      refetchId={renderProps.values.templateId}
                      fallback={<Spinner />}
                      fetch={async () => {
                        if (renderProps.values.templateId) {
                          const renderedContent =
                            await userExperience.getCampaignRender(
                              renderProps.values.templateId,
                              {
                                channelCode: OutboundCommChannel.Sms,
                                calendarEventId: reminderArgs.calendarEventId,
                                contactId: reminderArgs.contactId
                              }
                            );

                          return renderedContent ?? "";
                        }
                        return "";
                      }}
                    >
                      {messageContent => (
                        <div
                          style={{
                            height: "100%",
                            fontSize: FontSizes.small,
                            whiteSpace: "pre-line",
                            padding: 8,
                            borderRadius: 2,
                            overflowY: "scroll",
                            backgroundColor:
                              theme.semanticColors.disabledBackground
                          }}
                          dangerouslySetInnerHTML={{
                            __html: messageContent
                          }}
                        />
                      )}
                    </DataFetcher>
                  </div>
                </FieldCondition>
                <FieldCondition
                  when={nameOf("templateId")}
                  is={undefined || ""}
                >
                  <TextInputField
                    name={nameOf("reminderMessage")}
                    label="Message content"
                    multiline
                    rows={4}
                    resizable={false}
                    placeholder={
                      core.isNZTenant ? "No template selected" : "Enter details"
                    }
                    autoFocus={true}
                    disabled={core.isNZTenant}
                  />
                </FieldCondition>
              </Stack>
            )}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
});
