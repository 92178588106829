import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useState } from "react";

import { Dialog } from "@bps/fluent-ui";
import {
  CorrespondenceType,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import { TemplatePickerProps } from "@shared-types/clinical/template-picker-props.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { User } from "@stores/core/models/User.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DocumentWriterMergeFormDialog } from "../../document-writer/components/merge-fields/DocumentWriterMergeFormDialog.tsx";
import {
  DocumentMergeFieldsFormValues,
  DocumentWriterMergeFormDialogProps
} from "../../document-writer/components/merge-fields/DocumentWriterMergeFormDialog.types.ts";
import { getListItems } from "../correspondence.utils.ts";
import {
  TemplatePickerFormContext,
  useTemplatePickerFormContext
} from "./context/TemplatePickerFormContext.tsx";
import { TemplatePickerFormModel } from "./context/TemplatePickerFormModel.ts";
import { CreateBlankDocumentDialog } from "./CreateBlankDocumentDialog.tsx";
import { TemplatePickerForm } from "./TemplatePickerForm.tsx";
import { TemplatePickerFormValues } from "./TemplatePickerForm.types.ts";

const TemplatePickerWrapperBase: React.FC<TemplatePickerProps> = observer(
  ({
    onSuccess,
    userId,
    onDismiss,
    hidden,
    launchFrom,
    patientId,
    encounterId,
    claimId,
    documentSettings
  }) => {
    const { core, document, correspondence } = useStores();
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    const [showCreateBlankDocumentDialog, setShowCreateBlankDocumentDialog] =
      useState<boolean>(false);

    const model = useTemplatePickerFormContext();

    const fromClinical = launchFrom === LaunchFrom.Clinical;

    const onTemplateSelected = async (values: TemplatePickerFormValues) => {
      if (!values.templateId) {
        runInAction(() => {
          model.selectedTemplate = undefined;
        });
        await createDocument(CorrespondenceType.Letter, {});
      } else {
        if (values.templateId) {
          const template = await document.getTemplate(values.templateId);
          runInAction(() => {
            model.selectedTemplate = template;
          });
          if (template.documentType === CorrespondenceType.Autofill) {
            setShowCreateBlankDocumentDialog(true);
          } else {
            setShowDetailsDialog(true);
          }
        }
      }
    };

    const createDocument = async (
      templateType: CorrespondenceType,
      values: DocumentMergeFieldsFormValues,
      toContactId?: string
    ) => {
      await model.createDocument(templateType, values, {
        toContactId,
        encounterId,
        onSuccess,
        onDismiss
      });
    };

    const onMergeFieldFormSubmit: DocumentWriterMergeFormDialogProps["onSubmit"] =
      async values => {
        await createDocument(
          values.selectedTemplate!,
          values,
          values.toContactId
        );
        correspondence.ui.setShowTemplatePicker(false);
      };

    const getTemplatesBySide = async () => {
      let templates = await document.getTemplates({});

      // Admin documents should not appear in clinical. We have permissions that drive the initial fetch,
      // but even if you have the permission to view them, admin ONLY docs should not appear here.
      if (fromClinical) {
        templates = templates.filter(
          x =>
            x.visibility === TemplateVisibility.Both ||
            x.visibility === TemplateVisibility.Clinical
        );
      }

      return templates;
    };

    return showDetailsDialog && model.selectedTemplate ? (
      <DocumentWriterMergeFormDialog
        onCancel={() => {
          setShowDetailsDialog(false);
          runInAction(() => {
            model.selectedTemplate = undefined;
          });
        }}
        onSubmit={onMergeFieldFormSubmit}
        activeClaimId={claimId}
        launchFrom={launchFrom}
        patientId={patientId}
        documentSettings={documentSettings}
      />
    ) : (
      <DataFetcher<{ patientName?: string; patientNameWithTitle?: string }>
        fetch={async ({ practice }) => {
          if (patientId) {
            const patient = await practice.getContact(patientId);
            return {
              patientName: patient.name,
              patientNameWithTitle: patient.nameWithTitle
            };
          }
          return {};
        }}
      >
        {({ patientName, patientNameWithTitle }) => (
          <Dialog
            hidden={hidden}
            minWidth={1000}
            dialogContentProps={{
              showCloseButton: true,
              title: patientName
                ? `New document for ${patientName}`
                : "New document"
            }}
            onDismiss={onDismiss}
            modalProps={{ focusTrapZoneProps: {} }}
          >
            <DataFetcher<{
              templates: Template[];
              favourites: string[];
              user: User;
            }>
              fetch={async ({ userExperience }) => {
                const [templates, userStorage, user] = await Promise.all([
                  getTemplatesBySide(),
                  userExperience.getUserStorage(
                    UserStorageKeys.FavouriteTemplates
                  ),
                  core.getUser(userId)
                ]);
                return {
                  templates,
                  favourites: (userStorage?.jsonData ?? []) as string[],
                  user
                };
              }}
              refetchId={document.templateMap.size}
            >
              {({ templates, favourites, user }) => {
                const isNoneClinical = !core.hasPermissions(
                  Permission.DocTemplateClinicalRead
                );

                const items = getListItems(core, templates, {
                  isProviderClass: user.isProviderClass,
                  isNoneClinical
                });

                const heading = patientNameWithTitle
                  ? `Create document for ${patientNameWithTitle}`
                  : "Create document";
                return (
                  <>
                    <TemplatePickerForm
                      items={items}
                      favourites={favourites}
                      createDocument={createDocument}
                      onSubmit={onTemplateSelected}
                      onCancel={() => onDismiss()}
                      launchFrom={launchFrom}
                      patientNameWithTitle={patientNameWithTitle}
                      patientId={patientId}
                      documentSettings={documentSettings}
                    />
                    <CreateBlankDocumentDialog
                      createDocument={createDocument}
                      isDialogHidden={!showCreateBlankDocumentDialog}
                      onDismiss={() => setShowCreateBlankDocumentDialog(false)}
                      launchFrom={launchFrom}
                      patientId={patientId}
                      heading={heading}
                      documentSettings={documentSettings}
                    />
                  </>
                );
              }}
            </DataFetcher>
          </Dialog>
        )}
      </DataFetcher>
    );
  }
);

export const TemplatePickerWrapper: FunctionComponent<
  TemplatePickerProps
> = props => {
  const { clinical, correspondence, document, core } = useStores();
  const model = new TemplatePickerFormModel({
    document,
    correspondence,
    clinical,
    core
  });

  const loadPracticeDocumentSettings = async (
    root: IRootStore
  ): Promise<OrgUnitDocumentSettingsDto | undefined> => {
    const orgUnitId = root.core.location.parentOrgUnit?.id;

    if (orgUnitId) {
      const orgUnit = await root.practice.getOrgUnit(orgUnitId);
      return orgUnit?.orgUnitDocumentSettings;
    }

    return undefined;
  };

  return (
    <TemplatePickerFormContext.Provider value={model}>
      <DataFetcher fetch={loadPracticeDocumentSettings}>
        {documentSettings => (
          <TemplatePickerWrapperBase
            {...props}
            documentSettings={documentSettings}
          />
        )}
      </DataFetcher>
    </TemplatePickerFormContext.Provider>
  );
};
