import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Field, useForm } from "react-final-form";

import { IPersonaProps, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { AddFromWaitingListButton } from "@modules/booking/screens/waiting-list/components/waiting-list-details-dialog/AddFromWaitingListButton.tsx";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { PatientActivation } from "@modules/practice/screens/shared-components/PatientActivation.tsx";
import { getMinimumCharactersToSearch } from "@modules/practice/utils.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { AppointmentNoticesContactPickerWrapper } from "./appointment-notices/AppointmentNoticesContactPickerWrapper.tsx";
import { AppointmentNoticesLink } from "./appointment-notices/AppointmentNoticesLink.tsx";
import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";

export const IndividualAppointmentFields: React.FC = observer(() => {
  const form = useForm<AppointmentFormValues>();

  const { practice, booking } = useStores();

  const {
    isGroupAppointment,
    isAppointmentValidForEdit,
    patientLabel,
    setEpisodeOfCareId
  } = useContext(AppointmentFormContext);

  const getTextFromItem = (persona: IPersonaProps) => persona.text as string;

  if (isGroupAppointment) return null;

  return (
    <Stack>
      <AppointmentNoticesContactPickerWrapper />
      <ContactPickerFieldWithAdd
        minimumCharactersToSearch={(search: string | undefined) =>
          getMinimumCharactersToSearch(search)
        }
        autoFocus
        name={appointmentFormNameOf("patientId")}
        label={capitalizeSentence(patientLabel)}
        required
        includeInactive
        getTextFromItem={getTextFromItem}
        quickAddProps={{
          addButtonLabel: `New ${patientLabel}`,
          typeToAdd: ContactType.Patient,
          permission: [
            Permission.ContactRead,
            Permission.ContactWrite,
            Permission.PatientRead,
            Permission.PatientWrite
          ],
          buttonWrapperStyles: {
            root: { flexBasis: 0, flexGrow: 1.2 }
          }
        }}
        filter={{ types: [ContactType.Patient] }}
        disabled={isAppointmentValidForEdit}
        actionButton={{
          linkProps: {
            children: (
              <Field<string | undefined>
                name={appointmentFormNameOf("patientId")}
              >
                {props => {
                  if (!props.input.value) {
                    return null;
                  }
                  return <PatientActivation patientId={props.input.value} />;
                }}
              </Field>
            )
          }
        }}
        pickerFormItemStyles={{
          root: { flexBasis: 0, flexGrow: 2.8 }
        }}
      />
      <AddFromWaitingListButton linkStyles={{ root: { marginTop: 5 } }} />

      <FieldSpy
        name={appointmentFormNameOf("patientId")}
        onChange={async (value: string) => {
          //when patient name changes. episodeOfCareId has to reset
          setEpisodeOfCareId(undefined);
          form.change(appointmentFormNameOf("episodeOfCareId"), undefined);

          const [notices] = await Promise.all([
            practice.getPatientNotices(value),
            practice.loadSystemNotices([value])
          ]);
          if (!notices) {
            return;
          }

          const adminNotices = notices.filter(
            pn => pn.patientId === value && pn.isAdmin
          );

          if (adminNotices.length > 0) {
            booking.ui.setSecondColumnContent(
              booking.ui.currentAppointment?.secondColumnContent !==
                SecondColumnContent.patientNotices
                ? SecondColumnContent.patientNotices
                : undefined
            );
          } else {
            booking.ui.setSecondColumnContent(undefined);
          }
        }}
      />

      <FieldCondition when={appointmentFormNameOf("patientId")} is={id => id}>
        <AppointmentNoticesLink />
      </FieldCondition>
    </Stack>
  );
});
