import { FormTemplateTypeCode } from "@libs/gateways/forms/FormsGateway.dtos.ts";

export const outstandingFormWarningText = (
  formTemplateTypes: FormTemplateTypeCode[]
) => {
  if (
    formTemplateTypes.includes(FormTemplateTypeCode.patientDemographic) &&
    formTemplateTypes.includes(FormTemplateTypeCode.acc45Demographic)
  ) {
    return "Personal details, Contact methods";
  } else if (
    formTemplateTypes.includes(FormTemplateTypeCode.acc) &&
    formTemplateTypes.includes(FormTemplateTypeCode.acc45Demographic)
  ) {
    return "Personal details, Accident & employment details, Patient declaration";
  }
  return;
};

export enum WarningText {
  First = " have been requested via an online form on ",
  Second = ". The response to that form will overwrite any changes made at this stage. You may ",
  Third = " if you would like to preserve your changes to these sections."
}
