import {
  greaterThan,
  integer,
  Length,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { MeetingFormValues } from "./MeetingFormValues.ts";

export class MeetingFormValidator extends Validator<MeetingFormValues> {
  constructor() {
    super();

    this.forField("startTime", [required()]);
    this.forField("startDate", [required()]);
    this.forField("providerId", [required()]);
    this.forField("duration", [
      predicate(x => Number(x) !== 0, required()), // treat zero as value entered
      integer(),
      greaterThan(0)
    ]);
    this.forField("title", maxLength(Length.long));
  }
}
