import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import { ClinicalTasksTableLabels } from "../clinical-task/types/clinical-task-table-labels.enum.ts";

export interface ClinicalReminderManagementFilterProps {
  children: ReactNode;
}

export interface ClinicalReminderManagementFilterType {
  patientName?: string;
}

const defaultFilter: ClinicalReminderManagementFilterType = {};

const ClinicalReminderFilterBase: React.FC<ClinicalReminderManagementFilterProps> =
  observer(({ children }) => {
    const items: Item<ClinicalReminderManagementFilterType>[] = [
      {
        type: "searchBox",
        name: "patientName",
        stickItem: true,
        props: {
          placeholder: ClinicalTasksTableLabels.SearchPatient,
          styles: {
            root: {
              flexGrow: 1
            }
          }
        }
      }
    ];
    return (
      <FilterBar<ClinicalReminderManagementFilterType>
        initialValues={defaultFilter}
        items={items}
      >
        {() => children}
      </FilterBar>
    );
  });

export const ClinicalReminderFilter = withFetch(
  x => [x.clinical.ref.clinicalTaskTypes.load()],
  ClinicalReminderFilterBase
);
