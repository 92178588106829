import { observer } from "mobx-react-lite";

import {
  IconButton,
  IContextualMenuItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AppointmentTypeScreenItem } from "../AppointmentTypeScreen.types.ts";
import { useAppointmentTypeScreenContext } from "../context/AppointmentTypeScreenContext.ts";

export interface AppointmentsOnlineDisplayContextualMenuProps {
  item: AppointmentTypeScreenItem;
}

export const AppointmentsOnlineDisplayContextualMenu: React.FC<AppointmentsOnlineDisplayContextualMenuProps> =
  observer(({ item }) => {
    return (
      <DataFetcher<boolean>
        fetch={async ({ bhb }) => {
          const location = await bhb.getLocation();
          return !!location?.allowCancellationsEnabled;
        }}
      >
        {allowCancellationsEnabled => (
          <ContextualMenu
            item={item}
            allowCancellationsEnabled={allowCancellationsEnabled}
          />
        )}
      </DataFetcher>
    );
  });

const ContextualMenu: React.FC<
  AppointmentsOnlineDisplayContextualMenuProps & {
    allowCancellationsEnabled: boolean;
  }
> = ({ item, allowCancellationsEnabled }) => {
  const { bhb } = useStores();
  const { updateBhbAppointmentTypeSorting, setBhbAppointmentTypes } =
    useAppointmentTypeScreenContext();

  const getAvailabilityMenuItems = (
    item: AppointmentTypeScreenItem
  ): IContextualMenuItem[] => {
    const availabilityMenuItems: IContextualMenuItem[] = [];

    let isAllPatient = false;
    let isAvailableExisting = false;
    let isAvailableNew = false;
    let isUnavailable = false;
    if (
      item.bhbAppointmentType?.isAvailableExistingPatients &&
      item.bhbAppointmentType?.isAvailableNewPatients
    ) {
      isAllPatient = true;
    } else if (item.bhbAppointmentType?.isAvailableExistingPatients) {
      isAvailableExisting = true;
    } else if (item.bhbAppointmentType?.isAvailableNewPatients) {
      isAvailableNew = true;
    } else {
      isUnavailable = true;
    }

    availabilityMenuItems.push({
      key: "All patients",
      text: "All patients",
      iconProps: isAllPatient ? { iconName: "Accept" } : undefined,
      onClick: () => {
        updateAppointmentTypeAvailability(item, true, true);
      }
    });

    availabilityMenuItems.push({
      key: "Existing patients only",
      text: "Existing patients only",
      iconProps: isAvailableExisting ? { iconName: "Accept" } : undefined,
      onClick: () => {
        updateAppointmentTypeAvailability(item, true, null);
      }
    });

    availabilityMenuItems.push({
      key: "New patients only",
      text: "New patients only",
      iconProps: isAvailableNew ? { iconName: "Accept" } : undefined,
      onClick: () => {
        updateAppointmentTypeAvailability(item, null, true);
      }
    });

    availabilityMenuItems.push({
      key: "Unavailable online (hide)",
      text: "Unavailable online (hide)",
      iconProps: isUnavailable ? { iconName: "Accept" } : undefined,
      onClick: () => {
        updateAppointmentTypeAvailability(item, null, null);
      }
    });

    return availabilityMenuItems;
  };

  const getCancellationMenuItems = (
    item: AppointmentTypeScreenItem
  ): IContextualMenuItem[] => {
    const cancellationMenuItems: IContextualMenuItem[] = [];

    let isOnline = false;
    let isViaCallOnly = false;
    if (item.bhbAppointmentType?.isCancellable) {
      isOnline = true;
    } else {
      isViaCallOnly = true;
    }

    cancellationMenuItems.push({
      key: "Online",
      text: "Online",
      iconProps: isOnline ? { iconName: "Accept" } : undefined,
      onClick: () => {
        updateAppointmentCancellationType(item);
      }
    });

    cancellationMenuItems.push({
      key: "Via call only",
      text: "Via call only",
      iconProps: isViaCallOnly ? { iconName: "Accept" } : undefined,
      onClick: () => {
        updateAppointmentCancellationType(item);
      }
    });

    return cancellationMenuItems;
  };

  const updateAppointmentTypeAvailability = async (
    item: AppointmentTypeScreenItem,
    isAvailableExistingPatients: boolean | null | undefined,
    isAvailableNewPatients: boolean | null | undefined
  ) => {
    await bhb.putAppointmentTypeForLocation({
      id: item.bhbAppointmentType?.id!,
      ...item.bhbAppointmentType?.dto,
      isAvailableExistingPatients,
      isAvailableNewPatients,
      sortOrder:
        isAvailableExistingPatients || isAvailableNewPatients
          ? item.bhbAppointmentType?.sortOrder
          : null
    });

    const appTypes = await bhb.getAppointmentTypesForLocation();

    if (appTypes !== undefined && item.bhbAppointmentType?.id) {
      updateBhbAppointmentTypeSorting(appTypes, item.bhbAppointmentType.id);
    }
  };

  const updateAppointmentCancellationType = async (
    item: AppointmentTypeScreenItem
  ) => {
    await bhb.putAppointmentTypeForLocation({
      id: item.bhbAppointmentType?.id!,
      ...item.bhbAppointmentType?.dto,
      isCancellable: !item.bhbAppointmentType?.isCancellable ?? true
    });

    const appTypes = await bhb.getAppointmentTypesForLocation();

    if (appTypes !== undefined && item.bhbAppointmentType?.id) {
      setBhbAppointmentTypes(appTypes);
    }
  };

  const getOnlineDisplayMenuItems = (): IContextualMenuItem[] => {
    const onlineDisplayMenuItems: IContextualMenuItem[] = [];

    onlineDisplayMenuItems.push({
      key: "availability",
      text: "Availability",
      subMenuProps: {
        items: getAvailabilityMenuItems(item)
      }
    });

    onlineDisplayMenuItems.push({
      key: "cancellation",
      text: "Cancellation / rescheduling",
      subMenuProps: {
        items: getCancellationMenuItems(item)
      },
      disabled: !allowCancellationsEnabled
    });

    return onlineDisplayMenuItems;
  };

  return (
    <Stack horizontalAlign="center" verticalAlign="center" horizontal>
      <TooltipHost content="More">
        <IconButton
          onMouseDown={e => e.stopPropagation()}
          menuIconProps={{ iconName: "More" }}
          menuProps={{
            items: getOnlineDisplayMenuItems()
          }}
          styles={{
            root: { width: "32px", height: "36px", padding: 0 },
            flexContainer: { width: "32px", height: "36px" }
          }}
        />
      </TooltipHost>
    </Stack>
  );
};
