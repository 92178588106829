import { RFV_CD_TYPE_CODE_OTHER } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ReasonForVisitFormValues } from "./ReasonForVisitModal.types.ts";

const requiresReasonClinicalDataOtherCondition = (
  val: any,
  values: ReasonForVisitFormValues
) => !!values?.reasonForVisitClinicalDataKeys?.includes(RFV_CD_TYPE_CODE_OTHER);

export class ReasonForVisitValidator extends Validator<ReasonForVisitFormValues> {
  constructor() {
    super();
    this.forField("reasonForVisitClinicalDataOriginalText", [
      predicate(requiresReasonClinicalDataOtherCondition, required())
    ]);
  }
}
