import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Heading, NativeList, Separator, Stack, Text } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";

import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { PatientProviderDeclarationFormValidator } from "../validators/PatientProviderDeclarationFormValidator.tsx";
import {
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";

export const PatientProviderDeclarationFormSection: FC = observer(() => {
  const { claimAdjustment } = useClaimAdjustmentContext();

  const { selectedMenuItemId } = useCardFormLayoutContext();

  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.patientProviderDeclarations}
      openOnRender={
        selectedMenuItemId ===
        ClaimsAdjustmentCardIds.patientProviderDeclarations
      }
      heading={ClaimAdjustmentSectionHeading.patientProviderDeclarations}
      iconName="PenWorkspace"
      statusValidator={new PatientProviderDeclarationFormValidator()}
      visible={!claimAdjustment.verballyApproved}
    >
      <Stack>
        <Stack tokens={{ childrenGap: 8 }}>
          <Heading variant="section-heading-light">
            Declaration of discussion with patient
          </Heading>
          <Text>I have discussed with my patient:</Text>
          <NativeList>
            <li>
              that the information (including personal details) on this form is
              true and correct.
            </li>
            <li>
              that they are taking personal responsibility for their
              rehabilitation and treatment, and will actively participate in
              this treatment plan that has been developed.
            </li>
          </NativeList>
          <Text>My patient has authorised:</Text>
          <NativeList>
            <li>
              the collection of medical and other records which are or may be
              relevant to the provision of further treatment on this claim.
            </li>
            <li>
              me to lodge this request for treatment on their behalf, and
              understand that funding for further treatment is subject to prior
              approval by ACC.
            </li>
            <li>
              ACC to update their claim record with the postal address on this
              form if it differs from the one already held by ACC.
            </li>
          </NativeList>
          <CheckboxField
            required
            label={ClaimAdjustmentSectionHeading.confirmDeclaration}
            name={claimAdjustmentFormNameOf("patientDeclarationConfirmed")}
          />
          <Separator styles={{ root: { paddingTop: 24, paddingBottom: 24 } }} />
          <Heading variant="section-heading-light">
            Provider declaration
          </Heading>
          <NativeList>
            <li>
              This treatment is for the personal injury for which the patient
              has cover.
            </li>
            <li>
              The treatment is for the purpose of restoring the patient’s health
              to the maximum extent practicable, and is necessary, appropriate,
              and of the quality required for this purpose.
            </li>
            <li>
              I have discussed the treatment options with the patient and
              advised why the recommendation is the appropriate treatment in
              this case.
            </li>
          </NativeList>
          <CheckboxField
            required
            label={ClaimAdjustmentSectionHeading.confirmDeclaration}
            name={claimAdjustmentFormNameOf("providerDeclarationConfirmed")}
          />
        </Stack>
      </Stack>
    </ClaimCard>
  );
});
