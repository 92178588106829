import { FunctionComponent } from "react";
import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import {
  AvailabilitySlot,
  AvailabilitySlots,
  SlotGroup
} from "@stores/booking/models/AvailabilitySlots.ts";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { getGroupByCountSlices } from "../utils.ts";
import { EmptyState } from "./EmptyState.tsx";
import { timeSlotsGridStyles } from "./NextAvailableFields.styles.ts";
import { TimeSlot } from "./TimeSlot.tsx";

interface TimeSlotsProps {
  isExpanded: boolean;
  date: DateTime;
  orgUnitId: string;
  slots: AvailabilitySlots;
  setIsExpanded: (value: boolean) => void;
  changeDate: (date: Date) => void;
}

export const TimeSlots: FunctionComponent<TimeSlotsProps> = ({
  isExpanded,
  orgUnitId,
  setIsExpanded,
  changeDate,
  slots,
  date
}) => {
  const { providerId } = slots;

  const {
    input: { value: duration }
  } = useField(appointmentFormNameOf("duration"), {
    subscription: { value: true }
  });

  const today = DateTime.jsDateNow();

  const getAvailableTime = (item: AvailabilitySlots): SlotGroup[] => {
    return isExpanded ? slotGroupMax(item) : slotGroupMin(item);
  };

  const slotGroupMin = (item: AvailabilitySlots): SlotGroup[] => {
    return getGroupByCountSlices(
      item.getSelectedDateAvailabilitySlots(date, today, orgUnitId),
      5
    ).slice(0, 1);
  };

  const slotGroupMax = (item: AvailabilitySlots): SlotGroup[] => {
    return getGroupByCountSlices(
      item.getSelectedDateAvailabilitySlots(date, today, orgUnitId),
      5
    );
  };

  const slotFromSelectedDate = slots.getFirstSlotFromSelectedDate(
    date,
    orgUnitId
  );

  const nextAvailableText = slotFromSelectedDate
    ? `Next available appointment is at ${DateTime.fromISO(
        slotFromSelectedDate.availableTimes[0]
      ).toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)}`
    : "There are no available times for this provider.";

  const nextAvailableLinkText = slotFromSelectedDate
    ? DateTime.fromISO(slotFromSelectedDate.date)
    : undefined;

  const availableSlotGroup: SlotGroup[] = getAvailableTime(slots);

  return (
    <Stack
      verticalFill
      horizontalAlign="center"
      verticalAlign={
        slots.hasValue && slots.isSlotsAvailable(date) ? "start" : "center"
      }
      styles={{
        root: {
          overflowY: "auto"
        }
      }}
    >
      {providerId &&
      duration !== 0 &&
      !Number.isNaN(duration) &&
      availableSlotGroup.length > 0 ? (
        <div style={timeSlotsGridStyles}>
          {availableSlotGroup.map((slotGroup: SlotGroup) => {
            return slotGroup.time.map((slot: AvailabilitySlot) => (
              <TimeSlot
                providerId={providerId}
                orgUnitId={orgUnitId}
                date={date}
                slot={slot}
                key={`${providerId}-${slot.availableTime}`}
              />
            ));
          })}
        </div>
      ) : (
        <EmptyState
          changeDate={changeDate}
          setIsExpanded={setIsExpanded}
          nextAvailableText={nextAvailableText}
          nextAvailableLinkText={nextAvailableLinkText}
        />
      )}
    </Stack>
  );
};
