import { PropsWithChildren, useRef, useState } from "react";

import {
  Callout,
  DirectionalHint,
  IconButton,
  IIconStyleProps,
  IIconStyles,
  IStyleFunctionOrObject,
  mergeStyles,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

import { getToggleBarButtonStyles } from "./DrawingCanvas.styles.ts";

export interface ToolbarToggleButtonProps
  extends PropsWithChildren<{
    active: boolean;
    iconName?: string;
    title?: string | undefined;
    onClick?: () => void;
    styles?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
    disabled?: boolean;
    showChevron?: boolean;
  }> {}

export const ToolbarToggleButton: React.FC<ToolbarToggleButtonProps> = ({
  active,
  children,
  iconName,
  title,
  onClick,
  styles,
  disabled,
  showChevron
}) => {
  const toggleStyles = getToggleBarButtonStyles();
  const divRef = useRef<HTMLDivElement>(null);
  const [showCallOut, setShowCallOut] = useState(false);
  const theme = useTheme();

  const styleColors = () => {
    if (disabled) {
      return mergeStyles({
        backgroundColor: theme.semanticColors.buttonBackgroundDisabled,
        color: theme.semanticColors.disabledBodyText
      });
    } else if (active) {
      return mergeStyles({
        backgroundColor: theme.semanticColors.buttonBackgroundHovered,
        color: theme.semanticColors.bodyText
      });
    } else {
      return mergeStyles({
        backgroundColor: theme.semanticColors.buttonBackground,
        color: theme.semanticColors.bodyText
      });
    }
  };

  return (
    <div ref={divRef}>
      <TooltipHost content={title} directionalHint={DirectionalHint.topCenter}>
        <IconButton
          iconProps={{ iconName, styles }}
          checked={active}
          onClick={() => {
            if (onClick) onClick();
            setShowCallOut(prevState => !prevState);
          }}
          onMenuClick={() => {
            setShowCallOut(prevState => !prevState);
          }}
          toggle={true}
          menuProps={showChevron ? { items: [] } : undefined}
          disabled={disabled}
          className={styleColors()}
          styles={{ root: { width: 32, height: 32 } }}
        />
      </TooltipHost>
      {children && (
        <Callout
          target={divRef}
          styles={toggleStyles.callout}
          onDismiss={() => {
            setShowCallOut(false);
          }}
          hidden={!showCallOut}
        >
          {children}
        </Callout>
      )}
    </div>
  );
};
