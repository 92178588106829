import { FunctionComponent, useContext } from "react";

import { Link, Stack } from "@bps/fluent-ui";
import { SystemNotification as SystemNotificationModel } from "@stores/notifications/models/SystemNotification.ts";
import { useRouteDefinitionsFilter } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";

import { RenderDirection } from "../../notification.types.ts";
import { NotificationContext } from "../context/NotificationContext.ts";

interface NotificationActionsProps {
  notification: SystemNotificationModel;
}

export const NotificationActions: FunctionComponent<
  NotificationActionsProps
> = ({ notification }) => {
  const routeFilter = useRouteDefinitionsFilter();
  const { getNotificationActionSet } = useContext(NotificationContext);

  const actionSet = getNotificationActionSet(notification);

  const filteredActions = actionSet?.actions?.filter(action =>
    routeFilter(action)
  );

  if (!filteredActions) {
    return null;
  }

  const horizontal =
    (actionSet?.renderDirection ?? RenderDirection.Vertical) ===
    RenderDirection.Horizontal;

  return (
    <Stack
      styles={{ root: { paddingTop: 4 } }}
      horizontal={horizontal}
      tokens={{ childrenGap: horizontal ? 8 : 4 }}
    >
      {filteredActions.map(actionProps => {
        return (
          <Link key={actionProps.key} onClick={actionProps.onClick}>
            {actionProps.text}
          </Link>
        );
      })}
    </Stack>
  );
};
