import { SideRailMenuItem } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { Labels } from "../../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../../shared-components/types/patient-card-ids.enum.ts";

export type SideRailMenuItemWithPermissions = SideRailMenuItem & {
  permissions?: Permission[];
};

export function getMenuItemsWithNotes({
  hasPermissions,
  patient,
  accountTotals
}: {
  hasPermissions: (permission: string[] | string) => boolean;
  patient: Contact;
  accountTotals: AccountBalance;
}): SideRailMenuItemWithPermissions[] {
  const accountNotes: number | undefined = accountTotals?.totalOwing
    ? 1
    : undefined;

  const entitlementsNotes: number =
    Number(patient.medicareIsExpired || patient.medicareWillExpire) +
    Number(
      patient.healthInsuranceIsExpired || patient.healthInsuranceWillExpire
    ) +
    Number(patient.cscIsExpired || patient.cscWillExpire);

  const prefConsNotes: number = patient.interpreterLanguageValue ? 1 : 0;

  const menuItems: SideRailMenuItemWithPermissions[] = [
    {
      text: Labels.personalInfo,
      id: PatientCardIds.patientHeader
    },
    {
      text: Labels.contactMethods,
      id: PatientCardIds.contactMethods
    },
    {
      text: Labels.communicationPreferences,
      id: PatientCardIds.prefCons,
      notes: prefConsNotes || undefined
    },
    {
      text: Labels.account,
      id: PatientCardIds.patientAccount,
      notes: accountNotes,
      permissions: [Permission.AccountHistoryAllowed]
    },
    {
      text: Labels.relationships,
      id: PatientCardIds.contactRelationships
    },
    {
      text: Labels.cardsEntitlements,
      id: PatientCardIds.patientEntitlements,
      notes: entitlementsNotes || undefined
    },
    {
      text: Labels.healthProviders,
      id: PatientCardIds.healthProviders
    },
    {
      text: Labels.employers,
      id: PatientCardIds.employers
    },
    {
      text: Labels.appointments,
      id: PatientCardIds.appointments,
      permissions: [Permission.CalendarEventRead]
    },
    {
      text: Labels.conditions,
      id: PatientCardIds.conditions
    },
    {
      text: Labels.profile,
      id: PatientCardIds.patientProfile
    },
    {
      text: Labels.forms,
      id: PatientCardIds.forms,
      permissions: [Permission.SendForms]
    }
  ];

  return menuItems.filter(
    item => !item.permissions || hasPermissions(item.permissions)
  );
}
