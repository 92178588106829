import {
  ClinicalDataType,
  MeasurementType,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

interface ClinicalDataMap {
  clinicalDataType: ClinicalDataType;
  readOnlyView: ClinicalDataType;
  questionnaireType?: QuestionnaireType;
}

export class ClinicalDataToMeasurementConverter {
  private _clinicalDataTypeMeasurementMap: Map<
    MeasurementType,
    ClinicalDataMap
  >;

  private dataMap(): Map<MeasurementType, ClinicalDataMap> {
    if (!this._clinicalDataTypeMeasurementMap) {
      this._clinicalDataTypeMeasurementMap = new Map();
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.K10, {
        clinicalDataType: ClinicalDataType.K10,
        readOnlyView: ClinicalDataType.K10View,
        questionnaireType: QuestionnaireType.K10V1
      });
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.NPRS, {
        clinicalDataType: ClinicalDataType.NPRS,
        readOnlyView: ClinicalDataType.NPRSView,
        questionnaireType: QuestionnaireType.NPRSV1
      });
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.OREBRO, {
        clinicalDataType: ClinicalDataType.OREBRO,
        readOnlyView: ClinicalDataType.OREBROView,
        questionnaireType: QuestionnaireType.OREBROV1
      });
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.PSFS, {
        clinicalDataType: ClinicalDataType.PSFS,
        readOnlyView: ClinicalDataType.PSFSView,
        questionnaireType: QuestionnaireType.PSFSV1
      });
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.GRCS, {
        clinicalDataType: ClinicalDataType.GRCS,
        readOnlyView: ClinicalDataType.GRCSView,
        questionnaireType: QuestionnaireType.GRCSV1
      });
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.DASH, {
        clinicalDataType: ClinicalDataType.DASH,
        readOnlyView: ClinicalDataType.DASHView,
        questionnaireType: QuestionnaireType.DASHV1
      });
      this._clinicalDataTypeMeasurementMap.set(
        MeasurementType.CustomClinicalTool,
        {
          clinicalDataType: ClinicalDataType.CustomClinicalTool,
          readOnlyView: ClinicalDataType.CustomClinicalToolView
        }
      );
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.RAND36, {
        clinicalDataType: ClinicalDataType.RAND36,
        readOnlyView: ClinicalDataType.RAND36View,
        questionnaireType: QuestionnaireType.RAND36
      });
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.EPDS, {
        clinicalDataType: ClinicalDataType.EPDS,
        readOnlyView: ClinicalDataType.EPDSView,
        questionnaireType: QuestionnaireType.EPDSV1
      });
      this._clinicalDataTypeMeasurementMap.set(MeasurementType.DASS21, {
        clinicalDataType: ClinicalDataType.DASS21,
        readOnlyView: ClinicalDataType.DASS21View,
        questionnaireType: QuestionnaireType.DASS21V1
      });
    }
    return this._clinicalDataTypeMeasurementMap;
  }

  measurementToClinical(type: MeasurementType): ClinicalDataType {
    const item = this.dataMap().get(type);

    if (item) return item.clinicalDataType;

    throw new Error(
      `Can not match measurement type ${type} to a clinical data type. Add an item to the ClinicalDataTypeMeasurement map.`
    );
  }

  measurementToQuestionnaire(type: MeasurementType): QuestionnaireType {
    const item = this.dataMap().get(type);

    if (item && item.questionnaireType) return item.questionnaireType;

    throw new Error(
      `Can not match measurement type ${type} to a questionnaire type. Add an item to the ClinicalDataTypeMeasurement map.`
    );
  }

  measurementToReadOnlyView(type: MeasurementType): ClinicalDataType {
    const item = this.dataMap().get(type);

    if (item) return item.readOnlyView;

    throw new Error(
      `Can not match measurement type ${type} to a clinical data type. Add an item to the ClinicalDataTypeMeasurement map.`
    );
  }

  clinicalToReadOnlyView(type: ClinicalDataType): ClinicalDataType {
    let clinicalDataType: ClinicalDataType | undefined;
    this.dataMap().forEach(value => {
      if (value.clinicalDataType === type)
        clinicalDataType = value.readOnlyView;
    });
    if (clinicalDataType) return clinicalDataType;
    else
      throw new Error(
        `Can not match clinical data type ${type}. Add an item to the ClinicalDataTypeMeasurement map.`
      );
  }

  readonlyOnlyToMeasurement(type: ClinicalDataType): MeasurementType {
    let measurementType: MeasurementType | undefined;
    this.dataMap().forEach((value, key) => {
      if (value.readOnlyView === type) measurementType = key;
    });
    if (measurementType) return measurementType;
    else
      throw new Error(
        `Can not match clinical data type ${type} to a measurement type. Add an item to the ClinicalDataTypeMeasurement map.`
      );
  }

  clinicalToMeasurement(type: ClinicalDataType): MeasurementType {
    let measurementType: MeasurementType | undefined;
    this.dataMap().forEach((value, key) => {
      if (value.clinicalDataType === type) measurementType = key;
    });
    if (measurementType) return measurementType;
    else
      throw new Error(
        `Can not match clinical data type ${type} to a measurement type. Add an item to the ClinicalDataTypeMeasurement map.`
      );
  }

  clinicalToQuestionnaire(type: ClinicalDataType): QuestionnaireType {
    let questionnaireType: QuestionnaireType | undefined;
    this.dataMap().forEach(value => {
      if (value.clinicalDataType === type)
        questionnaireType = value.questionnaireType;
    });
    if (questionnaireType) return questionnaireType;
    else
      throw new Error(
        `Can not match clinical data type ${type} to a questionnaire type. Add an item to the ClinicalDataTypeMeasurement map.`
      );
  }
}
