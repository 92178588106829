import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import {
  CollapsibleCardControlled,
  DefaultButton,
  IDropdownOption,
  IIconProps,
  Link,
  PersonaSize,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  BillingStatuses,
  InvoiceStatus
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AppointmentStatusCode,
  CalendarEventAttendeeDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { openNewInvoiceFromCalendar } from "@modules/booking/utils/booking.utils.ts";
import { ageDescription } from "@modules/practice/screens/contact-details/patient/view/utils.ts";
import { PatientNoticesAddButton } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesAddButton.tsx";
import {
  PatientNoticesContextProvider,
  usePatientNoticesContext
} from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesContext.tsx";
import {
  NoticeType,
  PatientNoticesModel
} from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { PatientNoticesTable } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTable.tsx";
import { Labels } from "@modules/practice/screens/shared-components/types/labels.enums.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { AppointmentStatusDropdown } from "../../shared-components/appointment-status/AppointmentStatusDropdown.tsx";
import { invoiceStatusIcon } from "../BookingCalendarEvent.types.ts";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

interface AttendeeRowProps {
  attendee: CalendarEventAttendeeDto;
}

const AttendeeRowBase: React.FC<AttendeeRowProps> = observer(({ attendee }) => {
  const theme = useTheme();
  const root = useStores();
  const { practice, booking, core, routing } = root;

  const { getFilteredPatientNotices, systemNotices } =
    usePatientNoticesContext();

  const {
    calendarEvent,
    setPreventDismiss,
    openedAttendeeId,
    setOpenedAttendeeId
  } = useContext(BookingCalendarEventContext);

  const { attendeeInvoices } = useBookingCalendarScreenContext();
  if (!attendee) return null;

  const attendeeId = attendee.attendeeId;
  const invoiceStatus =
    calendarEvent.getCalendarEventExtensionAttendee(attendeeId)?.invoiceStatus;

  const contact = practice.contactsMap.get(attendeeId);
  if (!contact) return null;

  const patientId = contact.id;

  const attendeeInvoice = attendeeInvoices.find(x => {
    return x?.attendeeId === attendeeId;
  });

  const isInvoiced =
    attendeeInvoice &&
    !!attendeeInvoice.invoiceId &&
    attendeeInvoice.billingStatus !== BillingStatuses.cancelled;

  const isFutureDayAppointment =
    calendarEvent.startDateTime.startOf("day") > DateTime.today();

  const onStatusChange = async (
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    if (!option) return;

    const { key: code } = option;

    if (code !== attendee.attendeeStatus) {
      await booking.updateCalendarEventAttendeeStatusChange(
        calendarEvent.id,
        attendeeId,
        code as AppointmentStatusCode
      );
    }
  };

  const iconProps: IIconProps = isInvoiced
    ? {
        iconName: invoiceStatusIcon[invoiceStatus || InvoiceStatus.Settled],
        styles: ({ theme }) => ({
          root: { color: theme?.palette.neutralPrimary }
        })
      }
    : { iconName: "M365InvoicingLogo" };

  const onInvoiceClick = () => {
    if (isInvoiced && attendeeInvoice && attendeeInvoice.invoiceId) {
      routing.push(
        routes.accounts.invoices.invoice.path({
          id: attendeeInvoice.invoiceId
        })
      );
    } else {
      openNewInvoiceFromCalendar(root, calendarEvent.id, attendeeId);
    }
  };

  const noticeCount =
    getFilteredPatientNotices(NoticeType.Admin).length + systemNotices.length;

  const isOpened: boolean = openedAttendeeId === patientId;

  return (
    <CollapsibleCardControlled
      setIsOpened={isOpening =>
        setOpenedAttendeeId(isOpening ? patientId : undefined)
      }
      isOpened={isOpened}
      closeSpeed={500}
      styles={{
        collapseContent: { padding: 8, border: "none" },
        header: { padding: 8 },
        subComponentStyles: {
          tile: {
            root: {
              border: `1px solid ${theme.palette.neutralSecondaryAlt}`,
              boxShadow: "none"
            }
          }
        }
      }}
      onRenderHeading={isOpened => (
        <>
          <Persona
            id={patientId}
            text={contact.name}
            size={PersonaSize.size40}
            imageInitials={contact.initials}
            styles={{ root: { flexGrow: 1, paddingLeft: 3 } }}
            onRenderSecondaryText={() => {
              if (noticeCount > 0 && !isOpened) {
                const linkText = `View (${noticeCount}) notice${
                  noticeCount > 1 ? "s" : ""
                }`;

                return <Link>{linkText}</Link>;
              }
              return (
                <Stack>
                  {contact.primaryCommunication ? (
                    <Text variant="small">
                      {contact.primaryCommunication?.value ?? ""}
                    </Text>
                  ) : null}
                  <Text variant="small">
                    {ageDescription(contact) || Labels.noDobRecorded}
                  </Text>
                </Stack>
              );
            }}
          />

          <AppointmentStatusDropdown
            onClick={ev => ev.stopPropagation()} // Prevent collapsing card
            onChange={onStatusChange}
            defaultSelectedKey={
              attendee.attendeeStatus ?? AppointmentStatusCode.Booked
            }
            disabled={
              !core.hasPermissions(Permission.CalendarEventWrite) ||
              isFutureDayAppointment
            }
            styles={{
              root: { minWidth: 150 }
            }}
            currentattendeeAppointmentStatus={
              attendee.attendeeStatus ?? AppointmentStatusCode.Booked
            }
          />
          <When permission={Permission.InvoiceCreate}>
            <DefaultButton
              iconProps={iconProps}
              styles={{
                root: { width: 55, minWidth: 0, marginLeft: 8 }
              }}
              onClick={ev => {
                ev.stopPropagation(); // Prevent collapsing card
                onInvoiceClick();
              }}
              disabled={isFutureDayAppointment}
            />
          </When>
        </>
      )}
    >
      <PatientNoticesAddButton filter={NoticeType.Admin} />
      <PatientNoticesTable
        showActions
        padding={0}
        childrenGap={0}
        setPreventDismissOnDelete={value => {
          // Collapse the card if the last notice has been deleted
          if (!value && noticeCount === 1) setOpenedAttendeeId(undefined);
          setPreventDismiss(value);
        }}
        filter={NoticeType.Admin}
      />
    </CollapsibleCardControlled>
  );
});

export const AttendeeRow: React.FC<AttendeeRowProps> = props => {
  const { practice } = useStores();

  return (
    <PatientNoticesContextProvider
      value={
        new PatientNoticesModel(practice, {
          patientId: props.attendee.attendeeId,
          withSystemNotices: true
        })
      }
    >
      <AttendeeRowBase {...props} />
    </PatientNoticesContextProvider>
  );
};
