import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { EpisodeOfCareBadgesLabels } from "./condition-modal/Condition.types.ts";

interface ClaimBadgesProps {
  condition: ICondition;
}

export const EpisodeOfCareBadges: React.FC<ClaimBadgesProps> = ({
  condition
}) => {
  const { core } = useStores();

  const showBadge = core.hasPermissions(Permission.ClaimRead);
  const getBadgeJSX = (text: string) => (
    <TextBadge
      key={text}
      badgeSize={TextBadgeSize.small}
      badgeColor={TextBadgeColor.blue}
    >
      {text}
    </TextBadge>
  );

  const getBadges = () => {
    const badges = [];
    badges.push(getBadgeJSX(condition.primaryDiagnosis));

    if (
      showBadge &&
      condition.claim?.claimNumber &&
      !condition.referralNumber
    ) {
      badges.push(getBadgeJSX(condition.claim.claimNumber));
    }

    if (showBadge && condition.isPrivate) {
      badges.push(getBadgeJSX(EpisodeOfCareBadgesLabels.Private));
    }

    if (condition.referralNumber) {
      badges.push(
        getBadgeJSX(EpisodeOfCareBadgesLabels.Referred),
        getBadgeJSX(condition.referralNumber)
      );
    }

    if (showBadge && condition.claim?.isOnHold) {
      badges.push(getBadgeJSX(EpisodeOfCareBadgesLabels.OnHold));
    }
    return badges;
  };

  return <>{getBadges()}</>;
};
