import { observer } from "mobx-react-lite";
import { FC } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { PurchaseOrder } from "@stores/acc/models/PurchaseOrder.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { PurchaseOrderDialog } from "./PurchaseOrderDialog.tsx";

export const PurchaseOrderButton: FC = observer(() => {
  const {
    showPurchaseOrderDialog,
    setShowPurchaseOrderDialog,
    purchaseOrderId
  } = useClaimAdjustmentContext();
  return (
    <div>
      <DefaultButton
        iconProps={{ iconName: "Add" }}
        text="New purchase order"
        onClick={e => {
          e.stopPropagation();
          setShowPurchaseOrderDialog(true);
        }}
      />
      {showPurchaseOrderDialog && (
        <DataFetcher<PurchaseOrder | undefined>
          fetch={({ acc }) =>
            purchaseOrderId
              ? acc.getPurchaseOrder(purchaseOrderId)
              : Promise.resolve(undefined)
          }
          refetchId={purchaseOrderId}
        >
          {purchaseOrder => {
            return (
              <PurchaseOrderDialog
                onDismiss={() => setShowPurchaseOrderDialog(false)}
                purchaseOrder={purchaseOrder}
              />
            );
          }}
        </DataFetcher>
      )}
    </div>
  );
});
