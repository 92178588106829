import { EmployerDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  email,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

interface EmployerFieldsValidatorProps {
  usingPrivateInsurer: boolean;
}

export class EmployerFieldsValidator extends Validator<EmployerDto> {
  constructor(options: EmployerFieldsValidatorProps) {
    super();
    this.forField(
      "privateInsurerContactId",
      predicate(() => options.usingPrivateInsurer, required())
    );
    this.forField("claimsAdminEmail", email());
    this.forField("invoicingEmail", email());
  }
}
