import { memo } from "react";
import { Field } from "react-final-form";

import { dataAttribute, DataAttributes, MessageBar } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";

import { ContactPickerFieldWithAdd } from "../../../../shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { Labels } from "../../../../shared-components/types/labels.enums.types.ts";
import { professionalRelationships } from "../../../constants/professional-relationships.constant.ts";
import { RelationshipTypeDescription } from "./RelationshipTypeDescription.tsx";
import { RelationshipFieldValue } from "./utils.ts";

export type RelationshipFieldsOrgProps = {
  name: string;
  onRemove: () => void;
  fullName: string | undefined;
};

const RelationshipFieldsOrgBase: React.FC<RelationshipFieldsOrgProps> = memo(
  ({ onRemove, fullName, name }) => {
    const {
      practice: { ref }
    } = useStores();

    return (
      <Field<RelationshipFieldValue> name={name} subscription={{ value: true }}>
        {({
          input: {
            value: { relatedContactId, professional, isNew }
          }
        }) => {
          const relationships = [...professional];
          return (
            <>
              <ContactPickerFieldWithAdd
                quickAddProps={{
                  typeToAdd: ContactType.Organisation,
                  addButtonLabel: Labels.newOrganisation
                }}
                autoFocus={isNew}
                actionButton={{
                  linkProps: {
                    children: "Remove",
                    onClick: onRemove
                  }
                }}
                label={Labels.relatedOrganisation}
                filter={{
                  types: [ContactType.Organisation]
                }}
                pickerCalloutProps={{
                  calloutWidth: 300
                }}
                pickerSuggestionsProps={{
                  noResultsFoundText: "No match"
                }}
                name={`${name}.relatedContactId`}
                iconName="Search"
                placeholder="Search for organisation"
                onResolveSuggestionItem={contact => ({
                  text: contact.name
                })}
              />
              <>
                {relatedContactId && (
                  <>
                    <StaticTagPickerField
                      fetchDataSource={() =>
                        ref.relationshipTypes.keyNameValues.filter((x: any) =>
                          professionalRelationships.includes(x.key)
                        )
                      }
                      label="Has a legal or professional relationship"
                      name={`${name}.professional`}
                      excludeSelectedItems={true}
                      styles={{
                        text: { flexGrow: 0, maxWidth: 568 }
                      }}
                    />
                    <MessageBar
                      {...dataAttribute(
                        DataAttributes.Element,
                        "related-org-message-bar"
                      )}
                      styles={({ theme }) => ({
                        root: {
                          backgroundColor: theme.palette.neutralLighterAlt,
                          maxWidth: 568
                        }
                      })}
                    >
                      <ContactFetcher contactId={relatedContactId}>
                        {contact => contact.name}
                      </ContactFetcher>
                      &nbsp;
                      <span style={{ textTransform: "lowercase" }}>
                        {relationships.length > 0 ? (
                          <RelationshipTypeDescription codes={relationships} />
                        ) : (
                          "is linked to"
                        )}
                      </span>
                      &nbsp;
                      {fullName}
                    </MessageBar>
                  </>
                )}
              </>
            </>
          );
        }}
      </Field>
    );
  }
);

export const RelationshipFieldsOrg = withFetch(
  x => x.practice.ref.relationshipStatuses.load(),
  RelationshipFieldsOrgBase
);
