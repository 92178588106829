import { FunctionComponent } from "react";

import {
  concatStyleSetsWithProps,
  GenericPicker,
  IBasePickerStyleProps,
  IPickerItemProps
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { PickerIcon } from "@ui-components/pickers/contact-picker/PickerIcon.tsx";

import {
  ServicePickerProps,
  ServicePickerTag,
  ServicePickerType
} from "./ServicePicker.types.ts";
import { ServiceSuggestionItem } from "./ServiceSuggestionItem.tsx";
import { ServiceTagItem } from "./ServiceTagItem.tsx";

export const ServicePicker: FunctionComponent<ServicePickerProps> = ({
  onChange,
  selectedKey,
  styles,
  inputProps,
  disabled
}) => {
  const { billing } = useStores();
  const mergedStyles = (props: IBasePickerStyleProps) =>
    concatStyleSetsWithProps(
      props,
      {
        text: !props.isFocused &&
          !selectedKey && {
            minWidth: "auto",
            paddingLeft: 32
          },
        itemsWrapper: {
          width: "100%"
        },
        root: (props.isFocused || selectedKey) && {
          selectors: {
            "&+.picker-icon": {
              display: "none"
            }
          }
        }
      },
      styles
    );

  const getSchedules = async (scheduleIds: string[]) => {
    const schedulesToGet = scheduleIds.filter(
      id => !billing.schedulesMap.has(id)
    );
    if (schedulesToGet.length > 0) {
      await billing.getSchedules({ scheduleIds: schedulesToGet });
    }
  };

  const onSearchItem = async (filter: string) => {
    const services = await billing.getServiceSearch({
      searchText: filter,
      effectiveDate: DateTime.now().startOf("day").toISODate(),
      excludeDescription: true
    });

    const uniqueScheduleIds = Array.from(
      new Set(services.map(({ scheduleId }) => scheduleId))
    );
    await getSchedules(uniqueScheduleIds);

    const results: ServicePickerType[] = services.map(ser => ({
      serviceId: ser.serviceId,
      scheduleName: billing.schedulesMap.get(ser.scheduleId)?.name || "",
      name: ser.name || "",
      code: ser.code
    }));

    return {
      results
    };
  };

  const onFetchItem = async (id: string): Promise<ServicePickerType> => {
    const service = await billing.getService(id);

    const { id: serviceId, scheduleId, code, name } = service!;

    const schedule = await billing.getSchedule(scheduleId);

    return {
      serviceId,
      scheduleName: schedule?.name || "",
      name,
      code
    };
  };

  return (
    <div style={{ position: "relative" }}>
      <GenericPicker<ServicePickerType>
        styles={mergedStyles}
        onChange={onChange}
        pickerCalloutProps={{ calloutWidth: 400 }}
        selectedKey={selectedKey}
        keyAccessor={dto => dto.serviceId}
        disabled={disabled}
        inputProps={{
          ...inputProps,
          placeholder: inputProps?.placeholder ?? "Service search"
        }}
        onResolveSuggestionItem={dto => ({ key: dto.code, ...dto })}
        onSearchItems={onSearchItem}
        onRenderItem={(props: IPickerItemProps<ServicePickerTag>) => (
          <ServiceTagItem
            onRemoveItem={() => onChange && onChange(undefined)}
            {...props}
          />
        )}
        onRenderSuggestionsItem={(item: ServicePickerTag) => (
          <ServiceSuggestionItem item={item} />
        )}
        onFetchItem={onFetchItem}
      />
      <PickerIcon iconName="Search" />
    </div>
  );
};
