import { observer } from "mobx-react-lite";
import React from "react";

import { dataAttribute, DataAttributes, Stack, Toggle } from "@bps/fluent-ui";
import { AccBenefitCountDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  NzPracticeAccContractDto,
  OrgUnitCompanyDataDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { orgUnitCompanyDataToNzAccContracts } from "@stores/practice/models/PracOrgUnit.ts";

import { getContractClassWithCount } from "./utils.ts";

export interface AccContractTogglesProps {
  orgUnitCompanyData: OrgUnitCompanyDataDto[];
  accBenefitCounts: AccBenefitCountDto[];
  handleToggle: (contract: NzPracticeAccContractDto) => void;
}

export const AccContractToggles: React.FC<AccContractTogglesProps> = observer(
  ({ orgUnitCompanyData, accBenefitCounts, handleToggle }) => {
    const { practice } = useStores();
    const contracts = orgUnitCompanyDataToNzAccContracts(orgUnitCompanyData)!
      .contracts.slice()
      .sort((a, b) =>
        a.accPracticeContractTypeCode.localeCompare(
          b.accPracticeContractTypeCode
        )
      );
    return (
      <Stack>
        {contracts.map(contract => {
          const code = contract.accPracticeContractTypeCode;
          const baseText =
            practice.ref.accPracticeContractTypes.get(code)?.text;

          return (
            <Toggle
              key={code}
              {...dataAttribute(DataAttributes.Data, code)}
              automationAttribute="contract-toggle"
              styles={{ root: { marginBottom: 0 } }}
              inlineLabel
              label={getContractClassWithCount(
                baseText,
                code,
                accBenefitCounts
              )}
              checked={contract.isSubscribed}
              onClick={() => handleToggle(contract)}
            />
          );
        })}
      </Stack>
    );
  }
);
