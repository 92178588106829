import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";

enum ReminderButtonLabels {
  Add = "Add a reminder",
  Delete = "Delete"
}

export const RemindersListButtons = observer(() => {
  const {
    setActivityDialogVisible,
    activityDialogVisible,
    selectedClinicalActivities
  } = useContext(InboxScreenContext);

  const { clinical } = useStores();

  return (
    <>
      <When
        permission={[
          Permission.ClinActivityWrite,
          Permission.PatientReminderWrite
        ]}
      >
        <Stack.Item>
          <DefaultButton
            disabled={clinical.ui.clinicalActivityActionDisabled}
            onClick={() =>
              setActivityDialogVisible({
                ...activityDialogVisible,
                add: true
              })
            }
            text={ReminderButtonLabels.Add}
            iconProps={{ iconName: "Add" }}
          />
        </Stack.Item>
      </When>
      <When permission={[Permission.PatientReminderDelete]}>
        <Stack.Item>
          <DefaultButton
            disabled={
              clinical.ui.clinicalActivityActionDisabled ||
              selectedClinicalActivities.length === 0
            }
            onClick={() =>
              setActivityDialogVisible({
                ...activityDialogVisible,
                delete: true
              })
            }
            text={ReminderButtonLabels.Delete}
          />
        </Stack.Item>
      </When>
    </>
  );
});
