import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

const isValidMedicareNumber = (val?: string) => {
  if (val && val) {
    if (!new RegExp("\\d{10}").test(val)) {
      return false;
    }

    if (val[0].match(/[2-6]/g) === null) {
      return false;
    }

    const PATTERN = /^(\d{8})(\d)/;
    const newVal = val.toString().replace(/ /g, "");

    if (newVal.length === 10) {
      const matches = PATTERN.exec(newVal);
      if (matches) {
        const base: any = matches[1];
        const checkDigit: string = matches[2];
        let total: number = 0;
        const multipliers: any[] = [1, 3, 7, 9, 1, 3, 7, 9];

        for (let i = 0; i < multipliers.length; i++) {
          total += base[i] * multipliers[i];
        }
        return total % 10 === Number(checkDigit);
      }
    }
  }
  return false;
};

const medicareNumber: FieldValidator = (val: string | undefined) => {
  return !val || isValidMedicareNumber(val)
    ? undefined
    : ValidationMessages.invalid;
};

export class MedicareNumberValidator extends Validator<{
  number?: string;
  irnNumber: string;
}> {
  constructor() {
    super();
    this.forField("number", [required(), medicareNumber]);
    this.forField("irnNumber", [required(), maxLength(1)]);
  }
}
