import { observer, Observer } from "mobx-react-lite";
import { FunctionComponent, ReactElement, useRef } from "react";

import {
  CenteredBox,
  DetailsHeader,
  FontIcon,
  IColumn,
  IDetailsHeaderProps,
  IDragDropContext,
  IDragDropEvents,
  IShimmeredDetailsListProps,
  Link,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Stack,
  Text,
  TextBadge,
  TextBadgeSize,
  TooltipHost,
  useFormContext,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useAppointmentTypeScreenContext } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";
import { AppointmentTypesFilter } from "@shared-types/booking/appointment-type-filter.type.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { AppointmentTypeScreenItem } from "../AppointmentTypeScreen.types.ts";
import { AppointmentsOnlineDisplayContextualMenu } from "./AppointmentsOnlineDisplayContextualMenu.tsx";
import { OnlineBookingCell } from "./OnlineBookingCell.tsx";

const nameOf = nameOfFactory<AppointmentType>();
export interface AppointmentTypeListProps {
  isOnlineAppointments?: boolean;
}
export const AppointmentTypeList: FunctionComponent<AppointmentTypeListProps> =
  observer(({ isOnlineAppointments }) => {
    const { booking, bhb, core } = useStores();
    const {
      ui: { lastUpdatedAppointmentTypeETag }
    } = booking;

    const {
      state: { values }
    } = useFormContext<AppointmentTypesFilter>();

    const draggedRef = useRef<AppointmentTypeScreenItem | undefined>();

    const {
      setAppointmentType,
      getItems,
      getItemsForOnlineFilter,
      setBHBAppointmentType,
      setShowOnlineSettingModal,
      setOmniAppointmentType,
      updateSorting,
      bhbAppointmentTypes,
      setBhbAppointmentTypes
    } = useAppointmentTypeScreenContext();

    const theme = useTheme();

    enum AppointmentCancellableStatus {
      Online = "Online",
      CallOnly = "Call only"
    }

    const renderCell = (render: () => ReactElement) => (
      <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 8 }}>
        <Observer>{render}</Observer>
      </Stack>
    );

    const renderAppointmentType = (item: AppointmentTypeScreenItem) =>
      renderCell(() => (
        <>
          <Link
            onClick={() => setAppointmentType(item.appointmentType)}
            id={`appt-type-id-${item.appointmentType?.id}`}
          >
            {item.appointmentType?.name}
          </Link>
          {item.appointmentType?.isGroupAppointmentType && (
            <TextBadge
              badgeSize={TextBadgeSize.small}
              customColors={{
                backgroundColor: theme.palette.neutralLighter
              }}
            >
              Group appointment
            </TextBadge>
          )}
          {item.appointmentType?.isInactive && (
            <TooltipHost content="Inactive">
              <FontIcon iconName="UserRemove" />
            </TooltipHost>
          )}
        </>
      ));

    const renderOnlineAppointmentType = (item: AppointmentTypeScreenItem) =>
      renderCell(() => (
        <>
          <Stack
            styles={{
              root: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxwidth: "200px",
                display: "inline-block"
              }
            }}
          >
            {item.appointmentType?.name}
          </Stack>
          {item.appointmentType?.isGroupAppointmentType && (
            <TextBadge
              badgeSize={TextBadgeSize.small}
              customColors={{
                backgroundColor: theme.palette.neutralLighter
              }}
            >
              Group appointment
            </TextBadge>
          )}
          {item.appointmentType?.isInactive && (
            <TooltipHost content="Inactive">
              <FontIcon iconName="UserRemove" />
            </TooltipHost>
          )}
        </>
      ));

    const renderIcon = (item: AppointmentTypeScreenItem) =>
      renderCell(() => (
        <FontIcon
          iconName={item.appointmentType?.icon}
          styles={{ root: { width: 20 } }}
        />
      ));

    const renderOnlineAppointmentName = (item: AppointmentTypeScreenItem) => {
      const { appointmentType, bhbAppointmentType } = item;
      if (
        appointmentType?.isInactive ||
        !bhbAppointmentType ||
        appointmentType?.isGroupAppointmentType
      )
        return null;
      return renderCell(() => (
        <Link
          styles={{
            root: {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxwidth: "200px"
            }
          }}
          onClick={async () => {
            const appointmentType = await booking.getAppointmentType(
              item.appointmentType?.id!
            );
            setOmniAppointmentType(appointmentType);
            setBHBAppointmentType(bhbAppointmentType);
            setShowOnlineSettingModal(true);
          }}
        >
          {item.bhbAppointmentType?.onlineName || item.appointmentType?.name}
        </Link>
      ));
    };

    const renderDuration = (item: AppointmentTypeScreenItem) =>
      renderCell(() => {
        const mins = item.appointmentType?.duration
          ? item.appointmentType?.duration
          : 0;

        let patientMinuteDuration =
          item.bhbAppointmentType?.newPatientMinuteDuration;

        if (!patientMinuteDuration) {
          patientMinuteDuration = item.bhbAppointmentType?.newPatientDuration
            ? item.bhbAppointmentType?.newPatientDuration / 60
            : undefined;
        }

        let returnText = mins > 0 ? `${mins}` : "";

        if (
          item.bhbAppointmentType?.isAvailableExistingPatients &&
          item.bhbAppointmentType?.isAvailableNewPatients
        ) {
          if (mins > 0 && patientMinuteDuration) {
            returnText = `${mins}/${patientMinuteDuration}`;
          }
        } else if (item.bhbAppointmentType?.isAvailableNewPatients) {
          if (patientMinuteDuration) {
            returnText = `${patientMinuteDuration}`;
          }
        }

        return <>{returnText}</>;
      });

    const renderStatus = (item: AppointmentTypeScreenItem) =>
      renderCell(() => (
        <>{item.appointmentType?.isInactive ? "Inactive" : "Active"}</>
      ));

    const renderOnlineStatus = (item: AppointmentTypeScreenItem) => {
      const { appointmentType, bhbAppointmentType } = item;

      if (
        appointmentType?.isInactive ||
        !bhbAppointmentType ||
        appointmentType?.isGroupAppointmentType
      )
        return null;
      let cancellableType = AppointmentCancellableStatus.CallOnly;

      return renderCell(() => {
        if (bhbAppointmentType && !!bhbAppointmentType.isCancellable) {
          cancellableType = bhbAppointmentType.isCancellable
            ? AppointmentCancellableStatus.Online
            : AppointmentCancellableStatus.CallOnly;
        }
        return <>{cancellableType}</>;
      });
    };

    const renderOnlineBooking = (item: AppointmentTypeScreenItem) => {
      const { appointmentType, bhbAppointmentType } = item;

      if (
        appointmentType?.isInactive ||
        !bhbAppointmentType ||
        appointmentType?.isGroupAppointmentType
      )
        return null;

      return renderCell(() => (
        <OnlineBookingCell
          appointmentType={appointmentType!}
          bhbAppointmentType={bhbAppointmentType}
        />
      ));
    };

    let columns: IColumn[] = isOnlineAppointments
      ? [
          {
            name: "",
            className: RESET_CELLS_PADDING_CLASSNAME,
            key: "action",
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: AppointmentTypeScreenItem) => (
              <AppointmentsOnlineDisplayContextualMenu item={item} />
            )
          },
          {
            key: "move",
            name: "Order",
            fieldName: "move",
            minWidth: 40,
            maxWidth: 40,
            onRender: (item: AppointmentTypeScreenItem) => {
              return !item.appointmentType?.isInactive &&
                item.bhbAppointmentType?.sortOrder ? (
                <Stack horizontal verticalAlign="center">
                  <Text>{item.bhbAppointmentType?.sortOrder}</Text>
                  <FontIcon
                    iconName="move"
                    styles={{ root: { cursor: "grab", paddingLeft: "6px" } }}
                  />
                </Stack>
              ) : null;
            }
          },
          {
            name: "Type",
            onRender: renderOnlineAppointmentType,
            key: nameOf("name"),
            minWidth: 155,
            maxWidth: 200
          },
          {
            name: "Online name",
            onRender: renderOnlineAppointmentName,
            key: nameOf("onlineName"),
            minWidth: 155,
            maxWidth: 200
          },
          {
            name: "Cancelation / rescheduling",
            onRender: renderOnlineStatus,
            key: nameOf("isInactive"),
            minWidth: 80,
            maxWidth: 102,
            onRenderHeader: () => (
              <Stack
                styles={{
                  root: {
                    lineHeight: "18px",
                    paddingTop: "4px",
                    paddingBottom: "4px"
                  }
                }}
              >
                <Stack>Cancelation /</Stack>
                <Stack>rescheduling</Stack>
              </Stack>
            )
          },
          {
            name: "Availability",
            onRender: renderOnlineBooking,
            key: "onlineBooking",
            minWidth: 90,
            maxWidth: 110
          },
          {
            name: "Duration",
            onRender: renderDuration,
            key: nameOf("duration"),
            minWidth: 55,
            maxWidth: 55,
            onRenderHeader: () => (
              <Stack
                styles={{
                  root: {
                    lineHeight: "18px",
                    paddingTop: "4px",
                    paddingBottom: "4px"
                  }
                }}
              >
                <Stack>Duration</Stack>
                <Stack>(mins)</Stack>
              </Stack>
            )
          }
        ]
      : [
          {
            name: "Icon",
            onRender: renderIcon,
            key: nameOf("icon"),
            minWidth: 30,
            maxWidth: 60
          },
          {
            name: "Appointment type",
            onRender: renderAppointmentType,
            key: nameOf("name"),
            minWidth: 300,
            maxWidth: 600
          },
          {
            name: "Duration",
            onRender: renderDuration,
            key: nameOf("duration"),
            minWidth: 300,
            maxWidth: 400
          },
          {
            name: "Online booking",
            onRender: renderOnlineBooking,
            key: "onlineBooking",
            minWidth: 300,
            maxWidth: 400
          },
          {
            name: "Status",
            onRender: renderStatus,
            key: nameOf("isInactive"),
            minWidth: 200,
            maxWidth: 400
          }
        ];

    if (!core.hasPermissions(Permission.BhbRead)) {
      columns = columns.filter(col => col.key !== "onlineBooking");
    }

    const onRenderDetailsHeader = (props: IDetailsHeaderProps) => {
      return (
        <DetailsHeader
          {...props}
          styles={() => ({
            root: {
              height: "48px",
              cellTitle: { justifyContent: "center" }
            }
          })}
        />
      );
    };

    const onRenderRow: IShimmeredDetailsListProps["onRenderRow"] = (
      props,
      defaultRender
    ) => {
      if (!props || !defaultRender) {
        return null;
      }
      return defaultRender({
        ...props,
        styles: {
          cell: {
            minHeight: 49,
            paddingTop: 15
          }
        }
      });
    };

    // Only allow dropping over the bounds of other appointments that are also available online
    const dragDropEvents: IDragDropEvents = {
      canDrag: () => {
        return true;
      },

      canDrop: (dropContext?: IDragDropContext) => {
        const bhbAppointmenttType = (
          dropContext?.data as AppointmentTypeScreenItem
        ).bhbAppointmentType;
        return (
          !!bhbAppointmenttType?.isAvailableExistingPatients ||
          !!bhbAppointmenttType?.isAvailableNewPatients
        );
      },

      onDragStart: (item: AppointmentTypeScreenItem) => {
        draggedRef.current = item;
      },

      onDragEnd: () => {
        draggedRef.current = undefined;
      },

      onDrop: async (targetItem?: AppointmentTypeScreenItem) => {
        if (targetItem && draggedRef.current) {
          const reorder = updateSorting(targetItem, draggedRef.current);
          if (reorder !== undefined) {
            const updatedBhbAppointmentTypes =
              await bhb.updateAppointmentTypeSortOrderForLocation(reorder);
            setBhbAppointmentTypes(updatedBhbAppointmentTypes);
          }
        }

        draggedRef.current = undefined;
      }
    };

    return (
      <Observer>
        {() => (
          <DataFetcher
            fetch={() =>
              Promise.all([
                booking.fetchAppointmentTypes({ ...values }),
                bhb.getAppointmentTypesForLocation()
              ]).then(([appointmentTypes, bhbAppointmentTypes]) => {
                setBhbAppointmentTypes(bhbAppointmentTypes);
                return appointmentTypes;
              })
            }
            refetchId={`${lastUpdatedAppointmentTypeETag}-${JSON.stringify(
              values
            )}`}
          >
            {appointmentTypes => {
              if (!appointmentTypes.length) {
                return (
                  <CenteredBox>
                    <DefaultNoResultsTile defaultText="No records found" />
                  </CenteredBox>
                );
              }

              return (
                <Stack
                  grow
                  styles={{ root: { position: "relative", height: "100%" } }}
                >
                  <ScrollablePane>
                    <ShimmeredDetailsList
                      items={
                        isOnlineAppointments
                          ? getItemsForOnlineFilter(
                              appointmentTypes,
                              bhbAppointmentTypes,
                              values
                            )
                          : getItems(appointmentTypes, bhbAppointmentTypes)
                      }
                      columns={columns}
                      detailsListStyles={{
                        root: {
                          overflowX: "hidden"
                        }
                      }}
                      onRenderRow={onRenderRow}
                      onRenderDetailsHeader={onRenderDetailsHeader}
                      dragDropEvents={dragDropEvents}
                    />
                  </ScrollablePane>
                </Stack>
              );
            }}
          </DataFetcher>
        )}
      </Observer>
    );
  });
