import { observer } from "mobx-react-lite";

import {
  GroupedList,
  Heading,
  ThematicButton,
  ThematicButtonData,
  ThematicButtonItem
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  ClinicalDataTypeLabel
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { PatientRecordScreenHelper } from "@modules/clinical/screens/context/PatientRecordScreenHelper.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { getClinicalDataLastUpdatedDate } from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { SideNavContent } from "../SideNavContent.tsx";

export interface FamilySocialHistoryProps {
  clinicalRecord: ClinicalRecord;
}

export const FamilySocialHistory: React.FC<FamilySocialHistoryProps> = observer(
  ({ clinicalRecord }) => {
    const { clinical } = useStores();

    const socialItems: (ThematicButtonItem & { date?: DateTime })[] = [
      {
        key: "social-history",
        name: "Social history",
        icon: "BpSocial",
        date: getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.socialHistoryConfirmed
        ),
        onClick: () => {
          clinical.ui.setPatientClinicalContent({
            type: ClinicalDataType.Social
          });
        }
      },
      {
        key: "work-history",
        name: "Work history",
        icon: "BpWorkHistory",
        date: getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.workHistoryConfirmed
        ),
        onClick: () => {
          clinical.ui.setPatientClinicalContent({
            type: ClinicalDataType.WorkHistory
          });
        }
      }
    ];

    const socialData = {
      key: "social",
      name: "Social",
      items: socialItems
    };

    const familyItems: (ThematicButtonItem & { date?: DateTime })[] = [];

    familyItems.push({
      key: "FamilyHistory",
      name: ClinicalDataTypeLabel.FamilyHistory,
      icon: "BpFamilyTree",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.familyHistoryConfirmed
      ),
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.FamilyHistory
        });
      }
    });

    const familyData = {
      key: "family",
      name: "Family",
      items: familyItems
    };

    const lifeStyleItems: (ThematicButtonItem & { date?: DateTime })[] = [
      {
        key: "physical-activity",
        name: ClinicalDataTypeLabel.PhysicalActivity,
        icon: "BpActivity",
        date: getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.physicalActivityConfirmed
        ),
        onClick: () => {
          clinical.ui.setPatientClinicalContent({
            type: ClinicalDataType.PhysicalActivity
          });
        }
      }
    ];

    lifeStyleItems.push({
      key: "sleep",
      name: ClinicalDataTypeLabel.Sleep,
      icon: "BpSleep",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.sleepConfirmed
      ),
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.Sleep
        });
      }
    });

    const lifeStyleData = {
      key: "lifestyle",
      name: "Lifestyle",
      items: lifeStyleItems
    };

    const dependenciesData = {
      key: "dependencies-addiction",
      name: "Dependencies & addictions",
      items: [
        {
          key: "alcohol",
          name: ClinicalDataTypeLabel.Alcohol,
          icon: "BpAlcohol",
          date: getClinicalDataLastUpdatedDate(
            clinicalRecord.clinicalData?.alcoholConfirmed
          ),
          onClick: () => {
            clinical.ui.setPatientClinicalContent({
              type: ClinicalDataType.Alcohol
            });
          }
        },
        {
          key: "tobacco",
          name: ClinicalDataTypeLabel.Tobacco,
          icon: "BpSmoking",
          date: getClinicalDataLastUpdatedDate(
            clinicalRecord.clinicalData?.tobaccoConfirmed
          ),
          onClick: () => {
            clinical.ui.setPatientClinicalContent({
              type: ClinicalDataType.Tobacco
            });
          }
        },
        {
          key: "illicit-substances",
          name: ClinicalDataTypeLabel.SubstanceUse,
          icon: "BpSubstances",
          date: getClinicalDataLastUpdatedDate(
            clinicalRecord.clinicalData?.substanceUseConfirmed
          ),
          onClick: () => {
            clinical.ui.setPatientClinicalContent({
              type: ClinicalDataType.SubstanceUse
            });
          }
        }
      ]
    };

    const treeData: ThematicButtonData[] = [
      familyData,
      socialData,
      lifeStyleData,
      dependenciesData
    ];

    return (
      <>
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          Family &amp; social history
        </Heading>

        <SideNavContent>
          <GroupedList
            {...PatientRecordScreenHelper.getGroupedListItems(treeData)}
            compact={true}
            styles={{
              root: {
                selectors: {
                  ".ms-GroupHeader:hover": {
                    background: "inherit"
                  },
                  ".ms-GroupHeader-title": {
                    cursor: "default"
                  },
                  ".ms-GroupHeader-title [class*='header']": {
                    display: "none"
                  }
                },
                minWidth: 375
              },
              group: {
                selectors: {
                  ".ms-List-page": {
                    display: "flex",
                    flexWrap: "wrap"
                  },
                  ".ms-List-page > *": {
                    marginRight: 8,
                    marginBottom: 8
                  }
                }
              }
            }}
            onRenderCell={(
              depth,
              item: ThematicButtonItem & { date?: DateTime }
            ) => {
              return (
                <ThematicButton
                  icon={item.icon}
                  title={item.name}
                  subtitle={
                    item.date
                      ? `Last recorded: ${item.date.toDayDefaultFormat()}`
                      : "Not recorded"
                  }
                  onClick={item.onClick}
                />
              );
            }}
          />
        </SideNavContent>
      </>
    );
  }
);
