import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";

import { WorkingHoursFormFallback } from "../shared-components/WorkingHoursFormFallback.tsx";
import {
  AwayChoice,
  EditUserDayWorkingHoursData,
  EditUserDayWorkingHoursFormValues,
  EditUserDayWorkingHoursProps,
  nameOf
} from "./components/EditUserDayWorkingHours.types.ts";
import { OpeningHoursInfoMessage } from "./components/OpeningHoursInfoMessage.tsx";
import {
  EditUserDayWorkingHoursContext,
  useEditUserDayWorkingHoursContext
} from "./context/EditUserDayWorkingHoursContext.ts";
import { EditUserDayWorkingHoursModel } from "./context/EditUserDayWorkingHoursModel.ts";
import { OutsidePracticeHoursWarning } from "./OutsidePracticeHoursWarning.tsx";
import { EditUserDayWorkingHoursFormValidator } from "./validators/EditUserDayWorkingHoursValidator.tsx";

const EditUserDayWorkingHoursDialogBase: FunctionComponent<EditUserDayWorkingHoursProps> =
  observer(({ user, onClose }) => {
    const { booking } = useStores();
    const { fetch, handleSubmitForm, getInitialValues } =
      useEditUserDayWorkingHoursContext();

    const reloadDismiss = async () => {
      await booking.getUserAvailability(user.id, {
        ignoreCache: true
      });

      onClose();
    };

    return (
      <DataFetcher<EditUserDayWorkingHoursData>
        fetch={fetch}
        noExceptionsHandlers
      >
        {(data, loading, error) => {
          const initialValues = data ? getInitialValues(data) : undefined;

          const getButtonProps: SubmissionFormProps<EditUserDayWorkingHoursFormValues>["buttonsProps"] =
            form => {
              const { values } = form.getState();

              if (!values.date || values.awayChoice === AwayChoice.away)
                return {};

              const start = DateTime.fromJSDateAndTime(
                values.date,
                values.startTime
              );

              const end = DateTime.fromJSDateAndTime(
                values.date,
                values.endTime
              );

              const practiceHours = data?.orgUnitHours?.timeRanges.find(
                timeRange => timeRange.from.hasSame(start, "day")
              );

              const promptOnSubmit =
                !practiceHours ||
                practiceHours.from > start ||
                practiceHours.to < end;

              return {
                promptOnSubmit,
                submitConfirmProps: {
                  title: "Provider working hours outside of practice hours",
                  dialogContentProps: {},
                  confirmButtonProps: {
                    text: "Change hours"
                  },
                  children: (
                    <OutsidePracticeHoursWarning
                      user={user}
                      conflicts={[
                        {
                          start,
                          end,
                          practiceHours
                        }
                      ]}
                    />
                  )
                }
              };
            };

          return (
            <SubmissionFormDialog
              dialogName="Provider working hours dialog"
              initialValues={initialValues}
              onSubmit={handleSubmitForm}
              onSubmitSucceeded={reloadDismiss}
              validate={values => {
                const validator = new EditUserDayWorkingHoursFormValidator();
                return validator.validate(values);
              }}
              dialogProps={{
                onDismiss: onClose,
                minWidth: 600,
                dialogContentProps: { title: `${user.fullName} working hours` }
              }}
              loadingData={loading}
              dataLoadingError={error?.message}
              Fallback={WorkingHoursFormFallback}
              buttonsProps={getButtonProps}
            >
              {() => (
                <Fieldset>
                  <DatePickerField
                    label="Date"
                    name={nameOf("date")}
                    minDate={DateTime.jsDateNow()}
                  />
                  <OpeningHoursInfoMessage />

                  <ChoiceGroupField
                    name={nameOf("awayChoice")}
                    options={[
                      {
                        key: AwayChoice.present,
                        text: "Working from",
                        onRenderField: (props, render) => {
                          const disabled = !props || !props.checked;
                          return (
                            <Stack
                              horizontal
                              verticalAlign="center"
                              tokens={{ childrenGap: 16 }}
                              styles={{
                                root: {
                                  alignItems: "baseline"
                                }
                              }}
                            >
                              {render!(props)}
                              <TimePickerField
                                disabled={disabled}
                                name={nameOf("startTime")}
                                suggestionInterval={30}
                                styles={{
                                  subComponentStyles: {
                                    textField: {
                                      errorMessage: {
                                        alignItems: "flex-start"
                                      }
                                    }
                                  }
                                }}
                              />
                              <span> to </span>
                              <TimePickerField
                                disabled={disabled}
                                name={nameOf("endTime")}
                                suggestionInterval={30}
                                styles={{
                                  subComponentStyles: {
                                    textField: {
                                      errorMessage: {
                                        alignItems: "flex-start"
                                      }
                                    }
                                  }
                                }}
                              />
                            </Stack>
                          );
                        }
                      },
                      {
                        key: AwayChoice.away,
                        text: "Off work"
                      }
                    ]}
                  />

                  <TextInputField
                    label="Reason"
                    name={nameOf("reason")}
                    multiline
                  />
                </Fieldset>
              )}
            </SubmissionFormDialog>
          );
        }}
      </DataFetcher>
    );
  });

export const EditUserDayWorkingHoursDialog: FunctionComponent<
  EditUserDayWorkingHoursProps
> = props => {
  const { booking } = useStores();
  const { startDate } = useBookingCalendarScreenContext();

  const model = new EditUserDayWorkingHoursModel(
    booking,
    props.user,
    startDate
  );
  return (
    <EditUserDayWorkingHoursContext.Provider value={model}>
      <EditUserDayWorkingHoursDialogBase {...props} />
    </EditUserDayWorkingHoursContext.Provider>
  );
};
