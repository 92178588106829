import {
  InvoiceItemReferenceDto,
  TransactionAccountContactDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AddressDto,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { getInvoiceItemOwing } from "@stores/billing/utils/billing.utils.ts";

import { AllocationsListItem } from "./screens/shared-components/AllocationList/types.ts";

export const getAllocation = (
  item: InvoiceItemReferenceDto
): AllocationsListItem => {
  const { invoiceItem, amount } = item;
  return {
    patientName: `${invoiceItem.patient?.firstName || ""} ${
      invoiceItem.patient?.lastName || ""
    }`,
    providerName: `${invoiceItem.user?.firstName || ""} ${
      invoiceItem.user?.lastName || ""
    }`,
    serviceDate: invoiceItem.serviceDate,
    total: amount,
    invoiceNumber: invoiceItem.transaction.number,
    invoiceId: invoiceItem.transactionId,
    code: invoiceItem.code || "",
    description: invoiceItem.name || "",
    comment: item.comment,
    itemTotal: invoiceItem.amount,
    owing: getInvoiceItemOwing(invoiceItem),
    status: invoiceItem.paymentStatus,
    createdDate: item.changeLog?.createdDate
  };
};

export const mapContactToTransactionContact = (contact: {
  type: ContactType;
  phone?: string;
  address?: AddressDto;
  name?: string;
  firstName?: string;
  lastName?: string;
}): TransactionAccountContactDto => {
  const { type, phone, address, name, firstName, lastName } = contact;

  const accountContactBaseProps = {
    phone,
    address
  };

  if (type === ContactType.Organisation && name) {
    return {
      ...accountContactBaseProps,
      name,
      contactType: ContactType.Organisation
    };
  } else if (firstName && lastName) {
    return {
      ...accountContactBaseProps,
      contactType:
        type === ContactType.Patient
          ? ContactType.Patient
          : ContactType.Individual,
      firstName,
      lastName
    };
  } else {
    throw Error(
      "Cannot create contact for transaction missing required fields"
    );
  }
};
