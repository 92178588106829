import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";

import {
  ConfirmDialog,
  dataAttribute,
  DataAttributes,
  Heading
} from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import {
  CalendarEventType,
  Frequency
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { toMonthYearRecurrence } from "@libs/utils/calendar/calendar.utils.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import { toAttendees } from "@stores/booking/models/CalendarEvent.ts";
import { RecurrenceModel } from "@stores/booking/models/RecurrenceModel.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import {
  getCount,
  getDayRecur,
  getMonthDayRecur,
  getMonthRecur,
  getRecurrenceWeekPosition,
  getUntil
} from "../utils.tsx";
import { RecurrenceFormValues } from "./components/RecurrenceForm.types.tsx";
import { RecurrenceFormFields } from "./components/RecurrenceFormFields.tsx";
import { RecurrenceFormValidator } from "./components/RecurrenceFormValidator.ts";

const validator = new RecurrenceFormValidator();
const RecurrenceDialogComponent: FunctionComponent = observer(() => {
  const { core, booking } = useStores();

  const {
    currentRecurrenceId,
    newRecurrenceUserId,
    setCurrentRecurrenceId,
    setNewRecurrenceUserId,
    startDate
  } = useBookingCalendarScreenContext();

  const handleDismiss = () => {
    setCurrentRecurrenceId();
    setNewRecurrenceUserId();
  };

  const initialValues = (
    recurrence: RecurrenceModel | undefined,
    user: User
  ): Partial<RecurrenceFormValues> => {
    if (recurrence) {
      let endScheduleType: EndScheduleType;
      if (recurrence.endDate !== undefined) {
        endScheduleType = EndScheduleType.OnDate;
      } else if (recurrence.count === undefined) {
        endScheduleType = EndScheduleType.Never;
      } else {
        endScheduleType = EndScheduleType.After;
      }

      const locationRecurrStartDate = recurrence.startDate.startOf("day");

      return {
        providerId: recurrence.userId,
        bookedBy: recurrence.bookedBy,
        calendarEventType: recurrence.type,
        startDate: locationRecurrStartDate.toJSDate(),
        endScheduleType,
        startTime: recurrence.startDateTime.toTimeInputFormat(),
        endTime: recurrence.endDateTime.toTimeInputFormat(),
        frequency: recurrence.frequency,
        interval: recurrence.interval,
        count: recurrence.count,
        until:
          endScheduleType === EndScheduleType.OnDate
            ? DateTime.jsDateFromISO(recurrence.until)
            : undefined,
        purpose: recurrence.purpose,
        dayRecur:
          // Note: Month and Year Reoccur to not get shown on the UI control,
          // this gets set dynamically on save.
          recurrence.frequency === Frequency.Week
            ? recurrence.dayRecur || []
            : undefined,
        monthYearRecurrence:
          recurrence.frequency !== Frequency.Week
            ? toMonthYearRecurrence(
                recurrence.weekPosition,
                recurrence.monthDayRecur
              )
            : undefined
      };
    }

    return {
      calendarEventType: CalendarEventType.Unavailable,
      endScheduleType: EndScheduleType.Never,
      startTime: DateTime.now().startOf("hour").toTimeInputFormat(),
      endTime: DateTime.now()
        .startOf("hour")
        .plus({ hours: 1 })
        .toTimeInputFormat(),
      startDate: startDate.toJSDate(),
      orgUnitId: core.locationId,
      bookedBy: core.userId,
      providerId: user.id,
      frequency: Frequency.Week,
      interval: 1
    };
  };

  const onSubmit = async (
    values: RecurrenceFormValues,
    recurrence: RecurrenceModel | undefined
  ) => {
    const frequency = Number(values.frequency);
    const startDate = DateTime.fromJSDate(values.startDate);

    const baseRequest = {
      bookedBy: values.bookedBy,
      type: values.calendarEventType,
      attendees: values.patientId
        ? toAttendees({
            contactId: values.patientId,
            userId: values.providerId
          })
        : toAttendees({
            userId: values.providerId
          }),
      startTime: DateTime.fromFormat(
        values.startTime,
        TIME_FORMATS.TIME_FORMAT_24
      ).toFormat(TIME_FORMATS.TIME_FORMAT_24),
      endTime: DateTime.fromFormat(
        values.endTime,
        TIME_FORMATS.TIME_FORMAT_24
      ).toFormat(TIME_FORMATS.TIME_FORMAT_24),
      purpose: values.purpose,
      startDate: DateTime.jsDateToISODate(values.startDate),
      endDate:
        values.endScheduleType === EndScheduleType.OnDate
          ? DateTime.jsDateToISODate(values.until)
          : undefined,
      recurrenceRule: {
        count: getCount(values.endScheduleType, values.count),
        frequency,
        weekPosition: getRecurrenceWeekPosition(
          startDate,
          values.frequency,
          values.monthYearRecurrence
        ),
        dayRecur: getDayRecur({
          startDate,
          frequency: values.frequency,
          dayRecur: values.dayRecur,
          monthYearRecurrence: values.monthYearRecurrence
        }),
        monthDayRecur: getMonthDayRecur(
          startDate,
          values.frequency,
          values.monthYearRecurrence
        ),
        interval: values.interval,
        monthRecur: getMonthRecur(frequency, startDate),
        until: getUntil(values.endScheduleType, values.until)
      }
    };
    if (recurrence) {
      await booking.updateRecurrence({
        id: recurrence.id,
        ...baseRequest
      });
    } else {
      await booking.addRecurrence({
        orgUnitId: core.locationId,
        ...baseRequest
      });
    }
  };

  return (
    <DataFetcher<{
      user: User;
      recurrence: RecurrenceModel | undefined;
    }>
      fetch={async ({ core, booking }) => {
        let recurrence: RecurrenceModel | undefined;
        if (currentRecurrenceId) {
          recurrence = await booking.getRecurrence(currentRecurrenceId);
        }

        const user = await core.getUser(
          recurrence?.userId! ?? newRecurrenceUserId
        );

        return { user, recurrence };
      }}
      noExceptionsHandlers
    >
      {(data, loading, error) => {
        const dialogName = newRecurrenceUserId
          ? `Add reserve for ${data?.user?.fullName}`
          : `Edit break for ${data?.user?.fullName}`;

        return (
          <SubmissionFormDialog<RecurrenceFormValues>
            loadingData={loading}
            dataLoadingError={error?.message}
            dialogName="Recurrence dialog"
            validate={validator.validate}
            onSubmit={values => onSubmit(values, data?.recurrence)}
            onSubmitSucceeded={handleDismiss}
            initialValues={
              data?.user
                ? initialValues(data?.recurrence, data?.user)
                : undefined
            }
            styles={{ fields: { overflowY: "visible" } }}
            dialogProps={{
              ...dataAttribute(DataAttributes.Element, "recurrence-dialog"),
              minWidth: 600,
              onDismiss: handleDismiss,
              modalProps: { isDarkOverlay: true },
              dialogContentProps: {
                title: <Heading variant="modal-heading">{dialogName}</Heading>
              }
            }}
          >
            {() => <RecurrenceFormFields />}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
});

/**
 * The component handle a case with conformation of changes in series events
 */
export const RecurrenceDialog: FunctionComponent = observer(() => {
  const { currentRecurrenceId, newRecurrenceUserId, setCurrentRecurrenceId } =
    useBookingCalendarScreenContext();

  const [showRecurrenceDialog, setShowRecurrenceDialog] =
    useState<boolean>(false);

  useEffect(() => {
    !currentRecurrenceId && setShowRecurrenceDialog(false);
  }, [currentRecurrenceId, setCurrentRecurrenceId]);

  useEffect(() => {
    setShowRecurrenceDialog(!!newRecurrenceUserId);
  }, [newRecurrenceUserId, setCurrentRecurrenceId]);

  return (
    <>
      {currentRecurrenceId && (
        <ConfirmDialog
          {...dataAttribute(
            DataAttributes.Element,
            "recurrence-conformation-dialog"
          )}
          hidden={false}
          confirmButtonProps={{ text: "Yes" }}
          cancelButtonProps={{ text: "No" }}
          onConfirm={() => {
            setShowRecurrenceDialog(true);
          }}
          modalProps={{ isBlocking: false }}
          onCancel={setCurrentRecurrenceId}
        >
          If you have changed events in the series, your changes will be
          canceled and the events will match the series again
        </ConfirmDialog>
      )}

      {showRecurrenceDialog && <RecurrenceDialogComponent />}
    </>
  );
});
