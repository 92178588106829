import { Validator } from "@libs/validation/Validator.ts";

import { PracticeAccContractsValidator } from "../practice-acc-contracts/PracticeAccContractsValidator.tsx";
import { AccSetupFormValues } from "./AccSetupForm.tsx";

export class AccSetupFormValuesValidator extends Validator<AccSetupFormValues> {
  constructor() {
    super();

    const practiceAccContractsValidator = new PracticeAccContractsValidator();
    this.forArrayField("accContracts", practiceAccContractsValidator.validate);
  }
}
