import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";

export class ClaimDiagnosisRequiredValidator extends Validator<ClaimDiagnosisFormValues> {
  constructor() {
    super();

    this.forField("diagnosisKey", required());
    this.forField("diagnosisSide", required());
  }
}
