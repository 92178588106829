import { Validator } from "@libs/validation/Validator.ts";
import { ActivitiesValidator } from "@modules/clinical/screens/patient-record/components/family-social-history/validators/PhysicalActivityFormValidator.tsx";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

export class FamilyAndSocialCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();
    const activitiesValidator = new ActivitiesValidator();
    this.forArrayField(sotapNameOf("activities"), activitiesValidator.validate);
  }
}
