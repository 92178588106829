import { FontIcon, Stack, Text } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";

import { OverviewSectionListIcon } from "./OverviewSectionListIcon.tsx";

export const HandSplinting = () => {
  const { claimAdjustment } = useClaimAdjustmentContext();
  const { handSplintingCost, handSplintingCostApproved } = claimAdjustment;
  return (
    <Stack
      as="li"
      verticalAlign="center"
      horizontal
      tokens={{ childrenGap: 4 }}
    >
      {/* if handSplintingCostApproved is not number (in our case it's null or undefined), displayed icon is LocationDot*/}
      {typeof handSplintingCostApproved !== "number" && (
        <FontIcon iconName="LocationDot" />
      )}

      {/* if handSplintingCostApproved is number, displayed icon is dependent on handSplintingCostApproved */}
      {typeof handSplintingCostApproved === "number" ? (
        <>
          <OverviewSectionListIcon approved={handSplintingCostApproved > 0} />
          {handSplintingCostApproved < handSplintingCost! ? (
            <Text
              block
            >{`$${handSplintingCostApproved} splinting costs (requested $${handSplintingCost})`}</Text>
          ) : (
            <Text>{`$${handSplintingCostApproved}`}</Text>
          )}
        </>
      ) : (
        <Text>{`$${handSplintingCost} splinting costs`}</Text>
      )}
    </Stack>
  );
};
