export enum ClinicalActivityType {
  Task = "TASK",
  Reminder = "RMDR"
}

export enum ClinicalActivityDescriptionCode {
  ACC32 = "ACCNZ",
  FollowUpRequest = "FOLUR",
  RecordOutcomeMeasures = "RECNZ",
  ClaimReview = "CLANZ",
  Other = "OTH"
}

export enum ClinicalActivityPriority {
  High = "HIGH"
}
