import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ClaimAdjustmentResponseModalFormValues } from "../components/ClaimAdjustmentResponseModalForm.types.ts";

export class ClaimAdjustmentResponseModalFormValidator extends Validator<ClaimAdjustmentResponseModalFormValues> {
  constructor() {
    super();

    this.forField("purchaseOrderNumber", [maxLength(10)]);
    this.forField("responseDate", required());
  }
}
