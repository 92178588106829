import { ConfirmDialog } from "@bps/fluent-ui";

interface UnsavedSOTAPFormDialogProps {
  hidden: boolean;
  onCancel: () => void;
}

export const UnsavedSOTAPFormDialog: React.FC<UnsavedSOTAPFormDialogProps> = ({
  hidden,
  onCancel
}) => {
  return (
    <ConfirmDialog
      hidden={hidden}
      minWidth={480}
      dialogContentProps={{
        title: "Unsaved changes in SOTAP",
        subText:
          "You have unsaved changes in SOTAP. Please save these before beginning a discharge.",
        showCloseButton: true,
        onDismiss: onCancel
      }}
      confirmButtonProps={{ styles: { root: { display: "none" } } }}
      onConfirm={onCancel}
      cancelButtonProps={{ text: "Close" }}
      onCancel={onCancel}
    />
  );
};
