import { Country } from "@libs/enums/country.enum.ts";
import {
  AccClinicalTab,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalTabItem } from "@stores/clinical/models/clinical-tab/ClinicalTabItem.ts";
import { ClinicalDataTypeName } from "@stores/clinical/models/clinical-tab/ClinicalTabName.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

export const setClaimTab = (
  clinicalRecord: ClinicalRecord,
  accClinicalTab: AccClinicalTab,
  remove?: boolean
) => {
  const { currentPatientRecordTab: currentTab } =
    clinicalRecord.clinical.ui.tabs;

  const { tenantDetails } = clinicalRecord.core;

  // ⚠️ For NZ tenant only
  if (tenantDetails!.country === Country.NewZealand && currentTab) {
    const foundTab = currentTab.allTabs.find(t => t.type === accClinicalTab);

    if (accClinicalTab === AccClinicalTab.Injury) {
      const isNonReferralClaim: boolean =
        !!clinicalRecord.episodeOfCare &&
        !clinicalRecord.episodeOfCare.isReferral;

      // opened first time from ans appointment and has claimId,
      // we added a new injury tab
      if (isNonReferralClaim && !foundTab) {
        return currentTab.addTab(
          new ClinicalTabItem({
            type: AccClinicalTab.Injury,
            title: ClinicalDataTypeName[AccClinicalTab.Injury]
          })
        );
      }

      // an injury tab exists but after switching between and page reloading and
      // claimId lost from clinicalRecord.episodeOfCare,
      // we remove the injury tab and set active tab as TodaysNotes
      if (!isNonReferralClaim && foundTab) {
        currentTab.removeTab(foundTab.id);
        currentTab.setActive(PermanentClinicalTab.TodaysNotes);
        return;
      }
    } else {
      if (!foundTab) {
        currentTab.addTab(
          new ClinicalTabItem({
            type: accClinicalTab,
            title: ClinicalDataTypeName[accClinicalTab]
          })
        );
        currentTab.setActive(accClinicalTab);
      } else {
        if (!!remove) {
          currentTab.removeTab(foundTab.id);
          currentTab.setActive(PermanentClinicalTab.TodaysNotes);
          return;
        }
        currentTab.setActive(accClinicalTab);
      }
    }
  }
};
