import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

import { CenteredLargeSpinner } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BhbConfigCard } from "../components/BhbConfigCard.tsx";
import { BhbConfigContext } from "../context/BhbConfigContext.ts";
import { BhbConfigHelper } from "../context/BhbConfigHelper.ts";
import { BhbOnlineSettingsForm } from "./components/BhbOnlineSettingsForm.tsx";

const ONLINE_SETTING_CARD = "onlineBookingSettingsCard";

export const BhbOnlineSettingsCard: FunctionComponent = observer(() => {
  const root = useStores();
  const { bhb } = root;
  const bhbConfigHelper = useRef(new BhbConfigHelper(root));

  const setCardOpenState = () => {
    const onlineBookingsEnabled =
      bhbConfigHelper.current.location?.onlineBookingsEnabled;
    if (!onlineBookingsEnabled) {
      bhb.setCardOpenState(ONLINE_SETTING_CARD, !onlineBookingsEnabled);
    } else if (bhb.getCardOpenState(ONLINE_SETTING_CARD) === undefined) {
      bhb.setCardOpenState(ONLINE_SETTING_CARD, false);
    }
  };

  const fetch = async () => {
    const location = await bhbConfigHelper.current.getLocation();
    if (!location) throw Error("No Bhb location found.");
    setCardOpenState();
    await bhbConfigHelper.current.getAppointmentTypes();
  };

  return (
    <DataFetcher fetch={fetch} fallback={<CenteredLargeSpinner />}>
      {() => (
        <BhbConfigCard
          heading="Online booking settings"
          styles={{ collapseContent: { padding: "32px 0 48px 0" } }}
          openOnRender={bhb.getCardOpenState(ONLINE_SETTING_CARD)}
          onClick={() => {
            const isCardOpen = bhb.getCardOpenState(ONLINE_SETTING_CARD);
            bhb.setCardOpenState(ONLINE_SETTING_CARD, !isCardOpen);
          }}
        >
          <BhbConfigContext.Provider value={bhbConfigHelper.current}>
            <BhbOnlineSettingsForm />
          </BhbConfigContext.Provider>
        </BhbConfigCard>
      )}
    </DataFetcher>
  );
});
