import { isDefined } from "@bps/utils";
import {
  AgentClinicalDataItemDto,
  NatureOfReaction,
  ReactionClinicalDataDto,
  ReactionClinicalDataItemDto,
  ReactionSeverity,
  ReactionType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ReactionAgentKind } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { DrugsStore } from "@stores/drugs/DrugsStore.ts";

import { AgentReaction } from "./ReactionForm.types.ts";

export type ReactionSortOrder = "agent" | "severity";
export type ReactionColumnSorting = "ascending" | "descending";

/**
 * Fetches details about all reactions (drug class/ingredient names)
 * and returns an enriched Reaction array.
 * @param reactionsDto
 * @param config
 */
export function fetchReactionsDetails(
  reactionsDto: ReactionClinicalDataItemDto[] | undefined,
  config: {
    drugs: DrugsStore;
  }
): Promise<AgentReaction[]> {
  const { drugs } = config;
  return Promise.all(
    (reactionsDto || []).map(async x => {
      const { agent, type } = x;
      let name: string;
      switch (type) {
        case ReactionType.DrugClass: {
          name = (await drugs.getDrugClass(agent.code)).description;
          break;
        }
        case ReactionType.Ingredient: {
          name = (await drugs.getIngredient(agent.code)).ingredientName;
          break;
        }
        case ReactionType.NonDrug: {
          name = (await drugs.getNonDrug(agent.code)).preferredTerm;
          break;
        }
        default: {
          throw Error(`Unsupported reaction type ${ReactionType[type]}`);
        }
      }
      const naturesOfReaction = getNaturesOfReaction(agent.code, reactionsDto);
      return {
        ...x,
        key: `${agent.code}.${type}.${naturesOfReaction[0]?.severity}.${naturesOfReaction[0]?.id}.${x.id}`, // Unique key
        name,
        naturesOfReaction,
        maxSeverity: naturesOfReaction[0]?.severity
      };
    })
  );
}

/**
 * Returns a severity level rank from least severe to most severe.
 * @param severity
 */
function severityLevelRank(severity: ReactionSeverity) {
  switch (severity) {
    case ReactionSeverity.Mild:
      return 0;
    case ReactionSeverity.Moderate:
      return 1;
    case ReactionSeverity.Severe:
      return 2;
  }
}

/**
 * group by reaction/nature of reaction pair and descending severity / agent name
 * @param reactions
 */
export function sortReactions(
  reactions: AgentReaction[],
  sortedColumn: ReactionSortOrder = "severity",
  sortOrder: ReactionColumnSorting = "ascending"
): AgentReaction[] {
  const sortFunction =
    sortedColumn === "severity"
      ? sortReactionsBySeverity
      : sortReactionsByAgent;

  const sortedReactions = [...reactions].sort(sortFunction);
  return sortOrder === "ascending"
    ? sortedReactions
    : sortedReactions.reverse();
}

function sortReactionsByAgent(a: AgentReaction, b: AgentReaction) {
  return (
    a.name.localeCompare(b.name) ||
    reactionSeverityComparer(a.severity, b.severity)
  );
}

function sortReactionsBySeverity(a: AgentReaction, b: AgentReaction) {
  return (
    reactionSeverityComparer(a.severity, b.severity) ||
    a.name.localeCompare(b.name)
  );
}

/**
 * Comparer to sort severity from most severe to least severe
 */
export function reactionSeverityComparer(
  a: ReactionSeverity,
  b: ReactionSeverity
) {
  return severityLevelRank(b) - severityLevelRank(a);
}

export function getNaturesOfReaction(
  agentId: string,
  reactions?: ReactionClinicalDataItemDto[],
  otherText?: string
) {
  return (
    reactions
      ?.filter(y =>
        otherText ? y.otherText === otherText : y.agent.code === agentId
      )
      .reduce((acc: ReactionClinicalDataItemDto[], y) => [...acc, y], []) || []
  );
}

export function getReactionAgent(
  agentId: string,
  agents?: AgentClinicalDataItemDto[]
) {
  return agents?.find(x => x.agent?.code === agentId);
}

export function removeReactionFromClinicalData(params: {
  reaction: Pick<ReactionClinicalDataDto, "agents" | "reactions"> | undefined;
  reactionAgentId: string | undefined;
  natureOfReaction: NatureOfReaction | string | undefined;
  reasonForDelete?: string;
  reasonForDeleteComment?: string;
}) {
  const {
    reaction,
    reactionAgentId,
    natureOfReaction,
    reasonForDelete,
    reasonForDeleteComment
  } = params;

  const { agents = [], reactions = [] } = reaction || {};

  const newReactions = reactions
    .map(reaction => {
      if (
        reaction.agent?.code === reactionAgentId &&
        (natureOfReaction
          ? reaction.natureOfReaction === natureOfReaction
          : true)
      ) {
        if (!reasonForDelete) {
          return undefined;
        }
        return {
          ...reaction,
          isDeleted: true,
          reasonForDelete,
          deletedComment: reasonForDeleteComment
        };
      } else {
        return reaction;
      }
    })
    .filter(isDefined);

  if (
    newReactions.filter(
      x => x.agent.code === reactionAgentId && x.isDeleted !== true
    ).length === 0
  ) {
    const deletedAgent = agents.find(x => x.agent?.code === reactionAgentId);
    if (deletedAgent) {
      if (reasonForDelete) {
        deletedAgent.isDeleted = true;
        deletedAgent.reasonForDelete = reasonForDelete;
        deletedAgent.deletedComment = reasonForDeleteComment;
      } else {
        const index = agents.findIndex(x => x.agent?.code === reactionAgentId);
        agents.splice(index, 1);
      }
    }
  }

  return { agents, reactions: newReactions };
}

export function toReactionType(type: ReactionAgentKind): ReactionType {
  switch (type) {
    case ReactionAgentKind.Ingredient: {
      return ReactionType.Ingredient;
    }
    case ReactionAgentKind.DrugClass: {
      return ReactionType.DrugClass;
    }
    case ReactionAgentKind.NonDrug: {
      return ReactionType.NonDrug;
    }
    default: {
      throw Error(`Reaction Agent ${type} is not supported`);
    }
  }
}

export function toReactionAgentType(type: ReactionType): ReactionAgentKind {
  switch (type) {
    case ReactionType.Ingredient: {
      return ReactionAgentKind.Ingredient;
    }
    case ReactionType.DrugClass: {
      return ReactionAgentKind.DrugClass;
    }
    case ReactionType.NonDrug: {
      return ReactionAgentKind.NonDrug;
    }
    default: {
      throw Error(`Reaction type ${type} is not supported`);
    }
  }
}
