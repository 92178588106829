import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";

import {
  GroupedList,
  IGroup,
  mergeStyleSets,
  Spinner,
  Stack,
  Text,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { SELECT_FILE_TO_REVIEW } from "../inbox/Inbox.types.ts";
import {
  getInboxScreenStylesSet,
  sidePanelWidth
} from "../inbox/InboxScreen.styles.ts";
import { DropzoneSectionAzureBlob } from "../shared-components/DropzoneSectionAzureBlob.tsx";
import { HL7HeaderDataPreview } from "../shared-components/HL7HeaderDataPreview.tsx";
import { UploadDocForm } from "../shared-components/upload-doc-form/UploadDocForm.tsx";
import { UploadDocFormLabels } from "../shared-components/upload-doc-form/UploadDocForm.types.ts";
import { DocumentPreview } from "./components/DocumentPreview.tsx";
import { UploadScreenLeftSideList } from "./components/UploadScreenLeftSideList.tsx";

export const UploadScreen: React.FC = observer(() => {
  const { notification } = useStores();
  const theme = useTheme();
  const { noPDFWrapper, pdfInboxWrapper, uploadScreenTile } =
    getInboxScreenStylesSet(theme);

  const {
    setSelectedInboxDocument,
    clearOnLeave,
    selectedInboxDocument,
    uploadFileError,
    setUploadFileError,
    showUploadScreenSpinner,
    fetchAndSetInboxDocument
  } = useContext(InboxScreenContext);

  const handleOnRemove = () => {
    notification.success(UploadDocFormLabels.DeleteFileFeedback);
  };

  const { groupedListHeaderStyles } = getInboxScreenStylesSet(theme);

  useEffect(() => {
    return () => {
      clearOnLeave();
    };
  }, [clearOnLeave]);

  const groups = (): IGroup[] => {
    if (
      selectedInboxDocument?.headerDocumentDetailId &&
      selectedInboxDocument?.headerEntityId
    ) {
      return [
        {
          key: "messageHeader",
          name: "Message header",
          startIndex: 0,
          count: 1,
          isCollapsed: false
        },
        {
          key: "details",
          name: "Details",
          startIndex: 1,
          count: 1,
          isCollapsed: false
        }
      ];
    }

    return [
      {
        key: "details",
        name: "Details",
        startIndex: 0,
        count: 1,
        isCollapsed: false
      }
    ];
  };

  const getItems = (inboxDocument: InboxDocument) => {
    const items = [
      <UploadDocForm
        key="uploadDocForm"
        document={inboxDocument}
        onSubmitSucceeded={() => setSelectedInboxDocument(undefined)}
        onRemove={handleOnRemove}
      />
    ];
    if (
      inboxDocument?.headerDocumentDetailId &&
      inboxDocument?.headerEntityId
    ) {
      items.unshift(<HL7HeaderDataPreview key="messageHeader" />);
    }
    return items;
  };

  return (
    <Tile styles={uploadScreenTile}>
      <Stack
        horizontal
        tokens={{ childrenGap: 24 }}
        styles={{ root: { height: 0, flexGrow: 1 } }}
      >
        <Stack styles={sidePanelWidth}>
          <UploadScreenLeftSideList />
        </Stack>
        {showUploadScreenSpinner ? (
          <Stack styles={pdfInboxWrapper}>
            <Spinner />
          </Stack>
        ) : (
          <>
            {selectedInboxDocument?.id ? (
              <DataFetcher<InboxDocument>
                fetch={async () =>
                  fetchAndSetInboxDocument(selectedInboxDocument)
                }
                refetchId={selectedInboxDocument.id}
                fallback={
                  <Stack styles={pdfInboxWrapper}>
                    <Spinner />
                  </Stack>
                }
              >
                {document => {
                  return (
                    <>
                      <Stack styles={pdfInboxWrapper}>
                        <DocumentPreview previewDocument={document} />
                      </Stack>
                      <Stack
                        tokens={{ childrenGap: 8 }}
                        styles={mergeStyleSets(sidePanelWidth, {
                          root: {
                            marginLeft: 12
                          }
                        })}
                      >
                        <GroupedList
                          groups={groups()}
                          items={getItems(document)}
                          styles={{
                            root: {
                              selectors: groupedListHeaderStyles
                            }
                          }}
                          groupProps={{
                            onRenderHeader: (props, defaultRender) => {
                              if (!props || !defaultRender) {
                                return null;
                              }
                              return defaultRender!({
                                ...props,
                                styles: { headerCount: { display: "none" } },
                                onGroupHeaderClick: () =>
                                  props.onToggleCollapse!(props.group!)
                              });
                            }
                          }}
                          onRenderCell={(depth, item: React.ReactNode) => item}
                        />
                      </Stack>
                    </>
                  );
                }}
              </DataFetcher>
            ) : (
              <Stack styles={noPDFWrapper}>
                {uploadFileError.length === 0 ? (
                  <Text
                    styles={{
                      root: {
                        fontStyle: "italic"
                      }
                    }}
                  >
                    {SELECT_FILE_TO_REVIEW}
                  </Text>
                ) : (
                  <DropzoneSectionAzureBlob
                    onDropRejected={setUploadFileError}
                    onDropFinished={() => setUploadFileError([])}
                    hasError={!!uploadFileError}
                    fileTooLargeText="is greater than 7MB limit"
                  />
                )}
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Tile>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default UploadScreen;
