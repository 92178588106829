import { Observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";

import { noWrap, Text, TopBarDefaultHeader } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useDischargeContext } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeContext.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

export const DischargeFormHeader: FunctionComponent = () => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const helper = useDischargeContext();
  const isViewOnly = helper.isViewOnly;

  const { primaryDiagnoses } = helper.getDiagnoses();

  return (
    <TopBarDefaultHeader
      heading="Discharge"
      hideBackButton
      rightContainer={
        <Observer>
          {() => {
            const hasPersistedValues =
              !!clinicalRecord.stashedClinicalData?.dirtyDischargeForm;
            return (
              <FormSubmitButtons
                onCancel={() => {
                  helper.closeDischargeTab(helper.episodeOfCare.id);
                }}
                hideButtonsSeparator
                disableSubmitOnFormInvalid
                submitButtonProps={{
                  iconProps: undefined,
                  disabled: !hasPersistedValues
                }}
                cancelButtonProps={{ text: isViewOnly ? "Close" : "Cancel" }}
                hideSubmit={isViewOnly}
                styles={{ root: { padding: 0, margin: 0 } }}
                promptOnCancel
                extraPromptConditionOnCancel={() => hasPersistedValues}
              />
            );
          }}
        </Observer>
      }
      leftContainer={
        primaryDiagnoses &&
        primaryDiagnoses.length > 0 && (
          <Text
            styles={{
              root: {
                paddingTop: 5,
                maxWidth: 720,
                ...noWrap
              }
            }}
          >
            {primaryDiagnoses[0].originalText}
          </Text>
        )
      }
    />
  );
};
