import { useState } from "react";

import {
  EventContent,
  mergeStyles,
  NoDataTile,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { formatCalendarDate, ordinalNumber } from "@bps/utils";
import { AppointmentVisitDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  ConsultEncounterTypes,
  EncounterType,
  TreeView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ExpandableItem } from "@ui-components/EventList.tsx";

import { ConsultsRemainingBadges } from "./ConsultsRemainingBadges.tsx";

interface ConditionVisitsProps {
  episodeOfCareId: string;
  patientId: string;
  setVisitCount(value: number): void;
  appointmentVisits?: AppointmentVisitDto[];
}

export const ConditionVisits: React.FC<ConditionVisitsProps> = ({
  episodeOfCareId,
  patientId,
  setVisitCount,
  appointmentVisits
}) => {
  const { clinical, core } = useStores();
  const theme = useTheme();

  const [encounters, setEncounters] = useState<Encounter[]>([]);

  const showBusinessRoleText =
    core.hasPermissions(Permission.MultiProviderClaimsAllowed) &&
    encounters.some(x =>
      encounters.some(y => y.id !== x.id && y.businessRole !== x.businessRole)
    );

  const renderTitle = (
    encounter: Encounter,
    index: number
  ): React.ReactNode => {
    const businessRole = core.catalogBusinessRoles.find(
      x => x.code === encounter.businessRole
    );

    const businessRoleText =
      showBusinessRoleText && businessRole ? ` - ${businessRole.text}` : "";

    return (
      <>
        <Text
          styles={{
            root: {
              fontWeight: 600,
              color: theme.palette.themeDarker
            }
          }}
        >{`${ordinalNumber(index + 1)} consult${businessRoleText}`}</Text>
        <Text block>{formatCalendarDate(encounter.startDateTime)}</Text>
        {encounter.user && (
          <Text
            block
            styles={(_props, theme) => ({
              root: { color: theme?.palette.neutralPrimaryAlt }
            })}
          >
            {encounter.user.fullName}
          </Text>
        )}
      </>
    );
  };

  const onRowClick = (id: string) => {
    clinical.ui.tabs.currentPatientRecordTab!.sidePanelTab =
      TreeView.PastConsults;
    clinical.ui.tabs.currentPatientRecordTab!.sidePanelTabId = id;
  };

  const renderEntries = (): React.ReactNode => {
    const items = encounters.map((x: Encounter, index: number) => {
      return {
        id: x.id,
        title: renderTitle(x, encounters.length - (index + 1)),
        iconName: "Drop"
      } as ExpandableItem;
    });

    return (
      <Stack
        verticalFill
        grow
        tokens={{ childrenGap: 8 }}
        styles={{ root: { minHeight: "100px", marginLeft: 15 } }}
      >
        {items.map((x: ExpandableItem, index: number) => {
          return (
            <Stack.Item key={x.id + x.key + index.toString()}>
              <EventContent
                iconName={x.iconName}
                id={x.id}
                title={x.title}
                contentHeader={x.contentHeader}
                onRenderContent={x.onRenderContent}
                onClick={onRowClick}
              />
            </Stack.Item>
          );
        })}
      </Stack>
    );
  };

  return (
    <DataFetcher
      fetch={async () => {
        const eocEncounters = await clinical.getEncounters({
          patientId,
          episodeOfCareIds: [episodeOfCareId],
          encounterTypeCodes: ConsultEncounterTypes
        });

        const finalisedEncounters = eocEncounters.filter(
          x => x.isClosed && x.type !== EncounterType.RecordUpdate
        );

        setEncounters(finalisedEncounters);
        setVisitCount(finalisedEncounters.length);

        return finalisedEncounters;
      }}
      fallback={<Spinner />}
    >
      {encounters => {
        return (
          <div className={mergeStyles({ flexGrow: 1, overflowY: "auto" })}>
            {encounters.length === 0 ? (
              <NoDataTile
                styles={{
                  root: { backgroundColor: theme.palette.neutralLighterAlt }
                }}
                textProps={{ text: "No finalised consults" }}
                linkProps={{
                  hidden: true
                }}
                showBoxShadow={false}
              />
            ) : (
              <>
                {appointmentVisits && (
                  <Stack horizontal wrap>
                    <ConsultsRemainingBadges
                      appointmentVisits={appointmentVisits}
                    />
                  </Stack>
                )}
                {renderEntries()}
              </>
            )}
          </div>
        );
      }}
    </DataFetcher>
  );
};
