import { FunctionComponent, memo } from "react";

import {
  CompleteDialog,
  CompleteDialogProps
} from "@ui-components/CompleteDialog.tsx";

interface ClinicalNotificationCompleteDialogProps {
  itemName?: string;

  hidden: boolean;
  selectedCount: number;
  onConfirm: CompleteDialogProps["onConfirm"];
  onCancel?: () => void;
}
export const ClinicalNotificationCompleteDialog: FunctionComponent<ClinicalNotificationCompleteDialogProps> =
  memo(props => {
    const name = props.itemName ?? "clinical notification";

    const title =
      props.selectedCount > 1
        ? `Complete (${props.selectedCount}) ${name}s`
        : `Complete ${name}`;

    const subText =
      props.selectedCount > 1
        ? `Are you sure you want to complete these ${name}s?`
        : `Are you sure you want to complete this ${name}?`;
    return (
      <CompleteDialog
        formName="clinical-notification-complete"
        {...props}
        title={title}
        subText={subText}
        maxWidth={480}
        okButtonText="Complete"
      />
    );
  });
