export const isValidHpiOrganisationId = (val: string) => {
  if (!val) return;

  const errorMessages: string[] = [];
  if (val.length > 8) {
    errorMessages.push("HPI Organisation ID must not exceed 8 characters.");
  }
  if (errorMessages.length > 0) {
    return errorMessages.join(", ");
  }
  return undefined;
};
