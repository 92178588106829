import { FC } from "react";

import { Separator, Stack, Text } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { getAgeString } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { SexText } from "@ui-components/RefText.tsx";

type BillToTextProps = {
  contact: Contact;
};

export const BillToSecondaryText: FC<BillToTextProps> = ({ contact }) => {
  const getAge = (contact: Contact): string =>
    contact.birthDate
      ? getAgeString(contact.birthDate, contact.dateOfDeath)
      : "No DOB recorded ";

  const age = contact.type !== ContactType.Organisation ? getAge(contact) : "";

  const phone = contact.primaryCommunication?.value;

  return (
    <Stack verticalFill>
      {contact.pronoun && contact.pronoun}
      {!contact.pronoun && contact.sex && (
        <>
          Assigned:&nbsp;
          <SexText code={contact.sex} />
        </>
      )}
      <Stack horizontal>
        {!!age && <Text variant="small">{age}</Text>}
        {!!age && !!phone && (
          <Separator vertical styles={{ content: { padding: 0 } }} />
        )}
        {!!phone && (
          <Text variant="small">{contact.primaryCommunication?.value}</Text>
        )}
      </Stack>

      <Text variant="small">{contact.billingEmail}</Text>
    </Stack>
  );
};
