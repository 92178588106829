import { FormApi } from "final-form";
import { memo } from "react";

import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { PhysicalActivityFormValidator } from "../validators/PhysicalActivityFormValidator.tsx";
import { PhysicalActivityFormHelper } from "./context/PhysicalActivityFormHelper.ts";
import { PhysicalActivityFormValues } from "./PhysicalActivityForm.types.ts";
import { PhysicalActivityFormFields } from "./PhysicalActivityFormFields.tsx";
import { physicalActivityFormConfirmed } from "./utils.ts";

interface PhysicalActivityFormProps {
  clinicalRecord: ClinicalRecord;
}

const validator = new PhysicalActivityFormValidator();

export const PhysicalActivityForm: React.FunctionComponent<PhysicalActivityFormProps> =
  memo(({ clinicalRecord }) => {
    const { clinical, notification } = useStores();
    const { isViewOnly } = usePatientRecordScreenContext();
    const helper = new PhysicalActivityFormHelper(clinicalRecord);

    const { getPhysicalActivityClinicalData, submitData, getInitialValues } =
      helper;

    const onCancel = (): void => {
      clinical.ui.closePatientRecordContentForm(
        clinicalRecord.id,
        ClinicalDataType.PhysicalActivity
      );
    };

    const onReset = () => {
      onCancel();
      clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
        ClinicalDataType.PhysicalActivity,
        true
      );
    };

    const onSubmitSucceeded = (
      values: PhysicalActivityFormValues,
      form: FormApi<PhysicalActivityFormValues>,
      isSaveAndClose: boolean
    ) => {
      if (!isSaveAndClose) {
        clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
          type: ClinicalDataType.PhysicalActivity
        });
        form.restart(values);
        notification.success("Saved successfully");
      } else {
        onReset();
      }
    };

    const getValues = (stashedData?: StashedEncounterClinicalData) => {
      const physicalActivityAlreadyConfirmed =
        physicalActivityFormConfirmed(clinicalRecord) ?? false;

      const dirty =
        stashedData?.dirtyPhysicalActivityForm && !helper.hasBeenReset;

      const isNew =
        !clinicalRecord.clinicalData?.physicalActivityConfirmed?.createLog;

      return { dirty, physicalActivityAlreadyConfirmed, isNew };
    };

    const getButtonText = (stashedData?: StashedEncounterClinicalData) => {
      const { dirty, physicalActivityAlreadyConfirmed, isNew } =
        getValues(stashedData);

      const submissionButtonText =
        isNew ||
        physicalActivityAlreadyConfirmed ||
        (!physicalActivityAlreadyConfirmed && dirty)
          ? "Save"
          : "Confirm";

      return submissionButtonText;
    };

    const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
      const { dirty, physicalActivityAlreadyConfirmed, isNew } =
        getValues(stashedData);

      return (isNew || physicalActivityAlreadyConfirmed) && !dirty;
    };

    return (
      <ClinicalSubmissionForm<PhysicalActivityFormValues>
        formName="physical-activity"
        onSubmit={submitData}
        onSubmitSucceeded={onSubmitSucceeded}
        hideButtons
        initialValues={getInitialValues()}
        readOnly={isViewOnly}
        validate={validator.validate}
        disableRoutePrompt
        heading="Physical activity"
        onCancel={onReset}
        extraPromptConditionOnCancel={() =>
          !!clinicalRecord.stashedClinicalData?.dirtyPhysicalActivityForm &&
          !helper.hasBeenReset
        }
        disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
        submitButtonTextCondition={stashedData => getButtonText(stashedData)}
        lastUpdatedDate={getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.physicalActivityConfirmed
        )}
        lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
          clinicalRecord.clinicalData?.physicalActivityConfirmed
        )}
        hideSubmit={isViewOnly}
        noGap
        hasSeparator
      >
        <>
          <StashedClinicalDataFormSpy<PhysicalActivityFormValues>
            clinicalRecord={clinicalRecord}
            getData={getPhysicalActivityClinicalData}
            areasToObserve={{
              physicalActivity: [ClinicalDataType.PhysicalActivity]
            }}
            withHasBeenResetFormCheck={ClinicalDataType.PhysicalActivity}
          />

          <PhysicalActivityFormFields clinicalRecord={clinicalRecord} />
        </>
      </ClinicalSubmissionForm>
    );
  });
