import { FunctionComponent, memo } from "react";

import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DeleteDialog,
  DeleteDialogProps
} from "@ui-components/DeleteDialog.tsx";

export interface DeleteReactionDialogProps {
  hidden: boolean;
  onConfirm: DeleteDialogProps["onConfirm"];
  onCancel?: () => void;
}

const DeleteReactionDialogBase: FunctionComponent<DeleteReactionDialogProps> =
  memo(props => {
    const { clinical } = useStores();
    return (
      <DeleteDialog
        formName="delete-reaction"
        {...props}
        title="Delete reaction"
        subText="Are you sure you want to delete this reaction?"
        options={clinical.ref.reasonsForDeleteReaction.keyTextValues}
      />
    );
  });

export const DeleteReactionDialog = withFetch(
  x => [x.clinical.ref.reasonsForDeleteReaction.load()],
  DeleteReactionDialogBase
);
