import { Observer } from "mobx-react-lite";
import { FunctionComponent, memo } from "react";
import { useForm } from "react-final-form";

import { AccordionItem, FontWeights, Grid } from "@bps/fluent-ui";
import {
  BodyArea,
  InjuryAreaGroups,
  InjuryAreaSides
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import {
  BodyExaminationFormValues,
  bodyExamNameOf
} from "../../BodyExaminationForm.types.ts";
import { useSideAccordionContext } from "./context/SideAccordionContext.tsx";
import { getContainerStyles } from "./SideMotionAccordionItem.styles.ts";

interface SideMotionAccordionItemProps {
  area: BodyArea;
  group: InjuryAreaGroups;
  extendedByDefault?: boolean;
  isSingleAccordionItem?: boolean;
}

export const SideMotionAccordionItem: FunctionComponent<SideMotionAccordionItemProps> =
  memo(({ group, extendedByDefault, area, isSingleAccordionItem }) => {
    const { clinical } = useStores();
    const form = useForm<BodyExaminationFormValues>();
    const helper = useSideAccordionContext();
    const { values } = form.getState();

    const getName = (side: InjuryAreaSides) => {
      const index = values.injuryAreaMotionAssessments.findIndex(x => {
        // exclude  injurySide check from this condition if an area is Spine
        if (area === BodyArea.Spine) {
          return x.injuryArea === area && x.injuryAreaGroup === group;
        }

        return (
          x.injuryArea === area &&
          x.injuryAreaGroup === group &&
          x.injurySide === side
        );
      });

      return `${bodyExamNameOf(
        "injuryAreaMotionAssessments"
      )}[${index}].motionTypes`;
    };

    const title =
      clinical.ref.injuryAreaMotionTypeGroups.get(group)?.text ?? "";

    const renderFieldArray = () => (
      <Observer>
        {() => {
          const name = getName(helper.side);

          return (
            <FieldArray name={name}>
              {({ fields }) => {
                const { value: motions = [] } = fields;
                return (
                  <Fieldset
                    frame
                    styles={{
                      root: getContainerStyles(motions.length)
                    }}
                  >
                    <Grid
                      className="motions-grid"
                      childrenTokens={{ gap: 8 }}
                      verticalAlign="end"
                    >
                      <>
                        {fields.map((name: string, idx: number) => {
                          return (
                            <Observer key={name}>
                              {() => {
                                const code = motions[idx].code;
                                const label =
                                  clinical.ref.motionTypes.get(code)?.text;

                                return (
                                  <SpinNumberInputField
                                    label={label}
                                    name={`${name}.${helper.motionType}`}
                                    maxDigitsNumber={3}
                                    min={0}
                                    max={180}
                                    step={1}
                                    valueSuffix={"\u00B0"}
                                    fieldClassName="motions-grid-item"
                                    styles={{
                                      input: { width: 0 },
                                      spinButtonWrapper: {
                                        "::before": { padding: "0px 4px" }
                                      }
                                    }}
                                    fieldItemStyles={{
                                      subComponentStyles: {
                                        label: {
                                          root: {
                                            fontWeight: FontWeights.regular
                                          }
                                        }
                                      }
                                    }}
                                  />
                                );
                              }}
                            </Observer>
                          );
                        })}
                      </>
                    </Grid>
                  </Fieldset>
                );
              }}
            </FieldArray>
          );
        }}
      </Observer>
    );
    return isSingleAccordionItem ? (
      renderFieldArray()
    ) : (
      <AccordionItem extendedByDefault={extendedByDefault} title={title}>
        {renderFieldArray()}
      </AccordionItem>
    );
  });
