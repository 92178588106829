import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AttendanceStatusDropdown } from "../../shared-components/attendance-status/AttendanceStatusDropdown.tsx";
import { BookingEventTimeDisplay } from "./BookingEventTimeDisplay.tsx";

interface BookingEventDetailsProps {
  calendarEvent: CalendarEvent;
  eventTypeText: string;
}

export const BookingEventDetails: FunctionComponent<
  BookingEventDetailsProps
> = ({ calendarEvent, eventTypeText }) => {
  const {
    core: { getLocationName },
    booking
  } = useStores();

  const {
    startDateTime,
    endDateTime,
    isPartOfRecurrence,
    calendarEventRecurrenceId,
    user,
    contact,
    isGroupAppointment
  } = calendarEvent;

  const locationName = getLocationName(calendarEvent.orgUnitId);

  const getAppointmentType = async () => {
    if (calendarEvent.appointmentTypeId) {
      const appointmentType = await booking.getAppointmentType(
        calendarEvent.appointmentTypeId
      );
      return appointmentType;
    }

    return null;
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 16 }}>
      <DataFetcher fetch={getAppointmentType}>
        {appointmentType => {
          return (
            <BookingEventTimeDisplay
              startTime={startDateTime}
              endTime={endDateTime}
              isPartOfRecurrence={isPartOfRecurrence}
              hasRecurrenceId={calendarEventRecurrenceId !== undefined}
              userName={user?.name}
              bookingType={eventTypeText}
              icon={appointmentType?.icon}
              appointmentTypeName={appointmentType?.name}
              locationName={locationName}
            />
          );
        }}
      </DataFetcher>

      {contact && !isGroupAppointment && (
        <Stack.Item grow>
          <AttendanceStatusDropdown
            attendanceStatus={calendarEvent.attendanceStatus}
            onStatusChange={attendanceStatus => {
              booking.updateCalendarEvent({
                id: calendarEvent.id,
                attendees: calendarEvent.dto.attendees.map(a => {
                  return a.attendeeId === contact.id
                    ? {
                        ...a,
                        attendanceStatus
                      }
                    : a;
                })
              });
            }}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};
