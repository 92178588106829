import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  DocumentContentType,
  DocumentEnum,
  DocumentExtensionType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DocumentWriterDetails } from "./components/DocumentWriterDetails.tsx";
import { DocumentWriterContext } from "./context/DocumentWriterContext.ts";
import { DocumentWriterHelper } from "./context/DocumentWriterHelper.ts";

const DocumentWriterScreen: FunctionComponent = observer(() => {
  const { correspondence } = useStores();
  const map = correspondence.activeDocumentTab;

  if (!map) {
    return null;
  }

  let syncFusion: boolean | undefined;

  return (
    <DataFetcher<ClinicalDocument | undefined>
      refetchId={correspondence.activeDocumentTab?.documentId}
      fetch={async ({ correspondence }) => {
        const document = correspondence.correspondenceMap.get(map.documentId);

        const supportedSyncfusionExtensions: string[] = [
          DocumentExtensionType.Docx,
          DocumentExtensionType.Rtf,
          DocumentExtensionType.Doc
        ];

        const ext: string = document?.extension;

        syncFusion = supportedSyncfusionExtensions.includes(ext.toLowerCase());

        let contentType: string | undefined;

        if (syncFusion) contentType = DocumentContentType.Sfdt;

        // need to ignore cache if it's syncfusion and isn't a brand new document
        const ignoreCache =
          syncFusion &&
          !!document?.metadata.find(m => m.key === DocumentEnum.ContentType);

        return await correspondence.getCorrespondenceByDocumentId(
          map.patientId,
          map.documentId,
          {
            includeContent: document?.dto.content !== "",
            ignoreCache,
            contentType
          }
        );
      }}
    >
      {clinDoc => {
        if (!clinDoc) {
          return;
        }

        const model = new DocumentWriterHelper(correspondence, clinDoc);
        return (
          <DocumentWriterContext.Provider value={model}>
            <DocumentWriterDetails encounterId={map.encounterId} />
          </DocumentWriterContext.Provider>
        );
      }}
    </DataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default DocumentWriterScreen;
