import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  IBreadcrumbItem,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import { AccFeesTile } from "./AccFeesTile.tsx";
import { FeesTile } from "./fees/FeesTile.tsx";
import { ScheduleCard } from "./ScheduleCard.tsx";
import { ScheduleWarningBanner } from "./ScheduleWarningBanner.tsx";

const ScheduleView: React.FC = observer(() => {
  const {
    viewSchedule,
    openEditScheduleDialog,
    openFeeDialog,
    openScheduleList
  } = useContext(ScheduleScreenContext);

  const { core } = useStores();

  if (!viewSchedule) {
    return null;
  }

  const breadcrumbs: IBreadcrumbItem[] = [
    {
      key: "schedules",
      text: "Schedules",
      onClick: openScheduleList
    },
    {
      key: "view-schedule",
      text: viewSchedule.name,
      isCurrentItem: true
    }
  ];

  const getButtonProps = () => {
    if (!core.hasPermissions(Permission.BookingScheduleWrite)) return undefined;
    if (viewSchedule.benefitSchedule) {
      return {
        text: "New non-standard fee",
        onClick: () => openFeeDialog(viewSchedule),
        iconProps: {
          iconName: "add"
        }
      };
    }
    return {
      text: "New fee",
      onClick: () => openFeeDialog(viewSchedule),
      iconProps: {
        iconName: "add"
      },
      items: [
        {
          key: "edit-schedule",
          text: "Edit schedule",
          onClick: () => openEditScheduleDialog(viewSchedule)
        }
      ]
    };
  };

  return (
    <Stack
      {...dataAttribute(DataAttributes.Element, "schedule-view")}
      tokens={{ childrenGap: 16 }}
      grow
    >
      <BreadCrumb routes={breadcrumbs} />
      <ScheduleWarningBanner />
      <ScheduleCard
        collapsable
        schedule={viewSchedule}
        buttonProps={getButtonProps()}
      />
      {viewSchedule.benefitSchedule ? <AccFeesTile /> : <FeesTile />}
    </Stack>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ScheduleView;
