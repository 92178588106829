import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

import { SubmitActionCode } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Template } from "@stores/documents/models/Template.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { QuickAccessContext } from "../../patient-record/components/quickAccess/context/QuickAccessContext.tsx";
import { QuickAccessHelper } from "../../patient-record/components/quickAccess/context/QuickAccessHelper.ts";
import { TemplateManagementFormModel } from "../context/TemplateManagementFormModel.ts";
import { TemplateManagerFormContext } from "../context/TemplateManagerFormContext.ts";
import { TemplateManagementForm } from "./TemplateManagementForm.tsx";

const TemplateManagementWrapperBase: React.FC = observer(() => {
  const root = useStores();
  const { document, core } = root;
  const qaHelper = useRef(new QuickAccessHelper(root));

  const templateList: Template[] = document.documentLists;

  const hasPracWorkFlowAdminAccess =
    core.user?.hasPracWorkflowAdminSecurityRole;

  const hasPracWorkFlowContributorAccess =
    core.user?.hasPracWorkflowContributorSecurityRole;

  const hasClinContributorAccess = core.user?.hasClinContributorSecurityRole;

  const filteredTemplates = (() => {
    const templates = templateList ?? [];

    if (hasPracWorkFlowAdminAccess) return templates;

    if (hasPracWorkFlowContributorAccess && hasClinContributorAccess)
      return templates;

    if (hasPracWorkFlowContributorAccess)
      return templates.filter(x => x.isAdmin && !x.isClinical);

    if (hasClinContributorAccess)
      return templates.filter(x => x.isClinical && !x.isAdmin);

    return [];
  })();

  return (
    <DataFetcher<{
      favourites: string[];
    }>
      fetch={async ({ userExperience }) => {
        const [userStorage] = await Promise.all([
          userExperience.getUserStorage(UserStorageKeys.FavouriteTemplates),
          document.getAllTemplates(),
          userExperience.getCurrentUserQuickAccess()
        ]);
        return {
          favourites: (userStorage?.jsonData ?? []) as string[]
        };
      }}
    >
      {data => {
        const items = (filteredTemplates ?? [])
          .filter(
            x =>
              !x.isNew &&
              !x.isSystem &&
              x.isHiddenOnStatus(SubmitActionCode.DraftCode) &&
              x.isHiddenOnStatus(SubmitActionCode.PublishToSelfCode) &&
              !x.isPracticeLetterhead
          )
          .map(t => ({
            key: t.id,
            details: t.name,
            updated: t.updatedDate,
            isCustom: t.isCustom,
            documentStatus: t.documentStatus,
            documentType: t.documentType,
            documentAuthor: t.createdBy,
            isClinical: t.isClinical,
            isAdmin: t.isAdmin
          }));

        return (
          <QuickAccessContext.Provider value={qaHelper.current}>
            <TemplateManagementForm
              items={items}
              favourites={data?.favourites ?? []}
              onCancel={() => document.setShowTemplateManagementModal(false)}
            />
          </QuickAccessContext.Provider>
        );
      }}
    </DataFetcher>
  );
});
export const TemplateManagementWrapper: FunctionComponent = () => {
  const { document, userExperience } = useStores();

  const model = new TemplateManagementFormModel(document, userExperience);

  return (
    <TemplateManagerFormContext.Provider value={model}>
      <TemplateManagementWrapperBase />
    </TemplateManagerFormContext.Provider>
  );
};
