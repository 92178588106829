import { nameof } from "@libs/utils/name-of.utils.ts";
import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";

export class ClaimDiagnosisValidator extends Validator<ClaimDiagnosisFormValues> {
  constructor() {
    super();

    this.forField(
      nameof("diagnosisSide"),
      predicate((_val, values) => !!values?.diagnosisKey, required())
    );
    this.forField(
      nameof("diagnosisKey"),
      predicate((_val, values) => !!values?.diagnosisSide, required())
    );
  }
}
