import { observer } from "mobx-react-lite";
import { useRef } from "react";

import {
  AddBox,
  Card,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FontSizes,
  FontWeights,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import {
  UserWorkingHoursOverridesFormProps,
  UserWorkingHoursOverridesValues,
  UserWorkingHoursTestElements
} from "../UserWorkingHoursOverridesScreen.types.ts";
import { UserWorkingHoursOverridesFields } from "./UserWorkingHoursOverridesFields.tsx";
import { UserWorkingHoursOverridesFormValidator } from "./UserWorkingHoursOverridesFormValidator.ts";

export const UserWorkingHoursOverridesForm: React.FC<UserWorkingHoursOverridesFormProps> =
  observer(
    ({
      containerId,
      initialValues,
      onSubmit,
      onSubmitSucceeded,
      emptyStateSubText,
      emptyStateText,
      iconName,
      title,
      allowAdd
    }) => {
      const persistedInitialValues = useRef(initialValues);
      const { core } = useStores();

      const validator = new UserWorkingHoursOverridesFormValidator();

      return (
        <SubmissionForm<UserWorkingHoursOverridesValues>
          formName="user-working-hours-override"
          readOnly={!core.hasUserSettingWritePermission}
          validate={validator.validate}
          initialValues={persistedInitialValues.current}
          className="user-working-hours-overrides-form"
          onSubmit={onSubmit}
          onSubmitSucceeded={onSubmitSucceeded}
          hideButtons
          styles={{
            main: { overflowY: "visible" },
            fields: { overflowY: "visible" }
          }}
        >
          {({ values, form }) => {
            const showAddBox = values.overrides.length === 0;

            const onAddOverride = () => {
              const now = DateTime.today();

              form.change("overrides", [
                ...(values.overrides || []),
                {
                  startDate: now.toJSDate(),
                  endDate: now.toJSDate(),
                  atWork: "1",
                  startTime: DateTime.today()
                    .plus({ hours: 8 })
                    .toTimeInputFormat(),
                  endTime: DateTime.today()
                    .plus({ hours: 17 })
                    .toTimeInputFormat()
                }
              ]);
            };

            const renderEmptyExceptions = () => {
              return core.hasUserSettingWritePermission ? (
                <AddBox
                  {...dataAttribute(
                    DataAttributes.Element,
                    UserWorkingHoursTestElements.AddBox
                  )}
                  text={emptyStateText}
                  subText={emptyStateSubText}
                  buttonText="Add an exception"
                  onClick={allowAdd ? onAddOverride : undefined}
                  styles={{ root: { padding: "32px 64px" } }}
                />
              ) : (
                <Stack
                  horizontalAlign="center"
                  styles={{ root: { padding: "32px 64px" } }}
                >
                  <Text
                    styles={{
                      root: {
                        fontWeigh: FontWeights.semibold,
                        fontSize: FontSizes.size18,
                        paddingBottom: 8
                      }
                    }}
                  >
                    {emptyStateText}
                  </Text>
                </Stack>
              );
            };

            return (
              <Card
                {...dataAttribute(DataAttributes.Element, containerId)}
                iconName={iconName}
                headingLevel="section-heading"
                heading={title}
                styles={{
                  subComponentStyles: {
                    tile: {
                      content: {
                        padding: 0
                      }
                    }
                  }
                }}
                button={
                  allowAdd &&
                  values.overrides.length > 0 &&
                  core.hasUserSettingWritePermission && (
                    <DefaultButton
                      {...dataAttribute(
                        DataAttributes.Element,
                        UserWorkingHoursTestElements.AddButton
                      )}
                      onClick={onAddOverride}
                      iconProps={{ iconName: "Add" }}
                    >
                      Add an exception
                    </DefaultButton>
                  )
                }
              >
                {showAddBox && renderEmptyExceptions()}

                <UserWorkingHoursOverridesFields
                  initialValues={initialValues}
                />
              </Card>
            );
          }}
        </SubmissionForm>
      );
    }
  );
