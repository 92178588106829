import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemStyles,
  AccordionItemStylesProps,
  Heading,
  IconButton,
  IStyleFunctionOrObject,
  MessageBar,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { QuestionnaireDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import {
  ClinicalToolAnswer,
  findClinicalToolAnswer
} from "../utils/ClinicalToolAnswerFinder.ts";
import { Rand36Module } from "./Rand36Module.tsx";

interface Rand36QuestionnaireFormProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  isViewOnly?: boolean;
  width: number;
  questionPrefix?: string;
}

const SIZE_FULL_WIDTH = 950;
const SIZE_SEMI_FULL = 720;
const SIZE_SHORT = 530;

// The start and end of each question section, placed into a range for mapping.
// These names are based on the working of the questions, there doesn't appear to be any specific terminology for these sections as they're just questions sorted differently.
const QUESTION_SECTION_TYPICAL_DAY_START = 3;
const QUESTION_SECTION_TYPICAL_DAY_END = 12;
const QUESTION_SECTION_PHYSICAL_HEALTH_START = 13;
const QUESTION_SECTION_PHYSICAL_HEALTH_END = 16;
const QUESTION_SECTION_EMOTIONAL_PROBLEMS_START = 17;
const QUESTION_SECTION_EMOTIONAL_PROBLEMS_END = 19;
const QUESTION_SECTION_SINGULAR_QUESTIONS_START = 20;
const QUESTION_SECTION_SINGULAR_QUESTIONS_END = 22;
const QUESTION_SECTION_HOW_THINGS_HAVE_BEEN_START = 23;
const QUESTION_SECTION_HOW_THINGS_HAVE_BEEN_END = 31;
const QUESTION_SECTION_TRUE_OR_FALSE_START = 33;
const QUESTION_SECTION_TRUE_OR_FALSE_END = 36;
const SINGULAR_QUESTION_SOCIAL_ACTIVITIES = 32;

export const Rand36Questions: React.FunctionComponent<
  Rand36QuestionnaireFormProps
> = ({ questionnaire, answers, isViewOnly, width, questionPrefix }) => {
  const [informationVisible, setInformationVisible] = useState<boolean>(false);

  const theme = useTheme();

  const answerPrefix = questionPrefix ?? "items.q";

  const findAnswer = (questionId: string): string => {
    let answerValue = "";
    if (answers) {
      const answer = findClinicalToolAnswer(
        isViewOnly ? questionId : `${answerPrefix}${questionId}`,
        answers
      );
      if (answer) answerValue = answer.answerValue!;
    }
    return answerValue;
  };

  const getQuestionsInRange = (min: number, max: number) => {
    return questionnaire.items.filter(x => x.id >= min && x.id <= max);
  };

  const getQuestion = (id: number) => {
    return questionnaire.items.filter(x => x.id === id)[0];
  };

  const singularQuestion = getQuestion(SINGULAR_QUESTION_SOCIAL_ACTIVITIES);

  const getSectionTitle = (startNumber: number, endNumber: number) => {
    return `Questions ${startNumber}-${endNumber}`;
  };

  const masterVerticalAlignment = width < SIZE_FULL_WIDTH;

  const isSemiFullWidth = SIZE_SEMI_FULL < width && width <= SIZE_FULL_WIDTH;
  const isShortWidth = SIZE_SHORT < width && width <= SIZE_SEMI_FULL;
  const isSmallestWidth = width <= SIZE_SHORT;

  const styles: IStyleFunctionOrObject<
    AccordionItemStylesProps,
    AccordionItemStyles
  > = {
    root: { borderLeft: 0, borderRight: 0 },
    heading: { borderBottom: 0, textSize: "18px" },
    content: { paddingLeft: 0 },
    contentWrapper: { paddingLeft: 0 }
  };

  const renderTitleText = (title: string) => {
    return (
      <Text variant="large" styles={{ root: { margin: 8 } }}>
        {title}
      </Text>
    );
  };
  return (
    <Accordion
      withActions
      multiple
      onRenderTitle={() => {
        return (
          <Stack horizontal verticalAlign="center">
            <Heading variant="section-heading-light">
              Measure of Health-Related Quality of Life
            </Heading>

            <TooltipHost content={<>Click to show information about RAND 36</>}>
              <IconButton
                iconProps={{ iconName: "Info", color: theme.palette.accent }}
                onClick={() => {
                  setInformationVisible(!informationVisible);
                }}
              />
            </TooltipHost>
          </Stack>
        );
      }}
    >
      {informationVisible && (
        <MessageBar
          onDismiss={() => setInformationVisible(false)}
          dismissButtonAriaLabel="Close"
          styles={{ root: { marginBottom: 8 } }}
        >
          RAND is a research organization that develops solutions to public
          policy challenges to help make communities throughout the world safer
          and more secure, healthier and more prosperous. RAND is nonprofit,
          nonpartisan, and committed to the public interest. RAND® is a
          registered trademark. © 1994-2024 RAND Corporation.
        </MessageBar>
      )}

      <AccordionItem
        title="Questions 1-2"
        styles={styles}
        onRenderTitle={renderTitleText}
        extendedByDefault
      >
        <Rand36Module
          questions={[questionnaire.items[0]]}
          findAnswer={findAnswer}
          answers={answers}
          verticalAlignment={masterVerticalAlignment}
          verticalQuestions={isSmallestWidth}
        />
        {/* Seperated in order to have the items change differently with size changes */}
        <Rand36Module
          questions={[questionnaire.items[1]]}
          findAnswer={findAnswer}
          answers={answers}
          verticalAlignment={masterVerticalAlignment}
          verticalQuestions={isShortWidth || isSmallestWidth}
          invertedColorOrder
        />
      </AccordionItem>
      <AccordionItem
        title={getSectionTitle(
          QUESTION_SECTION_TYPICAL_DAY_START,
          QUESTION_SECTION_TYPICAL_DAY_END
        )}
        styles={styles}
        onRenderTitle={renderTitleText}
      >
        <Rand36Module
          verticalAlignment={masterVerticalAlignment}
          verticalQuestions={isSmallestWidth}
          questions={getQuestionsInRange(
            QUESTION_SECTION_TYPICAL_DAY_START,
            QUESTION_SECTION_TYPICAL_DAY_END
          )}
          findAnswer={findAnswer}
          answers={answers}
        />
      </AccordionItem>
      <AccordionItem
        title={getSectionTitle(
          QUESTION_SECTION_PHYSICAL_HEALTH_START,
          QUESTION_SECTION_PHYSICAL_HEALTH_END
        )}
        styles={styles}
        onRenderTitle={renderTitleText}
      >
        <Rand36Module
          verticalAlignment={masterVerticalAlignment}
          questions={getQuestionsInRange(
            QUESTION_SECTION_PHYSICAL_HEALTH_START,
            QUESTION_SECTION_PHYSICAL_HEALTH_END
          )}
          findAnswer={findAnswer}
          answers={answers}
        />
      </AccordionItem>
      <AccordionItem
        title={getSectionTitle(
          QUESTION_SECTION_EMOTIONAL_PROBLEMS_START,
          QUESTION_SECTION_EMOTIONAL_PROBLEMS_END
        )}
        styles={styles}
        onRenderTitle={renderTitleText}
      >
        <Rand36Module
          verticalAlignment={masterVerticalAlignment}
          questions={getQuestionsInRange(
            QUESTION_SECTION_EMOTIONAL_PROBLEMS_START,
            QUESTION_SECTION_EMOTIONAL_PROBLEMS_END
          )}
          findAnswer={findAnswer}
          answers={answers}
        />
      </AccordionItem>
      <AccordionItem
        title={getSectionTitle(
          QUESTION_SECTION_SINGULAR_QUESTIONS_START,
          QUESTION_SECTION_SINGULAR_QUESTIONS_END
        )}
        styles={styles}
        onRenderTitle={renderTitleText}
      >
        <Rand36Module
          verticalAlignment={masterVerticalAlignment}
          questions={getQuestionsInRange(
            QUESTION_SECTION_SINGULAR_QUESTIONS_START,
            QUESTION_SECTION_SINGULAR_QUESTIONS_END
          )}
          findAnswer={findAnswer}
          answers={answers}
          verticalQuestions={isShortWidth || isSmallestWidth}
        />
      </AccordionItem>
      <AccordionItem
        title={getSectionTitle(
          QUESTION_SECTION_HOW_THINGS_HAVE_BEEN_START,
          QUESTION_SECTION_HOW_THINGS_HAVE_BEEN_END
        )}
        styles={styles}
        onRenderTitle={renderTitleText}
      >
        <Rand36Module
          verticalAlignment={true}
          verticalQuestions={isSemiFullWidth || isShortWidth || isSmallestWidth}
          questions={getQuestionsInRange(
            QUESTION_SECTION_HOW_THINGS_HAVE_BEEN_START,
            QUESTION_SECTION_HOW_THINGS_HAVE_BEEN_END
          )}
          findAnswer={findAnswer}
          answers={answers}
        />
      </AccordionItem>
      <AccordionItem
        title={getSectionTitle(
          singularQuestion.id,
          QUESTION_SECTION_TRUE_OR_FALSE_END
        )}
        styles={styles}
        onRenderTitle={renderTitleText}
      >
        <Rand36Module
          verticalAlignment={masterVerticalAlignment}
          questions={[singularQuestion]}
          findAnswer={findAnswer}
          answers={answers}
          verticalQuestions={isSemiFullWidth || isShortWidth || isSmallestWidth}
        />
        <Rand36Module
          verticalAlignment={masterVerticalAlignment}
          verticalQuestions={isShortWidth || isSmallestWidth}
          questions={getQuestionsInRange(
            QUESTION_SECTION_TRUE_OR_FALSE_START,
            QUESTION_SECTION_TRUE_OR_FALSE_END
          )}
          findAnswer={findAnswer}
          answers={answers}
        />
      </AccordionItem>
    </Accordion>
  );
};
