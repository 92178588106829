import { observer } from "mobx-react-lite";

import { IBreadcrumbItem, Shimmer } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  BreadCrumb,
  BreadcrumbProps
} from "@ui-components/navigation/BreadCrumb.tsx";

export interface BillingBreadcrumbProps
  extends Omit<BreadcrumbProps, "routes"> {
  patient?: Contact;
  //number of sibling transaction
  transactionRefNumber?: string;
  transactionNumber?: string;
}
export enum BillingBreadcrumbsKeys {
  accounts = 1,
  accountHistory = 2,
  invoice = 3,
  allocation = 4,
  writeOff = 5,
  creditNote = 6,
  payment = 7,
  refund = 8
}

const BillingBreadcrumbsBase: React.FC<BillingBreadcrumbProps> = observer(
  ({ transactionNumber, transactionRefNumber, patient, ...props }) => {
    const { routing } = useStores();
    const isNewInvoiceRoute = !!routing.match(routes.accounts.invoices.new);

    const isAdjustInvoiceRoute = !!routing.match(
      routes.accounts.invoices.adjust
    );

    const isWriteOffViewRoute = !!routing.match(
      routes.accounts.invoices.writeOff.viewPath
    );

    const isCreditNoteViewRoute = !!routing.match(
      routes.accounts.creditNotes.viewPath
    );

    const isRefundViewRoute = !!routing.match(routes.accounts.refund);

    const isNewCreditNoteRoute = !!routing.match(
      routes.accounts.creditNotes.new
    );

    const isPaymentViewRoute = !!routing.match(
      routes.accounts.payments.viewPath
    );

    const isViewInvoiceRoute =
      !!routing.match(routes.accounts.invoices.invoice) &&
      !isNewInvoiceRoute &&
      !isAdjustInvoiceRoute &&
      !isWriteOffViewRoute &&
      !isPaymentViewRoute;

    const isNewAllocationRoute = !!routing.match(
      routes.accounts.allocations.new
    );

    const isViewAllocationRoute =
      !!routing.match(routes.accounts.allocations.allocation) &&
      !isNewAllocationRoute;

    const routeAction =
      isViewInvoiceRoute || isViewAllocationRoute
        ? routing.pushRetainingState
        : routing.replaceRetainingState;

    const isWriteOffRoute = !!routing.match(
      routes.accounts.invoices.writeOff.new
    );

    const from = (routing.location.state?.from || "") as string;
    const fromNoQuery = from.split("?")[0];

    const breadcrumbs: IBreadcrumbItem[] = [];

    let accountHref =
      routes.accounts.basePath.path({}) +
      (routing.location.state?.accountsFilter || "");
    if (
      from &&
      routes.accounts.basePath.match(fromNoQuery) &&
      !routes.accounts.account.match(fromNoQuery)
    ) {
      accountHref = from;
    }

    // Always show Accounts breadcrumb
    breadcrumbs.push({
      onClick: () => routeAction(accountHref),
      text: "Accounts",
      key: BillingBreadcrumbsKeys.accounts.toString()
    });

    const patientHref =
      from && routes.accounts.account.match(fromNoQuery) ? from : undefined;

    if (patientHref) {
      // Only show patient breadcrumb if the user has come from that page
      breadcrumbs.push({
        onClick: () => routeAction(patientHref),
        text: patient?.name || "Patient",
        key: BillingBreadcrumbsKeys.accountHistory.toString()
      });
    }

    if (isNewInvoiceRoute) {
      // New Invoice breadcrumb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.invoice.toString(),
        text: `New Invoice ${transactionNumber || ""}`,
        isCurrentItem: true
      });
    } else if (isAdjustInvoiceRoute || isViewInvoiceRoute) {
      // Adjust / View invoice breadcrumb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.invoice.toString(),
        text: `${isAdjustInvoiceRoute ? "Adjust " : ""}Invoice ${
          transactionNumber || ""
        }`,
        isCurrentItem: true
      });
    } else if (isWriteOffViewRoute) {
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.writeOff.toString(),
        text: `Write-off ${transactionNumber || ""}`,
        isCurrentItem: true
      });
    }

    if (isCreditNoteViewRoute) {
      //New Write Off Bredcrmb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.creditNote.toString(),
        text: `Credit ${transactionNumber}`,
        isCurrentItem: true
      });
    }

    if (isRefundViewRoute) {
      //New Write Off Bredcrmb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.refund.toString(),
        text: `Refund ${transactionNumber}`,
        isCurrentItem: true
      });
    }

    if (isNewCreditNoteRoute) {
      if (transactionRefNumber) {
        const invoiceId = routing.match(routes.accounts.creditNotes.new)?.params
          .id;
        if (invoiceId) {
          // Return to Invoice breadcrumb, which gets shown if the user has come from that page
          breadcrumbs.push({
            onClick: () =>
              routing.replaceRetainingState(
                routes.accounts.invoices.invoice.path({ id: invoiceId })
              ),
            text: `Invoices ${transactionRefNumber}`,
            key: BillingBreadcrumbsKeys.invoice.toString()
          });
        }
      }
      //New credit-note bredcrmb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.creditNote.toString(),
        text: `Credit ${transactionNumber}`,
        isCurrentItem: true
      });
    }

    if (isPaymentViewRoute) {
      //New Write Off Bredcrmb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.payment.toString(),
        text: `Payment ${transactionNumber}`,
        isCurrentItem: true
      });
    }

    if (isWriteOffRoute) {
      //New Write Off Bredcrmb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.writeOff.toString(),
        text: `New write-off ${transactionNumber}`,
        isCurrentItem: true
      });
    } else if (isNewAllocationRoute) {
      if (transactionRefNumber) {
        const invoiceId = routing.queryStringParam(
          routes.accounts.queryKeys.invoiceId
        );
        if (invoiceId) {
          // Return to Invoice breadcrumb, which gets shown if the user has come from that page
          breadcrumbs.push({
            onClick: () =>
              routing.replaceRetainingState(
                routes.accounts.invoices.invoice.path({ id: invoiceId })
              ),
            text: `Invoice ${transactionRefNumber}`,
            key: BillingBreadcrumbsKeys.invoice.toString()
          });
        }
      }
      // New Payment breadcrumb
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.allocation.toString(),
        text: `New Allocation ${transactionNumber || ""}`,
        isCurrentItem: true
      });
    } else if (isViewAllocationRoute) {
      // View
      breadcrumbs.push({
        key: BillingBreadcrumbsKeys.allocation.toString(),
        text: `Allocation ${transactionNumber || ""}`,
        isCurrentItem: true
      });
    }

    return <BreadCrumb routes={breadcrumbs} {...props} />;
  }
);

export const BillingBreadcrumbs: React.FC<
  Omit<BillingBreadcrumbProps, "patient">
> = props => (
  <DataFetcher<Contact | undefined>
    fetch={async ({ routing, practice }) => {
      const from = (routing.location.state?.from || "") as string;
      const match = routes.accounts.account.match(from.split("?")[0]);
      if (!match) {
        return undefined;
      }

      const patientId = match.params.id;

      return patientId ? practice.getContact(patientId) : undefined;
    }}
    fallback={<Shimmer styles={{ root: { width: "30%" } }} />}
  >
    {patient => <BillingBreadcrumbsBase {...props} patient={patient} />}
  </DataFetcher>
);
