import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";

export const GroupAppointmentFields: React.FC = observer(() => {
  const { isGroupAppointment, isAppointmentValidForEdit } = useContext(
    AppointmentFormContext
  );

  if (!isGroupAppointment) return null;

  return (
    <Fieldset>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <TextInputField
          name={appointmentFormNameOf("groupDescription")}
          label="Group description"
          disabled={isAppointmentValidForEdit}
        />
      </Stack>
    </Fieldset>
  );
});
