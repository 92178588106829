import { maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { InvoiceItemCommentFormValues } from "../invoice/components/InvoiceItemListAddComment.tsx";

export const COMMENT_MAX_LENGTH: number = 255;
export class InvoiceItemListAddCommentValidator extends Validator<InvoiceItemCommentFormValues> {
  constructor() {
    super();

    this.forField("comment", maxLength(COMMENT_MAX_LENGTH));
  }
}
