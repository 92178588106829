import { action, computed, observable, runInAction } from "mobx";

import { compareDatesOrUndefinedPredicate, DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccStore } from "@stores/acc/AccStore.ts";

export class ConditionAppointmentFormHelper {
  @observable patientId: string | undefined;

  @observable episodeOfCareId: string | undefined;

  @observable
  conditionsPromise = maybePromiseObservable<ICondition[]>();

  autoLinkConditionEnabled?: boolean;

  constructor(
    private root: IRootStore,
    _condition?: ICondition
  ) {
    if (_condition && this.conditions.length === 0) {
      runInAction(() => {
        this.conditionsPromise.set(Promise.resolve([_condition]));
        this.setEpisodeOfCareId(_condition.episodeOfCareId);
      });
    }
  }

  @action
  setPatientId = (patientId: string | undefined) => {
    this.patientId = patientId;
  };

  @action
  setEpisodeOfCareId = (episodeOfCareId: string | undefined) => {
    this.episodeOfCareId = episodeOfCareId;
  };

  private get acc(): AccStore {
    return this.root.acc;
  }

  @computed
  get conditions() {
    return this.conditionsPromise.value ? this.conditionsPromise.value : [];
  }

  @computed
  get condition() {
    return this.episodeOfCareId
      ? this.conditions.find(x => x.episodeOfCareId === this.episodeOfCareId)
      : undefined;
  }

  @computed
  get activeConditions() {
    return this.conditions.filter(x => !x.discharged);
  }

  getConditions = async () => {
    let mappedConditions: ICondition[] = [];
    if (this.patientId) {
      const episodesOfCare = await this.root.clinical.getPatientEpisodesOfCare(
        this.patientId
      );

      if (!episodesOfCare.length) {
        return [];
      }

      const [claimEpisodesOfCare, claims] = await Promise.all([
        this.root.acc.getClaimEpisodesOfCare({
          episodesOfCare: episodesOfCare.map(x => x.id)
        }),
        this.root.core.hasPermissions(Permission.ClaimRead)
          ? this.root.acc.fetchClaims({ patients: [this.patientId] })
          : undefined
      ]);

      const conditions = episodesOfCare.map(m => {
        const claimEpisodeOfCare = claimEpisodesOfCare.find(
          x => x.episodeOfCareId === m.id && x.isActive
        );
        return {
          episodeOfCare: m,
          claimEpisodeOfCare,
          claim: claimEpisodeOfCare
            ? claims?.results.find(x => x.id === claimEpisodeOfCare.claimId)
            : undefined
        };
      });

      mappedConditions = conditions.map(m => {
        const condition: ICondition = {
          episodeOfCareId: m.episodeOfCare.id,
          claim: m.claim,
          patientId: m.claim?.patientId,
          providerId: m.claim?.providerId,
          primaryDiagnosis:
            m.episodeOfCare.diagnoses?.find(x => x.isPrimaryDiagnosis)
              ?.diagnosisCode?.originalText ?? ClaimFormLabels.undiagnosed,
          diagnosisSide:
            m.episodeOfCare.diagnoses?.find(x => x.isPrimaryDiagnosis)
              ?.diagnosisSide ?? "",

          injuryDate: DateTime.fromISO(m.episodeOfCare.injuryDate),
          referralNumber: m.episodeOfCare.referralNumber,
          discharged: m.episodeOfCare.discharged,
          isPrivate: !m.claimEpisodeOfCare,
          createdDate: DateTime.fromISO(
            m.episodeOfCare.changeLog?.createdDate
          )!,
          sendAccForm: m.claim?.sendAccForm,
          isReferral: m.episodeOfCare.isReferral
        };

        return condition;
      });
    }

    return mappedConditions;
  };

  getSortedConditions = () => {
    return Array.from(
      this.conditions.sort((a, b) => {
        if (this.condition?.episodeOfCareId === a.episodeOfCareId) {
          return -1;
        } else if (this.condition?.episodeOfCareId === b.episodeOfCareId) {
          return 1;
        }
        return compareDatesOrUndefinedPredicate(b.createdDate, a.createdDate);
      })
    );
  };

  public async autoLinkCondition() {
    await this.loadAutoLinkConditionSetting();

    if (this.autoLinkConditionEnabled) {
      if (this.activeConditions.length === 1) {
        this.setEpisodeOfCareId(this.activeConditions[0].episodeOfCareId);
      }
    }
  }

  private async loadAutoLinkConditionSetting() {
    if (this.autoLinkConditionEnabled === undefined) {
      const orgUnitId = this.root.core.locationId;

      if (orgUnitId) {
        const orgUnit =
          await this.root.userExperience.getOrgUnitSetting(orgUnitId);
        this.autoLinkConditionEnabled =
          orgUnit?.preferenceSetting?.autoLinkCondition ?? false;
      }
    }
  }

  public getEpisodeOfCareInitialValues() {
    return {
      episodeOfCareId: this.condition?.episodeOfCareId,
      patientId: this.condition?.patientId,
      providerId: this.condition?.providerId
    };
  }
}
