import { stringify } from "query-string";

import { routes } from "@libs/routing/routes.ts";
import {
  convertInvoiceDetailsToRouteState,
  getStartingInvoiceValues
} from "@modules/billing/screens/shared-components/utils/invoice.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export const openNewInvoiceFromCalendar = async (
  root: RootStore,
  calendarEventId: string,
  attendeeId?: string
) => {
  const values = await getStartingInvoiceValues(root, {
    calendarEventId,
    patientId: attendeeId
  });

  const state = convertInvoiceDetailsToRouteState(values);

  const path = routes.accounts.invoices.new.path({});
  const queryKey = routes.accounts.invoices.queryKeys.calendarEvent;
  const search = stringify({ [queryKey]: calendarEventId });
  const href = `${path}?${search}`;

  root.routing.push(href, {
    ...state,
    ...root.routing.getStateWithFromQuery()
  });
};
