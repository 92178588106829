import React from "react";

import { Heading, IDropdownOption, Stack } from "@bps/fluent-ui";
import {
  PresetReportDefinition,
  ReportSlicerValues,
  ReportType
} from "@libs/gateways/reports/ReportsGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { ReportSlicerSaveValidator } from "../validators/ReportSlicerSaveValidator.ts";

interface ReportSaveModalProps {
  onClose: (values?: ReportSlicerValues) => void;
  reportDefinition: PresetReportDefinition;
  rename: boolean;
  isAdmin?: boolean;
}
const nameof = nameOfFactory<ReportSlicerValues>();
export const ReportSaveModal: React.FunctionComponent<ReportSaveModalProps> = ({
  onClose,
  rename,
  reportDefinition,
  isAdmin
}) => {
  const { reports } = useStores();
  const validator = new ReportSlicerSaveValidator({
    userDefinedReports: reports.userDefinedReportDefinitions,
    presetReports: reports.presetReportDefinitions,
    reportId: reportDefinition.id,
    rename
  });

  const onSubmit = async (values: ReportSlicerValues) => {
    onClose(values);
  };

  const rowVisibilityOptions: IDropdownOption<string>[] =
    reportDefinition.rowVisibilityTypes.map(x => ({
      key: x.role,
      text: x.name,
      data: x.role
    }));

  const rowVisibilityType = reportDefinition.rowVisibilityTypes.find(
    x => x.name === reportDefinition.rowVisibility
  );

  const defaultRowVisibility = reportDefinition.rowVisibilityTypes.length
    ? reportDefinition.rowVisibilityTypes[0].name
    : "All";

  const rowVisibility = rowVisibilityType
    ? rowVisibilityType.name
    : defaultRowVisibility;

  const isPublic = isAdmin
    ? reportDefinition.type === ReportType.Published
    : false;

  return (
    <SubmissionFormDialog<ReportSlicerValues>
      dialogName="Save Report"
      dialogProps={{
        onDismiss: () => onClose(),
        title: (
          <Heading variant="modal-heading">
            {rename ? "Rename report" : "New report"}
          </Heading>
        )
      }}
      onSubmit={onSubmit}
      buttonsProps={{
        disableSubmitOnPristine: false,
        hideButtonsSeparator: true,
        cancelButtonProps: {
          onClick: () => {
            onClose();
          }
        }
      }}
      initialValues={{
        reportName: rename ? reportDefinition.name : "",
        rowVisibility,
        isPublic
      }}
      validate={validator.validate}
    >
      {({ values }) => {
        const { isPublic } = values;
        return (
          <Stack tokens={{ childrenGap: 8 }}>
            <TextInputField
              name={nameof("reportName")}
              placeholder="Report name"
              label="Report name"
            />
            {isAdmin && (
              <CheckboxField name={nameof("isPublic")} label="Public" />
            )}
            {isAdmin && isPublic && rowVisibilityOptions.length > 1 && (
              <DropdownField
                name={nameof("rowVisibility")}
                label="Row Visibility"
                withNoEmptyOption={true}
                allowNull={false}
                options={rowVisibilityOptions}
              />
            )}
          </Stack>
        );
      }}
    </SubmissionFormDialog>
  );
};
