import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  DetailsList,
  DtoDetailsRow,
  IColumn,
  IDetailsRowProps,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  MedicationClinicalDataItemDto,
  MedicationTestElements
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

export interface MedicationsTableProps {
  medications: MedicationClinicalDataItemDto[];
}

const MedicationsTableBase: FunctionComponent<MedicationsTableProps> = observer(
  ({ medications }) => {
    const { clinical } = useStores();
    const columns: IColumn[] = [
      {
        key: "productName",
        name: "Product name",
        minWidth: 120,
        maxWidth: 160,
        onRender: item => {
          return (
            <Text
              {...dataAttribute(
                DataAttributes.Element,
                MedicationTestElements.ProductNameColumn
              )}
            >
              {item.productName}
            </Text>
          );
        }
      },
      {
        key: "doseUnit",
        name: "Dose",
        minWidth: 100,
        maxWidth: 120,
        isMultiline: true,
        onRender: (record: MedicationClinicalDataItemDto) => {
          return (
            <Text
              {...dataAttribute(
                DataAttributes.Element,
                MedicationTestElements.DoseColumn
              )}
            >
              {record.dose ?? ""}{" "}
              {(record.doseUnit &&
                clinical.ref.dosingAdministrationUnits.map.get(record.doseUnit)
                  ?.text) ||
                ""}{" "}
              {(record.frequency &&
                clinical.ref.dosingFrequencies.map.get(record.frequency)
                  ?.text) ||
                ""}
            </Text>
          );
        }
      }
    ];
    return (
      medications && (
        <Stack
          styles={{
            root: {
              overflowY: "auto"
            }
          }}
        >
          <DetailsList
            setKey="medications"
            compact
            columns={columns}
            onRenderRow={(x: IDetailsRowProps) => <DtoDetailsRow {...x} />}
            items={medications || []}
            cellStyleProps={{
              cellExtraRightPadding: 24,
              cellRightPadding: 8,
              cellLeftPadding: 2
            }}
          />
        </Stack>
      )
    );
  }
);

export const MedicationsTable = withFetch(
  x => [
    x.clinical.ref.dosingFrequencies.load(),
    x.clinical.ref.dosingAdministrationUnits.load()
  ],
  MedicationsTableBase
);
