import React from "react";
import { To } from "react-router-dom";

import { dataAttribute, DataAttributes, Stack, Text } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const AccContractLink: React.FC = () => {
  const path: To = {
    pathname: routes.settings.practices.basePath.pattern
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <Text>No contract-specific fees available</Text>
      <Navigate
        {...dataAttribute(DataAttributes.Element, "practice-settings-link")}
        to={path}
      >
        Add ACC contracts
      </Navigate>
    </Stack>
  );
};
