import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { TemplateManagementWrapper } from "./TemplateManagementWrapper.tsx";

const ManageTemplateBase: FunctionComponent = observer(() => {
  return <TemplateManagementWrapper />;
});

const ManageTemplateDialog = withFetch(
  x => [x.correspondence.ref.correspondenceTypes.load()],
  ManageTemplateBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ManageTemplateDialog;
