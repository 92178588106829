import { observer } from "mobx-react-lite";
import { useRef } from "react";

import { routes } from "@libs/routing/routes.ts";
import { ConditionModalFormValues } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/Condition.types.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { OverviewCard } from "@modules/clinical/screens/shared-components/OverviewCard.tsx";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ConditionCardIds } from "../ConditionScreen.tsx";

interface ConditionCardsProps {
  condition: Condition;
}

export const ConditionCards: React.FC<ConditionCardsProps> = observer(
  ({ condition }) => {
    const root = useStores();

    const initialCondition = ConditionHelper.getInitialCondition(condition);
    const conditionHelper = useRef(
      new ConditionHelper(root, condition.patientId, {
        onSubmit: async (
          values: ConditionModalFormValues,
          helper: ConditionHelper
        ) => {
          await helper.updateCondition(
            values,
            values.referralIn
              ? condition.nextCalendarEventProviderId
              : undefined
          );
        },
        onSubmitSucceeded: condition => {
          if (!condition.isPrivate && condition.claim) {
            root.routing.push(
              {
                pathname: routes.claims.management.edit.path({
                  id: condition.claim.id
                })
              },
              { openACC32Card: true }
            );
          }
        },
        initialCondition
      })
    );

    return (
      <OverviewCard
        cardId={ConditionCardIds.overview}
        referralIn={condition.isReferral}
        conditionHelper={conditionHelper.current}
        initialCalendarEvent={condition.initialCalendarEvent}
        initialConsultDate={condition.initialCalendarEvent?.startDateTime}
        isClaim={true}
        onDialogOpened={() =>
          conditionHelper.current.setHiddenConditionModal(false)
        }
        diagnoses={condition.diagnoses.map(x => ({
          description: x.diagnosisCode?.originalText,
          side: x.diagnosisSide
        }))}
        isViewMode={condition.inActiveClaim?.isViewMode}
      />
    );
  }
);
