import {
  dataAttribute,
  DataAttributes,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime, isToday } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import {
  ConditionListItem,
  EpisodeOfCareBadgesLabels
} from "../condition-modal/Condition.types.ts";
import { ConditionConsultBadge } from "../condition-modal/ConditionConsultBadge.tsx";
import { convertClinicalSideToDiagnosisSide } from "./active-condition-list.utils.ts";
import { getSubheadingStyles } from "./ActiveConditionList.styles.ts";

type ActiveClaimListItemProps = {
  conditionListItem: ConditionListItem;
};

export const ActiveConditionListItem: React.FC<ActiveClaimListItemProps> = ({
  conditionListItem
}) => {
  const { palette } = useTheme();
  const { core } = useStores();

  return (
    <Stack
      styles={{ root: { color: palette.neutralPrimary } }}
      {...dataAttribute(
        DataAttributes.Data,
        conditionListItem.claim
          ? conditionListItem.claim.id
          : conditionListItem.episodeOfCareId
      )}
    >
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
        <Text>
          {conditionListItem.primaryDiagnosis}
          {convertClinicalSideToDiagnosisSide(conditionListItem.diagnosisSide)}
        </Text>
        <Stack>
          <When permission={Permission.ClaimRead}>
            <ConditionConsultBadge conditionListItem={conditionListItem} />
          </When>
        </Stack>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            marginTop: 4
          }
        }}
      >
        <Text styles={getSubheadingStyles}>
          {conditionListItem.claim?.dto.initialConsultDate
            ? `Initial ${DateTime.fromISO(
                conditionListItem.claim?.dto.initialConsultDate
              ).toDayDefaultFormat()}`
            : `Created ${
                isToday(conditionListItem.createdDate)
                  ? "today"
                  : conditionListItem.createdDate.toDayDefaultFormat()
              }`}
        </Text>
        {conditionListItem.injuryDate && (
          <>
            <Text styles={getSubheadingStyles}>|</Text>
            <Text styles={getSubheadingStyles}>
              {`Injury date ${conditionListItem.injuryDate?.toDayDefaultFormat()}`}
            </Text>
          </>
        )}
        {core.hasPermissions(Permission.ClaimRead) &&
          !conditionListItem.isPrivate &&
          conditionListItem.claim?.claimNumber && (
            <>
              <Text styles={getSubheadingStyles}>|</Text>
              <Text
                styles={getSubheadingStyles}
              >{`Claim number ${conditionListItem.claim.claimNumber}`}</Text>
            </>
          )}
        {core.hasPermissions(Permission.ClaimRead) &&
          conditionListItem.isPrivate && (
            <>
              <Text styles={getSubheadingStyles}>|</Text>
              <Text styles={getSubheadingStyles}>
                {EpisodeOfCareBadgesLabels.Private}
              </Text>
            </>
          )}
      </Stack>
    </Stack>
  );
};
