import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";

import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";

export const OutsideWorkingHoursMessageBar: React.FC = observer(() => {
  let messageContent = "";
  let messageBarType = MessageBarType.info;
  const {
    isAppointmentWithinUserWorkTime,
    hasBookOutsideWorkingHoursPermissions,
    isSettingOutsideWorkHoursWithNoPermission
  } = useContext(AppointmentFormContext);
  if (
    hasBookOutsideWorkingHoursPermissions() &&
    !isAppointmentWithinUserWorkTime
  ) {
    messageBarType = MessageBarType.info;
    messageContent =
      "The current appointment’s start and end date is outside of provider’s working hours.";
  } else if (isSettingOutsideWorkHoursWithNoPermission()) {
    messageBarType = MessageBarType.error;
    messageContent =
      "You do not have permission to set the appointment's start and end date outside of the practice's opening hours";
  }
  return (
    <>
      {messageContent && (
        <MessageBar
          styles={{
            root: { flex: 1, width: "auto" },
            iconContainer: { margin: "auto 8px auto 16px" }
          }}
          messageBarType={messageBarType}
        >
          {messageContent}
        </MessageBar>
      )}
    </>
  );
});
