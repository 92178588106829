import { IDialogContentProps, Stack } from "@bps/fluent-ui";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AdjustInvoiceDialogValidator } from "./AdjustInvoiceDialogValidator.tsx";

export interface AdjustInvoiceDialogProps {
  onConfirm: (reason: string) => void | Promise<void>;
  onCancel: () => void;
  hidden: boolean;
  subTextJSX?: JSX.Element;
  dialogContentProps?: IDialogContentProps;
}

export interface AdjustInvoiceFormValues {
  otherReason: string;
}

const validator = new AdjustInvoiceDialogValidator();

const AdjustInvoiceDialogBase: React.FC<AdjustInvoiceDialogProps> = props => {
  const { hidden, onConfirm, onCancel, subTextJSX, dialogContentProps } = props;

  if (hidden) {
    return null;
  }

  const handleConfirm = async (values: AdjustInvoiceFormValues) => {
    await onConfirm(values.otherReason);
  };

  return (
    <SubmissionFormDialog<AdjustInvoiceFormValues>
      dialogName="Adjusting invoice"
      onSubmit={handleConfirm}
      initialValues={{ otherReason: undefined }}
      validate={validator.validate}
      dialogProps={{
        title: "Adjusting invoice",
        minWidth: 430,
        maxWidth: 430,
        onDismiss: onCancel,
        dialogContentProps
      }}
      buttonsProps={{
        submitButtonProps: {
          iconProps: {
            hidden: true
          }
        }
      }}
    >
      {() => (
        <Stack tokens={{ childrenGap: 8 }}>
          {subTextJSX && subTextJSX}
          <TextInputField label="Comment" name="otherReason" required />
        </Stack>
      )}
    </SubmissionFormDialog>
  );
};

export const AdjustInvoiceDialog = withFetch(
  x => [x.billing.ref.transactionCancelReason.load()],
  AdjustInvoiceDialogBase
);
