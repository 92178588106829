import { useEffect } from "react";
import { useField, useForm, useFormState } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { Stack, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";

import {
  Operation,
  OrgUnitOpeningHoursOverridesFormValues,
  OrgUnitOpeningHoursOverridesValues
} from "./OrgUnitOpeningHoursOverridesForm.types.ts";

export const OrgUnitOpeningHoursOverrideFields = () => {
  const nameOf = nameOfFactory<OrgUnitOpeningHoursOverridesValues>();
  const theme = useTheme();
  const form = useForm();
  const { pristine } = useFormState<OrgUnitOpeningHoursOverridesValues>({
    subscription: { pristine: true }
  });

  const {
    input: { value: orgUnitOpeningHoursOverrides }
  } = useField<OrgUnitOpeningHoursOverridesFormValues[]>(
    nameOf("orgUnitOpeningHoursOverrides"),
    { subscription: { value: true } }
  );

  useEffect(() => {
    if (pristine) {
      form.reset();
    }
  });
  return (
    <Stack styles={{ root: { overflowY: "auto" } }}>
      <FieldArray name={nameOf("orgUnitOpeningHoursOverrides")}>
        {(
          fieldsRenderProps: FieldArrayRenderProps<
            OrgUnitOpeningHoursOverridesFormValues,
            HTMLElement
          >
        ) => {
          const { fields } = fieldsRenderProps;
          const isOpen = (value: string) => value !== Operation.Closed;

          return fields.map((name, index) => {
            return (
              <Fieldset
                key={name}
                horizontal
                styles={{
                  root: {
                    borderBottom:
                      index < fields.value.length - 1 ? "1px solid" : undefined,
                    borderColor: theme.palette.neutralLight,
                    margin: "0px 64px 0px 64px",
                    paddingBottom: index < fields.value.length - 1 ? "16px" : 0,
                    paddingTop: 16,
                    maxWidth: "calc(100% - 128px)",
                    "&:first-child": {
                      paddingTop: 32
                    },
                    "&:last-child": {
                      paddingBottom: 32
                    }
                  }
                }}
              >
                <DropdownField
                  styles={{ root: { width: 90 } }}
                  placeholder="Operation"
                  label="&nbsp;"
                  name={`${name}.operation`}
                  options={[
                    { key: Operation.Open, text: "Open" },
                    { key: Operation.Closed, text: "Closed" }
                  ]}
                />
                <DatePickerField
                  label="From"
                  placeholder="Start"
                  name={`${name}.startDate`}
                />
                <FieldSpy
                  name={`${name}.startDate`}
                  onChange={date => {
                    if (
                      date &&
                      DateTime.fromJSDateOrNow(
                        orgUnitOpeningHoursOverrides[index].endDate
                      ) < DateTime.fromJSDate(date)
                    ) {
                      form.change(`${name}.endDate`, date);
                    }
                  }}
                />
                <DatePickerField
                  label="Until"
                  placeholder="End"
                  name={`${name}.endDate`}
                />
                <FieldSpy
                  name={`${name}.endDate`}
                  onChange={date => {
                    if (
                      date &&
                      DateTime.fromJSDate(date) <
                        DateTime.fromJSDateOrNow(
                          orgUnitOpeningHoursOverrides[index].startDate
                        )
                    ) {
                      form.change(`${name}.startDate`, date);
                    }
                  }}
                />
                <FieldCondition when={`${name}.operation`} is={isOpen}>
                  <TimePickerField
                    label="Start time"
                    suggestionInterval={30}
                    disabled={false}
                    name={`${name}.startTime`}
                  />
                  <TimePickerField
                    label="End time"
                    suggestionInterval={30}
                    disabled={false}
                    name={`${name}.endTime`}
                  />
                </FieldCondition>
                <TextInputField
                  styles={{ root: { width: 216, flexGrow: 1 } }}
                  name={`${name}.reason`}
                  label="Reason"
                />
                <Stack verticalAlign="start">
                  <DeleteButton
                    text="Remove"
                    styles={{ root: { marginTop: 29 } }}
                    onClick={() => {
                      fields.remove(index);
                    }}
                  />
                </Stack>
              </Fieldset>
            );
          });
        }}
      </FieldArray>
    </Stack>
  );
};
