import { FunctionComponent } from "react";

import {
  ResizableOverflowBar,
  ResizableOverflowBarProps,
  ScreenWidthMinXLarge,
  Stack,
  useScreenSize
} from "@bps/fluent-ui";

import {
  COIN_WIDTH_ONLY,
  COIN_WITH_CLOSE_ICON_WIDTH,
  PATIENT_PILL_MAX_WIDTH
} from "./PatientPill.tsx";

interface ClinicalRecordsBarProps
  extends Pick<
    ResizableOverflowBarProps<any>,
    "items" | "onRenderItem" | "onRenderOverflow"
  > {}

export const ClinicalRecordsBar: FunctionComponent<
  ClinicalRecordsBarProps
> = props => {
  const { width } = useScreenSize();

  return (
    <Stack styles={{ root: { flex: 1, minWidth: 1 } }}>
      <ResizableOverflowBar
        {...props}
        itemSize={
          width < ScreenWidthMinXLarge
            ? COIN_WITH_CLOSE_ICON_WIDTH
            : COIN_WIDTH_ONLY
        }
        itemsGap={8}
        activeItemSize={PATIENT_PILL_MAX_WIDTH}
      />
    </Stack>
  );
};
