import { action, computed, observable } from "mobx";

import { DateTime, newGuid } from "@bps/utils";
import {
  ClinicalDataType,
  MeasurementDto,
  MeasurementType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalDataTypeName } from "@stores/clinical/models/clinical-tab/ClinicalTabName.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { ClinicalDataToMeasurementConverter } from "../utils/ClinicalDataToMeasurementConverter.ts";

export class ClinicalToolResultOpener {
  @observable
  private _showConfirmDialog: boolean;
  private _openResultType: ClinicalDataType;
  private _converter = new ClinicalDataToMeasurementConverter();
  private _openContextId: string | undefined;

  constructor(
    private clinical: ClinicalStore,
    private clinicalRecord: ClinicalRecord
  ) {
    if (!clinical) throw new Error("clinical required parameter");

    if (!clinicalRecord) throw new Error("clinicalRecord required parameter");
  }

  @computed
  get showConfirmDialog() {
    return this._showConfirmDialog;
  }

  @computed
  get resultTypeName() {
    return ClinicalDataTypeName[this._openResultType];
  }

  @action
  openClinicalToolFromDropDown = (type: ClinicalDataType) => {
    if (type === ClinicalDataType.PSFS) {
      this.closeConfirmDialog();
      const contextId = newGuid();
      const encounterId = this.clinicalRecord?.openEncounter?.id;
      this.clinical.ui.setPatientClinicalContent({
        type,
        contextId,
        encounterId
      });
    } else {
      this._openResultType = type;
      this._openContextId = undefined;

      const measurement = this.clinicalRecord.measurements.find(
        m =>
          m.encounterId === this.clinicalRecord.openEncounter?.id &&
          m.type === this._converter.clinicalToMeasurement(type)
      );

      if (measurement) {
        this._openContextId = measurement.contextId;
        this._showConfirmDialog = true;
      } else {
        this.closeConfirmDialog();
        this.clinical.ui.setPatientClinicalContent({ type });
      }
    }
  };

  //after the user has confirmed to open the current result from the modal...
  @action
  onConfirmOpenCurrentResult = () => {
    this.closeConfirmDialog();
    this.clinical.ui.setPatientClinicalContent({
      type: this._openResultType,
      contextId: this._openContextId
    });
  };

  @action
  closeConfirmDialog = () => {
    this._showConfirmDialog = false;
  };

  openResultFromLink = async (item: MeasurementDto) => {
    if (item.type === MeasurementType.PSFS) {
      const psfsContext =
        this.clinicalRecord.clinicalData?.psfsContext?.contexts.find(
          x => x.contextId === item.contextId
        );

      const converterType =
        this.clinicalRecord.openEncounter?.id === item.encounterId
          ? this._converter.measurementToClinical(MeasurementType[item.type])
          : this._converter.measurementToReadOnlyView(
              MeasurementType[item.type]
            );

      this.clinical.ui.setPatientClinicalContent({
        type: converterType,
        encounterId: item.encounterId,
        contextId: item.contextId,
        title: `${item.type} ${psfsContext?.diagnosis?.originalText}`,
        toolTipText: `${item.type} ${psfsContext?.diagnosis
          ?.originalText} - ${DateTime.fromISOOrNow(
          item.changeLog?.createdDate
        ).toDayDefaultFormat()}`
      });
    } else if (this.clinicalRecord.openEncounter?.id === item.encounterId) {
      //open for current encounter
      this.clinical.ui.setPatientClinicalContent({
        type: this._converter.measurementToClinical(MeasurementType[item.type])
      });
    } else {
      //open historical entry
      this.clinical.ui.setPatientClinicalContent({
        type: this._converter.measurementToReadOnlyView(
          MeasurementType[item.type]
        ),
        encounterId: item.encounterId,
        contextId: item.contextId,
        title: `${item.type} - ${DateTime.fromISOOrNow(
          item.changeLog?.createdDate
        ).toDayDefaultFormat()}`
      });
    }
  };

  @action
  addNewSeriesItemFromLink = (type: ClinicalDataType, contextId: string) => {
    this._openResultType = type;
    this._openContextId = contextId;

    const typeText = this._converter.clinicalToMeasurement(type);
    const measurement = this.clinicalRecord.measurements.find(
      m =>
        m.encounterId === this.clinicalRecord.openEncounter?.id &&
        m.type === typeText &&
        m.contextId === contextId
    );

    if (measurement) {
      this._showConfirmDialog = true;
    } else if (type === ClinicalDataType.PSFS) {
      const psfsContext =
        this.clinicalRecord.clinicalData?.psfsContext?.contexts.find(
          x => x.contextId === contextId
        );

      const psfsMeasurement = this.clinicalRecord.measurements.find(
        x => x.contextId === contextId && x.type === typeText
      );

      this.clinical.ui.setPatientClinicalContent({
        type,
        contextId,
        encounterId: psfsMeasurement?.encounterId,
        title: `${MeasurementType.PSFS} ${psfsContext?.diagnosis?.originalText}`,
        toolTipText: `${MeasurementType.PSFS} ${psfsContext?.diagnosis
          ?.originalText} - ${DateTime.now().toDayDefaultFormat()}`
      });
    } else {
      this.closeConfirmDialog();
      this.clinical.ui.setPatientClinicalContent({
        type,
        contextId
      });
    }
  };
}
