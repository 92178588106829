import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { Spinner } from "@bps/fluent-ui";
import { PaymentViewContext } from "@modules/billing/screens/payment/context/PaymentViewContext.tsx";
import { PaymentViewHelper } from "@modules/billing/screens/payment/context/PaymentViewHelper.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface PaymentViewDataFetcherProps extends PropsWithChildren {
  paymentId: string;
}

export const PaymentViewDataFetcher: React.FC<PaymentViewDataFetcherProps> =
  observer(({ children, paymentId }) => {
    const loadHelper = async (root: RootStore): Promise<PaymentViewHelper> => {
      const payment = await root.billing.getPayment(paymentId);
      const contact = await root.practice.getContact(payment.accountId, {
        includeContactPreferences: true
      });

      const helper = new PaymentViewHelper(root, {
        payment,
        contact
      });

      return helper;
    };

    return (
      <DataFetcher<PaymentViewHelper> fetch={loadHelper} fallback={<Spinner />}>
        {helper => {
          return (
            <PaymentViewContext.Provider value={helper}>
              {children}
            </PaymentViewContext.Provider>
          );
        }}
      </DataFetcher>
    );
  });
