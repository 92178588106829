import { useState } from "react";

import { Link, Spinner, Stack, Text, TooltipHost } from "@bps/fluent-ui";
import {
  ClinicalDocumentLite,
  DocumentDestinationType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

export interface IRenderIncomingDocDetailsProps {
  item: ClinicalDocumentLite;
  clinicalRecord: ClinicalRecord;
}

export const RenderIncomingDocDetails: React.FunctionComponent<
  IRenderIncomingDocDetailsProps
> = ({ item, clinicalRecord }) => {
  const root = useStores();
  const { correspondence, inbox, practice } = root;
  const [previewDocument, setPreviewDocument] = useState<Document | undefined>(
    undefined
  );

  const handleDocumentClick = async (item: ClinicalDocumentLite) => {
    if (
      item.storeIn === DocumentDestinationType.Investigations ||
      item.storeIn === DocumentDestinationType.Correspondence ||
      item.storeIn === DocumentDestinationType.ClinicalImages
    ) {
      const [corr, documentUrl] = await Promise.all([
        correspondence.getCorrespondenceByDocumentId(
          clinicalRecord.id,
          item.id
        ),
        correspondence.getDocumentUrl({
          patientId: clinicalRecord.id,
          documentId: item.id
        })
      ]);

      if (corr && documentUrl) {
        setPreviewDocument({
          documentId: corr.id,
          entityType: DocumentEntityType.Patient,
          entityId: corr.patientId,
          name: corr.name,
          extension: corr.extension,
          previewUri: documentUrl.url,
          downloadUri: documentUrl.url
        });
      }
    } else {
      const inboxDocument = await inbox.getInboxDocument(
        {
          id: item.id,
          documentDetailId: item.documentDetailId
        },
        { ignoreCache: true }
      );

      setPreviewDocument({
        entityId: inboxDocument.id,
        documentId: inboxDocument.documentDetailId,
        entityType: DocumentEntityType.DocumentProcessing,
        name: inboxDocument.name,
        extension: inboxDocument.docExtension ?? "",
        downloadUri: inboxDocument.downloadLinkUri,
        previewUri: inboxDocument.previewUri
      });
    }
  };

  return (
    <>
      <DataFetcher
        fetch={async () => {
          return item.fromId
            ? await practice.getContactsById([item.fromId])
            : [];
        }}
        fallback={<Spinner />}
      >
        {(contacts: Contact[]) => {
          const contact = contacts[0];
          const contactName = contact?.preferredFullName;

          return (
            <Stack tokens={{ childrenGap: 4 }}>
              {contactName && (
                <Text variant="small" nowrap>
                  {`from ${contactName}`}
                </Text>
              )}
              <Stack.Item>
                <TooltipHost content="Preview">
                  <Link onClick={() => handleDocumentClick(item)}>
                    {item.title}
                  </Link>
                </TooltipHost>
              </Stack.Item>
            </Stack>
          );
        }}
      </DataFetcher>
      {previewDocument && (
        <DocumentViewerDialog
          getDocument={() => previewDocument}
          closeDocumentViewer={() => setPreviewDocument(undefined)}
        />
      )}
    </>
  );
};
