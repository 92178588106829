import { FC, useRef } from "react";

import {
  AnimationStyles,
  IStackProps,
  mergeStyles,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  CommunicationType,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { formatCommunication } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { CommunicationTypeText } from "@ui-components/RefText.tsx";

import { PreferredArgs, PreferredCheckBox } from "../PreferredCheckBox.tsx";
import { sortedCommunications } from "./utils.ts";

type ContactMethodListCommunicationsProps = {
  patient: Contact;
  isOrganisation?: boolean;
};

export const ContactMethodListCommunications: FC<
  ContactMethodListCommunicationsProps
> = ({ patient, isOrganisation }) => {
  const preferredNew = useRef<boolean>(false);
  const { core, practice } = useStores();

  const onPreferredClick = async (preferredArgs: PreferredArgs) => {
    const communications = patient.communications.slice().map(x => ({
      type: x.type,
      value: x.value,
      preferred: !(
        x.type === preferredArgs.preferredOptions?.type &&
        x.value === preferredArgs.preferredOptions.value
      )
        ? false
        : !x.preferred
    }));
    preferredNew.current = true;
    patient.type === ContactType.Patient
      ? await practice.updatePatient({
          id: patient.id,
          communications
        })
      : await practice.updateIndividual({
          id: patient.id,
          communications
        });
    preferredNew.current = false;
  };

  return (
    <>
      {sortedCommunications(patient.communications).map(item => {
        let className: IStackProps["className"];
        if (isOrganisation) {
          className = "noSelect";
        } else if (item.preferred && preferredNew) {
          className = mergeStyles({ ...AnimationStyles.slideRightIn40 });
        }

        return (
          <Stack
            key={JSON.stringify(item)}
            horizontal
            className={className}
            horizontalAlign="space-between"
            styles={(props, theme) => ({
              root: [
                { padding: "4px 10px" },
                {
                  selectors: {
                    "&:hover ": {
                      backgroundColor:
                        props.className === "noSelect"
                          ? "none"
                          : theme.semanticColors.disabledBackground
                    }
                  }
                }
              ]
            })}
          >
            <Stack>
              <Text variant="xSmall">
                <CommunicationTypeText code={item.type} />
              </Text>
              <Text>{formatCommunication(item, core).value}</Text>
            </Stack>
            {!isOrganisation && (
              <Stack verticalAlign="center" styles={{ root: { margin: 0 } }}>
                {item.type !== CommunicationType.Email &&
                item.type !== CommunicationType.Website &&
                item.type !== CommunicationType.Fax ? (
                  <PreferredCheckBox
                    contMethodsArgs={{
                      type: item.type,
                      value: item.value
                    }}
                    preferred={!!item.preferred}
                    tooltipContent={
                      item.preferred
                        ? "Preferred contact method"
                        : "Mark as preferred"
                    }
                    onPreferredClick={onPreferredClick}
                  />
                ) : null}
              </Stack>
            )}
          </Stack>
        );
      })}
    </>
  );
};
