import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AddFromWaitingListButton } from "@modules/booking/screens/waiting-list/components/waiting-list-details-dialog/AddFromWaitingListButton.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import {
  getActiveAttendees,
  getSpacesRemaining
} from "./GroupAttendeesHelper.ts";
import { GroupAttendeesSelect } from "./GroupAttendeesSelect.tsx";
import { QuickAddContact } from "./QuickAddContact.tsx";

export const GroupAttendeesAdd: React.FunctionComponent = observer(() => {
  const { isAppointmentValidForEdit, patientLabel } = useContext(
    AppointmentFormContext
  );

  const { values } = useFormState<AppointmentFormValues>({
    subscription: { values: true }
  });

  const activeAttendees = getActiveAttendees(values.groupAttendees || []);

  const spacesRemaining = getSpacesRemaining(
    values.maxParticipants ?? 0,
    activeAttendees.length ?? 0
  );

  const form = useForm<AppointmentFormValues>();
  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <Stack.Item
        styles={{
          root: { width: "208px" }
        }}
      >
        <GroupAttendeesSelect />
        <AddFromWaitingListButton />
      </Stack.Item>

      <SpinNumberInputField
        name={appointmentFormNameOf("maxParticipants")}
        label="Max"
        disabled={isAppointmentValidForEdit}
        required
        styles={{ input: { width: 0 } }}
        min={activeAttendees.length ?? 0}
        fieldItemStyles={{ root: { width: 100 } }}
      />
      <Stack.Item styles={{ root: { marginTop: 28 } }}>
        <QuickAddContact
          permission={[
            Permission.ContactRead,
            Permission.ContactWrite,
            Permission.PatientRead,
            Permission.PatientWrite
          ]}
          addButtonLabel={`New ${patientLabel}`}
          typeToAdd={ContactType.Patient}
          onContactAdded={contactId => {
            if (contactId && spacesRemaining > 0) {
              const { groupAttendeeIds = [] } = values;
              form.change("groupAttendeeIds", [...groupAttendeeIds, contactId]);
            }
          }}
          disabled={spacesRemaining <= 0 || isAppointmentValidForEdit}
        />
      </Stack.Item>
    </Stack>
  );
});
