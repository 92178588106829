import {
  CollapsibleCard,
  IStyleFunctionOrObject,
  Spinner,
  Stack,
  StateBlock,
  StateBlockStyleProps,
  StateBlockStyles
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useDischargeContext } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DischargeId, DischargeLabel } from "../SOTAP.constants.ts";
import { DischargeSummary } from "../SOTAP.types.ts";
import { DiagnosisList } from "./DiagnosisList.tsx";
import { DischargeSummaryProvider } from "./DischargeSummaryProvider.tsx";

export const DischargeSummaryCard: React.FunctionComponent = () => {
  const { getDiagnoses, getDischargeSummary } = useDischargeContext();
  const { core } = useStores();

  const { primaryDiagnoses, additionalDiagnoses } = getDiagnoses();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const businessRoleCatalogText = core.catalogBusinessRoles.find(
    x => x.code === clinicalRecord.openEncounter?.businessRole
  )?.text;

  const stateBlockStyles: IStyleFunctionOrObject<
    StateBlockStyleProps,
    StateBlockStyles
  > = {
    root: {
      padding: "8px 16px"
    }
  };

  return (
    <div id={DischargeId.Summary}>
      <CollapsibleCard
        heading={DischargeLabel.Summary}
        headingLevel="section-heading"
        iconName="FormLibraryMirrored"
        openOnRender
      >
        <Stack
          tokens={{
            childrenGap: 8
          }}
        >
          <DischargeSummaryProvider />

          <DiagnosisList
            primaryDiagnoses={primaryDiagnoses}
            additionalDiagnoses={additionalDiagnoses}
          />
          <DataFetcher<DischargeSummary>
            fallback={<Spinner />}
            fetch={getDischargeSummary}
          >
            {summary => {
              return (
                <>
                  {summary.claim && summary.claim.dto.referralProvider && (
                    <StateBlock
                      styles={stateBlockStyles}
                      labelText="Referral provider"
                      descText={summary.claim.dto.referralProvider}
                    />
                  )}
                  {summary.claim ? (
                    <>
                      <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <StateBlock
                          styles={stateBlockStyles}
                          labelText="Claim number"
                          descText={summary.claim.claimNumber ?? "Not lodged"}
                        />
                        <StateBlock
                          styles={stateBlockStyles}
                          labelText={`Total consults (${businessRoleCatalogText})`}
                          descText={`${summary.totalVisitCount}`}
                        />
                      </Stack>
                      <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <StateBlock
                          styles={stateBlockStyles}
                          labelText="Injury date"
                          descText={
                            summary.injuryDate
                              ? DateTime.fromJSDate(
                                  summary.injuryDate
                                )?.toDayDefaultFormat()
                              : "Nil"
                          }
                        />
                        <StateBlock
                          styles={stateBlockStyles}
                          labelText="Initial consult"
                          descText={
                            summary.initialConsultDate
                              ? summary.initialConsultDate.toDayDefaultFormat()
                              : "Nil"
                          }
                        />
                      </Stack>
                    </>
                  ) : (
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                      <StateBlock
                        styles={stateBlockStyles}
                        labelText="Initial consult"
                        descText={
                          summary.initialConsultDate
                            ? summary.initialConsultDate.toDayDefaultFormat()
                            : "Nil"
                        }
                      />
                      <StateBlock
                        styles={stateBlockStyles}
                        labelText="Total consults"
                        descText={`${summary.totalVisitCount} total consults`}
                      />
                    </Stack>
                  )}
                </>
              );
            }}
          </DataFetcher>
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
