import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  Heading,
  IconButton,
  IContextualMenuItem,
  IGroupHeaderProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ObservationPivotName } from "./ObservationsExpandedTable.tsx";
import {
  contextMenuStyles,
  subMarginStyle
} from "./ObservationsList.styles.ts";
import { ObservationGroup } from "./utils.ts";

interface ObservationHeaderProps {
  props: IGroupHeaderProps;
  selectedKey?: string;
  clinicalRecord: ClinicalRecord;
}

enum ObservationGroupContextMenu {
  AddAnotherKey = "addAnother",
  seeAsAChartKey = "seeAsAChart",
  AddAnotherLabel = "Add another",
  seeAsAChartLabel = "See as a chart"
}

export const ObservationHeader: React.FC<ObservationHeaderProps> = ({
  props,
  selectedKey
}) => {
  const group = props?.group!;
  const name = group.name || "";

  const groupCount = props.group?.data;
  const isFinal = props?.groups?.length
    ? props?.groupIndex === props?.groups?.length - 1
    : false;

  const isCollapsed = props.group?.isCollapsed || false;
  const theme = useTheme();
  const DEPTH_1 = 1;
  const { clinical } = useStores();
  const getContextMenuItem = (): IContextualMenuItem[] => {
    const seeAsAChartMenuItem: IContextualMenuItem = {
      key: "seeAsAChart",
      text: ObservationGroupContextMenu.seeAsAChartLabel,
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.Observations,
          defaultPivot: ObservationPivotName.DashBoard
        });
      }
    };

    if (
      selectedKey === ObservationGroup.GroupByDateAndTypeKey ||
      selectedKey === ObservationGroup.GroupByExaminationAndDateKey
    ) {
      return [seeAsAChartMenuItem];
    }

    return [];
  };

  return (
    <Stack
      horizontal
      onClick={() => {
        if (props?.onToggleCollapse && props?.group) {
          props.onToggleCollapse(props.group);
        }
      }}
      styles={{
        root: {
          borderTop: `1px ${theme.palette.neutralLight} solid`,
          borderBottom:
            isFinal && isCollapsed
              ? `1px ${theme.palette.neutralLight} solid`
              : undefined
        }
      }}
    >
      {group.level === DEPTH_1 && (
        <IconButton
          {...dataAttribute(DataAttributes.Element, `more-button-${name}`)}
          menuIconProps={{
            iconName: "More"
          }}
          menuProps={{
            items: getContextMenuItem()
          }}
          styles={contextMenuStyles}
        />
      )}

      <IconButton
        {...dataAttribute(DataAttributes.Element, `${name}-collapse`)}
        iconProps={{
          iconName: isCollapsed ? "ChevronRight" : "ChevronDown"
        }}
        styles={group.level === DEPTH_1 ? contextMenuStyles : subMarginStyle}
      />
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ childrenGap: 4 }}
      >
        <Heading
          variant="section-sub-heading"
          styles={{
            root: {
              fontWeight: FontWeights.bold
            }
          }}
        >
          {name} ({groupCount})
        </Heading>
      </Stack>
    </Stack>
  );
};
