import {
  email,
  maxLength,
  phone,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { medicareProviderNumberValidator } from "@modules/settings/screens/users/components/utils.ts";
import { User } from "@stores/core/models/User.ts";

import { CreateUserFormValues, nameOf } from "./CreateUserForm.types.ts";

export class CreateUserFormValidator extends Validator<CreateUserFormValues> {
  constructor(existingUser: User | undefined) {
    super();

    this.forField(nameOf("firstName"), [required(), maxLength(50)]);
    this.forField(nameOf("lastName"), [required(), maxLength(50)]);
    this.forField(nameOf("username"), [
      required(),
      maxLength(50),
      email(),
      () => (existingUser ? "Email is in use by another user" : undefined)
    ]);

    this.forField(nameOf("phone"), [maxLength(50), phone]);
    this.forField(nameOf("middleName"), maxLength(50));
    this.forField(nameOf("businessRoles"), [required()]);
    this.forField(nameOf("medicareProviderNumber"), [
      maxLength(8),
      medicareProviderNumberValidator
    ]);
  }
}
