import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { useTheme } from "@bps/fluent-ui";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { getUserStylesSet } from "../UserScreens.styles.tsx";
import { AccContractFormValues } from "./AccContractForm.types.ts";
import { ProviderAccContractContext } from "./context/ProviderAccContractContext.tsx";
import { ProviderAccContracts } from "./ProviderAccContracts.tsx";

type AccContractFormProps = {
  user: User;
  contractTypes: string[] | undefined;
};

export const AccContractForm: FunctionComponent<AccContractFormProps> =
  observer(props => {
    const theme = useTheme();
    const { notification, core } = useStores();

    const { formFooter, formFields } = getUserStylesSet(theme);

    const accContractHelper = useContext(ProviderAccContractContext);

    const { onSubmit } = accContractHelper;

    const initialValues: Partial<AccContractFormValues> = {
      contractTypes: accContractHelper.getAccContractTypeFormValues(
        props.contractTypes
      )
    };

    const resetForm = (form?: FormApi<AccContractFormValues>) => {
      form?.reset();
      accContractHelper.onFormReset();
    };

    const updateAvailableContracts = async (message: EntityEventData) => {
      if (
        message.action === EventAction.Update &&
        message.id === props.user.id
      ) {
        resetForm();
      }
    };

    core.hub.onEntityEvent(Entity.User, updateAvailableContracts);

    return (
      <SubmissionForm<AccContractFormValues>
        formName="acc-contract"
        styles={{ main: { borderRadius: 4 } }}
        autoFocus={false}
        readOnly={!core.hasPermissions(Permission.UserWrite)}
        initialValues={initialValues}
        onSubmitSucceeded={() => {
          resetForm();
          notification.success("ACC contracts have been updated.");
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          },
          onCancel: form => resetForm(form)
        }}
      >
        {() => (
          <Fieldset
            styles={{
              root: { ...formFields }
            }}
          >
            <ProviderAccContracts />
          </Fieldset>
        )}
      </SubmissionForm>
    );
  });
