import { observer } from "mobx-react-lite";

import { PresetButtonType } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { CorrespondenceFilter } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

type CorrespondenceFilterProps = Pick<
  FilterBarProps<CorrespondenceFilter>,
  "children"
>;

export const CorrespondencesFilter: React.FC<CorrespondenceFilterProps> =
  observer(({ children }) => {
    const { clinical, correspondence, core } = useStores();
    const { clinicalRecord } = usePatientRecordScreenContext();
    const patientId = clinicalRecord.patient?.id;

    const sidePanelSize =
      clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize;

    const directions =
      correspondence.ref.correspondenceDirections.keyTextValues;

    const correspondenceTypes =
      correspondence.ref.correspondenceTypes.keyTextValues;

    const correspondenceStatuses =
      correspondence.ref.correspondenceStatuses.keyTextValues;

    const presets: PresetButtonType<CorrespondenceFilter>[] = [];

    const correspondences = correspondence.correspondenceMapValues;

    const isExistConfidentialCorrespondenceWithOtherUsers =
      correspondences.some(x => {
        return (
          x.dto.patientId === patientId &&
          correspondence.isNotNullSecGroupId(x.dto.secGroupId) &&
          !core.hasAccessToSecGroup(x.secGroupId)
        );
      });

    if (
      core.hasPermissions(Permission.ConfidentialDataAllowed) &&
      isExistConfidentialCorrespondenceWithOtherUsers
    ) {
      presets.push({
        name: "confidential",
        iconName: "ReportHacked",
        tooltip: "Show confidential",
        tooltipPresses: "Hide confidential",
        tooltipOnDisabled: "No confidential items"
      });
    }

    return (
      <FilterBar<CorrespondenceFilter>
        initialValues={{}}
        items={[
          {
            type: "searchBox",
            name: "searchText",
            stickItem: true,
            props: {
              placeholder: "Search title",
              styles: { root: { maxWidth: "180px", flexGrow: 1 } }
            }
          },
          {
            type: "optionsSelect",
            name: "types",
            props: {
              placeholder: "Type",
              label: "Type",
              options: correspondenceTypes,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "optionsSelect",
            name: "direction",
            props: {
              placeholder: "In/Out",
              label: "In/Out",
              options: directions,
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "optionsSelect",
            name: "statuses",
            props: {
              id: "correspondence-filter-status",
              placeholder: "Status",
              label: "Status",
              options: correspondenceStatuses,
              hideSearchOption: true,
              multiSelect: true,
              calloutWidth: 200
            }
          }
        ]}
        styles={{
          calloutItem: {
            ":nth-child(1)": { width: "100%" },
            ":nth-child(2)": { width: "100%" },
            ":nth-child(3)": { width: "100%" }
          },
          callout: {
            width: 288
          }
        }}
        shrinkVersion={sidePanelSize === TreeViewSize.Default}
        children={children}
        presets={presets}
      />
    );
  });
