import { useField, useForm } from "react-final-form";

import { focusElement, Stack } from "@bps/fluent-ui";
import { computePersonInitials } from "@libs/utils/utils.ts";
import { DemographicProfilePictureField } from "@modules/practice/screens/contact-details/shared-components/edit/DemographicProfilePictureField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TitlePickerField } from "@ui-components/form/TitlePickerField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { ContactCardIdsEnum } from "../../../shared-components/types/contact-card-ids.enum.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { orgEditFormNameOf } from "../../organisation/edit/EditOrganisationFormValues.tsx";
import { OrganisationLabels } from "../../organisation/organisation.types.ts";
import { EditPronounHeader } from "./EditPronounHeader.tsx";
import {
  ContactEditFormValues,
  contactFormNameOf
} from "./PatientEditFormValues.tsx";

interface EditContactHeaderProps {
  contact: Contact | undefined;
}

const EditContactHeaderBase: React.FC<EditContactHeaderProps> = ({
  contact
}) => {
  const { practice } = useStores();
  const {
    ui: { selectedEditCard }
  } = practice;

  const {
    input: { value: firstName }
  } = useField<string | undefined>(contactFormNameOf("firstName"), {
    subscription: { value: true }
  });

  const {
    input: { value: lastName }
  } = useField<string | undefined>(contactFormNameOf("lastName"), {
    subscription: { value: true }
  });

  const { change, batch } = useForm<ContactEditFormValues>();

  const headerIsVisible =
    !selectedEditCard || selectedEditCard === PatientCardIds.patientHeader;

  const nameInputRef = headerIsVisible ? focusElement : undefined;
  return (
    <Stack
      id={`${ContactCardIdsEnum.contactHeader}-edit`}
      tokens={{ childrenGap: 8 }}
      styles={{
        root: {
          paddingRight: 16,
          paddingTop: 21
        }
      }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        horizontalAlign="space-between"
      >
        <Stack.Item grow={1} basis={0}>
          <TitlePickerField
            label="Title"
            name={contactFormNameOf("title")}
            styles={{
              text: {
                minWidth: "auto"
              }
            }}
          />
        </Stack.Item>
        <Stack.Item grow={3} basis={0}>
          <TextInputField
            name={contactFormNameOf("firstName")}
            label="First name"
            required
            componentRef={nameInputRef}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="start">
        <Stack.Item grow={1} basis={0}>
          <DemographicProfilePictureField
            contact={contact}
            imageInitials={computePersonInitials(firstName, lastName)}
          />
        </Stack.Item>

        <Stack.Item grow={3} basis={0}>
          <Stack
            verticalAlign="center"
            tokens={{ childrenGap: 8 }}
            verticalFill
          >
            <TextInputField
              name={contactFormNameOf("lastName")}
              label="Last name"
              required
            />

            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Stack.Item grow>
                <DropdownField
                  name={contactFormNameOf("gender")}
                  placeholder="Select gender"
                  label="Gender"
                  options={practice.ref.genders.keyTextValues}
                />
              </Stack.Item>
              <EditPronounHeader
                pronounSubjectiveName={contactFormNameOf("pronounSubjective")}
                pronounObjectiveName={contactFormNameOf("pronounObjective")}
                pronounPossessiveName={contactFormNameOf("pronounPossessive")}
              />
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <YesNoToggleField
          alignWithInputs
          styles={{
            root: { minWidth: 110 }
          }}
          label="Health provider"
          name={orgEditFormNameOf("isHealthProvider")}
          placeholder={OrganisationLabels.Email}
        />
        <FieldSpy
          name={orgEditFormNameOf("isHealthProvider")}
          onChange={checked => {
            batch(() => {
              if (!checked) {
                change("disciplines", []);
              }
              change("appointmentReminderType", undefined);
              change("appointmentConfirmationType", undefined);
              change("appointmentReminderValue", undefined);
              change("appointmentConfirmationValue", undefined);
            });
          }}
        />
        <FieldCondition when={orgEditFormNameOf("isHealthProvider")} is={true}>
          <StaticTagPickerField
            fetchDataSource={() =>
              practice.ref.contactDisciplines.keyNameValues
            }
            excludeSelectedItems
            label="Disciplines"
            name={orgEditFormNameOf("disciplines")}
            formItemFieldStyles={{ root: { flex: 1 } }}
          />
        </FieldCondition>
      </Stack>
    </Stack>
  );
};

export const EditContactHeader = withFetch(
  x => x.practice.ref.contactDisciplines.load(),
  EditContactHeaderBase
);
