import { BodyArea } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export type KneeImageContent = {
  frontKneeImage: string | undefined;
  backKneeImage: string | undefined;
  leftSideKneeImage: string | undefined;
  rightSideKneeImage: string | undefined;
};
export type HipImageContent = {
  frontHipImage: string | undefined;
  backHipImage: string | undefined;
  leftSideHipImage: string | undefined;
  rightSideHipImage: string | undefined;
};
export type FootImageContent = {
  backFootImage: string | undefined;
  frontFootImage: string | undefined;
  bottomFootImage: string | undefined;
  topFootImage: string | undefined;
  leftInsideFootImage: string | undefined;
  rightInsideFootImage: string | undefined;
  leftOutsideFootImage: string | undefined;
  rightOutsideFootImage: string | undefined;
};
export type ShoulderImageContent = {
  leftFrontShoulderImage: string | undefined;
  leftBackShoulderImage: string | undefined;
  leftSideShoulderImage: string | undefined;
  rightFrontShoulderImage: string | undefined;
  rightBackShoulderImage: string | undefined;
  rightSideShoulderImage: string | undefined;
};
export type ElbowImageContent = {
  leftElbowImage: string | undefined;
  rightElbowImage: string | undefined;
};

export class SidesCode {
  backCode: string;
  frontCode: string;
  leftSideCode: string;
  rightSideCode: string;
  leftBackCode: string;
  leftFrontCode: string;
  rightBackCode: string;
  rightFrontCode: string;
}

export type FieldNameMap = {
  back?: string;
  front?: string;
  leftSide?: string;
  rightSide?: string;
  leftBack?: string;
  leftFront?: string;
  rightBack?: string;
  rightFront?: string;
  top?: string;
  bottom?: string;
  leftInside?: string;
  rightInside?: string;
  leftOutside?: string;
  rightOutside?: string;
  side?: string;
};

export type ImagePartProp = {
  formValue: string;
  imageType: string;
  backGroundImageType: string;
  bodyArea?: BodyArea;
};

export enum ImageType {
  frontImage = 1,
  backImage = 2,
  leftImage = 3,
  rightImage = 4
}
