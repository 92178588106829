import { FontIcon, Heading, Stack, TooltipHost } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { OrganisationCardIds } from "@modules/practice/screens/shared-components/types/organisation-card-ids.enum.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";

import { EditOrganisationFormValues } from "./EditOrganisationFormValues.tsx";

const nameOf = nameOfFactory<EditOrganisationFormValues>();

export const EditOrganisationBilling = () => {
  return (
    <Stack id={`${OrganisationCardIds.OrganisationAccount}-edit`}>
      <Heading variant="section-heading-light">Billing</Heading>

      <Stack>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <Heading labelPaddings>Draft items</Heading>{" "}
          <TooltipHost content="Subsidy services are saved to draft items after each appointment so you can create a single invoice for multiple appointments">
            <FontIcon iconName="Info" styles={{ root: { paddingTop: 6 } }} />
          </TooltipHost>
        </Stack>
        <CheckboxField name={nameOf("draftItemsEnabled")} label="Enabled" />
      </Stack>
    </Stack>
  );
};
