import React from "react";

import {
  DefaultButton,
  SelectableIcon,
  SelectionMode,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { ReportType } from "@libs/gateways/reports/ReportsGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { ReportListViewModel } from "../../context/ReportScreenHelper.tsx";
import { getReportScreenStylesSet } from "../../ReportsScreen.styles.ts";

interface ReportButtonProps {
  reportView: ReportListViewModel;
  selected: boolean;
}

export const ReportButton: React.FC<ReportButtonProps> = ({
  reportView,
  selected
}) => {
  const theme = useTheme();
  const { reportButton, reportButtonNone } = getReportScreenStylesSet(theme);

  if (reportView?.definition?.type !== ReportType.None) {
    return (
      <Navigate to={routes.reports.view.path({ id: reportView.id })}>
        <DefaultButton styles={reportButton}>
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <SelectableIcon
              checked={selected}
              selectionMode={SelectionMode.single}
            />
            <Text>{reportView.name}</Text>
          </Stack>
        </DefaultButton>
      </Navigate>
    );
  } else {
    return <Stack styles={reportButtonNone}>{reportView.name}</Stack>;
  }
};
