import { newGuid } from "@bps/utils";
import {
  FamilyAliveStatus,
  Relationship,
  SignificantConditionDataItemDto,
  SignificantFamilyHistoryDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface FamilyHistoryFormValues {
  unknownFamilyHistory?: boolean;
  noSignificantFamilyHistory?: boolean;
  generalFamilyHistoryComment?: string;
  otherComments?: string;
  motherAliveStatus?: FamilyAliveStatus;
  motherAgeAtDeath?: number;
  motherDeceasedReason?: string;
  fatherAliveStatus?: FamilyAliveStatus;
  fatherAgeAtDeath?: number;
  fatherDeceasedReason?: string;
  parentHistories?: SignificantFamilyHistoryDataItemDto[];
  additionalParentHistories?: SignificantFamilyHistoryDataItemDto[];
  additionalFamilyHistories?: SignificantFamilyHistoryDataItemDto[];
}
export const generalSignificantConditions = [
  { term: "Diabetes", code: "73211009" },
  { term: "Hypertension", code: "38341003" },
  { term: "Heart disease", code: "56265001" },
  { term: "Stroke", code: "230690007" },
  { term: "Colonic cancer", code: "269533000" },
  { term: "Depression", code: "41006004" },
  { term: "Breast cancer", code: "254837009" },
  { term: "Prostate cancer", code: "399490008" }
];

export const placeHolderParentHistoryValues: SignificantFamilyHistoryDataItemDto[] =
  [
    {
      id: newGuid(),
      relationship: Relationship.Mother
    },
    {
      id: newGuid(),
      relationship: Relationship.Father
    }
  ];

export const familyHistoryNameOf = nameOfFactory<FamilyHistoryFormValues>();
export const significantConditionNameOf =
  nameOfFactory<SignificantConditionDataItemDto>();
export const significantFamilyHistoryNameOf =
  nameOfFactory<SignificantFamilyHistoryDataItemDto>();
