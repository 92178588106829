import { observer } from "mobx-react-lite";
import { useState } from "react";

import { NoDataTile, Pivot, PivotItem, Spinner, Stack } from "@bps/fluent-ui";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionList } from "./ConditionsList.tsx";

interface ContactCardProps {
  patientId: string;
}

interface ContactCardBaseProps {
  conditions: Condition[];
}

enum ConditionKey {
  claim = "claim",
  private = "private"
}

const ConditionsCardBase: React.FC<ContactCardBaseProps> = ({ conditions }) => {
  const claimConditions = conditions.filter(x => !x.isPrivate);
  const privateConditions = conditions.filter(x => !!x.isPrivate);

  const privateOnly = !claimConditions.length && !!privateConditions.length;

  const [conditionKey, setConditionKey] = useState<string>(
    privateOnly ? ConditionKey.private : ConditionKey.claim
  );

  const getNoDataTile = (text: string) => (
    <NoDataTile
      textProps={{ text }}
      linkProps={{ hidden: true }}
      showBoxShadow={false}
      styles={{ root: { minHeight: 85 } }}
      greyView={true}
    />
  );

  if (!conditions.length) {
    return getNoDataTile("No conditions");
  }

  const claimPivotItem = (
    <PivotItem
      headerText={`ACC (${claimConditions.length})`}
      itemKey={ConditionKey.claim}
      key={ConditionKey.claim}
    >
      {!!claimConditions.length ? (
        <ConditionList conditions={claimConditions} />
      ) : (
        getNoDataTile("No ACC claims")
      )}
    </PivotItem>
  );

  const privatePivotItem = (
    <PivotItem
      headerText={`Private (${privateConditions.length})`}
      itemKey={ConditionKey.private}
      key={ConditionKey.private}
    >
      {!!privateConditions.length ? (
        <ConditionList hideClaimNumberColumn conditions={privateConditions} />
      ) : (
        getNoDataTile("No conditions")
      )}
    </PivotItem>
  );

  return (
    <Pivot
      styles={{ itemContainer: { marginTop: 8 } }}
      selectedKey={conditionKey}
      onLinkClick={(item: PivotItem) => {
        setConditionKey(item.props.itemKey!);
      }}
    >
      {claimPivotItem}
      {privatePivotItem}
    </Pivot>
  );
};

export const ConditionsCard: React.FC<ContactCardProps> = observer(
  ({ patientId }) => {
    const { acc } = useStores();
    return (
      <Stack styles={{ root: { marginTop: 16, padding: "0 10px 16px" } }}>
        <DataFetcher<Condition[]>
          refetchId={acc.ui.recentlyUpdatedClaimKey}
          fetch={async ({ clinical }) => {
            const conditions = await clinical.getPatientConditions(patientId, {
              loadCalendarEvents: true,
              loadClaims: true
            });

            return conditions;
          }}
          fallback={<Spinner />}
        >
          {conditions => <ConditionsCardBase conditions={conditions} />}
        </DataFetcher>
      </Stack>
    );
  }
);
