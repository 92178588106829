import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  Heading,
  Stack
} from "@bps/fluent-ui";
import { CorrespondencesTestElements } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  CorrespondencesContext,
  useCorrespondencesHelper
} from "../correspondence/context/CorrespondencesContext.tsx";
import { CorrespondencesHelper } from "../correspondence/context/CorrespondencesHelper.ts";
import { CorrespondencesLabels } from "../correspondence/Correspondences.types.ts";
import { InvestigationSidePanelHelper } from "../investigations/side-panel/InvestigationSidePanelHelper.ts";
import { InvestigationSidePanelContext } from "../investigations/side-panel/InvestigationsSidePanelContext.ts";
import { MoveDocumentDialog } from "../MoveDocumentDialog.tsx";
import { CorrespondencePrint } from "../patient-summary/CorrespondencePrint.tsx";
import { SidePanelDocumentPreviewer } from "../SidePanelDocumentPreviewer.tsx";
import { DeleteCorrespondenceDialog } from "../SOTAP/DeleteCorrespondenceDialog.tsx";
import { ClinicalImagesDialog } from "./ClinicalImagesDialog.tsx";
import { ClinicalImagesFilter } from "./ClinicalImagesFilter.tsx";
import { ClinicalImagesList } from "./ClinicalImagesList.tsx";

export interface ClinicalImagesProps {
  clinicalRecord: ClinicalRecord;
}

const ClinicalImagesComponent: FunctionComponent = observer(() => {
  const { clinicalRecord, isViewOnlyOrDischarged } =
    usePatientRecordScreenContext();

  const {
    setUploadDialogVisible,
    uploadDialogVisible,
    editDialogVisible,
    handleDismissDetails,
    selectedClinicalDocument,
    setDocumentViewerVisible,
    documentViewerVisible,
    isDeleteDialogVisible,
    onDeleteConfirm,
    setIsDeleteDialogVisible,
    isPrinting,
    setIsPrinting,
    moveDialogVisible,
    onMoveToAnotherPatientSucceeded,
    handleOnConflictError
  } = useCorrespondencesHelper();

  const onUploadButtonClick = () => {
    setUploadDialogVisible(true);
  };

  const onDeleteCancel = () => {
    setIsDeleteDialogVisible(false);
  };

  const showClinicalImagesDialog = uploadDialogVisible || editDialogVisible;

  return (
    <>
      <Heading
        variant="section-heading-light"
        styles={{ root: { padding: "5px 0" } }}
      >
        Clinical images
      </Heading>
      <Stack
        grow
        styles={{
          root: {
            height: 0
          }
        }}
      >
        {!isViewOnlyOrDischarged && (
          <Stack.Item>
            <ActionButton
              {...dataAttribute(
                DataAttributes.Element,
                CorrespondencesTestElements.UploadButton
              )}
              text={CorrespondencesLabels.Upload}
              iconProps={{
                iconName: "Add"
              }}
              onClick={onUploadButtonClick}
            />
          </Stack.Item>
        )}

        <ClinicalImagesFilter>
          {() => <ClinicalImagesList patientId={clinicalRecord.id} />}
        </ClinicalImagesFilter>

        {documentViewerVisible && selectedClinicalDocument && (
          <SidePanelDocumentPreviewer
            setViewerVisible={setDocumentViewerVisible}
            record={selectedClinicalDocument}
          />
        )}

        {showClinicalImagesDialog && (
          <ClinicalImagesDialog
            clinicalImage={
              editDialogVisible ? selectedClinicalDocument : undefined
            }
            onDismiss={handleDismissDetails}
            onConflictError={() => {}}
          />
        )}

        {isPrinting &&
          selectedClinicalDocument &&
          selectedClinicalDocument.id && (
            <CorrespondencePrint
              completePrinting={() => setIsPrinting(false)}
              clinicalDocument={selectedClinicalDocument}
              documentTitle={selectedClinicalDocument.name}
            />
          )}

        {selectedClinicalDocument && (
          <MoveDocumentDialog
            hidden={!moveDialogVisible}
            onDismiss={() => handleDismissDetails(true)}
            onSubmitSucceeded={onMoveToAnotherPatientSucceeded}
            handleOnConflictError={handleOnConflictError}
            clinicalRecord={clinicalRecord}
            document={selectedClinicalDocument}
          />
        )}

        <DeleteCorrespondenceDialog
          hidden={!isDeleteDialogVisible}
          onConfirm={onDeleteConfirm}
          onCancel={onDeleteCancel}
          item={selectedClinicalDocument}
        />
      </Stack>
    </>
  );
});

const ClinicalImages: FunctionComponent<ClinicalImagesProps> = props => {
  const root = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
  const correspondenceHelper = useRef(
    new CorrespondencesHelper(root, props.clinicalRecord)
  );

  const investigationHelper = useRef(
    new InvestigationSidePanelHelper(
      props.clinicalRecord,
      root,
      isViewOnlyOrDischarged
    )
  );

  return (
    <DataFetcher
      fetch={async () => {
        await props.clinicalRecord.loadPatientUserTasks();
      }}
    >
      {() => (
        <CorrespondencesContext.Provider value={correspondenceHelper.current}>
          <InvestigationSidePanelContext.Provider
            value={investigationHelper.current}
          >
            <ClinicalImagesComponent />
          </InvestigationSidePanelContext.Provider>
        </CorrespondencesContext.Provider>
      )}
    </DataFetcher>
  );
};

export const ClinicalImagesSidePanel = withFetch(
  x => [x.inbox.ref.outcomes.load()],
  ClinicalImages
);
