import { Observer, observer } from "mobx-react-lite";
import React, { FC, useState } from "react";

import {
  ConfirmDialog,
  LabeledText,
  LabeledTextGroup,
  Link,
  NoDataTile,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  FormInstanceDTO,
  FormInstanceStatus
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { FormResponseDocument } from "@modules/forms/components/FormResponseDocument.tsx";
import { getFormInstanceStatus } from "@modules/forms/components/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface FormsCardProps {
  showEditIcon: boolean;
  patient: Contact;
  onHandleEditModal: (id?: string) => void;
}

export const FormsCard: FC<FormsCardProps> = observer(({ patient }) => {
  const { forms, core } = useStores();
  const theme = useTheme();

  const hideClinical = !core.hasPermissions(Permission.ClinicalRead);
  const [viewDocumentFormInstance, setViewDocumentFormInstance] = useState<
    FormInstanceDTO | undefined
  >(undefined);

  const [cancelFormInstance, setCancelFormInstance] = useState<
    FormInstanceDTO | undefined
  >(undefined);

  const formatDate = (date?: string) => {
    return DateTime.fromISO(date)?.toDayDefaultFormat();
  };

  const canCancel = (formInstance: FormInstanceDTO) => {
    return !formInstance.responseDate;
  };

  const handleCancelForm = async () => {
    if (cancelFormInstance) {
      await forms.cancelForm(cancelFormInstance);
      setCancelFormInstance(undefined);
    }
  };

  const onDialogDismiss = () => {
    setCancelFormInstance(undefined);
  };

  const getStatusText = (
    formInstance: FormInstanceDTO,
    status: FormInstanceStatus
  ) => {
    if (status === FormInstanceStatus.Cancelled) {
      return formatDate(formInstance.changeLog?.updatedDate);
    } else if (status === FormInstanceStatus.Responded) {
      return formatDate(formInstance.responseDate);
    } else if (status === FormInstanceStatus.Expired) {
      return formatDate(formInstance.expiryDate);
    } else {
      return "Cancel";
    }
  };

  const labelGroup = (formList: FormInstanceDTO[]) => {
    const topThreeItems = formList.slice(0, 3);
    return (
      <LabeledTextGroup
        horizontalColumnCount={3}
        styles={{
          root: {
            borderWidth: 1,
            borderColor: theme.palette.neutralQuaternaryAlt,
            borderStyle: "solid",
            marginLeft: 12,
            marginRight: 12,
            padding: 8
          }
        }}
      >
        {topThreeItems.map(f => {
          const status = getFormInstanceStatus(f);
          const statusText = getStatusText(f, status);
          return (
            <React.Fragment key={f.id}>
              <LabeledText label="Form" noSemicolon>
                {f.formTemplateName}
              </LabeledText>
              <LabeledText label="Sent" noSemicolon>
                {formatDate(f.sentDate)}
              </LabeledText>
              <LabeledText label={status} noSemicolon>
                {status === FormInstanceStatus.Cancelled ||
                status === FormInstanceStatus.Expired ? (
                  <Text>{statusText}</Text>
                ) : (
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    {hideClinical && f.isClinical ? (
                      <Text>{statusText}</Text>
                    ) : (
                      <Link
                        onClick={() => {
                          if (canCancel(f)) {
                            setCancelFormInstance(f);
                          } else {
                            setViewDocumentFormInstance(f);
                          }
                        }}
                      >
                        {statusText}
                      </Link>
                    )}
                    {f.hostingURL && status === FormInstanceStatus.Pending && (
                      <>
                        <Stack>|</Stack>
                        <Link
                          onClick={() =>
                            forms.ui.setSendFormDialog({
                              name: f.formTemplateName,
                              hostingUrl: f.hostingURL,
                              context: {},
                              qrcode: true
                            })
                          }
                        >
                          QRCode
                        </Link>
                      </>
                    )}{" "}
                  </Stack>
                )}
              </LabeledText>
            </React.Fragment>
          );
        })}
        {viewDocumentFormInstance && viewDocumentFormInstance.documentId && (
          <FormResponseDocument
            formDocumentId={viewDocumentFormInstance.documentId}
            formTemplateName={viewDocumentFormInstance.formTemplateName}
            patientName={patient.name}
            patientId={patient.id}
            responseDate={viewDocumentFormInstance.responseDate}
            closeDocumentViewer={() => setViewDocumentFormInstance(undefined)}
          />
        )}
        {cancelFormInstance && (
          <ConfirmDialog
            hidden={false}
            confirmButtonProps={{ text: "Yes" }}
            cancelButtonProps={{ text: "No" }}
            onConfirm={handleCancelForm}
            onCancel={onDialogDismiss}
          >
            Are you sure you want to cancel this form?
          </ConfirmDialog>
        )}
      </LabeledTextGroup>
    );
  };

  const noData = (
    <NoDataTile
      textProps={{ text: "No forms sent" }}
      linkProps={{ hidden: true }}
      showBoxShadow={false}
      styles={{ root: { width: "100%" } }}
      greyView={true}
    />
  );

  return (
    <DataFetcher<FormInstanceDTO[]>
      fetch={() => forms.getFormInstances(patient.id, { ignoreCache: false })}
      fallback={<Spinner />}
    >
      {forms => {
        return (
          <Observer>
            {() => {
              return forms.length > 0 ? labelGroup(forms) : noData;
            }}
          </Observer>
        );
      }}
    </DataFetcher>
  );
});
