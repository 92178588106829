import { observer } from "mobx-react-lite";

import { AnimatedListWithKeys, NoDataTile, Stack } from "@bps/fluent-ui";
import { AppointmentVisitDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimAdjustmentFormLabels } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentEnums.ts";
import {
  ClaimManagementCardIds,
  ClaimSectionHeadings
} from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";

import { claimManagementFormNameOf } from "../../claim/claim.utils.ts";
import { VisitsFormFields } from "./VisitsFormFields.tsx";

const getAnimationKey = (dto: AppointmentVisitDto): string =>
  `${dto.contractType} ${dto.providerType}`;

export const VisitsFormSection: React.FC = observer(() => {
  const { selectedMenuItemId } = useCardFormLayoutContext();

  return (
    <ClaimCard
      id={ClaimManagementCardIds.visits}
      openOnRender={selectedMenuItemId === ClaimManagementCardIds.visits}
      heading={ClaimSectionHeadings.consults}
      iconName="SearchCalendar"
    >
      <FieldArray<AppointmentVisitDto>
        name={claimManagementFormNameOf("appointmentVisits")}
      >
        {({ fields }) => {
          if (!fields.value?.filter(x => x.contractType).length) {
            return (
              <NoDataTile
                textProps={{
                  text: ClaimAdjustmentFormLabels.noConsults
                }}
                showBoxShadow={false}
                linkProps={{ hidden: true }}
              />
            );
          }

          return (
            <Stack tokens={{ childrenGap: 16 }}>
              <AnimatedListWithKeys
                items={fields.value
                  .filter(x => x.contractType)
                  .map(x => ({ ...x, id: getAnimationKey(x) }))}
                onItemIdRemoved={(key: string) => {
                  const index = fields.value.findIndex(
                    v => getAnimationKey(v) === key
                  );
                  fields.remove(index);
                }}
              >
                {(key, onRemoveItem) => {
                  const index = fields.value.findIndex(
                    p => getAnimationKey(p) === key
                  );

                  if (index >= 0) {
                    return (
                      <VisitsFormFields
                        name={`${fields.name}[${index}]`}
                        index={index}
                        onRemoveItem={onRemoveItem}
                      />
                    );
                  }
                  return null;
                }}
              </AnimatedListWithKeys>
            </Stack>
          );
        }}
      </FieldArray>
    </ClaimCard>
  );
});
