import {
  isDateBeforeField,
  isNotFutureDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  interactionTimeFilterNameOf,
  InteractionTimeLineFilterState
} from "./InteractionTimeLineFilter.types.ts";

export class InteractionTimelineValidator extends Validator<InteractionTimeLineFilterState> {
  constructor() {
    super();
    this.forField("dateFrom", isNotFutureDate());
    this.forField(
      "dateTo",

      isDateBeforeField(
        ValidationMessages.endDateBeforeStartDate,
        interactionTimeFilterNameOf("dateFrom"),
        true
      )
    );
  }
}
