export enum AllocationActions {
  payInvoice = "payInvoice",
  payAccount = "payAccount",
  setAmount = "setAmount"
}

export enum AllocationFormLabels {
  receiptNumber = "Receipt number",
  invoiceNumber = "Invoice",
  date = "Date",
  location = "Location",
  status = "Status",
  billTo = "Bill to",
  Notes = "Notes",
  comments = "Comments",
  amount = "Amount",
  paymentMethod = "Payment method",
  adjustmentReason = "Adjustment reason",
  cancellationReason = "Cancellation reason",
  accountState = "Account state after payment",
  accountStateAfterCredit = "Account state after credit",
  accountStateAfterWriteOff = "Account state after write-off",
  allocationAction = "Transaction type",
  allocationActionCreditPage = "Type",
  allocationOptions = "Allocation options",
  allocationReferenceNumber = "Allocation reference number"
}

export enum AllocationFormPlaceholders {
  contact = "Search contact",
  paymentMethod = "Select payment method"
}

export enum AllocationFormButtons {
  pdf = "PDF",
  submit = "Save",
  cancel = "Cancel allocation",
  close = "Close",
  payInvoice = "Pay invoice",
  payAmount = "Pay amount",
  payAccount = "Pay account",
  saveAllocation = "Save allocation",
  sendEmail = "Send email",
  refund = "Refund allocation",
  accountCredit = "Add account credit"
}

export enum AllocationItemsColumns {
  creditNumber = "Credit",
  invoiceNumber = "Invoice",
  allocationDate = "Allocation date",
  invoiceDate = "Invoice date",
  itemNumber = "Item number",
  itemNumberPrefixed = "Item #",
  patient = "Patient",
  provider = "Provider",
  description = "Description",
  quantity = "Quantity",
  status = "Status",
  fee = "Fee",
  gst = "GST",
  owing = "Owing",
  owingPrefixed = "Owing ($)",
  totalPrefixed = "Total ($)",
  allocation = "Allocated",
  serviceDate = "Service date",
  allocatedPrefixed = "Allocated ($)",
  creditedPrefixed = "Credited ($)",
  feeIncGstPrefixed = "Fee+GST ($)",
  changeType = "Change type"
}

export enum AllocationItemsColumnsKeys {
  description = "description",
  totalFee = "itemTotal",
  total = "total",
  owing = "owing",
  credit = "credit"
}

export enum AllocationItemsTotal {
  creditPrefixed = "Credit ($)",
  unallocatedPrefixed = "Unallocated ($)",
  availablePrefixed = "Available ($)",
  allocatedPrefixed = "Allocated ($)",
  paymentPrefixed = "Payment ($)",
  totalAccountPrefixed = "Account total ($)"
}

export enum AllocationCancelAccPaymentConfirmationDialog {
  title = "Cancel ACC payment",
  message = "This is not recommended as an automatic payment has already been processed for this invoice."
}
