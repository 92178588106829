import { action, observable, runInAction } from "mobx";

import { PivotItem } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  EncounterClinicalDataDto,
  Outcome,
  RequestStatus,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AddUserAction,
  Instructions,
  NoActionUserActionContext,
  UserActionType,
  UserTaskDto,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import {
  ClinicalRecord,
  InvestigationFilter
} from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { MoveCorrespondenceFormValues } from "../../clinical-form/MoveCorrespondenceFormValues.ts";
import { InvestigationsSectionKeys } from "../../Investigations.Types.ts";
import { InvestigationType } from "./InvestigationList.tsx";

export class InvestigationSidePanelHelper {
  constructor(
    public clinicalRecord: ClinicalRecord,
    public root: RootStore,
    public isViewOnly: boolean
  ) {}
  get inbox() {
    return this.root.inbox;
  }
  get correspondence() {
    return this.root.correspondence;
  }
  get notification() {
    return this.root.notification;
  }
  get clinical() {
    return this.root.clinical;
  }

  @observable
  selectedInvestigation: ClinicalDocument | undefined;
  @action
  setSelectedInvestigation = (value: ClinicalDocument | undefined) => {
    this.selectedInvestigation = value;
  };

  @observable
  isPrinting: boolean;
  @action
  setIsPrinting = (value: boolean) => {
    this.isPrinting = value;
  };

  @observable
  isDeleteDialogVisible: boolean;
  @action
  setIsDeleteDialogVisible = (value: boolean) => {
    this.isDeleteDialogVisible = value;
  };

  @observable
  moveDialogVisible: boolean;
  @action
  setMoveDialogVisible = (value: boolean) => {
    this.moveDialogVisible = value;
  };

  @observable
  documentViewerVisible: boolean;
  @action
  setDocumentViewerVisible = (value: boolean) => {
    this.documentViewerVisible = value;
  };

  @observable
  addEditReportsDialogVisible: boolean;
  @action
  setAddEditReportsDialogVisible = (value: boolean) => {
    this.addEditReportsDialogVisible = value;
  };

  @observable
  addEditRequestDialogVisible: boolean;
  @action
  setAddEditRequestDialogVisible = (value: boolean) => {
    this.addEditRequestDialogVisible = value;
  };

  @observable
  pageSelected: InvestigationsSectionKeys = InvestigationsSectionKeys.Reports;
  @action
  setPageSelected = (value: InvestigationsSectionKeys) => {
    this.pageSelected = value;
  };

  onOutcomeClick = async (document: ClinicalDocument, outcome: string) => {
    const task = this.clinicalRecord.patientUserTasks.find(
      task => task.documentId === document.id
    );
    if (task) {
      await this.inbox.updateUserTask({
        ...task.dto,
        outcome
      });
    } else {
      const context: NoActionUserActionContext = {
        userTask: {
          instructionCode: Instructions.NoAction,
          status:
            outcome === Outcome.AttemptedContact
              ? UserTaskStatus.InProgress
              : UserTaskStatus.Completed,
          outcome
        } as UserTaskDto,
        patientKey: {
          patientId: this.clinicalRecord.id,
          documentId: document.id
        }
      };

      const userAction: AddUserAction = {
        userActionType: UserActionType.NoAction,
        context
      };

      await this.inbox.addUserAction(userAction);
    }

    await this.clinicalRecord.loadPatientUserTasks();
  };

  onMoveTo = async (item: ClinicalDocument, storeType: StoreType) => {
    if (this.clinicalRecord.openEncounter?.id) {
      const document = { ...item.dto, store: storeType };

      await this.correspondence.updateCorrespondence(
        this.clinicalRecord.openEncounter.id,
        document.id,
        document
      );

      runInAction(() => {
        this.correspondence.investigationListRefreshKey = document.id;
      });
    } else {
      this.notification.error(
        "Failed to move document to correspondence - no active encounter."
      );
    }
  };

  onMoveToAnotherPatient = (item: ClinicalDocument) => {
    this.setSelectedInvestigation(item);
    this.setMoveDialogVisible(true);
  };

  onMoveToAnotherPatientSucceeded = (values: MoveCorrespondenceFormValues) => {
    runInAction(() => {
      this.correspondence.investigationListRefreshKey = values.sourceFileId;
    });
  };

  onPrintClick = async (item: ClinicalDocument) => {
    this.setSelectedInvestigation(item);
    this.setIsPrinting(true);
  };

  onOpenInvestigationClick = async (item: ClinicalDocument) => {
    const corr = await this.correspondence.getInvestigationByDocumentId(
      this.clinicalRecord.id,
      item.id
    );

    this.setSelectedInvestigation(corr);
    this.setDocumentViewerVisible(true);
  };

  onCreateReportClick = () => {
    this.setSelectedInvestigation(undefined);
    if (!this.isViewOnly) this.setAddEditReportsDialogVisible(true);
  };

  onCreateRequestClick = () => {
    if (!this.isViewOnly) this.setAddEditRequestDialogVisible(true);
  };

  handleDismissDetails = (clearSelected: boolean) => {
    this.setAddEditReportsDialogVisible(false);
    this.setMoveDialogVisible(false);
    if (clearSelected) {
      this.setSelectedInvestigation(undefined);
    }
  };

  handleDismissAdEditRequestDialog = async () => {
    this.setAddEditRequestDialogVisible(false);
  };

  handleOnConflictError = async (confirmed: boolean) => {
    if (confirmed) {
      await this.handleDismissDetails(true);
      runInAction(() => {
        this.correspondence.investigationListRefreshKey = newGuid();
      });
    }
  };

  handleViewClick = (item: PivotItem): void => {
    const selectedValue =
      item.props &&
      (item.props.itemKey as InvestigationsSectionKeys | undefined);

    if (selectedValue === this.pageSelected || selectedValue === undefined) {
      return;
    }
    this.setPageSelected(selectedValue);
  };

  confimDeleteReport = async (
    reasonForDelete: string,
    reasonForDeleteComment: string
  ) => {
    if (this.selectedInvestigation) {
      const encounterId = this.clinicalRecord.openEncounter!.id;
      const documentId = this.selectedInvestigation.id;

      await this.correspondence.deleteDocument(encounterId, documentId, {
        patientId: this.clinicalRecord.id,
        eTag: this.selectedInvestigation.eTag,
        reasonForDelete,
        reasonForDeleteComment
      });
    } else {
      throw new Error("No report selected.");
    }
    this.setIsDeleteDialogVisible(false);
  };

  deleteReport = (item: ClinicalDocument) => {
    this.setIsDeleteDialogVisible(true);
    this.setSelectedInvestigation(item);
  };

  onMarkAsReturnedClick = async (item: ClinicalDocument) => {
    const clinicalRecord = this.clinicalRecord;
    const imagingRequests = clinicalRecord.imagingRequests;
    const imagingRequestIndex = imagingRequests.findIndex(
      x => x.documentId === item.id
    );
    if (imagingRequestIndex > -1) {
      const updatedImagingRequests = [...imagingRequests];
      updatedImagingRequests[imagingRequestIndex] = {
        ...updatedImagingRequests[imagingRequestIndex],
        status: RequestStatus.Returned
      };

      const encounterClinicalData: EncounterClinicalDataDto = {
        imagingRequests: {
          eTag: this.clinicalRecord.clinicalData?.imagingRequests?.eTag,
          imagingRequests: updatedImagingRequests
        }
      };

      await this.clinicalRecord.saveClinicalData(encounterClinicalData);
    }
  };

  onCancelRequestClick = async (item: ClinicalDocument) => {
    const imagingRequests = this.clinicalRecord.imagingRequests;
    const imagingRequestIndex = imagingRequests.findIndex(
      x => x.documentId === item.id
    );
    if (imagingRequestIndex > -1) {
      const updatedImagingRequests = [...imagingRequests];
      updatedImagingRequests[imagingRequestIndex] = {
        ...updatedImagingRequests[imagingRequestIndex],
        status: RequestStatus.Cancelled
      };

      const encounterClinicalData: EncounterClinicalDataDto = {
        imagingRequests: {
          eTag: this.clinicalRecord.clinicalData?.imagingRequests?.eTag,
          imagingRequests: updatedImagingRequests
        }
      };

      await this.clinicalRecord.saveClinicalData(encounterClinicalData);
    }
  };

  updateDocumentSecGroupId = async (item: ClinicalDocument) => {
    if (this.clinicalRecord.openEncounter?.id) {
      const document = {
        ...item.dto,
        store: StoreType.Investigations,
        secGroupId: !item.secGroupId
          ? this.root.core.user?.privateSecGroupId
          : undefined
      };

      await this.correspondence.updateCorrespondence(
        this.clinicalRecord.openEncounter.id,
        document.id,
        document
      );
    }
  };

  onEditConfidential = async (item: ClinicalDocument) => {
    const imagingRequests = this.clinicalRecord.imagingRequests;
    const imagingRequestIndex = imagingRequests.findIndex(
      x => x.documentId === item.id
    );

    if (imagingRequestIndex > -1) {
      const updatedImagingRequests = [...imagingRequests];
      const imageRequestSecGroupId =
        updatedImagingRequests[imagingRequestIndex].secGroupId;

      updatedImagingRequests[imagingRequestIndex] = {
        ...updatedImagingRequests[imagingRequestIndex],
        secGroupId: !imageRequestSecGroupId
          ? this.root.core.user?.privateSecGroupId
          : undefined
      };

      const encounterClinicalData: EncounterClinicalDataDto = {
        imagingRequests: {
          eTag: this.clinicalRecord.clinicalData?.imagingRequests?.eTag,
          imagingRequests: updatedImagingRequests
        }
      };

      await this.clinicalRecord.saveClinicalData(encounterClinicalData);
    }
  };

  handleOtherConfidentialDocOnInvType = (
    investigationResults: ClinicalDocument[],
    investigationType: InvestigationType,
    filter: InvestigationFilter
  ) => {
    const isFilterOutOnReport =
      investigationType === InvestigationType.Report &&
      !filter.showOtherConfidentialReport;

    const isFilterOutOnRequest =
      investigationType === InvestigationType.Request &&
      !filter.showOtherConfidentialRequest;

    //filter out needed data on confidential button toggle
    if (isFilterOutOnReport || isFilterOutOnRequest) {
      return investigationResults.filter(
        x => !!this.root.core.hasAccessToSecGroup(x.secGroupId)
      );
    }
    return investigationResults;
  };
}
