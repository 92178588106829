import { fabric } from "fabric";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import { Overlay, Stack, useResizeElementObserver } from "@bps/fluent-ui";
import { Hotkey } from "@libs/utils/Hotkey.ts";

import { BLACK, WHITE } from "./constants.ts";
import "./drawing.css";
import { getDrawingCanvasStyles } from "./DrawingCanvas.styles.ts";
import {
  BrushKind,
  BrushKindEnum,
  DrawingCanvasProps,
  Options
} from "./DrawingCanvas.types.ts";
import { DrawingCanvasHistory } from "./DrawingCanvasHistory.ts";
import { DrawingThumbnailList } from "./DrawingThumbnailList.tsx";
import { DrawingToolbar } from "./DrawingToolbar.tsx";
import { FabricDrawing } from "./fabric-drawing/FabricDrawing.tsx";
import { BackgroundDisplayOption } from "./fabric-drawing/FabricDrawing.types.ts";
import { QuickColours } from "./QuickColours.tsx";

/**
 * Drawing canvas is a basic drawing component against a canvas.
 * It uses fabricjs through the Fabric.tsx component.
 *
 * See Fabric component for a description of the props.
 */

const styles = getDrawingCanvasStyles();

export const DrawingCanvas: FunctionComponent<DrawingCanvasProps> = observer(
  ({
    embedImages = true,
    onChange,
    images,
    caption,
    initialSelectedImage,
    showQuickColourPicker,
    onCanvasSelected,
    isViewOnly
  }) => {
    const { current: history } = useRef(new DrawingCanvasHistory());
    const isInitializing = useRef<boolean>(false);

    useEffect(() => {
      isInitializing.current = true;
      images.forEach(i => {
        if (i.id) {
          if (i.initialValue) {
            fabric.loadSVGFromString(i.initialValue, res => {
              const skipInitial = res.length - 1;
              i.id && history.initialize(i.id, skipInitial);
            });
          } else {
            i.id && history.initialize(i.id, 0);
          }
        }
      });
      setTimeout(() => {
        isInitializing.current = false;
      }, 500);
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canvases = useRef<fabric.Canvas[]>([]);
    const setCurrentCanvas = useRef<boolean>();
    const [currentCanvas, switchCanvas] = useState<fabric.Canvas | undefined>(
      undefined
    );

    const [isDrawingMode, setIsDrawingMode] = useState<boolean>(true);
    const [activeObjects, setActiveObjects] = useState<fabric.Object[] | null>(
      null
    );

    const [options, setOptions] = useState<Options>({
      kind: BrushKindEnum.Pencil,
      width: 2,
      color: BLACK,
      fill: WHITE
    });

    const handleBrushThicknessChange = (width: number) => {
      setOptions(prevState => ({
        ...prevState,
        width
      }));
    };

    const handleBrushColorChange = (color: string) => {
      setOptions(prevState => ({
        ...prevState,
        color
      }));
    };

    const handleBrushFillColorChange = (fill: string) => {
      setOptions(prevState => ({
        ...prevState,
        fill
      }));
    };

    const setCanvas = (
      canvas: fabric.Canvas,
      canvasRef: (canvas: fabric.Canvas) => void
    ) => {
      if (!currentCanvas && !setCurrentCanvas.current) {
        setCurrentCanvas.current = true;
        switchCanvas(canvas);
      }
      if (canvasRef) {
        canvasRef(canvas);
      }

      if (
        canvases.current.findIndex(
          c => c.getElement().id === canvas.getElement().id
        ) === -1
      ) {
        canvases.current.push(canvas);
      }
    };

    const handleBrushClicked = (brushKind: BrushKind) => {
      if (!currentCanvas || (isDrawingMode && options.kind === brushKind)) {
        return;
      }

      const newOptions: Options = {
        kind: brushKind,
        width: brushKind === "transparent" ? 20 : 2,
        color: options.color,
        fill: options.fill
      };

      setOptions(newOptions);
      setIsDrawingMode(true);
    };

    const handleCursorClicked = () => {
      if (isDrawingMode) {
        setIsDrawingMode(false);
      }
    };

    const handleRemoveClicked = () => {
      if (!currentCanvas || !activeObjects) {
        return;
      }

      currentCanvas.remove(...currentCanvas.getActiveObjects());

      setActiveObjects(null);
    };

    const handleChange = (canvas: fabric.Canvas, event?: fabric.IEvent) => {
      if (!event || isViewOnly || isInitializing.current) return;
      history.add(canvas.getElement().id, canvas);
      onChange(canvas, event);
    };

    const handleSelectionChange = (
      newActiveObjects: fabric.Object[] | null
    ) => {
      if (newActiveObjects) {
        setActiveObjects(newActiveObjects);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLCanvasElement>) => {
      if (currentCanvas) {
        const isUndoHotkey = Hotkey.getHotkeyChecker("mod+z");
        const isRedoHotkey = Hotkey.getHotkeyChecker("mod+y");

        if (isUndoHotkey(event.nativeEvent)) {
          history.undo(currentCanvas);
        }

        if (isRedoHotkey(event.nativeEvent)) {
          history.redo(currentCanvas);
        }
      }
    };

    const clear = () => {
      const canvasObjects = currentCanvas?.getObjects();
      if (canvasObjects) {
        currentCanvas?.remove(...canvasObjects);
      }
    };

    const selectedThumbnailChanged = (canvasIndex: number) => {
      switchCanvas(canvases.current[canvasIndex]);
      if (onCanvasSelected) {
        onCanvasSelected(canvasIndex);
      }
    };

    const onQuickColourSelected = (colour: string) => {
      handleBrushColorChange(colour);
      handleBrushFillColorChange(colour);
    };

    const displayThisCanvas = (index: number) => {
      if (
        currentCanvas &&
        currentCanvas.getElement() &&
        canvases.current[index]
      ) {
        const thisCanvasId = canvases.current[index].getElement().id;
        const currentCanvasId = currentCanvas.getElement().id;
        return thisCanvasId === currentCanvasId;
      }
      return false;
    };

    const { setElement, resizeObserverEntry, element } =
      useResizeElementObserver();

    const canvasWidth = resizeObserverEntry
      ? resizeObserverEntry.borderBoxSize[0].inlineSize
      : 0;

    return (
      <div
        ref={r => {
          if (r && !element) {
            setElement(r);
          }
        }}
      >
        <Stack styles={styles.wrapper}>
          {!isViewOnly && (
            <DrawingToolbar
              caption={caption}
              onUndoClicked={() => currentCanvas && history.undo(currentCanvas)}
              onRedoClicked={() => currentCanvas && history.redo(currentCanvas)}
              onDeleteClicked={handleRemoveClicked}
              onClearClicked={clear}
              onThicknessChanged={handleBrushThicknessChange}
              onBrushColorChanged={handleBrushColorChange}
              onFillColorChanged={handleBrushFillColorChange}
              onCursorClicked={handleCursorClicked}
              deleteEnabled={!!activeObjects && activeObjects?.length > 0}
              fillColour={options.fill}
              brushColour={options.color}
              strokeWidth={options.width}
              onBrushChange={handleBrushClicked}
            />
          )}
          <Stack
            styles={{
              root: {
                flexDirection:
                  canvasWidth && canvasWidth < 600 ? "column-reverse" : "row"
              }
            }}
          >
            <Stack.Item styles={styles.editorWrapper}>
              {images.map((image, index) => {
                return (
                  <div
                    key={image.id}
                    style={{
                      display: displayThisCanvas(index) ? "block" : "none",
                      position: "relative"
                    }}
                  >
                    <FabricDrawing
                      id={image.id}
                      options={options}
                      embedImages={embedImages}
                      initialValue={image.initialValue}
                      onChange={handleChange}
                      canvasRef={value => setCanvas(value, image.canvasRef)}
                      isDrawingMode={isDrawingMode}
                      backgroundImageUrl={image.backgroundImageUrl}
                      onSelectionChange={handleSelectionChange}
                      width={image.width}
                      height={image.height}
                      onKeyDown={handleKeyDown}
                      handleCanvasFocused={switchCanvas}
                      backgroundDisplayOption={BackgroundDisplayOption.Center}
                    />

                    {isViewOnly && (
                      <Overlay styles={{ root: { backgroundColor: "none" } }} />
                    )}
                  </div>
                );
              })}
            </Stack.Item>
            <Stack.Item styles={{ root: { width: "100%" } }}>
              <DrawingThumbnailList
                images={images}
                imageSelected={selectedThumbnailChanged}
                initialSelectedImage={initialSelectedImage ?? 0}
              />
            </Stack.Item>
          </Stack>
          {showQuickColourPicker && (
            <QuickColours onColourSelected={onQuickColourSelected} />
          )}
        </Stack>
      </div>
    );
  }
);
