import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { isImage, isPdf } from "@libs/utils/file.utils.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DocumentPrint } from "@ui-components/printing/DocumentPrint.tsx";

interface CorrespondencePrintProps {
  clinicalDocument: ClinicalDocument;
  completePrinting?: () => void;
  documentTitle: string;
}

export const CorrespondencePrint: React.FC<CorrespondencePrintProps> = ({
  clinicalDocument,
  completePrinting,
  documentTitle
}) => {
  const { notification, inbox, correspondence } = useStores();

  const getDocumentViewData = async () => {
    notification.warn("Preparing to print");
    if (
      !(
        isImage(clinicalDocument.extension) || isPdf(clinicalDocument.extension)
      )
    ) {
      return await inbox.createPdf(
        {
          entityType: DocumentEntityType.Patient,
          entityId: clinicalDocument.patientId,
          documentId: clinicalDocument.id
        },
        clinicalDocument.extension
      );
    } else {
      const document = await correspondence.getDocumentUrl({
        documentId: clinicalDocument.id,
        patientId: clinicalDocument.patientId,
        excludeContentDisposition: true
      });
      return document.url;
    }
  };

  return (
    <DocumentPrint
      completePrinting={completePrinting}
      documentTitle={documentTitle}
      getDocumentData={() => getDocumentViewData()}
      isImage={isImage(clinicalDocument.extension)}
    />
  );
};
