import { useContext } from "react";

import { clone, DateTime, TIME_FORMATS } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldsSpy } from "@ui-components/form/FieldsSpy.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UnavailableFormValues } from "./UnavailableForm.types.ts";
import { UnavailableFormContext } from "./UnavailableFormContext.ts";

export const CreateTemporaryReservation: React.FunctionComponent = () => {
  const { core } = useStores();

  const { asyncCreateTemporaryReservation } = useContext(
    UnavailableFormContext
  );

  // Convert to the browser date time
  const convertBrowserDateTime = (
    values: UnavailableFormValues
  ): UnavailableFormValues => {
    // Is it the same time zone?
    if (core.practiceAndUserHaveSameTimezone) return values;

    // Have all date times been specified?
    if (
      !values.startDate ||
      !values.startTime ||
      !values.endDate ||
      !values.endTime
    )
      return values;

    const browserStartTime = DateTime.fromJSDateAndTime(
      values.startDate,
      values.startTime
    );

    const browserEndTime = DateTime.fromJSDateAndTime(
      values.endDate,
      values.endTime
    );

    // Deep copy not to change the selected location date time
    const copiedValues = clone(values);

    copiedValues.startDate = browserStartTime.toJSDate();
    copiedValues.startTime = browserStartTime.toFormat(
      TIME_FORMATS.TIME_FORMAT_24
    );

    copiedValues.endDate = browserEndTime.toJSDate();
    copiedValues.endTime = browserEndTime.toFormat(TIME_FORMATS.TIME_FORMAT_24);

    return copiedValues;
  };

  const namesArray: (keyof UnavailableFormValues)[] = [
    "providerId",
    "startDate",
    "endDate",
    "startTime",
    "endTime"
  ];

  return (
    <When permission={Permission.PreRelease}>
      <FieldsSpy<UnavailableFormValues>
        onChange={(_field, value, values: UnavailableFormValues) =>
          asyncCreateTemporaryReservation(convertBrowserDateTime(values)!)
        }
        fieldNames={namesArray}
      />
    </When>
  );
};
