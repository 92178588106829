import { observer } from "mobx-react-lite";

import {
  FontIcon,
  mergeStyles,
  PillButton,
  Spinner,
  SpinnerSize,
  TooltipHost
} from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface DocumentPillComponentBaseProps {
  loading?: boolean;
  active?: boolean;
  patient?: Contact;
  documentType?: CorrespondenceType;
  handleClick?: () => void;
  handleClose?: (e: React.MouseEvent<HTMLElement>) => void;
  title?: string;
}

export const DocumentPillComponentBase: React.FunctionComponent<DocumentPillComponentBaseProps> =
  observer(
    ({
      loading,
      active,
      patient,
      documentType,
      handleClick,
      handleClose,
      title
    }) => {
      const { correspondence } = useStores();

      const getDocumentText = (patient?: Contact) => {
        if (title) {
          if (patient) {
            return `${title} - ${patient.firstName} ${patient.lastName}`;
          } else {
            return `${title} - Template`;
          }
        }

        if (documentType) {
          const docType =
            correspondence.ref.correspondenceTypes.get(documentType);

          if (!active) return docType?.altText;
          if (patient) {
            return `${documentType} - ${patient.firstName} ${patient.lastName}`;
          } else {
            return `${docType?.altText} - Template`;
          }
        }
        return "";
      };

      return (
        <TooltipHost
          content={documentType ? getDocumentText(patient) : undefined}
        >
          <PillButton
            active={active}
            onClick={handleClick}
            styles={{
              root: {
                maxWidth: 200,
                height: 32,
                padding: "3px 5px 3px 8px"
              }
            }}
            onClose={handleClose}
          >
            <FontIcon iconName="TextDocument" />
            <span
              className={mergeStyles({
                whiteSpace: "nowrap",
                overflow: "hidden",
                paddingLeft: 8,
                paddingRight: 8
              })}
            >
              {loading && <Spinner size={SpinnerSize.small} />}
              {getDocumentText(patient)}
            </span>
          </PillButton>
        </TooltipHost>
      );
    }
  );
