import { useEffect } from "react";

export const APPT_FILTER_OVERLAY_ID = "appt-filter-overlay";
const BOOKING_CALENDAR_CLASS = ".rbc-time-content";
const SCROLL_STEP = 20;

/**
 * This hook listens to Overlay wheel event and passes a scroll event to BookingCalendar
 */
export const useAppointmentFilterOverlayScroll = () => {
  const wheelEventHandler = (calendarElement: Element) => (e: WheelEvent) => {
    const currentScrollPosition = calendarElement.scrollTop;
    e.preventDefault();
    e.stopPropagation();

    const isWheelUp = e.deltaY > 0;

    if (isWheelUp) {
      const reachedBottom =
        calendarElement.scrollHeight <=
        calendarElement.scrollTop + calendarElement.clientHeight;

      if (!reachedBottom) {
        calendarElement.scrollTop = currentScrollPosition + SCROLL_STEP;
      }
    } else {
      const reachedTop = calendarElement.scrollTop === 0;
      if (!reachedTop) {
        calendarElement.scrollTop = currentScrollPosition - SCROLL_STEP;
      }
    }
  };

  useEffect(() => {
    const calendarElement = document.querySelector(BOOKING_CALENDAR_CLASS);
    let overlayElement: Element | null;
    setTimeout(() => {
      overlayElement = document.getElementById(APPT_FILTER_OVERLAY_ID);

      if (overlayElement && calendarElement) {
        overlayElement.addEventListener(
          "wheel",
          wheelEventHandler(calendarElement)
        );
      }
    }, 1);
    //  removeEventListener on component unmount
    return () => {
      if (calendarElement && overlayElement) {
        overlayElement.removeEventListener(
          "wheel",
          wheelEventHandler(calendarElement)
        );
      }
    };
  }, []);
};
