import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  DetailsList,
  IColumn,
  MoreLessToggle,
  RESET_CELLS_PADDING_CLASSNAME,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ClaimDocumentViewerDialog } from "@modules/acc/screens/claims/components/ClaimDocumentViewerDialog.tsx";
import {
  ClaimNumberCell,
  EM_DASH_SYMBOL
} from "@modules/acc/screens/claims/components/ClaimNumberCell.tsx";
import { ClaimsContextualMenu } from "@modules/acc/screens/claims/components/ClaimsContextualMenu.tsx";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionsContextualMenu } from "./ConditionsContextualMenu.tsx";
import { renderDiagnosisCell, sortConditions } from "./utils.tsx";

interface ConditionListProps {
  conditions: Condition[];
  hideClaimNumberColumn?: boolean;
}

export const ConditionList: React.FC<ConditionListProps> = observer(
  ({ conditions, hideClaimNumberColumn }) => {
    const longDashSymbol = String.fromCodePoint(EM_DASH_SYMBOL);
    const sortedCondtions = sortConditions(conditions);
    const { booking } = useStores();

    const [results, setResults] = useState<Condition[]>(
      sortedCondtions.slice(0, 3)
    );

    const theme = useTheme();

    const showMore = results.length < sortedCondtions.length;

    const moreStateText = `Show more (${
      sortedCondtions.length - results.length < 3
        ? sortedCondtions.length - results.length
        : 3
    })`;

    const getMore = async () => {
      setResults(prev => [
        ...prev,
        ...sortedCondtions.slice(prev.length, prev.length + 3)
      ]);
    };

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        <DetailsList
          items={results}
          columns={[
            {
              name: "",
              className: RESET_CELLS_PADDING_CLASSNAME,
              key: "action",
              minWidth: 20,
              maxWidth: 20,
              onRender: (item: Condition) =>
                item.claim ? (
                  <ClaimsContextualMenu
                    claim={item.claim}
                    displayConditionSetup
                    hideChangeToPrivate
                    newApptMenuItem={{
                      key: "newLinked",
                      text: "New linked appointment",
                      onClick: () => {
                        booking.ui.showCalendarEventDialog({
                          type: CalendarEventType.Appointment,
                          initialValues: {
                            patientId: item.patientId,
                            episodeOfCareId: item.id
                          }
                        });
                      }
                    }}
                  />
                ) : (
                  <ConditionsContextualMenu condition={item} />
                )
            },
            {
              name: "Claim",
              key: "claimNumber",
              minWidth: 80,
              maxWidth: 80,
              onRender: (item: Condition) =>
                item.claim ? (
                  <ClaimNumberCell hideReferalToolTip claim={item.claim!} />
                ) : (
                  longDashSymbol
                )
            },
            {
              name: "Diagnosis",
              key: "injuryDiagnosis",
              minWidth: 120,
              maxWidth: 120,
              onRender: (item: Condition) => renderDiagnosisCell(item, theme)
            },
            {
              name: "Next appt",
              key: "appt",
              minWidth: 80,
              maxWidth: 80,
              onRender: (item: Condition) => {
                if (item.nextCalendarEventDate) {
                  return item.nextCalendarEventDate.toDayDefaultFormat();
                } else {
                  return longDashSymbol;
                }
              }
            },
            {
              name: "Provider",
              key: "provider",
              minWidth: 120,
              maxWidth: 120,
              onRender: (item: Condition) => (
                <DataFetcher<string | undefined>
                  fetch={item.getNextCalendarEventProviderName}
                >
                  {providerName => (
                    <Text>{providerName || longDashSymbol}</Text>
                  )}
                </DataFetcher>
              )
            }
          ].filter(
            (col: IColumn) =>
              !(col.key === "claimNumber" && hideClaimNumberColumn)
          )}
        />
        {showMore && (
          <MoreLessToggle
            linkProps={{ moreStateText }}
            onChanged={getMore}
            value={true}
          />
        )}
        <ClaimDocumentViewerDialog />
      </Stack>
    );
  }
);
