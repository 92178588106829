import { memo, PropsWithChildren, ReactNode } from "react";

import {
  FontIcon,
  FontSizes,
  Heading,
  mergeStyleSets,
  Stack,
  Tile,
  TileStyles,
  useTheme
} from "@bps/fluent-ui";

type ScreenLayoutProps = PropsWithChildren<{
  label?: string;
  iconName?: string;
  tileStyles?: Partial<TileStyles>;
  onRenderHeader?: () => ReactNode;
}>;

export const ScreenLayout: React.FC<ScreenLayoutProps> = memo(
  ({ children, label, iconName, tileStyles, onRenderHeader }) => {
    const theme = useTheme();
    return (
      <Tile
        styles={mergeStyleSets(
          {
            root: {
              width: "100%",
              overflowY: "hidden",
              padding: "16px"
            }
          },
          tileStyles
        )}
      >
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          {onRenderHeader ? (
            onRenderHeader()
          ) : (
            <>
              {iconName && (
                <FontIcon
                  iconName={iconName}
                  styles={{
                    root: {
                      color: theme.palette.themePrimary,
                      fontSize: FontSizes.size24
                    }
                  }}
                />
              )}
              <Heading variant="section-heading">{label}</Heading>
            </>
          )}
        </Stack>
        {children}
      </Tile>
    );
  }
);
