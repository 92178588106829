import { FunctionComponent, useContext } from "react";

import { Text } from "@bps/fluent-ui";
import { TIME_FORMATS } from "@bps/utils";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

export const TimeRange: FunctionComponent = () => {
  const { calendarEvent } = useContext(BookingCalendarEventContext);
  return (
    <Text block variant="smallPlus" styles={{ root: { marginLeft: 4 } }}>
      {`${calendarEvent.startDateTime.toFormat(
        TIME_FORMATS.DEFAULT_TIME_FORMAT
      )} - ${calendarEvent.endDateTime.toFormat(
        TIME_FORMATS.DEFAULT_TIME_FORMAT
      )}`}
    </Text>
  );
};
