import { useField, useForm } from "react-final-form";

import { FontSizes, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { When } from "@ui-components/withPerm.tsx";

import { TodaysNotesConfidentialToolTipIcon } from "../../clinical-form/TodaysNotesConfidentialToolTipIcon.tsx";

interface ConfidentialShieldProps {
  name: string;
}

export const ConfidentialShield: React.FunctionComponent<
  ConfidentialShieldProps
> = ({ name }) => {
  const theme = useTheme();

  const form = useForm();

  const {
    input: { value }
  } = useField(name);

  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  return (
    <When permission={Permission.ConfidentialDataAllowed}>
      {!isViewOnlyOrDischarged && (
        <TodaysNotesConfidentialToolTipIcon
          isConfidential={value}
          updateConfidential={() => {
            form.change(name, !value);
          }}
          styles={{
            root: {
              backgroundColor: value
                ? theme.palette.neutralLight
                : "transparent",
              fontSize: FontSizes.size12
            }
          }}
        />
      )}
    </When>
  );
};
