import {
  CalendarEventType,
  Frequency
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  areTimesEqualField,
  greaterThan,
  integer,
  isBeforeTimeField,
  isDateBeforeField,
  Length,
  lessThan,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";

import { RecurrenceFormValues } from "./RecurrenceForm.types.tsx";

const isStartAndEndTimeNonEmpty = (val: string, values: RecurrenceFormValues) =>
  !!(values.endTime && values.startTime);

export class RecurrenceFormValidator extends Validator<RecurrenceFormValues> {
  constructor() {
    super();

    this.forField(nameof("endScheduleType"), [required()]);
    this.forField(nameof("patientId"), [
      predicate(
        (val: string, schedule: RecurrenceFormValues) =>
          schedule.calendarEventType !== CalendarEventType.Unavailable,
        required()
      )
    ]);

    this.forField(nameof("calendarEventType"), [required()]);
    this.forField(nameof("purpose"), maxLength(Length.long));
    this.forField(nameof("providerId"), [required()]);

    this.forField(nameof("endTime"), [
      required(),
      predicate(
        isStartAndEndTimeNonEmpty,
        isBeforeTimeField(
          "End time must not be before start time.",
          nameof("startTime")
        ),
        areTimesEqualField(
          "End time must not be equal to start time.",
          nameof("startTime")
        )
      )
    ]);

    this.forField(nameof("interval"), [
      predicate(x => x !== 0, required()), // treat zero as value entered
      integer(),
      greaterThan(0),
      lessThan(100)
    ]);

    this.forField(nameof("count"), [
      predicate(
        (val, schedule: RecurrenceFormValues) =>
          schedule.endScheduleType === EndScheduleType.After &&
          schedule.count !== 0,
        required()
      ),
      integer(),
      greaterThan(0),
      lessThan(100)
    ]);

    this.forField(nameof("startDate"), [required()]);
    this.forField("until", [
      predicate(
        (val, schedule: RecurrenceFormValues) =>
          schedule.endScheduleType === EndScheduleType.OnDate,
        required()
      )
    ]);
    this.forField(nameof("until"), [
      predicate(
        (val, schedule: RecurrenceFormValues) =>
          schedule.endScheduleType === EndScheduleType.OnDate,
        isDateBeforeField(
          ValidationMessages.endDateBeforeStartDate,
          nameof("startDate")
        )
      )
    ]);
    this.forField(nameof("startTime"), [required()]);
    this.forField(nameof("endTime"), [required()]);

    this.forField(nameof("endTime"), [
      isBeforeTimeField(
        "End time must not be before start time.",
        nameof("startTime"),
        true
      )
    ]);

    this.forField("endTime", [
      predicate(
        (val: string, schedule: RecurrenceFormValues) =>
          schedule.endTime && schedule.startTime
            ? schedule.endTime === schedule.startTime
            : false,
        () => "End time must not be equal to start time."
      )
    ]);

    this.forField(nameof("frequency"), required());
    this.forField(nameof("dayRecur"), [
      predicate(
        (val, schedule: RecurrenceFormValues) =>
          schedule.frequency === Frequency.Week,
        required(ValidationMessages.dayRecurrences)
      )
    ]);
    this.forField("monthYearRecurrence", [
      predicate(
        (val, schedule: RecurrenceFormValues) =>
          schedule.frequency === Frequency.Month,
        required(ValidationMessages.monthRecurrences)
      )
    ]);
    this.forField("monthYearRecurrence", [
      predicate(
        (val, schedule: RecurrenceFormValues) =>
          schedule.frequency === Frequency.Year,
        required(ValidationMessages.yearRecurrences)
      )
    ]);
  }
}
