import React from "react";
import { useForm } from "react-final-form";

import {
  AnimatedListWithKeys,
  DefaultButton,
  Heading,
  NoDataTile,
  Stack
} from "@bps/fluent-ui";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { EditAccountBillToRow } from "./EditAccountBillToRow.tsx";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";
import {
  RelationshipFieldValue,
  relationshipFromContactId
} from "./relationships/utils.ts";

export const EditAccountBillTo: React.FC = () => {
  const form = useForm<PatientEditFormValues>();
  const { fields } = useFieldArray<RelationshipFieldValue>(
    patientFormNameOf("accountHolders")
  );

  const addAccountHolder = (relatedContactId?: string) => {
    if (relatedContactId) {
      const emptyIndex = fields.value.findIndex(x => !x.relatedContactId);
      if (emptyIndex >= 0) {
        const field = `accountHolders[${emptyIndex}].relatedContactId`;
        form.change(field as keyof PatientEditFormValues, relatedContactId);
        return;
      }
    }

    const newAccount = relationshipFromContactId({
      contactId: relatedContactId,
      type: RelationshipType.AccountHolder,
      metadata: {
        isPrimary: fields.length === 0,
        relationshipTypeCode: RelationshipType.AccountHolder
      }
    });
    fields.push(newAccount);
  };

  const primaryFieldChanged = (primaryChangedIndex: number) => {
    const isPrimaryToggledOn =
      fields.value[primaryChangedIndex].metadata?.isPrimary;

    if (isPrimaryToggledOn) {
      //check for a previously selected primary toggle and untoggle it.
      const previousPrimaryFieldIndex = fields.value.findIndex(
        (field, fieldIndex) =>
          field.metadata?.isPrimary && fieldIndex !== primaryChangedIndex
      );
      if (previousPrimaryFieldIndex > -1) {
        form.change(
          `accountHolders[${previousPrimaryFieldIndex}].metadata.isPrimary` as keyof PatientEditFormValues,
          false
        );
      }
    }
  };

  const findFieldsIndex = (key: string) =>
    fields.value.findIndex(v => v.id === key);

  return (
    <div
      id={`${PatientCardIds.patientAccount}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { lineHeight: "22px", marginBottom: 6 } }}
      >
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          {Labels.account}
        </Heading>
        <DefaultButton
          iconProps={{ iconName: "Add" }}
          text="Add more"
          onClick={() => addAccountHolder()}
        />
      </Stack>
      <AnimatedListWithKeys
        items={fields.value}
        onItemIdRemoved={id => {
          const index = findFieldsIndex(id);
          fields.remove(index);
        }}
      >
        {(id, onRemoveItem) => {
          const index = findFieldsIndex(id);
          return (
            <EditAccountBillToRow
              accountHolders={fields.value}
              addAccountHolder={addAccountHolder}
              removeAccountHolder={onRemoveItem}
              primaryFieldChanged={primaryFieldChanged}
              index={index}
            />
          );
        }}
      </AnimatedListWithKeys>

      {!fields.length || fields.length === 0 ? (
        <NoDataTile
          textProps={{ text: "Invoiced to the patient" }}
          linkProps={{
            text: "Bill to another person",
            onClick: () => addAccountHolder(undefined)
          }}
        />
      ) : null}
    </div>
  );
};
