import { memo, useMemo } from "react";
import { FormSpy, useForm } from "react-final-form";

import { useDebounce } from "@bps/utils";
import {
  AccClinicalTab,
  ClinicalDataType,
  DockableClinicalTab,
  EncounterClinicalDataDto,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nullifyAnyUndefined } from "@libs/utils/utils.ts";
import { AreasToObserveKeys } from "@shared-types/clinical/areas-to-observe-keys.type.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface StashedClinicalDataFormSpyProps<T> {
  clinicalRecord: ClinicalRecord;
  getData: (
    values: T,
    dirtyFields?: Record<keyof T, boolean>
  ) => EncounterClinicalDataDto;
  areasToObserve: Partial<
    Record<AreasToObserveKeys, (ClinicalDataType | PermanentClinicalTab)[]>
  >;
  syncWithAcc45?: boolean;
  encounterId?: string;
  contextId?: string;
  duration?: number;
  /**
   * This prop should be used ONLY if a stashed data of one form is used for others, and on the entire form cancel/reset
   * action, we want to keep the stashed data for other forms but not for the current one. For example:
   * 1. WorkHistoryForm stashed data are used for SOTAP and ACC45 forms. By resting WorkHistoryForm values we are keeping the stashed data for two related forms,
   *    but not for WorkHistoryForm itself. And the next time we open WorkHistoryForm, we use just clinical data, not stashed data
   *    (like if hasBeenReset use clinicalData otherwise stashedClinicalData) for initial values mapping.
   * 2. IntakeForm(s) stashed data is used for this form only and when we set the form values we wipe out the form stashed data completely.
   */
  withHasBeenResetFormCheck?: ClinicalDataType;
}

const StashedClinicalDataFormBase = memo(
  (props: StashedClinicalDataFormSpyProps<object>) => {
    const {
      clinicalRecord,
      getData,
      areasToObserve,
      encounterId,
      contextId,
      duration,
      syncWithAcc45,
      withHasBeenResetFormCheck
    } = props;

    const { clinical } = useStores();
    const { getState } = useForm<object>();

    const tabsMap = useMemo(() => {
      const map = new Map<DockableClinicalTab, AreasToObserveKeys[]>();

      for (const [area, tabs = []] of Object.entries(areasToObserve)) {
        tabs.forEach(tab => {
          const current = map.get(tab) ?? [];
          current.push(area as AreasToObserveKeys);
          map.set(tab, current);
        });
      }
      return map;
    }, [areasToObserve]);

    const setDirtyTab = () => {
      tabsMap.forEach((areas, type) => {
        const dirty = areas.some(
          area => !!clinicalRecord?.stashedClinicalData?.dirtyAreas?.get(area)
        );

        clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(dirty, {
          type,
          encounterId,
          contextId
        });
      });

      if (syncWithAcc45) {
        const dirtyFromStashedValues =
          !!clinicalRecord?.stashedClinicalData?.hasAcc45DirtyAreas;

        const dirtyFromClinicalFormValues =
          clinicalRecord.clinicalRecordFormsValuesStash.hasAcc45DirtyAreas;

        const currentOccupation =
          clinicalRecord?.stashedClinicalData?.patientDemographicUpdate
            ?.occupation;

        const savedOccupation =
          clinicalRecord?.clinicalData?.patientDemographicUpdate?.occupation;

        // only dirty the tab when mapping accOccupation exsists
        const accOccupation = currentOccupation
          ? clinicalRecord.getAccOccupationWithOccupationId(currentOccupation)
          : undefined;

        const isOccupationDirty =
          currentOccupation !== undefined &&
          (currentOccupation !== savedOccupation || !!accOccupation);

        const isDirty =
          dirtyFromStashedValues ||
          dirtyFromClinicalFormValues ||
          isOccupationDirty;

        clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(isDirty, {
          type: AccClinicalTab.Injury,
          encounterId,
          contextId
        });
      }

      if (withHasBeenResetFormCheck) {
        clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
          withHasBeenResetFormCheck,
          false
        );
      }
    };

    const saveStashedData = (values: object) => {
      const { modified } = getState();
      const clinicalData: EncounterClinicalDataDto = getData(values, modified);
      clinicalRecord.stashedClinicalData?.updateFromPatch(
        nullifyAnyUndefined(clinicalData)
      );
      setDirtyTab();
    };

    const debounceSaveStashedDate = useDebounce(saveStashedData, duration);

    return (
      <FormSpy
        subscription={{
          dirty: true,
          values: true,
          modified: true
        }}
        onChange={({ dirty, values, modified }) => {
          const hasBeenModified =
            modified && Object.values(modified).some(i => i);

          if (dirty || hasBeenModified) {
            debounceSaveStashedDate(values);
          }
        }}
      />
    );
  }
);

export const StashedClinicalDataFormSpy = <T extends object>(
  props: StashedClinicalDataFormSpyProps<T>
) => <StashedClinicalDataFormBase {...props} />;
