import { observer } from "mobx-react-lite";

import { dataAttribute, DataAttributes, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  Accommodation,
  FamilySocialTestElements,
  IntakeStatuses,
  LivesWith,
  Relationship,
  SignificantFamilyHistoryDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";

interface FamilySocialCardComponentProps {
  clinicalRecord: ClinicalRecord;
  userExperience: UserExperienceStore;
}
const formatFamilyHistory = (
  familyHistories: SignificantFamilyHistoryDataItemDto[]
) => {
  return familyHistories
    .filter(
      familyMember =>
        familyMember.conditions &&
        familyMember.conditions.length > 0 &&
        (familyMember.relationship === Relationship.Father ||
          familyMember.relationship === Relationship.Mother)
    )
    .map(familyMember => {
      const relationship = capitalizeSentence(familyMember.relationship);
      const conditions = familyMember.conditions
        ? familyMember.conditions
            .map(condition => condition.condition.originalText)
            .join(", ")
        : "";
      return `${relationship}: ${conditions}`;
    });
};
export const FamilySocialCardComponent: React.FC<FamilySocialCardComponentProps> =
  observer(({ clinicalRecord, userExperience }) => {
    const content: string[] = [];
    const { clinicalData, patient } = clinicalRecord;

    const familyHistories =
      clinicalData?.familyHistory?.significantFamilyHistories;

    const social = clinicalData?.social;
    const patientDemographicUpdate = clinicalData?.patientDemographicUpdate;
    const substanceUse = clinicalData?.substanceUse;

    const patientLabel = userExperience.localisedConfig("patientDisplay", {
      capitalizeFirst: true
    });
    if (familyHistories) {
      const familyHistoriesText = formatFamilyHistory(familyHistories);
      content.push(...familyHistoriesText);
    }
    if (substanceUse) {
      if (substanceUse.status === IntakeStatuses.ActiveParticipant) {
        content.push("Substance user");
      }
    }

    if (social?.livesWith === LivesWith.Alone) {
      content.push("Lives alone");
    }

    if (
      patientDemographicUpdate?.hasACareGiver?.observed ||
      (patient &&
        patient.relationships.filter(
          r => r.relationship === RelationshipType.Carer && r.hasRelationship
        ).length > 0)
    ) {
      content.push("Has a care giver");
    }

    switch (social?.accommodation) {
      case Accommodation.Homeless:
        content.push(`${patientLabel} is homeless`);
        break;
      case Accommodation.Hostel:
        content.push("Lives in a hostel");
        break;
      case Accommodation.NursingHome:
        content.push("Lives in a RACF");
        break;
      default:
        break;
    }

    const acd = social?.isAdvancedCareDirective;

    if (acd) {
      if (acd.observed) {
        content.push("Advanced Care Directive in place");
      } else {
        if (patient && patient.birthDate) {
          const age = DateTime.now().diff(patient.birthDate, "years").years;

          if (age && age >= 75) {
            content.push("No Advanced Care Directive in place");
          }
        }
      }
    }

    if (
      patientDemographicUpdate?.isEnduringPowerOfAttorney?.observed ||
      patient?.relationships?.find(
        x => x.relationship === RelationshipType.PowerOfAttorney
      )?.hasRelationship
    ) {
      content.push("EPOA nominated");
    }

    return content.length > 0 ? (
      <div
        {...dataAttribute(
          DataAttributes.Element,
          FamilySocialTestElements.FamilySocialSummary
        )}
      >
        {content.map((c: string) => {
          return (
            <Text key={c} block>
              {c}
            </Text>
          );
        })}
      </div>
    ) : (
      <PatientSummaryEmptyState />
    );
  });
