import { maxArrayLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { AddDiagnosisFormValues } from "@shared-types/acc/add-diagnosis-values.type.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";

import { ClaimDiagnosisRequiredValidator } from "./ClaimDiagnosisRequiredValidator.tsx";
import { ClaimDiagnosisValidator } from "./ClaimDiagnosisValidator.tsx";

export class AddDiagnosisFormValidator extends Validator<AddDiagnosisFormValues> {
  constructor(claimAdjustment: ClaimAdjustment) {
    super();

    if (claimAdjustment.hasDiagnosisAddition) {
      const diagnosisRequiredValidator = new ClaimDiagnosisRequiredValidator();
      const claimDiagnosisValidator = new ClaimDiagnosisValidator();

      this.forArrayField("diagnosisAdditions", [
        diagnosisRequiredValidator.validate,
        claimDiagnosisValidator.validate
      ]);

      this.forField("diagnosisAdditions", [
        maxArrayLength(6, "Must not exceed 6 diagnosis.")
      ]);
    }
  }
}
