import { observer } from "mobx-react-lite";

import { DefaultButton, Heading } from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { MeetingFormFields } from "./components/MeetingFormFields.tsx";
import { MeetingFormValidator } from "./components/MeetingFormValidator.ts";
import {
  MeetingFormContext,
  useMeetingFormContext
} from "./context/MeetingFormContext.ts";
import { MeetingFormHelper } from "./context/MeetingFormHelper.ts";

const validator = new MeetingFormValidator();

const MeetingDialogBase: React.FunctionComponent = observer(() => {
  const { booking } = useStores();

  const { startDate } = useBookingCalendarScreenContext();
  const {
    onSubmit,
    onSubmitted,
    onDismiss,
    getInitialValues,
    calendarEventId,
    showCancelEvent
  } = useMeetingFormContext();

  const extraActionBefore = () =>
    calendarEventId ? (
      <DefaultButton
        text="Cancel meeting"
        onClick={showCancelEvent}
        styles={{ root: { marginRight: "auto" } }}
      />
    ) : undefined;

  return (
    <SubmissionFormDialog
      dialogName={`${
        !booking.ui.currentAppointment?.id ? "New" : "Edit"
      } meeting dialog`}
      dialogProps={{
        onDismiss,
        maxWidth: 600,
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">
              {`${!booking.ui.currentAppointment?.id ? "New" : "Edit"} meeting`}
            </Heading>
          ),
          showCloseButton: true
        }
      }}
      validate={validator.validate}
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitted}
      initialValues={getInitialValues(startDate)}
      buttonsProps={{
        extraActionsBefore: extraActionBefore,
        hideButtonsSeparator: true,
        submitButtonProps: { iconProps: undefined }
      }}
      styles={{
        subComponentStyles: {
          submitErrorMessage: { root: { marginTop: 8 } }
        }
      }}
    >
      {() => <MeetingFormFields />}
    </SubmissionFormDialog>
  );
});

export const MeetingDialog = observer(() => {
  const { booking } = useStores();

  const hidden =
    !booking.ui.currentAppointment ||
    booking.ui.currentAppointment.type !== CalendarEventType.Meeting;

  if (hidden) return null;
  return (
    <MeetingFormContext.Provider value={new MeetingFormHelper(booking)}>
      <MeetingDialogBase />
    </MeetingFormContext.Provider>
  );
});
