import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useField, useFormState } from "react-final-form";

import { DetailsList, IColumn, Shimmer } from "@bps/fluent-ui";
import { ALL_DAYS_OF_WEEK, dayOfWeekName } from "@bps/utils";
import {
  AppointmentReminderJobPreviewArgs,
  AppointmentReminderJobPreviewDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";

import { CommsScheduleContext } from "../context/CommsScheduleContext.ts";
import {
  CommsScheduleFormValues,
  DayOfWeekConfigValue
} from "./CommsScheduleForm.types.ts";

const nameOf = nameOfFactory<CommsScheduleFormValues>();

export const DaysOfWeekField: React.FC = observer(() => {
  const { booking } = useStores();

  const { initialValues } = useFormState<CommsScheduleFormValues>({
    subscription: { initialValues: true }
  });

  const {
    input: { value: sendBefore }
  } = useField(nameOf("sendBefore"), {
    subscription: { value: true }
  });

  const {
    input: { value: scheduleBehaviour }
  } = useField(nameOf("scheduleBehaviour"), {
    subscription: { value: true }
  });

  const {
    input: { value: daysOfWeek }
  } = useField(nameOf("daysOfWeek"), {
    subscription: { value: true }
  });

  const { transformFromDaysOfWeek, transformToDaysOfWeek } =
    useContext(CommsScheduleContext);

  const args: AppointmentReminderJobPreviewArgs = {
    numberOfDaysInAdvanceToSend: sendBefore,
    selectedDaysOfWeek: transformToDaysOfWeek(daysOfWeek),
    scheduleBehaviour
  };

  const schedule =
    initialValues.daysOfWeek ?? transformFromDaysOfWeek(ALL_DAYS_OF_WEEK);

  const fetchPreview = async () => {
    return await booking.getAppointmentReminderJobPreview(args);
  };

  const onRenderAppointmentDaysColumn = (
    value: AppointmentReminderJobPreviewDto,
    jobDayOfWeek: number
  ) => {
    const daysOfWeekPreview = value.daysOfWeek;
    const itemInPreview =
      daysOfWeekPreview &&
      daysOfWeekPreview.find(x => x.jobDayOfWeek === jobDayOfWeek);
    return itemInPreview &&
      itemInPreview.appointmentDaysOfWeek &&
      itemInPreview.appointmentDaysOfWeek.length > 0
      ? itemInPreview.appointmentDaysOfWeek
          .map(d => dayOfWeekName(d) ?? "")
          .join(", ")
      : "--";
  };

  const columns: IColumn[] = [
    {
      key: "checked",
      minWidth: 100,
      maxWidth: 100,
      name: "Send",
      onRender: (item: DayOfWeekConfigValue, index: number) => {
        return <CheckboxField name={`daysOfWeek[${index}].checked`} />;
      }
    },
    {
      key: "jobDayOfWeek",
      minWidth: 100,
      maxWidth: 100,
      name: "Remind on",
      onRender: (item: DayOfWeekConfigValue) => {
        return dayOfWeekName(item.jobDayOfWeek);
      }
    },
    {
      key: "appointmentDays",
      minWidth: 100,
      maxWidth: 100,
      name: "Booked appt",
      onRender: (item: DayOfWeekConfigValue) => (
        <DataFetcher
          refetchId={JSON.stringify(args)}
          fetch={fetchPreview}
          fallback={<Shimmer width={120} />}
        >
          {previewModel =>
            onRenderAppointmentDaysColumn(previewModel, item.jobDayOfWeek)
          }
        </DataFetcher>
      )
    }
  ];

  return <DetailsList items={schedule} columns={columns} />;
});
