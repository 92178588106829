import { FunctionComponent } from "react";

import { MedicalHistoryClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DeleteDialog,
  DeleteDialogProps
} from "@ui-components/DeleteDialog.tsx";

export interface DeleteMedicalHistoryDialogProps {
  hidden: boolean;
  onConfirm: DeleteDialogProps["onConfirm"];
  onCancel?: () => void;
  item: MedicalHistoryClinicalDataItemDto | undefined;
}

const DeleteMedicalHistoryDialogBase: FunctionComponent<
  DeleteMedicalHistoryDialogProps
> = ({ item, ...rest }) => {
  const { clinical } = useStores();

  let title: string = "diagnosis";
  if (item && item.diagnosisCode) {
    const diagnosisRef = clinical.getTerminologyFromMap(
      item.diagnosisCode.code + item.diagnosisCode.originalText
    );
    title = diagnosisRef?.isDiagnosis ? "diagnosis" : "procedure";
  }
  return (
    <DeleteDialog
      formName="delete-procedure"
      {...rest}
      title={`Delete ${title}`}
      subText={`Are you sure you want to delete this ${title}?`}
      options={clinical.ref.reasonsForDeleteMedicalHistory.keyTextValues}
    />
  );
};

export const DeleteMedicalHistoryDialog = withFetch(
  x => [x.clinical.ref.reasonsForDeleteMedicalHistory.load()],
  DeleteMedicalHistoryDialogBase
);
