import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { ProviderDetailsFormValues } from "@shared-types/acc/provider-details-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ErrorBlock } from "@ui-components/ErrorBlock.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { ProviderDetailsFormValidator } from "../validators/ProviderDetailsFormValidator.tsx";
import {
  ClaimAdjustmentFormLabels,
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";

const providerDetailsFields: (keyof ProviderDetailsFormValues)[] = [
  "providerContactNumber",
  "hpiOrganisationNumber",
  "providerContractType"
];

export const ProviderDetailsFormSectionBase: React.FC = observer(() => {
  const { acc } = useStores();
  const claimAdjustmentContext = useClaimAdjustmentContext();
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const form = useForm<ClaimAdjustmentFormValues>();
  const getProviderContractTypes = () => {
    return acc.ref.providerContractTypes.keyTextValues.filter(
      x => claimAdjustmentContext.provider?.contractTypes?.includes(x.key)
    );
  };

  const onProviderSelectionChanged = async (id: string) => {
    await claimAdjustmentContext.setProviderById(id);
    form.batch(() => {
      form.change(
        claimAdjustmentFormNameOf("providerContractType"),
        claimAdjustmentContext.providerContractType
      );
      form.change(
        claimAdjustmentFormNameOf("providerContactNumber"),
        claimAdjustmentContext.providerContactNumber
      );
    });
  };

  const onProviderSelectionCleared = async () => {
    form.batch(() => {
      form.change(claimAdjustmentFormNameOf("providerContractType"), undefined);
      form.change(
        claimAdjustmentFormNameOf("providerContactNumber"),
        undefined
      );
    });
    await claimAdjustmentContext.setProviderById(undefined);
  };

  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.providerDetails}
      errorProps={{ fields: providerDetailsFields }}
      openOnRender={
        selectedMenuItemId === ClaimsAdjustmentCardIds.providerDetails
      }
      heading={ClaimAdjustmentSectionHeading.providerDetails}
      iconName="contact"
      statusValidator={new ProviderDetailsFormValidator()}
    >
      <FieldSpy
        name={claimAdjustmentFormNameOf("providerId")}
        onChange={async id => {
          if (!id) await onProviderSelectionCleared();
          else await onProviderSelectionChanged(id);
        }}
      />
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <BookableUserPickerField
            name={claimAdjustmentFormNameOf("providerId")}
            label={ClaimAdjustmentFormLabels.provider}
            filter={{
              statusCodes: [UserStatus.Active]
            }}
            inputProps={{
              placeholder: ClaimAdjustmentFormLabels.searchForProvider,
              name: "provider-filter-box"
            }}
            styles={{ root: { width: 188 } }}
            required
            iconName="Search"
          />
          <DropdownField
            styles={{ root: { width: 210 } }}
            name={claimAdjustmentFormNameOf("providerContractType")}
            required
            label={ClaimAdjustmentFormLabels.providerContractType}
            options={getProviderContractTypes()}
            validateOnInitialize
          />

          <TextInputField
            styles={{ root: { width: 184 } }}
            name={claimAdjustmentFormNameOf("providerContactNumber")}
            required
            label={ClaimAdjustmentFormLabels.contactNumber}
          />
        </Stack>
        {claimAdjustmentContext.provider &&
          !claimAdjustmentContext.provider?.cpn && (
            <ErrorBlock
              errorText="HPI CPN missing for this provider's user settings. This information is required to lodge an ACC32"
              linkText="Update user settings"
              to={routes.settings.users.user.path({
                id: claimAdjustmentContext.provider.id
              })}
            />
          )}
        {!claimAdjustmentContext.claimAdjustment?.hpiOrganisationNumber && (
          <ErrorBlock
            errorText="ACC provider types missing for this provider's user settings. This information is required to lodge an ACC32"
            linkText="Update user settings"
            to={routes.settings.practices.basePath.pattern}
          />
        )}
        {claimAdjustmentContext.provider?.contractTypes?.length === 0 && (
          <ErrorBlock
            errorText="ACC provider types missing for this provider's user settings. This information is required to lodge an ACC32"
            linkText="Update user settings"
            to={routes.settings.users.user.path({
              id: claimAdjustmentContext.provider.id
            })}
          />
        )}
      </Stack>
    </ClaimCard>
  );
});

export const ProviderDetailsFormSection = withFetch(
  x => [x.acc.ref.providerContractTypes.load()],
  ProviderDetailsFormSectionBase
);
