import { isDateBeforeField } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { FilterState } from "../FeesList.types.ts";

export class FeesListFilterValidator extends Validator<
  RecursivePartial<FilterState>
> {
  constructor() {
    super();
    this.forField(
      "endDate",
      isDateBeforeField(
        ValidationMessages.endDateBeforeStartDate,
        "startDate",
        true
      )
    );
  }
}
