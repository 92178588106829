import { memo } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  FontWeights,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { AppointmentNotice } from "../../../BookingCalendarEventModel.ts";
import { NoticeIcon } from "./AppointmentNotices.tsx";

interface AppointmentNoticeItemProps {
  appointmentNotice: AppointmentNotice;
}

export const AppointmentNoticeItem: React.FunctionComponent<AppointmentNoticeItemProps> =
  memo((props: AppointmentNoticeItemProps): JSX.Element => {
    const { label, type, description } = props.appointmentNotice;

    const theme = useTheme();

    return (
      <Text
        {...dataAttribute(
          DataAttributes.Element,
          `appt-notice-${type.toLowerCase()}`
        )}
      >
        <FontIcon
          iconName={NoticeIcon[type]}
          styles={{
            root: {
              color: theme.palette.themePrimary,
              marginRight: 8,
              fontSize: FontSizes.size16,
              verticalAlign: "middle"
            }
          }}
        />
        {label && (
          <>
            <span
              style={{ fontWeight: FontWeights.semibold }}
              {...dataAttribute(DataAttributes.Element, "appt-notice-label")}
            >
              {label}
            </span>
            {" - "}
          </>
        )}
        {description}
      </Text>
    );
  });
