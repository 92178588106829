import { createContext } from "react";

import { EncounterFormHelper } from "./EncounterFormHelper.ts";

// Empty object is provided as default context as unable to create a EncounterFormHelper class without a RootStore
// This will result in the app or tests breaking if a context value isn't provided

export const EncounterFormContext = createContext<EncounterFormHelper>(
  {} as EncounterFormHelper
);
