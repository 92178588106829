import {
  FontIcon,
  FontSizes,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import {
  AgentClinicalDataItemDto,
  DescriptionTag,
  ReactionType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ReactionAgentKind } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";

import { AgentReaction } from "./ReactionForm.types.ts";

interface IRenderAgentDetailsProps {
  reaction: AgentReaction;
  agent?: AgentClinicalDataItemDto;
}

export const RenderAgentDetails: React.FunctionComponent<
  IRenderAgentDetailsProps
> = ({ reaction, agent }) => {
  const { type, name } = reaction;
  const theme = useTheme();
  const comment = agent?.comment;

  let descriptionTag = null;
  let otherAgentText = null;
  if (type === ReactionType.DrugClass) {
    descriptionTag = DescriptionTag.Class;
  } else if (
    type === ReactionType.NonDrug &&
    name !== ReactionAgentKind.Other
  ) {
    descriptionTag = DescriptionTag.NonDrug;
  } else {
    if (name === ReactionAgentKind.Other) {
      if (reaction?.agent?.originalText === ReactionAgentKind.Other) {
        otherAgentText = reaction?.otherText;
      }
    }
  }

  return (
    <Stack>
      <Text>
        {otherAgentText ? otherAgentText : name}
        {descriptionTag && <strong>{descriptionTag}</strong>}
        {comment && (
          <TooltipHost
            tooltipProps={{
              calloutProps: {
                calloutMaxWidth: 270,
                styles: { calloutMain: { padding: 8 } }
              }
            }}
            content={comment}
          >
            <FontIcon
              iconName="CommentSolid"
              styles={{
                root: {
                  fontSize: FontSizes.size14,
                  color: theme.palette.themePrimary,
                  paddingLeft: "4px"
                }
              }}
            />
          </TooltipHost>
        )}
      </Text>
    </Stack>
  );
};
