import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { DirectionalHint, Stack, TooltipHost } from "@bps/fluent-ui";
import { DATE_FORMATS, DateSettings, DateTime, TIME_FORMATS } from "@bps/utils";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  availableResourceClassname,
  unavailableOrgClassName,
  unavailableResourceClassname
} from "./BookingCalendar.styles.ts";

// This component is rendered a lot of times on screen so rerendering is to be kept to a minimum.
//   It uses the mobx observer HOC which memoises the component so that it won't rerender unless something changes
//   In addition, it receives a date in milliseconds as a prop rather than a JS Date or a DateTime.
//   This is because the function that renders this component may provide a different JS Date each time it is called,
//   which would result in unnecessary rerenders.  The function that renders this component is also called a lot so
//   we want that function to be as simple as possible - which is why we use milliseconds as it is the most straightforward
//   way to turn a JS Date into a raw value.

// One issue with using milliseconds though is because the date was never converted to a DateTime first, it will be in the
//  user's system timezone - which may not match the practice timezone.  So we have to use setZone to fix this problem.
//  This is not "normal" and is only done here because the performance optimisation mentioend above was needed.  And don't
//  use slotTime for anything other than as a timestamp, because the timezone is different to the other DateTimes

interface BookingCalendarCellProps extends PropsWithChildren {
  dateMillis: number;
  resourceId: string | undefined;
}

interface GetLocationBackgroundArgs {
  slotTime: DateTime;
  resourceId?: string;
  isUserAvailable: boolean;
  isOrgUnitAvailable: boolean;
}

const getBaseLocationClassNames = (slotTime: DateTime, resourceId?: string) => {
  const dateClass = `dt-${slotTime.toFormat(DATE_FORMATS.TEST_DATE_FORMAT)}`;
  const resourceClass = `provider-${resourceId}`;
  return [dateClass, resourceClass];
};

const getlocationBackgroundClassName = (args: GetLocationBackgroundArgs) => {
  const locationBackgroundClassName = getBaseLocationClassNames(
    args.slotTime,
    args.resourceId
  );
  if (!args.isUserAvailable && args.isOrgUnitAvailable) {
    locationBackgroundClassName.push(unavailableResourceClassname);
  } else if (!args.isUserAvailable && !args.isOrgUnitAvailable) {
    locationBackgroundClassName.push(unavailableOrgClassName);
  } else {
    locationBackgroundClassName.push(availableResourceClassname);
  }

  return {
    className: locationBackgroundClassName.join(" ")
  };
};

export const BookingCalendarCell = observer(
  ({ dateMillis, resourceId }: BookingCalendarCellProps) => {
    const { core } = useStores();

    const slotTime = DateTime.fromMillis(dateMillis).setZone(
      DateSettings.systemTimeZone
    );

    const dateString = slotTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

    const { locationHours, getLocationColourBySlotTime, getUserIsAvailable } =
      useBookingCalendarScreenContext();

    let colourCode: string | undefined;
    if (core.hasMultipleActiveLocations) {
      colourCode = getLocationColourBySlotTime(slotTime, resourceId ?? "");
    }

    const isUserAvailable = getUserIsAvailable(slotTime, resourceId);

    const isOrgUnitAvailable =
      !!locationHours &&
      (locationHours.timeRanges?.some(
        timeRange => slotTime >= timeRange.from && slotTime < timeRange.to
      ) ??
        false);

    const locationClassName = getlocationBackgroundClassName({
      slotTime,
      resourceId,
      isUserAvailable,
      isOrgUnitAvailable
    });

    return (
      <TooltipHost
        hostClassName="rbc-timeslot-group__item"
        content={dateString}
        directionalHint={DirectionalHint.topRightEdge}
        calloutProps={{
          isBeakVisible: false,
          layerProps: { styles: { root: { zIndex: 0 } } }
        }}
        styles={{ root: { zIndex: 1 } }}
      >
        <Stack horizontal grow>
          {colourCode && (
            <div style={{ backgroundColor: colourCode, width: 5 }} />
          )}
          <div style={{ flexGrow: 1 }} {...locationClassName} />
        </Stack>
      </TooltipHost>
    );
  }
);
