import { DATE_FORMATS, DateTime, TIME_FORMATS } from "@bps/utils";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";

import { WorkingHoursInfo } from "./components/EditUserDayWorkingHours.types.ts";

export const getReason = (
  availability: UserAvailabilityModel | undefined,
  date: DateTime
): string | undefined => {
  const existingOverride = availability?.scheduleOverrides.find(
    x =>
      DateTime.fromISO(x.startDate).startOf("day").toMillis() ===
        date.startOf("day").toMillis() &&
      DateTime.fromISO(x.endDate).startOf("day").toMillis() ===
        date.startOf("day").toMillis()
  );
  return existingOverride ? existingOverride.reason : "";
};
export const openingHoursInfoMessage = ({
  start,
  end,
  date
}: WorkingHoursInfo): string => {
  if (!start || !end) {
    return `Not working all day ${date.toFormat(
      DATE_FORMATS.DAY_OF_WEEK_STRING
    )}`;
  }

  const durationHours = end.diff(start, ["hours"]).hours;
  return `${date.toFormat(
    DATE_FORMATS.DAY_OF_WEEK_STRING
  )}'s Hours are usually ${start.toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  )} to ${end.toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  )} (${durationHours}hrs)`;
};
