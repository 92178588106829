import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { GetServiceSearchDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import type { Schedule } from "@stores/billing/models/Schedule.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

interface AddServicesFilterProps {
  onSearch: (searchFilters: AddServicesFilterValues) => void;
  schedules: Schedule[];
}

interface AddServicesFilterValues
  extends Pick<GetServiceSearchDto, "searchText" | "scheduleIds"> {}

export const AddServicesFilter: React.FC<AddServicesFilterProps> = ({
  onSearch,
  schedules
}) => {
  const scheduleOptions = schedules.map(schedule => {
    return { key: schedule.id, text: schedule.name };
  });

  return (
    <FilterBar<AddServicesFilterValues>
      initialValues={{ searchText: "", scheduleIds: [] }}
      onChange={({ values }) => onSearch(values)}
      items={[
        {
          name: "searchText",
          stickItem: true,
          type: "searchBox",
          props: {
            ...dataAttribute(DataAttributes.Element, "search-box"),
            placeholder: "Search by number or description",
            styles: {
              clearButton: { display: "none" },
              root: {
                width: 400
              }
            }
          }
        },
        {
          name: "scheduleIds",
          type: "optionsSelect",
          props: {
            ...dataAttribute(DataAttributes.Element, "schedule-dropdown"),
            multiSelect: true,
            options: scheduleOptions,
            placeholder: "All schedules",
            calloutWidth: 200,
            styles: {
              root: { width: 200 }
            }
          }
        }
      ]}
    />
  );
};
