import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { ReactionClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ReactionForm } from "./ReactionForm.tsx";

interface ReactionFormDialogProps {
  clinicalRecord: ClinicalRecord;
  onDismiss: () => void;
  reaction?: ReactionClinicalDataItemDto;
}

export const ReactionFormDialog: FunctionComponent<ReactionFormDialogProps> =
  observer(({ onDismiss, clinicalRecord, reaction }) => {
    const { notification } = useStores();
    const handleReactionSaved = (dismiss: boolean = false) => {
      notification.success(
        !reaction ? "Reaction has been recorded." : "Reaction has been updated."
      );
      if (dismiss) {
        onDismiss();
      }
    };

    return (
      <ReactionForm
        clinicalRecord={clinicalRecord}
        onSubmitted={handleReactionSaved}
        onCancel={onDismiss}
        reaction={reaction}
      />
    );
  });
