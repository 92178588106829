import { observable, runInAction } from "mobx";

import { newGuid } from "@bps/utils";
import { TemplateDto } from "@libs/gateways/template-management/TemplateManagementGateway.dtos.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { TemplateManagementFormValues } from "../TemplateManagementTypes.ts";
import { TemplateFieldsHelper } from "./TemplateFieldsHelper.ts";

export class TemplateManagementHelper {
  constructor(private root: RootStore) {
    this.fields = new TemplateFieldsHelper(root);
    this.fillTypeToFormatMap();
  }

  fields: TemplateFieldsHelper;

  typeToFormatMap = new Map<string, string>();

  @observable
  showTemplateManagementModal: boolean;

  templateManagementInitialValues?: TemplateManagementFormValues;

  setShowTemplateManagementModal = (
    formValues?: TemplateManagementFormValues
  ) => {
    runInAction(() => {
      this.templateManagementInitialValues = formValues;
      this.showTemplateManagementModal = true;
    });
  };

  hideTemplateManagementModal = () => {
    runInAction(() => {
      this.showTemplateManagementModal = false;
    });
  };

  saveTemplate = async (values: TemplateManagementFormValues) => {
    const { templateManagement } = this.root;

    const dto: TemplateDto = { ...values };
    if (dto.id) {
      await templateManagement.updateTemplate(dto);
    } else {
      dto.id = newGuid();
      await templateManagement.createTemplate(dto);
    }

    this.hideTemplateManagementModal();
  };

  private dtoToFormValues = (
    dto: TemplateDto
  ): TemplateManagementFormValues => {
    const formValues = { ...dto };
    return formValues;
  };

  editFormValues = (id: string) => {
    const { templateManagement } = this.root;
    const template = templateManagement.templateMap.get(id);

    if (template) {
      return this.dtoToFormValues(template.dto);
    }

    return;
  };

  newFormValues: TemplateManagementFormValues = {
    id: "",
    name: "",
    description: "",
    templateFormatCode: "TXT",
    templateTypeCode: "SMS",
    content: "",
    eTag: "",
    context: []
  };

  private fillTypeToFormatMap = () => {
    this.typeToFormatMap.set("SMS", "TXT");
    this.typeToFormatMap.set("EML", "HTML");
  };
}
