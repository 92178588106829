import { to2dp } from "@bps/utils";
import {
  AddAllocationItemDto,
  ItemType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { AllocationFieldValues } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import {
  ACCOUNT_CREDIT_METHOD,
  AllocationFormValues
} from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { Payment } from "@stores/billing/models/Payment.ts";

interface IUnallocatedCredit {
  creditId: string;
  unallocated: number;
}

interface AllocationCreditState {
  allocationItems: AddAllocationItemDto[];
  unallocatedCredits: IUnallocatedCredit[];
}

interface AddAllocationItemOptions {
  values: AllocationFormValues;
  creditNotes?: CreditNote[];
  payments?: Payment[];
  newPayment?: Payment;
  creditNotesTotal?: number;
  paymentTotal?: number;
}

export class AllocationItemHelper {
  //creates allocation items for an allocation.
  //it will use as many credits as it needs to pay for the allocation total
  //for each credit that is used a new allocation item will need to be created
  private mapToAllocationItems = (
    allocation: AllocationFieldValues,
    allocationCreditState: AllocationCreditState
  ): AllocationCreditState => {
    const unallocatedCredits = [...allocationCreditState.unallocatedCredits];

    const allocationItems = [...allocationCreditState.allocationItems];

    let allocationTotal = allocation.total;

    while (allocationTotal > 0 && !!unallocatedCredits.length) {
      const currentCreditNote = unallocatedCredits[0];
      let unallocatedCredit = currentCreditNote.unallocated;

      //get the total amount to be paid of as much as the credit has left unallocated.
      const amount = Math.min(allocationTotal, unallocatedCredit);

      //create allocation item
      allocationItems.push({
        creditId: currentCreditNote.creditId,
        amount,
        itemType: ItemType.Allocation,
        invoiceItemId: allocation.invoiceItemId,
        locationId: allocation.locationId
      });

      //reduce the totals.
      allocationTotal = to2dp(allocationTotal - amount);
      unallocatedCredit = to2dp(unallocatedCredit - amount);

      if (unallocatedCredit === 0) {
        //when no more unallocated credit, the item is removed as it has nothing left to allocate.
        unallocatedCredits.shift();
      } else {
        //set the remaing unallocated amount for the next allocaion.
        unallocatedCredits[0] = {
          ...currentCreditNote,
          unallocated: unallocatedCredit
        };
      }
    }

    return {
      allocationItems,
      unallocatedCredits
    };
  };

  //maps the credits and filters them by how much creditTotal is spent.
  //will only return a credit if there is creditTotal remaining
  private mapToUnallocatedCredits = (
    credits: CreditNote[] | Payment[],
    creditTotal: number
  ): IUnallocatedCredit[] => {
    let totalRemaing = creditTotal;
    return credits
      .map((credit: CreditNote | Payment) => {
        //the credits unallocated amount can't be more than the total remianing
        //this means some credits will only be partially used.
        const amount = Math.min(totalRemaing, credit.unallocated);
        totalRemaing = totalRemaing - amount;
        return {
          creditId: credit.id,
          unallocated: amount
        };
      })
      .filter(credit => credit.unallocated > 0);
  };

  private getCreditAmountsToSpend = (
    creditNotesTotal: number,
    paymenttotal: number,
    accountCreditSelected: number
  ) => {
    let remaingAccountCredit = accountCreditSelected;
    const creditTotalTotalToSpend = Math.min(
      creditNotesTotal,
      remaingAccountCredit
    );
    remaingAccountCredit = remaingAccountCredit - creditTotalTotalToSpend;
    const paymenttotalToSpend = Math.min(paymenttotal, remaingAccountCredit);

    return [creditTotalTotalToSpend, paymenttotalToSpend];
  };

  private getUnallocatedCredits = (options: AddAllocationItemOptions) => {
    const {
      values,
      creditNotes,
      payments,
      newPayment,
      creditNotesTotal,
      paymentTotal
    } = options;

    const accountCredit = values.payments?.find(
      x => x.method === ACCOUNT_CREDIT_METHOD
    );

    const [creditTotalTotalToSpend, paymentTotalToSpend] =
      this.getCreditAmountsToSpend(
        creditNotesTotal || 0,
        paymentTotal || 0,
        accountCredit?.amount || 0
      );

    const unallocatedCreditNotes = this.mapToUnallocatedCredits(
      creditNotes || [],
      creditTotalTotalToSpend
    );

    const unallocatedPayments = this.mapToUnallocatedCredits(
      payments || [],
      paymentTotalToSpend
    );

    const newPayments = newPayment
      ? this.mapToUnallocatedCredits(
          [newPayment],
          newPayment.unallocated || 0
        )[0]
      : undefined;

    //the order in which they should be used/
    const unallocatedCredits = [
      ...unallocatedCreditNotes,
      ...unallocatedPayments,
      newPayments
    ].filter(x => x !== undefined) as IUnallocatedCredit[];

    return unallocatedCredits;
  };

  public getAddAllocationItemsDto = (options: AddAllocationItemOptions) => {
    const { values } = options;
    const unallocatedCredits = this.getUnallocatedCredits(options);
    const { allocationItems } = (values.allocations || [])
      ?.filter(x => !!x.checked)
      .reduce(
        (
          allocationCreditState: AllocationCreditState,
          allocation: AllocationFieldValues
        ) => this.mapToAllocationItems(allocation, allocationCreditState),
        {
          allocationItems: [],
          unallocatedCredits
        }
      );

    return allocationItems;
  };
}
