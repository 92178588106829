import { FontWeights } from "@bps/fluent-ui";

export const styles = {
  fieldset: { root: { marginTop: 8 } },
  datePicker: {
    subComponentStyles: {
      label: {
        root: { width: 96, marginLeft: 8, marginRight: 8 }
      }
    },
    wrapper: { display: "flex" }
  },
  acceptable: { root: { fontSize: 14 } },
  outcome: { root: { width: 225, paddingLeft: 16 } },
  form: { fields: { overflowY: "inherit" } },
  inboxButton: { textContainer: { flexGrow: 0 } },
  fileName: { root: { fontWeight: FontWeights.bold } },
  previewLink: { root: { marginRight: "auto" } }
};
