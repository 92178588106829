import { CorrespondenceVisibility } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";

import { AttachCorrespondenceFileValue } from "../clinical-form/DocumentBatchFormValues.ts";
import { AddXrayParametersFormValues } from "./AddXrayParameterDialog.types.ts";

export interface InvestigationFormValues {
  subject: string;
  from?: string;
  reportDate?: Date;
  reportType?: string;
  link?: string;
  extraInfo?: string;
  files: AttachCorrespondenceFileValue[];
  inboxDocument?: InboxDocument;
  reportText?: string;
  outcome?: string;
  visibility?: CorrespondenceVisibility;
  xrayParameters?: AddXrayParametersFormValues;
  isReportTextDocument?: boolean;
}

export enum Labels {
  DialogAdd = "New investigation report",
  TaskOutcome = "Task outcome",
  Link = "Attach a link",
  ExtraInfo = "Extra info",
  Date = "Document date",
  Subject = "Subject",
  From = "From",
  DialogEdit = "Edit investigation report",
  InboxAdd = "Select related file from Inbox",
  InboxAddDialog = "Select a file from Inbox",
  ReportText = "Text of the report",
  ReportType = "This report is",
  Outcome = "Outcome",
  NewRequest = "New imaging request"
}

export interface AddEditInvestigationDialogProps {
  onDismiss: () => void;
  clinicalRecord: ClinicalRecord;
  editInvestigation?: ClinicalDocument;
  userTask?: UserTask;
}
