import { FunctionComponent, useContext } from "react";

import { IconButton, Stack, TooltipHost, useTheme } from "@bps/fluent-ui";
import { SystemNotification as SystemNotificationModel } from "@stores/notifications/models/SystemNotification.ts";

import { NotificationContext } from "../context/NotificationContext.ts";
import { SystemNotificationBadges } from "./SystemNotificationBadges.tsx";

interface SystemNotificationStatusProps {
  notification: SystemNotificationModel;
}

export const SystemNotificationStatus: FunctionComponent<
  SystemNotificationStatusProps
> = (props: SystemNotificationStatusProps) => {
  const { updateReadStatus } = useContext(NotificationContext);
  const { notification } = props;
  const isRead = notification.isRead;
  const theme = useTheme();
  return (
    <Stack horizontal>
      <SystemNotificationBadges notification={props.notification} />
      <TooltipHost content={!isRead ? "Mark as read" : "Mark as unread"}>
        <IconButton
          onMouseDown={e => e.stopPropagation()}
          iconProps={{ iconName: !isRead ? "CircleFill" : "Accept" }}
          onClick={async () =>
            await updateReadStatus(notification.id, !notification.isRead)
          }
          styles={{
            root: {
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              borderColor: "none"
            },
            rootHovered: {
              backgroundColor: "none",
              ".ms-Button-icon": {
                opacity: !isRead ? 0.8 : 1,
                color: !isRead
                  ? `${theme.palette.themeDark}`
                  : theme.palette.themeDarkAlt
              }
            },
            rootPressed: {
              backgroundColor: "none"
            },
            icon: {
              fontSize: !isRead ? 10 : 16,
              color: !isRead
                ? theme.palette.themeDark
                : theme.palette.neutralTertiary
            }
          }}
        />
      </TooltipHost>
    </Stack>
  );
};
