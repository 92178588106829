import { observer } from "mobx-react-lite";
import React from "react";

import { Pivot, PivotItem, Stack, Tile } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useAccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { ViewKeys } from "@modules/billing/screens/account/context/AccountScreenHelper.ts";
import { AllocationViewModal } from "@modules/billing/screens/allocation/components/AllocationViewModal.tsx";
import { BillingButtons } from "@modules/billing/screens/billing-history/components/BillingButtons.tsx";
import { InvoiceViewModal } from "@modules/billing/screens/invoice/components/InvoiceViewModal.tsx";
import { TransactionFilterBar } from "@modules/billing/screens/shared-components/AllocationList/transaction-filter/TransactionFilterBar.tsx";
import { TransactionList } from "@modules/billing/screens/shared-components/AllocationList/transaction-list/TransactionList.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { StatementsList } from "./StatementsList.tsx";
import { StatementPrint } from "./transaction-list/StatementPrint.tsx";

export const AccountsList: React.FC = observer(() => {
  const { routing } = useStores();

  const {
    modalInvoiceId,
    modalAllocationId,
    onDismissModalInvoice,
    onSubmitModalInvoice,
    onDismissModalAllocation,
    onSubmitModalAllocation,
    isStatementPrinting,
    accountContact,
    selectedViewKey,
    setSelectedView
  } = useAccountScreenContext();

  const accountContactId: string | undefined = routing.match(
    routes.accounts.account
  )?.params.id;

  return (
    <>
      <Stack horizontal horizontalAlign="space-between">
        <Pivot
          selectedKey={selectedViewKey}
          onLinkClick={item => setSelectedView(item?.props.itemKey as ViewKeys)}
          styles={{
            link: { backgroundColor: "inherit" }
          }}
        >
          <PivotItem headerText="Accounts" itemKey={ViewKeys.accounts} />
          <PivotItem headerText="Statements" itemKey={ViewKeys.statements} />
        </Pivot>
        <BillingButtons contactId={accountContactId} />
      </Stack>
      <Tile
        styles={{
          root: { padding: 0, flexGrow: 1 },
          content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch"
          }
        }}
      >
        <Stack
          styles={{ root: { padding: 24 } }}
          tokens={{ childrenGap: 24 }}
          grow
        >
          {selectedViewKey === ViewKeys.accounts ? (
            <TransactionFilterBar accountContact={accountContact}>
              <TransactionList accountContact={accountContact} />
            </TransactionFilterBar>
          ) : (
            <StatementsList />
          )}
        </Stack>
        <InvoiceViewModal
          invoiceId={modalInvoiceId}
          onDismiss={onDismissModalInvoice}
          onSubmit={onSubmitModalInvoice}
        />
        <AllocationViewModal
          allocationId={modalAllocationId}
          onDismiss={onDismissModalAllocation}
          onSubmit={onSubmitModalAllocation}
        />
      </Tile>
      {isStatementPrinting && <StatementPrint />}
    </>
  );
});
