import { memo, useEffect, useRef } from "react";

import { ITextField, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { nameOfFieldArray } from "@libs/utils/name-of.utils.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { Address } from "@stores/core/models/Address.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CountryPickerField } from "@ui-components/CountryPickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AddressFieldsLabels } from "./types/address-fields-labels.enum.ts";
import { AddressFieldsPlaceholders } from "./types/address-fields-placeholders.enum.ts";
import { Labels } from "./types/labels.enums.types.ts";
import { getAddressType } from "./utils/contact.utils.ts";

export interface AddressFieldsProps {
  name: string;
  frame?: boolean;
  required?: boolean;
  onRemove?: () => void;
  value: AddressFieldValue;
  focusFirstField?: boolean;
  disableRemoveLink?: boolean;
  onRenderFieldsetActions?: () => JSX.Element;
  onChangeCountry?: (country: Country) => void;
  hideLegendText?: boolean;
  useLabels?: boolean;
  street1InputOverride?: React.ReactNode;
}

export const AddressFields: React.FunctionComponent<AddressFieldsProps> = memo(
  ({
    name,
    value,
    frame,
    required,
    onRemove,
    useLabels,
    onChangeCountry,
    disableRemoveLink,
    focusFirstField = true,
    onRenderFieldsetActions,
    street1InputOverride,
    hideLegendText
  }) => {
    const { core, practice } = useStores();

    const halfWidthField = { root: { width: "50%" } };

    const fieldName = nameOfFieldArray<Address>(name);
    const isStateRequired = (value: string) =>
      !!core.ref.countries.values.find(
        c => c.code === value && c.isStateRequired
      );

    const isCityRequired = (value: string) =>
      !!core.ref.countries.values.find(
        c => c.code === value && !c.isStateRequired
      );

    const firstStreet = useRef<ITextField | null>(null);
    useEffect(() => {
      if (focusFirstField && firstStreet.current && value.isNew) {
        firstStreet.current.focus();
      }
    }, [focusFirstField, value.isNew]);

    const addressType = getAddressType(value);

    const showLabelOrPlaceholder = (name: string) => {
      if (
        (name === "Street" || name === "Address1") &&
        !!street1InputOverride
      ) {
        return;
      }
      return useLabels
        ? {
            label: AddressFieldsLabels[name]
          }
        : {
            placeholder: AddressFieldsPlaceholders[name]
          };
    };

    return (
      <Fieldset
        frame={frame}
        legend={
          hideLegendText
            ? undefined
            : practice.ref.addressTypes.get(addressType)?.text
        }
        hasAsterisk={required}
        actionButton={
          onRemove && {
            text: !disableRemoveLink ? Labels.remove : "",
            props: { onClick: () => onRemove && onRemove() },
            onRender: (props, defaultRender) => {
              if (!props || !defaultRender) {
                return null;
              }
              return (
                <Stack horizontal tokens={{ childrenGap: 24 }}>
                  {onRenderFieldsetActions && onRenderFieldsetActions()}
                  {defaultRender(props.text, {
                    onClick: () => onRemove && onRemove()
                  })}
                </Stack>
              );
            }
          }
        }
      >
        {!!street1InputOverride ? (
          street1InputOverride
        ) : (
          <TextInputField
            required={required}
            componentRef={(ref: ITextField) => {
              firstStreet.current = ref;
            }}
            name={fieldName("street1")}
            {...showLabelOrPlaceholder(
              value.country === Country.NewZealand ? "Street" : "Address1"
            )}
            autoFocus
            parse={(value: string) => value ?? ""}
          />
        )}
        <TextInputField
          name={fieldName("street2")}
          {...showLabelOrPlaceholder(
            value.country === Country.NewZealand ? "BuildingName" : "Address2"
          )}
        />
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            name={fieldName("suburb")}
            {...showLabelOrPlaceholder("Suburb")}
            styles={halfWidthField}
          />

          <FieldCondition when={fieldName("country")} is={isStateRequired}>
            <DropdownField
              name={fieldName("state")}
              key="state"
              {...showLabelOrPlaceholder("State")}
              options={core.ref.australianStates.keyTextValues}
              fieldItemStyles={halfWidthField}
            />
          </FieldCondition>
          {onChangeCountry && (
            <FieldSpy name={fieldName("country")} onChange={onChangeCountry} />
          )}
          <FieldCondition when={fieldName("country")} is={isCityRequired}>
            <TextInputField
              required={required}
              name={fieldName("city")}
              {...showLabelOrPlaceholder("City")}
              styles={halfWidthField}
            />
          </FieldCondition>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            required={
              required && core.tenantDetails?.country !== Country.NewZealand
            }
            name={fieldName("postCode")}
            {...showLabelOrPlaceholder("Postcode")}
            styles={halfWidthField}
          />
          <CountryPickerField
            required={required}
            name={fieldName("country")}
            {...showLabelOrPlaceholder("Country")}
            calloutProps={{
              calloutMaxHeight: 500
            }}
            fieldItemStyles={halfWidthField}
          />
        </Stack>
      </Fieldset>
    );
  }
);
