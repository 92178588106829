import { observer } from "mobx-react-lite";
import React from "react";

import {
  Card,
  CardProps,
  CollapsibleCard,
  CollapsibleCardProps,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  IContextualMenuItem,
  LabeledText,
  LabeledTextGroup,
  SplitButton,
  SplitButtonProps,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { BenefitSchedule } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";
import { LastUpdatedText } from "@ui-components/last-updated/LastUpdatedText.tsx";

import { AccScheduleContractInfo } from "./AccScheduleContractInfo.tsx";
import { getScheduleStatus } from "./utils.ts";

interface ScheduleTileProps {
  schedule: Schedule;
  buttonProps?: Omit<SplitButtonProps, "items"> & {
    items?: IContextualMenuItem[];
  };
  collapsable?: boolean;
}

export const ScheduleCard: React.FC<ScheduleTileProps> = observer(
  ({ schedule, buttonProps, collapsable }) => {
    const cardContent = (
      <Stack
        tokens={{ childrenGap: 16 }}
        {...dataAttribute(DataAttributes.Element, `schedule-${schedule.id}`)}
      >
        <Stack tokens={{ childrenGap: 8 }}>
          <LabeledTextGroup
            gap={24}
            horizontalColumnCount={2}
            styles={{ root: { justifyContent: "start" } }}
          >
            <LabeledText
              noSemicolon
              label="Fees"
              children={
                schedule.benefitSchedule === BenefitSchedule.ACC
                  ? `${schedule.activeBenefitFees} standard fees, ${schedule.activeNonBenefitFees} non-standard fees`
                  : schedule.activeFees
              }
            />
            {schedule.description && (
              <LabeledText
                noSemicolon
                wrap
                label="Description"
                children={schedule.description}
              />
            )}
          </LabeledTextGroup>
          {schedule.benefitSchedule === BenefitSchedule.ACC && (
            <AccScheduleContractInfo />
          )}
        </Stack>
        {schedule.changeLog && (
          <LastUpdatedText
            changeLog={schedule.changeLog}
            lastUpdatedUserId={schedule.updatedByUserId}
          />
        )}
      </Stack>
    );

    const collapsableStyles = {
      collapseContent: { padding: 24 }
    };

    const cardStyles = {
      subComponentStyles: {
        tile: { content: { padding: 24 } }
      }
    };

    const cardProps: CardProps | CollapsibleCardProps = {
      styles: collapsable ? collapsableStyles : cardStyles,
      heading: schedule.name,
      headingLevel: "section-heading",
      subHeading: getScheduleStatus(schedule),
      button: buttonProps && (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          {schedule.isInactive && (
            <TextBadge
              badgeSize={TextBadgeSize.medium}
              badgeColor={TextBadgeColor.grey}
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: 4 }}
            >
              Inactive
            </TextBadge>
          )}
          {buttonProps.items?.length ? (
            <SplitButton
              {...buttonProps}
              items={buttonProps.items}
              splitButtonMenuProps={{
                ...buttonProps.splitButtonMenuProps,
                ...dataAttribute(
                  DataAttributes.Element,
                  `schedule-tile-button-split-${schedule.id}`
                )
              }}
              onClick={e => {
                e.stopPropagation();
                buttonProps.onClick && buttonProps.onClick(e);
              }}
            />
          ) : (
            <DefaultButton
              {...buttonProps}
              onClick={e => {
                e.stopPropagation();
                buttonProps.onClick && buttonProps.onClick(e);
              }}
            />
          )}
        </Stack>
      ),
      children: cardContent,
      openOnRender: true
    };

    return (
      <>
        {collapsable ? (
          <CollapsibleCard {...cardProps} />
        ) : (
          <Card {...cardProps} />
        )}
      </>
    );
  }
);
