import { memo, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  mergeStyles,
  NoDataTile,
  Stack,
  Text
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { AllocationViewContext } from "@modules/billing/screens/allocation/context/AllocationViewContext.tsx";
import { AllocationsList } from "@modules/billing/screens/shared-components/AllocationList/AllocationsList.tsx";
import { TransactionAdjustedBar } from "@modules/billing/screens/shared-components/TransactionAdjustedBar.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  getAllocations,
  sortAllocationByInvoiceNumberAndStatus
} from "../utils.ts";
import { AllocationFormLabels } from "./AllocationForm.types.tsx";
import { AllocationViewTotalDetails } from "./AllocationViewTotalDetails.tsx";
import { BillToTile } from "./BillToTile.tsx";

interface AllocationViewProps {
  hideInfoBar?: boolean;
}

export const AllocationView: React.FC<AllocationViewProps> = memo(
  ({ hideInfoBar }) => {
    const { routing } = useStores();
    const accountId =
      routing.queryStringParam(routes.accounts.queryKeys.accountId) ??
      undefined;

    const { allocation, unallocatedCredit } = useContext(AllocationViewContext);

    const allocations = getAllocations(allocation).sort(
      sortAllocationByInvoiceNumberAndStatus
    );

    const gridStyles = {
      grid: {
        display: "grid",
        gap: "60px",
        gridTemplateColumns: "348px 1fr"
      },
      firstColumn: {
        height: "fit-content"
      },
      secondColumn: {
        display: "grid",
        gap: "16px 8px",
        gridTemplateColumns: " 258px 1fr",
        height: "fit-content"
      },
      fullRow: {
        gridColumn: "1 / 3"
      }
    };

    return (
      <Stack tokens={{ childrenGap: 16 }}>
        {!hideInfoBar && (
          <TransactionAdjustedBar transaction={allocation} name="allocation" />
        )}
        <div className={mergeStyles(gridStyles.grid)}>
          <div className={mergeStyles(gridStyles.firstColumn)}>
            <div>
              <Heading labelPaddings>{AllocationFormLabels.location}</Heading>
              <Text>{allocation.location}</Text>
            </div>
            <Stack tokens={{ childrenGap: 5 }}>
              <Heading
                styles={{ root: { padding: "5px 0" } }}
                variant="section-heading-light"
              >
                {AllocationFormLabels.billTo}
              </Heading>
              <BillToTile accountContactId={allocation.accountId} />
            </Stack>
          </div>
          <div className={mergeStyles(gridStyles.secondColumn)}>
            {allocation.comment && (
              <div className={mergeStyles(gridStyles.fullRow)}>
                <Heading labelPaddings>{AllocationFormLabels.comments}</Heading>
                <Text>{allocation.comment}</Text>
              </div>
            )}
            <AllocationViewTotalDetails />
            {allocation.reference && (
              <div className={mergeStyles(gridStyles.fullRow)}>
                <Heading labelPaddings>
                  {AllocationFormLabels.allocationReferenceNumber}
                </Heading>
                <Text>{allocation.reference}</Text>
              </div>
            )}
          </div>
        </div>
        <Stack>
          {allocation.items.length > 0 ? (
            <Stack>
              <Stack.Item>
                <div
                  {...dataAttribute(
                    DataAttributes.Element,
                    "original-payment-list"
                  )}
                  style={{ marginTop: "40px" }}
                >
                  <AllocationsList
                    allocations={allocations}
                    hideOwing
                    unallocatedAmount={unallocatedCredit || 0}
                    columnOptions={{
                      filtersToShow: {
                        comment: allocations.some(x => !!x.comment)
                      }
                    }}
                    accountId={accountId}
                  />
                </div>
              </Stack.Item>
            </Stack>
          ) : (
            <NoDataTile
              dataAttr="allocations-no-funds"
              textProps={{
                text: "No funds allocated in this allocation"
              }}
              linkProps={{ hidden: true }}
            />
          )}
        </Stack>
      </Stack>
    );
  }
);
