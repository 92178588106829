import { useForm, useFormState } from "react-final-form";

import { CollapsibleCard, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import {
  ClinicalFlagsFormValues,
  flagsNameOf
} from "./ClinicalFlagForm.types.ts";

export const RedFlagsCard: React.FunctionComponent = () => {
  const form = useForm<ClinicalFlagsFormValues>();
  const { values } = useFormState<ClinicalFlagsFormValues>({
    subscription: { values: true }
  });

  const { clinical } = useStores();

  const redFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isRed)
    .map(({ code, clinicalFlagText }) => ({
      value: code,
      label: clinicalFlagText
    }));

  return (
    <div id="RedFlags">
      <CollapsibleCard
        heading="Red flags"
        headingLevel="section-heading"
        iconName="BpRedFlagFilled"
        openOnRender
      >
        <Stack tokens={{ childrenGap: 8 }}>
          <ToggleField
            name={flagsNameOf("redFlagsAllClear")}
            label="No abnormalities detected (NAD)"
            inlineLabel
            styles={{
              root: {
                margin: 0
              }
            }}
          />
          <CheckboxGroupField
            name={flagsNameOf("redFlags")}
            options={redFlagOptions}
            disabled={!!values.redFlagsAllClear}
          />
          <FieldSpy
            name={flagsNameOf("redFlagsAllClear")}
            onChange={(value: boolean) => {
              if (value) {
                if (form) {
                  form.change(flagsNameOf("redFlags"), undefined);
                }
              }
            }}
          />
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
