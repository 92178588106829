import {
  dataAttribute,
  DataAttributes,
  Heading,
  IStyle,
  ITextProps,
  ITheme,
  mergeStyles,
  PersonaSize,
  Stack,
  Text
} from "@bps/fluent-ui";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";

export interface ProviderDetailsProps {
  providerId?: string;
  additionalStyles?: IStyle;
  locationId?: string;
}

const personaSummarySecondaryStyle = (_props: ITextProps, theme: ITheme) => ({
  root: {
    fontSize: theme.fonts.small.fontSize,
    fontWeight: theme.fonts.small.fontWeight,
    color: theme.palette.neutralTertiary,
    textOverflow: "hidden"
  }
});

export const ProviderDetails: React.FC<ProviderDetailsProps> = (
  props
): JSX.Element => {
  const { core } = useStores();
  const noProviderMessage: JSX.Element = (
    <Text
      variant="small"
      styles={{
        root: {
          overflow: "hidden"
        }
      }}
    >
      No provider selected
    </Text>
  );

  const locationName =
    props?.locationId && core.getLocationName(props.locationId);

  const providerPersona = (provider: User): JSX.Element => {
    return (
      <Persona
        text={`${provider.firstName} ${provider.lastName}`}
        id={provider.id}
        size={PersonaSize.size40}
        imageInitials={provider.initials}
        styles={{
          details: {
            paddingRight: 0
          },
          root: { marginTop: 5, paddingLeft: 5, width: "100%" }
        }}
        onRenderSecondaryText={() => (
          <Stack>
            <Text styles={personaSummarySecondaryStyle}>
              {locationName ?? core.location.name}
            </Text>
          </Stack>
        )}
      />
    );
  };

  return (
    <Stack.Item
      className={mergeStyles(props.additionalStyles)}
      {...dataAttribute(DataAttributes.Element, "provider-details")}
    >
      <Heading>{InvoiceFormLabels.provider}</Heading>
      <DataFetcher<User | undefined>
        fetch={x => {
          return props.providerId
            ? x.core.getUser(props.providerId)
            : Promise.resolve(undefined);
        }}
        key={props.providerId}
      >
        {provider => {
          return (
            <>{provider ? providerPersona(provider) : noProviderMessage}</>
          );
        }}
      </DataFetcher>
    </Stack.Item>
  );
};
