import { Field } from "react-final-form";

import { dataAttribute, DataAttributes, Stack } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

import { RelationshipFieldsContact } from "./RelationshipFieldsContact.tsx";
import { RelationshipFieldsOrg } from "./RelationshipFieldsOrg.tsx";
import { RelationshipFieldValue } from "./utils.ts";

type RelationshipFieldsProps = {
  name: string;
  onRemove: () => void;
  fullName: string | undefined;
};

export const RelationshipFields: React.FC<RelationshipFieldsProps> = ({
  name,
  onRemove,
  fullName
}) => {
  const fieldName = (key: keyof RelationshipFieldValue) => `${name}.${key}`;
  return (
    <Stack
      tokens={{ childrenGap: 8 }}
      {...dataAttribute(DataAttributes.Element, "relationship-field")}
    >
      <Field<ContactType>
        name={fieldName("type")}
        subscription={{ value: true }}
      >
        {({ input: { value: type } }) =>
          type !== ContactType.Organisation ? (
            <RelationshipFieldsContact
              fullName={fullName}
              onRemove={onRemove}
              name={name}
            />
          ) : (
            <RelationshipFieldsOrg
              fullName={fullName}
              onRemove={onRemove}
              name={name}
            />
          )
        }
      </Field>
    </Stack>
  );
};
