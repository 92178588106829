import { FC } from "react";
import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { formDateInputStyles } from "../../SchedulesScreen.styles.ts";
import { FeeFormLabels, FeeFormValues } from "../ScheduleForm.types.tsx";

const nameOf = nameOfFactory<FeeFormValues>();

export const FeeFormNewFeeDetailFields: FC = () => {
  const { input: endDateInput } = useField(nameOf("feeNextEffectiveDate"), {
    subscription: { value: true }
  });

  const {
    input: { value: feeIsNextActiveValue }
  } = useField(nameOf("feeNextIsActive"), {
    subscription: { value: true }
  });

  return (
    <Stack tokens={{ childrenGap: 8 }} styles={{ root: { maxHeight: 286 } }}>
      {/* width of fee input to match start date field below */}
      <Stack.Item styles={{ root: { width: 150 } }}>
        <SpinNumberInputField
          required
          name={nameOf("feeFee")}
          label={FeeFormLabels.fee}
          prefix="$"
          step={0.01}
          precision={2}
        />
      </Stack.Item>

      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <DatePickerField
          label={FeeFormLabels.startDate}
          name={nameOf("feeEffectiveDate")}
          styles={formDateInputStyles}
          required
        />

        <DatePickerField
          label="To"
          name={nameOf("feeNextEffectiveDate")}
          disabled={feeIsNextActiveValue}
          styles={formDateInputStyles}
        />

        <ToggleField
          label={FeeFormLabels.ongoing}
          inlineLabel
          name={nameOf("feeNextIsActive")}
          styles={{
            root: { margin: 0, paddingTop: 30, alignSelf: "flex-start" },
            label: { cursor: "pointer", fontWeight: 400 }
          }}
        />
        <FieldSpy
          name={nameOf("feeNextIsActive")}
          onChange={(isOngoing: boolean) => {
            if (isOngoing) {
              endDateInput.onChange(undefined);
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
