import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { CommsConfirmationCampaignFormValues } from "./CommsConfirmationCampaignFormValues.ts";

export class CommsConfirmationCampaignFormValidator extends Validator<CommsConfirmationCampaignFormValues> {
  constructor() {
    super();

    this.forField("name", [required()]);
    this.forField("newAppointmentCampaignId", [required()]);
    this.forField("rescheduleAppointmentCampaignId", [required()]);
    this.forField("cancelAppointmentCampaignId", [required()]);
  }
}
