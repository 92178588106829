export enum MedicalHistoryStatus {
  Active = "active",
  Inactive = "inactive"
}

export enum MedicalHistoryDialogLabels {
  EditMedicalHistory = "Edit medical history",
  AddToMedicalHistory = "Add to medical history",
  DiagnosisProcedure = "Diagnosis / Procedure",
  Side = "Side",
  PrimaryDiagnosis = "Primary diagnosis",
  SaveAsReasonForConsult = "Save as reason for consult",
  ClinicallySignificant = "Clinically significant",
  Confidential = "Confidential",
  Certainty = "Certainty",
  Details = "Details",
  ReasonForConsult = "Reason for consult",
  Severity = "Severity",
  Course = "Course",
  Fracture = "Fracture"
}
