import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { FormDesignFieldGroup } from "@shared-types/form-design/form-design-field-group.interface.ts";
import { FormDesignValues } from "@shared-types/form-design/form-design-form-values.interface.ts";

export class FormDesignModelValidator extends Validator<FormDesignValues> {
  constructor(existingNames: string[]) {
    super();

    this.forField("name", [
      required(),
      maxLength(50),
      name => {
        return existingNames
          .map(name => name.toLowerCase())
          .includes(name.toLowerCase())
          ? "Duplicate"
          : undefined;
      }
    ]);

    this.forField("title", [required(), maxLength(100)]);

    this.forField("description", [maxLength(100)]);

    this.forField("usedGroups", [
      items => {
        // Currently this validation message is not displayed anywhere, but needs to be here to prevent the form submission.
        if (
          items &&
          (items.length === 0 ||
            items.filter(item => item.domain && item.field).length === 0)
        ) {
          return ValidationMessages.required;
        }
        return undefined;
      },
      required()
    ]);

    this.forArrayField("usedGroups", [
      (group: FormDesignFieldGroup) => {
        if (group.fieldOptions.length === 0) {
          return undefined;
        }

        const options = group.fieldOptions[0];

        if (
          options.userCanSetMinMax &&
          options.min &&
          options.max &&
          options.min >= options.max
        ) {
          return { field: ValidationMessages.invalidMinMax };
        }

        if (
          options.userCanChangeLabel &&
          (!options?.label || options.label.length === 0)
        ) {
          if (options.userCanAddChoices) {
            return { field: ValidationMessages.optionsRequired };
          }

          return {
            field: options.noDataEntry
              ? ValidationMessages.textRequired
              : ValidationMessages.questionRequired
          };
        }
        if (options?.formChoices) {
          const choices = options.formChoices.filter(
            option => option.text && option.text.trim().length !== 0
          ).length;

          if (choices < 2) return { field: ValidationMessages.optionsRequired };
        }
        return undefined;
      },
      required()
    ]);
  }
}
