import { stringify } from "query-string";
import { memo, ReactNode } from "react";
import { LinkProps } from "react-router-dom";

import { Grid, GridItem, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ItemType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceAllocationStatuses } from "@shared-types/billing/invoice-allocation-statuses.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { InvoiceListItem, ReferenceLink } from "./Invoice.types.ts";
import {
  allocationStatusesHierarchy,
  getInvoiceAllocationStatusColors
} from "./utils.ts";

interface InvoiceItemReferenceCellProps {
  item: Pick<InvoiceListItem, "references" | "statuses">;
  accountId?: string;
  name: keyof Pick<InvoiceListItem, "references" | "statuses">;
}

export const InvoiceItemStatusReferenceCell: React.FC<InvoiceItemReferenceCellProps> =
  memo(({ item, accountId, name }) => {
    const { routing } = useStores();
    const theme = useTheme();

    const isReferenceLink = (
      val: ReferenceLink | InvoiceAllocationStatuses
    ): val is ReferenceLink => typeof val !== "string";

    const isInvoiceAllocationStatuses = (
      val: ReferenceLink | InvoiceAllocationStatuses
    ): val is InvoiceAllocationStatuses => typeof val === "string";

    const getRefContent = (reference: ReferenceLink) => {
      const path: LinkProps["to"] = {
        pathname: reference.path,
        search: accountId ? stringify({ accountId }) : undefined
      };
      return (
        <Navigate state={routing.getRouteState()} to={path}>
          {reference.number}
        </Navigate>
      );
    };

    const allocationItem = item.references?.find(
      reference => reference.transaction?.itemType === ItemType.Allocation
    );

    const getStatusContent = (status: InvoiceAllocationStatuses) => {
      const isPaid = status === InvoiceAllocationStatuses.paid;

      const dateForPrevPaidInvoices = isPaid
        ? DateTime.fromISO(
            allocationItem?.transaction.transactionDate
          )?.toDayDefaultFormat()
        : undefined;

      const prevPaidInvoicesStyles =
        dateForPrevPaidInvoices &&
        item.statuses?.includes(InvoiceAllocationStatuses.credited);
      return (
        <Stack>
          <Text
            styles={{
              root: {
                color: getInvoiceAllocationStatusColors(status, theme),
                fontStyle: prevPaidInvoicesStyles ? "italic" : undefined,
                background: prevPaidInvoicesStyles
                  ? theme.semanticColors.disabledBackground
                  : undefined
              }
            }}
            bold
          >
            {status}
            &nbsp;
            {prevPaidInvoicesStyles
              ? `(${dateForPrevPaidInvoices})`
              : undefined}
          </Text>
        </Stack>
      );
    };

    const getEmptyListItem = (key: string) => <GridItem key={key} />;

    const items: Array<ReactNode> = [];

    const insertOwingRow = () => {
      const hasOwingStatus = item.statuses?.find(
        x => x === InvoiceAllocationStatuses.owing
      );
      if (hasOwingStatus) {
        items.push(getEmptyListItem("owing"));
      }
    };

    item[name]
      ?.slice()
      ?.sort(
        (
          a: ReferenceLink | InvoiceAllocationStatuses,
          b: ReferenceLink | InvoiceAllocationStatuses
        ) => {
          if (
            isInvoiceAllocationStatuses(a) &&
            isInvoiceAllocationStatuses(b)
          ) {
            return (
              allocationStatusesHierarchy(a) - allocationStatusesHierarchy(b)
            );
          }
          return -1;
        }
      )
      .forEach(
        (x: ReferenceLink | InvoiceAllocationStatuses, index: number) => {
          if (isReferenceLink(x)) {
            if (index === 0) {
              insertOwingRow();
            }
          }

          items.push(
            <GridItem
              styles={{
                root: {
                  textAlign: isReferenceLink(x) ? "right" : "initial"
                }
              }}
              key={x.toString()}
              row="span 1"
            >
              {isReferenceLink(x) ? getRefContent(x) : getStatusContent(x)}
            </GridItem>
          );
        }
      );

    return <Grid styles={{ root: { gridAutoRows: "19px" } }}>{items}</Grid>;
  });
