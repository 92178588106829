import { memo } from "react";

import { ConfirmDialog, Heading } from "@bps/fluent-ui";

interface DeleteAppointmentCancellationReasonDialogProps {
  hidden?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  reason?: string;
}

export const DeleteAppointmentCancellationReasonDialog: React.FunctionComponent<DeleteAppointmentCancellationReasonDialogProps> =
  memo(({ hidden, reason, onCancel, onConfirm }) => {
    return (
      <ConfirmDialog
        hidden={hidden}
        confirmButtonProps={{ text: "Delete reason" }}
        cancelButtonProps={{ text: "Cancel" }}
        onConfirm={onConfirm}
        onCancel={onCancel}
        dialogContentProps={{
          title: (
            <Heading variant="modal-heading">
              Confirm cancellation reason deletion
            </Heading>
          ),
          showCloseButton: true
        }}
      >
        Do you want to permanently delete the cancellation reason "{reason}"?
      </ConfirmDialog>
    );
  });
