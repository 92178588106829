import { DateTime, upsertItem } from "@bps/utils";
import { EMPTY_GUID } from "@libs/constants/constants.ts";
import {
  ClinicalActivityClinicalDataDto,
  ClinicalActivityClinicalDataItemDto,
  ClinicalActivityMetadataItem,
  ClinicalTaskClinicalDataDto,
  ClinicalTaskClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { ClinicalTaskFormValues } from "@shared-types/clinical/clinical-task-values.type.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";

import { ClinicalActivityType } from "../clinical-activity/types/clinical-activity.type.ts";
import { ClinicalTaskFormModel } from "./ClinicalTaskFormModel.ts";
import { ClinicalTaskDue } from "./types/clinical-task-due.enum.ts";
import { findRelatedClinicalDataItem } from "./utils.ts";

export class PatientClinicalTaskFormModel extends ClinicalTaskFormModel {
  constructor(
    private clinicalRecord: ClinicalRecord,
    clinicalTask?: ClinicalTask
  ) {
    super(clinicalRecord.clinical, clinicalRecord.core, clinicalTask);
  }

  get acc() {
    return this.clinicalRecord.acc;
  }

  public onSubmit = async (values: ClinicalTaskFormValues) => {
    const clinicalTask: ClinicalTaskClinicalDataDto = this.clinicalRecord
      .clinicalData?.clinicalTask || { clinicalTasks: [], eTag: undefined };

    let { dueInVisits, remainingVisits, dueDate, isSystemGenerated } = values;

    if (values.dueChoice === ClinicalTaskDue.Date) {
      dueInVisits = undefined;
      remainingVisits = undefined;
    } else {
      dueDate = undefined;

      const storedClinicalTask = clinicalTask.clinicalTasks?.find(
        x => x.id === values.id
      );
      if (storedClinicalTask) {
        isSystemGenerated = storedClinicalTask.isSystemGenerated;
        if (storedClinicalTask.dueInVisits !== values.dueInVisits)
          remainingVisits = values.dueInVisits;
      } else {
        remainingVisits = values.dueInVisits;
        isSystemGenerated = false;
      }
    }

    const dueDateVal = dueDate ? DateTime.jsDateToISODate(dueDate) : undefined;

    const newClinicalTask = !values.id;

    const item: ClinicalTaskClinicalDataItemDto = {
      id: values.id ?? EMPTY_GUID,
      patientId: this.clinicalRecord.id,
      taskType: values.taskType,
      dueDate: dueDateVal,
      dueInVisits,
      remainingVisits,
      priority: values.priority,
      comment: values.comment,
      lockedBy: values.lockedBy,
      isCompleted: values.isCompleted,
      isSystemGenerated,
      claimNumber: values.claimNumber,
      businessRole: values.businessRole,
      secGroupId: values.confidential
        ? this.clinicalRecord.core.user?.privateSecGroupId
        : undefined
    };

    const clinicalActivity: ClinicalActivityClinicalDataDto = this
      .clinicalRecord.clinicalData?.clinicalActivity || {
      clinicalActivities: [],
      eTag: undefined
    };

    const clinicalData = {
      clinicalTask,
      clinicalActivity
    };

    let associatedClinicalActivity:
      | ClinicalActivityClinicalDataItemDto
      | undefined;

    // Temp: Also create a clinical notification item for the clinical data if they've got the permissions
    if (
      this.clinicalRecord.core.hasPermissions([
        Permission.ClinActivityRead,
        Permission.ClinActivityWrite
      ])
    ) {
      // Check if there's an already existing clinical notification.
      if (!newClinicalTask) {
        const associatedActivity = findRelatedClinicalDataItem(
          item,
          this.clinicalRecord.clinical.activityDescriptionMapValues,
          clinicalActivity.clinicalActivities
        );

        if (associatedActivity) {
          associatedClinicalActivity = associatedActivity;
        }
      }

      const convertedActivity = this.updateClinicalDataActivityBasedOnTask(
        item,
        associatedClinicalActivity
      );

      if (convertedActivity) {
        clinicalData.clinicalActivity = {
          ...clinicalActivity,
          clinicalActivities: upsertItem({
            item: convertedActivity,
            array: clinicalActivity.clinicalActivities ?? [],
            predicate: x => x.id === convertedActivity.id
          })
        };
      }
    }

    clinicalData.clinicalTask = {
      ...clinicalTask,
      clinicalTasks: upsertItem({
        item,
        array: clinicalTask.clinicalTasks ?? [],
        predicate: x => x.id === values.id
      })
    };

    await this.clinicalRecord.saveClinicalData(clinicalData);

    this.clinicalRecord.setPatientClinicalTasksPromise();
    this.clinicalRecord.loadClinicalActivities();
  };

  private updateClinicalDataActivityBasedOnTask = (
    clinicalTask: ClinicalTaskClinicalDataItemDto,
    associatedActivity?: ClinicalActivityClinicalDataItemDto
  ): ClinicalActivityClinicalDataItemDto | undefined => {
    const newMetadata: ClinicalActivityMetadataItem[] = [];

    // TEMP - Map the metadata
    if (clinicalTask.claimNumber) {
      newMetadata.push({ key: "ClaimNumber", value: clinicalTask.claimNumber });
    }

    const description = this.matchTaskTypeWithActivityDescription(
      clinicalTask.taskType,
      this.clinicalRecord.clinical.activityDescriptionMapValues
    );

    if (!description) {
      return undefined;
    }

    const noMatchingDescription = description.isOther;

    const result: ClinicalActivityClinicalDataItemDto = {
      id: associatedActivity?.id ?? EMPTY_GUID,
      descriptionId: description.id,
      activityPriority: clinicalTask.priority,
      activityType: ClinicalActivityType.Task,
      activityStatus: clinicalTask.isCompleted
        ? ClinicalActivityStatus.Completed
        : ClinicalActivityStatus.InProgress,
      patientId: clinicalTask.patientId,
      dueDate: clinicalTask.dueDate,
      dueInVisits: clinicalTask.dueInVisits,
      remainingVisits: clinicalTask.remainingVisits,
      comment: clinicalTask.comment,
      lockedBy: clinicalTask.lockedBy,
      deletedComment: clinicalTask.deletedComment,
      isSystemGenerated: clinicalTask.isSystemGenerated,
      businessRole: clinicalTask.businessRole,
      secGroupId: clinicalTask.secGroupId,
      completedBy: clinicalTask.completedBy,
      completedDate: clinicalTask.completedDate,
      freeText: noMatchingDescription ? clinicalTask.taskType : undefined,
      metadata: newMetadata,
      userId: this.clinicalRecord.core.userId
    };

    return result;
  };
}
