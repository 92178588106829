import { observer } from "mobx-react-lite";
import React from "react";

import { QuestionnaireDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import {
  ClinicalToolAnswer,
  findClinicalToolAnswer
} from "../utils/ClinicalToolAnswerFinder.ts";
import { K10Module } from "./K10Module.tsx";

interface K10QuestionnaireFormProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  summary?: string;
  viewOnly?: boolean;
  width: number;
}
const BREAKPOINT = 870;

export const K10Questions: React.FunctionComponent<K10QuestionnaireFormProps> =
  observer(({ questionnaire, answers, width, summary, viewOnly }) => {
    const isSemiFullWidth = width < BREAKPOINT;

    const findAnswer = (questionId: string): string => {
      let answerValue = "";
      if (answers) {
        const answer = findClinicalToolAnswer(questionId, answers);
        if (answer) answerValue = answer.answerValue!;
      }
      return answerValue;
    };
    return (
      <K10Module
        questions={questionnaire.items}
        answers={answers}
        findAnswer={findAnswer}
        verticalAlignment={true}
        heading="Kessler Psychological Distress Scale"
        isShortenQuestions={isSemiFullWidth}
        summary={summary}
        viewOnly={viewOnly}
      />
    );
  });
