import { observer } from "mobx-react-lite";

import { Heading, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";
import { AccidentLocationSelectField } from "./AccidentLocationSelectField.tsx";
import { SportingInjuryFields } from "./SportingInjuryFields.tsx";
import { WorkInjuryField } from "./WorkInjuryField.tsx";

const AccidentFieldsBase = observer(() => {
  const { acc } = useStores();

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Heading variant="section-heading-light">
        {ClaimFormLabels.accident}
      </Heading>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <DatePickerField
          styles={{ root: { maxWidth: 122 } }}
          name={claimFormNameOf("accidentDate")}
          label={ClaimFormLabels.date}
          required
          maxDate={DateTime.jsDateNow()}
        />
        <OptionsSelectField
          name={claimFormNameOf("accidentSceneCode")}
          label={ClaimFormLabels.sceneOfAccident}
          options={acc.ref.accidentScenes.keyTextValues}
          required
          fieldItemStyles={{ root: { flex: 1 } }}
          placeholder={ClaimFormLabels.sceneOfAccidentPlaceholder}
        />
      </Stack>
      <AccidentLocationSelectField />
      <TextInputField
        required
        label={ClaimFormLabels.describeHowTheAccidentOccurred}
        name={claimFormNameOf("causeOfAccident")}
        multiline
        resizable={false}
        rows={4}
        maxLength={255}
        placeholder={ClaimFormLabels.accidentDescriptionPlaceholder}
      />
      <BoolButtonGroupField
        label={ClaimFormLabels.medicalTreatmentInjury}
        name={claimFormNameOf("medicalTreatmentInjury")}
      />
      <BoolButtonGroupField
        label={ClaimFormLabels.involvesVehicle}
        name={claimFormNameOf("involvesVehicle")}
        required
      />
      <WorkInjuryField />
      <SportingInjuryFields />
    </Stack>
  );
});

export const AccidentFields = withFetch(
  x => [x.acc.ref.accidentScenes.load()],
  AccidentFieldsBase
);
