import {
  greaterThan,
  integer,
  lessThan,
  maxLength,
  predicate,
  required,
  url
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  BHB_ONLINE_SETTINGS_DISABILITY_MAX_CHARACTERS,
  BHB_ONLINE_SETTINGS_PARKING_MAX_CHARACTERS,
  BHB_ONLINE_SETTINGS_POLICY_MAX_CHARACTERS,
  BhbOnlineSettingsFormValues,
  onlineSettingsNameOf
} from "../online-settings/BhbOnlineSettings.types.ts";

export class BhbOnlineSettingsPracticeProfileValidator extends Validator<BhbOnlineSettingsFormValues> {
  constructor() {
    super();
    this.forField(onlineSettingsNameOf("url"), [
      predicate((val: string) => val?.length > 0, url())
    ]);
    this.forField(onlineSettingsNameOf("policy"), [
      maxLength(BHB_ONLINE_SETTINGS_POLICY_MAX_CHARACTERS)
    ]);
    this.forField(onlineSettingsNameOf("parkingAccess"), [
      maxLength(BHB_ONLINE_SETTINGS_PARKING_MAX_CHARACTERS)
    ]);
    this.forField(onlineSettingsNameOf("disabilityAccess"), [
      maxLength(BHB_ONLINE_SETTINGS_DISABILITY_MAX_CHARACTERS)
    ]);
    this.forField(onlineSettingsNameOf("minimumTimeToCancelValue"), [
      predicate(x => x !== 0, required()),
      integer(),
      greaterThan(0),
      lessThan(90)
    ]);
    this.forField(onlineSettingsNameOf("minimumTimeToBookValue"), [
      predicate(x => x !== 0, required()),
      integer(),
      greaterThan(0)
    ]);
  }
}
