import { observer } from "mobx-react-lite";
import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useRef
} from "react";

import { Stack, Tile } from "@bps/fluent-ui";
import { BillingScreenContext } from "@modules/billing/screens/shared-components/context/BillingScreenContext.ts";
import { BillingScreenHelper } from "@modules/billing/screens/shared-components/context/BilllingScreenHelper.ts";

import { BillingScreenTabs } from "../billing-history/components/BillingScreenTabs.tsx";
import { InvoiceViewModal } from "../invoice/components/InvoiceViewModal.tsx";

interface BillingScreenWrapperProps
  extends PropsWithChildren<{
    headerButtons?: ReactElement;
  }> {}

export const BillingScreenWrapper: FunctionComponent<BillingScreenWrapperProps> =
  observer(({ children, headerButtons }) => {
    const helper = useRef(new BillingScreenHelper());

    return (
      <>
        <BillingScreenContext.Provider value={helper.current}>
          <Stack
            tokens={{ childrenGap: 8 }}
            styles={{
              root: {
                height: "100%",
                maxWidth: 1500,
                margin: "0 auto"
              }
            }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <BillingScreenTabs />
              {headerButtons}
            </Stack>
            <Tile
              styles={{
                root: { flexGrow: 1, padding: 16 },
                content: { position: "relative" }
              }}
            >
              <Stack
                styles={{ root: { height: "100%" } }}
                tokens={{ childrenGap: 8 }}
              >
                {children}
              </Stack>
            </Tile>
          </Stack>
        </BillingScreenContext.Provider>
        <InvoiceViewModal
          invoiceId={helper.current.modalInvoiceId}
          onDismiss={helper.current.onDismissModalInvoice}
          onSubmit={helper.current.onDismissModalInvoice}
        />
      </>
    );
  });
