import {
  ClinicalDataType,
  IntakeFlagRefDataDto,
  IntakeType,
  SubstanceUseTypeDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { GenericPickerField } from "@ui-components/form/GenericPickerField.tsx";
import { IntakeTypeText } from "@ui-components/RefText.tsx";

import { IntakeDomain, IntakeFormItem } from "./IntakeForm.types.ts";
import { getFilteredOptions } from "./utils.ts";

interface IntakeTypeFieldProps {
  value: IntakeFormItem;
  domain: IntakeDomain;
  index: number;
  name: string;
}

export const IntakeTypeField: React.FunctionComponent<IntakeTypeFieldProps> = ({
  value,
  domain,
  index,
  name
}) => {
  const { clinical } = useStores();

  if (!value.isEdit || value.id) {
    // view mode
    return <IntakeTypeText code={value.type} />;
  }

  if (domain !== ClinicalDataType.SubstanceUse) {
    // Tobacco and Alcohol type
    return (
      <DropdownField
        name={`${name}[${index}].type`}
        options={getFilteredOptions(
          clinical.ref.intakeTypes.values as IntakeFlagRefDataDto<IntakeType>[],
          domain
        )}
        fieldItemStyles={{ root: { flexGrow: 1 } }}
        styles={{ errorMessage: { whiteSpace: "break-spaces" } }}
      />
    );
  }

  // Substance type
  return (
    <GenericPickerField<SubstanceUseTypeDto>
      name={`${name}[${index}].type`}
      required={true}
      keyAccessor={dto => dto.code}
      inputProps={{
        placeholder: "Search type"
      }}
      onResolveSuggestionItem={dto => ({
        key: dto.code,
        name: dto.text
      })}
      onSearchItems={async (filter, previousResult) => {
        return clinical.getSubstanceUseTypes({
          search: filter,
          take: 50,
          skip: previousResult?.results?.length ?? 0,
          total: true
        });
      }}
      onFetchItem={key => {
        // since we have already the list of SubstanceUseTypes,
        // if one of the items onSearchItems not resolve we can get one from refData
        const { snomed, category, code, text, eTag } =
          clinical.ref.intakeTypes.get(key)!;
        return {
          // ⚠️ HACK SubstanceUseTypeDto has id, and refData not, we can use eTag
          // it is just TS fix
          id: eTag,
          snomed: snomed!,
          category: category!,
          code,
          text
        };
      }}
    />
  );
};
