import { isEqual } from "lodash";
import { useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  ActionButtonWithMaxedReached,
  AnimatedListWithKeys,
  dataAttribute,
  DataAttributes,
  Stack
} from "@bps/fluent-ui";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimsNonStandardBadge } from "@modules/acc/screens/shared-components/ClaimsNonStandardBadge.tsx";
import { DiagnosisHeader } from "@modules/acc/screens/shared-components/DiagnosisHeader.tsx";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import {
  getDiagnosisSideFromOldDiagnosisKey,
  getEmptyDiagnosis,
  getOldDiagnosisKeyByFormValues,
  mapToClaimDiagnosisFormValues,
  sideFilter
} from "../../utils.ts";
import {
  changeSideFormNameOf,
  claimAdjustmentFormNameOf
} from "../claim-adjustment.utils.ts";
import { ChangeDiagnosisDropDown } from "./ChangeDiagnosisPicker.tsx";

const findById = (id: string) => (v: { id: string }) => v.id === id;

enum ChangeSideFieldLabels {
  diagnosis = "Diagnosis",
  newSide = "New side",
  changeAnotherSide = "Change another side",
  noMoreDiagnosisLeft = "All diagnoses displayed"
}

export const ChangeSideField: React.FC = () => {
  const { values: formValues } = useFormState<ClaimAdjustmentFormValues>();
  const { change } = useForm<ClaimAdjustmentFormValues>();
  const { claim, getSelectedFormDiagnoses } = useContext(
    ClaimAdjustmentContext
  );

  const { acc } = useStores();

  const sideChanges = formValues.sideChanges || [];
  const currentDiagnoses = claim.currentDiagnoses.map(
    mapToClaimDiagnosisFormValues
  );

  const allSelectedDiagnoses = getSelectedFormDiagnoses(
    claim.currentDiagnoses,
    formValues
  );

  return (
    <FieldArray name={changeSideFormNameOf("sideChanges")}>
      {({ fields }) => {
        return (
          <Stack
            tokens={{
              childrenGap: 8
            }}
          >
            <Fieldset
              frame
              styles={{
                root: {
                  padding: 0,
                  paddingBottom: 8
                }
              }}
            >
              <DiagnosisHeader
                styles={{
                  root: {
                    paddingRight: 48
                  }
                }}
                showStatusCol={false}
                diagnosisColumnLabel={ChangeSideFieldLabels.diagnosis}
                sideColumnLabel={ChangeSideFieldLabels.newSide}
              />
              <AnimatedListWithKeys
                items={fields.value}
                onItemIdRemoved={id => {
                  const index = fields.value.findIndex(findById(id));
                  fields.remove(index);
                }}
              >
                {(id, onRemoveItem) => {
                  const index = fields.value.findIndex(findById(id));
                  if (index < 0) return null;

                  const sideItems = acc.ref.diagnosisSides.keyTextValues.filter(
                    sideFilter(
                      allSelectedDiagnoses,
                      Array.from(fields.value)[index],
                      true
                    )
                  );

                  const notACC32Acceptable = Array.from(
                    acc.accTerminologyMap.values()
                  ).some(
                    x =>
                      x.code === fields.value[index].diagnosisCode &&
                      !x.aCC32Acceptable
                  );

                  return (
                    <Stack
                      key={`${claimAdjustmentFormNameOf(
                        "sideChanges"
                      )}[${index}].diagnosisItem`}
                      tokens={{
                        padding: "0px 8px"
                      }}
                    >
                      <Stack
                        horizontal
                        verticalAlign="end"
                        tokens={{
                          childrenGap: 8
                        }}
                        {...dataAttribute(
                          DataAttributes.Element,
                          "side-change-field"
                        )}
                      >
                        <FieldSpy
                          isEqual={isEqual}
                          name={`${claimAdjustmentFormNameOf(
                            "sideChanges"
                          )}[${index}]`}
                          onChange={sideChange => {
                            if (!sideChange.oldDiagnosisKey) {
                              return;
                            }

                            const oldDiagnosisSide =
                              getDiagnosisSideFromOldDiagnosisKey(
                                sideChange.oldDiagnosisKey
                              );
                            if (oldDiagnosisSide === sideChange.diagnosisSide) {
                              change(
                                `${claimAdjustmentFormNameOf(
                                  "sideChanges"
                                )}[${index}].diagnosisSide` as any,
                                undefined
                              );
                            }
                          }}
                        />
                        <FieldSpy
                          name={`${claimAdjustmentFormNameOf(
                            "sideChanges"
                          )}[${index}].oldDiagnosisKey`}
                          onChange={key => {
                            const currDiagnosis = currentDiagnoses.find(
                              c => getOldDiagnosisKeyByFormValues(c) === key
                            );
                            change(
                              `${claimAdjustmentFormNameOf(
                                "sideChanges"
                              )}[${index}].diagnosisCode` as any,
                              currDiagnosis?.diagnosisCode
                            );
                          }}
                        />
                        {notACC32Acceptable && (
                          <Stack>
                            <ClaimsNonStandardBadge />
                          </Stack>
                        )}
                        <ChangeDiagnosisDropDown
                          name={`${claimAdjustmentFormNameOf(
                            "sideChanges"
                          )}[${index}]`}
                          field={fields.value[index]}
                          dropdownWidth="auto"
                        />
                        <Stack
                          styles={{
                            root: {
                              flexBasis: 132,
                              marginLeft: 0
                            }
                          }}
                        >
                          <DropdownField
                            withNoEmptyOption
                            name={`${claimAdjustmentFormNameOf(
                              "sideChanges"
                            )}[${index}].diagnosisSide`}
                            options={sideItems}
                          />
                        </Stack>
                        <Stack>
                          <DeleteButton
                            onClick={() => {
                              if (sideChanges.length !== 1) onRemoveItem();
                              else {
                                // when there is only 1 item, reset the values, don't remove the item.
                                change(
                                  `${claimAdjustmentFormNameOf(
                                    "sideChanges"
                                  )}[0]` as any,
                                  {
                                    ...getEmptyDiagnosis(),
                                    id: sideChanges[0].id
                                  }
                                );
                              }
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                }}
              </AnimatedListWithKeys>
            </Fieldset>

            <ActionButtonWithMaxedReached
              styles={{
                root: {
                  alignSelf: "baseline"
                }
              }}
              text={ChangeSideFieldLabels.changeAnotherSide}
              maxReachedText={ChangeSideFieldLabels.noMoreDiagnosisLeft}
              onClick={() => {
                const newDiagnosisChange = getEmptyDiagnosis();
                sideChanges
                  ? change(claimAdjustmentFormNameOf("sideChanges"), [
                      ...sideChanges,
                      newDiagnosisChange
                    ])
                  : change(claimAdjustmentFormNameOf("sideChanges"), [
                      newDiagnosisChange
                    ]);
              }}
              maxCountReached={fields.length === currentDiagnoses.length}
            />
          </Stack>
        );
      }}
    </FieldArray>
  );
};
