import React from "react";
import { useFormState } from "react-final-form";

import { Heading, Stack, Text } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { nameOfGeneralExaminationForm } from "./GeneralExaminationFields.tsx";
import {
  GeneralExaminationFormValues,
  SQUARED_SYMBOL
} from "./GeneralExaminationFormValues.ts";
import { calculateBMI, getSpinButtonStyles } from "./utils.ts";

interface GeneralExamWeightHeightBMIFieldProps {
  wrapValues?: boolean;
}
export const GeneralExamWeightHeightBMIField: React.FC<
  GeneralExamWeightHeightBMIFieldProps
> = ({ wrapValues }) => {
  const { values } = useFormState<GeneralExaminationFormValues>({
    subscription: { values: true }
  });

  const spinButtonStyles = getSpinButtonStyles();

  const { core } = useStores();
  const isAUGP = core.hasPermissions(Permission.GpExamCompleteAllowed);
  const bMILabel = `BMI (kg/m${SQUARED_SYMBOL})`;
  const getBMI = () => {
    if (
      values.weight &&
      parseFloat(values.weight) > 0 &&
      values.height &&
      parseFloat(values.height) > 0
    ) {
      const bmi = calculateBMI(
        parseFloat(values.weight),
        parseFloat(values.height)
      );
      return bmi.toFixed(2);
    }
    return "-";
  };

  return (
    <Fieldset
      horizontal={!wrapValues}
      tokens={{ childrenGap: 8 }}
      legend={isAUGP ? "Weight / Height / BMI / Habitus" : "Body parameters"}
      legendLevel="section-heading-light"
    >
      <SpinNumberInputField
        label="Weight (kg)"
        horizontalLabel
        name={nameOfGeneralExaminationForm("weight")}
        min={0.1}
        max={99.9}
        step={0.1}
        precision={1}
        maxDigitsNumber={3}
        styles={spinButtonStyles}
      />
      <SpinNumberInputField
        label="Height (cm)"
        horizontalLabel
        name={nameOfGeneralExaminationForm("height")}
        min={0.1}
        max={99.9}
        step={0.1}
        precision={1}
        maxDigitsNumber={3}
        styles={spinButtonStyles}
      />
      <FormItemField name={nameOfGeneralExaminationForm("bmi")}>
        <Stack
          tokens={{ childrenGap: 8 }}
          styles={{ root: { alignItems: !wrapValues ? "center" : undefined } }}
        >
          <Heading labelPaddings>{bMILabel}</Heading>
          <Text variant="medium">{getBMI()}</Text>
        </Stack>
      </FormItemField>
    </Fieldset>
  );
};
