import { PatientDisplayCode } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";

export enum PracticeDetailsFormLabels {
  hpi = "HPI",
  businessName = "Business name",
  abn = "ABN",
  nzbn = "NZBN",
  practiceInformation = "Practice information",
  organisationName = "Organisation name",
  organisationNameAbbr = "Org. name",
  organisationID = "Organisation ID",
  organisationIDAbbr = "Org. ID",
  accContracts = "ACC contracts",
  withProvider = "With provider",
  alliedHealth = "allied health",
  specifiedTreament = "specified treament",
  ourCustomers = "Our customers",
  orAn = "or a",
  add = "Add",
  contract = "contract",
  addMore = "Add more",
  addAn = "Add an",
  locationList = "Location list",
  practiceLocation = "Practice location"
}

export const AccPracticeContractLabels = {
  STP: "Specified treatment ID",
  AHS: "Allied health contract ID",
  ORS: "Orthotists contract ID"
};

export enum AccPracticeContractType {
  SpecifiedTreatmentProvider = "STP",
  AlliedHeathServices = "AHS",
  OrthotistsServices = "ORS"
}

export interface PracticeDetailsValues {
  hpiId: string | undefined;
  abnId: string | undefined;
  businessName: string | undefined;
  abn?: string;
  nzbn?: string;
  tagLine: string | undefined;
  patientDisplayCode: PatientDisplayCode;
  userSettingsToReset?: UserSetting[];
  hpiOrganisationId?: string;
  hpiOrganisationName?: string;
  withProvider: boolean;
  finalised: boolean;
  profilePictureUrl?: string;
}
