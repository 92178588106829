// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { useField } from "react-final-form";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import {
  backgroundImages,
  BackgroundImageTypes
} from "../../notes/draw/constants.ts";
import { DrawingCanvas } from "../../notes/draw/DrawingCanvas.tsx";
import { CanvasImage } from "../../notes/draw/DrawingCanvas.types.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";

enum HandsWristsImageTypes {
  handsWristsImage = "0"
}

export const HandsWristsInjuryImageAssessment: React.FC = () => {
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const {
    input: { onChange: onHandsWristsImage, value: handsWristsImageValue }
  } = useField(sotapNameOf("handsWristsImage"), {
    subscription: { value: true }
  });

  const handsWristsImage = backgroundImages.find(
    x => x.type === BackgroundImageTypes.HandsWrists
  );

  const images: CanvasImage[] = [
    {
      canvasRef: () => {},
      backgroundImageUrl: handsWristsImage?.src,
      width: handsWristsImage!.width,
      height: handsWristsImage!.height,
      initialValue: handsWristsImageValue,
      id: HandsWristsImageTypes.handsWristsImage
    }
  ];

  return (
    <DrawingCanvas
      isViewOnly={
        isViewOnlyOrDischarged ||
        clinicalRecord.dischargeInProgressOrCompleted()
      }
      showQuickColourPicker={!isViewOnlyOrDischarged}
      initialSelectedImage={0}
      caption="Diagram"
      images={images}
      onChange={(canvas: Canvas, event: any) => {
        if (
          canvas["lowerCanvasEl"].id === HandsWristsImageTypes.handsWristsImage
        ) {
          if (event.target.dirty) {
            onHandsWristsImage(canvas?.toSVG());
          } else {
            onHandsWristsImage(undefined);
          }
        }
      }}
    />
  );
};
