import { CenteredLargeSpinner } from "@bps/fluent-ui";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

export const withFetchSOTAPRefData = <T extends object, P extends any>(
  component: React.ComponentType<P>
) =>
  withFetch<T, P>(
    x => [
      x.clinical.ref.investigations.load(),
      x.clinical.ref.progressionTypes.load(),
      x.clinical.ref.swellingTypes.load(),
      x.clinical.ref.stages.load(),
      x.clinical.ref.reactionSeverities.load(),
      x.clinical.ref.irritabilityLevels.load(),
      x.clinical.ref.natures.load(),
      x.clinical.ref.dailyPatterns.load(),
      x.clinical.ref.sleepPatterns.load(),
      x.clinical.ref.aggravatingFactors.load(),
      x.clinical.ref.easingFactors.load(),
      x.clinical.ref.generalHealthConditions.load(),
      x.clinical.ref.clinicalFlags.load(),
      x.clinical.ref.specialQuestions.load(),
      x.clinical.ref.vertebrobasilarInsufficiencies.load(),
      x.clinical.ref.occupations.load(),
      x.clinical.ref.workTypes.load(),
      x.clinical.ref.injuryAreas.load(),
      x.clinical.ref.motionTypes.load(),
      x.clinical.ref.injuryAreaMotionTypeGroups.load(),
      x.clinical.ref.sidesOfBody.load(),
      x.clinical.ref.rxAnalysisOptions.load(),
      x.clinical.ref.educationOptions.load(),
      x.clinical.ref.outcomesDetails.load(),
      x.clinical.ref.dischargers.load(),
      x.clinical.ref.dischargerOutcomes.load(),
      x.clinical.ref.treatmentOptions.load(),
      x.acc.ref.claimStatuses.load()
    ],
    component,
    {
      fallback: <CenteredLargeSpinner />
    }
  );
