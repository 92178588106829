import {
  ComponentType,
  FunctionComponent,
  useCallback,
  useEffect
} from "react";
import { CalendarProps as BigCalendarProps, Event } from "react-big-calendar";
// eslint-disable-next-line import/extensions
import * as dragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  classNamesFunction,
  mergeStyles,
  styled,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

import { getCalendarStyles } from "./Calendar.styles.tsx";
import {
  CalendarProps,
  CalendarStyleProps,
  CalendarStyles,
  ComponentsWithNoTitle,
  GenericDragAndDropBigCalendar
} from "./Calendar.types.tsx";
import { CalendarLoadingOverlay } from "./CalendarLoadingOverlay.tsx";
import { luxonLocalizer } from "./luxonLocalizer.ts";

const getClassNames = classNamesFunction<CalendarStyleProps, CalendarStyles>();
export const mapEvents = (events: CalendarEvent[]) => {
  return events.reduce((acc, event) => {
    // check if the event is TemporaryReservation
    if (event.type === CalendarEventType.TemporaryReservation) {
      // find an event if it has the TemporaryReservation type,
      // the same userId, startDateTime and endDateTime
      const hasMultipleEvent = acc.find(
        e =>
          e.type === CalendarEventType.TemporaryReservation &&
          e.userId === event.userId &&
          e.startDateTime.toMillis() === event.startDateTime.toMillis() &&
          e.endDateTime.toMillis() === event.endDateTime.toMillis()
      );

      // if in the acc (result array) we have already an multiple event
      // fine the event and set hasMultipleLocks as true
      // if not just return a event
      if (hasMultipleEvent) {
        return [...acc].map(e => {
          e.hasMultipleLocks = true;
          return e;
        });
      }
    }
    // in all other cases just push an event to acc (result array)
    return [...acc, event];
  }, [] as CalendarEvent[]) as any[];
};

/**
 * The Calendar wraps React BigCalendar with drag and drop feature from https://github.com/intljusticemission/react-big-calendar
 * The recommendation is to wrap this Calendar in a specialized component that uses a specific Event type and provides property accessors to this event.
 *
 * It uses MomentJS for localization.
 */

const CalendarBase = <
  TEvent extends object = Event,
  TResource extends object = {}
>({
  centerOnCurrentTime = false,
  styles,
  className,
  view,
  today,
  date,
  events,
  ...bigCalendarProps
}: CalendarProps<TEvent, TResource>) => {
  const theme = useTheme();

  const scrollToTime = useCallback(() => {
    const calView: boolean = view === "day";
    if (centerOnCurrentTime) {
      const scrollDiv = document.getElementsByClassName("rbc-time-content")[0];
      const day = document.getElementsByClassName("rbc-events-container")[0];
      const scrollToDate =
        typeof date === "string"
          ? DateTime.fromISO(date)
          : DateTime.fromJSDate(date);

      if (scrollDiv && day && scrollToDate) {
        if (
          scrollToDate.startOf(calView ? "day" : "week").toMillis() ===
          DateTime.now()
            .startOf(calView ? "day" : "week")
            .toMillis()
        ) {
          scrollDiv.scrollTop =
            (day.clientHeight - scrollDiv.clientHeight) *
            (DateTime.now().hour / 23);
        }
      }
    }
  }, [centerOnCurrentTime, date, view]);

  useEffect(() => {
    scrollToTime();
  }, [date, scrollToTime, view]);

  const classNames = getClassNames(styles, {
    theme,
    className
  });

  // 😢 not proud of having to resort to as unknown but couldn't find a better way
  // to please typescript
  const DragAndDropBigCalendar =
    GenericDragAndDropBigCalendar as unknown as FunctionComponent<
      Omit<BigCalendarProps<TEvent, TResource>, "components"> &
        Omit<dragAndDrop.withDragAndDropProps<TEvent>, "components">
    > & {
      components: ComponentsWithNoTitle<TEvent, TResource>;
    };

  return (
    <div className={mergeStyles(classNames.root)}>
      <CalendarLoadingOverlay />
      <DragAndDropBigCalendar
        className={classNames.bigCalendar}
        localizer={luxonLocalizer()}
        defaultView="day"
        resizable
        selectable
        formats={{ timeGutterFormat: "HH" }}
        dayLayoutAlgorithm="overlap"
        {...bigCalendarProps}
        resources={
          bigCalendarProps.resources?.length
            ? bigCalendarProps.resources
            : undefined
        }
        view={view}
        date={date}
        getNow={() => today}
        events={mapEvents(events as CalendarEvent[])}
      />
    </div>
  );
};

const UntypedStyledCalendar: FunctionComponent<CalendarProps<any, any>> =
  styled<CalendarProps<any, any>, CalendarStyleProps, CalendarStyles>(
    CalendarBase,
    getCalendarStyles
  );

export const Calendar = <TEvent extends object, TResource extends object>(
  props: CalendarProps<TEvent, TResource>
) => {
  const StyledCalendar = UntypedStyledCalendar as ComponentType<
    CalendarProps<TEvent, TResource>
  >;
  return <StyledCalendar {...props} />;
};
