import React from "react";

import { DateTime } from "@bps/utils";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import {
  IInteractionTimeLineFilterProps,
  interactionTimeFilterNameOf,
  InteractionTimeLineFilterState
} from "./InteractionTimeLineFilter.types.ts";
import { InteractionTimelineValidator } from "./InteractionTimelineFilterValidator.ts";

const validator = new InteractionTimelineValidator();

export const InteractionTimeLineFilter: React.FC<
  IInteractionTimeLineFilterProps
> = ({ onSearch }) => {
  const { clinical } = useStores();
  const sidePanelSize =
    clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize;

  const interactionItemsTypes = clinical.ref.interactionTypes.keyTextValues;

  const filterBarItems: Item<InteractionTimeLineFilterState>[] = [
    {
      name: interactionTimeFilterNameOf("search"),
      type: "searchBox",
      stickItem: true,
      props: {
        autoFocus: true,
        placeholder: "Search notes",
        styles: { root: { width: "100%" } }
      }
    },
    {
      type: "datesRangePicker",
      name: "datesRangePicker",
      props: {
        id: "timeline-filter-dates-range",
        startDateProps: {
          id: "timeline-filter-start-date",
          maxDate: DateTime.jsDateNow(),
          label: "Start"
        },
        endDateProps: { id: "timeline-filter-end-date", label: "End" },
        startDateName: "dateFrom",
        endDateName: "dateTo",
        placeholder: "Date(s)",
        label: "Date(s)",
        styles: { fieldGroup: { width: "100%" } }
      }
    },
    {
      type: "optionsSelect",
      name: interactionTimeFilterNameOf("types"),
      props: {
        id: "interaction-filter-type",
        placeholder: "Type",
        multiSelect: true,
        label: "Type",
        options: interactionItemsTypes,
        hideSearchOption: true,
        calloutWidth: 200
      }
    }
  ];

  return (
    <FilterBar<InteractionTimeLineFilterState>
      initialValues={{
        search: "",
        types: [],
        dateFrom: undefined,
        dateTo: undefined
      }}
      onChange={({ values }) => {
        onSearch(values);
      }}
      validate={validator.validate}
      items={filterBarItems}
      styles={{
        searchBox: { minWidth: 200 },
        calloutItem: { width: "100%" },
        callout: {
          width: 288
        }
      }}
      shrinkVersion={sidePanelSize === TreeViewSize.Default}
    />
  );
};
