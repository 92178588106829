import { Spinner, Stack, Text } from "@bps/fluent-ui";
import { ClinicalDocumentLite } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export interface IRenderIncomingDocCheckedDateProps {
  item: ClinicalDocumentLite;
}

export const RenderIncomingDocCheckedDate: React.FunctionComponent<
  IRenderIncomingDocCheckedDateProps
> = ({ item }) => {
  const root = useStores();
  const { core } = root;
  return (
    <DataFetcher
      fetch={async () => {
        return item.checkedById
          ? await core.getUsersByIds([item.checkedById])
          : [];
      }}
      fallback={<Spinner />}
    >
      {(users: User[]) => {
        const user = users[0];
        const fullName = user?.fullName;
        const dateCheckedString = item.dateChecked
          ? item.dateChecked.toDayDefaultFormat()
          : undefined;

        return (
          <Stack tokens={{ childrenGap: 4 }}>
            {fullName && (
              <Text variant="small" styles={{ root: { maxWidth: 140 } }} nowrap>
                {`by ${fullName}`}
              </Text>
            )}
            <Text variant="medium">{dateCheckedString}</Text>
          </Stack>
        );
      }}
    </DataFetcher>
  );
};
