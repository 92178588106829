import React from "react";
import { useField } from "react-final-form";

import {
  PSFSContextClinicalDataItemDto,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolAnswer } from "../utils/ClinicalToolAnswerFinder.ts";
import { PSFSBaselineView } from "./PSFSBaselineView.tsx";
import { psfsNameOf } from "./PSFSForm.types.ts";

interface PSFSBaselineFormProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  context?: PSFSContextClinicalDataItemDto;
  clinicalRecord?: ClinicalRecord;
}

export const getActiveDiagnosisSideExist = (
  contextClinicalData: PSFSContextClinicalDataItemDto[] | undefined,
  contextId: string | undefined
) => {
  const openPSFSClinicalData =
    contextClinicalData && contextClinicalData.filter(x => !x.isClosed);

  const allPSFSdataClosed =
    openPSFSClinicalData && openPSFSClinicalData.length === 0;

  let isExistingPSFSHasSameContextId: boolean | undefined = false;
  // check context ids to avoid creating duplicate PSFS by editing existing one.
  if (!!contextId && openPSFSClinicalData) {
    isExistingPSFSHasSameContextId =
      openPSFSClinicalData.length > 0
        ? openPSFSClinicalData[0].contextId === contextId
        : false;
  }

  return !(
    allPSFSdataClosed ||
    !contextClinicalData ||
    isExistingPSFSHasSameContextId
  );
};

const PSFSBaselineFormBase: React.FunctionComponent<PSFSBaselineFormProps> = ({
  questionnaire,
  answers,
  context,
  clinicalRecord
}) => {
  const {
    input: { value: diagnosis }
  } = useField(psfsNameOf("diagnosisKey"));

  const {
    input: { value: side }
  } = useField(psfsNameOf("side"));

  const isActiveDiagnosisSideExist = (): boolean => {
    if (side && diagnosis) {
      const contextClinicalData =
        clinicalRecord?.clinicalData?.psfsContext?.contexts.filter(x => {
          return x.diagnosis.code === diagnosis && x.side === side;
        });

      return getActiveDiagnosisSideExist(
        contextClinicalData,
        context?.contextId
      );
    } else {
      return false;
    }
  };

  return (
    <PSFSBaselineView
      questionnaire={questionnaire}
      context={context}
      answers={answers}
      isActiveDiagnosisSideExist={isActiveDiagnosisSideExist}
    />
  );
};
export const PSFSBaselineForm = withFetch(
  x => [x.clinical.ref.sidesOfBody.load(), x.clinical.ref.psfsReasons.load()],
  PSFSBaselineFormBase
);
