import { FC } from "react";

import { TooltipHost } from "@bps/fluent-ui";

import { ClaimDiagnosisDescription } from "../../claim/types/claim.types.ts";

export const ClaimsListInjuryDiagnosisText: FC<{
  diagnosis: ClaimDiagnosisDescription[];
}> = props => {
  const sumText = props.diagnosis
    .map(diag => diag.terminology?.text)
    .join(", ");

  return (
    <TooltipHost
      styles={{
        root: { alignContent: "center", verticalAlign: "center" }
      }}
      content={sumText}
    >
      {sumText}
    </TooltipHost>
  );
};
