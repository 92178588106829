import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  Stack,
  Text
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

interface IntakeTableHeaderProps {
  tableTitle: string;
  length: number;
  onClick: () => void;
  maxIntakes?: number;
}
export const IntakeTableHeader: React.FunctionComponent<
  IntakeTableHeaderProps
> = ({ tableTitle, length, onClick, maxIntakes }): JSX.Element => {
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  return (
    <Stack horizontal horizontalAlign="space-between">
      <Text variant="large">{tableTitle}</Text>

      {!isViewOnlyOrDischarged && (
        <ActionButton
          {...dataAttribute(DataAttributes.Element, "add-new-intake")}
          iconProps={{ iconName: "Add" }}
          title="Add more"
          disabled={maxIntakes ? length >= maxIntakes : false}
          onClick={() => onClick()}
        >
          Add more
        </ActionButton>
      )}
    </Stack>
  );
};
