import { useState } from "react";

import {
  CheckboxVisibility,
  IColumn,
  Link,
  NoDataTile,
  SelectionMode,
  Text
} from "@bps/fluent-ui";
import {
  DocumentEntityType,
  InboxDocumentDto
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { DetailsListField } from "@ui-components/form/DetailsListField.tsx";

import { InboxPickerFormValues } from "./SelectInboxDocumentDialog.tsx";

interface InboxPickerListItem extends InboxDocumentDto {
  key: string;
}

interface SelectInboxDocumentDialogFieldsProps {
  items: InboxPickerListItem[];
}

export const SelectInboxDocumentDialogFields: React.FC<
  SelectInboxDocumentDialogFieldsProps
> = ({ items }) => {
  const { inbox } = useStores();
  const [previewDocument, setPreviewDocument] = useState<Document | undefined>(
    undefined
  );

  const columns: IColumn[] = [
    {
      key: "id",
      name: "",
      minWidth: 100,
      onRender: (item: InboxPickerListItem) => (
        <>
          <Text styles={{ root: { marginRight: 8 } }}>{item.name}</Text>
          <Link
            onClick={async () => {
              const inboxDocument = await inbox.getInboxDocument({
                id: item.id,
                documentDetailId: item.documentDetailId
              });

              const document: Document = {
                entityId: inboxDocument.id,
                documentId: inboxDocument.documentDetailId,
                entityType: DocumentEntityType.DocumentProcessing,
                name: inboxDocument.name,
                extension: inboxDocument.docExtension ?? "",
                downloadUri: inboxDocument.fileUri,
                previewUri: inboxDocument.previewUri
              };

              setPreviewDocument(document);
            }}
          >
            Preview
          </Link>
        </>
      )
    }
  ];

  if (!items.length) {
    return (
      <NoDataTile
        textProps={{ text: "No documents associated to this patient" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
        styles={{ root: { minHeight: 0 } }}
      />
    );
  }

  return (
    <>
      <DetailsListField
        name={nameof<InboxPickerFormValues>("documentId")}
        columns={columns}
        items={items}
        selectionMode={SelectionMode.single}
        checkboxVisibility={CheckboxVisibility.always}
        required
        onRenderDetailsHeader={() => null}
        disableAutoSelectOnInputElements // Prevent selection on clicking preview link
      />
      {previewDocument && (
        <DocumentViewerDialog
          getDocument={() => previewDocument}
          closeDocumentViewer={() => setPreviewDocument(undefined)}
        />
      )}
    </>
  );
};
