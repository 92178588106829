import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  Heading,
  LabeledText,
  LabeledTextGroup,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { TIME_FORMATS } from "@bps/utils";
import { Claim } from "@stores/acc/models/Claim.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { invoiceDetailsRowHeadingStyles } from "./InvoiceDetailsRow.styles.ts";

interface InvoiceDetailsRowClaimProps {
  calendarEvent?: CalendarEvent;
  claim?: Claim;
  condition?: Condition;
}

export const InvoiceDetailsRowClaimBase: FunctionComponent<InvoiceDetailsRowClaimProps> =
  observer(({ calendarEvent, claim, condition }) => {
    const theme = useTheme();

    let claimNumberText: string = "";
    let calendarEventText: string = "";
    let refNumber: string = "";

    if (claim) {
      claimNumberText = claim.claimNumber || "Not lodged";
    }

    if (condition) {
      refNumber = condition.referralNumber || "Not lodged";
    }

    if (calendarEvent) {
      const startDateText = calendarEvent.startDateTime?.toDayDefaultFormat();

      const startTimeText = calendarEvent.startTime.toFormat(
        TIME_FORMATS.DEFAULT_TIME_FORMAT
      );
      calendarEventText = `${startDateText} ${startTimeText}`;
    }

    return (
      <>
        <Stack
          styles={{
            root: {
              borderBottom: `1px solid ${theme.palette.neutralLight}`
            }
          }}
        >
          <Heading styles={invoiceDetailsRowHeadingStyles}>
            Claim details
          </Heading>
        </Stack>
        <LabeledTextGroup horizontalColumnCount={2}>
          <LabeledText
            noSemicolon
            label="Number"
            styles={{ root: { minHeight: 33 } }}
          >
            {claimNumberText || refNumber}
          </LabeledText>
          <LabeledText noSemicolon label="Appointment">
            {calendarEventText}
          </LabeledText>
        </LabeledTextGroup>
      </>
    );
  });

const InvoiceDetailsRowClaimDataFetcher: FunctionComponent<
  InvoiceDetailsRowClaimProps
> = props => {
  const fetch = async (root: RootStore) => {
    let condition: Condition | undefined;
    if (props.calendarEvent?.reason?.episodeOfCareId)
      condition = await root.clinical.getCondition(
        props.calendarEvent?.reason?.episodeOfCareId
      );

    Promise.all([
      props.claim?.loadProvider(),
      props.claim?.loadClaimAppointments()
    ]);

    return condition;
  };

  return (
    <Stack grow tokens={{ childrenGap: 8 }}>
      <DataFetcher<Condition | undefined> fetch={fetch}>
        {condition => (
          <InvoiceDetailsRowClaimBase condition={condition} {...props} />
        )}
      </DataFetcher>
    </Stack>
  );
};

export const InvoiceDetailsRowClaim = withFetch(
  root => [root.practice.ref.accProviderContractTypes.load()],
  InvoiceDetailsRowClaimDataFetcher
);
