import { FunctionComponent } from "react";

import { Stack, Text, Tile, useTheme } from "@bps/fluent-ui";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { getAppointmentStatusColours } from "@modules/booking/screens/booking-calendar/components/shared-components/appointment-status/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface LegendItemProps {
  code: AppointmentStatusCode;
  text: string;
}

const LegendItem: FunctionComponent<LegendItemProps> = ({ code, text }) => {
  const theme = useTheme();
  const colours = getAppointmentStatusColours(theme);
  const statusColour = colours(code) || colours(AppointmentStatusCode.Booked);
  return (
    <Stack
      styles={{
        root: {
          padding: 2,
          flex: 1,
          textAlign: "center",
          borderLeft: `4px ${statusColour.dark} solid`,
          background: statusColour.light
        }
      }}
    >
      <Text>{text}</Text>
    </Stack>
  );
};

export const BookingCalendarLegend: FunctionComponent = () => {
  const {
    booking: { ref }
  } = useStores();

  const appointmentStatuses = ref.appointmentStatuses;
  return (
    <Tile
      tokens={{ childrenGap: 8 }}
      styles={{ content: { display: "flex", flexDirection: "row" } }}
    >
      {appointmentStatuses.values.map(({ code, text }) => (
        <LegendItem
          key={code}
          code={code as AppointmentStatusCode}
          text={text}
        />
      ))}
      <LegendItem code={AppointmentStatusCode.UrgentBooked} text="Urgent" />
      <LegendItem
        code={AppointmentStatusCode.UrgentWaiting}
        text="Urgent &amp; waiting"
      />
    </Tile>
  );
};
