import { DATE_FORMATS } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isNotFutureDate,
  predicate,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { PatientDetailsFormValues } from "@shared-types/acc/patient-details-values.type.ts";

import { ClaimAddressValidator } from "./ClaimAddressValidator.ts";

export class PatientDetailsFormValidator extends Validator<PatientDetailsFormValues> {
  constructor() {
    const claimAddressValidator = new ClaimAddressValidator();
    super();
    this.forField(nameof("patientFirstName"), [required()]);
    this.forField(nameof("patientLastName"), [required()]);
    this.forField(nameof("patientDateOfBirth"), [required()]);
    this.forField(nameof("mostRecentSurgeryDate"), [
      predicate(
        (val, values) => !!values?.accRelatedSurgery,
        required(),
        validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
        isNotFutureDate(ValidationMessages.futureDateEqualOrLess)
      )
    ]);
    this.forField(nameof("claimNumber"), [required()]);
    this.forField("patientAddress", claimAddressValidator.validate);
  }
}
