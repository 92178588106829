import { createContext, useContext } from "react";

import { PrescribingWizardHelper } from "./PrescribingWizardHelper.ts";

export const PrescribingWizardContext = createContext<PrescribingWizardHelper>(
  {} as PrescribingWizardHelper
);

export const usePrescribingWizardContext = () =>
  useContext(PrescribingWizardContext);
