import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { useFormState } from "react-final-form";

import { Text } from "@bps/fluent-ui";
import { RecurrenceLinkingActionType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { LinkField } from "@ui-components/form/LinkField.tsx";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";
import { getRecurrenceLinkText } from "./utils.ts";

type RecurrenceLinkTextBlockProps = {
  condition: ICondition;
};

const LINK_ALL_TEXT = "Link all future appointments";
const THIS_ONLY_TEXT = "Link this appointment only";

export const RecurrenceLinkTextBlock: FC<RecurrenceLinkTextBlockProps> =
  observer(({ condition }) => {
    const {
      values: { count, linkingAction, repeat }
    } = useFormState<AppointmentFormValues>({ subscription: { values: true } });

    const { recurrenceOccurred, booking } = useContext(AppointmentFormContext);

    const options = [
      { key: RecurrenceLinkingActionType.LinkAll, text: THIS_ONLY_TEXT },
      { key: RecurrenceLinkingActionType.LinkFirst, text: LINK_ALL_TEXT }
    ];

    const displayedCondition =
      condition.primaryDiagnosis && condition.primaryDiagnosis !== "Undiagnosed"
        ? condition.primaryDiagnosis
        : "new condition";

    if (!count) return null;

    return count && count > 1 ? (
      <LinkField
        disabled={
          repeat &&
          !!booking.ui.currentAppointment?.id &&
          booking.ui.isEditSingleEvent
        }
        options={options}
        defaultText={LINK_ALL_TEXT}
        description={getRecurrenceLinkText(
          displayedCondition,
          {
            totalOccurrences: count,
            recurrenceOccurred: recurrenceOccurred ?? 1
          },
          linkingAction
        )}
        name={appointmentFormNameOf("linkingAction")}
      />
    ) : (
      <Text>{`1 appointment linked to ${displayedCondition}`}</Text>
    );
  });
