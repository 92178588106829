import React, { FunctionComponent } from "react";
import { useField } from "react-final-form";

import {
  ActionButton,
  DetailsList,
  Heading,
  IColumn,
  SelectionMode
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { CustomToolTrendAnswers } from "@shared-types/clinical/custom-tool-trend-answers.interface.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SeriesQuestionAnswers } from "../../clinical-tools/psfs/PSFSTrendDialog.types.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";
import { getEmptyTool } from "./utils.ts";

enum OutcomeMeasuresCardKeys {
  trashCan = "trashCan",
  currentEncounter = "currentEncounter",
  outcomeMeasure = "outcomeMeasure"
}

export const CustomClinicalToolList: FunctionComponent = () => {
  const { fields: customClinicalTools } = useFieldArray<CustomToolTrendAnswers>(
    "customClinicalTools"
  );

  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const {
    input: { value: customToolBaseColumns }
  } = useField<{ key: string; name: string }[]>(
    sotapNameOf("customToolBaseColumns")
  );

  const isDischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();

  const defaultColumns: IColumn[] = Array.from(customToolBaseColumns ?? []).map(
    c => ({
      ...c,
      maxWidth: 75,
      minWidth: 75,
      onRender: (item: CustomToolTrendAnswers) => {
        const answerKey = `series${c.key}`;
        const result = item[answerKey] ?? "";
        return `${result}`;
      }
    })
  );

  const columns: IColumn[] = [
    ...[
      {
        //custom tool's name
        key: OutcomeMeasuresCardKeys.outcomeMeasure,
        minWidth: 95,
        name: "",
        onRender: (item: CustomToolTrendAnswers, index: number) => {
          if (item.isReadOnly) {
            const name = sotapNameOf("customClinicalTools");
            return (
              <TextInputField required={true} name={`${name}[${index}].name`} />
            );
          } else {
            return item.name;
          }
        },
        isMultiline: true,
        styles: { cellName: { width: "100%" }, cellTitle: { width: "100%" } },
        onRenderHeader: () => <Heading hasAsterisk>Outcome measure</Heading>
      },
      {
        key: OutcomeMeasuresCardKeys.currentEncounter,
        minWidth: 80,
        maxWidth: 80,
        name: "",
        onRender: (item: SeriesQuestionAnswers, index: number) => {
          const name = sotapNameOf("customClinicalTools");
          return (
            <SpinNumberInputField
              name={`${name}[${index}].thisEncounterResult`}
              maxDigitsNumber={2}
              step={1}
              styles={{
                root: {
                  verticalAlign: "center"
                }
              }}
            />
          );
        },
        isMultiline: false,
        styles: { cellName: { width: "100%" }, cellTitle: { width: "100%" } },
        onRenderHeader: () => <Heading hasAsterisk>Today</Heading>
      },
      {
        key: OutcomeMeasuresCardKeys.trashCan,
        minWidth: 12,
        maxWidth: 12,
        name: "",
        onRender: (item: SeriesQuestionAnswers, index: number) =>
          !isViewOnlyOrDischarged &&
          !isDischargeInProgressOrCompleted &&
          item.isReadOnly && (
            <DeleteButton
              onClick={() => customClinicalTools.remove(index)}
              styles={{
                icon: {
                  paddingTop: 8
                }
              }}
            />
          ),
        isMultiline: true
      }
    ],
    ...defaultColumns
  ];

  return (
    <>
      <Heading variant="section-heading-light">Custom</Heading>
      <DetailsList
        items={customClinicalTools.value}
        columns={columns}
        selectionMode={SelectionMode.none}
      />

      {!isViewOnlyOrDischarged && !isDischargeInProgressOrCompleted && (
        <ActionButton
          iconProps={{ iconName: "Add" }}
          text="Add another"
          onClick={() => {
            customClinicalTools.push(
              getEmptyTool(customClinicalTools.length ?? 0)
            );
          }}
        />
      )}
    </>
  );
};
