import { ReactNode } from "react";
import { useForm, useFormState } from "react-final-form";

import { CollapsibleCard, Stack, Text } from "@bps/fluent-ui";
import { SystemReviewLabel } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import {
  SystemsReviewFormValues,
  systemsReviewNameOf
} from "./SystemsReviewForm.types.ts";

export const UrinaryFlagsCard: React.FunctionComponent<{
  heading?: ReactNode | string;
}> = ({ heading }) => {
  const form = useForm<SystemsReviewFormValues>();
  const { values } = useFormState<SystemsReviewFormValues>({
    subscription: { values: true }
  });

  const { clinical } = useStores();

  const urinaryFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isUrinary && x.clinicalFlagText.localeCompare("MALE") !== 0)
    .map(({ code, text }) => ({
      value: code,
      label: text
    }));

  const maleUrinaryFlagOptions = clinical.ref.clinicalFlags.values
    .filter(x => x.isUrinary && x.clinicalFlagText.localeCompare("MALE") === 0)
    .map(({ code, text }) => ({
      value: code,
      label: text
    }));

  return (
    <div id="UrinaryFlags">
      <CollapsibleCard
        heading={heading}
        iconName="PlugDisconnected"
        openOnRender={false}
      >
        <Stack tokens={{ childrenGap: 8 }}>
          <ToggleField
            name={systemsReviewNameOf("urinaryFlagsAllClear")}
            label={SystemReviewLabel.NAD}
            inlineLabel
            styles={{
              root: {
                margin: 0
              }
            }}
          />
          <CheckboxGroupField
            name={systemsReviewNameOf("urinaryFlags")}
            options={urinaryFlagOptions}
            disabled={!!values.urinaryFlagsAllClear}
          />
          <Text bold styles={{ root: { marginBottom: 8 } }}>
            Male reproductive
          </Text>
          <CheckboxGroupField
            name={systemsReviewNameOf("maleUrinaryFlags")}
            options={maleUrinaryFlagOptions}
            disabled={!!values.urinaryFlagsAllClear}
          />
          <FieldSpy
            name={systemsReviewNameOf("urinaryFlagsAllClear")}
            onChange={(value: boolean) => {
              if (value) {
                if (form) {
                  form.batch(() => {
                    form.change(systemsReviewNameOf("urinaryFlags"), undefined);
                    form.change(
                      systemsReviewNameOf("maleUrinaryFlags"),
                      undefined
                    );
                  });
                }
              }
            }}
          />
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
