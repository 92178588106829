import {
  FeeType,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ChangeTypes } from "@modules/billing/screens/shared-components/change-type/change-types.enum.tsx";

export interface AllocationFieldValues {
  invoiceItemId: string;
  invoiceNumber: string;
  code: string;
  invoiceId: string;
  description: string;
  name?: string;
  itemTotal: number;
  comment?: string;
  invoiceDate: Date;
  checked: boolean;
  total: number;
  fee: number;
  feeType: FeeType;
  gst: number;
  quantity: number;
  owing?: number;
  amount?: number;
  changeType?: ChangeTypes;
  paymentStatus?: PaymentStatuses;
  locationId: string;
}

export enum AllocationDataAttributes {
  invoiceNumberCell = "invoice-number-cell",
  totalCell = "total-cell"
}

export enum ItemSelectionMode {
  //displays checkboxes: enabled when the sum of selected totals is <= totalAmount.
  checkboxes = "checkboxes",
  //items cannot be selected.
  none = "none",
  //hide table
  hidden = "hidden"
}

export const allocationFieldNameOf = nameOfFactory<AllocationFieldValues>();
