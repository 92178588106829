import { DateTime } from "@bps/utils";
import { CalendarEventStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

import { ApptinfoFilterType } from "./AppointmentInformationTable.tsx";

export const filterData = (
  calendarEvent: CalendarEvent,
  filterVal: ApptinfoFilterType,
  booking: BookingStore
) => {
  const apptTypeVal =
    booking.appointmentTypesMap.get(calendarEvent.appointmentTypeId ?? "")?.dto
      .text || "Not recorded";

  const durationVal = calendarEvent.duration.minutes;

  if (filterVal.apptType) {
    if (
      apptTypeVal?.toLowerCase().indexOf(filterVal.apptType.toLowerCase()) ===
      -1
    ) {
      return false;
    }
  }

  if (filterVal.apptDate) {
    if (
      !DateTime.fromJSDate(filterVal.apptDate).hasSame(
        calendarEvent.startDateTime,
        "day"
      )
    ) {
      return false;
    }
  }

  if (filterVal.duration) {
    if (filterVal.duration === "0") {
      if (durationVal <= 0 || durationVal > 15) {
        return false;
      }
    } else if (filterVal.duration === "15") {
      if (durationVal < 15 || durationVal > 30) {
        return false;
      }
    } else if (filterVal.duration === "30") {
      if (durationVal < 30 || durationVal > 45) {
        return false;
      }
    } else if (filterVal.duration === "45") {
      if (durationVal < 45 || durationVal > 60) {
        return false;
      }
    } else if (filterVal.duration === "60") {
      if (durationVal < 60 || durationVal > 500) {
        return false;
      }
    }
  }

  if (filterVal.providerId) {
    if (filterVal.providerId !== calendarEvent.userId) {
      return false;
    }
  }

  if (filterVal.status && filterVal.status.length) {
    if (calendarEvent.status === CalendarEventStatus.Cancelled) {
      if (!filterVal.status.includes(calendarEvent.status)) {
        return false;
      }
    } else {
      if (!filterVal.status.includes(calendarEvent.appointmentStatus ?? "")) {
        return false;
      }
    }
  }

  return true;
};
