export enum EncounterSubmitOptions {
  NotesAndServices = "Finalise notes & services",
  NotesNoCharge = "Finalise notes w. no charge",
  NotesOnly = "Finalise notes",
  DiscardNotesAndServices = "Discard notes & services",
  AddOrUpdateServices = "Add or update services",
  NoChargeForAppt = "No charge for this appointment",
  ProviderComment = "Comment for the front office",
  ProviderCommentAdd = "Add a comment for the front office",
  ViewInvoicedServices = "View invoiced services",
  SaveUpdateAndClose = "Save update & close",
  DiscardUpdate = "Discard update",
  EndConsult = "End consult",
  FinaliseConsult = "Finalise consult",
  EndConsultAndClose = "End consult & close record",
  SaveChanges = "Save changes",
  SaveChangestAndClose = "Save changes & close record"
}
