import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isDateBeforeField,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DocumentMergeFieldsFormValues } from "./DocumentWriterMergeFormDialog.types.ts";

export class DocumentWriterMergeFieldsMedicalFormValidator extends Validator<DocumentMergeFieldsFormValues> {
  constructor() {
    super();

    this.forField("patientId", [required()]);
    this.forField("fromDate", [required()]);
    this.forField("toDate", [
      required(),
      isDateBeforeField(
        "To date must not be before from date",
        nameof("fromDate"),
        false
      )
    ]);
  }
}
