import { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IButtonProps,
  IDialogContentProps
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { DraftItemListContext } from "@modules/billing/screens/draft-items/context/DraftItemListContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { DraftInvoiceGenerateContext } from "./context/DraftInvoiceGenerateContext.ts";
import { DraftInvoiceGenerateHelper } from "./context/DraftInvoiceGenerateHelper.ts";
import { DraftInvoiceGenerateFormValues } from "./DraftInvoiceGenerateModal.type.ts";
import { DraftInvoiceGenerateModalFields } from "./DraftInvoiceGenerateModalFields.tsx";

const DraftInvoiceGenerateModalBase: FunctionComponent = () => {
  const { setIsInvoiceNewModalVisible } = useContext(DraftItemListContext);
  const { notification } = useStores();

  const { handleSubmitAction, isDraftInvoiceFormReady } = useContext(
    DraftInvoiceGenerateContext
  );

  const dialogContentProps: IDialogContentProps = {
    title: <Heading variant="modal-heading">New invoice</Heading>,
    showCloseButton: true
  };

  const initalValues: DraftInvoiceGenerateFormValues = {
    accountId: "",
    cutOffDate: DateTime.jsDateNow(),
    selectedPatient: "",
    selectedProvider: ""
  };

  const submitButtonProps: IButtonProps = {
    ...dataAttribute(DataAttributes.Element, "submit-button"),
    text: "Generate",
    iconProps: { iconName: undefined }
  };

  const onSubmit = async (values: DraftInvoiceGenerateFormValues) => {
    const result = await handleSubmitAction(values);
    result.forEach(x =>
      notification.success(notificationMessages.invoiceSaved(x.number))
    );
  };
  return (
    <SubmissionFormDialog<DraftInvoiceGenerateFormValues>
      dialogName="draftInvoiceGenerate"
      initialValues={initalValues}
      validate={isDraftInvoiceFormReady}
      onSubmit={onSubmit}
      onSubmitSucceeded={() => setIsInvoiceNewModalVisible(false)}
      dialogProps={{
        showTitleSeparator: false,
        onDismiss() {
          setIsInvoiceNewModalVisible(false);
        },

        minWidth: 600,
        dialogContentProps
      }}
      buttonsProps={{ submitButtonProps }}
      children={() => <DraftInvoiceGenerateModalFields />}
    />
  );
};

export const DraftInvoiceGenerateModal = () => {
  const helper = new DraftInvoiceGenerateHelper(useStores());
  return (
    <DraftInvoiceGenerateContext.Provider value={helper}>
      <DraftInvoiceGenerateModalBase />
    </DraftInvoiceGenerateContext.Provider>
  );
};
