import { DateTime } from "@bps/utils";
import {
  AddDocumentDto,
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  CorrespondenceVisibility,
  DocumentCreateOptions,
  DocumentDto,
  DocumentEnum,
  DocumentMetadataItem,
  DocumentSource,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AddUserAction,
  Instructions,
  NoActionUserActionContext,
  ReportTypeKeys,
  UserActionType,
  UserTaskDto,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { getNewMetadata } from "@modules/clinical/utils/clinical.utils.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { CorrespondenceFormValue } from "../clinical-form/DocumentBatchFormValues.ts";

const {
  Name,
  EncounterId,
  ReportType,
  Outcome,
  ShowOnTimeline,
  Extension,
  CheckedBy,
  DateChecked,
  SecGroupId
} = DocumentEnum;
export class CorrespondenceDialogHelper {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private root: RootStore
  ) {}

  getInitialValues = (correspondence?: ClinicalDocument) => {
    const checkedBy = this.clinicalRecord.openEncounter?.userId;
    const dateChecked = DateTime.now().toISO();

    if (correspondence) {
      const userTask = this.clinicalRecord.patientUserTasks.find(
        task => task.documentId === correspondence.id
      );

      return {
        to: correspondence.to,
        direction: correspondence.direction,
        type: correspondence.type as CorrespondenceType,
        showOnTimeline: correspondence.showOnTimeline,
        reportType: correspondence.reportType as ReportTypeKeys,
        from: correspondence.from,
        outcome: userTask?.outcome,
        file: {
          subject: correspondence.name,
          date: correspondence.date?.toJSDate(),
          file: new File([], correspondence.name),
          name: correspondence.name,
          extension: correspondence.extension
        },
        checkedBy: correspondence.checkedBy ?? checkedBy,
        dateChecked: correspondence.dateChecked ?? dateChecked,
        visibility: !!correspondence.secGroupId
          ? CorrespondenceVisibility.Confidential
          : undefined
      };
    } else
      return {
        to: this.root.core.userId,
        direction: CorrespondenceDirection.Practice,
        checkedBy,
        dateChecked
      };
  };

  submitNewCorrespondence = async (formValue: CorrespondenceFormValue) => {
    const {
      type,
      direction,
      from,
      to,
      stagingPath,
      reportType,
      file,
      outcome,
      visibility
    } = formValue;

    const { id: encounterId, userId } = this.clinicalRecord.openEncounter || {};
    const { id: patientId } = this.clinicalRecord;
    if (!stagingPath) throw new Error("stagingPath not set");
    if (!encounterId) throw new Error("encounterId not set");

    const includeCheckedBy =
      reportType === ReportTypeKeys.acceptable ||
      reportType === ReportTypeKeys.unacceptable;

    const documentDateString = DateTime.jsDateToISODate(file?.date);

    const displayOnTimeline =
      visibility === CorrespondenceVisibility.DisplayOnTimeline;

    const conf = visibility === CorrespondenceVisibility.Confidential;

    const metadataRecord: Record<string, string | undefined> = {
      [Name]: file?.subject,
      [DocumentEnum.Date]: documentDateString,
      [EncounterId]: encounterId,
      [ReportType]: reportType,
      [Outcome]: outcome,
      [ShowOnTimeline]:
        displayOnTimeline === true ? true.toString() : undefined,
      [Extension]: file?.extension
    };

    if (includeCheckedBy) {
      metadataRecord[CheckedBy] = userId;
      metadataRecord[DateChecked] = DateTime.now().toISODate();
    }

    const metadata: DocumentMetadataItem[] = getNewMetadata([], metadataRecord);

    const document: AddDocumentDto = {
      patientId,
      direction,
      from,
      to,
      store: StoreType.Correspondence,
      metadata,
      showOnTimeline: displayOnTimeline,
      type,
      id: file?.blobName,
      status: CorrespondenceStatus.Uploaded,
      secGroupId: conf ? this.root.core.user?.privateSecGroupId : undefined
    };

    const request: DocumentCreateOptions = {
      stagingId: stagingPath.stagingId,
      source: DocumentSource.Staging,
      documents: [document]
    };

    await this.root.correspondence.addDocuments(encounterId, request);
  };

  updateCorrespondence = async (
    formValue: CorrespondenceFormValue,
    correspondence: ClinicalDocument
  ) => {
    const { id: encounterId, userId } = this.clinicalRecord.openEncounter || {};
    const { id: patientId } = this.clinicalRecord;

    const { reportType, direction, type, from, to, outcome } = formValue;
    if (!encounterId) throw new Error("encounterId not set");
    if (!correspondence)
      throw new Error("You do not have any correspondece data to edit");

    const includeCheckedBy =
      reportType !== ReportTypeKeys.unchecked &&
      this.getInitialValues(correspondence).reportType !== reportType;

    const { file, visibility } = formValue;

    const displayOnTimeline =
      visibility === CorrespondenceVisibility.DisplayOnTimeline;

    const confidential = visibility === CorrespondenceVisibility.Confidential;

    const documentDateString = DateTime.jsDateToISODate(file?.date);

    const metadataRecord: Record<string, string | undefined> = {
      [Name]: file?.subject,
      [DocumentEnum.Date]: documentDateString,
      [EncounterId]: encounterId,
      [ReportType]: reportType,
      [Outcome]: outcome,
      [ShowOnTimeline]:
        displayOnTimeline === true ? true.toString() : undefined,
      [Extension]: correspondence.extension
    };

    if (includeCheckedBy) {
      metadataRecord[CheckedBy] = userId;
      metadataRecord[DateChecked] = DateTime.now().toISODate();
    } else {
      metadataRecord[CheckedBy] = correspondence.checkedBy;
      metadataRecord[DateChecked] = correspondence.dateChecked;
    }

    if (confidential) {
      metadataRecord[SecGroupId] = this.root.core.user?.privateSecGroupId;
    }

    const metadata: DocumentMetadataItem[] = getNewMetadata([], metadataRecord);

    const updateDocumentDto: DocumentDto = {
      id: correspondence.id,
      eTag: correspondence.eTag,
      patientId,
      status: correspondence.status,
      store: correspondence.store,
      direction,
      type,
      from,
      to,
      metadata,
      secGroupId: confidential
        ? this.root.core.user?.privateSecGroupId
        : undefined
    };

    await this.root.correspondence.updateCorrespondence(
      encounterId,
      correspondence.id,
      updateDocumentDto
    );

    await this.upsertUserTask(correspondence, outcome);
  };

  upsertUserTask = async (
    correspondence: ClinicalDocument,
    outcome?: string
  ) => {
    if (!correspondence) {
      return;
    }

    const { id: patientId, patientUserTasks } = this.clinicalRecord;
    const userTask = patientUserTasks.find(
      task => task.documentId === correspondence.id
    );
    if (userTask) {
      await this.root.inbox.updateUserTask({
        ...userTask.dto,
        outcome
      });
    } else if (outcome) {
      const context: NoActionUserActionContext = {
        userTask: {
          instructionCode: Instructions.NoAction,
          status: UserTaskStatus.Completed,
          outcome
        } as UserTaskDto,
        patientKey: {
          patientId,
          documentId: correspondence.id
        }
      };

      const userAction: AddUserAction = {
        userActionType: UserActionType.NoAction,
        context
      };

      await this.root.inbox.addUserAction(userAction);
    }
  };
}
