import { fabric } from "fabric";

import { BackgroundDisplayOption } from "./FabricDrawing.types.ts";

const MIME_TYPE = "image/svg+xml";
/**
 * scale image to fit the canvas width and height
 * setting the image width and height to canvas width and height
 * sounds like a good idea 💡 but it doesn't work 😢
 */
export const setImageCanvasOption = (
  image: fabric.Image,
  canvas: fabric.Canvas,
  options?: BackgroundDisplayOption
) => {
  if (options === undefined || options === BackgroundDisplayOption.ScaleToFit) {
    scaleImage(image, canvas);
  } else if (options === BackgroundDisplayOption.Center) {
    centerImage(image, canvas);
  }
};

const centerImage = (image: fabric.Image, canvas: fabric.Canvas) => {
  const originalSize = image.getOriginalSize();
  const canvasWidth = canvas.getWidth();
  const canvasHeight = canvas.getHeight();

  const widthScale = canvasWidth / originalSize.width;
  const heightScale = canvasHeight / originalSize.height;

  const scale = Math.min(widthScale, heightScale);

  image.scaleX = scale;
  image.scaleY = scale;

  image.left = (canvasWidth - originalSize.width * scale) / 2;
  image.top = (canvasHeight - originalSize.height * scale) / 2;

  image.setCoords();
};

const scaleImage = (image: fabric.Image, canvas: fabric.Canvas) => {
  const originalSize = image.getOriginalSize();

  if (originalSize.width) {
    image.scaleX = canvas.getWidth() / originalSize.width;
  }
  if (originalSize.height) {
    image.scaleY = canvas.getHeight() / originalSize.height;
  }
};

export const convertToDataUrl = async (
  url: string,
  mimeType?: string
): Promise<string> => {
  if (url.startsWith("data:")) {
    return url;
  }

  const result = await fetch(url);
  const data = await (await result.blob()).text();
  return `data:${mimeType ?? MIME_TYPE};base64,${btoa(data)}`;
};
