import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Pivot, PivotItem, Stack, useTheme } from "@bps/fluent-ui";
import { PrefAndConsentPivotTabTypes } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { Contact } from "@stores/practice/models/Contact.ts";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { NoDetailsButton } from "../../shared-components/view/NoDetailsButton.tsx";
import { PatientNeedInterpreter } from "./PatientNeedInterpreter.tsx";
import { PatientPreferencesConsentsPivotTabs } from "./PatientPreferencesConsentsPivotTabs.tsx";

interface PatientPreferencesConsentsCardProps {
  showEditIcon: boolean;
  patient: Contact;
  contactPreferences?: ContactPreferences;
  onHandleEditModal: (id?: string) => void;
}

type PreferencesConsentsPivotTabsValues = {
  itemKey: PrefAndConsentPivotTabTypes;
  hasContactPreference: boolean;
  headerText: PrefAndConsentPivotTabTypes;
};

export const PatientPreferencesConsentsCard: FunctionComponent<PatientPreferencesConsentsCardProps> =
  observer(
    ({ patient, showEditIcon, contactPreferences, onHandleEditModal }) => {
      const theme = useTheme();
      const { noRelationships } = getPeopleScreenStylesSet(theme);

      const isInvoicePreferencesConsentsExist =
        !!contactPreferences?.invoiceCommunicationPreferences;

      const isAppointmentPreferencesConsentsExist = !!(
        contactPreferences?.apptRemPreferences ||
        contactPreferences?.apptConfirmPreferences
      );

      const isFormsPreferenceConsentsExist =
        !!contactPreferences?.formNotifyPreferences;

      const getPivotPatientPreferencesConsentsPivotTabsArray = () => {
        const data: PreferencesConsentsPivotTabsValues[] = [
          {
            itemKey: PrefAndConsentPivotTabTypes.Accounts,
            hasContactPreference: isInvoicePreferencesConsentsExist,
            headerText: PrefAndConsentPivotTabTypes.Accounts
          },

          {
            itemKey: PrefAndConsentPivotTabTypes.Appointments,
            hasContactPreference: isAppointmentPreferencesConsentsExist,
            headerText: PrefAndConsentPivotTabTypes.Appointments
          },

          {
            itemKey: PrefAndConsentPivotTabTypes.Forms,
            hasContactPreference: isFormsPreferenceConsentsExist,
            headerText: PrefAndConsentPivotTabTypes.Forms
          }
        ];

        return data;
      };

      const renderElement = (pivotItem: PrefAndConsentPivotTabTypes) => (
        <PatientPreferencesConsentsPivotTabs
          contactPreferences={contactPreferences}
          selectedTab={pivotItem}
        />
      );

      let defaultSelectedKey: string;
      if (isAppointmentPreferencesConsentsExist) {
        defaultSelectedKey = PrefAndConsentPivotTabTypes.Appointments;
      } else if (isInvoicePreferencesConsentsExist) {
        defaultSelectedKey = PrefAndConsentPivotTabTypes.Accounts;
      } else {
        defaultSelectedKey = PrefAndConsentPivotTabTypes.Appointments;
      }

      return (
        <Stack verticalFill tokens={{ childrenGap: 8 }}>
          {showEditIcon ? (
            <Stack verticalFill tokens={{ childrenGap: 8 }}>
              {patient.interpreterLanguage && (
                <PatientNeedInterpreter patient={patient} />
              )}
              <Pivot
                styles={{ linkIsSelected: { paddingLeft: 10 } }}
                defaultSelectedKey={defaultSelectedKey}
              >
                {getPivotPatientPreferencesConsentsPivotTabsArray().map(
                  pivotItem => (
                    <PivotItem
                      key={pivotItem.itemKey}
                      headerText={pivotItem.headerText}
                      itemKey={pivotItem.itemKey}
                      style={
                        !pivotItem.hasContactPreference
                          ? { ...noRelationships }
                          : undefined
                      }
                    >
                      <div>
                        {pivotItem.hasContactPreference &&
                          renderElement(pivotItem.itemKey)}
                        {!pivotItem.hasContactPreference &&
                          `No ${pivotItem.headerText} preferences`}
                      </div>
                    </PivotItem>
                  )
                )}
              </Pivot>
            </Stack>
          ) : (
            <NoDetailsButton
              sectionId={PatientCardIds.prefCons}
              actionTitle="Add a preference & consent"
              noPermissionText="No preferences & consents"
              secondaryText="Account bill to, Appointment reminder"
              onHandleEditModal={onHandleEditModal}
            />
          )}
        </Stack>
      );
    }
  );
