import { useForm } from "react-final-form";

import {
  CessationAdvice,
  CessationDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { getDropdownStyles } from "./IntakeForm.styles.tsx";
import { IntakeFormItem } from "./IntakeForm.types.ts";

interface IntakeCessationFieldProps {
  name: string;
  index: number;
  isGiven: boolean;
  isEdit: boolean;
}

export enum CessationFieldPlaceholder {
  Given = "Given",
  NotGiven = "Not given"
}

export const IntakeCessationField: React.FunctionComponent<
  IntakeCessationFieldProps
> = ({ name, index, isGiven, isEdit }) => {
  const nameOfCessation = nameOfFactory<CessationDto & { types: string[] }>();
  const nameOf = nameOfFactory<IntakeFormItem>();
  const { change } = useForm();
  let title: string = isGiven
    ? CessationFieldPlaceholder.Given
    : CessationFieldPlaceholder.NotGiven;

  const onRenderTitle = () => {
    return <span>{title}</span>;
  };

  return (
    <>
      <DropdownField
        name={`${name}[${index}].${nameOf("cessation")}.${nameOfCessation(
          "types"
        )}`}
        placeholder={title}
        onRenderTitle={onRenderTitle}
        calloutProps={{ calloutWidth: 300 }}
        multiSelect
        options={[
          {
            key: CessationAdvice.BriefSmokingCessAdvGiven,
            text: "Brief cessation advice given"
          },
          {
            key: CessationAdvice.SmokingCessBehaviourSupport,
            text: "Cessation behaviour support provided"
          },
          {
            key: CessationAdvice.PrescribedSmokingCessMedication,
            text: "Prescribed cessation medication"
          },
          {
            key: CessationAdvice.RefToSmokingCessSupport,
            text: "Referred to cessation support"
          },
          {
            key: CessationAdvice.RefusedSmokingCessSupport,
            text: "Refused cessation support"
          },
          {
            key: CessationAdvice.RiskManagement,
            text: "Risk management"
          }
        ]}
        styles={getDropdownStyles(isEdit)}
      />
      <FieldSpy
        name={`${name}[${index}].${nameOf("cessation")}.${nameOfCessation(
          "types"
        )}`}
        onChange={(cessationAdviceList: string[]) => {
          if (cessationAdviceList) {
            if (cessationAdviceList.length === 0) {
              change(
                `${name}[${index}].${nameOf("cessation")}.${nameOfCessation(
                  "isAdviceGiven"
                )}`,
                false
              );
              title = CessationFieldPlaceholder.NotGiven;
            } else {
              change(
                `${name}[${index}].${nameOf("cessation")}.${nameOfCessation(
                  "isAdviceGiven"
                )}`,
                true
              );
              title = CessationFieldPlaceholder.Given;
            }
          }
        }}
      />
    </>
  );
};
