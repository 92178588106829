import { ParsedQuery } from "query-string";

import { RoutePermissionOptions } from "@libs/routing/types/route-permission-options.interface.ts";

export interface IActionSet {
  renderDirection?: RenderDirection;
  actions: INotificationAction[];
}

export interface INotificationAction extends RoutePermissionOptions {
  key?: string;
  text: string;
  onClick: () => void;
}

export interface NotificationBehaviour extends RoutePermissionOptions {
  key?: string;
  text: string;
  url?: string;
  onClick?: (context?: { [key: string]: string }) => void;
  query?: ParsedQuery;
}

export interface NotificationSubtypeBehaviourCollection {
  [key: string]: {
    renderDirection?: RenderDirection;
    behaviours: NotificationBehaviour[];
  };
}

export interface NotificationTypeBaseBehaviour {
  renderDirection?: RenderDirection;
  behaviours?: NotificationBehaviour[]; // default behaviour for type, if no subtypes
  subtypes?: NotificationSubtypeBehaviourCollection;
}

export interface NotificationTypeBehaviourCollection {
  [key: string]: NotificationTypeBaseBehaviour;
}

export enum RenderDirection {
  Vertical = "Vertical",
  Horizontal = "Horizontal"
}
