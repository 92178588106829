import {
  IPickerItemProps,
  ISuggestionItemProps,
  ITag,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";

import { AppointmentTypeTagItem } from "./AppointmentTypeTagItem.tsx";

type AppointmentTypePickerProps = {
  name: string;
  disabled?: boolean;
  onRemoveAppointmentType: () => void;
};
export const AppointmentTypePicker: React.FunctionComponent<
  AppointmentTypePickerProps
> = ({ name, disabled, onRemoveAppointmentType }) => {
  const { booking } = useStores();
  const theme = useTheme();
  const getItems = () =>
    booking.allAppointmentTypes
      .filter(x => !x.isInternalStatic)
      .map(({ id, name, isInactive, isGroupAppointmentType }) => ({
        key: id,
        name,
        isInactive,
        isGroupAppointmentType
      }));

  const exclusionDataSource = (
    items: (ITag & { isInactive: boolean })[],
    selectedItemKeys: string[]
  ) =>
    items.filter(
      (item: ITag & { isInactive: boolean }) =>
        !item.isInactive || item.key === selectedItemKeys[0]
    );
  return (
    <StaticPickerField
      name={name}
      label="Type"
      fetchDataSource={getItems}
      required
      fieldItemStyles={{
        root: { flexGrow: 2.8, flexBasis: 0, overflow: "hidden" }
      }}
      onRenderSuggestionsItem={(
        suggestionItem: ITag & {
          isGroupAppointmentType: boolean;
        },
        itemProps: ISuggestionItemProps<ITag>
      ) => {
        return (
          <Stack
            horizontal
            styles={{
              root: { width: "100%", textAlign: "left", padding: "2px 4px" }
            }}
          >
            <AppointmentTypeTagItem
              badgeColor={theme.palette.neutralLighter}
              badgeText={
                suggestionItem.isGroupAppointmentType
                  ? "Group appointment"
                  : undefined
              }
              index={0}
              item={suggestionItem}
              isSuggestionText
              {...itemProps}
            />
          </Stack>
        );
      }}
      onRenderItem={(
        props: IPickerItemProps<ITag>,
        item: ITag & {
          isGroupAppointmentType: boolean;
        }
      ) => {
        return (
          <AppointmentTypeTagItem
            badgeColor={theme.palette.neutralLighter}
            badgeText={
              item.isGroupAppointmentType ? "Group appointment" : undefined
            }
            onRemoveAppointmentType={onRemoveAppointmentType}
            {...props}
          />
        );
      }}
      disabled={disabled}
      excludeItemsFilter={exclusionDataSource}
    />
  );
};
