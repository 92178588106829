import { Observer, observer } from "mobx-react-lite";
import { MutableRefObject } from "react";

import {
  DetailsListLayoutMode,
  DtoDetailsRow,
  IDetailsHeaderProps,
  IDetailsRowProps,
  SelectionMode,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { useMedicationsContext } from "./helpers/MedicationsContext.tsx";
import { MedicationListContextMenu } from "./MedicationListContextMenu.tsx";

interface CurrentMedicationsListProps {
  clinicalRecord: ClinicalRecord;
  selection: MutableRefObject<
    Selection<
      MedicationClinicalDataItemDto & {
        key: string;
      }
    >
  >;
}

export const CurrentMedicationsList: React.FC<CurrentMedicationsListProps> =
  observer(({ clinicalRecord, selection }) => {
    const { clinical } = useStores();

    const MAX_NUM_OF_MEDS = 3;
    const medications =
      clinicalRecord.clinicalData?.medication?.medications.filter(
        x => !x.isCeased && !x.isDeleted
      ) ?? [];

    const sortedMedications = Array.from(medications).sort((a, b) =>
      a.productName.localeCompare(b.productName)
    );

    const helper = useMedicationsContext();

    const renderDetailsHeader = (
      props: IDetailsHeaderProps,
      defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
    ) => {
      if (!defaultRender || !props) return null;

      return defaultRender({
        ...props,
        styles: {
          root: {
            selectors: {
              "div.ms-DetailsHeader-cellIsCheck": {
                display: "none"
              },
              "span.ms-DetailsHeader-cellTitle": { paddingLeft: 60 }
            }
          }
        }
      });
    };

    const getMedicationsTableColumns = () => {
      return [
        {
          fieldName: "contextMenu",
          key: "contextMenu",
          minWidth: 32,
          maxWidth: 32,
          name: "",
          onRender: (record: MedicationClinicalDataItemDto) => {
            return <MedicationListContextMenu medication={record} />;
          }
        },

        {
          fieldName: "productName",
          key: "productName",
          minWidth: 200,
          maxWidth: 200,
          isMultiline: true,
          name: "Name",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) => (
            <Stack verticalAlign="center">{record.productName}</Stack>
          )
        },
        {
          fieldName: "dose",
          key: "dose",
          minWidth: 325,
          maxWidth: 325,
          isMultiline: true,
          name: "Dose details",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) =>
            helper.getDoseDetails(record)
        },
        {
          fieldName: "quantity",
          key: "quantity",
          minWidth: 50,
          maxWidth: 50,
          name: "Qty",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) => record.quantity
        },
        {
          fieldName: "repeats",
          key: "repeats",
          minWidth: 50,
          maxWidth: 50,
          name: "Rpts",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) => record.repeats
        },
        {
          fieldName: "script",
          key: "type",
          minWidth: 65,
          maxWidth: 65,
          name: "Script",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) => {
            return clinical.ref.rxTypes.keyTextValues.find(
              x => x.key === record.rxType
            )?.text;
          }
        },
        {
          fieldName: "lastRx",
          key: "lastRx",
          minWidth: 70,
          maxWidth: 70,
          name: "Last Rx",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) =>
            record.lastRx &&
            DateTime.fromISO(record.lastRx).toDayDefaultFormat()
        },
        {
          fieldName: "firstRx",
          key: "firstRx",
          minWidth: 70,
          maxWidth: 70,
          name: "First Rx",
          isResizable: true,
          onRender: (record: MedicationClinicalDataItemDto) =>
            record.firstRx &&
            DateTime.fromISO(record.firstRx).toDayDefaultFormat()
        }
      ];
    };

    return (
      <ShimmeredDetailsList
        items={sortedMedications}
        setKey="set"
        onRenderRow={(props: IDetailsRowProps) => {
          return (
            <Observer>
              {() => {
                const selectedPackIds = helper.selectedCurrentMeds.map(
                  x => x.packId
                );

                const disabled =
                  !selectedPackIds.includes(
                    (props.item as MedicationClinicalDataItemDto).packId
                  ) && helper.selectedCurrentMeds.length === MAX_NUM_OF_MEDS;

                return <DtoDetailsRow {...props} disabled={disabled} />;
              }}
            </Observer>
          );
        }}
        onRenderDetailsHeader={renderDetailsHeader}
        stickyHeader
        layoutMode={DetailsListLayoutMode.fixedColumns}
        columns={getMedicationsTableColumns()}
        checkButtonAriaLabel="Row checkbox"
        selectionMode={SelectionMode.multiple}
        selection={selection.current}
        selectionPreservedOnEmptyClick={true}
      />
    );
  });
