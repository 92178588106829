import { fabric } from "fabric";

import { Options } from "../DrawingCanvas.types.ts";

export interface FabricProps
  extends Omit<React.CanvasHTMLAttributes<HTMLCanvasElement>, "onChange"> {
  // Background image URL used of the canvas
  // Can be a [Data Url](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs)
  backgroundImageUrl?: string;

  // The drawing brush used for free drawing. Default is a Pencil brush.
  // See http://fabricjs.com/fabric-intro-part-4#free_drawing
  // freeDrawingBrush?: fabric.FreeDrawingBrush;

  // when true, the canvas is in a free drawing mode. http://fabricjs.com/docs/fabric.Canvas.html#isDrawingMode
  isDrawingMode?: boolean;

  // the active (selected) object. When multiple object are selected, the active object is a group.
  // Setting to null discard the selection.
  activeObject?: fabric.Object | null;

  // callback to get access to the underlying fabric.Canvas object once it is created.
  canvasRef?: (canvas: fabric.Canvas) => void;

  // called everytime the canvas objects change
  onChange: (canvas: fabric.Canvas, event?: fabric.IEvent) => void;

  /**
   * Initial value of the canvas as SVG string
   * It assumes that if the first deserialized object is an Image, it gets loaded
   * as a background image.
   */
  initialValue?: string;

  // called when the selection (active objects) changes
  onSelectionChange?: (
    activeObjects: fabric.Object[] | null,
    event?: fabric.IEvent
  ) => void;

  /**
   * Whether or not to embed images as Data URL or to reference the external src.
   */
  embedImages?: boolean;
  handleCanvasFocused: (canvas: fabric.Canvas) => void;
  options: Options;
  backgroundDisplayOption?: BackgroundDisplayOption;
}

export enum BackgroundDisplayOption {
  ScaleToFit = 1,
  Center = 2
}
