import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  CommandBarButton,
  Dropdown,
  SearchBox,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { usePrescribingWizardContext } from "./helpers/PrescribingWizardContext.tsx";

export const PrescribingFormulationsFilter: FunctionComponent = observer(() => {
  const { drugs } = useStores();
  const theme = useTheme();
  const helper = usePrescribingWizardContext();

  const { formulations, selectedProduct, formulationsSearch } = helper;

  const listingCodes = formulations
    ? formulations.map(formulation => formulation.productPack.pbsListing)
    : null;

  const restrictionsOptions = drugs.ref.pbsListings.keyTextValues.filter(
    x => !listingCodes || listingCodes.includes(x.key)
  );

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      styles={{
        root: {
          background: theme.palette.neutralLighterAlt,
          padding: 4,
          borderRadius: 2
        }
      }}
    >
      <Stack horizontal grow>
        <CommandBarButton
          iconProps={{ iconName: "ChevronLeftMed" }}
          styles={{ root: { backgroundColor: "transparent" } }}
          onClick={() => {
            runInAction(() => {
              helper.productsLoading = false;
              helper.selectedProduct = undefined;
              helper.selectedFormulation = undefined;
              helper.formulations = [];
              helper.warnings = [];
              helper.formulationsSearch = undefined;
              helper.formulationsRestriction = {};
            });
          }}
        />

        <Stack grow>
          <SearchBox
            iconProps={{ iconName: "Filter" }}
            placeholder={`Filter ${selectedProduct?.name} formulations`}
            onChange={(_e, value) => {
              runInAction(() => {
                helper.formulationsSearch = value;
              });
            }}
            value={formulationsSearch || ""}
            styles={{
              root: {
                backgroundColor: "transparent",
                border: "none",
                selectors: {
                  "&.is-active": {
                    border: "none"
                  }
                }
              }
            }}
          />
        </Stack>
      </Stack>
      <Stack>
        <Dropdown
          name="filter-restrictions"
          multiSelect
          placeholder="Filter restrictions"
          onChange={(e, option) => {
            if (option) {
              const { key } = option;
              runInAction(() => {
                helper.formulationsRestriction[key] =
                  !helper.formulationsRestriction[key];
              });
            }
          }}
          options={restrictionsOptions}
          styles={{ title: { backgroundColor: "transparent", border: "none" } }}
        />
      </Stack>
    </Stack>
  );
});
