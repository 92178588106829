import { Heading, MessageBar, MessageBarType } from "@bps/fluent-ui";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SendClinicalDocumentDialogValidator } from "./SendClinicalDocumentDialogValidator.tsx";

interface SendClinicalDocumentDialogProps {
  onDismiss: () => void;
  onSubmitSucceeded?: () => void;
  onSubmit: SubmissionFormProps<SendClinicalDocumentDialogValues>["onSubmit"];
  patientId?: string;
  documentTitle: string;
  extension: string;
}

export interface SendClinicalDocumentDialogValues {
  email: string;
}

const validator = new SendClinicalDocumentDialogValidator();

export const SendClinicalDocumentDialog: React.FC<
  SendClinicalDocumentDialogProps
> = ({
  onDismiss,
  onSubmitSucceeded,
  onSubmit,
  patientId,
  documentTitle,
  extension
}) => {
  return (
    <DataFetcher<Contact | undefined>
      fetch={({ practice }) =>
        patientId ? practice.getContact(patientId) : Promise.resolve(undefined)
      }
      noExceptionsHandlers
      refetchId={patientId}
    >
      {(contact, loading, error) => {
        const initialValues:
          | Partial<SendClinicalDocumentDialogValues>
          | undefined = contact?.email ? { email: contact?.email } : undefined;

        return (
          <SubmissionFormDialog<SendClinicalDocumentDialogValues>
            dialogName="Clinical record - Email document dialog"
            onSubmit={onSubmit}
            onSubmitSucceeded={onSubmitSucceeded}
            validate={validator.validate}
            loadingData={loading}
            dataLoadingError={error?.message}
            buttonsProps={{
              submitButtonProps: {
                text: "Send email",
                iconProps: { hidden: true }
              },
              disableSubmitOnPristine: !initialValues
            }}
            dialogProps={{
              onDismiss,
              minWidth: 500,
              dialogContentProps: {
                title: (
                  <Heading variant="modal-heading">
                    Email document {documentTitle}.{extension}
                  </Heading>
                )
              }
            }}
            initialValues={initialValues}
          >
            {() => (
              <Fieldset
                tokens={{ childrenGap: 16 }}
                styles={{ root: { overflow: "hidden" } }}
              >
                <MessageBar messageBarType={MessageBarType.warning}>
                  Email is an unsecure communication method, we recommend other
                  methods for sending documents
                </MessageBar>
                <TextInputField
                  name={nameof<SendClinicalDocumentDialogValues>("email")}
                  label="Recipient's email"
                  required
                />
              </Fieldset>
            )}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
};
