import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useFormState } from "react-final-form";

import { PrimaryButton } from "@bps/fluent-ui";
import { useAppointmentTypeScreenContext } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";

import { SubmitButtonType } from "../AppointmentTypeDialog.tsx";
import { AppointmentTypeFormValues } from "../AppointmentTypeForm.types.ts";

interface Props {
  onClickSubmit: () => void;
  onOpenSetupOnlineModal: () => void;
}

export const ExtraActionsBetween: FunctionComponent<Props> = observer(
  ({ onClickSubmit, onOpenSetupOnlineModal }) => {
    const { SaveAndSetupOnline, SetupOnline } = SubmitButtonType;
    const { appointmentType } = useAppointmentTypeScreenContext();

    const { dirty, values } = useFormState<AppointmentTypeFormValues>();
    const buttonType =
      !dirty && !!appointmentType ? SetupOnline : SaveAndSetupOnline;

    const isGroupAppointmentType =
      values.maxParticipants !== undefined && values.maxParticipants > 1;

    const isDisabled =
      values.isInactive ||
      isGroupAppointmentType ||
      (!appointmentType && !dirty);

    return (
      <>
        {buttonType === SaveAndSetupOnline && (
          <PrimaryButton
            form="AppointmentType"
            type="submit"
            iconProps={{ iconName: "Globe" }}
            text="Save & set up online"
            disabled={isDisabled}
            onClick={onClickSubmit}
          />
        )}
        {buttonType === SetupOnline && (
          <PrimaryButton
            iconProps={{ iconName: "Globe" }}
            text="Set up online"
            disabled={isDisabled}
            onClick={onOpenSetupOnlineModal}
          />
        )}
      </>
    );
  }
);
