import { QuestionnaireDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

export interface GRCSFormValues {
  diagnosisKey?: string;
  side?: string;
  answer: string;
  confidential?: boolean;
}

export const grcsFormNameOf = nameOfFactory<GRCSFormValues>();

export const CLINICAL_TOOLS_FORM_HEADING = "Global Rating of Change Scale";

export interface GRCSFormProps {
  clinicalRecord: ClinicalRecord;
  questionnaire: QuestionnaireDto;
}
