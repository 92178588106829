import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { Spinner } from "@bps/fluent-ui";
import { BillingStoredDocumentDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { AccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { EmailConfirmationModal } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.tsx";

interface SendStatementMenuItemProps {
  statementId: string;
}

export const SendStatementMenuItem: FC<SendStatementMenuItemProps> = observer(
  ({ statementId }) => {
    const { billing } = useStores();

    const { accountContact } = useContext(AccountScreenContext);

    return (
      <DataFetcher<BillingStoredDocumentDto>
        fetch={() =>
          billing.getBillingStoredDocument({
            contactId: accountContact.id,
            statementId
          })
        }
        fallback={<Spinner />}
      >
        {storedDocument => {
          const { documentId, date } = storedDocument;
          if (!documentId) return null;

          return (
            <>
              <span style={{ margin: "0 4px" }}>
                {accountContact.billingOptedOut ? "Email (opted out)" : "Email"}
              </span>
              {billing.ui.isStatementEmailDialogVisible && (
                <EmailConfirmationModal
                  accountContactId={accountContact.id}
                  onDismiss={() => {
                    billing.ui.setStatementEmailDialogVisible(false);
                  }}
                  onSubmit={async ({ email }) => {
                    billing.sendStatementEmail({
                      statementId,
                      documentId,
                      emailAddress: email
                    });
                    billing.ui.setStatementEmailDialogVisible(false);
                  }}
                  statementDate={date}
                  commType={OutboundCommType.Statement}
                />
              )}
            </>
          );
        }}
      </DataFetcher>
    );
  }
);
