import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

interface InvoiceItemListPurchaseOrderSpyProps {
  rowIndex: number;
}

export const InvoiceItemListPurchaseOrderSpy: FunctionComponent<InvoiceItemListPurchaseOrderSpyProps> =
  observer(props => {
    const form = useForm();

    return (
      <FieldSpy
        name={
          `invoiceItems[${props.rowIndex}].purchaseOrderNumber` as keyof InvoiceFormValues
        }
        onChange={(value: InvoiceItemFormValue["purchaseOrderNumber"]) => {
          form.change(
            `invoiceItems[${props.rowIndex}].purchaseOrderNumber`,
            value
          );
        }}
      />
    );
  });
