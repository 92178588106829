import { Shimmer, Stack } from "@bps/fluent-ui";

export const ConditionModalFallback = (
  <Stack
    styles={{ root: { padding: "0 24px 24px 24px" } }}
    tokens={{ childrenGap: 8 }}
  >
    <Shimmer styles={{ shimmerWrapper: { height: 61 } }} width={144} />
    <Shimmer styles={{ shimmerWrapper: { height: 49 } }} width={100} />
    <Shimmer styles={{ shimmerWrapper: { height: 75 } }} />

    <Stack
      horizontal
      tokens={{ childrenGap: 8 }}
      styles={{ root: { marginLeft: "auto", paddingTop: 32 } }}
    >
      <Shimmer styles={{ shimmerWrapper: { height: 32 } }} width={80} />
      <Shimmer styles={{ shimmerWrapper: { height: 32 } }} width={80} />
    </Stack>
  </Stack>
);
