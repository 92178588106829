import { upsertItem } from "@bps/utils";
import { AgentClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ReactionAgentKind } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { DrugsStore } from "@stores/drugs/DrugsStore.ts";

import { ReactionFormTypes } from "./ReactionForm.types.ts";
import { removeReactionFromClinicalData, toReactionType } from "./utils.ts";

export class ReactionFormModel {
  constructor(
    private clinicalRecord: ClinicalRecord,
    private drugs: DrugsStore
  ) {}

  getAgentText = async (
    type: string,
    agentId: string
  ): Promise<string | undefined> => {
    if (type === ReactionAgentKind.DrugClass) {
      const response = await this.drugs.getDrugClass(agentId);
      return response.description;
    } else if (type === ReactionAgentKind.Ingredient) {
      const response = await this.drugs.getIngredient(agentId);
      return response.ingredientName;
    } else if (type === ReactionAgentKind.NonDrug) {
      const response = await this.drugs.getNonDrug(agentId);
      return response.preferredTerm;
    }

    return undefined;
  };

  onSubmit = async (allValues: ReactionFormTypes) => {
    const { deletedReactions, ...values } = allValues;
    const [agentId, type] = values.reaction!.split(".");

    const reactionType = toReactionType(type as ReactionAgentKind);
    let agents = Array.from(
      this.clinicalRecord.clinicalData?.reaction?.agents || []
    );
    let reactions = Array.from(
      this.clinicalRecord.clinicalData?.reaction?.reactions || []
    );

    const agentText = await this.getAgentText(type, agentId);

    for (const x of values.naturesOfReaction) {
      if (x.natureOfReaction && x.severity && agentText) {
        reactions = upsertItem({
          array: reactions,
          item: {
            id: x.id,
            type: reactionType,
            natureOfReaction: x.natureOfReaction,
            severity: x.severity,
            agent: {
              code: agentId,
              originalText: agentText
            },
            certainty: values.certainty,
            otherText: values.otherText
          },
          predicate: y =>
            (x.id && y.id === x.id) ||
            (y.agent.code === agentId &&
              y.type === reactionType &&
              y.natureOfReaction === x.natureOfReaction &&
              y.otherText === x.otherText)
        });
      }
    }

    const agentIndex = agents.findIndex(x => x.agent.code === agentId);

    if (agentIndex === -1 && agentText) {
      const newAgent: AgentClinicalDataItemDto = {
        agent: { code: agentId, originalText: agentText }
      };
      if (values.comment) {
        newAgent.comment = values.comment;
      }

      agents.push(newAgent);
    } else {
      if (values.comment) {
        agents[agentIndex].comment = values.comment;
      }
    }

    for (const id in deletedReactions) {
      const deletedReaction = deletedReactions[id];
      const { reactions: newReactions, agents: newAgents } =
        removeReactionFromClinicalData({
          reaction: {
            agents,
            reactions
          },
          reactionAgentId: deletedReaction.agent?.code,
          natureOfReaction: deletedReaction.natureOfReaction,
          reasonForDelete: deletedReaction.reasonForDelete,
          reasonForDeleteComment: deletedReaction.deletedComment
        });
      agents = newAgents;
      reactions = newReactions;
    }

    await this.clinicalRecord.saveClinicalData({
      reaction: {
        eTag: this.clinicalRecord.clinicalData?.reaction?.eTag,
        agents,
        reactions,
        nilKnown: false
      }
    });
  };
}
