import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";

import {
  Dropdown,
  FontWeights,
  IDropdownOption,
  IDropdownProps,
  Link,
  mergeStyles,
  NoDataTile,
  noWrap,
  Stack,
  StackItem,
  Text
} from "@bps/fluent-ui";
import { formatCalendarDate, SHORT_CALENDAR_FORMATS } from "@bps/utils";
import {
  ClinicalNoteSections,
  ClinicalNotesSubHeadings,
  NoteType,
  TodaysNotes,
  TodaysNotesHeading,
  TodaysNotesStructuredNote
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { UserDto } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { Amendment } from "@stores/clinical/models/Amendments.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { EncounterReasonForVisitText } from "../clinical-form/EncounterReasonForVisitText.tsx";
import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";
import { PastVisitAmendments } from "../past-visit/PastVisitAmendments.tsx";

export interface PreviousEncounterNotes {
  noteType: string;
  encounter: Encounter;
  notes: TodaysNotesHeading[] | undefined;
  amendments: Amendment[];
  fullBodyImages: TodaysNotesStructuredNote | undefined;
  freeText: string | undefined;
}

interface Notes {
  clinicalNotes: TodaysNotesHeading[] | undefined;
  fullBodyImages: TodaysNotesStructuredNote | undefined;
  freeText: string | undefined;
}

interface PreEncounterNotesContentProps {
  content: PreviousEncounterNotes[];
}

export interface LatestEncounterProps {
  encounters: Encounter[];
  core: CoreStore;
  user: UserDto | undefined;
  showOnlyMyNotes: boolean | undefined;
  clinicalRecord: ClinicalRecord;
  clinical: ClinicalStore;
}

const getAddedByInfo = (encounter: Encounter, noteType: string) => {
  const date = encounter.startDateTime
    ? encounter.startDateTime.toDayDefaultFormat()
    : "";
  if (noteType === NoteType.CurrentUser) {
    return `${date} Your last notes`;
  } else {
    return encounter.user ? `${date}  ${encounter.user.fullName}` : "";
  }
};

const getDataByInfo = (encounter: Encounter, hasAccessToSecGroup: boolean) => {
  return {
    hasAccessToSecGroup,
    date: encounter.startDateTime
      ? encounter.startDateTime.toDayDefaultFormat()
      : "",
    text: encounter.user ? encounter.user.fullName : "",
    encounterId: encounter.id
  };
};

const onRenderOption = (option: IDropdownOption): JSX.Element => {
  return (
    <Stack horizontal styles={{ root: noWrap }} tokens={{ childrenGap: 4 }}>
      <Text>{option.data.date}</Text>
      {option.data && !option.data.hasAccessToSecGroup && (
        <ConfidentialToolTipFontIcon
          isShowConfidentialIcon={true}
          summaryStyling
        />
      )}
      <Text nowrap>{option.data.text}</Text>
    </Stack>
  );
};

export const getSortedEncounters = (encounters: Encounter[] | undefined) => {
  return encounters
    ? encounters?.sort((a: Encounter, b: Encounter) => {
        return 0 - (a.startDateTime > b.startDateTime ? 1 : -1);
      })
    : [];
};

const refactorClinicalNotes = (notes?: TodaysNotes): Notes => {
  if (!notes) {
    return {
      clinicalNotes: undefined,
      fullBodyImages: undefined,
      freeText: undefined
    };
  }

  const examinations = notes?.headings?.find(
    x => x.code === ClinicalNoteSections.Examinations
  );
  if (!examinations) {
    return {
      clinicalNotes: notes.headings,
      fullBodyImages: undefined,
      freeText: notes.freeText
    };
  }

  const fullBodyImages = examinations.structuredNotes?.find(
    x => x.type === ClinicalNotesSubHeadings.FullBodyImages
  );
  if (!fullBodyImages) {
    return {
      clinicalNotes: notes.headings,
      fullBodyImages: undefined,
      freeText: notes.freeText
    };
  }

  const otherExaminationSN = examinations.structuredNotes?.filter(
    x => x.type !== ClinicalNotesSubHeadings.FullBodyImages
  );
  examinations.structuredNotes = otherExaminationSN;

  const structuredNotes = notes?.headings?.filter(
    x => x.code !== ClinicalNoteSections.Examinations
  );

  const images = fullBodyImages && fullBodyImages.note.includes("id='images'");

  if (structuredNotes) {
    structuredNotes.unshift(examinations);
    if (!images) {
      return {
        clinicalNotes: structuredNotes,
        fullBodyImages: undefined,
        freeText: notes.freeText
      };
    }
    return {
      clinicalNotes: structuredNotes,
      fullBodyImages,
      freeText: notes.freeText
    };
  }

  const examinationsArray: TodaysNotesHeading[] = [];
  examinationsArray.push(examinations);
  if (!images) {
    return {
      clinicalNotes: examinationsArray,
      fullBodyImages: undefined,
      freeText: notes.freeText
    };
  }
  return {
    clinicalNotes: examinationsArray,
    fullBodyImages,
    freeText: notes.freeText
  };
};

export const getLatestEncounters = async (props: LatestEncounterProps) => {
  const encounterNotes: PreviousEncounterNotes[] = [];
  const isOnlyMine = props.showOnlyMyNotes ? props.showOnlyMyNotes : false;
  const user = props.user;
  const latestEncounter = props.encounters[0];

  if (props.encounters.length > 0 && user) {
    const rawClinicalNote = props.core.hasAccessToSecGroup(
      latestEncounter.secGroupId
    )
      ? await props.clinicalRecord.getEncounterClinicalNotes(
          latestEncounter?.id
        )
      : {};

    const { clinicalNotes, fullBodyImages, freeText } =
      refactorClinicalNotes(rawClinicalNote);

    const amendments = await props.clinical.fetchAmendments(
      latestEncounter?.id
    );

    //if the last note is not from the current user
    if (latestEncounter.userId === user?.id) {
      encounterNotes.push({
        noteType: NoteType.CurrentUser,
        encounter: props.encounters[0],
        notes: clinicalNotes,
        fullBodyImages,
        freeText,
        amendments: amendments.filter(
          i => i.encounterId === props.encounters[0].id
        )
      });
    } else {
      if (!isOnlyMine) {
        encounterNotes.push({
          noteType: NoteType.OtherUser,
          encounter: props.encounters[0],
          notes: clinicalNotes,
          fullBodyImages,
          freeText,
          amendments: props.clinical.amendments.filter(
            i => i.encounterId === props.encounters[0].id
          )
        });
      }

      const myLastEncounter = props.encounters.find(
        item => item.userId === user?.id
      );
      if (myLastEncounter) {
        const result = await props.clinicalRecord.getEncounterClinicalNotes(
          myLastEncounter?.id
        );

        const { clinicalNotes, fullBodyImages, freeText } =
          refactorClinicalNotes(result);

        //add latest encounter by the current user
        encounterNotes.push({
          noteType: NoteType.CurrentUser,
          encounter: myLastEncounter,
          notes: clinicalNotes,
          fullBodyImages,
          freeText,
          amendments: props.clinical.amendments.filter(
            i => i.encounterId === myLastEncounter.id
          )
        });
      }
    }
  }
  return encounterNotes;
};

export const PreEncounterNotesContent: React.FunctionComponent<PreEncounterNotesContentProps> =
  observer(({ content }) => {
    const [previousNotes, setPreviousNotes] = useState<
      PreviousEncounterNotes | undefined
    >(content[0]);

    const { core } = useStores();

    const [showImages, setShowImages] = useState<boolean>(false);

    const { setSelectedPreviousEncounterId } = usePatientRecordScreenContext();
    const onNoteTypeChanged = (
      selected: IDropdownOption,
      index: number | undefined
    ) => {
      const prevEncounterNotes = content.find(
        item => item.noteType === selected.key
      );
      setPreviousNotes(prevEncounterNotes);
      index !== undefined &&
        setSelectedPreviousEncounterId(selected.data?.encounterId);
    };

    const notesOptions = content.map(({ noteType, encounter }) => ({
      key: noteType,
      text: getAddedByInfo(encounter, noteType),
      data: getDataByInfo(
        encounter,
        core.hasAccessToSecGroup(encounter.secGroupId)
      )
    }));

    const showOptions =
      content.length > 1 ||
      (content.length === 1 && content[0].noteType === NoteType.OtherUser);

    const handleImages = () => {
      setShowImages(value => !value);
    };

    const renderEncounterNote = (previousNotes: PreviousEncounterNotes) => {
      return (
        <>
          <div
            className={mergeStyles({
              fontWeight: FontWeights.bold,
              marginTop: 8,
              marginBottom: 8
            })}
          >
            <EncounterReasonForVisitText encounter={previousNotes.encounter} />

            {!showOptions && (
              <Text
                styles={(props, theme) => ({
                  root: {
                    color: theme.palette.neutralSecondary
                  }
                })}
              >
                {formatCalendarDate(
                  previousNotes.encounter.startDateTime,
                  SHORT_CALENDAR_FORMATS
                )}
              </Text>
            )}
          </div>

          <Stack styles={{ root: { margin: "16px 0" } }}>
            {/*/ main doctor note /*/}
            {previousNotes.freeText !== "" && (
              <Stack tokens={{ childrenGap: 8 }}>
                <div
                  className={mergeStyles({ marginBottom: 8 })}
                  dangerouslySetInnerHTML={{
                    __html: previousNotes.freeText!
                  }}
                />
              </Stack>
            )}

            <Stack tokens={{ childrenGap: 4 }}>
              {previousNotes.notes &&
                previousNotes.notes.map(heading => {
                  return (
                    <Fragment key={heading.code}>
                      {/*/ heading name/*/}
                      <h3
                        className={mergeStyles({
                          fontWeight: FontWeights.bold
                        })}
                        dangerouslySetInnerHTML={{
                          __html: heading.name
                        }}
                      />
                      <Stack
                        tokens={{ childrenGap: 8 }}
                        styles={{ root: { marginBottom: 12 } }}
                      >
                        {heading.structuredNotes?.map(value => (
                          <Stack key={value.sectionCode}>
                            {value.type === ClinicalNotesSubHeadings.FullBody &&
                            previousNotes.fullBodyImages ? (
                              <Stack>
                                <StackItem
                                  styles={(props, theme) => ({
                                    root: {
                                      borderLeftWidth: 1,
                                      borderLeftColor:
                                        theme.palette.neutralLight,
                                      borderLeftStyle: "solid",
                                      paddingLeft: "8px",
                                      overFlowY: "auto"
                                    }
                                  })}
                                >
                                  <Text
                                    styles={(props, theme) => ({
                                      root: {
                                        color: theme.palette.themeDarker,
                                        fontWeight: "600"
                                      }
                                    })}
                                  >
                                    {value.type}
                                  </Text>
                                  <div
                                    key={value.sectionCode}
                                    dangerouslySetInnerHTML={{
                                      __html: value.note
                                    }}
                                  />

                                  <Link onClick={handleImages}>
                                    {showImages ? "Hide images" : "Show images"}
                                  </Link>
                                  {showImages && (
                                    <div
                                      key="fullBody"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          previousNotes.fullBodyImages.note
                                      }}
                                    />
                                  )}
                                </StackItem>
                              </Stack>
                            ) : (
                              <StackItem
                                styles={(props, theme) => ({
                                  root: {
                                    borderLeftWidth: 1,
                                    borderLeftColor: theme.palette.neutralLight,
                                    borderLeftStyle: "solid",
                                    paddingLeft: "8px",
                                    overFlowY: "auto"
                                  }
                                })}
                              >
                                <Text
                                  styles={(props, theme) => ({
                                    root: {
                                      color: theme.palette.themeDarker,
                                      fontWeight: "600"
                                    }
                                  })}
                                >
                                  {value.type}
                                </Text>
                                <div
                                  key={value.sectionCode}
                                  dangerouslySetInnerHTML={{
                                    __html: value.note
                                  }}
                                />
                              </StackItem>
                            )}
                          </Stack>
                        ))}
                      </Stack>

                      {/*/ section note /*/}
                      {heading.freeText && (
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                          <div
                            className={mergeStyles({ marginBottom: 12 })}
                            dangerouslySetInnerHTML={{
                              __html: heading.freeText
                            }}
                          />
                        </Stack>
                      )}
                    </Fragment>
                  );
                })}
            </Stack>
          </Stack>
          <PastVisitAmendments amendments={previousNotes.amendments} />
        </>
      );
    };

    return (
      <>
        {previousNotes && (
          <Stack
            styles={{
              root: {
                overflowY: "auto"
              }
            }}
          >
            {showOptions ? (
              <Dropdown
                name="note-type"
                options={notesOptions}
                onChange={(evt, option: IDropdownOption, index) =>
                  onNoteTypeChanged(option, index)
                }
                onRenderOption={onRenderOption}
                onRenderPlaceholder={(props: IDropdownProps) =>
                  onRenderOption(props.options[0])
                }
                onRenderTitle={(options: IDropdownOption[]) =>
                  onRenderOption(options[0])
                }
                styles={{
                  root: {
                    paddingBottom: 5
                  }
                }}
              />
            ) : undefined}
            {core.hasAccessToSecGroup(previousNotes.encounter.secGroupId) ? (
              renderEncounterNote(previousNotes)
            ) : (
              <NoDataTile
                textProps={{
                  text: `Confidential (by ${core.getUserByPrivateSecGroupId(
                    previousNotes.encounter.secGroupId
                  )?.fullName})`
                }}
                showBoxShadow={false}
                greyView={true}
                linkProps={{ hidden: true }}
              />
            )}
          </Stack>
        )}
      </>
    );
  });
