import { DATE_FORMATS, DateTime } from "@bps/utils";
import { EmploymentStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  isDateAfterField,
  isDateBeforeField,
  isDateSameOrAfterDate,
  isNotFutureDate,
  maxLength,
  predicate,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimEmployerAddressDraftValidator } from "@modules/acc/screens/claim-adjustment/validators/ClaimEmployerAddressDraftValidator.tsx";

import { AccidentAndEmploymentDetailsFormValidatorValues } from "../types/accident-and-employment-details-form-validator-value.type.ts";

const nameOf = nameOfFactory<AccidentAndEmploymentDetailsFormValidatorValues>();

export class AccidentAndEmploymentDetailsDraftValidator extends Validator<AccidentAndEmploymentDetailsFormValidatorValues> {
  constructor() {
    super();

    const claimEmployerAddressDraftValidator =
      new ClaimEmployerAddressDraftValidator();

    this.forField("causeOfAccident", [maxLength(255)]);

    this.forField(nameOf("accidentDate"), [
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isNotFutureDate(),
      isDateAfterField(
        messageWithData(
          ValidationMessages.accidentDateEqualOrBefore,
          "patient"
        ),
        nameOf("patientDeclaration")
      ),

      isDateAfterField(
        messageWithData(
          ValidationMessages.accidentDateEqualOrBefore,
          "provider"
        ),
        nameOf("providerDeclaration")
      ),

      isDateSameOrAfterDate(
        DateTime.fromISO("1972-01-01").toJSDate(),
        ValidationMessages.noDateBefore1972
      ),
      predicate(
        (val, values?: AccidentAndEmploymentDetailsFormValidatorValues) =>
          !!values?.patientDateOfBirth && !!val,
        isDateBeforeField(
          ValidationMessages.afterBirthdate,
          nameOf("patientDateOfBirth"),
          true
        )
      )
    ]);

    this.forField(
      nameOf("employerAddress"),
      predicate(
        (val, values) =>
          values?.employmentStatus === EmploymentStatuses.IAmAnEmployee &&
          !!values?.workInjury,
        claimEmployerAddressDraftValidator.validate
      )
    );

    this.forField("otherEmployment", [maxLength(50)]);
  }
}
