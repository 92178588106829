import { Stack, Text } from "@bps/fluent-ui";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { sectionHeader } from "../social/SocialForm.tsx";
import { DominantHandSingleChoice } from "./DominantHandSingleChoice.tsx";
import { PhysicalActivitiesList } from "./PhysicalActivitiesList.tsx";
import { physicalActivityFormNameOf } from "./PhysicalActivityForm.types.ts";

interface PhysicalActivityFormFieldsProps {
  clinicalRecord: ClinicalRecord;
}

export const PhysicalActivityFormFields: React.FunctionComponent<
  PhysicalActivityFormFieldsProps
> = ({ clinicalRecord }) => {
  const getButtonOptions = (withNoDefaultOption: boolean) => {
    const options = [
      { key: "Y", text: "Yes" },
      { key: "N", text: "No" },
      { key: "", text: "Have not asked" }
    ];

    return withNoDefaultOption
      ? options.filter(val => val.key !== "")
      : options;
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <ButtonsGroupSingleChoiceField
        label="Is elite athlete"
        name={physicalActivityFormNameOf("isEliteAthlete")}
        options={getButtonOptions(true)}
      />

      <DominantHandSingleChoice
        name={physicalActivityFormNameOf("dominantHand")}
      />
      <SpinNumberInputField
        label="Hours per day spent sedentary"
        name={physicalActivityFormNameOf("hoursSpentAtSedentary")}
        min={0}
        max={24}
        step={1}
        fieldItemStyles={{
          itemWrapper: { alignItems: "baseline" },
          item: { flexGrow: 0 }
        }}
        // SpinNumberInput component has a min width of 86
        styles={{ root: { width: 86 } }}
      />
      <Stack tokens={{ childrenGap: 8, maxWidth: 540 }}>
        <Text styles={sectionHeader}>Activities</Text>
        <PhysicalActivitiesList clinicalRecord={clinicalRecord} />
      </Stack>
    </Stack>
  );
};
