import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Stack
} from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ErrorAlert } from "@ui-components/Alert.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { EncounterFormContext } from "./models/EncounterFormContext.ts";

interface ProviderCommentFormValues {
  providerComment: string;
}

// exported to allow for easier testing
export const CANCEL_BTN_ID = "provider-comment-dialog-cancel-button";
export const DELETE_BTN_ID = "provider-comment-dialog-delete-button";
export const SUBMIT_BTN_ID = "provider-comment-dialog-submit-button";

const nameof = nameOfFactory<ProviderCommentFormValues>();

export function getProviderCommentDialogTitle(providerComment?: string) {
  return `${providerComment ? "Edit" : "Add a"} comment for the front office`;
}

export const ProviderCommentDialog: React.FC = observer(() => {
  const [errorMessage, setErrorMessage] = useState("");

  const {
    closeProviderCommentDialog,
    isProviderCommentDialogVisible,
    providerComment,
    setProviderComment
  } = useContext(EncounterFormContext);

  if (!isProviderCommentDialogVisible) return null;

  const initialValues: ProviderCommentFormValues = {
    providerComment: providerComment || ""
  };

  const onDelete = async () => {
    try {
      await setProviderComment("");
      closeProviderCommentDialog();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onSubmit = (values: ProviderCommentFormValues) =>
    setProviderComment(values.providerComment || "");

  const extraActionsBefore = () => {
    return (
      <Stack.Item styles={{ root: { flexGrow: 1 } }}>
        {providerComment && (
          <DefaultButton
            {...dataAttribute(DataAttributes.Element, DELETE_BTN_ID)}
            text="Remove comment"
            onClick={onDelete}
          />
        )}
      </Stack.Item>
    );
  };

  const renderForm = () => {
    return (
      <>
        <TextInputField
          multiline
          name={nameof("providerComment")}
          label="Appointment comment"
          resizable={false}
          rows={4}
        />
        {errorMessage && <ErrorAlert error={errorMessage} />}
      </>
    );
  };

  const dialogContentProps = {
    title: getProviderCommentDialogTitle(providerComment),
    showCloseButton: true
  };

  const submitButtonProps = {
    ...dataAttribute(DataAttributes.Element, SUBMIT_BTN_ID),
    text: "Save comment",
    iconProps: {}
  };

  const cancelButtonProps = {
    ...dataAttribute(DataAttributes.Element, CANCEL_BTN_ID),
    text: "Cancel",
    iconProps: {}
  };

  return (
    <SubmissionFormDialog
      dialogName="Encounter form - provider comment dialog"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitSucceeded={closeProviderCommentDialog}
      dialogProps={{
        onDismiss: closeProviderCommentDialog,
        dialogContentProps,
        minWidth: 600
      }}
      render={renderForm}
      buttonsProps={{
        submitButtonProps,
        cancelButtonProps,
        extraActionsBefore
      }}
    />
  );
});
