import { DATE_FORMATS } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isNotFutureDate,
  predicate,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { PatientDetailsFormValues } from "@shared-types/acc/patient-details-values.type.ts";

import { ClaimAddressDraftValidator } from "./ClaimAddressDraftValidator.tsx";

export class PatientDetailsDraftValidator extends Validator<PatientDetailsFormValues> {
  constructor() {
    super();
    const claimAddressDraftValidator = new ClaimAddressDraftValidator();
    this.forField("patientAddress", claimAddressDraftValidator.validate);
    this.forField(nameof("mostRecentSurgeryDate"), [
      predicate(
        (val, values) => !!values?.accRelatedSurgery,
        validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
        isNotFutureDate(ValidationMessages.futureDateEqualOrLess)
      )
    ]);
    this.forField(
      nameof("patientDateOfBirth"),
      isNotFutureDate(ValidationMessages.futureDateEqualOrLess)
    );
  }
}
