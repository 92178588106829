import { Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { quickAffContactFormNameOf } from "./QuickAddContactFormValues.tsx";

const QuickAddOrgContactFormFieldsBase: React.FunctionComponent = () => {
  const { core, practice } = useStores();

  const { countries } = core.ref;

  const countryCode = core.tenantDetails?.country!;
  const isStateRequired = (value: string) => {
    return countries.values.some(c => c.code === value && c.isStateRequired);
  };
  return (
    <Stack
      tokens={{ childrenGap: 8 }}
      styles={{ root: { position: "relative", width: "100%" } }}
    >
      <TextInputField
        autoFocus
        name={quickAffContactFormNameOf("organisationName")}
        label="Name"
        required={true}
      />
      <StaticTagPickerField
        fetchDataSource={() =>
          practice.ref.organisationCategories.keyNameValues
        }
        excludeSelectedItems
        label="Categories"
        name={quickAffContactFormNameOf("organisationCategories")}
        styles={{
          text: { flexGrow: 0 }
        }}
        required
      />
      <Fieldset horizontal>
        <TextInputField
          label="Suburb"
          name={quickAffContactFormNameOf("suburb")}
        />
        {isStateRequired(countryCode) ? (
          <DropdownField
            name={quickAffContactFormNameOf("state")}
            label="State"
            styles={{ root: { width: "50%" } }}
            options={core.ref.australianStates.keyTextValues}
          />
        ) : (
          <TextInputField
            label="City"
            name={quickAffContactFormNameOf("city")}
          />
        )}
        <TextInputField
          label="Postcode"
          name={quickAffContactFormNameOf("postCode")}
          styles={{ root: { width: "60%" } }}
        />
      </Fieldset>
      <TextInputField name={quickAffContactFormNameOf("phone")} label="Phone" />
    </Stack>
  );
};

export const QuickAddOrgContactFormFields = withFetch(
  x => [
    x.practice.ref.organisationCategories.load(),
    x.core.ref.australianStates.load()
  ],
  QuickAddOrgContactFormFieldsBase
);
