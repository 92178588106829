import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useForm, useFormState } from "react-final-form";

import { dataAttribute, DataAttributes, Stack, useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";

import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import { UserWorkingHoursOverridesScreenContext } from "../context/UserWorkingHoursOverridesScreenContext.tsx";
import {
  UserWorkingHoursOverridesFieldsProps,
  UserWorkingHoursOverridesFormValues,
  UserWorkingHoursOverridesValues,
  UserWorkingHoursTestElements
} from "../UserWorkingHoursOverridesScreen.types.ts";

const atWork = (value: string) => value === "1";

export const UserWorkingHoursOverridesFields: React.FC<UserWorkingHoursOverridesFieldsProps> =
  observer(({ initialValues }) => {
    // Removed fields need to be tracked otherwise the useEffect will add them back
    //   as soon as they are removed
    const removedFields = useRef<string[]>([]);
    const mounted = useRef(false);

    const form = useForm<UserWorkingHoursOverridesValues>();
    const { change, reset } = form;

    const { pristine } = useFormState<UserWorkingHoursOverridesFormValues>({
      subscription: { pristine: true }
    });

    const { onSubmit } = useContext(UserWorkingHoursOverridesScreenContext);

    const { core } = useStores();

    const { fields } = useFieldArray("overrides");

    const resetForm = useCallback(() => {
      reset(initialValues);
      removedFields.current = [];
    }, [initialValues, reset]);

    // As there are two linked forms, adding or modifying rows in one form might result
    // in those rows being added to the other form.
    // This hook detects those changes and pushes them to the form when they are added
    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true;
        return;
      }
      if (pristine) {
        resetForm();
      }
    }, [pristine, resetForm]);

    const showForm = !!fields.length;
    const showSubmitButtons =
      !!fields.length || initialValues.overrides.length > 0;

    const theme = useTheme();
    const { formFooter } = getUserStylesSet(theme);
    return (
      <>
        {showForm && (
          <div
            {...dataAttribute(
              DataAttributes.Element,
              UserWorkingHoursTestElements.FieldsWrapper
            )}
          >
            {fields.map((name, index) => {
              return (
                <Stack
                  key={name}
                  styles={{
                    root: {
                      margin: "0 64px 0 64px",
                      paddingTop: 16,
                      paddingBottom: 16,
                      "&:not(:first-child)": {
                        borderTop: "1px solid",
                        borderColor: theme.palette.neutralLight,
                        marginTop: 0
                      },
                      "&:first-child": {
                        paddingTop: 32
                      },
                      "&:last-child": {
                        paddingBottom: 32
                      }
                    }
                  }}
                  {...dataAttribute(
                    DataAttributes.Id,
                    fields.value[index].id || "new"
                  )}
                  {...dataAttribute(
                    DataAttributes.Element,
                    UserWorkingHoursTestElements.FieldsRow
                  )}
                >
                  <Fieldset horizontal styles={{ root: { width: "100%" } }}>
                    <DropdownField
                      label="At work"
                      name={`${name}.atWork`}
                      options={[
                        { key: "1", text: "Yes" },
                        { key: "0", text: "No" }
                      ]}
                    />
                    <DatePickerField
                      label="From"
                      placeholder="Start"
                      name={`${name}.startDate`}
                    />
                    <FieldSpy
                      name={`${name}.startDate`}
                      onChange={(date: Date) => {
                        const endDate = fields.value[index].endDate;
                        if (date && endDate && endDate < date) {
                          change(`${name}.endDate` as any, date);
                        }
                      }}
                    />
                    <DatePickerField
                      label="Until"
                      placeholder="End"
                      name={`${name}.endDate`}
                    />
                    <FieldSpy
                      name={`${name}.endDate`}
                      onChange={(date: Date) => {
                        const startDate = fields.value[index].startDate;
                        if (date && startDate && date < startDate) {
                          change(`${name}.startDate` as any, date);
                        }
                      }}
                    />
                    <FieldCondition when={`${name}.atWork`} is={atWork}>
                      <TimePickerField
                        disabled={!core.hasUserSettingWritePermission}
                        label="Start time"
                        suggestionInterval={30}
                        name={`${name}.startTime`}
                      />
                      <TimePickerField
                        disabled={!core.hasUserSettingWritePermission}
                        label="End time"
                        suggestionInterval={30}
                        name={`${name}.endTime`}
                      />
                    </FieldCondition>
                    <TextInputField
                      disabled={!core.hasUserSettingWritePermission}
                      styles={{ root: { flexGrow: 1 } }}
                      name={`${name}.reason`}
                      label="Reason"
                    />
                    <Stack verticalAlign="start">
                      <DeleteButton
                        text="Remove"
                        disabled={!core.hasUserSettingWritePermission}
                        onClick={() => {
                          const id = fields.value[index].id;
                          if (id) {
                            removedFields.current.push(id);
                          }
                          fields.remove(index);
                        }}
                        styles={{ root: { marginTop: 29 } }}
                      />
                    </Stack>
                  </Fieldset>
                </Stack>
              );
            })}
          </div>
        )}
        {showSubmitButtons && (
          <FormSubmitButtons
            {...dataAttribute(
              DataAttributes.Element,
              UserWorkingHoursTestElements.SubmitButtons
            )}
            disableCancelOnPristine
            onCancel={resetForm}
            promptOnCancel
            styles={{ root: formFooter }}
            submitButtonProps={{
              onClick: event => {
                event.preventDefault();
                onSubmit(form);
              }
            }}
          />
        )}
      </>
    );
  });
