import {
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  useResizeElementObserver
} from "@bps/fluent-ui";
import {
  ClinicalDataType,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolForm } from "../clinical-tool-form/ClinicalToolForm.tsx";
import { EPDSQuestions } from "./EPDSQuestions.tsx";

interface EPDSQuestionnaireFormProps {
  clinicalRecord: ClinicalRecord;
}

const QUESTION_10_NEVER_ANSWER = "NEVR";

export const EPDSQuestionnaireForm: React.FC<EPDSQuestionnaireFormProps> = ({
  clinicalRecord
}) => {
  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.EPDSV1)}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <ClinicalToolForm
          formName="EPDS"
          clinicalRecord={clinicalRecord}
          clinicalDataType={ClinicalDataType.EPDS}
          formHeading="EPDS"
          questionnaire={questionnaire}
          boldDiagnosis
          onRenderModalContent={(diagnosis, measurement, response) => {
            const warningAnswer = response?.items.find(
              x =>
                x.questionnaireItemId === 10 &&
                x.answer !== QUESTION_10_NEVER_ANSWER
            );

            return (
              <Stack tokens={{ childrenGap: 8 }}>
                <MessageBar>{diagnosis}</MessageBar>
                {warningAnswer && (
                  <MessageBar messageBarType={MessageBarType.warning}>
                    The response to question 10 indicates that further
                    evaluation and management of their self-harm risk is
                    suggested before leaving the office. This is to ensure the
                    safety of the woman and her baby.
                  </MessageBar>
                )}
              </Stack>
            );
          }}
        >
          <>
            <div
              ref={r => {
                if (r && !element) {
                  setElement(r);
                }
              }}
            />
            <EPDSQuestions questionnaire={questionnaire} width={panelWidth} />
          </>
        </ClinicalToolForm>
      )}
    </DataFetcher>
  );
};
