import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { confirm } from "@bps/fluent-ui";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

export type PatientActivationProps = {
  patientId: string;
};

/**
 * PatientActivation accepts a patient id.
 * In the event the patient is Inactive, it will
 * render an information message with an action to activate the patient.
 */

const activatePromiseWrapper = maybePromiseObservable<any>();

export const PatientActivation: FunctionComponent<PatientActivationProps> =
  observer(({ patientId }) => {
    const { practice, notification } = useStores();

    const handleActivatePatient = async (id: string) => {
      const isConfirmed = await confirm({
        confirmButtonProps: {
          text: "Confirm"
        },
        dialogContentProps: {
          title: "Change to active",
          subText: "If you proceed, this inactive patient will become active."
        }
      });

      if (isConfirmed) {
        try {
          activatePromiseWrapper.set(
            practice.updatePatient({
              id,
              contactStatus: ContactStatus.Active
            })
          );
        } catch (error) {
          notification.error(error.message);
        }
      }
    };

    return (
      <ContactFetcher contactId={patientId}>
        {patient => {
          if (!patient || patient.status !== ContactStatus.Inactive) {
            return null;
          }

          return (
            <div onClick={() => handleActivatePatient(patient.id)}>
              Make active
            </div>
          );
        }}
      </ContactFetcher>
    );
  });
