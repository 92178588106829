import { observer } from "mobx-react-lite";
import { useFormState } from "react-final-form";

import {
  FontSizes,
  FontWeights,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  StackItem,
  Text
} from "@bps/fluent-ui";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";

import { PatientInfoCard } from "./PatientInfoCard.tsx";
import { PatientMergeFieldValues } from "./PatientMergeFieldValues.ts";

interface PatientPickerWithInfoProps {
  type: MessageBarType;
  label: string;
  formNameId: "masterId" | "duplicateId";
  formNamePatient: "master" | "duplicate";
  formNameStatus: string;
  borderColor: string;
  excludePatientId?: string;
}

export const PatientPickerWithInfo: React.FC<PatientPickerWithInfoProps> =
  observer(
    ({
      type,
      label,
      formNameId,
      formNameStatus,
      borderColor,
      excludePatientId
    }) => {
      const { practice } = useStores();
      const patientLabel = usePatientLabel(false);
      const { values } = useFormState<PatientMergeFieldValues>();

      const patientId = values[formNameId];

      const contactStatusOptions = practice.ref.contactStatuses.values
        .map(({ code, text }) => ({
          key: code,
          text: capitalizeSentence(text.toLowerCase())
        }))
        .filter(opt => opt.key !== ContactStatus.Merged);

      return (
        <Stack styles={{ root: { flex: 1 } }} tokens={{ childrenGap: 0 }}>
          <MessageBar messageBarType={type}>
            <Text bold variant="medium">
              {label}
            </Text>
          </MessageBar>
          <StackItem
            styles={{
              root: { border: `1px solid ${borderColor}`, padding: 8 }
            }}
          >
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 8 }}
            >
              <ContactPickerField
                name={formNameId}
                fieldItemStyle={{ root: { width: "100%" } }}
                iconName="Search"
                placeholder={`Search ${patientLabel}`}
                filter={{
                  statuses: [values[formNameStatus]],
                  types: [ContactType.Patient]
                }}
                excludeItemsFilter={items =>
                  items.filter(i => i.id !== excludePatientId)
                }
              />

              <DropdownField
                name={formNameStatus}
                options={contactStatusOptions}
                styles={{
                  root: { maxWidth: 105 },
                  title: {
                    borderWidth: 0,
                    fontSize: FontSizes.size14,
                    fontWeight: FontWeights.semibold
                  },
                  callout: { minWidth: 100 }
                }}
                disabled={!!patientId}
                withNoEmptyOption
              />
            </Stack>
            {patientId && (
              <ContactFetcher contactId={patientId} fallback={<Spinner />}>
                {patient => <PatientInfoCard patient={patient} />}
              </ContactFetcher>
            )}
          </StackItem>
        </Stack>
      );
    }
  );
