import { stringify } from "query-string";
import { useMemo, useState } from "react";

import {
  ContextualMenuItemType,
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  BillingStatuses,
  InvoiceItemView,
  TransactionReferenceDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { useAccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { CancelWriteOffDialog } from "@modules/billing/screens/invoice/write-off/components/CancelWriteOffDialog.tsx";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import {
  isAllocation,
  isCreditNote,
  isInvoice,
  isInvoiceItemReferenceTransaction,
  isPayment,
  isRefund,
  isWriteOff
} from "@stores/billing/utils/transaction.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ItemTypeText } from "@ui-components/RefText.tsx";

interface Props {
  transaction: TransactionBase;
}

export const AccountTransactionContextualMenu: React.FC<Props> = ({
  transaction
}) => {
  const { core, billing, routing } = useStores();

  const { setModalId } = useAccountScreenContext();

  const [showCancelWriteOff, setShowCancelWriteOff] = useState(false);

  const hasPayItem = isInvoice(transaction) && !transaction.isFullyPaid;
  const doesNotHavePdfItem =
    transaction.isAdjusted ||
    transaction.isCancelled ||
    isRefund(transaction) ||
    isCreditNote(transaction);

  const hasWriteOffCancelPermissions = core.hasPermissions([
    Permission.WriteOffAllowed,
    Permission.WriteOffCancel
  ]);

  const hasWriteOffCreatePermissions = core.hasPermissions([
    Permission.WriteOffAllowed,
    Permission.WriteOffCreate
  ]);

  const activityRows: IContextualMenuItem[] = useMemo(() => {
    let relatedTransactions:
      | TransactionReferenceDto["transaction"][]
      | InvoiceItemView["transaction"][] = [];

    if (isInvoiceItemReferenceTransaction(transaction)) {
      relatedTransactions = transaction.invoiceReferences;
    } else if (isInvoice(transaction)) {
      relatedTransactions = transaction.transactionReferences;
    }

    return relatedTransactions.map(
      (
        relatedTransaction:
          | TransactionReferenceDto["transaction"]
          | InvoiceItemView["transaction"]
      ) => {
        const transactionDate = relatedTransaction.transactionDate
          ? DateTime.fromISO(
              relatedTransaction.transactionDate
            ).toDayDefaultFormat()
          : "";

        const transactionNumber =
          relatedTransaction.status === BillingStatuses.adjusted
            ? "Adjustment"
            : `${relatedTransaction.itemType} ${relatedTransaction.number}`;

        const transactionAmount = currencyFormat(relatedTransaction.total, {
          currency: ""
        });

        const viewTransaction: IContextualMenuItem["onClick"] = event => {
          event?.preventDefault();
          setModalId(relatedTransaction.id, relatedTransaction.itemType);
          return true;
        };

        return {
          key: transactionNumber,
          onClick: viewTransaction,
          onRenderContent: () => {
            return (
              <Stack
                horizontal
                tokens={{ childrenGap: 16 }}
                styles={{ root: { marginLeft: 4, marginRight: 4 } }}
              >
                <Stack styles={{ root: { width: 75 } }}>
                  {transactionDate}
                </Stack>
                <Stack styles={{ root: { width: 128 } }}>
                  {transactionNumber === BillingStatuses.adjusted ? (
                    transactionNumber
                  ) : (
                    <>
                      <ItemTypeText code={relatedTransaction.itemType} />{" "}
                      {relatedTransaction.number}
                    </>
                  )}
                </Stack>
                <Stack>{transactionAmount}</Stack>
              </Stack>
            );
          }
        };
      }
    );
  }, [setModalId, transaction]);

  const menuItems = [
    {
      key: "Pay",
      text: "Pay",
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        const accountId = routing.match(routes.accounts.account)?.params.id;
        routing.pushWithFromQuery({
          pathname: routes.accounts.allocations.new.pattern,
          search: stringify({
            invoiceId: transaction.id,
            accountId
          })
        });
      },
      data: {
        filter: hasPayItem
      }
    },
    {
      key: "Activity",
      text: "Activity",
      subMenuProps: {
        items: activityRows
      },
      data: {
        filter: !!activityRows.length
      }
    },
    {
      key: "PDF",
      text: "PDF",
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (isInvoice(transaction)) {
          billing.openInvoicePdf(transaction.id);
        }

        if (isAllocation(transaction)) {
          billing.openAllocationReceiptPdf(transaction.id);
        }

        if (isPayment(transaction)) {
          billing.openPaymentReceiptPdf(transaction.id);
        }
      },
      data: {
        filter: !doesNotHavePdfItem
      }
    },
    {
      key: "divider1",
      itemType: ContextualMenuItemType.Divider,
      data: {
        filter: hasPayItem && hasWriteOffCreatePermissions
      }
    },
    {
      key: "Write off",
      text: "Write off",
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        routing.pushWithFromQuery({
          pathname: routes.accounts.invoices.writeOff.new.path({
            id: transaction.id
          })
        });
      },
      data: {
        filter: hasPayItem && hasWriteOffCancelPermissions
      }
    },
    {
      key: "cancelWriteOff",
      text: "Cancel write-off",
      onClick: () => {
        setShowCancelWriteOff(true);
      },
      data: {
        filter:
          isWriteOff(transaction) &&
          !transaction.isCancelled &&
          hasWriteOffCancelPermissions
      }
    }
  ].filter(x => x.data.filter === undefined || x.data.filter);

  // It is possible for some transaction to have no valid actions, in this case
  // simply do not show a menu at all
  if (menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton
        {...dataAttribute(
          DataAttributes.Element,
          "account-transaction-menu-button"
        )}
        menuIconProps={{ iconName: "More" }}
        menuProps={{ items: menuItems }}
        styles={{
          root: { height: "36px" },
          flexContainer: { height: "36px" }
        }}
      />
      {isWriteOff(transaction) && (
        <CancelWriteOffDialog
          writeOff={transaction}
          hidden={!showCancelWriteOff}
          onDismiss={() => setShowCancelWriteOff(false)}
          onSubmitSucceeded={() => setShowCancelWriteOff(false)}
        />
      )}
    </>
  );
};
