import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import {
  dataAttribute,
  DataAttributes,
  flatten,
  Heading,
  Link,
  Stack,
  Text,
  Tile
} from "@bps/fluent-ui";
import { DateTime, groupBy } from "@bps/utils";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { AppointmentStatusText } from "@ui-components/RefText.tsx";

import { CalendarEventView } from "../../types/CalendarEventView.types.ts";
import { AppointmentBillingStatusFilter } from "./AppointmentBillingStatusFilter.tsx";
import {
  AppointmentFilterStatus,
  apptTypes,
  BillingStatus,
  ClinicalStatus,
  combinedTypes
} from "./AppointmentFilter.types.ts";
import { useAppointmentFilterScroll } from "./useAppointmentFilterScroll.tsx";
import { getLinkText, getSelectedFilterStyles } from "./utils.ts";

export const AppointmentsFilter: React.FC = observer(() => {
  const { practice, booking } = useStores();
  const {
    setAppointmentsFilter,
    appointmentsFilter,
    dayOrWeekView,
    startDate,
    filteredCalendarEvents
  } = useBookingCalendarScreenContext();

  const {
    ui: { newContactType }
  } = practice;

  const trackEvent = useAppTrackEvent(
    AppInsightsEventNames.appointmentFilterCount
  );

  // subscribe newContactType to close appointment filter overlay when PatientDialog is opened
  const trackPatientDialog = reaction(
    () => newContactType,
    () => {
      // close the app filter overlay if open
      if (appointmentsFilter) {
        setAppointmentsFilter();
      }
    }
  );

  const events = filteredCalendarEvents.filter(e => {
    if (
      e.type === CalendarEventType.Appointment &&
      e.appointmentStatus &&
      apptTypes.includes(e.appointmentStatus)
    ) {
      if (dayOrWeekView === CalendarEventView.Day) {
        return startDate.hasSame(e.startDateTime, "day");
      } else if (dayOrWeekView === CalendarEventView.Week) {
        return e.startDateTime.isBetweenInclusive(
          startDate.startOf("week"),
          startDate.endOf("week")
        );
      } else {
        return false;
      }
    }

    return false;
  });

  useAppointmentFilterScroll(events, appointmentsFilter);

  // unsubscribe newContactType
  useEffect(() => {
    return () => {
      trackPatientDialog();
    };
  }, [trackPatientDialog]);

  const eventsGroupedByAppStatus = groupBy<
    CalendarEvent,
    AppointmentFilterStatus
  >(events, e => e.appointmentStatus!);

  const eventsGroupedByBillingStatus = groupBy<
    CalendarEvent,
    AppointmentFilterStatus | undefined
  >(events, calendarEvent => {
    if (calendarEvent.isUnbilled) {
      return BillingStatus.Unbilled;
    }

    return calendarEvent.invoiceStatus;
  });

  const getEventsByBillingStatus = (
    status: AppointmentFilterStatus | AppointmentFilterStatus[]
  ) => {
    if (Array.isArray(status)) {
      const groups = eventsGroupedByBillingStatus.filter(group =>
        group[0] ? status.includes(group[0]) : false
      );

      const events = flatten(groups.map(([, events]) => events));
      return events;
    } else {
      const group = eventsGroupedByBillingStatus.find(
        group => group[0] === status
      );

      const events = group ? group[1] : [];
      return events;
    }
  };

  const incompleteNotesCount = events.reduce((n, event) => {
    if (event.hasOpenEncounters) {
      return n + 1;
    }

    return n;
  }, 0);

  const getText = (status: AppointmentFilterStatus): string | undefined => {
    switch (status) {
      case combinedTypes.appointementStatuses.find(x => x === status):
        return booking.ref.appointmentStatuses.get(status)?.text;
      case combinedTypes.invoiceStatuses.find(x => x === status):
        return practice.ref.invoiceStatuses.get(status)?.text;
      case BillingStatus.Unbilled:
        return "Unbilled";
      case ClinicalStatus.incompleteNotes:
        return "Incomplete notes";
      default:
        return undefined;
    }
  };

  const handleOnClick = (status: AppointmentFilterStatus) => {
    if (status !== appointmentsFilter) {
      trackEvent({
        filter: getText(status),
        dateTime: DateTime.now().toISODate()
      });
    }
    if (status === appointmentsFilter) {
      setAppointmentsFilter();
    } else {
      setAppointmentsFilter(status);
    }
  };

  /**
   * if a status has been changes, and after the appointments status
   * filter is 0 => clear filter, otherwise render 0 counts
   */
  const handleNoItems = (status: AppointmentFilterStatus) => {
    if (status === appointmentsFilter) {
      setAppointmentsFilter();
      return null;
    }
    return <Text>0</Text>;
  };

  return (
    <Stack.Item
      grow
      shrink
      // ⚠️ before you do any changes!
      // z-index value depends on few others z-indexes in:
      // 1. Calendar.styles.tsx - time column;
      // 2. AppointmentsFilterOverlay.tsx;
      // 3. BookingCalendar.tsx - events styling;
      styles={{ root: { maxHeight: 400, minHeight: 150, zIndex: 50 } }}
    >
      <Tile
        styles={{
          root: { height: "100%" },
          content: {
            display: "flex",
            flexDirection: "column",
            overflowY: "auto"
          }
        }}
      >
        <Heading
          {...dataAttribute(
            DataAttributes.Element,
            "appointment-filter-heading"
          )}
          labelPaddings
          styles={{ root: { marginBottom: 10 } }}
        >{`${
          dayOrWeekView === CalendarEventView.Day ? "Today's" : "Week's"
        } appointments (${events.length})`}</Heading>
        <Stack tokens={{ childrenGap: 16 }}>
          <Stack tokens={{ childrenGap: 3 }}>
            {Object.values(apptTypes).map(status => {
              const group = eventsGroupedByAppStatus.find(
                group => group[0] === status
              );

              const events = group ? group[1] : [];

              return (
                <Stack
                  horizontal
                  key={`today-appts-${status}`}
                  tokens={{ childrenGap: 10 }}
                  {...dataAttribute(
                    DataAttributes.Element,
                    `today-appts-${status}`
                  )}
                  styles={getSelectedFilterStyles(status, appointmentsFilter)}
                >
                  <Stack.Item>
                    <AppointmentStatusText code={status} />:
                  </Stack.Item>
                  {events.length > 0 ? (
                    <Link
                      onClick={() => handleOnClick(status)}
                      {...dataAttribute(
                        DataAttributes.Element,
                        `today-appts-${status}-button`
                      )}
                    >
                      {getLinkText(events.length, status, appointmentsFilter)}
                    </Link>
                  ) : (
                    handleNoItems(status)
                  )}
                </Stack>
              );
            })}
          </Stack>

          <Stack
            {...dataAttribute(
              DataAttributes.Element,
              "today-appts-draft-notes"
            )}
            horizontal
            tokens={{ childrenGap: 10 }}
            styles={getSelectedFilterStyles(
              ClinicalStatus.incompleteNotes,
              appointmentsFilter
            )}
          >
            <Stack.Item>Incomplete notes:</Stack.Item>
            {incompleteNotesCount === 0 ? (
              handleNoItems(ClinicalStatus.incompleteNotes)
            ) : (
              <Link
                onClick={() => handleOnClick(ClinicalStatus.incompleteNotes)}
                {...dataAttribute(
                  DataAttributes.Element,
                  "today-appts-draft-notes-button"
                )}
              >
                {getLinkText(
                  incompleteNotesCount,
                  ClinicalStatus.incompleteNotes,
                  appointmentsFilter
                )}
              </Link>
            )}
          </Stack>

          <Stack tokens={{ childrenGap: 3 }}>
            <AppointmentBillingStatusFilter
              status={BillingStatus.Unbilled}
              handleClick={() => handleOnClick(BillingStatus.Unbilled)}
              events={getEventsByBillingStatus(BillingStatus.Unbilled)}
            />
            <AppointmentBillingStatusFilter
              status={InvoiceStatus.Unpaid}
              handleClick={() => handleOnClick(InvoiceStatus.Unpaid)}
              events={getEventsByBillingStatus([
                InvoiceStatus.Unpaid,
                InvoiceStatus.Part
              ])}
            />
            <AppointmentBillingStatusFilter
              status={InvoiceStatus.Paid}
              handleClick={() => handleOnClick(InvoiceStatus.Paid)}
              events={getEventsByBillingStatus(InvoiceStatus.Paid)}
            />
          </Stack>
        </Stack>
      </Tile>
    </Stack.Item>
  );
});
