import { DATE_FORMATS, DateTime, TIME_FORMATS } from "@bps/utils";
import { OrgUnitAvailability } from "@stores/booking/models/OrgUnitAvailability.ts";

export const getStandardOpeningHours = (
  date: DateTime,
  availability: OrgUnitAvailability
): { startTime: DateTime; endTime: DateTime } | undefined => {
  const stdOpeningHours = availability.openingHours.find(
    x => x.recurrenceRule?.dayRecur?.find(i => i === date.weekday)
  );
  if (!stdOpeningHours) return undefined;

  const startTime = DateTime.fromISO(stdOpeningHours!.startTime);
  const endTime = DateTime.fromISO(stdOpeningHours!.endTime);
  return { startTime, endTime };
};

export const openingHoursInfoMessage = (
  date: DateTime,
  availability: OrgUnitAvailability
): string => {
  const startEndTime = getStandardOpeningHours(date, availability);
  if (!startEndTime)
    return `Practice normally closed on a ${date.toFormat(
      DATE_FORMATS.DAY_OF_WEEK_STRING
    )}`;

  const startTime = startEndTime!.startTime;
  const endTime = startEndTime!.endTime;
  const durationHours = endTime!.diff(startTime, ["hours"]).hours;
  return `${date.toFormat(
    DATE_FORMATS.DAY_OF_WEEK_STRING
  )}'s hours are usually ${startTime.toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  )} to ${endTime.toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  )} (${durationHours}hrs)`;
};

export const getReason = (
  availability: OrgUnitAvailability | undefined,
  date: DateTime
): string | undefined => {
  return availability?.openingHoursOverrides.find(
    x =>
      DateTime.fromISO(x.startDate).startOf("day").toMillis() ===
        date.startOf("day").toMillis() &&
      DateTime.fromISO(x.endDate).startOf("day").toMillis() ===
        date.startOf("day").toMillis()
  )?.reason;
};
