import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Spinner } from "@bps/fluent-ui";
import {
  BillingStoredDocumentDto,
  GetBillingStoredDocumentArgs
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { EmailConfirmationModal } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.tsx";

interface EmailStatementModalProps {
  statementDocumentPreviewData:
    | PartialBy<GetBillingStoredDocumentArgs, "contactId">
    | undefined;
}

export const EmailStatementModal: FC<EmailStatementModalProps> = observer(
  ({ statementDocumentPreviewData }) => {
    const {
      billing: {
        sendStatementEmail,
        fetchPreferencesAndDocument,
        ui: { setStatementEmailDialogVisible, setStatementDocumentPreviewData }
      }
    } = useStores();

    if (!statementDocumentPreviewData) return null;

    const { contactId, statementId } = statementDocumentPreviewData;

    if (!contactId || !statementId) return null;

    return (
      <DataFetcher<{
        storedDocument: BillingStoredDocumentDto;
      }>
        fetch={async () =>
          await fetchPreferencesAndDocument({ contactId, statementId })
        }
        fallback={<Spinner />}
      >
        {({ storedDocument }) => {
          if (storedDocument.documentId) {
            return (
              <EmailConfirmationModal
                accountContactId={contactId}
                onDismiss={() => {
                  setStatementEmailDialogVisible(false);
                }}
                onSubmit={async ({ email }) => {
                  await sendStatementEmail({
                    statementId,
                    documentId: storedDocument.documentId!,
                    emailAddress: email
                  });
                  setStatementEmailDialogVisible(false);
                  setStatementDocumentPreviewData(undefined);
                }}
                statementDate={storedDocument.date}
                commType={OutboundCommType.Statement}
              />
            );
          }
          return null;
        }}
      </DataFetcher>
    );
  }
);
