import { FunctionComponent } from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { FormDesignValues } from "@shared-types/form-design/form-design-form-values.interface.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { FormDesignGroupList } from "./FormDesignGroupList.tsx";

const nameOf = nameOfFactory<FormDesignValues>();

export const FormDesignFields: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Stack>
      <Stack
        styles={{
          root: {
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            borderColor: theme.palette.neutralLight,
            paddingBottom: 16,
            marginBottom: 20
          }
        }}
      >
        <TextInputField
          required
          name={nameOf("name")}
          label="Name"
          maxLength={50}
          styles={{ root: { marginTop: 16 } }}
        />
        <CheckboxField
          label="Display system notifications for the response to this form"
          name={nameOf("notificationSetting")}
          styles={{ root: { marginTop: 16 } }}
        />
      </Stack>

      <Text styles={{ root: { fontSize: 16 } }}>Form content</Text>
      <Fieldset>
        <TextInputField
          required
          name={nameOf("title")}
          label="Title"
          maxLength={100}
          styles={{ root: { marginTop: 16 } }}
        />
        <TextInputField
          name={nameOf("description")}
          label="Subtitle"
          maxLength={100}
          styles={{ root: { marginTop: 16 } }}
        />
        <Stack
          horizontal
          tokens={{ childrenGap: 16 }}
          styles={{ root: { justifyContent: "space-between" } }}
        >
          <FormDesignGroupList />
        </Stack>
      </Fieldset>
    </Stack>
  );
};
