import { useState } from "react";

import {
  DefaultButton,
  DetailsList,
  Dialog,
  flatten,
  FontSizes,
  FontWeights,
  Heading,
  IColumn,
  Spinner,
  Stack
} from "@bps/fluent-ui";
import { DateTime, isDefined } from "@bps/utils";
import {
  MeasurementType,
  PSFSMultipleQuestionnaireResponseDto,
  QuestionnaireType,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { getSideOfBodyText } from "@modules/clinical/utils/clinical.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { SERIES_PREFIX } from "../../SOTAP/outcome-measure/OutcomeMeasuresCardModel.ts";
import { SeriesQuestionAnswers } from "./PSFSTrendDialog.types.ts";
import { createDynamicPsfsAnswerObject, getAverages } from "./utils.ts";

interface PSFSTrendDialogProps {
  clinicalRecord: ClinicalRecord;
  contextId: string;
  onClose?: () => void;
}

interface PSFSTrendModel {
  answersArray: SeriesQuestionAnswers[];
  columns: IColumn[];
}

export const PSFSTrendDialog: React.FunctionComponent<PSFSTrendDialogProps> = ({
  clinicalRecord,
  contextId,
  onClose
}) => {
  const { clinical } = useStores();
  const [isHidden, setIsHidden] = useState(false);
  const encounterId = clinicalRecord.openEncounter?.id;
  const psfsSeriesContext =
    clinicalRecord.clinicalData?.psfsContext?.contexts.find(
      c => c.contextId === contextId
    );

  const getEncounterPSFSData = async (encounterId: string) => {
    const data = (await clinical.getEncounterClinicalData({ encounterId }))[
      MeasurementType.PSFS.toLocaleLowerCase()
    ] as PSFSMultipleQuestionnaireResponseDto;

    if (data && data.responses) {
      return data.responses.filter(r => r.contextId === contextId);
    }
    return undefined;
  };

  const getPSFSTrendData =
    () =>
    async ({ clinical }: RootStore): Promise<PSFSTrendModel> => {
      const result = await clinical.getMeasurements({
        patientId: clinicalRecord.id,
        contextId,
        types: [MeasurementType.PSFS]
      });

      const measurements = result.results;

      const psfsSeriesEncounterIds = measurements.map(x => x.encounterId);
      if (encounterId) {
        const includesCurrentEncounter = psfsSeriesEncounterIds.includes(
          encounterId!
        );
        if (!includesCurrentEncounter) {
          psfsSeriesEncounterIds.push(encounterId!); // check if any have been created in currentEncounter
        }
      }

      const promises = psfsSeriesEncounterIds.map(x => getEncounterPSFSData(x));
      const respond = await Promise.all(promises);
      const responses = flatten(respond.filter(isDefined));

      const questionnaire = await clinical.getQuestionnaires(
        QuestionnaireType.PSFSV1
      );

      const questionIds = psfsSeriesContext?.activities.map(
        x => x.questionnaireItemId
      );

      responses.sort((a, b) => {
        return a.createLog?.createdDateTime! < b.createLog?.createdDateTime!
          ? 1
          : -1;
      });

      const answersArray = createDynamicPsfsAnswerObject({
        questionnaire,
        responses,
        questionIds: questionIds!,
        psfsSeriesContext: psfsSeriesContext!
      });

      const summaryRow = getAverages(responses, questionIds!.length);
      answersArray.push(summaryRow);

      const activitiesColumnWidth = () => {
        const COLUMN_WIDTH = 110;
        const SMALLEST_ACTIVITY_LENGTH = 332;
        const responsesLength = responses.length;
        if (responsesLength >= 5) return SMALLEST_ACTIVITY_LENGTH;
        if (responsesLength === 4)
          return SMALLEST_ACTIVITY_LENGTH + COLUMN_WIDTH;
        if (responsesLength === 3)
          return SMALLEST_ACTIVITY_LENGTH + COLUMN_WIDTH * 2;
        if (responsesLength === 2)
          return SMALLEST_ACTIVITY_LENGTH + COLUMN_WIDTH * 3;
        return SMALLEST_ACTIVITY_LENGTH;
      };

      const activitiesColumn: IColumn = {
        key: "activites",
        isRowHeader: true,
        minWidth: activitiesColumnWidth(),
        maxWidth: activitiesColumnWidth(),
        name: "Activities",
        onRender: (item: SeriesQuestionAnswers) => {
          return `${item.questionNumberText} ${item.questionText}`;
        },
        isMultiline: true
      };

      const columns: IColumn[] = [];

      for (let x = 0; x < responses.length; x++) {
        const fontWeight =
          x === responses.length - 1 ? FontWeights.bold : FontWeights.regular;

        const column: IColumn = {
          key: `${x}`,
          isRowHeader: true,
          minWidth: 90,
          maxWidth: 90,
          name:
            DateTime.fromISO(
              responses[x].createLog?.createdDateTime
            )?.toDayDefaultFormat() || "",
          styles: { root: { fontWeight } },
          onRender: (item: SeriesQuestionAnswers) => {
            const answerKey = `${SERIES_PREFIX}${column.key}`;
            return `${item[answerKey]}`;
          },
          isMultiline: false
        };
        columns.push(column);
      }

      columns.unshift(activitiesColumn);

      return {
        answersArray,
        columns
      };
    };

  const handleDismiss = () => {
    if (onClose) {
      onClose();
    }
    setIsHidden(true);
  };

  const side =
    psfsSeriesContext!.side! === SideOfBody.Neither
      ? undefined
      : `, ${getSideOfBodyText(psfsSeriesContext!.side!)} Side`;

  return (
    <Stack>
      <Dialog
        styles={{
          root: { overflowY: "none" }
        }}
        onDismiss={handleDismiss}
        hidden={isHidden}
        dialogContentProps={{
          title: (
            <Heading variant="modal-heading">
              PSFS Trending - {psfsSeriesContext?.diagnosis.originalText}
              {side}
            </Heading>
          ),
          showCloseButton: true,
          styles: {
            content: {
              display: "flex",
              flexDirection: "column"
            }
          }
        }}
        minWidth={950}
      >
        <DataFetcher<PSFSTrendModel>
          fetch={getPSFSTrendData()}
          fallback={<Spinner />}
        >
          {({ answersArray, columns }) => (
            <Stack
              styles={{ root: { overflowX: "auto", flexGrow: 1 } }}
              data-is-scrollable="true"
            >
              <DetailsList
                items={answersArray}
                bordersVariant="bottomHeader"
                columns={columns}
                onRenderRow={(props, defaultRender) => {
                  if (!props || !defaultRender) {
                    return null;
                  }

                  const averageBold =
                    props.item.questionText === "Average scores"
                      ? FontWeights.bold
                      : FontWeights.regular;
                  return (
                    <>
                      {defaultRender!({
                        ...props,
                        styles: {
                          root: [
                            {
                              lineHeight: "16px",
                              fontSize: FontSizes.small,
                              fontWeight: averageBold
                            }
                          ]
                        }
                      })}
                    </>
                  );
                }}
              />
            </Stack>
          )}
        </DataFetcher>
        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { paddingTop: 16 } }}
          tokens={{ childrenGap: 8 }}
        >
          <DefaultButton
            id="cancelPSFSTrendDialog"
            onClick={handleDismiss}
            text="Close"
          />
        </Stack>
      </Dialog>
    </Stack>
  );
};
