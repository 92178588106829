import { action, observable } from "mobx";

import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  UpdateQuickAccessDto,
  UserStorageKeys
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { TemplateListItem } from "@modules/clinical/screens/correspondence/components/TemplatePivot.types.ts";
import { getListItems } from "@modules/clinical/screens/correspondence/correspondence.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { EditQuickAccessDialogValues } from "../EditQuickAccessDialog.tsx";
import { quickAccessLinks } from "../QuickAccessLinks.Types.ts";

export class QuickAccessHelper {
  constructor(private root: RootStore) {}

  private get userExperience() {
    return this.root.userExperience;
  }

  private get document() {
    return this.root.document;
  }

  private get core() {
    return this.root.core;
  }

  public get quickAccessSettings() {
    return this.userExperience.quickAccessSettings;
  }

  public get quickAccessSettingsDocumentIds() {
    return this.quickAccessSettings?.map(x => x.documentId).filter(x => !!x);
  }

  @observable selectedTemplate: string | undefined;

  @observable quickAccessImagingRequest: boolean | undefined;

  @observable quickAccessPrescribingRx: boolean | undefined;

  @observable showEditQuickAccessDialog: boolean | undefined;

  @observable showDocumentWriterMergeFormDialog: boolean | undefined;

  @observable showOnlineFormsDialog: boolean | undefined;

  @action setShowOnlineFormsDialog = (value: boolean | undefined) => {
    this.showOnlineFormsDialog = value;
  };

  @action setSelectedTemplate = (value: string | undefined) => {
    this.selectedTemplate = value;
  };

  @action setDocumentWriterMergeFormDialog = (value: boolean) => {
    this.showDocumentWriterMergeFormDialog = value;
  };

  @action setQuickAccessImagingRequest = (value: boolean) => {
    this.quickAccessImagingRequest = value;
  };

  @action setShowEditQuickAccessDialog = (value: boolean) => {
    this.showEditQuickAccessDialog = value;
  };

  @action setQuickAccessPrescribingRx = (value: boolean) => {
    this.quickAccessPrescribingRx = value;
  };

  getQuickAccessLink = (code: string) =>
    quickAccessLinks.find(x => x.key === code);

  getInitialValues = (): EditQuickAccessDialogValues => {
    const editQuickAccessSettings = this.quickAccessSettings?.map(x => ({
      ...x,
      icon: this.userExperience.getQuickAccessIcon(x.icon)?.text ?? x.icon,
      title: this.getQuickAccessLink(x.code)?.text ?? x.title,
      isSourceTemplateDeleted: x.isSourceTemplateDeleted
    }));

    return {
      quickAccessSettings: editQuickAccessSettings ?? []
    };
  };

  isTemplateDeleted = (templateId: string, availableTemplateIds: string[]) => {
    return !availableTemplateIds.includes(templateId);
  };

  onSubmit = async (
    editQuickAccessDialogValues: EditQuickAccessDialogValues
  ) => {
    const quickAccess = this.userExperience.quickAccess;

    const updateQuickAccessSettings =
      editQuickAccessDialogValues.quickAccessSettings
        .filter(x => x.code && x.icon && x.title)
        .map(x => ({
          ...x,
          title: x.title
        }));

    const updatedQuickAccess: UpdateQuickAccessDto = {
      quickAccessSettings: updateQuickAccessSettings,
      eTag: quickAccess?.eTag
    };

    await this.userExperience.updateQuickAccess(updatedQuickAccess);
  };

  getTemplatesList = async () => {
    const [favouriteTemplates, templateFilterTabs, user] = await Promise.all([
      this.userExperience.getUserStorage(UserStorageKeys.FavouriteTemplates),
      this.userExperience.getUserStorage(UserStorageKeys.TemplateFilterTabs),
      this.core.getUser(this.core.userId)
    ]);

    const templates = this.document.documentLists;
    const isNoneClinical = !this.core.hasPermissions(
      Permission.DocTemplateClinicalRead
    );

    const templateItems: TemplateListItem[] = getListItems(
      this.core,
      templates,
      {
        isProviderClass: user.isProviderClass,
        isNoneClinical
      }
    ).filter(x => !x.isLetterhead);

    const templateTabs = (templateFilterTabs?.jsonData ?? [
      CorrespondenceType.Letter,
      CorrespondenceType.Referral,
      CorrespondenceType.Report
    ]) as string[];

    const sortedTabs = Array.from(templateTabs).sort((a, b) =>
      a.localeCompare(b)
    );

    return {
      templateItems,
      templateFilterTabs: sortedTabs,
      favourites: (favouriteTemplates?.jsonData ?? []) as string[]
    };
  };
}
