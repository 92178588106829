import { RRule } from "rrule";

import {
  applyOpacityToHexColor,
  DetailsRow,
  IDetailsRowProps,
  IDetailsRowStyles,
  ITheme
} from "@bps/fluent-ui";
import {
  DATE_FORMATS,
  DateTime,
  getDayOfWeek,
  getWeekPosition
} from "@bps/utils";
import { MonthYearRecurrence } from "@libs/constants/month-year-recurrence.enum.ts";
import {
  AppointmentStatusCode,
  CalendarEventPosition,
  CalendarEventPriority,
  CalendarEventType,
  Frequency
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { UserScheduleConfigured } from "@libs/utils/calendar/calendar.constants.ts";
import { RecurrenceRule } from "@libs/utils/calendar/calendar.types.ts";
import {
  dayRecurrences,
  isScheduleConfigured,
  rRuleFrequency
} from "@libs/utils/calendar/calendar.utils.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { RecurrenceModel } from "@stores/booking/models/RecurrenceModel.ts";
import { TimeRanges } from "@stores/booking/models/TimeRanges.ts";
import { TimeRangesOrgUnit } from "@stores/booking/models/TimeRangesOrgUnit.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";

import { CalendarEventRowOpts } from "../../did-not-arrive/DidNotArriveListScreen.types.ts";
import { BigCalendarEvent } from "./booking-calendar-event/BookingCalendarEvent.types.ts";
import {
  getAppointmentStatusColours,
  Tones
} from "./shared-components/appointment-status/utils.ts";

export function isOutsideTimeRange(params: {
  start: DateTime;
  end: DateTime;
  timeRange: TimeRanges | TimeRangesOrgUnit | undefined;
}) {
  const { start, end, timeRange } = params;
  if (!timeRange) return true;

  const { startTime: workingHoursStart, endTime: workingHoursEnd } =
    timeRange.startEndTime(start);

  if (!workingHoursStart || !workingHoursEnd) {
    return true;
  }

  return workingHoursStart > start || workingHoursEnd < end;
}

export function getCalendarAvailability(params: {
  start: DateTime;
  end: DateTime;
  providerTimeRange: TimeRangesOrgUnit | undefined;
  timeRange: TimeRanges | undefined;
  hasPermissions: boolean;
}): CalendarEventPosition {
  const { start, end, providerTimeRange, timeRange, hasPermissions } = params;

  const isOutsideProviderTimeRanges = isOutsideTimeRange({
    start,
    end,
    timeRange: providerTimeRange
  });

  if (isOutsideProviderTimeRanges) {
    if (!hasPermissions) {
      return CalendarEventPosition.NoPermission;
    }

    const isOutsideLocationTimeRanges = isOutsideTimeRange({
      start,
      end,
      timeRange
    });

    if (isOutsideLocationTimeRanges) {
      return CalendarEventPosition.PracticeWorkingHours;
    }

    return CalendarEventPosition.ProviderWorkingHours;
  }

  return CalendarEventPosition.Available;
}

const isWeekSetDay = (
  frequency?: number,
  monthYearRecurrence?: MonthYearRecurrence
) => {
  return (
    (frequency === Frequency.Month || frequency === Frequency.Year) &&
    (monthYearRecurrence === MonthYearRecurrence.LastDayOfWeek ||
      monthYearRecurrence === MonthYearRecurrence.DayOfWeek)
  );
};

export function getCount(endScheduleType?: string, count?: number) {
  return endScheduleType === EndScheduleType.After ? count : undefined;
}

export function getMonthRecur(frequency: number, startDate: DateTime) {
  return frequency === Frequency.Year ? [startDate.month] || [] : undefined;
}

export function getMonthDayRecur(
  startDate: DateTime,
  frequency?: number,
  monthYearRecurrence?: MonthYearRecurrence
) {
  return (frequency === Frequency.Month || frequency === Frequency.Year) &&
    monthYearRecurrence === MonthYearRecurrence.MonthDay
    ? [startDate.day]
    : undefined;
}

export function getRecurrenceWeekPosition(
  startDate: DateTime,
  frequency?: number,
  monthYearRecurrence?: MonthYearRecurrence
) {
  return isWeekSetDay(frequency, monthYearRecurrence)
    ? getWeekPosition(startDate, monthYearRecurrence)
    : undefined;
}

export function getDayRecur(params: {
  startDate: DateTime;
  frequency?: number;
  dayRecur?: number[];
  monthYearRecurrence?: MonthYearRecurrence;
}) {
  const { startDate, frequency, dayRecur, monthYearRecurrence } = params;
  if (frequency === Frequency.Week) {
    return dayRecur?.length ? dayRecur : undefined;
  }

  if (isWeekSetDay(frequency, monthYearRecurrence)) {
    return [getDayOfWeek(startDate)];
  }

  return undefined;
}

export function getUntil(
  endScheduleType?: string,
  until?: Date
): string | undefined {
  if (endScheduleType === EndScheduleType.OnDate) {
    if (until instanceof Date && !isNaN(until.getTime())) {
      return DateTime.fromJSDate(until).startOf("day").toISODate();
    }

    if (until) {
      return "Invalid date";
    }
  }

  return undefined;
}

export const isOnDate = (value: string) => value === EndScheduleType.OnDate;
export const isAfter = (value: string) => value === EndScheduleType.After;
export const isWeek = (value: number) => value === Frequency.Week;
export const isMonth = (value: number) => value === Frequency.Month;
export const isYear = (value: number) => value === Frequency.Year;
const isAppointmentStatusCompletedOrDNA = (
  appointmentStatus: AppointmentStatusCode | undefined
) =>
  appointmentStatus === AppointmentStatusCode.Completed ||
  appointmentStatus === AppointmentStatusCode.DidNotAttend;

export const isBefore12hours = (date?: DateTime): boolean => {
  return date ? date < DateTime.now().minus({ minutes: 720 }) : false;
};

export const isAppointmentEditable = (
  startTime: DateTime | undefined,
  appointmentStatus: AppointmentStatusCode | undefined
) => {
  return (
    isBefore12hours(startTime) &&
    isAppointmentStatusCompletedOrDNA(appointmentStatus)
  );
};

export const isAppointmentFormEditable = (
  startTime: DateTime | undefined,
  appointmentStatus: AppointmentStatusCode | undefined
) => {
  return (
    isBefore12hours(startTime) &&
    !isAppointmentStatusCompletedOrDNA(appointmentStatus)
  );
};

export const isAppointmentDeletable = (
  startTime: DateTime | undefined,
  appointmentStatus: AppointmentStatusCode | undefined,
  hasEncounter: boolean | undefined
) => {
  return (
    (isBefore12hours(startTime) &&
      isAppointmentStatusCompletedOrDNA(appointmentStatus)) ||
    hasEncounter
  );
};

const appointmentStatusOptions = [
  {
    key: AppointmentStatusCode.Booked,
    disabledStates: [AppointmentStatusCode.Booked]
  },
  {
    key: AppointmentStatusCode.Waiting,
    disabledStates: [
      AppointmentStatusCode.Booked,
      AppointmentStatusCode.Waiting
    ]
  },
  {
    key: AppointmentStatusCode.WithProvider,
    disabledStates: [
      AppointmentStatusCode.Booked,
      AppointmentStatusCode.Waiting,
      AppointmentStatusCode.WithProvider
    ]
  },
  {
    key: AppointmentStatusCode.Finalised,
    disabledStates: [
      AppointmentStatusCode.Booked,
      AppointmentStatusCode.Waiting,
      AppointmentStatusCode.WithProvider,
      AppointmentStatusCode.Finalised
    ]
  },
  {
    key: AppointmentStatusCode.DidNotAttend,
    disabledStates: [
      AppointmentStatusCode.Booked,
      AppointmentStatusCode.Waiting,
      AppointmentStatusCode.WithProvider,
      AppointmentStatusCode.Finalised,
      AppointmentStatusCode.DidNotAttend
    ]
  },
  {
    key: AppointmentStatusCode.Completed,
    disabledStates: [
      AppointmentStatusCode.Booked,
      AppointmentStatusCode.Waiting,
      AppointmentStatusCode.WithProvider,
      AppointmentStatusCode.Finalised,
      AppointmentStatusCode.DidNotAttend,
      AppointmentStatusCode.Completed
    ]
  }
];

export const enableAppointmentStatusItems = (
  currentAppointmentStatus: AppointmentStatusCode,
  key: AppointmentStatusCode,
  todayAppointmentWithoutEncounter: boolean
) => {
  if (todayAppointmentWithoutEncounter) {
    return currentAppointmentStatus === key;
  } else {
    const appointmentStatusOption = appointmentStatusOptions.find(
      appointmentStatusOptionItems =>
        appointmentStatusOptionItems.key === currentAppointmentStatus
    );
    return !!appointmentStatusOption?.disabledStates?.includes(key);
  }
};

export const calculateOccurrences = (
  recurrenceRule: RecurrenceRule
): DateTime[] => {
  // For the RRule library we must provide dates in UTC time.  Eg if the start date is 2024-02-29T00:00:00.000+10:00
  //  then we must provide 2024-02-29T00:00:00.000Z then do the reverse conversion afterwards.

  const rule = new RRule({
    freq: rRuleFrequency(recurrenceRule.frequency),
    count: recurrenceRule.count,
    bymonth: recurrenceRule.monthRecur,
    bymonthday: recurrenceRule.monthDayRecur,
    bysetpos: recurrenceRule.weekPosition,
    dtstart: recurrenceRule.start?._dateTime
      .setZone("utc", { keepLocalTime: true }) // we have to go back to the core luxon methods to do this conversion
      .toJSDate(),
    until: recurrenceRule.until
      ?.plus({ days: 1 }) // + 1 day is for the day 'until' to be included in the new RRule otherwise it returns the rule with the 'until' excluded
      ._dateTime.setZone("utc", { keepLocalTime: true })
      .toJSDate(),
    byweekday: dayRecurrences(recurrenceRule.dayRecur)
  });

  if (recurrenceRule.interval) {
    // Bug with RRule plugin doesn't work in constructor
    rule.options.interval = recurrenceRule.interval;
  }

  return rule.all().map(date =>
    DateTime.fromObject({
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate(),
      hour: date.getUTCHours(),
      minute: date.getUTCMinutes()
    })
  );
};
export const occurredCount = (args: {
  recurrence: RecurrenceModel;
  startDateTime?: DateTime;
  untilDate?: DateTime;
}): DateTime[] => {
  const allOccurrences: DateTime[] = [];

  const { recurrence, untilDate, startDateTime } = args;
  let endScheduleType: EndScheduleType;
  if (recurrence.endDate !== undefined) {
    endScheduleType = EndScheduleType.OnDate;
  } else if (recurrence.count === undefined) {
    endScheduleType = EndScheduleType.Never;
  } else {
    endScheduleType = EndScheduleType.After;
  }

  if (endScheduleType !== EndScheduleType.Never) {
    const rRule: RecurrenceRule = {
      count: recurrence.count,
      frequency: recurrence.frequency,
      dayRecur: recurrence.dayRecur,
      interval: recurrence.interval,
      monthDayRecur: recurrence.monthDayRecur,
      monthRecur: recurrence.monthRecur,
      weekPosition: recurrence.weekPosition,
      until: untilDate,
      start: startDateTime
    };

    const dates = calculateOccurrences(rRule);
    dates.forEach(occurrence => allOccurrences.push(occurrence));
  }
  return allOccurrences;
};

export const getSeriesOccurrences = (args: {
  recurrenceSeries: RecurrenceModel[];
  defaultEndDate?: DateTime;
}): DateTime[] => {
  const { recurrenceSeries, defaultEndDate } = args;
  const allOccurrences: DateTime[] = [];
  recurrenceSeries.forEach(recurrence => {
    const recurrenceUntilDate =
      recurrence.until && DateTime.fromISO(recurrence.until).isValid
        ? DateTime.fromISO(recurrence.until).plus({ days: 1 })
        : null;

    let untilDate = defaultEndDate;
    if (recurrenceUntilDate) {
      if (!defaultEndDate || recurrenceUntilDate <= defaultEndDate) {
        untilDate = recurrenceUntilDate;
      }
    }

    const startDateTime = recurrence.startDateTime?.plus({ days: 1 });

    occurredCount({
      recurrence,
      startDateTime,
      untilDate
    }).map(occurrence => allOccurrences.push(occurrence));
  });

  return allOccurrences;
};

export const getUsersAvailability = async (
  users: User[],
  booking: BookingStore,
  orgUnitIds?: string[]
) => {
  const userIds = users.map(u => u.id);
  const availabilities = await booking.getUserAvailabilities(userIds);

  const filteredUsers = setUsersAvailability(users, availabilities);

  if (orgUnitIds && orgUnitIds.filter(x => x).length > 0) {
    return filteredUsers.filter(
      user =>
        user.availableOrgUnitIds?.some(orgId => orgUnitIds.includes(orgId))
    );
  }

  return filteredUsers;
};

export const setUsersAvailability = (
  users: User[],
  availabilities: UserAvailabilityModel[]
) => {
  const filteredUsers = users.filter(user => {
    const availability = availabilities.find(a => a.userId === user.id);
    if (availability) {
      const schedule = isScheduleConfigured(
        availability.schedules,
        DateTime.now()
      );
      return schedule === UserScheduleConfigured.Configured;
    }
    return false;
  });

  return filteredUsers;
};

export function getAppointmentEventColors(
  event: BigCalendarEvent,
  theme: ITheme
): Tones {
  const colours = getAppointmentStatusColours(theme);

  if (event.type === CalendarEventType.Meeting) {
    return {
      dark: "#F238D4",
      light: "#FDE2F9"
    };
  }

  if (
    event.type === CalendarEventType.Unavailable ||
    event.type === CalendarEventType.AnotherLocation
  ) {
    return {
      dark: "#9D9B9B",
      light: "#CFCECE"
    };
  }

  if (event.type === CalendarEventType.ClosedException) {
    return {
      dark: applyOpacityToHexColor("#9D9B9B", 70),
      light: applyOpacityToHexColor("#CFCECE", 70)
    };
  }

  const appointmentStatus =
    event.appointmentStatus || AppointmentStatusCode.Booked;

  if (event.priority === CalendarEventPriority.Urgent) {
    if (appointmentStatus === AppointmentStatusCode.Booked) {
      return colours(AppointmentStatusCode.UrgentBooked);
    } else if (appointmentStatus === AppointmentStatusCode.Waiting) {
      return colours(AppointmentStatusCode.UrgentWaiting);
    }
  }

  return colours(appointmentStatus) || colours(AppointmentStatusCode.Booked);
}
export const renderCalendarEventWrapper = (
  props: IDetailsRowProps | undefined,
  options?: CalendarEventRowOpts,
  detailRowStyles?: Partial<IDetailsRowStyles>
) => {
  if (!props) return null;

  const record = props?.item as CalendarEvent;

  return options ? (
    <div
      onClick={evt => options.onShowCallout(evt, record)}
      onContextMenu={evt => options.onShowContext(evt, record)}
      data-calendar-event-id={record.id}
      data-patient-id={record.contact?.id}
      data-provider-id={record.user?.id}
    >
      <DetailsRow styles={detailRowStyles} {...props} />
    </div>
  ) : (
    <div
      data-calendar-event-id={record.id}
      data-patient-id={record.contact?.id}
      data-provider-id={record.user?.id}
    >
      <DetailsRow styles={detailRowStyles} {...props} />
    </div>
  );
};

export const formatDateRange = (start: DateTime, end: DateTime) => {
  if (start.year !== end.year) {
    return `${start.toFormat(
      DATE_FORMATS.DAY_TEXT_MONTH_YEAR_SHORT
    )} - ${end.toFormat(DATE_FORMATS.DAY_TEXT_MONTH_YEAR_SHORT)}`;
  }

  if (start.month !== end.month) {
    return `${start.toFormat(
      DATE_FORMATS.SHORT_DAY_AND_MONTH
    )} - ${end.toFormat(DATE_FORMATS.DAY_TEXT_MONTH_YEAR_SHORT)}`;
  }

  return `${start.toFormat(DATE_FORMATS.DAY_ONLY_NUMBER)} - ${end.toFormat(
    DATE_FORMATS.DAY_TEXT_MONTH_YEAR_SHORT
  )}`;
};
