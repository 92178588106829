import { observer } from "mobx-react-lite";

import {
  IColumn,
  Link,
  RESET_CELLS_PADDING_CLASSNAME,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { ClaimAdjustmentResponseCallout } from "./ClaimAdjustmentResponseCallout.tsx";
import { ClaimAdjustmentStatusBadge } from "./ClaimAdjustmentStatusBadge.tsx";
import {
  isClaimAdjustmentResponseDisplayable,
  isClaimAdjustmentResponseRecordable,
  sortClaimAdjustments
} from "./utils.ts";

interface ClaimAdjustmentListProps {
  claimAdjustments: ClaimAdjustment[];
  onClaimNumberClicked?: (claimAdjustmentId: string) => void;
}
export interface ClaimAdjustmentListItem {
  purchaseOrderNumber: string;
  formattedNumber: string;
  responseNotes: string;
  statusCode: string;
  createdDate: Date;
  claimId: string;
  lastCol: string;
  status: string;
  index: number;
  id: string;
  verballyApproved: boolean;
}

export const ClaimAdjustmentList: React.FC<ClaimAdjustmentListProps> = observer(
  ({ claimAdjustments, onClaimNumberClicked }) => {
    const nameOf = nameOfFactory<ClaimAdjustmentListItem>();
    const { routing } = useStores();

    const sortedAdjustments = sortClaimAdjustments(claimAdjustments);

    const { setResponseModal, hasMultiProviderErrorMessage } =
      useClaimAdjustmentContext();

    const handleClick = (item: ClaimAdjustmentListItem) => {
      if (!hasMultiProviderErrorMessage) {
        return setResponseModal(item.id);
      } else if (!onClaimNumberClicked) {
        routing.push(
          {
            pathname: routes.claimAdjustment.edit.path({
              id: item.id,
              claimId: item.claimId
            })
          },
          routing.getStateWithFromQuery()
        );
      }
      return undefined;
    };

    const columns: IColumn[] = [
      {
        name: "Status",
        key: nameOf("status"),
        className: RESET_CELLS_PADDING_CLASSNAME,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: ClaimAdjustmentListItem) => (
          <ClaimAdjustmentStatusBadge code={item.status} />
        )
      },
      {
        name: "ID",
        key: nameOf("id"),
        minWidth: 81,
        maxWidth: 81,
        onRender: (item: ClaimAdjustmentListItem) => (
          <Navigate
            onClick={
              onClaimNumberClicked
                ? () => onClaimNumberClicked(item.id)
                : undefined
            }
            to={
              !onClaimNumberClicked
                ? {
                    pathname: routes.claimAdjustment.edit.path({
                      id: item.id,
                      claimId: item.claimId
                    })
                  }
                : undefined
            }
            state={{ ...routing.getRouteState() }}
          >
            {item.formattedNumber}
          </Navigate>
        )
      },
      {
        name: "Created",
        key: nameOf("createdDate"),
        minWidth: 75,
        maxWidth: 75,
        onRender: (item: ClaimAdjustmentListItem) =>
          !!item.createdDate &&
          DateTime.fromJSDate(item.createdDate).toDayDefaultFormat()
      },
      {
        name: "",
        key: nameOf("lastCol"),
        minWidth: 75,
        onRender: (item: ClaimAdjustmentListItem) => (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            {isClaimAdjustmentResponseDisplayable(
              item.purchaseOrderNumber,
              item.status
            ) && (
              <ClaimAdjustmentResponseCallout
                id={item.id}
                number={item.formattedNumber}
                purchaseOrder={item.purchaseOrderNumber}
                responseNotes={item.responseNotes}
              />
            )}

            {isClaimAdjustmentResponseRecordable(item.status) &&
              !item.verballyApproved && (
                <Link onClick={() => handleClick(item)}>ACC response</Link>
              )}
          </Stack>
        )
      }
    ];

    return (
      <ShimmeredDetailsList
        bordersVariant="bottomHeader"
        items={sortedAdjustments.map(x => ({
          id: x.id,
          claimId: x.dto.claimId,
          formattedNumber: x.formattedNumber,
          status: x.dto.statusCode,
          createdDate: x.createdDate?.toJSDate(),
          responseNotes: x.responseNotes,
          purchaseOrderNumber: x.purchaseOrderNumber,
          verballyApproved: x.verballyApproved
        }))}
        columns={columns}
      />
    );
  }
);
