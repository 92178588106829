import { observer } from "mobx-react-lite";
import { FC, ReactNode, useContext } from "react";
import { useForm } from "react-final-form";

import { Link, MessageBar, MessageBarType, Stack } from "@bps/fluent-ui";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { UserContext } from "../context/UserContext.tsx";

interface LicenceMessageBarProps {
  userId: string;
}

export const LicenceMessageBar: FC<LicenceMessageBarProps> = observer(
  ({ userId }) => {
    const userHelper = useContext(UserContext);
    const form = useForm();

    const { fields: businessRoles } = useFieldArray("businessRoles");

    const updateLicence = () => {
      userHelper.updateLicence(userId).then(() => {
        // To update the securityPermissionsValue
        form.change("businessRoles", businessRoles.value);
      });
    };

    const licenceTypeMessageBar = (
      type: MessageBarType,
      children: ReactNode
    ) => {
      return (
        <MessageBar
          messageBarType={type}
          styles={{
            root: { width: 630 },
            innerText: {
              width: "100%",
              span: {
                display: "flex",
                justifyContent: "space-between"
              }
            }
          }}
        >
          {children}
        </MessageBar>
      );
    };

    const validLicence = userHelper.getValidLicence(userId);

    // Has the user an assigned licence?
    if (validLicence) {
      // Is the licence in the grace period?
      if (validLicence.isLicenceInGracePeriod)
        return licenceTypeMessageBar(
          MessageBarType.warning,
          <>
            {`The licence expired on ${validLicence.expiryDate.toDayDefaultFormat()}, this user requires a licence from ${validLicence.gracePeriodEndDate.toDayDefaultFormat()}  for full access`}
            <Link onClick={updateLicence}>Unassign</Link>
          </>
        );
      else
        return licenceTypeMessageBar(
          MessageBarType.success,
          <>
            User has been assigned a licence
            <Link onClick={updateLicence}>Unassign</Link>
          </>
        );
    }

    // Don't have availabe licences?
    if (userHelper.core.licences.filter(x => !x.userId).length === 0)
      return licenceTypeMessageBar(
        MessageBarType.severeWarning,
        <Stack
          styles={{
            root: { display: "inline" }
          }}
        >
          All of your licences are currently in use. You need to purchase extra
          licences or unassign one otherwise this user will have restricted
          access
        </Stack>
      );
    else
      return licenceTypeMessageBar(
        MessageBarType.warning,
        <>
          A licence is required for this business role
          <Link onClick={updateLicence}>Assign</Link>
        </>
      );
  }
);
