import { IDropdownStyles } from "@bps/fluent-ui";

export const getDropdownStyles = (
  isEdit: boolean
): Partial<IDropdownStyles> => ({
  dropdown: {
    width: 200,
    selectors: {
      "&::after": {
        border: isEdit ? "initial" : "none!important"
      }
    }
  },
  title: { border: isEdit ? "initial" : "none", backgroundColor: "none" }
});
