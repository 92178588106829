import { observer } from "mobx-react-lite";
import React from "react";

import { FontSizes, FontWeights, Stack, Tile } from "@bps/fluent-ui";
import {
  customFormatDuration,
  DateTime,
  getDurationFromMinutes,
  TIME_FORMATS
} from "@bps/utils";
import { ApptInfoCardColumns } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ApptInfoCardProps {
  providerId: string | undefined;
  startDate: Date | undefined;
  startTime: string | undefined;
  duration: string | number | undefined;
}

export const AppointmentInfoCard: React.FunctionComponent<ApptInfoCardProps> =
  observer(({ providerId, startDate, startTime, duration }) => {
    const { core } = useStores();
    const contact = providerId && core.userMap.get(providerId);
    const apptStartDate =
      startDate && DateTime.fromJSDate(startDate).toDayDefaultFormat();

    const apptStartTime =
      startTime &&
      DateTime.fromFormat(startTime, TIME_FORMATS.TIME_FORMAT_24).toFormat(
        TIME_FORMATS.TIME_FORMAT_WITH_MIDDAY_FULL
      );

    const apptDuration = customFormatDuration(
      getDurationFromMinutes(duration).shiftTo("hours", "minutes")
    );

    return (
      <Tile
        tokens={{ childrenGap: 24 }}
        styles={{
          root: {
            width: "max-content",
            height: "auto",
            padding: 16,
            marginBottom: 16
          }
        }}
      >
        <Stack horizontal>
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack.Item
              styles={(_props, theme) => ({
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  fontSize: FontSizes.size12,
                  fontWeight: FontWeights.regular,
                  color: theme.palette.neutralTertiary
                }
              })}
            >
              {ApptInfoCardColumns.provider}
            </Stack.Item>
            <Stack.Item
              styles={{
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  paddingRight: 24,
                  fontSize: FontSizes.size14,
                  fontWeight: FontWeights.regular
                }
              }}
            >
              {contact && contact.fullName}
            </Stack.Item>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack.Item
              styles={(_props, theme) => ({
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  fontSize: FontSizes.size12,
                  fontWeight: FontWeights.regular,
                  color: theme.palette.neutralTertiary
                }
              })}
            >
              {ApptInfoCardColumns.date}
            </Stack.Item>
            <Stack.Item
              styles={{
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  paddingRight: 24,
                  fontSize: FontSizes.size14,
                  fontWeight: FontWeights.regular
                }
              }}
            >
              {apptStartDate}
            </Stack.Item>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack.Item
              styles={(_props, theme) => ({
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  fontSize: FontSizes.size12,
                  fontWeight: FontWeights.regular,
                  color: theme.palette.neutralTertiary
                }
              })}
            >
              {ApptInfoCardColumns.time}
            </Stack.Item>
            <Stack.Item
              styles={{
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  paddingRight: 24,
                  fontSize: FontSizes.size14,
                  fontWeight: FontWeights.regular
                }
              }}
            >
              {apptStartTime}
            </Stack.Item>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack.Item
              styles={(_props, theme) => ({
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  fontSize: FontSizes.size12,
                  fontWeight: FontWeights.regular,
                  color: theme.palette.neutralTertiary
                }
              })}
            >
              {ApptInfoCardColumns.duration}
            </Stack.Item>
            <Stack.Item
              styles={{
                root: {
                  flexBasis: 0,
                  flexGrow: 1,
                  fontSize: FontSizes.size14,
                  fontWeight: FontWeights.regular
                }
              }}
            >
              {apptDuration}
            </Stack.Item>
          </Stack>
        </Stack>
      </Tile>
    );
  });
