import { CommentToolTip } from "@modules/billing/screens/shared-components/CommentToolTip.tsx";

interface CommentCellProps {
  comment?: string;
}

export const CommentCell: React.FC<CommentCellProps> = ({ comment }) => {
  if (!comment) {
    return null;
  }
  return <CommentToolTip content={comment} solid />;
};
