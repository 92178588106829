import { Observer, observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import {
  AddBox,
  CenteredBox,
  confirm,
  Heading,
  IColumn,
  IconButton,
  IContextualMenuItem,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { CommsSectionViews } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { CommsScreenCommands } from "@modules/settings/screens/comms-schedules/components/CommsScreenCommands.tsx";
import { useTemplateScreenContext } from "@modules/settings/screens/comms-templates/context/TemplateScreenContext.tsx";
import { ScreenLayout } from "@modules/settings/screens/shared-components/ScreenLayout.tsx";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { TemplatesFilter } from "./TemplatesFilter.tsx";

enum TemplateListLabel {
  Actions = "Actions",
  Name = "Name",
  Type = "Type",
  Updated = "Updated",
  UpdatedBy = "Updated By"
}

const TITLE = "Create a new template";
const nameOf = nameOfFactory<OutboundCommTemplate>();
const TemplatesListBase: React.FC = observer(() => {
  const { comms, routing, notification } = useStores();

  const theme = useTheme();
  const { templateListResults, getFilteredTemplates, getSortedTemplates } =
    useTemplateScreenContext();

  const load = async () => {
    const loadResults = async () => await comms.getAllOutboundCommTemplates();
    templateListResults.set(loadResults());
  };

  useEffect(() => {
    load();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comms, comms.recentlyChangedTemplateListId]);

  const renderActions = (item: OutboundCommTemplate) => {
    const menuItems: IContextualMenuItem[] = [];

    menuItems.push({
      key: "edit",
      text: "Edit",
      onClick: () => {
        routing.push(
          routes.settings.communications.template.edit.path({
            id: item.id
          })
        );
      }
    });
    menuItems.push({
      key: "duplicate",
      text: "Duplicate",
      onClick: () => {
        comms.addOutboundCommTemplate({
          outboundCommTypeCode: item.outboundCommTypeCode,
          channelTemplates: item.channelTemplates,
          name: `${item.name}-Copy`
        });
      }
    });
    menuItems.push({
      key: "delete",
      text: "Delete",
      onClick: () => {
        deleteTemplate(item);
      }
    });

    const deleteTemplate = async (item: OutboundCommTemplate) => {
      if (
        await confirm({
          confirmButtonProps: {
            text: "Confirm"
          },
          cancelButtonProps: {
            text: "Cancel"
          },
          dialogContentProps: {
            title: "Delete Template",
            subText: `Are you sure you want to delete the ${item.name} template?`
          }
        })
      ) {
        try {
          await comms.deleteOutboundCommTemplate(item.id);
          notification.success(notificationMessages.commTemplateDeleted);
        } catch (e) {
          notification.error(e.message);
        }
      }
    };

    return (
      <Stack horizontalAlign="center" verticalAlign="center">
        <TooltipHost content="More">
          <IconButton
            menuIconProps={{ iconName: "More" }}
            menuProps={{ items: menuItems }}
            styles={{
              root: { height: "36px" },
              flexContainer: { height: "36px" }
            }}
          />
        </TooltipHost>
      </Stack>
    );
  };

  const renderName = (item: OutboundCommTemplate) => {
    return (
      <Navigate
        to={routes.settings.communications.template.edit.path({
          id: item.id
        })}
      >
        {item.name}
      </Navigate>
    );
  };

  const renderType = (item: OutboundCommTemplate) => {
    const commType = comms.ref.outboundCommTypes.keyTextValues.find(
      x => x.key === item.outboundCommTypeCode
    )?.text;
    return item.isDefault ? (
      <>
        {commType}
        <Text styles={{ root: { color: theme.semanticColors.disabledText } }}>
          {" (default)"}
        </Text>
      </>
    ) : (
      `${commType}`
    );
  };

  const renderUpdated = (item: OutboundCommTemplate) => {
    return item.updatedDisplayDate ?? "";
  };

  const renderUpdatedBy = (item: OutboundCommTemplate) => {
    const userName = item.updated || item.created;

    if (userName === User.System) return userName;

    return (
      userName && (
        <UserFetcher username={userName}>{user => user.fullName}</UserFetcher>
      )
    );
  };

  const onColumnHeaderClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const items = templateListResults.value;
    if (items !== undefined) {
      const data = getSortedTemplates(
        column,
        columns,
        templateListResults.value
      );
      templateListResults.set(new Promise(resolve => resolve(data.newItems)));
      setColumns(data.newColumns);
    }
  };

  const defaultColumns: IColumn[] = [
    {
      key: TemplateListLabel.Actions,
      className: RESET_CELLS_PADDING_CLASSNAME,
      minWidth: 56,
      maxWidth: 56,
      name: TemplateListLabel.Actions,
      onRender: renderActions
    },
    {
      key: nameOf("name"),
      minWidth: 79,
      maxWidth: 344,
      name: TemplateListLabel.Name,
      onColumnClick: onColumnHeaderClick,
      onRender: renderName
    },
    {
      key: nameOf("templateType"),
      minWidth: 74,
      maxWidth: 224,
      name: TemplateListLabel.Type,
      onColumnClick: onColumnHeaderClick,
      onRender: renderType
    },
    {
      key: nameOf("updatedDateTime"),
      minWidth: 160,
      maxWidth: 160,
      name: TemplateListLabel.Updated,
      onColumnClick: onColumnHeaderClick,
      onRender: renderUpdated
    },
    {
      key: nameOf("updatedByProvider"),
      minWidth: 160,
      maxWidth: 160,
      name: TemplateListLabel.UpdatedBy,
      onColumnClick: onColumnHeaderClick,
      onRender: renderUpdatedBy
    }
  ];

  const [columns, setColumns] = useState<IColumn[]>(defaultColumns);

  const handleClick = () => {
    routing.push(routes.settings.communications.template.new.pattern);
  };

  return (
    <ScreenLayout
      tileStyles={{
        root: { height: "100%" },
        content: { height: "inherit", display: "flex", flexDirection: "column" }
      }}
      onRenderHeader={() => (
        <Stack
          horizontal
          horizontalAlign="space-between"
          grow
          styles={{ root: { marginBottom: 16 } }}
        >
          <Heading variant="section-heading">
            {SettingsLabels.communicationsTemplates}
          </Heading>
          <CommsScreenCommands selectedKey={CommsSectionViews.templates} />
        </Stack>
      )}
    >
      <TemplatesFilter>
        {state => {
          return (
            <Observer>
              {() => (
                <Stack
                  grow
                  styles={{
                    root: {
                      position: "relative",
                      height: "100%",
                      overflow: "hidden"
                    }
                  }}
                >
                  <ScrollablePane styles={{ root: { height: "100%" } }}>
                    <ShimmeredDetailsList
                      setKey="templatesList"
                      items={getFilteredTemplates(
                        state.values,
                        templateListResults?.value ?? []
                      )}
                      columns={columns}
                      enableShimmer={templateListResults.pending}
                      errorMessage={templateListResults.error?.message}
                    />

                    {templateListResults.value &&
                      templateListResults.value.length === 0 && (
                        <CenteredBox>
                          <AddBox
                            onClick={handleClick}
                            subText="No new template created"
                            buttonText={TITLE}
                            text=""
                          />
                        </CenteredBox>
                      )}
                  </ScrollablePane>
                </Stack>
              )}
            </Observer>
          );
        }}
      </TemplatesFilter>
    </ScreenLayout>
  );
});

export const TemplatesList = withFetch(
  x => [x.comms.ref.outboundCommTypes.load()],
  TemplatesListBase
);
