import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { PatientNoticesAddButton } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesAddButton.tsx";
import { PatientNoticesContextProvider } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesContext.tsx";
import {
  NoticeType,
  PatientNoticesModel
} from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { PatientNoticesTable } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTable.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { SecondColumnWrapper } from "../../SecondColumnWrapper.tsx";
import { appointmentFormNameOf } from "../AppointmentForm.types.ts";

export const AppointmentNoticesColumn: React.FC = () => {
  const {
    input: { value: patientId }
  } = useField(appointmentFormNameOf("patientId"));

  const { practice } = useStores();

  return (
    <PatientNoticesContextProvider
      value={
        new PatientNoticesModel(practice, {
          patientId,
          withSystemNotices: true
        })
      }
    >
      <SecondColumnWrapper
        heading={<PatientNoticesAddButton filter={NoticeType.Admin} />}
        childrenGap={0}
      >
        <Stack.Item grow>
          <PatientNoticesTable
            showActions
            padding="0 0 0 8px"
            childrenGap={0}
            filter={NoticeType.Admin}
          />
        </Stack.Item>
      </SecondColumnWrapper>
    </PatientNoticesContextProvider>
  );
};
