import { FunctionComponent, useRef } from "react";

import { routes } from "@libs/routing/routes.ts";
import { onSubmitAccManagementForm } from "@modules/acc/screens/claim-management/components/utils.tsx";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimHelper } from "@modules/acc/screens/claim/context/ClaimHelper.ts";
import { ClaimDataFetcher } from "@modules/acc/screens/shared-components/ClaimDataFetcher.tsx";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClaimManagementScreenBaseProps } from "../claim/types/claim.types.ts";
import { ClaimManagementForm } from "./components/ClaimManagementForm.tsx";
import { ClaimManagementFormHeader } from "./components/ClaimManagementFormHeader.tsx";

const ClaimManagementScreenBase: FunctionComponent<
  ClaimManagementScreenBaseProps
> = ({ claim }) => {
  const root = useStores();
  const { routing } = root;

  const onSubmitSucceeded = (claim: Claim) => {
    routing.push(routes.claims.management.edit.path({ id: claim.id }));
  };

  const claimHelper = useRef<ClaimHelper>(
    new ClaimHelper(root, claim, {
      onSubmit: onSubmitAccManagementForm,
      onSubmitSucceeded
    })
  );

  const header = <ClaimManagementFormHeader />;

  return (
    <ClaimContext.Provider value={claimHelper.current}>
      <ClaimManagementForm header={header} />
    </ClaimContext.Provider>
  );
};

const ClaimManagementScreen: React.FC = () => {
  const { routing } = useStores();
  const claimId = routes.claims.management.edit.match(routing.location.pathname)
    ?.params.id;
  return (
    <ClaimDataFetcher fetchProvider fetchPatient claimId={claimId}>
      {claim => <ClaimManagementScreenBase claim={claim} />}
    </ClaimDataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClaimManagementScreen;
