import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";

export interface UserInboxActionFormValues {
  dueDate?: Date;
  storeIn: string;
  priority?: string;
  documentId: string;
  extraInfo?: string;
  reportType?: string;
  instructionCode?: string;
  assignedToRoleCode?: string;
  correspondenceType?: string;
  clinicalProperty?: VisibilityAndConfidentiality;
}

export enum VisibilityAndConfidentiality {
  Timeline = "TimeLine",
  Confidential = "Confidential"
}

export enum UserInboxActionFormLabels {
  Save = "Save",
  Type = "Type",
  Cancel = "Cancel",
  Refresh = "Refresh",
  StoreIn = "Store in",
  Priority = "Priority",
  followUp = "Follow-up",
  ExtraInfo = "Extra info",
  Acceptable = "Acceptable",
  ImageIs = "This image is",
  followUpBy = "Follow-up by",
  ResultIs = "This report is",
  DocumentIs = "This document is",
  NotAcceptable = "Not acceptable",
  FileMoved = "File has been moved",
  ActionForProvider = "Action for provider",
  ActionForReception = "Action for reception",
  DialogRemoveConfirmationTitle = "Remove file",
  RemoveFileFeedback = "Document has been removed",
  VisibilityAndConfidentiality = "Visibility & confidentiality",
  DialogRemoveConfirmationSubText = "Are you sure you want to remove this file?",
  ConflictErrorMessage = "This file has been moved by someone else since you opened it. The up-to-date list will load on refresh and discard your changes."
}

export interface UserInboxActionProps {
  document: InboxDocument;
  onClose: () => void;
}
