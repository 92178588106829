import React from "react";

import { ButtonsGroupChoice, Stack, Text, useTheme } from "@bps/fluent-ui";
import { QuestionnaireItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";

import { ClinicalToolAnswer } from "../utils/ClinicalToolAnswerFinder.ts";
import {
  getBgColour,
  getQuestionnaireAnswerTexts
} from "../utils/clinicalToolsUtils.ts";

export interface Rand36ModuleProps {
  questions: QuestionnaireItemDto[];
  answers?: ClinicalToolAnswer[];
  findAnswer: (questionId: string) => string;
  verticalAlignment?: boolean;
  verticalQuestions?: boolean;
  invertedColorOrder?: boolean;
}

export const Rand36Module: React.FC<Rand36ModuleProps> = ({
  questions,
  answers,
  verticalAlignment,
  verticalQuestions,
  invertedColorOrder,
  findAnswer
}) => {
  const theme = useTheme();

  return (
    <Stack>
      {questions.map((item, index) => {
        return (
          <>
            {item.heading && (
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 8 }}
                styles={{
                  root: {
                    padding: "8px 40px"
                  }
                }}
              >
                <Text bold variant="mediumPlus">
                  {item.heading}
                </Text>
              </Stack>
            )}
            <Stack
              key={item.id.toString()}
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
              styles={{
                root: {
                  backgroundColor: getBgColour(
                    index,
                    theme,
                    invertedColorOrder
                  ),
                  padding: "8px 8px"
                }
              }}
            >
              <Stack
                horizontalAlign="space-between"
                horizontal={!verticalAlignment && item.id !== 32}
                verticalAlign="center"
                tokens={{ childrenGap: 8 }}
                styles={{
                  root: {
                    backgroundColor: getBgColour(
                      index,
                      theme,
                      invertedColorOrder
                    ),
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingRight: 8,
                    width: "100%"
                  }
                }}
              >
                <Stack
                  horizontal
                  styles={{
                    root: {
                      maxWidth:
                        verticalAlignment || item.id === 32 ? "100%" : "50%"
                    }
                  }}
                >
                  <Text bold styles={{ root: { minWidth: 16 } }}>
                    {item.id}.
                  </Text>
                  <Text bold styles={{ root: { paddingLeft: 18 } }}>
                    {item.text}
                  </Text>
                </Stack>
                <Stack>
                  {!answers && (
                    <ButtonsGroupSingleChoiceField
                      name={`items.q${item.id}`}
                      options={getQuestionnaireAnswerTexts(
                        item.answerOptions || [],
                        {
                          disabled: false
                        }
                      )}
                      vertical={verticalQuestions}
                      styles={{ label: { whiteSpace: "no-wrap" } }}
                      fieldItemStyles={{
                        item: {
                          marginLeft:
                            verticalAlignment || item.id === 32 ? 33 : 0
                        }
                      }}
                    />
                  )}

                  {answers && (
                    <ButtonsGroupChoice
                      name={`items.q${item.id}`}
                      options={getQuestionnaireAnswerTexts(
                        item.answerOptions || [],
                        {
                          disabled: true
                        }
                      )}
                      value={findAnswer(`${item.id}`)}
                      styles={{
                        root: {
                          marginLeft: verticalAlignment ? 40 : 0,
                          backgroundColor: getBgColour(
                            index,
                            theme,
                            invertedColorOrder
                          )
                        }
                      }}
                      vertical={verticalQuestions}
                      onChange={() => {}}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </>
        );
      })}
    </Stack>
  );
};
