import { createContext, useContext } from "react";

import { FamilyHistoryFormHelper } from "./FamilyHistoryFormHelper.ts";

export const FamilyHistoryFormContext = createContext<FamilyHistoryFormHelper>(
  {} as FamilyHistoryFormHelper
);

export const useFamilyHistoryFormContext = () =>
  useContext(FamilyHistoryFormContext);
