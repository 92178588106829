import { FunctionComponent } from "react";

import {
  FontWeights,
  Link,
  MessageBar,
  MessageBarType,
  Shimmer,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DrugMedicationSummaryDto } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface PrescribingProductCardProps {
  drug: DrugMedicationSummaryDto | undefined;
  loading: boolean;
  error?: Error;
}
const PrescribingProductCardComponent: FunctionComponent<
  PrescribingProductCardProps
> = ({ drug, loading, error }) => {
  const { drugs } = useStores();
  const theme = useTheme();
  if (loading) return <Shimmer />;
  if (error)
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        {error.message}
      </MessageBar>
    );

  if (!drug) return null;

  const { productPack }: DrugMedicationSummaryDto = drug;
  const {
    medication,
    pbsListing,
    productInformationUrl,
    consumerInformationUrl
  } = productPack;

  return (
    <Stack
      horizontal
      styles={(props, theme) => ({
        root: {
          borderRadius: 4,
          border: `1px ${theme.semanticColors.inputBorder} solid`,
          padding: 8,
          width: "100%",
          alignItems: "center"
        }
      })}
      tokens={{ childrenGap: 8 }}
    >
      <Stack
        grow
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
            textOverflow: "inherit",
            whiteSpace: "pre-wrap"
          }
        }}
      >
        {medication}
      </Stack>
      <Stack grow>
        <Text
          styles={{
            root: {
              padding: "4px 8px",
              background: theme.semanticColors.blockingBackground,
              borderRadius: 2
            }
          }}
        >
          {drugs.ref.pbsListings.get(pbsListing)?.text}
        </Text>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 4 }}>
        <Link
          disabled={!productInformationUrl}
          href={productInformationUrl}
          target="_blank"
        >
          PI
        </Link>
        <Link
          disabled={!consumerInformationUrl}
          href={consumerInformationUrl}
          target="_blank"
        >
          CMI
        </Link>
      </Stack>
    </Stack>
  );
};

export const PrescribingProductCard = withFetch(
  x => x.drugs.ref.pbsListings.load(),
  PrescribingProductCardComponent
);
