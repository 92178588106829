import {
  DisplayOutcome,
  DisplayOutcomeDetail
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DischargeFormValues } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeForm.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { useDischargeContext } from "./DischargeContext.tsx";

export const DisplayOutcomeUnresolved: React.FC = () => {
  const nameOf = nameOfFactory<DischargeFormValues>();
  const { clinical } = useStores();

  const outcomesDetails = clinical.ref.outcomesDetails.keyTextValues;

  const unResolvedDischargerOutcomes = outcomesDetails.filter(
    x =>
      x.key === DisplayOutcomeDetail.PoorAttendee ||
      x.key === DisplayOutcomeDetail.UnableToAttend ||
      x.key === DisplayOutcomeDetail.WillBenefitFromSpecialistReferral
  );

  const { isViewOnly } = useDischargeContext();

  return (
    <div id={DisplayOutcome.Unresolved}>
      <Fieldset tokens={{ childrenGap: 8 }}>
        <ChoiceGroupField
          name={nameOf("dischargeOutcomeUnresolved")}
          options={unResolvedDischargerOutcomes}
          label="Additional comments"
          disabled={isViewOnly}
        />
        <FieldCondition
          when={nameOf("dischargeOutcomeUnresolved")}
          is={DisplayOutcomeDetail.WillBenefitFromSpecialistReferral}
        >
          <TextInputField
            disabled={isViewOnly}
            label="Further specialist information to be returned to referrer"
            name={nameOf("specialistInformationUnresolved")}
          />
        </FieldCondition>
      </Fieldset>
    </div>
  );
};
