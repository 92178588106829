import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { PatientSummaryText } from "@ui-components/RefText.tsx";

import { usePatientSummaryContext } from "./helpers/PatientSummaryContext.tsx";
import { PatientSummaryCardInfo } from "./PatientSummaryCard.tsx";
import { PatientSummaryCardCallout } from "./PatientSummaryCardCallout.tsx";

interface PatientSummaryHeaderProps
  extends Pick<PatientSummaryCardInfo, "iconName" | "onClickLink" | "callout"> {
  code: string;
  isDisableLink?: boolean;
}

export const PatientSummaryHeader: FunctionComponent<
  PatientSummaryHeaderProps
> = ({ iconName, onClickLink, code, callout, isDisableLink }): JSX.Element => {
  const context = usePatientSummaryContext();
  const theme = useTheme();
  return (
    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
      <FontIcon
        iconName={iconName}
        className={mergeStyles({
          fontSize: FontSizes.xLarge
        })}
      />
      {isDisableLink ? (
        <PatientSummaryText
          code={code}
          styles={{ root: { color: theme.palette.neutralPrimary } }}
        />
      ) : (
        <Link
          onClick={evt => {
            onClickLink && onClickLink();
            callout &&
              context &&
              context.setVisibleCallout &&
              context.setVisibleCallout(evt);
          }}
          styles={{
            root: {
              fontSize: FontSizes.size14,
              fontWeight: FontWeights.regular
            }
          }}
          {...dataAttribute(
            DataAttributes.Element,
            `patient-summary-title-link-${code}`
          )}
        >
          <PatientSummaryText code={code} />
        </Link>
      )}
      {callout ? (
        <PatientSummaryCardCallout>{callout}</PatientSummaryCardCallout>
      ) : null}
    </Stack>
  );
};
