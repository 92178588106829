import { FunctionComponent, useContext } from "react";
import { useField, useForm } from "react-final-form";

import {
  CommandBarButton,
  dataAttribute,
  DataAttributes
} from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { AvailabilitySlot } from "@stores/booking/models/AvailabilitySlots.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";

interface TimeSlotBaseProps {
  slot: AvailabilitySlot;
  providerId: string;
  orgUnitId: string;
  date: DateTime;
}
export const TimeSlot: FunctionComponent<TimeSlotBaseProps> = ({
  slot,
  providerId,
  orgUnitId,
  date
}) => {
  const { asyncCreateTemporaryReservation, core } = useContext(
    AppointmentFormContext
  );

  const { booking } = useStores();

  const form = useForm<AppointmentFormValues>();

  const formProviderId = form.getState().values.providerId;

  const {
    input: { value: startTime }
  } = useField<string>(appointmentFormNameOf("startTime"), {
    subscription: { value: true }
  });

  const updateTime = (time: string) => async () => {
    form.batch(async () => {
      form.change(appointmentFormNameOf("startTime"), time);
      form.change(appointmentFormNameOf("startDate"), date.toJSDate());

      form.change(appointmentFormNameOf("orgUnitId"), orgUnitId);

      if (core.hasPermissions([Permission.PreRelease])) {
        await asyncCreateTemporaryReservation(form.getState().values);
      }
      form.change(appointmentFormNameOf("providerId"), providerId);
    });

    closePanel(time);
  };

  const closePanel = (value: string | undefined) => {
    if (
      value &&
      booking.ui.currentAppointment?.secondColumnContent ===
        SecondColumnContent.nextAvailable &&
      providerId
    ) {
      booking.ui.setSecondColumnContent(undefined);
    }
  };

  const timeSlotToRender = DateTime.fromISO(slot.availableTime).toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  );

  const checked =
    slot.availableTime === startTime && providerId === formProviderId;

  return (
    <CommandBarButton
      {...dataAttribute(DataAttributes.Element, "next-available-time-slot")}
      data-checked={checked}
      onClick={updateTime(slot.availableTime)}
      styles={{
        root: {
          padding: "8px 0",
          backgroundColor: "inherit"
        }
      }}
      toggle
      checked={checked}
      text={timeSlotToRender}
    />
  );
};
