import React from "react";
import { Field } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  ITag,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  CalendarEventType,
  Frequency
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { toRecurrenceOptions } from "@libs/utils/calendar/calendar.utils.ts";
import {
  isAfter,
  isMonth,
  isOnDate,
  isWeek,
  isYear
} from "@modules/booking/screens/booking-calendar/components/utils.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { WeekdaysDropdownField } from "@ui-components/form/WeekdaysDropdownField.tsx";

import { getFrequencyKeyTextOptions } from "../../utils.ts";
import { UserReservesFormTestElements } from "./UserReserves.types.ts";

const REC_WIDTH = 500;

export enum UserReservesFieldGroupLabels {
  on = "On",
  type = "Type",
  until = "Until",
  start = "Start",
  remove = "Remove",
  purpose = "Purpose",
  endTime = "End time",
  frequency = "Frequency",
  startTime = "Start time",
  occurrence = "Occurrence",
  recurEvery = "Recur every",
  occurrences = "Occurrences",
  endSchedule = "End schedule",
  startSchedule = "Start schedule"
}

export interface UserReservesFieldGroupProps {
  name: string;
  index: number;
  disabled?: boolean;
  removeField: (index: number) => void;
}

const typeDataSource = (): ITag[] => {
  return [
    {
      key: CalendarEventType.Unavailable,
      name: "Unavailable"
    }
  ];
};

export const UserReservesFieldGroup: React.FC<UserReservesFieldGroupProps> = ({
  name,
  index,
  removeField,
  disabled
}) => {
  const { core } = useStores();
  const theme = useTheme();
  return (
    <Stack
      {...dataAttribute(
        DataAttributes.Element,
        UserReservesFormTestElements.FieldGroup
      )}
      styles={{
        root: {
          width: "720px",
          margin: "0 64px 0 64px",
          paddingTop: 16,
          paddingBottom: 16,
          "&:not(:first-child)": {
            borderTop: "1px solid",
            borderColor: theme.palette.neutralLight,
            marginTop: 0
          },
          "&:first-child": {
            paddingTop: 32
          },
          "&:last-child": {
            paddingBottom: 32
          }
        }
      }}
    >
      <Field type="hidden" name={`${name}.id`}>
        {({ input }) => <input {...input} />}
      </Field>
      <Fieldset horizontal>
        <DatePickerField
          {...dataAttribute(
            DataAttributes.Element,
            UserReservesFormTestElements.StartDate
          )}
          disabled={disabled}
          label={UserReservesFieldGroupLabels.startSchedule}
          placeholder={UserReservesFieldGroupLabels.start}
          name={`${name}.startDate`}
        />
        <TimePickerField
          {...dataAttribute(
            DataAttributes.Element,
            UserReservesFormTestElements.StartTime
          )}
          disabled={disabled || !core.hasUserSettingWritePermission}
          label={UserReservesFieldGroupLabels.startTime}
          suggestionInterval={30}
          name={`${name}.startTime`}
        />
        <TimePickerField
          {...dataAttribute(
            DataAttributes.Element,
            UserReservesFormTestElements.EndTime
          )}
          disabled={disabled || !core.hasUserSettingWritePermission}
          label={UserReservesFieldGroupLabels.endTime}
          suggestionInterval={30}
          name={`${name}.endTime`}
        />
        <DropdownField
          disabled={disabled}
          label={UserReservesFieldGroupLabels.endSchedule}
          name={`${name}.endScheduleType`}
          options={[
            { key: "ondate", text: "On date" },
            { key: "after", text: "After" },
            { key: "never", text: "Never" }
          ]}
          {...dataAttribute(
            DataAttributes.Element,
            `${name}-endScheduleType-dropdown`
          )}
        />
        <FieldCondition when={`${name}.endScheduleType`} is={isOnDate}>
          <DatePickerField
            {...dataAttribute(
              DataAttributes.Element,
              UserReservesFormTestElements.UntilDate
            )}
            disabled={disabled}
            placeholder={UserReservesFieldGroupLabels.until}
            name={`${name}.until`}
            label={UserReservesFieldGroupLabels.until}
          />
        </FieldCondition>
        <FieldCondition when={`${name}.endScheduleType`} is={isAfter}>
          <Field name={`${name}.count`} subscription={{ value: true }}>
            {({ input }) => {
              return (
                <SpinNumberInputField
                  disabled={disabled}
                  label={
                    input.value > 1
                      ? UserReservesFieldGroupLabels.occurrences
                      : UserReservesFieldGroupLabels.occurrence
                  }
                  name={`${name}.count`}
                  min={1}
                  max={99}
                  step={1}
                  fieldItemStyles={{
                    itemWrapper: {
                      alignItems: "baseline"
                    },
                    item: { flexGrow: 0 }
                  }}
                  styles={{ root: { width: 120 } }}
                />
              );
            }}
          </Field>
        </FieldCondition>
        {!disabled && core.hasUserSettingWritePermission && (
          <Stack verticalAlign="start" styles={{ root: { marginTop: 24 } }}>
            <DeleteButton
              onClick={() => removeField(index)}
              disabled={disabled}
              text={UserReservesFieldGroupLabels.remove}
            />
          </Stack>
        )}
      </Fieldset>
      <Fieldset horizontal>
        <SpinNumberInputField
          disabled={disabled || !core.hasUserSettingWritePermission}
          label={UserReservesFieldGroupLabels.recurEvery}
          name={`${name}.interval`}
          min={1}
          max={99}
          step={1}
          fieldItemStyles={{
            itemWrapper: { alignItems: "baseline" },
            item: { flexGrow: 0 }
          }}
          // SpinNumberInput component has a min width of 86
          styles={{ root: { width: 86 } }}
        />
        <Field name={`${name}.interval`} subscription={{ value: true }}>
          {({ input }) => (
            <DropdownField
              disabled={disabled}
              styles={{
                root: {
                  width: 100
                }
              }}
              label="&nbsp;"
              name={`${name}.frequency`}
              options={getFrequencyKeyTextOptions(input.value)}
            />
          )}
        </Field>
        <FieldCondition when={`${name}.frequency`} is={isWeek}>
          <WeekdaysDropdownField
            disabled={disabled}
            styles={{
              root: {
                width: REC_WIDTH
              }
            }}
            name={`${name}.dayRecur`}
            label={UserReservesFieldGroupLabels.on}
          />
        </FieldCondition>
        <FieldCondition when={`${name}.frequency`} is={isMonth}>
          <Field name={`${name}.startDate`} subscription={{ value: true }}>
            {({ input }) => {
              return (
                <DropdownField
                  disabled={disabled}
                  styles={{
                    root: {
                      width: REC_WIDTH
                    }
                  }}
                  label={UserReservesFieldGroupLabels.on}
                  name={`${name}.monthYearRecurrence`}
                  options={toRecurrenceOptions(
                    DateTime.fromJSDate(input.value),
                    Frequency.Month
                  )}
                />
              );
            }}
          </Field>
        </FieldCondition>
        <FieldCondition when={`${name}.frequency`} is={isYear}>
          <Field name={`${name}.startDate`} subscription={{ value: true }}>
            {({ input }) => {
              return (
                <DropdownField
                  disabled={disabled}
                  styles={{
                    root: {
                      width: REC_WIDTH
                    }
                  }}
                  label={UserReservesFieldGroupLabels.on}
                  name={`${name}.monthYearRecurrence`}
                  options={toRecurrenceOptions(
                    DateTime.fromJSDate(input.value),
                    Frequency.Year
                  )}
                />
              );
            }}
          </Field>
        </FieldCondition>
      </Fieldset>
      <Fieldset horizontal>
        <StaticPickerField
          disabled={disabled}
          name={`${name}.calendarEventType`}
          label={UserReservesFieldGroupLabels.type}
          fetchDataSource={typeDataSource}
          fieldItemStyles={{ root: { flexGrow: 1 } }}
        />
        <TextInputField
          disabled={disabled}
          styles={{ root: { flexGrow: 1 } }}
          label={UserReservesFieldGroupLabels.purpose}
          name={`${name}.purpose`}
        />
      </Fieldset>
    </Stack>
  );
};
