import { action, observable } from "mobx";

import { DateTime } from "@bps/utils";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AddRefundDto,
  AddRefundItemDto,
  ItemType,
  PaymentMethod
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Allocation } from "@stores/billing/models/Allocation.ts";
import { Payment } from "@stores/billing/models/Payment.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface AllocationViewHelperOptions {
  allocation: Allocation;
  contact: Contact | undefined;
  //temporary identifier for the old payment.
  payment: Payment | undefined;
}

export class AllocationViewHelper {
  allocation: Allocation;
  contact: Contact | undefined;
  payment: Payment | undefined;

  constructor(
    private root: IRootStore,
    options: AllocationViewHelperOptions
  ) {
    this.allocation = options.allocation;
    this.payment = options.payment;
    this.contact = options.contact;
  }

  @observable showCancelDialog: boolean = false;

  @action setShowCancelDialog = (show: boolean) => {
    this.showCancelDialog = show;
  };

  //A way to figure out if we are viewing a payment not created through the new api. old payment id === payment.id
  public get oldPaymentId() {
    return this.payment?.id;
  }

  public get unallocatedCredit() {
    return this.payment?.unallocated;
  }

  public get accPaymentProcessingFeatureEnabled() {
    return this.root.core.hasPermissions(Permission.AccPaymentProcessingPrerel);
  }

  handleCancel = async (reason: string) => {
    await this.root.billing.cancelAllocation(this.allocation.id, reason);

    this.root.notification.success(
      notificationMessages.allocationCancelled(this.allocation.number)
    );
  };

  handleAllocationViewOpenPdf = async (allocationId: string) => {
    await this.root.billing.openAllocationReceiptPdf(allocationId);
  };

  @observable emailDialogVisible: boolean = false;

  @action setEmailDialogVisible = (value: boolean) => {
    this.emailDialogVisible = value;
  };

  getAddRefundDto = (refundNumber: string) => {
    if (this.oldPaymentId && this.payment) {
      const refundItem: AddRefundItemDto = {
        paymentMethod: PaymentMethod.Cash,
        creditId: this.payment.id,
        amount: this.payment?.unallocated,
        itemType: ItemType.Refund,
        locationId: this.root.core.location.id
      };

      const addRefund: AddRefundDto = {
        accountId: this.allocation.accountId,
        location: this.root.core.location.name,
        accountContact: this.allocation.accountContact,
        transactionDate: DateTime.now().toISODate(),
        itemType: ItemType.Refund,
        items: [refundItem],
        number: refundNumber
      };

      return addRefund;
    } else {
      return undefined;
    }
  };

  allocationAccountIsAccProvider = async () => {
    if (this.root.core.tenantDetails?.country === Country.NewZealand) {
      const insurers = await this.root.practice.getDefaultInsurers();
      return this.allocation.accountName === insurers.defaultNzPublicInsurerId;
    }

    return false;
  };
}
