import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";

import { Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { IInvoiceRow } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsList.tsx";
import { AllocationViewModal } from "@modules/billing/screens/allocation/components/AllocationViewModal.tsx";
import {
  WhenNavigate,
  WhenNavigateProps
} from "@ui-components/navigation/WhenNavigate.tsx";

export const AccreditedBillingTransactionAllocationCell: FunctionComponent<IInvoiceRow> =
  observer(props => {
    const [showModal, setShowModal] = useState<boolean>(false);

    if (!props.isFirstItem) {
      return null;
    }

    const allocationRefs = props.invoice.allocationReferences;

    if (!allocationRefs.length) {
      return null;
    }

    const allocation = allocationRefs[0];

    const toggleModal = () => {
      setShowModal(!showModal);
    };

    const onClick: WhenNavigateProps["onClick"] = event => {
      event.preventDefault();
      event.stopPropagation();
      toggleModal();
    };

    return (
      <Stack grow horizontalAlign="end">
        <WhenNavigate
          permission={Permission.AccountHistoryAllowed}
          onClick={onClick}
        >
          {allocation.transaction.number}
        </WhenNavigate>
        {showModal && (
          <AllocationViewModal
            allocationId={allocation.transactionId}
            onDismiss={toggleModal}
            onSubmit={toggleModal}
          />
        )}
      </Stack>
    );
  });
