import { PatientListFilters } from "@modules/practice/screens/address-book/components/contacts-filters/PatientListFilter.tsx";
import {
  dobColumn,
  emailColumn,
  nameColumn,
  phyAddressColumn,
  prePhoneColumn,
  statusColumn,
  typeColumn
} from "@modules/practice/screens/address-book/components/contacts-lists/contact-list-columns.tsx";
import { ContactList } from "@modules/practice/screens/address-book/components/contacts-lists/ContactList.tsx";

export const patientListColumns = [
  nameColumn(),
  dobColumn(),
  prePhoneColumn(),
  emailColumn(),
  phyAddressColumn(),
  typeColumn(),
  statusColumn()
];

export const PatientScreen = () => {
  return (
    <PatientListFilters>
      {({ values: filter }) => (
        <ContactList columns={patientListColumns} filter={filter} />
      )}
    </PatientListFilters>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PatientScreen;
