import { observer } from "mobx-react-lite";

import {
  dataAttribute,
  DataAttributes,
  DetailsList,
  DtoDetailsRow,
  IDetailsRowProps,
  Link,
  Stack,
  TextWithIcon,
  useScreenSize
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  TreeView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TerminologyText } from "@modules/clinical/screens/patient-record/components/TerminologyText.tsx";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { PatientClinicalRecordTab } from "@stores/clinical/models/clinical-tab/PatientClinicalRecordTab.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";

interface EncounterItem {
  id: string;
  date: Date;
}

interface RFVCardProps {
  encounters: Encounter[] | undefined;
  currentPatientRecordTab: PatientClinicalRecordTab;
}

export const RFVCard: React.FunctionComponent<RFVCardProps> = observer(
  ({ currentPatientRecordTab, encounters }) => {
    const { clinical, core } = useStores();
    const { width } = useScreenSize();
    let content: EncounterItem[] = [];
    if (encounters) {
      const cutOffDate = DateTime.now().minus({ months: 24 });

      const sortedEncounters = encounters
        ? encounters
            ?.filter(e => e.startDateTime >= cutOffDate)
            ?.sort((a: Encounter, b: Encounter) => {
              return (
                0 -
                (a.startDateTime.toJSDate() > b.startDateTime.toJSDate()
                  ? 1
                  : -1)
              );
            })
        : [];

      if (sortedEncounters) {
        const topEncounters = sortedEncounters.slice(0, 8);
        content = topEncounters.map(item => {
          return {
            id: item.id,
            date: item.startDateTime.toJSDate()
          } as EncounterItem;
        });
      }
    }

    return content.length > 0 ? (
      <Stack
        styles={{
          root: {
            overflowY: "auto"
          }
        }}
      >
        <DetailsList
          setKey="past consults"
          items={content}
          compact
          onRenderRow={(x: IDetailsRowProps) => <DtoDetailsRow {...x} />}
          columns={[
            {
              name: "Date",
              key: "date",
              minWidth: 103,
              maxWidth: 103,
              onRender: (item: EncounterItem) =>
                DateTime.fromJSDate(item.date).toDayDefaultFormat()
            },
            {
              name: "Reasons",
              key: "reasons",
              minWidth: 20,
              isMultiline: true,
              onRender: (item: EncounterItem) => {
                return (
                  <DataFetcher<EncounterClinicalDataDto>
                    fetch={async () =>
                      await clinical.getEncounterClinicalData({
                        encounterId: item.id,
                        types: [ClinicalDataType.ReasonForVisit]
                      })
                    }
                  >
                    {data => {
                      const confidential = !core.hasAccessToSecGroup(
                        data.reasonForVisit?.secGroupId
                      );

                      const reasonForVisits =
                        data.reasonForVisit?.reasonForVisits;
                      return (
                        <Link
                          disabled={confidential}
                          onClick={() => {
                            currentPatientRecordTab.sidePanelTabId = item.id;
                            currentPatientRecordTab.sidePanelTab =
                              TreeView.PastConsults;

                            if (
                              clinical.ui.tabs.currentPatientRecordTab?.state
                                .sidePanelSize === TreeViewSize.IconsOnly
                            ) {
                              clinical.ui.tabs.currentPatientRecordTab.toggleSidePanel(
                                true,
                                width
                              );
                            }
                          }}
                        >
                          {confidential ? (
                            <TextWithIcon
                              iconProps={{
                                iconName: "ShieldAlert"
                              }}
                            >
                              Confidential
                            </TextWithIcon>
                          ) : (
                            reasonForVisits && (
                              <TerminologyText
                                codedTexts={reasonForVisits.map(r => ({
                                  code: r.code,
                                  text: r.originalText
                                }))}
                                id={`pv-${item?.id}`}
                              />
                            )
                          )}
                        </Link>
                      );
                    }}
                  </DataFetcher>
                );
              }
            }
          ].map(c => ({
            ...c,
            onRender: item => (
              <div {...dataAttribute(DataAttributes.Element, c.key)}>
                {c.onRender ? c.onRender(item) : c.name}
              </div>
            )
          }))}
          cellStyleProps={{
            cellExtraRightPadding: 24,
            cellRightPadding: 8,
            cellLeftPadding: 2
          }}
        />
      </Stack>
    ) : (
      <PatientSummaryEmptyState />
    );
  }
);
