import { ReasonsForDiscard } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

export interface EncounterDiscardFormValues {
  reasonForDiscard: ReasonsForDiscard;
  reasonForDiscardComment?: string;
}
export class EncounterDiscardValidator extends Validator<EncounterDiscardFormValues> {
  constructor(core: CoreStore) {
    super();
    this.forField("reasonForDiscardComment", [maxLength(250)]);

    if (
      core.user?.userSetting?.clinicalView?.enforceDiscardConsultReason ??
      true
    )
      this.forField("reasonForDiscard", [required()]);
  }
}
