import { useRef } from "react";
import { useField, useForm } from "react-final-form";

import { Heading } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFieldArray } from "@libs/utils/name-of.utils.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { AddressFields } from "@modules/practice/screens/shared-components/AddressFields.tsx";
import { AddressTypeCheckboxFields } from "@modules/practice/screens/shared-components/AddressTypeCheckboxFields.tsx";
import { AddressFieldsLabels } from "@modules/practice/screens/shared-components/types/address-fields-labels.enum.ts";
import { AddressValidator } from "@modules/practice/screens/shared-components/validation/AddressValidator.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { Address, RefCountry } from "@stores/core/models/Address.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { capitaliseAddress } from "@stores/practice/utils/practice.utils.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

interface InvoiceDetailsNewAddressDialogProps {
  onDismiss: () => void;
}

export interface AddressFormValues {
  address: AddressFieldValue;
}

class InvoiceDetailsAddressValidator extends Validator<AddressFormValues> {
  constructor(countries: readonly RefCountry[], country: Country) {
    const empty = (x?: AddressFieldValue) => {
      return x
        ? !x.street1 &&
            !x.street2 &&
            !x.suburb &&
            !x.postCode &&
            !x.state &&
            !x.city &&
            x.types?.length === 2
        : true;
    };

    const addressValidator = new AddressValidator({
      countries,
      isRequired: true,
      emptyOverride: empty,
      country
    });

    super();
    this.forField("address", addressValidator.validate);
  }
}

export const InvoiceDetailsNewAddressDialog: React.FC<
  InvoiceDetailsNewAddressDialogProps
> = ({ onDismiss }) => {
  const {
    core: { tenantDetails }
  } = useStores();

  const fieldName = nameOfFieldArray<Address>("address");

  const { core } = useStores();
  const { change } = useForm();

  const {
    input: { value: accountContactId }
  } = useField("accountContactId", { subscription: { value: true } });

  const onSubmit = (values: AddressFormValues) => {
    const address = capitaliseAddress(values.address);

    change("newAddress", address);
    change("accountAddress", JSON.stringify(address));
    onDismiss();
  };

  const validator = useRef(
    new InvoiceDetailsAddressValidator(
      core.ref.countries.values,
      tenantDetails!.country
    )
  );

  return (
    <DataFetcher<Contact>
      fetch={({ practice }) => practice.getContact(accountContactId)}
    >
      {accountHolder => {
        return (
          <SubmissionFormDialog<AddressFormValues>
            dialogName="New Address Dialog"
            validate={validator.current.validate}
            onSubmit={onSubmit}
            dialogProps={{
              onDismiss,
              minWidth: 520,
              dialogContentProps: {
                title: (
                  <Heading variant="modal-heading">
                    New address for {accountHolder.name}
                  </Heading>
                ),
                showCloseButton: false
              }
            }}
            initialValues={{
              address: {
                street1: undefined,
                country: core.tenantDetails?.country!,
                types: [AddressType.Physical, AddressType.Postal],
                isNew: true,
                id: ""
              }
            }}
          >
            {formProps => (
              <AddressFields
                hideLegendText
                useLabels
                required
                disableRemoveLink
                street1InputOverride={
                  <div>
                    <TextInputField
                      required
                      name={fieldName("street1")}
                      autoFocus
                      label={
                        formProps.values.address.country === Country.NewZealand
                          ? AddressFieldsLabels.Street
                          : AddressFieldsLabels.Address1
                      }
                      parse={(value: string) => value ?? ""}
                      actionButton={{
                        onRender: () => (
                          <AddressTypeCheckboxFields parentName="address" />
                        )
                      }}
                    />
                  </div>
                }
                value={formProps.values.address}
                name="address"
              />
            )}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
};
