import { memo } from "react";

import { Heading } from "@bps/fluent-ui";
import {
  AddAppointmentCancellationReasonDto,
  UpdateAppointmentCancellationReasonDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { AppointmentCancellationReason } from "@stores/booking/models/AppointmentCancellationReason.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import {
  AppointmentCancellationReasonFields,
  AppointmentCancellationReasonStatus,
  CancellationReasonValue
} from "./AppointmentCancellationReasonFields.tsx";

interface EditAppointmentCancellationReasonDialogProps {
  hidden?: boolean;
  onDismiss: () => void;
  reason?: AppointmentCancellationReason;
}

export const AppointmentCancellationReasonDialog: React.FunctionComponent<EditAppointmentCancellationReasonDialogProps> =
  memo(({ hidden, onDismiss, reason }) => {
    const { booking, notification, core } = useStores();
    if (hidden) return null;

    const submitForm = async (value: CancellationReasonValue) => {
      if (reason) {
        const currentInstance: UpdateAppointmentCancellationReasonDto = {
          id: reason.id,
          eTag: reason.eTag,
          text: value.text,
          isInactive:
            value.isInactive === AppointmentCancellationReasonStatus.Inactive,
          isSystemManaged: reason.isSystemManaged,
          isDeleted: false
        };
        await booking.updateCalendarCancellationReasons(currentInstance);
        notification.success("Appointment cancellation reason updated");
      } else {
        const currentInstance: AddAppointmentCancellationReasonDto = {
          text: value.text,
          isInactive:
            value.isInactive === AppointmentCancellationReasonStatus.Inactive,
          isSystemManaged: false
        };
        await booking.addCalendarCancellationReasons(currentInstance);
        notification.success("Appointment cancellation reason added");
      }
    };

    const dialogName = `${reason ? "Edit" : "Add"} cancellation reason`;

    return (
      <SubmissionFormDialog
        readOnly={!core.hasPermissions(Permission.OrgUnitSettingWrite)}
        dialogName="Appointment cancellation reason dialog"
        onSubmit={submitForm}
        initialValues={
          reason
            ? {
                text: reason.text,
                isInactive: reason.isInactive
                  ? AppointmentCancellationReasonStatus.Inactive
                  : AppointmentCancellationReasonStatus.Active
              }
            : {
                text: "",
                isInactive: AppointmentCancellationReasonStatus.Active
              }
        }
        onSubmitSucceeded={onDismiss}
        dialogProps={{
          onDismiss,
          modalProps: {
            layerProps: {
              eventBubblingEnabled: true
            }
          },
          dialogContentProps: {
            title: <Heading variant="modal-heading">{dialogName}</Heading>,
            showCloseButton: true
          },
          minWidth: 600,
          maxWidth: 600
        }}
        component={() => (
          <AppointmentCancellationReasonFields reason={reason} />
        )}
      />
    );
  });
