import { FontWeights, IButtonStyles, IStyle, ITheme } from "@bps/fluent-ui";

export const getUserStylesSet = (theme: ITheme) => {
  const pendingSignUpStatusButton: IButtonStyles = {
    flexContainer: {
      backgroundColor: theme.semanticColors.warningBackground,
      text: theme.palette.yellowDark,
      padding: "6px 16px",
      marginRight: 8,
      fontWeight: FontWeights.semibold
    },
    rootDisabled: {
      color: theme.palette.yellowDark
    }
  };

  const userActiveStatusButton: IButtonStyles = {
    flexContainer: {
      background: theme.semanticColors.successBackground,
      padding: "6px 16px",
      marginRight: 8,
      fontWeight: FontWeights.semibold,
      borderRadius: 4
    },
    rootDisabled: {
      color: theme.palette.green
    }
  };

  const userInactiveStatusButton: IButtonStyles = {
    root: {
      "&:disabled": {
        color: theme.palette.white
      },
      background: theme.palette.neutralTertiary,
      padding: "6px 16px",
      marginRight: 8,
      fontWeight: FontWeights.semibold,
      borderRadius: 4
    }
  };

  const toggleIsActivateButton: IButtonStyles = {
    root: {
      padding: "6px 16px"
    }
  };

  const formFooter: IStyle = {
    marginTop: 0,
    padding: 24,
    borderTop: "1px solid",
    borderTopColor: theme.palette.neutralLight,
    borderRadius: "4px"
  };

  const settingsSeparator: IStyle = {
    root: { padding: "24px 0", height: 0 }
  };

  const formFields: IStyle = {
    width: 630,
    paddingTop: "16px",
    paddingRight: "0px",
    paddingBottom: "16px",
    paddingLeft: "0px",
    margin: "0px 64px 0px 64px"
  };
  return {
    userActiveStatusButton,
    userInactiveStatusButton,
    pendingSignUpStatusButton,
    toggleIsActivateButton,
    formFooter,
    formFields,
    settingsSeparator
  };
};
