import { useForm } from "react-final-form";

import { Card, Heading, Separator, Stack } from "@bps/fluent-ui";
import { getAccidentAndEmploymentDetailsViewValues } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  AccidentAndEmploymentDetailsViewValues,
  LabeledTextFields
} from "../types/claim.types.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";
import { ViewField } from "./ViewField.tsx";

const AccidentAndEmploymentDetailsViewSectionBase: React.FC<{
  accidentAndEmploymentDetailsValues: AccidentAndEmploymentDetailsViewValues;
}> = ({ accidentAndEmploymentDetailsValues }) => {
  const fields: LabeledTextFields[] = [
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.date,
          children: accidentAndEmploymentDetailsValues.accidentDateFormatted
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.sceneOfAccident,
          children: accidentAndEmploymentDetailsValues.accidentSceneText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.accidentLocation,
          children: accidentAndEmploymentDetailsValues.accidentLocationText
        }
      ]
    },
    {
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.describeTheAccident,
          children: accidentAndEmploymentDetailsValues.causeOfAccident
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.involvesVehicle,
          children: accidentAndEmploymentDetailsValues.involvesVehicleText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.medicalTreatmentInjury,
          children:
            accidentAndEmploymentDetailsValues.medicalTreatmentInjuryText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.workInjury,
          children: accidentAndEmploymentDetailsValues.workInjuryText
        }
      ]
    },
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.sportingInjury,
          children: accidentAndEmploymentDetailsValues.sportingInjuryText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.sport,
          children: accidentAndEmploymentDetailsValues.sportText
        }
      ]
    },
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.paidEmployment,
          children: accidentAndEmploymentDetailsValues.inPaidEmploymentText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.occupation,
          children: accidentAndEmploymentDetailsValues.occupationText
        }
      ]
    },
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.employmentStatus,
          children: accidentAndEmploymentDetailsValues.employmentStatusText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.workType,
          children: accidentAndEmploymentDetailsValues.workTypeText
        }
      ]
    },
    {
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.businessName,
          children: accidentAndEmploymentDetailsValues.employerName
        }
      ]
    },
    {
      renderInGroup: true,
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.address,
          children: accidentAndEmploymentDetailsValues.employerAddressFormatted
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.addressType,
          children: accidentAndEmploymentDetailsValues.employerAddressTypeText
        }
      ]
    }
  ];

  return (
    <Card
      heading={ClaimSectionHeadings.accidentAndEmploymentDetails}
      headingLevel="section-heading"
      iconName="BpMedCare"
      id={ClaimsCardIds.accidentAndEmploymentDetails}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <Heading variant="section-heading-light">Accident</Heading>
        <ViewField {...fields[0]} />
        <ViewField {...fields[1]} />
        <ViewField {...fields[2]} />
      </Stack>

      <Separator />

      <Stack tokens={{ childrenGap: 8 }}>
        <Heading variant="section-heading-light">Employment</Heading>
        <ViewField {...fields[3]} />
        <ViewField {...fields[4]} />
        <ViewField {...fields[5]} />
        <ViewField {...fields[6]} />
      </Stack>
    </Card>
  );
};

const AccidentAndEmploymentDetailsDataFetcher = () => {
  const values = useForm<ClaimFormValues>().getState().values;

  return (
    <DataFetcher
      fetch={(root: IRootStore) =>
        getAccidentAndEmploymentDetailsViewValues({ values, root })
      }
    >
      {(
        accidentAndEmploymentDetails: AccidentAndEmploymentDetailsViewValues
      ) => (
        <AccidentAndEmploymentDetailsViewSectionBase
          accidentAndEmploymentDetailsValues={accidentAndEmploymentDetails}
        />
      )}
    </DataFetcher>
  );
};

export const AccidentAndEmploymentDetailsViewSection = withFetch(
  x => [
    x.acc.ref.sports.load(),
    x.acc.ref.sports.load(),
    x.acc.ref.occupations.load(),
    x.acc.ref.employmentStatuses.load(),
    x.acc.ref.workTypes.load(),
    x.acc.ref.accidentScenes.load(),
    x.acc.ref.accidentLocations.load()
  ],
  AccidentAndEmploymentDetailsDataFetcher
);
