import {
  HeaderOptions,
  PaymentTotals
} from "@modules/billing/screens/shared-components/AllocationList/AllocationListBase.types.tsx";
import { TransactionView } from "@modules/billing/screens/shared-components/TransactionView.tsx";
import { WriteOff } from "@stores/billing/models/WriteOff.ts";

import { getWriteOffAllocations } from "../utils.ts";
import { WriteOffHeaderButtons } from "./WriteOffViewButtons.tsx";

interface WriteOffProps {
  writeOff: WriteOff;
}

export const WriteOffView: React.FC<WriteOffProps> = ({ writeOff }) => {
  const allocations = writeOff.items?.map(getWriteOffAllocations) || [];

  const headerOptions: HeaderOptions = {
    fields: [
      {
        key: PaymentTotals.total,
        title: "Written-off ($)"
      }
    ]
  };

  const allocationProps = [
    {
      allocations,
      unallocatedAmount: 0,
      columnOptions: {
        headingOptions: { allocationName: "Written-off ($)" },
        statusText: {
          partAllocated: "Part-written-off",
          fullyAllocated: "Written-off"
        }
      },
      headerOptions
    }
  ];

  return (
    <TransactionView
      transaction={writeOff}
      allocationOptions={{ allocationProps }}
      buttons={<WriteOffHeaderButtons writeOff={writeOff} />}
    />
  );
};
