import { useContext } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { MeetingFormContext } from "../context/MeetingFormContext.ts";
import { nameOf } from "./MeetingFormValues.ts";

export const CreateTemporaryReservation: React.FunctionComponent = () => {
  const { asyncCreateTemporaryReservation } = useContext(MeetingFormContext);

  return (
    <When permission={Permission.PreRelease}>
      <FieldSpy
        name={nameOf("duration")}
        onChange={(value, values) => asyncCreateTemporaryReservation(values!)}
      />
      <FieldSpy
        name={nameOf("providerId")}
        onChange={(value, values) => asyncCreateTemporaryReservation(values!)}
      />
      <FieldSpy
        name={nameOf("startDate")}
        onChange={(value, values) => asyncCreateTemporaryReservation(values!)}
      />
    </When>
  );
};
