import { IRenderFunction, Separator, Stack, Tile } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import { ClinicalHeader } from "./ClinicalHeader.tsx";
import { PatientSummaryContextProvider } from "./patient-summary/helpers/PatientSummaryContext.tsx";
import { PersonaDetails } from "./patient-summary/PersonaDetails.tsx";

export interface PatientDemographicsHeaderProps {
  topPanel?: IRenderFunction<{}>;
}

export const PatientDemographicsHeader: React.FC<
  PatientDemographicsHeaderProps
> = ({ topPanel }) => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  if (!clinicalRecord) return null;

  return (
    <PatientSummaryContextProvider>
      <Stack id="patient-overview" grow>
        <Tile
          styles={{
            root: {
              height: "100%",
              padding: 16,
              width: 324,
              overflowY: "auto",
              overflowX: undefined
            }
          }}
        >
          {topPanel && topPanel()}
          <Stack
            horizontalAlign="space-between"
            styles={{
              root: {
                overflow: "hidden"
              }
            }}
          >
            <Stack.Item
              styles={{
                root: {
                  alignSelf: "left"
                }
              }}
            >
              <PersonaDetails />
            </Stack.Item>
            <Separator styles={{ root: { marginTop: 0, padding: 0 } }} />
            <Stack.Item styles={{ root: { alignSelf: "right" } }}>
              <ClinicalHeader />
            </Stack.Item>
          </Stack>
        </Tile>
      </Stack>
    </PatientSummaryContextProvider>
  );
};
