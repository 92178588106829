import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";

import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalTaskCompleteDialog } from "@modules/clinical/screens/patient-record/components/clinical-task/ClinicalTaskCompleteDialog.tsx";
import { ClinicalTaskDeleteDialog } from "@modules/clinical/screens/patient-record/components/clinical-task/ClinicalTaskDeleteDialog.tsx";
import { ClinicalTaskDialog } from "@modules/clinical/screens/patient-record/components/clinical-task/ClinicalTaskDialog.tsx";
import { ClinicalTaskFormModel } from "@modules/clinical/screens/patient-record/components/clinical-task/ClinicalTaskFormModel.ts";
import { findRelatedClinicalActivity } from "@modules/clinical/screens/patient-record/components/clinical-task/utils.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { TasksClinicalList } from "./TasksClinicalList.tsx";

export const TasksClinical: FC = observer(() => {
  const { clinical, core } = useStores();

  const {
    selectedClinicalTasks,
    setSelectedClinicalTasks,
    setPatientClinicalTasks,
    patientClinicalTasks,
    setTaskDialogVisible,
    taskDialogVisible
  } = useContext(InboxScreenContext);

  useEffect(() => {
    if (selectedClinicalTasks?.length > 0) {
      const clinicalTask = selectedClinicalTasks[0];

      const fetchTasks = async () => {
        const tasks = await clinical.getPatientClinicalTasks(
          clinicalTask.patientId
        );
        setPatientClinicalTasks(tasks);
      };

      fetchTasks();
    }
  }, [clinical, selectedClinicalTasks, setPatientClinicalTasks]);

  const { initialValues, onSubmit } = new ClinicalTaskFormModel(
    clinical,
    core,
    selectedClinicalTasks[0]
  );

  const handleDeleteConfirmed = async (reasonForDeleteComment?: string) => {
    const promises = selectedClinicalTasks.map(task => {
      const item = {
        ...task.dto,
        isDeleted: true,
        deletedComment: reasonForDeleteComment
      };
      return clinical.updateClinicalTask(task.patientId, [item]);
    });

    if (
      core.hasPermissions([
        Permission.ClinActivityRead,
        Permission.ClinActivityWrite
      ])
    ) {
      const activitiesPromises = selectedClinicalTasks.map(async task => {
        const activities = await clinical.getPatientClinicalActivities(
          task.patientId
        );

        if (activities) {
          const associatedActivity = findRelatedClinicalActivity(
            task,
            clinical.activityDescriptionMapValues,
            activities
          );
          if (associatedActivity) {
            const activity = {
              ...associatedActivity.dto,
              isDeleted: true,
              deletedComment: reasonForDeleteComment
            };

            return clinical.updateClinicalActivity(task.patientId, [activity]);
          }
        }
      });

      promises.push(...activitiesPromises);
    }

    await Promise.all(promises);
    setTaskDialogVisible({
      ...taskDialogVisible,
      delete: false
    });
    setSelectedClinicalTasks([]);
  };

  const incompleteSelectedClinicalTasks = () => {
    return selectedClinicalTasks?.filter(x => x && !x.isCompleted);
  };

  const handleCompleteConfirmed = async (notes?: string) => {
    const promises = incompleteSelectedClinicalTasks().map(task => {
      const item = {
        ...task.dto,
        completionNotes: notes,
        isCompleted: true,
        completedBy: core.userId,
        completedDate: DateTime.now().toISO()
      };
      return clinical.updateClinicalTask(task.patientId, [item]);
    });

    if (
      core.hasPermissions([
        Permission.ClinActivityRead,
        Permission.ClinActivityWrite
      ])
    ) {
      const activitiesPromises = incompleteSelectedClinicalTasks().map(
        async task => {
          const activities = await clinical.getPatientClinicalActivities(
            task.patientId
          );

          if (activities) {
            const associatedActivity = findRelatedClinicalActivity(
              task,
              clinical.activityDescriptionMapValues,
              activities
            );

            if (associatedActivity) {
              const activity = {
                ...associatedActivity.dto,
                completionNotes: notes,
                activityStatus: ClinicalActivityStatus.Completed,
                completedBy: core.userId,
                completedDate: DateTime.now().toISO()
              };

              return clinical.updateClinicalActivity(task.patientId, [
                activity
              ]);
            }
          }
        }
      );

      promises.push(...activitiesPromises);
    }

    await Promise.all(promises);
    setTaskDialogVisible({
      ...taskDialogVisible,
      complete: false
    });
    setSelectedClinicalTasks([]);
  };

  return (
    <>
      <TasksClinicalList setSelected={setSelectedClinicalTasks} />
      <ClinicalTaskDialog
        clinicalTask={selectedClinicalTasks[0]}
        clinicalTasks={patientClinicalTasks}
        hidden={!taskDialogVisible.edit}
        onDismiss={() => {
          setTaskDialogVisible({
            ...taskDialogVisible,
            edit: false
          });
        }}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      <ClinicalTaskDeleteDialog
        hidden={!taskDialogVisible.delete}
        selectedCount={incompleteSelectedClinicalTasks()?.length ?? 0}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => {
          setTaskDialogVisible({
            ...taskDialogVisible,
            delete: false
          });
        }}
      />
      <ClinicalTaskCompleteDialog
        hidden={!taskDialogVisible.complete}
        selectedCount={incompleteSelectedClinicalTasks()?.length ?? 0}
        onConfirm={handleCompleteConfirmed}
        onCancel={() => {
          setTaskDialogVisible({
            ...taskDialogVisible,
            complete: false
          });
        }}
      />
    </>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default TasksClinical;
