import { FC } from "react";
import { useField } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { ConditionDetailsButton } from "../condition-modal/ConditionDetailsButton.tsx";
import { AppointmentDetailsButton } from "./AppointmentDetailsButton.tsx";

interface AppointmentDetailsProps {
  disabled?: boolean;
  isGroupAppointment: boolean;
  onClick: () => void;
}
export const AppointmentDetails: FC<AppointmentDetailsProps> = ({
  disabled,
  isGroupAppointment,
  onClick
}) => {
  const {
    input: { value: patientId }
  } = useField<string | undefined>(appointmentFormNameOf("patientId"), {
    subscription: { value: true }
  });
  return (
    <Stack>
      <Heading hasAsterisk labelPaddings>
        Appointment details
      </Heading>
      <AppointmentDetailsButton disabled={disabled} onClick={onClick} />
      <WhenCountry is={Country.NewZealand}>
        {!isGroupAppointment && (
          <ConditionDetailsButton disabled={!patientId} />
        )}
      </WhenCountry>
    </Stack>
  );
};
