import { Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

import { QuickAddAddressFields } from "./QuickAddAddressFields.tsx";
import { QuickAddContactBasicFormFields } from "./QuickAddContactBasicFormFields.tsx";
import { QuickAddOrgContactFormFields } from "./QuickAddOrgContactFormFields.tsx";

interface QuickAddContactFormFieldsProps {
  contactType: string;
  detailedView?: boolean;
  country: Country;
}

export const QuickAddContactFormFields: React.FunctionComponent<
  QuickAddContactFormFieldsProps
> = ({ contactType, detailedView, country }) => {
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {contactType === ContactType.Organisation ? (
        <QuickAddOrgContactFormFields />
      ) : (
        <>
          <QuickAddContactBasicFormFields detailedView={detailedView} />
          {detailedView && contactType === ContactType.Individual && (
            <QuickAddAddressFields country={country} />
          )}
        </>
      )}
    </Stack>
  );
};
