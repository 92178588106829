import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { dataAttribute, DataAttributes, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BillingScreenContext } from "@modules/billing/screens/shared-components/context/BillingScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { HighlightedText } from "@ui-components/highlighted-text/HighlightedText.tsx";
import { WhenNavigate } from "@ui-components/navigation/WhenNavigate.tsx";

import { IInvoiceRow } from "./AccInvoiceTransactionsList.tsx";

export const TransactionInvoiceCell: FunctionComponent<IInvoiceRow> = observer(
  props => {
    const { routing } = useStores();

    if (!props.isFirstItem) {
      return null;
    }

    const searchString = routing.queryStringParam("search") || "";

    const { setModalInvoiceId } = useContext(BillingScreenContext);

    return (
      <Stack horizontalAlign="end">
        <WhenNavigate
          permission={Permission.AccountHistoryAllowed}
          onMouseDown={event => {
            event.stopPropagation();
            setModalInvoiceId(props.invoice.id);
          }}
          {...dataAttribute(DataAttributes.Data, props.id)}
          {...dataAttribute(DataAttributes.Element, "invoice-link")}
        >
          <HighlightedText
            text={props.invoice.number}
            filter={searchString}
            startOnly
          />
        </WhenNavigate>
      </Stack>
    );
  }
);
