import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext, useMemo } from "react";

import {
  applyOpacityToHexColor,
  FontSizes,
  Link,
  MessageBar,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { sum } from "@libs/utils/utils.ts";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";

export const SelectedInvoicesMessageBar: FunctionComponent = observer(() => {
  const { selection, selectedInvoices, totalInvoices } = useContext(
    AccInvoicesListContext
  );

  const { palette } = useTheme();

  const borderColor = applyOpacityToHexColor(palette.neutralTertiary, 50);

  const totalValue = useMemo(
    () => sum("total", selectedInvoices),
    [selectedInvoices]
  );

  if (selectedInvoices.length === 0) {
    return null;
  }

  return (
    <MessageBar
      styles={({ theme }) => ({
        root: {
          width: "auto",
          height: 32,
          backgroundColor: theme.palette.white,
          border: `1px solid ${borderColor}`
        },
        content: { height: 32 }
      })}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 12 }}
        styles={{ root: { height: "100%" } }}
      >
        <Text>
          <strong>{selectedInvoices.length}</strong> of {totalInvoices}{" "}
          selected. Total value ($):{" "}
          <strong>{currencyFormat(totalValue, { currency: "" })}</strong>
        </Text>
        <Link
          styles={{ root: { fontSize: FontSizes.size14, lineHeight: 14 } }}
          onClick={() => {
            selection.setAllSelected(false);
          }}
        >
          Clear selection
        </Link>
      </Stack>
    </MessageBar>
  );
});
