import {
  ContactType,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";

import { ContactCardIdsEnum } from "./types/contact-card-ids.enum.ts";
import { OrganisationCardIds } from "./types/organisation-card-ids.enum.ts";
import { PatientCardIds } from "./types/patient-card-ids.enum.ts";

export const parseAsDigitsOnly = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const medicareFormat = (medNo: string) => {
  return medNo
    .toString()
    .replace(/(\d{4})/, "$1 ")
    .replace(/(\d{1}$)/g, " $1");
};

export const cscFormat = (cscNo: string) =>
  cscNo
    .toString()
    .replace(/(\d{9})/, "$1-")
    .replace(/(\d{5})/, "$1-")
    .replace(/(\d{3}$)/g, "-$1");

export const relationshipsPriorityOrder = (rel: RelationshipType): number => {
  switch (rel) {
    case RelationshipType.EmergencyContact:
      return 1;
    case RelationshipType.EmergencyContactFor:
      return 2;
    case RelationshipType.NextOfKin:
      return 3;
    case RelationshipType.NextOfKinFor:
      return 4;
    case RelationshipType.PowerOfAttorney:
      return 5;
    case RelationshipType.PowerOfAttorneyFor:
      return 6;
    default:
      return 10;
  }
};

export const noRelationshipsObject = {
  secondaryText: {
    [ContactType.Organisation]: "Employee, Employer",
    [ContactType.Individual]: "Emergency contact, Next of Kin",
    [ContactType.Patient]: "Emergency contact, Next of Kin"
  },
  sectionId: {
    [ContactType.Organisation]: OrganisationCardIds.OrganisationPeople,
    [ContactType.Individual]: ContactCardIdsEnum.contactRelationships,
    [ContactType.Patient]: PatientCardIds.contactRelationships
  },
  actionTitle: {
    [ContactType.Organisation]: "Add a related person",
    [ContactType.Individual]: "Add a relationship",
    [ContactType.Patient]: "Add a relationship"
  },
  noPermissionText: {
    [ContactType.Organisation]: "No related people",
    [ContactType.Individual]: "No relationships",
    [ContactType.Patient]: "No relationships"
  }
};
