import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  FontIcon,
  IContextualMenuListProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { ContextMenuItemsEnum } from "./ContextMenuItemsEnum.ts";

interface ReminderSubMenuProps {
  listProps: IContextualMenuListProps | undefined;
  defaultRender?: (props: IContextualMenuListProps) => JSX.Element | null;

  contactId: string;
}

export const AttendeeReminderSubMenu: FunctionComponent<ReminderSubMenuProps> =
  observer(({ listProps, defaultRender, contactId }) => {
    const theme = useTheme();
    const { setReminderArgs } = useBookingCalendarScreenContext();

    const { calendarEvent } = useContext(BookingCalendarEventContext);

    const getReminderSubMenuItem = (contactId: string) => {
      return {
        id: `booking-event-context-reminder-btn-${contactId}`,
        key: "reminder",
        onClick: () => {
          setReminderArgs({
            calendarEventId: calendarEvent.id,
            contactId
          });
        },

        onRenderContent: () => {
          return (
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <FontIcon
                styles={{
                  root: {
                    color: theme?.palette.themePrimary,
                    margin: "auto 4px"
                  }
                }}
                iconName="CellPhone"
              />
              <Stack.Item>{ContextMenuItemsEnum.SendApptReminder}</Stack.Item>
            </Stack>
          );
        }
      };
    };

    if (defaultRender && listProps) {
      return defaultRender({
        ...listProps,
        items: [getReminderSubMenuItem(contactId)]
      });
    }

    return null;
  });
