import { observer } from "mobx-react-lite";
import { FC } from "react";

import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { RecentPatientsDetailsProps } from "./RecentPatientsList.types.ts";

export const RecentRecordUpdateLink: FC<RecentPatientsDetailsProps> = observer(
  ({ recentPatient }) => {
    const { routing } = useStores();

    return (
      <Navigate
        to={routes.records.recordView.path({
          id: recentPatient.patientId
        })}
        state={routing.getStateWithFromQuery()}
      >
        View record
      </Navigate>
    );
  }
);
