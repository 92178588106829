import { action, observable } from "mobx";

import { DraftItemsListItem } from "@modules/billing/screens/billing-history/components/BillingList.types.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

export class DraftItemsListHelper {
  @observable selectedInvoices: DraftItemsListItem[] = [];
  @observable disableBulkDelete = true;

  @action
  updateFromSelection = () => {
    const selectedItems = this.selection.getSelection() as DraftItemsListItem[];

    const canBeDelete = selectedItems.length !== 0;

    this.disableBulkDelete = !canBeDelete;
    this.selectedInvoices = selectedItems.filter(item => item);
  };

  selection = new Selection({ onSelectionChanged: this.updateFromSelection });

  @observable
  isInvoiceNewModalVisible = false;
  @action
  setIsInvoiceNewModalVisible = (value: boolean) => {
    this.isInvoiceNewModalVisible = value;
  };
}
