import { GroupByDateMeasurementsView } from "./GroupByDateMeasurementsView.tsx";
import { GroupByExaminationMeasurementsView } from "./GroupByExaminationMeasurementsView.tsx";
import { GroupedMeasurement, ObservationGroup } from "./utils.ts";

interface GroupByMeasurementsViewProps {
  pivotKey: string;
  groupedMeasurements: Record<string, GroupedMeasurement[]>;
}

export const MeasurementsGroupedView: React.FC<
  GroupByMeasurementsViewProps
> = ({ groupedMeasurements, pivotKey }) => {
  return (
    <>
      {Object.keys(groupedMeasurements).map(primaryKey => {
        const measurements = groupedMeasurements[primaryKey];

        const MeasurementComponent =
          pivotKey === ObservationGroup.GroupByDateAndTypeKey
            ? GroupByDateMeasurementsView
            : GroupByExaminationMeasurementsView;

        return (
          <MeasurementComponent
            key={primaryKey}
            primaryKey={primaryKey}
            measurements={measurements}
          />
        );
      })}
    </>
  );
};
