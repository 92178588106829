import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { Heading, IPersonaProps, Stack } from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactType,
  OrgUnitDocumentSettingsDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DocumentWriterHeaderFormValidator } from "@modules/clinical/screens/document-writer/components/DocumentWriterHeaderFormValidator.ts";
import { VisibilityAndConfidentialityField } from "@modules/clinical/screens/document-writer/components/VisibilityAndConfidentialityField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { DocumentMergeFieldsFormValues } from "../../document-writer/components/merge-fields/DocumentWriterMergeFormDialog.types.ts";
import { CorrespondenceValues } from "../../patient-record/components/correspondence/CorrespondenceValues.tsx";

type CreateBlankDocumentDialogProps = {
  isDialogHidden: boolean;
  launchFrom: LaunchFrom;
  onDismiss: () => void;
  createDocument: (
    templateType: CorrespondenceType,
    values: DocumentMergeFieldsFormValues,
    toContactId?: string
  ) => Promise<void>;
  patientId?: string;
  documentSettings?: OrgUnitDocumentSettingsDto;
  heading: string;
};

export const CreateBlankDocumentDialog: React.FC<CreateBlankDocumentDialogProps> =
  observer((props: CreateBlankDocumentDialogProps) => {
    const nameOf = nameOfFactory<CorrespondenceValues>();
    const { isDialogHidden, createDocument, onDismiss, patientId, heading } =
      props;

    const { correspondence, core } = useStores();
    const patientLabel = usePatientLabel(true);

    const getTextFromItem = (persona: IPersonaProps) => persona.text as string;

    const validator = new DocumentWriterHeaderFormValidator();

    if (isDialogHidden) return null;

    const renderForm = (): ReactNode => {
      return (
        <Stack
          styles={{
            root: {
              minWidth: 600
            }
          }}
          tokens={{ childrenGap: 8 }}
        >
          {props.launchFrom !== LaunchFrom.Clinical && (
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <ContactPickerField
                name={nameOf("patientId")}
                label={patientLabel}
                required
                getTextFromItem={getTextFromItem}
                iconName="Search"
                filter={{ types: [ContactType.Patient] }}
                placeholder={`Search ${patientLabel.toLowerCase()}`}
              />
            </Stack.Item>
          )}
          <Fieldset horizontal grow>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <ContactPickerField
                name={nameOf("recipientId")}
                label="Recipient"
                iconName="Search"
                placeholder="Search contacts"
                getTextFromItem={getTextFromItem}
              />
            </Stack.Item>

            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <BookableUserPickerField
                name={nameOf("providerId")}
                currentUserOnTop
                label="Provider"
                filter={{
                  statusCodes: [UserStatus.Active]
                }}
                inputProps={{
                  placeholder: "Select provider",
                  name: "provider-filter-box"
                }}
                required
                iconName="Search"
                useBusinessRoleClass
              />
            </Stack.Item>
          </Fieldset>
          <Fieldset horizontal>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <TextInputField
                name={nameOf("documentTitle")}
                label="Subject"
                placeholder="Add subject"
                required
              />
            </Stack.Item>
            <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
              <StaticPickerField
                name={nameOf("documentType")}
                label="Document type"
                fetchDataSource={() =>
                  correspondence.ref.correspondenceTypes.fetchAsKeyNameValues()
                }
                required={true}
              />
            </Stack.Item>
          </Fieldset>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <VisibilityAndConfidentialityField name={nameOf("visibility")} />
          </Stack>
          <Fieldset horizontal>
            <Stack.Item>
              <CheckboxField
                name={nameOf("usePracticeLetterhead")}
                label="Use the practice letterhead"
                disabled={!!props.documentSettings?.usePracticeLetterhead}
              />
            </Stack.Item>
          </Fieldset>
        </Stack>
      );
    };

    return (
      <SubmissionFormDialog<CorrespondenceValues>
        onSubmit={async (formValues: CorrespondenceValues) => {
          const { patientId: patientIdValue } = formValues;
          await createDocument(
            formValues.documentType,
            {
              providerId: formValues.providerId,
              documentTitle: formValues.documentTitle,
              patientId: patientIdValue,
              usePracticeLetterhead: formValues.usePracticeLetterhead,
              visibility: formValues.visibility
            },
            formValues.recipientId
          );
          onDismiss();
        }}
        validate={validator.validate}
        dialogName="Create blank document dialog"
        dialogProps={{
          maxWidth: 650,
          styles: { root: { width: "100%" } },
          onDismiss,
          dialogContentProps: {
            title: <Heading variant="modal-heading">{heading}</Heading>,
            showCloseButton: true
          }
        }}
        initialValues={{
          documentType: CorrespondenceType.Letter,
          providerId: core.userId,
          patientId,
          usePracticeLetterhead:
            props.documentSettings?.usePracticeLetterhead ?? false
        }}
        buttonsProps={{
          hideButtonsSeparator: true,
          disableSubmitOnPristine: true,
          disableSubmitOnFormInvalid: true,
          submitButtonProps: {
            text: "Generate",
            iconProps: {
              iconName: "EditNote"
            }
          }
        }}
      >
        {renderForm}
      </SubmissionFormDialog>
    );
  });
