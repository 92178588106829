import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  CenteredLargeSpinner,
  Heading,
  Overlay,
  SplitButton,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FormDesignValues } from "@shared-types/form-design/form-design-form-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import {
  getFormDesignInitialValues,
  saveFormDesignModel
} from "../FormDesign.utils.ts";
import { FormDesignFields } from "./FormDesignFields.tsx";
import { FormDesignModelValidator } from "./FormDesignModelValidator.ts";

export const FormDesignModal: FunctionComponent = observer(() => {
  const { formDesign } = useStores();

  if (!formDesign.ui.formDesignVisible) return null;

  const config = formDesign.ui.formDesignModalConfig;
  const onClose = () => {
    formDesign.ui.setShowFormDesignModal(undefined);
  };

  const saveFormDesign = (values: FormDesignValues) =>
    saveFormDesignModel(values, formDesign);

  return (
    <DataFetcher
      fetch={() => getFormDesignInitialValues(formDesign, config)}
      fallback={
        <Overlay styles={{ root: { zIndex: 100 } }}>
          <CenteredLargeSpinner />
        </Overlay>
      }
    >
      {(model: FormDesignValues) => {
        const validator = new FormDesignModelValidator(
          formDesign.formDesigns
            .filter(design => design.id !== model?.id)
            .map(design => design.name)
        );
        return (
          <SubmissionFormDialog<FormDesignValues>
            dialogName="Online form design dialog"
            initialValues={model}
            onSubmit={async values => {
              const response = saveFormDesign(values);
              onClose();
              if (values.showPreview) {
                formDesign.ui.setFormDesignPreview(
                  await response,
                  !!values.previewQR
                );
              }
            }}
            buttonsProps={form => {
              const { valid } = form.getState();
              const tooltipProps = {
                content: !valid
                  ? ValidationMessages.formDesignRequiredFields
                  : "",
                tooltipProps: {
                  styles: { root: { marginBottom: 16, textAlign: "center" } }
                }
              };
              return {
                disableSubmitOnFormInvalid: true,
                submitButtonProps: {
                  onClick: async () => {
                    form.change("isPublished", true);
                    form.change("showPreview", false);
                    form.submit();
                  },
                  iconProps: { iconName: "Save" },
                  items: [
                    {
                      key: "saveDraft",
                      text: "Save as a draft",
                      iconProps: { iconName: "BpDraft" },
                      onClick: () => {
                        form.change("isPublished", false);
                        form.change("showPreview", false);
                        form.submit();
                      }
                    }
                  ],
                  tooltipProps
                },
                extraActionsBetween: (form, isPrimaryDisabled) => (
                  <Stack.Item>
                    <TooltipHost {...tooltipProps}>
                      <SplitButton
                        text="Save draft & preview"
                        onClick={async () => {
                          form.change("isPublished", false);
                          form.change("showPreview", true);
                          form.change("previewQR", false);
                          form.submit();
                        }}
                        disabled={isPrimaryDisabled}
                        disableContextMenuButton={isPrimaryDisabled}
                        items={[
                          {
                            key: "saveAndQrCode",
                            text: "Save draft & generate QR code",
                            onClick: () => {
                              form.change("isPublished", false);
                              form.change("showPreview", true);
                              form.change("previewQR", true);
                              form.submit();
                            }
                          }
                        ]}
                      />
                    </TooltipHost>
                  </Stack.Item>
                )
              };
            }}
            validate={values => validator.validate(values)}
            dialogProps={{
              onDismiss: onClose,
              minWidth: 672,
              dialogContentProps: {
                title: (
                  <Heading variant="modal-heading">
                    {model?.id ? "Form design" : "New form design"}
                  </Heading>
                )
              }
            }}
            component={FormDesignFields}
          />
        );
      }}
    </DataFetcher>
  );
});
