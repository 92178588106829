import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IPersonaProps,
  Stack
} from "@bps/fluent-ui";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";

import {
  AllocationFormLabels,
  AllocationFormPlaceholders
} from "./AllocationForm.types.tsx";
import { AllocationFormLocationField } from "./AllocationFormLocationField.tsx";
import { BillToTile } from "./BillToTile.tsx";

interface AllocationFormBillToFieldProps {
  showPicker: boolean;
  setAccountContactId?: (id?: string | null) => void;
}

export const AllocationFormBillToField: React.FC<AllocationFormBillToFieldProps> =
  observer(({ showPicker, setAccountContactId }) => {
    const {
      input: { value: accountContactId }
    } = useField(allocationNameOf("accountContactId"), {
      subscription: { value: true }
    });

    return (
      <Stack tokens={{ childrenGap: 5 }}>
        <AllocationFormLocationField />

        <Heading
          labelPaddings
          styles={{ root: { fontSize: 18, fontWeight: 400 } }}
          {...dataAttribute(DataAttributes.Element, "billto-label")}
        >
          {AllocationFormLabels.billTo}
        </Heading>
        <Stack tokens={{ childrenGap: 8 }}>
          {showPicker && (
            <>
              <ContactPickerField
                name={allocationNameOf("accountContactId")}
                required={true}
                getTextFromItem={(patient: IPersonaProps) => patient.text || ""}
                placeholder={AllocationFormPlaceholders.contact}
                iconName="Search"
                styles={{
                  root: {
                    marginBottom: 13
                  }
                }}
              />
              <FieldSpy
                name={allocationNameOf("accountContactId")}
                onChange={(contactId: string | undefined) => {
                  if (typeof contactId === "undefined") {
                    setAccountContactId && setAccountContactId(null);
                  } else {
                    setAccountContactId && setAccountContactId(contactId);
                  }
                }}
              />
            </>
          )}
          <BillToTile accountContactId={accountContactId} />
        </Stack>
      </Stack>
    );
  });
