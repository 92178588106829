import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { LocationDetailsValidator } from "../location-details/LocationDetailsValidator.ts";

export class NewLocationDetailsFormValidator extends LocationDetailsValidator {
  constructor(core: CoreStore) {
    super(core);

    this.forField("appointmentBookMarker", required());
    this.forField("locationNickname", [required(), maxLength(10)]);
  }
}
