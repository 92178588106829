import { observer } from "mobx-react-lite";

import { Stack } from "@bps/fluent-ui";
import {
  FamilyAliveStatus,
  FamilyMember
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { TerminologyPickerField } from "../../clinical-form/TerminologyPickerField.tsx";

export interface AliveStatusProps {
  familyMember: FamilyMember;
}

export const AliveStatusBase: React.FC<AliveStatusProps> = observer(
  ({ familyMember }) => {
    const { clinical } = useStores();

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        <ButtonsGroupSingleChoiceField
          name={`${familyMember}AliveStatus`}
          options={clinical.ref.familyAliveStatuses.keyTextValues}
          label={`${capitalizeSentence(familyMember)} alive?`}
        />

        <FieldCondition
          when={`${familyMember}AliveStatus`}
          is={FamilyAliveStatus.NO}
        >
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <SpinNumberInputField
              name={`${familyMember}AgeAtDeath`}
              min={0}
              label="Age at death"
              styles={{ root: { width: "86px" } }}
              maxDigitsNumber={3}
            />

            <Stack styles={{ root: { width: "402px" } }}>
              <TerminologyPickerField
                name={`${familyMember}DeceasedReason`}
                label="Cause of death"
                isCauseOfDeath
                isProcedure
                inputProps={{
                  placeholder: "Search for a cause of death"
                }}
              />
            </Stack>
          </Stack>
        </FieldCondition>
      </Stack>
    );
  }
);

export const AliveStatus = withFetch(
  x => [x.clinical.ref.familyAliveStatuses.load()],
  AliveStatusBase
);
