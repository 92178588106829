import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { AllocationViewContext } from "@modules/billing/screens/allocation/context/AllocationViewContext.tsx";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import { BillingHeading } from "@modules/billing/screens/shared-components/BillingHeading.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { AllocationViewButtons } from "./AllocationViewButtons.tsx";

export const AllocationViewHeader: React.FC = observer(() => {
  const { routing } = useStores();
  const { allocation } = useContext(AllocationViewContext);

  const onClose = () => {
    closeInvoiceOrPaymentPage(routing);
  };

  return (
    <BillingHeading
      amount={allocation.total}
      transaction={allocation}
      date={allocation.transactionDate}
      badgeOverride={true}
      buttons={<AllocationViewButtons onClose={onClose} />}
    />
  );
});
