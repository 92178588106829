import { ReactNode } from "react";
import { FormSpy } from "react-final-form";

import { IBreadcrumbItem, SideRailMenuItem } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import { ClaimAdjustmentOverviewSection } from "./claim-adjustment-overview-section/ClaimAdjustmentOverviewSection.tsx";
import {
  ClaimAdjustmentSideRailLabels,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";
import { DocumentationFormSection } from "./DocumentationFormSection.tsx";

interface ClaimAdjustmentFormProps {
  header: ReactNode;
}

export const ClaimAdjustmentFormView: React.FC<ClaimAdjustmentFormProps> = ({
  header
}) => {
  const { routing } = useStores();

  const context = useClaimAdjustmentContext();
  const { claimAdjustment } = context;

  const breadcrumbs: IBreadcrumbItem[] = [
    {
      key: "claimManagement",
      text: "Summary",
      onClick: () => {
        const claimId = routes.claimAdjustment.edit.match(
          routing.location.pathname
        )?.params.claimId;
        routing.push({
          pathname: routes.claims.management.edit.path({ id: claimId! })
        });
      }
    },
    {
      key: "newACC32",
      text: claimAdjustment.formattedNumber,
      isCurrentItem: true
    }
  ];

  const sideRailMenuItems: SideRailMenuItem[] = [
    {
      text: ClaimAdjustmentSideRailLabels.overview,
      id: ClaimsAdjustmentCardIds.overview
    }
  ];

  if (context.isDocumentError) {
    sideRailMenuItems.push({
      text: ClaimAdjustmentSideRailLabels.documentation,
      id: ClaimsAdjustmentCardIds.documentation
    });
  }

  return (
    <CardFormLayout sideRailMenuItems={sideRailMenuItems} header={header}>
      <FormSpy<ClaimAdjustmentFormValues>
        subscription={{ dirty: true, values: true }}
        onChange={({ values }) => {
          context.claimAdjustmentFormValues = values;
        }}
      />
      <BreadCrumb routes={breadcrumbs} />
      <ClaimAdjustmentOverviewSection />
      {context.isDocumentError && <DocumentationFormSection />}
    </CardFormLayout>
  );
};
