import { observer } from "mobx-react-lite";
import { useForm, useFormState } from "react-final-form";

import { FontSizes, FontWeights, Stack, Text } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextEditorField } from "@ui-components/form/TextEditorField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { OnlineBookingBadge } from "../OnlineBookingBadge.tsx";
import { OnlineSettingsValues } from "./OnlineSettingsValues.types.ts";

const nameOf = nameOfFactory<OnlineSettingsValues>();

type OnlineSettingsFormFieldsProps = {
  defaultOnlineName: string;
};

export const OnlineSettingsFormFields: React.FC<OnlineSettingsFormFieldsProps> =
  observer(({ defaultOnlineName }) => {
    const {
      values: { isAvailableNewPatients, isAvailableExistingPatients }
    } = useFormState<OnlineSettingsValues>();

    const form = useForm<OnlineSettingsValues>();
    return (
      <Stack styles={{ root: { marginTop: 8 } }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { marginBottom: 8 } }}
        >
          <Stack horizontal verticalAlign="center">
            <Text
              styles={{
                root: {
                  fontSize: FontSizes.size14,
                  fontWeight: FontWeights.semibold,
                  marginRight: 4
                }
              }}
            >
              Availability:
            </Text>
            <CheckboxField
              label="Existing patients"
              name={nameOf("isAvailableExistingPatients")}
              styles={{ root: { margin: "0 8px" } }}
            />
            <CheckboxField
              label="New patients"
              name={nameOf("isAvailableNewPatients")}
            />
          </Stack>
          <OnlineBookingBadge
            isAvailableExistingPatients={isAvailableExistingPatients}
            isAvailableNewPatients={isAvailableNewPatients}
          />
        </Stack>

        <SpinNumberInputField
          fieldItemStyles={{
            suffix: { alignSelf: "center" },
            root: { minWidth: 0 },
            item: { width: 120 },
            wrapper: { justifyContent: "space-between" },
            subComponentStyles: {
              error: {
                errorMessage: { justifyContent: "flex-end", marginRight: 22 }
              }
            }
          }}
          name={nameOf("newPatientMinuteDuration")}
          min={1}
          max={1439}
          step={5}
          label="Duration for new patients (default if not specified)"
          disabled={!isAvailableNewPatients}
          suffix="min"
          horizontalLabel={true}
          parse={value => (value === "" ? undefined : Number(value))}
        />
        <Fieldset verticalFill>
          <TextInputField
            label="Online name"
            placeholder={`'${defaultOnlineName}' if unspecified`}
            name={nameOf("name")}
          />
          <TextInputField
            label="Description"
            name={nameOf("description")}
            multiline
            resizable={false}
          />
          <Stack horizontal horizontalAlign="space-between">
            <Text
              styles={{
                root: {
                  fontSize: FontSizes.size14,
                  fontWeight: FontWeights.semibold
                }
              }}
            >
              Additional information for confirmation mail (optional)
            </Text>
          </Stack>
          <TextEditorField name={nameOf("additionalInformation")} />
          <FieldSpy
            name={nameOf("isAvailableNewPatients")}
            onChange={isAvailableNewPatients =>
              !isAvailableNewPatients &&
              form.change(
                nameOf("newPatientMinuteDuration"),
                form.getFieldState("newPatientMinuteDuration")?.initial
              )
            }
          />
        </Fieldset>
      </Stack>
    );
  });
