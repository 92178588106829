import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { AllocationViewContext } from "@modules/billing/screens/allocation/context/AllocationViewContext.tsx";
import { AllocationViewHelper } from "@modules/billing/screens/allocation/context/AllocationViewHelper.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface AllocationViewDataFetcherProps extends PropsWithChildren {
  allocationId: string;
}

export const AllocationViewDataFetcher: React.FC<AllocationViewDataFetcherProps> =
  observer(({ children, allocationId }) => {
    const loadHelper = async (
      root: RootStore
    ): Promise<AllocationViewHelper> => {
      const allocation = await root.billing.getAllocation(allocationId);

      const [payment, contact] = await Promise.all([
        allocation.oldPaymentId
          ? await root.billing.getPayment(allocation.oldPaymentId)
          : undefined,
        allocation.accountId
          ? root.practice.getContact(allocation.accountId, {
              includeContactPreferences: true
            })
          : undefined
      ]);

      const helper = new AllocationViewHelper(root, {
        allocation,
        payment,
        contact
      });

      return helper;
    };

    return (
      <DataFetcher<AllocationViewHelper> fetch={loadHelper}>
        {helper => {
          return (
            <AllocationViewContext.Provider value={helper}>
              {children}
            </AllocationViewContext.Provider>
          );
        }}
      </DataFetcher>
    );
  });
