import { observer } from "mobx-react-lite";

import { Link, Text } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { usePatientMatchContext } from "../context/PatientMatchContext.ts";

export const CreateNewPatientLink: React.FC = observer(() => {
  const { onCreateNewPatientClick } = usePatientMatchContext();

  const patientLabel = usePatientLabel(false);

  const { core } = useStores();

  return (
    <Text styles={{ root: { paddingTop: 8 } }}>
      If there are no matching records{" "}
      <Link
        onClick={() => onCreateNewPatientClick()}
        disabled={!core.hasPermissions(Permission.ContactWrite)}
      >{`create a new ${patientLabel}`}</Link>
    </Text>
  );
});
