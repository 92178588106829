import { Text } from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";

export const TransactionValueCell: React.FC<{ total: number }> = ({
  total
}) => {
  return (
    <Text
      styles={(_props, theme) => ({
        root: {
          color: theme.palette.neutralSecondary,
          textAlign: "right",
          display: "block",
          width: "100%"
        }
      })}
    >
      {currencyFormat(total, { currency: "" })}
    </Text>
  );
};
