import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useFormState } from "react-final-form";

import { FontWeights, Heading, Stack } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { CommsScheduleContext } from "../context/CommsScheduleContext.ts";
import { CommsCard } from "./CommsCard.tsx";
import { CommsScheduleFormValues } from "./CommsScheduleForm.types.ts";
import { CommsScheduleCardIds } from "./CommsScheduleFormEnums.ts";
import { DaysOfWeekField } from "./DaysOfWeekField.tsx";

const nameOf = nameOfFactory<CommsScheduleFormValues>();

export const NotifyFormSection: React.FC = observer(() => {
  const { selectedSectionId } = useContext(CommsScheduleContext);

  const { values } = useFormState<CommsScheduleFormValues>({
    subscription: { values: true, initialValues: true }
  });

  return (
    <CommsCard
      id={CommsScheduleCardIds.whenToNotify}
      openOnRender={
        !selectedSectionId ||
        selectedSectionId === CommsScheduleCardIds.whenToNotify
      }
      heading="When to notify"
    >
      <Stack tokens={{ childrenGap: 14 }}>
        <SpinNumberInputField
          horizontalLabel={true}
          name={nameOf("sendBefore")}
          label="Send reminder"
          suffix="day(s) before appointment"
          min={0}
          step={1}
          parse={value => Number(value)}
          fieldItemStyles={{
            wrapper: { display: "flex", alignItems: "center" },
            item: { maxWidth: 86 },
            suffix: {
              marginLeft: 8
            },
            subComponentStyles: {
              label: {
                root: {
                  fontWeight: FontWeights.regular
                }
              }
            }
          }}
        />
        <Stack>
          <Heading>Reminder time</Heading>
          <TimePickerField
            label="Appointment reminders will commence being sent at this time"
            suggestionInterval={30}
            name={nameOf("sendTime")}
            styles={{
              subComponentStyles: {
                textField: {
                  field: {
                    width: "94px"
                  },
                  root: { width: "100%" },
                  wrapper: {
                    display: "flex",
                    alignItems: "center"
                  },
                  subComponentStyles: {
                    label: { root: { marginRight: 8, fontWeight: "normal" } }
                  }
                }
              }
            }}
          />
        </Stack>
        What days should reminders be sent?
        <ToggleField
          name={nameOf("everyday")}
          onText="Everyday"
          offText="Advanced"
        />
        {!!!values.everyday && <DaysOfWeekField />}
      </Stack>
    </CommsCard>
  );
});
