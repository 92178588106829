import { observer } from "mobx-react-lite";
import { FC, useContext, useRef } from "react";

import {
  AnonymousCredential,
  BlockBlobClient,
  ContainerClient,
  newPipeline
} from "@azure/storage-blob";
import {
  IPersonaProps,
  PersonaSize,
  ProfilePhotoPicker,
  ProfilePhotoPickerProps
} from "@bps/fluent-ui";
import { GetSasUriResponseDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { PersonTypeIcon } from "@ui-components/persona/PersonTypeIcon.tsx";

import { PatientDemographicContext } from "../context/PatientDemographicContext.ts";
import { PersonaPhotoStatus } from "../context/PatientDemographicHelper.ts";

export interface ProfilePictureProps
  extends Omit<IPersonaProps, "styles" | "onChange"> {
  contact: Contact | undefined;
  imageInitials: string | undefined;
  profilePictureUrl: string | undefined;
  onRenderNoImageInitials?: IPersonaProps["onRenderInitials"];
  styles?: ProfilePhotoPickerProps["styles"];
  onChange: (url: string | undefined) => void;
}

export enum PersonaProfileLabel {
  UploadPhotoFailed = "Upload profile picture failed",
  Retry = "Retry"
}

export const ProfilePicture: FC<ProfilePictureProps> = observer(
  ({
    contact,
    imageInitials,
    profilePictureUrl,
    onRenderNoImageInitials,
    styles,
    onChange,
    ...coinProps
  }) => {
    const { practice, notification } = useStores();

    const patientDemographicContext = useContext(PatientDemographicContext);

    const {
      setPersonaPhotoStatus,
      personaPhotoStatus,
      setRetryUploadPersonaPhoto,
      setProfilePictureStagingId
    } = patientDemographicContext;

    const onPictureRemoved = () => {
      if (personaPhotoStatus !== PersonaPhotoStatus.stopped) {
        setPersonaPhotoStatus(PersonaPhotoStatus.stopped);
        return;
      }
      onChange(undefined);
      setProfilePictureStagingId(undefined);
    };

    const stagingPath = useRef<GetSasUriResponseDto>();

    const getContainerClient = async (): Promise<ContainerClient> => {
      stagingPath.current = await practice.getSaSUri();
      return new ContainerClient(
        stagingPath.current.sasUri,
        newPipeline(new AnonymousCredential())
      );
    };

    const getBlockBlobClient = (
      containerClient: ContainerClient
    ): BlockBlobClient =>
      containerClient.getBlockBlobClient(stagingPath.current?.fileStagingId!);

    const handleDropAccepted = async (files: File[]): Promise<void> => {
      // one file only
      if (files?.length > 0) {
        const file = files[0];
        try {
          setPersonaPhotoStatus(PersonaPhotoStatus.uploading, file.name);

          const containerClient = await getContainerClient();
          // prepare upload
          const aborter = new AbortController();
          const blockBlobClient = getBlockBlobClient(containerClient);

          // upload to browser
          await blockBlobClient.uploadBrowserData(file, {
            abortSignal: aborter.signal,
            blobHTTPHeaders: {
              blobContentType: file.type
            }
          });
          if (
            patientDemographicContext.personaPhotoStatus ===
            PersonaPhotoStatus.uploading
          ) {
            // set as form data
            setProfilePictureStagingId(stagingPath.current?.fileStagingId!);

            const url = URL.createObjectURL(file);
            onChange(url);
          }
          setPersonaPhotoStatus(PersonaPhotoStatus.stopped);
        } catch (reason) {
          notification.error(PersonaProfileLabel.UploadPhotoFailed);
          if (
            patientDemographicContext.personaPhotoStatus !==
            PersonaPhotoStatus.stopped
          ) {
            setPersonaPhotoStatus(PersonaPhotoStatus.error, file.name);
          }
        }
      }
    };

    return (
      <ProfilePhotoPicker
        id={contact?.id ?? ""}
        imageUrl={profilePictureUrl}
        onDropAccepted={handleDropAccepted}
        loading={personaPhotoStatus === PersonaPhotoStatus.uploading}
        hasError={personaPhotoStatus === PersonaPhotoStatus.error}
        imageInitials={imageInitials}
        onPictureRemoved={onPictureRemoved}
        onOpened={setRetryUploadPersonaPhoto}
        onRenderInitials={onRenderNoImageInitials}
        styles={styles}
        onRenderCoin={(props, defaultRendering) => {
          return (
            <>
              {defaultRendering!(props)}
              {contact?.type && (
                <PersonTypeIcon
                  contactType={contact?.type}
                  size={PersonaSize.size100}
                />
              )}
            </>
          );
        }}
        {...coinProps}
      />
    );
  }
);
