import { observer } from "mobx-react-lite";
import { FC } from "react";

import { DefaultButton, Heading, Stack, Text, useTheme } from "@bps/fluent-ui";
import { login } from "@bps/http-client";
import { appContext } from "@libs/config/app-context.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const UserAccessForm: FC = observer(() => {
  const theme = useTheme();
  const { core } = useStores();

  return (
    <Stack
      tokens={{ childrenGap: 21 }}
      styles={{
        root: {
          marginLeft: 64,
          padding: "24px 24px 24px 0",
          borderTop: "1px",
          borderTopColor: theme.palette.neutralLight,
          borderRadius: "4px"
        }
      }}
    >
      <Stack.Item>
        <DefaultButton
          text="Change password"
          onClick={() => {
            login({
              authority:
                appContext.config.b2cAuthentication.resetPasswordPolicy,
              extraQueryParameters: {
                email: core.user?.email ?? "",
                tid: core.tenantDetails?.id ?? ""
              }
            });
          }}
        />
      </Stack.Item>

      <Stack tokens={{ childrenGap: 16 }}>
        <Stack.Item>
          <Heading variant="section-sub-heading">
            Multi-factor authentication (MFA)
          </Heading>
          <Text block>
            MFA has been enabled for your account. This provides an extra layer
            of security when signing into Omni.
          </Text>
        </Stack.Item>
        <Stack.Item>
          <DefaultButton
            text="Edit MFA methods"
            onClick={() => {
              login({
                authority: appContext.config.b2cAuthentication.resetMfaPolicy,
                extraQueryParameters: {
                  email: core.user?.email ?? "",
                  tid: core.tenantDetails?.id ?? ""
                }
              });
            }}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
});
