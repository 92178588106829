import React from "react";

import { Heading, MessageBar, Stack, useTheme } from "@bps/fluent-ui";
import { MeasurementDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { splitNameFromSummary } from "./utils.ts";

export interface DASS21ScoreProps {
  measurements?: MeasurementDto[];
}

export const DASS21Score: React.FC<DASS21ScoreProps> = ({ measurements }) => {
  const theme = useTheme();

  if (!measurements) {
    return null;
  }

  const mappedMeasurementAndHeading = measurements.map(x => {
    return {
      splitContent: splitNameFromSummary(x.summary),
      measurement: x
    };
  });

  const depression = mappedMeasurementAndHeading.find(
    x => x.splitContent?.heading === "Depression"
  );

  const anxiety = mappedMeasurementAndHeading.find(
    x => x.splitContent?.heading === "Anxiety"
  );

  const stress = mappedMeasurementAndHeading.find(
    x => x.splitContent?.heading === "Stress"
  );

  const sortedMeasurements = [depression, anxiety, stress];

  return (
    <>
      {sortedMeasurements?.map(x => {
        return (
          <Stack
            key={x?.measurement.id}
            styles={{
              root: {
                border: "solid",
                borderWidth: 1,
                padding: 16,
                color: theme.palette.neutralLight
              }
            }}
            tokens={{ childrenGap: 8 }}
          >
            <Heading>{x?.splitContent?.heading}</Heading>
            <MessageBar>
              {x?.splitContent?.measurement} - {x?.measurement.value}
            </MessageBar>
          </Stack>
        );
      })}
    </>
  );
};
