import { FC } from "react";
import { useForm } from "react-final-form";

import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { OrganisationLabels } from "../organisation.types.ts";

type BillingAdminEmailFieldsProps = {
  name: string;
};

export const BillingAdminEmailFields: FC<BillingAdminEmailFieldsProps> = ({
  name
}) => {
  const form = useForm();
  return (
    <>
      <Fieldset legend={OrganisationLabels.ClaimsAdministratorEmail}>
        <TextInputField
          name={`${name}.claimsAdminEmail`}
          placeholder={OrganisationLabels.Email}
          parse={(value: string) => value ?? ""}
        />
      </Fieldset>

      <YesNoToggleField
        label={OrganisationLabels.DifferentInvoicingEmail}
        name={`${name}.differentInvoicingEmail`}
        styles={{ root: { marginBottom: 0 } }}
      />
      <FieldSpy
        name={`${name}.differentInvoicingEmail`}
        onChange={(value: boolean) =>
          !value && form.change(`${name}.invoicingEmail`, undefined)
        }
      />
      <FieldCondition when={`${name}.differentInvoicingEmail`} is={true}>
        <Fieldset legend={OrganisationLabels.InvoicingEmail}>
          <TextInputField
            name={`${name}.invoicingEmail`}
            placeholder={OrganisationLabels.Email}
            parse={(value: string) => value ?? ""}
          />
        </Fieldset>
      </FieldCondition>
    </>
  );
};
