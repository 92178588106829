import {
  ContextualMenuItemType,
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  TooltipHost
} from "@bps/fluent-ui";
import {
  CorrespondencesTestElements,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { isPreviewAndPrintSupported } from "@libs/utils/file.utils.ts";
import { useCorrespondencesHelper } from "@modules/clinical/screens/patient-record/components/correspondence/context/CorrespondencesContext.tsx";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { CorrespondencesLabels } from "../correspondence/Correspondences.types.ts";
import { useInvestigationHelper } from "../investigations/side-panel/InvestigationsSidePanelContext.ts";

interface ClinicalImagesListItemContextMenuProps {
  record: ClinicalDocument;
}

export const ClinicalImagesListItemContextMenu: React.FC<
  ClinicalImagesListItemContextMenuProps
> = ({ record }) => {
  const { core, inbox } = useStores();

  const {
    setEditDialogVisible,
    setIsDeleteDialogVisible,
    setIsPrinting,
    setSelectedClinicalDocument,
    previewCorrespondence,
    updateCorrespondenceConfidentiality,
    onMoveToAnotherPatient,
    onOutcomeClick,
    onMoveTo: onMoveToInvestigations
  } = useCorrespondencesHelper();

  const { onMoveTo: onMoveToCorrespondence } = useInvestigationHelper();

  const isConfidentOfOtherUser = !core.hasAccessToSecGroup(record.secGroupId);

  const sectionOneItems: IContextualMenuItem[] = [];
  const sectionTwoItems: IContextualMenuItem[] = [];
  const sectionThreeItems: IContextualMenuItem[] = [];
  const sectionFourItems: IContextualMenuItem[] = [];
  const items: IContextualMenuItem[] = [
    {
      key: "section1",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        bottomDivider: true,
        items: sectionOneItems
      }
    },
    {
      key: "section2",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        bottomDivider: true,
        items: sectionTwoItems
      }
    },
    {
      key: "section3",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        bottomDivider: true,
        items: sectionThreeItems
      }
    },
    {
      key: "section4",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        items: sectionFourItems
      }
    }
  ];

  const onEditCorrespondenceClick = (item: ClinicalDocument) => {
    setSelectedClinicalDocument(item);
    setEditDialogVisible(true);
  };

  sectionTwoItems.push({
    key: "edit",
    name: CorrespondencesLabels.ContextMenuEdit,
    onClick: () => {
      onEditCorrespondenceClick(record);
    }
  });

  if (record.extension && isPreviewAndPrintSupported(record.extension)) {
    const onPrintClick = async (item: ClinicalDocument) => {
      setSelectedClinicalDocument(item);
      setIsPrinting(true);
    };

    sectionTwoItems.push({
      key: "print",
      name: CorrespondencesLabels.ContextMenuPrint,
      onClick: () => {
        onPrintClick(record);
      }
    });
  }
  sectionTwoItems.push({
    key: "moveTo",
    name: CorrespondencesLabels.ContextMenuMoveTo,
    subMenuProps: {
      items: [
        {
          key: CorrespondencesLabels.ContextMenuAnotherPatient,
          name: CorrespondencesLabels.ContextMenuAnotherPatient,
          onClick: () => {
            onMoveToAnotherPatient(record);
          }
        },
        {
          key: CorrespondencesLabels.ContextMenuCorrespondence,
          name: CorrespondencesLabels.ContextMenuCorrespondence,
          onClick: () => {
            onMoveToCorrespondence(record, StoreType.Correspondence);
          }
        },
        {
          key: CorrespondencesLabels.ContextMenuInvestigations,
          name: CorrespondencesLabels.ContextMenuInvestigations,
          onClick: () => {
            onMoveToInvestigations(record, StoreType.Investigations);
          }
        }
      ]
    }
  });

  sectionTwoItems.push({
    key: "outcome",
    name: CorrespondencesLabels.ContextMenuOutcome,
    subMenuProps: {
      items: inbox.ref.outcomes.keyNameValues.map(outcome => {
        const item: IContextualMenuItem = {
          ...outcome
        };
        return item;
      }),
      onItemClick: (_, item) => {
        if (item) onOutcomeClick(record, item.key);
      }
    }
  });

  sectionOneItems.push({
    key: "preview",
    name: CorrespondencesLabels.ContextMenuOpenDocument,
    onClick: () => {
      previewCorrespondence(record);
    }
  });

  sectionFourItems.push({
    key: "delete",
    name: CorrespondencesLabels.ContextMenuDelete,
    onClick: () => {
      setIsDeleteDialogVisible(true);
      setSelectedClinicalDocument(record);
    }
  });

  if (core.hasPermissions(Permission.ConfidentialDataAllowed)) {
    sectionThreeItems.push({
      key: "Confidential",
      name: !!record.secGroupId
        ? "Remove confidentiality"
        : "Make confidential",
      onClick: () => {
        updateCorrespondenceConfidentiality(record);
      }
    });
  }

  return (
    <TooltipHost content={isConfidentOfOtherUser ? "Confidential" : ""}>
      <IconButton
        {...dataAttribute(
          DataAttributes.Element,
          CorrespondencesTestElements.MoreButton
        )}
        menuIconProps={{
          iconName: isConfidentOfOtherUser ? "ShieldAlert" : "More"
        }}
        menuProps={{
          items
        }}
        styles={{
          root: { height: "36px" },
          rootDisabled: {
            backgroundColor: "transparent"
          },
          flexContainer: { height: "36px" }
        }}
        disabled={isConfidentOfOtherUser}
      />
    </TooltipHost>
  );
};
