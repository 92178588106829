import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";

interface SelectedInvoicesButtonProps {
  onClick: (invoices: Invoice[]) => Promise<void>;
  disabled: boolean;
  text: string;
}

export const SelectedInvoicesButton: FunctionComponent<SelectedInvoicesButtonProps> =
  observer(({ onClick, text, disabled }) => {
    const { selection, selectedInvoices } = useContext(AccInvoicesListContext);

    const _onClick = async () => {
      await onClick(selectedInvoices);
      selection.setAllSelected(false);
    };

    return <DefaultButton onClick={_onClick} text={text} disabled={disabled} />;
  });
