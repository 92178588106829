export enum SettingsLabels {
  appointmentType = "Appointment type",
  appointmentTypes = "Appointment types",
  accSetup = "ACC setup",
  exceptions = "Exceptions",
  activeLocationExceptions = "Active location exceptions",
  expiredLocationExceptions = "Expired exceptions populate here",
  expiredLocationExceptionsSubText = "This section will begin to populate as records expire",
  noCurrentExceptions = "No current opening hours",
  useButtonBelow = "Use the button below to begin adding records to this area",
  previousLocationExceptions = "Previous location exceptions",
  fees = "Fees",
  financialInformation = "Financial information",
  healthCertificate = "Health secure certificate",
  locationDetails = "Location details",
  newFee = "New fee",
  openingHours = "Opening hours",
  practiceDetails = "Practice details",
  practiceLocation = "Practice & locations",
  schedules = "Fees & schedules",
  standardOpeningHours = "Standard opening hours",
  users = "Users",
  usersList = "Users list",
  appointments = "Appointments",
  appointmentsSettings = "Appointment settings",
  cancellationReasons = "Cancellation reasons",
  communications = "Communications",
  communicationPreference = "Practice communications preferences",
  communicationsSchedules = "Comms schedules",
  communicationsTemplates = "Comms templates",
  onlineSettings = "Online settings",
  onlineBooking = "Online booking", // to replace above and providers
  onlineDisplay = "Online display",
  providers = "Providers",
  documents = "Documents",
  integrations = "Integrations",
  security = "Security",
  preferenceSetting = "Preferences",
  locations = "Locations",
  newLocation = "New location",
  editLocation = "Edit location"
}

export enum UsersLabels {
  accessSignIn = "Access and sign in",
  users = "Users",
  personalInformation = "Personal information",
  professionalCredentials = "Professional credentials",
  security = "Security",
  apptBook = "Appointment book",
  clinicalView = "Clinical settings",
  sessions = "Sessions",
  workingHours = "Working hours",
  exceptions = "Exceptions",
  reserves = "Reserves",
  inactive = "Inactive",
  deactivate = "Deactivate",
  activate = "Activate",
  actions = "Actions",
  userRolesAndSecurityPermissions = "User role & security permissions",
  accContracts = "ACC contracts",
  onlineBookingProfile = "Online booking profile"
}
export enum UserInfoFormLabels {
  displaySettings = "Display settings",
  customersCalled = "My customers are called",
  title = "Title",
  firstName = "First (Given / Legal) name",
  middleName = "Middle name",
  lastName = "Last (Second / Family / Sur-) name",
  preferredName = "Preferred name",
  phone = "Phone",
  email = "Email (username)"
}

export enum TenantFormLabels {
  displaySettings = "Display settings",
  customersCalled = "Our customers are called"
}

export enum SettingsScreenBreadcrumbKeys {
  practice = 1,
  openingHours = 2,
  exceptions = 3,
  locations = 4
}
