import { MedicationDurationUnit } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  greaterThan,
  integer,
  isNotFutureDate,
  lessThan,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { PrescribingDoseFormValues } from "./PrescribingWizard.types.tsx";

const isShortTerm = (val: any, values: PrescribingDoseFormValues) =>
  !values.longTerm;

const isUntilFinished = (val: any, values: PrescribingDoseFormValues) =>
  values.durationUnit === MedicationDurationUnit.UntilFinished;

const isDurationRequired = (val: any, values: PrescribingDoseFormValues) =>
  isShortTerm(val, values) && !isUntilFinished(val, values);

export const INTEGER_VALIDATION_MESSAGE = "Only whole numbers are valid.";
interface PrescribingDoseFormValidatorProps {
  hasPrescribePermission: boolean;
}

export class PrescribingDoseFormValidator extends Validator<PrescribingDoseFormValues> {
  constructor(options: PrescribingDoseFormValidatorProps) {
    super();
    this.forField("duration", [
      predicate(
        isDurationRequired,
        required(),
        greaterThan(0),
        lessThan(100),
        integer(INTEGER_VALIDATION_MESSAGE)
      )
    ]);
    this.forField("durationUnit", predicate(isShortTerm, required()));
    this.forField("dose", [greaterThan(0), lessThan(10000)]);
    this.forField("quantity", [
      required(),
      greaterThan(0),
      lessThan(1000),
      integer(INTEGER_VALIDATION_MESSAGE)
    ]);
    this.forField("rpts", [lessThan(100), integer(INTEGER_VALIDATION_MESSAGE)]);
    this.forField("medicationStartedBy", [
      predicate(
        (value, values) =>
          !!values?.medicationStarted || !options.hasPrescribePermission,
        required()
      )
    ]);
    this.forField("medicationStarted", [isNotFutureDate()]);
  }
}
