import { ValidationErrors } from "final-form";

import { isDeepEmptyUtils } from "@libs/utils/isDeepEmpty.utils.ts";
import { ChangeTypes } from "@modules/billing/screens/shared-components/change-type/change-types.enum.tsx";

import { InvoiceListItem } from "../invoice/components/Invoice.types.ts";

export const getEditedInvoiceItem = (
  item: InvoiceListItem,
  items: InvoiceListItem[]
): InvoiceListItem | undefined => {
  const editedItem = items.find(otherItem => item.code === otherItem.code);
  if (!editedItem) {
    return undefined;
  }

  if (
    editedItem.fee !== item.fee ||
    editedItem.purchaseOrderNumber !== item.purchaseOrderNumber ||
    editedItem.serviceDate.getTime() !== item.serviceDate.getTime() ||
    editedItem.quantity !== item.quantity
  ) {
    return { ...item, changeType: ChangeTypes.edited };
  }

  return item;
};

export const getOriginalInvoiceItemsWithChangeType = (
  original: InvoiceListItem[],
  adjusted: InvoiceListItem[]
) => {
  return original.map(item => {
    return (
      getEditedInvoiceItem(item, adjusted) || {
        ...item,
        changeType: ChangeTypes.removed
      }
    );
  });
};

export const getAdjustedInvoiceItemsWithChangeType = (
  original: InvoiceListItem[],
  adjusted: InvoiceListItem[]
) => {
  return adjusted.map(item => {
    return (
      getEditedInvoiceItem(item, original) || {
        ...item,
        changeType: ChangeTypes.added
      }
    );
  });
};

export const getInvalidItemsIndexes = (options: {
  errors: undefined | string | ValidationErrors[];
  submitFailed: boolean | undefined;
}): number[] | undefined => {
  // dont validate till have no array of errors, or submit is not failed
  if (!Array.isArray(options.errors) || !options.submitFailed) {
    return undefined;
  }

  const indexes: number[] = [];
  options.errors.forEach((error, index) => {
    if (!isDeepEmptyUtils(error)) {
      indexes.push(index);
    }
  });

  return indexes;
};
