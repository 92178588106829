import { Spinner } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolForm } from "../clinical-tool-form/ClinicalToolForm.tsx";
import { OREBROQuestions } from "./OREBROQuestions.tsx";

interface OREBROQuestionnaireFormProps {
  clinicalRecord: ClinicalRecord;
}

export const OREBROQuestionnaireForm: React.FunctionComponent<
  OREBROQuestionnaireFormProps
> = ({ clinicalRecord }) => {
  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.OREBROV1)}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <ClinicalToolForm
          clinicalRecord={clinicalRecord}
          clinicalDataType={ClinicalDataType.OREBRO}
          formHeading="Orebro Musculoskeletal Pain Screening Questionnaire"
          questionnaire={questionnaire}
          formName="orebro"
        >
          <OREBROQuestions questionnaire={questionnaire} />
        </ClinicalToolForm>
      )}
    </DataFetcher>
  );
};
