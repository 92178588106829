import {
  IStackStyles,
  LabeledText,
  mergeStyles,
  PersonaSize,
  Spinner,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { AccountCard } from "@modules/billing/screens/account/components/AccountCard.tsx";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ItalicCenterText } from "@ui-components/ItalicCenterText.tsx";

interface BillToTileProps {
  accountContactId?: string;
  stackStyles?: IStackStyles;
}

export const BillToTile: React.FC<BillToTileProps> = ({
  accountContactId,
  stackStyles
}) => {
  const theme = useTheme();
  const labelStyle = {
    text: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      minWidth: 80
    }
  };

  const getAddressJSX = (contact: Contact) => {
    let text = "Not recorded";

    if (contact.physicalAddress) {
      text = addressText(contact.physicalAddress);
    } else if (contact.postalAddress) {
      text = addressText(contact.postalAddress);
    }

    return (
      <Stack>
        <LabeledText
          label={InvoiceFormLabels.address}
          noSemicolon
          styles={labelStyle}
        >
          {text}
        </LabeledText>
      </Stack>
    );
  };

  return (
    <Stack tokens={{ childrenGap: 8 }} styles={stackStyles}>
      <div
        className={mergeStyles({
          border: `1px solid ${theme.palette.neutralLight}`,
          padding: 10,
          borderRadius: 2
        })}
      >
        {!accountContactId ? (
          <ItalicCenterText>No contact selected</ItalicCenterText>
        ) : (
          <AccountCard
            size={PersonaSize.size48}
            contactId={accountContactId}
            styles={{
              root: { height: "auto" },
              primaryText: { whiteSpace: "normal", wordBreak: "break-word" }
            }}
          />
        )}
      </div>
      {accountContactId && (
        <DataFetcher
          fetch={({ practice }) => practice.getContact(accountContactId)}
          fallback={<Spinner />}
        >
          {(contact: Contact) => getAddressJSX(contact)}
        </DataFetcher>
      )}
    </Stack>
  );
};
