import { useForm } from "react-final-form";

import { to2dp } from "@bps/utils";
import { sum } from "@libs/utils/utils.ts";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";

import { AllocationFieldValues, ItemSelectionMode } from "../types.ts";
import { useAllocatableAmount } from "./useAllocatableAmount.ts";
import { useAllocationOption } from "./useAllocationOption.ts";
import { useAllocationsTotal } from "./useAllocationsTotal.tsx";
import { useAllocationsValues } from "./useAllocationsValues.ts";

/**
 * A hook to calculate and update the allocation field
 **/
export const useAllocationField = () => {
  const allocationOption = useAllocationOption();
  const allocatableAmount = useAllocatableAmount();
  const { owingRemaining, allocatedTotal } = useAllocationsTotal();
  const allocations = useAllocationsValues();
  const itemMaxTotalKey = allocationOption?.itemMaxTotalKey || "owing";

  const form = useForm();

  /**
   *  unchecks and removes all allocated total form all items
   *
   **/
  const deselectAllItems = () => {
    if (allocationOption?.itemSelectionMode !== ItemSelectionMode.none) {
      form.change(
        allocationNameOf("allocations"),
        allocations.map(a => ({
          ...a,
          checked: false,
          total: 0
        }))
      );
    }
  };

  /**Function auto allocates items up to amount selected
   *  @param allocations  - allocations
   *  @param allocatableAmount - amount to allocate
   * **/
  const autoAllocateItems = (
    allocatableAmount: number,
    allocations: AllocationFieldValues[]
  ) => {
    let allocatableAmountRemaining = allocatableAmount;

    const autoAllocatedAllocations = allocations.map(a => {
      const total = Math.min(
        a[itemMaxTotalKey] || 0,
        allocatableAmountRemaining
      );

      const checked = !!total;
      allocatableAmountRemaining = to2dp(allocatableAmountRemaining - total);
      return {
        ...a,
        checked,
        total
      };
    });

    form.change(allocationNameOf("allocations"), autoAllocatedAllocations);
  };

  /** Allocates a selected checkox to the amount avaialbe to allocate.
   *  @param options  - (item, index,  checked,
   * **/
  const allocateItem = (options: {
    item: AllocationFieldValues;
    checked: boolean;
    index: number;
  }) => {
    const { item, checked, index } = options;
    let itemTotal: number = 0;

    if (checked) {
      const allocatedTotal = sum("total", allocations);
      const itemMaxTotal = item[itemMaxTotalKey] || 0;
      itemTotal = Math.min(
        to2dp(allocatableAmount - allocatedTotal),
        itemMaxTotal
      );
    }

    form.mutators.update(allocationNameOf("allocations"), index, {
      ...item,
      total: itemTotal
    });
  };

  return {
    autoAllocateItems,
    allocateItem,
    allocatableAmount,
    allocationOption,
    deselectAllItems,
    allocatedTotal,
    owingRemaining,
    allocations
  };
};
