import { ObservableMap } from "mobx";

import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { ConvertToRefData, RefData } from "@libs/api/ref-data/RefData.ts";
import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

type RelationshipDescriptionProps = {
  relationshipRefData: Map<string, RefData>;
  separator?: string;
  lastSeparator?: string;
  codes?: string[];
};

export const RelationshipDescription: React.FC<
  RelationshipDescriptionProps
> = props => {
  const separatorOrDefault = props.separator ? props.separator : ", ";
  const lastSeparatorOrDefault = props.lastSeparator
    ? props.lastSeparator
    : " and ";

  // Note that this logic skips general relationship if there is something
  // else to fall back on
  const relevantRelationshipDescriptions = (codes?: string[]): string[] => {
    if (codes === undefined) {
      return [];
    }

    const validCodes = codes.filter(
      code => props.relationshipRefData.get(code) !== undefined
    );

    return validCodes
      .filter(code => {
        if (
          validCodes.includes(RelationshipType.General) &&
          validCodes.length > 1
        ) {
          return code !== RelationshipType.General;
        } else {
          return true;
        }
      })
      .map(code => props.relationshipRefData.get(code))
      .filter(relationship => relationship)
      .map(relationship => relationship?.description || "");
  };

  const formattedRelationshipDescripton = (): string => {
    if (props.codes === undefined) {
      return "";
    }

    const relationTypeDescriptions = relevantRelationshipDescriptions(
      props.codes
    );

    if (relationTypeDescriptions.length === 1) {
      return relationTypeDescriptions[0]!;
    }

    if (
      relationTypeDescriptions.length <= 2 ||
      separatorOrDefault === lastSeparatorOrDefault
    ) {
      return relationTypeDescriptions.join(lastSeparatorOrDefault);
    } else {
      const formattedBeginningDescriptions = relationTypeDescriptions
        .slice(0, relationTypeDescriptions.length - 2)
        .join(separatorOrDefault);

      const formattedLastTwoDescriptions = relationTypeDescriptions
        .slice(relationTypeDescriptions.length - 2)
        .join(lastSeparatorOrDefault);

      return [
        formattedBeginningDescriptions,
        formattedLastTwoDescriptions
      ].join(separatorOrDefault);
    }
  };

  return (
    <span
      {...dataAttribute(DataAttributes.Element, "relationship-description")}
    >
      {formattedRelationshipDescripton()}
    </span>
  );
};

export const refDescriptionFor =
  (accessor: (x: IRootStore) => RefDataAccessor) =>
  (props: Omit<RelationshipDescriptionProps, "relationshipRefData">) => (
    <DataFetcher<
      ObservableMap<string, ConvertToRefData<RefDataDto<string>, string>>
    >
      fetch={x => accessor(x).load()}
    >
      {relationshipData => (
        <RelationshipDescription
          {...props}
          relationshipRefData={relationshipData}
        />
      )}
    </DataFetcher>
  );

export const RelationshipTypeDescription = refDescriptionFor(
  x => x.practice.ref.relationshipTypes
);
