import { FunctionComponent, memo, useState } from "react";
import { Field, useField } from "react-final-form";

import { DefaultButton, Heading, noWrap, Stack, Text } from "@bps/fluent-ui";
import { AddressDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";
import { invoiceDetailsModalFormNameOf } from "./InvoiceDetailsModal.types.ts";
import { InvoiceDetailsNewAddressDialog } from "./InvoiceDetailsNewAddressDialog.tsx";

export const InvoiceDetailsModalNewAddressFields: FunctionComponent = memo(
  () => {
    const {
      input: { value: accountContactId }
    } = useField<string | undefined>(
      invoiceDetailsModalFormNameOf("accountContactId"),
      {
        subscription: { value: true }
      }
    );

    const {
      input: { value: newAddress }
    } = useField<AddressFieldValue | undefined>(
      invoiceDetailsModalFormNameOf("newAddress"),
      {
        subscription: { value: true }
      }
    );

    const { practice } = useStores();

    const [showAddressDialog, setShowAddressDialog] = useState(false);

    const accountContact = accountContactId
      ? practice.contactsMap.get(accountContactId)
      : undefined;

    const accountContactAddresses = accountContact?.addresses || [];

    const addressOptions = newAddress
      ? [...accountContactAddresses, newAddress as AddressDto]
      : accountContactAddresses;

    const button = (
      <DefaultButton
        styles={{ root: { minWidth: "max-content" } }}
        disabled={!accountContactId}
        onClick={() => setShowAddressDialog(true)}
      >
        New address
      </DefaultButton>
    );

    return (
      <div>
        {addressOptions.length > 1 ? (
          <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="end">
            <DropdownField
              label="Billing address"
              name={invoiceFormNameOf("accountAddress")}
              options={addressOptions.map(address => ({
                key: JSON.stringify(address),
                text: addressText(address)
              }))}
              fieldItemStyles={{ root: { ...noWrap, flexGrow: 1 } }}
            />
            {button}
          </Stack>
        ) : (
          <>
            <Heading labelPaddings>Billing Address</Heading>
            <Stack
              horizontal
              tokens={{ childrenGap: 8 }}
              verticalAlign="center"
            >
              <Field name={invoiceFormNameOf("accountAddress")}>
                {() => null}
              </Field>
              <FieldSpy
                name={invoiceFormNameOf("accountAddress")}
                subscription={{ value: true }}
              >
                {(_state, accountAddress) => (
                  <Text
                    id="invoice-form-account-address-label"
                    styles={{ root: { flex: "1 0 0 " } }}
                  >
                    {accountAddress
                      ? addressText(JSON.parse(accountAddress))
                      : "No recorded"}
                  </Text>
                )}
              </FieldSpy>

              {button}
            </Stack>
          </>
        )}

        {showAddressDialog && (
          <InvoiceDetailsNewAddressDialog
            onDismiss={() => setShowAddressDialog(false)}
          />
        )}
      </div>
    );
  }
);
