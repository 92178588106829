import { FC } from "react";

import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useShowContactDetails } from "@modules/practice/screens/address-book/components/hooks/useShowContactDetails.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const AddressColumn: FC<{ contact: Contact }> = ({ contact }) => {
  const showContactDetails = useShowContactDetails(contact);

  // look for physical address in record or in location
  const filterAddress = contact.addresses.filter(
    x => x.type === AddressType.Physical || x.type === AddressType.Both
  );
  if (filterAddress.length === 0) return null;
  if (filterAddress.length === 1) return <>{addressText(filterAddress[0])}</>;

  return (
    <Navigate onClick={() => showContactDetails()}>Multiple addresses</Navigate>
  );
};
