import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

export const priorityRelationships = [
  RelationshipType.EmergencyContact,
  RelationshipType.EmergencyContactFor,
  RelationshipType.PowerOfAttorney,
  RelationshipType.PowerOfAttorneyFor,
  RelationshipType.NextOfKin,
  RelationshipType.NextOfKinFor
];
