import { observer } from "mobx-react-lite";
import { ReactNode, useState } from "react";

import {
  DefaultButton,
  DirectionalHint,
  Heading,
  IconButton,
  mergeStyleSets,
  Slider,
  SpinButton,
  Stack,
  SwatchColorPicker,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

import { colorPalette } from "../../../notes/draw/constants.ts";
import { getDrawingToolbarStyles } from "../../../notes/draw/DrawingCanvas.styles.ts";
import {
  BrushKindEnum,
  DrawingIcons
} from "../../../notes/draw/DrawingCanvas.types.ts";
import { ToolbarToggleButton } from "../../../notes/draw/ToolbarToggleButton.tsx";

export interface DrawingToolbarProps {
  caption: string;
  onBrushChange: (brush: string) => void;
  onCursorClicked: () => void;
  onUndoClicked: () => void;
  onRedoClicked: () => void;
  onDeleteClicked: () => void;
  onClearClicked?: () => void; // Will only be called if there isn't an override to the render button!!!
  onThicknessChanged: (width: number) => void;
  onBrushColorChanged: (color: string) => void;
  onFillColorChanged: (color: string) => void;
  renderClearButton?: () => ReactNode;
  deleteEnabled: boolean;
  fillColour: string;
  brushColour: string;
  strokeWidth: number;
}

export const DrawingToolbar: React.FC<DrawingToolbarProps> = observer(
  ({
    caption,
    onBrushChange,
    onRedoClicked,
    onUndoClicked,
    onDeleteClicked,
    onClearClicked,
    onThicknessChanged,
    onBrushColorChanged,
    onFillColorChanged,
    onCursorClicked,
    renderClearButton,
    deleteEnabled,
    fillColour,
    brushColour,
    strokeWidth
  }) => {
    const [shapeIcon, setShapeIcon] = useState(DrawingIcons.ellipse);
    const [activeIcon, setActiveIcon] = useState(DrawingIcons.pencil);
    const styles = getDrawingToolbarStyles();

    const theme = useTheme();

    const onShapeClicked = () => {
      switch (shapeIcon) {
        case DrawingIcons.ellipse:
          onBrushChange(BrushKindEnum.Ellipse);
          break;
        case DrawingIcons.line:
          onBrushChange(BrushKindEnum.Line);
          break;
        case DrawingIcons.transparent:
          onBrushChange(BrushKindEnum.Transparent);
          break;
      }
    };

    return (
      <Stack verticalFill styles={{ root: { width: "100%", height: "auto" } }}>
        <Stack
          horizontal
          styles={{ root: { justifyContent: "space-between" } }}
        >
          <Heading
            variant="section-sub-heading"
            styles={{ root: { margin: "auto 0" } }}
          >
            {caption}
          </Heading>

          {renderClearButton ? (
            renderClearButton()
          ) : (
            <TooltipHost
              content="Clear all"
              directionalHint={DirectionalHint.bottomCenter}
            >
              <DefaultButton
                title="Clear"
                onClick={onClearClicked}
                disabled={false}
                styles={mergeStyleSets(styles.clearButton, {
                  root: { color: theme.palette.accent }
                })}
              >
                Clear
              </DefaultButton>
            </TooltipHost>
          )}
        </Stack>
        <Stack
          horizontal
          styles={{ root: { justifyContent: "space-between" } }}
        >
          <Stack horizontal>
            <ToolbarToggleButton
              iconName={DrawingIcons.undo}
              title="Undo"
              active={false}
              onClick={onUndoClicked}
            />
            <ToolbarToggleButton
              iconName={DrawingIcons.redo}
              title="Redo"
              active={false}
              onClick={onRedoClicked}
            />
            <ToolbarToggleButton
              iconName="BpCursor"
              title="Select"
              active={activeIcon === DrawingIcons.cursor}
              onClick={() => {
                setActiveIcon(DrawingIcons.cursor);
                onCursorClicked();
              }}
              styles={{ root: { transform: "rotate(12deg)" } }}
            />
            <ToolbarToggleButton
              iconName={DrawingIcons.delete}
              title="Delete"
              onClick={onDeleteClicked}
              active={false}
              styles={{ root: { color: theme.palette.accent } }}
              disabled={!deleteEnabled}
            />
          </Stack>
          <Stack horizontal>
            <ToolbarToggleButton
              iconName={DrawingIcons.pencil}
              onClick={() => {
                setActiveIcon(DrawingIcons.pencil);
                onBrushChange(BrushKindEnum.Pencil);
              }}
              title="Pencil"
              active={activeIcon === DrawingIcons.pencil}
            />
            <ToolbarToggleButton
              iconName={shapeIcon}
              title="Shape"
              active={activeIcon === shapeIcon}
              onClick={() => {
                setActiveIcon(shapeIcon);
                onShapeClicked();
              }}
              showChevron={true}
            >
              <IconButton
                iconProps={{ iconName: DrawingIcons.ellipse }}
                title="Ellipse"
                onClick={() => {
                  setActiveIcon(DrawingIcons.ellipse);
                  setShapeIcon(DrawingIcons.ellipse);
                  onBrushChange(BrushKindEnum.Ellipse);
                }}
              />
              <IconButton
                iconProps={{ iconName: DrawingIcons.line }}
                title="Line"
                onClick={() => {
                  setActiveIcon(DrawingIcons.line);
                  setShapeIcon(DrawingIcons.line);
                  onBrushChange(BrushKindEnum.Line);
                }}
              />
              <IconButton
                iconProps={{ iconName: DrawingIcons.transparent }}
                title="Transparent pen"
                onClick={() => {
                  setActiveIcon(DrawingIcons.transparent);
                  setShapeIcon(DrawingIcons.transparent);
                  onBrushChange(BrushKindEnum.Transparent);
                }}
              />
            </ToolbarToggleButton>

            <ToolbarToggleButton
              iconName={DrawingIcons.thickness}
              title="Stroke width"
              active={false}
              onClick={() => {
                setActiveIcon(DrawingIcons.thickness);
              }}
              showChevron={true}
            >
              <Stack horizontal styles={styles.sliderSpinWrapper}>
                <Slider
                  styles={styles.slider}
                  min={1}
                  max={20}
                  onChange={(value: number) => {
                    onThicknessChanged(value);
                  }}
                  value={strokeWidth}
                  showValue={false}
                />
                <SpinButton
                  styles={styles.spinButton}
                  onChange={(_event, value) => {
                    onThicknessChanged(Number(value));
                  }}
                  min={1}
                  max={20}
                  value={strokeWidth.toString()}
                  id="brushThickness"
                />
              </Stack>
            </ToolbarToggleButton>
            <ToolbarToggleButton
              iconName={DrawingIcons.nothing}
              title="Stroke colour"
              active={false}
              styles={styles.selectedStroke(brushColour, theme)}
            >
              <Stack styles={styles.colorPickerWrapper}>
                <Text styles={styles.contentMenuTitle}>Stroke colour</Text>
                <SwatchColorPicker
                  id="stroke-color"
                  cellShape="square"
                  columnCount={4}
                  colorCells={colorPalette}
                  onChange={(id, color) => {
                    if (color) {
                      onBrushColorChanged(color);
                    }
                  }}
                  styles={styles.colourPicker}
                />
              </Stack>
            </ToolbarToggleButton>
            <ToolbarToggleButton
              iconName={DrawingIcons.nothing}
              title="Fill colour"
              active={false}
              styles={styles.selectedFill(fillColour, theme)}
            >
              <Stack styles={styles.colorPickerWrapper}>
                <Text styles={styles.contentMenuTitle}>Fill colour</Text>
                <SwatchColorPicker
                  styles={styles.colourPicker}
                  id="fill-color"
                  cellShape="square"
                  columnCount={4}
                  colorCells={colorPalette}
                  onChange={(id, color) => {
                    if (color) {
                      onFillColorChanged(color);
                    }
                  }}
                />
              </Stack>
            </ToolbarToggleButton>
          </Stack>
        </Stack>
      </Stack>
    );
  }
);
