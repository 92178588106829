import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  dataAttribute,
  DataAttributes,
  Pivot,
  PivotItem
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

export const PeopleScreenPivot: FC = observer(() => {
  const { routing } = useStores();
  const activeTab = routing.location.pathname;
  const patientLabel = usePatientLabel(false);

  return (
    <Pivot
      selectedKey={activeTab}
      onLinkClick={(item: PivotItem) => {
        if (item.props.itemKey) {
          routing.push({
            pathname: item.props.itemKey
          });
        }
      }}
      styles={{
        link: { backgroundColor: "inherit" }
      }}
    >
      <PivotItem
        headerButtonProps={{
          ...dataAttribute(DataAttributes.Element, "pivot-patient-tab")
        }}
        headerText={`${usePatientLabel(true)}s`}
        itemKey={routes.addressBook.patient.pattern}
      />
      <PivotItem
        headerButtonProps={{
          ...dataAttribute(DataAttributes.Element, "pivot-individual-tab")
        }}
        headerText={`Non-${patientLabel}s`}
        itemKey={routes.addressBook.individuals.pattern}
      />
      <PivotItem
        headerButtonProps={{
          ...dataAttribute(DataAttributes.Element, "pivot-organisation-tab")
        }}
        headerText="Organisations"
        itemKey={routes.addressBook.organisations.pattern}
      />
    </Pivot>
  );
});
