import { FC } from "react";
import { useForm } from "react-final-form";

import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { OrganisationLabels } from "../organisation.types.ts";
import { BillingAdminEmailFields } from "./BillingAdminEmailFields.tsx";
import { orgEditFormNameOf } from "./EditOrganisationFormValues.tsx";

type PrivateInsurerFieldsProps = {
  name: string;
};

export const PrivateInsurerFields: FC<PrivateInsurerFieldsProps> = ({
  name
}) => {
  const form = useForm();
  return (
    <>
      <YesNoToggleField
        label={OrganisationLabels.PrivateInsurerForAcc}
        name={`${name}.accPrivateInsurer`}
      />
      <FieldSpy
        name={`${name}.accPrivateInsurer`}
        onChange={() => {
          form.batch(() => {
            form.change(`${name}.claimsAdminEmail`, undefined);
            form.change(`${name}.differentInvoicingEmail`, false);
            form.change(`${name}.invoicingEmail`, undefined);
          });
        }}
      />
      <FieldCondition when={`${name}.accPrivateInsurer`} is={true}>
        <BillingAdminEmailFields name={orgEditFormNameOf("privateInsurer")} />
      </FieldCondition>
    </>
  );
};
