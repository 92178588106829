import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  Badge,
  dataAttribute,
  DataAttributes,
  FontIcon,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { unique } from "@bps/utils";

import { AppointmentNotice } from "../../../BookingCalendarEventModel.ts";

interface NoticeBadgeProps {
  dataAtrributeValue: string;
  notices: AppointmentNotice[];
}

export const NoticeBadge: FunctionComponent<NoticeBadgeProps> = observer(
  ({ notices, dataAtrributeValue }) => {
    const count = unique(notices.map(n => n.type)).length;
    const theme = useTheme();
    return notices.length ? (
      <Stack horizontal verticalAlign="center">
        <Badge
          {...dataAttribute(DataAttributes.Element, dataAtrributeValue)}
          styles={{
            root: {
              padding: "4px 7px",
              borderRadius: "50%",
              lineHeight: 14,
              backgroundColor: theme.palette.themeLighter
            }
          }}
        >
          {count}
        </Badge>
        {count > 0 && (
          <FontIcon
            styles={{ root: { fontSize: 12 } }}
            iconName="ChevronRight"
          />
        )}
      </Stack>
    ) : null;
  }
);
