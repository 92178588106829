import React from "react";

import { mergeStyles, Spinner, SpinnerSize } from "@bps/fluent-ui";

interface ReportLoadingSpinnerProps {
  label?: string;
}

export const ReportLoadingSpinner: React.FC<ReportLoadingSpinnerProps> = ({
  label
}) => {
  return (
    <Spinner
      label={label}
      className={mergeStyles({
        position: "absolute",
        top: "50%",
        left: "50%"
      })}
      size={SpinnerSize.large}
    />
  );
};
