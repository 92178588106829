import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  IPersonaProps,
  PersonaSize,
  Spinner,
  Stack
} from "@bps/fluent-ui";
import { BillToSecondaryText } from "@modules/billing/screens/allocation/components/BillToSecondaryText.tsx";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { AccountCardContactDetailsButton } from "./AccountCardContactDetailsButton.tsx";

export interface AccountCardProps extends IPersonaProps {
  contactId: string;
  renderExtraData?: (contact: Contact) => React.ReactNode;
}

export const AccountCard: FunctionComponent<AccountCardProps> = ({
  contactId,
  renderExtraData,
  ...personaProps
}) => {
  return (
    <DataFetcher<Contact>
      fallback={<Spinner />}
      refetchId={`${contactId}-accountCard`}
      fetch={({ practice }) => practice.getContact(contactId)}
    >
      {contact => (
        <>
          <Stack
            horizontal
            horizontalAlign="space-between"
            {...dataAttribute(DataAttributes.Loading, !contact)}
          >
            <Persona
              id={contact.id}
              imageUrl={contact.profilePictureUrl}
              contactType={contact.type}
              size={PersonaSize.size72}
              imageInitials={contact.initials}
              text={contact.name}
              onRenderSecondaryText={() => (
                <BillToSecondaryText contact={contact} />
              )}
              {...personaProps}
            />
            <AccountCardContactDetailsButton contactId={contactId} />
          </Stack>
          {/*extra details*/}
          {renderExtraData && renderExtraData(contact)}
        </>
      )}
    </DataFetcher>
  );
};
