import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  Link,
  mergeStyles,
  Stack,
  Text
} from "@bps/fluent-ui";
import { PatientDisplayCode } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ResetUsersControlBaseProps {
  users: UserSetting[];
}

const ResetUsersControlFieldBase: React.FC<ResetUsersControlBaseProps> =
  observer(({ users }) => {
    const { input } = useField("userSettingsToReset", {
      subscription: { value: true }
    });

    const field = useField<PatientDisplayCode>("patientDisplayCode", {
      subscription: { value: true }
    });

    const usersToReset = users.filter(
      user => user.patientLabel && user.patientLabel !== field.input.value
    );

    const usersToResetJSX = usersToReset.map(user => (
      <li
        {...dataAttribute(DataAttributes.Element, "user-list-item")}
        className={mergeStyles({ fontWeight: FontWeights.semibold })}
        key={user.id}
      >
        {user.userName}
      </li>
    ));

    const handleReset = () => {
      input.onChange(usersToReset);
    };
    return (
      <>
        <Stack horizontal styles={{ root: { marginTop: "8px" } }}>
          <Text
            {...dataAttribute(DataAttributes.Element, "user-control-text")}
            styles={{ root: { paddingRight: "6px" } }}
          >
            {input.value.length > 0 && usersToReset.length > 0
              ? `Resetting (${usersToReset.length}) users to default`
              : `Users who have changed this (${usersToReset.length})`}
          </Text>
          {input.value.length < 1 && usersToReset.length > 0 && (
            <Link
              {...dataAttribute(DataAttributes.Element, "reset-users-button")}
              onClick={handleReset}
            >
              Reset
            </Link>
          )}
        </Stack>
        {input.value.length < 1 && <ul>{usersToResetJSX}</ul>}
      </>
    );
  });

export const ResetUsersControlField: React.FC = () => {
  return (
    <DataFetcher
      fetch={async ({ userExperience }: IRootStore) =>
        userExperience.getUsersSettings()
      }
    >
      {userSettings => <ResetUsersControlFieldBase users={userSettings} />}
    </DataFetcher>
  );
};
