import { required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  AddXrayParametersFormValues,
  addXrayParametersFormValuesNameOf,
  AddXrayParametersView,
  addXrayParametersViewNameOf
} from "./AddXrayParameterDialog.types.ts";

export class AddXrayParametersDialogValidator extends Validator<AddXrayParametersFormValues> {
  constructor(hasSide: boolean) {
    super();
    this.forField(addXrayParametersFormValuesNameOf("region"), required());
    this.forArrayField(
      addXrayParametersFormValuesNameOf("views"),
      (val, values: AddXrayParametersFormValues) => {
        if (values.region) {
          const xrayParametersValidator = new XrayParametersViewsValidator(
            val,
            values.views?.indexOf(val) ?? -1
          );
          return xrayParametersValidator.validate(val);
        }
        return false;
      }
    );

    hasSide &&
      this.forField(
        addXrayParametersFormValuesNameOf("side"),
        (val, values) => {
          if (values.region) {
            if (!val) {
              return ValidationMessages.required;
            }
          }
          return false;
        }
      );
  }
}

class XrayParametersViewsValidator extends Validator<AddXrayParametersView> {
  constructor(val: AddXrayParametersView, index: number) {
    super();
    index === 0 &&
      this.forField(addXrayParametersViewNameOf("view"), required());
    if (val.view || index === 0) {
      this.forField(addXrayParametersViewNameOf("areaSize"), required());
      this.forField(addXrayParametersViewNameOf("voltage"), required());
      this.forField(addXrayParametersViewNameOf("current"), required());
      this.forField(addXrayParametersViewNameOf("duration"), required());
      this.forField(addXrayParametersViewNameOf("sid"), required());
    }
  }
}
