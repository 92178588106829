import { FunctionComponent, useContext } from "react";
import { Field, useForm } from "react-final-form";

import { FieldItemError, IPersonaProps, Stack, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { AddFromWaitingListButton } from "@modules/booking/screens/waiting-list/components/waiting-list-details-dialog/AddFromWaitingListButton.tsx";
import { WaitingListDetailsDialog } from "@modules/booking/screens/waiting-list/components/waiting-list-details-dialog/WaitingListDetailsDialog.tsx";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { PatientActivation } from "@modules/practice/screens/shared-components/PatientActivation.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { SubmissionFormDialogHeader } from "@ui-components/form/submission-form-dialog/SubmissionFormDialogHeader.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { AppointmentDetails } from "../../appointment-dialog/components/appointment-form/appointment-details/AppointmentDetails.tsx";
import { appointmentFormNameOf } from "../../appointment-dialog/components/appointment-form/AppointmentForm.types.ts";
import { AppointmentFormContext } from "../../appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { formatTimeSpanWithDate } from "../../appointment-dialog/components/appointment-form/utils.ts";

interface AddToGroupApptFormFieldsProps {
  onDismiss: () => void;
}

export const AddToGroupApptFormFields: FunctionComponent<
  AddToGroupApptFormFieldsProps
> = ({ onDismiss }) => {
  const { practice, booking } = useStores();
  const getTextFromItem = (persona: IPersonaProps) => persona.text as string;
  const { spacing } = useTheme();

  const { patientLabel } = useContext(AppointmentFormContext);

  const getGroupApptTypes = () => {
    const apptTypes = booking.activeAppointmentTypes
      .filter(x => x.isGroupAppointmentType)
      .map(({ id, name }) => ({
        key: id,
        name
      }));
    return apptTypes;
  };

  const onApptDetailsClick = () => {
    booking.ui.setSecondColumnContent(
      booking.ui.currentAppointment?.secondColumnContent !==
        SecondColumnContent.nextGroupAppt
        ? SecondColumnContent.nextGroupAppt
        : undefined
    );
  };

  const form = useForm<AppointmentFormValues>();

  const getDuplicateError = () => {
    const values = form.getState().values;
    const patient = practice.contactsMap.get(values.patientId);
    if (patient && patient.name && values.startTime) {
      const startTime = formatTimeSpanWithDate(values.startTime, {
        date: values.startDate
      });
      return `${patient.name} is already included in the group appointment at ${startTime}`;
    }
    return "";
  };

  const onPatientChange = (
    patientId: AppointmentFormValues["patientId"],
    values: AppointmentFormValues
  ) => {
    if (
      patientId &&
      values.groupAttendees &&
      values.groupAttendees.length > 0
    ) {
      form.change(appointmentFormNameOf("groupAttendeeIds"), [
        ...values.groupAttendees.map(x => x.attendeeId),
        values.patientId
      ]);
    }
  };

  return (
    <>
      <SubmissionFormDialogHeader
        onCancel={onDismiss}
        title={`Add ${patientLabel} to group appointment`}
      />
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            overflowY: "auto",
            padding: "0, 24px"
          }
        }}
      >
        <ContactPickerFieldWithAdd
          autoFocus
          name={appointmentFormNameOf("patientId")}
          label={capitalizeSentence(patientLabel)}
          required
          includeInactive
          getTextFromItem={getTextFromItem}
          quickAddProps={{
            addButtonLabel: `New ${patientLabel}`,
            typeToAdd: ContactType.Patient,
            permission: [
              Permission.ContactRead,
              Permission.ContactWrite,
              Permission.PatientRead,
              Permission.PatientWrite
            ],
            buttonWrapperStyles: {
              root: { flexBasis: 0, flexGrow: 1.2 }
            }
          }}
          filter={{ types: [ContactType.Patient] }}
          actionButton={{
            linkProps: {
              children: (
                <Field<string | undefined>
                  name={appointmentFormNameOf("patientId")}
                >
                  {props => {
                    if (!props.input.value) {
                      return null;
                    }
                    return <PatientActivation patientId={props.input.value} />;
                  }}
                </Field>
              )
            }
          }}
          pickerFormItemStyles={{
            root: { flexBasis: 0, flexGrow: 2.8 }
          }}
        />
        <FieldSpy
          name={appointmentFormNameOf("patientId")}
          onChange={onPatientChange}
        />
        <AddFromWaitingListButton linkStyles={{ root: { marginTop: 5 } }} />
        <StaticPickerField
          name={appointmentFormNameOf("appointmentTypeId")}
          label="Type"
          fetchDataSource={getGroupApptTypes}
          required
          fieldItemStyles={{ root: { flexGrow: 2.8, flexBasis: 0 } }}
        />
        <AppointmentDetails
          isGroupAppointment={true}
          onClick={onApptDetailsClick}
        />
        <FieldSpy
          name={appointmentFormNameOf("groupAttendeeIds")}
          subscription={{ invalid: true }}
        >
          {props => (
            <>
              {props.invalid && (
                <FieldItemError
                  name="duplicate-Attendees"
                  errorMessage={getDuplicateError()}
                />
              )}
            </>
          )}
        </FieldSpy>
      </Stack>
      <WaitingListDetailsDialog />
      <FormSubmitButtons
        hideButtonsSeparator
        onCancel={onDismiss}
        disableSubmitOnPristine
        disableSubmitOnFormInvalid
        submitButtonProps={{
          id: "submit-addToGroupAppt-form",
          text: "Add to appointment",
          iconProps: undefined
        }}
        styles={{ root: { padding: spacing.l1 } }}
        cancelButtonProps={{ text: "Cancel" }}
      />
    </>
  );
};
