import { CommentToolTip } from "@modules/billing/screens/shared-components/CommentToolTip.tsx";

import { IInvoiceRow } from "./AccInvoiceTransactionsList.tsx";

export const ReasonCell: React.FC<IInvoiceRow> = props => {
  const reason =
    props.invoiceItem.accScheduleItem?.paymentReason ??
    props.invoiceItem.accScheduleItem?.statusReason;

  if (!reason) {
    return null;
  }

  return <CommentToolTip content={reason} />;
};
