import { DateTime, to2dp } from "@bps/utils";
import {
  AddInvoiceItemNewDto,
  GstMethod,
  InvoiceItemDto,
  ServiceRuleDto,
  ServiceRuleType,
  ServiceSearchDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { getAddressFormValueFromContact } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { getFeeIncludingGst } from "@modules/settings/screens/shared-components/fees.utils.ts";
import { Service } from "@stores/billing/models/Service.ts";
import {
  getServiceTax,
  getServiceTotal
} from "@stores/billing/utils/billing.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export const getTotal = (items: InvoiceItemFormValue[], gstPercent: number) => {
  const total = items.reduce((sum: number, item: InvoiceItemFormValue) => {
    return sum + getServiceTotal(item, gstPercent);
  }, 0);

  return to2dp(total);
};

export const getInvoiceItemFormValues = (args: {
  invoiceItems: InvoiceItemDto[];
  gstPercent: number;
  services: Service[];
}) => {
  const { invoiceItems, gstPercent, services } = args;
  return invoiceItems.reduce((items: InvoiceItemFormValue[], invoiceItem) => {
    const service = services.find(x => x.id === invoiceItem.serviceId);
    if (service?.instances[0]?.isActive) {
      items.push(
        getInvoiceItemFormValueFromService({
          invoiceItem,
          service,
          gstPercent
        })
      );
    }

    return items;
  }, []);
};
const getInvoiceItemFormValueFromService = (args: {
  invoiceItem: InvoiceItemDto;
  service: Service;
  gstPercent: number;
}): InvoiceItemFormValue => {
  const { invoiceItem, service, gstPercent } = args;

  const serviceInstance = service.instances[0];

  const feeValue = getInvoiceFeeValue({
    rules: serviceInstance.rules,
    invoiceItemFee: invoiceItem.fee,
    serviceFee: serviceInstance.fee
  });

  const displayFee = getInvoiceDisplayFee({
    rules: serviceInstance.rules,
    invoiceItemFee: invoiceItem.fee,
    serviceFee: serviceInstance.fee,
    gstMethod: service.gstMethod,
    gstPercent
  });

  const serviceSearch = getServiceSearchFromService(service);

  const gst = getServiceTax(
    {
      fee: feeValue,
      quantity: String(invoiceItem.quantity),
      serviceSearch,
      feeType: service.feeType
    },
    gstPercent
  );

  const total = getServiceTotal(
    {
      fee: feeValue,
      quantity: String(invoiceItem.quantity),
      serviceSearch,
      feeType: service.feeType
    },
    gstPercent
  );

  const invoiceItemFormValue: InvoiceItemFormValue = {
    id: invoiceItem.id,
    serviceId: service.id,
    scheduleId: service.scheduleId,
    description: service.description || "",
    name: service.name,
    code: service.code,
    quantity: invoiceItem.quantity.toString(),
    serviceDate: DateTime.jsDateFromISO(invoiceItem.serviceDate),
    fee: displayFee,
    gst: String(gst),
    total: String(total),
    allocatedAmount: to2dp(
      (invoiceItem.paid || 0) + (invoiceItem.writtenOff || 0)
    ),
    feeType: service.feeType,
    isService: service.isService,
    purchaseOrderNumber: invoiceItem.purchaseOrderNumber || "",
    serviceSearch,
    comment: invoiceItem.comment || ""
  };

  return invoiceItemFormValue;
};
export const getServiceSearchFromService = (
  service: Service
): ServiceSearchDto => {
  const serviceInstance = service.instances[0];
  return {
    ...service.dto,
    serviceId: service.id,
    fee: serviceInstance.fee,
    rules: serviceInstance.rules
  };
};
// the output value is to be stored on the invoice item
export const getInvoiceDisplayFee = (options: {
  rules: ServiceRuleDto[] | undefined;
  invoiceItemFee: number | undefined;
  serviceFee: number | undefined;
  gstMethod: GstMethod;
  gstPercent: number;
}): string => {
  const { rules, invoiceItemFee, serviceFee, gstMethod, gstPercent } = options;

  if (rules?.some(r => r.ruleType === ServiceRuleType.UserDefinedAmount)) {
    return String(invoiceItemFee ?? 0);
  }

  const fee = getFeeIncludingGst(serviceFee ?? 0, gstMethod, gstPercent);
  return String(fee);
};
// the output value is for use in calculating invoice + gst
export const getInvoiceFeeValue = (options: {
  rules: ServiceRuleDto[] | undefined;
  invoiceItemFee: number | undefined;
  serviceFee: number | undefined;
}): string => {
  const { rules, invoiceItemFee, serviceFee } = options;

  return String(
    (rules?.some(r => r.ruleType === ServiceRuleType.UserDefinedAmount)
      ? invoiceItemFee
      : serviceFee) ?? 0
  );
};
export const convertInvoiceDetailsToRouteState = (
  values: Partial<InvoiceDetailsModalFormValues>
) => {
  const state = {};

  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      state[key] = value;
    }
  });

  return state;
};

interface NewInvoiceOptions {
  patientId?: string;
  calendarEventId?: string;
}

export const getStartingInvoiceValues = async (
  root: RootStore,
  options: NewInvoiceOptions
): Promise<
  Pick<
    InvoiceDetailsModalFormValues,
    | "accountAddress"
    | "accountContactId"
    | "calendarEventId"
    | "claimId"
    | "patientId"
    | "userId"
    | "locationId"
  >
> => {
  const loadCalendarEvent = async () => {
    const ce = options.calendarEventId
      ? await root.booking.getCalendarEvent(options.calendarEventId)
      : undefined;

    if (root.core.hasPermissions(Permission.ClaimRead)) {
      await ce?.loadClaim();
    }
    return ce;
  };

  const calendarEvent = await loadCalendarEvent();
  const patientId = options?.patientId || calendarEvent?.contactId;

  if (!patientId) {
    throw new Error("No patient found");
  }

  const patient = await root.practice.getContact(patientId, {
    includeRelationships: true
  });

  const accountContactId =
    patient.primaryAccountHolder?.relatedContactId ?? patient.id;

  const accountContact = await root.practice.getContact(accountContactId);

  let locationId = calendarEvent?.orgUnitId ?? "";
  if (!locationId && !root.core.hasMultipleActiveLocations) {
    locationId = root.core.locationId;
  }

  return {
    accountAddress: getAddressFormValueFromContact(accountContact),
    accountContactId,
    calendarEventId: calendarEvent?.id,
    claimId: calendarEvent?.claimId,
    patientId: patient.id,
    userId: calendarEvent?.userId ?? root.core.userId,
    locationId
  };
};
export const getAddInvoiceItemNewDto = (options: {
  item: InvoiceItemFormValue;
  locationId: string;
  baseProps: Pick<
    InvoiceItemDto,
    | "userId"
    | "calendarEventId"
    | "user"
    | "patient"
    | "patientId"
    | "itemType"
    | "accountId"
  >;
}): AddInvoiceItemNewDto => {
  const { item, locationId, baseProps } = options;

  return {
    id: item.id,
    serviceId: item.serviceId,
    serviceDate: DateTime.jsDateToISODate(item.serviceDate),
    quantity: Number(item.quantity),
    fee: Number(item.fee),
    amount: Number(item.total),
    gst: Number(item.gst),
    purchaseOrderNumber: item.purchaseOrderNumber,
    comment: item.comment,
    locationId,
    ...baseProps
  };
};
