import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { NoDataTile, Shimmer } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { sortByKey } from "@libs/utils/utils.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import {
  ClaimManagementCardIds,
  ClaimSectionHeadings
} from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { PurchaseOrder } from "@stores/acc/models/PurchaseOrder.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { PurchaseOrderButton } from "./PurchaseOrderButton.tsx";
import { PurchaseOrderList } from "./PurchaseOrderList.tsx";

export const PurchaseOrdersFormSection: FC = observer(() => {
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const { setShowPurchaseOrderDialog } = useClaimAdjustmentContext();
  const { getClaimPurchaseOrders, claimPurchaseOrders } =
    useContext(ClaimContext);

  const {
    core,
    acc: {
      ui: { recentlyUpdatedPurchaseOrderKey }
    }
  } = useStores();

  const fetch = async () => {
    const purchaseOrders = await getClaimPurchaseOrders();
    await Promise.all(
      purchaseOrders.map(po => [
        po.loadRelatedClaimAdjustment(),
        po.loadDeleteCheckData()
      ])
    );
    return purchaseOrders;
  };

  return (
    <ClaimCard
      id={ClaimManagementCardIds.purchaseOrders}
      openOnRender={
        selectedMenuItemId === ClaimManagementCardIds.purchaseOrders
      }
      heading={`${ClaimSectionHeadings.purchaseOrders} ${
        claimPurchaseOrders && claimPurchaseOrders.length > 0
          ? `(${claimPurchaseOrders.length})`
          : ""
      }`}
      iconName="EntitlementRedemption"
      button={
        <When permission={Permission.ClaimWrite}>
          <PurchaseOrderButton />
        </When>
      }
    >
      <DataFetcher<PurchaseOrder[]>
        fetch={fetch}
        refetchId={recentlyUpdatedPurchaseOrderKey}
        fallback={<Shimmer width={120} />}
      >
        {purchaseOrders => {
          const sortedPurchaseOrders = sortByKey(
            purchaseOrders,
            "responseDate",
            true
          );
          return purchaseOrders && purchaseOrders.length > 0 ? (
            <PurchaseOrderList
              sortedPurchaseOrders={sortedPurchaseOrders}
              setShowModal={setShowPurchaseOrderDialog}
            />
          ) : (
            <NoDataTile
              textProps={{
                text: "No purchase orders"
              }}
              showBoxShadow={false}
              linkProps={
                core.hasPermissions(Permission.ClaimWrite)
                  ? {
                      text: "New purchase order",
                      onClick: () => {
                        setShowPurchaseOrderDialog(true);
                      }
                    }
                  : { hidden: true }
              }
            />
          );
        }}
      </DataFetcher>
    </ClaimCard>
  );
});
