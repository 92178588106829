import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  Length,
  lessThanOrSameWithSuffix,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { MotionTypeItem } from "@shared-types/clinical/motion-type-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { DEGREE_SYMBOL } from "../SOTAP.constants.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";

export class InjuryAreaCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();

    const injuryAreaMotionAssessmentValidator =
      new InjuryAreaMotionAssessmentValidator();
    this.forArrayField(
      sotapNameOf("injuryAreaMotionAssessments"),
      injuryAreaMotionAssessmentValidator.validate
    );
  }
}

export class InjuryAreaMotionAssessmentValidator extends Validator<InjuryAreaMotionAssessmentItem> {
  readonly nameOf = nameOfFactory<InjuryAreaMotionAssessmentItem>();
  constructor() {
    super();
    this.forField(this.nameOf("palpation"), [maxLength(Length.comments)]);
    this.forField(this.nameOf("strength"), [maxLength(Length.comments)]);
    this.forField(this.nameOf("specialTests"), [maxLength(Length.comments)]);
    const motionTypeValidator = new MotionTypeValidator();
    this.forArrayField(
      this.nameOf("motionTypes"),
      motionTypeValidator.validate
    );
  }
}

export class MotionTypeValidator extends Validator<MotionTypeItem> {
  readonly nameOf = nameOfFactory<MotionTypeItem>();
  constructor() {
    super();
    this.forField(this.nameOf("active"), [
      lessThanOrSameWithSuffix(180, DEGREE_SYMBOL)
    ]);
    this.forField(this.nameOf("passive"), [
      lessThanOrSameWithSuffix(180, DEGREE_SYMBOL)
    ]);
  }
}
