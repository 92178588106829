import { BodyArea } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  BodyExaminationFormValues,
  SpecialTestResponseType
} from "../examination/BodyExaminationForm.types.ts";

export enum specialTestOptions {
  Positive = "P",
  Negative = "N",
  Left = "L",
  Right = "R",
  Low = "L",
  High = "H",
  Neutral = "N",
  Yes = "Y",
  No = "N"
}

export const checkStringSpecialTest = (value: string) => {
  return value.length > 250 ? "string value length over 250" : undefined;
};

const checkIntegerSpecialTest = (value: number, range: number) => {
  return value < 0 || value > range
    ? `Integer value out of range ${range}`
    : undefined;
};

const checkMultipleChoiceSpecialTest = (value: string, options: string[]) => {
  const valueList = value.split(",");

  for (let i = 0; i < valueList.length; i++) {
    if (options.indexOf(valueList[i]) === -1) {
      return "Invalid option value";
    }
  }
  return undefined;
};

const checkElbowSpecialTest = (value: string, index?: number) => {
  if (!value) return;
  if (index === 0 || index === 2) {
    return checkMultipleChoiceSpecialTest(value[0], [
      specialTestOptions.Positive,
      specialTestOptions.Negative
    ]);
  }
  if (index === 1 || index === 3) {
    return checkStringSpecialTest(value);
  }

  return checkMultipleChoiceSpecialTest(value[0], [
    specialTestOptions.Yes,
    specialTestOptions.No
  ]);
};

const checkHandSpecialTest = (value: string, index?: number) => {
  if (!value) return;
  if (index === 0 || index === 2 || index === 4) {
    return checkStringSpecialTest(value);
  }
  return checkMultipleChoiceSpecialTest(value[0], [
    specialTestOptions.Positive,
    specialTestOptions.Negative
  ]);
};

const checkStrengthTest = (value: string) => {
  if (!value) return;
  return checkIntegerSpecialTest(parseInt(value), 5);
};

const checkShoulderSpecialTest = (value: string, index?: number) => {
  if (!value) return;

  switch (index) {
    case 0:
    case 2:
    case 4:
      return checkMultipleChoiceSpecialTest(value[0], [
        specialTestOptions.Positive,
        specialTestOptions.Negative
      ]);
    case 1:
    case 3:
    case 5:
      return checkStringSpecialTest(value);
    case 6:
      return checkMultipleChoiceSpecialTest(value[0], [
        specialTestOptions.Low,
        specialTestOptions.Neutral,
        specialTestOptions.High
      ]);
    default:
      return checkMultipleChoiceSpecialTest(value[0], [
        specialTestOptions.Yes,
        specialTestOptions.No
      ]);
  }
};

const checkHipSpecialTest = (value: string, index?: number) => {
  if (!value) return;
  if (index === 4 || index === 5) {
    return checkIntegerSpecialTest(parseInt(value), 5);
  }

  if (index === 3 || index === 7 || index === 9 || index === 11) {
    return checkStringSpecialTest(value);
  }
  if (index === 0) {
    return checkMultipleChoiceSpecialTest(value[0], [
      specialTestOptions.Left,
      specialTestOptions.Right
    ]);
  }
  if (index === 1) {
    return checkMultipleChoiceSpecialTest(value[0], [
      specialTestOptions.Low,
      specialTestOptions.Neutral,
      specialTestOptions.High
    ]);
  }
  return checkMultipleChoiceSpecialTest(value[0], [
    specialTestOptions.Positive,
    specialTestOptions.Negative
  ]);
};

export class SpecialTestValidator extends Validator<SpecialTestResponseType> {
  constructor() {
    super();
    this.forArrayField(BodyArea.LSHO, [
      (value, values: SpecialTestResponseType) => {
        const index = values.LSHO?.indexOf(value);
        return checkShoulderSpecialTest(value, index);
      }
    ]);
    this.forArrayField(BodyArea.RSHO, [
      (value, values: SpecialTestResponseType) => {
        const index = values.RSHO?.indexOf(value);
        return checkShoulderSpecialTest(value, index);
      }
    ]);
    this.forArrayField(BodyArea.Hip, [
      (value, values: SpecialTestResponseType) => {
        const index = values.HIP?.indexOf(value);
        return checkHipSpecialTest(value, index);
      }
    ]);
    this.forArrayField(BodyArea.LElbow, [
      (value, values: SpecialTestResponseType) => {
        const index = values.LELB?.indexOf(value);
        return checkElbowSpecialTest(value, index);
      }
    ]);
    this.forArrayField(BodyArea.RElbow, [
      (value, values: SpecialTestResponseType) => {
        const index = values.RELB?.indexOf(value);
        return checkElbowSpecialTest(value, index);
      }
    ]);
    this.forArrayField(BodyArea.RHAN, [
      (value, values: SpecialTestResponseType) => {
        const index = values.RHAN?.indexOf(value);
        return checkHandSpecialTest(value, index);
      }
    ]);
    this.forArrayField(BodyArea.LHAN, [
      (value, values: SpecialTestResponseType) => {
        const index = values.LHAN?.indexOf(value);
        return checkHandSpecialTest(value, index);
      }
    ]);
  }
}

export class StrengthTestValidator extends Validator<SpecialTestResponseType> {
  constructor() {
    super();

    this.forArrayField(BodyArea.RHAN, [
      value => {
        return checkStrengthTest(value);
      }
    ]);
    this.forArrayField(BodyArea.LHAN, [
      value => {
        return checkStrengthTest(value);
      }
    ]);
    this.forArrayField(BodyArea.RFOO, [
      value => {
        return checkStrengthTest(value);
      }
    ]);
    this.forArrayField(BodyArea.LFOO, [
      value => {
        return checkStrengthTest(value);
      }
    ]);
    this.forArrayField(BodyArea.RSHO, [
      value => {
        return checkStrengthTest(value);
      }
    ]);
    this.forArrayField(BodyArea.LSHO, [
      value => {
        return checkStrengthTest(value);
      }
    ]);
  }
}

export class BodyExaminationValidator extends Validator<BodyExaminationFormValues> {
  constructor() {
    super();
    const specialTestValidator = new SpecialTestValidator();
    const strengthTestValidator = new StrengthTestValidator();
    this.forField("specialTestResponseItems", specialTestValidator.validate);
    this.forField("strengthTestResponseItems", strengthTestValidator.validate);
  }
}
