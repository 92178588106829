import { FC } from "react";

import { LabeledText, Stack, Text, useTheme } from "@bps/fluent-ui";
import { EmployerDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { OrganisationRoleTypeText } from "@ui-components/RefText.tsx";

import { OrganisationLabels } from "../organisation.types.ts";

interface ViewEmployerFieldsProps {
  employer: EmployerDto;
  organisationRoleTypeCode: string;
}

export const ViewEmployerFields: FC<ViewEmployerFieldsProps> = ({
  employer,
  organisationRoleTypeCode
}) => {
  const { practice } = useStores();
  const theme = useTheme();
  const DEFAULT_VISITS_PER_CLAIM = 16;

  const getPrivateInsurer = async (id: string) => {
    return await practice.getContact(id);
  };

  return (
    <>
      {employer.accreditedEmployer ? (
        <>
          <Stack horizontal>
            <Stack styles={{ root: { width: "25%" } }}>
              <LabeledText noSemicolon label={OrganisationLabels.Type} wrap>
                <OrganisationRoleTypeText code={organisationRoleTypeCode} />
              </LabeledText>
            </Stack>
            <Stack
              horizontal
              horizontalAlign="space-between"
              styles={{ root: { paddingRight: 30, width: "75%" } }}
            >
              <Stack>
                <LabeledText
                  noSemicolon
                  label={OrganisationLabels.AccAccredited}
                  wrap
                >
                  Yes
                </LabeledText>
              </Stack>
              <Stack>
                <LabeledText
                  noSemicolon
                  label={OrganisationLabels.ConsultsPerClaim}
                  wrap
                >
                  {employer?.visitsPerClaim ?? DEFAULT_VISITS_PER_CLAIM}
                </LabeledText>
              </Stack>
              <Stack>
                <LabeledText
                  noSemicolon
                  label={OrganisationLabels.UsingPrivateInsurer}
                  wrap
                >
                  {employer?.privateInsurerContactId ? (
                    <DataFetcher<Contact>
                      fetch={() =>
                        getPrivateInsurer(employer?.privateInsurerContactId!)
                      }
                    >
                      {privateInsurer => (
                        <Stack.Item>{privateInsurer.name}</Stack.Item>
                      )}
                    </DataFetcher>
                  ) : (
                    <Stack.Item>No</Stack.Item>
                  )}
                </LabeledText>
              </Stack>
            </Stack>
          </Stack>
          {!employer.privateInsurerContactId && (
            <Stack horizontal>
              <Stack styles={{ root: { width: "50%" } }}>
                <LabeledText
                  label={
                    employer?.differentInvoicingEmail
                      ? OrganisationLabels.Claims
                      : OrganisationLabels.ClaimsAndInvoicing
                  }
                  wrap
                >
                  {employer.claimsAdminEmail ? (
                    <Stack.Item>{employer?.claimsAdminEmail}</Stack.Item>
                  ) : (
                    <Text
                      styles={{
                        root: {
                          fontStyle: "italic",
                          color: theme.semanticColors.disabledBodyText
                        }
                      }}
                    >
                      {OrganisationLabels.EmailNotProvided}
                    </Text>
                  )}
                </LabeledText>
              </Stack>
              {employer?.differentInvoicingEmail && (
                <Stack styles={{ root: { width: "50%" } }}>
                  <LabeledText label={OrganisationLabels.Invoicing} wrap>
                    {employer.invoicingEmail ? (
                      <Stack.Item>{employer.invoicingEmail}</Stack.Item>
                    ) : (
                      <Text
                        styles={{
                          root: {
                            fontStyle: "italic",
                            color: theme.semanticColors.disabledBodyText
                          }
                        }}
                      >
                        {OrganisationLabels.EmailNotProvided}
                      </Text>
                    )}
                  </LabeledText>
                </Stack>
              )}
            </Stack>
          )}
        </>
      ) : (
        <Stack horizontal>
          <Stack styles={{ root: { width: "25%" } }}>
            <LabeledText noSemicolon label={OrganisationLabels.Type} wrap>
              <OrganisationRoleTypeText code={organisationRoleTypeCode} />
            </LabeledText>
          </Stack>
          <Stack>
            <LabeledText
              noSemicolon
              label={OrganisationLabels.AccAccredited}
              wrap
            >
              No
            </LabeledText>
          </Stack>
        </Stack>
      )}
    </>
  );
};
