import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  DropdownField,
  DropdownFieldProps
} from "@ui-components/form/DropdownField.tsx";

interface PaymentMethodFieldProps extends Omit<DropdownFieldProps, "options"> {
  extraPaymentMethod?: KeyTextValue;
}

const PaymentMethodFieldBase: React.FC<PaymentMethodFieldProps> = ({
  extraPaymentMethod,
  ...dropDownProps
}) => {
  const {
    billing: { ref }
  } = useStores();

  const paymentMethods = extraPaymentMethod
    ? [extraPaymentMethod, ...ref.paymentMethods.keyTextValues]
    : ref.paymentMethods.keyTextValues;

  return (
    <DropdownField
      {...dataAttribute(DataAttributes.Element, "payment-method-name-dropdown")}
      options={paymentMethods}
      fieldItemStyles={{ root: { flexGrow: 1 } }}
      {...dropDownProps}
    />
  );
};

export const PaymentMethodField = withFetch(
  x => [x.billing.ref.paymentMethods.load()],
  PaymentMethodFieldBase
);
