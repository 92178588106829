import { newGuid } from "@bps/utils";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { mergeProperties } from "@libs/models/model.utils.ts";
import { withNullToUndefined } from "@libs/utils/utils.ts";

/**
 * Sames as Object.freeze, but recursively freezes each property which is an object
 *
 * Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 *
 * @param object object
 * @returns object
 */
export function deepFreeze<T extends object>(object: T): T {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === "object") {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

export function makeActive<R extends RefDataDto<T>, T extends string>(
  items: Array<Omit<R, "isActive">>
) {
  return items.map<R>(x => ({ ...x, isActive: true }) as R);
}

export function patchFromJson<T extends { eTag: string }>(
  source: T,
  patch: Patch<T>
): T {
  mergeProperties(source, withNullToUndefined(patch) as any);
  source.eTag = newGuid();
  return source;
}

export function patchFromJsonNoEtag<T extends {}>(
  source: T,
  patch: Patch<T>
): T {
  mergeProperties(source, withNullToUndefined(patch) as any);
  return source;
}
