export interface ClickedEventDetail {
  eventListElementId?: string;
  capturedScrollPosition?: number;
}

export const scrollToPositionFromClickedEvent = (
  clickedEventDetail: ClickedEventDetail
) => {
  setTimeout(() => {
    if (clickedEventDetail.eventListElementId) {
      const eventDiv = document.getElementById(
        clickedEventDetail.eventListElementId
      );

      if (eventDiv) {
        if (clickedEventDetail.capturedScrollPosition) {
          eventDiv.scrollTop = clickedEventDetail.capturedScrollPosition;
        }
      }
    }
  }, 100);
};
