import { Outlet } from "react-router-dom";

import {
  getScreenSelector,
  ScreenWidthMinXLarge,
  ScrollablePane,
  ScrollbarVisibility,
  Stack
} from "@bps/fluent-ui";

import { SettingsNav } from "./components/SettingsNav.tsx";

const SettingsScreen: React.FC = () => {
  return (
    <ScrollablePane
      styles={{
        root: { height: "100%" },
        contentContainer: { paddingTop: 16, paddingBottom: 24 }
      }}
      scrollbarVisibility={ScrollbarVisibility.always}
    >
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{
          root: {
            width: "100%",
            minHeight: "100%",
            maxWidth: 1500,
            margin: "0 auto",
            height: "auto"
          }
        }}
        tokens={{ childrenGap: 36 }}
      >
        <SettingsNav />
        <Stack
          styles={{
            root: {
              width: 760,
              maxWidth: 1270,
              paddingTop: 10,
              selectors: {
                [getScreenSelector(ScreenWidthMinXLarge, 1500)]: {
                  marginRight: 20
                }
              }
            }
          }}
          grow
        >
          <Outlet />
        </Stack>
      </Stack>
    </ScrollablePane>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default SettingsScreen;
