import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { LocationsSelectField } from "@modules/practice/screens/shared-components/location/LocationsSelectField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";

export const InvoiceLocationSelectField: React.FC = () => {
  const { core, booking } = useStores();
  const fetchUserAvailability = async (userId: string) => {
    if (!userId) return undefined;

    const user = await core.getUser(userId);
    return booking.getUserAvailability(user?.id);
  };

  return (
    <FieldSpy<InvoiceFormValues["userId"], InvoiceFormValues>
      name={invoiceFormNameOf("userId")}
      subscription={{ initial: true, value: true }}
    >
      {(_state, userId) => (
        <DataFetcher
          fetch={() => fetchUserAvailability(userId)}
          refetchId={userId}
        >
          {userAvailability => (
            <LocationsSelectField
              fieldItemStyle={{
                root: { flexGrow: 1, flexBasis: 0, minWidth: 0 }
              }}
              hideClearButton={true}
              name={invoiceFormNameOf("locationId")}
              placeholder="Select location"
              label="Location"
              required
              showIcon={false}
              hideSearchOption
              showAllSelected={false}
              multiSelect={false}
              disabled={
                !userId || userAvailability?.availableOrgUnitIds.length === 1
              }
              onFilter={options =>
                options.filter(
                  option =>
                    userAvailability?.availableOrgUnitIds.includes(option.key)
                )
              }
            />
          )}
        </DataFetcher>
      )}
    </FieldSpy>
  );
};
