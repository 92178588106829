import { RFV_CD_TYPE_CODE_OTHER } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { ConsultDetailDialogValues } from "./ConsultDetailDialog.tsx";

const requiresReasonClinicalDataOtherCondition = (
  val: any,
  values?: ConsultDetailDialogValues
) => !!values?.reasonForVisit?.includes(RFV_CD_TYPE_CODE_OTHER);

export class ReasonForConsultValidator extends Validator<ConsultDetailDialogValues> {
  constructor(core: CoreStore, options: { isReasonForVisitRequired: boolean }) {
    super();
    this.forField(
      "reasonForVisit",
      predicate(() => options.isReasonForVisitRequired, required())
    );
    this.forField("otherText", [
      predicate(requiresReasonClinicalDataOtherCondition, required())
    ]);
    this.forField("practiceLocationId", [
      predicate(() => core.hasMultipleActiveLocations, required())
    ]);
  }
}
