import { Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { PhysitrackCard } from "./components/PhysitrackCard.tsx";
import { PhysitrackProviderCard } from "./components/PhysitrackProviderCard.tsx";
import { IntegrationScreenContext } from "./context/IntegrationScreenContext.ts";
import { IntegrationScreenHelper } from "./context/IntegrationScreenHelper.ts";

const IntegrationScreen: React.FC = () => {
  const root = useStores();
  return (
    <IntegrationScreenContext.Provider
      value={new IntegrationScreenHelper(root)}
    >
      <Stack tokens={{ childrenGap: 24 }}>
        <PhysitrackCard />
        <PhysitrackProviderCard />
      </Stack>
    </IntegrationScreenContext.Provider>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default IntegrationScreen;
