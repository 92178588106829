import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import { CommandBarButton } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { BookingCalendarEventContext } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/BookingCalendarEventContext.tsx";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AppointmentInformationModal } from "../../appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModal.tsx";
import { PersonaRowFormMenuButton } from "./PersonaRowFormMenuButton.tsx";

export const PersonaRowCommandButtons: React.FC = observer(() => {
  const { core, routing } = useStores();

  const { calendarEvent, onShowEdit } = useContext(BookingCalendarEventContext);

  const [showApptInfoModal, setShowApptInfoModal] = useState<boolean>(false);

  const { contact } = calendarEvent;

  /* Demographic Items */
  const getDemographicsContextualMenuItems = () => {
    const contactId = contact && contact?.id;

    const demographicsMenuItems = [
      {
        key: "editDemographics",
        text: "Edit demographics",
        onClick: () => {
          onShowEdit(PatientCardIds.patientHeader)();
        },
        disabled: !core.hasPermissions(Permission.PatientWrite)
      },
      {
        key: "viewDemographics",
        text: "View demographics",
        onClick: () => {
          contactId &&
            routing.push(routes.contacts.contact.path({ id: contactId }));
        },
        disabled: !core.hasPermissions(Permission.PatientRead)
      }
    ];

    return demographicsMenuItems;
  };

  return (
    <>
      <CommandBarButton
        iconProps={{ iconName: "Contact" }}
        menuProps={{
          items: getDemographicsContextualMenuItems()
        }}
      />
      <CommandBarButton
        iconProps={{ iconName: "SearchCalendar" }}
        onClick={() => setShowApptInfoModal(true)}
      />
      <PersonaRowFormMenuButton />
      {contact && (
        <AppointmentInformationModal
          contactId={contact.id}
          patientName={contact.fullName}
          onDismissInfoModal={() => setShowApptInfoModal(false)}
          isHidden={!showApptInfoModal}
        />
      )}
    </>
  );
});
