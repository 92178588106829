import { Spinner, Stack } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AllocationForm } from "../shared-components/allocation-form/components/AllocationForm.tsx";
import { AllocationFormHeader } from "../shared-components/allocation-form/components/AllocationFormHeader.tsx";
import { AllocateToInvoiceFormHelper } from "./context/AllocateToInvoiceFormHelper.tsx";

export const AllocationScreen = () => {
  const fetch = async (root: IRootStore) => {
    const { billing, practice, routing } = root;
    const accountId =
      routing.queryStringParam(routes.accounts.queryKeys.accountId) ??
      undefined;

    const invoiceId = routing.queryStringParam(
      routes.accounts.queryKeys.invoiceId
    );

    const invoice = invoiceId ? await billing.getInvoice(invoiceId) : undefined;

    const accountContactId: string | undefined =
      invoice?.accountId || accountId;

    if (accountContactId) {
      await practice.getContact(accountContactId, {
        includeContactPreferences: true
      });
    }

    const routeState: { invoiceIds?: string } | undefined =
      routing.location.state;

    const helper = new AllocateToInvoiceFormHelper(root, {
      invoice,
      invoiceIds: routeState?.invoiceIds?.split(","),
      accountId: accountContactId
    });
    await helper.initialise(accountContactId);
    return helper;
  };

  return (
    // this Stack used for automation-tests only
    <Stack styles={{ root: { height: "100%" } }}>
      <DataFetcher fetch={fetch} fallback={<Spinner />}>
        {helper => (
          <AllocationForm
            helper={helper}
            header={
              <AllocationFormHeader
                invoice={helper.invoice}
                number={helper.number}
                setShouldOpenPdf={helper.setShouldOpenPdf}
                setShouldSendEmail={helper.setShouldSendEmail}
                setAllocationEmail={helper.setAllocationEmail}
              />
            }
          />
        )}
      </DataFetcher>
    </Stack>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default AllocationScreen;
