import { Heading, LabeledText, LabeledTextGroup, Stack } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";

export const VerbalApprovalDetails = () => {
  const { claimAdjustment } = useClaimAdjustmentContext();
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Heading>Verbal response</Heading>
      <LabeledTextGroup
        styles={{ root: { marginRight: "auto" } }}
        horizontalColumnCount={4}
        gap={24}
      >
        {claimAdjustment.purchaseOrderNumber && (
          <LabeledText noSemicolon label="Purchase order">
            {claimAdjustment.purchaseOrderNumber}
          </LabeledText>
        )}
        {claimAdjustment.responseDate && (
          <LabeledText noSemicolon label="Date">
            {claimAdjustment.responseDate?.toDayDefaultFormat()}
          </LabeledText>
        )}
      </LabeledTextGroup>
    </Stack>
  );
};
