import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { TodaysNotes } from "../notes/TodaysNotes.tsx";
import { EncounterFormHeader } from "./EncounterFormHeader.tsx";

export const nameOf = nameOfFactory<EncounterFormValues>();

export const EncounterFormFields: React.FC = observer(() => {
  const { notification } = useStores();

  const form = useForm();
  const resetTimeIfFuture = (values: EncounterFormValues) => {
    if (!values.startDate || !values.startTime) {
      return;
    }

    const startDateTime = DateTime.fromJSDateAndTime(
      values.startDate,
      values.startTime
    );

    if (startDateTime > DateTime.now()) {
      form.change(nameOf("startDate"), DateTime.jsDateNow());
      form.change(nameOf("startTime"), DateTime.now().toTimeInputFormat());
    }
  };

  const onDatePickerChange = (_value: Date, values: EncounterFormValues) =>
    resetTimeIfFuture(values);

  const onTimePickerChange = (_value: string, values: EncounterFormValues) =>
    resetTimeIfFuture(values);

  return (
    <>
      <EncounterFormHeader />
      <Stack
        grow
        verticalFill
        styles={{ root: { overflowY: "auto" } }}
        tokens={{ childrenGap: 8 }}
      >
        <FieldSpy name={nameOf("startDate")} onChange={onDatePickerChange} />
        <FieldSpy name={nameOf("startTime")} onChange={onTimePickerChange} />
        <FieldSpy
          name={nameOf("confidential")}
          onChange={confidential => {
            if (confidential) {
              notification.warn(
                "The notes are now confidential and won't be accessible for others"
              );
            }
          }}
        />

        <TodaysNotes />
      </Stack>
    </>
  );
});
