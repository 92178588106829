import { LabeledText, LabeledTextGroup } from "@bps/fluent-ui";
import {
  CommTypePreferencesDto,
  OPTOUT,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";

interface ViewPreferencesConsentsProps {
  contactPreference: CommTypePreferencesDto | undefined;
}

export const ViewPreferencesConsents: React.FC<
  ViewPreferencesConsentsProps
> = ({ contactPreference }) => {
  if (!contactPreference) {
    return null;
  }

  const getLabelText = (commType: string | undefined) => {
    switch (commType) {
      case OutboundCommType.ApptReminder:
        return Labels.reminder;
      case OutboundCommType.ApptConfirmation:
        return Labels.confirmation;
      case OutboundCommType.Invoice:
        return Labels.invoice;
      case OutboundCommType.FormNotify:
        return Labels.sentForms;
      default:
        return "";
    }
  };

  return (
    <LabeledTextGroup
      horizontalColumnCount={3}
      styles={{
        root: {
          padding: 8,
          gridTemplateColumns:
            "minmax(0, 1.5fr) minmax(0, 0.5fr) minmax(0, 2fr)"
        }
      }}
    >
      <LabeledText noSemicolon label={Labels.notification} wrap>
        {getLabelText(contactPreference.commTypeCode)}
      </LabeledText>

      <LabeledText noSemicolon label={Labels.method} wrap>
        {contactPreference.contactHasOptedOut
          ? OPTOUT
          : contactPreference.preferredCommChannelTypeCode}
      </LabeledText>

      <LabeledText noSemicolon label={Labels.destination}>
        {contactPreference.contactHasOptedOut
          ? ""
          : contactPreference.preferredCommAddressValue}
      </LabeledText>
    </LabeledTextGroup>
  );
};
