import { FunctionComponent, useState } from "react";
import { useField, useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FontWeights,
  IStackItemStyles,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactPickerFieldProps } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { WithPermissionProps } from "@ui-components/withPerm.tsx";

import { QuickAddContactDialog } from "../quick-add-contact-dialog/QuickAddContactDialog.tsx";
import { Labels } from "../types/labels.enums.types.ts";
import { extractName } from "../utils/contact.utils.ts";

export type ContactPickerFieldQuickAddCombinedProps =
  ContactPickerFieldQuickAddProps &
    Pick<ContactPickerFieldProps, "name" | "label" | "disabled"> & {
      addButtonClicked: React.MutableRefObject<boolean>;
      relatedContactText: React.MutableRefObject<string | undefined>;
    };

export type ContactPickerFieldQuickAddProps = {
  addButtonLabel?: string;
  addModalTitle?: string;
  buttonWrapperStyles?: IStackItemStyles;
  detailedView?: boolean;
  onContactAdded?: (contactId: string) => void;
  showAddByDropdown?: boolean;
  typeToAdd?: ContactType;
} & Pick<WithPermissionProps, "permission">;

export const ContactPickerFieldQuickAdd: FunctionComponent<
  ContactPickerFieldQuickAddCombinedProps
> = ({
  addButtonClicked,
  relatedContactText,
  addButtonLabel,
  addModalTitle,
  buttonWrapperStyles,
  detailedView,
  onContactAdded,
  showAddByDropdown,
  typeToAdd,
  name,
  label,
  disabled,
  permission
}) => {
  const { core } = useStores();
  const theme = useTheme();

  const [contactTypeToAdd, setTypeToAdd] = useState(
    typeToAdd ?? ContactType.Individual
  );

  const [quickAddOpened, setQuickAddOpened] = useState(false);

  const { change } = useForm();
  const {
    input: { value: id }
  } = useField(name, { subscription: { value: true } });

  if (permission && !core.hasPermissions(permission)) {
    return null;
  }

  const onAddContactClicked = (contactType: ContactType | undefined) => {
    if (contactType) {
      setTypeToAdd(contactType);
    }
    setQuickAddOpened(true);
  };

  const handleContactAdded = (contact: Contact) => {
    change(name, contact.id);
    setQuickAddOpened(false);
    relatedContactText.current = undefined;
    if (onContactAdded) {
      onContactAdded(contact.id);
    }
  };

  const onDismiss = () => {
    addButtonClicked.current = false;
    setQuickAddOpened(false);
    relatedContactText.current = undefined;
  };

  const marginTop = label ? 29 : 0;

  return (
    <>
      {!id && !disabled && (
        <Stack.Item styles={buttonWrapperStyles}>
          {showAddByDropdown ? (
            <DefaultButton
              text={Labels.newPerson}
              onMouseDown={() => {
                addButtonClicked.current = true;
              }}
              styles={{
                root: {
                  fontWeight: FontWeights.regular,
                  marginTop,
                  width: "100%"
                }
              }}
              menuProps={{
                onDismiss: () => {
                  addButtonClicked.current = false;
                },
                items: [
                  {
                    key: ContactType.Individual,
                    text: Labels.newPerson,
                    onClick: () => onAddContactClicked(ContactType.Individual)
                  },
                  {
                    key: ContactType.Organisation,
                    text: Labels.newOrganisation,
                    onClick: () => onAddContactClicked(ContactType.Organisation)
                  }
                ]
              }}
            />
          ) : (
            <DefaultButton
              text={addButtonLabel ?? Labels.newPerson}
              styles={{
                root: {
                  borderColor: theme.palette.neutralSecondary,
                  marginTop,
                  width: "100%"
                }
              }}
              onMouseDown={() => {
                addButtonClicked.current = true;
              }}
              onClick={() => onAddContactClicked(undefined)}
              {...dataAttribute(DataAttributes.Element, "add-new-contact")}
            />
          )}
        </Stack.Item>
      )}

      <QuickAddContactDialog
        type={contactTypeToAdd ?? ContactType.Individual}
        hidden={!quickAddOpened}
        onDismiss={onDismiss}
        onContactAdded={handleContactAdded}
        initialValues={extractName(relatedContactText.current)}
        detailedView={detailedView}
        title={addModalTitle}
      />
    </>
  );
};
