import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IInvoiceRow } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsList.tsx";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { WhenNavigate } from "@ui-components/navigation/WhenNavigate.tsx";

export const AccreditedBillingInsurerCell: FunctionComponent<IInvoiceRow> =
  observer(props => {
    if (!props.isFirstItem) {
      return null;
    }

    const path = routes.accounts.account.path({
      id: props.invoice.accountId
    });

    return (
      <ContactFetcher contactId={props.invoice.accountId}>
        {contact => (
          <WhenNavigate permission={Permission.ContactRead} to={path}>
            {contact.name}
          </WhenNavigate>
        )}
      </ContactFetcher>
    );
  });
