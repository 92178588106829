interface AppointmentIconProps {
  key: string;
  text: string;
}

export const appointmentTypeIcons: AppointmentIconProps[] = [
  {
    key: "Clock",
    text: "Standard appt."
  },
  {
    key: "AwayStatus",
    text: "Long appt."
  },
  {
    key: "AddFriend",
    text: "New patient"
  },
  {
    key: "BpScalpel",
    text: "Excision"
  },
  {
    key: "BpDoctor",
    text: "Procedure"
  },
  {
    key: "BpFemale",
    text: "Cervical screening"
  },
  {
    key: "BpSyringe",
    text: "Immunisation"
  },
  {
    key: "BpBaby",
    text: "Antenatal consult"
  },
  {
    key: "Pinned",
    text: "Acupunture"
  },
  {
    key: "AccountActivity",
    text: "Health Assessment"
  },
  {
    key: "BpMedCare",
    text: "Care Plan"
  },
  {
    key: "Savings",
    text: "Insurance medical"
  },
  {
    key: "BpAusFlag",
    text: "DVA medical"
  },
  {
    key: "Lifesaver",
    text: "Diving medical"
  },
  {
    key: "People",
    text: "Meeting"
  },
  {
    key: "BpAmbulance",
    text: "Operation"
  },
  {
    key: "Commitments",
    text: "Assist"
  },
  {
    key: "Home",
    text: "Home consult"
  },
  {
    key: "CityNext2",
    text: "Hospital consult"
  },
  {
    key: "ParkingLocationMirrored",
    text: "Nursing home (RACF) consult"
  },
  {
    key: "Phone",
    text: "Teleconference"
  },
  {
    key: "BpMedAdmin",
    text: "Drug rep."
  },
  {
    key: "FavoriteStar",
    text: "Recall"
  },
  {
    key: "Globe",
    text: "Internet"
  },
  {
    key: "DeveloperTools",
    text: "Workers comp."
  },
  {
    key: "Unknown",
    text: "Other"
  },
  {
    key: "Devices2",
    text: "Telehealth consult"
  },
  {
    key: "Telemarketer",
    text: "Telephone consult"
  }
];
