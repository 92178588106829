import { observer } from "mobx-react-lite";
import React from "react";
import { FormSpy } from "react-final-form";

import { FontSizes, Stack, Text, useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import {
  RECOMMENDED_INACTIVITY_TIMEOUT_THRESHOLD,
  SecurityFormValues
} from "./SecurityForm.types.tsx";
import { useSecurityFormContext } from "./SecurityFormContext.tsx";
import { SecurityFormValidator } from "./SecurityFormValidator.tsx";

const nameOf = nameOfFactory<SecurityFormValues>();

const validator = new SecurityFormValidator();

export const SecurityForm: React.FC = observer(() => {
  const { notification, core } = useStores();

  const {
    initialValues,
    showTimeoutEnabledRiskMessage,
    showTimeoutThresholdRiskMessage,
    inactivityThresholdControlEnabled,
    setShowTimeoutEnabledRiskMessage,
    setShowTimeoutThresholdRiskMessage,
    setInactivityThresholdControlEnabled,
    onSubmit
  } = useSecurityFormContext();

  const theme = useTheme();

  const { formFooter, formFields } = getUserStylesSet(theme);

  return (
    <SubmissionForm<SecurityFormValues>
      formName="practice-security"
      onSubmitSucceeded={() => {
        notification.success("Security settings have been updated.");
      }}
      autoFocus={false}
      onSubmit={onSubmit}
      readOnly={!core.hasPermissions(Permission.TenantSettingWrite)}
      validate={values => validator.validate(values)}
      initialValues={initialValues}
      buttonsProps={{
        disableCancelOnPristine: true,
        submitButtonProps: {
          text:
            showTimeoutEnabledRiskMessage || showTimeoutThresholdRiskMessage
              ? "Accept risk and save"
              : "Save"
        },
        styles: {
          root: formFooter
        }
      }}
    >
      {() => {
        return (
          <Stack
            styles={{
              root: { ...formFields }
            }}
            tokens={{ childrenGap: 8 }}
          >
            <FormSpy<SecurityFormValues>
              subscription={{ values: true }}
              onChange={({ values }) => {
                setInactivityThresholdControlEnabled(
                  values.inactivityTimeoutEnabled
                );

                setShowTimeoutEnabledRiskMessage(
                  initialValues.inactivityTimeoutEnabled &&
                    !values.inactivityTimeoutEnabled
                );

                setShowTimeoutThresholdRiskMessage(
                  values.inactivityTimeoutEnabled &&
                    initialValues.inactivityTimeoutThresholdMinutes !==
                      values.inactivityTimeoutThresholdMinutes &&
                    values.inactivityTimeoutThresholdMinutes !==
                      RECOMMENDED_INACTIVITY_TIMEOUT_THRESHOLD
                );
              }}
            />
            <WhenCountry is={Country.NewZealand}>
              <Stack>
                <Stack>
                  <Text bold>Inactivity timeout</Text>
                  <Text
                    styles={{
                      root: {
                        fontSize: FontSizes.size10,
                        color: theme.palette.neutralSecondary
                      }
                    }}
                  >
                    An extra layer of security to support your compliance with
                    health security standards
                  </Text>
                </Stack>
                <ToggleField
                  onText="Enabled"
                  offText="Disabled"
                  name={nameOf("inactivityTimeoutEnabled")}
                  styles={{ root: { marginTop: 8 } }}
                />
                {showTimeoutEnabledRiskMessage && (
                  <Text
                    styles={{
                      root: {
                        marginBottom: 8,
                        fontSize: FontSizes.size12,
                        color: theme.palette.redDark
                      }
                    }}
                  >
                    You accept the risk of unauthorised access by opting out of
                    the Omni inactivity lock and confirm that an alternate
                    inactivity lock is in place
                  </Text>
                )}
              </Stack>
            </WhenCountry>
            <Stack>
              <Text bold>Inactivity timeout threshold</Text>
              <Text
                styles={{
                  root: {
                    fontSize: FontSizes.size10,
                    color: theme.palette.neutralSecondary
                  }
                }}
              >
                Information security guidelines recommend a maximum of 15
                minutes of user inactivity to activate screen/session locking
              </Text>
            </Stack>
            <ButtonsGroupSingleChoiceField
              name={nameOf("inactivityTimeoutThresholdMinutes")}
              disabled={!inactivityThresholdControlEnabled}
              options={[
                {
                  key: "15",
                  text: "15 mins (Recommended)"
                },
                {
                  key: "30",
                  text: "30 mins"
                },
                {
                  key: "45",
                  text: "45 mins"
                },
                {
                  key: "60",
                  text: "60 mins"
                }
              ]}
            />
            {showTimeoutThresholdRiskMessage && (
              <Text
                styles={{
                  root: {
                    fontSize: FontSizes.size12,
                    color: theme.palette.redDark
                  }
                }}
              >
                By altering the timeframe, you are accepting the risk of
                unauthorised access
              </Text>
            )}
          </Stack>
        );
      }}
    </SubmissionForm>
  );
});
