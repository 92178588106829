import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

import {
  Card,
  Heading,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  useScrollToViewById
} from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ProviderAccContractHelper } from "@modules/settings/screens/users/components/user-provider/context/ProviderAccContractHelper.tsx";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { Provider } from "@stores/practice/models/Provider.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { PageLink } from "@ui-components/PageLink/PageLink.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UsersLabels } from "../shared-components/SettingsLabels.ts";
import { UserContext } from "./components/context/UserContext.tsx";
import { UserHelper } from "./components/context/UserHelper.tsx";
import { ShowUserOnCalendarForm } from "./components/security/ShowUserOnCalendar.tsx";
import { UserAccessForm } from "./components/security/UserAccessForm.tsx";
import { UserSecurityRolesForm } from "./components/security/UserSecurityRolesForm.tsx";
import { UserInformationCard } from "./components/user-info/UserInformationCard.tsx";
import { UserOnlineBookingProfileForm } from "./components/user-online-booking-profile/UserOnlineBookingProfileForm.tsx";
import { AccContractForm } from "./components/user-provider/AccContractForm.tsx";
import { ProviderAccContractContext } from "./components/user-provider/context/ProviderAccContractContext.tsx";
import { UserProviderForm } from "./components/user-provider/UserProviderForm.tsx";
import { UserProfileSection } from "./components/UserBreadcrumbs.tsx";
import { UserScreenCardKeys } from "./UserScreen.types.ts";

interface UserScreenProps {
  user: User;
  provider: Provider | undefined;
  pracOrgUnit: PracOrgUnit | undefined;
  bhbProvider: BhbProvider | undefined;
  userAvailability: UserAvailabilityModel | undefined;
  lastUserAdmin: boolean;
}

const UserScreenBase: React.FC<UserScreenProps> = observer(
  ({
    user,
    provider,
    pracOrgUnit,
    bhbProvider,
    userAvailability,
    lastUserAdmin
  }) => {
    const root = useStores();
    const { core, routing } = root;
    const locationHash = routing.location.hash.replace("#", "");

    const scrollTo = useScrollToViewById();

    useEffect(() => {
      if (locationHash) scrollTo(locationHash);
    }, [locationHash, scrollTo]);

    const userHelper = useRef<UserHelper>(new UserHelper(root)).current;

    const accContractHelper = useRef<ProviderAccContractHelper>(
      new ProviderAccContractHelper(root, user)
    );

    const isAllowedToEdit =
      user.id === core.userId ||
      core.permissions.includes(Permission.UserSettingWrite);

    const messageText = !core.hasPermissions(Permission.SecurityWrite)
      ? "You do not have access to edit this section. Please contact your administrator to update your security settings or business roles"
      : "This is the last user with User Management Administrator security permissions.  Another user must first be granted these permissions";

    return (
      <Stack styles={{ root: { paddingTop: 24 } }} tokens={{ childrenGap: 24 }}>
        <Heading variant="section-heading">{user.fullName}</Heading>
        <UserInformationCard user={user} />
        {user.isProviderClass && provider && (
          <>
            <When
              permission={[Permission.BhbConfigAllowed, Permission.BhbRead]}
            >
              <Card
                iconName="LaptopSelected"
                headingLevel="section-heading"
                heading={UsersLabels.onlineBookingProfile}
                styles={{
                  subComponentStyles: {
                    tile: {
                      content: {
                        padding: 0
                      }
                    }
                  }
                }}
                id={UserProfileSection.OnlineBookingProfile}
              >
                <UserOnlineBookingProfileForm
                  user={user}
                  bhbProvider={bhbProvider}
                  userAvailability={userAvailability}
                />
              </Card>
            </When>
            <Card
              iconName="work"
              headingLevel="section-heading"
              heading={UsersLabels.professionalCredentials}
              styles={{
                subComponentStyles: {
                  tile: {
                    content: {
                      padding: 0
                    }
                  }
                }
              }}
            >
              <UserProviderForm provider={provider} pracOrgUnit={pracOrgUnit} />
            </Card>
          </>
        )}
        {core.userId === user.id && (
          <Card
            iconName="permissions"
            headingLevel="section-heading"
            heading={UsersLabels.accessSignIn}
            styles={{
              subComponentStyles: {
                tile: {
                  content: {
                    padding: 0
                  }
                }
              }
            }}
          >
            <UserAccessForm />
          </Card>
        )}
        <div>
          <Card
            iconName="ReminderPerson"
            headingLevel="section-heading"
            heading={UsersLabels.userRolesAndSecurityPermissions}
            subHeading={
              !core.hasPermissions(Permission.SecurityWrite) ||
              lastUserAdmin ? (
                <MessageBar
                  styles={{ root: { marginBottom: 5 } }}
                  messageBarType={MessageBarType.info}
                >
                  {messageText}
                </MessageBar>
              ) : null
            }
            styles={{
              subComponentStyles: {
                tile: {
                  content: {
                    padding: 0
                  }
                }
              }
            }}
          >
            <UserContext.Provider value={userHelper}>
              <UserSecurityRolesForm
                user={user}
                lastUserAdmin={lastUserAdmin}
              />
            </UserContext.Provider>
          </Card>
        </div>
        {user.isProviderClass && provider && (
          <WhenCountry is={Country.NewZealand}>
            <Card
              iconName="Handwriting"
              headingLevel="section-heading"
              heading={UsersLabels.accContracts}
              styles={{
                subComponentStyles: {
                  tile: {
                    content: {
                      padding: 0
                    }
                  }
                }
              }}
            >
              <ProviderAccContractContext.Provider
                value={accContractHelper.current}
              >
                <AccContractForm
                  user={user}
                  contractTypes={provider.contractTypes}
                />
              </ProviderAccContractContext.Provider>
            </Card>
          </WhenCountry>
        )}
        {isAllowedToEdit && (
          <DataFetcher
            fetch={async () => {
              return user.id === core.userId
                ? core.hasPermissions([Permission.ClinSettingAllowed])
                : await core.checkUserPermissionsFromAPI(user.username, [
                    Permission.ClinSettingAllowed
                  ]);
            }}
            refetchId={user?.eTag}
          >
            {isClinSettingAllowed =>
              isClinSettingAllowed && (
                <PageLink
                  onClick={() =>
                    routing.push(
                      routes.settings.users.userClinicalView.path({
                        id: user.id
                      })
                    )
                  }
                  headingText={UsersLabels.clinicalView}
                  iconName="ContactCard"
                />
              )
            }
          </DataFetcher>
        )}
        {userAvailability && (
          <Card
            id={UserScreenCardKeys.AppointmentSettings}
            iconName="AddFriend"
            headingLevel="section-heading"
            heading={UsersLabels.apptBook}
            styles={{
              subComponentStyles: {
                tile: {
                  content: {
                    padding: 0
                  }
                }
              }
            }}
          >
            <Stack>
              <ShowUserOnCalendarForm
                user={user}
                userAvailabilityModel={userAvailability}
                bhbProvider={bhbProvider}
              />
            </Stack>
          </Card>
        )}
      </Stack>
    );
  }
);

const UserScreenDataFetch: React.FC = () => {
  const getData = async ({
    core,
    practice,
    routing,
    booking,
    userExperience,
    bhb
  }: RootStore): Promise<{
    user: User;
    provider: Provider | undefined;
    pracOrgUnit: PracOrgUnit | undefined;
    bhbProvider: BhbProvider | undefined;
    userAvailability: UserAvailabilityModel | undefined;
    lastUserAdmin: boolean;
  }> => {
    const userId = routing.match(routes.settings.users.user)?.params.id;
    if (!userId) {
      throw new Error("No userId");
    }

    const [user, provider, bhbProvider, userAvailability, pracOrgUnit] =
      await Promise.all([
        core.getUser(userId),
        core.hasPermissions(Permission.ProviderRead)
          ? practice.getProvider(userId, { ignoreCache: true })
          : undefined,
        core.hasPermissions([Permission.BhbConfigAllowed, Permission.BhbRead])
          ? bhb.getProvider(userId, { ignoreCache: true })
          : undefined,
        core.hasPermissions(Permission.ProviderRead) &&
        core.hasPermissions(Permission.BookingScheduleRead)
          ? booking.getUserAvailability(userId)
          : undefined,
        core.location.parentOrgUnitId
          ? practice.getOrgUnit(core.location.parentOrgUnitId)
          : undefined,
        userExperience.getUserSetting(userId),
        core.loadAllUsers()
      ]);

    const lastUserAdmin = await core.lastUserAdmin(user);

    return {
      user,
      provider,
      bhbProvider,
      userAvailability,
      lastUserAdmin,
      pracOrgUnit
    };
  };
  return (
    <DataFetcher fetch={getData} fallback={<Spinner />}>
      {({
        user,
        provider,
        pracOrgUnit,
        bhbProvider,
        userAvailability,
        lastUserAdmin
      }) => (
        <UserScreenBase
          user={user}
          provider={provider}
          pracOrgUnit={pracOrgUnit}
          bhbProvider={bhbProvider}
          userAvailability={userAvailability}
          lastUserAdmin={lastUserAdmin}
        />
      )}
    </DataFetcher>
  );
};

const UserScreen = withFetch(
  x => [
    x.practice.ref.accProviderContractTypes.load(),
    x.bhb.ref.areasOfInterest.load()
  ],
  UserScreenDataFetch
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default UserScreen;
