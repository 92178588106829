import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { DeleteInvestigationDialog } from "../../DeleteInvestigationDialog.tsx";
import { MoveDocumentDialog } from "../../MoveDocumentDialog.tsx";
import { CorrespondencePrint } from "../../patient-summary/CorrespondencePrint.tsx";
import { SidePanelDocumentPreviewer } from "../../SidePanelDocumentPreviewer.tsx";
import { AddEditInvestigationDialog } from "../AddEditInvestigationDialog.tsx";
import { ImagingRequestDialog } from "../imaging-request-dialog/ImagingRequestDialog.tsx";
import { InvestigationSidePanelContext } from "./InvestigationsSidePanelContext.ts";

export const InvestigationsSidePanelDialogs: FunctionComponent = observer(
  () => {
    const {
      isPrinting,
      selectedInvestigation,
      setIsPrinting,
      documentViewerVisible,
      setDocumentViewerVisible,
      clinicalRecord,
      moveDialogVisible,
      handleDismissDetails,
      onMoveToAnotherPatientSucceeded,
      handleOnConflictError,
      isDeleteDialogVisible,
      setIsDeleteDialogVisible,
      confimDeleteReport,
      addEditReportsDialogVisible,
      addEditRequestDialogVisible,
      handleDismissAdEditRequestDialog,
      onPrintClick
    } = useContext(InvestigationSidePanelContext);

    return (
      <>
        {addEditReportsDialogVisible && (
          <AddEditInvestigationDialog
            onDismiss={() => handleDismissDetails(true)}
            clinicalRecord={clinicalRecord}
            editInvestigation={selectedInvestigation}
            userTask={clinicalRecord.patientUserTasks.find(
              task => task.documentId === selectedInvestigation?.id
            )}
          />
        )}
        {addEditRequestDialogVisible && (
          <ImagingRequestDialog
            onDismiss={() => handleDismissAdEditRequestDialog()}
            clinicalRecord={clinicalRecord}
            printImagingRequest={onPrintClick}
          />
        )}
        {isPrinting && selectedInvestigation && selectedInvestigation?.id && (
          <CorrespondencePrint
            completePrinting={() => setIsPrinting(false)}
            clinicalDocument={selectedInvestigation}
            documentTitle={selectedInvestigation.name}
          />
        )}
        {documentViewerVisible && selectedInvestigation && (
          <SidePanelDocumentPreviewer
            setViewerVisible={setDocumentViewerVisible}
            record={selectedInvestigation}
          />
        )}
        {selectedInvestigation && (
          <MoveDocumentDialog
            hidden={!moveDialogVisible}
            onDismiss={() => handleDismissDetails(true)}
            onSubmitSucceeded={onMoveToAnotherPatientSucceeded}
            handleOnConflictError={handleOnConflictError}
            clinicalRecord={clinicalRecord}
            document={selectedInvestigation}
          />
        )}
        <DeleteInvestigationDialog
          hidden={isDeleteDialogVisible}
          documentName={selectedInvestigation?.name}
          onCancel={() => {
            setIsDeleteDialogVisible(false);
          }}
          onConfirm={confimDeleteReport}
        />
      </>
    );
  }
);
