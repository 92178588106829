import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { BillingHeading } from "@modules/billing/screens/shared-components/BillingHeading.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { InvoiceBadge } from "./InvoiceBadge.tsx";
import { InvoiceViewButtons } from "./InvoiceViewButtons.tsx";
import { closeInvoiceOrPaymentPage } from "./utils.ts";

export const InvoiceViewHeader: React.FC = observer(() => {
  const { routing } = useStores();
  const { invoice } = useContext(InvoiceViewContext);

  const onClose = () => {
    closeInvoiceOrPaymentPage(routing);
  };

  return (
    <BillingHeading
      breadcrumbProps={{ transactionNumber: invoice.number }}
      amount={invoice.total}
      badgeOverride={<InvoiceBadge invoice={invoice} />}
      date={invoice.transactionDate}
      buttons={<InvoiceViewButtons onClose={onClose} onSubmit={onClose} />}
    />
  );
});
