import { observable, runInAction } from "mobx";
import { MouseEvent } from "react";

import { compareDatesPredicate, DateTime } from "@bps/utils";
import {
  ClinicalDocumentLite,
  CorrespondenceDirection
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { UploadDocFormLabels } from "@modules/inbox/screens/shared-components/upload-doc-form/UploadDocForm.types.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { getClinicalDocumentStoreString } from "@stores/clinical/utils/clinical.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  ClinicalImageFormValues,
  CorrespondenceFormValue
} from "../../clinical-form/DocumentBatchFormValues.ts";
import { InvestigationFormValues } from "../../investigations/AddInvestigationDialog.types.ts";

export class PatientSummaryHelper {
  constructor(private root: RootStore) {}

  get inbox() {
    return this.root.inbox;
  }

  get correspondence() {
    return this.root.correspondence;
  }

  get core() {
    return this.root.core;
  }

  /**
   * preventDismissOnLostFocus makes callout closing behaviours to be controlled
   */
  preventDismissOnLostFocus = observable.box<boolean>();

  setPreventDismissOnLostFocus = (value: boolean) => {
    runInAction(() => {
      this.preventDismissOnLostFocus.set(value);
    });
  };

  visibleCallout = observable.box<MouseEvent<any> | undefined>();

  setVisibleCallout = (value: MouseEvent<any> | undefined) => {
    runInAction(() => {
      this.visibleCallout.set(value);
    });
  };

  public searchIncomingDocs = async (
    patientId: string,
    fromLastConsultDate: DateTime
  ) => {
    const clinicalDocumentResults: ClinicalDocument[] = [];
    const results: ClinicalDocumentLite[] = [];

    const hasClinViewSecurityRole = this.core.user?.hasClinViewSecurityRole;

    const hasPracWorkflowOrInfoContributorRole =
      this.core.user?.hasPracInfoContributorSecurityRole ||
      this.core.user?.hasPracWorkflowContributorSecurityRole;

    const request = {
      patientId,
      total: true,
      direction: CorrespondenceDirection.In
    };

    const [inboxes, correspondences, investigations, clinicalImages] =
      await Promise.all([
        this.inbox.fetchInboxDocuments({
          patientId
        }),
        this.correspondence.fetchCorrespondence(request),
        this.correspondence.fetchInvestigations(request),
        this.correspondence.fetchClinicalImages(request)
      ]);

    if (!(hasClinViewSecurityRole && !hasPracWorkflowOrInfoContributorRole)) {
      results.push(
        ...inboxes.results.map(x => ({
          dateReceived:
            DateTime.fromJSDate(x.receivedDate) ??
            DateTime.fromISO(x.dateUploaded),
          dateChecked: DateTime.fromISO(x.checkedDate),
          checkedById: x.checkedBy,
          storeIn: UploadDocFormLabels.Inbox,
          fromId: x.fromContactId,
          title: x.name,
          id: x.id,
          patientId: x.patientId,
          extension: x.docExtension,
          documentDetailId: x.documentDetailId,
          containerUri: x.containerUri,
          containerToken: x.containerToken,
          secGroupId: x.secGroupId
        }))
      );
    }
    clinicalDocumentResults.push(...correspondences.results);
    clinicalDocumentResults.push(...investigations.results);
    clinicalDocumentResults.push(...clinicalImages.results);

    results.push(
      ...clinicalDocumentResults.map(x => ({
        dateReceived: x.date,
        dateChecked: DateTime.fromISO(x.dateChecked),
        checkedById: x.checkedBy,
        storeIn: getClinicalDocumentStoreString(x.store ?? ""),
        fromId: x.from,
        title: x.name,
        id: x.id,
        patientId: x.patientId,
        extension: x.extension,
        status: x.status,
        documentDetailId: x.templateId,
        content: x.content,
        secGroupId: x.secGroupId,
        outcome: x.outcome
      }))
    );

    const sortedResults = results.sort((a, b) => {
      if (b.dateReceived && a.dateReceived) {
        return compareDatesPredicate(a.dateReceived, b.dateReceived, true);
      } else return 0;
    });

    if (fromLastConsultDate) {
      return sortedResults.filter(x => {
        if (x.dateReceived) {
          return (
            x.dateReceived.startOf("day") >=
              fromLastConsultDate.startOf("day") &&
            x.outcome === undefined &&
            x.checkedById === undefined
          );
        }
        return undefined;
      });
    }

    return sortedResults;
  };
}

export type EditPAHFormValues =
  | InvestigationFormValues
  | CorrespondenceFormValue
  | ClinicalImageFormValues;
