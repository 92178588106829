import { FontWeights, IButtonStyles, ITheme } from "@bps/fluent-ui";

export const getProviderAccContractStylesSet = (theme: ITheme) => {
  const rootStack = {
    root: {
      borderBottom: "1px solid",
      borderColor: theme.palette.neutralLighterAlt
    }
  };

  const headingStack = {
    root: { lineHeight: 25, marginBottom: 11.5, marginTop: 3.5 }
  };

  const addButtonMenuProps = { container: { width: 193 } };
  const deleteButton: IButtonStyles = {
    root: {
      height: "auto",
      marginLeft: 8,
      border: 0
    }
  };

  const neutralTertiaryColorStyle = {
    root: {
      color: theme.palette.neutralTertiary
    }
  };

  const lineStackRoot = {
    root: {
      borderRadius: 2,
      border: `1px solid ${theme.palette.neutralLight}`
    }
  };

  const lineStackItem = {
    root: {
      paddingBottom: 10,
      justifyContent: "space-between",
      paddingLeft: 8,
      paddingRight: 12
    }
  };

  const providerTypeLineStackItem = {
    root: {
      paddingLeft: 8,
      paddingTop: 8,
      paddingRight: 12,
      fontWeight: FontWeights.semibold
    }
  };

  const lineStackRootError = {
    root: {
      paddingRight: 12,
      borderRadius: 2,
      border: `1px solid ${theme.semanticColors.errorBackground}`,
      "& .ms-Label": { color: `${theme.semanticColors.errorText}` }
    }
  };

  const lineStackError = {
    root: {
      color: `${theme.semanticColors.errorText}`,
      fontSize: theme.fonts.small.fontSize,
      padding: 8,
      paddingTop: 0,
      paddingRight: 0
    }
  };

  const lineStackItemError = {
    root: {
      ...lineStackItem.root,
      paddingRight: 0
    }
  };

  return {
    rootStack,
    headingStack,
    addButtonMenuProps,
    deleteButton,
    neutralTertiaryColorStyle,
    lineStackRoot,
    lineStackItem,
    providerTypeLineStackItem,
    lineStackRootError,
    lineStackError,
    lineStackItemError
  };
};
