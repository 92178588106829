import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import {
  AttendeeTypeEnum,
  CalendarEventAttendeeDto,
  CalendarEventStatus
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { PatientsSelectField } from "@ui-components/form/selects/PatientsSelectField.tsx";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import {
  getActiveAttendees,
  getDifference,
  getSpacesRemaining,
  removeUnsavedAttendees
} from "./GroupAttendeesHelper.ts";

export const GroupAttendeesSelect: React.FunctionComponent = observer(() => {
  const { isAppointmentValidForEdit, patientLabel } = useContext(
    AppointmentFormContext
  );

  const { values } = useFormState<AppointmentFormValues>({
    subscription: { values: true, submitting: true }
  });

  const activeAttendees = getActiveAttendees(values.groupAttendees || []);

  const spacesRemaining = getSpacesRemaining(
    values.maxParticipants ?? 0,
    activeAttendees.length ?? 0
  );

  const form = useForm<AppointmentFormValues>();

  const removeFromAttendeeList = (
    prevAttendeeIds: string[],
    currentAttendeeIds: string[]
  ) => {
    const removeIds = getDifference(prevAttendeeIds, currentAttendeeIds);
    if (removeIds) {
      const groupAttendees = [...(values.groupAttendees || [])];
      const newGroupAttendees = removeUnsavedAttendees(
        removeIds,
        groupAttendees
      );
      form.change("groupAttendees", newGroupAttendees);
    }
  };

  const addToAttendeeList = (
    prevAttendeeIds: string[],
    currentAttendeeIds: string[]
  ) => {
    const attendeeIdsToAdd = getDifference(currentAttendeeIds, prevAttendeeIds);
    let groupAttendees = [...(values.groupAttendees || [])];

    attendeeIdsToAdd.forEach(attendeeId => {
      if (attendeeId && !isAttendeeAlreadySelected(attendeeId)) {
        groupAttendees = removeCancelledAttendee(attendeeId, groupAttendees);
        groupAttendees.push({
          attendeeId,
          type: AttendeeTypeEnum.contact
        });
      }
    });

    form.change("groupAttendees", groupAttendees);
  };

  const isAttendeeAlreadySelected = (attendeeId: string) => {
    return !!(
      values.groupAttendees &&
      values.groupAttendees.findIndex(
        attendee =>
          attendee.attendeeId === attendeeId &&
          attendee.status !== CalendarEventStatus.Cancelled
      ) >= 0
    );
  };

  const removeCancelledAttendee = (
    attendeeId: string,
    groupAttendees: CalendarEventAttendeeDto[]
  ) => {
    return groupAttendees.filter(
      attendee =>
        !(
          attendee.attendeeId === attendeeId &&
          attendee.status === CalendarEventStatus.Cancelled
        )
    );
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <Stack.Item
        styles={{
          root: { flexGrow: 1 }
        }}
      >
        <PatientsSelectField
          name={appointmentFormNameOf("groupAttendeeIds")}
          multiSelect
          placeholder={`Search for ${patientLabel}`}
          label={capitalizeSentence(patientLabel)}
          resultFilterPredicate={contact =>
            !isAttendeeAlreadySelected(contact.id)
          }
          styles={{ root: { width: 207 } }}
          showCountCoin
          showAllSelected
          hideSelectAllButton
          includeInactive
          disabled={isAppointmentValidForEdit}
        />

        <FieldSpy
          name={appointmentFormNameOf("groupAttendeeIds")}
          onChange={(
            currentAttendeeIds: string[],
            _formValues: AppointmentFormValues,
            prevAttendeeIds: string[] = []
          ) => {
            if (currentAttendeeIds.length > prevAttendeeIds.length) {
              if (spacesRemaining > 0) {
                addToAttendeeList(prevAttendeeIds, currentAttendeeIds);
              } else {
                form.change("groupAttendeeIds", [...prevAttendeeIds]);
              }
            } else if (currentAttendeeIds.length < prevAttendeeIds.length) {
              removeFromAttendeeList(prevAttendeeIds, currentAttendeeIds);
            }
          }}
        />
      </Stack.Item>
    </Stack>
  );
});
