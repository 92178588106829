import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { HighlightedText } from "@ui-components/highlighted-text/HighlightedText.tsx";
import { WhenNavigate } from "@ui-components/navigation/WhenNavigate.tsx";

import { IInvoiceRow } from "./AccInvoiceTransactionsList.tsx";

export const TransactionPatientCell: FunctionComponent<IInvoiceRow> = observer(
  props => {
    const { routing } = useStores();

    const searchString = routing.queryStringParam("search") || "";

    if (!props.isFirstItem || !props.invoice.patientId) {
      return null;
    }

    const path = routes.contacts.contact.path({
      id: props.invoice.patientId
    });

    return (
      <ContactFetcher contactId={props.invoice.patientId}>
        {contact => (
          <WhenNavigate permission={Permission.PatientRead} to={path}>
            <Stack horizontal styles={{ root: { whiteSpace: "pre" } }}>
              <HighlightedText
                text={`${contact.nickName || ""} `}
                filter={searchString}
                startOnly
              />
              {contact.firstName &&
                (contact.nickName
                  ? `(${contact.firstName}) `
                  : `${contact.firstName} `)}
              <HighlightedText
                text={contact.lastName || ""}
                filter={searchString}
                startOnly
              />
            </Stack>
          </WhenNavigate>
        )}
      </ContactFetcher>
    );
  }
);
