import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DocumentWriterValues } from "./DocumentWriterValues.ts";

export class DocumentWriterHeaderFormValidator extends Validator<DocumentWriterValues> {
  constructor() {
    super();

    this.forField("documentTitle", [required()]);
    this.forField("documentType", [required()]);
    this.forField("providerId", [required()]);
  }
}
