import { observer } from "mobx-react-lite";
import React from "react";

import {
  GroupedList,
  Heading,
  ThematicButton,
  ThematicButtonData,
  ThematicButtonItem,
  TooltipHost
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PatientRecordScreenHelper } from "@modules/clinical/screens/context/PatientRecordScreenHelper.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { getClinicalDataLastUpdatedDate } from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { SideNavContent } from "./SideNavContent.tsx";

export interface HistoryExaminationProps {
  clinicalRecord: ClinicalRecord;
}

export const HistoryExamination: React.FC<HistoryExaminationProps> = observer(
  ({ clinicalRecord }) => {
    const { core, clinical } = useStores();
    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    const historyItems: (ThematicButtonItem & { date?: DateTime })[] = [];

    historyItems.push({
      key: "HistoryPresentingComplaint",
      name: "Presenting complaint",
      icon: "BpMsPain",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.hofpcConfirmed
      ),
      disabled: isViewOnlyOrDischarged,
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.CurrentInjury
        });
      }
    });
    historyItems.push({
      key: "Clinical Flags",
      name: "Clinical flags",
      icon: "BpClinicalFlags40",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.clinicalFlagsConfirmed
      ),
      disabled: isViewOnlyOrDischarged,
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.ClinicalFlags
        });
      }
    });

    historyItems.push({
      key: "Systems review",
      name: "Systems review",
      icon: "BpSysRew40",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.systemsReviewConfirmed
      ),
      disabled: isViewOnlyOrDischarged,
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.SystemsReview
        });
      }
    });

    if (core.hasPermissions(Permission.MedicationsAndSupplementsAllowed)) {
      historyItems.push({
        key: "MedicationsAndSupplements",
        name: "Medications & supplements",
        icon: "BpMedications40",
        date: getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.medicationsAndSupplementsConfirmed
        ),
        onClick: () => {
          clinical.ui.setPatientClinicalContent({
            type: ClinicalDataType.MedicationsAndSupplements
          });
        }
      });
    }

    const examItems: (ThematicButtonItem & {
      date?: DateTime;
      tooltip?: string;
    })[] = [];

    examItems.push({
      key: "general",
      name: "General",
      icon: "BpGeneral",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.generalExamConfirmed
      ),
      disabled: isViewOnlyOrDischarged,
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.GeneralExamination
        });
      }
    });

    examItems.push({
      key: "body-examination",
      name: "Body",
      icon: "BpFullBody",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.bodyAreaConfirmed
      ),
      disabled: isViewOnlyOrDischarged,
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.BodyExam
        });
      }
    });

    examItems.push({
      key: "dermatomesAndMyotomes",
      name: "D/M/R",
      tooltip: "Dermatomes, myotomes, & reflexes",
      icon: "BpDermatomes40",
      date: getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.dermatomesAndMyotomesConfirmed
      ),
      disabled: isViewOnlyOrDischarged,
      onClick: () => {
        clinical.ui.setPatientClinicalContent({
          type: ClinicalDataType.DermatomesAndMyotomes
        });
      }
    });
    if (core.hasPermissions([Permission.CentralNervousSystemAllowed])) {
      examItems.push({
        key: "centeralNervousSystem",
        name: "CNS",
        tooltip: "Central nervous system",
        icon: "BpCNS40",
        date: getClinicalDataLastUpdatedDate(
          clinicalRecord.clinicalData?.centralNervousSystemConfirmed
        ),
        disabled: isViewOnlyOrDischarged,
        onClick: () => {
          clinical.ui.setPatientClinicalContent({
            type: ClinicalDataType.CentralNervousSystem
          });
        }
      });
    }

    const historyData: ThematicButtonData = {
      key: "history",
      name: "History",
      items: historyItems
    };

    const examinationsData = {
      key: "examinations",
      name: "Examinations",
      items: examItems
    };

    const treeData: ThematicButtonData[] = [];
    if (historyItems?.length > 0) {
      treeData.push(historyData);
    }

    if (examItems?.length > 0) {
      treeData.push(examinationsData);
    }

    return (
      <>
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          History &amp; examinations
        </Heading>
        <SideNavContent>
          <GroupedList
            {...PatientRecordScreenHelper.getGroupedListItems(treeData)}
            compact={true}
            styles={{
              root: {
                selectors: {
                  ".ms-GroupHeader:hover": {
                    background: "inherit"
                  },
                  ".ms-GroupHeader-title": {
                    cursor: "default"
                  },
                  ".ms-GroupHeader-title [class*='header']": {
                    display: "none"
                  }
                },
                minWidth: 375
              },
              group: {
                selectors: {
                  ".ms-List-page": {
                    display: "flex",
                    flexWrap: "wrap"
                  },
                  ".ms-List-page > *": {
                    marginRight: 8,
                    marginBottom: 8
                  }
                }
              }
            }}
            onRenderCell={(
              depth,
              item: ThematicButtonItem & { date?: DateTime; tooltip?: string }
            ) => {
              return (
                <TooltipHost content={item.tooltip}>
                  <ThematicButton
                    icon={item.icon}
                    title={item.name}
                    disabled={item.disabled}
                    subtitle={
                      item.date
                        ? `Last recorded: ${item.date.toDayDefaultFormat()}`
                        : "Not recorded"
                    }
                    onClick={item.onClick}
                  />
                </TooltipHost>
              );
            }}
          />
        </SideNavContent>
      </>
    );
  }
);
