import { getUniqueObjectsByKeys, newGuid } from "@bps/utils";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  AccTerminologyDto,
  ClaimDiagnosisChangeDto,
  ClaimDiagnosisDto,
  ClaimSideChangeDto,
  DiagnosisSides,
  ReferralDiagnosisDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { TerminologyDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ChangeSideItemValuesType } from "@shared-types/acc/change-side-item-values.type.ts";
import { ClaimDiagnosisChangeFormValues } from "@shared-types/acc/claim-diagnosis-change-values.type.ts";
import { ClaimDiagnosisFormValues } from "@shared-types/acc/claim-diagnosis-values.type.ts";
import { ReferralDiagnosisFormValues } from "@shared-types/acc/referral-diagnosis-values.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";

import { ClaimDiagnosisWithPrimary } from "./claim/types/claim.types.ts";

export const emptyReferralDiagnosis: ReferralDiagnosisFormValues = {
  id: newGuid(),
  diagnosisSide: "",
  diagnosisStatus: ""
};

export const getEmptyDiagnosis = (): ClaimDiagnosisFormValues => ({
  id: newGuid()
});

export const getAllDiagnoses = async (claim: Claim) => {
  let diagnosis: ClaimDiagnosisDto[] = [];

  if (claim.referralIn) {
    diagnosis = claim.referralDiagnosis
      ? claim.referralDiagnosis?.map(x => {
          const { diagnosisStatus, ...rest } = x;
          return rest;
        })
      : [];
  } else {
    diagnosis = claim.claimDiagnosis || [];
  }

  diagnosis = getUniqueObjectsByKeys({
    array: diagnosis,
    keys: ["diagnosisSide", "diagnosisCode"]
  });

  return diagnosis;
};

export const getTerminologyKey = (terminologyDto: TerminologyDto): string =>
  `${terminologyDto.code}${terminologyDto.text}`;

export const terminologyFilter =
  (
    allClaimDiagnoses: ClaimDiagnosisFormValues[],
    currentDiagnosis: ClaimDiagnosisFormValues
  ) =>
  (searchResults: AccTerminologyDto[]) => {
    const allDiagnoses = getUniqueObjectsByKeys({
      array: allClaimDiagnoses,
      keys: ["diagnosisSide", "diagnosisCode"]
    });

    return searchResults.filter((searchResult: AccTerminologyDto) => {
      const LEFT_AND_RIGHT_COUNT = 2;

      const previouslySelectedDiagnoses = allDiagnoses.filter(
        (claimDiagnosis: ClaimDiagnosisFormValues) =>
          claimDiagnosis.diagnosisCode === searchResult.code
      );

      //if a previously selected diagnoses is not applicable don't show
      if (
        previouslySelectedDiagnoses.length > 0 &&
        previouslySelectedDiagnoses[0].diagnosisSide ===
          DiagnosisSides.NotApplicable
      )
        return false;

      //if a previously selected diagnoses is a side and not applicable is selected don't show
      if (
        currentDiagnosis.diagnosisSide &&
        currentDiagnosis.diagnosisSide === DiagnosisSides.NotApplicable &&
        previouslySelectedDiagnoses.length > 0 &&
        previouslySelectedDiagnoses[0].diagnosisSide !==
          DiagnosisSides.NotApplicable
      )
        return false;

      //if a side is selected and a previous diagnoses with that side is selected don't show
      if (
        currentDiagnosis &&
        previouslySelectedDiagnoses.find(
          x => x.diagnosisSide === currentDiagnosis.diagnosisSide
        )
      )
        return false;

      //if left and right are selected don't show
      const selectedDiagnosisWithSides = previouslySelectedDiagnoses.filter(
        x =>
          x.diagnosisSide &&
          x.diagnosisSide.toLocaleLowerCase() !== DiagnosisSides.NotApplicable
      );

      return selectedDiagnosisWithSides.length !== LEFT_AND_RIGHT_COUNT;
    });
  };

export const sideFilter =
  (
    diagnosisList: ClaimDiagnosisFormValues[],
    currentDiagnosis: ClaimDiagnosisFormValues,
    showNotApplicableOverride?: boolean
  ) =>
  (value: KeyTextValue) => {
    const allDiagnoses = getUniqueObjectsByKeys({
      array: diagnosisList,
      keys: ["diagnosisSide", "diagnosisCode"]
    });

    const currentIndex = allDiagnoses.findIndex(
      x =>
        x.diagnosisCode?.toLowerCase() ===
          currentDiagnosis.diagnosisCode?.toLowerCase() &&
        x.diagnosisSide?.toLowerCase() ===
          currentDiagnosis.diagnosisSide?.toLowerCase()
    );

    const selectedNotApplicableIndex = currentDiagnosis.diagnosisCode
      ? allDiagnoses.findIndex(x => {
          return (
            x.diagnosisCode?.toLowerCase() ===
              currentDiagnosis.diagnosisCode?.toLowerCase() &&
            x.diagnosisSide?.toLowerCase() ===
              DiagnosisSides.NotApplicable.toLowerCase()
          );
        })
      : -1;

    //if a not applicable side is already selected then don't show
    if (
      selectedNotApplicableIndex > -1 &&
      selectedNotApplicableIndex !== currentIndex &&
      !showNotApplicableOverride
    )
      return false;

    const sidesSelected = currentDiagnosis.diagnosisCode
      ? allDiagnoses.filter(x => {
          return (
            x.diagnosisCode?.toLowerCase() ===
              currentDiagnosis.diagnosisCode?.toLowerCase() &&
            x.diagnosisSide &&
            x.diagnosisSide?.toLowerCase() !==
              DiagnosisSides.NotApplicable.toLowerCase()
          );
        })
      : [];

    const sideSelected = sidesSelected.length > 0;
    const moreThanOneSideSelected = sidesSelected.length > 1;
    const noCurrentSideSelected = !currentDiagnosis.diagnosisSide;

    const currentValueIsNotApplicable =
      value.key.toLowerCase() === DiagnosisSides.NotApplicable.toLowerCase();

    //if the current value is notApplicable but a side is already selected don't show
    if (
      currentValueIsNotApplicable &&
      sideSelected &&
      (noCurrentSideSelected || moreThanOneSideSelected) &&
      !showNotApplicableOverride
    ) {
      return false;
    }

    const previousIndexOfCurrentSide = currentDiagnosis.diagnosisCode
      ? allDiagnoses.findIndex(x => {
          return (
            x.diagnosisCode?.toLowerCase() ===
              currentDiagnosis.diagnosisCode?.toLowerCase() &&
            x.diagnosisSide?.toLowerCase() === value.key.toLowerCase()
          );
        })
      : -1;

    const currentSideNotSelected =
      previousIndexOfCurrentSide === -1 ||
      previousIndexOfCurrentSide === currentIndex;

    //otherwise if a side is selected and it is not the current side show.
    return currentSideNotSelected;
  };

export const isDiagnosisNotEmpty = (diagnosis: ClaimDiagnosisDto): boolean => {
  return !!(diagnosis.terminology || diagnosis.diagnosisSide);
};

const isDiagnosisFull = (diagnosis: ClaimDiagnosisDto): boolean => {
  return !!(diagnosis.terminology && diagnosis.diagnosisSide);
};

export const isDiagnosisChangeNotEmpty = (
  diagnosis: ClaimDiagnosisChangeDto
): boolean => {
  return (
    isDiagnosisNotEmpty(diagnosis.newDiagnosis) ||
    isDiagnosisNotEmpty(diagnosis.oldDiagnosis)
  );
};

export const isDiagnosisChangeFull = (
  diagnosis: ClaimDiagnosisChangeDto
): boolean => {
  return (
    isDiagnosisFull(diagnosis.newDiagnosis) &&
    isDiagnosisFull(diagnosis.oldDiagnosis)
  );
};

export const isSideChangeFull = (diagnosis: ClaimSideChangeDto): boolean => {
  return !!(diagnosis.newSide && isDiagnosisFull(diagnosis.oldDiagnosis));
};

export const isReferralDiagnosisNotEmpty = (
  referralDiagnosis: ReferralDiagnosisDto
): boolean => {
  return !!(
    referralDiagnosis.terminology?.code ||
    referralDiagnosis.diagnosisCodeType ||
    referralDiagnosis.terminology?.text ||
    referralDiagnosis.diagnosisSide ||
    referralDiagnosis.diagnosisStatus
  );
};

export const mergeClaimDiagnosis = (
  primaryDiagnosis: any,
  claimDiagnosis: any[]
) => {
  const updatedClaimDiagnoses = claimDiagnosis.slice();
  updatedClaimDiagnoses.unshift(primaryDiagnosis);
  return updatedClaimDiagnoses;
};

export const splitClaimDiagnosis = (
  claimDiagnosis: ClaimDiagnosisDto[]
): ClaimDiagnosisWithPrimary => {
  const clonedClaimDiagnosis = claimDiagnosis.slice();
  let primaryDiagnosis;
  if (claimDiagnosis.length > 0) {
    primaryDiagnosis = clonedClaimDiagnosis[0];
  }

  clonedClaimDiagnosis.shift();

  return { primaryDiagnosis, claimDiagnosis: clonedClaimDiagnosis };
};

export const mapToClaimDiagnosisDto = (
  claimDiagnosisFormValue: ClaimDiagnosisFormValues
): ClaimDiagnosisDto => {
  let terminology: ClaimDiagnosisDto["terminology"] | undefined;

  if (claimDiagnosisFormValue.diagnosisKey) {
    terminology = {
      code: claimDiagnosisFormValue.diagnosisCode!,
      text: claimDiagnosisFormValue.diagnosisDescription!,
      diagnosisKey: claimDiagnosisFormValue.diagnosisKey!,
      aCCAcceptable: claimDiagnosisFormValue.aCCAcceptable,
      aCC32Acceptable: claimDiagnosisFormValue.aCC32Acceptable,
      readCode: claimDiagnosisFormValue.readCode!
    };
  }

  return {
    diagnosisSide: claimDiagnosisFormValue.diagnosisSide,
    terminology
  };
};

export const mapToClaimDiagnosisFormValues = (
  claimDiagnosisDto: ClaimDiagnosisDto
): ClaimDiagnosisFormValues => ({
  id: newGuid(),
  diagnosisCode: claimDiagnosisDto.terminology?.code,
  diagnosisDescription: claimDiagnosisDto.terminology?.text,
  diagnosisKey: claimDiagnosisDto.terminology?.diagnosisKey,
  aCCAcceptable: claimDiagnosisDto.terminology?.aCCAcceptable,
  aCC32Acceptable: claimDiagnosisDto.terminology?.aCC32Acceptable,
  readCode: claimDiagnosisDto.terminology?.readCode,
  diagnosisSide: claimDiagnosisDto.diagnosisSide
});

export const mapToPrimaryDiagnosisFormValues = (
  claimDiagnosisDto: ClaimDiagnosisDto
): Omit<ClaimDiagnosisFormValues, "id"> => ({
  diagnosisCode: claimDiagnosisDto.terminology?.code,
  diagnosisDescription: claimDiagnosisDto.terminology?.text,
  diagnosisKey: claimDiagnosisDto.terminology?.diagnosisKey,
  aCCAcceptable: claimDiagnosisDto.terminology?.aCCAcceptable,
  aCC32Acceptable: claimDiagnosisDto.terminology?.aCC32Acceptable,
  readCode: claimDiagnosisDto.terminology?.readCode,
  diagnosisSide: claimDiagnosisDto.diagnosisSide,
  codeSystem: undefined,
  version: undefined
});

export const mapToReferalDiagnosisDto = (
  claimDiagnosisFormValue: ReferralDiagnosisFormValues
): ReferralDiagnosisDto => {
  return {
    diagnosisStatus: claimDiagnosisFormValue.diagnosisStatus,
    ...mapToClaimDiagnosisDto(claimDiagnosisFormValue)
  };
};

export const mapToClaimDiagnosisReferralFormValues = (
  claimDiagnosisDto: ReferralDiagnosisDto
): ReferralDiagnosisFormValues => {
  return {
    ...mapToClaimDiagnosisFormValues(claimDiagnosisDto),
    diagnosisStatus: claimDiagnosisDto.diagnosisStatus
  };
};

export const getOldDiagnosisKeyByFormValues = (
  claimDiagnosis: Omit<ClaimDiagnosisFormValues, "id">
) => `${claimDiagnosis.diagnosisCode}${claimDiagnosis?.diagnosisSide}`;

export const getOldDiagnosisKey = (
  claimDiagnosis: Omit<ClaimDiagnosisDto, "id">
) => `${claimDiagnosis.terminology?.code}${claimDiagnosis?.diagnosisSide}`;

export const getDiagnosisSideFromOldDiagnosisKey = (key: string) =>
  key.match(/[a-zA-Z]+/g)?.toString();

export const mapToDiagnosisChangeFormValues = (
  diagnosisChange: ClaimDiagnosisChangeDto
): ClaimDiagnosisChangeFormValues => {
  return {
    oldDiagnosisKey: diagnosisChange.oldDiagnosis
      ? getOldDiagnosisKey(diagnosisChange.oldDiagnosis)
      : undefined,
    requestApproved: diagnosisChange.requestApproved,
    ...mapToClaimDiagnosisFormValues(diagnosisChange.newDiagnosis)
  };
};

export const mapToDiagnosisChangesDto = (
  diagnosisChangesFormValues: ClaimDiagnosisChangeFormValues,
  currentDiagnoses: ClaimDiagnosisDto[]
): ClaimDiagnosisChangeDto => {
  const oldDiagnosis = currentDiagnoses.find(
    x => getOldDiagnosisKey(x) === diagnosisChangesFormValues.oldDiagnosisKey
  );

  return {
    oldDiagnosis: oldDiagnosis || getEmptyDiagnosis(),
    newDiagnosis: mapToClaimDiagnosisDto(diagnosisChangesFormValues),
    requestApproved: diagnosisChangesFormValues.requestApproved
  };
};

export const mapToSideChangesDto = (
  sideChangesFormValues: ChangeSideItemValuesType,
  currentDiagnoses: ClaimDiagnosisDto[]
): ClaimSideChangeDto => {
  const oldDiagnosis = currentDiagnoses.find(
    x => getOldDiagnosisKey(x) === sideChangesFormValues.oldDiagnosisKey
  );

  return {
    oldDiagnosis: oldDiagnosis || getEmptyDiagnosis(),
    newSide: sideChangesFormValues.diagnosisSide!,
    requestApproved: sideChangesFormValues.requestApproved
  };
};

export const mapToChangeSideItemFormValue = (
  sideChange: ClaimSideChangeDto
): ChangeSideItemValuesType => {
  return {
    id: sideChange.oldDiagnosis
      ? getOldDiagnosisKey(sideChange.oldDiagnosis)
      : newGuid(),
    oldDiagnosisKey: sideChange.oldDiagnosis
      ? getOldDiagnosisKey(sideChange.oldDiagnosis)
      : undefined,
    diagnosisCode: sideChange.oldDiagnosis.terminology?.code,
    diagnosisSide: sideChange.newSide,
    requestApproved: sideChange.requestApproved
  };
};

// removes newDiagnosis currentDiagnoses and back the oldDiagnoses
// this is how we can get the original currentDiagnoses for a claimAdjustment
export const getOriginalCurrentDiagnoses = (
  claim: Claim,
  claimAdjustment: ClaimAdjustment
): ClaimDiagnosisDto[] => {
  const currentDiagnoses = claim.currentDiagnoses || [];
  const diagnosisChanges = claimAdjustment.diagnosisChanges || [];
  const sideChanges = claimAdjustment.sideChanges || [];
  const diagnosisAdditions = claimAdjustment.diagnosisAdditions;

  const oldDiagnoses = [
    ...diagnosisChanges.map(x => x.oldDiagnosis),
    ...sideChanges.map(x => x.oldDiagnosis)
  ];

  const newDiagnoses = [
    ...diagnosisAdditions,
    ...diagnosisChanges.map(x => x.newDiagnosis),
    ...sideChanges.map(x => ({ ...x.oldDiagnosis, diagnosisSide: x.newSide }))
  ];

  const withoutNewDiagnoses = removeDiagnoses(currentDiagnoses, newDiagnoses);

  const withOldDiagnoses = [...withoutNewDiagnoses, ...oldDiagnoses];
  return withOldDiagnoses;
};

//map currentDiagnosis to currentDiagnosis picker values
export const getCurrentDiagnosisPickerData = (
  currentDiagnosis: ClaimDiagnosisDto,
  diagnosisSides: KeyTextValue[]
) => {
  return {
    key: getOldDiagnosisKey(currentDiagnosis),
    text: `${formatDiagnosis(currentDiagnosis, diagnosisSides)}`
  };
};

export const formatDiagnosis = (
  currentDiagnosis: ClaimDiagnosisDto,
  diagnosisSides: KeyTextValue[]
) =>
  `${currentDiagnosis.terminology?.text} - ${diagnosisSides.find(
    x => x.key === currentDiagnosis.diagnosisSide
  )?.text}`;

export const removeDiagnoses = (
  allDiagnoses: ClaimDiagnosisDto[],
  diagnosesToRemove: ClaimDiagnosisDto[]
) => {
  return allDiagnoses.filter(
    diagnosis =>
      !diagnosesToRemove.find(
        diagnosisToRemove =>
          getOldDiagnosisKey(diagnosisToRemove) ===
          getOldDiagnosisKey(diagnosis)
      )
  );
};

export const getDiagnosisChangesOverviewText = (
  diagnosisChange: ClaimDiagnosisChangeDto,
  diagnosisSides: KeyTextValue[]
) => {
  return `${formatDiagnosis(
    diagnosisChange.newDiagnosis,
    diagnosisSides
  )} (was ${formatDiagnosis(diagnosisChange.oldDiagnosis, diagnosisSides)})`;
};

export const getSideChangeOverviewText = (
  sideChange: ClaimSideChangeDto,
  diagnosisSides: KeyTextValue[]
) => {
  return `${formatDiagnosis(
    { ...sideChange.oldDiagnosis, diagnosisSide: sideChange.newSide },
    diagnosisSides
  )} (was ${formatDiagnosisSide(sideChange, diagnosisSides)})`;
};

const formatDiagnosisSide = (
  value: ClaimSideChangeDto,
  diagnosisSides: KeyTextValue[]
) =>
  `${
    diagnosisSides
      .find(y => y.key === value.oldDiagnosis.diagnosisSide)
      ?.text?.toLowerCase() || ""
  }`;

export const getClaimTerminologies = (claim: Claim): AccTerminologyDto[] => {
  return claim.claimDiagnosis
    ? (claim.claimDiagnosis
        .map(x => x.terminology)
        .filter(x => x) as AccTerminologyDto[])
    : [];
};

export const getClaimAdjustmentTerminologies = (
  claimAdjustment: ClaimAdjustment
): AccTerminologyDto[] => {
  const terminologies: AccTerminologyDto[] = [];

  const diagnosisChangeTerminologies = claimAdjustment.diagnosisChanges
    .map(x => x.newDiagnosis.terminology)
    .filter(x => x) as AccTerminologyDto[];

  const diagnosisAdditionTerminologies = claimAdjustment.diagnosisAdditions
    .map(x => x.terminology)
    .filter(x => x) as AccTerminologyDto[];

  terminologies.push(...diagnosisAdditionTerminologies);
  terminologies.push(...diagnosisChangeTerminologies);

  return terminologies;
};
