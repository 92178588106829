import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import {
  confirm,
  DefaultButton,
  IContextualMenuItem,
  Stack
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { deleteClaimAdjustmentHandler } from "../../claims/components/ClaimsContextualMenu.tsx";
import { ModalKeys } from "../context/ClaimAdjustmentHelper.types.ts";
import { ClaimAdjustmentModalValues } from "../types/claim-adjustment-modal-values.interface.ts";
import { ClaimAdjustmentDialogForm } from "./ClaimAdjustmentDialogForm.tsx";
import { ClaimAdjustmentResponseDialogForm } from "./ClaimAdjustmentResponseDialogForm.tsx";

export const OverviewButton: React.FC = observer(() => {
  const context = useClaimAdjustmentContext();

  const claimAdjustmentForm = useForm<ClaimAdjustmentFormValues>();

  const {
    claim,
    setOpenModal,
    claimAdjustment,
    saveClaimAdjustment,
    getModalConfirmSettings,
    hasMultiProviderErrorMessage
  } = context;

  const { acc, notification, routing } = useStores();

  const redirectToManagement = () => {
    routing.push({
      pathname: routes.claims.management.edit.path({ id: claim.id })
    });
  };

  const handleOnSubmit = async (values: ClaimAdjustmentModalValues) => {
    if (claimAdjustmentForm) {
      const modalConfirmSettings = getModalConfirmSettings(
        claimAdjustmentForm.getState().dirty
      );
      if (modalConfirmSettings.showConfirm) {
        const result = await confirm({
          cancelButtonProps: {
            text: "Cancel"
          },
          confirmButtonProps: {
            text: "Change ACC32 type"
          },
          dialogContentProps: {
            subText: modalConfirmSettings.subText
          }
        });
        if (result && modalConfirmSettings.saveClaimAdjustmentForm) {
          await claimAdjustmentForm?.submit();
        }
      }
    }
    await saveClaimAdjustment(values, claimAdjustmentForm?.initialize);
  };

  const actionMenuItems: IContextualMenuItem[] = [];
  //do not allow change type for verbally approved claims
  if (claimAdjustment.status?.allowUpdate && !claimAdjustment.verballyApproved)
    actionMenuItems.push({
      key: "type",
      text: "Change 32 type",
      onClick: () => {
        if (claimAdjustmentForm.getState().hasValidationErrors) {
          confirm({
            confirmButtonProps: {
              styles: { root: { display: "none" } }
            },
            cancelButtonProps: {
              text: "Close"
            },
            dialogContentProps: {
              subText:
                "The ACC32 has errors, please correct them before continuing"
            }
          }).then(() => {
            if (claimAdjustmentForm.getState().hasValidationErrors) {
              claimAdjustmentForm.submit();
            }
          });
        } else {
          setOpenModal(ModalKeys.AdjustmentModal);
        }
      }
    });

  if (claimAdjustment.status?.allowResponse)
    actionMenuItems.push({
      key: "response",
      text: "Record response",
      onClick: () => {
        setOpenModal(ModalKeys.AdjustmentResponseModal);
      }
    });

  if (claimAdjustment.status?.allowUpdate)
    actionMenuItems.push({
      key: "delete",
      text: "Delete",
      onClick: () => {
        deleteClaimAdjustmentHandler(
          { acc, notification },
          claimAdjustment,
          redirectToManagement
        );
      }
    });

  return (
    <Stack horizontal>
      <DefaultButton
        text="Actions"
        menuProps={{ items: actionMenuItems }}
        disabled={actionMenuItems.length === 0 || hasMultiProviderErrorMessage}
      />
      <ClaimAdjustmentDialogForm
        onSubmitSucceeded={() => setOpenModal(undefined)}
        onSubmitOverride={handleOnSubmit}
        claim={claim}
      />
      <ClaimAdjustmentResponseDialogForm />
    </Stack>
  );
});
