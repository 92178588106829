import { customFormatDuration, DateTime, TIME_FORMATS } from "@bps/utils";
import {
  AppointmentAttendeeDto,
  AppointmentStatusCode,
  AttendeeTypeEnum,
  CalendarEventAttendeeDto,
  CalendarEventPriority,
  CalendarEventStatus
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

export interface WaitingRoomRecord {
  calendarEventId: string;
  orgUnitId: string;
  patientId: string;
  isUrgent?: boolean;
  providerId?: string;
  lateByTime?: string;
  arrivedTime?: string;
  waitingTime?: string;
  scheduledTime?: string;
  withProviderTime?: string;
  appointmentTypeId?: string;
  appointmentStatus?: AppointmentStatusCode;
}

const toWaitingRoomRecord = (
  calendarEvent: CalendarEvent,
  attendee?: AppointmentAttendeeDto,
  calendarEventAttendee?: CalendarEventAttendeeDto
) => {
  const arrivedTime = getFormattedArrivedTime(
    calendarEvent,
    calendarEventAttendee
  );

  const waitingTime = getWaitingTime(calendarEvent, calendarEventAttendee);
  const lateByTime = getLateByTime(calendarEvent, calendarEventAttendee);
  const appointmentStatus = calendarEvent.appointmentStatus;

  const patientId = attendee ? attendee.attendeeId : calendarEvent.patientId;

  if (!patientId) {
    throw Error("Calendar event is missing patientId");
  }

  const waitingRoom: WaitingRoomRecord = {
    ...calendarEvent,
    calendarEventId: calendarEvent.id,
    orgUnitId: calendarEvent.orgUnitId,
    isUrgent: calendarEvent.priority === CalendarEventPriority.Urgent,
    appointmentStatus:
      attendee && attendee?.attendeeStatus
        ? attendee.attendeeStatus
        : appointmentStatus,
    providerId: calendarEvent.userId,
    appointmentTypeId: calendarEvent.appointmentTypeId,
    scheduledTime: calendarEvent.startTime.toFormat(
      TIME_FORMATS.TIME_FORMAT_WITH_MIDDAY_FULL
    ),
    arrivedTime,
    withProviderTime: calendarEvent.appointment?.withProviderTime
      ? DateTime.fromISO(calendarEvent.appointment.withProviderTime).toFormat(
          TIME_FORMATS.TIME_FORMAT_WITH_MIDDAY_FULL
        )
      : undefined,
    waitingTime,
    lateByTime,
    patientId
  };
  return waitingRoom;
};

export const getWaitingRoomRecords = (
  calendarEvents: CalendarEvent[],
  statusCodes?: string[]
) => {
  const waitingRoomRecords: WaitingRoomRecord[] = [];
  const validTimeAppt = calendarEvents.filter(
    x => x.startTime && x.attendees && x.attendees.length > 1
  );
  for (const calendarEvent of validTimeAppt) {
    const validApptAttendees = calendarEvent.attendees!.filter(
      attendee =>
        //condition for group appointment
        (attendee.type !== AttendeeTypeEnum.user &&
          attendee.attendeeStatus &&
          statusCodes?.includes(attendee.attendeeStatus)) ||
        //condition for single appointment
        (calendarEvent.attendees!.length === 2 &&
          attendee.type !== AttendeeTypeEnum.user &&
          statusCodes?.includes(calendarEvent.appointmentStatus ?? ""))
    );
    for (const attendee of validApptAttendees) {
      const calendarEventAttendee =
        calendarEvent?.attendeesPatientAndContact.find(
          a =>
            a.attendeeId === attendee.attendeeId &&
            a.status === CalendarEventStatus.Confirmed
        );
      if (calendarEventAttendee || calendarEvent.attendees!.length === 2) {
        waitingRoomRecords.push(
          toWaitingRoomRecord(calendarEvent, attendee, calendarEventAttendee)
        );
      }
    }
  }
  return waitingRoomRecords;
};

const getArrivedTime = (
  calendarEvent: CalendarEvent,
  attendee?: CalendarEventAttendeeDto
) => {
  const arrivedTime = attendee?.arrivedTime ?? calendarEvent.arrivedTime;

  return arrivedTime;
};

const getFormattedArrivedTime = (
  calendarEvent: CalendarEvent,
  attendee?: CalendarEventAttendeeDto
) => {
  const arrivedTime = getArrivedTime(calendarEvent, attendee);

  const formattedArrivedTime = arrivedTime
    ? DateTime.fromISO(arrivedTime).toFormat(
        TIME_FORMATS.TIME_FORMAT_WITH_MIDDAY_FULL
      )
    : undefined;

  return formattedArrivedTime;
};

export const contextualTimeDiff = (from: DateTime, to: DateTime) => {
  const HOUR_UNIT_THRESHOLD = 120;
  const diffMins = from.diff(to, "minutes").minutes;
  const diffFinal =
    diffMins > HOUR_UNIT_THRESHOLD ? from.diff(to, "hours").hours : diffMins;

  const finalUnit = diffMins > HOUR_UNIT_THRESHOLD ? "hour" : "min";
  const finalTime = diffFinal < 0 ? 0 : diffFinal;
  return `${finalTime} ${finalUnit}${finalTime === 1 ? "" : "s"}`;
};

export const getWaitingTime = (
  calendarEvent: CalendarEvent,
  attendee?: CalendarEventAttendeeDto
) => {
  const arrivedTimeIso = getArrivedTime(calendarEvent, attendee);
  const arriveTime = DateTime.fromISOOrNow(arrivedTimeIso);

  if (!arriveTime) {
    return;
  }

  const currentTime = DateTime.fromISOOrNow(
    calendarEvent.appointment?.withProviderTime
  );
  return `${customFormatDuration(currentTime.diff(arriveTime), true)}`;
};

const getLateByTime = (
  calendarEvent: CalendarEvent,
  attendee?: CalendarEventAttendeeDto
) => {
  const arrivedTimeIso = getArrivedTime(calendarEvent, attendee);
  const arriveTime = DateTime.fromISO(arrivedTimeIso);

  const scheduledTime = calendarEvent.startTime;
  if (!scheduledTime) {
    return;
  }
  if (arriveTime) {
    return `${customFormatDuration(arriveTime.diff(scheduledTime), true)}`;
  } else {
    return;
  }
};
