import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";

import {
  CenteredBox,
  dataAttribute,
  DataAttributes,
  DetailsRow,
  IColumn,
  IconButton,
  IContextualMenuItem,
  IDetailsRowProps,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Stack,
  Text,
  useFormContext
} from "@bps/fluent-ui";
import { AttendeeTypeEnum } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { WaitingListItemModel } from "@stores/booking/models/WaitingListModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { useWaitingListScreenContext } from "../context/WaitingListScreenContext.tsx";
import { WaitingListItem } from "../WaitingListScreen.types.ts";
import { ContactDetailsLink } from "./ContactDetailsLink.tsx";
import { NoDataTileWithAddWaitingListItem } from "./NoDataTileWithAddWaitingListItem.tsx";
import {
  renderAnyProvider,
  renderAppointmentDateTime,
  renderAppointmentType,
  renderComment,
  renderDuration,
  renderListExpiry,
  renderPatientPrimaryContact,
  renderPriority,
  renderProvider
} from "./utils.ts";
import { WaitingListFilterInternal } from "./WaitingListDetailFilter.tsx";

export const WaitingListDetails: FunctionComponent = observer(() => {
  const {
    fetchWaitingListItems,
    waitingListResults,
    items,
    editWaitingList,
    removeWaitingList
  } = useWaitingListScreenContext();

  const {
    state: { values: filter, dirty }
  } = useFormContext<WaitingListFilterInternal>();

  const { booking, core } = useStores();
  useEffect(() => {
    fetchWaitingListItems(filter);
    return () => {
      waitingListResults.clear();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, booking.recentlyUpdatedWaitingListItemEtag]);

  const iconButtonItems = (item: WaitingListItem): IContextualMenuItem[] => [
    {
      key: "edit",
      text: "Edit",
      onClick: () => {
        editWaitingList(item);
      }
    },
    {
      key: "remove",
      text: "Remove",
      onClick: () => {
        removeWaitingList(item);
      }
    }
  ];

  const columns: IColumn[] = [
    {
      fieldName: "name",
      key: "name",
      isRowHeader: true,
      minWidth: 130,
      maxWidth: 200,
      name: "Name",
      onRender: (record: WaitingListItemModel) => (
        <ContactDetailsLink
          modelId={record?.contact?.model?.id}
          modelName={record?.contact?.model?.name}
        />
      ),
      isMultiline: true
    },
    {
      fieldName: "priority",
      key: "priority",
      minWidth: 60,
      maxWidth: 60,
      name: "Priority",
      isResizable: true,
      onRender: renderPriority,
      isMultiline: true
    },
    {
      fieldName: "provider",
      key: "provider",
      minWidth: 150,
      maxWidth: 200,
      name: "Provider",
      onRender: renderProvider,
      isMultiline: true
    },
    {
      fieldName: "any",
      key: "any",
      minWidth: 60,
      maxWidth: 60,
      name: "Any",
      onRender: renderAnyProvider,
      isMultiline: true
    },

    {
      fieldName: "length",
      key: "length",
      minWidth: 90,
      maxWidth: 90,
      name: "Length",
      onRender: renderDuration,
      isMultiline: true
    },
    {
      fieldName: "type",
      key: "type",
      minWidth: 100,
      maxWidth: 150,
      name: "Type",
      onRender: renderAppointmentType,
      isMultiline: true
    },
    {
      fieldName: "primary",
      key: "primary",
      minWidth: 100,
      maxWidth: 100,
      name: "Primary",
      onRender: renderPatientPrimaryContact
    },
    {
      fieldName: "listexpiry",
      key: "listexpiry",
      minWidth: 80,
      maxWidth: 80,
      name: "List expiry",
      onRender: renderListExpiry,
      isMultiline: true
    },
    {
      fieldName: "appointmentDateTime",
      key: "appointmentDateTime",
      minWidth: 100,
      maxWidth: 100,
      name: "Appointment",
      onRender: renderAppointmentDateTime,
      isMultiline: true
    },
    {
      fieldName: "content",
      key: "content",
      minWidth: 100,
      maxWidth: 200,
      name: "Comment",
      onRender: renderComment,
      isMultiline: true
    }
  ];

  if (core.hasPermissions(Permission.CalendarEventWrite)) {
    columns.unshift({
      fieldName: "actions",
      key: "actions",
      minWidth: 50,
      maxWidth: 50,
      name: "Actions",
      className: RESET_CELLS_PADDING_CLASSNAME,
      onRender: (item: WaitingListItem) => (
        <Stack
          horizontalAlign="center"
          verticalAlign="start"
          grow
          styles={{ root: { padding: "2px 0px" } }}
        >
          <IconButton
            iconProps={{ iconName: "More" }}
            onRenderMenuIcon={() => null}
            menuProps={{ items: iconButtonItems(item) }}
            styles={{
              root: { width: "32px", height: "36px", padding: 0 },
              flexContainer: { width: "32px", height: "36px" }
            }}
          />
        </Stack>
      )
    });
  }

  if (core.hasMultipleActiveLocations) {
    const colIndex = columns.findIndex(x => x.fieldName === "length");

    columns.splice(colIndex, 0, {
      fieldName: "location",
      key: "location",
      minWidth: 150,
      maxWidth: 200,
      name: "Location",
      onRender: (record: WaitingListItemModel) => (
        <Text>{core.getLocationName(record.orgUnitId)}</Text>
      ),
      isMultiline: true
    });
  }

  const renderWaitingListWrapper = (props: IDetailsRowProps | undefined) => {
    if (!props) return null;

    const record = props?.item as WaitingListItem;

    const patient = record.attendees.find(
      x => x.type === AttendeeTypeEnum.contact
    );

    const provider = record.attendees.find(
      x => x.type === AttendeeTypeEnum.user
    );

    return (
      <div
        data-calendar-event-id={record.calenderEventId}
        data-patient-id={patient?.attendeeId}
        data-provider-id={provider?.attendeeId}
      >
        <DetailsRow {...props} />
      </div>
    );
  };

  if (!items.length && !dirty && waitingListResults.fulfilled)
    return <NoDataTileWithAddWaitingListItem />;

  return (
    <div
      style={{ overflowY: "auto", position: "relative", flexGrow: 1 }}
      {...dataAttribute(DataAttributes.Loading, waitingListResults.pending)}
    >
      {items.length ? (
        <ScrollablePane>
          <ShimmeredDetailsList
            items={items}
            onRenderRow={(props: IDetailsRowProps) => {
              return renderWaitingListWrapper(props);
            }}
            stickyHeader
            enableShimmer={waitingListResults.pending}
            errorMessage={waitingListResults.error?.message}
            columns={columns}
          />
        </ScrollablePane>
      ) : (
        <CenteredBox>
          <DefaultNoResultsTile />
        </CenteredBox>
      )}
    </div>
  );
});
