import { useForm } from "react-final-form";

import { Heading } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import {
  AddXrayParametersDialogProps,
  AddXrayParametersFormValues
} from "./AddXrayParameterDialog.types.ts";
import { AddXrayParametersDialogFields } from "./AddXrayParametersDialogFields.tsx";
import { AddXrayParametersDialogValidator } from "./AddXrayParametersDialogValidator.tsx";

export const AddXrayParametersDialog: React.FC<
  AddXrayParametersDialogProps
> = ({ onDismiss, initialValues }) => {
  const form = useForm();
  const { clinical } = useStores();

  const removeEmptyViews = (values: AddXrayParametersFormValues) => {
    return {
      ...values,
      views: values.views?.filter(
        view => !!view?.view && view.view.length !== 0
      )
    };
  };

  return (
    <SubmissionFormDialog<AddXrayParametersFormValues>
      onSubmit={values => {
        form.change("xrayParameters", removeEmptyViews(values));
      }}
      onSubmitSucceeded={onDismiss}
      dialogName="Add X-ray parameters"
      initialValues={initialValues}
      dialogProps={{
        onDismiss,
        minWidth: 900,
        dialogContentProps: {
          title: <Heading variant="modal-heading">Add X-ray parameters</Heading>
        }
      }}
      validate={values => {
        const hasSide = values.region
          ? !!clinical.ref.imagingRegions.get(values.region)?.hasSide
          : false;

        const validator = new AddXrayParametersDialogValidator(hasSide);
        return validator.validate(values);
      }}
      render={() => <AddXrayParametersDialogFields />}
    />
  );
};
