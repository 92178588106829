import React from "react";

import { FontWeights, Stack, Text } from "@bps/fluent-ui";
import { AccBenefitCountDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { NzPracticeAccContractDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { getContractClassWithCount } from "./utils.ts";

export const AccContractList: React.FC<{
  contracts: NzPracticeAccContractDto[];
  accBenefitCounts: AccBenefitCountDto[];
}> = ({ contracts, accBenefitCounts }) => {
  const { practice } = useStores();
  const subscribedContracts = contracts
    .filter(contract => contract.isSubscribed)
    .sort((a, b) =>
      a.accPracticeContractTypeCode.localeCompare(b.accPracticeContractTypeCode)
    );
  return subscribedContracts.length ? (
    <Stack>
      {subscribedContracts.map((contract: NzPracticeAccContractDto) => {
        const code = contract.accPracticeContractTypeCode;
        const baseText = practice.ref.accPracticeContractTypes.get(code)?.text;

        return (
          <Text key={contract.accPracticeContractTypeCode}>
            {getContractClassWithCount(
              baseText,
              contract.accPracticeContractTypeCode,
              accBenefitCounts
            )}
          </Text>
        );
      })}
    </Stack>
  ) : (
    <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
      No contracts
    </Text>
  );
};
