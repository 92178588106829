import { Heading, Stack } from "@bps/fluent-ui";
import { OrgUnitAvailabilityDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { OrgUnitAvailability } from "@stores/booking/models/OrgUnitAvailability.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { SettingsLabels } from "../../../shared-components/SettingsLabels.ts";
import { OrgUnitOpeningHoursDetails } from "./OrgUnitOpeningHoursDetails.tsx";

interface OrgUnitOpeningHoursScreenBaseProps {
  orgUnitAvailability: OrgUnitAvailabilityDto;
}

const OrgUnitOpeningHoursScreenBase: React.FC<
  OrgUnitOpeningHoursScreenBaseProps
> = ({ orgUnitAvailability }) => {
  return (
    <Stack
      styles={{
        root: {
          paddingTop: 26
        }
      }}
      tokens={{ childrenGap: 24 }}
    >
      <Heading variant="section-heading">{SettingsLabels.openingHours}</Heading>
      <OrgUnitOpeningHoursDetails orgUnitAvailability={orgUnitAvailability} />
    </Stack>
  );
};

const OrgUnitOpeningHoursScreenDataFetcher: React.FC = () => {
  return (
    <DataFetcher<OrgUnitAvailability>
      fetch={({ core, booking }) =>
        booking.getOrgUnitAvailability(core.locationId)
      }
    >
      {orgUnitAvailability => (
        <OrgUnitOpeningHoursScreenBase
          orgUnitAvailability={orgUnitAvailability.dto}
        />
      )}
    </DataFetcher>
  );
};

const OrgUnitOpeningHoursScreen = OrgUnitOpeningHoursScreenDataFetcher;

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default OrgUnitOpeningHoursScreen;
