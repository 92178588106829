import { BusinessRole } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { SecurityRolesFormValue } from "./UserSecurityRolesForm.types.ts";

export class UserSecurityRolesFormValidator extends Validator<SecurityRolesFormValue> {
  constructor() {
    super();
    this.forField("businessRoles", [
      required(),
      val => {
        if (
          (val.includes(BusinessRole.HandTherapist) &&
            val.includes(BusinessRole.Physiotherapist) &&
            val.includes(BusinessRole.OccupationalTherapist)) ||
          (val.includes(BusinessRole.HandTherapist) &&
            !val.includes(BusinessRole.Physiotherapist) &&
            !val.includes(BusinessRole.OccupationalTherapist))
        ) {
          return ValidationMessages.handTherapistMustBeAPhysioOrOccTherapist;
        } else return undefined;
      }
    ]);
  }
}
