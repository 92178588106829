import { computed, runInAction } from "mobx";

import { DateTime, unique } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { PatchClaimReviewDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ReviewFormValues } from "@shared-types/acc/claim-review-values.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimReview } from "@stores/acc/models/ClaimReview.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";

import {
  PreloadedSummaryDetailsViewValues,
  SummaryDetailsViewValues
} from "../claim-review.types.ts";
import { ClaimReviewStatus, DocumentStatus } from "../ClaimReviewEnums.ts";

enum ReviewStatus {
  draft = "DFT",
  completed = "COM"
}

export class ClaimReviewHelper {
  constructor(
    private store: IRootStore,
    public claim: Claim,
    public clinicalRecord: ClinicalRecord
  ) {
    this.store.acc.hub.onEntityEvent(
      Entity.AccClaimReview,
      this.updateDocumentStatus
    );
  }

  private updateDocumentStatus = async (message: EntityEventData) => {
    if (message.action === EventAction.Update) {
      if (message.id === this.claimReview?.id) {
        this.claim.loadClaimReview();
      }
    }
  };

  private preloadedFormValues: PreloadedSummaryDetailsViewValues | undefined;

  private claimEncounters: Encounter[] = [];

  private loadClaimEncounters = async () => {
    // claim.patientId should be truthy (as far as I know)
    if (this.claim.patientId) {
      const encounters = await this.store.acc.getClaimEncounters(
        this.claim.id,
        this.claim.patientId
      );
      this.claimEncounters = encounters;
    }
  };

  private getClinicalDirectorId = async () => {
    const currentUserSettings =
      await this.store.userExperience.getCurrentUserSetting();
    return currentUserSettings?.id;
  };

  private getDateOfSurgery = async () => {
    if (!!this.claimEncounters.length) {
      const clinicalData = await this.store.clinical.getEncounterClinicalData({
        encounterId: this.claimEncounters[0].id
      });

      return clinicalData.injury?.dateOfSurgery;
    }
    return undefined;
  };

  private getClaimAppointmentProviders = () => {
    return unique(this.claimEncounters.map(x => x.userId));
  };

  private patchClaimReview = async (
    values: ReviewFormValues
  ): Promise<ClaimReview | undefined> => {
    if (this.claimReview) {
      const { dateOfInjury, dateOfSurgery, ...summaryViewValues } =
        this.getSummaryViewValues();

      const patchDto: PatchClaimReviewDto = {
        id: this.claimReview.id,
        eTag: this.claimReview.eTag,
        documentStatus: this.renderPdf
          ? DocumentStatus.pending
          : this.claimReview.documentStatus,
        ...values,
        ...summaryViewValues,
        dateOfInjury: dateOfInjury?.toISODate(),
        dateOfSurgery: dateOfSurgery?.toISODate()
      };

      //patch
      const claimReviewDto = await this.store.acc.patchClaimReview(patchDto);
      return new ClaimReview(claimReviewDto);
    }
    return undefined;
  };

  private addClaimReview = async (
    values: ReviewFormValues
  ): Promise<ClaimReview> => {
    const summaryViewValues = this.getSummaryViewValues();

    const claimReviewDto = await this.store.acc.addClaimReview({
      documentStatus: this.renderPdf ? DocumentStatus.pending : undefined,
      ...summaryViewValues,
      ...values,
      statusCode: ClaimReviewStatus.draft,
      dateOfInjury: summaryViewValues.dateOfInjury?.toISODate(),
      dateOfSurgery: summaryViewValues.dateOfSurgery?.toISODate()
    });
    return new ClaimReview(claimReviewDto);
  };

  public renderPdf = false;

  public addClaimReviewDocumentRender = async () => {
    if (this.claimReview) {
      const encounterId = this.clinicalRecord.openEncounter!.id;
      await this.store.acc.addClaimReviewDocumentRender(
        this.claimReview.id,
        encounterId
      );

      this.store.notification.success("PDF has been saved.");
    }
  };

  @computed
  public get claimReview() {
    return this.claim.claimReview;
  }

  public preloadSummaryViewValues = async () => {
    await this.loadClaimEncounters();
    const [clinicalDirectorId, dateOfSurgery] = await Promise.all([
      this.getClinicalDirectorId(),
      this.getDateOfSurgery()
    ]);

    const providers = this.getClaimAppointmentProviders();

    if (clinicalDirectorId) {
      runInAction(() => {
        this.preloadedFormValues = {
          clinicalDirectorId,
          dateOfSurgery,
          providers
        };
      });
    }
  };

  public getSummaryViewValues = (): SummaryDetailsViewValues => {
    return {
      claimId: this.claim.id,
      patientId: this.claim.patientId!,
      providers: this.preloadedFormValues?.providers ?? [],
      providerType: this.store.practice.ref.accProviderTypes.keyTextValues.find(
        x => x.key === this.claim.providerTypeCode
      )?.text,
      claimNumber: this.claim.claimNumber,
      totalVisits: this.claimEncounters.length,
      dateOfInjury: this.claim.accidentDate,
      dateOfSurgery: DateTime.fromISO(this.preloadedFormValues?.dateOfSurgery),
      initialDiagnoses: this.claim.referralIn
        ? this.claim.referralDiagnosis ?? []
        : this.claim.claimDiagnosis ?? [],
      currentDiagnoses: this.claim.currentDiagnoses
    };
  };

  public getInitialValues = (): ReviewFormValues => {
    return {
      clinicalDirectorId: this.claimReview
        ? this.claimReview?.clinicalDirectorId
        : this.preloadedFormValues?.clinicalDirectorId ?? "",
      mechanismOfInjury: this.claimReview?.mechanismOfInjury,
      reviewInjuryAndDiagnosis: this.claimReview?.reviewInjuryAndDiagnosis,
      statementOnCausation: this.claimReview?.statementOnCausation,
      statementOnCausationDetails:
        this.claimReview?.statementOnCausationDetails,
      reviewOfCurrentTreatment: this.claimReview?.reviewOfCurrentTreatment,
      documentationOfAnyRecommendationsAndActions:
        this.claimReview?.documentationOfAnyRecommendationsAndActions,
      liaisonWithProviderUndertaken:
        this.claimReview?.liaisonWithProviderUndertaken,
      liaisonWithProviderUndertakenDetails:
        this.claimReview?.liaisonWithProviderUndertakenDetails
    };
  };

  public saveClaimReview = async (values: ReviewFormValues) => {
    let claimReview: ClaimReview | undefined;
    if (this.claimReview) {
      claimReview = await this.patchClaimReview(values);
    } else {
      claimReview = await this.addClaimReview(values);
    }
    this.claim.setClaimReview(claimReview);
    const eTag = this.clinicalRecord.clinicalData?.claimReview?.eTag;

    const user = await this.store.core.getUser(values.clinicalDirectorId);

    await this.clinicalRecord.saveClinicalData({
      claimReview: {
        eTag,
        reviewStatus: this.renderPdf
          ? ReviewStatus.completed
          : ReviewStatus.draft,
        claimNumber: this.claim.claimNumber,
        provider: user.fullName,
        providerType:
          this.store.practice.ref.accProviderTypes.keyTextValues.find(
            x => x.key === this.claim.providerTypeCode
          )?.text
      }
    });
    if (this.renderPdf) {
      await this.addClaimReviewDocumentRender();
      this.renderPdf = false;
    }
  };

  public deleteClaimReview = async (onDeleteSucceeded?: () => void) => {
    if (this.claimReview?.id) {
      //delete
      this.store.acc.deleteClaimReview(this.claimReview.id);
      this.claim.setClaimReview(undefined);
    }
    onDeleteSucceeded && onDeleteSucceeded();
  };

  @computed get missingClaimData(): string[] {
    const headings: string[] = [];

    const summaryFormValues = this.getSummaryViewValues();

    if (!summaryFormValues.providers.length) {
      headings.push("providers");
    }

    if (!summaryFormValues.claimNumber) {
      headings.push("claim number");
    }

    if (!summaryFormValues.dateOfInjury) {
      headings.push("date of injury");
    }

    if (!summaryFormValues.initialDiagnoses.length) {
      headings.push("initial diagnoses");
    }

    if (!summaryFormValues.currentDiagnoses.length) {
      headings.push("current diagnoses");
    }

    return headings;
  }

  @computed get formIsDisabled() {
    return this.missingClaimData.length > 0;
  }
}
