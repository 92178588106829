import { FontSizes, FontWeights } from "@bps/fluent-ui";

export const collapseIconStyles = {
  root: { height: "36px" },
  flexContainer: { height: "36px" },
  menuIcon: { fontSize: FontSizes.size14 },
  rootDisabled: {
    backgroundColor: "transparent"
  }
};

export const textBoldStyles = {
  root: {
    fontWeight: FontWeights.semibold
  }
};

export const contextMenuStyles = {
  root: { height: "36px", marginTop: 6, marginBottom: 4 },
  flexContainer: { height: "36px" },
  menuIcon: { fontSize: FontSizes.size16 },
  rootDisabled: {
    backgroundColor: "transparent"
  }
};

export const subMarginStyle = {
  root: {
    marginTop: 8,
    marginBottom: 4,
    paddingLeft: 75,
    paddingRight: 14
  }
};
