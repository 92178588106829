import { observer } from "mobx-react-lite";
import { stringify } from "query-string";
import { FC, useContext } from "react";

import {
  confirm,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  DirectionalHint,
  SplitButtonProps,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { AccScheduleTransactionStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  PromiseObservableButton,
  PromiseObservableContextualMenuItem
} from "@ui-components/PromiseObservableButton/PromiseObservableButton.tsx";

import { InvoiceFormButtons } from "./InvoiceForm.types.tsx";

interface InvoiceViewPrimaryButtonProps {
  isBilledToAcc?: boolean;
}

export const INVOICE_SPLIT_BUTTON_ID = "invoice-split-button";
const splitButtonProps: SplitButtonProps["splitButtonMenuProps"] = {
  id: INVOICE_SPLIT_BUTTON_ID,
  ...dataAttribute(DataAttributes.Element, INVOICE_SPLIT_BUTTON_ID)
};

export const InvoiceViewPrimaryButton: FC<InvoiceViewPrimaryButtonProps> =
  observer(({ isBilledToAcc }) => {
    const theme = useTheme();
    const { core, routing } = useStores();

    const {
      accountId,
      handleOnAdjustClick,
      handleOnCancelClick,
      invoice,
      handleWriteOffClick,
      credit
    } = useContext(InvoiceViewContext);

    const transactionStatus = invoice.accSchedule?.transactionStatus;

    const isStatusCancelled =
      transactionStatus === AccScheduleTransactionStatuses.Cancelled;

    const isDisableAdjust =
      transactionStatus === AccScheduleTransactionStatuses.Pending ||
      transactionStatus === AccScheduleTransactionStatuses.Queued ||
      transactionStatus === AccScheduleTransactionStatuses.InException ||
      transactionStatus === AccScheduleTransactionStatuses.Incomplete ||
      transactionStatus === AccScheduleTransactionStatuses.Invalid ||
      transactionStatus ===
        AccScheduleTransactionStatuses.CompleteAndAwaitingValidation ||
      transactionStatus ===
        AccScheduleTransactionStatuses.AwaitingAuthorisation ||
      transactionStatus === AccScheduleTransactionStatuses.Authorised;

    const calculateDisabled = () => {
      if (
        invoice.hasACCComment &&
        invoice.owing &&
        invoice.owing > 0 &&
        (transactionStatus ===
          AccScheduleTransactionStatuses.AwaitingAuthorisation ||
          transactionStatus === AccScheduleTransactionStatuses.PartiallyPaid)
      ) {
        return false;
      }
      return (
        transactionStatus === AccScheduleTransactionStatuses.Paid ||
        isDisableAdjust ||
        invoice.paid
      );
    };

    const items: PromiseObservableContextualMenuItem[] = [
      {
        key: InvoiceFormButtons.adjust as string,
        id: "adjust-invoice",
        text: InvoiceFormButtons.adjust,
        onClick: handleOnAdjustClick,
        disabled: invoice.isOwingReduced || !!invoice.credited,
        data: {
          permission: Permission.InvoiceAdjust,
          filter: isDisableAdjust
        }
      },
      {
        key: InvoiceFormButtons.cancelInvoice as string,
        id: "cancel-invoice",
        text: InvoiceFormButtons.cancelInvoice,
        onClick: () => handleOnCancelClick(true),
        disabled: calculateDisabled(),
        data: {
          permission: Permission.InvoiceCancel
        }
      },

      {
        key: InvoiceFormButtons.writeOff as string,
        id: "write-off",
        text: InvoiceFormButtons.writeOff,
        disabled: invoice.isNotOwing,
        onClick: handleWriteOffClick,
        data: {
          permission: [Permission.WriteOffAllowed, Permission.WriteOffCreate]
        }
      },
      {
        key: InvoiceFormButtons.credit as string,
        id: "credit",
        text: InvoiceFormButtons.credit,
        onClick: credit,
        disabled: !!invoice.credited || !!invoice.writtenOff,
        data: {
          permission: [Permission.CreditAllowed, Permission.CreditCreate]
        }
      }
    ].filter(i => core.hasPermissions(i.data.permission) && !i.data.filter);

    if (invoice.isNotOwing) {
      const disabled = items.every(item => item.disabled);
      return (
        <TooltipHost
          directionalHint={DirectionalHint.topRightEdge}
          content="Paid invoices cannot be adjusted or cancelled"
        >
          <PromiseObservableButton
            primary
            {...dataAttribute(DataAttributes.Element, "paid-invoice")}
            text={InvoiceFormButtons.paid}
            iconProps={{
              iconName: "SkypeCircleCheck",
              styles: {
                root: {
                  color: theme.semanticColors.primaryButtonTextDisabled
                }
              }
            }}
            disableContextMenuButton={disabled}
            disabled
            items={items}
            splitButtonMenuProps={splitButtonProps}
          />
        </TooltipHost>
      );
    }

    if (isStatusCancelled) {
      return (
        <DefaultButton
          {...dataAttribute(DataAttributes.Element, "close-invoice")}
          text={InvoiceFormButtons.cancelInvoice}
          onClick={() => handleOnCancelClick(true)}
        />
      );
    }

    const handlePayment = () => {
      let navigateAction = routing.pushWithFromQuery;
      if (routing.match(routes.accounts.invoices.invoice)) {
        navigateAction = routing.replaceRetainingState;
      }
      navigateAction({
        pathname: routes.accounts.allocations.new.pattern,
        search: stringify({
          invoiceId: invoice.id,
          accountId
        })
      });
    };

    const confirmManualPayment = async () => {
      const isConfirmed = await confirm({
        dialogContentProps: {
          title: "Create manual payment?",
          subText:
            "This is not recommended as your practice is set up to receive ACC payments automatically"
        },
        confirmButtonProps: {
          text: "Continue to payment"
        },
        cancelButtonProps: {
          text: "Cancel"
        }
      });
      if (isConfirmed) {
        handlePayment();
      }
    };

    return (
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <PromiseObservableButton
          primary
          {...dataAttribute(DataAttributes.Element, "pay-invoice")}
          text={InvoiceFormButtons.pay}
          iconProps={{ iconName: "Money" }}
          onClick={
            isBilledToAcc &&
            core.hasPermissions(Permission.AccPaymentProcessingPrerel)
              ? confirmManualPayment
              : handlePayment
          }
          items={items}
          splitButtonMenuProps={splitButtonProps}
          disabled={!core.hasPermissions(Permission.PaymentCreate)}
        />
      </Stack>
    );
  });
