import { FormApi } from "final-form";
// eslint-disable-next-line import/extensions
import isEqual from "lodash/isEqual";

import { Stack } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  WorkHistoryConfirmedClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";

import { StashedClinicalDataFormSpy } from "../../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { WorkHistoryFormValues } from "./WorkHistoryValues.ts";

interface WorkHistoryFormProps {
  clinicalRecord: ClinicalRecord;
}

const workHistoryFormConfirmed = (
  openEncounter: Encounter | undefined,
  workHistoryConfirmed: WorkHistoryConfirmedClinicalDataDto | undefined
) => {
  return (
    openEncounter &&
    workHistoryConfirmed?.createLog?.createdEncounterId === openEncounter?.id
  );
};

const WorkHistoryFormComponent: React.FunctionComponent<
  WorkHistoryFormProps
> = ({ clinicalRecord }) => {
  const { stashedClinicalData, id, openEncounter, saveClinicalData, patient } =
    clinicalRecord;

  const hasBeenReset =
    !!clinicalRecord.stashedClinicalData?.haveBeenResetForms.get(
      ClinicalDataType.WorkHistory
    );

  const currentWorkHistory = hasBeenReset
    ? clinicalRecord.clinicalData?.workHistory
    : stashedClinicalData?.workHistory;

  const currentPatientDemographicUpdate = hasBeenReset
    ? clinicalRecord.clinicalData?.patientDemographicUpdate
    : stashedClinicalData?.patientDemographicUpdate;

  const currentWorkHistoryConfirmed =
    clinicalRecord.clinicalData?.workHistoryConfirmed;

  const { clinical, notification } = useStores();
  const { isViewOnly } = usePatientRecordScreenContext();

  const onCancel = () => {
    clinical.ui.closePatientRecordContentForm(id, ClinicalDataType.WorkHistory);
  };

  const onReset = () => {
    onCancel();
    clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
      ClinicalDataType.WorkHistory,
      true
    );
  };

  const initialValuesFromDto: WorkHistoryFormValues = {
    workHistory: { workType: currentWorkHistory?.workType },
    patientDemographic: {
      occupation:
        !!clinicalRecord.clinicalData?.patientDemographicUpdate?.occupation ||
        !!currentPatientDemographicUpdate?.occupation
          ? currentPatientDemographicUpdate?.occupation
          : patient?.occupation
    },
    workHistoryConfirmed: {
      confirmed: workHistoryFormConfirmed(
        openEncounter,
        currentWorkHistoryConfirmed
      )
    }
  };

  const initialValues = initialValuesFromDto;

  const getWorkHistoryData = (values: WorkHistoryFormValues) => {
    const clinicalData: EncounterClinicalDataDto = {};

    if (!isEqual(values.workHistory, initialValuesFromDto.workHistory)) {
      clinicalData.workHistory = {
        eTag: stashedClinicalData?.originalDto?.workHistory?.eTag,
        workType: values.workHistory.workType
      };
    }

    if (
      !isEqual(
        values.patientDemographic,
        initialValuesFromDto.patientDemographic
      ) ||
      clinicalRecord.clinicalData?.patientDemographicUpdate?.occupation !==
        values.patientDemographic?.occupation
    ) {
      clinicalData.patientDemographicUpdate = {
        ...currentPatientDemographicUpdate,
        eTag: stashedClinicalData?.originalDto?.patientDemographicUpdate?.eTag,
        occupation: values.patientDemographic?.occupation
      };
    }

    clinicalData.workHistoryConfirmed = {
      eTag: stashedClinicalData?.originalDto?.workHistoryConfirmed?.eTag,
      confirmed: true
    };

    return clinicalData;
  };

  const submitData = async (values: WorkHistoryFormValues) => {
    const clinicalData = getWorkHistoryData(values);
    await saveClinicalData(clinicalData);
  };

  const getOccupations = () =>
    clinical.ref.occupations.values.map(({ code, text }) => ({
      key: code,
      name: text
    }));

  const onSubmitSucceeded = (
    values: WorkHistoryFormValues,
    form: FormApi<WorkHistoryFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.WorkHistory
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onReset();
    }
  };

  const getValues = (stashedData?: StashedEncounterClinicalData) => {
    const hasBeenReset = !!stashedData?.haveBeenResetForms.get(
      ClinicalDataType.WorkHistory
    );

    const dirty = stashedData?.dirtyWorkHistoryForm && !hasBeenReset;

    const initialEncounter =
      !currentWorkHistory &&
      !currentPatientDemographicUpdate?.occupation &&
      !patient?.occupation;

    const workHistoryFormAlreadyConfirmed =
      workHistoryFormConfirmed(
        openEncounter,
        stashedData?.originalDto?.workHistoryConfirmed
      ) ?? false;

    return { dirty, workHistoryFormAlreadyConfirmed, initialEncounter };
  };

  const getButtonText = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, workHistoryFormAlreadyConfirmed, initialEncounter } =
      getValues(stashedData);

    const submissionButtonText =
      workHistoryFormAlreadyConfirmed ||
      initialEncounter ||
      (!workHistoryFormAlreadyConfirmed && dirty)
        ? "Save"
        : "Confirm";

    return submissionButtonText;
  };

  const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, workHistoryFormAlreadyConfirmed, initialEncounter } =
      getValues(stashedData);

    return (initialEncounter || workHistoryFormAlreadyConfirmed) && !dirty;
  };

  return (
    <ClinicalSubmissionForm<WorkHistoryFormValues>
      formName="work-history"
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues}
      readOnly={isViewOnly}
      disableRoutePrompt
      heading="Work history"
      onCancel={onReset}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtyWorkHistoryForm &&
        !hasBeenReset
      }
      disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
      submitButtonTextCondition={stashedData => getButtonText(stashedData)}
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.workHistoryConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.workHistoryConfirmed
      )}
      hideSubmit={isViewOnly}
      noGap
      hasSeparator
    >
      <Stack tokens={{ childrenGap: 8, maxWidth: 630 }}>
        <StashedClinicalDataFormSpy<WorkHistoryFormValues>
          clinicalRecord={clinicalRecord}
          getData={getWorkHistoryData}
          areasToObserve={{
            workHistory: [ClinicalDataType.WorkHistory],
            patientDemographicUpdate: [ClinicalDataType.WorkHistory]
          }}
          withHasBeenResetFormCheck={ClinicalDataType.WorkHistory}
          syncWithAcc45
        />
        <Fieldset>
          <StaticPickerField
            name="patientDemographic.occupation"
            label="Occupation"
            fetchDataSource={getOccupations}
            fieldItemStyles={{
              root: { flexGrow: 1, flexBasis: 0 }
            }}
            inputProps={{
              placeholder: "Search for an occupation"
            }}
          />

          <DropdownField
            name="workHistory.workType"
            label="Type of work"
            options={clinical.ref.workTypes.keyTextValues}
            placeholder="e.g. Sedentary"
          />
        </Fieldset>
      </Stack>
    </ClinicalSubmissionForm>
  );
};

export const WorkHistoryForm = withFetch(
  x => [
    x.clinical.ref.occupations.load(),
    x.acc.ref.occupations.load(),
    x.clinical.ref.workTypes.load()
  ],
  WorkHistoryFormComponent
);
