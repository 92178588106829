import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  OverflowSet,
  SplitButton,
  SplitButtonProps,
  TooltipHost,
  useScreenSize
} from "@bps/fluent-ui";
import { AppointmentStartingPoints } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

export const BookingCalendarScreenCommands: FunctionComponent = observer(() => {
  const { core } = useStores();
  const screenSize = useScreenSize();
  const { startDate, showCalendarEventDialog } =
    useBookingCalendarScreenContext();

  const SCREEN_LARGE = 1830;
  const SCREEN_MED = 1575;

  const getEventList = () => {
    const eventList = [
      {
        text: "Add meeting",
        key: "addMeeting",
        iconProps: { iconName: "Add" },
        onClick: () => {
          showCalendarEventDialog({
            type: CalendarEventType.Meeting
          });
        }
      },
      {
        text: "Add unavailable",
        key: "addUnavailable",
        iconProps: { iconName: "Add" },
        onClick: () => {
          showCalendarEventDialog({
            type: CalendarEventType.Unavailable
          });
        }
      }
    ];

    if (core.hasPermissions([Permission.GroupAppointmentsAllowed])) {
      eventList.unshift({
        text: "Add to group appt",
        key: "addGroupAttp",
        iconProps: { iconName: "Add" },
        onClick: () => {
          showCalendarEventDialog({
            showAddToGroupApptDialog: true
          });
        }
      });
    }

    return eventList;
  };

  let items: Array<
    SplitButtonProps & {
      key: string;
      permission?: string;
      toolTipText?: string;
    }
  > = [
    {
      primary: true,
      key: "addAppointment",
      text: screenSize.width < SCREEN_LARGE ? "Appt" : "Add appointment",
      permission: Permission.CalendarEventWrite,
      items: getEventList(),
      iconProps: { iconName: "Add" },
      onClick: () => {
        showCalendarEventDialog(
          {
            type: CalendarEventType.Appointment,
            initialValues: {
              startDate: startDate.toJSDate(),
              expiryDate: startDate.toJSDate()
            }
          },
          AppointmentStartingPoints.CalendarAddAppointmentButton
        );
      }
    }
  ];

  const actionItems: Array<
    SplitButtonProps & { key: string; permission?: string }
  > = [
    {
      primary: true,
      // ⚠️ to prevent warning in unit tests, onClick is required if split is true. Ilya S.
      onClick: undefined,
      key: "actions",
      text: "Actions",
      permission: Permission.CalendarEventWrite,
      items: [
        {
          text: "Add appointment",
          key: "addAppointment",
          permission: Permission.CalendarEventWrite,
          iconProps: { iconName: "Add" },
          onClick: () =>
            showCalendarEventDialog(
              {
                type: CalendarEventType.Appointment,
                initialValues: {
                  startDate: startDate.toJSDate(),
                  expiryDate: startDate.toJSDate()
                }
              },
              AppointmentStartingPoints.CalendarAddAppointmentButton
            )
        },
        ...getEventList()
      ]
    }
  ];

  items = screenSize.width < SCREEN_MED ? actionItems : items;
  items = items.filter(x => !x.permission || core.hasPermissions(x.permission));
  // Using Overflowset instead of CommandBar because the latter doesn't play
  // nice with right alignment
  return (
    <OverflowSet
      styles={{
        item: { marginLeft: 8 }
      }}
      items={items}
      onRenderItem={(
        item: SplitButtonProps & {
          key: string;
          permission?: string;
          toolTipText?: string;
        }
      ) => {
        const { key, permission, ...buttonProps } = item;
        return (
          <TooltipHost content={item.toolTipText}>
            <SplitButton {...buttonProps} />
          </TooltipHost>
        );
      }}
      onRenderOverflowButton={() => null}
    />
  );
});
