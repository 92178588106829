import { observer } from "mobx-react-lite";
import React, { Suspense, useContext } from "react";

import { CenteredLargeSpinner, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AccClinicalTab,
  ClinicalDataType,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { PatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClaimReviewForm } from "@modules/clinical/screens/patient-record/components/claim-review/ClaimReviewForm.tsx";
import { DischargeForm } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeForm.tsx";
import { SOTAPForm } from "@modules/clinical/screens/patient-record/components/SOTAP/SOTAPForm.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { ClinicalClaimAdjustmentEditScreen } from "./claims/ClinicalClaimAdjustmentEditScreen.tsx";
import { ClinicalActivityList } from "./clinical-activity/ClinicalActivityList.tsx";
import { ClinicalTaskList } from "./clinical-task/ClinicalTaskList.tsx";
import { DASHForm } from "./clinical-tools/dash/DASHForm.tsx";
import { DASHFormView } from "./clinical-tools/dash/DASHFormView.tsx";
import { DASS21QuestionnaireView } from "./clinical-tools/dass21/DASS21QuesstionnaireView.tsx";
import { DASS21QuestionnaireForm } from "./clinical-tools/dass21/DASS21QuestionnaireForm.tsx";
import { EPDSQuestionnaireForm } from "./clinical-tools/epds/EPDSQuestionnaireForm.tsx";
import { EPDSQuestionnaireView } from "./clinical-tools/epds/EPDSQuestionnaireView.tsx";
import { GRCSForm } from "./clinical-tools/grcs/GRCSForm.tsx";
import { GRCSFormView } from "./clinical-tools/grcs/GRCSFormView.tsx";
import { K10QuestionnaireForm } from "./clinical-tools/k10/K10QuestionnaireForm.tsx";
import { K10QuestionnaireView } from "./clinical-tools/k10/K10QuestionnaireView.tsx";
import { NPRSQuestionnaireForm } from "./clinical-tools/nprs/NPRSQuestionnaireForm.tsx";
import { NPRSQuestionnaireView } from "./clinical-tools/nprs/NPRSQuestionnaireView.tsx";
import { OREBROQuestionnaireForm } from "./clinical-tools/orebro/OREBROQuestionnaireForm.tsx";
import { OREBROQuestionnaireView } from "./clinical-tools/orebro/OREBROQuestionnaireView.tsx";
import { PSFSForm } from "./clinical-tools/psfs/PSFSForm.tsx";
import { PSFSView } from "./clinical-tools/psfs/PSFSView.tsx";
import { Rand36Form } from "./clinical-tools/rand36/Rand36Form.tsx";
import { Rand36QuestionnaireView } from "./clinical-tools/rand36/Rand36QuestionnaireView.tsx";
import { AlcoholForm } from "./family-social-history/AlcoholForm.tsx";
import { FamilyHistoryForm } from "./family-social-history/family-history/FamilyHistoryForm.tsx";
import { PhysicalActivityForm } from "./family-social-history/physical-activity/PhysicalActivityForm.tsx";
import { SleepForm } from "./family-social-history/sleep/SleepForm.tsx";
import { SocialForm } from "./family-social-history/social/SocialForm.tsx";
import { SubstanceUseForm } from "./family-social-history/SubstanceUseForm.tsx";
import { TobaccoForm } from "./family-social-history/TobaccoForm.tsx";
import { WorkHistoryForm } from "./family-social-history/work-history/WorkHistoryForm.tsx";
import { BodyExaminationForm } from "./full-body-clinical-data/examination/BodyExaminationForm.tsx";
import { FullBodyClinicalDataForm } from "./full-body-clinical-data/FullBodyClinicalDataForm.tsx";
import { CentralNervousSystemForm } from "./history-examinations/central-nervous-system/CentralNervousSystemForm.tsx";
import { ClinicalFlagsForm } from "./history-examinations/clinical-flags/ClinicalFlagsForm.tsx";
import { DermatomesAndMyotomesForm } from "./history-examinations/dermatomes-and-myotomes/DermatomesAndMyotomesForm.tsx";
import { GeneralExaminationForm } from "./history-examinations/general-examination/GeneralExaminationForm.tsx";
import { MedicationsAndSupplementsForm } from "./history-examinations/medications-and-supplements/MedicationsAndSupplementsForm.tsx";
import { PresentingComplaintForm } from "./history-examinations/presenting-complaint/PresentingComplaintForm.tsx";
import { SystemsReviewForm } from "./history-examinations/systems-review/SystemsReviewForm.tsx";
import { ObservationsExpandedTable } from "./observations/ObservationsExpandedTable.tsx";
import { PatientSummaryContextProvider } from "./patient-summary/helpers/PatientSummaryContext.tsx";
import {
  ClinicalClaimEditScreen,
  PatientSummary
} from "./PatientRecordLazy.tsx";
import { MedicationsExpandedTable } from "./prescribing/MedicationsExpandedTable.tsx";
import { SOTAPFollowOnForm } from "./SOTAP/sotap-follow-on/SOTAPFollowOnForm.tsx";
import { ManagementForm } from "./treatmentAndManagement/management/ManagementForm.tsx";
import { TreatmentForm } from "./treatmentAndManagement/treatment/TreatmentForm.tsx";

export type PatientRecordContentProps = {
  clinicalRecord: ClinicalRecord;
};

export const PatientRecordContent: React.FC<PatientRecordContentProps> =
  observer(({ clinicalRecord }) => {
    const { claimAdjustment, claimReviewClaim } = useContext(
      PatientRecordScreenContext
    );

    const root = useStores();
    const { clinical } = root;
    let renderContent = null;
    const currentPatientRecordTab = clinical.ui.tabs.currentPatientRecordTab;
    const activeTab = currentPatientRecordTab?.activeTab;
    if (!currentPatientRecordTab) {
      return null;
    }

    switch (currentPatientRecordTab.activeTabType) {
      case PermanentClinicalTab.PatientSummary:
        const id = clinical.activeRecord!;

        renderContent = (
          <DataFetcher
            key={id}
            fetch={() => clinicalRecord.loadClosedEncounters()}
            fallback={<CenteredLargeSpinner />}
          >
            {() => {
              return (
                <Suspense fallback={<CenteredLargeSpinner />}>
                  <PatientSummaryContextProvider>
                    <PatientSummary clinicalRecord={clinicalRecord} />
                  </PatientSummaryContextProvider>
                </Suspense>
              );
            }}
          </DataFetcher>
        );
        break;
      case PermanentClinicalTab.SOTAP:
        const isFollowOn = clinicalRecord.isFollowOnEncounter;
        renderContent = (
          <>
            {isFollowOn ? (
              <SOTAPFollowOnForm
                defaultTools={[ClinicalDataType.PSFS, ClinicalDataType.NPRS]}
              />
            ) : (
              <SOTAPForm
                defaultTools={[ClinicalDataType.PSFS, ClinicalDataType.NPRS]}
              />
            )}
          </>
        );
        break;
      case ClinicalDataType.K10:
        renderContent = (
          <K10QuestionnaireForm clinicalRecord={clinicalRecord} />
        );

        break;
      case ClinicalDataType.RAND36:
        renderContent = <Rand36Form clinicalRecord={clinicalRecord} />;

        break;
      case ClinicalDataType.RAND36View:
        renderContent = (
          <Rand36QuestionnaireView
            encounterId={currentPatientRecordTab.activeTab?.encounterId!}
            clinicalRecord={clinicalRecord}
          />
        );
        break;
      case ClinicalDataType.K10View:
        renderContent = (
          <K10QuestionnaireView
            encounterId={currentPatientRecordTab.activeTab?.encounterId!}
          />
        );
        break;
      case ClinicalDataType.NPRS:
        renderContent = (
          <NPRSQuestionnaireForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.NPRSView:
        renderContent = (
          <NPRSQuestionnaireView
            encounterId={currentPatientRecordTab.activeTab?.encounterId!}
          />
        );
        break;
      case ClinicalDataType.OREBRO:
        renderContent = (
          <OREBROQuestionnaireForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.OREBROView:
        renderContent = (
          <OREBROQuestionnaireView
            encounterId={currentPatientRecordTab.activeTab?.encounterId!}
          />
        );
        break;
      case ClinicalDataType.GRCS:
        renderContent = <GRCSForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.GRCSView:
        renderContent = (
          <GRCSFormView
            encounterId={currentPatientRecordTab.activeTab?.encounterId!}
          />
        );
        break;
      case ClinicalDataType.GEN:
        break;
      case ClinicalDataType.FullBody:
        renderContent = (
          <FullBodyClinicalDataForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.BodyExam:
        renderContent = <BodyExaminationForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.Eye:
        break;
      case ClinicalDataType.Alcohol:
        renderContent = <AlcoholForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.Social:
        renderContent = <SocialForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.PhysicalActivity:
        renderContent = (
          <PhysicalActivityForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.Tobacco:
        renderContent = <TobaccoForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.SubstanceUse:
        renderContent = <SubstanceUseForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.PSFS:
        const contextId = activeTab?.contextId;
        renderContent = contextId ? (
          <PSFSForm clinicalRecord={clinicalRecord} contextId={contextId} />
        ) : null;

        break;
      case ClinicalDataType.PSFSView:
        renderContent = (
          <PSFSView
            encounterId={currentPatientRecordTab.activeTab?.encounterId!}
            clinicalRecord={clinicalRecord}
            contextId={activeTab?.contextId}
          />
        );

        break;
      case ClinicalDataType.DASH:
        renderContent = <DASHForm clinicalRecord={clinicalRecord} />;

        break;
      case ClinicalDataType.DASHView:
        renderContent = (
          <DASHFormView
            encounterId={currentPatientRecordTab.activeTab?.encounterId!}
          />
        );
        break;
      case AccClinicalTab.Injury:
        renderContent = clinicalRecord.claimId && (
          <WhenCountry is={Country.NewZealand}>
            <Suspense fallback={<CenteredLargeSpinner />}>
              <ClinicalClaimEditScreen claimId={clinicalRecord.claimId} />
            </Suspense>
          </WhenCountry>
        );
        break;
      case AccClinicalTab.Adjustment:
        renderContent = claimAdjustment && (
          <WhenCountry is={Country.NewZealand}>
            <ClinicalClaimAdjustmentEditScreen />
          </WhenCountry>
        );
        break;
      case AccClinicalTab.Review:
        renderContent = claimReviewClaim && (
          <WhenCountry is={Country.NewZealand}>
            <ClaimReviewForm claim={claimReviewClaim} />
          </WhenCountry>
        );
        break;
      case ClinicalDataType.WorkHistory:
        renderContent = <WorkHistoryForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.ClinicalTask:
        renderContent = (
          <ClinicalTaskList clinicalRecord={clinicalRecord} compact={false} />
        );
        break;
      case ClinicalDataType.Discharge:
        renderContent = activeTab?.contextId && (
          <DischargeForm
            clinicalRecord={clinicalRecord}
            contextId={activeTab?.contextId}
            defaultTools={[ClinicalDataType.GRCS]}
          />
        );
        break;
      case ClinicalDataType.Sleep:
        renderContent = <SleepForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.GeneralExamination:
        renderContent = (
          <GeneralExaminationForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.TreatmentAndEducation:
        renderContent = <TreatmentForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.PatientTreatmentPlan:
        renderContent = <ManagementForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.CurrentInjury:
        renderContent = (
          <PresentingComplaintForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.ClinicalFlags:
        renderContent = <ClinicalFlagsForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.DermatomesAndMyotomes:
        renderContent = (
          <DermatomesAndMyotomesForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.FamilyHistory:
        renderContent = <FamilyHistoryForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.SystemsReview:
        renderContent = <SystemsReviewForm clinicalRecord={clinicalRecord} />;
        break;
      case ClinicalDataType.CentralNervousSystem:
        renderContent = (
          <CentralNervousSystemForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.EPDS:
        renderContent = (
          <EPDSQuestionnaireForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.EPDSView:
        renderContent = currentPatientRecordTab.activeTab?.encounterId ? (
          <EPDSQuestionnaireView
            encounterId={currentPatientRecordTab.activeTab?.encounterId}
          />
        ) : null;
        break;
      case ClinicalDataType.DASS21:
        renderContent = (
          <DASS21QuestionnaireForm clinicalRecord={clinicalRecord} />
        );
        break;

      case ClinicalDataType.DASS21View:
        renderContent = currentPatientRecordTab.activeTab?.encounterId ? (
          <DASS21QuestionnaireView
            encounterId={currentPatientRecordTab.activeTab.encounterId}
          />
        ) : null;
        break;
      case ClinicalDataType.Observations:
        renderContent = (
          <ObservationsExpandedTable clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.Medication:
        renderContent = <MedicationsExpandedTable />;
        break;
      case ClinicalDataType.MedicationsAndSupplements:
        renderContent = (
          <MedicationsAndSupplementsForm clinicalRecord={clinicalRecord} />
        );
        break;
      case ClinicalDataType.ClinicalActivity:
        renderContent = (
          <ClinicalActivityList clinicalRecord={clinicalRecord} />
        );
        break;
      default:
        renderContent = null;
    }

    return (
      <Stack styles={{ root: { height: "inherit" } }}>{renderContent}</Stack>
    );
  });
