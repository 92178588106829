import React, { FunctionComponent } from "react";

import { mergeStyles, Stack, useTheme } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";

import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import { UserWorkingHourFormValues } from "./UserWorkingHourForm.types.ts";
import { UserWorkingHoursFormField } from "./UserWorkingHoursFormField.tsx";

export const nameOfUserWorkingHourFormValues =
  nameOfFactory<UserWorkingHourFormValues>();

export const UserWorkingHoursFormFields: FunctionComponent = () => {
  const theme = useTheme();
  const { formFields } = getUserStylesSet(theme);

  return (
    <FieldArray name={nameOfUserWorkingHourFormValues("userWorkingHours")}>
      {({ fields }) => (
        <Stack
          styles={{
            root: mergeStyles(formFields, { paddingTop: 32, paddingBottom: 32 })
          }}
          tokens={{ childrenGap: 16 }}
        >
          {fields.map((name, index) => (
            <React.Fragment key={name}>
              {index !== 0 && (
                <Stack
                  styles={{
                    root: {
                      borderTop: `1px solid ${theme.palette.neutralLight}`
                    }
                  }}
                />
              )}
              <UserWorkingHoursFormField
                name={name}
                onDelete={() => fields.remove(index)}
              />
            </React.Fragment>
          ))}
        </Stack>
      )}
    </FieldArray>
  );
};
