import { stringify } from "query-string";
import { FC } from "react";

import { NoDataTile, Spinner, useFormContext } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

import { TransactionFilter } from "../transaction-filter/TransactionFilter.types.ts";

interface TransactionListNoResultsProps {
  accountContact?: Contact;
}

export const TransactionListNoResults: FC<TransactionListNoResultsProps> = ({
  accountContact
}) => {
  const {
    state: { values: filter },
    actions: { reset }
  } = useFormContext<TransactionFilter>();

  const getNoDataTile = ({
    contact,
    hiddenLink
  }: {
    contact?: Contact;
    hiddenLink?: boolean;
  }) => {
    const linkProps = hiddenLink
      ? { hidden: true }
      : {
          text: "Clear filter",
          onClick: () => reset()
        };

    return (
      <NoDataTile
        styles={{ root: { boxShadow: "none" } }}
        textProps={{
          text: contact?.name
            ? `No accounts or transactions for ${contact?.name}`
            : "No accounts or transactions"
        }}
        linkProps={
          accountContact?.type === ContactType.Patient
            ? {
                text: `Invoice ${accountContact.name}`,
                onClick: () => {
                  routing.pushRetainingState({
                    pathname: routes.accounts.invoices.new.pattern,
                    search: stringify({
                      accountId: accountContact.id
                    })
                  });
                }
              }
            : linkProps
        }
      />
    );
  };

  const { routing } = useStores();
  return filter.patientId ? (
    <ContactFetcher contactId={filter.patientId!} fallback={<Spinner />}>
      {contact => getNoDataTile({ contact })}
    </ContactFetcher>
  ) : (
    getNoDataTile({ hiddenLink: true })
  );
};
