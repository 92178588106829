import { FunctionComponent } from "react";

import { IStackProps, Stack } from "@bps/fluent-ui";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  getAppointmentStatusText,
  urgentAppointmentStatuses
} from "@modules/booking/screens/booking-calendar/components/shared-components/appointment-status/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AppointmentStatusIndicator } from "./AppointmentStatusIndicator.tsx";

interface AppointmentStatusProps extends IStackProps {
  code: AppointmentStatusCode;
  showIndicator: boolean;
  urgent?: boolean;
}

const AppointmentStatusComponent: FunctionComponent<
  AppointmentStatusProps
> = props => {
  const { code, showIndicator, urgent, ...rest } = props;
  const { booking } = useStores();

  const appointmentStatus = booking.ref.appointmentStatuses.get(code);

  let useText =
    code === AppointmentStatusCode.Booked || appointmentStatus === undefined
      ? "Booked"
      : appointmentStatus.text;

  useText = getAppointmentStatusText(code, useText, urgent);

  const useCode =
    urgent && urgentAppointmentStatuses.indexOf(code) > -1 ? `U${code}` : code;

  return (
    <Stack horizontal verticalAlign="center" {...rest}>
      {showIndicator && (
        <AppointmentStatusIndicator code={useCode as AppointmentStatusCode} />
      )}
      <Stack>{useText}</Stack>
    </Stack>
  );
};

export const AppointmentStatus = withFetch(
  x => x.booking.ref.appointmentStatuses.load(),
  AppointmentStatusComponent
);
