import { FunctionComponent, memo, useContext, useState } from "react";
import { useField, useForm } from "react-final-form";

import { Toggle } from "@bps/fluent-ui";
import { InvoiceFormContext } from "@modules/billing/screens/invoice/context/InvoiceFormContext.tsx";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";

export const InvoiceDetailsModalNotesField: FunctionComponent = memo(() => {
  const { change } = useForm<InvoiceDetailsModalFormValues>();
  const {
    input: { value: reference }
  } = useField<string | undefined>(invoiceFormNameOf("reference"), {
    subscription: { value: true }
  });

  const { isFromCalendarEvent } = useContext(InvoiceFormContext);

  const [isAddingNotes, setIsAddingNotes] = useState<boolean>(!!reference);

  const onToggleIsAddingNotes = () => {
    if (isAddingNotes) {
      change("reference", "");
    }
    setIsAddingNotes(!isAddingNotes);
  };

  return (
    <>
      <Toggle
        automationAttribute="invoice-add-comment"
        styles={{ root: { margin: 0 } }}
        checked={isAddingNotes}
        onChange={onToggleIsAddingNotes}
        onText="Add comment to invoice"
        offText="Add comment to invoice"
        disabled={isFromCalendarEvent}
      />
      {isAddingNotes && (
        <TextInputField
          name={invoiceFormNameOf("reference")}
          disabled={isFromCalendarEvent}
        />
      )}
    </>
  );
});
