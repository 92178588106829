import { Country } from "@libs/enums/country.enum.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  email,
  maxLength,
  phone,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { RefCountry } from "@stores/core/models/Address.ts";

import { AddressValidator } from "../validation/AddressValidator.ts";
import { QuickAddContactFormValues } from "./QuickAddContactFormValues.tsx";

interface QuickAddContactFormValidatorProps {
  contactType: string;
}
export class QuickAddContactFormValidator extends Validator<QuickAddContactFormValues> {
  constructor(
    options: QuickAddContactFormValidatorProps,
    countries: readonly RefCountry[],
    country: Country
  ) {
    super();

    const isNotNewZealand = country !== Country.NewZealand;

    const addressValidator = new AddressValidator({ countries, country });
    const requiresCityCondition = () =>
      !!countries.find(c => c.code === country && !c.isStateRequired);

    const requiresStateCondition = () =>
      !!countries.find(c => c.code === country && c.isStateRequired);

    this.forField("firstName", [required(), maxLength(40)], {
      when: () =>
        options.contactType === ContactType.Individual ||
        options.contactType === ContactType.Patient
    });
    this.forField("lastName", [required(), maxLength(40)], {
      when: () =>
        options.contactType === ContactType.Individual ||
        options.contactType === ContactType.Patient
    });
    this.forField("mobile", phone);
    this.forField("email", email());
    this.forArrayField("addresses", addressValidator.validate);
    this.forField("organisationName", required(), {
      when: () => options.contactType === ContactType.Organisation
    });
    this.forField("organisationCategories", required(), {
      when: () => options.contactType === ContactType.Organisation
    });
    this.forField("suburb", required(), {
      when: (value, parent) =>
        isNotNewZealand &&
        options.contactType === ContactType.Organisation &&
        (!!parent.city || !!parent.state || !!parent.postCode)
    });
    this.forField("postCode", required(), {
      when: (value, parent) =>
        isNotNewZealand &&
        options.contactType === ContactType.Organisation &&
        (!!parent.suburb || !!parent.state || !!parent.city)
    });
    this.forField("state", required(), {
      when: (value, parent) =>
        requiresStateCondition() &&
        isNotNewZealand &&
        options.contactType === ContactType.Organisation &&
        (!!parent.suburb || !!parent.postCode)
    });
    this.forField("city", required(), {
      when: (value, parent) =>
        requiresCityCondition() &&
        options.contactType === ContactType.Organisation &&
        (!!parent.suburb || !!parent.postCode)
    });
    this.forField("phone", phone);
  }
}
