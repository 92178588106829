import { observer } from "mobx-react-lite";

import {
  FontIcon,
  FontWeights,
  getScreenSelector,
  Option,
  OptionsSelect,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { useBookingCalendarScreenContext } from "../context/BookingCalendarScreenContext.tsx";
import { CalendarEventView } from "../types/CalendarEventView.types.ts";

const weekOptions: number[] = [2, 3, 4, 6, 8];

const options: Option[] = weekOptions.map(num => ({
  key: String(num),
  text: `${num} weeks`
}));

export const BookingCalendarJumpInput = observer(() => {
  const theme = useTheme();

  const { dayOrWeekView, startDate, setStartDate } =
    useBookingCalendarScreenContext();

  const onChange = (keys: string | string[]) => {
    const key = Number(Array.isArray(keys) ? keys[0] : keys);

    const date = startDate.plus({ weeks: key });

    const dateToSet =
      dayOrWeekView === CalendarEventView.Week ? date.startOf("week") : date;

    setStartDate(dateToSet);
  };

  return (
    <OptionsSelect
      selectedKeys={[]}
      options={options}
      onChangeSelectedKeys={onChange}
      onRenderFieldContent={() => (
        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
          <FontIcon
            iconName="SearchCalendar"
            styles={{
              root: {
                color: theme.semanticColors.inputIcon
              }
            }}
          />
          <Text
            styles={{
              root: {
                fontWeight: FontWeights.semibold,
                selectors: { [getScreenSelector(0, 1350)]: { display: "none" } }
              }
            }}
          >
            Jump
          </Text>
        </Stack>
      )}
      hideSearchOption
      hideClearButton
      calloutWidth={120}
      styles={{
        field: {
          paddingLeft: 12,
          paddingRight: 12,
          background: "none",
          border: "none"
        }
      }}
    />
  );
});
