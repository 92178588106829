import { FormApi } from "final-form";
import { FunctionComponent } from "react";

import { ActionButton, Heading } from "@bps/fluent-ui";
import {
  QuickColoursSettingDto,
  UpdateQuickColoursDto
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { QuickColoursDetailsList } from "./QuickColoursDetailsList.tsx";
import { QuickColoursDialogValidator } from "./QuickColoursDialogValidator.ts";

export interface QuickColoursDialogValues {
  quickColoursSettings: QuickColoursSettingDto[];
}

interface QuickColoursDiagloProps {
  onDismiss: () => void;
}
export const QuickColoursDialog: FunctionComponent<QuickColoursDiagloProps> = (
  props: QuickColoursDiagloProps
) => {
  const { onDismiss } = props;

  const { userExperience } = useStores();
  const quickColours = userExperience.quickColours;
  const quickColoursSettings =
    userExperience.quickColours?.quickColoursSettings ?? [];

  const defaultQuickColoursSetting: QuickColoursSettingDto = {
    colourName: "",
    colourCode: ""
  };

  const addQuickColorsSetting = (form: FormApi<QuickColoursDialogValues>) => {
    form.mutators.push("quickColoursSettings", defaultQuickColoursSetting);
  };

  const getExtraActionsBefore = (form: FormApi<QuickColoursDialogValues>) => {
    return (
      <ActionButton
        iconProps={{ iconName: "Add" }}
        title="Add another"
        onClick={() => addQuickColorsSetting(form)}
        styles={{ root: { marginRight: "auto" } }}
      >
        Add another
      </ActionButton>
    );
  };

  const onSubmit = async (values: QuickColoursDialogValues) => {
    const updatedQuickColoursSettings = values.quickColoursSettings.filter(
      x => x.colourCode !== null || x.colourName !== null
    );

    const updatedQuickAccess: UpdateQuickColoursDto = {
      quickColoursSettings: updatedQuickColoursSettings,
      eTag: quickColours?.eTag
    };
    await userExperience.updateQuickColours(updatedQuickAccess);
  };

  const initialValues: QuickColoursDialogValues = {
    quickColoursSettings:
      quickColoursSettings.length === 0
        ? [defaultQuickColoursSetting]
        : quickColoursSettings
  };

  return (
    <SubmissionFormDialog<QuickColoursDialogValues>
      dialogName="Quick colours"
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => {
        const validator = new QuickColoursDialogValidator();
        return validator.validate(values);
      }}
      onSubmitSucceeded={onDismiss}
      buttonsProps={{
        disableSubmitOnFormInvalid: true,
        extraActionsBefore: getExtraActionsBefore,
        styles: {
          root: {
            width: 640
          }
        }
      }}
      dialogProps={{
        minWidth: 680,
        onDismiss: () => onDismiss(),
        dialogContentProps: {
          title: <Heading variant="modal-heading">Quick colours</Heading>
        }
      }}
      render={() => <QuickColoursDetailsList />}
    />
  );
};
