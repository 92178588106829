import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { DefaultButton, Heading, PrimaryButton, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useAppointmentTypeScreenContext } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";
import { When } from "@ui-components/withPerm.tsx";

import { SettingsLabels } from "../../shared-components/SettingsLabels.ts";

export const AppointmentTypeHeader: FunctionComponent = observer(() => {
  const { setAppointmentType, setShowOnlineAppointmentTypeSettingModal } =
    useAppointmentTypeScreenContext();
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      grow
      styles={{ root: { marginBottom: 16 } }}
    >
      <Heading variant="section-heading">
        {SettingsLabels.appointmentTypes}
      </Heading>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { padding: "5px 0", marginBottom: 16 } }}
      >
        <When permission={[Permission.BhbRead, Permission.BhbWrite]}>
          <DefaultButton
            id="online-display"
            onClick={() => setShowOnlineAppointmentTypeSettingModal(true)}
            text={SettingsLabels.onlineDisplay}
            iconProps={{ iconName: "Globe" }}
            styles={{
              root: {
                marginRight: 8
              }
            }}
          />
        </When>
        <When permission={Permission.AppointmentTypeWrite}>
          <PrimaryButton
            id="add-appointment-type"
            onClick={() => setAppointmentType(undefined)}
            text="Add appointment type"
            iconProps={{ iconName: "Add" }}
          />
        </When>
      </Stack>
    </Stack>
  );
});
