import { createContext, useContext } from "react";

import { EditUserDayWorkingHoursModel } from "./EditUserDayWorkingHoursModel.ts";

export const EditUserDayWorkingHoursContext =
  createContext<EditUserDayWorkingHoursModel>(
    {} as EditUserDayWorkingHoursModel
  );

export const useEditUserDayWorkingHoursContext = () =>
  useContext(EditUserDayWorkingHoursContext);
