import { CollapsibleCard, Stack } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";

export const AnalysisAndPlanCard: React.FC = () => {
  const { clinical } = useStores();

  const { clinicalRecord } = usePatientRecordScreenContext();

  const getRxAnalysisOptions = () =>
    clinical.ref.rxAnalysisOptions.keyTextValues;

  return (
    <div id={SotapMskId.AnalysisAndPlan}>
      <CollapsibleCard
        heading={SotapMskLabel.Analysis}
        headingLevel="section-heading"
        iconName="PasteAsText"
        openOnRender
      >
        <Stack
          tokens={{
            childrenGap: 8,
            maxWidth: 630
          }}
        >
          {clinicalRecord.isFollowOnEncounter && (
            <ButtonsGroupSingleChoiceField
              label="Rx analysis"
              name={sotapNameOf("rxAnalysis")}
              options={getRxAnalysisOptions()}
            />
          )}
          <TextInputField
            name={sotapNameOf("analysis")}
            label=""
            multiline
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: {
                minHeight: 60
              }
            }}
          />
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
