import { IStyle, ITextStyles } from "@bps/fluent-ui";

const messageLabelStyle: IStyle = {
  fontSize: 14,
  fontStyle: "italic",
  padding: "4px"
};

const textStyles: ITextStyles = {
  root: {
    fontStyle: "italic",
    padding: "8px",
    alignSelf: "center"
  }
};

const contentBoxStyles = {
  root: {
    overflowY: "auto",
    flex: 1
  }
};

const timeSlotsGridStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(5, 80px)",
  gap: 5,
  marginTop: 8
};

export { contentBoxStyles, messageLabelStyle, timeSlotsGridStyles, textStyles };
