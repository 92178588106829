import { createContext, useContext } from "react";

import { DocumentWriterMergeFormHelper } from "./DocumentWriterMergeFormHelper.ts";

export const DocumentWriterMergeFormFormContext =
  createContext<DocumentWriterMergeFormHelper>(
    {} as DocumentWriterMergeFormHelper
  );

export const useDocumentWriterMergeFormContext = () =>
  useContext(DocumentWriterMergeFormFormContext);
