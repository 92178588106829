import { FC } from "react";
import { useField } from "react-final-form";

import { FontSizes, FontWeights, Stack, Text } from "@bps/fluent-ui";
import { COMMENT_MAX_LENGTH } from "@modules/billing/screens/validators/InvoiceItemListAddCommentValidator.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { getFieldErrorMessage } from "@ui-components/form/FinalForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

export const CommentField: FC = () => {
  const { meta } = useField("comment", { subscription: { value: true } });

  const errorMessage = getFieldErrorMessage(meta, false);
  const onRenderDescription = (): JSX.Element => (
    <FieldSpy name="comment">
      {(meta, commentValue: string) => {
        return (
          <Stack
            horizontal
            horizontalAlign="end"
            styles={(prop, theme) => ({
              root: getFieldErrorMessage(meta)
                ? {
                    color: theme.semanticColors.errorText,
                    paddingTop: 5,
                    order: 1
                  }
                : undefined
            })}
          >
            <Text
              styles={{
                root: { fontSize: FontSizes.small, color: "inherit" }
              }}
            >
              Characters left:
            </Text>

            <Text
              styles={{
                root: {
                  fontWeight: FontWeights.bold,
                  fontSize: FontSizes.small,
                  color: "inherit"
                }
              }}
            >
              {COMMENT_MAX_LENGTH - commentValue.length}
            </Text>
          </Stack>
        );
      }}
    </FieldSpy>
  );

  return (
    <TextInputField
      multiline
      rows={5}
      resizable={false}
      name="comment"
      label="Comment"
      onRenderDescription={onRenderDescription}
      styles={{
        root: {
          "& > span": {
            display: "flex",
            justifyContent: errorMessage ? "space-between" : "end"
          }
        },
        errorMessage: { order: 0 }
      }}
    />
  );
};
