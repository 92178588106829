import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { SubmissionFormDialogProps } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.types.ts";

import { SelectInboxDocumentDialogFields } from "./SelectInboxDocumentDialogFields.tsx";

type SelectInboxDocumentDialogProps = {
  documents: InboxDocument[];
} & SubmissionFormDialogProps<InboxPickerFormValues>;
export interface InboxPickerFormValues {
  documentId: string;
}

export const SelectInboxDocumentDialog: React.FC<
  SelectInboxDocumentDialogProps
> = ({ documents, ...submissionFormDialogProps }) => {
  const items = documents.map(d => ({
    key: d.id,
    ...d.dto
  }));

  return (
    <SubmissionFormDialog<InboxPickerFormValues>
      {...submissionFormDialogProps}
      render={() => <SelectInboxDocumentDialogFields items={items} />}
    />
  );
};
