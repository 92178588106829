export enum LocationLabels {
  locationNickname = "Location nickname",
  addressLine1 = "Address line 1",
  locationName = "Location name",
  selectColor = "Select colour",
  postCode = "Post code",
  townCity = "Town/City",
  address = "Address",
  street = "Street",
  phone = "Phone",
  email = "Email",
  city = "City",
  state = "State",
  suburb = "Suburb",
  country = "Country",
  timeZone = "Timezone",
  addressLine2 = "Address line 2",
  hpiFacilityId = "HPI facility ID",
  defaultLocation = "Default location",
  buildingName = "Building/Facility name",
  appointmentBookMarkersLabel = "Appointment book marker"
}
