import { useState } from "react";

import { ConfirmDialog } from "@bps/fluent-ui";

interface ClinicalToolConfirmEditModalProps {
  resultType: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ClinicalToolConfirmEditModal: React.FunctionComponent<
  ClinicalToolConfirmEditModalProps
> = ({ resultType, onConfirm, onCancel }) => {
  const [isHidden, setisHidden] = useState(false);

  return (
    <ConfirmDialog
      hidden={isHidden}
      title={`Open existing ${resultType}`}
      onConfirm={() => {
        setisHidden(true);
        onConfirm();
      }}
      onCancel={() => {
        setisHidden(true);
        onCancel();
      }}
    >
      <p>
        An existing {resultType} has already been recorded for this
        consultation.
      </p>
      <p>Select OK to open the latest version.</p>
    </ConfirmDialog>
  );
};
