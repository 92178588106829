import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { useAllocationsTotal } from "@modules/billing/screens/shared-components/allocation-field/hooks/useAllocationsTotal.tsx";
import {
  LabelData,
  StatusLabel
} from "@modules/billing/screens/shared-components/StatusLabel.tsx";
import { Invoice } from "@stores/billing/models/Invoice.ts";

interface WriteOffStatusLabelProps {
  invoice: Invoice;
}

export const WriteOffStatusLabel: React.FC<WriteOffStatusLabelProps> = ({
  invoice
}) => {
  const { allocatedTotal } = useAllocationsTotal();

  const labelsData: LabelData[] = [
    {
      id: "invoice",
      data: {
        label: "Invoice ($)",
        value: currencyFormat(invoice.total || 0, {
          currency: ""
        })
      }
    },
    {
      id: "paid",
      data: {
        label: "Paid ($)",
        value: currencyFormat(invoice.total - (invoice.owing || 0), {
          currency: ""
        })
      }
    },
    {
      id: "Write-off",
      data: {
        label: "Write off ($)",
        value: currencyFormat(allocatedTotal, {
          currency: ""
        })
      }
    }
  ];

  return <StatusLabel childrenGap={80} labelData={labelsData} />;
};
