import { FunctionComponent, PropsWithChildren } from "react";

import { Text } from "@bps/fluent-ui";

export const SmallText: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  return (
    <Text variant="small" styles={{ root: { color: "inherit" } }}>
      {children}
    </Text>
  );
};
