import { IconButton, TooltipHost, useTheme } from "@bps/fluent-ui";

export const HiddenButton: React.FC<{
  hidden: boolean;
  disabled?: boolean;
  alwaysShown: boolean;
  onClick: () => void;
}> = ({ hidden, disabled, alwaysShown, onClick }) => {
  const theme = useTheme();

  const toolTipIfDisabled = alwaysShown ? "Always displayed" : "Displayed";
  const toolTipIfEnabled = !hidden ? "Hidden" : "Displayed";

  return (
    <TooltipHost content={disabled ? toolTipIfDisabled : toolTipIfEnabled}>
      <IconButton
        iconProps={{ iconName: hidden ? "View" : "Hide2" }}
        disabled={disabled}
        onClick={onClick}
        styles={{
          root: {
            padding: 16,
            background: !hidden
              ? theme.semanticColors.buttonBackgroundPressed
              : undefined
          }
        }}
      />
    </TooltipHost>
  );
};
