import { useContext } from "react";

import {
  confirm,
  ContextualMenuItemType,
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem
} from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { AccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { Statement } from "@stores/billing/models/Statement.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { SendStatementMenuItem } from "./SendStatementMenuItem.tsx";

interface Props {
  statement: Statement;
}

export const StatementsTransactionContextualMenu: React.FC<Props> = ({
  statement
}) => {
  const { billing, notification } = useStores();

  const { accountContact, setIsStatementPrinting, setSelectedStatement } =
    useContext(AccountScreenContext);

  const deleteStatement = async () => {
    const isConfirmed = await confirm({
      maxWidth: 560,
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: "Delete statement"
      },
      dialogContentProps: {
        title: "Confirm statement deletion",
        subText: "Do you want to permanently delete this statement?"
      }
    });
    if (isConfirmed) {
      try {
        await billing.deleteStatementById(statement?.id!);
        notification.success(notificationMessages.statementDeleted);
      } catch (e) {
        notification.error(e.message);
      }
    }
  };

  const onEmailStatement = async () => {
    const result = await billing.fetchPreferencesAndDocument({
      contactId: accountContact.id,
      statementId: statement.id
    });

    const statementId = statement.id;
    const documentId = result.storedDocument?.documentId;

    if (statementId && documentId) {
      billing.ui.setStatementEmailDialogVisible(true);
    }
  };

  const menuItems: IContextualMenuItem[] = [
    {
      key: "Preview",
      text: "Preview",
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        billing.openStatementPdf(statement.id);
        billing.ui.setStatementDocumentPreviewData({
          contactId: accountContact.id,
          statementId: statement.id,
          statement
        });
      }
    },
    {
      key: "Print",
      text: "Print",
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setIsStatementPrinting(true);
        setSelectedStatement(statement);
      }
    },
    {
      key: "email",
      text: "Email",
      disabled: accountContact.billingOptedOut,
      onRenderContent: () => (
        <SendStatementMenuItem statementId={statement.id} />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onEmailStatement();
      }
    },
    {
      key: "divider1",
      itemType: ContextualMenuItemType.Divider
    },
    {
      key: "delete",
      text: "Delete",
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        deleteStatement();
      }
    }
  ];

  return (
    <IconButton
      {...dataAttribute(
        DataAttributes.Element,
        "statement-transaction-menu-button"
      )}
      menuIconProps={{ iconName: "More" }}
      menuProps={{
        items: menuItems
      }}
      styles={{
        root: { height: "36px" },
        flexContainer: { height: "36px" }
      }}
    />
  );
};
