import { Spinner, useResizeElementObserver } from "@bps/fluent-ui";
import { MeasurementType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import { ClincialToolViewData } from "../clinical-tool-view/ClinicalToolViewDataFetcher.ts";
import { getClinicalToolViewData } from "../clinical-tool-view/utils.ts";
import { K10Questions } from "./K10Questions.tsx";

export interface K10QuestionnaireViewProps {
  encounterId: string;
}

export const K10QuestionnaireView: React.FunctionComponent<
  K10QuestionnaireViewProps
> = ({ encounterId }) => {
  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;
  return (
    <DataFetcher<ClincialToolViewData>
      fetch={getClinicalToolViewData(encounterId, MeasurementType.K10)}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        questionnaire,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId,
        summary
      }) => (
        <>
          <div
            ref={r => {
              if (r && !element) {
                setElement(r);
              }
            }}
          />
          <ClinicalToolView
            title="K10"
            isConfidential={isConfidential}
            lastUpdatedDate={lastUpdatedDate}
            lastUpdatedUserId={lastUpdatedUserId}
          >
            <K10Questions
              questionnaire={questionnaire}
              answers={answers}
              width={panelWidth}
              summary={summary}
              viewOnly
            />
          </ClinicalToolView>
        </>
      )}
    </DataFetcher>
  );
};
