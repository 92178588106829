export enum UserPageHeadings {
  users = "Users",
  personalInformation = "Personal information",
  clinicalView = "Clinical View Settings",
  clinical = "Clinical settings",
  sessions = "Sessions",
  workingHours = "Working hours",
  exceptions = "Exceptions",
  reserves = "Reserves",
  inactive = "Inactive",
  deactivate = "Deactivate",
  activate = "Activate",
  actions = "Actions",
  treeView = "TreeView",
  dock = "Dock"
}

export enum UserCardHeadings {
  security = "Security",
  personalInformation = "Personal information",
  professionalCredentials = "Professional credentials",
  clinical = "Clinical settings",
  workingHours = "Working hours",
  exceptions = "Active user exceptions",
  exceptionsPrevious = "Previous user exceptions",
  reserves = "Active reserves",
  previousReserves = "Previous reserves",
  treeView = "Tree view",
  dockView = "Dock",
  patientSummaryView = "Patient summary page",
  activeWorkingHours = "Active working hours",
  consultationNotes = "Consultation"
}

export enum UserCardSubHeadings {
  treeView = "Located on the right side of the clinical record, the tree view provides access to information about the current encounter.",
  dockView = "The dock is at the bottom of the clinical area and contains tabs of pages and tools that are currently open.",
  patientSummaryView = "The patient summary page is docked in the clinical area by default. It’s a matrix of cards that provide a snapshot of the current patient."
}

export enum UserLabels {
  patientSummaryPage = "Patient summary page",
  dockViewDefault = "Dock view default",
  items = "items",
  notesFromPreEncounter = "Notes from previous encounter",
  defaultDockedTab = "Default docked tab",
  captureDiscardConsultReason = "Reason for discarding consultation notes & services is",
  defaultTreeView = "Default view",
  cardArrangement = "Card arrangement",
  patientSummaryPageInfo = "Drag these fields to prioritise the position of each panel in your patient summary page. Use the checkbox to hide those you do not wish to see.",
  dockViewControlInfo = "Today’s notes will display in the main content area of the clinical record by default. To change this, use the dropdown below.",
  treeViewControlInfo = "Use the dropdown below to change which panel opens by default.",
  notesFromPreEncounterInfo = "By default you may see notes that are more recent than your own made by another provider of your specialty.",
  displaySettings = "Display settings",
  customersCalled = "My customers are called",
  rowArrangement = "Row arrangement",
  rowArrangementInfo = "Choose which piece of information displays at the top of each row.",
  notesFormat = "Format of consultation notes",
  notesFormatDescription = "This will affect the way your Today's Notes are formatted.",
  consultationWorkflow = "Workflow for consultation",
  defaultWorkflowDescription = "Choose which workflow page loads automatically in your consultations.",
  timerEnabled = "Timer is activated"
}

export enum UserClinicalViewFormIcons {
  gripperBarHorizontal = "GripperBarHorizontal"
}

export enum UserClinicalViewItemsColumns {
  name = "Name",
  checked = "Checked",
  icon = "Icon"
}

export enum UserScreenBreadcrumbKeys {
  users = 1,
  user = 2,
  clinical = 3,
  workingHours = 4,
  exceptions = 5,
  reserves = 6
}
