import { HideStack } from "@bps/fluent-ui";

import { AllocationHeader } from "../AllocationList/AllocationHeader.tsx";
import { AllocationFieldProps } from "./AllocationField.types.ts";
import { AllocationFieldBase } from "./AllocationFieldBase.tsx";
import { useAllocationField } from "./hooks/useAllocationField.ts";
import { ItemSelectionMode } from "./types.ts";

export const AllocationField: React.FC<AllocationFieldProps> = props => {
  const { statusLabel, defaultHeadingText, columnOptions } = props;
  const { allocationOption } = useAllocationField();
  const hidden =
    allocationOption?.itemSelectionMode === ItemSelectionMode.hidden;
  return (
    <HideStack when={hidden}>
      <AllocationHeader
        defaultHeadingText={defaultHeadingText}
        statusLabel={statusLabel}
      />
      <AllocationFieldBase columnOptions={columnOptions} />
    </HideStack>
  );
};
