import { observer } from "mobx-react-lite";
import { MouseEvent, useContext } from "react";
import { useForm } from "react-final-form";

import {
  FontIcon,
  SplitButton,
  Stack,
  Toggle,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { EncounterStatus } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";

import { EncounterSubmitOptions } from "./EncounterFormLabels.ts";
import { EncounterFormContext } from "./models/EncounterFormContext.ts";

export const EncounterFormSubmitButtons: React.FC = observer(() => {
  const theme = useTheme();
  const {
    clinicalRecord,
    encounter,
    handleFinaliseNotesAndServicesClick,
    handleSaveUpdateClick,
    noChargeComment,
    onDiscard,
    openAddServicesDialog,
    setNoChargeDialogVisibility,
    openProviderCommentDialog,
    providerComment,
    invoiceItemsAreFinalised,
    invoiceItemsWithServices,
    setInvoicedServicesDialogVisible,
    setIsEnding,
    setCloseOnEnd,
    checkIfFormUnsaved,
    notesCompleted,
    setNotesCompleted,
    isRecordUpdate,
    trackEventEncounterUpdated
  } = useContext(EncounterFormContext);

  const form = useForm<EncounterFormValues>();

  const isPrimaryButtonDiscard =
    !clinicalRecord?.areNotesEntered && !invoiceItemsAreFinalised;

  const appointmentStatus =
    clinicalRecord?.openEncounter?.calendarEvent?.appointmentStatus;

  const trackEvent = useAppTrackEvent(AppInsightsEventNames.encounterUpdated);

  //implement track event here
  const onDefaultClick = (evt: MouseEvent<any>) => {
    evt.preventDefault();
    evt.stopPropagation();
    // If its an update to the record.
    if (isRecordUpdate) {
      if (isPrimaryButtonDiscard) {
        onDiscard();
      } else {
        handleSaveUpdateClick();
        form.submit();
      }
    } else if (notesCompleted) {
      // If its a consult
      handleFinaliseNotesAndServicesClick();
      form.submit();
    } else {
      // The notes aren't complete, so its a close.
      const dirtyForms = checkIfFormUnsaved();
      if (!dirtyForms) {
        setIsEnding(true);
        form.submit();
        trackEventEncounterUpdated(trackEvent);
      }
    }
  };

  const buildMenuItems = () => {
    const newSubmitItems = [];

    // End encounter and close.
    if (!isRecordUpdate && !notesCompleted) {
      newSubmitItems.push({
        key: "endconsultandclose",
        text:
          appointmentStatus &&
          (appointmentStatus === AppointmentStatusCode.Finalised ||
            appointmentStatus === AppointmentStatusCode.Completed)
            ? EncounterSubmitOptions.SaveChangestAndClose
            : EncounterSubmitOptions.EndConsultAndClose,
        onClick: () => {
          const dirtyForms = checkIfFormUnsaved();
          if (!dirtyForms) {
            setCloseOnEnd(true);
            setIsEnding(true);
            form.submit();
          }
        },
        disabled: !encounter
      });
    }

    if (
      !encounter ||
      (encounter && encounter.status === EncounterStatus.Open)
    ) {
      newSubmitItems.push({
        key: "discard",
        text: isRecordUpdate
          ? EncounterSubmitOptions.DiscardUpdate
          : EncounterSubmitOptions.DiscardNotesAndServices,
        onClick: onDiscard,
        disabled: !encounter
      });
    }

    // No Charge Comment button
    if (!isRecordUpdate) {
      newSubmitItems.push({
        key: "noCharge",
        text: EncounterSubmitOptions.NoChargeForAppt,
        iconProps: noChargeComment
          ? {
              iconName: "completed",
              styles: { root: { color: theme.palette.redDark } }
            }
          : undefined,
        onClick: () => setNoChargeDialogVisibility(true)
      });
    }

    if (!isRecordUpdate) {
      // Provider Comment button
      newSubmitItems.push({
        key: "providerComment",
        text: providerComment
          ? EncounterSubmitOptions.ProviderComment
          : EncounterSubmitOptions.ProviderCommentAdd,
        iconProps: providerComment
          ? {
              iconName: "completed",
              styles: { root: { color: theme.palette.black } }
            }
          : undefined,
        onClick: openProviderCommentDialog
      });
    }

    // Add Services button
    if (!invoiceItemsAreFinalised) {
      newSubmitItems.push({
        key: "addServices",
        text: `${EncounterSubmitOptions.AddOrUpdateServices} (${invoiceItemsWithServices.length})`,
        onClick: openAddServicesDialog
      });
    } else {
      newSubmitItems.push({
        key: "viewServices",
        text: `${EncounterSubmitOptions.ViewInvoicedServices} (${invoiceItemsWithServices.length})`,
        onClick: () => setInvoicedServicesDialogVisible(true)
      });
    }

    if (isRecordUpdate) {
      newSubmitItems.push({
        key: "close",
        text: "Close",
        onClick: () => {
          clinicalRecord?.close(clinicalRecord?.openEncounter?.id);
        }
      });
    }

    return newSubmitItems;
  };

  const getPrimaryButtonText = () => {
    // Check if this is an update to a patient
    if (isRecordUpdate) {
      return isPrimaryButtonDiscard
        ? EncounterSubmitOptions.DiscardUpdate
        : EncounterSubmitOptions.SaveUpdateAndClose;
    } else {
      if (notesCompleted) {
        return EncounterSubmitOptions.FinaliseConsult;
      }

      if (
        appointmentStatus &&
        (appointmentStatus === AppointmentStatusCode.Finalised ||
          appointmentStatus === AppointmentStatusCode.Completed)
      ) {
        return EncounterSubmitOptions.SaveChanges;
      } else {
        return EncounterSubmitOptions.EndConsult;
      }
    }
  };

  const onToggleChanged = (e: MouseEvent<any>, checked: boolean) => {
    if (notesCompleted !== checked) {
      setNotesCompleted(checked);
    }
  };

  const submitDropdownItems = buildMenuItems();

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 8 }}
      verticalAlign="center"
      horizontalAlign="end"
      styles={{
        root: {
          overflow: "unset",
          paddingTop: 0
        }
      }}
    >
      <Stack
        horizontal
        styles={{ root: { marginLeft: "auto" } }}
        tokens={{ childrenGap: 4 }}
        horizontalAlign="end"
        wrap
      >
        {!isRecordUpdate && (
          <Toggle
            inlineLabel
            label="Complete "
            onText="Yes"
            offText="No"
            onChange={onToggleChanged}
            styles={{ root: { margin: "0 0 0 10px", whiteSpace: "nowrap" } }}
            automationAttribute="encounter-form-toggle"
          />
        )}

        {clinicalRecord?.calendarEvent?.providerComment && (
          <TooltipHost
            id="providerComment"
            content={clinicalRecord?.calendarEvent?.providerComment}
          >
            <FontIcon
              styles={{ root: { fontSize: 22, paddingTop: 6 } }}
              iconName="CommentActive"
            />
          </TooltipHost>
        )}

        <SplitButton
          primary
          disabled={
            !clinicalRecord?.areNotesEntered && invoiceItemsAreFinalised
          }
          text={getPrimaryButtonText()}
          onClick={onDefaultClick}
          items={submitDropdownItems}
        />
      </Stack>
    </Stack>
  );
});
