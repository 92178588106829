import React from "react";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import {
  AddBox,
  Card,
  DefaultButton,
  mergeStyles,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { WeekdaysDropdownField } from "@ui-components/form/WeekdaysDropdownField.tsx";

import { SettingsLabels } from "../../../shared-components/SettingsLabels.ts";
import { getUserStylesSet } from "../../../users/components/UserScreens.styles.tsx";
import { OrgUnitOpeningHoursFormValues } from "./OrgUnitOpeningHoursForm.types.ts";
import { OrgUnitOpeningHoursFormValidator } from "./OrgUnitOpeningHoursFormValidator.tsx";

type OrgUnitOpeningHoursFormProps = Pick<
  SubmissionFormProps<OrgUnitOpeningHoursValues>,
  "onSubmit" | "onSubmitSucceeded"
> & {
  readonly?: boolean;
  initialValues: Partial<OrgUnitOpeningHoursValues>;
};

export interface OrgUnitOpeningHoursValues {
  openingHours: OrgUnitOpeningHoursFormValues[];
}

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

export const OrgUnitOpeningHoursForm: React.FC<
  OrgUnitOpeningHoursFormProps
> = props => {
  const { readonly, initialValues, onSubmit, onSubmitSucceeded } = props;
  const theme = useTheme();
  const { formFooter, formFields } = getUserStylesSet(theme);

  const nameOf = nameOfFactory<OrgUnitOpeningHoursValues>();

  const validator = new OrgUnitOpeningHoursFormValidator();
  const validateForm = (values: OrgUnitOpeningHoursValues) =>
    (validator ?? new OrgUnitOpeningHoursFormValidator()).validate(values);

  return (
    <SubmissionForm
      formName="org-unit-opening-hours"
      validate={validateForm}
      initialValues={initialValues}
      hideButtons
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      styles={{
        main: { overflowY: "visible" },
        fields: { overflowY: "visible" }
      }}
      render={() => (
        <FieldArray name={nameOf("openingHours")}>
          {(
            fieldsRenderProps: FieldArrayRenderProps<
              OrgUnitOpeningHoursFormValues,
              HTMLElement
            >
          ) => {
            const { fields } = fieldsRenderProps;
            const count = fields.length ?? 0;
            const handleAdd = () => {
              const newOpeningHours = {
                startTime: DateTime.today()
                  .set({ hour: 8 })
                  .toTimeInputFormat(),
                endTime: DateTime.today().set({ hour: 17 }).toTimeInputFormat(),
                dayRecur: []
              };

              fields.push(newOpeningHours);
            };

            const formFieldElements = fields.map((name, index) => {
              return (
                <Stack
                  key={name}
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  styles={{
                    root: mergeStyles(
                      {
                        "&:not(:first-child)": {
                          borderTop: "1px solid",
                          borderColor: theme.palette.neutralLight,
                          marginTop: 0
                        },
                        "&:first-child": {
                          paddingTop: 32
                        },
                        "&:last-child": {
                          paddingBottom: 32
                        }
                      },
                      formFields
                    )
                  }}
                >
                  <WeekdaysDropdownField
                    name={`${name}.dayRecur`}
                    disabled={readonly}
                  />
                  <TimePickerField
                    label="Start time"
                    suggestionInterval={30}
                    disabled={readonly}
                    name={`${name}.startTime`}
                  />

                  <TimePickerField
                    label="End time"
                    suggestionInterval={30}
                    disabled={readonly}
                    name={`${name}.endTime`}
                  />
                  <Stack verticalAlign="start">
                    <DeleteButton
                      text="Remove"
                      onClick={() => {
                        fields.remove(index);
                      }}
                      styles={{
                        root: {
                          marginTop: 29
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              );
            });

            return (
              <Card
                iconName="Door"
                headingLevel="section-heading"
                heading={SettingsLabels.standardOpeningHours}
                styles={cardStyles}
                button={
                  count > 0 ? (
                    <DefaultButton
                      onClick={handleAdd}
                      iconProps={{ iconName: "Add" }}
                    >
                      Add opening hours
                    </DefaultButton>
                  ) : null
                }
              >
                <Stack>
                  {count === 0 ? (
                    <AddBox
                      text="No current opening hours"
                      subText="Use the button below to begin adding records to this area"
                      buttonText="Add opening hours"
                      onClick={handleAdd}
                      styles={{ root: { padding: "32px 64px 32px 64px" } }}
                    />
                  ) : (
                    formFieldElements
                  )}
                </Stack>
                {!readonly && (
                  <FormSubmitButtons
                    disableCancelOnPristine
                    promptOnCancel
                    styles={{ root: formFooter }}
                  />
                )}
              </Card>
            );
          }}
        </FieldArray>
      )}
    />
  );
};
