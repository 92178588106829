import { observer } from "mobx-react-lite";
import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";

import {
  ContextualMenu,
  dataAttribute,
  DataAttributes,
  DirectionalHint,
  FontIcon,
  IContextualMenuItem,
  mergeStyles,
  Stack,
  useTheme
} from "@bps/fluent-ui";

import { AppointmentNoticeType } from "../../../BookingCalendarEventModel.ts";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { ContextualMenuNoticeLabels } from "./EventTypeInfo.tsx";

export const AppointmentNoticesSubMenuItem: FunctionComponent = observer(() => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [hidden, setHidden] = useState(true);
  const theme = useTheme();

  const { appointmentNotices } = useContext(BookingCalendarEventContext);

  useEffect(() => {
    // set hidden to get div target
    if (hidden && divRef.current) {
      setTimeout(() => {
        setHidden(false);
      }, 100);
    }
  }, [hidden]);

  const getSubMenu = () => {
    return appointmentNotices.reduce<IContextualMenuItem[]>(
      (acc, apptNotice) => {
        switch (apptNotice.type) {
          case AppointmentNoticeType.noCharge:
          case AppointmentNoticeType.providerComment:
            return [
              ...acc,
              {
                key: apptNotice.type,
                text: ContextualMenuNoticeLabels[apptNotice.type],
                ...dataAttribute(
                  DataAttributes.Element,
                  `appt-notice-${apptNotice.type.toLowerCase()}`
                ),
                onRenderContent: prop => {
                  return (
                    <Stack horizontal horizontalAlign="space-between" grow>
                      <b
                        className={mergeStyles({
                          position: "relative",
                          paddingLeft: 24,
                          "&::before": {
                            position: "absolute",
                            content: "''",
                            top: 15,
                            left: 10,
                            width: 8,
                            height: 8,
                            borderRadius: "50%",
                            backgroundColor: theme.palette.themeLighter
                          }
                        })}
                      >
                        {prop.item.text}
                      </b>
                      <FontIcon
                        styles={{ root: { fontSize: 12 } }}
                        iconName="ChevronRight"
                      />
                    </Stack>
                  );
                },
                subMenuProps: {
                  items: [
                    {
                      key: apptNotice.type,
                      text:
                        apptNotice.shortDescription || apptNotice.description
                    }
                  ]
                }
              }
            ];
          default:
            return [
              ...acc,
              {
                key: apptNotice.type,
                ...dataAttribute(
                  DataAttributes.Element,
                  `appt-notice-${apptNotice.type.toLowerCase()}`
                ),
                text: ContextualMenuNoticeLabels[apptNotice.type],
                itemProps: { styles: { root: { paddingLeft: 24 } } },
                subMenuProps: {
                  items: [
                    {
                      key: apptNotice.type,
                      text:
                        apptNotice.shortDescription || apptNotice.description
                    }
                  ]
                }
              }
            ];
        }
      },
      []
    );
  };

  return (
    <div
      ref={r => {
        divRef.current = r;
      }}
    >
      {!hidden && (
        <ContextualMenu
          items={getSubMenu()}
          target={divRef.current}
          directionalHint={DirectionalHint.leftTopEdge}
          coverTarget
          gapSpace={0}
          shouldFocusOnMount={false}
          directionalHintFixed
        />
      )}
    </div>
  );
});
