import { memo } from "react";

import { ITextProps, ITheme, Text } from "@bps/fluent-ui";
import { ChangeTypes } from "@modules/billing/screens/shared-components/change-type/change-types.enum.tsx";

interface ChangeTypeProps {
  type: ChangeTypes | undefined;
  label: string;
}

const ChangeTypeBase: React.FunctionComponent<ChangeTypeProps> = ({
  type,
  label
}) => {
  if (type === undefined) return null;

  const getBackgroundColor = (type: ChangeTypes, theme: ITheme) => {
    switch (type) {
      case ChangeTypes.added:
        return theme.palette.themeDarkAlt;

      case ChangeTypes.removed:
        return theme.palette.red;

      case ChangeTypes.edited:
        return theme.palette.green;
    }
  };

  return (
    <Text
      styles={(props: ITextProps, theme: ITheme) => ({
        root: {
          position: "relative",
          paddingLeft: 15,
          selectors: {
            "&::after": {
              content: "''",
              position: "absolute",
              left: 0,
              top: 5,
              width: 11,
              height: 11,
              borderRadius: "50%",
              opacity: 0.6,
              backgroundColor: getBackgroundColor(type, theme)
            }
          }
        }
      })}
    >
      {label}
    </Text>
  );
};

export const ChangeType = memo(ChangeTypeBase);
