import { observer } from "mobx-react-lite";
import { FunctionComponent, useMemo } from "react";
import { useForm } from "react-final-form";

import {
  Accordion,
  AccordionItem,
  Pivot,
  PivotItem,
  PivotTabs,
  Separator,
  Stack
} from "@bps/fluent-ui";
import {
  BodyArea,
  InjuryAreaGroups,
  InjuryAreaSides,
  MotionType,
  SpecialTestDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useScrollToViewById } from "@ui-components/hooks/useScrollToViewById.tsx";

import { PivotLabels } from "../../../../SOTAP/SOTAP.constants.ts";
import {
  BodyExaminationFormValues,
  SpecialTestResponseType
} from "../../BodyExaminationForm.types.ts";
import { useBodyExaminationContext } from "../../context/BodyExaminationContext.ts";
import { testResponseItemsModified } from "../../utils.ts";
import { ExaminationComments } from "../ExaminationComments.tsx";
import { ExaminationSpecialTests } from "../ExaminationSpecialTests.tsx";
import {
  SideAccordionContext,
  useSideAccordionContext
} from "./context/SideAccordionContext.tsx";
import { SideAccordionHelper } from "./context/SideAccordionHelper.tsx";
import { SideMotionAccordionItem } from "./SideMotionAccordionItem.tsx";

interface SideAccordionProps {
  area: BodyArea;
  injuryAreaGroups: InjuryAreaGroups[];
  commentsModified?: boolean;
  romModified?: boolean;
  isDermAndMyotomes?: boolean;
  isCentralNervousSystem?: boolean;
  specialTest?: SpecialTestDto;
  panelWidth: number;
  specialResponseItems?: SpecialTestResponseType;
  strengthResponseItems?: SpecialTestResponseType;
  strengthTest?: SpecialTestDto;
}

const ACCORDION_ITEM_ROM_ID = "accordion-item-rom";
const SideAccordionBase: FunctionComponent<SideAccordionProps> = observer(
  ({
    area,
    injuryAreaGroups,
    commentsModified,
    isDermAndMyotomes,
    isCentralNervousSystem,
    specialTest,
    panelWidth,
    specialResponseItems,
    romModified,
    strengthResponseItems,
    strengthTest
  }) => {
    const scroll = useScrollToViewById({ behavior: "smooth" }, 1);
    const helper = useSideAccordionContext();
    const { mutators } = useForm<BodyExaminationFormValues>();

    const { setSide, setMotionType, setSpecialTestSide } = helper;

    const { currentBodyArea } = useBodyExaminationContext();

    const specialTestResponseItemsModified = testResponseItemsModified(
      currentBodyArea,
      specialResponseItems,
      specialTest?.hasSide
    );

    const strengthTestResponseItemsModified = testResponseItemsModified(
      currentBodyArea,
      strengthResponseItems,
      strengthTest?.hasSide
    );

    const renderSideMotionAccordionItem = () => {
      return injuryAreaGroups.map((group, idx) => {
        return (
          <SideMotionAccordionItem
            isSingleAccordionItem={injuryAreaGroups.length === 1}
            group={group}
            key={group}
            area={area}
            extendedByDefault={idx === 0}
          />
        );
      });
    };

    return (
      <Accordion toggleIconType="folder" disableAnimation>
        <AccordionItem
          title="ROM"
          extendedByDefault
          titleIconName="PageEdit"
          showTitleIcon={romModified}
          id={ACCORDION_ITEM_ROM_ID}
        >
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            styles={{ root: { marginBottom: 8 } }}
            verticalAlign="center"
            wrap
          >
            {area !== BodyArea.Spine && (
              <>
                <PivotTabs
                  selectedKey={helper.side}
                  onLinkClick={item => {
                    setSide(item?.props.itemKey as InjuryAreaSides);
                  }}
                  headersOnly
                >
                  <PivotItem
                    headerText={PivotLabels.Left}
                    itemKey={InjuryAreaSides.Left}
                  />
                  <PivotItem
                    headerText={PivotLabels.Right}
                    itemKey={InjuryAreaSides.Right}
                  />
                </PivotTabs>

                <Separator vertical />
              </>
            )}

            <Pivot
              headersOnly
              selectedKey={helper.motionType}
              onLinkClick={item => {
                setMotionType(item?.props.itemKey as MotionType);
              }}
            >
              <PivotItem
                headerText={PivotLabels.Active}
                itemKey={MotionType.active}
              />
              <PivotItem
                headerText={PivotLabels.Passive}
                itemKey={MotionType.passive}
              />
            </Pivot>
          </Stack>

          <Accordion disableAnimation noBorders multiple>
            {renderSideMotionAccordionItem()}
          </Accordion>
        </AccordionItem>
        {strengthTest && strengthTest?.items.length > 0 && (
          <AccordionItem
            title="Strength tests"
            showTitleIcon={strengthTestResponseItemsModified}
            titleIconName="PageEdit"
            styles={{
              content: {
                padding: 0
              }
            }}
          >
            {strengthTest.hasSide && (
              <PivotTabs
                styles={{
                  root: {
                    paddingBottom: 8
                  }
                }}
                selectedKey={helper.specialTestSide}
                onLinkClick={item => {
                  item &&
                    item.props.itemKey &&
                    setSpecialTestSide(item.props.itemKey);
                }}
                headersOnly
              >
                <PivotItem
                  headerText={PivotLabels.Left}
                  itemKey={InjuryAreaSides.Left}
                />
                <PivotItem
                  headerText={PivotLabels.Right}
                  itemKey={InjuryAreaSides.Right}
                />
              </PivotTabs>
            )}
            <ExaminationSpecialTests
              specialTest={strengthTest}
              panelWidth={panelWidth}
            />
          </AccordionItem>
        )}
        {specialTest && specialTest?.items.length > 0 && (
          <AccordionItem
            title="Special tests"
            showTitleIcon={specialTestResponseItemsModified}
            titleIconName="PageEdit"
            styles={{
              content: {
                padding: 0
              }
            }}
          >
            {specialTest.hasSide && (
              <PivotTabs
                styles={{
                  root: {
                    paddingBottom: 8
                  }
                }}
                selectedKey={helper.specialTestSide}
                onLinkClick={item => {
                  item &&
                    item.props.itemKey &&
                    setSpecialTestSide(item.props.itemKey);
                }}
                headersOnly
              >
                <PivotItem
                  headerText={PivotLabels.Left}
                  itemKey={InjuryAreaSides.Left}
                />
                <PivotItem
                  headerText={PivotLabels.Right}
                  itemKey={InjuryAreaSides.Right}
                />
              </PivotTabs>
            )}
            <ExaminationSpecialTests
              specialTest={specialTest}
              panelWidth={panelWidth}
            />
          </AccordionItem>
        )}

        <AccordionItem
          title="Comments"
          showTitleIcon={commentsModified}
          titleIconName="PageEdit"
          onClick={() => {
            scroll(ACCORDION_ITEM_ROM_ID);
          }}
        >
          <ExaminationComments
            mutators={mutators}
            isDermAndMyotomes={isDermAndMyotomes}
            isCentralNervousSystem={isCentralNervousSystem}
          />
        </AccordionItem>
      </Accordion>
    );
  }
);

export const SideAccordion: FunctionComponent<SideAccordionProps> = props => {
  // needs to re-initialize SideAccordionHelper helper values if current body area has been switched
  const helper = useMemo(
    () => new SideAccordionHelper(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.area]
  );
  return (
    <SideAccordionContext.Provider value={helper}>
      <SideAccordionBase {...props} />
    </SideAccordionContext.Provider>
  );
};
