import { DiagnosisSides } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { SideOfBody } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";

export const convertClinicalSideToDiagnosisSide = (
  value: string | undefined
) => {
  switch (value) {
    case SideOfBody.Left:
      return ` (${capitalizeSentence(DiagnosisSides.Left)})`;
    case SideOfBody.Right:
      return ` (${capitalizeSentence(DiagnosisSides.Right)})`;
    case SideOfBody.Neither:
      return "";
    case SideOfBody.Both:
      return ` (${capitalizeSentence(DiagnosisSides.Bilateral)})`;
    default:
      return "";
  }
};
