import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DateTime } from "@bps/utils";
import { BillingStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import {
  AdjustmentName,
  TransactionAdjustedBarBase
} from "@modules/billing/screens/shared-components/TransactionAdjustedBarBase.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { TransactionCancelReasonText } from "@ui-components/RefText.tsx";

const InvoiceViewInfoBarBase: React.FC = observer(() => {
  const { isStatusCancelledOrAdjusted, invoice } =
    useContext(InvoiceViewContext);

  const { billing } = useStores();

  const getReasonText = () => {
    if (
      invoice.adjustmentReason &&
      billing.ref.transactionCancelReason.get(invoice.adjustmentReason)
    ) {
      return <TransactionCancelReasonText code={invoice.adjustmentReason} />;
    }
    return <>{invoice.adjustmentReason}</>;
  };

  if (!invoice.changeLog || !isStatusCancelledOrAdjusted) {
    return null;
  }

  let actionText: string = "";
  if (invoice.status === BillingStatuses.cancelled) {
    actionText = AdjustmentName.cancelledBy;
  } else if (invoice.status === BillingStatuses.adjusted) {
    actionText = AdjustmentName.adjustedBy;
  }

  const updatedDate = DateTime.fromISO(
    invoice.changeLog.updatedDate
  )?.toDayDefaultFormat();

  const reasonText = getReasonText();

  return (
    <TransactionAdjustedBarBase
      userName={invoice.changeLog.createdBy}
      transactionName="invoice"
      adjustmentName={actionText}
      reason={reasonText}
      updatedDate={updatedDate}
    />
  );
});

export const InvoiceViewInfoBar = withFetch(
  x => [x.billing.ref.transactionCancelReason.load()],
  InvoiceViewInfoBarBase
);
