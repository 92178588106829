import { useForm } from "react-final-form";

import {
  DefaultButton,
  Heading,
  IContextualMenuItem,
  Stack
} from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { PatientEntitlementsLabels } from "../../patient/view/PatientEntitlements.tsx";
import { EntitlementsFields } from "./EntitlementsFields.tsx";
import { EntitlementsNZFields } from "./EntitlementsNZFields.tsx";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";
import { defaultCSC, defaultDVA, defaultHealthFund } from "./utils.ts";

export const EditEntitlements: React.FC = () => {
  const {
    core: { tenantDetails }
  } = useStores();

  const { getState, change } = useForm<PatientEditFormValues>();
  const { entitlements, healthInsurance, dva, csc } = getState().values;

  const hideAddButton: boolean = !!(tenantDetails?.country === Country.Australia
    ? entitlements && healthInsurance && dva
    : csc);

  const addHealthFund = () => {
    change(patientFormNameOf("healthInsurance"), defaultDVA);
  };

  const addDVA = () => {
    change(patientFormNameOf("dva"), defaultHealthFund);
  };

  const addCSC = () => {
    change(patientFormNameOf("csc"), defaultCSC);
  };

  const dvaMenuItem: IContextualMenuItem[] = !dva
    ? [
        {
          key: "DVA",
          text: PatientEntitlementsLabels.DVA,
          onClick: addDVA
        }
      ]
    : [];

  const hFundMenuItem: IContextualMenuItem[] = !healthInsurance
    ? [
        {
          key: "Healthfund",
          text: PatientEntitlementsLabels.HealthFund,
          onClick: addHealthFund
        }
      ]
    : [];

  const cscMenuItem: IContextualMenuItem[] = !csc
    ? [
        {
          key: "CSC",
          text: PatientEntitlementsLabels.CSC,
          onClick: addCSC
        }
      ]
    : [];

  return (
    <div
      id={`${PatientCardIds.patientEntitlements}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { lineHeight: "22px", marginBottom: 6 } }}
      >
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          {Labels.cardsEntitlements}
        </Heading>
        {!hideAddButton && (
          <DefaultButton
            id="add-more-entitlements-btn"
            iconProps={{ iconName: "Add" }}
            text={Labels.addMore}
            menuProps={{
              shouldFocusOnMount: true,
              items:
                tenantDetails?.country === Country.Australia
                  ? [...hFundMenuItem, ...dvaMenuItem]
                  : [...cscMenuItem]
            }}
          />
        )}
      </Stack>
      <WhenCountry is={Country.Australia}>
        <EntitlementsFields />
      </WhenCountry>
      <WhenCountry is={Country.NewZealand}>
        <EntitlementsNZFields addCSC={addCSC} />
      </WhenCountry>
    </div>
  );
};
