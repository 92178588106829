import { action, observable } from "mobx";

import {
  InjuryAreaSides,
  MotionType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export class SideAccordionHelper {
  @observable
  public side: InjuryAreaSides = InjuryAreaSides.Left;

  @observable
  public specialTestSide: string = InjuryAreaSides.Left;

  @observable
  public motionType: MotionType = MotionType.active;

  @action
  public setSpecialTestSide = (specialTestSide: string) => {
    this.specialTestSide = specialTestSide;
  };

  @action
  public setSide = (side: InjuryAreaSides) => {
    this.side = side;
  };

  @action
  public setMotionType = (motionType: MotionType) => {
    this.motionType = motionType;
  };
}
