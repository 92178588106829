import {
  CalendarEventAttendeeDto,
  Frequency
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  integer,
  isDateAfterField,
  isDateBeforeField,
  lessThan,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";

import { getActiveAttendees } from "./group-attendees-details/GroupAttendeesHelper.ts";

export class AppointmentFormValidator extends Validator<AppointmentFormValues> {
  constructor(isGroupAppointment: boolean) {
    super();

    this.forField("startTime", [required()]);
    this.forField("startDate", [required()]);
    if (!isGroupAppointment) {
      this.forField("patientId", [required()]);
    }
    this.forField("appointmentTypeId", [required()]);
    this.forField("providerId", [required()]);

    this.forField("orgUnitId", [required()]);

    this.forField("duration", [
      predicate(x => x !== 0, required()), // treat zero as value entered
      integer(),
      greaterThan(0)
    ]);

    this.forField(nameof("frequency"), required(), {
      when: (value, parent) => parent.repeat === true
    });

    this.forField(nameof("endScheduleType"), required(), {
      when: (value, parent) => parent.repeat === true
    });

    this.forField(
      nameof("interval"),
      [
        predicate(x => x !== 0, required()), // treat zero as value entered
        integer(),
        greaterThan(0),
        lessThan(100)
      ],
      { when: (value, parent) => parent.repeat === true }
    );

    this.forField(nameof("count"), [
      predicate(
        (val, appt: AppointmentFormValues) =>
          appt.repeat === true &&
          appt.endScheduleType === EndScheduleType.After &&
          appt.count !== 0,
        required()
      ),
      predicate(
        (val, appt: AppointmentFormValues) =>
          appt.count !== 0 &&
          appt.count !== undefined &&
          appt.recurrenceOccurred !== undefined &&
          appt.count < appt.recurrenceOccurred,
        () => "Must be greater than completed occurrences"
      ),
      integer(),
      greaterThan(0),
      lessThan(100)
    ]);

    this.forField(nameof("until"), [
      predicate(
        (val, appt: AppointmentFormValues) =>
          appt.repeat === true &&
          appt.endScheduleType === EndScheduleType.OnDate,
        required()
      )
    ]);

    this.forField(nameof("until"), [
      predicate(
        (val, appt: AppointmentFormValues) =>
          appt.repeat === true &&
          appt.endScheduleType === EndScheduleType.OnDate,
        isDateBeforeField(
          ValidationMessages.endDateBeforeStartDate,
          "startDate"
        )
      )
    ]);

    this.forField(nameof("priority"), [
      predicate(
        (val, appt: AppointmentFormValues) => appt.waitingFieldToggle === true,
        required()
      )
    ]);

    this.forField(nameof("expiryDate"), [
      predicate(
        (val, appt: AppointmentFormValues) => appt.waitingFieldToggle === true,
        required()
      ),
      predicate(
        (val, appt: AppointmentFormValues) => appt.waitingFieldToggle === true,
        isDateAfterField(
          ValidationMessages.expiryDateValidation,
          "startDate",
          false
        )
      )
    ]);

    this.forField(nameof("dayRecur"), [
      predicate(
        (val, appt: AppointmentFormValues) =>
          appt.repeat === true && appt.frequency === Frequency.Week,
        required(ValidationMessages.dayRecurrences)
      )
    ]);

    this.forField(nameof("monthYearRecurrence"), [
      predicate(
        (val, appt: AppointmentFormValues) =>
          appt.repeat === true && appt.frequency === Frequency.Month,
        required(ValidationMessages.monthRecurrences)
      )
    ]);

    this.forField(nameof("monthYearRecurrence"), [
      predicate(
        (val, appt: AppointmentFormValues) =>
          appt.repeat === true && appt.frequency === Frequency.Year,
        required(ValidationMessages.yearRecurrences)
      )
    ]);

    if (isGroupAppointment) {
      this.forField(nameof("maxParticipants"), [
        required(),
        integer(),
        greaterThan(0),
        lessThan(1001),
        predicate(
          (val, appt: AppointmentFormValues) => {
            const activeAttendees = getActiveAttendees(
              appt?.groupAttendees || []
            );
            return (
              activeAttendees.length > 0 && activeAttendees.length > (val ?? 0)
            );
          },
          () => ValidationMessages.maxParticipantsLessThanActive
        )
      ]);

      this.forField(nameof("groupDescription"), maxLength(255));
    }

    this.forArrayField(
      nameof("groupAttendees"),
      (
        value: CalendarEventAttendeeDto,
        _,
        allValues: AppointmentFormValues
      ) => {
        // Should ignore undefined / empty string caused by patient picker with no patient selected

        if (value && value.attendeeId) {
          const occurrences = allValues.groupAttendees?.filter(
            a => a.attendeeId === value.attendeeId
          );
          if (occurrences && occurrences.length > 1) {
            return ValidationMessages.appointmentClientDuplicate;
          }
        }
        return false;
      }
    );
  }
}
