import { observer } from "mobx-react-lite";
import { FC, Fragment } from "react";

import {
  PersonaSize,
  Pivot,
  PivotItem,
  Stack,
  StackItem,
  useTheme
} from "@bps/fluent-ui";
import {
  ContactType,
  EmployerType,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { employerRelationships } from "@shared-types/practice/employer.relationship.constant.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { RelationshipTypeText } from "@ui-components/RefText.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import {
  noRelationshipsObject,
  relationshipsPriorityOrder
} from "../../../shared-components/utils.ts";
import { familyRelationships } from "../../constants/family-relationships.constant.ts";
import { otherRelationships } from "../../constants/other-relationships.constant.ts";
import { priorityRelationships } from "../../constants/priority-relationships.constant.ts";
import { professionalRelationships } from "../../constants/professional-relationships.constant.ts";
import { RelationshipGroup } from "../../constants/relationship-group.constant.ts";
import { Relationship } from "../../constants/relationship.constant.ts";
import { CompoundButtonWithHoveredIcon } from "./CompoundButtonWithHoveredIcon.tsx";
import { NoDetailsButton } from "./NoDetailsButton.tsx";

export interface ContactRelationshipListProps {
  showEditIcon: boolean;
  contact: Contact;
  onHandleEditModal: (cardId?: string) => void;
}

export const ContactRelationshipList: FC<ContactRelationshipListProps> =
  observer(({ contact, onHandleEditModal, showEditIcon }) => {
    const {
      practice: {
        ui: { showContactDetails }
      }
    } = useStores();

    const theme = useTheme();
    const { noRelationships, labelSmall, personaText } =
      getPeopleScreenStylesSet(theme);

    const renderSecondaryText =
      (relationship: Relationship) => (): JSX.Element => {
        return (
          <Stack
            verticalAlign="center"
            verticalFill
            tokens={{ childrenGap: 4 }}
            styles={{
              root: {
                whiteSpace: "normal",
                wordBreak: "break-word"
              }
            }}
          >
            <RelationshipTypeText code={relationship!.relationshipTypes} />
            <StackItem styles={labelSmall}>
              {relationship!.contact.primaryCommunication &&
                relationship!.contact.primaryCommunication.value}
            </StackItem>
          </Stack>
        );
      };

    const renderRelationshipCard = (
      relationships: Relationship[]
    ): JSX.Element => {
      return (
        <Stack
          styles={{ root: { padding: "0 10px" } }}
          tokens={{ childrenGap: 8 }}
        >
          {relationships.map((re, idx) => (
            <Fragment key={`${re.contact.id}-${idx.toString()}`}>
              <CompoundButtonWithHoveredIcon
                onClick={() => showContactDetails(re.contact.id)}
              >
                <Persona
                  styles={personaText}
                  id={re.contact.id}
                  contactType={re.contact.type}
                  size={PersonaSize.size48}
                  imageInitials={re.contact.initials}
                  imageUrl={re.contact.profilePictureUrl}
                  text={re.contact.name}
                  status={re.contact.status}
                  onRenderSecondaryText={
                    re ? renderSecondaryText(re) : undefined
                  }
                />
              </CompoundButtonWithHoveredIcon>
            </Fragment>
          ))}
        </Stack>
      );
    };

    const filterRelationshipBy = (
      types: RelationshipType[]
    ): Relationship[] => {
      const isArrayType = (arrType: RelationshipType) =>
        types.includes(arrType);
      return contact.relationshipsPerContact
        .filter(rel =>
          rel.relationshipTypes
            .sort(
              (a, b) =>
                relationshipsPriorityOrder(a) - relationshipsPriorityOrder(b)
            )
            .some(isArrayType)
        )
        .sort((a, b) => {
          if (a.contact.reversedName! > b.contact.reversedName!) return 1;
          if (b.contact.reversedName! > a.contact.reversedName!) return -1;
          return 0;
        });
    };

    const filterEmployeeOrg = (
      relationships: Relationship[],
      type: EmployerType
    ): Relationship[] => {
      const employees: Relationship[] = [];
      const isCurrent = type === EmployerType.Current;
      relationships.forEach(value => {
        const isEmployee = value.contact.relationships.some(
          x =>
            x.relatedContactId === contact.id &&
            x.metadata?.currentJob === isCurrent &&
            x.relationship === RelationshipType.Employer
        );
        if (isEmployee) {
          employees.push(value);
        }
      });
      return employees;
    };

    const filteredProfessionalRel = filterRelationshipBy(
      professionalRelationships
    );

    const filteredPriorityRel = filterRelationshipBy(priorityRelationships);

    const filteredFamilyRel = filterRelationshipBy(familyRelationships);
    const filteredOtherRel = filterRelationshipBy(otherRelationships);

    const filteredCurrentEmployee = filterEmployeeOrg(
      filterRelationshipBy(employerRelationships),
      EmployerType.Current
    );

    const filteredPreviousEmployee = filterEmployeeOrg(
      filterRelationshipBy(employerRelationships),
      EmployerType.Previous
    );

    const pivotItemArray = [
      {
        itemKey: RelationshipGroup.Priority,
        headerText: "Priority",
        relationships: filteredPriorityRel,
        text: RelationshipGroup.Priority
      },
      {
        itemKey: RelationshipGroup.Family,
        headerText: "Family",
        relationships: filteredFamilyRel,
        text: RelationshipGroup.Family
      },
      {
        itemKey: RelationshipGroup.Professional,
        headerText: "Professional",
        relationships: filteredProfessionalRel,
        text: RelationshipGroup.Professional
      },
      {
        itemKey: RelationshipGroup.Other,
        headerText: "Other",
        relationships: filteredOtherRel,
        text: ""
      }
    ];

    const pivotItemArrayOrg = [
      {
        itemKey: RelationshipGroup.CurrentEmployee,
        headerText: "Current employee",
        relationships: filteredCurrentEmployee,
        text: "Current employee"
      },
      {
        itemKey: RelationshipGroup.PreviousEmployee,
        headerText: "Previous",
        relationships: filteredPreviousEmployee,
        text: "Previous employee"
      },
      {
        itemKey: RelationshipGroup.Other,
        headerText: "Other",
        relationships: filteredProfessionalRel,
        text: RelationshipGroup.Other
      }
    ];

    const getPivotItemArray = () =>
      contact.type === ContactType.Organisation
        ? pivotItemArrayOrg
        : pivotItemArray;

    let defaultSelectedKey: string;
    if (filteredCurrentEmployee.length) {
      defaultSelectedKey = RelationshipGroup.CurrentEmployee;
    } else if (filteredPreviousEmployee.length) {
      defaultSelectedKey = RelationshipGroup.PreviousEmployee;
    } else if (filteredProfessionalRel.length) {
      defaultSelectedKey = RelationshipGroup.Other;
    } else {
      defaultSelectedKey = RelationshipGroup.CurrentEmployee;
    }

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        {showEditIcon ? (
          <Pivot
            styles={{ linkIsSelected: { paddingLeft: 10 } }}
            defaultSelectedKey={defaultSelectedKey}
          >
            {getPivotItemArray().map(pivotItem => (
              <PivotItem
                key={pivotItem.itemKey + pivotItem.text}
                headerText={`${pivotItem.headerText} (${pivotItem.relationships.length})`}
                itemKey={pivotItem.itemKey}
                style={
                  !pivotItem.relationships.length
                    ? { ...noRelationships }
                    : undefined
                }
              >
                <div style={{ marginTop: 11 }}>
                  {pivotItem.relationships.length > 0 &&
                    renderRelationshipCard(pivotItem.relationships)}
                  {!pivotItem.relationships.length &&
                    `No ${pivotItem.text} relationships`}
                </div>
              </PivotItem>
            ))}
          </Pivot>
        ) : (
          <NoDetailsButton
            secondaryText={noRelationshipsObject.secondaryText[contact.type]}
            sectionId={noRelationshipsObject.sectionId[contact.type]}
            actionTitle={noRelationshipsObject.actionTitle[contact.type]}
            noPermissionText={
              noRelationshipsObject.noPermissionText[contact.type]
            }
            onHandleEditModal={onHandleEditModal}
          />
        )}
      </Stack>
    );
  });
