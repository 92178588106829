import {
  FontIcon,
  ITooltipHostProps,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

interface CommentToolTipProps extends Pick<ITooltipHostProps, "content"> {
  solid?: boolean;
}

export const CommentToolTip: React.FC<CommentToolTipProps> = ({
  solid,
  content
}) => {
  const theme = useTheme();
  return (
    <TooltipHost
      tooltipProps={{
        calloutProps: {
          calloutMaxWidth: 270,
          styles: { calloutMain: { padding: 8 } }
        }
      }}
      content={content}
    >
      <FontIcon
        iconName={solid ? "CommentSolid" : "CannedChat"}
        styles={{
          root: {
            color: solid ? theme.palette.themePrimary : "initial",
            fontSize: 16,
            paddingTop: 2,
            display: "block"
          }
        }}
      />
    </TooltipHost>
  );
};
