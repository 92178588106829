import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { ReasonsForDiscard } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  EncounterDiscardFormValues,
  EncounterDiscardValidator
} from "./EncounterDiscardFormValues.tsx";

const nameof = nameOfFactory<EncounterDiscardFormValues>();

const EncounterDiscardDialogBase: React.FC = observer(() => {
  const { clinical, core } = useStores();

  const validator = new EncounterDiscardValidator(core);

  const discardReasonRequired =
    core.user?.userSetting?.clinicalView?.enforceDiscardConsultReason ?? true;

  const {
    closeDiscardConfirmationDialog,

    isConfirmationDialogVisible
  } = clinical.ui;

  const patientRecordScreenHelper = usePatientRecordScreenContext();

  const { clinicalRecord, discardEncounter } = patientRecordScreenHelper;

  const isHidden = !isConfirmationDialogVisible;

  useEffect(() => {
    if (isHidden && patientRecordScreenHelper.encounterFormApi) {
      patientRecordScreenHelper.encounterFormApi.reset();
    }
  }, [isHidden, patientRecordScreenHelper.encounterFormApi]);

  if (isHidden) {
    return null;
  }

  return (
    <SubmissionFormDialog
      dialogName="Discard Consultation"
      disablePrompt
      dialogProps={{
        minWidth: 480,
        maxWidth: 480,
        onDismiss: closeDiscardConfirmationDialog,
        dialogContentProps: {
          title: "Discard consultation",
          showCloseButton: false
        }
      }}
      onSubmit={(values: EncounterDiscardFormValues) =>
        discardEncounter(values, clinicalRecord)
      }
      buttonsProps={{
        disableSubmitOnPristine: discardReasonRequired,
        submitButtonProps: {
          text: "Discard",
          iconProps: {}
        },
        cancelButtonProps: {
          text: "Keep editing"
        },
        onCancel: closeDiscardConfirmationDialog,
        hideButtonsSeparator: true
      }}
      validate={values => validator.validate(values)}
    >
      {() => (
        <Fieldset>
          <ChoiceGroupField
            options={clinical.ref.reasonsForDiscard.keyTextValues}
            name={nameof("reasonForDiscard")}
            label="Reason"
            required={discardReasonRequired}
          />

          <FieldCondition
            when={nameof("reasonForDiscard")}
            is={ReasonsForDiscard.Other}
          >
            <TextInputField
              multiline={true}
              name={nameof("reasonForDiscardComment")}
              label="Comments"
              resizable={false}
              rows={4}
            />
          </FieldCondition>
        </Fieldset>
      )}
    </SubmissionFormDialog>
  );
});

export const EncounterDiscardDialog = withFetch(
  x => [x.clinical.ref.reasonsForDiscard.load()],
  EncounterDiscardDialogBase
);
