import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ClaimAdjustmentModalValues } from "../types/claim-adjustment-modal-values.interface.ts";

export class ClaimAdjustmentModalValidator extends Validator<ClaimAdjustmentModalValues> {
  constructor() {
    super();

    this.forField(
      "claimAdjustmentReasons",
      required(ValidationMessages.atLeastOneOptionSelected)
    );

    this.forField("responseDate", [
      predicate((val, values) => !!values?.verballyApproved, required())
    ]);
  }
}
