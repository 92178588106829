import { action, computed, observable, runInAction } from "mobx";

import { IContextualMenuItem } from "@bps/fluent-ui";
import { NotFoundError } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AppointmentStatusCode,
  AppointmentTypeCode,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  FormTemplateDTO,
  FormTemplateTypeCode
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import {
  addNewClaim,
  createNewClaimDto
} from "@modules/acc/screens/claim/components/utils.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

import { ContextMenuItemsEnum } from "./components/booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";

export enum AppointmentNoticeType {
  comment = "comment",
  interpreter = "interpreter",
  noCharge = "noCharge",
  providerComment = "providerComment"
}

export interface AppointmentNotice {
  isNew?: boolean;
  label: string | undefined;
  type: AppointmentNoticeType;
  shortDescription?: string;
  description: string;
}

export enum NoticeLabels {
  account = "Account",
  cardExpired = "Card expired",
  comment = "Appointment comment",
  interpreter = "Interpreter",
  noCharge = "No charge",
  providerComment = "Provider comment",
  meetingComment = "Meeting comment",
  unavailableComment = "Unavailable comment"
}

export class BookingCalendarEventModel {
  constructor(
    private root: IRootStore,
    public calendarEvent: CalendarEvent
  ) {}

  @observable
  public invoiceItems: InvoiceItemDto[] | undefined = undefined;

  public canStatusChange: boolean | undefined = undefined;

  @observable
  public preventDismiss: boolean = false;

  @observable
  public isNoChargeDialogVisible: boolean = false;

  @observable
  public openedAttendeeId: string | undefined = undefined;

  private get acc() {
    return this.root.acc;
  }

  private get billing() {
    return this.root.billing;
  }

  private get core() {
    return this.root.core;
  }

  private get practice() {
    return this.root.practice;
  }

  private get booking() {
    return this.root.booking;
  }

  private get routing() {
    return this.root.routing;
  }

  private get clinical() {
    return this.root.clinical;
  }
  @action setNoChargeDialogVisibility = (visibility: boolean) => {
    this.isNoChargeDialogVisible = visibility;
  };

  @action
  public setOpenedAttendeeId = (id: string | undefined) => {
    this.openedAttendeeId = id;
  };

  @action
  public setPreventDismiss = (value: boolean) => {
    this.preventDismiss = value;
  };

  @action loadInvoiceItems = async () => {
    if (!this.core.hasPermissions(Permission.InvoiceView)) return;

    const invoiceItems = await this.billing
      .fetchInvoiceItems({
        calendarEventIds: [this.calendarEvent.id],
        take: 1000
      })
      .then(x => x.results);
    runInAction(() => {
      this.invoiceItems = [...invoiceItems];
    });
  };

  public loadCanStatusChange = async () => {
    this.canStatusChange =
      await this.booking.getCalendarEventAppointmentStatusChangeDateTimeLimit(
        this.calendarEvent.id
      );
  };

  getIsMenuDisable = (contactId: string) => {
    const status = this.getAppointmentOrAttendeeStatus(contactId);

    return (
      (status === AppointmentStatusCode.Finalised ||
        status === AppointmentStatusCode.Completed ||
        status === AppointmentStatusCode.DidNotAttend) &&
      !this.calendarEvent.userHasOpenEncounterWithPatient(contactId)
    );
  };

  getAppointmentOrAttendeeStatus = (contactId: string) => {
    const calendarEventAttendee = this.calendarEvent.activeAttendees?.find(
      a => a.attendeeId === contactId
    );
    return (
      calendarEventAttendee?.attendeeStatus || this.currentAppointmentStatus
    );
  };

  @computed
  get isRecordUpdateDisable() {
    return (
      !this.core.hasPermissions(Permission.EncounterWrite) ||
      this.currentAppointmentStatus === AppointmentStatusCode.Finalised ||
      this.currentAppointmentStatus === AppointmentStatusCode.Completed
    );
  }

  get currentAppointmentStatus() {
    return this.calendarEvent.appointmentStatus ?? AppointmentStatusCode.Booked;
  }

  get isAppointment() {
    return this.calendarEvent.type === CalendarEventType.Appointment;
  }
  get isPatientCalendarEvent() {
    return this.calendarEvent.isPatientCalendarEvent;
  }

  get isUnavailableType() {
    return this.calendarEvent.type === CalendarEventType.Unavailable;
  }

  get isSeries() {
    return (
      (this.isUnavailableType ||
        this.calendarEvent.type === CalendarEventType.Appointment) &&
      !!this.calendarEvent.calendarEventRecurrenceId
    );
  }

  get eventType() {
    return this.calendarEvent?.typeRef?.text || "event";
  }

  get pastDayAppointment() {
    return this.calendarEvent.startDateTime < DateTime.now();
  }

  get futureDayAppointment() {
    return this.calendarEvent.startDateTime.startOf("day") > DateTime.now();
  }

  get todayAppointment() {
    return this.calendarEvent.startDateTime.isToday;
  }

  @computed get appointmentNotices() {
    return this.adoptAppointmentNoticesDataToClient();
  }

  isStatusUpdating = observable.box<boolean>(false);

  isRecurrenceDeleted = observable.box<boolean>(false);

  @computed get claim() {
    return this.calendarEvent.claim;
  }

  public getClaim = async () => {
    return this.calendarEvent.loadClaim();
  };

  public getCondition = async (): Promise<ICondition | undefined> => {
    if (this.calendarEvent?.reason?.episodeOfCareId) {
      const [episodeOfCare, claim] = await Promise.all([
        this.root.clinical.getEpisodeOfCare(
          this.calendarEvent.reason.episodeOfCareId
        ),
        this.core.hasPermissions(Permission.ClaimRead)
          ? this.calendarEvent.loadClaim()
          : undefined
      ]);

      return {
        episodeOfCareId: episodeOfCare.id,
        claim,
        patientId: claim?.patientId,
        providerId: claim?.providerId,
        primaryDiagnosis:
          episodeOfCare.diagnoses?.find(d => d.isPrimaryDiagnosis)
            ?.diagnosisCode?.originalText ?? ClaimFormLabels.undiagnosed,
        isPrivate: !claim,
        referralNumber: episodeOfCare.referralNumber,
        discharged: episodeOfCare.discharged,
        createdDate: DateTime.fromISO(episodeOfCare.changeLog?.createdDate)!
      };
    }

    return undefined;
  };

  private isRecurrencePartOfSeriesDeleted = async () => {
    let isDeletedEvent = false;
    if (this.calendarEvent.calendarEventRecurrenceId) {
      try {
        await this.booking.getRecurrence(
          this.calendarEvent.calendarEventRecurrenceId
        );
      } catch (error) {
        if (error instanceof NotFoundError) {
          isDeletedEvent = true;
        }
      }
    }
    return isDeletedEvent;
  };

  private adoptAppointmentNoticesDataToClient = (): AppointmentNotice[] => {
    const appointmentNotices: AppointmentNotice[] = [];

    // Notices need to be in the following order:
    // 1. No Charge comment
    // 2. Provider comment
    // 3+ Every other notice sorted alphabetically by label

    //No charge
    if (this.calendarEvent.noChargeComment) {
      appointmentNotices.push({
        label: NoticeLabels.noCharge,
        type: AppointmentNoticeType.noCharge,
        description: this.calendarEvent.noChargeComment,
        isNew: true
      });
    }

    // Provider comment
    if (this.calendarEvent.providerComment) {
      appointmentNotices.push({
        label: NoticeLabels.providerComment,
        type: AppointmentNoticeType.providerComment,
        description: this.calendarEvent.providerComment,
        isNew: true
      });
    }
    let label: NoticeLabels = NoticeLabels.comment;
    if (this.calendarEvent.type === CalendarEventType.Meeting) {
      label = NoticeLabels.meetingComment;
    } else if (this.calendarEvent.type === CalendarEventType.Unavailable) {
      label = NoticeLabels.unavailableComment;
    }

    //Comment
    if (this.calendarEvent.content) {
      appointmentNotices.push({
        label,
        type: AppointmentNoticeType.comment,
        description: this.calendarEvent.content
      });
    }

    // Interpreter
    if (this.calendarEvent.contact?.interpreterLanguageValue)
      appointmentNotices.push({
        label: NoticeLabels.interpreter,
        type: AppointmentNoticeType.interpreter,
        description: this.calendarEvent.contact?.interpreterLanguageValue
      });

    return appointmentNotices;
  };

  public getStates = async () => {
    const isRecurrenceDeleted = await this.isRecurrencePartOfSeriesDeleted();
    runInAction(() => {
      this.isRecurrenceDeleted.set(isRecurrenceDeleted);
    });

    //Adding a not condition, as the dropdown need to disabled when more than 24 hours, Api returning false which makes dropdown enabled
    const updatingStatus =
      !(await this.booking.getCalendarEventAppointmentStatusChangeDateTimeLimit(
        this.calendarEvent.id
      ));

    runInAction(() => {
      this.isStatusUpdating.set(updatingStatus);
    });
  };

  public changeAppointmentStatus = async (
    appointmentStatus: AppointmentStatusCode,
    currentAppointmentStatus?: AppointmentStatusCode
  ) => {
    if (appointmentStatus === currentAppointmentStatus) {
      return;
    }

    runInAction(() => {
      this.isStatusUpdating.set(true);
    });

    try {
      return await this.booking.updateCalendarEventAppointmentStatus(
        this.calendarEvent.id,
        {
          appointmentStatus,
          calendarEventId: this.calendarEvent.id
        }
      );
    } finally {
      runInAction(() => {
        this.isStatusUpdating.set(false);
      });
    }
  };

  public showCancelEvent = () => {
    this.booking.ui.setCancelCalendarEventId(this.calendarEvent.id);

    if (this.calendarEvent.calendarEventRecurrenceId !== null) {
      this.booking.ui.setCancelCalendarEventRecurrenceId(
        this.calendarEvent.calendarEventRecurrenceId
      );
    }
    this.booking.ui.setShowCancelCalendarEventDialog(
      true,
      this.calendarEvent.id
    );
  };

  public onShowEdit = (cardId: PatientCardIds) => () => {
    if (!this.calendarEvent.contact || !this.calendarEvent.contactId) return;

    const hasPatientWritePermission = this.core.hasPermissions([
      Permission.PatientWrite
    ]);

    const id = this.calendarEvent.contactId;

    if (hasPatientWritePermission) this.practice.ui.showEditContact(cardId, id);
    else this.routing.push(routes.contacts.contact.path({ id }));
  };

  addClaimBeforeFormDeploy = async (
    selectedTemplate: FormTemplateDTO,
    currentContext: Record<string, string>
  ) => {
    //If no claim is in the context and it is an Acc form, then add an Acc claim to be associated with the form context
    if (
      selectedTemplate.code ===
        (FormTemplateTypeCode.acc || FormTemplateTypeCode.acc45Demographic) &&
      !currentContext.ClaimId
    ) {
      try {
        const claimDto = await createNewClaimDto({
          root: this.root,
          providerUser: this.calendarEvent.user!,
          patient: this.calendarEvent.contact!
        });

        const newClaim = await addNewClaim(claimDto, this.root.acc);
        this.root.notification.success("A new claim has been created");
        await this.booking.updateCalendarEvent({
          id: this.calendarEvent.id,
          reason: { episodeOfCareId: newClaim.id }
        });
        currentContext.ClaimId = newClaim.id;
      } catch (error) {
        this.root.notification.error("Failed creating claim");
        throw error;
      }
    }
    return currentContext;
  };

  @computed get enabled() {
    return (
      this.calendarEvent.type === CalendarEventType.Appointment &&
      this.calendarEvent.contact?.type === ContactType.Patient
    );
  }

  getClinicalMenuItems = (attendeeId: string) => {
    const clinicalOnClick = (contactId: string) => {
      if (this.enabled) {
        this.routing.push(
          routes.records.appointment.path({
            id: contactId,
            calendarEventId: this.calendarEvent.id
          }),
          this.routing.getStateWithFromQuery()
        );
      }
    };

    const appointmentTypeCode = this.calendarEvent.appointmentType?.code;
    const commandBarItemsList: IContextualMenuItem[] = [
      {
        key: "start-visit",
        id: "booking-event-context-start-visit-btn",
        text: this.calendarEvent.userHasOpenEncounterWithPatient(attendeeId)
          ? ContextMenuItemsEnum.CompleteConsult
          : ContextMenuItemsEnum.StartConsult,
        onClick: () => clinicalOnClick(attendeeId),
        disabled:
          this.getIsMenuDisable(attendeeId) ||
          !this.core.hasPermissions(Permission.EncounterWrite) ||
          (this.core.hasPermissions(Permission.LicencingAllowed) &&
            !this.core.hasPermissions(Permission.CreateConsultAllowed))
      },
      {
        key: "view-record",
        id: "booking-event-context-view-record-btn",
        text: ContextMenuItemsEnum.ViewRecord,
        onClick: () =>
          this.routing.push(
            routes.records.appointmentView.path({
              id: attendeeId,
              calendarEventId: this.calendarEvent.id
            }),
            this.routing.getStateWithFromQuery()
          )
      }
    ];

    appointmentTypeCode === AppointmentTypeCode.RecordUpdate &&
      (commandBarItemsList[0] = {
        key: "recordUpdate",
        id: "record-update-clinical-menu-item",
        text: ContextMenuItemsEnum.RecordUpdate,
        onClick: () => clinicalOnClick(attendeeId),
        disabled:
          this.isRecordUpdateDisable ||
          !this.core.hasPermissions(Permission.EncounterWrite)
      });

    return commandBarItemsList;
  };

  loadPatientNotices = async () => {
    const patientIds = this.calendarEvent.activeAttendees.map(
      a => a.attendeeId
    );

    await Promise.all([
      this.practice.getPatientNoticesByArgs({
        patientIds
      }),
      this.practice.loadSystemNotices(patientIds)
    ]);
  };
  updateNoChargeComment = async (
    noChargeComment: string | undefined,
    patientId: string
  ) => {
    if (this.calendarEvent) {
      const attendees = this.calendarEvent.dto.attendees.map(attendee => {
        if (attendee.attendeeId === patientId) {
          return {
            ...attendee,
            noChargeComment
          };
        }

        return attendee;
      });

      await this.root.booking.updateCalendarEvent({
        id: this.calendarEvent.id,
        attendees
      });
    }
  };

  startPatientMatchWorkflow = () => {
    this.booking.ui.startPatientMatchWorkflow(this.calendarEvent.id);
  };
}
