import { maxLength } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { medicareProviderNumberValidator } from "@modules/settings/screens/users/components/utils.ts";

import { UserProviderFormValues } from "./UserProviderForm.types.ts";

export class UserProviderFormValidator extends Validator<UserProviderFormValues> {
  constructor() {
    super();

    this.forField("medicareProviderNumber", [
      maxLength(8),
      medicareProviderNumberValidator
    ]);
    this.forField("cpn", maxLength(8));
    this.forField("discipline", maxLength(50));
    this.forField("prescriberNumber", [
      maxLength(7),
      prescriberNumberValidator
    ]);
  }
}

export const isValidPrescriberNumber = (val: string) => {
  let values;
  let divisor;
  let value;

  let isValid = false;
  let prescriberNum = val.replace(/\s+/g, "");

  prescriberNum = prescriberNum.padStart(7, "0");

  const checkValue = parseInt(prescriberNum.charAt(6));
  value = 0;
  if (parseInt(prescriberNum.charAt(0)) === 0) {
    values = [0, 5, 8, 4, 2, 1];
    divisor = 11;
  } else {
    values = [1, 3, 7, 9, 1, 3];
    divisor = 10;
  }
  for (let i = 0; i < 6; i++) {
    value += parseInt(prescriberNum.charAt(i)) * values[i];
  }
  if (value % divisor === checkValue) {
    isValid = true;
  }

  return isValid;
};

export const prescriberNumberValidator: FieldValidator = (
  val: string | undefined
) => {
  return !val || isValidPrescriberNumber(val)
    ? undefined
    : ValidationMessages.invalid;
};
