import { observer } from "mobx-react-lite";
import { FC } from "react";

import { DefaultButton, Dialog, DialogFooter, Heading } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useAppointmentTypeScreenContext } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { OnlineAppointmentTypeFields } from "../../OnlineAppointmentTypeFields.tsx";

export const OnlineAppointmentTypeDialog: FC = observer(() => {
  const {
    showOnlineAppointmentTypeSettingModal,
    closeOnlineAppointmentTypeSettingModal
  } = useAppointmentTypeScreenContext();

  const { core } = useStores();

  return (
    <>
      {showOnlineAppointmentTypeSettingModal && (
        <Dialog
          dialogContentProps={{
            title: (
              <Heading variant="modal-heading">
                Appointments online display
              </Heading>
            ),
            showCloseButton: true,
            styles: {
              content: {
                display: "flex",
                flexDirection: "column",
                padding: 0
              },
              innerContent: {
                paddingBottom: 24
              }
            }
          }}
          minWidth={960}
          onDismiss={closeOnlineAppointmentTypeSettingModal}
          styles={{ root: { overflowY: "hidden", padding: 0 } }}
          hidden={!core.hasPermissions(Permission.BhbWrite)}
        >
          <OnlineAppointmentTypeFields />
          <DialogFooter>
            <DefaultButton
              onClick={closeOnlineAppointmentTypeSettingModal}
              text="Close"
            />
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
});
