import { observer } from "mobx-react-lite";
import React from "react";

import { Stack } from "@bps/fluent-ui";
import {
  QuestionnaireItemType,
  SpecialTestItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { BodySpecialTestItem } from "./BodySpecialTestItem.tsx";

export interface SpecialTestItemGroupProps {
  bodyArea: string;
  allSpecialTestItems: SpecialTestItemDto[];
  groupedSpecialTestItems: SpecialTestItemDto[];
  isVertical?: boolean;
  hasSide?: boolean;
  specialTestType?: string;
}

export const SpecialTestItemGroup: React.FC<SpecialTestItemGroupProps> =
  observer(
    ({
      bodyArea,
      allSpecialTestItems,
      groupedSpecialTestItems,
      isVertical,
      hasSide,
      specialTestType
    }) => {
      const buildGroupRows = () => {
        const rows: SpecialTestItemDto[][] = [[]];
        let iteration = 0;

        groupedSpecialTestItems.forEach(
          (specialTestQuestion, itterationIndex, groupArray) => {
            const questionIndex = allSpecialTestItems.findIndex(
              x => x.id === specialTestQuestion.id
            );
            if (questionIndex !== -1) {
              // Specific offsets.
              if (itterationIndex >= 2) {
                if (
                  groupArray[itterationIndex - 1].type ===
                    QuestionnaireItemType.MultiChoice &&
                  groupArray[itterationIndex - 2].type ===
                    QuestionnaireItemType.MultiChoice
                ) {
                  iteration += 1;
                  rows.push([specialTestQuestion]);
                  return;
                }
              }

              // Push integer combos down onto their own lines.
              if (itterationIndex >= 1) {
                if (
                  groupArray[itterationIndex - 1].type ===
                    QuestionnaireItemType.Integer &&
                  specialTestQuestion.type === QuestionnaireItemType.Integer
                ) {
                  iteration += 1;
                  rows.push([specialTestQuestion]);
                  return;
                }
              }

              rows[iteration].push(specialTestQuestion);
            }
          }
        );

        return rows;
      };

      const renderRows = (rows: SpecialTestItemDto[][]) => {
        const rowSets = rows.map(row => {
          const rowItems = row.map(question => {
            const questionIndex = allSpecialTestItems.findIndex(
              x => x.id === question.id
            );

            return (
              <BodySpecialTestItem
                key={`${bodyArea}_${questionIndex}`}
                bodyArea={bodyArea}
                index={questionIndex}
                allSpecialTests={allSpecialTestItems}
                specialTestItem={question}
                isVertical={isVertical}
                hasSide={hasSide}
                specialTestType={specialTestType}
                hideLabel
              />
            );
          });

          return (
            <Stack
              key={`row_${row[0].id}`}
              horizontal={!isVertical}
              tokens={{ childrenGap: 8 }}
              verticalAlign="start"
            >
              {rowItems}
            </Stack>
          );
        });

        return <Stack tokens={{ childrenGap: 8 }}>{rowSets}</Stack>;
      };

      const headerIndex = allSpecialTestItems.findIndex(
        x => x.id === groupedSpecialTestItems[0].id
      );

      return (
        <Stack>
          {/* Label to render - we have to render it like this so that if a question shouldn't appear, we know to not show the label. */}
          <Stack styles={{ root: { marginBottom: 4 } }}>
            <BodySpecialTestItem
              bodyArea={bodyArea}
              index={headerIndex}
              allSpecialTests={allSpecialTestItems}
              specialTestItem={groupedSpecialTestItems[0]}
              isVertical={isVertical}
              hasSide={hasSide}
              specialTestType={specialTestType}
              renderLabelOnly
            />
          </Stack>

          {renderRows(buildGroupRows())}
        </Stack>
      );
    }
  );
