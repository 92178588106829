import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontWeights,
  IStyle,
  mergeStyles,
  noWrap,
  Stack,
  TextBadge,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

import { getAppointmentStatusColours } from "../shared-components/appointment-status/utils.ts";
import { BookingCalendarEventProps } from "./BookingCalendarEvent.types.ts";

export const GroupAppointmentTitle: FunctionComponent<BookingCalendarEventProps> =
  observer(({ event }) => {
    const theme = useTheme();

    const nameStyles: IStyle = {
      ...noWrap,
      fontSize: theme.fonts.small.fontSize,
      fontWeight: FontWeights.semibold,
      flexGrow: 1
    };

    const eventTypeText =
      (event.typeRef && event.typeRef.text) || "Appointment";

    const badgeStyles = mergeStyles({
      padding: "0px 8px",
      lineHeight: "10px"
    });

    const showGroupBadge =
      event.model?.appointmentStatus === AppointmentStatusCode.Waiting ||
      event.model?.appointmentStatus === AppointmentStatusCode.Finalised;

    const invoicedAttendees = (calendarEvent: CalendarEvent) => {
      return calendarEvent.activeAttendees.filter(
        x => calendarEvent.getInvoiceIdByAttendee(x.attendeeId) !== undefined
      ).length;
    };

    const renderAppointmentStatus = (calendarEvent: CalendarEvent) => {
      let bgColor: string;

      const apptStatusColors = getAppointmentStatusColours(theme);
      if (calendarEvent.appointmentStatus === AppointmentStatusCode.Finalised) {
        bgColor = apptStatusColors(AppointmentStatusCode.Finalised).dark;
      } else {
        bgColor = theme.palette.neutralLighter;
      }

      return (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          customColors={{
            backgroundColor: bgColor
          }}
          styles={{ root: badgeStyles }}
        >
          {badgeDisplayText(event.model)}
        </TextBadge>
      );
    };

    const toBeInvoicedAttendees = (calendarEvent: CalendarEvent) => {
      const dnaAttendees = calendarEvent.activeAttendees.filter(
        x => x.attendeeStatus === AppointmentStatusCode.DidNotAttend
      ).length;
      return calendarEvent.activeAttendees.length - dnaAttendees;
    };

    const waitingStatusAttendees = (calendarEvent: CalendarEvent) => {
      return calendarEvent.activeAttendees.filter(
        x => x.attendeeStatus === AppointmentStatusCode.Waiting
      ).length;
    };

    const bookedStatusAttendees = (calendarEvent: CalendarEvent) => {
      return calendarEvent.activeAttendees.length;
    };

    const badgeDisplayText = (calendarEvent: CalendarEvent) => {
      switch (calendarEvent.appointmentStatus) {
        case AppointmentStatusCode.Waiting: {
          return `${waitingStatusAttendees(
            calendarEvent
          )}/${bookedStatusAttendees(calendarEvent)}`;
        }
        case AppointmentStatusCode.Finalised: {
          return `${invoicedAttendees(calendarEvent)}/${toBeInvoicedAttendees(
            calendarEvent
          )}`;
        }
        default:
          return;
      }
    };

    return (
      <Stack.Item shrink styles={{ root: { ...noWrap, paddingTop: "3px" } }}>
        <Stack horizontal verticalAlign="baseline" tokens={{ childrenGap: 8 }}>
          <span
            className={`calendar-event-name ${mergeStyles(nameStyles)}`}
            data-calendar-event-id={event.model.id}
            data-calendar-event-type={eventTypeText}
            data-calendar-create-date={event.changeLog?.createdDate}
          >
            {event.model.groupAppointmentName}
          </span>
          {showGroupBadge && renderAppointmentStatus(event.model)}
        </Stack>
      </Stack.Item>
    );
  });
