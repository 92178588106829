import { FunctionComponent } from "react";

import { Badge, BadgeProps, noWrap, useTheme } from "@bps/fluent-ui";

export interface PrescribingPatientBadgeProps extends BadgeProps {
  active: boolean;
}

export const PrescribingPatientBadge: FunctionComponent<
  PrescribingPatientBadgeProps
> = props => {
  const { active, ...restProps } = props;
  const theme = useTheme();

  return (
    <Badge
      leftIcon={{ iconName: active ? "CheckMark" : "Blocked" }}
      styles={{
        root: {
          borderRadius: 2,
          backgroundColor: active
            ? theme.semanticColors.successBackground
            : "initial",
          color: active ? theme.semanticColors.successIcon : "initial"
        },
        content: noWrap
      }}
      {...restProps}
    />
  );
};
