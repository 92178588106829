import { FontSizes, MessageBar, MessageBarType, Text } from "@bps/fluent-ui";

export const OnHoldMessageBar = () => {
  return (
    <MessageBar key="on-hold-warning" messageBarType={MessageBarType.warning}>
      <Text bold styles={{ root: { fontSize: FontSizes.small } }}>
        Claim is on hold:{" "}
      </Text>
      to bill ACC successfully an ACC32 treatment extension is required
    </MessageBar>
  );
};
