import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Pivot, PivotItem, Stack } from "@bps/fluent-ui";

import { MatchedResultList } from "./components/MatchedResultList.tsx";
import { PatientMatchFilterBar } from "./components/PatientMatchFilterBar.tsx";
import { PatientMatchPivotKey } from "./components/PatientMatchListItem.types.ts";
import { PatientMatchSearch } from "./components/PatientMatchSearch.tsx";
import { usePatientMatchContext } from "./context/PatientMatchContext.ts";

export const PatientMatchPivot: React.FC = observer(() => {
  const helper = usePatientMatchContext();
  const [selectedPivotKey, setSelectedPivotKey] =
    useState<PatientMatchPivotKey>(PatientMatchPivotKey.MatchedResult);

  const { MatchedResult, SearchResult } = PatientMatchPivotKey;

  return (
    <Stack
      grow
      styles={{
        root: {
          margin: "8px 0"
        }
      }}
    >
      <Pivot
        onLinkClick={item => {
          const { itemKey } = item?.props || {};
          if (!itemKey || itemKey === selectedPivotKey) return;
          setSelectedPivotKey(itemKey as PatientMatchPivotKey);
        }}
        selectedKey={selectedPivotKey}
      >
        <PivotItem
          headerText={`Match results (${helper.totalMatched || ""})`}
          itemKey={MatchedResult}
        >
          <MatchedResultList
            onChangeToSearch={() =>
              setSelectedPivotKey(PatientMatchPivotKey.SearchResult)
            }
          />
        </PivotItem>

        <PivotItem headerText="Search" itemKey={SearchResult}>
          <PatientMatchFilterBar>
            {({ values: filter }) => {
              helper.filterCriteria = filter;
              return <PatientMatchSearch filter={filter} />;
            }}
          </PatientMatchFilterBar>
        </PivotItem>
      </Pivot>
    </Stack>
  );
});
