import { observer } from "mobx-react-lite";

import {
  IconButton,
  Pivot,
  PivotItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";

import { ObservationPivotName } from "./ObservationsExpandedTable.tsx";

interface ObservationMatrixPivotBarProps {
  selectedKey: string;
  onSwitch: () => void;
}

export const ObservationMatrixPivotBar: React.FC<ObservationMatrixPivotBarProps> =
  observer(({ onSwitch, selectedKey }) => {
    return (
      <Stack
        horizontal
        grow
        styles={{ root: { justifyContent: "space-between" } }}
      >
        <Pivot selectedKey={selectedKey}>
          <PivotItem
            headerText={ObservationPivotName.All}
            itemKey={ObservationPivotName.All}
          />
        </Pivot>
        <TooltipHost
          content="Switch dates order"
          styles={{
            root: {
              alignSelf: "center"
            }
          }}
        >
          <IconButton iconProps={{ iconName: "Switch" }} onClick={onSwitch} />
        </TooltipHost>
      </Stack>
    );
  });
