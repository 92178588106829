import { ConfirmDialog, Heading, Stack } from "@bps/fluent-ui";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export interface DeleteCurrentMedicationDialogProps {
  medication: MedicationClinicalDataItemDto;
  hidden: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DeleteCurrentMedicationDialog: React.FC<
  DeleteCurrentMedicationDialogProps
> = ({ medication, hidden, onCancel, onConfirm }) => {
  return (
    <ConfirmDialog
      hidden={hidden}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonProps={{
        text: "Delete"
      }}
      minWidth={450}
      dialogContentProps={{
        title: (
          <Heading variant="modal-heading">
            Delete {medication.productName}?
          </Heading>
        ),
        showCloseButton: true
      }}
    >
      <Stack>
        Are you sure you want to delete {medication.productName} from Current
        medications?
      </Stack>
    </ConfirmDialog>
  );
};
