import { observer } from "mobx-react-lite";
import { useState } from "react";

import { ConfirmDialog, Heading, Link, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { usePatientMatchContext } from "../context/PatientMatchContext.ts";

const KNOWLEDGEBASE_INFO_LINK = "some_link_here";

export const PatientMatchConfirmDialogs = observer(() => {
  const patientLabelLowerCase = usePatientLabel(false);
  const { bhac, core } = useStores();
  const patientLabel = usePatientLabel(true);
  const {
    showPatientMatchingDiscardDialog,
    showPatientMatchingProgressDialog,
    setShowPatientMatchingProgressDialog,
    setShowPatientMatchingDiscardDialog,
    onClosePatientMatch,
    addAttendeeToCalendarEvent,
    selectedPatient,
    externalPatient
  } = usePatientMatchContext();

  const {
    firstName = "",
    lastName = "",
    dateOfBirth,
    postcode
  } = externalPatient || {};

  const externalPatientFullName = `${firstName} ${lastName}`;
  const dob = dateOfBirth
    ? DateTime.fromISO(dateOfBirth).toDayDefaultFormat()
    : "";

  const [submitting, setSubmitting] = useState<boolean>(false);
  return (
    <>
      {showPatientMatchingDiscardDialog && (
        <ConfirmDialog
          hidden={false}
          confirmButtonProps={{ text: "Discard" }}
          cancelButtonProps={{ text: "Keep editing" }}
          onConfirm={() => {
            setShowPatientMatchingDiscardDialog(false);
            onClosePatientMatch();
          }}
          minWidth={500}
          onCancel={() => setShowPatientMatchingDiscardDialog(false)}
        >
          <Stack tokens={{ childrenGap: 30 }}>
            <Text>
              The {patientLabelLowerCase} for this appointment must be matched
              to an existing {patientLabelLowerCase} or have a new{" "}
              {patientLabelLowerCase} record created in order to proceed.
            </Text>
            <Text bold>Discard your changes?</Text>
          </Stack>
        </ConfirmDialog>
      )}
      {showPatientMatchingProgressDialog && (
        <SubmissionFormDialog
          onSubmit={async () => {
            setSubmitting(true);
            if (selectedPatient) {
              if (externalPatient?.sourceId && core.tenantDetails?.id) {
                await bhac.addPatient({
                  accountId: externalPatient.sourceId,
                  tenantId: core.tenantDetails?.id,
                  patientId: selectedPatient.id
                });
              }

              await addAttendeeToCalendarEvent(selectedPatient.id);
            }
            setShowPatientMatchingProgressDialog(false);
            onClosePatientMatch();
          }}
          buttonsProps={{
            cancelButtonProps: {
              id: "close-patient-match-form-dialog",
              disabled: submitting,
              text: "Cancel"
            },
            submitButtonProps: {
              text: "Confirm",
              iconProps: { hidden: true },
              disabled: submitting
            },
            hideButtonsSeparator: true
          }}
          dialogName="Match dialog"
          dialogProps={{
            onDismiss: () => setShowPatientMatchingProgressDialog(false),
            minWidth: 500,
            modalProps: {
              isBlocking: true
            },
            dialogContentProps: {
              showCloseButton: false,
              title: (
                <Heading variant="modal-heading">
                  Confirm {patientLabelLowerCase} matching
                </Heading>
              )
            }
          }}
        >
          {() => (
            <Stack tokens={{ childrenGap: 20 }}>
              <Text>
                {patientLabel} {selectedPatient?.name} will be matched to online
                booking {patientLabelLowerCase} {externalPatientFullName.trim()}
                {dob ? `, DOB ${dob}` : ""}
                {`, ${postcode}` ?? ""}.
              </Text>
              <Text bold>Check and be sure before confirming</Text>
              <Text>
                Review the{" "}
                <Link href={KNOWLEDGEBASE_INFO_LINK} target="_blank">
                  Knowledge Base
                </Link>{" "}
                for full {patientLabelLowerCase} matching rules.
              </Text>
            </Stack>
          )}
        </SubmissionFormDialog>
      )}
    </>
  );
});
