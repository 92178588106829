import { OutboundCommChannel } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";

import { PatientEditFormValues } from "./PatientEditFormValues.tsx";

export type ConsentCommsPreference = {
  [key in Partial<ConsentCommType>]: {
    outboundComm?: OutboundCommChannel;
    eraseValue: boolean;
  };
};

export type PatientEditFormField = {
  value?: string;
  initialValue?: string;
  name: keyof PatientEditFormValues;
};

export enum ConsentCommType {
  SMSOnly = "SMSOnly",
  EmailOnly = "EmailOnly",
  SMSOverEmail = "SMSOverEmail"
}

export const consentPreferenceFields: {
  [key in ConsentCommType]: {
    typeFieldName: keyof PatientEditFormValues;
    valueFieldName: keyof PatientEditFormValues;
  }[];
} = {
  SMSOnly: [
    {
      typeFieldName: "appointmentReminderType",
      valueFieldName: "appointmentReminderValue"
    }
  ],
  EmailOnly: [
    {
      typeFieldName: "appointmentConfirmationType",
      valueFieldName: "appointmentConfirmationValue"
    },
    {
      typeFieldName: "invoiceCommunicationType",
      valueFieldName: "invoiceCommunicationValue"
    }
  ],
  SMSOverEmail: [
    {
      typeFieldName: "formNotifyType",
      valueFieldName: "formNotifyValue"
    }
  ]
};

export const getSMSOverEmailPreference = (
  mobileCount: number,
  emailCount: number
): ConsentCommsPreference["SMSOverEmail"] => {
  if (mobileCount > 0) {
    return getPreference(mobileCount, OutboundCommChannel.Sms);
  } else if (emailCount > 0) {
    return getPreference(emailCount, OutboundCommChannel.Email);
  } else {
    return { eraseValue: false };
  }
};

export const getPreference = (
  count: number,
  outboundComm: OutboundCommChannel
): ConsentCommsPreference["EmailOnly" | "SMSOnly"] => {
  return {
    outboundComm: count > 0 ? outboundComm : undefined,
    eraseValue: count > 1
  };
};

export const getCommTypeCount = (
  communications: CommunicationFieldValue[],
  type: CommunicationType
) => {
  return communications.filter(comm => comm.type === type && comm.value).length;
};

export const checkIfCanSet = (
  presentValue?: string,
  initialValue?: string,
  newValue?: string
) => {
  return presentValue !== newValue && !initialValue;
};
