import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

import { SettingsLabels } from "../../shared-components/SettingsLabels.ts";

export const SettingsNav = observer(() => {
  const { routing } = useStores();
  const theme = useTheme();

  const isConfigLocation =
    routing.location.pathname ===
      routes.settings.appointmentTypes.basePath.pattern ||
    routing.location.pathname ===
      routes.settings.communications.schedules.pattern ||
    routing.location.pathname ===
      routes.settings.communications.templates.pattern ||
    routing.location.pathname === routes.settings.schedules.basePath.pattern ||
    routing.location.pathname ===
      routes.settings.integrations.basePath.pattern ||
    routing.location.pathname === routes.settings.bhb.onlineBooking.pattern;

  const [configExpanded, setConfigExpanded] = useState(false);

  useEffect(() => {
    if (isConfigLocation) setConfigExpanded(true);
  }, [isConfigLocation]);

  const links = [
    {
      name: SettingsLabels.users,
      path: routes.settings.users.basePath
    },
    {
      name: SettingsLabels.practiceLocation,
      path: routes.settings.practices.basePath
    },
    {
      name: "Config",
      isExpanded: configExpanded,
      onClick: () => setConfigExpanded(true),
      links: [
        {
          name: SettingsLabels.appointmentTypes,
          path: routes.settings.appointmentTypes.basePath
        },
        {
          name: SettingsLabels.onlineBooking,
          path: routes.settings.bhb.onlineBooking
        },
        {
          name: SettingsLabels.communicationsSchedules,
          path: routes.settings.communications.schedules
        },
        {
          name: SettingsLabels.communicationsTemplates,
          path: routes.settings.communications.templates,
          tenantLocation: Country.Australia
        },
        {
          name: SettingsLabels.schedules,
          path: routes.settings.schedules.basePath
        },
        {
          name: SettingsLabels.integrations,
          path: routes.settings.integrations.basePath
        }
      ]
    }
  ];

  return (
    <Nav
      styles={{
        root: {
          background: "transparent",
          boxShadow: "none",
          overflow: "hidden",
          position: "sticky",
          top: 0,
          marginLeft: 16
        },
        navItem: {
          ".ms-Nav-navItems": {
            background: theme.palette.white
          }
        },
        link: {
          "&:after": {
            borderLeft: "none"
          }
        },
        compositeLink: {
          ".ms-Nav-chevron": {
            transition: "transform 0.1s linear 0s",
            transform: "rotate(-90deg)"
          },
          "&.is-expanded": {
            ".ms-Nav-linkText": { fontWeight: "initial" },
            ".ms-Nav-chevron": {
              transform: "rotate(0deg)"
            }
          }
        },
        group: {}
      }}
      links={links}
    />
  );
});
