import {
  MyotomeDataItemDto,
  ReflexDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import {
  ExaminationCommentsType,
  ImageValue
} from "../../full-body-clinical-data/examination/BodyExaminationForm.types.ts";

export class DermatomesMyotomesAndReflexesFormValues {
  imageValue: ImageValue;
  examinationComments: ExaminationCommentsType;
  myotomes: string[];
  myotomeFields?: MyotomeFieldValues[];
  reflexFields?: ReflexFieldValues[];
  trigeminalDermatomes?: DermatomeFieldValues[];
  cervicalDermatomes?: DermatomeFieldValues[];
  thoracicDermatomes?: DermatomeFieldValues[];
  lumbosacralDermatomes?: DermatomeFieldValues[];
}

export interface MyotomeFieldValues extends MyotomeDataItemDto {
  checked: boolean;
}
export interface ReflexFieldValues extends ReflexDataItemDto {
  checked: boolean;
}

export interface DermatomeFieldValues {
  testType: DermatomeTest;
  nerves?: string[];
  estimation?: string;
  comment?: string;
  hasComment?: boolean;
}

export const dermMyotomesAndReflexesNameOf =
  nameOfFactory<DermatomesMyotomesAndReflexesFormValues>();

export enum DermatomeTest {
  LightTouch = "TOU",
  Pinprick = "PIN"
}

export enum Labels {
  LightTouch = "Light touch test",
  Pinprick = "Pinprick test",
  Trigeminal = "Trigeminal",
  Cervical = "Cervical",
  Thoracic = "Thoracic",
  Lumbosacral = "Lumbosacral"
}

export interface DermatomeOption {
  nerve: string;
  disabled: boolean;
  leftDisabled: boolean;
  rightDisabled: boolean;
}
