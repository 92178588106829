import { FontIcon, Grid, GridItem, Stack, Text } from "@bps/fluent-ui";

export interface DASS21InstructionsProps {
  expanded: boolean;
}

export const DASS21Instructions: React.FC<DASS21InstructionsProps> = ({
  expanded
}) => {
  return (
    <Stack>
      <Stack horizontal verticalAlign="center">
        <FontIcon iconName="Info" styles={{ root: { paddingRight: 8 } }} />
        <Text variant="small"> The rating scale is as follows:</Text>
      </Stack>
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={{ root: { paddingLeft: 16, paddingTop: 8, fontSize: 12 } }}
      >
        <Stack>
          0 — Did not apply to me at all - NEVER
          <br />
          1 — Applied to me to some degree, or some of the time - SOMETIMES
          <br />
          2 — Applied to me to a considerable degree, or a good part of time -
          OFTEN
          <br />3 — Applied to me very much, or most of the time - ALMOST ALWAYS
        </Stack>
        {expanded && (
          <Stack tokens={{ childrenGap: 16 }}>
            <Text variant="small" bold>
              DASS 21 Scoring Instructions
            </Text>
            <Text variant="small">
              The DASS 21 should not be used to replace a face to face clinical
              interview. If you are experiencing significant emotional
              difficulties you should contact your GP for a referral to a
              qualified professional. Depression, Anxiety and Stress Scale - 21
              Items (DASS 21) The Depression, Anxiety and Stress Scale - 21
              Items (DASS 21) is a set of three self-report scales designed to
              measure the emotional states of depression, anxiety and stress.
            </Text>
            <Text variant="small">
              Each of the three DASS 21 scales contains 7 items, divided into
              subscales with similar content. The depression scale assesses
              dysphoria, hopelessness, devaluation of life, self-deprecation,
              lack of interest / involvement, anhedonia and inertia. The anxiety
              scale assesses autonomic arousal, skeletal muscle effects,
              situational anxiety, and subjective experience of anxious affect.
              The stress scale is sensitive to levels of chronic nonspecific
              arousal. It assesses difficulty relaxing, nervous arousal, and
              being easily upset / agitated, irritable / over-reactive and
              impatient. Scores for depression, anxiety and stress are
              calculated by summing the scores for the relevant items.
            </Text>
            <Text variant="small">
              The DASS 21 is based on a dimensional rather than a categorical
              conception of psychological disorder. The assumption on which the
              DASS 21 development was based (and which was confirmed by the
              research data) is that the differences between the depression,
              anxiety and the stress experienced by normal subjects and clinical
              populations are essentially differences of degree. The DASS 21
              therefore has no direct implications for the allocation of
              patients to discrete diagnostic categories postulated in
              classificatory systems such as the DSM and ICD.
            </Text>
            <Text variant="small">
              Recommended cut-off scores for conventional severity labels
              (normal, moderate, severe) are as follows:
            </Text>

            <Grid
              templateColumns="150px repeat(3, 120px)"
              templateRows="repeat(6, 20px)"
              styles={{ root: { gridAutoFlow: "column", fontSize: 12 } }}
            >
              <GridItem />
              <GridItem>Normal</GridItem>
              <GridItem>Mild</GridItem>
              <GridItem>Moderate</GridItem>
              <GridItem>Severe</GridItem>
              <GridItem>Extremely severe</GridItem>

              <GridItem>
                <Text variant="small" bold>
                  Depression
                </Text>
              </GridItem>
              <GridItem>0-9</GridItem>
              <GridItem>10-13</GridItem>
              <GridItem>14-20</GridItem>
              <GridItem>21-27</GridItem>
              <GridItem>28+</GridItem>

              <GridItem>
                <Text bold variant="small">
                  Anxiety
                </Text>
              </GridItem>
              <GridItem>0-7</GridItem>
              <GridItem>8-9</GridItem>
              <GridItem>10-14</GridItem>
              <GridItem>15-19</GridItem>
              <GridItem>20+</GridItem>

              <GridItem>
                <Text bold variant="small">
                  Stress
                </Text>
              </GridItem>
              <GridItem>0-14</GridItem>
              <GridItem>15-18</GridItem>
              <GridItem>19-25</GridItem>
              <GridItem>26-33</GridItem>
              <GridItem>34+</GridItem>
            </Grid>

            <Text variant="small">
              <i>
                NB: In order to match the full 42 scale the scores are
                multiplied by 2.
              </i>
            </Text>
            <Text variant="small">
              <i>
                Lovibond, S.H. & Lovibond, P.F. (1995). Manual for the
                Depression Anxiety Stress Scales. (2nd Ed.) Sydney: Psychology
                Foundation
              </i>
            </Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
