import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import {
  IconButton,
  IContextualMenuItem,
  mergeStyles,
  Separator,
  Stack,
  StatusPanel,
  Tile,
  ToolTipButton,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  CommunicationDto,
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { ExportPatientRecord } from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecord.tsx";
import { OrganisationCardIds } from "@modules/practice/screens/shared-components/types/organisation-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { useRouteDefinitionsFilter } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";
import { useScrollToViewById } from "@ui-components/hooks/useScrollToViewById.tsx";
import { ContactStatusText } from "@ui-components/RefText.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { ContactCardIdsEnum } from "../../../shared-components/types/contact-card-ids.enum.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";

export interface HeaderWrapperProps {
  contact: Contact;
  onHandleEditModal: (cardId: string) => void;
  persona?: ReactNode;
  bottomContent?: ReactNode;
  communications?: CommunicationDto[] | undefined;
}

/**
 * HeaderWrapper is intended to show common for Patient and Contact cards elements
 * plus obtains Persona components as children
 * @param children - to render Persona component for Contact or Patient
 * @param status - contact status
 * @param type - ContactType
 * @id - contact id
 */

export const HeaderWrapper: React.FC<HeaderWrapperProps> = observer(
  ({ contact, onHandleEditModal, persona, bottomContent }) => {
    const { id, type, status } = contact;
    const { core, routing, userExperience, practice } = useStores();
    const { setShowExportPatientRecordModal } = practice.ui;

    const routeFilter = useRouteDefinitionsFilter();

    const scroll = useScrollToViewById();

    const theme = useTheme();

    const { localisedConfig } = userExperience;
    const patientLabel = localisedConfig("patientDisplay");

    const { setMasterPatient } = practice.ui;

    const { iconButtonStyles } = getPeopleScreenStylesSet(theme);

    const getHeaderId = (type: ContactType) => {
      if (type === ContactType.Patient) {
        return PatientCardIds.patientHeader;
      }
      if (type === ContactType.Organisation) {
        return OrganisationCardIds.OrganisationHeader;
      } else {
        return ContactCardIdsEnum.contactHeader;
      }
    };

    const onClickOpenModal = (type: ContactType) => () => {
      onHandleEditModal(getHeaderId(type));
    };

    const canOpenClinical = core.hasPermissions([
      Permission.EncounterRead,
      Permission.ClinicalRead
    ]);

    const canSendForms = core.hasPermissions([Permission.SendForms]);

    const canOpenEdit = core.hasPermissions([
      Permission.ContactWrite,
      Permission.PatientWrite
    ]);

    const canExportPatientRecord = core.hasPermissions(
      Permission.ExportPatientRecordAllowed
    );

    const onExportPatientRecord = () => {
      setShowExportPatientRecordModal(true);
      setMasterPatient(contact);
    };

    const openPatientRecordOptions: IContextualMenuItem[] = [
      {
        key: "startConsult",
        text: "Start consult",
        onClick: () => {
          routing.push(
            routes.records.record.path({ id }),
            routing.getStateWithFromQuery()
          );
        },
        disabled:
          !core.hasPermissions(Permission.CreateConsultAllowed) ||
          !routeFilter({ path: routes.records.record })
      },
      {
        key: "recordUpdate",
        text: "Record update",
        onClick: () => {
          routing.push(
            routes.records.recordUpdate.path({ id }),
            routing.getStateWithFromQuery()
          );
        },
        disabled: !routeFilter({ path: routes.records.recordUpdate })
      },
      {
        key: "viewRecord",
        text: "View record",
        onClick: () => {
          routing.push(
            routes.records.recordView.path({ id }),
            routing.getStateWithFromQuery()
          );
        },
        disabled: !routeFilter({ path: routes.records.recordView })
      },
      ...(canExportPatientRecord
        ? [
            {
              key: "exportPatientRecord",
              text: `Export ${patientLabel} record`,
              onClick: onExportPatientRecord
            }
          ]
        : [])
    ];

    const formMenu = useFormMenu(contact);

    const seeResponses = {
      key: "seeResponses",
      text: "See responses",
      onClick: () => scroll(PatientCardIds.forms)
    };

    const sendFormOptions: IContextualMenuItem[] = [...formMenu, seeResponses];

    return (
      <Stack horizontal>
        <Tile
          id={getHeaderId(type)}
          styles={{ root: { flexGrow: 1, padding: 0 } }}
        >
          <Stack horizontal horizontalAlign="space-between">
            <Stack
              horizontal
              horizontalAlign="space-between"
              styles={{
                root: {
                  marginTop: 8,
                  marginBottom: !bottomContent ? 8 : undefined,
                  width: "100%",
                  padding: 8
                }
              }}
            >
              {persona}
              <Stack
                className={mergeStyles({
                  marginTop: 8,
                  marginRight: 16
                })}
              >
                <Stack
                  tokens={{ childrenGap: 16 }}
                  styles={{ root: { alignItems: "end" } }}
                >
                  {canOpenEdit && (
                    <TooltipHost content="Edit">
                      <IconButton
                        data-edit-icon="open-edit-modal-header"
                        iconProps={{ iconName: "Edit" }}
                        ariaLabel="Edit"
                        styles={iconButtonStyles}
                        onClick={onClickOpenModal(type)}
                      />
                    </TooltipHost>
                  )}

                  {type === ContactType.Patient && canOpenClinical && (
                    <ToolTipButton
                      buttonProps={{
                        iconProps: {
                          iconName: "ContactCard",
                          styles: {
                            root: { fontSize: 14 }
                          }
                        },
                        className: "open-clinical-header",
                        menuProps: {
                          items: openPatientRecordOptions
                        },
                        style: {
                          border: 0,
                          minWidth: 0,
                          height: 24,
                          width: 40
                        }
                      }}
                      toolTipContent="Clinical record"
                    />
                  )}

                  {type === ContactType.Patient && canSendForms && (
                    <ToolTipButton
                      buttonProps={{
                        iconProps: {
                          iconName: "ClipboardList",
                          styles: {
                            root: { fontSize: 14 }
                          }
                        },
                        className: "open-clinical-header",
                        menuProps: {
                          items: sendFormOptions
                        },
                        style: { border: 0, minWidth: 0, height: 24, width: 40 }
                      }}
                      toolTipContent="Forms"
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {bottomContent && (
            <>
              <Separator />
              <Stack styles={{ root: { padding: 16, paddingTop: 0 } }}>
                {bottomContent}
              </Stack>
            </>
          )}
        </Tile>
        {
          // @todo need to delete later. added just because in DB some contacts uppercase
          status && status.toUpperCase() !== ContactStatus.Active && (
            <StatusPanel children={<ContactStatusText code={status} />} />
          )
        }
        <ExportPatientRecord />
      </Stack>
    );
  }
);
