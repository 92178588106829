import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";
import { useFormState } from "react-final-form";

import {
  ContextualMenuItemType,
  FontIcon,
  FontSizes,
  Heading,
  IContextualMenuItem,
  Link,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  PurposeType,
  SubmitActionCode
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { DOCUMENT_LABEL } from "@modules/clinical/screens/quick-links/components/TemplatePill.tsx";
import { DeleteTemplateDialog } from "@modules/clinical/screens/template-writer/components/DeleteTemplateDialog.tsx";
import { TemplateWriterValues } from "@modules/clinical/screens/template-writer/components/TemplateWriterValues.ts";
import { TemplateWriterHelper } from "@modules/clinical/screens/template-writer/context/TemplateWriterHelper.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

import {
  QuickAccessContext,
  useQuickAccessContext
} from "../../patient-record/components/quickAccess/context/QuickAccessContext.tsx";
import { QuickAccessHelper } from "../../patient-record/components/quickAccess/context/QuickAccessHelper.ts";
import { TemplateUsingElseWhereDialog } from "../../template-writer/components/TemplateUsingElseWhereDialog.tsx";
import { TemplateManagementFormModel } from "../../template-writer/context/TemplateManagementFormModel.ts";
import {
  TemplateManagerFormContext,
  useTemplateManagerFormContext
} from "../../template-writer/context/TemplateManagerFormContext.ts";
import { DocumentWriterHelper } from "../context/DocumentWriterHelper.ts";
import { SubmitAction } from "./DocumentWriterDetails.types.ts";
import { DocumentWriterDocStatusBadge } from "./DocumentWriterDocStatusBadge.tsx";
import { DocumentWriterValues } from "./DocumentWriterValues.ts";

interface DocumentWriterHeaderProps {
  recipientId?: string;
  patientNameWithTitle?: string;
  onSplitButtonCallback: (clickedPropType?: IContextualMenuItem) => void;
  onClickEditDetail: (value: boolean) => void;
  isTemplateEditor?: boolean;
  writerModel?: TemplateWriterHelper | DocumentWriterHelper;
}
export const DocumentWriterHeaderBase: React.FC<DocumentWriterHeaderProps> =
  observer(
    ({
      recipientId,
      patientNameWithTitle,
      onSplitButtonCallback,
      onClickEditDetail,
      isTemplateEditor,
      writerModel
    }) => {
      const model = isTemplateEditor
        ? (writerModel as TemplateWriterHelper)
        : (writerModel as DocumentWriterHelper);

      let isPracticeLetterhead = false;

      if (isTemplateEditor) {
        const { currentTemplate } = model as TemplateWriterHelper;
        isPracticeLetterhead = currentTemplate.isPracticeLetterhead;
      }

      const { values, pristine } = useFormState<DocumentWriterValues>();

      const { values: templateValue } = useFormState<TemplateWriterValues>();

      const { correspondence, document, core } = useStores();
      const { setSelectedTemplateId } = document;
      const [
        showTemplateUsingSomewhereDialog,
        setshowTemplateUsingSomewhereDialog
      ] = useState<boolean>(false);

      const { quickAccessSettingsDocumentIds } = useQuickAccessContext();

      const { setShowDeleteTemplateDialog } = useTemplateManagerFormContext();

      const onCancel = async (): Promise<void> => {
        isTemplateEditor
          ? document.closeTemplate(model.id)
          : await correspondence.closeDocument(model.id);
      };

      const onDeleteTemplate = (templateId: string) => {
        setshowTemplateUsingSomewhereDialog(false);
        setShowDeleteTemplateDialog(true);
        setSelectedTemplateId(templateId);
      };

      const isConsultationNotesEditor: boolean = values.documentTitle
        ? values.documentTitle.includes("Consultation notes")
        : false;

      const isAutofill = templateValue.purpose === PurposeType.AUTO;

      const getHeaderText = () => {
        if (isPracticeLetterhead) {
          return DOCUMENT_LABEL.PracticeLetterhead;
        }
        if (isTemplateEditor) {
          return isAutofill
            ? `${templateValue.shortcut} - ${templateValue.name}`
            : templateValue.name;
        }
        if (patientNameWithTitle && !isConsultationNotesEditor) {
          return `${getDocumentTypeText(
            values.documentType
          )} for ${patientNameWithTitle}`;
        }
        if (isConsultationNotesEditor) {
          return values.documentTitle;
        } else {
          return `${model.documentTitle} template`;
        }
      };

      const getDocStatusCode = () => {
        if (isTemplateEditor) {
          return templateValue.documentStatus ?? SubmitActionCode.DraftCode;
        }
        return "";
      };

      const isDraftTemplate = getDocStatusCode() === SubmitActionCode.DraftCode;

      const showPublishedIcon = (statusCodeCondition: string) => {
        if (getDocStatusCode() === statusCodeCondition) {
          return (
            <FontIcon
              iconName="Completed"
              styles={{
                root: {
                  padding: 3
                }
              }}
            />
          );
        }
        return null;
      };

      const getSplitButtonItems = (): IContextualMenuItem[] => {
        const onClick: IContextualMenuItem["onClick"] = (_evt, item) => {
          onSplitButtonCallback(item);
        };
        if (isTemplateEditor) {
          const templateHelper = model as TemplateWriterHelper;
          let commonEditorOptions: IContextualMenuItem[] = [
            {
              key: SubmitAction.SaveAndClose,
              text: isDraftTemplate ? "Save draft & exit" : "Save & exit",
              onClick,
              showBorder: true,
              onRenderIcon: () => {
                return null;
              }
            },
            {
              key: SubmitAction.SaveAs,
              text: "Save as",
              onClick: () => {
                document.isFromAutofillMgmt = isAutofill;
                onClickEditDetail(true);
                model instanceof TemplateWriterHelper &&
                  model.setIsEditAsCopy(true);
              },
              showBorder: true,
              onRenderIcon: () => {
                return null;
              }
            }
          ];
          commonEditorOptions = [
            ...commonEditorOptions,
            {
              key: "divider1",
              itemType: ContextualMenuItemType.Divider
            },
            {
              key: SubmitAction.Preview,
              text: "Preview",
              onRenderIcon: () => {
                return null;
              },
              onClick,
              disabled: !model.dto.eTag
            }
          ];

          const templateEditorOptions: IContextualMenuItem[] =
            !isPracticeLetterhead
              ? [
                  {
                    key: SubmitAction.PublishToEveryone,
                    text: "Publish to practice",
                    onClick,
                    disabled:
                      !core.hasPermissions([
                        Permission.TemplateManageAllowed
                      ]) ||
                      getDocStatusCode() ===
                        SubmitActionCode.PublishToEveryoneCode,
                    onRenderIcon: () => {
                      return showPublishedIcon(
                        SubmitActionCode.PublishToEveryoneCode
                      );
                    }
                  },
                  {
                    key: SubmitAction.PublishToSelf,
                    text: "Publish to me",

                    iconProps: {},
                    onRenderIcon: () => {
                      return showPublishedIcon(
                        SubmitActionCode.PublishToSelfCode
                      );
                    },
                    onClick,
                    disabled:
                      getDocStatusCode() ===
                        SubmitActionCode.PublishToSelfCode ||
                      (templateHelper.createdBy !==
                        core.accountInfo?.username &&
                        templateHelper.createdBy !== undefined)
                  },
                  {
                    key: "divider2",
                    itemType: ContextualMenuItemType.Divider
                  },
                  {
                    key: SubmitAction.Delete,
                    text: "Delete template",
                    disabled: templateHelper.currentTemplate.isNew,
                    onRenderIcon: () => {
                      return null;
                    },
                    onClick: () => {
                      const deletingTemplateId = model.id;
                      if (
                        quickAccessSettingsDocumentIds?.includes(
                          deletingTemplateId
                        )
                      ) {
                        setshowTemplateUsingSomewhereDialog(true);
                        return;
                      }
                      onDeleteTemplate(model.id);
                    }
                  }
                ]
              : [];

          return [...commonEditorOptions, ...templateEditorOptions];
        }
        return [
          {
            key: SubmitAction.SaveAndClose,
            text: "Save draft and exit",
            iconProps: { iconName: "Save" },
            onClick
          },
          {
            key: SubmitAction.Email,
            text: "Done and email",
            iconProps: { iconName: "Mail" },
            onClick
          },
          {
            key: SubmitAction.Finish,
            text: "Done",
            iconProps: { iconName: "Completed" },
            onClick
          }
        ];
      };

      const { getDocumentTypeText } = model;

      return (
        <Stack
          id="document-writer-header"
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 8 }}
          styles={{ root: { padding: "0 0 16px 0" } }}
        >
          <Stack
            horizontalAlign="start"
            styles={{ root: { justifyContent: "center" } }}
          >
            <Heading
              variant="section-heading-light"
              styles={{
                root: {
                  justifyContent: "center",
                  display: "flex"
                }
              }}
            >
              {isAutofill ? (
                <Text
                  styles={{
                    root: { fontSize: FontSizes.size18, marginRight: 4 }
                  }}
                >
                  Autofill
                </Text>
              ) : (
                !isPracticeLetterhead &&
                isTemplateEditor && (
                  <DocumentWriterDocStatusBadge
                    isDraftTemplate={isDraftTemplate}
                    docStatusCode={getDocStatusCode()}
                  />
                )
              )}
              {getHeaderText()}
              {recipientId && (
                <ContactFetcher contactId={recipientId} fallback={<Spinner />}>
                  {contact => (
                    <Text styles={{ root: { marginLeft: "8px" } }}>
                      {`to ${contact.nameWithTitle}`}
                    </Text>
                  )}
                </ContactFetcher>
              )}
              {!isPracticeLetterhead && (
                <Link
                  onClick={() => {
                    document.isFromAutofillMgmt = isAutofill;
                    onClickEditDetail(true);
                  }}
                  styles={{
                    root: {
                      margin: "0 16px",
                      fontSize: FontSizes.size14
                    }
                  }}
                >
                  Edit details
                </Link>
              )}
              {isAutofill && (
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  styles={{
                    root: {
                      width: "auto"
                    }
                  }}
                >
                  <Text>
                    Any pictures or tables will be converted to text if the
                    autofill is used outside of the text editor
                  </Text>
                </MessageBar>
              )}
            </Heading>
          </Stack>
          <Stack horizontalAlign="end">
            <DeleteTemplateDialog />
            <TemplateUsingElseWhereDialog
              onDelete={() => {
                onDeleteTemplate(model.id);
              }}
              onDismiss={() => {
                setshowTemplateUsingSomewhereDialog(false);
              }}
              hidden={!showTemplateUsingSomewhereDialog}
              templateName={model.documentTitle ?? ""}
            />
            {isTemplateEditor &&
              (model as TemplateWriterHelper).previewDocument && (
                <DocumentViewerDialog
                  getDocument={() =>
                    (model as TemplateWriterHelper).previewDocument!
                  }
                  closeDocumentViewer={() =>
                    (model as TemplateWriterHelper).setPreviewDocument(
                      undefined
                    )
                  }
                  hideDownloadButton={true}
                />
              )}

            <FormSubmitButtons
              onCancel={onCancel}
              cancelButtonProps={{ text: "Cancel" }}
              submitButtonProps={{
                items: getSplitButtonItems(),
                onClick: () => onSplitButtonCallback(),
                text: isDraftTemplate ? "Save draft" : "Save",
                iconProps: {},
                primaryDisabled: pristine
              }}
              styles={{
                root: {
                  paddingTop: 0,
                  margin: 0,
                  borderTop: "none"
                }
              }}
              disableSubmitOnPristine={false}
              disableSubmitOnFormInvalid={true}
            />
          </Stack>
        </Stack>
      );
    }
  );

const DocumentWriterHeaderWithContext: FunctionComponent<
  DocumentWriterHeaderProps
> = props => {
  const root = useStores();
  const { document, userExperience } = root;
  const helper = usePersistedHelper<QuickAccessHelper>(
    () => new QuickAccessHelper(root)
  );

  const model = usePersistedHelper<TemplateManagementFormModel>(
    () => new TemplateManagementFormModel(document, userExperience)
  );

  return (
    <TemplateManagerFormContext.Provider value={model}>
      <QuickAccessContext.Provider value={helper}>
        <DocumentWriterHeaderBase {...props} />
      </QuickAccessContext.Provider>
    </TemplateManagerFormContext.Provider>
  );
};

export const DocumentWriterHeader = withFetch(
  x => [x.userExperience.getCurrentUserQuickAccess()],
  DocumentWriterHeaderWithContext,
  { fallback: <Spinner /> }
);
