import { getColumnClampLinesSelector, IStyle, ITheme } from "@bps/fluent-ui";

export const getDetailsRowStyles = (options: {
  theme: ITheme;
  hasError?: boolean;
  hasWarning?: boolean;
}) => {
  const { theme, hasError, hasWarning } = options;

  let boxShadow = "inset rgba(0,0,0,0.1) 0px -1px 2px 0px";
  if (hasError || hasWarning) {
    const boxShadowColor = hasError
      ? theme.semanticColors.errorText
      : theme.palette.yellowDark;
    boxShadow = `inset 0px 0px 0px 1px ${boxShadowColor}`; // trick to use boxShadow as border if the block width is fixed
  }

  let backgroundColor = theme.palette.white;
  if (hasError) {
    backgroundColor = theme.semanticColors.errorBackground;
  } else if (hasWarning) {
    backgroundColor = theme.semanticColors.warningBackground;
  }

  return {
    root: {
      marginBottom: 2,
      boxShadow,
      borderBottom: "none",
      backgroundColor,
      selectors: {
        "&:hover": { backgroundColor },
        "& .clampTwoLines": getColumnClampLinesSelector(2)
      }
    },
    cell: { alignItems: "start" } as IStyle
  };
};
