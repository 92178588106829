import { DateTime, TIME_FORMATS } from "@bps/utils";
import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { OrgUnitOpeningHoursFormValues } from "./OrgUnitOpeningHoursForm.types.ts";

const isStartAndEndTimeNonEmpty = (
  val: string,
  values: OrgUnitOpeningHoursFormValues
) => !!(values.endTime && values.startTime);

export class OrgUnitOpeningHoursValidator extends Validator<OrgUnitOpeningHoursFormValues> {
  constructor() {
    super();
    this.forField("dayRecur", [required()]);
    this.forField("startTime", [required()]);

    this.forField("endTime", [
      required(),
      predicate(
        isStartAndEndTimeNonEmpty,
        (val, values: OrgUnitOpeningHoursFormValues) =>
          values.startTime &&
          DateTime.fromFormat(val, TIME_FORMATS.TIME_FORMAT_24) <
            DateTime.fromFormat(values.startTime, TIME_FORMATS.TIME_FORMAT_24)
            ? "End time must not be before start time."
            : undefined,
        (val, values: OrgUnitOpeningHoursFormValues) =>
          val === values.startTime
            ? "End time must not be equal to start time."
            : undefined
      )
    ]);
  }
}
