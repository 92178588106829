import { observer } from "mobx-react-lite";

import { AddressField } from "@modules/acc/screens/shared-components/AddressField.tsx";

import { useIsEmployerRequired } from "./hooks/useIsEmployerRequired.tsx";

export const BusinessAddressFields = observer(() => {
  const isEmployerDetailsRequired = useIsEmployerRequired();

  return (
    <AddressField
      validateOnInitialize={isEmployerDetailsRequired}
      required={isEmployerDetailsRequired}
      fieldNames={{
        street1: "employerAddress.street1",
        street2: "employerAddress.street2",
        addressType: "employerAddress.type",
        suburb: "employerAddress.suburb",
        city: "employerAddress.city",
        postCode: "employerAddress.postCode",
        country: "employerAddress.country"
      }}
    />
  );
});
