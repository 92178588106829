import {
  Checkbox,
  dataAttribute,
  DataAttributes,
  DetailsRow,
  IDetailsRowProps
} from "@bps/fluent-ui";
import { ServiceSearchDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { HighlightedText } from "@ui-components/highlighted-text/HighlightedText.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { getDetailsRowStyles } from "./AddServicesModal.styles.tsx";

interface ScheduleServicesProps {
  services: ServiceSearchDto[];
  filter: string;
  onItemsChecked: (item: ServiceSearchDto) => void;
  checkedItemsIds: string[];
}

export const SearchedServicesList: React.FC<ScheduleServicesProps> = ({
  services,
  filter,
  onItemsChecked,
  checkedItemsIds
}) => {
  const renderRow = (props: IDetailsRowProps): JSX.Element => {
    return (
      <div
        onClick={e => {
          e?.preventDefault();
          onItemsChecked(props.item as ServiceSearchDto);
        }}
      >
        <DetailsRow styles={getDetailsRowStyles} {...props} />
      </div>
    );
  };

  const renderDescription = (item: ServiceSearchDto) => {
    const { name = "", description = "" } = item;
    const isMatchName: boolean = name
      .toLowerCase()
      .includes(filter.toLowerCase());

    const isMatchDescription: boolean = description
      .toLowerCase()
      .includes(filter.toLowerCase());

    let displayText: string = "";

    if (isMatchName) {
      displayText = name;
    } else if (isMatchDescription) {
      displayText = description;
    }

    if (!displayText) {
      displayText = name || description;
    }

    return (
      <HighlightedText
        text={displayText || ""}
        filter={filter}
        verticalAlign="center"
        styles={{ root: { overflow: "hidden", minHeight: 38 } }}
      />
    );
  };

  return (
    <ShimmeredDetailsList
      listProps={{
        ...dataAttribute(DataAttributes.Element, "searched-service-list")
      }}
      setKey={`scheduleServicesList${services.length}`}
      items={services}
      isHeaderVisible={false}
      columns={[
        {
          name: "Checkbox",
          key: "checkbox",
          minWidth: 60,
          isResizable: true,
          maxWidth: 60,
          onRender: (item: ServiceSearchDto) => (
            <Checkbox
              automationAttribute="service-list-item-checkbox"
              inputProps={{
                value: checkedItemsIds.includes(item.serviceId).toString()
              }}
              onChange={() => {
                onItemsChecked(item);
              }}
              checked={checkedItemsIds.includes(item.serviceId)}
            />
          )
        },
        {
          name: "Item",
          key: "item",
          minWidth: 80,
          maxWidth: 80,
          onRender: (item: ServiceSearchDto) => (
            <HighlightedText
              text={item.code}
              filter={filter}
              verticalAlign="center"
              {...dataAttribute(DataAttributes.Element, `code-${item.code}`)}
            />
          )
        },
        {
          name: "Description",
          key: "description",
          minWidth: 200,
          maxWidth: 850,
          className: "clampTwoLines",
          onRender: renderDescription
        }
      ]}
      onRenderRow={renderRow}
    />
  );
};
