import { Observer } from "mobx-react-lite";

import { Spinner, useResizeElementObserver } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  QuestionnaireDto,
  QuestionnaireType,
  Rand36QuestionnaireResponseDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolView } from "../clinical-tool-view/ClinicalToolView.tsx";
import {
  ClinicalToolAnswer,
  findClinicalToolAnswerText
} from "../utils/ClinicalToolAnswerFinder.ts";
import { Rand36Questions } from "./Rand36Questions.tsx";

export interface Rand36QuestionnaireViewProps {
  encounterId: string;
  clinicalRecord: ClinicalRecord;
}

interface Rand36ViewFormModel {
  questionnaire: QuestionnaireDto;
  answers: ClinicalToolAnswer[];
  isConfidential: boolean;
  lastUpdatedDate?: DateTime;
  lastUpdatedUserId?: string;
}

export const Rand36QuestionnaireView: React.FC<
  Rand36QuestionnaireViewProps
> = ({ encounterId }) => {
  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const getRand36Data = async ({
    clinical
  }: RootStore): Promise<Rand36ViewFormModel> => {
    const questionnaire = await clinical.getQuestionnaires(
      QuestionnaireType.RAND36
    );

    const savedData = (
      await clinical.getEncounterClinicalData({
        encounterId,
        types: [ClinicalDataType.RAND36]
      })
    )[ClinicalDataType.RAND36.toLowerCase()] as Rand36QuestionnaireResponseDto;

    const answers = savedData
      ? savedData.items.map(i => ({
          questionId: `${i.questionnaireItemId}`,
          answerValue: i.answer,
          answerText: findClinicalToolAnswerText(
            i.questionnaireItemId,
            i.answer!,
            questionnaire
          )
        }))
      : [];

    const isConfidential = !!savedData.secGroupId;

    const lastUpdatedDate = getClinicalDataLastUpdatedDate(savedData);

    const lastUpdatedUserId = getClinicalDataLastUpdatedUserId(savedData);

    return {
      answers,
      questionnaire,
      isConfidential,
      lastUpdatedDate,
      lastUpdatedUserId
    };
  };

  return (
    <DataFetcher<Rand36ViewFormModel>
      fetch={getRand36Data}
      fallback={<Spinner />}
      key={encounterId}
    >
      {({
        answers,
        questionnaire,
        isConfidential,
        lastUpdatedDate,
        lastUpdatedUserId
      }) => (
        <Observer>
          {() => {
            return (
              <>
                <div
                  ref={r => {
                    if (r && !element) {
                      setElement(r);
                    }
                  }}
                />
                <ClinicalToolView
                  title="RAND 36"
                  encounterId={encounterId}
                  isConfidential={isConfidential}
                  lastUpdatedDate={lastUpdatedDate}
                  lastUpdatedUserId={lastUpdatedUserId}
                  onRenderSummary={() => undefined} // Don't render the summary at all.
                >
                  <Rand36Questions
                    questionnaire={questionnaire}
                    width={panelWidth}
                    answers={answers}
                    questionPrefix=""
                  />
                </ClinicalToolView>
              </>
            );
          }}
        </Observer>
      )}
    </DataFetcher>
  );
};
