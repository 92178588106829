import { FormEvent } from "react";
import { useForm, useFormState } from "react-final-form";

import { IComboBox, Stack } from "@bps/fluent-ui";
import { Pronoun } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";

interface PronounFieldsProps {
  pronounSubjectiveName: string;
  pronounObjectiveName: string;
  pronounPossessiveName: string;
}

export const EditPronounHeader: React.FunctionComponent<PronounFieldsProps> = ({
  pronounSubjectiveName,
  pronounObjectiveName,
  pronounPossessiveName
}) => {
  const { practice } = useStores();
  const form = useForm();

  const { errors } = useFormState({
    subscription: { errors: true }
  });

  const showError =
    errors?.[pronounSubjectiveName] ||
    errors?.[pronounObjectiveName] ||
    errors?.[pronounPossessiveName];

  const pronounSelectorProps = {
    validateOnInitialize: true,
    calloutProps: {
      calloutMinWidth: 300,
      calloutMaxHeight: 500
    },
    styles: { root: { width: 90 } },
    allowFreeform: true
  };

  const onRenderSubjectiveOption = (selectedKey: string | undefined) => {
    switch (selectedKey?.toLowerCase()) {
      case Pronoun.SHE.toLowerCase(): {
        return Pronoun.SHE;
      }
      case Pronoun.HE.toLowerCase(): {
        return Pronoun.HE;
      }
      case Pronoun.THEY.toLowerCase(): {
        return Pronoun.THEY;
      }
      default:
        return selectedKey ?? "";
    }
  };

  const onSubjectiveOptionChange = (selectedKey: string | undefined) => {
    switch (selectedKey?.toLowerCase()) {
      case Pronoun.HE.toLowerCase():
        form.batch(() => {
          form.change(pronounPossessiveName, Pronoun.HIS);
          form.change(pronounObjectiveName, Pronoun.HIM);
        });
        break;
      case Pronoun.SHE.toLowerCase():
        form.batch(() => {
          form.change(pronounPossessiveName, Pronoun.HERS);
          form.change(pronounObjectiveName, Pronoun.HER);
        });
        break;
      case Pronoun.THEY.toLowerCase(): {
        form.batch(() => {
          form.change(pronounPossessiveName, Pronoun.THEIR);
          form.change(pronounObjectiveName, Pronoun.THEM);
        });
        break;
      }
      default:
        break;
    }
  };

  const onRenderObjectiveOption = (selectedKey: string | undefined) => {
    switch (selectedKey?.toLowerCase()) {
      case Pronoun.HER.toLowerCase(): {
        return Pronoun.HER;
      }
      case Pronoun.HIM.toLowerCase(): {
        return Pronoun.HIM;
      }
      case Pronoun.THEM.toLowerCase(): {
        return Pronoun.THEM;
      }
      default:
        return selectedKey ?? "";
    }
  };

  const onObjectiveOptionChange = (selectedKey: string | undefined) => {
    switch (selectedKey?.toLowerCase()) {
      case Pronoun.HIM.toLowerCase():
        form.batch(() => {
          form.change(pronounSubjectiveName, Pronoun.HE);
          form.change(pronounPossessiveName, Pronoun.HIS);
        });
        break;
      case Pronoun.HER.toLowerCase():
        form.batch(() => {
          form.change(pronounSubjectiveName, Pronoun.SHE);
          form.change(pronounPossessiveName, Pronoun.HERS);
        });
        break;
      case Pronoun.THEM.toLowerCase(): {
        form.batch(() => {
          form.change(pronounSubjectiveName, Pronoun.THEY);
          form.change(pronounPossessiveName, Pronoun.THEIR);
        });
        break;
      }
      default:
        break;
    }
  };

  const onRenderPossessiveOption = (selectedKey: string | undefined) => {
    switch (selectedKey?.toLowerCase()) {
      case Pronoun.HERS.toLowerCase(): {
        return Pronoun.HERS;
      }
      case Pronoun.HIS.toLowerCase(): {
        return Pronoun.HIS;
      }
      case Pronoun.THEIR.toLowerCase(): {
        return Pronoun.THEIR;
      }
      default:
        return selectedKey ?? "";
    }
  };

  const onPossessiveOptionChange = (selectedKey: string | undefined) => {
    switch (selectedKey?.toLowerCase()) {
      case Pronoun.HIS.toLowerCase():
        form.batch(() => {
          form.change(pronounSubjectiveName, Pronoun.HE);
          form.change(pronounObjectiveName, Pronoun.HIM);
        });
        break;
      case Pronoun.HERS.toLowerCase():
        form.batch(() => {
          form.change(pronounSubjectiveName, Pronoun.SHE);
          form.change(pronounObjectiveName, Pronoun.HER);
        });
        break;
      case Pronoun.THEIR.toLowerCase(): {
        form.batch(() => {
          form.change(pronounSubjectiveName, Pronoun.THEY);
          form.change(pronounObjectiveName, Pronoun.THEM);
        });
        break;
      }
      default:
        break;
    }
  };

  const onClearClick = () => {
    form.batch(() => {
      form.change(pronounObjectiveName, undefined);
      form.change(pronounPossessiveName, undefined);
      form.change(pronounSubjectiveName, undefined);
    });
  };

  return (
    <Fieldset
      legend="Pronouns (e.g.: She | Her | Hers)"
      hasAsterisk={showError}
      actionButton={{
        text: Labels.clear,
        props: { onClick: () => onClearClick() }
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <ComboBoxField
          {...pronounSelectorProps}
          name={pronounSubjectiveName}
          options={
            practice.ref.pronouns.keyNameValuesWithContructedPronounSubjective
          }
          hint="Subjective"
          onRenderFieldText={onRenderSubjectiveOption}
          autoComplete="off"
          onChange={(event: FormEvent<IComboBox>, key: string | undefined) => {
            // Changed via dropdown click
            if (event.type === "click" && key) onSubjectiveOptionChange(key);
          }}
        />
        <ComboBoxField
          {...pronounSelectorProps}
          name={pronounObjectiveName}
          options={
            practice.ref.pronouns.keyNameValuesWithContructedPronounobjective
          }
          hint="Objective"
          calloutProps={{
            calloutMinWidth: 200,
            calloutMaxHeight: 500
          }}
          onRenderFieldText={onRenderObjectiveOption}
          autoComplete="off"
          onChange={(event: FormEvent<IComboBox>, key: string | undefined) => {
            // Changed via dropdown click
            if (event.type === "click" && key) onObjectiveOptionChange(key);
          }}
        />
        <ComboBoxField
          {...pronounSelectorProps}
          name={pronounPossessiveName}
          options={
            practice.ref.pronouns.keyNameValuesWithContructedPronounpossessive
          }
          hint="Possessive"
          calloutProps={{
            styles: { root: { boxShadow: "none" } },
            calloutMinWidth: 150,
            calloutMaxHeight: 500
          }}
          onRenderFieldText={onRenderPossessiveOption}
          autoComplete="off"
          onChange={(event: FormEvent<IComboBox>, key: string | undefined) => {
            // Changed via dropdown click
            if (event.type === "click" && key) onPossessiveOptionChange(key);
          }}
        />
      </Stack>
    </Fieldset>
  );
};
