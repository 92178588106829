import { observer } from "mobx-react-lite";

import { Heading, Stack } from "@bps/fluent-ui";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { PatientMergeFields } from "./PatientMergeFields.tsx";
import { PatientMergeFieldValues } from "./PatientMergeFieldValues.ts";
import { PatientMergeValidator } from "./PatientMergeValidator.tsx";

const PatientMergeDialog: React.FC = observer(() => {
  const {
    practice: {
      ui: {
        setShowPatientMergeModal,
        masterPatient,
        setShowPatMergeConfirmation
      }
    }
  } = useStores();

  const patientLabel = usePatientLabel(true);
  const validator = new PatientMergeValidator();
  const getMasterStatusFilter = (patientStatus: String | undefined) => {
    if (!patientStatus) return ContactStatus.Active;

    switch (patientStatus) {
      case ContactStatus.Active:
        return ContactStatus.Active;
      case ContactStatus.Deceased:
        return ContactStatus.Deceased;
      case ContactStatus.Inactive:
        return ContactStatus.Inactive;
      default:
        return ContactStatus.Active;
    }
  };
  return (
    <Stack grow tokens={{ childrenGap: 8 }}>
      <SubmissionFormDialog<PatientMergeFieldValues>
        initialValues={{
          masterId: masterPatient?.id,
          masterStatusFilter: getMasterStatusFilter(masterPatient?.status),
          duplicateStatusFilter: ContactStatus.Active
        }}
        onSubmit={() => {
          setShowPatMergeConfirmation(true);
        }}
        buttonsProps={{
          cancelButtonProps: {
            id: "close-patient-merge-form-dialog"
          },
          submitButtonProps: {
            text: "Continue",
            iconProps: { hidden: true }
          },
          hideButtonsSeparator: true,
          styles: {
            root: {
              marginTop: 0
            }
          },
          extraPromptConditionOnCancel: ({ getFieldState }) =>
            !!getFieldState("duplicateId")?.value ||
            !!getFieldState("masterId")?.value,
          promptOnCancel: true
        }}
        validate={validator.validate}
        dialogName="Patient merge dialog"
        dialogProps={{
          onDismiss: () => {
            setShowPatientMergeModal(false);
          },
          maxWidth: 890,
          dialogContentProps: {
            title: (
              <Heading variant="modal-heading">{patientLabel} merge</Heading>
            )
          }
        }}
      >
        {() => <PatientMergeFields />}
      </SubmissionFormDialog>
    </Stack>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PatientMergeDialog;
