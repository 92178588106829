import { FormApi } from "final-form";
import { FC } from "react";

import { Heading, PrimaryButton } from "@bps/fluent-ui";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { FormTemplateDTO } from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import {
  getClinicalFormContext,
  getFormContext,
  onSendFormsClick
} from "@modules/forms/components/forms-context-menu/form-context-menu.utils.tsx";
import { canReceiveForm } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { useQuickAccessContext } from "./context/QuickAccessContext.tsx";

interface QuickAccessOnlineFormsDialogValues {
  form: string;
}

interface QuickAccessOnlineFormsDialogProps {
  formTemplates: FormTemplateDTO[];
}

const QuickAccessOnlineFormsDialogBase: FC<
  QuickAccessOnlineFormsDialogProps
> = ({ formTemplates }) => {
  const options = formTemplates.map(x => ({
    key: x.id,
    text: x.name
  }));

  const trackFormEvent = useAppTrackEvent(AppInsightsEventNames.formDeployed);

  const nameOf = nameOfFactory<QuickAccessOnlineFormsDialogValues>();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { forms } = useStores();
  const { getDetails } = forms;
  const patient = clinicalRecord.patient;
  const { setShowOnlineFormsDialog } = useQuickAccessContext();

  const sendButton = (
    form: FormApi<QuickAccessOnlineFormsDialogValues>,
    isPrimaryDisabled?: boolean | undefined
  ) => {
    const formId = form.getFieldState(nameOf("form"))?.value;

    return (
      <PrimaryButton
        iconProps={{ iconName: "GenericScan" }}
        text="Show QR"
        onClick={async () => {
          const formTemplate = formTemplates.find(x => x.id === formId);
          formTemplate &&
            (await onSendFormsClick({
              formTemplate,
              patientId: patient?.id,
              qrcode: true,
              isClinical: formTemplate.isClinical ?? false,
              formUi: forms.ui
            }));

          setShowOnlineFormsDialog(false);
        }}
        disabled={isPrimaryDisabled}
      />
    );
  };

  const deployForm = async (
    formTemplate: FormTemplateDTO,
    isClinical: boolean
  ) => {
    const context = isClinical
      ? getClinicalFormContext({ patientId: patient?.id })
      : getFormContext({ patientId: patient?.id, claimId: undefined });

    if (context) {
      await forms.deployForm({
        formTemplate,
        context
      });
      trackFormEvent({ form: formTemplate.name, page: document.title });

      forms.ui.setSendFormDialog(undefined);
    }
  };

  return (
    <SubmissionFormDialog<QuickAccessOnlineFormsDialogValues>
      onSubmit={async values => {
        const formTemplate = formTemplates.find(x => x.id === values.form);
        const details = await getDetails(patient?.id ?? "");
        if (formTemplate) {
          if (details.sendOptions.length > 0) {
            // Show sendFormConfirmationDialog
            await onSendFormsClick({
              formTemplate,
              patientId: patient?.id,
              isClinical: formTemplate.isClinical ?? false,
              formUi: forms.ui
            });
          } else {
            await deployForm(formTemplate, formTemplate.isClinical ?? false);
          }
        }
      }}
      onSubmitSucceeded={() => setShowOnlineFormsDialog(false)}
      dialogProps={{
        onDismiss: () => {
          setShowOnlineFormsDialog(false);
        },
        minWidth: 600,
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">
              {`Online form for ${patient?.name}`}
            </Heading>
          )
        }
      }}
      buttonsProps={{
        extraActionsBetween: sendButton,
        styles: {
          root: {
            width: 600
          }
        },
        submitButtonProps: {
          iconProps: { iconName: "Send" },
          text: "Send",
          primaryDisabled: !canReceiveForm(patient?.communications)
        }
      }}
      dialogName="Quick access online form"
    >
      {() => {
        return (
          <OptionsSelectField
            label="Form"
            options={options}
            name={nameOf("form")}
            required
            placeholder="Select a form"
            hideSearchOption
            hideClearButton
          />
        );
      }}
    </SubmissionFormDialog>
  );
};

export const QuickAccessOnlineFormsDialog: FC = () => {
  const { forms } = useStores();
  return (
    <DataFetcher<FormTemplateDTO[]>
      fetch={async () => {
        return await forms.getTemplates({
          context: ["ClinicalPatientId", "PatientId"]
        });
      }}
    >
      {formTemplates => (
        <QuickAccessOnlineFormsDialogBase formTemplates={formTemplates} />
      )}
    </DataFetcher>
  );
};
