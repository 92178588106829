export enum ClinicalToolEditOptions {
  addNew = "Add New",
  record = "Record",
  viewTrend = "View Trending",
  closeSeries = "Close Series",
  makeConfidential = "Make Confidential",
  removeConfidentiality = "Remove Confidentiality"
}

export enum ClinicalToolReferences {
  DASH11 = "The DASH was developed by the American Academy of Orthopedic Surgeons, the Council of the Musculoskeletal Specialty Societies, and the Institute for Work and Health as a region-specific instrument to measure patients’ perception of disability and symptoms associated with any joint or condition of the upper limb (Hudak et al., 1996; Veehof et al., 2002)",
  GRCS = "Dr. Gordon Guyatt at McMaster University developed the Global Rating of Change Questionnaire (GRCQ), an approach to elucidating the significance of changes in score in quality of life instruments by comparing them to global rating of change.",
  OREBRO = "The Orebro Musculoskeletal Pain Screening Questionnaire was developed by Professor Linton and colleagues in 1997.",
  PSFS = "PSFS developed by: Stratford, P., Gill, C., Westaway, M., & Binkley, J. (1995). Assessing disability and change on individual patients: a report of patient specific measure. Physiotherapy Canada. 47, 258-263."
}
