import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  searchFilterItem,
  statusFilterItem,
  typesFilterItem
} from "@modules/practice/screens/address-book/components/contacts-filters/contact-filter-items.ts";
import { useParseContactFilters } from "@modules/practice/screens/address-book/components/contacts-filters/useParseContactFilters.ts";
import { PatientContactFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

export const filterKeys = {
  categories: "categories",
  statuses: "statuses",
  search: "search",
  types: "types"
};

export const defaultPatientFilter: PatientContactFilter = {
  search: "",
  types: [ContactType.Patient],
  statuses: [ContactStatus.Active]
};

export const PatientListFilters: FC<
  Pick<FilterBarProps<PatientContactFilter>, "children">
> = observer(props => {
  const { practice } = useStores();

  const { parseArrayFilter, setLocationFromFilter } =
    useParseContactFilters(filterKeys);

  const initialState = usePersistedHelper(() => {
    const defaultFilter: PatientContactFilter = { ...defaultPatientFilter };

    const filterQuery = { ...defaultFilter, ...parseArrayFilter() };

    return {
      search: filterQuery?.search,
      types: filterQuery?.types ?? [],
      statuses: filterQuery?.statuses ?? []
    };
  });

  const patientFilters: Item<PatientContactFilter>[] = [
    searchFilterItem(),
    typesFilterItem(
      practice.ref.contactTypes.keyTextValues.filter(
        x => x.key !== ContactType.Organisation
      )
    ),
    statusFilterItem(practice.ref.contactStatuses.keyTextValues)
  ];

  return (
    <FilterBar<PatientContactFilter>
      {...props}
      onChange={({ field, value }) => setLocationFromFilter(field, value)}
      items={patientFilters}
      initialValues={initialState}
    />
  );
});
