import { useContext } from "react";
import { useForm } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";
import { AppointmentTypePicker } from "./group-attendees-details/appointment-type-picker/AppointmentTypePicker.tsx";

export const AppointmentTypeField: React.FunctionComponent = () => {
  const { handleAppointmentTypeChange, isAppointmentValidForEdit } = useContext(
    AppointmentFormContext
  );

  const form = useForm<AppointmentFormValues>();

  const _handleAppointmentTypeChange = (value: string) => {
    const { getState, change, batch } = form;
    const { initialValues, values } = getState();
    handleAppointmentTypeChange(value, {
      initialValues,
      values,
      change,
      batch
    });
  };

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      tokens={{ childrenGap: 8 }}
    >
      <AppointmentTypePicker
        disabled={isAppointmentValidForEdit}
        name={appointmentFormNameOf("appointmentTypeId")}
        onRemoveAppointmentType={() => {
          form.change("appointmentTypeId", undefined);
        }}
      />
      <SpinNumberInputField
        required
        label="Duration (mins)"
        name={appointmentFormNameOf("duration")}
        min={0}
        step={15}
        parse={value => Number(value)}
        styles={{ input: { width: 0 } }}
        disabled={isAppointmentValidForEdit}
        fieldItemStyles={{
          root: { flexGrow: 1.2, flexBasis: 0 },
          subComponentStyles: {
            label: {
              root: { selectors: { "&::after": { paddingRight: 0 } } }
            }
          }
        }}
      />
      <FieldSpy
        name={appointmentFormNameOf("appointmentTypeId")}
        onChange={_handleAppointmentTypeChange}
      />
    </Stack>
  );
};
