import { DATE_FORMATS, DateTime } from "@bps/utils";
import { AccBenefitCountDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  AddScheduleDto,
  AddServiceDto,
  AddServiceInstanceDto,
  FeeType,
  GstMethod,
  ServiceRuleDto,
  ServiceRuleType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceSettings } from "@stores/billing/models/InvoiceSettings.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";
import { Service } from "@stores/billing/models/Service.ts";

import {
  FeeFormValues,
  newScheduleFormNameOf,
  NewScheduleFormValues,
  ScheduleFormValues
} from "./ScheduleForm.types.tsx";

export function getNewScheduleFormValues(
  settings: InvoiceSettings[]
): NewScheduleFormValues {
  return {
    ...getFormValuesFromSchedule(undefined),
    ...getFeeFormValuesFromDto({ settings })
  };
}

export function getScheduleDtoFromFormValues(
  formValues: ScheduleFormValues
): AddScheduleDto {
  return {
    name: formValues.scheduleName,
    description: formValues.scheduleDescription,
    startDate: DateTime.jsDateToISODate(formValues.scheduleStartDate)!,
    endDate: DateTime.jsDateToISODate(formValues.scheduleEndDate)
  };
}

export function getFormValuesFromSchedule(
  schedule: Schedule | undefined
): ScheduleFormValues {
  return {
    scheduleName: schedule?.name || "",
    scheduleDescription: schedule?.description,
    scheduleStartDate: schedule?.startDate
      ? DateTime.jsDateFromISO(schedule.startDate)
      : DateTime.today().toJSDate(),
    scheduleEndDate: DateTime.jsDateFromISO(schedule?.endDate),
    scheduleIsOngoing: !schedule?.endDate
  };
}

export function getFeeDtoFromFormValues(
  formValues: FeeFormValues,
  schedule: Schedule,
  fee?: Service
): AddServiceDto {
  const instances: AddServiceInstanceDto[] = (fee?.lockedInstances || []).map(
    instance => ({
      effectiveDate: instance.effectiveDate,
      fee: instance.fee,
      isActive: instance.isActive,
      rules: instance.rules
    })
  );

  if (formValues.feeShowCurrent) {
    const currentInstance: AddServiceInstanceDto = {
      effectiveDate: DateTime.fromJSDateOrNow(
        formValues.feeEffectiveDate
      )?.toISODate(),
      fee: formValues.feeIsActive ? Number(formValues.feeFee) : undefined,
      isActive: !!formValues.feeIsActive,
      rules: updateUserDefinedRule(
        fee?.currentInstance?.rules || [],
        !!formValues.feeIsEditable
      )
    };

    instances.push(currentInstance);
  }

  if (
    formValues.feeNextEffectiveDate &&
    (formValues.feeNextFee ||
      !formValues.feeNextIsActive ||
      formValues.feeNextIsEditable !== undefined)
  ) {
    const nextInstance: AddServiceInstanceDto = {
      effectiveDate: DateTime.fromJSDate(
        formValues.feeNextEffectiveDate
      )?.toISODate()!,
      fee: formValues.feeNextIsActive
        ? Number(formValues.feeNextFee)
        : undefined,
      isActive: !!formValues.feeNextIsActive,
      rules: formValues.feeNextIsActive
        ? updateUserDefinedRule(
            fee?.nextInstance?.rules || [],
            !!formValues.feeNextIsEditable
          )
        : []
    };
    if (
      formValues.feeEffectiveDate?.getTime() ===
        formValues.feeNextEffectiveDate?.getTime() &&
      fee?.currentInstance
    ) {
      instances.splice(-1, 1, {
        ...nextInstance
      });
    } else {
      instances.push(nextInstance);
    }
  }

  return {
    code: formValues.feeCode!,
    scheduleId: schedule.id || "",
    name: formValues.feeName || "",
    description: formValues.feeDescription!,
    isService: formValues.feeIsService,
    gstMethod: formValues.feeIncludesGst
      ? GstMethod.included
      : GstMethod.notApplicable,
    feeType: formValues.feeType,
    instances
  };
}

function updateUserDefinedRule(
  rules: ServiceRuleDto[],
  isEditable: boolean
): ServiceRuleDto[] {
  if (isEditable) {
    return rules.some(
      rule => rule.ruleType === ServiceRuleType.UserDefinedAmount
    )
      ? rules
      : [...rules, { ruleType: ServiceRuleType.UserDefinedAmount }];
  }
  return rules.filter(
    rule => rule.ruleType !== ServiceRuleType.UserDefinedAmount
  );
}

export function getFeeFormValuesFromDto({
  fee,
  settings
}: {
  fee?: Service;
  schedule?: Schedule;
  settings: InvoiceSettings[];
}): FeeFormValues {
  let feeIncludesGst: boolean = false;
  if (fee?.gstMethod !== undefined) {
    feeIncludesGst = fee.gstIncluded;
  } else if (settings.length) {
    feeIncludesGst = settings[0].alwaysApplyGst;
  }

  let feeEffectiveDate: Date | undefined;
  if (fee) {
    if (fee?.currentInstance?.effectiveDate) {
      feeEffectiveDate = DateTime.fromISO(
        fee.currentInstance.effectiveDate
      ).toJSDate();
    }
  } else {
    feeEffectiveDate = DateTime.today().toJSDate();
  }

  return {
    feeCode: fee?.code,
    feeIsService: fee?.isService ?? true,
    feeIncludesGst,
    feeName: fee?.name,
    feeType: fee?.feeType ?? FeeType.FlatRate,
    feeDescription: fee?.description,
    feeEffectiveDate,
    feeFee: fee?.currentInstance?.fee
      ? String(fee.currentInstance.fee)
      : undefined,
    feeIsActive: fee ? fee.currentInstance?.isActive : true,
    feeIsEditable: fee?.currentInstance?.rules?.some(
      rule => rule.ruleType === ServiceRuleType.UserDefinedAmount
    ),
    feeNextEffectiveDate: fee?.nextInstance?.effectiveDate
      ? DateTime.fromISO(fee.nextInstance.effectiveDate).toJSDate()
      : undefined,
    feeNextFee: fee?.nextInstance?.fee
      ? String(fee.nextInstance.fee)
      : undefined,
    feeNextIsActive: fee?.nextInstance?.isActive ?? true,
    feeNextIsEditable: fee?.nextInstance?.rules?.some(
      rule => rule.ruleType === ServiceRuleType.UserDefinedAmount
    ),
    feeShowCurrent: !fee || !!fee?.currentInstance
  };
}

// Returns whether the user has started the fee part of the combined form
// If the user has not, that part won't be validated or saved
// Other fields aren't taken into account because they either cannot be cleared
//  or it is difficult to clear them
export const isFeeFormDirty = (values: NewScheduleFormValues) => {
  return Boolean(
    values[newScheduleFormNameOf("feeCode")] ||
      values[newScheduleFormNameOf("feeName")] ||
      values[newScheduleFormNameOf("feeDescription")] ||
      values[newScheduleFormNameOf("feeFee")] !== ""
  );
};

export const getScheduleStatus = (schedule: Schedule) => {
  if (!schedule.isInactive) {
    return "Currently active (ongoing)";
  }

  const today = DateTime.today();
  if (schedule.endDate && today > DateTime.fromISO(schedule.endDate)) {
    return `Inactive (from ${DateTime.fromISO(schedule.endDate)
      .plus({ days: 1 })
      .toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)})`;
  }

  if (today < DateTime.fromISO(schedule.startDate)) {
    return `Inactive (until ${DateTime.fromISO(schedule.startDate).toFormat(
      DATE_FORMATS.DAY_DEFAULT_FORMAT
    )})`;
  }

  // This should not be reachable but provided just in case
  return "Inactive";
};

export const getFeeTypeText = (feeType: FeeType): string => {
  switch (feeType) {
    case FeeType.FlatRate:
      return "flat rate";
    case FeeType.PerUnit:
      return "each";
    case FeeType.Km:
      return "per km";
    case FeeType.Hourly:
      return "per hr";
    default:
      return "";
  }
};

export const getContractClassWithCount = (
  baseText: string | undefined,
  code: string,
  accBenefitCounts: AccBenefitCountDto[]
): string => {
  const text = baseText ?? code;

  const count = accBenefitCounts.find(abc => abc.contractClass === code)?.count;

  return count !== undefined ? `${text} (${count})` : text;
};
