import { MonthYearRecurrence } from "@libs/constants/month-year-recurrence.enum.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export interface UserReservesFormValues {
  currentUserReserves: UserReservesValues[];
  previousUserReserves: UserReservesValues[];
}

export interface UserReservesValues {
  id?: string;
  bookedBy: string;
  orgUnitId: string;
  calendarEventType: CalendarEventType;
  startDate: Date;
  endScheduleType: string;
  startTime?: string;
  endTime?: string;
  purpose?: string;
  interval: number;
  frequency: number;
  count?: number;
  until?: Date;
  dayRecur?: number[];
  monthRecur?: number[];
  monthDayRecur?: number[];
  weekPosition?: number;
  monthYearRecurrence?: MonthYearRecurrence;
}

export enum UserReservesFormTestElements {
  AddReserveCardButton = "user-reserves-form-add-reserves-button",
  AddReserveBodyButton = "user-reserves-form-add-nox-reserves-button",
  FieldGroup = "user-reserves-form-field",
  UntilDate = "until-date",
  StartDate = "start-date",
  UserReservesForm = "user-reserves-form",
  PreviousUserReservesForm = "previous-user-reserves-form",
  StartTime = "start-times",
  EndTime = "end-time"
}
