import { useEffect } from "react";

import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

import { isEventFiltered } from "../calendar/utils.ts";
import { AppointmentFilterStatus } from "./AppointmentFilter.types.ts";

/**
 * Scroll calendar to the earliest filtered appointment
 * @param events
 * @param appointmentsFilter
 */
export const useAppointmentFilterScroll = (
  events: CalendarEvent[],
  appointmentsFilter: AppointmentFilterStatus | undefined
) => {
  useEffect(() => {
    if (appointmentsFilter && events.length) {
      const filteredGroup = events.filter(e =>
        isEventFiltered(e, appointmentsFilter)
      );

      if (filteredGroup.length) {
        const sortedFilteredGroup = Array.from(filteredGroup).sort(
          (a, b) => a.startDateTime.toMillis() - b.startDateTime.toMillis()
        );

        const earliestAppt = sortedFilteredGroup[0];
        if (earliestAppt) {
          const eventElement = document.querySelector(
            `[data-calendar-event-id="${earliestAppt.id}"]`
          );
          if (eventElement) {
            eventElement.scrollIntoView({ block: "center", inline: "center" });
          }
        }
      }
    }
  }, [events, appointmentsFilter]);
};
