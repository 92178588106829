import { createContext, useContext } from "react";

import { InvestigationSidePanelHelper } from "./InvestigationSidePanelHelper.ts";

export const InvestigationSidePanelContext =
  createContext<InvestigationSidePanelHelper>(
    {} as InvestigationSidePanelHelper
  );

export const useInvestigationHelper = () =>
  useContext(InvestigationSidePanelContext);
