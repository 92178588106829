import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  Badge,
  dataAttribute,
  DataAttributes,
  FontWeights,
  IconButton,
  Persona,
  PersonaSize,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { PatientNotePriority } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { getPriorityColor } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTableRow.tsx";
import { getHighestNoticePriority } from "@modules/practice/screens/shared-components/patient-notices/utils.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export type GroupAttendeeProps = {
  contact: Contact;
  onRemove: (contact: Contact) => void;
};

export const GroupAttendee: React.FunctionComponent<GroupAttendeeProps> =
  observer(({ contact, onRemove }) => {
    const {
      setSelectedGroupAttendeeId,
      getFilteredPatientNotices,
      getSystemNotices,
      onShowEdit
    } = useContext(AppointmentFormContext);

    const { booking } = useStores();

    const id = contact.id;
    const notices = getFilteredPatientNotices(id);

    const systemNotices = getSystemNotices(id);

    const priority =
      systemNotices.length > 0
        ? PatientNotePriority.High
        : getHighestNoticePriority(notices);

    const noticeCounts = systemNotices.length + notices.length;

    const theme = useTheme();
    const { isAppointmentValidForEdit } = useContext(AppointmentFormContext);

    return (
      <Stack key={`attendee-container-${contact.id}`} horizontal>
        <Stack
          key={`persona-stack-${contact.id}`}
          styles={{
            root: {
              border: `1px solid ${theme.palette.neutralSecondaryAlt}`,
              padding: 8,
              gap: 10,
              background: theme.palette.white,
              flexGrow: 1,
              cursor: "pointer",
              selectors: {
                "&:hover ": {
                  backgroundColor: theme.semanticColors.disabledBackground,
                  textDecoration: "none"
                },
                "&:active:hover ": {
                  textDecoration: "none"
                }
              }
            }
          }}
          onClick={() => {
            onShowEdit(PatientCardIds.patientHeader, contact.id);
          }}
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Persona
            text={contact.name}
            id={contact.id}
            secondaryText={contact.phone}
            size={PersonaSize.size40}
            imageInitials={contact.initials}
            styles={{
              root: {
                paddingLeft: 3
              },
              primaryText: {
                fontWeight: FontWeights.semibold,
                maxWidth: 200
              }
            }}
          />

          <Badge
            {...dataAttribute(
              DataAttributes.Element,
              "patient-notices-count-button"
            )}
            styles={{
              root: [
                {
                  borderRadius: 5,
                  height: 26,
                  backgroundColor: getPriorityColor(theme.palette, priority),
                  cursor: "pointer",
                  selectors: {
                    "&:hover ": {
                      backgroundColor: theme.palette.neutralLighter
                    }
                  }
                },
                theme.fonts.small
              ]
            }}
            onClick={(ev: React.MouseEvent) => {
              ev.stopPropagation();

              booking.ui.setSecondColumnContent(
                SecondColumnContent.selectedGroupAttendee
              );
              setSelectedGroupAttendeeId(contact.id);
            }}
          >
            {`${noticeCounts} ${noticeCounts === 1 ? "notice" : "notices"}`}
          </Badge>
        </Stack>

        <Stack.Item styles={{ root: { margin: "auto 0", padding: "0 4px" } }}>
          <IconButton
            iconProps={{ iconName: "Trash" }}
            ariaLabel="Remove attendee"
            onClick={() => {
              onRemove(contact);
            }}
            disabled={isAppointmentValidForEdit}
          />
        </Stack.Item>
      </Stack>
    );
  });
