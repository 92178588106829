import { stringify } from "query-string";

import { dataAttribute, DataAttributes, Link, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import { isInvoice } from "@stores/billing/utils/transaction.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

interface TransactionActionCellProps {
  transaction: TransactionBase;
}

export const TransactionActionCell: React.FC<TransactionActionCellProps> = ({
  transaction
}) => {
  const { routing } = useStores();
  let payInvoiceLink: JSX.Element | null = null;

  if (
    transaction.isCancelled ||
    (isInvoice(transaction) && !transaction.owing)
  ) {
    return null;
  }

  if (isInvoice(transaction) && !transaction.isFullyPaid) {
    const accountId = routing.match(routes.accounts.account)?.params.id;

    const onClick = (event: React.MouseEvent) => {
      event.preventDefault();
      routing.pushWithFromQuery({
        pathname: routes.accounts.allocations.new.pattern,
        search: stringify({
          invoiceId: transaction.id,
          accountId
        })
      });
    };

    payInvoiceLink = (
      <When permission={Permission.PaymentCreate}>
        <Link
          onClick={onClick}
          {...dataAttribute(DataAttributes.Element, "pay-invoice-link")}
        >
          Pay
        </Link>
      </When>
    );
  }

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
      {payInvoiceLink}
    </Stack>
  );
};
