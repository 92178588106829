import { action, observable } from "mobx";

import { isDefined } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { GetTransactionsArgs } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { isInvoice } from "@stores/billing/utils/transaction.utils.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

export class AccInvoicesListHelper {
  constructor(
    private root: IRootStore,
    public defaultNzPublicInsurerId: string
  ) {}

  private get acc() {
    return this.root.acc;
  }

  @observable selectedInvoices: Invoice[] = [];
  @observable disableBulkSubmit = true;
  @observable invoiceEmailQueue = observable.array<Invoice>([]);

  @action
  setInvoiceEmailQueue = (invoices: Invoice[]) => {
    this.invoiceEmailQueue.replace(invoices);
  };

  @action
  updateFromSelection = () => {
    const selectedItems = this.selection.getSelection() as Invoice[];

    const canBeSent =
      selectedItems.length > 0 &&
      selectedItems.every(invoice => {
        return (
          invoice.claimReadyToInvoice && invoice?.accSchedule?.claimSubmittable
        );
      });

    this.disableBulkSubmit = !canBeSent;
    this.selectedInvoices = selectedItems.filter(item => item);
  };

  // Selections are not fully observable so best not to use selection.getSelection()
  //  or selection.length and instead use a separate observable (selectedInvoices)
  //  which is kept up to date
  selection = new Selection({ onSelectionChanged: this.updateFromSelection });

  bulkSubmitHandler = async () => {
    await this.acc.submitAccInvoices(this.selectedInvoices.map(x => x.id));

    if (this.selectedInvoices.length === 1) {
      const invoiceNumber = this.selectedInvoices[0].number;
      const message = `Invoice ${invoiceNumber} has been submitted to ACC`;
      this.root.notification.success(message);
    } else {
      const count = this.selectedInvoices.length;
      const message = `(${count}) invoices have been submitted to ACC`;
      this.root.notification.success(message);
    }
  };

  public subscribeToAccScheduleChanges = () => {
    this.acc.hub.onEntityEvent(Entity.Transaction, this.onAccScheduleEvent);
  };

  public unsubscribeToAccScheduleChanges = () => {
    this.acc.hub.unsubscribe(Entity.Transaction, this.onAccScheduleEvent);
  };

  @action
  public onAccScheduleEvent = async (event: EntityEventData) => {
    if (event.id != null && event.action === EventAction.TrivialUpdate) {
      const transaction = this.root.billing.transactionsNewMap.get(event.id);
      if (
        transaction &&
        transaction.eTag !== event.etag &&
        isInvoice(transaction)
      ) {
        this.root.billing.ui.lastUpdatedInvoiceETag = event.etag;
        await this.root.billing.getInvoice(event.id, { ignoreCache: true });
      }
    }
  };

  public fetchAccDataForSearchResult = async (result: Invoice[]) => {
    return await this.fetchCalendarEvents(result);
  };

  fetchCalendarEvents = async (result: Invoice[] | undefined) => {
    const calendarEventIds = result
      ?.map(invoice => invoice.calendarEventId)
      .filter(isDefined)
      .filter((id: string) => !this.root.booking.calendarEventsMap.has(id));

    if (calendarEventIds?.length) {
      await this.root.booking.getCalendarEvents({ calendarEventIds });
    }
  };

  getInvoices = async (query: GetTransactionsArgs) => {
    const result = await this.root.billing.fetchInvoicesNew(query);
    return result;
  };

  @action
  search = async (query: GetTransactionsArgs) => {
    const invoices = await this.getInvoices(query);

    if (invoices.results.length > 0) {
      this.fetchAccDataForSearchResult(invoices.results);
    }

    return invoices;
  };

  @observable
  totalInvoices: number | undefined;

  @action
  setTotalInvoices = (query: QueryResult<Invoice>) => {
    this.totalInvoices = query.total;
  };
}
