import { useCallback } from "react";

import {
  DetailsListLayoutMode,
  NoDataTile,
  ScrollablePane,
  SelectionMode,
  Stack,
  Tile,
  useFormContext
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { RunQueryOptions } from "@libs/utils/promise-observable/promise-observable.types.ts";
import {
  ClinicalActivityManagementFilter,
  ClinicalActivityManagementFilterValues
} from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalActivityManagementFilterBase.tsx";
import { ClinicalActivityTable } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalActivityTable.tsx";
import { ClinicalActivityType } from "@modules/clinical/screens/patient-record/components/clinical-activity/types/clinical-activity.type.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

export interface ActivitiesClinicalProps {
  setSelected: (selected: ClinicalActivity[]) => void;
}

export const BaseActivitiesClinicalList: React.FC<ActivitiesClinicalProps> = ({
  setSelected
}) => {
  const { clinical } = useStores();

  const {
    state: { values: filter }
  } = useFormContext<ClinicalActivityManagementFilterValues>();

  const getItems = useCallback(
    async (options?: RunQueryOptions) => {
      const clinicalActivities = await clinical.getClinicalActivities({
        ...options,
        ...filter,
        descriptions: filter.descriptions,
        priorities: filter.activityPriorities,
        statuses: filter.activityStatuses,
        startDateTime: DateTime.jsDateToISODate(filter.dueDateStartDate),
        endDateTime: DateTime.jsDateToISODate(filter.dueDateEndDate),
        createdByIds: filter.createdByIds,
        patientName: filter.patientSearch
      });

      clinicalActivities.results = clinicalActivities.results.filter(
        x => x.activityType === ClinicalActivityType.Task
      );

      if (filter.dueIncrement) {
        clinicalActivities.results = clinicalActivities.results.filter(
          x =>
            x.dueInVisits &&
            x.dueInVisits > Number.parseInt(filter.dueIncrement!)
        );
      }

      return clinicalActivities;
    },
    [filter, clinical]
  );

  return (
    <ScrollablePane
      styles={{
        root: {
          height: "100%",
          padding: 8,
          position: "relative"
        }
      }}
    >
      <ClinicalActivityTable
        showPriority
        showActions
        showPatientName
        onSelectionChanged={setSelected}
      >
        {({ selection, columns, renderRow, renderDetailsHeader }) => (
          <InfiniteScrollList
            setKey="clinical-activities-list"
            selectionMode={SelectionMode.multiple}
            layoutMode={DetailsListLayoutMode.justified}
            stickyHeader
            getItems={getItems}
            columns={columns}
            onRenderRow={renderRow}
            onRenderDetailsHeader={renderDetailsHeader}
            selection={selection}
            selectionPreservedOnEmptyClick={true}
            refreshKey={clinical.lastUpdatedClinicalActivities}
            onRenderNoResults={() => (
              <NoDataTile
                textProps={{ text: "No information to display" }}
                linkProps={{ hidden: true }}
                showBoxShadow={false}
              />
            )}
          />
        )}
      </ClinicalActivityTable>
    </ScrollablePane>
  );
};

export const ActivitiesClinicalList: React.FC<
  ActivitiesClinicalProps
> = props => (
  <Tile
    styles={{
      root: {
        flexGrow: 1,
        height: "100%",
        position: "relative",
        pending: 8
      }
    }}
  >
    <Stack
      styles={{
        root: { height: "100%", overflowX: "auto" }
      }}
    >
      <ClinicalActivityManagementFilter>
        <BaseActivitiesClinicalList {...props} />
      </ClinicalActivityManagementFilter>
    </Stack>
  </Tile>
);
