import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

import {
  IDropdownOption,
  IPersonaProps,
  ITextFieldStyles,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  StackItem,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  CorrespondenceType,
  EpisodeOfCareDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClaimPickerField } from "@modules/acc/screens/shared-components/claim-picker/ClaimPickerField.tsx";
import { useTemplatePickerFormContext } from "@modules/clinical/screens/correspondence/components/context/TemplatePickerFormContext.tsx";
import { TemplateName } from "@modules/clinical/screens/correspondence/components/context/TemplatePickerFormModel.ts";
import {
  DocumentWriterMergeFormFormContext,
  useDocumentWriterMergeFormContext
} from "@modules/clinical/screens/document-writer/components/merge-fields/context/DocumentWriterMergeFormContext.tsx";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { DocumentWriterDefaultFormValidator } from "../DocumentWriterDefaultFormValidator.ts";
import { DocumentWriterReportFormValidator } from "../DocumentWriterReportFormValidator.ts";
import { getCheckedItems, getOptions } from "../utils.ts";
import { VisibilityAndConfidentialityField } from "../VisibilityAndConfidentialityField.tsx";
import { DocumentWriterMergeFormHelper } from "./context/DocumentWriterMergeFormHelper.ts";
import { DocumentWriterMergeFieldsDischargeFormValidator } from "./DocumentWriterMergeFieldsDischargeFormValidator.ts";
import { DocumentWriterMergeFieldsLetterFormValidator } from "./DocumentWriterMergeFieldsLetterFormValidator.ts";
import { DocumentWriterMergeFieldsMedicalFormValidator } from "./DocumentWriterMergeFieldsMedicalFormValidator.ts";
import {
  DocumentMergeFieldsFormValues,
  DocumentWriterMergeFormDialogProps,
  MessageBarLabel
} from "./DocumentWriterMergeFormDialog.types.ts";

const getTextFromItem = (persona: IPersonaProps) => persona.text as string;

const medicalValidator = new DocumentWriterMergeFieldsMedicalFormValidator();
const dischargeValidator =
  new DocumentWriterMergeFieldsDischargeFormValidator();

const reportValidator = new DocumentWriterReportFormValidator();
const defaultValidator = new DocumentWriterDefaultFormValidator();
const letterValidator = new DocumentWriterMergeFieldsLetterFormValidator();

const nameOf = nameOfFactory<DocumentMergeFieldsFormValues>();

const DocumentWriterMergeFormDialogBase: React.FC<DocumentWriterMergeFormDialogProps> =
  observer(
    ({
      onCancel,
      onSubmit,
      activeClaimId,
      launchFrom,
      patientId,
      documentSettings
    }) => {
      const { acc, correspondence, clinical, core } = useStores();
      const templatePickerModel = useTemplatePickerFormContext();
      const template =
        templatePickerModel.selectedTemplate?.documentType ??
        templatePickerModel.documentType;

      const model = useDocumentWriterMergeFormContext();
      const [showMessageBar, setShowMessageBar] = useState<boolean>(false);
      const renderPracticeLetterheadCheck = () => {
        return (
          <CheckboxField
            name={nameOf("usePracticeLetterhead")}
            label="Use the practice letterhead"
            disabled={!!documentSettings?.usePracticeLetterhead}
          />
        );
      };

      const renderAddToTimelineCheckAndConfidentialToggle = () => {
        return (
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={{
              root: {
                marginTop: "8px",
                alignItems: "center"
              }
            }}
          >
            <VisibilityAndConfidentialityField name={nameOf("visibility")} />
          </Stack>
        );
      };

      const renderPatient = () =>
        launchFrom !== LaunchFrom.Clinical && (
          <Stack.Item
            grow
            styles={{ root: { flexBasis: 0, paddingBottom: "8px" } }}
          >
            <ContactPickerField
              name={nameOf("patientId")}
              label={patientLabel}
              required
              getTextFromItem={getTextFromItem}
              iconName="Search"
              filter={{ types: [ContactType.Patient] }}
              placeholder={`Search ${patientLabel.toLowerCase()}`}
            />
          </Stack.Item>
        );

      const renderMedical = () => {
        return (
          <>
            {renderPatient()}
            <Fieldset horizontal>
              <DatePickerField
                label="From"
                name="fromDate"
                required
                validateOnInitialize
                styles={
                  {
                    root: {
                      minWidth: 272
                    },
                    fieldGroup: { width: "100%" },
                    field: { width: "100%" }
                  } as Partial<ITextFieldStyles>
                }
              />
              <DatePickerField
                label="To"
                name="toDate"
                required
                validateOnInitialize
                styles={
                  {
                    root: {
                      minWidth: 272
                    },
                    fieldGroup: { width: "100%" },
                    field: { width: "100%" }
                  } as Partial<ITextFieldStyles>
                }
              />
            </Fieldset>
            <Fieldset>
              {renderAddToTimelineCheckAndConfidentialToggle()}
              {renderPracticeLetterheadCheck()}
            </Fieldset>
          </>
        );
      };

      const getMappedDischarge = async (clinicalData: EpisodeOfCareDto) => {
        return {
          episode: clinicalData,
          dataItems: (
            await clinical.getEpisodeOfCareScopedClinicalData({
              patientId: clinicalData.patientId,
              episodeOfCareId: clinicalData.id
            })
          ).discharge?.dataItems
        };
      };

      const onRenderDischargeItem = (name: string) => {
        if (name?.includes("::")) {
          const splitText = name.split("::");
          if (splitText) {
            const prefix = splitText[0];
            const role = splitText[1];

            return (
              <>
                <Text
                  nowrap
                  styles={{ root: { paddingRight: "4px", maxWidth: "50%" } }}
                >
                  {prefix}
                </Text>
                <Text nowrap>| {role}</Text>
              </>
            );
          }
        }

        return undefined;
      };

      const renderDischarge = (
        formApi: FormApi,
        formRenderValues: DocumentMergeFieldsFormValues
      ) => {
        //This should be called for only 'Outcome measure' and 'discharge letter' options.
        const getConditions = async () => {
          const patientIdForConditions =
            patientId ?? formRenderValues.patientId;
          if (!patientIdForConditions) {
            return [];
          }

          const episodesOfCare = await clinical.getPatientEpisodesOfCare(
            patientIdForConditions
          );

          const filteredEpisodesOfCare = episodesOfCare.filter(
            eoc => eoc.discharged
          );

          const eocAndRoles: {
            episodeOfCare: EpisodeOfCareDto;
            role: string | undefined;
          }[] = [];

          const data = await Promise.all(
            filteredEpisodesOfCare.map(clinicalData =>
              getMappedDischarge(clinicalData)
            )
          );

          data.forEach(mapItem => {
            mapItem.dataItems?.forEach(discharge => {
              if (
                discharge.dischargeStatus === DischargeStatus.Completed &&
                !eocAndRoles.find(x => x === discharge)
              ) {
                eocAndRoles.push({
                  episodeOfCare: mapItem.episode,
                  role: discharge.businessRoleCode
                });
              }
            });
          });

          if (
            templatePickerModel.documentType === CorrespondenceType.Report &&
            activeClaimId
          ) {
            await model.getOutcomeMeasuresReportData(activeClaimId);
          }
          return eocAndRoles;
        };
        return (
          <>
            <Fieldset
              horizontal
              styles={{
                root: {
                  selectors: {
                    ".bp-FormItem": {
                      flex: 1
                    }
                  }
                }
              }}
            >
              <ContactPickerField
                name={nameOf("toContactId")}
                label="Recipient"
                required
                getTextFromItem={getTextFromItem}
                iconName="Search"
                placeholder="Search contact"
                styles={{
                  root: {
                    minWidth: 272
                  }
                }}
              />
              <DataFetcher fetch={getConditions} fallback={<Spinner />}>
                {eocAndRolesMap => (
                  <DropdownField
                    name={nameOf("episodeOfCareId")}
                    label="Discharged Conditions"
                    styles={{
                      root: {
                        width: 272
                      }
                    }}
                    options={templatePickerModel.getDischargeOptions(
                      eocAndRolesMap
                    )}
                    required
                    onRenderOption={(x, defaultRender) => {
                      if (x) {
                        const formattedText = onRenderDischargeItem(x!.text);
                        if (formattedText) {
                          return formattedText;
                        }
                      }

                      return defaultRender ? defaultRender(x) : null;
                    }}
                    onRenderTitle={(x, defaultRender) => {
                      if (x && x[0]) {
                        const text = x[0].text;

                        const formattedText = onRenderDischargeItem(text);
                        if (formattedText) {
                          return formattedText;
                        }
                      }
                      return defaultRender ? defaultRender(x) : null;
                    }}
                  />
                )}
              </DataFetcher>
              <FieldSpy
                name={nameOf("episodeOfCareId")}
                onChange={async value => {
                  //sort the selected items
                  if (value) {
                    const claimEpisodeOfCare = await acc.getClaimEpisodesOfCare(
                      {
                        episodesOfCare: [value]
                      }
                    );

                    formApi.change("claimId", claimEpisodeOfCare[0].claimId);
                  }
                }}
              />
            </Fieldset>
            <Fieldset>
              {renderAddToTimelineCheckAndConfidentialToggle()}
              {renderPracticeLetterheadCheck()}
            </Fieldset>
          </>
        );
      };

      const patientLabel = usePatientLabel(true);
      const renderLetter = () => {
        return (
          <Stack tokens={{ childrenGap: 8 }}>
            <Fieldset
              horizontal
              styles={{
                root: {
                  selectors: {
                    ".bp-FormItem": {
                      flex: 1
                    }
                  }
                }
              }}
            >
              {renderPatient()}
              <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
                <ContactPickerField
                  name={nameOf("toContactId")}
                  label="Recipient"
                  required
                  getTextFromItem={getTextFromItem}
                  iconName="Search"
                  placeholder="Search contact"
                />
              </Stack.Item>
            </Fieldset>

            {renderAddToTimelineCheckAndConfidentialToggle()}
            {renderPracticeLetterheadCheck()}
          </Stack>
        );
      };

      const renderReport = (
        formApi: FormApi,
        formRenderValues: DocumentMergeFieldsFormValues
      ) => {
        //This should be called for only 'Outcome measure' options.
        return (
          <Stack tokens={{ childrenGap: 8 }}>
            <Fieldset
              horizontal
              styles={{
                root: {
                  selectors: {
                    ".bp-FormItem": {
                      flex: 1
                    }
                  }
                }
              }}
            >
              {renderPatient()}

              <StackItem grow styles={{ root: { flexBasis: 0 } }}>
                <ClaimPickerField
                  name={nameOf("claimId")}
                  patientId={formRenderValues.patientId}
                  label="Claim"
                  required
                  showBusinessRole={true}
                  displayDiagnosis={true}
                />
              </StackItem>
            </Fieldset>

            <Fieldset
              horizontal
              styles={{
                root: {
                  overflow: "hidden"
                }
              }}
            >
              <DropdownField
                name={nameOf("visits")}
                multiSelect
                required
                styles={{
                  root: {
                    width: 272
                  }
                }}
                label="Visits (up to 4)"
                options={getOptions(model.visitOptions)}
                onRenderTitle={(options: IDropdownOption[]) => {
                  const sortedSelectedVisits = options.sort(
                    (visitA, visitB) => {
                      const visitOptions: IDropdownOption[] = getOptions(
                        model.visitOptions
                      );
                      return (
                        visitOptions.findIndex(
                          option => option.key === visitA.key
                        ) -
                        visitOptions.findIndex(
                          option => option.key === visitB.key
                        )
                      );
                    }
                  );

                  return (
                    <span>
                      {sortedSelectedVisits
                        .map(x => x.text.split(" -")[0])
                        .join(", ")}
                    </span>
                  );
                }}
              />
              <FieldSpy
                name={nameOf("visits")}
                onChange={async values => {
                  if (values && values.length > 0) {
                    model.getUpdatedClinicalToolOptions(values);
                    setShowMessageBar(false);
                  }
                  if (
                    values &&
                    values.length === 0 &&
                    formRenderValues.claimId
                  ) {
                    setShowMessageBar(true);
                  }
                }}
              />
              <DropdownField
                name={nameOf("clinicalTools")}
                multiSelect
                styles={{
                  root: {
                    width: 272
                  }
                }}
                label="Outcomes (up to 4)"
                options={model.clinicalToolOptions}
              />

              <FieldSpy
                name={nameOf("claimId")}
                onChange={async value => {
                  if (value && value !== "") {
                    await model.getOutcomeMeasuresReportData(
                      value,
                      formRenderValues.patientId
                    );

                    const selectedVisits = getCheckedItems(
                      model.visitOptions,
                      model.userSettingsVisits
                    );
                    formApi.change("visits", selectedVisits);
                    setShowMessageBar(false);
                  } else {
                    formApi.change("visits", []);
                    setShowMessageBar(true);
                  }
                }}
              />
            </Fieldset>

            {renderAddToTimelineCheckAndConfidentialToggle()}
            {renderPracticeLetterheadCheck()}
            {showMessageBar && (
              <MessageBar messageBarType={MessageBarType.error}>
                {MessageBarLabel.noVisitsFound}
              </MessageBar>
            )}
          </Stack>
        );
      };

      const renderDefault = () => {
        return (
          <>
            <Fieldset
              horizontal
              styles={{
                root: {
                  overflow: "hidden",
                  selectors: {
                    ".bp-FormItem": {
                      flex: 1
                    }
                  }
                }
              }}
            >
              <DropdownField
                name={nameOf("selectedTemplate")}
                placeholder="Select Document Type"
                label="Document Type"
                required
                styles={{ root: { flexGrow: 1 } }}
                options={correspondence.ref.correspondenceTypes.keyTextValues}
              />
            </Fieldset>
            <Fieldset>
              {renderAddToTimelineCheckAndConfidentialToggle()}
              {renderPracticeLetterheadCheck()}
            </Fieldset>
          </>
        );
      };

      const renderCustom = () => {
        return (
          <>
            {renderPatient()}
            <Fieldset horizontal>
              <ContactPickerField
                name={nameOf("toContactId")}
                label="Recipient"
                iconName="Search"
                placeholder="Search contacts"
                getTextFromItem={getTextFromItem}
                styles={{
                  root: {
                    minWidth: 272
                  }
                }}
              />
              <Stack grow styles={{ root: { flexBasis: 0 } }}>
                <BookableUserPickerField
                  name={nameOf("providerId")}
                  currentUserOnTop
                  label="Provider"
                  filter={{
                    statusCodes: [UserStatus.Active]
                  }}
                  inputProps={{
                    placeholder: "Select provider",
                    name: "provider-filter-box"
                  }}
                  required
                  iconName="Search"
                  useBusinessRoleClass
                />
              </Stack>
            </Fieldset>
            <Fieldset>
              {renderAddToTimelineCheckAndConfidentialToggle()}
              {renderPracticeLetterheadCheck()}
            </Fieldset>
          </>
        );
      };

      const defaultDates = template === CorrespondenceType.MedicalCertificate;

      const initialValues = useRef<DocumentMergeFieldsFormValues>({
        toContactId: undefined,
        toDate: defaultDates ? DateTime.tomorrow().toJSDate() : undefined,
        fromDate: defaultDates ? DateTime.today().toJSDate() : undefined,
        selectedTemplate: template,
        usePracticeLetterhead: !!documentSettings?.usePracticeLetterhead,
        patientId,
        claimId: undefined,
        providerId: core.userId
      });

      const validate = (values: DocumentMergeFieldsFormValues) => {
        if (templatePickerModel.isCustomTemplate) {
          return defaultValidator.validate(values);
        }
        switch (template) {
          case CorrespondenceType.MedicalCertificate:
            return medicalValidator.validate(values);
          case CorrespondenceType.Referral:
          case CorrespondenceType.Letter:
            return letterValidator.validate(values);
          case CorrespondenceType.Report:
            return reportValidator.validate(values);
          case CorrespondenceType.DischargeLetter:
            return dischargeValidator.validate(values);
          default:
            return defaultValidator.validate(values);
        }
      };

      const getModalTitle = (): string => {
        let title = `New ${
          templatePickerModel.selectedTemplate?.name.toLocaleLowerCase() ??
          TemplateName.Blank
        }`;
        if (
          templatePickerModel.selectedTemplate?.name.toLocaleLowerCase() ===
          "outcome measure"
        ) {
          title = "New outcome measure report";
        }
        return title;
      };
      return (
        <SubmissionFormDialog<DocumentMergeFieldsFormValues>
          dialogName="Document write merge dialog"
          initialValues={initialValues.current}
          onSubmit={onSubmit}
          styles={{
            root: { overflowX: "hidden" }
          }}
          autoFocus={false}
          dialogProps={{
            minWidth: 600,
            onDismiss: onCancel,
            dialogContentProps: {
              styles: {
                inner: {
                  overflowX: "hidden",
                  minHeight: 238
                }
              },
              title: getModalTitle()
            }
          }}
          buttonsProps={{
            submitButtonProps: {
              text: "Generate"
            },
            promptOnCancel: true,
            onCancel,
            hideButtonsSeparator: true,
            disableSubmitOnFormInvalid: true,
            disableSubmitOnPristine: false
          }}
          validate={validate}
        >
          {formRenderProps => {
            if (templatePickerModel.isCustomTemplate) {
              return renderCustom();
            }
            switch (template) {
              case CorrespondenceType.MedicalCertificate:
                return renderMedical();
              case CorrespondenceType.Referral:
              case CorrespondenceType.Letter:
                return renderLetter();
              case CorrespondenceType.Report:
                return renderReport(
                  formRenderProps.form,
                  formRenderProps.values
                );
              case CorrespondenceType.DischargeLetter:
                return renderDischarge(
                  formRenderProps.form,
                  formRenderProps.values
                );
              default:
                return renderDefault();
            }
          }}
        </SubmissionFormDialog>
      );
    }
  );

export const DocumentWriterMergeFormDialog: React.FC<
  DocumentWriterMergeFormDialogProps
> = props => {
  const { acc, clinical, core, userExperience } = useStores();
  const model = new DocumentWriterMergeFormHelper(
    clinical,
    acc,
    core,
    userExperience
  );

  return (
    <DocumentWriterMergeFormFormContext.Provider value={model}>
      <DocumentWriterMergeFormDialogBase {...props} />
    </DocumentWriterMergeFormFormContext.Provider>
  );
};
