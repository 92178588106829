import { DATE_FORMATS } from "@bps/utils";
import { RefData } from "@libs/api/ref-data/RefData.ts";
import { Country } from "@libs/enums/country.enum.ts";
import {
  ContactStatus,
  Gender,
  Sex
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  isDateBeforeField,
  isNotFutureDate,
  maxLength,
  predicate,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import {
  ValidationConstants,
  ValidationMessages
} from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { RefCountry } from "@stores/core/models/Address.ts";

import { AddressValidator } from "../../../../shared-components/validation/AddressValidator.ts";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "../PatientEditFormValues.tsx";
import { AccountHolderValidator } from "./AccountHolderValidator.ts";
import { CommunicationValidator } from "./CommunicationValidator.ts";
import { CscValidator } from "./CscValidator.ts";
import { DvaValidator } from "./DvaValidator.ts";
import { EmployerValidator } from "./EmployerValidator.ts";
import { HealthInsuranceValidator } from "./HealthInsuranceValidator.ts";
import { MedicareNumberValidator } from "./MedicareNumberValidator.ts";

const emptyMedicare = ({ medicare }: PatientEditFormValues) => {
  return (
    !medicare ||
    !(
      medicare.number ||
      medicare.irnNumber ||
      medicare.expiryMonth ||
      medicare.expiryYear
    )
  );
};

export const nhiMsg = (nhi: string | undefined) => {
  const errorMessages: string[] = [];
  if (nhi) {
    const upperCaseNhi = nhi.toUpperCase();

    if (upperCaseNhi.length > 7)
      errorMessages.push(ValidationMessages.nhiLength);

    if (new RegExp("[IO]", "i").test(upperCaseNhi.substr(0)))
      errorMessages.push(ValidationMessages.nhiIODisallowed);

    if (
      !new RegExp("[A-Z][A-Z][A-Z][0-9][0-9][A-Z0-9 ][A-Z0-9 ]", "i").test(
        upperCaseNhi
      )
    )
      errorMessages.push(ValidationMessages.nhiFormat);

    if (errorMessages.length === 0 && !validateNhi(upperCaseNhi))
      errorMessages.push(ValidationMessages.nhiInvalid);
  }
  return errorMessages.join(", ") || "";
};

const validateNhi = (nhi: string) => {
  let passTest = false;
  let checkValue = 0;

  for (let i = 0; i < 3; i++) {
    const letterNumeric =
      ValidationConstants.alphaTableForNzMOH.indexOf(nhi[i]) + 1;
    checkValue += letterNumeric * (7 - i);
  }

  if (new RegExp("[A-Z]{2}", "i").test(nhi.substr(5, 2))) {
    //new NHI format
    for (let i = 3; i < 5; i++) {
      checkValue += parseInt(nhi[i]) * (7 - i);
    }
    const letterNumeric =
      ValidationConstants.alphaTableForNzMOH.indexOf(nhi[5]) + 1;
    checkValue += letterNumeric * (7 - 5);

    const checkSum = checkValue % 24;

    // Verify the checkdigit but only if checksum is not 0
    if (checkSum !== 0) {
      const letterNumeric2 =
        ValidationConstants.alphaTableForNzMOH.indexOf(nhi[6]) + 1;
      if (letterNumeric2 === 24 - checkSum) {
        passTest = true;
      }
    }
  } else {
    // old NHI format
    for (let i = 3; i < 6; i++) {
      checkValue += parseInt(nhi[i]) * (7 - i);
    }

    const checkSum = checkValue % 11;

    // Verify the checkdigit but only if checksum is not 0
    if (checkSum !== 0) {
      if (parseInt(nhi[6]) === (11 - checkSum) % 10) {
        passTest = true;
      }
    }
  }
  return passTest;
};

export class PatientFormValidator extends Validator<PatientEditFormValues> {
  constructor(options: {
    countries: readonly RefCountry[];
    languages: readonly RefData[];
    oldCscExpiry: Date | undefined;
    country: Country;
  }) {
    super();
    const { countries, languages, oldCscExpiry, country } = options;

    const medicareNumberValidator = new MedicareNumberValidator();
    const addressValidator = new AddressValidator({ countries, country });
    const commValidator = new CommunicationValidator(country);
    const employerValidator = new EmployerValidator();
    const accountHoldersValidator = new AccountHolderValidator();

    const isLanguage = (): FieldValidator => value => {
      if (value)
        return !languages.find(l => l.code === value) ? "Invalid." : undefined;
      return;
    };

    this.forField(
      "interpreterLanguage",
      predicate(
        (val: string, values: PatientEditFormValues) =>
          !!values.interpreterNeeded,
        isLanguage(),
        required()
      )
    );

    this.forField(
      "dateOfDeath",
      predicate(
        (val, values: PatientEditFormValues) =>
          values.status === ContactStatus.Deceased,
        required(),
        validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
        isNotFutureDate(),
        isDateBeforeField(
          ValidationMessages.afterBirthdate,
          patientFormNameOf("birthDate"),
          true
        )
      )
    );

    this.forField("birthDate", [
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isNotFutureDate()
    ]);

    this.forField("medicare", [
      predicate(
        (val, values: PatientEditFormValues) => !emptyMedicare(values),
        medicareNumberValidator.validate
      )
    ]);

    this.forField("medicare", [
      predicate(
        (val, values: PatientEditFormValues) => !emptyMedicare(values),
        medicareNumberValidator.validate
      )
    ]);

    this.forField("pronounObjective", [
      predicate(
        (val, values: PatientEditFormValues) => !birthGenderMatch(values),
        required()
      )
    ]);

    this.forField("pronounSubjective", [
      predicate(
        (val, values: PatientEditFormValues) => !birthGenderMatch(values),
        required()
      )
    ]);

    this.forField("pronounPossessive", [
      predicate(
        (val, values: PatientEditFormValues) => !birthGenderMatch(values),
        required()
      )
    ]);

    const birthGenderMatch = (values?: PatientEditFormValues) => {
      return !(
        values &&
        values.sex &&
        values.gender &&
        ((values?.sex === Sex.Female && values?.gender !== Gender.Female) ||
          (values?.sex === Sex.Male && values?.gender !== Gender.Male))
      );
    };

    const healthInsuranceValidator = new HealthInsuranceValidator();
    const emptyHealthInsurance = (values?: PatientEditFormValues) => {
      return !(
        values &&
        values.healthInsurance &&
        (values.healthInsurance.number || values.healthInsurance.fund)
      );
    };

    this.forField("healthInsurance", [
      predicate(
        (val, healthFund?: PatientEditFormValues) =>
          !emptyHealthInsurance(healthFund),
        healthInsuranceValidator.validate
      )
    ]);

    const dvaValidator = new DvaValidator();
    const emptyDva = (values?: PatientEditFormValues) => {
      return !(
        values &&
        values.dva &&
        (values.dva.number || values.dva.cardColor)
      );
    };

    this.forField("dva", [
      predicate(
        (val, dva?: PatientEditFormValues) => !emptyDva(dva),
        dvaValidator.validate
      )
    ]);

    const cscValidator = new CscValidator(oldCscExpiry);
    const emptyCsc = (values?: PatientEditFormValues) => {
      return !(
        values &&
        values.csc &&
        (values.csc.number || values.csc.startDate || values.csc.expiry)
      );
    };

    this.forField("csc", [
      predicate(
        (val, csc?: PatientEditFormValues) => !emptyCsc(csc),
        cscValidator.validate
      )
    ]);

    this.forField("firstName", [required(), maxLength(40)]);
    this.forField("lastName", [required(), maxLength(40)]);
    this.forField("middleName", [maxLength(40)]);
    this.forField("nickName", [maxLength(40)]);
    this.forField("pronounObjective", [maxLength(30)]);
    this.forField("pronounSubjective", [maxLength(30)]);
    this.forField("pronounPossessive", [maxLength(30)]);
    this.forArrayField("communications", commValidator.validate);
    this.forArrayField("addresses", addressValidator.validate);
    this.forArrayField("employers", employerValidator.validate);
    this.forArrayField("accountHolders", accountHoldersValidator.validate);

    this.forField("nhi", [(value: string | undefined) => nhiMsg(value)]);
  }
}
