import {
  isDateBeforeField,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ScheduleFormValues } from "../ScheduleForm.types.tsx";
import { nameOf } from "../ScheduleFormFields.tsx";

export class ScheduleFormValidator extends Validator<ScheduleFormValues> {
  constructor() {
    super();
    this.forField(nameOf("scheduleName"), [required(), maxLength(300)]);
    this.forField(nameOf("scheduleStartDate"), required());
    this.forField(
      nameOf("scheduleEndDate"),
      predicate(
        (value, values) => !!values && !values.scheduleIsOngoing && !value,
        required()
      )
    );
    this.forField(
      nameOf("scheduleEndDate"),
      isDateBeforeField(
        ValidationMessages.endDateBeforeStartDate,
        nameOf("scheduleStartDate")
      )
    );
  }
}
