import { mergeStyles } from "@bps/fluent-ui";

import type {
  CalendarStyleProps,
  CalendarStyles
} from "../../calendar/Calendar.types.tsx";
import { AppointmentFilterStatus } from "../appointments-filter/AppointmentFilter.types.ts";

export const unavailableOrgClassName = mergeStyles("org-unavailable", {
  border: "1px solid #9C9B9B",
  backgroundColor: "#9C9B9B",
  borderBottom: "1px dotted rgba(0, 0, 0, 0.1)"
});

export const unavailableResourceClassname = mergeStyles(
  "resource-unavailable",
  {
    borderStyle: "1px solid #CFCECE",
    backgroundColor: "#CFCECE",
    borderBottom: "1px dotted rgba(0, 0, 0, 0.1)"
  }
);

export const availableResourceClassname = mergeStyles("resource-available", {
  backgroundColor: "white",
  borderBottom: "1px dotted rgba(0, 0, 0, 0.1)"
});

export const getCalendarStyles =
  (filter: AppointmentFilterStatus | undefined) =>
  (props: CalendarStyleProps): Partial<CalendarStyles> => {
    const { theme } = props;
    return {
      bigCalendar: {
        selectors: {
          // overlay scrollbar if filter overlay is active
          "& .rbc-time-content": {
            overflowY: filter ? "overlay" : "auto"
          },
          "& .rbc-time-header-gutter": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          },
          "& .rbc-time-gutter .rbc-timeslot-group": {
            position: "relative"
          },
          // extra overlay effect for time slots in a case of events filtering
          "& .rbc-time-gutter .rbc-timeslot-group::after": filter
            ? {
                position: "absolute",
                content: "''",
                width: "100%",
                height: "100%",
                background: theme.palette.blackTranslucent40
              }
            : undefined
        }
      }
    };
  };
