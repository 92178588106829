import { FilterBarProps } from "@bps/fluent-ui";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

import { LocationStatus } from "./LocationDetails.types.ts";

type LocationsListFilterProps = Pick<
  FilterBarProps<LocationsListFilterState>,
  "children"
>;

export interface LocationsListFilterState {
  statusCodes?: string[];
  locationsSearch?: string;
}

export const LocationsListFilter: React.FC<LocationsListFilterProps> = ({
  children
}) => {
  return (
    <FilterBar<LocationsListFilterState>
      items={[
        {
          type: "searchBox",
          name: "locationsSearch",
          stickItem: true,
          props: {
            id: "locations-filter-search",
            placeholder: "Search for a location",
            styles: { root: { maxWidth: 250, minWidth: 250 } }
          }
        },
        {
          type: "optionsSelect",
          name: "statusCodes",
          props: {
            id: "locations-filter-status",
            multiSelect: true,
            hideSearchOption: true,
            options: [
              {
                key: LocationStatus.Active,
                text: LocationStatus.Active
              },
              {
                key: LocationStatus.Inactive,
                text: LocationStatus.Inactive
              }
            ],
            placeholder: "Status",
            calloutWidth: 200
          }
        }
      ]}
      initialValues={{ statusCodes: [LocationStatus.Active] }}
    >
      {children}
    </FilterBar>
  );
};
