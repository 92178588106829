import {
  greaterThan,
  lessThan,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { OnlineSettingsValues } from "./OnlineSettingsValues.types.ts";

export class OnlineSettingsFormValidator extends Validator<OnlineSettingsValues> {
  constructor() {
    super();
    this.forField(
      "newPatientMinuteDuration",
      [greaterThan(0), lessThan(1440)],
      {
        when: value => !!value || value === 0
      }
    );
    this.forField("name", [maxLength(30)]);
    this.forField("description", [maxLength(255)]);
  }

  checkIfNumberStringIsLessThan =
    (limit: number, replaceSuffix: string): FieldValidator<string> =>
    value => {
      if (value) {
        const intValue = value.replace(replaceSuffix, "");
        if (parseInt(intValue) > limit)
          return messageWithData(ValidationMessages.lessThan, limit);
      }
      return undefined;
    };
}
