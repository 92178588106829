import { createContext, useContext } from "react";

import { IntegrationScreenHelper } from "./IntegrationScreenHelper.ts";

export const IntegrationScreenContext = createContext(
  {} as IntegrationScreenHelper
);

export const useIntegrationScreenContext = () =>
  useContext(IntegrationScreenContext);
