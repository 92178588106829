import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  TooltipHost
} from "@bps/fluent-ui";
import {
  CorrespondencesTestElements,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { isPreviewAndPrintSupported } from "@libs/utils/file.utils.ts";
import { CorrespondencesLabels } from "@modules/clinical/screens/patient-record/components/correspondence/Correspondences.types.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InvestigationSidePanelContext } from "./InvestigationsSidePanelContext.ts";

export interface ReportListItemContextMenuItemsProps {
  record: ClinicalDocument;
}

const createItem = (
  name: string,
  onClick: () => void,
  filterOption?: boolean
): IContextualMenuItem => {
  return {
    key: name,
    name,
    data: { filter: filterOption },
    onClick
  };
};

export const ReportListItemContextMenuItems: FunctionComponent<ReportListItemContextMenuItemsProps> =
  observer(({ record }) => {
    const {
      inbox,
      onOutcomeClick,
      onOpenInvestigationClick,
      onPrintClick,
      onMoveToAnotherPatient,
      onMoveTo,
      deleteReport,
      setSelectedInvestigation,
      setAddEditReportsDialogVisible,
      updateDocumentSecGroupId
    } = useContext(InvestigationSidePanelContext);

    const { core } = useStores();
    const isConfidentOfOtherUser = !core.hasAccessToSecGroup(record.secGroupId);
    const _isPreviewAndPrintSupported =
      record.extension && isPreviewAndPrintSupported(record.extension);

    const items: IContextualMenuItem[] = [
      {
        key: "outcome",
        disabled: false,
        name: CorrespondencesLabels.ContextMenuOutcome,
        subMenuProps: {
          items: inbox.ref.outcomes.keyNameValues.map(outcome => {
            const item: IContextualMenuItem = {
              ...outcome,
              onClick(_, item?) {
                if (item) onOutcomeClick(record, item.key);
              }
            };
            return item;
          })
        }
      },
      createItem(
        CorrespondencesLabels.ContextMenuOpenDocument,
        () => onOpenInvestigationClick(record),
        !_isPreviewAndPrintSupported
      ),
      createItem(
        CorrespondencesLabels.ContextMenuPrint,
        () => onPrintClick(record),
        !_isPreviewAndPrintSupported
      ),
      {
        key: "moveTo",
        name: CorrespondencesLabels.ContextMenuMoveTo,
        subMenuProps: {
          items: [
            createItem(CorrespondencesLabels.ContextMenuAnotherPatient, () =>
              onMoveToAnotherPatient(record)
            ),
            createItem(CorrespondencesLabels.ContextMenuCorrespondence, () =>
              onMoveTo(record, StoreType.Correspondence)
            ),
            createItem(CorrespondencesLabels.ContextMenuClinicalImages, () =>
              onMoveTo(record, StoreType.ClinicalImages)
            )
          ]
        }
      },
      createItem(CorrespondencesLabels.ContextMenuDelete, () => {
        deleteReport(record);
      }),
      createItem(CorrespondencesLabels.ContextMenuEdit, () => {
        setSelectedInvestigation(record);
        setAddEditReportsDialogVisible(true);
      }),
      {
        key: "editConfidential",
        name: record.secGroupId
          ? CorrespondencesLabels.RemoveConfidentiality
          : CorrespondencesLabels.MakeConfidential,
        data: { permission: [Permission.ConfidentialDataAllowed] },
        onClick: () => {
          updateDocumentSecGroupId(record);
        }
      }
    ].filter(x => {
      if (x.data) {
        if (
          (x.data.permission && !core.hasPermissions(x.data.permission)) ||
          x.data.filter
        ) {
          return false;
        }
      }
      return true;
    });

    return (
      <TooltipHost content={isConfidentOfOtherUser ? "Confidential" : ""}>
        <IconButton
          {...dataAttribute(
            DataAttributes.Element,
            CorrespondencesTestElements.MoreButton
          )}
          menuIconProps={{
            iconName: isConfidentOfOtherUser ? "ShieldAlert" : "More"
          }}
          menuProps={{
            items
          }}
          styles={{
            root: { height: "36px" },
            flexContainer: { height: "36px" },
            rootDisabled: {
              backgroundColor: "transparent"
            }
          }}
          disabled={isConfidentOfOtherUser}
        />
      </TooltipHost>
    );
  });
