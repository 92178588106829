import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { routes } from "@libs/routing/routes.ts";
import { AllocationViewContext } from "@modules/billing/screens/allocation/context/AllocationViewContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { fullScreen } from "../shared-components/billing.styles.ts";
import { BillingLayout } from "../shared-components/BillingLayout.tsx";
import { TransactionAdjustedBar } from "../shared-components/TransactionAdjustedBar.tsx";
import { AllocationView } from "./components/AllocationView.tsx";
import { AllocationViewDataFetcher } from "./components/AllocationViewDataFetcher.tsx";
import { AllocationViewHeader } from "./components/AllocationViewHeader.tsx";

export const AllocationViewBase = observer(() => {
  const { allocation } = useContext(AllocationViewContext);

  const messageBar = allocation.isCancelled && (
    <TransactionAdjustedBar transaction={allocation} name="allocation" />
  );

  return (
    <div style={fullScreen}>
      <BillingLayout header={<AllocationViewHeader />} messageBar={messageBar}>
        <AllocationView hideInfoBar={true} />
      </BillingLayout>
    </div>
  );
});

export const AllocationViewScreen: React.FC = observer(() => {
  const { routing } = useStores();

  const allocationId = routing.match(routes.accounts.allocations.allocation)
    ?.params.id;

  if (!allocationId) {
    return null;
  }

  return (
    <AllocationViewDataFetcher allocationId={allocationId}>
      <AllocationViewBase />
    </AllocationViewDataFetcher>
  );
});
