import React from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Dialog,
  Heading,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { MeasurementDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

interface DASHScoreModalProps {
  measurements: MeasurementDto[];
  onClose: () => void;
}

export const DASHScoreModal: React.FunctionComponent<DASHScoreModalProps> = ({
  measurements,
  onClose
}) => {
  const sortedMeasurements = [...measurements].sort((a, b) => {
    if (a.summary! < b.summary!) return -1;
    if (a.summary! > b.summary!) return 1;
    return 0;
  });

  return (
    <Dialog
      minWidth={600}
      hidden={false}
      onDismiss={() => {
        onClose();
      }}
      dialogContentProps={{
        showCloseButton: true,
        title: <Heading variant="modal-heading">DASH11</Heading>
      }}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        {sortedMeasurements.map(measurement => (
          <Stack
            key={measurement.id}
            tokens={{ childrenGap: 16 }}
            styles={(props, theme) => {
              const borderColour = theme.palette.neutralLight;
              return {
                root: { padding: 16, border: `1px solid ${borderColour}` }
              };
            }}
          >
            <Heading variant="section-sub-heading">
              {measurement.summary}
            </Heading>
            <MessageBar
              {...dataAttribute(DataAttributes.Element, "score-modal")}
              messageBarType={MessageBarType.info}
            >
              {measurement.value ?? 0}
            </MessageBar>
          </Stack>
        ))}

        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { paddingTop: 16 } }}
          tokens={{ childrenGap: 8 }}
        >
          <DefaultButton
            onClick={() => {
              onClose();
            }}
          >
            Close
          </DefaultButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
