import { observer } from "mobx-react-lite";
import { FC, useContext, useMemo } from "react";
import { useField, useForm } from "react-final-form";

import { IDropdownOption } from "@bps/fluent-ui";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { useIsEmployerRequired } from "./hooks/useIsEmployerRequired.tsx";

interface SavedEmployerFieldProps {
  employerContacts: Contact[]; // patient.employerContacts get loaded by the parent so we pass it in via props rather than getting it from the claim to ensure it is loaded
}

export const SavedEmployerField: FC<SavedEmployerFieldProps> = observer(
  ({ employerContacts }) => {
    const form = useForm<ClaimFormValues>();

    const { onEmployerSelected } = useContext(ClaimContext);

    const {
      input: { value: isQuickAddEmployer }
    } = useField(claimFormNameOf("isQuickAddEmployer"), {
      subscription: { value: true }
    });

    const isEmployerDetailsRequired = useIsEmployerRequired();

    const patientEmployers: IDropdownOption[] = useMemo(
      () =>
        employerContacts.map(x => ({
          key: x.id,
          text: x.name
        })) || [],
      [employerContacts]
    );

    return (
      <>
        <DropdownField
          label="Saved employer"
          name={claimFormNameOf("employerId")}
          required={!isQuickAddEmployer && isEmployerDetailsRequired}
          options={patientEmployers}
          fieldItemStyles={{ root: { flex: 1 } }}
        />
        <FieldSpy
          name={claimFormNameOf("employerId")}
          onChange={employerId => {
            if (!employerId) {
              form.batch(() => {
                form.change(claimFormNameOf("employerAddress"), undefined);
                form.change(
                  claimFormNameOf("hasInvalidEmployerAddress"),
                  false
                );
              });
            } else {
              onEmployerSelected(employerId, form);
            }
          }}
        />
      </>
    );
  }
);
