import React, { useContext } from "react";

import { Heading } from "@bps/fluent-ui";
import {
  CommunicationKind,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserInfoFormLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { formatCommunicationValue } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TitlePickerField } from "@ui-components/form/TitlePickerField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { UserContext } from "../context/UserContext.tsx";
import { nameOf } from "./CreateUserForm.types.ts";
import { LicenceMessageBar } from "./LicenceMessageBar.tsx";
import { useCreateUserFormValidator } from "./useCreateUserFormValidator.ts";

interface CreateUserFormDialogProps {
  onDismiss: () => void;
}

export const CreateUserFormDialog: React.FC<CreateUserFormDialogProps> = ({
  onDismiss
}) => {
  const { core } = useStores();

  const helper = useContext(UserContext);

  const country = core.tenantDetails && core.tenantDetails.country;

  const format = (value: any) =>
    formatCommunicationValue(value, CommunicationKind.Phone, country);

  const DIALOG_TITLE = "Create user";

  const validate = useCreateUserFormValidator();

  return (
    <SubmissionFormDialog
      dialogName={`${DIALOG_TITLE} dialog`}
      onSubmit={helper.onCreateUserFormSubmit}
      buttonsProps={{ hideButtonsSeparator: true }}
      dialogProps={{
        dialogContentProps: {
          title: <Heading variant="modal-heading">{DIALOG_TITLE}</Heading>,
          showCloseButton: true
        },
        maxWidth: 600,
        minWidth: 600,
        onDismiss,
        modalProps: {
          layerProps: {
            eventBubblingEnabled: true
          }
        }
      }}
      validate={validate}
    >
      {() => (
        <Fieldset verticalFill>
          <Fieldset horizontal>
            <TitlePickerField label="Title" name={nameOf("title")} />
            <TextInputField
              required
              name={nameOf("firstName")}
              label={UserInfoFormLabels.firstName}
            />
          </Fieldset>

          <Fieldset horizontal>
            <TextInputField
              name={nameOf("preferredName")}
              label="Preferred name"
              placeholder="Same as the 'First name' if not specified"
            />
            <TextInputField name={nameOf("middleName")} label="Middle name" />
          </Fieldset>
          <TextInputField
            required
            name={nameOf("lastName")}
            label={UserInfoFormLabels.lastName}
          />

          <Fieldset horizontal>
            <TextInputField
              format={format}
              name={nameOf("phone")}
              label="Phone"
              styles={{ root: { width: 290 } }}
            />
            <TextInputField
              required
              name={nameOf("username")}
              label={UserInfoFormLabels.email}
              hint="This email will be used as login username"
              styles={{ root: { width: "100%" } }}
            />
          </Fieldset>
          <StaticTagPickerField
            required
            fetchDataSource={async () => {
              const businessRoles = await core.getCatalogBusinessRoles();
              return businessRoles.map(({ code, text }) => ({
                key: code,
                name: text
              }));
            }}
            name={nameOf("businessRoles")}
            label="Business roles"
            formItemFieldStyles={{ item: { width: "100%" } }}
          />
          <LicenceMessageBar />
          <When
            permission={[Permission.ProviderRead, Permission.ProviderWrite]}
          >
            <Fieldset horizontal>
              <TextInputField
                name={nameOf("ahpraNumber")}
                label="Registration number"
              />
              <TextInputField
                name={nameOf("medicareProviderNumber")}
                label="Provider number"
              />
            </Fieldset>
          </When>
        </Fieldset>
      )}
    </SubmissionFormDialog>
  );
};
