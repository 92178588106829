import { useField } from "react-final-form";

import { toBase64 } from "@libs/api/utils/blob-service.utils.ts";
import { DropzoneSection } from "@ui-components/drop-zone-section/DropzoneSection.tsx";

export const HealthCertificateFileField: React.FC = () => {
  const { input: certificateFileInput } = useField("certificateFile", {
    subscription: { value: true }
  });

  const { input: certificateNameInput } = useField("certificateName", {
    subscription: { value: true }
  });

  const handleDropAccepted = async (files: File[]) => {
    const certificate = files[0];
    const certificateBase64 = await toBase64(certificate);
    certificateNameInput.onChange(certificate.name);
    certificateFileInput.onChange(certificateBase64);
  };

  return (
    <DropzoneSection
      description="Drag and drop"
      onDropAccepted={handleDropAccepted}
      accept={{ "application/x-pkcs12": [".pfx"] }}
    />
  );
};
