import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DraftInvoiceGenerateFormValues } from "./DraftInvoiceGenerateModal.type.ts";

export class DraftInvoiceGenerateValidator extends Validator<DraftInvoiceGenerateFormValues> {
  constructor(invoiceItems: InvoiceItemDto[]) {
    super();

    const nameof = nameOfFactory<DraftInvoiceGenerateFormValues>();

    const anyItemsAvailable = (patientId: string, providerId: string) => {
      const itemsAvailable = invoiceItems.filter(
        x => x.patientId === patientId && x.userId === providerId
      );
      return itemsAvailable.length === 0;
    };

    this.forField(nameof("accountId"), required());
    this.forField(nameof("cutOffDate"), required());
    this.forField(nameof("selectedPatient"), [
      required(),
      (patientId: string, formValues: DraftInvoiceGenerateFormValues) =>
        anyItemsAvailable(patientId, formValues.selectedProvider)
          ? "No items for patient and provider combination"
          : undefined
    ]);
    this.forField(nameof("selectedProvider"), [
      required(),
      (providerId: string, formValues: DraftInvoiceGenerateFormValues) =>
        anyItemsAvailable(formValues.selectedPatient, providerId)
          ? "No items for patient and provider combination"
          : undefined
    ]);
  }
}
