import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";

import { PersonaSize, Spinner, Stack, Text, useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AppointmentInformationModal } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModal.tsx";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { useScrollToViewById } from "@ui-components/hooks/useScrollToViewById.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { EthnicityText, SexText, TitleText } from "@ui-components/RefText.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { PatientNoticesAddButton } from "../../../shared-components/patient-notices/PatientNoticesAddButton.tsx";
import { PatientNoticesContextProvider } from "../../../shared-components/patient-notices/PatientNoticesContext.tsx";
import {
  NoticeType,
  PatientNoticesModel
} from "../../../shared-components/patient-notices/PatientNoticesModel.ts";
import { PatientNoticesTable } from "../../../shared-components/patient-notices/PatientNoticesTable.tsx";
import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { CardWrapper } from "../../shared-components/view/CardWrapper.tsx";
import { ContactMethodList } from "../../shared-components/view/contact-method-list/ContactMethodList.tsx";
import { ContactLayoutWrapper } from "../../shared-components/view/ContactLayoutWrapper.tsx";
import { ContactRelationshipList } from "../../shared-components/view/ContactRelationshipList.tsx";
import { HeaderWrapper } from "../../shared-components/view/HeaderWrapper.tsx";
import { AccountsInfoCard } from "./AccountsInfoCard.tsx";
import { ConditionsCard } from "./conditions-card/ConditionsCard.tsx";
import { EmployersCard } from "./EmployersCard.tsx";
import { FormsCard } from "./FormsCard.tsx";
import { FormsCardWrapper } from "./FormsCardWrapper.tsx";
import { PatientProviders } from "./HealthProvidersCard.tsx";
import { NextAndLastAppointments } from "./NextAndLastAppointments.tsx";
import { AccountTotalDataFetcher } from "./patient-warnings/AccountTotalDataFetcher.tsx";
import { getMenuItemsWithNotes } from "./patient-warnings/utils.ts";
import { PatientEntitlements } from "./PatientEntitlements.tsx";
import { PatientPreferencesConsentsCard } from "./PatientPreferencesConsentsCard.tsx";
import { PatientProfileCard } from "./PatientProfileCard.tsx";
import { ageDescription } from "./utils.ts";

export interface PatientDetailsProps {
  patient: Contact;
  contactPreferences: ContactPreferences | undefined;
}

export const PatientDetails: FunctionComponent<PatientDetailsProps> = observer(
  ({ patient, contactPreferences }) => {
    const { core, practice, routing } = useStores();

    const {
      ui: { setSelectedEditCard, showEditContact }
    } = practice;

    const scroll = useScrollToViewById(undefined, 1000);
    const theme = useTheme();
    const { breakLongText, personaStyle, personaTitleStyle } =
      getPeopleScreenStylesSet(theme);

    const [hideModal, setHideModal] = useState(true);

    // Reload system notices when the edit patient form is saved e.g. interpret notice added/removed
    const contactReaction = reaction(
      () => patient.patientEntitlements,
      () => practice.loadSystemNotices([patient.id])
    );

    useEffect(() => {
      return () => {
        contactReaction();
      };
    }, [contactReaction]);

    useEffect(() => {
      return () => {
        setSelectedEditCard(PatientCardIds.patientHeader);
      };
    }, [setSelectedEditCard]);

    useEffect(() => {
      if (!!routing.location.search) {
        const section = routing.queryStringParam("section");
        scroll(section);
      }
    }, [routing, scroll]);

    const onHandleEditModal = (cardId: string) => {
      showEditContact(cardId, patient.id);
    };

    const getPersonaSecondaryText = (patient: Contact) => {
      return (
        <Stack
          verticalAlign="center"
          verticalFill
          styles={{ root: { ...breakLongText } }}
        >
          <Stack.Item>
            {ageDescription(patient) || Labels.noDobRecorded}
          </Stack.Item>
          <Stack.Item>
            <>
              {patient.pronoun ??
                (patient.sex ? (
                  <span>
                    Assigned:&nbsp;
                    <span>
                      <SexText code={patient.sex} />
                    </span>
                  </span>
                ) : (
                  Labels.noGenderRelatedInformationRecorded
                ))}
              {patient.pronoun && patient.sex && (
                <span>
                  &nbsp;(Assigned:&nbsp;
                  {patient.sex && (
                    <span>
                      <SexText code={patient.sex} />
                    </span>
                  )}
                  )
                </span>
              )}
            </>
          </Stack.Item>
          <Stack.Item>
            {(patient.ethnicities.length && (
              <EthnicityText code={patient.ethnicities} />
            )) ||
              Labels.noEthnicityRecorded}
          </Stack.Item>
          <WhenCountry is={Country.NewZealand}>
            {patient.nhi?.number && patient.nhi?.number !== ""
              ? patient.nhi?.number
              : Labels.noNhiRecorded}
          </WhenCountry>
        </Stack>
      );
    };

    const renderPrimaryText = (patient: Contact) => (): JSX.Element => {
      return (
        <Stack>
          <Text styles={personaTitleStyle}>
            <TitleText code={patient.title} />
          </Text>
          {patient.name}
        </Stack>
      );
    };

    const renderSecondaryText = (patient: Contact) => (): JSX.Element => {
      return getPersonaSecondaryText(patient);
    };

    const { medicare, healthInsurance } = patient;

    const isPreferencesConsentsExist = !!(
      contactPreferences?.commTypePreferences?.length ||
      patient.interpreterLanguage
    );

    const isContactMethodsExist =
      patient.addresses.length > 0 || patient.communications.length > 0;

    const isRelationshipsExist =
      patient.relationships.length > 0 &&
      patient.relationships.some(
        x =>
          x.relationship !== RelationshipType.AccountHolder &&
          x.relationship !== RelationshipType.AccountHolderFor
      );

    const isEmployersExist =
      patient.relationships.length > 0 &&
      patient.relationships.some(
        x => x.relationship === RelationshipType.Employer
      );

    const isHealthProvidersExist =
      patient.internalProviders?.length > 0 ||
      patient.externalProviders?.length > 0;

    const medNo = medicare && medicare.number;
    const hfund = healthInsurance && healthInsurance.fund;
    const isEntitlementsExist =
      core.tenantDetails!.country === Country.Australia
        ? medNo || patient.dva || hfund
        : patient.csc;

    return (
      <AccountTotalDataFetcher accountId={patient.id}>
        {({ accountTotals }) => (
          <ContactLayoutWrapper
            menuItems={getMenuItemsWithNotes({
              hasPermissions: core.hasPermissions,
              patient,
              accountTotals
            })}
          >
            <Stack tokens={{ childrenGap: 8 }} grow>
              <HeaderWrapper
                contact={patient}
                onHandleEditModal={onHandleEditModal}
                persona={
                  <Persona
                    id={patient.id}
                    contactType={patient.type}
                    size={PersonaSize.size100}
                    imageInitials={patient.initials}
                    imageUrl={patient.profilePictureUrl}
                    styles={personaStyle}
                    onRenderPrimaryText={renderPrimaryText(patient)}
                    onRenderSecondaryText={renderSecondaryText(patient)}
                  />
                }
                bottomContent={
                  <DataFetcher
                    fetch={({ practice }) =>
                      Promise.all([
                        practice.getPatientNotices(patient.id),
                        practice.loadSystemNotices([patient.id])
                      ])
                    }
                    fallback={<Spinner />}
                  >
                    {() => (
                      <PatientNoticesContextProvider
                        value={
                          new PatientNoticesModel(practice, {
                            patientId: patient.id,
                            withSystemNotices: true
                          })
                        }
                      >
                        <PatientNoticesAddButton filter={NoticeType.Admin} />
                        <PatientNoticesTable
                          filter={NoticeType.Admin}
                          childrenGap={0}
                          padding="0 8px"
                          showActions
                        />
                      </PatientNoticesContextProvider>
                    )}
                  </DataFetcher>
                }
              />
              <CardWrapper
                showEditIcon={isContactMethodsExist}
                title={Labels.contactMethods}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.contactMethods}
              >
                <ContactMethodList
                  showEditIcon={isContactMethodsExist}
                  patient={patient}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <CardWrapper
                showEditIcon={isPreferencesConsentsExist}
                title={Labels.communicationPreferences}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.prefCons}
              >
                <PatientPreferencesConsentsCard
                  contactPreferences={contactPreferences}
                  showEditIcon={isPreferencesConsentsExist}
                  patient={patient}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <When permission={[Permission.AccountHistoryAllowed]}>
                <AccountsInfoCard
                  contact={patient}
                  cardId={PatientCardIds.patientAccount}
                  onHandleEditModal={onHandleEditModal}
                />
              </When>
              <CardWrapper
                showEditIcon={isRelationshipsExist}
                title={Labels.relationships}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.contactRelationships}
              >
                <ContactRelationshipList
                  showEditIcon={isRelationshipsExist}
                  contact={patient}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <CardWrapper
                showEditIcon={isEntitlementsExist}
                title={Labels.cardsEntitlements}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.patientEntitlements}
              >
                <PatientEntitlements
                  showEditIcon={isEntitlementsExist}
                  patient={patient}
                  onHandleEditModal={onHandleEditModal}
                  country={core.tenantDetails?.country}
                />
              </CardWrapper>
              <CardWrapper
                showEditIcon={isHealthProvidersExist}
                title={Labels.healthProviders}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.healthProviders}
              >
                <PatientProviders
                  showEditIcon={isHealthProvidersExist}
                  patient={patient}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <CardWrapper
                showEditIcon={true}
                title={Labels.employers}
                cardId={PatientCardIds.employers}
                onButtonClick={onHandleEditModal}
              >
                <EmployersCard
                  showEditIcon={isEmployersExist}
                  patient={patient}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <When permission={[Permission.CalendarEventRead]}>
                <CardWrapper
                  showEditIcon={true}
                  title={Labels.appointments}
                  onButtonClick={() => setHideModal(false)}
                  cardId={PatientCardIds.appointments}
                  isActionIsLink={true}
                  actionLinkTitle={Labels.moreAppointments}
                >
                  <NextAndLastAppointments patient={patient} />
                  <AppointmentInformationModal
                    contactId={patient.id}
                    isHidden={hideModal}
                    onDismissInfoModal={() => setHideModal(true)}
                    patientName={patient.fullName}
                  />
                </CardWrapper>
              </When>
              <CardWrapper
                showEditIcon={false}
                title={Labels.conditions}
                cardId={PatientCardIds.conditions}
              >
                <ConditionsCard patientId={patient.id} />
              </CardWrapper>
              <CardWrapper
                showEditIcon={true}
                title={Labels.profile}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.patientProfile}
              >
                <PatientProfileCard contact={patient} />
              </CardWrapper>
              <When permission={[Permission.SendForms]}>
                <FormsCardWrapper
                  cardId={PatientCardIds.forms}
                  marginBottom={16}
                  communications={patient.communications}
                  patient={patient}
                >
                  <FormsCard
                    showEditIcon={isEmployersExist}
                    patient={patient}
                    onHandleEditModal={onHandleEditModal}
                  />
                </FormsCardWrapper>
              </When>
            </Stack>
          </ContactLayoutWrapper>
        )}
      </AccountTotalDataFetcher>
    );
  }
);
