import React from "react";

import { useStores } from "@stores/hooks/useStores.ts";

import { SecondColumnWrapper } from "../SecondColumnWrapper.tsx";
import { RecurrenceFields } from "./RecurrenceFields.tsx";

export const RepeatTwoColumnLayout: React.FunctionComponent = () => {
  const { booking } = useStores();
  return (
    <SecondColumnWrapper heading="Repeat">
      <RecurrenceFields isEditSingleEvent={booking.ui.isEditSingleEvent} />
    </SecondColumnWrapper>
  );
};
