import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import {
  Jump,
  ScrollablePane,
  SideRailMenu,
  SideRailMenuItem,
  Stack
} from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { SubmissionFormDialogHeader } from "@ui-components/form/submission-form-dialog/SubmissionFormDialogHeader.tsx";

import { PatientNoticesEditDemographicsCard } from "../../../shared-components/patient-notices/PatientNoticesEditDemographicsCard.tsx";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PersonaProfilePictureMessageBar } from "./PersonaProfilePictureMessageBar.tsx";

type EditFormLayoutProps = PropsWithChildren<{
  menuItems: SideRailMenuItem[];
  contact?: Contact;
}>;

export const EditFormLayout: React.FC<EditFormLayoutProps> = observer(
  ({ menuItems, children, contact }) => {
    const { practice } = useStores();
    const {
      ui: { selectedEditCard, newContactType, hideModal }
    } = practice;

    const getTitle = () => {
      if (newContactType) {
        switch (newContactType) {
          case ContactType.Individual:
            return Labels.newIndividual;
          case ContactType.Organisation:
            return Labels.newOrganisation;
          default:
            return Labels.newPatient;
        }
      } else {
        return `Edit ${contact?.name}`;
      }
    };

    return (
      <Stack styles={{ root: { height: "100%" } }}>
        <SubmissionFormDialogHeader onCancel={hideModal} title={getTitle()} />

        <SideRailMenu
          defaultId={selectedEditCard}
          styles={({ theme }) => ({
            root: { height: "calc(100% - 80px)" },
            container: {
              overflow: "auto",
              minWidth: 230,
              padding: 16,
              marginRight: 0,
              height: "70%"
            },
            containerWrapper: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: theme.palette.neutralLighterAlt
            }
          })}
          items={menuItems.map(item => ({
            ...item,
            id: `${item.id}-edit`
          }))}
          delay={100}
          scrollOptions={Jump}
          extraItems={
            contact &&
            contact.type === ContactType.Patient && (
              <PatientNoticesEditDemographicsCard patient={contact} />
            )
          }
        >
          <div style={{ position: "relative", width: "100%" }}>
            <ScrollablePane styles={{ root: { height: "100%" } }}>
              {children}
              <PersonaProfilePictureMessageBar />
            </ScrollablePane>
          </div>
        </SideRailMenu>
      </Stack>
    );
  }
);
