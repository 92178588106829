import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { IGroup } from "@bps/fluent-ui";
import { compareDatesPredicate, DateTime } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { getOrThrow } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";

import {
  InboxGroupedList,
  useInboxSelection
} from "../../shared-components/InboxGroupedList.tsx";
import { useUploadedResult } from "./useUploadedResult.tsx";

export const UploadScreenLeftSideList: React.FC = observer(() => {
  const { inbox } = useStores();
  const selection = useInboxSelection();
  const { searchResults, isLoading, isLoadingMore, handleScrolledToBottom } =
    useUploadedResult({ refreshKey: inbox.ui.uploadDocumentRefreshKey });

  useEffect(() => {
    const { hub } = inbox;
    // subscribe listener on mount
    hub.onEntityEvent(
      Entity.DocumentWorkflowInboxDocument,
      inbox.onDocumentUploadedEvent
    );

    // unsubscribe listener on unmount
    return () => {
      hub.unsubscribe(
        Entity.DocumentWorkflowInboxDocument,
        inbox.onDocumentUploadedEvent
      );
    };
  }, [inbox]);

  //stops assigned upload docs from jumping between uploads and other.
  //fetching inbox documents sometimes returns outdated data, even though the event has fired to say it updated.
  //https://dev.azure.com/bpcloud-dev/BpCloud/_workitems/edit/44932/
  const unassignedResults = searchResults?.value?.results.filter(
    doc =>
      !doc.assignedToUserId &&
      !inbox.pendingUploadMap.has(doc.id) &&
      inbox.inboxDocumentsMap.has(doc.id)
  );

  const uploadedDocs = Array.from(inbox.uploadSessionDocsIds.values())
    .map(id => {
      return getOrThrow(inbox.inboxDocumentsMap, id);
    })
    .sort((a, b) => {
      const dateA = DateTime.fromISO(a.changeLog.createdDate);
      const dateB = DateTime.fromISO(b.changeLog.createdDate);
      return compareDatesPredicate(dateA, dateB, true);
    });

  const isUploadSessionDoc = (doc: InboxDocument) =>
    inbox.uploadSessionDocsIds.has(doc.id);

  const otherDocs =
    unassignedResults?.filter(doc => !isUploadSessionDoc(doc)) ?? [];

  const allDocs = uploadedDocs.concat(otherDocs);

  const groups: IGroup[] = [];

  const getOtherDocTotalCount = () => {
    const searchResultTotal = searchResults.value?.total ?? 0;
    const unassignedDocsCount =
      (searchResults.value?.results.length ?? 0) - (otherDocs?.length ?? 0);

    return searchResultTotal - unassignedDocsCount;
  };

  if (uploadedDocs.length > 0)
    groups.push({
      key: "currentUpload",
      name: "This upload",
      startIndex: 0,
      count: uploadedDocs.length
    });

  if (otherDocs.length > 0)
    groups.push({
      key: "otherUpload",
      name: "Others",
      startIndex: uploadedDocs.length,
      count: getOtherDocTotalCount()
    });

  return (
    <InboxGroupedList
      selection={selection}
      groups={groups}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      files={allDocs}
      searchResults={searchResults}
      onScrollToBottom={handleScrolledToBottom}
    />
  );
});
