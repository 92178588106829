import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ChangeSideItemValuesType } from "@shared-types/acc/change-side-item-values.type.ts";

export class SideChangeValidator extends Validator<ChangeSideItemValuesType> {
  constructor() {
    super();
    this.forField("oldDiagnosisKey", required());
    this.forField("diagnosisCode", [required()]);
    this.forField("diagnosisSide", [required()]);
  }
}
