import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Heading, Stack, useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  CommunicationKind,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AddressType,
  CommunicationDto,
  CommunicationType,
  NzOrgUnitIdentifierDto,
  OrgUnitCompanyDataType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { Address } from "@stores/core/models/Address.ts";
import {
  formatCommunicationValue,
  formatPhone,
  parsePhone
} from "@stores/core/models/Communication.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { CountryPickerField } from "@ui-components/CountryPickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { getUserStylesSet } from "../../../users/components/UserScreens.styles.tsx";
import { LocationDetailsValues } from "../practice-locations/LocationDetails.types.ts";
import { LocationLabels } from "../PracticeScreen.types.ts";
import { LocationDetailsValidator } from "./LocationDetailsValidator.ts";

interface LocationDetailsFormProps {
  pracOrgUnit?: PracOrgUnit;
}

const nameOf = nameOfFactory<LocationDetailsValues>();
export const LocationDetailsForm: FC<LocationDetailsFormProps> = observer(
  ({ pracOrgUnit }) => {
    const { core, notification, practice } = useStores();
    const theme = useTheme();
    const { formFooter, formFields } = getUserStylesSet(theme);

    const readOnly = !core.hasPermissions(Permission.OrgUnitSettingWrite);

    const country = core.tenantDetails && core.tenantDetails.country;
    const { location: orgUnit } = core;
    const locationOrgUnit: OrgUnit = orgUnit;
    const validator = new LocationDetailsValidator(core);
    const address = locationOrgUnit.firstAddress;
    const validateForm = (values: LocationDetailsValues) =>
      validator.validate(values);

    const workPhone: CommunicationDto = {
      type: CommunicationType.WorkPhone,
      value: orgUnit && orgUnit.workPhone ? orgUnit.workPhone : "",
      preferred: false
    };

    const { countries } = core.ref;

    const isStateRequired = (value: string) =>
      countries.values.some(c => c.code === value && c.isStateRequired);

    const isCityRequired = (value: string) =>
      countries.values.some(c => c.code === value && !c.isStateRequired);

    const format = (value: any) =>
      formatCommunicationValue(value, CommunicationKind.Phone, country);

    const initialValues: LocationDetailsValues = {
      id: pracOrgUnit?.id,
      practiceName: locationOrgUnit.name,
      address1: address?.street1,
      address2: address?.street2,
      suburb: address?.suburb,
      country: address?.country,
      postCode: address?.postCode,
      city: address?.city,
      state: address?.state,
      phone: formatPhone(workPhone.value, country),
      timezone: locationOrgUnit.timezone,
      email: orgUnit.email,
      hpiFacilityId: pracOrgUnit?.nzOrgUnitIdentifier?.hpiFacilityId
    };

    const onSubmit = async (values: LocationDetailsValues) => {
      const { location } = core;

      const address = location.firstAddress;
      const { phone, email, hpiFacilityId } = values;
      const workPhone = parsePhone(phone);
      let communications: CommunicationDto[] = [];

      if (workPhone && (!location || location.workPhone !== workPhone)) {
        communications = (
          location
            ? location.contact.filter(
                x => x.type !== CommunicationType.WorkPhone
              )
            : []
        ).concat([
          {
            type: CommunicationType.WorkPhone,
            value: workPhone,
            preferred: false
          }
        ]);
      } else if (!workPhone && location && location.workPhone) {
        communications = location.contact.filter(
          x => x.type !== CommunicationType.WorkPhone
        );
      } else if (workPhone && location && location.workPhone === workPhone) {
        communications = location.contact.filter(
          x => x.type === CommunicationType.WorkPhone
        );
      }

      if (email) {
        communications.push({
          type: CommunicationType.Email,
          value: email,
          preferred: false
        });
      }

      let street2: string | undefined;
      if (values.address2) {
        street2 = values.address2;
      } else if (address?.street2) {
        street2 = "";
      }

      let city: string | undefined;
      if (values.city) {
        if (values.country === Country.Australia) {
          city = "";
        } else {
          city = values.city;
        }
      }

      const addresses: Address[] = [
        {
          type: AddressType.Physical,
          street1: values.address1!,
          street2,
          suburb: values.suburb,
          city,
          state: values.state,
          postCode: values.postCode,
          country: values.country ?? Country.NewZealand
        }
      ];

      await core.updateOrgUnit({
        id: location.id,
        addresses,
        name: values.practiceName,
        contact: communications,
        timeZone: values.timezone
      });

      if (initialValues.hpiFacilityId !== hpiFacilityId) {
        await practice.updateOrgUnit({
          id: location.id,
          orgUnitCompanyData: [
            {
              orgUnitCompanyDataTypeCode:
                OrgUnitCompanyDataType.NzOrgUnitIdentifier,
              hpiFacilityId
            } as NzOrgUnitIdentifierDto
          ]
        });
      }
    };

    return (
      <SubmissionForm<LocationDetailsValues>
        formName="location-details"
        onSubmit={onSubmit}
        autoFocus={false}
        onSubmitSucceeded={() => {
          notification.success(`${orgUnit.name} has been updated.`);
        }}
        readOnly={readOnly}
        validate={validateForm}
        initialValues={initialValues}
        buttonsProps={{
          disableCancelOnPristine: true,
          onCancel: form => {
            form.reset();
          },
          styles: {
            root: formFooter
          }
        }}
      >
        {({ form, values }) => {
          return (
            <Stack
              styles={{
                root: formFields
              }}
              tokens={{ childrenGap: 8 }}
            >
              <TextInputField
                placeholder={LocationLabels.locationName}
                name={nameOf("practiceName")}
                label={LocationLabels.locationName}
                required
              />
              <TextInputField
                placeholder={LocationLabels.email}
                name={nameOf("email")}
                label={LocationLabels.email}
              />
              <TextInputField
                placeholder={LocationLabels.phone}
                name={nameOf("phone")}
                label={LocationLabels.phone}
                format={format}
                required
              />
              <WhenCountry is={Country.NewZealand}>
                <TextInputField
                  placeholder={LocationLabels.hpiFacilityId}
                  name={nameOf("hpiFacilityId")}
                  label={LocationLabels.hpiFacilityId}
                />
              </WhenCountry>
              <Heading variant="section-sub-heading" hasAsterisk>
                {LocationLabels.address}
              </Heading>
              <TextInputField
                placeholder={
                  values.country === Country.NewZealand
                    ? LocationLabels.street
                    : LocationLabels.addressLine1
                }
                name={nameOf("address1")}
              />
              <TextInputField
                placeholder={
                  values.country === Country.NewZealand
                    ? LocationLabels.buildingName
                    : LocationLabels.addressLine2
                }
                name={nameOf("address2")}
              />
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <TextInputField
                  placeholder={LocationLabels.suburb}
                  name={nameOf("suburb")}
                />
                <FieldCondition when={nameOf("country")} is={isCityRequired}>
                  <TextInputField
                    placeholder={
                      values.country === Country.NewZealand
                        ? LocationLabels.townCity
                        : LocationLabels.city
                    }
                    name={nameOf("city")}
                  />
                </FieldCondition>
                <FieldCondition when={nameOf("country")} is={isStateRequired}>
                  <DropdownField
                    name={nameOf("state")}
                    placeholder={LocationLabels.state}
                    styles={{ root: { width: "100%" } }}
                    options={core.ref.australianStates.keyTextValues}
                  />
                </FieldCondition>
                <FieldSpy
                  name={nameOf("country")}
                  onChange={country =>
                    country &&
                    !isStateRequired(country) &&
                    form.change("state", undefined)
                  }
                />
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <TextInputField
                  placeholder={LocationLabels.postCode}
                  name={nameOf("postCode")}
                />
                <FieldSpy
                  name={nameOf("country")}
                  onChange={value => {
                    if (!value) {
                      form.change(nameOf("country"), Country.NewZealand);
                    }
                  }}
                />
                <CountryPickerField
                  styles={{ container: { width: "100%" } }}
                  name={nameOf("country")}
                  placeholder={LocationLabels.country}
                  calloutProps={{
                    calloutMaxHeight: 500
                  }}
                />
              </Stack>
              <StaticPickerField
                label={LocationLabels.timeZone}
                name={nameOf("timezone")}
                fetchDataSource={() =>
                  core.ref.timeZones.fetchAsKeyNameValues()
                }
                required
              />
            </Stack>
          );
        }}
      </SubmissionForm>
    );
  }
);
