import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Document,
  DocumentViewerDialog
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

import { ExportPatientRecordContext } from "./context/ExportPatientRecordContext.ts";

export const ExportPatientRecordViewerDialog = observer(() => {
  const { practice } = useStores();
  const { masterPatient, setShowExportPatientRecordViewerDialog } = practice.ui;

  const patientId = masterPatient?.id;

  const { getExportPatientDocument } = useContext(ExportPatientRecordContext);

  return (
    <DocumentViewerDialog
      getDocument={(): Promise<Document> =>
        getExportPatientDocument(DocumentContentType.Pdf, patientId)
      }
      downloadInNewTab
      closeDocumentViewer={() => setShowExportPatientRecordViewerDialog(false)}
    />
  );
});
