import { useField } from "react-final-form";

import {
  CSSTransition,
  dataAttribute,
  DataAttributes,
  fadeEnter,
  fadeEnterActive,
  fadeExit,
  fadeExitActive,
  Link,
  MessageBar
} from "@bps/fluent-ui";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { patientFormNameOf } from "./PatientEditFormValues.tsx";
import { RelationshipFieldValue } from "./relationships/utils.ts";

interface BillToAccountRelationshipMessageBarProps {
  addAccountHolder: (relatedContactId?: string) => void;
}

export const BillToAccountRelationshipMessageBar: React.FC<
  BillToAccountRelationshipMessageBarProps
> = ({ addAccountHolder }) => {
  const {
    input: { value: relationships }
  } = useField<RelationshipFieldValue[]>(patientFormNameOf("relationships"), {
    subscription: { value: true }
  });

  const {
    input: { value: accountHolders }
  } = useField<RelationshipFieldValue[]>(patientFormNameOf("accountHolders"), {
    subscription: { value: true }
  });

  const selectRelationship = (relationship: RelationshipFieldValue) => {
    addAccountHolder(relationship.relatedContactId);
  };

  const validRelationships = relationships.filter(
    x =>
      !!x.relatedContactId &&
      accountHolders.filter(y => y.relatedContactId === x.relatedContactId)
        .length === 0
  );

  const relationshipLink = (
    relationship: RelationshipFieldValue,
    index: number
  ) => {
    const contactId = relationship.relatedContactId
      ? relationship.relatedContactId
      : "";

    let separator: string = ", ";
    if (index === validRelationships.length - 1) {
      separator = ".";
    } else if (index === validRelationships.length - 2) {
      separator = ", or ";
    }

    return (
      <div key={relationship.id}>
        <Link
          key={relationship.id}
          {...dataAttribute(
            DataAttributes.Element,
            `relationship-link-${index}`
          )}
          onClick={() => selectRelationship(relationship)}
        >
          <ContactFetcher contactId={contactId}>
            {contact => contact.name}
          </ContactFetcher>
        </Link>
        {separator}
      </div>
    );
  };

  const text = Labels.addingAccountHolderFromExistingRelationships;
  return (
    <>
      {!!validRelationships.length && (
        <CSSTransition
          timeout={300}
          classNames={{
            enter: fadeEnter,
            enterActive: fadeEnterActive,
            exit: fadeExit,
            exitActive: fadeExitActive
          }}
          mountOnEnter={false}
        >
          <MessageBar styles={{ iconContainer: { alignItems: "center" } }}>
            <span
              {...dataAttribute(DataAttributes.Element, "relationship-text")}
            >
              {text}
            </span>
            {validRelationships.map((x, i) => relationshipLink(x, i))}
          </MessageBar>
        </CSSTransition>
      )}
    </>
  );
};
