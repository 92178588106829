import React from "react";

import {
  ButtonsGroupOption,
  FontIcon,
  Heading,
  Stack,
  TextWithIcon,
  TooltipHost
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CommunicationDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContextMenuItemsEnum } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";
import { canReceiveForm } from "@stores/core/models/Communication.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { When } from "@ui-components/withPerm.tsx";

interface SendFormCheckboxProps {
  communication: CommunicationDto[] | undefined;
  fieldName: string;
  heading?: string;
  checkBoxLabel: string;
}

export const SendFormCheckbox: React.FunctionComponent<
  SendFormCheckboxProps
> = ({ communication, fieldName, heading }) => {
  const labelProps = (iconName: string) => {
    return {
      iconProps: {
        iconName,
        styles: {
          root: { marginRight: 8 }
        }
      },
      styles: {
        root: { display: "flex", alignItems: "center" }
      }
    };
  };

  const send: ButtonsGroupOption<string> = {
    key: "send",
    text: "Send a link",
    disabled: !canReceiveForm(communication),
    onRenderText: option => (
      <TextWithIcon {...labelProps("Send")}>{option.text}</TextWithIcon>
    )
  };

  const qrCode: ButtonsGroupOption<string> = {
    key: ContextMenuItemsEnum.QRCodeKey,
    text: "Generate a QR code",
    onRenderText: option => (
      <TextWithIcon {...labelProps("GenericScan")}>{option.text}</TextWithIcon>
    )
  };

  const options: ButtonsGroupOption<string>[] = [send, qrCode];
  return (
    <When permission={Permission.SendForms}>
      <Stack>
        {heading && (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
            <Heading labelPaddings>{heading}</Heading>
            {!canReceiveForm(communication) && (
              <TooltipHost
                content="The information will be requested via the selected channel as soon as the appointment is booked"
                styles={{ root: { paddingTop: 3 } }}
              >
                <FontIcon iconName="info" />
              </TooltipHost>
            )}
          </Stack>
        )}
        <ButtonsGroupSingleChoiceField name={fieldName} options={options} />
      </Stack>
    </When>
  );
};
