import React, { Fragment, memo, useState } from "react";

import { Link, Stack, StackItem, Text } from "@bps/fluent-ui";
import {
  ClinicalNotesSubHeadings,
  TodaysNotesStructuredNote
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export interface PastVisitDetailsHeadingProps {
  structuredNote: TodaysNotesStructuredNote;
  index: number;
}

// Seperated so that individual headings may hide/show their images.
export const PastVisitDetailsHeading: React.FC<PastVisitDetailsHeadingProps> =
  memo(({ structuredNote, index }) => {
    const [showImages, setShowImages] = useState<boolean>(true);

    const isImage =
      (structuredNote.note.includes("id='images'") ||
        structuredNote.note.includes("data:image")) &&
      (structuredNote.type === ClinicalNotesSubHeadings.BodyExaminationImages ||
        structuredNote.type === ClinicalNotesSubHeadings.DMRImages);

    return (
      <Fragment key={structuredNote.sectionCode + index.toString()}>
        {isImage ? (
          <Stack horizontal>
            <StackItem
              styles={(props, theme) => ({
                root: {
                  width: "30%",
                  borderLeftWidth: 1,
                  borderLeftColor: theme.palette.neutralLight,
                  borderLeftStyle: "solid",
                  paddingLeft: "8px",
                  overFlowY: "auto"
                }
              })}
            >
              <Text
                styles={(props, theme) => ({
                  root: {
                    color: theme.palette.themeDarker,
                    fontWeight: "600"
                  }
                })}
              >
                {structuredNote.type}
              </Text>
            </StackItem>
            <StackItem
              styles={{
                root: { width: "70%" }
              }}
            >
              <Link onClick={() => setShowImages(!showImages)}>
                {showImages ? "Hide images" : "Show images"}
              </Link>
              {showImages && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: structuredNote.note
                  }}
                />
              )}
            </StackItem>
          </Stack>
        ) : (
          <Stack key={structuredNote.sectionCode + index.toString()} horizontal>
            <StackItem
              styles={(props, theme) => ({
                root: {
                  width: "33%",
                  borderLeftWidth: 1,
                  borderLeftColor: theme.palette.neutralLight,
                  borderLeftStyle: "solid",
                  paddingLeft: "8px",
                  overFlowY: "auto"
                }
              })}
            >
              <Text
                styles={(props, theme) => ({
                  root: {
                    color: theme.palette.themeDarker,
                    fontWeight: "600"
                  }
                })}
              >
                {structuredNote.type}
              </Text>
            </StackItem>
            <StackItem
              styles={{
                root: { width: "67%" }
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: structuredNote.note
                }}
              />
            </StackItem>
          </Stack>
        )}
      </Fragment>
    );
  });
