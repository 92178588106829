import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";

export const WorkInjuryField = observer(() => {
  const { practice } = useStores();

  const claimContext = useContext(ClaimContext);

  const { claim, updateAllocatedVisit } = claimContext;

  const updateInsurerPrivate = async () => {
    if (!!claim.dto.employerId) {
      const contact = await practice.getContact(claim.dto.employerId);
      if (contact.employer) {
        updateAllocatedVisit(contact.employer, claim.dto.employerId);
      }
    }
  };

  const updateInsurerPublic = async () => {
    const insurers = await practice.getDefaultInsurers();
    claimContext.insurerContactId = insurers.defaultNzPublicInsurerId;
  };

  return (
    <>
      <BoolButtonGroupField
        label={ClaimFormLabels.workInjury}
        name={claimFormNameOf("workInjury")}
        required
      />
      <FieldSpy
        name={claimFormNameOf("workInjury")}
        onChange={workInjury => {
          if (workInjury) {
            updateInsurerPrivate();
          } else {
            updateInsurerPublic();
          }
        }}
      />
    </>
  );
});
