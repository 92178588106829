import { observer } from "mobx-react-lite";

import { PresetButtonType } from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { InvestigationFilter } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import { InvestigationType } from "../investigations/side-panel/InvestigationList.tsx";

export interface InvestigationFilterProps
  extends Pick<FilterBarProps<InvestigationFilter>, "children"> {
  investigationType: InvestigationType;
}

export const InvestigationsFilter: React.FC<InvestigationFilterProps> =
  observer(({ children, investigationType }) => {
    const { core, correspondence } = useStores();
    const { clinicalRecord } = usePatientRecordScreenContext();

    const investigations = correspondence.investigationMapValues;

    const patientId = clinicalRecord.patient?.id;

    const currentCorrespondenceInvestigationType =
      investigationType === InvestigationType.Report
        ? CorrespondenceType.Report
        : CorrespondenceType.Referral;

    const isExistConfidentialInvestigationOfOthers = investigations.some(
      x =>
        x.dto.patientId === patientId &&
        correspondence.isNotNullSecGroupId(x.dto.secGroupId) &&
        !core.hasAccessToSecGroup(x.secGroupId) &&
        x.dto.type === currentCorrespondenceInvestigationType
    );

    const presets: PresetButtonType<InvestigationFilter>[] = [
      {
        name:
          investigationType === InvestigationType.Request
            ? "showOtherConfidentialRequest"
            : "showOtherConfidentialReport",
        iconName: "ReportHacked",
        tooltip: "Show confidential",
        tooltipPresses: "Hide confidential",
        tooltipOnDisabled: "No confidential items",
        disabled: !isExistConfidentialInvestigationOfOthers
      }
    ];
    return (
      <FilterBar<InvestigationFilter>
        items={[
          {
            type: "searchBox",
            name: "searchText",
            stickItem: true,
            props: {
              placeholder: "Search subject",
              styles: { root: { width: "100%" } }
            }
          }
        ]}
        presets={
          core.hasPermissions(Permission.ConfidentialDataAllowed) &&
          isExistConfidentialInvestigationOfOthers
            ? presets
            : undefined
        }
        children={children}
      />
    );
  });
