import { ITheme } from "@bps/fluent-ui";
import { CalendarEventReminderStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { OutboundCommStatuses } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CalendarEventReminderSearchModel } from "@stores/booking/models/CalendarEventReminderSearchModel.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { CalendarEventAttendeeArgs } from "../booking-calendar/context/BookingCalendarScreenModel.types.ts";

export type EventReminderState =
  | EventReminderFilters
  | CalendarEventReminderStatus
  | OutboundCommStatuses;

export enum EventReminderFilters {
  All = "ALL"
}

export interface EventReminderStateItem {
  title: EventReminderStatesText;
  state: EventReminderState;
  count: number;
  displayType: EventReminderStateDisplayType;
  statusFromApi: boolean;
}

export enum EventReminderStateDisplayType {
  Pill = 1,
  Link = 2
}

export interface EventReminderCurrentData {
  event?: CalendarEventReminderSearchModel;
  contextualMenuPosition?: MouseEvent;
}

export enum EventReminderStatesText {
  All = "All reminders sent",
  Sent = "All reminders sent",
  NotSent = "Not Sent",
  Confirmed = "Confirmed",
  NotAttending = "Not Attending",
  NoReply = "No reply",
  Declined = "Declined",
  RequiresReview = "Requires review",
  Attending = "Attending"
}

export enum EventReminderListLabel {
  AppointmentType = "Type",
  Provider = "Provider",
  Date = "Date",
  Time = "Time",
  Patient = "Patient",
  PhoneNumber = "Phone number",
  Attendance = "Attendance",
  Location = "Location",
  Actions = "Actions",
  Delivery = "Delivery",
  AppointmentStatus = "Appt status",
  DeliveryStatus = "Delivery status",
  Response = "Response"
}

export enum EventReminderStatesEnumText {
  NOREPLY = "No reply",
  CONFIRMED = "Confirmed",
  REQUIRESREVIEW = "Requires review",
  DECLINED = "Declined",
  NOTSENT = "Not sent"
}

export type EventReminderColours = {
  [key: string]: string;
};

export interface MenuItemParams {
  item?: CalendarEventReminderSearchModel;
  root: RootStore;
  setReminderArgs: (value?: CalendarEventAttendeeArgs | undefined) => void;
  theme: ITheme;
}
