import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

import {
  ActionButton,
  FontIcon,
  FontSizes,
  FontWeights,
  Heading,
  Shimmer,
  Stack,
  Text,
  TextWithIcon,
  useTheme
} from "@bps/fluent-ui";
import { formatCalendarDate } from "@bps/utils";
import {
  EncounterType,
  TodaysNotes
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { EventList } from "@ui-components/EventList.tsx";

import { EncounterReasonForVisitText } from "../clinical-form/EncounterReasonForVisitText.tsx";
import { ConditionTextBadge } from "../ConditionTextBadge.tsx";
import { loadingMoreShimmer } from "../interaction-time-line/InteractionTimeline.tsx";
import { PastVisitDetails } from "./PastVisitDetails.tsx";
import { PastVisitsFilter } from "./PastVisitFilter.tsx";
import {
  defaultFilter,
  PastVisitsFilterStateInternal
} from "./PastVisitsFilter.types.ts";
import { mapEncounterFilterArgs } from "./utils.ts";

export interface IPastVisitsProps {
  clinicalRecord: ClinicalRecord;
}

export const PastVisits: FunctionComponent<IPastVisitsProps> = observer(
  ({ clinicalRecord }) => {
    const { clinical, userExperience, core, routing } = useStores();
    const isViewOnly = clinical.activeRecordIsView;
    const encounterFilter =
      useRef<PastVisitsFilterStateInternal>(defaultFilter);

    const hasClaimPermission = core.hasPermissions([Permission.ClaimRead]);

    const theme = useTheme();

    const mapEncounters = (encounters: Encounter[]) => {
      let filteredEncounters = [...encounters];

      if (!encounterFilter.current.confidential) {
        filteredEncounters = encounters.filter(x =>
          core.hasAccessToSecGroup(x.secGroupId)
        );
      }
      return filteredEncounters.map(x => {
        return {
          id: x.id,
          title: renderDetails(x),
          iconName:
            x.type === EncounterType.RecordUpdate
              ? "NewFolder"
              : "SchoolDataSyncLogo",
          iconStyles: {
            transform: "none",
            fontSize: 16,
            top: 5,
            left: 4,
            bottom: 0
          }
        };
      });
    };

    const getPastProviders = async () => {
      const result = await clinicalRecord.loadPastProviders();
      if (result) {
        return result;
      }
      return;
    };

    const fetchData = async () => {
      const [pastProviders] = await Promise.all([
        getPastProviders(),
        clinicalRecord.loadPatientPastReasonForVisits(),
        clinicalRecord
          .loadClosedEncounters(
            mapEncounterFilterArgs(
              encounterFilter.current,
              clinicalRecord.patientPastReasonForVisit
            )
          )
          .then(() =>
            Promise.all([
              clinicalRecord.closedEncounters.map(x =>
                clinical.fetchAmendments(x.id)
              )
            ])
          )
      ]);

      return pastProviders;
    };

    const filterEncounters = async (filter: PastVisitsFilterStateInternal) => {
      encounterFilter.current = filter;
      await clinicalRecord.loadClosedEncounters(
        mapEncounterFilterArgs(filter, clinicalRecord.patientPastReasonForVisit)
      );
    };

    const loadMore = async () => {
      await clinicalRecord.loadMoreClosedEncounters(
        mapEncounterFilterArgs(
          encounterFilter.current,
          clinicalRecord.patientPastReasonForVisit
        )
      );
    };

    const renderPastVisitText = (encounter: Encounter) => {
      return core.hasAccessToSecGroup(encounter.secGroupId) ? (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <Text block>
            <EncounterReasonForVisitText
              encounter={encounter}
              skipOtherText={true}
              styles={{
                root: {
                  color: theme?.palette.themeDarker,
                  fontWeight: FontWeights.semibold
                }
              }}
            />
          </Text>
          {hasClaimPermission && (
            <ConditionTextBadge episodeOfCareId={encounter.episodeOfCareId} />
          )}
        </Stack>
      ) : (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <TextWithIcon
            bold
            styles={{
              root: {
                color: theme?.palette.themeDarker,
                fontSize: FontSizes.size14
              }
            }}
            iconProps={{
              iconName: "ShieldAlert",
              styles: {
                root: {
                  fontSize: FontSizes.size14,
                  color: theme?.palette.themeDarker,
                  fontWeight: FontWeights.semibold
                }
              }
            }}
          >
            Confidential
          </TextWithIcon>
          {hasClaimPermission && (
            <ConditionTextBadge episodeOfCareId={encounter.episodeOfCareId} />
          )}
        </Stack>
      );
    };

    const renderDate = (encounter: Encounter) => {
      return <Text>{formatCalendarDate(encounter.startDateTime)}</Text>;
    };

    const renderUser = (encounter: Encounter) => {
      return (
        <>
          {encounter.user && (
            <Text
              block
              styles={(_props, theme) => ({
                root: { color: theme?.palette.neutralPrimaryAlt }
              })}
            >
              {encounter.user.fullName}
            </Text>
          )}
          {!encounter.user && (
            <Text
              block
              styles={(_props, theme) => ({
                root: { color: theme?.palette.neutralPrimaryAlt }
              })}
            >
              System
            </Text>
          )}
        </>
      );
    };

    const renderIcon = () => {
      return (
        <FontIcon
          iconName="PageEdit"
          styles={{
            root: {
              color: theme.palette.themePrimary,
              margin: 5
            }
          }}
        />
      );
    };

    const renderDateAndProvider = (encounter: Encounter) => {
      const amendments = clinical.amendments.filter(
        i => i.encounterId === encounter.id
      );

      return userExperience.settings.pastVisitsReasonAtBottom ? (
        <>
          <Stack horizontal verticalAlign="center">
            {renderDate(encounter)}
            {amendments.length > 0 && <>{renderIcon()}</>}
          </Stack>
          {renderUser(encounter)}
        </>
      ) : (
        <>
          <Stack horizontal verticalAlign="center">
            {amendments.length > 0 && <>{renderIcon()}</>}
            {renderDate(encounter)}
          </Stack>
          {renderUser(encounter)}
        </>
      );
    };

    const renderDetails = (encounter: Encounter): React.ReactNode => {
      return (
        <>
          {userExperience.settings.pastVisitsReasonAtBottom ? (
            <Stack>
              {renderDateAndProvider(encounter)}
              {renderPastVisitText(encounter)}
            </Stack>
          ) : (
            <Stack>
              {renderPastVisitText(encounter)}
              {renderDateAndProvider(encounter)}
            </Stack>
          )}
        </>
      );
    };

    const onRowClick = (id: string) => {
      const encounter = clinicalRecord?.closedEncounters?.find(
        enc => enc.id === id
      );

      if (core.hasAccessToSecGroup(encounter?.secGroupId)) {
        clinical.ui.tabs.currentPatientRecordTab!.sidePanelTabId = id;
      }
    };

    const renderPastVisit = (encounter: Encounter) => {
      return (
        <Stack styles={{ root: { height: "100%" } }}>
          <DataFetcher<TodaysNotes | undefined>
            fetch={() => clinicalRecord.getEncounterClinicalNotes(encounter.id)}
            refetchId={encounter.id}
          >
            {structuredNotes => (
              <PastVisitDetails
                encounter={encounter}
                todaysNotes={structuredNotes}
                clinicalRecord={clinicalRecord}
              />
            )}
          </DataFetcher>
        </Stack>
      );
    };

    const encounter =
      clinicalRecord.closedEncounters &&
      clinicalRecord.closedEncounters.find(
        x => x.id === clinical.ui.tabs.currentPatientRecordTab?.sidePanelTabId
      );

    if (!clinical.ui.tabs.currentPatientRecordTab?.sidePanelTabId) {
      return (
        <>
          <Heading
            variant="section-heading-light"
            styles={{ root: { padding: "5px 0" } }}
          >
            Past consults & updates
          </Heading>
          {isViewOnly && (
            <ActionButton
              text="Record update"
              iconProps={{
                iconName: "NewFolder"
              }}
              onClick={async () => {
                await clinicalRecord.close();
                routing.push(
                  routes.records.recordUpdate.path({ id: clinicalRecord.id }),
                  routing.getStateWithFromQuery()
                );
              }}
              styles={{ root: { alignSelf: "flex-start" } }}
            />
          )}
          <DataFetcher
            fetch={fetchData}
            fallback={
              <Shimmer
                styles={{ shimmerWrapper: { height: 48 } }}
                width="100%"
              />
            }
          >
            {providers => (
              <PastVisitsFilter
                setPastVisitsFilter={filterEncounters}
                providers={providers}
                initialFilterValues={encounterFilter.current}
                clinicalRecord={clinicalRecord}
              />
            )}
          </DataFetcher>

          <EventList
            items={mapEncounters(clinicalRecord.closedEncounters)}
            onLoadMore={loadMore}
            hasMore={clinicalRecord.hasMoreClosedEncounters}
            loading={clinicalRecord.nextClosedEncounterPromise.pending}
            loadingShimmer={loadingMoreShimmer}
            emptyListContent={<div>No past items found.</div>}
            onRowClick={onRowClick}
          />
        </>
      );
    }

    if (encounter) {
      return renderPastVisit(encounter);
    }

    return (
      <DataFetcher<Encounter | undefined>
        fetch={() =>
          clinical.getEncounter(
            clinical.ui.tabs.currentPatientRecordTab?.sidePanelTabId!
          )
        }
      >
        {enc => (enc ? renderPastVisit(enc) : "Past consult not found.")}
      </DataFetcher>
    );
  }
);
