import { observer } from "mobx-react-lite";

import { IndividualAndOrganisationNavigate } from "@modules/practice/screens/address-book/components/contacts-lists/Individual-organisation-columns/IndividualAndOrganisationNavigate.tsx";
import { PhoneCommunicationText } from "@modules/practice/screens/address-book/components/contacts-lists/PhoneCommunicationText.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export const IndividualOrOrganisationPhoneColumn = observer(
  ({ contact }: { contact: Contact }) => {
    const { practice } = useStores();
    return (
      <PhoneCommunicationText contact={contact}>
        <IndividualAndOrganisationNavigate
          contact={contact}
          onClick={() => practice.ui.showContactDetails(contact.id)}
        >
          Multiple numbers
        </IndividualAndOrganisationNavigate>
      </PhoneCommunicationText>
    );
  }
);
