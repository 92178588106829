import { Spinner } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolForm } from "../clinical-tool-form/ClinicalToolForm.tsx";
import { NPRSQuestions } from "./NPRSQuestions.tsx";

interface NPRSQuestionnaireFormProps {
  clinicalRecord: ClinicalRecord;
}

export const NPRSQuestionnaireForm: React.FunctionComponent<
  NPRSQuestionnaireFormProps
> = ({ clinicalRecord }) => {
  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.NPRSV1)}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <ClinicalToolForm
          clinicalRecord={clinicalRecord}
          clinicalDataType={ClinicalDataType.NPRS}
          formHeading="Numeric pain rating scale"
          questionnaire={questionnaire}
          summaryText="Average score indicates "
          boldDiagnosis
          formName="nprs"
        >
          <NPRSQuestions questionnaire={questionnaire} />
        </ClinicalToolForm>
      )}
    </DataFetcher>
  );
};
