import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Stack } from "@bps/fluent-ui";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { AppointmentNoticeItem } from "./AppointmentNoticeItem.tsx";

export enum NoticeIcon {
  noCharge = "Warning",
  providerComment = "CommentActive",
  comment = "Calendar",
  interpreter = "LocaleLanguage"
}

export const AppointmentNotices = observer(() => {
  const { appointmentNotices } = useContext(BookingCalendarEventContext);

  if (!appointmentNotices.length) return null;

  return (
    <Stack id="appt-notices" tokens={{ childrenGap: 12 }}>
      {appointmentNotices.map(notice => (
        <AppointmentNoticeItem
          key={`${notice.type}-${notice.description}`}
          appointmentNotice={notice}
        />
      ))}
    </Stack>
  );
});
