import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  predicate,
  required,
  requiredBoolean
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { ConditionModalFormValues } from "./Condition.types.ts";

export class ConditionModalFormValidator extends Validator<ConditionModalFormValues> {
  constructor(private core: CoreStore) {
    super();
    this.forField("private", requiredBoolean());

    this.forField(
      "referralNumber",
      predicate(
        (val, values?: ConditionModalFormValues) =>
          !!values?.referralIn &&
          !values.private &&
          this.core.hasPermissions(Permission.ClaimWrite),
        required()
      )
    );
    this.forField(
      "insurerContactId",
      predicate(
        (val, values?: ConditionModalFormValues) =>
          !values?.private && this.core.hasPermissions(Permission.ClaimWrite),
        required()
      )
    );
  }
}
