import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  IContextualMenuListProps,
  Spinner,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  BillingStatuses,
  InvoiceStatus
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { openNewInvoiceFromCalendar } from "@modules/booking/utils/booking.utils.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { invoiceStatusIcon } from "../BookingCalendarEvent.types.ts";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { ContextMenuItemsEnum } from "./ContextMenuItemsEnum.ts";

interface InvoiceSubMenuProps {
  listProps: IContextualMenuListProps | undefined;
  defaultRender?: (props: IContextualMenuListProps) => JSX.Element | null;

  contactId: string;
}

export const AttendeeInvoiceSubMenu: FunctionComponent<InvoiceSubMenuProps> =
  observer(({ listProps, defaultRender, contactId }) => {
    const theme = useTheme();
    const root = useStores();
    const { routing } = root;

    const { calendarEvent } = useContext(BookingCalendarEventContext);

    const subMenuItemsStyles = {
      color: theme?.palette.themePrimary,
      margin: "auto 4px"
    };

    const getIconProps = (
      invoiceExists: boolean,
      invoiceStatuse?: InvoiceStatus
    ) => {
      const styleProps = invoiceExists
        ? {
            iconName:
              invoiceStatusIcon[invoiceStatuse || InvoiceStatus.Settled],
            styles: {
              root: {
                ...subMenuItemsStyles,
                color: theme?.palette.neutralPrimary
              }
            }
          }
        : {
            iconName: "M365InvoicingLogo",
            styles: {
              root: {
                ...subMenuItemsStyles
              }
            }
          };
      return styleProps;
    };

    const onInvoiceClick = (invoiceExists: boolean, invoiceId?: string) => {
      if (invoiceExists && invoiceId) {
        routing.push(
          routes.accounts.invoices.invoice.path({
            id: invoiceId
          })
        );
      } else {
        openNewInvoiceFromCalendar(root, calendarEvent.id, contactId);
      }
    };

    const getInvoiceSubMenuItem = (attendeeId: string) => {
      return {
        ...dataAttribute(
          DataAttributes.Element,
          `booking-event-context-invoice-btn-${contactId}`
        ),
        key: "invoice",
        text: ContextMenuItemsEnum.Invoice,
        onRenderContent: () => (
          <DataFetcher<{
            invoice?: Invoice;
            invoiceStatus?: InvoiceStatus;
          }>
            fetch={async root => {
              const extensionAttendee =
                calendarEvent.getCalendarEventExtensionAttendee(attendeeId);

              const { invoiceId, invoiceStatus } = extensionAttendee || {};

              const invoice = invoiceId
                ? await root.billing.getInvoice(invoiceId)
                : undefined;
              return { invoice, invoiceStatus };
            }}
            fallback={<Spinner />}
          >
            {({ invoice, invoiceStatus }) => {
              const { id: invoiceId, status } = invoice || {};

              const invoiceExists =
                !!invoiceId && !!status && status !== BillingStatuses.cancelled;

              const iconProps = getIconProps(invoiceExists, invoiceStatus);
              return (
                <Stack
                  horizontal
                  tokens={{ childrenGap: 4 }}
                  styles={{ root: { width: "100%" } }}
                  onClick={() => onInvoiceClick(invoiceExists, invoiceId)}
                >
                  <FontIcon
                    styles={iconProps.styles}
                    iconName={iconProps.iconName}
                  />
                  <Stack.Item>{ContextMenuItemsEnum.Invoice}</Stack.Item>
                </Stack>
              );
            }}
          </DataFetcher>
        )
      };
    };

    if (defaultRender && listProps) {
      return defaultRender({
        ...listProps,
        items: [getInvoiceSubMenuItem(contactId)]
      });
    }

    return null;
  });
