import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  Length,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { DiagnosisSideData } from "@shared-types/clinical/diagnosis-side-data.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

const diagnosisNameOf = nameOfFactory<DiagnosisSideData>();

export class DiagnosisCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();

    const primaryValidator = new PrimaryDiagnosisSideFieldsValidator();
    const additionalValidator = new AdditionalDiagnosisSideFieldsValidator();

    this.forArrayField(
      sotapNameOf("primaryDiagnosis"),
      primaryValidator.validate
    );
    this.forArrayField(
      sotapNameOf("additionalDiagnoses"),
      additionalValidator.validate
    );
    this.forField(sotapNameOf("diagnosisComment"), [
      maxLength(Length.comments)
    ]);
  }
}

export class AdditionalDiagnosisSideFieldsValidator extends Validator<DiagnosisSideData> {
  constructor() {
    super();
    this.forField(diagnosisNameOf("diagnosisKey"), [
      predicate((_value, parent: DiagnosisSideData) => {
        return !!parent?.side;
      }, required())
    ]);

    this.forField(diagnosisNameOf("side"), [
      predicate((_value, parent: DiagnosisSideData) => {
        return !!parent?.diagnosis;
      }, required())
    ]);
  }
}

export class PrimaryDiagnosisSideFieldsValidator extends AdditionalDiagnosisSideFieldsValidator {
  constructor() {
    super();
    this.forField(diagnosisNameOf("diagnosisKey"), [
      predicate((_value, _parent: DiagnosisSideData, root: SOTAPFormValues) => {
        return !!root.multipleDiagnoses;
      }, required())
    ]);

    this.forField(diagnosisNameOf("side"), [
      predicate((_value, _parent: DiagnosisSideData, root: SOTAPFormValues) => {
        return !!root.multipleDiagnoses;
      }, required())
    ]);
  }
}
