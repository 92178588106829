import {
  dataAttribute,
  DataAttributes,
  Stack,
  TextBadge,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import {
  BillingStatuses,
  InvoiceItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import {
  getInvoiceAllocationStatusBadgeColor,
  getStatusesItem
} from "@stores/billing/utils/invoice.utils.ts";

import { InvoiceStatusTooltip } from "./InvoiceStatusTooltip.tsx";

interface InvoiceBadgeProps {
  invoice?: Invoice;
  invoiceItem?: InvoiceItemDto;
}
export const InvoiceBadge: React.FC<InvoiceBadgeProps> = ({
  invoice,
  invoiceItem
}) => {
  const theme = useTheme();

  const statuses = invoiceItem
    ? getStatusesItem(invoiceItem)
    : invoice?.allocationStatuses;

  if (!statuses?.length) {
    return null;
  }

  const primaryStatus = statuses[0];

  return (
    <Stack horizontal tokens={{ childrenGap: 4 }} verticalAlign="center">
      <TextBadge
        styles={{
          root: invoice
            ? { width: 108 }
            : {
                paddingLeft: 0,
                paddingRight: 0,
                alignItems: "center",
                backgroundColor: "transparent",
                color:
                  invoiceItem?.status === BillingStatuses.cancelled
                    ? theme.palette.neutralSecondary
                    : undefined
              }
        }}
        badgeColor={getInvoiceAllocationStatusBadgeColor(primaryStatus)}
        badgeSize={TextBadgeSize.medium}
        {...dataAttribute(DataAttributes.Element, "invoice-badge")}
      >
        {primaryStatus}
      </TextBadge>
      <InvoiceStatusTooltip invoice={invoice} invoiceItem={invoiceItem} />
    </Stack>
  );
};
