import React from "react";

import {
  AnimatedList,
  Checkbox,
  DefaultButton,
  Heading,
  IContextualMenuItem,
  IContextualMenuItemProps,
  Stack,
  toKebabCase,
  useAnimationKeys
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { AccSetupFormValues } from "../health-certificate/AccSetupForm.tsx";
import {
  AccPracticeContractLabels,
  PracticeDetailsFormLabels
} from "../practice-details/PracticeDetailsForm.types.tsx";
import { PracticeAccContractsStyles } from "./PracticeAccContracts.styles.tsx";
import { PracticeAccContractsNoDataTile } from "./PracticeAccContractsNoDataTile.tsx";

const nameOf = nameOfFactory<AccSetupFormValues>();

export const PracticeAccContracts: React.FC = () => {
  const {
    core,
    practice: {
      ref: { accPracticeContractTypes: practiceContractTypeRef }
    }
  } = useStores();

  const readOnly = !core.hasPermissions(Permission.OrgUnitSettingWrite);

  const practiceContractFields = useFieldArray("accContracts").fields;

  const [animationKeys, handleOnAnimationKeyRemove] = useAnimationKeys(
    practiceContractFields.value,
    "accPracticeContractTypeCode"
  );

  const addItem = (key: string) => {
    practiceContractFields.push({
      accPracticeContractTypeCode: key,
      contractId: ""
    });
  };

  const getAddMoreItems = (): IContextualMenuItem[] =>
    practiceContractTypeRef.keyTextValues.map(({ key, text }) => {
      return {
        canCheck: true,
        checked: practiceContractFields.value.some(
          i => i.accPracticeContractTypeCode === key
        ),
        key,
        text,
        onClick: (_, option: IContextualMenuItem) => {
          option.checked = !option.checked;
          if (option.checked) {
            addItem(option.key);
          } else {
            handleOnAnimationKeyRemove(key)();
          }
        }
      } as IContextualMenuItem;
    });

  const orderedKeys = practiceContractTypeRef.keyTextValues
    .filter(item => animationKeys.includes(item.key))
    .map(item => item.key);

  const findFieldsIndex = (key: string) =>
    practiceContractFields.value.findIndex(
      v => v.accPracticeContractTypeCode === key
    );

  return (
    <Stack styles={PracticeAccContractsStyles.root}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={PracticeAccContractsStyles.heading}
      >
        <Heading variant="section-heading-light">
          {PracticeDetailsFormLabels.accContracts}
        </Heading>
        {practiceContractFields.value.length > 0 && (
          <DefaultButton
            disabled={readOnly}
            iconProps={{ iconName: PracticeDetailsFormLabels.add }}
            text={PracticeDetailsFormLabels.addMore}
            menuProps={{
              styles: PracticeAccContractsStyles.buttonMenuProps,
              shouldFocusOnMount: false,
              items: getAddMoreItems(),
              contextualMenuItemAs: (props: IContextualMenuItemProps) => (
                <Checkbox
                  automationAttribute={
                    props.item.text
                      ? toKebabCase(props.item.text)
                      : "no-text-acc-contractor-checkbox"
                  }
                  label={props.item.text}
                  defaultChecked={props.item.checked}
                />
              )
            }}
          />
        )}
      </Stack>
      <Stack tokens={{ childrenGap: 10 }}>
        <AnimatedList
          animationKeys={orderedKeys}
          onAnimationKeyRemoved={animationKey => {
            const index = findFieldsIndex(animationKey);
            practiceContractFields.remove(index);
          }}
        >
          {animationKey => {
            const index = findFieldsIndex(animationKey);
            return (
              <Stack
                key={animationKey}
                horizontal
                tokens={{ childrenGap: 10 }}
                styles={PracticeAccContractsStyles.contractTypeItem}
              >
                <TextInputField
                  readOnly={readOnly}
                  label={AccPracticeContractLabels[animationKey]}
                  name={`${nameOf("accContracts")}[${index}].contractId`}
                />
                <DeleteButton
                  styles={PracticeAccContractsStyles.deleteButton}
                  onClick={() => handleOnAnimationKeyRemove(animationKey)()}
                  disabled={readOnly}
                />
              </Stack>
            );
          }}
        </AnimatedList>
      </Stack>
      <PracticeAccContractsNoDataTile
        onTileItemClicked={addItem}
        show={!practiceContractFields.value.length}
      />
    </Stack>
  );
};
