import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useRef } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Heading,
  IContextualMenuItem,
  Stack
} from "@bps/fluent-ui";
import { CorrespondencesTestElements } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  CorrespondencesContext,
  useCorrespondencesHelper
} from "../correspondence/context/CorrespondencesContext.tsx";
import { CorrespondencesHelper } from "../correspondence/context/CorrespondencesHelper.ts";
import { MoveDocumentDialog } from "../MoveDocumentDialog.tsx";
import { CorrespondencePrint } from "../patient-summary/CorrespondencePrint.tsx";
import { SendClinicalDocumentDialog } from "../SendClinicalDocumentDialog.tsx";
import { SidePanelDocumentPreviewer } from "../SidePanelDocumentPreviewer.tsx";
import { DeleteCorrespondenceDialog } from "../SOTAP/DeleteCorrespondenceDialog.tsx";
import { CorrespondenceDialog } from "./CorrespondenceDialog.tsx";
import { CorrespondencesLabels } from "./Correspondences.types.ts";
import { CorrespondencesFilter } from "./CorrespondencesFilter.tsx";
import { CorrespondencesList } from "./CorrespondencesList.tsx";

export interface CorrespondencesProps {
  clinicalRecord: ClinicalRecord;
}

const CorrespondencesComponent: FunctionComponent = observer(() => {
  const { clinical } = useStores();

  const {
    documentViewerVisible,
    editDialogVisible,
    handleDismissDetails,
    handleDismissMoveDialog,
    handleDismissSendDialog,
    handleOnConflictError,
    isDeleteDialogVisible,
    isPrinting,
    moveDialogVisible,
    onDeleteConfirm,
    selectedClinicalDocument,
    setDocumentViewerVisible,
    setIsDeleteDialogVisible,
    setIsPrinting,
    setUploadDialogVisible,
    setSendDialogVisible,
    uploadDialogVisible,
    sendDialogVisible,
    onMoveToAnotherPatientSucceeded
  } = useCorrespondencesHelper();

  const { clinicalRecord, isViewOnlyOrDischarged } =
    usePatientRecordScreenContext();

  const showCorrespondeceDialog = uploadDialogVisible || editDialogVisible;

  useEffect(() => {
    clinicalRecord.loadPatient();
    clinicalRecord.loadPatientUserTasks();
  }, [clinicalRecord]);

  const completePrinting = () => {
    setIsPrinting(false);
  };

  const onDeleteCancel = () => {
    setIsDeleteDialogVisible(false);
  };

  const onUploadButtonClick = () => {
    setUploadDialogVisible(true);
  };

  const onCreateDocumentButtonClick = () => {
    clinicalRecord.setDocumentDialogVisible(true);
  };

  const renderDownloadButton = (handleDownload: () => void) => {
    const menuItems: IContextualMenuItem[] = [
      {
        key: "download",
        text: "Download",
        onClick: handleDownload
      }
    ];

    return (
      <DefaultButton
        onClick={() => {
          setSendDialogVisible(true);
        }}
        text="Email"
        iconProps={{ iconName: "Mail" }}
        menuProps={{ items: menuItems }}
        split
      />
    );
  };

  return (
    <>
      <Heading
        variant="section-heading-light"
        styles={{ root: { padding: "5px 0" } }}
      >
        Correspondence
      </Heading>
      <Stack
        grow
        styles={{
          root: {
            height: 0
          }
        }}
      >
        {!isViewOnlyOrDischarged && (
          <Stack.Item>
            <ActionButton
              {...dataAttribute(
                DataAttributes.Element,
                CorrespondencesTestElements.UploadButton
              )}
              text={CorrespondencesLabels.Upload}
              iconProps={{
                iconName: "Add"
              }}
              onClick={onUploadButtonClick}
            />

            <ActionButton
              {...dataAttribute(
                DataAttributes.Element,
                CorrespondencesTestElements.CreateButton
              )}
              text={CorrespondencesLabels.Create}
              iconProps={{
                iconName: "PageAdd"
              }}
              onClick={onCreateDocumentButtonClick}
            />
          </Stack.Item>
        )}
        <CorrespondencesFilter>
          {() => <CorrespondencesList patientId={clinicalRecord.id} />}
        </CorrespondencesFilter>

        {showCorrespondeceDialog && (
          <CorrespondenceDialog
            correspondence={
              editDialogVisible ? selectedClinicalDocument : undefined
            }
            onDismiss={handleDismissDetails}
            onConflictError={handleOnConflictError}
          />
        )}
        {isPrinting &&
          selectedClinicalDocument &&
          selectedClinicalDocument.id && (
            <CorrespondencePrint
              completePrinting={completePrinting}
              clinicalDocument={selectedClinicalDocument}
              documentTitle={selectedClinicalDocument.name}
            />
          )}
        {documentViewerVisible && selectedClinicalDocument && (
          <SidePanelDocumentPreviewer
            setViewerVisible={setDocumentViewerVisible}
            record={selectedClinicalDocument}
            renderDownloadButton={renderDownloadButton}
          />
        )}
        {selectedClinicalDocument && (
          <MoveDocumentDialog
            hidden={!moveDialogVisible}
            onDismiss={handleDismissMoveDialog}
            handleOnConflictError={handleOnConflictError}
            onSubmitSucceeded={onMoveToAnotherPatientSucceeded}
            clinicalRecord={clinicalRecord}
            document={selectedClinicalDocument}
          />
        )}
      </Stack>

      <DeleteCorrespondenceDialog
        hidden={!isDeleteDialogVisible}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
        item={selectedClinicalDocument}
      />

      {sendDialogVisible && selectedClinicalDocument && (
        <SendClinicalDocumentDialog
          onDismiss={handleDismissSendDialog}
          onSubmitSucceeded={handleDismissSendDialog}
          onSubmit={async ({ email }) => {
            await clinical.sendClinicalDocument({
              documentId: selectedClinicalDocument.id,
              patientId: selectedClinicalDocument.patientId,
              email
            });
          }}
          patientId={selectedClinicalDocument.patientId}
          documentTitle={selectedClinicalDocument.name}
          extension={selectedClinicalDocument.extension}
        />
      )}
    </>
  );
});

const CorrespondencesBase: FunctionComponent<CorrespondencesProps> = props => {
  const root = useStores();
  const helper = useRef(new CorrespondencesHelper(root, props.clinicalRecord));

  return (
    <CorrespondencesContext.Provider value={helper.current}>
      <CorrespondencesComponent />
    </CorrespondencesContext.Provider>
  );
};

export const Correspondences = withFetch(
  x => [
    x.correspondence.ref.correspondenceTypes.load(),
    x.correspondence.ref.correspondenceDirections.load(),
    x.correspondence.ref.correspondenceStatuses.load(),
    x.inbox.ref.outcomes.load(),
    x.inbox.ref.reportTypes.load(),
    x.core.loadAllUsers()
  ],
  CorrespondencesBase
);
