// eslint-disable-next-line import/extensions
import { Canvas } from "fabric/fabric-impl";
import { useField, useFormState } from "react-final-form";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { DrawingCanvas } from "../../notes/draw/DrawingCanvas.tsx";
import { InjuryImagesHelper } from "../context/InjuryImagesHelper.tsx";
import { sotapNameOf } from "../SOTAP.utils.ts";

enum ElbowImageType {
  LeftElbow = "0",
  RightElbow = "1"
}

export const ElbowInjuryImageAssessment: React.FC = () => {
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const { initialValues } = useFormState<SOTAPFormValues>({
    subscription: { initialValues: true }
  });

  const sex = clinicalRecord.patient?.sex;
  const {
    input: { onChange: onLeftElbowImage }
  } = useField(sotapNameOf("leftElbowImage"));

  const {
    input: { onChange: onRightElbowImage }
  } = useField(sotapNameOf("rightElbowImage"));

  const elbowProps = InjuryImagesHelper.getElbowProperties(sex);
  const images = InjuryImagesHelper.mapImagesOnPart(elbowProps, initialValues);

  const handleOnChange = (
    canvas: Canvas,
    event: any,
    onChangeFunc: (x: any) => void
  ) => {
    if (event.target.dirty) {
      onChangeFunc(canvas?.toSVG());
    } else {
      onChangeFunc(undefined);
    }
  };
  return (
    <DrawingCanvas
      isViewOnly={
        isViewOnlyOrDischarged ||
        clinicalRecord.dischargeInProgressOrCompleted()
      }
      showQuickColourPicker={!isViewOnlyOrDischarged}
      initialSelectedImage={0}
      caption="Diagram"
      images={images}
      onChange={(canvas: Canvas, event: any) => {
        switch (canvas["lowerCanvasEl"].id as ElbowImageType) {
          case ElbowImageType.LeftElbow:
            handleOnChange(canvas, event, onLeftElbowImage);
            break;
          case ElbowImageType.RightElbow:
            handleOnChange(canvas, event, onRightElbowImage);
            break;
          default:
            return;
        }
      }}
    />
  );
};
