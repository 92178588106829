import { isHL7, isImage } from "@libs/utils/file.utils.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { PDFViewer } from "@ui-components/document-viewer/PDFViewerLazy.tsx";

import { HL7Preview } from "./HL7Preview.tsx";

export type DocumentPreviewProps = {
  previewDocument: InboxDocument;
};

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  previewDocument
}) => {
  if (previewDocument.docExtension && isHL7(previewDocument.docExtension)) {
    return <HL7Preview previewDocument={previewDocument} />;
  }

  return (
    <PDFViewer
      pdfUrl={previewDocument.previewUri!}
      documentTitle={previewDocument.name!}
      isImage={
        !!previewDocument.docExtension && isImage(previewDocument.docExtension)
      }
    />
  );
};
