import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { dataAttribute, DataAttributes, Heading } from "@bps/fluent-ui";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { SubmissionFormDialogProps } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.types.ts";

import { ScheduleFormValues } from "./ScheduleForm.types.tsx";
import { ScheduleFormFields } from "./ScheduleFormFields.tsx";
import { getFormValuesFromSchedule } from "./utils.ts";
import { ScheduleFormValidator } from "./validators/ScheduleFormValidator.tsx";

const validator = new ScheduleFormValidator();

type FormProps = SubmissionFormDialogProps<ScheduleFormValues>;

export const ScheduleFormDialog: React.FC = observer(() => {
  const {
    closeEditScheduleDialog,
    editSchedule,
    isEditScheduleDialogVisible,
    submitEditScheduleValues,
    canEditSchedule
  } = useContext(ScheduleScreenContext);

  const initialValues = getFormValuesFromSchedule(editSchedule);

  if (!isEditScheduleDialogVisible) {
    return null;
  }

  const buttonProps: FormProps["buttonsProps"] = {
    submitButtonProps: { text: "Save schedule", iconProps: {} },
    cancelButtonProps: { id: "schedule-form-cancel-btn" },
    hideButtonsSeparator: true
  };

  const dialogProps: FormProps["dialogProps"] = {
    onDismiss: closeEditScheduleDialog,
    maxWidth: 600,
    dialogContentProps: {
      title: (
        <Heading
          variant="modal-heading"
          {...dataAttribute(
            DataAttributes.Element,
            "schedule-form-dialog-heading"
          )}
        >
          {`Edit ${editSchedule?.name || "schedule"}`}
        </Heading>
      ),
      showCloseButton: true
    }
  };

  return (
    <SubmissionFormDialog<ScheduleFormValues>
      dialogName="Schedule form dialog"
      onSubmit={values => submitEditScheduleValues(values)}
      initialValues={initialValues}
      validate={validator.validate}
      buttonsProps={buttonProps}
      dialogProps={dialogProps}
      readOnly={!canEditSchedule}
    >
      {() => <ScheduleFormFields />}
    </SubmissionFormDialog>
  );
});
