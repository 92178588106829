import { FC } from "react";

import { IconButton, TooltipHost } from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { PhysitrackApiKeyDto } from "@libs/gateways/custom-inegration/CustomIntegrationGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { PhysitrackProviderItem } from "./PhysitrackProviderList.tsx";

interface PhysitrackProviderMenuProps {
  item: PhysitrackProviderItem;
}

export const PhysitrackProviderMenu: FC<PhysitrackProviderMenuProps> = ({
  item
}) => {
  const { notification, customIntegrations } = useStores();

  if (!item.apiKey) {
    return null;
  }

  const copyApiKeyToClipboard = () =>
    executeAndNotify<void>(
      () => navigator.clipboard.writeText(item.apiKey!),
      notificationMessages.apiKeyCopied
    );

  const handleApiKeyUpdate = async () =>
    executeAndNotify<PhysitrackApiKeyDto>(
      () => customIntegrations.regeneratePhysitrackApiKey(item.id),
      notificationMessages.apiKeyUpdated
    );

  const handleApiKeyDelete = async () =>
    executeAndNotify<void>(
      () => customIntegrations.deletePhysitrackApiKey(item.id),
      notificationMessages.apiKeyDeleted
    );

  const handleEmailApiKeyClick = () =>
    executeAndNotify<void>(
      () => customIntegrations.emailPhysitrackApiKey(item.id),
      notificationMessages.emailIsBeingSent
    );

  async function executeAndNotify<Type>(
    operation: () => Promise<Type>,
    successMessage: string
  ) {
    try {
      await operation();
      notification.success(successMessage);
    } catch (e) {
      notification.error(e.message);
    }
  }

  return (
    <TooltipHost
      content="More"
      styles={{
        root: {
          justifyContent: "center",
          verticalAlign: "center",
          display: "flex"
        }
      }}
    >
      <IconButton
        menuIconProps={{ iconName: "More" }}
        menuProps={{
          items: [
            {
              key: "copy",
              text: "Copy API Key",
              onClick: () => {
                copyApiKeyToClipboard();
              }
            },
            {
              key: "update",
              text: "Update",
              onClick: handleApiKeyUpdate
            },
            {
              key: "remove",
              text: "Remove",
              onClick: handleApiKeyDelete
            },
            {
              key: "email",
              text: "Email",
              onClick: handleEmailApiKeyClick
            }
          ]
        }}
        styles={{
          root: { width: "32px", height: "36px", padding: 0 },
          flexContainer: { width: "32px", height: "36px" }
        }}
      />
    </TooltipHost>
  );
};
