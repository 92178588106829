import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext, useEffect, useRef } from "react";

import {
  FontIcon,
  Heading,
  IPivotItemProps,
  Pivot,
  PivotItem,
  useTheme
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  InvestigationsSectionKeys,
  InvestigationsSectionText
} from "../../Investigations.Types.ts";
import { InvestigationSidePanelHelper } from "./InvestigationSidePanelHelper.ts";
import { InvestigationSidePanelContext } from "./InvestigationsSidePanelContext.ts";
import { InvestigationsSidePanelDialogs } from "./InvestigationsSidePanelDialogs.tsx";
import { ReportsList } from "./ReportsList.tsx";
import { RequestsList } from "./RequestsList.tsx";

export interface InvestigationsSidePanelProps {
  clinicalRecord: ClinicalRecord;
}

const SidePanel: FunctionComponent<InvestigationsSidePanelProps> = observer(
  ({ clinicalRecord }) => {
    const theme = useTheme();

    const {
      handleViewClick,
      pageSelected,
      onCreateReportClick,
      onCreateRequestClick
    } = useContext(InvestigationSidePanelContext);

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
    useEffect(() => {
      clinicalRecord.loadPatientUserTasks();
    }, [clinicalRecord]);

    const renderItemLink = (
      onLinkClick: () => void,
      link?: IPivotItemProps,
      defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
    ) => {
      if (defaultRenderer)
        return (
          <>
            {!isViewOnlyOrDischarged && (
              <FontIcon
                iconName="Add"
                onClick={onLinkClick}
                styles={{
                  root: {
                    marginRight: 8,
                    fontSize: 16,
                    color: theme.palette.themePrimary
                  }
                }}
              />
            )}
            {defaultRenderer({ ...link, itemIcon: undefined })}
          </>
        );
      else return null;
    };

    return (
      <>
        <Heading variant="section-heading-light" labelPaddings={true}>
          Investigations
        </Heading>
        <Pivot onLinkClick={handleViewClick} selectedKey={pageSelected}>
          <PivotItem
            headerText={InvestigationsSectionText.Reports}
            itemKey={InvestigationsSectionKeys.Reports}
            onRenderItemLink={(link, defaultRenderer) =>
              renderItemLink(onCreateReportClick, link, defaultRenderer)
            }
          />
          <PivotItem
            headerText={InvestigationsSectionText.Requests}
            itemKey={InvestigationsSectionKeys.Requests}
            itemIcon="Add"
            onRenderItemLink={(link, defaultRenderer) =>
              renderItemLink(onCreateRequestClick, link, defaultRenderer)
            }
          />
        </Pivot>

        {pageSelected === InvestigationsSectionKeys.Reports && <ReportsList />}
        {pageSelected === InvestigationsSectionKeys.Requests && (
          <RequestsList />
        )}

        <InvestigationsSidePanelDialogs />
      </>
    );
  }
);

const SidePanelWrapper: React.FC<InvestigationsSidePanelProps> = ({
  ...props
}) => {
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
  const helper = useRef(
    new InvestigationSidePanelHelper(
      props.clinicalRecord,
      useStores(),
      isViewOnlyOrDischarged
    )
  );

  return (
    <InvestigationSidePanelContext.Provider value={helper.current}>
      <SidePanel {...props} />
    </InvestigationSidePanelContext.Provider>
  );
};

export const InvestigationsSidePanel = withFetch(
  x => [
    x.correspondence.ref.correspondenceTypes.load(),
    x.correspondence.ref.correspondenceDirections.load(),
    x.correspondence.ref.correspondenceStatuses.load(),
    x.inbox.ref.outcomes.load()
  ],
  SidePanelWrapper
);
