import { FunctionComponent } from "react";

import { NoDataTile } from "@bps/fluent-ui";

import { EditEmployersViewModel } from "./EditEmployersViewModel.ts";

export interface EmployerNoDataProps {
  model: EditEmployersViewModel;
}

export const EmployerNoData: FunctionComponent<EmployerNoDataProps> = ({
  model
}) => {
  return (
    <NoDataTile
      textProps={{
        text: "No employers"
      }}
      linkProps={{
        text: "Add an employer for this person",
        onClick: () => model.addEmployer()
      }}
    />
  );
};
