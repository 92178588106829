import { DateTime, upsertItem } from "@bps/utils";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  CodedFieldClinicalDataItemDto,
  EncounterClinicalDataDto,
  MedicalHistoryClinicalDataItemDto,
  SideOfBody,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { deleteItemFromClinicalDataArray } from "@stores/clinical/utils/clinical.utils.ts";

import { MedicalHistoryStatus } from "./MedicalHistory.types.ts";
import { getMedicalHistoryDateValues } from "./MedicalHistoryDateFields.tsx";
import { MedicalHistoryFilterType } from "./MedicalHistoryFilter.types.ts";
import {
  MedicalHistoryDateFieldsValues,
  MedicalHistoryDateKey,
  MedicalHistoryFormValues
} from "./MedicalHistoryFormValues.ts";

interface AddMedicalHistoryFromFormArgs {
  clinicalRecord: ClinicalRecord;
  medicalHistoryValues: MedicalHistoryFormValues;
  clinicalStore: ClinicalStore;
  isDiagnosis?: boolean;
  isProcedure?: boolean;
  episodeOfCareId?: string;
  confidential: boolean;
  secGroupId?: string;
}

interface EditMedicalHistoryFromFormArgs extends AddMedicalHistoryFromFormArgs {
  id: string;
}

interface EditMedicalHistoryArgs {
  clinicalRecord: ClinicalRecord;
  id: string;
  medicalHistoryItem: MedicalHistoryClinicalDataItemDto;
  episodeOfCareId?: string;
}

interface DeleteMedicalHistoryFromFormArgs {
  clinicalRecord: ClinicalRecord;
  id: string;
  reasonForDelete: string;
  deletedComment?: string;
}

/**
 * Add medical history item from add medical history form
 */
export const addMedicalHistoryItemFromForm = async (
  args: AddMedicalHistoryFromFormArgs
) => {
  const {
    clinicalRecord,
    medicalHistoryValues,
    clinicalStore,
    isDiagnosis,
    isProcedure,
    episodeOfCareId,
    confidential,
    secGroupId
  } = args;

  const { birthDate } = await clinicalRecord.loadPatient();
  const { saveAsReasonForVisit, active, diagnosisKey, ...otherValues } =
    medicalHistoryValues;

  const term =
    diagnosisKey && clinicalStore.getTerminologyFromMap(diagnosisKey);
  let diagnosisCode: CodedFieldClinicalDataItemDto | undefined;

  if (term)
    diagnosisCode = {
      code: term.code,
      originalText: term.text,
      codeSystem: term.codeSystem,
      version: term.version
    };

  const newItem: Omit<MedicalHistoryClinicalDataItemDto, "id"> = {
    active: !!active,
    clinicallySignificant: medicalHistoryValues.clinicallySignificant,
    details: medicalHistoryValues.details!,
    diagnosis: diagnosisCode?.code,
    diagnosisCode,
    diagnosisSide: getSideOfBodyKey(medicalHistoryValues.diagnosisSideSelected),
    certainty: medicalHistoryValues.certainty!,
    severity: medicalHistoryValues.severity!,
    fracture: medicalHistoryValues.fracture!,
    chronicity: medicalHistoryValues.chronicity!,
    fractureTypes: medicalHistoryValues.fractureTypes!,
    isDiagnosis,
    isProcedure,
    episodeOfCareId,
    isPrimary:
      medicalHistoryValues.linkToCondition && medicalHistoryValues.isPrimary,
    secGroupId: confidential ? secGroupId : undefined,
    ...getMedicalHistoryDateValues(otherValues, birthDate?.toJSDate())
  };

  const medicalHistories = clinicalRecord.medicalHistories;

  let updatedMedicalHistories: MedicalHistoryClinicalDataItemDto[] =
    medicalHistories;
  if (
    clinicalRecord.core.hasPermissions(
      Permission.MedicalHistoryDiagnosisSyncAllowed
    )
  ) {
    if (medicalHistoryValues.isPrimary) {
      // removes the isPrimary from the other medicalHistory but the diagnosis still remains linked to the condition
      updatedMedicalHistories = medicalHistories.map(x => {
        if (
          x.isPrimary &&
          x.episodeOfCareId === clinicalRecord.episodeOfCare?.id
        ) {
          return { ...x, isPrimary: undefined };
        }
        return x;
      });
    }
  } else {
    //Unlink the linked medicalHisotry if it exist
    updatedMedicalHistories = medicalHistories.map(x => {
      if (x.episodeOfCareId === episodeOfCareId) {
        return { ...x, episodeOfCareId: undefined };
      }
      return x;
    });
  }

  const encounterClinicalData: EncounterClinicalDataDto = {
    medicalHistory: {
      eTag: clinicalRecord.clinicalData?.medicalHistory?.eTag,
      noSignificantHistory: false,
      medicalHistories: [...updatedMedicalHistories, newItem]
    }
  };

  return encounterClinicalData;
};

/**
 * Edit medical history item from dto data
 */
export const editMedicalHistoryItem = (args: EditMedicalHistoryArgs) => {
  const { id, clinicalRecord, medicalHistoryItem, episodeOfCareId } = args;

  const medicalHistories = upsertItem({
    array: clinicalRecord.medicalHistories,
    item: medicalHistoryItem,
    predicate: x => x.id === id
  });

  let updatedMedicalHistories: MedicalHistoryClinicalDataItemDto[] =
    medicalHistories;
  if (
    clinicalRecord.core.hasPermissions(
      Permission.MedicalHistoryDiagnosisSyncAllowed
    )
  ) {
    if (medicalHistoryItem.isPrimary) {
      // removes the isPrimary from the other medicalHistory but the diagnosis still remains linked to the condition
      updatedMedicalHistories = medicalHistories.map(x => {
        if (
          x.isPrimary &&
          x.episodeOfCareId === clinicalRecord.episodeOfCare?.id &&
          x.id !== id
        ) {
          return { ...x, isPrimary: undefined };
        }
        return x;
      });
    }
  } else {
    //Unlink the linked medicalHisotry if it exist
    updatedMedicalHistories = medicalHistories.map(x => {
      if (x.episodeOfCareId === episodeOfCareId && x.id !== id) {
        return { ...x, episodeOfCareId: undefined };
      }
      return x;
    });
  }

  const encounterClinicalData: EncounterClinicalDataDto = {
    medicalHistory: {
      eTag: clinicalRecord.clinicalData?.medicalHistory?.eTag,
      noSignificantHistory: false,
      medicalHistories: updatedMedicalHistories
    }
  };

  return encounterClinicalData;
};

/**
 * Edit medical history item from edit medical history form
 */
export const editMedicalHistoryItemFromForm = async (
  args: EditMedicalHistoryFromFormArgs
) => {
  const {
    id,
    clinicalRecord,
    medicalHistoryValues,
    episodeOfCareId,
    confidential,
    secGroupId
  } = args;

  const { birthDate } = await clinicalRecord.loadPatient();

  const currentMedicalHistoryItem = clinicalRecord.medicalHistories.find(
    x => x.id === id
  );

  const medicalHistoryItem: MedicalHistoryClinicalDataItemDto = {
    ...currentMedicalHistoryItem,
    id,
    active: !!medicalHistoryValues.active,
    certainty: medicalHistoryValues.certainty,
    diagnosisSide: getSideOfBodyKey(medicalHistoryValues.diagnosisSideSelected),
    diagnosisSideSelected: medicalHistoryValues.diagnosisSideSelected,
    clinicallySignificant: medicalHistoryValues.clinicallySignificant,
    chronicity: medicalHistoryValues.chronicity,
    severity: medicalHistoryValues.severity,
    fracture: medicalHistoryValues.fracture,
    fractureTypes: medicalHistoryValues.fractureTypes,
    details: medicalHistoryValues.details,
    episodeOfCareId,
    isPrimary:
      medicalHistoryValues.linkToCondition && medicalHistoryValues.isPrimary,
    secGroupId: confidential ? secGroupId : undefined,
    ...getMedicalHistoryDateValues(medicalHistoryValues, birthDate?.toJSDate())
  };

  return editMedicalHistoryItem({
    clinicalRecord,
    id,
    medicalHistoryItem,
    episodeOfCareId
  });
};

/**
 * Delete medical history item from an id
 */
export const deleteMedicalHistoryItem = async (
  args: DeleteMedicalHistoryFromFormArgs
) => {
  const { id, reasonForDelete, deletedComment, clinicalRecord } = args;

  const medicalHistories = deleteItemFromClinicalDataArray({
    array: clinicalRecord.medicalHistories,
    id,
    deletedComment,
    reasonForDelete
  });

  const encounterClinicalData: EncounterClinicalDataDto = {
    medicalHistory: {
      eTag: clinicalRecord.clinicalData?.medicalHistory?.eTag,
      noSignificantHistory: false,
      medicalHistories
    }
  };

  return encounterClinicalData;
};

/**
 * Sorts Medical History dates by newest to oldest. Histories with no dates are shown last, and ordered by name
 */
export const sortMedicalHistoryClinicalDates = (
  dates: MedicalHistoryClinicalDataItemDto[]
): MedicalHistoryClinicalDataItemDto[] => {
  const filteredMedicalHistories = dates
    .filter(x => !!x.diagnosisDate)
    .sort((x, y) =>
      DateTime.fromISO(x.diagnosisDate!) < DateTime.fromISO(y.diagnosisDate!)
        ? 1
        : -1
    );

  const medicalHistoriesWithNullDate = dates
    .filter(x => !x.diagnosisDate)
    .sort((x, y) => {
      if (x.details && y.details) {
        if (x.details! < y.details!) {
          return -1;
        }
        if (x.details! > y.details!) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

  return [...filteredMedicalHistories, ...medicalHistoriesWithNullDate];
};

export const filterData = (
  x: MedicalHistoryClinicalDataItemDto,
  filters: MedicalHistoryFilterType,
  optionalData?: {
    terminology?: TerminologyDto;
    hasAccessToSecGroup?: boolean;
  }
) => {
  const terminology = optionalData?.terminology;
  const hasAccessToSecGroup = optionalData?.hasAccessToSecGroup;

  const diagnosisRef = terminology;
  const diagnosisText = terminology
    ? terminology.text
    : x.diagnosisCode?.originalText;

  const filtersEnabled =
    filters.name ||
    filters.status ||
    filters.type ||
    !!filters.clinicallySignificant ||
    !!filters.confidential;

  if (!filtersEnabled) {
    return true;
  }

  let filterNameEnabled = false;
  let filterName = false;
  let filterTypeEnabled = false;
  let filterType = false;
  let filtersStatusEnabled = false;
  let filterStatus = false;
  let filterClinicallySignificantEnabled = false;
  let filterConfidentialEnabled = false;
  let filterConfidential = false;
  let filterClinicallySignificant = false;

  if (filters.name) {
    filterNameEnabled = true;
    filterName = diagnosisText
      ? diagnosisText?.toLowerCase().indexOf(filters.name.toLowerCase()) > -1
      : false;
  }
  if (filters.type) {
    filterTypeEnabled = true;
    if (filters.type === "procedure") {
      filterType = !!diagnosisRef?.isProcedure;
    } else {
      filterType = !diagnosisRef?.isDiagnosis;
    }
  }

  if (filters.status === MedicalHistoryStatus.Active) {
    filtersStatusEnabled = true;
    filterStatus = filterStatus || x.active;
  } else if (filters.status === MedicalHistoryStatus.Inactive) {
    filtersStatusEnabled = true;
    filterStatus = filterStatus || !x.active;
  }

  if (filters.clinicallySignificant) {
    filterClinicallySignificantEnabled = true;
    filterClinicallySignificant =
      filterClinicallySignificant || !!x.clinicallySignificant;
  }
  if (!filters.confidential) {
    filterConfidentialEnabled = true;
    filterConfidential = hasAccessToSecGroup ?? false;
  }

  return (
    (!filterNameEnabled || filterName) &&
    (!filtersStatusEnabled || filterStatus) &&
    (!filterTypeEnabled || filterType) &&
    (!filterClinicallySignificantEnabled || filterClinicallySignificant) &&
    (!filterConfidentialEnabled || filterConfidential)
  );
};

export const getInitialDateValues = (
  medicalHistory: MedicalHistoryClinicalDataItemDto
) => {
  const dateValues: MedicalHistoryDateFieldsValues = {};
  if (medicalHistory.diagnosisDate) {
    const [year, month, day] = medicalHistory.diagnosisDate.split("-");
    if (day) {
      dateValues.dateKey = MedicalHistoryDateKey.Exact;
      dateValues.date = DateTime.fromISO(
        medicalHistory.diagnosisDate
      ).toJSDate();
    } else if (year && month) {
      dateValues.dateKey = MedicalHistoryDateKey.YearMonth;
      dateValues.year = year;
      dateValues.month = month;
    } else if (year) {
      dateValues.dateKey = MedicalHistoryDateKey.Year;
      dateValues.year = year;
    }
    if (medicalHistory.patientAge) {
      dateValues.dateKey = MedicalHistoryDateKey.Age;
      dateValues.age = String(medicalHistory.patientAge);
    }
  }
  return dateValues;
};

export const getYearFromAge = (date: DateTime, age: string | number) => {
  const birthYear = date.year;
  return Number(birthYear) + Number(age);
};

/**
 * Returns the age in years for a given year (not the current age)
 */
export const getAgeOnYear = (date: DateTime, year: string | number) => {
  const birthYear = date.year;
  const calcYear = Number(year) - Number(birthYear);
  return calcYear >= 0 ? calcYear : undefined;
};

/**
 * Returns the age composed by years and months for a given year and month (not the current age)
 */
export const getAgeOnYearAndMonth = (
  date: DateTime,
  year: string | number,
  month: string | number
) => {
  // Find the last day of a given month
  const lastDayOfMonth = DateTime.fromObject({
    year: Number(year),
    month: Number(month)
  })
    .endOf("month")
    .startOf("day");

  if (lastDayOfMonth < date) {
    return undefined;
  }

  const diff = lastDayOfMonth.diff(date, ["years", "months"]);

  return {
    years: diff.years,
    months: Math.floor(diff.months)
  };
};

/**
 * Returns the potential similar Date strings based on given array of Medical Histories
 */
export const getPotentialSimilarDiagnosisDates = (
  terminology: TerminologyDto,
  medicalHistories: MedicalHistoryClinicalDataItemDto[],
  options: {
    selectedId?: string;
    diagnosisSideSelected?: string[];
  }
) => {
  const { selectedId, diagnosisSideSelected } = options;

  const diagnosisSide = getSideOfBodyKey(diagnosisSideSelected);

  const results = medicalHistories
    ? medicalHistories
        .filter(
          x =>
            x &&
            x.id !== selectedId &&
            x.diagnosisCode &&
            x.diagnosisDate &&
            terminology &&
            x.diagnosisCode.code === terminology.code &&
            x.diagnosisSide === diagnosisSide
        )
        .map(x => x.diagnosisDate)
    : [];

  return results;
};

export const getDiagnosisSideString = (code: string[]) => {
  const side = getSideOfBodyKey(code);
  return Object.keys(SideOfBody).find(key => SideOfBody[key] === side);
};

export const getSideOfBodyKey = (
  diagnosisSideSelected: string[] | undefined
): string | undefined => {
  if (
    diagnosisSideSelected?.includes(SideOfBody.Left) &&
    diagnosisSideSelected?.includes("R")
  ) {
    return SideOfBody.Both;
  } else if (diagnosisSideSelected?.includes("L")) {
    return SideOfBody.Left;
  } else if (diagnosisSideSelected?.includes("R")) {
    return SideOfBody.Right;
  } else {
    return undefined;
  }
};

export const getSelectedDiagnosisSides = (
  side: string | undefined
): string[] => {
  let sidesSelected: string[] = [];
  if (side) {
    switch (side) {
      case "B":
        sidesSelected = ["L", "R"];
        break;
      case "L":
        sidesSelected = ["L"];
        break;
      case "R":
        sidesSelected = ["R"];
        break;
    }
  }
  return sidesSelected;
};

export const getClaimStatusText = (claimStatus: string | undefined) => {
  let claimStatusText;

  switch (claimStatus) {
    case ClaimStatuses.Ready:
      claimStatusText = "ready";
      break;
    case ClaimStatuses.Discharged:
      claimStatusText = "discharged";
      break;
    case ClaimStatuses.Queued:
      claimStatusText = "queued";
      break;
    case ClaimStatuses.Accepted:
      claimStatusText = "accepted";
      break;
    case ClaimStatuses.Pending:
      claimStatusText = "pending";
      break;
    case ClaimStatuses.NotAvailable:
      claimStatusText = "not available";
      break;
    case ClaimStatuses.Held:
      claimStatusText = "held";
      break;
    case ClaimStatuses.NotVerified:
      claimStatusText = "not verified";
      break;
    case ClaimStatuses.Private:
      claimStatusText = "private";
      break;
    case ClaimStatuses.Incomplete:
      claimStatusText = "incomplete";
      break;
    case ClaimStatuses.GetStatusError:
      claimStatusText = "get status error";
      break;
    case ClaimStatuses.Accredited:
      claimStatusText = "accredited employer";
      break;
    case ClaimStatuses.Error:
      claimStatusText = "error";
      break;
    case undefined:
    default:
      break;
  }
  return claimStatusText;
};
