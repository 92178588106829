import { BpTableRow, ReportDefinition } from "@bps/titanium-powerbi-helper";
import { DateTime } from "@bps/utils";

import {
  CsvDefinition,
  getDateFieldValueFunction,
  getFieldValueFunction
} from "../../utils/csvUtils.ts";

const getXeroFilename = (name: string, report: ReportDefinition) => {
  const start = DateTime.jsDateToISODate(report.reportDates?.startDate);

  const end = DateTime.jsDateToISODate(report.reportDates?.endDate!);

  return `Xero - ${name} - ${start} to ${end}.csv`;
};

const getDueDateFieldValueFunction = (field: string) => {
  return (report: ReportDefinition, row: BpTableRow) => {
    const maybeDate = getFieldValueFunction(field)(report, row);
    if (typeof maybeDate.getMonth === "function") {
      const aDate = DateTime.fromJSDate(maybeDate);
      return aDate.plus({ days: 10 }).toISODate();
    }
    return maybeDate;
  };
};

const getAccountCode = (field: string) => {
  return (report: ReportDefinition, row: BpTableRow) => {
    const prac = getFieldValueFunction(field)(report, row);
    if (typeof prac === "string") {
      const pracString = prac as string;
      const lastIndexOfSpace = pracString.lastIndexOf(" ");
      if (lastIndexOfSpace > -1) {
        const surname = pracString.slice(lastIndexOfSpace).slice(0, 9);
        const firstInitial = pracString.slice(0, 1);
        return surname + firstInitial;
      } else {
        const surname = pracString.slice(lastIndexOfSpace).slice(0, 10);
        return surname;
      }
    }
    return prac;
  };
};
export const xeroInvoiceCsvDefinition: CsvDefinition = {
  name: "Xero",
  description: "Csv export, for importing invoice data into Xero.",
  fileDescription: "Xero invoice import file",
  filename: r => getXeroFilename("invoice", r),
  exportHeader: true,
  matchesMetadata: "XeroInvoiceCompatible",
  columns: [
    {
      header: "*ContactName",
      field: getFieldValueFunction("Bill to")
    },
    {
      header: "EmailAddress",
      field: ""
    },
    {
      header: "POAddressLine1",
      field: getFieldValueFunction("Contact street 1")
    },
    {
      header: "POAddressLine2",
      field: getFieldValueFunction("Contact street 2")
    },
    { header: "POAddressLine3", field: "" },
    { header: "POAddressLine4", field: "" },
    { header: "POCity", field: getFieldValueFunction("Contact city") },
    { header: "PORegion", field: "" },
    {
      header: "POPostalCode",
      field: getFieldValueFunction("Contact postcode")
    },
    {
      header: "POCountry",
      field: getFieldValueFunction("Contact country")
    },
    {
      header: "*InvoiceNumber",
      field: getFieldValueFunction("Invoice number")
    },
    {
      header: "Reference",
      field: ""
    },
    {
      header: "*InvoiceDate",
      field: getDateFieldValueFunction("Invoice date")
    },
    {
      header: "*DueDate",
      field: getDueDateFieldValueFunction("Invoice date")
    },
    {
      header: "InventoryItemCode",
      field: getFieldValueFunction("Item number")
    },
    {
      header: "*Description",
      field: getFieldValueFunction("Description")
    },
    {
      header: "*Quantity",
      field: getFieldValueFunction("Quantity")
    },
    {
      header: "*UnitAmount",
      field: getFieldValueFunction("Fee")
    },
    {
      header: "Discount",
      field: ""
    },
    {
      header: "*AccountCode",
      field: getAccountCode("Provider")
    },
    {
      header: "*TaxType",
      field: "15% GST on Income"
    },
    {
      header: "TrackingName1",
      field: ""
    },
    {
      header: "TrackingOption1",
      field: ""
    },
    {
      header: "TrackingName2",
      field: ""
    },
    {
      header: "TrackingOption2",
      field: ""
    },
    {
      header: "Currency",
      field: ""
    },
    {
      header: "BrandingTheme",
      field: ""
    }
  ]
};
export const xeroPaymentCsvDefinition: CsvDefinition = {
  name: "Xero",
  description: "Csv export, for importing payment data into Xero.",
  fileDescription: "Xero payment import file",
  filename: r => getXeroFilename("Payment", r),
  exportHeader: true,
  matchesMetadata: "XeroPaymentCompatible",
  columns: [
    {
      header: "*Date",
      field: getDateFieldValueFunction("Date")
    },
    {
      header: "*Amount",
      field: getFieldValueFunction("Total")
    },
    {
      header: "Payee",
      field: getFieldValueFunction("Billed to")
    },
    { header: "Description", field: getFieldValueFunction("Description") },
    { header: "Reference", field: getFieldValueFunction("Payment number") },
    { header: "Check Number", field: "" }
  ]
};
export const csvExports = [xeroInvoiceCsvDefinition, xeroPaymentCsvDefinition];
