import { observer } from "mobx-react-lite";
import React from "react";

import {
  CompoundButton,
  FontIcon,
  FontSizes,
  FontWeights,
  Heading,
  NoDataTile,
  PersonaSize,
  Spinner,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { AppointmentInfo, NextAndLastAppointmentsLabels } from "./constants.ts";
import { getNextAndPastAppointments } from "./utils.ts";

interface NextAndLastAppointmentsProps {
  patient: Contact;
}

const NextAndLastAppointmentsComponent: React.FC<NextAndLastAppointmentsProps> =
  observer(({ patient }) => {
    const { booking, core } = useStores();
    const lastUpdatedCalendarEventData =
      booking.ui.lastUpdatedCalendarEventData;

    const getPersonaPrimaryText =
      (provider: User | undefined) => (): JSX.Element => {
        return (
          <Text
            id="appt-form-provider-name"
            styles={{
              root: { fontWeight: FontWeights.semibold }
            }}
          >
            {`${provider?.title ?? ""} ${provider?.name}`}
          </Text>
        );
      };

    const getPersonaSecondaryText =
      (data: AppointmentInfo) => (): JSX.Element => {
        return (
          <Stack verticalAlign="center" verticalFill>
            <Stack.Item>{data.type}</Stack.Item>
            <Stack.Item>{data.startTime}</Stack.Item>
          </Stack>
        );
      };

    const appointmentsList = [
      {
        title: NextAndLastAppointmentsLabels.Upcoming,
        message: NextAndLastAppointmentsLabels.UpcomingEmptyMessage
      },
      {
        title: NextAndLastAppointmentsLabels.Past,
        message: NextAndLastAppointmentsLabels.PatEmptyMessage
      }
    ];

    const hasEditPermission = core.hasPermissions(
      Permission.CalendarEventWrite
    );
    return (
      <Stack tokens={{ childrenGap: 8 }} styles={{ root: { marginTop: 8 } }}>
        {appointmentsList.map(item => {
          return (
            <Stack
              styles={{ root: { padding: "0 10px" } }}
              tokens={{ childrenGap: 5 }}
              key={item.title ?? item.message}
            >
              <Heading variant="section-sub-heading">{item.title}</Heading>
              <DataFetcher<AppointmentInfo | undefined>
                refetchId={lastUpdatedCalendarEventData?.id}
                fetch={root =>
                  getNextAndPastAppointments(patient, item.title, root)
                }
                fallback={<Spinner />}
              >
                {eventInfo => {
                  return (
                    <>
                      {eventInfo ? (
                        <CompoundButton
                          styles={{ root: { maxWidth: "100%", padding: 0 } }}
                          disabled={!hasEditPermission}
                          onClick={() => {
                            booking.ui.showCalendarEventDialog({
                              type: CalendarEventType.Appointment,
                              id: eventInfo.id
                            });
                          }}
                        >
                          <Stack
                            horizontal
                            horizontalAlign="space-between"
                            styles={{
                              root: {
                                width: "100%",
                                padding: 10,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                selectors: {
                                  "&:hover .ms-Icon": {
                                    visibility: "visible"
                                  }
                                }
                              }
                            }}
                          >
                            <Persona
                              id={eventInfo.provider?.id}
                              size={PersonaSize.size56}
                              imageInitials={eventInfo.provider?.initials}
                              styles={{
                                primaryText: { fontSize: FontSizes.medium }
                              }}
                              onRenderPrimaryText={getPersonaPrimaryText(
                                eventInfo.provider
                              )}
                              onRenderSecondaryText={getPersonaSecondaryText(
                                eventInfo
                              )}
                            />
                            {hasEditPermission && (
                              <Stack
                                verticalAlign="center"
                                styles={(props, theme) => ({
                                  root: {
                                    visibility: "hidden",
                                    fontSize: theme.fonts.medium.fontSize,
                                    color: theme.palette.neutralPrimary
                                  }
                                })}
                              >
                                <TooltipHost content="Edit appointment">
                                  <FontIcon iconName="Edit" />
                                </TooltipHost>
                              </Stack>
                            )}
                          </Stack>
                        </CompoundButton>
                      ) : (
                        <NoDataTile
                          textProps={{ text: item.message }}
                          linkProps={{ hidden: true }}
                          showBoxShadow={false}
                          styles={{ root: { minHeight: 85 } }}
                          greyView={true}
                        />
                      )}
                    </>
                  );
                }}
              </DataFetcher>
            </Stack>
          );
        })}
      </Stack>
    );
  });

export const NextAndLastAppointments = withFetch(
  x => [x.booking.loadAppointmentTypes()],
  NextAndLastAppointmentsComponent
);
