import { ReactNode } from "react";

import { Heading, Stack } from "@bps/fluent-ui";
import { BillToTile } from "@modules/billing/screens/allocation/components/BillToTile.tsx";
import {
  AllocationsList,
  PaymentAllocationsProp
} from "@modules/billing/screens/shared-components/AllocationList/AllocationsList.tsx";
import { fullScreen } from "@modules/billing/screens/shared-components/billing.styles.ts";
import { BillingLayout } from "@modules/billing/screens/shared-components/BillingLayout.tsx";
import { TransactionInfoColumn } from "@modules/billing/screens/shared-components/TransactionInfoColumn.tsx";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BillingDetailsRow } from "./BIllingDetailsRow.tsx";
import { BillingHeading } from "./BillingHeading.tsx";
import { TransactionAdjustedBar } from "./TransactionAdjustedBar.tsx";

interface AllocationOptions {
  hide?: boolean;
  allocationProps?: PaymentAllocationsProp[];
}
interface TransactionViewProps {
  transaction: TransactionBase;
  allocationOptions: AllocationOptions;
  buttons: ReactNode;
  extraDetailsContent?: ReactNode;
}

export const TransactionViewBase: React.FC<TransactionViewProps> = ({
  transaction,
  allocationOptions,
  buttons,
  extraDetailsContent
}) => {
  const { hide, allocationProps } = allocationOptions;
  const { billing } = useStores();

  return (
    <div style={fullScreen}>
      <BillingLayout
        messageBar={
          <TransactionAdjustedBar
            transaction={transaction}
            name={
              billing.ref.itemTypes.keyTextValues.find(
                x => x.key === transaction.itemType
              )?.text || ""
            }
          />
        }
        header={
          <BillingHeading
            date={transaction.transactionDate}
            amount={transaction.total}
            transaction={transaction}
            buttons={buttons}
          />
        }
      >
        <Stack tokens={{ childrenGap: 32 }}>
          <BillingDetailsRow
            items={[
              {
                id: "1",
                width: "25%",
                content: <TransactionInfoColumn transaction={transaction} />
              },
              {
                id: "2",
                width: "25%",
                content: (
                  <div>
                    <Heading labelPaddings>Billed to</Heading>
                    <BillToTile accountContactId={transaction.accountId} />
                  </div>
                )
              },
              {
                id: "3",
                width: "50%",
                content: <>{extraDetailsContent}</>
              }
            ]}
          />
          {!hide &&
            allocationProps?.map(props => (
              <AllocationsList
                key={`allocation-list-${props.headerOptions?.fields[0]?.title}`}
                hideOwing
                {...props}
              />
            ))}
        </Stack>
      </BillingLayout>
    </div>
  );
};

export const TransactionView = withFetch(
  x => [x.billing.ref.itemTypes.load()],
  TransactionViewBase
);
