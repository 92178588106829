import React, { PropsWithChildren, ReactNode } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IconButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface SecondColumnWrapperProps
  extends PropsWithChildren<{
    heading?: string | ReactNode;
    footerContent?: JSX.Element;
    childrenGap?: number;
  }> {}

export const SecondColumnWrapper: React.FC<SecondColumnWrapperProps> = ({
  children,
  footerContent,
  heading,
  childrenGap = 16
}) => {
  const { booking } = useStores();

  const theme = useTheme();

  const onClick = () => {
    booking.ui.currentAppointment?.secondColumnContent ===
    SecondColumnContent.selectedGroupAttendee
      ? booking.ui.setSecondColumnContent(SecondColumnContent.groupAttendees)
      : booking.ui.setSecondColumnContent(undefined);
  };

  return (
    <Stack
      tokens={{ childrenGap }}
      styles={{ root: { height: "100%", width: "100%" } }}
    >
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        tokens={{ childrenGap: 8 }}
      >
        {typeof heading === "string" ? (
          <Heading
            variant="section-heading-light"
            {...dataAttribute(DataAttributes.Element, "second-column-heading")}
          >
            {heading}
          </Heading>
        ) : (
          heading
        )}
        <IconButton
          styles={{
            root: {
              height: 32,
              width: 32,
              color: theme.palette.neutralPrimary
            },
            icon: { color: theme.palette.neutralPrimary },
            iconHovered: { color: theme.palette.neutralPrimary },
            iconPressed: { color: theme.palette.neutralPrimary }
          }}
          onClick={onClick}
          iconProps={{ iconName: "Back" }}
        />
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            width: "100%",
            height: "100%",
            overflow: "auto"
          }
        }}
      >
        {children}
      </Stack>
      {footerContent}
    </Stack>
  );
};
