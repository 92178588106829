import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  ActionButton,
  confirm,
  DefaultButton,
  Dialog,
  FontIcon,
  IColumn,
  IconButton,
  IContextualMenuItem,
  Link,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { TemplateInstance } from "@stores/template-management/models/TemplateInstance.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { TemplateManagementContext } from "./context/TemplateManagementContext.tsx";
import { TemplateManagementModal } from "./TemplateManagementModal.tsx";

export const TemplateManagementListBase: FunctionComponent = observer(() => {
  const { templateManagement, core } = useStores();
  const {
    setShowTemplateManagementModal,
    newFormValues,
    showTemplateManagementModal,
    editFormValues
  } = useContext(TemplateManagementContext);

  const confirmDelete = (item: TemplateInstance) => {
    return confirm({
      maxWidth: 560,
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: "Confirm"
      },
      dialogContentProps: {
        title: "Delete",
        subText: `Are you sure you want to delete ${item.name}?`
      }
    });
  };

  const menuItems: (item: TemplateInstance) => IContextualMenuItem[] = item => {
    if (core.hasPermissions(Permission.TemplateManagementWrite)) {
      return [
        {
          key: "Edit",
          text: "Edit",
          onClick: (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            setShowTemplateManagementModal(editFormValues(item.id));
            return;
          }
        },
        {
          key: "Delete",
          text: "Delete",
          onClick: async (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            if (await confirmDelete(item)) {
              await templateManagement.deleteTemplate(item.id);
            }
          }
        }
      ];
    } else {
      return [];
    }
  };

  const columns: IColumn[] = [
    {
      key: "ContextMenu",
      name: "",
      minWidth: 72,
      maxWidth: 72,
      onRender: item => {
        return (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <IconButton
              menuIconProps={{ iconName: "More" }}
              menuProps={{ items: menuItems(item) }}
            />
            <FontIcon
              styles={{
                root: { marginTop: 8 }
              }}
              iconName={item.isPublished ? "Accept" : "BpDraft"}
            />
          </Stack>
        );
      }
    },
    {
      name: "Name",
      key: "name",
      minWidth: 170,
      maxWidth: 170,
      onRender: (item: TemplateInstance) => {
        if (core.hasPermissions(Permission.TemplateManagementWrite)) {
          return (
            <Link
              onClick={() => {
                setShowTemplateManagementModal(editFormValues(item.id));
              }}
            >
              {item.name}
            </Link>
          );
        } else {
          return item.name;
        }
      }
    },
    {
      name: "Description",
      key: "description",
      minWidth: 410,
      maxWidth: 410,
      onRender: (item: TemplateInstance) => item.description
    },
    {
      name: "Type",
      key: "Type",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: TemplateInstance) => item.templateType
    }
  ];

  return (
    <Dialog
      maxWidth={1000}
      dialogContentProps={{
        showCloseButton: true,
        title: "Template management"
      }}
      onDismiss={() => templateManagement.ui.setShowTemplateListModal(false)}
      modalProps={{ focusTrapZoneProps: {} }}
      hidden={false}
    >
      {showTemplateManagementModal && <TemplateManagementModal />}
      <DataFetcher
        fetch={async ({ templateManagement }) => {
          return templateManagement.getTemplates({ name: "" });
        }}
        refetchId={templateManagement.ui.lastUpdatedTemplateInstanceETag}
      >
        {templates => {
          return <ShimmeredDetailsList items={templates} columns={columns} />;
        }}
      </DataFetcher>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { paddingTop: 16 } }}
      >
        {core.hasPermissions(Permission.TemplateManagementWrite) && (
          <ActionButton
            text="New template"
            iconProps={{ iconName: "add" }}
            onClick={() => setShowTemplateManagementModal(newFormValues)}
          />
        )}
        <DefaultButton
          text="Close"
          onClick={() => templateManagement.ui.setShowTemplateListModal(false)}
        />
      </Stack>
    </Dialog>
  );
});

export const TemplateManagementList = withFetch(
  x => [
    x.templateManagement.ref.templateTypes.load(),
    x.templateManagement.ref.templateFormats.load()
  ],
  TemplateManagementListBase
);
