import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Heading, Stack, Text, useTheme } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

interface DefaultCpnLinkProps {
  pracOrgUnit: PracOrgUnit | undefined;
}

export const DefaultCpnLink: FC<DefaultCpnLinkProps> = observer(
  ({ pracOrgUnit }) => {
    const theme = useTheme();
    const userId = pracOrgUnit?.nzOrgUnitIdentifier?.defaultHpiCpnUserId;

    return (
      <Stack>
        <Heading labelPaddings variant="section-sub-heading">
          Default HPI CPN for ACC queries
        </Heading>
        {userId ? (
          <UserFetcher userId={userId}>
            {user => (
              <Navigate to={routes.settings.users.user.path({ id: userId })}>
                {user.fullName}
              </Navigate>
            )}
          </UserFetcher>
        ) : (
          <Text
            variant="small"
            styles={{ root: { color: theme.palette.neutralSecondaryAlt } }}
          >
            No default HPI CPN set. This can be set in the user settings of the
            selected provider
          </Text>
        )}
      </Stack>
    );
  }
);
