import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useField } from "react-final-form";

import {
  DirectionalHint,
  FontSizes,
  IDropdownOption,
  Link,
  MessageBar,
  MessageBarType,
  NoDataTile,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";

import { CommsScheduleContext } from "../context/CommsScheduleContext.ts";
import { CommsCard } from "./CommsCard.tsx";
import { CommsScheduleFormValues } from "./CommsScheduleForm.types.ts";
import { CommsScheduleCardIds } from "./CommsScheduleFormEnums.ts";

const nameOf = nameOfFactory<CommsScheduleFormValues>();

interface MessageContentFormSectionProps {
  templates: OutboundCommTemplate[];
  subHeading?: string;
}

export const MessageContentFormSection = observer(
  ({ templates, subHeading }: MessageContentFormSectionProps) => {
    const { comms, routing } = useStores();
    const theme = useTheme();

    const {
      input: { value: templateId }
    } = useField(nameOf("templateId"), {
      subscription: { value: true }
    });

    const messageContent = templateId
      ? comms.outboundCommTemplatesMap.get(templateId)?.channelTemplates[0]
          .template || ""
      : "";

    const { selectedSectionId, templateHasMissingValues } =
      useContext(CommsScheduleContext);

    const templateOptions: IDropdownOption[] = templates.map(template => {
      const { id, name, isDefault } = template;
      return {
        key: id,
        text: isDefault ? `${name} (default)` : name,
        data: { isDefault, name },
        disabled: templateHasMissingValues(template)
      };
    });

    const onRenderOption = (option: IDropdownOption): JSX.Element => {
      return (
        <span>
          {option.data.name}
          {option.data.isDefault && (
            <Text
              styles={{ root: { color: theme.semanticColors.disabledText } }}
            >
              {" (default)"}
            </Text>
          )}
        </span>
      );
    };

    const renderSubHeading = () => {
      const onLinkClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        routing.push(routes.settings.practices.basePath.pattern);
      };

      const hasDisabledOption = templateOptions.find(o => o.disabled);

      return (
        <Stack>
          {subHeading}
          {hasDisabledOption ? (
            <MessageBar messageBarType={MessageBarType.warning}>
              Some templates are not available due to absent practice details.{" "}
              <Link onClick={onLinkClick}>Complete your practice details</Link>{" "}
              to have access to all templates.
            </MessageBar>
          ) : null}
        </Stack>
      );
    };

    return (
      <CommsCard
        id={CommsScheduleCardIds.messageContent}
        openOnRender={
          !selectedSectionId ||
          selectedSectionId === CommsScheduleCardIds.messageContent
        }
        heading="Message content"
        subHeading={renderSubHeading()}
      >
        <Stack tokens={{ childrenGap: 24 }}>
          <DropdownField
            required
            name={nameOf("templateId")}
            placeholder="Select template"
            label="Select a template"
            options={templateOptions}
            calloutProps={{
              calloutMaxHeight: 400,
              directionalHint: DirectionalHint.bottomCenter
            }}
            onRenderOption={onRenderOption}
          />
          <div
            style={{
              borderWidth: 1,
              height: "153px",
              maxHeight: "153px",
              borderRadius: 2,
              borderStyle: "solid",
              borderColor: theme.palette.neutralDark,
              padding: 4
            }}
          >
            {messageContent ? (
              <div
                style={{
                  height: "100%",
                  fontSize: FontSizes.small,
                  whiteSpace: "pre-line",
                  padding: 8,
                  borderRadius: 2,
                  overflowY: "scroll",
                  backgroundColor: theme.semanticColors.disabledBackground
                }}
                dangerouslySetInnerHTML={{ __html: messageContent }}
              />
            ) : (
              <NoDataTile
                textProps={{
                  text: "No template selected"
                }}
                showBoxShadow={false}
                linkProps={{
                  text: "",
                  hidden: true
                }}
              />
            )}
          </div>
        </Stack>
      </CommsCard>
    );
  }
);
