import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import {
  confirm,
  FontIcon,
  FontSizes,
  IconButton,
  IContextualMenuItem,
  Link,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  DischargeDataItemDto,
  EpisodeOfCareDto,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DischargeStatusText } from "@ui-components/RefText.tsx";

import { ClaimAppointmentContext } from "./ClaimAppointmentContext.tsx";
import { ConditionDischargeButton } from "./ConditionDischargeButton.tsx";
import { getDischargeStyles } from "./ConditionsSidePanel.styles.ts";
import { ReverseDischargeDialog } from "./ReverseDischargeDialog.tsx";
import { UnsavedSOTAPFormDialog } from "./UnsavedSOTAPFormDialog.tsx";

interface DischargeProps {
  condition: ICondition;
  dataItem: DischargeDataItemDto;
  showBusinessRole?: boolean;
}

export const Discharge: React.FC<DischargeProps> = observer(
  ({ condition, dataItem, showBusinessRole }) => {
    const { clinical, core, userExperience } = useStores();
    const theme = useTheme();
    const dischargeStyles = getDischargeStyles(theme);

    const { clinicalRecord, isLinkedCondition, linkConditionToEncounter } =
      useContext(ClaimAppointmentContext);

    const [hiddenUnsavedDialog, setHiddenUnsavedDialog] =
      useState<boolean>(true);

    const [hiddenReverseDischargeDialog, setHiddenReverseDischargeDialog] =
      useState<boolean>(true);

    const claimStatus = condition?.claim?.claimStatus;

    const businessRoleCode = dataItem.businessRoleCode;

    const contextId = `${condition.episodeOfCareId}::${businessRoleCode}`;

    const dischargeInProgress =
      (dataItem.dischargeStatus === undefined &&
        clinical.ui.tabs.currentPatientRecordTab?.allTabs.find(
          t =>
            t.type === ClinicalDataType.Discharge && t.contextId === contextId
        )) ||
      dataItem.dischargeStatus === DischargeStatus.InProgress ||
      dataItem.dischargeStatus === DischargeStatus.ReadyToFinalise;

    const handleDischarge = async () => {
      if (
        clinicalRecord.tabCondition?.unsavedData.some(
          x => x.type === PermanentClinicalTab.SOTAP
        )
      ) {
        setHiddenUnsavedDialog(false);
        return;
      }

      if (
        condition?.episodeOfCareId &&
        !isLinkedCondition(condition.episodeOfCareId)
      ) {
        await linkConditionToEncounter(condition.episodeOfCareId);
      }

      if (dataItem?.dischargeStatus === DischargeStatus.Reversed) {
        await clinicalRecord.updateDischargeStatus(
          DischargeStatus.InProgress,
          businessRoleCode
        );
      }

      const title = getDischargeTabTitle(false);

      clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.Discharge,
        contextId,
        title,
        toolTipText: title
      });
    };

    const handleUnsavedSOTAPFormDialogCancel = () => {
      setHiddenUnsavedDialog(true);
    };

    const getDischargeTabTitle = (displayDiagnosis: boolean) => {
      let title = "Discharge";

      if (showBusinessRole) {
        const businessRoleAbbreviation =
          userExperience.ref.businessRoleAbbreviations.values.find(
            x => x.businessRoleCode === businessRoleCode
          )?.text;

        title += ` (${businessRoleAbbreviation})`;
      }

      if (displayDiagnosis) title += ` - ${condition.primaryDiagnosis}`;

      return title;
    };

    const handleOpenDischarge = (displayDiagnosis: boolean) => {
      const title = getDischargeTabTitle(displayDiagnosis);

      clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.Discharge,
        contextId,
        title,
        toolTipText: title
      });
    };

    const handleDeleteDischarge = async () => {
      const isConfirmed = await confirm({
        minWidth: 480,
        confirmButtonProps: {
          text: "Delete"
        },
        cancelButtonProps: {
          text: "Cancel"
        },
        dialogContentProps: {
          title: "Delete discharge",
          subText: "Are you sure you want to delete this discharge?"
        }
      });

      if (isConfirmed) {
        await clinicalRecord.deleteDischarge(businessRoleCode);
        removeDischargeTab();
      }
    };

    const removeDischargeTab = () => {
      const currenPatientRecordTabs = clinical.ui.tabs.currentPatientRecordTab;
      const dischargeTab = currenPatientRecordTabs?.allTabs.find(
        patientRecordTab =>
          patientRecordTab.type === ClinicalDataType.Discharge &&
          patientRecordTab.contextId === contextId
      );
      if (dischargeTab) {
        if (!dischargeTab.isActive) {
          currenPatientRecordTabs?.setActive(ClinicalDataType.Discharge);
        }
        clinical.ui.closePatientRecordContentForm(
          clinicalRecord.id,
          ClinicalDataType.Discharge,
          contextId
        );
      }
    };

    const handleReverseDischargeConfirmed = async (comments?: string) => {
      if (
        condition?.episodeOfCareId &&
        !isLinkedCondition(condition.episodeOfCareId)
      ) {
        await linkConditionToEncounter(condition.episodeOfCareId);
      }

      await clinicalRecord.reverseDischarge(businessRoleCode, comments);
      setHiddenReverseDischargeDialog(true);
    };

    const iconButtonItems: IContextualMenuItem[] = [
      {
        key: "view",
        text: "View",
        onClick: () => {
          handleOpenDischarge(true);
        }
      },
      {
        key: "reverseDischarge",
        text: "Reverse discharge",
        disabled:
          clinicalRecord.episodeOfCare &&
          !isLinkedCondition(condition.episodeOfCareId),
        onClick: () => setHiddenReverseDischargeDialog(false)
      }
    ];

    let statusText: string | undefined;

    const dischargeDate = DateTime.fromISO(dataItem.dischargeDate);

    const updatedDate = DateTime.fromISO(
      dataItem.updateLog?.updatedDateTime || dataItem.createLog?.createdDateTime
    );

    if (!dischargeInProgress) {
      switch (dataItem.dischargeStatus) {
        case undefined:
          statusText = "Not started";
          break;
        case DischargeStatus.Completed:
          statusText = `Discharged on ${dischargeDate?.toDayDefaultFormat()}`;
          break;
        case DischargeStatus.Reversed:
          statusText = `Reversed on ${updatedDate?.toDayDefaultFormat()}`;
          break;
      }
    }

    const businessRoleText = core.catalogBusinessRoles.find(
      x => x.code === businessRoleCode
    )?.text;

    const statusTextColour =
      dataItem.dischargeStatus === DischargeStatus.Completed
        ? theme.semanticColors.disabledText
        : theme.palette.neutralSecondary;

    const showStatusIcon =
      !dischargeInProgress &&
      (dataItem.dischargeStatus === DischargeStatus.Completed ||
        dataItem.dischargeStatus === DischargeStatus.Reversed);

    return (
      <>
        <Stack
          tokens={{ childrenGap: dischargeInProgress ? 4 : 8 }}
          styles={dischargeStyles.dischargeBox}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack>
              {showBusinessRole && (
                <Text
                  styles={{
                    root: {
                      fontWeight: 600,
                      color: theme.palette.themeDarker
                    }
                  }}
                >
                  {businessRoleText}
                </Text>
              )}
              <Stack horizontal tokens={{ childrenGap: 4 }}>
                {showStatusIcon && (
                  <FontIcon
                    hidden={!showStatusIcon}
                    styles={{
                      root: {
                        fontSize: FontSizes.size12,
                        marginTop: 2,
                        color: statusTextColour,
                        transform:
                          dataItem.dischargeStatus !== DischargeStatus.Completed
                            ? "rotateY(-180deg)"
                            : ""
                      }
                    }}
                    iconName={
                      dataItem.dischargeStatus === DischargeStatus.Completed
                        ? "Leave"
                        : "ReturnToSession"
                    }
                  />
                )}
                <Text
                  styles={{
                    root: {
                      fontSize: 14,
                      color: statusTextColour
                    }
                  }}
                >
                  {statusText}
                </Text>
              </Stack>
            </Stack>
            {!dischargeInProgress &&
              (dataItem.dischargeStatus === undefined ||
                dataItem.dischargeStatus === DischargeStatus.Reversed) && (
                <DataFetcher<EpisodeOfCareDto>
                  fetch={async ({ clinical }) =>
                    clinical.getEpisodeOfCare(condition.episodeOfCareId)
                  }
                >
                  {episodeOfCare => (
                    <ConditionDischargeButton
                      claimStatus={claimStatus}
                      dischargeStatus={dataItem.dischargeStatus}
                      businessRoleCode={businessRoleCode}
                      episodeOfCare={episodeOfCare}
                      onClick={handleDischarge}
                    />
                  )}
                </DataFetcher>
              )}
            {dataItem.dischargeStatus === DischargeStatus.Completed && (
              <IconButton
                iconProps={{ iconName: "More" }}
                onRenderMenuIcon={() => null}
                menuProps={{ items: iconButtonItems }}
              />
            )}
          </Stack>
          {clinicalRecord.episodeOfCare?.id === condition?.episodeOfCareId &&
            dischargeInProgress && (
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                styles={{
                  root: {
                    background:
                      dataItem.dischargeStatus ===
                      DischargeStatus.ReadyToFinalise
                        ? theme.semanticColors.infoBackground
                        : theme.semanticColors.warningBackground,
                    padding: "8px 12px"
                  }
                }}
              >
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <FontIcon
                    iconName="Info"
                    styles={{
                      root: {
                        color: theme.palette.neutralSecondary,
                        fontSize: FontSizes.size16
                      }
                    }}
                  />
                  <Text styles={{ root: { fontSize: FontSizes.size12 } }}>
                    <DischargeStatusText
                      code={
                        dataItem.dischargeStatus ?? DischargeStatus.InProgress
                      }
                    />
                  </Text>
                </Stack>
                <Stack
                  horizontal
                  tokens={{ childrenGap: 4 }}
                  styles={{ root: { fontSize: FontSizes.size12 } }}
                >
                  <Link
                    onClick={() => {
                      handleOpenDischarge(false);
                    }}
                  >
                    Edit
                  </Link>
                  <Link onClick={handleDeleteDischarge}>Delete</Link>
                </Stack>
              </Stack>
            )}
        </Stack>
        <UnsavedSOTAPFormDialog
          hidden={hiddenUnsavedDialog}
          onCancel={handleUnsavedSOTAPFormDialogCancel}
        />
        <ReverseDischargeDialog
          hidden={hiddenReverseDischargeDialog}
          onConfirm={handleReverseDischargeConfirmed}
          onCancel={() => setHiddenReverseDischargeDialog(true)}
        />
      </>
    );
  }
);
