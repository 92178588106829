import { ITheme } from "@bps/fluent-ui";
import {
  AppointmentStatusCode,
  AppointmentStatusDto,
  CalendarEventReminderStatus
} from "@libs/gateways/booking/BookingGateway.dtos.ts";

type ApptColours = {
  [key: string]: Tones;
};

export type Tones = { light: string; dark: string };

export const getAppointmentStatusColours =
  (theme: ITheme) =>
  (code: AppointmentStatusCode): Tones => {
    const colours: ApptColours = {
      BO: {
        light: theme.appointmentStatusesColors.bookedSecondary,
        dark: theme.appointmentStatusesColors.bookedPrimary
      },
      UBO: {
        light: theme.appointmentStatusesColors.urgentSecondary,
        dark: theme.appointmentStatusesColors.urgentPrimary
      },
      W: {
        light: theme.appointmentStatusesColors.waitingSecondary,
        dark: theme.appointmentStatusesColors.waitingPrimary
      },
      UW: {
        light: theme.appointmentStatusesColors.urgentAndWaitingSecondary,
        dark: theme.appointmentStatusesColors.urgentAndWaitingPrimary
      },
      WP: {
        light: theme.appointmentStatusesColors.providerSecondary,
        dark: theme.appointmentStatusesColors.providerPrimary
      },
      BILL: {
        light: theme.appointmentStatusesColors.billingSecondary,
        dark: theme.appointmentStatusesColors.billingPrimary
      },
      CPL: {
        light: theme.appointmentStatusesColors.completedSecondary,
        dark: theme.appointmentStatusesColors.completedPrimary
      },
      NA: {
        light: theme.appointmentStatusesColors.notAttendedSecondary,
        dark: theme.appointmentStatusesColors.notAttendedPrimary
      }
    };

    return colours[code];
  };

export enum AppointmentStatusTextValues {
  "BO" = "Booked",
  "W" = "Waiting",
  "WP" = "With provider",
  "BILL" = "Finalised",
  "CPL" = "Completed",
  "NA" = "Did not attend"
}

/* Need to find a better way to get appointment status order */
const appointmentProgress: AppointmentStatusDto["code"][] = [
  "W",
  "WP",
  "BILL",
  "CPL"
];

export const urgentAppointmentStatuses = [
  AppointmentStatusCode.Booked,
  AppointmentStatusCode.Waiting
];

export const getAppointmentStatusText = (
  code: string,
  text: string,
  urgent?: boolean
) => {
  let useText = text;
  if (urgent) {
    if (code === AppointmentStatusCode.Booked) {
      useText = "Urgent";
    } else if (code === AppointmentStatusCode.Waiting) {
      useText = "Urgent & waiting";
    }
  }
  return useText;
};

export function getNextAppointmentStatusCode(code?: AppointmentStatusCode) {
  const noNextStepCodes = [
    AppointmentStatusCode.DidNotAttend,
    AppointmentStatusCode.Completed
  ];
  if (code && noNextStepCodes.indexOf(code) > -1) {
    return null;
  }

  const nextId = code ? appointmentProgress.indexOf(code) + 1 : 0;
  if (nextId >= appointmentProgress.length) {
    return null;
  }
  return appointmentProgress[nextId] as AppointmentStatusCode;
}

export const reminderStatusIconName: {
  [key in CalendarEventReminderStatus]: string;
} = {
  [CalendarEventReminderStatus.CONFIRMED]: "BPApptConfirmed12",
  [CalendarEventReminderStatus.NOREPLY]: "BPApptNoReply12",
  [CalendarEventReminderStatus.REQUIRESREVIEW]: "BPApptReview12",
  [CalendarEventReminderStatus.DECLINED]: "BPApptDeclined12"
};

export const getReminderStatusBackgroundColor = (
  theme: ITheme,
  status: CalendarEventReminderStatus
) => {
  const color = {
    [CalendarEventReminderStatus.CONFIRMED]:
      theme.semanticColors.successBackground,
    [CalendarEventReminderStatus.DECLINED]:
      theme.semanticColors.blockingBackground,
    [CalendarEventReminderStatus.NOREPLY]:
      theme.semanticColors.bodyStandoutBackground,
    [CalendarEventReminderStatus.REQUIRESREVIEW]:
      theme.semanticColors.warningBackground
  };
  return color[status];
};
