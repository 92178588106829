import { IStyle } from "@bps/fluent-ui";

export const getContainerStyles = (): IStyle => {
  return {
    containerType: "inline-size",
    "@container (width < 1130px)": {
      "#tasks-required .ms-Button-label, #reminders-required .ms-Button-label, #overdue-required .ms-Button-label":
        {
          display: "none"
        }
    }
  };
};
