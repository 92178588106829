import { FunctionComponent } from "react";
import { useField, useForm } from "react-final-form";

import { Pivot, PivotItem } from "@bps/fluent-ui";
import {
  InjuryArea,
  InjuryAreaGroups
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { InjuryAreaMotionAssessmentItem } from "@shared-types/clinical/injury-area-motion-assessment-item.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { PivotLabels } from "../SOTAP.constants.ts";
import { sotapNameOf } from "../SOTAP.utils.ts";
import { InjuryAreaMotionAssessmentFields } from "./InjuryAreaMotionAssessment.tsx";
import { getPivotIcon } from "./utils.ts";

interface SpinePivotsProps {
  injuryArea: InjuryArea;
}
export const SpinePivots: FunctionComponent<SpinePivotsProps> = ({
  injuryArea
}) => {
  const {
    input: { value: injuryAreaMotionAssessments }
  } = useField<InjuryAreaMotionAssessmentItem[] | undefined>(
    sotapNameOf("injuryAreaMotionAssessments"),
    {
      subscription: { value: true }
    }
  );

  const { getState } = useForm<SOTAPFormValues>();
  const initialInjuryAreaMotionAssessments =
    getState().initialValues.injuryAreaMotionAssessments;

  const icon = getPivotIcon(
    injuryAreaMotionAssessments,
    initialInjuryAreaMotionAssessments
  );

  return (
    <Pivot>
      <PivotItem
        headerText={PivotLabels.Cervical}
        key={InjuryAreaGroups.Cervical}
        itemIcon={icon({
          injuryArea,
          injuryAreaGroup: InjuryAreaGroups.Cervical
        })}
      >
        <InjuryAreaMotionAssessmentFields
          name={sotapNameOf("injuryAreaMotionAssessments")}
          injuryArea={injuryArea}
          injuryAreaGroup={InjuryAreaGroups.Cervical}
        />
      </PivotItem>
      <PivotItem
        headerText={PivotLabels.Thoracic}
        key={InjuryAreaGroups.Thoracic}
        itemIcon={icon({
          injuryArea,
          injuryAreaGroup: InjuryAreaGroups.Thoracic
        })}
      >
        <InjuryAreaMotionAssessmentFields
          name={sotapNameOf("injuryAreaMotionAssessments")}
          injuryArea={injuryArea}
          injuryAreaGroup={InjuryAreaGroups.Thoracic}
        />
      </PivotItem>
      <PivotItem
        headerText={PivotLabels.Lumbosacral}
        key={InjuryAreaGroups.Lumbosacral}
        itemIcon={icon({
          injuryArea,
          injuryAreaGroup: InjuryAreaGroups.Lumbosacral
        })}
      >
        <InjuryAreaMotionAssessmentFields
          name={sotapNameOf("injuryAreaMotionAssessments")}
          injuryArea={injuryArea}
          injuryAreaGroup={InjuryAreaGroups.Lumbosacral}
        />
      </PivotItem>
    </Pivot>
  );
};
