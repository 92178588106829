import { newGuid } from "@bps/utils";
import {
  FieldOptionsDto,
  FormDesignAddDTO,
  formDesignDomains,
  FormDesignUpdateDTO,
  FormGroupDTO
} from "@libs/gateways/form-design/FormDesignGateway.dtos.ts";
import { FieldOptionsValues } from "@shared-types/form-design/field-options-values.interface.ts";
import { FormDesignFieldGroup } from "@shared-types/form-design/form-design-field-group.interface.ts";
import { FormDesignValues } from "@shared-types/form-design/form-design-form-values.interface.ts";
import { FormDesignGroupEntityValues } from "@shared-types/form-design/form-design-group-entity-values.interface.ts";
import { FormResponseNotificationSetting } from "@shared-types/form-design/form-response-notification-setting.enum.ts";
import { FormDesignStore } from "@stores/form-design/FormDesignStore.ts";
import { FormDesignModalConfig } from "@stores/form-design/FormDesignUi.ts";

export const saveFormDesignModel = async (
  model: FormDesignValues,
  formDesign: FormDesignStore
): Promise<FormDesignUpdateDTO | undefined> => {
  const {
    usedGroups: usedGroupValues,
    id,
    eTag,
    domains: _domains,
    notificationSetting,
    ...rest
  } = model;

  const usedGroups = usedGroupValues.map(x => {
    const fieldOptions = x.fieldOptions.map((fieldOption, sortOrder) => {
      const fo: FieldOptionsDto = {
        sortOrder,
        hidden: fieldOption.hidden,
        label: fieldOption.label,
        name: x.field?.allowMultipleOnSameForm ? "" : fieldOption.name,
        required: fieldOption.required,
        choices: fieldOption.formChoices?.map(x => x.text),
        min: fieldOption.min,
        max: fieldOption.max,
        step: fieldOption.step,
        minText: fieldOption.minText,
        middleText: fieldOption.middleText,
        maxText: fieldOption.maxText,
        visibleIf: fieldOption.visibleIf,
        hideLabel: fieldOption.hideLabel,
        hideNumber: fieldOption.hideNumber,
        maxChoices: fieldOption.maxChoices
      };

      return fo;
    });
    return { ...x, fieldOptions };
  });

  const domains = await formDesign.getFormDesignGroups();

  const groups: FormGroupDTO[] = [];
  usedGroups.forEach((x: FormDesignFieldGroup) => {
    const domain = domains.find(
      d => `${d.domain}.${d.property}` === x.field?.groupPropertyPath
    );

    const ret: FormGroupDTO | null =
      domain && x.field?.groupPropertyPath
        ? {
            domain: domain.domain,
            groupPropertyPath: x.field.groupPropertyPath,
            context: domain.context,
            nodeType: domain.nodeType,
            groupTitle: domain.groupTitle,
            fieldOptions: x.fieldOptions,
            isCollapsed: x.collapsed
          }
        : null;
    ret && groups.push(ret);
  });

  return id && eTag
    ? await formDesign.saveFormDesign({
        id,
        eTag,
        notificationSetting: notificationSetting
          ? FormResponseNotificationSetting.system
          : FormResponseNotificationSetting.none,
        ...rest,
        groups
      })
    : formDesign.createFormDesign({
        ...rest,
        notificationSetting: notificationSetting
          ? FormResponseNotificationSetting.system
          : FormResponseNotificationSetting.none,
        groups
      });
};

const getDomainGroupsOnly = (availableGroups: FormGroupDTO[]) => {
  const domainGroups: Record<string, FormDesignGroupEntityValues> = {};

  availableGroups
    .filter(x => !x.hiddenInDesigner)
    .forEach(group => {
      if (!Object.keys(domainGroups).find(x => x === group.domain)) {
        domainGroups[group.domain] = {
          context: [group.context],
          domain: group.domain,
          domainEntity: group.domain,
          groups: []
        };
      }

      interface IFormField {
        isRequired: boolean;
        title: string;
        name: string;
      }
      const fieldDefinitionInfo: IFormField | undefined =
        group.formJson && JSON.parse(group.formJson);

      const fieldOptions: FieldOptionsValues[] = group.children
        ? group.children.map((x, i) => {
            const fieldDefinitionInfo: IFormField | undefined =
              x.formJson && JSON.parse(x.formJson);

            const result: FieldOptionsValues = {
              name: fieldDefinitionInfo?.name,
              label: x.allowMultipleOnSameForm
                ? ""
                : fieldDefinitionInfo?.title,
              noDataEntry: group.noDataEntry,
              userCanChangeLabel: x.userCanChangeLabel,
              userCanToggleRequired: x.userCanToggleRequired,
              userCanToggleShow: x.userCanToggleShow,
              userCanAddChoices: x.userCanAddChoices,
              userCanSetMinMax: x.userCanSetMinMax,
              userCanSetMinMaxText: x.userCanSetMinMaxText,
              userCanSetMaxChoices: x.userCanSetMaxChoices,
              groupDependency: x.groupDependency,
              sortOrder: i,
              required: fieldDefinitionInfo?.isRequired
            };
            return result;
          })
        : [
            {
              name: fieldDefinitionInfo?.name,
              label: fieldDefinitionInfo?.title,
              noDataEntry: group.noDataEntry,
              userCanChangeLabel: group.userCanChangeLabel,
              userCanAddChoices: group.userCanAddChoices,
              userCanSetMinMax: group.userCanSetMinMax,
              userCanSetMinMaxText: group.userCanSetMinMaxText,
              userCanToggleRequired: group.userCanToggleRequired,
              userCanSetMaxChoices: group.userCanSetMaxChoices,
              userCanToggleShow: group.userCanToggleShow,
              required: fieldDefinitionInfo?.isRequired
            }
          ];

      const newGroup: FormDesignFieldGroup = {
        domain: domainGroups[group.domain],
        domainName: group.domain,
        fieldOptions,
        field: {
          ...group,
          groupPropertyPath: `${group.domain}.${group.property}`
        },
        collapsed: group.isCollapsed
      };
      domainGroups[group.domain].groups.push(newGroup);
    });
  return domainGroups;
};

export const getDomainGroups = (
  availableGroups: FormGroupDTO[],
  groups: FormGroupDTO[]
) => {
  const domainGroups = getDomainGroupsOnly(availableGroups);
  const usedGroups: FormDesignFieldGroup[] = [];
  groups.forEach(fieldGroup => {
    Object.keys(domainGroups).forEach(x => {
      const domain = domainGroups[x];
      const field = domain.groups.find(
        x => x.field?.groupPropertyPath === fieldGroup.groupPropertyPath
      );

      if (domain && field) {
        const fieldOptions = field?.fieldOptions?.map(domainField => {
          const field = fieldGroup?.fieldOptions?.find(
            x =>
              x.name === domainField.name ||
              // This is necessary because the name is not saved in custom fields,
              // but we still need to carry over properties from the domain groups.
              (fieldGroup.groupPropertyPath.startsWith("Forms.") &&
                fieldGroup.groupPropertyPath.replace("Forms.", "Custom.") ===
                  domainField.name)
          );

          // This is needed because some properties should not be carried over from domainField.
          const { required, ...rest } = domainField;

          return {
            ...rest,
            ...field,
            label: rest.userCanChangeLabel ? field?.label : rest.label,
            formChoices: field?.choices?.map((y: string) => ({
              key: newGuid(),
              text: y
            }))
          };
        });

        const group: FormDesignFieldGroup = {
          domain,
          domainName: domain.domainEntity,
          fieldOptions: fieldOptions ?? [],
          field: field.field
        };
        usedGroups.push(group);
      }
    });
  });

  return {
    domainGroups,
    usedGroups
  };
};

export const getFormDesignInitialValues = async (
  store: FormDesignStore,
  config?: FormDesignModalConfig
): Promise<FormDesignValues | undefined> => {
  const design: FormDesignUpdateDTO | undefined = config?.id
    ? await store.getFormDesign(config?.id)
    : {
        id: "",
        name: "",
        description: "",
        groups: [],
        isPublished: false,
        notificationSetting: FormResponseNotificationSetting.system,
        eTag: "v1"
      };

  const values = design && getModelFromDesign(store, design);

  if (values) {
    return values.then(data => {
      if (config?.duplicateDesign) {
        data.id = "";
      }
      return data;
    });
  }
  return undefined;
};

const getModelFromDesign = async (
  store: FormDesignStore,
  design: FormDesignAddDTO
): Promise<FormDesignValues> => {
  const designGroups = await store.getFormDesignGroups();

  const { groups, notificationSetting, ...rest } = design;
  const { domainGroups, usedGroups } = getDomainGroups(designGroups, groups);

  const formDesignModel: FormDesignValues = {
    usedGroups,
    domainGroups,
    notificationSetting:
      notificationSetting === FormResponseNotificationSetting.system,
    ...rest
  };

  if (!formDesignModel.id || !formDesignModel.id.length) {
    formDesignModel.usedGroups.push({
      domain: {
        domain: "",
        domainEntity: "",
        groups: [],
        context: []
      },
      domainName: "",
      fieldOptions: []
    });
  }

  return formDesignModel;
};

export const getUxDomainName = (domainName: string) => {
  const definition = formDesignDomains.find(x => x.name === domainName);
  return definition?.uxName ?? domainName;
};
