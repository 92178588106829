import { DateTime } from "@bps/utils";
import {
  AddClaimDto,
  ClaimStatuses
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { isClaimAdjustmentAllowed } from "@modules/acc/screens/claim-adjustment/components/utils.ts";
import {
  ClaimManagementCardIds,
  ClaimManagementSideRailLabels
} from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import {
  addNewClaim,
  getClaimFormDefaultValues,
  patchExistingClaim
} from "@modules/acc/screens/claim/components/utils.ts";
import {
  emptyReferralDiagnosis,
  isReferralDiagnosisNotEmpty,
  mapToClaimDiagnosisReferralFormValues,
  mapToReferalDiagnosisDto,
  mergeClaimDiagnosis,
  splitClaimDiagnosis
} from "@modules/acc/screens/utils.ts";
import { ClaimManagementModalFormValues } from "@shared-types/acc/claim-management-modal-values.type.ts";
import { ClaimPatientFormValues } from "@shared-types/acc/claim-patient-values.type.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { Claim } from "@stores/acc/models/Claim.ts";

import { ClaimFormManagementValues } from "../../claim/types/claim-form-management-values.types.ts";

export const getClaimFormManagementModalValues = (options: {
  claim?: Claim;
  defaultInsurer?: string;
  appointmentFormValues?: AppointmentFormValues;
  setProviderById?: (id: string) => void;
}): ClaimManagementModalFormValues => {
  const { claim, defaultInsurer, appointmentFormValues, setProviderById } =
    options;

  const defaultValues = getClaimFormDefaultValues(claim);
  const insurerId = claim?.dto.insurerContactId
    ? claim.dto.insurerContactId
    : defaultInsurer;

  if (!!appointmentFormValues) {
    if (!!appointmentFormValues.providerId && setProviderById)
      setProviderById(appointmentFormValues.providerId);

    return {
      patientId: appointmentFormValues.patientId,
      providerId: appointmentFormValues.providerId,
      insurerContactId: insurerId
    };
  }

  if (!!claim?.providerId && setProviderById) {
    setProviderById(claim?.providerId);
  }

  const personalDetailsValues = {
    patient: {
      patientId: claim?.dto.patientId,
      patientFirstName: claim?.dto.patientFirstName,
      patientMiddleName: claim?.dto.patientMiddleName,
      patientLastName: claim?.dto.patientLastName,
      patientDateOfBirth: claim?.patientDateOfBirth?.toJSDate(),
      patientSex: claim?.dto.patientSex,
      patientNhiNumber: claim?.dto.patientNhiNumber,
      addressType: claim?.dto.addressType,
      patientEmail: claim?.dto.patientEmail,
      patientEthnicityCode: claim?.dto.patientEthnicityCode,
      patientAddress: claim?.dto.patientAddress,
      patientMobileNumber: claim?.dto.patientMobileNumber,
      patientHomePhoneNumber: claim?.dto.patientHomePhoneNumber,
      patientWorkPhoneNumber: claim?.dto.patientWorkPhoneNumber
    }
  };

  return {
    patientId: claim?.dto.patientId,
    providerId: claim?.dto.providerId,
    discharged: claim?.discharged,
    isDischarged: claim?.isDischarged,
    private: !!claim?.dto.private,
    referralIn: !!claim?.dto.referralIn,
    insurerContactId: insurerId,
    ...personalDetailsValues,
    ...defaultValues
  };
};

export const getClaimFormManagementValues = (
  claim?: Claim
): ClaimFormManagementValues => {
  const diagnosisSplit = splitClaimDiagnosis(
    claim?.dto.referralDiagnosis || []
  );

  const referralInformationValues = claim?.referralIn
    ? {
        referralDate: DateTime.jsDateFromISO(claim?.dto.referralDate),
        accidentDate: DateTime.jsDateFromISO(claim?.dto.accidentDate),
        referralProvider: claim?.dto.referralProvider,
        primaryDiagnosis:
          diagnosisSplit.primaryDiagnosis &&
          mapToClaimDiagnosisReferralFormValues(
            diagnosisSplit.primaryDiagnosis
          ),
        referralDiagnosis: diagnosisSplit.claimDiagnosis.map(
          mapToClaimDiagnosisReferralFormValues
        ),
        hasAdditionalDiagnoses: (claim?.dto.referralDiagnosis?.length ?? 0) > 1,
        serviceItems: claim?.dto.serviceItems,
        addServiceItem: !!claim?.dto.serviceItems?.length
      }
    : undefined;

  const visitsValues = {
    allocated: claim?.dto.allocated,
    billed: claim?.dto.billed,
    booked: claim?.dto.booked,
    extensions: claim?.dto.extensions,
    previousInitialConsultVisits: claim?.dto.previousInitialConsultVisits,
    previousFollowUpVisits: claim?.dto.previousFollowUpVisits,
    appointmentVisits: claim?.appointmentVisits
  };

  const defaultValues = getClaimFormDefaultValues(claim);

  return {
    ...referralInformationValues,
    ...visitsValues,
    ...defaultValues
  };
};

export const getAddClaimManagementModalDto = (
  values: ClaimManagementModalFormValues,
  patient?: ClaimPatientFormValues
): AddClaimDto => {
  const {
    isDischarged,
    discharged,
    referralIn,
    claimNumber,
    insurerContactId,
    referralDate,
    ...rest
  } = values;

  let claimStatusCode: ClaimStatuses;
  if (referralIn) {
    claimStatusCode = ClaimStatuses.NotVerified;
  } else {
    claimStatusCode = ClaimStatuses.Incomplete;
  }

  return {
    ...rest,
    ...patient,
    referralIn: referralIn ?? false,
    claimNumber: referralIn ? claimNumber : "",
    discharged: isDischarged
      ? discharged || DateTime.now().toISODate()
      : undefined,
    statusCode: claimStatusCode,
    insurerContactId: values.private ? undefined : insurerContactId,
    patientDateOfBirth: patient
      ? DateTime.jsDateToISODate(patient.patientDateOfBirth)
      : undefined,
    referralDate: DateTime.jsDateToISODate(referralDate)
  };
};

export const getAddClaimManagementDto = (
  values: ClaimFormManagementValues,
  isReferral?: boolean
): AddClaimDto => {
  const {
    referralDate,
    referralDiagnosis,
    primaryDiagnosis,
    hasAdditionalDiagnoses,
    accidentDate,
    ...rest
  } = values;

  const referralDiagnosisDtos = mergeClaimDiagnosis(
    primaryDiagnosis || emptyReferralDiagnosis,
    referralDiagnosis || []
  )
    .filter(isReferralDiagnosisNotEmpty)
    .map(mapToReferalDiagnosisDto);

  const referralValues = isReferral
    ? {
        referralDate: DateTime.jsDateToISODate(referralDate),
        accidentDate: DateTime.jsDateToISODate(accidentDate),
        referralDiagnosis:
          referralDiagnosisDtos.length === 0 ? undefined : referralDiagnosisDtos
      }
    : undefined;

  return {
    ...rest,
    ...referralValues
  };
};

export const onSubmitAccManagementForm = async (options: {
  values: ClaimFormManagementValues;
  acc: AccStore;
  onSubmitRedirect?: (claimId?: string) => void;
  isReferral?: boolean;
}): Promise<string> => {
  const { values, acc, onSubmitRedirect, isReferral } = options;
  const claimDto = getAddClaimManagementDto(values, isReferral);

  let submittedClaim: Claim;

  if (claimDto.id) {
    submittedClaim = await patchExistingClaim(
      { ...claimDto, id: claimDto.id },
      acc
    );
  } else {
    submittedClaim = await addNewClaim(claimDto, acc);
  }

  if (onSubmitRedirect) {
    onSubmitRedirect(submittedClaim.id);
  }
  return submittedClaim.id;
};

export const getMenuItems = (
  purchaseOrdersCount: number,
  claim: Claim
): { text: string; id: ClaimManagementCardIds }[] => {
  const menuItems: { text: string; id: ClaimManagementCardIds }[] = [
    {
      text: ClaimManagementSideRailLabels.overview,
      id: ClaimManagementCardIds.overview
    },
    {
      text: ClaimManagementSideRailLabels.Consults,
      id: ClaimManagementCardIds.visits
    }
  ];
  if (claim.referralIn)
    menuItems.push({
      text: ClaimManagementSideRailLabels.referralInformation,
      id: ClaimManagementCardIds.referralInformation
    });
  if (isClaimAdjustmentAllowed(claim.claimStatus!))
    menuItems.push({
      text: `${ClaimManagementSideRailLabels.claimAdjustment} ${
        claim.claimAdjustments.length > 0
          ? `(${claim.claimAdjustments.length})`
          : ""
      }`,
      id: ClaimManagementCardIds.claimAdjustment
    });
  menuItems.push({
    text: `${ClaimManagementSideRailLabels.PurchaseOrders} ${
      purchaseOrdersCount > 0 ? `(${purchaseOrdersCount})` : ""
    }`,
    id: ClaimManagementCardIds.purchaseOrders
  });
  return menuItems;
};
