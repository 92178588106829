import { observer } from "mobx-react-lite";
import { InfiniteScrollList } from "ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { ScrollablePane, ScrollbarVisibility } from "@bps/fluent-ui";
import { useActionColumn } from "@modules/practice/screens/address-book/components/contacts-lists/contact-list-columns.tsx";
import { ExportPatientRecord } from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecord.tsx";
import { Contact } from "@stores/practice/models/Contact.ts";

import { useContactListSearch } from "../hooks/useContactListSearch.ts";
import { ContactListProps } from "./ContactList.types.ts";
import { ContactListNotFound } from "./ContactListNotFound.tsx";

export const ContactList: React.FC<ContactListProps> = observer(
  ({ columns, filter }) => {
    const actionColumn = useActionColumn();

    const search = useContactListSearch(filter);

    return (
      <div style={{ position: "relative", flexGrow: 1, margin: 16 }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always}>
          <InfiniteScrollList<Contact>
            setKey="id"
            stickyHeader
            onRenderNoResults={() => <ContactListNotFound filter={filter} />}
            getItems={search}
            columns={actionColumn ? [actionColumn, ...columns] : columns}
          />
          <ExportPatientRecord />
        </ScrollablePane>
      </div>
    );
  }
);
