import { FontWeights, NativeList, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

import {
  EN_DASH,
  formatDateStringToTime,
  MeasurementItemProps,
  ObservationsLabels
} from "./utils.ts";

export const GroupByExaminationMeasurementsView: React.FC<
  MeasurementItemProps
> = ({ primaryKey, measurements }) => {
  const hasBloodPressureOrPulse = measurements.some(
    m =>
      m.typeLabel === ObservationsLabels.BloodPressure ||
      m.typeLabel === ObservationsLabels.PulseBpmInTreeView
  );

  const renderBPAndPulseList = () => (
    <Stack tokens={{ childrenGap: 4 }}>
      <Stack horizontal verticalAlign="center">
        <Text
          styles={{
            root: {
              fontWeight: FontWeights.semibold
            }
          }}
        >
          {primaryKey}
        </Text>
      </Stack>
      <NativeList>
        {measurements.map(measurement => {
          let displayText = "";
          const labelText = measurement.label
            ? ` ${EN_DASH} ${measurement.label}`
            : "";

          switch (measurement.typeLabel) {
            case ObservationsLabels.BloodPressure:
              displayText =
                measurement.systolic && measurement.diastolic
                  ? `${measurement.systolic}/${measurement.diastolic}`
                  : measurement.value;
              break;

            case ObservationsLabels.PulseBpmInTreeView:
              displayText = measurement.pulse
                ? `${measurement.pulse} bpm`
                : `${measurement.value} bpm`;
              break;

            default:
              displayText = measurement.value;
              break;
          }

          const timestamp = formatDateStringToTime(measurement.timestamp);

          return (
            <li key={measurement.id}>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 4 }}
              >
                <Text>
                  {timestamp} {EN_DASH}{" "}
                </Text>
                <Text
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold
                    }
                  }}
                >
                  {displayText}
                </Text>
                <Text>{labelText}</Text>
              </Stack>
            </li>
          );
        })}
      </NativeList>
    </Stack>
  );

  const renderOtherMeasurements = () => {
    const isSingleMeasurementForDate = measurements.length === 1;
    const formattedDate = DateTime.fromISO(measurements[0].timestamp).toFormat(
      "dd/MM/yy"
    );

    return (
      <Stack verticalAlign="center">
        {measurements.length > 1 && (
          <Stack horizontal verticalAlign="center">
            <Text>{primaryKey}</Text>
          </Stack>
        )}

        {isSingleMeasurementForDate ? (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
            <Text>{`${formattedDate}${EN_DASH}`}</Text>
            <Text
              styles={{
                root: {
                  fontWeight: FontWeights.semibold
                }
              }}
            >
              {measurements[0].value}
            </Text>
          </Stack>
        ) : (
          measurements.map(measurement => {
            return (
              <Stack horizontal verticalAlign="center" key={measurement.id}>
                <Text
                  styles={{
                    root: {
                      fontWeight: FontWeights.semibold
                    }
                  }}
                >
                  {measurement.value}
                </Text>
              </Stack>
            );
          })
        )}
      </Stack>
    );
  };

  return (
    <Stack
      key={primaryKey}
      styles={{
        root: { marginLeft: 32 }
      }}
    >
      {hasBloodPressureOrPulse
        ? renderBPAndPulseList()
        : renderOtherMeasurements()}
    </Stack>
  );
};
