import { observer } from "mobx-react-lite";

import { FilterBarProps, Item } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

export interface SyncFusionAutillFilterState {
  search?: string;
}

export interface SyncFusionAutofillFilterProps
  extends Pick<FilterBarProps, "children"> {
  onSearch: (values: SyncFusionAutillFilterState) => void;
}

export const nameOf = nameOfFactory<SyncFusionAutillFilterState>();

export const SyncFusionAutofillFilter: React.FC<SyncFusionAutofillFilterProps> =
  observer(({ onSearch, children }) => {
    const filterBarItems: Item<SyncFusionAutillFilterState>[] = [
      {
        type: "searchBox",
        name: nameOf("search"),
        stickItem: true,
        props: {
          placeholder: "Search via name",
          styles: { root: { flexGrow: 1 } }
        }
      }
    ];

    return (
      <FilterBar<SyncFusionAutillFilterState>
        onChange={({ values }) => {
          onSearch(values);
        }}
        items={filterBarItems}
        children={children}
        presets={undefined}
        hideResetFilterButton
      />
    );
  });
