import { DateTime } from "@bps/utils";
import {
  predicate,
  required,
  todayOrLater
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";

import {
  ImagingRequestFormValues,
  Modality,
  Region
} from "./ImagingRequestDialog.types.ts";

function isDuplicateRegion(regions: Region[], regionToCheck: Region) {
  const seen = new Set<string>();

  for (const region of regions) {
    if (region.region === regionToCheck.region) {
      if (seen.has(region.region)) {
        return true;
      } else {
        seen.add(region.region);
      }
    }
  }
  return false;
}

function duplicateDateCheck(tasks?: ClinicalTask[], date?: Date) {
  const match = tasks?.find(
    t =>
      t?.dueDate &&
      date?.toDateString() === DateTime.jsDateFromISO(t.dueDate).toDateString()
  );
  return match !== undefined;
}

export class ImagingRequestValidator extends Validator<ImagingRequestFormValues> {
  constructor(clinicalTask?: ClinicalTask[], toggle?: boolean) {
    super();

    const modalityValidator = new ImagingModalityValidator();

    this.forField("requestDate", required());
    this.forField("to", required());
    this.forArrayField("modalities", modalityValidator.validate);
    toggle &&
      this.forField("clinicalTaskDueDate", [
        required(),
        val => {
          if (duplicateDateCheck(clinicalTask, val)) {
            return ValidationMessages.noDuplicateClinicalTasksAllowed;
          } else return undefined;
        },
        todayOrLater
      ]);
  }
}

export class ImagingModalityValidator extends Validator<Modality> {
  constructor() {
    super();

    this.forField("modality", required());

    this.forArrayField("regions", (value: Region, values: Modality) => {
      const regionValidator = new ImagingRegionsValidator();
      return regionValidator.validate(value, values);
    });
  }
}

export class ImagingRegionsValidator extends Validator<Region, Modality> {
  constructor() {
    super();

    const requiredCustomViewSelection = (
      customViewDetails: string[],
      parent: Region
    ) => {
      return parent.customViews && customViewDetails.length === 0;
    };

    const requiredOtherDetails = (otherDetails: string, parent: Region) => {
      return parent.other && otherDetails.trim().length === 0;
    };

    this.forField("region", [
      predicate((value, parent: Region, modality: Modality) => {
        if (modality.regions.length === 1) return true;
        return (
          parent.side.length > 0 ||
          parent.customViewsDetails.length > 0 ||
          !!parent.otherDetails
        );
      }, required())
    ]);
    this.forField(
      "customViewsDetails",
      predicate(requiredCustomViewSelection, required())
    );

    this.forField("region", (value, region, modality) => {
      if (isDuplicateRegion(modality.regions, region)) return "Duplicate";
      else return;
    });

    this.forField("otherDetails", predicate(requiredOtherDetails, required()));
  }
}
