import { ConfirmDialog } from "@bps/fluent-ui";

interface ClaimDeleteInvoiceDialogProps {
  text: string;
  onDismiss: () => void;
}

export const ClaimDeleteInvoiceDialog: React.FC<
  ClaimDeleteInvoiceDialogProps
> = ({ text, onDismiss }) => {
  return (
    <ConfirmDialog
      hidden={false}
      confirmButtonProps={{
        text: undefined,
        styles: {
          root: {
            display: "none"
          }
        }
      }}
      cancelButtonProps={{ text: "Cancel" }}
      dialogContentProps={{
        title: "Unable to delete claim",
        subText: text,
        showCloseButton: true
      }}
      onConfirm={() => {}}
      onCancel={onDismiss}
    />
  );
};
