import { observer } from "mobx-react-lite";
import {
  FunctionComponent,
  MouseEvent,
  useLayoutEffect,
  useRef,
  useState
} from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  FontWeights,
  getColumnClampLinesSelector,
  IStackStyles,
  mergeStyles,
  mergeStyleSets,
  noWrap,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import {
  BookedByTypeEnum,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { getEventReminderStatusColours } from "@modules/booking/screens/event-reminders/components/utils.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getBookingCalendarEventStylesSet } from "./BookingCalendarEvent.styles.ts";
import { BookingCalendarEventProps } from "./BookingCalendarEvent.types.ts";
import { BookingCalendarEventIcons } from "./BookingCalendarEventIcons.tsx";
import { GroupAppointmentTitle } from "./GroupAppointmentTitle.tsx";

export const BookingCalendarEventComponent: FunctionComponent<BookingCalendarEventProps> =
  observer(({ event }) => {
    const { core } = useStores();
    const { setCurrentEventData, pushToOrgUnitIds } =
      useBookingCalendarScreenContext();

    const theme = useTheme();

    const {
      contact,
      typeRef,
      startDateTime,
      id,
      contactId,
      userId,
      isPartOfRecurrence,
      changeLog,
      isGroupAppointment
    } = event;

    const createdDate = changeLog?.createdDate;
    const eventTypeText = (typeRef && typeRef.text) || "Appointment";
    const isUnavailableSeries =
      event.type === CalendarEventType.Unavailable &&
      event.calendarEventRecurrenceId;

    //calender event height
    const [elementHeight, setElementHeight] = useState<number | undefined>();
    const ref = useRef<HTMLDivElement | null>(null);
    const layout = { headerHeight: 15, rowHeight: 20 };

    useLayoutEffect(() => {
      const height = ref.current?.clientHeight;
      setElementHeight(height);
    }, []);

    const calendarEventRows =
      elementHeight !== undefined
        ? (elementHeight - layout.headerHeight) / layout.rowHeight
        : 1;

    const attendanceStatus = event.attendanceStatus;

    const eventReminderStatusColor = getEventReminderStatusColours(theme);

    let name: string | undefined;
    if (isUnavailableSeries) {
      name = event.purpose;
    } else if (event.type === CalendarEventType.TemporaryReservation) {
      if (event.hasMultipleLocks) {
        name = "Time slot in use by multiple people";
      } else if (event.bookedByType === BookedByTypeEnum.onlineBooking) {
        name = "Time slot in use by online booking application";
      } else {
        const initial = event.bookedByUser?.firstName.substr(0, 1);
        const lastName = event.bookedByUser?.lastName;
        name = `Time slot in use by ${initial}.${lastName}`;
      }
    } else if (event.type === CalendarEventType.ClosedException) {
      name = "Unavailable";
    } else if (event.type === CalendarEventType.AnotherLocation) {
      name = `Working at ${core.getLocationName(event.orgUnitId)}`;
    } else {
      name = contact?.preferredName || eventTypeText;
    }

    const classNames = [
      `calendar-event-${event.type.toLocaleLowerCase()}`,
      `calendar-dt-${startDateTime.toFormat(DATE_FORMATS.TEST_DATE_FORMAT)}`
    ];
    if (event.id) {
      classNames.push(`data-calendar-event-id-${event.id}`);
    }
    if (isPartOfRecurrence) {
      classNames.push("calendar-event-recurrence-part");
    }

    if (contactId) {
      classNames.push(`calendar-patient-${contactId}`);
    }
    if (userId) {
      classNames.push(`calendar-provider-${userId}`);
    }

    const {
      bookingCalendarEventMainStyles,
      bookingCalendarEventNameStyle,
      bookingCalendarEventNoChargeStyles,
      bookingCalendarEventContentStyles,
      getProviderCommentIconsStyle,
      getBookingCalendarEventStyles,
      getBookingCalendarNameWrapperStyles
    } = getBookingCalendarEventStylesSet(theme);

    const bookingCalendarEventSlotContentStyles = mergeStyleSets(
      {
        root: getColumnClampLinesSelector(calendarEventRows)
      },
      {
        root: {
          lineHeight: "14px",
          fontSize: "12px",
          textOverflow: "initial",
          wordBreak: "break-all",
          marginTop: "5px"
        }
      }
    );

    const bookingCalendarEventSlotGroupContentStyles = mergeStyleSets(
      {
        root: getColumnClampLinesSelector(calendarEventRows)
      },
      {
        root: {
          lineHeight: "14px",
          fontSize: "10px",
          textOverflow: "initial",
          wordBreak: "break-all",
          marginTop: "5px"
        }
      }
    );

    const onClick = (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      if (!event.isPseudoEvent) {
        setCurrentEventData({
          event: event.model,
          calloutPosition: evt.nativeEvent
        });
      }

      if (event.type === CalendarEventType.AnotherLocation) {
        pushToOrgUnitIds(event.orgUnitId);
      }
    };

    const onShowContext = (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
      !event.isPseudoEvent &&
        setCurrentEventData({
          event: event.model,
          contextualMenuPosition: evt.nativeEvent
        });
    };

    const mainStyles: IStackStyles = mergeStyleSets(
      bookingCalendarEventMainStyles,
      event.type === CalendarEventType.ClosedException
        ? {
            root: {
              height: "100%",
              justifyContent: "center"
            }
          }
        : undefined
    );

    return (
      <Stack
        {...dataAttribute(DataAttributes.Element, "booking-calendar-event")}
        horizontal
        onClick={onClick}
        onContextMenu={onShowContext}
        styles={{ root: { height: "100%" } }}
        className={classNames.join(" ")}
      >
        <Stack
          horizontal
          styles={getBookingCalendarEventStyles(event)}
          tokens={{ childrenGap: 4 }}
        >
          <div style={{ flexGrow: 1, minWidth: 0 }} ref={ref}>
            <Stack styles={mainStyles}>
              <Stack
                horizontal
                styles={getBookingCalendarNameWrapperStyles(event)}
                tokens={{ childrenGap: 4 }}
              >
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 4 }}
                >
                  {event.model.appointmentType?.icon && (
                    <FontIcon
                      iconName={event.model.appointmentType?.icon}
                      styles={{ root: { width: 10 } }}
                    />
                  )}
                  {!isGroupAppointment && attendanceStatus && (
                    <Stack
                      styles={{
                        root: {
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          background: eventReminderStatusColor(attendanceStatus)
                        }
                      }}
                    />
                  )}
                </Stack>
                {isGroupAppointment ? (
                  <GroupAppointmentTitle event={event} />
                ) : (
                  <Stack.Item
                    grow
                    styles={{
                      root: {
                        ...noWrap,
                        paddingTop: 2,
                        lineHeight: 14 // this stops the letters like p, q, and g from being cut off at the bottom
                      }
                    }}
                  >
                    {name?.toUpperCase() === "APPOINTMENT" && (
                      <FontIcon
                        iconName="ProfileSearch"
                        styles={{
                          root: {
                            marginRight: 4,
                            width: 12,
                            fontSize: FontSizes.size12,
                            fontWeight: FontWeights.semibold,
                            color: theme.palette.redDark
                          }
                        }}
                      />
                    )}
                    <span
                      className={`calendar-event-name ${mergeStyles(
                        bookingCalendarEventNameStyle
                      )}`}
                      data-calendar-event-id={id}
                      data-calendar-event-type={eventTypeText}
                      data-calendar-create-date={createdDate}
                    >
                      {name}
                    </span>
                  </Stack.Item>
                )}
                {/* If it is a single line calendar event */}
                {calendarEventRows < 1 && (
                  <Stack
                    styles={mergeStyleSets({
                      root: getColumnClampLinesSelector(1)
                    })}
                  >
                    <Stack.Item styles={bookingCalendarEventContentStyles}>
                      {event.model.content}
                    </Stack.Item>
                  </Stack>
                )}

                {event.model.noChargeComment && !isGroupAppointment && (
                  <Stack.Item styles={bookingCalendarEventNoChargeStyles}>
                    NC
                  </Stack.Item>
                )}
                <Stack.Item
                  className={mergeStyles(getProviderCommentIconsStyle(event))}
                >
                  {event.model.providerComment && !isGroupAppointment && (
                    <FontIcon iconName="CommentActive" />
                  )}
                </Stack.Item>
                {!isGroupAppointment && (
                  <Stack.Item>
                    <BookingCalendarEventIcons event={event} />
                  </Stack.Item>
                )}
              </Stack>
              {calendarEventRows >= 1 && (
                <>
                  {isGroupAppointment ? (
                    <Stack.Item
                      styles={bookingCalendarEventSlotGroupContentStyles}
                    >
                      {`${event.model?.activeAttendees.length} participants (max ${event.model?.maxParticipants})`}
                    </Stack.Item>
                  ) : (
                    <Stack.Item styles={bookingCalendarEventSlotContentStyles}>
                      {event.model.content}
                    </Stack.Item>
                  )}
                </>
              )}
            </Stack>
          </div>
        </Stack>
      </Stack>
    );
  });

export const BookingCalendarEvent = withFetch(
  x => [x.booking.ref.calendarEventTypes.load()],
  BookingCalendarEventComponent
);
