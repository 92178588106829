import { FunctionComponent } from "react";

import { Spinner, Stack, useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { PatientNoticesAddButton } from "./PatientNoticesAddButton.tsx";
import { PatientNoticesContextProvider } from "./PatientNoticesContext.tsx";
import { NoticeType, PatientNoticesModel } from "./PatientNoticesModel.ts";
import { PatientNoticesTable } from "./PatientNoticesTable.tsx";

interface PatientNoticesEditDemographicsCardProps {
  patient: Contact;
}
export const PatientNoticesEditDemographicsCard: FunctionComponent<
  PatientNoticesEditDemographicsCardProps
> = ({ patient }) => {
  const { practice } = useStores();
  const theme = useTheme();
  return (
    <div
      style={{
        width: 230,
        position: "relative",
        overflowY: "auto",
        height: "30%",
        padding: "4px 0px",
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: theme.palette.neutralLight
      }}
    >
      <DataFetcher
        fetch={({ practice }) =>
          Promise.all([
            practice.getPatientNotices(patient.id),
            practice.loadSystemNotices([patient.id])
          ])
        }
        fallback={<Spinner />}
      >
        {() => (
          <Stack styles={{ root: { height: "100%" } }}>
            <PatientNoticesContextProvider
              value={
                new PatientNoticesModel(practice, {
                  patientId: patient.id,
                  withSystemNotices: true
                })
              }
            >
              <PatientNoticesAddButton filter={NoticeType.Admin} />
              <PatientNoticesTable
                filter={NoticeType.Admin}
                childrenGap={0}
                padding="0 8px 8px 8px"
                greyBackground
                showActions
                showNoDataTile
                noDataMessage="No admin notices"
              />
            </PatientNoticesContextProvider>
          </Stack>
        )}
      </DataFetcher>
    </div>
  );
};
