import { mergeStyles } from "@fluentui/merge-styles";

export const enterSlideSideStyle = mergeStyles({
  maxWidth: 0
});

export const enterSlideSideStyleActive = mergeStyles({
  maxWidth: "100%",
  transition: "max-width 200ms"
});

export const exitSlideSideStyle = mergeStyles({
  maxWidth: "100%"
});

export const exitSlideSideStyleActive = mergeStyles({
  maxWidth: 0,
  transition: "max-width 200ms"
});
