import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  AddressDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { RelationshipTypeText } from "@ui-components/RefText.tsx";

interface InvoiceAccountContactSelectSuggestionItemProps {
  contact: Contact;
  patient?: Contact;
}

export const InvoiceAccountContactSelectSuggestionItem: FunctionComponent<InvoiceAccountContactSelectSuggestionItemProps> =
  observer(({ contact, patient }) => {
    const address: AddressDto | undefined =
      contact.physicalAddress ?? contact.postalAddress;

    const relationships = patient?.relationships.filter(
      relationship => relationship.relatedContactId === contact.id
    );

    if (
      relationships?.some(
        relationship =>
          relationship.relationship === RelationshipType.AccountHolder &&
          relationship.metadata?.isPrimary
      )
    ) {
      return <>Primary account holder</>;
    }

    if (
      relationships?.some(
        relationship =>
          relationship.relationship === RelationshipType.AccountHolder
      )
    ) {
      return <>Account holder</>;
    }

    if (relationships?.length) {
      return <RelationshipTypeText code={relationships[0].relationship} />;
    }

    let secondaryText: string = "";
    if (contact?.birthDate) {
      secondaryText += contact.birthDate.toDayDefaultFormat();
      if (address?.suburb) {
        secondaryText += " ";
      }
    }

    if (address?.suburb) {
      secondaryText += address.suburb;
    }

    return <>{secondaryText}</>;
  });
