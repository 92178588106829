import {
  ClinicalDataType,
  IntakeTypesFormTitle,
  TobaccoClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { IntakeForm } from "../intake/IntakeForm.tsx";
import { IntakeFormValues } from "../intake/IntakeForm.types.ts";
import { getCombinedIntakes, getInitialValues } from "../intake/utils.ts";
import { TobaccoFormValidator } from "./validators/TobaccoFormValidator.tsx";

interface TobaccoFormProps {
  clinicalRecord: ClinicalRecord;
}

export const TobaccoForm: React.FC<TobaccoFormProps> = ({ clinicalRecord }) => {
  const { core } = useStores();
  const tobaccoFormValidator = new TobaccoFormValidator(
    {
      dateOfBirth: clinicalRecord.patient?.birthDate?.toJSDate()
    },
    core
  );

  const getClinicalData = (values: IntakeFormValues) => {
    const {
      deletedIntakes,
      currentIntakes,
      historicalIntakes,
      status,
      comment,
      exSmokerLessThan12Months,
      intakeConfirmed
    } = values;

    const initialValues = getInitialValues({
      intakeConfirmed,
      intake: clinicalRecord.clinicalData?.tobacco,
      domain: ClinicalDataType.Tobacco,
      dateOfBirth: clinicalRecord.patient?.birthDate
    });

    const intakes = getCombinedIntakes({
      deletedIntakes,
      currentIntakes,
      historicalIntakes,
      initialCurrentIntakes: initialValues.currentIntakes
    });

    const eTag = clinicalRecord.clinicalData?.tobacco?.eTag;
    const confirmedETag = clinicalRecord.clinicalData?.tobaccoConfirmed?.eTag;

    const tobacco: TobaccoClinicalDataDto = {
      eTag,
      intakes,
      status,
      comment,
      exSmokerLessThan12Months
    };

    return {
      tobacco,
      tobaccoConfirmed: {
        eTag: confirmedETag,
        confirmed: true
      }
    };
  };

  const submitData = async (values: IntakeFormValues) => {
    const clinicalData = getClinicalData(values);
    await clinicalRecord.saveClinicalData(clinicalData);
  };

  return (
    <IntakeForm
      formTitle={IntakeTypesFormTitle.TobaccoTitle}
      onSubmit={submitData}
      getData={getClinicalData}
      maxIntakes={6}
      validate={tobaccoFormValidator.validate}
      domain={ClinicalDataType.Tobacco}
      clinicalRecord={clinicalRecord}
    />
  );
};
