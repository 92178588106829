import React from "react";

import { Link, noWrap, Stack } from "@bps/fluent-ui";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";

import { ConfidentialToolTipFontIcon } from "../patient-record/components/ConfidentialToolTipFontIcon.tsx";
import { CorrespondenceDirectionBadge } from "../patient-record/components/correspondence/CorrespondenceDirectionBadge.tsx";

interface ClinicalDocumentViewerLinkProps {
  record: ClinicalDocument;
  onClick: (item: ClinicalDocument) => Promise<void>;
  isCorrespondence?: boolean;
}

export const ClinicalDocumentViewerLink: React.FC<
  ClinicalDocumentViewerLinkProps
> = ({ record, onClick, isCorrespondence }) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      {isCorrespondence && (
        <CorrespondenceDirectionBadge direction={record.direction} />
      )}

      <Link
        styles={{ root: { ...noWrap } }}
        onClick={() => {
          onClick(record);
        }}
      >
        {record.name}
      </Link>

      <ConfidentialToolTipFontIcon
        isShowConfidentialIcon={record.secGroupId !== undefined}
        content="Confidential. Use action menu to release"
      />
    </Stack>
  );
};
