import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { useAllocatableAmount } from "@modules/billing/screens/shared-components/allocation-field/hooks/useAllocatableAmount.ts";
import { useAllocationsTotal } from "@modules/billing/screens/shared-components/allocation-field/hooks/useAllocationsTotal.tsx";
import {
  LabelData,
  StatusLabel
} from "@modules/billing/screens/shared-components/StatusLabel.tsx";

import { AllocationItemsTotal } from "./AllocationForm.types.tsx";

export const AllocationStatusLabel: React.FunctionComponent = () => {
  const { owingRemaining, allocatedTotal } = useAllocationsTotal();

  const allocation = useAllocatableAmount() || 0;
  const getBalance = (): {
    label: AllocationItemsTotal;
    value: string;
  } => {
    if (allocation === 0 || allocatedTotal >= allocation) {
      return {
        label: AllocationItemsTotal.unallocatedPrefixed,
        value: currencyFormat(0, {
          currency: ""
        })
      };
    } else if (
      allocatedTotal === owingRemaining &&
      allocation > owingRemaining
    ) {
      return {
        label: AllocationItemsTotal.creditPrefixed,
        value: currencyFormat(allocation - owingRemaining, { currency: "" })
      };
    } else {
      const amount =
        allocation - allocatedTotal < 0
          ? allocation
          : allocation - allocatedTotal;
      return {
        label: AllocationItemsTotal.unallocatedPrefixed,
        value: currencyFormat(amount || 0, { currency: "" })
      };
    }
  };

  const labelsData: LabelData[] = [
    {
      id: "status-label-total-account",
      data: {
        label: AllocationItemsTotal.totalAccountPrefixed,
        value: currencyFormat(owingRemaining, { currency: "" })
      }
    },
    {
      id: "status-label-payment",
      data: {
        label: AllocationItemsTotal.paymentPrefixed,
        value: currencyFormat(allocation || 0, { currency: "" })
      }
    },
    {
      id: "status-label-balance",
      data: getBalance()
    }
  ];

  return <StatusLabel childrenGap={80} labelData={labelsData} />;
};
