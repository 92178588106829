import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef, useState } from "react";

import {
  IconButton,
  IContextualMenuItem,
  Spinner,
  Stack,
  Tile,
  ToolTipButton,
  useTheme
} from "@bps/fluent-ui";
import {
  QuickAccessSettingDto,
  QuickAccessType
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

import { useNewClinicalTheme } from "../../../../hooks/useNewClinicalTheme.tsx";
import { ImagingRequestDialog } from "../investigations/imaging-request-dialog/ImagingRequestDialog.tsx";
import { CorrespondencePrint } from "../patient-summary/CorrespondencePrint.tsx";
import { MedicationsContext } from "../prescribing/helpers/MedicationsContext.tsx";
import { MedicationsHelper } from "../prescribing/helpers/MedicationsHelper.ts";
import { PrescribingWizard } from "../prescribing/PrescribingWizard.tsx";
import {
  QuickAccessContext,
  useQuickAccessContext
} from "./context/QuickAccessContext.tsx";
import { QuickAccessHelper } from "./context/QuickAccessHelper.ts";
import { EditQuickAccessDialog } from "./EditQuickAccessDialog.tsx";
import { QuickAccessDocumentWriterDialog } from "./QuickAccessDocumentWriterDialog.tsx";
import {
  OVERFLOW_INDEX,
  OVERFLOW_LENGTH,
  separator
} from "./QuickAccessLinks.Types.ts";
import { QuickAccessOnlineFormsDialog } from "./QuickAccessOnlineFormsDialog.tsx";

export const QuickAccessBase: FunctionComponent = observer(() => {
  const root = useStores();
  const theme = useTheme();
  const clinicalTheme = useNewClinicalTheme();
  const { userExperience } = root;
  const {
    quickAccessImagingRequest,
    quickAccessPrescribingRx,
    showEditQuickAccessDialog,
    showDocumentWriterMergeFormDialog,
    showOnlineFormsDialog,
    setDocumentWriterMergeFormDialog,
    setQuickAccessImagingRequest,
    setQuickAccessPrescribingRx,
    setShowEditQuickAccessDialog,
    setSelectedTemplate,
    setShowOnlineFormsDialog
  } = useQuickAccessContext();

  const quickAccessSettings: QuickAccessSettingDto[] =
    userExperience.quickAccessSettings?.filter(x => !x.isHidden) ?? [];

  const addIcon: QuickAccessSettingDto = {
    code: QuickAccessType.Add,
    icon: "Add",
    title: "Add"
  };

  const moreIcon: QuickAccessSettingDto = {
    code: QuickAccessType.More,
    icon: "More",
    title: "More"
  };

  const hiddenQuickAccess = quickAccessSettings.filter((x, index) => {
    return index > OVERFLOW_INDEX;
  });

  const showQuickAccess = quickAccessSettings.filter((x, index) => {
    return index <= OVERFLOW_INDEX;
  });

  const displayQuickAccess: QuickAccessSettingDto[] =
    quickAccessSettings.length > OVERFLOW_LENGTH
      ? [...showQuickAccess, moreIcon]
      : [...showQuickAccess, addIcon];

  const { palette } = useTheme();

  const { clinicalRecord } = usePatientRecordScreenContext();

  const onQuickAccessClick = async (
    quickAccessType: string,
    documentId?: string,
    isSourceDeleted?: boolean
  ) => {
    if (isSourceDeleted && quickAccessType === QuickAccessType.Document) {
      setShowEditQuickAccessDialog(true);
      return;
    }
    switch (quickAccessType) {
      case QuickAccessType.PrescribingRx: {
        setQuickAccessPrescribingRx(true);
        break;
      }
      case QuickAccessType.ImagingRequest: {
        setQuickAccessImagingRequest(true);
        break;
      }
      case QuickAccessType.Add: {
        setShowEditQuickAccessDialog(true);
        break;
      }
      case QuickAccessType.Document: {
        setDocumentWriterMergeFormDialog(true);
        setSelectedTemplate(documentId);
        break;
      }
      case QuickAccessType.OnlineForm: {
        setShowOnlineFormsDialog(true);
        break;
      }
    }
  };

  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [imagingRequest, setImagingRequest] = useState<
    ClinicalDocument | undefined
  >(undefined);

  const medicationsHelper = useRef(new MedicationsHelper(clinicalRecord, root));

  const moreMenuProps = (): IContextualMenuItem[] => {
    const moreMenuItems: IContextualMenuItem[] = hiddenQuickAccess.map(x => ({
      key: `${x.code}-${x.documentId}`,
      text: `${x.title.split("/").pop()} ${
        x.isSourceTemplateDeleted ? "( Missing source )" : ""
      }`,
      onClick: () => {
        onQuickAccessClick(x.code, x.documentId, x.isSourceTemplateDeleted);
      },
      iconProps: { iconName: x.icon },
      style: {
        backgroundColor: x.isSourceTemplateDeleted
          ? theme.semanticColors.errorBackground
          : undefined
      }
    }));

    return [
      ...moreMenuItems,
      separator,
      {
        key: QuickAccessType.Add,
        text: "Add / edit shortcuts",
        onClick: () => {
          setShowEditQuickAccessDialog(true);
        },
        iconProps: { iconName: QuickAccessType.Add }
      }
    ];
  };

  const quickAccessContextMenu = (value: QuickAccessSettingDto) => {
    return (
      <IconButton
        key={`${value.code}${value.title}`}
        styles={{
          root: {
            height: 48,
            padding: 0
          },
          flexContainer: {
            width: 48
          }
        }}
        menuIconProps={{ iconName: "more" }}
        menuProps={{
          items: moreMenuProps()
        }}
      />
    );
  };

  return (
    <>
      <Tile
        styles={{
          root: {
            marginBottom: clinicalTheme.spacing.s1
          }
        }}
      >
        <Stack horizontal tokens={{ childrenGap: 4 }}>
          {displayQuickAccess?.map(x => {
            if (x.code === QuickAccessType.More)
              return quickAccessContextMenu(x);

            const isSourceTemplateDeleted =
              x.isSourceTemplateDeleted && x.code === QuickAccessType.Document;

            return (
              <ToolTipButton
                key={`${x.code}-${x.title}`}
                buttonProps={{
                  onClick: () => {
                    onQuickAccessClick(
                      x.code,
                      x.documentId,
                      x.isSourceTemplateDeleted
                    );
                  },
                  iconProps: {
                    iconName:
                      userExperience.getQuickAccessIcon(x.icon)?.text ?? x.icon,
                    styles: {
                      root: {
                        fontSize: 24
                      }
                    }
                  },
                  className: "quick-access-btn",
                  style: {
                    border:
                      x.icon === QuickAccessType.Add ||
                      x.icon === QuickAccessType.More
                        ? "0px"
                        : `1px solid ${palette.neutralLight}`,
                    minWidth: 0,
                    height: 48,
                    width: 48,
                    padding: 0,
                    backgroundColor: isSourceTemplateDeleted
                      ? theme.semanticColors.errorBackground
                      : undefined
                  }
                }}
                toolTipContent={
                  isSourceTemplateDeleted
                    ? "Source is missing"
                    : x.title.split("/").pop()
                }
              />
            );
          })}
        </Stack>
      </Tile>
      {quickAccessImagingRequest && (
        <ImagingRequestDialog
          onDismiss={() => setQuickAccessImagingRequest(false)}
          clinicalRecord={clinicalRecord}
          printImagingRequest={(document: ClinicalDocument) => {
            setIsPrinting(true);
            setImagingRequest(document);
          }}
        />
      )}

      {isPrinting && imagingRequest && imagingRequest?.id && (
        <CorrespondencePrint
          completePrinting={() => setIsPrinting(false)}
          clinicalDocument={imagingRequest}
          documentTitle={imagingRequest.name}
        />
      )}
      {quickAccessPrescribingRx && (
        <MedicationsContext.Provider value={medicationsHelper.current}>
          <PrescribingWizard />
        </MedicationsContext.Provider>
      )}

      {showDocumentWriterMergeFormDialog && <QuickAccessDocumentWriterDialog />}
      {showEditQuickAccessDialog && <EditQuickAccessDialog />}
      {showOnlineFormsDialog && <QuickAccessOnlineFormsDialog />}
    </>
  );
});

export const QuickAccess = withFetch(
  x => [
    x.userExperience.getCurrentUserQuickAccess(),
    x.userExperience.ref.quickAccessIcon.load(),
    x.correspondence.ref.correspondenceTypes.load()
  ],

  () => {
    const root = useStores();
    const helper = usePersistedHelper<QuickAccessHelper>(
      () => new QuickAccessHelper(root)
    );

    return (
      <QuickAccessContext.Provider value={helper}>
        <QuickAccessBase />
      </QuickAccessContext.Provider>
    );
  },
  { fallback: <Spinner /> }
);
