import { ValidationErrors } from "final-form";
import { action, observable, when } from "mobx";

import { ServiceRuleType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { isDeepEmptyUtils } from "@libs/utils/isDeepEmpty.utils.ts";
import type { ValidationResult } from "@libs/validation/validation.types.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { RootStore } from "@stores/root/RootStore.ts";

interface IWarningResult {
  error?: string;
  loading?: true;
  messages?: string[];
}

export class ServiceWarningsHelper {
  constructor(private root: RootStore) {}

  // If an error occurs, save it here and do no further API requests (user will have to refresh the page to fix it)
  // This is necessary as since the API requests are triggered by a render,
  //  we don't want them to keep repeatedly called every time a render is triggered
  @observable
  error?: string;

  @action
  catchError = (e: Error) => {
    this.root.notification.error(e.message);
    this.error = e.message;
  };

  getServiceWarnings = (
    item: InvoiceItemFormValue,
    options: { claimId?: string }
  ): IWarningResult => {
    if (this.error) {
      return { error: this.error };
    }

    const claimId = options?.claimId;
    const claim = claimId ? this.root.acc.claimsMap.get(claimId) : undefined;
    const rules = item.serviceSearch?.rules || [];
    const accRule = rules.find(r => r.ruleType === ServiceRuleType.ACC);

    const warningMessages: string[] = [];

    if (accRule && claimId && !this.root.acc.claimsMap.has(claimId)) {
      this.root.acc.getClaim(claimId).catch(this.catchError);
      return { loading: true };
    }

    if (accRule && claim?.isDischarged) {
      warningMessages.push("Claim has been discharged");
    }

    return { messages: warningMessages };
  };

  getServiceErrors = (
    item: InvoiceItemFormValue,
    options: { claimId?: string }
  ): IWarningResult => {
    if (this.error) {
      return { error: this.error };
    }

    const claimId = options?.claimId;
    const claim = claimId ? this.root.acc.claimsMap.get(claimId) : undefined;
    const rules = item.serviceSearch?.rules || [];
    const accRule = rules.find(r => r.ruleType === ServiceRuleType.ACC);

    const errorMessages: string[] = [];

    if (accRule && claimId && !this.root.acc.claimsMap.has(claimId)) {
      this.root.acc.getClaim(claimId).catch(this.catchError);
      return { loading: true };
    }

    if (accRule && !claim) {
      errorMessages.push(
        "A claim must be linked before ACC items can be added"
      );
    }

    if (
      rules.some(r => r.ruleType === ServiceRuleType.PORequired) &&
      !item.purchaseOrderNumber
    ) {
      errorMessages.push("Purchase order number required");
    }

    return { messages: errorMessages };
  };

  getServiceErrorsAndWarnings = (
    item: InvoiceItemFormValue,
    options: { claimId?: string }
  ): IWarningResult => {
    const warningResult = this.getServiceWarnings(item, options);
    const errorResult = this.getServiceErrors(item, options);

    return {
      ...warningResult,
      ...errorResult,
      messages:
        warningResult.messages && errorResult.messages
          ? [...errorResult.messages, ...warningResult.messages]
          : undefined
    };
  };

  getWarningIndexes = (
    items: InvoiceItemFormValue[],
    options: { claimId?: string }
  ): number[] => {
    return items.reduce((indexes: number[], item, index) => {
      const { messages } = this.getServiceWarnings(item, options);
      if (messages?.length) {
        indexes.push(index);
      }

      return indexes;
    }, []);
  };

  getErrorIndexes = (
    items: InvoiceItemFormValue[],
    options: { claimId?: string }
  ): number[] => {
    return items.reduce((indexes: number[], item, index) => {
      const { messages } = this.getServiceErrors(item, options);
      if (messages?.length) {
        indexes.push(index);
      }

      return indexes;
    }, []);
  };

  validate = async (
    items: InvoiceItemFormValue[],
    options: { claimId?: string }
  ): Promise<ValidationErrors> => {
    await when(() => {
      const results = items.map(item => this.getServiceErrors(item, options));
      return (
        results.some(result => result.error) ||
        results.every(result => !result.loading)
      );
    });

    if (this.error) {
      return { invoiceItems: this.error };
    }

    const validationResult: ValidationResult = {
      invoiceItems: items.map(item => {
        const { messages = [] } = this.getServiceErrors(item, options);
        if (messages.length > 0) {
          return { error: messages[0] };
        }

        return {};
      })
    };

    if (isDeepEmptyUtils(validationResult)) {
      return {};
    }

    return validationResult;
  };
}
