import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

export interface SendReminderFormValues {
  templateId?: string;
  reminderMessage?: string;
}
export class SendReminderValidator extends Validator<SendReminderFormValues> {
  constructor() {
    super();
    this.forField(
      "reminderMessage",
      predicate((value, values) => values?.templateId === "", required())
    );
    this.forField(
      "reminderMessage",
      predicate((value, values) => values?.templateId === "", maxLength(159))
    );
  }
}
