import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontIcon,
  FontSizes,
  IColumn,
  mergeStyleSets,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  BillingStatuses,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { CommentCell } from "@modules/billing/screens/allocation/components/CommentCell.tsx";
import { convertQuantity } from "@modules/billing/screens/allocation/utils.ts";
import {
  FooterOptions,
  HeaderOptions,
  InvoiceTotals,
  TotalField
} from "@modules/billing/screens/shared-components/AllocationList/AllocationListBase.types.tsx";
import { ChangeTypes } from "@modules/billing/screens/shared-components/change-type/change-types.enum.tsx";
import { ChangeType } from "@modules/billing/screens/shared-components/change-type/ChangeType.tsx";
import { InvoiceItemListBase } from "@modules/billing/screens/shared-components/invoice-list-base/InvoiceItemListBase.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InvoiceListItem } from "./Invoice.types.ts";
import {
  invoiceFormNameOf,
  InvoiceItemsColumnNames,
  InvoiceItemsColumns,
  InvoiceItemsTotal
} from "./InvoiceForm.types.tsx";
import { InvoiceItemStatusReferenceCell } from "./InvoiceItemStatusReferenceCell.tsx";
import { InvoiceViewChargeToCell } from "./InvoiceViewChargeToCell.tsx";

export interface InvoiceItemListProps {
  invoiceItems: InvoiceListItem[];
  invoice: Invoice;
  accountId?: string;
}

export const InvoiceItemList: FunctionComponent<InvoiceItemListProps> =
  observer(({ invoiceItems, invoice, accountId }) => {
    const { core } = useStores();

    const showPurchaseOrder = invoiceItems.some(ii => ii.purchaseOrderNumber);
    const showComment = invoiceItems.some(ii => ii.comment);

    const getChangeLabel = (type: ChangeTypes | undefined): string => {
      switch (type) {
        case ChangeTypes.added:
          return "Added item";
        case ChangeTypes.removed:
          return "Removed item";
        case ChangeTypes.edited:
          return "Edited";
        default:
          return "";
      }
    };

    const isPaidOrPartPaidStatus: boolean =
      invoice.paymentStatus === PaymentStatuses.paid ||
      invoice.paymentStatus === PaymentStatuses.part ||
      invoice.paymentStatus === PaymentStatuses.overpaid;

    const isCancelOrAdjustStatus: boolean =
      invoice.status !== BillingStatuses.current;

    const columns: IColumn[] = [
      {
        name: InvoiceItemsColumnNames.serviceDate,
        key: InvoiceItemsColumns.serviceDate,
        minWidth: 110,
        maxWidth: 120,
        onRender: (item: InvoiceItemFormValue) => (
          <Text>
            {DateTime.fromJSDate(item.serviceDate).toDayDefaultFormat()}
          </Text>
        )
      },
      {
        name: InvoiceItemsColumnNames.state,
        key: InvoiceItemsColumns.state,
        minWidth: 100,
        maxWidth: 150,
        onRender: (item: InvoiceListItem) => (
          <InvoiceItemStatusReferenceCell
            item={item}
            accountId={accountId}
            name="statuses"
          />
        )
      },
      {
        name: InvoiceItemsColumnNames.itemNumber,
        key: InvoiceItemsColumns.itemNumber,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: InvoiceListItem) => <Text>{item.code}</Text>
      },
      {
        name: InvoiceItemsColumnNames.description,
        key: InvoiceItemsColumns.description,
        minWidth: 150,
        maxWidth: 700,
        onRender: (item: InvoiceListItem) => {
          return (
            <Text styles={{ root: { overflow: "hidden" } }}>
              {item.name || item.description}
            </Text>
          );
        }
      },
      {
        name: InvoiceItemsColumnNames.lineItemComment,
        key: InvoiceItemsColumns.lineItemComment,
        iconName: "Comment",
        isIconOnly: true,
        minWidth: 17,
        maxWidth: 17,
        styles: {
          iconClassName: { fontSize: FontSizes.size16 }
        },
        onRender: (item: InvoiceListItem) => (
          <CommentCell comment={item.comment} />
        )
      },
      {
        name: InvoiceItemsColumnNames.chargedTo,
        key: InvoiceItemsColumns.chargedTo,
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: InvoiceListItem) => (
          <InvoiceViewChargeToCell invoice={invoice} item={item} />
        )
      },
      {
        name: InvoiceItemsColumnNames.purchaseOrderNumber,
        key: InvoiceItemsColumns.purchaseOrderNumber,
        minWidth: 120,
        maxWidth: 400,
        onRender: (item: InvoiceListItem) => (
          <Text styles={{ root: { overflow: "hidden" } }}>
            {item.purchaseOrderNumber}
          </Text>
        )
      },
      {
        name: InvoiceItemsColumnNames.quantity,
        key: InvoiceItemsColumns.quantity,
        minWidth: 120,
        maxWidth: 350,
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        }),
        onRender: (item: InvoiceListItem) => (
          <Text block styles={{ root: { textAlign: "right" } }}>
            {item.quantity
              ? convertQuantity(Number(item.quantity), item.feeType!)
              : ""}
          </Text>
        )
      },
      {
        name: InvoiceItemsColumnNames.fee,
        key: InvoiceItemsColumns.fee,
        minWidth: 135,
        maxWidth: 170,
        onRender: (item: InvoiceListItem) => (
          <Text block styles={{ root: { textAlign: "right" } }}>
            {item.total
              ? currencyFormat(Number(item.total), { currency: "" })
              : ""}
          </Text>
        ),
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        })
      },
      {
        name: InvoiceItemsColumnNames.gst,
        key: InvoiceItemsColumns.gst,
        minWidth: 60,
        maxWidth: 60,
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "center"
          }
        }),
        onRender: (item: InvoiceListItem) =>
          Number(item.gst) > 0 && (
            <Stack
              horizontal
              horizontalAlign="center"
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <FontIcon
                iconName="Completed"
                styles={{
                  root: {
                    fontSize: FontSizes.large
                  }
                }}
              />
            </Stack>
          )
      },
      {
        name: InvoiceItemsColumnNames.owing,
        key: InvoiceItemsColumns.owing,
        minWidth: 140,
        maxWidth: 140,
        onRender: (item: InvoiceListItem) => (
          <Text block styles={{ root: { textAlign: "right" } }}>
            {(Number(item.total) - Number(item.allocatedAmount)).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2
              }
            )}
          </Text>
        ),
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        })
      },
      {
        name: InvoiceItemsColumnNames.changeType,
        key: InvoiceItemsColumns.changeType,
        minWidth: 100,
        maxWidth: 150,
        onRender: (item: InvoiceListItem) => (
          <ChangeType
            type={item.changeType}
            label={getChangeLabel(item.changeType)}
          />
        )
      }
    ].filter(col => {
      if (col.key === InvoiceItemsColumns.purchaseOrderNumber) {
        return showPurchaseOrder;
      }
      if (col.key === InvoiceItemsColumns.lineItemComment) {
        return showComment;
      }
      if (
        col.key === InvoiceItemsColumns.chargedTo &&
        (!core.isNZTenant || !invoice.claimId)
      ) {
        return false;
      }

      // hide gst column if no items with gst at all
      if (
        col.key === InvoiceItemsColumns.gst &&
        invoiceItems.every(i => Number(i.gst) === 0)
      )
        return false;

      // hide owing is canceled or adjusted status
      if (col.key === InvoiceItemsColumns.owing && isCancelOrAdjustStatus)
        return false;

      return !(
        col.key === InvoiceItemsColumns.changeType &&
        invoice.status !== BillingStatuses.adjusted
      );
    });

    const totalFields: TotalField[] = [
      {
        key: InvoiceTotals.totalIncGst,
        title: InvoiceItemsTotal.totalIncGst,
        dataAttr: "billing-totals-total-inc-gst"
      },
      {
        key: InvoiceTotals.owing,
        title: InvoiceItemsTotal.owing,
        showState: true,
        dataAttr: "billing-totals-owing"
      }
    ];

    const getStartIndex: number = !isPaidOrPartPaidStatus ? 5 : 7;

    const footerOptions: FooterOptions = {
      startIndex: getStartIndex,
      fields: totalFields
        .filter(f => !(isCancelOrAdjustStatus && f.key === InvoiceTotals.owing))
        .map(i => ({ ...i, dataAttr: i.dataAttr }))
    };

    const headerOptions: HeaderOptions = {
      startIndex: getStartIndex,
      fields: totalFields
        .filter(f => f.key === InvoiceTotals.totalIncGst)
        .map(i => ({ ...i, dataAttr: i.dataAttr }))
    };

    return (
      <InvoiceItemListBase
        name={invoiceFormNameOf("invoiceItems")}
        invoice={invoice}
        items={invoiceItems}
        columns={columns}
        options={{
          footer: footerOptions,
          header: headerOptions
        }}
      />
    );
  });
