import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useRef
} from "react";

import { useStores } from "@stores/hooks/useStores.ts";

import { TemplateFormHelper } from "./TemplateFormHelper.ts";

const TemplateFormContext = createContext<TemplateFormHelper>(
  {} as TemplateFormHelper
);

export const useTemplateFormContext = (): TemplateFormHelper => {
  return useContext<TemplateFormHelper>(TemplateFormContext);
};

export const TemplateFormContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const root = useStores();

  const helper = useRef(new TemplateFormHelper(root));
  return (
    <TemplateFormContext.Provider value={helper.current}>
      {children}
    </TemplateFormContext.Provider>
  );
};
