import { observer } from "mobx-react-lite";
import { PropsWithChildren, useCallback, useMemo } from "react";

import { mergeStyles, Tile, useTheme } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { Calendar } from "../../calendar/Calendar.tsx";
import { STEP } from "../../context/BookingCalendarScreenModel.ts";
import { CalendarEventView } from "../../types/CalendarEventView.types.ts";
import { BookingCalendarEvent } from "../booking-calendar-event/BookingCalendarEvent.tsx";
import { BigCalendarEvent } from "../booking-calendar-event/BookingCalendarEvent.types.ts";
import { EditPracticeDayWorkingHoursDialog } from "../edit-practice-day-working-hours-dialog/EditPracticeDayWorkingHoursDialog.tsx";
import { getAppointmentEventColors } from "../utils.tsx";
import { getCalendarStyles } from "./BookingCalendar.styles.ts";
import { BookingCalendarCell } from "./BookingCalendarCell.tsx";
import { BookingCalendarNoProviders } from "./BookingCalendarNoProviders.tsx";
import { BookingCalendarResourceHeader } from "./BookingCalendarResourceHeader.tsx";
import { BookingCalendarWorkWeekView } from "./BookingCalendarWorkWeekView.tsx";
import {
  endAccessor,
  getTimeslots,
  isEventFiltered,
  resourceAccessor,
  resourceIdAccessor,
  resourceTitleAccessor,
  startAccessor
} from "./utils.ts";

export const BookingCalendar = observer(() => {
  const theme = useTheme();
  const helper = useBookingCalendarScreenContext();

  const { userExperience } = useStores();
  const { apptTypeBaseInterval } =
    userExperience.orgUnitSettingForLocation?.appointmentSetting || {};

  const step = parseInt(apptTypeBaseInterval || STEP);

  const {
    calendarView,
    today,
    appointmentsFilter,
    draggableAccessor,
    isCalendarReadOnly,
    resizableAccessor,
    events,
    differentLocationTimeslots,
    onSelectSlot,
    moveEvent,
    resizeEvent,
    bookableUsers,
    resourceIds,
    getIsOrphaned,
    getLocationColourByOrgUnitId
  } = helper;

  const resources = bookableUsers.filter(x => resourceIds.includes(x.id));

  const formats = {
    timeGutterFormat: TIME_FORMATS.DEFAULT_TIME_FORMAT
  };

  const timeSlotWrapper = useCallback(
    (props: PropsWithChildren<{ value: Date; resource?: string }>) => {
      // return default calendar slot in a safe it is event (object) or no resource (null)
      if (props.resource || props.resource === null) {
        // Here we convert the date in props.value to a number of milliseconds.  This is to ensure the cell component doesn't rerender if
        //  this function got called with a different instance of the same date.  Usually any date conversion should be done after converting
        //  to a DateTime first, however this function can run a LOT and creating a DateTime every time has a noticeable impact on the
        //  calendar.  However the milliseconds will be wrong since it will be in the user's system timezone rather than the practice
        //  timezone.  So don't use this millisecond value for anything else!
        return (
          <BookingCalendarCell
            dateMillis={props.value.getTime()}
            resourceId={props.resource}
          />
        );
      }

      const dateTime = DateTime.fromJSDate(props.value);

      const locationTime = dateTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

      // If props.resource  is undefined -> render left-panel time slots columns items
      return <div className="rbc-time-slot">{locationTime}</div>;
    },
    []
  );

  const timeGutterHeader = useCallback(() => {
    const initialDate = helper.startDate.isBeforeToday
      ? DateTime.today()
      : helper.startDate;

    return (
      <When permission={Permission.SecurityWrite}>
        <EditPracticeDayWorkingHoursDialog initialDate={initialDate} />
      </When>
    );
  }, [helper.startDate]);

  const components = {
    event: BookingCalendarEvent,
    timeSlotWrapper,
    timeGutterHeader,
    resourceHeader: BookingCalendarResourceHeader
  };

  const className = mergeStyles("bp", calendarView);

  const eventPropGetter = useCallback(
    (event: BigCalendarEvent) => {
      const eventColours = getAppointmentEventColors(event, theme);
      const isFiltered = isEventFiltered(event.model, appointmentsFilter);
      const isOrphaned = getIsOrphaned(event);

      return {
        className: mergeStyles(
          "bp-event",
          appointmentsFilter && isFiltered ? "bp-event--filtered" : undefined
        ),
        style: {
          borderColor: eventColours.dark,
          borderLeft: isOrphaned
            ? `5px solid ${getLocationColourByOrgUnitId(event.orgUnitId)}`
            : `1px solid ${eventColours.dark}`
        }
      };
    },
    [appointmentsFilter, theme, getIsOrphaned, getLocationColourByOrgUnitId]
  );

  const views = useMemo(
    () => ({
      [CalendarEventView.Day]: true,
      [CalendarEventView.Week]: true,
      [CalendarEventView.WorkWeek]: BookingCalendarWorkWeekView
    }),
    []
  );

  // Note: every time the date value changes, the calendar position resets
  //  so it needs to be memoized
  const date = useMemo(() => helper.startDate.toJSDate(), [helper.startDate]);

  if (!resources.length) {
    return <BookingCalendarNoProviders />;
  }

  return (
    <Tile styles={{ root: { flexGrow: 1, overflow: "hidden" } }}>
      <Calendar
        date={date}
        backgroundEvents={differentLocationTimeslots}
        events={events}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        step={step}
        timeslots={getTimeslots(step)}
        eventPropGetter={eventPropGetter}
        showMultiDayTimes={true}
        resources={resources}
        components={components}
        className={className}
        styles={getCalendarStyles(appointmentsFilter)}
        resourceIdAccessor={resourceIdAccessor}
        resourceTitleAccessor={resourceTitleAccessor}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        resourceAccessor={resourceAccessor}
        draggableAccessor={draggableAccessor}
        resizableAccessor={resizableAccessor}
        onView={() => undefined}
        view={calendarView}
        onSelectSlot={onSelectSlot}
        formats={formats}
        centerOnCurrentTime={true}
        toolbar={false}
        onNavigate={() => undefined}
        today={today}
        dayLayoutAlgorithm="no-overlap"
        views={views}
        selectable={!isCalendarReadOnly}
      />
    </Tile>
  );
});
