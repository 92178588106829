export enum CommsScheduleFormSideRailLabels {
  appointmentReminder = "Appointment reminder",
  whenToNotify = "When to notify",
  typeOfAppointments = "Type of appointments",
  exclusions = "Exclusions",
  messageContent = "Message content",
  apptConfirmationReminders = "Appt. confirmation reminder"
}

export enum CommsScheduleCardIds {
  appointmentReminder = "appointmentReminder",
  whenToNotify = "whenToNotify",
  typeOfAppointments = "typeOfAppointments",
  exclusions = "exclusions",
  messageContent = "messageContent",
  apptConfirmationReminders = "apptConfirmationReminders"
}
