import { observer } from "mobx-react-lite";
import { FC } from "react";

import { FontWeights, Text } from "@bps/fluent-ui";
import {
  EventType,
  EventVerb
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";

import { RecentPatientsDetailsProps } from "./RecentPatientsList.types.ts";

export const RecentPatientsAreaCell: FC<RecentPatientsDetailsProps> = observer(
  ({ recentPatient }) => {
    let text = "",
      fontWeight = FontWeights.regular;

    switch (recentPatient.eventType) {
      case EventType.appointment:
        text = "Appointment";
        break;
      case EventType.consult:
        if (recentPatient.eventVerb === EventVerb.encounterClosed) {
          text = "Consult (finalised)";
        } else if (recentPatient.eventVerb === EventVerb.encounterStarted) {
          text = "Consult (incomplete)";
          fontWeight = FontWeights.semibold;
        } else {
          text = "Consult";
        }
        break;
      case EventType.invoice:
        text = "Invoice";
        break;
      case EventType.patient:
        text = "Patient info";
        break;
      case EventType.recordUpdate:
        text = "Record update";
        break;
    }

    return <Text styles={{ root: { fontWeight } }}>{text}</Text>;
  }
);
