import { memo, PropsWithChildren } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  Link,
  Stack,
  Tile,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { DvaDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";

interface CardWrapperProps
  extends PropsWithChildren<{
    cardId: string;
    showEditIcon?: boolean | string | DvaDto;
    title: string;
    onButtonClick?: (cardId: string) => void;
    marginBottom?: number;
    isActionIsLink?: boolean;
    actionLinkTitle?: string;
  }> {}

export const CardWrapper: React.FC<CardWrapperProps> = memo(
  ({
    cardId,
    onButtonClick,
    title,
    children,
    showEditIcon,
    marginBottom,
    isActionIsLink,
    actionLinkTitle
  }) => {
    const { core } = useStores();
    const theme = useTheme();
    const onClickOpenModal = (cardId: string) => () => {
      onButtonClick && onButtonClick(cardId);
    };

    const canOpenEdit = core.hasPermissions([
      Permission.ContactWrite,
      Permission.PatientWrite
    ]);

    const { iconButtonStyles } = getPeopleScreenStylesSet(theme);

    return (
      <div id={cardId}>
        <Stack
          horizontal
          styles={{
            root: marginBottom ? { marginBottom } : undefined
          }}
        >
          <Tile
            header={
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="baseline"
                styles={{ root: { paddingLeft: 10 } }}
              >
                <Stack>{title}</Stack>
                {canOpenEdit && showEditIcon && (
                  <Stack.Item styles={{ root: { paddingRight: 10 } }}>
                    <TooltipHost content={isActionIsLink ? "" : Labels.edit}>
                      {isActionIsLink ? (
                        <Link
                          onClick={onClickOpenModal(cardId)}
                          {...dataAttribute(
                            DataAttributes.Element,
                            "card-wrapper-open-edit"
                          )}
                        >
                          {actionLinkTitle}
                        </Link>
                      ) : (
                        <IconButton
                          {...dataAttribute(
                            DataAttributes.Element,
                            "card-wrapper-open-edit"
                          )}
                          iconProps={{ iconName: "Edit" }}
                          styles={iconButtonStyles}
                          ariaLabel="Edit"
                          onClick={onClickOpenModal(cardId)}
                        />
                      )}
                    </TooltipHost>
                  </Stack.Item>
                )}
              </Stack>
            }
            styles={{
              root: { padding: "16px 14px", width: "100%" },
              header: {
                fontSize: theme.fonts.xLarge.fontSize
              }
            }}
          >
            {children}
          </Tile>
        </Stack>
      </div>
    );
  }
);
