import { ChangeLogDto } from "@libs/api/dtos/index.ts";

export interface GroupedListItem {
  key: string;
  name: string;
  link: string;
  documentDate: string;
  changeLog: ChangeLogDto;
  documentDetailId: string;
}

export interface IDialogOptions {
  edit: boolean;
  delete: boolean;
  complete: boolean;
  add: boolean;
}

export interface UploadScreenDocData {
  id: string;
  documentDetailId: string;
  url?: string;
}

export interface InboxDocumentKey {
  inboxDocumentId: string;
  documentDetailId: string;
}

export enum InboxScreenLabels {
  Inbox = "Inbox",
  Print = "Print",
  Upload = "Upload",
  Delete = "Delete",
  Actions = "Actions",
  Details = "Details",
  MessageHeader = "Message header",
  UploadFiles = "Upload files",
  SelectReport = "Select report",
  NoReports = "No incoming reports",
  IncomingReports = "Incoming reports"
}

export const SELECT_FILE_TO_REVIEW = "Select a file to review it";
