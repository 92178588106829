import debounce from "lodash.debounce";
import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { toAttendees } from "@stores/booking/models/CalendarEvent.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { MeetingFormValues } from "../components/MeetingFormValues.ts";

export class MeetingFormHelper {
  constructor(private booking: BookingStore) {}

  private get core(): CoreStore {
    return this.booking.core;
  }

  public onDismiss = async () => {
    this.booking.ui.hideCalendarEventDialog();
    await this.booking.deleteTemporaryReservation();
  };

  @computed
  get calendarEventId() {
    return this.booking.ui.currentAppointment?.id;
  }

  @computed
  get calendarEvent() {
    return this.calendarEventId
      ? this.booking.calendarEventsMap.get(this.calendarEventId)
      : undefined;
  }

  public showCancelEvent = () => {
    this.booking.ui.setShowCancelCalendarEventDialog(
      true,
      this.calendarEventId
    );
  };

  public onSubmitted = () => this.booking.ui.hideCalendarEventDialog();

  private getMeetingDefaultValues = (
    _startDate: DateTime
  ): MeetingFormValues => {
    // hours only formatted time sting
    const startTime: string = DateTime.now()
      .startOf("hour")
      .toTimeInputFormat();

    const startDate = _startDate.startOf("day").toJSDate();

    return {
      bookedBy: this.core.userId,
      duration: "15",
      startDate,
      startTime
    };
  };

  public onSubmit = async (values: MeetingFormValues) => {
    // operationally will never happen since validation rules
    if (!values.startDate || !values.startTime) {
      throw Error("Invalid form.");
    }

    const startTime = DateTime.fromJSDateAndTime(
      values.startDate,
      values.startTime
    );

    const endTime = startTime.plus({ minutes: Number(values.duration) });

    const baseRequest = {
      startTime: startTime.toISO(),
      endTime: endTime.toISO(),
      content: values.comments,
      attendees: toAttendees({
        contactId: values.contactId,
        userId: values.providerId
      }),

      title: values.title
    };

    if (this.calendarEvent) {
      await this.booking.updateCalendarEvent({
        ...baseRequest,
        id: this.calendarEvent.id
      });
    } else {
      await this.booking.addCalendarEvent({
        ...baseRequest,
        type: CalendarEventType.Meeting,
        orgUnitId: this.core.locationId
      });
    }
  };

  public getInitialValues = (_startDate: DateTime): MeetingFormValues => {
    if (this.calendarEvent) {
      return {
        providerId: this.calendarEvent.userId,
        startDate: this.calendarEvent.startDateTime.toJSDate(),
        startTime: this.calendarEvent.startDateTime?.toTimeInputFormat(),
        comments: this.calendarEvent.content,
        contactId: this.calendarEvent.contactId,
        duration: this.calendarEvent.duration.minutes.toString()
      };
    }
    return this.getMeetingDefaultValues(_startDate);
  };

  getBaseRequest(values: MeetingFormValues) {
    if (values.startDate && values.startTime) {
      const startTime = DateTime.fromJSDateAndTime(
        values.startDate,
        values.startTime
      );

      const endTime = startTime.plus({ minutes: Number(values.duration) });

      return {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        bookedBy: values.bookedBy || this.core.userId,
        content: values.comments,
        attendees: toAttendees({
          contactId: values.contactId,
          userId: values.providerId
        }),
        title: values.title,
        orgUnitId: this.core.locationId
      };
    }
    return undefined;
  }

  temporaryReservationDebounce = debounce(
    async (formValues: MeetingFormValues) => {
      if (!formValues) return;
      if (
        formValues.providerId &&
        Number(formValues.duration) !== 0 &&
        formValues.duration &&
        !Number.isNaN(formValues.duration) &&
        formValues.startDate &&
        formValues.startTime
      ) {
        const request = this.getBaseRequest(formValues);

        if (request) {
          await this.booking.addTemporaryReservation({
            ...request,
            type: CalendarEventType.TemporaryReservation
          });
        }
      }
      return;
    },
    500
  );

  // asyncCreateTemporaryReservation returns promise when debounce action is completed
  // it help to close sidePanel after temporary reservation is created
  asyncCreateTemporaryReservation = (formValues: MeetingFormValues) => {
    return new Promise(resolve => {
      resolve(this.temporaryReservationDebounce(formValues));
    });
  };
}
