import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import { Checkbox, FontWeights, Stack, Text, useTheme } from "@bps/fluent-ui";
import { unique } from "@bps/utils";
import { MyotomeDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { SliderField } from "@ui-components/form/SliderField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import {
  DermatomesMyotomesAndReflexesFormValues,
  dermMyotomesAndReflexesNameOf,
  MyotomeFieldValues
} from "./DermatomesAndMyotomesForm.types.ts";

export const MyotomeFields: FunctionComponent = observer(() => {
  const theme = useTheme();
  const form = useForm<DermatomesMyotomesAndReflexesFormValues>();

  const getFactorText = (myotome: MyotomeDataItemDto) => {
    if (myotome.strength || myotome.strength === 0) {
      return `${myotome.strength}/5`;
    }
    return "-";
  };

  const updateMyotomes = (
    value: boolean | undefined,
    myotome: MyotomeDataItemDto
  ) => {
    if (!value) {
      const values = form.getState().values;
      const myotomesFields = values.myotomeFields;
      const myotomes = values.myotomes;

      if (!myotomesFields || !myotomes) {
        return;
      }

      const filteredOutNerveFields = myotomesFields.filter(
        x => x.nerve !== myotome.nerve
      );

      const filteredOutMyotomes = myotomes.filter(x => x !== myotome.nerve);

      if (filteredOutNerveFields && filteredOutNerveFields.length > 0) {
        form.change("myotomeFields", filteredOutNerveFields);
      } else {
        form.change("myotomeFields", []);
      }

      if (filteredOutMyotomes && filteredOutMyotomes.length > 0) {
        form.change("myotomes", filteredOutMyotomes);
      } else {
        form.change("myotomes", []);
      }
    }
  };

  const fieldName = (
    fields: MyotomeFieldValues[],
    myotome: MyotomeFieldValues
  ) => {
    const indexOf = fields.findIndex(
      (x: MyotomeFieldValues) =>
        x.nerve === myotome.nerve && x.side === myotome.side
    );

    return `myotomeFields[${indexOf}]`;
  };

  return (
    <FieldArray name={dermMyotomesAndReflexesNameOf("myotomeFields")}>
      {({ fields }) => {
        // Get a distinct list of nerves to build our fields from.
        const uniqueNerves = unique(fields.value.map(x => x.nerve));

        return (
          <Stack tokens={{ childrenGap: 8 }}>
            {uniqueNerves.map((nerve, index) => {
              const myotomes = fields.value.filter(x => x.nerve === nerve);
              const firstElement = index === 0;
              return (
                <Stack
                  key={nerve}
                  tokens={{ childrenGap: 8 }}
                  wrap
                  styles={{
                    root: {
                      padding: 8,
                      paddingTop: firstElement ? 0 : 8,
                      paddingBottom: 0,
                      borderTop: firstElement ? "none" : "1px solid",
                      borderTopColor: theme.palette.neutralLight
                    }
                  }}
                >
                  <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: 8 }}
                    styles={{ root: { minWidth: 112, maxWidth: 168 } }}
                  >
                    <Checkbox
                      automationAttribute="nerve-selected-checkbox"
                      onChange={(ev, checked) =>
                        updateMyotomes(checked, myotomes[0])
                      }
                      checked={myotomes[0].checked}
                    />
                    <Text styles={{ root: { fontWeight: 600 } }}>
                      {myotomes[0].nerve}
                    </Text>
                  </Stack>

                  {myotomes.map(myotome => {
                    // Get the name of the field.
                    const name = fieldName(fields.value, myotome);

                    return (
                      <Stack
                        key={myotome.nerve + myotome.side}
                        horizontal
                        verticalAlign="center"
                        tokens={{ childrenGap: 8 }}
                      >
                        <Stack
                          verticalAlign="center"
                          styles={{
                            root: { minWidth: 112, maxWidth: 168 }
                          }}
                        >
                          <SpinNumberInputField
                            prefix={myotome.side}
                            parse={value => Number(value)}
                            max={5}
                            min={0}
                            maxDigitsNumber={1}
                            name={`${name}.strength`}
                            fieldItemStyles={{
                              root: { maxWidth: 116 }
                            }}
                            styles={{ input: { width: 0 } }}
                          />
                        </Stack>

                        <Stack horizontal verticalAlign="center" grow>
                          <SliderField
                            name={`${name}.strength`}
                            min={0}
                            max={5}
                            snapToStep
                            styles={{ wrapper: { flexGrow: 1 } }}
                          />
                          <Text
                            styles={{
                              root: {
                                fontWeight: FontWeights.semibold,
                                fontSize: 14,
                                width: 30
                              }
                            }}
                          >
                            {getFactorText(myotome)}
                          </Text>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        );
      }}
    </FieldArray>
  );
});
