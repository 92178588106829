import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  Heading,
  mergeStyles,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { responseStatusesArray } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ErrorMessageBar } from "@modules/acc/screens/shared-components/ErrorMessageBar.tsx";
import {
  formatDiagnosis,
  getDiagnosisChangesOverviewText,
  getSideChangeOverviewText,
  isDiagnosisChangeFull,
  isSideChangeFull
} from "@modules/acc/screens/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";

import {
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "../ClaimAdjustmentEnums.ts";
import { ClaimAdjustmentLodgeError } from "../ClaimAdjustmentLodgeError.tsx";
import { OverviewButton } from "../OverviewButton.tsx";
import { ClaimAdjustmentOverviewBadge } from "./ClaimAdjustmentOverviewBadge.tsx";
import { HandSplinting } from "./HandSplinting.tsx";
import { InitialAssessment } from "./InitialAssessment.tsx";
import { OverviewSectionListItem } from "./OverviewSectionListItem.tsx";
import { ResponseDetails } from "./ResponseDetails.tsx";
import { Treatments } from "./Treatments.tsx";
import { getTreatmentsHeading } from "./utils.ts";
import { VerbalApprovalDetails } from "./VerbalApprovalDetails.tsx";

const customListStyle = mergeStyles({
  listStyle: "inherit",
  marginLeft: 4,
  marginTop: 0
});

export const ClaimAdjustmentOverviewSection: FC = observer(() => {
  const { acc, core } = useStores();
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const {
    showTreatmentsOnOverview,
    hasMultiProviderErrorMessage,
    claimAdjustment: {
      treatmentsRequired,
      diagnosisAdditions,
      acc32errorMessages,
      treatmentStartDate,
      handSplintingCost,
      claimId,
      followUpTreatmentsNeeded,
      diagnosisChanges,
      sideChanges,
      responseNotes,
      initialAssessmentRequested,
      status,
      verballyApproved
    }
  } = useClaimAdjustmentContext();

  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.overview}
      openOnRender={
        !selectedMenuItemId ||
        selectedMenuItemId === ClaimsAdjustmentCardIds.overview
      }
      heading={ClaimAdjustmentSectionHeading.overview}
      iconName="DocumentSet"
      button={
        <>
          <ClaimAdjustmentOverviewBadge />
          {core.hasPermissions(Permission.ClaimWrite) &&
            status?.code &&
            !responseStatusesArray.includes(status?.code) && <OverviewButton />}
        </>
      }
    >
      <Stack tokens={{ childrenGap: 16 }}>
        <ClaimAdjustmentLodgeError claimId={claimId} />
        {hasMultiProviderErrorMessage && (
          <MessageBar messageBarType={MessageBarType.error}>
            In order to record a response you need to create an appointment with
            the provider that created this ACC32.
          </MessageBar>
        )}

        {acc32errorMessages.length > 0 && (
          <ErrorMessageBar errorMessageList={acc32errorMessages} />
        )}

        {verballyApproved ? <VerbalApprovalDetails /> : <ResponseDetails />}

        {responseNotes && (
          <Stack tokens={{ childrenGap: 8 }}>
            <Heading>Notes</Heading>
            <Text>{responseNotes}</Text>
          </Stack>
        )}
        {!!diagnosisAdditions.length && (
          <Stack tokens={{ childrenGap: 8 }}>
            <Heading>Add diagnosis</Heading>
            <Stack>
              <Stack as="ul" className={customListStyle}>
                {diagnosisAdditions.map(diagnosis => (
                  <OverviewSectionListItem
                    key={`${diagnosis.terminology?.code}${diagnosis.diagnosisSide}`}
                    text={formatDiagnosis(
                      diagnosis,
                      acc.ref.diagnosisSides.keyTextValues
                    )}
                    requestApproved={!!diagnosis.requestApproved}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
        {!!sideChanges?.length && (
          <Stack tokens={{ childrenGap: 8 }}>
            <Heading variant="section-sub-heading">Side change</Heading>
            <Stack>
              <Stack as="ul" className={customListStyle}>
                {sideChanges.filter(isSideChangeFull).map(sideChange => {
                  const text = getSideChangeOverviewText(
                    sideChange,
                    acc.ref.diagnosisSides.keyTextValues
                  );
                  return (
                    <OverviewSectionListItem
                      key={text}
                      text={text}
                      requestApproved={!!sideChange.requestApproved}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        )}
        {!!diagnosisChanges?.length && (
          <Stack tokens={{ childrenGap: 8 }}>
            <Heading variant="section-sub-heading">Diagnosis changes</Heading>
            <Stack as="ul" className={customListStyle}>
              {diagnosisChanges
                .filter(isDiagnosisChangeFull)
                .map(diagnosisChange => {
                  const text = getDiagnosisChangesOverviewText(
                    diagnosisChange,
                    acc.ref.diagnosisSides.keyTextValues
                  );
                  return (
                    <OverviewSectionListItem
                      key={text}
                      text={text}
                      requestApproved={!!diagnosisChange.requestApproved}
                    />
                  );
                })}
            </Stack>
          </Stack>
        )}
        {showTreatmentsOnOverview && (
          <Stack tokens={{ childrenGap: 8 }}>
            <Heading variant="section-sub-heading">
              {getTreatmentsHeading(treatmentStartDate)}
            </Heading>
            <Stack as="ul" className={customListStyle}>
              {initialAssessmentRequested ? <InitialAssessment /> : null}
              {(treatmentsRequired || followUpTreatmentsNeeded) && (
                <Treatments />
              )}
              {!!handSplintingCost && <HandSplinting />}
            </Stack>
          </Stack>
        )}
      </Stack>
    </ClaimCard>
  );
});
