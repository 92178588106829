import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import {
  MedicationClinicalDataItemDto,
  PrescriptionClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { getDoseFullInstructions } from "./utils.ts";

interface PrescriptionsListCellProps {
  prescription: PrescriptionClinicalDataItemDto;
}

export const PrescriptionListCell: FunctionComponent<PrescriptionsListCellProps> =
  observer(({ prescription }) => {
    const { clinical } = useStores();

    const theme = useTheme();

    const getDoseDetails = (
      medication: MedicationClinicalDataItemDto
    ): string => {
      const {
        prn,
        complexInstructions,
        food,
        dose,
        doseUnit,
        frequency,
        route,
        duration,
        durationUnit,
        otherInstructions
      } = medication;

      const complexInstructionsText = complexInstructions
        ? complexInstructions
        : "";

      const foodText = food ? clinical.ref.dosingFood.get(food)?.text : "";

      const frequencyText = frequency
        ? clinical.ref.dosingFrequencies.get(frequency)?.text
        : "";

      const routeText = route ? clinical.ref.dosingRoutes.get(route)?.text : "";

      const durationUnitText = durationUnit
        ? clinical.ref.dosingDurationPeriods.get(durationUnit)?.text
        : "";

      const otherInstructionsText = otherInstructions
        ? clinical.ref.dosingOtherInstructions.get(otherInstructions)?.text
        : "";

      const doseFullInstructions = getDoseFullInstructions({
        dose,
        doseUnit,
        frequencyText,
        prn,
        routeText,
        complexInstructionsText,
        foodText,
        otherInstructionsText,
        duration,
        durationUnitText
      });

      return doseFullInstructions;
    };

    return (
      <>
        {prescription.medications.map(x => {
          return (
            <Stack
              key={x.productName}
              tokens={{ childrenGap: 4 }}
              styles={{
                root: { paddingBottom: "4px" }
              }}
            >
              <Text bold>{x.productName}</Text>
              <Stack
                tokens={{ childrenGap: 4 }}
                styles={{
                  root: { marginLeft: 16 }
                }}
              >
                <Text>
                  <Text
                    variant="small"
                    styles={{
                      root: {
                        color: theme.palette.neutralSecondary,
                        paddingRight: 4
                      }
                    }}
                  >
                    Dose details
                  </Text>
                  {getDoseDetails(x)}
                </Text>

                <Stack horizontal>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 4 }}
                    verticalAlign="center"
                    styles={{ root: { width: "40%" } }}
                  >
                    <Text
                      variant="small"
                      styles={{
                        root: { color: theme.palette.neutralSecondary }
                      }}
                    >
                      QTY
                    </Text>
                    <Text variant="medium">{x?.quantity}</Text>
                  </Stack>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 4 }}
                    verticalAlign="center"
                    styles={{ root: { width: "60%" } }}
                  >
                    <Text
                      styles={{
                        root: { color: theme.palette.neutralSecondary }
                      }}
                      variant="small"
                    >
                      Repeats
                    </Text>
                    <Text variant="medium">{x?.repeats}</Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </>
    );
  });
