import { InvoiceEmailDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  OrganisationRoleDto,
  OrganisationRoleType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";

import { AccreditedBillingEmailDialogValues } from "./AccreditedBillingEmailDialog.types.ts";

interface onSubmitOptions {
  root: IRootStore;
  values: AccreditedBillingEmailDialogValues;
  invoice: Invoice;
  invoiceEmailQueue: Invoice[];
}

export const onSubmitAccreditedBillingEmailDialog = async (
  options: onSubmitOptions
) => {
  const { root, values, invoice, invoiceEmailQueue } = options;

  const { practice, billing } = root;

  // Organisation should be related to ACC as a private insure or employers
  const contact = await practice.getContact(invoice.accountId);
  if (values.addAsInvoicingEmail) {
    const organisationRoles: OrganisationRoleDto[] =
      contact.organisationRoles.map(orgRole => {
        if (
          (contact.privateInsurer?.accPrivateInsurer &&
            orgRole.organisationRoleTypeCode ===
              OrganisationRoleType.NzPrivateInsurer) ||
          (contact.employer?.accreditedEmployer &&
            orgRole.organisationRoleTypeCode ===
              OrganisationRoleType.NzEmployer)
        ) {
          return {
            ...orgRole,
            differentInvoicingEmail: true,
            invoicingEmail: values.email
          };
        }
        return orgRole;
      });

    await practice.updateOrganisation({
      id: contact.id,
      organisationRoles
    });

    // There is no need to continue prompting for an email for invoices being billed to the same contact
    // because it is now saved as their invoicing email
    const invoiceEmails: InvoiceEmailDto[] = invoiceEmailQueue
      .filter(i => i.accountId === invoice.accountId)
      .map(i => ({ invoiceId: i.id, email: values.email }));

    await billing.sendInvoicePdf(invoiceEmails);
  } else {
    await billing.sendInvoicePdf([
      { invoiceId: invoice.id, email: values.email }
    ]);
  }
};
