import React, { FC } from "react";

import { DateTime } from "@bps/utils";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DocumentViewerDialog } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

interface FormResponseDocumentProps {
  formDocumentId: string;
  formTemplateName: string;
  patientName: string;
  patientId: string;
  responseDate?: string;
  closeDocumentViewer: () => void;
}

export const FormResponseDocument: FC<FormResponseDocumentProps> = ({
  formDocumentId,
  formTemplateName,
  patientId,
  responseDate,
  closeDocumentViewer
}) => {
  const { correspondence } = useStores();

  const formatDate = (date?: string) => {
    return DateTime.fromISO(date)?.toDayDefaultFormat();
  };

  return (
    <DocumentViewerDialog
      closeDocumentViewer={closeDocumentViewer}
      getDocument={async () => {
        const response = await correspondence.getDocumentUrl({
          patientId,
          documentId: formDocumentId
        });
        return {
          entityId: patientId,
          documentId: formDocumentId,
          entityType: DocumentEntityType.Patient,
          name: `${formTemplateName} ${formatDate(responseDate)}`,
          extension: "pdf",
          previewUri: response.url
        };
      }}
      getDownloadUri={async () => {
        const documentUrlResponse = await correspondence.getDocumentUrl({
          patientId,
          documentId: formDocumentId
        });
        return documentUrlResponse.url;
      }}
    />
  );
};
