import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { PrintContext } from "@ui-components/printing/Print.tsx";

import { TasksScreenLabels } from "./Tasks.types.ts";

export const TaskInboxButtons = observer(() => {
  const print = useContext(PrintContext);

  return (
    <DefaultButton
      iconProps={{
        iconName: "Print"
      }}
      onClick={print.print}
    >
      {TasksScreenLabels.Print}
    </DefaultButton>
  );
});
