import { DetailsList, NoDataTile, Stack, Text } from "@bps/fluent-ui";
import { SideOfBody } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { DiagnosisSideData } from "@shared-types/clinical/diagnosis-side-data.interface.ts";
import { SideOfBodyText } from "@ui-components/RefText.tsx";

export interface IDiagnosisListProps {
  primaryDiagnoses: DiagnosisSideData[];
  additionalDiagnoses: DiagnosisSideData[];
}

export const DiagnosisList: React.FunctionComponent<IDiagnosisListProps> = ({
  primaryDiagnoses,
  additionalDiagnoses
}) => {
  const getDiagnosisColumns = (descriptionHeading: string) => {
    return [
      {
        key: "Description",
        name: descriptionHeading,
        minWidth: 150,
        onRender: (item: DiagnosisSideData) => item.originalText
      },
      {
        key: "Side",
        name: "Side",
        minWidth: 150,
        maxWidth: 150,
        onRender: (item: DiagnosisSideData) =>
          item.side === SideOfBody.Neither ? (
            <Text>{item.side}</Text>
          ) : (
            <SideOfBodyText code={item.side} />
          )
      }
    ];
  };

  return (
    <Stack
      tokens={{
        childrenGap: 8
      }}
    >
      {primaryDiagnoses.length === 0 && additionalDiagnoses.length === 0 ? (
        <NoDataTile
          textProps={{
            text: "No diagnosis to display"
          }}
          showBoxShadow={false}
          linkProps={{ hidden: true }}
        />
      ) : (
        <>
          <DetailsList
            compact={true}
            columns={getDiagnosisColumns("Primary diagnoses")}
            items={primaryDiagnoses}
            bordersVariant="full"
          />
          {additionalDiagnoses && additionalDiagnoses.length > 0 && (
            <DetailsList
              compact={true}
              columns={getDiagnosisColumns("Additional diagnoses")}
              items={additionalDiagnoses}
              bordersVariant="full"
            />
          )}
        </>
      )}
    </Stack>
  );
};
