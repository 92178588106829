import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

import { IBreadcrumbItem, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import { UserPageHeadings, UserScreenBreadcrumbKeys } from "./labels.ts";

const possibleUserRoutes = [
  routes.settings.users.reserves,
  routes.settings.users.userClinicalView,
  routes.settings.users.userWorkingHours,
  routes.settings.users.userWorkingHoursOverrides,
  routes.settings.users.user
];
interface UserBreadcrumbsBaseProps {
  user: User | undefined;
}

export enum UserProfileSection {
  OnlineBookingProfile = "online-booking-profile"
}

const UserBreadcrumbsBase: React.FC<UserBreadcrumbsBaseProps> = ({ user }) => {
  const { routing, core } = useStores();
  const breadcrumbItems: IBreadcrumbItem[] = [];

  const currentPath = possibleUserRoutes.find(element =>
    routing.match(element)
  );

  const hasUsersScreenPermission =
    !routes.settings.users.basePath.permissions ||
    core.hasPermissions(
      routes.settings.users.basePath.permissions,
      routes.settings.users.basePath.permissionsOperator
    );

  if (user) {
    breadcrumbItems.push({
      onClick: () =>
        routing.push({
          pathname: routes.settings.users.basePath.pattern
        }),
      text: "Users",
      key: UserScreenBreadcrumbKeys.users.toString(),
      hidden: !hasUsersScreenPermission
    });

    if (
      routing.match(routes.settings.users.userClinicalView) ||
      routing.match(routes.settings.users.userWorkingHours) ||
      routing.match(routes.settings.users.userWorkingHoursOverrides) ||
      routing.match(routes.settings.users.reserves)
    ) {
      breadcrumbItems.push({
        onClick: () =>
          routing.push(
            routes.settings.users.user.path({
              id: user.id
            })
          ),
        text: user.fullName,
        key: UserScreenBreadcrumbKeys.user.toString(),
        hidden: false
      });
    } else {
      breadcrumbItems.push({
        text: user.fullName,
        key: UserScreenBreadcrumbKeys.user.toString(),
        isCurrentItem: true
      });
    }

    if (routing.match(routes.settings.users.userClinicalView)) {
      breadcrumbItems.push({
        text: UserPageHeadings.clinical,
        key: UserScreenBreadcrumbKeys.clinical.toString(),
        isCurrentItem: true
      });
    }
    if (routing.match(routes.settings.users.userWorkingHours)) {
      breadcrumbItems.push({
        text: UserPageHeadings.workingHours,
        key: UserScreenBreadcrumbKeys.workingHours.toString(),
        isCurrentItem: true
      });
    }
    if (routing.match(routes.settings.users.userWorkingHoursOverrides)) {
      breadcrumbItems.push({
        text: UserPageHeadings.exceptions,
        key: UserScreenBreadcrumbKeys.exceptions.toString(),
        isCurrentItem: true
      });
    }
    if (routing.match(routes.settings.users.reserves)) {
      breadcrumbItems.push({
        text: UserPageHeadings.reserves,
        key: UserScreenBreadcrumbKeys.reserves.toString(),
        isCurrentItem: true
      });
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Stack
        tokens={{ childrenGap: 26 }}
        styles={{ root: { marginBottom: 16 } }}
      >
        <BreadCrumb
          styles={{ root: { margin: 0 } }}
          routes={breadcrumbItems.filter(item => !item.hidden)}
        />
      </Stack>
      {!hasUsersScreenPermission &&
        currentPath &&
        routing.match(currentPath)?.params.id !== core.userId && (
          <Navigate to={currentPath.path({ id: core.userId })} replace />
        )}
      <Outlet />
    </div>
  );
};

const UserBreadcrumbs: React.FC = props => {
  const { routing, core } = useStores();

  const { id } = useParams();
  const currentPath = possibleUserRoutes.find(element =>
    routing.match(element)
  );

  const userId: string | undefined =
    core.hasPermissions(Permission.SecurityWrite) && currentPath
      ? id
      : core.userId;

  async function getUser({ core }: RootStore): Promise<User | undefined> {
    if (userId) {
      return await core.getUser(userId);
    }

    return undefined;
  }
  return (
    <DataFetcher<User | undefined> fetch={getUser} key={userId}>
      {user => <UserBreadcrumbsBase user={user} {...props} />}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default UserBreadcrumbs;
