import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BillingScreenWrapper } from "@modules/billing/screens/shared-components/BillingScreenWrapper.tsx";
import { StatementGenerateModal } from "@modules/billing/screens/statement/components/StatementGenerateModal.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { StatementItemsContext } from "./context/StatementItemsContext.ts";
import { StatementItemsHelper } from "./context/StatementItemsHelper.ts";
import { StatementsList } from "./StatementItemList.tsx";

const StatementItemsScreen = () => {
  const stores = useStores();
  const helper = new StatementItemsHelper(stores);
  return (
    <StatementItemsContext.Provider value={helper}>
      <StatementItemsScreenBase />
    </StatementItemsContext.Provider>
  );
};

const StatementItemsScreenBase = observer(() => {
  const { setIsStatementNewModalVisible, isStatementNewModalVisible } =
    useContext(StatementItemsContext);

  return (
    <BillingScreenWrapper
      headerButtons={
        <When permission={Permission.StatementWrite}>
          <DefaultButton onClick={() => setIsStatementNewModalVisible(true)}>
            New statement run
          </DefaultButton>
        </When>
      }
    >
      {isStatementNewModalVisible && <StatementGenerateModal />}
      <StatementsList />
    </BillingScreenWrapper>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default StatementItemsScreen;
