import {
  maxLength,
  required,
  todayOrLater
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { UserInboxActionFormValues } from "./UserInboxActionForm.types.ts";

export class UserInboxActionFormValidator extends Validator<UserInboxActionFormValues> {
  constructor() {
    super();

    this.forField("instructionCode", required());
    this.forField("extraInfo", maxLength(200));
    this.forField("dueDate", todayOrLater);
    this.forField("storeIn", required());
  }
}
