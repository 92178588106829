import { observer } from "mobx-react-lite";

import { Heading, mergeStyleSets, Stack, useTheme } from "@bps/fluent-ui";
import { Contact } from "@stores/practice/models/Contact.ts";
import { LanguageText } from "@ui-components/RefText.tsx";

import { patientWarningDotStyles } from "./patient-warnings/PatientWarnings.styles.ts";

interface InterpreterFormProps {
  patient: Contact;
}

export const PatientNeedInterpreter: React.FC<InterpreterFormProps> = observer(
  ({ patient }) => {
    const theme = useTheme();

    return (
      <Stack verticalFill styles={{ root: { padding: "0 10px" } }}>
        <Heading
          variant="section-sub-heading"
          styles={mergeStyleSets(
            { root: { marginBottom: 8 } },
            patient.interpreterLanguage
              ? patientWarningDotStyles(theme)
              : undefined
          )}
        >
          Interpreter needed
        </Heading>
        {patient.interpreterLanguage ? (
          <LanguageText code={patient.interpreterLanguage} />
        ) : (
          "No"
        )}
      </Stack>
    );
  }
);
