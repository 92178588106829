import { FunctionComponent } from "react";

import {
  ITag,
  mergeStyleSets,
  MultiTagPickerItem,
  Text,
  TextBadge,
  TextBadgeSize
} from "@bps/fluent-ui";

interface AppointmentTypeTagItemProps {
  item: ITag;
  index: number;
  badgeColor?: string;
  badgeText?: string;
  isSuggestionText?: boolean;
  onRemoveAppointmentType?: () => void;
}

export const AppointmentTypeTagItem: FunctionComponent<
  AppointmentTypeTagItemProps
> = ({
  item,
  index,
  badgeColor,
  badgeText,
  isSuggestionText,
  onRemoveAppointmentType
}) => {
  const getPickerStyles = () => {
    const baseStyle = {
      text: {
        display: "flex",
        padding: 0
      },
      root: {
        padding: 0,
        background: "transparent",
        "&:hover": { background: "transparent" }
      }
    };

    mergeStyleSets(baseStyle);
    const pickerStyles = isSuggestionText
      ? mergeStyleSets(baseStyle, {
          close: { visibility: "hidden" }
        })
      : mergeStyleSets(baseStyle, {
          text: {
            justifyContent: "space-between",
            width: "100%"
          },
          root: {
            width: "100%",
            textAlign: "left"
          }
        });

    return pickerStyles;
  };

  return (
    <MultiTagPickerItem
      styles={getPickerStyles()}
      item={item}
      index={index}
      disableFocus
      onRemoveItem={() => {
        onRemoveAppointmentType && onRemoveAppointmentType();
      }}
    >
      <Text nowrap>{item.name}</Text>
      {badgeText && (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          customColors={{
            backgroundColor: badgeColor
          }}
          styles={{ root: { margin: "4px" } }}
        >
          {badgeText}
        </TextBadge>
      )}
    </MultiTagPickerItem>
  );
};
