import { TemplatesList } from "@modules/settings/screens/comms-templates/components/TemplatesList.tsx";
import { TemplateScreenContextProvider } from "@modules/settings/screens/comms-templates/context/TemplateScreenContext.tsx";

export const CommsTemplatesScreen: React.FC = () => {
  return (
    <TemplateScreenContextProvider>
      <TemplatesList />
    </TemplateScreenContextProvider>
  );
};
// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default CommsTemplatesScreen;
