import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useForm } from "react-final-form";

import { Link, NoDataTile, Stack } from "@bps/fluent-ui";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { ClaimFormLabels } from "../ClaimFormEnums.ts";

export const NoEmployerTile = observer(() => {
  const { practice } = useStores();
  const {
    ui: { showEditContact }
  } = practice;

  const form = useForm<ClaimFormValues>();

  const { claim, onQuickAddSelected } = useContext(ClaimContext);

  const patientLabel = usePatientLabel();

  const onAddEmployerSelected = () => {
    showEditContact(PatientCardIds.employers, claim.patient?.id || "");
  };

  return (
    <NoDataTile
      textProps={{
        text: `${ClaimFormLabels.NoEmployersLinked} ${patientLabel}`
      }}
      linkProps={{
        onRender: () => (
          <Stack horizontal>
            <Link onClick={() => onQuickAddSelected(form)}>
              Add a quick employer
            </Link>
            &nbsp;or&nbsp;
            <Link onClick={onAddEmployerSelected}>add a saved employer</Link>
          </Stack>
        )
      }}
    />
  );
});
