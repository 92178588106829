import * as React from "react";
import {
  Calendar as BigCalendar,
  CalendarProps as BigCalendarProps,
  Components,
  Event,
  EventProps
} from "react-big-calendar";
// eslint-disable-next-line import/extensions
import withDragAndDrop, * as dragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import { IStyle, IStyleFunctionOrObject, ITheme } from "@bps/fluent-ui";

// BigCalendar is defined as a generic class. The two lines below
// are a way to apply the withDragAndDrop HOC function to BigCalendar
export const GenericDragAndDropBigCalendar = withDragAndDrop(
  BigCalendar as React.ComponentType<BigCalendarProps>
);

export type DragAndDropInfo<TEvent extends object> = {
  event: TEvent;
  start: string | Date;
  end: string | Date;
  isAllDay: boolean;
  resourceId: string;
};

export type CalendarProps<
  TEvent extends object = Event,
  TResource extends object = {}
> = Omit<BigCalendarProps<TEvent, TResource>, "localizer" | "components"> &
  Omit<dragAndDrop.withDragAndDropProps<TEvent>, "components"> & {
    components: ComponentsWithNoTitle<TEvent, TResource>;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<CalendarStyleProps, CalendarStyles>;
    centerOnCurrentTime?: boolean;
    today: Date;
  };

export interface CalendarStyles {
  root: IStyle;
  bigCalendar: IStyle;
}

export interface CalendarStyleProps {
  theme: ITheme;
  className?: string;
}

export type ComponentsWithNoTitle<
  TEvent extends object = Event,
  TResource extends object = {}
> = Omit<Components<TEvent, TResource>, "event"> & {
  event: React.ComponentType<Omit<EventProps<TEvent>, "title">>;
};

export const MIN_SLOT_HEIGHT = 20;
