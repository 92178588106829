import { Contact } from "@stores/practice/models/Contact.ts";
import { GenderText } from "@ui-components/RefText.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";

export const ContactGenderInformation: React.FC<{
  contact: Contact;
}> = ({ contact }) => {
  if (contact.pronoun && contact.gender) {
    return (
      <>
        {contact.pronoun} (<GenderText code={contact.gender} />)
      </>
    );
  }

  if (contact.pronoun || contact.gender) {
    return contact.pronoun ? (
      <>{contact.pronoun}</>
    ) : (
      <GenderText code={contact.gender} />
    );
  }

  return <>{Labels.noGenderRelatedInformationRecorded}</>;
};
