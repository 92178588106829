import { observer } from "mobx-react-lite";

import { Separator, Stack } from "@bps/fluent-ui";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { AccidentAndEmploymentDetailsFormValues } from "@shared-types/acc/accident-and-employment-details-values.type.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AccidentAndEmploymentDetailsFormValidator } from "../../validators/AccidentAndEmploymentDetailsFormValidator.tsx";
import { ClaimsCardIds, ClaimSectionHeadings } from "../ClaimFormEnums.ts";
import { AccidentFields } from "./AccidentFields.tsx";
import { EmploymentFields } from "./EmploymentFields.tsx";

const accidentFields: (keyof AccidentAndEmploymentDetailsFormValues)[] = [
  "sport",
  "workType",
  "occupation",
  "workInjury",
  "employerId",
  "causeOfAccident",
  "sportingInjury",
  "otherEmployment",
  "involvesVehicle",
  "employmentStatus",
  "inPaidEmployment",
  "accidentSceneCode",
  "accidentLocationCode",
  "employerAddress",
  "medicalTreatmentInjury"
];

const AccidentAndEmploymentDetailsSectionBase = observer(() => {
  const { selectedMenuItemId } = useCardFormLayoutContext();

  return (
    <ClaimCard
      id={ClaimsCardIds.accidentAndEmploymentDetails}
      statusValidator={new AccidentAndEmploymentDetailsFormValidator()}
      openOnRender={
        selectedMenuItemId === ClaimsCardIds.accidentAndEmploymentDetails
      }
      heading={ClaimSectionHeadings.accidentAndEmploymentDetails}
      iconName="BpMedCare"
      errorProps={{ fields: accidentFields }}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <AccidentFields />
        <Separator />
        <EmploymentFields />
      </Stack>
    </ClaimCard>
  );
});

export const AccidentAndEmploymentDetailsSection = withFetch(
  x => [
    x.acc.ref.sports.load(),
    x.acc.ref.workTypes.load(),
    x.acc.ref.occupations.load(),
    x.acc.ref.accidentScenes.load(),
    x.acc.ref.employmentStatuses.load()
  ],
  AccidentAndEmploymentDetailsSectionBase
);
