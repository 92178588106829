import { IntakeFrequency } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  greaterThan,
  greaterThanOrSameAs,
  integer,
  isNumberSameOrAfterField,
  lessThan,
  maxLength,
  predicate,
  required,
  requiredCharactersLength
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { IntakeFormItem, IntakeFormValues } from "./IntakeForm.types.ts";
import { isAfterDob, isBeforeOrSameAsCurrentYear } from "./utils.ts";

interface IntakeFormValidatorProps {
  dateOfBirth: Date | undefined;
}

export class IntakeFormValidator extends Validator<IntakeFormValues> {
  constructor(options: IntakeFormValidatorProps, core: CoreStore) {
    super();
    this.forField("status", required());
    this.forField("comment", [maxLength(250)]);
    if (core.hasPermissions(Permission.CommonIntakeDetailAllowed)) {
      const intakeItemValidator = new IntakeItemValidator(options);
      this.forArrayField("historicalIntakes", intakeItemValidator.validate);
      this.forArrayField("currentIntakes", intakeItemValidator.validate);
    }
  }
}

class IntakeItemValidator extends Validator<IntakeFormItem> {
  constructor(options: IntakeFormValidatorProps) {
    super();
    this.forField("type", required());
    this.forField("amount", [
      predicate(x => x !== 0, required()), // treat zero as value entered
      integer(),
      greaterThan(0),
      lessThan(1000)
    ]);

    this.forField("occurrence", [
      integer(),
      greaterThan(0),
      (val, values) => {
        // don't validate undefined data that isn't actively being edited
        // work around for old data
        if (
          !val &&
          values.isEdit &&
          values.frequency !== IntakeFrequency.Daily
        ) {
          return ValidationMessages.required;
        } else {
          return false;
        }
      },
      lessThan(1000)
    ]);

    this.forField("frequency", required());

    this.forField("yearStarted", [
      required(),
      integer(),
      requiredCharactersLength(4),
      greaterThanOrSameAs(1900),
      predicate(
        val => !!val,
        isBeforeOrSameAsCurrentYear("Year started"),
        isAfterDob(options.dateOfBirth, "Year started")
      )
    ]);
    this.forField("yearStopped", [
      integer(),
      requiredCharactersLength(4),
      greaterThanOrSameAs(1900),
      isNumberSameOrAfterField(
        "Year stopped must be after year started.",
        "yearStarted"
      ),
      predicate(
        (val, values) => !values?.isCurrent && !!val,

        predicate(
          () => !!options?.dateOfBirth,
          isBeforeOrSameAsCurrentYear("Year stopped"),
          isAfterDob(options.dateOfBirth, "Year stopped")
        )
      )
    ]);
  }
}

export class ExtraCurrentItemValidator extends IntakeItemValidator {
  constructor(options: IntakeFormValidatorProps) {
    super(options);
    this.forField("type", [
      (val, values, form) => {
        const sameTypes = form.currentIntakes.filter(
          (x: IntakeFormItem) => x.type === val
        );

        if (sameTypes.length > 1) {
          return ValidationMessages.onlyOneCurrentIntake;
        } else {
          return false;
        }
      }
    ]);

    this.forField("yearStarted", [
      (val, values, form) => {
        const sameTypeHistIntakes: IntakeFormItem[] =
          form.historicalIntakes.filter(
            (x: IntakeFormItem) => x.type === values.type
          );

        const isLessThanHistYearStarted = sameTypeHistIntakes.some(
          i => val && i.yearStarted && i.yearStarted > val
        );

        const isBetweenHistIntake = sameTypeHistIntakes.some(
          i =>
            val &&
            i.yearStarted &&
            i.yearStopped &&
            val >= i.yearStarted &&
            val < i.yearStopped
        );

        if (isLessThanHistYearStarted) {
          return ValidationMessages.currentEarlierThanHistorical;
        } else if (isBetweenHistIntake) {
          return ValidationMessages.historicalCannotOverlapWithCurrent;
        } else {
          return false;
        }
      }
    ]);
  }
}

export class ExtraHistoricalItemValidator extends IntakeItemValidator {
  constructor(options: IntakeFormValidatorProps) {
    super(options);
    this.forField("yearStarted", [
      required(),
      (val, values, form) => {
        const sameTypeCurrentIntakes: IntakeFormItem[] =
          form.currentIntakes.filter(
            (x: IntakeFormItem) => x.type === values.type
          );

        const isMoreThanCurrentYearStarted =
          values.yearStarted &&
          values.yearStopped &&
          sameTypeCurrentIntakes.some(
            i => i.yearStarted && i.yearStarted < values.yearStarted!
          );

        return isMoreThanCurrentYearStarted
          ? ValidationMessages.historicalCannotOverlapWithCurrent
          : false;
      }
    ]);
    this.forField("yearStopped", [
      required(),
      (val, values, form) => {
        const sameTypeCurrentIntakes: IntakeFormItem[] =
          form.currentIntakes.filter(
            (x: IntakeFormItem) => x.type === values.type
          );

        const isInvalid = sameTypeCurrentIntakes.some(
          i =>
            values.yearStarted &&
            i.yearStarted &&
            values.yearStarted &&
            values.yearStopped &&
            i.yearStarted >= values.yearStarted &&
            i.yearStarted < values.yearStopped
        );
        if (isInvalid) {
          return ValidationMessages.historicalCannotOverlapWithCurrent;
        } else {
          return false;
        }
      }
    ]);
  }
}
