import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet, useParams } from "react-router-dom";

import { IBreadcrumbItem, Shimmer, Stack } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import {
  SettingsLabels,
  SettingsScreenBreadcrumbKeys
} from "../../shared-components/SettingsLabels.ts";

type PracticeBreadcrumbsProps = {
  locationName?: string;
};

const PracticeBreadcrumbsBase: React.FC<PracticeBreadcrumbsProps> = observer(
  ({ locationName }) => {
    const { routing } = useStores();

    const breadcrumbItems: IBreadcrumbItem[] = [];
    if (routing.match(routes.settings.practices.openingHours)) {
      breadcrumbItems.push({
        onClick: () =>
          routing.push({
            pathname: routes.settings.practices.basePath.pattern
          }),
        text: SettingsLabels.practiceLocation,
        key: SettingsScreenBreadcrumbKeys.practice.toString()
      });

      breadcrumbItems.push({
        text: SettingsLabels.openingHours,
        key: SettingsScreenBreadcrumbKeys.openingHours.toString(),
        isCurrentItem: true
      });
    }
    if (routing.match(routes.settings.practices.openingHoursOverrides)) {
      breadcrumbItems.push({
        onClick: () =>
          routing.push({
            pathname: routes.settings.practices.basePath.pattern
          }),
        text: SettingsLabels.practiceLocation,
        key: SettingsScreenBreadcrumbKeys.practice.toString()
      });

      breadcrumbItems.push({
        text: SettingsLabels.exceptions,
        key: SettingsScreenBreadcrumbKeys.exceptions.toString(),
        isCurrentItem: true
      });
    }
    if (routing.match(routes.settings.practices.locations.new)) {
      breadcrumbItems.push({
        onClick: () =>
          routing.push({
            pathname: routes.settings.practices.basePath.pattern
          }),
        text: SettingsLabels.practiceLocation,
        key: SettingsScreenBreadcrumbKeys.practice.toString()
      });

      breadcrumbItems.push({
        text: SettingsLabels.newLocation,
        key: SettingsScreenBreadcrumbKeys.locations.toString(),
        isCurrentItem: true
      });
    }
    if (routing.match(routes.settings.practices.locations.edit)) {
      breadcrumbItems.push({
        onClick: () =>
          routing.push({
            pathname: routes.settings.practices.basePath.pattern
          }),
        text: SettingsLabels.practiceLocation,
        key: SettingsScreenBreadcrumbKeys.practice.toString()
      });

      breadcrumbItems.push({
        text: locationName ?? SettingsLabels.editLocation,
        key: SettingsScreenBreadcrumbKeys.locations.toString(),
        isCurrentItem: true
      });
    }

    return (
      <div style={{ flexGrow: 1, paddingBottom: 24 }}>
        <Stack tokens={{ childrenGap: 26 }}>
          <BreadCrumb
            styles={{ root: { margin: 0 } }}
            routes={breadcrumbItems}
          />
          <Outlet />
        </Stack>
      </div>
    );
  }
);

const PracticeBreadcrumbs: React.FC = props => {
  const { id: locationId } = useParams();
  return (
    <DataFetcher
      refetchId={locationId}
      fetch={async ({ core }) => {
        return locationId ? core.getOrgUnit(locationId) : undefined;
      }}
      fallback={<Shimmer styles={{ root: { width: "30%" } }} />}
    >
      {locationOrgUnit => (
        <PracticeBreadcrumbsBase
          locationName={locationOrgUnit?.name ?? ""}
          {...props}
        />
      )}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PracticeBreadcrumbs;
