import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  ButtonsGroupChoice,
  ButtonsGroupOption,
  Heading,
  IconButton,
  Link,
  SpinNumberInput,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DatePicker } from "@ui-components/pickers/DatePicker.tsx";

interface NextAvailableFieldDatePickerProps {
  value: Date | undefined;
  onChange: (date: Date | undefined) => void;
  onlyOrgUnitRequired: boolean;
}

type JumpOptions = "weeks" | "months";

export const NextAvailableFieldDatePicker = observer(
  (props: NextAvailableFieldDatePickerProps) => {
    const { value, onChange, onlyOrgUnitRequired } = props;

    const theme = useTheme();

    const [spinInputValue, setSpinInputValue] = useState<string>("2");
    const [choiceGroupValue, setChoiceGroupValue] =
      useState<JumpOptions>("weeks");

    const [navigatedDate, setNavigatedDate] = useState<Date>(
      value ?? DateTime.now().toJSDate()
    );

    const setPickerDate = (date: Date | undefined) => {
      onChange(date);
      if (date) {
        setNavigatedDate(date);
      }
    };

    const onIconClick = () => {
      const num = Number(spinInputValue);
      if (num && !isNaN(num)) {
        setPickerDate(
          DateTime.fromJSDate(value)
            ?.plus({ [choiceGroupValue]: num })
            .toJSDate()
        );
      }
    };

    const jumpOptions: ButtonsGroupOption<JumpOptions>[] = [
      {
        key: "weeks",
        text: "Weeks"
      },
      {
        key: "months",
        text: "Months"
      }
    ];

    return (
      <DatePicker
        calendarProps={{
          minDate: DateTime.today().toJSDate(),
          isMonthPickerVisible: false,
          calendarMonthProps: {
            navigatedDate,
            onNavigateDate: setNavigatedDate
          },
          calendarDayProps: {
            navigatedDate,
            onNavigateDate: setNavigatedDate
          },
          styles: {
            root: {
              margin: "auto"
            }
          }
        }}
        value={value}
        onChange={setPickerDate}
        textFieldProps={{
          label: "Date",
          disabled: onlyOrgUnitRequired
        }}
        onRenderHeader={() => (
          <Stack
            styles={{
              root: {
                margin: "12px 12px 0 12px",
                padding: 8,
                backgroundColor: theme.palette.neutralLighterAlt
              }
            }}
          >
            <Heading labelPaddings>Jump forward</Heading>
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <SpinNumberInput
                value={spinInputValue}
                onChange={setSpinInputValue}
                styles={{ spinButtonWrapper: { width: 86 } }}
                maxDigitsNumber={2}
              />
              <ButtonsGroupChoice
                options={jumpOptions}
                value={choiceGroupValue}
                onChange={setChoiceGroupValue}
              />
              <IconButton
                iconProps={{ iconName: "SearchCalendar" }}
                onClick={onIconClick}
              />
            </Stack>
          </Stack>
        )}
        onRenderFooter={() => {
          const today = DateTime.today().toJSDate();
          const disabled = navigatedDate.getMonth() === today.getMonth();

          const onClick = () => {
            setNavigatedDate(today);
            setPickerDate(today);
          };

          return (
            <Link
              styles={{
                root: {
                  paddingLeft: 16,
                  paddingBottom: 8,
                  fontSize: 12
                }
              }}
              disabled={disabled}
              onClick={onClick}
            >
              Today
            </Link>
          );
        }}
      />
    );
  }
);
