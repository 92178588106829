import { IDropdownOption, ITag } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  EmploymentType,
  RelationshipMetadataDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { AllMutators } from "@ui-components/form/customMutators.ts";
import { minimumSearchLengthFilter } from "@ui-components/form/utils.ts";

import {
  EmployerFieldValue,
  patientFormNameOf
} from "../PatientEditFormValues.tsx";

export class EditEmployersViewModel {
  constructor(
    private practiceStore: PracticeStore,
    private formMutators: AllMutators
  ) {
    this.mapEmployees();
  }

  private _allActiveOccupations: ITag[];

  private get allActiveOccupations() {
    if (!this._allActiveOccupations) {
      this._allActiveOccupations = this.practiceStore.ref.occupations.values
        .filter(ref => !ref.disabled)
        .map(x => ({
          key: x.code,
          name: x.text
        }));
    }
    return this._allActiveOccupations;
  }

  // Includes inactive refdata
  public get allOccupations() {
    return this.practiceStore.ref.occupations.keyNameValues;
  }

  public searchOccupation = (searchText: string) => {
    return minimumSearchLengthFilter(searchText, 2, this.allActiveOccupations);
  };

  private _allEmployeeTypes: IDropdownOption[];
  public get allEmployeeTypes() {
    return this._allEmployeeTypes;
  }

  private mapEmployees() {
    this._allEmployeeTypes = this.practiceStore.ref.employeeTypes.values.map(
      x => ({
        key: x.code,
        text: x.text
      })
    );
  }

  public addEmployer = () => {
    const newEmployer: EmployerFieldValue = {
      animationId: newGuid(),
      relatedContactId: undefined,
      relationship: RelationshipType.Employer,
      hasRelationship: true,
      metadata: {
        relationshipTypeCode: RelationshipType.Employer,
        employmentType: EmploymentType.Employee,
        otherInformation: "",
        currentJob: true,
        primaryJob: false
      }
    } as EmployerFieldValue;
    this.formMutators.push(patientFormNameOf("employers"), newEmployer);
  };

  public onPrimaryJobChange = (
    isPrimaryJob: boolean,
    indexChanged: number,
    fieldValues: EmployerFieldValue[]
  ) => {
    //mark all jobs as 'un-primary' if this job is marked as primary
    if (isPrimaryJob) {
      for (let i = 0; i < fieldValues.length; i++) {
        if (i === indexChanged) continue;

        const metadata = fieldValues[i].metadata;
        if (metadata && metadata.primaryJob) {
          metadata.primaryJob = false;
          fieldValues[i].metadata = metadata;
          this.formMutators.update(
            patientFormNameOf("employers"),
            i,
            fieldValues[i]
          );
        }
      }
    }
  };

  public onCurrentJobChange = (
    isCurrentJob: boolean,
    indexChanged: number,
    fieldValues: EmployerFieldValue[]
  ) => {
    //if not current job then can not be primary job
    if (!isCurrentJob) {
      const metadata = fieldValues[indexChanged]
        .metadata as RelationshipMetadataDto;
      metadata.primaryJob = false;
      this.formMutators.update(
        patientFormNameOf("employers"),
        indexChanged,
        fieldValues[indexChanged]
      );
    }
  };

  public findValue = (employers: EmployerFieldValue[], key: string) => {
    const value = employers.find(p => p.animationId === key);
    const index = employers.findIndex(p => p.animationId === key);
    return { value, index };
  };
}
