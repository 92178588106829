import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ReportColumnChooserValues } from "../components/report/ReportColumnChooserValues.ts";

const nameOf = nameOfFactory<ReportColumnChooserValues>();
export class ReportColumnChooserValidator extends Validator<ReportColumnChooserValues> {
  constructor() {
    super();
    this.forField(nameOf("selectedColumns"), required());
  }
}
