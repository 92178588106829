import { FC } from "react";

import { FontIcon, FontSizes, Stack, TooltipHost } from "@bps/fluent-ui";

export const NameHeader: FC<{ headerName: string; toolTipText: string }> = ({
  headerName,
  toolTipText
}) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 5 }}>
      <span>{headerName}</span>
      <TooltipHost content={toolTipText}>
        <FontIcon
          iconName="Info"
          styles={{
            root: {
              paddingTop: 2,
              fontSize: FontSizes.size12,
              cursor: "pointer"
            }
          }}
        />
      </TooltipHost>
    </Stack>
  );
};
