import { observer } from "mobx-react-lite";
import { FC } from "react";

import { SideRailMenuItem, Stack } from "@bps/fluent-ui";
import { SOTAPSectionText } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import { CurrentInjuryCard } from "../history-examinations/presenting-complaint/CurrentInjuryCard.tsx";
import { BodyDiagramCard } from "./BodyDiagramCard.tsx";
import { useSOTAPFormContext } from "./context/SOTAPFormContext.tsx";
import { FamilyAndSocialHistoryCard } from "./FamilyAndSocialHistoryCard.tsx";
import { OutcomeMeasuresCard } from "./outcome-measure/OutcomeMeasuresCard.tsx";
import { PhysioMedicalHistoryCard } from "./PhysioMedicalHistoryCard.tsx";
import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { SOTAPFormWrapper } from "./SOTAPFormWrapper.tsx";

export const SOTAPSubjectiveContent: FC = observer(() => {
  const { currentSection } = useSOTAPFormContext();
  const { clinicalRecord } = usePatientRecordScreenContext();

  const subjectiveMenuItems: SideRailMenuItem[] = [
    {
      text: SotapMskLabel.ProblemDetails,
      id: SotapMskId.CurrentInjury
    },
    {
      text: SotapMskLabel.BodyDiagram,
      id: SotapMskId.BodyDiagram
    },
    {
      text: SotapMskLabel.PhysioMedicalHistory,
      id: SotapMskId.PhysioMedicalHistory
    },
    {
      text: SotapMskLabel.FamilyAndSocial,
      id: SotapMskId.FamilyAndSocial
    },
    {
      text: SotapMskLabel.OutcomeMeasure,
      id: SotapMskId.OutcomeMeasure
    }
  ];

  const isDischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();

  return (
    <Stack
      styles={{
        root: {
          height: "100%",
          display:
            currentSection !== SOTAPSectionText.Subjective ? "none" : "flex"
        }
      }}
    >
      <SOTAPFormWrapper sideRailMenuItems={subjectiveMenuItems}>
        <CurrentInjuryCard useSotapFormView />
        <BodyDiagramCard />
        <PhysioMedicalHistoryCard />
        <FamilyAndSocialHistoryCard />
        <OutcomeMeasuresCard
          hideAddToolsButton={isDischargeInProgressOrCompleted}
          isViewOnly={isDischargeInProgressOrCompleted}
          episodeOfCareId={clinicalRecord.episodeOfCare?.id}
        />
      </SOTAPFormWrapper>
    </Stack>
  );
});
