import React from "react";

import { Heading } from "@bps/fluent-ui";
import { IReportColumn, ReportDefinition } from "@bps/titanium-powerbi-helper";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ReportColumnChooserValidator } from "../../validators/ReportColumnChooserValidator.ts";
import { ReportColumnChooserFields } from "./ReportColumnChooserFields.tsx";
import { ReportColumnChooserValues } from "./ReportColumnChooserValues.ts";

interface ReportColumnChooserFormDialogProps {
  onClose: (newSlicerGroupName?: string) => void;
  saveColumns: (columns: IReportColumn[], groups: IReportColumn[]) => void;
  reportDefinition: ReportDefinition;
  isAdmin: boolean;
}
export const ReportColumnChooserFormDialog: React.FunctionComponent<
  ReportColumnChooserFormDialogProps
> = ({ onClose, saveColumns, reportDefinition, isAdmin }) => {
  const { sorts, viewColumns, availableViewColumns, name } = reportDefinition;
  const validator = new ReportColumnChooserValidator();

  const onSubmit = async (values: ReportColumnChooserValues) => {
    await saveColumns(values.selectedColumns, values.groupedColumns);
    onClose();
  };

  const init: ReportColumnChooserValues = {
    groupedColumns: [],
    selectedColumns: [],
    availableColumns: isAdmin ? availableViewColumns : [],
    dragFromItem: undefined
  };

  const formValues: ReportColumnChooserValues = viewColumns.reduce(
    (initial, value) => {
      const viewCol = availableViewColumns.find(
        x => x.powerBiIndex === value.powerBiIndex
      );
      if (viewCol) {
        const sort = sorts.find(x => x.columnName === value.powerBiName);
        const availableColumns = initial.availableColumns.filter(
          x => x.name !== value.powerBiName
        );
        if (sort && sort.group) {
          return {
            ...initial,
            groupedColumns: [...initial.groupedColumns, viewCol],
            availableColumns
          };
        }

        return {
          ...initial,
          selectedColumns: [...initial.selectedColumns, viewCol],
          availableColumns
        };
      }
      return {
        ...initial
      };
    },
    init
  );

  return (
    <SubmissionFormDialog
      onSubmit={onSubmit}
      initialValues={formValues}
      buttonsProps={{
        hideButtonsSeparator: true,
        cancelButtonProps: {
          onClick: () => {
            onClose();
          }
        },
        submitButtonProps: {
          text: "Apply",
          iconProps: { hidden: true }
        }
      }}
      validate={validator.validate}
      dialogName="Edit columns"
      dialogProps={{
        minWidth: 728,
        onDismiss: onClose,
        dialogContentProps: {
          showCloseButton: true,
          title: (
            <Heading variant="modal-heading">Customise table - {name}</Heading>
          )
        }
      }}
    >
      {() => {
        return <ReportColumnChooserFields />;
      }}
    </SubmissionFormDialog>
  );
};
