import { FC } from "react";

import { FontIcon, useTheme } from "@bps/fluent-ui";

type OverviewSectionListIconProps = {
  approved: boolean;
};

export const OverviewSectionListIcon: FC<OverviewSectionListIconProps> = ({
  approved
}) => {
  const { semanticColors } = useTheme();
  return (
    <FontIcon
      iconName={approved ? "Accept" : "Cancel"}
      styles={{
        root: {
          color: approved ? semanticColors.successIcon : ""
        }
      }}
    />
  );
};
