import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IconButton,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  ReactionClinicalDataItemDto,
  ReactionSeverity
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { PatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import { getSeverityStylingOptions, ReactionBadge } from "./ReactionBadge.tsx";
import { ReactionFormDialog } from "./ReactionFormDialog.tsx";
import { ReactionListDialog } from "./ReactionListDialog.tsx";
import { ReactionTable } from "./ReactionTable.tsx";
import { reactionSeverityComparer } from "./utils.ts";

export const ReactionListHeader = observer(() => {
  const theme = useTheme();
  const [isAddDialogVisible, setIsAddDialogVisible] = useState<boolean>(false);

  const [isListDialogVisible, setIsListDialogVisible] =
    useState<boolean>(false);

  const { isViewOnlyOrDischarged, clinicalRecord } = useContext(
    PatientRecordScreenContext
  );

  const reactions = clinicalRecord.clinicalData?.reaction?.reactions || [];
  const highestSeverity = getHighestSeverity(reactions);

  let badgeBackgroundColor: string | undefined;
  if (clinicalRecord.clinicalData?.reaction?.nilKnown) {
    badgeBackgroundColor = theme.semanticColors.successBackground;
  } else {
    badgeBackgroundColor = getSeverityStylingOptions(
      theme,
      highestSeverity || ReactionSeverity.Severe
    )?.badgeColor;
  }

  return (
    <>
      {/*/ Header / */}
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
      >
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <Heading
            styles={{ root: { paddingBottom: 6, paddingTop: 6 } }}
            variant="section-sub-heading"
          >
            Reactions
          </Heading>
          <ReactionBadge
            {...dataAttribute(
              DataAttributes.Element,
              "reaction-list-reaction-badge"
            )}
            severity={highestSeverity}
            styles={{
              root: [
                {
                  borderRadius: 8,
                  backgroundColor:
                    reactions.length === 0 ? undefined : badgeBackgroundColor,
                  selectors: isViewOnlyOrDischarged
                    ? undefined
                    : { "&:hover": { opacity: 0.7 } }
                },
                theme.fonts.small
              ]
            }}
            onClick={
              isViewOnlyOrDischarged
                ? undefined
                : () => setIsListDialogVisible(true)
            }
          >
            {reactions.length || 0}
          </ReactionBadge>
        </Stack>

        {!isViewOnlyOrDischarged && (
          <Stack horizontal horizontalAlign="center" verticalAlign="center">
            <IconButton
              {...dataAttribute(
                DataAttributes.Element,
                "reaction-list-add-button"
              )}
              iconProps={{ iconName: "Add" }}
              onClick={() => setIsAddDialogVisible(true)}
            />
            <Text>Add reaction</Text>
          </Stack>
        )}
      </Stack>
      <ReactionTable
        onAddReaction={() => {
          setIsAddDialogVisible(true);
        }}
      />

      {/*/ Dialogs / */}
      {isListDialogVisible && (
        <ReactionListDialog
          clinicalRecord={clinicalRecord}
          onDismiss={() => setIsListDialogVisible(false)}
        />
      )}

      {isAddDialogVisible && (
        <ReactionFormDialog
          clinicalRecord={clinicalRecord}
          onDismiss={() => setIsAddDialogVisible(false)}
        />
      )}
    </>
  );
});

function getHighestSeverity(
  reactions: ReactionClinicalDataItemDto[]
): ReactionSeverity | undefined {
  return (reactions?.map(x => x.severity) || []).sort(
    reactionSeverityComparer
  )[0];
}
