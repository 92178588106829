import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  Facepile,
  Heading,
  IFacepilePersona,
  PersonaCoin,
  PersonaInitialsColor,
  PersonaSize,
  Stack
} from "@bps/fluent-ui";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { ExpanderButton } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/ExpanderButton.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";

type GroupAttendeesFacePileProps = { contacts: Contact[] };

export const GroupAttendeesFacePile: FunctionComponent<GroupAttendeesFacePileProps> =
  observer(({ contacts }) => {
    const { isGroupAppointment, patientLabel } = useContext(
      AppointmentFormContext
    );

    const { booking } = useStores();

    const MAX_DISPLAY = 11;

    if (!isGroupAppointment) return null;

    const personas: IFacepilePersona[] = contacts?.map(contact => ({
      id: contact.id,
      personaName: contact.nameWithTitle,
      imageInitials: contact.initials
    }));

    const isGroupAttendeesSecondColumn =
      booking.ui.currentAppointment?.secondColumnContent ===
      SecondColumnContent.groupAttendees;
    return (
      <>
        <Heading>{`${capitalizeSentence(patientLabel)}s`}</Heading>
        <ExpanderButton<AppointmentFormValues>
          {...dataAttribute(
            DataAttributes.Element,
            "appt-form-group-attendees-button"
          )}
          hasBorder={false}
          fields={[]}
          errorMessage="Required fields remain in this section"
          isOpened={isGroupAttendeesSecondColumn}
          onClick={() => {
            booking.ui.setSecondColumnContent(
              booking.ui.currentAppointment?.secondColumnContent !==
                SecondColumnContent.groupAttendees
                ? SecondColumnContent.groupAttendees
                : undefined
            );
          }}
          styles={{
            root: {
              maxWidth: "100%",
              padding: "4px 8px",
              minHeight: "45px"
            }
          }}
        >
          {personas.length > 0 ? (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              <Facepile
                personas={personas}
                personaSize={PersonaSize.size24}
                maxDisplayablePersonas={MAX_DISPLAY}
                overflowButtonType={1}
                styles={{
                  root: { justifyContent: "center" },
                  members: {
                    display: "block",
                    textAlign: "left",
                    "& .ms-Persona-primaryText": { width: 200 }
                  },
                  member: {
                    paddingLeft: 4
                  }
                }}
              />
              {personas.length > MAX_DISPLAY && (
                <PersonaCoin
                  size={PersonaSize.size24}
                  initialsColor={PersonaInitialsColor.coolGray}
                  imageInitials={`+${personas.length - MAX_DISPLAY}`}
                />
              )}
            </Stack>
          ) : (
            <Heading labelPaddings>{`No ${patientLabel}s selected`}</Heading>
          )}
        </ExpanderButton>
      </>
    );
  });
