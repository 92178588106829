import { observer } from "mobx-react-lite";
import { useField, useForm, useFormState } from "react-final-form";

import { IPersonaProps, Separator, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { CorrespondenceDirection } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ReportTypeKeys } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { VisibilityAndConfidentialityField } from "@modules/clinical/screens/document-writer/components/VisibilityAndConfidentialityField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { UserPickerField } from "@ui-components/pickers/user-picker/UserPickerField.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { CorrespondencesLabels } from "../correspondence/Correspondences.types.ts";
import { CorrespondenceFileUploaderField } from "./CorrespondenceFileUploaderField.tsx";
import { CorrespondenceFormValue } from "./DocumentBatchFormValues.ts";

const getTextFromItem = (persona: IPersonaProps) => persona.text as string;
const UPLOAD_MAX_FILES = 1;

interface CorrespondenceFormBaseProps {
  isEdit: boolean;
  isEditInbox?: boolean;
}

const removeOptions = [ReportTypeKeys.normal];

export const nameOf = nameOfFactory<CorrespondenceFormValue>();
const CorrespondenceFormBase: React.FC<CorrespondenceFormBaseProps> = observer(
  ({ isEdit, isEditInbox }) => {
    const { correspondence, inbox } = useStores();
    const form = useForm();
    const { values: formValues, initialValues } =
      useFormState<CorrespondenceFormValue>();

    const reportTypeOptions: KeyTextValue[] =
      inbox.ref.reportTypes.keyTextValues.filter(
        reportType => !removeOptions.includes(reportType.key as ReportTypeKeys)
      );

    const {
      input: { value: stagingPath }
    } = useField(nameOf("stagingPath"), { subscription: { value: true } });

    const onDirectionChange = (
      value: CorrespondenceDirection,
      values: CorrespondenceFormValue
    ) => {
      // swap the "to" and "from" contacts/users when the direction is changed
      const originalFrom = values.from;
      form.batch(() => {
        form.change(nameOf("from"), values.to);
        form.change(nameOf("to"), originalFrom);
      });
    };

    const correspondenceDirectionOptions =
      correspondence.ref.correspondenceDirections.keyTextValues;

    const correspondenceDirection = correspondenceDirectionOptions.map(
      value => {
        const newKeyTextValue: KeyTextValue = { ...value };

        if (value.key.toUpperCase() === CorrespondenceDirection.In) {
          newKeyTextValue.text = "Incoming";
        } else if (value.key.toUpperCase() === CorrespondenceDirection.Out) {
          newKeyTextValue.text = "Outgoing";
        } else {
          newKeyTextValue.text = "Practice";
        }
        return newKeyTextValue;
      }
    );

    const disableReportType = [
      ReportTypeKeys.acceptable,
      ReportTypeKeys.unacceptable
    ].includes(initialValues.reportType as ReportTypeKeys);
    return (
      <>
        {!isEdit && (
          <CorrespondenceFileUploaderField
            stagingPath={stagingPath}
            hideSubject={true}
            hideDate={true}
            maxFiles={UPLOAD_MAX_FILES}
            setExternalFields={files => {
              const [file] = files;
              file &&
                form.batch(() => {
                  form.change(nameOf("file"), file);
                });
            }}
          />
        )}
        <Fieldset frame styles={{ root: { marginTop: "8px" } }}>
          <TextInputField
            name="file.subject"
            placeholder="Subject"
            label="Subject"
            required
          />
          <Fieldset horizontal>
            <Stack.Item styles={{ root: { flexBasis: "60%" } }}>
              <StaticPickerField
                name={nameOf("type")}
                label="Document type"
                fetchDataSource={
                  correspondence.ref.correspondenceTypes.fetchAsKeyNameValues
                }
                required={true}
              />
            </Stack.Item>
            <Stack.Item>
              <DatePickerField
                maxDate={DateTime.jsDateNow()}
                name="file.date"
                label="Document date"
              />
            </Stack.Item>
          </Fieldset>
          <Stack horizontal>
            <Stack.Item>
              <ButtonsGroupSingleChoiceField
                name={nameOf("direction")}
                options={correspondenceDirection}
                styles={{ root: { marginRight: "8px" } }}
                disabled={!!isEditInbox}
              />
              <FieldSpy
                name={nameOf("direction")}
                onChange={onDirectionChange}
              />
            </Stack.Item>
          </Stack>
          <FieldCondition
            when={nameOf("direction")}
            is={CorrespondenceDirection.Out}
          >
            <Fieldset horizontal>
              <ContactPickerField
                name={nameOf("to")}
                label="To"
                getTextFromItem={getTextFromItem}
                placeholder="Search contact"
                fieldItemStyle={{ root: { flexGrow: 1, flexBasis: 0 } }}
              />

              <UserPickerField
                name={nameOf("from")}
                label="From"
                getTextFromItem={getTextFromItem}
                iconName="Search"
                fieldItemStyle={{ root: { flexGrow: 1, flexBasis: 0 } }}
              />
            </Fieldset>
          </FieldCondition>

          <FieldCondition
            when={nameOf("direction")}
            is={CorrespondenceDirection.In}
          >
            <Fieldset horizontal>
              <UserPickerField
                name={nameOf("to")}
                label="To"
                getTextFromItem={getTextFromItem}
                iconName="Search"
                fieldItemStyle={{ root: { flexGrow: 1, flexBasis: 0 } }}
              />
              <ContactPickerField
                name={nameOf("from")}
                label="From"
                getTextFromItem={getTextFromItem}
                iconName="Search"
                placeholder="Search contact"
                fieldItemStyle={{ root: { flexGrow: 1, flexBasis: 0 } }}
              />
            </Fieldset>
          </FieldCondition>
          <VisibilityAndConfidentialityField
            name={nameOf("visibility")}
            isEdit={isEdit}
          />

          <Separator />
          <Fieldset verticalFill>
            <ButtonsGroupSingleChoiceField
              label="This document is"
              name={nameOf("reportType")}
              options={reportTypeOptions}
              disabled={disableReportType}
            />

            {[ReportTypeKeys.acceptable, ReportTypeKeys.unacceptable].includes(
              formValues.reportType as ReportTypeKeys
            ) && (
              <RecordUpdateCheckedLog
                checkedBy={formValues.checkedBy}
                checkedDate={formValues.dateChecked}
              />
            )}
            <DropdownField
              name={nameOf("outcome")}
              label="Task outcome"
              options={inbox.ref.outcomes.keyTextValues}
              styles={{ root: { width: "50%" } }}
              placeholder={CorrespondencesLabels.Outcome}
            />
          </Fieldset>
        </Fieldset>
      </>
    );
  }
);

export const CorrespondenceForm = withFetch(
  x => [
    x.correspondence.ref.correspondenceDirections.load(),
    x.correspondence.ref.correspondenceTypes.load()
  ],
  CorrespondenceFormBase
);
