import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

export const resetStashedClinicalData = (
  type: ClinicalDataType,
  clinicalRecord: ClinicalRecord
) => {
  switch (type) {
    case ClinicalDataType.CurrentInjury:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "physioBody",
        "currentInjury",
        "injury"
      ]);
      break;
    case ClinicalDataType.ClinicalFlags:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "clinicalFlags",
        "clinicalFlagsConfirmed"
      ]);
      break;
    case ClinicalDataType.SystemsReview:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "systemsReview",
        "systemsReviewConfirmed"
      ]);
      break;
    case ClinicalDataType.GeneralExamination:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "generalExamination",
        "generalExamConfirmed"
      ]);
      break;
    case ClinicalDataType.BodyExam:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "bodyArea",
        "bodyAreaConfirmed"
      ]);
      break;
    case ClinicalDataType.FullBody:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "fullBody"
      ]);
      break;
    case ClinicalDataType.DermatomesAndMyotomes:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "dermatomesAndMyotomes"
      ]);
      break;
    case ClinicalDataType.Discharge:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "discharge"
      ]);
      break;
    case ClinicalDataType.K10:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["k10"]);
      break;
    case ClinicalDataType.NPRS:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["nprs"]);
      break;
    case ClinicalDataType.OREBRO:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["orebro"]);
      break;
    case ClinicalDataType.RAND36:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["rand36"]);
      break;
    case ClinicalDataType.GRCS:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["grcs"]);
      break;
    case ClinicalDataType.DASH:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["dash"]);
      break;
    case ClinicalDataType.PSFS:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["psfs"]);
      break;
    case ClinicalDataType.EPDS:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["epds"]);
      break;
    case ClinicalDataType.DASS21:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["dass21"]);
      break;
    case ClinicalDataType.Social:
      clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
        ClinicalDataType.Social,
        true
      );
      break;
    case ClinicalDataType.WorkHistory:
      clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
        ClinicalDataType.WorkHistory,
        true
      );
      break;
    case ClinicalDataType.FamilyHistory:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "familyHistory"
      ]);
      break;
    case ClinicalDataType.PhysicalActivity:
      clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
        ClinicalDataType.PhysicalActivity,
        true
      );
      break;
    case ClinicalDataType.Sleep:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["sleep"]);
      break;
    case ClinicalDataType.Alcohol:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["alcohol"]);
      break;
    case ClinicalDataType.Tobacco:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["tobacco"]);
      break;
    case ClinicalDataType.SubstanceUse:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "substanceUse"
      ]);
      break;
    case ClinicalDataType.PatientTreatmentPlan:
      clinicalRecord.stashedClinicalData?.setHaveBeenResetForms(
        ClinicalDataType.PatientTreatmentPlan,
        true
      );
      break;
    case ClinicalDataType.TreatmentAndEducation:
      clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
        "treatmentAndEducation"
      ]);
      break;
    default:
      return undefined;
  }
};
