import { Culture, DateLocalizer, DateRange } from "react-big-calendar";

import { DateTime } from "@bps/utils";

const dateRangeFormat = (
  { start, end }: DateRange,
  culture: Culture,
  local: DateLocalizer
) =>
  `${local.format(start, "D", culture)} — ${local.format(end, "D", culture)}`;

const timeRangeFormat = (
  { start, end }: DateRange,
  culture: Culture,
  local: DateLocalizer
) =>
  `${local.format(start, "t", culture)} — ${local.format(end, "t", culture)}`;

const timeRangeStartFormat = (
  { start }: DateRange,
  culture: Culture,
  local: DateLocalizer
) => `${local.format(start, "t", culture)} — `;

const timeRangeEndFormat = (
  { end }: DateRange,
  culture: Culture,
  local: DateLocalizer
) => ` — ${local.format(end, "t", culture)}`;

const weekRangeFormat = (
  { start, end }: DateRange,
  culture: Culture,
  local: DateLocalizer
) =>
  `${local.format(start, "MMMM dd", culture)} — ${local.format(
    end,
    DateTime.fromJSDate(start).month === DateTime.fromJSDate(end).month
      ? "dd"
      : "MMMM dd",
    culture
  )}`;

export const formats = {
  dateFormat: "dd",
  dayFormat: "dd EEE",
  weekdayFormat: "ccc",

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: "t",

  monthHeaderFormat: "MMMM yyyy",
  dayHeaderFormat: "cccc MMM dd",
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: "ccc MMM dd",
  agendaTimeFormat: "t",
  agendaTimeRangeFormat: timeRangeFormat
};

// currently react-bog-calendar does not have luxon localizer
// the is not merged PR in the lib now https://github.com/jquense/react-big-calendar/pull/1574
export const luxonLocalizer = () => {
  const locale = (d: DateTime, c: Culture) =>
    c ? d._dateTime.reconfigure({ locale: c }) : d;

  return new DateLocalizer({
    formats,
    firstOfWeek() {
      return 1;
    },

    format(value, format, culture) {
      let dateTime: DateTime;

      if (typeof value === "string") {
        dateTime = DateTime.fromISO(value);
      } else if (typeof value === "number") {
        dateTime = DateTime.fromMillis(value); // this probably isn't right
      } else {
        dateTime = DateTime.fromJSDate(value);
      }

      return locale(dateTime, culture).toFormat(format);
    }
  });
};
