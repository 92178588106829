import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  Link,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

import { messageLabelStyle } from "./NextAvailableFields.styles.ts";

interface EmptyStateProps {
  setIsExpanded: (value: boolean) => void;
  changeDate: (date: Date) => void;
  nextAvailableText: string;
  nextAvailableLinkText: DateTime | undefined;
}

export const EmptyState: FunctionComponent<EmptyStateProps> = ({
  setIsExpanded,
  changeDate,
  nextAvailableText,
  nextAvailableLinkText
}) => {
  return (
    <Stack horizontal>
      <Text
        {...dataAttribute(DataAttributes.Element, "prefix-text")}
        block
        styles={{
          root: messageLabelStyle
        }}
      >
        {nextAvailableLinkText
          ? nextAvailableText.substring(0, 29)
          : nextAvailableText}
      </Text>
      {nextAvailableLinkText && (
        <>
          <Link
            {...dataAttribute(DataAttributes.Element, "date-link")}
            onClick={() => {
              setIsExpanded(true);
              changeDate(nextAvailableLinkText.toJSDate());
            }}
            styles={{
              root: { fontSize: 14, fontStyle: "italic" }
            }}
          >
            {nextAvailableLinkText.toDayDefaultFormat()}
          </Link>
          <Text
            {...dataAttribute(DataAttributes.Element, "suffix-text")}
            styles={{
              root: messageLabelStyle
            }}
          >
            {nextAvailableText.substring(30)}
          </Text>
        </>
      )}
    </Stack>
  );
};
