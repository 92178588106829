import { action, computed, observable } from "mobx";

import { DateTime } from "@bps/utils";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  AppointmentStatusCode,
  CalendarEventStatus,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export class AppointmentInformationModalHelper {
  constructor(private root: RootStore) {}

  get notification() {
    return this.root.notification;
  }

  get booking() {
    return this.root.booking;
  }

  @observable
  selectedCalendarEventId: string | undefined = undefined;

  @action
  setSelectedCalendarEventId = (id: string) => {
    this.selectedCalendarEventId = id;
  };

  @computed
  get selectedCalendarEvent() {
    return this.selectedCalendarEventId
      ? this.booking.calendarEventsMap.get(this.selectedCalendarEventId)
      : undefined;
  }

  @computed
  get calendarEventStatus() {
    return this.selectedCalendarEvent?.status;
  }

  @computed
  get appointmentStatus() {
    return this.selectedCalendarEvent?.appointmentStatus;
  }

  @computed
  get isDnaOrCanceledStatus() {
    if (this.appointmentStatus) {
      return (
        this.appointmentStatus === AppointmentStatusCode.DidNotAttend ||
        this.calendarEventStatus === CalendarEventStatus.Cancelled
      );
    }
    return false;
  }

  onRescheduleOrCreateCopyClick = () => {
    if (this.selectedCalendarEvent) {
      this.booking?.ui.showCalendarEventDialog({
        type: CalendarEventType.Appointment,
        secondColumnContent: SecondColumnContent.nextAvailable,
        initialValues: {
          startDate: DateTime.jsDateNow(),
          startTime: this.selectedCalendarEvent.startTime.toTimeInputFormat(),
          appointmentTypeId: this.selectedCalendarEvent.appointmentTypeId,
          patientId: this.selectedCalendarEvent.contactId,
          episodeOfCareId:
            this.selectedCalendarEvent.reason?.episodeOfCareId ?? undefined,
          providerId: this.selectedCalendarEvent.userId
        }
      });
    }
  };

  onCancelClick = () => {
    this.booking?.ui.setShowCancelCalendarEventDialog(
      true,
      this.selectedCalendarEventId
    );
  };

  onEditClick = () => {
    if (this.selectedCalendarEventId) {
      this.booking.ui.showCalendarEventDialog({
        type: CalendarEventType.Appointment,
        id: this.selectedCalendarEventId
      });
    } else {
      this.notification.warn(notificationMessages.selectAppt);
    }
  };
}
