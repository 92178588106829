import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { Heading } from "@bps/fluent-ui";
import { GetStagingInfoDto } from "@libs/api/dtos/index.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ClinicalImageFormFields } from "../clinical-form/ClinicalImageFormFields.tsx";
import { ClinicalImageFormValues } from "../clinical-form/DocumentBatchFormValues.ts";
import { handleConflictError } from "../correspondence/utils.tsx";
import { ClinicalImageDialogHelper } from "./ClinicalImageDialogHelper.tsx";
import { ClinicalImagesDialogValidator } from "./ClinicalImagesDialogValidator.tsx";

const validator = new ClinicalImagesDialogValidator();

interface CorrespondenceDialogProps {
  clinicalImage?: ClinicalDocument;
  onDismiss: () => void;
  onConflictError: (confirmed: boolean) => void;
}

export const ClinicalImagesDialog: React.FC<CorrespondenceDialogProps> =
  observer(({ clinicalImage, onDismiss, onConflictError }) => {
    const root = useStores();
    const { notification } = root;

    const { clinicalRecord } = usePatientRecordScreenContext();
    const clinicalImageDialogHelper = useRef(
      new ClinicalImageDialogHelper(clinicalRecord, root)
    ).current;

    const isEdit = !!clinicalImage;

    const formName = isEdit ? "Edit clinical image" : "New clinical image";

    const initialValues =
      clinicalImageDialogHelper.getInitialValues(clinicalImage);

    return (
      <DataFetcher<GetStagingInfoDto>
        fetch={async root => {
          const stagingPath = await root.clinical.getStagingInfo();
          return stagingPath;
        }}
      >
        {stagingPath => (
          <SubmissionFormDialog<ClinicalImageFormValues>
            dialogName="Clinical images dialog"
            validate={validator.validate}
            onSubmit={async formValues => {
              try {
                if (isEdit && clinicalImage) {
                  await clinicalImageDialogHelper.updateClinicalImage(
                    formValues,
                    clinicalImage
                  );
                } else {
                  await clinicalImageDialogHelper.submitNewClinicalImage(
                    formValues
                  );
                }
              } catch (error) {
                const isConflict = await handleConflictError(
                  error,
                  confirmed => {
                    onConflictError(confirmed);
                    if (!confirmed) throw new Error(error.message);
                  }
                );
                if (!isConflict) {
                  notification.error(
                    "An error occurred when uploading the clinical image."
                  );
                  throw new Error(error.message);
                }
              }
            }}
            onSubmitSucceeded={onDismiss}
            styles={{ fields: { overflowY: "inherit" } }}
            buttonsProps={() => {
              return {
                submitButtonProps: {
                  text: "Save",
                  iconProps: { hidden: true }
                },
                disableSubmitOnFormInvalid: true,
                disableSubmitOnPristine: true,
                hideButtonsSeparator: true
              };
            }}
            initialValues={
              isEdit ? initialValues : { ...initialValues, stagingPath }
            }
            dialogProps={{
              onDismiss,
              modalProps: {
                layerProps: {
                  eventBubblingEnabled: true
                }
              },
              minWidth: 650,
              dialogContentProps: {
                title: <Heading variant="modal-heading">{formName}</Heading>
              }
            }}
            render={() => (
              <ClinicalImageFormFields
                isEdit={isEdit}
                clinicalImage={clinicalImage}
              />
            )}
          />
        )}
      </DataFetcher>
    );
  });
