import {
  Heading,
  MessageBar,
  MessageBarType,
  Spinner,
  Tile
} from "@bps/fluent-ui";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

type HL7PreviewProps = {
  previewDocument: InboxDocument;
};

// Please note that this component was created in response to PBI 21133
// and is intended as a purely bare bones implementation. Further work
// to flesh out this component will be dealt with later
export const HL7Preview: React.FC<HL7PreviewProps> = ({ previewDocument }) => {
  if (!previewDocument.fileUri) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        Cannot display HL7 file contents
      </MessageBar>
    );
  }

  return (
    <DataFetcher
      fetch={root =>
        root.inbox.getDocumentFileContents(previewDocument.fileUri)
      }
      fallback={<Spinner />}
    >
      {(hl7Text: string) => (
        <Tile styles={{ root: { margin: 16, textAlign: "left", padding: 16 } }}>
          <Heading labelPaddings>Raw HL7 contents</Heading>
          <code>{hl7Text}</code>
        </Tile>
      )}
    </DataFetcher>
  );
};
