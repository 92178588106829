import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";

import { UsersFilterDto } from "../types/users-filter-dto.interface.ts";

type UserListFilterProps = Pick<FilterBarProps<UsersFilterItems>, "children">;

export interface UsersFilterItems extends UsersFilterDto {
  businessRoles: string[];
}

export const UserListFilter: React.FC<UserListFilterProps> = ({ children }) => {
  const { core } = useStores();
  const { Active, Inactive, Pending } = UserStatus;
  const items: Item<UsersFilterItems>[] = [
    {
      type: "searchBox",
      name: "search",
      stickItem: true,
      props: {
        placeholder: "Search by name",
        autoFocus: true
      }
    },

    {
      type: "optionsSelect",
      name: "statusCodes",
      props: {
        id: "userlist-filter-status",
        multiSelect: true,
        hideSearchOption: true,
        options: [
          {
            key: Active,
            text: "Active"
          },
          {
            key: Pending,
            text: "Pending sign up"
          },
          {
            key: Inactive,
            text: "Inactive"
          }
        ],
        placeholder: "Status",
        calloutWidth: 200
      }
    }
  ];
  items.push({
    type: "optionsSelect",
    name: "businessRoles",
    props: {
      id: "userlist-filter-business-roles",
      multiSelect: true,
      hideSearchOption: true,
      options: core.catalogBusinessRoles.map(({ code, text }) => ({
        key: code,
        text
      })),
      placeholder: "Business roles",
      calloutWidth: 200
    }
  });

  return (
    <FilterBar<UsersFilterItems>
      initialValues={{ statusCodes: [Active, Pending], businessRoles: [] }}
      items={items}
      styles={{ searchBox: { minWidth: 206 } }}
    >
      {children}
    </FilterBar>
  );
};
