import {
  BloodPressureDataItemDto,
  PulseDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  lessThan,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { GeneralExaminationFormValues } from "../GeneralExaminationFormValues.ts";

class BloodPressuresDataValidator extends Validator<BloodPressureDataItemDto> {
  constructor() {
    super();

    const validateBP = (
      val: number | undefined,
      values: BloodPressureDataItemDto
    ): true | "Invalid BP" => {
      const diastolic = Number(values?.diastolic);
      const systolic = Number(values?.systolic);
      if (systolic <= diastolic) {
        return "Invalid BP";
      }
      return true;
    };

    this.forField("diastolic", [
      predicate(
        (_v, values) => !!values?.systolic,
        required(),
        maxLength(3),
        greaterThan(0)
      ),
      validateBP
    ]);

    this.forField("systolic", [
      predicate(
        (_v, values) => !!values?.diastolic,
        required(),
        maxLength(3),
        greaterThan(0)
      ),
      validateBP
    ]);
  }
}

class PulsesDataValidator extends Validator<PulseDataItemDto> {
  constructor() {
    super();

    this.forField("heartRate", [
      predicate(
        (_v, values) => !!values?.position || !!values?.method,
        required(),
        maxLength(3),
        greaterThan(0)
      )
    ]);
  }
}

export class GeneralExamFormValidator extends Validator<GeneralExaminationFormValues> {
  constructor() {
    super();
    const bloodPressuresDataValidator = new BloodPressuresDataValidator();
    const pulsesDataValidator = new PulsesDataValidator();
    this.forArrayField("bloodPressures", bloodPressuresDataValidator.validate);
    this.forArrayField("pulses", pulsesDataValidator.validate);
    this.forField(nameof("temperature"), [greaterThan(0), lessThan(100)]);
    this.forField(nameof("comment"), [maxLength(250)]);
  }
}
