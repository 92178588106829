import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { User } from "@stores/core/models/User.ts";

export const defaultFilter: PastVisitsFilterStateInternal = {
  providers: [],
  searchReason: "",
  showConsultOnly: true
};

export interface PastVisitsFilterProps {
  setPastVisitsFilter: (filter: PastVisitsFilterStateInternal) => void;
  providers: User[] | undefined;
  initialFilterValues: PastVisitsFilterStateInternal;
  clinicalRecord: ClinicalRecord;
}

export interface PastVisitsFilterStateInternal {
  providers: string[];
  dateFrom?: Date;
  dateTo?: Date;
  showConsultOnly?: boolean;
  searchReason: string;
  confidential?: boolean;
}

export const pastVisitsFilterNameOf =
  nameOfFactory<PastVisitsFilterStateInternal>();
