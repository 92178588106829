import { observer } from "mobx-react-lite";

import { Heading, Stack } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { ActivityDescriptionDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClinicalActivityType } from "@modules/clinical/screens/patient-record/components/clinical-activity/types/clinical-activity.type.ts";
import { ActivityDescription } from "@stores/clinical/models/ActivityDescription.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DateRange } from "@ui-components/form/DatePickerOnInField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { ClinicalActivitiesPreferenceValidator } from "../Validator/ClinicalReminderPreferenceDialogValidator.tsx";

interface ClinicalReminderPreferenceDialogProps {
  onDismiss: () => void;
  selectedActivityDescription?: ActivityDescription;
}

export interface ClinicalReminderPreferenceFormValues {
  isActive: boolean;
  reasonText: string;
  interval?: number;
  frequency?: string;
  clinicallySignificant?: boolean;
}

export const ClinicalReminderPreferenceDialog: React.FC<ClinicalReminderPreferenceDialogProps> =
  observer(({ onDismiss, selectedActivityDescription }) => {
    const { clinical } = useStores();

    const nameOf = nameOfFactory<ClinicalReminderPreferenceFormValues>();

    const activityDescriptions = clinical.activityDescriptionMapValues ?? [];
    const initialValues: ClinicalReminderPreferenceFormValues =
      selectedActivityDescription
        ? {
            isActive: !selectedActivityDescription.isInactive,
            reasonText: selectedActivityDescription.reasonText,
            interval: selectedActivityDescription.interval,
            frequency: selectedActivityDescription.frequency,
            clinicallySignificant:
              selectedActivityDescription.clinicallySignificant
          }
        : { isActive: true, reasonText: "" };

    const onSubmit = async (values: ClinicalReminderPreferenceFormValues) => {
      if (selectedActivityDescription) {
        const updatedActivity: ActivityDescriptionDto = {
          ...selectedActivityDescription,
          id: selectedActivityDescription.id,
          activityType: selectedActivityDescription.activityType,
          eTag: selectedActivityDescription.eTag,
          isSupplied: selectedActivityDescription.isSupplied,
          reasonText: values.reasonText,
          frequency: values.frequency,
          interval: values.interval,
          clinicallySignificant: values.clinicallySignificant,
          isInactive: !values.isActive
        };

        await clinical.updateActivityDescription(updatedActivity);
      } else {
        const newActivityDecription: ActivityDescriptionDto = {
          activityType: ClinicalActivityType.Reminder,
          isInactive: !values.isActive,
          reasonText: values.reasonText,
          frequency: values.frequency,
          interval: values.interval,
          clinicallySignificant: values.clinicallySignificant,
          isSupplied: false,
          id: newGuid(),
          eTag: ""
        };

        await clinical.createActivityDescription(newActivityDecription);
      }
    };

    const validator = new ClinicalActivitiesPreferenceValidator(
      activityDescriptions,
      selectedActivityDescription
    );

    return (
      <SubmissionFormDialog<ClinicalReminderPreferenceFormValues>
        onSubmit={onSubmit}
        dialogName="Create reminder"
        initialValues={initialValues}
        onSubmitSucceeded={onDismiss}
        validate={validator.validate}
        buttonsProps={{
          disableSubmitOnPristine: true,
          disableSubmitOnFormInvalid: true
        }}
        dialogProps={{
          dialogContentProps: {
            title: (
              <Heading variant="modal-heading">
                {!selectedActivityDescription
                  ? "New clinical reminder"
                  : "Edit reminder"}
              </Heading>
            ),
            showCloseButton: true
          },
          maxWidth: 640,
          minWidth: 640,
          onDismiss
        }}
      >
        {() => (
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack tokens={{ childrenGap: 6 }}>
              <Stack horizontal verticalAlign="end">
                <Heading hasAsterisk>Reminder reason</Heading>

                {selectedActivityDescription && (
                  <Stack grow horizontalAlign="end">
                    <ToggleField
                      name={nameOf("isActive")}
                      label="Active"
                      inlineLabel
                      styles={{
                        label: { fontWeight: "normal", margin: 8 },
                        root: { marginBottom: -14 }
                      }}
                    />
                  </Stack>
                )}
              </Stack>

              <Stack grow>
                <TextInputField name={nameOf("reasonText")} required />
              </Stack>
            </Stack>

            <CheckboxField
              label="Clinically significant"
              name={nameOf("clinicallySignificant")}
            />
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
              <SpinNumberInputField
                label="Default Interval"
                name={nameOf("interval")}
              />
              <ButtonsGroupSingleChoiceField
                name={nameOf("frequency")}
                options={[
                  { key: DateRange.days, text: DateRange.days },
                  { key: DateRange.weeks, text: DateRange.weeks },
                  {
                    key: DateRange.months,
                    text: DateRange.months
                  },
                  { key: DateRange.years, text: DateRange.years }
                ]}
              />
            </Stack>
          </Stack>
        )}
      </SubmissionFormDialog>
    );
  });
