import { useState } from "react";

import {
  DetailsList,
  FontSizes,
  FontWeights,
  IColumn,
  Stack,
  Text
} from "@bps/fluent-ui";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { AgentReaction, ReactionsListLabels } from "./ReactionForm.types.ts";
import { ReactionRecordedDetails } from "./ReactionRecordedDetails.tsx";
import { RenderAgentDetails } from "./RenderAgentDetails.tsx";
import { RenderReactionActions } from "./RenderReactionActions.tsx";
import { StyledReactionText } from "./RenderReactionText.tsx";
import { RenderStatusBadgeAndIcon } from "./RenderStatusBadgeAndIcon.tsx";
import {
  getReactionAgent,
  ReactionColumnSorting,
  ReactionSortOrder,
  sortReactions
} from "./utils.ts";

interface IReactionListProps {
  reactions: AgentReaction[];
  clinicalRecord: ClinicalRecord;
}

export const ReactionsList: React.FunctionComponent<IReactionListProps> = ({
  reactions,
  clinicalRecord
}) => {
  const [sortOrder, setSortOrder] =
    useState<ReactionColumnSorting>("ascending");

  const [sortedColumn, setSortedColumn] =
    useState<ReactionSortOrder>("severity");

  const onColumnHeaderClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ) => {
    const isSortedDescending =
      sortedColumn === column.key && sortOrder === "ascending";

    const newSortOrder = isSortedDescending ? "descending" : "ascending";
    setSortedColumn(column.key as ReactionSortOrder);
    setSortOrder(newSortOrder);
  };

  const reactionClinicalData = clinicalRecord.clinicalData?.reaction;

  const getCertainty = (reaction: AgentReaction) => {
    const certainty =
      clinicalRecord.clinical.ref.reactionCertainties.keyTextValues.find(
        x => x.key === reaction.certainty
      )?.text;
    return certainty;
  };

  const getAgent = (reaction: AgentReaction) => {
    const result = getReactionAgent(
      reaction.agent.code,
      reactionClinicalData?.agents
    );
    return result;
  };

  const columns: IColumn[] = [
    {
      name: ReactionsListLabels.Severity,
      key: "severity",
      minWidth: 100,
      maxWidth: 100,
      isMultiline: true,
      showSortIconWhenUnsorted: true,
      isSorted: sortedColumn === "severity",
      isSortedDescending: sortOrder === "descending",
      onColumnClick: onColumnHeaderClick,
      onRender: (reaction: AgentReaction) => (
        <RenderStatusBadgeAndIcon reaction={reaction} />
      )
    },
    {
      name: ReactionsListLabels.Agent,
      key: "agent",
      minWidth: 170,
      maxWidth: 170,
      isMultiline: true,
      showSortIconWhenUnsorted: true,
      isSorted: sortedColumn === "agent",
      isSortedDescending: sortOrder === "descending",
      onColumnClick: onColumnHeaderClick,
      onRender: (reaction: AgentReaction) => (
        <RenderAgentDetails reaction={reaction} agent={getAgent(reaction)} />
      )
    },
    {
      name: ReactionsListLabels.Reaction,
      key: "reaction",
      minWidth: 100,
      maxWidth: 100,
      isMultiline: true,
      onRender: (reaction: AgentReaction) => (
        <StyledReactionText reaction={reaction} />
      )
    },
    {
      name: ReactionsListLabels.Certainty,
      key: "certainty",
      minWidth: 80,
      maxWidth: 80,
      isMultiline: true,
      onRender: (reaction: AgentReaction) => (
        <Stack>
          <Text
            styles={{
              root: {
                fontWeight: FontWeights.regular,
                fontSize: FontSizes.size14
              }
            }}
          >
            {getCertainty(reaction)}
          </Text>
        </Stack>
      )
    },
    {
      name: ReactionsListLabels.Recorded,
      key: "recorded",
      minWidth: 124,
      maxWidth: 124,
      isMultiline: true,
      onRender: (reaction: AgentReaction) => (
        <ReactionRecordedDetails reaction={reaction} />
      )
    },
    {
      name: "",
      key: "actions",
      minWidth: 20,
      maxWidth: 20,
      onRender: (reactionItem: AgentReaction) => (
        <RenderReactionActions
          reaction={reactionItem}
          clinicalRecord={clinicalRecord}
        />
      )
    }
  ];

  return (
    <>
      {reactions.length > 0 && (
        <Stack tokens={{ childrenGap: 4 }}>
          <DetailsList
            setKey="reactions list"
            styles={{
              contentWrapper: {
                maxHeight: "40vh",
                overflowX: "hidden"
              }
            }}
            items={sortReactions(reactions, sortedColumn, sortOrder)}
            columns={columns}
            cellStyleProps={{
              cellLeftPadding: 7,
              cellRightPadding: 7,
              cellExtraRightPadding: 0
            }}
          />
        </Stack>
      )}
    </>
  );
};
