import {
  MeridianDataItemDto,
  TreatmentDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  Length,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

const OTHER = "OTH";
export class TreatmentAndEducationCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();
    const treatmentValidator = new TreatmentValidator();
    const meridianValidator = new MeridianValidator();
    this.forArrayField(sotapNameOf("treatments"), treatmentValidator.validate);
    this.forArrayField(
      sotapNameOf("primaryMeridians"),
      meridianValidator.validate
    );
    this.forArrayField(
      sotapNameOf("extraordinaryMeridians"),
      meridianValidator.validate
    );
    this.forField(sotapNameOf("educationComment"), [
      predicate(
        (value, parent: SOTAPFormValues, allValues?: SOTAPFormValues) => {
          if (!allValues || !allValues.educationOptions) return false;

          return allValues.educationOptions.includes(OTHER);
        },
        required()
      ),
      maxLength(Length.long)
    ]);
    this.forField(sotapNameOf("otherComment"), maxLength(Length.long));
  }
}

export class TreatmentValidator extends Validator<TreatmentDataItemDto> {
  readonly nameOf = nameOfFactory<TreatmentDataItemDto>();

  constructor() {
    super();

    this.forField(this.nameOf("treatment"), [
      predicate(
        (value, parent: TreatmentDataItemDto, allValues?: SOTAPFormValues) => {
          if (!allValues || !allValues.treatments) return false;
          if (value === OTHER) return false;

          const treatments = allValues.treatments.filter(
            x => x.treatment !== undefined
          );

          if (treatments.length > 1) {
            const duplicateActivities = treatments.filter(
              x => x.treatment === value
            );
            return duplicateActivities.length > 1;
          }

          return false;
        },
        () => ValidationMessages.arrayNoDuplicatesAllowed
      )
    ]);

    this.forField(this.nameOf("comment"), [
      maxLength(Length.long),
      (value, parent: TreatmentDataItemDto) => {
        if (parent.comment && parent.treatment === undefined) {
          return ValidationMessages.treatmentRequired;
        }
        return;
      }
    ]);
  }
}

class MeridianValidator extends Validator<MeridianDataItemDto> {
  readonly nameOf = nameOfFactory<MeridianDataItemDto>();
  constructor() {
    super();
    this.forField(this.nameOf("comment"), [maxLength(Length.long)]);
  }
}
