import { DateTime } from "@bps/utils";
import {
  isBeforeTimeField,
  isDateBeforeField,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { nameOf, UnavailableFormValues } from "./UnavailableForm.types.ts";

export class UnavailableFormValidator extends Validator<UnavailableFormValues> {
  constructor() {
    super();

    this.forField("startDate", [required()]);
    this.forField("startTime", [required()]);
    this.forField("endDate", [
      required(),
      isDateBeforeField(
        "End date must not be before start date",
        nameOf("startDate"),
        false
      )
    ]);
    this.forField("endTime", [
      required(),
      predicate(
        (value, values: UnavailableFormValues) =>
          !!value &&
          !!values.startDate &&
          !!values.endDate &&
          DateTime.fromJSDate(values.startDate).equals(
            DateTime.fromJSDate(values.endDate)
          ),
        isBeforeTimeField(
          "End time must be after start time",
          nameOf("startTime"),
          true
        )
      )
    ]);
    this.forField("providerId", [required()]);
  }
}
