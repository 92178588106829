import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { UserActionType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { UserInboxActionFields } from "../../shared-components/UserInboxActionFields.tsx";
import { AssignToPickerField } from "./AssignToPickerField.tsx";
import { TaskDetailsFormValues, TasksScreenLabels } from "./Tasks.types.ts";

export type TaskDetailsFormProps = {
  task: UserTask;
};

export const TaskDetailsForm: FC<TaskDetailsFormProps> = observer(
  ({ task }) => {
    const { inbox, notification } = useStores();
    const { isFormActive, setActiveForm, setNextItemAfterUpdate } =
      useContext(InboxScreenContext);

    const initialValues: TaskDetailsFormValues = {
      id: task.id,
      assignTo: task.assignedToUserId,
      dueDate: task.dueDateTime ? task.dueDateTime?.toJSDate() : undefined,
      instructionCode: task.instructionCode,
      extraInfo: task.extraInfo
    };

    const nameOf = nameOfFactory<TaskDetailsFormValues>();

    const onClose = () => {
      setActiveForm(false);
    };

    const onSubmit = async (values: TaskDetailsFormValues) => {
      const baseRequest = {
        id: values.id,
        eTag: task.eTag,
        userActionId: task.userActionId,
        subject:
          values.instructionCode !== UserActionType.NoAction
            ? UserActionType.ReceptionToAdvise
            : undefined,
        assignedToUserId: values.assignTo,
        dueDateTime: DateTime.jsDateToISODate(values.dueDate),
        instructionCode: values.instructionCode,
        rootEntityETag: task.rootEntityETag
      };

      // The next item to follow is the same one here.
      setNextItemAfterUpdate(task.id);

      await inbox.updateUserTask(baseRequest);
    };

    return (
      <SubmissionForm<TaskDetailsFormValues>
        formName="task-details"
        initialValues={initialValues}
        onSubmitSucceeded={() => {
          notification.success("Task has been updated");
          onClose();
        }}
        onSubmit={onSubmit}
        hideButtons={!isFormActive}
        buttonsProps={{
          cancelButtonProps: {
            id: "close-task-details-form"
          },
          onCancel: onClose,
          submitButtonProps: {
            text: TasksScreenLabels.Save,
            iconProps: undefined
          }
        }}
      >
        {() => {
          return (
            <Stack
              tokens={{ childrenGap: 8 }}
              styles={(_props, { palette }) => ({
                root: {
                  padding: 8,
                  backgroundColor: !isFormActive
                    ? palette.neutralLighterAlt
                    : undefined
                }
              })}
            >
              <Stack tokens={{ childrenGap: 8 }}>
                <AssignToPickerField
                  name={nameOf("assignTo")}
                  disabled={!isFormActive}
                  onEditClick={() => {
                    setActiveForm(true);
                  }}
                />
                <UserInboxActionFields disabled={!isFormActive} />
              </Stack>
            </Stack>
          );
        }}
      </SubmissionForm>
    );
  }
);
