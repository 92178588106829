import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import {
  DefaultButton,
  FontIcon,
  FontSizes,
  Heading,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  useTheme
} from "@bps/fluent-ui";
import { UserContext } from "@modules/settings/screens/users/components/context/UserContext.tsx";

export const LicenceSummary: FC = observer(() => {
  const { palette, effects } = useTheme();
  const helper = useContext(UserContext);
  const validLicences = helper.core.licences.filter(x => x.isValid);

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      tokens={{ childrenGap: 8 }}
      styles={{
        root: {
          background: palette.white,
          borderRadius: 4,
          padding: "22px 24px",
          marginBottom: 15,
          boxShadow: effects.elevation4
        }
      }}
    >
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <FontIcon
          iconName="DocumentSet"
          styles={{
            root: {
              color: palette.accent,
              fontSize: FontSizes.size24
            }
          }}
        />
        <Heading
          styles={{
            root: {
              fontSize: FontSizes.size18
            }
          }}
        >
          Licences in use
        </Heading>
        <Text
          styles={{
            root: {
              color: palette.neutralSecondary,
              paddingLeft: 15
            }
          }}
        >
          Omni
        </Text>
        <TextBadge
          badgeColor={TextBadgeColor.blue}
          styles={{ root: { minWidth: "100px" } }}
        >
          {`${validLicences.filter(x => x.userId).length} of ${
            validLicences.length
          } Allied`}
        </TextBadge>
      </Stack>
      <Stack>
        <DefaultButton text="Purchase licences" />
      </Stack>
    </Stack>
  );
});
