import { IStackItemProps, IStyle, ITheme, Theme } from "@bps/fluent-ui";

import { WHITE } from "./constants.ts";

export const getToggleBarButtonStyles = () => {
  return {
    callout: { root: { padding: 4 } },
    hover: { root: { padding: 8, fontWeight: "400" } }
  };
};

export const getDrawingCanvasStyles = () => {
  return {
    wrapper: { root: { maxWidth: 598 } },
    editorWrapper: (prop: IStackItemProps, theme: ITheme) => ({
      root: {
        width: "100%",
        justifyContent: "center",
        display: "flex",
        border: `1px solid ${theme.palette.neutralLighter}`
      }
    })
  };
};

export const getDrawingThumbnailListStyles = (theme: ITheme) => {
  return {
    wrapper: {
      root: {
        width: "auto",
        height: "100%",
        border: `1px solid ${theme.palette.neutralLighter}`,
        overflowY: "auto",
        overflowX: "hidden",
        paddingLeft: 10
      }
    },
    choiceGroup: {
      root: {
        margin: 8
      }
    },
    choiceGroupOption: {
      innerField: { padding: 0, height: 96, width: 96 },
      field: { padding: 0, backgroundColor: theme.palette.neutralLighter },
      labelWrapper: { padding: 0, minHeight: 0, height: 0, margin: 0 },
      imageWrapper: { padding: 0, margin: 0 }
    }
  };
};

export const getDrawingToolbarStyles = () => {
  return {
    colourPicker: {
      focusedContainer: {
        minWidth: 0,
        margin: 0
      },
      tableCell: {
        padding: 0
      },
      root: {
        margin: 0,
        padding: 0,
        borderCollapse: "separate",
        borderSpacing: "4px"
      }
    },
    colorPickerWrapper: {
      root: {
        height: 114
      }
    },
    selectedStroke: (borderColor: string, theme: Theme) => {
      let outline = "";
      let backgroundColor = "";
      if (borderColor === WHITE) {
        outline = `solid 1px ${theme.palette.neutralLight}`;
        backgroundColor = theme.palette.neutralLight;
      }

      return {
        root: {
          width: 20,
          height: 20,
          borderWidth: 4,
          borderStyle: "solid",
          borderColor,
          outline,
          backgroundColor
        }
      };
    },
    selectedFill: (backgroundColor: string, theme: Theme) => {
      let outline = "";

      if (backgroundColor === WHITE) {
        outline = `solid 1px ${theme.palette.neutralLight}`;
      }

      return {
        root: {
          width: 20,
          height: 20,
          backgroundColor,
          outline
        }
      };
    },
    contentMenuTitle: {
      root: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: 20,
        marginLeft: 4,
        marginTop: 13
      } as IStyle
    },
    blackIcon: {
      root: {
        color: "black"
      }
    },
    clearButton: {
      root: {
        border: 0,
        padding: 0,
        margin: 0,
        minWidth: 0,
        width: 44
      } as IStyle
    },
    slider: { root: { width: 139, padding: 4 } },
    spinButton: {
      spinButtonWrapper: { maxWidth: 64, minWidth: 64 },
      root: { maxWidth: 64, minWidth: 64, padding: 4 }
    },
    sliderSpinWrapper: { root: { minWidth: 234 } },
    wrapper: {
      root: {
        paddingBottom: 8,
        justifyContent: "space-between",
        alignItems: "flex-start"
      }
    }
  };
};
