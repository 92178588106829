import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import {
  IColumn,
  NoDataTile,
  ScrollablePane,
  SelectionMode,
  TooltipHost
} from "@bps/fluent-ui";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { usePatientMatchContext } from "../context/PatientMatchContext.ts";
import {
  PatientMatchListItem,
  PatientMatchPivotKey
} from "./PatientMatchListItem.types.ts";
import { PatientMatchNameColumn } from "./PatientMatchNameColumn.tsx";

interface MatchedResultListProps {
  onChangeToSearch: (key: PatientMatchPivotKey) => void;
}
export const MatchedResultList: React.FC<MatchedResultListProps> = observer(
  ({ onChangeToSearch }) => {
    const patientLabel = usePatientLabel(false);

    const renderName = (patientMatchListItem: PatientMatchListItem) => {
      return <PatientMatchNameColumn contact={patientMatchListItem.contact} />;
    };

    const { selection, getMatchedResult } = usePatientMatchContext();

    const search = useCallback(
      async (query: PagingOptions) => {
        const matchedContacts = await getMatchedResult(query);

        return matchedContacts;
      },
      [getMatchedResult]
    );

    const columns: IColumn[] = [
      {
        name: "Name",
        key: nameof<PatientMatchListItem>("name"),
        onRender: renderName,
        minWidth: 80,
        maxWidth: 150
      },
      {
        name: "Date of birth",
        fieldName: nameof<PatientMatchListItem>("birthDate"),
        key: nameof<PatientMatchListItem>("birthDate"),
        minWidth: 80,
        maxWidth: 100
      },
      {
        name: "Preferred phone",
        fieldName: nameof<PatientMatchListItem>("preferredPhone"),
        key: nameof<PatientMatchListItem>("preferredPhone"),
        minWidth: 130,
        maxWidth: 130
      },
      {
        name: "Email",
        fieldName: nameof<PatientMatchListItem>("email"),
        key: nameof<PatientMatchListItem>("email"),
        minWidth: 80,
        maxWidth: 130
      },
      {
        name: "Address",
        fieldName: nameof<PatientMatchListItem>("address"),
        key: nameof<PatientMatchListItem>("address"),
        minWidth: 80,
        maxWidth: 250,
        onRender: (item: PatientMatchListItem) => {
          return (
            <TooltipHost content={item.address}>{item.address}</TooltipHost>
          );
        }
      }
    ];

    return (
      <div
        style={{ position: "relative", minHeight: 200 }}
        id="task-list-table"
      >
        <ScrollablePane styles={{ root: { overflow: "hidden" } }}>
          <InfiniteScrollList<PatientMatchListItem>
            getItems={search}
            selectionMode={SelectionMode.single}
            selection={selection}
            columns={columns}
            setKey="matched-contacts-list"
            onRenderNoResults={() => (
              <NoDataTile
                textProps={{ text: `No ${patientLabel} found` }}
                linkProps={{
                  hidden: false,
                  text: `Search for a ${patientLabel}`,
                  onClick: () => {
                    onChangeToSearch(PatientMatchPivotKey.SearchResult);
                  }
                }}
              />
            )}
          />
        </ScrollablePane>
      </div>
    );
  }
);
