import {
  EmploymentType,
  RelationshipDto,
  RelationshipMetadataDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";

export class EmployerValidator extends Validator<RelationshipDto> {
  constructor() {
    super();
    const employerMetadataValidator = new EmployerMetadataValidator();
    this.forField("metadata", employerMetadataValidator.validate);
  }
}

class EmployerMetadataValidator extends Validator<RelationshipMetadataDto> {
  constructor() {
    super();

    const requireOtherInformation: FieldValidator = (
      otherInformation: string,
      metadata: RelationshipMetadataDto
    ) => {
      if (
        metadata?.employmentType === EmploymentType.Other &&
        !otherInformation
      ) {
        return "Other information is required.";
      }

      return undefined;
    };

    this.forField("otherInformation", requireOtherInformation);
  }
}
