import { useState } from "react";

import { Link, NoDataTile, Stack, Text } from "@bps/fluent-ui";
import { ReactionClinicalDataDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { ReactionFormDialog } from "./ReactionFormDialog.tsx";

interface IRenderReactionEmptyStateProps {
  clinicalRecord: ClinicalRecord;
}

export const RenderReactionEmptyState: React.FunctionComponent<
  IRenderReactionEmptyStateProps
> = ({ clinicalRecord }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const onAddReaction = () => {
    setIsDialogVisible(true);
  };

  const onReactionDialogDismiss = () => {
    setIsDialogVisible(false);
  };

  const onRecordNilReactions = async () => {
    const reaction: ReactionClinicalDataDto = {
      eTag: clinicalRecord.clinicalData?.reaction?.eTag,
      nilKnown: true,
      reactions: [],
      agents: []
    };

    await clinicalRecord.saveClinicalData({
      reaction
    });
  };

  return (
    <>
      <NoDataTile
        textProps={{ text: "No reactions recorded" }}
        linkProps={{
          onRender: () => (
            <Stack horizontalAlign="center" tokens={{ childrenGap: 12 }}>
              <Link onClick={onAddReaction}>Add a reaction</Link>
              <Text
                styles={{
                  root: {
                    fontStyle: "italic"
                  }
                }}
              >
                or
              </Text>
              <Link onClick={onRecordNilReactions}>
                Record 'Nil known' reactions
              </Link>
            </Stack>
          )
        }}
        showBoxShadow={false}
        greyView={true}
        styles={{
          root: {
            paddingTop: 30,
            paddingBottom: 30
          }
        }}
      />
      {isDialogVisible && (
        <ReactionFormDialog
          clinicalRecord={clinicalRecord}
          onDismiss={onReactionDialogDismiss}
          reaction={undefined}
        />
      )}
    </>
  );
};
