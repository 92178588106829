import { createContext, useContext } from "react";

import { TemplatePickerFormModel } from "./TemplatePickerFormModel.ts";

export const TemplatePickerFormContext = createContext<TemplatePickerFormModel>(
  {} as TemplatePickerFormModel
);

export const useTemplatePickerFormContext = () =>
  useContext(TemplatePickerFormContext);
