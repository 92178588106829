import { FC } from "react";

import { MessageBar, MessageBarType, Text } from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";

type UnallocatedAmountWarningProps = {
  allocated: number;
  unallocated: number;
  noWrapper?: boolean;
};

export const UnallocatedAmountWarning: FC<UnallocatedAmountWarningProps> = ({
  allocated,
  unallocated,
  noWrapper
}) => {
  const warningText = (
    <Text block>
      {`Allocated amount is ${currencyFormat(allocated)} — `}
      <strong>{currencyFormat(unallocated)}</strong>
      &nbsp; will be <strong>credited</strong>
    </Text>
  );

  return noWrapper ? (
    warningText
  ) : (
    <MessageBar
      messageBarType={MessageBarType.warning}
      styles={{ root: { marginTop: 8 } }}
    >
      {warningText}
    </MessageBar>
  );
};
