import {
  integer,
  maxLength,
  minLength,
  predicate,
  required,
  requiredCharactersLength
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { InvoiceSettingsFormValues } from "../InvoiceSettingsForm.types.tsx";

export class InvoiceSettingsNzBankDetailsValidator extends Validator<
  InvoiceSettingsFormValues["bankDetails"]
> {
  constructor() {
    super();

    const partiallyCompleted = (
      value: string,
      values: InvoiceSettingsFormValues["bankDetails"]
    ) => {
      return (
        !!values.accountName ||
        !!values.accountNumber ||
        !!values.accountNumber2 ||
        !!values.accountNumber3 ||
        !!values.accountNumber4
      );
    };

    this.forField("accountName", [
      maxLength(100),
      predicate(partiallyCompleted, required())
    ]);
    this.forField("accountNumber", [
      requiredCharactersLength(2),
      integer(),
      predicate(partiallyCompleted, required())
    ]);
    this.forField("accountNumber2", [
      requiredCharactersLength(4),
      integer(),
      predicate(partiallyCompleted, required())
    ]);
    this.forField("accountNumber3", [
      requiredCharactersLength(7),
      integer(),
      predicate(partiallyCompleted, required())
    ]);
    this.forField("accountNumber4", [
      maxLength(3),
      minLength(2),
      integer(),
      predicate(partiallyCompleted, required())
    ]);
  }
}
