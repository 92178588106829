import React from "react";

import { Stack } from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { ContactPickerFieldWithAdd } from "../../../shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { BillToAccountRelationshipMessageBar } from "./BillToAccountRelationshipMessageBar.tsx";
import { patientFormNameOf } from "./PatientEditFormValues.tsx";
import { RelationshipFieldValue } from "./relationships/utils.ts";

interface EditAccountBillToRowProps {
  index: number;
  removeAccountHolder: (index: Number) => void;
  addAccountHolder: (relatedContactId?: string) => void;
  primaryFieldChanged: (index: Number) => void;
  accountHolders: RelationshipFieldValue[];
}

export const EditAccountBillToRow: React.FC<EditAccountBillToRowProps> = ({
  index,
  removeAccountHolder,
  addAccountHolder,
  primaryFieldChanged,
  accountHolders
}) => {
  const primaryFieldName = `${patientFormNameOf(
    "accountHolders"
  )}[${index}].metadata.isPrimary`;

  const accountHolder = accountHolders[index];

  return (
    <Stack
      key={accountHolder.id}
      styles={(_props, theme) => ({
        root: {
          display: "flex",
          flexGrow: 1,
          flexBasis: 1,
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          alignContent: "center",
          padding: 8,
          border: `1px solid ${theme.palette.neutralLight}`,
          gap: 8,
          marginBottom:
            accountHolders.length && index === accountHolders?.length - 1
              ? 0
              : 8
        }
      })}
    >
      <Stack.Item styles={{ root: { width: "100%" } }}>
        <Stack
          horizontal
          styles={{ root: { width: "100%", flexGrow: 1 } }}
          tokens={{ childrenGap: 8 }}
        >
          <ContactPickerFieldWithAdd
            label={Labels.billTo}
            actionButton={{
              linkProps: {
                children: "Remove",
                onClick: () => removeAccountHolder(index)
              }
            }}
            name={`accountHolders[${index}].relatedContactId`}
            parse={value => value ?? ""}
            quickAddProps={{
              addButtonLabel: Labels.newIndividual,
              detailedView: true
            }}
            filter={{
              types: [ContactType.Patient, ContactType.Individual]
            }}
            wrapperStyles={{ root: { flexGrow: 1 } }}
          />

          <ToggleField
            alignWithInputs
            id={`toggle-primary-account-${index}`}
            name={primaryFieldName}
            label={Labels.primary}
            styles={{
              root: { marginBottom: 0, marginTop: 0, minWidth: 54 },

              label: {
                visibility: index === 0 ? "visible" : "hidden"
              }
            }}
          />
          <FieldSpy
            name={primaryFieldName}
            onChange={() => primaryFieldChanged(index)}
          />
        </Stack>
      </Stack.Item>
      {!!accountHolders.length && index === accountHolders.length - 1 && (
        <BillToAccountRelationshipMessageBar
          addAccountHolder={addAccountHolder}
        />
      )}
    </Stack>
  );
};
