import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { dataAttribute, DataAttributes, Link, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { NoticeBadge } from "./NoticeBadge.tsx";

export const AppointmentNoticesItem: FunctionComponent = observer(() => {
  const {
    core,
    practice: {
      ui: { showContactDetails, showEditContact }
    }
  } = useStores();

  const { calendarEvent, appointmentNotices } = useContext(
    BookingCalendarEventContext
  );

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      grow
      verticalAlign="center"
    >
      <Link
        as="a" // to avoid console Warning: validateDOMNesting(...): <button> cannot appear as a descendant
        {...dataAttribute(
          DataAttributes.Element,
          "booking-event-context-contact-link"
        )}
        onClick={evt => {
          evt.preventDefault();
          if (core.hasPermissions(Permission.PatientWrite)) {
            return showEditContact(
              PatientCardIds.patientHeader,
              calendarEvent.contact!.id
            );
          }

          return showContactDetails(calendarEvent.contact!.id);
        }}
        styles={{
          root: {
            height: "100%",
            marginLeft: 4,
            marginRight: appointmentNotices.length ? 4 : 38
          }
        }}
      >
        {calendarEvent.contact ? calendarEvent.contact.preferredName : ""}
      </Link>

      <NoticeBadge
        notices={appointmentNotices}
        dataAtrributeValue="appt-notices-count"
      />
    </Stack>
  );
});
