import {
  getColumnClampLinesSelector,
  IDetailsRowStyles,
  IStyle,
  ITextProps,
  ITheme
} from "@bps/fluent-ui";

export const getDetailsRowStyles = (options: {
  theme: ITheme;
}): Partial<IDetailsRowStyles> => ({
  root: {
    cursor: "pointer",
    userSelect: "none",
    borderBottom: "none",
    selectors: {
      "&:focus": {
        backgroundColor: options.theme.palette.neutralQuaternaryAlt
      },
      "& .clampTwoLines": getColumnClampLinesSelector(2)
    }
  }
});

export const getOptionalTextStyle = (_props: ITextProps, theme: ITheme) => ({
  root: {
    fontSize: theme.fonts.large.fontSize,
    fontStyle: "italic",
    lineHeight: 25,
    color: theme.palette.neutralSecondary
  } as IStyle
});

export const tileStyles = {
  root: {
    padding: 15,
    boxShadow:
      "rgba(0, 0, 0, 0.133) 0px -1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px -0.3px 0.9px 0px"
  }
};
