import { DATE_FORMATS, DateTime } from "@bps/utils";
import {
  CalendarEventStatus,
  CalendarEventType,
  GetCalendarEventsDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { getAgeString } from "../../../shared-components/utils/contact.utils.ts";
import { AppointmentInfo, NextAndLastAppointmentsLabels } from "./constants.ts";

export async function getNextAndPastAppointments(
  patient: Contact,
  label: string,
  root: IRootStore
): Promise<AppointmentInfo | undefined> {
  const { booking } = root;
  const args: GetCalendarEventsDto = {
    attendees: [patient.id],
    statuses: [CalendarEventStatus.Confirmed]
  };
  if (label === NextAndLastAppointmentsLabels.Upcoming) {
    args.startTime = DateTime.now().minus({ days: 1 }).startOf("day").toISO();
  } else {
    args.endTime = DateTime.now().plus({ days: 1 }).startOf("day").toISO();
  }

  const calendarEvents = await booking.getCalendarEvents(args, {
    loadCalendarEventUsers: true,
    loadCalendarEventContacts: false,
    loadCalendarEventExtensions: false
  });

  const appointments = calendarEvents?.results?.filter(
    e => e.type === CalendarEventType.Appointment
  );

  if (appointments.length > 0) {
    const appointment =
      label === NextAndLastAppointmentsLabels.Upcoming
        ? appointments.find(e => e.endDateTime > DateTime.now())
        : appointments
            .slice()
            .reverse()
            .find(e => e.endDateTime < DateTime.now());
    if (appointment) {
      return {
        id: appointment.id,
        startTime: appointment.startDateTime.toFormat(
          DATE_FORMATS.LONG_DATE_TIME_FORMAT
        ),

        type: appointment.appointmentTypeId
          ? booking.appointmentTypesMap.get(appointment.appointmentTypeId)
              ?.name ?? ""
          : "",
        provider: appointment.user
      };
    }
  }
  return undefined;
}

export const ageDescription = (patient: Contact) => {
  return (
    patient.birthDate &&
    `${patient.birthDate.toDayDefaultFormat()} - ${getAgeString(
      patient.birthDate,
      patient.dateOfDeath
    )} old`
  );
};
