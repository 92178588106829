import {
  DocumentMetadataItem,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export function getSideOfBodyText(value: string) {
  let text =
    Object.keys(SideOfBody)[
      Object.values(SideOfBody).indexOf(value as SideOfBody)
    ];

  if (text) {
    text = text.toLocaleLowerCase();
  }

  return text;
}

export const getNewMetadata = (
  metadata: DocumentMetadataItem[],
  items: Record<string, string | undefined>
) => {
  const newMetadata = [...metadata];

  for (const [property, value] of Object.entries(items)) {
    const index = metadata.findIndex(m => m.key === property);
    if (value) {
      if (index !== -1) {
        newMetadata[index].value = value;
      } else {
        newMetadata.push({
          key: property,
          value
        });
      }
    } else if (index !== -1) {
      newMetadata.splice(index, 1);
    }
  }

  return newMetadata;
};
