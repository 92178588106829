import {
  AddAllocationItemDto,
  AddRefundItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";

export const getMessageBarText = (
  owingItems: Omit<AddAllocationItemDto, "creditId">[],
  paidItems: (Omit<AddRefundItemDto, "creditId" | "paymentMethod"> & {
    amount: number;
    invoiceItemId: string;
  })[]
) => {
  const owingItemsIds = owingItems.map(i => i.invoiceItemId);
  const partPaidItems = paidItems.filter(i =>
    owingItemsIds.includes(i.invoiceItemId)
  );

  const textResult = [];
  let owingItemLength = owingItems.length;

  if (partPaidItems.length > 0) {
    owingItemLength = owingItems.length - partPaidItems.length;
    textResult.push({
      key: "mixedItems",
      text: `${partPaidItems.length} item${
        partPaidItems.length > 1 ? "s" : ""
      } will have their remaining owing voided on the invoice`
    });
  }

  if (owingItemLength > 0)
    textResult.push({
      key: "owingItems",
      text: `${owingItemLength} unpaid item${
        owingItemLength > 1 ? "s" : ""
      } will be voided on the invoice`
    });
  return textResult;
};
