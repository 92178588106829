import { DATE_FORMATS } from "@bps/utils";
import { Statement } from "@stores/billing/models/Statement.ts";

export const DOC_DIALOG_TITLE = "Statement";

export function getStatementName(statement?: Statement) {
  if (!statement) {
    return DOC_DIALOG_TITLE;
  }

  const isMultipleMonths =
    statement.startDate.month !== statement.endDate.month ||
    statement.startDate.year !== statement.endDate.year;

  if (!isMultipleMonths) {
    return `${DOC_DIALOG_TITLE} - ${statement.startDate.toFormat(
      DATE_FORMATS.MONTH_TEXT_FORMAT
    )}`;
  }

  return DOC_DIALOG_TITLE;
}
