import { IStyle } from "@bps/fluent-ui";

export const getContainerStyles = (): IStyle => {
  return {
    containerType: "inline-size",
    "@container (width > 740px)": {
      ".med-and-sup-grid": {
        gridTemplateColumns: "3fr 5fr"
      }
    },
    "@container (width >= 500px) and (width < 740px)": {
      ".med-and-sup-grid": {
        gridTemplateColumns: "1fr 1fr"
      }
    },
    "@container (width < 500px)": {
      ".med-and-sup-grid": {
        gridTemplateColumns: "1fr"
      }
    },
    width: "100%"
  };
};
