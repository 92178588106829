import {
  Length,
  maxLength,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { DashFormValues } from "../DASHForm.types.ts";

const checkDisabilityQuestions = (
  _value: string,
  parent: DashFormValues["items"]
) => {
  const questionsAnswered: string[] = [];
  for (let i = 1; i < 12; i++) {
    const question = parent ? parent[`q${i}`] : undefined;
    if (question) {
      questionsAnswered.push(question);
    }
  }

  return questionsAnswered.length < 10;
};

const checkWorkQuestions = (
  _value: string,
  parent: DashFormValues["items"]
) => {
  const questionsAnswered: string[] = [];
  for (let i = 13; i < 17; i++) {
    const question = parent ? parent[`q${i}`] : undefined;
    if (question) {
      questionsAnswered.push(question);
    }
  }

  const textQuestion = parent ? parent["q12"] : undefined;
  if (textQuestion && questionsAnswered.length === 0) {
    return true;
  }

  return !(questionsAnswered.length === 0 || questionsAnswered.length === 4);
};

const checkSportQuestions = (
  _value: string,
  parent: DashFormValues["items"]
) => {
  const questionsAnswered: string[] = [];
  for (let i = 18; i < 22; i++) {
    const question = parent ? parent[`q${i}`] : undefined;
    if (question) {
      questionsAnswered.push(question);
    }
  }

  const textQuestion = parent ? parent["q17"] : undefined;
  if (textQuestion && questionsAnswered.length === 0) {
    return true;
  }

  return !(questionsAnswered.length === 0 || questionsAnswered.length === 4);
};

export class DASHFormValidator extends Validator<DashFormValues["items"]> {
  constructor() {
    super();
    this.forField("q12", maxLength(Length.short));
    this.forField("q17", maxLength(Length.short));

    this.forField("q1", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q2", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q3", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q4", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q5", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q6", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q7", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q8", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q9", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q10", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q11", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkDisabilityQuestions(_value, parent);
      }
    });
    this.forField("q12", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkWorkQuestions(_value, parent);
      }
    });
    this.forField("q13", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkWorkQuestions(_value, parent);
      }
    });
    this.forField("q14", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkWorkQuestions(_value, parent);
      }
    });
    this.forField("q15", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkWorkQuestions(_value, parent);
      }
    });
    this.forField("q16", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkWorkQuestions(_value, parent);
      }
    });
    this.forField("q17", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkSportQuestions(_value, parent);
      }
    });
    this.forField("q18", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkSportQuestions(_value, parent);
      }
    });
    this.forField("q19", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkSportQuestions(_value, parent);
      }
    });
    this.forField("q20", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkSportQuestions(_value, parent);
      }
    });
    this.forField("q21", required(), {
      when: (_value, parent: DashFormValues["items"]) => {
        return checkSportQuestions(_value, parent);
      }
    });
  }
}
