import { observer } from "mobx-react-lite";

import { CorrespondenceDirection } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";

import { InvestigationList, InvestigationType } from "./InvestigationList.tsx";
import { ReportListItemContextMenuItems } from "./ReportsListItemContextMenu.tsx";
import { ReportsListItem } from "./ReportsListItemTitle.tsx";

export const ReportsList = observer(() => {
  return (
    <InvestigationList
      onRenderContextMenu={(record: ClinicalDocument) => (
        <ReportListItemContextMenuItems record={record} />
      )}
      onRenderTitle={(record: ClinicalDocument) => (
        <ReportsListItem record={record} />
      )}
      correspondenceDirection={CorrespondenceDirection.In}
      investigationType={InvestigationType.Report}
    />
  );
});
