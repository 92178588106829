import {
  AlcoholClinicalDataDto,
  ClinicalDataType,
  IntakeTypesFormTitle
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { IntakeForm } from "../intake/IntakeForm.tsx";
import { IntakeFormValues } from "../intake/IntakeForm.types.ts";
import { getCombinedIntakes, getInitialValues } from "../intake/utils.ts";
import { AlcoholFormValidator } from "./validators/AlcoholFormValidator.tsx";

interface AlcoholFormProps {
  clinicalRecord: ClinicalRecord;
}

export const AlcoholForm: React.FC<AlcoholFormProps> = ({ clinicalRecord }) => {
  const { core } = useStores();

  const intakeFormValidator = new AlcoholFormValidator(
    {
      dateOfBirth: clinicalRecord.patient?.birthDate?.toJSDate()
    },
    core
  );

  const getClinicalData = (values: IntakeFormValues) => {
    const {
      deletedIntakes,
      currentIntakes,
      historicalIntakes,
      status,
      comment,
      intakeConfirmed
    } = values;

    const initialValues = getInitialValues({
      intakeConfirmed,
      intake: clinicalRecord.clinicalData?.alcohol,
      domain: ClinicalDataType.Alcohol,
      dateOfBirth: clinicalRecord.patient?.birthDate
    });

    const intakes = getCombinedIntakes({
      deletedIntakes,
      currentIntakes,
      historicalIntakes,
      initialCurrentIntakes: initialValues.currentIntakes
    });

    const eTag = clinicalRecord.clinicalData?.alcohol?.eTag;
    const confirmedETag = clinicalRecord.clinicalData?.alcoholConfirmed?.eTag;

    const alcohol: AlcoholClinicalDataDto = {
      eTag,
      intakes,
      status,
      comment
    };

    return {
      alcohol,
      alcoholConfirmed: {
        eTag: confirmedETag,
        confirmed: true
      }
    };
  };

  const submitData = async (values: IntakeFormValues) => {
    const clinicalData = getClinicalData(values);
    await clinicalRecord.saveClinicalData(clinicalData);
  };

  return (
    <IntakeForm
      formTitle={IntakeTypesFormTitle.AlcoholTitle}
      maxIntakes={2}
      onSubmit={submitData}
      getData={getClinicalData}
      validate={intakeFormValidator.validate}
      domain={ClinicalDataType.Alcohol}
      clinicalRecord={clinicalRecord}
    />
  );
};
