import { SchedulesList } from "./components/SchedulesList.tsx";
import { SchedulesScreenContextProvider } from "./context/CommsScheduleScreenContext.tsx";

const CommsSchedulesScreen: React.FC = () => {
  return (
    <SchedulesScreenContextProvider>
      <SchedulesList />
    </SchedulesScreenContextProvider>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default CommsSchedulesScreen;
