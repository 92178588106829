import { observer } from "mobx-react-lite";
import React from "react";

import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

export interface ClinicalImageFilter {
  searchText?: string;
}
type ClinicalImageFilterProps = Pick<
  FilterBarProps<ClinicalImageFilter>,
  "children"
>;

export const ClinicalImagesFilter: React.FC<ClinicalImageFilterProps> =
  observer(({ children }) => {
    return (
      <FilterBar<ClinicalImageFilter>
        items={[
          {
            type: "searchBox",
            name: "searchText",
            stickItem: true,
            props: {
              placeholder: "Search title",
              styles: { root: { flexGrow: 1 } }
            }
          }
        ]}
        children={children}
        presets={undefined}
        hideResetFilterButton
      />
    );
  });
