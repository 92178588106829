import React from "react";

import { Heading, Stack } from "@bps/fluent-ui";

import { UserPageHeadings } from "../labels.ts";
import { UserReservesForm } from "./components/UserReservesForm.tsx";

const UserReservesScreen: React.FC = () => {
  return (
    <Stack styles={{ root: { paddingTop: 26 } }} tokens={{ childrenGap: 24 }}>
      <Heading
        styles={{ root: { paddingBottom: 2 } }}
        variant="section-heading"
      >
        {UserPageHeadings.reserves}
      </Heading>

      <UserReservesForm />
    </Stack>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default UserReservesScreen;
