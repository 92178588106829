import React from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { When } from "@ui-components/withPerm.tsx";

import { useWaitingListScreenContext } from "../context/WaitingListScreenContext.tsx";

export const AddWaitingListItemButton = () => {
  const { setCloseDialog, setItemToEdit } = useWaitingListScreenContext();

  return (
    <When permission={Permission.CalendarEventWrite}>
      <DefaultButton
        text="Add to waiting list"
        iconProps={{ iconName: "Add" }}
        onClick={() => {
          setItemToEdit(undefined);
          setCloseDialog(false);
        }}
      />
    </When>
  );
};
