import { BoolObservation } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export function toBoolObservationFromString(
  observed: string | undefined
): BoolObservation | undefined {
  if (observed === undefined || observed === "") {
    return undefined;
  }
  return observed === "Y" ? { observed: true } : { observed: false };
}

export function toStringFromBoolObservation(
  observed: boolean | undefined
): string | undefined {
  if (typeof observed === "undefined") {
    return undefined;
  }
  return observed ? "Y" : "N";
}
