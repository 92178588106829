import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { ReactionSeverity } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { NatureOfReactionText } from "@ui-components/RefText.tsx";

import { AgentReaction } from "./ReactionForm.types.ts";

interface IRenderReactionTextProps {
  reaction: AgentReaction;
}

export const StyledReactionText: React.FunctionComponent<
  IRenderReactionTextProps
> = ({ reaction }) => {
  const { clinical } = useStores();
  const severity = reaction.severity;
  const theme = useTheme();
  let textColor;
  if (severity === ReactionSeverity.Severe) {
    textColor = theme.palette.redDark;
  }
  if (severity === ReactionSeverity.Moderate) {
    textColor = theme.palette.yellowDark;
  }
  if (severity === ReactionSeverity.Mild) {
    textColor = theme.palette.neutralSecondary;
  }

  const options = clinical.ref.natureOfReactions.keyTextValues;

  const getNatureOfReactionTextForKey = (key: string) => {
    const option = options.find(option => option.key === key);
    return option ? option.text : key;
  };

  const selectedNatureOfReaction = reaction.natureOfReaction;

  const text = getNatureOfReactionTextForKey(selectedNatureOfReaction);

  return (
    <Stack horizontal verticalAlign="center">
      {text ? (
        <NatureOfReactionText
          code={reaction.natureOfReaction}
          styles={{ root: { color: textColor } }}
        />
      ) : (
        <Text styles={{ root: { color: textColor } }}>
          {selectedNatureOfReaction}
        </Text>
      )}
    </Stack>
  );
};
