import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useForm } from "react-final-form";

import { ActionButton, Heading, ScrollablePane, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactType,
  OrganisationCategory,
  Sex
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ClaimPickerField } from "@modules/acc/screens/shared-components/claim-picker/ClaimPickerField.tsx";
import { VisibilityAndConfidentialityField } from "@modules/clinical/screens/document-writer/components/VisibilityAndConfidentialityField.tsx";
import { TerminologyTagPickerField } from "@modules/clinical/screens/patient-record/components/clinical-form/TerminologyTagPickerField.tsx";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";
import { When } from "@ui-components/withPerm.tsx";

import {
  clinicalDetailsHeading,
  eightGap,
  fieldWrapper,
  imagingRequestToggle,
  leftPane,
  rightPane,
  rightPaneContainer,
  rightPaneHeading,
  rightScrollablePane
} from "./ImagingRequest.styles.ts";
import { ImagingRequestContactCard } from "./ImagingRequestContactCard.tsx";
import {
  emptyModality,
  ImagingRequestFormValues,
  Labels,
  requestFormNameOf
} from "./ImagingRequestDialog.types.ts";
import { ImagingRequestModalityField } from "./ImagingRequestModalityField.tsx";
import { ImagingRequestUserCard } from "./ImagingRequestUserCard.tsx";

interface AddRequestDialogFieldsProps {
  clinicalRecord: ClinicalRecord;
}

export const ImagingRequestDialogFieldsBase: React.FC<AddRequestDialogFieldsProps> =
  observer(({ clinicalRecord }) => {
    const { core, practice } = useStores();
    const form = useForm<ImagingRequestFormValues>();
    const [imagingCompany, setImagingCompany] = useState<Contact | undefined>();
    const { fields: modalityFields } = useFieldArray(
      requestFormNameOf("modalities")
    );

    const onRenderClaimField = (claims: Claim[]) => {
      if (claims.length > 0) {
        const selectedClaim = claims[0];
        return onRenderClaimOption(selectedClaim);
      } else {
        return;
      }
    };

    const onRenderClaimOption = (claim: Claim) => {
      const diagnosisDescription =
        claim.primaryDiagnosis?.terminology?.text || "";

      return (
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 8 }}
          styles={{ root: { width: "100%" } }}
        >
          <Stack.Item>
            <span>{claim.claimNumber || "Not lodged"} </span>
            {diagnosisDescription && (
              <span>{` | ${diagnosisDescription}`}</span>
            )}
          </Stack.Item>
        </Stack>
      );
    };

    const patientId = clinicalRecord.patient?.id;

    const canCreateTasksOrActivities =
      core.hasPermissions(Permission.ClinTaskWrite) ||
      core.hasPermissions(Permission.ClinActivityWrite);

    return (
      <Stack horizontal styles={fieldWrapper}>
        <Stack tokens={eightGap} styles={leftPane}>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <DatePickerField
              name={requestFormNameOf("requestDate")}
              label={Labels.Date}
              minDate={DateTime.jsDateNow()}
            />
            <ToggleField
              label={Labels.Urgent}
              styles={imagingRequestToggle}
              name={requestFormNameOf("urgent")}
            />
          </Stack>
          <Stack>
            <ContactPickerField
              required
              label={Labels.To}
              name={requestFormNameOf("to")}
              iconName="Search"
              placeholder={Labels.To}
              filter={{
                types: [ContactType.Organisation],
                categories: [OrganisationCategory.ImagingProvider]
              }}
            />
          </Stack>
          <FieldSpy
            name={requestFormNameOf("to")}
            onChange={value => {
              if (value) {
                const contact = practice.contactsMap.get(
                  form.getState().values.to
                );
                setImagingCompany(contact);
              }
            }}
          />
          {imagingCompany && (
            <ImagingRequestContactCard
              contact={imagingCompany}
              displayAddress
              contactType="organisationHeader"
            />
          )}
          {clinicalRecord.patient && (
            <ImagingRequestContactCard
              contact={clinicalRecord.patient}
              displayDob
              displayMobile
              displayEmail
              displaySex
              contactType="patientHeader"
              heading={Labels.For}
            />
          )}

          <WhenCountry is={Country.NewZealand}>
            <When permission={Permission.ClaimRead}>
              <FieldSpy
                name={requestFormNameOf("isPrivateClaim")}
                subscription={{ initial: true }}
              >
                {state => (
                  <ClaimPickerField
                    name={requestFormNameOf("claimId")}
                    patientId={patientId}
                    label="Claim number"
                    onRenderOption={onRenderClaimOption}
                    onRenderField={onRenderClaimField}
                    isPrivateClaim={state.initial}
                  />
                )}
              </FieldSpy>
              <FieldSpy
                name={requestFormNameOf("claimId")}
                onChange={claimId => {
                  const condition = clinicalRecord.conditions.find(
                    c => c.claim?.id === claimId
                  );
                  form.change(
                    requestFormNameOf("episodeOfCareId"),
                    condition?.episodeOfCareId
                  );
                }}
              />
            </When>
          </WhenCountry>

          <ImagingRequestUserCard user={core.user} location={core.location} />
          <VisibilityAndConfidentialityField
            name={requestFormNameOf("visibility")}
          />

          {canCreateTasksOrActivities && (
            <Stack horizontal horizontalAlign="space-between">
              <Stack horizontal tokens={{ childrenGap: 24 }}>
                <ToggleField
                  label={Labels.CreateClinicalTask}
                  styles={imagingRequestToggle}
                  onText="Yes"
                  name={requestFormNameOf("createClinicalTask")}
                />
                <FieldCondition
                  when={requestFormNameOf("createClinicalTask")}
                  is={true}
                >
                  <DatePickerField
                    name={requestFormNameOf("clinicalTaskDueDate")}
                    label="Due on:"
                    minDate={DateTime.jsDateNow()}
                    required
                  />
                </FieldCondition>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack styles={rightPane}>
          <Stack styles={rightPaneContainer} grow={1}>
            <ScrollablePane styles={rightScrollablePane}>
              <Stack tokens={eightGap} styles={{ root: { marginRight: 24 } }}>
                <Stack horizontal horizontalAlign="space-between">
                  <Heading
                    variant="section-heading-light"
                    styles={rightPaneHeading}
                  >
                    Requested
                  </Heading>
                  <ActionButton
                    iconProps={{ iconName: "Add" }}
                    onClick={() => modalityFields.push(emptyModality)}
                  >
                    Add modality
                  </ActionButton>
                </Stack>
                {modalityFields.map((name, index) => {
                  return (
                    <ImagingRequestModalityField
                      key={name}
                      addRegion={() => modalityFields.push(emptyModality)}
                      deleteModality={() => modalityFields.remove(index)}
                      modalityIndex={index}
                      fieldName={name}
                    />
                  );
                })}
                <TextInputField
                  name={requestFormNameOf("additionalServiceInformation")}
                  label={Labels.AdditionalInfo}
                />
                <Stack tokens={eightGap}>
                  <Heading
                    variant="section-heading-light"
                    styles={clinicalDetailsHeading}
                  >
                    Clinical details
                  </Heading>
                  <TextInputField
                    name={requestFormNameOf("clinicalHistory")}
                    label={Labels.ClinicalHistory}
                  />
                  <TerminologyTagPickerField
                    label={Labels.ProvisionalDiag}
                    name={requestFormNameOf("provisionalDiagnosis")}
                    formItemFieldStyles={{ root: { backgroundColor: "white" } }}
                    clearOnBlur={true}
                  />
                  {clinicalRecord.patient?.sex === Sex.Female && (
                    <CheckboxField
                      name={requestFormNameOf("pregnant")}
                      label="Pregnant"
                      styles={{ label: { fontWeight: 600 } }}
                    />
                  )}
                  <TextInputField
                    name={requestFormNameOf("currentMedications")}
                    label={Labels.CurrentMeds}
                    multiline
                    styles={{ root: { marginBotton: 5 } }}
                  />
                </Stack>
              </Stack>
            </ScrollablePane>
          </Stack>
        </Stack>
      </Stack>
    );
  });

export const ImagingRequestDialogFields = withFetch(
  x => [x.inbox.ref.reportTypes.load(), x.inbox.ref.outcomes.load()],
  ImagingRequestDialogFieldsBase
);
