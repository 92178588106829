import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import {
  ActionButton,
  Card,
  ConfirmDialog,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  IButtonStyles,
  PersonaCoin,
  PersonaSize,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  Permission,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { UserCardHeadings } from "../labels.ts";
import { getUserStylesSet } from "../UserScreens.styles.tsx";
import { UserInfoForm } from "./UserInfoForm.tsx";

interface UserInformationCardProps {
  user: User;
}

enum Titles {
  deActivateUser = "Deactivate user",
  pendingSignUp = "Pending sign up",
  activateTheUser = "Activate user"
}

export const UserInformationCard: React.FC<UserInformationCardProps> = observer(
  ({ user }) => {
    const { core, notification } = useStores();
    const theme = useTheme();
    const {
      userInactiveStatusButton,
      toggleIsActivateButton,
      userActiveStatusButton,
      pendingSignUpStatusButton
    } = getUserStylesSet(theme);

    const [userStateDialogIsHidden, setUserStateDialogIsHidden] =
      useState(true);

    const [
      cannotInactivateUserDialogHidden,
      setCannotInactivateUserDialogHidden
    ] = useState(true);

    const coin = (
      <PersonaCoin imageInitials={user.initials} size={PersonaSize.size24} />
    );

    const toggleActivateUserStateDialog = async () => {
      const lastUserAdmin = await core.lastUserAdmin(user);

      if (!lastUserAdmin) setUserStateDialogIsHidden(prev => !prev);
      else setCannotInactivateUserDialogHidden(false);
    };

    const resendInviteToUser = async () => {
      try {
        await core.resendInvite(user?.id);
      } catch (error) {
        notification.error("Error occurred while sending user invite");
      }
      notification.success("A new email invititation has been sent");
    };

    let actionButtonStyles: IButtonStyles,
      actionButtonText: string,
      additionalButton: JSX.Element,
      confirmDialogTitle: string,
      onConfirmUserStateDialogAction: () => Promise<void>;

    if (user.status === UserStatus.Inactive) {
      actionButtonStyles = userInactiveStatusButton;
      actionButtonText = "Inactive user";
      confirmDialogTitle = Titles.activateTheUser;

      onConfirmUserStateDialogAction = async () => {
        await core.updateUser({
          id: user.id,
          status: UserStatus.Pending
        });

        notification.success(
          "User updated to pending status. An activation email will be sent shortly."
        );

        await toggleActivateUserStateDialog();
      };

      additionalButton = (
        <DefaultButton
          onClick={toggleActivateUserStateDialog}
          styles={toggleIsActivateButton}
          {...dataAttribute(DataAttributes.Element, "user-is-active-button")}
        >
          Activate
        </DefaultButton>
      );
    } else if (user.status === UserStatus.Active) {
      actionButtonStyles = userActiveStatusButton;
      actionButtonText = "Active user";
      confirmDialogTitle = Titles.deActivateUser;

      onConfirmUserStateDialogAction = async () => {
        await core.updateUser({
          id: user.id,
          status: UserStatus.Inactive
        });
        notification.success(`${user.name} is now inactive`);

        await toggleActivateUserStateDialog();
      };

      additionalButton = (
        <ActionButton
          onClick={toggleActivateUserStateDialog}
          styles={toggleIsActivateButton}
          {...dataAttribute(DataAttributes.Element, "user-is-inactive-button")}
        >
          Deactivate
        </ActionButton>
      );
    } else {
      actionButtonStyles = pendingSignUpStatusButton;
      actionButtonText = "Pending sign up";
      confirmDialogTitle = Titles.pendingSignUp;

      onConfirmUserStateDialogAction = async () => {
        try {
          await core.resendInvite(user?.id);
        } catch (error) {
          notification.error("Error occurred while sending user invite");
        }
        notification.success("A new email invitation has been sent");
      };

      additionalButton = (
        <DefaultButton
          onClick={resendInviteToUser}
          {...dataAttribute(DataAttributes.Element, "user-resend-button")}
        >
          Resend invite
        </DefaultButton>
      );
    }

    const userStatusButtons = (
      <Stack horizontal verticalAlign="center">
        <ActionButton
          disabled
          styles={actionButtonStyles}
          {...dataAttribute(DataAttributes.Element, "user-status-button")}
        >
          {actionButtonText}
        </ActionButton>

        <When permission={Permission.UserWrite}>{additionalButton}</When>
      </Stack>
    );

    return (
      <>
        <Card
          icon={coin}
          headingLevel="section-heading"
          heading={UserCardHeadings.personalInformation}
          button={userStatusButtons}
          styles={{
            subComponentStyles: {
              tile: {
                content: {
                  padding: 0
                }
              }
            }
          }}
        >
          <UserInfoForm user={user} />
        </Card>
        <ConfirmDialog
          {...dataAttribute(DataAttributes.Element, "activate-dialog")}
          minWidth={480}
          maxWidth={480}
          hidden={userStateDialogIsHidden}
          dialogContentProps={{
            showCloseButton: true,
            subText:
              user.status === UserStatus.Inactive
                ? "Are you sure you want to reactivate this user?"
                : "Are you sure? We recommend rescheduling future appointments before performing this action."
          }}
          title={confirmDialogTitle}
          confirmButtonProps={{
            text:
              user.status === UserStatus.Inactive
                ? Titles.activateTheUser
                : Titles.deActivateUser
          }}
          onConfirm={onConfirmUserStateDialogAction}
          onCancel={toggleActivateUserStateDialog}
        />

        <ConfirmDialog
          {...dataAttribute(DataAttributes.Element, "cannot-inactivate-dialog")}
          minWidth={480}
          maxWidth={600}
          dialogContentProps={{
            subText:
              "This is the last user with User Management Administrator security permissions. Another user must first be granted these permissions"
          }}
          hidden={cannotInactivateUserDialogHidden}
          title={confirmDialogTitle}
          cancelButtonProps={{
            text: "Ok, I understand"
          }}
          confirmButtonProps={{ styles: { root: { display: "none" } } }}
          onCancel={() => setCannotInactivateUserDialogHidden(true)}
          onConfirm={() => {}}
        />
      </>
    );
  }
);
