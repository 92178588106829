import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  Calendar,
  calendarStrings,
  dataAttribute,
  DataAttributes,
  DateRangeType,
  mergeStyles,
  Tile
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";

import { CalendarEventView } from "../types/CalendarEventView.types.ts";

const FIRST_DAY_OF_WEEK = 1;

const className = mergeStyles({
  selectors: {
    ".ms-DatePicker-wrap": {
      padding: "0!important",
      marginBottom: "0!important"
    },
    ".ms-DatePicker-holder": {
      minHeight: "unset!important",
      height: "unset!important"
    },
    ".ms-DatePicker-currentYear": {
      paddingLeft: "14px"
    },
    ".ms-DatePicker-day-button": {
      cursor: "pointer"
    }
  }
});

export const BookingCalendarPicker: FunctionComponent = observer(() => {
  const { today, dayOrWeekView, startDate, setStartDate } =
    useBookingCalendarScreenContext();

  const calendarPickerOnSelectDate = (date: Date) => {
    const dateTimeSystemTzDay = DateTime.fromJSDate(date);

    const dateToSet =
      dayOrWeekView === CalendarEventView.Day
        ? dateTimeSystemTzDay
        : dateTimeSystemTzDay.startOf("week");

    setStartDate(dateToSet);
  };

  return (
    <Tile>
      <Calendar
        {...dataAttribute(DataAttributes.Element, "date-calendar-picker")}
        today={today}
        firstDayOfWeek={FIRST_DAY_OF_WEEK}
        showMonthPickerAsOverlay
        dateRangeType={
          dayOrWeekView === CalendarEventView.Day
            ? DateRangeType.Day
            : DateRangeType.Week
        }
        showGoToToday={false}
        strings={calendarStrings}
        onSelectDate={calendarPickerOnSelectDate}
        value={startDate.toJSDate()}
        className={className}
      />
    </Tile>
  );
});
