import { IRawStyle } from "@bps/fluent-ui";

// this style is used on nested wrapper elements to allow a child element to
//  have the scrollbar.

export const encounterExistsWrapperStyles: IRawStyle = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflow: "auto"
};
