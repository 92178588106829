import {
  dataAttribute,
  DataAttributes,
  IDetailsRowProps,
  mergeStyleSets,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { Invoice } from "@stores/billing/models/Invoice.ts";

import { IInvoiceRow } from "./AccInvoiceTransactionsList.tsx";

interface InvoiceRowProps {
  invoice: Invoice;
  props: IDetailsRowProps;
  defaultRender: (props: IDetailsRowProps) => JSX.Element | null;
}

export const InvoiceRow: React.FC<InvoiceRowProps> = ({
  invoice,
  props,
  defaultRender
}) => {
  const theme = useTheme();
  const items: IInvoiceRow[] = invoice.items.map((invoiceItem, index) => ({
    id: invoiceItem.id,
    invoice,
    invoiceItem,
    isFirstItem: index === 0
  }));

  const baseRowStyles = {
    root: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`
    }
  };

  return (
    <Stack
      styles={mergeStyleSets(baseRowStyles, {
        root: {
          overflow: "hidden"
        }
      })}
    >
      <Stack
        styles={{
          root: {
            ".ms-DetailsRow": {
              transition: "background-color 0.3s"
            }
          }
        }}
      >
        {items.map((item: IInvoiceRow) => (
          <Stack
            key={item.id}
            styles={{
              root: {
                ".ms-DetailsRow": { borderBottom: "none" },
                ".ms-DetailsRow-check": item.isFirstItem
                  ? undefined
                  : { visibility: "hidden" }
              }
            }}
            {...dataAttribute(DataAttributes.Data, item.id || "")}
          >
            {defaultRender({
              ...props,
              item,
              styles: {
                root: {
                  transition: "background-color 0.3s",
                  "&.is-selected:hover": {
                    backgroundColor:
                      theme.semanticColors.listItemBackgroundChecked
                  }
                }
              }
            })}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
