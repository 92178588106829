import { TemplateFormContextProvider } from "@modules/settings/screens/comms-templates/components/template-form/context/TemplateFormContext.tsx";

import { AddTemplateForm } from "./components/template-form/AddTemplateForm.tsx";

const CommsAddTemplateScreen: React.FC = () => {
  return (
    <TemplateFormContextProvider>
      <AddTemplateForm />
    </TemplateFormContextProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default CommsAddTemplateScreen;
