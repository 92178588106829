import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  NoDataTile,
  Stack
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { PhysicalActivityFormDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  PhysicalActivity,
  PhysicalActivityFormLabels
} from "./PhysicalActivityFormEnum.ts";

interface PhysicalActivitiesListProps {
  clinicalRecord: ClinicalRecord;
  isSOTAPForm?: boolean;
}

const PhysicalActivitiesListBase: React.FunctionComponent<
  PhysicalActivitiesListProps
> = ({ clinicalRecord }) => {
  const { clinical } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
  const { fields: activities } =
    useFieldArray<PhysicalActivityFormDto>("activities");

  const isDischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();

  const addNewActivity = () => {
    activities.push({
      id: newGuid(),
      isDeleted: false,
      sessionsPerWeek: "",
      sessionLength: "",
      activity: ""
    });
  };

  const removeActivityRow = (index: number, item: PhysicalActivityFormDto) => {
    const initialActivities =
      clinicalRecord.clinicalData?.physicalActivity?.activities;

    const activityIsInitial = initialActivities?.some(x => x.id === item.id);
    if (activityIsInitial) {
      activities.update(index, {
        ...item,
        isDeleted: true
      });
    } else {
      activities.remove(index);
    }
  };

  const isNoActivities =
    !activities.value.length || activities.value.every(x => x.isDeleted);

  if (isNoActivities) {
    return (
      <NoDataTile
        textProps={{ text: "No physical activities" }}
        linkProps={{
          hidden: isViewOnlyOrDischarged || isDischargeInProgressOrCompleted,
          text: "Add a physical activity",
          onClick: () => addNewActivity()
        }}
        showBoxShadow={false}
        greyView
      />
    );
  }

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {activities.map((name, index) => {
        const activity = activities.value[index];
        if (activity.isDeleted) return null;

        return (
          <Stack horizontal key={name}>
            <Fieldset frame styles={{ root: { width: "100%" } }}>
              <StaticPickerField
                name={`${name}.activity`}
                label={PhysicalActivityFormLabels.name}
                fetchDataSource={() => clinical.ref.activities.keyNameValues}
                required
                inputProps={{
                  placeholder: PhysicalActivityFormLabels.namePlaceHolder
                }}
              />
              <FieldCondition
                when={`${name}.activity`}
                is={PhysicalActivity.other}
              >
                <TextInputField
                  name={`${name}.activityOtherText`}
                  required
                  maxLength={50}
                  styles={{ root: { marginTop: 8 } }}
                />
              </FieldCondition>
              <FieldSpy
                name={`${name}.activity`}
                onChange={value => {
                  if (value !== PhysicalActivity.other) {
                    activities.update(index, {
                      ...activity,
                      activityOtherText: undefined
                    });
                  }
                }}
              />

              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <DropdownField
                  name={`${name}.level`}
                  options={clinical.ref.levels.keyTextValues}
                  label="Level"
                  fieldItemStyles={{ root: { flexGrow: 1, minWidth: 140 } }}
                />
                <SpinNumberInputField
                  disabled={isDischargeInProgressOrCompleted}
                  label={PhysicalActivityFormLabels.sessionsPerWeek}
                  name={`${name}.sessionsPerWeek`}
                  min={1}
                  max={50}
                  step={1}
                  required
                  styles={{ input: { width: 0 } }}
                />
                <SpinNumberInputField
                  disabled={isDischargeInProgressOrCompleted}
                  label={PhysicalActivityFormLabels.sessionLength}
                  name={`${name}.sessionLength`}
                  min={1}
                  max={999}
                  step={1}
                  required
                  styles={{ input: { width: 0 } }}
                />
              </Stack>
            </Fieldset>
            {!isViewOnlyOrDischarged && !isDischargeInProgressOrCompleted && (
              <DeleteButton
                {...dataAttribute(
                  DataAttributes.Element,
                  "delete-activity-button"
                )}
                onClick={() => removeActivityRow(index, activity)}
              />
            )}
          </Stack>
        );
      })}
      {!isViewOnlyOrDischarged && !isDischargeInProgressOrCompleted && (
        <ActionButton
          iconProps={{ iconName: "Add" }}
          title="Add more"
          onClick={addNewActivity}
          styles={{ root: { width: "fit-content" } }}
          {...dataAttribute(DataAttributes.Element, "add-activity-button")}
        >
          Add another
        </ActionButton>
      )}
    </Stack>
  );
};

export const PhysicalActivitiesList = withFetch(
  x => [x.clinical.ref.activities.load(), x.clinical.ref.levels.load()],
  PhysicalActivitiesListBase
);
