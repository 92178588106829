import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import {
  FontSizes,
  IconButton,
  IContextualMenuItem,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { UserContext } from "@modules/settings/screens/users/components/context/UserContext.tsx";

interface UserListActionMenuProps {
  userId: string;
}

export const UserListActionMenu: FC<UserListActionMenuProps> = observer(
  ({ userId }: UserListActionMenuProps) => {
    const { palette, semanticColors } = useTheme();
    const helper = useContext(UserContext);

    const hasUserValidLicence = helper.hasActiveLicence(userId);

    const disabled =
      !hasUserValidLicence && !helper.core.licences.some(x => !x.userId);

    const menuItems: IContextualMenuItem[] = [
      {
        key: "Omni",
        text: "Omni Licences",
        iconProps: {
          iconName: "BpOmniLogoBG",
          styles: {
            root: { color: palette.themeDarker }
          }
        },
        subMenuProps: {
          items: [
            {
              key: "Allied",
              disabled,
              onRenderContent: () => (
                <>
                  <Text
                    styles={{
                      root: {
                        color: disabled
                          ? semanticColors.disabledText
                          : undefined
                      }
                    }}
                  >
                    {`${
                      hasUserValidLicence ? "Unassign" : "Assign"
                    } Allied licence`}
                  </Text>
                  <Text
                    styles={{
                      root: {
                        color: palette.neutralSecondary,
                        paddingLeft: 3,
                        fontSize: FontSizes.size10
                      }
                    }}
                  >
                    {`(${
                      helper.core.licences.filter(x => !x.userId).length
                    } available)`}
                  </Text>
                </>
              ),
              onClick: () => {
                helper.updateLicence(userId);
              }
            }
          ]
        }
      }
    ];

    return (
      <Stack horizontalAlign="center" verticalAlign="center">
        <TooltipHost content="More">
          <IconButton
            menuIconProps={{ iconName: "More" }}
            menuProps={{
              items: menuItems
            }}
          />
        </TooltipHost>
      </Stack>
    );
  }
);
