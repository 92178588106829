import { Stack, useTheme } from "@bps/fluent-ui";

/**
 * This is a booking calendar specific separator that creates a horizontal line with no padding that overlaps its parent's padding
 */
export const BookingEventCalloutSeparator = () => {
  const theme = useTheme();

  return (
    <Stack
      styles={{
        root: {
          margin: "0px -24px",
          borderTop: `1px solid ${theme.palette.neutralLight}`
        }
      }}
    />
  );
};
