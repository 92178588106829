import { observable, runInAction } from "mobx";

import { DateTime, to2dp } from "@bps/utils";
import {
  AddAllocationDto,
  AddPaymentDto,
  AddPaymentItemDto,
  BillingStatuses,
  ItemType,
  PaymentMethod,
  TransactionAccountContactDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { sum } from "@libs/utils/utils.ts";
import { mapContactToTransactionContact } from "@modules/billing/billing.utils.ts";
import { getAllocationFormValues } from "@modules/billing/screens/allocation/utils.ts";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import { AllocationOptions } from "@modules/billing/screens/shared-components/allocation-form/AllocationOptions.ts";
import {
  ACCOUNT_CREDIT_METHOD,
  AllocationFormValues,
  PaymentFormValues
} from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { PaymentOption } from "@modules/billing/screens/shared-components/allocation-form/components/payment-field/PaymentsField.tsx";
import { RowOptions } from "@modules/billing/screens/shared-components/TotalsDetails.tsx";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { Payment } from "@stores/billing/models/Payment.ts";
import {
  isCreditNote,
  isPayment
} from "@stores/billing/utils/transaction.utils.ts";

import { AllocationItemHelper } from "./AllocationItemHelper.tsx";

export class AllocationFormHelperBase {
  constructor(private _root: IRootStore) {}

  private getOutstandingInvoices = async (accountContactId: string) => {
    return this._root.billing.getOutstandingInvoices(accountContactId);
  };

  private getOutstandingCredits = async (accountContactId: string) => {
    const results = await this._root.billing.fetchTransactionsNew({
      take: 1000,
      itemTypes: [ItemType.CreditNote, ItemType.Payment],
      accountIds: [accountContactId],
      billingStatuses: [BillingStatuses.current]
    });

    const payments = results.results.filter(isPayment);

    const creditNotes = results.results.filter(isCreditNote);

    return { payments, creditNotes };
  };

  private getAddPaymentDto = (options: {
    payments: PaymentFormValues[];
    accountContactId: string;
    accountContact: TransactionAccountContactDto;
    paymentNumber: string;
    transactionDate: string;
    comment?: string;
    locationId: string;
  }) => {
    const {
      payments,
      accountContactId,
      accountContact,
      paymentNumber,
      transactionDate,
      comment,
      locationId
    } = options;

    const location = this._root.core.getLocationName(locationId) ?? "";

    const paymentItems: AddPaymentItemDto[] = payments.map(payment => ({
      itemType: ItemType.Payment,
      amount: payment.amount,
      locationId,
      paymentMethod: payment.method as PaymentMethod
    }));

    const addPaymentDto: AddPaymentDto = {
      accountId: accountContactId,
      location,
      accountContact,
      transactionDate,
      comment,
      itemType: ItemType.Payment,
      items: paymentItems,
      number: paymentNumber
    };

    return addPaymentDto;
  };

  @observable public resetting: boolean;

  public number: string;

  public outstandingPayments: Payment[] | undefined;
  //any credit notes the user has not fully allocated yet.
  public outstandingCreditNotes: CreditNote[] | undefined;
  public outstandingInvoices: Invoice[] | undefined;

  public get outstandingCreditNoteTotal() {
    return sum("amount", this.creditNoteOptions);
  }

  public get outstandingPaymentTotal() {
    return sum("amount", this.paymentOptions);
  }

  public get accountCreditTotal() {
    return to2dp(
      this.outstandingCreditNoteTotal + this.outstandingPaymentTotal
    );
  }

  public get creditNoteOptions(): RowOptions[] {
    if (!this.outstandingCreditNotes) return [];

    return this.outstandingCreditNotes.map(creditNote => ({
      id: creditNote.id,
      text: creditNote.number,
      amount: creditNote.unallocated
    }));
  }

  public get paymentOptions(): RowOptions[] {
    if (!this.outstandingPayments) return [];
    return this.outstandingPayments.map(payment => ({
      id: payment.id,
      text: payment.number,
      amount: payment.unallocated
    }));
  }
  public get accountCreditPaymentOption(): PaymentOption {
    return {
      text: `Account credit (${currencyFormat(this.accountCreditTotal)})`,
      key: ACCOUNT_CREDIT_METHOD,
      max: this.accountCreditTotal
    };
  }

  public getInvoiceAllocationFormValues = (
    invoice?: Invoice,
    checked?: boolean
  ) => {
    const { invoiceAllocations, outstandingAllocations } =
      getAllocationFormValues(invoice, this.outstandingInvoices, checked);
    return [...invoiceAllocations, ...outstandingAllocations];
  };

  public _getInitialValues = (
    accountContactId?: string,
    invoice?: Invoice
  ): AllocationFormValues => {
    const contactId = accountContactId;

    return {
      accountContactId: contactId,
      comment: "",
      allocations: this.getInvoiceAllocationFormValues(invoice),
      allocationOption: AllocationOptions.payAccount,
      locationId: invoice?.items.length
        ? invoice.items[0].locationId
        : this._root.core.locationId
    };
  };

  public getTransactionAccountContact = async (
    accountContactId: string
  ): Promise<TransactionAccountContactDto> => {
    const accountContact =
      await this._root.practice.getContact(accountContactId);
    return mapContactToTransactionContact(accountContact);
  };

  public getAddAllocationDto = (options: {
    values: AllocationFormValues;
    accountContact: TransactionAccountContactDto;
    allocationNumber: string;
    newPayment?: Payment;
    creditNotes?: CreditNote[];
    payments?: Payment[];
  }) => {
    const {
      values,
      accountContact,
      allocationNumber,
      newPayment,
      creditNotes,
      payments
    } = options;

    const addAllocationItemHelper = new AllocationItemHelper();

    const allocationItems = addAllocationItemHelper.getAddAllocationItemsDto({
      values,
      creditNotes: creditNotes || this.outstandingCreditNotes,
      payments: payments || this.outstandingPayments,
      newPayment,
      creditNotesTotal: this.outstandingCreditNoteTotal,
      paymentTotal: this.outstandingPaymentTotal
    });

    const location = this._root.core.getLocationName(values.locationId) ?? "";

    const addAllocationDto: AddAllocationDto = {
      accountId: values.accountContactId || "",
      location,
      accountContact,
      transactionDate:
        DateTime.fromJSDate(values.paymentDate)?.toISODate() ||
        DateTime.now().toISODate(),
      itemType: ItemType.Allocation,
      items: allocationItems,
      number: allocationNumber
    };

    return addAllocationDto;
  };

  public close = () => {
    closeInvoiceOrPaymentPage(this._root.routing, "replace");
  };

  public _reset = async (accountContactId?: string) => {
    runInAction(() => {
      this.resetting = true;
    });

    const [number, credits, invoices] = await Promise.all([
      !this.number ? this._root.billing.generateAllocationNumber() : undefined,
      accountContactId
        ? this.getOutstandingCredits(accountContactId)
        : undefined,
      accountContactId
        ? this.getOutstandingInvoices(accountContactId)
        : undefined
    ]);

    runInAction(() => {
      //set payment number
      if (!this.number && number) {
        this.number = number;
      }

      this.outstandingCreditNotes = credits?.creditNotes;
      this.outstandingPayments = credits?.payments;
      this.outstandingInvoices = invoices;
    });

    runInAction(() => {
      this.resetting = false;
    });
  };

  public _onSubmit = async (
    values: AllocationFormValues,
    creditNotes?: CreditNote[] | undefined,
    payments?: Payment[] | undefined
  ) => {
    if (values.accountContactId) {
      const newPaymentItems = values.payments?.filter(
        x => x.method !== ACCOUNT_CREDIT_METHOD
      );

      const [accountContact, paymentNumber] = await Promise.all([
        this.getTransactionAccountContact(values.accountContactId),
        !!newPaymentItems?.length
          ? this._root.billing.generatePaymentNewNumber()
          : undefined
      ]);

      let newPayment;

      if (!!newPaymentItems?.length && paymentNumber) {
        const addPaymentsDto = this.getAddPaymentDto({
          payments: newPaymentItems,
          accountContactId: values.accountContactId,
          accountContact,
          paymentNumber,
          transactionDate:
            DateTime.fromJSDate(values.paymentDate)?.toISODate() ||
            DateTime.now().toISODate(),
          comment: values.comment,
          locationId: values.locationId
        });

        newPayment = await this._root.billing.addPayment(addPaymentsDto);
      }
      if (values.allocations && values.allocations.length > 0) {
        const allocationDto = this.getAddAllocationDto({
          values,
          accountContact,
          allocationNumber: this.number,
          newPayment,
          payments,
          creditNotes
        });

        return await this._root.billing.addAllocation(allocationDto);
      }
    }
    return undefined;
  };

  public onSubmitSucceeded = async () => {
    closeInvoiceOrPaymentPage(this._root.routing);
  };
}
