import { FunctionComponent } from "react";

import { NoDataTile } from "@bps/fluent-ui";

interface PatientSummaryEmptyStateProps {
  displayText?: string;
}
export const PatientSummaryEmptyState: FunctionComponent<
  PatientSummaryEmptyStateProps
> = ({ displayText }) => {
  return (
    <NoDataTile
      textProps={{ text: displayText ?? "No information to display" }}
      linkProps={{ hidden: true }}
      showBoxShadow={false}
    />
  );
};
