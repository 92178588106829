import { DateTime } from "@bps/utils";
import { Frequency } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  greaterThan,
  integer,
  isBeforeTimeField,
  isDateBeforeField,
  lessThan,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";

import { ScheduleFields } from "./ScheduleFields.types.ts";
import { UserWorkingHourFormValues } from "./UserWorkingHourForm.types.ts";

export class UserWorkingHoursFormValidator extends Validator<UserWorkingHourFormValues> {
  constructor(hasMultipleActiveLocations: boolean) {
    super();
    const userWorkingHoursFormValidator = new ScheduleFieldsValidator(
      hasMultipleActiveLocations
    );
    this.forArrayField(
      "userWorkingHours",
      userWorkingHoursFormValidator.validate
    );
  }
}

const isStartAndEndTimeNonEmpty = (val: string, values: ScheduleFields) =>
  !!(values.endTime && values.startTime);

export class ScheduleFieldsValidator extends Validator<ScheduleFields> {
  constructor(hasMultipleActiveLocations: boolean) {
    super();

    if (hasMultipleActiveLocations) this.forField("location", required());

    this.forField("endTime", [
      required(),
      predicate(
        isStartAndEndTimeNonEmpty,
        (val, values: ScheduleFields) =>
          values.startTime &&
          DateTime.fromParsedTimeString(val)! <
            DateTime.fromParsedTimeString(values.startTime)!
            ? "End time must not be before start time."
            : undefined,
        (val, values: ScheduleFields) =>
          val === values.startTime
            ? "End time must not be equal to start time."
            : undefined
      ),
      predicate(isStartAndEndTimeNonEmpty, val => {
        return !DateTime.fromParsedTimeString(val)
          ? "A valid time is required."
          : undefined;
      })
    ]);
    this.forField("interval", [
      predicate(x => x !== 0, required()), // treat zero as value entered
      integer(),
      greaterThan(0),
      lessThan(100)
    ]);
    this.forField("count", [
      predicate(
        (val: string, schedule: ScheduleFields) =>
          schedule.endScheduleType === EndScheduleType.After &&
          schedule.count !== 0,
        required()
      ),
      integer(),
      greaterThan(0),
      lessThan(100)
    ]);
    this.forField("startDate", [required()]);
    this.forField("until", [
      predicate(
        (val, schedule: ScheduleFields) =>
          schedule.endScheduleType === EndScheduleType.OnDate,
        required(),
        isDateBeforeField(
          ValidationMessages.endDateBeforeStartDate,
          "startDate"
        )
      )
    ]);
    this.forField("startTime", [
      predicate(
        (val: string, schedule: ScheduleFields) => schedule.atWork === "1",
        required()
      ),
      predicate(isStartAndEndTimeNonEmpty, val => {
        return !DateTime.fromParsedTimeString(val)
          ? "A valid time is required."
          : undefined;
      })
    ]);
    this.forField("endTime", [
      predicate(
        (val: string, schedule: ScheduleFields) => schedule.atWork === "1",
        required()
      )
    ]);
    this.forField("endTime", [
      predicate(
        (val: string, schedule: ScheduleFields) => schedule.atWork === "1",
        isBeforeTimeField(
          "End time must not be before start time.",
          "startTime"
        )
      )
    ]);
    this.forField("endTime", [
      predicate(
        (val: string, schedule: ScheduleFields) =>
          schedule.atWork === "1" &&
          (schedule.endTime && schedule.startTime
            ? schedule.endTime === schedule.startTime
            : false),
        () => "End time must not be equal to start time."
      )
    ]);
    this.forField("reasonForNotWorking", [
      predicate(
        (val: string, schedule: ScheduleFields) => schedule.atWork === "0",
        required(),
        maxLength(250)
      )
    ]);
    this.forField("frequency", required());
    this.forField("dayRecur", [
      predicate(
        (val, schedule: ScheduleFields) =>
          schedule.frequency === Frequency.Week,
        required(ValidationMessages.dayRecurrences)
      )
    ]);
    this.forField("monthYearRecurrence", [
      predicate(
        (val, schedule: ScheduleFields) =>
          schedule.frequency === Frequency.Month,
        required(ValidationMessages.monthRecurrences)
      )
    ]);
    this.forField("monthYearRecurrence", [
      predicate(
        (val, schedule: ScheduleFields) =>
          schedule.frequency === Frequency.Year,
        required(ValidationMessages.yearRecurrences)
      )
    ]);
  }
}
