import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import {
  ExtraCurrentItemValidator,
  ExtraHistoricalItemValidator,
  IntakeFormValidator
} from "../../intake/IntakeFormValidator.tsx";

interface SubstanceUseFormValidatorProps {
  dateOfBirth: Date | undefined;
}

export class SubstanceUseFormValidator extends IntakeFormValidator {
  constructor(options: SubstanceUseFormValidatorProps, core: CoreStore) {
    super(options, core);
    if (core.hasPermissions(Permission.CommonIntakeDetailAllowed)) {
      const substanceUseCurrentItemValidator =
        new SubstanceUseCurrentItemValidator(options);

      const substanceUseHistoricalItemValidator =
        new SubstanceUseHistoricalItemValidator(options);
      this.forArrayField(
        "historicalIntakes",
        substanceUseHistoricalItemValidator.validate
      );
      this.forArrayField(
        "currentIntakes",
        substanceUseCurrentItemValidator.validate
      );
    }
  }
}

export class SubstanceUseCurrentItemValidator extends ExtraCurrentItemValidator {
  constructor(options: SubstanceUseFormValidatorProps) {
    super(options);

    this.forField("unit", [required()]);
  }
}

export class SubstanceUseHistoricalItemValidator extends ExtraHistoricalItemValidator {
  constructor(options: SubstanceUseFormValidatorProps) {
    super(options);

    this.forField("unit", [required()]);
  }
}
