import { runInAction } from "mobx";

import { compareDatesPredicate, isDefined } from "@bps/utils";
import { AppointmentVisitDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  AccClinicalTab,
  EpisodeOfCareDto,
  PatchEncounterDto,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ProviderTypeCode } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export class ConditionsSidePanelHelper {
  constructor(
    private _clinicalRecord: ClinicalRecord,
    private _root: RootStore,
    private setTabs: () => Promise<void>
  ) {}

  private _encounterArray: Encounter[] = [];
  private _injuryTabId: string;

  private get core() {
    return this._root.core;
  }

  private get practice() {
    return this._root.practice;
  }

  private get acc() {
    return this._root.acc;
  }

  private get clinical() {
    return this._root.clinical;
  }

  private get booking() {
    return this._root.booking;
  }

  get clinicalRecord() {
    return this._clinicalRecord;
  }

  getCondition = (id: string | undefined) => {
    if (!id) return;
    return this.clinicalRecord.getConditionByEocId(id);
  };

  getList = (
    conditions: ICondition[],
    showOnHoldSection: boolean,
    clinicalRecordDischargeStatus?: string | undefined
  ) => {
    let conditionList = conditions;

    let linkedConditionModel: ConditionDetailsModel | undefined;

    if (this.hasLinkedCondition) {
      const linkedCondition = conditionList.find(x =>
        this.isLinkedCondition(x.episodeOfCareId)
      );

      if (linkedCondition) {
        linkedConditionModel = this.toConditionDetailsModel(linkedCondition);

        // Remove the linked from the conditions
        conditionList = conditionList.filter(
          x => !this.isLinkedCondition(x.episodeOfCareId)
        );
      }
    }

    const onHoldConditions = conditionList
      .filter(c => c.claim?.isOnHold)
      .sort((a, b) => compareDatesPredicate(a.createdDate, b.createdDate, true))
      .map(c => this.toConditionDetailsModel(c));

    const inProgressConditions = conditionList
      .filter(c => !c.discharged && !c.claim?.isOnHold)
      .sort((a, b) => compareDatesPredicate(a.createdDate, b.createdDate, true))
      .map(c => this.toConditionDetailsModel(c));

    const dischargedConditions = conditionList
      .filter(c => c.discharged)
      .sort((a, b) => compareDatesPredicate(a.createdDate, b.createdDate, true))
      .map(c => this.toConditionDetailsModel(c));

    // Add the linked item to the front of the list.
    if (linkedConditionModel) {
      if (
        showOnHoldSection &&
        linkedConditionModel.condition?.claim?.isOnHold
      ) {
        onHoldConditions.unshift(linkedConditionModel);
      } else {
        linkedConditionModel.condition?.discharged &&
        clinicalRecordDischargeStatus !== DischargeStatus.Reversed
          ? dischargedConditions.unshift(linkedConditionModel)
          : inProgressConditions.unshift(linkedConditionModel);
      }
    }

    if (inProgressConditions.length === 0) {
      inProgressConditions.push(this.emptyModel("No in progress conditions"));
    }

    if (dischargedConditions.length === 0) {
      dischargedConditions.push(this.emptyModel("No discharged conditions"));
    }

    if (onHoldConditions.length === 0) {
      onHoldConditions.push(this.emptyModel("No on hold conditions"));
    }

    const groups = [
      {
        key: "inProgress",
        name: "In progress",
        count: inProgressConditions.length,
        startIndex: 0
      },
      {
        key: "discharged",
        name: "Discharged",
        count: dischargedConditions.length,
        startIndex: inProgressConditions.length
      }
    ];

    if (showOnHoldSection) {
      groups.push({
        key: "onHold",
        name: "On hold",
        count: onHoldConditions.length,
        startIndex: dischargedConditions.length + inProgressConditions.length
      });
    }

    return {
      items: [
        ...inProgressConditions,
        ...dischargedConditions,
        ...(showOnHoldSection ? onHoldConditions : [])
      ],
      groups
    };
  };

  public toConditionDetailsModel(condition: ICondition): ConditionDetailsModel {
    return {
      condition,
      diagnosis: condition.primaryDiagnosis
        ? condition.primaryDiagnosis
        : ClaimFormLabels.undiagnosed,
      referralIn: condition.isReferral,
      apptRemaining: condition.claim?.totalRemainingAppointments
        ? `${condition.claim?.totalRemainingAppointments} consults remaining`
        : undefined,
      initialConsultDate: condition.initialConsultDate?.toDayDefaultFormat(),
      injuryDate: condition.injuryDate?.toDayDefaultFormat(),
      side: condition.diagnosisSide,
      appointmentVisits: condition.claim?.appointmentVisits
        ? condition.claim.appointmentVisits.filter(av =>
            isDefined(av.contractType)
          )
        : undefined
    };
  }

  loadConditionEncounters = async (episodeOfCareId: string | undefined) => {
    if (!episodeOfCareId) return [];

    const encounters =
      this._clinicalRecord.encounterResult?.filter(
        e => e.episodeOfCareId === episodeOfCareId
      ) ?? [];

    this._encounterArray = encounters;

    return encounters;
  };

  get conditionEncounters() {
    return this._encounterArray
      .filter(x => x.patientId === this.clinicalRecord.id)
      .sort((a, b): number => {
        return a.startDateTime < b.startDateTime ? 1 : -1;
      });
  }

  linkConditionToEncounter = async (
    episodeOfCareId: string,
    claimId?: string | undefined
  ) => {
    if (this.core.hasPermissions(Permission.ClaimWrite)) {
      await this.linkClaimEncounter(claimId);
    }

    await this.updateEncounterEpisodeOfCareId(episodeOfCareId);
    await this.clinicalRecord.updateReasonForVisitFromDiagnosis();
    await this.clinicalRecord.loadConditions();
    await this.clinicalRecord.loadlinkedEncounters();

    if (
      this.core.hasPermissions(
        [Permission.SOTAPInitialEncounter, Permission.SOTAPFollowOn],
        "or"
      )
    ) {
      await this.setTabs();
    }
  };

  linkClaimAppointment = async (claimId: string | undefined) => {
    if (!claimId) return;

    if (
      !this.clinicalRecord.openEncounter?.calendarEventId ||
      !this.clinicalRecord.openEncounter?.id
    )
      return;

    const claimAppt = await this.getClaimAppointment(claimId);

    if (!claimAppt && this.clinicalRecord.openEncounter?.calendarEventId)
      await this.acc.addClaimAppointment({
        claimId,
        calendarEventId: this.clinicalRecord.openEncounter?.calendarEventId
      });

    const claimEncounter = await this.getClaimEncounter(
      claimId,
      this.clinicalRecord.openEncounter?.id
    );
    if (!claimEncounter)
      await this.acc.addClaimEncounter({
        claimId,
        encounterId: this.clinicalRecord.openEncounter?.id
      });

    const claim = await this.clinicalRecord.calendarEvent?.loadClaim();
    await claim?.loadClaimAppointments();
  };

  linkClaimEncounter = async (claimId: string | undefined) => {
    if (!claimId) return;

    if (!this.clinicalRecord.openEncounter?.id) return;

    const claimEncounter = await this.getClaimEncounter(
      claimId,
      this.clinicalRecord.openEncounter?.id
    );
    if (!claimEncounter)
      await this.acc.addClaimEncounter({
        claimId,
        encounterId: this.clinicalRecord.openEncounter?.id
      });
  };

  unlinkConditionFromEncounter = async () => {
    if (!this.clinicalRecord.openEncounter?.id) return;

    // need to remove the diagnoses before we unlink the condition
    await this.clinicalRecord.removeConditionDiagnosisFromReasonForVisit();

    if (!!this.clinicalRecord.condition?.claim?.id) {
      await this.acc.deleteClaimEncounter(
        this.clinicalRecord.condition?.claim?.id,
        this.clinicalRecord.openEncounter.id
      );
    }

    // these two api calls need to finish before we reload everything
    await Promise.all([
      this.updateEncounterEpisodeOfCareId(null),
      this.clinical.discardEpisodeClinicalData(
        this.clinicalRecord.openEncounter.id
      )
    ]);

    await Promise.all([
      this.clinicalRecord.loadClinicalData(),
      this.clinicalRecord.loadConditions(),
      this.clinicalRecord.loadlinkedEncounters(),
      this.clinicalRecord.loadStructuredNotes(
        this.clinicalRecord.openEncounter.id,
        undefined,
        true
      )
    ]);

    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "injury",
      "physioBody",
      "patientTreatmentPlan"
    ]);

    if (
      this.core.hasPermissions(
        [Permission.SOTAPInitialEncounter, Permission.SOTAPFollowOn],
        "or"
      )
    ) {
      await this.setTabs();
    }
  };

  unlinkClaimAppt = async (claimId?: string) => {
    if (!claimId || !this.clinicalRecord.openEncounter?.id) return;

    const claimAppt = await this.getClaimAppointment(claimId);
    if (claimAppt) await this.acc.deleteClaimAppointmentDto(claimAppt.id);

    await this.acc.deleteClaimEncounter(
      claimId,
      this.clinicalRecord.openEncounter?.id
    );

    // Is injury tab opend?
    if (!this._injuryTabId) {
      const injuryTab =
        this.clinical.ui.tabs.currentPatientRecordTab?.allTabs.find(
          t => t.type === AccClinicalTab.Injury
        );
      if (injuryTab) {
        this._injuryTabId = injuryTab?.id;
      }
    }

    // Close Injury tab from the docker
    this.clinical.ui.tabs.currentPatientRecordTab?.removeTab(this._injuryTabId);

    // At this point:
    // has active tab - selected another tab rather than injury one
    // No active tab - the active injury tab has just been removed
    if (!this.clinical.ui.tabs.currentPatientRecordTab?.activeTab) {
      // There is a no active tab, So open Today's note
      this.clinical.ui.setPatientClinicalContent({
        type: PermanentClinicalTab.TodaysNotes
      });
    }
  };

  get hasLinkedCondition(): boolean {
    return !!this.clinicalRecord.openEncounter?.episodeOfCareId;
  }

  getClaimAppointment = async (claimId: string) => {
    const claimAppts = await this.acc.getClaimAppointmentDtos({
      claimIds: [claimId],
      calendarEventId: this.clinicalRecord.openEncounter?.calendarEventId
    });

    return claimAppts?.length > 0 ? claimAppts[0] : undefined;
  };

  getClaimEncounter = async (claimId: string, encounterId: string) => {
    const claimEncounters = await this.acc.getClaimEncounters(
      claimId,
      this.clinicalRecord.id
    );

    // Claims can have multiple encounters linked to them so we want to
    // see if the current encounter is already linked
    return claimEncounters?.find(x => x.id === encounterId);
  };

  isLinkedCondition = (episodeOfCareId: string | undefined) => {
    return (
      this.clinicalRecord.openEncounter?.episodeOfCareId === episodeOfCareId ||
      this.clinicalRecord?.episodeOfCare?.id === episodeOfCareId
    );
  };

  isAlliedHealthService = (claim: Claim) =>
    claim.appointmentVisits?.some(
      x => x.contractType === ProviderTypeCode.alliedHealthService
    );

  private emptyModel(emptyMessage: string): ConditionDetailsModel {
    return {
      emptyMessage
    };
  }

  updateEncounterEpisodeOfCareId = async (episodeOfCareId: string | null) => {
    if (!this.clinicalRecord.openEncounter) return;

    let episodeOfCare: EpisodeOfCareDto | undefined;

    if (episodeOfCareId) {
      episodeOfCare = await this.clinical.getEpisodeOfCare(episodeOfCareId);
    } else {
      episodeOfCare = undefined;
    }

    runInAction(() => {
      this.clinicalRecord.episodeOfCare = episodeOfCare;
    });

    // Update the EpisodeOfCare ID of the current encounter
    await this.clinical.updateEncounter({
      id: this.clinicalRecord.openEncounter.id,
      episodeOfCareId,
      eTag: this.clinicalRecord.openEncounter.eTag
    } as PatchEncounterDto);
  };
}

export interface ConditionDetailsModel {
  condition?: ICondition;
  diagnosis?: string;
  referralIn?: boolean;
  apptRemaining?: string;
  emptyMessage?: string;
  initialConsultDate?: string;
  appointmentVisits?: AppointmentVisitDto[];
  side?: string;
  injuryDate?: string;
}
