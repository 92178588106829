import React from "react";
import { useForm } from "react-final-form";

import { ActionButton, Grid, Heading, Stack } from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  SignificantConditionDataItemDto,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { Length } from "@libs/validation/fieldValidators.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { TerminologyPickerField } from "../../clinical-form/TerminologyPickerField.tsx";

interface ConditionAndCommentGroupFieldProps {
  conditionsName: string;
  resultsFilter?: (
    results: QueryResult<TerminologyDto>
  ) => QueryResult<TerminologyDto>;
}

export const ConditionAndCommentGroupField: React.FC<
  ConditionAndCommentGroupFieldProps
> = ({ conditionsName, resultsFilter }) => {
  const { fields: conditions } = useFieldArray<SignificantConditionDataItemDto>(
    conditionsName,
    {
      subscription: { value: true }
    }
  );

  const { mutators } = useForm();

  return (
    <Stack>
      {conditions.value.length > 0 && (
        <Grid templateColumns="350px auto 50px" childrenTokens={{ gap: 8 }}>
          <Heading hasAsterisk labelPaddings>
            Condition
          </Heading>
          <Heading
            labelPaddings
            styles={{ root: { gridColumnStart: "2", gridColumnEnd: "4" } }}
          >
            Comment
          </Heading>
          {conditions.map((name, index) => {
            const value = conditions.value[index];
            return (
              <React.Fragment key={value.id}>
                <TerminologyPickerField
                  isDiagnosis
                  isProcedure
                  required
                  name={`${name}.diagnosisKey`}
                  terminology={value.condition}
                  resultsFilter={resultsFilter}
                />

                <TextInputField
                  name={`${name}.comment`}
                  rows={1}
                  multiline
                  maxLength={Length.long}
                  resizable={false}
                  styles={{
                    root: {
                      width: "100%"
                    },
                    fieldGroup: { minHeight: 30 }
                  }}
                  autoAdjustHeight
                />
                <DeleteButton
                  onClick={() => {
                    mutators.remove(conditionsName, index);
                  }}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      )}

      <ActionButton
        iconProps={{ iconName: "Add" }}
        title="Add another"
        onClick={() => {
          mutators.push(conditionsName, {
            id: newGuid(),
            condition: ""
          });
        }}
        styles={{ root: { width: 135 } }}
      >
        Add condition
      </ActionButton>
    </Stack>
  );
};
