import React, { FunctionComponent } from "react";

import { CenteredBox, NoDataTile } from "@bps/fluent-ui";

import { AddWaitingListItemButton } from "./AddWaitingListItemButton.tsx";

export const NoDataTileWithAddWaitingListItem: FunctionComponent = () => {
  return (
    <CenteredBox>
      <NoDataTile
        greyView
        styles={{ root: { width: "100%", height: "100%" } }}
        showBoxShadow={false}
        textProps={{ text: "No entries for the waiting list" }}
        linkProps={{
          onRender: AddWaitingListItemButton
        }}
      />
    </CenteredBox>
  );
};
