import { observer } from "mobx-react-lite";

import { ConfirmOptions, Link, Stack, Text, TooltipHost } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { TimeRange } from "@stores/booking/models/TimeRanges.ts";
import { User } from "@stores/core/models/User.ts";

export interface Conflict {
  start: DateTime;
  end: DateTime;
  practiceHours?: TimeRange;
}

interface OutsidePracticeHoursWarningProps {
  user: User;
  conflicts: Conflict[];
}

export const OutsidePracticeHoursWarning: React.FC<OutsidePracticeHoursWarningProps> =
  observer(({ user, conflicts }) => {
    if (!conflicts.length) return null;

    const [{ start, end, practiceHours }] = conflicts;

    const startStr = start.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

    const endStr = end.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);
    const dateStr = start.toDayDefaultFormat();

    const pracStartStr = practiceHours?.from.toFormat(
      TIME_FORMATS.DEFAULT_TIME_FORMAT
    );

    const pracEndStr = practiceHours?.to.toFormat(
      TIME_FORMATS.DEFAULT_TIME_FORMAT
    );

    const practiceHoursStr =
      pracStartStr && pracEndStr ? ` (${pracStartStr} - ${pracEndStr})` : "";

    const otherConflictDates = conflicts
      .slice(1)
      .map(conflict => conflict.start.toDayDefaultFormat());

    return (
      <Stack tokens={{ childrenGap: 16 }} horizontalAlign="start">
        <Text>
          The hours on {dateStr} ({startStr} - {endStr}) for {user.fullName} are
          outside the set practice hours{practiceHoursStr}.
        </Text>
        {otherConflictDates.length > 0 && (
          <TooltipHost
            content={
              <Stack>
                {otherConflictDates.map(dayString => (
                  <Stack.Item key={dayString}>{dayString}</Stack.Item>
                ))}
              </Stack>
            }
          >
            <Link styles={{ root: { fontStyle: "italic" } }}>
              (+ {conflicts.length - 1} other conflicts)
            </Link>
          </TooltipHost>
        )}
        <Text>Set {user.fullName} as available for this time?</Text>
      </Stack>
    );
  });

export const getOutsideWarningHoursConfirmOptions = (
  conflicts: Conflict[],
  user: User
): ConfirmOptions => {
  return {
    title: "Provider working hours outside of practice hours",
    dialogContentProps: {},
    confirmButtonProps: {
      text: "Change hours"
    },
    children: <OutsidePracticeHoursWarning user={user} conflicts={conflicts} />
  };
};
