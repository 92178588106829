import { DateTime } from "@bps/utils";
import { PatchUserAvailabilityDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { User } from "@stores/core/models/User.ts";

import {
  AwayChoice,
  EditUserDayWorkingHoursData,
  EditUserDayWorkingHoursFormValues
} from "../components/EditUserDayWorkingHours.types.ts";
import { getReason } from "../utils.ts";

export class EditUserDayWorkingHoursModel {
  constructor(
    private booking: BookingStore,
    private user: User,
    private startDate: DateTime
  ) {
    this.baseWorkingHoursQuery = {
      from: this.initialDate,
      to: this.initialDate.plus({ days: 1 }),
      userIds: [this.user.id],
      orgUnitId: this.core.locationId
    };
  }

  get core() {
    return this.booking.core;
  }

  public get initialDate() {
    return this.startDate.isBeforeToday ? DateTime.today() : this.startDate;
  }

  private baseWorkingHoursQuery;

  public fetch = async (): Promise<EditUserDayWorkingHoursData> => {
    const workingHoursPromise = this.booking.getUsersWorkingHours({
      ...this.baseWorkingHoursQuery,
      isStandardHours: false
    });

    const availabilityPromise = this.booking.getUserAvailability(this.user.id);

    const orgUnitHoursPromise = this.booking.getOrgUnitWorkingHours({
      from: this.startDate,
      to: this.startDate.plus({ days: 1 }),
      orgUnitId: this.core.locationId
    });

    const [workingHours, availability, orgUnitHours] = await Promise.all([
      workingHoursPromise,
      availabilityPromise,
      orgUnitHoursPromise
    ]);
    return {
      user: this.user,
      workingHours: workingHours[this.user.id],
      availability,
      orgUnitHours
    };
  };

  fetchStandardHours = async (date: Date) => {
    const dateTime = DateTime.fromJSDate(date);

    const data = await this.booking.getUsersWorkingHours({
      ...this.baseWorkingHoursQuery,
      from: dateTime,
      to: dateTime.plus({ days: 1 }),
      isStandardHours: true,
      orgUnitId: this.core.locationId
    });

    const userTimeRange = data[this.user.id];

    if (userTimeRange) {
      const { startTime, endTime } = data[this.user.id].startEndTime(dateTime);

      return { stdWorkingHoursStart: startTime, stdWorkingHoursEnd: endTime };
    }

    return { stdWorkingHoursStart: undefined, stdWorkingHoursEnd: undefined };
  };

  public getInitialValues = (
    data: EditUserDayWorkingHoursData
  ): EditUserDayWorkingHoursFormValues => {
    const workingHours = data.workingHours;

    const reason = getReason(data.availability, this.initialDate);
    const date = this.initialDate.toJSDate();

    if (workingHours) {
      const startEndResult = workingHours?.startEndTime(this.initialDate);

      const { startTime: _startTime, endTime: _endTime } = startEndResult;

      const isAway = !_startTime && !_endTime;

      const startTime = _startTime?.toTimeInputFormat();

      const endTime = _endTime?.toTimeInputFormat();

      return {
        date,
        awayChoice: isAway ? AwayChoice.away : AwayChoice.present,
        startTime,
        endTime,
        reason
      };
    }

    return {
      date,
      awayChoice: AwayChoice.present,
      startTime: undefined,
      endTime: undefined,
      reason
    };
  };

  public handleSubmitForm = async (
    values: EditUserDayWorkingHoursFormValues
  ) => {
    if (!this.user || !values.date) {
      return;
    }

    const userAvailability = await this.booking.getUserAvailability(
      this.user.id
    );

    const isAvailable = values.awayChoice === "present";

    const date = DateTime.fromJSDate(values.date);

    // try to match an existing override before attempting to append a new one
    const existingOverride = userAvailability.dto.bookingScheduleOverrides.find(
      x =>
        date.toMillis() === DateTime.fromISO(x.startDate).toMillis() &&
        date.toMillis() === DateTime.fromISO(x.endDate).toMillis()
    );

    const bookingScheduleOverrides: PatchUserAvailabilityDto["bookingScheduleOverrides"] =
      [
        ...userAvailability.scheduleOverrides.filter(
          x => !existingOverride || x.id !== existingOverride.id
        ),
        {
          ...existingOverride,
          orgUnitId: this.booking.core.locationId,
          startDate: date.toISODate(),
          endDate: date.toISODate(),
          startTime: values.startTime,
          endTime: values.endTime,
          isAvailable,
          reason: values.reason
        }
      ];

    await this.booking.updateUserAvailability({
      userId: this.user.id,
      bookingScheduleOverrides
    });
  };
}
