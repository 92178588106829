import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { sum } from "@libs/utils/utils.ts";
import { AllocationItemsTotal } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import {
  LabelData,
  StatusLabel
} from "@modules/billing/screens/shared-components/StatusLabel.tsx";
import { maxZero } from "@stores/billing/utils/billing.utils.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { AllocationFieldValues } from "../types.ts";

export interface ManualAllocationStatusLabelProps {
  allocatabaleAmount: number;
}

export const ManualAllocationStatusLabel: React.FunctionComponent<
  ManualAllocationStatusLabelProps
> = ({ allocatabaleAmount }) => {
  const {
    fields: { value: allocations }
  } = useFieldArray<AllocationFieldValues>(allocationNameOf("allocations"), {
    subscription: { value: true }
  });

  const total = sum("total", allocations);

  const labelsData: LabelData[] = [
    {
      id: "status-label-payment",
      data: {
        label: AllocationItemsTotal.paymentPrefixed,
        value: currencyFormat(allocatabaleAmount, {
          currency: ""
        })
      }
    },
    {
      id: "status-label-allocated",
      data: {
        label: AllocationItemsTotal.allocatedPrefixed,
        value: currencyFormat(total, { currency: "" })
      }
    },
    {
      id: "status-label-balance",
      data: {
        label: AllocationItemsTotal.unallocatedPrefixed,
        value: currencyFormat(maxZero(allocatabaleAmount - total), {
          currency: ""
        })
      }
    }
  ];

  return <StatusLabel childrenGap={20} labelData={labelsData} />;
};
