import { useState } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  CollapsibleCard,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { PhysitrackButton } from "./physitrack/PhysitrackButton.tsx";
import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";
import { TreatmentFields } from "./TreatmentFields.tsx";

interface TreatmentProps {
  setIsFilledFromTreatments: (value: boolean) => void;
  hideFollowOnFields?: boolean;
}

export const TreatmentAndEducationCard: React.FC<TreatmentProps> = ({
  setIsFilledFromTreatments,
  hideFollowOnFields
}) => {
  const { clinical } = useStores();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const theme = useTheme();
  const { values, dirty } = useFormState<SOTAPFormValues>({
    subscription: { values: true, dirty: true }
  });

  const form = useForm<SOTAPFormValues>();

  const [showMessageBar, setShowMessageBar] = useState<boolean>(
    values.treatmentDate !== undefined && !values.treatmentsCleared
  );

  const [updateTreatmentCard, setUpdateTreatmentCard] = useState<boolean>(true);

  const clearCard = () => {
    setUpdateTreatmentCard(false);
    form.batch(() => {
      form.change(sotapNameOf("treatments"), []);
      form.change(sotapNameOf("treatmentsBase"), []);
      form.change(sotapNameOf("hasOtherTreatments"), false);
      form.change(sotapNameOf("otherTreatments"), undefined);
      form.change(sotapNameOf("educationOptions"), []);
      form.change(sotapNameOf("educationComment"), undefined);
      form.change(sotapNameOf("otherComment"), undefined);
      form.change(sotapNameOf("treatmentsCleared"), true);
    });
    setShowMessageBar(false);
    // need the fieldSpys to have executed before state changed
    setTimeout(() => {
      setUpdateTreatmentCard(true);
    }, 500);
  };

  const getPlanEduItems = (
    eduOptions?: string[] | undefined,
    previousValue?: string[] | undefined
  ) => {
    const planOptions = values.planEducationOptions;

    if (!planOptions) return eduOptions;

    const educationOptions = eduOptions ?? [];
    const previousEduOptions = previousValue ?? [];

    const planEduOptions: string[] = Array.from(planOptions);
    if (educationOptions.length < previousEduOptions.length) {
      previousEduOptions.forEach(option => {
        if (!educationOptions.includes(option)) {
          const itemIndex = planOptions.findIndex(x => x === option);
          if (itemIndex !== -1) planEduOptions.splice(itemIndex, 1);
        }
      });

      const filledTreatments = values.planTreatments?.filter(
        x => x.isTreatAndEdu
      );
      if (
        planEduOptions.length === 0 &&
        (!filledTreatments || filledTreatments.length === 0)
      ) {
        setIsFilledFromTreatments(false);
      }
      return planEduOptions;
    } else {
      educationOptions.forEach(option => {
        if (!planEduOptions.includes(option)) {
          planEduOptions.push(option);
        }
      });
      return planEduOptions;
    }
  };

  return (
    <Stack id={SotapMskId.TodaysTreatment}>
      <CollapsibleCard
        heading={SotapMskLabel.TodaysTreatment}
        headingLevel="section-heading"
        iconName="IssueTrackingMirrored"
        openOnRender
      >
        <Stack tokens={{ childrenGap: 16, maxWidth: 630 }}>
          {clinicalRecord.isFollowOnEncounter && (
            <>
              {showMessageBar && (
                <MessageBar
                  messageBarType={MessageBarType.success}
                  styles={{
                    root: {
                      width: "auto"
                    },
                    innerText: { width: "100%" }
                  }}
                >
                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={{ childrenGap: 24 }}
                    styles={{ root: { height: "100%" } }}
                  >
                    <Text>
                      {`Autofilled from plan on ${values.treatmentDate}`}
                    </Text>
                    <Link
                      onClick={() => {
                        clearCard();
                      }}
                      styles={{
                        root: { fontSize: 12 }
                      }}
                    >
                      Clear values
                    </Link>
                  </Stack>
                </MessageBar>
              )}
              {!hideFollowOnFields && (
                <Fieldset>
                  <ButtonsGroupSingleChoiceField
                    label="Analysis of current treatment"
                    name={sotapNameOf("analysisOfCurrentTreatment")}
                    options={clinical.ref.progressionTypes.keyTextValues}
                  />
                  <TextInputField
                    name={sotapNameOf("analysisOfCurrentTreatmentNote")}
                    multiline={true}
                    rows={3}
                    autoAdjustHeight
                    resizable={false}
                    styles={{ fieldGroup: { minHeight: 60 } }}
                  />
                </Fieldset>
              )}
            </>
          )}
          <TreatmentFields
            treatmentArrayName="treatments"
            treatmentBaseArrayName="treatmentsBase"
            setIsFilledFromTreatments={setIsFilledFromTreatments}
            updateTreatmentPlan
          />

          <Stack tokens={{ childrenGap: 8 }} wrap>
            <Stack
              styles={{
                root: {
                  border: `1px solid ${theme.palette.neutralLight}`,
                  padding: 8
                }
              }}
            >
              <CheckboxGroupField
                label="Education"
                name={sotapNameOf("educationOptions")}
                options={clinical.ref.educationOptions.keyLabelValues}
                horizontal
                wrap
                checkboxWidth={150}
                styles={{
                  itemsWrapper: {
                    display: "flex",
                    justifyContent: "space-between"
                  },
                  root: {
                    width: "600"
                  }
                }}
              />
              {values.educationOptions &&
                values.educationOptions.includes("OTH") && (
                  <TextInputField
                    name={sotapNameOf("otherComment")}
                    rows={1}
                    multiline
                    autoAdjustHeight
                    resizable={false}
                    styles={{
                      fieldGroup: { minHeight: 30 }
                    }}
                    placeholder="Other education provided"
                  />
                )}

              <TextInputField
                label="Education comments"
                name="educationComment"
                rows={1}
                multiline
                autoAdjustHeight
                resizable={false}
                styles={{
                  fieldGroup: { minHeight: 30 }
                }}
              />

              <FieldSpy
                name={sotapNameOf("educationComment")}
                onChange={value => {
                  if (updateTreatmentCard && dirty) {
                    setIsFilledFromTreatments(true);
                    form.change(sotapNameOf("planEducationComment"), value);
                  }
                }}
              />
            </Stack>
            <FieldSpy
              name={sotapNameOf("educationOptions")}
              onChange={(value, values, previousValue) => {
                if (updateTreatmentCard && dirty) {
                  setIsFilledFromTreatments(true);
                  const planEduOptions = getPlanEduItems(value, previousValue);
                  form.change(
                    sotapNameOf("planEducationOptions"),
                    planEduOptions
                  );
                }
                if (!value.includes("OTH")) {
                  form.batch(() => {
                    form.change(sotapNameOf("otherComment"), undefined);
                    if (updateTreatmentCard && dirty) {
                      form.change(
                        sotapNameOf("planOtherEducationComment"),
                        undefined
                      );
                    }
                  });
                }
              }}
            />
          </Stack>
          <PhysitrackButton />
        </Stack>
      </CollapsibleCard>
    </Stack>
  );
};
