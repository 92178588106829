import { ReactNode } from "react";
import { FormSpy } from "react-final-form";

import { IBreadcrumbItem } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { BreadCrumb } from "@ui-components/navigation/BreadCrumb.tsx";

import { AddDiagnosisFormSection } from "./AddDiagnosisFormSection.tsx";
import { ChangeDiagnosisFormSection } from "./ChangeDiagnosisFormSection.tsx";
import { ChangeSideFormSection } from "./ChangeSideFormSection.tsx";
import { ClaimAdjustmentOverviewSection } from "./claim-adjustment-overview-section/ClaimAdjustmentOverviewSection.tsx";
import { CurrentStatusAndPrognosisFormSection } from "./CurrentStatusAndPrognosisFormSection.tsx";
import { DocumentationFormSection } from "./DocumentationFormSection.tsx";
import { DocumentUploadStatusModal } from "./DocumentUploadStatusModal.tsx";
import { PatientDetailsFormSection } from "./PatientDetailsFormSection.tsx";
import { PatientProviderDeclarationFormSection } from "./PatientProviderDeclarationFormSection.tsx";
import { ProviderDetailsFormSection } from "./ProviderDetailsFormSection.tsx";
import { TreatmentDetailsFormSection } from "./TreatmentDetailsFormSection.tsx";
import { getClaimAdjustmentMenuItems } from "./utils.ts";

interface ClaimAdjustmentFormEditProps {
  header: ReactNode;
}

export const ClaimAdjustmentFormEdit: React.FC<
  ClaimAdjustmentFormEditProps
> = ({ header }) => {
  const { routing } = useStores();
  const context = useClaimAdjustmentContext();
  const {
    claimAdjustment,
    showCurrentStatusAndPrognosisSection,
    isNonStandardCodeSelected
  } = context;

  const patientLabel = usePatientLabel(true);

  const showCurrentStatusAndPrognosis = () => {
    return showCurrentStatusAndPrognosisSection(isNonStandardCodeSelected());
  };

  const breadcrumbs: IBreadcrumbItem[] = [
    {
      key: "claimManagement",
      text: "Summary",
      onClick: () => {
        const claimId = routes.claimAdjustment.edit.match(
          routing.location.pathname
        )?.params.claimId;
        routing.pushRetainingState(
          {
            pathname: routes.claims.management.edit.path({ id: claimId! })
          },
          { openACC32Card: true }
        );
      }
    },
    {
      key: "newACC32",
      text: claimAdjustment.formattedNumber,
      isCurrentItem: true
    }
  ];

  return (
    <CardFormLayout
      sideRailMenuItems={getClaimAdjustmentMenuItems(
        showCurrentStatusAndPrognosis(),
        patientLabel,
        claimAdjustment
      )}
      header={header}
    >
      <DocumentUploadStatusModal />
      <FormSpy<ClaimAdjustmentFormValues>
        subscription={{ dirty: true, values: true }}
        onChange={({ values }) => {
          context.claimAdjustmentFormValues = values;
        }}
      />
      <BreadCrumb routes={breadcrumbs} />
      <ClaimAdjustmentOverviewSection />
      <PatientDetailsFormSection />
      <ProviderDetailsFormSection />
      <TreatmentDetailsFormSection />
      <AddDiagnosisFormSection />
      <ChangeSideFormSection />
      <ChangeDiagnosisFormSection />
      <CurrentStatusAndPrognosisFormSection />
      <DocumentationFormSection />
      <PatientProviderDeclarationFormSection />
    </CardFormLayout>
  );
};
