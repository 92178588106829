import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import {
  AnimatedListWithKeys,
  mergeStyles,
  NativeList,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  ClaimAdjustmentDocumentStatuses,
  ClaimAdjustmentDocumentTypes
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { ClaimAdjustmentDocumentFormValue } from "@shared-types/acc/claim-adjustment-document-value.type.ts";
import { ClaimAdjustmentDocumentFormValues } from "@shared-types/acc/claim-adjustment-document.value.type.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { ErrorWrapper } from "@ui-components/form/ErrorWrapper.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { DocumentSectionFormValidator } from "../validators/DocumentSectionFormValidator.tsx";
import { AttachCorrespondenceSelectionModal } from "./AttachCorrespondenceSelectionModal.tsx";
import { ClaimAdjustmentCorrespondenceFileUploaderField } from "./ClaimAdjustmentCorrespondenceFileUploaderField.tsx";
import {
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";

const documentFields: (keyof ClaimAdjustmentDocumentFormValues)[] = [
  "documents",
  "uploadDocuments"
];

const documentSectionFormValidator = new DocumentSectionFormValidator();

const DocumentationFormSectionBase = observer(() => {
  const { acc, core } = useStores();

  const form = useForm<ClaimAdjustmentFormValues>();
  const claimAdjustmentContext = useClaimAdjustmentContext();

  const handleDocumentsAttached = (
    selectedDocuments: ClaimAdjustmentDocumentFormValue[]
  ) => {
    const documents = form.getState().values.documents;
    form.change(claimAdjustmentFormNameOf("documents"), [
      ...documents,
      ...selectedDocuments
    ]);

    form.getState();
  };

  return (
    <ClaimCard
      errorProps={{
        fields: documentFields,
        submitFailedOverride: claimAdjustmentContext.isDocumentError
      }}
      id={ClaimsAdjustmentCardIds.documentation}
      openOnRender={claimAdjustmentContext.isDocumentError}
      heading={ClaimAdjustmentSectionHeading.documentation}
      iconName="TextDocumentShared"
      statusValidator={documentSectionFormValidator}
      visible={!claimAdjustmentContext.claimAdjustment.verballyApproved}
    >
      <Stack tokens={{ childrenGap: 8 }} styles={{ root: { marginBottom: 8 } }}>
        <Text as="p">
          Please attach relevant clinical records, outcome measures and other
          medical information to support the application.
        </Text>
        <NativeList>
          <li>UPLOAD – 20MB limit per attachment (maximum is 6 files).</li>
          <li>Allow upload of up to 6 files.</li>
        </NativeList>
      </Stack>

      <When permission={Permission.ClaimWrite}>
        <AttachCorrespondenceSelectionModal
          onDocumentsAttached={handleDocumentsAttached}
        />

        <div className={mergeStyles({ marginBottom: 8 })}>
          <ClaimAdjustmentCorrespondenceFileUploaderField />
        </div>
      </When>
      <FieldArray name={claimAdjustmentFormNameOf("documents")}>
        {({ fields }) => {
          if (!fields.value.length) return null;
          return (
            <Stack tokens={{ childrenGap: 8 }}>
              <AnimatedListWithKeys
                items={fields.value}
                onItemIdRemoved={id => {
                  const index = fields.value.findIndex(v => v.id === id);
                  fields.remove(index);
                }}
              >
                {(id, onRemove) => {
                  const index = fields.value.findIndex(v => v.id === id);

                  const disabled =
                    !core.hasPermissions(Permission.ClaimWrite) ||
                    fields.value[index].status ===
                      ClaimAdjustmentDocumentStatuses.Lodged;

                  const fieldPrefix = `${claimAdjustmentFormNameOf(
                    "documents"
                  )}[${index}]`;

                  return (
                    <ErrorWrapper
                      name="document-upload"
                      fields={[
                        `${fieldPrefix}.fileName`,
                        `${fieldPrefix}.contentType`,
                        `${fieldPrefix}.assessmentType`,
                        `${fieldPrefix}.bodySite`,
                        `${fieldPrefix}.status`
                      ]}
                      errorMessage="Invalid fields remain in this section"
                      messageOverride={{
                        message: "ACC returned an error with this document.",
                        field: `${fieldPrefix}.status`,
                        submitFailedOverride: true
                      }}
                    >
                      <Fieldset
                        frame
                        styles={{
                          root: {
                            padding: "8px 0 8px 8px"
                          }
                        }}
                      >
                        <Stack horizontal>
                          <Stack grow tokens={{ childrenGap: 8 }}>
                            <Stack
                              horizontal
                              styles={{
                                root: {
                                  display: "grid",
                                  gap: 8,
                                  gridAutoFlow: "column",
                                  gridAutoColumns: "minmax(0, 1fr)"
                                }
                              }}
                            >
                              <TextInputField
                                name={`${fieldPrefix}.fileName`}
                                disabled={disabled}
                                placeholder="File name"
                                suffix={
                                  fields.value[index] &&
                                  fields.value[index].extension
                                    ? `.${fields.value[index].extension}`
                                    : undefined
                                }
                              />
                              <DropdownField
                                name={`${fieldPrefix}.contentType`}
                                disabled={disabled}
                                placeholder="Choose type ..."
                                dropdownWidth="auto"
                                options={
                                  acc.ref.documentContentTypes.keyTextValues
                                }
                              />
                            </Stack>
                            <FieldCondition
                              when={`${fieldPrefix}.contentType`}
                              is={ClaimAdjustmentDocumentTypes.RadiologyReports}
                            >
                              <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <TextInputField
                                  name={`${fieldPrefix}.assessmentType`}
                                  disabled={disabled}
                                  placeholder="Assessment type"
                                />
                                <TextInputField
                                  name={`${fieldPrefix}.bodySite`}
                                  disabled={disabled}
                                  placeholder="Body site of assessment"
                                />
                              </Stack>
                            </FieldCondition>
                          </Stack>
                          <DeleteButton
                            disabled={disabled}
                            styles={{
                              root: {
                                height: 30,
                                width: 30,
                                display: "flex",
                                justifyContent: "center"
                              }
                            }}
                            onClick={onRemove}
                          />
                        </Stack>
                      </Fieldset>
                    </ErrorWrapper>
                  );
                }}
              </AnimatedListWithKeys>
            </Stack>
          );
        }}
      </FieldArray>
    </ClaimCard>
  );
});

export const DocumentationFormSection = withFetch(
  x => [x.acc.ref.documentContentTypes.load()],
  DocumentationFormSectionBase
);
