import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useField, useForm } from "react-final-form";

import { Link } from "@bps/fluent-ui";
import {
  BusinessRoleClasses,
  Permission,
  SecurityRoleCode
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { UserContext } from "../context/UserContext.tsx";
import {
  SecurityPermissionsValue,
  SecurityRolesFormValue
} from "./UserSecurityRolesForm.types.ts";
import {
  getDefaultSecurityPermissions,
  getDefaultSecurityRoleCodes,
  getSecurityPermissionMatrix
} from "./utils.ts";

export interface ResetSecurityPermissionsButtonProps {
  user: User;
}

export const ResetSecurityPermissionsButton: React.FC<ResetSecurityPermissionsButtonProps> =
  observer(({ user }) => {
    const form = useForm();
    const { core } = useStores();
    const userHelper = useContext(UserContext);

    const {
      input: { value: businessRoles }
    } = useField<string[]>("businessRoles", { subscription: { value: true } });

    const {
      input: { value: securityPermissionsValue }
    } = useField<Partial<SecurityPermissionsValue>>(
      "securityPermissionsValue",
      {
        subscription: { value: true }
      }
    );

    const hasBhbConfigAllowedPermission = core.hasPermissions(
      Permission.BhbConfigAllowed
    );

    const licencingAllowed = core.hasPermissions(Permission.LicencingAllowed);

    const licensedWfContributor =
      userHelper.hasActiveLicence(user.id) &&
      core.catalogBusinessRoles.some(
        catalogBr =>
          businessRoles.some((br: string) => br === catalogBr.code) &&
          catalogBr.classCode === BusinessRoleClasses.Provider
      );

    const defaultSRCodes: SecurityRoleCode[] = getDefaultSecurityRoleCodes(
      core.catalogBusinessRoles,
      businessRoles || [],
      core.tenantDetails?.country
    );

    const securityPermissionsValueMatrix = getSecurityPermissionMatrix(
      defaultSRCodes,
      getDefaultSecurityPermissions(
        core.isNZTenant,
        hasBhbConfigAllowedPermission,
        licencingAllowed
      ),
      licensedWfContributor
    );

    const isFormChange = !isEqual(
      securityPermissionsValueMatrix,
      securityPermissionsValue
    );

    const onClick = () => {
      form.change(
        nameof<SecurityRolesFormValue>("securityPermissionsValue"),
        securityPermissionsValueMatrix
      );
    };

    return (
      <Link
        disabled={!isFormChange}
        styles={{ root: { ":hover": { "text-decoration": "none" } } }}
        onClick={onClick}
      >
        Reset security permissions
      </Link>
    );
  });
