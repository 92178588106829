import { observer } from "mobx-react-lite";

import { Heading, Separator, Stack, useTheme } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { InvoiceSettings } from "@stores/billing/models/InvoiceSettings.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { getUserStylesSet } from "../../../users/components/UserScreens.styles.tsx";
import { gridRowStyles } from "./InvoiceSettingsForm.styles.ts";
import {
  InvoiceSettingsFormValues,
  SettingsFormHeadings,
  SettingsFormLabels
} from "./InvoiceSettingsForm.types.tsx";
import { InvoiceSettingsFormAuBankAccountField } from "./InvoiceSettingsFormAuBankAccountField.tsx";
import { InvoiceSettingsFormNzBankAccountField } from "./InvoiceSettingsFormNzBankAccountField.tsx";
import { getFormValuesFromInvoiceSettings, parseGstNumber } from "./utils.ts";
import { InvoiceSettingsValidator } from "./validators/InvoiceSettingsValidator.ts";

export interface SettingsFormProps {
  settings: InvoiceSettings | undefined;
  goBack: () => void;
  onSubmitForm: (values: InvoiceSettingsFormValues) => Promise<void>;
  readOnly?: boolean;
}

export const InvoiceSettingsForm: React.FC<SettingsFormProps> = observer(
  ({ settings, onSubmitForm, readOnly }) => {
    const { core } = useStores();
    const nameOf = nameOfFactory<InvoiceSettingsFormValues>();
    const theme = useTheme();
    const { formFooter, formFields, settingsSeparator } =
      getUserStylesSet(theme);

    const country = core.tenantDetails?.country || Country.Australia;

    const financialFormValidator = new InvoiceSettingsValidator(country);

    const initialValues = getFormValuesFromInvoiceSettings(settings, country);

    return (
      <SubmissionForm<InvoiceSettingsFormValues>
        formName="invoice-settings"
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validate={financialFormValidator.validate}
        buttonsProps={{
          cancelButtonProps: {
            id: "cancel-invoice-settings"
          },
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
        readOnly={readOnly}
        autoFocus={false}
      >
        {() => (
          <Stack
            styles={{
              root: formFields
            }}
            tokens={{ childrenGap: 8 }}
          >
            <Stack tokens={{ childrenGap: 16, maxWidth: 628 }}>
              <Heading variant="section-heading-light">
                {SettingsFormHeadings.bankDetails}
              </Heading>
              <WhenCountry is={Country.Australia}>
                <InvoiceSettingsFormAuBankAccountField />
              </WhenCountry>
              <WhenCountry is={Country.NewZealand}>
                <InvoiceSettingsFormNzBankAccountField />
              </WhenCountry>
            </Stack>
            <WhenCountry is={Country.NewZealand}>
              <Separator styles={settingsSeparator} />
              <Stack tokens={{ childrenGap: 8 }}>
                <Heading variant="section-heading-light">
                  {SettingsFormHeadings.gstSettings}
                </Heading>

                <Stack styles={gridRowStyles}>
                  <TextInputField
                    name={nameOf("gstNumber")}
                    label={SettingsFormLabels.gstNumber}
                    maxLength={11} // 9 numbers plus 2 dashes
                    parse={parseGstNumber}
                    styles={{ root: { gridColumn: "span 6" } }}
                  />
                </Stack>

                <Stack styles={gridRowStyles}>
                  <Stack.Item styles={{ root: { gridColumn: "span 4" } }}>
                    <ToggleField
                      label={SettingsFormLabels.feesAreGstInclusive}
                      name={nameOf("alwaysApplyGst")}
                      onText="Yes"
                      offText="No"
                    />
                  </Stack.Item>
                  <Stack.Item styles={{ root: { gridColumn: "span 2" } }}>
                    <SpinNumberInputField
                      required
                      label={SettingsFormLabels.gstPercent}
                      prefix="%"
                      name={nameOf("gstPercent")}
                      max={99}
                      min={1}
                      styles={{ input: { minWidth: 0 } }}
                      parse={value => Number(value)}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </WhenCountry>
          </Stack>
        )}
      </SubmissionForm>
    );
  }
);
