import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";

import {
  PersonaSize,
  SideRailMenuItem,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { TitleText } from "@ui-components/RefText.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { ContactCardIdsEnum } from "../../../shared-components/types/contact-card-ids.enum.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { AccountsInfoCard } from "../../patient/view/AccountsInfoCard.tsx";
import { EmployersCard } from "../../patient/view/EmployersCard.tsx";
import { PatientPreferencesConsentsCard } from "../../patient/view/PatientPreferencesConsentsCard.tsx";
import { CardWrapper } from "../../shared-components/view/CardWrapper.tsx";
import { ContactMethodList } from "../../shared-components/view/contact-method-list/ContactMethodList.tsx";
import { ContactLayoutWrapper } from "../../shared-components/view/ContactLayoutWrapper.tsx";
import { ContactRelationshipList } from "../../shared-components/view/ContactRelationshipList.tsx";
import { HeaderWrapper } from "../../shared-components/view/HeaderWrapper.tsx";
import { ContactPersonaSecondaryText } from "./ContactPersonaSecondaryText.tsx";

export interface ContactDetailsProps {
  contact: Contact;
  contactPreferences: ContactPreferences | undefined;
}

export const menuItems: (SideRailMenuItem & {
  permissions?: Permission[];
})[] = [
  {
    text: Labels.contactInfo,
    id: ContactCardIdsEnum.contactHeader
  },
  {
    text: Labels.contactMethods,
    id: ContactCardIdsEnum.contactMethods
  },
  {
    text: Labels.communicationPreferences,
    id: ContactCardIdsEnum.prefCons
  },
  {
    text: Labels.account,
    id: PatientCardIds.patientAccount,
    permissions: [Permission.AccountHistoryAllowed]
  },
  {
    text: Labels.relationships,
    id: ContactCardIdsEnum.contactRelationships
  },
  {
    text: Labels.employers,
    id: ContactCardIdsEnum.employers
  }
];

export const ContactDetails: FunctionComponent<ContactDetailsProps> = observer(
  ({ contact, contactPreferences }) => {
    const { practice, core } = useStores();
    const {
      ui: { setSelectedEditCard, showEditContact }
    } = practice;

    const theme = useTheme();
    useEffect(() => {
      return () => {
        setSelectedEditCard(ContactCardIdsEnum.contactHeader);
      };
    }, [setSelectedEditCard]);

    const isContactMethodsExist =
      contact.addresses.length > 0 || contact.communications.length > 0;

    const isRelationshipsExist =
      contact.relationships.length > 0 &&
      contact.relationships.some(
        x =>
          x.relationship !== RelationshipType.AccountHolder &&
          x.relationship !== RelationshipType.AccountHolderFor
      );

    const isEmployersExist =
      contact.relationships.length > 0 &&
      contact.relationships.some(
        x => x.relationship === RelationshipType.Employer
      );

    const onHandleEditModal = (cardId: string) => {
      showEditContact(cardId, contact.id);
    };

    const isPreferencesConsentsExist =
      !!contactPreferences?.commTypePreferences?.length;

    const { personaStyle, personaTitleStyle } = getPeopleScreenStylesSet(theme);

    const permittedMenuItems = menuItems.filter(
      item => !item.permissions || core.hasPermissions(item.permissions)
    );

    return (
      <ContactLayoutWrapper menuItems={permittedMenuItems}>
        <Stack tokens={{ childrenGap: 16 }} grow>
          <HeaderWrapper
            contact={contact}
            onHandleEditModal={onHandleEditModal}
            persona={
              <Persona
                id={contact.id}
                contactType={contact.type}
                size={PersonaSize.size100}
                imageInitials={contact.initials}
                imageUrl={contact.profilePictureUrl}
                styles={personaStyle}
                onRenderPrimaryText={() => (
                  <Stack>
                    <Text styles={personaTitleStyle}>
                      <TitleText code={contact.title} />
                    </Text>
                    {contact.name}
                  </Stack>
                )}
                onRenderSecondaryText={() => (
                  <ContactPersonaSecondaryText contact={contact} />
                )}
              />
            }
          />
          {contact.isPerson && (
            <>
              <CardWrapper
                showEditIcon={isContactMethodsExist}
                title={Labels.contactMethods}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.contactMethods}
              >
                <ContactMethodList
                  showEditIcon={isContactMethodsExist}
                  patient={contact}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <CardWrapper
                showEditIcon={isPreferencesConsentsExist}
                title={Labels.communicationPreferences}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.prefCons}
              >
                <PatientPreferencesConsentsCard
                  contactPreferences={contactPreferences}
                  showEditIcon={isPreferencesConsentsExist}
                  patient={contact}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <When permission={[Permission.AccountHistoryAllowed]}>
                <AccountsInfoCard
                  contact={contact}
                  cardId={PatientCardIds.patientAccount}
                  onHandleEditModal={onHandleEditModal}
                />
              </When>
              <CardWrapper
                showEditIcon={isRelationshipsExist}
                title={Labels.relationships}
                onButtonClick={onHandleEditModal}
                cardId={PatientCardIds.contactRelationships}
              >
                <ContactRelationshipList
                  showEditIcon={isRelationshipsExist}
                  contact={contact}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
              <CardWrapper
                showEditIcon={true}
                title={Labels.employers}
                cardId={PatientCardIds.employers}
                onButtonClick={onHandleEditModal}
              >
                <EmployersCard
                  showEditIcon={isEmployersExist}
                  patient={contact}
                  onHandleEditModal={onHandleEditModal}
                />
              </CardWrapper>
            </>
          )}
        </Stack>
      </ContactLayoutWrapper>
    );
  }
);
