import { AllocationFieldValues } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { allocationNameOf } from "../../allocation-form/components/AllocationForm.types.ts";

/**
 * @returns allocations field.
 **/
export const useAllocationsValues = () => {
  const {
    fields: { value: allocations }
  } = useFieldArray<AllocationFieldValues>(allocationNameOf("allocations"), {
    subscription: { value: true }
  });

  return allocations;
};
