import { Option } from "@bps/fluent-ui";
import {
  EventType,
  EventVerb
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";

type RecentPatientFilterOption = Option<{
  verb: EventVerb;
  type: EventType;
}>;

export enum RecentPatientFilterKeys {
  ConsultFinalised = "consult-finalised",
  ConsultIncomplete = "consult-incomplete",
  Appointment = "appointment",
  Invoice = "invoice",
  RecordUpdate = "record-update"
}

export const recentPatientFilterOptions: RecentPatientFilterOption[] = [
  {
    key: RecentPatientFilterKeys.ConsultFinalised,
    text: "Consult (finalised)",
    data: {
      type: EventType.consult,
      verb: EventVerb.encounterClosed
    }
  },
  {
    key: RecentPatientFilterKeys.ConsultIncomplete,
    text: "Consult (incomplete)",
    data: {
      type: EventType.consult,
      verb: EventVerb.encounterStarted
    }
  },
  {
    key: RecentPatientFilterKeys.Appointment,
    text: "Appointment",
    data: {
      type: EventType.appointment,
      verb: EventVerb.create
    }
  },
  {
    key: RecentPatientFilterKeys.Invoice,
    text: "Invoice",
    data: {
      type: EventType.invoice,
      verb: EventVerb.create
    }
  },
  {
    key: RecentPatientFilterKeys.RecordUpdate,
    text: "Record update",
    data: {
      type: EventType.recordUpdate,
      verb: EventVerb.encounterClosed
    }
  }
];
