import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  FontSizes,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

import { ErrorMessageWithAction } from "./types/error-message-with-action.interface.ts";

enum ErrorClaimStatus {
  NotRegistered = "NOTREGISTEREDCLAIM"
}

const notRegisteredMessage = [
  'ACC returned "Claim is not registered with ACC".',
  "The usual reasons are:",
  "1 - Claim number is incorrect",
  "2 - Claim number is correct but the claim either hasn't been sent to ACC or processed by ACC yet.",
  "You can re-query ACC via Omni now or contact them for more information."
];

interface ErrorMessageBarProps {
  errorMessageList: ErrorMessageWithAction[];
}
const formatDateTime = (isoDate?: string) =>
  isoDate
    ? DateTime.fromISO(isoDate).toFormat(DATE_FORMATS.DETAILED_DATE_TIME)
    : null;

const renderText = (text: string): JSX.Element => (
  <Text block variant="small">
    {text}
  </Text>
);

export const ErrorMessageBar: FC<ErrorMessageBarProps> = observer(
  ({ errorMessageList }) => {
    return (
      <>
        {errorMessageList?.map(
          ({
            message,
            action,
            code,
            details,
            field,
            hidden,
            lastRequestedDateTime,
            nextRequestDateTime
          }) =>
            !hidden && (
              <MessageBar
                messageBarType={MessageBarType.error}
                key={`claimError${message}`}
              >
                <Stack tokens={{ childrenGap: 24 }}>
                  {code !== ErrorClaimStatus.NotRegistered && message}
                  {action && (
                    <Link
                      onClick={action}
                      styles={{ root: { fontSize: FontSizes.size12 } }}
                    >
                      Clear error to relodge
                    </Link>
                  )}

                  {code !== ErrorClaimStatus.NotRegistered &&
                    field &&
                    renderText(`Field: ${field}`)}
                  {code !== ErrorClaimStatus.NotRegistered &&
                    details &&
                    renderText(details)}
                  {code === ErrorClaimStatus.NotRegistered && (
                    <Stack tokens={{ childrenGap: 8 }}>
                      {notRegisteredMessage.map(text => (
                        <Text key={text} block variant="small">
                          {text}
                        </Text>
                      ))}
                    </Stack>
                  )}
                  {lastRequestedDateTime &&
                    renderText(
                      `Status last requested on: ${formatDateTime(
                        lastRequestedDateTime
                      )}`
                    )}
                  {nextRequestDateTime &&
                    renderText(
                      `Next retry scheduled for: ${formatDateTime(
                        nextRequestDateTime
                      )}`
                    )}
                </Stack>
              </MessageBar>
            )
        )}
      </>
    );
  }
);
