import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useField } from "react-final-form";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserContext } from "@modules/settings/screens/users/components/context/UserContext.tsx";
import { When } from "@ui-components/withPerm.tsx";

export const LicenceMessageBar: React.FC = observer(() => {
  const helper = useContext(UserContext);

  const {
    input: { value: businessRoles }
  } = useField<string[]>("businessRoles", { subscription: { value: true } });

  const userProviderRoles = helper.providerBusinessRoleCodes.filter(
    providerBr =>
      !!businessRoles &&
      businessRoles.some((br: string) => br === providerBr.code)
  );

  const requiredLicenceCodes =
    helper.getRequiredLicenceCodes(userProviderRoles);

  const hasProviderBr =
    businessRoles?.length > 0 && userProviderRoles?.length > 0;

  const requiredLicenceAvailable =
    helper.availableLicences.some(x =>
      requiredLicenceCodes.some(l => l === x.licenceTypeCode)
    ) ?? false;

  const showAssignLicenceMessage = hasProviderBr && requiredLicenceAvailable;

  const showNoLicencesAvailableMessage =
    hasProviderBr && !requiredLicenceAvailable;

  return (
    <When permission={[Permission.LicencingAllowed]}>
      {showAssignLicenceMessage && (
        <MessageBar messageBarType={MessageBarType.info}>
          The current provider role requires a licence. An available licence
          will be assigned to this user
        </MessageBar>
      )}
      {showNoLicencesAvailableMessage && (
        <MessageBar messageBarType={MessageBarType.warning}>
          This provider role requires a licence. No licences are available. You
          need to purchase extra licences or unassign one. Otherwise this user
          will have restricted access
        </MessageBar>
      )}
    </When>
  );
});
