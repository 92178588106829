import { useRef, useState } from "react";

import { Callout, Heading, Stack } from "@bps/fluent-ui";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentHelper } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { ClaimAdjustmentList } from "./ClaimAdjustmentList.tsx";
import { ClaimAdjustmentResponseDialogForm } from "./ClaimAdjustmentResponseDialogForm.tsx";

interface ClaimAdjustmentCalloutProps {
  claim: Claim;
}

export const ClaimAdjustmentCallout: React.FC<ClaimAdjustmentCalloutProps> = ({
  claim
}) => {
  const [showCallout, setShowCallout] = useState(false);
  const claimAdjustments = claim.claimAdjustments!;
  const claimAdjustmentId = `claim-adjustment-callout-${claimAdjustments[0].id}`;
  const root = useStores();
  const claimAdjustmentHelper = useRef(new ClaimAdjustmentHelper(root, claim));

  return (
    <ClaimAdjustmentContext.Provider value={claimAdjustmentHelper.current}>
      <Navigate
        id={claimAdjustmentId}
        onMouseDown={e => e.stopPropagation()}
        onClick={() => setShowCallout(prev => !prev)}
      >
        <span> See all ({claimAdjustments.length})</span>
      </Navigate>
      {showCallout && (
        <Callout
          target={`#${claimAdjustmentId}`}
          calloutWidth={600}
          onDismiss={() => setShowCallout(prev => !prev)}
        >
          <Stack styles={{ root: { padding: "20px 24px" } }}>
            <Heading
              styles={{ root: { marginBottom: 16 } }}
              variant="section-heading-light"
            >
              ACC32s for claim #{claimAdjustments[0].dto.claimNumber}
            </Heading>
            <ClaimAdjustmentList claimAdjustments={claimAdjustments} />
            <ClaimAdjustmentResponseDialogForm />
          </Stack>
        </Callout>
      )}
    </ClaimAdjustmentContext.Provider>
  );
};
