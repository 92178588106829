import { Item } from "@bps/fluent-ui";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  ContactFilter,
  IndividualAndOrgContactFilter,
  PatientContactFilter
} from "@shared-types/practice/contacts-filter.interface.ts";

export const searchFilterItem = (): Item<ContactFilter> => {
  return {
    type: "searchBox",
    name: nameof<ContactFilter>("search"),
    stickItem: true,
    props: {
      removeSpecialCharacters: false,
      autoFocus: true,
      placeholder: "Search name",
      styles: { root: { maxWidth: 280, minWidth: 280 } }
    }
  };
};

export const typesFilterItem = (
  contactTypes: KeyTextValue<ContactType>[]
): Item<PatientContactFilter> => {
  return {
    type: "optionsSelect",
    name: nameof<PatientContactFilter>("types"),
    props: {
      id: "contacts-filter-types",
      multiSelect: true,
      placeholder: "Show all types",
      options: contactTypes,
      hideSearchOption: true,
      calloutWidth: 170,
      listHeight: "initial"
    }
  };
};

export const categoriesItem = (
  contactCategories: KeyTextValue<string>[]
): Item<IndividualAndOrgContactFilter> => {
  return {
    type: "optionsSelect",
    name: nameof<IndividualAndOrgContactFilter>("categories"),
    props: {
      id: "contacts-filter-categories",
      multiSelect: true,
      placeholder: "Categories",
      options: contactCategories,
      calloutWidth: 170,
      listHeight: "300px"
    }
  };
};

export const disciplinesItem = (
  contactDisciplines: KeyTextValue<string>[]
): Item<IndividualAndOrgContactFilter> => {
  return {
    type: "optionsSelect",
    name: nameof<IndividualAndOrgContactFilter>("disciplines"),
    props: {
      id: "contacts-filter-disciplines",
      multiSelect: true,
      placeholder: "Disciplines",
      options: contactDisciplines,
      calloutWidth: 170,
      listHeight: "300px"
    }
  };
};

export const statusFilterItem = (
  contactStatuses: KeyTextValue<string>[]
): Item<ContactFilter> => {
  return {
    type: "optionsSelect",
    name: nameof<ContactFilter>("statuses"),
    props: {
      id: "contacts-filter-statuses",
      multiSelect: true,
      placeholder: "Show all statuses",
      options: contactStatuses,
      hideSearchOption: true,
      calloutWidth: 170,
      listHeight: "initial"
    }
  };
};
