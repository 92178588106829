import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { CorrespondenceDirection } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";

import { InvestigationList, InvestigationType } from "./InvestigationList.tsx";
import { RequestListItem } from "./RequestsListItem.tsx";
import { RequestsListItemContextMenu } from "./RequestsListItemContextMenu.tsx";

export const RequestsList: FunctionComponent = observer(() => {
  return (
    <InvestigationList
      onRenderContextMenu={(record: ClinicalDocument) => (
        <RequestsListItemContextMenu record={record} />
      )}
      onRenderTitle={(record: ClinicalDocument) => (
        <RequestListItem record={record} />
      )}
      correspondenceDirection={CorrespondenceDirection.Out}
      investigationType={InvestigationType.Request}
    />
  );
});
