import React from "react";

import { FontIcon, Link, Stack, useTheme } from "@bps/fluent-ui";

type NotRecordedProps = {
  element: React.ReactNode;
  onClick: () => void;
};

export const NotRecorded: React.FC<NotRecordedProps> = ({
  element,
  onClick
}) => {
  const theme = useTheme();
  return (
    <Stack
      horizontal
      styles={{
        root: {
          color: theme.palette.yellowDark
        }
      }}
    >
      <FontIcon
        iconName="Unknown"
        styles={{ root: { marginRight: 4, marginTop: 1 } }}
      />
      <Link
        onClick={onClick}
        styles={{ root: { color: theme.palette.yellowDark } }}
      >
        {element}
      </Link>
    </Stack>
  );
};
