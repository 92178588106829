import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import { FontIcon, Link } from "@bps/fluent-ui";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";

export const AppointmentNoticesLink: React.FC = observer(() => {
  const { practice, booking } = useStores();

  const {
    input: { value: patientId }
  } = useField(appointmentFormNameOf("patientId"));

  const adminNotices = Array.from(practice.patientNoticesMap.values()).filter(
    pn => pn.patientId === patientId && pn.isAdmin
  );

  const systemNotices = practice.systemNotices.filter(
    sn => sn.patientId === patientId
  );

  const adminAndSystemNoticeCount = adminNotices.length + systemNotices.length;

  const handleClick = () => {
    booking.ui.setSecondColumnContent(
      booking.ui.currentAppointment?.secondColumnContent !==
        SecondColumnContent.patientNotices
        ? SecondColumnContent.patientNotices
        : undefined
    );
  };

  const collapsedText =
    adminAndSystemNoticeCount === 0
      ? "Add notices"
      : `View (${adminAndSystemNoticeCount}) notice${
          adminAndSystemNoticeCount > 1 ? "s" : ""
        }`;

  const iconStyles = { root: { fontSize: 10, marginLeft: 4 } };

  return (
    <div>
      <Link
        onClick={handleClick}
        styles={{
          root: {
            marginTop: 5
          }
        }}
      >
        {booking.ui.currentAppointment?.secondColumnContent ===
        SecondColumnContent.patientNotices ? (
          <>
            Hide notices
            <FontIcon iconName="DoubleChevronLeft" styles={iconStyles} />
          </>
        ) : (
          <>
            {collapsedText}
            <FontIcon iconName="DoubleChevronRight" styles={iconStyles} />
          </>
        )}
      </Link>
    </div>
  );
});
