import { NzPracticeAccContractDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { pattern } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

const ACCCONTRACT_PATTERN = /^[a-zA-Z0-9/]{1,8}$/;

export class PracticeAccContractsValidator extends Validator<NzPracticeAccContractDto> {
  constructor() {
    super();

    this.forField(
      "contractId",
      pattern(ACCCONTRACT_PATTERN, ValidationMessages.invalid)
    );
  }
}
