import { maxArrayLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { AddDiagnosisFormValues } from "@shared-types/acc/add-diagnosis-values.type.ts";

export class AddDiagnosisDraftValidator extends Validator<AddDiagnosisFormValues> {
  constructor() {
    super();

    this.forField("diagnosisAdditions", [
      maxArrayLength(6, "Must not exceed 6 diagnosis.")
    ]);
  }
}
