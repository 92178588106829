import React from "react";

import { Stack, StackItem, Text } from "@bps/fluent-ui";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  AddXrayParametersView,
  imagingReportViewParameters
} from "./AddXrayParameterDialog.types.ts";
import {
  customViews,
  nameOfView
} from "./imaging-request-dialog/ImagingRequestDialog.types.ts";

interface ImagingReportViewParameterFieldProps {
  deleteParameter: () => void;
  imagingReporViewIndex: number;
  addXrayParametersViews: AddXrayParametersView[];
}

export const ImagingReportViewParameterField: React.FC<
  ImagingReportViewParameterFieldProps
> = ({ deleteParameter, imagingReporViewIndex, addXrayParametersViews }) => {
  const viewIndexFieldName = `views[${imagingReporViewIndex}]`;

  return (
    <Stack
      horizontal
      verticalAlign="start"
      tokens={{ childrenGap: 8 }}
      styles={{
        root: {
          marginBottom: 8
        }
      }}
    >
      <StackItem
        styles={{
          root: {
            display: "flex",
            alignItems: "center",
            paddingTop: imagingReporViewIndex === 0 ? 35 : 5
          }
        }}
      >
        <Text bold>{imagingReporViewIndex + 1}</Text>
      </StackItem>
      <ComboBoxField
        options={customViews}
        allowFreeform
        label={imagingReporViewIndex === 0 ? "View" : undefined}
        name={`${viewIndexFieldName}.${nameOfView("view")}`}
        fieldItemStyles={{ root: { minWidth: 243.5 } }}
        placeholder="Select or type a view"
        useComboBoxAsMenuWidth
      />

      {imagingReportViewParameters.map(({ parameter, unit, fieldName }) => {
        return (
          <TextInputField
            key={parameter}
            name={`${viewIndexFieldName}.${fieldName}`}
            label={imagingReporViewIndex === 0 ? parameter : undefined}
            suffix={unit}
            placeholder="0"
          />
        );
      })}
      <StackItem
        styles={{
          root: {
            display: "flex",
            alignItems: "center",
            paddingTop: imagingReporViewIndex === 0 ? 30 : 0
          }
        }}
      >
        <DeleteButton
          onClick={deleteParameter}
          disabled={addXrayParametersViews.length <= 1}
        />
      </StackItem>
    </Stack>
  );
};
