import { observer } from "mobx-react-lite";

import {
  ButtonsGroupChoice,
  FontIcon,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  QuestionnaireAnswerOptionDto,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";

import { ClinicalToolInformationPanel } from "../shared-components/ClinicalToolInformationPanel.tsx";
import {
  ClinicalToolAnswer,
  findClinicalToolAnswer
} from "../utils/ClinicalToolAnswerFinder.ts";
import { DASS21Instructions } from "./DASS21Instructions.tsx";

interface DASS21QuestionsProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  isViewOnly?: boolean;
  width: number;
  questionPrefix?: string;
}

const SIZE_SHORT = 560;

export const DASS21Questions: React.FC<DASS21QuestionsProps> = observer(
  ({ questionnaire, answers, isViewOnly, width, questionPrefix }) => {
    const optionTooltips = [
      "Did not apply to me at all - NEVER",
      "Applied to me to some degree, or some of the time - SOMETIMES",
      "Applied to me to a considerable degree, or a good part of time - OFTEN",
      "Applied to me very much, or most of the time - ALMOST ALWAYS"
    ];

    const theme = useTheme();

    const getBgColour = (index: number) => {
      return index % 2 === 0
        ? theme.palette.neutralLighterAlt
        : theme.palette.white;
    };

    const findAnswer = (questionId: string): string => {
      let answerValue = "";
      if (answers) {
        const answer = findClinicalToolAnswer(
          isViewOnly ? questionId : `${questionPrefix}${questionId}`,
          answers
        );
        if (answer && answer.answerValue) answerValue = answer.answerValue;
      }
      return answerValue;
    };

    const isShortWidth = SIZE_SHORT >= width;

    const questions = questionnaire.items;

    const getKeyTextAnswersWithTooltip = (
      answerOptions: QuestionnaireAnswerOptionDto[],
      disabled: boolean
    ) => {
      const mapped = answerOptions.map((x, index) => {
        return {
          key: x.value,
          text: isShortWidth ? `${index}` : x.text,
          disabled,
          tooltipContent: isShortWidth ? optionTooltips[index] : undefined
        };
      });

      return mapped;
    };

    return (
      <Stack>
        <ClinicalToolInformationPanel
          title="Depression, Anxiety & Stress Scale 21"
          expandButtonText="Scoring instructions"
          onRenderOpenTooltip={() => (
            <>Click to show information about DASS 21</>
          )}
          onRenderCloseTooltip={() => (
            <>
              Close. Click the{" "}
              <FontIcon
                iconName="Info"
                styles={{ root: { color: theme.palette.accent } }}
              />{" "}
              icon to show again
            </>
          )}
          onRenderContent={isExpanded => (
            <DASS21Instructions expanded={isExpanded} />
          )}
          canExpand
        />
        {questions.map((item, index) => {
          return (
            <Stack
              key={item.id.toString()}
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
              styles={{
                root: {
                  backgroundColor: getBgColour(index),
                  padding: "8px 8px"
                }
              }}
            >
              <Stack
                horizontalAlign="space-between"
                verticalAlign="center"
                tokens={{ childrenGap: 8 }}
                styles={{
                  root: {
                    backgroundColor: getBgColour(index),
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingRight: 8,
                    width: "100%"
                  }
                }}
              >
                <Stack horizontal>
                  <Text bold styles={{ root: { minWidth: 16 } }}>
                    {item.id}.
                  </Text>
                  <Text bold styles={{ root: { paddingLeft: 18 } }}>
                    {item.text}
                  </Text>
                </Stack>
                <Stack>
                  {!answers && (
                    <ButtonsGroupSingleChoiceField
                      name={`q${item.id}`}
                      options={getKeyTextAnswersWithTooltip(
                        item.answerOptions || [],
                        false
                      )}
                      fieldItemStyles={{
                        item: { marginLeft: 33 }
                      }}
                    />
                  )}

                  {answers && (
                    <ButtonsGroupChoice
                      name={`q${item.id}`}
                      options={getKeyTextAnswersWithTooltip(
                        item.answerOptions || [],
                        true
                      )}
                      value={findAnswer(`${item.id}`)}
                      styles={{
                        root: {
                          marginLeft: 40,
                          backgroundColor: getBgColour(index),
                          width: "fit-content"
                        }
                      }}
                      onChange={() => {}}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  }
);
