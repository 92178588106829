import { DATE_FORMATS, DateTime } from "@bps/utils";
import { EmploymentStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isDateAfterField,
  isDateBeforeField,
  isDateSameOrAfterDate,
  isNotFutureDate,
  maxLength,
  predicate,
  required,
  requiredBoolean,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ClaimAddressValidator } from "../../claim-adjustment/validators/ClaimAddressValidator.ts";
import { AccidentAndEmploymentDetailsFormValidatorValues } from "../types/accident-and-employment-details-form-validator-value.type.ts";

export class AccidentAndEmploymentDetailsFormValidator extends Validator<AccidentAndEmploymentDetailsFormValidatorValues> {
  constructor() {
    super();

    const claimAddressValidator = new ClaimAddressValidator();
    this.forField(nameof("accidentSceneCode"), [required()]);
    this.forField(nameof("accidentLocationCode"), [required()]);
    this.forField(nameof("causeOfAccident"), [required(), maxLength(255)]);
    this.forField(nameof("accidentDate"), [
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isDateAfterField(
        messageWithData(
          ValidationMessages.accidentDateEqualOrBefore,
          "patient"
        ),
        nameof("patientDeclaration")
      ),

      isDateAfterField(
        messageWithData(
          ValidationMessages.accidentDateEqualOrBefore,
          "provider"
        ),
        nameof("providerDeclaration")
      ),

      isNotFutureDate(),
      isDateSameOrAfterDate(
        DateTime.fromISO("1972-01-01").toJSDate(),
        ValidationMessages.noDateBefore1972
      ),
      predicate(
        (val, values?: AccidentAndEmploymentDetailsFormValidatorValues) =>
          !!values?.patientDateOfBirth && !!val,
        isDateBeforeField(
          ValidationMessages.afterBirthdate,
          nameof("patientDateOfBirth"),
          true
        )
      ),
      required()
    ]);
    this.forField(nameof("involvesVehicle"), [requiredBoolean()]);
    this.forField(nameof("workInjury"), [requiredBoolean()]);
    this.forField(nameof("sportingInjury"), [requiredBoolean()]);

    this.forField(
      nameof("sport"),
      predicate((val, values) => !!values?.sportingInjury, required())
    );

    this.forField(nameof("occupation"), [required()]);

    this.forField(nameof("inPaidEmployment"), requiredBoolean());

    this.forField(
      nameof("employmentStatus"),
      predicate((val, values) => !!values?.inPaidEmployment, required())
    );

    this.forField(
      nameof("workType"),
      predicate((val, values) => !!values?.inPaidEmployment, required())
    );

    this.forField(
      nameof("employerId"),
      predicate(
        (val, values) =>
          values?.employmentStatus === EmploymentStatuses.IAmAnEmployee &&
          !!values?.workInjury &&
          !values?.isQuickAddEmployer,
        required()
      )
    );

    this.forField(
      nameof("employerName"),
      predicate(
        (val, values) =>
          values?.employmentStatus === EmploymentStatuses.IAmAnEmployee &&
          !!values?.workInjury &&
          !!values?.isQuickAddEmployer,
        required()
      )
    );

    this.forField(
      nameof("employerAddress"),
      predicate(
        (val, values) =>
          values?.employmentStatus === EmploymentStatuses.IAmAnEmployee &&
          !!values?.workInjury,
        claimAddressValidator.validate
      )
    );

    this.forField("otherEmployment", [
      maxLength(50),
      predicate(
        (val, values: AccidentAndEmploymentDetailsFormValidatorValues) =>
          values?.employmentStatus === EmploymentStatuses.Other,
        required()
      )
    ]);
  }
}
