import { ReactNode } from "react";

import { Heading, Stack, Text } from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export interface RowOptions {
  id?: string;
  text: string | ReactNode;
  amount: number;
  path?: string;
  permission?: string;
  noLinkText?: string;
}

interface TotalsDetailsProps {
  rowOptions?: RowOptions[];
  heading: string;
  fontColor?: string;
  width?: string | number;
}

export const TotalsDetails: React.FC<TotalsDetailsProps> = ({
  rowOptions,
  heading,
  fontColor,
  width
}) => {
  const { core, routing } = useStores();
  const color = fontColor ?? "initial";

  const getText = (rowOptions: RowOptions) => {
    if (
      rowOptions.path &&
      (!rowOptions.permission || core.hasPermissions(rowOptions.permission))
    ) {
      return (
        <Text>
          <Navigate
            onClick={() =>
              routing.pushRetainingState({
                pathname: rowOptions.path
              })
            }
          >
            {rowOptions.text}
          </Navigate>
          &nbsp;
          {rowOptions.noLinkText}
        </Text>
      );
    } else {
      return <Heading>{rowOptions.text}</Heading>;
    }
  };

  const totalJSX = rowOptions?.map(c => (
    <Stack as="li" key={c.id} horizontal horizontalAlign="space-between">
      {getText(c)}
      <Text>{currencyFormat(c.amount)}</Text>
    </Stack>
  ));

  return (
    <Stack
      styles={{
        root: { width: width ?? 243 }
      }}
    >
      <Heading
        styles={{
          root: {
            display: "flex",
            justifyContent: "space-between",
            margin: "5px 0px",
            color
          }
        }}
      >
        <span>{heading}</span>
        <span>($)</span>
      </Heading>
      <Stack as="ul">{totalJSX}</Stack>
    </Stack>
  );
};
