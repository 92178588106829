import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import { DateTime } from "@bps/utils";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { Booked } from "../../shared-components/Booked.tsx";
import { CreateTemporaryReservation } from "./CreateTemporaryReservation.tsx";
import { nameOf, UnavailableFormValues } from "./UnavailableForm.types.ts";
import { useUnavailableFormContext } from "./UnavailableFormContext.ts";

export const UnavailableFormFields: FunctionComponent = () => {
  const { change, getState } = useForm<UnavailableFormValues>();
  const { bookedByUser, bookedDate } = useUnavailableFormContext();
  return (
    <Fieldset>
      <BookableUserPickerField
        name={nameOf("providerId")}
        label="Provider"
        filter={{
          statusCodes: [UserStatus.Active],
          showOnCalendar: true
        }}
        required
        iconName="Search"
      />

      <Fieldset horizontal>
        <DatePickerField label="Start date" name={nameOf("startDate")} />
        <FieldSpy
          name={nameOf("startDate")}
          onChange={date => {
            const values = getState().values;
            if (
              values.endDate &&
              date &&
              DateTime.fromJSDate(values.endDate) < DateTime.fromJSDate(date)
            ) {
              change("endDate", date);
            }
          }}
        />
        <TimePickerField
          label="Start time"
          suggestionInterval={15}
          name={nameOf("startTime")}
        />
      </Fieldset>
      <Fieldset horizontal>
        <DatePickerField label="End date" name={nameOf("endDate")} />
        <TimePickerField
          label="End time"
          suggestionInterval={15}
          name={nameOf("endTime")}
        />
      </Fieldset>
      <TextInputField
        multiline={true}
        label="Comments"
        name={nameOf("comments")}
      />
      <Booked bookedByUser={bookedByUser} bookedDate={bookedDate} />
      <CreateTemporaryReservation />
    </Fieldset>
  );
};
