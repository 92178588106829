import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { AllocationFormButtons } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { AllocateToInvoiceFormHelper } from "@modules/billing/screens/allocation/context/AllocateToInvoiceFormHelper.tsx";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import {
  AllocationFieldValues,
  ItemSelectionMode
} from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { getAllocationsTotal } from "@modules/billing/screens/shared-components/allocation-field/utils.tsx";
import { BillingHeading } from "@modules/billing/screens/shared-components/BillingHeading.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { EmailConfirmationModal } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { useAllocationField } from "../../allocation-field/hooks/useAllocationField.ts";
import { useAllocationOption } from "../../allocation-field/hooks/useAllocationOption.ts";
import { OptedOutTooltip } from "../../OptedOutTooltip.tsx";
import {
  AllocationFormValues,
  allocationNameOf
} from "./AllocationForm.types.ts";
import { OverpayingConfirmationDialog } from "./OverpayingConfirmationDialog.tsx";

interface AllocationFormHeaderProps
  extends Pick<
    AllocateToInvoiceFormHelper,
    | "invoice"
    | "number"
    | "setShouldOpenPdf"
    | "setShouldSendEmail"
    | "setAllocationEmail"
  > {}

export const AllocationFormHeader: React.FC<AllocationFormHeaderProps> =
  observer(
    ({
      invoice,
      number,
      setShouldOpenPdf,
      setShouldSendEmail,
      setAllocationEmail
    }) => {
      const root = useStores();
      const { routing, practice } = root;
      const form = useForm();
      const formState = useFormState<AllocationFormValues>({
        subscription: { invalid: true, submitting: true, initialValues: true }
      });

      const allocationOption = useAllocationOption();
      const { allocatableAmount, allocatedTotal } = useAllocationField();

      const {
        input: { value: allocations }
      } = useField<AllocationFieldValues[]>(allocationNameOf("allocations"), {
        subscription: { value: true }
      });

      const [emailDialogVisible, setEmailDialogVisible] =
        useState<boolean>(false);

      const [overpayDialogVisible, setOverpayDialogVisible] =
        useState<boolean>(false);

      const { accountContactId, allocations: paymentAllocations } =
        formState.initialValues;

      const isAllUnallocated: boolean = allocations.every(
        (a: AllocationFieldValues) => !a.checked
      );

      const onSubmit = (): void => {
        form.submit();
      };

      const accountContact = accountContactId
        ? practice.contactsMap.get(accountContactId)
        : undefined;

      const isNotPublicInsurer =
        !accountContact?.organisationRoles ||
        !practice.isNzPublicInsurer(accountContact.organisationRoles);

      const handleSubmitClicked: React.MouseEventHandler<HTMLButtonElement> = (
        ev
      ): void => {
        if (formState.submitting) {
          ev.preventDefault();
          ev.stopPropagation();
          return;
        }

        allocationOption?.itemSelectionMode === ItemSelectionMode.hidden ||
        allocatableAmount <= allocatedTotal
          ? onSubmit()
          : setOverpayDialogVisible(true);
      };

      const handleSendEmail = () => {
        if (form.getState().invalid) {
          form.submit(); // the form won't submit - this is to show validation errors
        } else {
          setEmailDialogVisible(true);
        }
      };

      const handleOpenPdf = () => {
        setShouldOpenPdf(true);
        onSubmit();
      };

      const disableSubmitOnPristine =
        !accountContactId ||
        getAllocationsTotal(paymentAllocations || []) === 0;

      const extraActionsBetween = (
        form: FormApi<AllocationFormValues>,
        isDisabled: boolean
      ): JSX.Element => {
        return (
          <>
            {isNotPublicInsurer && (
              <OptedOutTooltip contact={accountContact}>
                <DefaultButton
                  {...dataAttribute(
                    DataAttributes.Element,
                    "email-allocation-button"
                  )}
                  text={AllocationFormButtons.sendEmail}
                  iconProps={{ iconName: "Mail" }}
                  onClick={handleSendEmail}
                  disabled={
                    isAllUnallocated ||
                    accountContact?.billingOptedOut ||
                    isDisabled
                  }
                />
              </OptedOutTooltip>
            )}
            <DefaultButton
              {...dataAttribute(
                DataAttributes.Element,
                "get-allocation-invoice-pdf-button"
              )}
              text={AllocationFormButtons.pdf}
              iconProps={{ iconName: "pdf" }}
              onClick={handleOpenPdf}
              disabled={isAllUnallocated || isDisabled}
            />
          </>
        );
      };

      const extraActionBefore = (
        form: FormApi<AllocationFormValues>,
        isDisabled: boolean
      ) => (
        <Stack.Item styles={{ root: { flexShrink: 0 } }}>
          <DefaultButton
            {...dataAttribute(
              DataAttributes.Element,
              "submit-allocation-button"
            )}
            text={AllocationFormButtons.submit}
            primary
            iconProps={{ iconName: "Save" }}
            onClick={handleSubmitClicked}
            disabled={isDisabled}
          />
        </Stack.Item>
      );

      const onConfirmOverpay = () => {
        setOverpayDialogVisible(false);
        onSubmit();
      };
      return (
        <>
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={{ root: { width: "100%" } }}
          >
            <BillingHeading
              breadcrumbProps={{
                transactionNumber: number,
                transactionRefNumber: invoice?.number
              }}
              dateOverride={
                <DatePickerField
                  name={allocationNameOf("paymentDate")}
                  styles={{ fieldGroup: { borderColor: "transparent" } }}
                  maxDate={DateTime.jsDateNow()}
                />
              }
              buttons={
                <>
                  <FormSubmitButtons
                    cancelButtonProps={{
                      ...dataAttribute(
                        DataAttributes.Element,
                        "cancel-allocation-button"
                      )
                    }}
                    disableSubmitOnPristine={disableSubmitOnPristine}
                    extraActionsBetween={extraActionsBetween}
                    extraActionsBefore={extraActionBefore}
                    hideButtonsSeparator={true}
                    hideSubmit
                    buttonsGap={8}
                    onCancel={() =>
                      closeInvoiceOrPaymentPage(routing, "replace")
                    }
                    styles={{
                      root: { paddingTop: 0, margin: 0, borderTop: "none" }
                    }}
                  />
                  {overpayDialogVisible &&
                    allocatableAmount - allocatedTotal > 0 && (
                      <OverpayingConfirmationDialog
                        onDismiss={() => setOverpayDialogVisible(false)}
                        onSucceeded={onConfirmOverpay}
                      />
                    )}
                </>
              }
            />
          </Stack>
          {emailDialogVisible && accountContactId && (
            <EmailConfirmationModal
              accountContactId={accountContactId}
              onDismiss={() => {
                setEmailDialogVisible(false);
              }}
              onSubmit={({ email }) => {
                setEmailDialogVisible(false);
                setShouldSendEmail(true);
                setAllocationEmail(email);
                onSubmit();
              }}
              commType={OutboundCommType.Receipt}
            />
          )}
        </>
      );
    }
  );
