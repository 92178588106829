import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";
import { ResourceHeaderProps } from "react-big-calendar";

import { ActionButton, IContextualMenuItem, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { EditUserDayWorkingHoursDialog } from "../edit-user-day-working-hours-dialog/EditUserDayWorkingHoursDialog.tsx";

export const BookingCalendarResourceHeader: FunctionComponent<ResourceHeaderProps> =
  observer(({ label, resource }) => {
    const { booking, core } = useStores();
    const { setNewRecurrenceUserId } = useBookingCalendarScreenContext();
    const [showEditWorkingHoursDialog, setShowEditWorkingHoursDialog] =
      useState<boolean>(false);

    const menuItems: IContextualMenuItem[] = [
      {
        key: "editWorkingHours",
        text: "Change working hours",
        onClick: () => {
          setShowEditWorkingHoursDialog(true);
        }
      },
      {
        key: "addRecurrence",
        text: "Add reserve",
        onClick: () => {
          setNewRecurrenceUserId((resource as User).id);
        }
      },
      {
        key: "sessionSettings",
        text: "Session settings",
        onClick: () => {
          booking.routing.push(
            routes.settings.users.userWorkingHours.path({
              id: (resource as User).id!
            })
          );
        }
      }
    ].filter(() => core.hasPermissions(Permission.SecurityWrite));

    return (
      <>
        {menuItems.length ? (
          <>
            {/* show EditUserDayWorkingHoursDialog */}
            {showEditWorkingHoursDialog && (
              <EditUserDayWorkingHoursDialog
                user={resource as User}
                onClose={() => setShowEditWorkingHoursDialog(false)}
              />
            )}

            <ActionButton
              menuIconProps={{
                iconName: "MoreVertical"
              }}
              menuProps={{
                items: menuItems
              }}
              styles={{
                root: { overflowX: "hidden" },
                textContainer: { whiteSpace: "normal" }
              }}
            >
              {label}
            </ActionButton>
          </>
        ) : (
          <Stack
            verticalAlign="center"
            horizontalAlign="center"
            styles={(props, theme) => ({
              root: [{ height: 40 }, theme.fonts.medium]
            })}
          >
            {label}
          </Stack>
        )}
      </>
    );
  });
