import { FunctionComponent } from "react";

import {
  FontWeights,
  IPickerItemProps,
  Stack,
  Text,
  TextBadge,
  TextBadgeSize
} from "@bps/fluent-ui";

import { ServicePickerTag } from "./ServicePicker.types.ts";

interface ServiceSuggestionItemProps
  extends Pick<IPickerItemProps<ServicePickerTag>, "item"> {}

export const ServiceSuggestionItem: FunctionComponent<
  ServiceSuggestionItemProps
> = ({ item }) => {
  return (
    <Stack
      key={item.key}
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
      styles={{ root: { width: "100%", padding: "6px 6px 6px 12px" } }}
      tokens={{ childrenGap: 16 }}
    >
      <Text nowrap>
        <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
          {`${item.code}: `}
        </Text>
        {item.name}
      </Text>

      <Stack.Item grow={0} shrink={0}>
        <TextBadge badgeColor={2} badgeSize={TextBadgeSize.small} noTextWrap>
          {item.scheduleName}
        </TextBadge>
      </Stack.Item>
    </Stack>
  );
};
