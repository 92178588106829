import { observer } from "mobx-react-lite";

import {
  FontWeights,
  LabeledText,
  LabeledTextStyles,
  mergeStyleSets,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import {
  CscDto,
  DvaDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DVACardColourText } from "@ui-components/RefText.tsx";

import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { cscFormat, medicareFormat } from "../../../shared-components/utils.ts";
import { NoDetailsButton } from "../../shared-components/view/NoDetailsButton.tsx";
import { patientWarningDotStyles } from "./patient-warnings/PatientWarnings.styles.ts";

interface PatientEntitlementProps {
  showEditIcon?: string | DvaDto | CscDto;
  patient: Contact;
  onHandleEditModal: (id?: string) => void;
  country: string | undefined;
}

export enum PatientEntitlementsLabels {
  MemberNumber = "Member number",
  FamilyNumber = "Family number",
  CardColour = "Card colour",
  ExpiryDate = "Expiry date",
  CardNumber = "Card number",
  HealthFund = "Health fund",
  Disability = "Disability",
  FundName = "Fund name",
  Medicare = "Medicare",
  Colour = "Colour",
  Number = "Number",
  Expiry = "Expiry",
  DVA = "DVA",
  IRN = "IRN",
  CSC = "Community Services Card",
  StartDate = "Start date"
}

export const PatientEntitlements: React.FC<PatientEntitlementProps> = observer(
  ({ showEditIcon, patient, onHandleEditModal, country }) => {
    const { medicare, dva, healthInsurance, csc } = patient;
    const theme = useTheme();
    const descriptionTitleStyle: Partial<LabeledTextStyles> = {
      label: {
        fontSize: theme.fonts.medium.fontSize,
        color: theme.palette.black,
        fontWeight: FontWeights.semibold
      }
    };

    const labelStyle: Partial<LabeledTextStyles> = {
      text: {
        whiteSpace: "normal",
        wordBreak: "break-word",
        minWidth: 80
      }
    };

    const expiryLabelStyle = {
      ...labelStyle,
      subComponentStyles: {
        label: mergeStyleSets(patientWarningDotStyles(theme))
      }
    };

    const hfundexpiry = DateTime.fromISO(
      healthInsurance?.expiry
    )?.toDayDefaultFormat();

    const cscstartdate = DateTime.fromISO(csc?.startDate)?.toDayDefaultFormat();

    const cscexpiry = DateTime.fromISO(csc?.expiry)?.toDayDefaultFormat();

    return (
      <Stack tokens={{ childrenGap: 16 }}>
        {showEditIcon ? (
          <>
            {medicare?.number ? (
              <Stack styles={{ root: { padding: "0 10px" } }}>
                <Stack.Item styles={{ root: { marginBottom: 8 } }}>
                  <LabeledText
                    noSemicolon
                    label={PatientEntitlementsLabels.Medicare}
                    styles={descriptionTitleStyle}
                  />
                </Stack.Item>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  tokens={{ childrenGap: 32 }}
                >
                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.Number}
                      styles={labelStyle}
                    >
                      <div>{medicareFormat(medicare.number)}</div>
                    </LabeledText>
                  </Stack.Item>
                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.IRN}
                      styles={{
                        text: {
                          minWidth: 20
                        }
                      }}
                    >
                      <div>{medicare?.irnNumber}</div>
                    </LabeledText>
                  </Stack.Item>

                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.Expiry}
                      styles={
                        patient.medicareIsExpired || patient.medicareWillExpire
                          ? expiryLabelStyle
                          : labelStyle
                      }
                    >
                      <div>
                        {medicare.expiryMonth}/{medicare.expiryYear}
                      </div>
                    </LabeledText>
                  </Stack.Item>
                </Stack>
              </Stack>
            ) : null}

            {healthInsurance ? (
              <Stack styles={{ root: { padding: "0 10px" } }}>
                <Stack.Item styles={{ root: { marginBottom: 8 } }}>
                  <LabeledText
                    noSemicolon
                    label={PatientEntitlementsLabels.HealthFund}
                    styles={descriptionTitleStyle}
                  />
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 32 }}>
                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.FundName}
                      styles={labelStyle}
                    >
                      <div>{healthInsurance.fund}</div>
                    </LabeledText>
                  </Stack.Item>
                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.MemberNumber}
                      styles={labelStyle}
                    >
                      <div>{healthInsurance.number}</div>
                    </LabeledText>
                  </Stack.Item>

                  {healthInsurance.familyNumber && (
                    <Stack.Item>
                      <LabeledText
                        noSemicolon
                        label={PatientEntitlementsLabels.FamilyNumber}
                        styles={labelStyle}
                      >
                        <div>{healthInsurance.familyNumber}</div>
                      </LabeledText>
                    </Stack.Item>
                  )}

                  {hfundexpiry && (
                    <Stack.Item>
                      <LabeledText
                        noSemicolon
                        label={PatientEntitlementsLabels.ExpiryDate}
                        styles={
                          patient.healthInsuranceIsExpired ||
                          patient.healthInsuranceWillExpire
                            ? expiryLabelStyle
                            : labelStyle
                        }
                      >
                        <div>{hfundexpiry}</div>
                      </LabeledText>
                    </Stack.Item>
                  )}
                </Stack>
              </Stack>
            ) : null}

            {dva ? (
              <Stack styles={{ root: { padding: "0 10px" } }}>
                <Stack.Item styles={{ root: { marginBottom: 8 } }}>
                  <LabeledText
                    noSemicolon
                    label={PatientEntitlementsLabels.DVA}
                    styles={descriptionTitleStyle}
                  />
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 32 }}>
                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.CardNumber}
                      styles={{
                        text: {
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          minWidth: 120
                        }
                      }}
                    >
                      <div>{dva.number}</div>
                    </LabeledText>
                  </Stack.Item>
                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.Colour}
                      styles={labelStyle}
                    >
                      <div>
                        <DVACardColourText code={dva.cardColor} />
                      </div>
                    </LabeledText>
                  </Stack.Item>

                  {dva.disability && (
                    <Stack.Item>
                      <LabeledText
                        noSemicolon
                        label={PatientEntitlementsLabels.Disability}
                        styles={labelStyle}
                      >
                        <div>{dva.disability}</div>
                      </LabeledText>
                    </Stack.Item>
                  )}
                </Stack>
              </Stack>
            ) : null}

            {csc ? (
              <Stack styles={{ root: { padding: "0 10px" } }}>
                <Stack.Item styles={{ root: { marginBottom: 8 } }}>
                  <LabeledText
                    noSemicolon
                    label={PatientEntitlementsLabels.CSC}
                    styles={descriptionTitleStyle}
                  />
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 32 }}>
                  <Stack.Item>
                    <LabeledText
                      noSemicolon
                      label={PatientEntitlementsLabels.Number}
                      styles={labelStyle}
                    >
                      <div>{cscFormat(csc.number)}</div>
                    </LabeledText>
                  </Stack.Item>

                  {cscstartdate && (
                    <Stack.Item>
                      <LabeledText
                        noSemicolon
                        label={PatientEntitlementsLabels.StartDate}
                        styles={labelStyle}
                      >
                        <div>{cscstartdate}</div>
                      </LabeledText>
                    </Stack.Item>
                  )}

                  {cscexpiry && (
                    <Stack.Item>
                      <LabeledText
                        noSemicolon
                        label={PatientEntitlementsLabels.Expiry}
                        styles={
                          patient.cscIsExpired || patient.cscWillExpire
                            ? expiryLabelStyle
                            : labelStyle
                        }
                      >
                        <div>{cscexpiry}</div>
                      </LabeledText>
                    </Stack.Item>
                  )}
                </Stack>
              </Stack>
            ) : null}
          </>
        ) : (
          <NoDetailsButton
            sectionId={PatientCardIds.patientEntitlements}
            actionTitle="Add an entitlement"
            noPermissionText="No entitlements"
            secondaryText={
              country === Country.Australia
                ? "Medicare, Health fund, DVA"
                : "Community Services Card"
            }
            onHandleEditModal={onHandleEditModal}
          />
        )}
      </Stack>
    );
  }
);
