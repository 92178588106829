import { observer } from "mobx-react-lite";
import React, { useContext, useRef } from "react";
import { Field } from "react-final-form";

import { Heading, IButtonProps, ITag } from "@bps/fluent-ui";
import {
  ReasonForVisitClinicalDataDto,
  RFV_CD_TYPE_CODE_OTHER,
  RFV_CD_TYPE_CODE_OTHER_TEXT
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ReasonForVisitContext } from "@modules/clinical/screens/context/ReasonForVisitContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { ReasonForVisitFormValues } from "./ReasonForVisitModal.types.ts";
import { ReasonForVisitPickerField } from "./ReasonForVisitPickerField.tsx";
import { ReasonForVisitValidator } from "./ReasonForVisitValidator.ts";

const nameOf = nameOfFactory<ReasonForVisitFormValues>();
interface ReasonForVisitModalProps {
  onSubmitted?: () => void;
  onCancelled?: () => void;
  title?: string;
  submitButtonProps?: IButtonProps;
}

const validator = new ReasonForVisitValidator();

export const ReasonForVisitModal: React.FC<ReasonForVisitModalProps> = observer(
  props => {
    const {
      mergeRFV,
      isReasonForVisitModalVisible,
      closeReasonForVisitDialog,
      clinicalRecord
    } = useContext(ReasonForVisitContext);

    const { clinical } = useStores();

    const selectedItems = useRef<ITag[]>();

    if (!clinicalRecord || !isReasonForVisitModalVisible) return null;

    const reasonForVisits =
      clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits;

    const initialValues = {
      reasonForVisits,
      reasonForVisitClinicalDataKeys: reasonForVisits
        ?.filter(x => !x.isDeleted)
        .map(rfv => `${rfv.code}.${rfv.originalText}`),
      reasonForVisitClinicalDataOriginalText: reasonForVisits?.find(
        rfv => rfv.code === RFV_CD_TYPE_CODE_OTHER
      )?.originalText
    };

    const addOrUpdateReasonForVisitsRequest = (
      values: Partial<ReasonForVisitFormValues>
    ) => {
      const updatedReasonForVisit: ReasonForVisitClinicalDataDto = {
        reasonForVisits:
          values.reasonForVisitClinicalDataKeys?.map(rv => {
            const code = rv.split(".")[0];
            const originalText =
              rv === RFV_CD_TYPE_CODE_OTHER &&
              values.reasonForVisitClinicalDataOriginalText
                ? values.reasonForVisitClinicalDataOriginalText
                : selectedItems?.current?.find(x => x.key === rv)?.name ??
                  values?.reasonForVisits?.find(x => x.code === code)
                    ?.originalText ??
                  RFV_CD_TYPE_CODE_OTHER_TEXT;

            const item = clinical.getTerminologyFromMap(
              clinical.getTerminologyKey(code, originalText)
            );

            const existingItem = values?.reasonForVisits?.find(
              x => x.code === rv
            );

            return {
              code,
              originalText,
              codeSystem: item?.codeSystem ?? existingItem?.codeSystem,
              version: item?.version ?? existingItem?.version
            };
          }) ?? []
      };

      // passing updatedReasonForVisit to reasonForVisits as we need to remove the last element in patch
      const reasonForVisit = updatedReasonForVisit;

      if (clinicalRecord.openEncounter) {
        mergeRFV(reasonForVisit);
        return reasonForVisit;
      }
      return reasonForVisit;
    };

    const onSubmit = async (values: Partial<ReasonForVisitFormValues>) => {
      const reasonForVisit = addOrUpdateReasonForVisitsRequest(values);

      await clinicalRecord.saveClinicalData({
        reasonForVisit
      });

      closeReasonForVisitDialog();

      if (props.onSubmitted) {
        props.onSubmitted();
      } else {
        const encounterId = clinicalRecord.openEncounter?.id;
        await clinicalRecord.close(encounterId);
      }
    };

    const onCancel = async () => {
      closeReasonForVisitDialog();
      if (props.onCancelled) {
        props.onCancelled();
      }
    };

    const getTitle = () => {
      if (props.title) {
        return props.title;
      }

      return "Reason for consult";
    };

    const getSubmitButtonMenuProps = () => {
      if (props.submitButtonProps) {
        return props.submitButtonProps;
      }

      return {
        text: "Save",
        iconProps: { iconName: "Save" }
      };
    };

    return (
      <SubmissionFormDialog
        dialogName="Encounter form - reason for consult dialog"
        initialValues={initialValues}
        onSubmit={onSubmit}
        dialogProps={{
          onDismiss: onCancel,
          minWidth: 600,
          maxWidth: 3000,
          dialogContentProps: {
            title: <Heading variant="modal-heading">{getTitle()}</Heading>
          }
        }}
        buttonsProps={{
          submitButtonProps: getSubmitButtonMenuProps()
        }}
        validate={validator.validate}
      >
        {() => (
          <Fieldset
            verticalAlign="space-between"
            styles={{
              root: { minHeight: 200, maxHeight: 600, width: 800 }
            }}
            tokens={{ childrenGap: 16 }}
          >
            <ReasonForVisitPickerField
              required
              codedFields={initialValues.reasonForVisits?.map(p => {
                return {
                  code: `${p.code}.${p.originalText}`,
                  text: p.originalText
                };
              })}
              inputProps={{ autoFocus: true, width: 300 }}
              name={nameOf("reasonForVisitClinicalDataKeys")}
              onChange={(ids, items) => {
                selectedItems.current = items;
              }}
              label={undefined}
            />

            <Field name={nameOf("reasonForVisitClinicalDataKeys")}>
              {({ input }) =>
                input.value.includes(RFV_CD_TYPE_CODE_OTHER) && (
                  <TextInputField
                    required
                    name={nameOf("reasonForVisitClinicalDataOriginalText")}
                    label="Other reason for consult"
                    maxLength={100}
                    styles={{ root: { marginTop: 16 } }}
                  />
                )
              }
            </Field>
          </Fieldset>
        )}
      </SubmissionFormDialog>
    );
  }
);
