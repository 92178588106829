import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { InboxScreenLabels } from "@modules/inbox/screens/inbox/Inbox.types.ts";
import { PrintContext } from "@ui-components/printing/Print.tsx";

export const IncomingReportsButtons = observer(() => {
  const print = useContext(PrintContext);

  return (
    <DefaultButton iconProps={{ iconName: "Print" }} onClick={print.print}>
      {InboxScreenLabels.Print}
    </DefaultButton>
  );
});
