import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  areTimesEqualField,
  isBeforeTimeField,
  Length,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { OccurrenceFormValues } from "./OccurrenceFormValues.ts";

const isStartAndEndTimeNonEmpty = (val: string, values: OccurrenceFormValues) =>
  !!(values.endTime && values.startTime);

export class OccurrenceFormValidator extends Validator<OccurrenceFormValues> {
  constructor() {
    super();

    this.forField(nameof("calendarEventType"), [required()]);
    this.forField(nameof("purpose"), maxLength(Length.long));
    this.forField(nameof("providerId"), [required()]);

    this.forField(nameof("endTime"), [
      required(),
      predicate(
        isStartAndEndTimeNonEmpty,
        isBeforeTimeField(
          "End time must not be before start time.",
          nameof("startTime")
        ),
        areTimesEqualField(
          "End time must not be equal to start time.",
          nameof("startTime")
        )
      )
    ]);

    this.forField(nameof("startDate"), [required()]);
    this.forField(nameof("startTime"), [required()]);
    this.forField(nameof("endTime"), [required()]);

    this.forField(nameof("endTime"), [
      isBeforeTimeField(
        "End time must not be before start time.",
        nameof("startTime")
      )
    ]);

    this.forField("endTime", [
      predicate(
        (val: string, event: OccurrenceFormValues) =>
          event.endTime && event.startTime
            ? event.endTime === event.startTime
            : false,
        () => "End time must not be equal to start time."
      )
    ]);
  }
}
