import { observer } from "mobx-react-lite";
import { FC } from "react";

import { SideRailMenuItem, Stack } from "@bps/fluent-ui";
import { SOTAPSectionText } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import { useSOTAPFollowOnFormContext } from "../context/SOTAPFollowOnFormContext.tsx";
import { OutcomeMeasuresCard } from "../outcome-measure/OutcomeMeasuresCard.tsx";
import { SotapMskId, SotapMskLabel } from "../SOTAP.constants.ts";
import { SOTAPFormWrapper } from "../SOTAPFormWrapper.tsx";
import { FollowUpNotesCard } from "./FollowUpNotesCard.tsx";

export const SOTAPFollowOnSubjectiveContent: FC = observer(() => {
  const { currentSection } = useSOTAPFollowOnFormContext();
  const { clinicalRecord } = usePatientRecordScreenContext();
  const subjectiveMenuItems: SideRailMenuItem[] = [
    {
      text: SotapMskLabel.FollowUpNotes,
      id: SotapMskId.FollowUpNotes
    },
    {
      text: SotapMskLabel.OutcomeMeasure,
      id: SotapMskId.OutcomeMeasure
    }
  ];

  const isDischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();

  return (
    <Stack
      styles={{
        root: {
          height: "100%",
          display:
            currentSection !== SOTAPSectionText.Subjective ? "none" : "flex"
        }
      }}
    >
      <SOTAPFormWrapper sideRailMenuItems={subjectiveMenuItems}>
        <FollowUpNotesCard />
        <OutcomeMeasuresCard
          hideAddToolsButton={isDischargeInProgressOrCompleted}
          isViewOnly={isDischargeInProgressOrCompleted}
          episodeOfCareId={clinicalRecord.episodeOfCare?.id}
        />
      </SOTAPFormWrapper>
    </Stack>
  );
});
