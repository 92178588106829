import { observer } from "mobx-react-lite";
import { FunctionComponent, memo, useEffect } from "react";

import { getScreenSelector, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { useBookingFilterTrackEvent } from "../../appInsights/useBookingFilterTrackEvent.ts";
import { AddToGroupApptDialog } from "./components/add-to-group-appt-dialog/AddToGroupApptDialog.tsx";
import { AppointmentsFilterOverlay } from "./components/appointments-filter/AppointmentsFilterOverlay.tsx";
import { BookingCalendarEventController } from "./components/booking-calendar-event/BookingCalendarEventController.tsx";
import { BookingCalendarContent } from "./components/BookingCalendarContent.tsx";
import { BookingCalendarFilters } from "./components/BookingCalendarFilters.tsx";
import {
  BASIC_PADDING,
  FILTERS_MIN_WIDTH
} from "./components/BookingCalendarFilters.types.ts";
import { BookingCalendarLegend } from "./components/BookingCalendarLegend.tsx";
import {
  BookingCalendarNavigation,
  LARGE_SCREEN_BREAKPOINT
} from "./components/BookingCalendarNavigation.tsx";
import { CancelAttendeeAppointmentDialog } from "./components/cancel-calendar-event-dialog/CancelAttendeeAppointmentDialog.tsx";
import { CancelCalendarEventDialog } from "./components/cancel-calendar-event-dialog/CancelCalendarEventDialog.tsx";
import { MeetingDialog } from "./components/meeting-dialog/MeetingDialog.tsx";
import { OccurrenceDialog } from "./components/occurrence-dialog/OccurrenceDialog.tsx";
import PatientMatchDialog from "./components/patient-match-dialog/PatientMatchDialog.tsx";
import { RecurrenceDialog } from "./components/recurrence-dialog/RecurrenceDialog.tsx";
import { SendReminderDialog } from "./components/send-reminder-dialog/SendReminderDialog.tsx";
import { UnavailableDialog } from "./components/unavailable-dialog/UnavailableDialog.tsx";
import {
  BookingCalendarScreenContext,
  useBookingCalendarScreenContext
} from "./context/BookingCalendarScreenContext.tsx";
import { BookingCalendarScreenModel } from "./context/BookingCalendarScreenModel.ts";

let setNextDateId: ReturnType<typeof setTimeout>;

const BookingCalendarScreenBase: FunctionComponent = observer(() => {
  const { booking, notification } = useStores();
  const {
    appointmentsFilter,
    setAppointmentsFilter,
    setToday,
    subscriptions,
    hasLoadingError,
    hideLeftSide,
    hideLegend,
    mergeWithCurrentResults,
    onCalendarEventAttendeeCancellation,
    onCalendarEventUpdate,
    loadInitialData
  } = useBookingCalendarScreenContext();

  useBookingFilterTrackEvent();
  const {
    ui: { showPatientMatchingDialog }
  } = booking;

  useEffect(() => {
    // this controls the orange line that designates "now" on the calendar
    const interval = setInterval(setToday, 120000); // updates every 2 minutes
    return () => clearInterval(interval);
  }, [setToday]);

  useEffect(() => {
    const calendarEvent = booking.lastAddedCalendarEventId
      ? booking.calendarEventsMap.get(booking.lastAddedCalendarEventId)
      : undefined;

    if (calendarEvent) {
      mergeWithCurrentResults(calendarEvent);
    }
  }, [
    booking.calendarEventsMap,
    booking.lastAddedCalendarEventId,
    mergeWithCurrentResults
  ]);

  useEffect(() => {
    if (hasLoadingError) {
      notification.error("An error occurred retrieving appointments");
    }
  }, [hasLoadingError, notification]);

  // COMPONENT MOUNTED
  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  // COMPONENT UNMOUNTED
  useEffect(
    () => {
      return () => {
        // clean setNextDay timeout
        clearTimeout(setNextDateId);

        // clean appointment filter if set, when unmount calendar
        appointmentsFilter && setAppointmentsFilter();

        // unsubscribe events
        subscriptions.map(s => booking.hub.unsubscribe(s.event, s.handler));
      };
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    booking.ui.lastUpdatedCalendarEventData &&
      onCalendarEventUpdate(booking.ui.lastUpdatedCalendarEventData);
  }, [booking.ui.lastUpdatedCalendarEventData, onCalendarEventUpdate]);

  const calendarEvent = booking.ui.cancelCalendarEventId
    ? booking.calendarEventsMap.get(booking.ui.cancelCalendarEventId!)
    : undefined;

  return (
    <>
      <Stack horizontal styles={{ root: { height: "100%" } }}>
        <BookingCalendarFilters />

        <Stack
          grow
          tokens={{ childrenGap: 16 }}
          styles={{
            root: {
              overflow: "hidden",
              marginLeft: 16,
              selectors: {
                [getScreenSelector(0, LARGE_SCREEN_BREAKPOINT)]: {
                  // ⚠️ Hack to move BookingCalendarNavigation left for laptop screen width,
                  // previously we rendered two versions of layouts, now one only with css handlers
                  // happy for another solution if someone comes up with. Ilya S.
                  marginLeft: !hideLeftSide
                    ? -Math.abs(FILTERS_MIN_WIDTH + BASIC_PADDING) + 16
                    : 16
                }
              }
            }
          }}
        >
          <BookingCalendarNavigation />
          <Stack
            grow
            tokens={{ childrenGap: 16 }}
            styles={{
              root: {
                overflow: "hidden",
                selectors: {
                  [getScreenSelector(0, LARGE_SCREEN_BREAKPOINT)]: {
                    // ⚠️ Hack to move BookingCalendarNavigation left for laptop screen width,
                    // previously we rendered two versions of layouts, now one only with css handlers
                    // happy for another solution if someone comes up with. Ilya S.
                    marginLeft: !hideLeftSide
                      ? Math.abs(FILTERS_MIN_WIDTH + BASIC_PADDING)
                      : 0
                  }
                }
              }
            }}
          >
            <BookingCalendarContent />
            {!hideLegend && <BookingCalendarLegend />}
          </Stack>
        </Stack>
      </Stack>

      {/*/ Dialogs and overlays  /*/}
      <OccurrenceDialog />
      <RecurrenceDialog />
      <SendReminderDialog />
      <MeetingDialog />
      <UnavailableDialog />
      <AddToGroupApptDialog />
      <When permission={Permission.PatientMatchAllowed}>
        {showPatientMatchingDialog && <PatientMatchDialog />}
      </When>
      {booking.ui.showCancelCalendarEventDialog && calendarEvent && (
        <CancelCalendarEventDialog />
      )}
      {booking.ui.showAttendeeCancellationDialog &&
        calendarEvent &&
        booking.ui.cancelCalendarEventAttendeeId && (
          <DataFetcher<{ providerName: string; patientName: string }>
            fetch={async root => {
              const { practice, core } = root;

              const [patientName, providerName] = await Promise.all([
                booking.ui.cancelCalendarEventAttendeeId
                  ? (
                      await practice.getContact(
                        booking.ui.cancelCalendarEventAttendeeId
                      )
                    ).nameWithTitle
                  : "",
                (calendarEvent.user?.id &&
                  (await core.getUser(calendarEvent.user?.id)).fullName) ||
                  ""
              ]);
              return { providerName, patientName };
            }}
          >
            {({ providerName, patientName }) => (
              <CancelAttendeeAppointmentDialog
                handleCancellation={async inputValues => {
                  const { cancellationReasonId, cancellationText } =
                    inputValues;
                  await onCalendarEventAttendeeCancellation({
                    calendarEvent,
                    cancellationReasonId,
                    cancellationText
                  });
                }}
                closeDialog={() => {
                  booking.ui.setShowAttendeeCancellationDialog(false);
                }}
                calendarEvent={calendarEvent}
                patientName={patientName}
                providerName={providerName}
              />
            )}
          </DataFetcher>
        )}
      <BookingCalendarEventController />
      {appointmentsFilter && <AppointmentsFilterOverlay />}
    </>
  );
});

const BookingCalendarScreenWithProvider = memo(() => {
  const rootStore = useStores();
  return (
    <BookingCalendarScreenContext.Provider
      value={new BookingCalendarScreenModel(rootStore)}
    >
      <BookingCalendarScreenBase />
    </BookingCalendarScreenContext.Provider>
  );
});

export const BookingCalendarScreen = withFetch(
  ({ booking, practice }) => [
    booking.ref.appointmentStatuses.load(),
    practice.ref.invoiceStatuses.load(),
    booking.ref.calendarEventTypes.load(),
    booking.loadAppointmentTypes()
  ],
  BookingCalendarScreenWithProvider
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default BookingCalendarScreen;
