import { action, computed, observable, runInAction } from "mobx";

import {
  ReasonForVisitClinicalDataDto,
  RFV_CD_TYPE_CODE_OTHER,
  RFV_CD_TYPE_CODE_OTHER_TEXT
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { ConsultDetailDialogValues } from "../patient-record/components/clinical-form/ConsultDetailDialog.tsx";

export class ReasonForVisitHelper {
  constructor(private root: IRootStore) {}

  private get clinical() {
    return this.root.clinical;
  }

  private get core() {
    return this.root.core;
  }

  @observable
  clinicalRecordId: string | undefined;

  @computed
  get clinicalRecord() {
    if (this.clinicalRecordId) {
      return this.clinical.multipleEncountersRecordsMap.get(
        this.clinicalRecordId
      );
    }
    return undefined;
  }

  get isReasonForVisitModalVisible() {
    return !!this.clinicalRecordId;
  }

  @action openReasonForVisitDialog = (id: string) => {
    this.clinicalRecordId = id;
  };

  @action closeReasonForVisitDialog = () => {
    this.clinicalRecordId = undefined;
    this.clinical.ui.resetClosingClinicalRecordId();
  };

  mergeRFV = (reasonForVisit: ReasonForVisitClinicalDataDto | undefined) => {
    if (!this.clinicalRecord) return;
    if (reasonForVisit) {
      reasonForVisit.eTag =
        this.clinicalRecord.clinicalData?.reasonForVisit?.eTag;

      reasonForVisit.reasonForVisits.forEach(x => {
        const matchedRFV =
          this.clinicalRecord!.clinicalData!.reasonForVisit?.reasonForVisits.find(
            y => y.code === x.code
          );
        if (matchedRFV) {
          x.id = matchedRFV.id;
          x.createLog = matchedRFV.createLog;
          x.updateLog = matchedRFV.updateLog;
        }
      });
    }

    this.clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits.forEach(
      x => {
        if (!reasonForVisit?.reasonForVisits.some(y => y.code === x.code)) {
          runInAction(() => {
            x.isDeleted = true;
            reasonForVisit?.reasonForVisits.push(x);
          });
        }
      }
    );
  };

  addOrUpdateReasonForVisitsRequest = (
    values: Partial<ConsultDetailDialogValues>
  ) => {
    const reasonForVisits =
      this.clinicalRecord?.clinicalData?.reasonForVisit?.reasonForVisits;

    const updatedReasonForVisit: ReasonForVisitClinicalDataDto = {
      reasonForVisits:
        values.reasonForVisit?.map(rv => {
          const code = rv.split(".")[0];
          const text = rv.split(".")[1];
          const originalText =
            rv === RFV_CD_TYPE_CODE_OTHER && values.otherText
              ? `${values.otherText}`
              : reasonForVisits?.find?.(
                  x => `${x.code}.${x.originalText}` === rv
                )?.originalText ??
                text ??
                RFV_CD_TYPE_CODE_OTHER_TEXT;

          const item = this.clinical.getTerminologyFromMap(
            this.clinical.getTerminologyKey(code, originalText)
          );

          return {
            code,
            originalText,
            codeSystem: item?.codeSystem,
            version: item?.version
          };
        }) ?? []
    };

    // passing updatedReasonForVisit to reasonForVisits as we need to remove the last element in patch
    const reasonForVisit = updatedReasonForVisit;

    if (this.clinicalRecord?.openEncounter) {
      this.mergeRFV(reasonForVisit);
      return reasonForVisit;
    }
    return reasonForVisit;
  };

  onSubmitReasonForVisitValue = async (
    values: Partial<ConsultDetailDialogValues>
  ) => {
    const reasonForVisit = this.addOrUpdateReasonForVisitsRequest(values);
    await this.clinicalRecord?.saveClinicalData({
      reasonForVisit
    });
  };
}
