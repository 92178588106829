import { observer } from "mobx-react-lite";
import { useContext, useRef } from "react";
import { useForm, useFormState } from "react-final-form";

import { FontWeights, Spinner, Stack, Text, useTheme } from "@bps/fluent-ui";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { CancelAttendeeAppointmentDialog } from "@modules/booking/screens/booking-calendar/components/cancel-calendar-event-dialog/CancelAttendeeAppointmentDialog.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { GroupAttendee } from "./GroupAttendee.tsx";
import {
  getActiveAttendees,
  getSpacesRemaining,
  removeUnsavedAttendees
} from "./GroupAttendeesHelper.ts";

type GroupAttendeesListProps = { contacts: Contact[] };
export const GroupAttendeesList: React.FunctionComponent<GroupAttendeesListProps> =
  observer(({ contacts }) => {
    const {
      attendeeCancellationDialog,
      setAttendeeCancellationDialog,
      handleAttendeeCancellation,
      calendarEvent
    } = useContext(AppointmentFormContext);

    const theme = useTheme();

    const { values } = useFormState<AppointmentFormValues>({
      subscription: { values: true }
    });

    const activeAttendees = getActiveAttendees(values.groupAttendees || []);
    const spacesRemaining = getSpacesRemaining(
      values.maxParticipants ?? 0,
      activeAttendees.length
    );

    const attendeeId = useRef<string | undefined>(undefined);
    const form = useForm<AppointmentFormValues>();
    const { booking, practice } = useStores();

    const removeFromList = (contactId: string) => {
      const groupAttendeeIds = [...(values.groupAttendeeIds || [])];
      if (groupAttendeeIds.findIndex(id => id === contactId) >= 0) {
        const newGroupAttendeeIds = groupAttendeeIds.filter(
          id => id !== contactId
        );
        form.change("groupAttendeeIds", newGroupAttendeeIds);
      }
    };

    const onRemoveAttendee = (contact: Contact) => {
      const newAttendeeId = values.groupAttendeeIds?.find(
        id => id === contact.id
      );

      if (!newAttendeeId) {
        setAttendeeCancellationDialog(true);
        attendeeId.current = contact.id;
      } else {
        removeFromList(contact.id);
        const groupAttendees = removeUnsavedAttendees(
          [contact.id],
          values.groupAttendees || []
        );

        form.change("groupAttendees", groupAttendees);
        if (values.waitingListIds) {
          const wlRecordId = values.waitingListIds?.find(id => {
            const wlRecord = booking.waitingListsMap.get(id);
            return wlRecord?.contact?.attendeeId === contact.id;
          });

          if (wlRecordId) {
            // The attendee has been added from the waiting list, but is being removed before saving
            // Avoid deleting the waiting list record on save
            form.change(
              "waitingListIds",
              values.waitingListIds.filter(id => id !== wlRecordId)
            );
          }
        }
      }
    };

    const loadPatientNotices = async () => {
      const patientIds = activeAttendees.map(x => x.attendeeId);
      await Promise.all([
        practice.getPatientNoticesByArgs({
          patientIds
        }),
        practice.loadSystemNotices(patientIds ?? [])
      ]);
    };
    return (
      <>
        <Stack
          verticalFill
          styles={{
            root: {
              flexGrow: 1,
              marginTop: "8px",
              overflow: "auto",
              padding: "0 5px"
            }
          }}
          tokens={{ childrenGap: 10 }}
        >
          <Stack.Item styles={{ root: { textAlign: "right" } }}>
            Spaces remaining:
            <Text
              styles={{
                root: {
                  color:
                    spacesRemaining <= 0
                      ? theme.semanticColors.errorText
                      : theme.semanticColors.inputText,
                  fontWeight: FontWeights.semibold
                }
              }}
            >
              {` ${spacesRemaining}`}
            </Text>
          </Stack.Item>
          <DataFetcher
            fetch={async () => await loadPatientNotices()}
            fallback={<Spinner />}
            refetchId={activeAttendees.length}
          >
            {() => {
              return contacts.map(contact => (
                <GroupAttendee
                  key={`group-${contact.id}`}
                  contact={contact}
                  onRemove={contact => onRemoveAttendee(contact)}
                />
              ));
            }}
          </DataFetcher>
        </Stack>

        {attendeeCancellationDialog && calendarEvent && (
          <DataFetcher<{ providerName: string; patientName?: string }>
            fetch={async root => {
              const { practice, core } = root;

              const patientName = attendeeId.current
                ? (await practice.getContact(attendeeId.current)).nameWithTitle
                : undefined;

              const providerName = (await core.getUser(values.providerId))
                .fullName;

              return {
                providerName,
                patientName
              };
            }}
          >
            {({ providerName, patientName }) => (
              <CancelAttendeeAppointmentDialog
                handleCancellation={async inputValues => {
                  await handleAttendeeCancellation(
                    inputValues,
                    {
                      values,
                      change: form.change
                    },
                    attendeeId.current
                  );
                  if (attendeeId.current) {
                    removeFromList(attendeeId.current);
                  }
                }}
                closeDialog={() => {
                  setAttendeeCancellationDialog(false);
                }}
                calendarEvent={calendarEvent}
                patientName={patientName || ""}
                providerName={providerName}
              />
            )}
          </DataFetcher>
        )}
      </>
    );
  });
