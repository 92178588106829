import React from "react";

import {
  FontIcon,
  FontWeights,
  IconButton,
  PersonaSize,
  Separator,
  Stack,
  Text,
  TooltipHost,
  useResizeElementObserver,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { getAgeString } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import {
  formatCommunication,
  isPhone
} from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { EncounterTimer } from "../EncounterTimer.tsx";
import { PatientHeaderTags } from "./PatientHeaderTags.tsx";

const SHORTENED_WIDTH = 750;

export const HorizontalPersonaDetailsBase: React.FC = () => {
  const {
    practice: {
      ui: { showEditContact }
    },
    clinical,
    core
  } = useStores();

  const { setElement, resizeObserverEntry, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const theme = useTheme();
  const onHandleEditModal = (cardId: string, id: string) => () => {
    showEditContact(cardId, id);
  };

  const { clinicalRecord } = usePatientRecordScreenContext();

  const onClinicalDataLinkClick =
    (clinicalDataType: ClinicalDataType) => () => {
      clinical.ui.setPatientClinicalContent({ type: clinicalDataType });
    };

  const shortened = SHORTENED_WIDTH >= panelWidth;

  return (
    <div
      ref={r => {
        if (r && !element) {
          setElement(r);
        }
      }}
      style={{ height: "100%" }}
    >
      <ContactFetcher contactId={clinicalRecord.id}>
        {patient => {
          const {
            birthDate,
            dateOfDeath,
            primaryCommunication,
            id,
            nameWithTitle,
            firstName,
            lastName,
            titleRef,
            nickName
          } = patient;

          const ageDescription =
            birthDate &&
            `${getAgeString(
              birthDate,
              dateOfDeath
            )} (${birthDate.toDayDefaultFormat()})`;

          const hasPreferredComms = isPhone(core, primaryCommunication);

          const primaryComms =
            primaryCommunication &&
            formatCommunication(primaryCommunication, core).value;

          const intakeOptions = clinical.ref.intakeStatuses?.keyTextValues;

          const patientNameWithTitle = () => {
            if (!nickName) return nameWithTitle;
            return (
              <>
                {titleRef?.text && <>{titleRef?.text} </>}
                {nickName}{" "}
                <span style={{ fontWeight: FontWeights.regular }}>
                  ({firstName})
                </span>{" "}
                {lastName}
              </>
            );
          };

          return (
            <Stack horizontal styles={{ root: { height: "100%" } }}>
              <Stack
                horizontal
                styles={{
                  root: {
                    paddingLeft: 8,
                    paddingRight: 8
                  }
                }}
              >
                <Persona
                  id={id}
                  contactType={patient.type}
                  imageInitials={patient.initials}
                  imageUrl={patient.profilePictureUrl}
                  size={PersonaSize.size48}
                  styles={{
                    details: {
                      padding: 2
                    }
                  }}
                />

                <When
                  permission={[
                    Permission.ContactRead,
                    Permission.ContactWrite,
                    Permission.PatientRead,
                    Permission.PatientWrite
                  ]}
                  permissionOperator="and"
                >
                  <TooltipHost content="Edit">
                    <IconButton
                      iconProps={{ iconName: "Edit" }}
                      styles={{
                        root: {
                          padding: 0,
                          width: 20,
                          height: 20,
                          cursor: "pointer"
                        },
                        icon: {
                          margin: 0,
                          fontSize: theme.fonts.medium.fontSize
                        }
                      }}
                      onClick={onHandleEditModal(
                        PatientCardIds.patientHeader,
                        id
                      )}
                    />
                  </TooltipHost>
                </When>
              </Stack>

              <Stack
                styles={{
                  root: {
                    paddingLeft: 8,
                    padding: 0
                  }
                }}
                grow
              >
                <Stack
                  horizontal={shortened}
                  verticalAlign="start"
                  horizontalAlign="space-between"
                  styles={{
                    root: {
                      marginBottom: 0,
                      marginTop: 0,
                      padding: 0,
                      paddingBottom: 8
                    }
                  }}
                >
                  <Stack horizontal={!shortened}>
                    <Text variant="mediumPlus" bold>
                      {patientNameWithTitle()}
                    </Text>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 8 }}
                      styles={{ root: { paddingLeft: 8 } }}
                    >
                      <Stack horizontal verticalAlign="center">
                        <FontIcon
                          iconName="CalendarMirrored"
                          styles={{ root: { paddingRight: 4 } }}
                        />

                        <Navigate
                          onClick={onHandleEditModal(
                            PatientCardIds.patientHeader,
                            id
                          )}
                        >
                          {ageDescription || "No DOB recorded"}
                        </Navigate>
                      </Stack>
                      <Separator vertical styles={{ root: { height: 16 } }} />
                      <Stack horizontal verticalAlign="center">
                        <FontIcon
                          iconName="Phone"
                          styles={{ root: { paddingRight: 4 } }}
                        />
                        <Navigate
                          onClick={onHandleEditModal(
                            PatientCardIds.contactMethods,
                            id
                          )}
                        >
                          {hasPreferredComms
                            ? primaryComms
                            : "Phone not recorded"}
                        </Navigate>
                      </Stack>
                    </Stack>
                  </Stack>
                  {shortened && (
                    <Stack
                      styles={{
                        root: { paddingRight: 8 }
                      }}
                    >
                      <EncounterTimer />
                    </Stack>
                  )}
                </Stack>
                <Stack
                  styles={{
                    root: {
                      marginTop: -5
                    }
                  }}
                >
                  <PatientHeaderTags
                    onClinicalDataLinkClick={onClinicalDataLinkClick}
                    intakeOptions={intakeOptions}
                    clinicalRecord={clinicalRecord}
                    onHandleEditModal={onHandleEditModal}
                    patient={patient}
                    updatedClinicalDesign
                  />
                </Stack>
              </Stack>
              {!shortened && (
                <Stack
                  grow
                  styles={{
                    root: { paddingRight: 8 }
                  }}
                >
                  <EncounterTimer />
                </Stack>
              )}
            </Stack>
          );
        }}
      </ContactFetcher>
    </div>
  );
};

export const HorizontalPersonaDetails = withFetch(
  x => x.clinical.ref.intakeStatuses.load(),
  HorizontalPersonaDetailsBase
);
