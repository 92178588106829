import { FC } from "react";
import { useForm } from "react-final-form";

import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

interface AddressTypeCheckboxFieldsProps {
  parentName: string;
  index?: number;
}

export const AddressTypeCheckboxFields: FC<AddressTypeCheckboxFieldsProps> = ({
  parentName,
  index
}) => {
  const name = index !== undefined ? `${parentName}[${index}]` : parentName;

  const form = useForm();

  return (
    <>
      <CheckboxGroupField
        name={`${name}.types`}
        horizontal
        options={[
          { label: "Physical", value: AddressType.Physical },
          { label: "Postal", value: AddressType.Postal }
        ]}
      />
      <FieldSpy
        name={`${name}.types`}
        onChange={(value, allValues, previousValue) => {
          const address =
            index !== undefined
              ? allValues![parentName][index]
              : allValues![parentName];

          //If the Postal checkbox is unchecked and when user unchecks Physical checkboox as well,
          //then check the Postal checkbox and vice-versa. At least one checkbox should be checked.
          if (value && value.length === 0) {
            const types = previousValue?.includes(AddressType.Physical)
              ? [AddressType.Postal]
              : [AddressType.Physical];
            if (index !== undefined) {
              form.mutators.update(parentName, index, {
                ...address,
                types
              });
            } else {
              form.change(name, {
                ...address,
                types
              });
            }
          }
        }}
      />
    </>
  );
};
