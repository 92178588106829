import { PatientEventsData } from "app-shell/NavBar/PatientAppointmentSearch/PatientAppointmentsSearch.types.ts";
import { getPatientEventsData } from "app-shell/NavBar/PatientAppointmentSearch/utils.ts";
import React, { FunctionComponent } from "react";

import { FontIcon, Link, Spinner, Stack } from "@bps/fluent-ui";
import { DATE_FORMATS, TIME_FORMATS } from "@bps/utils";
import {
  CalendarEventStatus,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface NextAppointmentInfoLinkProps {
  calendarEvent: CalendarEvent;
}
export const NextAppointmentInfoLink: FunctionComponent<
  NextAppointmentInfoLinkProps
> = ({ calendarEvent }) => {
  const {
    booking,
    core: { getLocationName }
  } = useStores();

  const { contact, startDateTime, isPatientCalendarEvent, isGroupAppointment } =
    calendarEvent;

  if (!isPatientCalendarEvent || isGroupAppointment) return null;

  const fetchPatientsData = async (): Promise<PatientEventsData[]> => {
    if (contact) {
      const contactEvents = await booking.getCalendarEvents({
        statuses: [CalendarEventStatus.Confirmed],
        attendees: contact ? [contact.id] : undefined
      });

      return getPatientEventsData(contactEvents.results, [contact]);
    }
    return [];
  };

  const handleOnClick = (patientsData: PatientEventsData[]) => {
    booking.ui.showCalendarEventDialog({
      type: CalendarEventType.Appointment,
      id: patientsData[0].upcomingAppointment?.id
    });
  };

  return (
    <DataFetcher<PatientEventsData[]>
      fetch={fetchPatientsData}
      fallback={<Spinner styles={{ root: { height: 19 } }} />}
    >
      {(patientsData: PatientEventsData[]) => {
        if (!patientsData.length || !patientsData[0].upcomingAppointment) {
          return null;
        }

        const { upcomingAppointment } = patientsData[0];

        const isThisAppointment = !!(
          upcomingAppointment.startDateTime &&
          upcomingAppointment.startDateTime === startDateTime
        );
        if (isThisAppointment) {
          return null;
        }

        const nextApptButtonText = ` Next:
          ${upcomingAppointment.startDateTime.toFormat(
            DATE_FORMATS.LONG_DATE_WITH_DAY
          )}
          ${upcomingAppointment.startDateTime.toFormat(
            TIME_FORMATS.DEFAULT_TIME_FORMAT
          )}
          with ${upcomingAppointment.user?.name} at ${getLocationName(
            upcomingAppointment.orgUnitId
          )}`;

        return (
          <Link onClick={() => handleOnClick(patientsData)}>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <FontIcon iconName="AddEvent" />
              <Stack>{nextApptButtonText}</Stack>
            </Stack>
          </Link>
        );
      }}
    </DataFetcher>
  );
};
