import React from "react";

import {
  ChoiceGroup as FluentChoiceGroup,
  FontWeights,
  IStackStyles,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  ClinicalDataTypeLabel,
  QuestionnaireAnswerOptionDto,
  QuestionnaireItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";

import { ClinicalToolReferences } from "../ClinicalTool.types.ts";
import { ClinicalToolInformation } from "../shared-components/ClinicalToolInformation.tsx";
import {
  ClinicalToolAnswer,
  findClinicalToolAnswer
} from "../utils/ClinicalToolAnswerFinder.ts";

interface DASHModuleProps {
  questions: QuestionnaireItemDto[];
  answers?: ClinicalToolAnswer[];
}

export const DASHModule: React.FunctionComponent<DASHModuleProps> = ({
  questions,
  answers
}) => {
  const theme = useTheme();

  const findAnswer = (questionId: string): string => {
    let answerValue = "";
    if (answers) {
      const answer = findClinicalToolAnswer(questionId, answers);
      if (answer) answerValue = answer.answerValue!;
    }
    return answerValue;
  };

  const tableStyles: IStackStyles = {
    root: {
      display: "grid",
      gridTemplateColumns: "30% 14% 14% 14% 14% 14%",
      overflowY: "auto",
      paddingRight: 8
    }
  };

  const getBgColour = (index: number) => {
    return index % 2 === 0
      ? theme.palette.themeLighterAlt
      : theme.palette.white;
  };

  const getQuestionNumbers = (itemId: number) => {
    switch (itemId) {
      case 13:
      case 18:
        return 1;
      case 14:
      case 19:
        return 2;
      case 15:
      case 20:
        return 3;
      case 16:
      case 21:
        return 4;
      default: {
        return null;
      }
    }
  };

  const headingTextItems = [7, 8, 9, 11];

  const getItalicQuestionText = (text: string) => {
    const sentences = text.split(".");
    return `${sentences[0]}.`;
  };

  const getNormalQuestionText = (text: string) => {
    const sentences = text.split(".");
    return sentences[1];
  };

  return (
    <>
      <ClinicalToolInformation
        clinicalToolType={ClinicalDataTypeLabel.DASH11}
        paragraphs={ClinicalToolReferences.DASH11}
      />
      {questions.length > 4 ? (
        <Stack styles={tableStyles}>
          {/* empty header of first column */}
          <div />
          {/* render answer options from the first item, since they are the same */}
          {questions.length > 0 &&
            questions[0].answerOptions?.map(
              (option: QuestionnaireAnswerOptionDto) => (
                <Text
                  styles={{
                    root: {
                      textAlign: "center",
                      fontWeight: FontWeights.semibold
                    }
                  }}
                  key={option.value}
                >
                  {option.text}
                </Text>
              )
            )}

          {questions.map(item => (
            <React.Fragment key={item.id}>
              {headingTextItems.includes(item.id) && (
                <>
                  <div style={{ backgroundColor: getBgColour(item.id) }} />
                  {item.answerOptions?.map(
                    (option: QuestionnaireAnswerOptionDto) => (
                      <Text
                        styles={{
                          root: {
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 12,
                            textAlign: "center",
                            fontWeight: FontWeights.semibold,
                            backgroundColor: getBgColour(item.id)
                          }
                        }}
                        key={option.value}
                      >
                        {option.text}
                      </Text>
                    )
                  )}
                </>
              )}
              {/* render question */}
              {item.id === 9 || item.id === 10 ? (
                <Stack
                  id={item.id.toString()}
                  horizontal
                  tokens={{ childrenGap: 24 }}
                  styles={{
                    root: {
                      backgroundColor: getBgColour(item.id),
                      paddingTop: 12,
                      paddingBottom: 12
                    }
                  }}
                >
                  <Stack.Item styles={{ root: { paddingLeft: 8 } }}>
                    {item.id}
                  </Stack.Item>

                  <Stack.Item>
                    <Stack tokens={{ childrenGap: 16 }}>
                      <Stack.Item
                        styles={{
                          root: {
                            fontStyle: "italic"
                          }
                        }}
                      >
                        {getItalicQuestionText(item.text)}
                      </Stack.Item>
                      <Stack.Item>
                        {getNormalQuestionText(item.text)}
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                </Stack>
              ) : (
                <Stack
                  id={item.id.toString()}
                  horizontal
                  tokens={{ childrenGap: 24 }}
                  styles={{
                    root: {
                      backgroundColor: getBgColour(item.id),
                      paddingTop: 12,
                      paddingBottom: 12
                    }
                  }}
                >
                  <Stack.Item styles={{ root: { paddingLeft: 8 } }}>
                    {item.id}
                  </Stack.Item>
                  <Stack.Item>{item.text}</Stack.Item>
                </Stack>
              )}

              {/* render options */}
              {item.answerOptions?.map(option => (
                <Stack
                  grow
                  horizontalAlign="center"
                  verticalAlign="center"
                  key={`${item.id}_${option.value}`}
                  styles={{ root: { backgroundColor: getBgColour(item.id) } }}
                >
                  {!answers ? (
                    <ChoiceGroupField
                      size="large"
                      name={`items.q${item.id}`}
                      options={[
                        {
                          key: `${option.value}`,
                          value: option.value.toString(),
                          text: ""
                        }
                      ]}
                    />
                  ) : (
                    <FluentChoiceGroup
                      name={`items.q${item.id}`}
                      options={[
                        {
                          key: `${option.value}`,
                          value: option.value.toString(),
                          text: ""
                        }
                      ]}
                      disabled={true}
                      defaultSelectedKey={findAnswer(`${item.id}`)}
                    />
                  )}
                </Stack>
              ))}
            </React.Fragment>
          ))}
        </Stack>
      ) : (
        <Stack styles={tableStyles}>
          {/* empty header of first column */}
          <div />
          {/* render answer options from the first item, since they are the same */}
          {questions.length > 0 &&
            questions[0].answerOptions?.map(
              (item: QuestionnaireAnswerOptionDto) => (
                <Text
                  styles={{
                    root: {
                      textAlign: "center",
                      fontWeight: FontWeights.semibold
                    }
                  }}
                  key={item.value}
                >
                  {item.text}
                </Text>
              )
            )}

          {questions.map(item => (
            <React.Fragment key={item.id}>
              {/* render question */}
              <Stack
                id={item.id.toString()}
                horizontal
                tokens={{ childrenGap: 24 }}
                styles={{
                  root: {
                    backgroundColor: getBgColour(item.id + 1),
                    paddingTop: 12,
                    paddingBottom: 12
                  }
                }}
              >
                <Stack.Item styles={{ root: { paddingLeft: 8 } }}>
                  {getQuestionNumbers(item.id)}
                </Stack.Item>
                <Stack.Item>{item.text}</Stack.Item>
              </Stack>

              {/* render options with titles */}
              {item.answerOptions?.map(option => (
                <Stack
                  grow
                  horizontalAlign="center"
                  verticalAlign="center"
                  key={`${item.id}_${option.value}`}
                  styles={{
                    root: {
                      backgroundColor: getBgColour(item.id + 1)
                    }
                  }}
                >
                  {!answers ? (
                    <ChoiceGroupField
                      name={`items.q${item.id}`}
                      options={[
                        {
                          key: `${option.value}`,
                          value: option.value.toString(),
                          text: ""
                        }
                      ]}
                    />
                  ) : (
                    <FluentChoiceGroup
                      name={`items.q${item.id}`}
                      options={[
                        {
                          key: `${option.value}`,
                          value: option.value.toString(),
                          text: ""
                        }
                      ]}
                      disabled={true}
                      defaultSelectedKey={findAnswer(`${item.id}`)}
                    />
                  )}
                </Stack>
              ))}
            </React.Fragment>
          ))}
        </Stack>
      )}
    </>
  );
};
