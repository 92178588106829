import { LabeledText, LabeledTextGroup } from "@bps/fluent-ui";

import { LabeledTextFields } from "../types/claim.types.ts";
import { descProps } from "./PersonalDetailViewSection.tsx";

// will not render anything when a children value is not provided
// hides undefined data
export const ViewField: React.FC<LabeledTextFields> = ({
  labeledTexts,
  renderInGroup
}) => {
  const labeledTextsJSX = labeledTexts
    .map(lt =>
      !!lt.children ? <LabeledText key={String(lt.label)} {...lt} /> : null
    )
    .filter(x => x) as JSX.Element[];

  if (!labeledTextsJSX.length) {
    return null;
  }

  return !!renderInGroup ? (
    <LabeledTextGroup
      {...descProps}
      horizontalColumnCount={labeledTextsJSX.length}
    >
      {labeledTextsJSX}
    </LabeledTextGroup>
  ) : (
    <>{labeledTextsJSX}</>
  );
};
