import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { useField, useForm } from "react-final-form";

import { focusElement, mergeStyles, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import {
  Gender,
  PatientNoticeType,
  Pronoun,
  Sex
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { computePersonInitials } from "@libs/utils/utils.ts";
import { DemographicProfilePictureField } from "@modules/practice/screens/contact-details/shared-components/edit/DemographicProfilePictureField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { PatientSetting } from "@stores/user-experience/models/PatientSetting.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TitlePickerField } from "@ui-components/form/TitlePickerField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { PatientDemographicContext } from "../../context/PatientDemographicContext.ts";
import { EditPatientHeaderGenderDiverseField } from "./EditPatientHeaderGenderDiverseField.tsx";
import { EditPronounHeader } from "./EditPronounHeader.tsx";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

interface EditPatientHeaderProps {
  contact: Contact | undefined;
  patientNotices: PatientNotice[] | undefined;
  patientSetting: PatientSetting | undefined;
}

export const listStyle = mergeStyles({
  listStyle: "inherit",
  marginLeft: 24,
  marginTop: 0
});

export const EditPatientHeader: FC<EditPatientHeaderProps> = observer(
  ({ contact, patientNotices, patientSetting }) => {
    const { practice } = useStores();
    const {
      ui: { selectedEditCard }
    } = practice;

    const { displayGenderMessageBar, setdisplayGenderMessageBar } = useContext(
      PatientDemographicContext
    );

    const form = useForm<PatientEditFormValues>();

    const {
      input: { value: firstName }
    } = useField<string | undefined>(patientFormNameOf("firstName"), {
      subscription: { value: true }
    });

    const {
      input: { value: lastName }
    } = useField<string | undefined>(patientFormNameOf("lastName"), {
      subscription: { value: true }
    });

    const genderDiverseNotice =
      patientNotices &&
      patientNotices.find(x => x.type === PatientNoticeType.GenderDiverse);

    const displayNoticeMessage = genderDiverseNotice
      ? genderDiverseNotice.id.length > 0
      : false;

    if (displayGenderMessageBar === undefined) {
      if (contact !== undefined) {
        if (patientSetting === undefined) {
          setdisplayGenderMessageBar(!(contact.sex === contact.gender));
        } else {
          setdisplayGenderMessageBar(patientSetting.pronounMessage ?? false);
        }
      } else {
        setdisplayGenderMessageBar(false);
      }
    }

    const showMessageBar = (sex: string, gender: string) => {
      const value =
        (gender === Gender.Male && sex === Sex.Male) ||
        (gender === Gender.Female && sex === Sex.Female);
      setdisplayGenderMessageBar(!value);
    };

    const headerIsVisible =
      !selectedEditCard || selectedEditCard === PatientCardIds.patientHeader;

    const nameInputRef = headerIsVisible ? focusElement : undefined;

    return (
      <Stack
        id={`${PatientCardIds.patientHeader}-edit`}
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            paddingRight: 16,
            paddingTop: 21
          }
        }}
      >
        <Stack
          tokens={{ childrenGap: 8 }}
          horizontal
          horizontalAlign="space-between"
        >
          <TitlePickerField
            fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            label="Title"
            name={patientFormNameOf("title")}
            styles={{
              text: {
                minWidth: "auto"
              }
            }}
          />
          <TextInputField
            name={patientFormNameOf("firstName")}
            label="First name"
            required={true}
            autoFocus
            styles={{ root: { width: "auto", flexBasis: 0, flexGrow: 3 } }}
            componentRef={nameInputRef}
          />
        </Stack>
        <Stack
          tokens={{ childrenGap: 8 }}
          horizontal
          horizontalAlign="space-between"
        >
          <Stack.Item
            grow={1}
            styles={{ root: { paddingTop: 8, flexBasis: 0 } }}
          >
            <Stack horizontalAlign="center">
              <DemographicProfilePictureField
                contact={contact}
                imageInitials={computePersonInitials(firstName, lastName)}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item grow={3} styles={{ root: { flexBasis: 0 } }}>
            <Stack tokens={{ childrenGap: 8 }}>
              <TextInputField
                name={patientFormNameOf("lastName")}
                label="Last name"
                required={true}
              />
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <TextInputField
                  name={patientFormNameOf("middleName")}
                  label="Middle name"
                />
                <TextInputField
                  name={patientFormNameOf("nickName")}
                  label="Preferred"
                />
              </Stack>
            </Stack>
          </Stack.Item>
        </Stack>
        <Stack
          tokens={{ childrenGap: 8 }}
          horizontal
          horizontalAlign="space-between"
        >
          <Stack
            tokens={{ childrenGap: 8 }}
            horizontal
            grow={3}
            styles={{ root: { flexBasis: 0 } }}
          >
            <Stack.Item styles={{ root: { flexShrink: 0 } }}>
              <DropdownField
                name={patientFormNameOf("sex")}
                placeholder="Select sex"
                label="Birth / assigned sex"
                options={practice.ref.sexes.keyTextValues}
              />
            </Stack.Item>
            <Stack.Item grow>
              <DropdownField
                name={patientFormNameOf("gender")}
                placeholder="Select gender"
                label="Gender"
                options={practice.ref.genders.keyTextValues}
              />
            </Stack.Item>
            <Stack.Item>
              <EditPronounHeader
                pronounSubjectiveName={patientFormNameOf("pronounSubjective")}
                pronounObjectiveName={patientFormNameOf("pronounObjective")}
                pronounPossessiveName={patientFormNameOf("pronounPossessive")}
              />
            </Stack.Item>
          </Stack>
        </Stack>
        <EditPatientHeaderGenderDiverseField
          displayNoticeMessage={displayNoticeMessage}
          displayGenderMessageBar={displayGenderMessageBar}
          patientId={contact?.id}
        />
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 8 }}
        >
          <Stack.Item>
            <DatePickerField
              name={patientFormNameOf("birthDate")}
              label="DOB"
              allowClear
              maxDate={DateTime.jsDateNow()}
              styles={{ fieldGroup: { width: "auto" } }}
            />
          </Stack.Item>
          <Stack.Item grow={3} styles={{ root: { flexBasis: 0 } }}>
            <StaticTagPickerField
              fetchDataSource={() =>
                practice.ref.ethnicities.fetchAsKeyNameValues()
              }
              label="Ethnicity"
              name={patientFormNameOf("ethnicities")}
              excludeSelectedItems={true}
              styles={{ itemsWrapper: { flexGrow: 1 } }}
            />
          </Stack.Item>
          <WhenCountry is={Country.NewZealand}>
            <Stack.Item grow={1} styles={{ root: { flexBasis: 0 } }}>
              <TextInputField
                styles={{
                  field: { textTransform: "uppercase" }
                }}
                name={patientFormNameOf("nhi")}
                label="NHI"
              />
            </Stack.Item>
          </WhenCountry>
        </Stack>
        <FieldSpy
          name="sex"
          onChange={(value, values: PatientEditFormValues) => {
            if (!values.gender) {
              if (value === Sex.Female) {
                form.batch(() => {
                  form.change(patientFormNameOf("gender"), Gender.Female);
                  form.change(
                    patientFormNameOf("pronounSubjective"),
                    Pronoun.SHE
                  );
                  form.change(
                    patientFormNameOf("pronounObjective"),
                    Pronoun.HER
                  );
                  form.change(
                    patientFormNameOf("pronounPossessive"),
                    Pronoun.HERS
                  );
                });
              } else if (value === Sex.Male) {
                form.change(patientFormNameOf("gender"), Gender.Male);
                form.batch(() => {
                  form.change(
                    patientFormNameOf("pronounSubjective"),
                    Pronoun.HE
                  );
                  form.change(
                    patientFormNameOf("pronounObjective"),
                    Pronoun.HIM
                  );
                  form.change(
                    patientFormNameOf("pronounPossessive"),
                    Pronoun.HIS
                  );
                });
              }
            } else {
              const sex = value;
              const gender = values.gender;
              showMessageBar(sex, gender);
            }
          }}
        />
        <FieldSpy
          name={patientFormNameOf("gender")}
          onChange={(value, values: PatientEditFormValues) => {
            if (values.sex) {
              const sex = values.sex;
              const gender = value;
              showMessageBar(sex, gender);
            }
          }}
        />
      </Stack>
    );
  }
);
