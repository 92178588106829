import { useField, useForm } from "react-final-form";

import { Checkbox } from "@bps/fluent-ui";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { patientFormNameOf } from "./PatientEditFormValues.tsx";

export interface SendFormCheckboxProps {
  type: CommunicationType;
  value?: string;
}

export const SendFormCheckbox: React.FC<SendFormCheckboxProps> = ({
  type,
  value
}) => {
  const { change } = useForm();

  const {
    input: { value: communications }
  } = useField<CommunicationFieldValue[]>(patientFormNameOf("communications"), {
    subscription: { value: true }
  });

  const {
    input: { value: emailForm }
  } = useField<number | undefined>(patientFormNameOf("emailForm"), {
    subscription: { value: true }
  });

  const {
    input: { value: smsForm }
  } = useField<number | undefined>(patientFormNameOf("smsForm"), {
    subscription: { value: true }
  });

  const emailOptions = communications?.filter(
    x => x.type === CommunicationType.Email
  );

  const emailIndex = emailOptions.findIndex(x => value === x.value);
  const smsOptions = communications?.filter(
    x => x.type === CommunicationType.Mobile
  );

  const smsIndex = smsOptions.findIndex(x => value === x.value);

  const onCheckBoxChanged = (checked: boolean) => {
    if (type === CommunicationType.Email) {
      if (checked) {
        change(patientFormNameOf("emailForm"), emailIndex);
      } else {
        change(patientFormNameOf("emailForm"), null);
      }
    } else {
      // SMS
      if (checked) {
        change(patientFormNameOf("smsForm"), smsIndex);
      } else {
        change(patientFormNameOf("smsForm"), null);
      }
    }
  };

  switch (type) {
    case CommunicationType.Email:
      return (
        <Checkbox
          checked={emailForm === emailIndex}
          onChange={(_, checked) => onCheckBoxChanged(!!checked)}
          label={Labels.emailFormLabel}
          automationAttribute={`select-email-form-${emailIndex}`}
          disabled={!value}
        />
      );
    case CommunicationType.Mobile:
      return (
        <Checkbox
          checked={smsForm === smsIndex}
          label={Labels.smsFormLabel}
          onChange={(_, checked) => onCheckBoxChanged(!!checked)}
          automationAttribute={`select-email-form-${smsIndex}`}
          disabled={!value}
        />
      );
    default:
      return null;
  }
};
