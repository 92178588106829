import { TextBadgeColor, useTheme } from "@bps/fluent-ui";
import { ClaimAdjustmentStatus } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimStatusBadgeBase } from "@modules/acc/screens/shared-components/claim-status-badge/ClaimStatusBadgeBase.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ClaimAdjustmentStatusBadgeProps {
  code: string;
}

const ClaimAdjustmentStatusBadgeBase: React.FC<
  ClaimAdjustmentStatusBadgeProps
> = ({ code }) => {
  const { acc } = useStores();
  const theme = useTheme();

  let displayIcon = false;
  let hasBorder = false;
  let badgeColor = TextBadgeColor.red;
  let iconName = "Message";
  let fontColor;
  let statusText = acc.ref.claimAdjustmentStatuses.keyTextValues.find(
    x => x.key === code
  )?.text;
  switch (code) {
    case ClaimAdjustmentStatus.Ready:
      badgeColor = TextBadgeColor.green;
      break;
    case ClaimAdjustmentStatus.Queued:
      displayIcon = true;
      iconName = "Documentation";
      break;
    case ClaimAdjustmentStatus.Accepted:
      displayIcon = true;
      iconName = "Accept";
      fontColor = theme.palette.green;
      break;
    case ClaimAdjustmentStatus.Partial:
      displayIcon = true;
      iconName = "Accept";
      fontColor = theme.palette.green;
      statusText = "Partial";
      break;
    case ClaimAdjustmentStatus.Pending:
      displayIcon = true;
      iconName = "HourGlass";
      break;
    case ClaimAdjustmentStatus.Error:
    case ClaimAdjustmentStatus.DocumentError:
      hasBorder = true;
      break;
    case ClaimAdjustmentStatus.Incomplete:
      badgeColor = TextBadgeColor.yellow;
      statusText = "Incomplete";
      break;
    default:
      iconName = "CannedChat";
      badgeColor = TextBadgeColor.red;
      break;
  }
  return (
    <ClaimStatusBadgeBase
      displayIcon={displayIcon}
      badgeColor={badgeColor}
      hasBorder={hasBorder}
      icon={iconName}
      textColor={fontColor}
      text={statusText}
    />
  );
};

export const ClaimAdjustmentStatusBadge = withFetch(
  x => [x.acc.ref.claimAdjustmentStatuses.load()],
  ClaimAdjustmentStatusBadgeBase
);
