import { useRef } from "react";
import { Outlet } from "react-router-dom";

import { Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Print } from "@ui-components/printing/Print.tsx";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { InboxScreenHelper } from "../context/InboxScreenHelper.ts";
import { ToDoHeader } from "./components/ToDoHeader.tsx";

export const ToDoScreen = () => {
  const root = useStores();

  const inboxHelper = useRef(new InboxScreenHelper(root));

  return (
    <InboxScreenContext.Provider value={inboxHelper.current}>
      <Print
        pageStyle={`
  @media print {
    @page { 
      size: landscape;
      margin: 20mm; 
    }
 
    .print-footer {
      padding-top: 24px;
      font-style: italic;
    }

    .print-page-break {
      margin-top: 12px;
      display: block;
      page-break-before: auto;
    }

    .ms-SelectionZone > .ms-FocusZone {
      display: block;
    }
  }
 `}
      >
        {() => (
          <Stack
            styles={{
              root: { height: "100%", maxWidth: 1500, margin: "auto" }
            }}
            tokens={{ childrenGap: 8 }}
          >
            <ToDoHeader />
            <Outlet />
          </Stack>
        )}
      </Print>
    </InboxScreenContext.Provider>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ToDoScreen;
