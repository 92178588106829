import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useField } from "react-final-form";

import { Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AvailabilitySlots } from "@stores/booking/models/AvailabilitySlots.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DatePickerArrows } from "@ui-components/pickers/DatePickerArrows.tsx";
import { LocationOption } from "@ui-components/selects/LocationOption.tsx";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { getTimeSpan } from "../utils.ts";
import { messageLabelStyle } from "./NextAvailableFields.styles.ts";
import { ProviderTimeSlotsHeader } from "./ProviderTimeSlotsHeader.tsx";
import { TimeSlots } from "./TimeSlots.tsx";

type ProviderTimeSlotsProps = {
  pickerStartDate: Date;
  settledResult: PromiseFulfilledResult<AvailabilitySlots>;
  orgUnitIds: string[];
};

export const DEFAULT_LOCATION_COLOUR = "black";

export const ProviderTimeSlots: React.FunctionComponent<ProviderTimeSlotsProps> =
  memo(({ pickerStartDate, settledResult, orgUnitIds }) => {
    const { value: slots } = settledResult;
    const { selectedProviders, getToggleText, groupSlotsByOrgUnitId } =
      useContext(AppointmentFormContext);

    const { core, practice } = useStores();

    const [sliderStartDate, setSliderStartDate] = useState<Date | undefined>(
      pickerStartDate
    );

    const {
      input: { value: duration }
    } = useField(appointmentFormNameOf("duration"), {
      subscription: { value: true }
    });

    const restrictedDates = useMemo(
      () => slots.getUnavailableDates(pickerStartDate),
      [slots, pickerStartDate]
    );

    const [_isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
      setSliderStartDate(pickerStartDate);
    }, [pickerStartDate]);

    const isSingleProvider = selectedProviders.length === 1;
    const isExpanded = _isExpanded || isSingleProvider;
    const minDate = DateTime.today().toJSDate();

    const date =
      DateTime.fromJSDate(isExpanded ? sliderStartDate : pickerStartDate) ||
      DateTime.today();

    const changeDate = (date: Date | undefined) => {
      setSliderStartDate(date);
    };

    const groupedSlots = groupSlotsByOrgUnitId(
      settledResult.value,
      date,
      isExpanded
    );

    return (
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={(props, theme) => ({
          root: {
            marginRight: 8, // to add space between scrollbar
            paddingBottom: 8,
            borderBottom: `1px solid ${theme.palette.neutralLighter}`
          }
        })}
      >
        <ProviderTimeSlotsHeader
          providerId={slots.providerId}
          moreText={getToggleText(slots, pickerStartDate)}
          toggleIsExpanded={() => setIsExpanded(value => !value)}
          isExpanded={isExpanded}
        />

        <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }}>
          {isExpanded && (
            <DatePickerArrows
              calendarProps={{
                minDate,
                restrictedDates,
                calendarMonthProps: {
                  onNavigateDate: changeDate
                },
                calendarDayProps: {
                  onNavigateDate: changeDate
                }
              }}
              value={sliderStartDate}
              onChange={changeDate}
            />
          )}
          {date && groupedSlots.length > 0 && (
            <DataFetcher<AvailabilitySlots>
              refetchId={date?.toFormat("yyyy-LL")}
              fallback={null}
              fetch={async ({ booking }) => {
                if (
                  DateTime.fromJSDate(pickerStartDate).toFormat("yyyy-LL") ===
                  DateTime.fromJSDate(sliderStartDate)?.toFormat("yyyy-LL")
                ) {
                  return settledResult.value;
                }

                const availableSlots = await booking.getUserAvailabilitySlot({
                  userId: slots.providerId,
                  orgUnitIds,
                  startDate: date.startOf("day"),
                  endDate: date
                    .startOf("day")
                    .plus({ months: 3 })
                    .endOf("month"),
                  duration: getTimeSpan(Number(duration))
                });

                return availableSlots;
              }}
            >
              {availableSlots =>
                groupedSlots.map(availability => {
                  // location is loaded by location select
                  const location = practice.pracOrgUnitMap.get(
                    availability.orgUnitId
                  );
                  return (
                    <Stack
                      key={availability.orgUnitId}
                      horizontalAlign="center"
                      tokens={{ childrenGap: 8 }}
                    >
                      {core.hasMultipleActiveLocations &&
                        (location?.orgUnitLocationData?.nickName ? (
                          <LocationOption
                            color={
                              location?.orgUnitLocationData
                                ?.appointmentBookMarkerCode ??
                              DEFAULT_LOCATION_COLOUR
                            }
                            text={location?.orgUnitLocationData?.nickName}
                          />
                        ) : (
                          <Text>
                            {core.getLocationName(availability.orgUnitId)}
                          </Text>
                        ))}
                      <TimeSlots
                        setIsExpanded={setIsExpanded}
                        date={date}
                        orgUnitId={availability.orgUnitId}
                        slots={availableSlots}
                        changeDate={changeDate}
                        isExpanded={isExpanded}
                      />
                    </Stack>
                  );
                })
              }
            </DataFetcher>
          )}

          {sliderStartDate && groupedSlots.length === 0 && (
            <Text
              styles={{
                root: messageLabelStyle
              }}
            >
              This provider is not available at this location
            </Text>
          )}
        </Stack>
      </Stack>
    );
  });
