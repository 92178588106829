import {
  lessThanOrSame,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  DermatomeFieldValues,
  DermatomesMyotomesAndReflexesFormValues,
  MyotomeFieldValues,
  ReflexFieldValues
} from "../DermatomesAndMyotomesForm.types.ts";

export class DMRFormValidator extends Validator<DermatomesMyotomesAndReflexesFormValues> {
  constructor() {
    super();
    const myotomesValidator = new MyotomesValidator();
    const reflexesValidator = new ReflexesValidator();
    const dermatomeValidator = new DermatomesValidator();
    this.forArrayField("myotomeFields", myotomesValidator.validate);
    this.forArrayField("reflexFields", reflexesValidator.validate);
    this.forArrayField("trigeminalDermatomes", dermatomeValidator.validate);
    this.forArrayField("cervicalDermatomes", dermatomeValidator.validate);
    this.forArrayField("lumbosacralDermatomes", dermatomeValidator.validate);
    this.forArrayField("thoracicDermatomes", dermatomeValidator.validate);
  }
}

class MyotomesValidator extends Validator<MyotomeFieldValues> {
  constructor() {
    super();
    this.forField("strength", [lessThanOrSame(5)]);
  }
}

class ReflexesValidator extends Validator<ReflexFieldValues> {
  constructor() {
    super();
    this.forField("strength", [lessThanOrSame(4)]);
  }
}

class DermatomesValidator extends Validator<DermatomeFieldValues> {
  constructor() {
    super();
    this.forField("estimation", [
      predicate((_v, values) => !!values?.nerves, required())
    ]);
    this.forField("nerves", [
      predicate((_v, values) => !!values?.estimation, required())
    ]);
  }
}
