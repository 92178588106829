import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef } from "react";

import {
  AnimationStyles,
  Heading,
  mergeStyles,
  PersonaSize,
  Spinner,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { UserStatus } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { communicationComparer } from "@stores/core/models/Communication.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import {
  PreferredArgs,
  PreferredCheckBox
} from "../../shared-components/view/PreferredCheckBox.tsx";

interface PatientFieldProps {
  patient: Contact;
}
const allUsersPromise = maybePromiseObservable<User[]>();

export const PatientProvidersInternal: FC<PatientFieldProps> = observer(
  ({ patient }) => {
    const { core, practice } = useStores();
    const theme = useTheme();
    const leadProvider = useRef<boolean>(false);
    useEffect(() => {
      allUsersPromise.set(core.loadAllUsers());
      return () => {
        allUsersPromise.clear();
      };
    }, [core]);

    const onLeadClick = async (preferredArgs: PreferredArgs) => {
      const internalProvider = patient.internalProviders.map(x => ({
        userId: x.userId,
        lead: x.userId !== preferredArgs.userId ? false : !x.lead
      }));
      leadProvider.current = true;
      await practice.updatePatient({
        id: patient.id,
        internalProvider
      });
      leadProvider.current = false;
    };

    const { userMap } = core;

    if (!allUsersPromise.wrappedPromise) return null;

    const internalProviders = patient.internalProviders
      .map((x, index) => {
        const intProv = userMap.get(x.userId);

        return {
          userId: x.userId,
          key: `EXT${index}`,
          new: false,
          lead: x.lead,
          name: intProv ? intProv.name : x.userId,
          initials: intProv ? intProv.initials : "",
          communications:
            intProv &&
            intProv.communications.slice().sort(communicationComparer)[0]
              ?.value,
          status: intProv?.status
        };
      })
      .sort((a, b) => {
        if (a.lead === b.lead) return 0;
        if (a.lead) return -1;
        return 1;
      });

    const { labelSmall, providerTitle, personaText } =
      getPeopleScreenStylesSet(theme);
    return (
      <Stack tokens={{ childrenGap: 8 }}>
        {internalProviders.length > 0 && (
          <Stack>
            <Heading className={providerTitle} variant="section-sub-heading">
              {Labels.internalProviders}
            </Heading>
            {internalProviders.map(prov => (
              <Stack.Item
                key={Math.random()}
                className={
                  leadProvider.current && prov.lead
                    ? mergeStyles({
                        ...AnimationStyles.slideRightIn40
                      })
                    : ""
                }
                styles={(_props, theme) => ({
                  root: {
                    padding: 1,
                    selectors: {
                      "&:hover ": {
                        backgroundColor: theme.semanticColors.disabledBackground
                      },
                      "&:hover .ms-Icon": {
                        visibility: "visible"
                      }
                    }
                  }
                })}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  tokens={{ childrenGap: 8 }}
                  styles={{
                    root: {
                      padding: 10
                    }
                  }}
                >
                  <UserFetcher userId={prov.userId} fallback={<Spinner />}>
                    {user => (
                      <Persona
                        status={
                          prov.status === UserStatus.Inactive
                            ? ContactStatus.Inactive
                            : undefined
                        }
                        size={PersonaSize.size48}
                        imageInitials={user.initials}
                        text={user.name}
                        onRenderSecondaryText={
                          prov
                            ? () => (
                                <Stack styles={labelSmall}>
                                  {prov.communications}
                                </Stack>
                              )
                            : undefined
                        }
                        styles={personaText}
                      />
                    )}
                  </UserFetcher>

                  <Stack verticalAlign="center">
                    <PreferredCheckBox
                      preferred={prov.lead}
                      userId={prov.userId}
                      onPreferredClick={onLeadClick}
                      tooltipContent={
                        prov.lead ? Labels.leadProvider : Labels.markAsPreferred
                      }
                    />
                  </Stack>
                </Stack>
              </Stack.Item>
            ))}
          </Stack>
        )}
      </Stack>
    );
  }
);
