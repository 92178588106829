import { useFormState } from "react-final-form";

import { Separator, Stack, useResizeElementObserver } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { MEDIUM_BREAKPOINT } from "../../full-body-clinical-data/examination/utils.ts";
import { BloodPressuresList } from "./BloodPressuresList.tsx";
import { GeneralExaminationFormValues } from "./GeneralExaminationFormValues.ts";
import { GeneralExaminationGPversionFields } from "./GeneralExaminationGPversionFields.tsx";
import { GeneralExamRespiratoryField } from "./GeneralExamRespiratoryField.tsx";
import { GeneralExamTemperatureField } from "./GeneralExamTemperatureField.tsx";
import { GeneralExamWeightHeightBMIField } from "./GeneralExamWeightHeightBMIField.tsx";
import { PulseList } from "./PulseList.tsx";
import { getSeparatorProps } from "./utils.ts";

export const nameOfGeneralExaminationForm =
  nameOfFactory<GeneralExaminationFormValues>();

const GeneralExaminationFieldsComponent: React.FunctionComponent = () => {
  const { values } = useFormState<GeneralExaminationFormValues>({
    subscription: { values: true }
  });

  const separatorProps = getSeparatorProps();
  const { setElement, resizeObserverEntry, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  const wrapValues = panelWidth <= MEDIUM_BREAKPOINT;

  return (
    <div
      ref={r => {
        if (r && !element) {
          setElement(r);
        }
      }}
      style={{ height: "100%", overflow: "auto" }}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <When
          permission={Permission.GpExamCompleteAllowed}
          else={
            <Stack tokens={{ childrenGap: 8 }}>
              <PulseList width={panelWidth} />

              <Separator {...separatorProps} />
              <BloodPressuresList wrapValues={wrapValues} />

              <Separator {...separatorProps} />
              <GeneralExamTemperatureField width={panelWidth} />

              <Separator {...separatorProps} />
              <GeneralExamRespiratoryField />

              <Separator {...separatorProps} />

              <GeneralExamWeightHeightBMIField wrapValues={wrapValues} />
            </Stack>
          }
        >
          <GeneralExaminationGPversionFields
            values={values}
            width={panelWidth}
          />
        </When>

        <Separator {...separatorProps} />
        <TextInputField
          label="General comments"
          name={nameOfGeneralExaminationForm("comment")}
          placeholder="Placeholder"
        />
      </Stack>
    </div>
  );
};

export const GeneralExaminationFields = withFetch(
  x => [
    x.clinical.ref.pulseRhythms.load(),
    x.clinical.ref.pulseCharacteristics.load(),
    x.clinical.ref.bslQualifiers.load(),
    x.clinical.ref.pulseMethods.load(),
    x.clinical.ref.temperatureMethods.load()
  ],
  GeneralExaminationFieldsComponent
);
