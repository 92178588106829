import { observer } from "mobx-react-lite";

import { ContextualMenu, useTheme } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useEventReminderScreenContext } from "@modules/booking/screens/event-reminders/context/EventReminderScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { eventReminderMenuItems } from "./utils.tsx";

export const EventReminderContextualMenu: React.FC = observer(() => {
  const theme = useTheme();

  const { setReminderArgs } = useBookingCalendarScreenContext();
  const { currentEventData, setCurrentEventData } =
    useEventReminderScreenContext();

  if (!currentEventData?.event) return null;

  const root = useStores();

  const items = eventReminderMenuItems({
    item: currentEventData?.event,
    root,
    setReminderArgs,
    theme
  });

  function onDismiss() {
    setCurrentEventData(undefined);
  }

  return (
    <ContextualMenu
      items={items}
      hidden={!currentEventData.contextualMenuPosition}
      target={currentEventData.contextualMenuPosition}
      onDismiss={onDismiss}
    />
  );
});
