import { PropsWithChildren } from "react";

import {
  Card,
  DefaultButton,
  FontIcon,
  Heading,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";

import { UserCardHeadings, UserPageHeadings } from "../../labels.ts";
import { ScheduleFields } from "./ScheduleFields.types.ts";
import { useHandleAdd } from "./userWorkingHoursFormHooks.ts";

export const UserWorkingHoursCard = (props: PropsWithChildren) => {
  const { core } = useStores();
  const theme = useTheme();
  const handleAdd = useHandleAdd();

  return (
    <Stack styles={{ root: { paddingTop: 26 } }} tokens={{ childrenGap: 24 }}>
      <Heading
        styles={{ root: { paddingBottom: 2 } }}
        variant="section-heading"
      >
        {UserPageHeadings.workingHours}
      </Heading>
      <Card
        icon={
          <FontIcon
            iconName="work"
            styles={{
              root: {
                color: theme.palette.themePrimary
              }
            }}
          />
        }
        headingLevel="section-heading"
        heading={UserCardHeadings.activeWorkingHours}
        styles={{
          subComponentStyles: {
            tile: {
              content: {
                padding: 0
              }
            }
          }
        }}
        button={
          <FieldCondition
            when="userWorkingHours"
            is={(v: ScheduleFields[]) => v.length > 0}
          >
            {core.hasUserSettingWritePermission ? (
              <DefaultButton
                onClick={handleAdd}
                iconProps={{ iconName: "Add" }}
              >
                Add working hours
              </DefaultButton>
            ) : null}
          </FieldCondition>
        }
      >
        {props.children}
      </Card>
    </Stack>
  );
};
