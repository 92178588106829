import {
  ClinicalDataType,
  CodedFieldClinicalDataItemDto,
  EncounterClinicalDataDto,
  FamilyAliveStatus,
  SignificantConditionDataItemDto,
  SignificantFamilyHistoryDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  FamilyHistoryFormValues,
  generalSignificantConditions,
  placeHolderParentHistoryValues
} from "../FamilyHistoryForm.types.ts";

export class FamilyHistoryFormHelper {
  constructor(
    private root: RootStore,
    public clinicalRecord: ClinicalRecord
  ) {}

  private get clinical() {
    return this.root.clinical;
  }

  static isGeneralCondition = (
    significantCondition: SignificantConditionDataItemDto
  ) => {
    return (
      generalSignificantConditions.find(
        z => z.code === significantCondition.condition.code
      ) !== undefined
    );
  };

  lookupGeneralTerminologies = async () => {
    const promises = generalSignificantConditions.map(x => {
      if (
        !this.clinical.terminologyMap.has(
          this.clinical.getTerminologyKey(x.code, x.term)
        )
      ) {
        return this.clinical.getTerminologiesSearch({
          search: x.term,
          isDiagnosis: true,
          isProcedure: true
        });
      }
      return undefined;
    });
    await Promise.all(promises.filter(x => !!x));
  };

  getInitialValues = () => {
    const stashedfamilyHistory =
      this.clinicalRecord.stashedClinicalData?.familyHistory;

    const significantFamilyHistories =
      stashedfamilyHistory?.significantFamilyHistories;

    const parentHistories = significantFamilyHistories
      ? significantFamilyHistories.slice(0, 2)
      : placeHolderParentHistoryValues;

    const additionalFamilyHistories = significantFamilyHistories
      ? significantFamilyHistories.slice(2)
      : undefined;

    const familyHistoriesWithDiagnosisKeys = parentHistories.map(x => ({
      id: x.id,
      relationship: x.relationship,
      diagnosisKeys: x.conditions
        ? x.conditions
            .filter(condition =>
              FamilyHistoryFormHelper.isGeneralCondition(condition)
            )
            .map(y =>
              this.clinical.getTerminologyKey(
                y.condition.code,
                y.condition.originalText
              )
            )
        : []
    }));

    const additionalParentHistories = parentHistories.map(x => {
      const additionalConditions = x.conditions
        ? x.conditions.filter(
            y => !FamilyHistoryFormHelper.isGeneralCondition(y)
          )
        : [];

      return {
        relationship: x.relationship,
        conditions: additionalConditions
      };
    });

    const iniValues: FamilyHistoryFormValues = {
      unknownFamilyHistory: stashedfamilyHistory?.unknownFamilyHistory,
      noSignificantFamilyHistory:
        stashedfamilyHistory?.noSignificantFamilyHistory,
      generalFamilyHistoryComment:
        stashedfamilyHistory?.generalFamilyHistoryComment,
      motherAliveStatus: stashedfamilyHistory?.motherAliveStatus,
      motherAgeAtDeath: stashedfamilyHistory?.motherAgeAtDeath,
      motherDeceasedReason: !!stashedfamilyHistory?.motherDeceasedReason
        ? this.clinical.getTerminologyKey(
            stashedfamilyHistory.motherDeceasedReason.code,
            stashedfamilyHistory.motherDeceasedReason.originalText
          )
        : undefined,
      fatherAliveStatus: stashedfamilyHistory?.fatherAliveStatus,
      fatherAgeAtDeath: stashedfamilyHistory?.fatherAgeAtDeath,
      fatherDeceasedReason: !!stashedfamilyHistory?.fatherDeceasedReason
        ? this.clinical.getTerminologyKey(
            stashedfamilyHistory?.fatherDeceasedReason.code,
            stashedfamilyHistory.fatherDeceasedReason.originalText
          )
        : undefined,
      otherComments: stashedfamilyHistory?.otherComments,
      parentHistories: familyHistoriesWithDiagnosisKeys,
      additionalParentHistories: this.generateDiagnosisKeyFromCondition(
        additionalParentHistories
      ),
      additionalFamilyHistories: additionalFamilyHistories
        ? this.generateDiagnosisKeyFromCondition(additionalFamilyHistories)
        : undefined
    };

    return iniValues;
  };

  generateDiagnosisKeyFromCondition = (
    values: SignificantFamilyHistoryDataItemDto[]
  ) => {
    return values.map(x => ({
      id: x.id,
      relationship: x.relationship,
      conditions: x.conditions
        ? x.conditions.map(y => ({
            id: y.id,
            comment: y.comment,
            condition: y.condition,
            diagnosisKey: y.condition
              ? this.clinical.getTerminologyKey(
                  y.condition.code,
                  y.condition.originalText
                )
              : ""
          }))
        : []
    }));
  };

  generateConditionFromDiagnosisKey = (
    values: SignificantFamilyHistoryDataItemDto[]
  ) => {
    return values.map(x => ({
      id: x.id,
      relationship: x.relationship,
      conditions: x.conditions
        ? x.conditions.map(y => ({
            id: y.id,
            comment: y.comment,
            condition: y.diagnosisKey
              ? this.convertDiagnosisKeytoCodedField(y.diagnosisKey)
              : { originalText: "", code: "" }
          }))
        : []
    }));
  };

  convertDiagnosisKeytoCodedField = (value: string) => {
    let diagnosisText: string | undefined,
      diagnosisCode: string | undefined,
      diagnosisVersion: string | undefined;
    let codedFieldItem: CodedFieldClinicalDataItemDto | undefined;

    if (value) {
      const diagnosisTerminology = this.clinical.getTerminologyFromMap(value);

      diagnosisText = diagnosisTerminology && diagnosisTerminology.text;
      diagnosisCode = diagnosisTerminology && diagnosisTerminology.code;
      diagnosisVersion = diagnosisTerminology && diagnosisTerminology.version;
    }
    if (diagnosisText && diagnosisCode) {
      codedFieldItem = {
        code: diagnosisCode,
        originalText: diagnosisText,
        version: diagnosisVersion
      };
      return codedFieldItem;
    }
    return { originalText: "", code: "" };
  };

  buildSignificantFamilyHistories = (values: FamilyHistoryFormValues) => {
    let updatedFamilyHistories: SignificantFamilyHistoryDataItemDto[] =
      placeHolderParentHistoryValues;

    let updatedAdditionalFamilyHistories:
      | SignificantFamilyHistoryDataItemDto[]
      | undefined;

    let updatedAdditionalParentHistories:
      | SignificantFamilyHistoryDataItemDto[]
      | undefined;

    const parentHistories = values.parentHistories;
    const additionalParentHistories = values.additionalParentHistories;
    const additionalFamilyHistories = values.additionalFamilyHistories;

    if (parentHistories) {
      updatedFamilyHistories = parentHistories.map(value => {
        const codedFields = value.diagnosisKeys
          ? value.diagnosisKeys.map(x =>
              this.convertDiagnosisKeytoCodedField(x)
            )
          : [];

        return {
          id: value.id,
          relationship: value.relationship,
          conditions: codedFields.map(y => ({
            condition: y
          }))
        };
      });

      if (additionalParentHistories) {
        updatedAdditionalParentHistories =
          this.generateConditionFromDiagnosisKey(additionalParentHistories);
        if (updatedAdditionalParentHistories) {
          updatedAdditionalParentHistories.forEach((value, index) => {
            const currentIndexParentConditions = updatedFamilyHistories[index]
              ? updatedFamilyHistories[index].conditions
              : [];

            const currentIndexAdditionalParentConditions = value?.conditions;
            if (
              currentIndexParentConditions &&
              currentIndexAdditionalParentConditions
            ) {
              currentIndexParentConditions.push(
                ...currentIndexAdditionalParentConditions
              );
            }
          });
        }
      }

      if (additionalFamilyHistories) {
        updatedAdditionalFamilyHistories =
          this.generateConditionFromDiagnosisKey(additionalFamilyHistories);

        updatedFamilyHistories.push(...updatedAdditionalFamilyHistories);
      }
    }
    return updatedFamilyHistories;
  };

  getClinicalData = (
    values: FamilyHistoryFormValues
  ): Partial<EncounterClinicalDataDto> => {
    const eTag = this.clinicalRecord.clinicalData?.familyHistory?.eTag;

    const confirmedETag =
      this.clinicalRecord.clinicalData?.familyHistoryConfirmed?.eTag;

    const clinicalData: EncounterClinicalDataDto = {};

    const checkboxTicked =
      values.unknownFamilyHistory || values.noSignificantFamilyHistory;

    if (checkboxTicked) {
      clinicalData.familyHistory = {
        eTag,
        unknownFamilyHistory: values.unknownFamilyHistory,
        noSignificantFamilyHistory: values.noSignificantFamilyHistory,
        generalFamilyHistoryComment: values.generalFamilyHistoryComment
      };
    } else {
      const recordMotherDetails =
        values.motherAliveStatus === FamilyAliveStatus.NO;

      const recordFatherDetails =
        values.fatherAliveStatus === FamilyAliveStatus.NO;
      clinicalData.familyHistory = {
        eTag,
        motherAliveStatus: values.motherAliveStatus,
        motherAgeAtDeath: recordMotherDetails
          ? values.motherAgeAtDeath
          : undefined,
        motherDeceasedReason:
          recordMotherDetails && values.motherDeceasedReason
            ? this.convertDiagnosisKeytoCodedField(values.motherDeceasedReason)
            : undefined,
        fatherAliveStatus: values.fatherAliveStatus,
        fatherAgeAtDeath: recordFatherDetails
          ? values.fatherAgeAtDeath
          : undefined,
        fatherDeceasedReason:
          recordFatherDetails && values.fatherDeceasedReason
            ? this.convertDiagnosisKeytoCodedField(values.fatherDeceasedReason)
            : undefined,
        otherComments: values.otherComments,
        unknownFamilyHistory: values.unknownFamilyHistory,
        noSignificantFamilyHistory: values.noSignificantFamilyHistory,
        generalFamilyHistoryComment: undefined,
        significantFamilyHistories: this.buildSignificantFamilyHistories(values)
      };
    }

    clinicalData.familyHistoryConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };

    return clinicalData;
  };

  submitData = async (values: FamilyHistoryFormValues) => {
    const clinicalData: EncounterClinicalDataDto = this.getClinicalData(values);

    await this.clinicalRecord.saveClinicalData(clinicalData);
  };

  onCancel = () => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.FamilyHistory
    );

    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "familyHistory"
    ]);
  };

  get familyHistoryFormConfirmed() {
    const clinicalRecord = this.clinicalRecord;

    const familyHistoryConfirmed =
      clinicalRecord.clinicalData?.familyHistoryConfirmed;

    const { openEncounter } = clinicalRecord;

    return (
      openEncounter &&
      familyHistoryConfirmed?.createLog?.createdEncounterId ===
        openEncounter?.id
    );
  }
}
