import { ShowAvailabilityEnum } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export type DurationUnit = "minutes" | "hours" | "days";

export type DurationValue = {
  unitValue: number;
  unit: DurationUnit;
};

export const onlineSettingsNameOf =
  nameOfFactory<BhbOnlineSettingsFormValues>();
export const BHB_ONLINE_SETTINGS_PARKING_MAX_CHARACTERS = 500;
export const BHB_ONLINE_SETTINGS_DISABILITY_MAX_CHARACTERS = 500;
export const BHB_ONLINE_SETTINGS_POLICY_MAX_CHARACTERS = 8000;

export interface BhbOnlineSettingsFormValues {
  onlineBookingsEnabled?: boolean;
  // Appointment cancellation & rescheduling values
  allowCancellationsEnabled?: boolean;
  minimumTimeToCancelValue: number;
  minimumTimeToCancelUnit: DurationUnit;
  nonCancellableAppointmentTypes: string[];
  // Booking availability timeframes & limits values
  showAvailability: ShowAvailabilityEnum;
  minimumTimeToBookValue: number;
  minimumTimeToBookUnit: DurationUnit;
  limitMaximumAppointmentsPerDay: boolean;
  maximumAppointmentsPerDay: number;
  // Parking & Disability values
  parkingAccess?: string;
  parkingAccessEnabled?: boolean;
  disabilityAccess?: string;
  disabilityAccessEnabled?: boolean;
  // Booking Policy
  policy?: string;
  bookingPolicyEnabled?: boolean;
  // Emergency message
  emergencyMessageEnabled?: boolean;
  emergencyMessage?: string;
  //Practice details
  url?: string;

  logoUrl?: string;
  hasLogoUrl?: boolean;
}
