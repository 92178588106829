import { memo } from "react";
import { useForm, useFormState } from "react-final-form";

import {
  CollapsibleCard,
  MessageBar,
  MessageBarType,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";
import { TreatmentFields } from "./TreatmentFields.tsx";

interface TreatmentPlanCardProps {
  isFilledFromTreatments: boolean;
  setIsFilledFromTreatments: (value: boolean) => void;
  linkedCondition?: boolean;
}

export const TreatmentPlanCard: React.FC<TreatmentPlanCardProps> = memo(
  ({ isFilledFromTreatments, setIsFilledFromTreatments, linkedCondition }) => {
    const { clinical } = useStores();
    const { values } = useFormState<SOTAPFormValues>({
      subscription: { values: true }
    });

    const form = useForm<SOTAPFormValues>();
    const { clinicalRecord } = usePatientRecordScreenContext();
    const theme = useTheme();

    const messageBarText = clinicalRecord.isFollowOnEncounter
      ? `Autofilled from plan on ${values.treatmentDate}.`
      : "Autofilled from today's treatment";

    return (
      <div id={SotapMskId.TreatmentPlan}>
        <CollapsibleCard
          heading={SotapMskLabel.TreatmentPlan}
          headingLevel="section-heading"
          iconName="QuestionnaireMirrored"
          openOnRender={linkedCondition}
          disabled={!linkedCondition}
        >
          <Stack
            tokens={{
              childrenGap: 16,
              maxWidth: 630
            }}
          >
            {isFilledFromTreatments && (
              <MessageBar
                messageBarType={MessageBarType.success}
                onDismiss={() => setIsFilledFromTreatments(false)}
              >
                {messageBarText}
              </MessageBar>
            )}
            <TreatmentFields
              treatmentArrayName="planTreatments"
              treatmentBaseArrayName="planTreatmentsBase"
              updateTreatmentPlan={false}
            />

            <Stack
              tokens={{ childrenGap: 8 }}
              styles={{
                root: {
                  border: `1px solid ${theme.palette.neutralLight}`,
                  padding: 8
                }
              }}
            >
              <CheckboxGroupField
                label="Education"
                name={sotapNameOf("planEducationOptions")}
                options={clinical.ref.educationOptions.keyLabelValues}
                horizontal
                wrap
                checkboxWidth={150}
                styles={{
                  itemsWrapper: {
                    display: "flex",
                    justifyContent: "space-between"
                  },
                  root: {
                    width: "600"
                  }
                }}
              />
              <FieldSpy
                name={sotapNameOf("planEducationOptions")}
                onChange={value => {
                  if (!value.includes("OTH")) {
                    form.change(
                      sotapNameOf("planOtherEducationComment"),
                      undefined
                    );
                  }
                }}
              />
              {values.planEducationOptions &&
                values.planEducationOptions.includes("OTH") && (
                  <TextInputField
                    name={sotapNameOf("planOtherEducationComment")}
                    rows={1}
                    multiline
                    autoAdjustHeight
                    resizable={false}
                    styles={{
                      fieldGroup: { minHeight: 30 }
                    }}
                    placeholder="Other education provided"
                  />
                )}
              <TextInputField
                label="Education comments"
                name="planEducationComment"
                rows={1}
                multiline
                autoAdjustHeight
                resizable={false}
                styles={{
                  fieldGroup: { minHeight: 30 }
                }}
              />
            </Stack>
            <TextInputField
              label="Plan / review"
              name={sotapNameOf("planReview")}
              multiline
              rows={3}
              autoAdjustHeight
              resizable={false}
              styles={{
                fieldGroup: {
                  minHeight: 60
                }
              }}
            />
          </Stack>
        </CollapsibleCard>
      </div>
    );
  }
);
