import {
  isDateBeforeField,
  isNotFutureDate,
  Length,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { PresentingComplaintFormValues } from "./PresentingComplaintForm.types.ts";

export class PresentingComplaintFormValidator extends Validator<PresentingComplaintFormValues> {
  constructor() {
    super();

    this.forField("dateOfInjury", [
      isNotFutureDate(ValidationMessages.futureDateEqualOrLess)
    ]);
    this.forField("dateOfSurgery", [
      isDateBeforeField(
        ValidationMessages.dateLaterThanDateOfInjury,
        "dateOfInjury"
      )
    ]);
    this.forField("mechanismOfInjury", [maxLength(Length.comments)]);
    this.forField("currentHistory", [maxLength(Length.comments)]);
    this.forField("naturesComment", [maxLength(Length.comments)]);
    this.forField("aggravatingFactorsComment", [maxLength(Length.comments)]);
    this.forField("easingFactorsComment", [maxLength(Length.comments)]);
  }
}
