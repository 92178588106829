import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { FontIcon, FontWeights, Stack, Text, useTheme } from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AppointmentNoticeType } from "../../../BookingCalendarEventModel.ts";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { NoticeIcon } from "../popup/AppointmentNotices.tsx";

export enum ContextualMenuNoticeLabels {
  comment = "Appointment comment",
  interpreter = "Interpreter needed",
  noCharge = "No charge",
  providerComment = "Provider comment"
}

export const EventTypeInfo: FunctionComponent = observer(() => {
  const theme = useTheme();

  const { booking } = useStores();

  const { appointmentNotices, calendarEvent } = useContext(
    BookingCalendarEventContext
  );

  const renderContent = (appointmentType?: AppointmentType) => {
    const newNotices = appointmentNotices.filter(({ isNew }) => isNew);
    let notice = newNotices.find(
      n => n.type === AppointmentNoticeType.noCharge
    );

    if (!notice)
      notice = newNotices.find(
        n => n.type === AppointmentNoticeType.providerComment
      );

    if (notice) {
      return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
          <FontIcon
            iconName={NoticeIcon[notice.type]}
            styles={{
              root: { color: theme.palette.themePrimary, margin: "0px 4px" }
            }}
          />
          <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
            {ContextualMenuNoticeLabels[notice.type]}
          </Text>
        </Stack>
      );
    }

    return (
      <Text block styles={{ root: { marginLeft: 4 } }}>
        {renderEventText(appointmentType)}
      </Text>
    );
  };

  const renderEventText = (appointmentType?: AppointmentType) => {
    if (!calendarEvent.user) {
      return null;
    }
    switch (calendarEvent.type) {
      case CalendarEventType.Appointment: {
        return appointmentType?.name;
      }
      case CalendarEventType.Unavailable: {
        return `${calendarEvent.user.fullName} is unavailable`;
      }
      case CalendarEventType.Meeting: {
        return `Meeting with ${calendarEvent.user.fullName}`;
      }
      default: {
        return null;
      }
    }
  };

  const getAppointmentType = async () => {
    if (calendarEvent.appointmentTypeId) {
      return await booking.getAppointmentType(calendarEvent.appointmentTypeId);
    }

    return undefined;
  };

  return (
    <DataFetcher fetch={getAppointmentType}>
      {appointmentType => {
        return <>{renderContent(appointmentType)}</>;
      }}
    </DataFetcher>
  );
});
