import { FormApi } from "final-form";
import { action, observable } from "mobx";

import { confirm, ConfirmOptions, flatten } from "@bps/fluent-ui";
import { DateTime, Interval } from "@bps/utils";
import {
  Conflict,
  getOutsideWarningHoursConfirmOptions
} from "@modules/booking/screens/booking-calendar/components/edit-user-day-working-hours-dialog/OutsidePracticeHoursWarning.tsx";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  UserWorkingHoursOverridesFormValues,
  UserWorkingHoursOverridesValues
} from "../UserWorkingHoursOverridesScreen.types.ts";

export class UserWorkingHoursOverridesScreenHelper {
  constructor(
    private root: RootStore,
    userAvailability: UserAvailabilityModel
  ) {
    this.setUserAvailability(userAvailability);
  }

  @observable userAvailability: UserAvailabilityModel;

  @action
  setUserAvailability = (userAvailability: UserAvailabilityModel) => {
    this.userAvailability = userAvailability;
  };

  onSubmit = async (
    form: Pick<FormApi<UserWorkingHoursOverridesValues>, "getState" | "submit">
  ) => {
    const { values } = form.getState();
    const activeOverrides = values.overrides.filter(
      override =>
        override.atWork === "1" &&
        override.startDate &&
        DateTime.fromJSDate(override.startDate) >= DateTime.today()
    );

    const conflictPromises = activeOverrides.map(this.getConflicts);
    const overrideConflicts = await Promise.all(conflictPromises);
    const conflicts = flatten(overrideConflicts);

    if (conflicts.length > 0) {
      const user = await this.root.core.getUser(this.userAvailability.userId);

      const isConfirmed = await this.confirm(
        getOutsideWarningHoursConfirmOptions(conflicts, user)
      );

      if (!isConfirmed) {
        return;
      }
    }

    form.submit();
  };

  getConflicts = async (
    override: UserWorkingHoursOverridesFormValues
  ): Promise<Conflict[]> => {
    const conflicts: Conflict[] = [];

    // these values will be true but double checking anyway to satisfy TypeScript
    if (override.atWork && override.startDate && override.endDate) {
      const from = DateTime.fromJSDate(override.startDate).startOf("day");

      const to = DateTime.fromJSDate(override.endDate).startOf("day").plus({
        days: 1
      });

      const practiceOpenHours = await this.root.booking.getOrgUnitWorkingHours({
        from,
        to,
        orgUnitId: this.root.core.locationId
      });

      let dates = Interval.fromDateTimes(from, to)
        .splitBy({ days: 1 })
        .map(d => d.start);

      if (dates.length > 1) {
        dates = dates.filter(date => date.weekday !== 6 && date.weekday !== 7);
      }

      dates.forEach(date => {
        const start = DateTime.fromJSDateAndTime(
          date.toJSDate(),
          override.startTime
        );

        const end = DateTime.fromJSDateAndTime(
          date.toJSDate(),
          override.endTime
        );

        const practiceHours = practiceOpenHours?.timeRanges.find(timeRange =>
          timeRange.from.hasSame(date, "day")
        );
        if (practiceHours) {
          if (practiceHours.from > start || practiceHours.to < end) {
            conflicts.push({ start, end, practiceHours });
          }
        } else {
          conflicts.push({ start, end });
        }
      });
    }

    return conflicts;
  };

  // This is separated out to allow it to be easily mocked for testing purposes
  confirm = (options: ConfirmOptions) => confirm(options);
}
