import { action, observable } from "mobx";

export class BillingScreenHelper {
  @observable modalInvoiceId: string | undefined;

  @action setModalInvoiceId = (value: string | undefined) => {
    this.modalInvoiceId = value;
  };

  onDismissModalInvoice = () => {
    this.setModalInvoiceId(undefined);
  };
}
