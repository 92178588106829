import { ClaimAddressDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { ClaimPatientFormValues } from "@shared-types/acc/claim-patient-values.type.ts";
import { PersonalDetailsValuesType } from "@shared-types/acc/personal-details-values.type.ts";

import { ClaimFormManagementValues } from "./types/claim-form-management-values.types.ts";

export const claimFormNameOf = nameOfFactory<ClaimFormValues>();
export const claimManagementFormNameOf =
  nameOfFactory<ClaimFormManagementValues>();
const personalDetailsNameOf = nameOfFactory<PersonalDetailsValuesType>();
const patient = personalDetailsNameOf("patient");
export const patientFieldNames = (key: keyof ClaimPatientFormValues) =>
  patient ? `${patient}.${String(key)}` : key;
const patientNameOf = nameOfFactory<ClaimPatientFormValues>();
const patientAddress = patientNameOf("patientAddress");
export const patientAddressFieldNames = (key: keyof ClaimAddressDto) =>
  patient && patientAddress
    ? `${patient}.${patientAddress}.${String(key)}`
    : key;
