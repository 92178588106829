import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";

import { FontWeights, mergeStyles, Stack } from "@bps/fluent-ui";
import {
  ClinicalNoteSections,
  ClinicalNotesSubHeadings,
  TodaysNotes,
  TodaysNotesHeading
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { PastVisitDetailsHeading } from "./PastVisitDetailsHeading.tsx";

export interface PastVisitDetailsStructuredNotesProps {
  todaysNotes: TodaysNotes | undefined;
}

const getStructuredNotes = (todaysNotes: TodaysNotes | undefined) => {
  if (todaysNotes) {
    const examinationHeading = todaysNotes.headings?.find(
      x => x.code === ClinicalNoteSections.Examinations
    );

    const imageNote = examinationHeading?.structuredNotes?.find(
      x => x.type === ClinicalNotesSubHeadings.FullBodyImages
    );

    if (imageNote) {
      const filteredNotes = examinationHeading!.structuredNotes?.filter(
        x => x.type !== ClinicalNotesSubHeadings.FullBodyImages
      );

      const newHeading: TodaysNotesHeading = {
        ...examinationHeading!,
        structuredNotes: filteredNotes
      };

      const filteredHeadings = todaysNotes.headings?.filter(
        x => x.code !== ClinicalNoteSections.Examinations
      );
      return [newHeading].concat(filteredHeadings ?? []);
    }

    return todaysNotes.headings;
  } else return undefined;
};

export const PastVisitDetailsStructuredNotes: React.FC<PastVisitDetailsStructuredNotesProps> =
  observer(({ todaysNotes }) => {
    const [structuredNotes] = useState<TodaysNotesHeading[] | undefined>(() =>
      getStructuredNotes(todaysNotes)
    );

    return (
      <Stack tokens={{ childrenGap: 4 }}>
        {todaysNotes?.freeText && (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <div
              className={mergeStyles({ marginBottom: 16 })}
              dangerouslySetInnerHTML={{
                __html: todaysNotes?.freeText!
              }}
            />
          </Stack>
        )}

        {structuredNotes &&
          structuredNotes.map((heading, index) => {
            return (
              <Fragment key={heading.code + index.toString()}>
                {/*/ heading name/*/}
                <h3
                  className={mergeStyles({
                    fontWeight: FontWeights.bold
                  })}
                >
                  {heading.name}
                </h3>
                <Stack
                  tokens={{ childrenGap: 8 }}
                  styles={{ root: { marginBottom: 12 } }}
                >
                  {heading.structuredNotes?.map((value, index) => {
                    return (
                      <PastVisitDetailsHeading
                        key={value.sectionCode}
                        structuredNote={value}
                        index={index}
                      />
                    );
                  })}
                </Stack>

                {/*/ section note /*/}
                {heading.freeText && (
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <div
                      className={mergeStyles({ marginBottom: 12 })}
                      dangerouslySetInnerHTML={{
                        __html: heading.freeText
                      }}
                    />
                  </Stack>
                )}
              </Fragment>
            );
          })}
      </Stack>
    );
  });
