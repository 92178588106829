import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { Heading, Separator, Stack, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { useScrollToViewById } from "@ui-components/hooks/useScrollToViewById.tsx";

import { PracticeDetailsFormLabels } from "../practice-details/PracticeDetailsForm.types.tsx";
import { LocationStatus } from "./LocationDetails.types.ts";
import { LocationsList } from "./LocationsList.tsx";
import { LocationsListFilter } from "./LocationsListFilter.tsx";
import { PracticeLocationAddButton } from "./PracticeLocationAddButton.tsx";
import { fetchAllLocations } from "./utils.ts";

export const LOCATION_LIST_ID = "location-list-id";

export const PracticeDetailsLocations = observer(() => {
  const { core, routing } = useStores();

  const scroll = useScrollToViewById();

  useEffect(() => {
    scroll(routing.location.hash.replace("#", ""));
  }, [routing.location.hash, scroll]);

  const theme = useTheme();
  const { settingsSeparator } = getUserStylesSet(theme);

  if (!core.hasPermissions([Permission.PreReleaseMultiLocationAllowed]))
    return null;

  return (
    <DataFetcher<OrgUnit[]>
      fetch={({ core, practice }) => fetchAllLocations(core, practice)}
      noExceptionsHandlers
      refetchId={core.recentlyUpdatedOrgUnitKey}
    >
      {(locations, loading, error) => (
        <Stack id={LOCATION_LIST_ID} tokens={{ childrenGap: 8 }}>
          <Separator styles={settingsSeparator} />
          <Stack horizontal horizontalAlign="space-between">
            <Heading variant="section-heading-light">
              {PracticeDetailsFormLabels.locationList}
            </Heading>
            <PracticeLocationAddButton
              locations={locations}
              loading={loading}
              error={error}
            />
          </Stack>
          <LocationsListFilter>
            {state => (
              <LocationsList
                locations={locations?.filter(x => {
                  const { statusCodes, locationsSearch } = state.values;

                  const isSearchResultMatches =
                    !locationsSearch ||
                    x.name
                      .toLowerCase()
                      .includes(locationsSearch.toLowerCase());

                  // If search doesn't match, exclude the location
                  if (!isSearchResultMatches) {
                    return false;
                  }

                  // If no status codes are provided, include all locations
                  if (!statusCodes || statusCodes.length === 0) {
                    return true;
                  }

                  const isActiveSelected = statusCodes.includes(
                    LocationStatus.Active
                  );

                  const isInactiveSelected = statusCodes.includes(
                    LocationStatus.Inactive
                  );

                  // If both statuses are selected, include all locations
                  if (isActiveSelected && isInactiveSelected) {
                    return true;
                  }

                  // Check for active
                  if (isActiveSelected && !x.isInactive) {
                    return true;
                  }

                  // Check for inactive
                  if (isInactiveSelected && x.isInactive) {
                    return true;
                  }

                  // If none of the above, exclude the location
                  return false;
                })}
                loading={loading}
                error={error}
              />
            )}
          </LocationsListFilter>
        </Stack>
      )}
    </DataFetcher>
  );
});
