import {
  email,
  maxLength,
  phone,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { UserInfoFormValues } from "./UserInfoForm.types.ts";

export class UserInfoFormValidator extends Validator<UserInfoFormValues> {
  constructor() {
    super();

    this.forField("firstName", [required(), maxLength(50)]);
    this.forField("lastName", [required(), maxLength(50)]);
    this.forField("username", [required(), maxLength(50), email()]);
    this.forField("workPhone", [maxLength(50), phone]);
    this.forField("middleName", maxLength(50));
  }
}
