import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimDiagnosisChangeFormValues } from "@shared-types/acc/claim-diagnosis-change-values.type.ts";

export class DiagnosisChangeValidator extends Validator<ClaimDiagnosisChangeFormValues> {
  constructor() {
    super();

    this.forField("oldDiagnosisKey", required());
    this.forField("diagnosisSide", required());
    this.forField("diagnosisCode", required());
  }
}
