import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface SystemsReviewFormValues {
  systemicFlags?: string[];
  systemicFlagsAllClear?: boolean;
  neuroFlags?: string[];
  neuroFlagsAllClear?: boolean;
  eNTFlags?: string[];
  eNTFlagsAllClear?: boolean;
  cardioFlags?: string[];
  cardioFlagsAllClear?: boolean;
  gastroFlags?: string[];
  gastroFlagsAllClear?: boolean;
  urinaryFlags?: string[];
  maleUrinaryFlags?: string[];
  urinaryFlagsAllClear?: boolean;
  musculoFlags?: string[];
  musculoFlagsAllClear?: boolean;
  haematoFlags?: string[];
  haematoFlagsAllClear?: boolean;

  gynae?: GynaeFieldValues;
}

export interface GynaeFieldValues {
  usesContraception?: boolean;
  hasMenopausalProblems?: boolean;

  flags?: string[];
  flagsAllClear?: boolean;

  couldBePregnant?: boolean;
  lastMenstrualPeriodDate?: Date;
  pregnancyCount?: number;
  contraceptionType?: string;
  sleepDisturbances?: boolean;
  hotFlushes?: boolean;
  moodDisturbances?: boolean;
  tiredness?: boolean;
  hRT?: boolean;
  lastBreastScreenDateMonth?: number;
  lastBreastScreenDateYear?: number;
  lastCervicalScreenDateMonth?: number;
  lastCervicalScreenDateYear?: number;
  comments?: string;
}

export const systemsReviewNameOf = nameOfFactory<SystemsReviewFormValues>();
