import React from "react";

import {
  ActionButton,
  confirm,
  dataAttribute,
  DataAttributes,
  TooltipHost
} from "@bps/fluent-ui";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { MAX_ACTIVE_LOCATIONS } from "./PracticeLocationAddButton.tsx";

interface LocationStatusButtonsProps {
  locationId: string | undefined;
  locationOrgUnit: OrgUnit | undefined;
  hasAppointments: boolean | undefined;
  hasFutureWaitingListEntries: boolean | undefined;
  onReactivate: () => void;
}

enum Titles {
  deactivateLocation = "Deactivate location",
  activateLocation = "Activate location"
}

export const LocationStatusToggleButton: React.FC<
  LocationStatusButtonsProps
> = ({
  locationId,
  locationOrgUnit,
  hasAppointments,
  hasFutureWaitingListEntries,
  onReactivate
}) => {
  const { core, notification, booking } = useStores();

  if (!locationId || !core.user?.hasPracInfoAdminSecurityRole) return null;

  const removeUserAvailabilityCacheByLocationId = (locationId: string) => {
    // Deactivating a location will result in working hours for each provider at that location being changed
    // Since there is no SignalR events for those changes, we just wipe the FE cache so the app will re-get
    //  those working hours.  This is not an ideal solution because other users and other tabs will be unaffected
    //  but those situation will be unlikely to matter so we decided not to spend time developing a better solution: PBI 43128
    booking.userAvailabilityMap.forEach((userAvailabilityModel, key) => {
      if (userAvailabilityModel.availableOrgUnitIds.includes(locationId)) {
        booking.userAvailabilityMap.delete(key);
      }
    });
  };

  const handleConfirmationButtonClick = async (location: OrgUnit) => {
    await core.updateOrgUnit({
      id: locationId,
      isInactive: !location.isInactive
    });

    notification.success(
      `Location ${location.name} is now ${
        location.isInactive ? "deactivated" : "activated"
      }`
    );

    if (location.isInactive) {
      removeUserAvailabilityCacheByLocationId(locationId);
    } else {
      onReactivate();
    }
  };

  const isInactiveLocation = !!locationOrgUnit?.isInactive;

  // This should probably be replaced with something standard
  const buttonStyles = {
    root: {
      padding: "6px 16px"
    }
  };

  const onConfirmButtonClick = () =>
    locationOrgUnit && handleConfirmationButtonClick(locationOrgUnit);

  const numberOfActiveLocation = core.userOrgUnits.reduce(
    (sum, locationId) => (core.hasInactiveFlag(locationId) ? sum : sum + 1),
    0
  );

  const reachedMaxActiveLocations =
    numberOfActiveLocation >= MAX_ACTIVE_LOCATIONS;

  const getTooltipText = () => {
    if (!isInactiveLocation) {
      if (isDefaultLocation) {
        return "The default location cannot be deactivated.";
      }
      if (isOnlyLocation) {
        return "You must add another location before you can deactivate this location.";
      }
      if (hasAppointments) {
        if (hasFutureWaitingListEntries) {
          return "There are waiting list patients and booked appointments for this location. Remove these in order to deactivate the location.";
        }
        return "There are booked appointments at this location. Remove these in order to deactivate this location.";
      }
      if (hasFutureWaitingListEntries) {
        return "There are patients on the waiting list for this location. Remove or update these in order to deactivate the location.";
      }
    } else if (reachedMaxActiveLocations) {
      return `The maximum of ${MAX_ACTIVE_LOCATIONS} active locations has been reached. You must deactivate a location before reactivating this one`;
    }

    return undefined;
  };

  const onClick = async () => {
    const isConfirmed = await confirm({
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: isInactiveLocation
          ? Titles.activateLocation
          : Titles.deactivateLocation
      },
      dialogContentProps: {
        title: isInactiveLocation
          ? Titles.activateLocation
          : Titles.deactivateLocation,
        subText: isInactiveLocation
          ? "Are you sure you want to activate this location?"
          : "Any current working hours for this location will be deleted. Are you sure you want to deactivate this location?"
      }
    });
    if (isConfirmed) {
      await onConfirmButtonClick();
    }
  };

  const isDefaultLocation = !!locationOrgUnit?.isDefaultLocation;
  const isOnlyLocation = core.userOrgUnits.length === 1;

  const isDisabled = isInactiveLocation
    ? reachedMaxActiveLocations
    : isDefaultLocation ||
      isOnlyLocation ||
      hasAppointments ||
      hasFutureWaitingListEntries;

  return (
    <TooltipHost content={getTooltipText()}>
      <ActionButton
        onClick={onClick}
        styles={buttonStyles}
        disabled={isDisabled}
        {...dataAttribute(
          DataAttributes.Element,
          isInactiveLocation
            ? "location-is-active-button"
            : "location-is-inactive-button"
        )}
      >
        {isInactiveLocation ? "Activate" : "Deactivate"}
      </ActionButton>
    </TooltipHost>
  );
};
