import { observer } from "mobx-react-lite";
import { memo, useRef } from "react";

import {
  FontSizes,
  Heading,
  mergeStyles,
  MessageBar,
  MessageBarType,
  SideRailMenuItem,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { AccClinicalTab } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ReviewFormValues } from "@shared-types/acc/claim-review-values.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CardFormLayout } from "@ui-components/card-form-layout/CardFormLayout.tsx";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { ClinicalFormValuesSpy } from "../clinical-record-forms-values-spy/ClinicalRecordFormsValuesSpy.tsx";
import { CardIds, DocumentStatus } from "./ClaimReviewEnums.ts";
import { ClaimReviewFormButtons } from "./ClaimReviewFormButtons.tsx";
import { ClaimReviewValidator } from "./ClaimReviewValidator.ts";
import {
  ClaimReviewContext,
  useClaimReviewContext
} from "./models/ClaimReviewContext.ts";
import { ClaimReviewHelper } from "./models/ClaimReviewHelper.ts";
import { ReviewCard } from "./ReviewCard.tsx";
import { SummaryCard } from "./SummaryCard.tsx";

const claimReviewValidator = new ClaimReviewValidator();

interface ClaimReviewFormProps {
  claim: Claim;
}

const ClaimReviewFormBase = observer(() => {
  const { setClaimReviewTab, clinicalRecord } = usePatientRecordScreenContext();

  const claimReviewHelper = useClaimReviewContext();

  const encounterData =
    clinicalRecord.clinicalRecordFormsValuesStash.persistedValues[
      AccClinicalTab.Review
    ];

  const {
    getInitialValues,
    saveClaimReview,
    claim,
    claimReview,
    formIsDisabled,
    missingClaimData
  } = claimReviewHelper;

  const sideRailMenuItems: SideRailMenuItem[] = [
    {
      text: "Summary",
      id: CardIds.summary
    },
    {
      text: "Review",
      id: CardIds.review
    }
  ];

  const initialValues = useRef(encounterData || getInitialValues());
  const theme = useTheme();

  return (
    <SubmissionForm<ReviewFormValues>
      formName="review-form"
      disabled={formIsDisabled}
      initialValues={initialValues.current}
      delayForSettingFieldsData={500}
      onSubmit={async values => {
        await saveClaimReview(values);
      }}
      onSubmitSucceeded={values => {
        setClaimReviewTab();
        clinicalRecord.clinicalRecordFormsValuesStash.resetPersistedValues(
          AccClinicalTab.Review,
          values
        );
      }}
      validate={claimReviewValidator.validate}
      className={mergeStyles({
        margin: "-16px",
        height: "100%",
        backgroundColor: theme.semanticColors.disabledBackground
      })}
      hideButtons={true}
    >
      {() => (
        <Stack styles={{ root: { height: "100%" } }}>
          <CardFormLayout
            messageBar={
              formIsDisabled && (
                <MessageBar
                  styles={{
                    root: { flexDirection: "row" },
                    actions: { margin: "0 12px" }
                  }}
                  messageBarType={MessageBarType.warning}
                  actions={
                    <Navigate to={routes.claims.edit.path({ id: claim.id })}>
                      Update claim
                    </Navigate>
                  }
                >
                  Unable to submit a claim review as the claim is missing the
                  following: {missingClaimData.join(", ")}.
                </MessageBar>
              )
            }
            header={
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 16 }}
                styles={{ root: { width: "100%" } }}
                horizontalAlign="space-between"
                disableShrink
              >
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 16 }}
                >
                  <Heading
                    styles={{ root: { paddingLeft: 8 } }}
                    variant="section-heading"
                  >
                    Claim review
                  </Heading>
                  <Text styles={{ root: { fontSize: FontSizes.size16 } }}>
                    {claim.primaryDiagnosis?.terminology?.text}
                  </Text>
                </Stack>
                <ClaimReviewFormButtons />
              </Stack>
            }
            sideRailMenuItems={sideRailMenuItems}
          >
            <SummaryCard />
            <ReviewCard />
            <ClinicalFormValuesSpy
              clinicalRecord={clinicalRecord}
              tab={AccClinicalTab.Review}
              originalInitialValues={getInitialValues()}
            />
          </CardFormLayout>
          {claimReview?.documentStatus === DocumentStatus.deleted && (
            <MessageBar
              styles={{ root: { flex: 1, width: "auto" } }}
              messageBarType={MessageBarType.warning}
            >
              The original PDF was deleted
            </MessageBar>
          )}
        </Stack>
      )}
    </SubmissionForm>
  );
});

const ClaimReviewFormFetcher: React.FC<ClaimReviewFormProps> = memo(
  ({ claim }) => {
    const { clinicalRecord } = usePatientRecordScreenContext();

    const store = useStores();
    const claimReviewHelper = new ClaimReviewHelper(
      store,
      claim,
      clinicalRecord
    );

    const loadClaimReviewData = async () => {
      await Promise.all([
        claimReviewHelper.preloadSummaryViewValues(),
        claim.loadClaimReview()
      ]);
    };

    return (
      <DataFetcher refetchId={claim.id} fetch={loadClaimReviewData}>
        {() => (
          <ClaimReviewContext.Provider value={claimReviewHelper}>
            <ClaimReviewFormBase />
          </ClaimReviewContext.Provider>
        )}
      </DataFetcher>
    );
  }
);

export const ClaimReviewForm = withFetch(
  x => [x.practice.ref.accProviderTypes.load()],
  ClaimReviewFormFetcher
);
