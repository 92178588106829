import { maxArrayLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { AttachCorrespondeceFormValues } from "../components/AttachCorrespondenceFormFields.tsx";

export class AttachCorrespondenceValidator extends Validator<AttachCorrespondeceFormValues> {
  constructor(currentSelectedDocumentCount: number) {
    super();

    this.forField(
      "ids",
      maxArrayLength(
        6 - currentSelectedDocumentCount,
        "A total of six documents can be selected."
      )
    );
  }
}
