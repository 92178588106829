import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { DefaultButton, mergeStyleSets, Stack } from "@bps/fluent-ui";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { showUnlessPrintingStyles } from "@ui-components/printing/Print.styles.ts";
import { PrintContext } from "@ui-components/printing/Print.tsx";

import { AppointmentInformationEditButton } from "./AppointmentInformationEditButton.tsx";

interface AppointmentInformationModalButtonsProps {
  onDismiss: () => void;
  selectionCount: number | undefined;
  noAppointments: boolean;
  apptItems: CalendarEvent[];
}

export const AppointmentInformationModalButtons: FC<AppointmentInformationModalButtonsProps> =
  observer(({ apptItems, noAppointments, onDismiss, selectionCount }) => {
    const print = useContext(PrintContext);

    return (
      <Stack
        tokens={{ childrenGap: 8 }}
        horizontal
        styles={mergeStyleSets(showUnlessPrintingStyles, {
          root: {
            paddingTop: 40
          }
        })}
      >
        <Stack grow horizontalAlign="start">
          <DefaultButton
            iconProps={{ iconName: "Print" }}
            onClick={() => print.print?.()}
          >
            Print
          </DefaultButton>
        </Stack>
        <AppointmentInformationEditButton
          selectionCount={selectionCount}
          noAppointments={noAppointments}
          apptItems={apptItems}
        />
        <DefaultButton onClick={onDismiss} text="Close" />
      </Stack>
    );
  });
