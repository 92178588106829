import { FunctionComponent } from "react";

import {
  FontIcon,
  FontWeights,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { addressText } from "@stores/core/models/Address.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface PatientContactCellProps {
  patient?: Contact;
}
const enum tooltipPhrases {
  smsConsentReceived = "SMS consent received",
  preferredContactMethod = "Preferred contact method",
  noSMSConsent = "No SMS consent"
}
export const PatientContactCell: FunctionComponent<PatientContactCellProps> = ({
  patient
}) => {
  const { semanticColors, palette } = useTheme();
  if (!patient) return null;

  const { communications, contactPreferences } = patient;
  const sortedCommunications = [...communications].sort(
    (a, b) => (b.preferred ? 1 : 0) - (a.preferred ? 1 : 0)
  );

  const hasOptedOut =
    contactPreferences?.commTypePreferences &&
    contactPreferences.commTypePreferences[OutboundCommType.ApptReminder]
      ?.contactHasOptedOut;

  const hasNotConsented =
    contactPreferences?.apptRemPreferences?.preferredCommChannelTypeCode ===
    undefined;

  const address = patient.postalAddress
    ? addressText(patient.postalAddress)
    : "No postal address recorded";

  const getIconDetails = (
    type: CommunicationType,
    preferred: boolean | undefined
  ) => {
    let iconColor = semanticColors.disabledText;
    let iconName = "Mail";
    let tipContent = "";

    switch (type) {
      case CommunicationType.Mobile:
        if (!hasNotConsented && !hasOptedOut) {
          iconColor = palette.green;
          iconName = "MobileSelected";
          tipContent = preferred
            ? `${tooltipPhrases.smsConsentReceived}.\r\n${tooltipPhrases.preferredContactMethod}`
            : tooltipPhrases.smsConsentReceived;
        } else {
          tipContent = tooltipPhrases.noSMSConsent;
          iconName = "CellPhone";
        }
        break;
      case CommunicationType.HomePhone:
        if (preferred) {
          iconColor = semanticColors.primaryButtonBackground;
          tipContent = tooltipPhrases.preferredContactMethod;
        }
        iconName = "Home";
        break;
      case CommunicationType.WorkPhone:
        if (preferred) {
          iconColor = semanticColors.primaryButtonBackground;
          tipContent = tooltipPhrases.preferredContactMethod;
        }
        iconName = "Work";
        break;
      case CommunicationType.Email:
        if (preferred) {
          iconColor = semanticColors.primaryButtonBackground;
          tipContent = tooltipPhrases.preferredContactMethod;
        }
        iconName = "Mail";
        break;
      default:
    }
    return { iconColor, iconName, tipContent };
  };

  return (
    <Stack horizontal wrap tokens={{ childrenGap: 8 }} verticalAlign="center">
      {sortedCommunications.map(communication => {
        const { iconColor, iconName, tipContent } = getIconDetails(
          communication.type,
          communication.preferred
        );
        if (!iconName) {
          return null;
        }

        return (
          <Stack
            key={`${communication.value}-${iconName.toLowerCase()}`}
            tokens={{ childrenGap: 4 }}
            verticalAlign="center"
          >
            <TooltipHost content={tipContent}>
              <Stack
                horizontal
                tokens={{ childrenGap: 4 }}
                verticalAlign="center"
              >
                <FontIcon
                  iconName={iconName}
                  styles={{
                    root: {
                      color: iconColor,
                      fontSize: 16
                    }
                  }}
                />
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      fontWeight: communication.preferred
                        ? FontWeights.semibold
                        : FontWeights.regular
                    }
                  }}
                >
                  {communication.value}
                </Text>
              </Stack>
            </TooltipHost>
          </Stack>
        );
      })}
      <Text variant="small">{address}</Text>
    </Stack>
  );
};
