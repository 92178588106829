import { required } from "@libs/validation/fieldValidators.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";

import { AddTemplateFormValues } from "./AddTemplateFormValues.ts";

export class AddTemplateFormValidator extends Validator<AddTemplateFormValues> {
  constructor(
    private templateList: OutboundCommTemplate[],
    private templateId: string
  ) {
    super();

    this.forField("templateName", [required()]);
    this.forField("templateType", [required()]);
    this.forField("templateName", this.notDuplicateName());
  }
  notDuplicateName =
    (): FieldValidator => (value: string | undefined | null) => {
      if (!value) {
        return undefined;
      }

      if (
        this.templateList?.some(
          x => x.name === value && x.id !== this.templateId
        )
      ) {
        return "Duplicate name.";
      }

      return undefined;
    };
}
