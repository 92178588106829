import { InboxDocumentSearchArgs } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { User } from "@stores/core/models/User.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import { IncomingLabels } from "../IncomingScreen.types.ts";

type IncomingFilterProps = Pick<FilterBarProps, "children">;

export interface IncomingFilterValues
  extends Omit<InboxDocumentSearchArgs, "receivedDate"> {
  receivedDate?: Date;
}

export const IncomingFilter: React.FC<IncomingFilterProps> = ({ children }) => {
  return (
    <FilterBar<IncomingFilterValues>
      initialValues={{}}
      items={[
        {
          type: "searchBox",
          name: "keyword",
          stickItem: true,
          props: {
            placeholder: IncomingLabels.SearchFileName,
            autoFocus: true
          }
        },
        {
          type: "contactPicker",
          name: "patientId",
          props: {
            inputProps: {
              placeholder: IncomingLabels.SearchPatient,
              name: "incoming-patient-filter"
            },
            iconName: "Search",
            pickerCalloutProps: {
              calloutWidth: 300
            },
            pickerSuggestionsProps: {
              noResultsFoundText: IncomingLabels.NoMatch
            },
            filter: {
              types: [ContactType.Patient]
            }
          }
        },
        {
          type: "userPicker",
          name: "assignedToUserId",
          props: {
            inputProps: {
              placeholder: IncomingLabels.AddressedTo,
              name: "incoming-user-filter"
            },
            iconName: "Search",
            onResolveSuggestionItem: (user: User) => ({
              text: user.fullName
            })
          }
        },
        {
          type: "datePicker",
          name: "receivedDate",
          props: {
            allowClear: true,
            placeholder: IncomingLabels.Date
          }
        }
      ]}
      styles={{ peoplePicker: { minWidth: 206 } }}
      children={children}
    />
  );
};
