import { IButtonStyles, ITheme } from "@bps/fluent-ui";

export const PracticeAccContractsStyles = {
  root: (_: any, theme: ITheme) => ({
    root: {
      borderBottom: "1px solid",
      borderColor: theme.palette.neutralLighterAlt
    }
  }),
  heading: {
    root: { lineHeight: 25, marginBottom: 11.5, marginTop: 3.5 }
  },
  buttonMenuProps: { container: { width: 250 } },
  contractTypeItem: (_: any, theme: ITheme) => ({
    root: {
      padding: 8,
      borderRadius: 2,
      border: `1px solid ${theme.palette.neutralLight}`
    }
  }),
  deleteButton: {
    root: {
      height: "auto",
      marginTop: 29,
      marginLeft: 8,
      border: 0
    }
  } as IButtonStyles
};
