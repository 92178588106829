import { observer } from "mobx-react-lite";
import { FC } from "react";

import { EmailColumnWrapper } from "@modules/practice/screens/address-book/components/contacts-lists/EmailColumnWrapper.tsx";
import { IndividualAndOrganisationNavigate } from "@modules/practice/screens/address-book/components/contacts-lists/Individual-organisation-columns/IndividualAndOrganisationNavigate.tsx";
import { useShowContactDetails } from "@modules/practice/screens/address-book/components/hooks/useShowContactDetails.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export const IndividualAndOrganisationEmailColumn: FC<{ contact: Contact }> =
  observer(({ contact }) => {
    const showContactDetails = useShowContactDetails(contact);
    return (
      <EmailColumnWrapper contact={contact}>
        <IndividualAndOrganisationNavigate
          contact={contact}
          onClick={() => showContactDetails()}
        >
          Multiple emails
        </IndividualAndOrganisationNavigate>
      </EmailColumnWrapper>
    );
  });
