import { email, required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { SendClinicalDocumentDialogValues } from "./SendClinicalDocumentDialog.tsx";

export class SendClinicalDocumentDialogValidator extends Validator<SendClinicalDocumentDialogValues> {
  constructor() {
    super();
    this.forField("email", [required(), email(ValidationMessages.email)]);
  }
}
