import { useState } from "react";
import { useField } from "react-final-form";

import { ActionButton, Heading, Stack } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { DOCUMENT_ATTACHMENT_LIMIT } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.types.ts";
import { ClaimAdjustmentDocumentFormValue } from "@shared-types/acc/claim-adjustment-document-value.type.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { useAttachedDocumentCount } from "../hooks/useAttachedDocumentCount.tsx";
import { AttachCorrespondenceValidator } from "../validators/AttachCorrespondenceValidator.tsx";
import {
  AttachCorrespondeceFormValues,
  AttachCorrespondenceFormFields,
  getClaimDocumentFormValues
} from "./AttachCorrespondenceFormFields.tsx";

interface AttachCorrespondenceSelectionModalProps {
  onDocumentsAttached: (
    selectedDocuments: ClaimAdjustmentDocumentFormValue[]
  ) => void;
}

export const AttachCorrespondenceSelectionModal: React.FC<
  AttachCorrespondenceSelectionModalProps
> = ({ onDocumentsAttached }) => {
  const { documentResults } = useClaimAdjustmentContext();

  const {
    input: { value: documents }
  } = useField("documents", {
    subscription: { value: true }
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const attachedDocumentCount = useAttachedDocumentCount();

  const onSubmit = (values: AttachCorrespondeceFormValues) => {
    const selectedDocuments = values.ids.reduce(
      (docs: ClinicalDocument[], id) => {
        const doc = documentResults?.find(x => x.id === id);
        if (doc) docs.push(doc);
        return docs;
      },
      []
    );

    onDocumentsAttached(getClaimDocumentFormValues(selectedDocuments));
  };

  return (
    <>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <Heading labelPaddings>Files</Heading>
        <div>
          {attachedDocumentCount < DOCUMENT_ATTACHMENT_LIMIT && (
            <ActionButton
              styles={{ root: { height: 38 } }}
              iconProps={{ iconName: "attach" }}
              title="Attach"
              text="Attach from correspondence"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
      </Stack>

      {showModal && (
        <SubmissionFormDialog<AttachCorrespondeceFormValues>
          dialogName="Select correspondence"
          validate={
            new AttachCorrespondenceValidator(attachedDocumentCount).validate
          }
          onSubmitSucceeded={() => setShowModal(false)}
          onSubmit={onSubmit}
          buttonsProps={{
            disableSubmitOnPristine: true,
            disableSubmitOnFormInvalid: true,
            hideButtonsSeparator: true,
            submitButtonProps: {
              text: "Attach",
              iconProps: { iconName: undefined }
            }
          }}
          dialogProps={{
            minWidth: 480,
            maxWidth: 480,
            onDismiss: () => setShowModal(false),
            dialogContentProps: {
              title: (
                <Heading variant="modal-heading">
                  Select correspondence (maximum 6)
                </Heading>
              )
            }
          }}
        >
          {() => (
            <AttachCorrespondenceFormFields
              previouslySelectedDocuments={documents}
            />
          )}
        </SubmissionFormDialog>
      )}
    </>
  );
};
