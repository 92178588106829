import React from "react";
import { useForm } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { ActionButton, confirm, Stack } from "@bps/fluent-ui";
import {
  EnduringPowerOfAttorneyDto,
  NO,
  YES
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { SocialFormValues } from "./SocialFormValues.ts";

export const PowerOfAttorneyList: React.FunctionComponent = () => {
  const socialForm = useForm<SocialFormValues>();
  const changeIsEnduringPowerOfAttorney = async () => {
    const { getState, change } = socialForm;
    const attorneyList =
      getState().values.patientDemographic.powerOfAttorneys?.map(p => ({
        ...p
      }));
    if (
      getState().values?.patientDemographic.isEnduringPowerOfAttorney === YES
    ) {
      if (!attorneyList || (attorneyList && attorneyList.length === 0)) {
        addNewAttorney();
      }
    } else {
      if (attorneyList && attorneyList.length > 0) {
        if (attorneyList.filter(x => x.id)?.length > 0) {
          if (
            await confirm({
              dialogContentProps: {
                title: "Remove enduring power of attorney record/s",
                subText:
                  "This action will remove all related records. Are you sure?"
              },
              confirmButtonProps: {
                text: "Confirm"
              },
              cancelButtonProps: {
                text: "Cancel"
              },
              minWidth: 500
            })
          ) {
            change(
              "patientDemographic.powerOfAttorneys" as keyof SocialFormValues,
              [] as any
            );
          } else {
            change(
              "patientDemographic.isEnduringPowerOfAttorney" as keyof SocialFormValues,
              YES as any
            );
          }
        } else {
          change(
            "patientDemographic.powerOfAttorneys" as keyof SocialFormValues,
            [] as any
          );
        }
      }
    }
  };

  const addNewAttorney = () => {
    const { mutators } = socialForm;
    mutators.push("patientDemographic.powerOfAttorneys", {
      id: ""
    } as EnduringPowerOfAttorneyDto);
  };

  const removeAttorneyRow = (attorneyRowNumber: number) => {
    const { getState, mutators, change } = socialForm;
    mutators.remove("patientDemographic.powerOfAttorneys", attorneyRowNumber);
    const attorneyList =
      getState().values.patientDemographic.powerOfAttorneys?.map(p => ({
        ...p
      }));
    if (!attorneyList || (attorneyList && attorneyList.length <= 0)) {
      change(
        "patientDemographic.isEnduringPowerOfAttorney" as keyof SocialFormValues,
        NO as any
      );
    }
  };

  return (
    <Fieldset>
      <FieldArray name="patientDemographic.powerOfAttorneys">
        {(
          fieldProps: FieldArrayRenderProps<
            EnduringPowerOfAttorneyDto,
            HTMLElement
          >
        ) => {
          const attorneys = fieldProps.fields.value;
          return (
            attorneys &&
            attorneys.length > 0 && (
              <Stack tokens={{ childrenGap: 8 }}>
                {attorneys.map(
                  (item: EnduringPowerOfAttorneyDto, index: number) => {
                    return (
                      <Stack key={`attorney${item.id}`} horizontal>
                        <ContactPickerFieldWithAdd
                          name={`patientDemographic.powerOfAttorneys[${index}].id`}
                          filter={{
                            types: [ContactType.Individual, ContactType.Patient]
                          }}
                          placeholder="Search for a person"
                          wrapperStyles={{
                            root: { minWidth: 400, marginRight: 5 }
                          }}
                          tagInUseKeys={attorneys?.map(v => v.id)}
                        />

                        <DeleteButton
                          onClick={() => removeAttorneyRow(index)}
                        />
                      </Stack>
                    );
                  }
                )}
                <ActionButton
                  iconProps={{ iconName: "Add" }}
                  title="Add more"
                  onClick={addNewAttorney}
                  styles={{ root: { width: 400 } }}
                >
                  Add another enduring power of attorney
                </ActionButton>
              </Stack>
            )
          );
        }}
      </FieldArray>
      <FieldSpy
        name="patientDemographic.isEnduringPowerOfAttorney"
        onChange={changeIsEnduringPowerOfAttorney}
      />
    </Fieldset>
  );
};
