import { memo, useContext, useEffect, useState } from "react";
import { useField } from "react-final-form";

import { Spinner, Stack, Text } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DatePickerArrows } from "@ui-components/pickers/DatePickerArrows.tsx";

import {
  appointmentFormNameOf,
  GroupApptSlot,
  GroupApptSlots
} from "../AppointmentForm.types.ts";
import { EmptyState } from "../next-available-fields/EmptyState.tsx";
import { ProviderTimeSlotsHeader } from "../next-available-fields/ProviderTimeSlotsHeader.tsx";
import { AppointmentSlot } from "./AppointmentSlot.tsx";
import { appointmentSlotsGridStyles } from "./NextGroupAppts.styles.ts";

interface ProviderAppointmentsProps {
  pickerStartDate: Date;
  providerAppointments: GroupApptSlots;
}

export const ProviderAppointments: React.FunctionComponent<ProviderAppointmentsProps> =
  memo(({ pickerStartDate, providerAppointments }) => {
    const {
      selectedProviders,
      getGroupApptToggleText,
      getNextAvailableDate,
      getUpcomingGroupAppointments
    } = useContext(AppointmentFormContext);

    const [sliderStartDate, setSliderStartDate] = useState<Date | undefined>(
      pickerStartDate
    );

    const [_isExpanded, setIsExpanded] = useState(false);
    const [appointmentTimes, setAppointmentTimes] = useState<GroupApptSlot[]>(
      providerAppointments.appointmentTimes ?? []
    );

    useEffect(() => {
      setSliderStartDate(pickerStartDate);
    }, [pickerStartDate]);

    const {
      input: { value: appointmentType }
    } = useField<string>(appointmentFormNameOf("appointmentTypeId"), {
      subscription: { value: true }
    });

    const isSingleProvider = selectedProviders.length === 1;
    const isExpanded = _isExpanded || isSingleProvider;
    const minDate = DateTime.today().toJSDate();

    const changeDate = async (date: Date | undefined) => {
      setSliderStartDate(date);
      if (!date) return;
      if (appointmentType) {
        const newTimeSlots = await getUpcomingGroupAppointments(
          date,
          [providerAppointments.providerId],
          appointmentType
        );
        setAppointmentTimes(newTimeSlots[0].appointmentTimes);
      }
    };

    const availableApptGroup = isExpanded
      ? appointmentTimes
      : appointmentTimes.slice(0, 3);

    return (
      <Stack.Item
        styles={(_props, theme) => ({
          root: {
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.neutralLighter,
            borderBottomWidth: 1,
            paddingBottom: 16
          }
        })}
      >
        <Stack
          grow
          verticalAlign="center"
          tokens={{ childrenGap: 8 }}
          styles={{ root: { marginTop: 8, marginRight: 8 } }}
        >
          <ProviderTimeSlotsHeader
            providerId={providerAppointments.providerId}
            moreText={getGroupApptToggleText(appointmentTimes)}
            toggleIsExpanded={() => setIsExpanded(value => !value)}
            isExpanded={isExpanded}
          />

          <Stack verticalAlign="center" horizontalAlign="center">
            {isExpanded && (
              <DatePickerArrows
                calendarProps={{
                  minDate,
                  calendarMonthProps: {
                    onNavigateDate: changeDate
                  },
                  calendarDayProps: {
                    onNavigateDate: changeDate
                  }
                }}
                value={sliderStartDate}
                onChange={changeDate}
              />
            )}

            {sliderStartDate && (
              <Stack
                verticalFill
                horizontalAlign="center"
                verticalAlign={providerAppointments ? "start" : "center"}
              >
                {providerAppointments.providerId &&
                appointmentTimes.length > 0 ? (
                  <>
                    <Text styles={{ root: { margin: 8 } }}>
                      Existing appointments
                    </Text>
                    <div style={appointmentSlotsGridStyles}>
                      {availableApptGroup.map((appointment: GroupApptSlot) => {
                        return (
                          <AppointmentSlot
                            providerId={providerAppointments.providerId}
                            pickerStartDate={pickerStartDate}
                            sliderStartDate={sliderStartDate}
                            isExpanded={isExpanded}
                            slot={appointment}
                            key={`${providerAppointments.providerId}-${appointment.startTime}`}
                          />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <DataFetcher
                    refetchId={
                      providerAppointments.providerId + sliderStartDate
                    }
                    fetch={() =>
                      getNextAvailableDate(
                        providerAppointments.providerId,
                        sliderStartDate,
                        appointmentType
                      )
                    }
                    fallback={<Spinner />}
                  >
                    {nextAvailableAppointmentDate => {
                      const nextAvailableText = nextAvailableAppointmentDate
                        ? `Next available appointment is at ${nextAvailableAppointmentDate.toFormat(
                            TIME_FORMATS.DEFAULT_TIME_FORMAT
                          )}`
                        : "There are no available times for this provider.";
                      return (
                        <EmptyState
                          changeDate={changeDate}
                          setIsExpanded={setIsExpanded}
                          nextAvailableLinkText={nextAvailableAppointmentDate}
                          nextAvailableText={nextAvailableText}
                        />
                      );
                    }}
                  </DataFetcher>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack.Item>
    );
  });
