import { observer } from "mobx-react-lite";
import {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect
} from "react";
import { useField } from "react-final-form";

import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { AppointmentFormContext } from "../context/AppointmentFormContext.ts";

export const AppointmentNoticesContactPickerWrapper: FunctionComponent<PropsWithChildren> =
  observer(({ children }) => {
    const { showPatientNoticesColumn } = useContext(AppointmentFormContext);

    const {
      booking: {
        ui: { setSecondColumnContent, currentAppointment }
      }
    } = useStores();

    const {
      input: { value: patientId }
    } = useField(appointmentFormNameOf("patientId"));

    useEffect(() => {
      if (!patientId) {
        !!currentAppointment?.secondColumnContent &&
          setSecondColumnContent(undefined);
      } else if (
        currentAppointment?.secondColumnContent ===
        SecondColumnContent.nextAvailable
      ) {
        return;
      } else {
        !currentAppointment?.secondColumnContent &&
          showPatientNoticesColumn(patientId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
  });
