import { useForm } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { ClaimAdjustmentReasons } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { claimAdjustmentModalFormNameOf } from "../claim-adjustment.utils.ts";

export const VerballyApprovedFields = () => {
  const form = useForm();
  const { claimAdjustment } = useClaimAdjustmentContext();

  // do not show on edit
  if (claimAdjustment) {
    return null;
  }
  return (
    <>
      <FieldSpy
        name={claimAdjustmentModalFormNameOf("verballyApproved")}
        onChange={val => {
          if (val) {
            form.batch(() => {
              form.change(
                claimAdjustmentModalFormNameOf("claimAdjustmentReasons"),
                [ClaimAdjustmentReasons.hasTreatmentDetails]
              );
              form.change(
                claimAdjustmentModalFormNameOf("isVoluntarySubmission"),
                false
              );
            });
          } else {
            form.batch(() => {
              form.change(claimAdjustmentModalFormNameOf("responseDate"));
              form.change(
                claimAdjustmentModalFormNameOf("purchaseOrderNumber")
              );
              form.change(
                claimAdjustmentModalFormNameOf("purchaseOrderNumber")
              );
              form.change(
                claimAdjustmentModalFormNameOf("claimAdjustmentReasons")
              );
              form.change(
                claimAdjustmentModalFormNameOf("isVoluntarySubmission"),
                false
              );
            });
          }
        }}
      />
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <div>
          <Heading labelPaddings hasAsterisk>
            Verbal approval
          </Heading>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <YesNoToggleField
              name={claimAdjustmentModalFormNameOf("verballyApproved")}
              styles={{ root: { paddingTop: 7, width: 78 } }}
            />
            <FieldCondition
              when={claimAdjustmentModalFormNameOf("verballyApproved")}
              is={true}
            >
              <DatePickerField
                name={claimAdjustmentModalFormNameOf("responseDate")}
              />
            </FieldCondition>
          </Stack>
        </div>
        <FieldCondition
          when={claimAdjustmentModalFormNameOf("verballyApproved")}
          is={true}
        >
          <TextInputField
            label="Purchase order"
            name={claimAdjustmentModalFormNameOf("purchaseOrderNumber")}
          />
        </FieldCondition>
      </Stack>
    </>
  );
};
