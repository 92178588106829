import { createContext, useContext } from "react";

import { SOTAPFollowOnFormModel } from "./SOTAPFollowOnFormModel.ts";

export const SOTAPFollowOnFormContext = createContext<SOTAPFollowOnFormModel>(
  {} as SOTAPFollowOnFormModel
);

export const useSOTAPFollowOnFormContext = () =>
  useContext(SOTAPFollowOnFormContext);
