import { FunctionComponent, useEffect, useState } from "react";

import { dataAttribute, DataAttributes, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { AutofillContext } from "@ui-components/text-editors/editor-autofills/editor-autofill.types.ts";
import { TextEditor } from "@ui-components/text-editors/TextEditor.tsx";

import { getFreeTextStyles } from "./FreeTextNote.styles.ts";
import { FreeTextProps } from "./FreeTextNote.types.ts";
import { FreeTextNoteWrapper } from "./FreeTextNoteWrapper.tsx";

export const FreeTextNote: FunctionComponent<FreeTextProps> = ({
  defaultValue,
  onChange,
  heading,
  boldHeading,
  placeholder = "Start typing here to add notes",
  stickyToolbar = true
}) => {
  const theme = useTheme();
  const { core } = useStores();
  const [mainFreeText, setMainFreeText] = useState(defaultValue);
  // want to pass the base context into the autofill so we can render templates with basic merge fields
  const { clinicalRecord } = usePatientRecordScreenContext();
  const context: AutofillContext = {
    PatientId: clinicalRecord.id,
    UserId: core.userId
  };

  useEffect(() => {
    if (mainFreeText !== defaultValue) {
      onChange(mainFreeText);
    }
  }, [defaultValue, mainFreeText, onChange]);

  return (
    <FreeTextNoteWrapper heading={heading} boldHeading={boldHeading}>
      {(toolBarHidden, autoFillBarHidden) => (
        <TextEditor
          {...dataAttribute(DataAttributes.Element, "free-text-note")}
          value={mainFreeText}
          onChange={setMainFreeText}
          placeholder={placeholder}
          className={getFreeTextStyles(theme, {
            hideToolbar: toolBarHidden,
            stickyToolbar
          })}
          withClinicalAutofill={core.hasPermissions(Permission.AutofillAllowed)}
          autofillContext={context}
          displayAutoFillBar={!autoFillBarHidden}
        />
      )}
    </FreeTextNoteWrapper>
  );
};
