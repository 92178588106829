import { ConditionModalFormValues } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/Condition.types.ts";
import { ConditionModal } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/ConditionModal.tsx";
import { ConditionContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionContext.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface PrivateConditionModalProps {
  condition: Condition;
  onDismiss: () => void;
}

export const PrivateConditionModal: React.FC<PrivateConditionModalProps> = ({
  condition,
  onDismiss
}) => {
  const root = useStores();

  const initialCondition = ConditionHelper.getInitialCondition(condition);

  const conditionHelper = new ConditionHelper(root, condition.patientId, {
    onSubmit: async (
      values: ConditionModalFormValues,
      helper: ConditionHelper
    ) => {
      await helper.updateCondition(
        values,
        values.referralIn ? condition.nextCalendarEventProviderId : undefined
      );
    },
    initialCondition
  });

  conditionHelper.setHiddenConditionModal(false);

  return (
    <ConditionContext.Provider value={conditionHelper}>
      <ConditionModal onDismiss={onDismiss} />
    </ConditionContext.Provider>
  );
};
