import {
  FontWeights,
  IStackItemStyles,
  IStackStyles,
  IStyle,
  ITheme,
  noWrap
} from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";

import { getAppointmentEventColors } from "../utils.tsx";
import { BigCalendarEvent } from "./BookingCalendarEvent.types.ts";

export const getBookingCalendarEventStylesSet = (theme: ITheme) => {
  const getProviderCommentIconsStyle = (event: BigCalendarEvent): IStyle => {
    if (event.isPseudoEvent) {
      return { display: "none" };
    }

    const eventColors = getAppointmentEventColors(event, theme);
    return {
      padding: "1px 2px 1px 1px",
      backgroundColor: eventColors.dark,
      minWidth: 0
    };
  };

  const getBookingCalendarNameWrapperStyles = (
    event: BigCalendarEvent
  ): IStackStyles => {
    if (event.type === CalendarEventType.TemporaryReservation) {
      return {
        root: {
          minWidth: 0,
          justifyContent: "center",
          ".ms-StackItem": { fontSize: "10px" }
        }
      };
    }

    return { root: { minWidth: 0, ".ms-StackItem": { fontSize: "10px" } } };
  };

  const getBookingCalendarEventStyles = (
    event: BigCalendarEvent
  ): IStackStyles => {
    const eventColors = getAppointmentEventColors(event, theme);

    return {
      root: {
        height: "100%",
        backgroundColor: eventColors.light,
        flexGrow: 1,
        paddingRight: 3,
        paddingTop: 0,
        minWidth: 0
      },
      inner: { paddingTop: 2, height: "auto", minWidth: 0, flexWrap: "nowrap" }
    };
  };

  const bookingCalendarEventMainStyles: IStackStyles = {
    root: { flexGrow: 1, minWidth: 0, paddingLeft: 2 }
  };

  const bookingCalendarEventNameStyle: IStyle = {
    fontSize: theme.fonts.small.fontSize,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: FontWeights.semibold,
    height: 20,
    flexGrow: 1
  };

  const bookingCalendarEventNoChargeStyles: IStackItemStyles = {
    root: {
      color: theme.palette.neutralSecondaryAlt,
      fontSize: 10,
      textOverflow: "initial",
      flexShrink: 0,
      paddingTop: 3,
      whiteSpace: "nowrap"
    }
  };

  const bookingCalendarEventContentStyles: IStackItemStyles = {
    root: {
      fontSize: 12,
      ...noWrap,
      wordBreak: "break-all",
      paddingTop: 3,
      height: 20
    }
  };

  return {
    bookingCalendarEventNoChargeStyles,
    bookingCalendarEventContentStyles,
    getBookingCalendarEventStyles,
    bookingCalendarEventNameStyle,
    bookingCalendarEventMainStyles,
    getBookingCalendarNameWrapperStyles,
    getProviderCommentIconsStyle
  };
};
