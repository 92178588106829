import { FormApi } from "final-form";

import { Heading, PrimaryButton, Spinner } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

export interface ConditionConsultsExportDialogProps {
  patientName: string;
  diagnosis: string;
  claimBadgeText: string;
  patientId: string;
  claimId: string | undefined;
  claimNumber?: string;
  episodeOfCareId: string;
  onDialogSubmitSucceeded?: (id: string) => void;
}

export interface ConditionConsultEncounterItem {
  id: string;
  label: string;
  selected: boolean;
}

export interface ConditionConsultsExportDialogValues {
  encounters: ConditionConsultEncounterItem[];
}

export const ConditionConsultsExportDialog: React.FC<
  ConditionConsultsExportDialogProps
> = ({
  diagnosis,
  claimBadgeText,
  patientName,
  patientId,
  claimId,
  episodeOfCareId,
  claimNumber
}) => {
  const root = useStores();
  const { clinical } = root;
  const { setConditionConsultsExportDialogVisible } = clinical.ui;

  const {
    onExportToEditorButtonClick,
    onExportToPdfButtonClick,
    getInitialItems
  } = usePatientRecordScreenContext();

  const nameOf = nameOfFactory<ConditionConsultsExportDialogValues>();

  const getExtraButtonsBefore = (
    form: FormApi<ConditionConsultsExportDialogValues>
  ): JSX.Element => {
    const selectedEncounters = form
      .getState()
      .values.encounters.filter(x => x.selected);
    return (
      <PrimaryButton
        id="export-editor"
        text="Export to PDF"
        disabled={selectedEncounters.length === 0}
        onClick={() => handleOnExportToPdfButtonClick(selectedEncounters)}
      />
    );
  };

  const dismissDialog = () => setConditionConsultsExportDialogVisible(false);

  const handleExportToEditorButtonClick = (
    values: ConditionConsultsExportDialogValues
  ) =>
    onExportToEditorButtonClick({
      claimId,
      claimNumber,
      diagnosis,
      episodeOfCareId,
      patientId,
      encounters: values.encounters
    });

  const handleOnExportToPdfButtonClick = (
    encounters: ConditionConsultEncounterItem[]
  ) =>
    onExportToPdfButtonClick({
      claimId,
      claimNumber,
      diagnosis,
      episodeOfCareId,
      patientId,
      encounters
    });

  return (
    <DataFetcher
      fetch={async () => await getInitialItems(patientId, episodeOfCareId)}
      fallback={<Spinner />}
    >
      {encounters => {
        return (
          <SubmissionFormDialog<ConditionConsultsExportDialogValues>
            dialogName="Export condition consultation notes"
            initialValues={{ encounters }}
            onSubmit={handleExportToEditorButtonClick}
            buttonsProps={({ getState }) => {
              const { values } = getState();
              const selectedEncounters = values.encounters.filter(
                x => x.selected
              );

              return {
                hideButtonsSeparator: true,
                disableSubmitOnFormInvalid: false,
                disableCancelOnPristine: false,
                submitButtonProps: {
                  text: "Export to editor",
                  iconProps: { iconName: undefined },
                  disabled: selectedEncounters.length === 0
                },
                extraActionsBefore: getExtraButtonsBefore
              };
            }}
            dialogProps={{
              minWidth: 480,
              maxWidth: 640,
              onDismiss: dismissDialog,
              dialogContentProps: {
                title: (
                  <Heading variant="modal-heading">
                    Export condition consultation notes
                  </Heading>
                )
              }
            }}
          >
            {() => (
              <>
                <Heading variant="section-heading-light">
                  {`${diagnosis}, ${claimBadgeText}`}
                </Heading>
                <Heading
                  variant="section-heading-light"
                  styles={{ root: { marginBottom: 20 } }}
                >
                  {`for ${patientName}`}
                </Heading>
                <FieldArray name={nameOf("encounters")}>
                  {({ fields }) => {
                    return (
                      <Fieldset>
                        {fields.map((name, index) => {
                          return (
                            <CheckboxField
                              key={`${fields.value[index].id}`}
                              label={`${fields.value[index].label}`}
                              name={`${name}.selected`}
                            />
                          );
                        })}
                      </Fieldset>
                    );
                  }}
                </FieldArray>
              </>
            )}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
};
