import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { PatientEditFormValues } from "../PatientEditFormValues.tsx";
import { RelationshipFieldValue } from "../relationships/utils.ts";

const nameOf = nameOfFactory<RelationshipFieldValue>();

export class AccountHolderValidator extends Validator<RelationshipFieldValue> {
  constructor() {
    super();
    this.forField(nameOf("relatedContactId"), [required()]);
    const isDuplicateAccountHolder = (
      key: string,
      allAccounts: RelationshipFieldValue[]
    ) => {
      return allAccounts?.filter(x => x.relatedContactId === key).length > 1;
    };
    this.forField("relatedContactId", [
      (value, _, values: PatientEditFormValues) => {
        const { accountHolders } = values;
        return value && isDuplicateAccountHolder(value, accountHolders)
          ? ValidationMessages.moreThanOnce
          : undefined;
      }
    ]);
    this.forField("relatedContactId", [
      (_, __, values: PatientEditFormValues) => {
        const { accountHolders } = values;
        return accountHolders.filter(x => x.metadata?.isPrimary).length > 1
          ? ValidationMessages.onlyOnePrimary
          : undefined;
      }
    ]);
  }
}
