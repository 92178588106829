import { useContext } from "react";

import {
  confirm,
  DefaultButton,
  IContextualMenuProps,
  Stack
} from "@bps/fluent-ui";
import { PatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import {
  ClaimReviewStatus,
  DocumentStatus
} from "@modules/clinical/screens/patient-record/components/claim-review/ClaimReviewEnums.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DocumentViewerDialog,
  DocumentViewerDialogProps
} from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { RenderButtonsOptions } from "@ui-components/document-viewer/PDFViewer.tsx";

interface ClaimReviewDocumentDialogProps extends DocumentViewerDialogProps {
  claim: Claim;
  documentId: string;
}
enum ClaimReviewDocumentStatuses {
  deleted = "DLT"
}

export const ClaimReviewDocumentDialog: React.FC<
  ClaimReviewDocumentDialogProps
> = ({ claim, documentId, ...props }) => {
  const { setClaimReviewTab, clinicalRecord } = useContext(
    PatientRecordScreenContext
  );

  const { correspondence, acc, core } = useStores();

  const onRenderButtons = (options: RenderButtonsOptions) => {
    const { handleDownload, handleDismiss, handlePrint } = options;

    const handleEdit = async () => {
      const result = await confirm({
        cancelButtonProps: {
          text: "Cancel"
        },
        confirmButtonProps: {
          text: "Edit claim review"
        },
        dialogContentProps: {
          subText:
            "Editing this claim review will delete this PDF. Do you wish to continue?"
        }
      });
      if (result) {
        handleDismiss && handleDismiss();

        const getDocumentPromise = correspondence.getCorrespondenceByDocumentId(
          claim.patientId!,
          documentId
        );

        const getUserPromise = core.getUser(
          claim.claimReview!.clinicalDirectorId
        );

        const [document, user] = await Promise.all([
          getDocumentPromise,
          getUserPromise
        ]);

        if (!document) {
          return;
        }

        const deleteDocumentPromise = correspondence.deleteDocument(
          clinicalRecord.openEncounter!.id,
          documentId,
          {
            patientId: documentId,
            eTag: document.eTag
          }
        );

        const eTag = clinicalRecord.clinicalData?.claimReview?.eTag;

        const saveClinicalDataPromise = clinicalRecord.saveClinicalData({
          claimReview: {
            eTag,
            reviewDocumentStatus: ClaimReviewDocumentStatuses.deleted,
            claimNumber: claim.claimNumber,
            provider: user.fullName
          }
        });

        const patchClaimReviewPromise = acc.patchClaimReview({
          id: claim.claimReview!.id,
          eTag: claim.claimReview!.eTag,
          documentStatus: DocumentStatus.deleted,
          clinicalDocumentId: undefined,
          statusCode: ClaimReviewStatus.draft
        });

        await Promise.all([
          deleteDocumentPromise,
          patchClaimReviewPromise,
          saveClinicalDataPromise
        ]);

        setClaimReviewTab(claim);
      } else {
        return;
      }
    };

    const getSubmitButtonMenuProps = (): IContextualMenuProps => ({
      items: [
        {
          key: "print",
          text: "Print",
          onClick: handlePrint
        },
        {
          key: "edit",
          text: "Edit",
          onClick: handleEdit
        }
      ]
    });

    return (
      <Stack
        tokens={{ childrenGap: 8 }}
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        styles={{
          root: {
            padding: 24
          }
        }}
      >
        <DefaultButton
          onClick={handleDownload}
          text="Download"
          iconProps={{ iconName: "Download" }}
          split
          menuProps={getSubmitButtonMenuProps()}
        />
        <DefaultButton onClick={handleDismiss} text="Close" />
      </Stack>
    );
  };
  return <DocumentViewerDialog onRenderButtons={onRenderButtons} {...props} />;
};
