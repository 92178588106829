import { Country } from "@libs/enums/country.enum.ts";

export interface LocationDetailsValues {
  id?: string;
  practiceName: string;
  address1: string | undefined;
  address2: string | undefined;
  suburb: string | undefined;
  city: string | undefined;
  postCode: string | undefined;
  state: string | undefined;
  country: Country | undefined;
  phone: string | undefined;
  email: string | undefined;
  timezone: string | undefined;
  hpiFacilityId: string | undefined;
  locationNickname?: string;
  appointmentBookMarker?: string;
  defaultLocation?: boolean;
  isInactiveLocation?: boolean;
}

export enum LocationDetailsFormLabels {
  setDefaultLocation = "Set as the default location"
}

export enum LocationStatus {
  Active = "Active",
  Inactive = "Inactive"
}
