import {
  CalendarEventAttendeeDto,
  CalendarEventStatus
} from "@libs/gateways/booking/BookingGateway.dtos.ts";

export const getSpacesRemaining = (
  maxParticipants: number,
  groupAttendeesCount: number
) => {
  return maxParticipants - groupAttendeesCount;
};

// This gives the difference of entries e.g [a, b, c, d] - [a, b, c] = [d]
export const getDifference = (biggerList: string[], smallerList: string[]) => {
  const result = biggerList.filter(i => !smallerList.includes(i));
  return result;
};

export const getActiveAttendees = (attendees: CalendarEventAttendeeDto[]) => {
  return attendees
    .filter(attendee => attendee.status !== CalendarEventStatus.Cancelled)
    .map(attendee => attendee);
};

export const removeUnsavedAttendees = (
  attendeeIdsToRemove: string[],
  groupAttendees: CalendarEventAttendeeDto[]
) =>
  groupAttendees
    .filter(
      attendee =>
        attendee.status || !attendeeIdsToRemove.includes(attendee.attendeeId)
    )
    .slice();
