import { action, observable } from "mobx";

export enum PersonaPhotoStatus {
  error = 1,
  uploading = 2,
  stopped = 3
}

export class PatientDemographicHelper {
  @observable
  personaPhotoFileName?: string;

  @observable
  personaPhotoStatus: PersonaPhotoStatus = PersonaPhotoStatus.stopped;

  @observable
  public displayGenderMessageBar: boolean;

  retryUploadPersonaPhoto: () => void;

  profilePictureStagingId: string | undefined;

  @action
  setPersonaPhotoStatus = (status: PersonaPhotoStatus, fileName?: string) => {
    this.personaPhotoStatus = status;
    this.personaPhotoFileName = fileName;
  };

  @action
  setdisplayGenderMessageBar = (value: boolean) => {
    this.displayGenderMessageBar = value;
  };

  setRetryUploadPersonaPhoto = (retryFunc: () => void) => {
    this.retryUploadPersonaPhoto = retryFunc;
  };

  setProfilePictureStagingId = (id: string | undefined) => {
    this.profilePictureStagingId = id;
  };
}
