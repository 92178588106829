import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  AttachCorrespondenceFileValue,
  ClinicalImageFormValues
} from "../clinical-form/DocumentBatchFormValues.ts";

export class ClinicalImagesDialogValidator extends Validator<ClinicalImageFormValues> {
  constructor() {
    super();
    const fileMetadataValidator = new FileMetaDataValidator();
    this.forField("file", fileMetadataValidator.validate);
  }
}

export class FileMetaDataValidator extends Validator<AttachCorrespondenceFileValue> {
  constructor() {
    super();

    this.forField("subject", [required()]);
  }
}
