import { observer } from "mobx-react-lite";

import { ScrollablePane, Stack, Tile } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { EventReminderContextualMenu } from "./components/EventReminderContextualMenu.tsx";
import { EventReminderList } from "./components/EventReminderList.tsx";
import { EventReminderSidePanel } from "./components/EventReminderSidePanel.tsx";
import { EventReminderTreeViewSize } from "./components/utils.tsx";
import {
  EventReminderScreenContext,
  useEventReminderScreenContext
} from "./context/EventReminderScreenContext.tsx";
import { EventReminderScreenModel } from "./context/EventReminderScreenModel.ts";

const PANEL_EXPANDED = 330;
const PANEL_COLLAPSED = 40;

const MAIN_WIDTH = "calc(100% - 346px)";
const MAX_WIDTH = "calc(100% - 56px)";

const EventReminderScreenBase: React.FC = observer(() => {
  const { eventReminderSidePanelSize } = useEventReminderScreenContext();

  const sidePanelSize = eventReminderSidePanelSize;

  return (
    <Stack verticalFill tokens={{ childrenGap: 16 }}>
      <Stack
        grow
        horizontal
        tokens={{ childrenGap: 9 }}
        styles={{ root: { width: "100%", height: "90%" } }}
      >
        <Stack.Item
          grow
          styles={{
            root: {
              position: "relative",
              minWidth:
                sidePanelSize === EventReminderTreeViewSize.Expanded
                  ? MAIN_WIDTH
                  : MAX_WIDTH
            }
          }}
        >
          <Stack
            tokens={{ childrenGap: 16 }}
            styles={{ root: { height: "100%" } }}
          >
            <ScrollablePane styles={{ root: { height: "100%" } }}>
              <EventReminderList />
            </ScrollablePane>
            <EventReminderContextualMenu />
          </Stack>
        </Stack.Item>

        <Stack.Item
          styles={{
            root: {
              height: "100%",
              width:
                sidePanelSize === EventReminderTreeViewSize.Expanded
                  ? PANEL_EXPANDED
                  : PANEL_COLLAPSED,
              minWidth:
                sidePanelSize === EventReminderTreeViewSize.Expanded
                  ? PANEL_EXPANDED
                  : PANEL_COLLAPSED
            }
          }}
        >
          <Tile
            styles={{
              root: {
                padding: 0,
                height: "100%"
              },
              content: {
                flexGrow: 1,
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                overflowY: "hidden",
                overflowX: "auto"
              }
            }}
          >
            <EventReminderSidePanel />
          </Tile>
        </Stack.Item>
      </Stack>
    </Stack>
  );
});

export const EventReminderScreen = observer(() => {
  const { booking } = useStores();
  return (
    <EventReminderScreenContext.Provider
      value={new EventReminderScreenModel(booking)}
    >
      <EventReminderScreenBase />
    </EventReminderScreenContext.Provider>
  );
});
