import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  Heading,
  IconButton,
  PersonaSize,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { addressText } from "@stores/core/models/Address.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { SexText } from "@ui-components/RefText.tsx";

import { SmallText } from "../SmallText.tsx";
import { personaCard, personaCardIconButton } from "./ImagingRequest.styles.ts";

export interface ContactCardProps {
  contact: Contact;
  displayAddress?: boolean;
  displayDob?: boolean;
  displayMobile?: boolean;
  displayEmail?: boolean;
  displaySex?: boolean;
  displayCompany?: boolean;
  contactType: string;
  heading?: string;
}

export const ImagingRequestContactCard: FunctionComponent<ContactCardProps> =
  observer(
    ({
      contact,
      displayAddress,
      displayDob,
      displayMobile,
      displayEmail,
      displaySex,
      contactType,
      heading
    }) => {
      const theme = useTheme();
      const {
        practice: {
          ui: { showEditContact }
        }
      } = useStores();

      const { defaultAddress, birthDate, email, id } = contact;

      const editContact = () => {
        showEditContact(contactType, id);
      };

      const address = defaultAddress ? addressText(defaultAddress) : "";

      return (
        <>
          {heading && <Heading>{heading}</Heading>}
          <Stack
            horizontal
            horizontalAlign="space-between"
            styles={personaCard(theme)}
          >
            <Persona
              id={contact.id}
              imageUrl={contact.profilePictureUrl}
              contactType={contact.type}
              size={PersonaSize.size48}
              imageInitials={contact.initials}
              text={contact.name}
              onRenderSecondaryText={() => (
                <>
                  <Stack horizontal>
                    {displayDob && (
                      <SmallText>
                        DOB: {birthDate?.toDayDefaultFormat()}{" "}
                      </SmallText>
                    )}
                    {displaySex && (
                      <>
                        {" | "}
                        <SmallText>
                          Sex:
                          {contact.sex && <SexText code={contact.sex} />}
                        </SmallText>
                      </>
                    )}
                  </Stack>
                  {displayMobile && (
                    <Stack horizontal>
                      <SmallText>Mobile: {contact.mobilePhone}</SmallText>
                      {displayEmail && (
                        <>
                          {" | "}
                          <SmallText>
                            Email:
                            {email}
                          </SmallText>
                        </>
                      )}
                    </Stack>
                  )}
                </>
              )}
            />
            <IconButton
              iconProps={{ iconName: "Edit" }}
              styles={personaCardIconButton}
              onClick={editContact}
            />
          </Stack>
          {displayAddress && (
            <Stack>
              <Text variant="xSmall">Address</Text>
              {address}
            </Stack>
          )}
        </>
      );
    }
  );
