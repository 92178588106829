import { observer } from "mobx-react-lite";
import { FC } from "react";

import { routes } from "@libs/routing/routes.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { RecentPatientsDetailsProps } from "./RecentPatientsList.types.ts";

export const RecentInvoiceLink: FC<RecentPatientsDetailsProps> = observer(
  ({ recentPatient }) => {
    return (
      <DataFetcher
        fetch={root =>
          root.billing
            .getInvoice(recentPatient.typeId)
            .catch(catchNotFoundError)
        }
      >
        {invoice => {
          if (!invoice) return <i>Unknown invoice</i>;

          return (
            <Navigate
              to={routes.accounts.invoices.invoice.path({
                id: invoice.id
              })}
            >
              Invoice #{invoice.number}
            </Navigate>
          );
        }}
      </DataFetcher>
    );
  }
);
