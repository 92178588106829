import { useFormState } from "react-final-form";

import { CollapsibleCard, Stack } from "@bps/fluent-ui";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { SotapMskId, SotapMskLabel } from "./SOTAP.constants.ts";
import { sotapNameOf } from "./SOTAP.utils.ts";

interface ConsentAndWarningsCardProps {
  linkedCondition?: boolean;
}

export const ConsentAndWarningsCard: React.FC<ConsentAndWarningsCardProps> = ({
  linkedCondition
}) => {
  const { values } = useFormState<SOTAPFormValues>({
    subscription: { values: true }
  });

  return (
    <div id={SotapMskId.ConsentAndWarning}>
      <CollapsibleCard
        heading={SotapMskLabel.ConsentAndWarning}
        headingLevel="section-heading"
        iconName="CannedChat"
        openOnRender={linkedCondition}
        disabled={!linkedCondition}
      >
        <Stack tokens={{ childrenGap: 8 }}>
          <CheckboxField
            name={sotapNameOf("treatmentPlanDiscussed")}
            label={SotapMskLabel.TreatmentPlanDiscussedText}
          />
          <CheckboxField
            name={sotapNameOf("warningExplained")}
            label={SotapMskLabel.WarningExplainedText}
          />
          <CheckboxField
            name={sotapNameOf("consentObtained")}
            label={SotapMskLabel.ConsentObtainedText}
          />
          <CheckboxField
            name={sotapNameOf("culturalNeedsIdentified")}
            label={SotapMskLabel.CulturalNeedsIdentifiedText}
          />
          <CheckboxField
            name={sotapNameOf("hasAdditionalDiscussions")}
            label={SotapMskLabel.AdditionalDiscussions}
          />
          {values.hasAdditionalDiscussions && (
            <TextInputField
              name={sotapNameOf("additionalDiscussions")}
              multiline
              rows={3}
              autoAdjustHeight
              resizable={false}
              styles={{
                fieldGroup: {
                  minHeight: 60
                }
              }}
              validateOnInitialize={true}
            />
          )}
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
