import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { IInvoiceRow } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsList.tsx";

export const AccreditedBillingSentDateCell: FunctionComponent<IInvoiceRow> =
  observer(props => {
    if (!props.isFirstItem) {
      return null;
    }

    const invoiceSentDate = DateTime.fromJSDate(
      props.invoice.sentDateTime
    )?.toDayDefaultFormat();

    return <Stack.Item>{invoiceSentDate || ""}</Stack.Item>;
  });
