import { observer } from "mobx-react-lite";

import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";

import { SideChangeFormValidator } from "../validators/SideChangeFormValidator.tsx";
import { ChangeSideField } from "./ChangeSideField.tsx";
import {
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";

export const ChangeSideFormSection: React.FC = observer(() => {
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const { claimAdjustment } = useClaimAdjustmentContext();

  if (!claimAdjustment?.hasSideChange) {
    return null;
  }
  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.changeSide}
      openOnRender={selectedMenuItemId === ClaimsAdjustmentCardIds.changeSide}
      heading={ClaimAdjustmentSectionHeading.changeSide}
      iconName="Medical"
      statusValidator={new SideChangeFormValidator(claimAdjustment)}
      visible={!!claimAdjustment?.hasSideChange}
    >
      <ChangeSideField />
    </ClaimCard>
  );
});
