import { IStackProps, ITheme } from "@bps/fluent-ui";

export const headerStyles = {
  root: {
    lineHeight: "22px",
    marginBottom: 11
  }
};

export const wrapperStyle = {
  paddingRight: 16
};

export const borderStyle = (prop: IStackProps, theme: ITheme) => ({
  root: {
    borderWidth: 1,
    borderColor: theme.palette.neutralLighter,
    borderStyle: "solid",
    borderRadius: 2,
    padding: 8
  }
});

export const dropdownStyle = {
  dropdown: {
    width: 228,
    marginTop: 0
  }
};

export const contactPickerWrapperStyle = {
  root: {
    flexGrow: 1
  }
};

export const toggleFieldStyle = {
  root: {
    marginBottom: 0,
    paddingTop: 8
  }
};
