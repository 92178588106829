import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  CheckboxGroup,
  CheckboxGroupOption,
  CollapsibleCard,
  Spinner,
  Stack,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";

export const BookingResourceFilter: FunctionComponent = observer(() => {
  const theme = useTheme();

  const calendarScreenHelper = useBookingCalendarScreenContext();
  const {
    onChangeResourceIds,
    pickableUsers,
    resourceIds,
    isInitialLoadingProviders
  } = calendarScreenHelper;

  const { height } = useScreenSize();

  if (isInitialLoadingProviders) {
    return <Spinner />;
  }

  const onResourceFilterChange = async (ids: string[]) => {
    onChangeResourceIds(ids);
  };

  const options: CheckboxGroupOption<string>[] = pickableUsers.map(x => ({
    label: x.name,
    value: x.id,
    checkboxProps: { id: x.id }
  }));

  return (
    <CollapsibleCard
      heading={`Schedule for (${resourceIds.length}/${options.length})`}
      headingLevel="section-sub-heading"
      iconName="Filter"
      openOnRender
      styles={{
        icon: { fontSize: 14 },
        header: { padding: 8 },
        collapseContent: {
          padding: 8,
          overflowY: "auto",
          maxHeight: Math.max(250, height - 850)
        }
      }}
    >
      {options.length > 0 ? (
        <CheckboxGroup
          styles={{
            root: {
              maxWidth: 200
            },
            legend: { marginBottom: 8 }
          }}
          options={options}
          value={resourceIds.slice()}
          onChange={onResourceFilterChange}
          checkAllOption={{
            label: "All",
            styles: { text: { wordBreak: "break-word" } }
          }}
        />
      ) : (
        <Stack
          styles={{
            root: {
              color: theme.palette.neutralTertiary,
              margin: "20px 0",
              textAlign: "center"
            }
          }}
        >
          No providers available
        </Stack>
      )}
    </CollapsibleCard>
  );
});
