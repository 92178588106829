import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  DataFetcherProps
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

export const BookingEventCalloutDataFetcher: React.FC<
  Pick<DataFetcherProps<void>, "children">
> = observer(({ children }) => {
  const root = useStores();
  const { core, billing } = root;

  const { setAttendeeInvoices } = useBookingCalendarScreenContext();

  const {
    calendarEvent,
    getClaim,
    getStates,
    loadCanStatusChange,
    loadPatientNotices,
    loadInvoiceItems
  } = useContext(BookingCalendarEventContext);

  const loadInvoices = async () => {
    const attendeeIds = calendarEvent.activeAttendees.map(x => x.attendeeId);

    const invoiceIds = attendeeIds.reduce((arr: string[], x) => {
      const id = calendarEvent.getInvoiceIdByAttendee(x);
      if (id) arr.push(id);
      return arr;
    }, []);

    const invoices = await Promise.all(
      invoiceIds.map(x => billing.getInvoice(x))
    );

    const attendeeInvoices = invoices.map(x => ({
      attendeeId: x?.patientId,
      invoiceId: x?.id,
      billingStatus: x?.status
    }));

    setAttendeeInvoices(attendeeInvoices);
  };

  const fetch = async () => {
    await Promise.all([
      loadInvoices(),
      getStates(),
      loadCanStatusChange(),
      loadPatientNotices(),
      core.hasPermissions(Permission.ClaimRead) ? getClaim() : undefined,
      loadInvoiceItems()
    ]);
  };

  return (
    <DataFetcher
      noExceptionsHandlers
      refetchId={calendarEvent.id}
      fetch={fetch}
    >
      {children}
    </DataFetcher>
  );
});
