import { Validator } from "@libs/validation/Validator.ts";
import { ChangeSideValuesType } from "@shared-types/acc/change-side-values.type.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";

import { SideChangeValidator } from "./SideChangeValidator.tsx";

export class SideChangeFormValidator extends Validator<ChangeSideValuesType> {
  constructor(claimAdjustment: ClaimAdjustment) {
    super();
    if (claimAdjustment.hasSideChange) {
      const sideChangeValidator = new SideChangeValidator();

      this.forArrayField("sideChanges", sideChangeValidator.validate);
    }
  }
}
