import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { ClinicalActivityUnlockArgs } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalTaskButtonLabels } from "@modules/clinical/screens/patient-record/components/clinical-task/types/clinical-task-button-labels.enum.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";

export const ActivityClinicalButtons = observer(() => {
  const {
    setSelectedClinicalActivities,
    selectedClinicalActivities,
    setActivityDialogVisible,
    activityDialogVisible
  } = useContext(InboxScreenContext);

  const { clinical } = useStores();

  const isEveryActivityCompleted = selectedClinicalActivities.every(
    x => x.activityStatus === ClinicalActivityStatusText.Completed
  );

  const isEveryActivityUnlocked = selectedClinicalActivities.every(
    x => x && !x.isLocked
  );

  const isMoreThanOneActivitySelected = selectedClinicalActivities.length !== 1;

  return (
    <>
      <When
        permission={[Permission.ClinTaskUnlock, Permission.ClinActivityUnlock]}
      >
        <Stack.Item>
          <DefaultButton
            disabled={isEveryActivityUnlocked}
            onClick={async () => {
              const args: ClinicalActivityUnlockArgs = {
                activityIds: selectedClinicalActivities.map(
                  activity => activity.id
                )
              };
              await clinical.unlockClinicalActivities(args);
              setSelectedClinicalActivities([]);
            }}
            text={ClinicalTaskButtonLabels.Unlock}
          />
        </Stack.Item>
      </When>
      <When
        permission={[Permission.ClinTaskWrite, Permission.ClinActivityWrite]}
      >
        <Stack.Item>
          <DefaultButton
            disabled={
              isEveryActivityCompleted ||
              clinical.ui.clinicalActivityActionDisabled
            }
            onClick={() =>
              setActivityDialogVisible({
                ...activityDialogVisible,
                complete: true
              })
            }
            text={ClinicalTaskButtonLabels.Completed}
          />
        </Stack.Item>
      </When>
      <When
        permission={[Permission.ClinTaskWrite, Permission.ClinActivityWrite]}
      >
        <Stack.Item>
          <DefaultButton
            disabled={
              isMoreThanOneActivitySelected ||
              selectedClinicalActivities[0].isCompleted ||
              clinical.ui.clinicalActivityActionDisabled
            }
            onClick={() =>
              setActivityDialogVisible({
                ...activityDialogVisible,
                edit: true
              })
            }
            text={ClinicalTaskButtonLabels.Edit}
          />
        </Stack.Item>
      </When>
      <When
        permission={[Permission.ClinTaskDelete, Permission.ClinActivityDelete]}
      >
        <Stack.Item>
          <DefaultButton
            disabled={
              isEveryActivityCompleted ||
              clinical.ui.clinicalActivityActionDisabled
            }
            onClick={() =>
              setActivityDialogVisible({
                ...activityDialogVisible,
                delete: true
              })
            }
            text={ClinicalTaskButtonLabels.Delete}
          />
        </Stack.Item>
      </When>
    </>
  );
});
