import { IGroup } from "@bps/fluent-ui";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export type MedicationGroups = {
  groups: IGroup[];
  sortedMedications: MedicationClinicalDataItemDto[];
};

export enum PrescriptionType {
  GeneralPBS = "PBS",
  GeneralRPBS = "RPBS"
}
