import { observer } from "mobx-react-lite";

import {
  MedicalCertainty,
  MedicalHistoryClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { MedicalHistoryTable } from "../medical-history/MedicalHistoryTable.tsx";
import { sortMedicalHistoryClinicalDates } from "../medical-history/utils.ts";
import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";

export interface MedicalHistoryCardComponentProps {
  clinicalRecord: ClinicalRecord;
}

export const MedicalHistoryCardComponent: React.FunctionComponent<MedicalHistoryCardComponentProps> =
  observer(({ clinicalRecord }) => {
    const medicalHistories = clinicalRecord.medicalHistories;
    let sortedContent: MedicalHistoryClinicalDataItemDto[] = [];
    if (medicalHistories) {
      const filteredContent = medicalHistories.filter(
        x =>
          x.active &&
          x.certainty === MedicalCertainty.Confirmed &&
          x.clinicallySignificant === true
      );
      sortedContent = sortMedicalHistoryClinicalDates(filteredContent);
    }

    return sortedContent.length > 0 ? (
      <MedicalHistoryTable medicalHistories={sortedContent} />
    ) : (
      <PatientSummaryEmptyState />
    );
  });
