import { stringify } from "query-string";

import {
  FontSizes,
  IColumn,
  IStyle,
  mergeStyleSets,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PaymentStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { IdParam, RouteInfo, routes } from "@libs/routing/routes.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { AllocationItemsColumns } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { CommentCell } from "@modules/billing/screens/allocation/components/CommentCell.tsx";
import { ChangeTypes } from "@modules/billing/screens/shared-components/change-type/change-types.enum.tsx";
import { ChangeType } from "@modules/billing/screens/shared-components/change-type/ChangeType.tsx";
import { getCreditPath } from "@stores/billing/utils/billing.utils.ts";
import { RouterStore } from "@stores/router/RouterStore.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { AllocationDataAttributes } from "../allocation-field/types.ts";
import { getFirstItemIndex } from "../allocation-field/utils.tsx";
import { RenderItemProps } from "./AllocationListBase.types.tsx";
import { AllocationsListItem } from "./types.ts";

const navLinkCellProps = {
  minWidth: 60,
  maxWidth: 60,
  styles: mergeStyleSets({
    cellTitle: {
      justifyContent: "flex-end"
    }
  })
};

const onRenderNavLinkCell = (options: {
  id?: string;
  number?: string;
  path?: RouteInfo<IdParam>;
  key: keyof AllocationsListItem;
  allocations: AllocationsListItem[];
  item: AllocationsListItem;
  index: number;
  accountId?: string;
  routing: RouterStore;
}) => {
  const {
    id,
    number,
    path,
    key,
    allocations,
    item,
    index,
    accountId,
    routing
  } = options;

  if (
    id &&
    number &&
    path &&
    index === getFirstItemIndex(key, item, allocations)
  ) {
    return (
      <Navigate
        replace
        to={{
          pathname: path.path({
            id
          }),
          search: accountId ? stringify({ accountId }) : undefined
        }}
        state={routing.getRouteState()}
        styles={{ root: { textAlign: "right", display: "block" } }}
      >
        {number}
      </Navigate>
    );
  } else {
    return null;
  }
};

export const invoiceNumberColumn = (
  allocations: AllocationsListItem[],
  routing: RouterStore,
  accountId?: string
): IColumn => ({
  name: AllocationItemsColumns.invoiceNumber,
  key: "invoiceNumber",
  ...navLinkCellProps,
  onRender: (item: AllocationsListItem, index: number) =>
    onRenderNavLinkCell({
      accountId,
      routing,
      allocations,
      item,
      index,
      key: "invoiceId",
      id: item.invoiceId,
      number: item.invoiceNumber,
      path: routes.accounts.invoices.invoice
    })
});

export const creditNumberColumn = (
  allocations: AllocationsListItem[],
  routing: RouterStore,
  accountId?: string
): IColumn => ({
  name: AllocationItemsColumns.creditNumber,
  key: "creditNumber",
  ...navLinkCellProps,
  onRender: (item: AllocationsListItem, index: number) =>
    onRenderNavLinkCell({
      accountId,
      routing,
      allocations,
      item,
      index,
      key: "creditId",
      id: item.creditId,
      number: item.creditNumber,
      path: item.creditType ? getCreditPath(item.creditType) : undefined
    })
});

export const allocationDateColumn = (): IColumn => ({
  name: AllocationItemsColumns.allocationDate,
  key: "createdDate",
  minWidth: 100,
  maxWidth: 120,
  onRender: (item: AllocationsListItem) => {
    if (item.createdDate) {
      return DateTime.fromISO(item.createdDate).toDayDefaultFormat();
    }
    return "-";
  }
});

export const patientNameColumn = (): IColumn => ({
  name: AllocationItemsColumns.patient,
  key: "patientName",
  minWidth: 100,
  maxWidth: 140,
  onRender: (item: AllocationsListItem) => <Text>{item.patientName}</Text>
});

export const providerNameColumn = (): IColumn => ({
  name: "Provider",
  key: "providerName",
  minWidth: 100,
  maxWidth: 140,
  onRender: (item: AllocationsListItem) => <Text>{item.providerName}</Text>
});

export const serviceDateColumn = (): IColumn => ({
  name: AllocationItemsColumns.serviceDate,
  key: "invoiceDate",
  minWidth: 50,
  maxWidth: 100,
  onRender: (item: AllocationsListItem) => (
    <Text>
      {item.serviceDate
        ? DateTime.fromISO(item.serviceDate).toDayDefaultFormat()
        : ""}
    </Text>
  )
});

export const commentColumn = (): IColumn => ({
  iconName: "comment",
  name: "",
  key: "comment",
  minWidth: 17,
  maxWidth: 17,
  styles: {
    iconClassName: { fontSize: FontSizes.size16 }
  },
  onRender: (item: AllocationsListItem) => (
    <CommentCell comment={item.comment} />
  )
});

export const owingColumn = (options: {
  renderItem: RenderItemProps;
  hide?: boolean;
}): IColumn => {
  const { renderItem, hide } = options;

  return {
    name: hide ? "" : AllocationItemsColumns.owingPrefixed,
    key: hide ? "" : "owing",
    minWidth: 80,
    maxWidth: 80,
    onRender: (item: AllocationsListItem) =>
      hide
        ? null
        : renderItem.text({
            value: currencyFormat(item.owing || 0, { currency: "" }),
            styles: () => ({ textAlign: "right" }) as IStyle
          }),
    styles: mergeStyleSets({
      cellTitle: {
        justifyContent: "flex-end"
      }
    })
  };
};

const getChangeLabel = (type: ChangeTypes | undefined): string => {
  switch (type) {
    case ChangeTypes.added:
      return "Added payment";
    case ChangeTypes.removed:
      return "Removed payment";
    case ChangeTypes.edited:
      return "Edited";
    default:
      return "";
  }
};

export const changeTypeColumn = (renderItem: RenderItemProps): IColumn => ({
  name: AllocationItemsColumns.changeType,
  key: "changeType",
  minWidth: 135,
  maxWidth: 150,
  onRender: (item: AllocationsListItem) =>
    renderItem.text({
      value: (
        <ChangeType
          type={item.changeType!}
          label={getChangeLabel(item.changeType)}
        />
      )
    })
});

export const getCreditedColumnContent = (item: {
  total: number;
  paymentStatus: PaymentStatuses;
  owing: number;
  checked: boolean;
}) => {
  const { checked, paymentStatus, owing, total } = item;
  switch (paymentStatus) {
    case PaymentStatuses.paid:
      return total;
    case PaymentStatuses.unpaid:
      return 0;
    case PaymentStatuses.part:
      return owing && checked ? owing : total;
    default:
      return total;
  }
};

export const allocatedColumn = (
  renderItem: RenderItemProps,
  nameOverride?: string
): IColumn => ({
  name: nameOverride || AllocationItemsColumns.allocatedPrefixed,
  key: "total",
  minWidth: 100,
  maxWidth: 100,
  onRender: (item: { total: number }, index: number) => {
    return renderItem.text({
      value: currencyFormat(Number(item.total || 0), { currency: "" }),
      styles: () => ({ textAlign: "right" }) as IStyle,
      dataAttributeElement: `${AllocationDataAttributes.totalCell}-${index}`
    });
  },
  styles: mergeStyleSets({
    cellTitle: {
      justifyContent: "flex-end"
    }
  })
});

export const creditedColumn = (renderItem: RenderItemProps): IColumn => ({
  name: AllocationItemsColumns.creditedPrefixed,
  key: "credit",
  minWidth: 100,
  maxWidth: 100,
  onRender: (
    item: {
      total: number;
      paymentStatus: PaymentStatuses;
      owing: number;
      checked: boolean;
    },
    index: number
  ) => {
    return renderItem.text({
      value: currencyFormat(getCreditedColumnContent(item), { currency: "" }),
      styles: () => ({ textAlign: "right" }) as IStyle,
      dataAttributeElement: `${AllocationDataAttributes.totalCell}-${index}`
    });
  },
  styles: mergeStyleSets({
    cellTitle: {
      justifyContent: "flex-end"
    }
  })
});

export const codeColumn = (renderItem: RenderItemProps): IColumn => ({
  name: AllocationItemsColumns.itemNumberPrefixed,
  key: "code",
  minWidth: 70,
  maxWidth: 70,
  onRender: (item: { code: string }) =>
    renderItem.text({
      value: item.code,
      styles: () => ({ textAlign: "right" }) as IStyle
    }),
  styles: mergeStyleSets({
    cellTitle: {
      justifyContent: "flex-end"
    }
  })
});

export const descriptionColumn = (renderItem: RenderItemProps): IColumn => ({
  name: AllocationItemsColumns.description,
  key: "description",
  minWidth: 200,
  maxWidth: 1000,
  className: "clampThreeLines",
  onRender: (item: { name?: string; description: string }) =>
    renderItem.text({
      value: item.name || item.description,
      styles: () => ({ overflow: "hidden" }) as IStyle
    })
});

export const feeColumn = (renderItem: RenderItemProps): IColumn => ({
  name: AllocationItemsColumns.feeIncGstPrefixed,
  key: "itemTotal",
  minWidth: 100,
  maxWidth: 100,
  onRender: (item: { itemTotal: number }) =>
    renderItem.text({
      value: currencyFormat(item.itemTotal, { currency: "" }),
      styles: () => ({ textAlign: "right" }) as IStyle
    }),
  styles: mergeStyleSets({
    cellTitle: {
      justifyContent: "flex-end"
    }
  })
});
