import { to2dp } from "@bps/utils";
import { GstMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";

export const getFeeIncludingGst = (
  fee: number,
  gstMethod: GstMethod,
  gstPercent: number
): number =>
  gstMethod === GstMethod.calculateRounded
    ? to2dp((1 + gstPercent) * fee)
    : fee;

export const sortSchedules = (scheduleA: Schedule, scheduleB: Schedule) => {
  // sort schedules first by active status, then by name
  if (scheduleA.isInactive !== scheduleB.isInactive) {
    return scheduleA.isInactive ? 1 : -1;
  }

  const aName = (scheduleA.name || "").toLowerCase();
  const bName = (scheduleB.name || "").toLowerCase();

  return aName > bName ? 1 : -1;
};
