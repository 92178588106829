import { ActivityDescriptionDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalActivityType } from "@modules/clinical/screens/patient-record/components/clinical-activity/types/clinical-activity.type.ts";

import { deepFreeze } from "./utils/test-data.utils.ts";

export const activityDescriptions: Array<ActivityDescriptionDto> = deepFreeze([
  {
    id: "1",
    activityType: ClinicalActivityType.Task,
    reasonText: "12 month Immunisation",
    isSupplied: true,
    interval: 6,
    frequency: "Months",
    isActive: true,

    eTag: ""
  }
]);
