import React from "react";
import { useField } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ObservationsLabels } from "../../observations/utils.ts";
import { nameOfGeneralExaminationForm } from "./GeneralExaminationFields.tsx";
import { GeneralHighLowSevereWarningMessageBar } from "./GeneralHighLowSevereWarningMessageBar.tsx";

export const GeneralExamRespiratoryField: React.FC = () => {
  const { core } = useStores();
  const isGpform = core.hasPermissions(Permission.GpExamCompleteAllowed);
  const {
    input: { value: o2SaturationRate }
  } = useField<number>(nameOfGeneralExaminationForm("o2SaturationRate"), {
    subscription: { value: true }
  });

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Fieldset
        horizontal
        tokens={{ childrenGap: 8 }}
        legend={isGpform ? "Respiratory" : "Respiratory rate"}
        legendLevel="section-heading-light"
      >
        <SpinNumberInputField
          name={nameOfGeneralExaminationForm("respiratoryRate")}
          min={1}
          max={999}
          maxDigitsNumber={3}
          label={isGpform ? "Rate" : undefined}
          styles={{ root: { width: 100 } }}
        />
        <When permission={Permission.GpExamCompleteAllowed}>
          <SpinNumberInputField
            name={nameOfGeneralExaminationForm("o2SaturationRate")}
            min={1}
            max={999}
            maxDigitsNumber={3}
            styles={{ root: { width: 100 } }}
            label={ObservationsLabels.O2SaturationRate}
          />
        </When>
      </Fieldset>
      {o2SaturationRate && (
        <GeneralHighLowSevereWarningMessageBar
          min={93}
          minMessage="Low O2 saturation"
          value={o2SaturationRate}
        />
      )}
    </Stack>
  );
};
