import { clone, DateTime, isDefined } from "@bps/utils";
import {
  ClinicalDataType,
  IntakeFlagRefDataDto,
  IntakeFrequency,
  IntakeItemDto,
  IntakeModelDto,
  IntakeStatus,
  IntakeStatuses,
  IntakeUnits,
  TobaccoClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { AllMutators } from "@ui-components/form/customMutators.ts";

import {
  IntakeDomain,
  IntakeFormItem,
  IntakeFormValues
} from "./IntakeForm.types.ts";

export const getFilteredIntakes = (params: {
  intakes: IntakeModelDto["intakes"];
  predicate: (item: Partial<IntakeItemDto>) => boolean;
  domain?: IntakeDomain;
  dateOfBirth?: DateTime;
}): IntakeFormItem[] => {
  const { intakes, predicate, domain, dateOfBirth } = params;
  return intakes.filter(predicate).map(item => ({
    id: item.id,
    type: item.type,
    yearStarted: item.yearStarted,
    yearStopped: item.yearStopped,
    frequency: item.frequency,
    cessation: {
      isAdviceGiven: item.cessation?.isAdviceGiven ?? false,
      advices: item.cessation?.advices,
      types: item.cessation?.advices?.map(x => x.type)
    },
    amount: item.amount,
    isEdit: !!(
      !!dateOfBirth &&
      item.yearStarted &&
      item.yearStarted < dateOfBirth.year
    ),
    isCurrent: item.isCurrent,
    unit:
      domain === ClinicalDataType.Alcohol
        ? IntakeUnits.StandardDrink
        : item.unit,
    occurrence: item.occurrence,
    isLocked: item.isLocked
  }));
};

const nameOf = nameOfFactory<IntakeFormValues>();

export const getInitialValues = (params: {
  intakeConfirmed: boolean | undefined;
  intake?: IntakeModelDto &
    Pick<TobaccoClinicalDataDto, "exSmokerLessThan12Months">;
  domain?: IntakeDomain;
  dateOfBirth?: DateTime;
}): IntakeFormValues => {
  const { intake, intakeConfirmed, domain, dateOfBirth } = params;
  return {
    currentIntakes: getFilteredIntakes({
      intakes: intake?.intakes ?? [],
      predicate: x => !!x.isCurrent,
      domain,
      dateOfBirth
    }),
    historicalIntakes: getFilteredIntakes({
      intakes: intake?.intakes ?? [],
      predicate: x => !x.isCurrent,
      domain,
      dateOfBirth
    }),
    comment: intake?.comment,
    status: intake?.status,
    deletedIntakes: [],
    deleteIntake: null,
    exSmokerLessThan12Months: intake?.exSmokerLessThan12Months,
    intakeConfirmed
  };
};

export const getDefaultIntake = (
  domain: IntakeDomain,
  name: string
): IntakeFormItem => ({
  type: "",
  frequency: IntakeFrequency.Daily,
  isEdit: true,
  isCurrent: name === nameOf("currentIntakes"),
  unit:
    domain === ClinicalDataType.Alcohol ? IntakeUnits.StandardDrink : undefined
});

/* Validator */
export const isBeforeOrSameAsCurrentYear =
  (messageFieldName: string): FieldValidator =>
  value => {
    return value > DateTime.now().year
      ? `${messageFieldName} must be before or equal to the current year.`
      : undefined;
  };

export const isAfterDob =
  (dob: Date | undefined, messageFieldName: string): FieldValidator =>
  value =>
    dob && value < DateTime.fromJSDate(dob).year
      ? `${messageFieldName} must be after date of birth.`
      : undefined;

export const getFilteredOptions = (
  arr: IntakeFlagRefDataDto<IntakeStatus>[],
  domain: IntakeDomain
) => {
  let key: string;
  if (domain === ClinicalDataType.Alcohol) {
    key = "isAlcohol";
  } else if (domain === ClinicalDataType.Tobacco) {
    key = "isTobacco";
  } else {
    key = "isSubstanceUse";
  }

  return arr.filter(i => i[key]).map(({ code, text }) => ({ key: code, text }));
};

export const isIntakeStatusCurrent = (status: string | undefined) => {
  return (
    status === IntakeStatuses.DrinksAlcohol ||
    status === IntakeStatuses.ActiveSmoker ||
    status === IntakeStatuses.ActiveParticipant
  );
};

export const isIntakeStatusEx = (status: string | undefined) => {
  return (
    status === IntakeStatuses.ExDrinker ||
    status === IntakeStatuses.ExSmoker ||
    status === IntakeStatuses.ExParticipant
  );
};

export const isIntakeStatusNever = (status: string | undefined) => {
  return (
    status === IntakeStatuses.NeverDrinker ||
    status === IntakeStatuses.NeverSmoker ||
    status === IntakeStatuses.NeverParticipant
  );
};

export const ceaseAllIntakes = (
  currentIntakes: IntakeFormItem[],
  mutators: AllMutators
) => {
  const nameOf = nameOfFactory<IntakeFormValues>();

  const ceaseIndexes = currentIntakes.map((intake, index) => {
    if (intake.id) {
      const item = currentIntakes[index];
      mutators.push(nameOf("historicalIntakes"), {
        ...item,
        isCurrent: false,
        isEdit: false,
        isLocked: true,
        yearStopped: DateTime.now().year
      });
    }
    return index;
  });

  return mutators.removeBatch(nameOf("currentIntakes"), ceaseIndexes);
};

export const removeNewIntakes = (
  currentIntakes: IntakeFormItem[],
  mutators: AllMutators
): void => {
  const nameOf = nameOfFactory<IntakeFormValues>();

  const ceaseIndexes: number[] = currentIntakes
    .map((intake: IntakeFormItem, index: number) => {
      if (!intake.id) return index;
      return undefined;
    })
    .filter(isDefined);

  return mutators.removeBatch(nameOf("currentIntakes"), ceaseIndexes);
};

export const ceaseCurrentIntake = (
  intake: IntakeFormItem,
  ceaseIndex: number,
  mutators: AllMutators
) => {
  const nameOf = nameOfFactory<IntakeFormValues>();

  if (intake.id) {
    mutators.push(nameOf("historicalIntakes"), {
      ...intake,
      isCurrent: false,
      isEdit: false,
      isLocked: true,
      yearStopped: DateTime.now().year
    });

    if (ceaseIndex !== -1) {
      mutators.remove(nameOf("currentIntakes"), ceaseIndex);
    }
  }
};

export const getIntakeStatusText = (
  status: string | undefined,
  statusOptions: { key: string; text: string }[] | undefined
) => {
  switch (status) {
    case IntakeStatuses.DrinksAlcohol:
      return statusOptions?.find(s => s.key === IntakeStatuses.DrinksAlcohol)
        ?.text;
    case IntakeStatuses.ExDrinker:
      return statusOptions?.find(s => s.key === IntakeStatuses.ExDrinker)?.text;
    case IntakeStatuses.NeverDrinker:
      return statusOptions?.find(s => s.key === IntakeStatuses.NeverDrinker)
        ?.text;
    case IntakeStatuses.ActiveParticipant:
      return statusOptions?.find(
        s => s.key === IntakeStatuses.ActiveParticipant
      )?.text;
    case IntakeStatuses.ExParticipant:
      return statusOptions?.find(s => s.key === IntakeStatuses.ExParticipant)
        ?.text;
    case IntakeStatuses.NeverParticipant:
      return statusOptions?.find(s => s.key === IntakeStatuses.NeverParticipant)
        ?.text;
    case IntakeStatuses.ActiveSmoker:
      return statusOptions?.find(s => s.key === IntakeStatuses.ActiveSmoker)
        ?.text;
    case IntakeStatuses.ExSmoker:
      return statusOptions?.find(s => s.key === IntakeStatuses.ExSmoker)?.text;
    case IntakeStatuses.NeverSmoker:
      return statusOptions?.find(s => s.key === IntakeStatuses.NeverSmoker)
        ?.text;
    default:
      return "Not recorded";
  }
};

export const getCombinedIntakes = (params: {
  deletedIntakes: IntakeFormItem[];
  currentIntakes: IntakeFormItem[];
  historicalIntakes: IntakeFormItem[];
  initialCurrentIntakes: IntakeFormItem[];
}) => {
  const {
    deletedIntakes,
    currentIntakes,
    historicalIntakes,
    initialCurrentIntakes
  } = params;
  if (initialCurrentIntakes.length > 0) {
    currentIntakes.forEach(currentIntake => {
      const originalIntake = initialCurrentIntakes.find(
        i => i.id && i.id === currentIntake.id
      );

      if (originalIntake) {
        if (
          originalIntake.amount !== currentIntake.amount ||
          originalIntake.unit !== currentIntake.unit ||
          originalIntake.frequency !== currentIntake.frequency ||
          originalIntake.occurrence !== currentIntake.occurrence
        ) {
          const newHistoricalIntake = clone(originalIntake);
          currentIntake.yearStarted = DateTime.now().year;

          newHistoricalIntake.id = undefined;
          newHistoricalIntake.isCurrent = false;
          newHistoricalIntake.yearStopped = DateTime.now().year;
          newHistoricalIntake.isLocked = true;
          historicalIntakes.push(newHistoricalIntake);
        }
      }
    });
  }

  return [...deletedIntakes, ...currentIntakes, ...historicalIntakes]
    .map(({ isEdit, ...rest }) => ({ ...rest }))
    .filter(i => i.type)
    .map(({ cessation, ...rest }) => ({
      ...rest,
      cessation: cessation
        ? {
            advices: [...(cessation.types ?? []).map(type => ({ type }))],
            isAdviceGiven: cessation.isAdviceGiven
          }
        : undefined
    }));
};
