import { useContext } from "react";

import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { CommsScheduleContext } from "../context/CommsScheduleContext.ts";
import { CommsCard } from "./CommsCard.tsx";
import { CommsScheduleFormValues } from "./CommsScheduleForm.types.ts";
import { CommsScheduleCardIds } from "./CommsScheduleFormEnums.ts";

const nameOf = nameOfFactory<CommsScheduleFormValues>();

export const AppointmentReminderFormSection = () => {
  const { selectedSectionId } = useContext(CommsScheduleContext);

  return (
    <CommsCard
      id={CommsScheduleCardIds.appointmentReminder}
      heading="Appointment reminder"
      openOnRender={
        !selectedSectionId ||
        selectedSectionId === CommsScheduleCardIds.appointmentReminder
      }
    >
      <TextInputField
        placeholder="Name your schedule"
        name={nameOf("jobName")}
        label="Name"
        required
      />
    </CommsCard>
  );
};
