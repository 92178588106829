import { observer } from "mobx-react-lite";
import { Field, useField } from "react-final-form";

import { Spinner, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Contact,
  contactFirstNameCompareFn
} from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { SecondColumnWrapper } from "../../SecondColumnWrapper.tsx";
import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { GroupAttendeesAdd } from "./GroupAttendeesAdd.tsx";
import { getActiveAttendees } from "./GroupAttendeesHelper.ts";
import { GroupAttendeesList } from "./GroupAttendeesList.tsx";

export const GroupAttendeesDetails: React.FunctionComponent = observer(() => {
  const {
    input: { value: groupAttendees }
  } = useField(appointmentFormNameOf("groupAttendees"), {
    subscription: { value: true }
  });

  const activeAttendees = getActiveAttendees(groupAttendees || []);

  const { userExperience } = useStores();
  const patientLabel = userExperience.localisedConfig("patientDisplay", {
    capitalizeFirst: true
  });

  return (
    <SecondColumnWrapper heading={`${patientLabel} details`} childrenGap={0}>
      <Stack
        styles={{
          root: { paddingTop: "24px", width: "100%" }
        }}
      >
        <Field
          name={appointmentFormNameOf("groupAttendees")}
          render={() => null}
        />
        <GroupAttendeesAdd />

        <DataFetcher<Contact[]>
          fetch={async root => {
            const contactIds = activeAttendees.map(
              attendee => attendee.attendeeId
            );

            const contacts = await root.practice.getContactsById(contactIds);
            return contacts.sort(contactFirstNameCompareFn);
          }}
          fallback={<Spinner />}
          refetchId={activeAttendees.length}
        >
          {contacts => <GroupAttendeesList contacts={contacts} />}
        </DataFetcher>
      </Stack>
    </SecondColumnWrapper>
  );
});
