import { observer } from "mobx-react-lite";
import { TimeGridProps } from "react-big-calendar";
// eslint-disable-next-line import/extensions
import TimeGrid from "react-big-calendar/lib/TimeGrid";

import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  calculateWorkWeekRange,
  getClosedExceptionDates,
  getWorkWeekViewMax,
  getWorkWeekViewMin
} from "./bookingCalendarWorkWeekUtils.ts";

function BookingCalendarWorkWeekViewCore(
  props: TimeGridProps & { date: Date }
) {
  const { booking } = useStores();
  const { resourceIds, orgUnitAvailability, providerAndLocationHours } =
    useBookingCalendarScreenContext();

  const overrideDates = getClosedExceptionDates(
    resourceIds,
    booking.userAvailabilityMap,
    orgUnitAvailability
  );

  const max = getWorkWeekViewMax(providerAndLocationHours);
  const min = getWorkWeekViewMin(providerAndLocationHours);
  const range = calculateWorkWeekRange(
    props.date,
    providerAndLocationHours,
    overrideDates
  );

  return <TimeGrid {...props} max={max} min={min} range={range} />;
}

// We're not using this function but it still has to be included
BookingCalendarWorkWeekViewCore.title = () => "";

// We're not using this function but it still has to be included
BookingCalendarWorkWeekViewCore.navigate = (date: Date) => date;

export const BookingCalendarWorkWeekView = observer(
  BookingCalendarWorkWeekViewCore
);
