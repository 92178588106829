import { nameof } from "@libs/utils/name-of.utils.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimAdjustmentDocumentFormValues } from "@shared-types/acc/claim-adjustment-document.value.type.ts";

import { DocumentFormValidator } from "./DocumentFormValidator.tsx";

export class DocumentSectionFormValidator extends Validator<ClaimAdjustmentDocumentFormValues> {
  constructor() {
    const documentFormValidator = new DocumentFormValidator();

    super();
    this.forArrayField(nameof("documents"), documentFormValidator.validate);
    this.forArrayField(
      nameof("uploadDocuments"),
      documentFormValidator.validate
    );
  }
}
