import { useForm } from "react-final-form";

import {
  AnimatedListWithKeys,
  DefaultButton,
  Heading,
  NoDataTile,
  Stack
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { RelationshipType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { OrganisationLabels } from "../organisation.types.ts";
import {
  EditOrganisationFormValues,
  orgEditFormNameOf,
  OrgRelationshipFieldValue
} from "./EditOrganisationFormValues.tsx";
import { OrgRelationshipFields } from "./OrgRelationshipFields.tsx";

export const EditOrgRelationships: React.FC = () => {
  const { getState, change } = useForm<EditOrganisationFormValues>();
  const getShowToggle = (value: OrgRelationshipFieldValue) => {
    let showtoggle: boolean = false;
    value?.professional.find(x => {
      if (x === RelationshipType.EmployeeOf) {
        showtoggle = true;
      }
      return false;
    });
    return showtoggle;
  };

  const relationships = getState().values.relationships;
  relationships.filter(x => x.professional.length > 0);

  const addRelationship = () => {
    const relationships = getState().values.relationships.map(p => ({
      ...p,
      autoFocus: false
    }));

    const newRelationship: OrgRelationshipFieldValue = {
      professional: [],
      org: [],
      autoFocus: true,
      id: newGuid(),
      currentJob: false
    };
    change(orgEditFormNameOf("relationships"), [
      ...relationships,
      newRelationship
    ]);
  };

  return (
    <div
      id={`${OrganisationCardIds.OrganisationPeople}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { lineHeight: "22px", marginBottom: 8 } }}
        >
          <Heading variant="section-heading-light">{Labels.people}</Heading>
          <DefaultButton
            id="org-relationships-add"
            iconProps={{ iconName: "Add" }}
            text={OrganisationLabels.AddAnother}
            onClick={addRelationship}
          />
        </Stack>

        <Stack tokens={{ childrenGap: 16 }}>
          {relationships.length === 0 && (
            <NoDataTile
              textProps={{
                text: "No relationships"
              }}
              linkProps={{
                text: "Add a relationship for this organisation",
                onClick: addRelationship
              }}
            />
          )}
          <FieldArray<OrgRelationshipFieldValue>
            name={orgEditFormNameOf("relationships")}
          >
            {fieldProps => {
              return (
                <AnimatedListWithKeys
                  items={fieldProps.fields.value}
                  onItemIdRemoved={id => {
                    const index = fieldProps.fields.value.findIndex(
                      v => v.id === id
                    );
                    fieldProps.fields.remove(index);
                  }}
                >
                  {(id, onRemoveItem) => {
                    const index = fieldProps.fields.value.findIndex(
                      v => v.id === id
                    );

                    const value = getState().values.relationships.find(
                      p => p.id === id
                    );
                    if (value && index >= 0) {
                      return (
                        <Fieldset frame>
                          <OrgRelationshipFields
                            onRemove={onRemoveItem}
                            showToggle={getShowToggle(value)}
                            name={`${orgEditFormNameOf(
                              "relationships"
                            )}[${index}]`}
                          />
                        </Fieldset>
                      );
                    }
                    return null;
                  }}
                </AnimatedListWithKeys>
              );
            }}
          </FieldArray>
        </Stack>
      </Stack>
    </div>
  );
};
