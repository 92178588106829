import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  ButtonsGroupSingleChoiceField,
  ButtonsGroupSingleChoiceFieldProps
} from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";

interface DominantHandSingleChoiceProps
  extends Pick<ButtonsGroupSingleChoiceFieldProps, "name" | "styles"> {}

export const DominantHandSingleChoiceBase: React.FunctionComponent<
  DominantHandSingleChoiceProps
> = ({ name, styles }) => {
  const { clinical } = useStores();

  return (
    <ButtonsGroupSingleChoiceField
      styles={styles}
      label="Dominant hand"
      name={name}
      options={clinical.ref.dominantHands.keyTextValues}
    />
  );
};

export const DominantHandSingleChoice = withFetch(
  x => [x.clinical.ref.dominantHands.load()],
  DominantHandSingleChoiceBase
);
