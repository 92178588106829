import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { Dropdown, IDropdownOption, IDropdownProps } from "@bps/fluent-ui";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { BookingCalendarEventContext } from "../../booking-calendar-event/BookingCalendarEventContext.tsx";
import { enableAppointmentStatusItems } from "../../utils.tsx";
import { AppointmentStatus } from "./AppointmentStatus.tsx";

interface AppointmentStatusDropdownProps
  extends Omit<IDropdownProps, "options"> {
  urgent?: boolean;
  currentattendeeAppointmentStatus?: AppointmentStatusCode;
  withNoEmptyOption?: boolean;
}

const AppointmentStatusDropdownComponent: FunctionComponent<AppointmentStatusDropdownProps> =
  observer(
    ({
      urgent,
      onDismiss,
      withNoEmptyOption,
      currentattendeeAppointmentStatus,
      ...rest
    }) => {
      const { booking } = useStores();
      const renderDropdownTitle = (options: IDropdownOption[]) => {
        const option = options[0];
        return renderDropdownOption(option);
      };

      const {
        currentAppointmentStatus,
        changeAppointmentStatus,
        todayAppointment,
        calendarEvent
      } = useContext(BookingCalendarEventContext);

      const renderDropdownOption = (option: IDropdownOption) => {
        const { key: code } = option;
        return (
          <AppointmentStatus
            code={code as AppointmentStatusCode}
            urgent={urgent}
            showIndicator
          />
        );
      };

      const appointmentStatus =
        currentattendeeAppointmentStatus ?? currentAppointmentStatus;

      const options = booking.ref.appointmentStatuses.keyTextValues;

      const statusItems = options.map(item => ({
        key: item.key,
        text: item.text,
        disabled: enableAppointmentStatusItems(
          appointmentStatus,
          item.key as AppointmentStatusCode,
          todayAppointment && !calendarEvent.hasEncounter
        ),
        onClick: () => {
          changeAppointmentStatus(item.key as AppointmentStatusCode).then(
            () => onDismiss && onDismiss()
          );
        }
      }));

      return (
        <Dropdown
          name="appointment-status"
          placeholder="Select a status"
          onRenderTitle={renderDropdownTitle}
          onRenderOption={renderDropdownOption}
          options={statusItems}
          withNoEmptyOption={withNoEmptyOption}
          {...rest}
        />
      );
    }
  );

export const AppointmentStatusDropdown = withFetch(
  x => x.booking.ref.appointmentStatuses.load(),
  AppointmentStatusDropdownComponent
);
