import {
  FontSizes,
  getColumnClampLinesSelector,
  IDetailsListStyles,
  IDetailsRowStyles,
  IDropdownStyles,
  IStackStyles,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
  ITextStyles,
  ITheme,
  mergeStyles,
  mergeStyleSets
} from "@bps/fluent-ui";

export const getFeesStylesSet = (theme: ITheme) => {
  const filterStyles: Partial<IDetailsRowStyles> = {
    root: {
      background: theme.palette.neutralLighterAlt,
      padding: "8px 16px",
      selectors: {
        "&:hover": {
          backgroundColor: theme.palette.neutralLighterAlt
        }
      }
    },
    cell: {
      selectors: {
        "&:last-child": {
          textAlign: "end"
        }
      }
    }
  };

  const detailsListStyles: Partial<IDetailsListStyles> = {
    root: {
      ".ms-List-cell": {
        minHeight: 0
      },
      ".ms-List": {
        paddingBottom: 8,
        paddingTop: 8
      }
    }
  };

  const listRowStyles: Partial<IDetailsRowStyles> = {
    root: [
      {
        minHeight: 0,
        lineHeight: 19
      }
    ],
    cell: {
      minHeight: 0,
      paddingTop: 8,
      paddingBottom: 8
    }
  };

  const listCellRightAlignHeaderClassName = mergeStyles({
    span: { display: "block", textAlign: "right" }
  });

  const listCellRightAlignClassName = mergeStyles({
    textAlign: "right"
  });

  const statusFilterDropdownStyles: Partial<IDropdownStyles> = {
    root: { minWidth: 100 }
  };

  const textFilterStyles: Partial<ITextFieldStyles> = {
    root: {
      width: "100%"
    }
  };

  const dateFilterTextFieldStyles: IStyleFunctionOrObject<
    ITextFieldStyleProps,
    ITextFieldStyles
  > = props => ({
    icon: { color: theme.palette.neutralSecondaryAlt },
    fieldGroup: {
      background: theme.palette.neutralLighterAlt,
      border: props.hasErrorMessage
        ? `1px solid ${theme.semanticColors.errorBackground}`
        : "none"
    }
  });

  const feeTileStyles: Partial<IStackStyles> = {
    root: {
      borderBottom: `1px solid${theme.palette.neutralLight}`,
      paddingBottom: 24,
      margin: "0 24px 24px"
    }
  };

  const feeTileHeadingStyles: Partial<ITextStyles> = {
    root: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      flexGrow: 1
    }
  };

  const feeTileLongDescriptionStyles: Partial<ITextStyles> = mergeStyleSets(
    { root: { color: theme.palette.neutralSecondary } },
    { root: getColumnClampLinesSelector(5) }
  );

  const feeTileSubheadingStyles: Partial<ITextStyles> = {
    root: {
      fontSize: FontSizes.mini,
      color: theme.palette.neutralSecondary
    }
  };

  const feeViewRowStyles: Partial<ITextStyles> = {
    root: {
      marginBottom: 12
    }
  };

  const feeViewSubheadingStyles: Partial<ITextStyles> = {
    root: {
      marginBottom: 4
    }
  };

  const feeViewTextStyles: Partial<ITextStyles> = {
    root: {
      minHeight: 18
    }
  };

  return {
    feeViewTextStyles,
    feeViewSubheadingStyles,
    feeViewRowStyles,
    feeTileSubheadingStyles,
    feeTileLongDescriptionStyles,
    feeTileHeadingStyles,
    feeTileStyles,
    dateFilterTextFieldStyles,
    textFilterStyles,
    statusFilterDropdownStyles,
    listCellRightAlignClassName,
    listCellRightAlignHeaderClassName,
    listRowStyles,
    detailsListStyles,
    filterStyles
  };
};
