import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { DetailsList, DetailsListLayoutMode, Link } from "@bps/fluent-ui";

import { TemplateManagementContext } from "./context/TemplateManagementContext.tsx";
import { TemplateFieldSelection } from "./TemplateManagementTypes.ts";

export interface TemplateFieldsListProps {
  addTemplateField: (templateField: TemplateFieldSelection) => void;
}

export const TemplateFieldsList: FunctionComponent<TemplateFieldsListProps> =
  observer(({ addTemplateField }) => {
    const { fields } = useContext(TemplateManagementContext);

    const columns = [
      {
        key: "text",
        name: "Fields",
        fieldName: "text",
        minWidth: 180,
        onRender: (item: TemplateFieldSelection) => {
          return (
            <Link onClick={() => addTemplateField(item)}>{item.text}</Link>
          );
        }
      }
    ];
    return (
      <DetailsList
        layoutMode={DetailsListLayoutMode.fixedColumns}
        compact={true}
        groups={fields.filteredGroups}
        items={fields.filteredItems}
        columns={columns}
        groupProps={{}}
        styles={{
          root: {
            maxHeight: 550,
            minWidth: 180
          }
        }}
      />
    );
  });
