import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import { InvoiceEmailDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { SelectedInvoicesMessageBar } from "@modules/billing/screens/acc-invoices/components/SelectedInvoicesMessageBar.tsx";
import { AccInvoicesListContext } from "@modules/billing/screens/acc-invoices/context/AccInvoicesListContext.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AccreditedBillingEmailDialog } from "../accredited-billing-email-dialog/AccreditedBillingEmailDialog.tsx";
import { SelectedInvoicesButton } from "./SelectedInvoicesButton.tsx";

export const AccreditedBillingSelectionButtons: FunctionComponent = observer(
  () => {
    const { selectedInvoices, setInvoiceEmailQueue } = useContext(
      AccInvoicesListContext
    );

    const { billing, core, practice } = useStores();

    const markInvoiceSentBulk = async (invoices: Invoice[]) => {
      const invoiceIds = invoices.map(invoice => invoice.id);
      await billing.markInvoiceSent(invoiceIds);
    };

    const sendInvoicePdfBulk = async (invoices: Invoice[]) => {
      const invoicesToSend: InvoiceEmailDto[] = [];
      const invoicesToPromptForEmail: Invoice[] = [];

      invoices.forEach(invoice => {
        // Contacts should be loaded at this point as they are fetched by AccreditedBillingScreen
        const billTo = practice.contactsMap.get(invoice.accountId);
        if (billTo?.invoicingEmail || billTo?.claimsAdminEmail) {
          invoicesToSend.push({
            invoiceId: invoice.id,
            email: billTo?.invoicingEmail ?? billTo?.claimsAdminEmail
          });
        } else {
          invoicesToPromptForEmail.push(invoice);
        }
      });

      if (invoicesToSend.length) {
        await billing.sendInvoicePdf(invoicesToSend);
      }

      if (invoicesToPromptForEmail.length) {
        setInvoiceEmailQueue(invoicesToPromptForEmail);
      }
    };

    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <SelectedInvoicesMessageBar />
        {core.hasPermissions(Permission.InvoiceCreate) && (
          <>
            <SelectedInvoicesButton
              onClick={markInvoiceSentBulk}
              disabled={!selectedInvoices.length}
              text="Mark sent"
            />
            <SelectedInvoicesButton
              onClick={sendInvoicePdfBulk}
              text="Email"
              disabled={!selectedInvoices.length}
            />
            <AccreditedBillingEmailDialog />
          </>
        )}
      </Stack>
    );
  }
);
