import { observer } from "mobx-react-lite";

import { FontSizes, Heading, HideStack } from "@bps/fluent-ui";

import { AllocationFieldProps } from "../allocation-field/AllocationField.types.ts";
import { useAllocationOption } from "../allocation-field/hooks/useAllocationOption.ts";
import { ItemSelectionMode } from "../allocation-field/types.ts";

interface AllocationHeaderProps
  extends Pick<AllocationFieldProps, "defaultHeadingText" | "statusLabel"> {}

export const AllocationHeader: React.FC<AllocationHeaderProps> = observer(
  ({ statusLabel, defaultHeadingText }) => {
    const allocationOption = useAllocationOption();

    const showDefaultHeading =
      allocationOption?.itemSelectionMode === ItemSelectionMode.none &&
      defaultHeadingText;

    return (
      <>
        <HideStack when={!showDefaultHeading}>
          <Heading
            labelPaddings
            styles={{ root: { fontSize: FontSizes.large } }}
          >
            {defaultHeadingText}
          </Heading>
        </HideStack>
        <HideStack when={!!showDefaultHeading}>{statusLabel}</HideStack>
      </>
    );
  }
);
