import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { useFormState } from "react-final-form";

import { FontSizes, Stack, Text } from "@bps/fluent-ui";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { DiagnosisFormFields } from "@modules/acc/screens/shared-components/DiagnosisFormFields.tsx";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";

import { getEmptyDiagnosis } from "../../utils.ts";
import { claimAdjustmentFormNameOf } from "../claim-adjustment.utils.ts";
import { AddDiagnosisFormValidator } from "../validators/AddDiagnosisFormValidator.tsx";
import {
  ClaimAdjustmentModalDescriptions,
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";

export const AddDiagnosisFormSection: FC = observer(() => {
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const { claimAdjustment, claim, getSelectedFormDiagnoses } = useContext(
    ClaimAdjustmentContext
  );

  const { values: formValues } = useFormState<ClaimAdjustmentFormValues>();

  const allSelectedDiagnoses = getSelectedFormDiagnoses(
    claim.currentDiagnoses,
    formValues
  );

  const renderDescription = (text: ClaimAdjustmentModalDescriptions) => (
    <Text
      styles={{
        root: {
          fontSize: FontSizes.size12
        }
      }}
      as="p"
    >
      {text}
    </Text>
  );
  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.addDiagnosis}
      openOnRender={selectedMenuItemId === ClaimsAdjustmentCardIds.addDiagnosis}
      heading={ClaimAdjustmentSectionHeading.addADiagnosis}
      iconName="Medical"
      statusValidator={new AddDiagnosisFormValidator(claimAdjustment)}
      visible={!!claimAdjustment?.hasDiagnosisAddition}
    >
      <Stack
        styles={{
          root: {
            marginBottom: 24
          }
        }}
        tokens={{
          childrenGap: 8
        }}
      >
        {renderDescription(ClaimAdjustmentModalDescriptions.description1)}
        {renderDescription(ClaimAdjustmentModalDescriptions.description2)}
      </Stack>
      <DiagnosisFormFields
        isClaimAdjustment
        maxDiagnosisCount={6}
        showStatusCol={false}
        arrayFieldName={claimAdjustmentFormNameOf("diagnosisAdditions")}
        emptyDiagnosis={getEmptyDiagnosis}
        diagnoses={allSelectedDiagnoses}
      />
    </ClaimCard>
  );
});
