// the drag and drop functionality of React Big Calendar
// performs a shallow copy of the event using Object.assign
// this makes mobx observable objects computed properties unsuitable
// as they will be lost during the copy
// instead we create an array of objects where we copy all properties we are interested in;
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { User } from "@stores/core/models/User.ts";

import {
  AppointmentFilterStatus,
  BillingStatus,
  ClinicalStatus
} from "../appointments-filter/AppointmentFilter.types.ts";
import { BigCalendarEvent } from "../booking-calendar-event/BookingCalendarEvent.types.ts";

export const convertToBigCalendarEvent = (
  events: CalendarEvent[]
): BigCalendarEvent[] => {
  return events.map(model => ({
    id: model.id,
    startDateTime: model.startDateTime,
    endDateTime: model.endDateTime,
    changeLog: model.changeLog,
    priority: model.priority,
    appointmentStatus: model.appointmentStatus,
    type: model.type,
    contactId: model.contactId,
    userId: model.userId,
    typeRef: model.typeRef,
    contact: model.contact,
    isPatientCalendarEvent: model.isPatientCalendarEvent,
    user: model.user,
    calendarEventRecurrenceId: model.calendarEventRecurrenceId,
    purpose: model.purpose,
    isPartOfRecurrence: model.isPartOfRecurrence,
    bookedByUser: model.bookedByUser,
    orgUnitId: model.orgUnitId,
    bookedByType: model.bookedByType,
    hasMultipleLocks: false,
    invoiceStatus: model.invoiceStatus,
    calendarEventExtension: model.calendarEventExtension,
    hasOpenEncounters: model.hasOpenEncounters,
    isGroupAppointment: model.isGroupAppointment,
    groupAppointmentName: model.groupAppointmentName,
    attendanceStatus: model.attendanceStatus,
    model,
    isPseudoEvent:
      model.type === CalendarEventType.ClosedException ||
      model.type === CalendarEventType.AnotherLocation ||
      model.type === CalendarEventType.TemporaryReservation,
    startDate: model.startDateTime.toJSDate(),
    endDate: model.endDateTime.toJSDate()
  }));
};

export const eventIsInFilter = (
  event: CalendarEvent,
  filter: AppointmentFilterStatus | undefined
): boolean => {
  switch (filter) {
    case BillingStatus.Unbilled:
      return event.isUnbilled;
    case ClinicalStatus.incompleteNotes:
      return event.hasOpenEncounters;
    case InvoiceStatus.Unpaid:
      return (
        event.invoiceStatus === InvoiceStatus.Unpaid ||
        event.invoiceStatus === InvoiceStatus.Part
      );
    default:
      return (
        filter === event.appointmentStatus || filter === event.invoiceStatus
      );
  }
};

export const isEventFiltered = (
  event: CalendarEvent,
  filter: AppointmentFilterStatus | undefined
): boolean => {
  return eventIsInFilter(event, filter);
};

// currently, there is a bug when setting the maxTime
// https://github.com/intljusticemission/react-big-calendar/issues/999
// temporarily setting the minTime only until there is a fix
export const startAccessor = (event: BigCalendarEvent) => {
  return event.startDate;
};
export const endAccessor = (event: BigCalendarEvent) => event.endDate;
export const resourceAccessor = (event: BigCalendarEvent) => event.userId;
export const resourceIdAccessor = (user: User) => user.id;
export const resourceTitleAccessor = (user: User) => user.fullName;

export const getTimeslots = (step: number) => {
  const MAX_CALENDAR_STEP = 60;
  const timeslot: number = MAX_CALENDAR_STEP / step;
  if (Number.isInteger(timeslot)) return timeslot;
  throw Error(`${timeslot} timeslot is not an integer.`);
};
