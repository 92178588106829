import { observer } from "mobx-react-lite";

import { dataAttribute, DataAttributes } from "@bps/fluent-ui";
import { getUniqueObjectsByKeys } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useTemplateScreenContext } from "@modules/settings/screens/comms-templates/context/TemplateScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";

interface TemplatesListFilterProps extends Pick<FilterBarProps, "children"> {}

interface TemplatesFilterInternal {
  templateType?: string[];
  templateName?: string;
}

export const TemplatesFilter: React.FC<TemplatesListFilterProps> = observer(
  ({ ...rest }) => {
    const { templateListResults, getFilteredTemplates } =
      useTemplateScreenContext();

    const { core } = useStores();

    const filterItems: Item<TemplatesFilterInternal>[] = [
      {
        type: "searchBox",
        name: "templateName",
        stickItem: true,
        props: {
          ...dataAttribute(
            DataAttributes.Element,
            "templateList-patientName-filter"
          ),
          autoFocus: true,
          placeholder: "Search by name"
        }
      }
    ];

    if (core.hasPermissions(Permission.ApptConfirmationsAllowed)) {
      const templateTypes =
        (templateListResults &&
          templateListResults.value?.map(x => ({
            key: x.templateType,
            text: x.templateType
          }))) ||
        [];

      const uniqueTemplateTypesOptions = getUniqueObjectsByKeys({
        array: templateTypes,
        keys: ["key"]
      }).sort((a, b) =>
        a.key.trim().toLowerCase() > b.key.trim().toLowerCase() ? 1 : -1
      );

      filterItems.push({
        type: "optionsSelect",
        name: "templateType",
        props: {
          id: "templateList-filter-apptType",
          placeholder: "Type",
          options: uniqueTemplateTypesOptions,
          multiSelect: true,
          calloutWidth: 250
        }
      });
    }

    return (
      <FilterBar<TemplatesFilterInternal>
        {...rest}
        initialValues={{ templateName: undefined, templateType: [] }}
        onChange={({ values }) => {
          getFilteredTemplates(values, templateListResults.value);
        }}
        {...dataAttribute(DataAttributes.Element, "templateList-filter")}
        items={filterItems}
        styles={{
          searchBox: { minWidth: 220 }
        }}
      />
    );
  }
);
