import {
  CorrespondenceType,
  PurposeType,
  SubmitActionCode,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Template } from "@stores/documents/models/Template.ts";

import { TemplateWriterValues } from "./components/TemplateWriterValues.ts";

export const getDuplicateAutoFillInitialValues = (
  selectedTemplate: Template
): TemplateWriterValues => {
  const isAutoFill =
    selectedTemplate?.documentType === CorrespondenceType.Autofill;
  return {
    name: `${selectedTemplate?.name}(Copy)` ?? "",
    shortcut: selectedTemplate?.shortcut ?? "",
    documentType: selectedTemplate?.documentType ?? undefined,
    documentStatus: selectedTemplate?.documentStatus ?? "",
    availability: SubmitActionCode.PublishToSelfCode,
    visibility: TemplateVisibility.Clinical,
    purpose: isAutoFill ? PurposeType.AUTO : PurposeType.DOC,
    content: selectedTemplate?.content,
    documentContent: "",
    templateState: !isAutoFill ? SubmitActionCode.Published : undefined,
    publicOption: !isAutoFill ? SubmitActionCode.PublishToSelfCode : undefined
  };
};
