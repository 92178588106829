import { EMPTY_GUID } from "@libs/constants/constants.ts";
import {
  ClinicalActivityClinicalDataItemDto,
  ClinicalActivityMetadataItem,
  ClinicalTaskClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ActivityDescription } from "@stores/clinical/models/ActivityDescription.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";

import { ClinicalActivityType } from "../clinical-activity/types/clinical-activity.type.ts";

export const findRelatedClinicalDataItem = (
  clinicalTask: ClinicalTaskClinicalDataItemDto,
  clinicalActivityDescriptions: ActivityDescription[],
  clinicalActivities?: ClinicalActivityClinicalDataItemDto[]
) => {
  const clinicalTaskDescription = matchTaskTypeWithActivityDescription(
    clinicalTask.taskType,
    clinicalActivityDescriptions
  );

  if (!clinicalTaskDescription) {
    return undefined;
  }

  const clinicalActivitiesAssociated = clinicalActivities?.filter(
    x =>
      x.patientId === clinicalTask.patientId &&
      x.activityType === ClinicalActivityType.Task &&
      !x.reasonForDelete &&
      x.activityStatus !== ClinicalActivityStatus.Completed &&
      x.dueInVisits === clinicalTask.dueInVisits &&
      x.dueDate === clinicalTask.dueDate &&
      // Check if the description code matches the task type, or if it's other and the free text matches.
      (x.descriptionId === clinicalTaskDescription.id ||
        (clinicalTaskDescription.isOther &&
          x.freeText &&
          x.freeText === clinicalTask.taskType))
  );
  if (clinicalActivitiesAssociated && clinicalActivitiesAssociated.length > 0) {
    return clinicalActivitiesAssociated[0];
  }

  return undefined;
};

export const findRelatedClinicalActivity = (
  clinicalTask: ClinicalTask,
  clinicalActivityDescriptions: ActivityDescription[],
  clinicalActivities?: ClinicalActivity[]
) => {
  const clinicalTaskDescription = matchTaskTypeWithActivityDescription(
    clinicalTask.taskType,
    clinicalActivityDescriptions
  );

  if (!clinicalTaskDescription) {
    return undefined;
  }

  const clinicalActivitiesAssociated = clinicalActivities?.filter(
    x =>
      x.patientId === clinicalTask.patientId &&
      x.activityType === ClinicalActivityType.Task &&
      !x.reasonForDelete &&
      x.activityStatus !== ClinicalActivityStatusText.Completed &&
      x.dueInVisits === clinicalTask.dueInVisits &&
      x.dueDate === clinicalTask.dueDate &&
      // Check if the description code matches the task type, or if it's other and the free text matches.
      (x.descriptionId === clinicalTaskDescription.id ||
        (clinicalTaskDescription.isOther &&
          x.freeText &&
          x.freeText === clinicalTask.taskType))
  );
  if (clinicalActivitiesAssociated && clinicalActivitiesAssociated.length > 0) {
    return clinicalActivitiesAssociated[0];
  }

  return undefined;
};

export const updateActivityClinicalDataBasedOnTask = (options: {
  clinicalTask: ClinicalTaskClinicalDataItemDto;
  clinical: ClinicalStore;
  userId: string;
  associatedActivity?: ClinicalActivityClinicalDataItemDto;
}): ClinicalActivityClinicalDataItemDto | undefined => {
  const newMetadata: ClinicalActivityMetadataItem[] = [];
  const { clinicalTask, clinical, userId, associatedActivity } = options;
  // TEMP - Map the metadata
  if (clinicalTask.claimNumber) {
    newMetadata.push({ key: "ClaimNumber", value: clinicalTask.claimNumber });
  }

  const description = matchTaskTypeWithActivityDescription(
    clinicalTask.taskType,
    clinical.activityDescriptionMapValues
  );

  if (!description) {
    return undefined;
  }

  const noMatchingDescription = description.isOther;

  const result: ClinicalActivityClinicalDataItemDto = {
    id: associatedActivity?.id ?? EMPTY_GUID,
    descriptionId: description.id,
    activityPriority: clinicalTask.priority,
    activityType: ClinicalActivityType.Task,
    activityStatus: clinicalTask.isCompleted
      ? ClinicalActivityStatus.Completed
      : ClinicalActivityStatus.InProgress,
    patientId: clinicalTask.patientId,
    dueDate: clinicalTask.dueDate,
    dueInVisits: clinicalTask.dueInVisits,
    remainingVisits: clinicalTask.remainingVisits,
    comment: clinicalTask.comment,
    lockedBy: clinicalTask.lockedBy,
    deletedComment: clinicalTask.deletedComment,
    isSystemGenerated: clinicalTask.isSystemGenerated,
    businessRole: clinicalTask.businessRole,
    secGroupId: clinicalTask.secGroupId,
    completedBy: clinicalTask.completedBy,
    completedDate: clinicalTask.completedDate,
    freeText: noMatchingDescription ? clinicalTask.taskType : undefined,
    metadata: newMetadata,
    userId
  };

  return result;
};

export const matchTaskTypeWithActivityDescription = (
  activityTaskCode: string | undefined,
  activityDescriptions: ActivityDescription[]
) => {
  const otherDescription = activityDescriptions.find(
    x => x.isOther && x.activityType === ClinicalActivityType.Task
  );

  if (!activityTaskCode) {
    return otherDescription;
  }

  const matchingActivity = activityDescriptions.find(
    x => x.convertedDescriptionCode === activityTaskCode
  );
  if (matchingActivity) {
    return matchingActivity;
  }

  return otherDescription;
};
