import {
  CollapsibleCard,
  CollapsibleCardProps,
  HideStack,
  Variant
} from "@bps/fluent-ui";
import { Validator } from "@libs/validation/Validator.ts";
import { FormStatusIndicator } from "@ui-components/form/FormStatusIndicator/FormStatusIndicator.tsx";

interface CommsCardCardProps<T extends object>
  extends Omit<CollapsibleCardProps, "headingLevel"> {
  id: string;
  children?: React.ReactNode;
  statusValidator?: Validator<T>;
  headingLevel?: Variant;
  visible?: boolean;
}

export const CommsCard = <T extends object>(props: CommsCardCardProps<T>) => {
  const { id, children, statusValidator, visible = true, ...rest } = props;
  const formStatusIndicator = statusValidator ? (
    <FormStatusIndicator validator={statusValidator} />
  ) : undefined;

  return (
    <HideStack when={!visible}>
      <CollapsibleCard
        id={id}
        headingLevel="section-heading"
        button={formStatusIndicator}
        {...rest}
      >
        {children}
      </CollapsibleCard>
    </HideStack>
  );
};
