import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { PrimaryButton, Spinner, SpinnerSize, Stack } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import { EncounterReasonForVisitText } from "./clinical-form/EncounterReasonForVisitText.tsx";

interface ViewEncounterProps {
  clinicalRecord: ClinicalRecord;
}

export const EncounterDetails: FunctionComponent<ViewEncounterProps> = observer(
  ({ clinicalRecord }) => {
    const { isViewOnly } = usePatientRecordScreenContext();
    if (!clinicalRecord.openEncounter) {
      return <Spinner size={SpinnerSize.small} />;
    }

    const onDiscard = () => {
      if (clinicalRecord.openEncounter) {
        runInAction(() => {
          clinicalRecord.clinical.ui.isConfirmationDialogVisible = true;
        });
      } else {
        clinicalRecord?.close();
      }
    };

    return (
      <section>
        <div>
          <time>
            {clinicalRecord.openEncounter.startDateTime.toFormat(
              DATE_FORMATS.LOCALIZED_DATE_TIME
            )}
          </time>
          {clinicalRecord.openEncounter.user && (
            <>
              {" "}
              by <em>{clinicalRecord.openEncounter.user.fullName}</em>
            </>
          )}
        </div>
        <h3>
          <Stack verticalAlign="baseline">
            <EncounterReasonForVisitText
              encounter={clinicalRecord.openEncounter}
            />
          </Stack>
        </h3>
        {clinicalRecord.mainFreeText && (
          <div
            className="notes"
            dangerouslySetInnerHTML={{
              __html: clinicalRecord.mainFreeText
            }}
          />
        )}

        {!isViewOnly && <PrimaryButton text="Discard" onClick={onDiscard} />}
      </section>
    );
  }
);
