import { observer } from "mobx-react-lite";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  IconButton,
  Overlay,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import {
  AppointmentStatusText,
  InvoiceStatusText
} from "@ui-components/RefText.tsx";

import {
  AppointmentFilterStatus,
  BillingStatus
} from "./AppointmentFilter.types.ts";
import {
  APPT_FILTER_OVERLAY_ID,
  useAppointmentFilterOverlayScroll
} from "./useAppointmentFilterOverlayScroll.tsx";

interface AppointmentsFilterOverlayProps {
  top?: number; // it is the header height
}

export const AppointmentsFilterOverlay: React.FunctionComponent<AppointmentsFilterOverlayProps> =
  observer(({ top = 48 }) => {
    useAppointmentFilterOverlayScroll();

    const { setAppointmentsFilter, appointmentsFilter } =
      useBookingCalendarScreenContext();

    const theme = useTheme();

    const resetFiler = () => () => {
      setAppointmentsFilter();
    };

    const isAppointmentStatus: boolean = Object.values(
      AppointmentStatusCode
    ).includes(appointmentsFilter as AppointmentStatusCode);

    const isBillingStatus: boolean = Object.values(InvoiceStatus).includes(
      appointmentsFilter as InvoiceStatus
    );

    const getFilterText = (filter: AppointmentFilterStatus): any => {
      if (isAppointmentStatus) {
        return <AppointmentStatusText code={filter} />;
      } else if (isBillingStatus) {
        return <InvoiceStatusText code={filter} />;
      } else {
        // later I expect to have it in ref data
        if (filter === BillingStatus.Unbilled) {
          return "unbilled";
        } else {
          return "incomplete";
        }
      }
    };

    if (!appointmentsFilter) return null;

    return (
      <Overlay
        id={APPT_FILTER_OVERLAY_ID}
        isDarkThemed
        // ⚠️ before you do any changes!
        // z-index value depends on few others z-indexes in:
        // 1. Calendar.styles.tsx - time column;
        // 2. AppointmentsFilter.tsx;
        // 3. BookingCalendar.tsx - events styling;
        styles={{ root: { zIndex: 40, position: "fixed", top } }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            height: 60,
            backgroundColor: theme.semanticColors.primaryButtonBackground
          }}
        >
          <div style={{ justifySelf: "end", alignSelf: "center" }}>
            <Text
              styles={{
                root: {
                  color: theme.palette.white
                }
              }}
            >
              Filtering to show{" "}
              <b
                style={{ textTransform: "uppercase" }}
                {...dataAttribute(
                  DataAttributes.Element,
                  "app-overlay-filter-text"
                )}
              >
                {getFilterText(appointmentsFilter)}
              </b>{" "}
              appointments
            </Text>

            <DefaultButton
              {...dataAttribute(
                DataAttributes.Element,
                "app-overlay-filter-clear-btn"
              )}
              onClick={resetFiler()}
              styles={{
                root: {
                  marginLeft: 80,
                  minWidth: 134,
                  color: theme.semanticColors.primaryButtonBackground,
                  border: "none"
                },
                rootHovered: {
                  backgroundColor: theme.palette.white,
                  color: theme.semanticColors.primaryButtonBackground
                },
                rootPressed: {
                  backgroundColor: theme.palette.white,
                  color: theme.semanticColors.primaryButtonBackground
                },
                labelHovered: {
                  fontWeight: "bold"
                }
              }}
            >
              Clear filter
            </DefaultButton>
          </div>

          <IconButton
            {...dataAttribute(
              DataAttributes.Element,
              "app-overlay-filter-cancel-btn"
            )}
            onClick={resetFiler()}
            iconProps={{ iconName: "Cancel" }}
            styles={{
              root: {
                justifySelf: "end",
                color: theme.palette.white,
                border: "none"
              },
              rootHovered: {
                backgroundColor: "inherit",
                color: theme.palette.white
              },
              rootPressed: {
                backgroundColor: "inherit",
                color: theme.palette.white
              },
              iconHovered: {
                fontWeight: "bold"
              }
            }}
          />
        </div>
      </Overlay>
    );
  });
