import { runInAction, when } from "mobx";
import { observer } from "mobx-react-lite";

import { dataAttribute, DataAttributes, Heading, Text } from "@bps/fluent-ui";
import { ClaimDto, ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { EmailConfirmationModalValues } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.types.ts";
import { EmailConfirmationModalValidator } from "@ui-components/email-confirmation-modal/EmailConfirmationModalValidator.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

interface SendACC45EmailModalProps {
  patientId: string;
  patientName?: string;
  onSubmitSucceeded?: (values: EmailConfirmationModalValues) => void;
  claimDto: ClaimDto;
}

const validator = new EmailConfirmationModalValidator();
const nameOf = nameOfFactory<EmailConfirmationModalValues>();

const CHECKBOX_LABEL_TEXT = "Save as email address for patient";
const LABEL = "Send ACC45 to";
const EMAIL_TO_CLIENT_BUTTON_LABEL = "Email to client";

export const SendACC45EmailModal: React.FC<SendACC45EmailModalProps> = observer(
  ({ patientId, patientName, claimDto, onSubmitSucceeded }) => {
    const root = useStores();
    const { acc, practice, correspondence } = root;
    const { currentClaimToEmail } = acc.ui;

    return (
      <DataFetcher<Contact>
        fetch={({ practice }) => practice.getContact(patientId)}
        noExceptionsHandlers
      >
        {(contact, loading, error) => {
          const claimEmailValue = claimDto.patientEmail;

          const onDismiss = () => {
            acc.ui.setIsEmailACC45DialogVisible(false);
            acc.ui.setCurrentClaimToEmail();
          };

          const onEmailModalSubmit = async (
            values: EmailConfirmationModalValues
          ) => {
            correspondence.ui.isWaitingDocumentEvent = true;
            runInAction(() => {
              correspondence.ui.readyToSendEmailPatientId = claimDto.patientId;
            });

            const contact = await practice.getContact(patientId);

            const communications = [
              ...contact.communications,
              {
                type: CommunicationType.Email,
                value: values.email
              }
            ];

            const patchRequest = {
              id: contact.id,
              communications
            };

            if (values.isUpdatedEmail) {
              await practice.updatePatient(patchRequest);
              if (
                claimDto.statusCode === ClaimStatuses.Incomplete ||
                claimDto.statusCode === ClaimStatuses.Ready
              ) {
                await acc.patchClaim({
                  ...claimDto,
                  patientEmail: values.email
                });
              }
            }
            await acc.generateDocumentById(claimDto.id);
            try {
              await when(() => !!correspondence.ui.readyToSendEmailDocumentId, {
                timeout: 5000
              });
            } catch (e) {
              throw Error("Try again");
            }
            const documentId = correspondence.ui.readyToSendEmailDocumentId;
            if (documentId) {
              await acc.emailAcc45Summary({
                claimId: claimDto.id,
                documentId,
                emailAddress: values.email
              });
              runInAction(() => {
                correspondence.ui.readyToSendEmailDocumentId = undefined;
                correspondence.ui.readyToSendEmailPatientId = undefined;
                correspondence.ui.isWaitingDocumentEvent = false;
              });
            }
          };

          const getModal = () => {
            const initialValues: EmailConfirmationModalValues = {
              email: contact?.email ?? claimEmailValue ?? "",
              isUpdatedEmail: false
            };

            const isCheckboxShown = initialValues.email === "";

            const header = `Email to ${patientName}`;

            if (currentClaimToEmail) {
              const { id, patientId } = currentClaimToEmail;
              if (id && patientId) {
                return (
                  <SubmissionFormDialog
                    validate={validator.validate}
                    initialValues={initialValues}
                    onSubmit={onEmailModalSubmit}
                    onSubmitSucceeded={values => {
                      onSubmitSucceeded && onSubmitSucceeded(values);
                      onDismiss();
                    }}
                    dialogName="Email dialog"
                    buttonsProps={{
                      submitButtonProps: {
                        ...dataAttribute(DataAttributes.Element, "send-button"),
                        text: "Send now",
                        iconProps: { hidden: true }
                      },
                      cancelButtonProps: {
                        ...dataAttribute(
                          DataAttributes.Element,
                          "send-cancel-button"
                        )
                      },
                      disableSubmitOnPristine: isCheckboxShown
                    }}
                    dialogProps={{
                      onDismiss,
                      minWidth: 600,
                      dialogContentProps: {
                        title: (
                          <Heading variant="modal-heading">{header}</Heading>
                        )
                      }
                    }}
                    loadingData={loading}
                    dataLoadingError={error?.message}
                  >
                    {() => (
                      <Fieldset>
                        <TextInputField
                          label={LABEL}
                          required
                          name={nameOf("email")}
                        />
                        {isCheckboxShown && (
                          <CheckboxField
                            label={CHECKBOX_LABEL_TEXT}
                            name={nameOf("isUpdatedEmail")}
                          />
                        )}
                      </Fieldset>
                    )}
                  </SubmissionFormDialog>
                );
              }
            }
            return null;
          };

          return (
            <>
              <Text styles={{ root: { margin: "0 4px", color: "inherit" } }}>
                {EMAIL_TO_CLIENT_BUTTON_LABEL}
              </Text>
              {acc.ui.isEmailACC45DialogVisible && getModal()}
            </>
          );
        }}
      </DataFetcher>
    );
  }
);
