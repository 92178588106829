import { createContext, useContext } from "react";

import { WaitingListScreenHelper } from "./WaitingListScreenHelper.ts";

export const WaitingListScreenContext = createContext<WaitingListScreenHelper>(
  {} as WaitingListScreenHelper
);

export const useWaitingListScreenContext = () =>
  useContext(WaitingListScreenContext);
