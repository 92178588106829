import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useField } from "react-final-form";

import { ConfirmDialog, Toggle } from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { useAllocationField } from "../../allocation-field/hooks/useAllocationField.ts";
import {
  ACCOUNT_CREDIT_METHOD,
  allocationNameOf,
  PaymentFormValues
} from "./AllocationForm.types.ts";

interface OverpayingConfirmationDialogProps {
  onDismiss: () => void;
  onSucceeded: () => void;
}

export const OverpayingConfirmationDialog: FC<OverpayingConfirmationDialogProps> =
  observer(({ onDismiss, onSucceeded }) => {
    const { allocatableAmount, allocatedTotal } = useAllocationField();

    const [creditToggle, setCreditToggle] = useState<boolean>(false);

    const { billing } = useStores();

    const {
      input: { value: payments }
    } = useField<PaymentFormValues[]>(allocationNameOf("payments"), {
      subscription: { value: true }
    });

    const accountCreditMethod = payments[0].method === ACCOUNT_CREDIT_METHOD;

    return (
      <ConfirmDialog
        hidden={false}
        confirmButtonProps={{
          text: "Add account credit",
          disabled: !creditToggle,
          styles: {
            root: {
              display: accountCreditMethod ? "none" : undefined
            }
          }
        }}
        maxWidth={480}
        styles={({ theme }) => ({
          root: {
            ".ms-Dialog-header": {
              height: 80
            },
            ".ms-Dialog-subText": { color: theme.palette.neutralPrimary }
          }
        })}
        cancelButtonProps={{ text: "Close" }}
        dialogContentProps={{
          title: "Allocation and payment don't match",
          subText: `The payment amount entered (${currencyFormat(
            allocatableAmount
          )} ${
            accountCreditMethod
              ? "Account credit"
              : billing.ref.paymentMethods.get(payments[0].method)?.text
          }) and the amount allocated (${currencyFormat(
            allocatedTotal
          )}) are different`,
          styles: { subText: { marginBottom: 16 } }
        }}
        onConfirm={onSucceeded}
        onCancel={onDismiss}
      >
        {!accountCreditMethod && (
          <Toggle
            onText="Yes"
            offText="No"
            label={` Add ${currencyFormat(
              allocatableAmount - allocatedTotal
            )} as account
            credit?`}
            automationAttribute="overpaymentToggle"
            checked={creditToggle}
            onChange={() => setCreditToggle(prev => !prev)}
            styles={{ root: { marginBottom: 0 } }}
          />
        )}
      </ConfirmDialog>
    );
  });
