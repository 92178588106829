import { observer } from "mobx-react-lite";

import { Tile } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";

import { AgendaListScreen } from "../../agenda/AgendaListScreen.tsx";
import { DidNotArriveListScreen } from "../../did-not-arrive/DidNotArriveListScreen.tsx";
import { EventReminderScreen } from "../../event-reminders/EventReminderScreen.tsx";
import { WaitingListScreen } from "../../waiting-list/WaitingListScreen.tsx";
import { WaitingRoomScreen } from "../../waiting-room/WaitingRoomScreen.tsx";
import { CalendarLoadingOverlay } from "../calendar/CalendarLoadingOverlay.tsx";
import { BookingCalendar } from "./calendar/BookingCalendar.tsx";

export const BookingCalendarContent = observer(() => {
  const {
    isLoading,
    resourceIds,
    calendarPage,
    filteredCalendarEvents,
    isInitialLoading,
    isReloading
  } = useBookingCalendarScreenContext();

  if (isInitialLoading || (!resourceIds.length && isReloading)) {
    return (
      <Tile
        styles={{
          root: {
            flexGrow: 1,
            position: "relative"
          }
        }}
      >
        <CalendarLoadingOverlay />
      </Tile>
    );
  }

  switch (calendarPage) {
    case "agenda":
      return (
        <AgendaListScreen loading={isLoading} items={filteredCalendarEvents} />
      );
    case "waitingRoom":
      return <WaitingRoomScreen />;
    case "waitingList":
      return <WaitingListScreen />;
    case "didNotArrive":
      return <DidNotArriveListScreen selectedUsers={resourceIds} />;
    case "eventReminders":
      return <EventReminderScreen />;
    default:
      return <BookingCalendar />;
  }
});
