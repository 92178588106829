import { FormApi } from "final-form";
import { Observer } from "mobx-react-lite";
import { useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  IButtonProps,
  Stack
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

interface ClinicalFormHeaderButtonsProps {
  disableButtonCondition?: (
    stashedData?: StashedEncounterClinicalData
  ) => boolean;
  submitButtonTextCondition?: (
    stashedData?: StashedEncounterClinicalData
  ) => string;
  panelWidth: number;
  onCancel?: (form: FormApi<any, Partial<any>>) => void;
  hideSubmit?: boolean;
  clinicalToolAfterSubmit?: (value: boolean) => void;
  canSubmit?: (form: FormApi<any, Partial<any>>) => Promise<boolean>;
  extraPromptConditionOnCancel?: (form: FormApi<any, Partial<any>>) => boolean;
  promptOnCancel?: boolean;
  saveAndCloseOnClick: (form: FormApi<any, Partial<any>>) => void;
  additionalDisableCheck?: (values: Partial<any>) => boolean;
}

export const ClinicalFormHeaderButtons: React.FC<
  ClinicalFormHeaderButtonsProps
> = props => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { submitting, invalid, values } = useFormState({
    subscription: { submitting: true, invalid: true, values: true }
  });

  const saveAndCloseButton = (form: FormApi) => {
    return props.hideSubmit ? null : (
      <Stack.Item styles={{ root: { flexShrink: 0 } }}>
        <DefaultButton
          {...dataAttribute(DataAttributes.Element, "submit-and-close-button")}
          disabled={
            (props.disableButtonCondition &&
              props.disableButtonCondition(
                clinicalRecord.stashedClinicalData
              )) ||
            submitting ||
            invalid ||
            (props.additionalDisableCheck &&
              props.additionalDisableCheck(values))
          }
          iconProps={{ iconName: "SaveAndClose" }}
          text={
            props.panelWidth >= 940
              ? `${
                  props.submitButtonTextCondition
                    ? props.submitButtonTextCondition(
                        clinicalRecord.stashedClinicalData
                      )
                    : "Save"
                } & close`
              : undefined
          }
          styles={{ root: { minWidth: 56 } }}
          primary={true}
          onClick={() => props.saveAndCloseOnClick(form)}
        />
      </Stack.Item>
    );
  };

  const text =
    props.panelWidth >= 940
      ? `${
          props.submitButtonTextCondition
            ? props.submitButtonTextCondition(
                clinicalRecord.stashedClinicalData
              )
            : "Save"
        }`
      : undefined;

  return (
    <Observer>
      {() => {
        const submitButtonProps: IButtonProps = {
          text,
          disabled:
            (props.disableButtonCondition &&
              props.disableButtonCondition(
                clinicalRecord.stashedClinicalData
              )) ||
            submitting ||
            invalid ||
            (props.additionalDisableCheck &&
              props.additionalDisableCheck(values)),
          styles: { root: { minWidth: 56 } }
        };
        return (
          <FormSubmitButtons
            hideButtonsSeparator
            disableSubmitOnFormInvalid
            styles={{
              root: {
                padding: 0,
                margin: 0
              }
            }}
            onSubmit={async form => {
              let canSubmit = true;
              if (props.canSubmit) {
                canSubmit = await props.canSubmit(form);
              }
              if (canSubmit) {
                await form.submit();
              }
            }}
            onCancel={props.onCancel}
            promptOnCancel={props.promptOnCancel}
            extraPromptConditionOnCancel={props.extraPromptConditionOnCancel}
            submitButtonProps={submitButtonProps}
            extraActionsBetween={saveAndCloseButton}
            hideSubmit={props.hideSubmit}
          />
        );
      }}
    </Observer>
  );
};
