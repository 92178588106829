import { useForm } from "react-final-form";

import { CollapsibleCard, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClaimAdjustmentFormLabels } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentEnums.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { PresentingComplaintFormValues } from "./PresentingComplaintForm.types.ts";

interface CurrentInjuryCardFormProps {
  useSotapFormView?: boolean;
}

interface CurrentInjuryFormValues
  extends PresentingComplaintFormValues,
    SOTAPFormValues {}

export const CurrentInjuryCard: React.FC<CurrentInjuryCardFormProps> = ({
  useSotapFormView
}) => {
  const nameOf = nameOfFactory<CurrentInjuryFormValues>();
  const { clinical } = useStores();
  const getDailyPatterns = () => clinical.ref.dailyPatterns.keyNameValues;
  const getSleepPatterns = () => clinical.ref.sleepPatterns.keyNameValues;
  const form = useForm<CurrentInjuryFormValues>();

  return (
    <div id="PresentingHistory">
      <CollapsibleCard
        heading="Presenting history"
        headingLevel="section-heading"
        iconName="BpMSP24"
        openOnRender
      >
        <Stack tokens={{ childrenGap: 8, maxWidth: 630 }}>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <DatePickerField
              name={nameOf("dateOfInjury")}
              maxDate={DateTime.jsDateNow()}
              label={ClaimAdjustmentFormLabels.injuryDate}
            />
            <ToggleField
              styles={{
                root: { marginBottom: 0 },
                container: { marginTop: 5 }
              }}
              onText="Yes"
              offText="None"
              name={nameOf("addSurgeryDate")}
              label="Surgery"
            />
            <FieldCondition when={nameOf("addSurgeryDate")} is={true}>
              <Stack.Item align="end">
                <DatePickerField name={nameOf("dateOfSurgery")} />
              </Stack.Item>
            </FieldCondition>
            <FieldSpy
              name={nameOf("addSurgeryDate")}
              onChange={(checked: boolean) => {
                checked
                  ? form.change(nameOf("dateOfSurgery"), DateTime.jsDateNow())
                  : form.change(nameOf("dateOfSurgery"), undefined);
              }}
            />
          </Stack>
          <TextInputField
            label="Mechanism"
            name={nameOf("mechanismOfInjury")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: { minHeight: 60 }
            }}
          />
          <TextInputField
            label="History"
            name={nameOf("currentHistory")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: { minHeight: 60 }
            }}
          />
          {!!useSotapFormView && (
            <>
              <Stack horizontal>
                <ToggleField
                  styles={{
                    root: { marginBottom: 0 },
                    container: { marginTop: 5 }
                  }}
                  onText="Yes"
                  offText="Nil"
                  name={nameOf("investigationsFlag")}
                  label="Investigations"
                />
                <FieldSpy
                  name={nameOf("investigationsFlag")}
                  onChange={checked => {
                    if (!checked) {
                      form.change(nameOf("investigations"), undefined);
                      form.change(nameOf("investigationsComment"), undefined);
                    }
                  }}
                />
                <FieldCondition when={nameOf("investigationsFlag")} is={true}>
                  <DropdownField
                    name={nameOf("investigations")}
                    multiSelect
                    options={clinical.ref.investigations.keyTextValues}
                    placeholder="e.g. Xray"
                    styles={{
                      root: {
                        width: 200,
                        marginTop: "28px",
                        justifyContent: "flex-end"
                      }
                    }}
                  />
                </FieldCondition>
              </Stack>
              <FieldCondition when={nameOf("investigationsFlag")} is={true}>
                <TextInputField
                  name={nameOf("investigationsComment")}
                  multiline={true}
                  rows={3}
                  autoAdjustHeight
                  resizable={false}
                  styles={{ fieldGroup: { minHeight: 60 } }}
                />
              </FieldCondition>
            </>
          )}
          <Fieldset>
            <DropdownField
              label="Nature"
              name={nameOf("natures")}
              multiSelect
              options={clinical.ref.natures.keyTextValues}
              placeholder="e.g. Sharp shooting"
            />
            <TextInputField
              name={nameOf("naturesComment")}
              multiline={true}
              rows={3}
              autoAdjustHeight
              resizable={false}
              styles={{ fieldGroup: { minHeight: 60 } }}
            />
          </Fieldset>

          <ButtonsGroupSingleChoiceField
            label="Intensity"
            name={nameOf("severity")}
            options={clinical.ref.reactionSeverities.keyTextValues.reverse()}
          />
          {!useSotapFormView && (
            <>
              <ButtonsGroupSingleChoiceField
                label="Onset"
                name={nameOf("onset")}
                options={clinical.ref.onsets.keyTextValues.reverse()}
              />
              <Stack horizontal tokens={{ childrenGap: 8 }} wrap>
                <Stack.Item>
                  <SpinNumberInputField
                    label="Duration"
                    name={nameOf("duration")}
                    styles={{ root: { width: 100 } }}
                  />
                </Stack.Item>
                <Stack.Item align="end">
                  <ButtonsGroupSingleChoiceField
                    name={nameOf("durationPeriod")}
                    options={clinical.ref.durations.keyTextValues.map(d => {
                      return { key: d.key, text: d.text.toLowerCase() };
                    })}
                  />
                </Stack.Item>
              </Stack>
              <Fieldset>
                <CheckboxGroupField
                  name={nameOf("frequencyOccurrences")}
                  options={clinical.ref.frequencyOccurrences.keyLabelValues}
                  label="Frequency"
                />
                <FieldCondition
                  when={nameOf("frequencyOccurrences")}
                  is={(values: string[]) => {
                    return values.includes("INT");
                  }}
                >
                  <ButtonsGroupSingleChoiceField
                    name={nameOf("frequencyPeriod")}
                    options={clinical.ref.frequencies.keyTextValues.filter(
                      f => f.key !== "YEAR"
                    )}
                  />
                </FieldCondition>
              </Fieldset>
              <FieldSpy
                name={nameOf("frequencyOccurrences")}
                onChange={value => {
                  if (!value.includes("INT")) {
                    form.change(nameOf("frequencyPeriod"), undefined);
                  }
                }}
              />
              <TextInputField name={nameOf("radiation")} label="Radiation" />
              <TextInputField
                name={nameOf("associatedSymptoms")}
                label="Associated symptoms"
              />
              <TextInputField
                name={nameOf("concomitantFactors")}
                label="Concomitant factors"
              />
            </>
          )}
          {!!useSotapFormView && (
            <>
              <ButtonsGroupSingleChoiceField
                label="Progression"
                name={nameOf("progression")}
                options={clinical.ref.progressionTypes.keyTextValues}
              />
              <ButtonsGroupSingleChoiceField
                label="Swelling"
                name={nameOf("swelling")}
                options={clinical.ref.swellingTypes.keyTextValues}
              />
              <ButtonsGroupSingleChoiceField
                label="Stage"
                name={nameOf("stage")}
                options={clinical.ref.stages.keyTextValues}
              />
              <ButtonsGroupSingleChoiceField
                label="Irritability"
                name={nameOf("irritability")}
                options={clinical.ref.irritabilityLevels.keyTextValues}
              />
              <Fieldset>
                <StaticPickerField
                  name={nameOf("dailyPattern")}
                  label="Daily pattern"
                  fetchDataSource={getDailyPatterns}
                  fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
                  inputProps={{
                    placeholder: "e.g. Worse in the morning"
                  }}
                />
              </Fieldset>
              <Fieldset>
                <StaticPickerField
                  name={nameOf("sleepPattern")}
                  label="Sleep pattern"
                  fetchDataSource={getSleepPatterns}
                  fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
                  inputProps={{
                    placeholder: "e.g. Disturbed by pain"
                  }}
                />
              </Fieldset>
            </>
          )}
          <Fieldset>
            <DropdownField
              label="Aggravating factors"
              name={nameOf("aggravatingFactors")}
              multiSelect
              options={clinical.ref.aggravatingFactors.keyTextValues}
              placeholder="e.g. Running"
            />
            <TextInputField
              name={nameOf("aggravatingFactorsComment")}
              multiline={true}
              rows={3}
              autoAdjustHeight
              resizable={false}
              styles={{ fieldGroup: { minHeight: 60 } }}
            />
          </Fieldset>
          <Fieldset>
            <DropdownField
              label="Easing factors"
              name={nameOf("easingFactors")}
              multiSelect
              options={clinical.ref.easingFactors.keyTextValues}
              placeholder="e.g. Rest"
            />
            <TextInputField
              name={nameOf("easingFactorsComment")}
              multiline={true}
              rows={3}
              autoAdjustHeight
              resizable={false}
              styles={{ fieldGroup: { minHeight: 60 } }}
            />
          </Fieldset>
          {!useSotapFormView && (
            <>
              <DropdownField
                name={nameOf("impacts")}
                multiSelect
                label="Impact"
                options={clinical.ref.impacts.keyTextValues}
              />
              <Fieldset>
                <CheckboxGroupField
                  name={nameOf("attributes")}
                  options={clinical.ref.hofPCAttributes.keyLabelValues}
                  label="Attributes"
                />
                <FieldCondition
                  when={nameOf("attributes")}
                  is={(values: string[]) => {
                    return values.includes("OTHE");
                  }}
                >
                  <TextInputField
                    name={nameOf("attributeComment")}
                    styles={{ root: { paddingLeft: 24 } }}
                  />
                </FieldCondition>
              </Fieldset>
              <FieldSpy
                name={nameOf("attributes")}
                onChange={value => {
                  if (!value.includes("OTHE")) {
                    form.change(nameOf("attributeComment"), undefined);
                  }
                }}
              />

              <Fieldset>
                <CheckboxGroupField
                  name={nameOf("appliedTreatments")}
                  options={clinical.ref.patientAppliedTreatments.keyLabelValues}
                  label="Treatment to date"
                />
                <FieldCondition
                  when={nameOf("appliedTreatments")}
                  is={(values: string[]) => {
                    return values.includes("OTHE");
                  }}
                >
                  <TextInputField
                    name={nameOf("appliedTreatmentComment")}
                    styles={{ root: { paddingLeft: 24 } }}
                  />
                </FieldCondition>
              </Fieldset>
              <FieldSpy
                name={nameOf("appliedTreatments")}
                onChange={value => {
                  if (!value.includes("OTHE")) {
                    form.change(nameOf("appliedTreatmentComment"), undefined);
                  }
                }}
              />
            </>
          )}
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
