import { observer } from "mobx-react-lite";
import React from "react";

import {
  CompoundButton,
  FontIcon,
  IColumn,
  NoDataTile,
  Spinner,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import {
  ClinicalNoteSections,
  EncounterClinicalNoteDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { toStructuredNotes } from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { useEncounterExistsPromptContext } from "./models/EncounterExistsPromptContext.ts";
import { StructuredNoteText } from "./StructureNoteText.tsx";
import { getEncounterDayString } from "./utils.ts";

type OpenEncounterListProps = {
  openEncounters: Encounter[];
};

export const OpenEncounterDetailList: React.FC<OpenEncounterListProps> =
  observer(({ openEncounters }) => {
    const {
      selectedEncounterId,
      setSelectedEncounterId,
      fetchOpenEncounterDetail
    } = useEncounterExistsPromptContext();

    const { clinical } = useStores();
    const HTML_TAG = /(<([^>]+)>)/gi;
    const clinicalNotes = (
      structuredNotes: EncounterClinicalNoteDto,
      isString?: boolean
    ) => {
      let noteString = "";
      const clinicalNoteSections =
        clinical.ref.clinicalNoteSections.keyNameValues;

      const todaysNotes = toStructuredNotes(
        structuredNotes,
        undefined,
        clinicalNoteSections
      );

      const mainFreeText =
        structuredNotes.sectionElements?.find(
          item => item.sectionCode === ClinicalNoteSections.Main
        )?.text ?? "";

      todaysNotes.map(heading => {
        heading.structuredNotes?.map(structuredNote => {
          noteString += structuredNote.note;
          return noteString;
        });
        if (heading.freeText) noteString += heading.freeText;
        return noteString;
      });

      const hasClinicalNotes =
        structuredNotes &&
        (mainFreeText !== "" ||
          todaysNotes.some(x => x.freeText !== "") ||
          todaysNotes.some(
            x => x.structuredNotes && x.structuredNotes?.length > 0
          ));

      const openEncounterDetail = isString ? (
        noteString?.replace(HTML_TAG, "")
      ) : (
        <Stack
          styles={{
            root: {
              flexWrap: "nowrap",
              marginLeft: 4,
              overflow: "hidden",
              maxWidth: 300
            }
          }}
        >
          <StructuredNoteText
            mainFreeText={mainFreeText}
            notesHeadings={todaysNotes}
          />
        </Stack>
      );

      return !hasClinicalNotes ? (
        <NoDataTile
          textProps={{ text: "None recorded" }}
          styles={{ root: { minHeight: 28, marginTop: 8 } }}
          linkProps={{ hidden: true }}
          showBoxShadow={false}
          greyView={true}
        />
      ) : (
        openEncounterDetail
      );
    };

    const columnRender = (openEncounter: Encounter) => {
      return (
        <DataFetcher
          fetch={() => fetchOpenEncounterDetail(openEncounter)}
          fallback={<Spinner />}
        >
          {values => {
            const structuredNotes = values[0];
            const clinicalData = values[1];

            const encounterDateTime = openEncounter.startDateTime;
            const timeString = encounterDateTime.toFormat("hh:mma");
            const dayString = getEncounterDayString(encounterDateTime);

            const reasons = clinicalData?.reasonForVisit?.reasonForVisits;

            return (
              <CompoundButton
                styles={{
                  root: {
                    width: "100%",
                    justifyContent: "start"
                  }
                }}
                checked={openEncounter.id === selectedEncounterId}
                onClick={() => {
                  setSelectedEncounterId(openEncounter.id);
                }}
              >
                <Stack
                  horizontal
                  tokens={{ childrenGap: 16 }}
                  styles={{
                    root: {
                      width: "100%",
                      justifyContent: "start"
                    }
                  }}
                >
                  <FontIcon
                    styles={{ root: { fontSize: 18, marginTop: 4 } }}
                    iconName="recent"
                  />

                  <Stack tokens={{ childrenGap: 8 }}>
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                      <Text bold>Date</Text>
                      <Text>{`${dayString} at ${timeString}`}</Text>
                    </Stack>
                    <TooltipHost
                      content={reasons
                        ?.map(reason => reason.originalText)
                        .join(", ")}
                    >
                      <Stack
                        horizontal
                        tokens={{ childrenGap: 8 }}
                        styles={{ root: { maxWidth: 325 } }}
                      >
                        <Text bold>Reasons</Text>
                        <Text nowrap>
                          {reasons
                            ?.map(reason => reason.originalText)
                            .join(", ")}
                        </Text>
                      </Stack>
                    </TooltipHost>
                    <TooltipHost content={clinicalNotes(structuredNotes)}>
                      <Stack
                        horizontal
                        tokens={{ childrenGap: 8 }}
                        styles={{
                          root: {
                            maxHeight: 19,
                            maxWidth: 325,
                            overflow: "hidden"
                          }
                        }}
                      >
                        <Text bold>Notes</Text>
                        <Text nowrap>
                          {clinicalNotes(structuredNotes, true)}
                        </Text>
                      </Stack>
                    </TooltipHost>
                  </Stack>
                </Stack>
              </CompoundButton>
            );
          }}
        </DataFetcher>
      );
    };

    const columns: IColumn[] = [
      {
        key: "openEncounterDetail",
        name: "openEncounterDetail",
        minWidth: 30,
        maxWidth: 30,
        onRender: (openEncounter: Encounter) => {
          return columnRender(openEncounter);
        }
      }
    ];

    return (
      <ShimmeredDetailsList
        isHeaderVisible={false}
        items={openEncounters}
        columns={columns}
        compact
        bordersVariant="borderless"
        cellStyleProps={{
          cellExtraRightPadding: 8,
          cellRightPadding: 8,
          cellLeftPadding: 8
        }}
      />
    );
  });
