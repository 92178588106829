import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThanOrSameAs,
  integer,
  Length,
  lessThan,
  maxLength
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { SleepFormValues } from "../sleep/SleepFormValues.ts";

export class SleepFormValidator extends Validator<SleepFormValues> {
  readonly nameOf = nameOfFactory<SleepFormValues>();

  constructor() {
    super();
    this.forField(this.nameOf("numOfHours"), [
      integer(),
      greaterThanOrSameAs(0),
      lessThan(25)
    ]);

    this.forField(this.nameOf("weekendDifferComment"), [
      maxLength(Length.comments)
    ]);
    this.forField(this.nameOf("environment"), [maxLength(Length.comments)]);
    this.forField(this.nameOf("comfortableComment"), [
      maxLength(Length.comments)
    ]);
    this.forField(this.nameOf("stopsSleep"), [maxLength(Length.comments)]);
    this.forField(this.nameOf("whatHelpsSleep"), [maxLength(Length.comments)]);
  }
}
