import { useState } from "react";
import { useForm } from "react-final-form";

import { DefaultButton, Heading, Separator, Stack, Text } from "@bps/fluent-ui";
import { QuestionnaireDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { ClinicalToolAnswer } from "../utils/ClinicalToolAnswerFinder.ts";
import { DASHFormModel } from "./DASHFormModel.ts";
import { DASHModule } from "./DASHModule.tsx";

interface DASHBaselineFormProps {
  questionnaire: QuestionnaireDto;
  answers?: ClinicalToolAnswer[];
  model: DASHFormModel;
}

export const DASHBaselineForm: React.FunctionComponent<
  DASHBaselineFormProps
> = ({ questionnaire, answers, model }) => {
  const [workModule, setWorkModule] = useState<boolean>(model.workModuleOpen);
  const [sportModule, setSportModule] = useState<boolean>(
    model.sportModuleOpen
  );

  const form = useForm();

  const removeWorkModule = () => {
    setWorkModule(false);

    form.change("items.q12", undefined);
    form.change("items.q13", undefined);
    form.change("items.q14", undefined);
    form.change("items.q15", undefined);
    form.change("items.q16", undefined);
  };

  const removeSportModule = () => {
    setSportModule(false);
    form.change("items.q17", undefined);
    form.change("items.q18", undefined);
    form.change("items.q19", undefined);
    form.change("items.q20", undefined);
    form.change("items.q21", undefined);
  };

  const openWorkModule = () => {
    setWorkModule(true);
  };

  const openSportModule = () => {
    setSportModule(true);
  };

  const disablityQuestions = questionnaire.items.filter(
    x => x.id > 0 && x.id <= 11
  );

  const workQuestions = questionnaire.items.filter(
    x => x.id > 12 && x.id <= 16
  );

  const sportQuestions = questionnaire.items.filter(
    x => x.id > 17 && x.id <= 21
  );

  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const workTextQuestion = questionnaire.items.find(x => x.id === 12);
  const sportTextQuestion = questionnaire.items.find(x => x.id === 17);
  return (
    <div>
      <DASHModule questions={disablityQuestions} answers={answers} />
      {workModule && (
        <Stack>
          <Separator
            styles={{ root: { paddingTop: "16px", paddingBottom: "16px" } }}
          />
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack horizontal horizontalAlign="space-between">
              <Heading variant="section-heading">Work module</Heading>
              <DefaultButton text="Remove" onClick={removeWorkModule} />
            </Stack>
            <Text block>{workTextQuestion?.heading}</Text>
            <TextInputField
              label={workTextQuestion?.text}
              name="items.q12"
              styles={{
                fieldGroup: {
                  minHeight: 30,
                  minWidth: 300,
                  maxWidth: 300,
                  marginBottom: 16
                }
              }}
            />
          </Stack>
          <DASHModule questions={workQuestions} answers={answers} />
        </Stack>
      )}
      {sportModule && (
        <Stack>
          <Separator
            styles={{ root: { paddingTop: "16px", paddingBottom: "16px" } }}
          />
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack horizontal horizontalAlign="space-between">
              <Heading variant="section-heading">
                Sports/Performing Arts module
              </Heading>
              <DefaultButton text="Remove" onClick={removeSportModule} />
            </Stack>
            <Text block>{sportTextQuestion?.heading}</Text>
            <TextInputField
              label={sportTextQuestion?.text}
              name="items.q17"
              styles={{
                fieldGroup: {
                  minHeight: 30,
                  minWidth: 300,
                  maxWidth: 300,
                  marginBottom: 16
                }
              }}
            />
          </Stack>
          <DASHModule questions={sportQuestions} answers={answers} />
        </Stack>
      )}
      <Stack tokens={{ childrenGap: 8 }} styles={{ root: { paddingTop: 16 } }}>
        {!workModule && !isViewOnlyOrDischarged && (
          <Stack.Item
            styles={{
              root: {
                height: "40px"
              }
            }}
          >
            <DefaultButton
              text="Add work module"
              iconProps={{ iconName: "Add" }}
              onClick={openWorkModule}
            />
          </Stack.Item>
        )}
        {!sportModule && !isViewOnlyOrDischarged && (
          <Stack.Item
            styles={{
              root: {
                height: "40px"
              }
            }}
          >
            <DefaultButton
              text="Add sports/performing arts module"
              iconProps={{ iconName: "Add" }}
              onClick={openSportModule}
            />
          </Stack.Item>
        )}
      </Stack>
    </div>
  );
};
