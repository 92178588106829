import { nameof } from "@libs/utils/name-of.utils.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { PatientProviderDeclarationFormValues } from "@shared-types/acc/patient-provider-declaration-values.type.ts";

export class PatientProviderDeclarationFormValidator extends Validator<PatientProviderDeclarationFormValues> {
  constructor() {
    super();
    this.forField(nameof("patientDeclarationConfirmed"), [required()]);
    this.forField(nameof("providerDeclarationConfirmed"), [required()]);
  }
}
