import { DATE_FORMATS } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  email,
  isNotFutureDate,
  maxLength,
  phone,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { nhiMsg } from "@modules/practice/screens/contact-details/shared-components/edit/validators/PatientFormValidator.tsx";
import { ClaimPatientFormValues } from "@shared-types/acc/claim-patient-values.type.ts";
import { PersonalDetailsValuesType } from "@shared-types/acc/personal-details-values.type.ts";

import { ClaimAddressDraftValidator } from "../../claim-adjustment/validators/ClaimAddressDraftValidator.tsx";

class PatientValidator extends Validator<ClaimPatientFormValues> {
  constructor() {
    super();

    const claimAddressDraftValidator = new ClaimAddressDraftValidator();
    this.forField("patientFirstName", [required(), maxLength(40)]);
    this.forField("patientLastName", [required(), maxLength(50)]);
    this.forField("patientMiddleName", [maxLength(40)]);
    this.forField(nameof("patientEmail"), [email()]);
    this.forField(nameof("patientWorkPhoneNumber"), [phone, maxLength(20)]);
    this.forField(nameof("patientMobileNumber"), [phone, maxLength(20)]);
    this.forField(nameof("patientHomePhoneNumber"), [phone, maxLength(20)]);
    this.forField("patientAddress", claimAddressDraftValidator.validate);
    this.forField("patientDateOfBirth", [
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isNotFutureDate()
    ]);
    this.forField(nameof("patientNhiNumber"), [
      (value: string | undefined) => nhiMsg(value)
    ]);
  }
}

export class PersonalDetailsDraftValidator extends Validator<PersonalDetailsValuesType> {
  constructor() {
    super();

    const validator = new PatientValidator();
    this.forField("patient", validator.validate);
  }
}
