import { observer } from "mobx-react-lite";
import { useContext, useMemo } from "react";
import { FormRenderProps } from "react-final-form";

import { SideRailMenuItem, Stack, useScreenSize } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { FormTemplateTypeCode } from "@libs/gateways/forms/FormsGateway.dtos.ts";
import {
  AddPatientDto,
  ContactStatus,
  DvaCardColour
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FormWarningClaimPending } from "@modules/forms/components/FormWarningClaimPending.tsx";
import { PatientDemographicContext } from "@modules/practice/screens/contact-details/context/PatientDemographicContext.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact, toEntitlements } from "@stores/practice/models/Contact.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { PatientSetting } from "@stores/user-experience/models/PatientSetting.ts";
import { When } from "@ui-components/withPerm.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { toAddressDto } from "../../../shared-components/utils/contact.utils.ts";
import { ContactSubmissionFormDialog } from "../../shared-components/edit/ContactSubmissionFormDialog.tsx";
import { EditAccountBillTo } from "../../shared-components/edit/EditAccountBillTo.tsx";
import { EditCommsAndPreferences } from "../../shared-components/edit/EditCommsAndPreferences.tsx";
import { EditEntitlements } from "../../shared-components/edit/EditEntitlements.tsx";
import { EditFormLayout } from "../../shared-components/edit/EditFormLayout.tsx";
import { EditHealthProviders } from "../../shared-components/edit/EditHealthProviders.tsx";
import { EditPatientHeader } from "../../shared-components/edit/EditPatientHeader.tsx";
import { EditProfile } from "../../shared-components/edit/EditProfile.tsx";
import { EditRelationships } from "../../shared-components/edit/EditRelationships.tsx";
import { EditEmployers } from "../../shared-components/edit/employers/EditEmployers.tsx";
import { PatientEditFormValues as PatientFormValues } from "../../shared-components/edit/PatientEditFormValues.tsx";
import {
  getCommTypePreferences,
  getPatientFormValues,
  toCommunicationDto,
  toExternalProviderDto,
  toInternalProviderDto,
  toRelationshipDto,
  toRelationshipDtoFromAccountHoldersField,
  toRelationshipDtoFromEmployerField
} from "../../shared-components/edit/utils.ts";
import { PatientFormValidator } from "../../shared-components/edit/validators/PatientFormValidator.tsx";
import { PreferencesAndConsentsValidator } from "../../shared-components/edit/validators/PreferencesAndConsentsValidator.ts";

export interface PatientFormDialogProps {
  contact: Contact | undefined;
  contactPreferences: ContactPreferences | undefined;
  patientNotices: PatientNotice[] | undefined;
  patientSetting: PatientSetting | undefined;
}

const preferencesConsentsValidator = new PreferencesAndConsentsValidator();

export const PatientFormDialog: React.FC<PatientFormDialogProps> = observer(
  ({ contact, contactPreferences, patientNotices, patientSetting }) => {
    const {
      core,
      practice,
      notification,
      comms,
      forms,
      userExperience,
      booking
    } = useStores();

    const {
      ref: { languages },
      addPatient,
      updatePatient,
      deleteProfilePicture,
      ui: { demographicInitialValues, newContactType }
    } = practice;

    const { height } = useScreenSize();

    const isNewPatientVisible = !!newContactType;

    const helper = useContext(PatientDemographicContext);
    const { tenantDetails } = core;
    const SIDE_MENU_HEIGHT_BREAK_POINT = 848;
    const sideMenuHeight = height <= SIDE_MENU_HEIGHT_BREAK_POINT;
    const commsPrefText = useMemo(
      () =>
        sideMenuHeight
          ? Labels.commsPreferences
          : Labels.communicationPreferences,
      [sideMenuHeight]
    );

    const menuItems: (SideRailMenuItem & { permissions?: Permission[] })[] = [
      {
        text: Labels.personalInfo,
        id: PatientCardIds.patientHeader
      },
      {
        text: Labels.contactMethods,
        id: PatientCardIds.contactMethods
      },
      {
        text: commsPrefText,
        id: PatientCardIds.prefCons
      },
      {
        text: Labels.account,
        id: PatientCardIds.patientAccount,
        permissions: [Permission.AccountHistoryAllowed]
      },
      {
        text: Labels.relationships,
        id: PatientCardIds.contactRelationships
      },
      {
        text: Labels.cardsEntitlements,
        id: PatientCardIds.patientEntitlements
      },
      {
        text: Labels.healthProviders,
        id: PatientCardIds.healthProviders
      },
      {
        text: Labels.employers,
        id: PatientCardIds.employers
      },
      {
        text: Labels.profile,
        id: PatientCardIds.patientProfile
      }
    ];

    const submitPicture = async (contact: Contact, fileStagingId: string) => {
      await practice.updateProfilePicture({
        entityId: contact.id,
        fileStagingId
      });
    };

    const addEditContactPreferences = async (
      updatedPatient: Contact,
      values: PatientFormValues
    ) => {
      const commTypePreferences = getCommTypePreferences(values);

      if (contactPreferences)
        await comms.patchContactPreferences({
          commTypePreferences,
          id: updatedPatient.id,
          eTag: contactPreferences.eTag
        });
      else
        await comms.addContactPreferences({
          commTypePreferences,
          id: updatedPatient.id
        });
    };

    const createPatientNotice = async (updatedPatient: Contact) => {
      const patientNotices = await practice.getPatientNotices(
        updatedPatient.id
      );

      const genderDiverseNotice = patientNotices.find(x => x.type === "GD");
      if (!genderDiverseNotice) {
        const notice = {
          patientId: updatedPatient.id,
          priority: "MED",
          type: "GD",
          comment: "",
          isAdmin: false,
          isClinical: true
        };

        await practice.addPatientNotice(notice);
      }
    };

    const initialValues = useMemo(
      () =>
        getPatientFormValues(
          {
            contact,
            contactPreferences,
            patientSetting,
            isAddPatient: isNewPatientVisible
          },
          {
            core,
            practice,
            userExperience
          }
        ),
      [
        contact,
        core,
        isNewPatientVisible,
        practice,
        contactPreferences,
        userExperience,
        patientSetting
      ]
    );

    const permittedMenuItems = menuItems.filter(
      item => !item.permissions || core.hasPermissions(item.permissions)
    );

    const matchContactWithCalendarEvent = async (patientId: string) => {
      const onCreateContact = booking.ui.patientMatchInfo?.onCreateContact;
      onCreateContact && (await onCreateContact(patientId));
    };

    const onSubmit = async (values: PatientFormValues) => {
      const {
        employers,
        relationships,
        accountHolders,
        status,
        firstName,
        lastName,
        middleName,
        title,
        nickName,
        birthDate,
        dateOfDeath,
        medicare,
        healthInsurance: healthFund,
        dva,
        csc,
        nhi,
        profilePictureUrl,
        appointmentReminderType,
        appointmentReminderValue,
        appointmentConfirmationType,
        appointmentConfirmationValue,
        invoiceCommunicationType,
        invoiceCommunicationValue,
        emailForm,
        smsForm,
        ...restValues
      } = values;

      if (dva && dva.cardColor !== DvaCardColour.White) {
        dva.disability = undefined;
      }

      const healthFundNew = healthFund?.number
        ? {
            fund: healthFund.fund!,
            number: healthFund.number!,
            expiry: DateTime.jsDateToISODate(healthFund.expiry)
          }
        : undefined;

      const medicareNew = medicare?.number
        ? {
            number: medicare.number,
            irnNumber: medicare.irnNumber!,
            expiryMonth: medicare.expiryMonth!,
            expiryYear: medicare.expiryYear!
          }
        : undefined;

      const dvaNew = dva?.number
        ? {
            number: dva.number!,
            cardColor: dva.cardColor!,
            disability: dva.disability!
          }
        : undefined;

      const cscNew = csc?.number
        ? {
            number: csc.number!,
            startDate: DateTime.jsDateToISODate(csc.startDate),
            expiry: DateTime.jsDateToISODate(csc.expiry)
          }
        : undefined;

      const nhiNew = nhi
        ? {
            number: nhi.toUpperCase()
          }
        : undefined;

      const baseRequest: AddPatientDto = {
        ...restValues,
        fullName: {
          firstName,
          lastName,
          nickName,
          middleName,
          title
        },
        contactStatus: status,
        communications: toCommunicationDto(values.communications),
        addresses: toAddressDto(values.addresses),
        patientEntitlements: toEntitlements({
          med: medicareNew,
          hf: healthFundNew,
          dva: dvaNew,
          nhi: nhiNew,
          csc: cscNew
        }),
        birthday: birthDate ? DateTime.jsDateToISODate(birthDate) : undefined,
        dateOfDeath: dateOfDeath
          ? DateTime.jsDateToISODate(dateOfDeath)
          : undefined,
        internalProvider: toInternalProviderDto(values.internalProvider),
        externalProvider: toExternalProviderDto(values.externalProvider),
        interpreterLanguage: values.interpreterLanguage
          ? values.interpreterLanguage
          : undefined
      };

      baseRequest.relationships = toRelationshipDto(
        [...relationships, ...accountHolders],
        contact?.relationships
      );

      if (employers && employers.length > 0) {
        baseRequest.relationships.push(
          ...toRelationshipDtoFromEmployerField(employers)
        );
      }

      if (accountHolders && accountHolders.length > 0) {
        baseRequest.relationships.push(
          ...toRelationshipDtoFromAccountHoldersField(accountHolders)
        );
      }

      let updatedPatient;
      if (!values.id) {
        updatedPatient = await addPatient({
          ...baseRequest,
          contactStatus: ContactStatus.Active
        });
      } else {
        updatedPatient = await updatePatient({
          id: values.id,
          ...baseRequest
        });
      }

      if (contact) {
        userExperience.upsertPatientSetting({
          patientId: contact.id,
          pronounMessage: helper.displayGenderMessageBar
        });
      }

      if (updatedPatient) {
        await addEditContactPreferences(updatedPatient, values);
        if (
          updatedPatient.sex &&
          updatedPatient.gender &&
          updatedPatient.sex.toString() !== updatedPatient.gender.toString()
        ) {
          await createPatientNotice(updatedPatient);
        }
      }

      if (!!initialValues.profilePictureUrl && !values.profilePictureUrl) {
        try {
          await deleteProfilePicture(contact?.id!);
        } catch (error) {
          notification.error("Error occurred while removing profile photo");
        }
      }

      if (helper.profilePictureStagingId && updatedPatient) {
        // stores picture and fetches contact with new picture url
        await submitPicture(updatedPatient, helper.profilePictureStagingId);
      }
      if (typeof smsForm === "number" || typeof emailForm === "number") {
        try {
          const context: Record<string, string> = {
            PatientId: updatedPatient.id
          };

          const formTemplate = await forms.getSingleTemplateByCode(
            FormTemplateTypeCode.patientDemographic
          );
          if (formTemplate) {
            await forms.deployForm({ context, formTemplate });
          }
        } catch (error) {
          notification.error("Error occurred while deploying form");
        }
      }
      await matchContactWithCalendarEvent(updatedPatient.id);
    };

    return (
      <ContactSubmissionFormDialog<PatientFormValues>
        initialValues={initialValues}
        validate={(values: PatientFormValues) => {
          const validator = new PatientFormValidator({
            countries: core.ref.countries.values,
            languages: languages.values,
            oldCscExpiry: initialValues.csc?.expiry,
            country: core.tenantDetails!.country
          });
          return {
            ...validator.validate(values),
            ...preferencesConsentsValidator.validate(values)
          };
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          cancelButtonProps: {
            id: "close-patient-form-dialog"
          },
          hideButtonsSeparator: true,
          disableSubmitOnPristine: !demographicInitialValues,
          styles: {
            root: {
              marginTop: 0
            }
          }
        }}
      >
        {(props: FormRenderProps<PatientFormValues>) => {
          const { firstName, lastName } = props.values;
          return (
            <EditFormLayout menuItems={permittedMenuItems} contact={contact}>
              <Stack>
                {contact && (
                  <FormWarningClaimPending
                    patientId={contact.id}
                    formTemplateTypes={[
                      FormTemplateTypeCode.acc45Demographic,
                      FormTemplateTypeCode.patientDemographic
                    ]}
                  />
                )}
                <Stack tokens={{ childrenGap: 48 }} grow>
                  <EditPatientHeader
                    contact={contact}
                    patientNotices={patientNotices}
                    patientSetting={patientSetting}
                  />
                  <EditCommsAndPreferences country={tenantDetails?.country!} />
                  <When permission={[Permission.AccountHistoryAllowed]}>
                    <EditAccountBillTo />
                  </When>
                  <EditRelationships
                    fullName={
                      firstName && lastName ? `${firstName} ${lastName}` : ""
                    }
                  />
                  <EditEntitlements />
                  <EditHealthProviders />
                  <EditEmployers contact={contact} />
                  <EditProfile
                    isAddNew={isNewPatientVisible}
                    contact={contact}
                    sectionId={PatientCardIds.patientProfile}
                  />
                </Stack>
              </Stack>
            </EditFormLayout>
          );
        }}
      </ContactSubmissionFormDialog>
    );
  }
);
