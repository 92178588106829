import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  DefaultButton,
  DetailsList,
  Dialog,
  getColumnClampLinesSelector,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { EncounterFormContext } from "./models/EncounterFormContext.ts";

export const InvoicedServicesDialog: React.FC = observer(() => {
  const {
    setInvoicedServicesDialogVisible,
    isInvoicedServicesDialogVisible,
    openProviderCommentDialog,
    invoiceItems
  } = useContext(EncounterFormContext);

  const closeModal = () => {
    setInvoicedServicesDialogVisible(false);
  };

  const handleOpenProviderCommentDialog = action(() => {
    closeModal();
    openProviderCommentDialog();
  });

  return (
    <Dialog
      minWidth={600}
      hidden={!isInvoicedServicesDialogVisible}
      onDismiss={closeModal}
      dialogContentProps={{ title: "Invoiced services", showCloseButton: true }}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        <MessageBar messageBarType={MessageBarType.info}>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            <Stack.Item>
              Cannot alter services which have been invoiced. Contact the front
              office if corrections are needed.
            </Stack.Item>
            <Stack.Item shrink={false}>
              <Navigate
                onClick={handleOpenProviderCommentDialog}
                styles={{ root: { lineHeight: 16 } }}
              >
                Contact the front office
              </Navigate>
            </Stack.Item>
          </Stack>
        </MessageBar>
        <DetailsList
          items={invoiceItems}
          bordersVariant="fullHeader"
          styles={() => ({
            root: {
              ".ms-DetailsRow-cell": { whiteSpace: "initial" }
            }
          })}
          columns={[
            {
              key: "itemNumber",
              name: "Item number",
              minWidth: 100,
              maxWidth: 100,
              onRender: (item: InvoiceItemDto) => item.code
            },
            {
              key: "name",
              name: "Description",
              minWidth: 300,
              maxWidth: 300,
              onRender: (item: InvoiceItemDto) => (
                <Text styles={{ root: getColumnClampLinesSelector(3) }}>
                  {item.name}
                </Text>
              )
            },
            {
              key: "fee",
              name: "Fee incl. GST",
              minWidth: 80,
              maxWidth: 80,
              styles: { cellTitle: { justifyContent: "flex-end" } },
              onRender: (item: InvoiceItemDto) => (
                <Stack styles={{ root: { textAlign: "right" } }}>
                  {currencyFormat(item.fee)}
                </Stack>
              )
            }
          ]}
        />
        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { paddingTop: 24 } }}
        >
          <DefaultButton onClick={closeModal}>Close</DefaultButton>
        </Stack>
      </Stack>
    </Dialog>
  );
});
