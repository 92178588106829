import { action, computed, observable } from "mobx";

import { DateTime, newGuid } from "@bps/utils";
import {
  AddAllocationDto,
  AddAllocationItemDto,
  AddCreditNoteDto,
  AddInvoiceItemReferenceDto,
  AddRefundDto,
  AddRefundItemDto,
  ItemType,
  PaymentMethod
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { sum } from "@libs/utils/utils.ts";
import { AllocationFormLabels } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { getAllocationsFromInvoice } from "@modules/billing/screens/allocation/utils.ts";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import {
  AllocationFieldValues,
  ItemSelectionMode
} from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { getVoidedColumnContent } from "@modules/billing/screens/shared-components/allocation-field/utils.tsx";
import { AllocationOptions } from "@modules/billing/screens/shared-components/allocation-form/AllocationOptions.ts";
import { AllocationFormValues } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { AllocationOption } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationOptionsChoiceFields.tsx";
import { IAllocationFormHelper } from "@modules/billing/screens/shared-components/allocation-form/context/AllocationFormHelper.types.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { Allocation } from "@stores/billing/models/Allocation.ts";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { Refund } from "@stores/billing/models/Refund.ts";
import { getAddRefundBase } from "@stores/billing/utils/billing.utils.ts";

export interface CreditNoteHelperOptions {
  invoice: Invoice;
}

export enum DialogType {
  refund = "refund",
  credit = "credit"
}
export type RefundCreditDialogType = DialogType.refund | DialogType.credit;

export class CreditNoteHelper implements IAllocationFormHelper {
  constructor(
    private root: IRootStore,
    private options: CreditNoteHelperOptions
  ) {
    this.invoice = this.options.invoice;
  }

  private refundModalPaymentValue: PaymentMethod | undefined = undefined;

  accountStateHeading = AllocationFormLabels.accountStateAfterCredit;

  public balance?: AccountBalance;
  public invoice;

  public initialValues: AllocationFormValues;

  public number: string;

  public readonly disableAccountPicker = true;

  public readonly hideAmountSelection = true;

  public readonly hideManuallyAllocate = true;

  public readonly showMessageBar = true;

  @computed
  public get showModal() {
    return this.dialogType === DialogType.credit;
  }

  public onRefundSubmit = (method: PaymentMethod) => {
    this.refundModalPaymentValue = method;
  };

  @observable
  public dialogType: RefundCreditDialogType | undefined = undefined;

  @action
  public submitDialogType = (value: RefundCreditDialogType | undefined) => {
    this.dialogType = value;
  };

  public getColumnOptions = () => {
    return {
      filtersToShow: {
        invoice: true,
        providerName: true,
        patientName: true,
        credit: true,
        owing: true,
        voided: true
      },
      statusText: {
        partAllocated: "Part-credited",
        fullyAllocated: "Fully-credited"
      },
      headingOptions: { owingName: "Voided ($)" },
      calculationOptions: {
        owingCalc: getVoidedColumnContent
      }
    };
  };

  public get allocationOptions(): AllocationOption[] {
    return [
      {
        key: AllocationOptions.paySetItems,
        itemSelectionMode: ItemSelectionMode.none,
        text: "Full invoice",
        itemMaxTotalKey: "itemTotal"
      },
      {
        key: AllocationOptions.setAmount,
        text: "Selected items",
        itemMaxTotalKey: "itemTotal"
      }
    ];
  }

  public getOwingAllocationItems = (allocations: AllocationFieldValues[]) => {
    const allocationItems: Omit<AddAllocationItemDto, "creditId">[] = [];
    allocations.forEach(allocation => {
      const owingSelectedTotal = Math.min(
        allocation.owing || 0,
        allocation.total
      );
      if (owingSelectedTotal === 0) {
        return;
      }

      allocationItems.push({
        amount: owingSelectedTotal,
        itemType: ItemType.Allocation,
        comment: allocation.comment,
        invoiceItemId: allocation.invoiceItemId,
        locationId: allocation.locationId
      });
    });
    return allocationItems;
  };

  public getPaidRefundItems = (allocations: AllocationFieldValues[]) => {
    const refundItems: Array<
      Omit<AddRefundItemDto, "creditId" | "paymentMethod"> & {
        amount: number;
        invoiceItemId: string;
      }
    > = [];
    allocations.forEach(allocation => {
      const selectedPaidTotal = allocation.total - (allocation.owing || 0);
      if (selectedPaidTotal > 0) {
        refundItems.push({
          amount: selectedPaidTotal,
          itemType: ItemType.Allocation,
          comment: allocation.comment,
          invoiceItemId: allocation.invoiceItemId,
          locationId: allocation.locationId
        });
      }
    });
    return refundItems;
  };

  public onAllocationOptionsChanged = (allocationOption: string) => {
    if (allocationOption === AllocationOptions.setAmount) {
      return {
        payments: [
          {
            id: newGuid(),
            amount: this.invoice.total,
            method: PaymentMethod.Eftpos
          }
        ]
      };
    }
    return;
  };

  public getInitialValues = () => {
    const values: AllocationFormValues = {
      comment: "",
      allocations: getAllocationsFromInvoice({
        invoice: this.invoice,
        checked: true,
        filterOwing: false
      }).map(x => ({
        ...x,
        total: x.itemTotal || 0
      })),
      accountContactId: this.invoice.accountId,
      locationId: this.invoice.items.length
        ? this.invoice.items[0].locationId
        : "",
      allocationOption: AllocationOptions.paySetItems,
      paymentDate: DateTime.today().toJSDate(),
      payments: [
        {
          id: newGuid(),
          amount: this.invoice.total,
          method: PaymentMethod.Eftpos
        }
      ]
    };
    this.initialValues = values;
    return values;
  };

  private getAddCreditNoteDto = (values: AllocationFormValues) => {
    const { allocations, comment, paymentDate } = values;

    const creditItems: AddInvoiceItemReferenceDto[] = (allocations ?? [])
      .filter(x => x.checked)
      .map(allocation => ({
        itemType: ItemType.CreditNote,
        comment: allocation.comment,
        amount: allocation.total,
        invoiceItemId: allocation.invoiceItemId,
        locationId: values.locationId
      }));

    const location = this.root.core.getLocationName(values.locationId) ?? "";

    const credit: AddCreditNoteDto = {
      accountId: this.invoice.accountId,
      location,
      accountContact: this.invoice.accountContact,
      transactionDate: DateTime.fromJSDateOrNow(paymentDate).toISODate(),
      comment,
      itemType: ItemType.CreditNote,
      items: creditItems,
      number: this.number
    };
    return credit;
  };

  private getAddRefundDto = (options: {
    creditNote: CreditNote;
    allocations: AllocationFieldValues[];
    paymentMethod?: PaymentMethod;
    transactionNumber: string;
  }) => {
    const { creditNote, allocations, paymentMethod, transactionNumber } =
      options;

    if (!paymentMethod) {
      throw new Error("A payment method must be specified.");
    }

    const items = this.getPaidRefundItems(allocations);
    const paidSelectedTotal = sum("amount", items);

    if (paidSelectedTotal === 0) {
      return;
    }

    const refundItem: AddRefundItemDto = {
      paymentMethod,
      creditId: creditNote.id,
      amount: paidSelectedTotal,
      itemType: ItemType.Refund,
      locationId: items.length > 0 ? items[0].locationId : ""
    };

    const refundBase = getAddRefundBase(creditNote);

    const addRefund: AddRefundDto = {
      ...refundBase,
      transactionDate: creditNote.dto.transactionDate,
      itemType: ItemType.Refund,
      items: [refundItem],
      number: transactionNumber
    };

    return addRefund;
  };

  private getAddAllocationDto = (options: {
    creditNote: CreditNote;
    allocations: AllocationFieldValues[];
    transactionNumber: string;
  }) => {
    const { creditNote, allocations, transactionNumber } = options;
    const allocationItems = this.getOwingAllocationItems(allocations);

    if (!allocationItems.length) {
      return;
    }

    const addAllocationDto: AddAllocationDto = {
      accountId: creditNote.accountId,
      location: creditNote.location,
      accountContact: creditNote.accountContact,
      transactionDate: creditNote.dto.transactionDate,
      itemType: ItemType.Allocation,
      items: allocationItems.map(x => ({ ...x, creditId: creditNote.id })),
      number: transactionNumber
    };

    return addAllocationDto;
  };

  private submitCreditNote = async (values: AllocationFormValues) => {
    if (!values.allocations) {
      throw new Error("No Allocations selected.");
    }

    const hasOwingItems =
      this.getOwingAllocationItems(values.allocations).length > 0;

    const creditNote = await this.root.billing.addCreditNote(
      this.getAddCreditNoteDto(values)
    );

    const [refundNumber, allocationNumber] = await Promise.all([
      this.dialogType === DialogType.refund
        ? this.root.billing.generateRefundNumber()
        : undefined,
      !!hasOwingItems ? this.root.billing.generateAllocationNumber() : undefined
    ]);

    if (!refundNumber && !allocationNumber) {
      return;
    }

    const createTransactionPromises: Array<Promise<Refund | Allocation>> = [];

    if (this.refundModalPaymentValue && refundNumber) {
      const addRefundDto = this.getAddRefundDto({
        transactionNumber: refundNumber,
        creditNote,
        allocations: values.allocations,
        paymentMethod: this.refundModalPaymentValue
      });
      if (addRefundDto) {
        createTransactionPromises.push(
          this.root.billing.addRefund(addRefundDto)
        );
      }
    }

    if (allocationNumber) {
      const addAllocationDto = this.getAddAllocationDto({
        transactionNumber: allocationNumber,
        creditNote,
        allocations: values.allocations
      });

      if (addAllocationDto) {
        createTransactionPromises.push(
          this.root.billing.addAllocation(addAllocationDto)
        );
      }
    }

    await Promise.all(createTransactionPromises);
  };

  public onSubmit = async (values: AllocationFormValues) => {
    return this.submitCreditNote(values);
  };

  public onSubmitSucceeded = async () => {
    closeInvoiceOrPaymentPage(this.root.routing);
  };

  public close = () => {
    closeInvoiceOrPaymentPage(this.root.routing, "replace");
  };

  public initialise = async (): Promise<void> => {
    this.number = await this.root.billing.generateCreditNoteNumber();
    await this.reset();
  };

  public reset = async () => {
    const balance = this.invoice.accountId
      ? await this.root.billing.getAccountBalanceForAccountId(
          this.invoice.accountId
        )
      : undefined;

    this.balance = balance;
    this.getInitialValues();
  };
}
