import { useForm } from "react-final-form";

import { Card, Heading, Separator, Stack } from "@bps/fluent-ui";
import { getInjuryDiagnosisViewValues } from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClaimDiagnosisOverview } from "../../claim-management/components/ClaimDiagnosisOverview.tsx";
import {
  InjuryDiagnosisViewValues,
  LabeledTextFields
} from "../types/claim.types.ts";
import {
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";
import { ViewField } from "./ViewField.tsx";

const InjuryDiagnosisViewSectionBase: React.FC<{
  injuryDiagnosisValues: InjuryDiagnosisViewValues;
}> = ({ injuryDiagnosisValues }) => {
  const fields: LabeledTextFields[] = [
    {
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.injuryComments,
          styles: { text: { whiteSpace: "initial" } },
          children: injuryDiagnosisValues.injuryComments
        }
      ]
    },
    {
      labeledTexts: [
        {
          noSemicolon: true,
          label: ClaimFormLabels.gradualProcessInjury,
          children: injuryDiagnosisValues.gradualProcessInjuryText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.assistanceRequired,
          children: injuryDiagnosisValues.assistanceRequiredText
        },
        {
          noSemicolon: true,
          label: ClaimFormLabels.accContactProvider,
          children: injuryDiagnosisValues.accContactProviderText
        }
      ]
    }
  ];

  return (
    <Card
      heading={ClaimSectionHeadings.injuryDiagnosisAndAssistance}
      headingLevel="section-heading"
      iconName="Medical"
      id={ClaimsCardIds.injuryDiagnosisAndAssistance}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <ClaimDiagnosisOverview
          diagnoses={injuryDiagnosisValues.claimDiagnosis.map(x => ({
            description: x.terminology?.text,
            side: x.diagnosisSide
          }))}
        />
        <ViewField {...fields[0]} />
      </Stack>
      {fields.length > 1 && fields[1] && (
        <>
          <Separator />
          <Heading
            variant="section-heading-light"
            styles={{ root: { marginBottom: 8 } }}
          >
            Injury
          </Heading>
          <Stack tokens={{ childrenGap: 8 }}>
            <ViewField {...fields[1]} />
          </Stack>
        </>
      )}
    </Card>
  );
};

export const InjuryDiagnosisViewSection = () => {
  const values = useForm<ClaimFormValues>().getState().values;

  return (
    <DataFetcher fetch={root => getInjuryDiagnosisViewValues({ values, root })}>
      {(injuryDiagnosis: InjuryDiagnosisViewValues) => (
        <InjuryDiagnosisViewSectionBase
          injuryDiagnosisValues={injuryDiagnosis}
        />
      )}
    </DataFetcher>
  );
};
