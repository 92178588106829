import { observer } from "mobx-react-lite";

import { Heading, Stack, Text } from "@bps/fluent-ui";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import {
  CommTypePreferencesDto,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { FormsChannelType } from "@libs/gateways/forms/FormsGateway.dtos.ts";
import {
  CommunicationDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { SendFormConfirmationDialogProps } from "@shared-types/forms/send-form-confirmation-dialog-props.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { QRCodeDialog } from "./QRCodeDialog.tsx";

interface SendFormValues {
  commsPreference?: string;
  savePreference?: boolean;
}

class SendFormValuesValidator extends Validator<SendFormValues> {
  constructor(private dataEntryNeeded: boolean = false) {
    super();
    dataEntryNeeded && this.forField(nameof("commsPreference"), [required()]);
  }
}

const nameof = nameOfFactory<SendFormValues>();

const SendFormConfirmationDialogBase: React.FunctionComponent<
  SendFormConfirmationDialogProps
> = ({ formTemplate, context, addContextBeforeDeployAction, hostingUrl }) => {
  const trackFormEvent = useAppTrackEvent(AppInsightsEventNames.formDeployed);
  const { comms, forms } = useStores();
  const { getDetails } = forms;
  const patientId = context["PatientId"] ?? context["ClinicalPatientId"];

  const deployForm = async (
    values: SendFormValues,
    commsChoices: CommunicationDto[]
  ) => {
    //setup vars
    const { commsPreference, savePreference } = values;
    const commsChoice =
      commsPreference && commsChoices.find(x => x.value === commsPreference);

    const getChannelCode = (type: CommunicationType) =>
      type === "MOBILE" ? FormsChannelType.SMS : FormsChannelType.Email;

    const channelCode = commsChoice
      ? getChannelCode(commsChoice.type)
      : undefined;

    let finalContext = context;

    if (formTemplate) {
      if (addContextBeforeDeployAction) {
        finalContext = await addContextBeforeDeployAction(
          formTemplate,
          context
        );
      }
      await forms.deployForm({
        formTemplate,
        context: finalContext,
        channelCode,
        addressValue: commsChoice && commsChoice.value
      });
      trackFormEvent({ form: formTemplate.name, page: document.title });
    }

    const formCommsPreference: CommTypePreferencesDto = {
      commTypeCode: OutboundCommType.FormNotify,
      preferredCommChannelTypeCode: channelCode,
      preferredCommAddressValue: commsChoice ? commsChoice.value : undefined
    };

    savePreference &&
      (await comms.addOrUpdateContactPreferences(patientId, [
        formCommsPreference
      ]));
    forms.ui.setSendFormDialog(undefined);
  };

  return hostingUrl ? (
    <QRCodeDialog
      hostingUrl={hostingUrl}
      onClose={() => forms.ui.setSendFormDialog(undefined)}
      title={`${
        forms.ui.showSendFormsDialog?.name ||
        forms.ui.showSendFormsDialog?.formTemplate?.name
      } link`}
    />
  ) : (
    <DataFetcher<{ patient?: Contact; sendOptions: CommunicationDto[] }>
      fetch={async () => await getDetails(patientId)}
    >
      {commsChoices => {
        const savePreference = commsChoices.sendOptions.length > 0;

        const SendFormValidator = new SendFormValuesValidator(savePreference);

        return (
          <SubmissionFormDialog
            dialogName="Send confirmation dialog"
            initialValues={{ savePreference }}
            onSubmit={values => deployForm(values, commsChoices.sendOptions)}
            validate={values => SendFormValidator.validate(values)}
            buttonsProps={{
              disableSubmitOnPristine: savePreference,
              disableSubmitOnFormInvalid: true,
              submitButtonProps: {
                text: "Send",
                iconProps: { iconName: "send" }
              },
              hideButtonsSeparator: true
            }}
            dialogProps={{
              minWidth: 525,
              onDismiss: () => forms.ui.setSendFormDialog(undefined),
              dialogContentProps: {
                title: <Heading variant="modal-heading">Send</Heading>,
                showCloseButton: true
              }
            }}
          >
            {() => {
              return (
                <>
                  <Stack>
                    <Heading>{formTemplate?.name || ""}</Heading>
                  </Stack>
                  <Stack>
                    <Heading>to {commsChoices.patient?.name || ""}</Heading>
                  </Stack>
                  {commsChoices.sendOptions.length > 0 && (
                    <Stack>
                      <Stack styles={{ root: { marginTop: 16 } }}>
                        <Text>
                          The following contact methods are available but not
                          set up as preferred to receive links to online forms.
                          Please select one to send the link to:
                        </Text>
                      </Stack>
                      <Stack styles={{ root: { marginTop: 8 } }}>
                        <ChoiceGroupField
                          name={nameof("commsPreference")}
                          options={commsChoices.sendOptions.map(x => {
                            const val = {
                              text: x.value,
                              tag: x,
                              key: x.value
                            };
                            return val;
                          })}
                        />
                      </Stack>
                      <Stack styles={{ root: { marginTop: 24 } }}>
                        <CheckboxField
                          name={nameof("savePreference")}
                          label="Save as preferred contact method to receive links to online forms"
                        />
                      </Stack>
                    </Stack>
                  )}
                </>
              );
            }}
          </SubmissionFormDialog>
        );
      }}
    </DataFetcher>
  );
};

export const SendFormConfirmationDialog: React.FC = observer(() => {
  const {
    forms: { ui }
  } = useStores();
  return ui.showSendFormsDialog ? (
    <SendFormConfirmationDialogBase {...ui.showSendFormsDialog} />
  ) : null;
});
