import { FC } from "react";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Heading,
  Stack,
  Text
} from "@bps/fluent-ui";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

interface AccreditedEmployersListProps {
  isHidden?: boolean;
  onDismissModal: () => void;
  organisations: Contact[];
  loading?: boolean;
  error?: Error;
}

export const AccreditedEmployersList: FC<AccreditedEmployersListProps> = ({
  onDismissModal,
  isHidden,
  organisations,
  error,
  loading
}) => {
  const renderName = (item: Contact) => <Text>{item.name}</Text>;

  const columns = [
    {
      key: "name",
      isRowHeader: true,
      minWidth: 75,
      name: "Name",
      onRender: renderName,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false
    }
  ];

  return (
    <Dialog
      hidden={isHidden}
      onDismiss={onDismissModal}
      dialogContentProps={{
        title: (
          <Heading variant="modal-heading">
            Associated accredited employers
          </Heading>
        ),
        showCloseButton: true,
        styles: {
          content: { height: "100%" },
          innerContent: {
            height: "611px"
          },
          inner: {
            paddingLeft: 24,
            paddingRight: 24
          }
        }
      }}
      minWidth={600}
    >
      <Stack
        styles={{
          root: {
            height: "100%",
            overflowY: "auto"
          }
        }}
      >
        <ShimmeredDetailsList
          items={organisations}
          columns={columns}
          errorMessage={error?.message}
          enableShimmer={loading}
        />
      </Stack>
      <DialogFooter>
        <DefaultButton onClick={onDismissModal} text="Close" />
      </DialogFooter>
    </Dialog>
  );
};
