import { createContext, FC, PropsWithChildren, useContext } from "react";

import { CommsScheduleScreenHelper } from "./CommsScheduleScreenHelper.ts";

export const CommsScheduleScreenContext =
  createContext<CommsScheduleScreenHelper>({} as CommsScheduleScreenHelper);

export const useCommsSchedulesScreenContext = () =>
  useContext(CommsScheduleScreenContext);

export const SchedulesScreenContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <CommsScheduleScreenContext.Provider
      value={new CommsScheduleScreenHelper()}
    >
      {children}
    </CommsScheduleScreenContext.Provider>
  );
};
