import imageCompression from "browser-image-compression";
import { observer } from "mobx-react-lite";
import { FC, useRef, useState } from "react";
import { DropzoneOptions } from "react-dropzone";
import { useForm, useFormState } from "react-final-form";

import {
  AnonymousCredential,
  BlockBlobClient,
  ContainerClient,
  newPipeline
} from "@azure/storage-blob";
import { Spinner, Stack, Text, useTheme } from "@bps/fluent-ui";
import { IStyleFunctionOrObject } from "@fluentui/utilities";
import { GetSasUriResponseDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Guid } from "@syncfusion/ej2-pdf-export";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropzoneSection } from "@ui-components/drop-zone-section/DropzoneSection.tsx";
import {
  DropzoneSectionStyleProps,
  DropzoneSectionStyles
} from "@ui-components/drop-zone-section/DropzoneSection.types.ts";

import { UserInfoFormValues } from "./UserInfoForm.types.ts";

export interface ProviderSignatureFieldProps {
  name: string;
  signatureUrl?: string;
  label: string;
  onSignatureCleared: () => void;
  onFileAccepted: (stagingId: string) => void;
  styles?: IStyleFunctionOrObject<
    DropzoneSectionStyleProps,
    DropzoneSectionStyles
  >;
}

export const TWO_MB_IN_BYTES = 2000000;

export const ProviderSignatureField: FC<ProviderSignatureFieldProps> = observer(
  props => {
    const theme = useTheme();
    const { practice, notification } = useStores();

    const [spinner, setSpinner] = useState<boolean>(false);
    const stagingPath = useRef<GetSasUriResponseDto>();
    const form = useForm<UserInfoFormValues>();
    const { values } = useFormState<UserInfoFormValues>();

    const getContainerClient = async (): Promise<ContainerClient> => {
      stagingPath.current = await practice.getSaSUri();
      return new ContainerClient(
        stagingPath.current.sasUri,
        newPipeline(new AnonymousCredential())
      );
    };

    const getBlockBlobClient = (
      containerClient: ContainerClient,
      blobName: string
    ): BlockBlobClient => containerClient.getBlockBlobClient(blobName);

    const handleDropAccepted: DropzoneOptions["onDropAccepted"] = async (
      files: File[]
    ) => {
      // one file only
      if (files?.length > 0) {
        const file = files[0];

        try {
          setSpinner(true);
          const containerClient = await getContainerClient();

          let fileToUpload = file;

          // Compress the file if it exceeds 2MB.
          if (fileToUpload.size > TWO_MB_IN_BYTES) {
            fileToUpload = await imageCompression(file, { maxSizeMB: 2 });
          }

          const blobName =
            stagingPath.current?.fileStagingId ?? Guid.getNewGuidString();

          const aborter = new AbortController();
          const blockBlobClient = getBlockBlobClient(containerClient, blobName);

          await blockBlobClient.uploadBrowserData(fileToUpload, {
            abortSignal: aborter.signal,
            blobHTTPHeaders: { blobContentType: fileToUpload.type }
          });

          const url = URL.createObjectURL(fileToUpload);
          form.change("signatureFile", url);
          props.onFileAccepted(blobName);
        } catch (reason) {
          notification.error("Failed to upload signature");
        }
      }

      setSpinner(false);
    };

    const renderSignature = (signatureUrl: string) => {
      return (
        <Stack
          horizontal
          verticalAlign="center"
          styles={{
            root: {
              width: "100%"
            }
          }}
        >
          <Stack.Item
            styles={{
              root: {
                padding: "8px",
                border: `1px solid ${theme.palette.neutralLight}`,
                borderRadius: "4"
              }
            }}
            grow
          >
            <img
              src={signatureUrl}
              style={{ maxHeight: "144px" }}
              alt="User signature"
            />
          </Stack.Item>
          <DeleteButton
            styles={{ root: { left: "8px" } }}
            onClick={() => {
              form.change("signatureFile", undefined);
              props.onSignatureCleared();
            }}
          />
        </Stack>
      );
    };

    return (
      <Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 16 }}
          styles={{ root: { padding: "5px 0px" } }}
        >
          <Text bold>{props.label}</Text>
          {(props.signatureUrl || values.signatureFile) && (
            <DropzoneSection
              isLink
              linkText="Replace"
              onDropAccepted={handleDropAccepted}
              accept={{
                "image/*": []
              }}
              maxFiles={1}
              multiple={false}
            />
          )}
        </Stack>

        {!spinner && (
          <>
            {props.signatureUrl &&
              !values.signatureFile &&
              renderSignature(props.signatureUrl)}
            {values.signatureFile && renderSignature(values.signatureFile)}
            {!props.signatureUrl && !values.signatureFile && (
              <DropzoneSection
                onDropAccepted={handleDropAccepted}
                accept={{
                  "image/*": []
                }}
                maxFiles={1}
                multiple={false}
                fileTooLargeText="is greater than 2MB limit"
                incorrectFileTypeText="is an incorrect type, only images are allowed"
                tooManyFilesText="Only 1 file can be used as a signature"
              />
            )}
          </>
        )}
        {spinner && <Spinner />}
      </Stack>
    );
  }
);
