import { DetailsList, Spinner, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClinicalDataTypeLabel,
  MeasurementDto,
  MeasurementType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { generateDashMeasurements } from "../clinical-tools/clinical-tools.utils.ts";
import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";

interface ClinicalToolComponentProps {
  clinicalRecord: ClinicalRecord;
}

export const ClinicalToolCardComponent: React.FunctionComponent<
  ClinicalToolComponentProps
> = ({ clinicalRecord }) => {
  return (
    <DataFetcher
      fetch={() => clinicalRecord.loadMeasurementData()}
      fallback={<Spinner />}
    >
      {() => {
        const content = getContent(clinicalRecord.measurements);
        return content ?? <PatientSummaryEmptyState />;
      }}
    </DataFetcher>
  );
};

const twentyFourMonthsAgo = DateTime.now().minus({ months: 24 });

const dateFilter = (measurement: MeasurementDto): boolean => {
  const createdDate = DateTime.fromISOOrNow(measurement.changeLog?.createdDate);

  if (!createdDate.isValid)
    throw new Error(
      `Unable to parse ${
        measurement.changeLog!.createdDate
      } to a valid date time.`
    );

  return createdDate.diff(twentyFourMonthsAgo, ["days"]).days >= 0;
};

export const getClinicalToolContent = (
  measurements?: Measurement[]
): Measurement[] | undefined => {
  return measurements?.filter(m => dateFilter(m));
};

const getContent = (measurements?: Measurement[]) => {
  const filteredMeasurements = getClinicalToolContent(measurements);

  const showClinicalToolsMeasurementsOnly = filteredMeasurements?.filter(
    x =>
      x.type === MeasurementType.GRCS ||
      x.type === MeasurementType.PSFS ||
      x.type === MeasurementType.NPRS ||
      x.type === MeasurementType.OREBRO ||
      x.type === MeasurementType.K10 ||
      x.type === MeasurementType.DASH
  );

  const includeDashMeasurements =
    showClinicalToolsMeasurementsOnly &&
    generateDashMeasurements(showClinicalToolsMeasurementsOnly);

  return (
    <>
      {includeDashMeasurements && includeDashMeasurements?.length > 0 ? (
        <Stack
          styles={{
            root: {
              overflowY: "auto"
            }
          }}
        >
          <DetailsList
            setKey="past consults"
            items={includeDashMeasurements}
            compact
            columns={[
              {
                name: "Date",
                key: "date",
                minWidth: 75,
                maxWidth: 75,
                onRender: (item: MeasurementDto) => (
                  <div>
                    {DateTime.fromISOOrNow(
                      item.changeLog?.createdDate
                    ).toDayDefaultFormat()}
                  </div>
                )
              },
              {
                name: "Tool",
                key: "tool",
                minWidth: 60,
                maxWidth: 60,
                onRender: (item: MeasurementDto) =>
                  item.type === MeasurementType.DASH
                    ? ClinicalDataTypeLabel.DASH11
                    : item.type
              },
              {
                name: "Result",
                key: "result",
                minWidth: 60,
                isMultiline: true,
                onRender: (item: MeasurementDto) => item.summary
              }
            ]}
            cellStyleProps={{
              cellExtraRightPadding: 24,
              cellRightPadding: 8,
              cellLeftPadding: 2
            }}
          />
        </Stack>
      ) : (
        <PatientSummaryEmptyState />
      )}
    </>
  );
};
