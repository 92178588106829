import { IDialogContentProps, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { CancelTransactionValidator } from "./CancelTransactionValidator.tsx";

export interface CancelInvoiceDialogProps {
  onConfirm: (reason: string) => void | Promise<void>;
  onCancel: () => void;
  hidden: boolean;
  initialValues?: Partial<CancelInvoiceFormValues>;
  subTextJSX?: JSX.Element;
  dialogContentProps?: IDialogContentProps;
  transactionType?: "invoice" | "payment" | "refund" | "allocation";
}

export interface CancelInvoiceFormValues {
  reason: string;
  otherReason?: string;
}

const validator = new CancelTransactionValidator();

const CancelTransactionDialogBase: React.FC<
  CancelInvoiceDialogProps
> = props => {
  const { billing, notification } = useStores();
  const {
    hidden,
    onConfirm,
    onCancel,
    initialValues,
    subTextJSX,
    dialogContentProps,
    transactionType
  } = props;

  const options = billing.ref.transactionCancelReason.keyTextValues;

  if (hidden) {
    return null;
  }

  const handleConfirm = async (values: CancelInvoiceFormValues) => {
    const reasonText =
      values.otherReason ||
      billing.ref.transactionCancelReason.get(values.reason)?.text;

    try {
      if (reasonText) {
        await onConfirm(reasonText);
      }
    } catch (e) {
      notification.error(e.message);
    }
  };

  const reasonOptions = Array.from(options);
  reasonOptions.push({ key: "other", text: "Other" });

  return (
    <SubmissionFormDialog<CancelInvoiceFormValues>
      dialogName="Cancelling transaction"
      onSubmit={handleConfirm}
      initialValues={{
        reason: options[0].key,

        ...initialValues
      }}
      validate={validator.validate}
      dialogProps={{
        title: "Cancelling transaction",
        minWidth: 430,
        maxWidth: 430,
        onDismiss: onCancel,
        dialogContentProps
      }}
      buttonsProps={{
        disableSubmitOnPristine: false,
        submitButtonProps: {
          text: `Cancel ${transactionType}`,
          iconProps: {
            hidden: true
          }
        }
      }}
    >
      {({ form }) => (
        <Stack tokens={{ childrenGap: 8 }}>
          {subTextJSX && subTextJSX}

          <ChoiceGroupField
            name="reason"
            options={reasonOptions}
            label="Reason"
            required
          />
          <FieldCondition when="reason" is="other">
            <TextInputField label="Comment" name="otherReason" />
          </FieldCondition>
          <FieldSpy
            name="reason"
            onChange={value => {
              if (value) {
                form.change("otherReason");
              }
            }}
          />
        </Stack>
      )}
    </SubmissionFormDialog>
  );
};

export const CancelTransactionDialog = withFetch(
  x => [x.billing.ref.transactionCancelReason.load()],
  CancelTransactionDialogBase
);
