import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext, useEffect } from "react";
import { useFormState } from "react-final-form";

import { FontSizes, MessageBar, Spinner, Stack } from "@bps/fluent-ui";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { WaitingListDetailsDialog } from "@modules/booking/screens/waiting-list/components/waiting-list-details-dialog/WaitingListDetailsDialog.tsx";
import { SendFormCheckbox } from "@modules/forms/components/SendFormCheckbox.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  Contact,
  contactFirstNameCompareFn
} from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialogHeader } from "@ui-components/form/submission-form-dialog/SubmissionFormDialogHeader.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { Booked } from "../../../shared-components/Booked.tsx";
import { AppointmentDetails } from "./appointment-details/AppointmentDetails.tsx";
import {
  appointmentFormNameOf,
  AppointmentFormProps
} from "./AppointmentForm.types.ts";
import { AppointmentFormSubmitButtons } from "./AppointmentFormSubmitButtons.tsx";
import { AppointmentTypeField } from "./AppointmentTypeField.tsx";
import { ChoiceButtons } from "./ChoiceButtons.tsx";
import { CreateTemporaryReservation } from "./CreateTemporaryReservation.tsx";
import { getActiveAttendees } from "./group-attendees-details/GroupAttendeesHelper.ts";
import { GroupAppointmentFields } from "./GroupAppointmentFields.tsx";
import { GroupAttendeesFacePile } from "./GroupAttendeesFacePile.tsx";
import { IndividualAppointmentFields } from "./IndividualAppointmentFields.tsx";
import { OutsideWorkingHoursMessageBar } from "./OutsideWorkingHoursMessageBar.tsx";
import { RepeatDetailsButton } from "./RepeatDetailsButton.tsx";
import { WaitingListFields } from "./WaitingListFields.tsx";

export const AppointmentFormFields: FunctionComponent<AppointmentFormProps> =
  observer(({ onCancel }) => {
    const { values } = useFormState<AppointmentFormValues>({
      subscription: { values: true }
    });

    const { booking, practice } = useStores();

    const {
      calendarEvent,
      checkAppointmentWithinUserWorkTime,
      isAppointmentValidForEdit,
      isGroupAppointment,
      onApptDetailsClick
    } = useContext(AppointmentFormContext);

    useEffect(() => {
      checkAppointmentWithinUserWorkTime({
        startDate: values.startDate,
        duration: values.duration,
        startTime: values.startTime,
        providerId: values.providerId
      });
    }, [
      values.providerId,
      values.duration,
      values.startDate,
      values.startTime,
      checkAppointmentWithinUserWorkTime
    ]);

    const activeAttendees = getActiveAttendees(values.groupAttendees || []);

    return (
      <>
        <SubmissionFormDialogHeader
          onCancel={onCancel}
          title={`${
            !booking.ui.currentAppointment?.id ? "New" : "Edit"
          } appointment`}
          dataTestAttr={booking.ui.currentAppointment?.id}
        />
        <Stack
          tokens={{ childrenGap: 8 }}
          styles={{
            root: {
              overflowY: "auto",
              padding: "0 24px"
            }
          }}
        >
          <IndividualAppointmentFields />
          <DataFetcher<Contact[]>
            fetch={async root => {
              const contactIds = activeAttendees.map(
                attendee => attendee.attendeeId
              );

              const contacts = await root.practice.getContactsById(contactIds);

              return contacts.sort(contactFirstNameCompareFn);
            }}
            fallback={<Spinner />}
            refetchId={activeAttendees.length}
          >
            {contacts => <GroupAttendeesFacePile contacts={contacts} />}
          </DataFetcher>
          {!booking.ui.currentAppointment?.id && !isGroupAppointment && (
            <SendFormCheckbox
              communication={
                practice.contactsMap.get(values.patientId)?.communications
              }
              fieldName={appointmentFormNameOf("sendDemographicForm")}
              heading="Request personal information Update"
              checkBoxLabel="Send demographic information form to patient"
            />
          )}
          <AppointmentTypeField />
          <GroupAppointmentFields />
          <AppointmentDetails
            disabled={!!values.orgUnitId && isAppointmentValidForEdit}
            isGroupAppointment={isGroupAppointment}
            onClick={onApptDetailsClick}
          />
          {/* Added Stack here to avoid margins between toggles from the parent Stack */}
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack.Item grow styles={{ root: { width: "100%" } }}>
              <ChoiceButtons />
            </Stack.Item>
            {values.repeat &&
              !!booking.ui.currentAppointment?.id &&
              booking.ui.isEditSingleEvent && (
                <MessageBar
                  styles={{
                    content: {
                      fontSize: FontSizes.size12
                    }
                  }}
                >
                  To edit repeats you need to edit the series
                </MessageBar>
              )}

            <WaitingListFields />

            {values.repeat && <RepeatDetailsButton />}
          </Stack>
          <TextInputField
            label="Comment"
            name={appointmentFormNameOf("comments")}
            multiline={true}
            rows={1}
            autoAdjustHeight
            styles={{ fieldGroup: { minHeight: 60 } }}
            disabled={isAppointmentValidForEdit}
          />

          <OutsideWorkingHoursMessageBar />
          <Booked
            bookedByUser={calendarEvent?.bookedByUser}
            bookedDate={calendarEvent?.createdDate}
          />
          <CreateTemporaryReservation />
        </Stack>
        <WaitingListDetailsDialog />
        <AppointmentFormSubmitButtons onCancel={onCancel} />
      </>
    );
  });
