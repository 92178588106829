import { parse } from "query-string";

import { DateTime } from "@bps/utils";
import {
  AccInvoicesFilter,
  AccInvoicesFilterQuery
} from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsListFilter.types.ts";

export const getAccInvoiceFilterQuery = (
  searchString: string
): AccInvoicesFilter => {
  const {
    actionRequired,
    serviceStartTime,
    serviceEndTime,
    transactionStatuses,
    isOwing,
    hasStatusReason
  }: AccInvoicesFilterQuery = parse(searchString);

  return {
    transactionStatuses:
      typeof transactionStatuses === "string"
        ? [transactionStatuses]
        : transactionStatuses || [],
    serviceStartTime: DateTime.jsDateFromISO(serviceStartTime),
    serviceEndTime: DateTime.jsDateFromISO(serviceEndTime),
    actionRequired: actionRequired || undefined,
    isOwing: isOwing || undefined,
    hasStatusReason: hasStatusReason || undefined
  };
};
