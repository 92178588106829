import { FunctionComponent, memo } from "react";
import { useField, useForm } from "react-final-form";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ErrorBlock } from "@ui-components/ErrorBlock.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { claimFormNameOf } from "../claim.utils.ts";

export const ClaimLicenceWarning: FunctionComponent = memo(() => {
  const { core } = useStores();

  const { change } = useForm<ClaimFormValues>();

  const {
    input: { value: providerId }
  } = useField<string | undefined>(claimFormNameOf("providerId"), {
    subscription: { value: true }
  });

  const fetchUser = async () => {
    let claimLodgementAllowed = false;

    if (!!providerId) {
      const user = await core.getUser(providerId);

      claimLodgementAllowed = await core.checkUserPermissionsFromAPI(
        user.username,
        [Permission.ClaimLodgementAllowed]
      );

      change("claimLodgementAllowed", claimLodgementAllowed);
    } else {
      change("claimLodgementAllowed", undefined);
    }

    return {
      claimLodgementAllowed
    };
  };

  return (
    <DataFetcher<{
      claimLodgementAllowed: boolean;
    }>
      refetchId={providerId}
      fetch={fetchUser}
    >
      {({ claimLodgementAllowed }) => (
        <When permission={Permission.LicencingAllowed}>
          {providerId && !claimLodgementAllowed && (
            <ErrorBlock
              errorText="Only licensed users can be added as the provider."
              linkText="Assign a licence"
              to={routes.settings.users.user.path({
                id: providerId
              })}
            />
          )}
        </When>
      )}
    </DataFetcher>
  );
});
