import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ContactStatus } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IndividualAndOrganisationNavigate } from "@modules/practice/screens/address-book/components/contacts-lists/Individual-organisation-columns/IndividualAndOrganisationNavigate.tsx";
import { useShowContactDetails } from "@modules/practice/screens/address-book/components/hooks/useShowContactDetails.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export const IndividualAndOrgNameColumn: FC<{
  contact: Contact;
}> = observer(({ contact }) => {
  const showContactDetails = useShowContactDetails(contact);
  return (
    <Stack horizontal>
      <IndividualAndOrganisationNavigate
        contact={contact}
        onClick={showContactDetails}
        data-contact-id={contact.id}
        data-contact-type={contact.type}
        styles={{ root: { paddingRight: 8 } }}
      >
        {contact.reversedName}
      </IndividualAndOrganisationNavigate>
      {contact.status === ContactStatus.Inactive && (
        <TextBadge
          styles={{ root: { fontStyle: "normal" } }}
          badgeColor={TextBadgeColor.grey}
          badgeSize={TextBadgeSize.small}
        >
          Inactive
        </TextBadge>
      )}
    </Stack>
  );
});
