import { observer } from "mobx-react-lite";

import { ExportPatientRecordModal } from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecordModal.tsx";
import { ExportPatientRecordViewerDialog } from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecordViewerDialog.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

import { ExportPatientRecordContext } from "./context/ExportPatientRecordContext.ts";
import { ExportPatientRecordHelper } from "./context/ExportPatientRecordHelper.ts";

export const ExportPatientRecord = observer(() => {
  const stores = useStores();
  const { practice } = stores;
  const { showExportPatientRecordViewerDialog, showExportPatientRecordModal } =
    practice.ui;
  if (!showExportPatientRecordModal && !showExportPatientRecordViewerDialog) {
    return null;
  }

  const helper = new ExportPatientRecordHelper(stores);

  return (
    <ExportPatientRecordContext.Provider value={helper}>
      {showExportPatientRecordModal && <ExportPatientRecordModal />}
      {showExportPatientRecordViewerDialog && (
        <ExportPatientRecordViewerDialog />
      )}
    </ExportPatientRecordContext.Provider>
  );
});
