import { observer } from "mobx-react-lite";
import { Stack, TooltipHost } from "office-ui-fabric-react";
import { FunctionComponent } from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  FontSizes,
  IColumn,
  Link,
  SelectionMode,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { SubmitActionCode } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";

import { useSyncFusionAutofillContext } from "./SyncFusionAutofillContext.ts";

export const SyncFusionAutofillList: FunctionComponent = observer(() => {
  const {
    handlePreviewClick,
    autofills,
    autofillContext,
    handleAutofillShortcutOnClick
  } = useSyncFusionAutofillContext();

  const PUBLISHED_TO_EVERYONE: string = "Shared";

  const sortedAutoFills = Array.from(autofills).sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const columns: IColumn[] = [
    {
      fieldName: "Shortcut",
      key: "shortcut",
      name: "Shortcut",
      minWidth: 60,
      maxWidth: 60,
      onRender: (autofill: AutofillDto) => (
        <Link
          styles={{
            root: {
              fontSize: FontSizes.size14
            }
          }}
          onClick={() =>
            handleAutofillShortcutOnClick(autofill, autofillContext)
          }
        >
          {autofill.shortcut}
        </Link>
      ),
      isResizable: false
    },
    {
      fieldName: "Name",
      key: "Name",
      minWidth: 150,
      name: "Name",
      onRender: (autofill: AutofillDto) => {
        return (
          <TooltipHost content={autofill.name}>
            <Text
              nowrap={true}
              block={true}
              styles={{
                root: {
                  maxWidth: 150
                }
              }}
            >
              {autofill.name}
            </Text>
          </TooltipHost>
        );
      }
    },
    {
      fieldName: "DocumentStatus",
      key: "DocumentStatus",
      minWidth: 50,
      name: "",
      onRender: (autofill: AutofillDto) => {
        return (
          autofill.documentStatus ===
            SubmitActionCode.PublishToEveryoneCode && (
            <Stack>
              <TextBadge
                badgeColor={TextBadgeColor.lightGrey}
                badgeSize={TextBadgeSize.medium}
                noTextWrap
                styles={{
                  root: {
                    fontSize: FontSizes.size12,
                    width: 50
                  }
                }}
              >
                <Stack horizontal horizontalAlign="center">
                  <Text styles={{ root: { fontSize: FontSizes.size10 } }}>
                    {PUBLISHED_TO_EVERYONE}
                  </Text>
                </Stack>
              </TextBadge>
            </Stack>
          )
        );
      }
    },
    {
      fieldName: "Preview",
      key: "Preview",
      minWidth: 52,
      maxWidth: 52,
      name: "",
      onRender: (autofill: AutofillDto) => {
        return (
          <Link
            styles={{
              root: {
                fontSize: FontSizes.size14
              }
            }}
            onClick={() => handlePreviewClick(autofill)}
          >
            Preview
          </Link>
        );
      }
    }
  ];

  return (
    <DetailsList
      layoutMode={DetailsListLayoutMode.fixedColumns}
      items={sortedAutoFills}
      columns={columns}
      selectionMode={SelectionMode.none}
      styles={{
        root: {
          overflow: "auto",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 240px)"
        }
      }}
    />
  );
});
