import { fabric } from "fabric";
import { FunctionComponent, memo, useCallback, useEffect, useRef } from "react";

import { BrushKindEnum, Options } from "../DrawingCanvas.types.ts";
import { EllipseBrush } from "./EllipseBrush.ts";
import { FabricProps } from "./FabricDrawing.types.ts";
import { LineBrush } from "./LineBrush.ts";
import { convertToDataUrl, setImageCanvasOption } from "./utils.ts";

/**
 * Wraps fabricjs in a React component
 * The idea is to make it more React friendly (read less imperative)
 * See docs at http://fabricjs.com
 */
export const FabricDrawing: FunctionComponent<FabricProps> = memo(props => {
  const {
    backgroundImageUrl,
    onChange,
    isDrawingMode,
    canvasRef,
    onSelectionChange,
    activeObject,
    tabIndex,
    initialValue,
    embedImages,
    backgroundDisplayOption,
    handleCanvasFocused,
    options,
    width,
    height,
    ...otherProps
  } = props;

  const prevProps = useRef<FabricProps>(props);
  const canvasElement = useRef<HTMLCanvasElement | null>(null);
  const canvas = useRef<fabric.Canvas | undefined>(undefined);

  const createBrush = (
    canvas: fabric.Canvas,
    options: Options
  ): fabric.BaseBrush & fabric.IObjectOptions => {
    // Work-around the absence of correct constructor for PencilBrush in @types/fabric
    let brush: fabric.BaseBrush & fabric.IObjectOptions;
    switch (options.kind) {
      case BrushKindEnum.Line: {
        brush = new LineBrush(canvas);
        break;
      }
      case BrushKindEnum.Ellipse: {
        brush = new EllipseBrush(canvas);
        if (options && options.fill) {
          brush.fill = options.fill;
        }
        break;
      }
      default: {
        // BrushKindEnum.Pencil
        brush = new (fabric.PencilBrush as any)(canvas) as fabric.PencilBrush;
        break;
      }
    }

    if (options && options.color) {
      brush.color = options.color;
      if (options.kind === BrushKindEnum.Transparent) {
        brush.color = options.color + 66;
      }
    }
    if (options && options.width) {
      brush.width = options.width;
    }

    return brush;
  };

  /**
   * Sets the background image in the fabric.canvas object.
   * Turns the callback based system into a promise
   * The promise resolves once the image is loaded and
   * set as background in the fabric.canvas object.
   *
   */
  const setBackgroundImageFromUrl = useCallback(
    async (newSrc: string): Promise<void> => {
      let src = newSrc;

      if (embedImages) {
        src = await convertToDataUrl(src);
      }

      await new Promise<void>(resolve => {
        fabric.Image.fromURL(src, image => {
          if (!canvas.current) {
            resolve();
            return;
          }

          setImageCanvasOption(image, canvas.current, backgroundDisplayOption);

          canvas.current.setBackgroundImage(image, () => {
            if (canvas.current) {
              canvas.current.renderAll();
            }
            resolve();
          });
        });
      });
    },
    [backgroundDisplayOption, embedImages]
  );

  useEffect(() => {
    if (canvas.current) {
      canvas.current.on({
        "object:modified": handleChange,
        "object:moved": handleChange,
        "object:scaled": handleChange,
        "object:rotated": handleChange,
        "object:skewed": handleChange,
        "object:added": handleChange,
        "object:removed": handleChange,
        "selection:created": handleSelectionChange,
        "selection:updated": handleSelectionChange,
        "selection:cleared": handleSelectionChange,
        "mouse:down": handleMouseDown
      });

      if (initialValue) {
        fabric.loadSVGFromString(initialValue, results => {
          const backgroundImage =
            results.length && results[0] instanceof fabric.Image
              ? (results[0] as fabric.Image)
              : undefined;

          if (canvas.current) {
            if (backgroundImage) {
              setBackgroundImage(backgroundImage);
              canvas.current.add(...results.slice(1));
            } else {
              canvas.current.add(...results);
            }
          }
        });
      } else {
        backgroundImageUrl && setBackgroundImageFromUrl(backgroundImageUrl);
      }

      canvas.current["freeDrawingBrush"] = createBrush(canvas.current, options);
      canvas.current.isDrawingMode = isDrawingMode;
      canvasRef && canvasRef(canvas.current);
      width && canvas.current.setWidth(width);
      height && canvas.current.setHeight(height);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canvas.current) {
      const { isDrawingMode, activeObject } = props;

      if (
        prevProps.current.options.color !== options.color ||
        prevProps.current.options.width !== options.width ||
        prevProps.current.options.kind !== options.kind ||
        prevProps.current.options.fill !== options.fill
      ) {
        canvas.current.freeDrawingBrush = createBrush(canvas.current, options);
      }

      if (isDrawingMode !== prevProps.current.isDrawingMode) {
        canvas.current.isDrawingMode = isDrawingMode;
      }

      if (
        activeObject !== prevProps.current.activeObject &&
        canvas.current.getActiveObject() !== activeObject
      ) {
        if (!activeObject) {
          canvas.current.discardActiveObject();
        } else {
          canvas.current.setActiveObject(activeObject);
        }
      }

      if (canvas.current.getActiveObject()) {
        setTimeout(() => {
          if (canvasElement.current) {
            canvasElement.current.focus();
          }
        }, 0);
      }
    }
    prevProps.current = props;
  }, [onChange, options, props]);

  const handleMouseDown = () => {
    setTimeout(() => {
      if (
        canvasElement.current &&
        document.activeElement !== canvasElement.current &&
        canvas.current
      ) {
        props.handleCanvasFocused(canvas.current);
      }
    }, 100);
  };

  /**
   * Sets the background image in the fabric.canvas object.
   * Turns the callback based system into a promise
   * The promise resolves once the image is loaded and
   * set as background in the fabric.canvas object.
   */
  const setBackgroundImage = (image: fabric.Image) => {
    if (!canvas.current) {
      return;
    }
    setImageCanvasOption(image, canvas.current, props.backgroundDisplayOption);

    canvas.current.setBackgroundImage(
      image,
      canvas.current.renderAll.bind(canvas.current)
    );
  };

  const handleChange = (event: fabric.IEvent) => {
    if (!canvas.current) {
      return;
    }
    if (props.onChange) {
      if (event.target) {
        event.target.dirty = true;
      }
      props.onChange(canvas.current, event);
    }
  };

  const handleSelectionChange = (event: fabric.IEvent) => {
    if (!canvas.current) {
      return;
    }

    if (props.onSelectionChange) {
      props.onSelectionChange(canvas.current.getActiveObjects(), event);
    }
  };

  return (
    <canvas
      tabIndex={tabIndex ?? 0}
      {...otherProps}
      ref={el => {
        if (el && !canvasElement.current && !canvas.current) {
          canvasElement.current = el;
          canvas.current = new fabric.Canvas(el);
        }
      }}
    />
  );
});
