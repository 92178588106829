import { Observer, observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontSizes,
  IColumn,
  IDetailsRowStyles,
  mergeStyleSets,
  NoDataTile,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, TIME_FORMATS } from "@bps/utils";
import {
  CalendarEventStatus,
  CalendarEventStatusText
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentInformationModalContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModalContext.ts";
import { renderCalendarEventWrapper } from "@modules/booking/screens/booking-calendar/components/utils.tsx";
import { AppointmentCancellationReason } from "@stores/booking/models/AppointmentCancellationReason.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";
import { showUnlessPrintingStyles } from "@ui-components/printing/Print.styles.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { getAppointmentStatusFromCode } from "../utils.ts";
import { listWrapperStyles } from "./AppointmentInformationModal.styles.ts";
import { AppointmentInformationModalButtons } from "./AppointmentInformationModalButtons.tsx";
import { filterData } from "./utils.ts";

interface AppointmentInformationTableProps {
  loading?: boolean;
  items: CalendarEvent[];
  onDismiss: () => void;
  selectedId?: string;
}

export type ApptinfoFilterType = {
  apptType?: string;
  apptDate?: Date;
  duration?: string;
  providerId?: string;
  status?: string[];
};

export const AppointmentInformationTable: React.FunctionComponent<AppointmentInformationTableProps> =
  observer(({ items, onDismiss, selectedId, loading }) => {
    const { core, booking } = useStores();
    const theme = useTheme();
    const [apptItems, setApptItems] = useState<CalendarEvent[]>([]);
    const [selectionCount, setSelectionCount] = useState<number>();
    const helper = useContext(AppointmentInformationModalContext);

    useEffect(() => {
      const appointmentItems = items.filter((item: CalendarEvent) =>
        filterData(item, {}, booking)
      );
      setApptItems(appointmentItems);
    }, [items, booking]);

    const selection = useRef(
      new Selection({
        onSelectionChanged: () => {
          const selectedCount = selection.current.getSelection().length;
          const selectedItems = selection.current.getSelection();

          if (selectedCount) {
            const selectedItem = selectedItems[0];
            if (selectedItem.key) {
              helper.setSelectedCalendarEventId(selectedItem.key.toString());
            }
          }
          if (selectionCount !== selectedCount) {
            setSelectionCount(selectedCount);
          }
        }
      })
    );

    useEffect(() => {
      const apptItemsWithKey = apptItems.map((i: CalendarEvent) => ({
        key: i.id
      }));
      if (selectedId !== "") {
        selection.current.setItems(apptItemsWithKey, false);
        const index = apptItemsWithKey.findIndex(
          item => item.key === selectedId
        );
        selection.current.setIndexSelected(index, true, true);
      }
    }, [selectedId, apptItems]);

    const renderDate = (record: CalendarEvent) => (
      <Observer>
        {() => {
          const date = record.startDateTime.toFormat(
            DATE_FORMATS.DAY_FORMAT_WITH_SHORT_DAY_OF_WEEK
          );

          const time = record.startDateTime
            .toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)
            .toLowerCase();

          return (
            <Text>
              {date} {time}
            </Text>
          );
        }}
      </Observer>
    );

    const renderApptType = (record: CalendarEvent) => (
      <Observer>
        {() => {
          const appointmentType = booking.appointmentTypesMap.get(
            record.appointmentTypeId!
          );
          return <Text>{appointmentType?.dto.text || "Not recorded"}</Text>;
        }}
      </Observer>
    );

    const renderDuration = (record: CalendarEvent) => (
      <Observer>
        {() => {
          return (
            <Text
              styles={{
                root: { textAlign: "right", display: "block" }
              }}
            >{`${record.duration.minutes} mins`}</Text>
          );
        }}
      </Observer>
    );

    const renderStatus = (record: CalendarEvent) => (
      <Observer>
        {() => {
          return (
            <DataFetcher<AppointmentCancellationReason | undefined>
              fetch={async () => {
                const cancelledReasonId = record.cancellationReasonId;
                if (cancelledReasonId) {
                  return await booking.getCalendarCancellationReason(
                    cancelledReasonId
                  );
                }
                return undefined;
              }}
              fallback={<Spinner />}
              refetchId={record.cancellationReasonId}
            >
              {cancelledReason => {
                if (record.appointmentStatus && record.status) {
                  const cancelReasonText =
                    cancelledReason?.text === "Other"
                      ? `${cancelledReason?.text}: ${record.cancellationText}`
                      : cancelledReason?.text;
                  return (
                    <TooltipHost
                      content={cancelReasonText ? cancelReasonText : ""}
                    >
                      <Text>
                        {getAppointmentStatusFromCode(
                          record.appointmentStatus,
                          record.status
                        )}
                      </Text>
                    </TooltipHost>
                  );
                } else return null;
              }}
            </DataFetcher>
          );
        }}
      </Observer>
    );

    const renderProvider = (record: CalendarEvent) => (
      <Observer>
        {() => {
          const user = core.userMap.get(record.userId);
          return <Text>{user?.fullName}</Text>;
        }}
      </Observer>
    );

    const columns: IColumn[] = [
      {
        key: "date",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 180,
        name: "Date",
        onRender: renderDate,
        isMultiline: true
      },
      {
        key: "status",
        minWidth: 30,
        maxWidth: 80,
        name: "Status",
        isMultiline: true,
        onRender: renderStatus,
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "left"
          }
        })
      },
      {
        key: "type",
        minWidth: 60,
        maxWidth: 130,
        name: "Appointment type",
        isMultiline: true,
        onRender: renderApptType
      },
      {
        key: "duration",
        minWidth: 30,
        maxWidth: 90,
        name: "Duration",
        isMultiline: true,
        onRender: renderDuration,
        styles: mergeStyleSets({
          cellTitle: {
            justifyContent: "flex-end"
          }
        })
      },
      {
        key: "provider",
        minWidth: 100,
        maxWidth: 130,
        name: "Provider",
        isMultiline: true,
        onRender: renderProvider
      }
    ];

    if (core.hasMultipleActiveLocations) {
      columns.push({
        key: "location",
        minWidth: 100,
        maxWidth: 130,
        name: "Location",
        isMultiline: true,
        onRender: (record: CalendarEvent) => (
          <Observer>
            {() => <>{core.getLocationName(record.orgUnitId)}</>}
          </Observer>
        )
      });
    }

    const latestPastRecord = apptItems.find(
      x => x.startDateTime.startOf("day") < DateTime.now().startOf("day")
    );

    const appointmentStatusOptions =
      booking.ref.appointmentStatuses.keyTextValues;

    const statusOptions = appointmentStatusOptions.concat([
      {
        key: CalendarEventStatus.Cancelled,
        text: CalendarEventStatusText.CANCELLED
      }
    ]);

    const durationOptions = [
      { key: "0", text: "0 - 15 mins" },
      { key: "15", text: "15 - 30 mins" },
      { key: "30", text: "30 - 45 mins" },
      { key: "45", text: "45 - 60 mins" },
      { key: "60", text: "60+ mins" }
    ];

    const renderFilterItems: FilterBarProps["items"] = [
      {
        name: "apptType",
        type: "searchBox",
        stickItem: true,
        props: {
          autoFocus: true,
          placeholder: "Search appointments",
          styles: { root: { width: "30%" } }
        }
      },
      {
        type: "datePicker",
        name: "apptDate",
        props: {
          allowClear: true,
          placeholder: "Date"
        }
      },
      {
        type: "optionsSelect",
        name: "status",
        props: {
          placeholder: "Status",
          label: "Status",
          options: statusOptions,
          hideSearchOption: true,
          calloutWidth: 130,
          multiSelect: true
        }
      },
      {
        type: "optionsSelect",
        name: "duration",
        props: {
          placeholder: "Duration",
          label: "Duration",
          options: durationOptions,
          hideSearchOption: true,
          calloutWidth: 100
        }
      },
      {
        type: "bookableUsersSelect",
        name: "providerId",
        props: {
          multiSelect: false,
          usersFilter: { showOnCalendar: true },
          placeholder: "Providers",
          calloutWidth: 250
        }
      }
    ];

    const noAppointments = items.length === 0;

    return (
      <>
        {noAppointments && !loading ? (
          <Stack
            grow
            verticalFill
            tokens={{ childrenGap: 20 }}
            horizontalAlign="center"
            verticalAlign="center"
            styles={(props, theme) => ({
              root: {
                background: theme.palette.neutralLighterAlt,
                padding: 8,
                height: "300px",
                fontSize: FontSizes.size14,
                fontStyle: "italic"
              }
            })}
          >
            <Text
              styles={(props, theme) => ({
                root: {
                  color: theme.palette.neutralSecondary
                }
              })}
            >
              There are no appointments
            </Text>
          </Stack>
        ) : (
          <FilterBar<ApptinfoFilterType>
            items={renderFilterItems}
            styles={showUnlessPrintingStyles}
            onChange={({ values }) => {
              setApptItems(
                items.filter((item: CalendarEvent) =>
                  filterData(item, values, booking)
                )
              );
            }}
          >
            {(_v, form) => (
              <Stack
                styles={listWrapperStyles}
                {...dataAttribute(DataAttributes.Element, "list-wrapper")}
                grow
              >
                {apptItems.length < 1 && !loading ? (
                  <Stack
                    grow
                    verticalFill
                    horizontalAlign="center"
                    verticalAlign="center"
                    styles={{
                      root: {
                        padding: 8,
                        height: "300px",
                        fontSize: FontSizes.size14,
                        fontStyle: "italic"
                      }
                    }}
                  >
                    <NoDataTile
                      textProps={{ text: "0 matches found" }}
                      linkProps={{
                        text: "Clear filters",
                        onClick: () => form.reset()
                      }}
                      showBoxShadow={false}
                    />
                  </Stack>
                ) : (
                  <ShimmeredDetailsList
                    setKey="appt-table-key"
                    enableShimmer={loading}
                    onRenderRow={props => {
                      const record = props?.item as CalendarEvent;

                      const showLineSeparator =
                        !!record && record === latestPastRecord;

                      const detailRowStyles:
                        | Partial<IDetailsRowStyles>
                        | undefined = showLineSeparator
                        ? {
                            root: {
                              borderTop: `1px solid ${theme.palette.redDark}`
                            }
                          }
                        : undefined;

                      return renderCalendarEventWrapper(
                        props,
                        undefined,
                        detailRowStyles
                      );
                    }}
                    items={apptItems}
                    selection={selection.current}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    columns={columns}
                  />
                )}
              </Stack>
            )}
          </FilterBar>
        )}
        <AppointmentInformationModalButtons
          onDismiss={onDismiss}
          selectionCount={selectionCount}
          apptItems={apptItems}
          noAppointments={noAppointments}
        />
      </>
    );
  });
