import {
  customFormatDuration,
  DATE_FORMATS,
  DateTime,
  getDurationFromMinutes
} from "@bps/utils";
import { boolToYesNo } from "@libs/utils/utils.ts";
import { WaitingListItemModel } from "@stores/booking/models/WaitingListModel.ts";

export const renderAnyProvider = (record: WaitingListItemModel) => {
  return boolToYesNo(record.anyProvider);
};

export const renderPriority = (record: WaitingListItemModel) => {
  return record.priority;
};

export const renderDuration = (record: WaitingListItemModel) => {
  const duration =
    record.endTime && record.startTime
      ? DateTime.fromISO(record.endTime).diff(
          DateTime.fromISO(record.startTime!)
        )
      : getDurationFromMinutes(record.duration);
  return customFormatDuration(duration);
};

export const renderListExpiry = (record: WaitingListItemModel) => {
  return DateTime.fromISO(record.until).toDayDefaultFormat();
};

export const getInterpreterLanguage = (record: WaitingListItemModel) => {
  return record.contact?.model?.interpreterLanguageValue;
};

export const renderPatientPrimaryContact = (record: WaitingListItemModel) => {
  return record.contact?.model?.primaryCommunication?.value;
};

export const renderAppointmentType = (record: WaitingListItemModel) => {
  return record.appointmentType?.name;
};

export const renderProvider = (record: WaitingListItemModel) => {
  return record.user?.model?.fullName;
};

export const renderAppointmentDateTime = (record: WaitingListItemModel) => {
  if (record.startTime) {
    const today = DateTime.now().toString();
    const appointmentDateTime = DateTime.fromISO(
      record.startTime
    ).toDayDefaultFormat();
    if (appointmentDateTime === today) return "Today";
    else
      return DateTime.fromISO(record.startTime).toFormat(
        DATE_FORMATS.LONG_DATE_TIME_FORMAT
      );
  }
  return null;
};

export const renderComment = (record: WaitingListItemModel) => record.content;
