import { GetClaimsDto } from "@libs/gateways/acc/AccGateway.dtos.ts";

export const defaultClaimsFilter: ClaimsFilterState = {
  claimNumber: undefined,
  providers: [],
  insurers: [],
  patients: undefined,
  claimStatusCode: [],
  dateFrom: undefined,
  dateTo: undefined,
  claimsActionRequired: undefined
};

export interface ClaimsFilterQuery extends Omit<GetClaimsDto, "discharged"> {}

export interface ClaimsFilterState
  extends Omit<ClaimsFilterQuery, "dateFrom" | "dateTo"> {
  dateFrom?: Date;
  dateTo?: Date;
  claimsActionRequired?: boolean;
}
