import { BodyDiagramCard } from "../../SOTAP/BodyDiagramCard.tsx";
import { SOTAPFormWrapper } from "../../SOTAP/SOTAPFormWrapper.tsx";
import { CurrentInjuryCard } from "./CurrentInjuryCard.tsx";

export const PresentingComplaintContent: React.FunctionComponent = () => {
  return (
    <SOTAPFormWrapper>
      <BodyDiagramCard />
      <CurrentInjuryCard />
    </SOTAPFormWrapper>
  );
};
