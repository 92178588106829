import { observer } from "mobx-react-lite";

import { Stack } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";

import { DiagnosisChangeFormValidator } from "../validators/DiagnosisChangeFormValidator.tsx";
import { ChangeDiagnosesField } from "./ChangeDiagnosesField.tsx";
import {
  ClaimAdjustmentSectionHeading,
  ClaimsAdjustmentCardIds
} from "./ClaimAdjustmentEnums.ts";

export const ChangeDiagnosisFormSection: React.FC = observer(() => {
  const { selectedMenuItemId } = useCardFormLayoutContext();
  const { claimAdjustment } = useClaimAdjustmentContext();

  if (!claimAdjustment?.hasDiagnosisChange) {
    return null;
  }
  return (
    <ClaimCard
      id={ClaimsAdjustmentCardIds.changeDiagnosis}
      openOnRender={
        selectedMenuItemId === ClaimsAdjustmentCardIds.changeDiagnosis
      }
      heading={ClaimAdjustmentSectionHeading.changeDiagnosis}
      iconName="Medical"
      statusValidator={new DiagnosisChangeFormValidator(claimAdjustment)}
      visible={!!claimAdjustment?.hasDiagnosisChange}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <ChangeDiagnosesField />
      </Stack>
    </ClaimCard>
  );
});
