export enum GeneralExamFieldConditionKeys {
  Hypovolemic = "HYO",
  Manual = "MAN",
  Cyanosis = "CYA"
}
export enum Positions {
  Standing = "STA",
  Sitting = "SIT",
  Lying = "LYI"
}
