import { PSFSReason } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { PSFSFormValues } from "../PSFSForm.types.ts";

const MAX_CHARACTERS_LENGTH = 100;
export class PSFSFormItemsValidator extends Validator<PSFSFormValues["items"]> {
  constructor(reason: string | undefined) {
    super();

    const optionTextRules = [required(), maxLength(MAX_CHARACTERS_LENGTH)];

    const isBaselineReason = reason === PSFSReason.Baseline;
    this.forField("q1a1", optionTextRules, {
      when: () => {
        let isRequired = false;

        if (isBaselineReason) {
          isRequired = true;
        }

        return isRequired;
      }
    });
    this.forField("q1a2", required());
    this.forField("q2a1", optionTextRules, {
      when: (_value, parent) => {
        let isRequired = false;

        if (isBaselineReason) {
          const q2a2Field: number | undefined =
            parent && parent["q2a2"] ? Number(parent["q2a2"]) : undefined;

          const q3a1Field = parent ? parent["q3a1"] : undefined;
          const q3a2Field: number | undefined =
            parent && parent["q3a2"] ? Number(parent["q3a2"]) : undefined;

          const q4a1Field = parent ? parent["q4a1"] : undefined;
          const q4a2Field: number | undefined =
            parent && parent["q4a2"] ? Number(parent["q4a2"]) : undefined;

          const q5a1Field = parent ? parent["q5a1"] : undefined;
          const q5a2Field: number | undefined =
            parent && parent["q5a2"] ? Number(parent["q5a2"]) : undefined;

          if (q2a2Field && q2a2Field >= 0) {
            isRequired = true;
          } else if (q3a1Field && q3a1Field.length > 0) {
            isRequired = true;
          } else if (q3a2Field && q3a2Field >= 0) {
            isRequired = true;
          } else if (q4a1Field && q4a1Field.length > 0) {
            isRequired = true;
          } else if (q4a2Field && q4a2Field >= 0) {
            isRequired = true;
          } else if (q5a1Field && q5a1Field.length > 0) {
            isRequired = true;
          } else if (q5a2Field && q5a2Field >= 0) {
            isRequired = true;
          }
        }

        return isRequired;
      }
    });
    this.forField("q2a2", required(), {
      when: (_value, parent) => {
        let isRequired = false;
        const q2a1Field = parent ? parent["q2a1"] : undefined;
        const q3a1Field = parent ? parent["q3a1"] : undefined;
        const q3a2Field: number | undefined =
          parent && parent["q3a2"] ? Number(parent["q3a2"]) : undefined;

        const q4a1Field = parent ? parent["q4a1"] : undefined;
        const q4a2Field: number | undefined =
          parent && parent["q4a2"] ? Number(parent["q4a2"]) : undefined;

        const q5a1Field = parent ? parent["q5a1"] : undefined;
        const q5a2Field: number | undefined =
          parent && parent["q5a2"] ? Number(parent["q5a2"]) : undefined;

        if (q2a1Field && q2a1Field.length > 0) {
          isRequired = true;
        } else if (q3a1Field && q3a1Field.length > 0) {
          isRequired = true;
        } else if (q3a2Field && q3a2Field >= 0) {
          isRequired = true;
        } else if (q4a1Field && q4a1Field.length > 0) {
          isRequired = true;
        } else if (q4a2Field && q4a2Field >= 0) {
          isRequired = true;
        } else if (q5a1Field && q5a1Field.length > 0) {
          isRequired = true;
        } else if (q5a2Field && q5a2Field >= 0) {
          isRequired = true;
        }

        return isRequired;
      }
    });
    this.forField("q3a1", optionTextRules, {
      when: (_value, parent) => {
        let isRequired = false;

        if (isBaselineReason) {
          const q3a2Field: number | undefined =
            parent && parent["q3a2"] ? Number(parent["q3a2"]) : undefined;

          const q4a1Field = parent ? parent["q4a1"] : undefined;
          const q4a2Field: number | undefined =
            parent && parent["q4a2"] ? Number(parent["q4a2"]) : undefined;

          const q5a1Field = parent ? parent["q5a1"] : undefined;
          const q5a2Field: number | undefined =
            parent && parent["q5a2"] ? Number(parent["q5a2"]) : undefined;

          if (q3a2Field && q3a2Field >= 0) {
            isRequired = true;
          } else if (q4a1Field && q4a1Field.length > 0) {
            isRequired = true;
          } else if (q4a2Field && q4a2Field >= 0) {
            isRequired = true;
          } else if (q5a1Field && q5a1Field.length > 0) {
            isRequired = true;
          } else if (q5a2Field && q5a2Field >= 0) {
            isRequired = true;
          }
        }

        return isRequired;
      }
    });
    this.forField("q3a2", required(), {
      when: (_value, parent) => {
        let isRequired = false;
        const q3a1Field = parent ? parent["q3a1"] : undefined;
        const q4a1Field = parent ? parent["q4a1"] : undefined;
        const q4a2Field: number | undefined =
          parent && parent["q4a2"] ? Number(parent["q4a2"]) : undefined;

        const q5a1Field = parent ? parent["q5a1"] : undefined;
        const q5a2Field: number | undefined =
          parent && parent["q5a2"] ? Number(parent["q5a2"]) : undefined;

        if (q3a1Field && q3a1Field.length > 0) {
          isRequired = true;
        } else if (q4a1Field && q4a1Field.length > 0) {
          isRequired = true;
        } else if (q4a2Field && q4a2Field >= 0) {
          isRequired = true;
        } else if (q5a1Field && q5a1Field.length > 0) {
          isRequired = true;
        } else if (q5a2Field && q5a2Field >= 0) {
          isRequired = true;
        }

        return isRequired;
      }
    });

    this.forField("q4a1", optionTextRules, {
      when: (_value, parent) => {
        let isRequired = false;

        if (isBaselineReason) {
          const q4a2Field: number | undefined =
            parent && parent["q4a2"] ? Number(parent["q4a2"]) : undefined;

          const q5a1Field = parent ? parent["q5a1"] : undefined;
          const q5a2Field: number | undefined =
            parent && parent["q5a2"] ? Number(parent["q5a2"]) : undefined;

          if (q4a2Field && q4a2Field >= 0) {
            isRequired = true;
          } else if (q5a1Field && q5a1Field.length > 0) {
            isRequired = true;
          } else if (q5a2Field && q5a2Field >= 0) {
            isRequired = true;
          }
        }

        return isRequired;
      }
    });

    this.forField("q4a2", required(), {
      when: (_value, parent) => {
        let isRequired = false;
        const q4a1Field = parent ? parent["q4a1"] : undefined;
        const q5a1Field = parent ? parent["q5a1"] : undefined;
        const q5a2Field: number | undefined =
          parent && parent["q5a2"] ? Number(parent["q5a2"]) : undefined;

        if (q4a1Field && q4a1Field.length > 0) {
          isRequired = true;
        } else if (q5a1Field && q5a1Field.length > 0) {
          isRequired = true;
        } else if (q5a2Field && q5a2Field >= 0) {
          isRequired = true;
        }

        return isRequired;
      }
    });

    this.forField("q5a1", optionTextRules, {
      when: (_value, parent) => {
        let isRequired = false;

        if (isBaselineReason) {
          const q5a2Field: number | undefined =
            parent && parent["q5a2"] ? Number(parent["q5a2"]) : undefined;

          if (q5a2Field && q5a2Field >= 0) {
            isRequired = true;
          }
        }

        return isRequired;
      }
    });

    this.forField("q5a2", required(), {
      when: (_value, parent) => {
        let isRequired = false;
        const q5a1Field = parent ? parent["q5a1"] : undefined;

        if (q5a1Field && q5a1Field.length > 0) {
          isRequired = true;
        }

        return isRequired;
      }
    });
  }
}
