import { useRef } from "react";

import { catchNotFoundError } from "@libs/utils/utils.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { CreateUserFormValues } from "./CreateUserForm.types.ts";
import { CreateUserFormValidator } from "./CreateUserValidator.tsx";

/**
 * This hook was created to address a couple of issues.  SubmissionForm calls validate whenever the form values change - which
 *  effectively means it is called every key stroke.  We don't want to have to call the API on every keystroke so we need some
 *  debouncing.  Also, if the user wasn't changing the email then we don't want to have to re-call the API.
 */
export const useCreateUserFormValidator = () => {
  const { core } = useStores();
  const userNameRef = useRef("");
  const userRef = useRef<Promise<User | undefined> | undefined>();

  return async (values: CreateUserFormValues) => {
    if (values.username && values.username !== userNameRef.current) {
      userNameRef.current = values.username;
      userRef.current = new Promise<User | undefined>((resolve, reject) => {
        setTimeout(async () => {
          if (values.username !== userNameRef.current) {
            // promise must still get resolved even if validate has been called another time
            return resolve(undefined);
          }

          try {
            const user = await core
              .getUserByUserName(values.username)
              .catch(catchNotFoundError);
            resolve(user);
          } catch (error) {
            reject(error);
          }
        }, 1000);
      });
    }

    const user = await userRef.current;
    const validator = new CreateUserFormValidator(user);
    return validator.validate(values);
  };
};
