import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontIcon,
  FontSizes,
  ILinkStyles,
  Link,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { OutboundCommStatuses } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { useEventReminderScreenContext } from "@modules/booking/screens/event-reminders/context/EventReminderScreenContext.tsx";

import { EventReminderFilters } from "../EventReminderScreen.types.ts";

export const EventRemindersTotalsBar: FunctionComponent = observer(() => {
  const {
    eventReminderStates,
    totalItems,
    setEventReminderState,
    remindersNotSent
  } = useEventReminderScreenContext();

  const theme = useTheme();
  const linkStyles: ILinkStyles = {
    root: {
      fontSize: FontSizes.size12
    }
  };

  const handleTotalApptsClick = () => {
    const item = eventReminderStates.find(
      x => x.state === EventReminderFilters.All
    );
    setEventReminderState(item!);
  };

  const handleNotSentClick = () => {
    const item = eventReminderStates.find(
      x => x.state === OutboundCommStatuses.NotSent
    );
    setEventReminderState(item!);
  };

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 4 }}
      verticalAlign="center"
      styles={{
        root: {
          marginLeft: 16
        }
      }}
    >
      <Link onClick={handleTotalApptsClick} styles={linkStyles}>
        {totalItems} total appt
      </Link>
      <FontIcon
        iconName="Separator"
        styles={{ root: { color: theme.semanticColors.link } }}
      />
      <Link onClick={handleNotSentClick} styles={linkStyles}>
        {remindersNotSent.length} reminders not sent
      </Link>
    </Stack>
  );
});
