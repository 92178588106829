import React from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  Heading,
  IColumn,
  IconButton,
  Stack
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import {
  AddXrayParametersFormValues,
  AddXrayParametersView,
  imagingReportViewParameters
} from "./AddXrayParameterDialog.types.ts";

interface XRayParametersTableProps {
  xrayParameters: AddXrayParametersFormValues;
  openXrayParameterDialog: () => void;
}
export const XRayParametersTable: React.FC<XRayParametersTableProps> = ({
  xrayParameters,
  openXrayParameterDialog
}) => {
  const { clinical } = useStores();
  const { region, side, views } = xrayParameters;

  const getHeaderText = () => {
    const regionString = clinical.ref.imagingRegions.get(region ?? "")?.text;
    const sideString = side && side[0] === "L" ? "Left" : "Right";
    return side && side[0] !== undefined
      ? `${regionString} (${sideString} side) X-Ray performed`
      : `${regionString} X-Ray performed`;
  };

  const onParameterRender = (
    item: AddXrayParametersView,
    options: {
      unit: string;
      fieldName: string;
    }
  ) => {
    const { unit, fieldName } = options;
    return <>{`${item[fieldName]} ${unit}`}</>;
  };

  const column: IColumn[] = [
    {
      key: "view",
      minWidth: 130,
      name: "View",
      onRender: (item: AddXrayParametersView) => {
        return <>{item.view}</>;
      }
    }
  ];

  imagingReportViewParameters.forEach(({ parameter, unit, fieldName }) => {
    column.push({
      key: fieldName,
      minWidth: 50,
      name: parameter,
      onRender: (item: AddXrayParametersView) =>
        onParameterRender(item, { unit, fieldName })
    });
  });

  return (
    <Fieldset
      frame
      styles={{
        root: {
          flexGrow: 1,
          paddingLeft: 10,
          paddingRight: 10
        }
      }}
    >
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <Heading>{getHeaderText()}</Heading>
        <IconButton
          iconProps={{ iconName: "Edit" }}
          onClick={openXrayParameterDialog}
        />
      </Stack>
      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        onRenderDetailsHeader={(props, defaultRender) => {
          return props && defaultRender
            ? defaultRender({
                ...props,
                styles: {
                  root: {
                    height: 30,
                    ".ms-DetailsHeader-cellName": {
                      fontSize: 11,
                      fontWeight: 400
                    }
                  }
                }
              })
            : null;
        }}
        onRenderRow={(props, defaultRender) => {
          return props && defaultRender
            ? defaultRender({
                ...props,
                styles: {
                  root: {
                    "ms-DetailsRow-cell": {
                      fontSize: 14,
                      fontWeight: 400,
                      minHeight: 20
                    }
                  }
                }
              })
            : null;
        }}
        bordersVariant="borderless"
        columns={column}
        items={views ?? []}
      />
    </Fieldset>
  );
};
