import {
  isDateBeforeField,
  isNotFutureDate
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  pastVisitsFilterNameOf,
  PastVisitsFilterStateInternal
} from "./PastVisitsFilter.types.ts";

export class PastVisitsFilterValidator extends Validator<
  RecursivePartial<PastVisitsFilterStateInternal>
> {
  constructor() {
    super();
    this.forField("dateFrom", isNotFutureDate());
    this.forField(
      "dateTo",
      isDateBeforeField(
        ValidationMessages.endDateBeforeStartDate,
        pastVisitsFilterNameOf("dateFrom"),
        true
      )
    );
  }
}
