import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Dialog, Stack } from "@bps/fluent-ui";
import { AllocationViewContext } from "@modules/billing/screens/allocation/context/AllocationViewContext.tsx";
import { ModalViewHeader } from "@modules/billing/screens/shared-components/ModalViewHeader.tsx";
import { TransactionBadgeNew } from "@modules/billing/screens/shared-components/TransactionBadgeNew.tsx";

import { AllocationView } from "./AllocationView.tsx";
import { AllocationViewButtons } from "./AllocationViewButtons.tsx";
import { AllocationViewDataFetcher } from "./AllocationViewDataFetcher.tsx";

interface AllocationViewModalProps {
  allocationId: string | undefined;
  onDismiss: () => void;
  onSubmit: () => void;
}

const AllocationViewModalBase: React.FC<AllocationViewModalProps> = observer(
  props => {
    const { onDismiss } = props;
    const { allocation } = useContext(AllocationViewContext);

    return (
      <Dialog
        hidden={false}
        onDismiss={onDismiss}
        dialogContentProps={{
          title: (
            <ModalViewHeader
              badge={<TransactionBadgeNew transaction={allocation} />}
              heading={`Payment ${allocation.number}`}
              amount={allocation.total}
              date={allocation.transactionDate}
            />
          ),
          showCloseButton: true,
          styles: { inner: { padding: 0 } }
        }}
        maxWidth="auto"
        minWidth="auto"
      >
        <Stack
          styles={{
            root: {
              maxHeight: "calc(90vh - 75px)"
            }
          }}
        >
          <Stack
            styles={{
              root: {
                flexShrink: 1,
                overflow: "auto",
                padding: "0 24px 16px 24px"
              }
            }}
          >
            <AllocationView />
          </Stack>
          <Stack styles={{ root: { padding: 24 } }}>
            <AllocationViewButtons onClose={onDismiss} />
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);

export const AllocationViewModal: React.FC<AllocationViewModalProps> = observer(
  props => {
    if (!props.allocationId) {
      return null;
    }

    return (
      <AllocationViewDataFetcher allocationId={props.allocationId}>
        <AllocationViewModalBase {...props} />
      </AllocationViewDataFetcher>
    );
  }
);
