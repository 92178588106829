import React from "react";
import { useField } from "react-final-form";

import { FontWeights, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import {
  MEDIUM_BREAKPOINT,
  SMALL_BREAKPOINT
} from "../../full-body-clinical-data/examination/utils.ts";
import { nameOfGeneralExaminationForm } from "./GeneralExaminationFields.tsx";
import { degreeCSymbol } from "./GeneralExaminationFormValues.ts";
import { GeneralHighLowSevereWarningMessageBar } from "./GeneralHighLowSevereWarningMessageBar.tsx";
import { getButtonGroupBreakLineStyles, getSpinButtonStyles } from "./utils.ts";

interface GeneralExamTemperatureFieldProps {
  width: number;
}

export const GeneralExamTemperatureField: React.FC<
  GeneralExamTemperatureFieldProps
> = ({ width }) => {
  const wrapValues = width <= MEDIUM_BREAKPOINT;
  const breakLines = width <= SMALL_BREAKPOINT;
  const { clinical } = useStores();
  const spinButtonStyles = getSpinButtonStyles();
  const {
    input: { value: temperature }
  } = useField<number>(nameOfGeneralExaminationForm("temperature"), {
    subscription: { value: true }
  });

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Fieldset
        horizontal={!wrapValues}
        tokens={{ childrenGap: 8 }}
        legend="Temperature"
        legendLevel="section-heading-light"
      >
        <SpinNumberInputField
          min={0.1}
          max={99.9}
          step={0.1}
          precision={1}
          name={nameOfGeneralExaminationForm("temperature")}
          styles={spinButtonStyles}
          suffix={degreeCSymbol}
          fieldItemStyles={{
            suffix: { fontWeight: FontWeights.semibold }
          }}
          horizontalLabel
        />
        <ButtonsGroupSingleChoiceField
          options={clinical.ref.temperatureMethods.keyTextValues}
          name={nameOfGeneralExaminationForm("temperatureMethod")}
          vertical={breakLines}
          styles={getButtonGroupBreakLineStyles(breakLines)}
        />
      </Fieldset>
      {temperature && (
        <GeneralHighLowSevereWarningMessageBar
          min={35.5}
          max={38.5}
          minMessage="Low temperature"
          maxMessage="High temperature"
          value={temperature}
        />
      )}
    </Stack>
  );
};
