import { observer } from "mobx-react-lite";

import { Heading, MessageBar, MessageBarType } from "@bps/fluent-ui";

import { useTemplateFormContext } from "./template-form/context/TemplateFormContext.tsx";

export const TemplateEditorCharacterCount: React.FC = observer(() => {
  const { smsCount, formattedTemplate, isTemplateLengthValid } =
    useTemplateFormContext();

  return (
    <>
      {isTemplateLengthValid && (
        <Heading styles={{ root: { width: "100%" } }}>
          ~{formattedTemplate.length} total characters | ~{smsCount} SMS message
          per contact
        </Heading>
      )}
      {!isTemplateLengthValid && (
        <MessageBar
          messageBarIconProps={{ iconName: "Error" }}
          messageBarType={MessageBarType.error}
          styles={{ root: { padding: 4, width: "20%" } }}
        >
          <span>Too many characters.</span>
        </MessageBar>
      )}
    </>
  );
});
