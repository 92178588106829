import {
  dataAttribute,
  DataAttributes,
  Heading,
  Stack,
  Text
} from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { HealthCertificateValues } from "./HealthCertificate.types.tsx";
import { HealthCertificateFileField } from "./HealthCertificateFileField.tsx";

const nameOf = nameOfFactory<HealthCertificateValues>();

export const HealthCertificateUploadFormFields: React.FC = () => {
  return (
    <Stack
      tokens={{ childrenGap: 8 }}
      {...dataAttribute(DataAttributes.Element, "health-certificate-form")}
    >
      <FieldCondition when="certificateFile" is={(v: string | undefined) => !v}>
        <div>
          <Heading
            variant="section-sub-heading"
            styles={{ root: { paddingBottom: 5 } }}
          >
            Health secure certificate
          </Heading>
          <Text styles={{ root: { paddingBottom: 16 } }}>
            Your ACC digital certificate has the file extension PFX. If it was
            sent to you on a CD, please insert your CD into your computer first.
          </Text>
        </div>
      </FieldCondition>

      <Fieldset verticalFill>
        <FieldCondition
          when="certificateFile"
          is={(v: string | undefined) => !v}
        >
          <HealthCertificateFileField />
        </FieldCondition>
        <FieldCondition
          when="certificateFile"
          is={(v: string | undefined) => !!v}
        >
          <>
            <TextInputField
              {...dataAttribute(DataAttributes.Element, "certificate-name")}
              placeholder="Certificate Name"
              name={nameOf("certificateName")}
              label="Certificate Name"
              required
            />
            <TextInputField
              type="password"
              {...dataAttribute(DataAttributes.Element, "certificate-password")}
              placeholder="Certificate Password"
              name={nameOf("certificatePassword")}
              label="Certificate Password"
              required
            />
          </>
        </FieldCondition>
      </Fieldset>
    </Stack>
  );
};
