import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontIcon,
  FontSizes,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { IInvoiceRow } from "./AccInvoiceTransactionsList.tsx";

export const AccInvoiceCalendarEventCell: FunctionComponent<IInvoiceRow> =
  observer(props => {
    const { clinical, booking, core } = useStores();
    const theme = useTheme();

    // calendarEvent is preloaded by fetchAccDataForSearchResult in AccInvoiceTransactionsList
    const calendarEvent = props.invoice.calendarEventId
      ? booking.calendarEventsMap.get(props.invoice.calendarEventId)
      : undefined;

    const AppointmentType = calendarEvent?.appointmentType?.text;

    if (!calendarEvent) {
      return <Text>—</Text>;
    }

    const fetch = async (): Promise<Encounter | undefined> => {
      const loadEncounter = async () => {
        const appointmentEncounters = await booking.getAppointmentEncounters({
          calendarEventId: calendarEvent.id
        });

        if (
          appointmentEncounters.length > 0 &&
          core.hasPermissions(Permission.ClinicalRead)
        ) {
          const { encounterId } = appointmentEncounters[0];
          return await clinical.getEncounter(encounterId);
        }

        return undefined;
      };

      const [encounter] = await Promise.all([
        loadEncounter(),
        calendarEvent.loadAppointmentType()
      ]);

      return encounter;
    };

    return (
      <DataFetcher<Encounter | undefined> fetch={fetch}>
        {encounter => (
          <Stack horizontal>
            {encounter?.isClosed === false && (
              <TooltipHost content="Incomplete notes">
                <FontIcon
                  iconName="EditNote"
                  styles={{
                    root: {
                      color: theme.semanticColors.errorIcon,
                      marginRight: 8,
                      fontSize: FontSizes.size16
                    }
                  }}
                />
              </TooltipHost>
            )}

            <TooltipHost
              content={AppointmentType}
              styles={{
                root: {
                  overflow: "hidden"
                }
              }}
            >
              <Text>{AppointmentType}</Text>
            </TooltipHost>
          </Stack>
        )}
      </DataFetcher>
    );
  });
