import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { ConfirmDialog, Spinner, Stack } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClaimAppointmentContext } from "../claims/ClaimAppointmentContext.tsx";

export const UnlinkConditionDialog: React.FC = observer(() => {
  const { clinical } = useStores();

  const { clinicalRecord } = usePatientRecordScreenContext();
  const { unlinkConditionFromEncounter } = useContext(ClaimAppointmentContext);
  const {
    setIsUnlinkConditionConfirmationDialogVisible,
    isUnlinkConditionConfirmationDialogVisible
  } = clinical.ui;

  if (!clinicalRecord || !clinicalRecord.condition) return null;

  const diagnosis = clinicalRecord.condition.primaryDiagnosis;
  const claimNumber = clinicalRecord.condition.claim?.claimNumber;

  const unlinkMessage = `Unlink ${diagnosis} ${
    !!claimNumber ? `${claimNumber} ` : ""
  }from this consult?`;

  return (
    <ConfirmDialog
      hidden={!isUnlinkConditionConfirmationDialogVisible}
      onCancel={() => setIsUnlinkConditionConfirmationDialogVisible(false)}
      onConfirm={async () => {
        await unlinkConditionFromEncounter();
        setIsUnlinkConditionConfirmationDialogVisible(false);
      }}
      dialogContentProps={{
        showCloseButton: false
      }}
      confirmButtonProps={{ text: "Unlink" }}
    >
      <DataFetcher<boolean>
        fetch={async () => {
          let dataExists = false;
          if (!!clinicalRecord.openEncounter?.id) {
            const data =
              await clinical.getEncounterEpisodeOfCareScopedClinicalData(
                clinicalRecord.openEncounter?.id
              );

            dataExists = !Object.values(data).every(el => el === null);
          }

          return dataExists;
        }}
        refetchId={Object.values(clinicalRecord.clinicalData?.dto ?? {})
          .map(e => e.eTag)
          .join()}
        fallback={<Spinner />}
      >
        {dataExists => {
          return (
            <Stack tokens={{ childrenGap: 16 }}>
              {dataExists && (
                <Stack.Item>
                  Clinical data has been recorded for the linked condition. This
                  data will be discarded if you proceed.
                </Stack.Item>
              )}
              <Stack.Item>{unlinkMessage}</Stack.Item>
            </Stack>
          );
        }}
      </DataFetcher>
    </ConfirmDialog>
  );
});
