import { Observer } from "mobx-react-lite";
import React from "react";

import {
  BodyAreaClinicalDataItemDto,
  BodyAreaExaminationClinicalDataDto,
  BodyAreaExaminationConfirmedClinicalDataDto,
  ClinicalDataType,
  FullBodyClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { StashedClinicalDataFormSpy } from "../../StashedClinicalDataFormSpy.tsx";
import { ImageValue } from "./examination/BodyExaminationForm.types.ts";
import { getImagesDTO } from "./examination/utils.ts";
import { FullBodyClinicalDataFields } from "./FullBodyClinicalDataFields.tsx";
import {
  FullBodyClinicalDataFormProps,
  FullBodyFormValues
} from "./FullBodyClinicalDataForm.types.ts";
import { FullBodyClinicalDataValidator } from "./validators/FullBodyClinicalDataValidator.tsx";

const validator = new FullBodyClinicalDataValidator();

export const FullBodyClinicalDataForm: React.FunctionComponent<
  FullBodyClinicalDataFormProps
> = ({ clinicalRecord }) => {
  const { clinical } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const { id, stashedClinicalData } = clinicalRecord;

  const getInitialValues = (): FullBodyFormValues => {
    const values: FullBodyFormValues = {};

    if (stashedClinicalData?.fullBody) {
      const fullBody = stashedClinicalData?.fullBody;

      values.examinationComment = fullBody?.examinationComment;

      if (fullBody?.frontImage) {
        values.frontImage = atob(fullBody.frontImage);
      }

      if (fullBody?.backImage) {
        values.backImage = atob(fullBody.backImage);
      }

      if (fullBody?.leftSideImage) {
        values.leftSideImage = atob(fullBody.leftSideImage);
      }

      if (fullBody?.rightSideImage) {
        values.rightSideImage = atob(fullBody.rightSideImage);
      }
    }

    return values;
  };

  const initialValues = getInitialValues();

  const onCancel = (): void => {
    clinical.ui.closePatientRecordContentForm(id, ClinicalDataType.FullBody);
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData(["fullBody"]);
  };

  // TODO: This is temporary to allow the back-end sync with the new data structure.
  // This needs to be removed when the new design of body examination available to release
  // The submit is saving bodyExamination data as well from this form
  // https://dev.azure.com/bpcloud-dev/BpCloud/_workitems/edit/29435/

  const getBodyExaminationData = (
    imageValue: ImageValue,
    examinationComment?: string
  ) => {
    const bodyExam: BodyAreaClinicalDataItemDto = {
      bodyArea: "FB",
      images: []
    };

    bodyExam.images = getImagesDTO(imageValue);
    bodyExam.comments = [{ comment: examinationComment }];

    const bodyArea: BodyAreaExaminationClinicalDataDto = {
      eTag: clinicalRecord.clinicalData?.bodyArea?.eTag,
      bodyAreasData: [bodyExam]
    };

    const bodyAreaConfirmed: BodyAreaExaminationConfirmedClinicalDataDto = {
      eTag: clinicalRecord.clinicalData?.bodyAreaConfirmed?.eTag,
      confirmed: true
    };

    return { bodyArea, bodyAreaConfirmed };
  };

  const getStashedClinicalData = (
    values: FullBodyFormValues
  ): Partial<EncounterClinicalData> => {
    const fullBody: FullBodyClinicalDataDto = {
      examinationComment: values.examinationComment
    };

    const imageValue: ImageValue = {};

    if (values.frontImage) {
      fullBody.frontImage = btoa(values.frontImage);
      imageValue.FBFS = values.frontImage;
    }

    if (values.backImage) {
      fullBody.backImage = btoa(values.backImage);
      imageValue.FBBS = values.backImage;
    }

    if (values.leftSideImage) {
      fullBody.leftSideImage = btoa(values.leftSideImage);
      imageValue.FBLS = values.leftSideImage;
    }

    if (values.rightSideImage) {
      fullBody.rightSideImage = btoa(values.rightSideImage);
      imageValue.FBRS = values.rightSideImage;
    }

    fullBody.eTag = clinicalRecord.clinicalData?.fullBody?.eTag;
    const { bodyArea, bodyAreaConfirmed } = getBodyExaminationData(
      imageValue,
      values.examinationComment
    );

    return { fullBody, bodyArea, bodyAreaConfirmed };
  };

  const onSubmit = async (values: FullBodyFormValues) => {
    const clinicalData = getStashedClinicalData(values);
    await clinicalRecord.saveClinicalData(clinicalData);
    onCancel();
  };

  const renderSubmitButtons = () => {
    return (
      <Observer>
        {() => {
          const dirty =
            clinicalRecord.stashedClinicalData?.dirtyAreas?.get("fullBody");

          return (
            <FormSubmitButtons
              onCancel={onCancel}
              promptOnCancel={true}
              submitButtonProps={{
                iconProps: {},
                disabled: !dirty
              }}
            />
          );
        }}
      </Observer>
    );
  };

  return (
    <SubmissionForm<FullBodyFormValues>
      formName="full-body"
      readOnly={isViewOnlyOrDischarged}
      disableRoutePrompt
      onRenderButtons={renderSubmitButtons}
      buttonsProps={{
        submitButtonProps: {
          iconProps: {}
        },
        promptOnCancel: true,
        extraPromptConditionOnCancel: () =>
          !!clinicalRecord.stashedClinicalData?.dirtyAreas?.get("fullBody"),
        onCancel
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validator.validate}
      subscription={{}}
      render={() => (
        <>
          <FullBodyClinicalDataFields />
          <StashedClinicalDataFormSpy<FullBodyFormValues>
            clinicalRecord={clinicalRecord}
            getData={getStashedClinicalData}
            areasToObserve={{
              fullBody: [ClinicalDataType.FullBody]
            }}
          />
        </>
      )}
    />
  );
};
