import { confirm, MessageBar, MessageBarType } from "@bps/fluent-ui";
import { ConflictError } from "@bps/http-client";

import { CorrespondencesLabels } from "./Correspondences.types.ts";

export const handleConflictError = async (
  error: any,
  dialogConfirmedCallback: (confirmed: boolean) => void
) => {
  const conflictErrorMessageBar: JSX.Element = (
    <>
      {CorrespondencesLabels.DialogConflictErrorTitle}
      <MessageBar
        messageBarType={MessageBarType.error}
        styles={{ root: { margin: "16px 0 0" } }}
      >
        {CorrespondencesLabels.DialogConflictErrorTitle}
      </MessageBar>
    </>
  );

  const isConflictError = error instanceof ConflictError;
  if (isConflictError) {
    const confirmed = await confirm({
      confirmButtonProps: {
        text: "Refresh"
      },
      cancelButtonProps: {
        text: "Cancel",
        autoFocus: true //Prevents close button to be focused by default when dialog appears
      },
      dialogContentProps: {
        title: conflictErrorMessageBar,
        subText: CorrespondencesLabels.DialogConflictErrorSubText,
        showCloseButton: true
      },
      maxWidth: 640
    });
    dialogConfirmedCallback(confirmed);
  }
  return isConflictError;
};
