import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import { Heading } from "@bps/fluent-ui";
import { DocumentStagingInfo } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { AddEditInvestigationDialogFields } from "./AddEditInvestigationDialogFields.tsx";
import { AddEditInvestigationDialogHelper } from "./AddEditInvestigationDialogHelper.ts";
import {
  AddEditInvestigationDialogProps,
  InvestigationFormValues,
  Labels
} from "./AddInvestigationDialog.types.ts";
import { AddInvestigationDialogValidator } from "./AddInvestigationDialogValidator.tsx";

const AddEditInvestigationDialogBase: React.FC<AddEditInvestigationDialogProps> =
  observer(({ onDismiss, clinicalRecord, editInvestigation, userTask }) => {
    const root = useStores();
    const { clinical } = root;
    const validator = new AddInvestigationDialogValidator(editInvestigation);

    useEffect(() => {
      clinical.getStagingInfo().then(uri => {
        setStagingPath(uri);
      });
    }, [clinical, editInvestigation]);

    const [stagingPath, setStagingPath] = useState<
      DocumentStagingInfo | undefined
    >();

    const helper = useRef(
      new AddEditInvestigationDialogHelper(clinicalRecord, root, {
        editInvestigation,
        userTask
      })
    );

    return (
      <SubmissionFormDialog<InvestigationFormValues>
        dialogName={editInvestigation ? Labels.DialogEdit : Labels.DialogAdd}
        dialogProps={{
          onDismiss,
          modalProps: {
            layerProps: {
              eventBubblingEnabled: true
            }
          },
          minWidth: 600,
          dialogContentProps: {
            title: (
              <Heading variant="modal-heading">
                {editInvestigation ? Labels.DialogEdit : Labels.DialogAdd}
              </Heading>
            ),
            showCloseButton: true
          }
        }}
        initialValues={helper.current.getInitialValues()}
        onSubmit={values =>
          helper.current.submitCorrespondence(values, stagingPath)
        }
        onSubmitSucceeded={onDismiss}
        validate={validator.validate}
        buttonsProps={() => {
          return {
            disableSubmitOnPristine: true,
            disableSubmitOnFormInvalid: true
          };
        }}
        render={() => (
          <AddEditInvestigationDialogFields
            stagingPath={stagingPath}
            editInvestigation={editInvestigation}
          />
        )}
      />
    );
  });

export const AddEditInvestigationDialog = withFetch(
  x => [x.inbox.ref.outcomes.load()],
  AddEditInvestigationDialogBase
);
