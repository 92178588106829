import { observer } from "mobx-react-lite";

import { Overlay, Stack } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { LocationsSelect } from "@ui-components/selects/LocationsSelect.tsx";

export const BookingCalendarLocationSelect = observer(() => {
  const { orgUnitIds, onChangeLocationOrgUnitIds, isInitialLoadingOrgUnits } =
    useBookingCalendarScreenContext();

  const { core } = useStores();

  if (!core.hasMultipleActiveLocations) return null;

  return (
    <Stack styles={{ root: { position: "relative" } }}>
      <LocationsSelect
        placeholder="Select location"
        multiSelect
        showAllSelected
        hideSearchOption
        showInactiveLocation
        selectedKeys={orgUnitIds.slice()}
        styles={{
          field: { border: "none", backgroundColor: "transparent" }
        }}
        onChangeSelectedKeys={onChangeLocationOrgUnitIds}
      />
      {isInitialLoadingOrgUnits && <Overlay />}
    </Stack>
  );
});
