import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontWeights,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, TIME_FORMATS } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { BookingEventSubText } from "./BookingEventSubText.tsx";
import { BookingEventTimeLocal } from "./BookingEventTimeLocal.tsx";

interface BookingEventTimeDisplayProps {
  startTime: DateTime;
  endTime: DateTime;
  isPartOfRecurrence: boolean | undefined;
  hasRecurrenceId: boolean;
  userName: string | undefined;
  bookingType: string;
  icon?: string;
  appointmentTypeName?: string;
  locationName: string | undefined;
}

export const BookingEventTimeDisplay: FunctionComponent<
  BookingEventTimeDisplayProps
> = ({
  startTime,
  endTime,
  isPartOfRecurrence,
  hasRecurrenceId,
  userName,
  bookingType,
  icon,
  appointmentTypeName,
  locationName
}) => {
  const { core } = useStores();

  const longStartDate = startTime.toFormat(DATE_FORMATS.LONG_DATE_WITH_DAY);
  const shortStartTime = startTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);
  const shortEndTime = endTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

  return (
    <Stack horizontal verticalAlign="center">
      <Stack styles={{ root: { width: 24, marginRight: 18 } }}>
        <FontIcon
          iconName={icon ?? "Clock"}
          styles={{ root: { fontSize: 24, marginRight: 18 } }}
        />
      </Stack>
      <Stack>
        <Stack horizontal>
          <Text
            styles={{ root: { fontWeight: FontWeights.bold } }}
            {...dataAttribute(DataAttributes.Element, "booking-time")}
          >
            {`${longStartDate} ${shortStartTime} - ${shortEndTime}`}
          </Text>
          {hasRecurrenceId && (
            <FontIcon
              styles={{ root: { paddingLeft: 12, paddingTop: 2 } }}
              iconName={isPartOfRecurrence ? "RepeatAll" : "UnsyncOccurence"}
            />
          )}
        </Stack>
        {!core.practiceAndUserHaveSameTimezone && (
          <When permission={Permission.PreRelease}>
            <Stack
              horizontal
              styles={{
                root: {
                  fontSize: 12
                }
              }}
              verticalAlign="center"
            >
              <FontIcon
                iconName="System"
                styles={{ root: { marginRight: 3 } }}
              />
              <BookingEventTimeLocal startTime={startTime} endTime={endTime} />
            </Stack>
          </When>
        )}
        <Stack horizontal>
          <BookingEventSubText
            bookingType={bookingType}
            userName={userName}
            appointmentTypeName={appointmentTypeName}
            locationName={locationName}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
