import { Heading, Separator, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { CalendarEventReminderStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";

import { getEventReminderStatusColours } from "./utils.tsx";

export enum EventReminderMessageTypes {
  Sent = 1,
  Reply = 2
}

enum EventReminderMessageTypesText {
  "Sent" = 1,
  "Reply" = 2
}

interface EventReminderMessageProps {
  message: string;
  dateTime: string;
  type: EventReminderMessageTypes;
  replyAction?: string;
}

export const EventReminderMessage: React.FC<EventReminderMessageProps> = ({
  message,
  dateTime,
  type,
  replyAction
}: EventReminderMessageProps) => {
  const theme = useTheme();

  const isoDateTime = DateTime.fromISO(dateTime);

  const dateTimeFormatted = isoDateTime.toDayDefaultFormat();

  const time = isoDateTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT);

  const eventReminderStatusColor = getEventReminderStatusColours(theme);

  return (
    <Stack>
      <Separator />
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack
          horizontalAlign="space-between"
          horizontal
          verticalAlign="center"
        >
          <Heading variant="section-sub-heading">
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <span>{EventReminderMessageTypesText[type]}</span>
              {!!replyAction && (
                <Stack
                  styles={{
                    root: {
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      background: eventReminderStatusColor(
                        replyAction as CalendarEventReminderStatus
                      )
                    }
                  }}
                />
              )}
            </Stack>
          </Heading>
          <Text
            styles={{
              root: {
                color: theme.palette.neutralSecondaryAlt
              }
            }}
          >
            {dateTimeFormatted} {time}
          </Text>
        </Stack>
        <Text>{message}</Text>
      </Stack>
    </Stack>
  );
};
