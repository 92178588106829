import { FunctionComponent } from "react";
import { useField } from "react-final-form";

import { MessageBar, MessageBarType, Shimmer } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { useEditUserDayWorkingHoursContext } from "../context/EditUserDayWorkingHoursContext.ts";
import { openingHoursInfoMessage } from "../utils.ts";
import { nameOf } from "./EditUserDayWorkingHours.types.ts";

export const OpeningHoursInfoMessage: FunctionComponent = () => {
  const {
    input: { value }
  } = useField<Date | undefined>(nameOf("date"));

  const { fetchStandardHours } = useEditUserDayWorkingHoursContext();

  if (!value) return null;

  return (
    <DataFetcher
      fetch={() => fetchStandardHours(value)}
      refetchId={value.toString()}
      fallback={<Shimmer styles={{ shimmerWrapper: { height: 32 } }} />}
    >
      {({ stdWorkingHoursStart, stdWorkingHoursEnd }) => (
        <MessageBar messageBarType={MessageBarType.info}>
          {openingHoursInfoMessage({
            start: stdWorkingHoursStart,
            end: stdWorkingHoursEnd,
            date: DateTime.fromJSDate(value)
          })}
        </MessageBar>
      )}
    </DataFetcher>
  );
};
