import { observer } from "mobx-react-lite";
import { FC, useRef } from "react";

import { Heading, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { defaultButtonsText } from "@ui-components/form/prompt/Prompt.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  AddPatientNoticeFormTypes,
  addPatientNoticesFormNameOf
} from "./PatientNoticeForm.types.tsx";
import { usePatientNoticesContext } from "./PatientNoticesContext.tsx";
import { PatientNoticeValidator } from "./PatientNoticeValidator.tsx";
import { getVisibility } from "./utils.ts";

interface AddPatientNoticeFormDialogNoContextBaseProps {
  onDismiss: () => void;
  editNotice?: PatientNotice;
  onSubmitSucceeded?: () => void;
}

const AddPatientNoticeFormDialogBase: FC<AddPatientNoticeFormDialogNoContextBaseProps> =
  observer(({ onDismiss, editNotice, onSubmitSucceeded }) => {
    const { userExperience, practice } = useStores();
    const { patientNotices, onSubmit, getInitialValues } =
      usePatientNoticesContext();

    // ⚠ Need to pass all the patients notices (clinical and admin) to validator (even if only displayng admin notices vice versa)
    const validator = useRef(
      new PatientNoticeValidator({
        patientNoticePriority: practice.ref.patientNoticePriority.values,
        patientNoticeType: practice.ref.patientNoticeType.values,
        currentNotices: patientNotices.filter(n => n.id !== editNotice?.id)
      })
    );

    const _onSubmit = async (values: AddPatientNoticeFormTypes) => {
      await onSubmit(values, editNotice);
      onDismiss();
    };

    const patientLabel = userExperience.localisedConfig("patientDisplay");
    const title = editNotice
      ? `Edit ${patientLabel} notice`
      : `New ${patientLabel} notice`;

    // Excluding inactive ref data for selection
    const patientNoticeTypeOptions = practice.ref.patientNoticeType.values
      .filter(ref => !ref.disabled)
      .map(({ code, text }) => ({
        key: code,
        text
      }));

    return (
      <SubmissionFormDialog<AddPatientNoticeFormTypes>
        dialogName="Add patient notice dialog"
        validate={validator.current.validate}
        initialValues={getInitialValues(editNotice)}
        onSubmit={_onSubmit}
        onSubmitSucceeded={onSubmitSucceeded}
        buttonsProps={{
          disableSubmitOnPristine: true,
          disableSubmitOnFormInvalid: true,
          submitButtonProps: { text: "Save" },
          hideButtonsSeparator: true
        }}
        promptDialogProps={{
          confirmButtonProps: { text: defaultButtonsText.confirm },
          cancelButtonProps: {
            text: defaultButtonsText.cancel
          }
        }}
        dialogProps={{
          minWidth: 480,
          onDismiss,
          dialogContentProps: {
            title: <Heading variant="modal-heading">{title}</Heading>,
            showCloseButton: true
          }
        }}
      >
        {({ form, values }) => {
          const { textMandatory } = values;
          return (
            <Stack tokens={{ childrenGap: 6 }}>
              <FieldSpy
                name={addPatientNoticesFormNameOf("type")}
                onChange={(type: string) => {
                  const selection = practice.ref.patientNoticeType.values.find(
                    x => x?.code === type
                  );
                  if (selection) {
                    form.batch(() => {
                      form.change(
                        "visibility",
                        getVisibility({
                          isClinical: selection.isClinical,
                          isAdmin: selection.isAdmin
                        })
                      );
                      form.change("textMandatory", selection.isTextMandatory);
                    });
                  }
                }}
              />
              <ChoiceGroupField
                fieldItemStyles={{ headerWrapper: { padding: 0 } }}
                required
                horizontal
                name={addPatientNoticesFormNameOf("priority")}
                label="Priority"
                options={practice.ref.patientNoticePriority.keyTextValues}
                styles={{
                  flexContainer: {
                    selectors: {
                      "& > .ms-ChoiceField:not(:first-child)": {
                        marginLeft: 8
                      }
                    }
                  }
                }}
              />
              <ComboBoxField
                name={addPatientNoticesFormNameOf("type")}
                label="Type"
                required
                options={patientNoticeTypeOptions}
                useComboBoxAsMenuWidth
                calloutProps={{
                  calloutMaxHeight: 500
                }}
              />
              <TextInputField
                name={addPatientNoticesFormNameOf("comment")}
                label="Comment"
                resizable={false}
                rows={4}
                maxLength={255}
                required={textMandatory}
                multiline
              />
              <CheckboxGroupField
                name={addPatientNoticesFormNameOf("visibility")}
                label="Visibility"
                required
                horizontal
                options={[
                  { label: "Clinical", value: "isClinical" },
                  { label: "Admin", value: "isAdmin" }
                ]}
              />
            </Stack>
          );
        }}
      </SubmissionFormDialog>
    );
  });

export const AddPatientNoticeFormDialog = withFetch(
  x => [
    x.practice.ref.patientNoticePriority.load(),
    x.practice.ref.patientNoticeType.load()
  ],
  AddPatientNoticeFormDialogBase
);
