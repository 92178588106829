import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import {
  ExaminationCommentsType,
  ImageValue,
  SpecialTestResponseType
} from "../../full-body-clinical-data/examination/BodyExaminationForm.types.ts";

export class CentralNervousSystemFormValues {
  imageValue: ImageValue;
  cranialNerveTestResponseItems?: SpecialTestResponseType;
  examinationComments: ExaminationCommentsType;
}

export const centralNervousSystemNameOf =
  nameOfFactory<CentralNervousSystemFormValues>();
