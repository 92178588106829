import { DATE_FORMATS } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  isNotFutureDate,
  maxLength,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { COMMENT_MAX_LENGTH } from "@modules/billing/screens/validators/InvoiceItemListAddCommentValidator.tsx";

import { CeaseCurrentMedicationFormValues } from "./CeaseCurrentMedicationDialog.tsx";

const nameOf = nameOfFactory<CeaseCurrentMedicationFormValues>();

export class CeaseCurrentMedicationFormValidator extends Validator<CeaseCurrentMedicationFormValues> {
  constructor() {
    super();

    this.forField(nameOf("date"), [
      required(),
      validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
      isNotFutureDate()
    ]);

    this.forField(nameOf("reason"), [required(), maxLength(255)]);
    this.forField(nameOf("comment"), [maxLength(COMMENT_MAX_LENGTH)]);
  }
}
