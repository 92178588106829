import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  getScreenSelector,
  mergeStyleSets,
  PersonaSize,
  PillButton,
  ScreenWidthMinXLarge,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { patientTabKey } from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

interface PatientPillProps {
  loading?: boolean;
  active?: boolean;
  record?: ClinicalRecord;
  patient: Contact;
  encounterId?: string;
}

export const PATIENT_PILL_MAX_WIDTH = 200;
export const COIN_WIDTH_ONLY = 42;
export const COIN_WITH_CLOSE_ICON_WIDTH = 62;

export const PatientPill: FunctionComponent<PatientPillProps> = observer(
  props => {
    const { clinical, notification } = useStores();
    const [loadingRecord, setLoadingRecord] = useState(false);

    const theme = useTheme();

    const handleClick = () => {
      if (props.patient && !props.active) {
        props.encounterId
          ? (clinical.activeRecord = {
              patientId: props.patient.id,
              encounterId: props.encounterId
            })
          : clinical.setActiveViewOnlyRecord(props.patient.id);
      }
    };

    const pillKey = patientTabKey(props.patient.id, props.encounterId);

    const onClose = async (id: string) => {
      setLoadingRecord(true);

      try {
        const record = await clinical.getRecord(id, {
          encounterId: props.encounterId
        });

        if (props.encounterId) {
          if (
            !record.areNotesEntered &&
            !record.clinicalData?.reasonForVisit?.reasonForVisits
          ) {
            runInAction(() => {
              clinical.ui.isConfirmationDialogVisible = true;
            });
          } else {
            runInAction(() => {
              clinical.ui.closingClinicalRecordId = record.id;
            });
          }
        } else {
          await record.close(props.encounterId);
        }
      } catch (e) {
        notification.error(e);
      } finally {
        setLoadingRecord(false);
      }
    };

    const handleClose = async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (!props.patient) {
        return;
      }

      await onClose(props.patient.id);
    };

    const { patient, record, active, loading } = props;
    const closable = !record || !record.saving;

    const activeStyles = {
      root: {
        width: PATIENT_PILL_MAX_WIDTH,
        transition: "width 300ms linear",
        i: {
          display: "block"
        }
      }
    };

    const pillColour = active
      ? theme.palette.neutralLight
      : theme.palette.white;

    const viewOnlyPillColour = theme.semanticColors.buttonBackgroundDisabled;

    return (
      <TooltipHost content={patient ? patient.preferredFullName : undefined}>
        <PillButton
          active={active}
          onClick={handleClick}
          onClose={closable ? handleClose : undefined}
          onRenderIcon={
            loadingRecord
              ? () => <Spinner size={SpinnerSize.xSmall} />
              : undefined
          }
          styles={mergeStyleSets(
            {
              root: {
                backgroundColor: props.encounterId
                  ? pillColour
                  : viewOnlyPillColour,
                padding: "3px 5px 3px 8px",
                width: COIN_WIDTH_ONLY,
                [getScreenSelector(0, ScreenWidthMinXLarge)]: {
                  width: !active
                    ? COIN_WITH_CLOSE_ICON_WIDTH
                    : PATIENT_PILL_MAX_WIDTH
                },
                i: {
                  display: "none",
                  [getScreenSelector(0, ScreenWidthMinXLarge)]: {
                    display: "block"
                  }
                }
              }
            },
            active && activeStyles
          )}
        >
          {patient && patient.preferredFullName && (
            <Persona
              {...dataAttribute(DataAttributes.Data, patient.preferredFullName)}
              text={patient.preferredFullName}
              id={`${pillKey}${props.encounterId === undefined ? "-view" : ""}`}
              imageUrl={active ? patient.profilePictureUrl : undefined}
              size={PersonaSize.size24}
              imageInitials={patient.preferredInitials}
              coinProps={
                props.encounterId
                  ? {}
                  : {
                      initialsColor: theme.palette.neutralTertiary
                    }
              }
              styles={{
                details: {
                  paddingRight: 0
                },
                root: {
                  width: "100%"
                }
              }}
              onRenderPrimaryText={() => (
                <span>
                  {loading && <Spinner size={SpinnerSize.small} />}
                  {patient && (
                    <Stack
                      horizontal
                      styles={{ root: { justifyContent: "space-between" } }}
                    >
                      <Text
                        styles={{
                          root: { overflow: "hidden" }
                        }}
                      >
                        {patient.preferredName}
                      </Text>
                      {!props.encounterId && (
                        <FontIcon
                          iconName="LockSolid"
                          styles={{
                            root: {
                              fontSize: 12,
                              marginRight: 4,
                              marginTop: 3,
                              color: theme.palette.neutralSecondaryAlt
                            }
                          }}
                        />
                      )}
                    </Stack>
                  )}
                </span>
              )}
            />
          )}
          {loading ||
            (record && record.saving && <Spinner size={SpinnerSize.small} />)}
        </PillButton>
      </TooltipHost>
    );
  }
);
