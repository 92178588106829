import React, { useContext, useState } from "react";

import {
  DefaultButton,
  Dialog,
  MessageBar,
  MessageBarType,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants";
import { AddInvoiceDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AccInvoicesListContext } from "../context/AccInvoicesListContext.ts";
import { ResubmitStatus } from "./ResubmitStatus.tsx";

type StepStatus = "initial" | "loading" | "success" | "error";

export enum ResubmitStatusEnum {
  Initial = "initial",
  Loading = "loading",
  Success = "success",
  Error = "error"
}

export interface Step {
  name: string;
  status: StepStatus;
}

interface ResubmitInvoiceDialogProps {
  selectedInvoice: Invoice;
  onDismiss: () => void;
  showResubmitInvoiceDialog: boolean;
}

export const ResubmitInvoiceDialog: React.FC<ResubmitInvoiceDialogProps> = ({
  selectedInvoice,
  onDismiss,
  showResubmitInvoiceDialog
}) => {
  const [cancelInvoiceStatus, setCancelInvoiceStatus] = useState(
    ResubmitStatusEnum.Initial
  );

  const [createInvoiceStatus, setCreateInvoiceStatus] = useState(
    ResubmitStatusEnum.Initial
  );

  const [submitInvoiceStatus, setSubmitInvoiceStatus] = useState(
    ResubmitStatusEnum.Initial
  );

  const [error, setError] = useState<string | undefined>(undefined);

  const [invNumber, setInvNumber] = useState<string | undefined>(undefined);

  const [oldInvNumber, setOldInvNumber] = useState<string | undefined>(
    undefined
  );

  const [accInvNumber, setAccInvNumber] = useState<string | undefined>(
    undefined
  );

  const { billing, notification, acc } = useStores();
  const theme = useTheme();

  const { selection } = useContext(AccInvoicesListContext);

  const cancelInvoice = async () => {
    setCancelInvoiceStatus(ResubmitStatusEnum.Loading);
    setOldInvNumber(selectedInvoice.number);
    try {
      await billing.cancelInvoice(selectedInvoice.id, "DECLINEDBYINSURER");
      notification.success(
        notificationMessages.invoiceCanceled(selectedInvoice.number)
      );
      setCancelInvoiceStatus(ResubmitStatusEnum.Success);
    } catch (e) {
      notification.error(e.message);
      setCancelInvoiceStatus(ResubmitStatusEnum.Error);
      setError(e.detail || e.message);
      throw e;
    }
  };

  const createNewInvoice = async () => {
    setCreateInvoiceStatus(ResubmitStatusEnum.Loading);
    try {
      const [oldInvoice, newInvNumber] = await Promise.all([
        billing.getInvoice(selectedInvoice.id),
        billing.generateInvoiceNumber()
      ]);
      setInvNumber(newInvNumber);
      const {
        items,
        dto: { accountId, location, transactionDate, accountContact, itemType }
      } = oldInvoice;

      const newItemsFromOld = items.map(x => ({
        ...x,
        id: undefined,
        transactionId: undefined,
        references: undefined,
        eTag: undefined,
        accScheduleItem: undefined,
        changeLog: undefined
      }));

      const newInvoiceDtoFromOld: AddInvoiceDto = {
        accountId,
        location,
        transactionDate,
        accountContact,
        itemType,
        number: newInvNumber,
        items: newItemsFromOld
      };

      const newInvoice = await billing.addInvoice({
        ...newInvoiceDtoFromOld,
        number: newInvNumber
      });

      setCreateInvoiceStatus(ResubmitStatusEnum.Success);
      return newInvoice;
    } catch (e) {
      notification.error(e.message);
      setCreateInvoiceStatus(ResubmitStatusEnum.Error);
      setError(e.message);
      throw e;
    }
  };

  const submitNewInvoice = async (newInvoice: Invoice) => {
    setSubmitInvoiceStatus(ResubmitStatusEnum.Loading);

    try {
      await acc.submitAccInvoices([newInvoice.id]);
      const message = `Invoice ${newInvoice.number} has been submitted to ACC`;
      notification.success(message);
      setAccInvNumber(newInvoice.number);
      setSubmitInvoiceStatus(ResubmitStatusEnum.Success);
    } catch (e) {
      notification.error(e.message);
      setSubmitInvoiceStatus(ResubmitStatusEnum.Error);
      setError(e.message);
      throw e;
    }
  };

  const handleResubmit = async () => {
    try {
      await cancelInvoice();
      const newInvoice = await createNewInvoice();
      await submitNewInvoice(newInvoice);
    } catch (e) {
    } finally {
      selection.setAllSelected(false);
    }
  };

  return (
    <Dialog
      onDismiss={onDismiss}
      minWidth={460}
      hidden={!showResubmitInvoiceDialog}
      dialogContentProps={{
        title: "Resubmit invoice to ACC",
        styles: { subText: { marginBottom: 16, width: 600 } }
      }}
    >
      <Stack tokens={{ childrenGap: 24 }}>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
        )}
        <Stack.Item>
          The existing invoice will need to be cancelled, and a new one created.
        </Stack.Item>
        <Stack.Item>
          This may cause payment issues if ACC pays the cancelled invoice.
        </Stack.Item>
        <Stack horizontalAlign="center">
          <DefaultButton
            disabled={!!error || !selectedInvoice}
            onClick={handleResubmit}
            type="submit"
            text="Confirm & continue"
          />
        </Stack>
        <Stack
          tokens={{ childrenGap: 8 }}
          styles={{
            root: {
              border: `1px solid ${theme.palette.neutralLight}`,
              borderRadius: 6,
              padding: 8
            }
          }}
        >
          <ResubmitStatus
            step={{ name: "Cancel invoice", status: cancelInvoiceStatus }}
            invNumber={oldInvNumber}
            failText="Unable to cancel"
          />
          <ResubmitStatus
            step={{
              name: "Create new invoice",
              status: createInvoiceStatus
            }}
            invNumber={invNumber}
            failText="Create manually"
          />

          <ResubmitStatus
            step={{ name: "Submit invoice", status: submitInvoiceStatus }}
            invNumber={accInvNumber}
            failText="Submit manually"
          />
        </Stack>
        <Stack horizontal horizontalAlign="end">
          <DefaultButton onClick={onDismiss} text="Close" />
        </Stack>
      </Stack>
    </Dialog>
  );
};
