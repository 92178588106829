import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Invoice } from "@stores/billing/models/Invoice.ts";

import { InvoiceListItem } from "./Invoice.types.ts";
import { InvoiceItemListChargeToCell } from "./InvoiceItemListChargeToCell.tsx";
import { getIsItemASubsidy } from "./utils.ts";

interface InvoiceViewChargeToCellProps {
  invoice: Invoice;
  item: InvoiceListItem;
}

export const InvoiceViewChargeToCell: FunctionComponent<InvoiceViewChargeToCellProps> =
  observer(props => {
    const { invoice, item } = props;

    const isSubsidy: boolean = getIsItemASubsidy(item);

    return (
      <InvoiceItemListChargeToCell
        contactId={invoice.accountId}
        isSubsidy={isSubsidy}
      />
    );
  });
