import { createContext } from "react";

import { DraftItemsListHelper } from "./DraftItemsListHelper.ts";

// Empty object is provided as default context as unable to create a class without a RootStore
// This will result in the app or tests breaking if a context value isn't provided

export const DraftItemListContext = createContext<DraftItemsListHelper>(
  {} as DraftItemsListHelper
);
