import { observer } from "mobx-react-lite";

import {
  ButtonsGroupOption,
  CopyToClipboardButton,
  DirectionalHint,
  FontWeights,
  Heading,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { ShowAvailabilityEnum } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextEditorField } from "@ui-components/form/TextEditorField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { useBhbConfigContext } from "../../context/BhbConfigContext.ts";
import { BhbOnlineSettingsPracticeProfileValidator } from "../../validators/BhbOnlineSettingsPracticeProfileValidator.tsx";
import {
  BHB_ONLINE_SETTINGS_DISABILITY_MAX_CHARACTERS,
  BHB_ONLINE_SETTINGS_PARKING_MAX_CHARACTERS,
  DurationUnit,
  onlineSettingsNameOf
} from "../BhbOnlineSettings.types.ts";
import { ApplyPracticeLogo } from "./ApplyPracticeLogo.tsx";
import { getBhbOnlineSettingsFormStyles } from "./BhbOnlineSettingsForm.styles.ts";

const validator = new BhbOnlineSettingsPracticeProfileValidator();
const PLACE_HOLDER_TEXT = "Nothing will be displayed if not specified";

export const BhbOnlineSettingsForm: React.FC = observer(() => {
  const root = useStores();

  const theme = useTheme();

  const {
    onlineSettingsFormInitialValues,
    onlineSettingsFormSubmit,
    appointmentTypes
  } = useBhbConfigContext();

  const {
    separatorStyles,
    inlineLabelStyles,
    reverseInlineLabelStyles,
    spinNumberStyles,
    dropdownStyles,
    toggleStyles
  } = getBhbOnlineSettingsFormStyles(theme);

  const ROWS = 3;

  const bookingUrl = `${window.appConfig.bhbBookingUrl}?locationId=${root.core.locationId}`;

  const buttonGroupOptons: ButtonsGroupOption<DurationUnit>[] = [
    { key: "minutes", text: "Mins" },
    { key: "hours", text: "Hours" },
    { key: "days", text: "Days" }
  ];

  const showAvailabilityOptions = Object.values(ShowAvailabilityEnum)
    .filter(y => !isNaN(Number(y)) && y)
    .map(value => {
      return { key: value, text: `${value}` };
    });

  const appointmentTypeOptions = appointmentTypes.map(t => ({
    key: t.id,
    text: t.name
  }));

  return (
    <SubmissionForm
      onSubmit={onlineSettingsFormSubmit}
      formName="bhb-online-booking-settings-form"
      initialValues={onlineSettingsFormInitialValues}
      validate={validator.validate}
      buttonsProps={{ styles: { root: { paddingRight: 24, marginTop: 0 } } }}
      styles={{
        fields: {
          width: 630,
          padding: "16px 0",
          margin: "0 64px"
        }
      }}
    >
      {() => (
        <Stack tokens={{ childrenGap: 16 }}>
          <Stack styles={separatorStyles} tokens={{ childrenGap: 8 }}>
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Heading variant="section-heading-light">
                Best Health Booking
              </Heading>
              <ToggleField
                name={onlineSettingsNameOf("onlineBookingsEnabled")}
                styles={toggleStyles}
                onText="Enabled"
                offText="Disabled"
              />
            </Stack>
            <FieldCondition
              when={onlineSettingsNameOf("onlineBookingsEnabled")}
              is={false}
            >
              <MessageBar messageBarType={MessageBarType.warning}>
                Please enable the online booking to display your practice
                information online
              </MessageBar>
            </FieldCondition>
          </Stack>
          <Stack styles={separatorStyles} tokens={{ childrenGap: 8 }}>
            <Heading
              variant="section-heading-light"
              styles={{ root: { marginBottom: 8 } }}
            >
              Practice information - {root.core.location.name}
            </Heading>
            <Stack>
              <ApplyPracticeLogo />
              <Heading>Unique booking url</Heading>
              <Stack
                horizontal
                tokens={{ childrenGap: 8 }}
                verticalAlign="center"
                horizontalAlign="space-between"
              >
                <Link
                  href={bookingUrl}
                  target="_blank"
                  styles={{ root: { overflow: "hidden", textWrap: "nowrap" } }}
                >
                  {bookingUrl}
                </Link>
                <CopyToClipboardButton
                  directionalHint={DirectionalHint.topRightEdge}
                  clipboardContent={bookingUrl}
                  text="Copy link"
                  buttonProps={{
                    styles: { textContainer: { textWrap: "nowrap" } }
                  }}
                />
              </Stack>
            </Stack>

            <Stack grow tokens={{ childrenGap: 8 }}>
              <TextInputField
                name={onlineSettingsNameOf("url")}
                label="Practice website URL"
              />
              <TextInputField
                name={onlineSettingsNameOf("parkingAccess")}
                label="Parking"
                multiline
                rows={ROWS}
                maxLength={BHB_ONLINE_SETTINGS_PARKING_MAX_CHARACTERS}
                placeholder={PLACE_HOLDER_TEXT}
              />
              <TextInputField
                name={onlineSettingsNameOf("disabilityAccess")}
                label="Disability access"
                multiline
                rows={ROWS}
                maxLength={BHB_ONLINE_SETTINGS_DISABILITY_MAX_CHARACTERS}
                placeholder={PLACE_HOLDER_TEXT}
              />
              <TextEditorField
                name={onlineSettingsNameOf("policy")}
                label="Booking policy"
                placeholder={PLACE_HOLDER_TEXT}
              />
              <Stack horizontal horizontalAlign="space-between">
                <Text bold>Emergency message</Text>
                <ToggleField
                  name={onlineSettingsNameOf("emergencyMessageEnabled")}
                  styles={toggleStyles}
                  onText="Showing"
                  offText="Show"
                />
              </Stack>
              <TextEditorField
                name={onlineSettingsNameOf("emergencyMessage")}
              />
            </Stack>
          </Stack>

          <Stack styles={separatorStyles} tokens={{ childrenGap: 8 }}>
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
              styles={{ root: { marginBottom: 8 } }}
            >
              <Heading variant="section-heading-light">
                Appointment cancellation / rescheduling
              </Heading>
              <ToggleField
                name={onlineSettingsNameOf("allowCancellationsEnabled")}
                styles={toggleStyles}
                onText="Allowed"
                offText="Disabled"
              />
            </Stack>
            <FieldSpy
              name={onlineSettingsNameOf("allowCancellationsEnabled")}
              subscription={{ value: true }}
            >
              {(_, allowCancellationsEnabled: boolean) => (
                <>
                  <Stack horizontal>
                    <SpinNumberInputField
                      name={onlineSettingsNameOf("minimumTimeToCancelValue")}
                      label="No later than"
                      parse={value => Number(value)}
                      fieldItemStyles={inlineLabelStyles}
                      styles={spinNumberStyles}
                      min={1}
                      disabled={!allowCancellationsEnabled}
                    />
                    <ButtonsGroupSingleChoiceField
                      options={buttonGroupOptons}
                      name={onlineSettingsNameOf("minimumTimeToCancelUnit")}
                      label="before the appointment"
                      fieldItemStyles={reverseInlineLabelStyles}
                      disabled={!allowCancellationsEnabled}
                    />
                  </Stack>
                  <OptionsSelectField
                    options={appointmentTypeOptions}
                    name={onlineSettingsNameOf(
                      "nonCancellableAppointmentTypes"
                    )}
                    label="Appointment types NOT allowed for cancellation online (via call to clinic only)"
                    multiSelect
                    disabled={!allowCancellationsEnabled}
                    hideSearchOption
                  />
                </>
              )}
            </FieldSpy>
          </Stack>

          <Stack styles={separatorStyles} tokens={{ childrenGap: 8 }}>
            <Heading
              variant="section-heading-light"
              styles={{ root: { marginBottom: 8 } }}
            >
              Booking availability timeframes & limits
            </Heading>
            <Stack horizontal wrap styles={{ inner: { rowGap: "8px" } }}>
              <DropdownField
                name={onlineSettingsNameOf("showAvailability")}
                label="Max"
                options={showAvailabilityOptions}
                fieldItemStyles={{
                  ...inlineLabelStyles,
                  suffix: { fontWeight: FontWeights.semibold }
                }}
                styles={dropdownStyles}
                suffix="weeks —"
                withNoEmptyOption
              />
              <SpinNumberInputField
                name={onlineSettingsNameOf("minimumTimeToBookValue")}
                label="Min"
                parse={value => Number(value)}
                fieldItemStyles={inlineLabelStyles}
                styles={spinNumberStyles}
                min={1}
              />
              <ButtonsGroupSingleChoiceField
                options={buttonGroupOptons}
                name={onlineSettingsNameOf("minimumTimeToBookUnit")}
              />
              <Text bold>before the appointment</Text>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              styles={{ root: { minHeight: 32 } }}
            >
              <ToggleField
                name={onlineSettingsNameOf("limitMaximumAppointmentsPerDay")}
                label="Daily appointments number per patient is"
                styles={{
                  root: {
                    display: "flex",
                    alignItems: "center",
                    margin: 0
                  },
                  label: { marginRight: 8 },
                  container: { marginRight: 8 }
                }}
              />
              <FieldSpy
                name={onlineSettingsNameOf("limitMaximumAppointmentsPerDay")}
                subscription={{ value: true }}
              >
                {(_, limitMaximumAppointmentsPerDay) =>
                  limitMaximumAppointmentsPerDay ? (
                    <SpinNumberInputField
                      name={onlineSettingsNameOf("maximumAppointmentsPerDay")}
                      label="limited to"
                      parse={value => Number(value)}
                      fieldItemStyles={inlineLabelStyles}
                      styles={spinNumberStyles}
                      min={1}
                    />
                  ) : (
                    <Text bold>unlimited</Text>
                  )
                }
              </FieldSpy>
            </Stack>
          </Stack>
        </Stack>
      )}
    </SubmissionForm>
  );
});
