import React from "react";

import { Spinner, useResizeElementObserver } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolForm } from "../clinical-tool-form/ClinicalToolForm.tsx";
import { DASS21Questions } from "./DASS21Questions.tsx";
import { DASS21Score } from "./DASS21Score.tsx";

export interface DASS21FormProps {
  clinicalRecord: ClinicalRecord;
}

export const DASS21QuestionnaireForm: React.FC<DASS21FormProps> = ({
  clinicalRecord
}) => {
  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.DASS21V1)}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <ClinicalToolForm
          formName="DASS21"
          clinicalRecord={clinicalRecord}
          clinicalDataType={ClinicalDataType.DASS21}
          formHeading="DASS 21"
          questionnaire={questionnaire}
          boldDiagnosis
          onRenderModalContent={(diagnosis, measurements) => {
            return <DASS21Score measurements={measurements} />;
          }}
        >
          <>
            <div
              ref={r => {
                if (r && !element) {
                  setElement(r);
                }
              }}
            />
            <DASS21Questions questionnaire={questionnaire} width={panelWidth} />
          </>
        </ClinicalToolForm>
      )}
    </DataFetcher>
  );
};
