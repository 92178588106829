import { useForm } from "react-final-form";

import { Heading } from "@bps/fluent-ui";
import {
  ClaimDiagnosisDto,
  ReferralDiagnosisDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { ClaimFormLabels } from "./ClaimFormEnums.ts";

interface DiagnosisToggleHeaderProps {
  toggleName: string;
  fieldName: string;
  emptyDiagnosis: ClaimDiagnosisDto | ReferralDiagnosisDto;
}

export const DiagnosisToggleHeader: React.FC<DiagnosisToggleHeaderProps> = ({
  toggleName,
  fieldName,
  emptyDiagnosis
}) => {
  const form = useForm();
  const { fields } = useFieldArray(fieldName);
  return (
    <>
      <Heading
        variant="section-heading-light"
        styles={{
          root: {
            "&&": {
              marginTop: 24
            }
          }
        }}
      >
        {ClaimFormLabels.additionalDiagnoses}
      </Heading>
      <ToggleField
        automationAttribute="diagnosis-toggle"
        styles={{ root: { marginBottom: 0 } }}
        onText="Yes"
        offText="No"
        name={toggleName}
      />
      <FieldSpy
        name={toggleName}
        onChange={checked => {
          if (checked) {
            if (
              !fields.value ||
              (fields?.value && (fields?.value?.length ?? 0) === 0)
            )
              fields.push(emptyDiagnosis);
          } else form.change(fieldName, []);
        }}
      />
      <FieldSpy
        name={fieldName}
        onChange={values => {
          if (values.length === 0) form.change(toggleName, false);
        }}
      />
    </>
  );
};
