import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { NoDataTile, ScrollablePane } from "@bps/fluent-ui";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { TransactionActionCell } from "@modules/billing/screens/account/components/transaction-list/TransactionActionCell.tsx";
import { TransactionDetailsCell } from "@modules/billing/screens/account/components/transaction-list/TransactionDetailsCell.tsx";
import { useAccountScreenContext } from "@modules/billing/screens/account/context/AccountScreenContext.ts";
import { Statement } from "@stores/billing/models/Statement.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { StatementsTransactionContextualMenu } from "./StatementsTransactionContextualMenu.tsx";

export const StatementsList: React.FC = observer(() => {
  const { billing, core } = useStores();
  const {
    ui: { onToggleStatementModal }
  } = billing;

  const { accountContact, getStatements } = useAccountScreenContext();

  const search = useCallback(
    async (query?: PagingOptions) => {
      return await getStatements({
        ...query,
        accountContactId: accountContact.id
      });
    },
    [accountContact.id, getStatements]
  );

  const columnArr = [
    {
      key: "ContextMenu",
      name: "",
      minWidth: 36,
      maxWidth: 36,
      onRender: (props: Statement) => {
        return <StatementsTransactionContextualMenu statement={props} />;
      }
    },
    {
      name: "Generated",
      key: "date",
      minWidth: 79,
      maxWidth: 79,
      onRender: (props: Statement) => {
        if (props.date) {
          return props.date.toDayDefaultFormat();
        }

        return "";
      }
    },
    {
      name: "Details",
      key: "status",
      minWidth: 367,
      maxWidth: 367,
      onRender: (props: Statement) => {
        return <TransactionDetailsCell statement={props} />;
      }
    },
    {
      name: "Action",
      key: "action",
      minWidth: 80,
      maxWidth: 80,
      onRender: (props: Statement) => {
        return <TransactionActionCell statement={props} />;
      }
    }
  ];

  return (
    <div style={{ position: "relative", flexGrow: 1 }}>
      <ScrollablePane>
        <InfiniteScrollList<Statement>
          setKey="billing-list"
          getItems={search}
          refreshKey={`${billing.ui.lastUpdatedStatementETag}`}
          detailsListStyles={{
            root: {
              ".ms-DetailsRow-cell": { alignSelf: "center" }
            }
          }}
          columns={
            !core.hasPermissions(Permission.InvoiceCreate)
              ? columnArr.filter(column => column.key !== "action")
              : columnArr
          }
          onRenderNoResults={() => (
            <NoDataTile
              styles={{ root: { boxShadow: "none" } }}
              textProps={{ text: "No statements" }}
              linkProps={
                accountContact.type === ContactType.Patient
                  ? {
                      text: `Add a new statement for ${accountContact.name}`,
                      onClick: () => {
                        onToggleStatementModal(true);
                      }
                    }
                  : { hidden: true }
              }
            />
          )}
        />
      </ScrollablePane>
    </div>
  );
});
