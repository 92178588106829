import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { useAppointmentTypeScreenContext } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";
import { BhbAppointmentType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { OnlineBookingBadge } from "./OnlineBookingBadge.tsx";

interface OnlineBookingBadgeProps {
  appointmentType: AppointmentType;
  bhbAppointmentType: BhbAppointmentType;
}

export const OnlineBookingCell: FunctionComponent<OnlineBookingBadgeProps> =
  observer(({ bhbAppointmentType }) => {
    const {
      setBHBAppointmentType,
      setShowOnlineSettingModal,
      setOmniAppointmentType,
      showOnlineAppointmentTypeSettingModal
    } = useAppointmentTypeScreenContext();

    const { booking } = useStores();
    const { isAvailableExistingPatients, isAvailableNewPatients } =
      bhbAppointmentType;

    const onClick = async () => {
      if (!showOnlineAppointmentTypeSettingModal) {
        const appointmentType = await booking.getAppointmentType(
          bhbAppointmentType.id
        );
        setOmniAppointmentType(appointmentType);
        setBHBAppointmentType(bhbAppointmentType);
        setShowOnlineSettingModal(true);
      }
    };

    return (
      <OnlineBookingBadge
        isAvailableExistingPatients={isAvailableExistingPatients}
        isAvailableNewPatients={isAvailableNewPatients}
        onClick={onClick}
      />
    );
  });
