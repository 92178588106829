import { observer } from "mobx-react-lite";
import { useField, useForm, useFormState } from "react-final-form";

import { ButtonsGroupOption } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { ReportTypeKeys } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { VisibilityAndConfidentialityField } from "@modules/clinical/screens/document-writer/components/VisibilityAndConfidentialityField.tsx";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { Labels } from "../investigations/AddInvestigationDialog.types.ts";
import { CorrespondenceFileUploaderField } from "./CorrespondenceFileUploaderField.tsx";
import { ClinicalImageFormValues } from "./DocumentBatchFormValues.ts";

const UPLOAD_MAX_FILES = 1;

interface ClinicalImageFormProps {
  isEdit: boolean;
  clinicalImage: ClinicalDocument | undefined;
}

export const nameOf = nameOfFactory<ClinicalImageFormValues>();
export const ClinicalImageFormFieldsBase: React.FC<ClinicalImageFormProps> =
  observer(({ isEdit, clinicalImage }) => {
    const form = useForm();
    const { inbox } = useStores();
    const { values: formValues } = useFormState<ClinicalImageFormValues>();

    const {
      input: { value: stagingPath }
    } = useField(nameOf("stagingPath"), { subscription: { value: true } });

    const reportTypeOptions: ButtonsGroupOption<string>[] =
      inbox.ref.reportTypes.keyTextValues
        .map(x => {
          return {
            key: x.key,
            text: x.text,
            disabled: false
          };
        })
        .filter(x => x.key !== ReportTypeKeys.normal);

    const reportTypeDisabled = clinicalImage?.reportType !== undefined;
    const showCheckedByUser = [
      ReportTypeKeys.acceptable,
      ReportTypeKeys.unacceptable
    ].includes(formValues.reportType as ReportTypeKeys);

    return (
      <>
        {!isEdit && (
          <CorrespondenceFileUploaderField
            stagingPath={stagingPath}
            hideSubject={true}
            hideDate={true}
            maxFiles={UPLOAD_MAX_FILES}
            setExternalFields={files => {
              const [file] = files;
              file &&
                form.batch(() => {
                  form.change(nameOf("file"), file);
                });
            }}
          />
        )}

        <Fieldset frame styles={{ root: { marginTop: "8px" } }}>
          <TextInputField
            name="file.subject"
            placeholder="Subject"
            label="Subject"
            required
          />

          <DatePickerField
            maxDate={DateTime.jsDateNow()}
            name="file.date"
            label="Document date"
          />

          <VisibilityAndConfidentialityField
            name={nameOf("visibility")}
            isEdit={isEdit}
          />

          <ButtonsGroupSingleChoiceField
            disabled={reportTypeDisabled}
            label="This image is "
            name={nameOf("reportType")}
            options={reportTypeOptions}
          />

          {showCheckedByUser && (
            <RecordUpdateCheckedLog
              checkedBy={formValues.checkedBy}
              checkedDate={formValues.dateChecked}
            />
          )}

          <DropdownField
            name={nameOf("outcome")}
            label={Labels.TaskOutcome}
            options={inbox.ref.outcomes.keyTextValues}
            placeholder={Labels.Outcome}
            styles={{ root: { width: 250 } }}
          />
        </Fieldset>
      </>
    );
  });

export const ClinicalImageFormFields = withFetch(
  x => [
    x.inbox.ref.reportTypes.load(),
    x.inbox.ref.outcomes.load(),
    x.clinical.ref.imagingRegions.load(),
    x.clinical.ref.sidesOfBody.load()
  ],
  ClinicalImageFormFieldsBase
);
