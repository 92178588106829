import { observer } from "mobx-react-lite";
import React from "react";

import { Stack, TextBadge, TextBadgeColor } from "@bps/fluent-ui";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";

import { LocationStatusToggleButton } from "./LocationStatusToggleButton.tsx";

interface LocationStatusButtonsProps {
  locationId: string | undefined;
  locationOrgUnit: OrgUnit | undefined;
  pracOrgUnit: PracOrgUnit | undefined;
  hasAppointments: boolean | undefined;
  hasFutureWaitingListEntries: boolean | undefined;
  onReactivate: () => void;
}

export const LocationStatusButtons: React.FC<LocationStatusButtonsProps> =
  observer(
    ({
      locationId,
      locationOrgUnit,
      hasAppointments,
      pracOrgUnit,
      hasFutureWaitingListEntries,
      onReactivate
    }) => {
      const statusColor = locationOrgUnit?.isInactive
        ? TextBadgeColor.grey
        : TextBadgeColor.green;

      const getStatusText = () => {
        if (pracOrgUnit?.isDefault) {
          return "Default location";
        }
        if (locationOrgUnit?.isInactive) {
          return "Inactive location";
        }
        return "Active location";
      };

      return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextBadge badgeColor={statusColor} hasBorder={false}>
            {getStatusText()}
          </TextBadge>
          <LocationStatusToggleButton
            locationId={locationId}
            locationOrgUnit={locationOrgUnit}
            hasAppointments={hasAppointments}
            hasFutureWaitingListEntries={hasFutureWaitingListEntries}
            onReactivate={onReactivate}
          />
        </Stack>
      );
    }
  );
