import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import { AllocationFieldValues } from "../types.ts";

export interface ManualAllocationFormValues {
  allocations: AllocationFieldValues[];
  allocatableAmount: number;
}

export const manualAllocationFormNameOf =
  nameOfFactory<ManualAllocationFormValues>();
