import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { grcsFormNameOf, GRCSFormValues } from "./GRCSForm.types.ts";

export class GRCSFormValidator extends Validator<GRCSFormValues> {
  constructor() {
    super();
    this.forField(grcsFormNameOf("diagnosisKey"), required());
    this.forField(grcsFormNameOf("side"), required());
  }
}
