import { FunctionComponent } from "react";

import { MoreLessToggle } from "@bps/fluent-ui";

type MoreLessToggleTimeSlotsProps = {
  isExpanded: boolean;
  onChange: (value: boolean) => void;
  text: string;
};
export const MoreLessToggleTimeSlots: FunctionComponent<
  MoreLessToggleTimeSlotsProps
> = ({ isExpanded, onChange, text }) => {
  return (
    <MoreLessToggle
      value={!isExpanded}
      onChanged={onChange}
      linkProps={{
        moreStateText: text,
        lessStateText: "Less"
      }}
    />
  );
};
