import { useForm } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import {
  ActionButton,
  confirm,
  Heading,
  Stack,
  TextField
} from "@bps/fluent-ui";
import {
  CareRecipientDto,
  NO,
  YES
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { Labels } from "@modules/practice/screens/shared-components/types/labels.enums.types.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import {
  CARE_CONTACTS_MAX_WIDTH,
  CARE_CONTACTS_MIN_WIDTH,
  CARE_TYPES_MAX_WIDTH,
  CARE_TYPES_MIN_WIDTH,
  SocialFormValues
} from "./SocialFormValues.ts";

interface SocialCareRecipientListProps {
  options: { key: string; text: string }[];
}

export const emptyCareRecipient: CareRecipientDto[] = [
  { careRecipientTypes: [], assigned: false }
];

export const SocialCareRecipientList: React.FunctionComponent<
  SocialCareRecipientListProps
> = props => {
  const socialForm = useForm<SocialFormValues>();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const changeIsACarer = async () => {
    const { getState, change } = socialForm;
    const careRecipientsList =
      getState().values.patientDemographic.careRecipients?.map(p => ({
        ...p
      }));
    if (getState().values?.patientDemographic.isACarer === YES) {
      if (
        !careRecipientsList ||
        (careRecipientsList && careRecipientsList.length === 0)
      ) {
        addNewCareRecipient();
      }
    } else {
      if (careRecipientsList && careRecipientsList.length > 0) {
        if (
          careRecipientsList.filter(
            x => x.id || x.careRecipientTypes.length > 0
          )?.length > 0
        ) {
          if (
            await confirm({
              dialogContentProps: {
                title: "Remove care recipient record/s",
                subText:
                  "This action will remove all related records. Are you sure?"
              },
              confirmButtonProps: {
                text: "Confirm"
              },
              cancelButtonProps: {
                text: "Cancel"
              },
              minWidth: 500
            })
          ) {
            change(
              "patientDemographic.careRecipients" as keyof SocialFormValues,
              [] as any
            );
          } else {
            change(
              "patientDemographic.isACarer" as keyof SocialFormValues,
              YES as any
            );
          }
        } else {
          change(
            "patientDemographic.careRecipients" as keyof SocialFormValues,
            [] as any
          );
        }
      }
    }
  };

  const addNewCareRecipient = () => {
    const { mutators } = socialForm;
    mutators.push("patientDemographic.careRecipients", {
      careRecipientTypes: []
    } as CareRecipientDto);
  };

  const isCarer = () => {
    const { getState } = socialForm;
    return getState().values.patientDemographic.isACarer === YES;
  };

  const removeCareRecipientRow = (careRecipientRowNumber: number) => {
    const { getState, mutators, change } = socialForm;
    mutators.remove(
      "patientDemographic.careRecipients",
      careRecipientRowNumber
    );

    const careRecipientsList =
      getState().values.patientDemographic.careRecipients?.map(p => ({
        ...p
      }));
    if (
      !careRecipientsList ||
      (careRecipientsList && careRecipientsList.length <= 0)
    ) {
      change(
        "patientDemographic.isACarer" as keyof SocialFormValues,
        NO as any
      );
    }
  };

  return (
    <Fieldset>
      <FieldArray name="patientDemographic.careRecipients">
        {(fieldProps: FieldArrayRenderProps<CareRecipientDto, HTMLElement>) => {
          const careRecipientsList = fieldProps.fields.value;
          const careRecipients = careRecipientsList?.map(p => ({
            ...p,
            assigned: p.assigned ?? !!p?.id
          }));
          return (
            careRecipients &&
            careRecipients.length > 0 &&
            isCarer() && (
              <Stack tokens={{ childrenGap: 8 }}>
                {careRecipients.map((item: CareRecipientDto, index: number) => {
                  return (
                    <Stack key={`careRecipient${item.id}`} horizontal>
                      <Stack
                        styles={{
                          root: {
                            width: "100%",
                            maxWidth: CARE_CONTACTS_MAX_WIDTH,
                            minWidth: CARE_CONTACTS_MIN_WIDTH,
                            marginRight: 8
                          }
                        }}
                      >
                        <Heading
                          labelPaddings
                          styles={{
                            root: {
                              display: `${index > 0 ? "none" : "block"}`
                            }
                          }}
                        >
                          Care Recipient
                        </Heading>
                        <Stack
                          verticalAlign="end"
                          horizontal
                          styles={{
                            root: {
                              width: "100%",
                              maxWidth: CARE_CONTACTS_MAX_WIDTH,
                              minWidth: CARE_CONTACTS_MIN_WIDTH,
                              marginRight: 8
                            }
                          }}
                        >
                          <ToggleField
                            name={`patientDemographic.careRecipients[${index}].assigned`}
                            styles={{ root: { marginRight: 8 } }}
                          />
                          {item.assigned ? (
                            <ContactPickerFieldWithAdd
                              name={`patientDemographic.careRecipients[${index}].id`}
                              quickAddProps={{
                                addButtonLabel: Labels.newPerson
                              }}
                              filter={{
                                types: [
                                  ContactType.Individual,
                                  ContactType.Patient
                                ]
                              }}
                              placeholder="Search for a person"
                              wrapperStyles={{
                                root: {
                                  flexGrow: 1,
                                  minWidth: `${CARE_CONTACTS_MIN_WIDTH - 40}`,
                                  maxWidth: `${CARE_CONTACTS_MAX_WIDTH - 40}`
                                }
                              }}
                              tagInUseKeys={careRecipients?.map(v => v.id!)}
                            />
                          ) : (
                            <TextField
                              placeholder="Care recipient not assigned"
                              disabled
                              styles={{
                                wrapper: {
                                  minheight: 32,
                                  flexGrow: 1
                                }
                              }}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Stack
                        styles={{
                          root: {
                            width: "100%",
                            minWidth: CARE_TYPES_MIN_WIDTH,
                            maxWidth: CARE_TYPES_MAX_WIDTH
                          }
                        }}
                      >
                        <Heading
                          labelPaddings
                          styles={{
                            root: {
                              display: `${index > 0 ? "none" : "block"}`
                            }
                          }}
                        >
                          Type of care
                        </Heading>
                        <Stack
                          verticalAlign="end"
                          horizontal
                          styles={{
                            root: {
                              marginRight: 8
                            }
                          }}
                        >
                          <DropdownField
                            multiSelect
                            options={props.options}
                            name={`patientDemographic.careRecipients[${index}].careRecipientTypes`}
                            fieldItemStyles={{
                              root: {
                                marginRight: 8,
                                width: "100%"
                              }
                            }}
                          />
                          {!isViewOnlyOrDischarged && (
                            <DeleteButton
                              onClick={() => removeCareRecipientRow(index)}
                              styles={{
                                icon: {
                                  paddingBottom: 8
                                }
                              }}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
                {!isViewOnlyOrDischarged && (
                  <ActionButton
                    iconProps={{ iconName: "Add" }}
                    title="Add more"
                    onClick={addNewCareRecipient}
                    styles={{ root: { width: 400 } }}
                  >
                    Add another care recipient
                  </ActionButton>
                )}
              </Stack>
            )
          );
        }}
      </FieldArray>
      <FieldSpy name="patientDemographic.isACarer" onChange={changeIsACarer} />
    </Fieldset>
  );
};
