import { FC, memo, useEffect, useRef } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontSizes,
  ITextField,
  Link,
  Stack
} from "@bps/fluent-ui";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { PhoneTextField } from "@ui-components/form/PhoneTextField.tsx";
import {
  TextFieldActionButtonProps,
  TextInputField
} from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { SendFormCheckbox } from "./SendFormCheckbox.tsx";

interface CommunicationFieldProps {
  value: CommunicationFieldValue;
  onRemove: () => void;
  index: number;
  name: string;
  required?: boolean;
  disableRemoveLink?: boolean;
  hideSendForm?: boolean;
  disablePreferredChoice?: boolean;
  onChangePreferred: (value: boolean) => void;
}

export const CommunicationField: FC<CommunicationFieldProps> = memo(
  ({
    name,
    index,
    value,
    required,
    onRemove,
    disableRemoveLink,
    hideSendForm,
    onChangePreferred,
    disablePreferredChoice
  }) => {
    const { core } = useStores();

    const communicationRef = useRef<ITextField | null>(null);
    useEffect(() => {
      if (communicationRef.current && value.isNew) {
        communicationRef.current.focus();
      }
    }, [value.isNew]);

    const actionButtonProp: TextFieldActionButtonProps | undefined =
      !disableRemoveLink
        ? {
            linkProps: { onClick: () => onRemove(), children: Labels.remove }
          }
        : undefined;

    return (
      <Fieldset
        frame
        horizontalAlign="space-between"
        verticalAlign="end"
        tokens={{ childrenGap: 16 }}
        styles={{ root: { marginBottom: 16 } }}
      >
        <Stack grow horizontal tokens={{ childrenGap: 16 }}>
          {value.type !== CommunicationType.Email &&
          value.type !== CommunicationType.Fax &&
          value.type !== CommunicationType.Website ? (
            <PhoneTextField
              componentRef={(ref: ITextField) => {
                communicationRef.current = ref;
              }}
              label={core.ref.contactCommTypes.get(value.type)?.text}
              required={required}
              styles={{
                root: {
                  width: "100%"
                }
              }}
              maxLength={15}
              name={`${name}[${index}].value`}
            />
          ) : (
            <TextInputField
              componentRef={ref => {
                communicationRef.current = ref;
              }}
              required={required}
              label={core.ref.contactCommTypes.get(value.type)?.text}
              actionButton={actionButtonProp}
              styles={{ root: { width: "100%" } }}
              name={`${name}[${index}].value`}
            />
          )}
          {!disablePreferredChoice &&
            value.type !== CommunicationType.Email &&
            value.type !== CommunicationType.Fax &&
            value.type !== CommunicationType.Website && (
              <Stack tokens={{ childrenGap: 8 }}>
                <Link
                  id={`remove-comm-pref-${index}`}
                  {...dataAttribute(
                    DataAttributes.Element,
                    "form-item-link-mobile-phone-remove"
                  )}
                  onClick={onRemove}
                  styles={{
                    root: {
                      textAlign: "right",
                      fontSize: FontSizes.small,
                      padding: "5px 0"
                    }
                  }}
                >
                  {Labels.remove}
                </Link>

                <ToggleField
                  id={`comm-pref-toggle-${index}`}
                  className="communications-toggle"
                  name={`${name}[${index}].preferred`}
                  onText={Labels.preferred}
                  offText={Labels.preferred}
                  styles={{
                    root: { marginBottom: 4 },
                    label: index !== 0 && { visibility: "hidden" }
                  }}
                />
                <FieldSpy
                  name={`${name}[${index}].preferred`}
                  onChange={(value: boolean) => onChangePreferred(value)}
                />
              </Stack>
            )}
        </Stack>
        {!hideSendForm && <SendFormCheckbox {...value} />}
      </Fieldset>
    );
  }
);
