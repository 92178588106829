import { FormApi } from "final-form";
import { useMemo } from "react";

import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { AreasToObserveKeys } from "@shared-types/clinical/areas-to-observe-keys.type.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { StashedEncounterClinicalData } from "@stores/clinical/models/StashedEncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { StashedClinicalDataFormSpy } from "../../StashedClinicalDataFormSpy.tsx";
import { ClinicalSubmissionForm } from "../clinical-form/ClinicalSubmissionForm.tsx";
import { IntakeFormProps, IntakeFormValues } from "./IntakeForm.types.ts";
import { IntakeFormContent } from "./IntakeFormContent.tsx";
import { getInitialValues } from "./utils.ts";

export const intakeConfirmed = (
  clinicalRecord: ClinicalRecord,
  domain: ClinicalDataType
) => {
  const alcoholConfirmed =
    clinicalRecord.stashedClinicalData?.originalDto?.alcoholConfirmed;

  const tobaccoConfirmed =
    clinicalRecord.stashedClinicalData?.originalDto?.tobaccoConfirmed;

  const substanceUseConfirmed =
    clinicalRecord.stashedClinicalData?.originalDto?.substanceUseConfirmed;

  const { openEncounter } = clinicalRecord;

  let isIntakeConfirmed = false;
  if (openEncounter?.id) {
    if (
      domain === ClinicalDataType.Alcohol &&
      alcoholConfirmed?.createLog?.createdEncounterId === openEncounter?.id
    ) {
      isIntakeConfirmed = true;
    } else if (
      domain === ClinicalDataType.Tobacco &&
      tobaccoConfirmed?.createLog?.createdEncounterId === openEncounter?.id
    ) {
      isIntakeConfirmed = true;
    } else {
      if (
        substanceUseConfirmed?.createLog?.createdEncounterId ===
        openEncounter?.id
      )
        isIntakeConfirmed = true;
    }
  }
  return isIntakeConfirmed;
};

export const IntakeFormBase: React.FunctionComponent<IntakeFormProps> = (
  props: IntakeFormProps
) => {
  const {
    domain,
    maxIntakes,
    onSubmit,
    clinicalRecord,
    validate,
    formTitle,
    getData
  } = props;

  const { clinical, notification } = useStores();
  const { isViewOnly } = usePatientRecordScreenContext();

  const getLastUpdatedDate = () => {
    if (domain === ClinicalDataType.Alcohol) {
      return getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.alcoholConfirmed
      );
    } else if (domain === ClinicalDataType.Tobacco) {
      return getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.tobaccoConfirmed
      );
    } else {
      return getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.substanceUseConfirmed
      );
    }
  };

  const getLastUpdatedUserId = () => {
    if (domain === ClinicalDataType.Alcohol) {
      return getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.alcoholConfirmed
      );
    } else if (domain === ClinicalDataType.Tobacco) {
      return getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.tobaccoConfirmed
      );
    } else {
      return getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.substanceUseConfirmed
      );
    }
  };

  const area: AreasToObserveKeys = useMemo(() => {
    switch (domain) {
      case ClinicalDataType.Alcohol:
        return "alcohol";
      case ClinicalDataType.SubstanceUse:
        return "substanceUse";
      case ClinicalDataType.Tobacco:
        return "tobacco";
    }
  }, [domain]);

  const onCancel = (): void =>
    clinical.ui.closePatientRecordContentForm(clinicalRecord.id, domain);

  const discardFormChanges = () => {
    onCancel();
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData([area]);
  };

  const intake = useMemo(() => {
    if (domain === ClinicalDataType.Alcohol) {
      return clinicalRecord.stashedClinicalData?.alcohol;
    } else if (domain === ClinicalDataType.Tobacco) {
      return clinicalRecord.stashedClinicalData?.tobacco;
    } else {
      return clinicalRecord.stashedClinicalData?.substanceUse;
    }
  }, [
    clinicalRecord.stashedClinicalData?.alcohol,
    clinicalRecord.stashedClinicalData?.substanceUse,
    clinicalRecord.stashedClinicalData?.tobacco,
    domain
  ]);

  const isInitialIntake = useMemo(() => {
    if (domain === ClinicalDataType.Alcohol) {
      return !clinicalRecord.clinicalData?.alcohol?.createLog;
    } else if (domain === ClinicalDataType.Tobacco) {
      return !clinicalRecord.clinicalData?.tobacco?.createLog;
    } else {
      return !clinicalRecord.clinicalData?.substanceUse?.createLog;
    }
  }, [
    clinicalRecord.clinicalData?.alcohol,
    clinicalRecord.clinicalData?.substanceUse,
    clinicalRecord.clinicalData?.tobacco,
    domain
  ]);

  const initialValues = getInitialValues({
    intakeConfirmed: intakeConfirmed(clinicalRecord, domain),
    intake,
    domain,
    dateOfBirth: clinicalRecord.patient?.birthDate
  });

  const areasToObserve = useMemo(() => {
    switch (domain) {
      case ClinicalDataType.Alcohol:
        return { alcohol: [ClinicalDataType.Alcohol] };
      case ClinicalDataType.SubstanceUse:
        return { substanceUse: [ClinicalDataType.SubstanceUse] };
      case ClinicalDataType.Tobacco:
        return { tobacco: [ClinicalDataType.Tobacco] };
    }
  }, [domain]);

  const onSubmitSucceeded = (
    values: IntakeFormValues,
    form: FormApi<IntakeFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: domain
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      discardFormChanges();
    }
  };

  const getValues = (stashedData?: StashedEncounterClinicalData) => {
    const dirty = stashedData?.dirtyAreas
      ? !!stashedData?.dirtyAreas.get(area)
      : false;

    const alreadyConfirmed = intakeConfirmed(clinicalRecord, domain);

    return { dirty, alreadyConfirmed };
  };

  const getButtonText = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, alreadyConfirmed } = getValues(stashedData);

    const submissionButtonText =
      isInitialIntake || alreadyConfirmed || (!alreadyConfirmed && dirty)
        ? "Save"
        : "Confirm";

    return submissionButtonText;
  };

  const isButtonDisabled = (stashedData?: StashedEncounterClinicalData) => {
    const { dirty, alreadyConfirmed } = getValues(stashedData);

    return (isInitialIntake || alreadyConfirmed) && !dirty;
  };

  return (
    <ClinicalSubmissionForm<IntakeFormValues>
      formName="intake"
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues}
      readOnly={isViewOnly}
      validate={validate}
      disableRoutePrompt
      heading={formTitle}
      onCancel={discardFormChanges}
      extraPromptConditionOnCancel={() =>
        clinicalRecord.stashedClinicalData?.dirtyAreas
          ? !!clinicalRecord.stashedClinicalData?.dirtyAreas.get(area)
          : false
      }
      disableButtonCondition={stashedData => isButtonDisabled(stashedData)}
      submitButtonTextCondition={stashedData => getButtonText(stashedData)}
      lastUpdatedDate={getLastUpdatedDate()}
      lastUpdatedUserId={getLastUpdatedUserId()}
      hideSubmit={isViewOnly}
      noGap
      hasSeparator
    >
      <>
        <StashedClinicalDataFormSpy<IntakeFormValues>
          clinicalRecord={clinicalRecord}
          getData={getData}
          areasToObserve={areasToObserve}
        />

        <IntakeFormContent
          domain={domain}
          clinicalRecord={clinicalRecord}
          maxIntakes={maxIntakes}
        />
      </>
    </ClinicalSubmissionForm>
  );
};

export const IntakeForm = withFetch(
  x => [
    x.clinical.ref.intakeStatuses.load(),
    x.clinical.ref.intakeTypes.load(),
    x.clinical.ref.intakeFrequencies.load(),
    x.clinical.ref.cessationAdvices.load(),
    x.clinical.ref.intakeUnits.load()
  ],
  IntakeFormBase
);
