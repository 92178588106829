import { observer } from "mobx-react-lite";
import React from "react";

import { IFontStyles, IStyle, ITextStyles, Stack, Text } from "@bps/fluent-ui";
import { CodedText } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

interface TerminologyTextComponentProps {
  codedTexts?: CodedText[];
  id?: string;
  variant?: keyof IFontStyles;
  nowrap?: boolean;
  block?: boolean;
  styles?: ITextStyles;
}

export const TerminologyText: React.FunctionComponent<TerminologyTextComponentProps> =
  observer(({ codedTexts, id, variant, nowrap, block, styles }) => {
    const TextStyles = block
      ? {
          root: {
            display: "block",
            overflow: "hidden"
          } as IStyle
        }
      : undefined;

    return (
      <Stack styles={TextStyles}>
        {codedTexts?.map((codableText, index) => {
          const text =
            index > 0 && block ? `, ${codableText.text}` : codableText.text;
          return (
            <Text
              variant={variant}
              key={`${id}-${codableText.code}`}
              nowrap={nowrap}
              styles={styles}
            >
              {text}
            </Text>
          );
        })}
      </Stack>
    );
  });
