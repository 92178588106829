import { observer } from "mobx-react-lite";

import {
  Heading,
  IChoiceGroupOption,
  IChoiceGroupOptionStyles,
  IDropdownOption,
  Separator,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  PastVisitViewOptions,
  TreeView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { UserLabels } from "../../labels.ts";
import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import rvBottom from "./assets/RVBottom.svg";
import rvTop from "./assets/RVTop.svg";
import { UserClinicalFormLabel } from "./UserClinicalFormLabel.tsx";
import { UserClinicalViewFormValues } from "./UserClinicalViewForm.types.ts";

interface TreeViewSettingsFromProps {
  initialValues: Partial<UserClinicalViewFormValues>;
  onSubmit: (values: UserClinicalViewFormValues) => void;
}

const TreeViewSettingsFormBase: React.FC<TreeViewSettingsFromProps> = observer(
  ({ initialValues, onSubmit }) => {
    const { core, userExperience, notification } = useStores();

    const theme = useTheme();
    const { formFooter, formFields } = getUserStylesSet(theme);

    const imageStyles: IChoiceGroupOptionStyles = {
      labelWrapper: { height: 20, margin: 0 },
      root: {
        backgroundColor: theme.palette.white,
        width: "calc(50% - 4px)"
      },
      field: {
        width: "100%",
        height: "100%",
        backgroundColor: theme.semanticColors.disabledBackground,
        opacity: 1
      },
      imageWrapper: { opacity: 1 }
    };

    const pastVisitOptions: IChoiceGroupOption[] = [
      {
        key: PastVisitViewOptions.PastVisitTop,
        text: "",
        imageSrc: rvTop,
        imageSize: { width: 250, height: 80 },
        styles: imageStyles
      },
      {
        key: PastVisitViewOptions.PastVisitBottom,
        text: "",
        imageSrc: rvBottom,
        imageSize: { width: 250, height: 80 },
        styles: imageStyles
      }
    ];

    const filterTreeviewOptions = (): IDropdownOption[] => {
      const treeViewsFilteredOnPermission =
        userExperience.ref.treeViewOptions.values?.filter(x => {
          if (core.isNZTenant && x.code === TreeView.HistoryExamination) {
            return false;
          }
          return x.permission ? core.hasPermissions(x.permission) : true;
        });

      return treeViewsFilteredOnPermission.map(
        x =>
          ({
            key: x.code,
            text: x.text
          }) as IDropdownOption
      );
    };

    return (
      <SubmissionForm
        formName="tree-view-settings"
        styles={{ main: { borderRadius: 4 } }}
        initialValues={initialValues}
        onSubmitSucceeded={() => {
          notification.success("Clinical settings tree view has been updated");
        }}
        onSubmit={onSubmit}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {() => (
          <Fieldset
            styles={{
              root: formFields
            }}
          >
            <Stack>
              <UserClinicalFormLabel
                label={UserLabels.defaultTreeView}
                description={UserLabels.treeViewControlInfo}
              />
              <DropdownField
                className="treeViewDropDown"
                name={nameof("selectedTreeView")}
                options={filterTreeviewOptions()}
                styles={{
                  root: {
                    width: "100%"
                  }
                }}
              />
            </Stack>
            <Separator />
            <Stack>
              <Heading
                variant="section-heading-light"
                styles={{ root: { marginBottom: 16 } }}
              >
                Past consults
              </Heading>
              <UserClinicalFormLabel
                label={UserLabels.rowArrangement}
                description={UserLabels.rowArrangementInfo}
              />
              <ChoiceGroupField
                options={pastVisitOptions}
                name={nameof("pastVisitsReasonViewOption")}
              />
            </Stack>
          </Fieldset>
        )}
      </SubmissionForm>
    );
  }
);

export const TreeViewSettingsForm = withFetch(
  x => [x.userExperience.ref.treeViewOptions.load()],
  TreeViewSettingsFormBase
);
