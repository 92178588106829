import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  maxArrayLength,
  predicate,
  requiredArrayLength
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ReferralDetailValuesType } from "@shared-types/acc/referral-detail-values.type.ts";
import { ReferralsOutFormValues } from "@shared-types/acc/referrals-out-values.type.ts";

import { ReferralDetailFormValidator } from "./ReferralDetailFormValidator.tsx";

export class ReferralsOutFormValidator extends Validator<ReferralsOutFormValues> {
  constructor() {
    super();

    const referralDetailFormValidator = new ReferralDetailFormValidator();

    this.forArrayField(
      nameof("referralDetails"),
      referralDetailFormValidator.validate
    );

    this.forField(
      nameof("referralDetails"),
      predicate(
        (items: ReferralDetailValuesType[], parent: ReferralsOutFormValues) =>
          !!parent.enableReferralsOut,
        requiredArrayLength(1)
      )
    );

    this.forField(nameof("referralDetails"), [
      maxArrayLength(5, "Must not exceed 5 referrals.")
    ]);
  }
}
