import { IStackStyles } from "@bps/fluent-ui";

import { AppointmentFilterStatus } from "./AppointmentFilter.types.ts";

export const getSelectedFilterStyles = (
  status: AppointmentFilterStatus,
  selectedFilter: AppointmentFilterStatus | undefined
): IStackStyles => {
  return {
    root: {
      selectors: {
        "& > *": {
          fontStyle: selectedFilter === status ? "italic" : "normal"
        },
        "& > *:not(button)": {
          fontWeight: selectedFilter === status ? "bold" : "normal"
        }
      }
    }
  };
};

export const getLinkText = (
  count: number,
  status: AppointmentFilterStatus,
  selectedFilter: AppointmentFilterStatus | undefined
) => `${count} (${status === selectedFilter ? "Close" : "View"})`;
