import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import {
  FontSizes,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  Tile
} from "@bps/fluent-ui";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DocumentEditor,
  DocumentEditorContainer
} from "@syncfusion/ej2-react-documenteditor";
import { SyncFusionWordProcessorField } from "@ui-components/form/SyncFusionWordProcessorField.tsx";
import { AutofillContext } from "@ui-components/text-editors/editor-autofills/editor-autofill.types.ts";

import {
  MEDIUM_TREE_VIEW_WIDTH,
  SMALL_TREE_VIEW_WIDTH
} from "../../../patient-record/types/tree-widths.constants.ts";
import { SyncFusionDocumentEditorContext } from "./context/SyncFusionDocumentEditorContext.tsx";
import { SyncFusionDocumentEditorHelper } from "./context/SyncFusionDocumentEditorHelper.ts";
import { SyncFusionDocumentEditorSidePanel } from "./SyncFusionDocumentEditorSidePanel.tsx";

interface SyncFusionDocumentEditorProps
  extends Pick<DocumentEditorContainer, "height"> {
  docEditor: DocumentEditor | undefined;
  patientId?: string;
  encounterId: string | undefined;
  setDocumentEditor: (documentEditor: DocumentEditor) => void;
  headerWithCTA: React.ReactNode;
  isTemplateEditor?: boolean;
  contactId?: string;
  onMessageBarLinkClick?: () => void;
  showMessageBar: boolean;
  disableLink?: boolean;
}

export const SyncFusionDocumentEditor: React.FC<SyncFusionDocumentEditorProps> =
  observer(
    ({
      docEditor,
      patientId,
      encounterId,
      contactId,
      setDocumentEditor,
      height,
      headerWithCTA,
      isTemplateEditor,
      onMessageBarLinkClick,
      showMessageBar,
      disableLink
    }) => {
      const root = useStores();
      const { core } = root;
      const helper = useRef(
        new SyncFusionDocumentEditorHelper(root, isTemplateEditor)
      );

      const sidePanelSize = helper.current.sidePanelSize;
      helper.current.documentEditor = docEditor;

      helper.current.setContextOption({
        patientId,
        userId: core.userId,
        encounterId,
        contactId,
        orgUnitId: core.location.parentOrgUnit?.id
      });

      const getAutofillContext = () => {
        const autofillContext: AutofillContext = {
          UserId: core.userId
        };

        if (patientId) {
          autofillContext["PatientId"] = patientId;
        }

        if (contactId) {
          autofillContext["ContactId"] = contactId;
        }

        if (encounterId) {
          autofillContext["EncounterId"] = encounterId;
        }

        if (core.location.parentOrgUnit?.id) {
          autofillContext["PracticeOrgUnitId"] =
            core.location.parentOrgUnit?.id;
        }

        return autofillContext;
      };

      const autofillContext = getAutofillContext();

      useEffect(() => {
        docEditor?.resize();
      }, [sidePanelSize, docEditor]);

      const onClick = () => {
        if (onMessageBarLinkClick) {
          onMessageBarLinkClick();
        }
      };

      return (
        <>
          <Tile
            styles={{
              root: {
                width: `calc(100% - ${
                  sidePanelSize === TreeViewSize.Default
                    ? MEDIUM_TREE_VIEW_WIDTH + 16
                    : SMALL_TREE_VIEW_WIDTH + 16
                }px)`,
                padding: 16,
                marginRight: 16,
                flexGrow: 1
              }
            }}
          >
            {headerWithCTA}
            {showMessageBar && isTemplateEditor && (
              <MessageBar
                messageBarType={MessageBarType.warning}
                styles={{
                  root: { zIndex: 1 },
                  innerText: {
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    span: {
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "space-between"
                    }
                  }
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  styles={{ root: { width: "100%" } }}
                >
                  <Text styles={{ root: { fontSize: FontSizes.size12 } }}>
                    This template uses the practice letterhead
                  </Text>
                  <Link
                    onClick={() => {
                      onClick();
                    }}
                    disabled={disableLink}
                  >
                    Preview
                  </Link>
                </Stack>
              </MessageBar>
            )}

            <SyncFusionWordProcessorField
              height={height}
              name="documentContent"
              ref={r => {
                if (!docEditor && r?.documentEditor) {
                  setDocumentEditor(r.documentEditor);
                }
              }}
              autofillContext={autofillContext}
            />
          </Tile>

          <Tile
            styles={{
              root: {
                padding: 0,
                minWidth:
                  sidePanelSize === TreeViewSize.Default
                    ? MEDIUM_TREE_VIEW_WIDTH
                    : SMALL_TREE_VIEW_WIDTH
              }
            }}
          >
            <SyncFusionDocumentEditorContext.Provider value={helper.current}>
              <SyncFusionDocumentEditorSidePanel
                autofillContext={autofillContext}
                docEditor={docEditor}
              />
            </SyncFusionDocumentEditorContext.Provider>
          </Tile>
        </>
      );
    }
  );
