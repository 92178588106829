import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import {
  AddBox,
  confirm,
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  Heading,
  Link,
  Spinner,
  Stack,
  TextBadge,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  AddHealthCertificateDto,
  HealthCertificateDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { PracticeAccContractsStyles } from "../practice-acc-contracts/PracticeAccContracts.styles.tsx";
import { HealthCertificateValues } from "./HealthCertificate.types.tsx";
import { HealthCertificateUploadFormFields } from "./HealthCertificateUploadFormFields.tsx";
import { HealthCertificateValidator } from "./HealthCertificateValidator.tsx";

const isExpired = (expiryDate: DateTime) => {
  const today = DateTime.now();

  return expiryDate <= today;
};

const HealthCertificateUpload: React.FC = observer(() => {
  const { core } = useStores();
  const theme = useTheme();
  const [hideDialog, setHideDialog] = useState(true);
  const [hasBeenSaved, setHasBeenSaved] = useState<boolean>(false);

  useEffect(() => {
    // reset hasBeenSaved on hideDialog if true
    if (hideDialog && hasBeenSaved) {
      setHasBeenSaved(false);
    }
  }, [hasBeenSaved, hideDialog]);

  const toggleHideDialog = () => setHideDialog(prev => !prev);
  const { acc, notification } = useStores();
  const initialValues = useRef<HealthCertificateValues>({
    certificateName: "",
    certificateFile: "",
    certificatePassword: ""
  });

  const onSubmit = async (
    values: HealthCertificateValues,
    healthCertificate?: HealthCertificateDto
  ) => {
    const addHealthCertificateDto: AddHealthCertificateDto = {
      certificateName: values.certificateName!,
      certificatePassword: values.certificatePassword!,
      certificateFile: values.certificateFile!
    };
    !!healthCertificate
      ? await acc.updateHealthCertificate(addHealthCertificateDto)
      : await acc.addHealthCertificate(addHealthCertificateDto);
  };

  const onDelete = async (healthCertificate: HealthCertificateDto) => {
    if (
      healthCertificate &&
      (await confirm({
        confirmButtonProps: {
          text: "Delete certificate",
          ...dataAttribute(
            DataAttributes.Element,
            "confirm-delete-certificate-button"
          )
        },
        cancelButtonProps: {
          text: "Cancel"
        },
        dialogContentProps: {
          title: "Confirm certificate deletion",
          subText: "Do you want to delete the health secure certificate?"
        }
      }))
    ) {
      await acc.deleteHealthCertificate(healthCertificate.id.toString());
      setHasBeenSaved(true);
    }
  };

  const onSubmitSucceeded = () => {
    notification.success("Certificate has been uploaded.");
    // set hasBeenSaved to prefetch updated healthCertificate
    setHasBeenSaved(true);
    toggleHideDialog();
  };
  return (
    <DataFetcher
      refetchId={`healthCertificate${hasBeenSaved}`}
      fetch={({ acc }) => acc.getHealthCertificate()}
      fallback={<Spinner />}
    >
      {healthCertificate => {
        const certificateExpired =
          healthCertificate?.certificateExpiryDate &&
          isExpired(DateTime.fromISO(healthCertificate.certificateExpiryDate));

        return (
          <div>
            {healthCertificate ? (
              <>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <Heading labelPaddings>
                    {SettingsLabels.healthCertificate}
                  </Heading>
                  <Link
                    {...dataAttribute(
                      DataAttributes.Element,
                      "renew-certificate-button"
                    )}
                    text="Renew certificate"
                    onClick={toggleHideDialog}
                  >
                    Renew
                  </Link>
                </Stack>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  tokens={{ childrenGap: 10 }}
                >
                  <Stack
                    grow
                    {...dataAttribute(
                      DataAttributes.Element,
                      "active-certificate"
                    )}
                    styles={PracticeAccContractsStyles.contractTypeItem}
                  >
                    <Stack horizontal horizontalAlign="space-between">
                      <Stack horizontal verticalAlign="center">
                        <FontIcon
                          iconName={certificateExpired ? "Info" : "Completed"}
                          styles={{
                            root: {
                              color: certificateExpired
                                ? theme.semanticColors.errorIcon
                                : theme.palette.green,
                              paddingRight: 6,
                              fontSize: FontSizes.size16
                            }
                          }}
                        />
                        Active certificate
                      </Stack>

                      <TextBadge
                        {...dataAttribute(
                          DataAttributes.Element,
                          "certificate-expiry-date"
                        )}
                        styles={{
                          root: {
                            borderRadius: 2,
                            backgroundColor: certificateExpired
                              ? theme.semanticColors.errorBackground
                              : theme.palette.neutralTertiary,
                            color: certificateExpired
                              ? theme.semanticColors.errorText
                              : theme.palette.white
                          }
                        }}
                      >
                        {`Expire${
                          certificateExpired ? "d" : "s"
                        } ${DateTime.fromISO(
                          healthCertificate.certificateExpiryDate
                        ).toDayDefaultFormat()}`}
                      </TextBadge>
                    </Stack>
                  </Stack>
                  <Stack
                    verticalAlign="center"
                    styles={{ root: { paddingRight: 8 } }}
                  >
                    <DeleteButton
                      onClick={async () => await onDelete(healthCertificate)}
                      {...dataAttribute(
                        DataAttributes.Element,
                        "delete-certificate-button"
                      )}
                    />
                  </Stack>
                </Stack>
              </>
            ) : (
              <AddBox
                text="No health certificate on file"
                subText="You are required to upload a valid health secure digital certificate for ACC purposes"
                buttonText="Add certificate"
                onClick={toggleHideDialog}
                {...dataAttribute(
                  DataAttributes.Element,
                  "add-certificate-button"
                )}
              />
            )}
            {!hideDialog && (
              <SubmissionFormDialog<HealthCertificateValues>
                dialogName="Upload health secure digital certificate"
                readOnly={!core.hasPermissions(Permission.OrgUnitSettingWrite)}
                initialValues={initialValues.current}
                onSubmitSucceeded={onSubmitSucceeded}
                validate={values =>
                  new HealthCertificateValidator().validate(values)
                }
                onSubmit={values => onSubmit(values, healthCertificate)}
                buttonsProps={{
                  submitButtonProps: {
                    text: "Upload",
                    id: "submit-health-certificate"
                  },
                  hideButtonsSeparator: true
                }}
                dialogProps={{
                  onDismiss: toggleHideDialog,
                  modalProps: {
                    layerProps: {
                      eventBubblingEnabled: true
                    }
                  },
                  minWidth: 600,
                  maxWidth: 600,
                  dialogContentProps: {
                    title: (
                      <Heading variant="modal-heading">
                        Upload health secure digital certificate
                      </Heading>
                    )
                  }
                }}
                component={HealthCertificateUploadFormFields}
              />
            )}
          </div>
        );
      }}
    </DataFetcher>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default HealthCertificateUpload;
