import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { ApiKeyModalFormValues } from "./ApiKeyModal.tsx";

export class ApiKeyModalValidator extends Validator<ApiKeyModalFormValues> {
  constructor() {
    super();
    this.forField("providerIds", [required()]);
  }
}
