import { useField, useForm } from "react-final-form";

import {
  Heading,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import {
  PaymentMethod,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { sum } from "@libs/utils/utils.ts";
import { AllocationFormLabels } from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import {
  AllocationFieldValues,
  ItemSelectionMode
} from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { useAllocationField } from "../../allocation-field/hooks/useAllocationField.ts";
import { useAllocationFormContext } from "../context/AllocationFormContext.ts";
import {
  AllocationFormValues,
  allocationNameOf
} from "./AllocationForm.types.ts";

export interface AllocationOption {
  itemMaxTotalKey?: keyof Pick<AllocationFieldValues, "owing" | "itemTotal">;
  text: string;
  key: string;
  itemSelectionMode?: ItemSelectionMode;
}

interface AllocationOptionsChoiceFieldProps {
  heading?: string;
}

export const AllocationOptionsChoiceField: React.FC<
  AllocationOptionsChoiceFieldProps
> = ({ heading }) => {
  const { allocationOptions, onAllocationOptionsChanged, showMessageBar } =
    useAllocationFormContext();

  const { autoAllocateItems } = useAllocationField();

  const form = useForm<AllocationFormValues>();
  const {
    input: { value: allocations }
  } = useField<AllocationFieldValues[]>(allocationNameOf("allocations"), {
    subscription: { value: true }
  });

  const getCreditMessageBarJSX = (allocations: AllocationFieldValues[]) => {
    const checkedAllocations = allocations.filter(
      allocation => allocation.checked
    );

    const paidInvoices = checkedAllocations.every(
      allocation => allocation.paymentStatus === PaymentStatuses.paid
    );

    const unpaidInvoices = checkedAllocations.every(
      allocation => allocation.paymentStatus === PaymentStatuses.unpaid
    );

    if (paidInvoices) {
      return (
        <Text variant="small">
          Paid items can be credited to account or refunded
        </Text>
      );
    } else if (unpaidInvoices) {
      return <Text variant="small">Unpaid items will be voided</Text>;
    } else {
      return (
        <>
          <Text block variant="small">
            Paid items can be credited to account or refunded
          </Text>
          <Text variant="small">Unpaid items will be voided</Text>
        </>
      );
    }
  };

  return (
    <Stack>
      <Heading labelPaddings>
        {heading ? heading : AllocationFormLabels.allocationAction}
      </Heading>
      <>
        <ButtonsGroupSingleChoiceField
          notUnselectable
          options={allocationOptions}
          name={allocationNameOf("allocationOption")}
        />
        {allocations.some(allocation => allocation.checked) &&
          showMessageBar && (
            <MessageBar
              isMultiline
              messageBarType={MessageBarType.warning}
              styles={{ root: { marginTop: 8 } }}
            >
              {getCreditMessageBarJSX(allocations)}
            </MessageBar>
          )}

        <FieldSpy
          name={allocationNameOf("allocationOption")}
          onChange={(value, currentFormValues) => {
            let selectedAmountTotal = 0;

            const selectedOption = allocationOptions.find(x => x.key === value);

            //Each option can have it's own allocations and default payment values
            const newFormValues =
              onAllocationOptionsChanged && onAllocationOptionsChanged(value);

            const newAllocationsFormValues =
              newFormValues?.allocations ?? currentFormValues?.allocations;

            let newPaymentsValues = newFormValues?.payments;

            if (!newPaymentsValues) {
              if (
                selectedOption?.itemSelectionMode === ItemSelectionMode.none
              ) {
                selectedAmountTotal = sum(
                  selectedOption?.itemMaxTotalKey || "owing",
                  newAllocationsFormValues || []
                );
              }

              newPaymentsValues = [
                {
                  id: newGuid(),
                  amount: selectedAmountTotal,
                  method: PaymentMethod.Eftpos
                }
              ];
            }

            //avoid needless animation flickering by just updating the values.
            for (let i = 0; i < currentFormValues?.payments.length; i++) {
              if (newPaymentsValues[i]) {
                newPaymentsValues[i].id = currentFormValues?.payments[i].id;
              } else {
                break;
              }
            }

            form.batch(() => {
              form.change(allocationNameOf("payments"), newPaymentsValues);
              autoAllocateItems(selectedAmountTotal, newAllocationsFormValues);
            });
          }}
        />
      </>
    </Stack>
  );
};
