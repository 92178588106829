import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  FontIcon,
  FontSizes,
  FontWeights,
  ITextStyles,
  noWrap,
  PillButton,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { CalendarEventReminderStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useEventReminderScreenContext } from "@modules/booking/screens/event-reminders/context/EventReminderScreenContext.tsx";

import { EventReminderStateItem } from "../EventReminderScreen.types.ts";
import { getEventReminderStatusColours } from "./utils.tsx";

interface EventReminderStatusPillProps {
  tab: EventReminderStateItem;
}
export const EventReminderStatusPill: FunctionComponent<EventReminderStatusPillProps> =
  observer(({ tab }) => {
    const { activeReminderState, setEventReminderState } =
      useEventReminderScreenContext();

    const theme = useTheme();

    const eventReminderStatusColors = getEventReminderStatusColours(theme);

    const tabLabelStyle: ITextStyles = {
      root: {
        margin: 0,
        color: theme.palette.neutralSecondary,
        fontSize: FontSizes.size12
      }
    };

    const tabCountStyle: ITextStyles = {
      root: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold
      }
    };

    return (
      <PillButton
        tokens={{ childrenGap: 8 }}
        active={tab.state === activeReminderState?.state}
        styles={{
          root: {
            ...noWrap,
            maxWidth: 250,
            height: 36
          }
        }}
        onClick={() => setEventReminderState(tab)}
      >
        <>
          {tab.statusFromApi && (
            <FontIcon
              iconName="RadioBullet"
              styles={{
                root: {
                  padding: 0,
                  margin: 0,
                  width: "12px",
                  color: eventReminderStatusColors(
                    tab.state as CalendarEventReminderStatus
                  ),
                  fontSize: FontSizes.size24
                }
              }}
            />
          )}
          <Text nowrap styles={tabLabelStyle}>
            {tab.title}
          </Text>
          <Text styles={tabCountStyle}>{tab.count}</Text>
        </>
      </PillButton>
    );
  });
