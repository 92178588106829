import { observer } from "mobx-react-lite";
import { useState } from "react";

import { DefaultButton, Stack } from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { OrgUnitDocumentSettingsDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import { FormSubmitButtonsProps } from "@ui-components/form/submission-form/FormSubmitButtons.types.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { SubmissionFormProps } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

import { DocumentMergeFieldsFormValues } from "../../document-writer/components/merge-fields/DocumentWriterMergeFormDialog.types.ts";
import { useTemplatePickerFormContext } from "./context/TemplatePickerFormContext.tsx";
import { CreateBlankDocumentDialog } from "./CreateBlankDocumentDialog.tsx";
import { TemplatePickerFilter } from "./TemplatePickerFilter.tsx";
import { TemplatePickerFormValues } from "./TemplatePickerForm.types.ts";
import { TemplatePivot } from "./TemplatePivot.tsx";
import { TemplateListItem } from "./TemplatePivot.types.ts";
import { TemplatePreview } from "./TemplatePreview.tsx";
import { TemplateScrollablePane } from "./TemplateScrollablePane.tsx";

type TemplatePickerFormProps = {
  items: TemplateListItem[];
  launchFrom: LaunchFrom;
  favourites: string[];
  createDocument: (
    templateType: CorrespondenceType,
    values: DocumentMergeFieldsFormValues,
    toContactId?: string
  ) => Promise<void>;
  patientId?: string;
  patientNameWithTitle?: string;
  documentSettings?: OrgUnitDocumentSettingsDto;
} & Pick<SubmissionFormProps<TemplatePickerFormValues>, "onSubmit"> &
  Pick<FormSubmitButtonsProps<TemplatePickerFormValues>, "onCancel">;

export const TemplatePickerForm: React.FC<TemplatePickerFormProps> = observer(
  ({
    items,
    favourites,
    createDocument,
    onSubmit,
    onCancel,
    launchFrom,
    patientId,
    patientNameWithTitle,
    documentSettings
  }) => {
    const [showCreateBlankDocumentDialog, setShowCreateBlankDocumentDialog] =
      useState<boolean>(false);

    const model = useTemplatePickerFormContext();

    model.setUpInitialState(favourites);

    const getExtraActionsBefore = () => {
      return (
        <Stack horizontal styles={{ root: { width: "100%" } }}>
          <DefaultButton
            text="Create a blank document"
            onClick={() => {
              setShowCreateBlankDocumentDialog(true);
            }}
            styles={{ root: { marginRight: 142 } }}
          />
        </Stack>
      );
    };

    const heading = patientNameWithTitle
      ? `Create blank document for ${patientNameWithTitle}`
      : "Create blank document";

    return (
      <SubmissionForm<TemplatePickerFormValues>
        formName="template-picker"
        onSubmit={onSubmit}
        disableRoutePrompt={true}
        buttonsProps={{
          hideButtonsSeparator: true,
          disableSubmitOnPristine: true,
          onCancel,
          submitButtonProps: {
            text: "Use template",
            iconProps: {
              iconName: "EditNote"
            }
          },
          extraActionsBefore: getExtraActionsBefore
        }}
      >
        {form => (
          <>
            <TemplatePivot items={items} isPickerFrom={true} />
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Stack.Item grow>
                <TemplatePickerFilter>
                  {() => (
                    <TemplateScrollablePane
                      isPickerFrom={true}
                      templateListItems={items}
                    />
                  )}
                </TemplatePickerFilter>
              </Stack.Item>
              <TemplatePreview templateId={form.values.templateId} />
            </Stack>
            <CreateBlankDocumentDialog
              createDocument={createDocument}
              isDialogHidden={!showCreateBlankDocumentDialog}
              onDismiss={() => setShowCreateBlankDocumentDialog(false)}
              launchFrom={launchFrom}
              patientId={patientId}
              documentSettings={documentSettings}
              heading={heading}
            />
          </>
        )}
      </SubmissionForm>
    );
  }
);
