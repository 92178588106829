import { FunctionComponent } from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { MedicationClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface MedicationsListCellProps {
  medication: MedicationClinicalDataItemDto;
}

export const MedicationsListCell: FunctionComponent<
  MedicationsListCellProps
> = ({ medication }) => {
  const { clinical } = useStores();
  const theme = useTheme();
  const getScriptText = () => {
    let scriptText = clinical.ref.rxTypes.keyTextValues.find(
      x => x.key === medication.rxType
    )?.text;
    if (medication.regulation49) {
      scriptText = `${scriptText}, Reg49`;
    }

    return scriptText;
  };

  return (
    <Stack
      tokens={{ childrenGap: 4 }}
      styles={{
        root: { paddingBottom: "8px", paddingTop: 0, marginLeft: 32 }
      }}
    >
      <Stack horizontal>
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          verticalAlign="center"
          styles={{ root: { width: "40%" } }}
        >
          <Text
            styles={{ root: { color: theme.palette.neutralSecondary } }}
            variant="small"
          >
            First Rx
          </Text>
          <Text variant="medium">
            {medication?.firstRx &&
              DateTime.fromISO(medication.firstRx).toDayDefaultFormat()}
          </Text>
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          verticalAlign="center"
          styles={{ root: { width: "60%" } }}
        >
          <Text
            styles={{ root: { color: theme.palette.neutralSecondary } }}
            variant="small"
          >
            Last Rx
          </Text>
          <Text variant="medium">
            {medication?.lastRx &&
              DateTime.fromISO(medication.lastRx).toDayDefaultFormat()}
          </Text>
        </Stack>
      </Stack>
      <Stack horizontal>
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          verticalAlign="center"
          styles={{ root: { width: "40%" } }}
        >
          <Text
            variant="small"
            styles={{ root: { color: theme.palette.neutralSecondary } }}
          >
            QTY
          </Text>
          <Text variant="medium">{medication?.quantity}</Text>
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          verticalAlign="center"
          styles={{ root: { width: "60%" } }}
        >
          <Text
            styles={{ root: { color: theme.palette.neutralSecondary } }}
            variant="small"
          >
            Repeats
          </Text>
          <Text variant="medium">{medication?.repeats}</Text>
        </Stack>
      </Stack>

      <Stack
        horizontal
        tokens={{ childrenGap: 4 }}
        verticalAlign="center"
        styles={{ root: { width: "50%" } }}
      >
        <Text
          styles={{ root: { color: theme.palette.neutralSecondary } }}
          variant="small"
        >
          Script
        </Text>
        <Text variant="medium">{getScriptText()}</Text>
      </Stack>
    </Stack>
  );
};
