import { observer } from "mobx-react-lite";

import { MoreLessToggle, useTheme } from "@bps/fluent-ui";
import { useTransactionListContext } from "@modules/billing/screens/shared-components/AllocationList/transaction-list/context/TransactionListContext.ts";

export const TransactionActivityCell: React.FC<{
  id: string;
}> = observer(({ id }) => {
  const theme = useTheme();
  const { rowsToggledOpen, toggleRow } = useTransactionListContext();

  return (
    <MoreLessToggle
      noCaption
      styles={{
        root: {
          padding: 4.5,
          paddingLeft: 5,
          paddingRight: 5,
          "&:hover": { backgroundColor: theme.palette.neutralLighter }
        }
      }}
      onChanged={() => toggleRow(id)}
      value={!rowsToggledOpen.has(id)}
    />
  );
});
