import { BodyArea } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { SpecialTestResponseType } from "../../../full-body-clinical-data/examination/BodyExaminationForm.types.ts";
import { checkStringSpecialTest } from "../../../full-body-clinical-data/validators/BodyExaminationValidator.tsx";
import { CentralNervousSystemFormValues } from "../CentralNervousSystemForm.types.ts";

const checkCranialNerveStringTestIndexs = [0, 1, 9];

const checkCranialNerveTest = (value: string, index: number) => {
  if (checkCranialNerveStringTestIndexs.includes(index)) {
    return checkStringSpecialTest(value);
  }
  return undefined;
};

export class CranialNerveTestValidator extends Validator<SpecialTestResponseType> {
  constructor() {
    super();
    this.forArrayField(BodyArea.CentralNervousSystemCranial, [
      (value, values) => {
        const index = values.CNSC?.indexOf(value);
        return checkCranialNerveTest(value, index);
      }
    ]);
  }
}

export class CentralNervousSystemValidator extends Validator<CentralNervousSystemFormValues> {
  constructor() {
    super();
    const cranialNerveTestValidator = new CranialNerveTestValidator();
    this.forField(
      "cranialNerveTestResponseItems",
      cranialNerveTestValidator.validate
    );
  }
}
