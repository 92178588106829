import { observer } from "mobx-react-lite";

import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";
import { Outcome } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface OutcomeBadgeProps {
  document: ClinicalDocument;
}

const OutcomeBadgeBase: React.FC<OutcomeBadgeProps> = observer(
  ({ document }) => {
    const { clinicalRecord } = usePatientRecordScreenContext();
    const { inbox } = useStores();

    const userTask = clinicalRecord.patientUserTasks.find(
      task => task.documentId === document.id
    );

    const badgeLabel =
      inbox.ref.outcomes.keyLabelValues.find(
        refData => refData.value === userTask?.outcome
      )?.label ?? "—";

    let badgeColor = TextBadgeColor.lightGrey;

    if (
      userTask?.outcome === Outcome.Informed ||
      userTask?.outcome === Outcome.Booked ||
      userTask?.outcome === Outcome.Given
    ) {
      badgeColor = TextBadgeColor.green;
    } else if (userTask?.outcome === Outcome.AttemptedContact) {
      badgeColor = TextBadgeColor.yellow;
    }

    return (
      <TextBadge
        badgeSize={TextBadgeSize.small}
        badgeColor={badgeColor}
        styles={{
          root: {
            backgroundColor: "transparent"
          }
        }}
      >
        {badgeLabel}
      </TextBadge>
    );
  }
);

export const OutcomeBadge = withFetch(
  x => [x.inbox.ref.outcomes.load()],
  OutcomeBadgeBase
);
