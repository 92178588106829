import { Text } from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";

export const InvoiceItemValueCell: React.FC<{ amount: number }> = ({
  amount
}) => (
  <Text
    block
    styles={(props, theme) => ({
      root: {
        color: theme.palette.neutralSecondary,
        textAlign: "right"
      }
    })}
  >
    {currencyFormat(amount, { currency: "" })}
  </Text>
);
