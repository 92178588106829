import React from "react";

import { FontWeights, Grid, Text, TextBadge, useTheme } from "@bps/fluent-ui";
import { userDefualtHotkeys } from "@stores/user-experience/UserHotkeysHelper.ts";

export const HotkeyCheatSheet = () => {
  const theme = useTheme();
  return (
    <Grid
      childrenTokens={{ gap: 16 }}
      templateColumns="32px 1fr 32px 1fr"
      verticalAlign="center"
    >
      {userDefualtHotkeys.map(([hotkey, description]) => (
        <React.Fragment key={hotkey}>
          <TextBadge
            styles={{
              root: {
                textTransform: "uppercase",
                borderRadius: "initial",
                fontWeight: FontWeights.bold,
                width: 32,
                height: 24,
                alignItems: "center",
                justifyContent: "center"
              }
            }}
            customColors={{ backgroundColor: theme.palette.neutralLight }}
            children={hotkey}
          />
          <Text variant="small">{description}</Text>
        </React.Fragment>
      ))}
    </Grid>
  );
};
