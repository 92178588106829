import { nameof } from "@libs/utils/name-of.utils.ts";
import { maxLength, required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ReferralDetailValuesType } from "@shared-types/acc/referral-detail-values.type.ts";

export class ReferralDetailFormValidator extends Validator<ReferralDetailValuesType> {
  constructor() {
    super();
    this.forField(nameof("providerTypeCode"), [required()]);
    this.forField(nameof("referralReason"), [required(), maxLength(255)]);
  }
}
