import { observer } from "mobx-react-lite";
import { useState } from "react";

import { DefaultButton, IButtonProps, Stack } from "@bps/fluent-ui";
import { PaymentMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  PromiseObservableButton,
  PromiseObservableButtonProps
} from "@ui-components/PromiseObservableButton/PromiseObservableButton.tsx";

import { RefundConfirmationDialog } from "../../shared-components/RefundConfirmationDialog.tsx";

export interface CreditNoteViewButtonsProps {
  creditNote: CreditNote;
}

export const CreditNoteViewButtons: React.FC<CreditNoteViewButtonsProps> =
  observer(({ creditNote }) => {
    const { routing, billing } = useStores();

    const [refundDialogVisible, setRefundDialogVisible] = useState(false);

    const onClear = () => {
      setRefundDialogVisible(false);
    };

    const onSucceeded = async (refundPaymentMethod: PaymentMethod) => {
      await billing.refundUnallocatedCredit(creditNote, refundPaymentMethod);
      closeInvoiceOrPaymentPage(routing, "replace");
      onClear();
    };

    const close = () => {
      closeInvoiceOrPaymentPage(routing, "replace");
    };

    const closeButtonProps: IButtonProps = {
      text: "Close",
      onClick: close
    };

    const primaryButtonProps: PromiseObservableButtonProps = {
      disabled: !creditNote.unallocated,
      primary: true,
      text: "Refund",
      onClick: () => setRefundDialogVisible(true)
    };

    return (
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <PromiseObservableButton {...primaryButtonProps} />
        <DefaultButton {...closeButtonProps} />
        <RefundConfirmationDialog
          refundItemType="credit"
          refundDialogVisible={refundDialogVisible}
          onDismiss={onClear}
          onSucceeded={(refundPaymentMethod: PaymentMethod) =>
            onSucceeded(refundPaymentMethod)
          }
          refundTotal={creditNote.total - (creditNote.allocated || 0)}
          refundItemNumber={creditNote.number}
        />
      </Stack>
    );
  });
