import { action, observable, runInAction } from "mobx";

import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import {
  isPending,
  isRejected,
  maybePromiseObservable,
  QueryResult
} from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { InvoiceSettings } from "@stores/billing/models/InvoiceSettings.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";
import { Service } from "@stores/billing/models/Service.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { sortSchedules } from "../../shared-components/fees.utils.ts";
import {
  FeeFormValues,
  NewScheduleFormValues,
  ScheduleFormValues
} from "../components/ScheduleForm.types.tsx";
import {
  getFeeDtoFromFormValues,
  getScheduleDtoFromFormValues,
  isFeeFormDirty
} from "../components/utils.ts";

export class ScheduleScreenHelper {
  // invoiceSettings is saved here because they're used by multiple forms on this screen
  @observable invoiceSettings: InvoiceSettings[] = [];

  constructor(private root: RootStore) {}

  get notification() {
    return this.root.notification;
  }

  get billing() {
    return this.root.billing;
  }

  get routing() {
    return this.root.routing;
  }

  get core() {
    return this.root.core;
  }

  loadInvoiceSettings = async () => {
    const invoiceSettings = await this.root.billing.getInvoiceSettings();
    runInAction(() => {
      this.invoiceSettings = invoiceSettings;
    });
  };

  // NewScheduleFormDialog
  @observable isNewScheduleDialogVisible: boolean = false;

  @action
  openNewScheduleDialog = () => {
    this.editFee = undefined;
    this.isNewScheduleDialogVisible = true;
  };

  @action
  closeNewScheduleDialog = () => {
    this.isNewScheduleDialogVisible = false;
  };

  public loadSchedules = async () => {
    const schedules = await this.root.billing.loadSchedules({
      ignoreCache: true
    });
    runInAction(() => {
      this.schedules = Array.from(schedules).sort(sortSchedules);
    });
  };

  submitNewScheduleValues = async (
    values: NewScheduleFormValues,
    newFeeAfterSubmit: boolean
  ) => {
    // save schedule
    const scheduleData = getScheduleDtoFromFormValues(values);
    const schedule = await this.billing.addSchedule(scheduleData);

    if (!schedule) {
      return;
    }

    // save service
    if (isFeeFormDirty(values)) {
      const feeData = getFeeDtoFromFormValues(values, schedule);
      await this.billing?.addService(feeData);
    }

    await this.loadSchedules();

    this.closeNewScheduleDialog();
    this.openScheduleView(schedule);
    if (newFeeAfterSubmit) {
      this.openFeeDialog(schedule);
    }

    this.notification.success(
      notificationMessages.scheduleAdded(values.scheduleName)
    );
  };

  // ScheduleFormDialog

  @observable editSchedule: Schedule | undefined;
  @observable isEditScheduleDialogVisible: boolean = false;

  @action
  openEditScheduleDialog = (editSchedule: Schedule) => {
    this.editSchedule = editSchedule;
    this.isEditScheduleDialogVisible = true;
  };

  @action
  closeEditScheduleDialog = () => {
    this.isEditScheduleDialogVisible = false;
  };

  submitEditScheduleValues = async (values: ScheduleFormValues) => {
    const scheduleData = getScheduleDtoFromFormValues(values);
    await this.billing.updateSchedule({
      ...this.editSchedule!.dto,
      ...scheduleData
    });

    this.closeEditScheduleDialog();

    this.notification.success(
      notificationMessages.scheduleUpdated(values.scheduleName)
    );
  };

  // FeeFormDialog

  @observable feeSchedule: Schedule | undefined;
  @observable editFee: Service | undefined;
  @observable isFeeDialogVisible: boolean = false;

  @action
  openFeeDialog = (feeSchedule: Schedule, editFee?: Service) => {
    this.feeSchedule = feeSchedule;
    this.editFee = editFee;
    this.isFeeDialogVisible = true;
  };

  @action
  closeFeeDialog = () => {
    this.isFeeDialogVisible = false;
  };

  submitNewFeeValues = async (
    values: FeeFormValues,
    closeAfterSubmit: boolean
  ) => {
    const data = getFeeDtoFromFormValues(values, this.feeSchedule!);

    const service = await this.billing.addService(data);

    runInAction(() => {
      this.feeSchedule!.services.push(service);
    });

    // update activeFees on schedule
    await this.billing.getSchedule(this.feeSchedule!.id, {
      ignoreCache: true
    });

    if (closeAfterSubmit) {
      if (this.viewSchedule?.id !== this.feeSchedule!.id) {
        this.openScheduleView(this.feeSchedule!);
      }

      this.closeFeeDialog();
    }
    this.notification.success(
      notificationMessages.serviceAdded(values.feeCode || "")
    );
  };

  submitEditFeeValues = async (values: FeeFormValues) => {
    const newValues = getFeeDtoFromFormValues(
      values,
      this.feeSchedule!,
      this.editFee
    );

    const { changeLog, ...originalValues } = this.editFee!.dto;
    const dto = { ...originalValues, ...newValues };

    await this.billing.updateService(dto);

    // update activeFees on schedule
    await this.billing.getSchedule(this.feeSchedule!.id, {
      ignoreCache: true
    });

    this.closeFeeDialog();
    this.notification.success(
      notificationMessages.serviceUpdated(values.feeCode || "")
    );
  };

  // FeeView

  @observable viewFee: Service | undefined;

  @action
  openFeeView = (viewSchedule: Schedule, viewFee: Service) => {
    this.viewSchedule = viewSchedule;
    this.viewFee = viewFee;
    const { feePath } = routes.settings.schedules;
    this.routing.push(
      feePath.path({ scheduleId: viewSchedule.id, feeId: viewFee.id })
    );
  };

  // ScheduleView

  @observable viewSchedule: Schedule | undefined;

  get isScheduleViewVisible(): boolean {
    return !!this.routing.match(routes.settings.schedules.viewPath)?.params
      .scheduleId;
  }

  @action
  openScheduleView = (viewSchedule: Schedule) => {
    this.viewSchedule = viewSchedule;

    this.searchResults.clear();
    this.nextResults.clear();

    const { viewPath } = routes.settings.schedules;
    this.routing.push(viewPath.path({ scheduleId: viewSchedule.id }));
  };

  // ScheduleList
  @observable
  schedules: Schedule[];

  @action
  openScheduleList = () => {
    this.routing.push(routes.settings.schedules.basePath.pattern);
  };

  // FeesList
  searchResults = maybePromiseObservable<QueryResult<Service>>();
  nextResults = maybePromiseObservable<QueryResult<Service>>();

  get isLoadingFees() {
    return isPending(this.searchResults);
  }

  get isLoadingMoreFees() {
    return isPending(this.nextResults);
  }

  get isFeesSearchRejected() {
    return isRejected(this.searchResults);
  }

  get canEditSchedule(): boolean {
    return this.core.hasPermissions(Permission.ScheduleWrite);
  }

  canEditService = (service: Service): boolean => {
    return (
      this.core.hasPermissions(Permission.ScheduleWrite) && !service.benefitId
    );
  };
}
