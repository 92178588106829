import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { SecurityFormValues } from "./SecurityForm.types.tsx";

export class SecurityFormValidator extends Validator<SecurityFormValues> {
  constructor() {
    super();
    this.forField("inactivityTimeoutThresholdMinutes", [required()]);
  }
}
