import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { invoiceFormNameOf } from "../InvoiceForm.types.tsx";

export const ClearCalendarEventOnClaimChangeSpy: FunctionComponent = observer(
  () => {
    const { acc } = useStores();

    const form = useForm<InvoiceFormValues>();

    const onChange = async (
      claimId: InvoiceFormValues["claimId"],
      values: InvoiceFormValues
    ) => {
      const calendarEventId = values.calendarEventId;
      if (claimId && calendarEventId) {
        const ca = await acc.getClaimAppointmentDtos({
          calendarEventId
        });
        if (!ca.length || ca[0]?.claimId !== claimId) {
          form.change("calendarEventId", "");
        }
      }
    };

    return <FieldSpy name={invoiceFormNameOf("claimId")} onChange={onChange} />;
  }
);
