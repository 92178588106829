import { FontIcon, Stack, Text } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";

import { OverviewSectionListIcon } from "./OverviewSectionListIcon.tsx";

export const InitialAssessment = () => {
  const { claimAdjustment } = useClaimAdjustmentContext();
  const { initialAssessmentsApproved, initialAssessmentRequested } =
    claimAdjustment;
  return (
    <Stack
      as="li"
      verticalAlign="center"
      horizontal
      tokens={{ childrenGap: 4 }}
    >
      {initialAssessmentRequested &&
      initialAssessmentsApproved === undefined ? (
        <FontIcon iconName="LocationDot" />
      ) : (
        <OverviewSectionListIcon approved={!!initialAssessmentsApproved} />
      )}
      <Text block>Initial</Text>
    </Stack>
  );
};
