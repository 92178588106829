import { maxArrayLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ChangeDiagnosisValuesInterface } from "@shared-types/acc/change-diagnosis-values.interface.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";

import { DiagnosisChangeValidator } from "./DiagnosisChangeValidator.tsx";

export class DiagnosisChangeFormValidator extends Validator<ChangeDiagnosisValuesInterface> {
  constructor(claimAdjustment: ClaimAdjustment) {
    super();

    if (claimAdjustment.hasDiagnosisChange) {
      const diagnosisChangeFormValidator = new DiagnosisChangeValidator();

      this.forArrayField(
        "diagnosisChanges",
        diagnosisChangeFormValidator.validate
      );

      this.forField("diagnosisChanges", [
        maxArrayLength(3, "Must not exceed 3 diagnosis.")
      ]);
    }
  }
}
