import { SOTAPFormWrapper } from "../../SOTAP/SOTAPFormWrapper.tsx";
import { RedFlagsCard } from "./RedFlagsCard.tsx";
import { YellowFlagsCard } from "./YellowFlagsCard.tsx";

export const ClinicalFlagsContent: React.FunctionComponent = () => {
  return (
    <SOTAPFormWrapper>
      <RedFlagsCard />
      <YellowFlagsCard />
    </SOTAPFormWrapper>
  );
};
