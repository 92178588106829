import { useField, useForm } from "react-final-form";

import { ActionButton, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import {
  AddXrayParametersFormValues,
  addXrayParametersFormValuesNameOf,
  AddXrayParametersView
} from "./AddXrayParameterDialog.types.ts";
import { ImagingReportViewParameterField } from "./ImagingReportViewParameterField.tsx";

export const AddXrayParametersDialogFields: React.FC = () => {
  const { clinical } = useStores();
  const {
    input: { value: regionValue }
  } = useField(addXrayParametersFormValuesNameOf("region"), {
    subscription: { value: true }
  });

  const { mutators } = useForm<AddXrayParametersFormValues>();
  const { fields: addXrayParametersViews } =
    useFieldArray<AddXrayParametersView>(
      addXrayParametersFormValuesNameOf("views")
    );

  const hasSide = clinical.ref.imagingRegions.get(regionValue)?.hasSide;

  const resetSideValues = () => {
    mutators.update(addXrayParametersFormValuesNameOf("side"), 0, undefined);
  };

  return (
    <>
      <Stack horizontal verticalAlign="start" tokens={{ childrenGap: 8 }}>
        <OptionsSelectField
          name={addXrayParametersFormValuesNameOf("region")}
          options={clinical.ref.imagingRegions.keyTextValues}
          label="Region"
          placeholder="Region"
          styles={{
            root: {
              width: 368,
              label: { margin: 0 },
              flexGrow: 1
            }
          }}
        />

        <FieldSpy
          name={addXrayParametersFormValuesNameOf("region")}
          onChange={() => {
            resetSideValues();
          }}
        />

        <ButtonsGroupSingleChoiceField
          name={addXrayParametersFormValuesNameOf("side")}
          label="Side"
          options={[
            {
              key: "L",
              text: "L",
              disabled: !hasSide
            },
            {
              key: "R",
              text: "R",
              disabled: !hasSide
            }
          ]}
        />
      </Stack>

      {addXrayParametersViews?.map((value, index) => {
        return (
          <ImagingReportViewParameterField
            key={value}
            deleteParameter={() => {
              addXrayParametersViews.remove(index);
            }}
            imagingReporViewIndex={index}
            addXrayParametersViews={addXrayParametersViews.value}
          />
        );
      })}

      <ActionButton
        iconProps={{ iconName: "Add" }}
        title="Add another view"
        onClick={() => {
          addXrayParametersViews?.push({});
        }}
      >
        Add another view
      </ActionButton>
    </>
  );
};
