import { FC } from "react";

import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";

interface LocationListStatusBadgeProps {
  badgeColor: TextBadgeColor;
  statusText: string;
  hasBorder?: boolean;
  badgeSize?: TextBadgeSize;
}
export const LocationListStatusBadge: FC<LocationListStatusBadgeProps> = ({
  badgeColor,
  statusText,
  hasBorder,
  badgeSize
}) => {
  return (
    <TextBadge
      badgeColor={badgeColor}
      badgeSize={badgeSize}
      hasBorder={hasBorder}
      styles={{ root: { flexGrow: 1 } }}
    >
      {statusText}
    </TextBadge>
  );
};
