import { observer } from "mobx-react-lite";
import React from "react";

import { ITextStyles, Spinner, Text } from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { User } from "@stores/core/models/User.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ConfidentialByProviderTextProps {
  secGroupId: string;
  styles?: ITextStyles;
}

export const ConfidentialByProviderText: React.FC<ConfidentialByProviderTextProps> =
  observer(({ secGroupId, styles }) => {
    const { fetchUserOnSecGroupId } = usePatientRecordScreenContext();

    return (
      <DataFetcher<User | undefined>
        fallback={<Spinner />}
        fetch={async () => {
          return await fetchUserOnSecGroupId(secGroupId);
        }}
      >
        {(fetchUser: User | undefined) => (
          <Text styles={styles}>{`Confidential by ${
            fetchUser ? fetchUser.fullName : undefined
          }`}</Text>
        )}
      </DataFetcher>
    );
  });
