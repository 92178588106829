import { FunctionComponent } from "react";
import { useField } from "react-final-form";

import { dataAttribute, DataAttributes, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { getAgeString } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";

import { medHistoryNameOf } from "./MedicalHistoryDateFields.tsx";

interface MedicalHistoryDatePickerProps {
  birthDate?: Date;
  disabled?: boolean;
}
export const MedicalHistoryDatePicker: FunctionComponent<
  MedicalHistoryDatePickerProps
> = ({ birthDate, disabled }) => {
  const {
    input: { value: date },
    meta: { error }
  } = useField("date", {
    subscription: { value: true, error: true }
  });

  const renderPatientAgeText = () => {
    let age = "";
    if (birthDate) {
      age = getAgeString(
        DateTime.fromJSDate(birthDate),
        DateTime.fromJSDate(date)
      );
    }

    return `Age (approx.) ${age}`;
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <DatePickerField
        label="Date"
        name={medHistoryNameOf("date")}
        minDate={birthDate}
        maxDate={DateTime.jsDateNow()}
        styles={{ root: { maxWidth: 180 } }}
        disabled={disabled}
      />
      {!error && (
        <Text
          {...dataAttribute(
            DataAttributes.Element,
            "medical-history-patient-age"
          )}
          variant="small"
        >
          {birthDate && renderPatientAgeText()}
        </Text>
      )}
    </Stack>
  );
};
