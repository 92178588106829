import { PropsWithChildren, ReactNode } from "react";

import { CheckboxGroupOption, CollapsibleCard, Stack } from "@bps/fluent-ui";
import { SystemReviewLabel } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

interface CollapsibleFlagCardProps extends PropsWithChildren {
  options: CheckboxGroupOption<string>[];
  heading?: ReactNode | string;
  icon: string;
  allClearName: string;
  flagName: string;
  id: string;
  disabled: boolean;
  onChange: (value: boolean) => void;
}

export const CollapsibleFlagsCard: React.FunctionComponent<
  CollapsibleFlagCardProps
> = ({
  options,
  heading,
  id,
  icon,
  allClearName,
  flagName,
  disabled,
  children,
  onChange
}) => {
  return (
    <div id={id}>
      <CollapsibleCard heading={heading} iconName={icon} openOnRender={false}>
        <Stack tokens={{ childrenGap: 8 }}>
          <ToggleField
            name={allClearName}
            label={SystemReviewLabel.NAD}
            inlineLabel
            styles={{
              root: {
                margin: 0
              }
            }}
          />
          <Stack>
            <CheckboxGroupField
              name={flagName}
              options={options}
              disabled={disabled}
            />

            {children}
          </Stack>

          <FieldSpy name={allClearName} onChange={onChange} />
        </Stack>
      </CollapsibleCard>
    </div>
  );
};
