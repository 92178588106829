import { observer } from "mobx-react-lite";
import { FC, PropsWithChildren, ReactNode } from "react";

import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

interface PhoneCommunicationTextProps extends PropsWithChildren {
  contact: Contact;
}

export const PhoneCommunicationText: FC<PhoneCommunicationTextProps> = observer(
  ({
    contact,
    children
  }: {
    contact: Contact;
    children?: ReactNode | undefined;
  }) => {
    const phoneCommunications = contact.communications.filter(
      x =>
        x.type !== CommunicationType.Email &&
        x.type !== CommunicationType.Fax &&
        x.type !== CommunicationType.Website
    );
    // would display number but none given
    if (phoneCommunications.length === 0) return null;

    // if preferred not set and more than one of comms of top type
    // then display number is false
    const displayNumber =
      phoneCommunications[0].preferred ||
      phoneCommunications.filter(x => x.type === phoneCommunications[0].type)
        .length === 1;

    // yes, there is a number to display
    if (displayNumber) return <>{phoneCommunications[0].value}</>;

    return <>{children}</>;
  }
);
