import { Observer, observer } from "mobx-react-lite";
import { FunctionComponent, useContext, useState } from "react";

import {
  FontSizes,
  Heading,
  IPivotItemProps,
  Link,
  mergeStyleSets,
  NoDataTile,
  Panel,
  PanelType,
  PersonaCoin,
  PersonaSize,
  Pivot,
  PivotItem,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";
import { LegacyScrollListener } from "@ui-components/LegacyScrollListener.tsx";
import { LinkButton } from "@ui-components/navigation/LinkButton.tsx";
import { getNavBarStylesSet } from "@ui-components/navigation/NavBar.styles.ts";

import { NotificationContext } from "../context/NotificationContext.ts";
import { SystemNotification } from "./SystemNotification.tsx";
import { NotificationsPivotKeys } from "./SystemNotifications.types.ts";

export const SystemNotificationPanelDetails: FunctionComponent = observer(
  () => {
    const {
      unreadCount,
      systemNotifications,
      clearNotificationsCache,
      setPivotSelection,
      selectedPivot,
      readAll
    } = useContext(NotificationContext);

    const theme = useTheme();
    const { notification, core } = useStores();

    const { getCommandBarButtonStyle } = getNavBarStylesSet(theme);
    const [isPanelVisible, setPanelVisibility] = useState<boolean>(false);

    const handleViewClick = async (item: PivotItem) => {
      const selectedValue =
        item.props &&
        (item.props.itemKey as NotificationsPivotKeys | undefined);

      if (selectedValue === undefined) {
        return;
      }
      clearNotificationsCache();
      await notification.getNotifications({
        userId: core.userId,
        isRead:
          selectedValue === NotificationsPivotKeys.All ? undefined : false,
        continuationToken: undefined
      });

      setPivotSelection(selectedValue);
    };

    const sortedSystemNotifications = Array.from(systemNotifications).sort(
      (a, b) => {
        const createDate1 = DateTime.fromISOOrNow(a.dto.changeLog?.createdDate);

        const createDate2 = DateTime.fromISOOrNow(
          b.dto.changeLog?.createdDate ?? ""
        );
        return createDate2.valueOf() - createDate1.valueOf();
      }
    );

    const unReadCoin = (
      link?: IPivotItemProps,
      defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
    ) => {
      if (!link || !defaultRenderer) {
        return null;
      }

      return (
        <Observer>
          {() => (
            <>
              {defaultRenderer({ ...link, itemIcon: undefined })}
              {unreadCount > 0 && (
                <PersonaCoin
                  size={PersonaSize.size24}
                  coinSize={24}
                  initialsColor={theme.palette.themeLight}
                  initialsTextColor={theme.palette.black}
                  imageInitials={`${unreadCount}`}
                  styles={{
                    coin: {
                      paddingLeft: 8
                    },
                    initials: {
                      fontSize: 14,
                      fontWeight: 400
                    }
                  }}
                />
              )}
            </>
          )}
        </Observer>
      );
    };

    return (
      <>
        <Stack horizontal>
          <LinkButton
            onClick={() => {
              setPanelVisibility(true);
            }}
            styles={mergeStyleSets(getCommandBarButtonStyle(), {
              icon: { fontSize: FontSizes.size18 },

              root: {
                position: "relative",
                "&::after": unreadCount > 0 && {
                  position: "absolute",
                  content: `'${unreadCount <= 9 ? unreadCount : "9+"}'`,
                  top: 4,
                  borderRadius: "50%",
                  backgroundColor: theme.palette.redDark,
                  fontSize: FontSizes.size10,
                  height: 15,
                  width: 15,
                  textAlign: "center"
                }
              }
            })}
            iconProps={{ iconName: "Ringer" }}
          />
        </Stack>
        {isPanelVisible ? (
          <Panel
            isLightDismiss
            isOpen={isPanelVisible}
            onDismiss={() => setPanelVisibility(false)}
            closeButtonAriaLabel="Close"
            hasCloseButton={false}
            headerText="Notifications"
            styles={{
              content: { padding: 0 },
              scrollableContent: { overflow: "hidden" }
            }}
            type={PanelType.custom}
            customWidth="462px"
            onRenderHeader={() => (
              <Stack
                horizontal
                styles={{
                  root: {
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0 24px 18px 24px",
                    borderBottom: "solid",
                    borderBottomColor: theme.palette.neutralLight,
                    borderBottomWidth: 1
                  }
                }}
              >
                <Heading variant="modal-heading">Notifications</Heading>
                <Link onClick={() => readAll()} disabled={unreadCount <= 0}>
                  Mark all as read
                </Link>
              </Stack>
            )}
          >
            <Stack verticalFill>
              <Stack horizontal>
                <Pivot
                  styles={{ root: { paddingLeft: 8 } }}
                  onLinkClick={handleViewClick}
                  defaultSelectedKey={selectedPivot}
                >
                  <PivotItem
                    headerText="All"
                    itemKey={NotificationsPivotKeys.All}
                  />
                  <PivotItem
                    headerText="Unread"
                    itemKey={NotificationsPivotKeys.Unread}
                    onRenderItemLink={unReadCoin}
                  />
                </Pivot>
              </Stack>

              {(selectedPivot === NotificationsPivotKeys.Unread &&
                unreadCount === 0) ||
              (selectedPivot === NotificationsPivotKeys.All &&
                sortedSystemNotifications.length === 0) ? (
                <NoDataTile
                  styles={{ root: { minHeight: "158px" } }}
                  textProps={{ text: "You have no notifications" }}
                  linkProps={{}}
                />
              ) : (
                <Stack
                  styles={{
                    root: {
                      overflow: "scroll",
                      height: "calc(100vh - 90px)"
                    }
                  }}
                  data-is-scrollable={true}
                >
                  {sortedSystemNotifications.map(notification => (
                    <SystemNotification
                      key={`system-notification-${notification.id}`}
                      notification={notification}
                    />
                  ))}
                  <LegacyScrollListener
                    onScrolledToBottom={async () => {
                      await notification.getNotifications({
                        userId: core.userId,
                        isRead:
                          selectedPivot === NotificationsPivotKeys.All
                            ? undefined
                            : false,
                        continuationToken: notification.continuationToken
                      });
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Panel>
        ) : null}
      </>
    );
  }
);
