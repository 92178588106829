import { Validator } from "@libs/validation/Validator.ts";

import { OrgUnitOpeningHoursValues } from "./OrgUnitOpeningHoursForm.tsx";
import { OrgUnitOpeningHoursValidator } from "./OrgUnitOpeningHoursValidator.ts";

export class OrgUnitOpeningHoursFormValidator extends Validator<OrgUnitOpeningHoursValues> {
  constructor() {
    super();
    const orgUnitOpeningHoursFormValidator = new OrgUnitOpeningHoursValidator();
    this.forArrayField(
      "openingHours",
      orgUnitOpeningHoursFormValidator.validate
    );
  }
}
