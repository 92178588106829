import { ClaimDiagnosisDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getAllDiagnoses } from "../utils.ts";

interface OtherDiagnosesProps {
  claim: Claim;
  currentDiagnoses: ClaimDiagnosisDto[];
  primaryDiagnosis?: ClaimDiagnosisDto;
  children: (filteredDiagnoses: ClaimDiagnosisDto[]) => React.ReactNode;
}

//filter out current diagnoses and primary diagnoses form all diagnoses
export const filterOutCurrentDiagnoses = (options: {
  allDiagnoses: ClaimDiagnosisDto[];
  currentDiagnoses: ClaimDiagnosisDto[];
  primaryDiagnosis?: ClaimDiagnosisDto;
}) => {
  const { allDiagnoses, currentDiagnoses, primaryDiagnosis } = options;
  const filteredOutAdditionalDiagnoses = allDiagnoses.filter(
    x =>
      !currentDiagnoses.find(
        (y: ClaimDiagnosisDto) =>
          !!(
            x.terminology?.code === y.terminology?.code &&
            x.diagnosisSide === y.diagnosisSide
          )
      )
  );

  const filteredOutPrimaryDiagnoses = primaryDiagnosis
    ? filteredOutAdditionalDiagnoses.filter(
        x =>
          !(
            x.terminology?.code === primaryDiagnosis.terminology?.code &&
            x.diagnosisSide === primaryDiagnosis.diagnosisSide
          )
      )
    : filteredOutAdditionalDiagnoses;

  return filteredOutPrimaryDiagnoses;
};

export const OtherDiagnosesDataFetcher: React.FC<OtherDiagnosesProps> = ({
  claim,
  currentDiagnoses,
  primaryDiagnosis,
  children
}) => {
  return (
    <DataFetcher
      fetch={async () => {
        const allDiagnoses = await getAllDiagnoses(claim);

        return filterOutCurrentDiagnoses({
          allDiagnoses,
          currentDiagnoses,
          primaryDiagnosis
        });
      }}
    >
      {(filteredDiagnosis: ClaimDiagnosisDto[]) => children(filteredDiagnosis)}
    </DataFetcher>
  );
};
