import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext } from "react";

import {
  Link,
  noWrap,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ReportTypeKeys } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ConfidentialToolTipFontIcon } from "../../ConfidentialToolTipFontIcon.tsx";
import { OutcomeBadge } from "../OutcomeBadge.tsx";
import { ConfidentialByProviderText } from "./ConfidentialByProviderText.tsx";
import { InvestigationSidePanelContext } from "./InvestigationsSidePanelContext.ts";

const investigationBadge = {
  [ReportTypeKeys.acceptable]: {
    color: TextBadgeColor.yellow,
    text: "Acceptable"
  },
  [ReportTypeKeys.normal]: { color: TextBadgeColor.green, text: "Normal" },
  [ReportTypeKeys.unacceptable]: {
    color: TextBadgeColor.red,
    text: "Unacceptable"
  }
};

export interface ReportsListItemProps {
  record: ClinicalDocument;
}

export const ReportsListItem: FunctionComponent<ReportsListItemProps> =
  observer(({ record }) => {
    const { onOpenInvestigationClick } = useContext(
      InvestigationSidePanelContext
    );

    const { reportType, secGroupId } = record;
    const { core } = useStores();
    const isConfidentOfOtherUser = !core.hasAccessToSecGroup(secGroupId);
    const isShowConfidentialIcon =
      !!secGroupId &&
      !isConfidentOfOtherUser &&
      core.hasPermissions(Permission.ConfidentialDataAllowed);

    return (
      <Stack tokens={{ childrenGap: 8 }}>
        {reportType ? (
          <Stack
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: 8 }}
          >
            {isConfidentOfOtherUser ? (
              <ConfidentialByProviderText
                secGroupId={secGroupId ?? ""}
                styles={{ root: noWrap }}
              />
            ) : (
              <Link
                styles={{ root: noWrap }}
                onClick={() => {
                  onOpenInvestigationClick(record);
                }}
              >
                {record.name}
              </Link>
            )}

            <Stack horizontal>
              <ConfidentialToolTipFontIcon
                isShowConfidentialIcon={isShowConfidentialIcon}
              />
              <TextBadge
                badgeColor={investigationBadge[reportType]?.color}
                badgeSize={TextBadgeSize.small}
                styles={{ root: { minWidth: "85px" } }}
              >
                {investigationBadge[reportType]?.text}
              </TextBadge>
            </Stack>
          </Stack>
        ) : (
          <Stack
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: 8 }}
          >
            {isConfidentOfOtherUser ? (
              <ConfidentialByProviderText secGroupId={secGroupId ?? ""} />
            ) : (
              <Link
                styles={{ root: noWrap }}
                onClick={() => {
                  onOpenInvestigationClick(record);
                }}
              >
                {record.name}
              </Link>
            )}

            <ConfidentialToolTipFontIcon
              isShowConfidentialIcon={isShowConfidentialIcon}
            />
          </Stack>
        )}

        <Stack horizontal horizontalAlign="space-between">
          <Text>{record.date?.toDayDefaultFormat()}</Text>
          <OutcomeBadge document={record} />
        </Stack>
      </Stack>
    );
  });
