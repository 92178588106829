import { useState } from "react";

import {
  Callout,
  FontSizes,
  Heading,
  Image,
  ImageFit,
  Link,
  Stack,
  Text
} from "@bps/fluent-ui";
import { TemplateDataModelNodeType } from "@libs/gateways/document/DocumentGateway.dtos.ts";

import { TemplateNode } from "./context/SyncFusionDocumentEditorHelper.ts";

interface MergeFieldPreviewCalloutProps {
  index: number;
  node: TemplateNode;
}

export const MergeFieldPreviewCallout: React.FC<
  MergeFieldPreviewCalloutProps
> = ({ index, node }) => {
  const [showCallOut, setShowCallOut] = useState<boolean>();

  return (
    <>
      <Link
        id={`preview-callout-${index}`}
        styles={{
          root: {
            fontSize: FontSizes.size14
          }
        }}
        onClick={() => setShowCallOut(prev => !prev)}
      >
        Preview
      </Link>

      {showCallOut && (
        <Callout
          role="dialog"
          target={`#preview-callout-${index}`}
          onDismiss={() => setShowCallOut(prev => !prev)}
        >
          <Stack
            tokens={{ childrenGap: 8 }}
            styles={{
              root: { minHeight: 140, width: 343, padding: 16 }
            }}
          >
            <Heading variant="modal-heading">Preview "{node.text}"</Heading>
            {node.nodeType &&
            node.nodeType === TemplateDataModelNodeType.Image ? (
              <Image
                src={node.value}
                imageFit={ImageFit.cover}
                height={100}
                width={100}
              />
            ) : (
              <Text variant="medium">{node.value}</Text>
            )}
          </Stack>
        </Callout>
      )}
    </>
  );
};
