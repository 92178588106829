import { DATE_FORMATS } from "@bps/utils";
import {
  isNotFutureDate,
  maxLength,
  required,
  validDate
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { AllocationFormValues } from "../shared-components/allocation-form/components/AllocationForm.types.ts";
import { PaymentsFormValidator } from "./PaymentsFormValidator.ts";

export class AllocationFormValidator extends Validator<AllocationFormValues> {
  constructor(dateRequired?: boolean, accountCreditTotal?: number) {
    super();
    dateRequired &&
      this.forField("paymentDate", [
        required(),
        validDate(DATE_FORMATS.DAY_DEFAULT_FORMAT),
        isNotFutureDate()
      ]);
    this.forField("accountContactId", required());
    this.forField("comment", maxLength(250));

    this.forArrayField("payments", value => {
      const paymentsValidator = new PaymentsFormValidator(accountCreditTotal);
      return paymentsValidator.validate(value);
    });

    this.forField("locationId", required());
  }
}
