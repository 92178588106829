import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import { ReactElement, useCallback, useState } from "react";

import {
  DefaultButton,
  FontSizes,
  Heading,
  IconButton,
  IContextualMenuItem,
  mergeStyles,
  RESET_CELLS_PADDING_CLASSNAME,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { AppointmentCancellationReason } from "@stores/booking/models/AppointmentCancellationReason.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { SettingsLabels } from "../../../shared-components/SettingsLabels.ts";
import { getUserStylesSet } from "../../../users/components/UserScreens.styles.tsx";
import { AppointmentCancellationReasonDialog } from "./AppointmentCancellationReasonDialog.tsx";
import { DeleteAppointmentCancellationReasonDialog } from "./DeleteAppointmentCancellationReasonDialog.tsx";

interface AppointmentCancellationReasonListProps {
  result?: QueryResult<AppointmentCancellationReason>;
  loading?: boolean;
  error?: Error;
  loadingMore?: boolean;
}

const nameOf = nameOfFactory<AppointmentCancellationReason>();

export const AppointmentCancellationReasonList: React.FC<
  AppointmentCancellationReasonListProps
> = props => {
  const theme = useTheme();
  const { booking, notification, core } = useStores();
  const { formFields } = getUserStylesSet(theme);
  const [editReason, setEditReason] = useState<
    AppointmentCancellationReason | undefined
  >();

  const [deleteReason, setDeleteReason] = useState<
    AppointmentCancellationReason | undefined
  >();

  const [isEditDialogHidden, setIsEditDialogHidden] = useState(true);
  const [isDeleteDialogHidden, setIsDeleteDialogHidden] = useState(true);
  const [calendarCancellationReasons, setCalendarCancellationReason] = useState<
    AppointmentCancellationReason[]
  >([]);

  reaction(
    () => Array.from(booking.appointmentCancellationReasonsMap.values()),
    setCalendarCancellationReason
  );

  const toggleEditDialog = (reason?: AppointmentCancellationReason) => {
    if (isEditDialogHidden) {
      setEditReason(reason);
    } else {
      setEditReason(undefined);
    }
    setIsEditDialogHidden(!isEditDialogHidden);
  };

  const toggleDeleteDialog = (reason?: AppointmentCancellationReason) => {
    if (isDeleteDialogHidden) {
      setDeleteReason(reason);
    } else {
      setDeleteReason(undefined);
    }
    setIsDeleteDialogHidden(!isDeleteDialogHidden);
  };

  const fetchReasons = useCallback(async () => {
    const results = await booking.getAppointmentCancellationReasons({});
    setCalendarCancellationReason(results);
  }, [booking]);

  const onDelete = async () => {
    if (deleteReason) {
      await booking.deleteAppointmentCancellationReason(deleteReason.id);
    }
    notification.success("Appointment cancellation reason deleted");
    await fetchReasons();
    toggleDeleteDialog();
  };

  const renderCell = (render: () => ReactElement) => (
    <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 8 }}>
      <Observer>{render}</Observer>
    </Stack>
  );

  const { loading, loadingMore } = props;
  const iconButtonItems = (
    item: AppointmentCancellationReason
  ): IContextualMenuItem[] => {
    const actionMenuItems: IContextualMenuItem[] = [];
    actionMenuItems.push({
      key: "edit",
      text: "Edit",
      onClick: () => {
        toggleEditDialog(item);
      }
    });
    if (!item.isSystemManaged) {
      actionMenuItems.push({
        key: "delete",
        text: "Delete",
        onClick: () => {
          toggleDeleteDialog(item);
        }
      });
    }
    return actionMenuItems;
  };
  return (
    <Stack
      styles={(props, theme) => ({
        root: mergeStyles(
          {
            borderTop: "none",
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.neutralLighterAlt
          },
          formFields
        )
      })}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{
          root: {
            lineHeight: "24px",
            marginTop: 10,
            marginBottom: 10
          }
        }}
      >
        <Heading variant="section-heading-light">
          {SettingsLabels.cancellationReasons}
        </Heading>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <DataFetcher fetch={fetchReasons}>
          {() => (
            <ShimmeredDetailsList
              styles={({ theme }) => ({
                headerWrapper: {
                  borderTop: `1px solid ${theme.palette.neutralLight}`
                },
                root: {
                  ".ms-DetailsRow-cell": { whiteSpace: "initial" },
                  ".ms-DetailsRow": {
                    borderBottomColor: "transparent"
                  },
                  borderBottom: `1px solid ${theme.palette.neutralLight}`
                }
              })}
              items={calendarCancellationReasons}
              setKey="id"
              enableShimmer={loading}
              errorMessage={props.error?.message}
              enableTrailingShimmer={loadingMore}
              columns={[
                {
                  name: "Actions",
                  key: "action",
                  className: RESET_CELLS_PADDING_CLASSNAME,
                  minWidth: 50,
                  maxWidth: 75,

                  onRender: (item: AppointmentCancellationReason) => (
                    <Stack horizontalAlign="center" verticalAlign="center">
                      <IconButton
                        iconProps={{ iconName: "More" }}
                        onRenderMenuIcon={() => null}
                        disabled={item.isSystemManaged && item.text === "Other"}
                        menuProps={{ items: iconButtonItems(item) }}
                        styles={{
                          root: { width: "32px", height: "36px", padding: 0 },
                          flexContainer: { width: "32px", height: "36px" }
                        }}
                      />
                    </Stack>
                  )
                },
                {
                  name: "Cancellation reason",
                  key: nameOf("text"),
                  minWidth: 400,
                  maxWidth: 456,
                  onRender: (item: AppointmentCancellationReason) =>
                    renderCell(() => <>{item.text}</>)
                },
                {
                  name: "Status",
                  key: nameOf("isInactive"),
                  minWidth: 50,
                  maxWidth: 75,
                  onRender: (item: AppointmentCancellationReason) =>
                    renderCell(() => (
                      <>{item.isInactive ? "Inactive" : "Active"}</>
                    ))
                }
              ]}
            />
          )}
        </DataFetcher>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        {core.hasPermissions(Permission.OrgUnitSettingWrite) && (
          <DefaultButton
            iconProps={{
              styles: {
                root: {
                  margin: 0,
                  fontSize: FontSizes.size14,
                  color: theme.palette.themePrimary
                }
              },
              iconName: "Add"
            }}
            text="Add another"
            onClick={() => toggleEditDialog()}
            styles={{
              root: {
                border: "none",
                textAlign: "left",
                height: "36px",
                padding: "24px"
              }
            }}
          />
        )}
      </Stack>
      <AppointmentCancellationReasonDialog
        hidden={isEditDialogHidden}
        onDismiss={toggleEditDialog}
        reason={editReason}
      />
      {!isDeleteDialogHidden && (
        <DeleteAppointmentCancellationReasonDialog
          hidden={isDeleteDialogHidden}
          onConfirm={onDelete}
          onCancel={toggleDeleteDialog}
          reason={deleteReason?.text}
        />
      )}
    </Stack>
  );
};
