import { useForm } from "react-final-form";

import {
  DirectionalHint,
  FontIcon,
  FontSizes,
  IconButton,
  IContextualMenuItem,
  Option,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";

import {
  DermatomeFieldValues,
  DermatomeOption,
  DermatomesMyotomesAndReflexesFormValues
} from "./DermatomesAndMyotomesForm.types.ts";

interface NerveSelectProps {
  options: DermatomeOption[];
  name: string;
  index: number;
  dermName: string;
  dermatome: DermatomeFieldValues;
  panelWidth: number;
}

type NerveOptions = {
  nerve: string;
  leftDisabled: boolean;
  rightDisabled: boolean;
};

export const NerveSelect: React.FC<NerveSelectProps> = ({
  options,
  name,
  index,
  dermatome,
  dermName,
  panelWidth
}) => {
  const form = useForm<DermatomesMyotomesAndReflexesFormValues>();
  const theme = useTheme();

  const SMALL_BREAKPOINT = 340;

  const getText = () => {
    let filteredNerveList = dermatome.nerves;
    if (filteredNerveList && filteredNerveList.length > 0) {
      filteredNerveList.forEach(x => {
        const splitKey = x.split("-");
        if (splitKey.length === 2) {
          filteredNerveList = filteredNerveList?.filter(x => x !== splitKey[0]);
        }
      });

      if (filteredNerveList.length <= 10 && panelWidth > SMALL_BREAKPOINT) {
        return filteredNerveList.join(", ");
      }

      return `${filteredNerveList[0]} (+${filteredNerveList.length - 1})`;
    } else {
      return undefined;
    }
  };

  const onToggleSelect = (
    ev?: React.MouseEvent<HTMLButtonElement>,
    item?: IContextualMenuItem
  ): void => {
    ev?.preventDefault();

    const currentNerves = dermatome.nerves ?? [];
    if (item) {
      if (!currentNerves?.includes(item.key)) {
        if (!currentNerves?.includes(item.nerve)) {
          const nerve = options.filter(x => x.nerve === item.nerve);
          if (!nerve[0].disabled) {
            currentNerves?.push(item.nerve);
          }
        }
        currentNerves?.push(item.key);
        form.mutators.update(name, index, {
          ...dermatome,
          nerves: currentNerves
        });
      } else {
        const filteredNerves = currentNerves.filter(x => x !== item.key);
        if (filteredNerves.length > 0) {
          form.mutators.update(name, index, {
            ...dermatome,
            nerves: filteredNerves
          });
        } else {
          form.mutators.update(name, index, {
            ...dermatome,
            nerves: []
          });
        }
      }
    }
  };

  const dermOptions: Option<NerveOptions>[] = options.map(x => {
    return {
      key: x.nerve,
      text: x.nerve,
      disabled: x.disabled,
      data: {
        hasSide: false,
        nerve: x.nerve,
        leftDisabled: x.leftDisabled,
        rightDisabled: x.rightDisabled
      }
    };
  });

  const onRenderField = (nerves: Option<NerveOptions>[]) => {
    if (
      nerves.length > 0 ||
      (dermatome.nerves && dermatome.nerves.length > 0)
    ) {
      return <Text>{getText()}</Text>;
    } else {
      if (panelWidth > SMALL_BREAKPOINT) {
        return <Text>Select nerve(s), side if required</Text>;
      } else {
        return <Text>Select nerve(s)</Text>;
      }
    }
  };

  const menuProps = (option: Option<NerveOptions>) => ({
    directionalHint: DirectionalHint.rightTopEdge,
    items: [
      {
        key: `${option.key}-L`,
        text: "Left",
        canCheck: true,
        hasSide: true,
        nerve: option.key,
        isChecked: dermatome.nerves?.includes(`${option.key}-L`),
        onClick: onToggleSelect,
        disabled: option.data?.leftDisabled
      },
      {
        key: `${option.key}-R`,
        text: "Right",
        canCheck: true,
        hasSide: true,
        nerve: option.key,
        isChecked: dermatome.nerves?.includes(`${option.key}-R`),
        onClick: onToggleSelect,
        disabled: option.data?.rightDisabled
      }
    ]
  });

  const onRenderOption = (option: Option<NerveOptions>) => {
    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        grow
        styles={{
          root: {
            lineHeight: 32,
            height: 32
          }
        }}
      >
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <FontIcon
            iconName="CheckMark"
            styles={{
              root: {
                visibility: option.selected ? "visible" : "hidden"
              }
            }}
          />
          <Text
            styles={{
              root: {
                color: option.disabled
                  ? theme.semanticColors.disabledText
                  : theme.semanticColors.inputText
              }
            }}
          >
            {option.key}
          </Text>
        </Stack>

        <IconButton
          text=""
          split={true}
          onClick={undefined}
          menuIconProps={{ iconName: "ChevronRight" }}
          disabled={
            option.disabled &&
            option.data?.leftDisabled === option.data?.rightDisabled
          }
          styles={{
            root: {
              borderLeft: "1px solid",
              height: 16,
              borderColor: theme.palette.themeLighter
            },
            menuIcon: {
              fontSize: FontSizes.size12,
              color:
                option.disabled &&
                option.data?.leftDisabled === option.data?.rightDisabled
                  ? theme.semanticColors.disabledText
                  : theme.semanticColors.inputText
            },
            rootDisabled: {
              backgroundColor: theme.palette.white
            }
          }}
          menuProps={menuProps(option)}
        />
      </Stack>
    );
  };

  const allSelected = (value: boolean) => {
    if (!value) {
      form.mutators.update(name, index, {
        ...dermatome,
        nerves: undefined
      });
    }
  };

  return (
    <OptionsSelectField
      onRenderOption={onRenderOption}
      onRenderFieldContent={onRenderField}
      hideSearchOption={true}
      hideClearButton={false}
      multiSelect={true}
      onAllSelected={allSelected}
      options={dermOptions}
      name={`${dermName}.nerves`}
      showAllSelected={true}
      styles={{
        checkbox: { display: "none" },
        multiOption: { paddingTop: 0, paddingBottom: 0 },
        cellDisabled: { backgroundColor: theme.palette.white }
      }}
    />
  );
};
