import { useContext } from "react";
import { useForm } from "react-final-form";
import { CSSTransition } from "react-transition-group";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Frequency } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { toRecurrenceOptions } from "@libs/utils/calendar/calendar.utils.ts";
import { getFrequencyKeyTextOptions } from "@modules/settings/screens/users/components/utils.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { WeekdaysDropdownField } from "@ui-components/form/WeekdaysDropdownField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { isAfter, isMonth, isOnDate, isWeek, isYear } from "../../../utils.tsx";
import {
  enterSlideDownStyle,
  enterSlideDownStyleActive,
  exitSlideDownStyle,
  exitSlideDownStyleActive
} from "./AppointmentForm.styles.tsx";
import { appointmentFormNameOf } from "./AppointmentForm.types.ts";
import { AppointmentFormContext } from "./context/AppointmentFormContext.ts";
import { RecurrenceLinkTextBlock } from "./RecurrenceLinkTextBlock.tsx";
import { RecurringAppointmentConflicts } from "./RecurringAppointmentConflicts.tsx";

interface RecurrenceFieldsProps {
  isEditSingleEvent: boolean;
}
const REC_WIDTH = 220;

export const RecurrenceFields: React.FunctionComponent<
  RecurrenceFieldsProps
> = ({ isEditSingleEvent }) => {
  const { calendarEvent, recurrenceOccurred, condition } = useContext(
    AppointmentFormContext
  );

  const form = useForm<AppointmentFormValues>();
  const { repeat, count, interval, startDate } = form.getState().values;
  return (
    <When permission={Permission.CalendarEventWrite}>
      {(calendarEvent === undefined ||
        calendarEvent?.calendarEventRecurrenceId) && (
        <CSSTransition
          timeout={300}
          in={repeat}
          classNames={{
            enter: enterSlideDownStyle,
            enterActive: enterSlideDownStyleActive,
            exit: exitSlideDownStyle,
            exitActive: exitSlideDownStyleActive
          }}
          unmountOnExit
        >
          <div>
            {(calendarEvent || repeat) && (
              <Stack tokens={{ childrenGap: 8 }}>
                <Fieldset horizontal>
                  <SpinNumberInputField
                    label="Recur every"
                    name={appointmentFormNameOf("interval")}
                    min={1}
                    max={99}
                    step={1}
                    fieldItemStyles={{
                      itemWrapper: { alignItems: "baseline" },
                      item: { flexGrow: 0 }
                    }}
                    // SpinNumberInput component has a min width of 86
                    styles={{ root: { width: 86 } }}
                    disabled={isEditSingleEvent}
                  />

                  {interval && (
                    <DropdownField
                      withNoEmptyOption
                      styles={{
                        root: {
                          width: 100
                        }
                      }}
                      label="&nbsp;"
                      name={appointmentFormNameOf("frequency")}
                      options={getFrequencyKeyTextOptions(interval)}
                      disabled={isEditSingleEvent}
                    />
                  )}

                  <FieldCondition
                    when={appointmentFormNameOf("frequency")}
                    is={isWeek}
                  >
                    <WeekdaysDropdownField
                      required
                      styles={{
                        root: {
                          width: REC_WIDTH
                        }
                      }}
                      daysOfWeekOnly={true}
                      name={appointmentFormNameOf("dayRecur")}
                      label="On"
                      disabled={isEditSingleEvent}
                    />
                  </FieldCondition>

                  <FieldCondition
                    when={appointmentFormNameOf("frequency")}
                    is={isMonth}
                  >
                    <DropdownField
                      withNoEmptyOption
                      styles={{
                        root: {
                          width: REC_WIDTH
                        }
                      }}
                      label="On"
                      name={appointmentFormNameOf("monthYearRecurrence")}
                      options={toRecurrenceOptions(
                        DateTime.fromJSDate(startDate!),
                        Frequency.Month
                      )}
                      disabled={isEditSingleEvent}
                    />
                  </FieldCondition>

                  <FieldCondition
                    when={appointmentFormNameOf("frequency")}
                    is={isYear}
                  >
                    <DropdownField
                      styles={{
                        root: {
                          width: REC_WIDTH
                        }
                      }}
                      label="On"
                      name={appointmentFormNameOf("monthYearRecurrence")}
                      options={toRecurrenceOptions(
                        DateTime.fromJSDate(startDate!),
                        Frequency.Year
                      )}
                      disabled={isEditSingleEvent}
                    />
                  </FieldCondition>
                </Fieldset>

                <Fieldset horizontal>
                  <DropdownField
                    required
                    withNoEmptyOption
                    label="End schedule"
                    name={appointmentFormNameOf("endScheduleType")}
                    options={[
                      { key: "ondate", text: "On date" },
                      { key: "after", text: "After" }
                    ]}
                    styles={{ dropdown: { width: 203 } }}
                    disabled={isEditSingleEvent}
                  />

                  <FieldCondition
                    when={appointmentFormNameOf("endScheduleType")}
                    is={isOnDate}
                  >
                    <DatePickerField
                      required
                      placeholder="Until"
                      name={appointmentFormNameOf("until")}
                      label="Until"
                      disabled={isEditSingleEvent}
                    />
                  </FieldCondition>

                  <FieldCondition
                    when={appointmentFormNameOf("endScheduleType")}
                    is={isAfter}
                  >
                    <Stack styles={{ root: { width: "130px" } }}>
                      <SpinNumberInputField
                        required
                        label="Total Occurrences"
                        name={appointmentFormNameOf("count")}
                        min={1}
                        max={99}
                        step={1}
                        fieldItemStyles={{
                          root: { whiteSpace: "nowrap" },
                          itemWrapper: {
                            alignItems: "baseline"
                          },
                          item: { flexGrow: 0 }
                        }}
                        styles={{ root: { width: 120 } }}
                        disabled={isEditSingleEvent}
                      />
                    </Stack>
                    {recurrenceOccurred !== undefined && (
                      <Stack styles={{ root: { marginTop: 35 } }}>
                        {`${recurrenceOccurred} of ${count} occurrences`}
                      </Stack>
                    )}
                  </FieldCondition>
                </Fieldset>
                {condition && <RecurrenceLinkTextBlock condition={condition} />}
                <RecurringAppointmentConflicts
                  hideInitialStateRrule={false}
                  isDetailedConflictVisible={true}
                />
              </Stack>
            )}
          </div>
        </CSSTransition>
      )}
    </When>
  );
};
