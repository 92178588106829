import { IColumn, ITheme, Stack, Text } from "@bps/fluent-ui";

import { BloodPressureDetails } from "./BloodPressureDetails.tsx";
import { MeasurementRow } from "./ObservationsExpandedTable.tsx";
import { OtherMeasurementDetails } from "./OtherMeasurementDetails.tsx";
import { PulseDetails } from "./PulseDetails.tsx";
import {
  EN_DASH,
  formatMeasurements,
  GroupedMeasurement,
  ObservationsLabels
} from "./utils.ts";

interface ObservationsMeasurementColumnsProps {
  dates: string[];
  groupedByDateAndType: GroupedByDateAndType;
  theme: ITheme;
}

type GroupedByDateAndType = Record<
  string,
  Record<string, GroupedMeasurement[]>
>;
export const LATEST_DATE_COLUMN_ID = "latest-date-observation-matrix";
export interface FormattedMeasurement {
  systolic?: string;
  diastolic?: string;
  label?: string;
  pulse?: string;
  value?: string;
}

export const reverseDateColumns = (cols: IColumn[]) => {
  const dateCols = cols.filter(x => x.key !== "type");
  const typeCol = cols.filter(x => x.key === "type");
  return typeCol.concat(dateCols.reverse());
};

export type FormattedMeasurements = Record<string, FormattedMeasurement>;

export const generateMeasurementColumns = ({
  dates,
  groupedByDateAndType,
  theme
}: ObservationsMeasurementColumnsProps): IColumn[] => {
  return [
    {
      key: "type",
      name: "",
      fieldName: "type",
      minWidth: 150,
      maxWidth: 170,
      isResizable: true,
      onRender: (item: MeasurementRow) => {
        return (
          <Stack styles={{ root: { alignSelf: "flex-start" } }}>
            <Text
              styles={{
                root: {
                  color: theme.palette.neutralPrimary
                }
              }}
              variant="medium"
            >
              {item.type}
            </Text>
          </Stack>
        );
      }
    },
    ...dates.map((date, index) => ({
      key: date,
      name: date,
      fieldName: date,

      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRenderHeader: () => {
        return (
          <div id={index === 0 ? LATEST_DATE_COLUMN_ID : undefined}>{date}</div>
        );
      },
      onRender: (item: MeasurementRow) => {
        const measurementsForTypeOnDate =
          groupedByDateAndType[date]?.[item.type] || [];

        if (measurementsForTypeOnDate.length > 0) {
          const formattedMeasurements: FormattedMeasurements =
            formatMeasurements(measurementsForTypeOnDate);
          switch (item.type) {
            case ObservationsLabels.BloodPressure:
              return (
                <BloodPressureDetails
                  formattedMeasurements={formattedMeasurements}
                />
              );
            case ObservationsLabels.PulseBpm:
              return (
                <PulseDetails formattedMeasurements={formattedMeasurements} />
              );
            default:
              return (
                <OtherMeasurementDetails
                  formattedMeasurements={formattedMeasurements}
                />
              );
          }
        }
        return EN_DASH;
      }
    }))
  ];
};
