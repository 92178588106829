import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Dialog,
  Heading,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text
} from "@bps/fluent-ui";

interface PSFSScoreModalProps {
  title: string;
  onClose: () => void;
  openTrendingDialog: () => void;
  sentenceText?: string;
  diagnosis: string;
  boldDiagnosis?: boolean;
  isInitialAssessment?: boolean;
}

export const PSFSScoreModal: React.FunctionComponent<PSFSScoreModalProps> = ({
  title,
  sentenceText,
  diagnosis,
  boldDiagnosis,
  onClose,
  openTrendingDialog,
  isInitialAssessment
}) => {
  const formatSummary = () => {
    if (boldDiagnosis) {
      return (
        <>
          <Text>{sentenceText}</Text>
          <Text styles={{ root: { fontWeight: "bold" } }}>{diagnosis}.</Text>
        </>
      );
    } else {
      return <Text>{diagnosis}</Text>;
    }
  };

  return (
    <Dialog
      minWidth={600}
      hidden={false}
      onDismiss={() => {
        onClose();
      }}
      dialogContentProps={{
        showCloseButton: true,
        title: <Heading variant="modal-heading">{title}</Heading>
      }}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        <MessageBar
          {...dataAttribute(DataAttributes.Element, "score-modal")}
          messageBarType={MessageBarType.info}
        >
          {formatSummary()}
        </MessageBar>
        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { paddingTop: 16 } }}
          tokens={{ childrenGap: 8 }}
        >
          {isInitialAssessment && (
            <PrimaryButton
              id="showPSFSTrending"
              onClick={() => {
                openTrendingDialog();
              }}
              text="View trending"
            />
          )}
          <DefaultButton
            onClick={() => {
              onClose();
            }}
          >
            Close
          </DefaultButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
