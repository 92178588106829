import React from "react";

import {
  ButtonsGroupChoice,
  FontIcon,
  IStyle,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { QuestionnaireItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";

import { ClinicalToolInformationPanel } from "../shared-components/ClinicalToolInformationPanel.tsx";
import { ClinicalToolAnswer } from "../utils/ClinicalToolAnswerFinder.ts";
import {
  getBgColour,
  getQuestionnaireAnswerTexts
} from "../utils/clinicalToolsUtils.ts";

export interface K10ModuleProps {
  questions: QuestionnaireItemDto[];
  answers?: ClinicalToolAnswer[];
  findAnswer: (questionId: string) => string;
  heading?: string;
  verticalAlignment?: boolean;
  isShortenQuestions?: boolean;
  invertedColorOrder?: boolean;
  summary?: string;
  viewOnly?: boolean;
}

export const K10Module: React.FC<K10ModuleProps> = ({
  questions,
  answers,
  findAnswer,
  heading,
  verticalAlignment,
  isShortenQuestions,
  invertedColorOrder,
  summary,
  viewOnly
}) => {
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const buttonFieldMarginLeft: IStyle = {
    marginLeft: verticalAlignment ? 40 : 0
  };

  const theme = useTheme();
  const isViewOnly = isViewOnlyOrDischarged || viewOnly;
  const paragraphs = [
    "What is the K10 and how is it scored?",
    `The K10 is widely recommended as a simple measure of psychological
    distress and as a measure of outcomes following treatment for common
    mental health disorders. The K10 is in the public domain and is
    promoted on the Clinical Research Unit for Anxiety and Depression
    website (www.crufad.org) as a self-report measure to identify need for
    treatment.`,
    `The K10 uses a five-value response option for each question – 'all of
    the time', 'most of the time', 'some of the time', 'a little of the
    time' and 'none of the time' which can be scored from five (5) through
    to one (1).`,
    `The maximum score is 50 indicating severe distress, the minimum score
    is 10 indicating no distress.`,
    `Questions 3 and 6 are not asked if the proceeding question was ‘none
    of the time’ in which case Questions 3 and 6 would automatically
    receive a score of one.`,
    `For further information on the K10 please refer to www.crufad.org or
Andrews, G Slade, T. Interpreting scores on the Kessler psychological
distress Scale (K10). Australian and New Zealand Journal of Public
health: 2001; 25:6: 494-497.`
  ];
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {heading && (
        <ClinicalToolInformationPanel
          title={heading}
          onRenderOpenTooltip={() => <>Click to show information about K10</>}
          onRenderSummaryMessageBar={() => {
            if (summary) {
              return (
                <MessageBar messageBarType={MessageBarType.info}>
                  {summary}
                </MessageBar>
              );
            }
            return null;
          }}
          onRenderCloseTooltip={() => (
            <Stack
              horizontal
              tokens={{ childrenGap: 4 }}
              verticalAlign="center"
            >
              <Text>Close. Click the</Text>
              <FontIcon
                iconName="info"
                styles={{
                  root: {
                    display: "block",
                    color: theme.palette.themePrimary
                  }
                }}
              />
              <Text> icon to show again</Text>
            </Stack>
          )}
          onRenderContent={() => (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <FontIcon
                iconName="info"
                styles={{
                  root: {
                    fontSize: 16,
                    display: "block"
                  }
                }}
              />
              <Stack tokens={{ childrenGap: 8 }}>
                {paragraphs.map((x, index) => {
                  return (
                    <Text key={`p${index + 1}`} variant="small">
                      {x}
                    </Text>
                  );
                })}
              </Stack>
            </Stack>
          )}
        />
      )}

      {questions.map((item, index) => {
        const qNum = item.id;
        return (
          <Stack
            key={qNum.toString()}
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 8, padding: 8 }}
            styles={{
              root: {
                backgroundColor: getBgColour(index, theme, invertedColorOrder)
              }
            }}
          >
            <Stack
              horizontalAlign="space-between"
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
              styles={{
                root: {
                  backgroundColor: getBgColour(
                    index,
                    theme,
                    invertedColorOrder
                  ),
                  padding: "10px 10px 10px 0px",
                  width: "100%"
                }
              }}
            >
              <Stack
                horizontal
                styles={{
                  root: { maxWidth: verticalAlignment ? "100%" : "50%" }
                }}
                tokens={{ childrenGap: 26 }}
              >
                <Text bold styles={{ root: { minWidth: 16 } }}>
                  {qNum}.
                </Text>
                <Text bold>{item.text}</Text>
              </Stack>
              {!answers ? (
                <ButtonsGroupSingleChoiceField
                  name={`q${qNum}`}
                  options={getQuestionnaireAnswerTexts(
                    item.answerOptions || [],
                    {
                      disabled: false,
                      showIndex: true,
                      displayIndexOnly: isShortenQuestions && !isViewOnly
                    }
                  )}
                  styles={{ label: { whiteSpace: "no-wrap" } }}
                  fieldItemStyles={{
                    item: buttonFieldMarginLeft
                  }}
                />
              ) : (
                <ButtonsGroupChoice
                  name={`q${qNum}`}
                  options={getQuestionnaireAnswerTexts(
                    item.answerOptions || [],
                    {
                      disabled: true,
                      showIndex: true,
                      displayIndexOnly: isShortenQuestions
                    }
                  )}
                  value={findAnswer(`q${qNum}`)}
                  styles={{
                    root: {
                      ...buttonFieldMarginLeft,
                      backgroundColor: getBgColour(
                        index,
                        theme,
                        invertedColorOrder
                      )
                    }
                  }}
                  onChange={() => {}}
                />
              )}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
