import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { mergeStyleSets, Spinner, Stack, Tile } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import type { UserTask } from "@stores/inbox/models/UserTask.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { sidePanelWidth } from "../inbox/InboxScreen.styles.ts";
import { FollowUpTaskFilter } from "./components/FollowupTaskFilter.tsx";
import { FollowUpTaskList } from "./components/FollowUpTaskList.tsx";
import { Task } from "./components/Task.tsx";
import { TasksScreenLabels } from "./components/Tasks.types.ts";

export const TasksInboxScreenBase: React.FC = observer(() => {
  const { taskId } = useContext(InboxScreenContext);
  return (
    <When permission={Permission.FollowUpTasks}>
      <Stack
        styles={{ root: { height: "100%" } }}
        horizontal
        tokens={{ childrenGap: 9 }}
      >
        <Tile
          styles={{
            root: {
              flexGrow: 1
            }
          }}
        >
          <Stack styles={{ root: { height: "100%", overflowX: "auto" } }}>
            <FollowUpTaskFilter>
              {() => <FollowUpTaskList />}
            </FollowUpTaskFilter>
          </Stack>
        </Tile>
        <Tile
          styles={mergeStyleSets(sidePanelWidth, {
            root: {
              padding: "16px 24px"
            }
          })}
        >
          {taskId ? (
            <DataFetcher<UserTask | undefined>
              fetch={(x: IRootStore) => x.inbox.getUserTask(taskId)}
              refetchId={taskId}
              fallback={
                <Stack grow verticalAlign="center" horizontalAlign="center">
                  <Spinner />
                </Stack>
              }
              children={(task: UserTask) => <Task task={task} />}
            />
          ) : (
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              styles={(_props, { palette }) => ({
                root: {
                  height: "100%",
                  fontStyle: "italic",
                  color: palette.neutralTertiary
                }
              })}
            >
              {TasksScreenLabels.SelectTask}
            </Stack>
          )}
        </Tile>
      </Stack>
    </When>
  );
});

const TasksInboxScreen = withFetch(
  x => [
    x.inbox.ref.outcomes.load(),
    x.inbox.ref.instructions.load(),
    x.inbox.ref.priorityTypes.load(),
    x.inbox.ref.userTaskStatus.load(),
    x.inbox.ref.userActionTypes.load(),
    x.inbox.ref.instructionsForProvider.load(),
    x.inbox.ref.instructionsForReception.load()
  ],
  TasksInboxScreenBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default TasksInboxScreen;
