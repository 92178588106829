import {
  ClaimAdjustmentDocumentStatuses,
  ClaimAdjustmentDocumentTypes
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { predicate, required } from "@libs/validation/fieldValidators.ts";
import { FieldValidator } from "@libs/validation/validation.types.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClaimAdjustmentDocumentUploadFormValue } from "@shared-types/acc/claim-adjustment-document-upload-value.types.ts";
import { ClaimAdjustmentDocumentFormValue } from "@shared-types/acc/claim-adjustment-document-value.type.ts";

export const errorStatus =
  (): FieldValidator => (value: ClaimAdjustmentDocumentStatuses) =>
    value === ClaimAdjustmentDocumentStatuses.Error
      ? "ACC returned an error with the above documents. Please delete or change."
      : undefined;

export class DocumentFormValidator extends Validator<
  ClaimAdjustmentDocumentFormValue | ClaimAdjustmentDocumentUploadFormValue
> {
  constructor() {
    super();
    this.forField(nameof("contentType"), required());
    this.forField(nameof("fileName"), required());
    this.forField(
      nameof("assessmentType"),
      predicate(
        (val, values) =>
          values?.contentType === ClaimAdjustmentDocumentTypes.RadiologyReports,
        required()
      )
    );
    this.forField(
      nameof("bodySite"),
      predicate(
        (val, values) =>
          values?.contentType === ClaimAdjustmentDocumentTypes.RadiologyReports,
        required()
      )
    );
    this.forField(nameof("status"), errorStatus());
  }
}
