import { Observer, observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import {
  Checkbox,
  ColumnActionsMode,
  dataAttribute,
  DataAttributes,
  DetailsRow,
  IDetailsRowProps,
  IGroup,
  mergeFuncStyles,
  mergeStyles,
  ScrollablePane,
  Spinner,
  Stack
} from "@bps/fluent-ui";
import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { ServiceWarningsContext } from "@modules/billing/screens/shared-components/add-service-modal/context/ServiceWarningsContext.tsx";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { getTotal } from "@modules/billing/screens/shared-components/utils/invoice.utils.ts";
import { Schedule } from "@stores/billing/models/Schedule.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { SelectedServiceDescription } from "./SelectedServiceDescription.tsx";
import { getDetailsRowStyles } from "./SelectedServiceslist.styles.tsx";
import { getIsBilled, getIsValid } from "./utils.ts";

export interface SelectedServicesListProps {
  schedules: Schedule[];
  existingInvoiceItems?: InvoiceItemDto[];
  claimId?: string;
  services: InvoiceItemFormValue[];
  onChange: (item: InvoiceItemFormValue) => void;
  onRemove: (id: string) => void;
}

export const SelectedServicesList: React.FC<SelectedServicesListProps> =
  observer(
    ({
      onChange,
      onRemove,
      schedules,
      services,
      existingInvoiceItems,
      claimId
    }) => {
      const { billing } = useStores();
      const [valid, setValid] = useState(false);
      const [invalid, setInvalid] = useState(false);
      const [billed, setBilled] = useState(false);

      const { getServiceErrorsAndWarnings } = useContext(
        ServiceWarningsContext
      );

      const [extendedServices, setExtendedServices] = useState<Set<string>>(
        new Set()
      );

      const toggleIsExtended = (id: string) => {
        const newSet = new Set(extendedServices);
        if (newSet.has(id)) {
          newSet.delete(id);
        } else {
          newSet.add(id);
        }

        setExtendedServices(newSet);
      };

      // The sort follows the UI grouping rules as follows:
      // - Items to be added to the invoice (i.e. valid but not yet billed)
      // - Items that cannot be added (i.e. not billed but invalid)
      // - Item in the invoice already (i.e. billed)

      const serviceSortOrder = (service: InvoiceItemFormValue): number => {
        const isBilled = getIsBilled(service, existingInvoiceItems);

        if (isBilled) {
          return 1;
        }

        const isValid = getIsValid(service, claimId);

        if (isValid) {
          return -1;
        }

        return 0;
      };

      // As this component is based upon an index based grouping method provided byf
      // FluentUI the order of the items must mirror the expected group ordering,
      // see the sort function above for the precedence rules
      const sortedServices = Array.from(services).sort((a, b) => {
        return serviceSortOrder(a) - serviceSortOrder(b);
      });

      enum SelectedItems {
        valid = "valid",
        invalid = "invalid",
        billed = "billed"
      }

      const onChangeCheckbox = (id: string) => () => {
        onRemove(id);
      };

      const getGroups = (): IGroup[] => {
        const validItems: InvoiceItemFormValue[] = sortedServices.filter(
          s => !getIsBilled(s, existingInvoiceItems) && getIsValid(s, claimId)
        );

        const invalidItems: InvoiceItemFormValue[] = sortedServices.filter(
          s => !getIsBilled(s, existingInvoiceItems) && !getIsValid(s, claimId)
        );

        const alreadyBilled: InvoiceItemFormValue[] = sortedServices.filter(s =>
          getIsBilled(s, existingInvoiceItems)
        );

        const startInvalidIndex = sortedServices.findIndex(
          s => !getIsValid(s, claimId)
        );

        const startBilledItems = sortedServices.findIndex(s =>
          getIsBilled(s, existingInvoiceItems)
        );

        const groups: IGroup[] = [];

        if (validItems.length > 0) {
          groups.push({
            key: SelectedItems.valid,
            startIndex: 0,
            count: validItems.length,
            name: "Items to be added to invoice",
            isCollapsed: valid
          });
        }

        if (invalidItems.length > 0) {
          groups.push({
            key: SelectedItems.invalid,
            startIndex: startInvalidIndex,
            count: invalidItems.length,
            name: "Items cannot be added to invoice",
            isCollapsed: invalid
          });
        }

        if (alreadyBilled.length > 0) {
          // gstPercent is loaded by AddServicesModal
          const billedName = `Items in invoice ${currencyFormat(
            getTotal(alreadyBilled, billing.gstPercent)
          )}`;

          groups.push({
            key: SelectedItems.billed,
            startIndex: startBilledItems,
            count: alreadyBilled.length,
            name: billedName,
            isCollapsed: billed
          });
        }

        return groups;
      };

      const renderRow = (props: IDetailsRowProps): JSX.Element => (
        <Observer>
          {() => {
            const item: InvoiceItemFormValue = props.item;
            const isValid = getIsValid(item, claimId);
            const warnings = getServiceErrorsAndWarnings(item, { claimId });

            return (
              <div onDoubleClick={onChangeCheckbox(item.serviceId)}>
                {!warnings.loading ? (
                  <DetailsRow
                    {...dataAttribute(
                      DataAttributes.Element,
                      "selected-list-item-row"
                    )}
                    styles={({ theme }) =>
                      getDetailsRowStyles({
                        theme,
                        hasError: !isValid,
                        hasWarning: isValid && !!warnings.messages?.length
                      })
                    }
                    {...props}
                  />
                ) : (
                  <Spinner />
                )}
              </div>
            );
          }}
        </Observer>
      );

      const renderDescription = (item: InvoiceItemFormValue) => {
        return (
          <SelectedServiceDescription
            item={item}
            isExtended={extendedServices.has(item.serviceId)}
            onChange={onChange}
            schedules={schedules}
            toggleIsExtended={toggleIsExtended}
            claimId={claimId}
          />
        );
      };

      const getHeaderClassName = (
        group: IGroup | undefined
      ): string | undefined => {
        if (group) {
          if (group.key === SelectedItems.billed && group.startIndex > 0)
            return mergeStyles("bp-selected-services-billed", {
              marginTop: 80
            });
          if (group.key === SelectedItems.valid)
            return "bp-selected-services-valid";
          if (group.key === SelectedItems.invalid)
            return "bp-selected-services-invalid";
        }
        return undefined;
      };

      return (
        <Stack
          {...dataAttribute(DataAttributes.Element, "selected-services-list")}
          styles={(_props, theme) => ({
            root: {
              padding: 24,
              paddingTop: 0,
              width: "50%",
              backgroundColor: theme.palette.neutralLighterAlt,
              overflowY: "auto"
            }
          })}
          tokens={{ childrenGap: 14 }}
        >
          {sortedServices.length ? (
            <Stack.Item
              grow
              styles={{
                root: {
                  position: "relative"
                }
              }}
            >
              <ScrollablePane
                styles={() => ({
                  contentContainer: {
                    overflowY: "inherit",
                    padding: "0,0,16,16"
                  }
                })}
              >
                <ShimmeredDetailsList
                  setKey={`selectedServicesList${sortedServices.length}`}
                  detailsListStyles={mergeFuncStyles(({ theme }) => ({
                    contentWrapper: {
                      backgroundColor: theme.palette.neutralLighterAlt
                    }
                  }))}
                  items={sortedServices}
                  groupProps={{
                    headerProps: {
                      styles: {
                        root: { border: "none" },
                        title: {
                          display: "flex",
                          alignItems: "center",
                          flexGrow: 1,
                          height: "100%"
                        }
                      }
                    },
                    onRenderHeader: (props, defaultRender) => {
                      if (!props || !defaultRender) {
                        return null;
                      }
                      return defaultRender({
                        ...props,
                        className: getHeaderClassName(props.group),
                        onGroupHeaderClick: group => {
                          group.key === SelectedItems.valid
                            ? setValid(!group.isCollapsed)
                            : setInvalid(!group.isCollapsed);
                          if (group.key === SelectedItems.billed) {
                            setBilled(!group.isCollapsed);
                          }
                        }
                      });
                    }
                  }}
                  isHeaderVisible={false}
                  groups={getGroups()}
                  columns={[
                    {
                      name: "Checkbox",
                      key: "checkbox",
                      minWidth: 15,
                      isResizable: true,
                      maxWidth: 15,
                      onRender: (item: InvoiceItemFormValue) => (
                        <Checkbox
                          automationAttribute="selected-service-item-checkbox"
                          checked={true}
                          onChange={onChangeCheckbox(item.serviceId)}
                        />
                      )
                    },
                    {
                      name: "Item",
                      key: "item",
                      minWidth: 60,
                      maxWidth: 60,
                      onRender: (item: InvoiceItemFormValue) => item.code
                    },
                    {
                      name: "Description",
                      key: "description",
                      minWidth: 200,
                      maxWidth: 850,
                      columnActionsMode: ColumnActionsMode.disabled,
                      onRender: renderDescription
                    }
                  ].map(c => ({
                    ...c,
                    onRender: item => (
                      <Stack
                        styles={{ root: { width: "100%" } }}
                        {...dataAttribute(DataAttributes.Element, c.key)}
                      >
                        {c.onRender ? c.onRender(item) : c.name}
                      </Stack>
                    )
                  }))}
                  onRenderRow={renderRow}
                />
              </ScrollablePane>
            </Stack.Item>
          ) : (
            <Stack
              verticalAlign="center"
              horizontalAlign="center"
              styles={{ root: { height: "100%" } }}
              grow
            >
              No items selected
            </Stack>
          )}
        </Stack>
      );
    }
  );
