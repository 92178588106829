import { isEmpty } from "lodash";
import { Observer, observer } from "mobx-react-lite";
import React, { useCallback, useContext } from "react";

import {
  DetailsHeader,
  FontIcon,
  IColumn,
  IDetailsHeaderProps,
  IShimmeredDetailsListProps,
  mergeStyles,
  mergeStyleSets,
  NoDataTile,
  ScrollablePane,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import {
  GetServiceDto,
  ServiceFilterQuery,
  ServiceRuleType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { ScheduleScreenContext } from "@modules/settings/screens/schedules/context/ScheduleScreenContext.tsx";
import { Service } from "@stores/billing/models/Service.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { HighlightedText } from "@ui-components/highlighted-text/HighlightedText.tsx";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { getFeeIncludingGst } from "../../../shared-components/fees.utils.ts";
import { getFeesStylesSet } from "./Fees.styles.ts";
import { ServiceItemLabels } from "./labels.ts";

interface FeesListProps {
  search: (query: GetServiceDto) => Promise<QueryResult<Service>>;
  queryOptions: ServiceFilterQuery;
  notFoundText?: string;
  noFeeText?: string;
  hideNotFoundLink?: boolean;
}

export const FeesList: React.FC<FeesListProps> = observer(
  ({ search, queryOptions, notFoundText, noFeeText, hideNotFoundLink }) => {
    const {
      billing,
      practice: {
        ui: { lastServiceETag }
      }
    } = useStores();

    const { viewSchedule, openFeeDialog, openFeeView, canEditService } =
      useContext(ScheduleScreenContext);

    const theme = useTheme();

    const onSearch = useCallback(
      async (query?: PagingOptions) => {
        const result = await search({
          ...query,
          ...queryOptions
        });
        return result;
      },
      [search, queryOptions]
    );

    const {
      listCellRightAlignClassName,
      listCellRightAlignHeaderClassName,
      listRowStyles,
      detailsListStyles
    } = getFeesStylesSet(theme);

    const getColumns = (): IColumn[] => {
      const filterText = queryOptions?.searchText?.trim().toLowerCase() ?? "";

      return [
        {
          name: ServiceItemLabels.itemNumber,
          onRender: (service: Service) => {
            return (
              <Navigate onClick={() => openFeeView(viewSchedule!, service)}>
                <HighlightedText text={service.code} filter={filterText} />
              </Navigate>
            );
          },
          key: "itemNumber",
          minWidth: 90,
          maxWidth: 150
        },
        {
          name: ServiceItemLabels.details,
          onRender: (service: Service) => (
            //using observer because the model update is not being caught in the callback function inside fluent.
            <Observer>
              {() => {
                let description: string = service.name || service.description;

                if (
                  filterText &&
                  !service.name.toLowerCase().includes(filterText) &&
                  service.description.toLowerCase().includes(filterText)
                ) {
                  description = service.description;
                }

                let inactiveText = "";
                if (!service.isActive) {
                  inactiveText = "Inactive";
                  if (service.nextInstance?.isActive) {
                    inactiveText += ` until ${DateTime.fromISO(
                      service.nextInstance.effectiveDate
                    ).toDayDefaultFormat()}`;
                  }
                }

                return (
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <Stack horizontal tokens={{ childrenGap: 4 }}>
                      {inactiveText && <Text>{`(${inactiveText})`}</Text>}
                      <HighlightedText
                        text={description}
                        filter={filterText}
                        cut
                      />
                    </Stack>
                    {!service.isService && (
                      <Stack.Item>
                        <FontIcon
                          iconName="Shop"
                          className={mergeStyles({
                            fontSize: theme.fonts.mediumPlus.fontSize
                          })}
                        />
                      </Stack.Item>
                    )}
                  </Stack>
                );
              }}
            </Observer>
          ),
          key: "description",
          minWidth: 180
        },
        {
          name: ServiceItemLabels.fee,
          className: listCellRightAlignClassName,
          headerClassName: listCellRightAlignHeaderClassName,
          onRender: (service: Service) => (
            <Observer>
              {() => {
                const userDefinedAmount = service.currentInstance?.rules?.find(
                  r => r.ruleType === ServiceRuleType.UserDefinedAmount
                );
                return (
                  <>
                    {currencyFormat(
                      getFeeIncludingGst(
                        userDefinedAmount?.max ??
                          service.currentNextOrMostRecentActiveInstance?.fee ??
                          0,
                        service.gstMethod,
                        billing.gstPercent
                      ),
                      { currency: "" }
                    )}
                  </>
                );
              }}
            </Observer>
          ),

          key: "fee",
          minWidth: 70,
          maxWidth: 90
        },
        {
          name: "",
          onRender: (service: Service) =>
            canEditService(service) && (
              <Navigate onClick={() => openFeeDialog(viewSchedule!, service)}>
                Edit
              </Navigate>
            ),
          key: "edit",
          minWidth: 40,
          maxWidth: 60
        }
      ];
    };

    const onRenderDetailsHeader = (props: IDetailsHeaderProps) => {
      return (
        <DetailsHeader
          {...props}
          styles={({ theme }) => ({
            root: {
              borderColor: theme.palette.neutralLight,
              selectors: {
                "& > div:hover": {
                  backgroundColor: "inherit"
                }
              }
            }
          })}
        />
      );
    };

    const onRenderRow: IShimmeredDetailsListProps["onRenderRow"] = (
      props,
      defaultRender
    ) => {
      if (!props || !defaultRender) {
        return null;
      }

      return defaultRender({
        ...props,
        styles: mergeStyleSets(
          listRowStyles,
          props.item.isActive
            ? { root: { color: theme.palette.black } }
            : { root: { fontStyle: "italic" } }
        )
      });
    };

    const renderNoResults = () => {
      if (isEmpty(queryOptions)) {
        return (
          <NoDataTile
            textProps={{
              text: "No fees"
            }}
            linkProps={{
              text: `Add a ${noFeeText ?? "new"} fee`,
              onClick: () => openFeeDialog(viewSchedule!)
            }}
            styles={{ root: { boxShadow: "none" } }}
          />
        );
      } else {
        const filterText = queryOptions?.searchText?.trim().toLowerCase() ?? "";
        return (
          <NoDataTile
            textProps={{
              text: filterText ? (
                <Stack
                  styles={{ root: { padding: 16 } }}
                  tokens={{ childrenGap: 48 }}
                >
                  <Text
                    styles={{
                      root: {
                        fontSize: theme.fonts.large.fontSize,
                        color: theme.palette.neutralTertiary
                      }
                    }}
                  >
                    {`No matches found for "${filterText}"`}
                  </Text>
                  <Text styles={{ root: { fontStyle: "normal" } }}>
                    {`Unable to find a match for "${filterText}" ${
                      notFoundText ?? "in item numbers or fee descriptions"
                    }`}
                  </Text>
                </Stack>
              ) : (
                "Unable to find a match"
              )
            }}
            linkProps={{
              hidden: !!hideNotFoundLink,
              text: `Add "${filterText}" as new fee`,
              onClick: () => openFeeDialog(viewSchedule!)
            }}
            styles={{ root: { boxShadow: "none" } }}
          />
        );
      }
    };

    return (
      <div style={{ position: "relative", flexGrow: 1 }}>
        <ScrollablePane>
          <InfiniteScrollList
            refreshKey={lastServiceETag}
            getItems={onSearch}
            onRenderRow={onRenderRow}
            onRenderNoResults={renderNoResults}
            columns={getColumns()}
            onRenderDetailsHeader={onRenderDetailsHeader}
            detailsListStyles={detailsListStyles}
          />
        </ScrollablePane>
      </div>
    );
  }
);
