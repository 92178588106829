import { Spinner } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { AllocationDefaultFormHeader } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationDefaultFormHeader.tsx";
import { AllocationForm } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.tsx";
import { BillingStore } from "@stores/billing/BillingStore.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { WriteOffStatusLabel } from "./components/WriteOffStatusLabel.tsx";
import { WriteOffFormHelper } from "./context/WriteOffFormHelper.ts";

const WriteOffScreen: React.FC = () => {
  const root = useStores();
  const invoiceId = root.routing.match(routes.accounts.invoices.writeOff.new)
    ?.params.id!;

  const fetch = async (billing: BillingStore): Promise<WriteOffFormHelper> => {
    const [invoice, writeOffNumber] = await Promise.all([
      billing.getInvoice(invoiceId),
      billing.generateWriteOffNumber()
    ]);

    const helper = new WriteOffFormHelper(root, { invoice, writeOffNumber });
    await helper.reset();
    return helper;
  };

  return (
    <DataFetcher<WriteOffFormHelper>
      fallback={<Spinner />}
      fetch={({ billing }) => fetch(billing)}
    >
      {helper => (
        <AllocationForm
          helper={helper}
          header={
            <AllocationDefaultFormHeader
              number={helper.writeOffNumber}
              close={helper.close}
              breadcrumbProps={{
                transactionRefNumber: helper.invoice.number,
                transactionNumber: helper.writeOffNumber
              }}
            />
          }
          statusLabel={() => <WriteOffStatusLabel invoice={helper.invoice} />}
        />
      )}
    </DataFetcher>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default WriteOffScreen;
