import {
  FontWeights,
  Heading,
  ITextProps,
  Link,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DrugMedicationSummaryDto } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface MedicationSummaryProps {
  summary: DrugMedicationSummaryDto;
}

const statesShortName = {
  1: "NSW",
  2: "QLD",
  3: "VIC",
  4: "SA",
  5: "WA",
  6: "TAS",
  7: "ACT",
  8: "NT"
};

const BoldText: React.FC<ITextProps> = props => (
  <Text
    styles={{ root: { fontWeight: FontWeights.semibold, marginRight: 8 } }}
    {...props}
  />
);

const breakListItems = (text: string) => {
  const LIST_REGX = /(\((?:i{1,3}v?|[1-6]|[a-f])\))/g;
  return text.replace(LIST_REGX, "<br/>$1");
};

const boldAndOr = (text: string) => {
  const AND_OR_REGX = /\b(AND|OR)\b/g;
  return text.replace(AND_OR_REGX, "<strong>$1</strong>");
};

const breakOnStars = (text: string) => {
  return text.replace(/\*/g, "<br/>*");
};

const breakCriteria = (text: string) => {
  let newText = text;
  const breakOn = [
    "Treatment Phase",
    "Clinical criteria",
    "Population criteria"
  ];
  for (const breaker of breakOn) {
    const regex = new RegExp(`(${breaker}:?)`, "g");
    newText = newText.replace(
      regex,
      "<br/><strong style='margin-right: 4px'>$1</strong>"
    );
  }
  return newText;
};

const formatPbsNotes = (text: string) => {
  return breakListItems(boldAndOr(breakOnStars(text)));
};

const formatRestrictions = (text: string) => {
  return breakListItems(breakCriteria(boldAndOr(breakOnStars(text))));
};

export const MedicationSummaryComponent: React.FC<MedicationSummaryProps> = ({
  summary: {
    productPack,
    productIngredient,
    pbsRestriction,
    pbsNote,
    pbsCaution,
    stateSchedule
  }
}) => {
  const { drugs } = useStores();

  const strengths = productIngredient
    .filter(ingredient => !!ingredient.strength)
    .map(ingredient => `${ingredient.strength}${ingredient.strengthUnit}`);

  const pbsListingText = drugs.ref.pbsListings.get(productPack.pbsListing)
    ?.longText;

  const productInformationUrl = productPack.productInformationUrl;
  const consumerInformationUrl = productPack.consumerInformationUrl;

  const pbsListingArray = [
    pbsListingText,
    "-",
    productPack.quantity,
    "and",
    productPack.rpts,
    "repeats"
  ];
  return (
    <Stack>
      <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 4 }}>
        <Link
          disabled={!productInformationUrl}
          href={productInformationUrl}
          target="_blank"
        >
          PI
        </Link>
        <Link
          disabled={!consumerInformationUrl}
          href={consumerInformationUrl}
          target="_blank"
        >
          CMI
        </Link>
      </Stack>

      <Stack>
        <Heading variant="section-heading">{productPack.medication}</Heading>
        {productIngredient && productIngredient.length > 0 && (
          <Stack horizontal>
            <BoldText>Generic name:</BoldText>
            <Text styles={{ root: { paddingBottom: 16, marginRight: 8 } }}>
              {productPack.genericName}
            </Text>
            <Text>{strengths.join(";")}</Text>
          </Stack>
        )}
      </Stack>
      <Stack horizontal>
        <BoldText>Schedule:</BoldText>
        {stateSchedule.map((state, i) => (
          <Text key={state.state} styles={{ root: { marginRight: 8 } }}>
            {statesShortName[state.state]}: {state.schedule}
            {i < stateSchedule.length - 1 && ","}
          </Text>
        ))}
      </Stack>
      <Text>
        <BoldText>PBS Listing:</BoldText>
        <Text styles={{ root: { fontStyle: "italic" } }}>
          {pbsListingArray.join(" ")}
        </Text>
      </Text>
      {pbsRestriction &&
        pbsRestriction.length > 0 &&
        pbsRestriction?.map(res => (
          <Text key={res.restrictionId}>
            <BoldText>Restriction:</BoldText>
            <span
              dangerouslySetInnerHTML={{
                __html: formatRestrictions(res.description)
              }}
            />
          </Text>
        ))}
      {pbsNote && pbsNote.length > 0 && (
        <Text>
          <Stack>
            <BoldText>Notes:</BoldText>
          </Stack>
          {pbsNote?.map(note => (
            <div
              key={note.pbsNoteId}
              dangerouslySetInnerHTML={{
                __html: formatPbsNotes(note.description)
              }}
            />
          ))}
        </Text>
      )}
      {pbsCaution && pbsCaution.length > 0 && (
        <Text>
          <Stack>
            <BoldText>Cautions:</BoldText>
          </Stack>
          {pbsCaution?.map(caution => (
            <Text key={caution.pbsCautionId}>{caution.description}</Text>
          ))}
        </Text>
      )}
    </Stack>
  );
};

export const MedicationSummary = withFetch(
  x => x.drugs.ref.pbsListings.load(),
  MedicationSummaryComponent
);
