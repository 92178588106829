import { observer, Observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  ButtonsGroupChoice,
  Card,
  CenteredBox,
  CenteredLargeSpinner,
  Option,
  ScrollablePane,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  BhbProviderDto,
  ProviderOnlineStatus,
  ProviderOnlineStatusText
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { UserOnlineBookingProfileFormHelper } from "@modules/settings/screens/users/components/user-online-booking-profile/UserOnlineBookingProfileFormHelper.ts";
import { UserProfileSection } from "@modules/settings/screens/users/components/UserBreadcrumbs.tsx";
import { BhbAppointmentType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import {
  DetailsRowHelper,
  DetailsRowWrapper
} from "@ui-components/ShimmeredDetailsList/DetailsRowWrapper.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { BhbProvidersOnlineFilter } from "../types/bhb-providers-online-filter.interface.ts";
import { BhbProvidersFilter } from "./BhbProvidersFilter.tsx";
import { AppointmentTypeTagPicker } from "./components/AppointmentTypeTagPicker.tsx";

export interface BhbProvidersData {
  providers: BhbProviderDto[];
  apptTypes: Option<BhbAppointmentType>[];
  userSettings: UserSetting[];
  users: User[];
}

export const BhbProvidersCard: FunctionComponent = observer(() => {
  const { bhb, core, userExperience } = useStores();
  const theme = useTheme();
  const getItemsForProviders = (
    providerData: BhbProviderDto[],
    filters: BhbProvidersOnlineFilter | undefined
  ): BhbProviderDto[] => {
    const providers = providerData.filter(provider =>
      filterData(provider, filters)
    );

    return providers;
  };

  const filterData = (
    provider: BhbProviderDto,
    filters: BhbProvidersOnlineFilter | undefined
  ): boolean => {
    if (!filters) {
      return true;
    }

    const { search, onlineAvailability, providers, appointmentTypes } = filters;

    if (
      search &&
      !(
        provider.displayName.toLowerCase().includes(search.toLowerCase()) ||
        provider.firstName.toLowerCase().includes(search.toLowerCase()) ||
        provider.lastName.toLowerCase().includes(search.toLowerCase())
      )
    ) {
      return false;
    }

    if (onlineAvailability && onlineAvailability.length > 0) {
      const statusMap = {
        ShowAndBook: ProviderOnlineStatus.YES,
        ShowAndCall: ProviderOnlineStatus.CALL,
        DontShow: ProviderOnlineStatus.NO
      };

      const selectedStatus = onlineAvailability.find(
        status => provider.providerOnlineStatus === statusMap[status]
      );
      if (!selectedStatus) {
        return false;
      }
    }

    if (providers && providers.length > 0 && !providers.includes(provider.id)) {
      return false;
    }

    if (
      appointmentTypes &&
      appointmentTypes.length > 0 &&
      !appointmentTypes.some(type =>
        provider.appointmentTypes.some(appType => appType.id === type)
      )
    ) {
      return false;
    }

    return true;
  };

  const buildColumns = ({
    apptTypes,
    userSettings,
    users
  }: BhbProvidersData) => {
    return [
      {
        key: "name",
        name: "Provider",
        fieldName: "name",
        minWidth: 160,
        isResizable: true,
        onRender: (item: { data: BhbProvider }) => (
          <Navigate
            to={{
              pathname: routes.settings.users.user.path({
                id: item.data.id
              }),
              hash: UserProfileSection.OnlineBookingProfile
            }}
          >
            {item.data.displayName}
          </Navigate>
        )
      },
      {
        key: "appt-types",
        name: "Appointment types",
        fieldName: "appt-types",
        minWidth: 640,
        isResizable: true,
        onRender: (item: { data: BhbProvider; helper: DetailsRowHelper }) => (
          <Observer>
            {() => (
              <AppointmentTypeTagPicker
                apptTypes={apptTypes}
                bhbProvider={item.data}
                detailsRowHelper={item.helper}
              />
            )}
          </Observer>
        )
      },

      {
        key: "accept-online-bookings",
        name: "Online availability",
        fieldName: "accept-online-bookings",
        minWidth: 360,
        isResizable: true,
        onRender: (item: { data: BhbProvider; helper: DetailsRowHelper }) => {
          const setting = userSettings.find(x => x.id === item.data.id);
          const user = users.find(x => x.id === item.data.id);

          const showOnCalendarDisabled = !(
            !!setting?.showOnCalendar && user?.status === UserStatus.Active
          );

          const providerOnlineStatus = [
            {
              key: ProviderOnlineStatus.YES,
              text: ProviderOnlineStatusText.ShowAndBook,
              disabled: showOnCalendarDisabled
            },
            {
              key: ProviderOnlineStatus.CALL,
              text: ProviderOnlineStatusText.ShowAndCall
            },
            {
              key: ProviderOnlineStatus.NO,
              text: ProviderOnlineStatusText.DontShow
            }
          ];

          return (
            <Observer>
              {() => (
                <ButtonsGroupChoice<ProviderOnlineStatus | undefined>
                  key={item.data.id}
                  equalWidth
                  options={providerOnlineStatus}
                  value={item.data.providerOnlineStatus}
                  onChange={(
                    providerOnlineStatus: ProviderOnlineStatus | undefined
                  ) => {
                    const updateProvider = {
                      ...item.data.dto,
                      providerOnlineStatus
                    };
                    item.helper.setPromise(
                      bhb.updateProviderForLocation(updateProvider)
                    );
                  }}
                />
              )}
            </Observer>
          );
        }
      }
    ];
  };

  const fetch = async () => {
    const apptTypesForLocation = await bhb.getAppointmentTypesForLocation();
    const onlineAppointmentTypes = apptTypesForLocation
      ? UserOnlineBookingProfileFormHelper.reduceToOnlineApptTypes(
          apptTypesForLocation
        )
      : [];

    const providers = (await bhb.getProvidersForLocation())?.sort((a, b) =>
      a.lastName.localeCompare(b.lastName)
    );

    if (!providers) throw new Error("No Bhb providers found.");

    const [userSettings, users] = await Promise.all([
      await userExperience.getUserSettingsByUserIds(providers.map(x => x.id)),
      await core.fetchUsers({
        showOnCalendar: true,
        statusCodes: [UserStatus.Active],
        businessRoleClasses: [BusinessRoleClasses.Provider]
      })
    ]);

    return {
      providers,
      apptTypes: onlineAppointmentTypes,
      userSettings,
      users
    };
  };

  return (
    <Card
      styles={{
        subComponentStyles: {
          tile: {
            content: {
              display: "flex",
              height: "68vh",
              padding: 16,
              flexDirection: "column"
            }
          }
        }
      }}
      heading="Providers"
      headingLevel="modal-heading"
    >
      <DataFetcher<BhbProvidersData>
        fetch={fetch}
        fallback={<CenteredLargeSpinner />}
      >
        {data => (
          <Observer>
            {() => (
              <BhbProvidersFilter data={data}>
                {({ values }) => {
                  const providers = Array.from(
                    getItemsForProviders(data.providers, values)
                  );
                  if (providers.length > 0) {
                    return (
                      <Stack
                        grow
                        styles={{
                          root: {
                            position: "relative"
                          }
                        }}
                      >
                        <ScrollablePane>
                          <ShimmeredDetailsList
                            stickyHeader
                            items={providers}
                            onRenderRow={props => (
                              <DetailsRowWrapper
                                props={props}
                                detailsRowStyles={{
                                  cell: {
                                    whiteSpace: "wrap"
                                  },
                                  fields: {
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.neutralLighter
                                    }
                                  }
                                }}
                              />
                            )}
                            columns={buildColumns(data)}
                            detailsListStyles={{
                              root: { overflowX: "hidden" }
                            }}
                          />
                        </ScrollablePane>
                      </Stack>
                    );
                  } else {
                    return (
                      <CenteredBox>
                        <DefaultNoResultsTile defaultText="No records found" />
                      </CenteredBox>
                    );
                  }
                }}
              </BhbProvidersFilter>
            )}
          </Observer>
        )}
      </DataFetcher>
    </Card>
  );
});
