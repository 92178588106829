import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";

import { SyncFusionDocumentEditorContext } from "./context/SyncFusionDocumentEditorContext.tsx";

export interface SyncFusionMergeFieldsFilterState {
  search?: string;
  categories?: string[];
  hideFieldsWithNoData?: boolean;
}

export interface SyncFusionMergeFieldsFilterProps
  extends Pick<FilterBarProps, "children"> {
  onSearch: (values: SyncFusionMergeFieldsFilterState) => void;
}

export const nameOf = nameOfFactory<SyncFusionMergeFieldsFilterState>();

export const SyncFusionMergeFieldsFilter: React.FC<SyncFusionMergeFieldsFilterProps> =
  observer(({ onSearch, children }) => {
    const { groups } = useContext(SyncFusionDocumentEditorContext);

    const categories = groups?.map(x => {
      return { key: x.key, text: x.name };
    });

    const filterBarItems: Item<SyncFusionMergeFieldsFilterState>[] = [
      {
        type: "searchBox",
        name: nameOf("search"),
        props: {
          placeholder: "Filter by name",
          styles: { root: { width: "100%" } }
        }
      },
      {
        type: "optionsSelect",
        name: nameOf("categories"),
        props: {
          placeholder: "Category",
          label: "Category",
          options: categories,
          hideSearchOption: true,
          multiSelect: true,
          calloutWidth: 300,
          listHeight: 300
        }
      },
      {
        type: "toggle",
        name: nameOf("hideFieldsWithNoData"),
        props: {
          label: "Hide fields with no data",
          onText: "On",
          offText: "Off",
          inlineLabel: false
        }
      }
    ];

    return (
      <FilterBar<SyncFusionMergeFieldsFilterState>
        onChange={({ values }) => {
          onSearch(values);
        }}
        styles={{
          toggle: { minWidth: 200 },
          searchBox: { minWidth: 150 },
          calloutItem: {
            width: "100%"
          },
          callout: {
            width: 400
          }
        }}
        items={filterBarItems}
        children={children}
        shrinkVersion
      />
    );
  });
