import {
  DetailsRow,
  FontWeights,
  IColumn,
  IDetailsFooterProps,
  Text
} from "@bps/fluent-ui";
import {
  AllocationItemsColumns,
  AllocationItemsColumnsKeys
} from "@modules/billing/screens/allocation/components/AllocationForm.types.tsx";
import { AllocationTotalValueLabel } from "@modules/billing/screens/allocation/components/AllocationTotalValueLabel.tsx";
import { AllocationFieldValues } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import {
  getAllocatedAmount,
  getBalanceOwing,
  getCreditedAmount,
  getTotalFee,
  getVoidedAmount
} from "@modules/billing/screens/shared-components/allocation-field/utils.tsx";

import { ColumnOptions } from "./types.ts";

interface AllocationsCustomFooterProps<K> {
  items: K[];
  detailsFooterProps: IDetailsFooterProps;
  columns: IColumn[];
  columnOptions?: ColumnOptions;
}

export const AllocationsCustomFooter = <K extends {}>({
  detailsFooterProps,
  columnOptions,
  columns,
  items
}: AllocationsCustomFooterProps<K>) => {
  return (
    <DetailsRow
      {...detailsFooterProps}
      columns={columns}
      styles={{
        root: {
          textAlign: "end",
          fontWeight: FontWeights.semibold,
          borderBottom: "none"
        }
      }}
      onRenderItemColumn={(
        _item: AllocationFieldValues,
        _index: number,
        column: IColumn
      ) => {
        if (column.key === AllocationItemsColumnsKeys.description)
          return (
            <Text
              styles={{
                root: {
                  fontWeight: FontWeights.semibold,
                  width: "100%"
                }
              }}
            >
              {AllocationItemsColumns.totalPrefixed}
            </Text>
          );
        if (column.key === AllocationItemsColumnsKeys.totalFee)
          return (
            <AllocationTotalValueLabel<K>
              fontWeight={FontWeights.semibold}
              items={items}
              iterator={getTotalFee}
              dataAttr="billing-totals-total-inc-gst-footer"
            />
          );
        if (column.key === AllocationItemsColumnsKeys.total) {
          return (
            <AllocationTotalValueLabel<K>
              fontWeight={FontWeights.semibold}
              items={items}
              iterator={getAllocatedAmount}
            />
          );
        }
        if (column.key === AllocationItemsColumnsKeys.credit) {
          return (
            <AllocationTotalValueLabel<K>
              fontWeight={FontWeights.semibold}
              items={items}
              iterator={getCreditedAmount}
            />
          );
        }
        if (column.key === AllocationItemsColumnsKeys.owing) {
          return (
            <AllocationTotalValueLabel<K>
              fontWeight={FontWeights.semibold}
              items={items}
              iterator={
                columnOptions?.filtersToShow?.voided
                  ? getVoidedAmount
                  : getBalanceOwing
              }
            />
          );
        }
        return null;
      }}
      item={{}}
      itemIndex={-1}
    />
  );
};
