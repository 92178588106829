import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  FontWeights,
  Persona,
  PersonaSize,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { PatientNoticesAddButton } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesAddButton.tsx";
import { PatientNoticesContextProvider } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesContext.tsx";
import {
  NoticeType,
  PatientNoticesModel
} from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { PatientNoticesTable } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTable.tsx";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";

import { SecondColumnWrapper } from "../../SecondColumnWrapper.tsx";
import { AppointmentFormContext } from "../context/AppointmentFormContext.ts";

interface SelectedGroupAttendeeProp {
  contact: Contact;
}

export const SelectedGroupAttendeeBase: React.FC<SelectedGroupAttendeeProp> =
  observer(({ contact }) => {
    const theme = useTheme();
    const { onShowEdit } = useContext(AppointmentFormContext);

    return (
      <SecondColumnWrapper childrenGap={5}>
        <Stack
          styles={{
            root: {
              border: `1px solid ${theme.palette.neutralSecondaryAlt}`,
              padding: 8,
              gap: 10,
              background: theme.palette.white,
              flexGrow: 1,
              justifyContent: "flex-start",
              height: "max-content"
            }
          }}
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack
            styles={{
              root: {
                cursor: "pointer",
                selectors: {
                  "&:hover ": {
                    backgroundColor: theme.semanticColors.disabledBackground,
                    textDecoration: "none"
                  },
                  "&:active:hover ": {
                    textDecoration: "none"
                  }
                },
                padding: 5
              }
            }}
            onClick={() => {
              onShowEdit(PatientCardIds.patientHeader, contact.id);
            }}
          >
            <Persona
              text={contact.name}
              id={contact.id}
              secondaryText={contact.phone}
              size={PersonaSize.size40}
              imageInitials={contact.initials}
              styles={{
                root: {
                  paddingLeft: 3
                },
                primaryText: {
                  fontWeight: FontWeights.semibold,
                  maxWidth: 230
                }
              }}
            />
          </Stack>

          <PatientNoticesAddButton filter={NoticeType.Admin} />
          <PatientNoticesTable
            showActions
            padding={0}
            childrenGap={0}
            setPreventDismissOnDelete={() => {}}
            filter={NoticeType.Admin}
          />
        </Stack>
      </SecondColumnWrapper>
    );
  });

export const SelectedGroupAttendee: React.FC = () => {
  const { selectedGroupAttendeeId } = useContext(AppointmentFormContext);
  const { practice } = useStores();
  return (
    <ContactFetcher contactId={selectedGroupAttendeeId}>
      {contact => {
        return (
          <PatientNoticesContextProvider
            value={
              new PatientNoticesModel(practice, {
                patientId: contact.id,
                withSystemNotices: true
              })
            }
          >
            <SelectedGroupAttendeeBase contact={contact} />
          </PatientNoticesContextProvider>
        );
      }}
    </ContactFetcher>
  );
};
