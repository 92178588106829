import { FunctionComponent } from "react";
import { Field, useForm } from "react-final-form";

import {
  PatientEditFormValues,
  patientFormNameOf
} from "@modules/practice/screens/contact-details/shared-components/edit/PatientEditFormValues.tsx";
import { Contact } from "@stores/practice/models/Contact.ts";

import {
  ProfilePicture,
  ProfilePictureProps
} from "../PersonaProfilePicture.tsx";

interface DemographicProfilePictureFieldProps
  extends Pick<ProfilePictureProps, "styles" | "onRenderNoImageInitials"> {
  contact?: Contact;
  imageInitials: string | undefined;
}

export const DemographicProfilePictureField: FunctionComponent<
  DemographicProfilePictureFieldProps
> = ({ contact, imageInitials, ...rest }) => {
  const form = useForm<PatientEditFormValues>();

  return (
    <Field name={patientFormNameOf("profilePictureUrl")}>
      {({ input: { value } }) => (
        <ProfilePicture
          onChange={url =>
            form.change(patientFormNameOf("profilePictureUrl"), url)
          }
          contact={contact}
          profilePictureUrl={value}
          imageInitials={imageInitials}
          {...rest}
        />
      )}
    </Field>
  );
};
