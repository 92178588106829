import {
  getColumnClampLinesSelector,
  IDetailsRowProps,
  IDetailsRowStyleProps,
  IDetailsRowStyles,
  IStyleFunctionOrObject,
  ITextFieldStyles,
  ITheme,
  mergeFuncStyles,
  mergeStyleSets
} from "@bps/fluent-ui";

import { AllocationFieldValues } from "../allocation-field/types.ts";
import {
  FooterOptions,
  InvalidItemsIndexes
} from "./AllocationListBase.types.tsx";

export const getItemListRowStyles =
  (
    props: IDetailsRowProps,
    options: {
      warningItemsIndexes?: InvalidItemsIndexes;
      invalidItemsIndexes?: InvalidItemsIndexes;
    },
    getExtraRowStyles?: (
      props: IDetailsRowProps
    ) =>
      | IStyleFunctionOrObject<IDetailsRowStyleProps, IDetailsRowStyles>
      | undefined
  ) =>
  ({ theme }: { theme: ITheme }) => {
    const items = props.selection.getItems() as AllocationFieldValues[];
    const findIndex = items.findIndex(
      item =>
        (item as AllocationFieldValues).invoiceNumber ===
        (props.item as AllocationFieldValues).invoiceNumber
    );

    const hasWarning = options.warningItemsIndexes?.includes(props.itemIndex);
    const hasError = options.invalidItemsIndexes?.includes(props.itemIndex);

    let backgroundColor: string | undefined;
    let color: string | undefined;

    if (hasWarning) {
      backgroundColor = theme.semanticColors.warningBackground;
    }
    if (hasError) {
      backgroundColor = theme.semanticColors.errorBackground;
      color = theme.semanticColors.errorText;
    }

    return mergeStyleSets(
      {
        root: {
          minHeight: 18,
          selectors: {
            "& .clampThreeLines": getColumnClampLinesSelector(3)
          }
        },
        cell: { minHeight: 18 },
        fields: {
          backgroundColor,
          color,
          borderTop:
            findIndex === props.itemIndex && props.itemIndex !== 0
              ? `1px ${theme.palette.neutralLight} solid`
              : ""
        }
      },
      getExtraRowStyles && getExtraRowStyles(props)
    );
  };

export const getDetailsListStyles = (
  footerOptions?: FooterOptions,
  invalidItemsIndexes?: InvalidItemsIndexes
) =>
  mergeFuncStyles(({ theme }) => ({
    root: footerOptions
      ? {
          borderBottom: "1px solid",
          // set error border color if one of  the items is invalid
          borderBottomColor: invalidItemsIndexes?.length
            ? theme.semanticColors.errorText
            : theme.palette.neutralLighter,
          paddingBottom: 6
        }
      : {
          borderTop: "1px solid",
          borderTopColor: "transparent"
        },
    contentWrapper: {
      borderBottom: "1px solid",
      // set error border color if one of the items is invalid
      borderBottomColor: invalidItemsIndexes?.length
        ? theme.semanticColors.errorText
        : theme.palette.neutralLighter,
      selectors: {
        "& .ms-List-cell ": {
          minHeight: 18
        }
      }
    }
  }));

export const headerFooterRowStyles = () =>
  mergeStyleSets({
    root: {
      minHeight: 0,
      borderBottom: "none"
    },
    cell: {
      // changed from "end" to "right" as both IE and EDGE doesnt support
      // textAlign: "and", but fabric use it
      textAlign: "right",
      lineHeight: 19,
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 0
    }
  });

export const getDetailsHeader =
  (hasHeaderOptions: boolean, invalidItemsIndexes?: InvalidItemsIndexes) =>
  ({ theme }: { theme: ITheme }) => {
    return mergeStyleSets({
      root: {
        borderTop: "1px solid",
        // set error border color if one of the items is invalid
        borderColor: invalidItemsIndexes?.length
          ? theme.semanticColors.errorText
          : theme.palette.neutralLighter
      }
    });
  };

export const disableFieldStyle: Partial<ITextFieldStyles> = {
  fieldGroup: {
    position: "relative",
    borderColor: "rgb(244, 244, 244)",
    selectors: {
      "&:hover": {
        borderColor: "rgb(244, 244, 244)"
      },
      "&:after": {
        position: "absolute",
        content: "''",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(244, 244, 244, 0.6)",
        cursor: "default"
      }
    }
  }
};
