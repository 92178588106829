export enum ClinicalTaskButtonLabels {
  Title = "Clinical tasks",
  AddTask = "Add task",
  Edit = "Edit",
  Delete = "Delete",
  Close = "Close",
  Complete = "Mark as completed",
  Completed = "Completed",
  Unlock = "Unlock"
}
