import {
  CareGiverDto,
  CareRecipientDto,
  EnduringPowerOfAttorneyDto,
  YES
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  required,
  requiredArrayNoDuplicateValues
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  PatientDemographicValues,
  SocialFormValues
} from "../social/SocialFormValues.ts";

export class SocialFormValidator extends Validator<SocialFormValues> {
  readonly nameOf = nameOfFactory<SocialFormValues>();

  constructor() {
    super();

    const validator = new PatientDemographicValidator();

    this.forField(this.nameOf("patientDemographic"), validator.validate);
  }
}

class PatientDemographicValidator extends Validator<PatientDemographicValues> {
  readonly nameOf = nameOfFactory<PatientDemographicValues>();

  constructor() {
    super();

    this.forField(this.nameOf("powerOfAttorneys"), required(), {
      when: (_value, parent) => parent.isEnduringPowerOfAttorney === YES
    });

    const careGiversValidator = new CareGiversValidator();
    this.forArrayField(this.nameOf("careGivers"), careGiversValidator.validate);

    const careRecipientsValidator = new CareRecipientsValidator();
    this.forArrayField(
      this.nameOf("careRecipients"),
      careRecipientsValidator.validate
    );

    const attorneysValidator = new PowerOfAttorneysValidator();
    this.forArrayField(
      this.nameOf("powerOfAttorneys"),
      attorneysValidator.validate
    );
  }
}

class CareGiversValidator extends Validator<CareGiverDto> {
  readonly nameOf = nameOfFactory<CareGiverDto>();
  constructor() {
    super();
    this.forField(this.nameOf("id"), required());
    this.forField(
      this.nameOf("careGiverTypes"),
      requiredArrayNoDuplicateValues()
    );
  }
}

class CareRecipientsValidator extends Validator<CareRecipientDto> {
  readonly nameOf = nameOfFactory<CareRecipientDto>();
  constructor() {
    super();
    this.forField(
      this.nameOf("careRecipientTypes"),
      requiredArrayNoDuplicateValues()
    );
  }
}

class PowerOfAttorneysValidator extends Validator<EnduringPowerOfAttorneyDto> {
  readonly nameOf = nameOfFactory<EnduringPowerOfAttorneyDto>();
  constructor() {
    super();
    this.forField(this.nameOf("id"), required());
  }
}
