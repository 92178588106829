// eslint-disable-next-line import/extensions
import isEqual from "lodash/isEqual";
import { parse } from "query-string";
import { ReactNode, useRef } from "react";

import { FormMethods, NotifyState } from "@bps/fluent-ui";
import { useDebounce } from "@bps/utils";
import { GetTransactionsArgs } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import { TransactionFilterContext } from "./context/TransactionFilterContext.ts";
import { TransactionFilterHelper } from "./context/TransactionFilterHelper.ts";
import {
  SetFilter,
  TransactionFilterBase
} from "./TransactionFilterBase.types.ts";

export interface onFilterChangeParams<TFilter extends TransactionFilterBase> {
  field: keyof TFilter;
  value: TFilter[keyof TFilter];
  values: TFilter;
  state: NotifyState;
  actions: Pick<FormMethods, "setValue" | "reset">;
}

export interface TransactionFilterBaseProps<
  TFilter extends TransactionFilterBase
> extends Pick<FilterBarProps<TFilter>, "items" | "presets"> {
  children: ReactNode;
  onChange?: (
    param: onFilterChangeParams<TFilter>,
    setFilter: SetFilter
  ) => void;
  defaultFilter: TransactionFilterBase;
  initialValues?: FilterBarProps["initialValues"];
  parseToGetByArgs?: (search: string) => GetTransactionsArgs;
}

export const TransactionFilterBarBase = <
  TFilter extends TransactionFilterBase
>({
  children,
  items,
  defaultFilter,
  initialValues,
  onChange,
  parseToGetByArgs,
  presets
}: TransactionFilterBaseProps<TFilter>) => {
  const store = useStores();
  const helper = useRef(
    new TransactionFilterHelper<TFilter>(store, {
      items,
      parseToGetByArgs
    })
  );

  const { routing } = store;
  const filterQuery = parse(routing.location.search);

  const baseInitialValues = useRef({
    ...helper.current.getBaseInitialValues(filterQuery),
    ...initialValues
  });

  const handleFilterChanged = useDebounce(
    (param: {
      field: keyof TFilter;
      value: TFilter[keyof TFilter];
      values: TFilter;
      state: NotifyState;
      actions: Pick<FormMethods, "setValue" | "reset">;
    }) => {
      const { field, values } = param;

      if (field === "startTime" || field === "endTime") {
        helper.current.onBaseFilterChange({ field, values });
        return;
      }

      onChange
        ? onChange(param, helper.current.setFilter)
        : helper.current.setFilter({ [field]: values[field] });
    }
  );

  return (
    <TransactionFilterContext.Provider value={helper.current}>
      <FilterBar<TFilter>
        items={items}
        initialValues={baseInitialValues.current}
        onChange={handleFilterChanged}
        styles={{
          peoplePicker: { minWidth: 400 }
        }}
        maxItemWidth={120}
        customResetButtonCondition={(_actions, values) =>
          isEqual(defaultFilter, values)
        }
        onReset={(actions, values) => {
          if (!isEqual(defaultFilter, values)) {
            actions.reset(defaultFilter);
            routing.pushQueryStringParams(defaultFilter);
          }
        }}
        presets={presets}
      >
        {() => children}
      </FilterBar>
    </TransactionFilterContext.Provider>
  );
};
