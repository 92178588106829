import { Observer } from "mobx-react-lite";
import { FC } from "react";
import { FormSpy } from "react-final-form";

import { PivotItem, PivotTabs, TopBarDefaultHeader } from "@bps/fluent-ui";
import {
  PermanentClinicalTab,
  SOTAPSectionText,
  SOTAPSectionTextShort
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";

import { SOTAPFollowOnFormModel } from "./context/SOTAPFollowOnFormModel.ts";
import { SOTAPFormModel } from "./context/SOTAPFormModel.ts";
import { SOTAPConditionHeading } from "./SOTAPConditionHeading.tsx";

interface SOTAPFormHeaderProps {
  model: SOTAPFormModel | SOTAPFollowOnFormModel;
}

export const SOTAPFormHeader: FC<SOTAPFormHeaderProps> = ({ model }) => {
  const { clinicalRecord } = usePatientRecordScreenContext();

  const handlePivotClicked = (item: PivotItem): void => {
    const selectedValue = item.props && item.props.itemKey;
    if (selectedValue === model.currentSection) {
      return;
    }

    switch (selectedValue) {
      case SOTAPSectionText.Subjective:
        return model.setCurrentSection(SOTAPSectionText.Subjective);
      case SOTAPSectionText.Objective:
        return model.setCurrentSection(SOTAPSectionText.Objective);
      case SOTAPSectionText.TAP:
        return model.setCurrentSection(SOTAPSectionText.TAP);
    }
  };

  const renderPivotTabs = () => {
    const subjectiveText = clinicalRecord.isFollowOnEncounter
      ? SOTAPSectionTextShort.Subjective
      : SOTAPSectionText.Subjective;

    const objectiveText = clinicalRecord.isFollowOnEncounter
      ? SOTAPSectionTextShort.Objective
      : SOTAPSectionText.Objective;

    const tapText = clinicalRecord.isFollowOnEncounter
      ? SOTAPSectionTextShort.TAP
      : SOTAPSectionText.TAP;

    return (
      <Observer>
        {() => {
          return (
            <PivotTabs
              onLinkClick={handlePivotClicked}
              selectedKey={model.currentSection}
            >
              <PivotItem
                headerText={subjectiveText}
                itemKey={SOTAPSectionText.Subjective}
              />
              <PivotItem
                headerText={objectiveText}
                itemKey={SOTAPSectionText.Objective}
              />
              <PivotItem headerText={tapText} itemKey={SOTAPSectionText.TAP} />
            </PivotTabs>
          );
        }}
      </Observer>
    );
  };

  const isDischargeInProgressOrCompleted =
    clinicalRecord.dischargeInProgressOrCompleted();
  return (
    <TopBarDefaultHeader
      heading={clinicalRecord.isFollowOnEncounter ? "Follow up" : "Initial"}
      leftContainer={renderPivotTabs()}
      rightContainerStyles={{
        root: {
          flexGrow: 0.5,
          justifyContent: clinicalRecord.isFollowOnEncounter
            ? "space-between"
            : "flex-end"
        }
      }}
      rightContainer={
        <>
          <SOTAPConditionHeading />
          <FormSpy
            subscription={{
              errors: true
            }}
          >
            {({ errors }) => (
              <Observer>
                {() => {
                  const isDirty =
                    // STOAP form
                    clinicalRecord.stashedClinicalData?.dirtySOTAPForm ||
                    // SOTAP FollowOn form
                    clinicalRecord.clinicalRecordFormsValuesStash.dirtyForms.get(
                      PermanentClinicalTab.SOTAP
                    );

                  const isFormValid: boolean | undefined =
                    errors && Object.keys(errors).length === 0;

                  return (
                    <FormSubmitButtons
                      hideButtonsSeparator
                      hideCancel
                      submitButtonProps={{ disabled: !isDirty || !isFormValid }}
                      hideSubmit={isDischargeInProgressOrCompleted}
                      styles={{
                        root: {
                          padding: 0,
                          margin: 0
                        }
                      }}
                    />
                  );
                }}
              </Observer>
            )}
          </FormSpy>
        </>
      }
      hideBackButton
    />
  );
};
