import React from "react";
import { useFormState } from "react-final-form";

import { Stack, useTheme } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import {
  ReportColumnChooserList,
  ReportColumnChooserListProps
} from "./ReportColumnChooserList.tsx";
import { ReportColumnChooserValues } from "./ReportColumnChooserValues.ts";

interface TableSettings {
  available: Omit<ReportColumnChooserListProps, "items" | "height" | "styles">;
  grouped: Omit<ReportColumnChooserListProps, "items" | "height" | "styles">;
  selected: Omit<ReportColumnChooserListProps, "items" | "height" | "styles">;
}

const nameOf = nameOfFactory<ReportColumnChooserValues>();

export const ReportColumnChooserFields: React.FunctionComponent = () => {
  const { values } = useFormState<ReportColumnChooserValues>({
    subscription: { values: true }
  });

  const {
    selectedColumns,
    availableColumns: available,
    groupedColumns
  } = values;

  const availableColumns = available.map((column, index) => ({
    ...column,
    powerBiIndex: index,
    viewIndex: index,
    powerBiName: column.name
  }));

  const theme = useTheme();
  const tableSettings: TableSettings = {
    available: {
      heading: "Hidden columns",
      field: nameOf("availableColumns"),
      dropZoneText: "Drag here to hide",
      background: theme.palette.neutralLighterAlt,
      doubleClickTo: nameOf("selectedColumns")
    },
    grouped: {
      heading: "Group by",
      field: nameOf("groupedColumns"),
      dropZoneText: "Drag here to group"
    },
    selected: {
      heading: "Active columns",
      field: nameOf("selectedColumns"),
      dropZoneText: "Drag here to activate",
      doubleClickTo: nameOf("availableColumns")
    }
  };

  // below, for all grid-items, we are setting minHeight and minWidth to "0"
  // see here for why: https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
  return (
    <Stack
      horizontal
      verticalFill={true}
      styles={{
        root: {
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 3fr",
          columnGap: "16px",
          rowGap: "16px",
          height: "80vh"
        }
      }}
    >
      <Stack
        styles={{
          root: {
            gridColumn: "1",
            gridRow: "1 / span 2 ",
            minHeight: "0",
            minWidth: "0"
          }
        }}
      >
        <ReportColumnChooserList
          items={availableColumns}
          {...tableSettings.available}
        />
      </Stack>
      <Stack
        styles={{
          root: {
            gridColumn: "2",
            gridRow: "1",
            gridRowEnd: "1",
            gridColumnEnd: "2",
            minHeight: "0",
            minWidth: "0"
          }
        }}
      >
        <ReportColumnChooserList
          items={groupedColumns}
          {...tableSettings.grouped}
        />
      </Stack>
      <Stack
        styles={{
          root: {
            gridColumn: "2",
            gridRow: "2",
            minHeight: "0",
            minWidth: "0"
          }
        }}
      >
        <ReportColumnChooserList
          items={selectedColumns}
          {...tableSettings.selected}
        />
      </Stack>
    </Stack>
  );
};
