import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import {
  Permission,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { ClinicalTaskFilterValues } from "@shared-types/clinical/clinical-task-filter-values.type.ts";
import { ClinicalTaskStatus } from "@shared-types/clinical/clinical-task-status.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { Item } from "@ui-components/filter-bar/FilterBar.types.ts";

import { getConfidentialFilterPreset } from "./ClinicalTaskFilter.tsx";
import { ClinicalTasksTableLabels } from "./types/clinical-task-table-labels.enum.ts";

export interface ClinicalTaskManagementFilterProps {
  children: ReactNode;
}

export interface ClinicalTaskManagementFilterType
  extends Omit<ClinicalTaskFilterValues, "description"> {
  patientName?: string;
  excludeConfidential?: boolean;
  createdByIds: string[];
  descriptions?: string[];
}

const defaultFilter: ClinicalTaskManagementFilterType = {
  statuses: [
    ClinicalTaskStatus.Today,
    ClinicalTaskStatus.Overdue,
    ClinicalTaskStatus.Upcoming
  ],
  priorities: [],
  createdByIds: [],
  excludeConfidential: true
};

const ClinicalTaskManagementFilterBase: React.FC<ClinicalTaskManagementFilterProps> =
  observer(({ children }) => {
    const { clinical, core } = useStores();

    const statusOptions = [
      {
        key: ClinicalTaskStatus.Overdue,
        text: capitalizeSentence(ClinicalTaskStatus.Overdue)
      },
      {
        key: ClinicalTaskStatus.Today,
        text: capitalizeSentence(ClinicalTaskStatus.Today)
      },
      {
        key: ClinicalTaskStatus.Upcoming,
        text: capitalizeSentence(ClinicalTaskStatus.Upcoming)
      },
      {
        key: ClinicalTaskStatus.Completed,
        text: capitalizeSentence(ClinicalTaskStatus.Completed)
      }
    ];

    const dueVisitOptions = [
      { key: "0", text: "0 - 5" },
      { key: "5", text: "5 - 10" },
      { key: "10", text: "10 - 20" },
      { key: "20", text: "20 - 30" },
      { key: "30", text: "30 - 50" },
      { key: "50", text: "50+" }
    ];

    const priorityOptions = clinical.ref.clinicalTaskPriorities.keyTextValues;
    const descriptionOptions = clinical.ref.clinicalTaskTypes.keyTextValues;

    const items: Item<ClinicalTaskManagementFilterType>[] = [
      {
        type: "searchBox",
        name: "patientName",
        stickItem: true,
        props: {
          placeholder: ClinicalTasksTableLabels.SearchPatient,
          styles: {
            root: {
              flexGrow: 1
            }
          }
        }
      },
      {
        type: "optionsSelect",
        name: "statuses",
        props: {
          id: "clinical-task-status-dropdown",
          placeholder: ClinicalTasksTableLabels.Status,
          options: statusOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 256
        }
      },
      {
        type: "optionsSelect",
        name: "descriptions",
        props: {
          id: "clinical-task-status-dropdown",
          placeholder: ClinicalTasksTableLabels.Description,
          options: descriptionOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 256
        }
      },
      {
        type: "datesRangePicker",
        name: "datesRangePicker",
        props: {
          id: "clinical-tasks-management-filter-dates-range",
          startDateProps: {
            id: "clinical-tasks-management-filter-start-date"
          },
          endDateProps: { id: "clinical-tasks-management-filter-end-date" },
          startDateName: "startDateTime",
          endDateName: "endDateTime",
          placeholder: "Date(s)"
        }
      },
      {
        type: "optionsSelect",
        name: "dueVisits",
        props: {
          id: "clinical-task-duevisit-dropdown",
          placeholder: ClinicalTasksTableLabels.DueConsults,
          options: dueVisitOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 256
        }
      },
      {
        type: "optionsSelect",
        name: "priorities",
        props: {
          id: "clinical-task-priority-dropdown",
          placeholder: ClinicalTasksTableLabels.Priority,
          options: priorityOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 256
        }
      },
      {
        type: "bookableUsersSelect",
        name: "createdByIds",
        props: {
          id: "clinical-task-filter-providers",
          usersFilter: {
            statusCodes: [UserStatus.Active, UserStatus.Inactive],
            businessRoleClasses: ["PROVIDER"]
          },
          filterByStatus: true,
          showInactiveBadge: true,
          multiSelect: true,
          placeholder: ClinicalTasksTableLabels.CreatedBy,
          calloutWidth: 350
        }
      }
    ];
    return (
      <FilterBar<ClinicalTaskManagementFilterType>
        initialValues={defaultFilter}
        items={items}
        presets={getConfidentialFilterPreset(
          core.hasPermissions(Permission.ConfidentialDataAllowed),
          !clinical.isExistConfidentialClinicalTasksWithOtherUsers
        )}
      >
        {() => children}
      </FilterBar>
    );
  });

export const ClinicalTaskManagementFilter = withFetch(
  x => [
    x.clinical.ref.clinicalTaskPriorities.load(),
    x.clinical.ref.clinicalTaskTypes.load()
  ],
  ClinicalTaskManagementFilterBase
);
