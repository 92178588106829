import { ReactNode } from "react";

import { Heading, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { currencyFormat } from "@libs/utils/currency.utils.ts";

interface ModalViewHeaderProps {
  heading: string;
  date: Date;
  amount: number;
  badge: ReactNode;
}

export const ModalViewHeader: React.FC<ModalViewHeaderProps> = ({
  heading,
  date,
  amount,
  badge
}) => {
  return (
    <Stack horizontal horizontalAlign="space-between">
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            alignSelf: "stretch",
            alignItems: "baseline"
          }
        }}
      >
        <Heading variant="modal-heading">{heading}</Heading>
        <Text>{DateTime.fromJSDate(date).toDayDefaultFormat()}</Text>
      </Stack>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
        styles={{ root: { marginRight: 8 } }}
      >
        <Heading variant="section-heading-light">
          {currencyFormat(amount)}
        </Heading>
        {badge}
      </Stack>
    </Stack>
  );
};
