import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { CancelAppointmentFormValues } from "./CancelAppointmentForm.types.ts";

export class CancelAppointmentFormValidator extends Validator<CancelAppointmentFormValues> {
  constructor(
    otherCancellationReasonId?: string,
    captureApptCancellationReason?: boolean
  ) {
    super();
    !!captureApptCancellationReason &&
      this.forField("cancellationReasonId", required());

    this.forField("cancellationText", [
      predicate(
        (val: string, cancelCalendarEvent: CancelAppointmentFormValues) =>
          cancelCalendarEvent.cancellationReasonId ===
          otherCancellationReasonId,
        required()
      ),
      maxLength(50)
    ]);
  }
}
