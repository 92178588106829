import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  Heading,
  LabeledText,
  PersonaSize,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { InvoiceLicenceWarning } from "../invoice-details-modal/InvoiceLicenceWarning.tsx";
import {
  invoiceDetailsRowHeadingStyles,
  invoiceDetailsRowPersonaContainerStyles,
  invoiceDetailsRowPersonaStyles
} from "./InvoiceDetailsRow.styles.ts";

interface InvoiceDetailsRowProviderProps {
  locationId: InvoiceFormValues["locationId"];
  userId: InvoiceFormValues["userId"];
  editMode: boolean;
}

export const InvoiceDetailsRowProvider: FunctionComponent<InvoiceDetailsRowProviderProps> =
  observer(props => {
    const { core } = useStores();
    const { locationId, userId, editMode } = props;

    const location = core.getLocationName(locationId);

    return (
      <Stack grow>
        <Heading
          variant="section-heading-light"
          styles={invoiceDetailsRowHeadingStyles}
        >
          Provider
        </Heading>
        {userId && (
          <UserFetcher userId={userId} fallback={<Spinner />}>
            {user => (
              <Stack tokens={{ childrenGap: 16 }}>
                <Stack styles={invoiceDetailsRowPersonaContainerStyles}>
                  <Persona
                    styles={invoiceDetailsRowPersonaStyles}
                    text={user.fullName}
                    id={user.id}
                    size={PersonaSize.size48}
                    imageInitials={user.initials}
                    onRenderSecondaryText={() => (
                      <Text variant="small">{user.workPhone}</Text>
                    )}
                  />
                </Stack>
                {editMode && <InvoiceLicenceWarning />}
                <LabeledText noSemicolon label="Provider location">
                  {location}
                </LabeledText>
              </Stack>
            )}
          </UserFetcher>
        )}
      </Stack>
    );
  });
