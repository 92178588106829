import { FC, memo } from "react";
import { FormSpy, useForm } from "react-final-form";

import { Heading, Stack, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { RelationshipStatus } from "../../patient/view/constants.ts";
import { PatientEditFormValues } from "./PatientEditFormValues.tsx";

interface EditProfileProps {
  contact: Contact | undefined;
  sectionId: string;
  isAddNew?: boolean;
}

type EditProfileValues = Pick<
  PatientEditFormValues,
  "status" | "dateOfDeath" | "occupation" | "relationshipStatus"
>;

export const EditProfile: FC<EditProfileProps> = memo(
  ({ contact, sectionId, isAddNew }) => {
    const { practice } = useStores();
    const {
      ui: { newContactType }
    } = practice;

    const theme = useTheme();

    const form = useForm<PatientEditFormValues>();

    const nameof = nameOfFactory<EditProfileValues>();

    const buttonsStatusOptions = [
      {
        key: ContactStatus.Active,
        text: capitalizeSentence(ContactStatus.Active.toLowerCase())
      },
      {
        key: ContactStatus.Inactive,
        text: capitalizeSentence(ContactStatus.Inactive.toLowerCase())
      }
    ];

    const buttonsStatusOptionsPatient = [
      ...buttonsStatusOptions,
      {
        key: ContactStatus.Deceased,
        text: capitalizeSentence(ContactStatus.Deceased.toLowerCase())
      }
    ];

    const { halfWidthField } = getPeopleScreenStylesSet(theme);

    return (
      <div
        id={`${sectionId}-edit`}
        style={{ paddingRight: 16, marginBottom: 8 }}
      >
        <Heading
          variant="section-heading-light"
          styles={{ root: { padding: "5px 0" } }}
        >
          {Labels.profile}
        </Heading>
        <Stack tokens={{ childrenGap: 8 }}>
          {!isAddNew && (
            <ButtonsGroupSingleChoiceField
              label={Labels.status}
              name={nameof("status")}
              options={
                contact?.type === ContactType.Patient
                  ? buttonsStatusOptionsPatient
                  : buttonsStatusOptions
              }
            />
          )}
          {(contact?.type === ContactType.Patient ||
            newContactType === ContactType.Patient) && (
            <>
              <FieldSpy
                name={nameof("status")}
                onChange={(value: ContactStatus) => {
                  if (value !== ContactStatus.Deceased) {
                    form && form.change(nameof("dateOfDeath"), undefined);
                  }
                }}
              />
              <FormSpy>
                {({ values }) =>
                  values.status === ContactStatus.Deceased && (
                    <DatePickerField
                      label={Labels.dateOfDeath}
                      name={nameof("dateOfDeath")}
                      maxDate={DateTime.jsDateNow()}
                    />
                  )
                }
              </FormSpy>
              <DropdownField
                label={Labels.relationshipStatus}
                placeholder="Select"
                name={nameof("relationshipStatus")}
                styles={halfWidthField}
                options={practice.ref.relationshipStatuses.keyTextValues.filter(
                  x => x.key !== RelationshipStatus.Unknown
                )}
              />
            </>
          )}
        </Stack>
      </div>
    );
  }
);
