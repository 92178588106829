import { ContextualMenuItemType } from "@bps/fluent-ui";

interface QuickAccessLink {
  key: string;
  text: string;
}

export const quickAccessLinks: QuickAccessLink[] = [
  { key: "PRES", text: "Medications / Prescribe" },
  { key: "IMGREQ", text: "Investigations / New image request" }
];

export const separator = {
  key: "divider_1",
  itemType: ContextualMenuItemType.Divider
};

export enum AvailableLinkType {
  Favourites = "Favourites",
  Letter = "Letter",
  Referral = "Referral",
  Report = "Report",
  Custom = "Custom"
}

export enum DocumentSubMenuIcon {
  Favourites = "FavoriteStar",
  Letter = "AddToShoppingList",
  Referral = "Script",
  Report = "ReportDocument",
  Document = "PageAdd",
  Custom = "ViewAll2",
  OnlineForm = "ClipboardList"
}

export const defulatDocumentSubMenuIcon = [
  "AddToShoppingList",
  "Script",
  "ReportDocument"
];

export enum QuickAccessSection {
  Document = "New document",
  Forms = "Online Forms"
}

export const OVERFLOW_LENGTH = 5;

export const OVERFLOW_INDEX = 4;
