import { sum } from "@libs/utils/utils.ts";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

/**
 *  @returns the total amount that has been selected.
 **/
export const useAllocatableAmount = () => {
  const {
    fields: { value: payments }
  } = useFieldArray(allocationNameOf("payments"), {
    subscription: { value: true }
  });

  return sum("amount", payments || []);
};
