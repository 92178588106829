import React from "react";

import { Stack } from "@bps/fluent-ui";
import {
  SignificantFamilyHistoryDataItemDto,
  TerminologyDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";

import { ConditionAndCommentGroupField } from "./ConditionAndCommentGroupField.tsx";
import {
  familyHistoryNameOf,
  generalSignificantConditions,
  significantFamilyHistoryNameOf
} from "./FamilyHistoryForm.types.ts";

interface SignificantConditionCheckBoxGroupFieldProps {
  familyHistoryIndex: string | number;
  significantValues: SignificantFamilyHistoryDataItemDto[];
}

export const SignificantConditionCheckBoxGroupField: React.FC<
  SignificantConditionCheckBoxGroupFieldProps
> = ({ familyHistoryIndex }) => {
  const { clinical } = useStores();

  const parentHistoryName = `${familyHistoryNameOf(
    "parentHistories"
  )}[${familyHistoryIndex}]`;

  const additionalParentHistoryName = `${familyHistoryNameOf(
    "additionalParentHistories"
  )}[${familyHistoryIndex}]`;

  const diagnosisKeysName = `${parentHistoryName}.${significantFamilyHistoryNameOf(
    "diagnosisKeys"
  )}`;

  const additionalParentConditionsName = `${additionalParentHistoryName}.${significantFamilyHistoryNameOf(
    "conditions"
  )}`;

  const options = generalSignificantConditions.map(x => ({
    value: clinical.getTerminologyKey(x.code, x.term),
    label: x.term
  }));

  const filteredOptions =
    familyHistoryIndex === 0
      ? options.filter(x => x.label !== generalSignificantConditions[7].term)
      : options.filter(x => x.label !== generalSignificantConditions[6].term);

  const resultsFilter = (results: QueryResult<TerminologyDto>) => {
    return {
      ...results,
      results: results.results.filter(
        x => !generalSignificantConditions.find(y => y.code === x.code)
      )
    };
  };

  const { isViewOnly } = usePatientRecordScreenContext();

  return (
    <Stack>
      <CheckboxGroupField
        options={filteredOptions}
        name={diagnosisKeysName}
        disabled={isViewOnly}
        horizontal
        wrap
        checkboxWidth={175}
        styles={{
          root: {
            maxWidth: 900
          }
        }}
      />

      {!isViewOnly && (
        <ConditionAndCommentGroupField
          conditionsName={additionalParentConditionsName}
          resultsFilter={resultsFilter}
        />
      )}
    </Stack>
  );
};
