import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface PSFSFormValues {
  diagnosisKey?: string;
  side?: string;
  reason?: string;
  items: Record<string, string>;
  confidential?: boolean;
}

export const psfsNameOf = nameOfFactory<PSFSFormValues>();
export const PSFS_FORM_HEADING = "Patient Specific Function Scale";
