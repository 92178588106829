import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";

import {
  IColumn,
  NoDataTile,
  ScrollablePane,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { RecentPatientDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { RecentPatientsAreaCell } from "./RecentPatientsAreaCell.tsx";
import { RecentPatientsDetailsCell } from "./RecentPatientsDetailsCell.tsx";
import {
  RecentPatientsFilter,
  RecentPatientsFilterState
} from "./RecentPatientsFilter.tsx";
import { recentPatientFilterOptions } from "./RecentPatientsFilter.types.ts";

type RecentPatientDtoWithName = RecentPatientDto & { name: string | undefined };

const RecentPatientListInner: FC<{
  filter: RecentPatientsFilterState;
  reset: () => void;
}> = observer(({ filter, reset }) => {
  const { practice, userExperience } = useStores();

  const columns: IColumn[] = [
    {
      name: "When",
      fieldName: nameof<RecentPatientDto>("eventTime"),
      key: nameof<RecentPatientDto>("eventTime"),
      onRender: (item: RecentPatientDtoWithName) => (
        <Text>
          {DateTime.fromISOOrNow(item.eventTime).toFormat(
            DATE_FORMATS.LONG_DATE_TIME_FORMAT_REVERSED
          )}
        </Text>
      ),
      minWidth: 180,
      maxWidth: 180
    },
    {
      name: "Patient",
      fieldName: nameof<RecentPatientDto>("patientId"),
      key: nameof<RecentPatientDto>("patientId"),
      onRender: (item: RecentPatientDtoWithName) => <Text>{item.name}</Text>,
      minWidth: 200,
      maxWidth: 320
    },
    {
      name: "Event",
      fieldName: nameof<RecentPatientDto>("eventType"),
      key: nameof<RecentPatientDto>("eventType"),
      onRender: (item: RecentPatientDtoWithName) => (
        <RecentPatientsAreaCell recentPatient={item} />
      ),
      minWidth: 200,
      maxWidth: 320
    },
    {
      name: "Details",
      fieldName: nameof<RecentPatientDto>("eventVerb"),
      key: nameof<RecentPatientDtoWithName>("eventVerb"),
      minWidth: 200,
      onRender: (item: RecentPatientDtoWithName) => (
        <RecentPatientsDetailsCell recentPatient={item} />
      )
    }
  ];

  const getItems = useCallback(
    async (query: PagingOptions) => {
      const option = filter.eventCode
        ? recentPatientFilterOptions.find(
            option => option.key === filter.eventCode
          )
        : undefined;

      const result = await userExperience.getRecentPatients({
        ...query,
        patientIds: filter.patientIds,
        eventVerb: option?.data?.verb,
        eventType: option?.data?.type,
        eventTimeFrom: filter.eventTimeFrom
          ? DateTime.fromJSDate(filter.eventTimeFrom).startOf("day").toISO()
          : DateTime.today().minus({ days: 7 }).toISO(),
        eventTimeTo: DateTime.fromJSDate(filter.eventTimeTo)?.toISO()
      });

      const contacts = await practice.getContactsById(
        result.results.map(x => x.patientId)
      );

      const results = result.results.map(x => ({
        ...x,
        id: x.recentPatientsId,
        name: contacts.find(c => c.id === x.patientId)?.reversedName
      }));

      return {
        ...result,
        results
      };
    },
    [filter, userExperience, practice]
  );

  return (
    <Stack styles={{ root: { position: "relative", flex: 1 } }}>
      <ScrollablePane>
        <InfiniteScrollList
          stickyHeader
          columns={columns}
          getItems={getItems}
          onRenderNoResults={() => (
            <NoDataTile
              styles={{ root: { boxShadow: "none" } }}
              textProps={{
                text: "No record recent interactions with patient records"
              }}
              linkProps={
                filter.patientIds || filter.eventCode
                  ? {
                      text: "Clear filter",
                      onClick: () => reset()
                    }
                  : { hidden: true }
              }
            />
          )}
        />
      </ScrollablePane>
    </Stack>
  );
});

export const RecentPatientsList: FC = observer(() => (
  <Stack grow tokens={{ childrenGap: 16 }}>
    <RecentPatientsFilter>
      {({ values: filter }, { reset }) => (
        <RecentPatientListInner filter={filter} reset={reset} />
      )}
    </RecentPatientsFilter>
  </Stack>
));
