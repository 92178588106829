import { useField, useForm } from "react-final-form";

import { FontIcon, Stack, useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ButtonsChoiceGroupField } from "@ui-components/form/ButtonsGroupChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import {
  deleteRegionButton,
  eightGap,
  regionDetails,
  regionItemCheckBox,
  regionLeftRightButtons,
  regionWrapper
} from "./ImagingRequest.styles.ts";
import {
  customViews,
  ImagingRequestFormValues,
  Labels,
  regionNameOf,
  regionTypes
} from "./ImagingRequestDialog.types.ts";

interface RequestRegionFieldProps {
  deleteRegion: () => void;
  regionIndex: number;
  modalityIndex: number;
  fieldName: string;
}
export const ImagingRequestRegionField: React.FC<RequestRegionFieldProps> = ({
  deleteRegion,
  regionIndex,
  fieldName
}) => {
  const theme = useTheme();

  const regionIndexFieldName = `${fieldName}[${regionIndex}]`;

  const { fields: thisRegionFields } = useFieldArray(regionIndexFieldName);

  const form = useForm<ImagingRequestFormValues>();
  const { mutators } = form;

  const { clinical } = useStores();

  const {
    input: { value: regionValue }
  } = useField(`${regionIndexFieldName}.region`);

  const hasSide = regionValue
    ? !!clinical.ref.imagingRegions.get(regionValue)?.hasSide
    : false;

  const availableRegionCodes = () => {
    const selectedRegion = clinical.ref.imagingRegions.get(
      thisRegionFields.value["region"]
    );
    let newRegionInfo = regionTypes;

    if (!selectedRegion?.weightBearing) {
      newRegionInfo = newRegionInfo.filter(
        t => t.key !== regionNameOf("weightBearing")
      );
    }

    return newRegionInfo;
  };

  const updateRegionDetails = (selected: boolean, regionDetail: string) => {
    if (!selected) {
      const selectedRegions = thisRegionFields.value[
        "selectedRegionDetails"
      ] as string[];
      thisRegionFields.value[regionNameOf("selectedRegionDetails")] =
        selectedRegions.filter(r => r !== regionDetail);
      mutators.update(fieldName, regionIndex, thisRegionFields.value);
    }
  };

  const resetRegionValues = () => {
    thisRegionFields.value["side"] = [];
    thisRegionFields.value["customViews"] = false;
    thisRegionFields.value["customViewsDetails"] = [];
    thisRegionFields.value["weightBearing"] = false;
    thisRegionFields.value["other"] = false;
    thisRegionFields.value["otherDetails"] = "";
    mutators.update(fieldName, regionIndex, thisRegionFields.value);
  };

  return (
    <Stack styles={regionWrapper(theme)} tokens={eightGap}>
      <Stack horizontal horizontalAlign="space-between">
        <OptionsSelectField
          name={`${regionIndexFieldName}.region`}
          required
          options={clinical.ref.imagingRegions.keyTextValues}
          horizontalLabel={true}
          label="Region"
          styles={{
            root: {
              width: regionIndex !== 0 ? 236 : 268,
              label: { margin: 0 },
              flexGrow: 1
            }
          }}
        />
        <FieldSpy
          name={`${regionIndexFieldName}.region`}
          onChange={() => {
            resetRegionValues();
          }}
        />
        <ButtonsChoiceGroupField
          name={`${regionIndexFieldName}.side`}
          options={[
            {
              key: "L",
              text: "L",
              disabled: !hasSide
            },
            {
              key: "R",
              text: "R",
              disabled: !hasSide
            }
          ]}
          disabled={true}
          styles={regionLeftRightButtons}
        />
        <OptionsSelectField
          name={`${regionIndexFieldName}.selectedRegionDetails`}
          options={availableRegionCodes()}
          hideSearchOption
          multiSelect
          hideClearButton
          calloutWidth={190}
          onRenderFieldContent={() => (
            <FontIcon
              iconName="Add"
              styles={{
                root: {
                  color: theme.palette.themePrimary
                }
              }}
            />
          )}
          showAllSelected
          styles={regionDetails(theme)}
          fieldItemStyles={{ root: { display: "flex", alignSelf: "center" } }}
        />
        {regionIndex !== 0 && (
          <DeleteButton styles={deleteRegionButton} onClick={deleteRegion} />
        )}
      </Stack>

      <FieldSpy
        name={`${regionIndexFieldName}.selectedRegionDetails`}
        onChange={(values: string[]) => {
          thisRegionFields.value[regionNameOf("customViews")] = values.includes(
            regionNameOf("customViews")
          );
          thisRegionFields.value[regionNameOf("other")] = values.includes(
            regionNameOf("other")
          );
          thisRegionFields.value[regionNameOf("weightBearing")] =
            values.includes(regionNameOf("weightBearing"));
          mutators.update(fieldName, regionIndex, thisRegionFields.value);
        }}
      />

      <FieldCondition when={`${regionIndexFieldName}.customViews`} is={true}>
        <Stack horizontal tokens={eightGap}>
          <CheckboxField
            name={`${regionIndexFieldName}.customViews`}
            label={Labels.CustomViews}
            styles={regionItemCheckBox}
          />
          <ComboBoxField
            options={customViews}
            multiSelect
            allowFreeform
            name={`${regionIndexFieldName}.customViewsDetails`}
            fieldItemStyles={{
              root: { width: "100%" }
            }}
            useComboBoxAsMenuWidth
          />
        </Stack>
      </FieldCondition>
      <FieldSpy
        name={`${regionIndexFieldName}.customViews`}
        onChange={value => {
          updateRegionDetails(value, regionNameOf("customViews"));
          if (!value) {
            form.change(
              `${regionIndexFieldName}.customViewsDetails` as keyof ImagingRequestFormValues,
              []
            );
          }
        }}
      />
      <FieldCondition when={`${regionIndexFieldName}.weightBearing`} is={true}>
        <CheckboxField
          name={`${regionIndexFieldName}.weightBearing`}
          label={Labels.WeightBearing}
          styles={regionItemCheckBox}
        />
      </FieldCondition>
      <FieldSpy
        name={`${regionIndexFieldName}.weightBearing`}
        onChange={value => {
          updateRegionDetails(value, regionNameOf("weightBearing"));
        }}
      />
      <FieldCondition when={`${regionIndexFieldName}.other`} is={true}>
        <Stack horizontal tokens={eightGap}>
          <CheckboxField
            name={`${regionIndexFieldName}.other`}
            label={Labels.Other}
            styles={regionItemCheckBox}
          />
          <TextInputField name={`${regionIndexFieldName}.otherDetails`} />
        </Stack>
      </FieldCondition>
      <FieldSpy
        name={`${regionIndexFieldName}.other`}
        onChange={value => {
          updateRegionDetails(value, regionNameOf("other"));
          if (!value) {
            form.change(
              `${regionIndexFieldName}.otherDetails` as keyof ImagingRequestFormValues,
              ""
            );
          }
        }}
      />
    </Stack>
  );
};
