import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IInvoiceRow } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsList.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { WhenNavigate } from "@ui-components/navigation/WhenNavigate.tsx";

export const AccInvoicesTransactionAllocationCell: FunctionComponent<IInvoiceRow> =
  observer(props => {
    const { routing } = useStores();

    if (!props.isFirstItem) {
      return null;
    }

    const allocationRefs = props.invoice.allocationReferences;

    if (!allocationRefs.length) {
      return null;
    }

    return (
      <Stack tokens={{ childrenGap: 8 }} grow horizontalAlign="end">
        {allocationRefs.map(
          allocation =>
            allocation.transactionId && (
              <WhenNavigate
                key={allocation.id}
                permission={Permission.AccountHistoryAllowed}
                to={{
                  pathname: routes.accounts.allocations.allocation.path({
                    id: allocation.transactionId
                  })
                }}
                state={routing.getStateWithFromQuery()}
              >
                {allocation.transaction.number || ""}
              </WhenNavigate>
            )
        )}
      </Stack>
    );
  });
