import {
  ContextualMenuItemType,
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  TooltipHost
} from "@bps/fluent-ui";
import {
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondencesTestElements,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { isPreviewAndPrintSupported } from "@libs/utils/file.utils.ts";
import { ClinicalDocument } from "@stores/clinical/models/ClinicalDocument.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { useCorrespondencesHelper } from "../correspondence/context/CorrespondencesContext.tsx";
import { CorrespondencesLabels } from "./Correspondences.types.ts";

interface CorrespondenceListItemContextMenuProps {
  record: ClinicalDocument;
}

export const CorrespondenceListItemContextMenu: React.FC<
  CorrespondenceListItemContextMenuProps
> = ({ record }) => {
  const { correspondence, inbox, core } = useStores();

  const {
    clinicalRecord,
    setEditDialogVisible,
    setIsDeleteDialogVisible,
    setIsPrinting,
    setSelectedClinicalDocument,
    setSendDialogVisible,
    setActiveDocumentTab,
    onOpenClinicalDocumentClick,
    previewCorrespondence,
    onMoveTo,
    onOutcomeClick,
    updateCorrespondenceConfidentiality,
    onDuplicateDocument,
    onMoveToAnotherPatient
  } = useCorrespondencesHelper();

  const isConfidentOfOtherUser = !core.hasAccessToSecGroup(record.secGroupId);

  const patientId = clinicalRecord.id;

  const sectionOneItems: IContextualMenuItem[] = [];
  const sectionTwoItems: IContextualMenuItem[] = [];
  const sectionThreeItems: IContextualMenuItem[] = [];
  const sectionFourItems: IContextualMenuItem[] = [];
  const sectionFiveItems: IContextualMenuItem[] = [];
  const items: IContextualMenuItem[] = [
    {
      key: "section1",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        bottomDivider: true,
        items: sectionOneItems
      }
    },
    {
      key: "section2",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        bottomDivider: true,
        items: sectionTwoItems
      }
    },
    {
      key: "section3",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        bottomDivider: true,
        items: sectionThreeItems
      }
    },
    {
      key: "section4",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        bottomDivider: true,
        items: sectionFourItems
      }
    },
    {
      key: "section5",
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        items: sectionFiveItems
      }
    }
  ];

  if (record.status !== CorrespondenceStatus.Draft) {
    const onEditCorrespondenceClick = (item: ClinicalDocument) => {
      setSelectedClinicalDocument(item);
      setEditDialogVisible(true);
    };

    sectionThreeItems.push({
      key: "edit",
      name: CorrespondencesLabels.ContextMenuEdit,
      onClick: () => {
        onEditCorrespondenceClick(record);
      }
    });
  }

  if (record.extension && isPreviewAndPrintSupported(record.extension)) {
    const onPrintClick = async (item: ClinicalDocument) => {
      if (item.status === CorrespondenceStatus.Draft) {
        setActiveDocumentTab(item);
      } else {
        // this call interacts with document API to get the SasUri, required for viewing documents
        const corr = await correspondence.getCorrespondenceByDocumentId(
          patientId,
          item.id
        );
        setSelectedClinicalDocument(corr);
        setIsPrinting(true);
      }
    };

    sectionThreeItems.push({
      key: "print",
      name: CorrespondencesLabels.ContextMenuPrint,
      onClick: () => {
        onPrintClick(record);
      }
    });

    if (
      record.status === CorrespondenceStatus.Draft &&
      record.from === core.userId
    ) {
      sectionOneItems.push({
        key: "open",
        name: CorrespondencesLabels.ContextMenuOpenDraft,
        onClick: () => {
          onOpenClinicalDocumentClick(record);
        }
      });
    }

    sectionOneItems.push({
      key: "preview",
      name: CorrespondencesLabels.ContextMenuOpenDocument,
      onClick: () => {
        previewCorrespondence(record);
      }
    });
  }

  if (
    record.status === CorrespondenceStatus.Done &&
    record.direction === CorrespondenceDirection.Out
  ) {
    sectionOneItems.push({
      key: "send",
      name: CorrespondencesLabels.ContextMenuEmail,
      onClick: () => {
        setSelectedClinicalDocument(record);
        setSendDialogVisible(true);
      }
    });
  }

  sectionFiveItems.push(
    {
      key: "moveTo",
      name: CorrespondencesLabels.ContextMenuMoveTo,
      subMenuProps: {
        items: [
          {
            key: "moveToPatient",
            name: CorrespondencesLabels.ContextMenuMoveToAnotherPatient,
            onClick: () => {
              onMoveToAnotherPatient(record);
            }
          },
          {
            key: "moveToInvestigations",
            name: CorrespondencesLabels.ContextMenuMoveToInvestigations,
            onClick: () => {
              onMoveTo(record, StoreType.Investigations);
            }
          },
          {
            key: "moveToClinicalImage",
            name: CorrespondencesLabels.ContextMenuMoveToClinicalImages,
            onClick: () => {
              onMoveTo(record, StoreType.ClinicalImages);
            }
          }
        ]
      }
    },
    {
      key: "delete",
      name: CorrespondencesLabels.ContextMenuDelete,
      onClick: () => {
        setIsDeleteDialogVisible(true);
        setSelectedClinicalDocument(record);
      }
    }
  );

  sectionThreeItems.push({
    key: "outcome",
    name: CorrespondencesLabels.ContextMenuOutcome,
    subMenuProps: {
      items: inbox.ref.outcomes.keyNameValues.map(outcome => {
        const item: IContextualMenuItem = {
          ...outcome,
          onClick(_, item?) {
            if (item) onOutcomeClick(record, item.key);
          }
        };
        return item;
      })
    }
  });

  if (
    record.status === CorrespondenceStatus.Done &&
    record.direction === CorrespondenceDirection.Out
  ) {
    sectionTwoItems.push({
      key: "Duplicate",
      name: "Duplicate",
      onClick: () => {
        onDuplicateDocument(record);
      }
    });
  }

  if (core.hasPermissions(Permission.ConfidentialDataAllowed)) {
    sectionFourItems.push({
      key: "Confidential",
      name: !!record.secGroupId
        ? "Remove confidentiality"
        : "Make confidential",
      onClick: () => {
        updateCorrespondenceConfidentiality(record);
      }
    });
  }

  return (
    <TooltipHost content={isConfidentOfOtherUser ? "Confidential" : ""}>
      <IconButton
        {...dataAttribute(
          DataAttributes.Element,
          CorrespondencesTestElements.MoreButton
        )}
        menuIconProps={{
          iconName: isConfidentOfOtherUser ? "ShieldAlert" : "More"
        }}
        menuProps={{
          items
        }}
        styles={{
          root: { height: "36px" },
          rootDisabled: {
            backgroundColor: "transparent"
          },
          flexContainer: { height: "36px" }
        }}
        disabled={isConfidentOfOtherUser}
      />
    </TooltipHost>
  );
};
