import { observer } from "mobx-react-lite";

import { DefaultButton, Heading } from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ContextMenuItemsEnum } from "../booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";
import { UnavailableFormValues } from "./components/UnavailableForm.types.ts";
import {
  UnavailableFormContext,
  useUnavailableFormContext
} from "./components/UnavailableFormContext.ts";
import { UnavailableFormFields } from "./components/UnavailableFormFields.tsx";
import { UnavailableFormHelper } from "./components/UnavailableFormHelper.ts";
import { UnavailableFormValidator } from "./components/UnavailableFormValidator.ts";

const validator = new UnavailableFormValidator();
const UnavailableDialogBase: React.FunctionComponent = observer(() => {
  const { booking } = useStores();
  const { startDate } = useBookingCalendarScreenContext();

  const {
    showCancelEvent,
    handleSubmit,
    onSubmitted,
    calendarEventId,
    getInitialValues
  } = useUnavailableFormContext();

  const onDismiss = async () => {
    booking.ui.hideCalendarEventDialog();
    await booking.deleteTemporaryReservation();
  };

  const extraActionBefore = () =>
    calendarEventId ? (
      <DefaultButton
        text={ContextMenuItemsEnum.MakeAvailable}
        onClick={showCancelEvent}
        styles={{ root: { marginRight: "auto" } }}
      />
    ) : undefined;

  return (
    <SubmissionFormDialog
      onSubmit={handleSubmit}
      onSubmitSucceeded={onSubmitted}
      validate={(values: UnavailableFormValues) => validator.validate(values)}
      initialValues={getInitialValues(startDate)}
      buttonsProps={{
        extraActionsBefore: extraActionBefore,
        hideButtonsSeparator: true,
        submitButtonProps: { iconProps: undefined }
      }}
      styles={{
        subComponentStyles: {
          submitErrorMessage: { root: { marginTop: 8 } }
        }
      }}
      dialogName={`${
        !booking.ui.currentAppointment?.id ? "New" : "Edit"
      } unavailable dialog`}
      dialogProps={{
        onDismiss,
        minWidth: 600,
        dialogContentProps: {
          showCloseButton: true,
          title: (
            <Heading variant="modal-heading">
              {`${
                !booking.ui.currentAppointment?.id ? "New" : "Edit"
              } unavailable`}
            </Heading>
          )
        }
      }}
    >
      {() => <UnavailableFormFields />}
    </SubmissionFormDialog>
  );
});

export const UnavailableDialog = observer(() => {
  const { booking } = useStores();
  const hidden =
    !booking.ui.currentAppointment ||
    booking.ui.currentAppointment.type !== CalendarEventType.Unavailable;

  if (hidden) return null;

  return (
    <UnavailableFormContext.Provider value={new UnavailableFormHelper(booking)}>
      <UnavailableDialogBase />
    </UnavailableFormContext.Provider>
  );
});
