export enum AddressFieldsPlaceholders {
  BuildingName = "Building/Facility name",
  Address1 = "Address line 1",
  Address2 = "Address line 2",
  Country = "Select country",
  State = "Select state",
  Postcode = "Post Code",
  Street = "Street",
  Suburb = "Suburb",
  City = "City"
}
