import { Accordion } from "@bps/fluent-ui";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { AccordionItemBodyMeasurement } from "./AccordionItemBodyMeasurement.tsx";
import { AccordionItemGeneralObservations } from "./AccordionItemGeneralObservations.tsx";
import { AccordionItemVitalSigns } from "./AccordionItemVitalSigns.tsx";
import { GeneralExaminationFormValues } from "./GeneralExaminationFormValues.ts";

interface GeneralExaminationGPversionFieldsComponentProps {
  values: GeneralExaminationFormValues;
  width: number;
}
export const GeneralExaminationGPversionFieldsComponent: React.FunctionComponent<
  GeneralExaminationGPversionFieldsComponentProps
> = ({ values, width }) => {
  return (
    <Accordion disableAnimation multiple>
      <AccordionItemGeneralObservations values={values} width={width} />
      <AccordionItemVitalSigns values={values} width={width} />
      <AccordionItemBodyMeasurement values={values} width={width} />
    </Accordion>
  );
};

export const GeneralExaminationGPversionFields = withFetch(
  x => [
    x.clinical.ref.bodyHabitus.load(),
    x.clinical.ref.nails.load(),
    x.clinical.ref.hands.load(),
    x.clinical.ref.hydrations.load(),
    x.clinical.ref.cyanosisPatterns.load(),
    x.clinical.ref.discolourations.load()
  ],
  GeneralExaminationGPversionFieldsComponent
);
