import { Spinner, useResizeElementObserver } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  QuestionnaireDto,
  QuestionnaireType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalToolForm } from "../clinical-tool-form/ClinicalToolForm.tsx";
import { K10Questions } from "./K10Questions.tsx";

interface K10QuestionnaireFormProps {
  clinicalRecord: ClinicalRecord;
}

export const K10QuestionnaireForm: React.FunctionComponent<
  K10QuestionnaireFormProps
> = ({ clinicalRecord }) => {
  const { resizeObserverEntry, setElement, element } =
    useResizeElementObserver();

  const panelWidth = resizeObserverEntry
    ? resizeObserverEntry.borderBoxSize[0].inlineSize
    : 0;

  return (
    <DataFetcher<QuestionnaireDto>
      fetch={x => x.clinical.getQuestionnaires(QuestionnaireType.K10V1)}
      fallback={<Spinner />}
    >
      {questionnaire => (
        <>
          <div
            ref={r => {
              if (r && !element) {
                setElement(r);
              }
            }}
          />
          <ClinicalToolForm
            clinicalRecord={clinicalRecord}
            clinicalDataType={ClinicalDataType.K10}
            formHeading="K10"
            questionnaire={questionnaire}
            formName="k10"
          >
            <K10Questions questionnaire={questionnaire} width={panelWidth} />
          </ClinicalToolForm>
        </>
      )}
    </DataFetcher>
  );
};
