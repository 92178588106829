import { FC } from "react";
import { useFormState } from "react-final-form";

import { ClaimAdjustmentStatus } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { FormStatusIndicatorBadge } from "@ui-components/form/FormStatusIndicator/FormStatusIndicatorBadge.tsx";

import { ClaimAdjustmentStatusBadge } from "../ClaimAdjustmentStatusBadge.tsx";

export const ClaimAdjustmentOverviewBadge: FC = () => {
  const {
    claimAdjustment,
    isClaimAdjustmentFormReady,
    isVerballyApprovedClaimAdjustmentReady
  } = useClaimAdjustmentContext();

  const { values } = useFormState<ClaimAdjustmentFormValues>();

  const status =
    claimAdjustment.status?.code ?? ClaimAdjustmentStatus.Incomplete;

  if (
    status === ClaimAdjustmentStatus.Incomplete ||
    status === ClaimAdjustmentStatus.Ready
  ) {
    return (
      <FormStatusIndicatorBadge
        valid={
          claimAdjustment.verballyApproved
            ? isVerballyApprovedClaimAdjustmentReady(values)
            : isClaimAdjustmentFormReady(values)
        }
      />
    );
  }

  return <ClaimAdjustmentStatusBadge code={status} />;
};
