import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { Callout } from "@bps/fluent-ui";

import { usePatientSummaryContext } from "./helpers/PatientSummaryContext.tsx";

export const PatientSummaryCardCallout: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const { preventDismissOnLostFocus, setVisibleCallout, visibleCallout } =
      usePatientSummaryContext();
    return (
      <Callout
        styles={{
          root: {
            display: "flex",
            alignItems: "stretch",
            minHeight: 224
          },
          calloutMain: { flex: 1 }
        }}
        preventDismissOnLostFocus={
          preventDismissOnLostFocus && preventDismissOnLostFocus.get()
        }
        calloutWidth={600}
        calloutMaxHeight={448}
        onDismiss={() => {
          setVisibleCallout && setVisibleCallout(undefined);
        }}
        hidden={visibleCallout && !visibleCallout.get()}
        target={visibleCallout && visibleCallout.get()?.nativeEvent}
      >
        {children}
      </Callout>
    );
  }
);
