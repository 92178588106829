import { observer } from "mobx-react-lite";

import { Spinner } from "@bps/fluent-ui";
import { OutboundCommTemplate } from "@stores/comms/models/OutboundCommTemplate.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { CommsScheduleContext } from "../comms-schedules/context/CommsScheduleContext.ts";
import { CommsScheduleHelper } from "../comms-schedules/context/CommsScheduleHelper.ts";
import { CommsConfirmationCampaignForm } from "./components/confirmation/CommsConfirmationCampaignForm.tsx";
import { CommsConfirmationCampaignFormValues } from "./components/confirmation/CommsConfirmationCampaignFormValues.ts";

const CommsConfirmationCampaignScreen = observer(() => {
  const root = useStores();
  const helper = new CommsScheduleHelper(root);
  return (
    <CommsScheduleContext.Provider value={helper}>
      <DataFetcher<
        [
          CommsConfirmationCampaignFormValues | undefined,
          OutboundCommTemplate[]
        ]
      >
        fetch={async ({ comms }) => {
          const commTemplates = await comms.getAllOutboundCommTemplates();
          return [
            await helper.getInitialValuesCommsConfirmationCampaign(
              commTemplates
            ),
            commTemplates
          ];
        }}
        fallback={<Spinner />}
      >
        {([initialValues, templates]) => (
          <CommsConfirmationCampaignForm
            templates={templates}
            initialValues={initialValues}
          />
        )}
      </DataFetcher>
    </CommsScheduleContext.Provider>
  );
});

// eslint-disable-next-line import/no-default-export
export default CommsConfirmationCampaignScreen;
