import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  FontSizes,
  Link,
  MessageBar,
  MessageBarType,
  useTheme
} from "@bps/fluent-ui";
import { PatientDemographicContext } from "@modules/practice/screens/contact-details/context/PatientDemographicContext.ts";
import { PersonaPhotoStatus } from "@modules/practice/screens/contact-details/context/PatientDemographicHelper.ts";

import { PersonaProfileLabel } from "../PersonaProfilePicture.tsx";

export const PersonaProfilePictureMessageBar: React.FC = observer(() => {
  const {
    personaPhotoStatus,
    setPersonaPhotoStatus,
    personaPhotoFileName,
    retryUploadPersonaPhoto
  } = useContext(PatientDemographicContext);

  const theme = useTheme();

  return personaPhotoStatus !== PersonaPhotoStatus.stopped ? (
    <MessageBar
      messageBarType={
        personaPhotoStatus === PersonaPhotoStatus.error
          ? MessageBarType.error
          : MessageBarType.info
      }
      onDismiss={() => setPersonaPhotoStatus(PersonaPhotoStatus.stopped)}
      styles={{
        root: {
          position: "absolute",
          bottom: 0,
          width: "602px",
          right: "15px",
          padding: "4px 0"
        }
      }}
    >
      {personaPhotoStatus === PersonaPhotoStatus.uploading ? (
        `Currently uploading new profile picture ${personaPhotoFileName} ....`
      ) : (
        <div style={{ color: theme.semanticColors.errorText }}>
          {`Error uploading profile picture ${personaPhotoFileName}`}
          <Link
            styles={{
              root: {
                textAlign: "center",
                fontSize: FontSizes.size12,
                marginLeft: 16
              }
            }}
            onClick={retryUploadPersonaPhoto}
          >
            {PersonaProfileLabel.Retry}
          </Link>
        </div>
      )}
    </MessageBar>
  ) : null;
});
