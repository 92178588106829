import { useContext } from "react";

import { Heading, IDialogContentProps, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { PurchaseOrder } from "@stores/acc/models/PurchaseOrder.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { PurchaseOrderValidator } from "../../validators/PurchseOrderValidator.tsx";
import { PurchaseOrderFormValues } from "./PurchaseOrderDialog.types.ts";
import { getInitialValues } from "./utils.ts";

interface PurchaseOrderDialogProps {
  onDismiss: () => void;
  purchaseOrder?: PurchaseOrder;
}

const validator = new PurchaseOrderValidator();

const nameOf = nameOfFactory<PurchaseOrderFormValues>();

export const PurchaseOrderDialog: React.FC<PurchaseOrderDialogProps> = ({
  onDismiss,
  purchaseOrder
}) => {
  const { claim } = useContext(ClaimContext);
  const { acc } = useStores();

  const dialogContentProps: IDialogContentProps = {
    title: (
      <Heading variant="modal-heading">{`${
        purchaseOrder ? "Edit" : "Add"
      }  purchase order`}</Heading>
    )
  };

  const { setPurchaseOrderId } = useClaimAdjustmentContext();

  const onSubmit = async (values: PurchaseOrderFormValues) => {
    const { date, ...rest } = values;
    const responseDate = DateTime.jsDateToISODate(date);
    if (purchaseOrder) {
      await acc.updatePurchaseOrder({
        ...rest,
        id: purchaseOrder.id,
        eTag: purchaseOrder.eTag,
        claimId: purchaseOrder.claimId,
        responseDate
      });
    } else {
      await acc.addPurchaseOrder({
        ...rest,
        claimId: claim.id,
        responseDate
      });
    }
  };

  const onCancel = () => {
    onDismiss();
    setPurchaseOrderId();
  };

  return (
    <SubmissionFormDialog<PurchaseOrderFormValues>
      dialogName="Add purchase order"
      onSubmit={onSubmit}
      initialValues={getInitialValues(purchaseOrder)}
      onSubmitSucceeded={onCancel}
      buttonsProps={{
        submitButtonProps: {
          text: purchaseOrder ? "Save" : "New purchase order"
        }
      }}
      dialogProps={{
        dialogContentProps,
        onDismiss: onCancel,
        minWidth: 600,
        styles: {
          root: {
            ".ms-Dialog-action": { width: "100%" }
          }
        },
        modalProps: {
          layerProps: {
            eventBubblingEnabled: false
          }
        }
      }}
      validate={validator.validate}
    >
      {() => (
        <Fieldset>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <TextInputField
              required
              label="Number"
              name={nameOf("purchaseOrderNumber")}
              styles={{ root: { width: "50%" } }}
            />
            <DatePickerField
              name={nameOf("date")}
              label="Date"
              required
              maxDate={DateTime.jsDateNow()}
            />
          </Stack>
          <TextInputField
            multiline
            label="Notes"
            name={nameOf("purchaseOrderNotes")}
          />
        </Fieldset>
      )}
    </SubmissionFormDialog>
  );
};
