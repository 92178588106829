import { observer } from "mobx-react-lite";

import { Toggle } from "@bps/fluent-ui";

import { useTransactionListContext } from "./context/TransactionListContext.ts";

export const ExpandTransactionsToggle: React.FC = observer(() => {
  const { rowsToggledOpen, toggleAll, allParentIds } =
    useTransactionListContext();

  return (
    <Toggle
      automationAttribute="expand-all-rows"
      inlineLabel
      label={
        allParentIds?.length && rowsToggledOpen.size > 0
          ? "Close all rows"
          : "Expand all rows"
      }
      checked={allParentIds?.length === rowsToggledOpen.size}
      onChange={toggleAll}
      styles={{
        root: { flexGrow: 1, margin: 0, alignSelf: "end" },
        label: { marginLeft: 8, marginRight: 8 }
      }}
    />
  );
});
