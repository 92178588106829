import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { useCallback, useRef } from "react";

import {
  applyOpacityToHexColor,
  FontWeights,
  mergeStyleSets,
  ScrollablePane,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import {
  ItemType,
  TransactionItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { SortableColumnIds } from "@modules/billing/screens/acc-invoices/components/InvoiceRowColumns.ts";
import { TransactionOwingCell } from "@modules/billing/screens/account/components/transaction-list/TransactionOwingCell.tsx";
import { TransactionValueCell } from "@modules/billing/screens/account/components/transaction-list/TransactionValueCell.tsx";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import { getInvoiceItemOwing } from "@stores/billing/utils/billing.utils.ts";
import {
  isCreditNote,
  isInvoice,
  isPayment
} from "@stores/billing/utils/transaction.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import {
  InfiniteScrollListProps,
  SortDirection
} from "@ui-components/InfiniteScrollList/InfiniteScrollList.types.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { TransactionCell } from "../../TransactionCell.tsx";
import { useTransactionFilterContext } from "../transaction-filter/context/TransactionFilterContext.ts";
import { AccountTransactionContextualMenu } from "./AccountTransactionContextualMenu.tsx";
import { CollapsibleRow } from "./CollapsibleRow.tsx";
import {
  TransactionListContext,
  useTransactionListContext
} from "./context/TransactionListContext.ts";
import { TransactionListHelper } from "./context/TransactionListHelper.ts";
import { ExpandTransactionsToggle } from "./ExpandTransactionsToggle.tsx";
import { TransactionActionCell } from "./TransactionActionCell.tsx";
import { TransactionListNoResults } from "./TransactionListNoResults.tsx";

export interface TransactionRow {
  item: TransactionBase;
  subItem?: TransactionItemDto;
}

const TransactionNewListBase = observer(() => {
  const { routing, billing, core } = useStores();
  const { accountContact, resetRowsToggledOpen, setAllParentIdsNew } =
    useTransactionListContext();

  const theme = useTheme();

  const patientLabel = usePatientLabel(true);

  const { getTransactionArgs } = useTransactionFilterContext();

  const numberSearch = parse(routing.location.search).numberSearch;

  const onSearch = useCallback(
    async (query: PagingOptions): Promise<QueryResult<TransactionBase>> => {
      resetRowsToggledOpen();
      //add logic to default to accountID whne on PA
      return await billing.fetchTransactionsNew(getTransactionArgs(query));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routing.location.search, getTransactionArgs]
  );

  const onRenderRow: InfiniteScrollListProps<TransactionBase>["onRenderRow"] = (
    props,
    defaultRender
  ) => {
    if (!props || !defaultRender) {
      return null;
    }

    const owing = isInvoice(props.item) ? props.item.owing : undefined;

    const backgroundColor =
      owing && owing > 0
        ? applyOpacityToHexColor(theme.semanticColors.errorBackground, 50)
        : undefined;

    const owingStyles = {
      root: {
        backgroundColor,
        "&:hover": {
          backgroundColor
        }
      }
    };

    return (
      <CollapsibleRow
        item={props.item}
        renderRow={(item: TransactionRow) =>
          defaultRender({
            ...props,
            item,
            styles: mergeStyleSets(props.styles, owingStyles)
          })
        }
      />
    );
  };

  const columns = [
    {
      key: "ContextMenu",
      name: "",
      minWidth: 36,
      maxWidth: 36,
      onRender: ({ item, subItem }: TransactionRow) => {
        if (subItem) {
          return null;
        }
        return <AccountTransactionContextualMenu transaction={item} />;
      },
      data: { filter: !!accountContact }
    },
    {
      name: "Updated",
      key: SortableColumnIds.transactionChangedDate,
      minWidth: 79,
      maxWidth: 79,
      isSortable: true,
      sortDefaultDirection: SortDirection.Descending,
      onRender: ({ item, subItem }: TransactionRow) => {
        if (!subItem) {
          return DateTime.fromJSDate(item.changedDate).toDayDefaultFormat();
        }
        return "";
      }
    },
    {
      name: "Transaction",
      key: "transaction",
      minWidth: 350,
      maxWidth: 350,
      onRender: ({ item, subItem }: TransactionRow) => (
        <TransactionCell transaction={item} item={subItem} />
      )
    },
    {
      name: "Value ($)",
      key: "value",
      minWidth: 80,
      maxWidth: 80,
      styles: { cellTitle: { justifyContent: "flex-end" } },
      onRender: ({ item, subItem }: TransactionRow) => {
        if (!subItem) {
          return <TransactionValueCell total={item.total} />;
        } else if (subItem) {
          return <TransactionValueCell total={subItem.amount} />;
        } else {
          return null;
        }
      }
    },
    {
      name: "Owing ($)",
      key: "owing",
      minWidth: 80,
      maxWidth: 80,
      styles: { cellTitle: { justifyContent: "flex-end" } },
      onRender: ({ item, subItem }: TransactionRow) => {
        if (!subItem && isInvoice(item)) {
          return (
            <TransactionOwingCell
              owing={item.owing || 0}
              isCancelled={item.isCancelled}
            />
          );
        } else if (subItem?.itemType === ItemType.Invoice) {
          return (
            item && (
              <TransactionOwingCell
                owing={getInvoiceItemOwing(subItem) || 0}
                isCancelled={item.isCancelled}
              />
            )
          );
        } else if (!subItem && (isCreditNote(item) || isPayment(item))) {
          return (
            <Text
              styles={{
                root: {
                  fontWeight: FontWeights.semibold,
                  textAlign: "right",
                  display: "block"
                }
              }}
            >
              {item.unallocated > 0 &&
                `${currencyFormat(item.unallocated, { currency: "" })} CR`}
            </Text>
          );
        } else {
          return null;
        }
      }
    },
    {
      name: "Action",
      key: "action",
      minWidth: 50,
      maxWidth: 50,
      onRender: ({ item, subItem }: TransactionRow) => {
        if (!subItem) {
          return <TransactionActionCell transaction={item} />;
        }
        return null;
      },
      data: { filter: core.hasPermissions(Permission.InvoiceCreate) }
    },
    {
      name: patientLabel,
      key: "patient",
      minWidth: 200,
      maxWidth: 200,
      onRender: ({ item, subItem }: TransactionRow) => {
        return (
          !subItem &&
          isInvoice(item) &&
          item.patientId && (
            <>
              {!accountContact ? (
                <Navigate
                  to={{
                    pathname: routes.accounts.account.path({
                      id: item.patientId
                    })
                  }}
                >
                  {item.patientName}
                </Navigate>
              ) : (
                <Text>{item.patientName}</Text>
              )}
            </>
          )
        );
      }
    },
    {
      name: "Billed to",
      key: "billedTo",
      minWidth: 167,
      maxWidth: 212,
      onRender: ({ item, subItem }: TransactionRow) => {
        return (
          !subItem &&
          item.accountId && (
            <>
              {!accountContact ? (
                <Navigate
                  to={{
                    pathname: routes.accounts.account.path({
                      id: item.accountId
                    })
                  }}
                >
                  {item.accountContactName}
                </Navigate>
              ) : (
                <Text> {item.accountContactName}</Text>
              )}
            </>
          )
        );
      }
    },
    {
      name: "Provider",
      key: "provider",
      minWidth: 100,
      maxWidth: 380,
      onRender: ({ item, subItem }: TransactionRow) => {
        return (
          !subItem &&
          isInvoice(item) &&
          item.user && <Text>{item.userNameWithTitle}</Text>
        );
      }
    }
  ].filter(
    x =>
      x.data?.filter === undefined ||
      (x.data?.filter !== undefined && !!x.data?.filter)
  );

  return (
    <Stack styles={{ root: { height: "100%" } }}>
      <ExpandTransactionsToggle />
      <div style={{ position: "relative", height: "100%" }}>
        <ScrollablePane>
          <InfiniteScrollList<TransactionBase>
            setKey="accounts-list"
            getItems={onSearch}
            onGetItems={setAllParentIdsNew}
            refreshKey={`${billing.ui.lastAddedTransactionEtag}`}
            detailsListStyles={{
              root: {
                ".ms-DetailsRow-cell": { alignSelf: "center" }
              }
            }}
            columns={columns}
            onRenderRow={onRenderRow}
            onRenderNoResults={() =>
              numberSearch ? (
                <DefaultNoResultsTile
                  defaultText={`No matches found for "${numberSearch}"`}
                />
              ) : (
                <TransactionListNoResults />
              )
            }
            initialSort={{
              sortColumn: SortableColumnIds.transactionChangedDate,
              sortDescending: true
            }}
          />
        </ScrollablePane>
      </div>
    </Stack>
  );
});

export interface TransactionNewListProps {
  accountContact?: Contact;
}

export const TransactionList: React.FC<TransactionNewListProps> = ({
  accountContact
}) => {
  const helper = useRef(new TransactionListHelper(accountContact));

  return (
    <TransactionListContext.Provider value={helper.current}>
      <TransactionNewListBase />
    </TransactionListContext.Provider>
  );
};
