import { FunctionComponent, memo } from "react";

import { FontIcon, FontSizes, Stack, Text, useTheme } from "@bps/fluent-ui";

export interface ILinkedConditionTextProps {
  compact?: boolean;
}

export const LinkedConditionText: FunctionComponent<ILinkedConditionTextProps> =
  memo(({ compact }) => {
    const theme = useTheme();
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        styles={{
          root: compact
            ? {
                background: theme.palette.themeLighterAlt
              }
            : {
                background: theme.palette.themeLighterAlt,
                padding: "8px 12px",
                marginBottom: 12
              }
        }}
      >
        <FontIcon
          iconName="Completed"
          styles={{
            root: {
              color: theme.palette.themePrimary,
              fontSize: FontSizes.size16
            }
          }}
        />
        <Text styles={{ root: { fontSize: FontSizes.size12 } }}>Linked</Text>
      </Stack>
    );
  });
