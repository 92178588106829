import { memo } from "react";

import {
  CompoundButton,
  dataAttribute,
  DataAttributes,
  FontWeights,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ItalicCenterText } from "@ui-components/ItalicCenterText.tsx";

export type NoDetailsButtonProps = {
  sectionId: string;
  actionTitle: string;
  secondaryText: string;
  noPermissionText?: string;
  onHandleEditModal: (id?: string | undefined) => void;
};

export const NoDetailsButton: React.FC<NoDetailsButtonProps> = memo(
  ({
    actionTitle,
    onHandleEditModal,
    secondaryText,
    sectionId,
    noPermissionText
  }) => {
    const { core } = useStores();
    const theme = useTheme();
    const compoundButtonStyles = {
      root: {
        fontSize: theme.fonts.medium.fontSize,
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        width: 280,
        margin: "24px auto 0"
      }
    };

    const onCompoundButtonClick = (sectionId: string) => () => {
      onHandleEditModal(sectionId);
    };

    const canOpenRecord = core.hasPermissions([
      Permission.ContactWrite,
      Permission.PatientWrite
    ]);

    return canOpenRecord ? (
      <CompoundButton
        {...dataAttribute(DataAttributes.Element, "no-details-button")}
        secondaryText={secondaryText}
        styles={compoundButtonStyles}
        onClick={onCompoundButtonClick(sectionId)}
      >
        {actionTitle}
      </CompoundButton>
    ) : (
      <ItalicCenterText
        {...dataAttribute(DataAttributes.Element, "no-details-button-text")}
        styles={{
          root: { margin: "12px 0" }
        }}
      >
        {noPermissionText}
      </ItalicCenterText>
    );
  }
);
