import { FunctionComponent } from "react";

import {
  FontWeights,
  IStackItemStyles,
  IStackStyles,
  ITextStyles,
  noWrap,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import {
  BenefitSchedule,
  ServiceSearchDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ServicePickerInputFieldSuggestionItemProps {
  item: ServiceSearchDto;
}

const wrapperStyles: IStackStyles = {
  root: { width: "100%", textAlign: "left", padding: 8 }
};

const leftWrapperStyles: IStackItemStyles = {
  root: { flexShrink: 1, overflow: "hidden" }
};

const codeTextStyles: ITextStyles = {
  root: { fontWeight: FontWeights.semibold, whiteSpace: "noWrap" }
};

const descriptionTextStyles: ITextStyles = {
  root: {
    ...noWrap
  }
};

const rightWrapperStyles: IStackItemStyles = {
  root: {
    width: 140,
    flexShrink: 0,
    display: "flex",
    justifyContent: "flex-end"
  }
};

const badgeStyles: IStackStyles = {
  root: {
    minWidth: 80,
    maxWidth: 80,
    display: "inline",
    ...noWrap
  }
};

const feeStyles: ITextStyles = {
  root: {
    marginRight: 8,
    alignSelf: "center"
  }
};

export const ServicePickerInputFieldSuggestionItem: FunctionComponent<
  ServicePickerInputFieldSuggestionItemProps
> = props => {
  const { item } = props;
  const { billing } = useStores();

  const schedule = billing.schedulesMap.get(item.scheduleId);
  const scheduleBadgeColor =
    schedule?.benefitSchedule === BenefitSchedule.ACC
      ? TextBadgeColor.green
      : TextBadgeColor.blue;

  return (
    <Stack
      horizontal
      verticalAlign="center"
      styles={wrapperStyles}
      tokens={{ childrenGap: 8 }}
    >
      <Stack.Item grow styles={leftWrapperStyles}>
        <Stack grow horizontal tokens={{ childrenGap: 4 }}>
          <Text styles={codeTextStyles}>{`${item.code}:`}</Text>
          <Text styles={descriptionTextStyles}>
            {item.name || item.description}
          </Text>
        </Stack>
      </Stack.Item>
      <Stack.Item styles={rightWrapperStyles}>
        <Text styles={feeStyles}>{currencyFormat(item.fee || 0)}</Text>
        {schedule && (
          <TextBadge
            badgeColor={scheduleBadgeColor}
            badgeSize={TextBadgeSize.small}
            styles={badgeStyles}
          >
            {schedule.name}
          </TextBadge>
        )}
      </Stack.Item>
    </Stack>
  );
};
