import { FC } from "react";

import { FontWeights, Stack, Text } from "@bps/fluent-ui";

import { FormattedMeasurements } from "./generateMeasurementColumns.tsx";
import { EN_DASH } from "./utils.ts";

interface OtherMeasurementDetailsProps {
  formattedMeasurements: FormattedMeasurements;
}

export const OtherMeasurementDetails: FC<OtherMeasurementDetailsProps> = ({
  formattedMeasurements
}) => {
  return (
    <Stack styles={{ root: { alignSelf: "flex-start" } }}>
      {Object.keys(formattedMeasurements).map(timestamp => {
        const measurement = formattedMeasurements[timestamp];
        if (!measurement) {
          return null;
        }
        return (
          <Text
            key={timestamp}
            variant="medium"
            styles={{ root: { fontWeight: FontWeights.semibold } }}
          >
            {measurement.value ? measurement.value : EN_DASH}
          </Text>
        );
      })}
    </Stack>
  );
};
