import { observer } from "mobx-react-lite";

import {
  getScreenSelector,
  ScreenWidthMinXLarge,
  Stack,
  Tile
} from "@bps/fluent-ui";
import { PatientSummary as PatientSummaryEnum } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { PatientSummaryItem } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";
import { PatientSummaryEmptyState } from "./PatientSummaryEmptyState.tsx";
import { PatientSummaryHeader } from "./PatientSummaryHeader.tsx";

interface PatientSummaryCardProps {
  cardInfo: PatientSummaryCardInfo;
}

export type PatientSummaryCardInfo = PatientSummaryItem & {
  content: JSX.Element | null;
  onClickTile?: () => void;
  onClickLink?: () => void;
  callout?: JSX.Element | null;
  columnSpan?: number;
  isDisableLink?: boolean;
  counter?: JSX.Element | null;
};
export const PatientSummaryCard: React.FunctionComponent<PatientSummaryCardProps> =
  observer(({ cardInfo }) => {
    const {
      iconName,
      onClickLink,
      callout,
      key,
      content,
      columnSpan,
      onClickTile,
      counter,
      isDisableLink
    } = cardInfo;

    const { clinicalRecord } = usePatientRecordScreenContext();
    const renderExtraTitle = () => {
      let showIcon = false;

      if (
        key === PatientSummaryEnum.ClinicalTasks ||
        key === PatientSummaryEnum.ClinicalNotifications
      ) {
        showIcon =
          clinicalRecord?.isExistConfidentialClinicalTasksWithOtherUsers;
      } else if (key === PatientSummaryEnum.ReasonForVisit) {
        showIcon =
          clinicalRecord?.isExistConfidentialClosedEncountersWithOtherUsers;
      }

      const showConfidential =
        key === PatientSummaryEnum.ClinicalTasks ||
        key === PatientSummaryEnum.ReasonForVisit;

      let tooltipType =
        key === PatientSummaryEnum.ClinicalTasks ? "tasks" : "past consults";
      if (key === PatientSummaryEnum.ClinicalNotifications) {
        tooltipType = "notifications";
      }

      const tooltipText = `Some ${tooltipType} are confidential`;

      return (
        <Stack>
          {counter}
          {showIcon ? (
            <ConfidentialToolTipFontIcon
              content={tooltipText}
              isShowConfidentialIcon={showConfidential}
              summaryStyling
            />
          ) : null}
        </Stack>
      );
    };

    return (
      <Tile
        tokens={{ childrenGap: 0, padding: 0 }}
        styles={({ theme }) => ({
          root: {
            height: 220,
            minWidth: "100%",
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderColor: theme.palette.neutralTertiary,
            boxShadow: "none",
            overflowY: "hidden",
            gridColumnEnd: columnSpan ? `span ${columnSpan}` : "",
            selectors: {
              [getScreenSelector(0, ScreenWidthMinXLarge)]: {
                gridColumnEnd: "span 1"
              }
            }
          },
          header: {
            background: theme.palette.neutralLighterAlt,
            color: theme.semanticColors.messageLink,
            fontWeight: 600,
            height: 50,
            padding: "8px 16px",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
          },
          content: {
            overflowY: "auto",
            padding: "8px 8px",
            whiteSpace: "pre-line",
            flexGrow: 1
          }
        })}
        onClick={onClickTile}
        header={
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
            <PatientSummaryHeader
              iconName={iconName}
              code={key}
              callout={callout}
              onClickLink={onClickLink}
              isDisableLink={isDisableLink}
            />
            {renderExtraTitle()}
          </Stack>
        }
      >
        {content ? content : <PatientSummaryEmptyState />}
      </Tile>
    );
  });
