import { runInAction } from "mobx";

import {
  ContextualMenuItemType,
  IContextualMenuItem,
  IIconProps,
  ITheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  CalendarEventReminderStatus,
  CalendarEventStatus
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { CalendarEventReminderSearchModel } from "@stores/booking/models/CalendarEventReminderSearchModel.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { CalendarEventAttendeeArgs } from "../../booking-calendar/context/BookingCalendarScreenModel.types.ts";
import {
  EventReminderColours,
  EventReminderStatesEnumText,
  EventReminderStatesText,
  MenuItemParams
} from "../EventReminderScreen.types.ts";

export const getEventReminderStatusColours =
  (theme: ITheme) =>
  (code: CalendarEventReminderStatus): string => {
    const colours: EventReminderColours = {
      NOREPLY: theme.palette.neutralTertiary,
      CONFIRMED: theme.palette.green,
      REQUIRESREVIEW: theme.palette.yellowLight,
      DECLINED: theme.palette.redDark
    };
    return colours[code];
  };

export enum EventReminderTreeViewSize {
  IconsOnly = 0,
  Expanded = 1
}

export enum EventReminderDateFilterTypes {
  CalendarEvent = "Appt",
  Sent = "Sent",
  Reply = "Received"
}

export enum EventReminderDateFilterValues {
  Today = "Today",
  Yesterday = "Yesterday",
  Tomorrow = "Tomorrow",
  Custom = "Custom"
}

export interface cancelSubMenuOptionsItemProps {
  booking: BookingStore;
  item: CalendarEventReminderSearchModel;
  key: string;
  text: string;
}

export enum CancelSubMenuOptionsTypes {
  RecurrenceEvent = "cancelThisAndFollowing",
  CurrentEvent = "cancelThisEvent",
  SingleEvent = "cancel"
}

export enum CancelSubMenuOptionsTypeText {
  RecurrenceEvent = "This and the following events",
  CurrentEvent = "This event",
  SingleEvent = "Cancel appointment"
}

const addCancelSubMenuOptionsItem = (value: cancelSubMenuOptionsItemProps) => {
  const { booking, item, key, text } = value;

  return {
    key,
    text,
    onClick: () => {
      if (item.calendarEvent.isGroupAppointment) {
        booking.ui.setShowAttendeeCancellationDialog(
          true,
          item.calendarEvent.id,
          item.patientId
        );
      } else {
        booking.ui.setShowCancelCalendarEventDialog(
          true,
          item.calendarEvent.id
        );
        addCancelRecurrenceSubMenuOptionsItem(value);
      }
    },
    permissions: [Permission.CalendarEventWrite]
  };
};

const addCancelRecurrenceSubMenuOptionsItem = (
  value: cancelSubMenuOptionsItemProps
) => {
  const { booking, item, key } = value;
  if (key === CancelSubMenuOptionsTypes.RecurrenceEvent) {
    booking.ui.setCancelCalendarEventRecurrenceId(
      item.calendarEvent.calendarEventRecurrenceId
    );
  } else {
    booking.ui.setCancelCalendarEventId(item.calendarEvent.id);
  }
};

const getCalendarEventStatus = (item: CalendarEventReminderSearchModel) => {
  return item.calendarEvent.attendeesPatientAndContact.find(
    X => X.attendeeId === item.patientId
  )?.status;
};

export const eventReminderMenuItems = (
  params: MenuItemParams
): IContextualMenuItem[] => {
  const menuItemCollection: IContextualMenuItem[] = [];

  const item = params.item;
  if (!item) return menuItemCollection;

  const divider: IContextualMenuItem = {
    key: "divider1",
    itemType: ContextualMenuItemType.Divider
  };

  if (
    item.attendanceStatus &&
    params.root.core.hasPermissions(Permission.SendApptRemindersAllowed)
  ) {
    const statusMenu = statusMenuItems(item, params.root, params.theme);

    menuItemCollection.push(statusMenu, divider);
  }

  const items = sidePanelMenuActionItems(
    item,
    params.root,
    params.setReminderArgs
  );
  return menuItemCollection.concat(items);
};

export const getAlternativeStates = (
  reminderResult: CalendarEventReminderSearchModel,
  root: RootStore,
  theme: ITheme
): IContextualMenuItem[] => {
  const states: IContextualMenuItem[] = [];
  const { booking } = root;

  const calendarEvent = reminderResult.calendarEvent;

  const updateAttendanceStatus = (
    attendanceStatus: CalendarEventReminderStatus
  ) => {
    booking.updateCalendarEvent({
      id: calendarEvent.id,
      attendees: calendarEvent.dto.attendees.map(attendee => {
        return attendee.attendeeId === reminderResult.patientId
          ? { ...attendee, attendanceStatus }
          : attendee;
      })
    });
  };

  const attending = {
    key: CalendarEventReminderStatus.CONFIRMED,
    text: EventReminderStatesText.Confirmed,
    iconProps: getBulletIconProps(CalendarEventReminderStatus.CONFIRMED, theme),
    onClick: () => updateAttendanceStatus(CalendarEventReminderStatus.CONFIRMED)
  };

  const notAttending = {
    key: CalendarEventReminderStatus.DECLINED,
    text: EventReminderStatesText.Declined,
    iconProps: getBulletIconProps(CalendarEventReminderStatus.DECLINED, theme),
    onClick: () => updateAttendanceStatus(CalendarEventReminderStatus.DECLINED)
  };

  const requiresReview = {
    key: CalendarEventReminderStatus.REQUIRESREVIEW,
    text: EventReminderStatesText.RequiresReview,
    iconProps: getBulletIconProps(
      CalendarEventReminderStatus.REQUIRESREVIEW,
      theme
    ),
    onClick: () =>
      updateAttendanceStatus(CalendarEventReminderStatus.REQUIRESREVIEW)
  };

  const noReply = {
    key: CalendarEventReminderStatus.NOREPLY,
    text: EventReminderStatesText.NoReply,
    iconProps: getBulletIconProps(CalendarEventReminderStatus.NOREPLY, theme),
    onClick: () => updateAttendanceStatus(CalendarEventReminderStatus.NOREPLY)
  };

  if (
    reminderResult.attendanceStatus === CalendarEventReminderStatus.CONFIRMED
  ) {
    states.push(notAttending, requiresReview, noReply);
  }

  if (
    reminderResult.attendanceStatus === CalendarEventReminderStatus.DECLINED
  ) {
    states.push(attending, requiresReview, noReply);
  }

  if (
    reminderResult.attendanceStatus ===
    CalendarEventReminderStatus.REQUIRESREVIEW
  ) {
    states.push(notAttending, attending, noReply);
  }

  if (reminderResult.attendanceStatus === CalendarEventReminderStatus.NOREPLY) {
    states.push(notAttending, attending, requiresReview);
  }

  return states;
};

export const getBulletIconProps = (
  state: CalendarEventReminderStatus,
  theme: ITheme
): IIconProps => {
  const eventReminderStatusColors = getEventReminderStatusColours(theme);
  return {
    style: {
      color: eventReminderStatusColors(state),
      fontSize: 22
    },
    iconName: "RadioBullet"
  };
};

const statusMenuItems = (
  item: CalendarEventReminderSearchModel,
  root: RootStore,
  theme: ITheme
): IContextualMenuItem => {
  if (item.attendanceStatus) {
    return {
      key: item.attendanceStatus,
      text: EventReminderStatesEnumText[item.attendanceStatus],
      iconProps: getBulletIconProps(item.attendanceStatus, theme),
      subMenuProps: {
        items: getAlternativeStates(item, root, theme)
      }
    } as IContextualMenuItem;
  } else {
    const divider3: IContextualMenuItem = {
      key: "divider3",
      itemType: ContextualMenuItemType.Divider
    };
    return divider3;
  }
};

export const sidePanelMenuActionItems = (
  item: CalendarEventReminderSearchModel,
  root: RootStore,
  setReminderArgs: (value?: CalendarEventAttendeeArgs | undefined) => void
): IContextualMenuItem[] => {
  let menuItems: IContextualMenuItem[] = [];
  if (!item) return menuItems;

  menuItems = [
    getSmsMenuItem(item, setReminderArgs),
    getEditMenuItem(root, item),
    getCancelMenuItem(root, item)
  ];
  return menuItems.filter(
    item =>
      item &&
      (!item?.permissions || root.core.hasPermissions(item?.permissions))
  );
};

const getEditMenuItem = (
  { booking }: RootStore,
  item: CalendarEventReminderSearchModel
) => {
  const status = getCalendarEventStatus(item);
  const disabled =
    item.disabled ||
    (status
      ? status === CalendarEventStatus.Cancelled
      : item.calendarEvent.status === CalendarEventStatus.Cancelled);

  const editSubMenuOptions = [
    {
      key: "editThisEvent",
      text: "This event",
      onClick: () => {
        booking.ui.showCalendarEventDialog({
          type: item.calendarEvent.type,
          id: item.calendarEvent.id
        });
        runInAction(() => {
          booking.ui.isEditSingleEvent = true;
        });
      },
      permissions: [Permission.CalendarEventWrite]
    }
  ];

  if (
    !(
      item.calendarEvent.startDateTime &&
      item.calendarEvent.startDateTime < DateTime.now()
    )
  ) {
    editSubMenuOptions.push({
      key: "editThisAndFollowing",
      text: "This and the following events",
      onClick: () => {
        booking.ui.showCalendarEventDialog({
          type: item.calendarEvent.type,
          id: item.calendarEvent.id
        });
      },
      permissions: [Permission.CalendarEventWrite]
    });
  }

  const editAppointment: IContextualMenuItem =
    item.calendarEvent.isPartOfRecurrence &&
    item.calendarEvent.calendarEventRecurrenceId
      ? {
          key: "edit",
          disabled,
          text: "Edit appointment",
          iconProps: { iconName: "Edit" },
          subMenuProps: {
            items: [...editSubMenuOptions]
          }
        }
      : {
          key: "edit",
          disabled,
          text: "Edit appointment",
          iconProps: {
            style: {
              fontSize: 18
            },
            iconName: "Edit"
          },
          onClick: () => {
            booking.ui.showCalendarEventDialog({
              type: item.calendarEvent.type,
              id: item.calendarEvent.id
            });
          }
        };
  editAppointment.permissions = [Permission.CalendarEventWrite];
  return editAppointment;
};

const getSmsMenuItem = (
  item: CalendarEventReminderSearchModel,
  setReminderArgs: (value?: CalendarEventAttendeeArgs | undefined) => void
) => {
  const sms: IContextualMenuItem = {
    key: "sms",
    text: "SMS",
    disabled: item.disabled,
    iconProps: {
      style: {
        fontSize: 18
      },
      iconName: "CellPhone"
    },
    onClick: () => {
      setReminderArgs({
        calendarEventId: item.calendarEvent.id,
        contactId: item.patientId
      });
    },
    permissions: [Permission.CommunicationTemplateRead]
  };
  return sms;
};

const getCancelMenuItem = (
  { booking }: RootStore,
  item: CalendarEventReminderSearchModel
) => {
  const status = getCalendarEventStatus(item);
  const disabled =
    item.disabled ||
    (status
      ? status === CalendarEventStatus.Cancelled
      : item.calendarEvent.status === CalendarEventStatus.Cancelled);

  const cancelSubMenuOptions = [
    addCancelSubMenuOptionsItem({
      booking,
      item,
      key: CancelSubMenuOptionsTypes.CurrentEvent,
      text: CancelSubMenuOptionsTypeText.CurrentEvent
    })
  ];
  if (
    !(
      item.calendarEvent.startDateTime &&
      item.calendarEvent.startDateTime < DateTime.now()
    )
  ) {
    cancelSubMenuOptions.push(
      addCancelSubMenuOptionsItem({
        booking,
        item,
        key: CancelSubMenuOptionsTypes.RecurrenceEvent,
        text: CancelSubMenuOptionsTypeText.RecurrenceEvent
      })
    );
  }

  const menuitem = addCancelSubMenuOptionsItem({
    booking,
    item,
    key: CancelSubMenuOptionsTypes.SingleEvent,
    text: CancelSubMenuOptionsTypeText.SingleEvent
  });

  const cancelAppointment: IContextualMenuItem =
    item.calendarEvent?.isPartOfRecurrence &&
    item.calendarEvent.calendarEventRecurrenceId
      ? {
          key: CancelSubMenuOptionsTypes.SingleEvent,
          disabled,
          subMenuProps: {
            items: [...cancelSubMenuOptions]
          },
          text: CancelSubMenuOptionsTypeText.SingleEvent,
          iconProps: {
            style: {
              fontSize: 18
            },
            iconName: "Delete"
          }
        }
      : {
          ...menuitem,
          disabled,
          iconProps: {
            style: {
              fontSize: 18
            },
            iconName: "Delete"
          }
        };
  cancelAppointment.permissions = [Permission.CalendarEventWrite];
  return cancelAppointment;
};
