import { FormSpy } from "react-final-form";

import {
  CompoundButton,
  FieldItemError,
  IButtonProps,
  mergeStyleSets,
  Stack,
  useTheme
} from "@bps/fluent-ui";

export type CompoundButtonWithErrorProps<T> = IButtonProps & {
  fields: (keyof T)[];
  errorMessage: string;
  hasBorder?: boolean;
};
export const CompoundButtonWithError = <T extends object>({
  errorMessage,
  fields,
  hasBorder = true,
  ...compoundButtonprops
}: CompoundButtonWithErrorProps<T>) => {
  const { palette, semanticColors, spacing } = useTheme();
  return (
    <FormSpy
      subscription={{
        errors: true,
        submitFailed: true
      }}
    >
      {({ errors, submitFailed }) => {
        const isFieldPresent = fields.find(
          field => errors && errors.hasOwnProperty(field)
        );

        const showError = submitFailed && isFieldPresent;
        return (
          <Stack>
            <CompoundButton
              data-has-error={!!showError}
              {...compoundButtonprops}
              styles={mergeStyleSets(
                {
                  root: {
                    "&:disabled": {
                      border:
                        hasBorder && `1px solid ${palette.neutralTertiary}`
                    },
                    borderColor: !!showError
                      ? semanticColors.errorText
                      : palette.neutralDark,
                    minHeight: 56,
                    padding: spacing.m
                  },
                  flexContainer: {
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }
                },
                compoundButtonprops.styles
              )}
            />
            {!!showError && (
              <FieldItemError
                name={fields.join("-")}
                errorMessage={errorMessage}
              />
            )}
          </Stack>
        );
      }}
    </FormSpy>
  );
};
