import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { CancelAppointmentRecurrenceFormValues } from "./CancelAppointmentRecurrenceForm.types.ts";

export class CalendarEventRecurrenceCancelFormValidator extends Validator<CancelAppointmentRecurrenceFormValues> {
  constructor(
    otherCancellationReasonId?: string,
    captureApptCancellationReason?: boolean
  ) {
    super();
    !!captureApptCancellationReason &&
      this.forField("cancellationReasonId", required());
    this.forField("cancellationText", [
      predicate(
        (
          val: string,
          cancelCalendarEvent: CancelAppointmentRecurrenceFormValues
        ) =>
          cancelCalendarEvent.cancellationReasonId ===
          otherCancellationReasonId,
        required()
      ),
      maxLength(50)
    ]);
  }
}
