import {
  IStackItemStyles,
  IStackStyles,
  ITheme,
  TileStyles
} from "@bps/fluent-ui";

export const getInboxScreenStylesSet = (theme: ITheme) => {
  const uploadScreenTile: Partial<TileStyles> = {
    root: {
      padding: "24px",
      width: "100%",
      margin: "0 auto",
      overflowY: "hidden",
      height: "100%"
    },
    content: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexFlow: "column nowrap"
    }
  };

  const pdfInboxWrapper: IStackItemStyles = {
    root: {
      height: "100%",
      width: "100%",
      overflowY: "auto",
      textAlign: "center",
      position: "relative",
      justifyContent: "center",
      color: theme.palette.neutralTertiary,
      backgroundColor: theme.palette.neutralLighterAlt
    }
  };

  const pdfDialogWrapper: IStackItemStyles = {
    root: {
      height: "100%",
      width: "100%",
      overflowY: "auto",
      textAlign: "center",
      position: "relative",
      justifyContent: "center",
      color: theme.palette.neutralTertiary,
      backgroundColor: theme.palette.neutralDark
    }
  };

  const noPDFWrapper: IStackItemStyles = {
    root: {
      width: "100%",
      height: "100%",
      overflowY: "auto",
      textAlign: "center",
      position: "relative",
      justifyContent: "center",
      color: theme.palette.neutralTertiary,
      backgroundColor: theme.palette.neutralLighterAlt
    }
  };

  const groupedListHeaderStyles = {
    ".ms-GroupHeader:hover": {
      background: "inherit"
    },
    ".ms-GroupHeader-title": {
      cursor: "default",
      whiteSpace: "normal",
      wordBreak: "break-word"
    },
    ".ms-GroupHeader-dropIcon": {
      display: "none"
    },
    ".ms-GroupedList-group": {
      paddingBottom: 16
    }
  };

  return {
    uploadScreenTile,
    groupedListHeaderStyles,
    pdfInboxWrapper,
    noPDFWrapper,
    pdfDialogWrapper
  };
};

export const sidePanelWidth: IStackStyles = {
  root: {
    flexBasis: 340,
    overflowX: "auto",
    flexShrink: 0
  }
};
