import { getIn } from "final-form";
import { useForm, useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  IComboBoxOption,
  IDropdownOption
} from "@bps/fluent-ui";
import {
  OPTOUT,
  OutboundCommChannel,
  OutboundCommChannelMapping
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { PatientEditFormValues } from "./PatientEditFormValues.tsx";

export const getDropdownValues = (
  values: PatientEditFormValues,
  typeValue: OutboundCommChannel
): IComboBoxOption[] => {
  const { communications } = values;
  if (!communications || !typeValue) return [];

  switch (typeValue) {
    case OutboundCommChannel.Email:
      return communications
        .filter(c => c.type === CommunicationType.Email && c.value)
        .map((m, i) => ({ key: i, text: m.value ?? "" }));
    case OutboundCommChannel.Sms:
      return communications
        .filter(c => c.type === CommunicationType.Mobile && c.value)
        .map((m, i) => ({ key: i, text: m.value ?? "" }));
    default:
      return [];
  }
};
export interface CommsPreferenceItemProps {
  key: string;
  label: string;
  automationName: string;
  typeFieldName: keyof PatientEditFormValues;
  valueFieldName: keyof PatientEditFormValues;
  automationActiveFieldName?: keyof PatientEditFormValues;
  permission?: string;
  typeOptions: IDropdownOption<any>[];
  onValueSelected?: (values?: PatientEditFormValues) => void;
  onTypeSelected?: (values?: PatientEditFormValues) => void;
}

export const CommsPreferenceItem: React.FC<CommsPreferenceItemProps> = ({
  permission,
  typeOptions,
  label,
  automationName,
  typeFieldName,
  valueFieldName,
  onValueSelected,
  onTypeSelected
}) => {
  const { change } = useForm<PatientEditFormValues>();
  const { values } = useFormState<PatientEditFormValues>({
    subscription: { values: true }
  });

  const { core } = useStores();
  const typeValue = getIn(values, typeFieldName);
  const { communications } = values;

  const groupedComms = communications.reduce(
    (previous, current) => {
      const { sms, email } = previous;
      if (current.type === CommunicationType.Mobile) {
        const val: IDropdownOption = {
          ...current,
          text: current.value ?? "",
          key: sms.length
        };
        return { email, sms: [...sms, val] };
      } else if (current.type === CommunicationType.Email) {
        const val: IDropdownOption = {
          ...current,
          text: current.value ?? "",
          key: email.length
        };
        return { email: [...email, val], sms };
      } else return previous;
    },
    { sms: [], email: [] }
  );

  const typeSelected = (values?: PatientEditFormValues) => {
    if (values) {
      if (onTypeSelected) {
        onTypeSelected(values);
      } else {
        const type = getIn(values, typeFieldName);
        if (type) {
          const comms = values.communications.filter(
            x => x.type === OutboundCommChannelMapping[type]
          );
          if (comms.length === 1) {
            change(valueFieldName, 0);
          } else {
            change(valueFieldName, undefined);
          }
        }
      }
    }
  };

  const options =
    typeValue === OutboundCommChannel.Sms
      ? groupedComms?.sms
      : groupedComms?.email;

  const selectedTypeIsDisabled = !!options.find(o => o.key === typeValue)
    ?.disabled;

  const showValue =
    !selectedTypeIsDisabled && !!typeValue && typeValue !== OPTOUT;

  if (permission && !core.hasPermissions(permission)) return null;
  return (
    <Fieldset
      styles={{ root: { flexgrow: 2 } }}
      tokens={{ childrenGap: 8 }}
      horizontal
    >
      <DropdownField
        label={label}
        {...dataAttribute(DataAttributes.Element, `${automationName}-type`)}
        placeholder="Select"
        styles={{ root: { width: 250 } }}
        name={typeFieldName}
        options={typeOptions}
        validateOnInitialize
      />

      {showValue && (
        <DropdownField
          placeholder="Select"
          styles={{
            root: { marginTop: 29, width: 250 }
          }}
          {...dataAttribute(DataAttributes.Element, `${automationName}-value`)}
          name={valueFieldName}
          options={options}
          validateOnInitialize
        />
      )}
      <FieldSpy<String, PatientEditFormValues>
        name={typeFieldName}
        onChange={(_, values) => typeSelected(values)}
      />
      <FieldSpy<String, PatientEditFormValues>
        name={valueFieldName}
        onChange={(_, values) => onValueSelected && onValueSelected(values)}
      />
    </Fieldset>
  );
};
