import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { LabeledText, LabeledTextGroup, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { LastUpdatedText } from "@ui-components/last-updated/LastUpdatedText.tsx";
import {
  ContactStatusText,
  RelationshipStatusText
} from "@ui-components/RefText.tsx";

import { RelationshipStatus } from "./constants.ts";

interface ProfileCardProps {
  contact: Contact;
}

export const PatientProfileCard: FunctionComponent<ProfileCardProps> = observer(
  ({ contact: { id, relationshipStatus, dateOfDeath, status, changeLog } }) => {
    const {
      userExperience: { localisedConfig }
    } = useStores();

    const patientLabel = localisedConfig("patientDisplay", {
      capitalizeFirst: true
    });
    return (
      <Stack
        tokens={{ childrenGap: 16 }}
        styles={{ root: { padding: "0 10px" } }}
      >
        <LabeledTextGroup>
          <LabeledText noSemicolon label="Status">
            <ContactStatusText code={status} />
          </LabeledText>
          <LabeledText noSemicolon label="Date of death">
            {dateOfDeath ? dateOfDeath.toDayDefaultFormat() : "Not applicable"}
          </LabeledText>
          <LabeledText noSemicolon label="Relationship status">
            {relationshipStatus === RelationshipStatus.Unknown ||
            relationshipStatus === undefined ? (
              "No status recorded"
            ) : (
              <RelationshipStatusText code={relationshipStatus} />
            )}
          </LabeledText>
          <LabeledText noSemicolon label={`${patientLabel} ID`}>
            {id}
          </LabeledText>
        </LabeledTextGroup>
        {changeLog && <LastUpdatedText changeLog={changeLog} />}
      </Stack>
    );
  }
);
