import {
  ClaimDiagnosisChangeDto,
  ClaimDiagnosisDto,
  ClaimSideChangeDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export type ClaimAdjustmentResponseModalFormValues = {
  responseDate?: Date;
  isDeclined?: boolean;
  responseNotes?: string;
  approvedVisits?: number;
  diagnosisKeys?: string[];
  sideChangesKeys?: string[];
  purchaseOrderNumber?: string;
  diagnosisChangesKeys?: string[];
  sideChanges?: ClaimSideChangeDto[];
  handSplintingCostApproved?: number;
  initialAssessmentsApproved: boolean;
  diagnosisAdditions?: ClaimDiagnosisDto[];
  diagnosisChanges?: ClaimDiagnosisChangeDto[];
  abscessHematomaTreatmentsNeededApproved?: number;
  nailSimpleRemovalTreatmentsNeededApproved?: number;
  nailRemovalResectionTreatmentsNeededApproved?: number;
};

export const claimAdjustmentResponseFormNameOf =
  nameOfFactory<ClaimAdjustmentResponseModalFormValues>();
