import { ReactNode } from "react";

import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ClinicalClaimDataFetcherProps {
  children: (claim: Claim) => ReactNode;
  fetchPatient?: boolean;
  fetchProvider?: boolean;
  claimId: string;
}

export const ClinicalClaimDataFetcher: React.FC<
  ClinicalClaimDataFetcherProps
> = ({ children, fetchPatient, fetchProvider, claimId }) => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const loadClaimData = async ({ acc }: RootStore): Promise<Claim> => {
    const claim = await acc.getClaim(claimId);

    const promises = [];

    if (fetchPatient && claim.patientId) {
      promises.push(claim.loadPatient());
    }

    if (fetchProvider && claim.providerId) {
      promises.push(claim.loadProvider());
      promises.push(claim.loadUser());
    }

    await Promise.all(promises);

    return claim;
  };

  const reFetchId = clinicalRecord.episodeOfCare
    ? clinicalRecord.episodeOfCare?.eTag
    : undefined;

  return (
    <DataFetcher fetch={loadClaimData} refetchId={reFetchId}>
      {(claim: Claim) => children(claim)}
    </DataFetcher>
  );
};
