import { decode } from "base64-arraybuffer";
import { action, observable } from "mobx";

import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { DocumentStore } from "@stores/documents/DocumentStore.ts";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import { Document } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";
import { AutofillContext } from "@ui-components/text-editors/editor-autofills/editor-autofill.types.ts";

import { SyncFusionAutillFilterState } from "./SyncFusionAutofillFilter.tsx";

export enum AutofillPivotKey {
  All = "All",
  Shared = "Shared",
  Private = "Private"
}

export class SyncFusionAutofillModel {
  constructor(
    private document: DocumentStore,
    data: {
      docEditor: DocumentEditor | undefined;
      autofillContext: AutofillContext;
      autofills: AutofillDto[];
      isTemplate: boolean;
    }
  ) {
    this.setAutofills(data.autofills);
    this.autofillContext = data.autofillContext;
    this.documentEditor = data.docEditor;
    this.isTemplateWorkflow = data.isTemplate;
  }

  autofillContext: AutofillContext;

  documentEditor: DocumentEditor | undefined;

  isTemplateWorkflow: boolean;

  @observable
  autofills: AutofillDto[];

  @observable
  selectedPivotKey: string = AutofillPivotKey.All;

  @observable
  previewDocument: Document | undefined;

  @observable
  documentViewerVisible: boolean = false;

  @action
  setDocumentViewerVisible = (value: boolean) => {
    this.documentViewerVisible = value;
  };

  @action
  setAutofills(autofills: AutofillDto[]) {
    this.autofills = autofills;
  }

  @action
  setSelectedKey(selectedKey: string) {
    this.selectedPivotKey = selectedKey;
  }

  @action
  setPreviewDocument(value: Document | undefined) {
    this.previewDocument = value;
  }

  handlePreviewClick = async (autofill: AutofillDto) => {
    const renderedContent = await this.document.renderTemplate(autofill.id, {
      isPreview: this.isTemplateWorkflow,
      letterheadId: undefined,
      contentType: DocumentContentType.Pdf,
      context: this.autofillContext
    });
    this.setPreviewDocument({
      entityId: "",
      documentId: autofill.id,
      entityType: DocumentEntityType.DocumentProcessing,
      name: renderedContent.name,
      extension: DocumentContentType.Pdf,
      previewUri: new Uint8Array(decode(renderedContent.content))
    });
    this.setDocumentViewerVisible(true);
  };

  handleAutofillShortcutOnClick = async (
    autofill: AutofillDto,
    context: AutofillContext
  ) => {
    const text = await this.document.renderTemplate(autofill.id, {
      context
    });

    this.documentEditor?.editor.paste(text.content);
  };

  onSearch = (values?: SyncFusionAutillFilterState) => {
    let filteredItems = Array.from(this.document.autofills);

    if (values && values.search && values.search.length > 0) {
      filteredItems = filteredItems.filter(x =>
        x.name.toLowerCase().includes(values.search!.toLowerCase())
      );
    }
    this.setAutofills(filteredItems);
  };
}
