import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface ClinicalFlagsFormValues {
  redFlags?: string[];
  redFlagsAllClear?: boolean;
  yellowFlags?: string[];
  yellowFlagsAllClear?: boolean;
}

export const flagsNameOf = nameOfFactory<ClinicalFlagsFormValues>();
