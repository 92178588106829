import React from "react";

import { Stack } from "@bps/fluent-ui";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { gridRowStyles } from "./InvoiceSettingsForm.styles.ts";
import { SettingsFormPlaceholders } from "./InvoiceSettingsForm.types.tsx";

export const InvoiceSettingsFormNzBankAccountField: React.FC = () => (
  <Stack tokens={{ childrenGap: 8 }}>
    <TextInputField
      label="Account name"
      name="bankDetails.accountName"
      placeholder={SettingsFormPlaceholders.accountName}
    />
    <Stack horizontal styles={gridRowStyles}>
      <TextInputField
        label="Bank"
        maxLength={2}
        name="bankDetails.accountNumber"
      />

      <TextInputField
        label="Branch"
        maxLength={4}
        name="bankDetails.accountNumber2"
        styles={{ root: { gridColumn: "span 2" } }}
      />

      <TextInputField
        label="Account number"
        maxLength={7}
        name="bankDetails.accountNumber3"
        styles={{ root: { gridColumn: "span 3" } }}
      />

      <TextInputField
        label="Suffix"
        maxLength={3}
        name="bankDetails.accountNumber4"
      />
    </Stack>
  </Stack>
);
