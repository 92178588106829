import { observer } from "mobx-react-lite";

import { AddBox, FontSizes, FontWeights, Stack, Text } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { ScheduleFields } from "./ScheduleFields.types.ts";
import { useHandleAdd } from "./userWorkingHoursFormHooks.ts";

export const UserWorkingHoursEmptyMessage = observer(() => {
  const { core } = useStores();
  const handleAdd = useHandleAdd();
  const { fields } = useFieldArray<ScheduleFields>("userWorkingHours");

  if (fields.value.length) {
    return null;
  }

  return core.hasUserSettingWritePermission ? (
    <AddBox
      text="No current working hours"
      subText="Use the button below to begin adding records to this area"
      buttonText="Add working hours"
      onClick={handleAdd}
      styles={{ root: { padding: "32px 64px" } }}
    />
  ) : (
    <Stack horizontalAlign="center" styles={{ root: { padding: "32px 64px" } }}>
      <Text
        styles={{
          root: {
            fontWeigh: FontWeights.semibold,
            fontSize: FontSizes.size18,
            paddingBottom: 8
          }
        }}
      >
        No current working hours
      </Text>
    </Stack>
  );
});
