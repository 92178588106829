import { BusinessRole } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AnalysisAndPlanCard } from "../../SOTAP/AnalysisAndPlanCard.tsx";
import { MeridiansCard } from "../../SOTAP/MeridiansCard.tsx";
import { SOTAPFormWrapper } from "../../SOTAP/SOTAPFormWrapper.tsx";
import { TreatmentAndEducationCard } from "../../SOTAP/TreatmentAndEducationCard.tsx";

export const TreatmentFormContent: React.FunctionComponent = () => {
  const { core } = useStores();

  const isAcupuncturist = core.user?.businessRoles.some(
    x => x === BusinessRole.Acupuncturist
  );
  return (
    <SOTAPFormWrapper>
      <TreatmentAndEducationCard
        setIsFilledFromTreatments={() => undefined}
        hideFollowOnFields={true}
      />

      {isAcupuncturist && <MeridiansCard isPrimaryMeridians />}
      {isAcupuncturist && <MeridiansCard />}
      <AnalysisAndPlanCard />
    </SOTAPFormWrapper>
  );
};
