import { FormApi } from "final-form";
import { toJS } from "mobx";
import { FunctionComponent } from "react";

import { CenteredLargeSpinner, TopBarWrapper, useTheme } from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { StashedClinicalDataFormSpy } from "../../StashedClinicalDataFormSpy.tsx";
import {
  SOTAPFormContext,
  useSOTAPFormContext
} from "./context/SOTAPFormContext.tsx";
import { SOTAPFormModel } from "./context/SOTAPFormModel.ts";
import { SOTAPFormHeader } from "./SOTAPFormHeader.tsx";
import { SOTAPObjectiveContent } from "./SOTAPObjectiveContent.tsx";
import { SOTAPSubjectiveContent } from "./SOTAPSubjectiveContent.tsx";
import { SOTAPTapContent } from "./SOTAPTapContent.tsx";
import { AnalysisAndPlanCardValidator } from "./validators/AnalysisAndPlanCardValidator.tsx";
import { CurrentInjuryCardValidator } from "./validators/CurrentInjuryCardValidator.tsx";
import { DiagnosisCardValidator } from "./validators/DiagnosisCardValidator.tsx";
import { FamilyAndSocialCardValidator } from "./validators/FamilyAndSocialCardValidator.tsx";
import { GoalCardValidator } from "./validators/GoalCardValidator.tsx";
import { InjuryAreaCardValidator } from "./validators/InjuryAreasCardValidator.tsx";
import { OtherMovementsCardValidator } from "./validators/OtherMovementsCardValidator.tsx";
import { OutcomeMeasuresCardValidator } from "./validators/OutcomeMeasuresCardValidator.tsx";
import { PhysioMedicalHistoryCardValidator } from "./validators/PhysioMedicalHistoryCardValidator.tsx";
import { PostureOrObservationsCardValidator } from "./validators/PostureOrObservationsCardValidator.tsx";
import { TreatmentAndEducationCardValidator } from "./validators/TreatmentAndEducationCardValidator.tsx";
import { withFetchSOTAPRefData } from "./withFetchSOTAPRefData.tsx";

const familyAndSocialCardValidator = new FamilyAndSocialCardValidator();
const postureOrObservationsCardValidator =
  new PostureOrObservationsCardValidator();

const otherMovementsCardValidator = new OtherMovementsCardValidator();
const currentInjuryCardValidator = new CurrentInjuryCardValidator();
const physioMedicalHistoryCardValidator =
  new PhysioMedicalHistoryCardValidator();

const outcomeMeasuresCardValidator = new OutcomeMeasuresCardValidator();
const analysisAndPlanCardValidator = new AnalysisAndPlanCardValidator();
const injuryAreasCardValidator = new InjuryAreaCardValidator();
const treatmentAndEducationValidator = new TreatmentAndEducationCardValidator();
const goalCardValidator = new GoalCardValidator();
const diagnosisCardValidator = new DiagnosisCardValidator();

interface ISOTAPFormProps {
  defaultTools?: ClinicalDataType[] | undefined;
}

const SOTAPFormBase: React.FC = () => {
  const theme = useTheme();
  const model = useSOTAPFormContext();
  const { notification } = useStores();

  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const formInitialValues = model.initialSOTAPFormValues;

  const mergedValues: SOTAPFormValues = {
    ...formInitialValues,
    filledClinicalDataType: model.filledClinicalDataType
  };

  const initialValues = toJS(mergedValues, {
    recurseEverything: true
  });

  const onSubmit = async (
    values: SOTAPFormValues,
    form: FormApi<SOTAPFormValues>
  ) => {
    await model.submitData(values);
    restartForm(values, form);
    notification.success("SOTAP MSK has been saved successfully.");
  };

  const restartForm = (
    values: SOTAPFormValues,
    form: FormApi<SOTAPFormValues>
  ) => {
    form.restart(values);
  };

  const dischargeInProgress = clinicalRecord.dischargeInProgress();

  const dischargeInProgressOrCompleted =
    dischargeInProgress ||
    clinicalRecord.getDischargeStatus(
      clinicalRecord.openEncounter?.businessRole
    ) === DischargeStatus.Completed;

  const warning =
    dischargeInProgress &&
    clinicalRecord.getDischargeStatus(
      clinicalRecord.openEncounter?.businessRole
    ) !== DischargeStatus.Completed
      ? "Unable to edit SOTAP once discharge has begun. Delete the discharge to enable editing."
      : undefined;

  return (
    <SubmissionForm<SOTAPFormValues>
      formName="SOTAP"
      disabled={dischargeInProgressOrCompleted}
      delayForSettingFieldsData={500}
      disableRoutePrompt
      styles={{
        root: {
          margin: -16
        }
      }}
      warning={warning}
      warningMessageBarProps={{
        styles: {
          text: { fontSize: theme.fonts.small.fontSize }
        }
      }}
      subscription={{}}
      hideButtons
      onSubmit={onSubmit}
      readOnly={isViewOnlyOrDischarged}
      initialValues={initialValues}
      validate={values => {
        return {
          ...familyAndSocialCardValidator.validate(values),
          ...physioMedicalHistoryCardValidator.validate(values),
          ...currentInjuryCardValidator.validate(values),
          ...outcomeMeasuresCardValidator.validate(values),
          ...postureOrObservationsCardValidator.validate(values),
          ...otherMovementsCardValidator.validate(values),
          ...analysisAndPlanCardValidator.validate(values),
          ...injuryAreasCardValidator.validate(values),
          ...treatmentAndEducationValidator.validate(values),
          ...goalCardValidator.validate(values),
          ...diagnosisCardValidator.validate(values)
        };
      }}
      render={() => {
        return (
          <TopBarWrapper header={<SOTAPFormHeader model={model} />}>
            <StashedClinicalDataFormSpy
              clinicalRecord={clinicalRecord}
              getData={model.getStashedEncounterClinicalData}
              areasToObserve={model.areasToObserve}
              duration={1000}
              syncWithAcc45
            />
            <SOTAPSubjectiveContent />
            <SOTAPObjectiveContent />
            <SOTAPTapContent />
          </TopBarWrapper>
        );
      }}
    />
  );
};

const SOTAPFormComponent: FunctionComponent<ISOTAPFormProps> = ({
  defaultTools
}) => {
  // Needs to be here to preload persisted values before SOTAPFormBase rendering.
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { clinical, acc } = useStores();

  const fetch = async (): Promise<SOTAPFormModel> => {
    const model = new SOTAPFormModel(clinical, clinicalRecord);

    await Promise.all([
      acc.ref.occupations.load(),
      model.loadPsfsQuestions(),
      model.loadCurrentEncounterClinicalToolsData(
        clinicalRecord.openEncounter?.businessRole,
        defaultTools
      )
    ]);

    return model;
  };

  return (
    <DataFetcher fetch={fetch} fallback={<CenteredLargeSpinner />}>
      {model => (
        <SOTAPFormContext.Provider value={model}>
          <SOTAPFormBase />
        </SOTAPFormContext.Provider>
      )}
    </DataFetcher>
  );
};
export const SOTAPForm = withFetchSOTAPRefData(SOTAPFormComponent);
