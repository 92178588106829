import { Contact } from "@stores/practice/models/Contact.ts";

export interface PatientMatchListItem {
  contact: Contact;
  name?: string;
  address?: string;
  birthDate?: string;
  email?: string;
  preferredPhone?: string;
  id: string;
}

export enum PatientMatchPivotKey {
  MatchedResult = "MATCHED_RESULT",
  SearchResult = "SEARCH_RESULT"
}

export interface PatientMatchFilter {
  search?: string;
  statuses?: string[];
  birthday?: string;
  phoneNumber?: string;
}
