import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useShowContactDetails } from "@modules/practice/screens/address-book/components/hooks/useShowContactDetails.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const ContactNameColumn: FC<{
  contact: Contact;
}> = observer(({ contact }) => {
  const showContactDetails = useShowContactDetails(contact);
  return (
    <Navigate
      onClick={showContactDetails}
      data-contact-id={contact.id}
      data-contact-type={contact.type}
    >
      {contact.reversedName}
    </Navigate>
  );
});
