import { Country } from "@libs/enums/country.enum.ts";
import {
  CommunicationDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  email,
  phone,
  predicate,
  required,
  url
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

export class CommunicationValidator extends Validator<CommunicationDto> {
  isRequired: boolean;
  constructor(country: Country, isRequired?: boolean) {
    super();
    this.isRequired = !!isRequired;

    this.forField("value", [
      predicate(
        (val, comm) =>
          comm?.type !== CommunicationType.Email &&
          comm?.type !== CommunicationType.Website &&
          !!comm?.value,
        phone
      ),
      (val, values) => {
        if (!!values.value && values?.type === CommunicationType.Mobile) {
          const truncatedVal = values.value.split(" ").join("");

          if (!truncatedVal.startsWith("+") && !truncatedVal.startsWith("0")) {
            return ValidationMessages.mustStartWithPlusOrZero;
          } else if (
            (country === Country.Australia && truncatedVal.length < 10) ||
            (country === Country.NewZealand && truncatedVal.length < 9)
          ) {
            return ValidationMessages.minimumLengthOfX.replace(
              "X",
              country === Country.Australia ? "10" : "9"
            );
          }
        }
        return undefined;
      },
      predicate(() => this.isRequired, required())
    ]);

    this.forField("value", [
      predicate(
        (val: string, comm: CommunicationDto) =>
          comm.type === CommunicationType.Email && !!comm.value,
        email(),
        predicate(() => this.isRequired, required())
      )
    ]);

    this.forField("value", [
      predicate(
        (val: string, comm: CommunicationDto) =>
          comm.type === CommunicationType.Website && !!comm.value,
        url(),
        predicate(() => this.isRequired, required())
      )
    ]);
  }
}
