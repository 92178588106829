import { observer } from "mobx-react-lite";
import React, { useContext, useMemo } from "react";
import { useField, useForm } from "react-final-form";

import {
  FontSizes,
  Heading,
  SavingIdentificator,
  Separator,
  Stack,
  StackItem,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DateTime, TIME_FORMATS } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import { EncounterType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ReasonForVisitContext } from "@modules/clinical/screens/context/ReasonForVisitContext.ts";
import { clinicalRecordMapKey } from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ConditionLinkedIndicator } from "./ConditionLinkedIndicator.tsx";
import {
  ConsultDetailDialog,
  ConsultDetailDialogValues
} from "./ConsultDetailDialog.tsx";
import {
  AutoFocusElement,
  encounterTypes
} from "./ConsultDetailDialogFields.tsx";
import { nameOf } from "./EncounterFormHeader.tsx";
import { EncounterFormSubmitButtons } from "./EncounterFormSubmitButtons.tsx";
import { EncounterFormContext } from "./models/EncounterFormContext.ts";
import { TodaysNotesConfidentialToolTipIcon } from "./TodaysNotesConfidentialToolTipIcon.tsx";
import { getReasonsText } from "./utils.ts";

export const EncounterFormResponsiveHeader: React.FC = observer(() => {
  const { clinical } = useStores();

  const {
    onSubmitReasonForVisitValue,
    openReasonForVisitDialog,
    closeReasonForVisitDialog
  } = useContext(ReasonForVisitContext);

  const {
    setInvoicedServicesDialogVisible,
    openConsultDetailDialog,
    encounter,
    reasonForVisits,
    getConsultDetailDialogInitialValues,
    clinicalRecord,
    invoiceItemsWithServices,
    invoiceItemsAreFinalised,
    isFinalising,
    isEnding
  } = useContext(EncounterFormContext);

  const patientRecordScreenHelper = usePatientRecordScreenContext();

  const form = useForm();
  const values = form.getState().values;
  const theme = useTheme();
  const {
    input: { value: isConfidential }
  } = useField(nameOf("confidential"));

  const { input: startDateInput } = useField(nameOf("startDate"));
  const { input: startTimeInput } = useField(nameOf("startTime"));

  const clinicalRecordKey = clinicalRecordMapKey(
    clinicalRecord.id,
    clinicalRecord.currentEncounterId
  );

  // ⚠️ Register encounter form fields. Needs to be here to track any form updates after setting values from ConsultDetailDialog. Ilya S.
  useField(nameOf("type"));
  useField(nameOf("reasonForVisit"));
  useField(nameOf("location"));
  useField(nameOf("location"));
  useField(nameOf("practiceLocationId"));

  const startDate = DateTime.fromJSDateOrNow(startDateInput.value);
  const isRecordUpdate = encounter?.type === EncounterType.RecordUpdate;

  const getNavigateLink = (
    linkText?: string,
    isReasonNavigation?: boolean,
    focusElement?: AutoFocusElement
  ) => (
    <Navigate
      styles={{
        root: {
          padding: "0 4px",
          color:
            isReasonNavigation && reasonForVisits.length === 0
              ? theme.semanticColors.errorText
              : undefined
        }
      }}
      onClick={() => {
        openConsultDetailDialog(focusElement);
      }}
    >
      {linkText}
    </Navigate>
  );

  const encounterTypeDetails = () => {
    if (isRecordUpdate) {
      return reasonForVisits.length > 0 ? (
        <Stack horizontal>
          Record update Reason(s)
          {getNavigateLink(
            getReasonsText(reasonForVisits),
            true,
            AutoFocusElement.Reason
          )}
        </Stack>
      ) : (
        getNavigateLink("Record update")
      );
    }
    return (
      <Stack horizontal wrap>
        <Stack horizontal>
          {values.location && (
            <>
              at
              {getNavigateLink(
                clinical.ref.encounterLocations.keyTextValues.find(
                  x => x.key === values.location
                )?.text,
                false,
                AutoFocusElement.Location
              )}
            </>
          )}
        </Stack>
        <Stack horizontal>
          Reason(s)
          {getNavigateLink(
            getReasonsText(reasonForVisits),
            true,
            AutoFocusElement.Reason
          )}
        </Stack>
      </Stack>
    );
  };

  const initialValues = useMemo(() => {
    return getConsultDetailDialogInitialValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getConsultDetailDialogInitialValues, values, reasonForVisits]);

  return (
    <>
      <Stack horizontal verticalAlign="start" horizontalAlign="space-between">
        <Stack horizontal verticalAlign="center" wrap>
          <Heading variant="section-heading" nowrap>
            Today's notes
          </Heading>
          <Stack horizontal verticalAlign="start" tokens={{ childrenGap: 4 }}>
            <WhenCountry is={Country.NewZealand}>
              <ConditionLinkedIndicator />
            </WhenCountry>
            <When permission={Permission.ConfidentialDataAllowed}>
              <TodaysNotesConfidentialToolTipIcon
                isConfidential={isConfidential}
                updateConfidential={() => {
                  form.change(nameOf("confidential"), !isConfidential);
                }}
                styles={{
                  root: {
                    backgroundColor: isConfidential
                      ? theme.palette.neutralLight
                      : "transparent",
                    fontSize: FontSizes.size12
                  }
                }}
              />
            </When>
            {invoiceItemsAreFinalised &&
              invoiceItemsWithServices.length > 0 && (
                <TextBadge
                  badgeSize={TextBadgeSize.medium}
                  badgeColor={TextBadgeColor.yellow}
                  horizontal
                  horizontalAlign="center"
                  onClick={() => setInvoicedServicesDialogVisible(true)}
                >
                  {`invoiced (${invoiceItemsWithServices.length})`}
                </TextBadge>
              )}
          </Stack>
          <Stack
            styles={{
              root: {
                color: theme.palette.neutralSecondary,
                fontsize: "12px",
                fontStyle: "italic",
                padding: "6px 12px 6px 0",
                width: 200
              }
            }}
          >
            <SavingIdentificator
              submitting={clinicalRecord?.saving}
              lastSaved={clinicalRecord?.lastSaved}
            />
          </Stack>
        </Stack>
        <EncounterFormSubmitButtons />
      </Stack>

      <Stack horizontal wrap verticalAlign="center">
        <Stack horizontal wrap>
          Date
          {getNavigateLink(
            startDate.toDayDefaultFormat(),
            false,
            AutoFocusElement.Date
          )}
          <StackItem>
            time
            {getNavigateLink(
              DateTime.fromParsedTimeString(startTimeInput.value)?.toFormat(
                TIME_FORMATS.DEFAULT_TIME_FORMAT
              ),
              false,
              AutoFocusElement.Time
            )}
          </StackItem>
          {!isRecordUpdate && (
            <StackItem>
              via
              {getNavigateLink(
                encounterTypes.find(x => x.key === values.type)?.linkText,
                false,
                AutoFocusElement.Via
              )}
            </StackItem>
          )}
        </Stack>

        {encounterTypeDetails()}
      </Stack>

      <Separator />
      <ConsultDetailDialog
        initialValues={initialValues}
        onSubmit={async (values: ConsultDetailDialogValues) => {
          openReasonForVisitDialog(clinicalRecordKey);
          if (values.reasonForVisit) {
            await onSubmitReasonForVisitValue(values);
            if (
              (isFinalising || isEnding) &&
              patientRecordScreenHelper.encounterFormApi
            ) {
              patientRecordScreenHelper.encounterFormApi?.submit();
            }
            //reset clinicalId
            closeReasonForVisitDialog();
          }
          form.batch(() => {
            form.change(nameOf("startDate"), values.startDate);
            form.change(nameOf("startTime"), values.startTime);
            form.change(nameOf("type"), values.type);
            form.change(nameOf("location"), values.location);
            form.change(nameOf("confidential"), values.confidential);
            form.change(nameOf("reasonForVisit"), values.reasonForVisit);
            form.change(nameOf("otherText"), values.otherText);
            form.change(
              nameOf("practiceLocationId"),
              values.practiceLocationId
            );
          });
        }}
      />
    </>
  );
});
