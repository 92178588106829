import { Country } from "@libs/enums/country.enum.ts";
import {
  ContactStatus,
  EmployerDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { RefCountry } from "@stores/core/models/Address.ts";

import { AddressValidator } from "../../../../shared-components/validation/AddressValidator.ts";
import { CommunicationValidator } from "../../../shared-components/edit/validators/CommunicationValidator.ts";
import {
  EditOrganisationFormValues,
  orgEditFormNameOf
} from "../EditOrganisationFormValues.tsx";
import { EmployerFieldsValidator } from "./EmployerFieldsValidator.ts";
import { OrganisationTypeValidator } from "./OrganisationTypeValidator.ts";
import { OrgRelationshipValidator } from "./OrgRelationshipValidator.ts";

export const CHANGE_NAME_PUBLIC_INSURER_ERROR =
  "Can't change name when type is public insurer New Zealand.";
export const CHANGE_STATUS_PUBLIC_INSURER_ERROR =
  "Can't change status when type is public insurer New Zealand.";
export class OrganisationFormValidator extends Validator<EditOrganisationFormValues> {
  constructor(
    countries: readonly RefCountry[],
    orgIsNzPublicInsurer: boolean,
    originalOrgName: string
  ) {
    super();

    const organisationTypeValidator = new OrganisationTypeValidator();

    this.forField("privateInsurer", organisationTypeValidator.validate);
    this.forField(
      "employer",
      (value: EmployerDto, values: EditOrganisationFormValues) => {
        const employerFieldsValidator = new EmployerFieldsValidator({
          usingPrivateInsurer: values?.usingPrivateInsurer!
        });
        return employerFieldsValidator.validate(value!);
      }
    );

    this.forField(orgEditFormNameOf("organisationName"), [
      required(),
      maxLength(40)
    ]);
    this.forField(
      orgEditFormNameOf("organisationName"),
      predicate(
        v => orgIsNzPublicInsurer && v !== originalOrgName,
        () => CHANGE_NAME_PUBLIC_INSURER_ERROR
      )
    );

    this.forField(
      orgEditFormNameOf("status"),
      predicate(
        v => orgIsNzPublicInsurer && v !== ContactStatus.Active,
        () => CHANGE_STATUS_PUBLIC_INSURER_ERROR
      )
    );

    this.forArrayField(orgEditFormNameOf("relationships"), value => {
      const relationshipValidator = new OrgRelationshipValidator();
      return relationshipValidator.validate(value);
    });

    this.forArrayField(orgEditFormNameOf("addresses"), value => {
      const addressValidator = new AddressValidator({
        countries,
        country: Country.Australia
      });
      return addressValidator.validate(value);
    });

    this.forArrayField("communications", value => {
      const commValidator = new CommunicationValidator(
        Country.Australia,
        false
      );
      return commValidator.validate(value);
    });
  }
}
