import {
  DominantHand,
  PhysicalActivityFormDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface PhysicalActivityFormValues {
  isEliteAthlete?: string;
  hoursSpentAtSedentary?: string;
  activities: PhysicalActivityFormDto[];
  dominantHand?: DominantHand;
}

export const physicalActivityFormNameOf =
  nameOfFactory<PhysicalActivityFormValues>();
