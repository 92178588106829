import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Card, DefaultButton } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { When } from "@ui-components/withPerm.tsx";

import { useIntegrationScreenContext } from "../context/IntegrationScreenContext.ts";
import { ApiKeyModal } from "./ApiKeyModal.tsx";
import { PhysitrackProviderList } from "./PhysitrackProviderList.tsx";

export const PhysitrackProviderCard: React.FC = observer(() => {
  const { showPhysitrackProviderList } = useIntegrationScreenContext();
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);

  if (!showPhysitrackProviderList) {
    return null;
  }
  return (
    <>
      <Card
        heading="Activated Providers"
        iconName="Group"
        button={
          <When permission={[Permission.ManageIntegrationsAllowed]}>
            <DefaultButton
              iconProps={{ iconName: "Add" }}
              text="Add provider"
              onClick={() => setShowApiKeyModal(true)}
            />
          </When>
        }
      >
        <PhysitrackProviderList />
      </Card>
      {showApiKeyModal && (
        <ApiKeyModal
          onDismiss={() => {
            setShowApiKeyModal(false);
          }}
        />
      )}
    </>
  );
});
