import { observer } from "mobx-react-lite";
import { FormSpy, useField } from "react-final-form";

import { CheckboxGroupOption, Stack } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { BoolButtonGroupField } from "@ui-components/form/BoolButtonGroupField.tsx";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";

import { claimAdjustmentModalFormNameOf } from "../claim-adjustment.utils.ts";
import { ClaimAdjustmentModalValues } from "../types/claim-adjustment-modal-values.interface.ts";
import { ClaimAdjustmentModalLabels } from "./ClaimAdjustmentEnums.ts";
import { VerballyApprovedFields } from "./VerballyApprovedFields.tsx";

export const ClaimAdjustmentModalFields: React.FC = observer(() => {
  const context = useClaimAdjustmentContext();

  const {
    input: { value: verballyApproved }
  } = useField(claimAdjustmentModalFormNameOf("verballyApproved"), {
    subscription: { value: true }
  });

  const getAvailableOptions = (): CheckboxGroupOption<string>[] => {
    const options = [
      {
        label: ClaimAdjustmentModalLabels.hasTreatmentDetails,
        value: "hasTreatmentDetails"
      }
    ];
    if (context.userHasDiagnosePermission && !verballyApproved) {
      options.push({
        label: ClaimAdjustmentModalLabels.addADiagnosis,
        value: "hasDiagnosisAddition"
      });
      options.push({
        label: ClaimAdjustmentModalLabels.changeSide,
        value: "hasSideChange"
      });
      options.push({
        label: ClaimAdjustmentModalLabels.hasDiagnosisChange,
        value: "hasDiagnosisChange"
      });
    }

    return options;
  };

  return (
    <Stack styles={{ root: { marginTop: 8 } }} tokens={{ childrenGap: 8 }}>
      <FormSpy<ClaimAdjustmentModalValues>
        subscription={{ dirty: true, values: true }}
        onChange={({ values }) => {
          context.claimAdjustmentModalFormValues = values;
        }}
      />
      <VerballyApprovedFields />
      <CheckboxGroupField
        disabled={verballyApproved}
        label={ClaimAdjustmentModalLabels.iNeedTo}
        name={claimAdjustmentModalFormNameOf("claimAdjustmentReasons")}
        required
        options={getAvailableOptions()}
      />
      {!verballyApproved && (
        <BoolButtonGroupField
          label={ClaimAdjustmentModalLabels.isVoluntarySubmission}
          name={claimAdjustmentModalFormNameOf("isVoluntarySubmission")}
          options={[
            { key: false, text: "No" },
            { key: true, text: "Yes" }
          ]}
        />
      )}
    </Stack>
  );
});
