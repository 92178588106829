import { ISeparatorProps } from "@bps/fluent-ui";

export const calculateBMI = (weight: number, height: number) => {
  const heightFormat = height / 100;
  const heightSquared = Math.pow(heightFormat, 2);
  return weight / heightSquared;
};

export const getGeneralExamAccordionItemStyles = () => {
  return {
    root: { borderLeft: 0, borderRight: 0, padding: 0 },
    contentWrapper: { paddingTop: 0, paddingBottom: 0, paddingLeft: 24 },
    title: { fontSize: 18 },
    heading: { borderBottom: 0 },
    content: { paddingBottom: 0 }
  };
};

export const getFieldSetStyles = () => {
  return {
    root: { borderLeft: 0, borderRight: 0, borderBottom: 0, padding: 0 }
  };
};

export const getSeparatorProps = (): ISeparatorProps => {
  return {
    styles: { root: { paddingBottom: 8, height: 0 } }
  };
};

export const getSpinButtonStyles = () => {
  return { root: { width: 100 } };
};

export const getButtonGroupBreakLineStyles = (isBreak: boolean) => {
  return {
    label: isBreak
      ? {
          padding: "10px 50px 10px 50px"
        }
      : undefined
  };
};
