import { FormApi } from "final-form";

import { DateTime } from "@bps/utils";
import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  GynaeFieldDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { StashedClinicalDataFormSpy } from "@modules/clinical/screens/patient-record/StashedClinicalDataFormSpy.tsx";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClinicalSubmissionForm } from "../../clinical-form/ClinicalSubmissionForm.tsx";
import { SystemsReviewContent } from "./SystemsReviewContent.tsx";
import {
  GynaeFieldValues,
  SystemsReviewFormValues
} from "./SystemsReviewForm.types.ts";
import { SystemsReviewFormValidator } from "./validator/SystemsReviewFormValidator.tsx";

interface SystemsReviewFormProps {
  clinicalRecord: ClinicalRecord;
}

const SystemsReviewFormBase: React.FunctionComponent<
  SystemsReviewFormProps
> = ({ clinicalRecord }) => {
  const { clinical, notification } = useStores();
  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const validator = new SystemsReviewFormValidator();

  const systemsReviewData = clinicalRecord.stashedClinicalData?.systemsReview;

  const initialValues = () => {
    const maleFlags = clinical.ref.clinicalFlags.values
      .filter(
        x => x.isUrinary && x.clinicalFlagText.localeCompare("MALE") === 0
      )
      .map(x => x.code);

    const urinary = systemsReviewData?.urinaryFlags?.filter(
      x => !maleFlags.includes(x)
    );

    const maleUrinary = systemsReviewData?.urinaryFlags?.filter(x =>
      maleFlags.includes(x)
    );

    const gynaeFields: GynaeFieldValues = {
      ...systemsReviewData?.gynae,
      lastMenstrualPeriodDate: systemsReviewData?.gynae?.lastMenstrualPeriodDate
        ? DateTime.jsDateFromISO(
            systemsReviewData?.gynae?.lastMenstrualPeriodDate
          )
        : undefined,
      usesContraception:
        systemsReviewData?.gynae?.contraceptionType !== undefined,
      hasMenopausalProblems:
        systemsReviewData?.gynae?.sleepDisturbances ||
        systemsReviewData?.gynae?.hotFlushes ||
        systemsReviewData?.gynae?.moodDisturbances ||
        systemsReviewData?.gynae?.tiredness
    };

    const values: SystemsReviewFormValues = {
      systemicFlags: systemsReviewData?.systemicFlags ?? [],
      systemicFlagsAllClear: systemsReviewData?.systemicFlagsAllClear,
      neuroFlags: systemsReviewData?.neuroFlags ?? [],
      neuroFlagsAllClear: systemsReviewData?.neuroFlagsAllClear,
      eNTFlags: systemsReviewData?.eNTFlags ?? [],
      eNTFlagsAllClear: systemsReviewData?.eNTFlagsAllClear,
      cardioFlags: systemsReviewData?.cardioFlags ?? [],
      cardioFlagsAllClear: systemsReviewData?.cardioFlagsAllClear,
      gastroFlags: systemsReviewData?.gastroFlags ?? [],
      gastroFlagsAllClear: systemsReviewData?.gastroFlagsAllClear,
      urinaryFlags: urinary ?? [],
      maleUrinaryFlags: maleUrinary ?? [],
      urinaryFlagsAllClear: systemsReviewData?.urinaryFlagsAllClear,

      musculoFlags: systemsReviewData?.musculoFlags ?? [],
      musculoFlagsAllClear: systemsReviewData?.musculoFlagsAllClear,
      haematoFlags: systemsReviewData?.haematoFlags ?? [],
      haematoFlagsAllClear: systemsReviewData?.haematoFlagsAllClear,
      gynae: gynaeFields
    };

    return values;
  };

  const convertFormValuesToData = (values: SystemsReviewFormValues) => {
    const eTag = clinicalRecord.clinicalData?.systemsReview?.eTag;

    const confirmedETag =
      clinicalRecord.clinicalData?.systemsReviewConfirmed?.eTag;

    const clinicalData: EncounterClinicalDataDto = {};

    const urinaryFlags = values.urinaryFlags?.concat(
      values.maleUrinaryFlags ?? []
    );

    let cleanedGyaneFields: GynaeFieldDataDto = {};

    if (values.gynae) {
      // Remove the non-clinical data records.
      const { hasMenopausalProblems, usesContraception, ...rest } =
        values.gynae;

      cleanedGyaneFields = {
        ...rest,
        sleepDisturbances: values.gynae.hasMenopausalProblems
          ? values.gynae.sleepDisturbances
          : undefined,
        hotFlushes: values.gynae.hasMenopausalProblems
          ? values.gynae.hotFlushes
          : undefined,
        moodDisturbances: values.gynae.hasMenopausalProblems
          ? values.gynae.moodDisturbances
          : undefined,
        tiredness: values.gynae.hasMenopausalProblems
          ? values.gynae.tiredness
          : undefined,
        contraceptionType: values.gynae.usesContraception
          ? values.gynae.contraceptionType
          : undefined,
        hRT: values.gynae.hRT ? values.gynae.hRT : undefined,
        couldBePregnant: values.gynae.couldBePregnant
          ? values.gynae.couldBePregnant
          : undefined,
        lastMenstrualPeriodDate: DateTime.jsDateToISODate(
          values.gynae.lastMenstrualPeriodDate
        )
      };
    }

    clinicalData.systemsReview = {
      eTag,
      systemicFlags: values.systemicFlags,
      systemicFlagsAllClear: values.systemicFlagsAllClear,
      neuroFlags: values.neuroFlags,
      neuroFlagsAllClear: values.neuroFlagsAllClear,
      eNTFlags: values.eNTFlags,
      eNTFlagsAllClear: values.eNTFlagsAllClear,
      cardioFlags: values.cardioFlags,
      cardioFlagsAllClear: values.cardioFlagsAllClear,
      gastroFlags: values.gastroFlags,
      gastroFlagsAllClear: values.gastroFlagsAllClear,
      urinaryFlags,
      urinaryFlagsAllClear: values.urinaryFlagsAllClear,
      musculoFlags: values.musculoFlags,
      musculoFlagsAllClear: values.musculoFlagsAllClear,
      haematoFlags: values.haematoFlags,
      haematoFlagsAllClear: values.haematoFlagsAllClear,
      gynae: cleanedGyaneFields
    };

    clinicalData.systemsReviewConfirmed = {
      eTag: confirmedETag,
      confirmed: true
    };

    return clinicalData;
  };

  const submitData = async (values: SystemsReviewFormValues) => {
    const clinicalData: EncounterClinicalDataDto = convertFormValuesToData({
      ...values
    });

    await clinicalRecord.saveClinicalData(clinicalData);
  };

  const onDismiss = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.SystemsReview
    );
    clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "systemsReview",
      "systemsReviewConfirmed"
    ]);
  };

  const onSubmitSucceeded = (
    values: SystemsReviewFormValues,
    form: FormApi<SystemsReviewFormValues>,
    isSaveAndClose: boolean
  ) => {
    if (!isSaveAndClose) {
      clinical.ui.tabs.currentPatientRecordTab?.setIsDirty(false, {
        type: ClinicalDataType.SystemsReview
      });
      form.restart(values);
      notification.success("Saved successfully");
    } else {
      onDismiss();
    }
  };

  return (
    <ClinicalSubmissionForm<SystemsReviewFormValues>
      formName="systemsReview"
      onSubmit={submitData}
      onSubmitSucceeded={onSubmitSucceeded}
      hideButtons
      initialValues={initialValues()}
      readOnly={isViewOnlyOrDischarged}
      disableRoutePrompt
      validate={validator.validate}
      heading="Systems review"
      noGap
      onCancel={onDismiss}
      extraPromptConditionOnCancel={() =>
        !!clinicalRecord.stashedClinicalData?.dirtySystemsReviewForm
      }
      disableButtonCondition={stashedData =>
        !stashedData?.dirtySystemsReviewForm
      }
      lastUpdatedDate={getClinicalDataLastUpdatedDate(
        clinicalRecord.clinicalData?.systemsReviewConfirmed
      )}
      lastUpdatedUserId={getClinicalDataLastUpdatedUserId(
        clinicalRecord.clinicalData?.systemsReviewConfirmed
      )}
    >
      <>
        <StashedClinicalDataFormSpy<SystemsReviewFormValues>
          clinicalRecord={clinicalRecord}
          getData={convertFormValuesToData}
          areasToObserve={{
            systemsReview: [ClinicalDataType.SystemsReview]
          }}
        />
        <SystemsReviewContent />
      </>
    </ClinicalSubmissionForm>
  );
};

export const SystemsReviewForm = withFetch(
  x => [x.clinical.ref.clinicalFlags.load()],
  SystemsReviewFormBase
);
