import { FC } from "react";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { AnimatedListWithKeys, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { AddressType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";

import { AddressFields } from "../../../shared-components/AddressFields.tsx";
import { defaultAddress } from "./utils.ts";

export interface ContactMethodAddressProps {
  disableRemoveLink?: boolean;
  required?: boolean;
  country: Country;
  name: string;
  defaultAddressType?: AddressType;
  onRenderFieldsetActions?: (name: string, index: number) => JSX.Element;
}

export const ContactMethodAddress: FC<ContactMethodAddressProps> = ({
  name,
  country,
  required,
  disableRemoveLink,
  defaultAddressType,
  onRenderFieldsetActions
}) => {
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <FieldArray name={name}>
        {(
          fieldProps: FieldArrayRenderProps<AddressFieldValue, HTMLElement>
        ) => (
          <AnimatedListWithKeys
            items={fieldProps.fields.value}
            onItemIdRemoved={id => {
              const index = fieldProps.fields.value.findIndex(v => v.id === id);
              if (fieldProps.fields.value.length === 1) {
                fieldProps.fields.update(
                  index,
                  defaultAddress(
                    defaultAddressType ?? AddressType.Physical,
                    fieldProps.fields.value[index]?.id,
                    country
                  )
                );
              } else {
                fieldProps.fields.remove(index);
              }
            }}
          >
            {(id, onRemoveItem) => {
              const index = fieldProps.fields.value.findIndex(v => v.id === id);
              const value = fieldProps.fields.value[index];
              if (value && index >= 0) {
                return (
                  <AddressFields
                    frame
                    required={required}
                    disableRemoveLink={disableRemoveLink}
                    name={`${name}[${index}]`}
                    value={value}
                    onRemove={onRemoveItem}
                    onChangeCountry={() => {
                      if (value.country === Country.Australia) {
                        fieldProps.fields.update(index, {
                          ...value,
                          city: undefined
                        });
                      } else {
                        fieldProps.fields.update(index, {
                          ...value,
                          state: undefined
                        });
                      }
                    }}
                    onRenderFieldsetActions={
                      onRenderFieldsetActions
                        ? () => onRenderFieldsetActions(name, index)
                        : undefined
                    }
                  />
                );
              }
              return null;
            }}
          </AnimatedListWithKeys>
        )}
      </FieldArray>
    </Stack>
  );
};
