import { observable, runInAction } from "mobx";

import {
  flatten,
  IDropdownOption,
  SelectableOptionMenuItemType
} from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import {
  ClinicalDataType,
  EncounterClinicalDataDto,
  EncounterType,
  MeasurementType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { getSortedEncounters } from "@modules/clinical/screens/patient-record/components/patient-summary/PreEncounterNotesContent.tsx";
import { AccStore } from "@stores/acc/AccStore.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { VisitData } from "../../utils.ts";

export class DocumentWriterMergeFormHelper {
  // eslint-disable-next-line max-params
  constructor(
    private clinical: ClinicalStore,
    private acc: AccStore,
    private core: CoreStore,
    private userExperience: UserExperienceStore
  ) {}

  @observable
  private _visitOptions: VisitData[] = [];

  @observable
  private _clinicalToolOptions: IDropdownOption[] = [];

  private _userSettingVisits: string[];

  claimRelatedEncounters: Encounter[] = [];

  get visitOptions() {
    return this._visitOptions;
  }

  set visitOptions(value: VisitData[]) {
    runInAction(() => {
      this._visitOptions = value;
    });
  }

  get clinicalToolOptions() {
    return this._clinicalToolOptions;
  }

  set clinicalToolOptions(value: IDropdownOption[]) {
    runInAction(() => {
      this._clinicalToolOptions = value;
    });
  }

  get userSettingsVisits() {
    return this._userSettingVisits;
  }

  set userSettingsVisits(value: string[]) {
    this._userSettingVisits = value;
  }

  getClinicalToolOptions = (clinicalTools: string[], customTools: string[]) => {
    let clinicalToolOptions: IDropdownOption[] = [];

    clinicalToolOptions = clinicalTools.map(tool => {
      return {
        key: tool,
        text: tool
      };
    });

    if (customTools.length > 0) {
      clinicalToolOptions.push({
        key: "divider",
        text: "",
        itemType: SelectableOptionMenuItemType.Divider
      });
      clinicalToolOptions.push({
        key: "header2",
        text: "Custom Tools",
        itemType: SelectableOptionMenuItemType.Header
      });
      customTools.forEach(tool => {
        clinicalToolOptions.push({ key: tool, text: tool });
      });
    }

    return clinicalToolOptions;
  };

  getClinicalToolTypes = (
    encounterClinicalData: EncounterClinicalDataDto,
    currentClinicalToolTypes: string[]
  ) => {
    if (encounterClinicalData.dash) {
      if (!currentClinicalToolTypes.includes(MeasurementType.DASH))
        currentClinicalToolTypes.push(MeasurementType.DASH);
    }

    if (encounterClinicalData.grcs) {
      if (!currentClinicalToolTypes.includes(MeasurementType.GRCS))
        currentClinicalToolTypes.push(MeasurementType.GRCS);
    }

    if (encounterClinicalData.orebro) {
      if (!currentClinicalToolTypes.includes(MeasurementType.OREBRO))
        currentClinicalToolTypes.push(MeasurementType.OREBRO);
    }

    return currentClinicalToolTypes;
  };

  getCustomClinicalToolTypes = (
    encounterClinicalData: EncounterClinicalDataDto,
    currentCustomTools: string[]
  ) => {
    if (encounterClinicalData.customClinicalTool) {
      encounterClinicalData.customClinicalTool.tools.forEach(tool => {
        if (encounterClinicalData.customClinicalToolContext) {
          const toolName =
            encounterClinicalData.customClinicalToolContext!.contexts.find(
              x => x.contextId === tool.contextId
            )?.name;
          if (toolName && !currentCustomTools.includes(toolName))
            currentCustomTools.push(toolName);
        }
      });
    }
    return currentCustomTools;
  };

  getEncounterClinicalData = async (encounterId: string) => {
    const data = await this.clinical.getEncounterClinicalData({
      encounterId,
      types: [
        ClinicalDataType.CustomClinicalTool,
        ClinicalDataType.CustomClinicalToolContext,
        ClinicalDataType.DASH,
        ClinicalDataType.GRCS,
        ClinicalDataType.OREBRO,
        ClinicalDataType.K10
      ]
    });

    if (data) return data;
    return undefined;
  };

  getUserSettingsVisits = async () => {
    const userSetting = await this.userExperience.getUserSetting(
      this.core.userId
    );

    const DEFAULT_VISIT_NUMBER = "1";
    const STANDARD_NUMBER_OF_VISITS_CHECK = "6";

    const userSettingVisits: string[] = [DEFAULT_VISIT_NUMBER];
    if (
      userSetting?.clinicalTaskSettings &&
      userSetting?.clinicalTaskSettings?.length > 0
    ) {
      userSettingVisits.push(
        ...userSetting?.clinicalTaskSettings.map(x => x.visitNumber)
      );
    } else {
      userSettingVisits.push(STANDARD_NUMBER_OF_VISITS_CHECK);
    }

    return userSettingVisits;
  };

  getConsultationEncounters = async (claimId: string, patientId?: string) => {
    const claimEoc = await this.acc.getClaimEpisodesOfCare({
      claimId
    });

    const relatedEncounters = await this.clinical.getEncounters({
      patientId: this.clinical.activeRecordPatientId ?? patientId ?? "",
      episodeOfCareIds: [claimEoc[0].episodeOfCareId]
    });

    return relatedEncounters.filter(e => e.type !== EncounterType.RecordUpdate);
  };

  getOutcomeMeasuresReportData = async (claim: string, patientId?: string) => {
    const claimInfo = claim.split("/");
    let claimId: string | undefined;
    let businessRole: string | undefined;
    if (claimInfo && claimInfo.length === 2) {
      claimId = claimInfo[0];
      businessRole = claimInfo[1];
    } else {
      claimId = claim;
    }

    const userSettingVisits = await this.getUserSettingsVisits();

    let possibleVisits: VisitData[] = [];
    let clinicalToolOptions: IDropdownOption[] = [];

    const consultationEncounters = await this.getConsultationEncounters(
      claimId,
      patientId
    );

    const sortedConstEncounters = getSortedEncounters(
      consultationEncounters
    ).reverse();

    this.claimRelatedEncounters = businessRole
      ? sortedConstEncounters.filter(x => x.businessRole === businessRole)
      : sortedConstEncounters;

    let selectedEncounters: Encounter[] = [];
    if (sortedConstEncounters && sortedConstEncounters.length >= 1) {
      selectedEncounters = sortedConstEncounters.filter((x, index) =>
        userSettingVisits.includes((index + 1).toString())
      );
    }

    if (selectedEncounters) {
      let clinicalToolTypes: string[] = [];
      let customToolTypes: string[] = [];
      const promises = selectedEncounters.map(x =>
        this.getEncounterClinicalData(x.id)
      );

      const respond = await Promise.all(promises);
      const allEncountersClinicalData = flatten(respond.filter(isDefined));

      allEncountersClinicalData.forEach(encounterData => {
        clinicalToolTypes = this.getClinicalToolTypes(
          encounterData,
          clinicalToolTypes
        );
        customToolTypes = this.getCustomClinicalToolTypes(
          encounterData,
          customToolTypes
        );
      });

      clinicalToolOptions = this.getClinicalToolOptions(
        clinicalToolTypes,
        customToolTypes
      );
    }
    possibleVisits = this.claimRelatedEncounters.map(e => ({
      encounterId: e.id,
      date: e.startDateTime
    }));

    runInAction(() => {
      this.clinicalToolOptions = clinicalToolOptions;
      this.visitOptions = possibleVisits;
      this.userSettingsVisits = userSettingVisits;
    });
  };

  getUpdatedClinicalToolOptions = async (visits: string[]) => {
    let selectedEncounters: Encounter[] = [];
    if (this.claimRelatedEncounters.length >= 1) {
      selectedEncounters = this.claimRelatedEncounters.filter(x =>
        visits.includes(x.id.toString())
      );
    }

    if (selectedEncounters) {
      let clinicalToolTypes: string[] = [];
      let customToolTypes: string[] = [];
      const promises = selectedEncounters.map(x =>
        this.getEncounterClinicalData(x.id)
      );

      const respond = await Promise.all(promises);
      const allEncountersClinicalData = flatten(respond.filter(isDefined));

      allEncountersClinicalData.forEach(encounterData => {
        clinicalToolTypes = this.getClinicalToolTypes(
          encounterData,
          clinicalToolTypes
        );
        customToolTypes = this.getCustomClinicalToolTypes(
          encounterData,
          customToolTypes
        );
      });

      const clinicalToolOptions = this.getClinicalToolOptions(
        clinicalToolTypes,
        customToolTypes
      );

      runInAction(() => {
        this.clinicalToolOptions = clinicalToolOptions;
      });
    }
  };
}
