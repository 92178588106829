import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";

import {
  CenteredLargeSpinner,
  ScreenWidthMinXLarge,
  Spinner,
  useScreenSize
} from "@bps/fluent-ui";
import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { PatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PatientRecordScreenHelper } from "@modules/clinical/screens/context/PatientRecordScreenHelper.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

import { PatientRecordDetails } from "./components/PatientRecordDetails.tsx";

const PatientRecordScreenBase: FunctionComponent = observer(() => {
  const root = useStores();
  const { clinical } = root;
  const { width } = useScreenSize();
  const helper = usePersistedHelper<PatientRecordScreenHelper>(
    () => new PatientRecordScreenHelper(root)
  );

  const patientId = clinical.activeRecordPatientId!;

  const trackEvent = useAppTrackEvent(AppInsightsEventNames.encounterStarted);

  useEffect(() => {
    // listen to screen with changes and collapse clinical side panner
    // when reached ScreenWidthMinXLarge (1024px)
    if (
      width <= ScreenWidthMinXLarge &&
      clinical.ui.tabs.currentPatientRecordTab?.sidePanelSize !==
        TreeViewSize.IconsOnly
    ) {
      clinical.ui.tabs.currentPatientRecordTab?.toggleSidePanel(false, width);
    }
  }, [clinical.ui.tabs.currentPatientRecordTab, width]);

  if (!patientId) {
    return null;
  }

  return (
    <DataFetcher
      refetchId={clinical.activeRecord}
      fetch={() => helper.loadHelper(trackEvent)}
      fallback={<CenteredLargeSpinner />}
    >
      {() => (
        <PatientRecordScreenContext.Provider value={helper}>
          <PatientRecordDetails />
        </PatientRecordScreenContext.Provider>
      )}
    </DataFetcher>
  );
});

export const PatientRecordScreen = withFetch(
  x => x.clinical.ref.clinicalNoteSections.load(),
  PatientRecordScreenBase,
  { fallback: <Spinner /> }
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default PatientRecordScreen;
