import { useContext } from "react";

import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";

import { CommsScheduleContext } from "../context/CommsScheduleContext.ts";
import { CommsCard } from "./CommsCard.tsx";
import { CommsScheduleFormValues } from "./CommsScheduleForm.types.ts";
import { CommsScheduleCardIds } from "./CommsScheduleFormEnums.ts";

const nameOf = nameOfFactory<CommsScheduleFormValues>();

interface TypeOfAppointmentsFormSectionProps {
  appointmentTypes: AppointmentType[];
}

export const TypeOfAppointmentsFormSection = ({
  appointmentTypes
}: TypeOfAppointmentsFormSectionProps) => {
  const { selectedSectionId } = useContext(CommsScheduleContext);

  const appointmentTypesKeyTextValues = (): KeyTextValue[] => {
    return appointmentTypes.map(({ text, id }) => ({
      key: id,
      text
    }));
  };

  return (
    <CommsCard
      id={CommsScheduleCardIds.typeOfAppointments}
      heading="Type of appointments"
      openOnRender={
        !selectedSectionId ||
        selectedSectionId === CommsScheduleCardIds.typeOfAppointments
      }
    >
      <OptionsSelectField
        name={nameOf("appointmentTypes")}
        placeholder="Select appointment types"
        label="What type of appointments should be sent a reminder?"
        multiSelect
        options={appointmentTypesKeyTextValues()}
        hideSearchOption
        showCountCoin
        required
      />
    </CommsCard>
  );
};
