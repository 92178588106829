import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { IContextualMenuItem, PrimaryButton } from "@bps/fluent-ui";
import { AppointmentInformationModalContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModalContext.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";

interface AppointmentInformationEditButtonProps {
  noAppointments: boolean;
  apptItems: CalendarEvent[];
  selectionCount: number | undefined;
}

export const AppointmentInformationEditButton: React.FC<AppointmentInformationEditButtonProps> =
  observer(({ noAppointments, apptItems, selectionCount }) => {
    const helper = useContext(AppointmentInformationModalContext);
    const getMenuItems = () => {
      const items: IContextualMenuItem[] = [];
      if (helper.isDnaOrCanceledStatus) {
        items.push({
          key: "reschedule",
          text: "Reschedule",
          onClick: helper.onRescheduleOrCreateCopyClick
        });
      } else {
        items.push(
          {
            key: "createCopy",
            text: "Create a copy",
            onClick: helper.onRescheduleOrCreateCopyClick
          },
          {
            key: "cancelAppointment",
            text: "Cancel appointment",
            onClick: helper.onCancelClick
          }
        );
      }

      return items;
    };
    return (
      <PrimaryButton
        onClick={helper.onEditClick}
        text="Edit"
        disabled={noAppointments || apptItems.length < 1 || !selectionCount}
        split
        menuProps={{ items: getMenuItems() }}
      />
    );
  });
