import { Stack } from "@bps/fluent-ui";
import {
  isInvoiceItemDto,
  isInvoiceItemReferenceDto
} from "@libs/gateways/billing/billing-gateway.utils.ts";
import { TransactionItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import {
  isPayment,
  isRefund
} from "@stores/billing/utils/transaction.utils.ts";

import { InvoiceItemDescriptionCell } from "../account/components/transaction-list/InvoiceItemDescriptionCell.tsx";
import { InvoiceBadge } from "../invoice/components/InvoiceBadge.tsx";
import { TransactionActivityCell } from "./AllocationList/transaction-list/TransactionActivityCell.tsx";
import { TransactionBadgeNew } from "./TransactionBadgeNew.tsx";
import { TransactionLinkCell } from "./TransactionLinkCell.tsx";

interface TransactionCellProps {
  transaction: TransactionBase;
  item?: TransactionItemDto;
}

export const TransactionCell: React.FC<TransactionCellProps> = ({
  transaction,
  item
}) => {
  let descriptionCell;
  if (item) {
    if (isInvoiceItemReferenceDto(item)) {
      descriptionCell = (
        <InvoiceItemDescriptionCell invoiceItem={item.invoiceItem} />
      );
    } else if (isInvoiceItemDto(item)) {
      descriptionCell = <InvoiceItemDescriptionCell invoiceItem={item} />;
    } else {
      return null;
    }
  }

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      styles={{ root: { width: "100%" } }}
    >
      <Stack horizontal tokens={{ childrenGap: 24 }} verticalAlign="center">
        {item && isInvoiceItemDto(item) ? (
          <InvoiceBadge invoiceItem={item} />
        ) : (
          <TransactionBadgeNew
            transaction={transaction}
            item={item}
            displayAsIconBadge
          />
        )}
        {descriptionCell || <TransactionLinkCell transaction={transaction} />}
      </Stack>
      {!descriptionCell &&
        !isPayment(transaction) &&
        !isRefund(transaction) && (
          <TransactionActivityCell id={transaction.id} />
        )}
    </Stack>
  );
};
