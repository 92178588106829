import { FC } from "react";

import { routes } from "@libs/routing/routes.ts";
import { EM_DASH_SYMBOL } from "@modules/acc/screens/claims/components/ClaimNumberCell.tsx";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

type ClaimAdjustamentCellProps = {
  claimAdjustment?: ClaimAdjustment;
};

export const ClaimAdjustamentCell: FC<ClaimAdjustamentCellProps> = ({
  claimAdjustment
}) => {
  const { routing } = useStores();

  return claimAdjustment ? (
    <Navigate
      to={{
        pathname: routes.claimAdjustment.edit.path({
          id: claimAdjustment.id,
          claimId: claimAdjustment.claimId
        })
      }}
      state={routing.getStateWithFromQuery()}
    >
      {claimAdjustment.formattedNumber}
    </Navigate>
  ) : (
    <>{String.fromCodePoint(EM_DASH_SYMBOL)}</>
  );
};
