import { Observer } from "mobx-react-lite";
import React, { useState } from "react";

import {
  ConfirmDialog,
  FontSizes,
  FontWeights,
  Link,
  MessageBar,
  MessageBarType,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  FormInstanceDTO,
  FormInstanceStatus,
  FormTemplateTypeCode
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import {
  outstandingFormWarningText,
  WarningText
} from "./FormWarningClaimPendingLabel.ts";
import { findLatestForm } from "./utils.ts";

interface FormWarningClaimPendingProps {
  patientId: string;
  formTemplateTypes: FormTemplateTypeCode[];
  context?: Record<string, string>;
}

export const FormWarningClaimPending: React.FunctionComponent<
  FormWarningClaimPendingProps
> = ({ patientId, formTemplateTypes, context }) => {
  const { forms } = useStores();

  const [cancelFormInstance, setCancelFormInstance] = useState<
    FormInstanceDTO | undefined
  >(undefined);

  const handleCancelForm = async () => {
    if (cancelFormInstance) {
      await forms.cancelForm(cancelFormInstance);
      setCancelFormInstance(undefined);
    }
  };

  const onDialogDismiss = () => {
    setCancelFormInstance(undefined);
  };

  const boldStyle = {
    root: {
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.small
    }
  };

  return (
    <When permission={Permission.SendForms}>
      <DataFetcher<FormInstanceDTO[]>
        fetch={({ forms }) =>
          forms.getFormInstances(patientId, { ignoreCache: false })
        }
      >
        {(patientForms: FormInstanceDTO[]) => {
          const latestOustandingForm = findLatestForm(patientForms, {
            formTemplateTypes,
            context,
            status: FormInstanceStatus.Pending
          });
          return (
            <Observer>
              {() => {
                return !latestOustandingForm ? null : (
                  <>
                    <MessageBar messageBarType={MessageBarType.warning}>
                      <Text styles={boldStyle}>
                        {outstandingFormWarningText(formTemplateTypes)}
                      </Text>
                      {WarningText.First}
                      <Text styles={boldStyle}>
                        {DateTime.fromISO(
                          latestOustandingForm.sentDate
                        ).toDayDefaultFormat()}
                      </Text>
                      {WarningText.Second}
                      <Link
                        onClick={() =>
                          setCancelFormInstance(latestOustandingForm)
                        }
                      >
                        Cancel the form
                      </Link>
                      {WarningText.Third}
                    </MessageBar>
                    {cancelFormInstance && (
                      <ConfirmDialog
                        hidden={false}
                        confirmButtonProps={{ text: "Yes" }}
                        cancelButtonProps={{ text: "No" }}
                        onConfirm={handleCancelForm}
                        onCancel={onDialogDismiss}
                      >
                        Are you sure you want to cancel this form?
                      </ConfirmDialog>
                    )}
                  </>
                );
              }}
            </Observer>
          );
        }}
      </DataFetcher>
    </When>
  );
};
