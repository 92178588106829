import { observable, runInAction } from "mobx";

import { IGroup } from "@bps/fluent-ui";
import {
  TemplateFieldGroup,
  TemplateFieldOptions
} from "@libs/gateways/template-management/TemplateManagementGateway.dtos.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { TemplateFieldSelection } from "../TemplateManagementTypes.ts";

export class TemplateFieldsHelper {
  constructor(private root: RootStore) {}

  @observable
  filteredGroups: IGroup[] = [];

  @observable
  filteredItems: TemplateFieldSelection[] = [];

  private templateFieldGroups: TemplateFieldGroup[];

  loadTemplateFields = async () => {
    const { templateManagement } = this.root;
    const context = [
      "ContactId",
      "CalendarEventId",
      "TransactionId",
      "ClaimId",
      "OrgUnitId",
      "UserId"
    ];

    const templateRenderOptions: TemplateFieldOptions = {
      context
    };

    this.templateFieldGroups = await templateManagement.getTemplateFields(
      templateRenderOptions
    );

    this.mapTemplateFieldGroups();
  };

  mapTemplateFieldGroups = () => {
    runInAction(() => {
      this.filteredGroups = [];
      this.filteredItems = [];

      let startIndex = 0;

      const addGroup = (group: TemplateFieldGroup) => {
        this.filteredGroups.push({
          key: group.name,
          name: group.name,
          startIndex,
          count: group.fieldDefinitions.length
        });
        group.fieldDefinitions.forEach(field => {
          this.filteredItems.push({
            text: field.text,
            path: `{{${field.property}}}`,
            context: group.contextName
          });
        });
        startIndex += group.fieldDefinitions.length;
      };

      this.templateFieldGroups.forEach(group => addGroup(group));
    });
  };
}
