import {
  noWrap,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";

interface ClaimTextBadgeProps {
  episodeOfCareId?: string;
}
export const ConditionTextBadge: React.FC<ClaimTextBadgeProps> = ({
  episodeOfCareId
}) => {
  const PRIVATETEXT = "Private";
  const { clinicalRecord } = usePatientRecordScreenContext();
  const getClaimInfo = (episodeOfCareId?: string) => {
    if (episodeOfCareId) {
      const condition = clinicalRecord.getConditionByEocId(episodeOfCareId);

      if (condition) {
        if (condition.isPrivate) {
          return PRIVATETEXT;
        }

        const claim = condition.claim;
        if (claim) {
          if (claim.claimNumber === undefined) return "Not lodged";
          return claim.claimNumber;
        }
      }
    }
    return undefined;
  };

  const claimText = getClaimInfo(episodeOfCareId);

  if (!claimText) return null;

  return (
    <TextBadge
      badgeSize={TextBadgeSize.small}
      horizontalAlign="center"
      badgeColor={
        claimText === PRIVATETEXT
          ? TextBadgeColor.lightGrey
          : TextBadgeColor.blue
      }
      styles={{
        root: {
          paddingLeft: "8px",
          paddingRight: "8px",
          ...noWrap
        }
      }}
    >
      {claimText}
    </TextBadge>
  );
};
