import { Heading, Shimmer, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  OrgUnitOpeningHoursDto,
  OrgUnitOpeningHoursOverrideDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { OrgUnitAvailability } from "@stores/booking/models/OrgUnitAvailability.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { SettingsLabels } from "../../../shared-components/SettingsLabels.ts";
import { OrgUnitOpeningHoursOverridesForm } from "./OrgUnitOpeningHoursOverridesForm.tsx";
import {
  Operation,
  OrgUnitOpeningHoursOverridesFormValues,
  toOrgUnitOpeningHoursOverridesFormValues
} from "./OrgUnitOpeningHoursOverridesForm.types.ts";

const OrgUnitOpeningHoursOverridesScreen: React.FC = () => {
  const { core, booking, notification } = useStores();
  const onSubmit = async (
    values: OrgUnitOpeningHoursOverridesFormValues[],
    other: OrgUnitOpeningHoursOverridesFormValues[],
    availabilityOpeningHours: OrgUnitOpeningHoursDto[]
  ) => {
    const orgUnitOpeningHoursOverrides = toOrgUnitOpeningHoursOverride(
      [...other, ...values],
      core.locationId
    );

    await booking.updateOrgUnitAvailability({
      orgUnitId: core.locationId,
      orgUnitOpeningHoursOverrides,
      // pass in orgUnitOpeningHours because the server fails with 500 otherwise
      // TODO: remove line below when API properly supports patch behaviour
      orgUnitOpeningHours: availabilityOpeningHours
    });
  };

  const toOrgUnitOpeningHoursOverride = (
    exceptions: OrgUnitOpeningHoursOverridesFormValues[],
    orgUnitId: string
  ) => {
    return (
      exceptions?.map<OrgUnitOpeningHoursOverrideDto>(e => {
        const isClosed: boolean = e.operation === Operation.Closed;
        return {
          id: e.id!,
          orgUnitId,
          eTag: e.eTag!,
          startDate: DateTime.fromJSDateOrNow(e.startDate).toISODate(),
          endDate: DateTime.fromJSDateOrNow(e.endDate).toISODate(),
          isClosed: !!(e.operation && e.operation === Operation.Closed),
          startTime: !isClosed ? e.startTime! : undefined,
          endTime: !isClosed ? e.endTime! : undefined,
          reason: e.reason
        };
      }) ?? []
    );
  };
  return (
    <Stack tokens={{ childrenGap: 24 }} styles={{ root: { paddingTop: 26 } }}>
      <Heading variant="section-heading">{SettingsLabels.exceptions}</Heading>
      <DataFetcher<OrgUnitAvailability>
        fallback={
          <Stack styles={{ root: { width: 700, marginTop: 16 } }}>
            <Shimmer styles={{ root: { marginBottom: 16 } }} />
            <Shimmer styles={{ root: { marginBottom: 16 } }} />
            <Shimmer styles={{ root: { marginBottom: 16 } }} />
          </Stack>
        }
        fetch={({ booking, core }) =>
          booking.getOrgUnitAvailability(core.locationId)
        }
      >
        {availability => {
          const initialValues = toOrgUnitOpeningHoursOverridesFormValues(
            availability.openingHoursOverrides
          );

          return (
            <>
              <OrgUnitOpeningHoursOverridesForm
                readonly={!core.hasPermissions(Permission.OrgUnitSettingWrite)}
                initialValues={{
                  orgUnitOpeningHoursOverrides: initialValues.after
                }}
                iconName="AwayStatus"
                title={SettingsLabels.activeLocationExceptions}
                onSubmit={values =>
                  onSubmit(
                    values.orgUnitOpeningHoursOverrides,
                    initialValues.before,
                    availability.openingHours
                  )
                }
                onSubmitSucceeded={() => {
                  notification.success("Exceptions have been updated.");
                }}
                emptyStateText={SettingsLabels.noCurrentExceptions}
                emptyStateSubText={SettingsLabels.useButtonBelow}
                allowAdd={true}
              />
              <OrgUnitOpeningHoursOverridesForm
                readonly={core.hasPermissions(Permission.OrgUnitSettingWrite)}
                initialValues={{
                  orgUnitOpeningHoursOverrides: initialValues.before
                }}
                iconName="History"
                title={SettingsLabels.previousLocationExceptions}
                onSubmit={values =>
                  onSubmit(
                    values.orgUnitOpeningHoursOverrides,
                    initialValues.after,
                    availability.openingHours
                  )
                }
                onSubmitSucceeded={() => {
                  notification.success("Exceptions have been updated.");
                }}
                emptyStateText={SettingsLabels.expiredLocationExceptions}
                emptyStateSubText={
                  SettingsLabels.expiredLocationExceptionsSubText
                }
              />
            </>
          );
        }}
      </DataFetcher>
    </Stack>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default OrgUnitOpeningHoursOverridesScreen;
