import { UserDto } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PatientDisplayCode } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface UserInfoFormValues
  extends Pick<
    UserDto,
    | "firstName"
    | "lastName"
    | "title"
    | "middleName"
    | "id"
    | "gender"
    | "preferredName"
  > {
  username?: string;
  workPhone?: string;
  patientDisplayCode?: PatientDisplayCode;
  signatureFile?: string;
  signatureUrl?: string;
  pronounSubjective?: string;
  pronounObjective?: string;
  pronounPossessive?: string;
}

export const userInfoFormNameOf = nameOfFactory<UserInfoFormValues>();
