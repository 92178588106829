import {
  SubmitActionCode,
  TemplateFormatType,
  TemplateTypeCode
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { sortByKey } from "@libs/utils/utils.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { Template } from "@stores/documents/models/Template.ts";

import { TemplateName } from "./components/context/TemplatePickerFormModel.ts";
import { TemplateListItem } from "./components/TemplatePivot.types";

export const sortItems = (items: TemplateListItem[]) => {
  return sortByKey(items, "details");
};
export const getListItems = (
  core: CoreStore,
  templates: Template[],
  options?: {
    isProviderClass?: boolean;
    isNoneClinical?: boolean;
  }
) => {
  const isProviderClass = options?.isProviderClass;
  const isNoneClinical = options?.isNoneClinical;

  const isNZTenant = core.isNZTenant;

  const list = templates.filter(x =>
    !isProviderClass && isNoneClinical
      ? x.templateType !== TemplateTypeCode.Clinical
      : x.templateFormat === TemplateFormatType.SyncFusionDocx &&
        !x.isSystem &&
        !x.isNew &&
        x.isHiddenOnStatus(SubmitActionCode.PublishToSelfCode) &&
        x.isHiddenOnStatus(SubmitActionCode.DraftCode, true) &&
        (!isNZTenant ||
          core.hasPermissions(Permission.PhysioDischarge) ||
          x.name !== TemplateName.Discharge) &&
        (isNZTenant || x.name !== TemplateName.OutcomeMeasure)
  );

  return list.map(t => {
    return {
      key: t.id,
      details: t.name,
      updated: t.updatedDate,
      isCustom: t.isCustom,
      documentStatus: t.documentStatus,
      documentType: t.documentType,
      documentAuthor: t.createdBy,
      isLetterhead: t.isLetterhead
    };
  });
};
