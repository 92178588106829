import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  Length,
  lessThan,
  maxLength,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CustomToolTrendAnswers } from "@shared-types/clinical/custom-tool-trend-answers.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";

import { sotapNameOf } from "../SOTAP.utils.ts";

export class OutcomeMeasuresCardValidator extends Validator<SOTAPFormValues> {
  constructor() {
    super();
    const customToolValidator = new CustomToolValidator();
    this.forArrayField(
      sotapNameOf("customClinicalTools"),
      customToolValidator.validate
    );
  }
}

class CustomToolValidator extends Validator<CustomToolTrendAnswers> {
  readonly nameOf = nameOfFactory<CustomToolTrendAnswers>();

  constructor() {
    super();
    this.forField(this.nameOf("name"), [required(), maxLength(Length.long)]);
    this.forField(this.nameOf("thisEncounterResult"), [
      required(),
      lessThan(1000),
      greaterThan(-1000)
    ]);
  }
}
