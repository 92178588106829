import { CollapsibleCard } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { DischargeFormValues } from "@modules/clinical/screens/patient-record/components/SOTAP/discharge-form/DischargeForm.types.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { DischargeId, DischargeLabel } from "../SOTAP.constants.ts";

export const DischargeReferralFollowUpCard: React.FunctionComponent = () => {
  const nameOf = nameOfFactory<DischargeFormValues>();

  return (
    <div id={DischargeId.ReferralFollowUp}>
      <CollapsibleCard
        heading={DischargeLabel.ReferralFollowUp}
        headingLevel="section-heading"
        iconName="Send"
        openOnRender
      >
        <Fieldset>
          <TextInputField
            label="Follow-up notes"
            name={nameOf("followUpNotes")}
            multiline={true}
            rows={3}
            autoAdjustHeight
            resizable={false}
            styles={{
              fieldGroup: { minHeight: 60 }
            }}
          />
          <DatePickerField
            name={nameOf("followUpDueDate")}
            label="Recommended follow-up due date"
            minDate={DateTime.jsDateNow()}
            validateOnInitialize={true}
          />
        </Fieldset>
      </CollapsibleCard>
    </div>
  );
};
