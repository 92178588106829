import { DateTime } from "@bps/utils";
import {
  AppointmentStatusCode,
  CalendarEventAttendeeDto,
  CalendarEventStatus,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { User } from "@stores/core/models/User.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { RootStore } from "@stores/root/RootStore.ts";

export interface DidNotArrive {
  startDateTime: DateTime;
  patientId: string;
  patient: Contact | undefined;
  cancelled: boolean;
  cancellationReasonId: string | undefined;
  cancellationDateTime: DateTime | undefined;
  cancellationText: string | undefined;
  provider: User | undefined;
  calendarEvent: CalendarEvent;
  location: string | undefined;
}

export const getDidNotArriveListItems = (
  calendarEvents: CalendarEvent[],
  statuses: string[],
  root?: RootStore
): DidNotArrive[] => {
  const items: DidNotArrive[] = [];
  const includeCancelled = statuses.includes(CalendarEventStatus.Cancelled);
  const includeDna = statuses.includes(AppointmentStatusCode.DidNotAttend);
  const includeAll = !statuses.length;

  calendarEvents.forEach(ce => {
    if (ce.type === CalendarEventType.Appointment) {
      const getDidNotArriveItem = (
        attendee: CalendarEventAttendeeDto
      ): DidNotArrive => {
        const patient = root?.practice.contactsMap.get(attendee.attendeeId);
        const location = root?.core.getLocationName(ce.orgUnitId);

        return {
          startDateTime: ce.startDateTime,
          patientId: attendee.attendeeId,
          patient,
          cancelled:
            attendee.status === CalendarEventStatus.Cancelled ||
            ce.status === CalendarEventStatus.Cancelled,
          cancellationReasonId:
            attendee.cancellationReasonId ?? ce.cancellationReasonId,
          cancellationDateTime: attendee.cancellationDateTime
            ? DateTime.fromISO(attendee.cancellationDateTime)
            : ce.cancellationDateTime,
          cancellationText: attendee.cancellationText ?? ce.cancellationText,
          provider: ce.user,
          calendarEvent: ce,
          location
        };
      };

      if (ce.isGroupAppointment) {
        const dnaAttendees = [];
        if (includeDna) dnaAttendees.push(...ce.dnaAttendees);
        if (includeCancelled) dnaAttendees.push(...ce.cancelledAttendees);
        if (includeAll)
          dnaAttendees.push(...[...ce.dnaAttendees, ...ce.cancelledAttendees]);

        items.push(...dnaAttendees.map(a => getDidNotArriveItem(a)));
      } else {
        if (
          (ce.appointmentStatus === AppointmentStatusCode.DidNotAttend &&
            (includeAll || includeDna)) ||
          (ce.status === CalendarEventStatus.Cancelled &&
            (includeAll || includeCancelled))
        ) {
          items.push(
            ...ce.attendeesPatientAndContact.map(a => getDidNotArriveItem(a))
          );
        }
      }
    }
  });

  return items;
};
