import {
  dataAttribute,
  DataAttributes,
  IFontStyles,
  ITextStyles,
  Stack
} from "@bps/fluent-ui";
import {
  EncounterType,
  RFV_CD_TYPE_CODE_OTHER
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TerminologyText } from "@modules/clinical/screens/patient-record/components/TerminologyText.tsx";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface IEncounterReasonForVisitText {
  encounter?: Encounter;
  skipOtherText?: boolean;
  variant?: keyof IFontStyles;
  nowrap?: boolean;
  block?: boolean;
  styles?: ITextStyles;
}

export const EncounterReasonForVisitText: React.FC<
  IEncounterReasonForVisitText
> = ({ encounter, skipOtherText, nowrap, variant, block, styles }) => {
  const { clinical } = useStores();

  const getClinicalData = async () => {
    if (encounter) {
      const clinicalData = clinical.clinicalDataMap.get(encounter.id);
      if (clinicalData) {
        const terminologyCodes = clinicalData?.reasonForVisit?.reasonForVisits;
        return { clinicalData, terminologyCodes };
      } else {
        const fetchedClinicalData = await clinical.getEncounterClinicalData({
          encounterId: encounter.id
        });

        return {
          clinicalData: fetchedClinicalData,
          terminologyCodes: fetchedClinicalData.reasonForVisit?.reasonForVisits
        };
      }
    }

    return undefined;
  };

  return (
    <DataFetcher fetch={getClinicalData}>
      {data => (
        <Stack verticalAlign="start">
          {data?.terminologyCodes && (
            <TerminologyText
              codedTexts={data?.terminologyCodes.map(r => ({
                code: r.code,
                text: r.originalText
              }))}
              id={`pv-${encounter?.id}`}
              variant={variant}
              nowrap={nowrap}
              block={block}
              styles={styles}
            />
          )}
          {!data?.terminologyCodes &&
            encounter?.type === EncounterType.RecordUpdate &&
            "Record update"}

          {!skipOtherText &&
            data?.clinicalData?.reasonForVisit?.reasonForVisits.some(
              r => r.originalText && r.code === RFV_CD_TYPE_CODE_OTHER
            ) && (
              <span
                {...dataAttribute(DataAttributes.Element, "rfv-original-text")}
              >
                Other:{" "}
                {
                  data?.clinicalData?.reasonForVisit?.reasonForVisits.find(
                    r => !!r.originalText && r.code === RFV_CD_TYPE_CODE_OTHER
                  )?.originalText
                }
              </span>
            )}
        </Stack>
      )}
    </DataFetcher>
  );
};
