import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

import {
  confirm,
  IContextualMenuItem,
  NoDataTile,
  NoDataTileProps,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { SystemNotice } from "@shared-types/practice/system-notice.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { SystemNoticeTableRow } from "../system-notice/SystemNoticeTableRow.tsx";
import { AddPatientNoticeFormDialog } from "./AddPatientNoticeFormDialog.tsx";
import { usePatientNoticesContext } from "./PatientNoticesContext.tsx";
import { NoticeType } from "./PatientNoticesModel.ts";
import { PatientNoticesTableRow } from "./PatientNoticesTableRow.tsx";

interface PatientNoticesTableNoContextProps {
  filter: NoticeType;
  setPreventDismissOnDelete?: (value: boolean) => void;
  padding?: string | number;
  childrenGap?: string | number;
  showNoDataTile?: boolean;
  noDataMessage?: string;
  showActions?: boolean;
  greyBackground?: boolean;
  noDataTileStyles?: NoDataTileProps["styles"];
}

const PatientNoticesTableBase: React.FC<PatientNoticesTableNoContextProps> =
  observer(
    ({
      filter,
      setPreventDismissOnDelete,
      padding = 16,
      childrenGap = 8,
      showNoDataTile = false,
      noDataMessage,
      showActions,
      greyBackground,
      noDataTileStyles
    }) => {
      const { core, practice } = useStores();
      const {
        getFilteredPatientNotices,
        systemNotices,
        patientNoticeSort,
        patientId
      } = usePatientNoticesContext();

      const [showDialog, setShowDialog] = useState<boolean>(false);
      const editNoticeRef = useRef<PatientNotice | undefined>(undefined);
      const { isViewOnly } = usePatientRecordScreenContext();
      const notices = getFilteredPatientNotices(filter);
      const noticeCount = notices.length + systemNotices.length;

      const getMenuItems = (n: PatientNotice): IContextualMenuItem[] => {
        return [
          {
            key: "editNotice",
            text: "Edit",
            onClick: () => {
              editNoticeRef.current = n;
              setShowDialog(true);
            }
          },

          {
            key: "deleteNotice",
            text: "Delete",
            onClick: async () => {
              if (setPreventDismissOnDelete) setPreventDismissOnDelete(true);

              const isConfirmed = await confirm({
                maxWidth: 560,
                cancelButtonProps: {
                  text: "Cancel"
                },
                confirmButtonProps: {
                  text: "Delete"
                },
                dialogContentProps: {
                  title: "Delete patient notice",
                  subText:
                    "Are you sure you’d like to remove this patient notice?"
                }
              });

              if (isConfirmed) {
                await practice.deletePatientNotice(n.id);
              }

              if (setPreventDismissOnDelete) setPreventDismissOnDelete(false);
            }
          }
        ];
      };

      return (
        <>
          {noticeCount > 0 && (
            <Stack tokens={{ childrenGap, padding }}>
              {systemNotices.map((n: SystemNotice) => (
                <SystemNoticeTableRow
                  key={n.type}
                  systemNotice={n}
                  patientId={patientId}
                />
              ))}
              {notices.sort(patientNoticeSort).map((n: PatientNotice) => (
                <PatientNoticesTableRow
                  key={n.id}
                  showActions={
                    showActions && core.hasPermissions(Permission.ContactWrite)
                  }
                  items={getMenuItems(n)}
                  patientNotice={n}
                />
              ))}
            </Stack>
          )}
          {noticeCount === 0 && showNoDataTile && (
            <NoDataTile
              styles={{
                root: {
                  padding: 0,
                  minHeight: 120,
                  width: "100%",
                  height: "100%"
                },
                ...noDataTileStyles
              }}
              greyView={greyBackground}
              textProps={{ text: noDataMessage ?? "" }}
              linkProps={{
                text: "Add notice",
                hidden: isViewOnly,
                onClick: () => setShowDialog(true)
              }}
              showBoxShadow={false}
            />
          )}

          {showDialog && (
            <AddPatientNoticeFormDialog
              onDismiss={() => setShowDialog(false)}
              editNotice={editNoticeRef.current}
            />
          )}
        </>
      );
    }
  );

export const PatientNoticesTable = withFetch(
  x => [x.practice.ref.patientNoticeType.load()],
  PatientNoticesTableBase
);
