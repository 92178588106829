import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  integer,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { WaitingListItemFormValues } from "./WaitingListItemFormValues.ts";

export class WaitingListItemFormValidator extends Validator<WaitingListItemFormValues> {
  private nameOf = nameOfFactory<WaitingListItemFormValues>();

  constructor() {
    super();

    this.forField(this.nameOf("expiryDate"), [required()]);
    this.forField(this.nameOf("patientId"), [required()]);
    this.forField(this.nameOf("duration"), [
      predicate(x => x !== 0, required()),
      integer(),
      greaterThan(0)
    ]);
    this.forField(this.nameOf("appointmentTypeId"), [required()]);
    this.forField(this.nameOf("providerId"), [required()]);
  }
}
