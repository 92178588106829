import { observer } from "mobx-react-lite";
import { useField, useForm } from "react-final-form";

import { IChoiceGroupOption, Stack } from "@bps/fluent-ui";
import { EmploymentStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { claimFormNameOf } from "../../claim.utils.ts";
import { ClaimFormLabels } from "../ClaimFormEnums.ts";

export const EmploymentStatusField = observer(() => {
  const { acc } = useStores();

  const form = useForm<ClaimFormValues>();

  const {
    input: { value: employmentStatusField }
  } = useField(claimFormNameOf("employmentStatus"), {
    subscription: { value: true }
  });

  const options: IChoiceGroupOption[] =
    acc.ref.employmentStatuses.keyTextValues.map(employmentStatus => {
      if (employmentStatus.text === "Other") {
        return {
          ...employmentStatus,
          onRenderField: (props, render) => (
            <Stack
              tokens={{ childrenGap: 8 }}
              horizontal
              verticalAlign="center"
              styles={{
                root: {
                  width: "100%"
                }
              }}
            >
              {render && render(props)}
              <TextInputField
                disabled={employmentStatusField !== EmploymentStatuses.Other}
                placeholder={ClaimFormLabels.otherEmployment}
                name={claimFormNameOf("otherEmployment")}
              />
            </Stack>
          )
        };
      } else return employmentStatus;
    });

  return (
    <>
      <ChoiceGroupField
        styles={{
          root: {
            ".ms-ChoiceField-wrapper": {
              width: "100%"
            }
          }
        }}
        required
        options={options}
        label={ClaimFormLabels.employmentStatus}
        name={claimFormNameOf("employmentStatus")}
      />
      <FieldSpy
        name={claimFormNameOf("employmentStatus")}
        onChange={employmentStatus => {
          if (employmentStatus.key !== EmploymentStatuses.Other) {
            form.change(claimFormNameOf("otherEmployment"), undefined);
          }
        }}
      />
    </>
  );
});
