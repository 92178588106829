import { observer } from "mobx-react-lite";

import {
  defaultWaitingListFilter,
  WaitingListFilter
} from "@stores/booking/BookingStore.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";

import { useWaitingListScreenContext } from "../context/WaitingListScreenContext.tsx";

export interface WaitingListDetailFilterProps
  extends Pick<FilterBarProps, "children"> {}

export interface WaitingListFilterInternal
  extends Omit<WaitingListFilter, "attendees"> {
  providerId: string | undefined;
}

const WaitingListDetailFilterBase: React.FC<WaitingListDetailFilterProps> =
  observer(({ children }) => {
    const { booking, core } = useStores();
    const { items: fetchedItems, waitingListResults } =
      useWaitingListScreenContext();

    const priorityOptions = [
      { key: "1", text: "1" },
      { key: "2", text: "2" },
      { key: "3", text: "3" }
    ];

    const durationOptions = [
      { key: "0", text: "0 - 15 mins" },
      { key: "15", text: "15 - 30 mins" },
      { key: "30", text: "30 - 45 mins" },
      { key: "45", text: "45 - 60 mins" },
      { key: "60", text: "60+ mins" }
    ];

    const allAppointmentTypes = booking.allAppointmentTypes.map(
      ({ id, name }) => ({
        key: id,
        text: name
      })
    );

    const anyProviderOptions = [
      { key: "0", text: "No" },
      { key: "1", text: "Yes" }
    ];

    const items: Item<WaitingListFilterInternal>[] = [
      {
        type: "searchBox",
        name: "patientName",
        stickItem: true,
        props: {
          placeholder: "Search for patient"
        }
      },
      {
        type: "optionsSelect",
        name: "priority",
        props: {
          id: "waitinglist-filter-priority",
          placeholder: "Priority",
          options: priorityOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 200
        }
      },
      {
        type: "bookableUsersSelect",
        name: "providerId",
        props: {
          multiSelect: false,
          usersFilter: {
            showOnCalendar: true
          },
          placeholder: "Providers",
          calloutWidth: 250
        }
      },
      {
        type: "optionsSelect",
        name: "anyProvider",
        props: {
          placeholder: "Any provider",
          options: anyProviderOptions,
          multiSelect: false,
          hideSearchOption: true,
          calloutWidth: 96
        }
      },
      {
        type: "optionsSelect",
        name: "duration",
        props: {
          id: "waitinglist-filter-duration",
          placeholder: "Duration",
          options: durationOptions,
          multiSelect: true,
          hideSearchOption: true,
          calloutWidth: 200
        }
      },
      {
        type: "optionsSelect",
        name: "appointmentTypeId",
        props: {
          id: "waitinglist-filter-type",
          placeholder: "Type",
          options: allAppointmentTypes,
          multiSelect: true,
          calloutWidth: 200
        }
      }
    ];

    if (core.hasMultipleActiveLocations) {
      items.push({
        type: "locationsSelect",
        name: "orgUnitIds",
        props: {
          id: "waitinglist-filter-locations",
          multiSelect: true,
          placeholder: "Locations",
          calloutWidth: 250
        }
      });
    }

    return (
      <FilterBar<WaitingListFilterInternal>
        hidden={state => {
          return (
            !state.dirty && !fetchedItems?.length && !waitingListResults.pending
          );
        }}
        initialValues={{ ...defaultWaitingListFilter, providerId: undefined }}
        items={items}
        styles={{
          peoplePicker: { minWidth: 200 }
        }}
        children={children}
      />
    );
  });

export const WaitingListDetailFilter = withFetch(
  x => [x.booking.loadAppointmentTypes()],
  WaitingListDetailFilterBase
);
