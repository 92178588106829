import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import {
  ActionButton,
  CenteredLargeSpinner,
  DefaultButton,
  Dialog,
  FontIcon,
  IColumn,
  IconButton,
  IContextualMenuItem,
  Link,
  Overlay,
  Stack,
  StackItem,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { FormDesignUpdateDTO } from "@libs/gateways/form-design/FormDesignGateway.dtos.ts";
import { FormResponseNotificationSetting } from "@shared-types/form-design/form-response-notification-setting.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { getUxDomainName } from "../FormDesign.utils.ts";
import { FormDesignModal } from "./FormDesignModal.tsx";
import { FormDesignPreview } from "./FormDesignPreview.tsx";

export const FormDesignListModal: FunctionComponent = observer(() => {
  const { formDesign } = useStores();
  const { semanticColors } = useTheme();
  const showModel = async (id: string) => {
    formDesign.ui.setShowFormDesignModal({ id });
  };

  const previewModel = async (item: FormDesignUpdateDTO, qr?: boolean) => {
    formDesign.ui.setFormDesignPreview(item, qr ?? false);
  };

  const menuItems: (
    item: FormDesignUpdateDTO
  ) => IContextualMenuItem[] = item => {
    const { id, isPublished } = item;
    const isDomainForm = !!item.domainCode;
    const isClinical = item.domains?.some(x => x === "Clinical");
    const isUIDefault = !!item.formTypeCode;
    return [
      {
        key: "Edit",
        text: "Edit",
        disabled: isDomainForm || isUIDefault,
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          showModel(id);

          return;
        }
      },
      {
        key: "Preview",
        text: "Preview",
        subMenuProps: {
          items: [
            {
              key: "Preview",
              text: "Preview form",
              onClick: (event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                previewModel(item);
              }
            },
            {
              key: "QRCode",
              text: "Preview QR code",
              onClick: (event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                previewModel(item, true);
              }
            }
          ]
        }
      },
      {
        key: "Activate",
        text: isPublished ? "Make inactive" : "Make active",
        disabled: isUIDefault,
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          formDesign.togglePublish(item);
        }
      },
      {
        key: "Duplicate & edit",
        text: "Duplicate & edit",
        disabled: isClinical,
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          const duplicateAndShowModel = async () => {
            formDesign.ui.setShowFormDesignModal({ id, duplicateDesign: true });
          };
          duplicateAndShowModel();
        }
      },
      {
        key: "Response Notifications",
        text: "Response notifications",
        disabled: isClinical,
        subMenuProps: {
          items: [
            {
              key: "System notification",
              text: "Display system notification",
              canCheck: true,
              checked:
                item.notificationSetting ===
                FormResponseNotificationSetting.system,
              onClick: () => {
                formDesign.getFormDesign(item.id).then(design => {
                  if (!design) {
                    return;
                  }
                  design.notificationSetting =
                    design.notificationSetting ===
                    FormResponseNotificationSetting.system
                      ? FormResponseNotificationSetting.none
                      : FormResponseNotificationSetting.system;
                  formDesign.saveFormDesign(design);
                });
              }
            }
          ]
        }
      },
      {
        key: "Delete",
        text: "Delete",
        disabled: isDomainForm || isUIDefault,
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          const deleteModel = async () => {
            formDesign.ui.setShowDeleteConfirmation(item);
          };
          deleteModel();
        }
      }
    ];
  };

  const columns: IColumn[] = [
    {
      key: "ContextMenu",
      name: "",
      minWidth: 72,
      maxWidth: 72,
      onRender: item => {
        const publishedStatus = item.formTypeCode
          ? "Active (Appointment default)"
          : "Active";
        return (
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            styles={{ root: { height: "100%" } }}
            verticalAlign="center"
          >
            <IconButton
              menuIconProps={{ iconName: "More" }}
              menuProps={{ items: menuItems(item) }}
            />
            <TooltipHost
              content={item.isPublished ? publishedStatus : "Inactive"}
            >
              <FontIcon
                styles={{
                  root: {
                    color: item.isPublished
                      ? semanticColors.successIcon
                      : undefined
                  }
                }}
                iconName={item.isPublished ? "Accept" : "BpDraft"}
              />
            </TooltipHost>
          </Stack>
        );
      }
    },

    {
      name: "Name",
      key: "FormName",
      minWidth: 220,
      maxWidth: 220,
      onRender: (item: FormDesignUpdateDTO) => (
        <Stack horizontal styles={{ root: { height: "100%" } }}>
          <Link
            onClick={() => {
              previewModel(item);
            }}
          >
            {item.name}
          </Link>
        </Stack>
      )
    },
    {
      name: "Title",
      key: "FormTitle",
      minWidth: 310,
      maxWidth: 310,
      onRender: (item: FormDesignUpdateDTO) => (
        <Stack
          horizontal
          styles={{ root: { height: "100%" } }}
          verticalAlign="center"
        >
          {item.title}
        </Stack>
      )
    },
    {
      name: "Subject",
      key: "Subject",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: FormDesignUpdateDTO) => {
        const sanitisedDomains = item.domains
          ?.map(getUxDomainName)
          .filter(x => !!x)
          .sort();
        return (
          <Stack styles={{ root: { height: "100%" } }} verticalAlign="center">
            {sanitisedDomains?.map(x =>
              x === "Forms" && item.domainCode ? null : (
                <StackItem key={x}>{x}</StackItem>
              )
            )}
          </Stack>
        );
      }
    },
    {
      name: "Updated",
      key: "UpdatedDate",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: FormDesignUpdateDTO) => {
        const date = item.changeLog?.updatedDate ?? item.changeLog?.createdDate;

        return (
          <Stack
            horizontal
            styles={{ root: { height: "100%" } }}
            verticalAlign="center"
          >
            {date ? DateTime.fromISO(date).toDayDefaultFormat() : null}
          </Stack>
        );
      }
    }
  ];

  return (
    <DataFetcher
      fetch={async ({ formDesign }) => {
        return formDesign.getFormDesigns();
      }}
      fallback={
        <Overlay styles={{ root: { zIndex: 100 } }}>
          <CenteredLargeSpinner />
        </Overlay>
      }
    >
      {() => {
        return (
          <>
            <FormDesignPreview />
            <Dialog
              maxWidth={1000}
              dialogContentProps={{
                showCloseButton: true,
                title: "Online forms"
              }}
              onDismiss={() => formDesign.ui.setShowFormDesignListModal(false)}
              hidden={false}
            >
              <ShimmeredDetailsList
                items={formDesign.formDesigns}
                columns={columns}
                detailsListStyles={{
                  contentWrapper: {
                    maxHeight: "40vh",
                    overflowX: "hidden"
                  }
                }}
              />

              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={{ root: { paddingTop: 16 } }}
              >
                <ActionButton
                  text="Create a new form"
                  iconProps={{ iconName: "add" }}
                  onClick={() => {
                    formDesign.ui.setShowFormDesignModal({});
                  }}
                />
                <DefaultButton
                  text="Close"
                  onClick={() =>
                    formDesign.ui.setShowFormDesignListModal(false)
                  }
                />
              </Stack>
              <FormDesignModal />

              {formDesign.ui.showDeleteConfirmationModel && (
                <SubmissionFormDialog
                  dialogName="Delete template dialog"
                  onSubmit={() => {}}
                  dialogProps={{
                    onDismiss: () => formDesign.ui.setShowDeleteConfirmation(),
                    dialogContentProps: { title: "Delete online form?" }
                  }}
                  buttonsProps={{
                    submitButtonProps: {
                      text: "Delete",
                      iconProps: { hidden: true },
                      onClick: () => {
                        formDesign.ui.showDeleteConfirmationModel &&
                          formDesign.ui.showDeleteConfirmationModel.id &&
                          formDesign.deleteFormDesign(
                            formDesign.ui.showDeleteConfirmationModel.id
                          );
                        formDesign.ui.setShowDeleteConfirmation();
                      }
                    },
                    disableSubmitOnPristine: false
                  }}
                >
                  {() => (
                    <Text>{`Are you sure you want to delete ${formDesign.ui?.showDeleteConfirmationModel?.name}?`}</Text>
                  )}
                </SubmissionFormDialog>
              )}
            </Dialog>
          </>
        );
      }}
    </DataFetcher>
  );
});
