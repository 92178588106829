import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { IInvoiceRow } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsList.tsx";
import { TransactionBadgeNew } from "@modules/billing/screens/shared-components/TransactionBadgeNew.tsx";

export const AccreditedBillingTransactionStateCell: FunctionComponent<IInvoiceRow> =
  observer(props => {
    if (!props.isFirstItem) {
      return null;
    }

    return (
      <Stack.Item styles={{ root: { marginTop: -5, marginBottom: -6 } }}>
        <TransactionBadgeNew transaction={props.invoice} />
      </Stack.Item>
    );
  });
