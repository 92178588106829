import { mergeStyles } from "@bps/fluent-ui";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { SubmissionFormStyles } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

export const enterSlideDownStyle = mergeStyles({
  height: 0,
  opacity: 0
});

export const enterSlideDownStyleActive = mergeStyles({
  height: 210,
  opacity: 1,
  transition: "height 200ms ease-in, opacity 100ms ease-in"
});

export const exitSlideDownStyle = mergeStyles({
  opacity: 1,
  height: 210
});

export const exitSlideDownStyleActive = mergeStyles({
  height: 0,
  opacity: 0,
  transition: "height 200ms ease-in-out, opacity 200ms ease-in-out"
});

export const getSubmissionFormStyles = (
  secondColumnContent: SecondColumnContent | undefined
): Partial<SubmissionFormStyles> => ({
  root: {
    width: !!secondColumnContent ? 1000 : 500,
    transition: "width 150ms ease-in-out"
  },
  main: {
    width: 500
  },
  sidePanel: {
    padding: 24,
    width: 500,
    display: "flex"
  },
  fields: {
    overflowY: "hidden",
    display: "inherit",
    flexDirection: "column",
    padding: 0
  },
  subComponentStyles: {
    submitErrorMessage: {
      root: { margin: 8, marginLeft: 24, marginRight: 24 }
    },
    buttonSeparator: {}
  }
});
