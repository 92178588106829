import { toJS } from "mobx";

import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { ConvertToRefData } from "@libs/api/ref-data/RefData.ts";
import { PatientNoticeTypeDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  maxLength,
  predicate,
  required,
  requiredBoolean
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";

import { AddPatientNoticeFormTypes } from "./PatientNoticeForm.types.tsx";

const nameOf = nameOfFactory<AddPatientNoticeFormTypes>();
interface PatientNoticeValidatorProps {
  patientNoticeType: readonly ConvertToRefData<PatientNoticeTypeDto>[];
  patientNoticePriority: readonly ConvertToRefData<RefDataDto>[];
  currentNotices: PatientNotice[];
}

export class PatientNoticeValidator extends Validator<AddPatientNoticeFormTypes> {
  constructor(options: PatientNoticeValidatorProps) {
    super();
    const isDuplicateType = (type: string) => {
      return type === "OT"
        ? false
        : !!options.currentNotices?.some(x => x.type === type);
    };

    this.forField(nameOf("type"), [
      required(ValidationMessages.noticeTypeIsRequired),
      (value: string | undefined) =>
        value && isDuplicateType(value) ? "Notice already exists" : undefined
    ]);

    this.forField(nameOf("priority"), [
      required(ValidationMessages.noticePriorityIsRequired)
    ]);

    this.forField(
      nameOf("visibility"),
      required(ValidationMessages.pleaseCheckAtLeastOneCheckbox)
    );

    this.forField(nameOf("comment"), [
      maxLength(250),
      predicate((val, values) => {
        const mobXtype =
          values && options.patientNoticeType.find(x => x.code === values.type);

        const type = toJS(mobXtype);
        return !!type?.isTextMandatory && !values?.comment;
      }, requiredBoolean(ValidationMessages.thisNoticeTypeRequiresAComment))
    ]);
  }
}
