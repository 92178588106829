import React from "react";
import { useForm } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { appointmentFormNameOf } from "./AppointmentForm.types.ts";

const priorityOptions = [
  { key: 1, text: "1" },
  { key: 2, text: "2" },
  { key: 3, text: "3" }
];

export const WaitingListFields: React.FunctionComponent = () => {
  const form = useForm<AppointmentFormValues>();
  return (
    <>
      <FieldSpy
        name={appointmentFormNameOf("startDate")}
        onChange={date => {
          if (date) {
            form.change(appointmentFormNameOf("expiryDate"), date);
          }
        }}
      />
      <FieldCondition
        when={appointmentFormNameOf("waitingFieldToggle")}
        is={true}
      >
        <Stack
          styles={(props, theme) => ({
            root: {
              borderColor: theme.palette.neutralDark,
              border: "1px solid"
            }
          })}
        >
          <Fieldset
            horizontal
            styles={{
              root: {
                padding: 16
              }
            }}
          >
            <ButtonsGroupSingleChoiceField
              label="Priority "
              name={appointmentFormNameOf("priority")}
              options={priorityOptions}
              required
            />
            <Stack.Item styles={{ root: { width: 120 } }}>
              <DatePickerField
                label="List expires"
                name={appointmentFormNameOf("expiryDate")}
                required
              />
            </Stack.Item>
            <CheckboxField
              label="Any provider"
              name={appointmentFormNameOf("anyProvider")}
              styles={{
                root: {
                  marginTop: 40
                }
              }}
            />
          </Fieldset>
        </Stack>
      </FieldCondition>
    </>
  );
};
