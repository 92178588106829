import { fabric } from "fabric";

import { FabricProps } from "./fabric-drawing/FabricDrawing.types.ts";

export interface CanvasImage {
  canvasRef: (canvas: fabric.Canvas) => void;
  backgroundImageUrl?: string | undefined;
  width: number;
  height: number;
  id?: string;
  text?: string;
  initialValue?: string | undefined;
}

export interface DrawingCanvasProps
  extends Pick<FabricProps, "onChange" | "embedImages"> {
  images: CanvasImage[];
  caption: string;
  initialSelectedImage?: number;
  onCanvasSelected?: (imageIndex: number) => void;
  showQuickColourPicker?: boolean;
  isViewOnly?: boolean;
}

export enum BrushKindEnum {
  Pencil = "pencil",
  Line = "line",
  Transparent = "transparent",
  Ellipse = "ellipse"
}

export type BrushKind =
  | BrushKindEnum.Pencil
  | BrushKindEnum.Line
  | BrushKindEnum.Transparent
  | BrushKindEnum.Ellipse;

export type Options = {
  color: string;
  width: number;
  fill: string;
  kind?: BrushKind;
};

export type BackgroundImage = {
  commonName: string;
  type: string;
  src: string;
  width: number;
  height: number;
  displayOrder: number;
  group?: string;
  sex?: string;
};

export enum BackgroundImageGroup {
  Knee = "Knee",
  Wrist = "Wrist",
  Hip = "Hip",
  Foot = "Foot",
  Spine = "Spine",
  Eye = "Eye",
  FullBody = "FullBody",
  Shoulder = "Shoulder",
  Elbow = "Elbow",
  Abdomen = "Abdomen",
  Dermatomes = "Dermatomes",
  CentralNervousSystem = "CentralNervousSystem"
}

export enum DrawingIcons {
  pencil = "Edit",
  cursor = "Move",
  transparent = "InkingTool",
  ellipse = "Ellipse",
  line = "Line",
  thickness = "LineThickness",
  fill = "RectangleShapeSolid",
  undo = "Undo",
  redo = "Redo",
  delete = "Delete",
  nothing = ""
}
