import { FunctionComponent } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import {
  FieldItemStyles,
  mergeFuncStyles,
  OptionsSelectProps,
  useTheme
} from "@bps/fluent-ui";
import { ExposedFieldProps } from "@ui-components/form/FinalForm.tsx";
import { FormItemField } from "@ui-components/form/FormItemField.tsx";
import { getFieldBorderColors } from "@ui-components/form/selects/utils.ts";
import { formatUndefined } from "@ui-components/form/utils.ts";
import {
  LocationsSelect,
  LocationsSelectProps
} from "@ui-components/selects/LocationsSelect.tsx";

type HtmlElementType = HTMLElement;

export type LocationsSelectFieldProps = Omit<
  LocationsSelectProps,
  "label" | "selectedKeys" | "onChangeSelectedKeys"
> & {
  label?: string | JSX.Element;
  fieldItemStyle?: Partial<Pick<FieldItemStyles, "item" | "root">>;
} & ExposedFieldProps<OptionsSelectProps["selectedKeys"], HtmlElementType>;

const LocationsSelectFieldAdapter = (
  props: FieldRenderProps<OptionsSelectProps["selectedKeys"], HtmlElementType> &
    Omit<
      OptionsSelectProps,
      "label" | "selectedKeys" | "onChangeSelectedKeys"
    > & { label?: string }
) => {
  const theme = useTheme();

  const {
    input: { value, onChange, onBlur, onFocus, name },
    meta,
    styles,
    required,
    label,
    disabled,
    fieldItemStyle,
    ...optionsSelectProps
  } = props;

  return (
    <FormItemField name={name} required={required} styles={fieldItemStyle}>
      <LocationsSelect
        onBlur={onBlur}
        onFocus={onFocus}
        {...optionsSelectProps}
        selectedKeys={value as any}
        onChangeSelectedKeys={onChange}
        label={label}
        disabled={disabled}
        required={required}
        styles={mergeFuncStyles(getFieldBorderColors(meta, theme), styles)}
      />
    </FormItemField>
  );
};

export const LocationsSelectField: FunctionComponent<
  LocationsSelectFieldProps
> = props => (
  <Field
    {...props}
    format={formatUndefined(!!props.multiSelect)}
    component={LocationsSelectFieldAdapter}
  />
);
