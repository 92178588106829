import { FC } from "react";

import { Stack } from "@bps/fluent-ui";
import { Contact } from "@stores/practice/models/Contact.ts";

import { PatientCardIds } from "../../../../shared-components/types/patient-card-ids.enum.ts";
import { NoDetailsButton } from "../NoDetailsButton.tsx";
import { ContactMethodListAddresses } from "./ContactMethodListAddresses.tsx";
import { ContactMethodListCommunications } from "./ContactMethodListCommunications.tsx";

export interface ContactMethodListFieldProps {
  showEditIcon: boolean;
  patient: Contact;
  onHandleEditModal: (id?: string) => void;
  isOrganisation?: boolean;
}

export const ContactMethodList: FC<ContactMethodListFieldProps> = ({
  onHandleEditModal,
  showEditIcon,
  isOrganisation,
  patient
}) => {
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {showEditIcon ? (
        <>
          <ContactMethodListAddresses patient={patient} />
          <ContactMethodListCommunications
            patient={patient}
            isOrganisation={isOrganisation}
          />
        </>
      ) : (
        <NoDetailsButton
          sectionId={PatientCardIds.contactMethods}
          actionTitle="Add a contact method"
          noPermissionText="No contact methods"
          secondaryText="Phone numbers, Addresses"
          onHandleEditModal={onHandleEditModal}
        />
      )}
    </Stack>
  );
};
