import { DateTime } from "@bps/utils";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { OrgUnitAvailability } from "@stores/booking/models/OrgUnitAvailability.ts";
import { TimeRange } from "@stores/booking/models/TimeRanges.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";

const HOUR_MARGIN = 2;

export const getWorkWeekViewMin = (
  providerAndLocationHours: TimeRange[] | undefined
): Date => {
  const earliestDateTime = providerAndLocationHours?.reduce(
    (earliest: DateTime | undefined, current) => {
      if (!earliest) {
        return current.from;
      }
      if (current.from.hour < earliest.hour) {
        return current.from;
      }
      if (
        current.from.hour === earliest.hour &&
        current.from.minute < earliest.minute
      ) {
        return current.from;
      }

      return earliest;
    },
    undefined
  );

  if (!earliestDateTime || earliestDateTime.hour <= HOUR_MARGIN) {
    return DateTime.today().toJSDate();
  }

  return earliestDateTime.minus({ hours: 2 }).toJSDate();
};

export const getWorkWeekViewMax = (
  providerAndLocationHours: TimeRange[] | undefined
): Date => {
  const latestDateTime = providerAndLocationHours?.reduce(
    (latest: DateTime | undefined, current) => {
      if (!latest) {
        return current.to;
      }
      if (current.to.hour > latest.hour) {
        return current.to;
      }
      if (
        current.to.hour === latest.hour &&
        current.to.minute > latest.minute
      ) {
        return current.to;
      }

      return latest;
    },
    undefined
  );

  if (!latestDateTime || latestDateTime.hour >= 24 - HOUR_MARGIN) {
    return DateTime.now().endOf("day").toJSDate();
  }

  return latestDateTime.plus({ hours: 2 }).toJSDate();
};

export const calculateWorkWeekRange = (
  date: Date,
  providerAndLocationHours: TimeRange[] | undefined,
  closedExceptionDates: DateTime[]
): Date[] => {
  const dateTime = DateTime.fromJSDate(date).startOf("week");

  const daysToDisplay = [
    dateTime.toJSDate(),
    dateTime.plus({ days: 1 }).toJSDate(),
    dateTime.plus({ days: 2 }).toJSDate(),
    dateTime.plus({ days: 3 }).toJSDate(),
    dateTime.plus({ days: 4 }).toJSDate()
  ];

  const exceptionExistsForDate = (date: DateTime): boolean => {
    return closedExceptionDates.some(exceptionDate =>
      exceptionDate.hasSame(date, "day")
    );
  };

  const saturday = dateTime.plus({ days: 5 });
  const isOpenSaturday = providerAndLocationHours?.some(
    timeRange => timeRange.from.weekday === 6
  );

  if (isOpenSaturday || exceptionExistsForDate(saturday)) {
    daysToDisplay.push(saturday.toJSDate());
  }

  const sunday = dateTime.plus({ days: 6 });
  const isOpenSunday = providerAndLocationHours?.some(
    timeRange => timeRange.from.weekday === 7
  );

  if (isOpenSunday || exceptionExistsForDate(sunday)) {
    daysToDisplay.push(sunday.toJSDate());
  }

  return daysToDisplay;
};

export const filterEventsForWorkWeek = (
  events: CalendarEvent[],
  timeRanges: TimeRange[] | undefined
): CalendarEvent[] => {
  const min = DateTime.fromJSDate(getWorkWeekViewMin(timeRanges));

  const max = DateTime.fromJSDate(getWorkWeekViewMax(timeRanges));

  return events.filter(event => {
    const start = event.startDateTime;
    const end = event.endDateTime;

    // Note: While min and max are datetimes, the date part should be ignored

    if (
      end.hour < min.hour ||
      (end.hour === min.hour && end.minute <= min.minute)
    ) {
      return false;
    }

    if (
      start.hour > max.hour ||
      (start.hour === max.hour && start.minute >= max.minute)
    ) {
      return false;
    }

    return true;
  });
};

export const getClosedExceptionDates = (
  userIds: string[],
  userAvailabilityMap: Map<string, UserAvailabilityModel>,
  orgUnitAvailability?: OrgUnitAvailability
): DateTime[] => {
  const closedExceptionDates: DateTime[] = [];

  userIds.forEach(userId => {
    const userAvailability = userAvailabilityMap.get(userId);
    userAvailability?.scheduleOverrides.forEach(override => {
      if (!override.isAvailable) {
        closedExceptionDates.push(DateTime.fromISO(override.startDate));
      }
    });
  });

  orgUnitAvailability?.openingHoursOverrides.forEach(override => {
    if (override.isClosed) {
      closedExceptionDates.push(DateTime.fromISO(override.startDate));
    }
  });

  return closedExceptionDates;
};
