import { Heading, useTheme } from "@bps/fluent-ui";

import { FeeFormFeeTypeField } from "./fees/FeeFormFeeTypeField.tsx";
import { FeeFormFields } from "./fees/FeeFormFields.tsx";
import { FeeFormNewFeeDetailFields } from "./fees/FeeFormNewFeeDetailFields.tsx";
import { ScheduleFormFields } from "./ScheduleFormFields.tsx";

export const NewScheduleFields: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <ScheduleFormFields />
      <div
        style={{
          borderBottom: `1px solid ${theme.palette.neutralLight}`,
          marginTop: 24
        }}
      />
      <Heading
        variant="modal-heading"
        styles={{ root: { marginTop: 24, marginBottom: 24 } }}
      >
        New fee
      </Heading>
      <FeeFormFields />
      <FeeFormNewFeeDetailFields />
      <FeeFormFeeTypeField />
    </>
  );
};
