import { computed } from "mobx";

import {
  ClinicalDataType,
  EncounterClinicalDataDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import {
  toBoolObservationFromString,
  toStringFromBoolObservation
} from "../../family-social-history.utils.ts";
import { PhysicalActivityFormValues } from "../PhysicalActivityForm.types.ts";

export class PhysicalActivityFormHelper {
  constructor(public clinicalRecord: ClinicalRecord) {}

  @computed
  get stashedPhysicalActivity() {
    return this.clinicalRecord.stashedClinicalData?.physicalActivity;
  }

  @computed
  get hasBeenReset() {
    return !!this.clinicalRecord.stashedClinicalData?.haveBeenResetForms.get(
      ClinicalDataType.PhysicalActivity
    );
  }

  public getInitialValues = (): PhysicalActivityFormValues => {
    const currentData = this.hasBeenReset
      ? this.clinicalRecord.clinicalData?.physicalActivity
      : this.stashedPhysicalActivity;

    return {
      isEliteAthlete: toStringFromBoolObservation(
        currentData?.isEliteAthlete?.observed
      ),
      hoursSpentAtSedentary:
        currentData && !!currentData.hoursSpentAtSedentary
          ? currentData.hoursSpentAtSedentary.toString()
          : undefined,
      activities:
        currentData?.activities?.map(x => {
          return {
            ...x,
            sessionsPerWeek: x.sessionsPerWeek.toString(),
            sessionLength: x.sessionLength.toString()
          };
        }) ?? [],
      dominantHand: currentData?.dominantHand
    };
  };

  public getPhysicalActivityClinicalData = (
    values: PhysicalActivityFormValues
  ): EncounterClinicalDataDto => {
    const originalDto = this.clinicalRecord.stashedClinicalData?.originalDto;
    return {
      physicalActivity: {
        ...this.stashedPhysicalActivity,
        activities: values.activities.map((x: any) => {
          return {
            ...x,
            sessionsPerWeek: Number(x.sessionsPerWeek),
            sessionLength: Number(x.sessionLength)
          };
        }),
        hoursSpentAtSedentary: Number(values.hoursSpentAtSedentary),
        isEliteAthlete: toBoolObservationFromString(values.isEliteAthlete),
        dominantHand: values.dominantHand,
        eTag: originalDto?.physicalActivity?.eTag
      },
      physicalActivityConfirmed: {
        eTag: originalDto?.physicalActivityConfirmed?.eTag,
        confirmed: true
      }
    };
  };

  public submitData = async (valued: PhysicalActivityFormValues) => {
    const payload = this.getPhysicalActivityClinicalData(valued);
    await this.clinicalRecord.saveClinicalData(payload);
  };
}
