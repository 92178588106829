import { observer } from "mobx-react-lite";
import { FC, useRef } from "react";

import {
  AnonymousCredential,
  BlockBlobClient,
  ContainerClient,
  newPipeline
} from "@azure/storage-blob";
import {
  FieldItem,
  IPersonaProps,
  ProfilePhotoPicker,
  ProfilePhotoPickerProps,
  useResizeImage,
  useTheme
} from "@bps/fluent-ui";
import { GetSasUriResponseDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

export interface PracticeProfilePictureProps
  extends Omit<IPersonaProps, "styles" | "onChange"> {
  contact: Contact | undefined;
  imageInitials: string | undefined;
  profilePictureUrl: string | undefined;
  onRenderNoImageInitials?: IPersonaProps["onRenderInitials"];
  styles?: ProfilePhotoPickerProps["styles"];
  onChange: (url: string | undefined) => void;
  addPhotoLinkText?: string;
  removePhotoLinkText?: string;
  profilePictureStagingId: React.MutableRefObject<string | undefined>;
}

export enum PraticeDetailsabel {
  UploadPhotoFailed = "Upload profile picture failed",
  Retry = "Retry",
  Uploading = "Uploading ...",
  newLogo = "New logo",
  removeLogo = "Remove logo"
}

export const PracticeProfilePicture: FC<PracticeProfilePictureProps> = observer(
  ({
    contact,
    imageInitials,
    profilePictureUrl,
    onRenderNoImageInitials,
    styles,
    onChange,
    addPhotoLinkText,
    removePhotoLinkText,
    profilePictureStagingId,
    ...coinProps
  }) => {
    const theme = useTheme();
    const { practice, notification } = useStores();
    const MAX_WIDTH_OR_HEIGHT = 400;
    const {
      handleImageUpload,
      uploadingError,
      setUploadingError,
      setLoading,
      loading
    } = useResizeImage({
      maxSizeMB: 2,
      maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT
    });

    const onPictureRemoved = () => {
      onChange(undefined);
      profilePictureStagingId.current = undefined;
    };

    const stagingPath = useRef<GetSasUriResponseDto>();

    const getContainerClient = async (): Promise<ContainerClient> => {
      stagingPath.current = await practice.getSaSUri();
      return new ContainerClient(
        stagingPath.current.sasUri,
        newPipeline(new AnonymousCredential())
      );
    };

    const getBlockBlobClient = (
      containerClient: ContainerClient
    ): BlockBlobClient =>
      containerClient.getBlockBlobClient(stagingPath.current?.fileStagingId!);

    const handleDropAccepted = async (files: File[]): Promise<void> => {
      // one file only
      if (files?.length > 0) {
        let file = files[0];

        if (file.size > MAX_WIDTH_OR_HEIGHT * MAX_WIDTH_OR_HEIGHT) {
          const resizedImage = await handleImageUpload(file);

          if (resizedImage) {
            file = resizedImage;
          }
        }
        try {
          setLoading(true);

          const containerClient = await getContainerClient();
          // prepare upload
          const aborter = new AbortController();
          const blockBlobClient = getBlockBlobClient(containerClient);

          // upload to browser
          await blockBlobClient.uploadBrowserData(file, {
            abortSignal: aborter.signal,
            blobHTTPHeaders: {
              blobContentType: file.type
            }
          });

          // set as form data
          profilePictureStagingId.current = stagingPath.current?.fileStagingId!;

          const url = URL.createObjectURL(file);

          onChange(url);
        } catch (reason) {
          setUploadingError(reason.message);
          notification.error(PraticeDetailsabel.UploadPhotoFailed);
        } finally {
          setLoading(false);
        }
      }
    };

    return (
      <FieldItem
        name="profile picture"
        errorMessage={uploadingError}
        styles={{
          subComponentStyles: {
            error: { errorMessage: { textAlign: "center", width: 216 } }
          }
        }}
      >
        <ProfilePhotoPicker
          id={contact?.id ?? ""}
          imageUrl={profilePictureUrl}
          onDropAccepted={handleDropAccepted}
          loading={loading}
          hasError={!!uploadingError}
          imageInitials={imageInitials}
          onPictureRemoved={onPictureRemoved}
          onRenderInitials={onRenderNoImageInitials}
          styles={{
            removeIcon: {
              backgroundColor: theme.palette.whiteTranslucent40,
              borderRadius: 2
            },
            container: {
              "&:hover": {
                ".ms-Icon": {
                  backgroundColor: "transparent"
                }
              }
            },
            subComponentStyles: {
              personaCoin: {
                image: { img: { objectFit: "contain" } }
              }
            }
          }}
          addPhotoLinkText={addPhotoLinkText}
          removePhotoLinkText={removePhotoLinkText}
          coinRadius="0%"
          toolTipText="Used in the letterhead, and documents"
          {...coinProps}
        />
      </FieldItem>
    );
  }
);
