import { FunctionComponent } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { getAppointmentStatusColours } from "@modules/booking/screens/booking-calendar/components/shared-components/appointment-status/utils.ts";

interface AppointmentStatusIndicatorProps {
  code: AppointmentStatusCode;
}

export const AppointmentStatusIndicator: FunctionComponent<
  AppointmentStatusIndicatorProps
> = ({ code }) => {
  const theme = useTheme();
  const colours = getAppointmentStatusColours(theme);
  const background = colours(code).dark;
  return (
    <Stack
      styles={{
        root: {
          width: 10,
          height: 10,
          borderRadius: "50%",
          background,
          marginRight: 8
        }
      }}
    />
  );
};
