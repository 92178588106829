import { observer } from "mobx-react-lite";
import { FunctionComponent, MouseEvent, useEffect, useRef } from "react";

import {
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  ScrollablePane,
  Shimmer,
  Spinner,
  Stack,
  Text,
  Tile
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { AppointmentStatus } from "../booking-calendar/components/shared-components/appointment-status/AppointmentStatus.tsx";
import { renderCalendarEventWrapper } from "../booking-calendar/components/utils.tsx";
import { WaitingRoomModel } from "./context/WaitingRoomModel.ts";
import { getWaitingRoomRecords, WaitingRoomRecord } from "./utils.ts";

const WaitingRoomComponent: FunctionComponent = observer(() => {
  const root = useStores();
  const { core, booking, practice } = root;
  const { setCurrentEventData, orgUnitIds } = useBookingCalendarScreenContext();
  const model = useRef(new WaitingRoomModel(root));

  const {
    current: {
      timestamp,
      calendarEventsPromise,
      setAppointments,
      allowedStatusCodes,
      destroy
    }
  } = model;

  useEffect(() => {
    setAppointments();
  }, [setAppointments]);

  useEffect(() => {
    return () => {
      destroy();
    };
  }, [destroy]);

  const renderAppointmentStatus = (record: WaitingRoomRecord) => {
    const { isUrgent, appointmentStatus } = record;
    if (!appointmentStatus) {
      return null;
    }
    return (
      <AppointmentStatus
        code={appointmentStatus}
        showIndicator
        urgent={isUrgent}
      />
    );
  };

  const renderContactLink = (
    { id, preferredName }: Contact,
    calendarEventId: string
  ) => {
    return core.hasPermissions(Permission.EncounterWrite) &&
      (!core.hasPermissions(Permission.LicencingAllowed) ||
        core.hasPermissions(Permission.CreateConsultAllowed)) ? (
      <Navigate
        to={routes.records.appointment.path({
          id,
          calendarEventId
        })}
      >
        {preferredName}
      </Navigate>
    ) : (
      <Text>{preferredName}</Text>
    );
  };

  const onShowCallout = (evt: MouseEvent, event: CalendarEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setCurrentEventData({
      event,
      calloutPosition: evt.nativeEvent
    });
  };

  const onShowContext = (evt: MouseEvent, event: CalendarEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setCurrentEventData({
      event,
      contextualMenuPosition: evt.nativeEvent
    });
  };

  const onRenderRow = (props: IDetailsRowProps) => {
    if (props) {
      const record: WaitingRoomRecord = props.item;
      const { calendarEventId } = record;

      return (
        <DataFetcher<CalendarEvent>
          fetch={({ booking }) => booking.getCalendarEvent(calendarEventId)}
        >
          {calendarEvent => {
            return renderCalendarEventWrapper(
              {
                ...props,
                item: {
                  ...props.item,
                  contact: calendarEvent.contact,
                  user: calendarEvent.user
                }
              },
              {
                onShowCallout: evt => onShowCallout(evt, calendarEvent),
                onShowContext: evt => onShowContext(evt, calendarEvent)
              }
            );
          }}
        </DataFetcher>
      );
    }
    return null;
  };

  const renderPatient = (record: WaitingRoomRecord) => {
    const contact = practice.contactsMap.get(record.patientId);

    return contact ? (
      <Text data-calendar-event-id={record.calendarEventId}>
        {renderContactLink(contact, record.calendarEventId)}
      </Text>
    ) : (
      <Shimmer />
    );
  };

  const renderAppointmentType = (record: WaitingRoomRecord) => {
    const { appointmentTypeId, calendarEventId } = record;

    return appointmentTypeId ? (
      <DataFetcher<CalendarEvent>
        fetch={({ booking }) => booking.getCalendarEvent(calendarEventId)}
        fallback={<Spinner styles={{ root: { margin: "0 auto" } }} />}
      >
        {calendarEvent => {
          const groupDescription = calendarEvent.groupDescription;
          return (
            <Text>
              {groupDescription ??
                booking.appointmentTypesMap.get(appointmentTypeId)?.name}
            </Text>
          );
        }}
      </DataFetcher>
    ) : (
      <Shimmer />
    );
  };

  const renderProvider = (record: WaitingRoomRecord) => {
    const { providerId } = record;
    if (!providerId) {
      return <Text />;
    }

    const provider = core.userMap.get(providerId);
    return provider ? <Text>{provider.fullName}</Text> : <Shimmer />;
  };

  const columns: IColumn[] = [
    {
      fieldName: "Name",
      key: "name",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 300,
      name: "Name",
      onRender: renderPatient,
      isResizable: true
    },
    {
      fieldName: "status",
      key: "status",
      minWidth: 150,
      maxWidth: 150,
      name: "Status",
      onRender: renderAppointmentStatus,
      isResizable: true
    },
    {
      fieldName: "scheduledTime",
      key: "scheduledTime",
      minWidth: 100,
      maxWidth: 100,
      name: "Scheduled"
    },
    {
      fieldName: "arrivedTime",
      key: "arrived",
      minWidth: 100,
      maxWidth: 100,
      name: "Arrived"
    },
    {
      key: "waitingTime",
      minWidth: 100,
      maxWidth: 100,
      name: "Waiting",
      onRender: x => <Text key={timestamp.get()}>{x.waitingTime}</Text>
    },
    {
      fieldName: "lateByTime",
      key: "lateByTime",
      minWidth: 115,
      maxWidth: 115,
      name: "Late by"
    },
    {
      fieldName: "type",
      key: "type",
      minWidth: 80,
      maxWidth: 100,
      name: "Type",
      onRender: renderAppointmentType,
      isResizable: true
    },
    {
      fieldName: "provider",
      key: "provider",
      minWidth: 100,
      maxWidth: 200,
      name: "Provider",
      onRender: renderProvider,
      isResizable: true
    }
  ];

  if (core.hasMultipleActiveLocations) {
    columns.push({
      fieldName: "location",
      key: "location",
      minWidth: 150,
      maxWidth: 200,
      name: "Location",
      onRender: (record: WaitingRoomRecord) => (
        <Text>{core.getLocationName(record.orgUnitId)}</Text>
      ),
      isResizable: true
    });
  }

  const content = (
    <Stack
      horizontal
      grow
      styles={{
        root: {
          position: "relative",
          height: "100%"
        }
      }}
    >
      <ScrollablePane>
        <ShimmeredDetailsList
          errorMessage={calendarEventsPromise.error?.message}
          enableShimmer={calendarEventsPromise.pending}
          items={
            model.current.calendarEvents
              ? getWaitingRoomRecords(
                  model.current.calendarEvents,
                  allowedStatusCodes
                ).filter(x => orgUnitIds.includes(x.orgUnitId))
              : []
          }
          setKey="items"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          columns={columns}
          onRenderRow={onRenderRow}
          detailsListStyles={{
            root: {
              overflow: "visible"
            }
          }}
        />
      </ScrollablePane>
    </Stack>
  );

  return <Tile styles={{ root: { flexGrow: 1 } }}>{content}</Tile>;
});

export const WaitingRoomScreen = withFetch(
  x => [x.booking.ref.calendarEventTypes.load()],
  WaitingRoomComponent
);
