import { MonthYearRecurrence } from "@libs/constants/month-year-recurrence.enum.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export enum RecurrenceFormLabels {
  patient = "Patient",
  type = "Type",
  endSchedule = "End schedule",
  occurrence = "Occurrence",
  startTime = "Start time",
  endTime = "End time",
  recurEvery = "Recur every",
  on = "On",
  purpose = "Purpose",
  startSchedule = "Start schedule"
}
export interface RecurrenceFormValues {
  bookedBy: string;
  orgUnitId: string;
  patientId: string;
  providerId: string;
  calendarEventType: CalendarEventType;
  startDate: Date;
  endScheduleType: string;
  startTime: string;
  endTime: string;
  purpose?: string;
  reasonForNotWorking?: string;
  interval: number;
  frequency: number;
  count?: number;
  until?: Date;
  dayRecur?: number[];
  monthRecur?: number[];
  monthDayRecur?: number[];
  weekPosition?: number;
  monthYearRecurrence?: MonthYearRecurrence;
}
