import { useEffect, useRef } from "react";

import { Spinner } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentHelper } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.ts";
import { ClaimDataFetcher } from "@modules/acc/screens/shared-components/ClaimDataFetcher.tsx";
import { Claim } from "@stores/acc/models/Claim.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ClaimAdjustmentForm } from "./components/ClaimAdjustmentForm.tsx";
import { ClaimAdjustmentFormHeader } from "./components/ClaimAdjustmentFormHeader.tsx";

interface ClaimAdjustmentEditScreenProps {
  claim: Claim;
  claimAdjustmentId?: string;
}

export const ClaimAdjustmentEditScreenBase: React.FC<
  ClaimAdjustmentEditScreenProps
> = ({ claim, claimAdjustmentId }) => {
  const root = useStores();

  const claimHelper = useRef<ClaimAdjustmentHelper>(
    new ClaimAdjustmentHelper(root, claim, {
      claimAdjustmentId,
      subscribeToClaimAdjustmentEvent: true
    })
  );

  const { destroy } = claimHelper.current;

  useEffect(() => {
    return () => {
      destroy();
    };
  }, [destroy]);

  const header = <ClaimAdjustmentFormHeader />;

  return (
    <ClaimAdjustmentContext.Provider value={claimHelper.current}>
      <ClaimAdjustmentForm header={header} />
    </ClaimAdjustmentContext.Provider>
  );
};

const ClaimAdjustmentEditScreenDataFetcher: React.FC = props => {
  const { routing } = useStores();
  const claimAdjustmentId = routes.claimAdjustment.edit.match(
    routing.location.pathname
  )?.params.id;

  return (
    <ClaimDataFetcher
      fallback={<Spinner />}
      fetchProvider
      claimAdjustmentId={claimAdjustmentId}
    >
      {(claim: Claim) => (
        <ClaimAdjustmentEditScreenBase
          claim={claim}
          claimAdjustmentId={claimAdjustmentId}
          {...props}
        />
      )}
    </ClaimDataFetcher>
  );
};

const ClaimAdjustmentEditScreen = withFetch(
  x => [x.acc.ref.levelTwoEthnicities.load()],
  ClaimAdjustmentEditScreenDataFetcher
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClaimAdjustmentEditScreen;
