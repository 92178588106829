import { memo, useState } from "react";

import {
  Checkbox,
  CSSTransition,
  dataAttribute,
  DataAttributes,
  DetailsList,
  DetailsRow,
  fadeEnter,
  fadeEnterActive,
  fadeExit,
  fadeExitActive,
  FontSizes,
  IDetailsRowProps,
  MoreLessToggle,
  Spinner,
  Stack,
  Text,
  Tile
} from "@bps/fluent-ui";
import {
  FeeType,
  InvoiceItemDto,
  ServiceSearchDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getDetailsRowStyles, tileStyles } from "./AddServicesModal.styles.tsx";
import { ServiceableItem } from "./AddServicesModal.types.ts";
import { SelectedServiceTitleQuantity } from "./SelectedServiceTitleQuantity.tsx";

export interface PreviouslyUsedServicesProps {
  patientId: string;
  onItemsChecked: (item: ServiceSearchDto) => void;
  checkedItemsIds: string[];
  invoiceDate: Date;
  serviceableItem: ServiceableItem;
}

export const PreviouslyUsedServices: React.FC<PreviouslyUsedServicesProps> =
  memo(
    ({
      patientId,
      onItemsChecked,
      checkedItemsIds,
      invoiceDate,
      serviceableItem
    }) => {
      const title =
        serviceableItem === ServiceableItem.Invoice
          ? "Previously billed items"
          : "Previously used items";

      const basedOnText = (patientName?: string) => {
        const basedOn =
          serviceableItem === ServiceableItem.Invoice ? "invoices" : "consults";

        return `Your last 3 ${basedOn} with ${patientName} charged the following items`;
      };

      const [drawerClosed, setDrawerClosed] = useState<boolean>(true);
      const handleDrawerClosed = () => {
        setDrawerClosed(prevState => !prevState);
      };

      const renderRow = (props: IDetailsRowProps): JSX.Element => {
        return (
          <div
            onClick={e => {
              e?.preventDefault();
              onItemsChecked(props.item as ServiceSearchDto);
            }}
          >
            <DetailsRow styles={getDetailsRowStyles} {...props} />
          </div>
        );
      };

      return (
        <DataFetcher<{
          patient: Contact;
          items: ServiceSearchDto[];
          previousItems: InvoiceItemDto[];
        }>
          key={`${patientId}-previouslyBilledItemList`}
          fetch={({ billing }) =>
            billing.getContactWithServices(patientId!, invoiceDate)
          }
          fallback={<Spinner />}
        >
          {({
            patient,
            items,
            previousItems
          }: {
            patient: Contact;
            items: ServiceSearchDto[];
            previousItems: InvoiceItemDto[];
          }) => {
            if (items.length > 0) {
              return (
                <Tile
                  styles={tileStyles}
                  {...dataAttribute(
                    DataAttributes.Element,
                    "previously-used-services"
                  )}
                >
                  <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    styles={{ root: { marginBottom: 16 } }}
                  >
                    <Text styles={{ root: { fontSize: FontSizes.large } }}>
                      {title}
                    </Text>
                    <MoreLessToggle
                      value={!drawerClosed}
                      onChanged={handleDrawerClosed}
                      linkProps={{
                        moreStateText: "Hide",
                        lessStateText: "Show"
                      }}
                    />
                  </Stack>

                  <CSSTransition
                    in={!drawerClosed}
                    unmountOnExit
                    timeout={300}
                    classNames={{
                      enter: fadeEnter,
                      enterActive: fadeEnterActive,
                      exit: fadeExit,
                      exitActive: fadeExitActive
                    }}
                  >
                    <Stack tokens={{ childrenGap: 16 }} verticalAlign="end">
                      <Text>{basedOnText(patient.name)}</Text>

                      <DetailsList
                        setKey={`billedItemsList${items.length}`}
                        items={items}
                        isHeaderVisible={false}
                        columns={[
                          {
                            name: "Checkbox",
                            key: "checkbox",
                            minWidth: 60,
                            isResizable: true,
                            maxWidth: 60,
                            onRender: (item: ServiceSearchDto) => (
                              <Checkbox
                                automationAttribute="previous-user-service-item-checkbox"
                                checked={checkedItemsIds.includes(
                                  item.serviceId
                                )}
                                inputProps={{
                                  value: checkedItemsIds
                                    .includes(item.serviceId)
                                    .toString()
                                }}
                                onChange={() => onItemsChecked(item)}
                              />
                            )
                          },
                          {
                            name: "Item",
                            key: "itemCode",
                            minWidth: 60,
                            maxWidth: 60,
                            onRender: (item: ServiceSearchDto) => item.code
                          },
                          {
                            name: "Description",
                            key: "description",
                            minWidth: 200,
                            maxWidth: 850,
                            onRender: (
                              item: ServiceSearchDto,
                              quantity?: number,
                              previousAmount?: number
                            ) => (
                              <Stack
                                horizontal
                                horizontalAlign="space-between"
                                verticalAlign="center"
                                tokens={{ childrenGap: 6 }}
                              >
                                <Stack
                                  horizontal
                                  horizontalAlign="start"
                                  tokens={{ childrenGap: 6 }}
                                  verticalAlign="center"
                                >
                                  <Text
                                    block
                                    className="clampTwoLines"
                                    styles={{
                                      root: {
                                        overflow: "hidden"
                                      }
                                    }}
                                  >
                                    {item.name || item.description}
                                  </Text>
                                  {item.feeType !== FeeType.FlatRate && (
                                    <SelectedServiceTitleQuantity
                                      feeType={item.feeType}
                                      quantity={quantity?.toString() || ""}
                                      isExtended={false}
                                    />
                                  )}
                                </Stack>
                                <Text>
                                  {currencyFormat(previousAmount || 0)}
                                </Text>
                              </Stack>
                            )
                          }
                        ].map(c => ({
                          ...c,
                          onRender: item => {
                            const quantity = previousItems.find(
                              x => x.code === item.code
                            )?.quantity;

                            const previousAmount = previousItems.find(
                              x => x.code === item.code
                            )?.amount;
                            return (
                              <Stack
                                styles={{ root: { width: "100%" } }}
                                {...dataAttribute(
                                  DataAttributes.Element,
                                  c.key
                                )}
                              >
                                {c.onRender
                                  ? c.onRender(item, quantity, previousAmount)
                                  : c.name}
                              </Stack>
                            );
                          }
                        }))}
                        onRenderRow={renderRow}
                      />
                    </Stack>
                  </CSSTransition>
                </Tile>
              );
            }

            return null;
          }}
        </DataFetcher>
      );
    }
  );
