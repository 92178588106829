import { required } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import { PatientMergeFieldValues } from "./PatientMergeFieldValues.ts";

export class PatientMergeValidator extends Validator<PatientMergeFieldValues> {
  constructor() {
    super();
    this.forField("masterId", required());
    this.forField("duplicateId", required());
  }
}
