import { observer } from "mobx-react-lite";

import { MessageBar, MessageBarType, Stack } from "@bps/fluent-ui";
import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

export const ClinicalRecordNotifications: React.FC = observer(() => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const { core } = useStores();
  const claim = clinicalRecord.calendarEvent?.claim;

  const contextID =
    clinicalRecord.clinical.ui.tabs.currentPatientRecordTab?.activeTab
      ?.contextId;

  const context = contextID?.split("::");

  const DEFAULT_PROVIDER_ERROR: string =
    "The provider type for this discharge does not match yours. Please open the correct discharge.";

  const shouldShowProviderError = () => {
    if (
      clinicalRecord.clinical.ui.tabs.currentPatientRecordTab?.activeTab
        ?.type !== ClinicalDataType.Discharge
    ) {
      return false;
    }

    if (context) {
      const businessCode = context[1];
      return clinicalRecord.core.primaryBusinessRole?.code !== businessCode;
    }

    return false;
  };

  const getProviderErrorMessage = () => {
    if (context) {
      const businessCode = context[1];
      const roleName = clinicalRecord.core.catalogBusinessRoles.find(
        x => x.code === businessCode
      );

      return roleName
        ? `The provider type for this discharge is ${roleName?.text}. Please open the correct discharge.`
        : DEFAULT_PROVIDER_ERROR;
    }

    return "";
  };

  const padding = core.hasPermissions(Permission.UIRedesignAllowed) ? -3 : -16;

  return (
    <When permission={Permission.MultiProviderClaimsAllowed}>
      <Stack.Item
        styles={{
          root: {
            marginBottom: -5,
            marginTop: padding,
            marginLeft: -16,
            marginRight: -16
          }
        }}
        grow={0}
      >
        {claim && clinicalRecord.is1stMultiRoleConsult() && (
          <MessageBar messageBarType={MessageBarType.warning}>
            Additional provider type for
            {claim.claimNumber
              ? ` claim ${claim.claimNumber}`
              : " this claim"}{" "}
            — multiple notes, outcome measures, and discharges will be generated
          </MessageBar>
        )}

        {shouldShowProviderError() &&
          !core.hasPermissions(
            Permission.ConditionDischargeOnBehalfAllowed
          ) && (
            <MessageBar messageBarType={MessageBarType.error}>
              {getProviderErrorMessage()}
            </MessageBar>
          )}
      </Stack.Item>
    </When>
  );
});
