import { FC } from "react";

import { FontIcon, Stack, Text } from "@bps/fluent-ui";
import { useClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";

import { OverviewSectionListIcon } from "./OverviewSectionListIcon.tsx";

type OverviewSectionListItemProps = {
  text: string;
  requestApproved: boolean;
};

export const OverviewSectionListItem: FC<OverviewSectionListItemProps> = ({
  text,
  requestApproved
}) => {
  const { claimAdjustment } = useClaimAdjustmentContext();

  const { responseDate } = claimAdjustment;

  return (
    <Stack
      as="li"
      verticalAlign="center"
      horizontal
      tokens={{ childrenGap: 4 }}
    >
      {!responseDate ? (
        <FontIcon iconName="LocationDot" />
      ) : (
        <OverviewSectionListIcon approved={requestApproved} />
      )}
      <Text>{text}</Text>
    </Stack>
  );
};
