import { observer } from "mobx-react-lite";

import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import PaymentFormScreen from "./AllocationFormScreen.tsx";
import { AllocationViewScreen } from "./AllocationViewScreen.tsx";

const AllocationScreenBase: React.FC = observer(() => {
  const root = useStores();

  const isAllocationForm = !!root.routing.match(
    routes.accounts.allocations.new
  );

  const isAllocationView =
    !isAllocationForm &&
    !!root.routing.match(routes.accounts.allocations.allocation);

  return (
    <>
      {isAllocationForm && <PaymentFormScreen />}
      {isAllocationView && <AllocationViewScreen />}
    </>
  );
});

const AllocationScreen = withFetch(
  x => [
    x.billing.ref.paymentMethods.load(),
    x.practice.ref.invoiceStatuses.load()
  ],
  AllocationScreenBase
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default AllocationScreen;
