import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { Card, Heading, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  ClinicalNoteFormat,
  ClinicalWorkflowFormat,
  DockView,
  PastVisitViewOptions,
  TreeView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ClinicalViewDto,
  PatientSummaryPageDto,
  UpdateUserSettingDto
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import {
  UserCardHeadings,
  UserCardSubHeadings,
  UserPageHeadings
} from "../labels.ts";
import { ConsultationNotesSettingsForm } from "./components/ConsultationNotesSettingsForm.tsx";
import { PatientSummarySettingsForm } from "./components/PatientSummarySettingsForm.tsx";
import { TreeViewSettingsForm } from "./components/TreeViewSettingsForm.tsx";
import {
  IUserClinicalViewItem,
  UserClinicalViewFormValues
} from "./components/UserClinicalViewForm.types.ts";

interface ClinicalScreenProps {
  clinicalView?: ClinicalViewDto;
}

const ClinicalScreenBase: React.FC<ClinicalScreenProps> = observer(
  ({ clinicalView }) => {
    const { userExperience, core } = useStores();
    const { id: userId } = useParams();
    if (!userId) {
      return null;
    }

    const isLoggedInUser = core.userId === userId;
    const allPatientSummaries = (): IUserClinicalViewItem[] => {
      const patientSummariesFilteredOnPermission =
        userExperience.ref.patientSummaries.values?.filter(x => {
          return x.permission ? core.hasPermissions(x.permission) : true;
        });

      return patientSummariesFilteredOnPermission.map(
        x =>
          ({
            key: x.code,
            name: x.text,
            checked: x.isChecked,
            position: x.sortOrder
          }) as IUserClinicalViewItem
      );
    };

    const filterPermission = (patientSummaries?: PatientSummaryPageDto[]) => {
      //If a patient summary hasn't been saved previously, but is still in the reference data of patient summaries
      //then need to add it in to the array of patient summaries.
      const allSummaries = allPatientSummaries();
      return allSummaries
        .map(referenceData => {
          const savedSummary = patientSummaries?.find(
            f => f.key === referenceData.key
          );
          return savedSummary ?? referenceData;
        })
        .sort((item1, item2) => item1.position - item2.position);
    };

    const getInitialValues = (): Partial<UserClinicalViewFormValues> => {
      const workflow =
        !!clinicalView &&
        clinicalView?.clinicalWorkflow !== ClinicalWorkflowFormat.None &&
        core.tenantDetails?.country === Country.NewZealand &&
        core.hasPermissions(Permission.SOTAPInitialEncounter)
          ? ClinicalWorkflowFormat.SOTAP
          : ClinicalWorkflowFormat.None;

      const defaultNotes =
        workflow === ClinicalWorkflowFormat.SOTAP
          ? ClinicalNoteFormat.SOTAP
          : clinicalView?.clinicalNotesFormat || ClinicalNoteFormat.Default;

      const userClinicalTaskSettings =
        userExperience.userSettingMap?.get(userId)?.clinicalTaskSettings;

      const values: Partial<UserClinicalViewFormValues> = {
        defaultDockView: clinicalView?.defaultDockView || DockView.TodaysNotes,
        selectedTreeView:
          clinicalView?.selectedTreeView ||
          (core.tenantDetails?.country === Country.NewZealand
            ? TreeView.Conditions
            : TreeView.Timeline),
        patientSummaryPage: clinicalView?.patientSummaryPage?.length
          ? filterPermission(clinicalView?.patientSummaryPage)
          : allPatientSummaries(),
        onlyShowMyNotes: !!clinicalView?.onlyShowMyNotes,
        pastVisitsReasonViewOption: clinicalView?.pastVisitsReasonAtBottom
          ? PastVisitViewOptions.PastVisitBottom
          : PastVisitViewOptions.PastVisitTop,
        clinicalWorkflow: workflow,
        clinicalNotesFormat: defaultNotes,
        clinicalTaskSettings:
          userClinicalTaskSettings && userClinicalTaskSettings.length > 0
            ? userClinicalTaskSettings
            : userExperience.tenantClinicalTaskSettings,
        enforceDiscardConsultReason:
          clinicalView?.enforceDiscardConsultReason ?? true,
        timerEnabled: clinicalView?.timerEnabled ?? true
      };

      return values;
    };

    const initialValues = getInitialValues();

    const saveData = async (updatedUserSettings: UpdateUserSettingDto) => {
      if (!isLoggedInUser) {
        await userExperience.upsertUserSetting(updatedUserSettings);
      } else {
        await userExperience.upsertCurrentUserSetting(updatedUserSettings);
      }
    };

    const onSubmit = async (values: UserClinicalViewFormValues) => {
      const clinicalView: ClinicalViewDto = {
        defaultDockView: values.defaultDockView,
        patientSummaryPage:
          values.patientSummaryPage as PatientSummaryPageDto[],
        selectedTreeView: values.selectedTreeView,
        onlyShowMyNotes: values.onlyShowMyNotes,
        pastVisitsReasonAtBottom:
          values.pastVisitsReasonViewOption ===
          PastVisitViewOptions.PastVisitBottom,
        clinicalNotesFormat: values.clinicalNotesFormat,
        clinicalWorkflow: values.clinicalWorkflow,
        enforceDiscardConsultReason: values.enforceDiscardConsultReason,
        timerEnabled: values.timerEnabled
      };

      const updatedUserSettings: UpdateUserSettingDto = {
        userId,
        clinicalView
      };
      await saveData(updatedUserSettings);
    };

    return (
      <Stack styles={{ root: { paddingTop: 26 } }} tokens={{ childrenGap: 24 }}>
        <Heading
          styles={{ root: { paddingBottom: 2 } }}
          variant="section-heading"
        >
          {UserPageHeadings.clinical}
        </Heading>
        <Card
          iconName="bulletedTreeList"
          headingLevel="section-heading"
          heading={UserCardHeadings.treeView}
          subHeading={UserCardSubHeadings.treeView}
          styles={{
            subComponentStyles: {
              tile: {
                content: {
                  padding: 0
                }
              }
            }
          }}
        >
          <TreeViewSettingsForm
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </Card>
        <Card
          iconName="editNote"
          heading={UserCardHeadings.consultationNotes}
          headingLevel="section-heading"
          styles={{
            subComponentStyles: {
              tile: {
                content: {
                  padding: 0
                }
              }
            }
          }}
        >
          <ConsultationNotesSettingsForm
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </Card>
        <Card
          iconName="publishCourse"
          heading={UserCardHeadings.patientSummaryView}
          headingLevel="section-heading"
          subHeading={UserCardSubHeadings.patientSummaryView}
          styles={{
            subComponentStyles: {
              tile: {
                content: {
                  padding: 0
                }
              }
            }
          }}
        >
          <PatientSummarySettingsForm
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </Card>
      </Stack>
    );
  }
);

const ClinicalScreenDataFetcher: React.FC = () => {
  const { core } = useStores();
  const { id: userId } = useParams();
  const isAllowedToEdit =
    userId === core.userId || core.hasPermissions(Permission.UserSettingWrite);

  if (!userId || !isAllowedToEdit) {
    return null;
  }

  return (
    <DataFetcher
      key={userId}
      fetch={async ({ userExperience, core }) => {
        const user = await core.getUser(userId);

        const hasPermission = core.checkUserPermissionsFromAPI(user.username, [
          Permission.ClinSettingAllowed
        ]);

        const userSetting =
          userId === core.userId
            ? userExperience.getCurrentUserSetting()
            : userExperience.getUserSetting(userId!);

        return Promise.all([hasPermission, userSetting]);
      }}
    >
      {([hasPermission, userSetting]) => (
        <>
          {hasPermission ? (
            <ClinicalScreenBase clinicalView={userSetting?.clinicalView} />
          ) : null}
        </>
      )}
    </DataFetcher>
  );
};

const ClinicalScreen = withFetch(
  x => [
    x.userExperience.ref.patientSummaries.load(),
    x.userExperience.ref.treeViewOptions.load(),
    x.userExperience.ref.dockViews.load()
  ],
  ClinicalScreenDataFetcher
);

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ClinicalScreen;
