import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Heading,
  Spinner,
  Stack
} from "@bps/fluent-ui";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { AllocationViewContext } from "@modules/billing/screens/allocation/context/AllocationViewContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { EmailConfirmationModal } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.tsx";
import { PromiseObservableButton } from "@ui-components/PromiseObservableButton/PromiseObservableButton.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { CancelTransactionDialog } from "../../shared-components/CancelTransactionDialog.tsx";
import { OptedOutTooltip } from "../../shared-components/OptedOutTooltip.tsx";
import {
  AllocationCancelAccPaymentConfirmationDialog,
  AllocationFormButtons
} from "./AllocationForm.types.tsx";

interface InvoiceViewButtonsProps {
  onClose: () => void;
}

export const AllocationViewButtonsBase: React.FC<InvoiceViewButtonsProps> =
  observer(({ onClose }) => {
    const { billing } = useStores();

    const {
      handleAllocationViewOpenPdf,
      emailDialogVisible,
      setEmailDialogVisible,
      allocation,
      allocationAccountIsAccProvider,
      showCancelDialog,
      setShowCancelDialog,
      handleCancel,
      accPaymentProcessingFeatureEnabled,
      contact
    } = useContext(AllocationViewContext);

    const handleGetPdf = async () => {
      await handleAllocationViewOpenPdf(allocation.id);
    };

    const isEmailOrPdfDisabled =
      allocation.items.length === 0 || allocation.isCancelled;

    return (
      <>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
          {!allocation.isCancelled && (
            <When permission={Permission.PaymentCreate}>
              <PromiseObservableButton
                id="cancel-allocation"
                text={AllocationFormButtons.cancel}
                onClick={() => {
                  setShowCancelDialog(true);
                }}
              />
            </When>
          )}
          {!allocation.isCancelled && allocation.items.length > 0 && (
            <When permission={Permission.PaymentCreate}>
              <OptedOutTooltip contact={contact}>
                <DefaultButton
                  text="Send email"
                  iconProps={{ iconName: "Mail" }}
                  onClick={() => setEmailDialogVisible(true)}
                  disabled={isEmailOrPdfDisabled || contact?.billingOptedOut}
                />
              </OptedOutTooltip>
              <DefaultButton
                {...dataAttribute(
                  DataAttributes.Element,
                  "get-allocation-invoice-pdf-button"
                )}
                text={AllocationFormButtons.pdf}
                iconProps={{ iconName: "pdf" }}
                onClick={handleGetPdf}
                disabled={isEmailOrPdfDisabled}
              />
            </When>
          )}
          <DefaultButton
            {...dataAttribute(
              DataAttributes.Element,
              "close-allocation-button"
            )}
            text={AllocationFormButtons.close}
            onClick={onClose}
          />
        </Stack>
        {emailDialogVisible && (
          <EmailConfirmationModal
            accountContactId={allocation.accountId}
            onDismiss={() => {
              setEmailDialogVisible(false);
            }}
            onSubmit={async ({ email }) => {
              await billing.sendAllocationEmail(allocation.id, email);
              setEmailDialogVisible(false);
              onClose();
            }}
            commType={OutboundCommType.Receipt}
          />
        )}
        <DataFetcher
          fetch={allocationAccountIsAccProvider}
          fallback={<Spinner />}
        >
          {(allocationAccountIsAccProvider: boolean) => (
            <CancelTransactionDialog
              hidden={!showCancelDialog}
              onCancel={() => setShowCancelDialog(false)}
              onConfirm={reason => {
                handleCancel(reason).then(onClose);
              }}
              dialogContentProps={
                accPaymentProcessingFeatureEnabled &&
                allocationAccountIsAccProvider
                  ? {
                      title: (
                        <Heading variant="modal-heading">
                          {AllocationCancelAccPaymentConfirmationDialog.title}
                        </Heading>
                      ),
                      showCloseButton: true,
                      subText:
                        AllocationCancelAccPaymentConfirmationDialog.message
                    }
                  : undefined
              }
              transactionType="allocation"
            />
          )}
        </DataFetcher>
      </>
    );
  });

export const AllocationViewButtons = withFetch(
  x => [x.billing.ref.transactionCancelReason.load()],
  AllocationViewButtonsBase
);
