import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import React from "react";

import {
  AddBox,
  Card,
  dataAttribute,
  DataAttributes,
  DefaultButton,
  FontSizes,
  FontWeights,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { UserUnavailabilityModel } from "@stores/booking/models/UserUnavailabilityModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { UserCardHeadings } from "../../labels.ts";
import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import {
  UserReservesFormTestElements,
  UserReservesFormValues
} from "./UserReserves.types.ts";
import { UserReservesFieldGroup } from "./UserReservesFieldGroup.tsx";
import { UserReservesFormValidator } from "./UserReservesFormValidator.ts";
import {
  getEmptyUserReserveValues,
  prepareFormValues,
  toUserReservesFormValues
} from "./utils.ts";

const nameOf = nameOfFactory<UserReservesFormValues>();

type UserReservesFormProps = {
  providerId: string;
  userUnavailability: UserUnavailabilityModel;
};

const validator = new UserReservesFormValidator();

const UserReservesFormBase: React.FC<UserReservesFormProps> = observer(
  props => {
    const { core, booking, notification } = useStores();
    const theme = useTheme();

    const { providerId, userUnavailability } = props;

    const userReserves = toUserReservesFormValues(
      userUnavailability?.calendarEventRecurrences || []
    );

    const initialValues = {
      currentUserReserves: userReserves.currentUserReserves,
      previousUserReserves: userReserves.previousUserReserves
    };

    const { formFooter } = getUserStylesSet(theme);

    const handleAddCurrentUserReserves = (
      form: FormApi<UserReservesFormValues>
    ) => {
      const userReservesValues = getEmptyUserReserveValues(
        core.userId,
        core.locationId
      );

      let formStateCurrentUserReserves =
        form?.getState()?.values.currentUserReserves;

      if (formStateCurrentUserReserves) {
        formStateCurrentUserReserves = [
          ...formStateCurrentUserReserves,
          userReservesValues
        ];
      } else {
        formStateCurrentUserReserves = [userReservesValues];
      }

      form.change("currentUserReserves", formStateCurrentUserReserves);
    };

    const handleSubmit = async (values: UserReservesFormValues) => {
      await booking.updateUserUnavailability({
        userId: userUnavailability?.userId!,
        calendarEventRecurrences: [
          ...values.currentUserReserves.map(x =>
            prepareFormValues(x, {
              userId: providerId,
              locationId: core.locationId,
              loggedInUserId: core.userId
            })
          ),
          ...values.previousUserReserves.map(x =>
            prepareFormValues(x, {
              userId: providerId,
              locationId: core.locationId,
              loggedInUserId: core.userId
            })
          )
        ]
      });
    };

    const renderEmptyReserves = (
      form: FormApi<UserReservesFormValues, Partial<UserReservesFormValues>>
    ) => {
      return core.hasUserSettingWritePermission ? (
        <AddBox
          text="No current reserves"
          subText="Use the button below to begin adding records to this area"
          buttonText="Add reserves"
          onClick={() => handleAddCurrentUserReserves(form)}
          styles={{ root: { padding: "32px 64px" } }}
          {...dataAttribute(
            DataAttributes.Element,
            UserReservesFormTestElements.AddReserveBodyButton
          )}
        />
      ) : (
        <Stack
          horizontalAlign="center"
          styles={{ root: { padding: "32px 64px" } }}
        >
          <Text
            styles={{
              root: {
                fontWeigh: FontWeights.semibold,
                fontSize: FontSizes.size18,
                paddingBottom: 8
              }
            }}
          >
            No current reserves
          </Text>
        </Stack>
      );
    };

    return (
      <SubmissionForm<UserReservesFormValues>
        formName="user-reserves"
        readOnly={!core.hasUserSettingWritePermission}
        styles={{
          main: { backgroundColor: "none", overflowY: "visible" },
          fields: { overflowY: "visible" }
        }}
        onSubmit={handleSubmit}
        onSubmitSucceeded={() =>
          notification.success("Reserves have been updated.")
        }
        validate={validator.validate}
        initialValues={initialValues}
        hideButtons
      >
        {({ form, values, dirty }) => (
          <Stack tokens={{ childrenGap: 24 }}>
            <Card
              iconName="dateTimeMirrored"
              headingLevel="section-heading"
              heading={UserCardHeadings.reserves}
              styles={{
                subComponentStyles: {
                  tile: {
                    content: {
                      padding: 0
                    }
                  }
                }
              }}
              button={
                values.currentUserReserves.length > 0 &&
                core.hasUserSettingWritePermission ? (
                  <DefaultButton
                    onClick={() => handleAddCurrentUserReserves(form)}
                    iconProps={{ iconName: "Add" }}
                    {...dataAttribute(
                      DataAttributes.Element,
                      UserReservesFormTestElements.AddReserveCardButton
                    )}
                  >
                    Add reserves
                  </DefaultButton>
                ) : null
              }
            >
              {values.currentUserReserves.length === 0 ? (
                renderEmptyReserves(form)
              ) : (
                <FieldArray name={nameOf("currentUserReserves")}>
                  {({ fields }) => (
                    <div
                      {...dataAttribute(
                        DataAttributes.Element,
                        UserReservesFormTestElements.UserReservesForm
                      )}
                    >
                      {fields.map((name, index) => {
                        return (
                          <UserReservesFieldGroup
                            name={name}
                            key={name}
                            index={index}
                            removeField={(index: number) => {
                              fields.remove(index);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
              )}
              <FormSubmitButtons
                submitButtonProps={{
                  id: "submit-reserves",
                  disabled:
                    (!dirty && values.currentUserReserves.length > 0) ||
                    (!dirty &&
                      values.currentUserReserves.length === 0 &&
                      initialValues.currentUserReserves.length === 0)
                }}
                disableCancelOnPristine={true}
                promptOnCancel={true}
                styles={() => ({
                  root:
                    dirty || initialValues.currentUserReserves.length > 0
                      ? formFooter
                      : { display: "none" }
                })}
              />
            </Card>
            <Card
              iconName="history"
              headingLevel="section-heading"
              heading={UserCardHeadings.previousReserves}
              styles={{
                subComponentStyles: {
                  tile: {
                    content: {
                      padding: 0
                    }
                  }
                }
              }}
            >
              {values.previousUserReserves.length === 0 && (
                <Stack
                  horizontalAlign="center"
                  styles={{ root: { padding: "32px 64px" } }}
                >
                  <Text
                    styles={{
                      root: {
                        fontWeigh: FontWeights.semibold,
                        fontSize: 18,
                        paddingBottom: 8
                      }
                    }}
                  >
                    Expired reserves populate here
                  </Text>
                  <Text>
                    This section will begin to populate as records expire
                  </Text>
                </Stack>
              )}
              <div
                {...dataAttribute(
                  DataAttributes.Element,
                  UserReservesFormTestElements.PreviousUserReservesForm
                )}
              >
                <FieldArray name={nameOf("previousUserReserves")}>
                  {({ fields }) => (
                    <div
                      {...dataAttribute(
                        DataAttributes.Element,
                        UserReservesFormTestElements.UserReservesForm
                      )}
                    >
                      {fields.map((name, index) => {
                        return (
                          <UserReservesFieldGroup
                            disabled={true}
                            name={name}
                            key={name}
                            index={index}
                            removeField={(index: number) => {
                              fields.remove(index);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
              </div>
            </Card>
          </Stack>
        )}
      </SubmissionForm>
    );
  }
);

export const UserReservesForm: React.FC = () => {
  const { routing } = useStores();

  const userId = routing.match(routes.settings.users.reserves)?.params.id;

  return (
    <DataFetcher<UserUnavailabilityModel>
      fetch={({ booking }) => booking.getUserUnavailability(userId!)}
    >
      {userUnavailability => (
        <UserReservesFormBase
          providerId={userId!}
          userUnavailability={userUnavailability}
        />
      )}
    </DataFetcher>
  );
};
