import { ReactNode } from "react";

import { FontSizes, Stack, Text, TopBarDefaultHeader } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";

import {
  BillingBreadcrumbProps,
  BillingBreadcrumbs
} from "./BillingBreadcrumbs.tsx";
import { TransactionBadgeNew } from "./TransactionBadgeNew.tsx";

interface BreadcrumbProps
  extends Omit<BillingBreadcrumbProps, "transactionNumber"> {
  transactionNumber?: string;
}

export interface BillingHeadingProps {
  breadcrumbProps?: BreadcrumbProps;
  badgeOverride?: ReactNode;
  transaction?: TransactionBase;
  amount?: number;
  date?: Date;
  dateOverride?: ReactNode;
  buttons: ReactNode;
}

export const BillingHeading: React.FC<BillingHeadingProps> = ({
  breadcrumbProps,
  date,
  dateOverride,
  amount,
  buttons,
  transaction,
  badgeOverride
}) => {
  let dateJSX: ReactNode;

  if (dateOverride) {
    dateJSX = dateOverride;
  } else if (date) {
    dateJSX = <Text>{DateTime.fromJSDate(date).toDayDefaultFormat()}</Text>;
  }

  return (
    <TopBarDefaultHeader
      leftContainerStyles={{ root: { padding: 0 } }}
      hideBackButton
      leftContainer={
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 16 }}
          styles={{
            root: {
              fontSize: 18
            }
          }}
        >
          <BillingBreadcrumbs
            transactionNumber={transaction?.number}
            {...breadcrumbProps}
            styles={{
              root: { fontSize: FontSizes.size18, marginTop: 0 },
              listItem: {
                ".breadcrumb-current": {
                  fontSize: FontSizes.size18
                }
              }
            }}
          />
          {amount && (
            <Stack
              styles={(_, theme) => ({
                root: {
                  paddingLeft: 16,
                  borderLeft: `1px solid ${theme.palette.neutralLight}`
                }
              })}
            >
              {currencyFormat(amount)}
            </Stack>
          )}
          {badgeOverride}
          {!badgeOverride && transaction && (
            <TransactionBadgeNew transaction={transaction} />
          )}
          {dateJSX}
        </Stack>
      }
      rightContainer={buttons}
      heading=""
    />
  );
};
