export class WorkingHoursAvailabilityMessages {
  static readonly createPermissionMessage: string =
    "Appointment cannot be created outside working hours";
  static readonly practiceOutsideWorkingHoursMessage: string =
    "This is outside the standard working hours of the practice. Are you sure you want to book outside the standard working hours?";
  static readonly practiceOutsideWorkingHoursTitle: string =
    "Outside practice working hours";
  static readonly providerOutsideWorkingHoursTitle: string =
    "Outside provider working hours";
  static moveNoPermissionMessage = (eventType: string | undefined) =>
    `${eventType} cannot be moved outside working hours.`;
  static providerOutsideWorkingHoursMessage = (userName: string | undefined) =>
    `This is outside the standard working hours of ${userName}. Are you sure you want to book outside the standard working hours?`;
}
