import { memo, useContext } from "react";
import { FormSpy } from "react-final-form";

import { ClaimAddressDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { FormTemplateTypeCode } from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { FormWarningClaimPending } from "@modules/forms/components/FormWarningClaimPending.tsx";
import { FormWarningPartiallyComplete } from "@modules/forms/components/FormWarningPartiallyComplete.tsx";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { ErrorBlock } from "@ui-components/ErrorBlock.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";

import { claimFormNameOf } from "../claim.utils.ts";
import { AccidentAndEmploymentDetailsSection } from "./AccidentAndEmploymentDetails/AccidentAndEmploymentDetailsSection.tsx";
import { ClaimExtraErrorMessages } from "./ClaimFormEnums.ts";
import { InjuryDiagnosisAndAssistanceFormSection } from "./InjuryDiagnosisAndAssistanceFormSection.tsx";
import { PatientDeclarationFormSection } from "./PatientDeclarationFormSection.tsx";
import { PersonalDetailsFormSection } from "./PersonalDetailsFormSection.tsx";
import { ProviderDeclarationFormSection } from "./ProviderDeclarationFormSection.tsx";
import { ReferralsOutFormSection } from "./ReferralsOutFormSection.tsx";

export const ClaimEditFormBody: React.FC = memo(() => {
  const helper = useContext(ClaimContext);
  const { claim } = helper;
  return (
    <>
      {claim.patient?.id && (
        <>
          <FormWarningClaimPending
            patientId={claim.patient.id}
            formTemplateTypes={[
              FormTemplateTypeCode.acc45Demographic,
              FormTemplateTypeCode.acc
            ]}
            context={{
              ClaimId: claim.id,
              PatientId: claim.patient.id
            }}
          />
          <FormWarningPartiallyComplete
            patientId={claim.patient.id}
            formTemplateTypes={[
              FormTemplateTypeCode.acc45Demographic,
              FormTemplateTypeCode.acc
            ]}
            context={{
              ClaimId: claim.id,
              PatientId: claim.patient.id
            }}
          />
        </>
      )}

      <FieldCondition<ClaimAddressDto["street1"]>
        when="providerAddress.street1"
        is={value => !value}
      >
        <ErrorBlock
          errorText={ClaimExtraErrorMessages.LocationAddressMissing}
          linkText={ClaimExtraErrorMessages.UpdateLocationSettings}
          to={{
            pathname: routes.settings.practices.basePath.pattern
          }}
        />
      </FieldCondition>
      <FieldCondition<ClaimFormValues["practiceName"]>
        when={claimFormNameOf("practiceName")}
        is={value => !value}
      >
        <ErrorBlock
          errorText={ClaimExtraErrorMessages.HpiOrgNameMissing}
          linkText={ClaimExtraErrorMessages.UpdatePracticeSettings}
          to={routes.settings.practices.basePath.pattern}
        />
      </FieldCondition>
      <FieldCondition<ClaimFormValues["hpiOrganisationNumber"]>
        when={claimFormNameOf("hpiOrganisationNumber")}
        is={value => !value}
      >
        <ErrorBlock
          errorText={ClaimExtraErrorMessages.HpiOrgIdMissing}
          linkText={ClaimExtraErrorMessages.UpdatePracticeSettings}
          to={routes.settings.practices.basePath.pattern}
        />
      </FieldCondition>
      <PersonalDetailsFormSection />
      <AccidentAndEmploymentDetailsSection />
      <PatientDeclarationFormSection />
      <InjuryDiagnosisAndAssistanceFormSection />
      <ProviderDeclarationFormSection />
      <ReferralsOutFormSection />
      <FormSpy<ClaimFormValues>
        subscription={{ dirty: true, values: true }}
        onChange={({ values }) => {
          helper.claimFormValues = values;
        }}
      />
    </>
  );
});
