import {
  dataAttribute,
  DataAttributes,
  Heading,
  IStyle,
  ITextProps,
  ITheme,
  mergeStyles,
  PersonaSize,
  Stack,
  Text
} from "@bps/fluent-ui";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { getAgeString } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { GenderText, SexText } from "@ui-components/RefText.tsx";

export interface PatientDetailsProps {
  patientId?: string;
  additionalStyles?: IStyle;
}

const personaSummarySecondaryStyle = (_props: ITextProps, theme: ITheme) => ({
  root: {
    fontSize: theme.fonts.small.fontSize,
    fontWeight: theme.fonts.small.fontWeight,
    color: theme.palette.neutralTertiary,
    textOverflow: "hidden"
  }
});

export const PatientDetails: React.FC<PatientDetailsProps> = (
  props
): JSX.Element => {
  const { userExperience } = useStores();
  const patientLabel = userExperience.localisedConfig("patientDisplay");

  const noPatientMessage: JSX.Element = (
    <Text
      variant="small"
      styles={{
        root: {
          overflow: "hidden"
        }
      }}
    >
      {`No ${patientLabel} selected`}
    </Text>
  );

  const getAgeIfAvailable = (patient: Contact): string => {
    if (patient.birthDate)
      return getAgeString(patient.birthDate, patient.dateOfDeath);
    else {
      return "No DOB recorded ";
    }
  };

  const getSexIfAvailable = (patient: Contact): React.ReactNode => {
    if (patient.sex) {
      return <SexText code={patient.sex} />;
    } else {
      return null;
    }
  };

  const getGenderIfAvailable = (patient: Contact): React.ReactNode => {
    if (!patient.sex || !patient.gender) {
      return null;
    }

    return patient.sex.toString() !== patient.gender.toString() ? (
      <GenderText code={patient.gender} />
    ) : null;
  };

  const patientPersona = (patient: Contact): JSX.Element => {
    return (
      <Persona
        text={patient.name}
        id={patient.id}
        imageUrl={patient.profilePictureUrl}
        contactType={patient.type}
        size={PersonaSize.size40}
        imageInitials={patient.initials}
        styles={{
          details: {
            paddingRight: 0
          },
          root: {
            marginTop: 5,
            paddingLeft: 5,
            width: "100%"
          }
        }}
        onRenderSecondaryText={() => (
          <Text
            {...dataAttribute(DataAttributes.Element, "patient-details-text")}
            styles={personaSummarySecondaryStyle}
          >
            {getAgeIfAvailable(patient)}
            {getSexIfAvailable(patient)}
            {getGenderIfAvailable(patient)}
          </Text>
        )}
      />
    );
  };

  return (
    <Stack.Item
      className={mergeStyles(props.additionalStyles)}
      {...dataAttribute(DataAttributes.Element, "patient-details")}
    >
      <Heading>{capitalizeSentence(patientLabel)}</Heading>
      <DataFetcher<Contact | undefined>
        key={props.patientId}
        fetch={({ practice }) =>
          props.patientId
            ? practice.getContact(props.patientId)
            : Promise.resolve(undefined)
        }
      >
        {patient => {
          return <>{patient ? patientPersona(patient) : noPatientMessage}</>;
        }}
      </DataFetcher>
    </Stack.Item>
  );
};
