import { DateTime } from "@bps/utils";
import { nameof } from "@libs/utils/name-of.utils.ts";
import {
  isNotFutureDate,
  Length,
  lessThanOrSame,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  GynaeFieldValues,
  SystemsReviewFormValues
} from "../SystemsReviewForm.types.ts";

export class SystemsReviewFormValidator extends Validator<SystemsReviewFormValues> {
  constructor() {
    super();

    const gynaeFieldsValidator = new GynaeFieldsValidator();
    this.forField(nameof("gynae"), gynaeFieldsValidator.validate);
  }
}

export class GynaeFieldsValidator extends Validator<GynaeFieldValues> {
  constructor() {
    super();

    const date = DateTime.now();

    this.forField(nameof("comments"), [maxLength(Length.comments)]);

    this.forField(nameof("lastMenstrualPeriodDate"), [
      isNotFutureDate(ValidationMessages.futureDateEqualOrLess)
    ]);

    this.forField(nameof("contraceptionType"), [
      predicate((value, values) => {
        return !!values?.usesContraception && !value;
      }, required())
    ]);

    this.forField(nameof("lastBreastScreenDateYear"), [
      predicate((value, values) => {
        return !!values?.lastBreastScreenDateMonth;
      }, required()),
      lessThanOrSame(date.year, ValidationMessages.futureDate)
    ]);

    this.forField(nameof("lastBreastScreenDateMonth"), [
      (value, values) => {
        if (
          value &&
          values.lastBreastScreenDateYear &&
          // If these objects aren't converted to string, they will never be seen as the same.
          values.lastBreastScreenDateYear === date.year &&
          value > date.month
        ) {
          return ValidationMessages.futureDate;
        }

        return undefined;
      }
    ]);

    this.forField(nameof("lastCervicalScreenDateYear"), [
      predicate((value, values) => {
        return !!values?.lastCervicalScreenDateMonth;
      }, required()),
      lessThanOrSame(date.year, ValidationMessages.futureDate)
    ]);

    this.forField(nameof("lastCervicalScreenDateMonth"), [
      (value, values) => {
        if (
          value &&
          values.lastCervicalScreenDateYear &&
          // If these objects aren't converted to string, they will never be seen as the same.
          values.lastCervicalScreenDateYear === date.year &&
          value > date.month
        ) {
          return ValidationMessages.futureDate;
        }

        return undefined;
      }
    ]);
  }
}
