import { observer } from "mobx-react-lite";
import { FunctionComponent, ReactNode } from "react";

import {
  Heading,
  ITag,
  MessageBar,
  MessageBarType,
  PersonaSize
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import {
  CalendarEvent,
  toAttendees
} from "@stores/booking/models/CalendarEvent.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { OccurrenceFormLabels } from "./components/OccurrenceForm.types.tsx";
import { OccurrenceFormValidator } from "./components/OccurrenceFormValidator.ts";
import { OccurrenceFormValues } from "./components/OccurrenceFormValues.ts";

const nameOf = nameOfFactory<OccurrenceFormValues>();
const validator = new OccurrenceFormValidator();

export const OccurrenceDialog: FunctionComponent = observer(() => {
  const { booking, core } = useStores();

  const { setCurrentOccurrenceId, currentOccurrenceId } =
    useBookingCalendarScreenContext();

  const onDismiss = () => {
    setCurrentOccurrenceId(undefined);
  };

  const getInitialValues = (
    occurrence: CalendarEvent
  ): OccurrenceFormValues => {
    return {
      providerId: occurrence.userId,
      calendarEventType: occurrence.type,
      startDate: occurrence.startDateTime.startOf("day").toJSDate(),
      startTime: occurrence.startDateTime.toTimeInputFormat(),
      endTime: occurrence.endDateTime.toTimeInputFormat(),
      purpose: occurrence.purpose
    };
  };

  const onSubmit = async (values: OccurrenceFormValues, eventId: string) => {
    if (values.startDate && values.startTime) {
      const startTime = DateTime.fromJSDateAndTime(
        values.startDate,
        values.startTime
      );

      const endTime = DateTime.fromJSDateAndTime(
        values.startDate,
        values.endTime
      );

      const baseRequest = {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        bookedBy: values.providerId || core.userId,
        purpose: values.purpose,
        attendees: toAttendees({
          userId: values.providerId
        })
      };

      await booking.updateCalendarEvent({
        id: eventId,
        ...baseRequest
      });
      onDismiss();
    }
  };

  const renderForm = (occurrence: CalendarEvent): ReactNode => {
    const dataSource = (): ITag[] => {
      return [
        {
          key: CalendarEventType.Unavailable,
          name: "Unavailable"
        }
      ];
    };

    return (
      <Fieldset styles={{ root: { marginTop: 8 } }}>
        <MessageBar messageBarType={MessageBarType.info}>
          You're editing a single occurrence of a repeating series
        </MessageBar>
        <Persona
          id={occurrence.userId}
          size={PersonaSize.size24}
          text={occurrence.user?.name}
          imageInitials={occurrence.user?.initials}
        />
        <TextInputField
          label={OccurrenceFormLabels.purpose}
          name={nameOf("purpose")}
        />

        <StaticPickerField
          name={nameOf("calendarEventType")}
          label={OccurrenceFormLabels.type}
          fetchDataSource={dataSource}
          fieldItemStyles={{ root: { flexGrow: 1 } }}
        />

        <Fieldset horizontal>
          <DatePickerField
            label={OccurrenceFormLabels.startDate}
            name={nameOf("startDate")}
          />
          <TimePickerField
            label={OccurrenceFormLabels.startTime}
            suggestionInterval={30}
            name={nameOf("startTime")}
          />
          <TimePickerField
            label={OccurrenceFormLabels.endTime}
            suggestionInterval={30}
            name={nameOf("endTime")}
          />
        </Fieldset>
      </Fieldset>
    );
  };
  return currentOccurrenceId ? (
    <DataFetcher<{ user: User; occurrence: CalendarEvent }>
      fetch={async ({ core, booking }) => {
        const occurrence = await booking.getCalendarEvent(currentOccurrenceId);

        const user = await core.getUser(occurrence.userId);

        return { user, occurrence };
      }}
    >
      {({ user, occurrence }) => (
        <SubmissionFormDialog
          onSubmit={values => onSubmit(values, occurrence.id)}
          initialValues={getInitialValues(occurrence)}
          dialogName="Occurrence dialog"
          dialogProps={{
            onDismiss,
            minWidth: 600,
            dialogContentProps: {
              title: (
                <Heading variant="modal-heading">{`Edit break for ${user?.name} `}</Heading>
              ),
              showCloseButton: true
            }
          }}
          validate={validator.validate}
          render={() => renderForm(occurrence)}
        />
      )}
    </DataFetcher>
  ) : null;
});
