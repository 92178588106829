import {
  PhysicalActivityDto,
  PhysicalActivityFormDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  greaterThanOrSameAs,
  integer,
  lessThan,
  maxLength,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  physicalActivityFormNameOf,
  PhysicalActivityFormValues
} from "../physical-activity/PhysicalActivityForm.types.ts";
import { PhysicalActivity } from "../physical-activity/PhysicalActivityFormEnum.ts";

export class PhysicalActivityFormValidator extends Validator<PhysicalActivityFormValues> {
  constructor() {
    super();
    this.forField("hoursSpentAtSedentary", [
      integer(),
      greaterThanOrSameAs(0),
      lessThan(25)
    ]);

    const activitiesValidator = new ActivitiesValidator();
    this.forArrayField(
      physicalActivityFormNameOf("activities"),
      activitiesValidator.validate
    );
  }
}

export class ActivitiesValidator extends Validator<PhysicalActivityDto> {
  readonly nameOf = nameOfFactory<PhysicalActivityFormDto>();
  constructor() {
    super();
    this.forField("id", required());
    this.forField("activity", [
      required(),
      predicate(
        (
          value,
          parent: PhysicalActivityFormDto,
          allValues?: PhysicalActivityFormValues
        ) => {
          if (!allValues || !allValues.activities) return false;

          const activeActivities = allValues.activities.filter(
            x => !x.isDeleted
          );
          if (activeActivities.length > 1) {
            const filteredActivities = activeActivities.filter(
              x => x.activity !== PhysicalActivity.other
            );
            if (filteredActivities.length > 1) {
              const duplicateActivities = filteredActivities.filter(
                x =>
                  x.activity === value &&
                  x.id !== parent.id &&
                  !parent.isDeleted
              );
              return duplicateActivities.length > 0;
            }
          }
          return false;
        },
        () => ValidationMessages.noDuplicateActivitiesAllowed
      )
    ]);
    this.forField(
      this.nameOf("activityOtherText"),
      [required(), maxLength(50)],
      {
        when: (_value, parent) => parent.activity === PhysicalActivity.other
      }
    );
    this.forField(this.nameOf("sessionsPerWeek"), [
      required(),
      integer(),
      greaterThan(0),
      lessThan(51)
    ]);
    this.forField(this.nameOf("sessionLength"), [
      required(),
      integer(),
      greaterThan(0),
      lessThan(1000)
    ]);
  }
}
