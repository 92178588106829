import { observer } from "mobx-react-lite";

import { Stack } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { AppointmentCancellationReason } from "@stores/booking/models/AppointmentCancellationReason.ts";
import { ChoiceGroupField } from "@ui-components/form/ChoiceGroupField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

const nameOf = nameOfFactory<AppointmentCancellationReason>();

interface AppointmentCancellationReasonFieldsProps {
  reason?: AppointmentCancellationReason;
}

export interface CancellationReasonValue {
  text: string;
  isInactive: string;
}

export enum AppointmentCancellationReasonStatus {
  Active = "active",
  Inactive = "inactive"
}

export const AppointmentCancellationReasonFields: React.FC<AppointmentCancellationReasonFieldsProps> =
  observer(({ reason }) => {
    return (
      <Fieldset verticalFill>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            autoFocus
            required
            label="Name"
            name={nameOf("text")}
            disabled={reason?.isSystemManaged}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 24 }}>
          <ChoiceGroupField
            fieldItemStyles={{ headerWrapper: { padding: 0 } }}
            required
            horizontal
            name={nameOf("isInactive")}
            label="Status"
            options={[
              {
                key: AppointmentCancellationReasonStatus.Active,
                text: capitalizeSentence(
                  AppointmentCancellationReasonStatus.Active
                )
              },
              {
                key: AppointmentCancellationReasonStatus.Inactive,
                text: capitalizeSentence(
                  AppointmentCancellationReasonStatus.Inactive
                )
              }
            ]}
            styles={{
              flexContainer: {
                selectors: {
                  "& > .ms-ChoiceField:not(:first-child)": {
                    marginLeft: 8
                  }
                }
              }
            }}
          />
        </Stack>
      </Fieldset>
    );
  });
